import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

const GuessTheOpeningHelp = () => {
  const { t } = useTranslation("Games");
  return (
    <Box>
      <Typography>{t("GuessTheOpening.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheOpening.helpDetails.instructions.select_length.primary"
            )}
            secondary={t(
              "GuessTheOpening.helpDetails.instructions.select_length.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheOpening.helpDetails.instructions.play_moves.primary"
            )}
            secondary={t(
              "GuessTheOpening.helpDetails.instructions.play_moves.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheOpening.helpDetails.instructions.guess_opening.primary"
            )}
            secondary={t(
              "GuessTheOpening.helpDetails.instructions.guess_opening.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheOpening.helpDetails.instructions.check_guess.primary"
            )}
            secondary={t(
              "GuessTheOpening.helpDetails.instructions.check_guess.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "GuessTheOpening.helpDetails.instructions.multiple_attempts.primary"
            )}
            secondary={t(
              "GuessTheOpening.helpDetails.instructions.multiple_attempts.secondary"
            )}
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default GuessTheOpeningHelp;
