import React, { useState } from "react";
import { Typography, IconButton, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const ExpandableTypography = ({
  text,
  charLimit = 200,
  expandIcon: ExpandIcon = ExpandMoreIcon,
  collapseIcon: CollapseIcon = ExpandLessIcon,
  buttonPosition = "left",
  textWidth = "100%",
  isExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(isExpanded);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const shortText = text.substring(0, charLimit);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "flex-start",
        p: 0,
      }}
    >
      {buttonPosition === "left" && (
        <IconButton onClick={handleToggle} sx={{ mt: 1, mr: 1 }}>
          {expanded ? <CollapseIcon /> : <ExpandIcon />}
        </IconButton>
      )}
      <Typography sx={{ pt: 2, width: textWidth }}>
        {expanded ? text : `${shortText}...`}
      </Typography>
      {buttonPosition === "right" && (
        <IconButton onClick={handleToggle} sx={{ mt: 1, ml: 1 }}>
          {expanded ? <CollapseIcon /> : <ExpandIcon />}
        </IconButton>
      )}
    </Box>
  );
};

export default ExpandableTypography;
