import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  Tooltip,
  Paper,
  useTheme,
  Typography,
} from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { useShare } from "../../../context/ShareContext";
import { useUser } from "../../../context/UserContext";
import { useTranslation } from "react-i18next";
import { chessterms } from "../../../data/chessterms/chessterms";
import { updateUserData } from "../../../features/Firestore";
import { trackEvent } from "../../../config/ga";
import HelpModal from "../../../components/HelpModal";
import ExpandableTypography from "../../../components/ExpandableTypograph";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CryptogramsHelp from "../../../help/CryptogramsHelp";

function Cryptograms() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userData, userRef } = useUser();
  const { t } = useTranslation("Games");
  const { setShareData } = useShare();
  const [selectedTerms, setSelectedTerms] = useState([]);
  const [gameStarted, setGameStarted] = useState(false);
  const [letterMapping, setLetterMapping] = useState({});
  const [guessedLetters, setGuessedLetters] = useState({});
  const [quitConfirmed, setQuitConfirmed] = useState(false);
  const [gameCompleted, setGameCompleted] = useState(false);
  const [quitGame, setQuitGame] = useState(false);
  const [focusedLetter, setFocusedLetter] = useState("");
  const [hintsRemaining, setHintsRemaining] = useState(3);
  const [disabledLetters, setDisabledLetters] = useState({});

  const [inputSize, setInputSize] = useState("25px");
  const [termLimit, setTermLimit] = useState(25);

  useEffect(() => {
    const updateSize = () => {
      if (window.innerWidth >= 768) {
        setInputSize("40px"); // Desktop size
        setTermLimit(25);
      } else {
        setInputSize("25px"); // Mobile size
        setTermLimit(13);
      }
    };

    updateSize(); // Run on initial load
    window.addEventListener("resize", updateSize); // Add event listener for resize

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const ChessSymbols = useMemo(
    () => ({
      BRILLIANT_MOVE: { symbol: "!!", definition: "A brilliant move" },
      GOOD_MOVE: { symbol: "!", definition: "A good move" },
      BAD_MOVE: { symbol: "?", definition: "A bad move" },
      BLUNDER: { symbol: "??", definition: "A blunder" },
      INTERESTING_MOVE: { symbol: "!?", definition: "An interesting move" },
      DUBIOUS_MOVE: { symbol: "?!", definition: "A dubious move" },
      CHECK: { symbol: "+", definition: "A check" },
      CHECKMATE: { symbol: "#", definition: "A checkmate" },
      UNCLEAR_POSITION: { symbol: "∞", definition: "An unclear position" },
      ADVANTAGE_WHITE: { symbol: "±", definition: "Advantage for white" },
      ADVANTAGE_BLACK: { symbol: "∓", definition: "Advantage for black" },
      BETTER_MOVE: { symbol: "⌓", definition: "A better move exists" },
      ONLY_MOVE: { symbol: "□", definition: "The only move" },
      PLANNED_MOVE: { symbol: "Δ", definition: "A planned move" },
      COUNTERS_PLAN: { symbol: "∇", definition: "Counters the plan" },
      INITIATIVE: { symbol: "↑", definition: "Takes the initiative" },
      ATTACK: { symbol: "→", definition: "Initiates an attack" },
      COUNTERPLAY: { symbol: "⇄", definition: "Counterplay exists" },
      SPACE_ADVANTAGE: { symbol: "○", definition: "Advantage in space" },
      ZUGZWANG: { symbol: "⊙", definition: "Zugzwang position" },
      DEVELOPMENT_ADVANTAGE_1: {
        symbol: "↻",
        definition: "Development advantage",
      },
      DEVELOPMENT_ADVANTAGE_2: {
        symbol: "↑↑",
        definition: "Development advantage",
      },
      EQUAL_POSITION: { symbol: "=", definition: "Equal position" },
      ROOK: { symbol: "R", definition: "Rook" },
      BISHOP: { symbol: "B", definition: "Bishop" },
      KING: { symbol: "K", definition: "King" },
      QUEEN: { symbol: "Q", definition: "Queen" },
      KNIGHT: { symbol: "N", definition: "Knight" },
      CAPTURE: { symbol: "X", definition: "Capture" },
    }),
    []
  );

  const isPunctuation = useCallback((char) => {
    return /[.,:;!?()'-]/.test(char);
  }, []);

  const isTermCompleted = useCallback(
    (termObj) => {
      return termObj.Term.toUpperCase()
        .split("")
        .every((char) => {
          if (char === " " || isPunctuation(char)) return true;
          const symbol = letterMapping[char] || char;
          return guessedLetters[symbol] === char;
        });
    },
    [guessedLetters, letterMapping, isPunctuation]
  );

  const areAllTermsCompleted = useCallback(() => {
    return selectedTerms.every((termObj) => isTermCompleted(termObj));
  }, [selectedTerms, isTermCompleted]);

  useEffect(() => {
    if (areAllTermsCompleted()) {
      setGameCompleted(true);
      setFocusedLetter("");
      trackEvent("Games", "Cryptogram-Success", "Cryptogram");
      if (userData) {
        userData.Puzzles.Cryptogram.Completed =
          (userData.Puzzles.Cryptogram.Completed || 0) + 1;
        updateUserData(userRef, userData);
      }
    }
  }, [guessedLetters, areAllTermsCompleted, userRef, userData]);

  const generateRandomMapping = useCallback(() => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    const symbols = Object.values(ChessSymbols).map((entry) => entry.symbol);

    const shuffledSymbols = [...symbols].sort(() => 0.5 - Math.random());

    const mapping = {};
    alphabet.forEach((letter, index) => {
      mapping[letter] = shuffledSymbols[index % shuffledSymbols.length];
    });
    setLetterMapping(mapping);
  }, [ChessSymbols]);

  const handleNewGame = useCallback(() => {
    generateRandomMapping();
    const filteredTerms = chessterms.filter(
      (term) => term.Term.length <= termLimit && term.Term !== "Chess960"
    );
    const shuffled = [...filteredTerms].sort(() => 0.5 - Math.random());
    setSelectedTerms(shuffled.slice(0, 7));
    setGameStarted(true);
    setHintsRemaining(3);
    setGuessedLetters({});
    setQuitConfirmed(false);
    setGameCompleted(false);
    setQuitGame(false);
    setDisabledLetters({});
    trackEvent("Games", "Cryptogram-Play", "Cryptogram");

    if (userData) {
      if (!userData.Puzzles) {
        userData.Puzzles = {};
      }
      if (userData.Puzzles.Cryptogram) {
        userData.Puzzles.Cryptogram.Played =
          (userData.Puzzles.Cryptogram.Played || 0) + 1;
      } else {
        userData.Puzzles.Cryptogram = {
          Played: 1,
          Completed: 0,
        };
      }
      updateUserData(userRef, userData);
    }
  }, [userData, userRef, termLimit, generateRandomMapping]); // Add dependencies

  useEffect(() => {
    // Start a new game when the component loads
    handleNewGame();
  }, [handleNewGame]);

  const handleInputChange = (symbol, value) => {
    const uppercasedValue = value.toUpperCase();
    if (uppercasedValue.length === 1) {
      setGuessedLetters((prevGuesses) => ({
        ...prevGuesses,
        [symbol]: uppercasedValue,
      }));
    }
  };

  const handleFocus = (symbol) => {
    setFocusedLetter(symbol);
  };

  const handleBlur = () => {
    setFocusedLetter("");
  };

  const getSymbolDefinition = (symbol) => {
    const entry = Object.values(ChessSymbols).find(
      (entry) => entry.symbol === symbol
    );
    return entry ? entry.definition : "";
  };

  const handleHint = () => {
    // Get all letters that are part of the terms but not yet guessed
    const availableLetters = selectedTerms
      .flatMap((termObj) => termObj.Term.toUpperCase().split("")) // Get all letters in terms
      .filter((char) => !isPunctuation(char) && char !== " ") // Ignore spaces and punctuation
      .filter((char) => !guessedLetters[letterMapping[char]]); // Only keep unguessed letters

    if (availableLetters.length > 0 && hintsRemaining > 0) {
      // Pick a random letter from available letters
      const randomLetter =
        availableLetters[Math.floor(Math.random() * availableLetters.length)];

      // Reveal the letter and disable the corresponding input
      setGuessedLetters((prev) => ({
        ...prev,
        [letterMapping[randomLetter]]: randomLetter,
      }));
      setDisabledLetters((prev) => ({
        ...prev,
        [letterMapping[randomLetter]]: true,
      }));

      setHintsRemaining(hintsRemaining - 1); // Decrease hint count
    }
  };

  const handleQuit = () => {
    setQuitConfirmed(true);
  };

  const confirmQuit = () => {
    const correctLetters = {};
    selectedTerms.forEach((termObj) => {
      termObj.Term.toUpperCase()
        .split("")
        .forEach((char) => {
          if (!isPunctuation(char) && char !== " ") {
            correctLetters[letterMapping[char]] = char;
          }
        });
    });
    setGuessedLetters(correctLetters);
    setQuitConfirmed(false);
    setGameCompleted(true);
    setQuitGame(true);
    trackEvent("Games", "Cryptogram-Failed", "Cryptogram");
    if (userData) {
      userData.Puzzles.Cryptogram.Completed =
        (userData.Puzzles.Cryptogram.Completed || 0) + 1;
      updateUserData(userRef, userData);
    }
  };

  const cancelQuit = () => {
    setQuitConfirmed(false);
  };

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/cryptogram",
      title: "Chessboard Magic - Chess Cryptogram",
      description:
        "Crack the code in Chess Cryptogram! Decrypt chess terms, engines, and famous players to reveal their hidden meanings.",
    };
    setShareData(newShareData);
  }, [setShareData, t]);

  return (
    <Box>
      <ContentHeader
        title={t("Cryptograms.header.title")}
        subtitle={t("Cryptograms.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      <Helmet>
        <title>Chess Cryptogram</title>
        <meta
          name="description"
          content="Crack the code in Chess Cryptogram! Decrypt chess terms, engines, and famous players to reveal their hidden meanings"
        />
        <meta property="og:title" content="Chess Cryptogram" />
        <meta
          property="og:description"
          content="Crack the code in Chess Cryptogram! Decrypt chess terms, engines, and famous players to reveal their hidden meanings"
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/games/cryptogram.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/cryptogram`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Chess Cryptogram" />
        <meta
          name="twitter:description"
          content="Crack the code in Chess Cryptogram! Decrypt chess terms, engines, and famous players to reveal their hidden meanings"
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/games/cryptogram.png`}
        />
      </Helmet>
      <Box>
        <Button
          variant="contained"
          onClick={handleNewGame}
          style={{ marginRight: 10 }}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.material[1] }}
            />
          }
        >
          {t("Cryptograms.buttons.play")}
        </Button>
        {!quitConfirmed && hintsRemaining > 0 && (
          <Button
            variant="contained"
            onClick={handleHint}
            style={{ marginRight: 10 }}
            disabled={!gameStarted || gameCompleted}
          >
            {t("Cryptograms.buttons.hints")} ({hintsRemaining})
          </Button>
        )}

        {!quitConfirmed && hintsRemaining === 0 && (
          <Button
            variant="contained"
            color="error"
            onClick={handleQuit}
            style={{ marginRight: 10 }}
            disabled={!gameStarted || gameCompleted}
          >
            {t("Cryptograms.buttons.quit")}
          </Button>
        )}

        {quitConfirmed && (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={confirmQuit}
              style={{ marginRight: 10 }}
            >
              {t("Cryptograms.buttons.confirm")}
            </Button>
            <Button
              variant="contained"
              onClick={cancelQuit}
              style={{ marginRight: 10 }}
            >
              {t("Cryptograms.buttons.cancel")}
            </Button>
          </>
        )}
        <Button
          variant="contained"
          onClick={handleClickOpen}
          style={{ marginRight: 10 }}
          startIcon={<HelpOutlineIcon style={{ color: colors.material[1] }} />}
        >
          {t("Cryptograms.buttons.help")}
        </Button>

        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("Cryptograms.helpDetails.title")}
          content={<CryptogramsHelp />}
        ></HelpModal>
      </Box>

      {gameCompleted && gameStarted && !quitGame && (
        <Box mt={3} display="flex" alignItems="center">
          <CheckCircleOutlineOutlinedIcon style={{ color: "green" }} />
          <Typography variant="body" style={{ marginLeft: "10px" }}>
            {t("Cryptograms.labels.completed")}
          </Typography>
        </Box>
      )}

      <Box>
        {gameStarted && (
          <Box sx={{ pt: 2 }}>
            {selectedTerms.map((termObj, index) => (
              <Box key={index} mb={2}>
                <Box display="flex" alignItems="center">
                  <Box display="flex" justifyContent="left">
                    {termObj.Term.toUpperCase()
                      .split("")
                      .map((char, i) => {
                        const symbol = letterMapping[char] || char;

                        if (char === " ") {
                          return (
                            <Box
                              key={i}
                              style={{
                                width: "10px",
                                height: inputSize,
                                marginBottom: "8px",
                              }}
                            />
                          );
                        } else if (isPunctuation(char)) {
                          return (
                            <Box
                              key={i}
                              textAlign="center"
                              display="flex"
                              flexDirection="column"
                            >
                              <Box
                                style={{
                                  width: "10px",
                                  height: inputSize,
                                  lineHeight: "30px",
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "transparent",
                                  marginBottom: "8px",
                                }}
                              >
                                {char}
                              </Box>
                              <Box
                                style={{
                                  width: "10px",
                                  height: inputSize,
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  backgroundColor: "transparent",
                                  marginBottom: "8px",
                                }}
                              >
                                {char}
                              </Box>
                            </Box>
                          );
                        } else {
                          return (
                            <Box key={i} textAlign="center">
                              <Tooltip
                                title={getSymbolDefinition(symbol)}
                                arrow
                                placement="top"
                              >
                                <Paper
                                  elevation={3}
                                  style={{
                                    width: inputSize,
                                    height: inputSize,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "8px",
                                  }}
                                >
                                  {symbol}
                                </Paper>
                              </Tooltip>
                              <input
                                type="text"
                                maxLength={1}
                                style={{
                                  width: inputSize,
                                  height: inputSize,
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  color: `${colors.black[900]}`,
                                  border: `0.5px solid ${colors.black[500]}`,
                                  backgroundColor: `${
                                    symbol === focusedLetter
                                      ? colors.red[100]
                                      : "transparent"
                                  }`,
                                }}
                                value={guessedLetters[symbol] || ""}
                                onFocus={() => handleFocus(symbol)}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  const value = e.target.value.toUpperCase();
                                  if (value === "") {
                                    // Handle backspace to clear the input
                                    setGuessedLetters((prevGuesses) => ({
                                      ...prevGuesses,
                                      [symbol]: "", // Clear the input on backspace
                                    }));
                                  } else if (
                                    value.length === 1 &&
                                    /^[A-Z]$/.test(value)
                                  ) {
                                    // Handle valid letter input to replace the current character
                                    handleInputChange(symbol, value);
                                  }
                                }}
                                disabled={
                                  gameCompleted || disabledLetters[symbol]
                                }
                              />
                            </Box>
                          );
                        }
                      })}
                  </Box>

                  {!gameCompleted && isTermCompleted(termObj) && (
                    <CheckCircleOutlineOutlinedIcon
                      style={{ color: "green", marginLeft: "10px" }}
                    />
                  )}
                </Box>

                {gameCompleted && (
                  <Box sx={{ maxWidth: "600px" }}>
                    <ExpandableTypography
                      text={termObj.Description}
                      isExpanded={true}
                      buttonPosition="right"
                      charLimit={100}
                    />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Cryptograms;
