export const usergames = [
  {
    W: "2258",
    T: "180+2",
    P: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Nf3 c5 5. g3 cxd4 6. Nxd4 Ne4 7. Qc2 Nxc3 8. bxc3 Be7 9. Bg2 O-O 10. O-O a6 11. Rd1 Qc7 12. Qb3 d6 13. Be3 Nd7 14. Rab1 Rb8 15. Qc2 Nc5 16. Nb3 b6 17. Nxc5 bxc5 18. Rxb8 Qxb8 19. Rb1 Qc7 20. Qa4 Bf6 21. Rb3 h6 22. Qc6 Qxc6 23. Bxc6 Bd8 24. Bf4 Bc7 25. Bb7 g5 26. Be3 Bd7 27. Bf3 Bc8 28. Bb7 Bd7 29. Bxa6 Ra8 30. Rb7 Rxa6 31. Rxc7 Ba4 32. h4 gxh4 33. gxh4 Kg7 34. Rb7 Bd1 35. Rb2 Ra4 36. Rd2 Rxc4 37. Rxd1 d5 38. Ra1 Rxc3 39. a4 d4 40. a5 dxe3 41. a6 exf2+ 42. Kxf2 1-0",
    B: "2446",
  },
  {
    W: "2420",
    T: "30+0",
    P: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. h4 h6 7. h5 Bh7 8. Nf3 Nd7 9. Bd3 Bxd3 10. Qxd3 Qc7 11. Bf4 Qxf4 12. Ne2 Qc7 13. O-O-O e6 14. Qd2 Ngf6 15. Nf4 Bd6 16. Qe3 Bxf4 17. Kb1 Bxe3 18. fxe3 O-O-O 19. Rhe1 e5 20. Nd2 exd4 21. exd4 Rhe8 22. Nb3 Rxe1 23. Rxe1 Re8 24. Rd1 c5 25. dxc5 Nxc5 26. Nd4 Rd8 27. Kc1 Ne6 28. Nb5 Rxd1+ 29. Kxd1 Qd7+ 0-1",
    B: "2269",
  },
  {
    W: "2456",
    T: "60+0",
    P: "1. c3 g6 2. d3 c6 3. Nd2 d5 4. Ngf3 Nf6 5. g3 Bg7 6. Bg2 Kf8 7. O-O h5 8. Re1 h4 9. e4 hxg3 10. fxg3 Bh3 11. e5 Ng4 12. Bxh3 Rxh3 13. e6 Qb6+ 14. d4 Bh6 15. Nf1 Bxc1 16. Qxc1 c5 17. exf7 cxd4 18. cxd4 Nc6 19. Qf4 Qxb2 20. Qxg4 Rh5 21. Qxg6 Rh8 22. Ng5 Qxd4+ 23. Kg2 1-0",
    B: "2315",
  },
  {
    W: "1956",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 g6 4. dxc5 e6 5. Nc3 Bxc5 6. Bd3 Nf6 7. Be3 d5 8. Bxc5 d4 9. Ne2 e5 10. O-O Bg4 11. Ng3 Qa5 12. Ba3 O-O-O 13. h3 Be6 14. Qe1 Qc7 15. c3 dxc3 16. Qxc3 Qd7 17. Bb5 Qc7 18. Bxc6 Qxc6 19. Qxc6+ bxc6 20. Rac1 Rd4 21. Nxe5 Nxe4 22. Nxc6 Kd7 23. Nxd4 Rb8 24. Nxe4 f5 25. Nxe6 Kxe6 26. Nc5+ Kf6 27. Nb3 Re8 28. Rfe1 Rd8 29. Rcd1 Rc8 30. Nd4 Rb8 31. Re6+ Kf7 32. Rde1 Rd8 33. Re7+ Kf6 34. R1e6+ Kg5 35. h4+ Kg4 36. Nf3 Rd1+ 37. Re1 Rxe1+ 38. Nxe1 g5 39. Rxh7 gxh4 40. Rxa7 Kg5 41. Ra5 Kf6 42. Rxf5+ Kxf5 43. g3 Kg5 44. gxh4+ Kxh4 45. Kh2 Kg4 46. f4 Kf5 47. b3 Kxf4 48. b4 Ke5 49. b5 Kd5 50. b6 Kc6 51. b7 Kxb7 52. Ng2 Kc6 1/2-1/2",
    B: "2039",
  },
  {
    W: "1445",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. Bxe7 Qxe7 7. f4 f6 8. Nf3 c5 9. Nb5 Qd8 10. Nd6+ Kf8 11. Bd3 1-0",
    B: "1386",
  },
  {
    W: "1548",
    T: "60+0",
    P: "1. d4 a6 2. Nf3 b5 3. Bf4 Bb7 4. e3 h6 5. h3 g5 6. Bh2 e6 7. c3 Be7 8. b3 Nf6 9. c4 g4 10. hxg4 Nxg4 11. g3 Nxh2 12. Rxh2 d6 13. cxb5 a5 14. Bg2 Be4 15. Nbd2 Bxf3 16. Bxf3 d5 17. Nc4 Bb4+ 18. Ke2 c6 19. bxc6 Nxc6 20. a3 Be7 21. b4 axb4 22. axb4 Rxa1 23. Qxa1 Nxb4 24. Ne5 O-O 25. Qh1 Bg5 26. Ng4 Kh7 27. Nxh6 Qf6 28. Ng4+ Kg8 29. Nxf6+ Kg7 30. Rh8 Bxf6 31. Qh7# 1-0",
    B: "1549",
  },
  {
    W: "2453",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 Nf6 3. e3 a6 4. b3 e6 5. Bb2 c5 6. Bd3 Nc6 7. Ne5 b5 8. Nxc6 Qc7 9. Ne5 c4 10. bxc4 bxc4 11. Be2 Bb4+ 12. c3 Bd6 13. f4 Rb8 14. Ba3 Bxa3 15. Nxa3 Qa5 16. Qc1 Ne4 17. Nb1 Bd7 18. O-O f6 19. Nxd7 Kxd7 20. Bf3 Nd6 21. f5 exf5 22. Qa3 Qxa3 23. Nxa3 g6 24. Bxd5 Rhe8 25. Nxc4 Nb5 26. Rab1 Nxc3 27. Rxb8 Rxb8 28. Bf3 Rb4 29. Na5 Ra4 30. Bc6+ 1-0",
    B: "2754",
  },
  {
    W: "2354",
    T: "60+0",
    P: "1. d4 Nf6 2. Nf3 e6 3. Bg5 d5 4. Nbd2 h6 5. Bxf6 Qxf6 6. e4 dxe4 7. Nxe4 Qd8 8. Bd3 Nd7 9. Qe2 Be7 10. O-O-O c6 11. h4 Qc7 12. g4 Nf6 13. Nxf6+ Bxf6 14. g5 Be7 15. gxh6 Rxh6 16. Rhg1 g6 17. Ne5 Rxh4 18. Bxg6 fxg6 19. Rxg6 Bd7 20. Rg8+ Bf8 21. Qf3 Qd6 22. Qf7+ Kd8 23. Rxf8+ Kc7 24. Rxa8 1-0",
    B: "2373",
  },
  {
    W: "1425",
    T: "60+0",
    P: "1. d4 d5 2. e3 c6 3. Nf3 g6 4. Bd3 Nf6 5. b3 Bg7 6. Bb2 O-O 7. Nbd2 Re8 8. O-O Nfd7 9. Qe2 e5 10. dxe5 Nxe5 11. Nxe5 Bxe5 12. Bxe5 Rxe5 13. f4 Re8 14. Qf3 Nd7 15. Qh3 Ne5 16. Qh6 Nxd3 17. cxd3 Rxe3 18. Rf3 Re2 19. Nf1 Bf5 20. Ng3 Re6 21. Nxf5 gxf5 22. Qg5+ Rg6 23. Qxf5 Qb6+ 24. Kf1 Qd4 25. Rg3 Qxa1+ 26. Kf2 Qxa2+ 27. Kf3 Rxg3+ 28. hxg3 Qxb3 29. Qg4+ Kh8 30. f5 Qxd3+ 31. Kf4 Re8 32. Kg5 Re4 33. Qh5 Re5 34. Kh6 Rxf5 35. Qxf5 Qxf5 36. g4 Qxg4 0-1",
    B: "1623",
  },
  {
    W: "1757",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 d6 4. Nc3 Be6 5. d3 Be7 6. Be3 Nf6 7. b3 O-O 8. h4 a6 9. h5 h6 10. Qd2 Ng4 11. O-O-O Nxe3 12. Qxe3 Nd4 13. Nxd4 exd4 14. Qxd4 Bf6 15. e5 Bxe5 16. Qe3 Bxc3 17. d4 Bxc4 18. Qxc3 Be6 19. Rd3 Qg5+ 20. Kb2 Qxg2 21. Qe1 Rfe8 22. Rg3 Qc6 23. Qd2 Qxh1 24. Qxh6 Kf8 25. Qxg7+ Ke7 26. Qg5+ Kd7 27. h6 Rh8 28. d5 Bh3 29. Qg7 Rag8 30. Qxf7+ Kc8 31. Rxg8+ Rxg8 32. Qxg8+ Kd7 33. h7 Qxd5 34. h8=Q 1-0",
    B: "1647",
  },
  {
    W: "2229",
    T: "60+0",
    P: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. Nf3 Bb4 5. e3 O-O 6. Bd2 dxc4 7. Bxc4 c5 8. O-O cxd4 9. Nxd4 Nc6 10. Nxc6 bxc6 11. a3 Be7 12. Qe2 Qb6 13. b4 a5 14. Na4 Qc7 15. b5 cxb5 16. Bxb5 Bb7 17. Rfc1 Qe5 18. Bc3 Qg5 19. f3 Nd5 20. Bd2 Qg6 21. e4 Nf6 22. Rc7 Bc5+ 23. Nxc5 Rab8 24. Rxb7 Rbc8 25. Nd7 Nxd7 26. Rxd7 Rc2 27. Rd1 Rb2 28. Qf1 h5 29. Bxa5 h4 30. h3 Qg3 31. Bb4 Rb8 32. Kh1 Kh7 33. Rd8 Rb7 34. Bd3 R7xb4 35. e5+ Re4 36. Bxe4+ g6 37. Re1 Rd2 38. Re2 Rxd8 39. Re1 Rd2 40. Re2 Rd4 41. Bc2 Rf4 42. Re4 Rxf3 43. gxf3 Qxh3+ 44. Qxh3 Kg7 45. Qg4 g5 46. Qxh4 Kf8 0-1",
    B: "2303",
  },
  {
    W: "1948",
    T: "60+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. Bc4 d6 4. O-O Nf6 5. Bxf7+ Kxf7 6. Qe2 Bg4 7. e5 Bxf3 8. Qxf3 dxe5 9. d3 Nc6 10. Nc3 Rf8 11. Ne4 Kg8 12. Ng5 Qd5 13. Qe2 h6 14. Ne4 Nxe4 15. dxe4 Qf7 16. Qb5 Rab8 17. Be3 Nd4 18. Bxd4 exd4 19. f4 c6 20. Qa5 a6 21. f5 b6 22. Qxa6 Rfd8 23. Rae1 e5 24. fxe6 Qxe6 25. Qxb6 Rxb6 0-1",
    B: "1854",
  },
  {
    W: "2327",
    T: "60+0",
    P: "1. d4 d5 2. c4 c6 3. cxd5 f6 4. dxc6 bxc6 5. Nc3 Ba6 6. e3 Bxf1 7. Kxf1 e5 8. dxe5 Qxd1+ 9. Nxd1 Nd7 10. exf6 Ngxf6 11. Nc3 Ne5 12. Nf3 Nc4 13. b3 Nb6 14. Bb2 Bb4 15. Ke2 O-O-O 16. Rac1 Nbd5 17. Nxd5 Nxd5 18. Rxc6+ Kb7 19. Rc4 a5 20. Bxg7 Rhg8 21. Be5 Rxg2 22. Bg3 Re8 23. Rhc1 Nxe3 24. Rc7+ Kb6 25. R1c6+ Kb5 26. Nd4# 1-0",
    B: "2333",
  },
  {
    W: "1463",
    T: "60+0",
    P: "1. e4 d5 0-1",
    B: "1490",
  },
  {
    W: "1711",
    T: "420+5",
    P: "1. d4 d5 2. Bf4 Nf6 3. e3 c5 4. c3 c4 5. Nd2 e6 6. b3 cxb3 7. axb3 Bd6 8. Bxd6 Qxd6 9. Ngf3 a6 10. Be2 O-O 11. O-O Nbd7 12. c4 dxc4 13. bxc4 Qb4 14. Rb1 Qe7 15. Rc1 b5 16. c5 Ne5 17. dxe5 1-0",
    B: "1526",
  },
  {
    W: "1681",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nxd4 5. Qxd4 d6 6. Nc3 Nf6 7. a3 Be7 8. Bb5+ c6 9. Be2 O-O 10. Bf4 a6 11. O-O-O c5 12. Qd2 Ne8 13. Nd5 Be6 14. Nxe7+ Qxe7 15. Bxd6 Nxd6 16. Qxd6 Qf6 17. f3 Rfd8 18. Qg3 Ba2 19. Rxd8+ Rxd8 20. Rd1 Qh6+ 21. f4 Rxd1+ 22. Bxd1 Qd6 23. e5 Qd5 24. Qf3 Bc4 25. Qxd5 Bxd5 26. g3 b5 27. Kd2 h6 28. Ke3 Kf8 29. Bf3 Bxf3 30. Kxf3 Ke7 31. Ke4 g6 32. g4 Kd7 33. Kd5 c4 34. c3 Kc7 35. h4 h5 36. gxh5 gxh5 37. f5 1-0",
    B: "1703",
  },
  {
    W: "1320",
    T: "300+3",
    P: "1. e4 Nc6 2. d4 d5 3. exd5 Qxd5 4. Nf3 Qe4+ 5. Qe2 Qxe2+ 6. Bxe2 Nf6 7. Bd3 Bg4 8. Be4 Bxf3 9. Bxf3 Nxd4 10. Bxb7 Rb8 11. c3 Nc2+ 12. Ke2 Rxb7 13. Na3 Nxa1 14. Be3 Rxb2+ 15. Kf3 Nc2 16. Bxa7 Nxa3 17. Rd1 Rxa2 18. Bb8 g6 19. Bxc7 e6 20. Rd8+ Ke7 21. Ke3 Nd5+ 22. Kd4 Nxc7 23. Rc8 Ncb5+ 24. Kc5 Rc2 25. Kb4 Kf6+ 26. Kb3 Bg7 27. c4 Rxc8 28. cxb5 Nxb5 29. Kb4 Nd4 30. f4 Rb8+ 31. Ka3 Rcb2 32. g4 R2b7 33. g5+ Kf5 34. h4 Ra7# 0-1",
    B: "1212",
  },
  {
    W: "1771",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 d6 5. h3 Nf6 6. O-O Be7 7. Re1 O-O 8. Nxd4 Ne5 9. Bb3 a6 10. Nc3 c5 11. Nf3 c4 12. Nxe5 cxb3 13. Nf3 bxc2 14. Qxc2 Be6 15. Be3 Rc8 16. Qa4 Bd7 17. Qb3 Bc6 18. Bb6 Qd7 19. Rad1 Qe8 20. Nd5 Nxd5 21. exd5 Bd7 22. Ng5 h6 23. Ne4 Ba4 24. Qg3 Bxd1 25. Nf6+ 1-0",
    B: "1901",
  },
  {
    W: "1088",
    T: "300+3",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. Nc3 Nf6 5. b3 Bg4 6. h3 Bh5 7. g4 Bg6 8. Bb2 Bd6 9. Bd3 Bxd3 10. cxd3 O-O 11. Qc2 Re8+ 12. Ne2 Nc6 13. O-O-O Bb4 14. g5 Nh5 15. Rhg1 Rxe2 16. Rde1 Rxf2 17. Ref1 Rxf1+ 18. Rxf1 h6 19. gxh6 g6 20. a3 Bf8 21. Qd1 Bxh6 22. Kb1 a5 23. Nh4 b5 24. Bf6 Qd6 25. Qe2 Nxf6 26. Nf3 Nh5 27. Ka2 b4 28. a4 Qd7 29. d4 Re8 30. Qg2 Re4 31. d3 Nxd4 32. dxe4 Nxf3 33. Qe2 Nd4 34. Qe3 Nc6 35. Rd1 Qe6 36. exd5 Qxe3 0-1",
    B: "1094",
  },
  {
    W: "1672",
    T: "300+3",
    P: "1. e4 e5 2. Bc4 d6 3. Nc3 Nf6 4. d3 Be7 5. h3 O-O 6. Nf3 c6 7. a3 b5 8. Ba2 Nbd7 9. Ne2 a5 10. c3 Nc5 11. b4 Nb7 12. Bb2 a4 13. Rc1 Be6 14. Bb1 Nd7 15. Qd2 Kh8 16. O-O Nb6 17. d4 Bf6 18. Ba1 Nc4 19. Qd3 Nxa3 20. d5 Bd7 21. Ba2 c5 22. c4 cxb4 23. Bb2 bxc4 24. Bxc4 Nxc4 25. Qxc4 a3 26. Ba1 Qb6 27. Rb1 Ra4 28. Nc3 Raa8 29. Rxb4 Qa7 30. Qe2 Nc5 31. Rc4 Rfb8 32. Nd2 Bg5 0-1",
    B: "1680",
  },
  {
    W: "1125",
    T: "300+3",
    P: "1. d4 d5 2. c4 dxc4 3. Bf4 Nc6 4. Nf3 Be6 5. e3 Nf6 6. Ng5 h6 7. Nxe6 fxe6 8. Bxc4 g5 9. Bg3 Ne4 10. Bxe6 Nxg3 11. hxg3 Qd6 12. Qb3 Na5 13. Qd5 Qxd5 14. Bxd5 c6 15. Bf3 O-O-O 16. Nc3 Nc4 17. O-O Nxb2 18. Rab1 Nc4 19. Rfd1 Bg7 20. d5 Bxc3 21. dxc6 bxc6 22. Rxd8+ Rxd8 23. Rc1 Nd2 24. Rxc3 Nxf3+ 25. gxf3 Kc7 26. Kg2 Rd2 27. Ra3 Kb6 28. f4 gxf4 29. gxf4 a5 30. Kf3 c5 31. Rb3+ Ka6 32. a3 c4 33. Rb8 Rd3 34. Rc8 c3 35. a4 Kb6 36. Ke2 Rd2+ 37. Kf3 c2 38. Rc4 Ka6 39. Kg3 Kb6 40. f3 Ka6 41. e4 Re2 42. Kg4 Rg2+ 43. Kf5 Rf2 44. e5 Rd2 45. Ke4 Kb6 46. Ke3 Rh2 47. f5 h5 48. f4 h4 49. f6 exf6 50. exf6 h3 51. f7 c1=Q+ 52. Rxc1 Rg2 1-0",
    B: "1210",
  },
  {
    W: "2073",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. e5 d5 6. Bb5 Ne4 7. Nxd4 Bd7 8. Bxc6 bxc6 9. O-O Bc5 10. f3 Ng5 11. Be3 Qe7 12. f4 Ne4 13. Kh1 O-O 14. Nd2 Nxd2 15. Qxd2 f6 16. Qc3 fxe5 17. Nxc6 Qf6 18. Bxc5 Bxc6 19. fxe5 Qxf1+ 20. Rxf1 Rxf1+ 21. Bg1 Bb5 22. a4 Bxa4 23. Qd3 Rff8 24. Qxd5+ Kh8 25. b3 Rad8 26. Qe4 Bd7 27. e6 Rde8 28. e7 Rf6 29. h3 Bc6 30. Qe2 Rg6 31. Bc5 Rxg2 32. Qxg2 Bxg2+ 33. Kxg2 Kg8 34. Kf3 Kf7 35. h4 c6 36. Kf4 Ke6 37. Ke4 a6 38. c4 g6 39. b4 h6 40. Kf4 Kd7 41. Ke5 Rg8 42. Kf6 g5 43. hxg5 hxg5 44. Kf7 Re8 45. Kf6 g4 46. Kf7 g3 47. Kf6 g2 48. Kf7 Rxe7+ 49. Kf6 Re1 0-1",
    B: "2239",
  },
  {
    W: "1499",
    T: "300+3",
    P: "1. c4 c5 2. Nc3 e6 3. Nf3 d6 4. g3 Ne7 5. Bg2 Ng6 6. O-O Nc6 7. d4 cxd4 8. Nxd4 Be7 9. Nxc6 bxc6 10. Bxc6+ Bd7 11. Bxa8 Qxa8 12. f3 O-O 13. Ne4 Qc6 14. Bg5 f6 15. Be3 a6 16. b3 Bc8 17. Rc1 Bb7 18. Bf2 f5 19. Nc3 f4 20. Ne4 fxg3 21. hxg3 Rf5 22. Kg2 Rh5 23. Rh1 Rf5 24. g4 Rf4 25. Bg3 Rxe4 26. fxe4 Qxe4+ 27. Kh3 Qg2# 0-1",
    B: "1562",
  },
  {
    W: "1780",
    T: "60+0",
    P: "1. d4 c5 2. d5 d6 3. c4 Bd7 4. Nc3 e6 5. e4 e5 6. h3 f5 7. Nf3 Nf6 8. exf5 Be7 9. Ng5 O-O 10. Ne6 Bxe6 11. fxe6 Nbd7 12. Be2 a6 13. a4 b5 14. exd7 Nxd7 15. axb5 axb5 16. Rxa8 Qxa8 17. Nxb5 Nf6 18. O-O e4 19. Bf4 h6 20. Bxd6 Bxd6 21. Nxd6 g5 22. Nf5 Kh7 23. Qc2 e3 24. Ne7+ Kg7 25. Qg6+ Kh8 26. Qxh6+ Nh7 27. Ng6+ Kg8 28. Nxf8 Qxf8 29. fxe3 Qxh6 30. Bd3 g4 31. hxg4 Qxe3+ 32. Kh1 Qxd3 33. Rf8+ 0-1",
    B: "1736",
  },
  {
    W: "1420",
    T: "300+0",
    P: "1. d4 c6 2. c4 b6 3. d5 Qc7 4. Nc3 Bb7 5. e4 d6 6. Bf4 Nd7 7. Nf3 Ngf6 8. e5 dxe5 9. Nxe5 Nxe5 10. Bg3 O-O-O 11. Qa4 Ba8 12. Rd1 e6 13. Bd3 Nxd3+ 14. Rxd3 Qd7 15. Qa6+ Qb7 16. Qxb7+ Bxb7 17. d6 Kb8 18. O-O Kc8 19. Rfd1 h5 20. h3 h4 21. Bh2 Nd7 22. Na4 f6 23. c5 b5 24. Nc3 e5 25. b4 a5 26. a3 axb4 27. axb4 Nb8 28. Ne4 Na6 29. Ra1 Nxb4 30. Rda3 Nc2 31. Ra8+ Bxa8 32. Rxa8+ Kb7 33. Rxd8 Nd4 34. Rd7+ Kc8 35. Rf7 1-0",
    B: "1415",
  },
  {
    W: "895",
    T: "300+0",
    P: "1. d4 d5 2. e3 Nc6 3. Bd3 Nf6 4. Nd2 e6 5. e4 g6 6. e5 Ng4 7. h3 f5 8. hxg4 fxg4 9. g3 Nxd4 10. c3 Nc6 11. Bb5 a6 12. Bxc6+ bxc6 13. c4 Bc5 14. Qe2 O-O 15. Rh2 h5 16. a3 dxc4 17. Nxc4 Rf7 18. b4 Bd4 19. Rb1 Qf8 20. Be3 Bxe5 21. Nxe5 Rf5 22. Nxg6 Qf6 23. Nh4 Re5 24. f3 gxf3 25. Ngxf3 Bb7 26. Nxe5 Qxe5 27. Qf2 Qc3+ 28. Bd2 Qe5+ 29. Qe2 Qxg3+ 30. Qf2 Qe5+ 31. Kd1 Rf8 32. Qg2+ Kh7 33. Qg6+ Kh8 34. Qh6+ Kg8 0-1",
    B: "998",
  },
  {
    W: "1998",
    T: "300+0",
    P: "1. e4 e6 2. d4 c6 3. Nf3 d6 4. Nc3 Nf6 5. Bd3 Be7 6. O-O O-O 7. Re1 Nbd7 8. e5 dxe5 9. dxe5 Nd5 10. Nxd5 cxd5 11. Bf4 a6 12. c3 b5 13. Rc1 Bb7 14. Bb1 Rc8 15. Qd3 g6 16. h4 Bxh4 17. Nxh4 Qxh4 18. g3 Qh5 19. Qe2 Qxe2 20. Rxe2 Nc5 21. f3 a5 22. Rh2 f5 23. Kf2 b4 24. cxb4 axb4 25. b3 Ba6 26. Rch1 Rf7 27. Ke3 Nd7 28. Rc2 Rff8 29. Rhc1 Rxc2 30. Rxc2 Rc8 31. Rxc8+ Bxc8 32. g4 Kg7 33. Kd4 h6 34. gxf5 gxf5 35. Bd3 h5 36. Bb5 h4 37. Bxd7 Bxd7 38. Kc5 h3 39. Kxb4 Kg6 40. a4 Bc6 41. Kc5 Ba8 42. Kd6 d4 43. Kxe6 d3 44. Kd7 d2 45. Bxd2 h2 46. e6 h1=Q 47. e7 Qh7 48. Kd8 Bc6 49. Bc3 Qg8+ 50. Kc7 Qe8 51. Bb4 Bxf3 52. a5 Be2 53. Kd6 f4 54. Ke5 f3 55. Bc5 f2 56. Bxf2 Qxe7+ 57. Kd5 Qd8+ 58. Kc6 Qxa5 59. Bc5 Qb5+ 60. Kd5 Qxb3+ 61. Kd6 Qc4 62. Kc6 Kf5 63. Kd6 Kf6 64. Kc6 Ke6 65. Kb6 Kd5 66. Kb7 Qxc5 67. Ka8 Ke6 68. Kb7 Bf3+ 69. Ka6 Be2+ 70. Kb7 Kd7 71. Ka8 Qa5+ 72. Kb7 Bf3+ 73. Kb8 Qb6# 0-1",
    B: "1942",
  },
  {
    W: "1630",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. d4 Bg4 4. dxe5 Bxf3 5. gxf3 Nc6 6. Bb5 Be7 7. Bxc6+ bxc6 8. Nc3 dxe5 9. Qxd8+ Rxd8 10. O-O Nf6 11. Bg5 O-O 12. Bxf6 Bxf6 13. Na4 Be7 14. Rfd1 Bd6 15. c4 c5 16. Nc3 c6 17. a3 Rb8 18. Rab1 f5 19. Rxd6 fxe4 20. Nxe4 Rxf3 21. Nxc5 Rf4 22. b3 e4 23. Rxc6 e3 24. fxe3 Rf3 25. e4 Re3 26. a4 Rf8 27. Ne6 Rff3 28. Rc8+ Kf7 29. Ng5+ Kg6 30. Nxf3 Rxf3 31. Kg2 1-0",
    B: "1570",
  },
  {
    W: "1546",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nc3 Nc6 4. Bb5 d6 5. O-O Bd7 6. a3 Be7 7. Re1 O-O 8. d3 a6 9. Bc4 Bg4 10. b4 Nd4 11. Re3 Nh5 12. Ne2 Bg5 13. Nxg5 Qxg5 14. Nxd4 exd4 15. Rg3 Qe5 16. Qxg4 Nf6 1-0",
    B: "1528",
  },
  {
    W: "1866",
    T: "300+0",
    P: "1. f4 e6 2. Nf3 d5 3. h3 Nf6 4. d3 c5 5. g4 Bd7 6. Bg2 Nc6 7. O-O Bd6 8. c3 Qc7 9. e3 O-O 10. a4 Rfe8 11. Na3 a6 12. Nc2 e5 13. fxe5 Nxe5 14. d4 Nxf3+ 15. Bxf3 Bc6 16. dxc5 Bxc5 17. Nd4 Bd6 18. b4 Bh2+ 19. Kh1 Ne4 20. Bxe4 dxe4 21. Qe2 Bg3 22. Rf3 exf3 23. Qg2 fxg2+ 24. Kg1 Bh2+ 0-1",
    B: "1877",
  },
  {
    W: "1727",
    T: "300+0",
    P: "1. e4 b6 2. f4 Bb7 3. d3 e6 4. Nf3 c5 5. a3 d5 6. e5 f6 7. d4 cxd4 8. Nxd4 Nc6 9. Bb5 Rc8 10. Nxe6 Qe7 11. Nxf8 Qxf8 12. exf6 Nxf6 13. Qe2+ Ne4 14. Bd3 Nd4 15. Qh5+ g6 16. Qe5+ Ne6 17. Qxe6+ Kd8 18. Bxe4 dxe4 19. c3 Qc5 20. Qf6+ Qe7 21. Qxh8+ Kc7 22. Qe5+ Qxe5 23. fxe5 Re8 24. Bf4 Kc6 25. O-O g5 26. Bg3 h5 27. h3 h4 28. Bh2 Kd7 29. Nd2 Ke6 30. Rae1 Rg8 31. Nxe4 g4 32. hxg4 Rxg4 33. Nf6 Rxg2+ 34. Kh1 Rxb2+ 35. Kg1 Rg2+ 36. Kh1 Rg5+ 37. Ne4 Rxe5 38. Rf6+ Ke7 39. Bxe5 1-0",
    B: "1701",
  },
  {
    W: "1351",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Bc4 h6 4. Nc3 c6 5. O-O d5 6. exd5 cxd5 7. Bb3 d4 8. Nxe5 Be6 9. Nb5 Nc6 10. Nxc6 bxc6 11. Na3 Bxb3 12. axb3 c5 13. Nc4 Qd5 14. c3 Bd6 15. Nxd6+ Qxd6 16. cxd4 cxd4 17. d3 O-O 18. Bd2 Rae8 19. Rxa7 Re5 20. Bf4 Qc5 21. Bxe5 Qxa7 22. Bxf6 g6 23. Qg4 Qb6 24. Qxd4 Rd8 25. Bxd8 1-0",
    B: "1345",
  },
  {
    W: "1274",
    T: "300+0",
    P: "1. d4 d5 2. Nc3 Nc6 3. Nf3 f6 4. Bf4 g5 5. Bg3 Nh6 6. h3 Nf5 7. Bh2 h5 8. e3 g4 9. Nd2 Nd6 10. hxg4 Bxg4 11. Be2 Be6 12. Bxd6 Qxd6 13. Bxh5+ Kd7 14. Be8+ Rxe8 15. Rxh8 Bf7 16. Qg4+ e6 17. O-O-O f5 18. Qg3 Qb4 19. a3 Qb6 20. Nf3 Bd6 21. Qh3 Re7 22. Ng5 e5 23. Rh7 Be8 24. Qxf5+ Kd8 25. Nf7+ Bxf7 26. Rxf7 Rxf7 27. Qxf7 Be7 28. Rh1 Qa6 29. Rh8+ Kd7 30. Qf5+ Kd6 31. Rh6+ Bf6 32. Rxf6+ Ke7 33. Qe6+ Kd8 34. Qg8+ Kd7 35. Qg7+ Ne7 36. Qg4+ Ke8 37. Qh5+ Kd7 38. Qg4+ Ke8 39. Qh5+ Kd7 40. Qg4+ 1/2-1/2",
    B: "1345",
  },
  {
    W: "1851",
    T: "300+0",
    P: "1. c4 Nf6 2. Nc3 e6 3. g3 Bc5 4. Bg2 c6 5. Nf3 d6 6. O-O O-O 7. d4 Bb6 8. a3 a5 9. Re1 d5 10. c5 Bc7 11. Nd2 e5 12. dxe5 Bxe5 13. f4 Bd4+ 14. Kh1 Bxc5 15. e4 d4 16. Na4 Ba7 17. e5 Ng4 18. Ne4 Bf5 19. Nd6 Nf2+ 20. Kg1 Nxd1 21. Rxd1 d3+ 22. Kh1 Bg4 23. Rf1 Be2 24. Re1 Na6 25. Nxb7 Qd4 26. Nc3 Qf2 27. Bd2 Bf3 28. Bxf3 Qxf3# 0-1",
    B: "1735",
  },
  {
    W: "1439",
    T: "300+0",
    P: "1. e4 d5 2. Nf3 dxe4 3. Ng5 Nf6 4. d3 h6 5. Nxe4 Nxe4 6. dxe4 Qxd1+ 7. Kxd1 Nc6 8. Bd3 Bg4+ 9. f3 Bh5 10. Nc3 O-O-O 11. Nd5 e6 12. Nf4 g6 13. Nxh5 gxh5 14. Ke2 Bg7 15. c3 Rhg8 16. Be3 Ne5 17. Bc2 Nc4 18. Bxa7 b6 19. Bxb6 Nxb6 20. Rad1 Be5 21. Ke3 Rxg2 22. Bb3 Bxh2 23. f4 h4 24. Kf3 Rg3+ 25. Kf2 Rxd1 26. Rxd1 Rg4 27. Rh1 Bg3+ 28. Kf3 Rxf4+ 29. Ke3 Nd7 30. Rg1 Nc5 31. Rxg3 Rxe4+ 32. Kf3 hxg3 33. Kxg3 Nxb3 34. axb3 f5 35. Kf3 h5 36. b4 h4 37. c4 h3 38. Kg3 Rxc4 39. Kxh3 Rxb4 40. Kg3 Rxb2 41. Kf4 Kd7 42. Kf3 c5 43. Ke3 Kd6 44. Kd3 Kd5 45. Kc3 Rb4 46. Kd3 Rd4+ 47. Ke3 1/2-1/2",
    B: "1422",
  },
  {
    W: "1454",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 f6 4. c3 Nge7 5. d4 d5 6. exd5 Nxd5 7. dxe5 Be6 8. exf6 gxf6 9. O-O Bd6 10. Re1 Nf4 11. Bxf4 Bxf4 12. Rxe6+ Ne7 13. Nbd2 Rg8 14. Qa4+ c6 15. Rae1 Rg7 16. Ne4 b5 17. Nxf6+ Kf8 18. Bxb5 cxb5 19. Qxb5 Rb8 20. Nd7+ Ke8 21. Nxb8+ Kf7 22. Qh5+ Rg6 23. Rxe7+ Kf8 24. Re8+ Qxe8 25. Rxe8+ Kxe8 26. Qxh7 Rd6 27. g3 Rd1+ 28. Kg2 Bxb8 29. Qg8+ Ke7 30. Qxb8 1-0",
    B: "1453",
  },
  {
    W: "1149",
    T: "300+0",
    P: "1. d4 d5 2. c4 dxc4 3. e3 Nc6 4. Bxc4 e5 5. d5 Nb4 6. Qa4+ b5 7. Bxb5+ Bd7 8. Bxd7+ Qxd7 9. Qxd7+ Kxd7 10. Kd2 Nxd5 11. Nc3 Nxc3 12. bxc3 Rd8 13. Ke2 Nf6 14. Nf3 Ne4 15. Rd1+ Ke8 16. Rxd8+ Kxd8 17. Nxe5 Nxc3+ 18. Kd2 Ne4+ 19. Kd3 Nxf2+ 20. Ke2 Ne4 21. Nxf7+ Kc8 22. Nxh8 Nc3+ 23. Kd3 Nd5 24. Kd4 Nb4 25. Ba3 Nc2+ 0-1",
    B: "1141",
  },
  {
    W: "1860",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. Nc3 Nf6 5. d3 d6 6. Bg5 h6 7. Bh4 g5 8. Bg3 Ng4 9. h3 Nf6 10. Qd2 a6 11. O-O-O 1-0",
    B: "1660",
  },
  {
    W: "1082",
    T: "300+0",
    P: "1. d3 h5 2. Bf4 1-0",
    B: "1013",
  },
  {
    W: "1296",
    T: "60+0",
    P: "1. d4 Nf6 2. c4 d5 3. e3 dxc4 4. Bxc4 e6 5. Nc3 Bb4 6. Bd2 Nc6 7. Nf3 a6 8. a3 b5 9. axb4 bxc4 10. O-O Nxb4 11. Qe2 Nd3 12. Ne1 O-O 13. Nxd3 cxd3 14. Qxd3 Bb7 15. e4 Nxe4 16. Nxe4 f5 17. Ng3 Be4 18. Qe2 Bd5 19. Bc3 Qg5 20. Qe5 g6 21. Rfe1 h5 22. Ne4 Bxe4 23. Rxe4 h4 24. d5 h3 25. Qg7# 1-0",
    B: "1287",
  },
  {
    W: "1894",
    T: "60+0",
    P: "1. d4 c5 2. e3 cxd4 3. exd4 Nc6 4. Nf3 d5 5. Bd3 Bg4 6. Nbd2 Nf6 7. b3 e6 8. Bb2 Nb4 9. O-O Nxd3 10. cxd3 Bd6 11. Qe1 O-O 12. Ne5 Bf5 13. f4 Bxe5 14. fxe5 Bxd3 15. Rf3 Be4 16. Nxe4 Nxe4 17. Rh3 h6 18. Qe3 Qg5 19. Qxg5 Nxg5 20. Rg3 Ne4 21. Rg4 Rfc8 22. Rf1 Rc2 23. Ba3 Rac8 24. Rgf4 R8c7 25. Bd6 Rd7 26. h3 Rxa2 27. Rxe4 dxe4 28. Re1 f6 29. b4 fxe5 30. dxe5 Ra4 31. Rxe4 a5 32. Rf4 axb4 33. Rf8+ Kh7 34. Rf2 b3 35. Bf8 Ra2 36. Rf1 b2 37. Rb1 Ra1 38. Kh2 Rxb1 39. Bxg7 Rc1 40. Bf6 b1=Q 41. Kg3 Qb6 42. Bg5 Qe3+ 43. Bxe3 Re1 44. Bf2 Rd6 45. Bxe1 Rd3+ 0-1",
    B: "1839",
  },
  {
    W: "1428",
    T: "60+0",
    P: "1. e4 Nc6 2. Bc4 e5 3. Nf3 d6 4. d4 Bg4 5. dxe5 Bxf3 6. Qxf3 f6 7. exf6 Qxf6 8. Qh5+ g6 9. Qd5 O-O-O 10. O-O Qe5 11. Nc3 Qxd5 12. exd5 Ne5 13. Bb3 Nf6 14. Bg5 Bg7 15. Ne4 Rdf8 16. Bxf6 Bxf6 17. Nxf6 Rf7 18. c4 Rxf6 19. Rae1 Nd3 20. Rb1 Rhf8 21. Bc2 Nxf2 22. Rxf2 Rxf2 23. Bb3 a5 24. a4 b6 25. Ba2 Kb7 26. h3 c6 27. Kh2 cxd5 28. cxd5 R8f5 0-1",
    B: "1494",
  },
  {
    W: "1599",
    T: "60+0",
    P: "1. d4 d5 2. c4 Nf6 3. Nc3 e6 4. Bg5 Be7 5. Bxf6 Bxf6 6. cxd5 exd5 7. e3 O-O 8. Bd3 Nc6 9. Nge2 Nb4 10. Bb1 Bf5 11. a3 Bxb1 12. Qxb1 Nc6 13. b4 Ne7 14. O-O Ng6 15. Qa2 c6 16. Rab1 Qc7 17. Rfc1 Rae8 18. Nxd5 Qd6 19. Nxf6+ Qxf6 20. b5 cxb5 21. Rxb5 b6 22. a4 Ne7 23. Rcb1 Nc6 24. a5 Nxa5 25. Nf4 Rc8 26. Nd5 Qe6 27. Nf4 Qxa2 0-1",
    B: "1635",
  },
  {
    W: "1951",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 a6 6. a3 g6 7. Bf4 Bg7 8. Nf3 Nge7 9. Bc4 O-O 10. h3 b5 11. O-O Bb7 12. Bb3 Qb6 13. Re1 Rad8 14. Qe2 Nd4 15. Nxd4 Bxd4 16. Bd2 d5 17. exd5 Nxd5 18. Nxd5 Bxd5 19. Rad1 Bxb2 20. Bxd5 Rxd5 21. Bb4 Rfd8 22. c3 Rxd1 23. Rxd1 Rxd1+ 24. Qxd1 a5 25. Qc2 axb4 26. axb4 Bxc3 27. Qxc3 Qd6 28. Kf1 Kf8 29. Ke2 Ke7 30. g3 h5 31. h4 e5 32. f3 Qd4 33. Qc7+ Qd7 34. Qc5+ Qd6 35. Qxb5 e4 36. Qb7+ Ke6 37. Qxe4+ Qe5 38. Ke3 Qxe4+ 39. Kxe4 Kd6 40. Kd4 f5 41. f4 Kc6 42. Kc4 Kb6 43. b5 Kc7 44. Kc5 Kb7 45. b6 Kb8 46. Kd6 Kb7 47. Ke6 Kxb6 48. Kf6 Kc6 49. Kxg6 Kd6 50. Kxf5 Ke7 51. Kg5 Kf7 52. f5 Kg7 53. Kxh5 1-0",
    B: "1894",
  },
  {
    W: "1710",
    T: "60+0",
    P: "1. d4 g6 2. c4 Bg7 3. Nf3 e6 4. e3 d5 5. Be2 Nf6 6. O-O O-O 7. h3 Nbd7 8. Re1 c5 9. cxd5 exd5 10. Nc3 cxd4 11. Nxd4 b6 12. Qb3 Bb7 13. Bf3 Nc5 14. Qb4 Nd3 15. Qxf8+ Kxf8 16. Rf1 Nxc1 17. Raxc1 Ne4 18. Nxe4 dxe4 19. Bg4 Bxd4 20. Rfd1 Qf6 21. Rxd4 Kg7 22. Rc7 Ba6 23. Rdd7 Bc4 24. Rxc4 Re8 25. Rcc7 Rf8 26. Be2 Qxb2 27. Bg4 Qb1+ 28. Kh2 Qxa2 29. Rxa7 Qb2 0-1",
    B: "1827",
  },
  {
    W: "1958",
    T: "60+0",
    P: "1. e4 e6 2. d4 d6 3. Nc3 Be7 4. f4 Nd7 5. Nf3 Ngf6 6. Be2 c5 7. O-O cxd4 8. Nxd4 O-O 9. Kh1 e5 10. Nf5 exf4 11. Nxe7+ Qxe7 12. Bxf4 Ne5 13. Qe1 h6 14. Qf2 Ng6 15. Bxh6 gxh6 16. Qxf6 Qxf6 17. Rxf6 Ne5 18. Rxd6 Kh7 19. Nd5 Be6 20. Nf6+ Kg7 21. Nh5+ Kh7 22. Rf1 Bg4 23. Nf6+ Kg7 24. Nxg4 Nxg4 25. Bxg4 Rfe8 26. Bf5 f6 27. Rf3 Re7 28. Rg3+ Kf7 29. Bg6+ Kf8 30. Bf5 Rc7 31. Rxf6+ Ke7 32. e5 Rxc2 33. Bxc2 Rc8 34. Rg7+ Ke8 35. Bg6+ Kd8 36. Rf8# 1-0",
    B: "1973",
  },
  {
    W: "1556",
    T: "60+0",
    P: "1. b3 Nf6 2. Bb2 g6 3. h3 Bg7 4. e3 O-O 5. Bxf6 Re8 6. Bxg7 Kxg7 7. Nf3 d6 8. Ng5 Bf5 9. g4 f6 10. gxf5 fxg5 11. fxg6 hxg6 12. Bd3 Rf8 13. Qg4 Qd7 14. Qxg5 Qe6 15. Nc3 Qf6 16. Qg4 Nd7 17. Ne4 Qf7 18. Ng5 Nf6 19. Qh4 Qg8 20. O-O Nh7 21. f4 Nxg5 22. Qxg5 Qh8 23. f5 Qh5 24. Qxg6+ Qxg6+ 25. fxg6 Rxf1+ 26. Rxf1 Rg8 27. Rf7+ Kh8 28. g7+ Rxg7+ 29. Rxg7 Kxg7 30. h4 Kf6 31. h5 Kg7 32. Kg2 Kh6 33. Kh3 Kxh5 34. e4 Kh6 35. Kg4 Kg7 36. Kf5 Kf7 37. e5 c6 38. exd6 exd6 39. Ke4 Ke7 40. Bc4 Kd7 41. Kf5 Kc7 42. d3 Kb6 0-1",
    B: "1562",
  },
  {
    W: "2276",
    T: "30+0",
    P: "1. d4 c5 2. Nc3 cxd4 3. Qxd4 Nc6 4. e4 Nf6 5. Qd3 d6 6. f4 g6 7. Nf3 Bg7 8. Be3 O-O 9. O-O-O Be6 10. a3 Rc8 11. Ng5 Bd7 12. Bd2 a6 13. h3 b5 14. g4 b4 15. Nd5 bxa3 16. Qxa3 Nxd5 17. exd5 Nd4 18. Bc3 Nb5 19. Qa5 Nxc3 20. Qxd8 Rfxd8 21. bxc3 Bxc3 22. Rd3 Rc5 23. Bg2 Rb8 24. Kd1 Rb1+ 25. Ke2 Rb2 26. Rc1 Ba4 27. Ne4 Rc8 28. Bf3 Bb5 29. f5 Bxd3+ 30. Kxd3 Bg7 31. fxg6 hxg6 32. g5 Rbb8 33. h4 Rc7 34. c3 Rbc8 35. c4 Rxc4 36. Rxc4 Rb8 37. Nxd6 Rb2 38. Nxf7 Kh7 39. Rc8 Rf2 40. Be4 Rf3+ 41. Bxf3 1-0",
    B: "2588",
  },
  {
    W: "1839",
    T: "15+0",
    P: "1. b3 g6 2. Bb2 Bg7 3. Bxg7 Nf6 4. Bxh8 Kf8 5. Bxf6 exf6 6. c3 Kg7 7. e3 c6 8. Be2 d5 9. Bd3 d4 10. Bc2 dxe3 11. dxe3 Qe8 12. b4 Qf8 13. Ba4 Be6 14. Bb3 Bd7 15. Bxf7 Qxf7 16. Nf3 Be6 17. Nd4 Nd7 18. Nxe6+ Qxe6 19. O-O Rd8 20. Qe2 Ne5 21. Rd1 Re8 22. Rd6 Kh6 23. Rxe6 Rxe6 24. Nd2 Ng4 25. Ne4 Ne5 26. Nxf6 Rxf6 27. Qg4 Nf7 28. Qh3+ Kg7 29. g4 Ng5 30. Qf3 Nxf3+ 31. Kg2 Ng5 32. Kg3 Rf3+ 0-1",
    B: "1909",
  },
  {
    W: "1567",
    T: "15+0",
    P: "1. e3 e5 2. d4 Bc5 3. dxc5 e4 4. Qh5 d6 5. Bc4 Be6 6. Qxf7+ Bxf7 7. Bxf7+ Kxf7 8. cxd6 Qxd6 9. Ne2 Nd7 10. Nf4 Rd8 11. O-O Nc5 12. Nd5 Qxd5 13. Nc3 Qd7 14. Nxe4 Nxe4 15. f4 Nd2 16. f5 Nxf1 17. f6 1-0",
    B: "1404",
  },
  {
    W: "2166",
    T: "15+0",
    P: "1. Nf3 a5 2. g3 h5 3. Bg2 d6 4. O-O Bd7 5. e3 Nc6 6. h3 Ne5 7. Kh2 Nxf3+ 8. Bxf3 Rb8 9. Bg2 Bxh3 10. f3 Bxg2 11. Kxg2 h4 12. Kf2 hxg3+ 13. Ke2 Rh6 14. Rg1 Rf6 15. Rg2 e5 16. Qg1 e4 17. f4 Rf5 18. Rxg3 Rf6 19. Qg2 d5 20. Rg4 Qe7 21. d3 Nh6 22. dxe4 Nxg4 23. Nc3 dxe4 24. Nxe4 Nxe3 25. Nxf6+ Qxf6 26. Kf2 Qe7 27. Bxe3 Kd7 28. Re1 Qd6 29. Qg4+ Qe6 30. Qf3 Bd6 31. Bc1 Re8 32. Rd1 Qe1+ 33. Kg2 Re2+ 34. Qxe2 Qxe2+ 35. Kg3 a4 36. Re1 Qxe1+ 37. Kg4 a3 38. Be3 f5+ 39. Kh3 Qxe3+ 40. Kh4 axb2 41. Kg5 b1=Q 42. Kh5 Qxc2 43. Kg6 Qxf4 44. Kxg7 Qg5+ 45. Kh8 f4 0-1",
    B: "2088",
  },
  {
    W: "2073",
    T: "180+3",
    P: "1. d4 Nf6 2. e3 b6 3. Bd3 Bb7 4. Nf3 e6 5. c3 h6 6. Qc2 c5 7. b3 Nc6 8. Bb2 Rc8 9. Qd2 Qc7 10. c4 d5 11. dxc5 Bxc5 12. cxd5 Nxd5 13. Bb5 O-O 14. Bxc6 Qxc6 15. O-O Rfd8 16. Qe2 Nb4 17. Na3 Ba6 18. Nc4 Qe4 19. Rad1 Nd3 20. Ba1 Bxc4 21. bxc4 Qxc4 22. Nd4 Ne5 23. Qh5 Ng6 24. f4 Rd5 25. f5 Bxd4 26. exd4 Qb5 27. Rc1 Rxc1 28. Rxc1 Rxf5 29. Qd1 Qd5 30. Rc8+ Kh7 31. Rc7 a5 32. Qc2 b5 33. Rc5 Qd7 34. Rc7 Qd6 35. Rc5 Rd5 36. g3 a4 37. Rc6 Qd8 38. Rc8 Qg5 39. Rc7 Qf6 40. Rc5 Qf3 41. Rxd5 Qxd5 42. Qc5 Qxc5 43. dxc5 Ne7 44. Be5 Nc6 45. Kf2 Nxe5 46. Ke3 Nc6 0-1",
    B: "2454",
  },
  {
    W: "2007",
    T: "180+3",
    P: "1. d4 d5 2. e3 Nf6 3. Bd3 e6 4. c3 b6 5. Nf3 Bb7 6. O-O Bd6 7. Re1 O-O 8. h3 Nbd7 9. Nbd2 Rc8 10. a4 c5 11. a5 Qe7 12. axb6 axb6 13. Nf1 Ra8 14. Bd2 Ne4 15. Bxe4 dxe4 16. N3h2 f5 17. Qc2 Rfc8 18. dxc5 Nxc5 19. Rxa8 Rxa8 20. Bc1 Nd3 21. Rd1 Ba6 22. c4 Rc8 23. b3 b5 24. f3 bxc4 25. bxc4 Bxc4 26. Bb2 Qb7 27. Ba1 Bb3 28. Qe2 Bxd1 29. Qxd1 Rc1 0-1",
    B: "2020",
  },
  {
    W: "1552",
    T: "180+3",
    P: "1. e4 e6 2. d3 d5 3. exd5 exd5 4. d4 Bd6 5. Nc3 c6 6. Nf3 Nf6 7. g3 O-O 8. Bg2 h6 9. O-O Bf5 10. Ne2 Qc7 11. Nf4 Nbd7 12. Re1 Rfe8 13. Bd2 Rxe1+ 14. Qxe1 Bxc2 15. Bh3 Ne4 16. Bxd7 Qxd7 17. Ne5 Bxe5 18. dxe5 g5 19. Nh5 Qh3 20. Nf6+ Nxf6 21. exf6 Be4 22. Qf1 Qf5 23. Bc3 Qf3 24. Re1 Qh1# 0-1",
    B: "1807",
  },
  {
    W: "1941",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bc4 g6 4. Nc3 Bg7 5. h4 a6 6. a4 Qc7 7. d3 e6 8. h5 Nge7 9. h6 Bf6 10. Qe2 O-O 11. g3 Ne5 12. Nxe5 Qxe5 13. Bf4 Qd4 14. O-O-O d5 15. e5 Bh8 16. Be3 Qxe5 17. f4 Qc7 18. Bb3 d4 19. Ne4 dxe3 20. Qxe3 Nf5 21. Qf2 Nd4 22. Nd2 b5 23. Rde1 Nxb3+ 24. Nxb3 bxa4 25. Nd2 Bb7 26. Nc4 Bxh1 27. Rxh1 Qxf4+ 0-1",
    B: "1921",
  },
  {
    W: "2249",
    T: "180+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. f4 g6 4. Nf3 Bg7 5. Bc4 e6 6. f5 gxf5 7. d3 a6 8. O-O b5 9. Bb3 c4 10. dxc4 Qb6+ 11. Kh1 Bxc3 12. bxc3 fxe4 13. Ng5 f6 14. Qh5+ Ke7 15. Nf7 bxc4 16. Ba3+ 1-0",
    B: "2205",
  },
  {
    W: "2105",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 c5 3. d5 e5 4. Nc3 d6 5. e4 g6 6. Bd3 Nbd7 7. f4 exf4 8. Nf3 Qe7 9. Bxf4 Nh5 10. Bg5 f6 11. Be3 a6 12. O-O Ne5 13. Nxe5 fxe5 14. Qf3 Bg7 15. Bc2 Bd7 16. g4 Nf6 17. Bg5 O-O 18. h3 h6 19. Bxf6 Rxf6 20. Qg3 Raf8 21. Rxf6 Qxf6 22. Bd3 Qf3 23. Qxf3 Rxf3 24. Bf1 h5 25. gxh5 gxh5 26. Bg2 Re3 27. Kf2 Bh6 28. Rg1 Kh7 29. h4 b5 30. cxb5 axb5 31. a3 b4 32. axb4 cxb4 33. Ne2 Rb3 34. Rb1 Bb5 35. Nc1 Re3 36. Bf3 b3 37. Ra1 Kg6 38. Ra5 Bc4 39. Ra4 Bd3 40. Nxb3 Bc2 0-1",
    B: "2077",
  },
  {
    W: "1635",
    T: "180+0",
    P: "1. c4 e6 2. Nc3 Bc5 3. Nf3 h6 4. e3 g5 5. d4 Bb6 6. h3 c5 7. a3 cxd4 8. exd4 Nf6 9. b4 a5 10. c5 Bc7 11. b5 b6 12. a4 bxc5 13. dxc5 Bb7 14. Be2 O-O 15. O-O Qe7 16. Be3 e5 17. Nh2 e4 18. Ng4 Rd8 19. Qc2 Kg7 20. Rab1 Nxg4 21. Bxg4 Qe5 22. g3 f5 23. Bh5 Rg8 24. Rbd1 Kh8 25. Bd4 g4 26. Bxe5+ Bxe5 27. Bf7 Rg7 28. Bd5 Bxd5 29. Nxd5 gxh3 30. Kh2 d6 31. cxd6 Nd7 32. Ne3 Rag8 33. Nxf5 Rg5 34. Qxe4 Nf6 35. Qxe5 Kh7 36. Qe7+ R5g7 37. Nxg7 Rxg7 38. Qxf6 h5 39. Qf5+ Kh8 40. d7 1-0",
    B: "1646",
  },
  {
    W: "2177",
    T: "180+0",
    P: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. h4 h6 7. Nf3 Nd7 8. h5 Bh7 9. Bd3 Bxd3 10. Qxd3 e6 11. Bd2 Bd6 12. Ne4 Bc7 13. Bb4 Ngf6 14. Nd6+ Bxd6 15. Bxd6 Nb6 16. Ba3 Qd5 17. O-O O-O-O 18. c3 Qc4 19. Qd2 Nfd7 20. Qf4 Qd5 21. Rfe1 f6 22. Qe4 Qxe4 23. Rxe4 Rhe8 24. Rae1 e5 25. dxe5 Nxe5 26. Nxe5 fxe5 27. Rxe5 Rxe5 28. Rxe5 Rd2 29. Re7 Nc4 30. Rxg7 Nxa3 31. bxa3 Rxa2 32. Rh7 Rxa3 33. Rxh6 Rxc3 34. Rg6 Rc1+ 35. Kh2 Rc5 36. g4 a5 37. h6 Rd5 38. h7 Rd8 39. Rg8 a4 40. Rxd8+ Kxd8 41. h8=Q+ Kc7 1-0",
    B: "2142",
  },
  {
    W: "1942",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Nb5 d6 6. Be3 a6 7. N5c3 Nf6 8. Nd5 Nxd5 9. exd5 Ne7 10. c4 f5 11. Nc3 f4 12. Bd2 Bf5 13. f3 Rc8 14. Rc1 Qb6 15. Qb3 Qxb3 16. axb3 Ng6 17. Ne4 Be7 18. Bd3 O-O 19. O-O h6 20. Rfe1 Rfd8 21. Red1 Rd7 22. Bb1 Bd8 23. Kh1 Bb6 24. b4 Bd4 25. Nxd6 Rxd6 26. Bxf5 Rcd8 27. Bxg6 Rxg6 28. Bc3 Be3 29. Bd2 Bxd2 30. Rxd2 Rgd6 31. Rcd1 Kf7 32. Kg1 Kf6 33. c5 R6d7 34. Kf2 g5 35. h3 Kf5 36. d6 h5 37. Re1 b6 38. Rd5 Re8 39. c6 Rf7 40. d7 Rd8 41. Rexe5+ Kg6 42. Re8 Rf8 43. Rxd8 Rxd8 44. c7 1-0",
    B: "1953",
  },
  {
    W: "2139",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. d4 c6 4. Nc3 Qa5 5. Bd3 Nf6 6. Ne2 Bg4 7. O-O e6 8. f3 Bh5 9. Nf4 Bg6 10. Nxg6 hxg6 11. Bf4 Bb4 12. Ne4 Nbd7 13. a3 Be7 14. Ng5 Qb6 15. c3 Qd8 16. Bxg6 fxg6 17. Nxe6 Qa5 18. Nc7+ Kf7 19. Qb3+ Nd5 20. Nxa8 Rxa8 21. Bd2 Qc7 22. Rae1 Bd6 23. h3 Kf8 24. f4 Kg8 25. Re2 Kh7 26. Ref2 N5f6 27. Qc2 Nf8 28. f5 Bg3 29. fxg6+ Nxg6 30. Rxf6 gxf6 31. Rxf6 Qg7 32. Qf5 Rg8 33. Rf7 Bc7 34. Qh5# 1-0",
    B: "2111",
  },
  {
    W: "1757",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. d4 Nc6 4. Nf3 h6 5. c3 Bg4 6. Nbd2 O-O-O 7. Be2 Kb8 8. O-O h5 9. h3 f5 10. hxg4 hxg4 11. Nh2 Qe6 12. f3 g3 13. Ng4 fxg4 14. fxg4 Qh6 0-1",
    B: "1762",
  },
  {
    W: "2006",
    T: "180+0",
    P: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. Be2 O-O 6. Be3 c6 7. h3 b5 8. a3 a5 9. O-O Nbd7 10. Qd2 Rb8 11. Rab1 b4 12. axb4 axb4 13. Nd1 Nxe4 14. Qd3 d5 15. Nd2 Ndf6 16. Bf3 Qb6 17. Nxe4 Nxe4 18. Bxe4 Ba6 19. c4 Bxc4 20. Qc2 b3 21. Qd2 Bxf1 22. Bd3 Bxd3 23. Qxd3 Qb5 24. Qd2 Qb4 25. Nc3 e6 26. Bh6 Qxd4 27. Qxd4 Bxd4 28. Bxf8 Kxf8 29. Ne2 Bf6 30. Kf1 c5 31. Ke1 c4 32. Kd2 d4 33. Rc1 Rc8 34. f4 g5 35. g4 gxf4 36. Rf1 e5 37. Nc1 c3+ 38. bxc3 dxc3+ 39. Kd3 b2 40. Ne2 Kg7 41. Kc2 Bg5 42. Rd1 f3 43. Nxc3 Bc1 44. Kb1 Rxc3 45. Rf1 e4 46. h4 Kg6 47. Re1 f2 48. Rf1 e3 49. h5+ Kg5 50. Rh1 e2 51. h6 e1=Q 52. Rxe1 fxe1=Q 53. Ka2 Rc5 0-1",
    B: "2033",
  },
  {
    W: "1678",
    T: "180+0",
    P: "1. Nf3 d5 2. c4 g6 3. g3 dxc4 4. Bg2 Bg7 5. O-O e6 6. Nc3 Nf6 7. Ne5 Nbd7 8. Nxc4 O-O 9. d4 c6 10. e4 Nb6 11. Ne3 a6 12. d5 exd5 13. exd5 Nbxd5 14. Nexd5 Nxd5 15. Nxd5 cxd5 16. Qxd5 Qxd5 17. Bxd5 Bh3 18. Re1 Rab8 19. Rb1 Bf5 20. Bf4 Bxb1 21. Bxb8 Rxb8 22. Rxb1 Rd8 23. Bb3 Rd2 24. Rd1 Rxb2 25. Rd8+ Bf8 26. Rd7 Rb1+ 27. Kg2 b5 28. Rxf7 Rxb3 29. Rxf8+ Kxf8 30. axb3 Ke7 31. Kf3 Kd6 32. Ke4 Kc5 33. Kd3 Kb4 34. Kc2 a5 35. f4 a4 36. bxa4 bxa4 37. h4 Kc4 38. g4 Kd4 39. f5 gxf5 40. g5 Ke5 41. h5 f4 42. g6 hxg6 43. h6 Kf6 44. h7 Kg7 45. h8=Q+ Kxh8 0-1",
    B: "1681",
  },
  {
    W: "2045",
    T: "180+0",
    P: "1. e4 g6 2. d4 Bg7 3. Nf3 c6 4. Nc3 d5 5. h3 h5 6. Bg5 Qb6 7. Qd2 e6 8. O-O-O Bh6 9. g4 hxg4 10. hxg4 Bxg5 0-1",
    B: "2051",
  },
  {
    W: "1444",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Bg4 4. O-O Nf6 5. Re1 h5 6. h3 Bxf3 7. Qxf3 Nc6 8. c3 Qd7 9. d4 O-O-O 10. d5 Ne7 11. Bg5 c6 12. Nd2 cxd5 13. Bxd5 Nexd5 14. exd5 Be7 15. Ne4 Nxd5 16. Bxe7 Qxe7 17. c4 Nf6 18. b3 Nxe4 19. Qxe4 g5 20. Rac1 Rd7 21. c5 dxc5 22. b4 Rc7 23. a3 cxb4 24. axb4 Rxc1 25. Rxc1+ Kb8 26. Qc4 Qd7 27. Kf1 g4 28. Ke2 gxh3 29. gxh3 Rc8 30. Qxc8+ Qxc8 31. Rxc8+ Kxc8 32. Ke3 Kc7 33. Ke4 f6 34. f3 Kd6 35. Kf5 Ke7 36. b5 a6 37. bxa6 bxa6 38. h4 a5 39. Kg6 a4 40. Kxh5 Kf7 41. Kg4 Kg6 42. h5+ Kh6 43. Kf5 Kxh5 44. Kxf6 a3 45. Kxe5 a2 46. f4 a1=Q+ 47. Ke4 Qa5 48. f5 Kh6 49. Kf4 Kg7 50. Kg5 Kf7 51. Kf4 Kf6 52. Kg4 Qxf5+ 53. Kh4 Qf1 54. Kg3 Kg6 55. Kg4 Qf5+ 56. Kg3 Kf6 57. Kg2 Ke6 58. Kg3 Kf6 59. Kg2 Kg6 60. Kg3 Kh6 61. Kg2 Kg6 62. Kg3 1/2-1/2",
    B: "1388",
  },
  {
    W: "2059",
    T: "180+0",
    P: "1. e3 e5 2. Be2 d5 3. d3 f5 4. h3 Nf6 5. Nd2 Bd6 6. g4 O-O 7. g5 Ne8 8. h4 g6 9. e4 fxe4 10. dxe4 d4 11. Bc4+ Kh8 12. h5 Qxg5 13. Ndf3 Qg4 14. Rh4 Qg2 15. Rh2 Qg4 16. Rh4 Qg2 17. Bf4 Rxf4 18. Rxf4 exf4 19. hxg6 Qxg6 20. Qxd4+ Ng7 21. e5 Bf8 22. Qd8 Nd7 23. O-O-O Qe8 24. Qh4 Nxe5 25. Ng5 Qg6 26. Rd8 Nd7 27. Nf7+ Kg8 28. Ne5+ Kh8 29. Nxg6# 1-0",
    B: "2052",
  },
  {
    W: "1905",
    T: "180+0",
    P: "1. d4 d5 2. Bf4 c6 3. e3 Nf6 4. c3 Bf5 5. Bd3 e6 6. Bxf5 exf5 7. Nf3 Bd6 8. Bg3 Bxg3 9. hxg3 Ne4 10. Nbd2 Nd7 11. Nh4 g6 12. g4 fxg4 13. Qxg4 Ndf6 14. Qe2 Qb6 15. Nxe4 Nxe4 16. Nf3 h5 17. a3 Qb3 18. O-O h4 19. a4 h3 20. g3 Qb6 21. Ne5 Qc7 22. f3 Nxg3 23. Qf2 h2+ 24. Kg2 Nxf1 25. Rxf1 Qe7 26. Qg3 O-O-O 27. Rh1 f6 28. Ng4 f5 29. Nxh2 Qxe3 30. Qf2 Qg5+ 31. Qg3 Qd2+ 32. Qf2 Qxf2+ 33. Kxf2 Rde8 34. Nf1 Rxh1 35. Ne3 1-0",
    B: "1914",
  },
  {
    W: "1985",
    T: "180+0",
    P: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f3 Bg7 5. Be3 O-O 6. Qd2 Nc6 7. O-O-O Bd7 8. Bc4 e5 9. d5 Ne7 10. h4 h5 11. Nh3 Bxh3 12. gxh3 Qd7 13. f4 a6 14. f5 gxf5 15. exf5 Nxf5 16. Bg5 b5 17. Bb3 b4 18. Na4 Ne4 19. Qg2 Nxg5 20. Qxg5 f6 21. Qxh5 Ng3 22. Qg6 Nxh1 23. Rxh1 Qf7 24. Qg2 Kh7 25. c3 f5 26. Bc2 Bh6+ 27. Kb1 Qh5 28. Rf1 e4 29. cxb4 Rg8 30. Qh1 Raf8 31. Nc3 Qxh4 32. a3 Rg3 33. Ne2 Rxh3 34. Qg2 Rh2 35. Qh1 Rxh1 36. Rxh1 Qxh1+ 37. Ka2 Qg2 38. Bd1 f4 39. Nd4 f3 40. Ne6 f2 41. Bc2 f1=Q 42. Nxf8+ Bxf8 43. a4 Bg7 44. Bb3 Qc2 45. Ka3 1-0",
    B: "1990",
  },
  {
    W: "1969",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Bb5+ Nbd7 6. Nc3 a6 7. Ba4 b5 8. Bb3 e6 9. O-O Nc5 10. Re1 Nxb3 11. cxb3 Bb7 12. b4 Rc8 13. a4 bxa4 14. Qxa4+ Qd7 15. Qd1 Be7 16. b5 axb5 17. Ndxb5 Qc6 18. Na7 Qb6 19. Nxc8 Bxc8 20. Qa4+ Bd7 21. Qa8+ Bd8 22. Ra6 Qb4 23. Rd1 O-O 24. Raxd6 Bc7 25. Qa3 Qxa3 26. bxa3 Bxd6 27. Rxd6 Rd8 28. Bg5 h6 29. Bxf6 gxf6 30. e5 Kf8 31. exf6 Ke8 32. Ne4 Ba4 33. Rxd8+ Kxd8 34. Nd6 Bc2 35. Nxf7+ Kd7 36. Ne5+ Kd6 37. Nf7+ Kd7 38. Nxh6 e5 39. Ng4 Ke6 40. f7 Ke7 41. Nxe5 Bb3 42. f4 Bxf7 43. Nxf7 Kxf7 44. a4 Ke6 45. a5 Kd5 46. a6 Kc6 47. a7 Kb7 48. f5 Ka8 49. f6 Kxa7 50. f7 Kb7 51. f8=Q Kb6 52. h4 Kb5 53. h5 Kc4 54. h6 Kd3 55. h7 Ke3 56. h8=Q Ke4 57. Qe8+ Kd5 58. Qhh5+ Kc4 59. Qeb5+ 1-0",
    B: "1976",
  },
  {
    W: "1705",
    T: "180+0",
    P: "1. e4 d6 2. d4 Nd7 3. Nf3 g6 4. d5 Bg7 5. Nc3 Ngf6 6. Bf4 O-O 7. Be2 b6 8. h3 Bb7 9. O-O Nc5 10. b4 Ncxe4 11. Nxe4 Nxe4 12. Qd3 Nf6 13. c4 Qd7 14. Rae1 Rfe8 15. Bh2 a6 16. Ng5 b5 17. cxb5 Bxd5 18. bxa6 Qc6 19. b5 Qd7 20. Bg4 e6 21. a4 Kh8 22. Qa3 Bc4 23. b6 Bxf1 24. bxc7 Bxa6 25. Bxd6 Bc8 26. Rd1 Qxa4 27. Nxf7+ Kg8 28. Qxa4 Rxa4 0-1",
    B: "1706",
  },
  {
    W: "1833",
    T: "180+0",
    P: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. d4 Nc6 5. Bb5 Bg4 6. Nc3 a6 7. Bxc6+ bxc6 8. Bg5 Nf6 9. Qe2 e6 10. O-O-O Be7 11. h3 Bh5 12. g4 Bg6 13. h4 h6 14. h5 Bh7 15. Bh4 Nxg4 16. Bxe7 Qxe7 17. Rhg1 f5 18. Ne5 Nf6 19. Nxc6 Qd6 20. Ne5 Rb8 21. Rxg7 Qb4 22. Nd3 Qxd4 23. Qxe6+ Kd8 24. Ne5 Qf4+ 25. Kb1 Rb7 26. Rxb7 Re8 27. Rxd5+ Nxd5 28. Qxd5+ Kc8 29. Qd7# 1-0",
    B: "1839",
  },
  {
    W: "1880",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. d4 Bg4 6. dxe5 Qxd1+ 7. Kxd1 O-O-O+ 8. Ke1 Ne7 9. h3 Bh5 10. Bf4 Ng6 11. Bh2 Bxf3 12. gxf3 Bc5 13. Ke2 Rhe8 14. f4 f6 15. Kf3 fxe5 16. f5 Nf8 17. Rg1 Rd7 18. Nc3 Rd2 19. Raf1 g6 20. Bg3 Rxc2 21. Bh4 Rxb2 22. Na4 Rc2 23. Nxc5 Rxc5 24. Bf6 Rc3+ 25. Kg4 gxf5+ 26. Kxf5 Ng6 27. h4 Rf3+ 28. Kg5 Rg8 29. Kh6 Rxf6 30. Kxh7 Ne7 31. Rxg8+ Nxg8 32. Kxg8 Kd7 33. f4 exf4 34. Kg7 Ke6 35. h5 f3 36. h6 Rf7+ 37. Kg8 Kf6 38. h7 Rg7+ 39. Kh8 Kf7 40. Rxf3+ Kg6 41. Rg3+ Kf6 42. Rxg7 Ke5 43. Rf7 Kxe4 0-1",
    B: "1884",
  },
  {
    W: "1481",
    T: "180+0",
    P: "1. g3 d5 2. Bg2 Nf6 3. Nf3 Nc6 4. d3 Be6 5. Bg5 h6 6. Bxf6 gxf6 7. a3 Rg8 8. b4 Qd7 9. Nc3 O-O-O 10. b5 Ne5 11. Nxe5 fxe5 12. a4 d4 13. Ne4 Bd5 14. Rf1 Bg7 15. Rg1 f5 16. Nc5 Qe8 17. Bxd5 Rxd5 18. Ne6 e4 19. Nxg7 Rxg7 20. dxe4 fxe4 21. c3 d3 22. exd3 Rxd3 23. Qb3 Rg6 24. Rd1 Rgd6 25. Rxd3 exd3 26. Kd2 Kb8 27. Re1 Qf8 28. f4 Qf6 29. Re3 c6 30. Rxd3 cxb5 31. Rxd6 Qxd6+ 32. Kc2 bxa4 33. Qxa4 Kc7 34. Qxa7 Qb6 35. Qxb6+ Kxb6 36. Kd3 Kc5 37. h4 Kd6 38. g4 Ke6 39. c4 Kf6 40. Ke4 Ke6 41. g5 Kf7 42. Kf5 Kg7 43. h5 hxg5 44. fxg5 Kh7 45. h6 Kh8 46. g6 Kg8 47. Ke6 Kh8 48. Kxe7 Kg8 49. Kf6 Kh8 50. h7 1-0",
    B: "1501",
  },
  {
    W: "1754",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. c3 Nc6 4. d4 cxd4 5. cxd4 e5 6. Be2 exd4 7. Nxd4 Nxd4 8. Qxd4 Nf6 9. Bb5+ Bd7 10. Bxd7+ Qxd7 11. Bg5 Be7 12. O-O O-O 13. Nc3 h6 14. Bh4 Qg4 15. Bg3 Rad8 16. h3 Qg5 17. Nd5 Nxd5 18. exd5 Bf6 19. Qxa7 Qxd5 20. Rfd1 Qc6 21. Rac1 Qd7 22. Rxd6 Qe7 23. Rxd8 Rxd8 24. a4 Bd4 25. Qa5 b6 26. Qb5 Qg5 27. Rf1 Qf6 28. b4 Qe6 29. Qd3 Bxf2+ 30. Rxf2 Rxd3 31. Kh2 Rd8 32. Rf1 Qg6 33. Re1 Rd3 34. Bc7 Rd2 35. Rg1 Qd3 36. a5 bxa5 37. bxa5 Ra2 38. Bb6 Qb3 39. Kh1 Qb2 40. Kh2 1-0",
    B: "1750",
  },
  {
    W: "1546",
    T: "180+0",
    P: "1. d4 g6 2. c4 Bg7 3. Nc3 Nf6 4. Bf4 c6 5. e3 d6 6. h3 Nbd7 7. Nf3 e5 8. dxe5 dxe5 9. Nxe5 O-O 10. Nxd7 Qxd7 11. Qb3 Qe6 12. Rd1 b6 13. Be2 Bb7 14. O-O c5 15. Nb5 Qc6 16. Bf3 Ne4 17. Nd6 f5 18. Nxb7 Qxb7 19. Rd5 Rad8 20. Rfd1 Rxd5 21. Rxd5 Re8 22. Rxc5 Qd7 23. Rd5 Qe6 24. Bxe4 fxe4 25. Qb5 Qf6 26. Qxe8+ Bf8 27. Rd8 Qxb2 28. Qxf8# 1-0",
    B: "1531",
  },
  {
    W: "1615",
    T: "180+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 d6 5. O-O Be6 6. Bxe6 fxe6 7. d4 Bg7 8. Nc3 Nd7 9. Ne2 h6 10. c3 Ngf6 11. Qb3 d5 12. exd5 exd5 13. Qxb7 O-O 14. Ne5 Nxe5 15. dxe5 Ne4 16. Rd1 Bxe5 17. Rxd5 Nd6 18. Qc6 Bg7 19. Nd4 Bxd4+ 20. cxd4 f3 21. gxf3 Rxf3 22. b3 Qf6 23. Qxa8+ Kf7 24. Rf5 Nxf5 25. Qd5+ Kg6 26. Qxf3 Nh4 27. Qxf6+ Kxf6 28. Ba3 Nf3+ 29. Kg2 Nxd4 30. Kh3 Nc2 31. Rf1+ Kg6 32. Bc5 a6 33. Ba7 Ne3 34. Re1 Nf5 35. Re6+ Kf7 36. Rxa6 Nd6 37. Bc5 Ke6 38. Bxd6 1-0",
    B: "1629",
  },
  {
    W: "1741",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 d5 3. d4 dxe4 4. Ng5 Nf6 5. Nc3 h6 6. Ngxe4 Nxe4 7. Nxe4 b6 8. c4 Bb7 9. f3 Bxe4 10. fxe4 Qh4+ 11. Kd2 Bb4+ 12. Kd3 Nc6 13. Qf3 Qf6 14. Qxf6 gxf6 15. d5 Ne5+ 16. Kc2 Bc5 17. a3 Ng4 18. b4 Bd4 19. Bb2 Bxb2 20. Kxb2 Nf2 21. Rg1 Rg8 22. Be2 Nxe4 23. Raf1 Nd2 24. Rxf6 Nxc4+ 25. Bxc4 Ke7 26. Bb5 Kxf6 27. Rf1+ Ke5 28. dxe6 Rxg2+ 0-1",
    B: "1769",
  },
  {
    W: "2028",
    T: "60+0",
    P: "1. d4 Nf6 2. Bf4 g6 3. Nf3 d6 4. e3 b6 5. Bd3 Bb7 6. O-O Bg7 7. c4 O-O 8. Nbd2 Nbd7 9. Qc2 Qe8 10. Ne4 e5 11. dxe5 dxe5 12. Nxf6+ Bxf6 13. Bg5 e4 14. Bxf6 Nxf6 15. Be2 exf3 16. Bxf3 Bxf3 17. gxf3 Qe6 18. Qc3 Nd7 19. Kh1 Ne5 20. f4 Nxc4 21. Rac1 Nd6 22. Qxc7 Rfc8 23. Qxc8+ Nxc8 24. Rcd1 Nd6 25. Kg2 Nf5 26. Rd2 Kg7 27. Rfd1 Rc8 28. Rd7 Rc2 29. R7d2 Rxd2 30. Rxd2 Qe4+ 31. Kf1 Qh1+ 32. Ke2 Qc6 33. a3 Nh4 34. Kf1 Qf3 35. Ke1 Nf5 36. Rc2 Qh1+ 0-1",
    B: "2021",
  },
  {
    W: "1955",
    T: "60+0",
    P: "1. e4 c5 2. c3 d5 3. e5 Bf5 4. d4 cxd4 5. cxd4 Nc6 6. Nf3 e6 7. Bd3 Nge7 8. O-O Bxd3 9. Qxd3 Nf5 10. Nc3 Qb6 11. Ne2 Be7 12. b3 O-O 13. Be3 Rac8 14. Ng3 Nb4 15. Qe2 Rc2 16. Qd1 Nxe3 17. fxe3 Rfc8 18. a3 Nc6 19. Qd3 Nd8 20. Ne2 Qa6 21. Qxa6 bxa6 22. Rfc1 Nb7 23. Rxc2 Rxc2 24. Rc1 Rxe2 25. Rc7 Bxa3 26. Rc8+ Bf8 27. Rc7 Rxe3 28. Kf2 1-0",
    B: "1948",
  },
  {
    W: "1957",
    T: "60+0",
    P: "1. d4 c6 2. c4 d5 3. Nc3 Nf6 4. Nf3 Bg4 5. cxd5 Bxf3 6. exf3 cxd5 7. Bb5+ Nbd7 8. Qa4 e6 9. Bg5 Be7 10. Bxf6 Bxf6 11. O-O a6 12. Bxd7+ Qxd7 13. Qxd7+ Kxd7 14. Rfc1 Bxd4 15. Na4 Rac8 16. Rd1 Ba7 17. Rac1 Rxc1 18. Rxc1 Rc8 19. Rxc8 Kxc8 20. b3 Bd4 21. Kf1 b5 22. Ke2 bxa4 23. Kd3 Bc5 24. bxa4 Kc7 25. Kc3 Kc6 26. Kd3 a5 27. Kc3 e5 28. Kd3 f5 29. Kc3 g5 30. Kd3 h5 31. g3 e4+ 32. fxe4 fxe4+ 33. Ke2 g4 34. f3 gxf3+ 35. Kf1 e3 36. Ke1 e2 37. h3 Bb4+ 38. Kf2 e1=Q+ 39. Kxf3 Qe4+ 40. Kf2 d4 41. g4 d3 42. gxh5 d2 0-1",
    B: "1958",
  },
  {
    W: "1825",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e6 6. Be3 Bb4 7. Bd3 Qa5 8. O-O Bxc3 9. bxc3 Qxc3 10. Nb5 Qe5 11. Bd4 Nxd4 12. f4 Qc5 13. Nxd4 Qxd4+ 14. Kh1 Qb6 15. e5 Nd5 16. Rb1 Qc7 17. c4 Nc3 18. Qg4 Nxb1 19. Qxg7 Rf8 20. Bxb1 Qxc4 21. Rd1 Qxf4 22. Bxh7 b6 23. Kg1 Bb7 24. Rf1 Qe3+ 25. Kh1 Qe2 26. Kg1 Rc8 27. Bg6 Rc2 28. Bxf7+ Kd8 29. Bxe6 1-0",
    B: "1808",
  },
  {
    W: "2150",
    T: "60+0",
    P: "1. e4 g6 2. Nc3 Bg7 3. Be2 e6 4. d3 Ne7 5. h4 O-O 6. h5 b6 7. hxg6 hxg6 8. Bh6 f6 9. Bxg7 Kxg7 10. Qd2 Rh8 11. Rxh8 Qxh8 12. O-O-O Qh6 13. f4 Nbc6 14. Nh3 Nd4 15. Qe3 Nxe2+ 16. Qxe2 c5 17. Qf3 Nc6 18. Kb1 Nd4 19. Qf2 d5 20. Ne2 dxe4 21. Nxd4 cxd4 22. Qxd4 exd3 23. Qxd3 Qh7 24. Qd6 Kh6 25. Qc6 Bb7 26. Qc3 Qe7 27. a3 Kg7 28. Qg3 Rc8 29. Rh1 Qc5 30. Qb3 Bxg2 31. Rh2 Bxh3 32. Qxh3 Qg1+ 33. Ka2 Qc5 34. Qh7+ Kf8 35. Qh8+ Ke7 36. Qg7+ Kd6 37. Qxg6 Qc4+ 38. Kb1 Qf1+ 39. Ka2 Qc4+ 40. Kb1 Qf1+ 41. Ka2 Qxf4 42. Re2 Qc4+ 43. Kb1 Qxe2 44. Qg3+ e5 0-1",
    B: "2244",
  },
  {
    W: "1729",
    T: "60+0",
    P: "1. d4 g6 2. c4 Bg7 3. e3 d5 4. f4 Nf6 5. cxd5 Nxd5 6. Bc4 e6 7. Bxd5 Qxd5 8. Nc3 Qxg2 9. Qf3 Qxf3 10. Nxf3 O-O 11. h4 b6 12. h5 Bb7 13. Rh3 gxh5 14. Ng5 Bh6 15. Rxh5 Bxg5 16. Rxg5+ Kh8 17. Bd2 Nc6 18. Ke2 Ba6+ 19. Kf2 Rg8 20. Rag1 Rxg5 21. Rxg5 Rg8 22. Rxg8+ Kxg8 23. a3 Kg7 24. b4 Bb7 25. Nb5 a5 26. Nxc7 axb4 27. axb4 Kf6 28. b5 Na5 29. Ne8+ Ke7 30. Ng7 Nb3 31. Be1 Be4 32. Nh5 Nc1 33. Ng3 Nd3+ 34. Ke2 Nxe1 35. Kxe1 Bd5 36. Ke2 Bc4+ 0-1",
    B: "1662",
  },
  {
    W: "2316",
    T: "60+0",
    P: "1. h4 d6 2. h5 h6 3. Nc3 e5 4. e3 f5 5. d4 Nf6 6. Nf3 e4 7. Nd2 Be7 8. Be2 O-O 9. f3 d5 10. f4 c5 11. Nf1 Nc6 12. dxc5 Bxc5 13. Nb5 Qa5+ 14. Bd2 Qb6 15. c3 a6 16. Nd4 Bxd4 17. cxd4 Bd7 18. Bc3 Nb4 19. Nd2 Nd3+ 20. Bxd3 exd3 21. O-O Ne4 22. Nf3 Bb5 23. a4 Bc4 24. a5 Qf6 25. Ne5 Rae8 26. Nxd3 Bxd3 27. Qxd3 Qf7 28. Rf3 Qxh5 29. Rh3 Qg6 30. Kh1 Nf6 31. Kh2 Ng4+ 32. Kg1 Re4 33. Rf1 Rfe8 34. Rff3 Qc6 35. Rhg3 Qa4 36. Rxg4 fxg4 37. Rg3 Qa1+ 38. Kh2 Qc1 39. Rxg4 Rxe3 40. Qg6 R8e7 41. Qxh6 Re1 42. Qg5 Rh1+ 43. Kg3 Qe1+ 44. Bxe1 1-0",
    B: "2415",
  },
  {
    W: "2029",
    T: "60+0",
    P: "1. d4 c6 2. c4 d5 3. Nc3 Nf6 4. cxd5 cxd5 5. Nf3 Nc6 6. Bf4 Bg4 7. h3 Bxf3 8. exf3 e6 9. Be2 Bd6 10. O-O Bxf4 11. g3 Bd6 12. f4 O-O 13. Bf3 Nd7 14. a3 Re8 15. Nb5 Nb6 16. Rc1 a6 17. Nc3 Nc4 18. Qb3 Qb6 19. Qxb6 Nxb6 20. b4 Nxd4 21. Bg2 Rec8 22. Rfd1 Rxc3 23. Bf1 Rxc1 24. Rxc1 Rc8 25. Rd1 Nf5 26. g4 Nh4 27. Kh2 Bxf4+ 28. Kg1 Rc2 29. Bd3 Rc3 30. Bf1 Nc4 31. b5 axb5 32. Rb1 Nxa3 33. Ra1 b4 34. Bb5 Rc1+ 35. Rxc1 Bxc1 36. Ba4 Nf3+ 37. Kf1 Nd2+ 38. Ke2 b3 39. Kd3 b2 40. Bc2 Nxc2 41. Kxc2 Nf1 42. Kb1 Nh2 43. g5 Nf3 44. g6 hxg6 45. h4 d4 46. h5 gxh5 47. Kc2 h4 48. Kb1 h3 49. Kc2 Nd2 50. f4 b1=Q+ 51. Kd1 Qb2 52. Ke2 Nc4+ 53. Kf3 Qc3+ 0-1",
    B: "2037",
  },
  {
    W: "1962",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 Bd6 5. c4 c6 6. cxd5 cxd5 7. Nc3 Nf6 8. Bd3 O-O 9. O-O Bg4 10. h3 Bh5 11. g4 Bg6 12. Bxg6 hxg6 13. Bg5 a6 14. Bxf6 gxf6 15. Qd2 Bc7 16. Qh6 Nc6 17. Rae1 Qd6 18. Kg2 Rfe8 19. g5 f5 20. Re5 Qf8 21. Qh4 Nxe5 22. dxe5 Kg7 23. Nxd5 Qc5 24. Nf6 Bxe5 25. Qh7+ Kf8 26. Qh6+ Ke7 27. Nxe5 Qxe5 28. Qh4 Rh8 29. Qb4+ Kd8 30. Rd1+ Kc8 31. Rc1+ Kb8 32. Nd7+ Ka7 33. Nxe5 Rac8 34. Qd4+ Ka8 35. Rxc8+ Rxc8 36. Qd7 Rc2 37. Nxf7 Ka7 38. Nd6 Rxb2 39. Qe6 Rb6 40. Qxg6 Rc6 41. Qxf5 Rxd6 42. g6 Rd8 43. g7 Rg8 44. Qc5+ Kb8 45. Qe7 Ka7 46. Qf7 Rxg7+ 47. Qxg7 Kb6 48. h4 Ka5 49. h5 b6 50. h6 b5 51. h7 Ka4 52. h8=Q a5 53. Qb2 b4 54. Qe8# 1-0",
    B: "1960",
  },
  {
    W: "1585",
    T: "60+0",
    P: "1. g4 e5 2. Bg2 Nf6 3. g5 Ng4 4. h4 Nxf2 5. Kxf2 Bc5+ 6. e3 f5 7. Qf3 f4 8. Ne2 O-O 9. Ke1 fxe3 10. Qd5+ Rf7 11. Qxc5 exd2+ 12. Bxd2 d6 13. Qe3 Nc6 14. Rf1 Nd4 15. Na3 Nf5 16. Bd5 Nxe3 17. Rxf7 Kh8 18. Bxe3 Bg4 19. Nc3 h6 20. Ne4 hxg5 21. Nxg5 c6 22. Be6 Qa5+ 23. c3 Rg8 24. Bxg4 Qd5 25. Rd1 Qh1+ 26. Kd2 Qxd1+ 27. Kxd1 g6 28. Kd2 Rg7 29. Rxg7 Kxg7 30. Ne6+ Kf6 31. Nd8 g5 32. Nxb7 gxh4 33. Nxd6 h3 34. Ndc4 h2 0-1",
    B: "1609",
  },
  {
    W: "1507",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 f6 4. Nc3 Bc5 5. Nxe5 fxe5 6. Qh5+ g6 7. Qf3 Nf6 8. Nd5 d6 9. Nxf6+ Kf8 10. Nxh7+ Kg7 11. Qf7+ Kh6 12. Nf6 Qxf6 13. Qxf6 Bf5 14. Be6 Bxe6 15. Qxe6 Rhe8 16. Qh3+ Kg7 17. f3 Bf2+ 18. Kxf2 Nd4 19. Kg1 Nxc2 20. d3 Nxa1 21. Kf2 d5 22. Be3 dxe4 23. Qh7+ Kxh7 24. dxe4 Rf8 25. Rxa1 Rfe8 26. Re1 Rf8 27. g3 Rad8 28. f4 Rd3 29. f5 g5 0-1",
    B: "1581",
  },
  {
    W: "1936",
    T: "60+0",
    P: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Ne2 e6 5. c3 h6 6. Ng3 Ne7 7. h4 Bh7 8. Be3 Nd7 9. Nd2 a6 10. Nf3 c5 11. Bd3 Bxd3 12. Qxd3 cxd4 13. cxd4 Nc6 14. Rc1 Nb4 15. Qb1 Qa5 16. O-O Be7 17. a3 Nc6 18. Nh2 Bxh4 19. f4 Bxg3 20. Rf3 Bh4 21. Rh3 Qe1+ 22. Rxe1 Bxe1 23. Qxe1 Nb6 24. Rg3 g6 25. Ng4 h5 26. Nf6+ Ke7 27. Qf2 Nc4 28. Rf3 Rac8 29. Bc1 N6a5 30. Qh4 Kf8 31. Nd7+ Kg8 32. Qf6 Kh7 33. Qxf7+ Kh6 34. Nf6 1-0",
    B: "1861",
  },
  {
    W: "1670",
    T: "300+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. Nf3 f6 5. Bc4 e6 6. d4 Ne7 7. O-O c6 8. Re1 Qd6 9. Ne4 Qb4 10. Bd2 Qxb2 11. Nd6+ Kd8 12. Nf7+ Ke8 13. Nxh8 Nd5 14. Bb3 Bd6 15. c4 Nf4 16. Bxf4 Bxf4 17. d5 e5 18. Nh4 Kf8 19. Qh5 Bd7 20. Qf7# 1-0",
    B: "1681",
  },
  {
    W: "2203",
    T: "300+0",
    P: "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. Nf3 d6 5. Bf4 Nc6 6. exd6 cxd6 7. Nc3 Qb6 8. Bg3 Qxb2 9. Nd5 Kd8 10. e3 Be6 11. Be2 Bxd5 12. cxd5 Qc3+ 13. Nd2 Nb4 14. O-O Ne5 15. a3 Nbd3 16. Ne4 Qc4 17. Ng5 Ke7 18. Bxe5 dxe5 19. Bxd3 Qxd5 20. Rc1 h6 21. Rc7+ Kd6 22. Qc2 hxg5 23. Be4 Qa5 24. Rd1+ Ke6 25. Qc4+ Kf6 26. Qxf7# 1-0",
    B: "2136",
  },
  {
    W: "1420",
    T: "300+0",
    P: "1. d4 Nf6 2. Bf4 g6 3. Nf3 Bg7 4. e3 d6 5. Bd3 O-O 6. c3 Nbd7 7. h3 Re8 8. Bh2 e5 9. dxe5 dxe5 10. Be2 Ne4 11. Nbd2 Nd6 12. Nc4 Nxc4 13. Bxc4 b6 14. Bb5 Re7 15. Bc6 Rb8 16. O-O Bb7 17. Bxd7 Rxd7 18. Qb3 Bxf3 19. gxf3 Rd2 20. f4 exf4 21. exf4 Qd3 22. Rfd1 Rxd1+ 23. Rxd1 Qf3 24. c4 Qe2 25. Rb1 Rd8 26. Qb5 Rd2 27. Bg3 h5 28. h4 Kh7 29. b3 Bd4 30. Rf1 c5 31. Qa6 Rxa2 32. Qb7 Kg7 33. Qd5 Rd2 34. Qg2 Rb2 35. Qh1 Rxb3 36. Kg2 Rb2 37. Qg1 a5 38. Re1 Qd2 39. Rd1 Qc2 40. Rc1 Qe4+ 41. Kh2 Qf3 42. Rf1 a4 43. Kh3 a3 44. f5 Qg4+ 45. Kg2 Qe4+ 46. Kh3 Qxf5+ 47. Kh2 a2 48. Re1 Bxf2 0-1",
    B: "1612",
  },
  {
    W: "1698",
    T: "300+0",
    P: "1. c4 e5 2. Nc3 Nf6 3. e3 c6 4. d4 Bb4 5. a3 Bxc3+ 6. bxc3 exd4 7. cxd4 O-O 8. Nf3 d5 9. cxd5 Nxd5 10. Bd3 Bg4 11. h3 Bxf3 12. Qxf3 Nd7 13. Bb2 Qa5+ 14. Kf1 Qd2 15. Qe2 Qa5 16. h4 b5 17. h5 h6 18. g4 Rae8 19. Kg2 Nf4+ 20. exf4 Rxe2 21. Bxe2 Qd2 0-1",
    B: "1719",
  },
  {
    W: "1994",
    T: "300+0",
    P: "1. d4 d6 2. Bf4 g6 3. e3 Bg7 4. Nf3 Nc6 5. Nbd2 Bg4 6. Be2 e5 7. dxe5 dxe5 8. Bg3 f5 9. c3 Nge7 10. Nc4 Qxd1+ 11. Rxd1 e4 12. Ng5 Bxe2 13. Kxe2 Rc8 14. Ne6 Kf7 15. Nxc7 Rhd8 16. Nd6+ Rxd6 17. Bxd6 Be5 18. Bxe5 Nxe5 19. Nb5 Rc6 20. Nd4 Ra6 21. a3 Nd3 22. Rd2 Nd5 23. g3 Rb6 24. c4 Nf6 25. b4 Ng4 26. f3 Ngf2 27. Ra1 a5 28. c5 Ra6 29. b5 Ra8 30. c6 bxc6 31. Nxc6 exf3+ 32. Kxf3 g5 33. Rxf2 Nxf2 34. Kxf2 g4 35. a4 h5 36. Rd1 Ke6 37. Nd4+ Ke5 38. Rc1 Ke4 39. Rc5 Rf8 40. Nxf5 Rxf5+ 41. Rxf5 Kxf5 42. b6 Ke5 43. b7 Kd6 44. b8=Q+ 1-0",
    B: "1975",
  },
  {
    W: "1475",
    T: "60+0",
    P: "1. e3 d5 2. d4 Nc6 3. Nf3 Bg4 4. Nc3 Bxf3 5. Qxf3 e6 6. Bd3 Bb4 7. O-O Bxc3 8. bxc3 Nf6 9. Bd2 O-O 10. h3 g6 11. e4 Nh5 12. e5 f6 13. exf6 Rxf6 14. Qg3 Nxg3 15. fxg3 Rxf1+ 16. Rxf1 Qf6 17. Bf4 g5 18. Bxc7 Qe7 19. Be5 Rf8 20. Rxf8+ Qxf8 21. c4 Nxe5 22. dxe5 Qg7 23. cxd5 Qxe5 24. dxe6 Qxe6 25. Bf1 Qe1 26. c4 Kf7 27. a4 Ke6 28. c5 Ke5 29. g4 Kd5 30. h4 gxh4 31. g5 Kxc5 32. g4 hxg3 33. g6 Qf2+ 34. Kh1 Qh2# 0-1",
    B: "1430",
  },
  {
    W: "1812",
    T: "600+0",
    P: "1. e4 c6 2. Nf3 e6 3. d4 g6 4. Bc4 Bg7 5. c3 Ne7 6. O-O a6 7. a3 b5 8. Ba2 Bb7 9. Bg5 h6 10. Bh4 g5 11. Bg3 f5 12. exf5 Nxf5 13. Nbd2 O-O 14. Ne4 Nxg3 15. Nxg3 Qf6 16. Nh5 Qg6 17. g4 c5 18. Ne5 Bxe5 19. dxe5 Qe4 20. f3 Rxf3 21. Rxf3 Qxg4+ 22. Rg3 Qe4 23. Nf6+ 1-0",
    B: "1769",
  },
  {
    W: "1617",
    T: "600+0",
    P: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6 6. cxd5 Nxd5 7. Bc4 Be6 8. Nxd5 Bxd5 9. Bxd5 Qxd5 10. Nf3 e6 11. O-O Be7 12. Be3 O-O 13. a3 Bf6 14. b4 a6 15. Rc1 Rfd8 16. Rc5 Qd6 17. Qc1 Bxd4 18. Bxd4 Nxd4 19. Nxd4 Qxd4 20. Rc3 b5 21. Rg3 g6 22. Qh6 Rac8 23. h4 Rc3 24. Rxc3 Qxc3 25. h5 Qg7 26. Qg5 Rd5 27. Qf4 Rxh5 28. Rd1 Rd5 29. Rxd5 exd5 30. Qb8+ Qf8 31. Qe5 Qd8 32. Kf1 d4 33. Ke2 d3+ 34. Kd2 Qg5+ 35. Qe3 Qxe3+ 36. fxe3 f5 37. Kxd3 Kf7 38. Kd4 Ke6 39. Kc5 g5 40. Kb6 f4 41. exf4 gxf4 42. Kxa6 h5 43. Kxb5 f3 44. gxf3 h4 45. a4 Kd7 46. a5 Kc7 47. a6 Kb8 48. Kc6 h3 49. b5 h2 50. b6 h1=Q 51. b7 Qxf3+ 0-1",
    B: "1619",
  },
  {
    W: "1689",
    T: "300+3",
    P: "1. c4 e6 2. g3 d5 3. cxd5 Qxd5 4. Nf3 Nf6 5. Nc3 Qd8 6. Bg2 Be7 7. O-O O-O 8. d3 c5 9. a3 Nc6 10. h3 Rb8 11. Bg5 h6 12. Be3 b6 13. Nd2 Bb7 14. Nce4 Nxe4 15. Nxe4 Qc7 16. Bf4 e5 17. Bd2 f5 18. Nc3 Nd4 19. Nd5 Qd7 20. Nxe7+ Qxe7 21. Bxb7 Rxb7 22. e3 Ne6 23. Qb3 Rd7 24. Rfd1 Rfd8 25. Be1 Kh8 26. a4 Ng5 27. Kg2 Qd6 28. h4 Nf7 29. a5 Qd5+ 30. Qxd5 Rxd5 31. axb6 axb6 32. Ra7 R5d7 33. Ra6 Rb7 34. Bc3 Kh7 35. d4 exd4 36. exd4 cxd4 37. Rxd4 Rxd4 38. Bxd4 b5 39. b4 Nd8 40. h5 Kg8 41. Ra8 Rd7 42. Bb6 Kf7 43. Bxd8 Rb7 44. Ba5 g6 45. hxg6+ Kxg6 46. Rg8+ Kf7 47. Rh8 Kg7 48. Rc8 Kf7 49. Kf3 Kf6 50. Kf4 Re7 51. Bd8 Kf7 52. Bxe7 1-0",
    B: "1575",
  },
  {
    W: "1485",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. c3 Nf6 5. d4 d6 6. dxe5 Nxe4 7. exd6 Bxd6 8. Qd5 O-O 9. O-O Nf6 10. Qd1 b6 11. Nbd2 Ne7 12. Re1 Bb7 0-1",
    B: "1556",
  },
  {
    W: "1407",
    T: "900+15",
    P: "1. e4 e6 2. Nf3 c6 3. Nc3 d5 4. d4 dxe4 5. Nxe4 Nf6 6. Nxf6+ Qxf6 7. Bg5 Qf5 8. Bd3 Bb4+ 9. c3 Bxc3+ 10. bxc3 Qa5 11. O-O O-O 12. c4 c5 13. dxc5 Qxc5 14. Re1 Nc6 15. Be3 Qe7 16. Ng5 f5 17. Bf4 h6 18. Nh7 Kxh7 19. Bxf5+ Rxf5 20. Bd6 Qf6 21. Bc5 b6 22. Ba3 Ba6 23. Qa4 Bb7 24. Rad1 Nd4 25. Qd7 Rd8 26. Be7 Rxd7 27. Bxf6 Rxf6 28. Re4 Bxe4 29. f3 Bc2 30. Re1 0-1",
    B: "1477",
  },
  {
    W: "1241",
    T: "900+15",
    P: "1. d4 f5 2. Nf3 b6 3. c4 Bb7 4. Nc3 Nc6 5. d5 Na5 6. e3 Nxc4 7. Bxc4 Nf6 8. e4 fxe4 9. Ne5 c6 10. dxc6 Bxc6 11. Bf7# 1-0",
    B: "1234",
  },
  {
    W: "1616",
    T: "900+15",
    P: "1. Nf3 g6 2. d4 Bg7 3. c4 Nf6 4. Nc3 O-O 5. e4 d6 6. Bf4 Nbd7 7. Bd3 c6 8. Qc2 Nh5 9. Be3 Qa5 10. g4 Nhf6 11. h3 e5 12. g5 Nh5 13. a3 exd4 14. b4 Qd8 15. Nxd4 Bxd4 16. Bxd4 Qxg5 17. Be3 Qh4 18. O-O-O Nf4 19. Bf1 d5 20. exd5 cxd5 21. Nxd5 Ne5 22. Nxf4 Qxf4 23. Bxf4 a6 24. Bxe5 Bf5 25. Qc3 b6 26. Bd3 Be6 27. Bh8 1-0",
    B: "1694",
  },
  {
    W: "1917",
    T: "900+15",
    P: "1. e4 e5 2. f4 f5 3. exf5 exf4 4. Qh5+ g6 5. fxg6 b6 6. g7+ Ke7 7. gxh8=Q Bb7 8. Qxg8 Nc6 9. Qhf7+ Kd6 10. Qd5+ Ke7 11. Qgf7# 1-0",
    B: "1771",
  },
  {
    W: "1106",
    T: "900+15",
    P: "1. e4 e5 2. f4 d6 3. Bc4 exf4 4. Nf3 Be6 5. b3 Nf6 6. O-O Nxe4 7. Nd4 d5 8. Nxe6 Qe7 9. Bxd5 fxe6 10. Qh5+ g6 11. Ba3 Qd7 12. Qe5 Bc5+ 13. Kh1 O-O 14. Bxe6+ Rf7 15. Bxd7 Rxd7 16. Qe6+ Rf7 17. Bxc5 Nxc5 18. Qc4 Nba6 19. Rxf4 Rf8 20. Nc3 g5 21. Rff1 Ne6 22. Rae1 c5 23. Qxe6 Nc7 24. Qc4 b5 25. Qg4 Rxf1+ 26. Rxf1 Rxf1# 0-1",
    B: "1143",
  },
  {
    W: "1204",
    T: "900+15",
    P: "1. c4 g6 2. Nc3 e6 3. e3 b6 4. d4 Bb7 5. Nf3 Bg7 6. Be2 Ne7 7. a3 c5 8. Nb5 Nbc6 9. Nd6+ Kf8 10. Nxb7 Qb8 11. Nxc5 bxc5 12. O-O cxd4 13. exd4 e5 14. d5 e4 15. dxc6 exf3 16. Bxf3 Qc7 17. cxd7 Rb8 18. Rb1 Qxc4 19. d8=Q+ Rxd8 20. Qxd8# 1-0",
    B: "1188",
  },
  {
    W: "1365",
    T: "900+15",
    P: "1. d4 d5 2. c3 Nc6 3. f3 e5 4. dxe5 Nxe5 5. f4 Ng6 6. Nf3 Bf5 7. h3 Bd6 8. g4 Bxb1 9. Rxb1 Bxf4 10. Qd4 Bg3+ 11. Kd1 Qf6 12. Bg5 Qxd4+ 13. cxd4 N8e7 14. Rc1 O-O-O 15. Rg1 Bf2 16. Rg2 h6 17. Bxe7 Nxe7 18. Rxf2 f6 19. e3 Rd6 20. b4 Rb6 21. a3 a6 22. e4 dxe4 23. Nh2 Rd8 24. Re2 Re6 25. Rc4 b5 26. Rc3 Rxd4+ 27. Rd2 Nc6 28. Rxc6 Rxd2+ 29. Kxd2 Rxc6 30. Ke3 Re6 31. Kd4 e3 32. Kd5 e2 33. Kxe6 e1=Q+ 34. Kf7 Qf2 0-1",
    B: "1332",
  },
  {
    W: "2140",
    T: "300+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. Bb5 e6 4. Bxc6 bxc6 5. d4 d5 6. exd5 cxd5 7. dxc5 Bxc5 8. Nf3 Ba6 9. Bg5 Qb6 10. Bh4 Qxb2 11. Ne2 Bb4+ 12. c3 Bxc3+ 13. Kf1 Qxa1 0-1",
    B: "2051",
  },
  {
    W: "1514",
    T: "300+0",
    P: "1. d4 e6 2. Nf3 c5 3. e4 cxd4 4. Bd3 a6 5. Nbd2 g6 6. Nb3 f5 7. exf5 gxf5 8. Nbxd4 Nc6 9. Qe2 Nxd4 10. Nxd4 h5 11. Nxf5 Qa5+ 12. Bd2 Qxf5 13. Bxf5 Nf6 14. Bg6+ Kd8 15. O-O-O b5 16. Ba5+ Ke7 17. Qe5 Bg7 18. Qc5+ d6 19. Qc7+ Bd7 20. Qxd6# 1-0",
    B: "1509",
  },
  {
    W: "1777",
    T: "300+0",
    P: "1. d4 d5 2. Bf4 h5 3. e3 e5 4. Bxe5 f6 5. Bg3 h4 6. Bxh4 Rxh4 7. Nf3 Rh8 8. c4 Bg4 9. Be2 Nc6 10. cxd5 Qxd5 11. Nc3 Bb4 12. Qc2 Bxc3+ 13. bxc3 Nge7 14. c4 Qa5+ 15. Kf1 O-O-O 16. g3 Rh3 17. Kg2 Rdh8 18. Nh4 g5 19. Bxg4+ Kb8 20. Bxh3 gxh4 21. Bg4 hxg3 22. hxg3 f5 23. Rxh8+ Nc8 24. Bxf5 Nb4 25. Rxc8# 1-0",
    B: "1755",
  },
  {
    W: "1500",
    T: "300+0",
    P: "1. d4 e6 2. c4 c5 3. d5 exd5 4. cxd5 d6 5. Nc3 Nf6 6. a3 Be7 7. b4 cxb4 8. axb4 b6 9. b5 Bb7 10. e4 Nbd7 11. Nf3 Ne5 12. Nxe5 dxe5 13. Bb2 Bc5 14. Bc4 O-O 15. Na4 Bd6 16. O-O Rc8 17. Bb3 Nxe4 18. Re1 Nf6 19. Bxe5 Bxe5 20. Rxe5 Re8 21. Rxe8+ Qxe8 22. Ra2 Qxb5 23. Rd2 Rd8 24. Nc3 Qc5 25. Na4 Qd6 26. Nc3 a5 27. Nb5 Qc5 0-1",
    B: "1499",
  },
  {
    W: "1982",
    T: "300+0",
    P: "1. e4 Nc6 2. d4 e5 3. d5 Nce7 4. c4 Ng6 5. Bd3 Nf6 6. Ne2 Bc5 7. O-O O-O 8. a3 a5 9. Nbc3 d6 10. Be3 Ba7 11. Bxa7 Rxa7 12. Rb1 Nh5 13. b4 axb4 14. axb4 Bd7 15. c5 Qg5 16. Nb5 Nhf4 17. Ng3 Bxb5 18. Bxb5 Nh4 19. cxd6 cxd6 20. Be2 Nfxg2 21. Bf3 Nf4 22. b5 Ra2 23. Rc1 Rd2 24. Qb3 Rd3 25. Rc3 Nxf3+ 26. Kh1 Qh4 0-1",
    B: "2262",
  },
  {
    W: "1294",
    T: "300+0",
    P: "1. d4 d5 2. e3 c5 3. c3 Nc6 4. Bd3 f6 5. Nf3 e5 6. dxe5 fxe5 7. Bb5 a6 8. Bxc6+ bxc6 9. Nxe5 c4 10. Nxc6 Qf6 11. Nb4 a5 12. Nxd5 Qd6 13. O-O Be6 14. e4 Nh6 15. Bxh6 gxh6 16. Qh5+ Bf7 17. Qf3 Rg8 18. Rd1 Qg6 19. Nf6+ Ke7 20. Nxg8+ Bxg8 21. e5 Rd8 22. Qb7+ Ke8 23. Qb5+ Ke7 24. Rxd8 Kxd8 25. Qxa5+ Ke8 26. Na3 Bxa3 27. bxa3 Qc2 28. Re1 Qd2 29. Qa8+ Kf7 30. Qf3+ Ke8 31. Qe3 Qxa2 32. e6 Ke7 33. Qxh6 Qxa3 34. Qg7+ Kd6 35. Qxg8 Qxc3 36. Qg3+ Qxg3 37. fxg3 c3 38. e7 c2 39. e8=Q 1-0",
    B: "1263",
  },
  {
    W: "1442",
    T: "300+0",
    P: "1. e4 e6 2. f4 d5 3. exd5 exd5 4. d4 Be7 5. Nf3 Bg4 6. Nc3 Nf6 7. h3 Bh5 8. g4 Bg6 9. f5 Nxg4 10. hxg4 Bxf5 11. gxf5 Bb4 12. Bd2 Nc6 13. Qe2+ Kd7 14. Bh3 Re8 15. f6+ Kd6 16. Nb5# 1-0",
    B: "1498",
  },
  {
    W: "1370",
    T: "300+0",
    P: "1. h4 e5 2. a4 Nc6 3. e4 Nf6 4. Nc3 d5 5. exd5 Nxd5 6. Nf3 Nxc3 7. bxc3 Bg4 8. Be2 Qf6 9. d4 O-O-O 10. Bg5 Qd6 11. Bxd8 Qxd8 12. Nxe5 Nxe5 13. Bxg4+ Nxg4 14. Qxg4+ Kb8 15. O-O Bd6 16. Qxg7 Rg8 17. Qxf7 a6 18. Qxg8 Qxg8 19. Rfd1 Qf8 20. Rd3 Qf4 21. g3 Qf6 22. f4 Qg6 23. Kf2 c5 24. Re1 Ka7 25. Re2 cxd4 26. cxd4 Bc7 27. Re7 Bb6 28. Re5 Qc6 29. c3 Qxa4 30. Rf3 Qc2+ 31. Re2 Qg6 32. f5 Qf6 33. Re6 Bxd4+ 34. Kg2 Qd8 35. cxd4 Qxd4 36. f6 Qa4 37. Rf2 Qd7 38. Rxa6+ Kxa6 39. f7 Qd5+ 40. Kh2 Qd8 41. f8=Q Qxf8 42. Rxf8 b6 43. Rf5 b5 44. Rxb5 Kxb5 45. Kh3 Kc6 46. Kg4 Kd6 47. Kh5 Ke7 48. Kh6 Kf6 49. g4 Kf7 50. Kxh7 Kf6 51. g5+ Kf5 52. g6 Kg4 53. g7 Kxh4 54. g8=Q Kh3 55. Qg1 Kh4 56. Kg6 Kh3 57. Kf5 Kh4 58. Qh2# 1-0",
    B: "1333",
  },
  {
    W: "1727",
    T: "300+0",
    P: "1. e4 e5 2. Bc4 Nc6 3. Nf3 Nd4 4. Nxd4 exd4 5. O-O Bc5 6. d3 Ne7 7. Bg5 O-O 8. Nd2 h6 9. Bh4 c6 10. Nf3 d5 11. exd5 cxd5 12. Bb3 Qd7 13. Bxe7 Bxe7 14. Ne5 Qd8 15. Qh5 Bf6 16. Nf3 Be6 17. Rae1 Qc8 18. h3 a5 19. a4 Rb8 20. Ne5 b5 21. axb5 Rxb5 22. f4 Rb8 23. f5 Bxe5 24. fxe6 fxe6 25. Qxe5 Rxf1+ 26. Rxf1 a4 27. Bxa4 Rxb2 28. Re1 Kh8 29. Qxe6 Qc5 30. Rf1 Rb8 31. Rf6 Rb1+ 32. Kh2 Qc7+ 33. Qd6 1-0",
    B: "1746",
  },
  {
    W: "1894",
    T: "300+0",
    P: "1. e4 d6 2. d4 Nd7 3. c4 e5 4. d5 Ndf6 5. Bg5 h6 6. Bxf6 Nxf6 7. Bd3 c6 8. Nc3 Be7 9. Nge2 Qa5 10. O-O O-O 11. a3 a6 12. b4 Qd8 13. Qb3 Nh7 14. Na4 Bg5 15. f4 exf4 16. Nxf4 Qf6 17. Qc3 Qe7 18. Nh3 Bxh3 19. gxh3 Bf6 20. Qd2 Bxa1 21. Rxa1 Qe5 22. Qc3 Ng5 23. Qxe5 dxe5 24. h4 Nf3+ 25. Kf2 Nd4 26. Rg1 cxd5 27. exd5 b5 28. Nb6 bxc4 29. Nxa8 cxd3 30. Nc7 Rd8 31. Ke3 Rd7 32. Nxa6 Rxd5 33. Kxd3 Nf3+ 34. Ke4 Rd6 35. Kxf3 Rxa6 36. Ra1 Kf8 37. Ke4 f6 38. h5 Ke7 39. h4 Kd6 40. a4 Rb6 41. Rd1+ Kc6 42. b5+ Kc5 43. Rb1 Rb7 44. Kf5 Kd4 45. Rd1+ Kc5 46. Ke4 Kb4 47. Ra1 Ka5 48. Kd5 Rd7+ 49. Kc6 Rd4 50. b6 Rb4 51. b7 e4 52. Rc1 f5 53. Rc5+ Ka6 54. Rxf5 Ka7 55. Rf7 Rxa4 56. Rxg7 e3 57. Re7 Ra3 58. Kc7 Rc3+ 59. Kd6 Kb8 60. Kd5 Rb3 61. Kc4 Rb1 62. Rxe3 Kxb7 63. Re6 Rh1 64. Rxh6 Rxh4+ 65. Kd5 Kc7 66. Ke5 Kd7 67. Kf5 Ke7 68. Kg5 Rh1 69. Ra6 Kf7 70. Ra7+ Kg8 71. h6 Rg1+ 72. Kf6 Kh8 73. Kf5 Rg2 74. Kf4 Rg8 75. Kf5 Rg1 76. Kf4 Rg6 77. h7 Rg7 78. Rxg7 Kxg7 79. h8=Q+ Kxh8 1/2-1/2",
    B: "2004",
  },
  {
    W: "1585",
    T: "300+0",
    P: "1. e4 d5 2. exd5 Nf6 3. Nc3 Nxd5 4. Nxd5 Qxd5 5. d4 Bf5 6. Bd3 e6 7. Bxf5 Qxf5 8. c4 Bb4+ 9. Bd2 Qe4+ 10. Ne2 Bxd2+ 11. Qxd2 Qxg2 12. O-O-O O-O 13. d5 Rd8 14. Nf4 Qg5 15. h4 Qh6 16. Kb1 e5 17. Ng2 Qxd2 18. Rxd2 c6 19. f4 cxd5 20. cxd5 Nc6 21. Rhd1 Nd4 22. fxe5 Nf3 23. Re2 Re8 24. d6 Rxe5 25. d7 Rd8 26. Rc1 Rxe2 27. Rc8 Nd2+ 28. Kc1 1-0",
    B: "1621",
  },
  {
    W: "1550",
    T: "300+0",
    P: "1. d4 d5 2. c4 c6 3. cxd5 Qxd5 4. Nc3 Qa5 5. Bd2 Qc7 6. Nf3 Nf6 7. Rc1 Nbd7 8. e4 Nb6 9. d5 c5 10. Nb5 Qd8 11. e5 Nfxd5 12. Ba5 Bg4 13. Rxc5 e6 14. Bc4 Bxc5 15. Bxd5 exd5 16. Bxb6 Bxb6 17. Nd6+ Ke7 18. Qxd5 Ba5+ 19. Kf1 Bxf3 20. Qxf7# 1-0",
    B: "1552",
  },
  {
    W: "1064",
    T: "300+0",
    P: "1. e4 e6 2. e5 d5 3. d4 Ne7 4. Bg5 Nbc6 5. Nf3 Nxe5 6. dxe5 b6 7. Bb5+ c6 8. Bxe7 Qxe7 9. Bxc6+ Kd8 10. Bxa8 Qb4+ 11. c3 Qxb2 12. Nbd2 Qxc3 13. Ng5 h6 14. Nxf7+ Ke8 15. Nxh8 Ba6 16. Qh5+ Ke7 17. Qf7+ Kd8 18. Qxf8+ Kd7 19. Qxg7+ Kd8 20. Nf7+ Ke7 21. Nxh6+ Ke8 22. Qf7+ Kd8 23. Qf6+ Ke8 24. Qxe6+ Kd8 25. Qxd5+ Kc8 26. Rd1 Qc7 27. e6 Qh7 28. Qc6+ Kb8 29. Qe8+ Kc7 30. e7 Qxh6 31. Rc1+ Kd6 32. Qd8+ Ke6 33. e8=Q+ Kf5 34. Qd5+ Kf6 35. Qf3+ Kg7 36. Rc7# 1-0",
    B: "884",
  },
  {
    W: "1125",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 f6 4. d4 Nxd4 5. Nxd4 exd4 6. Qxd4 d6 7. O-O Be7 8. Bb5+ Bd7 9. Bxd7+ Qxd7 10. Nc3 c6 11. Rd1 Nh6 12. Bxh6 g6 13. Bg7 Rg8 14. Bxf6 Bxf6 15. Qxf6 Rf8 16. Qg7 O-O-O 17. Qh6 d5 18. exd5 Qf5 19. Qh3 Qxh3 20. gxh3 Rf5 21. dxc6 Rxd1+ 22. Rxd1 bxc6 23. Ne4 Re5 24. Nf6 h5 25. h4 g5 26. hxg5 Rxg5+ 27. Kh1 h4 28. h3 Rf5 29. Ne4 Ra5 30. a3 c5 31. b4 cxb4 32. axb4 Ra4 33. Rd4 Ra2 34. c4 Rb2 35. b5 Kc7 36. Nf6 Rxf2 37. Nd5+ Kb7 38. Rxh4 a6 39. b6 a5 40. Rh7+ Kc6 41. b7 Rb2 42. h4 Rxb7 43. Rxb7 Kxb7 44. h5 1-0",
    B: "1225",
  },
  {
    W: "1698",
    T: "300+0",
    P: "1. e4 e5 2. Nc3 Bc5 3. Nf3 Nc6 4. Bc4 Nf6 5. Bb3 a6 6. Nxe5 Nxe5 7. d4 Ba7 8. dxe5 Nh5 9. Qxh5 g6 10. Qf3 Qe7 11. Bh6 d6 12. exd6 cxd6 13. O-O Bd7 14. e5 O-O-O 15. exd6 Qxd6 16. Qxf7 Rdf8 17. Bxf8 Rxf8 18. Qc4+ Kb8 19. Rad1 Qe7 20. Nd5 Qf7 21. Qc7+ Ka8 22. Nb6+ Bxb6 23. Bxf7 Bxc7 24. Rxd7 Be5 25. Re1 Bxb2 26. Re8+ Rxe8 27. Bxe8 Ka7 28. Rxh7 g5 29. Rh5 Bc1 30. h4 gxh4 31. Rxh4 Kb6 32. Rc4 Ka5 33. g4 b5 34. Rc6 b4 35. Kg2 b3 36. axb3 Kb4 37. Rxa6 Kc3 38. Rc6+ Kd4 39. b4 Bd2 40. b5 Kd5 41. b6 Bf4 42. b7 Kd4 43. g5 Kd5 44. g6 Be5 45. g7 Bxg7 46. b8=Q Be5 47. Qb5+ Ke4 48. Re6 Kf5 49. Qxe5+ Kg4 50. Rg6+ Kh4 51. Qg3+ Kh5 52. Ra6# 1-0",
    B: "1717",
  },
  {
    W: "1487",
    T: "60+0",
    P: "1. e3 c5 2. a3 a6 3. Bc4 b5 4. Be2 d5 5. Nf3 Nf6 6. O-O e6 7. c3 Be7 8. Ne5 Bb7 9. Nf3 Nc6 10. d3 O-O 11. d4 c4 12. b4 Qc7 13. Bd2 e5 14. Bc1 exd4 15. Nxd4 Bd6 16. Nxc6 Bxc6 17. Bb2 Rfd8 18. Nd2 Be5 19. Nf3 Ne4 20. Nd4 Bb7 21. f3 Nf6 22. f4 Bd6 23. Bf3 Bf8 24. Nf5 Qd7 25. Nd4 Ne4 26. Bxe4 dxe4 27. h3 Be7 28. Qg4 Bf6 29. Qxd7 Rxd7 30. Rad1 Rad8 31. Bc1 Bxd4 32. cxd4 c3 33. Rde1 Bd5 34. Re2 Bc4 35. Rc2 Bxf1 36. Kxf1 1-0",
    B: "1548",
  },
  {
    W: "2270",
    T: "180+0",
    P: "1. Nf3 Nf6 2. b3 e6 3. Bb2 c5 4. e3 Nc6 5. d4 d5 6. Bd3 cxd4 7. exd4 Be7 8. O-O O-O 9. Nbd2 b6 10. Ne5 Bb7 11. a3 Rc8 12. Qe2 Re8 13. f4 Bd6 14. g4 Nd7 15. g5 Ndxe5 16. Bxh7+ Kxh7 17. Qh5+ Kg8 18. fxe5 g6 19. Qf3 Qxg5+ 20. Kh1 Rf8 21. exd6 Qxd2 22. Bc1 Qxd4 23. Bh6 Qh4 24. Bxf8 Rxf8 25. Qf4 Qh5 26. Rf3 Ne5 27. Rh3 d4+ 28. Kg1 Qxh3 29. Qg3 Nf3+ 30. Kf2 Qxh2+ 31. Qxh2 Nxh2 32. Kg3 Nf3 33. Rd1 e5 34. c3 Rd8 35. cxd4 Nxd4 36. b4 Rxd6 37. Rc1 Ne2+ 38. Kg4 Nxc1 39. Kg5 Kg7 40. Kg4 Rd4+ 41. Kg3 f5 42. Kf2 Rd3 43. a4 Ra3 44. b5 Rxa4 45. Ke1 Rb4 46. Kd2 Rxb5 47. Kxc1 Rb3 48. Kd2 a5 49. Kc2 a4 50. Kc1 Ba6 51. Kc2 Bc4 52. Kc1 a3 53. Kc2 b5 54. Kc1 Rb2 55. Kd1 g5 56. Kc1 g4 57. Kd1 g3 58. Kc1 g2 59. Kd1 g1=Q# 0-1",
    B: "2329",
  },
  {
    W: "1453",
    T: "180+0",
    P: "1. e4 g5 2. Nc3 Bg7 3. d4 h6 4. Nf3 d6 5. g4 Bxg4 6. Be2 Nc6 7. h4 Bxf3 8. Bxf3 Nxd4 9. hxg5 hxg5 10. Rxh8 Bxh8 11. Bxg5 c6 12. Be3 Ne6 13. Bg4 Qa5 14. Bd2 Qc5 15. Bxe6 fxe6 16. f4 O-O-O 17. e5 Qg1+ 18. Ke2 Qg2+ 19. Ke1 dxe5 20. fxe5 Bxe5 21. Qe2 Qg1+ 22. Qf1 Bg3+ 23. Ke2 Qh2+ 24. Kd1 Qxd2# 0-1",
    B: "1938",
  },
  {
    W: "1183",
    T: "15+0",
    P: "1. Nf3 d6 2. Nc3 e5 3. e3 e4 4. d3 exf3 5. gxf3 Nc6 6. f4 Nf6 7. Qf3 Bf5 8. e4 Ne5 9. Qe3 Bg4 10. f3 Nxf3+ 11. Ke2 Ng1+ 12. Kf2 Nh5 13. Kxg1 Qh4 14. h3 Bxh3 15. Rxh3 Qg4+ 16. Bg2 Be7 0-1",
    B: "1445",
  },
  {
    W: "1194",
    T: "120+1",
    P: "1. f4 g5 2. fxg5 h6 3. g6 f5 4. Nf3 e6 5. e3 Nc6 6. Be2 Qf6 7. O-O Qxg6 8. Nh4 Qf6 9. Rf4 Bd6 10. Ra4 Qe5 11. g3 Nf6 12. d4 Qd5 13. Bf3 Ne4 14. Nc3 Nxc3 15. bxc3 Qb5 16. Ra3 Bxa3 17. Bxa3 Na5 18. Rb1 Qc4 19. Be2 Qxc3 20. Bb2 Qc6 0-1",
    B: "1238",
  },
  {
    W: "1050",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Qe7 5. Nxf7 Nxe4 6. Nxh8 Qf6 7. f3 Ng5 8. d3 g6 9. Bxg5 Qxh8 10. Nc3 h6 11. Be3 g5 12. d4 exd4 13. Bxd4 Nxd4 14. Qd2 c5 15. O-O-O d6 16. Nb5 Kd7 17. Nxd4 cxd4 18. Rde1 Bg7 19. Bb5+ Kd8 20. Re8+ Kc7 21. Rhe1 Qh7 22. R1e7+ Kb6 23. Qb4 Bf5 24. Bd3+ Kc6 25. Qb5# 1-0",
    B: "854",
  },
  {
    W: "1675",
    T: "120+1",
    P: "1. Nf3 c5 2. d3 d5 3. Nbd2 Nc6 4. e4 d4 5. g3 e5 6. Bg2 b5 7. b3 Bd6 8. a4 Ba6 9. axb5 Bxb5 10. O-O Nge7 11. Nc4 Bxc4 12. bxc4 O-O 13. Bd2 f5 14. exf5 Nxf5 15. Ng5 Rc8 16. Bd5+ Kh8 17. Nf7+ Rxf7 18. Bxf7 Qf6 19. Bd5 Nce7 20. Be4 Bb8 21. f4 Nd6 22. fxe5 Qxe5 23. Bf4 Qe6 24. Bxd6 Bxd6 25. Rxa7 Ng6 26. Bxg6 1-0",
    B: "1704",
  },
  {
    W: "1580",
    T: "120+1",
    P: "1. d4 c5 2. dxc5 Nc6 3. Nc3 e6 4. e4 Bxc5 5. Bc4 Nf6 6. Bg5 O-O 7. Nf3 h6 8. Bh4 Be7 9. Qe2 Nd5 10. Bxe7 Ndxe7 11. O-O-O Ng6 12. a3 Nf4 13. Qd2 Nxg2 14. Rhg1 Qf6 15. Rxg2 Qxf3 16. Rdg1 g5 17. h4 f6 18. hxg5 fxg5 19. Nb5 Qf4 20. Qxf4 Rxf4 21. Nd6 Ne5 22. Ba2 b6 23. Rg3 Ba6 24. b4 Be2 25. Re1 Bf3 26. Rh1 Nf7 27. Nxf7 Kxf7 28. Rxh6 Rg8 29. Rh7+ Kf8 30. Rxd7 Rxe4 31. Rd8+ Kg7 32. Rxg8+ Kxg8 33. Rxg5+ Kf7 34. Rg3 Be2 35. Re3 Rxe3 36. fxe3 b5 37. c4 bxc4 38. Kd2 Bd3 39. Kc3 e5 40. Bxc4+ Bxc4 41. Kxc4 Ke6 42. e4 Kd6 43. a4 a6 44. b5 axb5+ 45. axb5 Kc7 46. Kd5 1-0",
    B: "1605",
  },
  {
    W: "1728",
    T: "120+1",
    P: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. c3 dxc3 5. Nxc3 Bb4 6. Bc4 Nc6 7. O-O Nge7 8. a3 Bxc3 9. bxc3 a6 10. e5 b5 11. Bd3 Ng6 12. Qe2 O-O 13. Rd1 Qc7 14. Bxg6 fxg6 15. Bb2 Rf5 16. c4 bxc4 17. Qxc4 Qb6 18. Rab1 Na5 19. Qe2 Qc6 20. Rbc1 Qb7 21. Rb1 Qc6 22. Nd4 Qc4 23. Qxc4 Nxc4 24. Nxf5 gxf5 25. Ba1 Nxa3 26. Rb3 Nb5 27. h3 Kf7 28. Bb2 Ra7 29. Bd4 Nxd4 30. Rxd4 Rc7 31. Ra3 Rc6 32. Rda4 Ke7 33. Ra5 g6 34. Kh2 Rc2 35. Ra2 Rxa2 36. Rxa2 h5 37. f4 Bb7 38. g3 Kd8 39. Kg1 Kc7 40. Kf2 Kb6 41. Ke3 Bc6 42. Kd4 Bb5 43. Rb2 Kc6 44. Kc3 Kc5 45. Ra2 1-0",
    B: "1734",
  },
  {
    W: "1891",
    T: "120+1",
    P: "1. d4 c5 2. d5 d6 3. e4 e6 4. f4 exd5 5. exd5 Nf6 6. Nf3 Be7 7. Be2 O-O 8. O-O Re8 9. Nc3 Nbd7 10. Bd3 a6 11. f5 b5 12. h3 c4 13. Ne4 cxd3 14. cxd3 Nb6 15. g4 Nbxd5 16. g5 Nxe4 17. dxe4 Nb6 18. Be3 Bb7 19. Bd4 Bxe4 20. f6 gxf6 21. gxf6 Bxf6 22. Qe2 Bxf3 23. Qxf3 Bxd4+ 24. Kh1 Qh4 25. Qxf7+ Kh8 26. Rf3 Re1+ 27. Rxe1 Qxe1+ 28. Kg2 Qg1# 0-1",
    B: "1911",
  },
  {
    W: "1882",
    T: "120+1",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 Nf6 5. Nc3 Bb4 6. Bd2 O-O 7. O-O-O Re8 8. Qg3 d5 9. exd5 Nxd5 10. Nxd5 Bxd2+ 11. Rxd2 Re1+ 12. Rd1 Rxd1+ 13. Kxd1 Qxd5+ 14. Bd3 Bf5 15. Ne2 Bxd3 16. Qxd3 Qxd3+ 17. cxd3 Rd8 18. Kd2 g6 19. Rc1 Kg7 20. a3 a5 21. f4 Rd7 22. g3 h5 23. Nc3 Nd4 24. Rf1 b5 25. Ne4 b4 26. axb4 axb4 27. Nc5 Rd6 28. Ke3 Nf5+ 29. Kd2 Nd4 30. Rf2 b3 31. Kc3 Rc6 32. Kxd4 Kf6 33. Nxb3 Rd6+ 34. Kc3 Rc6+ 35. Kd2 Rb6 36. Kc2 Rc6+ 37. Kb1 Rb6 38. Nc5 Kf5 39. Ne4 Rb3 40. Kc2 Rb8 41. Kc3 Rd8 42. b4 1-0",
    B: "1740",
  },
  {
    W: "2221",
    T: "120+1",
    P: "1. d4 d5 2. Nf3 Nf6 3. g3 g6 4. Bg2 Bg7 5. O-O c6 6. Bg5 Ne4 7. Be3 Qb6 8. b3 O-O 9. c4 dxc4 10. bxc4 Be6 11. d5 Bxa1 12. dxe6 f5 13. Bxb6 axb6 14. Nbd2 Bf6 15. Nxe4 fxe4 1-0",
    B: "2043",
  },
  {
    W: "1446",
    T: "120+1",
    P: "1. Nf3 d5 2. d4 Nf6 3. c4 g6 4. Nc3 Bg7 5. cxd5 Nxd5 6. e4 Nxc3 7. bxc3 c5 8. Bc4 O-O 9. O-O Nc6 10. Be3 Qc7 11. Qb3 cxd4 12. cxd4 Na5 13. Bxf7+ Rxf7 14. Rac1 Qxc1 15. Qxf7+ Kxf7 16. Rxc1 Bg4 17. Rc7 Bxf3 18. gxf3 b6 19. Bg5 Bf6 20. Bh6 Bxd4 21. f4 b5 22. e5 Nc4 23. Bg5 Re8 24. a4 a6 25. axb5 axb5 26. Rb7 Na3 27. Kg2 Bc3 28. f3 b4 29. Bh4 Nc4 30. Rc7 Ne3+ 31. Kf2 Nf5 32. Bg5 h6 33. Bf6 Rd8 34. Ke2 Rd2+ 35. Kf1 Rxh2 0-1",
    B: "1547",
  },
  {
    W: "1333",
    T: "120+1",
    P: "1. d4 Nf6 2. Nf3 d6 3. Nc3 g6 4. Bg5 Bg7 5. e4 Nbd7 6. Be2 O-O 7. O-O Re8 8. Qd2 c6 9. Rad1 b5 10. b4 a5 11. a3 axb4 12. axb4 Ra3 13. e5 dxe5 14. dxe5 Nd5 15. Nxd5 cxd5 16. Qxd5 e6 17. Qd6 Bf8 18. Bxd8 Bxd6 19. Rxd6 Rxd8 20. Bxb5 Ra7 21. Rfd1 h5 22. c4 Kg7 23. Bxd7 Bxd7 24. c5 f6 25. c6 Bxc6 26. Rxd8 Bxf3 27. gxf3 1-0",
    B: "1159",
  },
  {
    W: "2228",
    T: "180+2",
    P: "1. d4 d5 2. e3 e6 3. Bd3 Nf6 4. f4 Nbd7 5. Nf3 Bb4+ 6. Nc3 Ng4 7. O-O Bxc3 8. bxc3 O-O 9. Ne5 Ndxe5 10. fxe5 Qh4 11. h3 Nh6 12. e4 dxe4 13. Rf4 Qg3 14. Rxe4 Nf5 15. Rg4 Ne3 16. Rxg3 Nxd1 17. Be2 Nxc3 1-0",
    B: "1870",
  },
  {
    W: "1862",
    T: "180+2",
    P: "1. e4 b6 2. Nf3 Bb7 3. Nc3 Nf6 4. d3 e6 5. Be2 d5 6. e5 Nfd7 7. d4 c5 8. O-O cxd4 9. Qxd4 Nc6 10. Qf4 Be7 11. Qg3 d4 12. Ne4 Qc7 13. Qxg7 O-O-O 14. Qxf7 Ndxe5 15. Nxe5 Qxe5 16. Qf4 Qg7 17. Ng3 Bd6 18. Qg4 Qd7 19. Bb5 e5 20. Qxd7+ Rxd7 21. Ne4 Kc7 22. Bg5 Rg8 23. Bh4 Nb4 24. Nxd6 Rxd6 25. Bg3 h5 26. f3 Rd5 27. a3 Nxc2 28. Rac1 Rxb5 29. Rxc2+ Kd6 30. Re1 Re8 31. Rd2 Rc5 32. Rxd4+ Rd5 33. Rxd5+ Kxd5 34. Rd1+ Kc6 35. Re1 Kd6 36. Rd1+ Ke6 37. Rc1 Kd6 38. b4 Bd5 39. a4 a6 40. a5 b5 41. Re1 Bc4 42. Rd1+ Ke6 43. h3 Rc8 44. Kf2 Bd5 45. Re1 Rc2+ 46. Kg1 1-0",
    B: "1866",
  },
  {
    W: "1327",
    T: "180+2",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 Nc6 4. d4 d5 5. e5 Bb4+ 6. c3 Ba5 7. Bxf4 f6 8. exf6 Nxf6 9. Bd3 O-O 10. O-O Re8 11. Ne5 Nxe5 12. Bxe5 Ng4 13. Qf3 Nxe5 14. dxe5 Rf8 15. Bxh7+ 0-1",
    B: "1308",
  },
  {
    W: "1747",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. Nxe5 Nxe5 5. d4 Bb6 6. dxe5 Qe7 7. O-O Qxe5 8. Nc3 Nf6 9. Nd5 Nxd5 10. exd5 O-O 11. Re1 Qf6 12. Be3 Bxe3 13. Rxe3 Qxb2 14. Re7 Qxb5 0-1",
    B: "1729",
  },
  {
    W: "1081",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. Nxe5 Qg5 6. Nf3 Qxg2 7. Rg1 Qh3 8. Nc3 Bg4 9. Ng5 Bxd1 10. Nxh3 Bxc2 11. d4 Bb4 12. Rxg7 Bxe4 13. Ng5 Nf6 14. Rxf7 Rf8 15. Rxf8+ Kxf8 16. Nxh7+ Nxh7 17. Bh6+ Kg8 18. a3 Bxc3+ 19. bxc3 Re8 20. O-O-O Bf3 21. Rd3 Re5 22. Rxf3 Re1+ 23. Kd2 Rh1 24. Rg3+ Kf7 25. Rg7+ Kf8 26. Rxh7+ Kg8 27. Rg7+ Kh8 28. Rxc7 Rxh2 29. Bf4 Rxf2+ 30. Ke3 Ra2 31. Rc8+ Kg7 32. Rc7+ Kf6 33. Be5+ Ke6 34. Rxb7 Rxa3 35. Rc7 Rxc3+ 36. Kd2 Rc4 37. Kd3 Rc1 38. Rg7 a5 39. Rg6+ Kd5 40. Rd6# 1-0",
    B: "1154",
  },
  {
    W: "2154",
    T: "180+2",
    P: "1. e4 Nf6 2. Nc3 d6 3. d4 g6 4. f4 Bg7 5. Nf3 Bg4 6. e5 dxe5 7. dxe5 Qxd1+ 8. Kxd1 Bxf3+ 9. gxf3 Nh5 10. Nd5 Kd7 11. Bh3+ e6 12. Ke2 c6 13. Nc3 Kc7 14. Be3 b6 15. Ne4 Bf8 16. Ng5 Kd7 17. Nxf7 Rg8 18. Ng5 h6 1-0",
    B: "2049",
  },
  {
    W: "1548",
    T: "180+2",
    P: "1. d4 Nf6 2. c4 d5 3. c5 Nc6 4. Nf3 Bg4 5. e3 e6 6. Be2 Be7 7. O-O O-O 8. Nc3 Bxf3 9. Bxf3 e5 10. a3 e4 11. Be2 h6 0-1",
    B: "1682",
  },
  {
    W: "2002",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nf6 5. Nxc6 bxc6 6. e5 Nd5 7. c4 Nb6 8. Bd3 Qe7 9. O-O Ba6 10. b3 O-O-O 11. Bb2 Qg5 12. c5 Bxd3 13. Qxd3 Bxc5 14. a4 Nd5 15. Na3 Qxg2+ 16. Kxg2 Nf4+ 0-1",
    B: "1966",
  },
  {
    W: "1243",
    T: "180+2",
    P: "1. c4 c5 2. Nc3 e6 3. e3 d6 4. d4 b6 5. b3 f5 6. Nf3 h6 7. Bb2 g5 8. Be2 Be7 9. O-O Bf6 10. e4 g4 11. Nd2 f4 12. Bxg4 e5 13. Bxc8 Qxc8 14. dxe5 dxe5 15. f3 Ne7 16. Nd5 Nxd5 17. cxd5 Na6 18. Nc4 Qc7 19. Rc1 b5 20. Na3 b4 21. Nb5 Qb8 22. Qe2 Nc7 23. Nxc7+ Qxc7 24. Rfd1 O-O-O 25. a3 a5 26. axb4 axb4 27. Qc2 h5 28. Qxc5 Qxc5+ 29. Rxc5+ Kb7 30. Rb5+ Ka6 31. Rxb4 Ka5 32. Rc4 Kb5 33. Rc6 h4 34. Rxf6 1-0",
    B: "1179",
  },
  {
    W: "1459",
    T: "180+2",
    P: "1. d4 Nf6 2. Bg5 h6 3. Bh4 g5 4. Bg3 d6 5. h4 Bg7 6. hxg5 hxg5 7. Rh3 Bxh3 8. Nxh3 Nc6 9. Nxg5 Qd7 10. Nh3 O-O-O 11. Nc3 e5 12. dxe5 dxe5 13. Qxd7+ Rxd7 14. e3 Ng4 15. Bd3 Nb4 16. a3 Nxd3+ 17. cxd3 Rxd3 18. Rd1 Rxd1+ 19. Kxd1 Rd8+ 20. Kc2 f5 21. f3 Nxe3+ 22. Kb3 Nxg2 23. Bxe5 Bxe5 24. f4 Bxc3 25. bxc3 Rd3 26. Nf2 Rf3 27. Nd3 Rxd3 0-1",
    B: "1351",
  },
  {
    W: "1412",
    T: "300+8",
    P: "1. c4 d5 2. cxd5 c6 3. dxc6 bxc6 4. Nc3 Bb7 5. Nf3 e6 6. b3 Na6 7. e3 Nc5 8. Ba3 Ba6 9. b4 Na4 10. Bxa6 Rb8 11. Nxa4 Bd6 12. O-O Nf6 13. Nc5 e5 14. Nb7 Rxb7 15. Bxb7 Qa8 16. Bxa8 Kf8 17. Bxc6 Ng4 18. h3 1-0",
    B: "1500",
  },
  {
    W: "2048",
    T: "30+0",
    P: "1. b3 d5 2. Bb2 Nf6 3. d4 e6 4. Nf3 Be7 5. Nc3 O-O 6. e3 c5 7. Qd2 cxd4 8. exd4 a6 9. O-O-O b5 10. Ne5 b4 11. Nb1 Nc6 12. f3 Nxe5 13. dxe5 Nh5 14. Qf2 Bg5+ 15. Nd2 f6 16. g3 fxe5 17. h4 Bf6 18. g4 Nf4 19. g5 Be7 20. Bxe5 Bd6 21. Bb2 e5 22. Bh3 Be6 23. Bxe6+ Nxe6 24. f4 Rc8 25. fxe5 Qc7 26. exd6 Qxc2# 0-1",
    B: "2063",
  },
  {
    W: "1941",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bc4 Bc5 5. d3 h6 6. O-O O-O 7. h3 d6 8. Be3 Bxe3 9. fxe3 Bd7 10. d4 a6 11. Bd5 Ne7 12. dxe5 Nfxd5 13. exd5 dxe5 14. Nxe5 Be8 15. e4 f6 16. Ng4 Bh5 17. Qd3 Bxg4 18. hxg4 Ng6 19. g3 Ne5 20. Qe2 Qd7 21. Rf4 g5 22. Rf5 Qd6 23. Kg2 Kg7 24. Nd1 Rae8 25. Nf2 Qb4 26. c3 Qb6 27. Nd3 Nc4 28. Re1 Nd6 29. e5 Nxf5 30. gxf5 fxe5 31. Nxe5 Rxf5 32. Ng4 Rxe2+ 0-1",
    B: "1974",
  },
  {
    W: "2342",
    T: "60+0",
    P: "1. Nf3 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 O-O 5. O-O d6 6. Nc3 c6 7. d3 Nbd7 8. h3 Re8 9. Re1 e5 10. e4 Qc7 11. Be3 Rb8 12. Rc1 b6 13. b4 Nf8 14. g4 Ne6 15. b5 c5 16. g5 Nh5 17. Nd5 Qd8 18. Qd2 Bb7 19. h4 Bxd5 20. cxd5 Nef4 21. Nh2 Qd7 22. Bxf4 Nxf4 23. Qe3 f6 24. a4 fxg5 25. hxg5 Rf8 26. Qg3 Rf7 27. Ng4 Rbf8 28. Re3 a6 29. Bh3 Qe8 30. bxa6 Qxa4 31. Nf6+ Bxf6 32. gxf6 Rxf6 33. Rce1 Qxa6 34. Rf3 Kg7 35. Rxf4 Rxf4 36. Re2 R8f6 37. Be6 Rf3 38. Qg2 Qxd3 39. Ra2 Qxe4 40. Ra8 Qe1+ 41. Kh2 Qe4 42. Rg8+ Kh6 43. Qg4 Qxg4 44. Bxg4 1-0",
    B: "2106",
  },
  {
    W: "2125",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 e6 3. c3 d6 4. d4 Ne7 5. dxc5 dxc5 6. Qxd8+ Kxd8 7. Ne5 Nbc6 8. Nxf7+ Kc7 9. Nxh8 1-0",
    B: "2098",
  },
  {
    W: "1631",
    T: "60+0",
    P: "1. d4 d5 2. e3 g6 3. Be2 Bg7 4. h4 e6 5. h5 b6 6. hxg6 hxg6 7. Rxh8 Bxh8 8. Nf3 Ne7 9. Ng5 Bb7 10. Bg4 Nf5 11. Bxf5 Qxg5 12. Bg4 f5 13. e4 fxg4 14. Bxg5 Bxd4 15. exd5 Bxb2 16. dxe6 Nc6 17. Nd2 Bxa1 18. Qxa1 Bc8 19. Qh8# 1-0",
    B: "1646",
  },
  {
    W: "1459",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 Nf6 4. d3 g6 5. O-O Bg7 6. Ng5 O-O 7. Nd2 Nc6 8. Ndf3 a6 9. Bf4 b5 10. Bd5 Bd7 11. Bxc6 Bxc6 12. Qd2 h5 13. Nh3 e5 14. Bh6 Qe7 15. Bxg7 Kxg7 16. Nhg5 Ng4 17. h3 Nh6 18. Nh2 d5 19. f4 exf4 20. Rxf4 dxe4 21. dxe4 Rad8 22. Re1 Rxd2 23. e5 Rxc2 24. Nxf7 Rxf7 25. Rxf7+ Qxf7 26. e6 Qe7 27. Nf3 Nf5 28. Ne5 Rxg2+ 29. Kf1 Ng3# 0-1",
    B: "1466",
  },
  {
    W: "2034",
    T: "60+0",
    P: "1. d4 e6 2. c4 d5 3. Nc3 Nf6 4. cxd5 exd5 5. Bg5 Be7 6. e3 O-O 7. Bd3 Ne4 8. Qc2 f5 9. Bxe7 Qxe7 10. Nxd5 Qh4 11. Nxc7 Nc6 12. Nxa8 Kh8 13. Nf3 Qg4 14. O-O Nb4 15. Qb3 Nxd3 16. Qxd3 Be6 17. Nc7 Bf7 18. Rac1 Bh5 19. Nb5 Qg6 20. Kh1 Bg4 21. Nc3 Qh5 22. Nxe4 Bxf3 23. Ng3 Qg4 24. gxf3 Qxf3+ 25. Kg1 h5 26. Qe2 Qe4 27. Qxh5+ 1-0",
    B: "2096",
  },
  {
    W: "1379",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Nf6 4. c3 Nxe4 5. d4 exd4 6. Bxf7+ Kxf7 7. Qxd4 Qe7 8. O-O Nc6 9. Qd5+ Qe6 10. Qb5 1-0",
    B: "1313",
  },
  {
    W: "1728",
    T: "60+0",
    P: "1. d4 d5 2. c4 Nf6 3. Nc3 c6 4. Bf4 g6 5. Qb3 Bg7 6. e3 Nbd7 7. cxd5 cxd5 8. Nxd5 Nxd5 9. Qxd5 Nf6 10. Qb3 O-O 11. Bc4 e6 12. Nf3 Nd5 13. Bg3 Bd7 14. O-O Re8 15. Ne5 Qe7 16. Bxd5 exd5 17. Qxd5 Bc6 18. Nxc6 bxc6 19. Qxc6 Rac8 20. Qb5 Rc2 21. Qd3 Rec8 22. Be5 Bxe5 23. dxe5 Qxe5 24. Rfd1 Qxb2 25. Qd4 Qxd4 26. exd4 Rc1 27. Raxc1 Rxc1 28. Rxc1 Kg7 29. Re1 Kf6 30. d5 1-0",
    B: "1851",
  },
  {
    W: "2520",
    T: "60+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. a4 d6 5. a5 N6d7 6. e6 fxe6 7. d4 Nf6 8. Nf3 g6 9. Bd3 Bg7 10. Nc3 O-O 11. a6 Nc6 12. O-O e5 13. d5 Nd4 14. Ng5 Bg4 15. f3 Bd7 16. axb7 Rb8 17. Rxa7 h6 18. Nge4 c5 19. Nxf6+ Rxf6 20. Ne4 Rf8 21. Be3 Qb6 22. Ra2 Rxb7 23. Bxd4 exd4 24. Kh1 Bf5 25. h3 h5 26. Qe2 Bxe4 27. Qxe4 Rf6 28. Ra8+ Kf7 29. Rf2 Qb3 30. Re2 Qb6 31. f4 Rf5 32. Qe6# 1-0",
    B: "2358",
  },
  {
    W: "1945",
    T: "60+0",
    P: "1. Nf3 e6 2. e4 c6 3. d4 d5 4. e5 Nd7 5. c3 Qb6 6. Bd3 Ne7 7. h4 c5 8. Ng5 h6 9. Nxf7 Kxf7 10. Qg4 Kg8 11. Nd2 cxd4 12. cxd4 Nf5 13. Bxf5 exf5 14. Qxf5 Nc5 15. Qg6 Ne4 16. Qxb6 axb6 17. Nxe4 dxe4 18. O-O Be6 19. Re1 Bd5 20. Bd2 Kh7 21. Bc3 Be7 22. Rac1 Bxh4 23. g3 Bg5 24. a3 Bxc1 25. Rxc1 Rhf8 26. Bb4 Rf3 27. Rc7 Bc6 28. e6 Re8 29. e7 e3 30. fxe3 Rxg3+ 31. Kf2 Rh3 32. Ke2 Rh2+ 33. Kd3 Rxb2 34. Bd6 Bb5+ 35. Ke4 Bc6+ 36. Ke5 Re2 37. Ke6 Rxe3+ 38. Kf7 Re6 39. Rxc6 bxc6 40. Kxe8 g5 41. Kd8 Rxe7 0-1",
    B: "1939",
  },
  {
    W: "1954",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. Bb5+ c6 7. dxc6 bxc6 8. Qf3 Bd7 9. Ba4 Bc5 10. c3 h6 11. Ne4 Nxe4 12. Qxe4 O-O 13. b4 f5 14. Qe2 Nb7 15. bxc5 Nxc5 16. Bc2 e4 17. O-O Be6 18. d3 Nd7 19. dxe4 f4 20. Bb2 Qg5 21. Nd2 Ne5 22. Nf3 Nxf3+ 23. Qxf3 Bg4 24. Qd3 Rad8 25. Qc4+ Kh8 26. Qxc6 Bf3 27. g3 Qg4 28. c4 Qh3 29. Bxg7+ Kxg7 30. Qc7+ Rf7 0-1",
    B: "2022",
  },
  {
    W: "1234",
    T: "60+0",
    P: "1. e4 e6 2. Bc4 Be7 3. e5 d5 4. Bb3 c5 5. c3 c4 6. Bc2 Nc6 7. b3 Nxe5 8. bxc4 Nxc4 9. d3 Nb6 10. Nf3 Bd7 11. d4 Nf6 12. Ng5 O-O 13. Bxh7+ Nxh7 14. Nxh7 Kxh7 15. Qd3+ Kg8 16. h4 f5 17. h5 Bg5 18. Bxg5 Qxg5 19. h6 gxh6 20. Nd2 Qxg2 21. O-O-O Ba4 22. Rdg1 Qxg1+ 23. Rxg1+ Kh7 24. Qh3 Rf6 25. Nf3 Be8 26. Ng5+ Kh8 27. Nxe6 Rxe6 28. Rh1 Kh7 29. Qxf5+ Rg6 30. Rg1 Nd7 31. Qxd5 Nf6 32. Qe6 Rd8 0-1",
    B: "1249",
  },
  {
    W: "1834",
    T: "180+0",
    P: "1. d3 d5 2. f4 f5 3. Be3 Nf6 4. Nf3 e6 5. Nbd2 Bd6 6. g3 g6 7. Bg2 O-O 8. c3 c6 9. Bf2 Bd7 10. Qc2 Be8 11. e4 dxe4 12. dxe4 Be7 13. e5 Nd5 14. Nd4 a6 15. Nxe6 1-0",
    B: "1861",
  },
  {
    W: "1861",
    T: "60+0",
    P: "1. d4 d5 2. c4 c5 3. Nc3 cxd4 4. Qxd4 dxc4 5. Qxd8+ Kxd8 6. Nb5 a6 7. Nd4 e5 8. Ndf3 Nc6 9. e4 Bb4+ 10. Bd2 Bxd2+ 11. Kxd2 f6 12. Bxc4 b5 13. Bb3 Na5 14. Bd5 Bb7 15. Bxb7 Nxb7 16. Ne2 Rc8 17. a3 Nd6 18. Nc3 Nc4+ 19. Kc2 Ne7 20. Rad1+ Kc7 21. Kb3 Kb6 22. a4 Na5+ 23. Ka3 Nc4+ 24. Kb3 Na5+ 25. Kb4 Rc4+ 26. Ka3 b4+ 27. Ka2 bxc3 28. bxc3 Rxc3 29. Rb1+ Ka7 30. Rhc1 Rxc1 31. Rxc1 Nec6 32. Nd2 Rb8 33. Nc4 Nxc4 34. Rxc4 Na5 35. Rc7+ Rb7 36. Rc1 Nb3 37. Rb1 Nd4 38. Rd1 Rc7 39. f3 Rc2+ 40. Ka3 Rxg2 41. Rc1 1-0",
    B: "1852",
  },
  {
    W: "2286",
    T: "60+0",
    P: "1. e4 c5 2. c3 Nc6 3. d4 cxd4 4. cxd4 d5 5. e5 Bf5 6. Nc3 e6 7. Bd3 Nge7 8. Nf3 Bxd3 9. Qxd3 Nf5 10. O-O Be7 11. a3 Nh4 12. b4 Rc8 13. Nxh4 Bxh4 14. Be3 Be7 15. h3 O-O 16. Rad1 f6 17. exf6 Bxf6 18. Rfe1 a6 19. b5 axb5 20. Nxb5 Qd7 21. Bf4 Be7 22. a4 Rxf4 23. Re3 Rcf8 24. Rd2 Bg5 25. Rde2 R4f6 26. Rg3 Bf4 27. Rf3 Bb8 28. Rxf6 Rxf6 29. Re1 Qf7 30. Re2 Qh5 31. Qe3 Qg6 32. g4 h6 33. Rd2 Qf7 34. Re2 Bd6 35. Nc3 Qc7 36. Nd1 Bh2+ 37. Kf1 Bf4 38. Ke1 Qf7 0-1",
    B: "2237",
  },
  {
    W: "2299",
    T: "60+0",
    P: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 O-O 5. Nf3 c5 6. e4 cxd4 7. Nxd4 d5 8. cxd5 exd5 9. e5 Re8 10. f4 Ne4 11. Bd3 Nc6 12. O-O Nxd4 13. Qd1 Nxc3 14. bxc3 Bxc3 15. Rb1 Bf5 16. h3 Qh4 17. Be3 Bxd3 18. Qxd3 Qg3 19. Rbd1 Bb2 20. Bxd4 Bxd4+ 21. Qxd4 Rad8 22. Rde1 g6 23. f5 gxf5 24. Re3 Qg6 25. Ref3 Re6 26. Rxf5 Rde8 27. h4 Qg4 28. Rg5+ Qxg5 29. hxg5 Rxe5 30. Qf4 R8e7 31. Qf6 d4 32. Rd1 R5e6 33. Qf3 Re5 34. Rxd4 Rxg5 35. Rg4 Rxg4 36. Qxg4+ Kf8 37. Qc8+ Kg7 38. Qg4+ 1-0",
    B: "2380",
  },
  {
    W: "1892",
    T: "180+0",
    P: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 dxc4 5. Nf3 Bg4 6. Bxc4 Nf6 7. Nc3 e6 8. Qa4+ Nbd7 9. Ne5 Bh5 10. Bb5 a6 11. Nxd7 Nxd7 12. Bd3 Be7 13. O-O O-O 14. f4 Nb6 15. Qa5 Qxd4+ 16. Kh1 Qc5 17. Qxc5 Bxc5 18. h3 f6 19. Ne4 Be7 20. Be3 Nd5 21. Bd2 Bg6 22. g4 Rfd8 23. f5 exf5 24. gxf5 Bf7 25. Bc4 b5 26. Bb3 a5 27. Nc3 a4 28. Bxd5 Bxd5+ 29. Nxd5 Rxd5 30. Bc3 b4 31. Be1 Rc8 32. Bg3 Rc2 33. Rae1 Kf7 34. b3 axb3 35. axb3 Rd3 36. Rb1 Rxg3 0-1",
    B: "1911",
  },
  {
    W: "2073",
    T: "180+0",
    P: "1. d4 Nf6 2. Bf4 g6 3. e3 Bg7 4. c3 O-O 5. Bc4 d5 6. Bb3 c5 7. dxc5 Nc6 8. Ne2 e5 9. Bg3 Qe7 10. Bxd5 Qxc5 11. Bxc6 bxc6 12. O-O Ba6 13. Re1 Rab8 14. Qc2 Rfe8 15. Nd2 Rbd8 16. Nb3 Qb6 17. Rad1 Nh5 18. Rxd8 Rxd8 19. Rd1 Nxg3 20. Nxg3 h5 21. Rxd8+ Qxd8 22. Qd2 Qe7 23. f3 Bh6 24. Kf2 h4 25. Ne4 h3 26. g4 Qh4+ 27. Ng3 f5 28. gxf5 gxf5 29. Qd7 f4 30. Qe6+ Kh8 31. Qxe5+ Kh7 32. Qf5+ Kh8 33. exf4 Qd8 34. Qg5 Bxg5 35. fxg5 Qxg5 36. Nd4 Qd2+ 37. Kg1 Qg2# 0-1",
    B: "2072",
  },
  {
    W: "1053",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d3 g6 4. d4 Nxd4 5. Nxd4 exd4 6. Qxd4 c5 7. Qxh8 1-0",
    B: "955",
  },
  {
    W: "2438",
    T: "180+0",
    P: "1. d4 Nf6 2. Nf3 d6 3. Bf4 Bg4 4. Nbd2 e6 5. h3 Bh5 6. c3 c6 7. e4 d5 8. e5 Ne4 9. g4 Nxd2 10. gxh5 Nxf3+ 11. Qxf3 h6 12. Rg1 Nd7 13. Bd3 Qb6 14. O-O-O c5 15. Be3 Qa5 16. Kb1 c4 17. Bc2 b5 18. Rg4 b4 19. Bd2 Qb6 20. Rf4 f5 21. exf6 gxf6 22. Rxf6 Nxf6 23. Qxf6 Rg8 24. Bg6+ Kd7 25. Qf7+ Be7 26. Bg5 Qd6 27. Bf4 Qc6 28. Be5 Raf8 29. Qh7 bxc3 30. bxc3 Rxf2 31. Ka1 Qa4 32. Bb1 Rgg2 0-1",
    B: "2273",
  },
  {
    W: "2110",
    T: "180+0",
    P: "1. d4 Nf6 2. Nf3 e6 3. Bf4 c5 4. c3 d5 5. e3 Nc6 6. Bd3 Bd6 7. Bg3 O-O 8. Nbd2 Re8 9. O-O Bxg3 10. hxg3 e5 11. dxe5 Nxe5 12. Nxe5 Rxe5 13. Nf3 Rh5 14. Qe2 c4 15. Bc2 Bg4 16. e4 dxe4 17. Bxe4 Re5 18. Bxh7+ Kxh7 19. Qxe5 Bxf3 20. gxf3 Qd3 21. Rad1 Qxf3 22. Rd4 Re8 23. Qf4 Qe2 24. Qh4+ Kg8 25. Rxc4 Ne4 26. Rd4 Nd2 27. Rxd2 Qxd2 28. Qb4 b6 29. a4 Re1 30. Rxe1 Qxe1+ 31. Kg2 Qd2 32. b3 Qd5+ 33. Kh2 Qh5+ 34. Kg2 Qd5+ 35. f3 Qd2+ 36. Kh3 Qh6+ 37. Qh4 Qe3 38. Qd8+ Kh7 39. Qh4+ Kg8 40. Qe4 Qxc3 41. Qa8+ Kh7 42. Qxa7 Qxb3 43. Qe7 Qe6+ 44. Qxe6 fxe6 45. g4 Kg6 46. f4 Kf6 47. Kg3 g5 48. fxg5+ Kxg5 49. Kf3 e5 50. Ke4 Kxg4 51. Kxe5 Kf3 52. Kd5 Kf4 53. Kc6 Ke5 54. Kxb6 1-0",
    B: "2146",
  },
  {
    W: "1605",
    T: "180+0",
    P: "1. d4 g6 2. e3 Bg7 3. Nf3 Nf6 4. Nc3 O-O 5. h3 c6 6. b3 d6 7. Bb2 Qb6 8. Be2 Nbd7 9. Qd2 c5 10. g4 cxd4 11. exd4 e5 12. dxe5 dxe5 13. O-O-O e4 14. Nd4 Nc5 15. g5 Nh5 16. Bxh5 gxh5 17. h4 Bxd4 18. Qxd4 Bf5 19. Nd5 Qe6 20. Qg7# 1-0",
    B: "1620",
  },
  {
    W: "2213",
    T: "180+0",
    P: "1. d4 g6 2. e4 Bg7 3. Nc3 c6 4. f4 d5 5. e5 Nh6 6. Bd3 Bf5 7. Be3 O-O 8. h3 Qd7 9. g4 Bxd3 10. Qxd3 f6 11. O-O-O fxe5 12. fxe5 e6 13. Nge2 c5 14. dxc5 Nc6 15. Nd4 Nxe5 16. Qb5 Qxb5 17. Ncxb5 Nc4 18. Bg5 e5 19. Ne6 Rf2 20. b3 a6 21. Nbc7 Rc8 22. bxc4 Nf7 23. Be3 Re2 24. Rhe1 Rxe1 25. Rxe1 d4 26. Bg1 Bh6+ 27. Kb2 Nd8 28. Nxd8 Rxd8 29. Nd5 Bg7 30. Bh2 Re8 31. Kc1 e4 32. Kd2 e3+ 33. Kd3 h5 34. gxh5 gxh5 35. c3 dxc3 36. Nxc3 Bh6 37. Nd5 Rf8 38. Nxe3 Rf3 39. Ke4 Rxh3 40. Bd6 Kf7 41. Rf1+ Ke6 42. Nd5 Kd7 43. Rf7+ Ke6 44. Rxb7 Rh4+ 45. Kf3 Rxc4 46. Nf4+ Bxf4 47. Bxf4 Rxc5 48. Rb6+ 1-0",
    B: "2269",
  },
  {
    W: "1603",
    T: "180+0",
    P: "1. d4 g6 2. c4 Bg7 3. Nc3 e6 4. Nf3 c6 5. Bf4 d5 6. e3 Ne7 7. Qc2 Nd7 8. Bd3 O-O 9. c5 b6 10. b4 a5 11. bxa5 bxc5 12. dxc5 Nxc5 13. Be2 Qxa5 14. Rc1 Bxc3+ 15. Qxc3 Qxc3+ 16. Rxc3 Ne4 17. Rc2 Bd7 18. O-O Ra5 19. Ne5 Bc8 20. Ra1 c5 21. Nd3 c4 22. Ne5 Nc5 23. Rb2 Nf5 24. Nc6 Ra6 25. Nb4 Rb6 26. a3 Bd7 27. Rba2 Nb3 28. Rd1 Ra8 29. Bc7 Rb7 30. Be5 Ba4 31. Rb1 Nd2 32. Rxd2 Bb3 33. f4 Rxa3 34. Bf3 Rxb4 35. Rdb2 Nxe3 36. Bd6 Rba4 37. Bxa3 Rxa3 0-1",
    B: "1533",
  },
  {
    W: "2099",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Bc5 5. O-O Ng4 6. h3 h5 7. c3 Qf6 8. d4 exd4 9. cxd4 Nxd4 10. Nxd4 Bxd4 11. Nc3 Nxf2 12. Rxf2 Qxf2+ 13. Kh1 c6 14. Bf4 Qxf4 15. Qxd4 O-O 16. Rf1 Qh4 17. Qd6 b5 18. Bb3 a5 19. Qg6 d5 20. Qxc6 Bxh3 21. gxh3 Qxh3+ 22. Kg1 Qg3+ 23. Kh1 dxe4 24. Nxe4 Qh3+ 25. Kg1 Rac8 26. Bxf7+ Kh8 27. Be6 Rxf1# 0-1",
    B: "2078",
  },
  {
    W: "1629",
    T: "180+0",
    P: "1. d4 b6 2. Nd2 Bb7 3. e4 e6 4. c3 Qh4 5. Ngf3 Qd8 6. Nb3 Nf6 7. Bd3 Nxe4 8. O-O d5 9. Re1 f5 10. Ne5 Nd7 11. Qh5+ g6 12. Nxg6 Qg5 13. Qxg5 Nxg5 14. Nxh8 O-O-O 15. Bxg5 Re8 16. Nf7 h6 17. Bxh6 Bxh6 18. Nxh6 Rh8 19. Nf7 Rh4 20. Rxe6 f4 21. Re8# 1-0",
    B: "1628",
  },
  {
    W: "1972",
    T: "180+0",
    P: "1. Nf3 b6 2. g3 Bb7 3. Bg2 Nc6 4. d4 e6 5. O-O Nf6 6. c4 d5 7. cxd5 exd5 8. Nc3 Bb4 9. Ne5 O-O 10. Bd2 Re8 11. Nxc6 Bxc6 12. a3 Bd6 13. Bg5 h6 14. Bxf6 Qxf6 15. Nxd5 Qe6 16. Ne3 Bxg2 17. Kxg2 Rad8 18. d5 Qe4+ 19. Kg1 Bc5 20. Qb3 Bxe3 21. Qxe3 Qxe3 22. fxe3 Rxe3 23. Kf2 Re5 24. Rfc1 Rd7 25. Rc6 Rexd5 26. Rac1 Rc5 27. R1xc5 bxc5 28. Rxc5 f6 29. Rc6 g5 30. Rxf6 Kg7 31. Rc6 h5 32. Ra6 c5 33. Rc6 Rf7+ 34. Kg2 Rf5 35. Rc7+ Kg6 36. Rxa7 Re5 37. Kf2 Rf5+ 38. Ke3 Re5+ 39. Kd3 Rd5+ 40. Kc4 Rd2 41. Kxc5 Rxb2 42. Re7 Rc2+ 43. Kb4 Rb2+ 44. Ka4 Kf6 45. Re8 Kf7 46. Re4 Kf6 47. e3 Rxh2 48. Kb4 Rg2 49. a4 Rxg3 50. a5 Rg4 51. Rxg4 hxg4 52. a6 g3 53. a7 g2 54. a8=Q g1=Q 55. Qf8+ Ke5 56. Qe7+ Kf5 57. Qd7+ Ke4 58. Qe6+ Kf3 59. e4 Qb1+ 60. Kc5 Qc2+ 61. Kd4 Qd2+ 62. Kc5 Qc3+ 63. Kd5 Qb3+ 64. Kd6 Qxe6+ 65. Kxe6 Kxe4 66. Kf6 g4 67. Ke7 g3 68. Kd6 g2 69. Kc6 g1=Q 70. Kd7 Qd1+ 71. Ke7 Qa4 72. Kf7 Qa6 73. Kg7 Qe6 74. Kh7 Qf6 75. Kg8 Qe7 76. Kh8 Kf5 77. Kg8 Kg6 78. Kh8 Qf7 1/2-1/2",
    B: "1926",
  },
  {
    W: "1838",
    T: "180+0",
    P: "1. b3 d5 2. Bb2 f6 3. e3 e6 4. Bd3 Bd6 5. Qh5+ g6 6. Bxg6+ Ke7 7. Bd3 e5 8. Nf3 Be6 9. e4 d4 10. Na3 a6 11. Nc4 Nd7 12. O-O-O Bf7 13. Qh3 b5 14. Nxd6 cxd6 15. Nh4 Qc7 16. Nf5+ Kd8 17. Ba3 Nc5 18. Kb1 Rb8 19. c3 dxc3 20. dxc3 a5 21. Bxc5 Qxc5 22. Nxd6 Qxd6 23. Bxb5 Rxb5 24. Rxd6+ Ke8 25. Qd7+ Kf8 26. Qxb5 Kg7 27. Qxa5 Nh6 28. c4 Ng4 29. c5 Nxf2 30. Rf1 Nxe4 31. Rd3 Rc8 32. b4 Bc4 33. Rdf3 Nd2+ 34. Kb2 Nxf1 35. Qa6 Bxa6 0-1",
    B: "1847",
  },
  {
    W: "1413",
    T: "180+0",
    P: "1. e4 e6 2. Nf3 c5 3. Bc4 h6 4. d3 a6 5. Be3 Nc6 6. Nc3 Qb6 7. b3 Qc7 8. d4 cxd4 9. Nxd4 Qa5 10. Qd2 Ne5 11. O-O Bb4 12. a3 Bxc3 13. b4 Bxd2 14. Bxd2 Nxc4 15. bxa5 Nxd2 16. Rfd1 Nxe4 0-1",
    B: "1339",
  },
  {
    W: "1311",
    T: "180+0",
    P: "1. e4 c5 2. Bc4 e6 3. d3 a6 4. h3 b5 5. Bb3 Bb7 6. a3 Nc6 7. Nf3 h6 8. O-O Nf6 9. Be3 Qc7 10. g3 O-O-O 11. Bf4 Bd6 12. Bxd6 Qxd6 13. Re1 Qe7 14. e5 Nd5 15. Bxd5 exd5 16. c4 d4 17. cxb5 axb5 18. Nbd2 Na5 19. b4 cxb4 20. axb4 Nc6 21. Qb3 Nxb4 22. Ra5 Bxf3 23. Rc1+ Nc6 24. Ra8+ Kc7 25. Ra7+ Kc8 26. Qxb5 1-0",
    B: "1168",
  },
  {
    W: "1439",
    T: "300+3",
    P: "1. d4 d5 2. e3 e6 3. Nf3 Nf6 4. a3 Be7 5. Be2 O-O 6. b4 Nbd7 7. O-O b6 8. Bb2 a5 9. c3 axb4 10. cxb4 Ba6 11. b5 Bb7 12. a4 c6 13. Nbd2 cxb5 14. Bxb5 Ba6 15. Bxa6 Rxa6 16. Ne5 Nxe5 17. dxe5 Nd7 18. Nf3 f6 19. exf6 Nxf6 20. Nd4 Qb8 21. Nxe6 Re8 22. Qxd5 Nxd5 23. Nxg7 Rc8 24. Nf5 Kf8 25. Rfd1 Bf6 26. Bxf6 Nxf6 27. g3 Ra5 28. Nd4 Ne4 29. Ne6+ Kg8 30. Rd7 Qe5 31. Nf4 Qxa1+ 32. Kg2 0-1",
    B: "1492",
  },
  {
    W: "1994",
    T: "300+3",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 d6 4. Bc4 h6 5. O-O Be6 6. Bxe6 fxe6 7. Nd4 Qf6 8. Nb5 Na6 9. d4 g5 10. Qh5+ Qf7 11. Qxf7+ Kxf7 12. g3 e5 13. gxf4 gxf4 14. dxe5 dxe5 15. N5a3 Bxa3 16. Nxa3 Nf6 17. Nc4 Ke6 18. b3 Rhg8+ 19. Kh1 b5 20. Nxe5 Kxe5 21. Bb2+ Ke6 22. Rxf4 Raf8 23. Raf1 Nd7 24. Rxf8 Rxf8 25. Rxf8 Nxf8 26. Bg7 Ng6 27. Bxh6 Ke5 28. Be3 Nb4 29. Bxa7 Nxc2 30. Bb8 Kxe4 31. Bxc7 Nb4 32. a4 bxa4 33. bxa4 Na6 34. Bb6 Kd5 35. Kg2 Kc4 36. Kg3 Kb4 37. a5 Kb5 38. Kg4 Nb8 39. Kf5 Nh4+ 40. Kg4 Ng2 41. h4 Nxh4 42. Kxh4 Nc6 43. Kg4 Nxa5 44. Bxa5 1/2-1/2",
    B: "1999",
  },
  {
    W: "1306",
    T: "300+3",
    P: "1. d4 d5 2. c4 c6 3. cxd5 cxd5 4. Nc3 Nf6 5. Nf3 Bg4 6. Ne5 Bh5 7. h3 Nbd7 8. Qa4 e6 9. Bg5 Be7 10. g4 Bg6 11. h4 h6 12. Bxf6 Bxf6 13. Nxg6 fxg6 14. Qc2 Bxd4 15. Qxg6+ Kf8 16. Qxe6 Qe7 17. Qxe7+ Kxe7 18. Nxd5+ Ke6 19. Nc7+ Ke5 20. Nxa8 Rxa8 21. Rd1 Rf8 22. e3 1-0",
    B: "1306",
  },
  {
    W: "1067",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. Nxe5 Nf6 6. Nf3 Nxe4 7. d3 Ng5 8. Bxg5 Qxg5 9. Nxg5 Bb4+ 10. c3 Be7 11. Ne4 O-O 12. O-O f5 13. Ng3 f4 14. Qe1 fxg3 15. Qxe7 gxf2+ 16. Rxf2 Rxf2 17. Kxf2 Bf5 18. Qxc7 Bxd3 19. Qxb7 Rf8+ 20. Ke3 Bf5 21. Qb3+ Rf7 22. Qb8+ Rf8 23. Qd6 Re8+ 24. Kf4 g6 25. Qxc6 Re6 26. Qxe6+ Bxe6 27. Nd2 Kg7 28. Re1 Bxa2 29. Ne4 Bd5 30. Ra1 Bc4 31. Nd2 Bd3 32. Ke3 g5 33. Kxd3 1-0",
    B: "1079",
  },
  {
    W: "1103",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 f6 4. c3 Nge7 5. d4 Na5 6. dxe5 Nxc4 7. exf6 gxf6 8. Qd4 b5 9. Qxf6 Ng6 10. Qxd8+ Kxd8 11. Bg5+ Be7 12. Bxe7+ Kxe7 13. O-O Bb7 14. b3 Nce5 15. Nd4 a6 16. Re1 Nd3 17. Re3 Nge5 18. h3 c5 19. Nf5+ Kf6 20. f4 Bxe4 21. Rxe4 Kxf5 22. Nd2 Rhg8 23. g4+ Ke6 24. fxe5 h5 25. Rd1 Nxe5 26. Rde1 Kd5 27. Rxe5+ Kc6 28. Rxh5 Raf8 29. Rh6+ d6 30. Ne4 Re8 31. Rxd6+ Kc7 32. Rh6 Rh8 33. Rxh8 Rxh8 34. Rf1 Rxh3 35. Rf7+ Kb6 36. Rg7 Re3 37. Ng5 Re1+ 38. Kf2 Re5 39. Nf3 Rd5 40. g5 Rf5 41. Rg8 b4 42. cxb4 1-0",
    B: "1227",
  },
  {
    W: "1424",
    T: "300+3",
    P: "1. e4 Nc6 2. Nf3 e5 3. Nc3 Nf6 4. d4 d6 5. Bg5 g6 6. h3 Bg7 7. Bc4 O-O 8. Nh2 a6 9. Ng4 Qe8 10. Nxf6+ Bxf6 11. Bxf6 Nxd4 12. h4 b5 13. Bd3 h5 14. g4 Bxg4 15. Be2 Nxe2 16. Nxe2 Qe6 17. Bg5 Qc4 18. f3 Qb4+ 19. c3 Qxb2 20. fxg4 hxg4 21. Rg1 f5 22. Bh6 Rf7 23. h5 gxh5 24. Bc1 Qxa1 25. Rh1 Rh7 26. Ng3 fxe4 27. Nxh5 Qxc3+ 28. Bd2 Qd3 29. Qxg4+ Kh8 30. Bg5 Qb1+ 31. Kf2 Qxh1 32. Bf6+ Rg7 33. Qxg7# 1-0",
    B: "1299",
  },
  {
    W: "1298",
    T: "300+3",
    P: "1. e4 Nf6 2. e5 Nd5 3. Bc4 Nb6 4. d3 d5 5. exd6 cxd6 6. Nf3 Nxc4 7. dxc4 Bg4 8. O-O g6 9. h3 Bxf3 10. Qxf3 Bg7 11. c3 Nc6 12. Rd1 b6 13. b4 Qc7 14. Bb2 O-O 15. b5 Ne5 16. Qf4 Nxc4 17. Rd5 Nxb2 18. Nd2 e5 19. Qb4 e4 20. Qxb2 Bxc3 21. Qc2 Rac8 22. Rc1 Bxd2 23. Qxc7 Bxc1 24. Qxa7 e3 25. fxe3 Rfe8 26. Rxd6 Bxe3+ 27. Kh2 Bf4+ 28. g3 Re2+ 29. Kg1 Rc1+ 30. Rd1 Rxd1# 0-1",
    B: "1262",
  },
  {
    W: "1882",
    T: "300+3",
    P: "1. Nf3 c5 2. c4 d6 3. d4 cxd4 4. Nxd4 Nf6 5. g3 g6 6. Bg2 Bg7 7. Nc3 Qc7 8. b3 Nc6 9. Ndb5 Qd7 10. Bb2 O-O 11. Rc1 a6 12. Nd4 e5 13. Nc2 Rb8 14. O-O b6 15. Nd5 Nxd5 16. Bxd5 Bb7 17. e4 Ne7 18. Nb4 Bxd5 19. Nxd5 Nxd5 20. Qxd5 b5 21. Rfd1 bxc4 22. Rxc4 Rfd8 23. Rc6 Qb7 24. Rxd6 Rxd6 25. Qxd6 Qxe4 26. Qxb8+ Bf8 27. Qd8 Qe2 28. Ba3 Kg7 29. Bxf8+ Kg8 30. Bh6# 1-0",
    B: "1618",
  },
  {
    W: "1520",
    T: "300+3",
    P: "1. e4 e5 2. Bc4 Nc6 3. c3 Nf6 4. d3 d6 5. Bg5 Bg4 6. f3 Bd7 7. Ne2 h6 8. Bh4 Be7 9. Bf2 a6 10. O-O b5 11. Bb3 a5 12. a4 bxa4 13. Bxa4 Rb8 14. Qc2 Ra8 15. Nd2 O-O 16. Nc4 Nb8 17. Bxd7 Nbxd7 18. Nxa5 d5 19. Nc6 Rxa1 20. Rxa1 Qe8 21. Nxe7+ Qxe7 22. exd5 Nxd5 23. Ng3 f5 24. Ra5 Nf4 25. d4 e4 26. fxe4 fxe4 27. Nxe4 Nf6 28. Re5 Qd7 29. Nc5 Qf7 30. Bg3 Nd7 31. Nxd7 Nh3+ 32. Kh1 Qf1# 0-1",
    B: "1536",
  },
  {
    W: "1873",
    T: "300+3",
    P: "1. e4 e6 2. d4 d6 3. Nf3 g6 4. Bc4 Bg7 5. Bg5 Ne7 6. Nbd2 Nd7 7. c3 b6 8. O-O Bb7 9. Re1 h6 10. Bh4 g5 11. Bg3 Ng6 12. Qb3 Nf4 13. Rad1 h5 14. Bxf4 gxf4 15. h4 Rg8 16. Ng5 d5 17. exd5 f6 18. Nxe6 Qc8 19. Nxg7+ Kf8 20. Ne6+ Kf7 21. Nf3 Rg4 22. Kf1 Qg8 23. d6 Qc8 24. Nxf4+ Kg7 25. Re7+ Kh6 26. Ne6 Bxf3 27. gxf3 Rxh4 28. Qc2 Qg8 29. Qd2+ Kg6 30. Bd3+ f5 31. Qg5# 1-0",
    B: "1529",
  },
  {
    W: "2139",
    T: "30+0",
    P: "1. d4 e6 2. Nf3 Ne7 3. c4 d6 4. Nc3 c6 5. Bg5 d5 6. Bh4 dxc4 7. e3 c5 8. Bxc4 cxd4 9. Nxd4 g6 10. Qb3 Qd7 11. O-O-O Nbc6 12. Nxe6 Qxd1+ 13. Rxd1 Bxe6 14. Bxe6 fxe6 15. Qxe6 Bg7 16. Nd5 Rf8 17. Nc7# 1-0",
    B: "1987",
  },
  {
    W: "1722",
    T: "60+0",
    P: "1. g3 d5 2. Bg2 e5 3. d4 exd4 4. Qxd4 Nc6 5. Qxd5 Qxd5 6. Bxd5 Nf6 7. Bxc6+ bxc6 8. Nc3 Be6 9. Bg5 Bd6 10. O-O-O O-O 11. Bxf6 gxf6 12. h4 Be5 13. e4 Rab8 14. Rd3 Rb7 15. Nf3 Rfb8 16. b3 Bxc3 17. Rxc3 a5 18. Rxc6 a4 19. Nd4 axb3 20. Nxe6 bxc2 21. Rxc7 Rb1+ 22. Kxc2 Rxh1 23. Nc5 Rb5 24. Kd3 Rd1+ 25. Kc4 Rdb1 26. Nd3 Rb7 27. Rc8+ Kg7 28. e5 Rd7 29. exf6+ Kxf6 30. Rc6+ Kg7 31. Ne5 Rc1+ 32. Kb5 Rxc6 33. Nxd7 Rc2 34. Ne5 Rxa2 35. f4 Rg2 36. g4 Rf2 37. f5 Rf4 38. Kc6 f6 39. Nd7 Rxg4 40. Kd6 Rxh4 41. Ke6 Re4+ 42. Kd5 Re5+ 43. Kd6 Rxf5 44. Ke6 Re5+ 0-1",
    B: "1750",
  },
  {
    W: "1780",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Be6 4. Bb5+ Bd7 5. Bd3 Be6 6. c4 Nf6 7. Bc2 Bxc4 8. d3 Be6 9. Nc3 Nc6 10. Be3 Be7 11. Qd2 Qd7 12. h4 h6 13. Qe2 Ng4 14. Bd2 Nf6 15. Be3 d5 16. exd5 Bxd5 17. Nxd5 Nxd5 18. Bd2 f6 19. Bb3 Nf4 20. Bxf4 exf4 21. O-O-O O-O-O 22. Rhe1 Rhe8 23. Be6 Bd6 24. Bxd7+ Kxd7 25. Qxe8+ Rxe8 26. Rxe8 Kxe8 27. d4 Nb4 28. Kb1 Nd5 29. Re1+ Kd7 30. Rd1 g5 31. a3 g4 32. Ng1 Ne7 33. Ne2 Nf5 34. Nc3 f3 35. g3 c6 36. Ne4 Kc7 37. Nxd6 Kxd6 38. d5 Ne7 39. dxc6+ Kxc6 40. Rc1+ Kb6 41. Re1 a6 42. Re6+ Ka7 43. Rxe7 Kb6 44. Re6+ Kc5 45. Rxf6 Kc4 46. Rxh6 Kd4 47. Rg6 1-0",
    B: "1791",
  },
  {
    W: "2061",
    T: "60+0",
    P: "1. Nf3 d6 2. g3 e5 3. Bg2 Nc6 4. O-O Be7 5. d4 exd4 6. Nxd4 Nxd4 7. Qxd4 Nf6 8. Nc3 O-O 9. e4 h6 10. Be3 Nh7 11. Qd2 Bf6 12. Nd5 c6 13. Nxf6+ Qxf6 14. c3 Rd8 15. Bd4 Qe7 16. Rae1 Be6 17. f4 Bxa2 18. b4 Bc4 19. Rf2 Re8 20. Qe3 Rad8 21. Bxa7 b5 22. Bb6 Rd7 23. Bd4 Nf6 24. e5 dxe5 25. fxe5 Nd5 26. Bxd5 Rxd5 27. e6 Qxe6 28. Qxe6 Rxe6 29. Rxe6 fxe6 30. Bc5 Rd1+ 31. Kg2 Bd5+ 32. Kh3 Rd3 33. Rf8+ Kh7 34. Bd4 Rf3 35. Re8 Kg6 36. Re7 Kf5 37. Rxg7 Ke4 38. Rg6 Rf5 39. Rxh6 Kd3 40. Kg4 Kc4 41. h4 Rf2 42. Rh7 Rd2 43. Rc7 Kb3 44. Kg5 Rd3 45. g4 Rxc3 46. h5 Rd3 47. Bc5 Rd4 48. Rf7 Re4 49. Rf3+ 1-0",
    B: "2042",
  },
  {
    W: "1279",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 d6 3. h3 Nc6 4. d4 cxd4 5. Nxd4 Nxd4 6. Qxd4 e5 7. Qc3 Nf6 8. f3 Be7 9. Be3 O-O 10. Nd2 a6 11. Nb3 b5 12. O-O-O Be6 13. g4 Qc8 14. g5 Qxc3 15. bxc3 Nd7 16. Rg1 Nc5 17. h4 Nxb3+ 18. cxb3 Rac8 19. Kb2 d5 20. exd5 Bxd5 21. Rxd5 Rfd8 22. Rxe5 f6 23. Rxe7 Re8 24. Rxe8+ Rxe8 25. Bd2 Rd8 26. Bc1 Rd1 27. Bd3 Rxg1 28. gxf6 gxf6 29. Be4 Rg3 30. Ka3 Rg1 31. Bf4 Rf1 32. Bd6 Kf7 33. Be7 Kxe7 34. Bxh7 Rxf3 35. Be4 Rxc3 36. h5 Rh3 37. Bg6 Rxh5 38. Bxh5 Ke6 39. Kb4 f5 40. Ka5 f4 41. Kxa6 f3 42. Kxb5 f2 43. Be2 Ke5 44. Bf1 Kf4 45. Kc4 Ke3 0-1",
    B: "1197",
  },
  {
    W: "1643",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 Nf6 4. d5 Nd4 5. Nxd4 cxd4 6. Qxd4 Qb6 7. Qxb6 axb6 8. f3 e5 9. Bb5 Bd6 10. O-O Ra5 11. a4 Bc5+ 12. Kh1 O-O 13. c3 d6 14. b4 Ra8 15. bxc5 Nh5 16. cxb6 1-0",
    B: "1610",
  },
  {
    W: "1440",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 g6 3. Bc4 Bg7 4. Nc3 Ne7 5. d4 d5 6. exd5 exd5 7. Bd3 Bg4 8. O-O Bxf3 9. Qxf3 Bxd4 10. Re1 Bxc3 11. bxc3 O-O 12. Bg5 Re8 13. Rab1 b6 14. Rb5 Nbc6 15. Rxd5 Qc8 16. Bh6 Nxd5 17. Rxe8+ Qxe8 18. Qxd5 Ne7 19. Qf3 Nf5 20. h3 Qe1+ 21. Kh2 Nxh6 22. Qxa8+ Kg7 23. Qc8 Qxf2 24. Qxc7 Nf5 25. Qe5+ Kh6 26. Qe8 Ne3 27. Qf8+ Kg5 28. h4+ Kg4 29. Qh6 Qxg2# 0-1",
    B: "1494",
  },
  {
    W: "1384",
    T: "180+2",
    P: "1. d4 d5 2. Bf4 Nf6 3. Nc3 Nc6 4. e3 Bf5 5. a3 e6 6. Nf3 Bd6 7. Bxd6 Qxd6 8. Bd3 Bxd3 9. cxd3 O-O 10. O-O Rfe8 11. h3 e5 12. dxe5 Nxe5 13. Nxe5 Qxe5 14. d4 Qe6 15. Ne2 c6 16. Nf4 Qd6 17. b4 b5 18. Qd3 a5 19. Rfc1 axb4 20. axb4 Rxa1 21. Rxa1 Qxb4 22. Ra6 Rc8 23. Kh2 c5 24. dxc5 Rxc5 25. Ra8+ Ne8 26. Rxe8# 1-0",
    B: "1347",
  },
  {
    W: "1424",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 h6 4. Nc3 Nf6 5. d3 c6 6. Be3 Be7 7. O-O O-O 8. h3 b5 9. Bb3 Na6 10. a4 b4 11. Ne2 c5 12. Nh4 g6 13. Ng3 Kg7 14. f4 Nxe4 15. dxe4 Bxh4 16. Ne2 g5 17. g3 gxf4 18. gxh4 fxe3 19. Kh2 Qxh4 20. Rg1+ Kf6 21. Qf1+ Qf2+ 22. Qxf2+ exf2 23. Rgf1 Bb7 24. Rxf2+ Ke7 25. Bd5 Bxd5 26. exd5 Nc7 27. c3 Nxd5 28. cxb4 Nxb4 29. Raf1 Nd3 30. Rf6 Nxb2 31. Rxh6 Nxa4 32. Ng3 Rab8 33. Nf5+ Ke8 34. Nxd6+ Ke7 35. Nf5+ Kd7 36. Rd6+ Kc7 37. Rd5 Rb2+ 38. Kg3 Rg8+ 39. Kh4 Rb4+ 40. Kh5 Rh8+ 41. Nh6 e4 42. Rxf7+ Kc6 43. Re5 Nc3 44. Rf6+ Kb5 45. Re7 a5 46. Kg6 a4 47. Nf5 Rxh3 48. Nd6+ Kc6 49. Nxe4+ Kd5 50. Nxc3+ Rxc3 51. Rd7+ Kc4 52. Rf4+ Kb3 53. Rxb4+ cxb4 54. Rd2 0-1",
    B: "1447",
  },
  {
    W: "1336",
    T: "180+2",
    P: "1. Nf3 e6 2. d4 d5 3. c4 Nf6 4. Nc3 c5 5. e3 Nc6 6. cxd5 exd5 7. dxc5 Bxc5 8. Bd3 O-O 9. O-O Bg4 10. Qc2 Bxf3 11. gxf3 Ne5 12. Be2 Nh5 13. f4 Ng6 14. Bxh5 Qh4 15. Bxg6 fxg6 16. Kh1 Rf5 17. Rg1 Rh5 18. Rg2 Rf8 19. Ne2 Bxe3 20. fxe3 Qe1+ 21. Ng1 Qh4 22. Nf3 Qf6 23. h4 Rxh4+ 24. Nxh4 Qxh4+ 25. Rh2 Qe1+ 26. Kg2 d4 27. Qc4+ Kh8 28. Qxd4 h6 29. b3 Qe2+ 30. Kg1 Rf5 31. Rxe2 1-0",
    B: "1305",
  },
  {
    W: "2096",
    T: "180+2",
    P: "1. d4 e6 2. c4 d5 3. Nc3 Nf6 4. Nf3 c6 5. e3 Bd6 6. b3 Nbd7 7. Bb2 O-O 8. Rc1 Re8 9. Bd3 e5 10. dxe5 Nxe5 11. Bb1 Bg4 12. cxd5 cxd5 13. O-O Nxf3+ 14. gxf3 Bh3 15. Re1 Re5 16. f4 Rh5 17. Ne2 Bg4 18. Qc2 Ne4 19. Ng3 Nxg3 20. fxg3 Bf3 21. Qf2 Bg4 22. Bd3 Qe7 23. Be2 Bxe2 24. Qxe2 Rh6 25. Qd3 Re6 26. Qd4 Bc5 27. Qxg7# 1-0",
    B: "2092",
  },
  {
    W: "2018",
    T: "60+0",
    P: "1. e4 c5 2. c3 Nc6 3. d4 cxd4 4. cxd4 d5 5. e5 Bf5 6. Nc3 e6 7. a3 Nge7 8. Nge2 h6 9. Ng3 Bh7 10. h4 Nf5 11. h5 Nxg3 12. fxg3 Qb6 13. Bb5 Rc8 14. Bxc6+ Rxc6 15. O-O Be7 16. Ne2 O-O 17. Be3 Rfc8 18. Rc1 Qxb2 19. Rxc6 Rxc6 20. Bc1 Qb5 21. Rf3 Bd3 22. Rxd3 Bg5 23. Bxg5 hxg5 24. Kh2 Rc4 25. g4 Kh7 26. Qd2 Qa4 27. Qxg5 b5 28. Qf4 a5 29. Qxf7 b4 30. Qg6+ Kg8 31. Rf3 Rc8 32. Nf4 bxa3 33. Nxe6 a2 34. Qxg7# 1-0",
    B: "2097",
  },
  {
    W: "1901",
    T: "300+0",
    P: "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. Nf3 Bc5 5. e3 Nc6 6. Nc3 Ngxe5 7. Be2 O-O 8. O-O a6 9. a3 Nxf3+ 10. Bxf3 Ne5 11. Be2 d6 12. b4 Ba7 13. Bb2 Be6 14. Nd5 c6 15. Bxe5 dxe5 16. Nc3 Qc7 17. Qd2 Rad8 18. Qc2 f6 19. Rfd1 c5 20. b5 Bb8 21. g3 axb5 22. Nxb5 Qc6 23. Bf1 Rd7 24. Bg2 Qa6 25. Rxd7 Bxd7 26. a4 Bxb5 27. cxb5 Qd6 28. Qc4+ Kh8 29. Bd5 b6 30. Rd1 g5 31. e4 Rd8 32. Qe2 Kg7 33. Qh5 Rd7 34. Qe8 Kh6 35. g4 c4 36. Rc1 Re7 37. Qf8+ Rg7 38. Qxd6 Bxd6 39. Rxc4 Ra7 40. Rc6 Bc5 41. Rxf6+ Kg7 42. Rf5 h6 43. Rxe5 Rxa4 44. Re6 Ra2 45. Rc6 Rxf2 46. Kh1 Rb2 47. Rc7+ Kf6 48. Bc6 Rb3 49. Rh7 Kg6 50. Rh8 Rh3 51. Rg8+ Kf7 52. Re8 Bd6 53. e5 Bc5 54. Rd8 Re3 55. Rd7+ Kf8 56. Rh7 Rxe5 57. Rxh6 Re2 58. Rf6+ Kg7 59. Rf3 Bd6 60. Rh3 Kf6 61. Bf3 Re1+ 62. Kg2 Rb1 63. Bc6 Bf4 64. Rf3 Ke5 65. Kf2 Rb2+ 66. Ke1 Rxh2 67. Rf1 Rh1 68. Kd1 1-0",
    B: "1792",
  },
  {
    W: "1218",
    T: "300+0",
    P: "1. d4 d5 2. Nf3 Nc6 3. g3 Bg4 4. Bg2 Nf6 5. c3 Ne4 6. Nbd2 f5 7. Nxe4 fxe4 8. Nh4 e6 9. Qc2 h6 10. Bf4 g5 11. Be5 Nxe5 12. Ng6 Nxg6 13. O-O Bd6 0-1",
    B: "1233",
  },
  {
    W: "1595",
    T: "300+0",
    P: "1. d4 b6 2. Bf4 Bb7 3. e3 e6 4. Nf3 Nf6 5. h3 d6 6. Bb5+ c6 7. Be2 h6 8. O-O Nh5 9. Bh2 Nd7 10. Nfd2 Be7 11. Bxh5 O-O 12. Be2 c5 13. Bf3 Bxf3 14. Nxf3 cxd4 15. Qxd4 Bf6 16. Qb4 a5 17. Qb3 Nc5 18. Qa3 b5 19. c4 b4 20. Qb3 Nxb3 21. axb3 Bxb2 22. Ra2 Bf6 23. Rc2 Rc8 24. Rd1 Qb6 25. Bxd6 Rcd8 26. c5 Rxd6 27. cxd6 Rd8 28. Rcd2 Bg5 29. Nxg5 hxg5 30. d7 f5 31. Rd6 Qc5 32. Rxe6 Qc2 33. Re8+ Rxe8 34. dxe8=Q+ Kh7 35. Qd8 Qxb3 36. Qd3 Qf7 37. g3 a4 38. Qd5 Qh5 39. Qxf5+ g6 40. Qf7+ Kh6 41. Qf8+ Kh7 42. Rd7# 1-0",
    B: "1552",
  },
  {
    W: "2016",
    T: "300+0",
    P: "1. c4 c5 2. Nc3 Nc6 3. Nf3 Nf6 4. d4 cxd4 5. Nxd4 g6 6. e4 e5 7. Nc2 Bg7 8. Be2 O-O 9. Be3 Qc7 10. Nd5 Nxd5 11. cxd5 Ne7 12. Rc1 d6 13. O-O Qd8 14. Nb4 f5 15. f3 fxe4 16. fxe4 Bd7 17. Qd2 Rf7 18. Rxf7 Kxf7 19. Rf1+ Kg8 20. Bg5 Qe8 21. Qe3 Rc8 22. a3 a5 23. Na2 Rc2 24. Nc3 Rxb2 25. Qa7 Nc8 26. Qxa5 Nb6 27. Rb1 Rxb1+ 28. Nxb1 Na4 29. Nc3 Nxc3 30. Qxc3 Qc8 31. Qb4 Qc2 32. Kf2 Bg4 33. Qc4 Qxc4 34. Bxc4 Bd1 35. Ke3 Kf7 36. Bb5 h6 37. Bd8 h5 38. Kd2 Bb3 39. Kc3 Bd1 40. Kb4 Bh6 41. Bd3 Bd2+ 42. Kb5 Bf4 43. g3 Be3 44. Bb6 Bc1 45. a4 Ke7 46. Bf2 Kd7 47. Kb6 Bxa4 48. Kxb7 Ba3 49. Kb6 Bc1 50. Bb5+ Ke7 51. Bxa4 Bd2 52. Kc6 g5 53. Bb5 g4 54. Be2 Bg5 55. Kc7 Bh6 56. Kc6 Bf8 57. Kb7 Kd7 58. Kb6 Be7 0-1",
    B: "1914",
  },
  {
    W: "2064",
    T: "300+0",
    P: "1. e4 d6 2. d4 e5 3. dxe5 dxe5 4. Qxd8+ Kxd8 5. Nf3 Bd6 6. Bc4 Be6 7. Bxe6 fxe6 8. Ng5 Ke7 9. Nc3 a6 10. Be3 Nf6 11. f3 Nc6 12. O-O-O Rad8 13. Nh3 Bb4 14. Ne2 Na5 15. a3 Nc4 16. Bg5 Bc5 17. Rxd8 Rxd8 18. Nc3 Ne3 19. g3 h6 20. Bxe3 Bxe3+ 21. Kb1 Rd2 22. Re1 Rxh2 23. Rxe3 Rxh3 24. Ne2 Rh1+ 25. Ka2 Rd1 26. Nc3 Rd2 27. Kb3 Nd7 28. f4 exf4 29. gxf4 b6 30. Rg3 Kf7 31. e5 Nc5+ 32. Kc4 Rxc2 33. b4 Na4 34. Kb3 Rxc3+ 35. Rxc3 Nxc3 36. Kxc3 Kg6 37. Kd3 Kf5 38. Ke3 h5 39. Kf3 h4 0-1",
    B: "2183",
  },
  {
    W: "1654",
    T: "300+0",
    P: "1. Nf3 b6 2. g3 Bb7 3. Bg2 e5 4. O-O e4 5. Ne1 Bc5 6. Nc3 h5 7. Nxe4 Bxe4 8. Bxe4 Nc6 9. Nd3 h4 10. Nxc5 hxg3 11. fxg3 bxc5 12. Bxc6 dxc6 13. e4 Qd4+ 14. Kh1 Qxe4+ 15. Qf3 Qxf3+ 16. Rxf3 Ne7 17. d3 Rh3 18. Bf4 Kd7 19. Re1 Rah8 20. Rf2 Ng6 21. Be3 Ne5 22. Bxc5 Nf3 23. Re7+ Kc8 24. Rxf3 Rxh2+ 25. Kg1 Rh1+ 26. Kf2 R8h2+ 27. Ke3 Re1+ 28. Kd4 Rxe7 29. Bxe7 Rxc2 30. Ba3 f6 31. Ke4 Kd7 32. Kf5 Rh2 33. Kg6 Ke6 34. Kxg7 f5 35. Kg6 Ke5 36. Rxf5+ Kd4 37. Bc5+ Kxd3 38. Bxa7 Rxb2 39. Rc5 Rxa2 40. Bb8 Kd4 41. Rxc6 Kd5 42. Rxc7 Ra6+ 43. Kf7 Ke4 44. Re7+ Kf5 45. Bf4 Rf6+ 46. Ke8 Kg6 47. Rd7 Ra6 48. Ke7 Ra2 49. Rd6+ Kf5 50. Rd5+ Kg4 51. Rg5+ Kf3 52. Kf6 Ra6+ 53. Kf5 Ra5+ 54. Kg6 Rxg5+ 55. Kxg5 Ke2 56. g4 Kd1 1/2-1/2",
    B: "1655",
  },
  {
    W: "2025",
    T: "300+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. d4 e5 4. dxe5 Qxd1+ 5. Kxd1 Nc6 6. f4 Bf5 7. Bd2 O-O-O 8. a3 f6 9. exf6 Nxf6 10. Nc3 Ng4 11. Nh3 Ne3+ 12. Kc1 Nxc2 13. Ra2 N6d4 14. Ng5 Nb3+ 15. Kd1 Rxd2# 0-1",
    B: "2035",
  },
  {
    W: "1330",
    T: "300+0",
    P: "1. d4 d5 2. c4 dxc4 3. e3 Nf6 4. Bxc4 Nc6 5. Nc3 g6 6. Nf3 Bg7 7. O-O O-O 8. e4 e6 9. e5 Nd7 10. Ng5 Nb6 11. Qd3 Nxe5 12. Qh3 h6 13. dxe5 Nxc4 14. Rd1 Qe7 15. Nf3 Nxe5 16. Bxh6 Nxf3+ 17. gxf3 Bxh6 18. Qxh6 Qf6 19. f4 Qg7 20. Qg5 f6 21. Qc5 b6 22. Qc4 g5 23. Rd3 gxf4+ 24. Kf1 a5 25. Qxf4 Ba6 26. Rd1 Bxd3+ 27. Rxd3 Rad8 28. Rg3 Qxg3 29. Qxg3+ Kf7 30. Ne4 Rd1+ 31. Kg2 Rg8 32. Qxg8+ Kxg8 33. h4 Kg7 34. Kf3 Rd3+ 35. Ke2 Rh3 36. b3 Rxh4 0-1",
    B: "1361",
  },
  {
    W: "1013",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3 Bc5 5. d3 O-O 6. O-O a6 7. Ng5 d5 8. exd5 Nxd5 9. Nxd5 b5 10. Bb3 f6 11. Nb6+ 1-0",
    B: "718",
  },
  {
    W: "1628",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O Nf6 5. d4 exd4 6. e5 Ne4 7. Re1 d5 8. exd6 Bf5 9. Ng5 O-O 10. Nxe4 Bxe4 11. Rxe4 Qxd6 12. Bf4 Qg6 13. Bd3 Rfe8 14. Rxe8+ Rxe8 1-0",
    B: "1615",
  },
  {
    W: "1240",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bc5 4. Bc4 h6 5. O-O Nf6 6. h3 O-O 7. d3 d6 8. Nd5 Nxd5 9. Bxd5 Ne7 10. c3 c6 11. Bb3 d5 12. d4 exd4 13. cxd4 Bb6 14. exd5 cxd5 15. Re1 Be6 16. Ne5 f6 17. Nf3 a5 18. a4 Bf5 19. Bf4 Nc6 20. Rc1 Nxd4 21. Nxd4 Bxd4 22. Qxd4 b6 23. Bc7 Qd7 24. Bxb6 Rab8 25. Bxd5+ Kh8 26. Rc7 Qd6 27. Ree7 Rxb6 28. Rxg7 Rb4 29. Qa7 Qxd5 30. Rh7+ Bxh7 31. Rxh7+ Kg8 32. Qg7# 1-0",
    B: "1315",
  },
  {
    W: "1309",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. O-O Nxe4 5. Re1 d5 6. Bb3 Bf5 7. d3 Bc5 8. dxe4 Bxe4 9. Nc3 Bxf3 10. Qxf3 Qf6 11. Qxf6 gxf6 12. Bxd5 O-O-O 13. Bh6 Nd4 14. Be4 Rhg8 15. Rad1 f5 16. Rxd4 Bxd4 17. Bxf5+ Kb8 18. Bxh7 Rh8 19. Bg5 Rd6 20. Bd3 Bxc3 21. bxc3 Re8 22. f4 exf4 23. Rxe8+ Rd8 24. Rxd8# 1-0",
    B: "1308",
  },
  {
    W: "1545",
    T: "15+0",
    P: "1. e4 d5 2. Bc4 dxc4 3. Nc3 e6 4. Nf3 Bc5 5. O-O e5 6. d3 Bd6 7. Be3 Be6 8. Nd5 c6 9. Nc3 cxd3 10. cxd3 Nd7 11. d4 Qc7 12. d5 cxd5 13. Nb5 Qc6 14. Qe2 a6 15. Rac1 1-0",
    B: "1456",
  },
  {
    W: "1769",
    T: "60+0",
    P: "1. d4 Nf6 2. e3 e6 3. Nf3 c5 4. Be2 cxd4 5. Nxd4 Be7 6. Nd2 d5 7. N2f3 Nc6 8. O-O O-O 9. a3 Bd7 10. b3 a5 11. Bb2 Qc7 12. c4 Rac8 13. Rc1 dxc4 14. Bxc4 Qb8 15. Nb5 e5 16. a4 e4 17. Nfd4 Ng4 18. g3 Nge5 19. Nxc6 bxc6 20. Nc3 Nf3+ 21. Kg2 Qe5 22. Qc2 Qh5 23. Rh1 Qh3# 0-1",
    B: "1735",
  },
  {
    W: "1348",
    T: "60+0",
    P: "1. d4 Nf6 2. c4 g6 3. e3 Bg7 4. Nf3 d6 5. Nc3 O-O 6. Bd3 c5 7. O-O cxd4 8. exd4 Nc6 9. Re1 b6 10. d5 Ne5 11. Nxe5 dxe5 12. Rxe5 Nd7 13. Re1 Bxc3 14. bxc3 Nf6 15. Ba3 Bb7 16. Rb1 Qc7 17. Rb4 Rfe8 18. Qf3 Rad8 19. d6 exd6 20. Qxf6 Rxe1+ 21. Bf1 Rde8 22. Rb1 Rxb1 23. c5 Ree1 24. c4 Rxf1# 0-1",
    B: "1333",
  },
  {
    W: "1821",
    T: "60+0",
    P: "1. e4 e5 2. Nc3 Nf6 3. d3 d5 4. Bg5 dxe4 5. Bxf6 Qxf6 6. Nxe4 Qe7 7. Nf3 f5 8. Nc3 e4 9. dxe4 fxe4 10. Nd2 e3 11. fxe3 Qxe3+ 12. Qe2 Qxe2+ 13. Bxe2 Bd6 14. O-O Rf8 15. Rxf8+ Kxf8 16. Bf3 c6 17. Rf1 Kg8 18. Nde4 Bc7 19. Nc5 Nd7 20. Nxd7 Bxd7 21. b3 Bb6+ 22. Kh1 Bd4 23. Ne2 Bf6 24. a4 Re8 25. Ng3 Bc3 26. Ne4 Bf5 27. Nxc3 Bxc2 28. b4 Re3 29. Ne2 Bxa4 30. Nd4 Rd3 31. Ne6 Bb3 32. Nf4 Rd2 33. h3 Bf7 34. Nh5 Rb2 35. Nf6+ gxf6 36. Be4 Kg7 37. Bxh7 Kxh7 38. Rxf6 Kg7 39. Rxf7+ Kxf7 40. Kh2 Rxb4 41. Kg3 Rc4 42. Kf3 a5 43. Kf2 a4 44. Kg3 a3 45. h4 a2 46. h5 a1=Q 47. h6 Qa3+ 0-1",
    B: "1861",
  },
  {
    W: "1940",
    T: "60+0",
    P: "1. e4 e6 2. f4 d5 3. Nc3 d4 4. Nce2 b6 5. d3 c5 6. Nf3 Bb7 7. Ng3 f5 8. Be2 Nf6 9. e5 Ng4 10. Ng5 Bxg2 11. Rg1 Bb7 12. Bxg4 fxg4 13. Qxg4 Be7 14. Qxe6 Bc8 15. Qf7+ Kd7 16. e6+ Kc7 17. Qf5 Bxg5 18. fxg5 Re8 19. Bf4+ Kb7 20. O-O-O Rxe6 21. Rge1 Rxe1 22. Rxe1 Bxf5 23. Nxf5 Qf8 24. Nd6+ Kc6 25. Bg3 Nd7 26. Nc4 b5 27. Re6+ Kd5 28. Rd6+ Qxd6 29. Nxd6 Kc6 30. Nf7 Re8 31. b3 Re2 32. Kb2 Rg2 33. Ne5+ Nxe5 34. Bxe5 Rg1 35. Bxg7 Rh1 36. Be5 Rxh2 37. Bg3 Rh3 38. Bf4 Rg3 39. Bd2 Rf3 40. g6 Rf2 41. Bh6 Rg2 42. Bg7 1-0",
    B: "1885",
  },
  {
    W: "1477",
    T: "60+0",
    P: "1. d4 d6 2. Bf4 Nc6 3. Nf3 e5 4. dxe5 dxe5 5. Nxe5 Qxd1+ 6. Kxd1 Nxe5 7. Bxe5 Nf6 8. Bxf6 gxf6 9. Nc3 Bf5 10. e4 O-O-O+ 11. Bd3 Bg4+ 12. f3 Bh5 13. g4 Bg6 14. Ke2 h5 15. gxh5 Bxh5 16. Rae1 Bg6 17. Rhg1 Rh3 18. Rh1 Bh5 19. Ref1 Bh6 20. Nd5 Rg8 21. Nxf6 Rg2+ 22. Ke1 Bd2+ 23. Kd1 Bxf3+ 24. Rxf3 Rxf3 25. h4 Rxf6 26. h5 Rff2 27. h6 Rh2 28. Rxh2 Rxh2 29. h7 Bg5 30. Bc4 f6 31. h8=Q+ Rxh8 32. Ke2 Rh1 33. Kd3 Rh2 34. Kd4 Rxc2 35. Kd3 Rxb2 36. Kd4 Rd2+ 37. Kc5 Re2 38. Kb4 Rxe4 39. Kc3 Rxc4+ 40. Kxc4 f5 41. Kd4 Bf6+ 42. Kd3 Bb2 43. Ke3 b5 44. Kf4 a5 45. Kxf5 a4 46. Kg6 a3 47. Kf7 b4 48. Ke6 b3 49. Ke7 bxa2 50. Kf7 a1=Q 51. Ke7 Qe1+ 52. Kf7 1/2-1/2",
    B: "1488",
  },
  {
    W: "1638",
    T: "60+0",
    P: "1. e4 d5 2. d3 dxe4 3. dxe4 Qxd1+ 4. Kxd1 Nf6 5. Bd3 Ng4 6. Be3 Nxe3+ 7. fxe3 e5 8. Nf3 Bg4 9. Nc3 c6 10. Kd2 Bxf3 11. gxf3 Nd7 12. Raf1 O-O-O 13. Kc1 f6 14. Rhg1 g5 15. f4 h6 16. fxe5 Nxe5 17. Rxf6 Nxd3+ 18. cxd3 Rxd3 19. Rgf1 Bc5 20. Kc2 Rxe3 21. Na4 Bb4 22. a3 Ba5 23. b4 Bc7 24. Nc5 Rd8 25. Rf7 Re2+ 26. Kc3 Be5+ 27. Kc4 Rc2+ 28. Kb3 Rc3+ 29. Ka4 b5+ 30. Ka5 Rxa3+ 0-1",
    B: "1591",
  },
  {
    W: "1447",
    T: "60+0",
    P: "1. f4 c6 2. Nf3 Qc7 3. b3 d6 4. Bb2 e6 5. e3 Nd7 6. Bd3 Nb6 7. Nc3 Bd7 8. Qe2 O-O-O 9. a4 f5 10. b4 a6 11. a5 Nd5 12. Nxd5 exd5 13. c3 Nf6 14. Nd4 Be7 15. Nxf5 Bxf5 16. Bxf5+ Kb8 17. d3 Rhf8 18. Bh3 g6 19. c4 dxc4 20. dxc4 d5 21. Rc1 dxc4 22. Qxc4 Nd5 23. Be5 Bxb4+ 24. Kf2 Bd6 25. e4 Bxe5 26. exd5 Bxf4 27. Rc2 Rxd5 28. Kg1 Rfd8 0-1",
    B: "1415",
  },
  {
    W: "1091",
    T: "60+0",
    P: "1. d4 d5 2. e4 e6 3. Nc3 c6 4. e5 f6 5. Nf3 fxe5 6. Nxe5 Nd7 7. Qf3 Nxe5 8. dxe5 h6 9. Bd3 Bb4 10. Bd2 Bd7 11. Ne2 Bxd2+ 12. Kxd2 Qe7 13. h4 O-O-O 14. g4 g5 15. h5 Qg7 16. Bg6 Qxe5 17. c3 Nf6 18. Nd4 Ne4+ 19. Kc2 c5 20. Nxe6 Bxe6 21. Rhe1 Rhf8 22. Rxe4 dxe4 23. Qe3 Bd5 24. Qxc5+ 1-0",
    B: "1176",
  },
  {
    W: "1961",
    T: "60+0",
    P: "1. e4 e5 2. Nc3 f5 3. Bc4 fxe4 4. Nxe4 d5 5. Bb3 dxe4 6. Qh5+ g6 7. Qxe5+ Qe7 8. Qxh8 Nf6 9. Ne2 Nc6 10. d4 exd3 11. cxd3 Nd4 12. Bd1 Bf5 13. O-O Nxe2+ 14. Bxe2 O-O-O 15. Bf3 Bxd3 16. Rd1 Bg7 17. Qxd8+ Qxd8 18. Bg5 Qd6 19. Rac1 Qa6 20. Rc3 Be2 21. Bxb7+ Kxb7 22. Rdc1 c6 23. Be3 Bb5 24. Rc5 Qa5 25. Bf4 Qxa2 26. Re5 Qxb2 0-1",
    B: "2025",
  },
  {
    W: "1218",
    T: "600+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. Nc3 Bc5 5. Bxc6 dxc6 6. Nxe5 Qd4 7. Nd3 Bg4 8. f3 Bh5 9. g4 Bg6 10. b3 O-O-O 11. Bb2 Nxe4 12. fxe4 Bxe4 13. Qe2 Rhe8 14. Rf1 Bxd3 15. cxd3 Rxe2+ 16. Kxe2 Qxg4+ 17. Ke1 Re8+ 18. Ne4 f5 0-1",
    B: "1510",
  },
  {
    W: "1441",
    T: "180+0",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qd1 Nf6 5. Nf3 Bc5 6. Bd3 O-O 7. O-O Nd4 8. Nxd4 Bxd4 9. Nc3 d6 10. Nd5 Nxd5 11. exd5 Qf6 12. Be3 Bxb2 13. Rb1 Bd4 14. Bxd4 Qxd4 15. Re1 Qxd5 16. Qe2 Be6 17. Be4 Qxa2 18. Bxb7 Rab8 19. Ra1 Qc4 20. Ba6 Qxe2 21. Rxe2 g6 22. f4 c5 23. Rd2 d5 24. Ra5 c4 25. c3 Rb3 26. Rf2 Rxc3 27. Rc5 Rc1+ 28. Rf1 Rxf1+ 29. Kxf1 Rb8 30. g4 Rb2 31. f5 gxf5 32. gxf5 Bxf5 33. Rxd5 Be6 34. Bxc4 Bxd5 35. Bxd5 Rxh2 36. Bb7 Kg7 37. Kg1 Rh6 38. Be4 Re6 39. Bd3 f6 40. Bc4 Re5 41. Kf2 a5 42. Kf3 a4 43. Kf4 a3 44. Kg4 h5+ 45. Kf4 Kh6 46. Kf3 Kg5 47. Kf2 Re4 48. Bd3 Rg4 49. Bb1 f5 50. Ba2 f4 51. Kf3 Rg3+ 52. Kf2 h4 53. Kf1 Re3 54. Kf2 Kg4 55. Kf1 Kg3 56. Kg1 Re1# 0-1",
    B: "1523",
  },
  {
    W: "1208",
    T: "180+0",
    P: "1. f3 e5 2. e4 d5 3. exd5 Qxd5 4. c4 Qd4 5. Ne2 Qc5 6. d3 Nf6 7. a3 Bd6 8. b4 Qc6 9. c5 e4 10. cxd6 exf3 11. Nd4 Qxd6 12. Nxf3 O-O 13. Be2 Bg4 14. O-O Bxf3 15. gxf3 Nc6 16. Nd2 Nd4 17. Ne4 Qe5 18. Re1 Nxf3+ 19. Bxf3 Qxa1 20. Nxf6+ gxf6 21. Bxb7 Rae8 22. Bc6 Re6 23. Rxe6 fxe6 24. Qg4+ Kf7 25. Qd1 Rg8+ 26. Kf2 Qd4+ 27. Be3 Qh4+ 28. Ke2 Qxh2+ 29. Bf2 Rg2 30. Qf1 Qh5+ 31. Ke1 Rg5 32. Bg2 Re5+ 33. Kd2 Qe2+ 34. Qxe2 Rxe2+ 35. Kxe2 h5 36. Bxa7 c6 37. Bc5 f5 38. d4 Kg6 39. Kf3 Kg5 40. Kg3 h4+ 41. Kh3 f4 42. Bxc6 e5 43. dxe5 Kf5 44. Bf3 Kxe5 45. Be4 1-0",
    B: "1231",
  },
  {
    W: "1692",
    T: "180+0",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Bb4 4. cxd5 Bxc3+ 5. bxc3 Qxd5 6. Nf3 Ne7 7. Qc2 O-O 8. e4 Qd8 9. Bd3 f5 10. exf5 Nxf5 11. a4 Bd7 12. Ba3 Rf6 13. Ne5 Nc6 14. Nxd7 Qxd7 15. O-O Rh6 16. Rad1 Nce7 17. Bc1 Rh5 18. Be2 Rxh2 19. Kxh2 Qd6+ 20. Kg1 Rf8 21. Bf3 Rf6 22. Rfe1 Rg6 23. Qe4 Nc6 24. d5 Ne5 25. dxe6 Nxf3+ 26. Qxf3 Qe7 27. Qxf5 Rf6 28. Qd5 Rg6 29. Qd8+ Qf8 30. e7 1-0",
    B: "1587",
  },
  {
    W: "1562",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 g6 3. Bc4 Bg7 4. d4 cxd4 5. Nxd4 Nc6 6. Nxc6 bxc6 7. c3 d6 8. Qf3 Nf6 9. O-O O-O 10. Na3 Rb8 11. b3 e6 12. Bg5 h6 13. Bxf6 Bxf6 14. Qh3 Bg7 15. f4 d5 16. Bd3 dxe4 17. Bxe4 e5 18. Qf3 Qe7 19. Nc4 exf4 20. Qxf4 Bxc3 21. Rad1 Bg7 22. Bxc6 Ba6 23. Rd7 Qg5 24. Qxg5 hxg5 25. Bd5 Bxc4 26. Bxc4 a5 27. Bxf7+ Kh8 28. Bxg6 Rxf1+ 29. Kxf1 Rf8+ 30. Ke2 Rf6 31. Be4 Re6 32. Kd3 Be5 33. h3 Bc3 34. Rh7+ Kg8 35. Ra7 Kf8 36. Ra8+ 1-0",
    B: "1546",
  },
  {
    W: "1872",
    T: "180+0",
    P: "1. d4 b6 2. e4 Bb7 3. Bd3 e6 4. Be3 Be7 5. Ne2 Nf6 6. Nd2 d5 7. e5 Ne4 8. Ng3 Ng5 9. O-O h6 10. f4 Nh7 11. Bxh7 Rxh7 12. f5 c5 13. c3 Nc6 14. fxe6 g6 15. exf7+ Rxf7 16. Qc2 Bg5 17. Bxg5 Qxg5 18. Rxf7 Kxf7 19. Rf1+ Ke7 20. Nf3 Qe3+ 21. Kh1 Rf8 22. Qxg6 cxd4 23. Qd6+ Ke8 24. e6 Nb8 25. Nh5 Rg8 26. Qxb8+ Ke7 27. Qxg8 d3 28. Qf7+ Kd6 29. Qf4+ Qxf4 30. Nxf4 d4 31. cxd4 Bxf3 32. gxf3 d2 33. d5 Ke7 34. Rd1 a6 35. Rxd2 b5 36. Kg2 a5 37. Ng6+ 1-0",
    B: "1862",
  },
  {
    W: "1744",
    T: "180+0",
    P: "1. e4 e6 2. d4 b6 3. e5 Bb7 4. Nf3 h6 5. Bd3 d5 6. O-O c5 7. c3 c4 8. Bc2 b5 9. Re1 Be7 10. Be3 b4 11. Qd2 bxc3 12. Nxc3 a6 13. a3 Qd7 14. h3 Bd8 15. Nh2 Ba5 16. Ng4 Nf6 17. Nxf6+ gxf6 18. exf6 Nc6 19. Bxh6 O-O-O 20. Bg7 Rhg8 21. b4 cxb3 22. Bxb3 Na7 23. Ba4 Qd6 24. Qc2 Kb8 25. Rab1 Rc8 26. Qb3 Qc7 27. Bh6 Ka8 28. Bf4 Qd7 29. Bxd7 1-0",
    B: "1693",
  },
  {
    W: "1544",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. c3 Nc6 4. Be2 e6 5. O-O Nf6 6. d3 Qc7 7. Re1 Be7 8. g3 O-O 9. Bf1 a6 10. Bg2 b5 11. Bg5 Bb7 12. Nbd2 Rad8 13. a3 Rfe8 14. h3 Ne5 15. Nxe5 dxe5 16. Nb3 c4 17. Nc1 cxd3 18. Nxd3 Qc4 19. Re3 h6 20. Bxf6 Bxf6 21. Qe2 Bg5 22. b3 Qxc3 23. Rc1 Qd4 24. Re1 Bxe3 25. Qxe3 Qxd3 26. Qxd3 Rxd3 27. Rc1 Red8 28. Rc7 R8d7 29. Rc5 Rxb3 30. Rxe5 Rxa3 31. Rc5 Rad3 32. e5 Bxg2 33. Kxg2 R3d5 34. Rc8+ Kh7 35. Rc6 a5 36. Ra6 b4 37. Rb6 Rxe5 38. Rc6 Red5 39. Rc2 b3 40. Rc1 b2 41. Rb1 Rb5 42. Kf3 a4 43. Ke2 a3 44. Kf3 a2 45. Rxb2 Rxb2 46. Kg2 a1=Q 0-1",
    B: "1524",
  },
  {
    W: "2275",
    T: "180+0",
    P: "1. d4 c5 2. c3 cxd4 3. cxd4 Nf6 4. Nf3 e6 5. e3 Bb4+ 6. Nc3 O-O 7. Bd3 d5 8. O-O Nbd7 9. a3 Bd6 10. b4 e5 11. dxe5 Nxe5 12. Nxe5 Bxe5 13. Bb2 Bg4 14. Qb3 Be6 15. Qd1 Bxh2+ 16. Kxh2 Ng4+ 17. Kg3 Qg5 18. f4 Qe7 19. Ne2 Nxe3 20. Bxh7+ Kxh7 21. Qd3+ Nf5+ 22. Kf2 Qh4+ 23. g3 Qh2+ 24. Ke1 g6 25. Qf3 1-0",
    B: "2212",
  },
  {
    W: "1149",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qe6+ 4. Qe2 Qxe2+ 5. Bxe2 e5 6. Nf3 Bc5 7. Nxe5 Nc6 8. Nxc6 bxc6 9. O-O Nf6 10. d3 O-O 11. Bg5 Nh5 12. Bxh5 Bb7 13. Ne4 Bd4 14. c3 Be5 15. d4 Bf6 16. Bxf6 gxf6 17. Nc5 Ba6 18. Nxa6 Rae8 19. Rae1 Rxe1 20. Rxe1 f5 21. Re3 f4 22. Re4 f5 23. Rxf4 c5 24. dxc5 Rd8 25. Rd4 Rf8 26. f4 Kg7 27. Bf3 Re8 28. Kf2 Kg6 29. Nxc7 h5 30. Nxe8 1-0",
    B: "982",
  },
  {
    W: "1762",
    T: "180+0",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Nge7 6. Be2 Nf5 7. Bb5 Bd7 8. Bxc6 Bxc6 9. O-O Be7 10. h3 O-O 11. Na3 Qb6 12. b3 Qa5 13. Qd2 cxd4 14. b4 dxc3 15. bxa5 cxd2 16. Nxd2 Bb4 17. Nf3 Bxa5 18. Bd2 Bb6 19. Nc2 Ba4 20. Nb4 Nd4 21. a3 Nxf3+ 22. gxf3 Bd4 23. Rab1 Bxe5 24. f4 Bd6 25. Rfe1 Rab8 26. Rb2 Rfc8 27. Rc1 Rxc1+ 28. Bxc1 Bxb4 29. Rxb4 h6 30. Rxa4 a6 31. Rb4 b5 32. Rb2 Rc8 33. Be3 Rc3 34. a4 bxa4 35. Rb8+ Kh7 36. Ra8 Rc6 37. Ra7 Kg6 38. h4 a3 39. h5+ Kf6 40. Bd4+ Kf5 41. Kg2 a2 42. Kg3 f6 43. Kf3 e5 44. fxe5 fxe5 45. Bb2 e4+ 46. Ke3 Rc2 47. Bd4 1-0",
    B: "1823",
  },
  {
    W: "2195",
    T: "180+0",
    P: "1. e4 g6 2. d4 d6 3. Nc3 Bg7 4. Be3 Nc6 5. f3 e5 6. d5 Nce7 7. Bb5+ Bd7 8. Bxd7+ Qxd7 9. Qd2 h6 10. Nge2 f5 11. g4 f4 12. Bf2 a6 13. O-O Nf6 14. Kh1 g5 15. a4 h5 16. Rg1 hxg4 17. fxg4 Rh3 18. Rg2 Nxg4 19. Bg1 Bf6 20. Rf1 f3 21. Rg3 fxe2 22. Nxe2 Rxg3 23. Nxg3 O-O-O 24. Rxf6 Nxf6 25. Qf2 Rf8 26. Qa7 Qxa4 27. Qa8+ Kd7 28. Qxf8 Nxe4 29. Qf3 Nxg3+ 30. hxg3 Qxc2 31. Qg4+ Qf5 32. Qd1 e4 33. g4 Qxd5 34. Qa4+ Qc6 35. Qd4 e3+ 36. Kh2 e2 37. Qe3 Qc4 38. Bf2 Qxg4 0-1",
    B: "2112",
  },
  {
    W: "1888",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d4 Nf6 5. Be3 e6 6. Bd3 Bb4 7. Ne2 b6 8. O-O Bb7 9. a3 Be7 10. Ne4 Nxe4 11. f3 Nf6 12. c4 O-O 13. Qc2 Re8 14. b4 Nbd7 15. f4 c5 16. f5 exf5 17. Rxf5 cxb4 18. axb4 Bxb4 19. Raf1 Rxe3 20. Nf4 Qe7 21. d5 Re1 22. Qf2 Rxf1+ 23. Bxf1 Bc5 24. Qxc5 Qxc5+ 25. Kh1 g6 26. Nxg6 hxg6 27. Rf3 Ng4 28. h3 Nf2+ 29. Kh2 Qd6+ 30. Kg1 Ne4 31. Bd3 Nec5 32. Be2 Ne5 33. Bf1 Nxf3+ 34. gxf3 Re8 35. Kg2 Qe5 36. h4 a5 37. h5 gxh5 38. Kf2 a4 39. Kg2 a3 40. Kf2 a2 41. Kg2 a1=Q 42. Kf2 Qab2+ 43. Kg1 Qeh2# 0-1",
    B: "1793",
  },
  {
    W: "1788",
    T: "180+0",
    P: "1. f4 Nc6 2. Nf3 d6 3. d4 e5 4. e3 Nf6 5. Bb5 Bd7 6. O-O Qe7 7. Bxc6 Bxc6 8. dxe5 Bxf3 9. Qxf3 dxe5 10. Qxb7 Rd8 11. Nc3 exf4 12. exf4 Qc5+ 13. Kh1 Be7 14. Qb5+ Qxb5 15. Nxb5 O-O 16. Nxc7 Ng4 17. f5 Bc5 18. h3 Nf2+ 19. Kh2 Ne4 20. f6 Bd6+ 21. Bf4 Bxf4+ 22. Rxf4 Nxf6 23. Nb5 Rd2 24. Rc1 Rb8 25. Nxa7 Rxb2 26. Nc6 Rdxc2 27. Rxc2 Rxc2 28. Nb4 Rb2 29. Kg3 Nh5+ 0-1",
    B: "1994",
  },
  {
    W: "1884",
    T: "180+0",
    P: "1. e4 d6 2. Nf3 Nf6 3. e5 Nd5 4. d4 c5 5. c4 Nb6 6. dxc5 dxc5 7. Qxd8+ Kxd8 8. Ng5 Ke8 9. Nc3 h6 10. Nge4 e6 11. Be3 Na6 12. Nb5 Bd7 13. Nbd6+ Bxd6 14. Nxd6+ Ke7 15. Nxb7 Rab8 16. Bxc5+ Nxc5 17. Nxc5 Bc6 18. O-O-O Rhd8 19. f3 Rxd1+ 20. Kxd1 Rd8+ 21. Kc2 Rd4 22. b3 g5 23. Kc3 Rd8 24. Bd3 f5 25. exf6+ Kxf6 26. Re1 e5 27. Be4 Be8 28. g4 Bf7 29. Nb7 Rd4 30. c5 Nd7 31. c6 Nb6 32. c7 Be6 33. Nd8 Bd5 34. Bf5 Ba8 35. Re3 Nd5+ 36. Kb2 Nxc7 37. Rc3 Nd5 38. Rc8 Nb6 39. Rc6+ Ke7 40. Nb7 Bxb7 41. Rc7+ Kd6 42. Rxb7 Kc5 43. Rh7 Nd5 44. Rxh6 Ne3 45. Be4 Rd2+ 0-1",
    B: "2094",
  },
  {
    W: "1926",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 Bd7 5. Bc4 Nc6 6. O-O Nge7 7. Nc3 Ng6 8. Be3 Qe7 9. a4 O-O-O 10. a5 a6 11. f4 Nxd4 12. Bxd4 f6 13. Qe2 Kb8 14. Ra3 Be6 15. Bxa6 bxa6 16. Qxa6 c5 17. Nd5 Bxd5 18. exd5 Rd7 19. Rb3+ Rb7 20. Bf2 Rxb3 21. cxb3 Qb7 22. Qc4 Be7 23. a6 Qa7 24. b4 Rc8 25. b5 Qb6 26. Be3 Ka7 27. Re1 Rb8 28. Qe4 Qxb5 29. Qf5 Qb4 30. Qd7+ Kxa6 31. Ra1+ Kb6 32. Qc6# 1-0",
    B: "1870",
  },
  {
    W: "1698",
    T: "180+2",
    P: "1. d4 d5 2. c4 c6 3. Bf4 Nf6 4. e3 e6 5. Nc3 Bb4 6. Qb3 Qa5 7. a3 Bxc3+ 8. bxc3 Ne4 9. Rc1 Nd7 10. Bd3 Ndf6 11. Nh3 h6 12. f3 O-O 13. fxe4 1-0",
    B: "1652",
  },
  {
    W: "1587",
    T: "420+2",
    P: "1. e4 c6 2. Nf3 d5 3. e5 Bf5 4. d4 e6 5. Bd3 Bg6 6. Bg5 Qb6 7. b3 Qa5+ 8. Bd2 Qc7 9. O-O c5 10. c3 Nc6 11. Bc1 a6 12. Bb2 b5 13. Bc2 c4 14. h3 Nge7 15. Nh2 Nc8 16. f4 b4 17. f5 exf5 18. Bxf5 Bxf5 19. Rxf5 bxc3 20. Bxc3 Bb4 21. Qg4 O-O 22. Nf3 Bxc3 23. Nxc3 N6e7 24. Rf4 Nb6 25. Ne2 Ng6 26. Rf5 Ne7 27. Rf4 Ng6 28. Rf5 Ne7 29. Rf4 1/2-1/2",
    B: "1741",
  },
  {
    W: "1828",
    T: "120+1",
    P: "1. d4 Nf6 2. Bf4 g6 3. Nf3 d6 4. e3 Bg7 5. Bd3 O-O 6. Nbd2 c5 7. c3 Nbd7 8. h4 h5 9. Bg5 cxd4 10. exd4 Qc7 11. Qc2 e5 12. dxe5 Nxe5 13. Nxe5 Re8 14. f4 dxe5 15. O-O-O exf4 16. Ne4 Nxe4 17. Bxe4 Bg4 18. Rdf1 f5 19. Bxf4 Qa5 20. Qb3+ Kh7 21. Bb1 Re2 22. g3 Rae8 23. Bd2 Qd8 24. Qc2 Bh6 25. Rd1 Rg2 26. Bxh6 Rxc2+ 27. Bxc2 Bxd1 28. Rxd1 Qc7 29. Bf4 Qa5 30. a3 Re7 31. Bd6 Rd7 32. Rd4 Qd8 33. Be5 Rxd4 34. cxd4 Qd5 35. b3 Qf3 36. Kb2 Qe2 37. Kc3 a6 38. a4 b5 39. axb5 axb5 40. Bd3 Qf3 41. Kd2 b4 42. Bd6 Qf2+ 43. Kc1 Qxd4 0-1",
    B: "1818",
  },
  {
    W: "945",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Qf6 3. Nc3 Bc5 4. Bc4 c6 5. O-O Be7 6. d3 c5 7. Bg5 Qg6 8. Nxe5 Qxg5 9. Qf3 f6 10. Nf7 b5 11. Nxh8 bxc4 12. Nf7 Kxf7 13. dxc4 d6 14. Nd5 Na6 15. Nxe7 Nxe7 16. e5 dxe5 17. Qd5+ Nxd5 0-1",
    B: "1111",
  },
  {
    W: "1610",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. a3 f5 4. exf5 Nf6 5. Qe2 e4 6. d3 d5 7. Bg5 Bxf5 8. Bxf6 Qxf6 9. Nc3 O-O-O 10. Nxd5 Rxd5 11. O-O-O exf3 12. Qxf3 Qg5+ 13. Kb1 Bg4 14. Qxd5 Qxd5 15. Re1 Bc5 16. h3 Bf5 17. Be2 Be6 18. c4 Qd4 19. Ref1 Ne5 20. f4 Nxd3 21. Bxd3 Qxd3+ 22. Ka1 Qxc4 23. Kb1 Bf5+ 24. Ka1 Rd8 25. Rc1 Qd5 26. g4 Be4 27. Rhe1 Bf2 28. Re2 Qd4 29. Rxf2 Qxf2 30. f5 Rd2 31. Rb1 Bxb1 32. Kxb1 Rxb2+ 33. Ka1 Ra2+ 34. Kb1 Qb2# 0-1",
    B: "1663",
  },
  {
    W: "1712",
    T: "60+2",
    P: "1. g4 d5 2. Bg2 c6 3. c4 dxc4 4. Qa4 Be6 5. h3 Nd7 6. Qb4 Qb6 7. Qc3 Bd5 8. Nf3 e6 9. a3 Ngf6 10. d3 cxd3 11. exd3 Bd6 12. Be3 Bc5 13. d4 Bd6 14. Ne5 Bxg2 15. Rg1 Bd5 16. Nd2 Ne4 17. Ndf3 Nxc3 18. bxc3 Bxf3 19. Nxf3 Qa5 20. Bd2 Qd5 21. Ne5 Nxe5 22. dxe5 Bxe5 23. Rd1 Qe4+ 24. Kf1 Rd8 0-1",
    B: "1786",
  },
  {
    W: "1791",
    T: "60+0",
    P: "1. d4 c6 2. c4 d5 3. e3 dxc4 4. Nf3 Nf6 5. Be2 Bg4 6. O-O Bxf3 7. Bxf3 e6 8. Be2 Be7 9. Bxc4 O-O 10. Bd3 Nbd7 11. Qc2 Rc8 12. Nc3 Qa5 13. f4 Nd5 14. e4 Ne3 15. Bxe3 c5 16. e5 Nb6 17. dxc5 Bxc5 18. Bxc5 Qxc5+ 19. Kh1 Nd5 20. Bxh7+ Kh8 21. Be4 Ne3 22. Qe2 g6 23. Rf3 Nf5 24. Rh3+ Kg7 25. Bxf5 exf5 26. Qf2 Rh8 27. Rxh8 Qxf2 28. Rd1 Rxh8 29. h3 Qxf4 30. Ne2 Qf2 31. Ng1 Qe3 32. Rb1 Qxe5 33. a3 g5 34. b4 g4 35. a4 gxh3 36. Nxh3 Qg3 37. Rf1 Rxh3+ 38. gxh3 Qxh3+ 39. Kg1 Qg3+ 40. Kh1 Qh3+ 41. Kg1 Qg3+ 42. Kh1 Qh3+ 1/2-1/2",
    B: "1714",
  },
  {
    W: "1869",
    T: "60+0",
    P: "1. b3 e5 2. g3 d6 3. Bg2 Nf6 4. e4 Nc6 5. Nf3 Bd7 6. O-O h6 7. Re1 h5 8. Bb2 h4 9. c4 hxg3 10. fxg3 Bh3 11. Bxh3 Rxh3 12. Kg2 Rh8 13. d4 Qd7 14. d5 Nd4 15. Nxd4 exd4 16. Bxd4 Qh3+ 17. Kf3 O-O-O 18. Re2 g5 19. Ke3 Ng4+ 20. Kd3 Ne5+ 21. Bxe5 dxe5 22. Nd2 f5 23. exf5 Bc5 24. Nf3 Qxf5+ 25. Kc3 Qxf3+ 26. Kb2 Bd4+ 27. Ka3 Bxa1 28. Rxe5 Bxe5 0-1",
    B: "1873",
  },
  {
    W: "1849",
    T: "60+0",
    P: "1. Nf3 d5 2. e4 Bg4 3. exd5 Bxf3 4. Qxf3 Nf6 5. d6 Qxd6 6. Qxb7 Qe5+ 7. Be2 Qe4 8. Qc8# 1-0",
    B: "1793",
  },
  {
    W: "2001",
    T: "60+0",
    P: "1. c4 e6 2. Nc3 Nf6 3. e4 c5 4. d3 d5 5. e5 Nfd7 6. cxd5 Nxe5 7. f4 Ng6 8. dxe6 Bxe6 9. Nf3 Nc6 10. Be2 Nd4 11. O-O Be7 12. Nxd4 Qxd4+ 13. Kh1 O-O 14. Ne4 Bd5 15. Rf3 Rad8 16. Be3 Qxb2 17. Rb1 Qxa2 18. Nc3 Qa6 19. Nxd5 Rxd5 20. d4 Qc6 21. Bc4 Rd7 22. d5 Qf6 23. Bd2 Bd6 24. Bc3 Qf5 25. Bd3 Qg4 26. f5 Qh4 27. g3 Qg4 28. fxg6 hxg6 29. Qe2 f6 30. Qe6+ Qxe6 31. dxe6 Re7 32. Re1 Rfe8 33. Bc4 Be5 34. Bxe5 fxe5 35. Rxe5 1-0",
    B: "2092",
  },
  {
    W: "2157",
    T: "60+0",
    P: "1. d3 c6 2. c4 d5 3. cxd5 cxd5 4. d4 Nc6 5. Nf3 a6 6. Bf4 Nf6 7. e3 Bg4 8. h3 Bxf3 9. Qxf3 e6 10. Nc3 Bd6 11. Bg5 Be7 12. a3 h6 13. Bf4 O-O 14. Bd3 Rc8 15. O-O Na5 16. Rac1 Nc4 17. Rc2 Nxa3 18. bxa3 Bxa3 19. Na4 Rxc2 20. Bxc2 Qa5 21. Nc5 Bxc5 22. dxc5 Qxc5 23. Qe2 Rc8 24. Bd3 b5 25. Be5 Nd7 26. Bd4 Qa3 27. Ra1 Qd6 28. Bb1 e5 29. Bb2 d4 30. Qd3 g6 31. exd4 exd4 32. Ba2 Ne5 33. Qxd4 Qxd4 34. Bxd4 Nd3 35. Bd5 Rd8 36. Rd1 Rxd5 37. Rxd3 b4 38. Kf1 a5 39. Ke2 b3 40. Bb2 a4 41. Rxd5 a3 42. Bxa3 1-0",
    B: "2145",
  },
  {
    W: "1926",
    T: "60+0",
    P: "1. d4 b6 2. e3 Bb7 3. f3 d6 4. b3 Ba6 5. Bxa6 Nxa6 6. Qe2 Nb8 7. Nh3 Qc8 8. Nf2 Qa6 9. Qxa6 Nxa6 10. O-O Nb8 11. c4 Nd7 12. Bb2 c6 13. Nd2 O-O-O 14. g3 e6 15. e4 Be7 16. f4 Bf6 17. Nf3 Be7 18. e5 d5 19. c5 b5 20. b4 f6 21. a3 fxe5 22. dxe5 Nh6 23. Bc1 Nf5 24. Kg2 h6 25. g4 g6 26. gxf5 gxf5 27. Kh1 Rhg8 28. Be3 Rg6 29. Rg1 Rdg8 30. Rxg6 Rxg6 31. Rg1 Rxg1+ 32. Kxg1 Nf8 33. Kg2 Ng6 34. h3 Kd7 35. Nd3 Bf8 36. Bf2 Bg7 37. h4 Ne7 38. h5 Ng8 39. Nh4 Ne7 40. Ng6 Ng8 41. Bh4 Ke8 42. Bf6 Bxf6 43. exf6 Nxf6 44. Nde5 Nxh5 45. Nxc6 a6 46. Nb8 Nf6 47. Nxa6 Ne4 48. Nc7+ Kd7 49. Nxb5 Kc6 50. a4 Nd2 51. Ne5+ Kb7 52. Nd6+ Kc7 53. Nef7 Kd7 54. Nxh6 Ke7 55. Nhf7 Kf6 56. Ne5 Ne4 57. a5 Nxd6 58. cxd6 d4 59. d7 Ke7 60. Kf3 Kd8 61. b5 d3 62. Ke3 d2 63. Kxd2 1-0",
    B: "1977",
  },
  {
    W: "1491",
    T: "60+0",
    P: "1. e3 e5 2. Ne2 Nf6 3. g3 g6 4. Bg2 Bg7 5. Rg1 b6 6. h3 Bb7 7. b3 Nc6 8. Bxc6 dxc6 9. Bb2 Qd7 10. Bxe5 O-O 11. Bxf6 Bxf6 12. c3 Rfe8 13. d4 c5 14. Na3 cxd4 15. Nxd4 Rad8 16. Rc1 c5 17. Nf3 Qxd1+ 18. Rxd1 Rxd1+ 19. Kxd1 Bxf3+ 20. Ke1 Bc6 21. Nc4 Bxc3+ 22. Ke2 Ba5 23. Nd6 Rd8 24. Nc4 Bb5 25. Rd1 Rxd1 26. Kxd1 Bxc4 27. bxc4 Bc3 28. Kc2 Bf6 29. h4 Kf8 30. Kd3 Ke7 31. Ke4 Ke6 32. a3 Bb2 33. a4 Be5 34. f4 Bc3 35. g4 f6 36. h5 Bd2 37. hxg6 hxg6 38. g5 fxg5 39. fxg5 Kd6 40. Kd3 Be1 41. Ke2 Bh4 42. Kf3 Bxg5 43. Ke4 Bxe3 44. Kxe3 Ke6 45. Kf4 g5+ 46. Kxg5 Ke5 47. Kg4 Kd4 48. Kf3 Kxc4 49. Ke3 Kb4 50. Kd3 Kxa4 51. Kc3 b5 52. Kb2 b4 53. Kb1 c4 54. Kb2 a5 55. Kb1 c3 56. Kc2 Ka3 57. Kb1 b3 58. Ka1 c2 1/2-1/2",
    B: "1425",
  },
  {
    W: "2304",
    T: "60+0",
    P: "1. e4 d6 2. d3 Nf6 3. f4 g6 4. Nf3 Bg7 5. Be2 O-O 6. O-O c5 7. Nc3 Nc6 8. Be3 Rb8 9. Bf2 a6 10. h3 b5 11. a3 b4 12. axb4 Nxb4 13. Nb1 Bb7 14. Nbd2 e6 15. c3 Nc6 16. b3 Qe7 17. Qc2 d5 18. e5 Nd7 19. d4 f6 20. exf6 Nxf6 21. Ne5 Nxe5 22. fxe5 Nd7 23. Bg4 h5 24. Bf3 Bh6 25. Qxg6+ Qg7 26. Bxh5 Qxg6 27. Bxg6 Bxd2 28. Bh4 Bxc3 29. Be7 Bxd4+ 30. Kh1 Rxf1+ 31. Rxf1 Nxe5 32. Rf6 Nxg6 33. Rxg6+ Kf7 0-1",
    B: "2337",
  },
  {
    W: "2231",
    T: "60+0",
    P: "1. Nf3 b6 2. g3 Bb7 3. Bg2 Nf6 4. O-O e6 5. d4 Bxf3 6. Bxf3 d5 7. c4 c6 8. cxd5 cxd5 9. Nc3 Be7 10. Bg5 O-O 11. Bxf6 Bxf6 12. e3 Nd7 13. Rc1 Rc8 14. Qd2 a6 15. Na4 b5 16. Nc3 Nb6 17. b3 Nd7 18. Ne2 Nb6 19. Nf4 g6 20. Nd3 Qd6 21. Nc5 Nd7 22. Nd3 Rc7 23. Rxc7 Qxc7 24. Rc1 Qd6 25. Qc3 Bg7 26. Qc6 Qxc6 27. Rxc6 Ra8 28. Nc5 Nxc5 29. dxc5 Bf8 30. b4 a5 31. a3 axb4 32. axb4 Ra4 33. Rc8 Rxb4 34. c6 Rc4 35. c7 Kg7 36. Be2 Rc5 37. Kf1 Rc1+ 38. Kg2 Bd6 39. Bxb5 Rxc7 40. Rxc7 Bxc7 41. Kf3 f5 42. Ke2 Kf6 43. Kd3 e5 44. Bd7 h6 45. Ke2 g5 46. h3 Bb6 47. Bc8 d4 48. exd4 Bxd4 49. Bb7 Ke6 50. Ba8 Kf6 51. Bg2 e4 52. Bxe4 Ke5 53. f3 fxe4 54. fxe4 Kxe4 55. Kf1 Be3 56. Kg2 Bf4 57. h4 Bxg3 0-1",
    B: "2224",
  },
  {
    W: "1642",
    T: "60+0",
    P: "1. f4 c5 2. Nf3 d6 3. e3 Nf6 4. Be2 e6 5. O-O Be7 6. d3 O-O 7. Nbd2 Nc6 8. e4 d5 9. exd5 exd5 10. Ne5 Nxe5 11. fxe5 Nd7 12. c3 b6 13. d4 Bb7 14. Nf3 cxd4 15. cxd4 f6 16. e6 Nb8 17. Bb5 Nc6 18. Bf4 a6 19. Ba4 b5 20. Bb3 Bb4 21. a3 Bd6 22. Bxd6 Qxd6 23. Rc1 Qxe6 24. Re1 Qd7 25. Nh4 Rae8 26. Rxe8 Rxe8 27. Qf3 Rd8 28. Qg3 Nxd4 29. Bd1 Re8 30. Bg4 Qf7 31. Rf1 Ne2+ 32. Bxe2 Rxe2 33. Nf5 Qe6 0-1",
    B: "1593",
  },
  {
    W: "2357",
    T: "60+0",
    P: "1. e4 c6 2. Nf3 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Qe2 Nxe4 6. Qxe4 Nd7 7. Bc4 Nf6 8. Ne5 Nxe4 9. Bxf7# 1-0",
    B: "2216",
  },
  {
    W: "1958",
    T: "600+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. Nf3 Bg4 5. d4 Nc6 6. Be3 O-O-O 7. a3 f5 8. Be2 Bxf3 9. Bxf3 f4 10. Bxf4 Nxd4 11. O-O g5 12. Be3 Nf5 13. Bxb7+ Kxb7 14. Qxd8 Bg7 15. b4 Qe5 16. Na4 Nxe3 17. Nc5+ Kb6 18. Qb8+ Kc6 19. Qb7+ Kd6 20. Rad1+ Nd5 21. c4 e6 22. cxd5 exd5 23. Rfe1 Qf5 24. Re6+ Qxe6 25. Nxe6 Kxe6 26. Qxd5+ Kf6 27. g4 Kg6 28. Qf5+ Kh6 29. Rc1 Ne7 30. Qe6+ Ng6 31. Rxc7 Be5 32. Rc5 Bf4 33. Rf5 Rd8 34. Kg2 Rd6 35. Qe8 Nh4+ 36. Kf1 Nxf5 37. gxf5 Rf6 38. Qe4 Bxh2 0-1",
    B: "1971",
  },
  {
    W: "1629",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d5 3. exd5 e4 4. Ng1 Nf6 5. Qe2 Bc5 6. Nc3 O-O 7. Nxe4 Nxe4 8. Qxe4 Re8 9. d3 Rxe4+ 10. dxe4 Qf6 11. Nf3 c6 12. Bg5 Qxb2 13. Rd1 Bb4+ 14. Nd2 cxd5 15. exd5 Bg4 16. f3 Bf5 17. Bd3 Bxd3 18. cxd3 Na6 19. O-O Nc5 20. Nc4 Qc3 21. Bd2 Qd4+ 22. Be3 Qf6 23. Rb1 Na6 24. d6 Bc5 25. d7 Bxe3+ 26. Nxe3 Qd4 27. Kf2 Qxd7 28. Rfe1 Qd4 29. Rxb7 f5 30. g3 Nb4 31. Ke2 Re8 32. Kd2 Qxd3+ 33. Kc1 Rc8+ 34. Kb2 Qd2+ 0-1",
    B: "1592",
  },
  {
    W: "2013",
    T: "300+0",
    P: "1. d4 Nf6 2. Bf4 g6 3. Nc3 Bg7 4. e4 O-O 5. Qd2 d6 6. O-O-O Nbd7 7. Bh6 e5 8. d5 Nc5 9. f3 a5 10. h4 Nh5 11. Nge2 f5 12. Bg5 Bf6 13. exf5 gxf5 14. g4 fxg4 15. fxg4 Bxg4 16. Bh3 Bxh3 17. Rxh3 Bxg5 18. hxg5 Nf4 19. Nxf4 Rxf4 20. g6 hxg6 21. Rdh1 Kg7 22. Rh7+ Kf6 23. Nb5 Ne4 24. Qd3 Nf2 25. Qc3 Nxh1 26. Nxc7 Rc8 27. Qxa5 Nf2 28. b3 Qg8 29. Rd7 Nd3+ 0-1",
    B: "1989",
  },
  {
    W: "1585",
    T: "300+0",
    P: "1. c4 e5 2. e4 c6 3. Nf3 d6 4. h3 Nf6 5. Nc3 Nbd7 6. d4 exd4 7. Qxd4 Be7 8. Bd3 O-O 9. O-O Nc5 10. b4 Nxd3 11. Qxd3 b6 12. Bg5 Bb7 13. Bxf6 Bxf6 14. Rad1 Be7 15. a3 c5 16. b5 a6 17. a4 axb5 18. cxb5 Qc7 19. Rfe1 Rfd8 20. Qc4 Bf6 21. Nd5 Bxd5 22. Rxd5 Qa7 23. e5 dxe5 24. Rxd8+ Rxd8 25. Nxe5 Bxe5 26. Rxe5 Rd1+ 27. Kh2 Qc7 28. Qe4 Rd4 29. Qa8+ Rd8 30. Qc6 Qxe5+ 31. f4 Qxf4+ 32. g3 Rd2+ 33. Kg1 Qf2+ 34. Kh1 Qe1# 0-1",
    B: "1565",
  },
  {
    W: "1411",
    T: "300+0",
    P: "1. d4 d5 2. c4 e6 3. Nc3 h6 4. Bf4 Bb4 5. e3 Nf6 6. Bd3 Nc6 7. Ne2 O-O 8. O-O a6 9. Qb3 a5 10. a3 Be7 11. cxd5 exd5 12. Ng3 a4 13. Qc2 Bd6 14. Bxd6 Qxd6 15. Nxa4 b6 16. Qd2 Rxa4 17. Qc2 Ra8 18. Rfe1 Ne7 19. e4 dxe4 20. Nxe4 Nxe4 21. Bxe4 Rb8 22. Qd3 f5 23. Qc4+ Kh8 24. Bc2 b5 25. Qd3 c6 26. g4 1-0",
    B: "1377",
  },
  {
    W: "1353",
    T: "300+0",
    P: "1. d4 e6 2. Bf4 c6 3. h3 Nf6 4. Nf3 Nd5 5. Bh2 Be7 6. c3 Nc7 7. e3 O-O 8. Bd3 b6 9. O-O Bb7 10. Re1 d5 11. Nbd2 Bd6 12. Bxd6 Qxd6 13. e4 dxe4 14. Nxe4 Qd5 15. Nfd2 f5 16. Bc4 Qd8 17. Ng5 Qxg5 18. Nf3 Qg6 19. Ne5 Qg5 20. Qf3 Nd5 21. Bxd5 cxd5 22. Qd3 Nc6 23. Re3 Nxe5 24. Rxe5 f4 25. Rxg5 e5 26. Rxe5 Rfe8 27. Rae1 Bc6 28. Rxe8+ Bxe8 29. Re7 Bg6 30. Qb5 h5 31. Qxd5+ Kh7 32. Qd7 Rg8 33. f3 a5 34. d5 Kh6 35. h4 Rf8 36. Rxg7 Bf5 37. Qc7 Re8 38. Qxf4+ Kxg7 39. Qg5+ Bg6 40. Kh2 b5 41. d6 Rd8 42. Qxd8 b4 43. Qe7+ Bf7 44. d7 bxc3 45. bxc3 a4 46. d8=Q a3 47. Qg5+ Bg6 48. Qde7+ Kh8 49. Qh6+ Kg8 50. Qeg7# 1-0",
    B: "1321",
  },
  {
    W: "1791",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. c3 dxc3 5. Nxc3 e6 6. Bc4 Bb4 7. O-O Bxc3 8. bxc3 Nge7 9. Ba3 O-O 10. Nd4 d5 11. Nxc6 bxc6 12. exd5 exd5 13. Bd3 Re8 14. Qh5 g6 15. Qh6 Nf5 16. Bxf5 Bxf5 17. c4 Qa5 18. Bb2 f6 19. Bxf6 Rf8 20. Qg7# 1-0",
    B: "1753",
  },
  {
    W: "1456",
    T: "300+0",
    P: "1. c4 c5 2. Nc3 e6 3. g3 Nf6 4. Bg2 a6 5. e3 Nc6 6. Nf3 Be7 7. O-O d5 8. d4 dxc4 9. dxc5 Qxd1 10. Rxd1 Bxc5 11. Na4 Be7 12. Nb6 Rb8 13. e4 Nxe4 14. Bf4 e5 15. Nxe5 Bf5 16. Nxc6 bxc6 17. Bxb8 O-O 18. Bf4 Bc5 19. Rf1 Bxb6 20. Rac1 Rc8 21. Rxc4 Nf6 22. Rxc6 Rb8 23. Rfc1 h6 24. Bxb8 1-0",
    B: "1446",
  },
  {
    W: "1890",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Nxb4 5. c3 Nc6 6. d4 exd4 7. cxd4 Bb4+ 8. Bd2 Bxd2+ 9. Nbxd2 Nge7 10. Bxf7+ Kxf7 11. Ng5+ Kg8 12. f4 Nxd4 13. Nc4 c5 14. Ne5 Qa5+ 15. Kf2 d6 16. Nef7 h6 17. Nxh8 hxg5 18. Qh5 Qd2+ 19. Kf1 Qd3+ 20. Kf2 Qxe4 21. Rhe1 Qxf4+ 22. Kg1 Bg4 23. Qf7+ Qxf7 24. Nxf7 Kxf7 0-1",
    B: "1852",
  },
  {
    W: "1554",
    T: "300+0",
    P: "1. g3 e5 2. Bg2 d5 3. b3 Nc6 4. Bb2 Nf6 5. e3 Be6 6. Nf3 Bd6 7. Nc3 O-O 8. h4 h6 9. h5 Bg4 10. Bh3 e4 11. Bxg4 Nxg4 12. Nh4 Nge5 13. Nxd5 f5 14. Qe2 Ng4 15. O-O-O Bxg3 16. Nxc7 Qxc7 17. fxg3 Nb4 18. Qc4+ Kh7 19. Qxb4 Nf2 20. Qb5 Nxd1 21. Kxd1 Rac8 22. Bc3 Qxg3 23. Qxb7 Qg4+ 24. Kc1 Qxh5 25. Qxg7# 1-0",
    B: "1501",
  },
  {
    W: "1679",
    T: "300+0",
    P: "1. e4 e5 2. Qh5 Nc6 3. Bc4 g6 4. Qf3 Nf6 5. c3 Bg7 6. d3 d6 7. h3 Qe7 8. Bg5 a6 9. Bb3 Na5 10. Bc2 c5 11. Ne2 c4 12. O-O cxd3 13. Bxd3 Be6 14. Nd2 h6 15. Bxf6 Bxf6 16. b4 Nc6 17. a4 g5 18. b5 axb5 19. axb5 Rxa1 20. Rxa1 Nd8 21. Ra8 g4 22. hxg4 Qd7 23. Nc4 Bg5 24. Nb6 Qc7 25. Nd5 Qd7 26. Nf6+ Bxf6 27. Qxf6 Rh7 28. Ng3 Bxg4 29. Nf5 Bxf5 30. exf5 Qe7 31. Qxe7+ Kxe7 32. f6+ Kxf6 33. Bxh7 1-0",
    B: "1706",
  },
  {
    W: "1656",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. O-O Nxe4 5. d4 exd4 6. Nxd4 Nxd4 7. Qxd4 Nd6 8. Re1+ Be7 9. Qxg7 Rf8 10. Bh6 Nxc4 11. Qxf8# 1-0",
    B: "1654",
  },
  {
    W: "985",
    T: "300+0",
    P: "1. g3 e5 2. d4 d6 3. d5 Bf5 4. Bg2 Qf6 5. e4 Bxe4 6. Bxe4 Nc6 7. a4 Nd4 8. c3 Nf3+ 9. Bxf3 1-0",
    B: "985",
  },
  {
    W: "1273",
    T: "300+0",
    P: "1. e4 e6 2. d4 Qh4 3. Nc3 Bb4 4. Bd2 Bxc3 5. Bxc3 d5 6. e5 Nd7 7. Bb5 c6 8. Nf3 Qe4+ 9. Be2 Nb6 10. O-O Na4 11. Re1 Nxc3 12. bxc3 Ne7 13. Bd3 Qf4 0-1",
    B: "1250",
  },
  {
    W: "1802",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d5 3. Nxe5 Qe7 4. d4 dxe4 5. Be3 Nf6 6. Nc3 g6 7. Bc4 Be6 8. Bxe6 Qxe6 9. O-O Bd6 10. f4 Nbd7 11. Qe2 Nd5 12. Nxe4 O-O-O 13. Nxd6+ Qxd6 14. Nxf7 Qb6 15. Nxh8 Rxh8 16. c4 N5f6 17. d5 Re8 18. Bxb6 Rxe2 19. Bd4 Ng4 20. h3 Nh6 21. b4 Nf5 22. Bc3 Nb6 23. g4 Ne3 24. Rfe1 Rg2+ 25. Kh1 Nbxc4 26. Bd4 Rg3 27. Bxe3 Rxh3+ 28. Kg2 Rxe3 29. Rxe3 Nxe3+ 30. Kf3 Nxd5 31. a3 c6 32. Re1 Kd7 33. f5 gxf5 34. gxf5 a6 35. Kg4 Nf6+ 36. Kg5 Ng8 37. f6 h6+ 38. Kg6 Nxf6 1-0",
    B: "1835",
  },
  {
    W: "2312",
    T: "30+0",
    P: "1. Nf3 Nf6 2. g3 d6 3. Bg2 Nc6 4. d4 e5 5. dxe5 dxe5 6. Qxd8+ Nxd8 7. O-O Nc6 8. Nfd2 e4 9. Nxe4 Nxe4 10. Bxe4 Bb4 11. Bg2 Bc5 12. c3 Be7 13. Nd2 O-O 14. Ne4 Bf5 15. Bf4 Be6 16. Nc5 Bxc5 17. Bxc7 Rac8 18. Bf4 Bc4 19. b3 Bxe2 20. Rfe1 Bd3 21. Rad1 Bg6 22. h4 Be7 23. Bf3 h5 24. Bxc6 Bc5 25. Bxb7 Rce8 26. Be4 f5 27. Bd5+ Bf7 28. Rxe8 Rxe8 29. Bxf7+ Kxf7 30. Rd7+ Re7 31. Rd5 1-0",
    B: "1975",
  },
  {
    W: "1664",
    T: "420+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bc4 g6 4. O-O Bg7 5. d3 Nf6 6. h3 O-O 7. a4 e6 8. b3 d5 9. exd5 Nxd5 10. Bb5 a6 11. Bxc6 bxc6 12. Re1 Bd7 13. Ne5 Be8 14. Nxc6 Bxc6 15. c4 Nc7 16. Ra2 a5 17. Bb2 Bd4 18. Bxd4 Qxd4 19. Nd2 Rad8 20. Ne4 f5 21. Ng5 e5 22. Rd2 Rfe8 23. Re3 f4 24. Ree2 Ne6 25. Qe1 Nxg5 26. Rxe5 Rxe5 0-1",
    B: "1718",
  },
  {
    W: "1015",
    T: "60+0",
    P: "1. d4 d5 2. Bf4 f6 3. e3 e5 4. Bg3 Bf5 5. Bd3 Ne7 6. c3 Nbc6 7. Nf3 Ng6 8. Bxf5 Nce7 9. Qd3 Nxf5 10. Qxf5 Ne7 11. Qh5+ g6 12. Qg4 f5 13. Qg5 Qd7 14. Bxe5 O-O-O 15. Bxh8 Bh6 16. Ne5 1-0",
    B: "926",
  },
  {
    W: "1280",
    T: "60+0",
    P: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 e6 5. fxe4 Bb4 6. Qd2 Nxe4 7. Qe3 f5 8. a3 Bxc3+ 9. bxc3 O-O 10. Bb2 Nc6 11. Bb5 a6 12. Bxc6 bxc6 13. Nh3 Bb7 14. Ng5 Nxg5 15. h4 Ne4 16. Rh3 c5 17. Rg3 g6 18. Rg5 Nxg5 19. hxg5 Bxg2 20. c4 cxd4 21. Bxd4 c5 22. Bxc5 f4 23. Qe4 f3 24. Bd4 f2+ 25. Ke2 Qxd4 26. Qxd4 Rf5 0-1",
    B: "1180",
  },
  {
    W: "1705",
    T: "60+0",
    P: "1. d4 f5 2. c4 Nf6 3. Nc3 g6 4. e3 Bg7 5. Bd3 O-O 6. Nge2 d6 7. O-O Be6 8. Nf4 Nbd7 9. Nxe6 1-0",
    B: "1657",
  },
  {
    W: "1752",
    T: "60+0",
    P: "1. e4 g6 2. d4 c5 3. c3 cxd4 4. cxd4 Bg7 5. Be3 d5 6. e5 e6 7. f4 Ne7 8. Nc3 O-O 9. Bd3 Nd7 10. Nf3 f6 11. O-O fxe5 12. fxe5 Nf5 13. Bf2 Nb6 14. b3 a6 15. Qc2 g5 16. g4 Nh4 17. Bxh7+ Kh8 18. Nxh4 gxh4 19. Qg6 h3 20. Qh5 Qe7 21. Be3 Bd7 22. Bg5 Qe8 23. Bg6+ Kg8 24. Qh7# 1-0",
    B: "1855",
  },
  {
    W: "1357",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 d5 3. d4 dxe4 4. Nxe5 Bd6 5. Qh5 g6 6. Nxg6 fxg6 7. Qd5 Qe7 8. c3 Be6 9. Qxe4 c6 10. Bd3 Nf6 11. Qe2 Bd5 12. O-O Qxe2 13. Bxe2 O-O 14. c4 Be4 15. Nc3 Bf5 16. b3 Nbd7 17. c5 Bc7 18. Bb2 Ba5 19. a3 Bxc3 20. Bxc3 Ne4 21. Bd3 Nxc3 22. Bxf5 Rxf5 23. Rac1 Ne4 24. f3 Nd2 25. Rfd1 Nxb3 26. Rb1 Na5 27. a4 Raf8 28. Re1 Kf7 29. Re7+ Kxe7 30. Re1+ Kf7 31. d5 Re8 32. Re7+ 1-0",
    B: "1347",
  },
  {
    W: "2011",
    T: "60+0",
    P: "1. c4 g6 2. Nc3 Bg7 3. e4 d6 4. d3 Nf6 5. f4 O-O 6. Nf3 b6 7. Be2 Bb7 8. O-O Nbd7 9. Nd5 Re8 10. Ng5 Nxd5 11. cxd5 Nc5 12. Rb1 e6 13. b4 Nd7 14. dxe6 fxe6 15. Qb3 d5 16. exd5 Bxd5 17. Qc2 Nf6 18. Bf3 Bxf3 19. Nxf3 Nd5 20. Ne5 Qh4 21. Bd2 Bxe5 22. fxe5 Qd4+ 23. Kh1 Qxe5 24. Rbe1 Qd4 25. Bh6 Nxb4 26. Qxc7 Qh8 1-0",
    B: "2050",
  },
  {
    W: "2291",
    T: "60+0",
    P: "1. e4 c5 2. d4 cxd4 3. c3 Nf6 4. e5 Nd5 5. cxd4 Nc6 6. Nc3 Nxc3 7. bxc3 g6 8. Nf3 Bg7 9. Bd3 O-O 10. h4 d6 11. h5 dxe5 12. hxg6 hxg6 13. d5 Qxd5 14. Bh6 e4 15. Qd2 Qxd3 16. Qg5 exf3 17. Bxg7 Qe2# 0-1",
    B: "2430",
  },
  {
    W: "1718",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Bc5 5. c3 h6 6. b4 Bb6 7. a4 a6 8. a5 Ba7 9. Qb3 O-O 10. O-O d6 11. Be3 Bxe3 12. fxe3 Re8 13. Nbd2 Ne7 14. Rfe1 d5 15. exd5 Nexd5 16. e4 Nb6 17. Bxf7+ Kh8 18. Bxe8 Qxe8 19. Nf1 Nbd7 20. Ng3 c5 21. bxc5 Nxc5 22. Qc4 Ncd7 23. Nf5 Nf8 24. Qa2 Bxf5 25. exf5 Qh5 26. h3 Qxf5 27. Rf1 Qxd3 28. Qf7 Qxc3 29. Nh4 Qe3+ 30. Kh2 Qf4+ 31. Kg1 Qxh4 32. Rxf6 gxf6 0-1",
    B: "1733",
  },
  {
    W: "2132",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3 Qc7 7. Nf3 Ne7 8. Bd3 Nbc6 9. a4 Qa5 10. Bd2 c4 11. Be2 b6 12. h4 Bb7 13. O-O O-O-O 14. Ng5 Rdf8 15. Bh5 g6 16. Bg4 h6 17. Nf3 Kc7 18. Qe2 Bc8 19. Rfb1 f5 20. Rb5 Qa6 21. Bh3 g5 22. hxg5 hxg5 23. Bxg5 Ng6 24. Bf6 Rh7 25. Qd2 Rfh8 26. Ng5 Rxh3 27. Nxh3 Rh5 28. Qe2 Bd7 29. Qxh5 1-0",
    B: "1986",
  },
  {
    W: "1656",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Qe7 3. Bc4 d6 4. Nc3 c6 5. d4 b5 6. Bb3 f6 7. d5 b4 8. Na4 cxd5 9. Qxd5 Bb7 10. Qb5+ Nc6 11. Bd5 a6 12. Bxc6+ Kf7 13. Qxb7 Qc7 14. Qxc7+ Ne7 15. Bd5+ Kg6 16. Qd7 Nxd5 17. exd5 Kh5 18. Qf5+ g5 19. Qxf6 Bh6 20. Bxg5 Bxg5 21. Qxg5# 1-0",
    B: "1586",
  },
  {
    W: "1559",
    T: "60+0",
    P: "1. e4 b6 2. Nc3 Bb7 3. Nf3 Nf6 4. d3 e5 5. Bg5 d6 6. h3 Be7 7. Qd2 O-O 8. O-O-O Nc6 9. Be2 Nd4 10. Nxd4 exd4 11. Nb5 c5 12. c3 a6 13. Na3 Rc8 14. cxd4 cxd4+ 15. Kb1 d5 16. e5 Nd7 17. Bxe7 Qxe7 18. Bg4 Qxe5 19. Rhe1 Qd6 20. Bxd7 Qxd7 21. Re2 Rfe8 22. Rde1 Rxe2 23. Rxe2 Qc7 24. f3 b5 25. b3 a5 26. Nc2 a4 27. Nxd4 axb3 28. Nxb3 d4 29. Nxd4 Bd5 30. Nxb5 Qb6 31. a4 Ra8 32. Qb4 Rb8 33. Rb2 Qg1+ 34. Kc2 Qf2+ 35. Kc3 Rc8+ 36. Qc5 Rxc5+ 0-1",
    B: "1529",
  },
  {
    W: "1841",
    T: "60+0",
    P: "1. e4 g6 2. Bc4 Bg7 3. Nf3 e6 4. d4 b6 5. d5 exd5 6. Qxd5 Qf6 7. Qxa8 Ba6 8. Qxb8+ Ke7 9. Bg5 Nh6 10. Bxf6+ Bxf6 11. Qxc7 Bxc4 12. e5 Bh4 13. Qd6+ Kd8 14. Nc3 Nf5 15. Qb4 Ba6 16. Nxh4 Nxh4 17. Nd5 Nxg2+ 18. Kd1 Nf4 19. Qe7+ Kc8 20. Nf6 Be2+ 21. Kd2 Kb7 22. Qxd7+ Ka6 23. a4 Rc8 24. b4 Rxc2+ 25. Kxc2 Bd3+ 26. Kc3 Bc4 27. b5+ Ka5 28. Kxc4 Ne2 29. Qxa7# 1-0",
    B: "1663",
  },
  {
    W: "1203",
    T: "600+0",
    P: "1. e4 c6 2. f4 d5 3. Nc3 dxe4 4. Nxe4 Bf5 5. d3 e6 6. Qf3 Be7 7. b3 Nf6 8. Bb2 Qa5+ 9. Bc3 Bb4 10. Ne2 Nxe4 11. dxe4 Bxc3+ 12. Nxc3 Bxe4 13. Qe3 Bxc2 14. Be2 Nd7 15. Qd2 Bf5 16. O-O-O Qa3+ 17. Qb2 Qxb2+ 18. Kxb2 e5 19. Rhf1 O-O 20. fxe5 Be6 21. Ne4 Nxe5 22. Nd6 Rab8 23. Kc3 b5 24. Kd4 f6 25. g4 Rbd8 26. Kc5 Nd7+ 27. Kxc6 Nb8+ 28. Kxb5 Bd7+ 29. Kb4 Nc6+ 30. Kc5 Ne7 31. Bc4+ Kh8 32. Nb7 Rc8+ 33. Kb4 Bxg4 34. Rd4 Nc6+ 35. Kc3 Nxd4 36. Kxd4 Be2 37. Bxe2 Rfe8 38. Bb5 Re7 39. Ba6 Rd7+ 40. Ke3 Rc3+ 41. Kf4 Rd4+ 42. Kf5 Rd5+ 43. Ke4 Re5+ 44. Kd4 Rc7 45. Nd6 Re6 46. Kd5 Re2 47. Bxe2 Rd7 48. Bb5 Rd8 49. Re1 Kg8 50. Bc4 Kf8 51. Kc6 a5 52. Kc7 Ra8 53. Nc8 g5 54. Kb7 Rxc8 55. Kxc8 Kg7 56. Re7+ Kg6 57. Bd3+ f5 58. Kd7 g4 59. Re6+ Kg5 60. h4+ Kxh4 61. Rh6+ Kg5 62. Rxh7 g3 63. Rg7+ Kf4 64. Ke6 g2 65. Rxg2 Kf3 66. Rd2 f4 67. Be2+ Kf2 68. Bg4+ Kg3 69. Kf5 f3 70. Bxf3 Kxf3 71. Rd3+ Ke2 72. Ke4 1-0",
    B: "1307",
  },
  {
    W: "1519",
    T: "600+0",
    P: "1. d4 d5 2. c4 dxc4 3. e3 Bf5 4. Bxc4 e6 5. Nc3 Nc6 6. Qb3 Rb8 7. d5 Nb4 8. Qa4+ c6 9. dxc6 bxc6 10. a3 Nc2+ 0-1",
    B: "1548",
  },
  {
    W: "1083",
    T: "600+0",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qc4 Ne5 5. Qa4 Ne7 6. f4 N5c6 7. e5 a6 8. Qe4 d5 9. Qf3 Nd4 10. Qa3 Nxc2+ 11. Kd1 Nxa3 12. bxa3 Nf5 13. g4 Nd4 14. f5 Bc5 15. Ne2 Nf3 16. Bb2 d4 17. Bg2 d3 18. Nf4 Ng5 19. Nxd3 Qxd3+ 20. Nd2 O-O 21. h4 Rd8 22. Bc1 Be3 23. Rh3 Bd7 24. Ke1 Nxh3 25. Bxh3 Bf2+ 26. Kxf2 Qxh3 27. h5 Qxg4 28. e6 Qd4+ 29. Ke2 Qxa1 30. Kd1 fxe6 31. f6 Ba4+ 0-1",
    B: "1202",
  },
  {
    W: "1641",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Bc4 Nxe4 4. d3 Nf6 5. Nxe5 d5 6. Bb3 Bd6 7. Bf4 O-O 8. O-O Re8 9. Re1 c6 10. Qf3 Qc7 11. Qg3 Nh5 12. Qg5 Nxf4 13. Qxf4 Rxe5 14. Nd2 Re6 15. Qh4 Bd7 16. Re3 Bf4 17. Rxe6 Bxe6 18. Nf3 Nd7 19. g3 Bh6 20. Re1 Nf6 21. c4 dxc4 22. dxc4 Qd7 23. Bc2 Bg4 24. Ne5 Qd2 25. Rc1 Qxc1+ 26. Kg2 Qxc2 0-1",
    B: "1835",
  },
  {
    W: "1562",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Qf6 3. d4 exd4 4. Nxd4 Qg6 5. Qf3 Bc5 6. c3 Nf6 7. Bd3 d6 8. e5 1-0",
    B: "1581",
  },
  {
    W: "1546",
    T: "600+0",
    P: "1. d3 d5 2. e3 e5 3. h3 c5 4. g3 Nc6 5. Bg2 Be6 6. a3 Nf6 7. Nd2 Be7 8. b3 O-O 9. Ngf3 Qc8 10. g4 e4 11. Nh2 d4 12. exd4 Nxd4 13. Nxe4 Nxe4 14. Bxe4 f5 15. Bf3 Bh4 16. Bb2 fxg4 17. Bxg4 Bxf2+ 18. Kd2 Nf5 19. Rf1 Be3+ 20. Ke2 Bd4 21. Bxd4 Ng3+ 22. Ke1 cxd4 23. Rxf8+ Qxf8 24. Bxe6+ Kh8 25. Qf3 Qxf3 26. Nxf3 Re8 27. Kf2 1-0",
    B: "1648",
  },
  {
    W: "1615",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Bc4 e6 5. Nxd4 d5 6. exd5 exd5 7. Bd3 Nf6 8. Qe2+ Be7 9. O-O O-O 10. Re1 Re8 11. Bg5 Bd7 12. Bxf6 Bxf6 13. Qxe8+ Bxe8 14. Rxe8+ Qxe8 15. Bxh7+ Kxh7 16. Ne6 Qxe6 17. Nc3 Bxc3 18. Re1 Qxe1# 0-1",
    B: "1624",
  },
  {
    W: "1502",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Qxd4 Nc6 5. Qa4 Nf6 6. Nc3 g6 7. e5 dxe5 8. Nxe5 Bg7 9. Nxc6 bxc6 10. Qxc6+ Bd7 11. Qf3 O-O 12. Bd2 Bf5 13. O-O-O Qb6 14. Be3 Qb4 15. a3 Qg4 16. Be2 Qxf3 17. Bxf3 Ng4 18. Bxa8 Rxa8 19. Nd5 Nxe3 20. fxe3 e6 21. Ne7+ Kf8 22. Nxf5 exf5 23. Rd7 Rb8 24. c3 Ke8 25. Rhd1 Bf6 26. Rxa7 Bg5 27. Rd3 f4 28. Ra5 h6 29. exf4 Bxf4+ 30. Kc2 Bxh2 31. g3 Bg1 32. Re5+ Kf8 33. Rd1 Bf2 34. g4 Bg3 35. Red5 Bh4 36. b4 Ke7 37. Rf1 Ke6 38. Rd3 Ra8 39. Kb3 f5 40. Re3+ Kf6 41. Ref3 g5 42. Rxf5+ 1-0",
    B: "1428",
  },
  {
    W: "1705",
    T: "60+0",
    P: "1. e4 d6 2. d4 Nd7 3. c4 e5 4. Nc3 exd4 5. Qxd4 c5 6. Qd1 Ngf6 7. Nf3 Be7 8. Bg5 O-O 9. Bd3 Ne5 10. Nxe5 dxe5 11. O-O h6 12. Bh4 Be6 13. Bg3 Bd6 14. Qc2 Qc7 15. Nb5 Qc6 16. Nxd6 Qxd6 17. Rad1 Nd7 18. Be2 Qe7 19. b3 f6 20. f4 b6 21. f5 Bf7 22. Bf3 Rfd8 23. Rd2 Nf8 24. Rfd1 Rxd2 25. Rxd2 Rd8 26. Qd1 Rxd2 27. Qxd2 Nd7 28. h3 Nb8 29. Bf2 Nc6 30. Qc3 Nd4 31. Bxd4 exd4 32. Qd2 Qe5 33. Qf2 Qf4 0-1",
    B: "1830",
  },
  {
    W: "2061",
    T: "60+0",
    P: "1. e3 e6 2. d4 b6 3. Nf3 Bb7 4. c3 c5 5. Bd3 Qc7 6. Qe2 a6 7. Nbd2 cxd4 8. exd4 Nc6 9. Ne5 Nxe5 10. dxe5 Bxg2 11. Rg1 Bb7 12. Be4 Bxe4 13. Qxe4 O-O-O 14. Qa8+ Qb8 15. Qxa6+ Qb7 16. Qxb7+ Kxb7 17. Nc4 b5 18. Na5+ Kb6 19. b4 d6 20. Be3+ Kc7 21. exd6+ Bxd6 22. Rxg7 Nf6 23. Rxf7+ Rd7 24. Rxf6 Rg8 25. O-O-O Bxh2 26. Rxe6 Rxd1+ 27. Kxd1 Rd8+ 28. Ke2 Bd6 29. Rh6 Rd7 30. Nb3 Re7 31. Nd4 Bf4 32. Nxb5+ Kb7 33. Nd6+ Kb8 34. Nf5 Rd7 35. Bxf4+ Kb7 36. Nd6+ Ka7 37. Nb5+ Kb7 38. Nd4 Re7+ 39. Be3 Rf7 40. Ne6 Kc8 41. Ng5 Rd7 42. Rxh7 Rd8 43. Ne6 Re8 44. Rc7+ Kb8 45. Rc6 Kb7 46. Nd4 Ka7 47. b5 Rb8 0-1",
    B: "2108",
  },
  {
    W: "2333",
    T: "60+0",
    P: "1. Nf3 d5 2. g3 Bg4 3. h3 Bh5 4. g4 Bg6 5. d3 e6 6. Ne5 Nd7 7. Nxg6 hxg6 8. Bg2 Ngf6 9. c4 c6 10. e3 Bd6 11. Nc3 Rc8 12. Qb3 Nc5 13. Qc2 a5 14. d4 Na6 15. a3 Bb8 16. c5 e5 17. Na4 Qe7 18. Nb6 exd4 19. Nxc8 Qe6 20. Nd6+ Bxd6 21. cxd6 O-O 22. O-O Qxd6 23. exd4 Nc7 24. Qd3 Ne6 25. Be3 b5 26. f4 Rc8 27. f5 gxf5 28. Qxf5 b4 29. Bg5 Qe7 30. Bxf6 Qxf6 31. Qxf6 gxf6 32. Rad1 Kg7 33. Rfe1 bxa3 34. bxa3 Rb8 35. Rb1 Rc8 36. Rec1 Nxd4 37. Kf2 Re8 38. Rd1 Re2+ 39. Kg3 Re3+ 40. Kh2 Ne6 41. Kg1 Ng5 42. Rf1 Rg3 43. Rbe1 Rxa3 44. Rf2 Ra2 45. Kf1 Rxf2+ 46. Kxf2 Ne4+ 47. Kg1 1-0",
    B: "2378",
  },
  {
    W: "1846",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nxd4 5. Qxd4 d6 6. Nc3 Nf6 7. Be3 Be7 8. O-O-O O-O 9. f4 c5 10. Qd2 a6 11. h3 b5 12. e5 dxe5 13. Qf2 exf4 14. Rxd8 fxe3 15. Qxe3 Bxd8 16. Bd3 Bb7 17. Rg1 Bb6 18. Qg5 c4 19. Bf5 Bxg1 20. h4 Rae8 21. Kd1 Rd8+ 22. Ke2 Rfe8+ 23. Kf1 Be3 24. Qg3 Ne4 25. Nxe4 Rd1+ 26. Ke2 Rd4 27. Nf6+ Kf8 28. Nxh7+ Kg8 29. Nf6+ Kf8 1-0",
    B: "1783",
  },
  {
    W: "1903",
    T: "300+0",
    P: "1. e4 e6 2. f4 d5 3. e5 c5 4. Nf3 Nc6 5. Bb5 Qb6 6. Nc3 Bd7 7. Bxc6 bxc6 8. b3 a5 9. Na4 Qa7 10. Ba3 Nh6 11. c4 Nf5 12. O-O Be7 13. Qe2 O-O 14. Qf2 Rfe8 15. Bxc5 Bxc5 16. Nxc5 Bc8 17. g4 Ne7 18. Na4 Qxf2+ 19. Rxf2 Ba6 20. d3 Red8 21. Rd2 dxc4 22. dxc4 Rxd2 23. Nxd2 Ng6 24. Rf1 Rd8 25. Ne4 f5 1-0",
    B: "1849",
  },
  {
    W: "1753",
    T: "600+0",
    P: "1. e4 c5 2. c3 Nc6 3. d4 cxd4 4. cxd4 d5 5. e5 e6 6. Nf3 h6 7. Nc3 a6 8. Be3 Bb4 9. Bd3 Nge7 10. O-O Ba5 11. Rc1 Bd7 12. a3 O-O 13. b4 Bb6 14. Na4 Ba7 15. Nc5 f5 16. Nxb7 Qc7 17. Nd6 f4 18. Bd2 Qd8 19. Bxa6 Nxd4 20. Nxd4 Bxd4 21. Be2 Bxe5 22. Nb5 Bxb5 23. Bxb5 f3 24. g3 Ng6 25. a4 Bd6 26. Qc2 Qf6 27. Kh1 Ne5 28. Qc3 Qf5 29. Bf4 Qh5 30. Rce1 Ng4 31. h4 Bxf4 32. Qxf3 Bd2 33. Qe2 Bxe1 34. Qxe1 Qf5 35. f3 Ne5 36. f4 Qh3+ 37. Kg1 Ng4 38. Qxe6+ Kh8 39. Qe2 Qxg3+ 40. Qg2 Qxh4 41. Be2 Ne3 42. Qf2 Nxf1 43. Bxf1 Qxf2+ 44. Kxf2 Rxf4+ 45. Ke2 Rxb4 0-1",
    B: "1723",
  },
  {
    W: "1512",
    T: "600+0",
    P: "1. e4 c5 2. Bc4 Nc6 3. Bxf7+ Kxf7 4. Qh5+ g6 5. Qxc5 e6 6. Qc3 Bg7 7. Qf3+ Nf6 8. d3 Ne5 9. Qf4 Qc7 10. Nf3 d6 11. Ng5+ Ke7 12. Nc3 Bd7 13. d4 Nc4 14. O-O Raf8 15. Qg3 e5 16. dxe5 Nh5 17. Nd5+ Kd8 18. Nxc7 Nxg3 19. Nce6+ Bxe6 20. Nxe6+ Ke7 21. Nxf8 Nxf1 22. Nxg6+ hxg6 23. Kxf1 Bxe5 24. Bg5+ Ke6 25. f4 Bxb2 26. f5+ gxf5 27. exf5+ Kxf5 28. Rd1 Kxg5 29. Rd5+ Kg6 30. Rb5 Rf8+ 31. Ke2 Be5 32. Rxb7 Nb6 33. Rxa7 Bd4 34. Re7 Rf2+ 35. Kd3 Be5 36. Re6+ Kf7 37. Rh6 Rxg2 38. Rh7+ Kg6 39. Rh4 Nd5 40. h3 Rg3+ 41. Kc4 Ne3+ 42. Kb5 Nxc2 43. a4 Rb3+ 44. Ka5 d5 45. Ka6 Nd4 46. a5 Nc6 47. Rg4+ Kf7 48. Rh4 Nb8+ 49. Ka7 d4 50. Rh7+ Ke6 51. Rh6+ Kf5 52. Rh5+ Ke6 53. Rh6+ Kd5 54. Rg6 d3 55. Rg1 d2 56. Rd1 Kc6 57. Rxd2 Rb7+ 58. Ka8 Rh7 59. Rc2+ Kd7 60. Rd2+ Ke6 61. Rb2 Nc6 62. Rb6 Ra7# 0-1",
    B: "1538",
  },
  {
    W: "1695",
    T: "600+0",
    P: "1. c4 Nf6 2. Nc3 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 Re8 6. O-O e5 7. d4 e4 8. Ng5 h6 9. Ngxe4 Nxe4 10. Nxe4 f5 11. Nc5 d6 12. Nxb7 Qf6 13. Na5 Qxd4 14. Qxd4 Bxd4 15. Bxa8 Bb6 16. Nb3 c6 17. Bxh6 Bd7 18. Rad1 1-0",
    B: "1716",
  },
  {
    W: "1943",
    T: "600+0",
    P: "1. d4 d5 2. c4 c6 3. Nc3 e6 4. Nf3 Nf6 5. e3 Be7 6. Bd3 O-O 7. O-O b6 8. a3 Bb7 9. b4 Nbd7 10. cxd5 cxd5 11. Re1 Rc8 12. Bb2 a6 13. Rc1 b5 14. Bb1 Nb6 15. Nd2 Nc4 16. Nxc4 dxc4 17. e4 Qd7 18. e5 Ne8 19. Qh5 g6 20. Qg4 Ng7 21. Ne4 h5 22. Qh3 Nf5 23. Nc5 Bxc5 24. dxc5 Qd2 25. Bc3 Qg5 26. g3 Rcd8 27. f4 Qg4 28. Qxg4 hxg4 29. Be4 Bxe4 30. Rxe4 Rd3 31. Kf2 Rfd8 32. Re2 Rf3+ 33. Ke1 Rdd3 34. Bb2 c3 35. Ba1 Nd4 36. Rf2 Rfe3+ 37. Kf1 Nb3 38. Rxc3 Rxc3 39. Bxc3 Rxc3 40. Rb2 Nd4 41. Rd2 Nf3 42. Re2 Kf8 43. Kg2 Ke7 44. a4 f5 45. exf6+ Kxf6 46. axb5 axb5 47. h3 gxh3+ 48. Kxh3 Ng1+ 0-1",
    B: "1979",
  },
  {
    W: "1883",
    T: "600+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. d4 e5 4. Nc3 Qxd4 5. Qxd4 exd4 6. Nb5 Kd8 7. Nxd4 Nf6 8. Bg5 Nd7 9. O-O-O c6 10. Nf5 g6 11. Nd6 Bxd6 12. Rxd6 Re8 13. Bxf6+ 1-0",
    B: "1854",
  },
  {
    W: "2146",
    T: "600+0",
    P: "1. d4 Nf6 2. Bf4 g6 3. Nc3 Bg7 4. e4 d6 5. Qd2 O-O 6. O-O-O Re8 7. Nf3 Bg4 8. Bg5 Nbd7 9. Qe3 c6 10. h3 Be6 11. Bh6 Bh8 12. d5 cxd5 13. exd5 Bf5 14. g4 Bxg4 15. hxg4 Nxg4 16. Qd2 Qa5 17. Nd4 Rac8 18. f3 Rcd8 19. fxg4 Ne5 20. Bb5 Nd7 21. Bxd7 Rxd7 22. Be3 e5 23. dxe6 fxe6 24. g5 Rc8 25. Kb1 Rdc7 26. Ne4 Qd5 27. Nf6+ Bxf6 28. gxf6 h5 29. Rdg1 Kf7 30. Qg2 Qxg2 31. Rxg2 a6 32. Rhg1 Rg8 33. Bg5 Rc4 34. c3 b5 35. Rf2 b4 36. Bd2 e5 37. Nf3 bxc3 38. Bxc3 Rf4 39. Ng5+ Kxf6 40. Rxf4+ Kg7 41. Rf7+ 1-0",
    B: "1985",
  },
  {
    W: "1785",
    T: "600+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. g3 e6 4. Bg2 Be7 5. d3 d6 6. Be3 Bd7 7. Qd2 Nf6 8. O-O-O O-O 9. f3 a6 10. g4 b5 11. g5 Nh5 12. f4 g6 13. Bf3 Ng7 14. h4 b4 15. Nce2 a5 16. h5 a4 17. Ng3 b3 18. cxb3 axb3 19. a3 Nd4 20. Bxd4 cxd4 21. hxg6 Rc8+ 22. Kb1 fxg6 23. Rc1 Bc6 24. N1e2 e5 25. Nxd4 exd4 26. Be2 d5 27. Bg4 Rc7 28. e5 Ba8 29. Rxc7 Qxc7 30. Qh2 Rxf4 31. Qxh7+ Kf7 32. e6+ Ke8 33. Qxg7 Qc2+ 34. Ka1 Qxd3 35. Rh8+ Rf8 36. Rxf8+ Bxf8 37. Qd7# 1-0",
    B: "1800",
  },
  {
    W: "1364",
    T: "600+0",
    P: "1. d3 d5 2. Nf3 Nc6 3. d4 Bf5 4. e3 Nb4 5. a3 Nxc2+ 6. Kd2 Nxa1 7. Nc3 e6 8. b4 a5 9. Bb2 axb4 10. axb4 Bxb4 11. Bxa1 Bxc3+ 12. Bxc3 Ra2+ 13. Ke1 Bc2 14. Qd2 Qa8 15. Bd3 Ra1+ 16. Bxa1 Qxa1+ 17. Ke2 Bxd3+ 18. Qxd3 Qxh1 19. Qb5+ c6 20. Qxb7 Ne7 21. Qb8+ Kd7 22. Qxh8 Qxg2 23. Qxh7 Qg6 24. Qxg6 fxg6 25. Ne5+ Kd6 26. Kf3 c5 27. dxc5+ Kxc5 28. Kg4 d4 29. exd4+ Kxd4 30. f4 Ke4 31. h4 Nd5 32. Nxg6 Nxf4 33. Nxf4 e5 34. Ng6 Kd5 35. Kf5 e4 36. Nf4+ Kd4 37. h5 e3 38. Kg6 Ke4 39. Ne2 Kf3 40. Nc1 Ke4 41. Kxg7 Kd4 42. h6 Kc3 43. Ne2+ Kd2 44. Nf4 e2 45. Nxe2 Kxe2 46. h7 1-0",
    B: "1403",
  },
  {
    W: "1477",
    T: "60+0",
    P: "1. e4 d5 2. f4 dxe4 3. Nc3 f5 4. d3 Nf6 5. Be2 Nc6 6. Bd2 e6 7. Bh5+ g6 8. Be2 Bd6 9. Nh3 h6 10. O-O Bd7 11. a3 Qe7 12. Na2 e5 13. fxe5 Bxe5 14. Nf4 Nd4 15. Nxg6 Qg7 16. Nxh8 Qxh8 17. Bh5+ Nxh5 18. Qxh5+ Ke7 19. c3 Be8 20. Qh4+ Ke6 21. cxd4 Bxd4+ 22. Kh1 Bxb2 23. Rab1 Be5 24. Nc3 Bc6 25. dxe4 Bxe4 26. Nxe4 fxe4 27. Qxe4 Kd6 28. Bf4 Re8 29. Bxe5+ Rxe5 30. Qd3+ Ke6 31. Rbc1 Qg7 32. Qc4+ Kd6 33. Rcd1+ Ke7 34. Qb4+ Ke6 35. Qc4+ Ke7 36. Qxc7+ Ke6 37. Qxg7 Rd5 38. Rde1+ Re5 0-1",
    B: "1472",
  },
  {
    W: "1482",
    T: "60+0",
    P: "1. e3 e5 2. d3 d5 3. d4 e4 4. f3 f5 5. fxe4 fxe4 6. Bd2 Nf6 7. Qe2 Nc6 8. Nc3 Bg4 9. Qf2 Bd6 10. h3 Bh5 11. g4 Bg6 12. O-O-O Qe7 13. Qh4 Bb4 14. Kb1 O-O-O 15. Qg3 Bd6 16. Qg2 Nb4 17. h4 Nd7 18. h5 Bf7 19. g5 Nb6 20. g6 hxg6 21. hxg6 Rxh1 22. Qxh1 Bxg6 23. Bh3+ Kb8 24. Nge2 Bf7 25. Nf4 Bxf4 26. exf4 g5 27. Be3 gxf4 28. Bxf4 Nc4 29. Bxc7+ Kxc7 30. a3 Na6 31. Nxd5+ Rxd5 0-1",
    B: "1482",
  },
  {
    W: "1421",
    T: "60+0",
    P: "1. d4 d5 2. c4 dxc4 3. e3 c6 4. Bxc4 b5 5. Bd3 Bb7 6. Nc3 Nf6 7. Nf3 e6 8. Qc2 Bb4 9. O-O O-O 10. a3 Bxc3 11. bxc3 Qc7 12. Re1 Ng4 13. e4 Re8 14. e5 c5 15. Bxh7+ Kf8 16. Be4 g6 17. Bxb7 Qxb7 18. Qe4 Qxe4 19. Rxe4 Nc6 20. Be3 Nf6 21. Rh4 Ke7 22. Bg5 Na5 23. Bxf6+ Kd7 24. Rh7 Nb3 25. Rd1 Na5 26. dxc5+ Kc6 27. Nd4+ Kxc5 28. Rxf7 Kc4 29. Rc7+ Kd5 30. Nxb5+ Ke4 31. Rd4+ Kf5 32. Rg7 Rab8 33. Rf7 Rxb5 34. Bh4+ Kxe5 35. Rf5+ 1-0",
    B: "1429",
  },
  {
    W: "1452",
    T: "60+0",
    P: "1. d4 d5 2. c4 c6 3. Nc3 e6 4. cxd5 exd5 5. Nf3 Nf6 6. e3 Bg4 7. Be2 Bxf3 8. Bxf3 Be7 9. O-O O-O 10. Re1 Nbd7 11. h3 Re8 12. a3 Bf8 13. b4 Nb6 14. a4 Nc4 15. Qe2 Qc7 16. b5 Bb4 17. bxc6 Bxc3 18. cxb7 Qxb7 19. Bd2 Bxa1 20. Rxa1 Rab8 21. Qd3 Nxd2 22. Qxd2 Qd7 23. Qd3 Rb4 24. Ra3 Rxa4 25. Rxa4 Qxa4 26. g3 Rb8 27. e4 Qa1+ 28. Kg2 Nxe4 29. Bxe4 dxe4 30. Qxe4 Rd8 31. d5 Kf8 32. f4 Qa5 33. g4 Qxd5 34. Qxd5 Rxd5 35. f5 a5 36. Kg3 a4 37. Kf2 a3 38. Ke2 a2 39. Kf3 a1=Q 40. Kg3 Qa3+ 41. Kh4 Qa7 42. Kg5 Qe7+ 43. Kf4 h6 44. Kg3 Rd3+ 45. Kf4 Qe3# 0-1",
    B: "1434",
  },
  {
    W: "1360",
    T: "60+0",
    P: "1. d4 d5 2. c4 dxc4 3. e3 c6 4. Bxc4 b5 5. Bd3 e6 6. Nf3 Nf6 7. Nc3 Be7 8. b4 O-O 9. a3 a5 10. Bb2 axb4 11. axb4 Rxa1 12. Bxa1 Nbd7 13. Bc2 Nb6 14. Qd3 Nc4 15. g4 g6 16. h3 Kg7 17. g5 Ng8 18. d5 exd5 19. Nxd5+ Nf6 20. Nxe7 Qxe7 21. Qc3 Re8 22. Nd4 Nxe3 23. Nxc6 Nxc2+ 24. Kf1 Qe1+ 25. Kg2 Re4 26. Rxe1 Rxe1 27. Qxf6+ Kf8 28. Qe7+ Kg8 29. Qe8+ Rxe8 30. Nd4 Bxh3+ 31. Kxh3 Nxd4 32. Kg2 1-0",
    B: "1372",
  },
  {
    W: "1406",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nf6 3. d4 Nxe4 4. dxc5 Nxc5 5. Bc4 e6 6. O-O Ne4 7. Nc3 Nxc3 8. bxc3 d5 9. Bb3 Nc6 10. a4 Bd7 11. Ba3 Bxa3 12. Rxa3 O-O 13. c4 dxc4 14. Bxc4 Bc8 15. Qb1 Nd4 16. Nxd4 Qxd4 17. Bb3 Bd7 18. a5 Bc6 19. Rd1 Qb4 20. Qb2 Qg4 21. g3 Qxd1# 0-1",
    B: "1411",
  },
  {
    W: "1301",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 g6 3. c3 e6 4. d4 cxd4 5. cxd4 d5 6. e5 Nc6 7. Nc3 Bg7 8. Bb5 Ne7 9. O-O O-O 10. Bg5 h6 11. Bh4 g5 12. Bg3 Nf5 13. Rc1 Nxg3 14. hxg3 Qb6 15. Bd3 Nxd4 16. Nxd4 Bxe5 17. Nce2 Bxd4 18. Nxd4 e5 19. Nf5 e4 20. Ne7+ Kg7 21. Nxd5 Qxb2 22. Bxe4 Be6 23. Ne7 Rad8 24. Nf5+ Bxf5 25. Bxf5 Rxd1 26. Rfxd1 Qxa2 27. Rb1 a5 28. Ra1 a4 29. Rxa2 b5 30. Raa1 Rb8 31. Rdb1 a3 32. Bc2 b4 33. Bb3 Rc8 34. Rc1 Rc3 35. Rxc3 bxc3 36. Rxa3 c2 37. Bxc2 f6 38. Rf3 Kf8 39. g4 Ke7 40. Rf5 Kd6 41. Bb3 Ke7 42. Bg8 1-0",
    B: "1290",
  },
  {
    W: "1347",
    T: "60+0",
    P: "1. d4 d5 2. c4 c5 3. Nc3 cxd4 4. Qxd4 Nf6 5. cxd5 Nxd5 6. Nxd5 e6 7. Nc7+ Qxc7 8. Qa4+ Bd7 9. Qc2 Qxc2 0-1",
    B: "1332",
  },
  {
    W: "1388",
    T: "60+0",
    P: "1. d3 d5 2. e3 Nf6 3. Nf3 e6 4. Nbd2 Bd6 5. Be2 O-O 6. O-O Nbd7 7. e4 dxe4 8. dxe4 e5 9. b3 Re8 10. Bb2 b6 11. c4 Bb7 12. Ne1 c5 13. Nc2 Rc8 14. f3 Bc7 15. Na3 h6 16. Nb5 Ba6 17. Nxc7 Qxc7 18. Re1 Nh7 19. Nf1 Rf8 20. Ng3 Ng5 21. Nf5 Kh7 22. Bd3 Rcd8 23. Re2 Nf6 24. Rd2 g6 25. Be2 gxf5 26. Rxd8 Rxd8 27. Qf1 fxe4 28. fxe4 Nfxe4 29. Rd1 Rxd1 30. Qxd1 Qe7 31. Bd3 Kg7 32. Qg4 Qf6 33. Bxe4 Qg6 0-1",
    B: "1396",
  },
  {
    W: "1177",
    T: "60+0",
    P: "1. Nf3 d5 2. d4 Nc6 3. c4 f6 4. Nc3 e5 5. g3 Be6 6. dxe5 dxc4 7. exf6 Nxf6 8. Bg2 Bd5 9. O-O Bxf3 10. Bxf3 Qxd1 11. Rxd1 Bb4 12. Nb5 Rc8 13. a3 O-O 14. axb4 a6 15. Nd4 Nxb4 16. Nf5 Rcd8 17. Rxd8 Rxd8 18. Bg5 Nc2 19. Rd1 Rxd1+ 20. Kg2 Rb1 21. Ne7+ Kf7 22. Bxf6 Kxf6 23. Nd5+ Ke6 24. Nc3 Rxb2 25. e3 a5 0-1",
    B: "1073",
  },
  {
    W: "1690",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bb5 e6 4. O-O Nf6 5. Nc3 d6 6. d4 cxd4 7. Nxd4 Bd7 8. Bg5 Be7 9. Re1 h6 10. Bh4 O-O 11. Nxc6 bxc6 12. Bd3 Rb8 13. b3 c5 14. Ne2 Bc6 15. Ng3 Qc7 16. Bxf6 Bxf6 17. Rc1 Bb2 18. Rb1 Bc3 19. Rf1 Rfe8 20. Ne2 Bf6 21. c4 d5 22. exd5 exd5 23. cxd5 Bxd5 24. Bb5 Qb7 25. Bxe8 Rxe8 26. f3 a5 27. Nf4 Bd4+ 28. Kh1 Bc6 29. Qd2 Bb5 30. Rfe1 Rxe1+ 31. Rxe1 Qc7 32. Ne2 Bxe2 33. Qxe2 Qc6 34. Qe8+ Qxe8 35. Rxe8+ Kh7 36. Rb8 Kg6 37. Rb5 a4 38. bxa4 Kf6 39. a5 Ke6 40. a6 Kd7 41. a7 1-0",
    B: "1689",
  },
  {
    W: "1641",
    T: "600+0",
    P: "1. d4 Nf6 2. c4 e6 3. a3 b6 4. Nc3 Bb7 5. Bg5 Be7 6. e3 Ne4 7. Bxe7 Qxe7 8. Qc2 Nxc3 9. Qxc3 Qg5 10. Nh3 Qg6 11. Nf4 Qg5 12. d5 exd5 13. h4 Qg4 14. cxd5 O-O 15. Be2 Qf5 16. g4 Qe4 17. O-O-O Na6 18. f3 Qe7 19. h5 Rac8 20. Qd3 c6 21. Kb1 Nc5 22. Qc2 Qxe3 23. dxc6 Bxc6 24. Nd3 Ba4 25. Qd2 Qxd2 26. Rxd2 Nb3 27. Rdd1 Nd4 28. Rde1 Nxe2 29. Rxe2 Bb5 30. Rd2 Bxd3+ 31. Rxd3 Rc7 32. Rhd1 Rd8 33. b4 h6 34. Kb2 g6 35. hxg6 fxg6 36. Re1 d5 37. Re6 Kg7 38. Rd4 Rdd7 39. Re5 Rf7 40. f4 Rc4 41. Rexd5 Rxd4 42. Rxd4 Kf6 43. Kc3 g5 44. f5 h5 45. Rd6+ Ke5 46. Re6+ Kf4 47. gxh5 Kxf5 48. Rg6 Rh7 49. Kd3 Rxh5 50. Rg7 g4 51. Rxa7 Rg5 52. Ke2 Ke4 53. Re7+ Kd4 54. Kf2 Kc4 55. Re6 Kb5 56. Kg3 Rg8 57. Re4 Ka4 58. Re6 Kxa3 59. Rxb6 Kb3 60. b5 Kb4 61. Rb7 Kc5 62. b6 Kc6 63. Rf7 Kxb6 64. Rf4 Rc8 65. Rxg4 Rc3+ 66. Kh4 Rc6 67. Kh5 Rc5+ 68. Rg5 Rc3 69. Rg6+ Kb5 70. Rg5+ Kc6 71. Rg6+ Kd5 72. Rg5+ Ke6 73. Rg6+ Kf5 74. Rg5+ Ke6 0-1",
    B: "1643",
  },
  {
    W: "1608",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bc4 Bc5 5. d3 h6 6. a3 d6 7. b4 Bb6 8. Na4 Bg4 9. h3 Bh5 10. Nxb6 axb6 11. O-O Nd4 12. g4 Nxf3+ 13. Qxf3 Bg6 14. g5 hxg5 15. Bxg5 Qd7 16. Kh2 Nxe4 17. dxe4 Bxe4 18. Qg3 f6 19. Be3 Qc6 20. Bb3 Bf5 21. h4 g5 22. Bxg5 fxg5 23. Qxg5 Qf3 24. Qg3 Qe4 25. Rae1 Rxh4+ 26. Qxh4 Qxh4+ 0-1",
    B: "1601",
  },
  {
    W: "1641",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d3 d5 6. exd5 Nxd5 7. h3 Be6 8. O-O Qf6 9. Bb3 O-O-O 10. Bg5 Qg6 11. Bxd8 Bxh3 12. g3 Bxf1 13. Qxf1 Nf4 14. Bh4 Qh5 15. Bd1 f6 16. gxf4 exf4 17. Qh3+ Kb8 18. Na3 Qg6+ 19. Qg2 Qxd3 20. Bc2 Qd6 21. Re1 Ne5 22. Nxe5 fxe5 23. Qxg7 Re8 24. Rd1 Qg6+ 25. Qxg6 hxg6 26. Bxg6 Rg8 27. Kh2 a6 28. Rg1 Ka7 29. Nc4 e4 30. b4 Bd6 31. Nxd6 cxd6 32. Bxe4 Rh8 33. Kh3 Kb6 34. Rg4 1-0",
    B: "1620",
  },
  {
    W: "1541",
    T: "600+0",
    P: "1. d4 c5 2. d5 d6 3. c4 e6 4. e4 Nf6 5. Bd3 Bd7 6. Nc3 a6 7. Bd2 b5 8. b3 Be7 9. Nf3 exd5 10. exd5 O-O 11. O-O Ng4 12. cxb5 axb5 13. Nxb5 Bxb5 14. Bxb5 Nf6 15. Bc3 Qb6 16. Bc4 Ne4 17. Bb2 Nd7 18. Re1 Ndf6 19. Qc2 Qd8 20. Rxe4 Nxe4 21. Qxe4 f5 22. Qe6+ Kh8 23. Qe3 Bf6 24. Bxf6 Qxf6 25. Re1 h6 26. h3 Qb2 27. Re2 Qb1+ 28. Kh2 f4 29. Qe6 Rxa2 30. Qxd6 Rxe2 31. Qxf8+ Kh7 32. Bxe2 Qxb3 33. Ne5 Qxd5 34. Bd3+ g6 35. Bxg6# 1-0",
    B: "1572",
  },
  {
    W: "1428",
    T: "600+0",
    P: "1. d4 d5 2. c4 dxc4 3. e4 h6 4. Bxc4 e6 5. d5 exd5 6. Bxd5 c6 7. Bb3 Qxd1+ 8. Bxd1 Nf6 9. Nc3 Bb4 10. a3 Bxc3+ 11. bxc3 Nxe4 12. c4 Be6 13. Bf3 Nc3 14. Bb2 Na4 15. Bxg7 Rg8 16. Bxh6 Bxc4 17. Kd2 Na6 18. Re1+ Kd7 19. Bg4+ Kd6 20. Bf4+ Kc5 21. Re5+ Kb6 22. Be3+ N6c5 23. h3 Rad8+ 24. Kc2 Bd3+ 25. Kc1 Kb5 26. Be2 Bxe2 27. Nxe2 Rd5 28. Rxd5 cxd5 29. Kc2 Rxg2 30. Rb1+ Kc6 31. Nd4+ Kd7 32. Nb5 a6 33. Nc3 Nxc3 34. Kxc3 Ne4+ 35. Kd4 Nxf2 36. Rb2 Rg4+ 37. Kxd5 1-0",
    B: "1294",
  },
  {
    W: "1644",
    T: "600+0",
    P: "1. e4 d5 2. exd5 Nf6 3. Nc3 Nxd5 4. Nf3 Bf5 5. Bc4 e6 6. Qe2 c6 7. Bxd5 cxd5 8. b3 Nc6 9. Bb2 Bd6 10. O-O Qc7 11. Nb5 Qb8 12. Bxg7 Rg8 13. Nxd6+ Qxd6 14. Be5 Nxe5 15. Qxe5 Qxe5 16. Nxe5 Bh3 17. f4 Bxg2 18. Rfe1 Be4+ 19. Kf2 Bxc2 20. Rac1 f6 21. Nf3 Bg6 22. Rxe6+ Kf7 23. Rce1 Rge8 24. Rxe8 Rxe8 25. Rxe8 Kxe8 26. a4 Bc2 27. Nd4 Bd1 28. a5 b6 29. axb6 axb6 30. b4 Kd7 31. h4 Kd6 32. Ke3 Bg4 33. Nb5+ Kc6 34. Nd4+ Kd6 35. Nb5+ Ke6 36. Nc7+ Kd7 37. Nxd5 Kc6 38. Nxf6 Bf5 39. d4 Kb5 40. Nd5 Be6 41. Nc7+ Kxb4 42. Nxe6 Kc3 43. f5 b5 44. f6 b4 45. f7 b3 46. f8=Q b2 47. Qc8+ Kb3 48. Nc5+ Ka2 49. Qa6+ Kb1 50. Nd3 Kc2 51. Nxb2 Kxb2 1-0",
    B: "1679",
  },
  {
    W: "1477",
    T: "600+0",
    P: "1. e4 d6 2. d4 c6 3. Nf3 Nf6 4. Nc3 Bg4 5. Bc4 Bxf3 6. Qxf3 e6 7. e5 d5 8. Bb3 Ne4 9. Nxe4 dxe4 10. Qxe4 Nd7 11. Qg4 Bb4+ 12. c3 Bf8 13. O-O Nb6 14. Bg5 Be7 15. Be3 g6 16. h3 h5 17. Qe2 Nd5 18. Bd2 f5 19. c4 Nb6 20. c5 Nd5 21. a4 g5 22. g3 g4 23. h4 Bxh4 24. Kg2 Bg5 25. Rh1 Bxd2 26. Qxd2 Qe7 27. Rh4 Kd7 28. Rah1 Qf7 29. Qg5 Rag8 30. Qd2 f4 31. gxf4 Nxf4+ 32. Kg3 Nd5 33. Bc2 Qf3+ 34. Kh2 Rf8 35. Rg1 Qxf2+ 36. Qxf2 Rxf2+ 37. Rg2 Rxg2+ 38. Kxg2 Ne3+ 39. Kg3 Nxc2 40. Rh2 h4+ 41. Kxg4 Ne3+ 42. Kf3 Nf5 43. Ke4 Ng3+ 44. Kf3 Rf8+ 45. Kg4 Nf5 46. Rf2 Rg8+ 0-1",
    B: "1453",
  },
  {
    W: "1619",
    T: "600+0",
    P: "1. c4 e5 2. e4 Nf6 3. Nc3 Bc5 4. d3 c6 5. Be3 Na6 6. Nf3 Qb6 7. Nxe5 Bxe3 8. fxe3 Qxe3+ 9. Be2 d6 10. Nf3 Ng4 11. Qd2 Qf2+ 12. Kd1 Nb4 13. Rc1 Ne3+ 14. Qxe3 Qxe3 15. Rf1 Nxd3 16. Bxd3 Qxd3+ 17. Nd2 Bg4+ 18. Ke1 O-O 19. h3 Bh5 20. g4 Qg3+ 21. Kd1 Bxg4+ 22. hxg4 Qxg4+ 23. Kc2 Rac8 24. Kb1 d5 25. cxd5 cxd5 26. Nxd5 Rxc1+ 27. Rxc1 Qe2 28. Nb3 Qxe4+ 0-1",
    B: "1614",
  },
  {
    W: "1573",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. c3 dxc3 5. Nxc3 Nf6 6. Bc4 e6 7. O-O a6 8. a4 Bd7 9. Bg5 Be7 10. Re1 h6 11. Bh4 Ng8 12. Bxe7 Nxe7 13. Rc1 O-O 14. h4 Nbc6 15. h5 Na5 16. Bd3 Rc8 17. b4 Nac6 18. g4 Nxb4 19. Bb1 d5 20. exd5 Nexd5 21. Nxd5 Rxc1 22. Qxc1 Nxd5 23. Qa3 Qc7 24. g5 hxg5 25. Nxg5 Qf4 26. Nh3 Qxa4 27. Qd3 Qg4+ 28. Kh2 f5 29. Rg1 Nf4 30. Nxf4 Qxf4+ 31. Rg3 Qxf2+ 32. Kh3 Bc6 33. h6 Be4 34. Rxg7+ Kh8 35. Qd4 Qf3+ 36. Kh2 Qh1+ 37. Kg3 f4+ 38. Kf2 Qf3+ 39. Kg1 Qe3+ 40. Qxe3 fxe3 41. Bxe4 Rg8 42. Rxg8+ Kxg8 43. Kf1 b6 44. Ke2 e5 45. Kxe3 a5 46. h7+ Kg7 47. Kd3 b5 48. Kc3 Kh8 49. Kb3 Kg7 50. Kc3 Kh8 51. Kb3 Kg7 52. Kc3 1/2-1/2",
    B: "1591",
  },
  {
    W: "1488",
    T: "600+0",
    P: "1. d4 g6 2. Bf4 Bg7 3. Nf3 d6 4. e3 Nf6 5. Nbd2 Nc6 6. c3 Bd7 7. Bd3 O-O 8. Qb3 b6 9. O-O-O Rb8 10. Bg5 a5 11. e4 Qc8 12. e5 dxe5 13. dxe5 Be6 14. Bc4 Bxc4 15. Nxc4 e6 16. exf6 b5 17. fxg7 Kxg7 18. Nce5 Nxe5 19. Nxe5 f6 20. Nd7 Re8 21. Bxf6+ Kh6 22. Nxb8 Qxb8 23. Rd3 Kh5 24. Rh3+ Kg4 25. Qd1+ Kf5 26. Rf3+ Ke4 27. Qd4# 1-0",
    B: "1489",
  },
  {
    W: "1598",
    T: "600+0",
    P: "1. c4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Bg5 e6 6. e3 Be7 7. Be2 O-O 8. O-O h6 9. Bf4 Nh5 10. Bg3 Nxg3 11. fxg3 d5 12. cxd5 exd5 13. Nc3 Be6 14. Nxe6 fxe6 15. Rc1 Bf6 16. Nb5 Bxb2 17. Rc2 Bf6 18. Bg4 Qe7 19. a4 a6 20. Nd4 Nxd4 21. exd4 Rac8 22. Re1 Rxc2 23. Qxc2 Bxd4+ 24. Kh1 Qf6 25. Bxe6+ Qxe6 26. Qd3 Qxe1+ 0-1",
    B: "1599",
  },
  {
    W: "1495",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Bg4 4. h3 Bxf3 5. Qxf3 Qf6 6. Qb3 b6 7. Nc3 c6 8. d3 Ne7 9. Be3 Ng6 10. a3 Nd7 11. Qa2 Nf4 12. Bxf4 Qxf4 13. Ne2 Qf6 14. Ng3 g6 15. Ba6 Rb8 16. Qc4 c5 17. Qd5 Qe6 18. Qc6 Be7 19. Bb5 Rd8 20. Bc4 Qf6 21. O-O-O a5 22. h4 O-O 23. f4 h5 24. f5 Kg7 25. fxg6 fxg6 26. Rhf1 Qxh4 27. Rxf8 Bxf8 28. Ne2 Qe7 29. Rf1 Nf6 30. Nc3 Qd7 31. Qxd7+ Nxd7 32. Rf7+ Kh6 33. Be6 Nb8 34. Nd5 Nc6 35. Nxb6 Nd4 36. Bd5 Ne2+ 37. Kb1 Nf4 38. g3 Nxd5 39. Nxd5 Bg7 40. Ra7 Rf8 41. Rxa5 Rf1+ 42. Ka2 Rf2 43. c3 Rf3 44. Ra6 Bf8 45. Nc7 Rxd3 46. Nb5 Kg5 47. Ra8 Be7 48. Re8 Bf6 49. Re6 d5 50. exd5 Rxd5 51. Nc7 Rd3 52. a4 Rxg3 53. a5 Rd3 54. a6 Rd8 55. a7 h4 56. Re8 Rxe8 57. Nxe8 h3 58. a8=Q Kf5 59. Nxf6 Kxf6 60. Qh1 g5 61. Qxh3 e4 62. Qd7 Ke5 63. Qe7+ Kf4 64. Qxc5 e3 65. Qe7 Kf3 0-1",
    B: "1495",
  },
  {
    W: "1612",
    T: "600+0",
    P: "1. e4 e5 2. g3 Bc5 3. Bg2 d6 4. Nc3 Nf6 5. Nge2 Ng4 6. O-O Nxf2 7. Rxf2 Bxf2+ 8. Kxf2 f5 9. exf5 Nc6 10. Bxc6+ bxc6 11. d4 Bxf5 12. dxe5 O-O 13. exd6 Bxc2+ 14. Bf4 Bxd1 15. Rxd1 cxd6 16. Rxd6 Qxd6 17. Ne4 Qb4 18. Kg2 Rfe8 19. N2c3 Qxb2+ 20. Kh3 Rad8 21. Nd6 Re6 22. Nce4 Qxa2 23. Nc5 Rdxd6 24. Bxd6 Rh6+ 25. Kg4 Rxd6 26. Ne4 Qe6+ 27. Kf3 Rd3+ 28. Kf4 Qf7+ 29. Kg4 h5+ 30. Kg5 Kh7 31. g4 Qg6+ 32. Kf4 Qxg4+ 33. Ke5 Rd5# 0-1",
    B: "1611",
  },
  {
    W: "1535",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nc3 Nc6 4. Bb5 Bb4 5. d3 O-O 6. O-O d6 7. Bd2 Bg4 8. h3 Bh5 9. g4 Bg6 10. Ne2 Bc5 11. Ng3 h5 12. Ng5 Nd4 13. Bc4 b5 14. Bb3 hxg4 15. hxg4 Qd7 16. Nf5 Nxb3 17. cxb3 Bh7 18. Kg2 g6 19. Nh6+ Kg7 20. Rh1 d5 21. f3 Rad8 22. Bc3 dxe4 23. Bxe5 exf3+ 24. Qxf3 Be7 25. Qh3 Qd5+ 26. Ne4 Qxe5 27. Nf5+ gxf5 28. Qh6+ Kg8 29. Ng5 Qd5+ 30. Nf3 fxg4 31. Qg5+ Kh8 32. Qxd5 Nxd5 33. Ne5 Bf6 34. Nxg4 Rg8 35. Kg3 Ne3 36. Kf3 1-0",
    B: "1533",
  },
  {
    W: "1516",
    T: "600+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 Nf6 5. Nf3 c6 6. Bd2 e6 7. Ne4 Qd8 8. Bd3 Nxe4 9. Bxe4 Bd6 10. c4 b6 11. O-O O-O 12. b4 c5 13. Bxa8 cxd4 14. Nxd4 Be5 15. Bc3 Ba6 16. b5 Bc8 17. Rc1 a5 18. Nf3 Bxc3 19. Qxd8 Rxd8 20. Rxc3 Bd7 21. Be4 f5 22. Bc2 Be8 23. Rd3 Rxd3 24. Bxd3 Nd7 25. Re1 Nc5 26. Bc2 Bh5 27. Ne5 g6 28. Bd1 Bxd1 29. Rxd1 Ne4 30. Nd7 Nc3 31. Ra1 Kg7 32. Nxb6 h5 33. Nd7 Ne2+ 34. Kf1 Nf4 35. b6 Nd3 36. b7 1-0",
    B: "1500",
  },
  {
    W: "1184",
    T: "600+0",
    P: "1. e4 e5 2. Qf3 Qf6 3. Bc4 c5 4. a3 Nc6 5. c3 a6 6. a4 Qxf3 7. Nxf3 Nf6 8. d3 h6 9. Bd2 Na5 10. b4 Nxc4 11. dxc4 Nxe4 12. bxc5 Bxc5 13. Nxe5 f6 14. Ng6 Rg8 15. O-O Bxf2+ 16. Kh1 Bc5 17. Rf4 Nxd2 18. Nxd2 Bd6 19. Re4+ Kf7 20. Nh4 Be5 21. Rc1 d6 22. Ndf3 g5 23. Nxe5+ fxe5 24. Nf3 b5 25. axb5 axb5 26. cxb5 Bb7 27. Rg4 Ra5 28. b6 Rb5 29. h4 Rxb6 30. hxg5 hxg5 31. Nxg5+ Kf6 32. Ne4+ Kf7 33. Ng5+ Kf6 34. Nh7+ Kf7 35. Ng5+ 1/2-1/2",
    B: "1213",
  },
  {
    W: "1732",
    T: "300+0",
    P: "1. e4 c5 2. c3 Nc6 3. d4 cxd4 4. cxd4 d6 5. Nc3 e5 6. d5 Nb8 7. Bb5+ Bd7 8. Bxd7+ Nxd7 9. f4 Ngf6 10. Nf3 exf4 11. Bxf4 Be7 12. O-O O-O 13. Be3 b6 14. Rc1 Ne5 15. Nxe5 dxe5 16. Nb5 Bd6 17. Bg5 Be7 18. d6 Nxe4 19. Bxe7 Qd7 20. Nc7 Rac8 21. Bxf8 Rxf8 22. Qd5 Nxd6 23. Rfd1 Rd8 24. Nb5 Qg4 25. Nxd6 Qf4 26. Rc8 Rxc8 27. Nxc8 g6 28. Nxa7 e4 29. Qd8+ Kg7 30. Qd4+ f6 31. Nc6 g5 32. Qd7+ Kg6 33. Ne7+ Kh5 34. Qh3+ Qh4 35. Qxh4+ Kxh4 36. g3+ Kg4 37. Kg2 h5 38. h3# 1-0",
    B: "1737",
  },
  {
    W: "1717",
    T: "300+0",
    P: "1. e4 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qd1 Nf6 5. Bd3 d6 6. Nf3 a6 7. h3 b5 8. a3 Bb7 9. Nc3 e5 10. Bg5 Be7 11. O-O O-O 12. Re1 Nd7 13. Be3 Nf6 14. Qd2 Nd7 15. b4 a5 16. Nd5 Bf6 17. Nxf6+ Nxf6 18. Bg5 axb4 19. axb4 Rxa1 20. Rxa1 Qb6 21. Bxf6 gxf6 22. Nh4 Ne7 23. Qh6 Ng6 24. Nf5 1-0",
    B: "1717",
  },
  {
    W: "1456",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 d5 4. exd5 Qxd5 5. Nc3 Bb4 6. Be3 Bg4 7. a3 Bxc3+ 8. bxc3 exd4 9. cxd4 Nf6 10. Be2 O-O 11. h3 Bh5 12. Qd3 Rfe8 13. c4 Qd7 14. d5 Ne7 15. Ne5 Bxe2 16. Qxe2 h6 17. Nxd7 1-0",
    B: "1489",
  },
  {
    W: "1862",
    T: "300+0",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. Bg5 O-O 5. e3 d6 6. Nf3 Nbd7 7. Be2 h6 8. Bh4 g5 9. Bg3 Nh5 10. Ne4 f5 11. Nexg5 Ndf6 12. Nh3 e5 13. dxe5 dxe5 14. Qxd8 Rxd8 15. Bxe5 Be6 16. Bxc7 Rd7 17. Be5 Rc8 18. Bxf6 Bxf6 19. Nd4 Ng7 20. Nxe6 Nxe6 21. O-O Rd2 22. Bf3 Rxb2 23. Bd5 Kf7 24. Nf4 Re8 25. Bxe6+ Rxe6 26. Nxe6 Kxe6 27. c5 Kd5 28. Rad1+ Kc4 29. Rc1+ Kb5 30. Rb1 Kxc5 31. Rxb2 Bxb2 32. Rb1 Be5 33. Rxb7 a5 34. Rh7 Kb4 35. Rxh6 Ka3 36. Rh5 a4 37. Rxf5 Bc3 38. Rc5 Bb4 39. Rc2 Bd6 40. h4 1-0",
    B: "1919",
  },
  {
    W: "1977",
    T: "300+0",
    P: "1. e4 d6 2. d4 c6 3. c4 g6 4. Nc3 Bg7 5. Nf3 Bg4 6. Be3 Qb6 7. h3 Bxf3 8. Qxf3 Qxb2 9. Rc1 Bxd4 10. Nd1 Qa3 11. Bxd4 Qxc1 12. Bxh8 Nd7 13. Bb2 Qb1 14. Bd3 Qxa2 15. O-O Ngf6 16. Bd4 c5 17. Nc3 Qb3 18. Bxf6 Nxf6 19. Nb5 Kd7 20. e5 1-0",
    B: "1980",
  },
  {
    W: "1444",
    T: "300+0",
    P: "1. d4 g6 2. Nf3 b6 3. Bf4 d6 4. e3 e6 5. Bd3 Bb7 6. Nbd2 Bg7 7. c4 Nf6 8. O-O Nc6 9. a3 O-O 10. b4 Ne7 11. a4 c6 12. a5 e5 13. dxe5 dxe5 14. Nxe5 Nd7 15. Nxd7 Qxd7 16. Ra3 bxa5 17. Ra4 Qxd3 18. Rxa5 Bc3 19. Ra3 Qxd2 20. Qf3 Bxb4 21. Rb3 Rab8 22. Bh6 Nf5 23. Rd1 Qc2 24. Rdb1 Nxh6 25. Rxb4 a5 26. Rxb7 Rxb7 27. Rxb7 Qxc4 28. h3 a4 29. Ra7 Qc1+ 30. Kh2 a3 31. e4 Qb2 32. Rxa3 Rb8 33. Ra7 Qe5+ 34. g3 f5 35. Qe3 fxe4 36. Qxh6 Re8 37. Qxh7+ Kf8 38. Qf7# 1-0",
    B: "1421",
  },
  {
    W: "1456",
    T: "300+0",
    P: "1. d4 d5 2. c4 dxc4 3. e3 Nf6 4. Bxc4 g6 5. Nf3 Bg7 6. Bd2 c6 7. a3 Bf5 8. Bb4 O-O 9. Bd3 Be6 10. Qc2 Na6 11. Bxa6 bxa6 12. O-O Bf5 13. Qxc6 Bd3 14. Rd1 Bb5 15. Qc5 Re8 16. Nc3 Bd7 17. Qc4 Bg4 18. Qxa6 Qc7 19. Nb5 Qc2 20. Rab1 Ne4 21. Rf1 Bxf3 22. gxf3 Ng5 23. Qa5 Nxf3+ 24. Kg2 Nh4+ 25. Kg3 Nf5+ 26. Kg2 Nxe3+ 27. Kf3 Nxf1 28. Rxf1 Qd3+ 29. Kg2 Qe4+ 30. f3 Qe2+ 31. Rf2 Qe6 32. Nc7 Qf6 33. Nxe8 Rxe8 34. Qxa7 Qh4 35. Bc3 e6 36. Qd7 Rd8 37. Qa7 e5 38. dxe5 Bh6 39. a4 Bf4 40. Kf1 Bxh2 41. Ke2 Bf4 42. a5 Qh1 43. a6 Qc1 44. Qb7 Qc2+ 45. Kf1 Rd1+ 46. Kg2 1-0",
    B: "1451",
  },
  {
    W: "1711",
    T: "300+0",
    P: "1. d4 Nf6 2. Bf4 g6 3. Nf3 Bg7 4. e3 Nh5 5. Be5 Bxe5 6. Nxe5 d6 7. Nf3 Bg4 8. Be2 Nf6 9. Nbd2 c5 10. c3 Nc6 11. O-O Qb6 12. b3 a5 13. Rb1 O-O 14. c4 Qc7 15. d5 Bxf3 16. Bxf3 Ne5 17. Be2 Rab8 18. Nf3 Nxf3+ 19. Bxf3 b5 20. cxb5 Rxb5 21. Kh1 Rfb8 22. Be2 Rb4 23. f3 Nxd5 24. Qxd5 e6 25. Qd1 Qd7 26. e4 d5 27. exd5 exd5 28. Bc4 Rd8 29. Be2 Rd4 30. Qc2 Qc7 31. Rfd1 Rb4 32. a4 Rh4 33. h3 Rb4 34. Bb5 c4 35. bxc4 Rxb1 36. Rxb1 dxc4 37. Qxc4 Qe5 38. Rc1 Qe3 39. Qc3 Qf4 40. Qc7 Rd1+ 41. Rxd1 Qxc7 42. Rd7 Qc1+ 43. Kh2 Qf4+ 44. Kg1 h5 45. Kf2 Kg7 46. Kg1 g5 47. Kf2 g4 48. hxg4 hxg4 49. Ke2 g3 50. Rd3 Qe5+ 51. Re3 Qb2+ 52. Kd3 Qxg2 53. Ke4 Qc2+ 54. Bd3 Qxa4+ 55. Kf5 Qb4 56. Re4 g2 57. Rg4+ Kf8 58. Rg8+ Kxg8 59. Be4 g1=Q 60. Kf4 1-0",
    B: "1704",
  },
  {
    W: "1806",
    T: "300+0",
    P: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. Nf3 Nf6 7. Bd3 e6 8. c3 Nbd7 9. Bg5 Be7 10. Bxg6 hxg6 11. Qa4 O-O 12. h4 Nh5 13. O-O-O Nb6 14. Qc2 Nd5 15. Ne4 Qa5 16. Kb1 Rac8 17. c4 Bxg5 18. hxg5 Ne7 19. Qd2 Nf5 20. g4 Qxd2 21. Rxd2 Nxd4 22. Rxd4 Nf4 23. Nc3 e5 24. Nxe5 Ne6 25. Rd7 Rc7 26. Rhd1 b6 27. Rxc7 Nxc7 28. Nxc6 Ne6 29. Ne4 Re8 30. f3 Nf4 31. Rd7 f5 32. gxf6 gxf6 33. Nxf6+ 1-0",
    B: "1840",
  },
  {
    W: "1694",
    T: "300+0",
    P: "1. f4 f5 2. Nf3 Nf6 3. d3 d5 4. e3 e6 5. Be2 Bd6 6. Ne5 Nbd7 7. Bh5+ g6 8. Nxg6 hxg6 9. Bxg6+ Ke7 10. O-O Qg8 11. e4 Bc5+ 12. d4 Nxe4 13. dxc5 Qxg6 14. Nc3 Ndxc5 15. Be3 Nxc3 16. Bxc5+ Kf7 17. bxc3 b6 18. Bd4 Bb7 19. Bxh8 d4 20. Rf2 Rxh8 21. Qxd4 Qh5 22. h3 Rg8 23. Qd7+ Kf6 24. Qd4+ Ke7 25. Qe3 Rd8 26. Re1 Qh6 27. Qe5 Rd6 28. Rfe2 Qg6 29. c4 c5 30. a4 a5 31. Qc3 Be4 32. Qb3 Qh5 33. Qg3 Qh6 34. c3 Rd3 35. Qh2 Qg6 36. g4 fxg4 37. h4 g3 38. Qh3 Bf5 39. Qf1 g2 40. Rxg2 Rg3 41. Re2 Rxg2+ 42. Rxg2 Qh6 43. Qe1 Qxf4 44. Qf2 Kf6 45. Qxf4 e5 46. Qh6+ Ke7 47. Qg5+ 1-0",
    B: "1702",
  },
  {
    W: "1750",
    T: "300+0",
    P: "1. d4 f5 2. Bf4 Nf6 3. e3 d6 4. h3 g6 5. Nd2 Bg7 6. Be2 Nbd7 7. g4 fxg4 8. hxg4 e5 9. dxe5 dxe5 10. Bg3 h6 11. c3 Qe7 12. Qb3 Nc5 13. Qc2 e4 14. O-O-O Bxg4 15. Bxg4 Nxg4 16. Nc4 Nd3+ 17. Rxd3 exd3 18. Qxd3 Nf6 19. Nf3 Nd7 20. Qxg6+ Kd8 21. Rd1 Kc8 22. Nfe5 Nxe5 23. Nxe5 Bxe5 24. Qf5+ Kb8 25. Bxe5 Re8 26. f4 a6 27. Qg6 Qe6 28. Qh5 Ka7 29. Bxc7 Rac8 30. Be5 Qxa2 31. Qxh6 Qa1+ 32. Kc2 Qa4+ 33. Kb1 Qxd1+ 34. Ka2 Red8 35. Bd4+ Ka8 36. Qb6 Qa4+ 37. Kb1 Kb8 38. Qa7+ Kc7 39. Be5+ Kc6 40. Bd4 Qb5 41. f5 Rf8 42. f6 Rh8 43. f7 Rh1+ 44. Kc2 Qa4+ 45. Kd3 Rd1+ 46. Ke4 Qc2+ 47. Ke5 Qh2+ 48. Ke4 Qh7+ 49. Kf4 Qxf7+ 50. Kg5 Rg1+ 51. Kh6 Qg6# 0-1",
    B: "1780",
  },
  {
    W: "1986",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Ndb5 d6 7. Bd3 a6 8. Na3 b5 9. Nd5 Nxd5 10. exd5 Ne7 11. O-O Bb7 12. c4 bxc4 13. Bxc4 Ng6 14. b4 Be7 15. b5 O-O 16. bxa6 Bxa6 17. Be3 Bc8 18. Nc2 f5 19. a4 Bf6 20. a5 Rxa5 21. Rxa5 Qxa5 22. Bd2 Qd8 23. Qh5 Nf4 24. Bxf4 exf4 25. Re1 Be5 26. h3 Qf6 27. Qd1 Bd7 28. Nb4 Kh8 29. Nd3 Bc3 30. Re2 g5 31. Ra2 g4 32. hxg4 fxg4 33. Ra6 f3 34. g3 Qh6 35. Qf1 Rf5 36. Nf4 Be5 37. Bd3 Rxf4 38. Ra8+ Kg7 39. Qb1 Qh3 40. Bf1 Qh5 41. Qb8 Rf7 42. Qh8+ Kh6 43. Qd8 Bxg3 44. fxg3 f2+ 45. Kg2 Qh3# 0-1",
    B: "1992",
  },
  {
    W: "1290",
    T: "300+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. Nf3 e6 5. Bd3 Be7 6. O-O Bf6 7. a3 Ne7 8. Ne4 Ng6 9. Nxf6+ Qxf6 10. c3 O-O 11. Bxg6 fxg6 12. d4 Nd7 13. Bg5 Qf7 14. Re1 Nf6 15. Ne5 Qe8 16. h3 Qb5 17. Rb1 b6 18. Bxf6 Rxf6 19. Nd3 Bb7 20. Rf1 Be4 0-1",
    B: "1391",
  },
  {
    W: "1081",
    T: "300+0",
    P: "1. e4 e5 2. d4 exd4 3. e5 f6 4. Nf3 fxe5 5. Nxe5 Qe7 6. Qxd4 Nf6 7. Bg5 c5 8. Qc4 d6 9. Bxf6 gxf6 10. Qb5+ Nc6 11. Be2 Qxe5 12. Nc3 Bg4 13. f3 Bc8 14. g3 Be7 15. f4 Qe3 16. Rf1 a6 17. Qd3 Qxd3 18. Bxd3 Nd4 19. Rc1 Bf5 20. h4 Bxd3 21. cxd3 O-O-O 22. Nd5 Rde8 23. Nb6+ Kc7 24. Nd5+ Kb8 25. Kd2 Bd8 26. Nxf6 Bxf6 27. Rce1 Rxe1 28. Rxe1 Nf3+ 29. Kd1 Nxe1 30. Kxe1 Re8+ 31. Kf2 Rc8 32. Kf3 c4 33. dxc4 Rxc4 34. g4 Bxh4 35. Ke3 Rc2 36. f5 Rxb2 37. Kf4 Rxa2 38. g5 Ra4+ 39. Ke3 Bxg5+ 40. Kd3 Bf6 0-1",
    B: "1129",
  },
  {
    W: "1623",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. Bxc6 dxc6 6. Re1 Nd6 7. Nxe5 Be7 8. d4 O-O 9. c4 Bf6 10. Bf4 Bxe5 11. Bxe5 Nxc4 12. b3 Nxe5 13. Rxe5 Be6 14. Nc3 Qd7 15. Ne4 Rad8 16. Nc5 Qxd4 17. Qxd4 Rxd4 18. Nxb7 Rb8 19. Nc5 Rbd8 20. g3 R8d5 21. Rxd5 cxd5 22. Nxe6 fxe6 23. Rc1 Rd2 24. Rxc7 Rxa2 25. b4 d4 26. Rd7 e5 27. Rd5 Rb2 28. b5 Rb1+ 29. Kg2 Rd1 30. Kf3 d3 31. Ke3 d2 32. Rxd2 Rxd2 33. Kxd2 Kf7 34. Kc3 Ke7 35. Kc4 Kd7 36. Kc5 Kc7 37. f3 g6 38. h3 h6 39. Kd5 Kb6 40. Kc4 g5 41. Kb4 h5 42. Kc4 Ka5 43. Kc5 g4 44. hxg4 hxg4 45. fxg4 e4 46. Kd4 Kxb5 47. Kxe4 a5 48. g5 a4 49. g6 a3 50. g7 a2 51. g8=Q a1=Q 52. Qb8+ Kc4 53. Qc7+ Kb3 54. Qb6+ Kc2 55. Qc5+ Kb3 56. Qb5+ Kc2 57. Qe2+ Kb3 58. Qd3+ Kb4 59. Qd4+ Qxd4+ 60. Kxd4 Kb5 61. Ke5 Kc6 62. Ke6 Kc7 63. g4 Kd8 64. Kf7 Kd7 65. g5 1-0",
    B: "1614",
  },
  {
    W: "1590",
    T: "300+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. Be2 Nf6 5. O-O Bd6 6. d4 O-O 7. Ne5 Re8 8. f4 Ne4 9. Bf3 f6 10. Ng4 Bxg4 11. Bxg4 c6 12. Nd2 Nd7 13. Nxe4 Rxe4 14. Bf5 Re7 15. Bd2 Qc7 16. Qg4 g6 17. Rae1 Rae8 18. Bxd7 f5 19. Bxf5 Rxe1 20. Rxe1 Rxe1+ 21. Bxe1 Bxf4 22. Be6+ Kg7 23. g3 Be3+ 24. Kg2 Qe7 25. Bf2 h5 26. Qh3 Bc1 27. g4 Qxe6 28. gxh5 Qxh3+ 29. Kxh3 gxh5 30. Kh4 Kg6 31. b3 Bb2 32. Kg3 b6 33. Kf4 Kf6 34. h4 Bc3 35. a4 Bb4 36. Ke3 Kf5 37. Kd3 Bd6 38. c4 Kg4 39. cxd5 cxd5 40. Be1 Bg3 41. Bxg3 Kxg3 42. Ke3 Kxh4 43. Kf4 Kh3 44. Ke5 h4 45. Kxd5 Kg3 46. Ke4 h3 47. d5 h2 48. d6 h1=Q+ 49. Ke5 Qd1 50. Ke6 Qe1+ 51. Kd7 Qe5 52. Kc7 Qg7+ 53. d7 Qf8 54. d8=Q Qxd8+ 55. Kxd8 Kf3 56. Kc7 Ke4 57. Kb7 Kd5 58. Kxa7 Kc5 59. Ka6 Kb4 60. Kxb6 Kxb3 61. a5 Ka4 62. a6 Kb4 63. a7 Kc4 64. a8=Q Kd4 65. Qe8 Kc3 66. Kc5 Kc2 67. Qe4+ Kd2 68. Kc4 1-0",
    B: "1540",
  },
  {
    W: "1611",
    T: "300+0",
    P: "1. e4 g6 2. c3 Bg7 3. d4 d6 4. f4 Nf6 5. Bd3 O-O 6. Nf3 Bg4 7. O-O Bxf3 8. Rxf3 e5 9. fxe5 dxe5 10. d5 c6 11. c4 a6 12. a4 Nbd7 13. Be3 Ng4 14. Qe2 Nxe3 15. Qxe3 cxd5 16. cxd5 Qa5 17. Nc3 b5 18. b3 Qxc3 19. Rc1 Qxb3 20. axb5 axb5 21. h3 Ra2 22. Rcf1 Qb2 23. Qg5 Nc5 24. R3f2 Qd4 25. Bxb5 Nxe4 26. Qh4 Nxf2 27. Qxd4 exd4 28. Rxf2 Rxf2 29. Kxf2 Rd8 30. Bc6 Kf8 31. Ke2 Ke7 32. Kd3 Kd6 33. g4 Rc8 34. Bb7 Rc3+ 35. Ke4 Rxh3 0-1",
    B: "1612",
  },
  {
    W: "1522",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. f3 e5 7. Nb3 Be6 8. Be3 Be7 9. Qd2 Nbd7 10. O-O-O Rc8 11. g4 O-O 12. h4 Nb6 13. h5 Nc4 14. Bxc4 Bxc4 15. g5 Ne8 16. f4 exf4 17. Bxf4 Bxb3 18. cxb3 b5 19. Kb1 Qa5 20. g6 fxg6 21. Bh6 gxh6 22. Qxh6 Nf6 23. hxg6 Rf7 24. gxf7+ Kxf7 25. Rhg1 Rg8 26. Nd5 Rxg1 27. Rxg1 Bf8 28. Qxf6+ Ke8 29. Rc1 Qd8 30. Qe6+ Be7 31. Qg8+ Kd7 32. Qxd8+ Bxd8 33. Rh1 1-0",
    B: "1537",
  },
  {
    W: "1774",
    T: "180+7",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Qb6 5. Nf3 Bd7 6. Bd3 Bb5 7. Bxb5+ Qxb5 8. dxc5 Bxc5 9. b4 Bb6 10. Na3 Qd7 11. O-O Ne7 12. Qd3 a6 13. b5 O-O 14. bxa6 bxa6 15. Rb1 Qc7 16. Ng5 Ng6 17. h4 h5 18. Qf3 Rc8 19. Qxh5 Nxe5 20. Qh7+ Kf8 21. Qh8+ Ke7 22. Qxg7 Nbd7 23. Bf4 Rg8 24. Bxe5 Rxg7 25. Bxc7 Bxc7 26. Rb7 Bd6 27. Nc2 Rc8 28. Ne3 Rxc3 29. g3 Rc7 30. Rb2 Ne5 31. f4 Nc4 32. Nxc4 dxc4 33. Rc2 f6 34. Ne4 e5 35. Nxd6 Kxd6 36. Rd1+ Ke6 37. fxe5 fxe5 38. Kh2 Kf5 39. Re1 Rgd7 40. Kh3 Rd3 41. Rf1+ Ke4 42. Rf6 c3 43. Rxa6 Rd2 44. Rc1 c2 45. Rf6 Rd1 46. Rf1 Rxf1 47. Rxf1 c1=Q 0-1",
    B: "1763",
  },
  {
    W: "1896",
    T: "120+1",
    P: "1. e4 f6 2. d4 g6 3. f4 Nh6 4. Nf3 Nf7 5. Bd3 Bg7 6. O-O O-O 7. e5 e6 8. Qe2 Nc6 9. c3 d6 10. Be3 fxe5 11. dxe5 dxe5 12. fxe5 Nfxe5 13. Nxe5 Rxf1+ 14. Qxf1 Nxe5 15. Bc2 Ng4 16. Bf4 e5 17. Bg3 Qf6 18. Qe2 Bf5 19. Bb3+ Kh8 20. Nd2 Rd8 21. h3 Nh6 22. Ne4 Bxe4 23. Qxe4 Qb6+ 24. Kh1 Nf5 25. Be1 Nd6 26. Qe2 Rf8 27. Rd1 c6 28. Bf2 Qa5 29. Rxd6 1-0",
    B: "1884",
  },
  {
    W: "1215",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. a3 h6 5. Bc4 Bc5 6. d3 d6 7. O-O O-O 8. Re1 Bg4 9. h3 Bh5 10. Be3 Qc8 11. Bxc5 dxc5 12. b4 Nd4 13. bxc5 Nxf3+ 14. gxf3 Qxh3 15. Ne2 Bxf3 0-1",
    B: "1083",
  },
  {
    W: "1326",
    T: "120+1",
    P: "1. d4 d5 2. Bf4 e6 3. Nf3 Nf6 4. Nbd2 c5 5. e3 Nc6 6. c4 cxd4 7. exd4 Be7 8. Bd3 O-O 9. O-O Qb6 10. Qc2 Nxd4 11. c5 Nxc2 12. cxb6 Nxa1 13. Rxa1 axb6 14. Ne5 Bd7 15. Nxd7 Nxd7 16. Bb5 Nf6 17. Bc7 Ra5 18. a4 Ne4 19. Nxe4 dxe4 20. Bxb6 Raa8 21. Re1 f5 22. f3 exf3 23. gxf3 Kf7 24. a5 Rac8 25. a6 bxa6 26. Bxa6 Rc2 27. Bd4 Bf6 28. Be3 Rxb2 29. Bc4 Ra8 30. Rd1 Rd8 31. Ra1 Rc8 32. Ra7+ Kg6 33. Bxe6 Rcc2 34. h4 Rg2+ 35. Kf1 Rh2 36. h5+ Kxh5 37. Bxf5 Rh1+ 38. Bg1 Rb1+ 39. Kg2 Rbxg1+ 40. Kf2 Rf1+ 41. Ke3 Re1+ 42. Kf4 Rh4+ 43. Bg4+ Kg6 44. Ra5 Rc1 45. Kg3 Rhh1 0-1",
    B: "1287",
  },
  {
    W: "1961",
    T: "120+1",
    P: "1. d4 d5 2. Bf4 c6 3. e3 e6 4. Nf3 f5 5. c4 Nf6 6. Nc3 Bd6 7. Be5 O-O 8. c5 Bxe5 9. Nxe5 Nbd7 10. Nxd7 Bxd7 11. b4 Ne4 12. Qc2 Qf6 13. f3 Qh4+ 14. g3 Nxg3 15. Qf2 f4 16. hxg3 fxg3 17. Rxh4 gxf2+ 18. Kxf2 Rf6 19. Bg2 Raf8 20. Rf1 g5 21. Rg4 h6 22. Ke2 Rf5 23. e4 Rf4 24. Rxf4 Rxf4 25. Bh3 Kf7 26. exd5 cxd5 27. Nxd5 Rxd4 28. Rd1 Bb5+ 29. Ke3 Rxd5 30. Rxd5 exd5 31. Kd4 Bc4 32. a3 Ke7 33. Bc8 b6 34. Bb7 Ke6 35. Bc8+ Ke7 36. Bb7 bxc5+ 37. bxc5 Ke6 38. Bc8+ Ke7 39. Bg4 Kf6 40. c6 Ke7 41. c7 Ba6 42. c8=Q Bxc8 43. Bxc8 Kf6 44. Kxd5 Kg6 45. Ke5 h5 46. Bh3 g4 47. fxg4 hxg4 48. Bxg4 Kf7 49. Kd6 1-0",
    B: "1938",
  },
  {
    W: "2009",
    T: "120+1",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d4 Nf6 5. Bc4 e6 6. Nf3 Be7 7. O-O O-O 8. Ne5 b6 9. Qf3 c6 10. Nxc6 Nxc6 11. Qxc6 Bd7 12. Qf3 Rc8 13. Bd3 Bc6 14. Qh3 Qxd4 15. Bg5 h6 16. Rad1 Qe5 17. Bxh6 gxh6 18. Qxh6 Qh5 19. Qf4 Ng4 20. h3 Ne5 21. Be2 Qg5 22. Qxg5+ Bxg5 23. f4 Nc4 24. Bxc4 Bf6 25. Bd3 Bxc3 26. bxc3 Bd5 27. c4 Bxc4 28. Bxc4 Rxc4 29. Rd2 Rfc8 30. Rff2 f5 31. g4 fxg4 32. hxg4 Kf7 33. Kg2 Rg8 34. Kg3 a5 35. Rh2 Ke7 36. Rh7+ Kf6 37. g5+ Kg6 38. Rh6+ Kf5 39. Rf6+ Ke4 40. Rxe6+ Kf5 41. Re5+ Kg6 42. Rd6+ Kf7 43. Rf6+ Kg7 44. Re7+ Kh8 45. Rh6# 1-0",
    B: "2047",
  },
  {
    W: "1990",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 d5 3. d4 exd4 4. exd5 Qxd5 5. Nc3 Bb4 6. Nxd4 Bxc3+ 7. bxc3 Nf6 8. Ba3 c5 9. Nb3 O-O 10. Nxc5 Re8+ 11. Be2 Bg4 12. f3 Bxf3 13. gxf3 Qxf3 14. Rf1 Qg2 15. Qd3 Nc6 16. Nxb7 Ne5 17. Qg3 Qxb7 18. Rxf6 Nc4 19. Bc1 Qe4 20. Rf2 Qxc2 21. Bh6 Qxc3+ 22. Kf1 Qxa1+ 23. Kg2 Re6 24. Bxc4 Rg6 25. Bxf7+ Kh8 26. Bxg6 hxg6 27. Bxg7+ Qxg7 0-1",
    B: "2099",
  },
  {
    W: "1611",
    T: "120+1",
    P: "1. d4 e6 2. c4 f5 3. Nc3 Nf6 4. Bg5 Be7 5. f4 d6 6. Nf3 Nbd7 7. e3 b6 8. Be2 Bb7 9. O-O O-O 10. Bxf6 Bxf6 11. d5 exd5 12. cxd5 c5 13. Rb1 a5 14. Na4 Ba6 15. Bxa6 Rxa6 16. b3 Re8 17. Qd3 Ra7 18. Qb5 Rb7 19. Rfe1 Nf8 20. Nb2 Ng6 21. Nc4 Re4 22. Nxa5 Rbe7 23. Nc6 Qd7 24. Nxe7+ Qxe7 25. Qxb6 Rxe3 26. Kf2 Rxe1 27. Rxe1 Qf7 28. Qxd6 h6 29. h3 Kh7 30. Qxc5 Ne7 31. d6 Nc8 32. b4 1-0",
    B: "1441",
  },
  {
    W: "1884",
    T: "120+1",
    P: "1. d4 e5 2. d5 c6 3. c4 Nf6 4. Nc3 Bc5 5. e3 Bb4 6. Nf3 Ne4 7. Qc2 cxd5 8. cxd5 Qa5 9. Bd2 Nxd2 10. Nxd2 Bxc3 11. bxc3 Qxd5 12. e4 Qe6 13. Bc4 Qg6 14. O-O O-O 15. Qd3 Nc6 16. Kh1 d6 17. f4 Be6 18. f5 Bxc4 19. Nxc4 Qf6 20. Qxd6 Qxd6 21. Nxd6 b6 22. Rfd1 Rad8 23. Rd5 Ne7 24. Rd3 Nc6 25. Rad1 h6 26. h4 f6 27. g4 Ne7 28. Kg2 Nc8 29. Kf3 Nxd6 30. Rxd6 Kh7 31. Rxd8 Rxd8 32. Rxd8 g6 33. Rd7+ Kg8 34. fxg6 1-0",
    B: "1822",
  },
  {
    W: "1664",
    T: "120+1",
    P: "1. e4 e6 2. d4 d5 3. exd5 exd5 4. Nc3 Nf6 5. Nf3 Bb4 6. h3 O-O 7. Bd2 Re8+ 8. Be2 Bxc3 9. bxc3 Bf5 10. O-O c6 11. Bd3 Bxd3 12. cxd3 Nbd7 13. Qc2 b6 14. Rfe1 c5 15. Rxe8+ Qxe8 16. dxc5 Nxc5 17. Re1 Qd7 18. Ne5 Qc7 19. d4 Nce4 20. Bf4 Qxc3 21. Qxc3 Nxc3 22. Nc6 Nxa2 23. Ne7+ Kf8 24. Bd6 Rd8 25. Ng6+ Kg8 26. Be7 Re8 27. Ra1 hxg6 28. Bxf6 gxf6 29. Rxa2 a5 30. Kf1 Re6 31. f3 Kg7 32. Kf2 f5 33. Rb2 Kf6 34. Rb5 Rd6 35. Ke3 g5 36. g3 Ke6 37. Kd3 f6 38. Ke3 Rc6 39. Kd3 f4 40. gxf4 gxf4 41. Rb2 Kf5 42. Rg2 a4 43. h4 a3 44. h5 b5 45. h6 Rc8 46. h7 Rh8 47. Rh2 a2 48. Rxa2 Rxh7 49. Ra5 Rb7 50. Ra3 b4 51. Rb3 Ke6 52. Ke2 f5 53. Kf2 Kd6 54. Ke2 Kc6 55. Kd3 Kb5 56. Kc2 Rc7+ 57. Kd3 Rc4 0-1",
    B: "1695",
  },
  {
    W: "1466",
    T: "240+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Ne5 5. Nc3 Bc5 6. Be3 Qh4 7. Be2 Nf6 8. Nf5 Nf3+ 9. Bxf3 Bxe3 10. Nxh4 Bf4 11. O-O d6 12. Nf5 Bxf5 13. exf5 O-O-O 14. Nd5 Be5 15. Nxf6 Bxf6 16. c3 Be5 17. Qd5 h5 18. Qxb7+ Kd7 19. Qc6+ Kc8 20. Qa8+ Kd7 21. Qxa7 h4 22. Qa4+ Kc8 23. Bc6 Kb8 24. Qa8# 1-0",
    B: "1399",
  },
  {
    W: "1658",
    T: "60+0",
    P: "1. d4 d5 2. c4 Nf6 3. e3 e6 4. Nf3 c6 5. Nc3 Bd7 6. Bd3 Na6 7. O-O Bb4 8. cxd5 Nxd5 9. Bxa6 Bxc3 10. Bxb7 Rb8 11. bxc3 Rxb7 12. c4 Nf6 13. Ne5 O-O 14. Nxd7 Qxd7 15. Ba3 Rd8 16. Rb1 Rxb1 17. Qxb1 Ne4 18. f3 Nd2 19. Qd1 Nxf1 20. Kxf1 f5 21. Qa4 Qb7 22. Kf2 g5 23. Be7 Rb8 24. Bxg5 Qb2+ 25. Kg3 Kf7 26. Qxc6 Qb6 27. Qd7+ Kg8 28. Qe7 Qb7 29. Qxe6+ Qf7 30. Qxf7+ Kxf7 31. Kf4 Rb2 32. Kxf5 Rxa2 33. d5 Rxg2 34. e4 Rxh2 35. d6 Rd2 36. e5 1-0",
    B: "1595",
  },
  {
    W: "1782",
    T: "180+0",
    P: "1. e4 c6 2. Nf3 d6 3. d4 Qc7 4. c4 Bg4 5. Nc3 Nf6 6. Be2 e5 7. h3 Bh5 8. dxe5 dxe5 9. Be3 Bb4 10. Qb3 Bxc3+ 11. Qxc3 Nxe4 12. Qd3 Bg6 13. Qa3 Nd7 14. O-O b6 15. Rad1 Nec5 16. Bxc5 bxc5 17. Qe3 O-O 18. Bd3 f5 19. Nh4 e4 20. Bc2 Nb6 21. Nxg6 hxg6 22. f3 Nxc4 23. Qxc5 Qb6 24. Qxb6 axb6 25. Bb3 b5 26. fxe4 Kh7 27. exf5 gxf5 28. Rd4 Ne3 29. Rff4 g5 30. Rf2 Ra7 31. Rd6 c5 32. a3 c4 33. Ba2 Re7 34. Rb6 Nd1 35. Rf1 c3 36. bxc3 Nxc3 37. Bb3 Ne2+ 38. Kh2 Nf4 39. Rxb5 Re2 40. Rg1 g4 41. h4 Nh5 42. a4 g3+ 43. Kh1 1-0",
    B: "1526",
  },
  {
    W: "1617",
    T: "600+0",
    P: "1. e4 e5 2. f4 f6 3. Bc4 Bc5 4. f5 Ne7 5. Nc3 Bd4 6. Nd5 Nxd5 7. Bxd5 c6 8. Qh5+ Ke7 9. Qf7+ Kd6 10. Bb3 Qe7 11. Qc4 Kc7 12. c3 Bb6 13. d4 d6 14. d5 Kd8 15. dxc6 Nxc6 16. Ne2 Na5 17. Qd3 Nxb3 18. axb3 h6 19. Be3 Bxe3 20. Qxe3 a6 21. O-O Qc7 22. c4 Ke7 23. Nc3 Kf7 24. Qg3 Qb6+ 25. Kh1 Qxb3 26. Qg6+ Kf8 27. Nd5 Qxc4 28. Nxf6 gxf6 29. Qxf6+ Ke8 30. Qxh8+ Kd7 31. Qg7+ Kd8 32. Rfc1 Qxe4 33. f6 Bd7 34. Qe7# 1-0",
    B: "1552",
  },
  {
    W: "1777",
    T: "180+3",
    P: "1. e4 c5 2. c3 g6 3. d4 cxd4 4. cxd4 Bg7 5. Bc4 Qb6 6. Nf3 Qb4+ 7. Nbd2 Nc6 8. a3 Qb6 9. Nb3 d6 10. Be3 Qd8 11. O-O Nf6 12. e5 dxe5 13. dxe5 Nd7 14. e6 fxe6 15. Bxe6 Nde5 16. Qxd8+ Nxd8 17. Bxc8 Nxf3+ 18. gxf3 Rxc8 19. Bxa7 Bxb2 20. Ra2 Bg7 21. Re1 Nc6 22. Rae2 O-O 23. Bc5 Rxf3 24. Nd2 Rf7 25. Bxe7 Nxe7 26. Rxe7 Rxe7 27. Rxe7 Ra8 28. Rxb7 Rxa3 29. Rb8+ Bf8 30. Rb7 Ra1+ 31. Kg2 Rd1 32. Ne4 Bg7 33. Ng5 Rd8 34. Ne6 Rd5 35. Rxg7+ Kh8 36. Re7 Kg8 37. Nf4 Rg5+ 38. Kf3 h5 39. Re6 Kf7 40. Ra6 Rf5 41. Ke4 Rf6 42. Rxf6+ Kxf6 43. Ng2 Kg5 44. h4+ Kg4 45. Ke3 g5 46. hxg5 Kxg5 47. Kf3 Kf5 48. Nh4+ Kg5 49. Kg3 Kf6 50. Kf4 Kg7 51. Kg5 Kf7 52. Kxh5 Ke6 53. Kg5 Ke5 54. f4+ Ke6 55. Kg6 Ke7 56. Kg7 Ke6 57. f5+ 1-0",
    B: "1809",
  },
  {
    W: "1877",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. Nf3 d6 5. e3 O-O 6. Bd3 c5 7. h3 Nc6 8. O-O e5 9. d5 Ne7 10. e4 Nh5 11. Nh2 Nf4 12. Bc2 f5 13. exf5 Bxf5 14. Bxf5 Nxf5 15. Bxf4 exf4 16. Nf3 Nd4 17. Nxd4 cxd4 18. Nb5 f3 19. Nxd4 fxg2 20. Kxg2 Qg5+ 21. Kh2 Qf4+ 22. Kh1 Bxd4 23. Qg4 Qxg4 24. hxg4 Rxf2 25. Rxf2 Bxf2 26. Kg2 Bd4 27. Rd1 Bxb2 28. Rb1 Ba3 29. Rxb7 Bc5 30. a4 Re8 31. a5 Re4 32. Kg3 Rxc4 33. a6 Rd4 34. Rb8+ Kg7 35. Rb7+ Kh6 36. Kh4 g5+ 37. Kg3 Rd3+ 38. Kg2 Rxd5 39. Re7 Re5 40. Rf7 Kg6 41. Rc7 Re2+ 42. Kg3 Ra2 43. Re7 Ra3+ 44. Kg2 Rxa6 45. Re6+ Kf7 46. Rh6 Kg7 47. Re6 Ra4 48. Re7+ Kg6 49. Re6+ Kf7 50. Rh6 Kg7 51. Re6 Rxg4+ 52. Kf3 Rf4+ 53. Kg3 h5 54. Re7+ Kg6 55. Re6+ Rf6 56. Re8 a5 57. Ra8 Bb4 58. Ra6 h4+ 59. Kg2 Kh5 60. Kh3 g4+ 61. Kg2 h3+ 62. Kh1 g3 63. Kg1 Bc5+ 64. Kh1 Rf1# 0-1",
    B: "2169",
  },
  {
    W: "1581",
    T: "60+0",
    P: "1. e4 b6 2. Bc4 Bb7 3. Qf3 e6 4. Qb3 g6 5. d4 Bg7 6. d5 Qe7 7. dxe6 fxe6 8. Bg5 Nf6 9. Bxf6 Bxf6 10. f3 Nc6 11. Bf1 Na5 12. Qd3 Bxb2 13. Nd2 Bxa1 14. Be2 Qg7 15. c3 Bxc3 16. g4 Bxd2+ 17. Qxd2 O-O-O 18. h4 Nc6 19. h5 gxh5 20. g5 Rdf8 21. f4 Qf7 22. Bxh5 Qxf4 23. Bf3 Ne5 24. Nh3 Qxd2+ 25. Kxd2 Nxf3+ 26. Kd3 Ne5+ 27. Kd4 d6 28. g6 hxg6 29. Ng5 Rxh1 30. Nxe6 Rff1 31. a4 Rd1+ 32. Kc3 Rd3+ 33. Kb4 Rc1 34. Nxc7 Kxc7 35. Kb5 Rb3# 0-1",
    B: "1827",
  },
  {
    W: "1789",
    T: "600+0",
    P: "1. d4 d5 2. Nc3 c6 3. e4 e6 4. Nf3 Nd7 5. e5 f6 6. exf6 Ngxf6 7. Bd3 Be7 8. O-O O-O 9. h3 Qe8 10. Re1 Nb6 11. a3 Bd6 12. Ne5 Nfd7 13. Nxd7 Bxd7 14. Qh5 Qxh5 15. Be3 Rf6 16. Rf1 Raf8 17. Rae1 Nc4 18. Nd1 Nxe3 19. Nxe3 Bf4 20. Ng4 R6f7 21. c3 e5 22. dxe5 Bxg4 23. hxg4 Qxg4 24. f3 Qg3 25. e6 Qh2+ 26. Kf2 Re7 27. Re2 Qg3+ 28. Kg1 Qh2+ 29. Kf2 Bg3+ 30. Ke3 Rxe6+ 31. Kd2 Rxe2+ 32. Bxe2 Qxg2 33. Kd3 Re8 34. Kd4 Qxe2 35. Re1 Bxe1 36. Kc5 Qc4+ 37. Kd6 Bg3+ 38. Kd7 Rf8 39. b4 Qxc3 40. f4 Rf7+ 41. Kd8 Qf6+ 42. Kc8 Qf5+ 43. Kb8 Bxf4+ 44. Kxa7 b5+ 45. Kb6 c5 46. bxc5 d4 47. Kxb5 d3 48. a4 Qxc5+ 49. Kxc5 d2 50. a5 d1=Q 51. a6 Qa4 52. Kb6 Rf6+ 53. Kb7 Qxa6# 0-1",
    B: "1694",
  },
  {
    W: "1424",
    T: "600+0",
    P: "1. e4 a6 2. Nf3 f6 3. d4 d5 4. Nc3 c6 5. Bd2 Nd7 6. exd5 cxd5 7. Nxd5 e6 8. Nf4 e5 9. dxe5 fxe5 10. Qe2 Be7 11. Ne6 Qb6 12. Nxg7+ Kf8 13. Nf5 Ngf6 14. O-O-O Nd5 15. Nxe5 Qe6 16. Nxd7+ Bxd7 17. Bh6+ Ke8 18. Ng7+ Kd8 19. Nxe6+ 1-0",
    B: "1057",
  },
  {
    W: "1391",
    T: "300+0",
    P: "1. d4 Nf6 2. c3 g6 3. f3 Bg7 4. Nd2 e6 5. e4 b6 6. e5 Nd5 7. Bc4 Ne3 8. Qe2 Bh6 9. Qxe3 Bxe3 10. Nb3 Qg5 11. Bxe3 Qxe3+ 12. Ne2 c5 13. Rd1 Na6 14. Rd3 Qg5 15. Bxa6 Bxa6 16. Rd2 Qxg2 17. Rf1 Qxh2 18. Rf2 Qh4 19. Nec1 Qh1+ 20. Rf1 Qxf1# 0-1",
    B: "1647",
  },
  {
    W: "2049",
    T: "30+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nc3 c5 4. d3 d6 5. Be3 Nc6 6. g3 a6 7. Bg2 b6 8. O-O Bb7 9. Re1 Rb8 10. Ne2 Qc7 11. c3 Be7 12. d4 h5 13. c4 Nd8 14. dxe5 dxe5 15. Qc2 h4 16. Nc3 hxg3 17. fxg3 b5 18. b3 b4 19. Nd5 Nxd5 20. cxd5 Qd6 21. Rad1 f6 22. Qd2 Nf7 23. Nh4 g5 24. Nf5 Qd8 25. d6 Nxd6 26. Nxd6+ Bxd6 27. Qxd6 Qxd6 28. Rxd6 Rc8 29. Red1 Rc7 30. Rd8+ Ke7 31. Rxh8 a5 32. Rh7+ Ke6 33. Rxc7 Ba6 34. Kf2 Be2 35. Kxe2 g4 36. Rd2 f5 37. exf5+ Kxf5 38. Bf4 exf4 39. gxf4 Kxf4 40. Bh1 Kg5 41. Kf2 Kh4 42. Kg2 Kg5 43. Rd5+ Kf4 44. Rdxc5 a4 45. Ra5 a3 46. Rxa3 Ke3 47. Ra4 Kd2 48. Rxb4 Kd1 49. Rxg4 Ke1 50. h3 Ke2 51. h4 Ke3 52. h5 Ke2 53. Rg5 Ke1 54. h6 Ke2 55. Rg6 Ke1 56. Rgg7 Ke2 57. Rg8 Ke1 58. Re8+ 1-0",
    B: "1959",
  },
  {
    W: "1044",
    T: "60+0",
    P: "1. g3 e5 2. Bg2 d5 3. c4 d4 4. d3 Nc6 5. e3 Nce7 6. exd4 exd4 7. Ne2 c5 8. O-O Bg4 9. Bf4 Bxe2 10. Qxe2 f6 11. Re1 Qd7 12. Nd2 O-O-O 13. Nb3 Ng6 14. Nxc5 Nxf4 15. gxf4 Qf7 16. Nxb7 Qxb7 17. Bxb7+ Kxb7 18. Qe6 Ka8 19. Qc6+ Kb8 20. Re4 Nh6 21. Re6 Nf5 22. b4 Bxb4 23. Qb5+ Kc8 24. Qxb4 Kd7 25. Qb5+ Kxe6 26. Re1+ Kf7 27. Qxf5 g6 28. Qe6+ Kg7 29. Qe7+ 1-0",
    B: "1025",
  },
  {
    W: "1595",
    T: "60+0",
    P: "1. e4 e6 2. d4 b6 3. d5 Bb7 4. c4 exd5 5. exd5 Qe7+ 6. Be2 c6 7. d6 Qf6 8. Nf3 Bxd6 9. Bg5 Qe6 10. O-O f6 11. Re1 fxg5 12. Nxg5 Qf6 13. Bh5+ Kf8 14. Nf7 g6 15. Nxh8 gxh5 16. Re3 Qxh8 17. Qxd6+ Kg7 18. Rg3+ Kf7 19. Rf3+ Ke8 20. Qf8# 1-0",
    B: "1715",
  },
  {
    W: "1344",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4 5. Kf1 Nxb5 6. Nxb5 c6 7. Nc3 d5 8. exd5 cxd5 9. Nxe5 Bd6 10. Qe1 O-O 11. d3 Re8 12. Qe2 Rxe5 13. Qf3 Bg4 14. Qg3 Re1+ 15. Kxe1 Bxg3 16. hxg3 Qe7+ 0-1",
    B: "1412",
  },
  {
    W: "1784",
    T: "60+0",
    P: "1. g3 e5 2. Bg2 d5 3. Nc3 e4 4. f3 Nf6 5. fxe4 dxe4 6. Nxe4 Nxe4 7. Bxe4 c6 8. Nf3 Be6 9. c3 Bc5 10. Qa4 Bb6 11. Bd3 O-O 12. b3 c5 13. Bb2 Qd7 14. Bc4 Qxa4 15. bxa4 Bxc4 16. O-O-O Bxa2 17. Kc2 Bd5 18. d3 Bxf3 19. exf3 Nc6 20. Rhf1 Rae8 21. f4 Re3 22. d4 Rfe8 23. dxc5 Re2+ 24. Kb3 Bxc5 25. c4 R8e3+ 26. Ka2 Rc2 27. Rd8+ Nxd8 28. Rd1 Re8 29. Rd5 Bb6 30. Rb5 Nc6 31. a5 Ra8 32. axb6 axb6+ 33. Kb1 f6 34. Kxc2 Nd4+ 35. Kc3 Nxb5+ 36. cxb5 Ra2 37. Ba3 Rxa3+ 38. Kb4 Ra2 39. Kc4 Rb2 40. Kd5 Rxb5+ 41. Ke6 Rb2 42. Kf5 Rf2 43. g4 Rxh2 44. g5 Rg2 45. gxf6 Rg5+ 46. Kxg5 gxf6+ 47. Kxf6 Kf8 48. f5 b5 49. Ke6 b4 50. Kd6 b3 51. Kc7 b2 52. Kxb7 b1=Q+ 53. Kc7 Qc2+ 54. Kd6 Qxf5 55. Kc6 Kf7 0-1",
    B: "1879",
  },
  {
    W: "2137",
    T: "60+0",
    P: "1. Nf3 g6 2. d4 Bg7 3. Nbd2 e5 4. dxe5 Nc6 5. e4 Nge7 6. e6 O-O 7. exf7+ Rxf7 8. Bc4 d5 9. exd5 Nxd5 10. O-O Be6 11. Re1 Re7 12. Ng5 Na5 13. Nxe6 Rxe6 14. Rxe6 Qh4 15. Bxd5 Kh8 16. Re3 c6 17. Bb3 Nxb3 18. axb3 Rd8 19. Rh3 Qd4 20. Ra4 Qf6 21. Rah4 h5 22. Qf3 Qe7 23. Re4 Qd6 24. Qg3 Kh7 25. Qg5 Bxb2 26. Rxh5+ gxh5 27. Qxh5+ Kg8 28. Bxb2 Qxd2 29. Qh8+ Kf7 30. Qf6+ Kg8 31. Rg4+ Kh7 32. Qg7# 1-0",
    B: "2111",
  },
  {
    W: "2188",
    T: "60+0",
    P: "1. d4 e6 2. Nf3 Ne7 3. Bf4 d5 4. e3 Ng6 5. Bg3 Bd6 6. Bd3 Bxg3 7. hxg3 f5 8. Nbd2 Nd7 9. Nh4 Qf6 10. Nxg6 Qxg6 11. Qf3 c5 12. c3 b6 13. O-O-O Bb7 14. g4 Qxg4 15. Qxg4 fxg4 16. Rxh7 Rxh7 17. Bxh7 O-O-O 18. Bg6 cxd4 19. exd4 Nf6 20. Rh1 Ba6 21. Bf7 Rd6 22. Nf1 Be2 23. Ne3 Kd7 24. Bg6 Ke7 25. Kd2 Ne4+ 26. Kxe2 Ng5 27. Nxg4 a5 28. Bd3 Ne4 29. Ne5 Nf6 30. f4 Rd8 31. g4 Rg8 32. Kf3 Nd7 33. g5 Nxe5+ 34. fxe5 g6 35. Kg4 Rg7 36. Rh7 Kf7 37. Bxg6+ Kxg6 38. Rh6+ Kf7 39. Rf6+ Ke7 40. g6 Rg8 41. Kg5 Ke8 42. Rf7 Rf8 43. Ra7 Rf1 44. Ra8+ Kd7 45. Ra7+ Kc6 46. Rc7+ Kxc7 47. g7 Rg1+ 48. Kf6 1-0",
    B: "2148",
  },
  {
    W: "1271",
    T: "60+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. c5 Nc4 5. Bxc4 d6 6. exd6 exd6 7. cxd6 Bxd6 8. Nf3 Bg4 9. O-O O-O 10. Qc2 Bxf3 11. gxf3 Qg5+ 12. Kh1 Qh4 13. f4 Qxh2+ 14. Kxh2 Bxf4+ 15. Kg2 Re8 16. Rh1 h6 17. Qf5 Rf8 18. Qxf4 b5 19. Bb3 Nc6 20. Qg3 Rae8 21. Rxh6 g6 22. Rh3 Kg7 23. Qh4 Rh8 24. Qxh8+ Rxh8 25. Rxh8 Kxh8 0-1",
    B: "1266",
  },
  {
    W: "1544",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. Nf3 Nc6 5. d4 e6 6. d5 Bb4 7. dxe6 Qxd1+ 8. Kxd1 Bxc3 9. bxc3 Bxe6 10. Bb5 O-O-O+ 11. Ke2 Na5 12. Bg5 f6 13. Bf4 g5 14. Bg3 Bc4+ 15. Bxc4 Nxc4 16. Rhd1 Rxd1 17. Rxd1 Ne7 18. Rd4 Na3 19. Ra4 Nxc2 20. Nd4 Nxd4+ 21. cxd4 Kd7 22. f3 a6 23. Bf2 Nd5 24. Kd3 Re8 25. Kc4 Nb6+ 26. Kb4 Nxa4 27. Kxa4 Re2 28. Ka5 Rxf2 29. g4 Rxa2+ 30. Kb4 Rxh2 31. f4 gxf4 32. d5 Rg2 33. d6 Rxg4 34. dxc7 Kxc7 35. Kc5 f3 36. Kd5 f2 37. Ke6 f1=Q 38. Kf7 Rg2 39. Ke8 Re2+ 40. Kf7 Qe1 41. Kg7 f5 42. Kf6 f4 43. Kg5 f3 44. Kf4 f2 45. Kf3 f1=Q+ 0-1",
    B: "1595",
  },
  {
    W: "1509",
    T: "60+0",
    P: "1. d4 d5 2. c4 e6 3. cxd5 Nf6 4. Nc3 exd5 5. e3 Be7 6. Nf3 O-O 7. Bd3 a6 8. Kf1 b6 9. g3 Bb7 10. Kg2 Ne4 11. Re1 Nxc3 12. bxc3 c5 13. Ba3 cxd4 14. Bxe7 Qxe7 15. cxd4 f6 16. Qb3 b5 17. a4 Bc6 18. axb5 axb5 19. Rxa8 Bxa8 20. Qxb5 Nd7 21. Qxd5+ Bxd5 22. e4 Ba8 23. h4 f5 24. e5 Nb6 25. Bb1 Na4 26. Bd3 Nc3 27. Bc2 Ne4 28. Bxe4 Bxe4 29. Re2 Qb7 30. Re3 Bxf3+ 31. Rxf3 1-0",
    B: "1541",
  },
  {
    W: "2081",
    T: "60+0",
    P: "1. d3 d5 2. Nd2 c6 3. Ngf3 Nf6 4. g3 e6 5. Bg2 Bd6 6. O-O h5 7. Re1 h4 8. e4 hxg3 9. e5 gxh2+ 10. Kh1 Ng4 11. Qe2 Bc5 12. d4 Bb6 13. Nb3 Nd7 14. Bg5 Qc7 15. Nfd2 Nh6 16. Bxh6 Rxh6 17. Qg4 Rg6 18. Qh4 c5 19. Qh8+ Nf8 20. c3 cxd4 21. cxd4 Qc2 22. Re3 Qf5 23. Rf3 Qg5 24. Rg3 Qf5 25. Rb1 Rxg3 26. fxg3 Bd7 27. Rf1 Qg6 28. Qxh2 O-O-O 29. Rc1+ Kb8 30. Kg1 Bc6 31. Nc5 Bxc5 32. Rxc5 Nd7 33. Rxc6 bxc6 34. Bf1 Ka8 35. Qe2 Rh8 0-1",
    B: "1970",
  },
  {
    W: "1764",
    T: "60+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. Nc3 d6 5. exd6 exd6 6. Nf3 Nc6 7. d4 Bg4 8. d5 Bxf3 9. Qxf3 Ne5 10. Qe4 Be7 11. f4 Nexc4 12. Bxc4 f5 13. Qe2 O-O 14. O-O Re8 15. Bb3 Bf6 16. Qh5 g6 17. Qf3 Qd7 18. Bd2 a5 19. a4 Qf7 20. Kh1 Kg7 21. Rfe1 Rxe1+ 22. Rxe1 Nd7 23. Re6 Nc5 24. Ba2 Nxe6 25. dxe6 Qe7 26. Qxb7 Re8 27. Nd5 Qxe6 28. Nxf6 Qe2 29. Qxc7+ Kxf6 30. Qxd6+ 1-0",
    B: "1759",
  },
  {
    W: "1347",
    T: "60+0",
    P: "1. d4 d5 2. Bf4 c5 3. c3 Nc6 4. e3 Nf6 5. Bd3 Bg4 6. Nf3 Bxf3 7. Qxf3 e6 8. h3 Be7 9. Bh2 Qd7 10. Nd2 O-O 11. O-O e5 12. dxe5 Qe6 13. exf6 Bxf6 14. e4 dxe4 15. Nxe4 Rfe8 16. Nxf6+ Qxf6 17. Rae1 Qxf3 18. gxf3 Rxe1 19. Rxe1 Rd8 20. Be4 Ne5 21. f4 Nd3 22. Rd1 c4 23. Kf1 b5 24. Ke2 h6 25. Bxd3 cxd3+ 26. Rxd3 Rxd3 27. Kxd3 Kh7 28. f5 f6 29. f4 Kg8 30. Bg1 Kf7 31. Bc5 Ke8 32. Kd4 Kd7 33. Kd5 Kc7 34. b4 a6 35. a3 h5 36. Bf8 h4 37. Bxg7 Kd7 38. Bh6 Ke7 39. Bg7 Kd7 40. Bxf6 Ke8 41. Kc6 1-0",
    B: "1428",
  },
  {
    W: "1776",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. c3 Qf6 6. Be3 Bb6 7. Bc4 d6 8. Nd2 Be6 9. O-O O-O-O 10. b3 d5 11. exd5 Bxd5 12. Bxd5 Rxd5 13. Ne4 Qd8 14. c4 Rxd4 15. Bxd4 Bxd4 16. Rc1 Nf6 17. Nd2 g5 18. Nf3 Bb6 19. a4 Qg8 20. g3 g4 21. Nd2 h5 22. b4 Nxb4 23. Rb1 Nc6 24. Nb3 h4 25. c5 Ba5 26. Nxa5 Nxa5 27. Qd2 Nc6 28. Qe3 Qg6 29. f4 hxg3 30. hxg3 Qh5 31. Kf2 Qh2+ 32. Ke1 Nd5 33. Qd4 Re8+ 34. Kd1 Nxd4 35. Kc1 Nc3 36. Rb2 Nde2+ 0-1",
    B: "1914",
  },
  {
    W: "1378",
    T: "180+0",
    P: "1. d4 d5 2. c4 e6 3. Nf3 Nf6 4. Bg5 Be7 5. e3 h6 6. Bh4 g5 7. Bg3 Nc6 8. cxd5 Qxd5 9. Bd3 Bd7 10. O-O O-O-O 11. Nbd2 Nb4 12. Be2 h5 13. Qa4 h4 14. Qxa7 Nc6 15. Qa8+ Nb8 16. Ne5 hxg3 17. Nxf7 gxh2+ 18. Kh1 Bc6 19. Nxd8 Qxg2# 0-1",
    B: "1354",
  },
  {
    W: "1674",
    T: "180+0",
    P: "1. f4 c5 2. Nf3 e6 3. e3 h6 4. b3 Rh7 5. Bb2 g6 6. Bd3 Bg7 7. Bxg7 Rxg7 8. O-O d6 9. c4 f5 10. Nc3 a6 11. e4 b6 12. exf5 exf5 13. Re1+ Kd7 14. Nd5 Bb7 15. Qc2 Bxd5 16. cxd5 Nf6 17. Qc3 Qg8 18. a4 Nxd5 19. Qb2 Nxf4 20. Bc2 Nc6 21. d4 Re8 22. dxc5 Rxe1+ 23. Rxe1 bxc5 24. Rd1 Nd5 25. Bd3 Re7 26. Bc4 Ncb4 27. Bxd5 Nxd5 28. h3 a5 29. Qd2 Kc6 30. Qxa5 Rb7 31. Qa6+ Rb6 32. Qd3 Qb8 33. Nd2 Nb4 34. Qf3+ Kc7 35. Nc4 d5 0-1",
    B: "1685",
  },
  {
    W: "1903",
    T: "180+0",
    P: "1. e4 b6 2. Nc3 Bb7 3. Bc4 e6 4. d3 Bc5 5. Nge2 a5 6. a4 Ne7 7. O-O O-O 8. Bg5 h6 9. Bh4 g5 10. Bg3 f5 11. exf5 Nxf5 12. Kh1 Nxg3+ 13. fxg3 Rxf1+ 14. Qxf1 Qc8 15. Qf6 Qf8 16. Qg6+ Qg7 17. Qe8+ Qf8 18. Qxd7 Nxd7 19. Bxe6+ Kh7 20. Bxd7 Qd6 21. Bf5+ Kg7 22. Be4 Bxe4 23. Nxe4 Qd4 24. Nxd4 Bxd4 25. c3 Bc5 26. Nxc5 bxc5 27. Re1 Rb8 28. Re2 Kf7 29. Kg1 Rb3 30. Kf2 c4 31. d4 c5 32. d5 Rb6 33. Ke3 Rd6 34. Ke4 Kf6 35. Rf2+ Kg6 36. Rf5 Rd8 37. Re5 Kf6 38. Re6+ Kf7 39. Rxh6 Re8+ 40. Kf5 Rd8 1-0",
    B: "1895",
  },
  {
    W: "1535",
    T: "180+0",
    P: "1. e3 e5 2. Be2 e4 3. f3 exf3 4. Nxf3 Nc6 5. O-O d6 6. d4 d5 7. c4 dxc4 8. Bxc4 a6 9. e4 g6 10. Bxf7+ Kxf7 11. Ng5+ Kg7 12. Rf7+ Kh6 13. Ne6+ 1-0",
    B: "1606",
  },
  {
    W: "1387",
    T: "180+0",
    P: "1. e4 e5 2. d4 exd4 3. Nf3 Nc6 4. Bb5 a6 5. Bxc6 dxc6 6. Nxd4 c5 7. Nf3 Qxd1+ 8. Kxd1 Bg4 9. Bf4 O-O-O+ 10. Ke2 Bxf3+ 11. gxf3 Nf6 12. Nc3 b6 13. Rad1 Nh5 14. Bg3 Nxg3+ 15. fxg3 Bd6 16. f4 f6 17. Nd5 c6 18. Nxb6+ Kc7 19. Nc4 g5 20. Nxd6 Rxd6 21. Rxd6 Kxd6 22. c4 gxf4 23. gxf4 Re8 24. Kf3 Rb8 25. b3 a5 26. h4 a4 27. Rb1 axb3 28. axb3 h5 29. Rd1+ Ke6 30. Rd3 Rb7 31. Ke2 f5 32. e5 Rg7 33. Rd6+ Ke7 34. Rxc6 Kd7 35. Rxc5 Rg2+ 36. Kd1 Rg4 37. e6+ Kxe6 38. Rc6+ Kd7 39. Rf6 Rxf4 40. c5 Re4 41. c6+ Kc7 42. Kc2 Rd4 43. Kc3 Rf4 44. b4 Re4 45. b5 Rxh4 46. Rxf5 Rh3+ 47. Kc4 Rh4+ 48. Kc5 Ra4 49. Rf7+ Kc8 50. b6 Ra5+ 51. Kd6 Ra6 52. b7+ Kb8 53. Kd7 Ka7 54. Kc7 h4 55. Kc8 Rxc6+ 56. Rc7 Rb6 57. b8=Q+ Ka6 58. Ra7+ Kb5 59. Qe5+ Kc6 60. Rc7# 1-0",
    B: "1426",
  },
  {
    W: "1884",
    T: "180+0",
    P: "1. d4 c5 2. Nf3 cxd4 3. Nxd4 Nc6 4. Nxc6 bxc6 5. e4 e6 6. c3 Nf6 7. Nd2 d5 8. exd5 exd5 9. Be2 Bd6 10. O-O O-O 11. h3 Bc7 12. Bf3 Qd6 13. g3 Bxh3 14. Re1 Bb6 15. Bg2 Qxg3 16. Qf3 Qxf3 17. Nxf3 Bxg2 18. Kxg2 Ne4 19. Be3 Bxe3 20. fxe3 Rae8 21. Nd4 c5 22. Nf5 Re5 23. Nd6 Rg5+ 0-1",
    B: "1884",
  },
  {
    W: "2204",
    T: "180+0",
    P: "1. Nf3 e6 2. g3 d5 3. Bg2 Nf6 4. O-O Be7 5. d4 O-O 6. c4 c5 7. Nc3 cxd4 8. Nxd4 dxc4 9. Be3 e5 10. Ndb5 Nc6 11. Qa4 Nd4 12. Qxc4 Be6 13. Qd3 Nxb5 14. Qxb5 Qc7 15. Rac1 Bc4 16. Qxb7 Qxb7 17. Bxb7 Rab8 18. b3 Bxe2 19. Nxe2 Rxb7 20. Rfd1 Ba3 21. Rc6 a5 22. Nc3 Bb2 23. Nd5 Nxd5 24. Rxd5 f6 25. Rxa5 Rd7 26. Ra7 Rd1+ 27. Kg2 Rfd8 28. Rcc7 e4 29. Rxg7+ Kf8 30. Rgf7+ Kg8 31. Rg7+ Kf8 32. Raf7+ Ke8 33. Rxh7 f5 34. Bh6 Ba3 35. Rfg7 Bf8 36. Rg8 R8d2 37. Rxf8# 1-0",
    B: "2024",
  },
  {
    W: "1221",
    T: "180+0",
    P: "1. d4 d6 2. Bf4 e5 3. dxe5 dxe5 4. Bxe5 Qxd1+ 5. Kxd1 Bf5 6. Nc3 Bd6 7. Bxg7 Nc6 8. Bxh8 Nh6 9. Bf6 Be5 10. Bxe5 Nxe5 11. g3 Neg4 12. Ke1 O-O-O 13. Nh3 Bxc2 14. f3 Ne3 15. Kf2 Nxf1 16. Kxf1 Nf5 17. g4 Ne3+ 18. Kf2 Ng2 19. b3 Nf4 20. e4 Nxh3+ 21. Kg3 Ng5 22. Rac1 Bd3 23. Rhd1 Be2 24. Rxd8+ Kxd8 25. Nxe2 Ne6 26. Rd1+ Kc8 27. Nf4 Nxf4 28. Kxf4 b6 29. Kf5 Kb7 30. Rd7 Kc6 31. Rxf7 a6 32. Rxh7 b5 33. e5 Kc5 34. e6 c6 35. e7 Kb4 36. e8=Q c5 37. Qe4+ Ka3 38. Qc2 b4 39. Qxc5 a5 40. Ra7 Kxa2 41. Qxa5+ Kb2 42. Qxb4 Kc2 43. g5 Kd3 44. g6 Ke2 45. Kf4 Kf2 46. h4 Ke2 47. h5 Kd3 48. h6 Kc2 49. h7 Kd3 50. h8=Q Ke2 51. g7 Kd3 52. g8=Q Kc2 53. Qd8 Kb1 54. Qhc3 1/2-1/2",
    B: "1112",
  },
  {
    W: "1355",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 d6 3. c3 Nd7 4. d4 c6 5. Nbd2 Qc7 6. Nc4 e5 7. dxe5 dxe5 8. Ncxe5 Nxe5 9. Nxe5 Qxe5 10. Bd3 Bd6 11. O-O Qxh2# 0-1",
    B: "1272",
  },
  {
    W: "1854",
    T: "60+0",
    P: "1. e3 Nf6 2. Be2 e5 3. d4 exd4 4. exd4 d5 5. c4 c6 6. cxd5 cxd5 7. Nc3 Nc6 8. Nf3 Bb4 9. O-O Qa5 10. Bd2 O-O 11. Nb1 Bg4 12. Bxb4 Qxb4 13. Nc3 Bxf3 14. Bxf3 Nxd4 15. Qd2 Nxf3+ 16. gxf3 Qh4 17. Nxd5 Rfd8 18. Nxf6+ Qxf6 19. Qe3 Re8 20. Qd3 Rad8 21. Qc3 Re5 22. f4 Qg6+ 23. Kh1 Rh5 24. Qg3 Qh6 25. Rfd1 Qc6+ 26. Kg1 Rxd1+ 27. Rxd1 f6 28. Qb3+ Kf8 29. Qg3 Rh6 30. Rd8+ Ke7 31. f5 Qc1+ 32. Kg2 Qg5 33. Qxg5 fxg5 34. Rd3 Rd6 35. Rg3 Kf6 36. Rf3 Rb6 37. h4 Rb5 38. hxg5+ Kxg5 39. Rg3+ Kxf5 40. Rf3+ Kg6 41. Re3 Rg5+ 42. Rg3 1-0",
    B: "1870",
  },
  {
    W: "2034",
    T: "60+0",
    P: "1. e4 d5 2. Nc3 d4 3. Nce2 e5 4. Ng3 Nc6 5. Nf3 Bd6 6. Bb5 Nf6 7. Bxc6+ bxc6 8. O-O Bg4 9. d3 Bxf3 10. Qxf3 O-O 11. Nf5 g6 12. Nh6+ Kg7 13. Bg5 Be7 14. c3 Ng8 15. Bxe7 Qxe7 16. Nxg8 Rxg8 17. cxd4 exd4 18. Qg3 f5 19. f4 fxe4 20. dxe4 Qxe4 21. Rae1 Qf5 22. Re7+ Kh8 23. Rxc7 Rae8 24. Rxc6 Re2 25. h3 Rge8 26. Kh2 Qe4 27. Rc7 Rxg2+ 28. Qxg2 Qxg2+ 29. Kxg2 Re2+ 30. Rf2 Re3 0-1",
    B: "2012",
  },
  {
    W: "1194",
    T: "60+0",
    P: "1. e4 c5 2. Nc3 d6 3. g3 Nc6 4. Nf3 g6 5. Bg2 Bg7 6. O-O e6 7. Re1 Nge7 8. d4 cxd4 9. Nxd4 Qc7 10. Nxc6 bxc6 11. Ne2 O-O 12. Bf4 Bb7 13. Bxd6 Qb6 14. Bxe7 Rfe8 15. Qd4 Qc7 16. Qd6 Qxe7 17. Qxe7 Rxe7 18. Nf4 e5 19. Nh3 Rd7 20. Rad1 Rad8 21. Rxd7 Rxd7 22. Bf3 c5 23. Bg4 Rd6 24. f3 Bf8 25. Nf2 h5 26. Nd3 hxg4 27. fxg4 c4 28. Nxe5 Re6 29. Nxc4 Bxe4 30. Kf2 Bd5 31. Rxe6 fxe6 32. Nd6 Bxd6 33. Kg1 Bc5+ 34. Kf1 a5 35. c3 Bxa2 36. Ke1 Bc4 37. Kd2 e5 38. Kc2 e4 0-1",
    B: "1456",
  },
  {
    W: "1385",
    T: "60+0",
    P: "1. d3 e5 2. e4 Nf6 3. Nf3 Nc6 4. Be2 d5 5. Nc3 Bb4 6. Bg5 d4 7. Bxf6 Qxf6 8. O-O dxc3 9. bxc3 Bxc3 10. Rb1 O-O 11. h3 h6 12. Qc1 Nd4 13. Nxd4 Bxd4 14. Bf3 g5 15. Qa3 h5 16. Bxh5 Qh6 17. Bf3 g4 18. Bxg4 Bxg4 19. hxg4 Kg7 20. Qc1 Rh8 21. Qxh6+ Rxh6 22. g3 Rah8 23. Kg2 Rh2+ 24. Kf3 1-0",
    B: "1173",
  },
  {
    W: "1338",
    T: "120+1",
    P: "1. e4 c5 2. f4 Nc6 3. Nf3 e6 4. Bb5 Nge7 5. Nc3 a6 6. Bxc6 bxc6 7. O-O d6 8. d4 cxd4 9. Nxd4 c5 10. Nf3 Ng6 11. f5 Nh4 12. Bg5 Be7 13. Bxh4 Bxh4 14. fxe6 Bxe6 15. e5 d5 16. g3 Bg5 17. a3 c4 18. b4 O-O 19. h4 Be3+ 20. Kh2 Bb6 21. Ng5 Qe7 22. Ne2 Bc7 23. c3 Bxe5 24. Qc2 Bg4 25. Qxh7# 1-0",
    B: "1383",
  },
  {
    W: "1156",
    T: "120+1",
    P: "1. d4 g6 2. Bf4 Bg7 3. Nf3 d5 4. e3 Nf6 5. Nbd2 Nc6 6. Bd3 O-O 7. c3 Re8 8. O-O Nh5 9. Be5 Nxe5 10. Nxe5 f6 11. Nxg6 hxg6 12. Bxg6 Rf8 13. Qxh5 Rf7 14. Qh7+ Kf8 15. Bxf7 Kxf7 16. f4 Bd7 17. Rf3 Qh8 18. Qxh8 Rxh8 19. c4 c6 20. cxd5 cxd5 21. Rc1 Rh6 22. Rc7 Be6 23. Rxb7 Rg6 24. Rg3 Rxg3 25. hxg3 Bf8 26. e4 Bc8 27. Rxa7 dxe4 28. Nxe4 1-0",
    B: "1177",
  },
  {
    W: "1276",
    T: "120+1",
    P: "1. d4 d5 2. Bf4 Nc6 3. e3 e6 4. Nf3 Nf6 5. Bd3 Be7 6. O-O O-O 7. Re1 h6 8. Nbd2 Nh7 9. e4 dxe4 10. Nxe4 Bf6 11. c3 g5 12. Be5 Bxe5 13. Nxe5 Nxe5 14. dxe5 Qd5 15. Nf6+ Nxf6 16. exf6 e5 17. Bh7+ Kxh7 18. Qxd5 Be6 19. Qe4+ Kg8 20. Re3 Rad8 21. Rh3 Bxh3 22. gxh3 c6 23. Kg2 Rd2 24. b4 Rfd8 25. Qf5 R8d6 26. Qc8+ Kh7 27. Qxb7 Kg6 28. Qxa7 Rxf6 29. Qc5 Rfxf2+ 30. Qxf2 Rxf2+ 31. Kxf2 f5 32. a4 Kf6 33. a5 e4 34. a6 f4 35. a7 Ke5 36. a8=Q Kd5 37. Rd1+ Kc4 38. Qxc6+ Kb3 39. b5 e3+ 40. Kf3 Kc2 41. Qd5 Kxc3 42. Qd3+ Kb4 43. Rc1 Ka5 44. Qc4 Kb6 45. Qc6+ Ka5 46. b6 Kb4 47. b7 Kb3 48. b8=Q+ Ka3 49. Qa6# 1-0",
    B: "1313",
  },
  {
    W: "1456",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Bg4 4. Be2 Nc6 5. h3 Bxf3 6. Bxf3 Nf6 7. O-O Nd4 8. d3 Nxf3+ 9. Qxf3 Be7 10. Nc3 h6 11. b3 Qd7 12. Bb2 O-O-O 13. a4 d5 14. exd5 Nxd5 15. Nxd5 Qxd5 16. Qxd5 Rxd5 17. Rfe1 f6 18. d4 Rhd8 19. dxe5 fxe5 20. Bxe5 Bf6 21. Bxf6 gxf6 22. c4 Rd2 23. Rab1 b6 24. Re6 R8d6 25. Re7 Rd1+ 26. Rxd1 Rxd1+ 27. Kh2 Rd2 28. f4 Rb2 29. Rf7 Rxb3 30. Rxf6 Rb4 31. Rxh6 Rxc4 32. f5 Rf4 33. g4 Rf2+ 34. Kg3 Ra2 35. f6 Rxa4 36. f7 Ra3+ 37. Kh4 Rf3 38. Rh7 Kb7 39. g5 Rf4+ 40. Kh5 Rf5 41. Kh6 Rf3 42. h4 Rf5 43. g6 1-0",
    B: "1384",
  },
  {
    W: "1046",
    T: "120+1",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. Nf3 Nc6 5. d4 e5 6. Nxe5 Nxe5 7. dxe5 Qe7 8. Bb5+ Bd7 9. Bxd7+ Qxd7 10. Qxd7+ Kxd7 11. Bf4 Re8 12. O-O-O+ Kc8 13. Nd5 Ne7 14. c3 Nxd5 15. Rxd5 Be7 16. Rhd1 h6 17. g3 c6 18. Rc5 Bxc5 19. Be3 Bxe3+ 20. fxe3 Rxe5 21. Re1 Rhe8 22. Rf1 Rxe3 23. Rxf7 Re1+ 24. Kc2 R8e2+ 25. Kd3 Rxh2 26. Rxg7 Rxb2 27. Rg8+ Kc7 28. Rg7+ Kb6 29. Rg8 Rxa2 30. Rh8 Rg1 31. Rxh6 Rxg3+ 32. Ke4 Rxc3 33. Ke5 Re2+ 34. Kf6 Rf3+ 35. Kg5 a5 36. Kg6 Rfe3 37. Kh7 Re7+ 38. Kg6 R7e6+ 39. Kh7 Rxh6+ 40. Kxh6 a4 41. Kg6 a3 42. Kf6 a2 43. Kf5 a1=Q 44. Kf4 Qf1+ 45. Kg3 Rg2+ 46. Kh3 Qh1# 0-1",
    B: "1067",
  },
  {
    W: "1469",
    T: "120+1",
    P: "1. e4 e5 2. Bc4 c6 0-1",
    B: "1461",
  },
  {
    W: "1838",
    T: "120+1",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. Bc4 c6 5. Nge2 Bf5 6. b3 Nf6 7. a4 e6 8. Bb2 Bd6 9. O-O Nbd7 10. Ng3 Bg6 11. Re1 O-O 12. Qe2 Nd5 13. Nce4 Bxg3 14. fxg3 Bxe4 15. Qxe4 N7f6 16. Qf3 Qc7 17. Re2 Rad8 18. Rf1 Qb6+ 19. Kh1 Rfe8 20. Bxd5 exd5 21. Bxf6 Rxe2 22. Bxd8 Qxd8 23. Qxf7+ Kh8 24. Qf8+ 1-0",
    B: "1881",
  },
  {
    W: "1731",
    T: "120+1",
    P: "1. g3 e5 2. Bg2 d5 3. c4 c6 4. cxd5 cxd5 5. Qb3 Nf6 6. Nc3 d4 7. Nd5 Nc6 8. d3 Rb8 9. e4 Be6 10. Ne2 Bd6 11. Bg5 Bxd5 12. exd5 Na5 13. Qa4+ b5 14. Qd1 h6 15. Bd2 O-O 16. O-O Re8 17. b4 Nb7 18. Rc1 Qe7 19. a3 e4 20. Nxd4 exd3 21. Nc6 1-0",
    B: "1759",
  },
  {
    W: "1844",
    T: "120+1",
    P: "1. d4 d5 2. c4 e6 3. e3 Nf6 4. Nc3 Be7 5. Nf3 O-O 6. Ne5 c5 7. cxd5 exd5 8. Bd3 cxd4 9. exd4 Nc6 10. Bc2 h6 11. O-O Be6 12. f4 Nxe5 13. fxe5 Ne4 14. Nxe4 dxe4 15. Bxe4 Rc8 16. Be3 Bd5 17. Qf3 Bxe4 18. Qxe4 Bg5 19. Rac1 Bxe3+ 20. Qxe3 Rxc1 21. Rxc1 Qb6 22. Qc3 Qe6 23. a3 a6 24. h3 b5 25. Qc6 Qb3 26. Qc3 Qd5 27. Qc5 Qe4 28. Kh1 Qe3 29. Rc3 Qe1+ 30. Kh2 Qf2 31. Rc2 Qf4+ 32. Kg1 Rd8 33. d5 Qxe5 34. Qc6 Rxd5 35. Qc8+ Kh7 36. Rf2 Rd1+ 0-1",
    B: "1854",
  },
  {
    W: "1735",
    T: "120+1",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. Nf3 Bg4 5. Be2 Nc6 6. O-O O-O-O 7. a3 e6 8. b4 Bxb4 9. Rb1 Bd6 10. Bb5 Nd4 11. Be2 Nxe2+ 12. Qxe2 Qh5 13. Qb5 Bxf3 14. Qxh5 Bxh5 15. d3 Nf6 16. Bg5 a6 17. Ne4 Bxa3 18. Nxf6 gxf6 19. Bxf6 Rhg8 20. Bxd8 Rxd8 21. Rb3 Bd6 22. Rfb1 b6 23. d4 Rg8 24. Rh3 Bg6 25. Rb2 a5 26. c3 a4 27. Ra2 a3 28. c4 Bb4 29. Kf1 c5 30. d5 exd5 31. cxd5 Kd7 32. Ke2 Kd6 33. Rd3 Bxd3+ 34. Kxd3 Rxg2 35. Ke4 Rxh2 36. Kf5 Be1 37. Kg4 Rxf2 38. Rxf2 Bxf2 39. Kf3 Bd4 40. Ke4 a2 0-1",
    B: "2063",
  },
  {
    W: "1701",
    T: "300+4",
    P: "1. e4 e5 2. Nf3 Qf6 3. Nc3 Bc5 4. Nd5 Qd6 5. d4 exd4 6. Bf4 Qc6 7. Nxd4 Bxd4 8. Qxd4 Qxc2 9. Rc1 c5 10. Qe5+ 1-0",
    B: "1370",
  },
  {
    W: "1931",
    T: "180+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. d4 d6 5. exd6 exd6 6. Nf3 Bg4 7. Bd3 Be7 8. O-O Nc6 9. Be3 O-O 10. Nc3 Qd7 11. b3 f5 12. Be2 Rae8 13. Qc2 Bh5 14. a3 Bf6 15. Rad1 Bg6 16. Bf4 Re7 17. c5 Nc8 18. Bc4+ Kh8 19. b4 Bf7 20. d5 Ne5 21. Bxe5 dxe5 22. d6 cxd6 23. cxd6 Nxd6 24. Bxf7 Rexf7 25. Nd5 Qc6 26. Qb3 e4 27. Nxf6 exf3 28. Nh5 fxg2 29. Rfe1 Ne4 30. Qxf7 1-0",
    B: "1941",
  },
  {
    W: "1758",
    T: "180+0",
    P: "1. e4 Nc6 2. d4 d5 3. e5 b6 4. c4 Bb7 5. Nf3 e6 6. Nc3 Bb4 7. Bd2 Nge7 8. a3 Bxc3 9. Bxc3 O-O 10. cxd5 Nxd5 11. Be2 Nxc3 12. bxc3 Ne7 13. O-O Nd5 14. c4 Nc3 15. Qc2 Nxe2+ 16. Qxe2 Bxf3 17. Qxf3 Qxd4 18. Qc6 Qxe5 19. f4 Qd6 20. Qxd6 cxd6 21. Rfd1 Rfd8 22. a4 d5 23. cxd5 Rxd5 24. Rxd5 exd5 25. Rd1 Rd8 26. Kf2 Kf8 27. Ke3 Ke7 28. Kd4 Ke6 29. g4 f6 30. f5+ Kd6 31. h4 Rc8 32. g5 Rc4+ 33. Ke3 Rxa4 34. gxf6 gxf6 35. Rd4 1-0",
    B: "1755",
  },
  {
    W: "2135",
    T: "180+0",
    P: "1. e4 e6 2. d4 d5 3. c4 dxe4 4. Nc3 Bb4 5. f3 Nf6 6. Bg5 e3 7. Bxe3 O-O 8. Bd3 b6 9. Ne2 Bb7 10. O-O Nbd7 11. Qc2 c5 12. Rad1 Qe7 13. d5 exd5 14. Nxd5 Nxd5 15. cxd5 Nf6 16. Bg5 h6 17. Bh4 g5 18. Bf2 Rfe8 19. Ng3 c4 20. Nf5 Qc7 21. Nxh6+ Kg7 22. Nf5+ Kh8 23. Bd4 cxd3 24. Bxf6+ Kg8 25. Qxc7 1-0",
    B: "2064",
  },
  {
    W: "1600",
    T: "180+0",
    P: "1. e4 e5 2. f4 Nf6 3. Nc3 exf4 4. Nf3 Bc5 5. Bc4 Qe7 6. d4 Bb6 7. e5 Nh5 8. O-O Nc6 9. Ne1 d5 10. Bxd5 Bd7 11. Qxh5 O-O-O 12. Bxc6 Bxc6 13. Bxf4 Bxd4+ 14. Kh1 g6 15. Qg5 f6 16. exf6 Bxf6 17. Qa5 g5 18. Bg3 Rhf8 19. Nd3 Bxg2+ 20. Kxg2 Bxc3 21. bxc3 Qe4+ 22. Kg1 Rxf1+ 23. Rxf1 b6 24. Qe5 Qc4 25. Nb4 a5 26. Na6 Kb7 27. Nc5+ Qxc5+ 28. Qxc5 bxc5 29. Rb1+ Kc6 30. a4 Rd2 31. Rc1 h5 32. h3 h4 33. Be1 Re2 34. Kf1 Re3 35. Kf2 Rxh3 36. Kg2 Re3 37. Kf2 Re5 38. Bd2 Rf5+ 39. Kg2 g4 40. Rf1 h3+ 41. Kg1 Rd5 42. Bf4 Rd2 43. Bxd2 g3 44. Rf3 h2+ 45. Kh1 g2+ 46. Kxg2 h1=Q+ 47. Kxh1 Kb7 48. Rf6 Ka7 49. Bf4 Kb7 50. Kg2 Kc8 51. Kg3 Kd7 52. Kg4 Ke7 53. Kg5 Kd7 54. c4 Ke7 55. Rf5 Kd7 56. Rxc5 Kc8 57. Rxa5 Kb7 58. Rc5 Kb6 59. Re5 Kc6 60. a5 Kd6 61. c5+ Kc6 62. a6 Kb5 63. a7 1-0",
    B: "1638",
  },
  {
    W: "1889",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 d5 3. exd5 e4 4. Qe2 Nf6 5. d3 Bf5 6. dxe4 Nxe4 7. Nc3 Qe7 8. Ng5 Nxc3 9. Qxe7+ Bxe7 10. bxc3 Bxc2 11. Bb5+ c6 12. dxc6 bxc6 13. Ba4 O-O 14. Bf4 Bd3 15. Bxb8 Raxb8 16. Nf3 Rb2 17. Nd4 c5 18. Nc6 Re2+ 19. Kd1 Bg5 20. Re1 Rd2+ 21. Kc1 Rxa2+ 22. Kd1 Rxa1# 0-1",
    B: "1902",
  },
  {
    W: "2005",
    T: "180+0",
    P: "1. d4 d5 2. Bf4 Nf6 3. Nf3 c6 4. e3 h6 5. Bd3 Nbd7 6. h3 Nb6 7. c3 g5 8. Bh2 Bg7 9. Nbd2 g4 10. Ne5 gxh3 11. gxh3 Be6 12. f4 Qc8 13. h4 h5 14. Ndf3 Ng4 15. Ng5 Nxe3 16. Qe2 Ng4 17. O-O-O Nxe5 18. fxe5 Bg4 19. Qf2 O-O 20. Rdf1 f6 21. exf6 exf6 22. Nf3 Nc4 23. Bxc4 dxc4 24. Nd2 b5 25. Qg2 a5 26. Rfg1 Ra6 27. Bd6 Rf7 28. Nf3 b4 29. cxb4 axb4 30. Bxb4 Rxa2 31. Bc3 Ra1+ 32. Kc2 Qf5+ 33. Kd2 Qd3# 0-1",
    B: "1966",
  },
  {
    W: "1653",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. O-O Bc5 5. h3 h6 6. c3 O-O 7. d4 exd4 8. cxd4 Bb6 9. Nc3 d6 10. Bf4 Re8 11. Re1 Na5 12. Bb5 c6 13. Ba4 Bc7 14. b4 Nc4 15. Bb3 Nb6 16. d5 a5 17. b5 a4 18. Bxa4 Nxa4 19. Nxa4 cxb5 20. Nc3 b4 21. Nb5 Nxe4 22. Qd4 Bb6 23. Qxb4 Bxf2+ 24. Kh1 Bxe1 25. Rxe1 Nc5 26. Rxe8+ Qxe8 27. Nc7 Qa4 28. Qb6 Qd1+ 29. Kh2 Rxa2 30. Qxd6 Qe2 31. Qd8+ Kh7 32. Nh4 g5 33. Be5 gxh4 34. Qh8+ Kg6 35. Qg7+ Kf5 36. Qf6+ Ke4 37. Qf4+ Kd3 38. Qd4+ 1-0",
    B: "1688",
  },
  {
    W: "1867",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 d6 4. d4 exd4 5. Nxd4 Nf6 6. Nxc6 bxc6 7. Bd3 Be7 8. O-O Be6 9. f4 O-O 10. e5 dxe5 11. fxe5 Nd5 12. Nxd5 cxd5 13. Qh5 g6 14. Qe2 Re8 15. Bh6 Bf8 16. Bf4 c5 17. Qf2 c4 18. Be2 d4 19. Qg3 Bg7 20. Bg5 Qc7 21. Bf6 Bxf6 22. Rxf6 Bf5 23. Bf3 Rad8 24. Rc1 Qxe5 25. Qxe5 Rxe5 26. Ra6 Rd7 27. h3 d3 28. cxd3 Bxd3 29. Bc6 Rde7 30. a4 c3 31. bxc3 Bxa6 32. c4 Re1+ 33. Kf2 Rxc1 0-1",
    B: "1867",
  },
  {
    W: "1847",
    T: "180+0",
    P: "1. c4 d5 2. cxd5 Qxd5 3. Nc3 Qd6 4. Nf3 Nf6 5. g3 c6 6. Bg2 Bg4 7. O-O e5 8. d3 Be7 9. h3 Be6 10. g4 h6 11. Rb1 e4 12. dxe4 Nxe4 13. Nxe4 1-0",
    B: "1839",
  },
  {
    W: "1868",
    T: "180+0",
    P: "1. d4 e6 2. e4 d5 3. Nc3 c5 4. dxc5 d4 5. Nb5 Bxc5 6. Nf3 Nc6 7. Bc4 Nf6 8. e5 Ng4 9. O-O O-O 10. Qe1 a6 11. Na3 b5 12. Bd3 Qc7 13. Bf4 Bb7 14. h3 Nh6 15. Qe4 Nf5 16. Qe2 Nb4 17. Rfe1 Nxd3 18. Qxd3 g6 19. Bg5 Be7 20. Bh6 Rfd8 21. Bf4 Bxa3 22. bxa3 Qc5 23. g4 Ng7 24. Bh6 Qc3 25. Qxc3 dxc3 26. Nh4 Rd4 27. Rad1 Rc8 28. Rxd4 1-0",
    B: "1870",
  },
  {
    W: "1827",
    T: "180+0",
    P: "1. e4 e6 2. d4 d5 3. exd5 exd5 4. Nf3 Bd6 5. h3 Ne7 6. c4 c6 7. Nc3 O-O 8. Be2 dxc4 9. Bxc4 Nf5 10. Bg5 Re8+ 11. Be2 Qb6 12. Rb1 h6 13. Bd2 Nxd4 14. Nxd4 Qxd4 15. O-O Nd7 16. Qc1 Nc5 17. Be3 Qe5 18. Bc4 Qh2# 0-1",
    B: "1806",
  },
  {
    W: "1724",
    T: "180+0",
    P: "1. e4 d5 2. Nf3 dxe4 3. Ng5 Bf5 4. Nc3 e6 5. Ngxe4 Bxe4 6. Nxe4 Nc6 7. Bb5 Ne7 8. O-O g6 9. d4 Bg7 10. Be3 O-O 11. Qd2 Nxd4 12. Bxd4 Qxd4 13. Qxd4 Bxd4 14. c3 Bg7 15. Nc5 a6 16. Ba4 b5 17. Bb3 Rfd8 18. Rad1 c6 19. Ne4 Nd5 20. f3 Rd7 21. Rd2 Rad8 22. Rfd1 Bh6 23. Rd3 Bg7 24. Nc5 Ra7 25. Ne4 f5 26. Nc5 a5 27. Nxe6 Re8 28. Nxg7 Kxg7 29. Bxd5 cxd5 30. Rxd5 b4 31. cxb4 axb4 32. Rd7+ Rxd7 33. Rxd7+ Kh6 34. Rb7 Re1+ 35. Kf2 Ra1 36. Rxb4 Rxa2 37. Ke2 Kg5 38. Rb8 h5 39. Kf2 Ra1 40. Kg3 Rf1 41. b4 f4+ 42. Kh3 Kf5 43. Rb5+ Kf6 44. Rb6+ Kf5 45. Rb5+ Kf6 46. Rb8 Rf2 47. b5 g5 48. b6 Kf5 49. Rf8+ Kg6 50. b7 g4+ 51. fxg4 hxg4+ 52. Kxg4 Rxg2+ 53. Kxf4 Rf2+ 54. Ke5 Rxf8 55. Kd6 Rb8 56. Kc7 Rxb7+ 57. Kxb7 Kg5 58. Kc6 Kh4 59. Kd5 Kh3 60. Ke4 Kxh2 1/2-1/2",
    B: "1731",
  },
  {
    W: "1717",
    T: "180+0",
    P: "1. Nf3 e6 2. e4 d5 3. d3 dxe4 4. Ne5 exd3 5. Bxd3 Nf6 6. O-O Bd6 7. Nc4 O-O 8. Nxd6 cxd6 9. Nc3 d5 10. Bg5 h6 11. Bxf6 Qxf6 12. Nb5 Na6 13. a3 Bd7 14. c4 Bxb5 15. cxb5 Nc7 16. a4 e5 17. b3 b6 18. Be2 Rad8 19. Bf3 d4 20. Bc6 Nd5 21. Bxd5 Rxd5 22. g3 Re8 23. Rc1 Rd7 24. f4 e4 25. Qe2 d3 26. Qd2 e3 27. Qc3 e2 28. Rfe1 Qxc3 29. Rxc3 d2 30. Rxe2 d1=Q+ 0-1",
    B: "1718",
  },
  {
    W: "1457",
    T: "180+0",
    P: "1. Nf3 Nc6 2. d4 d5 3. c3 e6 4. g3 Nf6 5. Bg2 Bd6 6. O-O O-O 7. Bg5 h6 8. Bxf6 Qxf6 9. Nbd2 e5 10. dxe5 Nxe5 11. Re1 Nxf3+ 12. Nxf3 Bg4 13. Qxd5 Rad8 14. Qxb7 a5 15. Qe4 Bf5 16. Qe3 Rfe8 17. Qd2 Qe6 18. Nd4 Qf6 19. e4 Bg6 20. f4 Bc5 21. e5 Qb6 22. Rad1 Rd7 23. Kh1 Red8 24. Qe2 Bxd4 25. cxd4 Rxd4 26. Rxd4 Qxd4 27. Be4 Bxe4+ 28. Qxe4 Qxe4+ 29. Rxe4 Rd2 30. Rc4 Rxb2 31. Rxc7 Rxa2 32. Rc8+ Kh7 33. h4 Kg6 34. Rf8 a4 35. h5+ Kxh5 36. Rxf7 Kg6 37. Rf8 a3 38. e6 Ra1+ 39. Kh2 a2 40. e7 Re1 41. Ra8 Rxe7 42. Rxa2 Re3 43. Ra6+ Kh7 44. Kh3 g5 45. f5 h5 46. Rg6 g4+ 47. Kh4 Rf3 48. Kg5 Rxf5+ 49. Kxf5 h4 50. Kxg4 h3 51. Rg5 h2 52. Rh5+ Kg6 53. Rxh2 1-0",
    B: "1492",
  },
  {
    W: "1277",
    T: "180+0",
    P: "1. Nf3 d5 2. d4 Bf5 3. e3 Nd7 4. Nbd2 e6 5. c3 Bd6 6. g3 f6 7. Nh4 Be4 8. f3 Bg6 9. Nxg6 hxg6 10. Bg2 e5 11. Nb3 e4 12. Bd2 f5 13. Qe2 Ngf6 14. O-O-O Nh5 15. g4 fxg4 16. fxg4 Nhf6 17. Rdf1 Qe7 18. Be1 Qe5 19. h3 1-0",
    B: "1224",
  },
  {
    W: "1916",
    T: "120+1",
    P: "1. e4 c5 2. Nc3 Nf6 3. f4 g6 4. Nf3 Bg7 5. e5 Ng8 6. h4 h5 7. Bc4 Nh6 8. O-O a6 9. a4 O-O 10. Ng5 Nc6 11. f5 Kh8 12. fxg6 fxg6 13. Rxf8+ Qxf8 14. Nce4 Nxe5 15. Be2 Neg4 16. Nf3 d6 17. d3 b6 18. Bxh6 Bxh6 19. Neg5 Bd7 20. Qd2 e5 21. Rf1 Qe7 22. Nh2 Rf8 23. Nxg4 Rxf1+ 24. Kxf1 Bxg4 25. Bxg4 hxg4 26. Kg1 g3 27. Nf7+ Qxf7 28. Qxh6+ Kg8 29. Qe3 Qf4 30. Qxf4 exf4 31. Kf1 Kf7 32. Ke2 Kf6 33. Kf3 Kf5 34. c4 g5 35. hxg5 Kxg5 36. b3 Kf5 0-1",
    B: "1889",
  },
  {
    W: "2167",
    T: "60+0",
    P: "1. Nf3 d6 2. b3 Nd7 3. Bb2 e5 4. e3 g6 5. d3 Bg7 6. Be2 h6 7. c4 Ne7 8. Nc3 O-O 9. O-O f5 10. d4 e4 11. Nd2 g5 12. d5 Ne5 13. h3 N7g6 14. Bh5 Qf6 15. Bxg6 Qxg6 16. Nb5 Qf7 17. Bxe5 dxe5 18. f3 a6 19. Nc3 g4 20. fxg4 Qg6 21. gxf5 Bxf5 22. Kh2 Rf7 23. Qe1 Raf8 24. Qg3 Qh5 25. Ndxe4 Bxe4 26. Rxf7 Rxf7 27. Nxe4 Qf5 28. Nc3 e4 29. Rd1 Kh7 30. Na4 Be5 31. h4 Bxg3+ 32. Kxg3 Qg6+ 33. Kh3 Qf6 34. Rg1 Qf5+ 35. g4 Qf3+ 36. Rg3 Qf1+ 37. Kh2 Rf3 38. Rg2 Rf2 39. Rxf2 Qxf2+ 40. Kh3 Qf3+ 41. Kh2 Qxe3 0-1",
    B: "2169",
  },
  {
    W: "698",
    T: "180+2",
    P: "1. d4 e5 2. Nc3 exd4 3. Nd5 c6 4. Nf4 Bd6 5. g3 c5 6. Nf3 Nc6 7. Nd5 b6 8. Bg5 Nf6 9. Nxf6+ gxf6 10. Bh4 a5 11. e3 dxe3 12. fxe3 Ba6 13. Bxa6 Rxa6 14. Qxd6 Qe7 15. Qxe7+ Nxe7 16. O-O-O O-O 17. Bxf6 Nf5 18. Rxd7 Nxe3 19. a3 b5 20. b4 Rxf6 21. Ra7 Rxf3 22. Rxa5 Rf1+ 23. Kd2 Rxh1 24. Kxe3 Re1+ 25. Kf2 Rc1 26. Rxb5 Rc8 27. Ke2 Rxc2+ 28. Kd3 cxb4 29. axb4 Rxh2 30. Rb7 Rd8+ 0-1",
    B: "851",
  },
  {
    W: "1672",
    T: "180+2",
    P: "1. e4 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qe3 Nf6 5. Nc3 d6 6. h3 g6 7. Bb5 Bd7 8. Nf3 Bg7 9. Bd2 O-O 10. a3 a6 11. Bxc6 Bxc6 12. O-O-O b5 13. Na2 Bxe4 14. g4 Qc7 15. Bc3 Bxf3 16. Qxf3 Rac8 17. h4 e5 18. g5 Nd7 19. h5 e4 20. Qxe4 Bxc3 21. Nxc3 Nc5 22. Qd4 Na4 23. hxg6 fxg6 24. Nxa4 bxa4 25. c3 Qa5 26. Rh3 Qxg5+ 27. Kb1 Qf5+ 28. Qd3 Qxf2 29. Rdh1 Rb8 30. Qd5+ Rf7 31. R3h2 Qf5+ 32. Ka2 Qxd5+ 33. Kb1 Qd3+ 34. Ka1 Rf1+ 35. Rxf1 Qxf1+ 36. Ka2 Qc4+ 37. Kb1 Qe4+ 38. Ka2 Rb3 39. Rc2 Qxc2 0-1",
    B: "1619",
  },
  {
    W: "1598",
    T: "180+2",
    P: "1. d4 Nf6 2. Nc3 g6 3. e4 d6 4. Bg5 Bg7 5. e5 dxe5 6. Bxf6 Bxf6 7. dxe5 Qxd1+ 8. Nxd1 Bxe5 9. Nf3 Bxb2 10. Nxb2 O-O 11. Nd3 Nc6 12. Nde5 Nb4 13. Bd3 Bf5 14. Bxf5 gxf5 15. O-O Nxc2 16. Rac1 Nb4 17. Rxc7 Nxa2 18. Rxb7 f6 19. Nc6 a5 20. Rxe7 Nb4 21. Nxb4 axb4 22. Rb1 b3 23. Rb7 Rfc8 24. R7xb3 Rc2 25. h3 Raa2 26. Rf1 Kg7 27. Nd4 Rd2 28. Ne6+ Kh6 29. Rg3 f4 30. Nxf4 Rd4 31. Rg4 f5 32. Ne6 fxg4 33. Nxd4 gxh3 34. gxh3 Ra4 35. Nf3 Ra5 36. Rd1 Kh5 37. Kh2 Rf5 38. Rd3 Kh6 39. Kg3 Kg7 40. Kg4 Kf6 41. Rd6+ Ke7 42. Rh6 Rf7 43. Kg3 Rg7+ 44. Kf4 Kf7 45. Ne5+ Kg8 46. f3 Kf8 47. Rf6+ Kg8 48. Rb6 h5 49. Rb8+ Kh7 50. h4 Kh6 51. Rb6+ Kh7 52. Rd6 Kg8 53. Rd8+ Kh7 54. Rd7 Kg8 55. Rxg7+ Kxg7 56. Kg5 Kh7 57. f4 Kg7 58. f5 Kh7 59. f6 Kg8 60. Kg6 Kh8 61. Nf3 Kg8 62. Ng5 Kf8 63. Ne6+ Kg8 64. Nf4 Kf8 65. Nxh5 Kg8 66. Nf4 Kf8 67. h5 Kg8 68. h6 Kh8 69. Ne6 Kg8 70. Ng5 Kf8 71. h7 Ke8 72. h8=Q+ Kd7 73. Qh3+ Ke8 74. Qe6+ Kf8 75. Qf7# 1-0",
    B: "1545",
  },
  {
    W: "1758",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Nxc6 Qf6 6. Nc3 dxc6 7. Qe2 Be6 8. Be3 Bb4 9. Bd2 Ne7 10. O-O-O O-O 11. h4 Rfd8 12. g4 Bxc3 13. Bxc3 Qf4+ 14. Kb1 Rxd1+ 15. Qxd1 Qxf2 16. Bd3 Rd8 17. Qc1 c5 18. h5 c4 19. Bxg7 Kxg7 20. Qg5+ Ng6 21. hxg6 fxg6 22. Qh6+ Kf6 23. Rf1 Qxf1+ 24. Bxf1 Rd1+ 25. Qc1 Rxc1+ 26. Kxc1 Bxg4 27. Bxc4 h5 28. Kd2 h4 29. Ke3 h3 30. Kf2 g5 31. Kg3 Bc8 32. Be2 Ke5 33. Bf3 g4 34. Bxg4 Bxg4 0-1",
    B: "1783",
  },
  {
    W: "2353",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. d3 h6 7. Nf3 e4 8. dxe4 Nxc4 9. Qd4 Nd6 10. Nbd2 Be7 11. O-O O-O 12. c4 b6 13. b3 Nd7 14. Bb2 Ne8 15. e5 a5 16. Ne4 Nc5 17. Rad1 Bf5 18. Ng3 Bg6 19. Rfe1 Qc8 20. h4 h5 21. e6 Bf6 22. Qd2 fxe6 23. Bxf6 Rxf6 24. Qg5 exd5 25. Ne5 Ne6 26. Nxg6 Nxg5 27. Ne7+ Kf8 28. Nxc8 Ne4 29. Nxe4 dxe4 30. Rd8 Re6 31. Re3 g6 32. f3 a4 33. fxe4 axb3 34. axb3 Ra3 35. e5 Ra2 36. Kh2 Rf2 37. Kg3 Rf5 38. Na7 Rfxe5 39. Rf3+ Kg7 40. Nb5 Re7 41. Rfd3 Nf6 42. Rc8 Re3+ 43. Rxe3 Rxe3+ 44. Kf2 Rxb3 45. Rxc7+ Kh6 46. Nd6 Ng4+ 47. Ke2 Rb2+ 48. Kd3 Rxg2 49. Nf7+ Kg7 50. Ng5+ Kf6 51. Rc6+ Kf5 52. Rxb6 Ne5+ 53. Kc3 Rg3+ 54. Kb4 Rg4 55. Kb3 Rxc4 56. Rb5 Rd4 57. Nf7 Re4 58. Kc2 Kf4 59. Nd6 Rd4 60. Ne8 Kf5 61. Ng7+ Kf6 62. Ne8+ Ke6 63. Ng7+ Kd6 64. Ne8+ Kd7 65. Rxe5 Rxh4 66. Nf6+ Kd6 67. Re8 Rf4 68. Nh7 h4 69. Ng5 Rf5 70. Nh3 g5 71. Rg8 Ke5 72. Nxg5 Kf4 73. Nh3+ Kf3 74. Kd3 Re5 75. Ng5+ Kf4 76. Nh3+ Kf3 77. Ng1+ Kf2 78. Ne2 Rxe2 79. Rf8+ Ke1 80. Rh8 Rh2 81. Ke3 Kf1 82. Kf3 h3 83. Kg3 Kg1 84. Rxh3 Rxh3+ 85. Kxh3 1/2-1/2",
    B: "2311",
  },
  {
    W: "1736",
    T: "180+2",
    P: "1. d4 Nf6 2. e3 d5 3. Bd3 c5 4. c3 Nc6 5. Ne2 cxd4 6. exd4 e6 7. O-O Bd6 8. f4 Bc7 9. Nd2 Ng4 10. Nf3 Qf6 11. h3 Nh6 12. Ne5 Nf5 13. Qe1 O-O 14. Ng3 Re8 15. Ng4 Qd8 16. Ne5 Nd6 17. Bc2 Nc4 18. Qe2 f6 19. Nxc4 dxc4 20. Qxc4 Ne7 21. Bd2 Nf5 22. Nxf5 b5 23. Qb3 a5 24. Ne3 Bb7 25. Bb1 Rf8 26. Qxb5 Rb8 27. Qb3 Qe8 28. Qc2 Qc6 29. Qxh7+ Kf7 30. Bg6+ Ke7 31. Qxg7+ Kd6 32. Bh5 Rg8 33. Bf3 Qxf3 34. gxf3 Rxg7+ 35. Kf2 Rbg8 36. Rg1 Ke7 37. Rxg7+ Rxg7 38. Ng4 f5 39. Ne5 Rh7 40. Rh1 Bd6 41. h4 Bd5 42. a3 a4 43. Rh3 Rg7 44. h5 Rh7 45. c4 Bxe5 46. cxd5 Bxd4+ 47. Be3 Bxb2 48. Bc5+ Kd7 49. dxe6+ Kxe6 50. Ke3 Rc7 51. Bb4 Rc4 52. h6 Rc8 53. h7 Re8 54. Kd3 Kd5 55. Bc3 Bxa3 56. h8=Q Rxh8 57. Rxh8 Bc5 58. Ra8 a3 59. Ra5 Kc6 60. Bd4 1-0",
    B: "1672",
  },
  {
    W: "1480",
    T: "180+2",
    P: "1. e4 d5 2. Nf3 dxe4 3. Ng5 Nf6 4. d3 h6 5. Nxe4 Nxe4 6. dxe4 Qxd1+ 7. Kxd1 Nc6 8. Bd3 e5 9. Nc3 Be6 10. f4 O-O-O 11. fxe5 Nxe5 12. Ke2 Bg4+ 13. Ke3 Bc5+ 14. Kd2 Rd7 15. Re1 Rhd8 16. b4 Bxb4 17. Re3 Bc5 18. Rg3 g5 19. h3 Nxd3 20. Rxd3 Rxd3+ 21. cxd3 Bh5 22. Rb1 Bg6 23. Nd5 c6 24. Nc3 Bb6 25. Ba3 f5 26. exf5 Bxf5 27. Kc2 Bxd3+ 28. Kb2 Bxb1 29. Kxb1 Rd2 30. Bf8 Rxg2 31. Bxh6 1-0",
    B: "1429",
  },
  {
    W: "1779",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nc3 Nc6 4. Bc4 Bb4 5. d3 O-O 6. O-O d6 7. Bg5 h6 8. Bxf6 Qxf6 9. Nd5 Qd8 10. c3 Ba5 11. b4 Bb6 12. a4 Ne7 13. a5 c6 14. Nxe7+ Qxe7 15. axb6 a6 16. b5 Bg4 17. bxa6 bxa6 18. Bxa6 d5 19. h3 Bh5 20. g4 Bg6 21. exd5 cxd5 22. Re1 f6 23. c4 d4 24. Qc2 Qc5 25. Bb7 Rxa1 26. Bd5+ Kh8 27. Rxa1 Qxb6 28. Nh4 Bh7 29. Rb1 Qa5 30. Rb7 Qe1+ 31. Kg2 Qc3 32. Qb3 Bxd3 33. Qb5 Qe1 34. Qd7 Qf1+ 35. Kg3 Qg1+ 36. Ng2 1-0",
    B: "1787",
  },
  {
    W: "1345",
    T: "180+2",
    P: "1. e3 d5 2. c3 c5 3. Bd3 Nc6 4. Bc2 Nf6 5. d3 Bg4 6. Ne2 e6 7. h3 Bh5 8. g4 Bg6 9. b3 Bd6 10. Nd2 O-O 11. Bb2 Rc8 12. Ng3 Re8 13. Qe2 e5 14. O-O-O b5 15. a3 a5 16. Qf1 b4 17. a4 bxc3 18. Bxc3 d4 19. exd4 cxd4 20. Bb2 Nb4 21. Nc4 Bf8 22. Rd2 Nfd5 23. Kb1 Nc3+ 24. Bxc3 dxc3 25. Rd1 1-0",
    B: "1363",
  },
  {
    W: "2086",
    T: "180+2",
    P: "1. e4 c6 2. d4 d5 3. f3 g6 4. e5 c5 5. f4 cxd4 6. Nf3 Bg4 7. Nbd2 Nc6 8. h3 Bxf3 9. Nxf3 Qb6 10. Bd3 e6 11. O-O Nge7 12. Kh2 h5 13. a4 a5 14. Rb1 Bh6 15. g3 O-O-O 16. Ra1 Kb8 17. Ra3 Rc8 18. Rb3 Qc7 19. Bd2 Nf5 20. Qe1 Ka8 21. Rb5 b6 22. b4 axb4 23. Bxb4 Ne3 24. Rf2 h4 25. Bd6 hxg3+ 26. Kxg3 Qd8 27. h4 Bxf4+ 28. Kxf4 Rxh4+ 29. Kg3 Rg4+ 30. Kh2 Qh8+ 0-1",
    B: "2175",
  },
  {
    W: "1781",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 1-0",
    B: "1722",
  },
  {
    W: "1652",
    T: "180+0",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 d6 4. Nf3 Nc6 5. Bb5 Bd7 6. Bxc6 Bxc6 7. Nc3 Nf6 8. O-O Be7 9. Bg5 h6 10. Bh4 Qd7 11. Rad1 a6 12. e5 dxe5 13. Qxd7+ Bxd7 14. Nxe5 O-O 15. Bxf6 Bxf6 16. Nxd7 Rfe8 17. Nxf6+ gxf6 18. Rfe1 Rf8 19. Nd5 Rac8 20. Re7 Kg7 21. Rxc7 Rcd8 22. Ne3 Rxd1+ 23. Nxd1 Re8 24. Ne3 f5 25. g3 h5 26. Nxf5+ Kf6 27. Ne3 Kg5 28. Rxb7 1-0",
    B: "1504",
  },
  {
    W: "1725",
    T: "180+0",
    P: "1. d4 d5 2. c4 e6 3. Nf3 Nf6 4. a3 c6 5. e3 Bd6 6. Nc3 Nbd7 7. Bd3 dxc4 8. Bxc4 b5 9. Ba2 a5 10. O-O a4 11. Re1 O-O 12. e4 e5 13. Be3 exd4 14. Bxd4 c5 15. Bxf6 Nxf6 16. e5 Re8 17. exd6 Rxe1+ 18. Nxe1 Bg4 19. f3 Bf5 20. Nxb5 Qb6 21. Bc4 Rd8 22. Rc1 h6 23. Nd3 Nd7 24. Qe2 Bxd3 25. Qxd3 Ne5 26. Bd5 Nxd3 27. Rd1 Nf4 28. Bc4 Kf8 29. d7 Ne6 30. g3 Ke7 31. f4 Rxd7 32. Rxd7+ Kxd7 33. f5 Ng5 34. Kg2 f6 35. Nc3 Qc6+ 36. Bd5 Qd6 37. Be6+ Nxe6 38. fxe6+ Qxe6 0-1",
    B: "1774",
  },
  {
    W: "1451",
    T: "180+0",
    P: "1. c3 e6 2. Qc2 d5 3. d4 h6 4. Bf4 Nf6 5. e3 Nbd7 6. Nf3 Bd6 7. Ne5 Bxe5 8. dxe5 Ne4 9. f3 Nec5 10. b4 Na6 11. Bxa6 bxa6 12. O-O f6 13. exf6 Nxf6 14. Nd2 g5 15. Be5 Rf8 16. Rab1 Nd7 17. Bg7 Rf7 18. Bxh6 Bb7 19. f4 gxf4 20. Bxf4 c5 21. Qg6 Qe7 22. bxc5 O-O-O 23. Bd6 Qe8 24. Qg3 Rxf1+ 25. Rxf1 Bc6 26. Nb3 Nxc5 27. Bxc5 Rd7 28. Na5 Bb5 29. Re1 Rc7 30. Bd4 Qc6 31. Nxc6 Bxc6 32. Rb1 Kd7 33. Qg7+ Kd6 34. Be5+ Kc5 35. Bxc7 Ba4 36. Qd4+ Kc6 37. Be5 Kd7 38. Qxa7+ Ke8 39. Qxa6 Bc2 40. Rb8+ Ke7 41. Qd6+ Kf7 42. Rb7+ Kg6 43. Qxe6+ Kg5 44. Rg7+ Kh5 45. g4+ Kh4 46. Qh6# 1-0",
    B: "1199",
  },
  {
    W: "1665",
    T: "180+0",
    P: "1. e4 Nf6 2. Nc3 e6 3. d4 Bb4 4. Bd3 d5 5. e5 Ne4 6. Bd2 Nxf2 7. Kxf2 Qh4+ 8. g3 Qe7 9. Qf3 f6 10. Nge2 O-O 11. Kg2 fxe5 12. Qg4 e4 13. Bxe4 dxe4 14. Nxe4 Bxd2 15. Nxd2 e5 16. Qe4 Bf5 17. Qxe5 Qxe5 18. dxe5 Bxc2 19. Nf3 Be4 20. Rhf1 Nd7 21. Nd4 Nxe5 22. Kg1 Bxf3 23. Nxf3 Rxf3 24. Rf2 Rxf2 25. Kxf2 Nd3+ 26. Ke3 Nxb2 27. Rb1 Nc4+ 28. Kd4 Nb6 29. Kc5 Rd8 30. a3 Rd5+ 31. Kb4 a5+ 32. Kc3 Na4+ 33. Kb3 b5 34. Re1 Rd4 35. Re7 c5 36. Rc7 c4+ 37. Kc2 Nb6 38. Rb7 Rd6 39. h3 b4 40. axb4 axb4 41. g4 b3+ 42. Kb2 Rd2+ 43. Ka3 Ra2+ 44. Kb4 b2 45. Rxb6 b1=Q+ 46. Kc5 Ra5+ 47. Kc6 Qe4+ 48. Kc7 Rc5+ 49. Kd7 Qd4+ 50. Rd6 Rd5 51. Rxd5 Qxd5+ 52. Ke7 Qf3 53. Ke6 Qxh3 54. Kf5 c3 55. Kg5 c2 56. Kf4 c1=Q+ 57. Kf5 g6+ 58. Kf6 Qcf1+ 59. Ke7 Qe3+ 60. Kd6 Qd1+ 61. Kc6 Qc3+ 62. Kb5 Qb1+ 0-1",
    B: "1715",
  },
  {
    W: "1171",
    T: "180+0",
    P: "1. e3 e6 2. Qf3 Nc6 3. b3 b6 4. Bb2 Bb7 5. Bd3 Nf6 6. Na3 Bxa3 7. Bxa3 d6 8. Bb2 Qe7 9. O-O-O O-O-O 10. a4 a5 11. h4 Nd5 12. Bxg7 Rhg8 13. Bb2 Ndb4 14. Bxh7 Na2+ 15. Kb1 Ncb4 16. Bf6 Bxf3 17. Bxe7 Bxd1 18. Bxd8 Bxc2+ 19. Kb2 Rxg2 20. Bxc2 Kxd8 21. Be4 Rxf2 22. h5 Rxd2+ 23. Kb1 Nc3+ 24. Kc1 Rd1+ 25. Kb2 Nxe4 26. h6 Nf6 27. h7 Nxh7 28. Rxh7 Rxg1 29. Rh8+ Ke7 30. Rc8 Kd7 31. Rf8 Rg2+ 32. Kc3 Rf2 33. Kd4 e5+ 34. Ke4 f5+ 35. Rxf5 Rxf5 36. Kxf5 c5 37. Ke4 Ke6 38. Kf3 d5 39. Ke2 d4 40. exd4 exd4 41. Kf3 Kd5 42. Ke2 c4 43. Kd2 c3+ 44. Kc1 d3 45. Kd1 Kd4 46. Kc1 Ke3 47. Kd1 c2+ 48. Kc1 d2+ 49. Kb2 d1=Q 50. Ka3 Qa1# 0-1",
    B: "1821",
  },
  {
    W: "1828",
    T: "180+0",
    P: "1. e4 c5 2. Bc4 e6 3. Nf3 a6 4. a4 Nc6 5. c3 Rb8 6. d4 b5 7. axb5 axb5 8. Ba2 cxd4 9. cxd4 Bb4+ 10. Nc3 Nf6 11. O-O Bxc3 12. bxc3 Nxe4 13. d5 Nxc3 14. Qb3 Nxd5 15. Re1 Nf6 16. Bb2 O-O 17. Rad1 d5 18. Bxf6 Qxf6 19. Qc2 Bd7 20. Bb1 g6 21. h3 Rbc8 22. Qc5 Ne7 23. Qd6 Bc6 24. Ne5 Nf5 25. Qb4 Be8 26. Ng4 Qg5 27. Bxf5 exf5 28. f4 Qh5 29. Nf6+ Kg7 30. Nxh5+ gxh5 31. Qe7 Kg6 32. Qg5# 1-0",
    B: "1795",
  },
  {
    W: "2040",
    T: "180+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 Nf6 4. e5 Qe7 5. d4 d6 6. Bxf4 dxe5 7. dxe5 Bg4 8. Be2 Bxf3 9. Bxf3 Nbd7 10. Nc3 Nxe5 11. O-O Nxf3+ 12. Qxf3 Qc5+ 13. Kh1 Qb6 14. Rae1+ Be7 15. Bg5 h6 16. Bh4 Qc6 17. Bxf6 gxf6 18. Nd5 Kd8 19. Rxe7 Rg8 20. Rxf7 1-0",
    B: "1993",
  },
  {
    W: "2069",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4 O-O 6. Nf3 c5 7. d5 e6 8. Be2 exd5 9. exd5 Re8 10. O-O Bg4 11. h3 Bf5 12. Nh4 Be4 13. f5 Nxd5 14. Nxd5 Bxd5 15. Qxd5 Qxh4 16. fxg6 hxg6 17. Qxf7+ 1-0",
    B: "2077",
  },
  {
    W: "1401",
    T: "60+0",
    P: "1. e4 e6 2. d3 d5 3. f4 c5 4. e5 Nc6 5. Nf3 Qa5+ 6. c3 d4 7. Bd2 Nge7 8. b4 cxb4 9. cxd4 Nd5 10. Be2 Qb6 11. O-O b3 12. axb3 Bb4 13. Bxb4 Qxb4 14. Ra4 Qb6 15. Rc4 Nxd4 16. Rxc8+ Rxc8 17. Nxd4 Qxd4+ 18. Rf2 Ne3 19. Qf1 Nf5 20. h3 O-O 21. g4 Ng3 22. Qd1 Nxe2+ 23. Qxe2 Rc3 24. Nxc3 Qxc3 25. f5 Qd4 26. fxe6 fxe6 27. Kf1 Rxf2+ 28. Qxf2 Qxf2+ 29. Kxf2 Kf7 30. Ke3 Kg6 31. Ke4 Kg5 32. b4 Kh4 33. Kd4 Kxh3 34. Kc5 Kg3 35. Kd6 Kxg4 36. Kxe6 h5 37. Kd6 h4 38. e6 h3 39. e7 1-0",
    B: "1433",
  },
  {
    W: "1560",
    T: "180+2",
    P: "1. d4 d5 2. Nf3 c5 3. c3 Nf6 4. e3 e6 5. Bd3 c4 6. Bc2 b5 7. a3 Nc6 8. Nbd2 a5 9. e4 b4 10. e5 Nd7 11. axb4 Bb7 12. O-O axb4 13. Rxa8 Qxa8 14. Re1 bxc3 15. bxc3 Be7 16. Nf1 Qa1 17. Ng5 h6 18. Nf3 Qxc3 19. Bd2 Qb2 20. Ng3 O-O 21. Bxh6 gxh6 22. Qd2 Bg5 23. Nxg5 Qxd4 24. Qe2 hxg5 25. Qh5 f5 26. exf6 Nxf6 27. Qxg5+ Kf7 28. Qg6+ Ke7 29. Nf5+ Kd7 30. Nxd4 Nxd4 31. Ba4+ Bc6 32. Bxc6+ Nxc6 33. Qg7+ Ne7 34. Qxf8 Ne4 35. Rxe4 dxe4 36. Qf4 Nd5 37. Qxe4 c3 38. Qd4 Kd6 39. h4 e5 40. Qd3 Kc5 41. h5 e4 42. Qc2 Kc4 43. h6 Nf6 44. h7 Nxh7 45. Qxe4+ Kb3 46. Qxh7 c2 47. Qxc2+ Kxc2 48. g4 Kd3 49. g5 Ke4 50. g6 Kf5 51. g7 Kf6 52. g8=Q Kf5 53. Qg7 1-0",
    B: "1664",
  },
  {
    W: "1298",
    T: "60+0",
    P: "1. g3 g6 2. Bg2 Bg7 3. c3 Nf6 4. Qb3 O-O 5. Bxb7 Bxb7 6. Qxb7 Nc6 7. Qb3 Rb8 8. Qc4 Ne5 9. Qd4 Nc6 10. Qd3 Ne8 11. Nf3 f6 12. O-O Ne5 13. Nxe5 fxe5 14. b3 c6 15. Ba3 Qa5 16. Bxe7 Rf7 17. Bg5 Rxb3 18. Kh1 Rb8 19. f4 Qd5+ 20. Qxd5 cxd5 21. Na3 h6 22. fxe5 hxg5 23. Rxf7 Kxf7 24. Rf1+ Kg8 25. Nc2 Bxe5 26. Ne3 Bd6 27. Nxd5 Ng7 28. Rf6 Rf8 29. Rxg6 Rf1+ 30. Kg2 Rf8 31. Rxd6 Re8 32. Ne7+ Kf7 0-1",
    B: "1420",
  },
  {
    W: "1898",
    T: "60+0",
    P: "1. e4 Nf6 2. g3 Nxe4 3. Bg2 Nf6 4. d3 g6 5. Qe2 Bg7 6. Nf3 O-O 7. Bd2 d5 8. c4 c5 9. cxd5 Nxd5 10. O-O Bxb2 0-1",
    B: "1968",
  },
  {
    W: "1427",
    T: "60+0",
    P: "1. g3 d5 2. Bg2 Nf6 3. d3 g6 4. Nf3 Bg7 5. Bf4 O-O 6. Nc3 e6 7. Qd2 b6 8. Bh6 Bb7 9. Bxg7 Kxg7 10. O-O-O c5 11. h4 a5 12. e4 b5 13. exd5 exd5 14. Nxb5 Nc6 15. Nc3 Qd6 16. Ng5 d4 17. Nce4 Nxe4 18. Nxe4 Qe6 19. h5 Ba6 20. h6+ Kg8 21. Kb1 Rab8 22. Nxc5 Qd5 23. Bxd5 Nb4 24. Qg5 Rb6 25. Qe5 Nxd5 26. Qg7# 1-0",
    B: "1469",
  },
  {
    W: "2203",
    T: "60+0",
    P: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. Bf4 dxc4 5. a4 Nd5 6. Bd2 Nxc3 7. Bxc3 b5 8. axb5 cxb5 9. e3 e6 10. Qf3 Nc6 11. Qxc6+ Bd7 12. Qf3 a6 13. Ne2 Be7 14. Nf4 O-O 15. Be2 Qc7 16. O-O Bc6 17. Qg3 Kh8 18. Bf3 Bd6 19. Bxc6 Qxc6 20. Qg4 f5 21. Qh4 Rae8 22. f3 Kg8 23. Rae1 b4 24. Bd2 e5 25. dxe5 Bxe5 26. Bxb4 Bxb2 27. Bxf8 Rxf8 28. Qe7 c3 29. Ne6 Rf7 30. Qd8+ 1-0",
    B: "2358",
  },
  {
    W: "1598",
    T: "60+0",
    P: "1. c4 d5 2. cxd5 Qxd5 3. Nc3 Qd8 4. Nf3 Nf6 5. g3 Bg4 6. Bg2 Nc6 7. O-O Nd4 8. Rb1 Nxf3+ 9. exf3 Bf5 10. d3 Bxd3 11. Ra1 Bxf1 12. Qxf1 Nd5 13. Nxd5 Qxd5 14. f4 Qd8 15. Be3 e6 16. Rd1 Bd6 17. Bxb7 Rb8 18. Bc6+ Ke7 19. Bc5 Rxb2 20. Bxd6+ cxd6 21. Qd3 Qc7 22. Bb5 Rd8 23. a4 Qc2 24. Qxc2 Rxc2 25. Rb1 Rc5 26. Kg2 Rf5 27. h3 Kf6 28. Rc1 Rd5 29. Re1 Rd4 30. h4 d5 31. g4 Rc8 32. g5+ Ke7 33. Kg3 Rc3+ 34. Kg4 Rdd3 35. Kh5 Rh3 36. g6 Rh2 37. gxh7 Rxh4+ 0-1",
    B: "1588",
  },
  {
    W: "1825",
    T: "60+0",
    P: "1. e4 g6 0-1",
    B: "2023",
  },
  {
    W: "1733",
    T: "60+0",
    P: "1. e4 Nc6 2. Nc3 Nf6 3. Nf3 e6 4. Be2 d6 5. O-O Be7 6. d3 Bd7 7. Bg5 e5 8. Bxf6 Bxf6 9. Nd5 Be6 10. Nxf6+ Qxf6 11. c3 g6 12. a3 h6 13. Qd2 O-O-O 14. Rab1 Bb3 15. Rbc1 Na5 16. d4 Bc4 17. Bxc4 Nxc4 18. Qd3 Nxb2 19. Qc2 Nc4 20. Qb3 Nb6 21. a4 Qe6 22. Qxe6+ fxe6 23. a5 Nc4 24. Ra1 a6 25. dxe5 Nxe5 26. Nxe5 dxe5 27. Rfd1 Rxd1+ 28. Rxd1 Rd8 29. Rxd8+ Kxd8 30. f3 b6 31. Kf2 bxa5 32. c4 Kd7 33. Ke3 Kc6 34. Kd3 Kc5 35. Kc3 h5 36. h3 g5 37. g4 a4 38. gxh5 a3 39. Kb3 a2 40. Kxa2 Kxc4 41. h6 Kd4 42. h7 c5 43. h8=Q c4 44. Qf6 Ke3 45. Qxg5+ Kxf3 46. Qxe5 Ke3 47. Qxe6 Kd3 48. Qd5+ Kc3 49. e5 Kb4 50. Qc6 a5 51. Qb6+ Kc3 52. Qxa5+ Kd3 53. Qa4 c3 54. Qa3 Kd2 55. Qxc3+ Kxc3 56. e6 Kd4 57. e7 Kd5 1/2-1/2",
    B: "1761",
  },
  {
    W: "1499",
    T: "60+0",
    P: "1. e4 Nf6 2. Bc4 Nxe4 3. d3 Nf6 4. Bg5 e6 5. a3 Be7 6. h4 h6 7. Be3 O-O 8. Nc3 d5 9. Ba2 Nc6 10. Nce2 Ne5 11. Qd2 Bd7 12. O-O-O c5 13. f4 Ng6 14. g3 b5 15. Nf3 Ng4 16. h5 Nxe3 17. Qxe3 Nh8 18. g4 c4 19. dxc4 bxc4 20. Ned4 Bc5 21. Rdg1 f6 22. g5 fxg5 23. fxg5 Nf7 24. g6 Bxd4 25. gxf7+ Rxf7 26. Nxd4 Qe7 27. Rg2 e5 28. Ne2 d4 29. Qe4 Bf5 30. Qd5 Rd8 31. Qxc4 Be6 0-1",
    B: "1543",
  },
  {
    W: "1726",
    T: "60+0",
    P: "1. d4 c6 2. Bg5 d5 3. e3 Nf6 4. Bd3 h6 5. Bh4 e6 6. Nf3 Bd6 7. Nbd2 Nbd7 8. Qe2 Nf8 9. c3 Bd7 10. O-O-O Ng6 11. Bg3 Bxg3 12. fxg3 Qa5 13. Rhf1 Qxa2 14. e4 Nxe4 15. Nxe4 dxe4 16. Bxe4 a5 17. Kc2 a4 18. Ra1 Qb3+ 19. Kd2 Qxb2+ 20. Bc2 c5 21. Rab1 Qa2 22. Rxb7 a3 23. Rfb1 Ba4 24. Kd3 Bxc2+ 25. Qxc2 Qxc2+ 26. Kxc2 O-O 27. Ra1 Ra6 28. Rb3 Rfa8 29. Rbxa3 Rxa3 30. Rb1 Ra2+ 31. Kd3 Rxg2 32. h4 e5 33. Nxe5 Nxe5+ 34. dxe5 Rxg3+ 35. Ke4 Rxc3 36. Kd5 Rd3+ 37. Kc6 Rc8+ 38. Kb7 c4 39. Ra1 Rdd8 40. Rc1 Rb8+ 41. Kc6 Rbc8+ 42. Kb6 Rb8+ 43. Kc5 Rbc8+ 44. Kb4 Rb8+ 45. Kc3 Ra8 46. Rd1 Rdb8 47. Rd7 Ra3+ 48. Kd4 Rb4 49. Rd8+ 1-0",
    B: "1707",
  },
  {
    W: "1331",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Nxc6 bxc6 6. Nc3 Bxf2+ 7. Kxf2 Nf6 8. Qf3 d6 9. Bg5 Bg4 10. Qf4 O-O 11. Bxf6 Qxf6 12. Qxf6 gxf6 13. Be2 Be6 14. Rad1 Kg7 15. g3 Kh6 16. Kg2 Rg8 17. Rhf1 Rg6 18. a4 Rag8 19. Rf3 Rg5 20. b4 Rh5 21. h4 1-0",
    B: "1363",
  },
  {
    W: "1207",
    T: "60+0",
    P: "1. e4 e6 2. f3 d5 3. f4 Nf6 4. f5 exf5 5. d3 fxe4 6. dxe4 dxe4 7. Qxd8+ Kxd8 8. Bg5 Be7 9. Bxf6 Bxf6 10. Nc3 Ke7 11. Nxe4 Rd8 12. Nxf6 Kxf6 13. c4 Re8+ 14. Kd2 Nc6 15. c5 Ke7 16. Bc4 Kf8 17. Nh3 Be6 18. Bxe6 Rxe6 19. Ng5 Rg6 20. Nxh7+ Kg8 21. Nf6+ Rxf6 22. Rhf1 Rxf1 23. Rxf1 Rd8+ 24. Kc3 Ne5 25. c6 Nxc6 26. b4 Nd4 27. Kc4 Ne2 28. b5 Nd4 29. b6 axb6 30. Kb4 Nc2+ 31. Kb5 Nd4+ 32. Kb4 Nc2+ 33. Kb5 Nd4+ 34. Kb4 1/2-1/2",
    B: "1221",
  },
  {
    W: "783",
    T: "60+0",
    P: "1. d4 c6 2. e3 d5 3. f3 Nf6 4. e4 dxe4 5. fxe4 Nxe4 6. c3 Bf5 7. g3 e6 8. Nh3 Bd6 9. Ng5 O-O 10. h3 Nxg3 11. Rg1 Nxf1 12. Rxf1 Bxh3 13. Rf3 Bg4 14. Rg3 Bxg3+ 15. Kf1 Bxd1 16. Nd2 Qf6+ 17. Kg2 Qf2+ 18. Kh3 Bf3 19. Ngxf3 Qxf3 20. Nxf3 f6 21. Kxg3 g5 22. Bxg5 fxg5 23. Nxg5 h6 24. Nxe6 Nd7 25. Nxf8 Rxf8 26. Rh1 Nf6 27. Rf1 Ne4+ 0-1",
    B: "1105",
  },
  {
    W: "2190",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 Nf6 3. g3 c5 4. Bg2 cxd4 5. Nxd4 Nc6 6. Nf3 e5 7. O-O Be6 8. e3 h6 9. b3 Bd6 10. Bb2 O-O 11. c4 dxc4 12. bxc4 Bxc4 13. Nbd2 Bxf1 0-1",
    B: "2083",
  },
  {
    W: "1572",
    T: "1080+0",
    P: "1. f3 e5 2. g4 h6 3. Bg2 Qh4+ 4. Kf1 Bc5 5. Nh3 d6 6. e3 a6 7. Qe2 Nc6 8. c3 Nf6 9. Nf2 b5 10. h3 O-O 11. b4 Ba7 12. a3 Nh7 13. d3 Bb7 14. Nd2 Ne7 15. Nde4 f5 16. gxf5 Nxf5 17. Ke1 Bxe4 18. Kd1 Bd5 19. Rg1 Bb3+ 20. Ke1 Ng3 21. Qb2 Bd5 22. e4 Be6 23. a4 Ng5 24. Bxg5 Qxg5 25. axb5 Qe3+ 26. Kd1 Qxf2 27. Qxf2 Bxf2 28. Rf1 Be3 29. Re1 Bb3# 0-1",
    B: "1582",
  },
  {
    W: "1651",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Nxc6 bxc6 6. Bd3 Nf6 7. h3 O-O 8. O-O d5 9. exd5 Qxd5 10. Nc3 Qe6 11. Re1 Qd6 12. Ne4 Nxe4 13. Bxe4 Qg3 14. Qf3 Qxf3 15. Bxf3 Bf5 16. Bxc6 Rad8 17. c3 Rd6 18. Bf3 Rfd8 19. Bf4 Rf6 20. Bxc7 Rc8 21. Bg3 Bxh3 22. gxh3 Rxf3 23. Kg2 Rf6 24. Re5 h6 25. Rae1 Bb6 26. Re8+ Rxe8 27. Rxe8+ Kh7 28. c4 g5 29. Rc8 Bd4 30. b3 h5 31. h4 gxh4 32. Bxh4 Rf4 33. Bg3 Rg4 34. Kf3 f5 35. Rc7+ Kg6 36. Rd7 h4 37. Bh2 h3 38. Rd5 Bxf2 39. Kxf2 Rg2+ 40. Kf1 Rxh2 41. Rd3 Kg5 42. Kg1 Rg2+ 43. Kh1 Kg4 44. a4 Re2 45. c5 f4 46. c6 f3 47. Rc3 Kg3 48. c7 Re1# 0-1",
    B: "1728",
  },
  {
    W: "1774",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Qb6 5. Nc3 Bc5 6. Nce2 Nc6 7. Nxc6 Bxf2+ 8. Kd2 Qe3# 0-1",
    B: "1788",
  },
  {
    W: "2475",
    T: "60+0",
    P: "1. d4 Nf6 2. c4 c5 3. d5 e5 4. Nc3 d6 5. e4 Be7 6. g3 Nbd7 7. h4 Nf8 8. Bh3 h5 9. Bg5 Ng6 10. Bxc8 Qxc8 11. Qd3 Ng4 12. Bd2 a6 13. f3 Nf6 14. Nge2 Qd7 15. a4 Qc7 16. Bg5 Nh7 17. Bd2 Ngf8 18. a5 Nd7 19. f4 Bf6 20. f5 g5 21. hxg5 Bxg5 22. Rxh5 Bxd2+ 23. Kxd2 O-O-O 24. Rah1 Ndf6 25. Rh6 Rdg8 26. Qe3 Qd8 27. Qf2 Ng5 28. Rxh8 Rxh8 29. Rh4 Rxh4 30. gxh4 Ngxe4+ 31. Nxe4 Nxe4+ 32. Kd3 Nxf2+ 33. Kc3 Qxh4 34. Kb3 Qh3+ 35. Ka4 Qe3 36. b3 Qxe2 37. Ka3 Ne4 38. b4 Qd3+ 39. Ka4 Nc3+ 40. Kb3 Nxd5+ 41. Ka4 cxb4 42. cxd5 Qxd5 43. f6 Qb5+ 44. Kb3 d5 45. Kb2 d4 46. Kc2 Qd5 47. Kd2 Qxa5 48. Kd1 Qd5 49. Kd2 Qe6 0-1",
    B: "2321",
  },
  {
    W: "1883",
    T: "-",
    P: "1. c4 Nf6 2. g3 d5 3. cxd5 Qxd5 4. Nf3 Nc6 5. Nc3 Qd8 6. Bg2 e5 7. e4 Bc5 8. O-O O-O 9. d3 Re8 10. a3 a5 11. Bd2 Nd4 12. Nxd4 exd4 13. Ne2 Be6 14. b4 Bb6 15. e5 Nd5 16. bxa5 Bxa5 17. Bxa5 Rxa5 18. Nxd4 Bc8 19. Nb3 Ra6 20. d4 f6 21. exf6 Rxf6 22. Nd2 Nc3 23. Qb3+ Nd5 24. Qxd5+ Qxd5 25. Bxd5+ Kf8 26. Rab1 c6 27. Bg2 Rfe6 28. Nc4 Re2 29. Nd6 1-0",
    B: "1792",
  },
  {
    W: "1776",
    T: "300+0",
    P: "1. Nf3 d5 2. e4 dxe4 3. Ng5 e5 4. Nxe4 Bf5 5. Qf3 Qd7 6. d3 Nc6 7. Be2 Nd4 8. Qe3 Nxc2+ 9. Kd2 Nxe3 10. fxe3 Bxe4 0-1",
    B: "1769",
  },
  {
    W: "1730",
    T: "300+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 d6 4. Bc4 Be7 5. O-O Nf6 6. d4 Nxe4 7. Re1 d5 8. Bd3 Bf5 9. Bxf4 O-O 10. Bxe4 Bxe4 11. Nfd2 Bg6 12. Nc3 c6 13. Nf3 Nd7 14. Rc1 Bb4 15. a3 Bxc3 16. bxc3 Qf6 17. Qe2 Qxf4 0-1",
    B: "1696",
  },
  {
    W: "1328",
    T: "300+0",
    P: "1. e4 g6 2. d3 Nc6 3. Nf3 Bg7 4. Ng5 e6 5. Na3 h6 6. Nf3 Nge7 7. Be2 d5 8. e5 Nxe5 9. Nxe5 Bxe5 10. O-O Bd7 11. Bd2 Bb5 12. b3 Ba6 13. c4 dxc4 14. dxc4 Qd7 15. b4 1-0",
    B: "1140",
  },
  {
    W: "1368",
    T: "300+0",
    P: "1. e4 e6 2. d4 d5 3. Nc3 a6 4. a3 c5 5. Bf4 Nc6 6. Nf3 Qf6 7. Qd2 cxd4 8. e5 dxc3 9. bxc3 Qd8 10. Be2 f6 11. O-O fxe5 12. Bxe5 Nxe5 13. Nxe5 Bd6 14. Qd4 Nf6 15. Rab1 O-O 16. Kh1 b5 17. f4 Ne4 18. Bd3 Bxe5 19. Qxe5 Qe7 20. Bxe4 dxe4 21. Qxe4 Bb7 22. Qd4 e5 23. fxe5 Rxf1+ 24. Rxf1 Rf8 25. Rxf8+ Qxf8 26. e6 Qf6 27. Qxf6 gxf6 28. e7 Kf7 29. h4 Kxe7 30. Kh2 h5 31. Kh3 Bc8+ 32. Kg3 Kf7 33. Kf4 a5 34. g4 b4 35. axb4 a4 36. gxh5 a3 37. h6 Kg8 38. b5 a2 39. b6 a1=Q 40. c4 Qd4+ 41. Kg3 Qxc4 42. h5 Qxc2 43. b7 Bxb7 44. h7+ Kxh7 45. h6 Kxh6 46. Kg4 Qg6+ 47. Kf4 Qg5# 0-1",
    B: "1352",
  },
  {
    W: "1548",
    T: "300+0",
    P: "1. d4 d5 2. Nf3 Bg4 3. c4 Bxf3 4. exf3 e6 5. Qb3 b6 6. c5 Nc6 7. Bb5 Ne7 8. Qa4 Qd7 9. cxb6 cxb6 10. Nc3 g6 11. Bg5 h6 12. Bxe7 Bxe7 13. Bxc6 1-0",
    B: "1516",
  },
  {
    W: "1575",
    T: "300+0",
    P: "1. d4 e5 2. dxe5 Nc6 3. Nf3 Nh6 4. h3 Nf5 5. Bg5 f6 6. exf6 gxf6 7. Bf4 Bg7 8. c3 O-O 9. e3 d6 10. Bc4+ Kh8 11. O-O Qe7 12. Nbd2 Be6 13. Bb3 Nh6 14. Re1 Ng4 15. Nd4 Bxb3 16. N2xb3 Nge5 17. Nf5 Qf7 18. Nbd4 Rg8 19. Nh6 Bxh6 20. Bxh6 Qg6 21. g3 Qxh6 22. Nf5 Qxh3 23. Qc2 Ng4 0-1",
    B: "1603",
  },
  {
    W: "1829",
    T: "300+0",
    P: "1. e4 Nc6 2. d4 d5 3. e5 f6 4. Nf3 Bg4 5. Bb5 Qd7 6. h3 Bxf3 7. Qxf3 a6 8. Bxc6 Qxc6 9. c3 Qb5 10. Nd2 O-O-O 11. Nb3 Nh6 12. Bf4 e6 13. Kd1 Nf5 14. g4 Ne7 15. Kc2 Kb8 16. Rae1 Qb6 17. Nc5 Ng6 18. exf6 Nxf4 19. fxg7 Bxg7 20. Qxf4 Rhf8 21. Qg3 Rf6 22. Re2 Bf8 23. Nd3 Bd6 24. Ne5 Qb5 25. Rhe1 Rdf8 26. b3 Qa5 27. a4 b5 28. Nd7+ Ka7 29. Nxf8 Bxg3 30. fxg3 Rxf8 31. axb5 Qxb5 32. Rxe6 Rf2+ 33. Kd1 Qd3+ 34. Kc1 Qc2# 0-1",
    B: "1800",
  },
  {
    W: "1905",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 e6 3. Bc4 1-0",
    B: "1833",
  },
  {
    W: "1062",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. d4 Nc6 4. Bc4 g6 5. Ng5 Nf6 6. Nxf7 b5 7. Nxd8 1-0",
    B: "1122",
  },
  {
    W: "1960",
    T: "300+0",
    P: "1. d4 d5 2. Nc3 c5 3. dxc5 e6 4. e4 Bxc5 5. Bb5+ Nc6 6. Bxc6+ bxc6 7. exd5 cxd5 8. Nf3 Ba6 9. Bf4 Qb6 10. Bg3 Qxb2 11. Kd2 Bb4 12. Be5 f6 13. Rb1 Qa3 14. Rb3 Qa5 15. a3 Bxc3+ 16. Bxc3 Qc7 17. Re1 e5 18. Nd4 Kf7 19. Qh5+ g6 20. Qe2 exd4 21. Qe6+ Kf8 22. Bxd4 Bc8 23. Qxd5 Ne7 24. Qxa8 Qf4+ 25. Be3 Qd6+ 26. Kc1 Kg7 27. Qxa7 Be6 28. Rb7 Kf7 29. Bc5 Qf4+ 30. Re3 1-0",
    B: "1961",
  },
  {
    W: "1010",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 a6 3. Nxe5 f6 4. Nf3 Bc5 5. d4 Bb4+ 6. c3 Ba5 7. b4 Bb6 8. Bb2 d5 9. Nbd2 Bg4 10. exd5 Nh6 11. Qe2+ Kf7 12. d6 Re8 13. Qxe8+ Qxe8+ 14. Be2 cxd6 15. O-O Qxe2 16. Nc4 Qxc4 0-1",
    B: "1007",
  },
  {
    W: "1637",
    T: "300+0",
    P: "1. e4 e6 2. Nf3 d5 3. Nc3 a6 4. exd5 exd5 5. d4 Nf6 6. Bg5 Be7 7. Bd3 O-O 8. O-O h6 9. Re1 c6 10. Bh4 Be6 11. Na4 Nbd7 12. c4 dxc4 13. Rxe6 fxe6 14. Bxc4 Nd5 15. Bxe7 Qxe7 16. Nc3 b5 17. Bb3 a5 18. Nxd5 exd5 19. Bc2 Nf6 20. Qd3 Qe4 21. Qd2 Qg4 22. Ne5 Qe6 23. Re1 Qd6 24. Ng6 Rfe8 25. Rd1 Ne4 26. Ne5 Nxd2 27. Ng6 0-1",
    B: "1685",
  },
  {
    W: "1426",
    T: "300+0",
    P: "1. d4 d5 2. c4 Nf6 3. Nc3 e6 4. Bf4 Bd6 5. Bxd6 Qxd6 6. c5 Qd8 7. e3 Nc6 8. Nf3 O-O 9. Bd3 h6 10. Ne5 Nxe5 11. dxe5 Nh7 12. Bxh7+ Kxh7 13. Qd3+ Kg8 14. Ne2 f5 15. exf6 Qxf6 16. f3 Qxb2 17. O-O Qf6 18. Ng3 c6 19. Nh5 Qg5 20. g4 e5 21. f4 Qxg4+ 22. Ng3 exf4 23. exf4 Bf5 24. Qd4 Be4 25. Rae1 h5 26. Re3 h4 27. Kf2 hxg3+ 28. Rxg3 Qxf4+ 29. Ke2 Qxf1+ 30. Kd2 Rf2+ 31. Kc3 Rc2+ 32. Kb3 Qb5+ 33. Ka3 a5 34. Qxg7# 1-0",
    B: "1473",
  },
  {
    W: "1945",
    T: "300+0",
    P: "1. e4 e6 2. g3 d5 3. Nc3 Nf6 4. Bg2 Bb4 5. exd5 Nxd5 6. Nxd5 exd5 7. c3 Bc5 8. d4 Bd6 9. Bxd5 O-O 10. Ne2 Bxg3 11. Bxf7+ Rxf7 12. hxg3 Bg4 13. Be3 Qd5 14. Rh4 Bf3 15. Qd3 h6 16. O-O-O Nc6 17. Kb1 Re8 18. Ka1 a5 19. Rd2 b5 20. Nf4 Qg5 21. Ne6 Qf5 22. Qxf5 Rxf5 23. Nxc7 Re7 24. Nxb5 Rxb5 25. Rf4 Bh5 26. a4 Rb8 27. d5 Ne5 28. d6 Rd7 29. Rfd4 Nf3 30. Bf4 Nxd4 31. Rxd4 g5 32. Be5 Kf7 33. f4 gxf4 34. Bxf4 Ke6 35. Re4+ Kf5 36. Re5+ Kg4 37. Rxa5 Re8 38. Ka2 Re2 39. Rb5 Bf7+ 40. Ka3 Re1 41. a5 h5 42. Rb4 Kf3 43. Rb8 h4 44. gxh4 Kxf4 45. Rf8 Ke5 46. Kb4 Bd5 47. a6 1-0",
    B: "1907",
  },
  {
    W: "2078",
    T: "300+0",
    P: "1. Nf3 Nc6 2. c4 e6 3. g3 Nf6 4. Bg2 Be7 5. O-O O-O 6. d4 d5 7. cxd5 exd5 8. Nc3 Be6 9. Qb3 Na5 10. Qc2 Nc4 11. Re1 c6 12. a3 Nd6 13. Ne5 Rc8 14. Qb3 b6 15. Qa4 Qc7 16. Bg5 a5 17. Rac1 h6 18. Bxf6 Bxf6 19. Nxd5 Bxd5 20. Bxd5 Bxe5 21. dxe5 Nb5 22. Rxc6 Qxe5 23. Qxb5 Rcd8 24. Bc4 Qe4 25. Bd3 Rxd3 26. Qxd3 Qxc6 27. Rd1 Rc8 28. h4 Rc7 29. e4 f6 30. Qd5+ Kh7 31. Qxc6 Rxc6 32. Rd2 Kg6 33. Kg2 Kf7 34. f4 g5 35. hxg5 hxg5 36. Kf3 Kg6 37. fxg5 Kxg5 38. Rd5+ Kg6 39. Kf4 Re6 40. Rb5 Kf7 41. g4 Ke7 42. g5 fxg5+ 43. Rxg5 Kd6 44. Rb5 Kc6 45. e5 Kxb5 46. Kf5 Re8 47. e6 Kc4 48. Kf6 Kb3 49. Kf7 Rc8 50. e7 Kxb2 51. e8=Q Rxe8 52. Kxe8 Kxa3 0-1",
    B: "2020",
  },
  {
    W: "1679",
    T: "15+0",
    P: "1. f4 e6 2. Nf3 c5 3. e4 Nc6 4. e5 d5 5. Bb5 Bd7 6. Bxc6 Bxc6 7. O-O d4 8. c3 Be7 9. cxd4 cxd4 10. d3 f6 11. Nbd2 fxe5 12. Nxe5 Bf6 13. Qh5+ g6 14. Nxg6 hxg6 15. Qxg6+ Kd7 16. Ne4 Qe7 17. b3 Bxe4 18. Qxe4 e5 19. Ba3 Qxa3 20. fxe5 Qe7 21. Qd5+ Qd6 22. exf6 Qxd5 23. f7 1-0",
    B: "1627",
  },
  {
    W: "1597",
    T: "180+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. Bc4 b6 4. O-O Bb7 5. d3 e6 6. c3 Ne7 7. Bb3 O-O 8. Nbd2 d6 9. Re1 Nd7 10. Nf1 Nc5 11. Bc2 Qd7 12. Ng3 Rad8 13. Nh4 d5 14. d4 Nxe4 15. Bxe4 dxe4 16. Nxe4 f5 17. Ng5 Bd5 18. b3 Qc6 19. c4 Be4 20. f3 Bxd4+ 21. Kh1 Bxa1 22. fxe4 Rxd1 0-1",
    B: "1941",
  },
  {
    W: "1794",
    T: "180+0",
    P: "1. e4 b6 2. d4 Bb7 3. c4 e6 4. Nc3 Nf6 5. e5 Ng8 6. Nf3 Bb4 7. g3 Bxc3+ 8. bxc3 Ne7 9. Bg2 Nf5 10. O-O O-O 11. d5 Na6 12. Bg5 Qe8 13. Nd4 Nxd4 14. cxd4 h6 15. Be3 Qe7 16. dxe6 Bxg2 17. exf7+ Rxf7 18. Kxg2 Nb4 19. a3 Na6 20. Re1 d5 21. exd6 cxd6 22. Bxh6 Qf6 23. Be3 Rc8 24. Rc1 Nc7 25. f4 Ne6 26. d5 Nc5 27. Bxc5 bxc5 28. Re6 Qb2+ 29. Qc2 Qxa3 30. Rxd6 Kh8 31. Re1 Qb4 32. Re4 a5 33. Qe2 a4 34. Ree6 a3 35. Ra6 Qc3 36. Qa2 Qb2+ 37. Qxb2 axb2 38. Rab6 Ra8 39. Rxb2 Ra4 40. Rc2 Kh7 41. Rc6 Re7 42. Rxc5 Rd7 43. d6 Rxd6 44. Rd5 Rc6 45. c5 Ra5 46. Rd6 Rcxc5 47. Rxc5 Rxc5 48. Rd2 Rc3 49. h4 g6 50. Kh3 Kh6 51. Rd6 Kh5 52. f5 gxf5 53. Rd5 Rf3 54. Kg2 Kg4 55. Rxf5 Rxf5 56. h5 Rxh5 57. Kf2 Rf5+ 58. Kg2 Rf3 59. Kh2 Rxg3 60. Kh1 Rh3+ 61. Kg1 Rg3+ 62. Kf1 Rf3+ 63. Ke1 Kg3 64. Kd1 Rf2 65. Kc1 Kg2 66. Kb1 Rd2 67. Ka1 Kf2 68. Kb1 Ke2 69. Ka1 1/2-1/2",
    B: "1808",
  },
  {
    W: "1391",
    T: "180+0",
    P: "1. d4 e6 2. e4 d5 3. e5 Bb4+ 4. Nc3 Bxc3+ 5. bxc3 Nc6 6. Nf3 Na5 7. Bd3 b5 8. O-O 1-0",
    B: "1350",
  },
  {
    W: "1909",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 g6 4. Bc4 Bg7 5. O-O d6 6. h3 Nge7 7. d3 Be6 8. Be3 Bxc4 9. dxc4 O-O 10. Nd5 f5 11. Qd2 f4 12. Bxf4 exf4 13. Nxf4 Bh6 14. g3 Bxf4 15. gxf4 Qd7 16. Kg2 Rae8 17. e5 Nf5 18. Ng5 h6 19. e6 Qe7 20. Ne4 Qxe6 21. Rae1 Nh4+ 22. Kg3 Qf5 23. Qd5+ Qxd5 24. cxd5 Nf5+ 25. Kg4 Ncd4 26. Nf6+ Rxf6 27. Rxe8+ Kg7 28. h4 h5+ 29. Kh3 c5 30. c3 Nf3 31. Rb8 b6 32. Rb7+ Kh6 33. Rxa7 N5xh4 34. Kg3 g5 35. fxg5+ Kxg5 36. Rg7+ Ng6 37. Rh1 h4+ 38. Kg2 Kh6 39. Rd7 Nf4+ 40. Kf1 Nd2+ 41. Ke1 Ng2+ 42. Kxd2 Nf4 43. Rxh4+ 1-0",
    B: "1876",
  },
  {
    W: "912",
    T: "180+0",
    P: "1. e4 d5 2. d4 Nf6 3. Nc3 dxe4 4. Nxe4 Nxe4 5. Qf3 Nd6 6. Ne2 e6 7. c4 Nxc4 8. Nc3 Bb4 9. Bxc4 Qxd4 10. Qh5 Qxc4 11. Bd2 O-O 12. O-O-O Rd8 13. Rhf1 Bxc3 14. Bxc3 Rxd1+ 15. Kxd1 Qxf1+ 16. Kc2 Qxf2+ 17. Kb3 f6 18. Qe8# 1-0",
    B: "898",
  },
  {
    W: "2033",
    T: "180+0",
    P: "1. a3 d5 2. d4 Nf6 3. Nf3 Bf5 4. h3 e6 5. Nc3 c5 6. e3 Bd6 7. Be2 O-O 8. O-O Nbd7 9. Re1 a6 10. Bf1 b5 11. dxc5 Nxc5 12. g3 Be7 13. Bg2 Nce4 14. Nxe4 Bxe4 15. Bd2 h6 16. Bc3 Rc8 17. Qd2 Qc7 18. Rad1 Bd6 19. Rc1 Nh5 20. Rf1 Bxa3 21. Ba5 Bxb2 22. Bxc7 Bxc1 23. Qxc1 Rxc7 24. Nd4 Bxg2 25. Kxg2 Nf6 26. Qa3 Rfc8 27. f4 Ne4 28. Qxa6 Rc3 29. Re1 h5 30. Qxb5 Nd6 31. Qa6 Ne4 32. Re2 Nf6 33. Kf3 Ne4 34. g4 hxg4+ 35. hxg4 R3c7 36. g5 g6 37. Rh2 Kg7 38. Nxe6+ fxe6 39. Qxe6 1-0",
    B: "2035",
  },
  {
    W: "1774",
    T: "180+0",
    P: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. d4 Nc6 5. Bb5 a6 6. Bxc6+ bxc6 7. c3 Bf5 8. Bf4 e6 9. O-O Bd6 10. Be5 Bxe5 11. Nxe5 Nf6 12. f3 O-O 13. Nd2 Qc7 14. g4 Bg6 15. h4 h6 16. Kf2 Bh7 17. Rg1 c5 18. Rc1 cxd4 19. cxd4 Qb6 20. Nb3 a5 21. a4 Qb4 22. Ra1 Rac8 23. Nd3 Qb6 24. Ndc5 Qb4 25. Qd2 Rfd8 26. Qxb4 axb4 27. a5 Ra8 28. a6 Nd7 29. Ra4 Nxc5 30. Nxc5 Rdb8 31. a7 Bc2 32. Ra5 Rd8 33. Rc1 b3 34. Rca1 Rdc8 35. Ke3 Rc7 36. Kf4 Kf8 37. g5 hxg5+ 38. hxg5 Ke7 39. Kg4 Kd6 40. f4 Kc6 41. Ra6+ Kb5 42. R1a5+ Kb4 43. Ra4+ Kb5 44. R4a5+ Kb4 45. Na4 Kc4 46. Nb6+ Kd3 47. Nxa8 Rc8 48. Nb6 Ke2 49. a8=Q Rxa8 50. Nxa8 Bd3 51. Nc7 Bxa6 52. Nxd5 Bd3 53. Ra6 exd5 0-1",
    B: "1816",
  },
  {
    W: "2384",
    T: "180+0",
    P: "1. f4 d6 2. e4 g5 3. f5 h6 4. d4 Bg7 5. c3 c5 6. dxc5 Nc6 7. cxd6 Qb6 8. dxe7 Ngxe7 9. Nf3 Bd7 10. Qb3 Qc7 11. Be2 O-O-O 12. Be3 Kb8 13. Nbd2 Rhe8 14. Nd4 Ne5 15. O-O N7c6 16. Qa3 a6 17. b4 Nxd4 18. cxd4 Nc6 19. Nb3 Bxf5 20. Rxf5 Rxe4 21. Bf2 Nxd4 22. Nxd4 Bxd4 23. Bxd4 Rexd4 24. Qg3 Rf4 25. Rxf4 gxf4 26. Qf3 Qb6+ 27. Qf2 Qxb4 28. Rf1 Rd4 29. h3 Ka8 30. Qf3 Re4 31. Bxa6 Re3 32. Bxb7+ Qxb7 33. Qxf4 Re2 34. Rf2 Re1+ 35. Kh2 Qb1 36. Qa4+ Kb8 37. Qe8+ Rxe8 38. Rxf7 Re1 39. Rf3 Rh1+ 40. Kg3 Qg6+ 41. Kf2 Ra1 42. a3 Ra2+ 43. Ke1 Qxg2 44. Rb3+ Ka7 45. Re3 Qg1# 0-1",
    B: "2275",
  },
  {
    W: "2157",
    T: "180+0",
    P: "1. d4 Nf6 2. Bf4 d5 3. e3 c5 4. Nf3 Nc6 5. h3 Qb6 6. Nc3 cxd4 7. Nxd4 Nxd4 8. Qxd4 Qxd4 9. exd4 a6 10. Bd3 g6 11. Be5 Bg7 12. Nxd5 Nxd5 13. Bxg7 Rg8 14. Be5 Be6 15. c3 O-O-O 16. O-O f6 17. Bg3 Nb6 18. b3 Bf7 19. Rfe1 e5 20. f3 exd4 21. cxd4 Rxd4 22. Rad1 Rgd8 23. Bf2 Rxd3 24. Rxd3 Rxd3 25. Bxb6 Kd7 26. Re2 Rd6 27. Be3 Bd5 28. Rd2 Bc6 29. Rxd6+ Kxd6 30. Kf2 Kd5 31. Ke2 h5 32. Kd2 g5 33. Bb6 Ke6 34. Ke3 g4 35. hxg4 hxg4 36. Kf4 gxf3 37. gxf3 Bd5 38. Bd4 Bc6 39. a3 Bd5 40. b4 b5 41. Kg4 Bb7 42. f4 Be4 43. Kh5 f5 44. Kg5 Kd5 45. Bc5 Kc4 46. Kf6 Kb3 47. Ke5 Kxa3 48. Kd4 Kb3 49. Bd6 Ka4 50. Kc3 a5 51. Be7 axb4+ 52. Bxb4 Bb1 53. Be7 Be4 54. Bf8 Bh1 55. Be7 Be4 56. Bf8 Bh1 57. Be7 Be4 1/2-1/2",
    B: "2163",
  },
  {
    W: "1672",
    T: "180+0",
    P: "1. e4 e5 2. Bc4 Nc6 3. Nf3 Nf6 4. Ng5 d5 5. exd5 b5 6. Bxb5 Qxd5 7. Nc3 Qxg2 8. Bxc6+ Qxc6 9. Rg1 Bc5 10. Qe2 Bd4 11. b3 Bxc3 12. dxc3 Qxc3+ 13. Kf1 Qxa1 14. Qb5+ Bd7 0-1",
    B: "1703",
  },
  {
    W: "1001",
    T: "180+0",
    P: "1. e4 Nc6 2. Bc4 Nf6 3. Nf3 Nxe4 4. d3 Nf6 5. Ne5 Nxe5 0-1",
    B: "1253",
  },
  {
    W: "1089",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d3 Nge7 4. Nc3 b6 5. Be3 Bb7 6. Be2 Nc8 7. O-O d6 8. d4 exd4 9. Nxd4 Nxd4 10. Bxd4 c5 11. Be3 b5 12. Nd5 Bxd5 13. Qxd5 Be7 14. Qxa8 Nb6 15. Qxd8+ Bxd8 16. Rad1 Bc7 17. b3 O-O 18. a4 bxa4 19. bxa4 a5 20. Ra1 Re8 21. c4 Rxe4 22. f3 Rxe3 23. Bd1 Rc3 24. Be2 Bd8 25. Rac1 Bf6 26. Rxc3 Bxc3 27. Rc1 Bb4 28. f4 Nxa4 29. f5 Nb6 30. g4 a4 31. h4 a3 32. Ra1 f6 33. g5 d5 34. gxf6 dxc4 35. fxg7 Kxg7 36. h5 Kf6 37. Bg4 c3 38. h6 c2 39. Rc1 Bc3 40. Rxc2 a2 41. Rxc3 Nc4 42. Ra3 1-0",
    B: "1055",
  },
  {
    W: "2343",
    T: "60+0",
    P: "1. Nf3 c6 2. d3 d5 3. g3 Nf6 4. Bg2 h5 5. O-O h4 6. Nxh4 Rxh4 7. gxh4 Ng4 8. Bg5 f6 9. Bd2 e5 10. h3 Nh6 11. c4 Qe7 12. cxd5 cxd5 13. Nc3 Be6 14. Nxd5 Bxd5 15. Bxd5 Nc6 16. Qa4 Rc8 17. Rac1 Nf5 18. Bxc6+ bxc6 19. Rxc6 Rxc6 20. Qxc6+ Kf7 21. Qe4 Nd6 22. Qd5+ Kg6 23. Kh2 Qd7 24. Rg1+ Kh7 25. Bb4 Qf5 26. Qf3 Qxf3 27. exf3 Kg8 28. Rc1 Nf5 29. Rc8 Nxh4 30. Rxf8+ Kh7 31. Ra8 Nxf3+ 32. Kg3 Nd4 33. Rxa7 Nc6 34. Ra4 Kg6 35. Bc3 f5 36. b3 e4 37. dxe4 fxe4 38. b4 Ne5 39. Bxe5 Kf5 40. Bd4 Ke6 41. Be3 Kd5 42. b5 g5 43. Rxe4 Kxe4 44. a4 Kd5 45. a5 Kd6 46. a6 Kc7 47. a7 Kb7 48. b6 Ka8 49. Kg4 Kb7 50. Kxg5 Ka8 51. h4 Kb7 52. h5 Ka8 53. h6 Kb7 54. h7 Ka8 55. f3 Kb7 56. h8=Q Ka6 57. a8=Q+ Kb5 58. Qe4 Ka5 59. Qhe5+ Ka6 60. Qa8# 1-0",
    B: "2251",
  },
  {
    W: "1978",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. exd5 exd5 4. Nf3 Nf6 5. Bd3 Bd6 6. O-O O-O 7. Re1 c6 8. c3 Nbd7 9. Be3 Qc7 10. Nbd2 Re8 11. Qc2 Nf8 12. Bg5 h6 13. Bxf6 gxf6 14. Nf1 Be6 15. Qd2 Nh7 16. Qxh6 Ng5 17. Nxg5 Be7 18. Qh7+ 1-0",
    B: "2026",
  },
  {
    W: "1535",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 d6 5. c3 Be7 6. Nbd2 O-O 7. Nf1 a6 8. Ng3 b5 9. Bb3 Na5 10. Bc2 Bb7 11. d4 exd4 12. cxd4 c5 13. Qd3 cxd4 14. e5 Be4 15. Nxe4 Nxe4 16. Qxe4 f5 17. Qd3 dxe5 18. Nxe5 Nc4 19. g4 Nxe5 20. Qd1 d3 21. Bb1 Qd5 22. a3 Qxh1+ 23. Kd2 Qxd1+ 24. Kxd1 fxg4 25. Ba2+ Kh8 26. Bf4 Nf3 27. Rc1 Rxf4 28. Rc3 Rd8 29. Bd5 Ne5 30. Bf3 Rfd4 31. Be4 d2 32. b4 Nf3 33. h3 Rxe4 34. hxg4 Re1+ 35. Kc2 d1=Q+ 36. Kb2 Qa1+ 37. Kb3 Rb1+ 38. Kc2 Rc1+ 39. Kb3 Qxc3+ 0-1",
    B: "1566",
  },
  {
    W: "1712",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 d6 3. d4 f5 4. dxe5 fxe4 5. Nfd2 d5 6. f3 c6 7. fxe4 dxe4 8. Nxe4 Qxd1+ 9. Kxd1 Bf5 10. Bd3 1-0",
    B: "1756",
  },
  {
    W: "1538",
    T: "300+0",
    P: "1. d4 Nf6 2. h3 g6 3. g3 Bg7 4. Bg2 O-O 5. Nf3 d6 6. O-O Nbd7 7. a3 e5 8. Be3 e4 9. Nh2 d5 10. b3 Nh5 11. Qd2 Re8 12. Bh6 Bh8 13. e3 c5 14. c3 Nf8 15. Qe2 Qc7 16. Nd2 Be6 17. Rae1 Qc8 18. g4 Nf6 19. f3 cxd4 20. cxd4 exf3 21. Bxf3 Qc3 22. Rc1 Qb2 23. a4 N8d7 24. Rb1 Qc3 25. Rfc1 Qb4 26. Rc7 Ne4 27. Nxe4 dxe4 28. Bxe4 1-0",
    B: "1460",
  },
  {
    W: "1745",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 d6 3. Nc3 Be7 4. d4 Be6 5. Be3 exd4 6. Nxd4 Nf6 7. f3 c5 8. Nb3 Nc6 9. Qe2 Bxb3 10. axb3 O-O 11. O-O-O a6 12. Bxc5 dxc5 13. Rxd8 Rfxd8 14. h4 Nd4 15. Qf2 a5 16. g4 b6 17. g5 Nh5 18. f4 f6 19. Bh3 fxg5 20. hxg5 Rf8 21. Ne2 Bxg5 22. Nxd4 Bxf4+ 23. Kb1 Bg3 24. Qe2 Nf4 25. Qg4 Nxh3 26. Rxh3 Rf1+ 27. Ka2 Rf4 28. Rxg3 Rxg4 29. Rxg4 cxd4 0-1",
    B: "1758",
  },
  {
    W: "1070",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. g3 Nf6 4. d3 d5 5. Nbd2 d4 6. Bg2 Bg4 7. O-O Qd7 8. h3 Bxf3 9. Nxf3 h6 10. c3 O-O-O 11. cxd4 Nxd4 12. Nxd4 Qxd4 13. Be3 Qxb2 14. d4 Bd6 15. Qb1 Qc3 16. dxe5 Bxe5 17. Bxa7 Qa5 18. Be3 Bxa1 19. Qxa1 Rhe8 20. e5 Rxe5 21. Qb2 Rb5 22. Bd2 Qa6 23. Qc3 Qxa2 24. Rc1 c6 25. h4 Rxd2 26. Bh3+ Kb8 27. Qe5+ Ka7 28. Qe3+ Kb8 29. Qf4+ Ka7 30. Qe3+ Kb8 31. Qf4+ Ka7 32. Qe3+ 1/2-1/2",
    B: "1104",
  },
  {
    W: "1817",
    T: "300+0",
    P: "1. e4 b6 2. Nf3 Bb7 3. Bc4 e6 4. d3 g6 5. Bg5 Bg7 6. Bxd8 Kxd8 7. Qd2 Bxb2 8. c3 Bxa1 9. Na3 Ne7 10. O-O Bxc3 11. Qxc3 Rg8 12. Qf6 Nc6 13. Qxf7 a6 14. Bxe6 dxe6 15. Qxe6 Ke8 16. Ne5 Nd8 17. Qd7+ Kf8 18. Qxc7 Rc8 19. Qxb6 Rg7 20. Qf6+ Kg8 21. Nac4 Bc6 22. Nd6 Ba4 23. Nxc8 1-0",
    B: "1806",
  },
  {
    W: "1562",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. d4 Bg4 4. Be2 Nc6 5. d5 Nb8 6. O-O Nf6 7. Bg5 Be7 8. h3 Bh5 9. Bxf6 Bxf6 10. Nh2 Bg6 11. Nd2 O-O 12. c4 c6 13. f4 exf4 14. Rxf4 Be5 15. Rf1 cxd5 16. exd5 Nd7 17. Bg4 Nc5 18. Qe2 f5 19. Bh5 Qg5 20. Bxg6 Qxg6 21. Ndf3 Bxb2 22. Rab1 Bf6 23. Qc2 Ne4 24. Rxb7 Qg3 25. a4 g5 26. Rd7 g4 27. hxg4 fxg4 28. Qxe4 gxf3 29. Rxf3 Qg5 30. Qxh7# 1-0",
    B: "1563",
  },
  {
    W: "1440",
    T: "300+0",
    P: "1. e4 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qd1 e5 5. Nc3 Nf6 6. Nf3 d6 7. Bd3 Bg4 8. O-O Be7 9. h3 Bh5 10. g4 Bg6 11. Nh4 Qc7 12. Nxg6 hxg6 13. Kh2 Nd4 14. Nd5 Nxd5 15. exd5 O-O-O 16. c3 Nb5 17. Bxb5 Rdf8 18. Be3 b6 19. Ba6+ Kb8 20. Rc1 f5 21. f3 f4 22. Bf2 Bg5 23. Re1 Qe7 24. Re4 Bh4 25. Bg1 Bg3+ 26. Kg2 Qh4 27. Kf1 Qxh3+ 28. Ke2 Qg2+ 29. Kd3 Rh1 30. Rc2 Qh3 31. Kc4 Bh2 32. Re1 Bxg1 33. Rxg1 Rxg1 34. Qxg1 Qxf3 35. Kb3 Qxd5+ 36. c4 Qf3+ 37. Rc3 Qe2 38. Rc2 Qe3+ 39. Rc3 Qxg1 40. c5 dxc5 0-1",
    B: "1506",
  },
  {
    W: "1427",
    T: "300+0",
    P: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. Bg5 Nbd7 5. Nf3 e6 6. Ne5 Nxe5 7. dxe5 h6 8. Bxf6 gxf6 9. exf6 Qxf6 10. e3 Bb4 11. Be2 Bxc3+ 12. bxc3 Qxc3+ 13. Qd2 Qxd2+ 14. Kxd2 dxc4 15. Bxc4 b5 16. Bb3 c5 17. Rac1 c4 18. Bc2 Bb7 19. f3 Rg8 20. g4 Bxf3 21. Rhg1 Rd8+ 22. Kc3 Bxg4 23. a4 bxa4 24. Bxa4+ Kf8 25. h3 Be2 26. Rxg8+ Kxg8 27. Rg1+ Kf8 28. Rg4 Rd3+ 29. Kxc4 Rxe3+ 30. Kd4 Rd3+ 31. Ke5 Bxg4 32. Bd7 Rxd7 33. hxg4 Rd5+ 0-1",
    B: "1382",
  },
  {
    W: "1889",
    T: "300+0",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4 O-O 6. e5 dxe5 7. fxe5 Nfd7 8. Nf3 c5 9. e6 fxe6 10. d5 exd5 11. cxd5 Nf6 12. Bc4 Kh8 13. O-O a6 14. Bg5 b5 15. Be2 Nbd7 16. Qd2 Bb7 17. Rad1 Qa5 18. Rfe1 b4 19. Nb1 Qxa2 20. Bf1 Nxd5 21. Bxe7 Nxe7 22. Rxe7 Bxf3 23. gxf3 Ne5 24. Rxe5 Bxe5 25. Qd7 Qxb2 26. Nd2 Rad8 27. Qe6 Qd4+ 28. Kh1 Qf4 29. Nc4 Qxh2# 0-1",
    B: "1952",
  },
  {
    W: "801",
    T: "300+0",
    P: "1. e4 e6 2. d3 Nc6 3. Nf3 Nf6 4. g3 d5 5. Bg2 dxe4 6. dxe4 Nxe4 7. Qe2 f5 8. Qc4 Bb4+ 9. Qxb4 Nxb4 10. c3 Nc2+ 11. Ke2 Nxa1 12. Ng5 Nc2 13. Bd2 h6 14. Na3 Nxa3 15. bxa3 hxg5 16. Rd1 Rxh2 17. Bf3 e5 18. Kf1 Nxd2+ 19. Ke2 Be6 20. Bh5+ g6 21. Bxg6+ Bf7 22. Bxf5 Bc4+ 23. Ke1 Nf3# 0-1",
    B: "870",
  },
  {
    W: "1342",
    T: "300+0",
    P: "1. d4 d5 2. c4 dxc4 3. Nc3 Nc6 4. Nf3 Be6 5. Bg5 Bg4 6. e3 Bxf3 7. Qxf3 Nb4 8. O-O-O Nc6 9. d5 Ne5 10. Qe4 f6 11. Bf4 Qd6 12. Bxc4 O-O-O 13. Nb5 Qb4 14. Nxa7+ Kb8 15. a3 Qc5 16. Nb5 Nxc4 17. Bxc7+ Ka8 18. Bxd8 Qxb5 19. Bc7 Qxb2# 0-1",
    B: "1338",
  },
  {
    W: "1991",
    T: "300+0",
    P: "1. e4 c6 2. d4 d5 3. e5 e6 4. c3 Ne7 5. Nf3 c5 6. Bd3 cxd4 7. cxd4 Nbc6 8. Be3 Nf5 9. a3 Nxe3 10. fxe3 Be7 11. Nc3 Bh4+ 12. g3 Be7 13. Bc2 a6 14. Qd3 g6 15. O-O-O Bd7 16. Kb1 Rc8 17. h4 Na5 18. g4 Nc4 19. g5 Qb6 20. b4 Nxa3+ 21. Ka2 Qxb4 22. Rb1 Qxc3 23. Qxc3 Rxc3 24. Bb3 Nxb1 0-1",
    B: "1993",
  },
  {
    W: "1000",
    T: "300+0",
    P: "1. d4 d5 2. Bf4 Nc6 3. Nc3 f6 4. e3 e5 5. dxe5 fxe5 6. Bg3 Bb4 7. Qd3 d4 8. O-O-O Bxc3 9. bxc3 dxc3 10. Qxc3 Qf6 11. Bb5 Bf5 12. Bxc6+ Qxc6 13. Qxe5+ Qe6 14. Qxg7 Qxa2 15. Qxh8 Qa1+ 16. Kd2 Bg4 17. Rxa1 Rd8+ 18. Kc3 Bd7 19. Qxg8+ 1-0",
    B: "926",
  },
  {
    W: "1181",
    T: "300+0",
    P: "1. e4 d5 2. e5 f6 3. f4 fxe5 4. fxe5 e6 5. Nf3 b6 6. d4 Bb7 7. Bg5 Be7 8. h4 h6 9. Bf4 Nc6 10. Bb5 a6 11. Ba4 b5 12. Bb3 h5 13. Bg5 Nh6 14. Bxe7 Qxe7 15. Ng5 Nf5 16. a3 Ncxd4 17. Ba2 Ng3 18. Rh3 Ngf5 19. c3 Nc6 20. Nd2 O-O-O 21. Ndf3 g6 22. Qd2 d4 23. Nxe6 Rd7 24. Nexd4 Nfxd4 25. Nxd4 Nxd4 26. cxd4 Rf8 27. O-O-O Rf4 28. Qxf4 b4 29. a4 Bxg2 30. Rg3 Bc6 31. Rxg6 Bxa4 32. Be6 Bxd1 33. Kxd1 Qh7 34. Qf8+ Kb7 35. Rh6 Qd3+ 36. Kc1 Qe3+ 37. Kb1 Qe1+ 38. Ka2 Rxd4 39. Rh8 Rd1 40. Qb8+ Kc6 41. Qe8+ Kb7 42. Qa8+ Kb6 43. Rb8+ Kc5 44. Qa7+ Kc6 45. Rb6+ Kc5 46. Qxc7+ Kd4 47. Qc4+ Kxe5 48. Qc7+ Kf6 49. Qf4+ Ke7 50. Qf7+ Kd8 51. Qd7+ Rxd7 52. Bxd7 Kxd7 53. Rxa6 Qe6+ 0-1",
    B: "1200",
  },
  {
    W: "2137",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 d6 5. d4 exd4 6. cxd4 Bb6 7. h3 h6 8. Nc3 Nge7 9. a3 O-O 10. O-O a6 11. b4 Ng6 12. Be3 Nh4 13. Ne1 Kh7 14. Nd5 Ba7 15. Qd3 Kh8 16. a4 Ne7 17. Nxe7 Qxe7 18. b5 axb5 19. axb5 Bd7 20. Nc2 Bb6 21. Nb4 Be6 22. Nd5 Bxd5 23. Bxd5 Rab8 24. f4 f5 25. e5 dxe5 26. fxe5 Rfd8 27. Qc4 Rf8 28. Bf3 f4 29. Bf2 Nf5 30. Rfe1 Rfd8 31. Rad1 Qd7 32. Bg4 Rf8 33. e6 Qe7 34. Bxf5 Rxf5 35. d5 Rd8 36. Bxb6 cxb6 37. d6 Qe8 38. d7 Qe7 39. Qe4 Rf6 40. Rc1 Rff8 41. Rc7 g5 42. Rec1 Rf6 43. Rc8 Kg7 44. Rxd8 Qxd8 45. e7 Qxd7 46. e8=Q Qxb5 47. Rc7+ 1-0",
    B: "2106",
  },
  {
    W: "1634",
    T: "300+0",
    P: "1. d4 d5 2. e3 Nf6 3. h3 c5 4. dxc5 Nc6 5. a3 e6 6. b4 a5 7. b5 Na7 8. a4 Bxc5 9. Bb2 O-O 10. Nf3 Bd7 11. Nbd2 Nc8 12. c4 Nd6 13. Be2 Bb4 14. O-O Bxd2 15. Nxd2 dxc4 16. Bxc4 Rc8 17. Be2 Nd5 18. Bf3 Rc7 19. Nb3 b6 20. e4 Nb4 21. Qxd6 Qc8 22. Qg3 1-0",
    B: "1801",
  },
  {
    W: "1961",
    T: "300+0",
    P: "1. d4 d5 2. Nf3 Nc6 3. Bg5 f6 4. Bh4 h6 5. e3 h5 6. Bd3 g5 7. Bg6+ Kd7 8. Bf5+ e6 9. Bh3 g4 10. Nc3 gxh3 11. gxh3 Nh6 12. Qd3 Nf5 13. Bg3 Nb4 14. Qb5+ c6 15. Qa4 Nxg3 16. hxg3 b5 17. Nxb5 cxb5 18. Qxb5+ Nc6 19. c4 a5 20. cxd5 Bb4+ 21. Ke2 Ba6 22. dxc6+ Kc7 23. Qxa6 Rxa6 24. a3 Bd6 25. d5 exd5 26. Nd4 Be5 27. Ne6+ Kxc6 28. Nxd8+ Rxd8 29. Rhc1+ Kd6 30. Rc2 Rb6 31. Rb1 Rdb8 32. b4 axb4 33. axb4 Rxb4 34. Rxb4 Rxb4 35. f4 Ba1 36. Kf3 Rb3 37. Ra2 Bd4 38. Re2 Bc5 39. Kf2 f5 40. Ra2 Bxe3+ 41. Kg2 Bd4 42. Ra6+ Kc5 43. Rh6 Rb2+ 44. Kh1 Bf2 45. Rxh5 Bxg3 46. Rxf5 Rh2+ 47. Kg1 Rxh3 48. Kg2 Rh4 49. Kxg3 Rh6 50. Rg5 Kd4 51. f5 Ke5 52. Kg4 Rh1 53. Rg6 Rg1+ 54. Kh5 Rh1+ 55. Kg5 Rg1+ 56. Kh6 Rh1+ 57. Kg7 Kxf5 58. Rf6+ Ke5 59. Rg6 d4 60. Rg5+ Ke4 61. Rg6 d3 62. Re6+ Kd5 63. Rd6+ Kxd6 64. Kf6 Rf1+ 65. Kg5 Ke5 66. Kg4 Ke4 0-1",
    B: "1971",
  },
  {
    W: "1586",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Bc5 3. d4 exd4 4. Nxd4 Nc6 5. Nxc6 dxc6 6. Be2 Qxd1+ 7. Bxd1 Nf6 8. O-O Nxe4 9. Re1 Bf5 10. Be3 Bxe3 11. Rxe3 O-O-O 12. Bf3 Ng5 13. Nc3 Nxf3+ 14. Rxf3 Bxc2 15. Rxf7 g5 16. Rc1 Be4 17. Nxe4 Rhe8 18. Nxg5 Re2 19. Rb1 Rdd2 20. Nxh7 Kd8 21. Ng5 Ke8 22. h4 Re5 23. g3 Rxg5 24. hxg5 Kxf7 25. f4 Kg6 26. a3 a5 27. b4 axb4 28. axb4 Rc2 29. Rb3 b5 30. Kf1 c5 31. bxc5 Rxc5 32. Ke1 c6 33. Kf2 Rc2+ 34. Kf3 Kf5 35. g4+ Kg6 36. f5+ Kxg5 37. Re3 b4 38. Kg3 Rc3 39. Kf2 Rxe3 40. Kxe3 c5 41. Kd3 Kf6 42. Kc4 Kg5 43. Kb3 Kf6 44. Kc4 Kg5 45. Kb3 Kf6 46. Kc4 Ke5 47. Kb3 Kd6 48. Kc4 Ke5 49. Kb3 Kd5 50. f6 c4+ 51. Kxb4 Ke5 52. g5 Ke6 53. Kxc4 Kf7 54. Kd5 Kg6 55. Ke6 Kxg5 56. Kf7 Kf5 57. Kg7 Ke6 58. f7 Ke7 59. f8=Q+ Ke6 60. Qd8 Ke5 61. Kf7 Kf5 62. Qe8 Kf4 63. Kf6 Kf3 64. Kf5 Kg3 65. Kg5 Kf3 66. Kf5 Kg3 67. Ke4 Kf2 68. Kd3 Kf3 69. Qe4+ Kg3 70. Qf5 Kg2 71. Ke3 Kg3 72. Qf3+ Kh4 73. Qg2 Kh5 74. Kf4 Kh6 75. Kf5 Kh7 76. Kf6 Kh8 77. Kf7 Kh7 78. Qg7# 1-0",
    B: "1590",
  },
  {
    W: "2054",
    T: "180+0",
    P: "1. d4 d5 2. e3 Nf6 3. c4 c6 4. Nf3 e6 5. Bd3 Nbd7 6. O-O dxc4 7. Bxc4 a6 8. a3 b5 9. Bd3 Bb7 10. Nbd2 c5 11. dxc5 Nxc5 12. Be2 Be7 13. b4 Nce4 14. Nxe4 Nxe4 15. Bb2 O-O 16. Qxd8 Rfxd8 17. Rfd1 Rac8 18. Rxd8+ Rxd8 19. Rd1 Rxd1+ 20. Bxd1 h6 21. Bc2 g5 22. Bxe4 Bxe4 23. Nd2 Bd5 24. e4 Ba2 25. f3 f5 26. exf5 exf5 27. Kf2 Kf7 28. Ke3 Ke6 29. g3 Bd6 30. f4 g4 31. Bd4 h5 32. Bc5 Bxc5+ 33. bxc5 Kd5 34. c6 Kxc6 35. Kd4 a5 36. Ke5 b4 37. axb4 a4 38. Kxf5 Kb5 39. Kg5 Bf7 40. Kf6 Be8 41. Ke7 Bc6 42. f5 Kxb4 43. f6 Bd5 44. f7 Bxf7 45. Kxf7 Kc3 46. Nb1+ Kb2 47. Nd2 Kc2 48. Nc4 Kb3 49. Nd2+ Kc2 50. Nc4 Kb3 51. Nd2+ Kc2 1/2-1/2",
    B: "2040",
  },
  {
    W: "2216",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 e5 7. Nb3 Be7 8. f3 h5 9. Qd2 Be6 10. O-O-O Nbd7 11. Kb1 Rc8 12. g3 b5 13. Bxb5 axb5 14. Nxb5 Nb6 15. Nxd6+ Bxd6 16. Bxb6 Qxb6 17. Qxd6 Qxd6 18. Rxd6 Ke7 19. Rhd1 h4 20. g4 h3 21. Nc1 Bc4 22. R6d2 Rhd8 23. b3 Ba6 24. Kb2 Nh7 25. c4 Rxd2+ 26. Rxd2 Ng5 27. Rd3 Ne6 28. Ne2 g6 29. Kc3 Nc5 30. Rd1 Ne6 31. a4 Ng5 32. Rd3 Rd8 33. Rxd8 Kxd8 34. Ng1 Kc7 35. a5 Bb7 36. b4 Kc6 37. b5+ Kc5 38. a6 Bc8 39. Kd3 Ne6 40. Nxh3 Nc7 41. Nf2 Be6 42. Kc3 Bxc4 43. Nd3+ Bxd3 44. Kxd3 Kxb5 45. a7 Kb6 46. h4 Kxa7 47. h5 gxh5 48. gxh5 Ne6 49. h6 Ng5 50. Ke3 Kb7 51. f4 exf4+ 52. Kxf4 Nh7 53. e5 Kc7 54. e6 fxe6 55. Kg4 Kd6 56. Kh5 e5 57. Kg6 Nf8+ 58. Kg7 Ke7 59. h7 Nxh7 60. Kxh7 e4 0-1",
    B: "2316",
  },
  {
    W: "2419",
    T: "60+0",
    P: "1. e4 c5 2. e5 Nc6 3. Nc3 d6 4. Nf3 dxe5 5. Bb5 f6 6. d3 g6 7. Be3 Bg7 8. Qd2 Bd7 9. h4 Nd4 10. Bxd7+ Qxd7 11. h5 O-O-O 12. hxg6 hxg6 13. Rxh8 Bxh8 14. O-O-O e6 15. Ne4 b6 16. c3 Nxf3 17. gxf3 Ne7 18. Rh1 Nf5 19. f4 exf4 20. Bxf4 g5 21. Be3 Bg7 22. d4 cxd4 23. cxd4 Nxd4 24. Kb1 Nc6 25. Rc1 Qxd2 26. Nxd2 Kb7 27. Ne4 Rd5 28. a4 Bf8 29. a5 Nxa5 30. Nxf6 Rd3 31. Nd7 Rxd7 32. Bxg5 Nc6 33. Be3 Bc5 34. Rh1 Bxe3 35. fxe3 Kc7 36. Rh6 Kd6 37. Rg6 Ne5 38. Rh6 a5 39. Rh5 b5 40. Kc2 a4 41. Rh4 Rc7+ 42. Kd1 Rc4 43. Kd2 Rxh4 0-1",
    B: "2363",
  },
  {
    W: "1782",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d4 e6 5. Nf3 c6 6. Ne5 Be7 7. f4 Nd7 8. g3 Ngf6 9. Bg2 Nb6 10. b3 Nbd5 11. Nxd5 Nxd5 12. c4 Nf6 13. O-O O-O 14. Kh1 Nd7 15. Bb2 Nxe5 16. fxe5 Bd7 17. Qg4 a6 18. d5 cxd5 19. cxd5 exd5 20. e6 Bxe6 21. Qxg7# 1-0",
    B: "1665",
  },
  {
    W: "1566",
    T: "1800+0",
    P: "1. d4 e5 2. Nf3 e4 3. Ne5 d6 4. Nc4 d5 5. Ncd2 Nc6 6. e3 Nf6 7. Bb5 Bd6 8. Bxc6+ bxc6 9. Nc3 Ba6 10. b3 c5 11. Bb2 Qd7 12. Ba3 c4 13. Bxd6 Qxd6 14. bxc4 dxc4 15. Ndxe4 Nxe4 16. Nxe4 Qd5 17. Nc3 Qxg2 18. Ke2 Qg4+ 19. f3 Qg2+ 0-1",
    B: "1571",
  },
  {
    W: "2048",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nf6 3. d4 Nxe4 4. d5 d6 5. Bd3 Bf5 6. Bxe4 Bxe4 7. O-O Bxf3 8. Qxf3 Nd7 9. Re1 Ne5 10. Qg3 Qd7 11. Bf4 Ng6 12. Bg5 O-O-O 13. b4 h6 14. Be3 cxb4 15. Bxa7 Qa4 16. Bb6 Rd7 17. Nc3 bxc3 18. Qxc3+ Kb8 19. Rab1 Ne5 20. Rb4 Qa6 21. Rxe5 dxe5 22. Qxe5+ Ka8 23. Be3 Qxa2 24. h3 e6 25. dxe6 Bxb4 26. exd7 Qb1+ 27. Kh2 Rd8 28. Qe8 Ba5 29. Qxf7 Qb6 30. Bxb6 Bxb6 31. Qxg7 Bc7+ 32. g3 Bd6 33. Qa1+ Kb8 34. Qa5 Rxd7 35. h4 Rc7 36. Kh3 Rxc2 37. Qd8+ Ka7 38. Qxd6 Rc6 39. Qa3+ Ra6 40. Qd3 Rc6 41. Qe3+ Rb6 42. g4 Ka6 43. g5 Kb5 44. g6 Rxg6 45. Qxh6 Rc6 0-1",
    B: "2110",
  },
  {
    W: "1641",
    T: "60+0",
    P: "1. e4 c5 2. Nc3 d6 3. g3 g6 4. Bg2 Bg7 5. Nge2 e6 6. O-O Nc6 7. d3 Nge7 8. f4 O-O 9. Bd2 Qc7 10. e5 dxe5 11. fxe5 Nxe5 12. Ne4 N7c6 13. c3 b6 14. d4 cxd4 15. cxd4 Nc4 16. d5 exd5 17. b3 Nxd2 18. Rc1 Nxe4 19. Qxd5 Bb7 20. Bxe4 Qe7 21. Qc4 Rfe8 22. Rf4 Bh6 23. Bxc6 Bxc6 24. Qxc6 Qxe2 25. Rcf1 Bxf4 26. gxf4 Qe3+ 27. Kg2 Rac8 28. Qf3 Rc2+ 29. Kg3 1-0",
    B: "1611",
  },
  {
    W: "1926",
    T: "60+0",
    P: "1. d4 d5 2. c4 Nc6 3. Nc3 Nf6 4. Nf3 Bg4 5. Bg5 Bxf3 6. gxf3 e6 7. e4 Be7 8. cxd5 exd5 9. Bxf6 Bxf6 10. Nxd5 Nxd4 11. Bc4 c5 12. Nxf6+ Qxf6 13. Qa4+ Ke7 14. O-O-O a6 15. Qa3 b6 16. f4 Qxf4+ 17. Kb1 Rhd8 18. Rhe1 Kf8 19. f3 Nxf3 20. Rf1 Nd2+ 21. Kc2 Nxf1 22. Rxd8+ Rxd8 23. Qxa6 Qd2+ 24. Kb3 Ne3 25. Qxb6 Nxc4 26. Kxc4 Qd4+ 27. Kb5 Qb4+ 28. Kc6 Qxb6+ 29. Kxb6 Rb8+ 30. Kxc5 Rxb2 31. a4 Ra2 32. Kb5 Rxa4 33. Kxa4 Ke7 34. Kb4 Ke6 35. Kc4 Ke5 36. Kd3 f5 37. exf5 Kxf5 38. Ke3 Kg4 39. Kf2 Kh3 40. Kg1 g5 41. Kh1 g4 0-1",
    B: "1946",
  },
  {
    W: "2031",
    T: "60+0",
    P: "1. d4 c5 2. d5 d6 3. c4 e5 4. dxe6 fxe6 5. Nc3 a6 6. e4 Nf6 7. Bd3 g6 8. Bh6 Bg7 9. Bxg7 1-0",
    B: "1954",
  },
  {
    W: "1690",
    T: "60+0",
    P: "1. d4 d5 2. c4 Nf6 3. Nc3 e5 4. dxe5 Nfd7 5. cxd5 Nxe5 6. e3 Ng6 7. Nf3 c6 8. e4 cxd5 9. exd5 Bb4 10. Bb5+ Qd7 11. Bxd7+ Bxd7 12. Qe2+ Ne7 13. Bg5 f6 14. Bf4 O-O 15. O-O Re8 16. Qd2 Bf5 17. Rfe1 Nd7 18. a3 Ng6 19. axb4 Rxe1+ 20. Rxe1 Nge5 21. Nxe5 fxe5 22. Bxe5 Bg6 23. Qd4 Rd8 24. Bxg7 b6 25. Bh8 Kf8 26. Qg7# 1-0",
    B: "1694",
  },
  {
    W: "1907",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d4 Nf6 5. Nf3 e6 6. Be3 Be7 7. Bd3 O-O 8. O-O Nc6 9. Qd2 Nb4 10. a3 Nxd3 11. cxd3 a6 12. Ne5 Rb8 13. Ne4 Re8 14. Bg5 h6 15. Bxh6 Nxe4 16. dxe4 gxh6 17. Qxh6 Bg5 18. Qh5 Qf6 19. g3 Qh6 20. Qxf7+ Kh8 21. f4 Rf8 22. fxg5 Qg7 23. Qxg7+ Kxg7 24. Rxf8 Kxf8 25. Rf1+ Kg8 26. Rf6 b5 27. h4 Rb6 28. b4 Bb7 29. Nd7 Rd6 30. Nc5 Bc8 31. Nxe6 Rxe6 32. Rxe6 Bxe6 33. d5 Bd7 34. Kf2 Kf7 35. Ke3 Kg6 36. Kf4 Kh5 37. Ke5 c6 38. Kf6 cxd5 39. exd5 Kg4 40. g6 Kf3 41. g7 Ke4 42. g8=Q Kd4 43. Qe6 Kc3 44. Qxd7 Kb3 45. Qa7 Kc4 46. Qxa6 Kxd5 47. Qxb5+ Kd4 48. a4 Kc3 49. a5 Kb3 50. a6 Ka3 51. a7 Kb3 52. a8=Q Kc3 53. Qa3+ Kd2 54. Qbd3+ 1-0",
    B: "1873",
  },
  {
    W: "2223",
    T: "60+0",
    P: "1. e4 c5 2. Qe2 Nc6 3. g3 g6 4. Bg2 Bg7 5. f4 d6 6. Nf3 e6 7. c3 Nge7 8. O-O O-O 9. d3 b6 10. Be3 Bb7 11. Rd1 Qc7 12. Qf2 f5 13. Nbd2 a6 14. d4 fxe4 15. Nxe4 Rad8 16. Neg5 Nd5 17. Nxe6 Qe7 18. Nxf8 Nxe3 19. Re1 Re8 20. Re2 Bxf8 21. Rae1 cxd4 22. cxd4 Qf7 23. Rxe3 Rxe3 24. Qxe3 Bg7 25. Ng5 Bxd4 26. Nxf7 Bxe3+ 27. Rxe3 Kxf7 28. Bd5+ Kf6 29. Rc3 Na5 30. Rc7 Bxd5 31. Rd7 Bxa2 32. Rxd6+ Be6 33. Rxb6 Nc4 34. Rc6 Nxb2 35. Rxa6 Nd3 36. Ra3 Nc5 37. Rc3 Ne4 38. Re3 Nd2 39. Kf2 Nc4 40. Re4 Nd2 41. Re2 Nc4 42. Re4 Nd6 43. Ra4 Nf5 44. Ra6 1-0",
    B: "2291",
  },
  {
    W: "2251",
    T: "60+0",
    P: "1. b3 e5 2. Bb2 d6 3. e3 Nf6 4. Be2 Be7 5. f4 O-O 6. Nf3 exf4 7. exf4 Bg4 8. O-O Ne4 9. Nc3 Nxc3 10. Bxc3 Bf6 11. Bxf6 Qxf6 12. d4 Nc6 13. Qd3 Rae8 14. c4 Bxf3 15. Bxf3 Qxd4+ 16. Qxd4 Nxd4 17. Bxb7 Re2 18. Rf2 Rfe8 19. Raf1 g6 20. h3 Nf5 21. Kh2 Nd4 22. Bd5 Kg7 23. f5 Nxf5 24. Rxf5 gxf5 25. Rxf5 R8e7 26. a4 Rb2 27. c5 f6 28. cxd6 cxd6 29. h4 Ree2 30. Kh3 Re3+ 31. g3 Kg6 32. Rf4 h5 33. Bc4 Rc3 34. Rf1 Rcc2 35. Rh1 Rc3 36. Bd5 Rbxb3 37. Bxb3 Rxb3 38. Rd1 Ra3 39. Rxd6 Rxa4 40. Kh2 a5 41. Kh3 Ra2 42. Rb6 a4 43. Ra6 a3 44. Rb6 Ra1 45. Ra6 a2 0-1",
    B: "2200",
  },
  {
    W: "2414",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d3 d6 6. O-O h6 7. Be3 Bb6 8. Nbd2 O-O 9. Bb3 Be6 10. Bc2 Re8 11. Re1 d5 12. Bxb6 axb6 13. a3 dxe4 14. dxe4 Qe7 15. Qe2 Rad8 16. Nf1 Nd7 17. h3 Nc5 18. Ng3 Nb3 19. Rad1 Rd7 20. Nf5 Qf8 21. g4 Red8 22. g5 hxg5 23. Nxg5 Bxf5 24. exf5 f6 25. Ne6 Qe7 26. Nxd8 Rxd8 27. Rxd8+ Nxd8 28. Bxb3+ Nf7 29. Qc4 c6 30. Qxf7+ Qxf7 31. Bxf7+ Kxf7 32. Rd1 Ke7 33. h4 b5 34. Rd3 b4 35. Rg3 bxc3 36. bxc3 b5 37. Rxg7+ Kd6 38. Rg6 Kc5 39. h5 Kc4 40. h6 Kxc3 41. h7 Kb3 42. h8=Q c5 43. Qxf6 c4 44. Qxe5 Ka4 0-1",
    B: "2379",
  },
  {
    W: "1773",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Nb5 a6 6. N5c3 Bb4 7. Bd2 Nf6 8. a3 Bxc3 9. Nxc3 O-O 10. Bc4 Re8 11. O-O b5 12. Ba2 Bb7 13. h3 Nd4 14. Nd5 Nxd5 15. Bxd5 Bxd5 16. exd5 Rc8 17. c3 Nf5 18. Re1 Ne7 19. Rxe5 d6 20. Re1 Nxd5 21. Rxe8+ Qxe8 22. Qe1 Nf6 23. Bf4 Qe4 24. Bxd6 Re8 25. Qxe4 Rxe4 26. Bg3 Re2 27. Rb1 g6 28. Kf1 Kg7 29. Kxe2 Ne4 30. Be5+ f6 31. Bd4 Nd6 32. f4 Nf5 33. g4 Nxd4+ 34. cxd4 1-0",
    B: "1719",
  },
  {
    W: "1348",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Nxe5 Nxe5 5. d4 Nc6 6. d5 Nb4 7. a3 Na6 8. Bg5 h6 9. Bxf6 Qxf6 10. Bc4 Bc5 11. Qd2 d6 12. O-O-O Bg4 13. h3 Bh5 14. g4 Bg6 15. f4 h5 16. f5 Bh7 17. g5 Qe5 18. g6 fxg6 19. fxg6 Bxg6 20. Rdg1 Bxe4 21. Nxe4 Qxe4 22. Rxg7 O-O-O 23. Bd3 Qh4 24. Rhg1 Qxh3 25. R7g5 1-0",
    B: "1131",
  },
  {
    W: "2187",
    T: "180+2",
    P: "1. c4 Nf6 2. Nc3 g6 3. Nf3 Bg7 4. d4 c6 5. e4 d6 6. Bd3 O-O 7. Bg5 Bg4 8. Qd2 Re8 9. h3 Bxf3 10. gxf3 e5 11. d5 Nbd7 12. h4 h5 13. f4 exf4 14. Qxf4 cxd5 15. Nxd5 Nxd5 16. cxd5 Qa5+ 17. Qd2 Qxd2+ 18. Bxd2 Bxb2 19. Rb1 Be5 20. Rxb7 Nc5 21. Ba6 Nxa6 22. f4 Bg7 0-1",
    B: "1922",
  },
  {
    W: "1089",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Nf6 4. Nc3 c5 5. Ng5 h6 6. Nxf7 Qe7 7. Nxh8 g6 8. Nxg6 Qg7 9. Nxf8 Kxf8 10. O-O b6 11. d3 Ba6 12. Bxa6 Nxa6 13. a3 Re8 14. Nb5 h5 15. Nxd6 Rd8 16. Nb5 c4 17. Nxa7 cxd3 18. Nb5 Nc5 19. cxd3 Nxd3 20. Be3 Nxb2 21. Qxd8+ Kf7 22. Qxb6 Nxe4 23. Rac1 Qg4 24. Rc7+ Kg8 25. Qb8+ Qc8 26. Qxc8# 1-0",
    B: "1098",
  },
  {
    W: "1859",
    T: "600+0",
    P: "1. c4 d5 2. cxd5 Nf6 3. Nf3 Nxd5 4. g3 c6 5. Bg2 Bg4 6. Qb3 b6 7. Ne5 Bd7 8. Bxd5 cxd5 9. Qxd5 Be6 10. Qxa8 Bd5 11. Qxa7 Bxh1 12. Qa4+ Nd7 13. Nxd7 Qxd7 14. Qxd7+ Kxd7 15. f3 Bg2 16. Kf2 Bh3 17. g4 h5 18. g5 e6 19. d3 Bc5+ 20. e3 Bf5 21. d4 Bd6 22. h4 Rc8 23. Nc3 Bb4 24. Bd2 1-0",
    B: "1885",
  },
  {
    W: "1155",
    T: "600+0",
    P: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. d5 Ng4 5. Be2 Ne5 6. Nf3 Bg7 7. Nxe5 Bxe5 8. O-O O-O 9. Bh6 Re8 10. f4 Bf6 11. f5 c6 12. fxg6 fxg6 13. Bg4 cxd5 14. Nxd5 Bg7 15. Bxg7 Kxg7 16. Bxc8 Qxc8 17. Qd4+ e5 18. Qb4 Qc5+ 19. Qxc5 dxc5 20. Nc7 Nc6 21. Nxe8+ Rxe8 22. Rad1 Kh6 23. Rd5 b6 24. Rd6 Nb4 25. Rd7 a6 26. Rff7 Nxc2 27. Rxh7+ Kg5 28. Rde7 Rf8 29. Rxe5+ Kg4 30. h3+ Kg3 31. Rd5 Ne3 32. Rd3 Rf1# 0-1",
    B: "1109",
  },
  {
    W: "1516",
    T: "600+0",
    P: "1. b3 e5 2. Bb2 Nc6 3. e4 Nf6 4. d3 Bb4+ 5. Nd2 d5 6. f3 dxe4 7. fxe4 Bg4 8. Be2 Bxe2 9. Qxe2 Bxd2+ 10. Qxd2 Nd4 11. Nf3 Nc6 12. Nxe5 Nxe5 13. Bxe5 Qe7 14. Qc3 O-O-O 15. O-O-O Rhe8 16. Bg3 Ng4 17. h3 Ne3 18. Rd2 g6 19. Bf4 Nxc2 20. Rxc2 f5 21. Bxc7 Qxc7 22. Qxc7# 1-0",
    B: "1484",
  },
  {
    W: "1424",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. Nc3 d6 5. b3 Bg4 6. h3 Bh5 7. Bb2 Qd7 8. a4 f5 9. exf5 Qxf5 10. Be2 Nf6 11. g4 Nxg4 12. hxg4 Bxg4 13. Nd5 O-O-O 14. Ne3 Qf7 15. Nxg4 Be7 1-0",
    B: "1451",
  },
  {
    W: "1270",
    T: "600+0",
    P: "1. e4 d5 2. e5 e6 3. d4 Bb4+ 4. c3 Ba5 5. Nd2 b5 6. Nb3 c6 7. Ne2 Ne7 8. Bg5 h6 9. Bxe7 Qxe7 10. Nxa5 f6 11. Ng3 fxe5 12. dxe5 Qg5 13. Nb3 Qxe5+ 14. Qe2 Qxe2+ 15. Bxe2 Nd7 16. O-O O-O 17. Nd4 Nf6 18. Bh5 g6 19. Bxg6 Kg7 20. Bh5 e5 21. Ndf5+ Bxf5 22. Nxf5+ Kh7 23. Bf3 e4 24. Be2 Nd7 25. Nd4 c5 26. Nxb5 Rab8 27. Nxa7 Rxb2 28. Rab1 Rxb1 29. Rxb1 Ne5 30. Rb7+ Kg6 31. Rb6+ Rf6 32. Rxf6+ Kxf6 33. Nb5 Nd7 34. a4 c4 35. a5 Ke5 36. a6 Nb6 37. Nc7 Kd6 38. Nb5+ Kc5 39. Na3 Na8 40. Bg4 Nb6 41. Be6 Na8 42. g4 Nb6 43. Kg2 Na8 44. Kh3 Nc7 45. Bc8 Kb6 46. Bb7 Ka7 47. Kh4 Ne8 48. Kh5 Nf6+ 49. Kxh6 Nxg4+ 50. Kg6 Nxf2 51. h4 e3 52. Nc2 e2 53. h5 Nd3 54. h6 e1=Q 55. Nxe1 Nxe1 56. h7 d4 57. h8=Q d3 58. Qd4+ Kb8 59. Qxc4 d2 60. Qc8+ Ka7 61. Qc5+ Kb8 62. a7+ Kxb7 63. Qe7+ Kc6 64. a8=Q+ Kb5 65. Qeb7+ Kc4 66. Qac8+ Kd3 67. Qd5+ Kc2 68. Qe4+ Nd3 69. Qcc4 d1=Q 70. Qa4+ Kd2 71. Qxd1+ Kxd1 72. Qxd3+ Ke1 1/2-1/2",
    B: "1310",
  },
  {
    W: "1663",
    T: "600+0",
    P: "1. d4 e5 2. dxe5 Nc6 3. e3 Nxe5 4. g3 c6 5. Bg2 Bc5 6. Nd2 d5 7. Ngf3 Bg4 8. h3 Bh5 9. g4 Nxf3+ 10. Nxf3 Bg6 11. O-O Bb6 12. a3 Ne7 13. b4 O-O 14. Bb2 Qd6 15. Be5 Qe6 16. Bc3 Bc7 17. Bd4 b6 18. c3 f6 19. Qe2 Qd6 20. Rad1 Be4 21. Rfe1 Ng6 22. c4 Nh4 23. c5 Nxf3+ 24. Bxf3 Qh2+ 25. Kf1 Qxh3+ 26. Kg1 Bh2+ 27. Kh1 Bxf3+ 28. Qxf3 Qxf3+ 29. Kxh2 Qxf2+ 30. Kh3 g5 31. cxb6 axb6 32. Rf1 Qh4+ 33. Kg2 Qxg4+ 34. Kf2 Rae8 35. Bxb6 f5 36. Rg1 Qh4+ 37. Ke2 f4 38. Bc5 f3+ 39. Kd3 Qc4+ 40. Kd2 Qa2+ 41. Ke1 Qe2# 0-1",
    B: "1612",
  },
  {
    W: "1676",
    T: "600+0",
    P: "1. e4 e5 2. f4 d5 3. exd5 exf4 4. Nf3 Bg4 5. Bb5+ Nd7 6. O-O Bd6 7. Re1+ Ne7 8. Nc3 O-O 9. d4 Nf5 10. Ne4 Nh4 11. Rf1 Bxf3 12. Rxf3 Nxf3+ 13. Qxf3 Nf6 14. Nxd6 Qxd6 15. Bxf4 Qb4 16. Bd3 Qxb2 17. Rf1 Qxd4+ 18. Be3 Qb2 19. Qg3 Nxd5 20. Bc1 Qxa2 21. Bh6 g6 22. Qe5 f6 23. Qe6+ Rf7 24. Be4 c6 25. Rxf6 Qa1+ 26. Kf2 Qd4+ 27. Be3 Qxf6+ 28. Qxf6 0-1",
    B: "1710",
  },
  {
    W: "1591",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. d4 exd4 5. Nxd4 Nxd4 6. Qxd4 Qe7 7. O-O d6 8. Re1 Be6 9. Bd2 c5 10. Qc3 Nf6 11. e5 Nd5 12. Bxd5 dxe5 13. Rxe5 Rd8 14. f4 Rd7 15. f5 Rxd5 16. fxe6 Rxe5 17. Qxe5 Qxe6 18. Qb8+ Ke7 19. Qxb7+ Kf6 20. Bc3+ Kg6 21. Qxa7 Qe3+ 22. Kf1 Qc1+ 23. Ke2 Qxc2+ 24. Ke1 Qe4+ 25. Kd1 Qd3+ 26. Nd2 Bd6 27. Qd7 f6 28. Qg4+ Kh7 29. Qe4+ Qxe4 30. Nxe4 Be7 31. Kc2 Rd8 32. Rd1 Re8 33. a3 f5 34. Ng3 f4 35. Nf5 Bf6 36. Bxf6 gxf6 37. Rd7+ Kg6 38. Nh4+ Kg5 39. Nf3+ Kf5 40. Rd5+ Kg6 41. Rxc5 Re2+ 42. Kc3 Rxg2 43. Nh4+ 1-0",
    B: "1584",
  },
  {
    W: "1076",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Qxd4 Nc6 5. Qd3 Nb4 6. Qc4 Qa5 7. c3 Nxa2 8. Bd2 e5 9. Na3 Be6 10. Qc7 Qxc7 0-1",
    B: "914",
  },
  {
    W: "1445",
    T: "120+1",
    P: "1. d4 c5 2. dxc5 e5 3. a3 Bxc5 4. e3 d5 5. b4 Be7 6. Bb2 Nc6 7. c4 d4 8. exd4 Nxd4 9. Bxd4 Qxd4 10. Nd2 Nf6 11. Ngf3 Qd8 12. Nb3 e4 13. Qxd8+ Bxd8 14. Nfd4 O-O 15. Be2 Re8 16. O-O Bc7 17. g3 Be5 18. Rad1 Bd7 19. Nc2 a6 20. Nc5 Bc6 21. Nb3 b5 22. c5 Bd5 23. Nbd4 Rac8 24. Rd2 Bc4 25. Bxc4 bxc4 26. Ne3 c3 27. Rdd1 Rcd8 28. Nec2 Nd5 29. Nc6 Rc8 30. Nxe5 Rxe5 31. Rfe1 Rce8 32. Kg2 e3 33. f3 e2 34. Rd3 Ne3+ 35. Nxe3 Rxe3 36. Rxe3 Rxe3 37. f4 c2 38. Kf2 Rc3 39. Kxe2 c1=Q 40. Rxc1 Rxc1 41. Kd2 Rh1 42. Kd3 Rxh2 43. Kc3 Rg2 44. c6 Re2 45. c7 Re8 46. a4 Rc8 47. b5 axb5 48. axb5 Rxc7+ 49. Kb4 Kf8 50. b6 Rb7 51. Kb5 Ke7 52. Kc6 Rxb6+ 53. Kxb6 Ke6 54. Kc5 Kf5 55. Kd6 Kg4 56. Ke7 f6 57. Kf7 g5 58. fxg5 fxg5 59. Kg7 h5 60. Kg6 h4 61. gxh4 gxh4 0-1",
    B: "1441",
  },
  {
    W: "2023",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 e6 4. Nc3 Qc7 5. d5 Nd8 6. Bd2 a6 7. a4 Bd6 8. g3 f6 9. Qe2 Nf7 10. Bh3 Ne7 11. O-O O-O 12. Rfd1 Ng6 13. dxe6 dxe6 14. Qc4 Re8 15. Be3 b6 16. Rd2 Nfe5 17. Nxe5 Nxe5 18. Qe2 Bd7 19. f4 Nf7 20. Rad1 Rad8 21. a5 b5 22. b3 b4 23. Na4 Bb5 24. Qf2 Qxa5 25. Nxc5 Qc7 26. Nxe6 Rxe6 27. Bxe6 Bd7 28. Bxf7+ Kxf7 29. Rxd6 Ke7 30. Bc5 Qxc5 0-1",
    B: "1950",
  },
  {
    W: "2497",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bb5 Qb6 4. Nc3 e6 5. O-O Nge7 6. Re1 Ng6 7. a4 Be7 8. a5 Qc7 9. d3 a6 10. Bc4 Nxa5 11. Ba2 Nc6 12. d4 cxd4 13. Nxd4 O-O 14. Be3 b5 15. Nxc6 Qxc6 16. Qg4 Bb7 17. f3 b4 18. Ne2 Qxc2 19. Bd4 Bc5 20. Bxc5 Qxc5+ 21. Kh1 d5 22. Rac1 Qb5 23. Nd4 Qb6 24. e5 Rfc8 25. Rcd1 a5 26. h4 h6 27. h5 Nf8 28. f4 b3 29. Bb1 Rc4 30. Nf3 Qb4 31. Bd3 Rxf4 32. Qg3 a4 33. Rc1 a3 34. bxa3 Rxa3 35. Rc7 b2 36. Bb1 Rg4 37. Qf2 Rf4 38. Rf1 d4 39. Qg3 Rfxf3 40. gxf3 Qb6 41. Re7 Ra1 42. Qf4 f5 43. exf6 Qc5 44. Rxg7+ Kh8 45. Kg1 Qxh5 46. Qg3 Qg5 47. Rxg5 hxg5 48. Qxg5 Rxb1 49. Qh6+ 1-0",
    B: "2393",
  },
  {
    W: "2202",
    T: "180+0",
    P: "1. d4 d5 2. Bg5 Nf6 3. Nd2 Nc6 4. e3 h6 5. Bf4 e6 6. h3 Bd6 7. Ngf3 Bxf4 8. exf4 Bd7 9. c3 Qe7 10. Ne5 Nxe5 11. fxe5 Ng8 12. Nf3 O-O-O 13. a4 f6 14. Bb5 c6 15. Bd3 Be8 16. O-O fxe5 17. Nxe5 Nf6 18. a5 Nd7 19. f4 Nxe5 20. fxe5 Rf8 21. Qe2 Rxf1+ 22. Rxf1 Bd7 23. b4 Rf8 24. Rxf8+ Qxf8 25. Qf2 Qxf2+ 26. Kxf2 Kd8 27. Kg3 Ke7 28. Kh4 Kf7 29. Kg4 g6 30. h4 Kg7 31. Kf4 b6 32. axb6 axb6 33. g4 Be8 34. g5 h5 35. c4 Bd7 36. b5 cxb5 37. cxb5 Kf7 38. Ke3 Be8 39. Kd2 Ke7 40. Ke3 Kd8 41. Kf4 Ke7 42. Bf1 Kd8 43. Bh3 Ke7 44. Bg2 Bxb5 45. Bf3 Bd3 46. Ke3 Bc4 47. Kd2 Kd8 48. Kc3 Ke7 49. Kb4 Bd3 50. Bd1 b5 51. Bb3 Kd7 52. Kc5 Bc4 53. Bc2 b4 54. Kxb4 1-0",
    B: "2067",
  },
  {
    W: "1960",
    T: "180+0",
    P: "1. e4 c5 2. Nc3 e6 3. Nf3 Ne7 4. Bb5 g6 5. a3 Bg7 6. h3 O-O 7. O-O a6 8. Be2 b5 9. d3 Bb7 10. Rb1 f5 11. Bg5 Nbc6 12. Nh2 Qc7 13. exf5 Rxf5 14. Bxe7 Nxe7 15. f3 b4 16. Ne4 Bxe4 17. fxe4 Rxf1+ 18. Qxf1 Rf8 19. Qd1 Bd4+ 20. Kh1 Be5 21. Ng4 Bd4 22. Qd2 Bg7 23. Rf1 Rxf1+ 24. Bxf1 h5 25. Ne3 Bxb2 26. axb4 cxb4 27. Qxb4 Bf6 28. g4 Nc6 29. Qc4 Bd4 30. Nd1 Qg3 0-1",
    B: "1992",
  },
  {
    W: "1312",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Nf6 3. Nc3 Nxd5 4. Nf3 Nxc3 5. bxc3 Bg4 6. d4 Nc6 7. Ba3 e6 8. Bxf8 Rxf8 9. Rb1 Na5 10. Bd3 Qd5 11. Rb5 Qxa2 12. O-O Nc4 13. Bxc4 Qxc4 14. Rxb7 Qxc3 15. Re1 Bxf3 16. Qxf3 Qxf3 17. gxf3 Ke7 18. d5 Rfb8 19. Rxc7+ Kd6 20. Rxf7 exd5 21. Rxg7 Rb2 22. Re2 Rc8 23. Rxh7 Rcxc2 24. Rxc2 Rxc2 25. Kg2 a5 26. Rh6+ Kc5 27. h4 d4 28. h5 d3 29. Kf1 d2 30. Ke2 Kc4 31. Rd6 Kc3 32. h6 Rb2 33. h7 Rb8 34. Kd1 Rh8 35. Rh6 a4 36. f4 a3 37. f5 a2 38. Rc6+ Kd3 39. Rd6+ Kc3 40. Rc6+ Kd3 41. Ra6 Rb8 42. Rxa2 1-0",
    B: "1259",
  },
  {
    W: "1562",
    T: "180+0",
    P: "1. e4 g6 2. Bc4 Bg7 3. Nf3 d6 4. Bxf7+ Kxf7 5. Ng5+ Ke8 6. Qf3 Nf6 7. d3 Rf8 8. d4 Bg4 9. Qb3 Nbd7 10. O-O h6 11. Ne6 Qc8 12. Nxg7+ 1-0",
    B: "1552",
  },
  {
    W: "1867",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 d5 5. exd5 Qxd5 6. Nxc6 Qxd1+ 7. Kxd1 bxc6 8. Nc3 Bf5 9. Be3 O-O-O+ 10. Ke2 Kb7 11. g3 Bxc2 12. Bg2 Bd3+ 13. Kf3 e5 14. Rad1 f5 15. Bf1 e4+ 16. Nxe4 fxe4+ 17. Kf4 Bd6+ 18. Kf5 Ne7+ 19. Ke6 Bc2 20. Rxd6 Rde8 21. Kd7 Ba4 22. b3 Nf5 23. bxa4 Re7# 0-1",
    B: "1873",
  },
  {
    W: "1232",
    T: "180+0",
    P: "1. Nf3 d5 2. e3 Nc6 3. c4 e5 4. cxd5 Qxd5 5. Nc3 Qd6 6. Nb5 Qc5 7. Nxc7+ 1-0",
    B: "1171",
  },
  {
    W: "1956",
    T: "180+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 Nf6 5. Bd3 Bd6 6. Bg5 O-O 7. O-O c6 8. c3 Bg4 9. Nbd2 Nbd7 10. Qc2 Qc7 11. h3 Bh5 12. Rfe1 Bg6 13. Bxg6 fxg6 14. Be3 Rae8 15. Nf1 Ne4 16. Ng5 Nxg5 17. Bxg5 Bf4 18. Bxf4 Qxf4 19. Nh2 Re4 20. Nf3 Rfe8 21. Rxe4 dxe4 22. Nd2 e3 23. Nf3 exf2+ 24. Qxf2 Qe3 25. Re1 Qxf2+ 26. Kxf2 Rxe1 27. Kxe1 Kf7 28. Kd2 Ke6 29. Kd3 Kf5 30. g3 g5 31. c4 h5 32. Ke3 g4 33. hxg4+ hxg4 34. Nd2 g5 35. b4 b5 36. a3 a6 37. cxb5 axb5 38. Ne4 Nb6 39. Nd6+ Ke6 40. Nb7 Kd5 41. Kd3 Nc4 42. Nc5 Nxa3 43. Nd7 Nc4 44. Nf6+ Ke6 45. Nxg4 Kf5 46. Nh6+ Kg6 47. Ng4 Nd6 48. Ne5+ Kf5 49. Nxc6 Kg4 50. d5 Kxg3 51. Kd4 g4 52. Kc5 Ne4+ 53. Kxb5 Kf3 54. Ne5+ Kf4 55. Nxg4 Kxg4 56. Kc6 Kf5 57. d6 Ke6 58. b5 Nxd6 59. b6 Ke7 60. Kc7 Ke6 61. Kc6 Ke7 62. Kc7 Ke6 63. Kc6 1/2-1/2",
    B: "1900",
  },
  {
    W: "1432",
    T: "180+0",
    P: "1. e4 e5 2. c3 Nc6 3. d4 exd4 4. cxd4 Bb4+ 5. Bd2 Bxd2+ 6. Qxd2 Nf6 7. e5 Ne4 8. Qe3 d5 9. exd6 Bf5 10. f3 Qxd6 11. fxe4 Bg6 12. Bd3 O-O 13. Nf3 Rfe8 14. O-O Nxd4 15. Nxd4 Rad8 16. Bb5 c6 17. Ba4 Qb4 18. b3 Qxd4 19. Qxd4 Rxd4 20. b4 Rxb4 21. Nc3 Bxe4 22. Nxe4 Rbxe4 23. Bc2 Rc4 24. Rac1 h6 25. Bh7+ Kxh7 26. Rxc4 Re2 27. a4 b5 28. axb5 cxb5 29. Rc7 a5 30. Rcxf7 b4 31. Ra7 Ra2 32. Rff7 Ra1+ 33. Kf2 Ra2+ 34. Kg3 Ra3+ 35. Kh4 Kg6 36. Rxg7+ Kf6 37. Rgf7+ Kg6 38. Rg7+ Kf6 39. Rgd7 b3 40. Rd3 a4 41. Ra6+ Kg7 42. Kh5 b2 43. Rd7+ Kg8 44. Ra8# 1-0",
    B: "1292",
  },
  {
    W: "1047",
    T: "180+0",
    P: "1. e4 e5 2. Qh5 Nc6 3. Bc4 g6 4. Qf3 f6 5. Nc3 Bb4 6. d3 Bxc3+ 7. bxc3 a6 8. Ba3 b5 9. Bb3 Nge7 10. Qxf6 Rf8 11. Qg5 a5 12. Nf3 a4 13. Bd5 Nxd5 14. exd5 Qxg5 15. Nxg5 Rf5 16. Nxh7 Ne7 17. d6 cxd6 18. Bxd6 Nd5 19. O-O-O Nxc3 20. Rde1 Nxa2+ 21. Kb2 Nb4 22. Bxb4 Rxf2 23. Rxe5+ Kf7 24. Ng5+ Kf6 25. Ne4+ Kxe5 26. Nxf2 Bb7 27. Re1+ Kf4 28. Re4+ Bxe4 29. Nh3+ Kg4 30. dxe4 Rf8 31. c4 Rf1 32. Kc3 Rh1 33. Bd6 Rxh2 34. Bxh2 Kh4 35. Bd6 g5 36. e5 a3 37. cxb5 a2 38. b6 a1=Q+ 39. Kc4 1-0",
    B: "1057",
  },
  {
    W: "1650",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Nf6 3. Nc3 Nxd5 4. Nf3 Nc6 5. Bc4 Nxc3 6. bxc3 e6 7. d4 Be7 8. a3 O-O 9. Bf4 a6 10. Rb1 b5 11. Bb3 Bb7 12. a4 bxa4 13. Bxa4 Rb8 14. O-O Na5 15. Qd2 Nc4 16. Qd3 Bxf3 17. Rxb8 Qxb8 18. gxf3 Rd8 19. Qxc4 c5 20. Bxb8 1-0",
    B: "1661",
  },
  {
    W: "2248",
    T: "180+0",
    P: "1. e4 d6 2. d4 Nf6 3. Bd3 e5 4. Ne2 Nc6 5. d5 Nb4 6. O-O Nxd3 7. cxd3 Be7 8. Nbc3 O-O 9. Be3 Ng4 10. Bd2 f5 11. f3 Nf6 12. Rc1 a6 13. b4 c6 14. dxc6 bxc6 15. Kh1 d5 16. a4 Bxb4 17. Qb3 a5 18. exd5 cxd5 19. Nb5 Bxd2 20. Rc7 Rb8 21. Qc2 Be3 22. f4 Ng4 23. h3 Nf2+ 24. Kh2 e4 25. dxe4 fxe4 26. Qb2 Rf6 27. Ned4 Nd3 28. Qe2 Bxd4 29. Nxd4 Qxc7 0-1",
    B: "2268",
  },
  {
    W: "1524",
    T: "180+0",
    P: "1. e4 e6 2. Bc4 d5 3. exd5 exd5 4. Bd3 Nf6 5. Nc3 Bd6 6. Nf3 O-O 7. O-O c6 8. Re1 Bc7 9. b3 Qd6 10. Bb2 Bg4 11. g3 Na6 12. Be2 c5 13. d4 Rfe8 14. dxc5 Nxc5 15. Ba3 b6 16. Nb5 Qd7 17. Qd4 Bxf3 18. Bxf3 Qxb5 19. Rxe8+ Rxe8 20. Bxd5 Ncd7 21. Bc4 Qe5 22. Qxe5 Nxe5 23. Rd1 g5 24. Bb5 Re6 25. Kg2 Kg7 26. Bb2 Ng6 27. a4 Re2 28. Bxf6+ Kxf6 29. Rd7 Rxc2 30. Bc4 Bxg3 31. Rxf7+ Ke5 32. hxg3 Rb2 33. Rxa7 Ne7 34. Rxe7+ Kd4 35. Rxh7 Kc3 36. Rg7 g4 37. Rxg4 b5 38. axb5 Rxb3 39. Bxb3 Kxb3 40. b6 Kc3 41. b7 1-0",
    B: "1537",
  },
  {
    W: "1658",
    T: "900+15",
    P: "1. e4 c5 2. Nf3 d6 3. Nc3 a6 4. Bc4 Nf6 5. Nd5 Nxd5 6. Bxd5 e6 7. Bb3 Nd7 8. d3 Nf6 9. Ng5 Be7 10. Qf3 O-O 11. Bf4 d5 12. e5 c4 13. dxc4 Ne4 14. Nxh7 Kxh7 15. Qh5+ Kg8 16. c3 Nc5 17. Bc2 g6 18. Bxg6 fxg6 19. Qxg6+ Kh8 20. Bh6 Rg8 21. Qh5 Qe8 22. Qh3 dxc4 23. Be3+ Kg7 24. Qh6+ Kf7 25. Qh7+ Rg7 26. Qh5+ Rg6 27. O-O Qg8 28. g3 Ne4 29. Qf3+ Nf6 30. exf6 Rxf6 31. Qh5+ Rg6 32. Rad1 b5 33. Rd4 Bf6 34. Rf4 e5 35. Qxe5 Bh3 36. Rd1 Re8 37. Qh5 Be6 38. Bd4 Ke7 39. Bxf6+ Rxf6 40. Qh4 Rf8 41. Re1 Kd6 42. Rd4+ Bd5 43. Qe4 Rxf2 44. Qe7+ Kc6 45. Rh4 Rg2+ 46. Kh1 Re2+ 47. Kg1 Rxe7 48. Rxe7 Kd6 49. Reh7 Qg5 50. R7h6+ Rf6 51. R4h5 Qe3# 0-1",
    B: "1640",
  },
  {
    W: "1957",
    T: "60+0",
    P: "1. g4 d5 2. Bg2 e5 3. e3 Bd6 4. d4 c6 5. dxe5 Bxe5 6. f4 Bd6 7. Nf3 Bxg4 8. c4 Bxf3 9. Bxf3 Qh4+ 10. Kf1 Nf6 11. cxd5 O-O 12. Qb3 Qh3+ 13. Kf2 Ng4+ 14. Bxg4 Qxg4 15. Rg1 Qh4+ 16. Kg2 Nd7 17. dxc6 bxc6 18. Nc3 Nf6 19. Qd1 Ng4 20. Qf3 Qxh2+ 21. Kf1 Nf6 22. Rh1 Qc2 23. e4 Rfe8 24. e5 Bxe5 25. fxe5 Rxe5 26. Bf4 Rf5 27. Qh3 Re8 28. Qxf5 Qxf5 29. Ne2 Rxe2 30. Kxe2 Qxf4 31. Raf1 Ne4 32. Kd1 Qg3 33. Rhg1 1-0",
    B: "1843",
  },
  {
    W: "1780",
    T: "60+0",
    P: "1. b3 g6 2. Bb2 Nf6 3. g4 Bg7 4. e3 Rg8 5. Be2 Nd5 6. Bxg7 Rxg7 7. g5 f6 8. h4 fxg5 9. hxg5 e6 10. Nc3 Qxg5 11. Nh3 Qf5 12. Nxd5 Qxd5 13. Nf4 Qd6 14. c4 Qe5 15. d4 Qf6 16. Qc1 d6 17. Qa3 e5 18. Nd3 exd4 19. exd4 Qxd4 20. O-O-O Qa1+ 21. Kd2 Re7 22. Nf4 Qd4+ 23. Bd3 Qxf4+ 24. Kc3 Nc6 25. Rhe1 Qd4+ 26. Kc2 Rxe1 27. Rxe1+ Be6 28. Qc1 Qxf2+ 29. Kc3 Kd7 30. b4 Re8 31. c5 Qd4+ 32. Kc2 Nxb4+ 33. Kb1 Qxd3+ 34. Ka1 Qa3 35. Qxa3 Nc2+ 36. Kb2 Nxa3 37. Kxa3 Bxa2 38. Rc1 Re3+ 39. Ka4 Ra3+ 40. Kxa3 Bb3 41. Kxb3 d5 42. Rh1 c6 43. Rxh7+ Ke6 44. Rxb7 Ke5 45. Rxa7 d4 46. Rc7 Ke4 47. Rxc6 Ke3 48. Rd6 Kd3 49. Rxg6 Ke4 50. Rd6 d3 51. Rxd3 Kxd3 52. c6 Kd2 53. c7 Ke2 1/2-1/2",
    B: "1772",
  },
  {
    W: "1296",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 bxc6 5. O-O Rb8 6. d3 Nf6 7. Be3 d5 8. Bxc5 Rxb2 9. exd5 Nxd5 10. Ba3 Rb8 11. Re1 e6 12. Bxf8 Rxf8 13. Qe2 f6 14. c4 Nc3 15. Nxc3 Kf7 16. Qe3 Kg8 17. Nd4 e5 18. Nxc6 Qb6 19. Nxb8 Qxe3 20. Rxe3 Be6 21. Nc6 Rc8 22. Nb4 a5 23. Nbd5 Bxd5 24. cxd5 Rxc3 25. f3 Kf7 26. Re4 Rxd3 27. Rxe5 fxe5 28. Re1 Rxd5 29. f4 e4 30. Kf2 Rd4 0-1",
    B: "1359",
  },
  {
    W: "1703",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bc4 b6 4. Bb5 e6 5. Nc3 Bb7 6. d3 a6 7. Bxc6 Bxc6 8. Be3 Be7 9. d4 d6 10. d5 Bd7 11. Qd2 Nf6 12. e5 dxe5 13. Nxe5 exd5 14. Nxd5 Be6 15. Nc6 Qxd5 16. Qxd5 Nxd5 17. Nxe7 Kxe7 18. O-O Nxe3 19. Rfe1 Nxc2 20. Rac1 Nxe1 21. Rxe1 Rhd8 22. f4 f5 23. h3 Kf6 24. g4 fxg4 0-1",
    B: "1876",
  },
  {
    W: "2217",
    T: "60+0",
    P: "1. d4 c5 2. e3 cxd4 3. exd4 Nc6 4. Be3 g6 5. Nf3 Bg7 6. c3 d6 7. Bd3 Nf6 8. O-O O-O 9. Bb5 a6 10. Nbd2 axb5 11. a3 Be6 12. Rc1 Na5 13. Qe2 Nc4 14. Nb1 Nxe3 15. fxe3 Bc4 16. Qf2 Bxf1 17. Rxf1 Ne4 18. Qe2 Qb6 19. Nbd2 Nxd2 20. Qxd2 b4 21. axb4 Ra2 22. e4 Rfa8 23. Qf4 Ra1 24. Ng5 Rxf1+ 25. Qxf1 Bf6 26. Nf3 e5 27. h3 exd4 28. cxd4 Bxd4+ 29. Kh2 Bxb2 30. e5 Bxe5+ 31. Kh1 Ra1 32. Ne1 Qe3 33. Nc2 Rxf1# 0-1",
    B: "2503",
  },
  {
    W: "1558",
    T: "60+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. f4 Nc6 4. c4 Nb6 5. d4 d6 6. Nf3 dxe5 7. fxe5 Bg4 8. Be3 Bxf3 9. Qxf3 e6 10. Bd3 Bb4+ 11. Nc3 Nxd4 12. Bxd4 Qxd4 13. O-O-O Bxc3 14. bxc3 Qxc3+ 15. Bc2 Nxc4 16. Qxc3 b5 17. Qd4 O-O 18. Bb3 Na5 19. Qd7 c5 20. Qxb5 Nxb3+ 21. Qxb3 c4 22. Qc3 a5 23. Rd4 1-0",
    B: "1515",
  },
  {
    W: "1263",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 b6 3. d3 g6 4. c3 a5 5. Qb3 Nc6 6. Ng5 f6 7. Nf3 g5 8. c4 g4 9. Nh4 Nd4 10. Qc3 e5 11. Be3 Bb4 12. Qd2 Bxd2+ 13. Nxd2 Ne6 14. c5 bxc5 15. f3 Rb8 16. fxg4 Rxb2 17. Nc4 Rc2 18. Nxa5 Ba6 19. Nf3 c4 20. dxc4 Nc5 21. Bd3 Nxd3+ 22. Kf1 Nb4 23. Nb7 Bxb7 24. Rb1 Bxe4 25. Rxb4 Rc1+ 26. Ke2 Rxh1 27. Bc5 Bxf3+ 28. gxf3 c6 29. Rb7 Qa5 30. Bb4 Qxa2+ 31. Kd3 Qa1 0-1",
    B: "1292",
  },
  {
    W: "1721",
    T: "60+0",
    P: "1. e4 g6 2. d4 Bg7 3. c3 e6 4. Bd3 Ne7 5. Ne2 c5 6. Be3 Qb6 7. b3 cxd4 8. cxd4 Bxd4 9. Nxd4 O-O 10. O-O e5 11. Nf5 Qf6 12. Nh6+ Kg7 13. Qg4 d5 14. Bg5 Qb6 15. Qg3 Nec6 16. exd5 Nd4 17. Ng4 Bxg4 18. Qxg4 f6 19. Be3 Nf5 20. Bxf5 Qb4 21. Qxb4 Na6 22. Qe7+ Rf7 23. Qe6 gxf5 24. Qxf5 Rg8 25. Nc3 Nc5 26. Ne4 Nxe4 27. Qxe4 f5 28. Qxe5+ Kf8 0-1",
    B: "1715",
  },
  {
    W: "2056",
    T: "60+0",
    P: "1. e4 d5 2. e5 d4 3. f4 c5 4. Nf3 Nc6 5. d3 e6 6. Nbd2 Nge7 7. Ne4 Nd5 8. c4 dxc3 9. bxc3 Be7 10. c4 Ndb4 11. a3 Na6 12. Nfg5 O-O 13. Qh5 h6 14. Nf3 Nd4 15. Be2 Nc2+ 16. Kf2 Nxa1 17. Bd1 Qxd3 18. Re1 Nc2 19. Be2 Qc3 20. Rd1 Nd4 21. Bd2 Qxa3 22. Nxd4 cxd4 23. Kg1 d3 24. Bf3 Nc5 25. Nf6+ Bxf6 26. exf6 gxf6 27. Qxh6 Nd7 28. Be4 f5 29. Bf3 Qe7 30. Be1 Qf6 31. Qh5 Qg6 32. Qh3 Nc5 33. Bh5 Ne4 34. Bxg6 Nf2 35. Bxf2 fxg6 36. Qg3 Kg7 37. Qxg6+ Kxg6 38. Be3 Rf6 39. Rxd3 e5 40. Rd8 e4 41. Rg8+ Kf7 42. Rg7+ 1-0",
    B: "2032",
  },
  {
    W: "1603",
    T: "60+0",
    P: "1. e4 g5 2. Bc4 e6 3. d4 h6 4. d5 Bg7 5. dxe6 fxe6 6. Qh5+ Kf8 7. b3 c6 8. Ba3+ d6 9. Qd1 Be5 10. Nf3 Qf6 11. Nxe5 Qxe5 12. c3 Kf7 13. Bb2 d5 14. Nd2 dxc4 15. Nxc4 Qf4 16. g3 Qf6 17. Qc2 Ne7 18. O-O-O e5 19. Ne3 Re8 20. c4 Kf8 21. Ng4 Qf7 22. Nxe5 Qh7 23. Nd7+ Bxd7 24. Rxd7 Nxd7 25. e5 Nc5 26. Qxh7 Ng8 27. Qf5+ 1-0",
    B: "1451",
  },
  {
    W: "2256",
    T: "60+0",
    P: "1. e4 b6 2. d4 Bb7 3. Nc3 Nc6 4. Bc4 e6 5. d5 Ne5 6. Bb3 d6 7. f4 Ng6 8. h4 exd5 9. h5 N6e7 10. exd5 Nf6 11. Nf3 a6 12. a4 Nf5 13. Qd3 Qe7+ 14. Kf2 Ng4+ 15. Kg1 Ng3 16. Bd2 O-O-O 17. Re1 Qd7 18. Rh3 Nf5 19. Ng5 h6 20. Ne6 fxe6 21. dxe6 Qc6 22. Bd5 Qc5+ 23. Kf1 Qf2# 0-1",
    B: "2618",
  },
  {
    W: "1958",
    T: "30+0",
    P: "1. g3 e5 2. Nf3 Bc5 3. Bg2 Qf6 4. O-O Qb6 5. c3 Qg6 6. d4 e4 7. dxc5 exf3 8. Bxf3 h5 9. Nd2 h4 10. Bg2 hxg3 11. fxg3 Qh7 12. Nf3 d6 13. e4 Bg4 14. e5 Bxf3 15. Qxf3 Qxh2+ 16. Kf2 dxe5 17. Rh1 Qxh1 18. Bxh1 Rxh1 19. Qxh1 Nd7 20. Qxb7 Rb8 21. Qe4 Rd8 22. Bg5 f6 23. Be3 Ne7 24. c6 f5 25. cxd7+ Rxd7 26. Qa8+ Rd8 27. Qh1 e4 28. Qh8+ Kd7 29. Qh4 Rf8 30. Qf4 Nd5 31. Bd4 Nxf4 32. gxf4 g5 33. Ke3 gxf4+ 34. Kxf4 c5 35. Rg1 cxd4 36. Rg7+ Kd6 37. cxd4 e3 38. Rg5 e2 39. Rxf5 e1=Q 40. Rf6+ Rxf6+ 41. Kg5 Qf1 42. d5 Qb1 43. Kh4 Qxb2 44. Kh5 Qxa2 45. Kh4 Qxd5 46. Kg4 Rf4+ 47. Kh3 Qd3+ 48. Kh2 Re4 49. Kh1 Re2 50. Kg1 Qd1# 0-1",
    B: "1869",
  },
  {
    W: "1628",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Qf6 3. d3 h6 4. Nc3 Bc5 5. Nd5 Qd6 6. d4 exd4 7. Bf4 Bb4+ 8. c3 Bxc3+ 9. bxc3 Qc5 10. Rc1 d6 11. cxd4 Qa3 12. Nxc7+ Kd7 13. Nxa8 g5 14. Be5 Kd8 15. Bxh8 Bg4 16. Be2 1-0",
    B: "1594",
  },
  {
    W: "1510",
    T: "900+15",
    P: "1. d4 Nf6 2. Nc3 d5 3. g3 c5 4. e3 Nc6 5. Nf3 Bg4 6. h3 Bh5 7. Be2 e6 8. g4 Bg6 9. h4 Nxg4 10. h5 Bf5 11. b3 Nb4 12. Bd3 Nxd3+ 13. cxd3 cxd4 14. Nxd4 Bb4 15. Qd2 Rc8 16. Bb2 Qa5 17. Nxf5 Rxc3 18. Bxc3 Bxc3 19. Nd6+ Ke7 20. b4 Bxd2+ 21. Kxd2 Qxb4+ 22. Ke2 Qxd6 23. Rac1 Kf6 24. f3 Nh6 25. f4 e5 26. Rcf1 exf4 27. Rxf4+ Ke7 28. d4 Rc8 29. Kd3 Qa3+ 30. Ke2 Rc2+ 31. Kf3 g6 32. Rb1 b6 33. Rb5 Qd6 34. e4 dxe4+ 35. Rxe4+ Kd7 36. Ree5 Qa3+ 37. Ke4 f5+ 38. Kf4 Rf2+ 39. Kg5 Nf7+ 40. Kf6 Nxe5 41. Rxe5 Qd6+ 42. Kg7 Qxd4 43. hxg6 Qxe5+ 44. Kxh7 Rh2+ 45. Kg8 Qe6+ 46. Kg7 Qe7+ 47. Kg8 Ke8 48. g7 Qf7# 0-1",
    B: "1500",
  },
  {
    W: "1406",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Qf6 3. Nc3 c6 4. d4 exd4 5. Nxd4 Bc5 6. Be3 d5 7. exd5 cxd5 8. Bb5+ Nc6 9. Nxd5 Qg6 10. Nc7+ Kd8 11. Nxa8 Nxd4 12. Bxd4 Bxd4 13. Qxd4+ Bd7 1-0",
    B: "1322",
  },
  {
    W: "1052",
    T: "900+15",
    P: "1. e4 e5 2. b3 Nf6 3. Nf3 d6 4. Bc4 Nc6 5. O-O Na5 6. Bb5+ c6 7. Ba4 b5 8. b4 Nc4 9. Bb3 Nb6 10. d3 d5 11. Nc3 Bxb4 12. Bg5 Bxc3 13. Rb1 d4 14. Nxe5 O-O 15. Qf3 Re8 16. Nxc6 Bg4 17. Qf4 Qd6 18. Qxd6 Nxe4 19. dxe4 Rxe4 20. h3 Rae8 21. hxg4 Re1 22. Rbxe1 Bxe1 23. Ne7+ Kf8 24. Rxe1 Nc8 25. Qd7 Nxe7 26. Bxe7+ Rxe7 27. Qxe7+ 1-0",
    B: "1119",
  },
  {
    W: "1710",
    T: "900+15",
    P: "1. d4 d5 2. Bf4 e6 3. g3 Nf6 4. Bg2 Nh5 5. Be3 Nc6 6. Nf3 Be7 7. O-O f6 8. g4 f5 9. gxh5 Bh4 10. Nbd2 Qf6 11. Nxh4 Qxh4 12. Nf3 Qxh5 13. Ne5 Nxe5 14. dxe5 Rf8 15. Bc5 Rf7 16. e3 Qg5 17. f4 Qh4 18. Qf3 g5 19. Qg3 Qxg3 20. hxg3 g4 21. Kf2 h5 22. Rh1 Rh7 23. Rac1 b5 24. b3 Rb8 25. a3 a5 26. Bf1 Ra8 27. Bxb5+ Bd7 28. Bd3 Kf7 29. c4 Rb8 30. b4 Ra8 31. cxd5 exd5 32. bxa5 Rxa5 33. Bb4 Ra8 34. Rxc7 Ke6 35. Bb5 Rb8 36. Bxd7+ Rxd7 37. Rxd7 Kxd7 38. Rxh5 Ke6 39. Rh6+ Kf7 40. Rd6 Rc8 41. Rxd5 Rc2+ 42. Rd2 Rc4 43. Ke2 Ke6 44. Kd3 Re4 45. Ke2 Rc4 46. Rb2 Kd5 47. Bd6 Ke4 48. Rd2 Rc3 49. Rd4# 1-0",
    B: "1725",
  },
  {
    W: "1431",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 d6 3. d4 f5 4. dxe5 fxe4 5. Ng5 Qe7 6. Bb5+ c6 7. exd6 Qe5 8. Be2 e3 9. Bh5+ g6 10. Bxg6+ hxg6 11. Nf3 Qc5 12. Bxe3 Qc4 13. Qd4 Qxd4 14. Bxd4 Rh7 15. Be5 Nd7 16. Bg3 Ndf6 17. Nbd2 Bf5 18. O-O-O O-O-O 19. d7+ Rdxd7 20. Nh4 Rxh4 21. Bxh4 Ng4 22. h3 Nxf2 23. Bxf2 Nf6 24. Rhf1 Nd5 25. g4 Rf7 26. Bxa7 Bxg4 27. Rxf7 Bh6 1-0",
    B: "1492",
  },
  {
    W: "2159",
    T: "60+0",
    P: "1. d4 g6 2. Nf3 Bg7 3. Bf4 c5 4. e3 cxd4 5. exd4 Nf6 6. c3 O-O 7. Bd3 d5 8. O-O Bg4 9. Nbd2 Nc6 10. h3 Bxf3 11. Nxf3 e6 12. Re1 Qb6 13. Qd2 Rfe8 14. a4 a6 15. Ne5 Nxe5 16. Bxe5 Nd7 17. Bxg7 Kxg7 18. a5 Qc7 19. Re3 Re7 20. Rf3 Rae8 21. Qg5 f6 22. Qd2 Nf8 23. h4 e5 24. Rg3 e4 25. Be2 f5 26. h5 Re6 27. hxg6 Rxg6 28. Rxg6+ hxg6 29. Qg5 Qe7 30. Qg3 g5 31. Bh5 f4 32. Qg4 Rd8 33. Re1 Rd6 34. g3 Qf6 35. gxf4 Qxf4 36. Qxf4 gxf4 37. f3 e3 38. Bg4 Ng6 39. Kg2 Kf6 40. Rh1 Rd7 41. Bxd7 Ke7 42. Bc8 Kf6 43. Bxb7 e2 44. Re1 Nh4+ 45. Kh3 Nf5 46. Rxe2 Ne3 47. Rxe3 fxe3 48. Kg2 Kf5 49. Kf1 Kf4 50. Ke2 Kg3 51. Bxd5 Kf4 52. Bb7 Kf5 53. Bxa6 Ke6 54. Bd3 Kd5 55. Kxe3 Kd6 56. f4 Ke6 57. f5+ Kf6 58. d5 Ke7 59. d6+ Kf6 60. c4 Kf7 61. c5 Ke8 62. c6 Kd8 63. f6 Ke8 64. f7+ Kxf7 65. c7 Ke6 66. c8=Q+ Kd5 67. Qd8 Kc5 68. d7 Kb4 69. Qe8 Kb3 70. d8=Q Ka2 71. Qe4 Ka1 72. Qc4 1-0",
    B: "2121",
  },
  {
    W: "1799",
    T: "120+1",
    P: "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. d5 Bxc3+ 5. bxc3 f5 6. Qc2 Qa5 7. Bd2 Nf6 8. f3 d6 9. e4 O-O 10. Bd3 fxe4 11. Bxe4 Nxe4 12. Qxe4 Bf5 13. Qxe7 Nd7 14. Qxd6 Rae8+ 15. Kf2 Ne5 16. Nh3 Nd3+ 17. Kg3 Rd8 18. Qe7 Qb6 19. Bh6 Rf7 20. Qe3 Qd6+ 21. Bf4 Nxf4 22. Nxf4 g5 23. Qe2 Qxf4+ 24. Kf2 g4 25. Rae1 gxf3 26. Qxf3 Qd2+ 27. Kg1 0-1",
    B: "1818",
  },
  {
    W: "1266",
    T: "120+1",
    P: "1. e4 c5 2. d4 Nc6 3. d5 e5 4. dxc6 dxc6 5. Qxd8+ Kxd8 6. Bc4 Kc7 7. Be3 Be6 8. Bxe6 fxe6 9. Nf3 Be7 10. Nxe5 g5 11. Nf7 Bf6 12. Nxh8 Bxh8 13. Bxg5 Bxb2 14. c3 Bxa1 15. O-O Bb2 16. Rd1 h6 17. Be3 b6 18. Re1 Nf6 19. Re2 h5 20. Rxb2 Ng4 21. Re2 Rd8 22. h3 Rd1+ 0-1",
    B: "1276",
  },
  {
    W: "1704",
    T: "120+1",
    P: "1. d4 d5 2. Bf4 e6 3. Nf3 Nf6 4. e3 c5 5. Bd3 cxd4 6. Nxd4 Nc6 7. Nxc6 bxc6 8. Nd2 Bd6 9. Bg3 Bxg3 10. hxg3 a5 11. c3 Ba6 12. Bxa6 Rxa6 13. Qe2 Rb6 14. Nf3 Qb8 15. Rb1 h6 16. Ne5 Ne4 17. g4 Nxc3 18. bxc3 Rxb1+ 19. Qd1 Rxd1+ 20. Kxd1 Qb1+ 21. Ke2 Qxh1 0-1",
    B: "1782",
  },
  {
    W: "1289",
    T: "120+1",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 Nf6 5. Nc3 d6 6. Nf3 Ng4 7. Qe2 Be6 8. h3 Nge5 9. Nxe5 Nxe5 10. Bf4 Nc6 11. Rd1 Qf6 12. g3 Nd4 13. e5 dxe5 14. Bxe5 Nxc2+ 15. Qxc2 Qxe5+ 16. Be2 Bd6 17. f4 Qe3 18. Rd3 Qxg3+ 19. Rxg3 Bxf4 20. Rxg7 O-O-O 21. Rf1 Bxh3 22. Rxf4 Rhe8 23. Rgxf7 Be6 24. Rxh7 Bc4 25. Qa4 Bd3 26. Rf3 Bxh7 27. Rf7 a6 28. Rxh7 Rd6 29. Qc4 Rc6 30. Qf7 Rd8 31. Bf3 Rc5 32. Bxb7+ Kxb7 33. Qf3+ Kc8 34. Qa8# 1-0",
    B: "1296",
  },
  {
    W: "1840",
    T: "120+1",
    P: "1. d4 d5 2. Bf4 Nf6 3. e3 c5 4. Nf3 Nc6 5. c3 g6 6. Bd3 Bg7 7. Nbd2 O-O 8. Ne5 a6 9. g4 Nxe5 10. dxe5 Nxg4 11. Rg1 Nxe5 12. Bxe5 Bxe5 13. h4 d4 14. h5 dxc3 15. hxg6 cxd2+ 16. Kxd2 c4 17. gxh7+ Kh8 0-1",
    B: "1842",
  },
  {
    W: "1144",
    T: "120+1",
    P: "1. c4 e5 2. Nc3 Nc6 3. g3 Nf6 4. e4 Bb4 5. Nge2 O-O 6. Bg2 Bxc3 7. bxc3 d6 8. d3 Bg4 9. O-O a5 10. a4 b6 11. Rb1 Ne7 12. Qd2 Ng6 13. f4 exf4 14. gxf4 Bxe2 15. Qxe2 Re8 16. Qb2 Qd7 17. f5 Ne5 18. Bf4 Nxd3 19. Qd2 Nxf4 20. Qxf4 Rxe4 21. Bxe4 Nxe4 22. Qxe4 Re8 23. Qc2 Qe7 24. Qd2 Qe5 25. Rfe1 Qc5+ 26. Kh1 Rxe1+ 27. Rxe1 Qc6+ 28. Qg2 h6 1-0",
    B: "1009",
  },
  {
    W: "1509",
    T: "120+1",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 Nf6 4. Nc3 Nc6 5. Bg5 Nxd4 0-1",
    B: "1487",
  },
  {
    W: "1201",
    T: "120+1",
    P: "1. b3 e5 2. Bb2 Nc6 3. e4 d5 4. d3 d4 5. Nd2 Nf6 6. Ngf3 h6 7. Be2 Be6 8. O-O Qd7 9. Nc4 O-O-O 10. Nfxe5 Nxe5 11. Nxe5 Qb5 12. f4 g5 13. a4 Qc5 14. Ba3 Qb6 15. fxg5 hxg5 16. Bxf8 Rhxf8 17. Qd2 Rg8 18. b4 a6 19. a5 Qb5 20. c4 Qxe5 21. b5 Rh8 22. bxa6 Qxh2+ 23. Kf2 Qh4+ 24. Kg1 Qh2+ 25. Kf2 Ng4+ 26. Ke1 Qg3+ 27. Kd1 Ne3+ 28. Kc1 Nxf1 29. Bxf1 Rh1 30. Kc2 Bg4 31. Qb4 Kb8 32. Qxb7# 1-0",
    B: "1156",
  },
  {
    W: "1423",
    T: "120+1",
    P: "1. d4 d5 2. Bf4 e6 3. e3 Nf6 4. Nd2 Be7 5. c3 Nc6 6. Ngf3 O-O 7. Bd3 h6 8. Qc2 a6 9. Ne5 Nxe5 10. Bxe5 c6 11. Nf3 b5 12. e4 dxe4 13. Bxe4 Nxe4 14. Qxe4 Qd5 15. Qxd5 exd5 16. Kd2 Bf5 17. h3 Be4 18. Ke3 Bxf3 19. Kxf3 a5 20. g4 Bh4 21. Ke3 a4 22. a3 Rfe8 23. Kf3 f6 24. Bg3 Bg5 25. h4 Bd2 26. Rad1 Bxc3 27. bxc3 g5 28. hxg5 hxg5 29. Rde1 Kf7 30. Rxe8 Rxe8 31. Rh7+ Kg6 32. Rc7 Re6 33. Kg2 f5 34. gxf5+ Kxf5 35. Rf7+ Ke4 36. Be5 Kd3 37. Rf3+ Kc4 38. Rg3 g4 39. Rxg4 Kxc3 40. Rg3+ Kc4 41. f4 b4 42. axb4 Kxb4 43. Kh3 c5 44. dxc5 Kxc5 45. Ra3 Ra6 46. Kg4 d4 47. f5 Kc4 48. f6 d3 49. f7 Rg6+ 50. Kf5 Rg1 51. Rxa4+ Kb3 52. Rf4 d2 53. Rf3+ Kc2 54. Rf2 Kc1 55. Rxd2 Kxd2 56. f8=Q Rf1+ 57. Bf4+ Ke2 58. Qe7+ Kf3 59. Qe3+ Kg2 60. Qg3+ Kh1 61. Qh2# 1-0",
    B: "1439",
  },
  {
    W: "1992",
    T: "120+1",
    P: "1. e4 c6 2. Nf3 d5 3. Nc3 dxe4 4. Nxe4 Nd7 5. Qe2 Ndf6 6. d3 g6 7. Bd2 Bg7 8. Bc3 Bg4 9. O-O-O b5 10. h3 Bd7 11. g4 a5 12. b3 b4 13. Bb2 a4 14. Ne5 axb3 15. axb3 Kf8 16. Bg2 Nxe4 17. Qxe4 Bh6+ 18. f4 Be6 19. g5 Bg7 20. Kb1 Qa5 21. Qxc6 Qa2+ 22. Kc1 Rc8 23. Qb7 Bxe5 24. fxe5 Kg7 25. Bd5 Rd8 26. Be4 h6 27. gxh6+ Rxh6 28. h4 Rc8 29. Bd5 Bf5 30. e6+ f6 31. Qxc8 1-0",
    B: "2017",
  },
  {
    W: "1527",
    T: "180+2",
    P: "1. e4 e5 2. Nc3 Nc6 3. Bc4 Nf6 4. d3 h6 5. f4 Bc5 6. Nf3 Qe7 7. Na4 Ng4 8. Nxc5 Qxc5 9. Bxf7+ Kxf7 10. Ng5+ Ke8 11. Qxg4 hxg5 12. Rf1 d5 13. Qxg5 Qxc2 14. fxe5 Qxd3 15. Qg6+ Kd7 16. Rf7+ Ne7 17. e6+ Kd6 18. Bf4+ Kc6 19. exd5+ Qxd5 20. Rc1+ Kb6 21. Be3+ Ka6 22. Rxe7 Qa5+ 23. Bd2 Qe5+ 24. Kd1 Rd8 25. Qg3 Rxd2+ 26. Kxd2 Qd6+ 27. Qd3+ Qxd3+ 28. Kxd3 b6 29. Re8 Kb7 30. Kd4 c5+ 31. Ke5 Kc6 32. e7 Kd7 33. Rd1+ Kxe8 34. Rd8+ Kxe7 35. Rg8 Bb7 36. Rxg7+ Kf8 37. Rxb7 Re8+ 38. Kf6 Re2 39. Rb8+ Re8 40. Rb7 Re2 41. Rxa7 Rxb2 42. Ra8# 1-0",
    B: "1494",
  },
  {
    W: "1958",
    T: "180+2",
    P: "1. c4 c5 2. Nf3 d6 3. g3 Nf6 4. Bg2 g6 5. O-O Bg7 6. Nc3 O-O 7. d3 Nc6 8. Be3 a6 9. Qd2 Rb8 10. a3 Bd7 11. Bh6 b5 12. Bxg7 Kxg7 13. cxb5 axb5 14. h4 h5 15. Ng5 b4 16. Nd5 Nd4 17. Qe3 Nc2 18. Qxe7 Qxe7 19. Nxe7 Nxa1 20. Rxa1 bxa3 21. bxa3 Rfe8 22. Nc6 Rb6 23. Na7 Ra6 0-1",
    B: "1903",
  },
  {
    W: "1700",
    T: "180+2",
    P: "1. e4 c5 2. d4 cxd4 3. c3 d5 4. exd5 Qxd5 5. Qxd4 Qxd4 6. cxd4 e6 7. g3 Bb4+ 8. Bd2 Bxd2+ 9. Kxd2 Nf6 10. Bg2 Nc6 11. Bxc6+ bxc6 12. Nc3 Bb7 13. Nge2 c5 14. Rhd1 cxd4 15. Nxd4 O-O-O 16. Kc2 Kb8 17. Ndb5 Rc8 18. Nd6 Rc7 19. Nxb7 Kxb7 20. Rd2 Rhc8 21. Rad1 Ne4 22. Rd7 Nxf2 23. R1d4 Rxd7 24. Rxd7+ Rc7 25. Rd4 h5 26. Kb3 Ng4 27. h3 Ne5 28. g4 hxg4 29. hxg4 Nc6 30. Rf4 e5 31. Rf5 Nd4+ 32. Ka4 Rc4+ 33. Ka5 f6 34. Rh5 Rc6 35. Rh7 Rc7 36. Nb5 Nxb5 37. Kxb5 a6+ 38. Kb4 e4 39. a3 e3 40. Rh1 e2 41. Re1 Re7 42. Kc3 Kc6 43. Kd2 Kd5 44. Rxe2 Rxe2+ 45. Kxe2 Ke4 46. b4 Kf4 47. a4 Kxg4 48. b5 axb5 49. axb5 f5 50. b6 f4 51. b7 f3+ 52. Kf2 Kf4 53. b8=Q+ 1-0",
    B: "1726",
  },
  {
    W: "2219",
    T: "180+2",
    P: "1. d4 d5 2. Bf4 Nf6 3. e3 c5 4. c3 Nc6 5. Nd2 Bf5 6. Ngf3 e6 7. Be2 Bd6 8. Bg3 O-O 9. O-O Re8 10. Ne5 Qc7 11. f4 Ne4 12. Nxe4 Bxe4 13. Bd3 Bxd3 14. Qxd3 f6 15. Nf3 c4 16. Qe2 b5 17. e4 b4 18. e5 fxe5 19. fxe5 Be7 20. Bf4 h6 21. Qf2 Rf8 22. Qg3 Kh7 23. Rf2 Rf5 24. Raf1 Raf8 25. Bd2 a5 26. Qh3 Qd8 27. g4 R5f7 28. g5 Rxf3 29. g6+ Kxg6 30. Rxf3 Rxf3 31. Qxe6+ Kh7 32. Rxf3 1-0",
    B: "2235",
  },
  {
    W: "1764",
    T: "180+2",
    P: "1. d4 c5 2. dxc5 e6 3. e4 Bxc5 4. Nf3 Qb6 5. Qe2 Ne7 6. Nc3 O-O 7. g3 d5 8. exd5 Nxd5 9. Na4 Qb4+ 10. Nc3 Nxc3 11. bxc3 Qxc3+ 12. Nd2 Qxa1 13. Bg2 Qxc1+ 0-1",
    B: "1729",
  },
  {
    W: "2047",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. d3 d6 5. h3 Nf6 6. a3 a6 7. Ba4 b5 8. Bb3 Bb7 9. Nc3 O-O 10. g4 Nd4 11. Nxd4 Bxd4 12. Ne2 Bb6 13. g5 Nh5 14. Ng3 Nf4 15. Bxf4 exf4 16. Nf5 Qxg5 17. Qf3 g6 18. O-O-O Kh8 19. Rhg1 Qh5 20. Qxf4 gxf5 21. Rg5 Qe2 22. Qxf5 Bd4 23. Rh5 Qxh5 24. Qxh5 Bxf2 25. Rf1 Be3+ 26. Kb1 f6 27. Bd5 c6 28. Be6 Bg5 29. h4 Be3 30. Bf5 h6 31. Qg6 1-0",
    B: "2007",
  },
  {
    W: "2448",
    T: "180+0",
    P: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nf6 5. Nxf6+ exf6 6. c3 Bd6 7. Bd3 O-O 8. Ne2 Re8 9. O-O Nd7 10. Bf4 Nf8 11. Bxd6 Qxd6 12. Ng3 Ng6 13. Re1 Bd7 14. Qb3 b6 15. Ne4 Qd5 16. Bc4 Qh5 17. Bxf7+ Kf8 18. Bxe8 Rxe8 19. Ng3 Qg4 20. Qa3+ Kf7 21. Qxa7 h5 22. h3 Qf4 23. Qxd7+ Kg8 24. Rxe8+ Kh7 25. Nxh5 Qg5 26. Qxg7# 1-0",
    B: "2254",
  },
  {
    W: "1788",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd6 4. Nf3 c6 5. d3 g6 6. Bf4 Bg7 7. Bxd6 exd6 1-0",
    B: "1708",
  },
  {
    W: "1828",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 d6 5. Nc3 a6 6. Be3 Bd7 7. Qd2 Nf6 8. f3 e6 9. O-O-O Be7 10. g4 Rc8 11. h4 Qc7 12. g5 Ng8 13. Bh3 b5 14. Ncxb5 axb5 15. Nxb5 Qb7 16. Nxd6+ Bxd6 17. Qxd6 Nge7 18. Bc5 Qc7 19. Qd3 Ne5 20. Qd2 Qxc5 21. f4 Ba4 22. fxe5 Bxc2 23. Qd7+ Kf8 24. Qd8+ Rxd8 25. Rxd8# 1-0",
    B: "1801",
  },
  {
    W: "1654",
    T: "180+0",
    P: "1. d4 g6 2. e3 Bg7 3. Bd3 e6 4. Nc3 Ne7 5. Bd2 b6 6. Nf3 c5 7. dxc5 bxc5 8. Be4 Nbc6 9. Na4 d5 10. Nxc5 dxe4 11. Ng5 Bxb2 12. Rb1 Bg7 13. Ncxe4 O-O 14. Bc3 Bxc3+ 15. Nxc3 Ba6 16. Qf3 Ne5 17. Qh3 h5 18. Nce4 Qa5+ 19. c3 Rad8 20. Nf6+ Kg7 21. Nxe6+ fxe6 22. Qxe6 Qxc3# 0-1",
    B: "1667",
  },
  {
    W: "2168",
    T: "180+0",
    P: "1. e4 e5 2. Nc3 Nf6 3. Nf3 d6 4. Bc4 h6 5. d3 Bg4 6. h3 Bh5 7. g4 Bg6 8. Nh4 Bh7 9. Nf5 g6 10. Nxh6 Bxh6 11. Bxh6 Nbd7 12. Qf3 Qe7 13. Bg5 O-O-O 14. Nd5 Qf8 15. Bxf6 Nxf6 16. Qxf6 Kb8 17. Qe7 Qh6 18. Qxc7+ Ka8 19. Qa5 Kb8 20. Qc7+ Ka8 21. Rd1 f5 22. Qa5 Kb8 23. Qc7+ Ka8 24. Nf6 fxg4 25. Bd5 Rb8 26. Nd7 Qg7 27. Nb6+ axb6 28. Qxg7 g3 29. fxg3 g5 30. d4 g4 31. Rd3 gxh3 32. Ra3# 1-0",
    B: "1915",
  },
  {
    W: "1708",
    T: "180+0",
    P: "1. b3 e6 2. Bb2 d5 3. g3 c5 4. Bg2 f6 5. f3 g6 6. d3 Bg7 7. e4 Ne7 8. Nd2 O-O 9. Ne2 b6 10. O-O Bb7 11. f4 Nd7 12. c3 Rc8 13. c4 dxe4 14. Bxe4 Bxe4 15. dxe4 e5 16. fxe5 Nxe5 17. Bxe5 fxe5 18. Nf3 Nc6 19. h4 Qc7 20. Ng5 Rxf1+ 21. Qxf1 Nd4 22. Nxd4 exd4 23. Qd3 Re8 24. Re1 Be5 25. Kg2 h6 26. Nf3 Bxg3 27. Re2 Be5 28. Kf1 Rf8 29. Ke1 g5 30. hxg5 hxg5 31. Nxg5 Qg7 32. Ne6 Qg3+ 33. Qxg3+ Bxg3+ 34. Kd2 Rf6 35. Nc7 Bxc7 36. e5 Re6 37. Kd3 Rxe5 38. Rxe5 Bxe5 39. Ke4 Bg3 40. Kd3 Bf2 41. Ke4 Be3 42. Kd3 Kf7 43. Ke4 Ke6 44. Kd3 Kd7 45. Ke4 Kc7 46. Kd5 Kb7 47. Ke4 Ka6 48. Kd3 Ka5 49. Kc2 Kb4 50. Kb1 Ka3 51. Ka1 Bd2 52. Kb1 Bc3 53. b4 Bxb4 54. Ka1 Bc3+ 55. Kb1 d3 56. Kc1 d2+ 57. Kc2 Be5 58. Kd1 Bb2 59. Kc2 Kxa2 60. Kd1 Bc3 61. Kc2 Ka3 62. Kd3 Kb4 63. Kc2 a5 64. Kd1 Kxc4 65. Kc2 Kd4 66. Kb1 a4 67. Kc2 a3 68. Kd1 a2 69. Kc2 a1=Q 70. Kb3 1/2-1/2",
    B: "1691",
  },
  {
    W: "1996",
    T: "180+0",
    P: "1. d4 c6 2. c4 d5 3. Nc3 Bf5 4. e3 e6 5. Bd3 Bxd3 6. Qxd3 Nf6 7. Nf3 Bb4 8. g4 Nxg4 9. Rg1 h5 10. h3 Bxc3+ 11. bxc3 Nf6 12. Rxg7 Rg8 13. Rxg8+ Nxg8 14. Qh7 Ke7 15. Ne5 Qf8 16. Ng6+ 1-0",
    B: "2088",
  },
  {
    W: "2133",
    T: "60+0",
    P: "1. e4 d6 2. Nf3 c6 3. d3 g6 4. Be3 Bg7 5. Be2 Qb6 6. O-O Qxb2 7. Nbd2 Qxa1 8. Qxa1 Bxa1 9. d4 Bc3 10. Nb3 c5 11. dxc5 dxc5 12. Nxc5 Bg7 13. Rd1 b6 14. Bb5+ Kf8 15. Rd8# 1-0",
    B: "2008",
  },
  {
    W: "1698",
    T: "180+2",
    P: "1. e4 d6 2. d4 Nf6 3. Nc3 Nbd7 4. Nf3 e5 5. dxe5 dxe5 6. Bc4 h6 7. O-O c6 8. Qe2 Qc7 9. Be3 Be7 10. Rad1 a5 11. a4 Nf8 12. Rd2 Bg4 13. Rfd1 Rd8 14. Rxd8+ Bxd8 15. Bc5 Ne6 16. Bd6 Qc8 17. h3 Bh5 18. g4 Bg6 19. Nxe5 Bc7 20. Nxg6 fxg6 21. Bxc7 Nxc7 22. e5 Nfd5 23. Nxd5 cxd5 24. Ba2 O-O 25. c4 h5 26. cxd5 hxg4 27. d6+ Kh7 28. dxc7 Qxc7 29. Qxg4 Rf5 30. Bb1 Rh5 31. Bxg6+ Kh6 32. Bxh5 Qxe5 33. Qg6# 1-0",
    B: "1685",
  },
  {
    W: "2460",
    T: "180+2",
    P: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 Re8 6. Nf3 c5 7. O-O Bxc3 8. bxc3 b6 9. e4 h6 10. e5 Nh7 11. Qc2 Nf8 12. Ba3 Nc6 13. dxc5 bxc5 14. Bxc5 Qa5 15. Bd6 Ba6 16. c5 Bxd3 17. Qxd3 Ng6 18. Rfb1 Qa4 19. c4 Na5 20. Nd2 Qc6 21. g3 Nb7 22. Ne4 Rec8 23. f4 Nxd6 24. Qxd6 Qxe4 25. Rb2 Qxc4 26. Qxd7 Qxc5+ 27. Kg2 Rd8 28. Qb7 Rab8 29. Qxb8 Rxb8 30. Rxb8+ Kh7 31. Rb2 Ne7 32. Re1 Nf5 33. Rbe2 h5 34. Re4 Qc3 35. R4e2 Kg6 36. Re4 a5 37. R4e2 a4 38. Re4 a3 39. R4e2 h4 40. g4 h3+ 41. Kh1 Nd4 42. Re3 Qc6+ 43. Kg1 Nf3+ 0-1",
    B: "2484",
  },
  {
    W: "1700",
    T: "180+2",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. Nf3 Nf6 5. d4 Bg4 6. Be2 c6 7. h3 Bh5 8. Ne5 g6 9. Bg5 Nbd7 10. Bxh5 Nxe5 11. dxe5 Qxe5+ 12. Be3 Qxh5 13. Qxh5 Nxh5 14. O-O-O Bg7 15. g4 Nf6 16. Bd4 O-O-O 17. g5 e5 18. gxf6 Bh6+ 19. Be3 Bxe3+ 20. fxe3 Rde8 21. Rd3 Re6 22. Ne4 c5 23. Rhd1 c4 24. Rd7 h5 25. Rxf7 Rb6 26. Rfd7 g5 27. f7 1-0",
    B: "1724",
  },
  {
    W: "1244",
    T: "180+2",
    P: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Bc5 4. d4 exd4 5. Nxd4 O-O 6. Be3 d6 7. g3 Bd7 8. Bg2 c6 9. Na4 Bxd4 10. Bxd4 Qe7 11. Nc3 a5 12. a4 Ra6 13. Qc2 d5 14. cxd5 cxd5 15. Rd1 Bc6 16. O-O Nbd7 17. Rfe1 b6 18. e4 dxe4 19. Nxe4 Nxe4 20. Qxc6 f5 21. Qd5+ Rf7 22. Bxe4 fxe4 23. Rxe4 1-0",
    B: "1314",
  },
  {
    W: "1520",
    T: "180+2",
    P: "1. d4 d5 2. c4 Nf6 3. cxd5 Nxd5 4. e4 Nb4 5. d5 g6 6. Qa4+ N4c6 7. dxc6 bxc6 8. Nf3 Bb7 9. Be2 Bg7 10. O-O O-O 11. Nc3 e6 12. Bg5 Qd6 13. Bh4 Nd7 14. Bg3 Be5 15. Rad1 Qc5 16. b4 Qb6 17. Nxe5 Nf6 18. Bh4 Nh5 19. Bxh5 g5 1-0",
    B: "1332",
  },
  {
    W: "1740",
    T: "180+2",
    P: "1. e3 e5 2. d4 exd4 3. exd4 d5 4. c4 dxc4 5. Bxc4 Nf6 6. Nf3 Bb4+ 7. Nc3 Qe7+ 8. Be3 O-O 9. O-O Ng4 10. Re1 Re8 11. a3 Ba5 12. h3 Nxe3 13. Rxe3 Qd8 14. Qb3 Rxe3 15. Bxf7+ Kh8 16. fxe3 Qe7 17. Ne5 Bxc3 18. Qxc3 c6 19. Rf1 Be6 20. Bxe6 Na6 21. Bg4 h6 22. Ng6+ Kh7 23. Nxe7 1-0",
    B: "1895",
  },
  {
    W: "1975",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Nb3 a6 7. Be2 Be7 8. O-O b5 9. f4 Bb7 10. Bf3 b4 11. Nd5 Bxd5 12. exd5 e4 13. Be2 Qb6+ 14. Kh1 Nbd7 15. c3 bxc3 16. bxc3 O-O 17. Nd4 Rfc8 18. Be3 Nxd5 19. Nf5 Nxe3 20. Nxe7+ Kf8 21. Nxc8 Rxc8 22. Qd4 Qxd4 23. cxd4 Nxf1 24. Rxf1 a5 25. Bg4 Rc7 26. d5 Nf6 27. Bh3 Nxd5 28. Rc1 Rxc1# 0-1",
    B: "2014",
  },
  {
    W: "1233",
    T: "180+2",
    P: "1. d4 e6 2. c4 c5 3. Nf3 Qf6 4. Nc3 Bd6 5. e4 Bc7 6. e5 Qe7 7. Be2 Bb6 8. b3 d6 9. exd6 Qxd6 10. Be3 Nc6 11. dxc5 Bxc5 12. Qxd6 Bxd6 13. Rd1 Bb4 14. Rc1 Bd7 15. O-O Nb8 16. Rfd1 Nf6 17. Ne5 Bc6 0-1",
    B: "1139",
  },
  {
    W: "1564",
    T: "180+2",
    P: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 g6 5. Nf3 Bg7 6. Bd2 O-O 7. h3 Nbd7 8. Qc2 Qc7 9. Bd3 e5 10. dxe5 Nxe5 11. Nxe5 Qxe5 12. O-O Bd7 13. cxd5 Nxd5 14. Nxd5 Qxd5 15. Bc3 Qg5 16. Kh1 Bxc3 17. Qxc3 Rad8 18. Bc4 Bf5 19. Rad1 Rxd1 20. Rxd1 Be4 21. Bf1 Qf5 22. Rd2 Bd5 23. a3 Rd8 24. b4 Qb1 25. Kg1 Qe4 26. f3 Qe7 27. Qd4 Qg5 28. e4 1-0",
    B: "1677",
  },
  {
    W: "1825",
    T: "180+2",
    P: "1. e4 e5 2. Bc4 Nf6 3. d4 exd4 4. Nf3 h6 5. e5 Nh7 6. Nxd4 Bc5 7. O-O O-O 8. c3 a6 9. a4 Ba7 10. Nd2 d6 11. exd6 Qxd6 12. Ne4 Qd8 13. Be3 Nf6 14. Nxf6+ Qxf6 15. Qh5 Nd7 16. Bb3 Ne5 17. Bc2 Bg4 18. Bxh6 Bxh5 0-1",
    B: "1825",
  },
  {
    W: "2308",
    T: "180+0",
    P: "1. d4 d6 2. e4 Nf6 3. Nc3 c6 4. a4 Qa5 5. Bd2 Qb6 6. Bc1 e5 7. dxe5 dxe5 8. Nf3 Bg4 9. a5 Qb4 10. Bd3 Be7 11. Ra4 Qd6 12. O-O O-O 13. h3 Bxf3 14. Qxf3 Nbd7 15. b4 Qc7 16. Be3 b5 17. axb6 axb6 18. Rfa1 Rxa4 19. Rxa4 h6 20. b5 Nc5 21. Rc4 Rd8 22. Bf1 cxb5 23. Nxb5 Qc6 24. Nc3 Qb7 25. Bxc5 Bxc5 26. Na4 Be7 27. Nc3 Bc5 28. Bd3 Bd4 29. Ne2 Bc5 30. g4 Bf8 31. Kg2 Qd7 32. Ng3 Rc8 33. Rxc8 Qxc8 34. c4 Qc6 35. Nf5 g6 36. Ne3 Kg7 37. Nd5 Bc5 38. Nxf6 Qxf6 39. Qxf6+ Kxf6 40. Kg3 Bd4 41. h4 Bc3 42. f4 Be1+ 43. Kf3 Bxh4 44. f5 Be1 45. fxg6 fxg6 46. Ke2 Bb4 47. Kf3 Bc5 48. Bf1 Kg5 49. Be2 h5 50. gxh5 gxh5 51. Kg3 h4+ 52. Kh3 Bf2 53. Bd3 Kf4 54. Kg2 Ke3 55. Bb1 Be1 56. Kh3 Kd4 57. Kg2 Bg3 0-1",
    B: "2605",
  },
  {
    W: "1961",
    T: "180+0",
    P: "1. e4 e6 2. d4 c6 3. f4 d5 4. e5 b5 5. Nf3 a6 6. Bd3 Nd7 7. O-O g6 8. g4 c5 9. c3 c4 10. Bc2 Ne7 11. f5 gxf5 12. gxf5 Rg8+ 13. Kh1 Bb7 14. fxe6 fxe6 15. Ng5 Nf5 16. Bxf5 exf5 17. Qh5+ Rg6 18. Nxh7 Qe7 19. Qxg6+ Kd8 20. Bg5 Nxe5 21. Bxe7+ Bxe7 22. dxe5 Kd7 23. Qxf5+ Kc7 24. Qf7 Kd7 25. e6+ Kd6 26. Ng5 Bc6 27. Nf3 Rf8 28. Qh5 a5 29. Qe5+ Kc5 30. b4+ axb4 31. cxb4+ Kxb4 32. a3+ Kc5 33. Nc3 Bd6 34. Qd4# 1-0",
    B: "1989",
  },
  {
    W: "1285",
    T: "240+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. c3 Nf6 5. d3 d6 6. h3 Bd7 7. b3 Na5 8. Bd5 c6 9. Bc4 d5 10. exd5 cxd5 11. Nxe5 dxc4 12. dxc4 Be7 13. O-O Nc6 14. Re1 Nxe5 15. Rxe5 Qc7 16. Re1 O-O-O 17. Be3 Bc5 18. b4 Bxe3 19. Rxe3 Qc6 20. Nd2 g5 21. b5 Qb6 22. a4 g4 23. h4 h5 24. Nb3 Ne8 25. c5 Qf6 26. Nd4 Nc7 27. Rb1 Qxh4 28. g3 Qg5 29. c6 bxc6 30. bxc6 Bxc6 31. Qb3 Rxd4 32. cxd4 Na6 33. Qxf7 h4 34. Qc4 Nc7 35. Qxc6 hxg3 36. Rc1 Rh6 0-1",
    B: "1459",
  },
  {
    W: "1897",
    T: "30+1",
    P: "1. d4 e6 2. c4 d5 3. Nc3 Nc6 4. Bf4 Bd6 5. Bg3 Bxg3 6. hxg3 Nf6 7. e3 Ne4 8. Nf3 Nxc3 9. bxc3 dxc4 10. Bxc4 f6 11. Qc2 e5 12. dxe5 Nxe5 13. Nxe5 fxe5 14. Rd1 Qe7 15. Rxh7 Rxh7 16. Qxh7 Qf8 17. Qg6+ Ke7 18. Qg5+ Qf6 19. Qxf6+ gxf6 20. f3 Be6 21. Bxe6 Kxe6 22. Rb1 b6 23. Ke2 Rd8 24. Rd1 Rd5 25. Rxd5 Kxd5 26. Kd3 f5 27. e4+ fxe4+ 28. fxe4+ Ke6 29. Kc4 c5 30. Kb5 Kd7 31. Ka6 Kc6 32. c4 b5 33. cxb5+ Kc7 34. Ka5 c4 35. Kb4 c3 36. Kxc3 Kb6 37. a4 a6 38. bxa6 Kxa6 39. g4 Ka5 40. g5 Kxa4 41. g6 Kb5 42. g7 Kc6 43. g8=Q Kd6 44. Qd5+ Ke7 45. Qxe5+ Kf7 46. g4 Kg6 47. Qf5+ Kh6 48. e5 Kg7 49. e6 Kg8 50. e7 Kg7 51. e8=N+ Kh6 52. Qf6+ Kh7 53. Qg7# 1-0",
    B: "1563",
  },
  {
    W: "2156",
    T: "300+0",
    P: "1. e4 e5 2. f4 exf4 3. Bc4 c6 4. d4 d5 5. exd5 cxd5 6. Bb3 Qh4+ 7. Kf1 Nf6 8. Nf3 Qh5 9. Bxf4 Nc6 10. Nc3 Bb4 11. Nb5 O-O 12. Nc7 Rb8 13. Ng5 Bg4 14. Qd3 Rbd8 15. h3 h6 16. Nf3 a6 17. Kg1 Bxf3 18. gxf3 Ba5 19. Ba4 Nb4 20. Qf1 Qf5 21. Bh2 Nxc2 22. Rc1 Nxd4 23. Kg2 Qg5+ 24. Bg3 Nf5 25. Qf2 Nh5 0-1",
    B: "2262",
  },
  {
    W: "1373",
    T: "300+0",
    P: "1. d4 c5 2. Nf3 cxd4 3. Nxd4 e5 4. Nb3 Bc5 5. Nxc5 Qa5+ 6. Nc3 Qxc5 7. Qd5 Qxd5 8. Nxd5 Kd8 9. e4 Nf6 10. Nxf6 gxf6 11. Be3 Nc6 12. O-O-O Ke7 13. Bc5+ d6 14. Bxd6+ Ke6 15. Bc5 b6 16. Bc4# 1-0",
    B: "1517",
  },
  {
    W: "1643",
    T: "300+0",
    P: "1. e4 c5 2. Bc4 Nc6 3. Nf3 Na5 4. Bb3 Nxb3 5. axb3 Nf6 6. e5 Nd5 7. c4 Nf4 8. d4 cxd4 9. Bxf4 h6 10. Nxd4 g5 11. Bg3 Bg7 12. O-O O-O 13. Nf5 Qb6 14. Nxg7 Kxg7 15. Nc3 f5 16. exf6+ exf6 17. Nd5 Qc6 18. Qd4 d6 19. f4 Be6 20. fxg5 Bxd5 21. cxd5 Qc5 22. gxf6+ Kf7 23. Qxc5 dxc5 24. Bd6 Rfd8 25. Bxc5 Rxd5 26. b4 b6 27. Be3 Re8 28. Bxh6 Re6 29. Bg7 Rg5 30. Rxa7+ Ke8 31. f7+ Kd8 32. f8=Q+ Re8 33. Qf6+ Kc8 34. Qxg5 Rd8 35. Qf5+ Kb8 36. Rfa1 Rd1+ 37. Rxd1 Kxa7 38. Rd7+ Ka6 39. Rd6 Ka7 40. Qb5 Kb7 41. Qxb6+ Kc8 42. Qd8+ Kb7 43. Rb6+ Ka7 1-0",
    B: "1646",
  },
  {
    W: "1535",
    T: "300+0",
    P: "1. d4 b6 2. c4 Bb7 3. e3 g6 4. Nf3 Bh6 5. Be2 e6 6. h3 Ne7 7. Nc3 O-O 8. e4 Bxc1 9. Qxc1 d6 10. Qh6 c5 11. Ng5 1-0",
    B: "1530",
  },
  {
    W: "1824",
    T: "300+0",
    P: "1. f4 d5 2. e3 Nc6 3. d4 f6 4. Nf3 Be6 5. Bd3 Qd7 6. O-O O-O-O 7. Nbd2 h5 8. c4 Nb4 9. cxd5 Nxd5 10. Qe2 h4 11. h3 Nh6 12. Ne4 b6 13. Bd2 Nf5 14. Rac1 Kb8 15. Rfd1 Ng3 16. Nxg3 hxg3 17. Qe1 Bxh3 18. Qxg3 Be6 19. Kf2 Bf7 20. Rh1 Rxh1 21. Rxh1 e6 22. Ke2 Qa4 23. a3 Qb3 24. Bc1 Be8 25. Qe1 Ba4 26. Nd2 Qa2 27. Bc4 Qa1 28. Nb3 Bxb3 29. Bxb3 a5 30. Qc3 Nxc3+ 31. bxc3 Qxc3 32. Bd1 0-1",
    B: "1793",
  },
  {
    W: "1654",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Nxc6 bxc6 6. Nc3 Nf6 7. e5 Qe7 8. Bf4 d6 9. Be2 dxe5 10. Bg5 h6 11. Bh4 Qe6 12. O-O O-O 13. Bxf6 Qxf6 14. Ne4 Qe7 15. Bc4 Bb6 16. a4 a5 17. Qh5 Be6 18. Bxe6 Qxe6 19. Rad1 Rfd8 20. b3 Rd5 21. c4 Rd4 22. Rfe1 Rad8 23. Rxd4 Rxd4 24. c5 Ba7 25. Nc3 Qxb3 26. Qxe5 Qxc3 27. Qe8+ Kh7 28. h3 Bxc5 29. Rf1 Rf4 30. g3 Qxg3+ 0-1",
    B: "1685",
  },
  {
    W: "1834",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be2 e5 7. Be3 exd4 0-1",
    B: "1885",
  },
  {
    W: "1195",
    T: "300+0",
    P: "1. e4 e5 2. Bc4 d6 3. d4 Nh6 4. Bxh6 gxh6 5. Bxf7+ Kxf7 6. Qh5+ Kg8 7. dxe5 dxe5 8. Nc3 Nc6 9. Rd1 Qf6 10. Nf3 Bb4 11. O-O Bxc3 12. bxc3 Be6 13. g4 Bxa2 14. g5 hxg5 15. Nxg5 Bc4 16. Rfe1 Rd8 17. Rxd8+ Nxd8 18. Re3 Nf7 19. Rf3 Qg6 20. Qh4 Nxg5 21. Rg3 h6 22. Qg4 Kh7 23. Qd7+ Kg8 24. Qxc7 Rh7 25. Qxc4+ Kh8 26. Qd3 Rf7 27. h4 Nf3+ 28. Kg2 Ne1+ 29. Kf1 Qf6 30. Qe2 Qxh4 31. Kxe1 Rc7 32. Kd2 Rd7+ 33. Kc1 Qh1+ 34. Kb2 Qh2 35. Qb5 Rd2 36. Qxe5+ Kh7 37. Qg7# 1-0",
    B: "1202",
  },
  {
    W: "1083",
    T: "300+0",
    P: "1. g4 d5 2. Bg2 e5 3. c4 d4 4. Bxb7 Bxb7 5. Qb3 Bxh1 6. Nf3 Bxf3 7. exf3 Qh4 8. Qb7 Qxh2 9. Qxa8 Qg1+ 10. Ke2 Nf6 11. Qxb8+ Ke7 12. Qxc7+ Nd7 13. c5 Qxc1 14. c6 Qc4+ 15. Kd1 Qa4+ 16. b3 Qb5 17. Qxd7+ Kf6 18. Na3 Bxa3 19. Qxa7 Bd6 20. c7 Qf1+ 21. Kc2 Qxa1 22. Qb6 Qxa2+ 23. Kd3 Qb1+ 24. Kc4 Qc1+ 25. Kd5 Qxc7 26. Qxd6+ Qxd6+ 27. Kxd6 Rb8 28. f4 exf4 29. Kc5 d3 30. Kc4 Rd8 31. b4 g6 32. b5 h5 33. g5+ Kxg5 34. b6 h4 35. Kc5 Rc8+ 36. Kb5 Rb8 37. Kc6 h3 38. b7 h2 39. Kc7 Rxb7+ 40. Kxb7 h1=Q+ 41. Kc7 Qc1+ 42. Kd6 Kf5 43. Kd5 Qxd2 44. Kd4 Qxf2+ 45. Kxd3 Qe3+ 46. Kc4 f3 47. Kb4 f2 48. Kc4 f1=Q+ 49. Kb4 Qd1 50. Kc4 Qec1+ 51. Kb4 Qd6+ 52. Kb3 Qb6+ 53. Ka2 Qc2+ 54. Ka3 Qcb3# 0-1",
    B: "1119",
  },
  {
    W: "1325",
    T: "300+0",
    P: "1. e4 c5 2. Bc4 Nc6 3. Nf3 g6 4. O-O Bg7 5. Ng5 e6 6. f4 Nge7 7. f5 d5 8. exd5 exd5 9. Be2 O-O 10. fxg6 Nxg6 11. Bh5 Qxg5 12. Bxg6 Qxg6 13. d3 Bh3 14. Rf2 Bd4 15. Qf3 Bxf2+ 16. Qxf2 Nd4 17. Na3 Rae8 18. Be3 Rxe3 19. Rf1 Re2 0-1",
    B: "1366",
  },
  {
    W: "1173",
    T: "300+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. Nc3 d4 5. Ne4 c5 6. Bc4 Bf5 7. d3 Nf6 8. Nxf6+ Qxf6 9. O-O Bd6 10. g3 O-O 11. Bd5 Bc8 12. Bg5 Qf5 13. Be4 Qg4 14. Re1 Re8 15. Bd5 Rxe1+ 16. Qxe1 h6 17. Qe8+ Kh7 18. Be4+ Bf5 19. Bd2 Bxe4 20. dxe4 Qxf3 21. Re1 b6 22. e5 Bc7 23. e6 fxe6 24. Re5 Bxe5 25. Qxe6 Qd1+ 26. Kg2 Qxd2 27. Qxe5 Qxc2 28. Qe4+ g6 29. Qxc2 Nc6 30. b3 Nb4 31. Qe2 d3 32. Qe7+ Kh8 33. Qf6+ Kh7 34. Qf7+ Kh8 35. Qxg6 d2 36. Qf6+ Kh7 37. Qf5+ Kg7 38. Qe5+ Kh7 39. Qe4+ Kh8 40. Qe2 Rd8 41. f3 d1=Q 42. Qe5+ Kh7 43. Qf5+ Kh8 44. Qf6+ Kh7 45. Qf5+ Kh8 46. Qe5+ Kh7 47. Qe4+ Kh8 48. Qe5+ 1/2-1/2",
    B: "1160",
  },
  {
    W: "1241",
    T: "300+0",
    P: "1. e3 c5 2. d4 d5 3. Bc4 e6 4. Be2 Nc6 5. dxc5 Bxc5 6. b3 Nf6 7. Bb2 O-O 8. Nh3 Re8 9. Bh5 e5 10. Ng5 Nxh5 11. Qxh5 Qf6 12. Qxh7+ Kf8 13. Qh5 d4 14. Nh7+ 1-0",
    B: "1240",
  },
  {
    W: "1610",
    T: "300+0",
    P: "1. e4 e6 2. Nc3 c5 3. f4 Nc6 4. Nf3 d5 5. e5 Nge7 6. d4 cxd4 7. Nxd4 Ng6 8. Nxc6 bxc6 9. Be3 Be7 10. Bd3 O-O 11. O-O c5 12. Ne2 Bb7 13. Ng3 c4 14. Be2 Qb6 15. Bxb6 axb6 16. Kh1 b5 17. c3 Bc5 18. Bh5 Nh4 19. Qg4 Ng6 20. Rae1 Rxa2 21. f5 exf5 22. Nxf5 Ra3 23. bxa3 1-0",
    B: "1635",
  },
  {
    W: "2140",
    T: "300+0",
    P: "1. d4 d5 2. e3 Nc6 3. f4 Bf5 4. c3 Nf6 5. Nf3 Bxb1 6. Rxb1 Ne4 7. Bd3 f5 8. O-O e6 9. Ne5 Nxe5 10. fxe5 Be7 11. Bxe4 dxe4 12. Qh5+ g6 13. Qh6 Bf8 14. Qh3 Bg7 15. g4 O-O 16. Bd2 b5 17. gxf5 gxf5 18. Rbd1 Qd5 19. b3 a5 20. Rf2 a4 21. Rb1 axb3 22. axb3 Ra2 23. b4 Qc4 24. Qf1 Qxf1+ 25. Kxf1 Rfa8 26. Be1 Rxf2+ 27. Kxf2 Ra2+ 28. Kf1 Bh6 29. Bf2 Rc2 30. Rb3 f4 31. exf4 Bxf4 32. h3 e3 33. Be1 Kf7 34. Ra3 Kg6 35. Ra7 Kf5 36. Rxc7 Ke4 37. Rxh7 Kf3 38. Rf7 e2+ 39. Kg1 Rc1 40. Rxf4+ Kxf4 41. Kf2 Rc2 42. h4 Ke4 43. h5 Kd3 44. h6 Ra2 45. Kf3 Ra1 46. Kf2 Ra8 47. Kf3 Rh8 48. d5 exd5 49. e6 Rxh6 50. e7 Re6 51. Kf4 Rxe7 52. Kf5 Kc2 53. Kf6 Re4 54. Kf5 Kd1 0-1",
    B: "2103",
  },
  {
    W: "1567",
    T: "300+0",
    P: "1. Nf3 d5 2. Ng5 h6 3. Nxf7 Kxf7 4. c4 dxc4 5. e3 Be6 6. Nc3 Nf6 7. b3 cxb3 8. axb3 Nc6 9. Nb5 a6 10. Nc3 Qd7 11. h3 Bd5 12. Nxd5 Nxd5 13. Bc4 e6 14. Bxd5 Qxd5 15. O-O Bd6 16. Re1 Rhf8 17. e4 Qe5 18. g3 Qxa1 19. Qh5+ Kg8 20. d3 Qc3 21. Kf1 Bb4 22. Bxh6 Qxe1+ 23. Kg2 Qxf2+ 24. Kh1 Qf1+ 25. Kh2 Rf2# 0-1",
    B: "1552",
  },
  {
    W: "1945",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 Nc6 3. Bg5 Bg4 4. e3 f6 5. Bh4 e6 6. a3 Nge7 7. Nbd2 Nf5 8. Bg3 Bd6 9. Bxd6 cxd6 10. Be2 e5 11. dxe5 dxe5 12. h3 Bh5 13. g4 Bxg4 14. hxg4 Nd6 15. c3 Qb6 16. Qc2 O-O-O 17. O-O-O Kb8 18. Rxh7 Rxh7 19. Qxh7 Rd7 20. Qg8+ Kc7 21. Qxd5 Na5 22. Qa2 Nc6 23. Nc4 Nxc4 24. Qxc4 Rxd1+ 25. Kxd1 Qxb2 26. a4 Qb1+ 27. Kd2 e4 28. Nd4 Qb2+ 29. Ke1 Qc1+ 30. Bd1 f5 31. Nxc6 bxc6 32. gxf5 Qb2 33. Qf7+ Kb6 34. Qb3+ Qxb3 35. Bxb3 Kc5 36. Kd2 a5 37. Be6 Kd6 38. c4 Ke5 39. Kc3 Kd6 40. Kd4 c5+ 41. Kxe4 g6 42. f4 gxf5+ 43. Kxf5 1-0",
    B: "2040",
  },
  {
    W: "2371",
    T: "30+0",
    P: "1. g3 d5 2. Bg2 Nf6 3. c3 Nc6 4. d3 e5 5. Nd2 Bg4 6. Ne4 dxe4 7. h3 Bf5 8. g4 Bg6 9. g5 Nh5 10. Qb3 exd3 11. exd3 Qxd3 12. Qxb7 Be4 13. Bxe4 Qxe4+ 14. Ne2 Rd8 15. O-O Nf4 16. Ng3 Qg2# 0-1",
    B: "2410",
  },
  {
    W: "1778",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. c3 Bc5 4. a3 Nf6 5. Bd3 Ng4 6. O-O h5 7. Bc2 Qf6 8. d3 d6 9. Bg5 Qg6 10. Qd2 f6 11. Bh4 Qh7 12. h3 g5 13. Bg3 h4 14. Bh2 Nxh2 15. Nxh2 Be6 16. b4 Bb6 17. b5 Ne7 18. d4 O-O-O 19. d5 Bd7 20. c4 Kb8 21. Ra2 Ng6 22. Nc3 Nf4 23. Na4 Bd4 24. Kh1 Rdg8 25. Nc3 g4 26. Ne2 Nxe2 27. Qxe2 gxh3 28. Rg1 Qg6 29. g4 hxg3 30. Rxg3 Qh6 31. Bd1 Qf4 32. Rf3 Qh4 33. a4 Rg2 34. Qf1 Rhg8 35. Rg3 R8xg3 36. fxg3 Qxg3 37. Rxg2 hxg2+ 38. Qxg2 Qe1+ 39. Qf1 Qxe4+ 40. Bf3 Qf4 41. Qg2 Qg5 42. Qf1 Bg4 43. Bxg4 e4 44. Qf5 Qc1+ 45. Nf1 Qh6+ 46. Kg2 Qh8 47. Ng3 e3 48. Qe4 Bc5 0-1",
    B: "1849",
  },
  {
    W: "1400",
    T: "180+0",
    P: "1. e4 e5 2. Nc3 f6 3. Nf3 Nc6 4. d4 d6 5. Bb5 Bd7 6. dxe5 dxe5 7. O-O Qe7 8. Bxc6 Bxc6 9. a3 Bd7 10. b4 O-O-O 11. Be3 Nh6 12. Bxa7 b6 13. a4 Kb7 14. Nb5 Bxb5 15. axb5 Ra8 16. Qd5+ Kc8 17. Qxa8+ Kd7 18. Qb7 Qxb4 19. Bxb6 Qxb5 20. Qxc7+ Ke6 21. c4 Qd7 22. Qxd7+ Kxd7 23. Rfd1+ Kc6 24. Rab1 Ng4 25. Nd2 g6 26. c5 f5 27. exf5 gxf5 28. h3 Nf6 29. Ba5 f4 30. Rbc1 e4 31. f3 e3 32. Nb3 e2 33. Re1 Rg8 34. Rxe2 Nd5 35. Re6+ Kb5 36. c6 Ne3 37. c7 Bb4 38. c8=Q Rxc8 39. Rxc8 Bxa5 40. Nd4+ Kb4 41. Rb8+ Ka4 42. Ra8 h5 43. Re4 h4 44. Rxf4 Nd1 45. Rxh4 Nc3 46. Nc6+ Kb5 47. Nxa5 Ne2+ 48. Kf2 Nd4 49. Rh5+ Kb4 50. Nc6+ Kc4 51. Ne7 Nc2 52. Rd8 Kc3 53. Rh7 Nd4 54. Nf5 Nc2 55. Rc7+ Kb2 56. Rb8+ Ka3 57. Ra7# 1-0",
    B: "1249",
  },
  {
    W: "2339",
    T: "60+0",
    P: "1. e4 c5 2. d4 e6 3. c3 Qc7 4. d5 Nf6 5. Bd3 c4 6. Bc2 e5 7. Nf3 d6 8. O-O Bg4 9. Nbd2 Nbd7 10. a4 Rc8 11. Qe2 Be7 12. h3 Bh5 13. g4 Bg6 14. Nh4 O-O 15. Nf5 Bxf5 16. gxf5 Kh8 17. Kh1 g6 18. Rg1 gxf5 19. exf5 Rg8 20. Ne4 Nxe4 21. Bxe4 Nf6 22. Bh6 b5 23. axb5 Qb6 24. f3 Qxb5 25. Rxg8+ Rxg8 26. Rxa7 Nh5 27. Qf2 Ng3+ 28. Kh2 Bh4 29. Rxf7 Nxf5 30. Qa7 Qxb2+ 31. Kh1 Qg2# 0-1",
    B: "2411",
  },
  {
    W: "1316",
    T: "180+2",
    P: "1. g3 e5 2. Bg2 d5 3. Nf3 e4 4. Nd4 c5 5. Nb3 c4 6. Nd4 Bc5 7. c3 Bxd4 8. cxd4 Nf6 9. d3 cxd3 10. exd3 Bg4 11. Qd2 exd3 12. Qxd3 Qa5+ 13. Bd2 Qb6 14. b3 Nc6 15. h3 Bh5 16. O-O O-O 17. g4 Bg6 18. Qe3 Qxd4 19. Qxd4 Nxd4 20. Bc3 Ne2+ 21. Kh2 Nxc3 22. Nxc3 Rac8 23. Nxd5 Nxd5 24. Bxd5 Rfd8 25. Bxb7 Rc2 26. a4 Rdd2 27. Kg3 Rc3+ 28. Kg2 Rxb3 29. Bd5 Rxd5 30. Rfe1 h6 31. Re8+ Kh7 32. Ra8 Ra5 33. Kh2 Rb4 34. Re1 Rbxa4 35. Ree8 Bc2 36. Rh8+ Kg6 37. Rac8 Be4 38. h4 Kf6 39. g5+ hxg5 40. hxg5+ Rxg5 41. Rc7 Rg2+ 42. Kh3 Rxf2 43. Rf8 Ra3+ 44. Kg4 Rg2+ 45. Kf4 Bd5 46. Rcxf7+ Bxf7 47. Rxf7+ Kxf7 48. Ke5 Ra6 0-1",
    B: "1361",
  },
  {
    W: "1491",
    T: "180+0",
    P: "1. d4 d5 2. Nf3 Nc6 3. Nc3 Nf6 4. e4 e6 5. e5 Ne4 6. Bb5 Bd7 7. Bxc6 Bxc6 8. a3 Nxc3 9. bxc3 Be7 10. h4 h6 11. g4 f6 12. a4 Qd7 13. c4 Bxa4 14. c5 Qb5 15. Bd2 fxe5 16. Nxe5 Bf6 17. Nf3 O-O 18. Rb1 Qc4 19. Rb4 Qa6 20. Qa1 Bc6 21. Qc1 Rae8 22. g5 Bd8 23. gxh6 gxh6 24. Bxh6 Rxf3 25. Rg1+ Kf7 26. Rg7+ Kf6 27. Qg5# 1-0",
    B: "1573",
  },
  {
    W: "1913",
    T: "300+0",
    P: "1. e4 c5 2. f4 d5 3. d3 dxe4 4. dxe4 Qxd1+ 5. Kxd1 Bg4+ 6. Be2 Bxe2+ 7. Nxe2 Nc6 8. c3 O-O-O+ 9. Kc2 e5 10. f5 Nf6 11. Ng3 h5 12. h4 Ng4 13. Re1 Be7 14. Bg5 Bxg5 15. hxg5 f6 16. g6 h4 17. Ne2 h3 18. gxh3 Rxh3 19. Nd2 Nf2 20. Ng1 Rh2 21. Re2 Ng4 22. Rae1 Rdh8 23. Rxh2 Rxh2 24. Nf3 Rf2 25. Rf1 Ne3+ 26. Kd3 Nxf1 0-1",
    B: "1838",
  },
  {
    W: "1610",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Nxc6 bxc6 6. Bc4 Nf6 7. Nc3 Bb4 8. f3 d5 9. exd5 cxd5 10. Bb3 O-O 11. O-O Ba6 12. Bd2 Bxf1 13. Kxf1 d4 14. Ne4 Bxd2 15. Nxf6+ Qxf6 16. Qxd2 Rfd8 17. Qd3 Rab8 18. Kg1 a5 19. a4 Qd6 20. Rf1 Re8 21. Qc4 Qe7 22. h3 Rbc8 23. Qd5 Rc5 24. Qe4 Qg5 25. Rd1 Kh8 26. Rxd4 f5 27. Qe2 Qc1+ 28. Rd1 Qf4 29. Qa6 h6 30. Qg6 Rec8 31. Rd7 R5c7 32. Qxg7# 1-0",
    B: "1683",
  },
  {
    W: "1966",
    T: "30+0",
    P: "1. Nf3 b6 2. d3 Bb7 3. Nbd2 Bxf3 4. Nxf3 c6 5. e4 e6 6. Be2 Bb4+ 7. c3 Be7 8. a4 h6 9. Qc2 g5 10. h3 g4 11. hxg4 Nf6 12. g5 Ng4 13. gxh6 Rxh6 14. Rxh6 Nxh6 15. Bxh6 Bf8 16. Bxf8 Kxf8 17. O-O-O Qf6 18. Rh1 Qg7 19. Nh4 Ke7 20. g4 Qg5+ 21. Kb1 Qf4 22. f3 Qg3 23. Qd1 Na6 24. Qf1 Nc5 25. Ng2 Qd6 26. d4 Nxa4 27. Ne3 b5 28. Nc2 Nb6 29. e5 Qd5 30. Rh7 Rf8 31. Bd3 Nc4 32. Bxc4 bxc4 33. f4 Qb5 34. f5 exf5 35. gxf5 Ke8 36. f6 Qb3 37. Qg1 Qxc2+ 38. Kxc2 Kd8 39. Kb1 Kc7 40. Qh1 Rb8 41. Rh8 Rxb2+ 42. Kc1 a5 43. Kxb2 a4 44. Kc1 a3 45. Kb1 a2+ 46. Ka1 1-0",
    B: "2194",
  },
  {
    W: "1878",
    T: "60+0",
    P: "1. c4 e5 2. g3 Nf6 3. Bg2 c6 4. d4 exd4 5. Qxd4 d5 6. Nf3 Be7 7. O-O dxc4 8. Qxc4 O-O 9. Nc3 Be6 10. Qa4 Nbd7 11. Ng5 Nc5 12. Qc2 h6 13. Nxe6 Nxe6 14. Be3 Qc7 15. Rfd1 Rfd8 16. Rac1 Rxd1+ 17. Rxd1 Rd8 18. Rxd8+ Bxd8 19. Bh3 Be7 20. Bxe6 fxe6 21. Qb3 Nd5 22. Nxd5 exd5 23. Bxa7 Qe5 24. Qxb7 Qxe2 25. Qb8+ Kh7 26. Qf4 Qxb2 27. Qf5+ Kg8 28. Be3 Qa1+ 29. Kg2 Qf6 30. Qd3 Qe5 31. Bd4 Qe4+ 0-1",
    B: "1803",
  },
  {
    W: "1680",
    T: "60+0",
    P: "1. d4 c5 2. Nf3 cxd4 3. Bg5 e6 4. Bxd8 Kxd8 5. Nxd4 Ne7 6. Nf3 f6 7. c3 Ng6 8. e3 Be7 9. Qc2 Ke8 10. Nbd2 Kf7 11. Bd3 Rf8 12. e4 Ne5 13. Nxe5+ fxe5 14. f3 Ke8 15. O-O-O Nc6 16. h4 a6 17. h5 b5 18. h6 g6 19. g4 Bb7 20. Rdf1 Kd8 21. a3 Bg5 22. Kb1 Kc7 23. c4 Nd4 24. Qc3 Kb8 25. cxb5 axb5 26. Qb3 Bc6 27. Nc4 Nxb3 28. Nd6 Be7 29. Nxb5 Bxb5 30. Bxb5 Bxa3 31. bxa3 Rxa3 32. Bc4 Nd4 33. Kb2 Re3 34. Rc1 Nxf3 35. Rc3 Rxe4 36. Rb3+ Kc7 37. Bd3 Rd4 38. Rc1+ Kd6 39. Bb5 Ke7 40. Rc7 Rd2+ 0-1",
    B: "1681",
  },
  {
    W: "1554",
    T: "60+0",
    P: "1. e4 d6 2. d4 c5 3. d5 b6 4. c4 Nd7 5. Nf3 Bb7 6. Nc3 e5 7. dxe6 fxe6 8. Be2 g6 9. O-O e5 10. Bg5 Be7 11. Bxe7 Nxe7 12. Qd2 O-O 13. Qh6 Nf6 14. Ng5 Rf7 15. Ne6 Qd7 16. Qh3 Re8 17. Bg4 Bxe4 18. Nxe4 Nxe4 19. Ng5 Qd8 20. Nxf7 Kxf7 21. Be6+ Kf8 22. Qf3+ Nf5 23. Qxe4 Ke7 24. Bxf5 Rf8 25. Bg4 Ke8 26. Qd5 Qf6 27. Qc6+ Kf7 28. Qd5+ Kg7 29. h4 Kh8 30. h5 Qf7 31. hxg6 hxg6 32. Bh5 Qxd5 33. Bxg6 Qxc4 34. Rac1 Qh4 0-1",
    B: "1597",
  },
  {
    W: "1162",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nge7 4. c3 a6 5. Ba4 b5 6. Bb3 d5 7. exd5 Nxd5 8. d4 exd4 9. cxd4 Bg4 10. O-O Nb6 11. Re1+ Be7 12. Be3 O-O 13. Nc3 h6 14. h3 Bxf3 15. Qxf3 Bg5 16. Qxc6 Bxe3 17. Rxe3 Re8 18. Rae1 Rxe3 19. Rxe3 Qd7 20. Re8+ Rxe8 21. Qxd7 Nxd7 22. Nd5 Nf6 23. Nxc7 Re7 0-1",
    B: "1218",
  },
  {
    W: "2160",
    T: "60+0",
    P: "1. d4 d5 2. c4 dxc4 3. e3 b5 4. Nf3 c6 5. Be2 a6 6. O-O Bb7 7. b3 cxb3 8. Qxb3 Nd7 9. a4 e6 10. axb5 cxb5 11. Ba3 Bxa3 12. Nxa3 Ne7 13. Nc2 O-O 14. Nb4 Nd5 15. Nxd5 Bxd5 16. Qb4 Nb6 17. Rfc1 Nc4 18. Nd2 a5 19. Qxb5 Nxd2 20. Qd3 Nb3 21. Rcb1 Nxa1 22. Rxa1 Qb6 23. h3 Rfb8 24. e4 Bb3 25. Bh5 g6 26. Be2 a4 27. e5 a3 28. Bf3 Ra7 29. h4 a2 30. h5 Bd5 31. Bxd5 exd5 32. h6 Qb1+ 33. Rxb1 axb1=Q+ 34. Qxb1 Rxb1+ 35. Kh2 Ra3 0-1",
    B: "2360",
  },
  {
    W: "1488",
    T: "60+0",
    P: "1. Nc3 d5 2. e4 dxe4 3. Nxe4 Nf6 4. Nxf6+ exf6 5. Nf3 Qe7+ 6. Qe2 Nc6 7. Qxe7+ Bxe7 8. Bb5 Bd7 9. O-O O-O-O 10. Re1 a6 11. Bxc6 Bxc6 12. Rxe7 Rhe8 13. Rxe8 Rxe8 14. Kf1 Bxf3 15. gxf3 Re5 16. d3 Rh5 17. Kg2 Rg5+ 18. Bxg5 fxg5 19. Re1 h6 20. Re7 Kd8 21. Rxf7 1-0",
    B: "1433",
  },
  {
    W: "1543",
    T: "60+0",
    P: "1. Nc3 d5 2. Nb1 c5 3. Nc3 Nc6 4. Nb1 Nf6 5. Nc3 d4 6. Nb1 e5 7. c3 dxc3 8. Nxc3 c4 9. Nb1 b5 10. Nc3 Bb4 11. Nb1 a5 12. a3 Bc5 13. Nc3 b4 14. Nb5 Qb6 15. Nd6+ 1-0",
    B: "1521",
  },
  {
    W: "1363",
    T: "60+0",
    P: "1. e4 d6 2. d4 Nf6 3. Bd3 Nc6 4. Ne2 a6 5. c4 Bg4 6. Be3 Nb4 7. O-O Nxd3 8. Qxd3 Bxe2 9. Qxe2 Nxe4 10. Nc3 Nxc3 11. bxc3 h6 12. d5 b6 13. Rab1 g5 14. Bd4 Rg8 15. f4 g4 16. f5 h5 17. f6 e6 18. dxe6 Qxf6 19. Bxf6 fxe6 20. Qxe6+ Be7 21. Qxe7# 1-0",
    B: "1249",
  },
  {
    W: "1726",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 d6 3. d4 Bg4 4. dxe5 Bxf3 5. Qxf3 dxe5 6. Bc4 Nf6 7. Nc3 Bc5 8. Nd5 Be7 9. Nxf6+ Bxf6 10. O-O O-O 11. Bd3 Nc6 12. Qf5 Nd4 13. Qh3 Qe7 14. Be3 c5 15. Bxd4 cxd4 16. b3 Rac8 17. f4 Rc3 18. fxe5 Bxe5 19. Qf5 Qf6 20. Qxf6 Bxf6 21. Rae1 Bg5 22. e5 Be3+ 23. Kh1 Re8 24. Rxe3 dxe3 25. Re1 1-0",
    B: "1793",
  },
  {
    W: "2107",
    T: "60+0",
    P: "1. d4 e6 2. Nf3 d5 3. Bg5 c5 4. Bxd8 cxd4 5. Bg5 f6 6. Bh4 e5 7. c3 dxc3 8. Nxc3 d4 9. Ne4 Bg4 10. Qa4+ Nc6 11. Qb5 Bxf3 12. gxf3 Bb4+ 13. Nd2 Ne7 14. Qxb7 O-O 15. a3 Rab8 16. Qa6 Bxd2+ 17. Kxd2 Rxb2+ 18. Kc1 Rfb8 19. Bh3 R2b3 20. Be6+ Kf8 21. Bxb3 Rxb3 22. Qc4 Rc3+ 23. Qxc3 dxc3 24. Kc2 Nd4+ 25. Kd3 Nxe2 26. Kxe2 Nd5 27. Rhd1 Nf4+ 28. Ke3 c2 29. Rdc1 Nd5+ 30. Kd3 Nf4+ 31. Kxc2 Ng6 32. Bg3 h5 33. h4 f5 34. f4 exf4 35. Kd2 fxg3 36. Rc6 Ne5 37. Re6 Nc4+ 38. Kc3 Nb6 39. fxg3 Kf7 40. Rxb6 axb6 41. Rb1 f4 42. gxf4 g5 43. Rxb6 g4 44. Rb5 g3 45. Rg5 g2 46. Rxg2 Kf6 47. Rg5 Ke6 48. Rxh5 Kf7 49. Rg5 Kf6 50. a4 Kf7 51. a5 Ke6 52. a6 Kf6 53. a7 Ke7 54. a8=Q Kd6 55. Qa6+ Ke7 56. Rg7+ Kf8 57. Rb7 Ke8 58. Qa8# 1-0",
    B: "2091",
  },
  {
    W: "1646",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bc5 4. Nxe5 Nxe5 5. d4 Bxd4 6. Qxd4 d6 7. f4 Ng6 8. Qxg7 Qf6 9. Qxf6 Nxf6 10. e5 Nd7 11. exd6 cxd6 12. Be3 O-O 13. O-O-O Re8 14. Bd2 f5 15. Bb5 b6 16. Rhe1 Rxe1 17. Rxe1 a6 18. Re8+ Kg7 19. Bxd7 Bxd7 20. Rxa8 Bc6 21. Rxa6 b5 22. Rxc6 1-0",
    B: "1666",
  },
  {
    W: "2362",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 d5 5. exd5 Nxd5 6. O-O Nb6 7. Bb5 Bd6 8. Re1 f6 9. d4 O-O 10. Nc3 exd4 11. Nxd4 Nxd4 12. Qxd4 Bxh2+ 13. Kxh2 Qxd4 14. Be3 Qh4+ 15. Kg1 Bf5 16. Rad1 Bxc2 17. Rd2 Bf5 18. Red1 c6 19. Be2 Rae8 20. Bf3 Qb4 21. a3 Qe7 22. b4 Nc4 23. Bc5 Nxd2 24. Bxe7 Nxf3+ 25. gxf3 Rxe7 0-1",
    B: "2305",
  },
  {
    W: "1938",
    T: "180+0",
    P: "1. c4 Nf6 2. d4 c5 3. d5 e6 4. Nc3 exd5 5. Nxd5 Nxd5 6. cxd5 d6 7. e4 Bd7 8. a4 g6 9. Bd2 Bg7 10. Bc3 O-O 11. Bxg7 Kxg7 12. Bd3 Qa5+ 13. Qd2 Qb6 14. Ne2 Na6 15. O-O Nb4 16. b3 Nxd3 17. Qxd3 Rae8 18. Kh1 Bg4 19. f3 Bd7 20. f4 Qd8 21. f5 f6 22. fxg6 hxg6 23. Nf4 Rh8 24. Qg3 g5 25. Rae1 Re5 26. Nd3 Ree8 27. b4 cxb4 28. Qxd6 b3 29. Nc5 Bb5 30. Qxd8 Rxd8 31. Rf2 Rd6 32. Nxb3 Bxa4 33. Nd4 Bd7 34. Ref1 Re8 35. Nf5+ Bxf5 36. Rxf5 Rxe4 37. R5f2 b5 38. Rc2 b4 39. Rc7+ Kg6 40. Rxa7 Rxd5 41. h3 b3 42. Ra6 Rf4 43. Rb1 b2 44. Rb6 Rf2 45. R6xb2 Rxb2 46. Rxb2 Rd1+ 47. Kh2 f5 48. g4 f4 49. Rb6+ Kf7 50. Kg2 Rd3 51. Rb5 Rg3+ 52. Kh2 Kf6 53. Rf5+ Kg6 54. Rb5 Rd3 55. Rb6+ Kf7 56. Rb2 Kf6 57. Rb5 Rd2+ 58. Kg1 f3 59. Rf5+ Kg6 60. Rxf3 Rc2 61. Rf2 Rc1+ 62. Kg2 Rc3 63. Re2 Rb3 64. Re6+ Kh7 65. Re5 Kg6 66. Re6+ Kf7 67. Re5 Kf6 68. Rf5+ Kg6 69. Re5 Rb6 70. Kf3 Rf6+ 71. Ke4 Rf3 72. Rf5 Rxf5 73. gxf5+ Kf6 74. h4 gxh4 75. Kf4 h3 76. Kg4 1-0",
    B: "1970",
  },
  {
    W: "2018",
    T: "600+0",
    P: "1. d4 c5 2. d5 e6 3. dxe6 dxe6 4. Qxd8+ Kxd8 5. e4 Nc6 6. c3 Bd7 7. Be3 Kc7 8. Nf3 f5 9. e5 Rd8 10. Nbd2 g6 11. Ng5 Nh6 12. f4 b6 13. Be2 a6 14. Bxa6 Ng4 15. Bg1 Ra8 16. Bc4 Nd8 17. h3 Nh6 18. Be3 b5 19. Be2 Bc6 20. O-O Be7 21. a3 Nhf7 22. Nxf7 Nxf7 23. Nb3 c4 24. Nc5 Bd5 25. b4 cxb3 26. Bxb5 Rhb8 27. c4 Bxc5 28. Bxc5 Be4 29. a4 b2 30. Rad1 Rd8 31. Ba3 b1=Q 32. Rxb1 Bxb1 33. Rxb1 Rd3 34. Bc5 Rad8 35. a5 Rd1+ 36. Rxd1 Rxd1+ 37. Kf2 g5 38. Be8 Nd8 39. fxg5 Kb7 40. Bb5 Ra1 41. a6+ Ka8 42. Be7 Nf7 43. Bf6 Ka7 44. h4 Rd1 45. h5 Nd8 46. g6 hxg6 47. hxg6 Rd4 48. g7 Rf4+ 49. Ke3 Re4+ 50. Kd3 Rg4 51. Bxd8 Rxg7 52. Bf6 Rxg2 53. c5 f4 54. Ke4 Rg4 55. Bd8 f3+ 56. Kxf3 Rb4 57. Bd7 Kxa6 58. Bxe6 Kb5 59. Be7 Kc6 60. Bd6 Ra4 61. Bf5 Ra3+ 62. Ke4 Ra1 63. e6 Re1+ 64. Kf4 Rf1+ 65. Kg5 Rg1+ 66. Kf6 Ra1 67. e7 Ra8 68. Bg6 Ra1 69. e8=Q+ Kb7 70. Bf7 Rf1+ 71. Ke7 Re1+ 72. Kf8 Rb1 0-1",
    B: "1974",
  },
  {
    W: "1778",
    T: "600+0",
    P: "1. d4 d5 2. Nf3 Nf6 3. c4 Nc6 4. Nc3 dxc4 5. e4 Bg4 6. Bxc4 h6 7. O-O h5 8. Qb3 e6 9. Qxb7 Nb4 10. Bb5+ c6 11. Bxc6+ Nxc6 12. Qxc6+ Nd7 13. Ne5 Bh3 14. Bg5 f6 15. Qxe6+ Be7 16. Nc6 Nf8 17. Nxd8 Nxe6 18. Nxe6 Bxe6 19. Be3 h4 20. d5 Bd7 21. f4 a5 22. Rad1 Bb4 23. e5 Bg4 24. Rd2 h3 25. a3 hxg2 26. Kxg2 Bh3+ 27. Kg1 Bxf1 28. Kxf1 Bf8 29. e6 Bd6 30. Nb5 Ra6 31. f5 Ke7 32. Kg2 Rxh2+ 33. Kf3 Rxd2 34. Bxd2 Ra8 35. a4 Rc8 36. Bc3 Rc4 37. Bxa5 Rxa4 38. b4 Bxb4 39. Bxb4+ Rxb4 40. d6+ Kd8 41. Nc7 Rb3+ 42. Ke4 Rb4+ 43. Kd5 Rf4 44. Kc6 1-0",
    B: "1712",
  },
  {
    W: "1289",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 d5 5. exd5 Qxd5 6. Qe2+ Ne7 7. Be3 Nf5 8. Qb5+ Qxb5 9. Bxb5+ c6 10. Nxf5 Bxf5 11. Bd3 Bb4+ 12. c3 Bxc3+ 13. Nxc3 Bxd3 14. O-O-O Bg6 15. Bd4 O-O 16. Bc5 Re8 17. Rhe1 Rxe1 18. Rxe1 Na6 19. Na4 b5 0-1",
    B: "1308",
  },
  {
    W: "1880",
    T: "600+0",
    P: "1. Nf3 Nc6 2. d4 d5 3. e3 Nf6 4. Bd3 Bg4 5. c3 e6 6. Nbd2 Bd6 7. Nf1 Qe7 8. h3 Bh5 9. Qc2 Bxf3 10. gxf3 O-O-O 11. Rg1 g6 12. Bd2 e5 13. Ng3 exd4 14. O-O-O dxc3 15. Bxc3 Kb8 16. Ne2 Be5 17. Nd4 Bxd4 18. exd4 Rhe8 19. Qb3 Nd7 20. a4 Qd6 21. Kb1 f5 22. a5 Re6 23. Rc1 Rde8 24. Ba6 b6 25. Ka2 Ka8 26. axb6 Nxb6 27. Rgd1 Rb8 28. Bb5 Nc4 29. Qa4 Rb6 30. b3 Re2+ 31. Ka1 Rxb5 0-1",
    B: "1802",
  },
  {
    W: "1554",
    T: "600+0",
    P: "1. d4 Nf6 2. e3 g6 3. Nf3 d5 4. Bd3 Bg7 5. Nc3 c6 6. e4 dxe4 7. Bxe4 Nxe4 8. Nxe4 O-O 9. Be3 Nd7 10. Qd2 Nf6 11. Nxf6+ Bxf6 12. Bh6 Re8 13. O-O-O Bg4 14. Qd3 Qd5 15. c3 Qxa2 16. h3 Qa1+ 17. Kc2 Qa4+ 18. Kc1 Bf5 19. Qe2 Qa1+ 20. Kd2 Qxb2+ 21. Ke1 Qxc3+ 22. Kf1 Qc2 23. g4 Qxe2+ 24. Kxe2 Be4 25. Rhg1 Rad8 26. g5 Bxf3+ 27. Kxf3 Bxd4 28. Rd3 e5 29. Rgd1 c5 30. Ra3 a6 31. Rb3 Rd7 32. h4 Re6 33. Rdb1 b5 34. Ra3 b4 35. Ra5 Rb7 36. Rba1 b3 37. Rxa6 Rxa6 38. Rxa6 Rb8 39. Ke4 b2 40. h5 b1=Q+ 41. Kd5 Qb7+ 0-1",
    B: "1596",
  },
  {
    W: "1233",
    T: "600+0",
    P: "1. d4 d5 2. e3 f6 3. Nf3 e6 4. c4 c6 5. Nc3 dxc4 6. Bxc4 c5 7. dxc5 Bxc5 8. Bb5+ Bd7 9. Bd3 Bb4 10. Bd2 Bxc3 11. Bxc3 Nh6 12. O-O O-O 13. h3 Nf5 14. e4 Nd6 15. e5 Nb5 16. Bxb5 Bxb5 17. exf6 Bxf1 18. Qxf1 gxf6 19. Nh4 Nc6 20. Rd1 Qe8 21. g4 f5 22. f4 a6 23. gxf5 exf5 24. Nf3 Qe3+ 25. Kh1 Qxf4 26. Rd7 b5 27. Rg7+ Kh8 28. Rg4+ Qd4 29. Bxd4+ Nxd4 30. Rxd4 Rae8 31. Ng5 Re7 32. Qc1 Rfe8 33. Nf3 Re2 34. Qc3 Kg8 35. Qb3+ Kg7 36. Qc3 Kg6 37. Rd6+ Kh5 38. Ng1 R8e3 39. Qa5 Rf2 40. Qxa6 Kg5 41. Rg6+ hxg6 42. Qxb5 Rg3 43. Qc5 Rf1 44. Kh2 Kh4 45. Qc4+ f4 46. Ne2 Rxh3+ 47. Kg2 Re1 48. Qxf4+ Kh5 49. Qf5+ Kh4 50. Qf4+ Kh5 51. Ng3+ Rxg3+ 52. Qxg3 Re2+ 53. Kg1 Rxb2 54. Qf3+ Kh6 55. Qf8+ Kg5 56. a3 Rb1+ 57. Kg2 Rb2+ 58. Kf3 Rb3+ 59. Ke4 1-0",
    B: "1288",
  },
  {
    W: "1336",
    T: "600+0",
    P: "1. d4 d5 2. Nc3 e6 3. Bf4 Nf6 4. e4 dxe4 5. Bc4 Bd6 6. Bxd6 Qxd6 7. Nge2 e3 8. O-O exf2+ 9. Rxf2 O-O 10. Nb5 Qd7 11. Nf4 a6 12. Nc3 b5 13. Bb3 Bb7 14. d5 exd5 15. Ncxd5 Nxd5 16. Bxd5 Bxd5 17. Nxd5 Nc6 18. Nf6+ gxf6 19. Qxd7 Nd4 20. Qg4+ Kh8 21. Qxd4 1-0",
    B: "1335",
  },
  {
    W: "1635",
    T: "600+0",
    P: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. Nc3 Bf5 5. e3 Nc6 6. Bxc4 e6 7. O-O Nb4 8. a3 Nc2 9. Ra2 a6 10. Bd3 Bxd3 11. Qxd3 Nxa3 12. bxa3 b5 13. Ne5 Nd5 14. Nxd5 Qxd5 15. Ra1 Bd6 16. Nf3 Qh5 17. Bd2 O-O 18. Bb4 Bxb4 19. axb4 f5 20. Qc2 Ra7 21. Qc6 Re8 22. Rxa6 Rxa6 23. Qxa6 Kf7 24. Qxb5 Re7 25. Qc5 g5 26. Ne5+ Kf6 27. f3 Qe8 28. Ra1 h5 29. b5 g4 30. fxg4 hxg4 31. Rf1 Kg5 32. Rf4 Rh7 33. Nxg4 e5 34. Qxe5 Qxe5 35. Nxe5 Kf6 36. h3 Ke6 37. Kf2 Rh8 38. Kf3 Rb8 39. g4 fxg4+ 40. hxg4 Rxb5 41. Rf7 c5 42. Ke4 c4 43. Rc7 Rb4 44. Rxc4 Rxc4 45. Nxc4 Kf6 46. d5 Ke7 47. g5 Ke8 48. Ke5 Kd7 49. g6 Ke7 50. e4 Kf8 51. d6 Kg7 52. Kf5 Kh6 53. d7 Kg7 54. d8=Q Kh6 55. Qg5+ Kg7 56. Qf6+ Kh6 57. g7+ Kh7 58. Qf8 Kh6 59. Qh8# 1-0",
    B: "1657",
  },
  {
    W: "2185",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Bc5 5. Nxe5 Nxe5 6. d4 Bb6 7. dxe5 Nxe4 8. Qg4 Nxf2 9. Rxf2 Bxf2+ 10. Kxf2 O-O 11. Bh6 g6 12. Qd4 Re8 13. e6 f6 14. exd7 Bxd7 15. Qxd7 1-0",
    B: "2197",
  },
  {
    W: "1547",
    T: "600+0",
    P: "1. d4 d5 2. c4 dxc4 3. Nc3 Nf6 4. Bg5 Nd5 5. e3 h6 6. Bh4 Nxc3 7. bxc3 b5 8. Qf3 Qd6 9. Qxa8 g5 10. Qxb8 gxh4 11. Qxc8+ Qd8 12. Qxd8+ Kxd8 13. Nf3 1-0",
    B: "1510",
  },
  {
    W: "2082",
    T: "120+1",
    P: "1. d4 e6 2. Nf3 d5 3. Bg5 f6 4. Bh4 Bd6 5. Nbd2 Ne7 6. e3 O-O 7. c4 c6 8. cxd5 exd5 9. Bd3 Nd7 10. Qc2 g6 11. O-O f5 12. Qb3 Nf6 13. Rae1 Kg7 14. a3 Rb8 15. Ne5 Ne4 16. Bxe4 fxe4 17. f3 exf3 18. Ndxf3 Qc7 19. Bg3 Bf5 20. Ng5 h6 21. Rxf5 hxg5 22. Rxg5 Rf6 23. Qd3 Rbf8 24. Bf4 Qc8 25. h3 Qe8 26. Qe2 Nf5 27. Qg4 Ne7 28. Rf1 Bxe5 29. dxe5 Rf5 30. Rxf5 Nxf5 31. Rf3 Qe6 32. Kh1 c5 33. Qg5 d4 34. exd4 cxd4 35. g4 Qd5 36. gxf5 Qxf3+ 37. Kg1 Rxf5 38. Qh6+ Kf7 39. Qh7+ Ke8 40. Qg8+ Kd7 41. Qd5+ Qxd5 42. e6+ Kxe6 43. Bg3 Qe4 44. Bf2 d3 45. Kf1 Qe2+ 46. Kg1 Rxf2 47. Kh1 Qe1# 0-1",
    B: "2074",
  },
  {
    W: "1994",
    T: "15+0",
    P: "1. g3 g6 2. Bg2 Bg7 3. e4 Nf6 4. e5 d6 5. exf6 exf6 6. d4 O-O 7. Ne2 f5 8. O-O Re8 9. Nf4 d5 10. c4 c6 11. cxd5 cxd5 12. Nxd5 Be6 13. Nf4 Nc6 14. d5 Bxd5 15. Nxd5 Ne5 16. Ne3 Ng4 17. Qxd8 Raxd8 18. Nxg4 fxg4 19. Be3 f5 20. Nc3 b6 21. Rfd1 b5 22. Rxd8 Rxd8 23. Nxb5 Re8 24. Nc7 Bxb2 25. Nxe8 Bxa1 26. Bd2 Bg7 27. Bc3 Kf7 28. Bxg7 Kxe8 29. Bf6 Kd7 30. Bg5 Kd6 31. Bf4+ Kc5 32. h3 Kb4 33. hxg4 Ka3 34. gxf5 gxf5 35. Bg5 Kxa2 36. g4 Kb3 37. gxf5 Kc4 38. f6 a6 39. f7 a5 40. f8=Q 1-0",
    B: "2087",
  },
  {
    W: "1857",
    T: "300+0",
    P: "1. e4 f5 2. exf5 g6 3. fxg6 hxg6 4. Nf3 d5 5. Bd3 Kf7 6. O-O Nc6 7. b4 Qd6 8. b5 Bg4 9. Ng5+ Ke8 10. Bxg6+ Qxg6 11. Qxg4 Nf6 12. Qe6 Qxg5 13. bxc6 b6 14. d3 Qh5 15. Bf4 Bh6 16. Be5 Ng4 17. h3 Nxe5 18. Nc3 Bg7 19. Nxd5 Qf7 20. Nxc7+ Kf8 21. Qxf7+ Kxf7 22. Nxa8 Rxa8 23. d4 Nc4 24. Rad1 Rc8 25. d5 Ne5 26. f4 Nc4 27. g4 Ne3 28. d6 exd6 29. Rxd6 Nxf1 30. Rd7+ Kf6 31. g5+ Kg6 32. c7 Bc3 33. Kxf1 a5 34. Ke2 a4 35. Kd3 Ba5 36. Ke4 b5 37. f5+ Kxg5 38. h4+ Kf6 39. h5 Rxc7 0-1",
    B: "1782",
  },
  {
    W: "1583",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 d6 4. O-O Bg4 5. d3 Nf6 6. Nbd2 Nd4 7. c3 Nxf3+ 8. Nxf3 Be7 9. h3 Be6 10. Bxe6 fxe6 11. Ng5 Qd7 12. f4 h6 13. Nf3 O-O-O 14. fxe5 Nh5 15. exd6 Bxd6 16. Nd4 Nf6 17. Qe2 c5 18. Nb3 Bc7 19. e5 Nd5 20. Nxc5 Qc6 21. d4 b6 22. Na6 Rdf8 23. Bd2 Bd8 24. b4 Bg5 25. b5 Qb7 26. Bxg5 hxg5 27. c4 Nf4 28. Qg4 Rh4 29. Qxg5 Rfh8 30. Rxf4 R4h6 31. Raf1 Qd7 32. Rf8+ Rxf8 33. Rxf8+ Kb7 34. Rf3 Qxd4+ 35. Kh1 Qd7 36. Qe7 Qxe7 0-1",
    B: "1575",
  },
  {
    W: "1200",
    T: "180+0",
    P: "1. e4 d5 2. d3 dxe4 3. Be2 f5 4. f3 exf3 5. Nxf3 Nf6 6. O-O b6 7. Nh4 Bb7 8. Nxf5 Nbd7 9. Qd2 e6 10. Nh4 Bd6 11. Bf3 Bxf3 12. Rxf3 O-O 13. Ng6 hxg6 14. Rh3 Kf7 15. Qg5 Bc5+ 16. Be3 Bxe3+ 17. Qxe3 Nd5 18. Qf3+ Ke7 19. Qg4 Ne3 20. Rxe3 Nf6 21. Qb4+ c5 22. Qc4 Ng4 23. Qxe6# 1-0",
    B: "1276",
  },
  {
    W: "1325",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be2 e5 7. Nf5 g6 8. Ng3 Qc7 9. Bg5 Bg7 10. h4 O-O 11. Qd2 Nd4 12. Bh6 Bxh6 13. Qxh6 Nxc2+ 14. Kd2 Nxa1 15. Rxa1 Qd6+ 16. Kc2 b5 17. h5 Nxh5 18. Nxh5 gxh5 19. Qxd6 1-0",
    B: "1301",
  },
  {
    W: "2534",
    T: "180+0",
    P: "1. c4 Nf6 2. g3 c6 3. Bg2 d5 4. Nf3 Bf5 5. cxd5 cxd5 6. Qb3 Qb6 7. Nc3 e6 8. O-O Nc6 9. d3 h6 10. Qxb6 axb6 11. Be3 Bc5 12. Bf4 Bh7 13. a3 Kd7 14. b4 Bd6 15. Be3 Bc7 16. Nb5 Ng4 17. Bd2 Bd8 18. h3 Nge5 19. Nxe5+ Nxe5 20. Rac1 Nxd3 21. Rcd1 Nb2 22. Rc1 Nc4 23. Bc3 Bf6 24. Bxf6 gxf6 25. Rfd1 Rhc8 26. e4 Nxa3 27. Rxc8 Kxc8 28. Nd6+ Kc7 29. Nxf7 Bxe4 30. Bxe4 dxe4 31. Ra1 Rf8 32. Nxh6 Nc4 33. Ng4 b5 34. Rc1 Kd6 35. Rd1+ Kc6 36. Rc1 Kd5 37. Rd1+ Kc6 38. Rc1 Kb6 39. h4 f5 40. Ne3 Ne5 41. Kg2 Nd3 42. Rb1 f4 43. gxf4 Rxf4 44. h5 Rh4 45. Rh1 Nf4+ 46. Kg1 Rxh1+ 47. Kxh1 Nxh5 48. Kg2 Nf4+ 49. Kg3 Nd5 50. Nc2 Kc6 51. Kg4 Kd6 52. Kg5 Ke5 53. Kg6 Nf4+ 54. Kf7 Nd3 55. Ke7 Nxf2 56. Kd7 Nd1 57. Kc7 e3 58. Kxb7 e2 59. Kc6 Ne3 60. Ne1 Kd4 61. Kxb5 Kc3 62. Kc5 Nc2 63. Nf3 Nxb4 64. Kd6 Nd3 65. Kxe6 Kc2 66. Kd5 Kd1 67. Ke4 Ne1 68. Nd4 Nc2 69. Nxe2 Kxe2 1/2-1/2",
    B: "2694",
  },
  {
    W: "2006",
    T: "180+0",
    P: "1. e4 c5 2. f4 e6 3. Nf3 Nc6 4. c3 d5 5. e5 d4 6. d3 b6 7. Be2 Bb7 8. Nbd2 f6 9. O-O Be7 10. Ne4 f5 11. Neg5 Qd7 12. Qb3 Bc8 13. cxd4 cxd4 14. Bd2 Bxg5 15. Nxg5 h6 16. Nf3 Nge7 17. Bd1 O-O 18. a3 Ba6 19. Be1 Rac8 20. Be2 Qd5 21. Qd1 Rc7 22. Bf2 Rfc8 23. b4 b5 24. Rc1 Nd8 25. Rxc7 Rxc7 26. Bxd4 Bb7 27. h3 g5 28. fxg5 hxg5 29. Ne1 Nf7 30. Bb2 Nxe5 31. Nf3 Nxf3+ 32. Bxf3 Qa2 33. Bxb7 Rxb7 34. Qf3 Rd7 35. Bf6 Qd5 36. Qh5 Nc6 37. Qxg5+ Kf8 38. Qh6+ Ke8 39. Qh8+ Kf7 40. Qg7+ Ke8 41. Qg8# 1-0",
    B: "1938",
  },
  {
    W: "2032",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3 Nxe4 5. Bxf7+ Kxf7 6. Nxe4 Bb4 7. O-O Rf8 8. d3 Kg8 9. Nfg5 d5 10. Qh5 h6 11. Ng3 hxg5 12. Bxg5 Be7 13. Bd2 Nd4 14. c3 Ne6 15. Nf5 Nf4 16. Nxe7+ Qxe7 17. Bxf4 Rxf4 18. g3 Bg4 19. Qg6 Bf5 20. Qh5 g6 21. Qh6 Rg4 22. Rae1 Qf6 23. Re3 Rf8 24. f3 Rg5 25. Rfe1 Rh5 26. Qxh5 gxh5 27. Rxe5 Bh3 28. f4 Qg6 29. Rg5 Qxg5 30. fxg5 Rf5 31. g6 Kg7 32. d4 Kxg6 33. Re6+ Kg5 34. Re1 b6 35. Re5 Rxe5 36. dxe5 Kf5 37. Kf2 Kxe5 38. Ke3 c5 39. b3 a5 40. a4 Bd7 41. h4 b5 42. axb5 Bxb5 43. Kf3 Bd7 44. Ke3 d4+ 45. cxd4+ cxd4+ 46. Kd3 Bb5+ 47. Kd2 Ke4 48. Kc2 d3+ 49. Kd2 Bd7 50. Kc3 Ke3 0-1",
    B: "2019",
  },
  {
    W: "1314",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nxd5 6. Bxd5 Qxd5 7. Qf3 Qxf3 8. Nxf3 Bg4 9. O-O Bxf3 10. gxf3 O-O-O 11. Re1 Nb4 12. Na3 f5 13. d3 Nc6 14. Be3 h6 15. Nb5 b6 16. Nc3 g5 17. Kf1 f4 18. Bd2 Nd4 19. Rad1 Nxc2 20. Re4 Bb4 21. a3 Bxc3 22. Bxc3 Rhg8 23. Rxe5 g4 24. Ke2 gxf3+ 25. Kxf3 Rge8 26. Kxf4 Rxe5 27. Kxe5 Re8+ 28. Kf6 c5 29. Kg6 Re6+ 30. Kh5 1-0",
    B: "1380",
  },
  {
    W: "2012",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. f3 e5 6. Bb5+ Bd7 7. Bxd7+ Qxd7 8. Nf5 g6 9. Ne3 Nc6 10. Nc3 O-O-O 11. O-O Nd4 12. Ncd5 Nxd5 13. Nxd5 f5 14. c3 Nc6 15. Be3 Kb8 16. b4 f4 17. Bf2 Ne7 18. Bh4 Nxd5 19. Bxd8 Ne3 20. Qe2 Qxd8 21. Rfc1 h5 22. c4 h4 23. c5 dxc5 24. bxc5 h3 25. g3 Bh6 26. c6 Qb6 27. cxb7 fxg3 28. Rc8+ Rxc8 29. bxc8=Q+ Kxc8 30. hxg3 Nc2+ 31. Kh2 Nxa1 32. Qc4+ Qc7 33. Qg8+ Kb7 34. Qd5+ Kb8 35. Qg8+ Qc8 36. Qxg6 Bf8 37. Qf6 Bc5 38. Qxe5+ Ka8 39. Qxa1 Bd6 40. Qd4 Qc2+ 41. Kxh3 Qc8+ 42. g4 Qc2 43. Qd5+ 1-0",
    B: "2012",
  },
  {
    W: "1605",
    T: "180+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. Nc3 Nf6 4. d4 O-O 5. Bd3 c6 6. O-O e6 7. e5 Ne8 8. Bg5 Qb6 9. b3 d6 10. Na4 Qd8 11. Bxd8 1-0",
    B: "1749",
  },
  {
    W: "1923",
    T: "180+0",
    P: "1. e4 c5 2. c3 d6 3. d4 cxd4 4. cxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. Be2 O-O 8. Qd2 Ng4 9. Bxg4 Bxg4 10. Bh6 Nd7 11. Bxg7 Kxg7 12. f3 Bh5 13. g4 e5 14. d5 Qh4+ 15. Qf2 Qxf2+ 16. Kxf2 Bxg4 17. fxg4 f5 18. gxf5 gxf5 19. exf5 Rxf5+ 20. Ke3 Raf8 21. Nge2 Rf3+ 22. Kd2 Nc5 23. Rhg1+ Kh8 24. Rg3 Rf2 25. Rag1 Rxh2 26. b4 Nd7 27. Rg7 Nf6 28. Rxb7 Nh5 29. Rgg7 Nxg7 30. Rxa7 h5 31. b5 Rff2 32. b6 Rf8 33. a4 Rb8 34. a5 Rh4 35. Kd3 Rb4 36. Ne4 Nf5 37. Rf7 Nd4 38. Nxd4 Rxd4+ 39. Ke3 Ra4 40. Ra7 h4 41. Nf6 Ra3+ 42. Ke4 Ra4+ 43. Kf5 Rf4+ 44. Ke6 Rxf6+ 45. Kxf6 h3 46. Rg7 h2 47. a6 h1=Q 48. a7 Qf3+ 49. Ke6 Re8+ 50. Kxd6 Qa3+ 51. Kc7 Qa6 52. Rh7+ 1-0",
    B: "1901",
  },
  {
    W: "2111",
    T: "180+0",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Qb6 6. Bd3 Bd7 7. dxc5 Bxc5 8. O-O Nh6 9. Nbd2 Qc7 10. Nb3 Bb6 11. Bf4 Ng8 12. Nbd4 Nge7 13. Nb5 Qd8 14. Nd6+ Kf8 15. Ng5 Ng6 16. Ngxf7 Qh4 17. Bg3 Qg4 18. Qxg4 Ngxe5 1-0",
    B: "2129",
  },
  {
    W: "1844",
    T: "180+0",
    P: "1. d3 Nf6 2. e4 e5 3. Nf3 Bc5 4. Nxe5 d6 5. Nf3 Bg4 6. Be2 O-O 7. O-O Nbd7 8. Nbd2 h6 9. c3 Re8 10. Qc2 Nf8 11. d4 Bb6 12. a4 d5 13. a5 dxe4 14. Ne5 Bxe2 15. axb6 Bxf1 16. bxc7 Qxc7 17. Nxf1 N8d7 18. Ng3 Nxe5 19. dxe5 Rxe5 20. Bf4 Qc5 21. Re1 Ree8 22. Nxe4 Nxe4 23. Rxe4 Rxe4 24. Qxe4 Qc6 25. Qe2 Re8 26. Qd2 Qe4 27. Be3 Qb1+ 28. Qc1 Qxc1+ 29. Bxc1 Re1# 0-1",
    B: "1822",
  },
  {
    W: "2220",
    T: "180+0",
    P: "1. e4 d6 2. d4 Bd7 3. d5 Qc8 4. Bd3 c5 5. c4 g6 6. Ne2 Bg7 7. O-O f6 8. Nbc3 a6 9. f4 f5 10. Ng3 fxe4 11. Ncxe4 Nf6 12. Nxf6+ Bxf6 13. Ne4 O-O 14. Nxf6+ exf6 15. b3 Bg4 16. Qc2 Kg7 17. Bb2 Bd7 18. Rae1 Re8 19. Qc3 Rf8 20. Re7+ Kg8 21. Qc2 f5 22. Qc3 1-0",
    B: "2132",
  },
  {
    W: "1529",
    T: "60+0",
    P: "1. d4 d5 2. c3 Nc6 3. Bf4 Bf5 4. Nf3 e6 5. e3 f6 6. Bd3 g5 7. Bxf5 gxf4 8. Bd3 fxe3 9. fxe3 e5 10. dxe5 fxe5 11. Nxe5 Nxe5 12. Qh5+ Ng6 13. Bxg6+ Ke7 14. Qe5+ Kd7 15. Qxh8 Ne7 16. Qxh7 Kd6 17. Bc2 Qe8 18. O-O Rd8 19. Rf6+ Kd7 20. Qf5+ Nxf5 21. Bxf5+ Ke7 22. Rh6 Bxh6 0-1",
    B: "1589",
  },
  {
    W: "2199",
    T: "300+0",
    P: "1. d4 Nf6 2. Nf3 g6 3. c4 Bg7 4. Nc3 O-O 5. e4 d6 6. Be2 e5 7. d5 a5 8. O-O Na6 9. Qc2 Nh5 10. g3 h6 11. a3 Nc5 12. Be3 Qe8 13. Nd2 f5 14. b4 Na6 15. f3 f4 16. Bf2 fxg3 17. hxg3 axb4 18. axb4 Nxb4 19. Qb3 Rxa1 20. Rxa1 Na6 21. Qb5 Qe7 22. Nb3 Qg5 23. Kh2 Nxg3 24. Bxg3 Qh5+ 25. Kg2 Qh3+ 26. Kf2 h5 27. Bf1 Qg4 28. Bg2 h4 29. Bh2 h3 30. Bh1 Bh6 31. Rg1 Qh5 32. c5 Bf4 33. Bxf4 Rxf4 34. Qe8+ Rf8 35. Rxg6+ Qxg6 36. Qxg6+ Kh8 37. Qh6+ Kg8 38. Qg6+ Kh8 39. Qh5+ Kg8 40. Qg6+ 1/2-1/2",
    B: "2130",
  },
  {
    W: "1728",
    T: "300+0",
    P: "1. d3 d5 2. c3 c5 3. d4 Nf6 4. Bg5 e6 5. Bxf6 Qxf6 6. e3 Nc6 7. Nf3 e5 8. dxe5 Nxe5 9. Nxe5 Qxe5 10. Nd2 Be6 11. Nf3 Qc7 12. h4 Be7 13. g3 Bg4 14. Bg2 O-O-O 15. Qa4 Kb8 16. Qxg4 g6 17. Qa4 f5 18. Ng5 Bxg5 19. hxg5 d4 20. cxd4 cxd4 21. exd4 Rhe8+ 22. Kf1 f4 23. Qb3 Rxd4 24. g4 Red8 25. Bf3 Rd3 26. Qxb7+ Qxb7 27. Bxb7 Kxb7 28. Rxh7+ Kb6 29. Rg7 f3 30. Rxg6+ Kb7 31. Kg1 R8d4 32. Re1 Rxg4+ 33. Kh2 Rd7 34. Kh3 Rg2 35. Rf1 Rd5 36. Rg7+ Kb6 37. b4 Rd4 38. Rg6+ Kb5 39. Rh6 Rxg5 40. Rh4 Rxb4 41. Rxb4+ Kxb4 42. Re1 Rg2 43. Re4+ Ka3 44. Re3+ Kxa2 45. Rxf3 Rg6 46. Rf7 a5 47. f4 a4 48. f5 Rh6+ 49. Kg4 Rh1 50. Kg5 a3 51. Kf6 Kb3 52. Rb7+ Kc4 53. Kf7 a2 54. Ra7 a1=Q 55. Rxa1 Rxa1 56. f6 Kd5 57. Kf8 Ke6 58. f7 Rf1 59. Kg7 Rxf7+ 60. Kg6 Rf2 61. Kg5 Rf1 62. Kg4 Ke5 63. Kg3 Rf7 64. Kg2 Ke4 65. Kg3 Rf8 66. Kg4 Rg8+ 67. Kh5 Kf5 68. Kh6 Rg2 69. Kh7 Kf6 70. Kh6 Rh2# 0-1",
    B: "1963",
  },
  {
    W: "1330",
    T: "60+0",
    P: "1. d4 g6 2. e4 Bg7 3. e5 e6 4. Nf3 Ne7 5. Nc3 O-O 6. Be3 d6 7. Bc4 d5 8. Bb3 b6 9. O-O c5 10. dxc5 bxc5 11. Bxc5 Re8 12. Be3 Nbc6 13. Re1 Ba6 14. a3 Rb8 15. Na4 d4 16. Nxd4 Nxd4 17. Bxd4 Nf5 18. Bxa7 Qxd1 19. Raxd1 Ra8 20. Be3 Bxe5 21. Bc5 Bg7 22. c4 Bb7 23. Bc2 Bc6 24. Nb6 Rab8 25. Nd7 Rxb2 26. Bxf5 gxf5 27. a4 Bxa4 28. Rd6 Bc3 29. Rc1 Rc8 30. h3 Bd2 31. Rd1 Bxd1 32. Rxd2 Rxd2 33. Kh2 Bh5 34. Nf6+ Kg7 35. Nxh5+ Kg6 36. Ng3 Kg7 37. Be3 Kh8 38. Bxd2 Rg8 39. f4 Rxg3 40. Kxg3 h6 41. Kf2 h5 42. g4 h4 43. g5 Kg7 44. Be3 Kg6 45. c5 f6 46. c6 fxg5 47. c7 gxf4 48. c8=Q fxe3+ 49. Kxe3 Kg5 50. Qg8+ Kf6 51. Qxe6+ Kxe6 52. Kf4 Kf6 0-1",
    B: "1457",
  },
  {
    W: "1588",
    T: "600+0",
    P: "1. e4 1-0",
    B: "1614",
  },
  {
    W: "2199",
    T: "0+5",
    P: "1. d4 d5 2. Bf4 e6 3. e3 Be7 4. Nf3 Nf6 5. Bd3 O-O 6. Nbd2 h6 7. O-O a6 8. Qe2 c6 9. h3 b5 10. Rad1 Nfd7 11. c3 f5 12. Ne5 Nf6 13. f3 Bd6 14. e4 fxe4 15. fxe4 dxe4 16. Nxe4 Nxe4 17. Qxe4 Bxe5 18. Qh7+ Kf7 19. Bxe5+ Ke7 20. Qxg7+ Rf7 21. Qxf7# 1-0",
    B: "1675",
  },
  {
    W: "1993",
    T: "60+0",
    P: "1. d4 e6 2. c4 Be7 3. Nc3 a6 4. e4 c6 5. Nf3 b5 6. cxb5 axb5 7. Be2 Qa5 8. Bd2 Qd8 9. O-O h6 10. h3 g5 11. Ne5 f6 12. Ng6 Rh7 13. Nxe7 Qxe7 14. Bd3 h5 15. Be2 g4 16. hxg4 hxg4 17. Bxg4 Qg7 18. Bh3 Rxh3 19. Bf4 Rh8 20. Qf3 Qh7 21. g4 e5 22. Bg3 Ne7 23. dxe5 fxe5 24. Rad1 Ba6 25. Bxe5 b4 26. Bxh8 Bxf1 27. Rxf1 bxc3 28. Bxc3 Rxa2 29. Rd1 Ra5 30. e5 Rxe5 31. Bxe5 d5 32. Bxb8 Qc2 33. Qe3 Qxd1+ 34. Kg2 Qxg4+ 35. Kf1 Qc8 36. Bg3 1-0",
    B: "1817",
  },
  {
    W: "2139",
    T: "60+0",
    P: "1. d4 f5 2. Bf4 Nf6 3. e3 d5 4. Nf3 e6 5. Bd3 c6 6. Ne5 Be7 7. h3 O-O 8. O-O Nbd7 9. c4 Ne4 10. f3 Nxe5 11. Bxe5 Nf6 12. Nc3 Bd6 13. Bf4 Bxf4 14. exf4 Bd7 15. Re1 Qc7 16. Re5 Rae8 17. Qc2 Bc8 18. Rae1 Nd7 19. Rxe6 Rxe6 20. Rxe6 Qxf4 21. Ne2 Qg5 22. cxd5 Nf6 23. Bxf5 Bxe6 24. Bxe6+ Kh8 25. dxc6 bxc6 26. Qxc6 Qe3+ 27. Kf1 Nh5 28. d5 Nf4 29. Nxf4 Qxf4 30. Qc3 Rd8 31. Kf2 Qd6 32. a3 Qb6+ 33. Kf1 Re8 34. b4 Qd6 35. Qc5 Qd8 36. Qc6 h6 37. Kg1 Qe7 38. Kh1 Qf6 39. b5 Qa1+ 0-1",
    B: "2200",
  },
  {
    W: "2108",
    T: "60+0",
    P: "1. c4 c6 2. Nc3 d5 3. cxd5 cxd5 4. d4 e6 5. g3 Nf6 6. Bg2 Nc6 7. e3 b6 8. Nge2 Nb4 9. O-O a6 10. Qa4+ b5 11. Qd1 Bb7 12. a3 Nc6 13. b4 Rc8 14. Bb2 Bd6 15. Rc1 O-O 16. f3 a5 17. e4 axb4 18. axb4 Nxb4 19. e5 Qe7 20. exf6 Qxf6 21. Nxb5 Bb8 22. Rxc8 Rxc8 23. Ba3 Nc2 24. Qd2 Ba6 25. Nbc3 Nxa3 26. Ra1 Nc4 27. Qf4 Qxf4 28. Nxf4 Bxf4 29. gxf4 Ra8 30. Nb5 Bb7 31. Rxa8+ Bxa8 32. Nc7 Bb7 33. Nb5 Kf8 34. Bf1 Ke7 35. Bxc4 dxc4 36. Nc3 Kd6 37. Kf2 f6 38. Ke3 e5 39. Nb5+ Ke6 0-1",
    B: "2083",
  },
  {
    W: "1698",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. Ng5 Nf6 5. Bxf7+ Ke7 6. Bb3 Re8 7. O-O Kf8 8. Nf7 Qe7 9. Ng5 h6 10. Nh3 Nxe4 11. d3 Nf6 12. Qf3 d6 13. Nc3 a6 14. Nd5 Qf7 15. Nxf6 Qxf6 16. Qxf6+ gxf6 17. Bxh6+ Ke7 18. Rae1 Bxh3 19. gxh3 Rg8+ 20. Kh1 Rg6 21. Be3 Rag8 22. Bxc5 dxc5 23. Rg1 Rxg1+ 24. Rxg1 Rxg1+ 25. Kxg1 Nd4 26. h4 Nxb3 27. axb3 b5 28. Kg2 a5 29. Kg3 Ke6 30. Kg4 c4 31. dxc4 bxc4 32. bxc4 c5 33. h5 e4 34. h6 Ke5 35. h7 Kd4 36. h8=Q Kxc4 37. Qd8 Kb4 38. Qxf6 a4 39. Qe6 a3 40. Qxe4+ Kb5 41. bxa3 Kb6 42. Qa4 Kc7 43. c3 Kd6 44. Qc4 Ke5 45. a4 Kf6 46. a5 Ke7 47. a6 Kd6 48. a7 Kc7 49. a8=Q Kd6 50. Qxc5+ Ke6 51. Qa6+ 1-0",
    B: "1612",
  },
  {
    W: "1482",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 d6 4. d3 Bg4 5. Ng5 h6 6. Nxf7 Qh4 7. g3 Qh5 8. Qd2 Nd4 9. Nxh8 Nf3+ 10. Kd1 Nxd2+ 11. Kxd2 Be2 12. Nc3 Qf3 13. Nxe2 Nf6 14. Re1 Be7 15. Bf7+ Kd7 16. Bd5 Nxd5 17. exd5 Rxh8 18. Ng1 Bg5+ 19. Re3 Bxe3+ 20. fxe3 Qf2+ 21. Ne2 Rf8 0-1",
    B: "1520",
  },
  {
    W: "1290",
    T: "60+0",
    P: "1. e4 e6 2. d4 Nc6 3. d5 Nce7 4. dxe6 fxe6 5. Qf3 g6 6. Nc3 Bg7 7. Qh3 h5 8. g4 hxg4 9. Qxg4 d5 10. exd5 exd5 11. Qa4+ c6 12. Nxd5 Nxd5 13. Bg2 Qe7+ 14. Ne2 Bg4 15. Be3 Nxe3 16. fxe3 Qxe3 17. Qe4+ Qxe4 18. Bxe4 Bxe2 19. Kxe2 Nf6 20. Bd3 O-O-O 21. Rad1 Ng4 22. Be4 Rxd1 23. Rxd1 Rd8 24. Rxd8+ Kxd8 25. Kf3 Nf6 26. Kg3 Nxe4+ 27. Kf3 Bxb2 0-1",
    B: "1245",
  },
  {
    W: "874",
    T: "60+0",
    P: "1. e4 e5 2. Qh5 Nc6 3. d3 Nf6 4. d4 Nxh5 5. dxe5 Nxe5 1-0",
    B: "1150",
  },
  {
    W: "1556",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 e6 3. e3 Nf6 4. c4 Nc6 5. a3 dxc4 6. Bd2 Bd6 7. Bxc4 Bd7 8. Nc3 O-O 9. b4 Re8 10. Ba2 e5 11. d5 Ne7 12. e4 c6 13. Bb1 cxd5 14. Nxd5 Bc6 15. Qc2 Nfxd5 16. exd5 Bd7 17. Qxh7+ Kf8 18. Qh8+ Ng8 19. Bh7 Ke7 20. Bxg8 Rxg8 21. Qh4+ Kf8 22. Qxd8+ Rxd8 23. O-O Be7 24. Nxe5 f6 25. Nxd7+ Rxd7 26. Bc3 Kf7 27. Rad1 Rgd8 28. Rfe1 Bd6 29. g3 Re7 30. Rxe7+ Kxe7 31. f4 Kf7 32. Kg2 Re8 33. Kf3 b6 34. Ba1 a5 35. bxa5 bxa5 36. a4 Bb4 37. h4 Rc8 38. d6 Rc3+ 39. Ke4 Ra3 40. d7 Ke7 41. d8=Q+ Kf7 42. Qd7+ Kg6 43. Qf5+ Kf7 44. Qd5+ Kg6 45. h5+ Kh7 46. Qf5+ Kg8 47. Rd8+ Kf7 48. Qg6+ 1-0",
    B: "1553",
  },
  {
    W: "1486",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Be3 Nxd4 6. Bxd4 Bxd4 7. Qxd4 Qf6 8. e5 Qe6 9. Bc4 Qg6 10. Bd3 Qxg2 11. Rf1 Qxh2 12. Nc3 Ne7 13. Nb5 O-O 14. Nxc7 Nc6 15. Qd6 Qxe5+ 16. Qxe5 Nxe5 17. Nxa8 Nxd3+ 18. cxd3 Re8+ 19. Kd2 h5 20. Rae1 Rxe1 21. Rxe1 Kf8 22. Nc7 g5 23. Re8+ Kg7 24. Rxc8 h4 25. Ne8+ Kg6 26. Rc1 f5 27. Ke3 g4 28. Rh1 Kg5 29. Nd6 f4+ 30. Ke2 g3 31. fxg3 fxg3 32. Ne4+ Kg4 33. Nxg3 hxg3 34. Rg1 Kh3 35. Kf3 1-0",
    B: "1450",
  },
  {
    W: "1456",
    T: "300+0",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qd1 Bc5 5. Nc3 Nf6 6. Nf3 O-O 7. Bd3 d6 8. O-O Bg4 9. h3 Bh5 10. a3 Nd4 11. Nxd4 Bxd1 12. Rxd1 Bxd4 13. Nb5 c6 14. Nxd4 c5 15. Nf3 Qa5 16. Bd2 Qb6 17. c3 Qxb2 18. c4 a6 19. Bf1 Nxe4 20. Rdb1 Qc2 21. Rc1 Qa4 22. Bf4 b5 23. cxb5 axb5 24. Bd3 Rfd8 25. Bxe4 Qxe4 26. Be3 d5 27. Rxc5 d4 28. Bd2 Qe2 29. Rxb5 Qxb5 30. Bb4 d3 31. Rc1 d2 32. Nxd2 Qe2 33. Nf3 Rd1+ 34. Rxd1 Qxd1+ 35. Kh2 Qe2 36. Nd4 Qxf2 37. Nc6 h6 38. Ne7+ Kh7 39. Nd5 Re8 40. Ne7 Qe2 41. Nd5 Qf2 42. Ne7 Qf4+ 43. Kh1 Rd8 44. g3 Qxg3 45. Nf5 Rd1+ 46. Be1 Rxe1# 0-1",
    B: "1364",
  },
  {
    W: "1534",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 exd4 5. Nxd4 Nxd4 6. Qxd4 d6 7. Bg5 Be7 8. Bc4 c5 9. Qd3 O-O 10. Bd5 Nxd5 11. Nxd5 Be6 12. Bxe7 Qa5+ 13. c3 Bxd5 14. Qxd5 Rfe8 15. Bxd6 Rad8 16. O-O-O Re6 17. Qxc5 Qxc5 18. Bxc5 Rxd1+ 19. Rxd1 h6 20. f3 b6 21. Bd4 Rg6 22. g4 Rd6 23. h4 f6 24. e5 fxe5 25. Bxe5 Re6 26. f4 g5 27. hxg5 hxg5 28. Rd8+ Kf7 29. Rd7+ Kg6 30. f5+ 1-0",
    B: "1495",
  },
  {
    W: "969",
    T: "300+0",
    P: "1. g3 e5 2. e4 Bc5 3. Nc3 Qf6 4. f3 Nh6 5. Bc4 d5 6. Bxd5 Bg4 7. Bxb7 Bxf3 8. Nxf3 Nc6 9. Bxc6+ Qxc6 10. Nxe5 Qf6 11. Rf1 Qxe5 12. d3 O-O-O 13. Nb5 Bb4+ 14. c3 Qxb5 15. Bf4 Bxc3+ 16. bxc3 Rxd3 17. Qc2 Ng4 18. Rb1 Qc4 19. Qb3 Qxe4+ 20. Be3 Qxe3# 0-1",
    B: "1077",
  },
  {
    W: "2585",
    T: "60+0",
    P: "1. d3 d5 2. Nd2 Nf6 3. Ngf3 g6 4. g3 Bg7 5. Bg2 O-O 6. O-O Bg4 7. h3 Bxf3 8. Nxf3 c6 9. b3 Nbd7 10. Bb2 Re8 11. c3 Qc7 12. c4 e6 13. cxd5 cxd5 14. Rc1 Qd6 15. Rc2 Rac8 16. Qd2 Nh5 17. Bxg7 Nxg7 18. Rfc1 Rxc2 19. Qxc2 Nf5 20. Qc7 Rb8 21. Qxd7 Qxd7 22. g4 Nd6 23. Ne5 Qe7 24. d4 Rc8 25. Rd1 Ne4 26. f3 Nc3 27. Rd2 Nb5 28. e3 Qb4 29. Re2 Rc1+ 30. Kf2 Qc3 31. f4 Rc2 32. Bf1 Rxe2+ 33. Bxe2 Qd2 34. Nf3 Qxa2 35. Kf1 Nc3 36. Bd3 Ne4 37. Bxe4 dxe4 38. Ng5 Qb1+ 39. Kf2 Qb2+ 40. Kg3 Qxb3 41. Kf2 a5 42. Nxe4 a4 43. Nf6+ Kh8 44. Ne4 a3 45. Nc5 Qb2+ 46. Kf3 a2 47. Nd3 a1=Q 48. Nxb2 Qxb2 49. Ke4 Qb6 50. g5 Qc6+ 51. Kd3 Qd5 52. e4 Qb3+ 53. Kd2 Qxh3 54. Ke2 Qg3 55. f5 Qf4 56. fxg6 fxg6 57. d5 Qxe4+ 58. Kf2 Qxd5 59. Ke3 Qxg5+ 60. Kf2 Qf5+ 61. Ke2 b6 62. Kd2 1/2-1/2",
    B: "2501",
  },
  {
    W: "1205",
    T: "180+0",
    P: "1. Nf3 Nc6 2. b3 e6 3. Bb2 Qe7 4. d4 d6 5. c4 Bd7 6. e4 O-O-O 7. Bd3 d5 8. O-O Nf6 9. e5 Ne4 10. Bxe4 dxe4 11. Nfd2 Be8 12. f3 Nxd4 13. Bxd4 Rxd4 14. fxe4 Qg5 15. Qf3 Qxe5 16. Qf4 Bb4 17. Qxe5 Rxd2 18. Nxd2 Bxd2 19. Rfd1 Bb4 20. Qd4 Bc6 21. a3 Ba5 22. b4 Bb6 23. c5 e5 24. Qxe5 Re8 25. Qxg7 Bxc5+ 26. bxc5 Bxe4 27. Qxf7 Bc6 28. Re1 Kd8 29. Rxe8+ Bxe8 30. Rd1+ Bd7 31. Qf8# 1-0",
    B: "1263",
  },
  {
    W: "1637",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. c4 Bg7 6. Be3 e6 7. Nc3 Nf6 8. Be2 Nxd4 9. Bxd4 b6 10. f3 Bb7 11. O-O d5 12. e5 Nh5 13. f4 dxc4 14. Bxc4 O-O 15. g4 Qc7 16. Bb3 Nxf4 17. Rxf4 Qc6 18. Bd5 exd5 19. Qf3 Rae8 20. Rf1 Re7 21. g5 Bxe5 22. Bxe5 Rxe5 23. h4 d4 24. Qxc6 Bxc6 25. Nd1 Re4 26. Rxe4 Bxe4 27. Nf2 Bf5 28. Rd1 Rd8 29. Nd3 Bxd3 30. Rxd3 Kf8 31. Kf2 Ke7 32. Kf3 Ke6 33. Ke4 a6 34. Rxd4 Rxd4+ 35. Kxd4 Kd6 36. Ke4 b5 37. b3 a5 38. a3 b4 39. axb4 axb4 40. Ke3 Kd5 41. Kf4 Ke6 42. Ke4 Kd6 43. Kf4 Ke6 44. Ke4 Kd6 1/2-1/2",
    B: "1487",
  },
  {
    W: "1918",
    T: "300+0",
    P: "1. d4 f5 2. Bg5 Nf6 3. e3 Ne4 4. Bh4 g5 5. Qh5# 1-0",
    B: "1904",
  },
  {
    W: "1845",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O d6 5. c3 Nf6 6. h3 Nxe4 7. d4 exd4 8. cxd4 Bb6 9. Re1 d5 10. Bd3 O-O 11. Bxe4 dxe4 12. Rxe4 Nxd4 13. Nxd4 c5 14. Qf3 cxd4 15. Nd2 Be6 16. b3 Bd5 17. Qg3 Bxe4 18. Nxe4 Qe7 19. Bh6 g6 20. Bxf8 Qxe4 21. Bh6 Re8 22. Rc1 d3 23. Bd2 Qe2 24. Bc3 d2 25. Bxd2 Qxd2 26. Rf1 Re1 27. Qb8+ Kg7 28. Qg3 Rxf1+ 29. Kxf1 Qd1# 0-1",
    B: "1870",
  },
  {
    W: "1659",
    T: "300+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 Bd6 5. Nc3 c6 6. Be3 Bf5 7. Bd3 Bxd3 8. Qxd3 Nf6 9. O-O-O Nbd7 10. Rhe1 O-O 11. Bg5 b5 12. Ne5 Qc7 13. f4 a5 14. h4 a4 15. a3 b4 16. Nb1 bxa3 17. Nxa3 Ne4 18. Nxd7 Qxd7 19. Qf3 h6 20. Rh1 f6 21. Bxh6 gxh6 22. g4 Qe7 23. g5 Bxa3 24. bxa3 fxg5 25. hxg5 Qf7 26. g6 Qxf4+ 27. Qxf4 Rxf4 28. Rxh6 Kg7 29. Rdh1 Nf6 30. Kd2 Rxd4+ 31. Ke3 Rc4 32. Kd3 Re8 33. R6h3 Ree4 34. Re3 Red4+ 35. Ke2 Rxc2+ 36. Kf3 Ne4 37. Rh4 Kxg6 38. Rg4+ Ng5+ 39. Kg3 Rxg4+ 40. Kxg4 Rg2+ 41. Kf4 Ne4 42. Ke5 Rc2 0-1",
    B: "1976",
  },
  {
    W: "1557",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Nc3 exd4 5. Nxd4 Be7 6. Bc4 O-O 7. O-O c6 8. a3 d5 9. exd5 cxd5 10. Ba2 Nc6 11. Nf3 Ng4 12. Qxd5 Qb6 13. b4 Bf6 14. Bd2 Be6 15. Qh5 Rad8 16. Nd5 Bxd5 17. Bxd5 Bxa1 18. Rxa1 Qxf2+ 19. Kh1 Nce5 20. Nxe5 Nxe5 21. Qxe5 Qxd2 22. Bf3 Rfe8 23. Qxe8+ Rxe8 24. Rg1 Qxc2 25. Bxb7 Qb3 26. Bc6 Rd8 27. Re1 Qxa3 28. b5 g6 29. h3 Qg3 30. Rf1 Rd2 31. Rg1 h5 32. Rf1 g5 33. Rf5 Rd1+ 0-1",
    B: "1503",
  },
  {
    W: "1903",
    T: "300+0",
    P: "1. e4 a6 2. Nf3 b5 3. d4 Bb7 4. Nc3 b4 5. Na4 Bxe4 6. Bc4 e6 7. O-O Nf6 8. Bg5 h6 9. Bh4 g5 10. Bg3 Be7 11. Nc5 Bxc5 12. dxc5 Nc6 13. Ne5 Nxe5 14. Bxe5 O-O 15. Qd4 Bd5 16. Bxf6 Qe8 17. Bxd5 exd5 18. Qxd5 Qe6 19. Qxe6 fxe6 20. Bd4 Rf5 21. b3 e5 22. Be3 d5 23. cxd6 cxd6 24. Rad1 Rd8 25. Rfe1 Rd7 26. Bb6 d5 27. Ba5 Rdf7 28. Rf1 e4 29. Bxb4 Rc7 30. c3 a5 31. Bxa5 Ra7 32. b4 Rd7 33. a4 h5 34. Rd4 g4 35. Bb6 Rdf7 36. a5 e3 37. Rxg4+ hxg4 38. Bxe3 Kf8 39. g3 Ke8 40. Bd4 Kd7 41. b5 Kc7 42. b6+ Kb7 43. Ra1 Rf8 44. a6+ Kb8 45. b7 Kc7 46. Rb1 Rf3 47. Be5+ Kc6 48. b8=Q Rxb8 49. Rxb8 Rd3 50. Bd4 Rd1+ 51. Kg2 Ra1 52. a7 Ra6 53. a8=Q+ Rxa8 54. Rxa8 Kb5 55. h4 Kc4 56. h5 Kd3 57. h6 Ke4 58. h7 Kf5 59. h8=Q Ke4 60. Qh7# 1-0",
    B: "1887",
  },
  {
    W: "1690",
    T: "300+0",
    P: "1. d4 d5 2. Nf3 Nf6 3. Bf4 Nbd7 4. c3 Nb6 5. Nbd2 c6 6. e3 e6 7. Bd3 Be7 8. Qc2 h6 9. Ne5 g5 10. Bg3 h5 11. h4 g4 12. O-O-O Nbd7 13. f3 Nxe5 14. dxe5 Nd7 15. fxg4 hxg4 16. Rdf1 Qa5 17. Kb1 Nc5 18. Be2 Bd7 19. Bxg4 Na4 20. Rf4 b5 0-1",
    B: "1626",
  },
  {
    W: "1552",
    T: "120+1",
    P: "1. e4 e6 2. d4 c6 3. f4 d5 4. e5 c5 5. c3 c4 6. Nf3 Nc6 7. Be2 a5 8. O-O Nh6 9. Be3 Nf5 10. Qd2 b5 11. g4 Nh4 12. g5 Nf5 13. b3 Ba6 14. bxc4 bxc4 15. Na3 Bxa3 16. Bf2 O-O 17. Nh4 Nce7 18. Bg4 Nxh4 19. Bxh4 Nf5 20. Bxf5 exf5 21. Qe1 Be7 22. Qg3 Bc8 23. Rf2 Be6 24. Rg2 Rb8 25. g6 Bxh4 26. gxh7+ Kxh7 27. Qxg7# 1-0",
    B: "1576",
  },
  {
    W: "1255",
    T: "180+2",
    P: "1. d4 e5 2. d5 d6 3. e4 Nf6 4. Bg5 Be7 5. Bxf6 Bxf6 6. c4 c6 7. Nc3 O-O 8. Nf3 Bg4 9. Be2 Na6 10. Qb3 b6 11. dxc6 Qc7 12. Qa4 Nc5 13. Nd5 Nxa4 14. Nxc7 Rac8 15. Nb5 Rxc6 16. Nxa7 Rc7 17. Nb5 Rd7 18. b3 Nc5 19. O-O Nxe4 20. h3 Be6 21. a4 Ra8 22. Nc7 Ra7 23. Nb5 Ra8 24. Rfd1 Be7 25. g4 h6 26. Bd3 Nc5 27. b4 Nxd3 28. Rxd3 Bxc4 29. Re3 Bxb5 30. a5 bxa5 31. bxa5 Ba6 32. Rb3 Rc7 33. Rb6 Bd8 34. Rxa6 Rxa6 35. Rb1 Rxa5 36. Rb8 Rd7 37. Nd2 Ra1+ 38. Kg2 Ra2 39. Nc4 Rc2 40. Nb6 Rdc7 41. Rxd8+ Kh7 42. Nd5 R7c6 43. Rd7 R2c5 44. Ne7 Ra6 45. Nf5 Kg6 46. Nxd6 Rac6 47. Ne4 Re6 48. h4 Rc4 49. f3 Rc2+ 50. Kg3 f6 51. h5+ Kh7 52. f4 Re2 53. f5 Re3+ 54. Kf2 Rc6 55. Kxe3 Rc3+ 56. Kd2 Ra3 57. Nxf6+ 1-0",
    B: "1270",
  },
  {
    W: "1962",
    T: "180+2",
    P: "1. e4 d6 2. Bc4 Nf6 3. Nc3 c6 4. d3 d5 5. exd5 cxd5 6. Bb3 Bg4 7. f3 Bf5 8. Bg5 e6 9. Qe2 Be7 10. O-O-O Nc6 11. Bxf6 gxf6 12. g4 Bg6 13. Nh3 Qd6 14. Nb5 Qb8 15. Rhe1 a6 16. Nc3 h5 17. Kb1 hxg4 18. Nf4 Qxf4 19. Nxd5 exd5 20. Bxd5 gxf3 21. Bxc6+ Kf8 22. Qxe7+ Kg7 23. Bxb7 Rae8 24. Qd7 Rxe1 25. Rxe1 Rxh2 0-1",
    B: "2030",
  },
  {
    W: "1583",
    T: "180+2",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Bg5 Nbd7 7. Be2 h6 8. Bh4 Re8 9. e5 dxe5 10. dxe5 Ng4 11. Bg3 Ngxe5 12. Bxe5 Nxe5 13. Qxd8 Nxf3+ 14. Bxf3 Rxd8 15. Nb5 Bxb2 16. Rb1 Bg7 17. O-O c6 18. Nc7 Rb8 19. h3 Bf5 20. Rbd1 Rxd1 21. Rxd1 Rc8 22. g4 Bd3 23. Ne6 Bxc4 24. Nc5 Bd5 25. Bxd5 cxd5 26. Nxb7 Rc7 27. Na5 Rc5 28. Nb3 Rb5 29. Rc1 e6 30. Rc7 a5 31. Rc8+ Kh7 32. Ra8 Bc3 33. Kg2 Kg7 34. Ra7 Kf6 35. Kf3 e5 36. h4 e4+ 37. Ke3 g5 38. Ra6+ Kg7 39. h5 a4 40. Rxa4 Kf6 41. Ra6+ Ke5 42. Ra7 d4+ 43. Ke2 d3+ 44. Kd1 f5 45. gxf5 Kxf5 46. Ra6 Bg7 47. Nd4+ 1-0",
    B: "1600",
  },
  {
    W: "1500",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. Nc3 Bb4 5. Nd5 O-O 6. Nxb4 Nxb4 7. c3 Nc6 8. Bxc6 dxc6 9. d4 Bg4 10. Bg5 h6 11. Bxf6 Qxf6 12. h3 Bh5 13. O-O exd4 14. g4 Bg6 15. Nxd4 Rfe8 16. f3 c5 17. Nb5 c6 18. Nc7 Rad8 19. Qa4 Re7 20. Na8 Rxa8 0-1",
    B: "1446",
  },
  {
    W: "1748",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 b6 5. Nc3 Bb7 6. f3 a6 7. Be3 Nc6 8. Qd2 Nf6 9. O-O-O Bb4 10. Kb1 Na5 11. a3 Bxc3 12. Qxc3 Rc8 13. Qd2 Nc4 14. Bxc4 Rxc4 15. g4 Qc7 16. h4 d5 17. g5 Nd7 18. Bf4 e5 19. Bxe5 Nxe5 20. Nf5 O-O 21. Qf2 Rc8 22. Rc1 dxe4 23. f4 e3 24. Qxe3 Bxh1 25. Qxe5 Qxe5 26. fxe5 Be4 27. Ne7+ Kf8 28. Nxc8 Rxc8 29. Re1 Bxc2+ 0-1",
    B: "1752",
  },
  {
    W: "1370",
    T: "180+2",
    P: "1. e4 e5 2. Bc4 Nf6 3. d3 Nc6 4. h3 d5 5. exd5 Nxd5 6. Nf3 Nf4 7. Bxf4 exf4 8. O-O Be7 9. Nc3 O-O 10. Nd5 Bc5 11. Nxf4 Bxh3 12. Nxh3 Re8 13. Re1 g5 14. Rxe8+ Qxe8 15. Nhxg5 Ne5 16. Nxe5 Qxe5 17. Bxf7+ Kh8 18. Qh5 Qg7 19. Re1 Rf8 20. Re8 Rxe8 21. Bxe8 h6 22. Nf7+ Kg8 23. Qxc5 1-0",
    B: "1385",
  },
  {
    W: "1665",
    T: "180+2",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Qb6 6. Be2 Nge7 7. O-O cxd4 8. cxd4 Nf5 9. Be3 Nxe3 10. fxe3 Be7 11. b3 Bd7 12. Nc3 a6 13. Qd3 O-O-O 14. Na4 Qa7 15. a3 Kb8 16. b4 f6 17. Nc5 Bxc5 18. bxc5 fxe5 19. Rab1 exd4 20. exd4 Bc8 21. Rfc1 Ka8 22. Ng5 Rdf8 23. Bg4 Rf6 24. Nf3 h5 25. Bh3 Rg8 26. Ne5 Nxe5 27. dxe5 Rf4 28. Kh1 g5 29. g3 Rf2 30. Qh7 Rgf8 31. c6 bxc6 32. Qxa7+ Kxa7 33. Rxc6 g4 34. Bg2 Ra2 35. Rc7+ Ka8 36. Rbc1 Kb8 37. Re7 Rb2 38. h3 gxh3 39. Bxh3 Rb7 40. Rxb7+ Kxb7 41. Rb1+ Kc7 42. Kg2 Kd8 43. Rb8 Ke7 44. Ra8 d4 45. Ra7+ Bd7 46. g4 1-0",
    B: "1729",
  },
  {
    W: "1933",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 d6 3. c3 Nf6 4. Qc2 g6 5. d4 cxd4 6. cxd4 Qa5+ 7. Nc3 Nc6 8. Be3 Bg7 9. Bc4 O-O 10. O-O h5 11. a3 Ng4 12. Bd2 Qc7 13. Nd5 Qd8 14. b4 Nxd4 15. Nxd4 Bxd4 16. Rae1 e6 17. Nf4 e5 18. Ne2 Qh4 19. h3 Bxf2+ 20. Rxf2 Nxf2 21. Rf1 Qxe4 22. Bxf7+ Rxf7 23. Qxe4 Nxe4 0-1",
    B: "1801",
  },
  {
    W: "2081",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 e6 3. c4 Nc6 4. Nc3 Nf6 5. g3 d5 6. e5 Ne4 7. cxd5 exd5 8. d3 Nxc3 9. bxc3 Be7 10. Bg2 O-O 11. O-O b5 12. d4 b4 13. Bb2 c4 14. Re1 a5 15. Qa4 Bd7 16. Qc2 a4 17. cxb4 Nxb4 18. Qd1 Nd3 19. Re2 a3 20. Bc3 Rb8 21. Rb1 Qc7 22. Ne1 Rxb1 23. Qxb1 Rb8 24. Qd1 Nxe1 25. Rxe1 Qb6 26. Bxd5 Bb4 27. Qf3 Bxc3 28. Bxf7+ Kh8 29. Qxc3 Qb4 30. Rc1 Qxc3 31. Rxc3 Bh3 32. Rc1 c3 33. f4 Rb2 34. Rxc3 Rg2+ 35. Kh1 Rd2 36. e6 Rd1# 0-1",
    B: "2140",
  },
  {
    W: "1360",
    T: "180+2",
    P: "1. e4 e6 2. f3 d5 3. exd5 exd5 4. Nc3 Nf6 5. Bb5+ c6 6. Bd3 Bd6 7. Nge2 Nbd7 8. O-O Nb6 9. Re1 O-O 10. Nd4 Bd7 11. a4 Qc7 12. a5 Nc8 13. a6 b6 14. Nce2 Bc5 15. c3 Nd6 16. b4 Bxd4+ 17. Nxd4 c5 18. bxc5 bxc5 19. Ne2 c4 20. Bc2 Bf5 21. Bxf5 Nxf5 22. Nd4 Nxd4 23. cxd4 Qb6 24. Kh1 Qxd4 25. Rb1 Rab8 26. Rxb8 Rxb8 27. Qc2 Qf2 28. Rg1 Re8 29. Qd1 Re6 30. Bb2 Rxa6 31. Bxf6 Rxf6 32. Rf1 Qd4 33. Qa4 h6 34. Qe8+ Kh7 35. h3 Qxd2 36. Qe7 c3 37. Qa3 c2 38. Kh2 Rb6 39. Kg3 Rb1 40. Rf2 c1=Q 41. Rxd2 Qxa3 42. Rxd5 0-1",
    B: "1335",
  },
  {
    W: "1717",
    T: "60+0",
    P: "1. d4 c5 2. d5 e6 3. c4 b5 4. b3 d6 5. e3 bxc4 6. Bxc4 Ba6 7. Bxa6 Nxa6 8. Qd3 Nb4 9. Qc3 Nf6 10. Ne2 Nbxd5 11. Qd3 Qb6 12. e4 Nb4 13. Qd2 Be7 14. f3 O-O 15. Nbc3 Rad8 16. Na4 Qc6 17. Bb2 d5 18. O-O dxe4 19. Qe3 exf3 20. Qxf3 Nbd5 21. Rad1 Qb5 22. Nac3 Nxc3 23. Bxc3 Rd5 24. Rxd5 Nxd5 25. Qg3 Nxc3 26. Nxc3 Qb4 27. Ne4 Qxe4 28. Re1 Qd4+ 29. Qf2 Qxf2+ 30. Kxf2 Bf6 31. g3 Bd4+ 32. Ke2 Rd8 33. Rd1 e5 34. Kf3 e4+ 35. Kxe4 f6 36. g4 Kf7 37. h3 g6 38. h4 h6 39. b4 Ke6 40. bxc5 Ke7 41. Rxd4 Rxd4+ 42. Kxd4 Ke6 43. Kc4 f5 44. a4 f4 45. c6 f3 46. Kc5 Ke7 47. c7 Kd7 48. Kb5 Kxc7 49. Ka6 Kc6 50. Kxa7 f2 51. g5 f1=Q 52. gxh6 Qc4 53. h7 Qf7+ 54. Ka6 Qxh7 0-1",
    B: "1764",
  },
  {
    W: "2145",
    T: "60+0",
    P: "1. e3 c5 2. d4 cxd4 3. exd4 d5 4. Nf3 g6 5. c3 Bg7 6. Bd3 Nc6 7. O-O Bg4 8. h3 Bxf3 9. Qxf3 e6 10. Bf4 Nge7 11. Re1 O-O 12. Nd2 Qb6 13. Nb3 a5 14. Rab1 a4 15. Nc5 Qa5 16. Qe3 b6 17. Nd7 Rfd8 18. Ne5 Nxe5 19. Bxe5 b5 20. Bxg7 Kxg7 21. Qe5+ Kg8 22. Qf6 Nc6 23. Rxe6 Nxd4 24. Re7 Ne6 25. Qxf7+ Kh8 26. Qxh7# 1-0",
    B: "1948",
  },
  {
    W: "2027",
    T: "60+0",
    P: "1. b3 b6 2. Bb2 Bb7 3. e3 Nc6 4. Nf3 Qb8 5. d4 e6 6. c4 Nge7 7. Bd3 Ng6 8. a3 Be7 9. O-O Bf6 10. Re1 O-O 11. Nbd2 Nge7 12. Qe2 Na5 13. e4 d6 14. Rac1 c5 15. d5 Bxb2 16. Rc2 Bxa3 17. e5 Bb4 18. Qe4 exd5 19. Qxh7# 1-0",
    B: "1900",
  },
  {
    W: "892",
    T: "60+0",
    P: "1. e4 c6 2. d3 Na6 3. Nc3 e6 4. d4 d5 5. Bxa6 bxa6 6. exd5 Rb8 7. dxc6 e5 8. Qe2 f6 9. Bg5 h5 10. Bxf6 gxf6 11. Nd5 Qxd5 12. Rd1 Rxb2 13. c7 Qxa2 14. dxe5 Be7 15. exf6 Nxf6 16. Rd8+ Kf7 17. Re8 Kxe8 18. Qxh5+ Kd7 19. Qxh8 Kxc7 0-1",
    B: "869",
  },
  {
    W: "1411",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 f5 3. exf5 e4 4. Nd4 c5 5. Nb3 Nf6 6. d3 d5 7. dxe4 Nxe4 8. f3 Qh4+ 9. g3 Nxg3 10. Qxd5 Ne4+ 11. Kd1 Qf2 12. Qxe4+ Be7 13. Bg5 Nc6 14. Nxc5 O-O 15. Nd3 Bxf5 16. Qxf5 Rxf5 17. Nxf2 Bxg5 18. Nc3 Nb4 19. Nce4 Rd8+ 20. Ke2 Re5 21. c3 Nc2 22. Rc1 Rxe4+ 23. fxe4 Ne3 0-1",
    B: "1423",
  },
  {
    W: "1472",
    T: "60+0",
    P: "1. d4 d5 2. c4 c6 3. e3 dxc4 4. Bxc4 b5 5. Bd3 e6 6. Nf3 Ne7 7. O-O g6 8. a3 Bg7 9. b4 a6 10. Bb2 O-O 11. Nc3 Nd7 12. Re1 f5 13. e4 Bb7 14. exf5 exf5 15. Ne5 Nxe5 16. dxe5 h5 17. Qc2 Kh7 18. Rad1 Nd5 19. Nxd5 Qxd5 20. Bxf5 Qf7 21. Bd3 Bc8 22. e6 Bxe6 23. Bxg7 Kxg7 24. Qxc6 Bf5 25. Bxf5 Qxf5 26. Rd7+ Kh6 27. Re6 Rac8 28. Rxg6+ Qxg6 29. Rd6 Qxd6 30. Qxd6+ Kh7 31. Qg6+ Kxg6 32. h3 Rc1+ 33. Kh2 Ra1 34. g3 Rxa3 35. Kg2 Rb3 36. h4 Rxb4 0-1",
    B: "1476",
  },
  {
    W: "1798",
    T: "60+0",
    P: "1. Nf3 c5 2. g3 Nc6 3. Bg2 g6 4. O-O Bg7 5. d3 d6 6. Nbd2 e5 7. Re1 Nge7 8. e4 Be6 9. c3 Qd7 10. Nf1 O-O 11. Be3 f5 12. exf5 Nxf5 13. Bd2 b5 14. Ne3 b4 15. Nxf5 Bxf5 16. c4 Bxd3 17. Bh6 Bxc4 18. Bxg7 Kxg7 19. Qc2 Be6 20. Rad1 Nd4 21. Qd3 Bh3 22. Nxd4 exd4 23. Bxa8 Rxa8 24. Qe4 Rf8 25. Qe7+ Qxe7 26. Rxe7+ Rf7 27. Rxf7+ Kxf7 28. Rd3 Ke6 29. f4 Kd5 30. Kf2 c4 31. Rd2 d3 32. Ke3 Bg4 33. a3 a5 34. axb4 axb4 35. Rg2 Be2 36. Rf2 c3 37. bxc3 bxc3 38. h4 c2 39. Kd2 Kc4 40. g4 d5 41. h5 Kc5 42. hxg6 hxg6 43. f5 gxf5 44. gxf5 Kd6 45. f6 Ke6 46. f7 c1=Q+ 47. Kxc1 Bh5 48. Kd2 Bxf7 49. Kxd3 Bg6+ 50. Ke3 Be4 51. Kd4 Kd6 52. Rb2 Kc6 53. Rb8 Bg2 54. Rh8 1-0",
    B: "1894",
  },
  {
    W: "2230",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Nf6 3. d4 c6 4. dxc6 Nxc6 5. c4 Qxd4 6. Nf3 Qxd1+ 7. Kxd1 e5 8. Nc3 Bg4 9. Be2 O-O-O+ 10. Ke1 Nb4 11. Bd1 Nd3+ 12. Kd2 Nxf2+ 13. Kc2 Nxh1 14. Be2 e4 15. Nd4 Rxd4 0-1",
    B: "2168",
  },
  {
    W: "1644",
    T: "60+0",
    P: "1. d4 d5 2. Bf4 e6 3. Nf3 Nf6 4. c3 Bd6 5. e3 Bxf4 6. exf4 Ne4 7. Bd3 f5 8. Nbd2 Nd7 9. Nxe4 dxe4 10. Bxe4 fxe4 11. Ne5 Nxe5 12. Qh5+ Nf7 13. O-O-O Qf6 14. g3 O-O 15. Qe2 b6 16. Qxe4 Rb8 17. Rhg1 Bb7 18. Qe3 Nd6 19. g4 Ne4 20. g5 Qf5 21. f3 Nd6 22. Qe5 Qxe5 23. fxe5 Nf5 24. f4 Ne3 25. Rde1 Nc4 26. Rgf1 c5 27. h4 cxd4 28. cxd4 Rbd8 29. Kb1 Rxd4 30. Rc1 Na5 31. b3 Be4+ 32. Kb2 Rd2+ 33. Ka3 Bg2 34. Rfe1 Rfd8 35. Rc7 R2d7 36. Rc3 Nc6 37. Rec1 Nd4 38. Rb1 Ne2 39. Rb2 Nxc3 40. Rxg2 Rd2 41. Rxd2 Rxd2 42. Kb4 Rxa2 43. Kxc3 Ra3 44. Kb2 Rxb3+ 45. Kxb3 a5 46. Ka4 Kf7 47. Kb5 Ke7 48. Kxb6 Kd7 49. Kb5 Kc7 50. Kxa5 1-0",
    B: "1595",
  },
  {
    W: "1278",
    T: "60+0",
    P: "1. e4 d6 2. Bc4 e6 3. Qh5 g6 4. Qf3 Nf6 5. e5 Nh5 6. d3 dxe5 7. Bg5 Be7 8. Bxe7 Qxe7 9. Qg3 Nd7 10. Qh3 Nf4 11. Qg3 Nh5 12. Qh4 Nhf6 13. Qh6 a6 14. Qg7 Qf8 15. Qxf8+ Kxf8 16. Bxe6 fxe6 17. d4 exd4 18. c3 dxc3 19. bxc3 Kg7 20. Nd2 Ne5 21. Ne4 Nxe4 22. f3 Nxc3 23. a3 0-1",
    B: "1173",
  },
  {
    W: "2035",
    T: "300+10",
    P: "1. d4 Nf6 2. Nf3 b6 3. c4 Bb7 4. Nc3 e6 5. Bg5 Bb4 6. e3 h6 7. Bxf6 Qxf6 8. Be2 O-O 9. O-O a6 10. a3 Bxc3 11. bxc3 d6 12. a4 Nc6 13. Qd3 Ne7 14. e4 Ng6 15. a5 Nf4 16. Qd2 e5 17. axb6 cxb6 18. g3 Nh3+ 19. Kg2 Bxe4 20. Qe3 Qf5 21. dxe5 dxe5 22. Rad1 Rad8 23. Qxb6 Rxd1 24. Rxd1 Ng5 25. Qe3 Rc8 26. h4 Qh3+ 27. Kg1 Nxf3+ 28. Bxf3 Bxf3 29. Qxf3 Qe6 30. Qd5 Rxc4 31. Qxe6 fxe6 32. Rd6 Kf7 33. Rxa6 Rxc3 34. Kg2 h5 35. Ra8 g6 36. Ra5 Kf6 37. Ra8 Rb3 38. Rf8+ Kg7 39. Re8 Kf7 40. Ra8 Rb2 41. Ra5 Kf6 42. Ra8 Rd2 43. Rf8+ Kg7 44. Rf3 e4 45. Re3 Rd4 46. g4 Kf6 47. Kg3 Rd3 48. gxh5 gxh5 49. Kf4 e5+ 50. Kxe4 Rd4+ 51. Kf3 Rxh4 52. Kg3 Rg4+ 53. Kh3 Kf5 54. f3 Ra4 55. Rc3 Ra1 56. Kg3 Rg1+ 57. Kf2 Rg6 58. Rc4 Rf6 59. Rh4 Kg5 60. Re4 Rf5 61. Kg2 Kf6 62. Kg3 Kg5 63. Ra4 Rf4 64. Ra8 h4+ 65. Kg2 Rf6 66. Re8 Rf5 67. Rg8+ Kf6 68. Rh8 Rf4 69. Rf8+ Kg6 70. Rg8+ Kf6 71. Rf8+ Kg5 72. Rg8+ Kh6 73. Rh8+ Kg6 74. Rg8+ Kf6 75. Rf8+ Kg6 76. Rg8+ 1/2-1/2",
    B: "2011",
  },
  {
    W: "1822",
    T: "300+8",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bc4 e6 4. Nc3 a6 5. a3 Be7 6. d4 cxd4 7. Nxd4 h6 8. O-O Nf6 9. Nxc6 bxc6 10. e5 Nd5 11. Bxd5 cxd5 12. Be3 O-O 13. Qg4 Kh7 14. f4 f5 15. exf6 Rxf6 16. Bd4 Rg6 17. Qh5 d6 18. Kh1 Qe8 19. g4 e5 20. f5 exd4 21. fxg6+ Qxg6 22. Qxg6+ Kxg6 23. Nxd5 Bb7 24. c4 dxc3 25. Rfd1 Bf6 26. bxc3 Bxc3 27. Rab1 Rb8 28. Rxb7 Rxb7 29. Nxc3 Rb6 30. Ne4 Rb3 31. Rxd6+ Kh7 32. Rxa6 Re3 33. Nf2 Rb3 34. Kg2 Rb2 35. a4 Ra2 36. a5 Kg8 37. h4 Kf7 38. Kf3 Ra3+ 39. Kf4 Ra4+ 40. Ne4 g5+ 41. hxg5 hxg5+ 42. Kf5 Rb4 43. Ra7+ Kf8 44. Nxg5 Kg8 45. Kg6 Rb8 46. Nh7 Rc8 47. Nf6+ Kf8 48. Rf7# 1-0",
    B: "2083",
  },
  {
    W: "1105",
    T: "60+0",
    P: "1. e4 Nf6 2. Nf3 Nxe4 3. Qe2 f5 4. d3 Ng5 5. Nxg5 Nc6 6. Bf4 e5 7. Bxe5 Nxe5 8. Qxe5+ Be7 9. Qxg7 Bf6 10. Qh6 Bxg5 11. Be2 Bxh6 12. O-O Rg8 13. Bh5+ Kf8 14. Re1 Qg5 15. Bf3 d6 16. Nc3 f4 17. Ne4 Qg6 18. Rad1 Bg4 19. Nxd6 Bxf3 20. Re8+ Rxe8 21. Nxe8 Qxg2# 0-1",
    B: "1139",
  },
  {
    W: "1453",
    T: "300+0",
    P: "1. d4 d5 2. e3 h6 3. Nc3 a6 4. Nf3 c6 5. a3 Bf5 6. Ne5 e6 7. Bd3 Bxd3 8. cxd3 Nd7 9. O-O Ngf6 10. e4 dxe4 11. dxe4 Qc7 12. f4 O-O-O 13. Qc2 Bd6 14. Be3 Ng4 15. Bf2 Nxf2 16. Rxf2 Qb6 17. Nxf7 Qxd4 18. Nxd8 Rxd8 19. Kf1 Qc4+ 20. Kg1 Bc5 21. Rc1 Bxf2+ 22. Qxf2 Rf8 23. Ne2 Qb3 24. Qd4 c5 25. Qxg7 Qe3+ 26. Kf1 Qxe4 27. Rd1 Rd8 28. Qxh6 Qc4 29. Rc1 Qb3 30. Qg7 Nb6 31. h4 Rd1+ 32. Rxd1 Qxd1+ 33. Kf2 Nd5 34. h5 c4 35. h6 Qd2 36. h7 Kb8 37. h8=Q+ Ka7 38. Qd4+ Qxd4+ 39. Qxd4+ Nb6 40. g4 a5 41. g5 Ka6 42. g6 Nd5 43. g7 Ne7 44. Qd6+ Nc6 45. g8=Q Kb6 46. Qgxe6 Kb5 47. f5 1-0",
    B: "1419",
  },
  {
    W: "1755",
    T: "900+15",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Bc4 Bg7 6. Be3 a6 7. Nc3 d6 8. Nxc6 bxc6 9. O-O Nf6 10. e5 Ng4 11. h3 Nxe5 12. Bb3 O-O 13. f4 Nd7 14. Bd4 e5 15. fxe5 Nxe5 16. Ne4 d5 17. Nc5 Qg5 18. Rf2 Bxh3 19. Kf1 Be6 20. Qe2 Ng4 21. Bxg7 Kxg7 22. Nxe6+ fxe6 23. Rxf8 Rxf8+ 24. Kg1 Rf2 25. Qxe6 Qh4 26. Qd6 Rxg2+ 27. Kxg2 Ne3+ 28. Kf3 Qe4+ 29. Kf2 Ng4+ 30. Kg3 Ne5 31. Qe7+ Kh6 32. Rh1+ 1-0",
    B: "1787",
  },
  {
    W: "991",
    T: "900+15",
    P: "1. e4 d6 2. d3 e5 3. Nf3 Nf6 4. Nc3 Nc6 5. Nd5 b6 6. b3 Na5 7. a4 Qd7 8. Bg5 h6 9. Bh4 c6 10. Nxf6+ gxf6 11. Qd2 c5 12. c4 Nxb3 13. Qb2 Nd4 14. Nxd4 exd4 15. Be2 Rg8 16. O-O 1-0",
    B: "999",
  },
  {
    W: "1598",
    T: "900+15",
    P: "1. e4 d6 2. Nf3 e5 3. Be2 a6 4. O-O Nc6 5. c3 Nf6 6. Qc2 Be6 7. d4 exd4 8. Nxd4 Be7 9. Nxe6 fxe6 10. f4 Qd7 11. Be3 O-O-O 12. Nd2 e5 13. f5 Rdg8 14. Bf3 h6 15. a4 g6 16. Nc4 Kb8 17. b4 gxf5 18. exf5 e4 19. Bxe4 Nxe4 20. Qxe4 Bf6 21. Rac1 h5 22. Qf3 Rg4 23. Qd5 Ne7 24. Qf7 Qc6 25. Qxf6 Qxg2# 0-1",
    B: "1558",
  },
  {
    W: "1964",
    T: "900+15",
    P: "1. e4 g6 2. d4 Nf6 3. Nc3 d6 4. f3 c6 5. Be3 Bg7 6. Bd3 Qc7 7. Qd2 b5 8. a3 Nbd7 9. Nge2 Bb7 10. Bh6 Bxh6 11. Qxh6 a6 12. f4 O-O-O 13. e5 Ng4 14. Qh3 h5 15. Ne4 dxe5 16. dxe5 Qb6 17. Qf3 c5 18. c4 b4 19. h3 Nh6 20. O-O f5 21. exf6 Nxf6 22. Qe3 Nf5 23. Qf3 Nxe4 24. Bxe4 Rd2 25. Bxb7+ Qxb7 26. Qxb7+ Kxb7 27. Nc1 Rxb2 28. axb4 cxb4 29. Nd3 Rb3 30. Nc5+ Kb6 31. Nxb3 1-0",
    B: "2101",
  },
  {
    W: "1198",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3 Nd4 5. Nxe5 d6 6. Nxf7 1-0",
    B: "1167",
  },
  {
    W: "1252",
    T: "900+15",
    P: "1. d3 f5 2. e3 Nf6 3. b3 e5 4. Bb2 e4 5. Bxf6 Qxf6 6. c3 exd3 7. Bxd3 Bb4 8. Ne2 O-O 9. cxb4 Qxa1 10. Qc2 Qe5 11. f4 Qxe3 12. Bxf5 g6 13. Qc4+ Kh8 14. Qc3+ Qxc3+ 15. Nexc3 Rxf5 16. Ne4 Rxf4 17. Ng5 Rxb4 18. Nc3 d6 19. Nd5 Rb5 20. Ne7 Rxg5 21. Kf1 Nc6 22. Nxc8 Rxc8 23. h4 Rf5+ 0-1",
    B: "1266",
  },
  {
    W: "1676",
    T: "1200+0",
    P: "1. e4 c5 2. Bc4 e6 3. Nc3 Nc6 4. Nf3 a6 5. d4 b5 6. Be2 b4 7. Nb1 Nf6 8. d5 Ne7 9. d6 Nc6 10. e5 Ng4 11. O-O Ngxe5 12. Nxe5 Nxe5 13. c3 a5 14. Bf4 Qf6 15. Bxe5 Qxe5 16. cxb4 cxb4 17. Nd2 Bxd6 18. Nf3 Qc5 19. Rc1 Qb6 20. Qc2 O-O 21. Ng5 g6 22. Bf3 Bb7 23. Qb3 Bf4 24. Nh3 Bxc1 25. Rxc1 Rac8 26. Rf1 a4 27. Qd1 a3 28. b3 Rc3 29. Bxb7 Qxb7 30. Ng5 Rfc8 31. Qg4 Qd5 32. Ne4 f5 33. Nf6+ Kf7 34. Qg5 Qe5 35. Nxd7 Qd4 36. Qh6 Kg8 37. h4 Qxd7 38. h5 Qg7 39. Qxg7+ Kxg7 40. g3 Rc1 41. hxg6 hxg6 42. Rxc1 Rxc1+ 43. Kg2 Ra1 44. Kf3 Rxa2 45. Ke3 Rb2 46. Kd4 a2 0-1",
    B: "1617",
  },
  {
    W: "2030",
    T: "1200+0",
    P: "1. d4 d5 2. e3 e6 3. Nf3 c6 4. Ne5 Nd7 5. Bd3 Nxe5 6. dxe5 Qc7 7. f4 c5 8. c3 Ne7 9. O-O Nc6 10. Nd2 Bd7 11. Nf3 h6 12. Bd2 Be7 13. Rc1 b5 14. Bxb5 Nxe5 15. Bxd7+ Nxd7 16. b4 O-O 17. b5 Nf6 18. Ne5 Ne4 19. a4 Bf6 20. Ng4 a6 21. Nxf6+ Nxf6 22. c4 Rfd8 23. Qc2 d4 24. exd4 Rxd4 25. Be3 Re4 26. Qf2 Ng4 27. Qf3 Rxe3 28. Qxa8+ Kh7 29. Qc6 Qe7 30. h3 h5 31. hxg4 hxg4 32. Rce1 Rxe1 33. Rxe1 Qh4 34. Qe4+ f5 35. Qxe6 g6 36. Qe7+ 1-0",
    B: "1969",
  },
  {
    W: "1228",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 cxd4 5. cxd4 Nc6 6. Nf3 Bb4+ 7. Nbd2 Bxd2+ 8. Bxd2 Qb6 9. Bc3 Nge7 10. Be2 O-O 11. O-O Qc7 12. Rc1 a5 13. b4 axb4 14. Bxb4 Rxa2 15. Bb5 Rd8 16. Bxc6 Nxc6 17. Bc5 Ra5 18. Bb4 Rb5 19. Qd2 Nxb4 20. Qb2 b6 21. Rxc7 Nd3 22. Qxb5 Rd7 23. Qxb6 Rxc7 24. Qxc7 h6 0-1",
    B: "1345",
  },
  {
    W: "1971",
    T: "120+0",
    P: "1. b4 d5 2. a3 Bf5 3. Bb2 e6 4. e3 Nf6 5. c3 Be7 6. c4 c6 7. Nc3 O-O 8. f4 h6 9. h3 Nbd7 10. Qf3 Ne4 11. h4 Bf6 12. g4 Bh7 13. g5 Bxc3 14. Bxc3 Nxc3 15. dxc3 Be4 16. Qg4 f5 17. gxf6 Qxf6 18. Nf3 Qxc3+ 19. Kf2 Qxa1 20. Rg1 Rf7 21. Nd4 Qb2+ 22. Be2 Nf6 23. Qxe6 Re8 24. Qd6 dxc4 25. Ne6 Bd3 26. Nd4 Ne4+ 27. Kf3 Bxe2+ 28. Nxe2 Nxd6 29. Nd4 Ne4 30. Rg2 Qxa3 31. f5 Qxb4 32. f6 c5 33. Nf5 c3 34. Nxh6+ Kf8 35. Nxf7 Kxf7 36. fxg7 Rg8 37. Rg4 Nd2+ 38. Kg3 Qb1 39. Kf4 Qf1+ 40. Ke5 Qf3 41. Rf4+ Qxf4+ 42. exf4 c2 43. f5 c1=Q 44. f6 Qc3+ 45. Kd6 Nf3 46. Kc7 Nxh4 47. Kxb7 Qe5 48. Ka6 Qxf6+ 49. Kb5 Qxg7 50. Ka5 Qf6 51. Kb5 Qd6 52. Ka4 Qd4+ 53. Kb3 1/2-1/2",
    B: "2051",
  },
  {
    W: "1298",
    T: "120+1",
    P: "1. d4 d5 2. c4 dxc4 3. Nf3 Nc6 4. e4 e6 5. Bxc4 Bb4+ 6. Bd2 a5 7. Bxb4 axb4 8. d5 Nce7 9. O-O Nf6 10. dxe6 Bxe6 11. Qxd8+ Rxd8 12. Bxe6 fxe6 13. Nbd2 c5 14. a3 b3 15. e5 Ng4 16. h3 Nh6 17. a4 Rxd2 18. Nxd2 O-O 19. a5 Rc8 20. Nxb3 Nd5 21. Rfc1 Nf5 22. Rxc5 Rxc5 23. Nxc5 Kf7 24. Nxb7 Ke7 25. a6 Nb6 26. a7 Kd7 27. a8=Q Nxa8 28. Rxa8 Kc7 29. Nc5 Kc6 30. Nxe6 Kd5 31. Nxg7 Kxe5 32. Nxf5 Kxf5 33. Re8 1-0",
    B: "1141",
  },
  {
    W: "1390",
    T: "120+1",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bc4 d6 4. O-O Nf6 5. d4 cxd4 6. Nxd4 e5 7. Nxc6 bxc6 8. Bg5 Be7 9. Nc3 h6 10. Bh4 O-O 11. Qf3 a5 12. a3 Ba6 13. Bxa6 Rxa6 14. Rad1 Ra8 15. Ne2 Nh7 16. Bxe7 Qxe7 17. Qc3 Rfc8 18. Qd3 Ng5 19. Qxd6 Qxd6 20. Rxd6 Nxe4 21. Rdd1 f6 22. f3 Ng5 23. Rd6 Nf7 24. Re6 Ng5 25. Rd6 Nf7 26. Rd7 c5 27. Rfd1 Kf8 28. Rb7 Ng5 29. Rdd7 Ne6 30. Re7 Nd4 31. Nxd4 cxd4 32. Rxg7 Rxc2 33. Rgf7+ Kg8 34. Rxf6 Rac8 35. h3 d3 36. Rd7 d2 37. Rfd6 Rc1+ 38. Kh2 d1=Q 39. Rxd1 Rxd1 40. Rxd1 Rc2 41. Re1 Rxb2 42. Rxe5 a4 43. Re4 Rb3 44. Rxa4 Kf7 45. Ra6 Kg7 46. a4 Rb2 47. a5 h5 48. Ra7+ Kg6 49. a6 Kg5 50. Ra8 Kf4 51. a7 Ke3 52. Re8+ Kf2 53. a8=Q 1-0",
    B: "1415",
  },
  {
    W: "1354",
    T: "120+1",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 Nc6 4. Bc4 d6 5. O-O Bg4 6. Nc3 Nf6 7. d3 Qd7 8. Bxf4 O-O-O 9. Qe1 Bxf3 10. Rxf3 Ng4 11. Bg3 Kb8 12. Rxf7 Be7 13. Qe2 Nge5 14. Bxe5 Nxe5 15. Rxg7 d5 16. exd5 Bc5+ 17. Kh1 Qxg7 18. Ne4 Nxc4 19. Nxc5 Ne5 20. Ne6 Qg6 21. Nxd8 Rxd8 22. Qxe5 Rg8 23. g3 Qg4 24. Re1 Qf3+ 25. Kg1 b6 26. Qe4 Qf7 27. Kg2 h5 28. Rf1 Qg7 29. Rf3 h4 30. Qf4 hxg3 31. Rxg3 Qh7 32. Rxg8+ Qxg8+ 33. Qg3 Qxd5+ 34. Qf3 Qg5+ 35. Kf2 Qh4+ 36. Qg3 Qd4+ 37. Qe3 Qxb2 38. Qe8+ Kb7 39. Qe4+ Kb8 40. Qe8+ Kb7 41. Qe4+ Kb8 42. Qe8+ 1/2-1/2",
    B: "1356",
  },
  {
    W: "2256",
    T: "120+1",
    P: "1. d4 d5 2. c4 dxc4 3. e3 Nf6 4. Bxc4 e6 5. a3 b6 6. Nf3 Bb7 7. O-O Nbd7 8. Nc3 a6 9. b4 Bd6 10. Bb2 O-O 11. Be2 h6 12. Qc2 Qe7 13. e4 e5 14. d5 a5 15. b5 Nc5 16. Na4 Bc8 17. Nxc5 Bxc5 18. Bxe5 Bg4 19. Bxf6 Qxf6 20. a4 Bxf3 21. Bxf3 Rae8 22. Rfe1 Bd4 23. Rac1 Be5 24. Qd3 Bd6 25. Rc6 g5 26. g3 Re7 27. Bg2 Rfe8 28. Rc2 Bc5 29. Rce2 Qd6 30. Qf3 Bd4 31. Qh5 Kg7 32. h4 f6 33. Bh3 Qxg3+ 34. Bg2 gxh4 35. Kh1 Bxf2 36. Rf1 Be3 37. Rf4 Bxf4 0-1",
    B: "2071",
  },
  {
    W: "1520",
    T: "120+1",
    P: "1. e4 e5 2. Bc4 f5 3. exf5 Nf6 4. Nf3 d5 5. Bb3 Nc6 6. c3 Bxf5 7. O-O Bg4 8. d3 e4 9. dxe4 dxe4 10. Qxd8+ Rxd8 11. Nfd2 Bc5 12. Re1 Ne5 13. Nxe4 Nd3 14. Nxf6+ 1-0",
    B: "1557",
  },
  {
    W: "1562",
    T: "120+1",
    P: "1. e4 d5 2. Bc4 dxc4 3. Qh5 Nf6 4. Nc3 Nxh5 0-1",
    B: "1603",
  },
  {
    W: "1893",
    T: "120+1",
    P: "1. Nf3 e6 2. g3 c6 3. Bg2 d5 4. O-O Nf6 5. d3 Be7 6. Re1 O-O 7. Nbd2 a6 8. e4 b5 9. e5 Nfd7 10. Qe2 c5 11. c3 Bb7 12. Nf1 Nc6 13. g4 Rc8 14. Bf4 b4 15. Bg3 a5 16. h3 Ba6 17. Rad1 c4 18. d4 Qb6 19. Rb1 b3 20. a3 Bb7 21. N3d2 Qd8 22. f4 Bh4 23. Nf3 Bxg3 24. Nxg3 Ne7 25. f5 exf5 26. gxf5 Re8 27. Qd2 Nc6 28. Qf4 Nf8 29. Qg4 g6 30. Rf1 Ne7 31. f6 Nc6 32. Qg5 Ne7 33. h4 Ne6 34. Qh6 Nf8 35. Qg7# 1-0",
    B: "1891",
  },
  {
    W: "1564",
    T: "720+8",
    P: "1. e4 e5 2. c3 Nf6 3. Qf3 Nc6 4. h3 d5 5. d3 d4 6. Bg5 Be7 7. Ne2 h6 8. Bh4 O-O 9. g4 g5 10. Bg3 b5 11. Bg2 b4 12. c4 b3 13. O-O Nb4 14. axb3 Nc2 15. Ra2 c5 16. Bxe5 Bd6 17. Bxf6 Be7 18. Bxe7 Qxe7 19. Nd2 Ne3 20. fxe3 dxe3 1-0",
    B: "1253",
  },
  {
    W: "1880",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Nc3 e6 4. Bb5 Nf6 5. Bxc6 bxc6 6. e5 Ng8 7. d3 d6 8. Bg5 Be7 9. Bxe7 Nxe7 10. exd6 Qxd6 11. O-O Ng6 12. Ne4 Qe7 13. Qd2 Ba6 14. Rfe1 c4 15. dxc4 Bxc4 16. b3 Bd5 17. c4 Bxe4 18. Rxe4 Rd8 19. Qe3 O-O 20. Ne5 Nxe5 21. Rxe5 Qd6 22. Qe1 Qe7 23. h3 Rd3 24. f4 Rfd8 25. f5 Qd6 26. fxe6 fxe6 27. Rxe6 Qd4+ 28. Kh2 Qf4+ 29. Kh1 Kf7 30. Re7+ Kg6 31. Qe6+ Qf6 32. Qxf6+ gxf6 33. Rxa7 Rd1+ 34. Rxd1 Rxd1+ 35. Kh2 Ra1 36. b4 Rb1 37. a3 Rc1 38. c5 Ra1 39. Ra6 h5 40. g4 hxg4 41. hxg4 Kg5 42. Kg3 Ra2 43. Kf3 Ra1 44. Rxc6 Rxa3+ 45. Ke4 Rb3 46. Rb6 Kxg4 47. Rxf6 Rxb4+ 48. Kd5 Rb5 49. Rb6 Ra5 50. Kd6 Ra8 51. c6 Kf5 52. c7 Rh8 53. Rb8 Rh6+ 54. Kc5 Rh5 55. c8=Q+ Kf4+ 56. Kd4 Kg3 57. Rb3+ Kh4 58. Qh3+ Kg5 59. Rb5+ Kf6 60. Qxh5 Ke6 61. Qh6+ Kd7 62. Rb7+ Kd8 63. Qh8# 1-0",
    B: "1850",
  },
  {
    W: "1190",
    T: "60+0",
    P: "1. e4 d5 2. f3 dxe4 3. fxe4 Be6 4. Nh3 Qd7 5. Nf2 Nc6 6. d3 Nf6 7. c3 b5 8. Na3 a6 9. Nc2 a5 10. b3 b4 11. c4 Ne5 12. Be3 Nxd3+ 13. Nxd3 Nxe4 14. Nf2 Qxd1+ 15. Nxd1 Rd8 16. Nd4 Bg4 17. Nf2 Nc3 18. Be2 Nxe2 19. Nxe2 Bxe2 20. Kxe2 e6 21. Ne4 g6 22. Bc5 Bxc5 23. Nxc5 1-0",
    B: "1102",
  },
  {
    W: "1572",
    T: "60+0",
    P: "1. b3 d5 2. Bb2 Nf6 3. e3 g6 4. Be2 Bg7 5. Nf3 O-O 6. d4 b6 7. c4 dxc4 8. bxc4 c5 9. O-O cxd4 10. exd4 e6 11. Nc3 Qd6 12. a4 a6 13. Ba3 Qc7 14. Bxf8 Kxf8 15. Re1 Bb7 16. Bd3 Nbd7 17. Rb1 Rd8 18. d5 exd5 19. cxd5 Re8 20. Rxe8+ Kxe8 21. Qe2+ Kf8 22. Re1 Kg8 23. Ne4 Nxd5 24. Bxa6 Ba8 25. Bb5 Nf4 26. Qe3 Nd5 27. Nc3 Nxe3 28. Rxe3 Qd6 29. Re8+ Bf8 30. Bxd7 Qxd7 31. Re1 Qd3 32. Rf1 Qxc3 33. g3 Bc6 34. Kg2 Bxf3+ 35. Kh3 Bd5 36. Kh4 Be6 37. Re1 Qc4+ 38. f4 Qd5 39. Rxe6 Qxe6 40. f5 Qxf5 0-1",
    B: "1613",
  },
  {
    W: "2279",
    T: "60+0",
    P: "1. e4 b6 2. d4 Bb7 3. Nc3 g6 4. Bd3 Bg7 5. Nge2 e6 6. f3 Ne7 7. O-O d6 8. Qe1 h6 9. Qf2 O-O 10. Be3 c5 11. dxc5 bxc5 12. Rad1 a6 13. Kh1 Qc7 14. Qh4 g5 15. Qf2 Nbc6 16. f4 gxf4 17. Nxf4 Ne5 18. Nh5 N7g6 19. Nxg7 Kxg7 20. Qf6+ Kh7 21. Bxh6 Kxh6 22. Be2 Qd8 23. Qf2 Qh4 24. g3 Qg5 25. Rxd6 Ng4 26. Bxg4 Qxg4 27. Rfd1 Bxe4+ 28. Nxe4 Qxe4+ 29. Kg1 Ne5 30. Qf6+ Kh7 31. c3 Qe3+ 32. Kg2 Qe2+ 33. Kh3 Qh5+ 34. Kg2 Qe2+ 35. Kh3 Qh5+ 36. Kg2 Ng4 37. Qh4 Qxh4 38. gxh4 Ne3+ 39. Kf3 Nxd1 40. Rxd1 Rad8 41. Rf1 Rd2 42. Ke3 Rxh2 43. Rf4 Rxb2 44. Rb4 cxb4 45. cxb4 Rxa2 46. Kd3 Rb2 47. Kc4 Rxb4+ 48. Kxb4 Kg6 49. Ka5 Rh8 50. Kxa6 Rxh4 51. Kb7 Rd4 52. Kc6 e5 53. Kc5 Kf5 54. Kb5 Ke4 55. Kc5 f5 56. Kc6 f4 57. Kc5 f3 58. Kc6 f2 59. Kc5 Rd2 60. Kc6 f1=Q 61. Kc5 Qc1+ 62. Kb4 Rb2+ 63. Ka3 Qa1# 0-1",
    B: "2282",
  },
  {
    W: "1982",
    T: "60+0",
    P: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. cxd5 cxd5 5. e3 g6 6. a3 Bg7 7. h3 O-O 8. Nf3 Bf5 9. Ne5 Nbd7 10. Bd3 Nxe5 11. Bxf5 gxf5 12. dxe5 Ne4 13. Nxe4 dxe4 14. Qh5 Bxe5 15. Qxf5 Qa5+ 16. Bd2 Qb5 17. Qxe4 Bxb2 18. Rb1 Rac8 19. Qb4 Qxb4 20. axb4 Rc2 21. Kd1 Rfc8 22. Re1 Be5 23. b5 a6 24. bxa6 Rd8 25. Re2 Rdxd2+ 26. Rxd2 Rxd2+ 27. Kxd2 bxa6 28. Rb8+ Kg7 29. Rb7 Bb8 30. Rxb8 f6 31. Rb7 Kg6 32. Rxe7 1-0",
    B: "1906",
  },
  {
    W: "1526",
    T: "60+0",
    P: "1. d4 Nf6 2. c4 g6 3. e3 Bg7 4. f4 d6 5. Nf3 O-O 6. Nc3 Nbd7 7. Bd2 e5 8. Bd3 e4 9. Bxe4 Nxe4 10. Nxe4 Re8 11. d5 Rxe4 12. Ng5 Re8 13. O-O Nf6 14. Qf3 Bg4 15. Qg3 h6 16. h3 hxg5 17. hxg4 Ne4 18. Qe1 Nxd2 19. Qxd2 gxf4 20. exf4 c6 21. Rad1 cxd5 22. Qxd5 Qb6+ 23. Kh2 Rad8 24. b3 Re6 25. f5 gxf5 26. gxf5 Rh6+ 27. Kg3 Be5+ 28. Kf3 Qb4 29. Qd3 Qc5 30. Ke2 Rh4 31. Rf3 Rd4 32. Qe3 Rxd1 33. Qxc5 dxc5 34. Re3 R8d2+ 35. Kf3 Bd4 36. Re4 Kg7 37. Kf4 Re2 38. g4 Rf2+ 39. Kg5 Rdf1 40. f6+ 1-0",
    B: "1500",
  },
  {
    W: "1416",
    T: "60+0",
    P: "1. d4 d5 2. c4 Nf6 3. Nf3 dxc4 4. Nc3 e6 5. e4 Be7 6. Bxc4 a6 7. a4 b6 8. Bf4 O-O 9. O-O Bb7 10. Qc2 Nbd7 11. Bd3 g6 12. Ne5 c5 13. dxc5 Nxc5 14. Rad1 Qc7 15. g3 Rac8 16. Nc4 Nxd3 17. Bxc7 Rxc7 18. Qxd3 Rfc8 19. Ne3 Bb4 20. Ne2 b5 21. axb5 axb5 22. Qxb5 Bf8 0-1",
    B: "1496",
  },
  {
    W: "1647",
    T: "60+0",
    P: "1. c4 d5 2. g3 dxc4 3. Qa4+ Bd7 4. Qxc4 Bc6 5. Nc3 Bxh1 6. Nf3 Bxf3 7. exf3 Nf6 8. Bg2 e6 9. d4 Be7 10. Bg5 Nc6 11. Qd3 h6 12. a3 O-O 13. Bxf6 Bxf6 14. Rd1 Qxd4 15. Qxd4 Nxd4 16. Ne4 Nc6 17. Rd7 Rac8 18. Nc5 Rfd8 19. Rxd8+ Nxd8 20. Nxb7 Nxb7 0-1",
    B: "1674",
  },
  {
    W: "2254",
    T: "60+0",
    P: "1. e4 e6 2. d3 d5 3. Nd2 c5 4. g3 Nc6 5. Bg2 Nf6 6. Ngf3 a6 7. e5 Nd7 8. Qe2 b5 9. O-O a5 10. a4 Qb6 11. axb5 Qxb5 12. c4 dxc4 13. dxc4 Qb6 14. Ne4 Ba6 15. Bf4 Be7 16. Rfd1 Rd8 17. Nd6+ Bxd6 18. Rxd6 Qc7 19. Ng5 Bb7 20. Qh5 g6 21. Qh6 Ndxe5 22. Rxd8+ Nxd8 23. Qg7 Rf8 24. Bxe5 Qb6 25. Bf6 Bxg2 26. Kxg2 Nc6 27. Nxh7 Qb7 28. Qxf8+ Kd7 29. Qxf7+ Kc8 30. Qxb7+ Kxb7 31. Nf8 Ka6 32. Nxg6 a4 33. Ne5 Ka5 34. Nxc6+ Kb6 35. Ne5 Ka5 36. Rxa4+ Kxa4 37. g4 Kb3 38. g5 Kxb2 39. g6 Kc2 40. g7 1-0",
    B: "2258",
  },
  {
    W: "2212",
    T: "60+0",
    P: "1. Nf3 c6 2. d4 d5 3. c4 Nf6 4. Nc3 e6 5. cxd5 cxd5 6. Bg5 Be7 7. e3 O-O 8. Bd3 Qb6 9. Qc2 Nc6 10. a3 g6 11. O-O Bd7 12. Rab1 Rfe8 13. Qe2 Na5 14. Bh4 Qc7 15. Ne5 Bc8 16. f4 Nh5 17. Bf2 Bxa3 18. Nb5 Qe7 19. Nxa3 Nc6 20. g4 Nxe5 21. dxe5 Ng7 22. Qf3 Bd7 23. Kh1 Bc6 24. Rbc1 d4 25. Rxc6 bxc6 26. Qxc6 dxe3 27. Bxe3 Qh4 28. Qg2 Qe7 29. f5 exf5 30. gxf5 Qxe5 31. Bf4 Qd4 32. Bb1 Nxf5 33. Bxf5 Rab8 34. Bb1 Rxb2 35. Nc2 Qc3 36. Bh6 Rb7 37. Qf2 Qh8 38. Ba2 Rf8 39. Bxf8 Kxf8 40. Bxf7 Qg7 41. Qc5+ 1-0",
    B: "2185",
  },
  {
    W: "2081",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. c3 Bb4 4. cxb4 Nxb4 5. Nxe5 Nf6 6. d3 O-O 7. Nf3 d5 8. e5 Ng4 9. d4 Nc6 10. Be2 f6 11. h3 Nh6 12. Bxh6 gxh6 13. Nc3 fxe5 14. Nxe5 Nxe5 15. dxe5 Qg5 16. Qxd5+ Kh8 17. O-O Bxh3 18. e6 Rad8 19. Qxg5 hxg5 20. gxh3 Rd2 21. Bg4 h5 22. e7 Re8 23. Bxh5 Rxe7 24. Rad1 Rxb2 25. Rd8+ Kg7 26. Rfd1 b5 27. R8d7 Rxd7 28. Rxd7+ Kf6 29. Rxc7 Ke5 30. Nd5 b4 31. Bf3 g4 32. hxg4 Rxa2 33. Rb7 b3 34. Rxb3 a5 35. Rb7 a4 36. Ra7 a3 37. Kg2 Ra1 38. Rxa3 Rxa3 39. g5 1-0",
    B: "2045",
  },
  {
    W: "1761",
    T: "180+0",
    P: "1. e4 e6 2. d4 d5 3. exd5 exd5 4. Nf3 c6 5. Nc3 f5 6. Bf4 Nf6 7. Be5 Bd6 8. Bxf6 Qxf6 9. Qd2 O-O 10. O-O-O Nd7 11. Ne2 Qd8 12. h4 Nf6 13. g3 Ne4 14. Qe1 a5 15. Bg2 b5 16. Nh2 Qc7 17. Bxe4 fxe4 18. Rf1 b4 19. f4 a4 20. Kb1 b3 21. a3 bxc2+ 22. Kxc2 Ba6 23. Rf2 Qb6 24. Nc3 Bd3+ 25. Kc1 Rfb8 26. Ng4 Qxd4 27. Ne5 Qc4 28. Nxc4 1-0",
    B: "1779",
  },
  {
    W: "1738",
    T: "180+0",
    P: "1. e4 d6 2. Nf3 Nf6 3. Nc3 g6 4. Be2 Bg7 5. d3 O-O 6. Rb1 c5 7. Bd2 a6 8. O-O b5 9. Nd5 Nxd5 10. exd5 Nd7 11. c4 b4 12. b3 Nf6 13. Qc1 Nd7 14. Bh6 Ne5 15. Bxg7 Nxf3+ 16. Bxf3 Kxg7 17. Qf4 Bf5 18. Be4 Bd7 19. Rfe1 Ra7 20. Re2 Qc8 21. Rbe1 Bg4 22. Bf3 Bxf3 23. Qxf3 a5 24. Rxe7 Rxe7 25. Rxe7 a4 26. Re1 axb3 27. axb3 Qa6 28. Qe3 Qa3 29. d4 cxd4 30. Qxd4+ Kg8 31. Qd3 Qa7 32. g3 Qb7 33. Qd2 Ra8 34. Rb1 Ra3 35. Qb2 Qe7 36. Ra1 Rxa1+ 37. Qxa1 Qe2 38. Qb1 Qd2 39. Kg2 Kf8 40. h3 Qc3 41. Qd1 Ke8 42. Qe2+ Kd7 43. Qd1 Kc7 44. h4 Kb6 45. Kf1 Ka5 46. Ke2 Kb6 47. Qd3 Kc5 48. Qxc3 bxc3 49. Kd3 Kb4 50. Kc2 f6 51. f3 h6 52. g4 h5 53. gxh5 gxh5 54. f4 f5 55. Kd3 Kxb3 56. c5 dxc5 57. d6 Kb2 58. d7 c2 59. d8=Q c1=Q 60. Qb6+ Ka2 61. Qa5+ Kb2 62. Qb5+ Ka2 63. Qa4+ Kb2 64. Qb5+ Ka2 65. Qa6+ Kb2 66. Qb6+ Ka2 67. Qa7+ Kb2 68. Qb6+ 1/2-1/2",
    B: "1462",
  },
  {
    W: "2027",
    T: "180+0",
    P: "1. Nf3 Nf6 2. b3 e6 3. Bb2 Be7 4. e3 d5 5. d4 c5 6. c4 Nc6 7. dxc5 Bxc5 8. a3 O-O 9. b4 Be7 10. Be2 dxc4 11. Nc3 Qxd1+ 12. Rxd1 Bd7 13. Bxc4 Rad8 14. Ke2 h6 15. Nb5 a6 16. Nd6 Bxd6 17. Rxd6 Ne8 18. Rd2 Bc8 19. Rhd1 Rxd2+ 20. Rxd2 Nc7 21. Ne5 Nxe5 22. Bxe5 Ne8 23. Rd8 Nf6 24. Rxf8+ Kxf8 25. Bxf6 gxf6 26. a4 Ke7 27. a5 Kd6 28. Kd3 Bd7 29. Kd4 Bc6 30. g3 Bf3 31. h4 Bc6 32. f4 Bf3 33. e4 Bg4 34. e5+ fxe5+ 35. fxe5+ Kc6 36. Bd3 Bf3 37. Bh7 Bh5 38. Be4+ Kb5 39. Kc3 b6 40. Bd3+ Kc6 41. Bxa6 bxa5 42. bxa5 Kd5 43. Bb7+ Kxe5 44. a6 1-0",
    B: "2014",
  },
  {
    W: "2208",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. d3 b5 6. Bb3 Be7 7. O-O O-O 8. h3 d6 9. c3 a5 10. Re1 a4 11. Bc2 Na5 12. Nbd2 c5 13. Nf1 Nc6 14. Ng3 Re8 15. d4 Bf8 16. Bg5 h6 17. Be3 Qc7 18. Rc1 Na5 19. b3 a3 20. Qd2 Nc6 21. Nh4 Qb7 22. Nhf5 Bxf5 23. Nxf5 Rad8 24. d5 Ne7 25. Nxg7 Bxg7 26. Bxh6 Ng6 27. g3 Qe7 28. Bg5 Qd7 29. Kg2 Rc8 30. Qe3 Nh7 31. h4 Nxg5 32. hxg5 Qg4 33. Qf3 Qxf3+ 34. Kxf3 f6 35. gxf6 Rf8 36. Rh1 Rxf6+ 37. Ke2 Rcf8 38. Rcf1 Rf3 39. Rh5 Rxc3 40. Kd2 Rcf3 41. Ke2 b4 42. Rg5 Ne7 43. Rh5 Nc8 44. Rh2 Nb6 45. Bd1 c4 46. Ke1 Rc3 47. bxc4 Nxc4 48. Bb3 Nb2 49. Kd2 Rd3+ 50. Kc2 Rc8+ 51. Kb1 Rd2 52. Rfh1 Nd3 53. f4 Rxh2 0-1",
    B: "2169",
  },
  {
    W: "2250",
    T: "180+0",
    P: "1. e4 e5 2. Nc3 Bb4 3. g3 Bxc3 4. bxc3 Nf6 5. Bg2 O-O 6. Ne2 d6 7. O-O Be6 8. d4 Qc8 9. f4 Bh3 10. fxe5 dxe5 11. Rxf6 gxf6 12. Bh6 Rd8 13. Nc1 Bxg2 14. Kxg2 exd4 15. cxd4 Qe6 16. Qf3 Rxd4 17. Nd3 Rxe4 18. Nf4 Qc6 19. Qg4+ Kh8 20. Qg7# 1-0",
    B: "1935",
  },
  {
    W: "1979",
    T: "180+0",
    P: "1. d4 e6 2. c4 b6 3. Nc3 Bb7 4. Nf3 Nc6 5. e3 Nf6 6. Be2 Bb4 7. O-O Ne7 8. Qc2 Ng6 9. a3 Bxc3 10. Qxc3 Ne4 11. Qc2 f5 12. b3 Nh4 13. Bb2 Qf6 14. Rfd1 g5 15. d5 O-O-O 16. Bxf6 Nxf6 17. Nxh4 gxh4 18. Bf3 Ne4 19. Bxe4 fxe4 20. Qxe4 exd5 21. cxd5 Rde8 22. Qxh4 Rhg8 23. Rd4 c5 24. Rd2 h5 25. g3 Re5 26. Rad1 Rg4 27. Qh3 Kb8 28. f3 Rg8 29. e4 Ba6 30. Re1 c4 31. bxc4 Bxc4 32. Qxd7 Reg5 33. Qd6+ Ka8 34. Rg2 Ba6 35. f4 R5g6 36. Qb4 h4 37. Qc3 h3 38. Rf2 Rc8 39. Qxc8+ Bxc8 40. f5 Rf6 41. e5 Rxf5 42. Rxf5 Bxf5 43. e6 Bg6 44. d6 Kb7 45. d7 Kc7 46. e7 Kxd7 47. e8=Q+ Bxe8 48. Re4 Bg6 49. Rh4 Bf5 50. Kf2 Ke6 51. Ke3 Kd5 52. Kd2 Be6 53. Kc1 a5 54. Kb2 b5 55. Rh5+ Kd6 56. Rh6 Kd5 57. Rh5+ Ke4 58. Rxb5 Kf3 59. Rxa5 Kg2 60. Re5 Bc8 61. Re2+ Kf3 62. Rd2 Bb7 63. Kc3 Be4 64. a4 1-0",
    B: "1922",
  },
  {
    W: "2310",
    T: "180+0",
    P: "1. c4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. e4 d6 6. Nge2 e6 7. O-O Nge7 8. d3 O-O 9. f4 Qd7 10. Rb1 b6 11. Be3 Bb7 12. d4 Rad8 13. d5 exd5 14. exd5 Na5 15. b3 Nf5 16. Bf2 Rfe8 17. Qd3 Qe7 18. Ne4 Bc8 19. g4 Nh4 20. h3 Nxg2 21. Nxd6 Rxd6 0-1",
    B: "2356",
  },
  {
    W: "1493",
    T: "180+0",
    P: "1. e4 c5 2. Bc4 d6 3. c3 Nf6 4. d3 g6 5. a3 Bg7 6. Bg5 O-O 7. Nf3 Nc6 8. O-O Bg4 9. Nbd2 a6 10. Ba2 Bxf3 11. Nxf3 b5 12. b4 cxb4 13. cxb4 Qb6 14. Rb1 Nd4 15. Nxd4 Qxd4 16. Be3 Qe5 17. f3 d5 18. d4 Qd6 19. e5 Qc6 20. exf6 Bxf6 21. f4 Qc3 22. Qf3 Bxd4 23. Bxd5 Bxe3+ 24. Kh1 Bd4 25. Bxa8 Qxf3 26. gxf3 e5 27. Bb7 exf4 28. Bxa6 Ra8 29. Bxb5 Rxa3 30. Be8 Ra2 31. b5 Kf8 32. Bc6 Ke7 33. Rbd1 Bc5 34. Rd7+ Kf6 35. Be4 Kg7 36. Bd5 Rb2 37. Re7 Bxe7 0-1",
    B: "1498",
  },
  {
    W: "1185",
    T: "180+0",
    P: "1. c4 g6 2. Nc3 Bg7 3. e3 Nc6 4. Nge2 e6 5. g3 d5 6. Bg2 Bd7 7. cxd5 exd5 8. Nxd5 Nge7 9. Ndc3 O-O 10. O-O a5 11. Qb3 Ra7 12. Re1 Ra6 13. a4 Bxc3 14. Nxc3 Nb4 15. Bxb7 Ra7 16. Bg2 Qe8 17. Nb5 Bxb5 18. axb5 Qxb5 19. Qc3 Nd3 20. Rd1 Nd5 21. Bxd5 Qxd5 22. b3 Rb8 23. Bb2 Rxb3 24. Qg7# 1-0",
    B: "1151",
  },
  {
    W: "1277",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. c3 Nf6 4. d4 Nxe4 5. dxe5 d6 6. Be2 Bg4 7. Qa4 Qd7 8. Qxe4 Nxe5 9. Nxe5 dxe5 10. Qxb7 Rd8 11. Qxc7 Bxe2 12. Qxd7+ Rxd7 13. Kxe2 Bc5 14. Bg5 O-O 15. Nd2 e4 16. Nxe4 Rd5 17. Rad1 Rxg5 18. Nxg5 Be7 19. h4 Re8 20. Rd7 h6 21. Kf1 hxg5 22. hxg5 Bxg5 23. Rh5 Bf6 24. Rf5 Rb8 25. g4 Rxb2 26. g5 Bxc3 27. Rfxf7 Rb1+ 28. Kg2 Rb2 29. Rxg7+ Bxg7 30. Re7 Rxa2 31. Re8+ Bf8 32. Re5 a5 33. g6 Bg7 34. Re8+ Bf8 35. Re6 Kg7 36. Kg3 Ra4 37. f4 Rc4 38. Kg4 a4 39. Kg5 Rc5+ 40. f5 Rc7 41. Ra6 a3 42. Rxa3 Bxa3 43. f6+ Kf8 44. g7+ Kg8 45. Kg6 Bb2 46. f7+ Rxf7 47. Kg5 Rxg7+ 48. Kf4 Kf7 49. Kf5 Rg6 50. Ke4 Ke6 51. Kf4 Rg5 52. Ke4 Rg4+ 53. Kd3 Kd5 54. Ke3 Rg3+ 55. Kf4 Rg1 56. Kf5 Re1 57. Kg6 Ke6 58. Kg5 Rf1 59. Kg6 Bc1 60. Kg7 Rg1+ 61. Kh8 Kf7 62. Kh7 Rh1# 0-1",
    B: "1307",
  },
  {
    W: "1435",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Bc4 d5 4. exd5 Nxd5 5. Nxe5 f6 6. Nf3 Nc6 7. Qe2+ Be7 8. O-O a6 9. Re1 O-O 10. d3 b5 11. Bb3 Na5 12. c4 bxc4 13. dxc4 Nxb3 14. axb3 Nf4 15. Bxf4 Bd7 16. Qxe7 Qxe7 17. Rxe7 Bc6 18. Bxc7 Rfe8 19. Rxe8+ Rxe8 20. Nbd2 Rc8 21. Ba5 g5 22. Nd4 Bd7 23. Bb4 Rb8 24. Bc3 f5 25. Rxa6 f4 26. Ra7 Be8 27. Ne6 Rb6 28. Nxg5 h6 29. Nge4 Re6 30. g3 fxg3 31. hxg3 Bc6 32. Nc5 Re1+ 33. Kh2 Rc1 34. Ra8+ Kf7 35. Ra6 Be8 36. Rxh6 Ke7 37. Rh7+ Bf7 38. Nde4 Kf8 39. Ng5 Bg6 40. Rh6 Be8 41. Nge6+ Ke7 42. Rh7+ Kd6 43. Ne4+ Kxe6 44. Rh6+ Kf7 45. Rf6+ Ke7 46. Rh6 Kf7 47. Rh7+ Kg8 48. Ra7 Kf8 49. Ra8 Ke7 50. Bf6+ Kf7 51. Rxe8 Kxe8 52. Nd6+ Kd7 53. Nb5 Kc6 54. Bh4 Kc5 55. Be7+ Kc6 56. Nd4+ Kb6 57. c5+ Ka5 58. c6 Kb6 59. c7 1-0",
    B: "1314",
  },
  {
    W: "1269",
    T: "180+0",
    P: "1. e3 e5 2. d4 f6 3. Be2 d5 4. Bd2 Nc6 5. Nf3 exd4 6. Nxd4 Nxd4 7. exd4 Qe7 8. O-O f5 9. Re1 Nf6 10. Bh5+ g6 11. Rxe7+ Bxe7 12. Bf3 b6 13. Qe2 Bb7 14. Nc3 Rg8 15. Re1 Rg7 16. Bg5 Ne4 17. Nxe4 fxe4 18. Bxe7 Rxe7 19. Bg4 h5 20. Bh3 1-0",
    B: "1101",
  },
  {
    W: "1911",
    T: "180+0",
    P: "1. b3 a5 2. Bb2 a4 3. e3 axb3 4. axb3 Rxa1 5. Bxa1 Nf6 6. Bxf6 g6 7. Bxh8 f6 8. g3 Kf7 9. Bc4+ d5 10. Bd3 Kg8 11. Bxf6 exf6 12. Qf3 Nc6 13. h3 Ne5 14. Qe2 Qe7 15. Nc3 c6 16. Na4 Qa3 17. Qd1 Be6 18. Ke2 b5 19. Nc3 Qa8 20. Nf3 Nd7 21. Nd4 Bf7 22. Kf1 b4 23. Na4 c5 24. Ne2 c4 25. bxc4 dxc4 26. Rg1 cxd3 27. cxd3 b3 28. Nac3 Qa6 29. Qb1 Nc5 30. Qb2 Bg7 31. Nf4 Nxd3 32. Nxd3 Qxd3+ 33. Kg2 f5 34. f3 h5 35. Kh2 Kh7 36. Rg2 Qf1 37. f4 Qf3 38. Kg1 Bd5 39. d4 Qxe3+ 40. Kh2 Bxg2 41. Kxg2 Bxd4 42. Ne2 Bxb2 0-1",
    B: "1910",
  },
  {
    W: "1696",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. a3 a5 5. c3 d6 6. b4 axb4 7. cxb4 Ba7 8. Qb3 Nh6 9. d4 Nxd4 10. Bxh6 Nxb3 0-1",
    B: "1689",
  },
  {
    W: "1749",
    T: "600+10",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Qb6 5. Nf3 cxd4 6. cxd4 Nc6 7. b3 Bb4+ 8. Bd2 Nxd4 9. Bxb4 Qxb4+ 10. Nfd2 Nh6 0-1",
    B: "2017",
  },
  {
    W: "2375",
    T: "30+0",
    P: "1. e4 d5 2. Nc3 c6 3. exd5 cxd5 4. d4 Nc6 5. Nf3 Bf5 6. Bb5 Bg6 7. O-O e6 8. Bf4 Nf6 9. Ne5 Bd6 10. Nxc6 bxc6 11. Bxc6+ Kf8 12. Bxd6+ Qxd6 13. Bxa8 Ke7 14. Bb7 Rb8 15. Ba6 Ne4 16. Nxe4 Bxe4 17. Bd3 Bg6 18. Bxg6 hxg6 19. c3 Rh8 20. g3 g5 21. Qe2 f6 22. Rae1 Kf8 23. Qxe6 Qxe6 24. Rxe6 Kf7 25. Rfe1 Rb8 26. R1e2 Kg6 27. Re7 a5 28. Ra7 Rb5 29. b3 a4 30. Ree7 axb3 31. Rxg7+ Kf5 32. axb3 Rxb3 33. Kg2 Rxc3 34. h3 Rc4 35. Rae7 Rxd4 36. Kf3 Rd3+ 37. Kg2 Rd4 38. g4+ Kf4 39. Rgf7 1-0",
    B: "2252",
  },
  {
    W: "2151",
    T: "180+0",
    P: "1. e4 e6 2. f4 b6 3. d4 Bb7 4. Bd3 f5 5. exf5 Qh4+ 6. g3 Qf6 7. Nf3 Nc6 8. c3 exf5 9. Kf2 O-O-O 10. Re1 Nh6 11. h3 Nf7 12. Ne5 d6 13. Nxf7 Qxf7 14. b3 Be7 15. Na3 Bf6 16. Nc4 h5 17. Bxf5+ Kb8 18. Be6 Qf8 19. d5 Ne7 20. Bb2 g5 21. Re4 h4 22. g4 gxf4 23. Rxf4 Qg7 24. Qf3 Rhf8 25. Ne3 Be5 26. Nf5 Nxf5 27. Rxf5 Rxf5 28. Bxf5 Rf8 29. Re1 a5 30. Bc1 Bxc3 31. Be3 Be5 32. Bf4 Ka7 33. Bxe5 dxe5 34. Qe4 Qg8 35. Rd1 Qg7 36. Ke3 Rd8 37. Rf1 Qg5+ 38. Kd3 Ba6+ 39. Kc3 Bxf1 40. Be6 Qc1+ 0-1",
    B: "2155",
  },
  {
    W: "2191",
    T: "180+2",
    P: "1. d4 d6 2. c4 Nf6 3. Nc3 e5 4. dxe5 dxe5 5. Qxd8+ Kxd8 6. Bg5 Be6 7. O-O-O+ Nd7 8. Nd5 Bxd5 9. Rxd5 c6 10. Rd1 Bc5 11. e3 Kc7 12. Bd3 Ng4 13. Bh4 Ngf6 14. Ne2 Rhe8 15. Nc3 h6 16. Bg3 a5 17. Ne4 Nxe4 18. Bxe4 f6 19. Bf5 Nb6 20. b3 a4 21. Kb2 axb3 22. axb3 Ba3+ 23. Kc2 Bb4 24. Ra1 Red8 25. Rxa8 Rxa8 26. Rd1 Ra2+ 27. Kb1 Rd2 28. Rxd2 Bxd2 29. Kc2 Be1 30. Bg6 Kd6 31. Kd1 Bc3 32. f3 Kc5 33. Kc2 Ba1 34. Be1 Nd7 35. Kb1 Bd4 36. exd4+ Kxd4 37. Kc2 Ke3 38. Bf5 Nc5 39. b4 Na4 40. c5 b6 41. Bd7 bxc5 42. Bxc6 Nb6 43. bxc5 Nc4 44. Be4 Ke2 45. Bg3 Ne3+ 46. Kc3 Nxg2 47. c6 Ne3 48. c7 f5 49. c8=Q fxe4 50. fxe4 g5 51. Bxe5 g4 52. Bd4 Ng2 53. Qxg4+ Kf1 54. Qf3+ 1-0",
    B: "2119",
  },
  {
    W: "1755",
    T: "300+8",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 d6 4. d4 Bg4 5. dxe5 Bxf3 6. gxf3 Nxe5 7. f4 Ng6 8. Be3 Qh4 9. Nd5 O-O-O 10. Qd2 N8e7 11. O-O-O Nxd5 12. Qxd5 Nxf4 13. Qxf7 Ng6 14. Bxa7 Ne5 15. Qe6+ Nd7 16. Bb5 g6 17. f4 Qxf4+ 18. Kb1 Bg7 19. Rhf1 Qe5 20. Bxd7+ Rxd7 21. Qxe5 Bxe5 22. Bd4 Re7 23. Rf2 Rhe8 24. Bxe5 Rxe5 25. Rf7 h5 26. Rf6 g5 27. Rf5 Rxe4 28. Rxg5 Rh4 29. Rg2 Rh3 30. b3 Re4 31. Rdd2 Kd7 32. Rde2 Reh4 33. a4 c5 34. Kb2 Kc6 35. Rd2 b6 36. Rdf2 Rd4 37. Rg8 Rdh4 38. Rgg2 b5 39. a5 b4 40. a6 Kb6 41. Rf6 Rd4 42. Rgg6 Kxa6 43. Rxd6+ Rxd6 44. Rxd6+ Kb5 45. Rd2 Rc3 46. Rd3 Rxd3 47. cxd3 Kc6 48. Kc2 Kd5 49. Kd1 Kd4 50. Kd2 h4 51. h3 Kd5 52. Ke3 Ke5 53. Kf3 Kd4 54. Kg4 Kc3 55. Kxh4 Kxb3 56. Kg4 Kc3 57. d4 cxd4 58. h4 b3 59. h5 b2 60. h6 b1=Q 0-1",
    B: "1711",
  },
  {
    W: "1520",
    T: "60+0",
    P: "1. c4 e6 2. Nc3 c5 3. g3 Nc6 4. Bg2 d5 5. d3 d4 6. Ne4 Nf6 7. Nxf6+ Qxf6 8. h4 Bd6 9. Bg5 Qf5 10. Nf3 f6 11. Bd2 O-O 12. Qc1 e5 13. h5 h6 14. Rh4 Nb4 15. Bxb4 cxb4 16. g4 Qe6 17. Bh3 Qe7 18. b3 Bc5 19. Nd2 g5 20. hxg6 Qg7 21. Ne4 Qxg6 22. Nxc5 Bxg4 23. Bxg4 Kh7 24. Kd2 Qg5+ 25. Kc2 Qxh4 26. Bf3 Qxf2 27. Qd2 Qe3 28. Qxe3 dxe3 29. Rh1 Rg8 30. Ne4 Rad8 31. Nxf6+ Kg6 32. Nxg8 Rxg8 33. Rg1+ Kh7 34. Rxg8 Kxg8 35. Bxb7 Kg7 36. Bd5 Kf6 37. c5 Kg5 38. c6 Kf4 39. c7 Kg3 40. c8=Q Kf2 41. Qf8+ Kxe2 42. Qf3+ Ke1 43. Qxe3+ Kf1 0-1",
    B: "1426",
  },
  {
    W: "1611",
    T: "300+0",
    P: "1. e4 e5 2. Nc3 Nc6 3. Bb5 Bc5 4. Nf3 d6 5. d3 Ne7 6. Bg5 f6 7. Bh4 g5 8. Bg3 h5 9. h3 h4 10. Bh2 Bd7 11. Nd5 a6 12. Ba4 g4 13. hxg4 Bxg4 14. Nxe7 Qxe7 15. Bxc6+ bxc6 16. Qd2 d5 17. b4 Bxb4 18. c3 Ba5 19. Qb2 Kf7 20. Qb7 Qd7 21. O-O Bxf3 22. gxf3 Qh3 23. exd5 Rhg8+ 24. Kh1 Qg2# 0-1",
    B: "1604",
  },
  {
    W: "1878",
    T: "240+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Nb5 d6 6. c4 a6 7. N5c3 h6 8. Be3 Nf6 9. Be2 Be7 10. O-O O-O 11. Qd2 Ng4 12. Bxg4 Bxg4 13. Na3 f5 14. exf5 Bxf5 15. Nd5 Bg5 16. Bxg5 hxg5 17. Nc2 Ne7 18. Nce3 Nxd5 19. Nxd5 Be4 20. Rad1 Kh7 21. Qe3 Bf5 22. b3 Kg6 23. f3 Rh8 24. h3 Qd7 25. g4 Be6 26. Kg2 Raf8 27. Rf2 Rh7 28. Qe4+ Kh6 29. Rdf1 g6 30. Rh1 Qc6 31. h4 gxh4 32. Rxh4+ Kg7 33. Rxh7+ Kxh7 34. Kg3 Kg7 35. Rh2 Bxd5 36. cxd5 Qc1 37. Rf2 Qf4+ 38. Kg2 Qxe4 39. fxe4 Rxf2+ 40. Kxf2 Kf6 41. Kf3 Kg5 42. Kg3 b6 43. a3 Kf6 44. Kh4 g5+ 45. Kh5 b5 46. Kh6 a5 47. Kh7 Kf7 48. Kh6 Kf6 49. Kh5 b4 50. a4 Kg7 51. Kxg5 Kf7 52. Kf5 Kg7 53. g5 Kf7 54. g6+ Kg7 55. Ke6 Kxg6 56. Kxd6 Kf6 57. Kd7 Kg5 58. Ke7 Kf4 59. Ke6 Kxe4 60. d6 Kd4 61. d7 e4 62. d8=Q+ Kc3 63. Qd1 Kb2 64. Ke5 Ka3 65. Kxe4 Kb2 0-1",
    B: "1976",
  },
  {
    W: "1509",
    T: "600+0",
    P: "1. e4 e5 2. Bc4 Nf6 3. Nf3 Nxe4 4. Bxf7+ Kxf7 5. Nxe5+ Ke8 6. Qh5+ Ke7 7. d4 Qe8 8. Bg5+ Nf6 9. Bxf6+ gxf6 10. Qxe8+ Kxe8 11. Ng4 Be7 12. O-O d6 13. Ne3 Nc6 14. Nc3 Bd7 15. d5 Nd4 16. Rfd1 Nf5 17. Nxf5 Bxf5 18. Nb5 Kd8 19. c4 a6 20. Nd4 Bd7 21. Ne6+ Bxe6 22. dxe6 b6 23. b4 Kc8 24. a4 Kb7 25. b5 a5 26. Rd5 Rhg8 27. Rh5 Rg7 28. g3 Rag8 29. Re1 Bd8 30. Re4 Re7 31. Reh4 Rge8 32. Rxh7 Rxe6 33. Rf7 Re1+ 34. Kg2 R8e2 35. Rh8 Kc8 36. Rff8 Kd7 37. Rxd8+ Ke6 38. Rc8 Kf5 39. Rxc7 Ke4 40. Rc6 Rg1+ 41. Kxg1 Kf3 42. h4 Re1+ 43. Kh2 Rf1 44. Kh3 Rh1# 0-1",
    B: "1338",
  },
  {
    W: "2336",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nf3 Qb5 4. d4 Qa5+ 5. b4 Qh5 6. c3 Bg4 7. Be2 e6 8. Be3 Bd6 9. Nbd2 Nf6 10. Qc2 g6 11. O-O-O Bf5 12. Bd3 Bxd3 13. Qxd3 Qd5 14. Kb2 Na6 15. a3 c6 16. c4 Qh5 17. Ne4 Nc7 18. Nxd6+ Ke7 19. Ne4 a5 20. Bg5 h6 21. Bxf6+ Kf8 22. Bxh8 Kg8 23. d5 cxd5 24. Qd4 axb4 25. Qg7# 1-0",
    B: "1722",
  },
  {
    W: "1578",
    T: "60+0",
    P: "1. e4 d6 2. d4 h6 3. Nf3 g5 4. c3 Bg7 5. h3 c6 6. Nbd2 Nd7 7. Bd3 Ngf6 8. Qc2 Nb6 9. Nf1 Qc7 10. Ng3 Bd7 11. Nf5 Bxf5 12. exf5 O-O-O 13. Be3 Nbd5 14. Bd2 Rde8 15. O-O-O e5 16. fxe6 Rxe6 17. g4 Rhe8 18. Rhe1 Qd7 19. h4 gxh4 20. Nxh4 Rxe1 21. Rxe1 Rxe1+ 22. Bxe1 Kb8 23. Nf5 Bf8 24. f3 Nf4 25. Bg3 Nxd3+ 26. Qxd3 Ka8 27. Qe2 d5 28. Be5 Nh7 0-1",
    B: "1467",
  },
  {
    W: "1606",
    T: "120+1",
    P: "1. d4 e6 2. c4 d5 3. c5 b6 4. b4 Bb7 5. Be3 a6 6. a4 Nc6 7. Qd2 bxc5 8. bxc5 a5 9. Nc3 Be7 10. Na2 Nf6 11. f3 O-O 12. Rb1 Ba6 13. Qc3 h6 14. Bd2 Nh5 15. g3 Nf6 16. e3 Bxf1 17. Kxf1 Qd7 18. Nh3 e5 19. Nf2 exd4 20. exd4 Qf5 21. Kg2 Rfe8 22. Rhe1 Bf8 23. Rb7 Rxe1 24. Bxe1 Qb1 25. Rxb1 Be7 26. Qb2 g6 27. Qb7 Kg7 28. Qxa8 Nxd4 29. Qxa5 Ne2 30. Qxc7 Bf8 31. Qe5 Nxg3 32. Qxg3 Bxc5 33. Qe5 Be7 34. Bc3 Bd8 35. Nd3 d4 36. Bxd4 Kg8 37. Qxf6 Bxf6 38. Bxf6 1-0",
    B: "1633",
  },
  {
    W: "1764",
    T: "300+3",
    P: "1. Nf3 d5 2. d4 Nf6 3. e3 Bg4 4. Be2 e6 5. h3 Bh5 6. Nbd2 Nbd7 7. c4 c6 8. b3 Bd6 9. Bb2 h6 10. Qc2 Bg6 11. Bd3 Bxd3 12. Qxd3 g5 13. Nh2 Qc7 14. Ng4 Nxg4 15. hxg4 Nf6 16. f3 dxc4 17. Nxc4 Bg3+ 18. Ke2 Nd5 19. a3 Kd7 20. Rad1 Bh2 21. Ne5+ Ke7 22. Rxh2 Rag8 23. Rhh1 f6 24. Nc4 e5 25. dxe5 fxe5 26. Bxe5 1-0",
    B: "1665",
  },
  {
    W: "1553",
    T: "900+0",
    P: "1. d4 Nf6 2. Nf3 d5 3. Bg5 Bf5 4. Nc3 e6 5. e3 c5 6. Bb5+ Nc6 7. Qd2 c4 8. O-O-O Bb4 9. Ne5 Qc7 10. f3 a6 11. Bxc6+ bxc6 12. Bxf6 gxf6 13. e4 Bg6 14. Ng4 Ke7 15. h4 h5 16. Ne3 Rhb8 17. g4 Qa5 18. a3 Bxa3 19. bxa3 Qxa3# 0-1",
    B: "1904",
  },
  {
    W: "1515",
    T: "60+0",
    P: "1. Nf3 b6 2. c4 Bb7 3. e3 e6 4. d4 Bb4+ 5. Bd2 Bxd2+ 6. Nbxd2 Nf6 7. Bd3 Bxf3 8. Nxf3 d6 9. O-O Nbd7 10. e4 c5 11. d5 e5 12. Nd2 O-O 13. f4 h6 14. fxe5 Nxe5 15. Be2 Nfg4 16. h3 Ne3 17. Qb3 Nxf1 18. Rxf1 Qf6 19. Rf2 Qg6 20. Bf3 Rae8 21. a4 a5 22. Qxb6 Rb8 23. Qxd6 Rxb2 24. Qxe5 Rb1+ 25. Kh2 Rb4 26. d6 Rxa4 27. d7 Rxc4 28. Qe8 Qf6 29. Qxf8+ Kxf8 30. Nxc4 Qd8 31. Rd2 Ke7 32. Bg4 a4 33. Ne5 a3 34. Nc6+ Kf6 35. Nxd8 a2 36. Rxa2 Ke7 37. Nb7 1-0",
    B: "1441",
  },
  {
    W: "1267",
    T: "60+0",
    P: "1. d4 c6 2. e3 Nf6 3. f4 g6 4. c3 Bg7 5. h3 e6 6. Bd3 d5 7. Nf3 O-O 8. O-O b5 9. Ne5 Bb7 10. Qf3 a5 11. a3 Nbd7 12. Nd2 Nxe5 13. fxe5 Nd7 14. b3 b4 15. c4 bxa3 16. Rxa3 c5 17. cxd5 Bxd5 18. Qf2 cxd4 19. exd4 Nb6 20. b4 axb4 21. Rc3 Ra1 22. Nb1 bxc3 23. Nxc3 Nc4 24. Nxd5 exd5 25. Bxc4 dxc4 26. Qe2 Qxd4+ 27. Kh1 Bxe5 28. h4 Qxh4+ 29. Kg1 Bd4+ 30. Rf2 Rxc1+ 31. Qf1 Re1 32. Qxe1 Re8 33. Qxe8+ Kg7 34. Qe6 Qxf2+ 35. Kh2 fxe6 36. Kh3 e5 0-1",
    B: "1269",
  },
  {
    W: "2030",
    T: "60+0",
    P: "1. d4 Nf6 2. c4 d5 3. Nc3 dxc4 4. e3 g6 5. Bxc4 Bg7 6. f4 O-O 7. Nf3 Bf5 8. O-O a6 9. Bd3 Bg4 10. h3 Bxf3 11. Qxf3 b5 12. e4 c6 13. Bd2 Ra7 14. e5 Nd5 15. Nxd5 cxd5 16. Rac1 e6 17. Rc2 Nd7 18. Rfc1 Nb6 19. b3 f6 20. Qg4 Qe7 21. Rc6 Nd7 22. Qxe6+ Qxe6 23. Rxe6 fxe5 24. fxe5 Kf7 25. Rec6 Rb8 26. Rc7 Rbb7 27. Rxb7 Rxb7 28. g4 Nb6 29. Kf2 h6 30. Kg3 g5 31. h4 gxh4+ 32. Kxh4 Ke6 33. Bg6 Nd7 34. Bf5+ Ke7 35. g5 hxg5+ 36. Bxg5+ Bf6 37. exf6+ Nxf6 38. Bxf6+ Kxf6 39. Kg4 Rg7+ 40. Kf4 Rg5 41. Rc6+ 1-0",
    B: "2046",
  },
  {
    W: "1836",
    T: "60+0",
    P: "1. e4 e6 2. Nc3 d5 3. Nf3 Nf6 4. exd5 exd5 5. d4 Be6 6. Be2 Bb4 7. Bd2 O-O 8. O-O Bd6 9. Bb5 c6 10. Be2 Nbd7 11. Re1 Qc7 12. Be3 Bg4 13. Nb1 Ne4 14. h3 Bh5 15. c3 Bg6 16. Nbd2 Nxd2 17. Nxd2 Nf6 18. Bg5 Ne4 19. Nxe4 Bxe4 20. Bf3 Bxf3 21. Qxf3 Rfe8 22. Re3 Rxe3 23. Bxe3 Re8 24. Re1 Qd7 25. Re2 Bb8 26. h4 Qd6 27. g3 Qg6 28. Kg2 Qb1 29. b3 Qg6 30. c4 dxc4 31. bxc4 Qd3 32. c5 Qc4 33. a3 Qe6 34. a4 Qd5 35. Qxd5 cxd5 36. Kf1 Bc7 37. Bd2 b6 38. Rxe8# 1-0",
    B: "1796",
  },
  {
    W: "1711",
    T: "60+0",
    P: "1. d4 Nf6 2. c4 e6 3. Nc3 b6 4. Nf3 Bb7 5. e3 c5 6. d5 exd5 7. cxd5 d6 8. Bd3 Bc8 9. O-O Bg4 10. h3 Bh5 11. Be2 Nbd7 12. Nd2 Bxe2 13. Qxe2 Be7 14. f4 O-O 15. e4 Ne8 16. e5 dxe5 17. fxe5 Bg5 18. e6 fxe6 19. dxe6 Rxf1+ 20. Kxf1 Ndf6 21. Nf3 Bxc1 22. Rxc1 Qd6 23. Rd1 Qe7 24. Nd5 Nxd5 25. Rxd5 Nf6 26. Rd1 Re8 27. Ng5 h6 28. Nf3 Qxe6 29. Qxe6+ Rxe6 30. Rd8+ Re8 31. Rxe8+ Nxe8 32. Ne5 Nd6 33. Ke2 g6 34. Ke3 g5 35. g3 Nc4+ 36. Nxc4 b5 37. Ne5 a6 38. g4 c4 39. b3 Kg7 0-1",
    B: "1732",
  },
  {
    W: "1295",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Qd3 d6 6. Nxc6 bxc6 7. Nc3 Nf6 8. Be2 Ng4 9. Be3 d5 10. exd5 cxd5 11. Qb5+ Bd7 12. Qxd5 Nxe3 13. Qxa8+ Ke7 14. Qe4+ Be6 15. Qxh4+ 1-0",
    B: "1258",
  },
  {
    W: "1981",
    T: "180+0",
    P: "1. d4 d5 2. Nf3 c5 3. g3 Nc6 4. Bg2 h5 5. O-O h4 6. c4 hxg3 7. hxg3 Bh3 8. cxd5 Bxg2 9. Kxg2 Qd7 10. Rh1 Rxh1 11. Qxh1 Nxd4 12. Nc3 O-O-O 13. Bf4 Nf6 14. b4 c4 15. Nxd4 Nxd5 16. Qh3 Qxh3+ 17. Kxh3 Nxc3 18. Rc1 Rxd4 19. Rxc3 f6 20. e3 Rd2 21. Rxc4+ Kd7 22. Rd4+ Rxd4 23. exd4 Kc6 24. Kg4 Kb5 25. Bd2 Kc4 26. Kf5 Kxd4 27. Ke6 Kd3 28. Be3 Kc4 29. Kf7 Kxb4 30. Kxf8 a5 31. Kxg7 Ka3 32. Bd2 a4 33. Kf7 Kxa2 34. Kxe7 Kb3 35. Kxf6 a3 36. Be3 a2 37. Bd4 Kc2 38. g4 b5 39. f4 b4 40. g5 b3 41. g6 b2 42. Bxb2 Kxb2 43. g7 Ka3 44. g8=Q a1=Q+ 45. Kg5 Qg1+ 46. Kf6 Qxg8 47. f5 Qf8+ 48. Kg6 Kb4 49. f6 Kc5 50. f7 Kd6 51. Kf6 Kd7 52. Kg6 Ke6 53. Kh7 Qxf7+ 54. Kh8 Qf8+ 55. Kh7 Qf1 56. Kh8 Qg1 57. Kh7 Kf7 58. Kh6 Qg6# 0-1",
    B: "1910",
  },
  {
    W: "1415",
    T: "180+0",
    P: "1. e4 c5 2. f4 e6 3. Nf3 d5 4. exd5 exd5 5. Ng5 Be7 6. Nc3 Bxg5 7. fxg5 Qxg5 8. d3 Qe5+ 9. Qe2 Qxe2+ 10. Bxe2 d4 11. O-O dxc3 12. bxc3 Nf6 13. Bf3 O-O 14. Bg5 Nfd7 15. Bd5 Kh8 16. Rxf7 Rxf7 17. Bxf7 Ne5 18. Bd5 Nbd7 19. Rf1 h6 20. Be4 hxg5 21. Rf8+ Nxf8 0-1",
    B: "1554",
  },
  {
    W: "2443",
    T: "180+0",
    P: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. Bxe7 Qxe7 7. f4 O-O 8. Nf3 c5 9. dxc5 Qxc5 10. Bd3 h6 11. Qd2 Nc6 12. O-O-O a6 13. Ne2 b5 14. Ned4 Nxd4 15. Nxd4 Nb6 16. Kb1 b4 17. g4 Bd7 18. Qf2 a5 19. f5 a4 20. f6 g6 21. Qf4 Kh7 22. h4 Rh8 23. h5 Kg8 24. hxg6 a3 25. Rxh6 Qxd4 26. Rxh8+ 1-0",
    B: "2421",
  },
  {
    W: "2051",
    T: "420+0",
    P: "1. e4 e6 2. Nf3 c6 3. c4 d5 4. cxd5 cxd5 5. exd5 Qxd5 6. Nc3 Qd8 7. d4 a6 8. Bg5 Nf6 9. Ne5 Be7 10. f4 h6 11. Bh4 O-O 12. Be2 Nbd7 13. O-O b5 14. a3 Bb7 15. Bf3 Bxf3 16. Qxf3 Rc8 17. f5 exf5 18. Qxf5 Nb6 19. Rad1 Nc4 20. h3 Ne3 21. Bxf6 Nxf5 22. Bxg7 Kxg7 23. Rxf5 Bxa3 24. Rd3 Bxb2 25. Rg3+ Kh7 26. Ne2 Bxd4+ 27. Nxd4 Qxd4+ 28. Kh2 f6 29. Ng4 Rc1 30. Re3 Rf7 31. Rh5 Qf4+ 32. Rg3 f5 33. Rxh6+ Qxh6 34. Nxh6 Kxh6 0-1",
    B: "1955",
  },
  {
    W: "1481",
    T: "300+5",
    P: "1. e4 c5 2. Nf3 g6 3. Bc4 Bg7 4. Nc3 e6 5. e5 d6 6. Bb5+ Nc6 7. Bxc6+ bxc6 8. exd6 Qxd6 9. O-O Ba6 10. Re1 Rd8 11. Ne4 Qe7 12. d3 c4 13. Bg5 f6 14. Bh4 Nh6 15. h3 Nf5 16. a4 Nxh4 17. Nxh4 f5 18. Nxf5 gxf5 19. Qh5+ Kd7 20. Ng5 Rdf8 21. Nxe6 Bxb2 22. Rad1 Rf6 23. dxc4+ Kc8 24. Qe2 Qg7 25. Qe5 Bxe5 26. Nxg7 Bxc4 27. Rxe5 Rg6 28. Nxf5 Rhg8 29. Ne7+ Kc7 30. Nxg8 Rxg8 31. g4 Bd5 32. Re7+ Kd6 33. Rxh7 1-0",
    B: "1609",
  },
  {
    W: "2374",
    T: "60+0",
    P: "1. e4 d6 2. d4 Nf6 3. Bd3 g6 4. f4 Bg7 5. e5 Nfd7 6. Nf3 c5 7. c3 cxd4 8. cxd4 dxe5 9. dxe5 O-O 10. Be3 Nc6 11. O-O Ndxe5 12. fxe5 Nxe5 13. Nxe5 Bxe5 14. Nc3 Qc7 15. h3 Be6 16. Rc1 Rac8 17. Qe2 Qa5 18. a3 a6 19. Qf2 Bg7 20. Bd4 b5 21. Bxg7 Kxg7 22. Rfe1 b4 23. axb4 Qxb4 24. Bxa6 Rc7 25. Bd3 Rfc8 26. Qd2 Bb3 27. Re3 e5 28. Rxe5 Qd4+ 29. Qe3 Qxe3+ 30. Rxe3 Rd8 31. Rce1 Rcd7 32. Bb1 Rd2 33. R3e2 Rxe2 34. Rxe2 Rd1+ 35. Nxd1 Bxd1 36. Rd2 Bb3 37. Bc2 Be6 38. b4 Kf6 39. b5 Ke5 40. b6 Bc8 41. Ba4 Bb7 42. Rd8 Be4 43. Rd7 f5 44. Rxh7 g5 45. b7 Bxb7 46. Rxb7 f4 47. Rb5+ Ke4 48. Bd1 f3 49. Rb4+ Kf5 50. Bxf3 g4 51. Rxg4 Kf6 52. Kf2 Kf5 53. Re4 Kf6 54. Ke3 Kf5 55. g3 Kf6 56. Rf4+ Ke5 57. g4 Ke6 58. h4 Ke5 1/2-1/2",
    B: "2184",
  },
  {
    W: "1842",
    T: "60+0",
    P: "1. e4 d5 2. e5 d4 3. f4 e6 4. Nf3 g6 5. d3 Bg7 6. c4 b6 7. b3 Bb7 8. Nbd2 Ne7 9. Qe2 Nf5 10. g4 Ne7 11. Bg2 c5 12. h4 Nd7 13. Bb2 a5 14. h5 a4 15. hxg6 fxg6 16. Rh2 axb3 17. a3 Bc6 18. Kf2 Qb8 19. Rah1 h6 20. g5 h5 21. Nh4 Nf8 22. Bxc6+ Kd8 23. Ndf3 Ra7 24. Ng1 Nxc6 25. Nh3 Ne7 26. Kg3 Nf5+ 27. Nxf5 exf5 28. Nf2 Qb7 29. e6 Qe7 30. Re1 Ra8 31. Nd1 Kc7 32. Qf3 Re8 33. Qe2 Nxe6 34. Nf2 Qd6 35. Qf1 Qxf4+ 36. Kg2 Qd6 37. Nh3 Nf4+ 38. Kh1 Nxh3 39. Rxh3 Qc6+ 40. Kg1 Qd6 41. Rf3 Qc6 42. Qf2 Qd7 0-1",
    B: "1886",
  },
  {
    W: "852",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Nxe4 4. Qf3 Nf6 5. Bc4 b6 6. Bxf7+ Ke7 7. d4 Nc6 8. Bg5 Nxe5 9. Bxf6+ Kxf7 10. Qd5+ Kxf6 11. Qxe5+ Kf7 12. Qd5+ Ke8 13. Qxa8 c6 14. Qxa7 Bb4+ 15. c3 Qe7+ 0-1",
    B: "1024",
  },
  {
    W: "1768",
    T: "60+0",
    P: "1. e4 d6 2. Nf3 c6 3. e5 g6 4. exd6 Qxd6 5. d3 Bg7 6. Nc3 Bg4 7. Bd2 Bxf3 8. Qxf3 Nf6 9. O-O-O O-O 10. g3 Nbd7 11. Qg2 Ne5 12. f4 Ned7 13. Be2 Qd5 14. Rhe1 Qxg2 0-1",
    B: "1736",
  },
  {
    W: "2017",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d3 Nf6 5. Nf3 Bg4 6. Be2 e6 7. O-O Bxf3 8. Bxf3 c6 9. d4 Nbd7 10. Ne2 Bd6 11. c4 Bc7 12. Nc3 Qe7 13. d5 cxd5 14. cxd5 O-O 15. Bg5 Qd6 16. dxe6 Qxh2# 0-1",
    B: "1967",
  },
  {
    W: "2119",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7 5. Nf3 Ngf6 6. Nxf6+ Nxf6 7. Bd3 h6 8. c3 Bd6 9. O-O Bd7 10. Qe2 c5 11. h3 cxd4 12. Nxd4 O-O 13. Be3 Qc7 14. Rad1 a6 15. Bb1 Rad8 16. Qc2 Bc6 17. Rfe1 Bc5 18. b4 Bxd4 19. Bxd4 Rxd4 20. cxd4 g6 21. Rc1 Qf4 22. a4 Qxd4 23. b5 axb5 24. axb5 Bxb5 25. Rcd1 Qh4 26. Re3 Rd8 27. Rxd8+ Kg7 28. Rd1 Nd5 29. Rg3 Qh5 30. Re1 e5 31. Qd2 Nf4 32. Be4 f6 33. Bf3 Bc6 34. Bxh5 Nxh5 35. Rge3 Nf4 36. Rxe5 Nxg2 37. Re7+ Kf8 38. Qd8+ Be8 39. Rxe8+ Kf7 40. Qe7# 1-0",
    B: "2036",
  },
  {
    W: "1202",
    T: "60+0",
    P: "1. d4 c5 2. Nf3 cxd4 3. Nxd4 g6 4. c3 Bg7 5. e4 e5 6. Nb5 a6 7. Nd6+ Ke7 8. Nxc8+ Qxc8 9. Be3 Nf6 10. Bd3 Nc6 11. O-O h5 12. Nd2 g5 13. Bxg5 Rg8 14. Bxf6+ Bxf6 15. Nc4 d6 16. Qxh5 Qe6 17. b4 Rh8 18. Qf3 Rag8 19. g3 Rh3 20. Nb6 Nd8 21. Nd5+ Kd7 22. Nxf6+ Kc6 23. b5+ Kc5 24. Nd7+ Qxd7 25. Rab1 axb5 26. Qe3+ Kc6 27. Bxb5+ Kc7 28. Bxd7 Kxd7 29. Rxb7+ Ke6 0-1",
    B: "1346",
  },
  {
    W: "1521",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 Bf5 3. Bg5 Qd7 4. e3 Qd6 5. Bd3 Bxd3 6. Qxd3 Nf6 7. Bf4 Qd8 8. Nfd2 e6 9. f3 Bd6 10. Bg3 Nbd7 11. c3 Qe7 12. Na3 a6 13. Nc2 O-O 14. e4 dxe4 15. Nxe4 Nxe4 16. Qxe4 Nf6 17. Bxd6 Qxd6 18. Qe5 Qb6 19. b3 Nd5 20. c4 Nb4 21. Nxb4 Qxb4+ 22. Kf2 Qd2+ 23. Qe2 Qxd4+ 24. Qe3 Qh4+ 25. g3 Qf6 26. Qf4 Qb2+ 27. Ke3 Rad8 28. Ke4 Rd4+ 29. Ke5 Rxf4+ 30. Kxf4 Qd2+ 31. Kg4 Qh6 32. h4 g6 33. Kh3 Qh5 34. Rhg1 Qxf3 35. Rg2 1-0",
    B: "1546",
  },
  {
    W: "1647",
    T: "60+0",
    P: "1. d4 c5 2. c4 cxd4 3. Qxd4 Nc6 4. Qd1 e6 5. g3 Nf6 6. Bg2 Bc5 7. Nf3 O-O 8. O-O d5 9. cxd5 Nxd5 10. e4 Nf6 11. Qxd8 Rxd8 12. Bg5 h6 13. Bxf6 gxf6 14. Nc3 Bb4 15. a3 Bxc3 16. bxc3 Na5 17. Rfd1 Rxd1+ 18. Rxd1 Nc4 19. Rd8+ Kg7 20. Nh4 Nxa3 21. Nf3 b6 22. e5 Bb7 23. Rd7 Rb8 24. exf6+ Kxf6 25. Nh4 Nc4 26. Bxb7 Ne5 27. Rc7 Nd3 28. Be4 Nc5 29. Bc2 Rd8 30. Rxa7 Rd2 31. Bb1 Rd1+ 32. Kg2 Rxb1 33. Nf3 Rc1 34. c4 Rxc4 35. Rc7 Rc2 36. Nd4 Ke5 37. Nxc2 Kd6 38. Rxc5 Kxc5 39. Ne3 b5 40. Nc2 b4 41. Nxb4 Kxb4 42. h4 Kc5 43. Kf3 Kd5 44. Kf4 Kd6 45. g4 Ke7 46. Ke5 f6+ 47. Kf4 Kf7 48. Ke4 f5+ 49. gxf5 exf5+ 50. Kxf5 Kg7 51. f4 h5 52. Kg5 Kh7 53. f5 Kg7 54. f6+ Kf7 55. Kxh5 1-0",
    B: "1650",
  },
  {
    W: "2029",
    T: "60+0",
    P: "1. c4 Nf6 2. Nc3 e6 3. g3 d6 4. Bg2 Be7 5. d4 c6 6. Nf3 e5 7. O-O exd4 8. Nxd4 Qc7 9. f4 Bd7 10. e4 Na6 11. Be3 O-O-O 12. a3 Nb8 13. b4 b6 14. Rb1 Ng4 15. Bd2 Bf6 16. Nce2 h5 17. h3 c5 18. hxg4 Bxg4 19. Nf3 g5 20. fxg5 h4 21. gxf6 hxg3 22. Nxg3 d5 23. Ne2 dxe4 24. Ng5 e3 25. Rb2 Qh2# 0-1",
    B: "1925",
  },
  {
    W: "1469",
    T: "60+0",
    P: "1. d4 d5 2. e3 e6 3. f4 Qf6 4. Bd3 Be7 5. Nf3 Bd8 6. Nbd2 b6 7. O-O c5 8. c3 c4 9. Ne5 b5 10. g4 cxd3 11. g5 Qe7 12. f5 f6 13. gxf6 gxf6 14. Qh5+ Kf8 15. fxe6 Qxe6 16. Ndf3 fxe5 17. Nxe5+ Nf6 18. Qh6+ Ke7 19. Qg7+ Kd6 20. Nf7+ Kc6 21. Nxd8+ Kd6 22. Nxe6 Kxe6 23. Rxf6# 1-0",
    B: "1463",
  },
  {
    W: "2161",
    T: "180+0",
    P: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 g6 5. Bc4 Bg7 6. Nge2 e6 7. f4 d6 8. O-O Ne7 9. Nb5 Qb6+ 10. Ned4 a6 11. Nxd6+ Qxd6 12. e5 Qb6 13. Be3 O-O 14. Nf5 Qc7 15. Rc1 Nxf5 16. Bxe6 Nxe3 17. Rxc7 Nxd1 18. Bxc8 Nxb2 19. Bxb7 Ra7 20. Rb1 Nd3 21. Rc4 Nd7 22. Rd4 N7c5 23. Bd5 Rd7 24. e6 Bxd4+ 25. Kf1 Rxd5 26. Ke2 0-1",
    B: "2075",
  },
  {
    W: "1737",
    T: "540+0",
    P: "1. d4 Nf6 2. Nf3 e6 3. Bg5 Be7 4. e3 d5 5. c3 c5 6. Qc2 Nbd7 7. Nbd2 b6 8. Bb5 a6 9. Bc6 Ra7 10. Ne5 Ng8 11. Bf4 Nf6 12. O-O-O Bb7 13. Ndf3 Qc8 14. Bxd7+ Nxd7 15. h4 cxd4 16. exd4 Bd6 17. g4 f6 18. Ng6 Bxf4+ 19. Nxf4 Qc7 20. Nxe6 Qd6 21. Nxg7+ Kf7 22. Nf5 Qf4+ 23. Kb1 Qxf3 24. Nd6+ Kf8 25. Rhe1 Qxg4 26. Re8+ Kg7 27. Nf5+ Kf7 28. Nh6+ Kxe8 29. Nxg4 Kd8 30. Nh6 Kc7 31. c4 Kb8 32. cxd5 Bxd5 33. Qf5 Bc6 34. d5 Ba4 35. Rg1 Rc8 36. Nf7 Bc2+ 37. Qxc2 Rxc2 38. Kxc2 Nc5 39. Rg8+ Kc7 40. d6+ Kd7 41. Nh6 Kxd6 42. Rd8+ Ke7 43. Rb8 Rc7 44. Nf5+ Ke6 45. Nd4+ Kd5 46. Nb3 Nd7+ 47. Kb1 Nxb8 48. Nd2 Nc6 49. f4 Nd4 50. b3 Ke6 51. Nc4 b5 52. Na5 Kf5 53. b4 Kxf4 54. Nb3 Nxb3 55. axb3 Ke4 56. Kb2 Kd3 57. Kb1 Kc3 0-1",
    B: "1805",
  },
  {
    W: "1867",
    T: "120+1",
    P: "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. Nf3 Nc6 5. a3 Ngxe5 6. Nc3 Nxf3+ 7. exf3 d6 8. Be2 Be7 9. O-O Bf6 10. Bf4 O-O 11. Re1 Re8 12. Rc1 Ne5 13. Bf1 Be6 14. Nd5 Bxd5 15. Qxd5 Nc6 16. Qd2 Rxe1 17. Rxe1 Qd7 18. c5 d5 19. Bb5 d4 20. b4 a6 21. Ba4 Re8 22. h3 Rxe1+ 23. Qxe1 g6 24. Bh6 Bg7 25. Bxg7 Kxg7 26. Bxc6 bxc6 27. Qe5+ f6 28. Qe2 d3 29. Qd2 Kf7 30. Kf1 Qd5 31. Ke1 Qe6+ 32. Kf1 Qc4 33. Ke1 Ke6 34. Qe3+ Kf7 35. Qd2 Qb3 36. a4 Qc4 37. g3 Qd5 38. Qe3 Qc4 39. Qd2 f5 40. f4 Qd5 41. Qe3 Kf6 42. Kd2 Qa2+ 43. Kxd3 Qxa4 44. Qe5+ Kf7 45. Qxc7+ Kg8 46. Qd8+ Kg7 47. Qe7+ Kg8 48. Qe6+ Kg7 49. Qe7+ Kg8 50. Qe6+ Kg7 51. Qc4 Qb5 52. Qxb5 axb5 53. Kd4 Kf6 54. f3 Ke6 55. g4 h6 56. h4 h5 57. gxf5+ gxf5 58. Kd3 Kd5 59. Kc3 Ke6 60. Kd4 Kf6 61. Kd3 Ke6 62. Kc3 Kd5 63. Kd3 Ke6 64. Kd4 Kf6 65. Kd3 Ke6 1/2-1/2",
    B: "1889",
  },
  {
    W: "1659",
    T: "300+3",
    P: "1. e4 b6 2. Nc3 Bb7 3. Bd3 g6 4. Nge2 Bg7 5. Ng3 e6 6. O-O Ne7 7. Nce2 O-O 8. c3 d5 9. exd5 Qxd5 10. Be4 Qxe4 11. Nxe4 Bxe4 12. Ng3 Bd3 13. Re1 Nbc6 14. Re3 Ne5 15. Qe1 f6 16. f4 Nd5 17. fxe5 Nxe3 18. Qxe3 Ba6 19. d4 fxe5 20. dxe5 Rad8 21. b3 Rd1+ 22. Nf1 Rfxf1# 0-1",
    B: "1615",
  },
  {
    W: "1479",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3 Bc5 5. Ng5 O-O 6. O-O d6 7. Be2 h6 8. Nf3 Bg4 9. Ne1 Bxe2 10. Qxe2 Nd4 11. Qd3 c6 12. Nf3 Ng4 13. Nxd4 Bxd4 14. Ne2 Qh4 15. Qg3 Qxg3 16. Nxg3 f5 17. Nxf5 Bxf2+ 18. Rxf2 Nxf2 19. d3 Ng4 20. h3 Nf6 21. Ne7+ Kh7 22. Bd2 d5 23. exd5 cxd5 24. Nf5 Rac8 25. c3 Nh5 26. Nd6 Rc7 27. Nb5 Rcf7 28. Nxa7 Rf2 29. Rd1 Ng3 30. Kh2 Nf1+ 31. Kh1 Nxd2 0-1",
    B: "1457",
  },
  {
    W: "1534",
    T: "300+3",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 Nf6 4. d3 e6 5. Be3 b6 6. Nbd2 Bb7 7. O-O Be7 8. h3 O-O 9. Re1 Qc7 10. Bf4 Nc6 11. c3 Na5 12. e5 Nxc4 13. Nxc4 Nd5 14. exd6 Qb8 15. dxe7 Nxf4 16. exf8=Q+ Kxf8 17. Nce5 Qc7 18. d4 Rd8 19. Ng5 Qe7 20. Ngxf7 Rb8 21. Qg4 Qf6 22. Nd7+ Kxf7 23. Nxb8 Nxg2 24. Kf1 Bf3 25. Qg3 Nxe1 26. Kxe1 Qf5 27. dxc5 Qe4+ 28. Kf1 Qe2+ 29. Kg1 Bh5 30. Qf4+ Ke7 31. Nc6+ Ke8 32. Qb8+ Kf7 33. Qc7+ Kg6 34. Ne7+ Kf6 35. Nc6 Bf3 36. Qf4+ Kg6 37. Ne5+ Kh5 38. Qxf3+ Qxf3 39. Nxf3 Kg6 40. Re1 bxc5 41. b4 Kf7 42. bxc5 Ke7 43. Nd4 Kd7 44. Rxe6 a6 45. Rxa6 g6 46. c6+ Kc7 47. Kf1 h5 48. h4 Kb8 49. Ne6 Kc8 50. c7 Kb7 51. Ra4 Kc8 52. Rb4 Kd7 53. Rb8 Kxe6 54. c8=Q+ Kd5 55. a3 Ke4 56. a4 Kf3 57. Qh3+ Ke4 58. Re8+ Kd5 59. Qf3+ Kc4 60. Re4+ Kb3 61. Rb4+ Kc2 62. c4 Kd2 63. Rb2+ Kc1 64. Qe2 g5 65. Qc2# 1-0",
    B: "1587",
  },
  {
    W: "1375",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 d6 3. d4 f6 4. dxe5 fxe5 5. Bc4 h6 6. O-O Nf6 7. Re1 Bg4 8. h3 Bh5 9. Qd3 Nc6 10. a3 Qd7 11. Qb3 Na5 12. Qd3 Nxc4 13. Qxc4 Bf7 14. Qd3 d5 15. Nxe5 Qd6 16. Nxf7 Kxf7 17. Nc3 dxe4 18. Qxd6 cxd6 19. Nxe4 Re8 20. f3 d5 21. Nxf6 Rxe1+ 22. Kf2 Re5 23. Ng4 Re6 24. Be3 h5 25. Nh2 Bd6 26. Nf1 Rhe8 27. Bxa7 Re2+ 28. Kg1 Rxc2 29. Rd1 Ree2 30. Rxd5 Be7 31. Rf5+ Kg6 32. g4 hxg4 33. fxg4 Kh7 34. Rf2 Rxb2 35. Rxe2 Rxe2 36. Ng3 Re1+ 37. Kg2 Bxa3 38. Nf5 b5 39. Kg3 b4 40. Bf2 Rd1 41. Ne7 b3 42. Nd5 0-1",
    B: "1234",
  },
  {
    W: "1193",
    T: "300+3",
    P: "1. c4 e5 2. d4 d6 3. dxe5 dxe5 4. Qxd8+ Kxd8 5. b3 Be6 6. g3 f5 7. Bb2 Nc6 8. Bg2 Bb4+ 9. Nc3 Nf6 10. e3 Ke7 11. Ne2 Rhd8 12. O-O h6 13. Rfd1 Rxd1+ 14. Rxd1 Rd8 15. Rxd8 Nxd8 16. Bxb7 Nxb7 17. Nb5 Kd7 18. Nxa7 g5 19. Nb5 Nc5 20. Kg2 g4 21. h3 h5 22. hxg4 hxg4 23. a3 Ba5 24. b4 Nd3 25. bxa5 Nxb2 26. a6 Nxc4 27. a7 Nb6 28. Nec3 c6 29. Na4 Na8 30. f3 cxb5 31. Nc5+ Kd6 32. Nd3 gxf3+ 33. Kxf3 e4+ 34. Ke2 exd3+ 35. Kxd3 Kc6 36. Kd4 Kb6 37. Ke5 Ng4+ 38. Kxe6 Nxe3 39. Ke5 Kxa7 40. Kf4 Nd5+ 41. Kxf5 Ka6 42. g4 Ne7+ 43. Kf6 Nd5+ 44. Kf7 Ndb6 45. g5 Nd5 46. g6 Nac7 47. g7 b4 48. axb4 Nxb4 49. g8=Q Nbd5 50. Qg3 Nb5 51. Qg5 Ndc3 52. Qg6+ Ka5 53. Ke7 Nd5+ 54. Kd7 Nb6+ 55. Kc6 Nd4+ 56. Kc5 Nf3 57. Qxb6+ Ka4 1-0",
    B: "1189",
  },
  {
    W: "1395",
    T: "300+3",
    P: "1. d4 b6 2. c4 Bb7 3. Nc3 e6 4. Nf3 Nf6 5. Bf4 Bb4 6. a3 Bxc3+ 7. bxc3 Ne4 8. Qc2 Qf6 9. e3 O-O 10. Bd3 d5 11. cxd5 exd5 12. Be5 Qe7 13. Bxe4 dxe4 14. Nd2 f5 15. O-O Nd7 16. Bf4 Nf6 17. Nc4 c5 18. Ne5 cxd4 19. cxd4 Rac8 20. Qd2 Nh5 21. Rfc1 Nxf4 22. exf4 Rxc1+ 23. Rxc1 Rc8 24. Rxc8+ Bxc8 25. Nc6 Qc7 26. Qa2+ Kh8 27. d5 Qxf4 28. h3 e3 29. fxe3 Qxe3+ 30. Kh2 f4 31. d6 Qg3+ 32. Kg1 Bxh3 33. Qe2 Qg6 34. Ne7 Bg4 35. Nxg6+ hxg6 36. Qxg4 1-0",
    B: "1397",
  },
  {
    W: "1789",
    T: "600+0",
    P: "1. e4 e6 2. d4 c5 3. d5 Nf6 4. dxe6 fxe6 5. Bg5 e5 6. Nc3 Be7 7. Bxf6 Bxf6 8. Qh5+ g6 9. Qh6 Bg5 10. Qg7 Qf6 11. Qxf6 Bxf6 12. O-O-O O-O 13. Nf3 Nc6 14. Bc4+ Kg7 15. h4 Nd4 16. Nxd4 cxd4 17. Nd5 a6 18. Nc7 Rb8 19. g4 b5 20. Bb3 Bb7 21. f3 Bd8 22. Nd5 Bxd5 23. Bxd5 Rxf3 24. Rdf1 Rxf1+ 25. Rxf1 Bxh4 26. Rf7+ Kh6 27. Rxd7 Bg5+ 28. Kb1 Rf8 29. b3 Bd2 30. Rd6 Bc3 31. a4 Rf1+ 0-1",
    B: "2043",
  },
  {
    W: "2418",
    T: "60+0",
    P: "1. d4 g6 2. Nf3 Bg7 3. Nc3 d6 4. e4 Nf6 5. h3 O-O 6. Be3 c5 7. d5 a6 8. a4 e5 9. Nd2 Ne8 10. Nc4 f5 11. Be2 f4 12. Bd2 Qg5 13. Kf1 Nd7 14. a5 Ndf6 15. Bf3 Rb8 16. Na4 Qh6 17. Nab6 g5 18. Kg1 Qg6 19. b4 cxb4 20. Bxb4 h5 21. Nxd6 Nxd6 22. Bxd6 g4 23. Bxb8 gxf3 24. Qxf3 Bg4 25. hxg4 hxg4 26. Qb3 Rxb8 27. d6+ Kf8 28. d7 Rd8 29. Qe6 Qf7 30. Qxf7+ Kxf7 31. Rd1 Ke7 32. f3 g3 33. Kf1 Ke6 34. Ke2 Bf8 35. c4 Bb4 36. Ra1 Nxd7 37. Rhd1 Ke7 38. Nxd7 Rxd7 39. Rxd7+ Kxd7 40. Kd3 Kc6 41. Kc2 Kc5 42. Kb3 Bd2 43. Rh1 Kd4 44. Rh7 Ke3 45. Rxb7 Kf2 46. Rb6 Kxg2 47. Rxa6 Kxf3 48. Rg6 Be1 49. a6 g2 50. a7 Bg3 51. a8=Q g1=Q 52. Qa2 Qd1+ 53. Qc2 Qxc2+ 54. Kxc2 Kxe4 55. c5 Bf2 56. c6 Bb6 57. Rg7 f3 58. c7 Bxc7 0-1",
    B: "2438",
  },
  {
    W: "2416",
    T: "30+0",
    P: "1. d4 g6 2. Nf3 Bg7 3. Nc3 d6 4. e4 Nd7 5. Bg5 h5 6. h4 Ngf6 7. e5 dxe5 8. dxe5 Ng4 9. e6 fxe6 10. Bc4 Nde5 11. Nxe5 Qxd1+ 12. Rxd1 Nxe5 13. Be2 O-O 14. O-O c5 15. f4 Ng4 16. Rfe1 Bd4+ 17. Kh1 Nf2+ 18. Kh2 Ng4+ 19. Bxg4 hxg4 20. Bxe7 Rf7 21. Bg5 e5 22. fxe5 Bf5 23. Ne4 Bxe5+ 24. g3 Re8 25. Nf6+ Kg7 26. Nxe8+ Kh7 27. Nd6 Rd7 28. Nxf5 Rxd1 29. Rxd1 gxf5 30. Bf4 Bf6 31. Rd7+ Kg8 32. Rd6 Bxb2 33. Rd7 Bd4 34. Rxb7 c4 35. Rb4 Bc5 36. Rxc4 Bb6 37. Rc8+ Bd8 38. Bc7 Kf7 39. Bxd8 Ke6 40. Ba5 f4 41. Ra8 fxg3+ 42. Kxg3 Kf5 43. h5 Ke4 44. h6 Kf5 45. Rxa7 Kg5 46. Rg7+ 1-0",
    B: "2365",
  },
  {
    W: "1599",
    T: "600+0",
    P: "1. d4 g6 2. e4 Bg7 3. c4 b6 4. Nc3 Bb7 5. f4 Nc6 6. Nf3 e6 7. Be3 Nge7 8. Bd3 O-O 9. a3 Re8 10. f5 Na5 11. fxg6 fxg6 12. Ne5 d6 13. Ng4 Nec6 14. Nh6+ Bxh6 15. Bxh6 Qh4+ 0-1",
    B: "1680",
  },
  {
    W: "2148",
    T: "60+0",
    P: "1. e4 f5 2. e5 e6 3. f4 d6 4. d4 dxe5 5. fxe5 c5 6. Nf3 cxd4 7. Bb5+ Bd7 8. Bxd7+ Nxd7 9. Qxd4 Bc5 10. Qc4 Qb6 11. Nc3 Rc8 12. Na4 Bf2+ 13. Ke2 Rxc4 14. Nxb6 Bxb6 15. c3 Re4+ 16. Kd3 Nxe5+ 17. Kc2 Nxf3 18. gxf3 Re2+ 19. Kb3 Nf6 20. Bg5 Kf7 21. h4 h6 22. Bf4 e5 23. Bg3 f4 24. Be1 Rd8 25. h5 Rd5 26. a4 a5 27. Bh4 Nd7 28. Rhg1 Nc5+ 29. Ka3 Rdd2 30. Rab1 Nd3 31. Rg6 Bc5+ 32. Kb3 Rxb2+ 33. Rxb2 Rxb2+ 34. Kc4 Rd2 35. Be1 Rd1 36. Bh4 Bf8 37. Rb6 Ne1 38. Rxb7+ Ke8 39. Rb8+ Kf7 40. Rb7+ Ke6 41. Rb8 Bd6 42. Rb6 Nxf3 43. Bd8 Ng5 0-1",
    B: "2502",
  },
  {
    W: "1819",
    T: "60+0",
    P: "1. d4 d5 2. c4 dxc4 3. Bf4 Nc6 4. d5 Nb8 5. e4 c6 6. Bxc4 cxd5 7. Bxd5 Nf6 8. Bxf7+ Kxf7 9. Qxd8 Nc6 10. Qd1 Bg4 11. f3 Bh5 12. Qb3+ e6 13. Qxb7+ Ne7 14. Qxa8 1-0",
    B: "1799",
  },
  {
    W: "1949",
    T: "60+0",
    P: "1. e4 e5 2. d4 exd4 3. c3 c5 4. Nf3 Nc6 5. Bc4 d6 6. O-O Nf6 7. Qb3 Qc7 8. Ng5 Na5 9. Bxf7+ Ke7 10. Qd1 h6 11. b4 hxg5 12. bxa5 Kxf7 13. Re1 Bg4 14. f3 Be6 15. cxd4 cxd4 16. Qxd4 Rd8 17. Nc3 Be7 18. Ba3 Qb8 19. Rad1 Rd7 20. Qe3 Nh5 21. g4 Nf4 22. e5 d5 23. Bc1 d4 24. Rxd4 Rhd8 25. Rxd7 Rxd7 26. Ne4 Qxe5 27. Qxa7 Qd4+ 28. Qxd4 Rxd4 29. Bb2 Ra4 30. a3 Nd3 0-1",
    B: "1916",
  },
  {
    W: "1777",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Nf5 d6 6. Bd3 Bxf5 7. exf5 Nf6 8. O-O d5 9. Re1 e4 10. f3 Bc5+ 11. Kh1 O-O 12. fxe4 dxe4 13. Be2 Nd4 14. c3 Nxe2 15. Qxe2 Re8 16. Nd2 e3 17. Nb3 Bb6 18. Nd4 Ne4 19. Bxe3 Qf6 20. Qf3 Bxd4 21. Bxd4 Ng5 22. Rxe8+ Rxe8 23. Qxb7 Qe6 24. fxe6 Nxe6 25. Qd7 Kf8 26. Re1 1-0",
    B: "1776",
  },
  {
    W: "2215",
    T: "60+0",
    P: "1. e4 e6 2. d3 d5 3. Nd2 dxe4 4. dxe4 Nf6 5. Ngf3 Bc5 6. Bd3 Nc6 7. a3 O-O 8. O-O b6 9. h3 a5 10. Qe2 Nd4 11. Nxd4 Bxd4 12. Nf3 Bc5 13. Bg5 Be7 14. e5 Nd5 15. Bxe7 Qxe7 16. c4 Nf4 17. Qe4 Nxd3 18. Qxa8 Bd7 19. Qe4 Nc5 20. Qe3 Bc6 21. b4 Ne4 22. Nd4 Ba8 23. f3 Ng5 24. bxa5 bxa5 25. Nb5 h5 26. Nxc7 Qxc7 27. Qxg5 Qxc4 28. Qe3 Qh4 29. Rfc1 Rd8 30. Rd1 Bd5 31. Rac1 Qg3 32. Rd4 Kh7 33. Rcd1 g6 34. Qe1 Qg5 35. f4 Qf5 36. Qf2 Rc8 37. R1d2 Rc1+ 38. Kh2 Qb1 39. Kg3 Rh1 40. Kh4 Qe1 41. Qxe1 Rxe1 42. g4 Re4 43. Rxe4 Bxe4 44. gxh5 gxh5 45. Kxh5 Bg6+ 46. Kg5 Bf5 47. Kf6 Kh6 48. Rg2 Kh5 49. Rg5+ Kh4 50. Rxf5 Kxh3 51. Kxf7 exf5 52. Kf6 Kg3 53. e6 Kxf4 54. e7 Ke4 55. e8=Q+ Kf3 56. Qc6+ Kg4 0-1",
    B: "2382",
  },
  {
    W: "1994",
    T: "60+0",
    P: "1. c4 e5 2. g3 Nc6 3. Bg2 Nf6 4. Nc3 Bc5 5. e3 d6 6. Nge2 Bg4 7. h3 Bh5 8. d4 exd4 9. exd4 Bb6 10. O-O Ba5 11. a3 Bxc3 12. bxc3 Bg6 13. Re1 Qd7 14. Nf4+ Ne7 15. Nd5 Nxd5 16. Bxd5 O-O 17. Bxb7 Rab8 18. Bg2 Nf5 19. Bf4 Qc6 20. Bxc6 Rb6 21. d5 h5 22. Qe2 h4 23. g4 Nh6 24. Bxh6 gxh6 25. f4 Kg7 26. f5 Bh5 27. gxh5 Kh7 28. Kh2 Rg8 29. Qe7 Rb2+ 30. Kh1 Rgg2 31. Qxf7+ Rg7 32. Qxg7+ Kxg7 33. Rg1+ Kf6 34. Raf1 Rb3 35. Rg6+ Ke5 36. f6 Rxc3 37. f7 Rxh3+ 38. Kg2 Kd4 39. f8=Q Kc5 40. Qf2+ Kxc4 41. Rc1+ Kb3 42. Qc2+ Kxa3 43. Ra1+ Kb4 44. Rb1+ Rb3 45. Qd2+ Kc5 46. Qc2+ Kd4 47. Qxb3 Ke5 48. Re1+ Kf5 0-1",
    B: "1967",
  },
  {
    W: "1633",
    T: "60+0",
    P: "1. Nf3 c6 2. c4 d5 3. Nc3 dxc4 4. e3 e6 5. d4 Nf6 6. Be2 Be7 7. b3 O-O 8. bxc4 Qc7 9. e4 b6 10. e5 Nfd7 11. O-O c5 12. dxc5 Nxc5 13. Bb2 Bb7 14. Qc2 Nbd7 15. Rad1 Rac8 16. Rfe1 Rfd8 17. Kf1 Nxe5 18. Nxe5 Qxe5 19. Bf3 Qc7 20. Bxb7 Qxb7 21. Rxd8+ Rxd8 22. Ne4 Qc7 23. Nxc5 Bxc5 24. a3 Rc8 25. Qc3 Bf8 26. a4 Qxc4+ 27. Qxc4 Rxc4 28. h4 Rxa4 29. g3 Rb4 30. Bc1 Rb1 31. Bd2 Rxe1+ 32. Bxe1 Bc5 33. f4 a5 34. g4 Kf8 35. Bc3 Ke7 36. g5 f6 37. Ke2 fxg5 38. hxg5 g6 39. Kd3 Kd6 40. Kc4 Kc6 41. Be5 b5+ 42. Kb3 a4+ 43. Ka2 Kd5 44. Bc3 b4 45. Bd2 a3 46. Kb3 Ke4 47. Be1 e5 48. Bg3 exf4 49. Bxf4 Kxf4 50. Kc4 Kxg5 51. Kxc5 a2 52. Kxb4 a1=Q 53. Kc4 Qf6 54. Kd3 Qh8 55. Ke2 h6 56. Kf1 h5 57. Kg1 Kh6 58. Kh1 g5 59. Kh2 h4 60. Kh3 Qe5 61. Kg4 Qf4+ 62. Kh3 Kh5 0-1",
    B: "1675",
  },
  {
    W: "1655",
    T: "60+0",
    P: "1. f4 Nf6 2. Nf3 d5 3. e3 c5 4. b3 Nc6 5. Bb2 g6 6. Bb5 Bg7 7. O-O O-O 8. Bxc6 bxc6 9. Ne5 Qc7 10. d3 Be6 11. Nd2 Rab8 12. c4 dxc4 13. Ndxc4 Bxc4 14. Nxc4 Rfd8 15. Qe2 Qd7 16. Rad1 Nd5 17. Bxg7 Kxg7 18. Ne5 Qe6 19. e4 Nxf4 20. Rxf4 Qxe5 21. Qf3 f6 22. Rf1 Rd4 23. h4 Rbd8 24. h5 Rxd3 25. Qg4 Qd4+ 26. Kh2 Qe5 27. Kh1 Rd2 28. hxg6 hxg6 29. R1f3 Rh8+ 30. Rh3 Rxh3+ 31. gxh3 c4 32. bxc4 c5 33. Rf1 Qh2# 0-1",
    B: "1673",
  },
  {
    W: "1376",
    T: "60+0",
    P: "1. e3 e5 2. d4 Nc6 3. dxe5 Nxe5 4. Nf3 Nxf3+ 5. Qxf3 Nf6 6. Bc4 d5 7. Bb3 Bb4+ 8. c3 Bd6 9. O-O c6 10. e4 dxe4 11. Re1 Be6 12. Bxe6 fxe6 13. Bg5 exf3 14. Nd2 fxg2 15. Kxg2 O-O 16. Rxe6 Re8 17. Ree1 Rxe1 18. Rxe1 Bc5 19. Ne4 Qf8 20. b4 Be7 21. Nxf6+ Bxf6 22. Bxf6 Qxf6 23. Kh1 Rf8 24. f3 Qxf3+ 25. Kg1 Qf2+ 26. Kh1 Qxe1+ 27. Kg2 Qf2+ 28. Kh3 Rf3+ 29. Kg4 Qg2+ 30. Kh5 Rh3# 0-1",
    B: "1500",
  },
  {
    W: "1540",
    T: "60+0",
    P: "1. e4 e5 2. Nc3 d6 3. Nf3 Nf6 4. d3 Bg4 5. Bg5 Be7 6. Bxf6 Bxf6 7. Be2 c5 8. Ng1 Bxe2 9. Qxe2 Nc6 10. Nf3 Nd4 11. Nxd4 cxd4 12. Nd5 O-O 13. Nxf6+ Qxf6 14. O-O a6 15. c3 dxc3 16. bxc3 b5 17. d4 exd4 18. cxd4 Qxd4 19. Rad1 Qe5 20. Rd3 f5 21. Rfd1 Rfd8 22. Rf3 fxe4 23. Re3 d5 24. Rg3 h6 25. Rh3 Kh8 26. Rh5 Qf6 27. Rhxd5 Rxd5 28. Rxd5 Re8 29. g3 Qa1+ 30. Kg2 Re6 31. Rd8+ Kh7 32. Qg4 Qf6 33. Qh4 Qxh4 34. gxh4 Kg6 35. Kf1 Kh5 36. Ke2 Kxh4 37. f3 exf3+ 38. Kxf3 g5 39. Rd4+ Kh3 40. Rd2 Rf6+ 41. Ke3 Re6+ 42. Kf3 Rf6+ 43. Ke4 1-0",
    B: "1568",
  },
  {
    W: "1524",
    T: "60+0",
    P: "1. e4 c5 2. d4 Nc6 3. dxc5 e5 4. a3 Bxc5 5. b4 Bb6 6. Bb2 Nf6 7. Bc4 O-O 8. f3 d6 9. Ne2 Be6 10. Bxe6 fxe6 11. Nd2 d5 12. c4 d4 13. c5 Bc7 14. Nc4 Rc8 15. O-O h6 16. f4 exf4 17. Nxf4 e5 18. Ne6 Qe7 19. Nxf8 Rxf8 20. Qe2 Qf7 21. Nd2 Qg6 22. Rf2 Nxe4 23. Nf3 Nxf2 24. Qxf2 Qc2 25. Qxc2 e4 26. Qxe4 d3 27. Qe6+ Kh8 28. Bxg7+ Kxg7 29. Qg4+ Kh7 30. Nh4 Rg8 31. Qf5+ Kh8 32. Qf6+ Rg7 0-1",
    B: "1532",
  },
  {
    W: "1318",
    T: "120+1",
    P: "1. c4 e5 2. Nc3 Nc6 3. e3 Nf6 4. d4 exd4 5. exd4 Bb4 6. d5 Ne5 7. Bd2 Ng6 8. a3 Bxc3 9. Bxc3 d6 10. Bd4 Bg4 11. Qd3 O-O 12. Qg3 c6 13. Bxf6 Qxf6 14. Qxg4 Qxb2 15. Qd1 Qc3+ 16. Qd2 Qxa1+ 0-1",
    B: "1265",
  },
  {
    W: "1085",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. d4 exd4 5. Nxd4 Ne5 6. Bd2 Nxc4 7. Bc3 Bc5 8. O-O Nf6 9. Nf5 O-O 10. b3 Nb6 11. b4 Bd6 12. Nxd6 cxd6 13. Qxd6 Nc4 14. Qd4 Nb6 15. b5 Re8 16. f3 d6 17. e5 Be6 18. exf6 Qxf6 19. Qxf6 gxf6 20. Re1 Bc4 21. Rxe8+ Rxe8 22. Bxf6 Re1+ 23. Kf2 Rc1 24. Na3 Rxc2+ 25. Nxc2 Bd5 26. Bd4 Nc4 27. Ne3 Ne5 28. Nxd5 Nd3+ 29. Ke3 b6 30. Nc7 Kh7 31. Rc1 Kg6 32. Rc3 Kf5 33. Rxd3 Kg6 34. Bc3 Kh7 35. f4 Kg8 36. Nd5 Kf8 37. Kd4 Ke8 38. f5 Kd8 39. Bb4 Ke8 40. Bxd6 Kd7 41. Bb8 Kc8 42. Bxa7 Kb7 43. Bxb6 Ka8 44. Rh3 Kb7 45. Rxh6 Kc8 46. Rh7 Kd7 47. Rxf7+ Kd6 48. h3 1/2-1/2",
    B: "1140",
  },
  {
    W: "1319",
    T: "120+1",
    P: "1. e4 c6 2. Nf3 a5 3. Bc4 b5 4. Bd3 e6 5. a3 Nf6 6. c3 Ng4 7. h3 Nf6 8. Bc2 Nh5 9. d3 Bb7 10. Ne5 Nf6 11. Bg5 Be7 12. O-O d6 13. Nf3 Nbd7 14. Nbd2 O-O 15. d4 Nh5 16. Bxe7 Qxe7 17. Ne1 Nf4 18. Qf3 e5 19. d5 cxd5 20. exd5 Bxd5 21. Qg3 Nf6 0-1",
    B: "1358",
  },
  {
    W: "1926",
    T: "120+1",
    P: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. e3 c5 5. Nf3 cxd4 6. Nxd4 Bb4 7. Bd2 Bxc3 8. Bxc3 Ne4 9. Bb4 a5 10. Ba3 Qf6 11. Qf3 Nc6 12. Qxf6 Nxf6 13. Nxc6 bxc6 14. O-O-O Ba6 15. cxd5 cxd5 16. Bxa6 Rxa6 17. f3 Kd7 18. Rd2 Rc8+ 19. Rc2 Rxc2+ 20. Kxc2 Rc6+ 21. Kd2 Ne8 22. Rc1 Rxc1 23. Kxc1 Nc7 24. Kd2 Kc6 25. Bf8 g6 26. g4 Kb5 27. h4 Kc4 28. b3+ Kb5 29. Bd6 Ne8 30. Be7 Nc7 31. Kc3 Na6 32. a4+ Kc6 33. Bd8 Nc7 34. Kd4 Kd7 35. Bxc7 Kxc7 36. Kc5 Kb7 37. Kb5 e5 38. Kxa5 d4 39. exd4 exd4 40. Kb4 d3 41. Kc3 d2 42. Kxd2 1-0",
    B: "1722",
  },
  {
    W: "1409",
    T: "120+1",
    P: "1. e4 e6 2. d4 b6 3. Nc3 Bb7 4. Nf3 h6 5. Bd3 Ne7 6. O-O g5 7. Re1 f6 8. Be2 g4 9. Nh4 h5 10. d5 exd5 11. exd5 d6 12. Bd3 Qd7 13. Nf5 Na6 14. Bb5 c6 15. dxc6 Bxc6 16. Bxa6 Qxf5 17. Be3 Bg7 18. Bd4 Qa5 19. Bc4 d5 20. Bb3 O-O-O 21. Qd3 Rde8 22. a3 Rhg8 23. Re6 Kc7 24. Rae1 Bf8 25. Bxf6 Rg6 26. Rxe7+ Rxe7 27. Rxe7+ Bxe7 28. Qxg6 Qc5 29. Bxe7 Kb7 30. Bxc5 bxc5 1-0",
    B: "1409",
  },
  {
    W: "1605",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. O-O a6 5. Nc3 Nxe4 6. Nxe4 d5 7. Bxd5 Qxd5 8. Nc3 Qd6 9. Re1 Be7 10. d3 O-O 11. Ne4 Qg6 12. Ng3 Re8 13. Nxe5 Nxe5 14. Rxe5 Be6 15. Re1 Rad8 16. Qf3 Bd6 17. Qxb7 Bxg3 18. hxg3 Bd5 19. Be3 Bxb7 0-1",
    B: "1500",
  },
  {
    W: "2182",
    T: "120+1",
    P: "1. Nf3 d5 2. d4 c5 3. dxc5 Nc6 4. c3 e5 5. b4 Bg4 6. b5 Bxf3 7. bxc6 Be4 8. cxb7 Rb8 9. Qa4+ Ke7 10. c6 f5 11. Nd2 Kf7 12. f3 Bd6 13. fxe4 fxe4 14. Rb1 e3 15. Nf3 e4 16. Nd4 Qh4+ 17. g3 Bxg3+ 18. hxg3 Qxh1 19. c7 Nf6 20. cxb8=Q Rxb8 21. Qxa7 Nd7 22. Qa5 Qg1 23. Qxd5+ Ke8 24. Qe6+ Kd8 25. Qg8+ Kc7 26. Qc4+ Kd8 27. Nc6+ Ke8 28. Qe6+ Kf8 29. Ba3+ 1-0",
    B: "2155",
  },
  {
    W: "1243",
    T: "120+1",
    P: "1. e4 c5 2. Nf3 a6 3. Bc4 e6 4. O-O Qc7 5. Nc3 Nc6 6. d4 cxd4 7. Nxd4 Nf6 8. Nxc6 bxc6 9. f3 Bc5+ 10. Kh1 d5 11. exd5 cxd5 12. Bb3 O-O 13. Na4 Ba7 14. a3 Bb8 15. g3 Bd7 16. Nc3 h6 17. Bf4 Qb7 18. Bxb8 Raxb8 19. f4 d4+ 20. Qf3 Qxf3+ 21. Rxf3 dxc3 22. Rxc3 Rfc8 23. Rd3 Bb5 24. c4 Bxc4 25. Bxc4 Rxc4 26. Rad1 Nd5 27. f5 Rxb2 28. fxe6 fxe6 29. Re1 Rcc2 30. Rxe6 Rxh2+ 31. Kg1 Rbg2+ 32. Kf1 Nf6 33. Rd8+ Kh7 34. Rde8 Ra2 35. Re1 Rxa3 36. R8e2 Rxe2 37. Rxe2 Rxg3 38. Rg2 Rxg2 39. Kxg2 a5 40. Kg3 a4 41. Kf2 a3 42. Ke2 a2 43. Kd2 a1=Q 44. Kd3 Qe1 45. Kd4 g5 0-1",
    B: "1241",
  },
  {
    W: "2004",
    T: "60+0",
    P: "1. e4 d6 2. Nc3 c6 3. Bc4 Nf6 4. Qf3 e5 5. Nge2 Be7 6. h3 O-O 7. O-O h6 8. d3 d5 9. Qg3 dxc4 10. Bxh6 Nh5 11. Qg4 Bxg4 0-1",
    B: "2137",
  },
  {
    W: "1400",
    T: "15+0",
    P: "1. e4 d5 2. Be2 Bg4 3. Bxg4 dxe4 4. Bd7+ Nxd7 5. Nf3 Ngf6 6. Ne5 Nxe5 7. d4 Ned7 8. d5 e6 9. dxe6 Be7 10. exd7+ Qxd7 11. Qxd7+ Nxd7 12. Bg5 Rd8 13. Bxe7 Kxe7 14. Nc3 Rhe8 15. Nxe4 Kf8 16. O-O-O Rxe4 17. Rhe1 Rxe1 18. Rxe1 Nf6 19. Rd1 Rxd1+ 20. Kxd1 Nd7 21. f3 c6 22. g3 f6 23. f4 Ke7 24. g4 f5 25. h3 Nf6 26. g5 Ng4 27. h4 h5 28. g6 Nh6 0-1",
    B: "2024",
  },
  {
    W: "1572",
    T: "15+0",
    P: "1. e3 g6 2. d4 Bg7 3. Nf3 c5 4. Bc4 cxd4 5. exd4 d5 6. Bb3 Bd7 7. c3 Bf5 8. Nbd2 Bc2 9. Qxc2 Bh6 10. Ba4+ Kf8 11. Bb3 Bg7 12. O-O Bf6 13. Re1 Bg5 14. Rxe7 Bxd2 15. Rxf7+ Ke8 16. Qxd2 Qe7 17. Qg5 Qxf7 18. Qxd5 Qxf3 19. Qxb7 Qxf2+ 20. Kxf2 Nd7 21. Kg1 Rb8 22. Qxb8+ Nxb8 23. Bf7+ Kd8 24. Bg5+ Kc8 25. Bf6 Nd7 26. Bg7 Ndf6 27. Bxh8 Ne4 28. Bg7 Nd2 0-1",
    B: "1834",
  },
  {
    W: "2158",
    T: "15+0",
    P: "1. Nf3 d5 2. d3 e5 3. e4 dxe4 4. Nxe5 Nc6 5. Qh5 Nxe5 6. Qxe5+ Qe7 7. Qxe4 Qxe4+ 8. dxe4 Nf6 9. Bb5+ c6 10. Bd3 Bd6 11. O-O Be5 12. f4 Bd6 13. e5 Nd5 14. Kh1 O-O 15. e6 Bc5 16. exf7+ Rxf7 17. Nc3 Nxf4 18. Bc4 Nxg2 19. Bxf7+ Kh8 20. Kxg2 Bh3+ 21. Kxh3 Rf8 22. Kg2 1-0",
    B: "1857",
  },
  {
    W: "1565",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3 d6 5. O-O Bg4 6. h3 a6 7. Bxc6+ bxc6 8. hxg4 Nf6 9. d4 Bb6 10. Nbd2 exd4 11. cxd4 O-O 12. Re1 Nxg4 13. Nf1 Qh4 14. Nxh4 1-0",
    B: "1097",
  },
  {
    W: "1876",
    T: "60+0",
    P: "1. d4 d5 2. Nc3 e6 3. Bg5 Nf6 4. h4 Be7 5. Qd3 h6 6. O-O-O b6 7. f4 Nbd7 8. Bxf6 Nxf6 9. e4 dxe4 10. Nxe4 Bb7 11. Nxf6+ Bxf6 12. Nf3 Qd7 13. Ne5 Bxe5 14. fxe5 O-O-O 15. Qd2 a5 16. a4 Qxa4 17. Qf4 g5 18. hxg5 hxg5 19. Rxh8 gxf4 20. Rxd8+ Kxd8 21. d5 exd5 22. Kb1 c6 23. g3 fxg3 24. Bh3 Bc8 25. Bg2 Bf5 26. Bxd5 Qxc2+ 27. Ka2 Qxd1 28. Bxc6 Be6+ 29. b3 g2 30. Bd5 g1=Q 31. Bxe6 Qg2+ 32. Ka3 fxe6 33. Ka4 Qgd5 34. Ka3 Qa1# 0-1",
    B: "1802",
  },
  {
    W: "1130",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 Nxd4 4. Nxd4 exd4 5. Qxd4 d6 6. Nc3 c5 7. Bb5+ Bd7 8. Bxd7+ Qxd7 9. Qd5 Nf6 10. Qd3 O-O-O 11. O-O Re8 12. Re1 d5 13. Nxd5 Nxd5 14. Qxd5 Qxd5 15. exd5 Rxe1# 0-1",
    B: "883",
  },
  {
    W: "1375",
    T: "60+0",
    P: "1. e4 e5 2. Nc3 Bc5 3. Nf3 Qf6 4. d3 Ne7 5. Be3 h6 6. Bxc5 b6 7. Bxe7 Qxe7 8. d4 Nc6 9. dxe5 Nxe5 10. Nxe5 Qxe5 11. Bc4 d6 12. O-O c6 13. f4 Qh5 14. e5 Qxd1 15. Raxd1 b5 16. Bd3 dxe5 17. fxe5 Be6 18. Bf5 O-O 19. Bxe6 fxe6 20. Rxf8+ Rxf8 21. a4 b4 22. Ne4 a5 23. Nd6 Rd8 24. Rf1 Kh7 25. Rf7 Kg6 26. Ra7 Kg5 27. Rxa5 Kf4 28. Kf2 g5 29. g3+ Kg4 30. h3+ Kxh3 31. Kf3 Rf8+ 32. Ke4 g4 33. Ra7 Kxg3 34. Rg7 Kh3 35. Nf7 Kg3 36. Nxh6 Rf4+ 37. Ke3 Rf3+ 38. Ke4 Rf4+ 39. Kd3 Rf3+ 40. Kc4 Rf4+ 41. Kc5 1-0",
    B: "1380",
  },
  {
    W: "1386",
    T: "60+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. d4 d6 4. c3 Nf6 5. Be2 O-O 6. O-O Nbd7 7. e5 dxe5 8. dxe5 Ng4 9. Bf4 Ndxe5 10. Nxe5 Nxe5 11. Qd2 Qe8 12. Bh6 e6 13. Bxg7 Kxg7 14. Qd4 f6 15. Re1 Nc6 16. Qe3 Qf7 17. Bf3 Re8 18. Nd2 e5 19. Bxc6 bxc6 20. Nc4 Qxc4 21. Qe2 Qxe2 22. Rxe2 Rb8 23. Rae1 Rxb2 24. Rxb2 Bf5 25. Rbe2 Rd8 26. c4 Rd4 27. a3 Rxc4 28. Re3 Ra4 29. h3 Kf7 30. g4 Be6 31. Kg2 Bd5+ 32. f3 a6 33. Kg3 Rc4 34. R1e2 1-0",
    B: "1440",
  },
  {
    W: "1461",
    T: "60+0",
    P: "1. d4 d5 2. c4 Nc6 3. Nc3 dxc4 4. e4 Qxd4 5. Qxd4 Nxd4 6. Bxc4 Nc2+ 7. Kd2 Nxa1 8. Nf3 e6 9. b3 a6 10. Bb2 Nxb3+ 11. axb3 b5 12. Be2 Bb7 13. Rd1 O-O-O+ 14. Kc2 Bb4 15. Rxd8+ Kxd8 16. e5 Ne7 17. Bd3 Nd5 18. Nxd5 exd5 19. Nd2 Re8 20. f3 c5 21. f4 d4 22. g3 f6 23. Nb1 fxe5 24. fxe5 Rxe5 25. Ba3 Bxa3 26. Nxa3 b4 27. Nc4 Rf5 28. Kd2 Rf2+ 29. Be2 Rxh2 30. Kd3 Rh3 31. Bg4 Rxg3+ 32. Ke2 Rxg4 33. Kd1 Bf3+ 34. Kc2 Rg3 35. Kd2 Bd5 36. Kc1 Bxc4 37. Kc2 Bxb3+ 38. Kd2 Ba4 39. Kc1 h5 40. Kd2 h4 0-1",
    B: "1542",
  },
  {
    W: "1322",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 Bb4 5. Bd2 Bxc3 6. Bxc3 exd4 7. Nxd4 Nxd4 8. Qxd4 Nxe4 9. Qxe4+ Qe7 10. Qxe7+ Kxe7 11. O-O-O Re8 12. Re1+ Kf8 13. Rxe8+ Kxe8 14. Bd3 d6 15. Re1+ Kf8 16. Bxh7 Bg4 17. f3 Bh5 18. Re4 g6 19. Bf6 Re8 20. Rxe8+ Kxe8 21. Bc3 Kf8 22. Kd2 Ke8 23. g4 Bxg4 24. fxg4 Kf8 25. h4 c6 26. h5 d5 27. hxg6 fxg6 28. Ke3 Kf7 29. Kf4 c5 30. Kg5 d4 31. Ba5 Kg7 32. Bxg6 b6 33. Be4 bxa5 34. Kf4 Kf7 35. g5 Ke7 36. Kf5 Kf7 37. g6+ Kg7 38. Kg5 c4 39. Kf5 a6 40. Ke5 d3 41. Kd4 d2 42. Bf3 d1=Q+ 43. Bxd1 Kxg6 44. Kxc4 Kf5 45. Kc5 Ke4 46. Kb6 Kd4 47. Kxa6 Ke3 48. Kxa5 Kd2 49. b4 Kxd1 50. b5 Kxc2 51. b6 Kc3 52. b7 Kb2 53. b8=Q+ Kxa2 54. Qb4 Ka1 55. Ka4 Ka2 56. Qb3+ 1-0",
    B: "1163",
  },
  {
    W: "1623",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. O-O Nf6 5. Nc3 Bc5 6. b4 Bxb4 7. a3 Bc5 8. Nd5 O-O 9. c3 a6 10. d4 exd4 11. cxd4 Ba7 12. e5 Nxd5 13. Bxd5 d6 14. exd6 Qxd6 15. Bb3 Bg4 16. Bb2 Bxf3 17. Qxf3 Bxd4 18. Rad1 Qf6 19. Bxd4 Qxf3 20. gxf3 Nxd4 21. Rxd4 Rad8 22. Rfd1 Rxd4 23. Rxd4 Re8 24. Rd7 Re1+ 25. Kg2 Rb1 26. Bxf7+ Kh8 27. Be6 Rb6 28. Bf5 Rf6 29. Rd8+ Rf8 30. Rxf8# 1-0",
    B: "1636",
  },
  {
    W: "2004",
    T: "60+0",
    P: "1. d4 g6 2. c4 Bg7 3. Nf3 c5 4. e3 cxd4 5. Nxd4 d6 6. Be2 Nf6 7. Bf3 O-O 8. O-O e5 9. Ne2 e4 10. Nd4 exf3 11. Qxf3 Nc6 12. Nb3 Ne5 13. Qe2 Be6 14. Bd2 Nxc4 15. Nc3 Nxd2 16. Qxd2 Bxb3 17. axb3 a6 18. Rfd1 d5 19. Na4 b5 20. Nc5 Qc7 21. Rac1 Qb6 22. b4 Rac8 23. h3 a5 24. Qd4 axb4 25. Qxb4 Ne4 26. Nd7 Rxc1 27. Rxc1 Qe6 28. Nxf8 Bxf8 29. Qxb5 Bd6 30. Qe2 Qe5 31. g3 Qxb2 32. Qxb2 Bf8 33. Rc8 Nd6 34. Ra8 h5 35. Qb8 Kh7 36. Qxf8 Nf5 37. Qxf7+ Kh6 38. Qxf5 gxf5 39. Rd8 Kg6 40. Rxd5 Kg5 41. Rd6 h4 42. Rd8 hxg3 43. fxg3 f4 44. Rg8+ Kf5 45. exf4 Ke4 46. Rh8 Kf5 47. Rh5+ Kg6 48. g4 Kg7 49. Kg2 Kg6 50. Kf3 Kg7 51. Ra5 Kg6 52. Ke4 Kg7 53. Ke5 Kg6 54. Ra6+ Kg7 55. Kf5 Kh7 56. Kg5 Kg7 57. Ra7+ Kh8 58. Kf5 Kg8 59. Kf6 Kh8 60. Kg6 Kg8 61. Ra8# 1-0",
    B: "2145",
  },
  {
    W: "2243",
    T: "60+0",
    P: "1. d4 b6 2. g3 Bb7 3. Nf3 e6 4. Bg2 h5 5. h4 Be7 6. O-O Nf6 7. c4 c5 8. dxc5 bxc5 9. Nc3 O-O 10. a3 d5 11. cxd5 exd5 12. b4 cxb4 13. axb4 Bxb4 14. Qb3 Bxc3 15. Qxc3 Ne4 16. Qb3 Qb6 17. Rb1 Qxb3 18. Rxb3 Ba6 19. Nd4 Nd7 20. f3 Nec5 21. Rc3 Rfe8 22. Bf4 Bxe2 23. Nxe2 Rxe2 24. Bd6 Na4 25. Rc7 Ndb6 26. f4 Rae8 27. Rxa7 Rb2 28. Bc7 Ree2 29. Bxb6 Rxg2+ 0-1",
    B: "2342",
  },
  {
    W: "1721",
    T: "60+0",
    P: "1. e3 Nc6 2. e4 Nf6 3. d3 e5 4. f4 exf4 5. Bxf4 d5 6. exd5 Nxd5 7. Bd2 f5 8. Nc3 f4 9. Nxd5 Qxd5 10. Bxf4 Nd4 11. Bd2 Qc5 12. Bc3 Nb5 13. Qd2 Rg8 14. Nf3 Be7 15. O-O-O Bf6 16. Bxf6 gxf6 17. Re1+ Kf7 18. Qc3 Nxc3 19. bxc3 Be6 20. c4 Qa5 21. Be2 Qxa2 22. Kd2 a5 23. Ra1 Qb2 24. Rhb1 Qxa1 25. Rxa1 a4 26. Kc3 a3 27. Kb3 c6 28. Rxa3 Rxa3+ 29. Kxa3 Ra8+ 30. Kb3 b6 31. Nd4 b5 32. Nxc6 bxc4+ 33. dxc4 Bxc4+ 34. Bxc4+ Ke8 35. Nd4 Rc8 36. Kc3 Kd7 37. Kd3 Kd6 38. Nb5+ Kd7 39. Nc3 Kc6 40. Ne4 Kd7 41. Bb3 1-0",
    B: "1644",
  },
  {
    W: "2198",
    T: "60+0",
    P: "1. Nf3 e6 2. g3 d5 3. Bg2 Be7 4. O-O Nf6 5. d4 O-O 6. c4 b6 7. Nc3 Ba6 8. cxd5 exd5 9. Qa4 Bb7 10. Ne5 Nbd7 11. Rd1 Nxe5 12. dxe5 Nd7 13. Nxd5 Nxe5 14. Nxe7+ Qxe7 15. Bxb7 Rad8 16. Bg2 Rxd1+ 17. Qxd1 Nc4 18. Qc2 Rd8 19. b3 Ne5 20. Bb2 Ng4 21. Rd1 Re8 22. e3 Ne5 23. Qe4 f6 24. Qd5+ Nf7 25. Qd7 Qf8 26. Bd5 Rd8 27. Bxf7+ Qxf7 28. Qxf7+ Kxf7 29. Rxd8 Ke7 30. Rc8 c5 31. Rc7+ 1-0",
    B: "2175",
  },
  {
    W: "2146",
    T: "30+0",
    P: "1. d4 b6 2. Bg5 Bb7 3. Bxe7 Bxe7 4. Nf3 Nf6 5. Nc3 d5 6. e3 Nbd7 7. Bd3 O-O 8. Qe2 c5 9. O-O-O Rc8 10. dxc5 Nxc5 11. h4 Nxd3+ 12. Qxd3 Ne4 13. Ng5 Nxg5 14. hxg5 g6 15. f4 d4 16. exd4 Re8 17. Qh3 Bxg5 18. Qxh7+ Kf8 19. Qh8+ Ke7 20. Qe5+ Kd7 21. Qxg5 1-0",
    B: "2147",
  },
  {
    W: "2278",
    T: "30+0",
    P: "1. g3 d5 2. Bg2 Bh3 3. Nxh3 h5 4. O-O h4 5. e4 hxg3 6. fxg3 dxe4 7. Bxe4 Rxh3 8. Qf3 Nf6 9. Bxb7 Qd4+ 10. Qf2 Qb4 11. Bxa8 Ng4 12. Qg2 Rxh2 13. Qxh2 Nxh2 14. Kxh2 e6 15. Bg2 Bd6 16. Rf3 Qh4+ 17. Bh3 Qg4 18. Bxg4 f5 19. Kg2 fxg4 20. Re3 Kd7 21. Nc3 Nc6 22. d3 Nd4 23. Re2 Nxe2 24. Nxe2 Be7 25. Be3 g5 26. Bf4 c5 27. Bxg5 Bf8 28. Be7 Kc6 29. Nf4 Bxe7 30. Nxe6 Kd5 31. Nf4+ Kc6 32. Re1 Bf6 33. Re6+ Kd7 34. Rxf6 c4 35. dxc4 Kc7 36. Rg6 a5 37. Rxg4 a4 38. Rg5 Kb8 39. Ra5 a3 40. bxa3 Kc7 41. Rb5 Kc6 42. Rd5 Kc7 43. g4 Kc6 44. g5 Kc7 45. g6 Kc6 1/2-1/2",
    B: "2411",
  },
  {
    W: "1667",
    T: "180+0",
    P: "1. e4 f6 2. Nf3 f5 3. exf5 Nf6 4. Bc4 d5 5. Bb3 Bxf5 6. O-O Qd7 7. Re1 Nc6 8. d4 Bg4 9. h3 Bh5 10. Bg5 h6 11. Bxf6 gxf6 12. Ba4 Rg8 13. Nbd2 Qxh3 14. g3 Rxg3+ 15. fxg3 Qxg3+ 16. Kf1 O-O-O 17. Qe2 Nxd4 18. Qe3 Qh3+ 19. Kg1 e5 20. Nxd4 exd4 21. Qxh3+ 1-0",
    B: "1671",
  },
  {
    W: "2001",
    T: "180+0",
    P: "1. d4 d5 2. Bf4 c6 3. c3 e6 4. e3 Nd7 5. Nf3 Ngf6 6. Bd3 Be7 7. Qc2 O-O 8. Ne5 h6 9. Nxd7 Bxd7 10. Nd2 Be8 11. Nf3 Bd6 12. Ne5 Bb8 13. O-O Nd7 14. Nxd7 Qxd7 15. Bxb8 Rxb8 16. c4 dxc4 17. Bxc4 f6 18. Bb3 Bf7 19. Qd3 Rbc8 20. Bc2 f5 21. g4 g6 22. f4 c5 23. gxf5 exf5 24. Kf2 cxd4 25. exd4 Rfd8 26. Rad1 Bc4 27. Qg3 Kg7 28. Rg1 Bf7 29. h4 Rxc2+ 30. Ke1 Qe7+ 0-1",
    B: "2038",
  },
  {
    W: "1126",
    T: "180+0",
    P: "1. e4 e5 2. Nc3 d6 3. Nf3 Nc6 4. Bc4 Nf6 5. d3 a6 6. Ng5 Be6 7. Nxe6 fxe6 8. Bxe6 Qe7 9. Bc4 b5 10. Bb3 Na5 11. Nd5 Nxd5 12. Bxd5 Rb8 13. b3 c6 14. Bd2 cxd5 15. Bxa5 dxe4 16. dxe4 d5 17. exd5 Qd6 18. O-O Be7 19. Re1 O-O 20. Qh5 g6 21. Qxe5 Qxe5 22. Rxe5 Bc5 23. Rae1 Bd6 24. Re6 Bf4 25. Rxa6 Rbd8 26. Bxd8 Rxd8 27. Rae6 Rxd5 28. Re8+ Kg7 29. R1e7+ Kh6 30. Re1 Bd2 31. R1e2 Bc3 32. h4 Kh5 33. g3 Kg4 34. Kg2 g5 35. hxg5 Rxg5 36. f3+ Kh5 37. Kh3 Rd5 38. g4+ Kg5 39. Kg3 Rd1 40. Re1 Bxe1+ 41. Kh3 Bh4 42. Rg8+ Kf4 43. Kxh4 Rh1# 0-1",
    B: "1298",
  },
  {
    W: "1854",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Bc5 3. b4 Bb6 4. Bb2 d6 5. Bc4 Nc6 6. O-O Nge7 7. d4 O-O 8. d5 Nxb4 9. a3 Na6 10. Ng5 Nc5 11. Kh1 Ng6 12. f4 exf4 13. Rxf4 Qxg5 0-1",
    B: "1752",
  },
  {
    W: "1530",
    T: "180+0",
    P: "1. g3 e5 2. Bg2 d5 3. b3 Nf6 4. f4 Nc6 5. fxe5 Nxe5 6. Bb2 Bd6 7. Bxe5 Bxe5 8. d4 Bd6 9. Nc3 c6 10. a3 Qe7 11. b4 a5 12. b5 Bd7 13. b6 Bxa3 14. Nf3 Bb4 15. Qd2 Ne4 16. Qe3 Bxc3+ 17. Kf1 Bxa1 18. Ne5 O-O 19. Bxe4 dxe4 20. Qxe4 f6 21. Kf2 fxe5+ 22. Ke3 exd4+ 23. Kd2 Qxe4 24. Rg1 Rf2 25. Rxa1 0-1",
    B: "1584",
  },
  {
    W: "1419",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bc5 4. Bc4 d6 5. d3 Nf6 6. O-O Ng4 7. a3 Qf6 8. Na4 Qg6 9. Nxc5 dxc5 1-0",
    B: "1427",
  },
  {
    W: "2087",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. Bg5 Be7 5. e3 Nbd7 6. Nf3 b6 7. Qc2 Bb7 8. Rd1 Rc8 9. Be2 c5 10. dxc5 bxc5 11. O-O O-O 12. h3 h6 13. Bf4 Nb6 14. b3 Bd6 15. Bxd6 Qxd6 16. cxd5 Nbxd5 17. e4 Nxc3 18. Rxd6 Ncxe4 19. Rdd1 Rfe8 20. Ne5 Ng5 21. Nc4 Rf8 22. Nd6 Rc7 23. Nxb7 Rxb7 24. f4 Ngh7 25. Bf3 Rc7 26. Rd6 Ne8 27. Rd2 Nef6 28. Rfd1 Rfc8 29. Rd8+ Nf8 30. Qc3 Kh7 31. Qa5 Rxd8 32. Rxd8 1-0",
    B: "2065",
  },
  {
    W: "1432",
    T: "180+0",
    P: "1. e4 d5 2. e5 Be6 3. d4 f6 4. f4 fxe5 5. fxe5 c6 6. Nf3 g6 7. g3 Bh6 8. Bxh6 Nxh6 9. Bg2 Nf5 10. Nc3 Qb6 11. Qd2 Qxb2 12. Rb1 Qa3 13. O-O b5 14. Rb3 Qa6 15. Ng5 Bd7 16. e6 Bc8 17. Nf7 O-O 18. Rxf5 Bxe6 19. Nh6+ Kg7 20. Rxf8 Bf5 21. Rxf5 gxf5 22. Qg5+ Kh8 23. Qg8# 1-0",
    B: "1433",
  },
  {
    W: "1312",
    T: "180+0",
    P: "1. e4 Nc6 2. f4 d6 3. Nf3 e5 4. Bc4 Bg4 5. d3 Nd4 6. Nc3 f6 7. h3 Bh5 8. g4 Bg6 9. f5 Nxf3+ 10. Qxf3 Bf7 11. Be3 Bxc4 12. dxc4 Qd7 13. O-O-O O-O-O 14. Kb1 Qf7 15. Nb5 b6 16. Nxa7+ Kb7 17. Nb5 Ra8 18. b3 c6 19. Nxd6+ 1-0",
    B: "1355",
  },
  {
    W: "1972",
    T: "180+0",
    P: "1. e4 c6 2. Nc3 d5 3. Nf3 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. d3 Nf6 7. Be2 Nbd7 8. O-O e6 9. Bg5 Be7 10. h3 O-O 11. Bd2 Qc7 12. Re1 Rad8 13. Bc3 Nd5 14. Bd2 a5 15. Rb1 b5 16. Rf1 N7f6 17. Qe1 a4 18. Rc1 Rb8 19. c4 bxc4 20. dxc4 Nb4 21. Bxb4 Bxb4 22. Nd2 Qa5 0-1",
    B: "1969",
  },
  {
    W: "2094",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Bc4 Nxe4 4. Qe2 d5 5. d3 dxc4 6. Qxe4 cxd3 7. Nxe5 Be7 8. Nxd3 Nc6 9. O-O O-O 10. Re1 g6 11. Bh6 Bf5 12. Qc4 Bxd3 13. cxd3 Re8 14. Qc3 Bf8 15. Rxe8 Qxe8 16. Bxf8 Qxf8 17. Nd2 Re8 18. Ne4 Re6 19. Nc5 Rd6 20. Nxb7 Re6 21. Nc5 Rd6 22. Ne4 Re6 23. Rc1 Ne5 24. f4 Ng4 25. h3 Ne3 26. Nf6+ Kh8 27. Nd5+ Kg8 28. Nxe3 Rxe3 29. Qxc7 Rxd3 30. Qe5 Qd8 31. Rc7 f6 32. Qe6+ Kh8 33. Qc4 Rd7 34. Rc8 Rd1+ 35. Kh2 Rd7 36. Rxd8+ Rxd8 37. Qe6 Rg8 38. Qf7 Rg7 39. Qxf6 Kg8 40. Qe5 Rf7 41. f5 Kf8 42. fxg6 hxg6 43. g4 Kg8 44. Kg3 a5 45. Qe6 a4 46. Qxg6+ Rg7 47. Qe6+ Kh7 48. Qa6 Rg8 49. Qxa4 Rg6 50. Qe4 Kg7 51. Qxg6+ Kxg6 52. b4 Kf7 53. a4 Ke7 54. b5 Kd7 55. h4 Kc7 56. h5 Kb6 57. h6 Ka5 58. h7 Kb6 59. h8=Q Ka5 60. g5 Kb6 61. g6 Ka5 62. g7 Kb6 63. g8=Q Ka5 64. Qb8 Kb4 1/2-1/2",
    B: "2223",
  },
  {
    W: "1774",
    T: "180+0",
    P: "1. e4 b6 2. Nf3 e6 3. d4 Bb7 4. Nc3 Bb4 5. Bd3 Ne7 6. O-O O-O 7. e5 Bxc3 8. bxc3 Nd5 9. Bxh7+ Kxh7 10. Ng5+ Kg8 11. Qh5 Nf6 12. Qh4 Be4 13. Re1 Bg6 14. Re3 Nh7 15. f4 Nxg5 16. fxg5 Re8 17. Rh3 Kf8 18. Ba3+ c5 19. dxc5 b5 20. c6+ d6 21. Bxd6+ Re7 22. Qh8# 1-0",
    B: "1772",
  },
  {
    W: "2224",
    T: "180+0",
    P: "1. d4 Nf6 2. Bf4 g6 3. e3 Bg7 4. Nf3 O-O 5. Be2 d6 6. O-O b6 7. c3 Bb7 8. Nbd2 Nbd7 9. Re1 Qe8 10. h3 e5 11. Bh2 Qe7 12. dxe5 Nxe5 13. Nxe5 dxe5 14. Nf3 Nd7 15. Qb3 a5 16. Rad1 Nc5 17. Qc2 Rad8 18. Rxd8 Rxd8 19. Rd1 Rxd1+ 20. Qxd1 h6 21. Bc4 Kh7 22. Bd5 c6 23. Bb3 b5 24. Nd2 e4 25. Bc2 f5 26. Nb3 a4 27. Nd4 Ne6 28. g4 Nxd4 29. cxd4 c5 30. gxf5 gxf5 31. Qh5 Bc8 32. Bf4 cxd4 33. exd4 Qe6 34. b3 a3 35. f3 e3 36. Kf1 Bxd4 37. Ke2 Qc6 38. Bxf5+ Bxf5 39. Qxf5+ Kg8 40. h4 b4 41. h5 Qc3 42. Qe6+ Kf8 43. Bxh6+ 1-0",
    B: "2282",
  },
  {
    W: "1197",
    T: "600+0",
    P: "1. d4 e6 2. d5 exd5 3. Qxd5 c6 4. Qe5+ Ne7 5. Nf3 d5 6. Bg5 f6 7. Bxf6 gxf6 8. Qxf6 Rg8 9. e3 Rg6 10. Qf4 Bh6 11. Qh4 Bg4 12. Bd3 Nf5 13. Bxf5 Qxh4 0-1",
    B: "1209",
  },
  {
    W: "1477",
    T: "600+0",
    P: "1. e4 e5 2. d4 exd4 3. c3 Nf6 4. Bc4 Nxe4 5. Qf3 Nf6 6. Bg5 Be7 7. Bxf6 Bxf6 8. Qe4+ Qe7 9. Qxe7+ Bxe7 10. cxd4 O-O 11. Nf3 c6 12. O-O d5 13. Bd3 Nd7 14. Re1 Re8 15. Nc3 Nf6 16. Ne5 Be6 17. h3 Rad8 18. f4 Nh5 19. Ne2 f6 20. Nf3 g6 21. Rf1 Ng7 22. g4 f5 23. gxf5 Bxf5 24. Kh2 Bxd3 0-1",
    B: "1469",
  },
  {
    W: "1178",
    T: "600+0",
    P: "1. c4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe4+ Qe7 5. Qxe7+ Bxe7 6. e3 Nf6 7. Be2 d5 8. cxd5 Nxd5 9. Nf3 Bg4 10. O-O O-O-O 11. h3 Bf5 12. Nbd2 h5 13. e4 Bxh3 14. gxh3 Nf4 15. Bd1 g6 16. Nc4 g5 17. Bxf4 gxf4 18. Nfe5 Nxe5 19. Nxe5 Rd2 20. Rb1 Bf6 21. Nxf7 Rg8+ 22. Kh2 f3 23. Bxf3 Rg7 24. Bxh5 Be5+ 25. Kh1 Rxf2 26. Nxe5 Rxf1+ 27. Rxf1 Rg5 28. Bg4+ Kd8 29. Nf7+ Ke7 30. Nxg5 c5 31. Rf7+ Kd6 32. Rxb7 a5 33. Ra7 Ke5 34. Rxa5 Kf4 35. Kh2 Kxg5 36. Rxc5+ Kf4 37. a4 Kxe4 38. Rc8 Kf4 39. a5 Ke3 40. a6 Kf2 41. a7 Kf1 42. a8=Q Kf2 43. b4 Kf1 44. Rc2 Ke1 45. Qa1# 1-0",
    B: "1166",
  },
  {
    W: "1340",
    T: "600+0",
    P: "1. d4 d5 2. e3 Nf6 3. a4 g6 4. a5 Bg7 5. Ra3 b6 6. axb6 cxb6 7. Bb5+ Bd7 8. Bxd7+ Qxd7 9. h4 Qf5 10. Ne2 e5 11. Ng3 Qe6 12. dxe5 Qxe5 13. f4 Qd6 14. Rc3 O-O 15. b3 Ng4 16. Ba3 Qf6 17. Bxf8 Kxf8 18. Rc8+ Ke7 19. Qxd5 Nxe3 20. Qe4+ Qe6 21. Qb4+ Kf6 22. Qc3+ Ke7 23. Rc7+ Kd8 24. Qxg7 Nd5+ 25. Qe5 Qxe5+ 26. fxe5 Nxc7 27. O-O Nc6 28. Rxf7 Nxe5 29. Rxh7 Nf7 30. Rxf7 Ke8 31. Rxc7 Kd8 32. Rg7 a5 33. Rxg6 Kc7 34. h5 a4 35. bxa4 Rxa4 36. Nd2 Ra1+ 37. Kf2 b5 38. h6 b4 39. h7 Ra8 40. Nf5 b3 41. Nxb3 Rh8 42. Rh6 Kd7 43. g4 Kc7 44. g5 Kb7 45. g6 Kc8 46. g7 Kc7 47. gxh8=Q Kd7 48. Rf6 Kc7 49. Qf8 Kb7 50. Qd6 Kc8 51. Rf7 1/2-1/2",
    B: "1308",
  },
  {
    W: "1748",
    T: "600+0",
    P: "1. d4 d5 2. Bf4 Nc6 3. e3 f6 4. Bb5 Bd7 5. Qh5+ g6 6. Qxd5 e6 7. Qb3 a6 8. Bxc6 bxc6 9. h3 Rb8 10. Qd3 Rxb2 11. Ne2 Qb8 12. O-O Bd6 13. c4 e5 14. dxe5 fxe5 15. Bh2 Nf6 16. e4 O-O 17. c5 Nxe4 18. cxd6 cxd6 19. Bxe5 dxe5 20. Qxd7 Rxe2 21. Qxc6 Nxf2 22. Nc3 Rc2 23. Qd5+ Kh8 24. Ne4 Qb6 25. Nxf2 Rcxf2 26. Qxe5+ Kg8 27. Qd5+ Kg7 28. Rxf2 Qxf2+ 29. Kh1 Qb2 30. Rd1 Rf2 31. Qd4+ Qxd4 32. Rxd4 Rxa2 33. Rd7+ Kh6 1/2-1/2",
    B: "1695",
  },
  {
    W: "1602",
    T: "600+0",
    P: "1. d4 f6 2. Bf4 b6 3. e3 Bb7 4. Bd3 Bxg2 5. Nf3 Bxh1 6. Be4 Bxf3 7. Qxf3 Nc6 8. Bxc6 Rb8 9. Ba4 c6 10. Nc3 e5 11. dxe5 fxe5 12. Bxe5 Rc8 13. O-O-O Nf6 14. Bxf6 Qxf6 15. Qe4+ Be7 16. Bb3 d5 17. Bxd5 cxd5 18. Nxd5 Qh4 19. f4 Rf8 20. Qa4+ Kf7 21. Qxa7 Rfe8 22. Qxb6 Kg8 23. a3 Qxh2 24. Nb4 Bxb4 25. c3 Bxc3 26. Qb3+ Kh8 27. bxc3 Rxe3 28. Rd2 Rexc3+ 29. Qxc3 Rxc3+ 30. Kd1 Qh1+ 31. Ke2 Qg2+ 32. Kd1 Qf1# 0-1",
    B: "1682",
  },
  {
    W: "1040",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3 Bc5 5. d3 Ng4 6. Qe2 Nxf2 7. O-O Ng4+ 8. Kh1 d6 9. h3 Nd4 10. Nxd4 Qh4 11. Bxf7+ Kf8 12. Be6+ Ke8 13. Ndb5 Bb6 14. Nd5 Kd8 15. Nxb6 cxb6 16. Bxc8 Kxc8 17. Nxd6+ Kd8 18. Nf7+ Ke7 19. Nxh8 Rxh8 20. Qxg4 Qxg4 21. hxg4 Rc8 22. Bg5+ Ke8 23. c3 b5 24. Rf5 h6 25. Rxe5+ Kf8 26. Rf1+ Kg8 27. Bxh6 gxh6 28. g5 hxg5 29. Rxg5+ Kh7 30. Rf6 Rd8 31. Re5 a5 32. Re7+ Kh8 33. Rh6+ Kg8 34. Rg6+ Kf8 35. Rh7 b6 36. Rgh6 b4 37. Rh8+ Kg7 38. Rxd8 Kxh6 39. e5 bxc3 40. Rf8 Kg7 41. e6 Kxf8 42. e7+ Kxe7 43. g3 c2 44. g4 c1=Q+ 45. Kh2 Qxb2+ 46. Kh3 Qxa2 47. g5 Qg8 48. g6 Qxg6 49. d4 Kf6 50. d5 Kf5 51. d6 Qxd6 52. Kh4 Qg6 53. Kh3 Kf4 54. Kh2 Kf3 55. Kh3 Qh6# 0-1",
    B: "1080",
  },
  {
    W: "1364",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 b5 5. Bb3 Nd4 6. Bd5 c6 7. Nxd4 exd4 8. Bb3 Qg5 9. O-O d5 10. d3 Bh3 11. Bxg5 1-0",
    B: "1374",
  },
  {
    W: "1919",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. c3 d6 6. Be3 Bb6 7. f3 Nge7 8. Bd3 O-O 9. O-O d5 10. Qe2 dxe4 11. fxe4 Ne5 12. Bc2 Bg4 13. Qf2 N7g6 14. h3 Bd7 15. Nf5 Bxe3 16. Qxe3 Ne7 17. Qg3 Nxf5 18. exf5 f6 19. Nd2 Bb5 20. Rf2 Qd5 21. Bb3 Bc4 22. Nxc4 Nxc4 23. Rf4 b5 24. Rd4 Qc5 25. Rd1 a5 26. Kh1 a4 27. Rd7 Rf7 28. Rxf7 Kxf7 29. Rd7+ Ke8 30. Qxg7 Ne5 31. Rxc7 Qf8 32. Bd1 Qxg7 33. Rxg7 Rd8 34. Bh5+ Kf8 35. Rxh7 Rd2 36. Rb7 Rxb2 37. Rb6 Ke7 38. Re6+ Kd7 39. Rxf6 Rxa2 40. Re6 Nc4 41. Re4 Nd6 42. Rf4 Rc2 43. f6 a3 44. f7 Rc1+ 45. Kh2 a2 46. f8=Q a1=Q 47. Be8+ Kc7 48. Qe7+ Kc8 49. Qd7+ Kb8 50. Qxd6+ Kb7 51. Rf7+ Kc8 52. Qd7+ Kb8 53. Qb7# 1-0",
    B: "1903",
  },
  {
    W: "1843",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 e6 3. Bc4 Be7 4. O-O d6 5. d4 cxd4 6. Qxd4 Bf6 7. e5 dxe5 8. Bb5+ Nc6 9. Bxc6+ bxc6 10. Qxd8+ Kxd8 11. Rd1+ Ke7 12. b3 g6 13. Ba3+ Ke8 14. Bb2 e4 15. Bxf6 Nxf6 16. Ne5 Bb7 17. Nd2 Ke7 18. Re1 c5 19. Ndc4 Rad8 20. Rad1 Nd5 21. Rxe4 Nc3 22. Rxd8 Rxd8 23. Rf4 Ne2+ 24. Kf1 Nxf4 25. g3 Rd1# 0-1",
    B: "1840",
  },
  {
    W: "1661",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nd4 4. Nxd4 exd4 5. d3 c5 6. a3 Qa5+ 7. Bd2 Qb6 8. b4 cxb4 9. axb4 Bxb4 10. O-O Bxd2 11. Nxd2 Nf6 12. Nb3 O-O 13. Qf3 d6 14. Qg3 a5 15. f4 Ng4 16. f5 Ne3 17. Rf2 d5 18. Bxd5 Nxd5 19. exd5 f6 20. Qg4 a4 21. Nxd4 a3 22. Ne6 Bxe6 23. dxe6 a2 24. Qd1 Qd4 25. Kf1 b5 26. c4 bxc4 27. Rd2 Qf4+ 28. Kg1 c3 29. Rc2 Qd4+ 30. Kh1 h5 31. Rcxa2 Rac8 32. Rc2 h4 33. Qh5 Rb8 34. h3 Rb2 35. Rac1 Rxc2 36. Rxc2 Qxd3 37. Qe2 Qxf5 38. e7 Re8 39. Rxc3 Qb1+ 40. Kh2 Qb8+ 41. Kg1 Qa7+ 42. Kf1 Rxe7 43. Qc4+ Rf7 44. Qc8+ Kh7 45. Qf5+ g6 46. Qf4 Qa1+ 47. Ke2 Qxc3 48. Qxh4+ Kg7 0-1",
    B: "1622",
  },
  {
    W: "1613",
    T: "300+0",
    P: "1. e4 Nc6 2. Nf3 d6 3. d4 Nf6 4. Nc3 e5 5. d5 Ne7 6. h3 Nd7 7. Bd3 Nc5 8. O-O f5 9. exf5 Nxf5 10. Bxf5 Bxf5 11. Be3 Nd7 12. Bg5 Be7 13. Qd2 O-O 14. Bxe7 Qxe7 15. Ng5 h6 16. Ne6 Bxe6 17. dxe6 Qxe6 18. Ne4 Nf6 19. Qb4 b6 20. Nxf6+ Rxf6 21. Qc3 Rg6 22. Rae1 Rf8 23. Re3 Rf7 24. Qa3 a5 25. b4 axb4 26. Qxb4 Qxa2 27. Rc3 Qd5 28. f3 Rf4 29. Qb3 Qxb3 30. cxb3 Rd4 31. Rxc7 Rd2 32. Rc6 Rgxg2+ 33. Kh1 Rh2+ 34. Kg1 Rdg2# 0-1",
    B: "1762",
  },
  {
    W: "1188",
    T: "300+0",
    P: "1. e3 e5 2. Nf3 Nc6 3. d3 d5 4. b3 Nf6 5. c4 d4 6. exd4 exd4 7. Bb2 Bc5 8. Qe2+ Be6 9. Ne5 Nxe5 10. Qxe5 Bb4+ 11. Ke2 Qe7 12. Qxd4 Rd8 13. Qxa7 Bg4# 0-1",
    B: "1127",
  },
  {
    W: "1807",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e6 5. Be3 a6 6. Nc3 Nge7 7. Bc4 Nxd4 8. Bxd4 Nc6 9. Be3 b5 10. Bb3 Be7 11. O-O O-O 12. f4 d6 13. f5 Ne5 14. Qh5 Nc4 15. Bc1 Bf6 16. Rf3 g6 17. fxg6 hxg6 18. Rg3 Kg7 19. Bh6+ Kg8 20. Bxf8 Qxf8 21. Ne2 Qg7 22. Qf3 Bxb2 23. Rd1 Be5 24. Rh3 Bb7 25. Bxc4 bxc4 26. c3 Rb8 27. Nd4 Ba8 28. Qe2 Rb6 29. Qxc4 Rb8 30. Nxe6 fxe6 31. Qxe6+ Qf7 32. Qxf7+ Kxf7 33. Rh7+ Kf6 34. Rf1+ Kg5 35. h4+ Kg4 36. g3 Rb2 0-1",
    B: "1827",
  },
  {
    W: "1417",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. c3 Qf6 6. Be3 Nge7 7. f4 Bxd4 8. cxd4 Qh4+ 9. g3 Qf6 10. Nc3 O-O 11. e5 Qf5 12. d5 Nb4 13. a3 Nc2+ 14. Kf2 Nxa1 15. Bd3 Qh3 16. Qxa1 d6 17. Qd1 Bg4 18. Qd2 dxe5 19. fxe5 Bf5 20. Bf1 Qg4 21. Be2 Qg6 22. Nb5 c6 23. dxc6 Rfd8 24. Nd6 Nxc6 25. Bc4 Be6 26. Bd3 Qh5 27. Bf4 Nxe5 28. Be2 Ng4+ 29. Kg2 Qc5 30. Bxg4 Bxg4 31. b4 Qc6+ 32. Kf2 Qf3+ 33. Kg1 g6 34. Qf2 Qd1+ 35. Qf1 Rxd6 36. Bxd6 Bf3 37. Qxd1 Bxd1 38. Kf2 Ba4 39. Re1 Rd8 40. Bc7 Rc8 41. Bd6 h5 42. Re7 Bc6 43. h4 Rd8 44. Bc5 Rd2+ 45. Ke3 Rg2 46. Kf4 Ra2 47. Bxa7 Rxa3 48. Bc5 Ra1 49. Bd4 Rf1+ 50. Kg5 Kh7 51. Rxf7+ Rxf7 52. Bf6 Be8 53. Kf4 Bc6 54. Kg5 Kg8 55. Bd4 Be4 56. Bf6 Bf5 57. b5 Rd7 58. b6 Rd5 59. Bc3 Rb5 60. Bd4 Bb1+ 61. Kf4 Rb4 62. Ke3 Bf5 63. Bc5 Rb3+ 64. Kf4 Rb1 65. Be3 Rf1+ 66. Ke5 Kf7 67. Bd2 Re1+ 68. Bxe1 1-0",
    B: "1760",
  },
  {
    W: "1605",
    T: "300+0",
    P: "1. d4 b6 2. Nf3 Bb7 3. e3 Nf6 4. c4 e6 5. Nc3 d5 6. cxd5 exd5 7. a3 Bd6 8. h3 Nbd7 9. Bd3 Qe7 10. O-O O-O-O 11. Re1 Ne4 12. Bxe4 dxe4 13. Nd2 f5 14. f3 g5 15. fxe4 fxe4 16. Qg4 h5 17. Qf5 Rdf8 18. Qb5 g4 19. hxg4 Qh4 20. Ncxe4 Qh2# 0-1",
    B: "1564",
  },
  {
    W: "1306",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. Nc3 c6 4. Bc4 b5 5. Bd3 h6 6. O-O g5 7. a4 b4 8. Na2 a5 9. b3 Bg7 10. c3 c5 11. cxb4 cxb4 12. Bb2 g4 13. Nh4 Qxh4 14. g3 Qe7 15. Bb5+ Bd7 16. Bxd7+ Qxd7 17. Qc2 Ne7 18. Rac1 Nbc6 19. Qd3 h5 20. Qb5 h4 21. Qxc6 Nxc6 0-1",
    B: "1322",
  },
  {
    W: "1941",
    T: "300+0",
    P: "1. e4 e5 2. f4 Nc6 3. Nf3 exf4 4. Bc4 d6 5. O-O Bg4 6. d4 Nf6 7. Nc3 Be7 8. Bxf4 Qd7 9. a3 O-O-O 10. Qd3 Bxf3 11. Rxf3 Ng4 12. Nd5 Na5 13. Bb5 c6 14. Nxe7+ Qxe7 15. Ba4 f6 16. b4 b5 17. bxa5 bxa4 18. Rb1 Kc7 19. Qa6 Rc8 20. Rb7+ Kd8 21. Rxe7 Kxe7 22. Qxa7+ Ke6 23. d5+ cxd5 24. exd5+ Kxd5 25. Rd3+ Ke4 26. Qd4+ Kf5 27. c4 Rhe8 28. Bd2 Rb8 29. g3 Rb1+ 30. Kg2 Re2+ 31. Kf3 Rxh2 32. Qxg4+ Ke5 33. Bf4# 1-0",
    B: "1941",
  },
  {
    W: "1286",
    T: "300+0",
    P: "1. e4 c5 2. Bc4 Nc6 3. Qh5 g6 4. Qxc5 e5 5. Qd5 Nh6 6. d3 Ne7 7. Qxe5 d6 8. Qxh8 d5 9. Bb5+ Nc6 10. Bxh6 dxe4 11. Qxf8+ Kd7 12. Qxf7+ 1-0",
    B: "1226",
  },
  {
    W: "2141",
    T: "300+0",
    P: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 exd5 5. Ngf3 Bg4 6. Be2 Nf6 7. O-O Be7 8. c3 O-O 9. dxc5 Bxc5 10. Nb3 Bd6 11. Bg5 h6 12. Bxf6 Qxf6 13. Qxd5 Nc6 14. Rad1 Rad8 15. Qb5 Rfe8 16. Nbd4 Nxd4 17. Nxd4 Bxe2 18. Nxe2 a6 19. Qh5 g6 20. Qg4 h5 21. Qd4 Bxh2+ 22. Kxh2 Rxd4 23. Nxd4 Qh4+ 24. Kg1 g5 25. Rd3 Qe4 26. Re3 Qf4 27. Rxe8+ Kg7 28. Re7 h4 29. Ne6+ 1-0",
    B: "1971",
  },
  {
    W: "1538",
    T: "300+0",
    P: "1. e4 b6 2. d4 Bb7 3. Bd3 d5 4. e5 f6 5. Qh5+ Kd7 6. e6+ Kxe6 7. Bf5+ Kd6 8. Bf4+ Kc6 9. Nc3 Nh6 10. Be6 Bc8 11. Bxd5+ Kd7 12. Bxa8 c6 13. d5 f5 14. dxc6+ Nxc6 15. O-O-O+ 1-0",
    B: "1484",
  },
  {
    W: "1787",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 d5 4. Bb5 exd4 5. Nxd4 dxe4 6. Nxc6 Qxd1+ 7. Kxd1 Bg4+ 8. Ke1 bxc6 9. Bxc6+ Kd8 10. Bxa8 Bb4+ 11. c3 Bc5 12. Bxe4 Nf6 13. Bg5 Re8 14. Bxf6+ gxf6 15. f3 Bd7 16. Kd1 f5 17. Bd3 Kc8 18. Nd2 Be3 19. Re1 f4 20. Kc2 Rd8 21. Nc4 Bf2 22. Re2 Bh4 23. Ne5 Be6 24. Ba6+ 1-0",
    B: "1799",
  },
  {
    W: "1674",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 c6 5. Be2 Nf6 6. O-O Be7 7. Bg5 O-O 8. Bxf6 Re8 9. Bxe7 Qxe7 10. Re1 Bg4 11. h3 Qb4 12. b3 Bxf3 13. gxf3 Nd7 14. c3 Qb6 15. Rf1 Qc7 16. Nd2 Qf4 17. Kh1 Qh4 18. Kh2 Qf4+ 19. Kg2 Qg5+ 20. Kh2 Qf4+ 21. Kg1 Qh4 22. Kh2 Qf4+ 23. Kg2 Qg5+ 24. Kh2 Qf4+ 25. Kh1 Qh4 26. Rg1 Qxh3# 0-1",
    B: "1625",
  },
  {
    W: "1612",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 b5 5. Bb3 Nf6 6. d3 h6 7. c3 Bc5 8. O-O O-O 9. h3 d5 10. d4 exd4 11. cxd4 Bb6 12. e5 Ne4 13. Nc3 Nxc3 14. bxc3 Bf5 15. Bc2 Bh7 16. Bxh7+ Kxh7 17. Qd3+ Kh8 18. Nh2 Ne7 19. f4 Ng6 20. f5 Nxe5 21. Qg3 Qf6 22. Kh1 Nc4 23. Ng4 Qd6 24. Qh4 c5 25. f6 Bd8 26. fxg7+ Kxg7 27. Qxh6+ Qxh6 28. Bxh6+ Kg6 29. Bxf8 Bg5 30. Bxc5 Nd2 31. Ne5+ Kh5 32. Rxf7 Ne4 33. Rh7+ Bh6 34. Ng4 Ng3+ 35. Kg1 Ne2+ 36. Kf2 Nf4 37. Rxh6+ Kg5 38. Be7+ Kf5 39. Rf6+ Ke4 40. Kg1 1-0",
    B: "1610",
  },
  {
    W: "1564",
    T: "15+0",
    P: "1. e3 g6 2. d3 Bg7 3. f3 d6 4. c3 e6 5. Be2 Ne7 6. Bd2 O-O 7. g4 Nd7 8. h4 c5 9. h5 g5 10. h6 Bh8 11. f4 Nd5 12. fxg5 Qxg5 13. e4 Nf4 14. Bxf4 Qxf4 15. Qd2 e5 16. Qxf4 exf4 17. Nd2 Ne5 18. g5 f3 19. g6 fxe2 20. Kxe2 fxg6 21. Kd1 Rf2 22. Kc2 Rxd2+ 23. Kxd2 Bg4 24. Nf3 Nxf3+ 25. Kc2 Rf8 26. Rhf1 Ne5 27. Rxf8+ Kxf8 28. Rf1+ Ke7 29. Rg1 Bf6 30. Rxg4 Nxg4 31. e5 Nxh6 32. exf6+ Kxf6 33. c4 Nf5 34. Kb3 Nd4+ 35. Ka4 a6 36. b4 Ke7 37. bxc5 dxc5 38. a3 Kd6 0-1",
    B: "1903",
  },
  {
    W: "2003",
    T: "120+1",
    P: "1. d4 Nf6 2. f4 b6 3. e3 Bb7 4. Nf3 d6 5. c3 Nbd7 6. Bd3 e6 7. O-O Be7 8. Bd2 c5 9. Be1 O-O 10. Nbd2 d5 11. Bh4 Ne4 12. Bxe7 Qxe7 13. Nxe4 dxe4 14. Ng5 exd3 15. Qxd3 Nf6 16. Rae1 h6 17. Nf3 Be4 18. Qe2 Rfd8 19. a3 a5 20. Ne5 Nd7 21. g4 f6 22. Nf3 a4 23. Nd2 f5 24. gxf5 exf5 25. Qh5 Nf6 26. Qg6 Qf7 27. Qg3 Qa2 28. Rf2 Qxb2 29. Nxe4 Qxf2+ 30. Nxf2 Re8 31. Nd3 Ne4 32. Qg6 Rf8 33. Ne5 Rf6 34. Qg2 Nxc3 35. Qxa8+ Kh7 36. dxc5 bxc5 37. Kh1 Ne4 38. Re2 c4 39. Rg2 Rd6 40. h4 Nf6 41. Qf8 Rd1+ 42. Kh2 Ng4+ 43. Nxg4 fxg4 44. Rxg4 Rd7 45. f5 Rc7 46. f6 h5 47. Rxg7+ Rxg7 48. Qxg7# 1-0",
    B: "2105",
  },
  {
    W: "1994",
    T: "180+0",
    P: "1. Nf3 d6 2. d4 e6 3. e3 Be7 4. Bd3 Nf6 5. Nbd2 O-O 6. O-O c6 7. Re1 Qc7 8. e4 Bd8 9. c3 Nbd7 10. Qe2 e5 11. Nf1 Qb8 12. Ng3 Ng4 13. Nf5 a6 14. dxe5 dxe5 15. h3 Ngf6 16. Bg5 Bb6 17. Be3 Bd8 18. Bg5 Qa7 19. Rad1 Bc7 20. Ne7+ Kh8 21. Bc4 b5 22. Bb3 Bb7 23. Nh4 Rae8 24. Nef5 Bb8 25. Qf3 c5 26. Bd5 c4 27. Bxb7 Qxb7 28. Nd6 Bxd6 29. Rxd6 Ra8 30. Nf5 a5 31. Red1 b4 32. Bxf6 Nxf6 33. g4 bxc3 34. bxc3 Qb2 35. R6d2 Qb6 36. g5 Ne8 37. h4 g6 38. Nd6 Ng7 39. Nxf7+ Kg8 40. Nh6+ Kh8 41. Qg3 Nh5 42. Qxe5+ Ng7 43. Rf1 Rae8 44. Qd4 Qe6 45. e5 Qh3 46. Rfd1 Rf3 47. Qg4 Qxg4+ 48. Nxg4 Rf4 49. Nf6 Rxh4 50. Nxe8 Nxe8 51. Rd8 Kg7 52. Rxe8 Rg4+ 53. Kf1 Rxg5 54. Rd7+ Kh6 55. Ree7 1-0",
    B: "1827",
  },
  {
    W: "2233",
    T: "180+0",
    P: "1. d4 d5 2. Nf3 c5 3. g3 Nc6 4. e3 h5 5. Bg2 e6 6. h4 Be7 7. c3 g5 8. hxg5 Bxg5 9. Nxg5 Qxg5 10. e4 Qg6 11. exd5 exd5 12. Bxd5 cxd4 13. Bf4 Nge7 14. Bf3 h4 15. Qe2 Be6 16. Be4 Qg7 17. gxh4 O-O-O 18. Qf3 Bg4 19. Qg3 f5 20. Bg5 fxe4 21. Qxg4+ Kb8 22. Bf4+ Ka8 23. Qxg7 dxc3 24. Nxc3 Nf5 25. Qg4 Nfd4 26. O-O-O Rc8 27. Kb1 Rhg8 28. Qh3 Nf3 29. Nb5 a6 30. Nd6 Rcf8 31. Nxb7 Kxb7 32. Qd7+ Kb6 33. Be3+ Nfd4 34. Rxd4 Nxd4 35. Qxd4+ Kb7 36. Qb6+ Kc8 37. Rc1+ Kd7 38. Rc7+ 1-0",
    B: "2217",
  },
  {
    W: "2307",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. e3 e6 6. Nc3 Qa5 7. Bd2 Qb6 8. e4 axb5 9. Bxb5 Nxe4 10. Bxd7+ Nxd7 11. Nxe4 exd5 12. Nc3 Bb7 13. Nf3 Be7 14. Qe2 O-O 15. O-O Bf6 16. Qd3 Ba6 17. Qxd5 Bxf1 18. Rxf1 Rad8 19. Rb1 Ne5 20. Nxe5 Rxd5 21. Nxd5 Qe6 22. Nxf6+ Qxf6 23. Bc3 Rd8 24. h3 Qf5 25. Re1 Qc2 26. Nc6 Rd1 27. Rxd1 Qxd1+ 28. Kh2 Qd6+ 29. Ne5 f6 0-1",
    B: "2362",
  },
  {
    W: "1819",
    T: "180+0",
    P: "1. d4 c5 2. c3 cxd4 3. Qxd4 Nc6 4. Qd1 Qc7 5. Nf3 a6 6. Nbd2 Nf6 7. e3 e6 8. Bd3 Ne5 9. Nxe5 Qxe5 10. Nf3 Qc7 11. O-O Bd6 12. h3 e5 13. Ng5 h6 14. Ne4 Nxe4 15. Bxe4 O-O 16. Qf3 f5 17. Bxf5 g6 18. Qg4 Rf6 19. Bc2 Kg7 20. e4 Bc5 21. Qh4 Qb6 22. Qxh6+ Kf7 23. Bb3+ Ke7 24. Qg7+ Kd8 25. Qxf6+ Qxf6 26. h4 Qxh4 27. Bg5+ Qxg5 0-1",
    B: "1891",
  },
  {
    W: "1790",
    T: "180+0",
    P: "1. c4 e5 2. Nc3 d6 3. e3 c6 4. d4 f6 5. Nf3 Qe7 6. Be2 Be6 7. O-O h6 8. d5 Bd7 9. b3 c5 10. Bb2 a6 11. Nd2 g5 12. Nde4 Qg7 13. Qc2 Be7 14. Bh5+ Kd8 15. a3 Be8 16. Bg4 h5 17. Be6 Bd7 18. Bxd7 Nxd7 19. b4 b6 20. bxc5 bxc5 21. Rfb1 f5 22. Ng3 f4 23. exf4 gxf4 24. Nge4 Ngf6 25. Qa4 Nxe4 26. Nxe4 Rg8 27. g3 f3 28. h4 Bxh4 29. Kh2 Be7 30. Qa5+ Ke8 31. Bc1 h4 32. Nxd6+ Bxd6 33. Be3 0-1",
    B: "1759",
  },
  {
    W: "2141",
    T: "60+0",
    P: "1. d4 d6 2. c3 c6 3. Nf3 g6 4. e3 Bg7 5. Be2 Nf6 6. O-O O-O 7. h3 Nbd7 8. Nbd2 Qb6 9. Nc4 Qc7 10. b3 b5 11. Ncd2 Re8 12. e4 e5 13. d5 cxd5 14. exd5 Nxd5 15. Bxb5 Nxc3 16. Qe1 Nxb5 17. Bb2 a6 18. a4 Na7 19. Rc1 Qb8 20. Qe3 Bb7 21. Ng5 Nc5 22. Nc4 f5 23. Rfd1 f4 24. Qc3 d5 25. Nb6 Ne4 26. Nxe4 dxe4 27. Qc4+ Kh8 28. Qf7 Nc6 29. Rd7 Rg8 30. Nd5 Bc8 31. Rc7 Nd8 32. Qe7 Ne6 33. Nf6 Nxc7 34. Qf7 Bxf6 35. Qxf6+ Rg7 36. Qf8+ Rg8 37. Bxe5# 1-0",
    B: "2000",
  },
  {
    W: "1745",
    T: "300+0",
    P: "1. d4 d5 2. Bf4 c5 3. Nf3 cxd4 4. c3 dxc3 5. Nxc3 Nc6 6. Nxd5 e5 7. Nxe5 Nxe5 8. Bxe5 Nf6 9. Nc7+ Ke7 10. Bd6+ Qxd6 11. Qxd6+ Kxd6 12. Nxa8 b6 13. O-O-O+ Kc6 14. Rd8 Bb7 15. g3 Bxa8 16. Bg2+ Kc7 17. Rxa8 g6 18. Rxa7+ Kd6 19. Rd1+ Ke6 20. Bh3+ Ke5 21. f4+ Ke4 22. Rxf7 Nh5 23. Rd8 Bg7 24. Re7+ Be5 25. Rxh8 Ke3 26. Rxe5+ Kf2 1-0",
    B: "1712",
  },
  {
    W: "1448",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O d6 5. Re1 Bg4 6. c3 Nf6 7. d4 exd4 8. cxd4 Nxd4 9. Nbd2 O-O 10. Qa4 c6 11. Nxd4 b5 12. Qb3 Bxd4 13. Be2 Be6 14. Qg3 Be5 15. Qd3 Qb6 16. Nf3 Bc4 17. Be3 c5 18. Qd1 Bxe2 19. Qxe2 Nxe4 20. Nxe5 dxe5 21. b4 Rfe8 22. Bxc5 Nxc5 23. bxc5 Qxc5 24. Rec1 Qb6 25. Re1 f6 26. Qf3 Rad8 27. Rab1 a6 28. a4 b4 29. Qe4 a5 30. Rec1 Rd4 31. Qf3 e4 32. Qe3 Rd3 33. Qxb6 Re5 34. Qb8+ Kf7 35. Qb7+ Kg6 36. Qb6 h6 37. Rc5 Re7 38. Qxa5 Red7 39. h3 b3 40. Qb4 f5 41. a5 R7d4 42. Qb6+ Kh7 43. Rc7 Rd6 44. Rc6 Rg6 45. Rxg6 1-0",
    B: "1491",
  },
  {
    W: "1346",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d3 Nf6 4. a3 Be7 5. Nc3 O-O 6. Be3 d6 7. d4 Be6 8. d5 Bd7 9. dxc6 Bxc6 10. Bd3 a6 11. Qd2 Ng4 12. O-O f5 13. exf5 Bxf3 14. gxf3 Nxe3 15. Qxe3 Rxf5 16. Bxf5 1-0",
    B: "1374",
  },
  {
    W: "1680",
    T: "300+0",
    P: "1. d4 d5 2. Bf4 Bf5 3. Nf3 e6 4. e3 Bd6 5. Bg3 Nf6 6. Ne5 Ne4 7. Bf4 g5 8. Bg3 Nxg3 9. hxg3 Bxe5 10. dxe5 Nc6 11. f4 Qe7 12. Nc3 Nb4 13. Bd3 Bxd3 14. cxd3 Qc5 15. d4 Qa5 16. a3 Nc6 17. b4 Qb6 18. Na4 Qb5 19. Nc5 b6 20. Nb3 gxf4 21. gxf4 Rg8 22. Kf2 O-O-O 23. Rxh7 Rdf8 24. Qe2 Qxe2+ 25. Kxe2 Rxg2+ 26. Kf3 Rfg8 27. Rh3 Rb2 28. Nc1 Rgg2 29. Nd3 Rbd2 30. Rh8+ Kb7 31. b5 Na5 32. Rb1 Nc4 33. Rbh1 Rge2 34. Kg4 Nxe3+ 35. Kg5 Rxd3 36. R1h7 Rg2+ 37. Kf6 Rxd4 38. Kxf7 Rxf4+ 39. Kxe6 Rg6+ 40. Kd7 a6 41. bxa6+ Kxa6 42. e6 Nf5 43. Ra8+ Kb5 44. e7 Nxe7 45. Rxe7 d4 46. Kxc7 d3 47. Rd7 Kc4 48. Rad8 Rf3 49. a4 1-0",
    B: "1682",
  },
  {
    W: "1555",
    T: "300+0",
    P: "1. Nc3 Nf6 2. d4 d5 3. h3 Nc6 4. Nf3 Bf5 5. e3 h6 6. a3 e6 7. g4 Bh7 8. b4 Qd6 9. Nb5 Qd7 10. Nc3 a6 11. b5 axb5 12. Bxb5 Ra5 13. a4 Bb4 14. Bd2 Qd6 15. Ne5 Rxb5 16. Nxb5 Bxd2+ 17. Qxd2 Qe7 18. Nxc6 bxc6 19. Nc3 O-O 20. a5 Ne4 21. Nxe4 Bxe4 22. Rg1 c5 23. a6 Ra8 24. Ke2 c6 25. Rgb1 Qf6 26. f4 Qh4 27. a7 Qd8 28. Qc3 c4 29. Qb2 Qc7 30. Qb8+ Rxb8 31. axb8=Q+ Qxb8 32. Rxb8+ Kh7 33. c3 f5 34. Re8 fxg4 35. hxg4 1-0",
    B: "1540",
  },
  {
    W: "1657",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nd4 4. Bxf7+ Kxf7 5. Nxe5+ Ke8 6. Qh5+ g6 7. Nxg6 hxg6 8. Qxh8 Nxc2+ 9. Kd1 Nxa1 10. Qxg8 Qf6 11. Rf1 d6 12. d3 Bg4+ 13. f3 Be6 14. Qh7 Bxa2 15. Nc3 Bb3+ 16. Ke2 Nc2 17. Qxc7 Nd4+ 18. Kf2 Rd8 19. Nd5 Qh4+ 20. g3 Qxh2+ 21. Ke3 Nc2+ 22. Kf4 Bxd5 23. exd5 Bh6+ 24. Ke4 Bxc1 25. Rxc1 Qe2+ 26. Kf4 Nd4 27. Qg7 Qd2+ 28. Kg4 Qxc1 29. Qg8+ Kd7 30. Qxg6 Kc8 31. b4 Qc7 32. Kh3 Nxf3 33. Qf5+ Kb8 34. Qxf3 Rh8+ 35. Kg2 Qc2+ 36. Kg1 Qc1+ 37. Kg2 Qh1+ 38. Kf2 Qxf3+ 39. Kxf3 Re8 40. g4 Kc7 41. g5 Rg8 42. Kf4 Kd7 43. Kf5 Ke7 44. b5 b6 45. d4 Kf7 46. Ke4 Kg6 47. Kf4 Rf8+ 48. Kg4 Rf5 49. Kg3 Rxg5+ 50. Kf4 Rxd5 51. Ke4 Rxb5 52. Kd3 a5 53. Kc4 Rb1 54. Kc3 Kf5 55. Kc2 Rb5 56. Kc3 Ke4 57. Kc4 Rb1 58. Kc3 Kd5 59. Kd3 Rc1 60. Kd2 Rc4 61. Kd3 a4 62. Ke3 a3 63. Kf4 a2 64. Kf5 a1=Q 65. Kf6 Qxd4+ 66. Ke7 Rc7+ 67. Kd8 Qc4 68. Ke8 Rc8+ 69. Ke7 Qc7+ 70. Kf6 Re8 71. Kf5 Qf7+ 72. Kg4 Rg8+ 73. Kh4 Qh7# 0-1",
    B: "1644",
  },
  {
    W: "1790",
    T: "300+0",
    P: "1. d4 e5 2. dxe5 f6 3. exf6 Nxf6 4. Nc3 Bc5 5. Bg5 O-O 6. Nf3 h6 7. h4 hxg5 8. hxg5 Ng4 9. e3 c6 10. Qd3 Kf7 11. Qf5+ Ke8 12. Qg6+ Ke7 13. Qe4+ Kf7 14. Qxg4 d5 15. Ne5+ Ke7 16. Ng6+ Kd6 17. Qg3+ Ke6 18. Nxf8+ Qxf8 19. O-O-O Nd7 20. Be2 Ne5 21. f4 Nc4 22. e4 Be3+ 23. Kb1 Bxf4 24. exd5+ Kd6 25. Ne4+ Kc7 26. Qc3 Nb6 27. dxc6 bxc6 28. Bf3 Nd5 29. Qa5+ Nb6 30. Nc5 Bxg5 31. Na6+ Kb7 32. Qxg5 Kxa6 33. Be2+ Kb7 34. Qa5 Be6 35. Ba6+ Kc7 36. Qe5+ 1-0",
    B: "1819",
  },
  {
    W: "2157",
    T: "300+0",
    P: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. f4 h5 5. Nf3 e6 6. Nc3 c5 7. Be3 Nc6 8. Bb5 Ne7 9. O-O a6 10. Bxc6+ Nxc6 11. a3 cxd4 12. Nxd4 Nxd4 13. Bxd4 Be7 14. Qf3 Bxc2 15. Rac1 Bf5 16. Qf2 O-O 17. h3 Rc8 18. Qf3 h4 19. Rfd1 Rc4 20. Qf2 b5 21. Kh2 Qc7 22. Rg1 b4 23. axb4 Bxb4 24. g4 Be4 25. Rgd1 Rc8 26. f5 Bc5 27. Bxc5 Qxc5 28. Qf4 Bxf5 29. Qf1 Bg6 30. Na2 Rc2+ 0-1",
    B: "2166",
  },
  {
    W: "1829",
    T: "300+0",
    P: "1. e4 d5 2. exd5 e6 3. dxe6 Bxe6 4. Nf3 Nf6 5. Qe2 Bd6 6. g3 O-O 7. Bg2 Re8 8. O-O Nc6 9. Qd3 Bg4 10. c3 Bxf3 11. Qxf3 Rb8 12. d4 Ne7 13. Bg5 Ng6 14. Nd2 h6 15. Bxf6 gxf6 16. Rae1 c6 17. Rxe8+ Qxe8 18. Qxf6 Rd8 19. Ne4 Bb8 20. Qxg6+ fxg6 21. Nf6+ Kf7 22. Nxe8 Rxe8 23. f4 Re2 24. Rf2 Re1+ 25. Bf1 Ra1 26. b3 Rc1 27. c4 Bd6 28. Kg2 Kf6 29. Rd2 g5 30. d5 g4 31. dxc6 Bc7 32. cxb7 h5 33. Rd7 Rc2+ 34. Kg1 Bb8 35. Rd8 Bc7 36. Rc8 Bb6+ 37. Kh1 Rf2 38. c5 Rxf1+ 39. Kg2 Rc1 40. cxb6 axb6 41. b8=Q Rxc8 42. Qxc8 1-0",
    B: "1900",
  },
  {
    W: "1500",
    T: "300+0",
    P: "1. d4 e6 2. Bf4 f5 3. Nf3 Nf6 4. g3 Be7 5. Bg2 O-O 6. O-O d6 7. c3 Qe8 8. Nbd2 Qh5 9. h3 g5 10. Bxg5 Kh8 11. e4 h6 12. g4 fxg4 13. hxg4 Qxg4 14. Bxf6+ Bxf6 15. Qe2 e5 16. Nh2 Qxe2 0-1",
    B: "1745",
  },
  {
    W: "1572",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. f3 Bg7 7. Be3 O-O 8. Qd2 Nc6 9. O-O-O d5 10. e5 Nxe5 11. Bh6 Nc6 12. Bxg7 Kxg7 13. h4 h5 14. Be2 e5 15. Nxc6 bxc6 16. g4 hxg4 17. fxg4 Nxg4 18. Bxg4 Bxg4 19. Rdg1 Bh5 20. Rg5 f6 21. Rxh5 gxh5 22. Rg1+ Kf7 23. Qh6 Rg8 24. Qh7+ Ke6 25. Rf1 Qe7 26. Qxh5 Rh8 27. Qf5+ Kd6 28. Qh3 Rh5 29. Na4 Kc7 30. c4 Qb4 31. Rxf6 Qxa4 32. Rf7+ Kb6 33. Qd7 Qxc4+ 34. Kb1 Qd3+ 35. Kc1 Rg8 36. Qb7+ Kc5 37. Qxa7+ Kc4 38. b3+ Kc3 39. Qc5+ Qc4 40. bxc4 dxc4 41. Rf3# 1-0",
    B: "1598",
  },
  {
    W: "1212",
    T: "300+0",
    P: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. Nf3 exf3 5. Qxf3 Bg4 6. Qxb7 Nbd7 7. Bb5 e6 8. Bxd7+ Nxd7 9. Qc6 Bd6 10. O-O O-O 11. Bh6 g6 12. Bxf8 Qxf8 13. Qxd7 Qg7 14. Rfe1 Qh6 15. g3 f5 16. d5 f4 17. dxe6 fxg3 18. Qf7+ Kh8 19. fxg3 Qh3 20. e7 Bc5+ 21. Kh1 Bf3+ 22. Qxf3 Re8 23. Qf7 Qg4 24. Qxe8+ Kg7 25. Qf8# 1-0",
    B: "1199",
  },
  {
    W: "1965",
    T: "300+0",
    P: "1. e4 e5 2. Nc3 Nc6 3. a4 Nf6 4. Bc4 Bc5 5. d3 d6 6. Bg5 O-O 7. h3 a6 8. Nd5 Kh8 9. Nf3 Be6 10. Nxf6 gxf6 11. Bh4 Rg8 12. O-O Bxh3 13. Kh2 Be6 14. Bxe6 fxe6 15. c3 Rg4 16. g3 Qe7 17. d4 exd4 18. Nxd4 Bxd4 19. Qxg4 Ne5 20. Qf4 Ng6 21. Qh6 Nxh4 22. Qxh4 Ba7 23. f4 Kg8 24. Kg2 Kh8 25. e5 dxe5 26. fxe5 Rf8 27. exf6 Qc5 28. Rae1 Rd8 29. f7 Rd2+ 30. Kh3 1-0",
    B: "2024",
  },
  {
    W: "1320",
    T: "300+0",
    P: "1. e4 e5 2. d4 d6 3. Nf3 Bg4 4. h3 Bh5 5. g4 Bg6 6. dxe5 Bxe4 7. exd6 Bxd6 8. Bd3 Qe7 9. Nc3 Bxf3+ 10. Be2 Bxh1 11. h4 Nc6 12. Bg5 f6 13. Be3 O-O-O 14. b3 Be5 0-1",
    B: "1219",
  },
  {
    W: "1923",
    T: "300+3",
    P: "1. e4 e6 2. Bc4 d5 3. exd5 exd5 4. Bb3 Nf6 5. h3 Bd6 6. c3 O-O 7. d3 Re8+ 8. Be3 c5 9. Nf3 Nc6 10. O-O Qc7 11. Bg5 Nh5 12. Bxd5 Nf4 13. Bxf4 Bxf4 14. Bxc6 Qxc6 15. d4 Rd8 16. Re1 Be6 17. Nbd2 Rac8 18. Ne4 cxd4 19. Nxd4 Qb6 20. Qf3 Bh6 21. Nxe6 fxe6 22. Re2 Rd5 23. Rae1 Rf8 24. Qg3 Rdf5 25. Kf1 Bf4 26. Qd3 Bc7 27. Qd4 Qa6 28. a3 Bb6 29. Qd6 Bxf2 30. Qxa6 bxa6 31. Rxf2 Rxf2+ 32. Nxf2 Rd8 33. Rxe6 Rd2 34. Rxa6 Rxb2 35. Rxa7 Rb3 36. c4 Rc3 37. Ra4 Kf7 38. Ke2 Ke6 39. Ne4 Rc2+ 40. Kf3 Ke5 41. g3 g6 42. Ra5+ Kd4 43. c5 Kd5 44. Nf6+ Kc6 45. Nxh7 Rxc5 46. Rxc5+ Kxc5 47. Nf8 1-0",
    B: "1929",
  },
  {
    W: "1918",
    T: "180+0",
    P: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. Nc3 Nf6 5. d4 Bg4 6. Bb5+ Nc6 7. h3 Bh5 8. g4 Bg6 9. Ne5 Rc8 10. Qf3 e6 11. g5 a6 12. Bxc6+ bxc6 13. gxf6 gxf6 14. Nxg6 hxg6 15. Bd2 Bd6 16. O-O-O Rb8 17. b3 a5 18. h4 Bb4 19. h5 g5 20. Rde1 Qe7 21. Re2 Bxc3 22. Bxc3 a4 23. b4 Kd7 24. a3 f5 25. Rhe1 g4 26. Qxf5 Rh6 27. Qxg4 Rbh8 28. Rh1 Qf6 29. Bd2 R6h7 30. Kb1 Qe7 31. Bg5 Qd6 32. f4 Rb8 33. f5 Rxb4+ 34. axb4 Qxb4+ 35. Kc1 a3 36. fxe6+ fxe6 37. Qxe6+ Kc7 38. Qe5+ Kb6 39. Kd1 Qb1+ 40. Kd2 Qxh1 41. Qb8+ Rb7 42. Bd8+ Ka6 43. Qa8+ Ra7 44. Qxc6# 1-0",
    B: "1962",
  },
  {
    W: "1817",
    T: "180+0",
    P: "1. d4 e6 2. c4 d6 3. Nf3 b6 4. g3 Nf6 5. Bg2 Bb7 6. O-O Be7 7. Nc3 O-O 8. e3 Nbd7 9. a3 c5 10. d5 exd5 11. cxd5 a6 12. a4 Re8 13. b3 Ne5 14. Bb2 Bf8 15. Nxe5 dxe5 16. e4 Rc8 17. Re1 c4 18. Rc1 cxb3 19. Qxb3 Bc5 20. h3 Qd7 21. Ba3 Rb8 22. Bxc5 bxc5 23. Qc4 Rec8 24. a5 Rc7 25. Na4 Qb5 26. Qxb5 axb5 27. Nxc5 Bc8 28. Nb3 Ra7 29. Rc6 Bd7 30. Rb6 Rxb6 31. axb6 Rb7 32. Nc5 Rxb6 33. Rb1 Be8 34. f3 Nd7 35. Nxd7 Bxd7 36. Bf1 Kf8 37. Kf2 Ke7 38. Ke3 Kd6 39. f4 f6 40. fxe5+ fxe5 41. g4 g5 42. Bd3 Kc5 43. Bc2 b4 44. Kd2 Bb5 45. Bb3 Kd4 46. Re1 Bd3 47. Re3 Bc4 48. Bxc4 Kxc4 49. Kc2 Kd4 50. Re1 b3+ 51. Kb2 Kd3 52. Rf1 Kxe4 53. Re1+ Kxd5 54. Rd1+ Ke6 55. Re1 h6 56. Rf1 e4 57. Rf5 e3 58. Rf1 Ke5 59. Re1 Ke4 60. Kc3 b2 61. Rb1 e2 62. Kd2 Kf3 63. Ke1 Ke3 0-1",
    B: "1795",
  },
  {
    W: "2388",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Be7 5. a3 O-O 6. Ba2 d6 7. Nc3 Bg4 8. h3 Bh5 9. g4 Bg6 10. Be3 Re8 11. Qd2 a6 12. O-O-O b5 13. Nd5 Nd7 14. h4 h5 15. Rdg1 hxg4 16. Rxg4 Bh5 17. Rhg1 Bxg4 18. Rxg4 Nf6 19. Nxf6+ Bxf6 20. Ng5 Rf8 21. f4 exf4 22. Bxf4 Qd7 23. Qg2 Ne5 24. Rg3 Ng6 25. Nxf7 Rxf7 26. Rxg6 Kf8 27. h5 Bd4 28. Qg3 Rf6 29. h6 Rxg6 30. Qxg6 Re8 31. h7 Ke7 32. Bg5+ Bf6 33. Qxg7+ Kd8 34. Bxf6+ Kc8 35. Qxd7+ Kxd7 36. h8=Q Rxh8 37. Bxh8 c5 38. Bd5 a5 39. b3 b4 40. a4 Kc7 41. Kd2 Kd7 42. Ke3 Kc7 43. Kf4 Kd7 44. Kf5 Ke7 45. Bf6+ Kd7 1-0",
    B: "2326",
  },
  {
    W: "1724",
    T: "60+0",
    P: "1. d4 g6 2. Nf3 Bg7 3. Bf4 d6 4. e3 Nf6 5. Bb5+ c6 6. Bd3 O-O 7. O-O e5 8. dxe5 dxe5 9. Nxe5 Nd5 10. Bg3 Nd7 11. Nxd7 Qxd7 12. Qe2 Re8 13. c3 Qe7 14. Nd2 Be6 15. Nc4 Nb6 16. Nxb6 axb6 17. Bc4 Bxc4 18. Qxc4 b5 19. Qb3 Qe6 20. Qc2 Rxa2 21. Rxa2 Qxa2 22. Rd1 Ra8 23. h3 Qc4 24. Kh2 Ra2 25. Rd7 Qe6 26. Qd2 Bf8 27. Rd8 Qe7 28. Rb8 Ra4 29. Qd8 Qxd8 30. Rxd8 Re4 31. Rb8 h5 32. Rxb7 Kg7 33. Rc7 Re6 34. Rxc6 Rxc6 35. b4 Bxb4 36. Bf4 Bxc3 37. Be5+ Bxe5+ 38. f4 Bf6 39. Kg3 h4+ 40. Kf3 Rc3 41. g3 Bd4 42. Ke4 Rxe3+ 43. Kxd4 Rxg3 0-1",
    B: "1749",
  },
  {
    W: "1494",
    T: "60+0",
    P: "1. Nc3 e6 2. e4 Qf6 3. Nf3 Bc5 4. d4 Bb6 5. e5 Qf5 6. Qd3 Qxd3 7. Bxd3 Ba5 8. Bd2 Bxc3 9. bxc3 Nc6 10. Be4 d5 11. exd6 Na5 12. dxc7 f5 13. Bd3 Nc6 14. O-O Nf6 15. Rfe1 O-O 16. Bc4 Re8 17. Ng5 Nd8 18. cxd8=Q Rxd8 19. Nxe6 Kh8 20. Nxd8 1-0",
    B: "1319",
  },
  {
    W: "2023",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Nc3 Qc7 6. Be3 Nf6 7. Be2 Bb4 8. f3 d5 9. exd5 Nxd5 10. Bd2 Nxc3 11. Bxc3 Bxc3+ 12. bxc3 Qxc3+ 13. Kf2 O-O 14. Re1 Qc5 15. Kf1 e5 16. Nb3 Qc7 17. c4 Be6 18. Rc1 Nd7 19. Qc2 b6 20. Qb2 f6 21. Bd3 Rfd8 22. Bb1 g6 23. Kg1 Nc5 24. Kh1 Nd3 25. Bxd3 Rxd3 26. Rc2 Rad8 27. Rd2 Rxd2 28. Nxd2 Bxc4 29. Rc1 b5 30. h3 Rd4 31. Ne4 Kg7 32. Qa3 Qd7 33. Qe3 Rd3 34. Qc5 Rd1+ 35. Rxd1 Qxd1+ 36. Kh2 Qd7 0-1",
    B: "2236",
  },
  {
    W: "1501",
    T: "60+0",
    P: "1. Nc3 e5 2. Nf3 Bb4 3. Nxe5 d6 4. Nf3 Nf6 5. e3 Bxc3 6. bxc3 Ne4 7. Bb2 d5 8. d3 Nd6 9. Be2 c6 10. O-O Be6 11. Re1 O-O 12. e4 f5 13. e5 Nf7 14. Nd4 Qe8 15. f4 Nd7 16. Nf3 Nc5 17. d4 Ne4 18. Nd2 Nxd2 19. Qxd2 Nd8 20. Qe3 c5 21. Qg3 cxd4 22. cxd4 g6 23. h4 b6 24. h5 Kg7 25. hxg6 hxg6 26. Bh5 Rh8 27. Bxg6 Qxg6 28. Qxg6+ Kxg6 29. Kf2 Nf7 30. Rh1 Rxh1 31. Rxh1 Rc8 32. c3 Rc4 33. Rg1 a5 34. g4 1-0",
    B: "1517",
  },
  {
    W: "2266",
    T: "60+0",
    P: "1. d4 g6 2. Nf3 f6 3. c4 Nh6 4. Nc3 Nf7 5. e4 Bg7 6. Be2 O-O 7. O-O e5 8. b3 d6 9. Bb2 Nc6 10. dxe5 dxe5 11. Qxd8 Rxd8 12. Nd5 Rd7 13. Rfd1 Kh8 14. Rd2 Ne7 15. Rad1 Nxd5 16. cxd5 Rd8 17. Rc1 Rd7 18. Rdc2 a5 19. Rxc7 Rxc7 20. Rxc7 Kg8 21. a4 Nd6 22. Ba3 Nxe4 23. d6 Bf5 24. Bc4+ Kh8 25. h3 h5 26. Rxb7 Rd8 27. Bb5 Nxd6 28. Be2 Ne4 29. Ra7 Nd2 30. Nxd2 Rxd2 31. Bc4 e4 32. Ra8+ Kh7 33. Bg8+ Kh6 34. Bc1 e3 35. Bxd2 exd2 36. Rd8 Bc2 37. Rxd2 Bxb3 38. Bxb3 f5 39. Rd6 Be5 40. Ra6 1-0",
    B: "2278",
  },
  {
    W: "1682",
    T: "60+0",
    P: "1. e4 e5 2. c3 d6 3. d4 Nc6 4. d5 Nf6 5. dxc6 1-0",
    B: "1652",
  },
  {
    W: "1770",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O d6 5. e5 Bh3 6. exf6 Bxg2 7. Kxg2 gxf6 8. Kh1 Rg8 9. d3 e5 10. Bxc6+ bxc6 11. Nc3 Be7 12. Be3 Qd7 13. d4 cxd4 14. Nxd4 exd4 15. Bxd4 O-O-O 16. Re1 f5 17. Qe2 Bg5 18. Qa6+ Qb7 19. Qa4 Bf4 20. Be3 Bxe3 21. Rxe3 Rde8 22. Rae1 Rxe3 23. Rxe3 Qd7 24. Qb3 Re8 25. Na4 Rxe3 26. Qxe3 c5 27. Nc3 Qc6+ 28. Kg1 Qd7 29. Qf4 Qe7 30. Qf3 Qe1+ 31. Kg2 Qe4 32. Nxe4 fxe4 33. Qxe4 f5 34. Qxf5+ Kc7 35. Qxh7+ 1-0",
    B: "1799",
  },
  {
    W: "1885",
    T: "60+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. Nf3 e6 4. Bb5 a6 5. Bxc6 bxc6 6. d4 cxd4 7. Nxd4 Bb7 8. Be3 d5 9. Nb3 Bb4 10. O-O Ne7 11. exd5 cxd5 12. Ne2 O-O 13. a3 Bd6 14. Ned4 Rc8 15. Rc1 Be5 16. Qe2 Nc6 17. f4 Nxd4 18. Nxd4 Bf6 19. c3 Qd7 20. f5 Rfe8 21. fxe6 fxe6 22. Qg4 Kh8 23. Nf3 d4 24. Bg5 Bxg5 25. Nxg5 Rf8 26. Qh4 h6 27. Nf3 Qd5 28. Qg3 Qd7 29. Nxd4 Qc6 30. Nf3 Rf6 31. Ne5 Qd5 32. Ng6+ Kh7 0-1",
    B: "1864",
  },
  {
    W: "1319",
    T: "60+0",
    P: "1. d4 e5 2. c4 exd4 3. Qxd4 Nc6 4. Qd3 Nf6 5. Nc3 Bb4 6. Nf3 d6 7. Bg5 O-O 8. Bxf6 Qxf6 9. e4 Ne5 10. Nxe5 dxe5 11. O-O-O Be6 12. g3 Rad8 13. Qe3 a5 14. c5 Rxd1+ 15. Nxd1 Bg4 16. Be2 Bxe2 17. Qxe2 a4 18. b3 axb3 19. axb3 Qc6 20. Qc2 Bxc5 21. Nc3 Bd4 22. Nd5 Qxc2+ 23. Kxc2 c5 24. Rd1 1-0",
    B: "1262",
  },
  {
    W: "1976",
    T: "60+0",
    P: "1. c4 e6 2. e3 d5 3. Ne2 Nf6 4. Ng3 Bd6 5. cxd5 exd5 6. a3 Be6 7. Qc2 O-O 8. b4 Nbd7 9. Bb2 b6 10. Bd3 c5 11. b5 c4 12. Be2 Rc8 13. a4 Ne4 14. Nc3 Nxc3 15. Bxc3 Nf6 16. Nh5 Nxh5 17. Bxh5 Bc5 18. Bf3 d4 19. exd4 Bxd4 20. O-O Qf6 21. Be4 g6 22. Kh1 Rfe8 23. a5 Bd7 24. axb6 axb6 25. Ra7 Bxb5 26. f4 Bxc3 27. dxc3 Qe6 28. Re1 Bc6 29. f5 gxf5 30. Qf2 Bxe4 31. Qg3+ Qg6 32. Qxg6+ hxg6 33. Re3 Bxg2+ 34. Kxg2 Rxe3 0-1",
    B: "1986",
  },
  {
    W: "2299",
    T: "120+1",
    P: "1. e4 c6 2. f4 d5 3. e5 g6 4. d4 Bf5 5. Bd3 Bxd3 6. Qxd3 Bg7 7. Nf3 Qd7 8. Be3 f6 9. Nc3 fxe5 10. Nxe5 Bxe5 11. fxe5 Qf5 12. Qd2 Nd7 13. Rf1 Qh5 14. h3 Nb6 15. b3 e6 16. Kf2 Ne7 17. Bg5 Rf8+ 18. Kg1 Kd7 19. a4 Nf5 20. Bf6 Ng3 21. Rf3 Ne4 22. Nxe4 dxe4 23. Re3 Nd5 24. Rxe4 Nxf6 25. exf6 Rxf6 26. Rae1 Qd5 27. Re5 Qd6 28. c4 Raf8 29. d5 Kc8 30. Rxe6 Qc5+ 31. Kh2 cxd5 32. cxd5 Rf2 33. Qg5 R2f5 34. Re8+ 1-0",
    B: "2243",
  },
  {
    W: "1734",
    T: "180+0",
    P: "1. g3 c5 2. Bg2 e6 3. b3 a6 4. Bb2 Qc7 5. e3 Nf6 6. Nh3 Be7 7. O-O O-O 8. Qe2 Nc6 9. f4 b5 10. Nf2 Bb7 11. Ng4 Nb4 12. Nxf6+ Bxf6 13. Bxf6 gxf6 14. Bxb7 Qxb7 15. Na3 f5 16. c3 Qe4 17. cxb4 cxb4 18. d3 Qc6 19. Nc2 Rac8 20. Nxb4 Qb6 21. Nc2 Rc3 22. Nd4 Rfc8 23. Qd2 Qd6 24. Rac1 b4 25. Rxc3 bxc3 26. Qe2 Qa3 27. Rc1 Qxc1+ 28. Kg2 Qd2 29. Qxd2 cxd2 0-1",
    B: "1946",
  },
  {
    W: "1904",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Nxc6 bxc6 6. Nc3 Bb7 7. Bc4 Nf6 8. f3 d5 9. exd5 cxd5 10. Bb3 Bc6 11. O-O Bc5+ 12. Kh1 O-O 13. Re1 Re8 14. Na4 Bb4 15. c3 Ba5 16. Be3 Qd7 17. Nc5 Qf5 18. Bc2 e4 19. Nb3 Bc7 20. fxe4 dxe4 21. Rf1 Qe5 22. Bd4 Qxh2# 0-1",
    B: "1881",
  },
  {
    W: "1581",
    T: "180+0",
    P: "1. Nf3 e6 2. Nc3 d5 3. d4 c5 4. e4 cxd4 5. Qxd4 Nc6 6. Qd3 Nh6 7. exd5 exd5 8. Qxd5 Qxd5 9. Nxd5 Bd6 10. Bxh6 gxh6 11. Bb5 Be6 12. Bxc6+ bxc6 13. Nc3 O-O-O 14. O-O Rhg8 15. g3 h5 16. Nh4 Be7 17. Nf3 h4 18. Rfd1 hxg3 19. fxg3 Bh3 20. Rxd8+ Bxd8 21. Re1 Bb6+ 22. Kh1 Bg4 23. Rf1 Re8 24. Nh4 f5 25. Nxf5 Rf8 26. Ne7+ Kd7 27. Rxf8 Bf3+ 28. Rxf3 1-0",
    B: "1639",
  },
  {
    W: "1537",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. c3 Bc5 4. d4 exd4 5. cxd4 Bb6 6. d5 Nce7 7. Nc3 c6 8. d6 Ng6 9. Ng5 Nf6 10. Bc4 O-O 11. O-O a6 12. Kh1 Ne8 13. f4 Nxd6 14. Bb3 Ne8 15. f5 Ne5 16. Nxh7 Kxh7 17. Qh5+ Kg8 18. Rf4 Qf6 19. Rh4 Qh6 20. Bxh6 1-0",
    B: "1473",
  },
  {
    W: "2295",
    T: "180+0",
    P: "1. Nf3 d5 2. b3 Nf6 3. Bb2 c6 4. d4 Bf5 5. g3 e6 6. Bg2 Bd6 7. O-O Nbd7 8. c4 b6 9. Nc3 O-O 10. Ne5 Bxe5 11. dxe5 Nxe5 12. cxd5 exd5 13. Nxd5 Nxd5 14. Bxe5 Nf6 15. Qxd8 Raxd8 16. Bxf6 gxf6 17. Bxc6 Rd2 18. Bf3 Rfd8 19. e4 Bh3 20. Rfd1 Rxd1+ 21. Rxd1 Rc8 22. Bg2 Bg4 23. f3 Be6 24. Rd2 Rc1+ 25. Kf2 Kg7 26. Ke3 a5 27. f4 f5 28. e5 b5 29. Bd5 a4 30. Bxe6 fxe6 31. bxa4 bxa4 32. Rd7+ Kg6 33. Rd4 a3 34. Rd3 Rc2 35. Rxa3 Rxh2 36. Ra7 h5 37. Re7 Rxa2 38. Rxe6+ Kf7 39. Rf6+ Kg7 40. Rxf5 Ra3+ 41. Kf2 Kg6 42. Rg5+ Kh6 43. e6 Ra2+ 44. Kf3 Ra3+ 45. Kg2 Ra2+ 46. Kh3 Ra1 47. e7 Re1 48. Re5 Rh1+ 49. Kg2 1-0",
    B: "2134",
  },
  {
    W: "2082",
    T: "180+0",
    P: "1. e4 b5 2. Nf3 Bb7 3. d3 g6 4. a3 Bg7 5. c3 d6 6. Nbd2 Nd7 7. d4 c5 8. Bd3 c4 9. Bc2 e5 10. dxe5 dxe5 11. Qe2 Ne7 12. O-O O-O 13. Rd1 Qc7 14. Nf1 Rfe8 15. Ne3 Rad8 16. h3 Nc5 17. Nf1 Rxd1 18. Bxd1 Nd3 19. Bg5 f5 20. exf5 Nxf5 21. N3h2 h6 22. Be3 Qc6 23. f3 Bf8 24. Rb1 Nxe3 25. Nxe3 Nf4 26. Qd2 Bc5 27. Nf1 Bb6 28. Kh2 Rd8 29. Qc2 Nd3 30. Be2 Qf6 31. Rd1 Qf4+ 32. Kg1 Bxe3+ 33. Nxe3 Qxe3+ 34. Kh2 Rd6 35. b3 h5 36. bxc4 bxc4 37. Qa2 Bd5 38. Bxd3 cxd3 39. c4 Bc6 40. Qb3 Qf4+ 41. Kg1 e4 42. Qb8+ Kg7 43. Qc7+ Kh6 44. Qe7 e3 45. c5 e2 46. Re1 Rf6 47. Rxe2 dxe2 48. Qxe2 Bxf3 49. gxf3 Qxf3 50. Qd2+ Qf4 51. Qe2 Qg3+ 52. Qg2 Qxg2+ 53. Kxg2 Rf5 54. c6 Rc5 55. c7 Rxc7 56. h4 Rc5 57. a4 Ra5 58. Kf3 Rxa4 59. Kg3 Rg4+ 60. Kf3 a5 61. Ke3 a4 62. Kd3 a3 63. Kc3 a2 64. Kb2 a1=Q+ 65. Kxa1 Rxh4 66. Kb2 Rc4 67. Kb3 Rc8 68. Kb2 g5 69. Kb3 g4 70. Kb4 h4 71. Kb5 g3 72. Kb6 h3 73. Kb7 Rc1 0-1",
    B: "2099",
  },
  {
    W: "1732",
    T: "180+0",
    P: "1. d4 f5 2. Bf4 Nf6 3. Nd2 Nc6 4. Ngf3 e6 5. c3 Bd6 6. Bg3 O-O 7. e3 h6 8. Bc4 Be7 9. Bd3 Nh5 10. Bb1 Nf6 11. a3 b6 12. b4 Bb7 13. a4 Nd5 14. Ra3 a5 15. Rb3 axb4 16. c4 Nc3 17. Rxc3 bxc3 18. e4 fxe4 19. Nxe4 Nb4 20. Nxc3 Bxf3 21. gxf3 Bg5 22. h4 Bf6 23. Rg1 Nc6 24. Ne2 e5 25. d5 Nd4 26. Nxd4 exd4 27. Be4 d6 28. Bf4 Kh8 29. h5 Qc8 30. Kf1 Qa6 31. Bc2 Qxc4+ 32. Kg2 Qxd5 33. Qd3 Qg8 34. Kh1 c5 35. Bb3 Qh7 36. Qc4 Qf5 37. Bxd6 Qxf3+ 38. Rg2 Rfe8 39. Kh2 Qxh5+ 40. Kg3 1-0",
    B: "1762",
  },
  {
    W: "1555",
    T: "180+0",
    P: "1. e4 e6 2. d4 Nc6 3. f4 b6 4. Nf3 Na5 5. Nc3 Bb4 6. Bd2 Qf6 7. a3 Bxc3 8. bxc3 Nc4 9. Rb1 Nxa3 10. Rb3 Nc4 11. Bxc4 d5 12. e5 Qg6 13. Bb5+ Kf8 14. O-O Ne7 15. Nh4 Qe4 16. Bd3 g5 17. Bxe4 gxh4 18. Qh5 dxe4 19. g4 hxg3 20. hxg3 Nf5 21. Kf2 e3+ 22. Bxe3 h6 23. g4 Ne7 24. f5 exf5 25. Ke2 Ba6+ 26. c4 Bxc4+ 27. Rd3 Bxd3+ 28. Kxd3 Ke8 29. gxf5 Rh7 30. e6 c5 31. d5 Rd8 32. c4 Nxd5 33. cxd5 Rxd5+ 34. Ke4 Rd6 35. Bf4 Rd4+ 36. Ke5 Ke7 37. Qh4+ f6+ 38. Qxf6+ Ke8 39. Qg6+ Rf7 40. Qxf7+ Kd8 41. Qf8+ Kc7 42. e7 Kc6 43. e8=Q+ Kb7 44. Qfe7+ Ka6 45. Qc8+ Kb5 46. Bd2 c4 47. Kf6 Ka4 48. Kg7 Kb3 49. Kh7 Rd3 50. Kh8 Kc2 51. Kh7 Rxd2 52. Kg7 Rg2+ 53. Kf7 Kd3 54. Kf8 1-0",
    B: "1571",
  },
  {
    W: "2041",
    T: "180+0",
    P: "1. e4 c6 2. Nf3 g6 3. Nc3 Bg7 4. d4 Nf6 5. Be3 d6 6. Qd2 Bg4 7. O-O-O h5 8. h3 Bxf3 9. gxf3 Nbd7 10. f4 Qc7 11. d5 c5 12. e5 dxe5 13. fxe5 Nxe5 14. Bf4 Nfd7 15. Nb5 Qb6 16. Bxe5 Nxe5 17. d6 O-O 18. f4 Nf3 19. Qf2 Nd4 20. dxe7 Rfe8 21. Bg2 Qxb5 22. c3 Nf5 23. Be4 Nxe7 24. f5 Rad8 25. fxg6 Nxg6 26. Bxg6 fxg6 27. Rde1 Rxe1+ 28. Rxe1 Qd3 29. Rg1 Bh6+ 0-1",
    B: "2078",
  },
  {
    W: "1254",
    T: "180+0",
    P: "1. e4 e5 2. Qh5 g6 3. Qxe5+ Be7 4. Qxh8 Kf8 5. Qc3 d5 6. exd5 Qxd5 7. d4 b6 8. Nf3 Bb7 9. Ne5 Nf6 10. Bc4 Qe4+ 11. Be3 Qxg2 12. Rf1 Nc6 13. Bxf7 Nxe5 14. dxe5 Kxf7 15. exf6 Bxf6 16. Qxc7+ Be7 17. Qxb7 Qxb7 18. f4 Rd8 19. f5 Qd7 20. Nc3 Bf6 21. Rd1 Bxc3+ 22. Ke2 Qb5+ 23. Kf2 Rxd1 24. Rxd1 Qxf5+ 25. Ke2 Qg4+ 26. Kd3 Qxd1+ 27. Kxc3 Qe1+ 28. Kb3 Qxe3+ 29. Kb4 Qd4+ 30. c4 Qc5+ 31. Kc3 a5 32. a3 h5 33. b4 Qe3+ 34. Kc2 Qe2+ 35. Kc3 Qxh2 36. bxa5 Qg3+ 37. Kb4 bxa5+ 38. Kxa5 Qb8 39. c5 g5 40. c6 g4 41. Ka6 g3 42. c7 Qxc7 43. Kb5 g2 44. Kb4 g1=Q 45. Kb3 Qgb6+ 46. Ka2 Qc2+ 47. Ka1 Qbb1# 0-1",
    B: "1048",
  },
  {
    W: "1786",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. c3 Nc6 5. cxd4 d5 6. e5 Nge7 7. Bb5 a6 8. Bxc6+ Nxc6 9. O-O Be7 10. Be3 Qb6 11. Qc2 O-O 12. Nc3 Bd7 13. Rac1 Rac8 14. Qe2 Qa7 15. Rc2 b5 16. Rfc1 Nb4 17. Rd2 Rc4 18. a3 Nc6 19. Rdc2 Na5 20. Nd2 Rc7 21. b4 Nc4 22. Nxc4 bxc4 23. f4 a5 24. b5 Bxa3 25. Ra1 Bb4 26. Bd2 Qxd4+ 27. Kh1 Ra8 28. b6 Qxb6 29. Be3 Qb7 30. Na4 Bxa4 31. Rxa4 Qb5 32. Rca2 Bc5 33. Bxc5 Qxc5 34. h3 c3 35. Rc2 d4 36. Qd3 Rd8 37. Kh2 Rb7 38. g4 Rb4 39. Raa2 a4 40. g5 a3 41. g6 Rb2 42. gxh7+ Kh8 43. Rg2 Rxa2 44. Rxa2 Rc8 45. Qa6 c2 46. Rxa3 c1=Q 47. Qa8 Q1c2+ 48. Kg3 Rxa8 49. Rxa8+ Qc8 50. Rxc8+ Qxc8 51. Kg4 Qc3 52. f5 d3 53. fxe6 d2 54. e7 Qc8+ 55. Kg5 Qe8 56. e6 Qxe7+ 57. Kf5 Qf6+ 58. Ke4 d1=Q 59. h4 Qdd4# 0-1",
    B: "1824",
  },
  {
    W: "2418",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Be7 5. Nf3 O-O 6. O-O dxc4 7. Ne5 Nc6 8. Nxc6 bxc6 9. Na3 Rb8 10. Nxc4 c5 11. Na5 Bd7 12. Nb7 Qe8 13. Nxc5 Bc6 14. Nb3 Bxg2 15. Kxg2 Qc6+ 16. f3 Rfd8 17. Be3 e5 18. Qc1 Qxc1 19. Raxc1 Nd5 20. Bf2 exd4 21. Bxd4 a5 22. Rfd1 a4 23. Na5 Rxb2 24. Bxb2 Ne3+ 25. Kf2 Nxd1+ 26. Rxd1 Rxd1 27. Nc4 Bc5+ 28. e3 h5 29. h4 a3 30. Be5 Rc1 0-1",
    B: "2470",
  },
  {
    W: "1683",
    T: "180+0",
    P: "1. e4 e6 2. f4 b6 3. Nf3 Bb7 4. d3 d5 5. Nc3 Bb4 6. Bd2 d4 7. Nxd4 Qxd4 8. Nb5 Bxd2+ 9. Qxd2 Qxb2 10. Nxc7+ Kd7 11. Nxa8 Qxa1+ 12. Ke2 Nc6 0-1",
    B: "1642",
  },
  {
    W: "1860",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e5 7. Nf3 Be7 8. Bd3 O-O 9. Qd2 Nc6 10. a3 Bg4 11. h3 Bxf3 12. gxf3 d5 13. Rg1 g6 14. Bh6 Re8 15. exd5 Nxd5 16. O-O-O Nxc3 17. Qxc3 Bf6 18. Bxg6 Qb6 19. Be4+ Kh8 20. Qc4 Re7 21. Be3 Qc7 22. Qe2 Nd4 23. Bxd4 exd4 24. Qd3 Rd8 25. Bxh7 Qe5 26. Rde1 Qf4+ 27. Kb1 Rc7 28. Re4 Qh2 29. Reg4 Rcc8 30. Qf5 Qxg1+ 31. Rxg1 Bg7 32. Qd3 f6 33. Bf5 1-0",
    B: "1846",
  },
  {
    W: "1732",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. exd5 exd5 4. Nf3 Qe7+ 5. Qe2 Nf6 6. Qxe7+ Bxe7 7. Bd3 Nc6 8. Nc3 O-O 9. O-O h6 10. Bg5 hxg5 0-1",
    B: "1745",
  },
  {
    W: "2176",
    T: "120+1",
    P: "1. Nf3 d5 2. g3 Nf6 3. c4 c6 4. Bg2 Bf5 5. O-O e6 6. d3 Be7 7. cxd5 exd5 8. Nc3 h6 9. Qb3 Qb6 10. Qc2 O-O 11. Be3 Qa6 12. a3 Nbd7 13. b4 Rac8 14. Rfb1 c5 15. b5 Qe6 16. Bf4 d4 17. Na4 b6 18. Re1 Nd5 19. Bc1 Bf6 20. Nd2 Ne5 21. Ne4 Rfe8 22. Bb2 Be7 23. Rac1 Bh3 24. Bh1 f5 25. Nd2 Ng4 26. Qc4 Rcd8 27. Nf3 Bf6 28. Ba1 Nxf2 29. Kxf2 Qe3# 0-1",
    B: "2225",
  },
  {
    W: "1659",
    T: "120+1",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 e5 7. Nf3 Be7 8. Bc4 Be6 9. Bb3 O-O 10. O-O Nbd7 11. Bxe6 fxe6 12. Ng5 d5 13. Nxe6 1-0",
    B: "1614",
  },
  {
    W: "1196",
    T: "120+1",
    P: "1. e4 d5 2. Nf3 e6 3. Nc3 c6 4. d3 a6 5. a3 Bd6 6. Be3 Nd7 7. Be2 Qe7 8. O-O Ngf6 9. Bg5 h6 10. Bh4 g5 11. Bg3 Bxg3 12. hxg3 dxe4 13. dxe4 Nc5 14. Qd4 b6 15. Bd3 Bb7 16. Rfe1 Rd8 17. Qe3 Nxd3 18. cxd3 c5 19. b3 Ng4 20. Qe2 e5 21. Nxe5 Nxe5 22. f4 gxf4 23. gxf4 Nxd3 24. Red1 Nxf4 25. Rxd8+ Qxd8 26. Qg4 Qf6 27. Rd1 Nxg2 28. Qxg2 Qxc3 29. Qg4 Qe3+ 30. Kg2 Bxe4+ 31. Kf1 Rf8 32. Qd7# 1-0",
    B: "1276",
  },
  {
    W: "1300",
    T: "120+1",
    P: "1. d4 d6 2. c4 Nd7 3. Nf3 g6 4. d5 Bg7 5. e4 c6 6. Nc3 cxd5 7. cxd5 Qa5 8. Bd2 Qc7 9. Nb5 Qd8 10. Rc1 Nc5 11. e5 b6 12. exd6 exd6 13. Bc4 Ne7 14. O-O O-O 15. h3 Bxb2 16. Rb1 Bg7 17. Nc3 Ba6 18. Bxa6 Nxa6 19. Bg5 Qd7 20. Nd4 Rac8 21. Qd2 Nc5 22. Rfe1 Rfe8 23. Nc6 Nxc6 24. dxc6 Qxc6 25. Rxe8+ Rxe8 26. Re1 Rxe1+ 27. Qxe1 Qd7 28. Ne4 Nxe4 29. Qxe4 Qa4 30. Qa8+ Bf8 31. Be7 Qd1+ 32. Kh2 1-0",
    B: "1355",
  },
  {
    W: "1902",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 f5 4. d3 fxe4 5. dxe4 Nf6 6. Nc3 c6 7. Be3 Nbd7 8. O-O Nb6 9. Bb3 Bg4 10. h3 Bh5 11. g4 Bg6 12. Nd2 Be7 13. f3 Qd7 14. Rf2 Kd8 15. Nf1 Kc7 16. a4 d5 17. exd5 Nbxd5 18. Nxd5+ cxd5 19. Rd2 d4 20. c3 Nd5 21. cxd4 Bb4 22. Rf2 e4 23. f4 Rae8 24. f5 Bf7 25. Rc1+ Kb8 26. Rfc2 h5 27. Bxd5 Qxd5 28. Rc7 Bd6 29. R7c5 Bxc5 30. Rxc5 Qd6 31. a5 hxg4 32. Qxg4 Ka8 33. b4 Bb3 34. b5 Ba4 35. a6 bxa6 36. bxa6 Qxa6 37. Kf2 Rb8 38. Qxg7 Rb2+ 39. Kg3 Rxh3+ 40. Kxh3 Qxf1+ 41. Kg3 Rg2+ 0-1",
    B: "1913",
  },
  {
    W: "969",
    T: "120+1",
    P: "1. e4 f5 2. f4 fxe4 3. d3 exd3 4. Qxd3 Nc6 5. c3 Nf6 6. h3 d5 7. g4 h5 8. g5 g6 9. Qxg6+ Kd7 10. Na3 Ng4 11. hxg4 hxg4 12. Rxh8 Ne5 13. Qf5+ Kd6 14. Nb5+ Kc5 15. a4 Qd7 16. Rxf8 Qxf5 17. Rxf5 e6 18. Rf8 c6 19. Nc7 Bd7 20. Rxa8 Nf3+ 21. Nxf3 b6 22. Be3+ Kd6 23. Ne8+ Ke7 24. Ng7 Kd6 25. Ne8+ Ke7 26. Rd8 Bxe8 27. Rb8 Bd7 28. Rd1 Kd6 29. Ba6 Ke7 30. Rb7 Kd6 31. Rxa7 Ke7 32. Bxb6 Kf7 33. Bc5 Kg7 34. Rxd7+ Kg6 35. Rd6 Kg7 36. Rxe6 Kf7 37. Rxc6 d4 38. Rxd4 g3 39. Bc4+ Kg7 40. Rd7+ Kh8 41. Rc8# 1-0",
    B: "968",
  },
  {
    W: "1905",
    T: "120+1",
    P: "1. e4 c6 2. d4 d5 3. Nc3 g6 4. e5 Bg7 5. h3 f6 6. Nf3 Nh6 7. Bd3 Bf5 8. O-O Bxd3 9. Qxd3 Nf7 10. exf6 exf6 11. Re1+ Kf8 12. Bf4 Nd7 13. Bd6+ Nxd6 0-1",
    B: "1937",
  },
  {
    W: "1493",
    T: "120+1",
    P: "1. e4 e5 2. d4 Nc6 3. c3 exd4 4. Nf3 dxc3 5. Bc4 cxb2 6. Bxb2 Bb4+ 7. Kf1 Nf6 8. e5 Ng4 9. e6 dxe6 10. Qxd8+ Nxd8 11. Bxg7 Rg8 12. Bc3 Bxc3 13. Nxc3 Nc6 14. Nb5 Kd7 15. Rd1+ Ke7 16. Nxc7 Rb8 17. h3 Nge5 18. Nxe5 Nxe5 19. Bb3 b6 20. Nb5 a6 21. Nc7 Rg7 22. f4 Ng6 23. g3 f5 24. Kg2 Kf6 25. Rhe1 Rxc7 26. Rd8 Bb7+ 27. Rd5 0-1",
    B: "1452",
  },
  {
    W: "1803",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nc3 Bc5 4. Bc4 O-O 5. O-O Nc6 6. Nxe5 Nxe5 7. Bb3 Bb6 8. d4 Ng6 9. Bg5 d6 10. Nd5 Be6 11. Nxf6+ gxf6 12. Bh6 Re8 13. d5 Bd7 14. Qf3 Re5 15. Rae1 Qe7 16. c3 c6 17. Rd1 c5 18. c4 Bd8 19. Bc2 Qe8 20. Qg3 Be7 21. f4 Bf8 22. Bxf8 Qxf8 23. fxe5 fxe5 24. Rf6 Qg7 25. Rxd6 Be8 26. Rf1 Rb8 27. Qg5 Nf4 28. Qxg7+ Kxg7 29. g3 Ne2+ 30. Kg2 Nd4 31. Bd3 a6 32. Rdf6 b5 33. b3 bxc4 34. Bxc4 Bb5 35. R6f2 Bxc4 36. bxc4 Rb7 37. Rc1 a5 38. g4 a4 39. h3 a3 40. h4 Rb2 41. Rxb2 axb2 42. Rb1 1-0",
    B: "1745",
  },
  {
    W: "1342",
    T: "300+3",
    P: "1. d4 d5 2. c4 e6 3. cxd5 Qxd5 4. Nc3 Qa5 5. Bd2 Bb4 6. Nf3 Nf6 7. a3 Bxc3 8. Bxc3 Qd5 9. e3 Ne4 10. Bd3 Nc6 11. Bxe4 Qxe4 12. d5 Ne7 13. dxe6 Bxe6 14. Bxg7 Rg8 15. Bd4 Rxg2 16. Rc1 O-O-O 17. Qa4 Qxf3 18. Rf1 Nc6 19. Bxa7 Rd2 20. Kxd2 Rxf2+ 21. Rxf2 Qxf2+ 22. Kd3 Ne5+ 23. Kd4 Nc6+ 24. Rxc6 Qd2+ 25. Ke4 Qg2+ 26. Kd4 Qd2+ 27. Kc5 Qc2+ 28. Kd4 Qxa4+ 0-1",
    B: "1348",
  },
  {
    W: "2191",
    T: "180+2",
    P: "1. d4 e5 2. dxe5 d6 3. Nf3 Bg4 4. Bg5 Qd7 5. Nbd2 Nc6 6. h3 Bh5 7. e4 h6 8. Bh4 Qe6 9. Be2 f6 10. exf6 Nxf6 11. O-O O-O-O 12. Bc4 Qe8 13. Bxf6 gxf6 14. Re1 Kb8 15. Nf1 Ne5 16. Be2 Nxf3+ 17. Bxf3 Bf7 18. Ne3 h5 19. Nf5 Be6 20. Qd4 Qg6 21. Kh1 Bxf5 22. exf5 Qxf5 23. Qd5 Qc8 24. Re6 c6 25. Qf5 Bg7 26. Rae1 Qd7 27. Bxh5 c5 28. Bg4 Qb5 29. b3 Rde8 30. c4 Qc6 31. Bf3 Rxe6 32. Qxe6 Qc7 33. Qe7 Rc8 34. Qxc7+ Rxc7 35. Re8+ Rc8 36. Re6 Rd8 37. Re7 Bh6 38. Rxb7+ Kc8 39. Rxa7 Bf4 40. g3 Be5 41. Ra8+ Kc7 42. Rxd8 Kxd8 43. h4 Ke7 44. h5 f5 45. Bd5 Kf6 46. a4 Bc3 47. f3 Ke5 48. g4 Kd4 49. Kg2 f4 50. g5 Kd3 51. h6 Kc2 52. h7 Kxb3 53. a5 1-0",
    B: "2084",
  },
  {
    W: "1662",
    T: "180+0",
    P: "1. d4 Nf6 2. Bf4 e6 3. Nf3 d5 4. e3 c5 5. c3 Qb6 6. Qc2 Bd7 7. Nbd2 Nc6 8. a3 Rc8 9. h3 cxd4 10. exd4 Be7 11. Ne5 Nxe5 12. Bxe5 O-O 13. Nf3 Ne4 14. Bd3 Nd6 15. Bxh7+ Kh8 16. Bd3 Nb5 17. Nd2 f5 18. g4 Bd6 19. gxf5 exf5 20. Rg1 Bxe5 21. dxe5 Nd4 22. Qb1 Nf3+ 23. Nxf3 Rce8 24. Rg5 Re6 25. Rh5+ Rh6 26. Rxh6+ gxh6 27. Qc1 Rg8 28. Nd4 Qg6 29. Ke2 Qh5+ 30. Nf3 Bc6 31. Qc2 d4 32. Kf1 Qxf3 33. Bxf5 Bb5+ 34. Bd3 Bxd3+ 35. Ke1 Rg1+ 36. Kd2 Qe2# 0-1",
    B: "1656",
  },
  {
    W: "1112",
    T: "900+15",
    P: "1. e4 e5 2. Qh5 Nf6 3. Qxe5+ Be7 4. Bc4 Nc6 5. a3 Nxe5 6. d3 h5 7. Bf4 Nfg4 8. Be3 f6 0-1",
    B: "849",
  },
  {
    W: "1526",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Bg4 4. d3 Bxf3 5. Qxf3 Nf6 6. Bg5 Nbd7 7. Bb5 c6 8. Bc4 h6 9. Bxf6 Nxf6 10. Nc3 g6 11. h3 Bg7 12. b3 O-O 13. g4 g5 14. h4 gxh4 15. Rxh4 Qd7 16. Qh3 b5 17. g5 Qxh3 18. Rxh3 hxg5 19. Bxb5 cxb5 20. Nxb5 d5 21. exd5 Nxd5 22. O-O-O a6 23. Nd6 Nf4 24. Rh2 Rad8 25. Nf5 Rfe8 26. Kb1 e4 27. dxe4 Rxd1# 0-1",
    B: "1542",
  },
  {
    W: "1640",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nxd5 6. Nxf7 Kxf7 7. Qf3+ Ke6 8. Nc3 Ne7 9. O-O c6 10. Re1 b5 11. Bb3 a5 12. Nxd5 Nxd5 13. d4 Qf6 14. Rxe5+ Kd6 15. Bxd5 cxd5 16. Qxd5+ Kc7 17. Qxa8 Bd6 18. Qxa5+ Kc6 19. Qxb5+ Kc7 20. Qc4+ Kd7 21. Qe6+ Qxe6 22. Rxe6 Kxe6 23. Be3 Bd7 24. Re1 Kd5 25. c3 Ra8 26. a3 Rb8 27. Bc1 Ba4 28. Re3 Kc4 29. b4 Bf4 30. Re1 Bxc1 31. Rxc1 Re8 32. h3 Re2 33. Kf1 Rc2 34. Rxc2 Bxc2 35. Ke1 Kxc3 36. d5 Ba4 37. d6 Kb3 38. Kd2 Kxa3 39. Kd3 Kxb4 40. Kd4 Ka5 41. Kd5 Bd7 42. f4 Kb6 43. g4 h6 44. g5 Bxh3 45. gxh6 gxh6 46. Ke5 Kc6 47. f5 Bxf5 48. Kxf5 Kxd6 49. Kg6 Ke5 50. Kxh6 1/2-1/2",
    B: "1654",
  },
  {
    W: "1798",
    T: "900+15",
    P: "1. d4 Nf6 2. Nc3 e6 3. Nf3 c5 4. dxc5 Bxc5 5. e4 Ng4 6. Nd4 Nxf2 7. Kxf2 Qf6+ 8. Ke3 e5 9. Nb5 exd4+ 10. Nxd4 Nc6 11. c3 O-O 12. Bc4 d5 13. Bxd5 Re8 14. Rf1 Qg5+ 15. Kd3 Ne5+ 16. Kc2 Qxg2+ 17. Bd2 Bg4 18. Qe1 Bxd4 19. cxd4 Rac8+ 20. Kb3 Be6 21. dxe5 Bxd5+ 22. exd5 Qxd5+ 23. Ka3 Re6 24. b3 Qd4 25. b4 Qd3+ 26. Kb2 Qc2+ 27. Ka3 Ra6# 0-1",
    B: "1875",
  },
  {
    W: "1369",
    T: "900+15",
    P: "1. Nc3 d5 2. e4 d4 3. Nce2 e5 4. Nf3 Nc6 5. c3 d3 6. Ng3 Nf6 7. Nf5 Bxf5 8. exf5 e4 9. Nd4 Nxd4 10. cxd4 Qxd4 11. b3 Qxa1 12. a3 Bxa3 13. Bxa3 Qxa3 14. Qb1 O-O-O 15. f3 Qb4 16. h3 Rhe8 17. g4 exf3+ 18. Kf2 Qd4+ 19. Kxf3 Qe4+ 20. Kf2 Qxh1 21. g5 Ne4+ 22. Ke1 Nc3+ 23. Kf2 Nxb1 0-1",
    B: "1472",
  },
  {
    W: "1168",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 d6 3. Nxe5 dxe5 4. Bb5+ Bd7 5. Bxd7+ Nxd7 0-1",
    B: "1299",
  },
  {
    W: "1890",
    T: "900+15",
    P: "1. d4 c6 2. c4 d6 3. e4 g6 4. d5 Bg7 5. Nc3 Nf6 6. f3 O-O 7. Bd3 e5 8. dxe6 Bxe6 9. Nge2 Nbd7 10. Bg5 Ne5 11. Nf4 Bxc4 12. Bxc4 Nxc4 13. Qe2 b5 14. O-O h6 15. Bxf6 Qxf6 16. Nd3 Rfe8 17. Rac1 a5 18. Kh1 Rac8 19. Nd1 Qd4 20. b3 Ne5 21. Nxe5 Rxe5 22. Ne3 Rh5 23. Rfd1 Qe5 24. Ng4 Qe7 25. Qd2 d5 26. exd5 Rxd5 27. Qxa5 Rcd8 28. Rxd5 Rxd5 29. Re1 Qd7 30. Qa8+ Kh7 31. g3 Rd1 32. Qa5 Qd3 33. Kg2 Rd2+ 34. Nf2 Bd4 35. Rf1 h5 36. Qc7 Kg7 37. Qxc6 Qe2 38. Qe4 1-0",
    B: "1909",
  },
  {
    W: "1649",
    T: "900+15",
    P: "1. d4 g6 2. e4 b6 3. e5 e6 4. c3 d6 5. Be2 Na6 6. Bf3 Nh6 7. Bxa8 c6 8. Bxc6+ Ke7 9. Bg5+ f6 10. Bxf6+ Kf7 11. Bxd8 Be7 12. Qf3+ Nf5 13. Bc7 Rd8 14. Bxd8 Bxd8 15. g4 h6 16. gxf5 gxf5 17. exd6 Nb8 18. d7 Bxd7 19. Bxd7 Nxd7 20. Qh5+ Ke7 21. Nf3 Nf6 22. Qxh6 b5 23. Qg7+ Kd6 24. Na3 Nh5 25. Qf8+ Kc7 26. Ne5 a6 27. Qc5+ Kb8 28. Nc6+ Kc8 29. Ne7+ Kd7 30. Nxf5 exf5 31. Qxf5+ Kc6 32. Qg6+ Bf6 33. Qxh5 Be7 34. O-O-O b4 35. Qg6+ Kd5 36. c4# 1-0",
    B: "1625",
  },
  {
    W: "2177",
    T: "60+0",
    P: "1. g3 e6 2. Bg2 d5 3. Nf3 Nc6 4. c4 Be7 5. cxd5 exd5 6. Nc3 Be6 7. d4 h5 8. e4 h4 9. exd5 Bxd5 10. Nxd5 Qxd5 11. Nxh4 Qa5+ 12. Bd2 Qb6 13. Nf3 Nf6 14. O-O O-O-O 15. Be3 Ng4 16. Rc1 Nxe3 17. fxe3 Bd6 18. Qc2 f6 19. d5 Ne5 20. Nxe5 Qxe3+ 21. Kh1 Qxe5 22. Qd3 Qxg3 23. Qxg3 Bxg3 24. h3 Bd6 25. Rc3 Rde8 26. Rfc1 Re5 27. a3 Kb8 28. b4 g5 29. Rf3 Rh6 30. Rcf1 Be7 31. d6 cxd6 32. Rc3 g4 33. Kg1 gxh3 34. Rxh3 Reh5 35. Rxh5 Rxh5 36. Kf2 Re5 37. Kf3 b6 38. Rh1 Kc7 39. Rh7 Kd7 40. Kf4 d5 41. Bh3+ Kd6 42. Bg2 Re4+ 43. Bxe4 dxe4 44. Kxe4 Ke6 45. Kd4 a5 46. Kc4 axb4 47. axb4 f5 48. b5 Bc5 49. Rh6+ Ke5 50. Rh5 Ke4 51. Rh6 f4 52. Rc6 Ke3 53. Rxc5 bxc5 54. b6 f3 55. b7 f2 56. b8=Q f1=Q+ 57. Kxc5 Qc1+ 58. Kb6 Qd2 59. Kc7 Qc2+ 60. Kb7 Qb2+ 61. Kc8 Qxb8+ 62. Kxb8 1/2-1/2",
    B: "2106",
  },
  {
    W: "1484",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 d6 5. Nxc6 bxc6 6. Nc3 Nf6 7. Bg5 Be7 8. e5 dxe5 9. Qxd8+ Kxd8 10. Bxf6 Bxf6 11. Ne4 Be7 12. Bc4 Bf5 13. Ng3 Rf8 14. Nxf5 g6 15. Nxe7 Kxe7 16. O-O-O Rad8 17. Rxd8 Rxd8 18. Rd1 Rb8 19. b3 a5 20. a4 Kf6 21. Rd7 Rb7 22. Ba6 Rb4 23. Rxc7 Kf5 24. Rxc6 Rf4 25. Rc5 Rh4 26. Rxa5 Rxh2 27. Kb2 Rxg2 28. Bb7 Rxf2 29. Rd5 h5 30. Rd3 e4 31. Kc3 exd3 32. Kxd3 h4 33. Ke3 Rxc2 34. Kd3 Rc7 35. Bd5 g5 36. Be4+ Kf4 37. Kd4 Rd7+ 38. Bd5 g4 39. b4 g3 40. b5 Rb7 41. b6 Rxb6 42. a5 Ra6 43. Bb7 Ra8 44. Bxa8 g2 45. Bf3 g1=Q+ 0-1",
    B: "1581",
  },
  {
    W: "1441",
    T: "60+0",
    P: "1. e3 d6 2. h3 e5 3. g4 Nc6 4. Bg2 Nf6 5. a3 Be7 6. b4 b6 7. Bb2 Bb7 8. g5 a5 9. gxf6 gxf6 10. b5 Na7 11. Bxb7 Rb8 12. Bg2 Nxb5 13. a4 Na7 14. Nf3 Nc6 15. Nh4 Na7 16. Nf5 Bf8 17. Be4 Bg7 18. Nxg7+ Kf8 19. Nf5 d5 20. Bf3 d4 21. Be4 d3 22. c3 c5 23. Na3 b5 24. Nxb5 Nxb5 25. axb5 c4 26. Ba3+ Kg8 27. b6 Rxb6 28. Rg1# 1-0",
    B: "1429",
  },
  {
    W: "1854",
    T: "60+0",
    P: "1. e4 e6 2. f4 d5 3. exd5 exd5 4. Nf3 f5 5. d4 Nf6 6. c4 c6 7. c5 Be7 8. Bd3 O-O 9. O-O Ne4 10. Nc3 Nd7 11. Qc2 Ndf6 12. Be3 Bd7 13. Rae1 Be8 14. Ne5 Bh5 15. Nxe4 fxe4 16. Be2 Bxe2 17. Qxe2 Qe8 18. h3 Qh5 19. Qf2 Rac8 20. b4 Ne8 21. a4 Bh4 22. g3 Bf6 23. Nd7 Rf7 24. Nxf6+ Nxf6 25. h4 Ng4 26. Qg2 Qf5 27. Re2 h5 28. Bc1 b6 29. Bb2 Rb7 30. Rc1 a5 31. bxa5 bxc5 32. dxc5 e3 33. Bd4 Re8 34. Rce1 Rb4 35. Bxe3 Rxa4 36. Bd2 Rxe2 37. Rxe2 Ra1+ 38. Re1 Rxe1+ 39. Bxe1 Qd3 0-1",
    B: "1884",
  },
  {
    W: "1902",
    T: "60+0",
    P: "1. d4 h5 2. f4 h4 3. Nf3 h3 4. g4 d5 5. g5 Nc6 6. e3 f5 7. Bd3 g6 8. O-O Bd7 9. c3 e6 10. Nbd2 Be7 11. Qe2 Na5 12. Ne5 Bc6 13. Nxg6 Rh7 14. Ne5 Bd6 15. b4 Nc4 16. Ndxc4 dxc4 17. Nxc4 b6 18. Ne5 Bb7 19. Bb5+ Ke7 20. Ng6+ Kf7 21. Ne5+ Ke7 22. c4 Bxe5 23. fxe5 Kf7 24. Bb2 Ne7 25. e4 Qh8 26. exf5 Nxf5 27. d5 Kg6 28. dxe6 Re8 29. Qd3 Rf8 30. e7 Rxe7 31. Rae1 Qg7 32. Bd4 Kf7 33. e6+ Kg8 34. Bxg7 Kxg7 35. Rxf5 Rxf5 36. Qxf5 Re8 37. Qf6+ Kg8 38. Qf7+ Kh8 39. Qxe8+ Kh7 40. Qf7+ Kh8 41. e7 1-0",
    B: "1914",
  },
  {
    W: "1725",
    T: "60+0",
    P: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. Be2 Bg4 5. O-O e6 6. c3 Bd6 7. d4 Nf6 8. Bg5 O-O 9. Ne5 Nbd7 10. Bxg4 Nxg4 11. Bxd8 Bxe5 12. Qxg4 Rfxd8 13. dxe5 Nxe5 14. Qg3 a5 15. Qxe5 b5 16. Qg3 b4 17. cxb4 axb4 18. Nd2 Rdc8 19. Nf3 Rc2 20. Ne5 Rxb2 21. Qf4 b3 22. Qxf7+ Kh8 23. axb3 Rxa1 24. Qf8# 1-0",
    B: "1753",
  },
  {
    W: "1500",
    T: "60+0",
    P: "1. f4 e5 2. e3 d5 3. Bb5+ c6 4. Bd3 Nf6 5. c3 Bg4 6. Qc2 e4 7. b4 exd3 0-1",
    B: "1499",
  },
  {
    W: "2040",
    T: "60+0",
    P: "1. e4 c6 2. Nc3 d5 3. f4 dxe4 4. Nxe4 Nd7 5. Nf3 Ngf6 6. Nxf6+ Nxf6 7. d4 Bg4 8. Be3 e6 9. Bd3 Bd6 10. h3 Bh5 11. Qd2 Bxf3 12. gxf3 Qb6 13. O-O-O O-O-O 14. d5 c5 15. dxe6 fxe6 16. Qf2 Nd5 17. Bd2 Nxf4 18. Bxf4 Bxf4+ 19. Kb1 Be5 20. b3 Qa5 21. Qd2 Qa3 22. Qc1 Qb4 23. Qd2 Bc3 24. Qc1 Kb8 25. Be4 Rhe8 26. Rhg1 a5 27. Rxg7 Bxg7 28. Rxd8+ Rxd8 29. Qf4+ Ka8 30. Qc7 Rd1# 0-1",
    B: "2022",
  },
  {
    W: "2066",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Qb6 5. Nf3 Nc6 6. a3 c4 7. Nbd2 Na5 8. Rb1 Nb3 9. Nxb3 cxb3 10. Bd3 Bd7 11. O-O Ne7 12. Be3 Bb5 13. Bxb5+ Qxb5 14. Nd2 a5 15. Qxb3 Qxb3 16. Nxb3 Nc6 17. Nc5 Bxc5 18. dxc5 O-O 19. Bd4 f6 20. exf6 gxf6 21. Rfe1 e5 22. Be3 Kf7 23. f4 Ke6 24. fxe5 fxe5 25. Rbd1 Rg8 26. b4 axb4 27. cxb4 Rxa3 28. b5 Nb4 29. Rb1 Nc2 30. Bf2 Nxe1 31. Bxe1 Ra2 32. c6 Rgxg2+ 33. Kh1 Rxh2+ 34. Kg1 Rhg2+ 35. Kh1 bxc6 36. b6 Rgb2 37. Rxb2 Rxb2 38. Ba5 Rb1+ 39. Kg2 d4 40. Kg3 Kd5 41. Kf3 c5 42. b7 Rxb7 43. Bd2 e4+ 44. Kf4 Rb2 45. Kf5 Rxd2 46. Kf6 Rf2+ 47. Ke7 e3 48. Kd7 e2 49. Kc7 Rf6 50. Kd7 c4 51. Kc7 d3 52. Kd7 c3 53. Kc7 c2 54. Kd7 c1=Q 55. Kd8 Qg5 56. Kd7 Qg7+ 57. Kd8 Rf8# 0-1",
    B: "2422",
  },
  {
    W: "2013",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. d4 c6 4. Nc3 Qd6 5. Be3 Nf6 6. Bd3 Bg4 7. Nf3 e6 8. O-O Nbd7 9. Qd2 Qc7 10. Bf4 Bd6 11. Bxd6 Qxd6 12. Ne5 Nxe5 13. dxe5 Qxe5 14. Rae1 Qc7 15. Be2 Bxe2 16. Rxe2 O-O 17. Re3 Rad8 18. Qe2 Nd5 19. Nxd5 exd5 20. Qg4 Rde8 21. Rg3 g6 22. h4 Re4 23. Qg5 Rfe8 24. h5 R4e5 25. Qxg6+ fxg6 26. hxg6 h6 27. g7 Rg5 28. Rxg5 hxg5 29. g3 Kxg7 30. Kg2 Rh8 31. Re1 Qf7 32. Re3 g4 33. c3 Qf5 34. a3 Kf7 35. a4 Kg6 36. a5 a6 37. Kg1 Qb1+ 38. Kg2 Qh1# 0-1",
    B: "1987",
  },
  {
    W: "1791",
    T: "60+0",
    P: "1. d4 Nf6 2. Nf3 c5 3. Bg5 cxd4 4. Bxf6 gxf6 5. Nxd4 Nc6 6. Nxc6 dxc6 7. Nd2 Bf5 8. e3 Qb6 9. b3 e5 10. c4 O-O-O 11. Qc1 Bb4 12. c5 Qa5 13. Ke2 Rxd2+ 14. Kf3 Be6 15. Bc4 f5 16. Bxe6+ fxe6 17. Qc4 Rd5 18. a3 Bd2 19. b4 Qc7 20. Rhd1 e4+ 21. Ke2 Rg8 22. g3 Bc3 23. Rxd5 exd5 24. Qxc3 d4 25. Qc4 d3+ 26. Kf1 Qe7 27. Qxg8+ Kd7 0-1",
    B: "1852",
  },
  {
    W: "2044",
    T: "60+0",
    P: "1. d4 c5 2. d5 d6 3. c4 Bd7 4. Nc3 g6 5. e4 Bg7 6. f4 a6 7. Nf3 Nf6 8. a4 Bg4 9. Be2 Qc8 10. O-O h5 11. Qe1 O-O 12. e5 dxe5 13. fxe5 Nfd7 14. e6 fxe6 15. dxe6 Nf6 16. Nd5 Bxe6 17. Nxe7+ Kh7 18. Nxc8 Bxc8 19. Ng5+ Kg8 20. Bd3 Re8 21. Qh4 Ng4 22. Bxg6 Re4 23. Nxe4 Nf6 24. Nxf6+ Bxf6 25. Qxf6 1-0",
    B: "2088",
  },
  {
    W: "1524",
    T: "180+0",
    P: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Nf3 Bg4 5. h3 Bxf3 6. Qxf3 e6 7. Nc3 Nd7 8. Bd3 c5 9. Be3 Rc8 10. O-O cxd4 11. Bxd4 Qc7 12. Qg3 Ne7 13. f4 Nf5 14. Bxf5 exf5 15. e6 fxe6 16. Bxg7 Rg8 17. Be5 Qc5+ 18. Qf2 Qxf2+ 19. Kxf2 Nxe5 20. fxe5 Rc4 21. Kg1 Bc5+ 22. Kh1 d4 23. Nb5 Rxc2 24. Nc7+ Kd7 25. Nb5 Rgxg2 0-1",
    B: "1936",
  },
  {
    W: "1914",
    T: "15+0",
    P: "1. e3 d5 2. Be2 e5 3. Nf3 Nc6 4. Nxe5 Nf6 5. Nxc6 bxc6 6. c4 Bf5 7. cxd5 Qxd5 8. d3 Be4 9. dxe4 Qxd1+ 10. Bxd1 Nxe4 11. O-O Bc5 12. Nc3 Nxc3 13. bxc3 Bb6 14. Bb2 c5 15. Bb3 O-O 16. Ba4 Rac8 17. c4 Rce8 18. Bc6 Re6 19. Bb5 Rh6 20. Rad1 c6 21. Ba4 Bc7 22. Bc2 Bxh2+ 23. Kh1 Bg3+ 24. Kg1 Bh2+ 25. Kh1 Bc7+ 26. Kg1 Bb6 27. g3 1-0",
    B: "1450",
  },
  {
    W: "1451",
    T: "15+0",
    P: "1. d4 d5 2. Bf4 Nd7 3. e3 c6 4. Bd3 Ngf6 5. Nf3 e6 6. Ne5 Bb4+ 7. c3 Bd6 8. Nxd7 Qxd7 9. Bxd6 Qxd6 10. e4 Nxe4 11. Bxe4 dxe4 12. f3 exf3 13. gxf3 e5 14. Qe2 f6 15. Rf1 O-O 16. Nd2 Re8 17. Rd1 exd4 18. Ne4 f5 19. Nf6+ 1-0",
    B: "1180",
  },
  {
    W: "1420",
    T: "60+0",
    P: "1. e4 d5 2. Nf3 dxe4 3. Ng5 Nf6 4. Qe2 c5 5. Nxe4 Nc6 6. Nxf6+ gxf6 7. Nc3 e5 8. d3 Bg7 9. f3 O-O 10. Qf2 Re8 11. Be2 Qe7 12. O-O f5 13. Qg3 e4 14. Bh6 Qf6 15. Bxg7 Qxg7 16. Qxg7+ Kxg7 17. fxe4 fxe4 18. Nxe4 Nd4 19. Nf6 Rxe2 20. Nh5+ Kh8 21. Rxf7 Nxc2 22. Rf8# 1-0",
    B: "1339",
  },
  {
    W: "1412",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3 h6 5. O-O a6 6. a4 b6 7. Re1 Bb7 8. d3 Bc5 9. Be3 Qe7 10. Bxc5 bxc5 11. Nd5 Nxd5 12. Bxd5 Rb8 13. b3 O-O 14. Qd2 Nd4 15. Nxd4 cxd4 16. Bc4 c6 17. h3 d5 18. exd5 cxd5 0-1",
    B: "1182",
  },
  {
    W: "1431",
    T: "90+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Be2 h6 4. d3 Nf6 5. Nc3 a6 6. Nd5 d6 7. Nxf6+ Qxf6 8. O-O Be6 9. c4 Be7 10. b3 O-O 11. a4 Rfe8 12. a5 Rad8 13. Ba3 Nd4 14. Nxd4 exd4 15. c5 dxc5 16. Bb2 Bd6 17. g3 Qe7 18. Bg4 Bxg4 19. Qxg4 Kh8 20. Qh3 Qe6 21. Qxe6 Rxe6 22. Bc1 Ree8 23. Bf4 Bxf4 24. gxf4 f6 25. f3 1-0",
    B: "1315",
  },
  {
    W: "1675",
    T: "180+0",
    P: "1. e4 e6 2. Nf3 Ne7 3. d4 c6 4. Bg5 f6 5. Bh4 Ng6 6. e5 Nxh4 7. exf6 Nxf3+ 8. Qxf3 Qxf6 9. Be2 Qxf3 10. Bxf3 Bd6 11. O-O O-O 12. Bg4 Rf6 13. Nd2 Rh6 14. Nf3 Na6 15. h4 Be7 16. h5 d6 17. Rae1 Nb4 18. c4 Nd3 19. Rd1 Nxb2 20. Rd2 Nxc4 21. Rc2 Na3 22. Rc3 Nb5 23. Rcc1 e5 24. dxe5 Bxg4 25. exd6 Bxd6 26. Ng5 Bxh5 27. Rfe1 Bg6 28. Ne4 Bc7 29. Nc5 Rb8 30. Nd7 Rd8 31. Ne5 Bxe5 32. Rxe5 Bf7 33. g3 Bd5 34. a4 Nd4 35. f4 Rh1+ 36. Kf2 Rxc1 37. g4 Rc2+ 38. Ke3 Nb3 39. Kd3 Rc1 40. Ke3 Re1+ 41. Kf2 Rxe5 42. fxe5 Re8 43. Ke3 Rxe5+ 44. Kf4 0-1",
    B: "1675",
  },
  {
    W: "1614",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Bc5 3. Nxe5 Qh4 4. Qf3 Nf6 5. Bd3 Nc6 6. c3 Nxe5 0-1",
    B: "1594",
  },
  {
    W: "1541",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Bg4 4. h3 Bxf3 5. Qxf3 Nf6 6. d3 a6 7. Bg5 Nc6 8. Nc3 Nd4 9. Qd1 Be7 10. O-O b5 11. Bb3 c5 12. Nd5 c4 13. Nxe7 Qxe7 14. dxc4 h6 15. Bh4 g5 16. Bg3 bxc4 17. Bxc4 Nxe4 18. Bd5 Nxg3 19. fxg3 Rb8 20. Bxf7+ Kd8 21. c3 Nc6 22. Bb3 Rf8 23. Qd5 Rxf1+ 24. Rxf1 Qa7+ 25. Kh1 a5 26. Qxc6 Rb6 27. Qf3 Qa6 28. Qf8+ Kc7 29. Rf7+ Kc6 30. Qe8+ Kc5 31. Rc7+ Rc6 32. Rxc6+ Qxc6 33. Qxc6+ Kxc6 34. a4 Kc5 35. Ba2 d5 36. Kg1 d4 37. cxd4+ exd4 38. Kf2 Kb4 39. b3 Ka3 40. Bb1 Kxb3 41. Ke2 Kxa4 42. Kd3 Kb4 43. Kxd4 a4 44. Ke5 Kb3 45. Kf5 a3 46. Be4 Kc4 47. Bb1 Kc3 48. Ba2 Kd2 49. Kg6 Ke2 50. Kxh6 Kf2 51. Kxg5 Kxg3 52. h4 Kxg2 53. h5 Kf3 54. h6 Ke3 55. h7 Kd3 56. h8=Q Kc2 57. Qb8 Kc3 58. Qb3+ Kd2 59. Qxa3 1-0",
    B: "1559",
  },
  {
    W: "999",
    T: "180+2",
    P: "1. e4 d5 2. Nc3 dxe4 3. Nxe4 e5 4. c3 f5 5. Ng3 f4 6. Ne4 Bf5 7. Qf3 Nf6 8. Nxf6+ gxf6 9. g4 Be6 10. Bb5+ Nc6 11. Bxc6+ bxc6 12. Qxc6+ Bd7 13. Qe4 Bh6 14. Nf3 c6 15. Nxe5 fxe5 16. Qxe5+ Qe7 17. Qxe7+ Kxe7 18. d4 Bxg4 19. O-O f3 20. Bxh6 Rhg8 21. Rfe1+ Kf6 22. Bf4 Rae8 23. Rxe8 Rxe8 24. b4 Kf5 25. Be3 Ke4 26. Re1 Kd3 27. d5 cxd5 28. Rd1+ Kxc3 29. Bxa7 Kxb4 30. Rxd5 Bh3 31. Rd3 Re1# 0-1",
    B: "1032",
  },
  {
    W: "1856",
    T: "180+2",
    P: "1. b3 e6 2. Bb2 d5 3. e3 Nf6 4. Nf3 c5 5. Bb5+ Bd7 6. Nc3 Bxb5 7. Nxb5 a6 8. Nc3 b5 9. O-O Be7 10. d4 O-O 11. Ne2 Nc6 12. c4 cxd4 13. Nexd4 Nxd4 14. Qxd4 dxc4 15. bxc4 Qxd4 16. Nxd4 bxc4 17. Nc6 Bc5 18. Rac1 Rac8 19. Ne5 Bb6 20. Rxc4 Rxc4 21. Nxc4 Nd5 22. Nxb6 Nxb6 23. Rc1 Rc8 24. Rxc8+ Nxc8 25. Bd4 Nd6 26. Kf1 Nb5 27. Be5 f6 28. Ba1 e5 29. Ke2 Kf7 30. a4 Nd6 31. Kd3 Ke6 32. Bc3 Nb7 33. a5 Kd5 34. e4+ Kc6 35. Bb4 Nc5+ 36. Ke3 Kb5 37. Bd2 Nb3 38. Bc3 Nxa5 39. f4 Nc4+ 40. Kf3 a5 41. fxe5 Nxe5+ 42. Ke2 a4 43. Kd1 Kc4 44. Ba1 Kb3 45. Kc1 Nd3+ 46. Kd2 Ne5 47. Bd4 Nc4+ 48. Kd3 a3 49. Ke2 Nb2 0-1",
    B: "1772",
  },
  {
    W: "1251",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Bg4 4. h3 Bxf3 5. Qxf3 Nf6 6. Bd3 Nc6 7. c3 a6 8. Qd1 b5 9. Bc2 a5 10. O-O b4 11. d3 Qb8 12. Re1 Be7 13. Ba4 O-O 14. Bxc6 Ra6 15. Ba4 bxc3 16. bxc3 c5 17. Nd2 Qc7 18. Nf3 c4 19. Bg5 cxd3 20. Qxd3 Rb6 21. Rab1 Rxb1 22. Rxb1 Rc8 23. Rc1 Bd8 24. Bxf6 Bxf6 25. Kh2 Bg5 26. Rc2 Bf4+ 27. g3 Bh6 28. Bb3 Kf8 29. Bd5 Qc5 30. Nh4 g5 31. Nf5 Bg7 32. Nxg7 Kxg7 33. Bxf7 Kxf7 34. f4 exf4 35. gxf4 gxf4 36. e5 dxe5 0-1",
    B: "1263",
  },
  {
    W: "1900",
    T: "60+0",
    P: "1. b4 b6 2. Bb2 Ba6 3. a3 Nf6 4. e3 e6 5. Bxa6 Nxa6 6. Nf3 c5 7. Qe2 Qc8 8. O-O cxb4 9. axb4 Nc7 10. Nc3 Bxb4 11. Ra4 a5 12. Qd1 O-O 13. Qe2 d5 14. d4 Bxc3 15. Bxc3 Ne4 16. Bd2 Qd7 17. Rb1 Qxa4 18. Rxb6 Nxd2 19. Qf1 Nxf1 20. Kxf1 Qa1+ 21. Ke2 Rab8 22. Kd2 Rxb6 23. Ne5 Qa4 24. Ke2 Qc4+ 25. Kf3 Qxc2 26. Nd7 Rb2 27. Nxf8 Qxf2+ 28. Kg4 Qxg2+ 29. Kf4 Qe4+ 30. Kg3 Rxh2 31. Kxh2 Kxf8 32. Kg3 Qxe3+ 33. Kg4 Qxd4+ 34. Kg3 a4 35. Kf3 a3 36. Kg3 a2 37. Kf3 a1=Q 38. Kg3 Qa3+ 39. Kg2 Qdb2+ 40. Kf1 Qaa1# 0-1",
    B: "1919",
  },
  {
    W: "2085",
    T: "60+0",
    P: "1. Nf3 f5 2. g3 Nf6 3. Bg2 e6 4. O-O d5 5. d3 Bd6 6. Nbd2 O-O 7. Re1 e5 8. e4 fxe4 9. dxe4 d4 10. Nc4 Be6 11. Nxd6 Qxd6 12. Qd3 h6 13. a3 c5 14. h3 c4 15. Qe2 b5 16. Nh4 Nc6 17. Nf5 Qc5 18. Bxh6 Bxf5 19. Bxg7 Kxg7 20. exf5 Rae8 21. Qf3 e4 22. Qf4 Qe5 23. Qh4 Rh8 24. Qg5+ Kf7 25. f3 Reg8 26. Qd2 e3 27. Qe2 Rxg3 28. Rf1 Rhg8 29. f4 Qc5 30. Kh1 Rxg2 31. Qxg2 Rxg2 32. Kxg2 Qd5+ 33. Kh2 Qe4 34. Rae1 Nh5 35. Rg1 Nxf4 36. Rg3 e2 37. h4 d3 38. h5 1-0",
    B: "2091",
  },
  {
    W: "1746",
    T: "60+0",
    P: "1. d4 Nf6 2. e3 c5 3. Bd3 c4 4. Be2 d5 5. c3 b5 6. b3 Nc6 7. a4 cxb3 8. Qxb3 Na5 9. Qxb5+ Bd7 10. Qb2 Nc4 11. Bxc4 dxc4 12. Nd2 1-0",
    B: "1756",
  },
  {
    W: "1573",
    T: "60+0",
    P: "1. d4 d5 2. e3 Bf5 3. Bd3 Bg6 4. Bxg6 hxg6 5. Nf3 f6 6. h3 Nc6 7. Nc3 e5 8. a3 exd4 9. exd4 Qe7+ 10. Be3 O-O-O 11. O-O Re8 12. Re1 Qd7 13. Bf4 Bd6 14. Bxd6 Qxd6 15. Rxe8+ Nd8 16. Qe2 Qd7 17. Re1 g5 18. Rf8 b6 19. Qe8 Qxe8 20. Rexe8 Kb7 21. Rxd8 1-0",
    B: "1570",
  },
  {
    W: "2219",
    T: "60+0",
    P: "1. g3 d5 2. Bg2 e6 3. Nf3 Nf6 4. d4 Be7 5. O-O O-O 6. c4 dxc4 7. Qc2 a6 8. Qxc4 b5 9. Qc2 Bb7 10. Nc3 Nbd7 11. e4 c5 12. e5 Nd5 13. Nxd5 Bxd5 14. Be3 c4 15. Rfd1 Rc8 16. Rac1 Nb6 17. a3 h6 18. Qe2 Qd7 19. Nd2 Bxg2 20. Kxg2 a5 21. Qf3 Qd5 22. Ne4 Rfd8 23. Nc3 Qd7 24. h4 b4 25. axb4 axb4 26. Ne4 Nd5 27. Nc5 Bxc5 28. dxc5 Qc6 29. Rxc4 Nxe3+ 30. fxe3 Rxd1 31. Qxc6 Rxc6 32. Kf2 Rd2+ 33. Kf3 Rxb2 0-1",
    B: "2197",
  },
  {
    W: "1310",
    T: "60+0",
    P: "1. e3 c5 2. b3 e6 3. Bb2 d5 4. c4 d4 5. exd4 cxd4 6. d3 e5 7. Nf3 Nd7 8. Be2 Ngf6 9. O-O Bd6 10. a3 O-O 11. b4 Qe7 12. c5 Bc7 13. Nbd2 e4 14. dxe4 Nxe4 15. Nxd4 Qe5 16. Nxe4 Qxh2# 0-1",
    B: "1327",
  },
  {
    W: "1731",
    T: "300+0",
    P: "1. d4 d5 2. c4 e6 3. e3 c6 4. Nf3 Bb4+ 5. Nc3 Bd7 6. a3 Bxc3+ 7. bxc3 Ne7 8. Bb2 Na6 9. Bd3 Nc7 10. O-O dxc4 11. Bxc4 b5 12. Bd3 a5 13. Qc2 Ng6 14. Rfe1 Nd5 15. h3 Nh4 16. Nxh4 Qxh4 17. c4 Nf6 18. cxb5 cxb5 19. Qc5 Rc8 20. Qd6 Bc6 21. Rac1 Be4 22. Rxc8# 1-0",
    B: "1751",
  },
  {
    W: "1982",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Bc5 5. Nc3 d6 6. Be3 Nd4 7. Bxd4 exd4 8. Nd5 Bg4 9. h3 Bh5 10. g4 Bg6 11. Qd2 O-O 12. O-O-O c6 13. Nxf6+ Qxf6 14. Nh2 b5 15. Bb3 a5 16. a4 Bb4 17. Qe2 c5 18. Bd5 Rab8 19. axb5 Rxb5 20. Bc4 Rbb8 21. Rdf1 Qf4+ 22. Kd1 a4 23. Qf3 Qd2# 0-1",
    B: "2000",
  },
  {
    W: "1344",
    T: "300+0",
    P: "1. d4 e6 2. e3 c5 3. Nf3 cxd4 4. Qxd4 Nc6 5. Qc3 Bb4 6. a3 Bxc3+ 7. Nxc3 Nf6 8. Bb5 O-O 9. Bd2 a6 10. Ba4 b5 11. Bb3 Bb7 12. Rg1 d5 13. O-O-O Na5 14. Ba2 Nc4 15. Be1 Qe7 16. Nb1 Rfc8 17. c3 a5 18. Nd4 Ba6 19. g4 Ne4 20. h4 Qxh4 21. f3 Ned6 22. Bxh4 Nxe3 23. g5 Nxd1 24. Rxd1 Nf5 25. Bf2 Nxd4 26. Bxd4 Re8 27. f4 Bc8 28. Rg1 Bd7 29. Bc5 Rac8 30. Bd6 Rc6 31. Be5 Rf8 32. Nd2 f6 33. gxf6 Kh8 34. fxg7+ 1-0",
    B: "1352",
  },
  {
    W: "1670",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bc4 e6 4. Nc3 a6 5. a3 Qc7 6. d4 cxd4 7. Nxd4 Nf6 8. Qe2 Nxd4 0-1",
    B: "1658",
  },
  {
    W: "1754",
    T: "300+0",
    P: "1. d4 e5 2. dxe5 Qe7 3. Nc3 Qxe5 4. Nf3 Qe7 5. e4 d6 6. h3 Nf6 7. Bd3 g6 8. O-O Bg7 9. Bg5 O-O 10. Re1 Nbd7 11. Nd5 Qe6 12. Nxc7 Qe7 13. Nxa8 Ne5 14. Nxe5 dxe5 15. Bc4 Bd7 16. Nc7 Qc5 17. Bxf6 Bxf6 18. Nd5 Bg7 19. Bb3 a5 20. a3 Rc8 21. Nf6+ Bxf6 22. Qxd7 Rc7 23. Qd5 Qb6 24. Rad1 Kg7 25. Qd6 Rc6 26. Qd7 Rc7 27. Qe8 Re7 28. Qc8 Bh4 29. g3 Bxg3 30. Rf1 Bh4 31. Rd8 Qf6 32. Rg8+ Kh6 33. Qg4 Rc7 34. Rd8 b5 35. Rd3 a4 36. Bd5 Rxc2 37. Rf3 Qd8 38. Rxf7 Qb6 39. Qxh4# 1-0",
    B: "1808",
  },
  {
    W: "984",
    T: "300+0",
    P: "1. e4 g6 2. d4 Bg7 3. e5 Nf6 4. Nc3 Nh5 5. Bg5 O-O 6. Bc4 c6 7. d5 b5 8. Bb3 cxd5 9. Nxd5 Qa5+ 10. Bd2 b4 11. Nxe7+ Kh8 12. Qf3 f5 13. O-O-O Qxe5 14. Nxg6+ hxg6 15. Qxa8 Qxb2# 0-1",
    B: "1072",
  },
  {
    W: "1497",
    T: "300+0",
    P: "1. d4 d5 2. Bf4 c5 3. e3 Nc6 4. c4 Nf6 5. Nc3 e6 6. Nf3 Bd6 7. Bxd6 Qxd6 8. Be2 O-O 9. O-O dxc4 10. Bxc4 a6 11. a4 Bd7 12. Bd3 Nb4 13. Ne4 Nxe4 14. Bxe4 Rab8 15. Qe1 f5 16. Bb1 Bc6 17. Ne5 a5 18. Nxc6 Qxc6 19. dxc5 Qxc5 20. Qe2 Rfd8 21. e4 Re8 22. Ra3 f4 23. Rc3 Qg5 24. Qb5 e5 25. Qxa5 Nc6 26. Qd5+ Kh8 27. a5 Rf8 28. Rc4 f3 29. g3 Rfd8 30. Qf7 Qg4 31. Kh1 Rf8 32. Qc7 Qh3 33. Rg1 Nd4 34. Bd3 Ne2 35. Bxe2 fxe2 36. Rcc1 Rxf2 37. Qxb8+ 1-0",
    B: "1466",
  },
  {
    W: "1570",
    T: "300+0",
    P: "1. Nf3 d6 2. d4 c6 3. g3 g6 4. Bg2 Bg7 5. O-O Qb6 6. c4 Nd7 7. Nbd2 Ngf6 8. e3 c5 9. Qb3 cxd4 10. Qxb6 Nxb6 11. exd4 Nfd7 12. Re1 e6 13. Ne4 Ke7 14. Bg5+ Bf6 15. Bxf6+ Nxf6 16. b3 Nxe4 17. Rxe4 Nd7 18. d5 Nf6 19. dxe6 Nxe4 20. exf7 Kxf7 21. Ne5+ dxe5 22. Bxe4 Bf5 23. Bxb7 Rab8 24. Bd5+ Kf6 25. Re1 Rb6 26. Re2 h5 27. f4 exf4 28. gxf4 h4 29. Kf2 h3 30. Kg3 a5 31. Re4 Bxe4 0-1",
    B: "1562",
  },
  {
    W: "1544",
    T: "300+0",
    P: "1. d4 d5 2. e3 Nc6 3. c4 dxc4 4. Bxc4 Bf5 5. a3 e6 6. b4 a6 7. Nf3 h6 8. O-O Qd7 9. Re1 O-O-O 10. e4 Bg4 11. h3 Bh5 12. g4 Bg6 13. Bb2 f6 14. Nc3 Nge7 15. Qd2 h5 16. g5 f5 17. e5 h4 18. Ne2 Nd5 19. Bxd5 Qxd5 20. Nf4 Qxf3 21. Nxg6 Qxh3 22. Nxh8 Be7 23. Ng6 Qg4+ 24. Kf1 Bxg5 25. Qe2 Qh3+ 26. Kg1 Nxd4 27. Bxd4 Rxd4 28. Qe3 Bxe3 29. fxe3 Rg4+ 30. Kf2 Rxg6 31. Ke2 Qg2+ 32. Kd3 Qe4+ 33. Ke2 Rg2+ 34. Kf1 h3 0-1",
    B: "1533",
  },
  {
    W: "1339",
    T: "300+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 c5 5. dxc5 Bxc5 6. Be3 Bxe3 7. fxe3 Qb6 8. Qd4 Qa5+ 9. Nc3 Nc6 10. Qxg7 Qb6 11. Nxd5 Qc5 12. Nc7+ Kd7 13. Qxf7+ Kd8 14. O-O-O+ Bd7 15. Qxd7# 1-0",
    B: "1181",
  },
  {
    W: "1613",
    T: "300+0",
    P: "1. d4 d5 2. c4 Nc6 3. Nc3 Bf5 4. e3 Nf6 5. Nf3 e6 6. Bd3 Bxd3 7. Qxd3 dxc4 8. Qxc4 Bb4 9. a3 Bd6 10. O-O O-O 11. h3 Qe7 12. Qd3 Rfd8 13. b4 Nxb4 14. axb4 Bxb4 15. Ne2 c5 16. Qb5 cxd4 17. Nexd4 Bc3 18. Rb1 b6 19. Nc6 1-0",
    B: "1606",
  },
  {
    W: "1728",
    T: "300+0",
    P: "1. e4 Nc6 2. Nf3 e5 3. Bb5 Bc5 4. Nc3 d6 5. d4 exd4 6. Nxd4 Bd7 7. Nxc6 bxc6 8. Bc4 Nf6 9. Bg5 O-O 10. h3 h6 11. Bh4 g5 12. Bg3 Nh5 13. Qxh5 Kg7 14. O-O-O Qf6 15. e5 Qe7 16. exd6 cxd6 17. Rhe1 Qf6 18. Ne4 Qg6 19. Qxg6+ fxg6 20. Nxd6 Bxf2 21. Re7+ Kf6 22. Rxd7 Bxg3 23. Ne4+ Ke5 24. Nxg3 Rf2 25. Re7+ Kf4 26. Ne2+ Kf5 27. Nd4+ Kf4 28. Rf7+ Kg3 29. Rd3+ Kxg2 30. Rxf2+ Kxf2 31. Rf3+ Kg2 32. Rf6 Re8 33. Rxg6 Re1+ 34. Kd2 Rh1 35. Rxh6 Rh2 36. Kc3 Kg3 37. Be6 g4 38. Bxg4 Rh1 39. Nxc6 Rf1 40. Nxa7 Re1 41. Nc6 Re3+ 42. Kb4 Re4+ 43. Kb3 Re8 44. Rg6 Re3+ 45. c3 Kh4 46. a4 Re5 47. a5 Rb5+ 48. Ka4 Rxb2 49. Ka3 Rh2 50. a6 Rh1 51. Kb2 Rh2+ 52. Kb3 Rh1 53. Nd4 Rb1+ 54. Kc4 Rb8 55. Bc8 Rxc8+ 56. Kd3 Rd8 57. a7 Kh5 58. Ra6 Ra8 59. Nc6 Kg6 0-1",
    B: "1712",
  },
  {
    W: "1113",
    T: "300+0",
    P: "1. d4 d5 2. Bf4 f6 3. e3 Nc6 4. Nf3 e5 5. c3 exf4 6. exf4 Bg4 7. Bb5 Qd7 8. h3 Bxf3 9. Qxf3 O-O-O 10. Bxc6 Qxc6 11. O-O Nh6 12. Nd2 Re8 13. Rae1 Bd6 14. Rxe8+ Rxe8 15. Nb3 Qb5 16. Nc5 Bxc5 17. dxc5 Qxc5 18. Rd1 g5 19. fxg5 fxg5 20. Kh2 Qd6+ 21. g3 Rf8 22. Qh5 Rxf2+ 23. Kg1 Qxg3+ 24. Kh1 Qg2# 0-1",
    B: "1140",
  },
  {
    W: "1730",
    T: "120+1",
    P: "1. e4 c5 2. f4 Nc6 3. c3 e6 4. d4 d5 5. e5 cxd4 6. cxd4 Bb4+ 7. Nc3 Nge7 8. Bd2 a6 9. Nf3 Bxc3 10. bxc3 b5 11. Bd3 Na5 12. O-O Nc4 13. Bc1 a5 14. Qe2 Ba6 15. Nd2 Rc8 16. Nxc4 bxc4 17. Bc2 O-O 18. Bb2 Qd7 19. Rf3 a4 20. Rh3 g6 21. Qg4 Rb8 22. Qh4 h5 23. Ba3 Nf5 24. Bxf5 exf5 25. Qg5 Rfc8 26. Rxh5 1-0",
    B: "1730",
  },
  {
    W: "1803",
    T: "120+1",
    P: "1. e4 e6 2. d4 d5 3. e5 Ne7 4. c4 c6 5. c5 Nf5 6. g4 Nh6 7. Bxh6 gxh6 8. Nd2 Qh4 9. Qf3 Nd7 10. Qh3 Qg5 11. Ne2 Bg7 12. f4 Qe7 13. f5 exf5 14. gxf5 Bf8 15. f6 Qe6 16. Qxe6+ fxe6 17. Bh3 Kf7 18. Rg1 h5 19. Nf4 h4 20. Bxe6+ Ke8 21. Nf3 b5 22. Ng5 Kd8 23. Nf7+ Kc7 24. Nxh8 Nxc5 25. dxc5 Bxc5 26. Bxc8 Rxc8 27. Nf7 Bxg1 28. Ne6+ Kb6 29. O-O-O Bxh2 30. b4 Bg3 31. Neg5 a5 32. a3 axb4 33. axb4 Ra8 34. Kb2 Ra4 35. Kb3 h6 36. e6 hxg5 37. e7 c5 38. bxc5+ Kxc5 39. e8=Q Rb4+ 40. Kc3 Rc4+ 41. Kd2 Bf4+ 42. Ke2 Rc2+ 43. Kf1 Rh2 44. Qc8+ Kb4 45. Rb1+ Ka5 46. Qa8+ Kb6 47. Qb8+ Kc5 48. Qxf4 gxf4 49. Ne5 Rh1+ 50. Kf2 Rxb1 51. f7 Rb2+ 52. Kf3 1-0",
    B: "1809",
  },
  {
    W: "1071",
    T: "120+1",
    P: "1. e4 e5 2. f3 d6 3. Bb5+ Bd7 4. d3 Bxb5 5. Nh3 Bd7 6. Ng5 f6 7. Qd2 h6 8. Qa5 fxg5 9. Qxc7 Qxc7 0-1",
    B: "964",
  },
  {
    W: "1999",
    T: "120+1",
    P: "1. e4 e6 2. b3 d5 3. Bb2 c5 4. exd5 exd5 5. d4 Nf6 6. Nf3 Nc6 7. Be2 Bg4 8. O-O cxd4 9. Nxd4 Bxe2 10. Qxe2+ Be7 11. Nxc6 bxc6 12. Re1 Ne4 13. f3 Bc5+ 14. Kf1 Bf2 15. Rd1 Qh4 16. fxe4 O-O 17. Qxf2 Qxh2 18. exd5 cxd5 19. Rd3 Rae8 20. Nd2 Re6 21. Nf3 Qh5 22. Rad1 Rfe8 23. Rxd5 Qg4 24. Rg5 Qf4 25. Rxg7+ Kf8 26. Qc5+ R6e7 27. Re1 f6 28. Rgxe7 Rxe7 29. Qxe7+ Kg8 30. Qe8+ Kg7 31. Re7+ Kh6 32. Qf8+ Kg6 33. Rg7+ Kf5 34. Qc5+ Ke4 35. Qd4+ Kf5 36. Qxf6+ Ke4 37. Re7+ Kd5 38. Qxf4 h5 39. Qd4+ Kc6 40. Qd7+ Kc5 41. Ba3+ Kb6 42. Qc7+ Kb5 43. c4+ Ka6 44. Re6# 1-0",
    B: "1843",
  },
  {
    W: "1282",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 c6 5. Qf3 g6 6. Bc4 d5 7. exd5 cxd5 8. Bxd5 Nf6 9. Bxf7+ Kxf7 10. Qb3+ Kg7 11. Be3 Be7 12. O-O Bd6 13. Ne6+ Bxe6 14. Qxe6 Bxh2+ 15. Kxh2 Re8 16. Qb3 1-0",
    B: "1270",
  },
  {
    W: "2081",
    T: "120+1",
    P: "1. c4 Nf6 2. Nc3 g6 3. g3 Bg7 4. Bg2 O-O 5. Nf3 d6 6. O-O c5 7. d4 cxd4 8. Nxd4 a6 9. Nc2 Nc6 10. e4 Qc7 11. b3 Nd7 12. Bb2 e6 13. Qd2 Nc5 14. Rad1 Rd8 15. b4 Nd7 16. Qxd6 Qxd6 17. Rxd6 Nde5 18. Rxd8+ Nxd8 19. Ne3 Ndc6 20. a3 Nd3 21. Ba1 Nd4 22. Rd1 Nb3 23. Rxd3 Nxa1 24. Na4 b5 25. Nb6 Rb8 26. Rd8+ Bf8 27. Nxc8 Kg7 28. c5 Be7 29. Re8 Bf6 30. Ng4 Nc2 31. Nxf6 Kxf6 32. f4 Nxa3 33. e5+ Kg7 34. Nd6 Rxe8 35. Nxe8+ Kf8 36. Nc7 Nc2 37. Nxa6 1-0",
    B: "2094",
  },
  {
    W: "1452",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. Bxc6+ bxc6 5. d4 Nf6 6. dxe5 dxe5 7. Qxd8+ Kxd8 8. Nxe5 Bd7 9. Nxf7+ 1-0",
    B: "1434",
  },
  {
    W: "1411",
    T: "120+1",
    P: "1. g3 d5 2. Bg2 Nf6 3. Nf3 c5 4. O-O Nc6 5. d3 e5 6. Bg5 Be7 7. Nbd2 Be6 8. b3 O-O 9. c4 d4 10. e4 h6 11. Bxf6 Bxf6 12. a3 g5 13. Qc1 g4 14. Ne1 h5 15. f3 h4 16. Qd1 hxg3 17. hxg3 Kg7 18. fxg4 Qe7 19. Nef3 Rh8 20. g5 Bxg5 21. Nxg5 Qxg5 22. Nf3 Qxg3 23. Ra2 Rh6 24. Qe1 Qg6 25. Nh4 Qg4 26. Nf5+ Bxf5 27. exf5 Rah8 28. f6+ Kf8 29. Qe2 Rh1+ 0-1",
    B: "1398",
  },
  {
    W: "1692",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 e6 4. c3 Nc6 5. Bb3 Ne5 6. O-O Nxf3+ 7. Qxf3 Nf6 8. Re1 Be7 9. Bc2 a6 10. d4 cxd4 11. cxd4 Rb8 12. b3 b5 13. Nd2 Bb7 14. Nf1 Rc8 15. Bd3 e5 16. d5 Rc3 17. Bd2 Rc8 18. Ng3 Qb6 19. Nf5 h6 20. Nxg7+ Kf8 21. Nf5 Rg8 22. Nxh6 Rxg2+ 23. Kxg2 Qd4 24. Nf5 Nxd5 25. Bh6+ Ke8 26. exd5 Bxd5 27. Ng7+ Kd8 28. Be4 Bxe4 29. Qxe4 Qc3 30. Qf3 Qd4 31. Qxf7 Qe4+ 32. Rxe4 1-0",
    B: "1625",
  },
  {
    W: "2392",
    T: "180+0",
    P: "1. Nf3 Nf6 2. c4 e6 3. Nc3 d5 4. d4 Bb4 5. g3 O-O 6. Bg2 c6 7. O-O a6 8. Qc2 dxc4 9. Ne5 b5 10. Nxc6 Nxc6 11. Bxc6 Ra7 12. Bg2 Bb7 13. e4 Bxc3 14. bxc3 Ra8 15. Bg5 h6 16. Bxf6 Qxf6 17. e5 Qe7 18. Bxb7 Qxb7 19. a4 Rab8 20. axb5 axb5 21. Rab1 Qd5 22. Rb4 h5 23. h4 f6 24. Re1 Qf3 25. Re3 Qg4 26. Qe2 Qg6 27. exf6 Rxf6 28. Re5 Rbf8 29. Qxh5 Qxh5 30. Rxh5 Rxf2 31. Rhxb5 R8f3 32. Rxc4 Rc2 33. Re5 Rxg3+ 34. Kf1 Rh3 35. Kg1 Rg3+ 36. Kf1 Rh3 37. Kg1 Rd3 38. Re1 Kh7 39. Rc6 Rcxc3 40. Rcxe6 Rg3+ 41. Kh2 Rh3+ 42. Kg2 Rxh4 43. R6e3 Rxe3 44. Rxe3 Rxd4 45. Rh3+ Kg6 46. Re3 Rg4+ 47. Kf3 Rg5 48. Re6+ Kf7 49. Rb6 Rg6 50. Rb7+ Kg8 51. Ke4 Kh7 52. Kf5 Rf6+ 53. Kg5 Rh6 54. Rf7 Rg6+ 55. Kf5 Rf6+ 56. Rxf6 gxf6 57. Kxf6 1/2-1/2",
    B: "2343",
  },
  {
    W: "2143",
    T: "30+0",
    P: "1. d4 c5 2. Nf3 cxd4 3. c3 Nc6 4. cxd4 d5 5. Nc3 Nf6 6. Bg5 e6 7. e3 Be7 8. Be2 h6 9. Bf4 O-O 10. O-O Bd7 11. Rc1 a6 12. a3 Rc8 13. b4 b5 14. Qb3 Nb8 15. a4 bxa4 16. Nxa4 Bb5 17. Nc5 Nc6 18. Bxb5 axb5 19. Ra1 Bxc5 20. bxc5 Ra8 21. Rxa8 Qxa8 22. Qxb5 Rc8 23. Ne5 Nxe5 24. Bxe5 Ne4 25. f3 Nd2 26. Rd1 Nc4 27. h3 Nxe3 28. Re1 Nc4 29. Qb3 Rb8 30. Qd3 Rb2 31. Rf1 Qa2 32. Rf2 Rxf2 33. Qf1 Rxf1+ 34. Kxf1 Qb1+ 35. Kf2 Qb3 36. Kg3 Nxe5 37. dxe5 Qc4 38. f4 Qxc5 39. Kh4 Qc6 40. g3 Qd7 41. g4 Qe8 42. Kg3 f6 43. h4 f5 44. g5 Qf7 45. Kh3 g6 46. gxh6 Qh7 47. Kg3 Qxh6 0-1",
    B: "2373",
  },
  {
    W: "1217",
    T: "600+0",
    P: "1. c4 e6 2. d4 Na6 3. Nf3 Bb4+ 4. Bd2 Qe7 5. a3 c5 6. axb4 Nxb4 7. dxc5 Qxc5 8. Be3 Qb6 1-0",
    B: "1152",
  },
  {
    W: "1876",
    T: "600+0",
    P: "1. e4 c5 2. a3 Nc6 3. b4 b6 4. b5 Nd4 5. Bc4 a5 6. a4 d6 7. Ne2 Be6 8. Bxe6 Nxe6 9. O-O Nf6 10. d3 g6 11. f4 Bg7 12. Bb2 O-O 13. Nd2 Ng4 14. Bxg7 Kxg7 15. Rf3 Nd4 16. Rg3 Nh6 17. Nxd4 cxd4 18. Nc4 f5 19. e5 d5 20. Nb2 e6 21. h4 Qc7 22. Rc1 Qc3 23. Rb1 Rac8 24. h5 Ng4 25. hxg6 hxg6 26. Qc1 Qxc2 27. Qxc2 Rxc2 28. Nd1 Rfc8 29. Nb2 Rc1+ 30. Rxc1 Rxc1+ 0-1",
    B: "1857",
  },
  {
    W: "1636",
    T: "600+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 d6 4. Bc4 Be6 5. Bxe6 fxe6 6. d4 Be7 7. Bxf4 Bh4+ 8. g3 Bf6 9. O-O Ne7 10. c4 Ng6 11. Qc1 c6 12. e5 Nxf4 13. Qxf4 dxe5 14. dxe5 Qb6+ 15. Kh1 Be7 16. b3 Na6 17. Qf7+ Kxf7 18. Ng5+ Kg8 19. Nxe6 c5 20. Nf4 Nb4 21. Nc3 Nc2 22. Rac1 Ne3 23. Rf3 Qc6 24. Nfd5 Bg5 25. Re1 Nxd5 26. Nxd5 Rf8 27. Rxf8+ Kxf8 28. Rf1+ Ke8 29. e6 Rf8 30. Re1 Rf2 31. e7 Bf6 32. Kg1 Bd4 33. Ne3 Bxe3 34. Rxe3 Qg2# 0-1",
    B: "1638",
  },
  {
    W: "984",
    T: "600+0",
    P: "1. Nf3 d5 2. d4 Nc6 3. e3 Nf6 4. Bd3 e6 5. c3 Bd6 6. e4 dxe4 7. Bxe4 Nxe4 8. O-O e5 9. Qe1 f5 10. dxe5 Nxe5 11. Bf4 Nxf3+ 12. gxf3 Bxf4 13. fxe4 Qh4 14. f3 Qxh2# 0-1",
    B: "1062",
  },
  {
    W: "1491",
    T: "600+0",
    P: "1. e4 e6 2. Nf3 a6 3. d4 h6 4. Qe2 Nf6 5. e5 Nd5 6. c4 Nb4 7. Bd2 Nc2+ 8. Kd1 Nxa1 9. Nc3 c6 10. g3 Bb4 11. Bg2 Qa5 12. Qe4 Bxc3 13. bxc3 Qa4+ 14. Ke2 Qxa2 15. Qg4 Qxc4+ 16. Ke3 Nc2+ 17. Ke4 Qd5+ 18. Kd3 Nxd4 19. Nxd4 Qxg2 20. Re1 Qd5 21. Qxg7 Rf8 22. Bxh6 Qc5 23. Re4 d5 24. Rf4 Nd7 25. Kd2 Nxe5 26. Qxe5 Rg8 27. Qf6 Qe7 28. Qe5 Bd7 29. Qc7 f5 30. Qxb7 Rd8 31. Nxc6 Bxc6 32. Qxc6+ Kf7 33. Qxa6 Rg6 34. Rh4 Rxh6 35. Rxh6 Qg5+ 36. f4 Qxh6 37. Qb7+ Ke8 38. Qc6+ Rd7 39. Qc8+ Ke7 40. Qg8 Qxh2+ 41. Kd3 d4 42. cxd4 Qf2 43. Qg7+ Kd8 44. Qf6+ Kc8 45. Qxe6 Qxd4+ 46. Kc2 Qf2+ 47. Kc3 Qc5+ 48. Kb3 Qb5+ 49. Kc3 Kd8 50. Qg8+ Ke7 51. Qg7+ Ke8 52. Qe5+ Qxe5+ 53. fxe5 Ke7 54. Kc4 Ke6 55. Kc5 Kxe5 56. Kc6 Rd1 57. g4 fxg4 0-1",
    B: "1462",
  },
  {
    W: "1358",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 d6 3. Nc3 Nf6 4. b3 a6 5. d4 cxd4 6. Nxd4 Nc6 7. Nf3 b5 8. Ba3 Bb7 9. Nd5 Nxd5 10. exd5 Ne5 11. Ng5 h6 12. Nf3 Nxf3+ 13. Qxf3 Qa5+ 14. b4 Qb6 15. Bd3 Qd4 16. O-O Bxd5 17. Rad1 Bxf3 18. gxf3 Qc3 19. Rb1 Qxa3 20. Rb3 Qxa2 21. Be4 Qxc2 22. Bxc2 Rc8 23. Bf5 e6 24. Bh3 Be7 25. Ra1 Ra8 26. Rc3 O-O 27. Bg2 d5 28. Rc7 Bxb4 29. f4 Bd6 30. Rc6 Bxf4 31. Rc3 Be5 32. Bxd5 Bxc3 33. Rc1 exd5 34. Rxc3 Rac8 35. Ra3 Rc1+ 36. Kg2 Ra8 37. Rd3 Rc5 0-1",
    B: "1413",
  },
  {
    W: "1896",
    T: "600+0",
    P: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. Nf3 e6 7. c3 Nd7 8. Bd3 Bxd3 9. Qxd3 Bd6 10. O-O Ne7 11. Ne4 Bc7 12. Re1 Ng6 13. Bg5 Qb8 14. h3 O-O 15. Nc5 Nxc5 16. dxc5 h6 17. Be3 Rd8 18. Qe2 Bf4 19. Rad1 Bxe3 20. Qxe3 Qf4 21. Qxf4 Nxf4 22. Kh2 Nd3 23. Re2 Nxc5 24. Red2 Rxd2 25. Rxd2 Ne4 26. Re2 Nf6 27. Rd2 Ne4 28. Re2 Nc5 29. Rd2 Ne4 1/2-1/2",
    B: "2028",
  },
  {
    W: "1449",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 a6 5. a3 b5 6. Ba2 d5 7. exd5 Nxd5 8. Bg5 f6 9. Be3 Bb7 10. Nc3 Nd4 11. Nxd5 Bxd5 12. Bxd5 Qxd5 13. O-O Nxf3+ 0-1",
    B: "1449",
  },
  {
    W: "1769",
    T: "600+0",
    P: "1. d4 e6 2. e4 d5 3. e5 c5 4. Bb5+ Bd7 5. Bxd7+ Qxd7 6. c3 Nc6 7. Nf3 Nge7 8. Bg5 h6 9. Bh4 Nf5 10. Bg3 cxd4 11. cxd4 Bb4+ 12. Nc3 O-O 13. Bf4 Rac8 14. O-O a6 15. a3 Be7 16. Ne2 g5 17. Be3 Kh7 18. Qd3 Rg8 19. Ng3 f6 20. Nh5 fxe5 21. Nxe5 Nxe5 22. dxe5 Kh8 23. Nf6 Bxf6 24. exf6 Rcf8 25. Bd4 Rf7 26. Be5 Nd6 27. Rfc1 b5 28. Rc2 Nc4 29. Bd4 Nd6 30. Rac1 Ne4 31. f3 Nd6 32. Be5 Nc4 33. b3 Nxe5 34. Qe2 Ng6 35. Qd3 Nf4 36. Qd4 Qc7 37. Rxc7 Rxc7 38. f7+ Kh7 39. fxg8=Q+ Kxg8 40. Qxf4 gxf4 41. Rxc7 1-0",
    B: "1710",
  },
  {
    W: "1124",
    T: "600+0",
    P: "1. e4 g6 2. d4 Bg7 3. d5 d6 4. Nc3 h5 5. Qd3 e5 6. h4 Ne7 7. Bg5 f6 8. Bxf6 Bxf6 9. O-O-O O-O 10. f3 b6 11. Nh3 Ba6 12. Nb5 Qd7 13. Qe3 Bxb5 14. Bxb5 Qxb5 15. Ng5 Bxg5 16. Qxg5 Na6 17. Qxe7 Nb4 18. Kb1 Qa4 19. a3 Qxc2+ 20. Ka1 c5 21. dxc6 Nxc6 22. Qxd6 Rfc8 23. Qxg6+ Kf8 24. Rd7 Ne7 25. Qe6 Re8 26. Qxe5 Qxg2 27. Rdd1 Qxf3 28. Qh8+ Ng8 29. Rdf1 Qxf1+ 30. Rxf1+ Ke7 31. Qxh5 Nf6 32. Qe5+ Kf7 33. Qxf6+ Kg8 34. Qg6+ Kh8 35. Qh6+ Kg8 36. Rg1+ Kf7 37. Qg7+ Ke6 38. h5 Re7 39. Qg6+ Ke5 40. h6 Rh8 41. Rg5+ Kd4 42. Qd6+ Kc4 43. Qxe7 Rxh6 44. Qxa7 Rh1+ 45. Ka2 b5 46. b3+ Kc3 47. Qc5+ Kd3 48. Qd5+ Kc3 49. e5 Kc2 50. e6 Rb1 51. a4 Rb2+ 52. Ka3 Kb1 53. Rg1+ Kc2 54. Kb4 bxa4 55. Kxa4 Ra2+ 56. Kb4 Kb2 57. e7 Ra3 58. e8=Q Rxb3+ 59. Qxb3# 1-0",
    B: "1129",
  },
  {
    W: "1610",
    T: "600+0",
    P: "1. e4 e5 2. Bc4 Nf6 3. Qf3 Be7 4. d3 O-O 5. Ne2 c6 6. h3 d5 7. exd5 cxd5 8. Bb3 Nc6 9. Be3 Bd6 10. Ng3 Nd4 11. Bxd4 exd4 12. O-O h6 13. Bxd5 Nh7 14. Bxb7 Bxb7 15. Qxb7 Ng5 16. Ne4 Nxe4 17. Qxe4 Re8 18. Qg4 Re6 19. Kh1 Rg6 20. Qxd4 Rc8 21. c4 Qg5 22. Qe4 Rf6 23. Nc3 Rf4 24. Qd5 Be5 25. Rfe1 Rxf2 26. Rxe5 Rd8 27. Rxg5 1-0",
    B: "1506",
  },
  {
    W: "2058",
    T: "180+1",
    P: "1. Nf3 e6 2. d4 d5 3. c4 Nf6 4. Nc3 c6 5. Bg5 Be7 6. e3 Nbd7 7. Rc1 dxc4 8. Bxc4 b5 9. Bd3 Bb7 10. Qe2 O-O 11. e4 h6 12. Bf4 Rc8 13. g4 Nh7 14. h4 f6 15. Bb1 a6 16. Qd3 c5 17. e5 f5 18. g5 hxg5 19. hxg5 b4 20. Ne2 Be4 21. Qe3 cxd4 22. Nexd4 Rxc1+ 23. Qxc1 Nc5 24. g6 Bxb1 25. gxh7+ Kh8 26. Qxb1 Qd5 27. Ke2 Rc8 28. Rc1 Rd8 29. Qc2 Ne4 30. Qc6 Qxa2 31. Qxe6 Qxb2+ 32. Rc2 Nc3+ 33. Kf1 Qb1+ 34. Kg2 Rxd4 35. Nxd4 Ne2 36. Qg8# 1-0",
    B: "1956",
  },
  {
    W: "1449",
    T: "180+2",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 Nf6 5. Bd3 b6 6. Nc3 Bc5 7. Qe2 O-O 8. Bf4 d5 9. O-O-O d4 10. Na4 Be6 11. Bg5 h6 12. Bxf6 Qxf6 13. Qf3 Qe7 14. Kb1 a5 15. Bb5 Nb4 16. a3 c6 17. axb4 cxb5 18. Nxc5 bxc5 19. bxc5 Qxc5 20. Qa3 b4 21. Qa4 Rab8 22. Ne2 b3 23. Nxd4 bxc2+ 24. Qxc2 Qb6 25. Nxe6 Rfc8 26. Qe2 Qxe6 27. Rd2 a4 0-1",
    B: "1476",
  },
  {
    W: "1813",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. d4 exd4 5. O-O Bc5 6. c3 Qf6 7. cxd4 Bxd4 8. Nxd4 Nxd4 9. e5 Qb6 10. Be3 c5 11. Nc3 Ne7 12. a4 a6 13. a5 Qa7 14. Ne2 Nec6 15. b4 Nxe5 16. Nxd4 Nxc4 17. bxc5 Nxe3 18. Re1 O-O 19. Rxe3 Qxc5 20. Nb3 Qa7 21. Qd6 f5 22. Re7 Qb8 23. Qd4 Rf7 24. Qd5 Qc7 25. Qxf7+ 1-0",
    B: "1847",
  },
  {
    W: "1364",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 h6 5. O-O Nf6 6. Re1 a6 7. h3 d6 8. d4 exd4 9. cxd4 Bb4 10. Nc3 Bd7 11. a3 Ba5 12. b4 Bb6 13. Bf4 Qe7 14. e5 dxe5 15. dxe5 O-O-O 16. exf6 Qxf6 17. Nd5 Qb2 18. Nxb6+ cxb6 19. Qd6 Rhf8 1-0",
    B: "1324",
  },
  {
    W: "1362",
    T: "180+2",
    P: "1. d4 d5 2. Nc3 Nf6 3. Bf4 Bf5 4. e3 e6 5. Bd3 c5 6. Bxf5 exf5 7. Nf3 Nc6 8. O-O cxd4 9. Nxd4 Nxd4 10. Qxd4 Bd6 11. Rfe1 O-O 12. Nxd5 Bxf4 13. Nxf6+ Qxf6 14. Qxf4 Qxb2 15. Rab1 Qxc2 16. Rxb7 Qxa2 17. Qxf5 Qc4 18. Reb1 Qe6 19. Qa5 Qe4 20. h3 h6 21. R1b4 Qc2 22. Rxa7 Rxa7 23. Qxa7 Qd1+ 24. Kh2 Qe1 25. Rf4 Qe2 26. Qa4 g5 27. Rf3 Kg7 28. Qd4+ f6 29. Rxf6 Rxf6 30. Qd7+ Rf7 31. Qd4+ Kg8 32. Qd8+ Rf8 33. Qd5+ Kg7 34. Qe5+ Rf6 35. Qe7+ Kg6 36. Qe4+ Kh5 37. Qe8+ Rg6 38. g4+ Kh4 39. Qxg6 Qxf2+ 40. Kh1 Kg3 41. Qd6+ Kxh3 42. Qxh6+ Kxg4 43. Qe6+ Kg3 44. Qe5+ Kh4 45. Qe4+ Kh3 46. Qh7+ Qh4 47. Qxh4+ Kxh4 48. e4 Kg4 49. e5 Kf5 50. e6 Kxe6 51. Kg2 Kf5 52. Kf3 g4+ 53. Kg3 Kg5 54. Kg2 Kh4 55. Kf2 Kh3 56. Kg1 g3 57. Kh1 Kh4 58. Kg2 Kg4 59. Kg1 Kf3 60. Kh1 Kf2 1/2-1/2",
    B: "1347",
  },
  {
    W: "2218",
    T: "180+2",
    P: "1. d4 Nf6 2. c4 e6 3. Nc3 c5 4. d5 exd5 5. cxd5 d6 6. Nf3 g6 7. g3 Bg7 8. Bg2 O-O 9. O-O a6 10. a4 Nbd7 11. Nd2 Re8 12. h3 Rb8 13. Nc4 Qc7 14. Bf4 Bf8 15. a5 b5 16. axb6 Nxb6 17. Na5 Bd7 18. Nc6 Bxc6 19. dxc6 Nh5 20. Bc1 Ra8 21. g4 Nf6 22. Bf4 Nc4 23. b3 Ne5 24. Bg3 Nxc6 25. g5 Nh5 26. Nd5 Qd8 27. Bh2 Ne5 28. f4 Nc6 29. Bf3 Bg7 30. Nf6+ Nxf6 31. gxf6 Bxf6 32. Bxc6 Bd4+ 33. Kg2 Re3 34. Bg1 Rc8 35. Bxe3 Bxe3 36. Bf3 Qf6 37. Rxa6 Qxf4 38. Qxd6 Qg5+ 39. Qg3 Qe7 40. Ra8 Rxa8 41. Bxa8 h5 42. Qf3 Bd4 43. Bd5 Qg5+ 44. Kh1 Kh8 45. Bxf7 1-0",
    B: "2389",
  },
  {
    W: "1649",
    T: "180+2",
    P: "1. d4 d5 2. c4 e6 3. a3 Nf6 4. Nc3 Be7 5. Bf4 c6 6. e3 O-O 7. Nf3 Nbd7 8. Qb3 Nb6 9. Be2 dxc4 10. Bxc4 Nxc4 11. Qxc4 Re8 12. O-O a6 13. b4 b5 14. Qd3 Nd5 15. Nxd5 cxd5 16. Ne5 Bb7 17. Rac1 Rc8 18. Qd2 h6 19. h3 a5 20. Nd3 axb4 21. axb4 Qd7 22. Nc5 Bxc5 23. dxc5 Ra8 24. Bd6 Ra4 25. Rc2 Rea8 26. Rfc1 Bc6 27. Be5 Qd8 28. Bc3 Qg5 29. Qd4 e5 30. Qg4 Qg6 31. Qxg6 fxg6 32. Rd1 Ra1 33. Rxa1 1-0",
    B: "1649",
  },
  {
    W: "1766",
    T: "180+2",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Qb6 5. Nf3 cxd4 6. cxd4 Bd7 7. Nc3 Bb4 8. Be2 Bb5 9. O-O Bxe2 10. Qxe2 Bxc3 11. bxc3 Ne7 12. Ba3 Nbc6 13. Rab1 Qc7 14. Qb5 b6 15. Bxe7 Kxe7 16. Qd3 g6 17. Qe3 Qd8 18. Ng5 h6 19. Nf3 Na5 20. Qf4 Kf8 21. Nh4 Qg5 22. Qxg5 hxg5 23. Nf3 g4 24. Nd2 Rc8 25. Rfc1 Ke7 26. f3 gxf3 27. Nxf3 Nc4 28. Kf2 Rc7 29. h3 Rhc8 30. Rb3 Na5 31. Ra3 Rc4 32. Ke2 R4c7 33. Kd3 Nc4 34. Rb3 Rc6 35. Nd2 a6 36. Nxc4 dxc4+ 37. Kd2 cxb3 38. axb3 b5 39. Kd3 Kd7 40. c4 bxc4+ 41. bxc4 Rb8 42. Ke4 Rb4 43. Kd3 a5 44. c5 Rb3+ 45. Kc2 Rg3 46. Rg1 Kc7 47. Kb2 Ra6 48. Ka2 Kc6 49. Rd1 Rxg2+ 50. Ka3 Rg3+ 51. Ka4 Rxh3 52. d5+ exd5 53. Rd4 Kxc5 54. Rf4 Re3 55. Rxf7 Rxe5 56. Rc7+ Kd4 57. Kb5 Ra8 58. Rb7 a4 0-1",
    B: "1729",
  },
  {
    W: "1213",
    T: "180+2",
    P: "1. d4 d5 2. Nf3 c5 3. Bf4 Nc6 4. e3 e6 5. Bd3 b6 6. O-O Bb7 7. h3 Nf6 8. Nbd2 Be7 9. Re1 O-O 10. Nf1 cxd4 11. Nxd4 Nxd4 12. exd4 Bd6 13. Bxd6 Qxd6 14. Ne3 Rac8 15. Ng4 Rfe8 16. Nxf6+ gxf6 17. Qh5 Kf8 18. Qxh7 Ke7 19. Qh4 Rh8 20. Qg3 Qd7 21. b3 Rcg8 22. Qf3 Qd6 23. g3 e5 24. dxe5 fxe5 25. g4 f6 26. c4 Bc8 27. cxd5 Bb7 28. Be4 Bc8 29. Bf5 Bxf5 30. Qxf5 Qxd5 31. g5 Qf7 32. f4 Qg6 33. Qxg6 Rxg6 34. fxe5 Rxg5+ 35. Kh2 Rxe5 36. Rxe5+ fxe5 37. Re1 Ke6 38. Rg1 Rh6 39. Rg7 a5 40. Ra7 Kd5 41. Rb7 Rf6 42. a4 e4 43. Rd7+ Ke5 44. Rd2 e3 45. Re2 Kd4 46. Kg3 Kd3 47. Re1 Kd2 48. Ra1 e2 49. Ra2+ Ke3 50. h4 e1=Q+ 51. Kg4 Qg1+ 52. Kh5 Qg6# 0-1",
    B: "1246",
  },
  {
    W: "1954",
    T: "180+2",
    P: "1. e4 c5 2. Nc3 d6 3. f4 Nc6 4. Nf3 Bg4 5. h3 Bxf3 6. Qxf3 e5 7. d3 Nf6 8. Bd2 Be7 9. g3 Nd4 10. Qd1 b5 11. Bg2 b4 12. Ne2 Rc8 13. c3 Nxe2 14. Qxe2 bxc3 15. bxc3 O-O 16. O-O Qc7 17. g4 Nd7 18. f5 Rb8 19. g5 f6 20. h4 Rb2 21. Rfb1 Rfb8 22. Rxb2 Rxb2 23. Qe3 Qa5 24. c4 0-1",
    B: "1930",
  },
  {
    W: "1661",
    T: "180+0",
    P: "1. d4 e6 2. e3 d5 3. c4 dxc4 4. Bxc4 Nf6 5. Qb3 Nc6 6. Nc3 Na5 7. Qa4+ c6 8. Bd3 Bd7 9. Qc2 c5 10. Nf3 cxd4 11. Nxd4 Nc6 12. Nxc6 Bxc6 13. O-O Nd5 14. Rd1 Nxc3 15. bxc3 Qd5 16. Be4 Qc5 17. Bxc6+ Qxc6 18. Bb2 f6 19. Rd3 e5 20. Rad1 Bc5 21. Rd7 Qxd7 22. Rxd7 Kxd7 23. Qd3+ Bd6 24. g3 Ke6 25. c4 Rad8 26. Qd5+ Ke7 27. Qxb7+ Rd7 28. Qe4 h6 29. f4 Kf7 30. fxe5 Bxe5 31. Bxe5 fxe5 32. Qxe5 Re7 33. Qf4+ Kg6 34. e4 Rhe8 35. Qg4+ Kh7 36. Kg2 Rxe4 37. Qf3 Re3 38. Qb7 R3e7 39. Qb1+ Kg8 40. c5 Re2+ 0-1",
    B: "1698",
  },
  {
    W: "2078",
    T: "180+0",
    P: "1. d4 b6 2. Nf3 e6 3. c4 Bb7 4. Nc3 Bb4 5. Bd2 Nf6 6. a3 Be7 7. Qc2 d5 8. e3 Ne4 9. Nxe4 dxe4 10. Ne5 Nd7 11. Qa4 f6 12. Nc6 Bd6 13. Nxd8 1-0",
    B: "2069",
  },
  {
    W: "1755",
    T: "180+0",
    P: "1. b3 e6 2. Bb2 d6 3. g3 Ne7 4. Bg2 Nd7 5. e4 b6 6. e5 Rb8 7. exd6 cxd6 8. Ne2 Bb7 9. Bxb7 Rxb7 10. Nbc3 g6 11. Nb5 Rg8 12. Ned4 e5 13. Nf3 Bg7 14. Nxd6+ Kf8 15. Nxb7 Qa8 16. Nd6 e4 17. Bxg7+ Rxg7 18. Nd4 e3 19. Qf3 exd2+ 20. Kxd2 Qxf3 21. Nxf3 Nf5 22. Nxf5 gxf5 23. Rhe1 Nc5 24. c4 Ne4+ 25. Ke3 f6 26. Nd2 Nc5 27. Kf3 Nd3 28. Re3 Ne5+ 29. Kf4 Rg4+ 30. Kxf5 Kf7 31. Rxe5 Rg5+ 32. Kf4 Rxe5 33. Ne4 Rf5+ 34. Kxf5 1-0",
    B: "1760",
  },
  {
    W: "1373",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 e6 3. a3 c6 4. e3 Na6 5. Nc3 Nc7 6. Bd3 d5 7. cxd5 Nfxd5 8. Nf3 Nxc3 9. bxc3 Nd5 10. Bb2 Qb6 11. Qd2 Bd6 12. e4 Nf4 13. Ne5 Nxd3+ 14. Nxd3 Bd7 15. c4 Qc7 16. c5 Bxh2 17. g3 Bxg3 18. fxg3 Qxg3+ 19. Kd1 Qf3+ 20. Qe2 Qxh1+ 21. Ne1 O-O 22. e5 f6 23. Kd2 fxe5 24. Nf3 exd4 25. Rxh1 d3 26. Qxd3 Rad8 27. Qxh7+ Kf7 28. Ng5+ Ke7 29. Qxg7+ Ke8 30. Bf6 Rc8 31. Qe7# 1-0",
    B: "1323",
  },
  {
    W: "1878",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bc4 e5 7. Nf3 Be7 8. O-O O-O 9. Bg5 h6 10. Bh4 a6 11. Bb3 Be6 12. Qd2 Qc7 13. Rad1 Rad8 14. Bxf6 Bxf6 15. Nd5 Bxd5 16. Qxd5 Ne7 17. Qd2 Ng6 18. g3 Rd7 19. Ba4 b5 20. Bb3 Rfd8 21. Qd5 Ne7 22. Qd2 Nc8 23. Rc1 Qb7 24. Bd5 Qb8 25. c4 Nb6 26. b3 Nxd5 27. cxd5 g5 28. Rc6 Qb7 29. Rfc1 g4 30. Qxh6 gxf3 31. Qxf6 1-0",
    B: "1880",
  },
  {
    W: "2157",
    T: "180+0",
    P: "1. d4 Nf6 2. Bg5 g6 3. Bxf6 exf6 4. e3 Bg7 5. Bd3 f5 6. Ne2 O-O 7. h4 b6 8. h5 Bb7 9. Nf4 c5 10. c3 d6 11. hxg6 fxg6 12. Bc4+ Kh8 13. Nxg6# 1-0",
    B: "2104",
  },
  {
    W: "2008",
    T: "180+0",
    P: "1. d4 d5 2. c4 Nc6 3. Nc3 e6 4. Nf3 Nf6 5. Bg5 Be7 6. e3 O-O 7. c5 e5 8. h4 exd4 9. exd4 Ne4 10. Bd3 Re8 11. Kf1 Nxc3 12. bxc3 Bg4 13. Be2 h6 14. Qd2 Bf6 15. Bxh6 Bxf3 16. gxf3 gxh6 17. Qxh6 Bg7 18. Qh5 Kf8 19. Rg1 Qf6 20. Bd3 Qh6 21. Qg4 Re6 22. Bf5 Rf6 23. Re1 Ne7 24. Bd7 Rg6 25. Qh3 Rxg1+ 26. Kxg1 Rd8 27. Ba4 c6 28. h5 Qg5+ 29. Kh2 Nf5 30. Rg1 Qf4+ 31. Kh1 Qh4 32. Qxh4 Nxh4 33. Rg3 Nf5 34. Rh3 Re8 35. Bc2 Re1+ 36. Kg2 Nh6 37. f4 Re2 0-1",
    B: "2002",
  },
  {
    W: "1573",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. Nf3 Nf6 5. d4 Bg4 6. Bd2 Nc6 7. Ne4 Qb6 8. b3 Nxe4 0-1",
    B: "1542",
  },
  {
    W: "1166",
    T: "180+0",
    P: "1. d4 d5 2. c4 Nf6 3. cxd5 Nxd5 4. e4 Nb6 5. Nf3 Bg4 6. g3 e6 7. Bg2 Nc6 8. Be3 Bb4+ 9. Nc3 Nc4 10. O-O Bxc3 11. bxc3 Bxf3 12. Bxf3 O-O 13. Qb3 N4a5 14. Qb2 Qe7 15. d5 exd5 16. exd5 Ne5 17. Rfe1 Nac4 18. Qe2 Nxf3+ 19. Qxf3 Rfe8 20. Rab1 Nd2 21. Bxd2 Qc5 22. Rxe8+ Rxe8 23. Re1 Kf8 24. Rxe8+ Kxe8 25. Qe3+ Qxe3 26. Bxe3 a6 27. Ba7 Kd7 28. Bb8 c6 29. dxc6+ Kxc6 30. Be5 g6 31. Bg7 b5 32. Kg2 Kd5 33. Kf3 h5 34. Kf4 a5 35. h4 b4 36. cxb4 axb4 37. f3 Ke6 38. g4 Ke7 39. gxh5 gxh5 40. Kg5 f6+ 41. Bxf6+ Ke6 42. f4 b3 1-0",
    B: "1235",
  },
  {
    W: "1449",
    T: "180+0",
    P: "1. e4 Nc6 2. Qf3 g6 3. Bb5 Bg7 4. Bxc6 dxc6 5. d3 Nf6 6. c3 Bg4 7. Qg3 Nxe4 8. Qxg4 Bxc3+ 9. bxc3 Qxd3 10. f3 Nxc3 11. Qe4 Qd1+ 12. Kf2 Qxc1 13. Qd4 Qb2+ 14. Nd2 Qxa1 15. Qxh8+ Kd7 16. Qxa8 Qb2 17. Ke3 Nd5+ 18. Kd3 Qc3+ 19. Ke2 Qe3+ 20. Kd1 Nc3+ 21. Kc2 Nxa2 22. Qxb7 Qc3+ 23. Kd1 Qa1+ 24. Ke2 Nc1+ 25. Ke3 Qc3+ 26. Kf4 Nd3+ 27. Kg3 Qxd2 28. Qb3 Qf2+ 29. Kh3 Ne1 30. Qd1+ Ke8 31. Ne2 Qxg2+ 32. Kh4 Qxh1 33. Ng3 Qxf3 34. Qxf3 Nxf3+ 35. Kg4 Nxh2+ 0-1",
    B: "1385",
  },
  {
    W: "1238",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Qxd4 Nc6 5. Qa4 Bd7 6. Bb5 a6 7. Bxc6 Bxc6 8. Qb4 Nf6 9. Nc3 a5 10. Qc4 b5 11. Qxc6+ Nd7 12. Nxb5 Rc8 13. Qd5 Rxc2 14. Bg5 a4 15. O-O h6 16. Bh4 g5 17. Bg3 e6 18. Qb7 g4 19. Nfd4 Rxb2 20. Nxd6+ Bxd6 21. Qxb2 Bxg3 22. fxg3 Nc5 23. Qb5+ Nd7 24. Qxa4 Kf8 25. Rad1 Qf6 26. Qxd7 Kg7 27. e5 Qxe5 28. Qxf7# 1-0",
    B: "1321",
  },
  {
    W: "1998",
    T: "180+0",
    P: "1. e4 d5 2. Nc3 d4 3. Nce2 e5 4. Ng3 c5 5. Bb5+ Nc6 6. d3 Bd7 7. Nf3 Bd6 8. O-O h5 9. h3 h4 10. Bxc6 Bxc6 11. Nf5 Bf8 12. Bg5 f6 13. Bxh4 Rxh4 14. N5xh4 Nh6 15. Nf5 Nxf5 16. exf5 Qd7 17. g4 O-O-O 18. Nd2 g6 19. Ne4 gxf5 20. gxf5 Qxf5 21. f3 Bh6 22. Kf2 Rg8 23. Nd6+ Kc7 24. Nxf5 Bf4 25. Rg1 1-0",
    B: "1942",
  },
  {
    W: "1839",
    T: "180+0",
    P: "1. e4 c5 2. Bc4 Nc6 3. d3 e6 4. a4 d5 5. exd5 exd5 6. Ba2 a5 7. Nf3 Nf6 8. O-O Be7 9. Re1 O-O 10. Bf4 Be6 11. Ne5 Nxe5 12. Bxe5 Nd7 13. f4 Nxe5 14. fxe5 d4 15. Nd2 Bh4 16. Rf1 Bg5 17. Ne4 Be3+ 18. Kh1 Bxa2 19. Rxa2 f5 20. exf6 Qd5 21. b3 g6 22. Qg4 Rf7 23. Rf3 Re8 24. Qh3 Re6 25. Ng5 Bxg5 26. c4 Re1+ 0-1",
    B: "1904",
  },
  {
    W: "2044",
    T: "180+0",
    P: "1. e4 e6 2. d4 d5 3. Nd2 Nf6 4. e5 Nfd7 5. Bb5 c5 6. Bxd7+ Nxd7 7. c3 Qb6 8. Ne2 cxd4 9. cxd4 Be7 10. O-O Nf8 11. f4 Ng6 12. Nf3 O-O 13. Qd3 Bd7 14. a3 Bb5 15. Qd2 Rac8 16. Re1 a5 17. Nc3 Bc4 18. Qf2 Bb3 19. Be3 Bxa3 20. Rxa3 Qb4 21. Rea1 b6 22. Bd2 Qe7 1-0",
    B: "2041",
  },
  {
    W: "1805",
    T: "600+0",
    P: "1. d4 d5 2. Bf4 Nc6 3. e3 Nf6 4. Nf3 Bf5 5. c3 e6 6. Qb3 Rb8 7. Nbd2 Bd6 8. Be2 Bxf4 9. exf4 O-O 10. O-O Ne4 11. Qd1 Bg4 12. Ne5 Bxe2 13. Qxe2 Nxe5 14. fxe5 f5 15. g3 Nxd2 16. Qxd2 f4 17. gxf4 Qh4 18. Qe3 Rxf4 19. Kh1 Rbf8 20. Qg3 Qxg3 21. hxg3 Rxf2 22. Rxf2 Rxf2 23. Rb1 Kf7 24. Kg1 Kg6 25. Kxf2 Kg5 26. Kg2 Kg4 27. Rf1 g5 28. Rf6 h5 29. Rxe6 h4 30. gxh4 gxh4 31. Rf6 h3+ 32. Kh2 Kg5 33. Rf2 Kg4 34. e6 Kg5 35. e7 Kh4 36. e8=Q c5 37. Rf4+ Kg5 38. Qf7 cxd4 39. Qf6+ Kh5 40. Rh4# 1-0",
    B: "1788",
  },
  {
    W: "1922",
    T: "600+0",
    P: "1. e4 e5 2. Bc4 Nf6 3. d3 h6 4. Nf3 Nc6 5. h3 Bc5 6. c3 O-O 7. O-O d5 8. exd5 Nxd5 9. Re1 Nf4 10. Nxe5 Nxh3+ 11. gxh3 Nxe5 12. Rxe5 Bxf2+ 13. Kg2 Qh4 14. Qf3 Bc5 15. Rxc5 b6 16. Rxc7 Qe1 17. Rxf7 Rxf7 18. Qxf7+ Kh8 19. Qg8# 1-0",
    B: "1892",
  },
  {
    W: "2170",
    T: "600+0",
    P: "1. d4 Nf6 2. Nf3 g6 3. c3 Bg7 4. Bf4 O-O 5. Nbd2 d6 6. e3 Nbd7 7. h3 c5 8. Be2 a6 9. O-O b5 10. Re1 Bb7 11. Bf1 Re8 12. Rc1 Nd5 13. Bh2 Rc8 14. e4 N5b6 15. d5 b4 16. Nc4 bxc3 17. bxc3 Nxc4 18. Bxc4 Nb6 19. Bf1 Qd7 20. Qc2 Qa4 21. c4 Qxc2 22. Rxc2 a5 23. Rb1 1-0",
    B: "1588",
  },
  {
    W: "2100",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 a6 6. Bc4 e5 7. Nb3 Be6 8. Bxe6 fxe6 9. O-O Be7 10. Qg4 Bf6 11. Qxe6+ Qe7 12. Qd5 Nb4 13. Qd1 Qe6 14. a3 Nc6 15. Nd5 Rc8 16. f4 Nge7 17. f5 Qf7 18. Nxf6+ Qxf6 19. c3 O-O 20. Be3 Rfd8 21. Qg4 h6 22. Rad1 d5 23. Nc5 d4 24. cxd4 exd4 25. Bf4 b6 26. Ne6 Rd7 27. Bg3 Ne5 28. Qf4 Nf7 29. e5 g5 30. Qe4 Qh8 31. f6 Rc6 32. fxe7 Rxe6 33. Qg6+ Rxg6 34. e8=Q+ Kh7 35. Qxd7 1-0",
    B: "2087",
  },
  {
    W: "1750",
    T: "600+0",
    P: "1. d4 Nf6 2. e3 g6 3. Be2 Bg7 4. Nf3 O-O 5. O-O Nc6 6. c3 e6 7. a3 d5 8. b4 a6 9. Re1 Bd7 10. Bb2 Re8 11. Nbd2 Ne4 12. Bd3 f5 13. h3 Kh8 14. c4 Na7 15. c5 b6 16. Ne5 Nc6 17. Nxc6 Bxc6 18. f3 Nxd2 19. Qxd2 h5 20. Qf2 a5 21. b5 Bd7 22. c6 Bc8 23. a4 Qd6 24. Ba3 Qd8 25. Rad1 e5 26. dxe5 Bxe5 27. Bb2 d4 28. exd4 Bf6 29. Rxe8+ Qxe8 30. Re1 Qf7 31. Qc2 Be6 32. Rc1 Bb3 33. Qc3 Bxa4 34. Bc4 Qg7 35. Re1 Rd8 36. Qa3 Bxd4+ 37. Bxd4 Qxd4+ 38. Kh2 Qxc4 39. Qe7 Qd5 40. Qf6+ Kg8 41. Qxg6+ Kf8 42. Qh6+ Kg8 43. Qg6+ Kh8 44. Qxh5+ Kg7 45. Re7+ Kf6 46. Qh4+ Kg6 47. Qh7+ Kf6 48. Qg7# 1-0",
    B: "1602",
  },
  {
    W: "1980",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 d6 3. c3 Nc6 4. d4 cxd4 5. cxd4 Nf6 6. Nc3 e6 7. a3 Be7 8. Bd3 Bd7 9. O-O O-O 10. h3 Rc8 11. Ne2 a6 12. Ng3 e5 13. d5 Na7 14. Be3 Nb5 15. a4 Nd4 16. Nxd4 exd4 17. Bxd4 g6 18. f4 Rb8 19. Qf3 b5 20. a5 Rb7 21. b4 Qb8 22. f5 g5 23. Nh5 Nxh5 24. Qxh5 f6 25. h4 Be8 26. Qh6 gxh4 27. Qxh4 Rf7 28. Rf3 Kh8 29. Rg3 Qd8 30. Qh6 Bf8 31. Qh4 Bg7 32. Rh3 Bf8 33. Rf1 Be7 34. Rff3 Rg7 35. Rfg3 Bf8 36. Bxf6 Qc8 37. Bxg7+ Rxg7 38. Rxg7 Qc1+ 39. Kh2 Kxg7 40. Qxh7+ Kf6 41. Qh8+ Ke7 42. Rh7+ Kd8 43. Qxf8 Qf4+ 44. Kh3 Qe3+ 45. g3 Qxd3 46. Qe7+ Kc8 47. Qxe8# 1-0",
    B: "2068",
  },
  {
    W: "1071",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d3 Nf6 6. Nc3 Be7 7. Bf4 O-O 8. d4 d5 9. Bd3 Bb4 10. O-O Bg4 11. h3 Bxc3 12. bxc3 Bh5 13. g4 Bg6 14. Bxg6 fxg6 15. Ne5 Ne4 16. Qf3 g5 17. Bxg5 Nxg5 18. Qd3 Nc6 19. Nxc6 bxc6 20. Rfe1 Ne4 21. Re3 Qh4 22. Re2 Nxf2 23. Rf1 Nxd3 24. Rxf8+ Rxf8 0-1",
    B: "1006",
  },
  {
    W: "1664",
    T: "600+0",
    P: "1. Nf3 Nc6 2. e4 Nf6 3. Nc3 e6 4. d4 Bb4 5. Bd3 O-O 6. Bd2 Bxc3 7. bxc3 Ne7 8. c4 d6 9. h3 b6 10. Bg5 Nd7 11. Nd2 h6 12. Bh4 Qe8 13. Qf3 Bb7 14. Bg3 e5 15. c3 c5 16. d5 Ng6 17. O-O Nf6 18. a4 a5 19. Qd1 Nh7 20. f4 exf4 21. Rxf4 Nxf4 22. Bxf4 Qe7 23. Qg4 Ng5 24. h4 Bc8 25. Qg3 f6 26. hxg5 fxg5 27. Bxd6 Qf6 28. Bxf8 Qxc3 29. Bxg7 Qxg7 30. Rf1 Bd7 31. Nf3 Rf8 32. Qd6 Bg4 33. Ne5 Rxf1+ 34. Bxf1 Bh5 35. Nc6 Be8 36. Ne7+ Kh8 37. Nf5 Qh7 38. Qxb6 Bg6 39. Bd3 Bxf5 40. exf5 Qg7 41. f6 Qf8 42. Qe6 Qb8 43. Qf5 Qc7 44. f7 Qf4 45. f8=Q# 1-0",
    B: "1663",
  },
  {
    W: "1370",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nxd5 6. Qf3 f6 7. Bxd5 Qxd5 8. Qxd5 1-0",
    B: "1184",
  },
  {
    W: "1524",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 Bc5 5. Bg5 a6 6. Bxc6 dxc6 7. Nbd2 Qd4 8. Nxd4 1-0",
    B: "1528",
  },
  {
    W: "1960",
    T: "300+3",
    P: "1. Nf3 Nf6 2. b3 g6 3. Bb2 Bg7 4. g3 O-O 5. Bg2 d5 6. d3 c5 7. Nbd2 Nc6 8. O-O Qc7 9. c4 d4 10. e3 e5 11. e4 a5 12. a4 Bh6 13. Bc1 Bg4 14. h3 Bxf3 15. Nxf3 Bxc1 16. Rxc1 h5 17. Ng5 Nh7 18. f4 Nxg5 19. fxg5 Qe7 20. Rf6 Nb8 21. Qf1 Ra6 22. Rc2 Rxf6 23. gxf6 Qd6 24. g4 hxg4 25. hxg4 Rc8 26. Bh3 Rc6 27. g5 Kf8 28. Bg4 Ke8 29. Rh2 Nd7 30. Bxd7+ Kxd7 31. Rh7 Qe6 32. Qg2 Rc7 33. Kf2 Kc6 34. Ke2 Kb6 35. Kd2 Ka6 36. Kc2 Rc8 37. Qg3 Re8 38. Qh4 Rc8 39. Rh8 Rxh8 40. Qxh8 Qg4 41. Qa8+ Kb6 42. Qd8+ Ka6 43. Qd6+ b6 44. Qxe5 Qe2+ 45. Kc1 Qe1+ 46. Kc2 Qe2+ 47. Kc1 Qe1+ 48. Kc2 Qe2+ 49. Kc1 Qe1+ 50. Kc2 Qe2+ 51. Kc1 1/2-1/2",
    B: "2139",
  },
  {
    W: "1790",
    T: "300+3",
    P: "1. d4 e6 2. c4 c5 3. d5 a6 4. Nc3 b5 5. e4 exd5 6. exd5 bxc4 7. Bxc4 d6 8. Nf3 Nf6 9. O-O Be7 10. Re1 O-O 11. Bg5 Nxd5 12. Bxd5 Bxg5 13. Bxa8 Bg4 14. Bd5 Nd7 15. Qb3 Bxf3 16. Bxf3 Bf4 17. Ne4 Ne5 18. Ng3 Re8 19. Rad1 Nxf3+ 20. Qxf3 Be5 21. Qc6 Re6 22. Qxa6 Rh6 23. Qd3 Qh4 24. h3 Qd8 25. Rxe5 f6 26. Rxc5 1-0",
    B: "1649",
  },
  {
    W: "1373",
    T: "120+0",
    P: "1. e4 e5 2. f4 d5 3. exd5 Qxd5 4. Nc3 Qc5 5. Nf3 f6 6. fxe5 fxe5 7. Ne4 Qd5 8. d3 Bc5 9. c4 Qe6 10. Nxc5 Qb6 11. Ne4 Qg6 12. Ng3 Nf6 13. Be2 O-O 14. Nxe5 Qf5 15. Nxf5 Ne8 16. Ne7+ Kh8 17. Rf1 Rxf1+ 18. Kxf1 Be6 19. d4 Nf6 20. d5 Bd7 21. Nf7# 1-0",
    B: "1919",
  },
  {
    W: "1483",
    T: "300+3",
    P: "1. e4 Nc6 2. c3 d5 3. d3 dxe4 4. dxe4 Qxd1+ 5. Kxd1 e5 6. f3 Be6 7. Kc2 O-O-O 8. b4 a5 9. b5 Na7 10. a4 Kb8 11. Be3 Nc8 12. g4 Bd7 13. Nd2 c6 14. bxc6 Bxc6 15. Nc4 Nd6 16. Nxe5 Ne7 17. Bb6 Rc8 18. Bxa5 f6 19. Nxc6+ Nxc6 20. Bb6 Nc4 21. Bxc4 Nb4+ 22. cxb4 Rxc4+ 23. Kd3 Rxb4 24. a5 Be7 25. Ke3 Rc8 26. Ne2 Rb3+ 27. Kf2 Rc2 28. Rad1 Rbb2 29. Rhe1 Bb4 30. Rd8+ Rc8 31. Rxc8+ Kxc8 32. Rc1+ Kb8 33. Rc7 Bd6 34. Rxg7 Kc8 35. Rg8+ Kd7 36. Rg7+ Kc6 37. Rxh7 Bc5+ 38. Bxc5 Kxc5 39. h4 Kb5 40. Rxb7+ 1-0",
    B: "1554",
  },
  {
    W: "2107",
    T: "300+3",
    P: "1. f4 d5 2. e4 dxe4 3. d3 exd3 4. Bxd3 Nf6 5. Nc3 g6 6. Nf3 Bg7 7. O-O O-O 8. Qe1 c5 9. Be3 b6 10. Qh4 Nc6 11. Rad1 Nd4 12. f5 Bxf5 13. Bxf5 gxf5 14. Bxd4 cxd4 15. Nxd4 e6 16. Nxf5 Nd5 17. Qg3 exf5 18. Nxd5 Qc8 19. Ne7+ 1-0",
    B: "2030",
  },
  {
    W: "1587",
    T: "300+3",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Nb3 a6 6. Nc3 d6 7. Bc4 b5 8. Bd5 Bd7 9. O-O Rc8 10. a4 b4 11. Na2 a5 12. c3 Nce7 13. cxb4 Nxd5 14. exd5 Bxa4 15. Qd3 Bxb3 16. Qxb3 Be7 17. Nc3 axb4 18. Qxb4 Nf6 19. Bg5 O-O 20. f4 Ng4 21. Bxe7 Qxe7 22. fxe5 Nxe5 23. Rae1 Qa7+ 24. Kh1 Nd3 25. Ra1 Nf2+ 26. Rxf2 Qxf2 27. Qxd6 Qxb2 0-1",
    B: "1633",
  },
  {
    W: "1755",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d3 a6 6. O-O O-O 7. Re1 d6 8. h3 Be6 9. Bxe6 fxe6 10. d4 exd4 11. cxd4 Ba7 12. Nc3 e5 13. dxe5 Nxe5 14. Nxe5 dxe5 15. Qb3+ Kh8 16. Qxb7 Nh5 17. Nd5 Bxf2+ 18. Kh1 Bxe1 19. Nxc7 Rf1+ 20. Kh2 Bg3# 0-1",
    B: "1717",
  },
  {
    W: "1169",
    T: "300+3",
    P: "1. e4 e5 2. Qh5 Nc6 3. Bc4 g6 4. Qf3 Nf6 5. Qb3 Qe7 6. Nc3 d6 7. Nd5 Nxd5 8. exd5 Nd4 9. Bb5+ Bd7 10. Bxd7+ Qxd7 11. Qc3 c5 12. Nf3 Qg4 13. O-O Nxf3+ 14. Qxf3 Qxf3 15. gxf3 Bh6 16. d4 exd4 17. Bxh6 Kd7 18. Bg7 Rhe8 19. b4 Re2 20. bxc5 dxc5 21. c3 dxc3 22. Bxc3 Kd6 23. f4 Kxd5 24. Rfd1+ Ke4 25. Be5 Re8 26. Re1 Rxe1+ 27. Rxe1+ Kf3 28. Re3+ Kg4 29. Rg3+ Kf5 30. Rg5+ Ke4 31. Kg2 c4 32. f3+ Kd3 33. Bf6 c3 34. Rd5+ Kc2 35. Rc5 Kb2 36. Rxc3 Kxa2 37. Rc7 Rb8 38. Rxf7 b5 39. Rxh7 b4 40. Rxa7+ Kb1 41. Be5 Rb5 42. Ra1+ Kc2 43. h4 b3 44. h5 gxh5 45. Bd4 Rb4 46. Bh8 Rxf4 47. Rf1 b2 48. Rf2+ Kd3 49. Bxb2 Ke3 50. Rf1 h4 51. Bc1+ 1/2-1/2",
    B: "1155",
  },
  {
    W: "643",
    T: "300+3",
    P: "1. e4 e5 2. Qg4 Nf6 3. Qf5 Bc5 4. Bc4 d5 5. exd5 Bxf5 6. Nf3 Nxd5 7. Bxd5 Qxd5 8. Nxe5 Qxe5+ 9. Kd1 O-O 10. f3 Bxc2+ 11. Kxc2 Nc6 12. Rf1 Qf4 13. g3 Qc4+ 14. Kd1 Qxf1+ 15. Kc2 Nb4+ 16. Kb3 Qd3+ 17. Ka4 Qa6+ 18. Kb3 b5 19. d3 Qd6 20. Bg5 Qxd3+ 21. Nc3 Qxf3 22. Rd1 Be7 23. Bxe7 Rfe8 24. Bxb4 a5 25. Bxa5 Rxa5 26. a3 Qg2 27. Rd7 Qxh2 28. Rxc7 Qxg3 29. Rc5 Rb8 30. Rc4 Qg4 31. Rb4 Qxb4+ 32. axb4 Rc8 33. bxa5 Rxc3+ 34. bxc3 f5 35. a6 f4 36. a7 f3 37. a8=Q+ Kf7 38. Qa7+ Kg6 39. c4 bxc4+ 40. Kxc4 Kh6 41. Qf2 g5 42. Qxf3 Kg6 43. Qg3 h5 44. Kd4 Kf5 45. Kd5 h4 46. Qf3+ Kg6 47. Qg4 Kh6 48. Ke5 Kg6 49. Ke4 Kg7 50. Qxg5+ Kh8 51. Qxh4+ Kg7 52. Ke5 Kg6 53. Qg4+ Kf7 54. Qf4+ Ke7 55. Qd4 Kf7 56. Kf5 Ke7 57. Qd5 Ke8 58. Ke6 Kf8 59. Qd6+ Kg7 60. Kf5 Kf7 61. Qf6+ Ke8 62. Qe6+ Kd8 63. Qd6+ Ke8 64. Qe6+ Kf8 65. Kf6 1/2-1/2",
    B: "954",
  },
  {
    W: "1361",
    T: "300+3",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Bb4 4. Qc2 Nc6 5. Nf3 Nf6 6. e3 Ne4 7. a3 Bxc3+ 8. bxc3 O-O 9. Nd2 Nxd2 10. Bxd2 Bd7 11. Bd3 g6 12. cxd5 exd5 13. O-O Qg5 14. e4 Nxd4 15. cxd4 1-0",
    B: "1193",
  },
  {
    W: "1099",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 d6 3. Bb5+ c6 4. Bc4 b5 5. Bb3 Nf6 6. Ng5 h6 7. Nxf7 Qd7 8. Nxh8 a5 9. Bf7+ Kd8 10. a3 Nxe4 11. Ba2 Qf5 12. f3 Nc5 13. O-O Qg5 14. Nf7+ Ke7 15. Nxg5 1-0",
    B: "1045",
  },
  {
    W: "2070",
    T: "30+0",
    P: "1. c4 b6 2. Nc3 Bb7 3. f3 e6 4. e4 d5 5. exd5 exd5 6. cxd5 Nf6 7. d4 Nxd5 8. Nge2 Nc6 9. Be3 Bb4 10. Qd3 Bxc3+ 11. bxc3 Qe7 12. Bd2 O-O-O 13. g3 Rhe8 14. Bg2 Ne3 15. Bxe3 Qxe3 16. Qxe3 Rxe3 17. Kf2 Rde8 18. Rhe1 Ba6 19. Nf4 Rxe1 20. Rxe1 Rxe1 21. Kxe1 Bc4 22. a3 g5 23. Nh3 h6 24. Nf2 Na5 25. Ne4 Nb3 26. Nd2 Nxd2 27. Kxd2 Be6 28. Ke3 c5 29. f4 cxd4+ 30. Kxd4 a5 31. Be4 gxf4 32. gxf4 h5 33. Ke5 Bg4 34. Bf5+ Bxf5 35. Kxf5 Kd7 36. Kg5 Ke6 37. Kxh5 b5 38. h4 Kf6 39. Kg4 Kg7 40. h5 Kh6 41. Kf5 Kxh5 42. Kf6 Kh6 43. Kxf7 Kh7 44. Ke6 b4 45. Kd5 b3 46. f5 b2 47. f6 1-0",
    B: "2042",
  },
  {
    W: "1857",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 Nf6 5. Bd3 Be7 6. O-O O-O 7. Re1 Re8 8. c3 c6 9. Nbd2 Bg4 10. Nf1 Qb6 11. Bf4 Qxb2 12. Rb1 Qxc3 13. Rxb7 Bxf3 14. Qxf3 Qa3 15. Rbxe7 Rxe7 16. Bxh7+ Nxh7 17. Qxa3 Rxe1 18. Qb4 Re8 19. Qb7 Nd7 20. Qxd7 Nf6 21. Qxc6 Ne4 22. Qxd5 Rac8 23. Be5 Ng5 24. f4 Ne6 25. f5 Ng5 26. Ne3 Rcd8 27. Qc4 Ne4 28. f6 gxf6 29. Nf5 fxe5 30. Qc6 Nf6 31. Qxf6 1-0",
    B: "1807",
  },
  {
    W: "1294",
    T: "60+0",
    P: "1. e4 e5 2. c4 Nc6 3. Nc3 Be7 4. Nd5 Nf6 5. Nxe7 Qxe7 6. d3 O-O 7. Nf3 a6 8. g4 b5 9. h4 h6 10. g5 hxg5 11. hxg5 Ng4 12. Bh3 d6 13. Bxg4 Bxg4 14. Ke2 f6 15. Qg1 Bxf3+ 16. Kxf3 fxg5+ 17. Ke2 bxc4 18. Bxg5 Nd4+ 19. Kd2 Nf3+ 20. Ke2 Nxg5 21. Rf1 Rab8 22. Qg2 Rxb2+ 23. Ke1 Nf3+ 24. Kd1 Rd2+ 25. Kc1 cxd3 26. Rfg1 Nxg1 27. Qxg1 Rxa2 28. Kd1 Ra1+ 29. Kd2 Rxg1 30. Rxg1 Qh4 31. Rf1 Qxf2+ 32. Rxf2 Rxf2+ 33. Kxd3 Rf3+ 34. Ke2 g5 35. Kxf3 Kg7 36. Kg4 Kg6 37. Kf3 a5 38. Kg4 a4 39. Kf3 a3 40. Kg4 a2 41. Kf3 a1=Q 42. Kg4 Qa3 1/2-1/2",
    B: "1350",
  },
  {
    W: "1475",
    T: "60+0",
    P: "1. e4 e5 2. Nc3 Nc6 3. Nf3 Bc5 4. Bc4 d6 5. O-O Nf6 6. d4 exd4 7. Nd5 Ng4 8. h3 h5 9. hxg4 hxg4 10. Nd2 Qh4 0-1",
    B: "1379",
  },
  {
    W: "1538",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d3 Bc5 4. Nc3 Nf6 5. Be2 O-O 6. O-O h6 7. h3 d5 8. exd5 Nxd5 9. Nxd5 Qxd5 10. c4 Qe6 11. b3 a6 12. Bb2 Bd4 13. Bxd4 Nxd4 14. Nxd4 exd4 15. Re1 Qc6 16. Bf3 Qd7 17. Qb1 Re8 18. Re4 Rxe4 19. Bxe4 c6 20. Qe1 Qc7 21. Qe2 Be6 22. Qf3 Re8 23. Kf1 Qe7 24. Re1 Qb4 25. Qe2 Qc3 26. Qd1 Re7 27. f3 c5 28. Kg1 b6 29. Kh2 b5 30. Qe2 bxc4 31. Rd1 cxd3 32. Bxd3 Qb4 33. Qc2 Qb6 34. Bc4 Qd6+ 35. Kg1 Bxc4 36. Qxc4 a5 37. Kf2 Kh7 38. Re1 Kg8 39. Rxe7 Qxe7 40. Qxc5 Qxc5 41. Kg3 Qe5+ 42. f4 Qe3+ 43. Kg4 d3 44. g3 d2 45. f5 d1=Q+ 0-1",
    B: "1696",
  },
  {
    W: "2124",
    T: "60+0",
    P: "1. c4 Nf6 2. g3 d5 3. Bg2 dxc4 4. Nf3 Nc6 5. Na3 e5 6. Nxc4 Bd6 7. O-O O-O 8. d3 Bg4 9. Rb1 Nd4 10. Nfxe5 Nxe2+ 11. Kh1 Nxg3+ 12. hxg3 Bxd1 13. Rxd1 Bxe5 14. Nxe5 Qd4 15. Nc4 Qxf2 16. Bf4 Nh5 17. Bxc7 Nxg3+ 18. Kh2 Ne2 19. Rf1 Qh4+ 20. Bh3 Rac8 21. Bd6 Rcd8 22. Be5 Rxd3 23. Bxg7 Qxh3# 0-1",
    B: "2174",
  },
  {
    W: "2003",
    T: "60+0",
    P: "1. Nf3 Nf6 2. d4 g6 3. c4 Bg7 4. Nc3 d6 5. g3 O-O 6. Bg2 Nbd7 7. O-O c5 8. Re1 cxd4 9. Nxd4 e5 10. Ndb5 Rb8 11. Qxd6 Re8 12. Bg5 Re6 13. Qd2 Qe8 14. Nxa7 Nc5 15. Nxc8 Rxc8 16. b3 e4 17. Bxf6 Bxf6 18. Rad1 Rd8 19. Nd5 Bg7 20. b4 Nd7 21. Nc7 Qe7 22. Nxe6 Qxe6 23. Qd5 Qe7 24. Bh3 e3 25. f3 Bc3 26. Rf1 Bd2 27. Rxd2 Kg7 28. Qd4+ 1-0",
    B: "1886",
  },
  {
    W: "2019",
    T: "60+0",
    P: "1. d4 d5 2. c4 dxc4 3. e3 c5 4. Bxc4 cxd4 5. Nf3 Nf6 6. O-O dxe3 7. Bxf7+ Kxf7 8. Qxd8 Be6 9. Ne5+ Kg8 10. Bxe3 b6 11. Nc3 h6 12. Ng6 Nbd7 13. Qxa8 Rh7 14. Nxf8 Nxf8 15. Ne4 Bd5 16. Nxf6+ 1-0",
    B: "2044",
  },
  {
    W: "2003",
    T: "60+0",
    P: "1. d4 e6 2. c4 d5 3. Nc3 Nf6 4. cxd5 exd5 5. Nf3 c6 6. Bg5 Be7 7. e3 Nbd7 8. Bd3 O-O 9. O-O h6 10. Bh4 Re8 11. Bg3 Nf8 12. Ne2 Ne6 13. Rc1 Ng5 14. b4 Nxf3+ 15. gxf3 Nh5 16. e4 Bh3 17. Re1 Qd7 18. Nf4 Nxf4 19. Bxf4 Bg5 20. Bxg5 hxg5 21. f4 gxf4 22. Qf3 g5 23. e5 Be6 24. b5 Kg7 25. bxc6 bxc6 26. Bc2 Rh8 27. Ba4 Rh3 28. Qe2 Rc8 29. Qa6 g4 30. Bxc6 Qe7 31. Qxc8 Bxc8 32. Bxd5 Bf5 33. Rc6 Be6 34. Be4 Qh4 35. Kf1 Rxh2 36. Ke2 Qxf2+ 37. Kd1 Qxd4+ 38. Bd3 Qb2 39. Bc2 Qxc2+ 40. Rxc2 f3 41. Kc1 1-0",
    B: "1860",
  },
  {
    W: "2062",
    T: "60+0",
    P: "1. e4 c6 2. Be2 d5 3. exd5 cxd5 4. d4 e6 5. Nf3 Nf6 6. O-O Bd6 7. Re1 Nc6 8. Bg5 a6 9. c3 Qc7 10. Nbd2 Bd7 11. Bh4 O-O-O 12. Bg3 Bxg3 13. hxg3 h5 14. Nh4 g5 15. Nhf3 h4 16. gxh4 gxh4 17. Bd3 h3 18. g3 h2+ 19. Kh1 Ng4 20. Qe2 f5 21. Ne5 Ncxe5 22. dxe5 Nxe5 23. Qxe5 Qxe5 24. Rxe5 Bc6 25. f3 d4 26. cxd4 Rxd4 27. Re3 f4 28. gxf4 e5 29. fxe5 Rdh4 30. Nb3 Rh3 31. Rae1 Bxf3+ 32. Rxf3 Rxf3 33. Be4 Rf4 34. Bg2 Rf2 35. Nd4 Rxb2 36. e6 Kd8 37. e7+ Ke8 38. Nf5 Rh6 39. Ng7+ Kf7 40. e8=Q+ Kxg7 41. Re7+ Kf6 42. Qf7+ Kg5 43. Re5+ Kg4 44. Qf3+ Kh4 45. Re4+ Kg5 46. Qg4+ Kf6 47. Rf4+ Ke7 48. Qg7+ Kd6 49. Qxh6+ Kc5 50. Rf5+ Kb4 51. Qh4+ Ka3 52. Rf3+ Rb3 53. Rxb3+ Kxa2 54. Qa4# 1-0",
    B: "2067",
  },
  {
    W: "1214",
    T: "60+0",
    P: "1. d4 e5 2. dxe5 Qe7 3. Bf4 Nc6 4. Nf3 f6 5. exf6 Nxf6 6. e3 d6 7. b4 Bg4 8. c3 O-O-O 9. a3 a6 10. Nbd2 Kb8 11. Be2 Ne5 12. h3 Nxf3+ 13. Bxf3 Bxf3 14. Nxf3 Ne4 15. O-O Nxc3 16. Qc2 Ne4 17. Rfc1 c6 18. Nd4 Rc8 19. Rab1 h6 20. b5 g5 21. bxc6 gxf4 22. cxb7 Rxc2 23. Rxc2 Qd7 24. Rc3 Nxc3 25. Rc1 Ne2+ 26. Nxe2 fxe3 0-1",
    B: "1249",
  },
  {
    W: "1765",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 e6 3. Nc3 f5 4. e3 Nf6 5. Bb5+ c6 6. Bd3 Bd6 7. Ne5 Ne4 8. f3 O-O 9. O-O Nxc3 10. bxc3 Bd7 11. f4 Be8 12. Rf3 Nd7 13. Rh3 Nf6 14. a3 Ne4 15. Bb2 Rf6 16. c4 Rh6 17. Rxh6 gxh6 18. Qe1 c5 19. cxd5 exd5 20. dxc5 Bxc5 21. Bd4 Bxd4 22. exd4 Qb6 23. Bxe4 Qxd4+ 24. Kh1 fxe4 25. Qg3+ Kh8 26. Ng4 Qxa1+ 0-1",
    B: "1725",
  },
  {
    W: "1873",
    T: "60+0",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 Nf6 5. Nc3 d6 6. Bd2 Be7 7. O-O-O O-O 8. f3 Be6 9. Bd3 a6 10. h4 b5 11. Nh3 h6 12. Ne2 d5 13. Kb1 d4 14. Qf2 Bc5 15. Qg3 Nh5 16. Qf2 Qd6 17. g4 Nf6 18. g5 Nh7 19. gxh6 g6 20. h5 b4 21. hxg6 fxg6 22. Rdg1 Bf7 23. Nef4 a5 24. Nxg6 Bxg6 25. f4 Kf7 26. f5 Bh5 27. Rg7+ Ke8 28. Rxh7 a4 29. Bb5 b3 30. cxb3 axb3 31. axb3 Bb4 32. Bxb4 Qxb4 33. Qxd4 Qa5 34. Bxc6# 1-0",
    B: "1879",
  },
  {
    W: "1697",
    T: "300+0",
    P: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. f4 e6 5. Bd3 Qh4+ 6. g3 Qg4 7. Be2 Qg6 8. Nc3 Bxc2 9. Qd2 Bf5 10. Nf3 Be7 11. b4 b6 12. b5 c5 13. dxc5 Bxc5 14. a4 Ne7 15. Nh4 Qh6 16. Nxf5 Nxf5 17. Qd3 Qh3 18. Bf3 Nd7 19. Ba3 Rc8 20. Ne2 O-O 21. g4 Nh4 22. Bxc5 Nxf3+ 23. Kf2 Nxc5 24. Qxf3 Nd3+ 25. Ke3 Qxf3+ 26. Kxf3 Rc4 27. Ke3 Nb4 28. Rac1 Rfc8 29. Rxc4 Rxc4 30. Rc1 Nc2+ 31. Kd2 Nb4 32. Rxc4 dxc4 33. Kc3 Nd5+ 34. Kxc4 Kf8 35. f5 Ke7 36. fxe6 fxe6 37. h3 Kf7 38. Kd4 Kg6 0-1",
    B: "1691",
  },
  {
    W: "1296",
    T: "300+0",
    P: "1. d4 d5 2. Bf4 Nc6 3. Nf3 Bf5 4. e3 a6 5. Be2 e6 6. O-O Nf6 7. Ne5 Nxe5 8. Bxe5 Bd6 9. Bxd6 Qxd6 10. Bd3 Ng4 11. g3 Be4 12. Bxe4 dxe4 13. Qxg4 O-O-O 14. Qxe4 1-0",
    B: "1269",
  },
  {
    W: "1618",
    T: "300+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. Be2 Nc6 5. Nf3 e5 6. d3 h6 7. O-O Nf6 8. Be3 Bd6 9. Qd2 Bg4 10. h3 Bh5 11. Nb5 O-O 12. Nxd6 cxd6 13. c3 e4 14. dxe4 Nxe4 15. Qd1 Ne5 16. Nxe5 dxe5 17. Bxh5 Qh4 18. Bf3 f5 19. Qb3+ Kh8 20. Bxe4 fxe4 21. Qxb7 Rae8 22. Qxa7 Rf6 23. Qc5 Rg6 24. Kh2 Kg8 25. Qd5+ Kh7 26. Rad1 Rf8 27. Rd2 1-0",
    B: "1511",
  },
  {
    W: "1455",
    T: "300+0",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. Nf3 cxd4 5. Bb5+ Nc6 6. Nxd4 Bd7 7. Be2 Qb6 8. c3 Nxe5 9. Be3 Bc5 10. O-O Nf6 11. Nb3 Bxe3 12. fxe3 Qxe3+ 13. Kh1 O-O 14. Na3 a6 15. Nd4 Nfg4 16. Nac2 Nf2+ 17. Rxf2 Qxf2 18. Bf3 Nxf3 19. Nxf3 Qb6 20. Rb1 Bc6 21. Nfd4 e5 22. Nf5 Qf2 23. Qg4 g6 24. Ne7+ Kh8 25. Nb4 Rae8 26. Nexc6 bxc6 27. Nxc6 Qc2 28. Rf1 Qxb2 29. Qg5 Qxc3 30. Qf6+ Kg8 31. Ne7+ Rxe7 32. Qxe7 Qd3 33. Kg1 1-0",
    B: "1439",
  },
  {
    W: "1392",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. d4 Nxd4 5. Nxd4 exd4 6. Qxd4 Nf6 7. Nc3 b6 8. Nd5 Bc5 9. Nxf6+ Qxf6 10. Qxf6 gxf6 11. Bf4 Rg8 12. Bg3 Bd6 13. O-O-O Bxg3 14. hxg3 Bb7 15. f3 O-O-O 16. g4 c6 17. Rxh6 d5 18. exd5 cxd5 19. Bd3 Rd6 20. Be4 d4 21. c3 Bxe4 22. fxe4 Rxg4 23. e5 Re6 24. cxd4 Rxg2 25. Rxf6 Rxf6 26. exf6 Kd7 27. Rf1 Kd6 28. Rf5 a5 29. Kb1 Ke6 30. Rf4 Rg6 31. Kc2 Rxf6 32. Rxf6+ Kxf6 33. Kc3 Ke6 34. Kc4 f5 35. Kd3 Kd5 36. b3 b5 37. a3 a4 38. bxa4 bxa4 39. Ke3 f4+ 40. Kd3 f3 41. Ke3 f2 42. Kxf2 Kxd4 43. Ke2 Kc3 44. Kd1 Kb2 45. Kd2 Kxa3 46. Kc2 Kb4 47. Kb2 a3+ 48. Ka1 Kb3 49. Kb1 Ka4 50. Ka2 Kb4 51. Kb1 Kb3 52. Ka1 Ka4 53. Ka2 Kb4 54. Kb1 Kb3 55. Ka1 Ka4 56. Ka2 Kb4 1/2-1/2",
    B: "1415",
  },
  {
    W: "1244",
    T: "300+0",
    P: "1. d4 d5 2. Bf4 Nf6 3. e3 h6 4. Nf3 g5 5. Bg3 Ne4 6. Nbd2 Nxg3 7. hxg3 Bf5 8. Ne5 f6 9. Ndf3 fxe5 10. Nxe5 Nc6 0-1",
    B: "1229",
  },
  {
    W: "1404",
    T: "180+0",
    P: "1. e4 d6 2. d3 g6 3. f3 Bg7 4. g3 Nf6 5. h4 h5 6. g4 O-O 7. g5 Nfd7 8. f4 c6 9. f5 gxf5 10. exf5 f6 11. Qxh5 Bh8 12. Qg6+ Bg7 13. h5 Ne5 14. h6 Nxg6 15. fxg6 Nd7 16. hxg7 Kxg7 17. gxf6+ Kxg6 18. fxe7 Qxe7+ 19. Kd2 Qg5+ 20. Kd1 Qg4+ 21. Be2 Qf5 22. Nf3 Ne5 23. Rg1+ Kf6 24. Nxe5 Qxe5 25. Rf1+ Ke6 26. Rxf8 d5 27. Re8+ Kf5 28. Rxe5+ Kxe5 29. c3 Be6 30. d4+ Kd6 31. Bf4+ Ke7 32. Bg5+ Kd6 33. Nd2 Rg8 34. Bf4+ Ke7 35. c4 Rg1+ 36. Nf1 Rg2 37. cxd5 cxd5 38. Bb5 a6 39. Ba4 b5 40. Bb3 Rxb2 41. a4 Bg4+ 42. Ke1 Re2+ 43. Kd1 Rf2+ 44. Ke1 Rxf4 45. Bxd5 Rxd4 46. Bb7 Rd6 47. axb5 axb5 48. Ra7 Re6+ 49. Kd2 Re2+ 50. Kc3 Kf6 51. Ra6+ Kf5 52. Bc8+ Ke4 53. Bxg4 Rf2 54. Nd2+ Kf4 55. Bd7 Rh2 56. Bxb5 Rh3+ 57. Bd3 Ke3 58. Nc4+ Kf2 59. Ra2+ Ke1 60. Ne5 Re3 61. Kd4 Kd1 62. Nc4 Re1 63. Ra1# 1-0",
    B: "1302",
  },
  {
    W: "1875",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. c3 e5 4. d4 cxd4 5. cxd4 exd4 6. Nxd4 Bb4+ 7. Nc3 Nf6 8. f3 O-O 9. Be2 b6 10. Be3 Bb7 11. O-O Bxc3 12. bxc3 Ne7 13. Nf5 Nxf5 14. exf5 Nd5 15. Bd2 f6 16. Qb3 Kh8 17. Bc4 Ne7 18. Bd3 Qc7 19. Rae1 Rae8 20. Kh1 Nd5 21. Be4 Qd6 22. Rd1 Nf4 23. Bxb7 Re2 24. Bxf4 Qxf4 25. Rxd7 Qxf5 26. Rdd1 Rfe8 27. Be4 Qg5 28. Rg1 h5 29. Qf7 Rg8 30. Bd5 Rd8 31. c4 h4 32. h3 a5 33. a4 f5 34. f4 Qh6 35. Rde1 1-0",
    B: "1903",
  },
  {
    W: "1378",
    T: "180+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. e5 e6 4. d4 Ne7 5. Nc3 O-O 6. Bg5 b6 7. Bd3 h6 8. Bh4 g5 9. Bg3 Nf5 10. Bxf5 exf5 11. O-O d6 12. h3 Re8 13. Nd5 Bb7 14. Nf6+ Bxf6 15. exf6 Qxf6 16. h4 f4 17. hxg5 hxg5 18. Bh2 Bxf3 19. Qxf3 Nd7 20. Rfe1 Rxe1+ 21. Rxe1 Kg7 22. Qxa8 Qxd4 23. Qe4 Qxb2 24. Qd3 Qxa2 25. Qc3+ Kg6 26. Qxc7 Ne5 27. Qxd6+ Kf5 28. Qxe5+ Kg6 29. Qd6+ Kg7 30. Qe7 Qa5 31. Qe5+ Qxe5 32. Rxe5 Kf6 33. Re1 a5 34. Rb1 Ke5 35. Rxb6 Kd5 36. Ra6 Kc4 37. Rxa5 Kc3 38. Rxg5 Kxc2 39. Rf5 Kd3 40. Rxf7 Ke4 41. Rxf4+ Kd5 42. g4 Ke6 43. g5 Ke5 44. Rf3+ Kd4 45. g6 Kc5 46. g7 Kd5 47. g8=Q+ Kc6 48. Qe8+ Kd5 49. Rd3+ Kc4 50. Rd1 Kc3 51. Qc8+ Kb2 52. Rd3 Ka2 1/2-1/2",
    B: "1413",
  },
  {
    W: "1182",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Qe7 5. Bxf7+ Kd8 6. Bc4 Nd4 7. Nf7+ Ke8 1-0",
    B: "999",
  },
  {
    W: "1842",
    T: "180+0",
    P: "1. e4 c5 2. b4 e6 3. bxc5 Bxc5 4. d4 Be7 5. Nf3 d6 6. Bb5+ Nc6 7. Qe2 Bd7 8. Nc3 Nf6 9. e5 dxe5 10. Bxc6 Bxc6 11. Nxe5 O-O 12. Nxc6 bxc6 13. Be3 Rb8 14. O-O Nd5 15. Ne4 Nxe3 16. fxe3 Qd5 17. Qf3 Bd6 18. Nc3 Qh5 19. Qxh5 g6 20. Qa5 1-0",
    B: "1735",
  },
  {
    W: "1550",
    T: "180+0",
    P: "1. c4 e5 2. Nc3 Bc5 3. Nf3 d6 4. d4 exd4 5. Nxd4 Bf5 6. Nxf5 Qf6 7. Qd3 Ne7 8. Nd5 Nxf5 9. Nxc7+ Kd7 10. Nxa8 Nh4 11. Be3 Na6 12. a3 Rxa8 13. b4 Bxe3 14. Qxe3 b6 15. g3 Nf5 16. Qd3 Qxa1+ 17. Qd1 Qc3+ 18. Qd2 Qxc4 19. e3 Qe6 20. Bxa6 Re8 21. Bb5+ Kc7 22. Qc3+ Kb8 23. O-O Rc8 24. Qd3 g6 25. Ba6 Rc7 26. a4 Qa2 27. a5 Qc2 28. Qd5 Qc6 29. Qg2 Qxg2+ 30. Kxg2 bxa5 31. bxa5 Rc6 32. Rb1+ Kc7 33. Rb7+ Kd8 34. Rxa7 Rc8 35. Bxc8 Kxc8 36. Ra8+ Kd7 37. a6 Ke6 38. a7 Ne7 39. Rf8 Nc8 40. a8=Q Kf5 41. Qxc8+ Kg5 42. Rxf7 Kh6 1-0",
    B: "1546",
  },
  {
    W: "2024",
    T: "180+0",
    P: "1. d4 d5 2. Nf3 c6 3. Bf4 Nf6 4. Nbd2 Nbd7 5. e3 e6 6. Bd3 Be7 7. O-O b6 8. Qe2 Bb7 9. Rae1 c5 10. c3 O-O 11. Ne5 Ne4 12. Bb5 Nxd2 13. Qxd2 Nf6 14. Nc6 Bxc6 15. Bxc6 Bd6 16. Bxd6 Qxd6 17. Bxa8 Rxa8 18. f3 cxd4 19. exd4 h6 20. Re5 Nd7 21. Rh5 Kh7 22. Qd3+ g6 23. Rh3 Kg7 24. Re1 Rf8 25. Qe3 Rh8 26. f4 h5 27. Rg3 Kh7 28. h3 Rf8 29. Rf3 a5 30. g4 h4 31. Qf2 Qe7 32. f5 Qg5 33. fxe6 fxe6 34. Rxf8 1-0",
    B: "2057",
  },
  {
    W: "1311",
    T: "180+0",
    P: "1. c4 g6 2. Nc3 Bg7 3. g3 e6 4. Bg2 Ne7 5. e4 O-O 6. d3 b6 7. Bg5 Bb7 8. Nge2 h6 9. Bxe7 Qxe7 10. O-O f5 11. exf5 exf5 12. d4 Bxg2 13. Kxg2 Nc6 14. d5 Ne5 15. b3 Ng4 16. Qd2 Rae8 17. h3 Nf6 18. f3 h5 19. a3 g5 20. b4 g4 21. hxg4 fxg4 22. fxg4 hxg4 23. Rh1 Ne4 24. Nxe4 Qxe4+ 25. Kg1 Qxe2 26. Qxe2 Rxe2 27. Rh4 Bxa1 28. Rxg4+ Kf7 29. Rf4+ Ke8 30. Rxf8+ Kxf8 31. Kf1 Re3 32. Kf2 Rxa3 33. Ke2 Rb3 34. Kd2 Rxb4 35. Kc2 Rxc4+ 36. Kd3 Rg4 0-1",
    B: "1274",
  },
  {
    W: "2012",
    T: "180+0",
    P: "1. c4 d5 2. cxd5 Qxd5 3. Nc3 Qd8 4. e4 c6 5. g3 e5 6. Bg2 Be6 7. Nge2 a6 8. O-O Bc5 9. a3 Nf6 10. b3 Nbd7 11. Na4 Ba7 12. Bb2 Nb6 13. Nxb6 Bxb6 14. Bxe5 O-O 15. d4 Nd7 16. Bd6 Re8 17. e5 Nf8 18. Nc3 Ng6 19. Na4 Ba7 20. Nc5 Qc8 21. b4 Bc4 22. Re1 b6 23. Ne4 Bd5 24. Rc1 Bb8 25. Qc2 Qf5 26. Bxb8 Raxb8 27. Nd6 Qxc2 28. Rxc2 Re6 29. Rec1 Bxg2 30. Kxg2 Ne7 31. f4 g6 32. Ne4 h6 33. Nf6+ Kg7 34. g4 Rc8 35. Kf3 Rd8 36. Ke4 Nd5 37. Nxd5 cxd5+ 38. Ke3 Re7 39. Rc6 Rb7 40. Rf6 Rdd7 41. Rcc6 a5 42. g5 hxg5 43. fxg5 axb4 44. axb4 b5 45. Rfd6 Kf8 46. Kf4 Ke7 47. h4 Rxd6 48. Rxd6 Rc7 49. Rxd5 Rc2 50. Rxb5 Rh2 51. Kg4 Rd2 52. d5 Rd4+ 53. Kg3 Re4 54. Rb7+ 1-0",
    B: "2010",
  },
  {
    W: "2067",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O Nf6 5. d3 O-O 6. Bg5 d6 7. c3 Bg4 8. Nbd2 a6 9. b4 Ba7 10. a4 Kh8 11. h3 Be6 12. Be3 Ne7 13. Qe2 Ng6 14. Ng5 Qd7 15. Bxe6 fxe6 16. Bxa7 Rxa7 17. g3 d5 18. exd5 exd5 19. Kg2 h6 20. Ngf3 Qe6 21. Rae1 Nh5 22. Kh2 Nhf4 23. gxf4 Nxf4 24. Ng5 hxg5 25. Qg4 Qf6 26. Re3 Raa8 27. Nf3 Rae8 28. Nxg5 e4 29. d4 Nd3 30. Qh5+ Qh6 31. Nf7+ Rxf7 32. Qxf7 Re6 33. Rg1 Rf6 34. Qe8+ Kh7 35. Rg2 Nxf2 36. Reg3 Qf4 37. Kg1 Qc1+ 38. Kh2 Qh1# 0-1",
    B: "2100",
  },
  {
    W: "1486",
    T: "180+0",
    P: "1. e4 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qa4 g6 5. Nf3 Bg7 6. Bb5 Nf6 7. e5 Nd5 8. Bxc6 bxc6 9. O-O O-O 10. c4 Nb6 11. Qb3 Ba6 12. Re1 Bxc4 13. Qc2 Bd5 14. Nc3 Be6 15. Bf4 Rc8 16. Ng5 Bf5 17. Qd2 Nc4 18. Qd4 Nxb2 19. Ne2 Rb8 20. Rab1 c5 21. Qc3 Na4 22. Rxb8 Qxb8 23. Qa5 Nb6 24. Qxc5 h6 25. Nf3 d6 26. exd6 Nd7 27. dxe7 Nxc5 28. Bxb8 Rxb8 29. Ned4 Bxd4 30. Nxd4 Re8 31. Nc6 Ne6 32. Nxa7 Rxe7 33. Nc6 Rc7 34. Ne5 Kg7 35. a4 Ra7 36. Nf3 Rxa4 37. h3 Bc2 38. g4 Nf4 39. Kh2 Ra3 40. Nd4 Rxh3+ 41. Kg1 Ba4 42. Re3 Rxe3 43. fxe3 Nd3 44. Kf1 Ne5 45. Kg2 Nxg4 46. Kf3 Nxe3 47. Kxe3 1-0",
    B: "1530",
  },
  {
    W: "2002",
    T: "180+0",
    P: "1. d4 g6 2. c4 Bg7 3. Nc3 d6 4. e4 Nf6 5. f4 e6 6. Nf3 O-O 7. Bd3 c5 8. d5 exd5 9. cxd5 Re8 10. e5 dxe5 11. fxe5 Nxd5 12. O-O Nxc3 13. bxc3 a6 14. Bg5 Qa5 15. Qc2 Nc6 16. Rae1 Be6 17. Bd2 Qxa2 18. Qd1 c4 19. Bb1 Qb3 20. Bc2 Qb6+ 21. Kh1 Qc7 22. Nd4 Nxd4 23. cxd4 Rad8 24. Qa1 Qc6 25. Be4 Qd7 26. Bg5 Rb8 27. Rd1 h6 28. Bh4 g5 29. Bf2 Bd5 30. Bc2 Qe6 31. Bg3 b5 32. Qc3 Ra8 33. Bf5 Qc6 34. Qc2 b4 35. Rb1 b3 36. Qf2 Ra7 37. h4 a5 38. hxg5 hxg5 39. Kh2 a4 40. Rh1 a3 41. Kg1 a2 42. Bh7+ Kf8 43. Ra1 c3 44. Rh5 b2 45. Rf1 a1=Q 46. Bg6 b1=Q 47. Bxf7 Rxf7 0-1",
    B: "1963",
  },
  {
    W: "1854",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. c3 Nf6 4. Be2 Bd7 5. O-O Nxe4 6. d3 Nf6 7. Re1 e6 8. d4 cxd4 9. cxd4 Be7 10. Nc3 O-O 11. d5 e5 12. Bg5 Bf5 13. Qd2 Nbd7 14. Rac1 a6 15. b4 h6 16. Bh4 Nxd5 17. Nxd5 Bxh4 18. Ne3 Be6 19. Qxd6 Bf6 20. Red1 Rc8 21. h3 Rxc1 22. Rxc1 Qb6 23. Qxb6 Nxb6 24. a3 Rc8 25. Rxc8+ Bxc8 26. Nc4 Nxc4 27. Bxc4 Bd7 28. g4 Bc6 29. Nd2 Kf8 30. f3 Be7 31. Kf2 a5 32. b5 Bd7 33. a4 Bb4 34. Ne4 Ke7 35. Ke3 Be6 36. f4 exf4+ 37. Kxf4 Bxc4 0-1",
    B: "1867",
  },
  {
    W: "1921",
    T: "180+0",
    P: "1. e4 e5 2. Bc4 Nf6 3. c3 c6 4. d4 exd4 5. cxd4 Bb4+ 6. Bd2 Qa5 7. e5 Ne4 8. Nc3 Nxd2 9. Qxd2 O-O 10. Nge2 d5 11. exd6 Bg4 12. f3 Bf5 13. O-O Nd7 14. Ng3 Bg6 15. a3 Bxd6 16. b4 Qc7 17. Nge4 Bxh2+ 18. Kh1 Bf4 19. Qe2 Rfe8 20. Rae1 b5 21. Bd3 h6 22. Qc2 Qd8 23. Nc5 Qh4+ 24. Kg1 Bg3 25. Rxe8+ Rxe8 0-1",
    B: "1889",
  },
  {
    W: "2288",
    T: "60+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. d4 Nb6 4. Nf3 d6 5. Bd3 g6 6. O-O Bg7 7. Qe2 O-O 8. h3 Nc6 9. c3 dxe5 10. dxe5 Bf5 11. Bxf5 gxf5 12. Bf4 Qe8 13. Nbd2 Rd8 14. Rad1 Nd5 15. Bg5 f6 16. exf6 exf6 17. Qxe8 Rfxe8 18. Bh4 Nf4 19. Rfe1 Ne2+ 20. Kf1 Nf4 21. Bg3 Ng6 22. Bxc7 Rxe1+ 23. Rxe1 Rd7 24. Bh2 Nce5 25. Nd4 Kf7 26. N2b3 Nd3 27. Rd1 Nxb2 28. Rd2 Nc4 29. Re2 b5 30. Nxb5 Rd1+ 31. Re1 Rxe1+ 32. Kxe1 a6 33. N5d4 Nge5 34. Ke2 f4 35. Bxf4 Nd6 36. Nc5 Ne8 37. Nxa6 Ke7 38. Bxe5 fxe5 39. Nf5+ Kf6 40. Nxg7 Nxg7 41. Ke3 Ne6 42. Ke4 Ng5+ 43. Ke3 Ne6 44. h4 Nc5 45. Nxc5 Kf5 46. Ne4 h5 47. g3 Kg4 48. a4 Kh3 49. a5 Kg4 50. a6 Kh3 51. a7 Kg4 52. a8=Q Kh3 53. Qd5 Kg2 54. Qxe5 Kf1 55. Qxh5 Ke1 56. Qg5 Kf1 57. h5 Ke1 58. f3 Kd1 59. f4 Kc1 60. f5 Kb2 61. Kd3 Kb3 62. Qf4 Kb2 63. c4 Kb1 64. Qd6 Ka2 65. Qd4 Kb3 66. Qc3+ Ka4 67. Qb4+ Kxb4 1/2-1/2",
    B: "2146",
  },
  {
    W: "2031",
    T: "60+0",
    P: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 e6 5. Nc3 Nf6 6. Nf3 Be7 7. Bd3 a6 8. O-O b6 9. Bg5 Bb7 10. Rc1 O-O 11. a3 h6 12. Bh4 Nh7 13. Bxe7 Qxe7 14. cxd5 exd5 15. Re1 Qd7 16. Bb1 Nf6 17. Qd3 g6 18. Ne5 Qd6 19. Nxg6 fxg6 20. Qxg6+ Kh8 21. Re5 Rg8 22. Qxh6+ Nh7 23. Qxh7# 1-0",
    B: "2001",
  },
  {
    W: "1262",
    T: "60+0",
    P: "1. c4 d5 2. cxd5 Qxd5 3. Nc3 Qc6 4. d4 Nf6 5. e3 e6 6. Bb5 Bd7 7. Bxc6 Bxc6 8. Nf3 Bb4 9. O-O Nbd7 10. a3 O-O-O 11. axb4 a6 12. b5 Bxb5 13. Nxb5 axb5 14. Ra8+ Nb8 15. Ne5 Rhe8 16. Nxf7 Rd7 17. Qb3 Rxf7 18. Qxb5 Rd7 19. Ra7 b6 20. Bd2 Ne4 21. Rd1 Nxd2 22. Rxd2 Red8 23. Rc2 Rd5 24. Raxc7# 1-0",
    B: "1345",
  },
  {
    W: "1913",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Bd7 6. a3 Rc8 7. b4 f6 8. exf6 Nxf6 9. bxc5 b6 10. cxb6 axb6 11. Bb5 Bd6 12. Bg5 O-O 13. Nbd2 Qc7 14. O-O Ne4 15. h3 Nxc3 16. Qb3 Nxb5 17. Qxb5 Nxd4 18. Qb4 Nxf3+ 19. Nxf3 Bxb4 20. axb4 e5 21. Rac1 Qd6 22. Rcd1 d4 23. Rfe1 Rce8 24. Bh4 Bc6 25. Bg3 Bxf3 26. gxf3 Rxf3 27. Kg2 Qd5 28. Rd3 Rxd3+ 29. Kg1 e4 30. h4 e3 31. fxe3 Rdxe3 32. Kf2 Rxe1 33. Be5 R1xe5 34. b5 Qf7+ 35. Kg3 Re3+ 36. Kg2 R8e4 37. h5 Qf3+ 0-1",
    B: "1984",
  },
  {
    W: "1733",
    T: "60+0",
    P: "1. c4 e5 2. Nc3 Nf6 3. g3 Bc5 4. Bg2 d6 5. e3 O-O 6. Rb1 a5 7. Nge2 Nc6 8. a3 Bg4 9. O-O Ba7 10. h3 Bh5 11. Kh2 Bg6 12. Nb5 Rb8 13. Nxa7 Nxa7 14. Qa4 Nc6 15. b4 Bxb1 16. b5 Ne7 17. Bb2 b6 18. Rxb1 Nh5 19. Qc2 f5 20. Qc3 f4 21. exf4 exf4 22. Nxf4 Nxf4 23. Qxg7# 1-0",
    B: "1750",
  },
  {
    W: "2131",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. Nf3 c6 5. Bc4 Bf5 6. d4 e6 7. Bd2 Nf6 8. Ne4 Qc7 9. Nxf6+ gxf6 10. c3 Nd7 11. Qe2 Rg8 12. Bd3 Bxd3 13. Qxd3 O-O-O 14. Rg1 e5 15. dxe5 fxe5 16. Qe3 f6 17. O-O-O Nb6 18. Nh4 Kb8 19. Nf5 Nc4 20. Qe2 Nxd2 21. Rxd2 Rxd2 22. Qxd2 Bc5 23. Kc2 Rd8 24. Qe2 Qb6 25. b4 Bf8 26. Rd1 Rxd1 27. Kxd1 a5 28. a3 axb4 29. axb4 Qa6 30. Qxa6 bxa6 31. Kc2 Kc7 32. f3 c5 33. g4 cxb4 34. cxb4 Kc6 35. Kb3 Kd5 36. h4 e4 37. fxe4+ Kxe4 38. Ng3+ Kf3 39. Nh5 Be7 40. Ng7 Kxg4 41. Ne6 f5 42. Kc4 f4 43. Nxf4 Kxf4 44. h5 Kg5 45. b5 axb5+ 46. Kxb5 Kxh5 47. Kc4 Kg4 48. Kd3 Kg3 49. Ke2 Kg2 50. Ke3 Bd6 51. Ke4 h5 52. Kf5 h4 53. Kg4 h3 0-1",
    B: "2138",
  },
  {
    W: "2174",
    T: "60+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Bg4 5. Bc4 dxe5 6. h3 Bxf3 7. Qxf3 e6 8. dxe5 c6 9. Nc3 Nd7 10. Bf4 Bc5 11. O-O-O Qe7 12. h4 h6 13. Ne4 O-O-O 14. Nd6+ Bxd6 15. exd6 Qf6 16. Bxd5 exd5 17. Qg3 Rhe8 18. Rde1 Re6 19. Rxe6 fxe6 20. Re1 Rf8 21. Be5 Qxf2 22. Qxf2 Rxf2 23. Bxg7 e5 24. Bxh6 Rxg2 25. Rh1 e4 26. Be3 d4 27. Bxd4 c5 28. Be3 Nf6 29. h5 Ng4 30. h6 1-0",
    B: "2147",
  },
  {
    W: "1825",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Bd3 Nxe4 6. Bxe4 Nd7 7. c3 c6 8. Qc2 Bd6 9. Bxh7 g6 10. Bxg6 fxg6 11. Qxg6+ Kf8 12. Bh6+ Ke7 13. Nf3 Qg8 14. Qxg8 Rxg8 15. O-O b6 16. Bg5+ Ke8 17. Nh4 Rxg5 18. f4 Rg4 19. Nf3 Bb7 20. f5 exf5 21. h3 Rg3 22. Nh4 f4 23. Nf5 Bc7 24. Nxg3 fxg3 25. Rf3 Ke7 26. Re1+ Kd6 27. Rxg3 Rf8 28. Rg6+ Rf6 29. Rg7 Re6 30. Rf1 Re2 31. Rff7 Bc8 32. c4 Re1+ 33. Kf2 Rb1 34. Rg6+ Nf6 35. Rgxf6+ Be6 36. Rh7 Rxb2+ 37. Ke3 Rxa2 38. Rhh6 Ra3+ 39. Kd2 Ra2+ 40. Kc3 Rxg2 41. Rxe6+ Kd7 42. Ref6 Kc8 0-1",
    B: "1796",
  },
  {
    W: "1646",
    T: "60+0",
    P: "1. d4 Nf6 2. c4 d5 3. Nf3 Be6 4. e3 g6 5. Nc3 Bg7 6. cxd5 Nxd5 7. Nxd5 Bxd5 8. Bd2 O-O 9. Be2 c6 10. O-O Nd7 11. Rc1 Re8 12. a3 e5 13. dxe5 Nxe5 14. Nxe5 Bxe5 15. Bf3 Bxb2 16. Rb1 Bxa3 17. Rxb7 Bxf3 18. Qxf3 Rb8 19. Rxb8 Qxb8 20. h3 Bd6 21. Bc3 f5 22. Rd1 Bf8 23. Qxc6 Rc8 24. Qf6 Bh6 25. Qh8+ Kf7 26. Rd7+ Ke6 27. Qd4 Qb1+ 28. Kh2 Rxc3 29. Qxc3 Kxd7 30. Qd4+ Ke7 31. Qc5+ 1-0",
    B: "1443",
  },
  {
    W: "1182",
    T: "60+0",
    P: "1. e4 e5 2. Bc4 Nc6 3. f4 exf4 4. Nf3 Nf6 5. O-O Nxe4 6. Re1 Qe7 7. d3 Nd4 8. Nxd4 Nc3 9. Rxe7+ Bxe7 10. Qe1 c6 11. Nf5 O-O 12. Nxe7+ Kh8 13. Qxc3 Re8 14. Nf5 d5 15. Qxg7# 1-0",
    B: "1133",
  },
  {
    W: "1719",
    T: "60+0",
    P: "1. e4 c5 2. c3 Nc6 3. d4 cxd4 4. cxd4 e5 5. dxe5 Nxe5 6. Qd5 d6 7. Nc3 Nf6 8. Qd1 Be6 9. Nf3 Be7 10. Bb5+ Nfd7 11. Nxe5 dxe5 12. O-O O-O 13. f4 exf4 14. Bxf4 Bc5+ 15. Kh1 Nf6 16. Qf3 Qb6 17. Be5 Bd4 18. Qg3 Bxc3 19. Bxc3 Nxe4 20. Qxg7# 1-0",
    B: "1700",
  },
  {
    W: "1816",
    T: "180+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. d4 Nb6 4. f4 d6 5. Nf3 g6 6. Bc4 Bg7 7. Bb3 O-O 8. O-O e6 9. c3 d5 10. Nbd2 N8d7 11. Re1 c5 12. Kh1 a5 13. a3 cxd4 14. cxd4 f6 15. Nf1 fxe5 16. fxe5 Nc4 17. Ng3 Ndb6 18. Bg5 Qe8 19. Qc2 Bd7 20. Bxc4 Nxc4 21. b3 Nb6 22. h3 Rc8 23. Qd2 a4 24. bxa4 Nc4 25. Nh2 Nxd2 26. Bxd2 Bxa4 27. Ng4 Rc2 28. Bh6 Qb5 29. Bxg7 Kxg7 30. Rf1 Rf5 31. Nxf5+ gxf5 32. Nf6 Qe2 33. Rg1 Rd2 34. Rae1 Qf2 35. Ne8+ Bxe8 0-1",
    B: "2162",
  },
  {
    W: "1535",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 c6 3. Bc4 d5 4. exd5 cxd5 5. Bb3 e4 6. Nd4 Nc6 7. Nxc6 bxc6 8. d3 f5 9. O-O Nf6 10. Re1 Bb4 11. Bd2 Bxd2 12. Qxd2 O-O 13. Nc3 Re8 14. dxe4 fxe4 15. Rad1 Kh8 16. Qg5 h6 17. Qg6 d4 18. Ne2 d3 19. cxd3 exd3 20. Bf7 Rf8 21. Nf4 d2 22. Nh5 dxe1=Q+ 23. Rxe1 Rxf7 24. Kh1 Nxh5 25. Qxf7 Nf6 26. Re8+ Nxe8 27. Qf8+ Kh7 28. Qf5+ g6 29. h3 Qd1+ 30. Kh2 gxf5 0-1",
    B: "1347",
  },
  {
    W: "2284",
    T: "60+0",
    P: "1. d3 e6 2. Nf3 d6 3. Nbd2 Nd7 4. e4 c6 5. h3 Qc7 6. c3 e5 7. Qc2 Be7 8. Be2 Ngf6 9. Nf1 d5 10. Be3 d4 11. Bd2 c5 12. Rc1 O-O 13. g4 h6 14. Ng3 Re8 15. Rg1 Nf8 16. Nf5 Bd8 17. h4 Bxf5 18. gxf5 N8h7 19. Bxh6 g6 20. fxg6 fxg6 21. h5 g5 22. Bxg5 Nxh5 23. Bxd8+ Ng7 24. Bxc7 Re7 25. Bxe5 Rxe5 26. Nxe5 Re8 27. f4 Kh8 28. cxd4 Ne6 29. dxc5 Nd4 30. Qc3 Nxe2 31. Kxe2 Nf6 32. Ng6+ 1-0",
    B: "1280",
  },
  {
    W: "1849",
    T: "60+0",
    P: "1. g3 b6 2. Bg2 Nc6 3. b4 Bb7 4. b5 Na5 5. Nf3 e6 6. Nc3 Nf6 7. e4 Bc5 8. d3 O-O 9. Na4 h6 10. Nxc5 bxc5 11. Qd2 c6 12. Bb2 Rb8 13. O-O cxb5 14. Ne5 d5 15. Qf4 Nc6 16. Ng4 Nxg4 17. Qxg4 d4 18. c3 Ne5 19. Qh5 Nxd3 20. Ba3 b4 21. Bc1 bxc3 22. Bxh6 gxh6 23. Qxh6 Ne5 24. f4 Ng6 25. f5 Nh8 26. f6 Qxf6 27. Qxf6 e5 28. Rf5 Rfe8 29. Rg5+ Ng6 30. Rxg6+ fxg6 31. Qxg6+ Kf8 32. Rf1+ Ke7 33. Qf7+ Kd8 34. Rf6 Bc8 35. Rd6+ Bd7 36. Qd5 1-0",
    B: "1993",
  },
  {
    W: "1399",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 d6 3. Nc3 g6 4. e4 Bg7 5. f4 c6 6. Be3 Bg4 7. Nf3 Qd7 8. e5 dxe5 9. fxe5 Nh5 10. h3 Bxf3 11. Qxf3 O-O 12. g4 Qc7 13. gxh5 b6 14. hxg6 fxg6 15. Qg4 Na6 16. a3 c5 17. Qe6+ Rf7 18. Nd5 Qc8 19. Qxc8+ Rxc8 20. e6 Rxf1+ 21. Rxf1 Rd8 22. Nxe7+ Kh8 23. Nc6 Re8 24. Nxa7 cxd4 25. Bg5 d3 26. Kd2 Bxb2 27. Rab1 Bxa3 28. e7 Bxe7 29. Bxe7 Rxe7 30. Rf8+ Kg7 31. Ra8 h5 32. Nc6 Rc7 33. Rxb6 Nc5 34. Ra7 Rxa7 35. Nxa7 Kh6 36. Nc6 Kg5 37. Ne7 Kf4 38. Nxg6+ Kg3 39. Ne5 Kxh3 0-1",
    B: "1391",
  },
  {
    W: "2399",
    T: "60+0",
    P: "1. d4 b5 2. e3 Bb7 3. Nf3 b4 4. c4 c5 5. Bd3 cxd4 6. exd4 Nf6 7. O-O a5 8. Re1 e6 9. Nbd2 Be7 10. Ne4 Nxe4 11. Bxe4 Bxe4 12. Rxe4 d5 13. cxd5 Qxd5 14. Re5 Qd8 15. Be3 O-O 16. Qd3 Nd7 17. Rh5 Nf6 18. Rh3 g6 19. Re1 Qd5 20. Bg5 Rfd8 21. a3 bxa3 22. bxa3 Rab8 23. g3 Rb3 24. Qa6 Rxf3 25. Qb6 Qxd4 26. Qxd4 Rxd4 27. Rh4 Rxh4 28. gxh4 Rxa3 29. Rc1 Kg7 30. Rc7 Bc5 31. Rxc5 Ne4 32. Re5 Nxg5 33. hxg5 h6 34. gxh6+ Kxh6 35. Rb5 f5 36. Rb7 e5 37. Kg2 e4 38. Ra7 a4 39. Ra5 Kg5 40. h3 Kf4 41. Ra6 g5 42. Ra5 Ra2 43. Ra6 e3 44. Ra5 Rxf2+ 45. Kg1 Ra2 46. h4 gxh4 47. Kf1 Kf3 48. Ke1 0-1",
    B: "2435",
  },
  {
    W: "1607",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. Nf3 Nf6 5. Bc4 e6 6. d3 c5 7. Bg5 Nc6 8. O-O Be7 9. Qe2 Nd4 10. Nxd4 cxd4 11. Nd1 O-O 12. f4 Bd7 13. Nf2 b5 14. Bb3 a5 15. a3 Rc8 16. a4 b4 17. Ne4 Nxe4 18. Bxe7 Qxe7 19. Qxe4 f5 20. Qxd4 Rcd8 21. Qf2 Bc6 22. Rae1 Bd7 23. Qa7 Qf6 24. Qxa5 Kh8 25. Qxb4 Qg6 26. Rf2 Bc6 27. Rxe6 Qg4 28. Rxc6 Qd1+ 29. Rf1 Qg4 30. Rc7 Rde8 31. Be6 Rf6 32. Qe7 Rxe6 33. Qxg7+ Qxg7 34. Rxg7 Kxg7 35. Rf2 Kf6 36. b3 Rc8 37. Kf1 h5 38. h3 Rec6 39. Ke2 Rxc2+ 40. Ke3 R2c3 41. Rd2 Rxb3 42. a5 Ra3 43. Rb2 Rxa5 44. Rb6+ Ke7 45. Rb7+ Kd6 46. Rh7 Re8+ 47. Kf2 Ra2+ 48. Kg3 Rg8+ 49. Kf3 Raxg2 50. Rh6+ Kc5 0-1",
    B: "1632",
  },
  {
    W: "1751",
    T: "480+4",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Nf3 c6 1-0",
    B: "1266",
  },
  {
    W: "1344",
    T: "300+3",
    P: "1. d4 Nf6 2. Nf3 d5 3. Bf4 e6 4. e3 Nc6 5. c4 Bd6 6. c5 Bxf4 7. exf4 b6 8. Ne5 Nxe5 9. fxe5 Ne4 10. Bd3 bxc5 11. Bxe4 dxe4 12. O-O cxd4 13. Re1 Bb7 14. Qb3 Rb8 15. Nd2 Qg5 16. Nxe4 Qxe5 17. Nc3 Qg5 18. Ne4 Qg6 19. Rac1 0-1",
    B: "1391",
  },
  {
    W: "1890",
    T: "60+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. f4 Nxf4 4. Nf3 Ng6 5. d4 e6 6. Bd3 d5 7. c3 c5 8. Qc2 Nc6 9. h4 h5 10. Bxg6 fxg6 11. Qxg6+ Ke7 12. Bg5+ Kd7 13. Bxd8 Nxd8 14. O-O cxd4 15. Ng5 Kc7 16. Nf7 Nxf7 17. Rxf7+ Bd7 18. cxd4 Rh6 19. Qc2+ Kd8 20. Nc3 Rc8 21. Raf1 Rg6 22. Qxg6 Be8 23. Rxf8 Kc7 24. Rxe8 Rxe8 25. Qxe8 Kb6 26. Qxe6+ 1-0",
    B: "1880",
  },
  {
    W: "1855",
    T: "15+0",
    P: "1. e4 e5 2. Nc3 Nf6 3. Nf3 d5 4. Bc4 Nxe4 5. Nxe4 dxe4 6. Bxf7+ Kxf7 7. Nxe5+ Kg8 8. Qg4 Qd5 9. Qg5 Nc6 10. Nxc6 Qxg5 11. Ne7+ Bxe7 12. O-O Qh6 13. d3 Qg6 14. Be3 Bh4 15. g3 Bh3 16. Rfe1 Qg4 17. f4 Qf3 18. Re2 Qxe2 19. Bf2 Qf3 20. Bd4 Qg2# 0-1",
    B: "1550",
  },
  {
    W: "2025",
    T: "15+0",
    P: "1. d3 Nf6 2. g3 g6 3. Bd2 Bg7 4. Nf3 O-O 5. Bg2 h5 6. O-O h4 7. Ne1 h3 8. Bh1 d5 9. c4 e5 10. cxd5 e4 11. dxe4 Re8 12. e5 Nfd7 13. Nc3 Nxe5 14. f4 Bf6 15. Nf3 Nxf3+ 16. exf3 Bg7 17. Re1 Qe7 18. Rxe7 Rxe7 19. Qc2 Re8 20. Re1 Nd7 21. Rxe8+ Bf8 22. Qxg6+ fxg6 23. Ne4 Nf6 24. Nxf6+ Kf7 25. Rxc8 Kxf6 26. Rxa8 c6 27. Rxa7 cxd5 28. Rxb7 Bc5+ 29. Kf1 Bd4 30. f5 gxf5 31. g4 Be5 32. g5+ Ke6 33. Re7+ Kxe7 34. f4 Kf7 35. fxe5 Ke6 36. Be3 d4 37. Be4 fxe4 38. Ke2 Kxe5 39. Bxd4+ Ke6 0-1",
    B: "2160",
  },
  {
    W: "1852",
    T: "15+0",
    P: "1. e3 Nf6 2. d3 e5 3. Be2 d5 4. Nf3 Bd6 5. Nxe5 Bxe5 6. f4 Bd6 7. e4 Nc6 8. e5 Be7 9. O-O Be6 10. f5 Qd7 11. fxe6 fxe6 12. exf6 gxf6 13. Bh5+ Kd8 14. Bh6 Rg8 15. Bf7 Rh8 16. Re1 Bd6 17. Bxe6 Qe7 18. Nc3 Ne5 19. Nxd5 Qf8 20. Bxf8 Rxf8 21. Nf4 c6 22. Bd5 Kc7 23. Ne6+ Kb6 24. Nxf8 Ka6 25. Bb3 b5 26. c4 Kb6 27. Rc1 Rc8 28. c5+ Kb7 29. cxd6 Rd8 30. Rxe5 1-0",
    B: "1954",
  },
  {
    W: "1606",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Be7 4. d3 Nf6 5. O-O O-O 6. b3 Bg4 7. h3 Nc6 8. c3 Bh5 9. d4 d5 10. exd5 Nxd5 11. Be2 e4 12. Ne5 Bxe2 13. Qxe2 Nxe5 14. dxe5 f5 15. exf6 Bxf6 16. Qxe4 Nxc3 17. Nxc3 Bxc3 18. Rb1 Bf6 19. Ba3 Re8 20. Qc4+ Kh8 21. Rfd1 Qc8 22. Rd3 a6 23. Rbd1 b5 24. Qf7 c5 25. b4 c4 26. Rd7 Qc6 27. Bc1 Rf8 28. Qh5 Qb6 29. Kh1 Qxf2 30. Bg5 Be5 31. Rg1 c3 32. Rdd1 Qxa2 33. Be7 Rfe8 34. Qxe5 c2 35. Rc1 Qf7 36. Rge1 Ra7 37. Qb2 Raxe7 38. Rxe7 1-0",
    B: "1547",
  },
  {
    W: "1791",
    T: "600+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 Nf6 5. Nc3 Be6 6. Bg5 h6 7. Bh4 c5 8. Bb5+ Nc6 9. Ne5 Be7 10. Nxc6 bxc6 11. Bxc6+ Bd7 12. Bxa8 Qxa8 13. O-O O-O 14. dxc5 Qc6 15. Bxf6 Bxf6 16. Nxd5 Bxb2 17. Ne7+ 1-0",
    B: "1826",
  },
  {
    W: "1257",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 d6 3. g3 Nf6 4. d3 Be7 5. Nh4 O-O 6. Bg2 Bg4 7. Qd2 d5 8. Qg5 dxe4 9. Qxe5 exd3 10. Nd2 dxc2 11. f3 Nc6 12. Qb5 a6 13. Qxb7 Be6 14. Qxc6 Bd5 15. Qxc2 Bd6 16. Nf5 Qe8+ 17. Kd1 Qe6 18. g4 Ne4 19. fxe4 Bxe4 20. Bxe4 Rae8 21. Qc3 g6 22. Qg7# 1-0",
    B: "1257",
  },
  {
    W: "1442",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bc4 d5 5. Bxd5 Nxd5 6. Nxd5 Bg4 7. d3 f5 8. Bg5 Be7 9. Bxe7 Nxe7 10. Nxe7 Qxe7 11. O-O fxe4 12. dxe4 O-O 13. Qd5+ Kh8 14. Qxe5 Rae8 15. Qxe7 Rxe7 16. Nd2 Rfe8 17. f3 Bd7 18. Rad1 Bb5 19. c4 Ba4 20. b3 Bc6 21. Rde1 Rd8 22. Re2 Red7 23. Rff2 b5 24. c5 b4 25. e5 Bb5 26. Nc4 Rd1+ 27. Rf1 Rxf1+ 28. Kxf1 Rd1+ 29. Kf2 Ra1 30. e6 Kg8 31. e7 Be8 32. Re4 Rxa2+ 33. Kg3 Rb2 34. Nxb2 1-0",
    B: "1500",
  },
  {
    W: "1500",
    T: "900+15",
    P: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. Bf4 Bg4 5. Qb1 Qxd4 6. Nxe4 Nxe4 7. Nf3 Qxf2+ 8. Kd1 Bxf3+ 9. Kc1 Qe1# 0-1",
    B: "1529",
  },
  {
    W: "1992",
    T: "900+15",
    P: "1. e4 c5 2. Bc4 d6 3. Nc3 e6 4. d3 a6 5. a3 b5 6. Ba2 Bb7 7. Nge2 g6 8. O-O Bg7 9. f3 Nc6 10. f4 Nge7 11. Ng3 O-O 12. f5 exf5 13. exf5 Bf6 14. fxg6 hxg6 15. Rxf6 Nd4 16. Bh6 Nef5 17. Rxg6+ 1-0",
    B: "1967",
  },
  {
    W: "1657",
    T: "900+15",
    P: "1. Nf3 c5 2. e4 d6 3. d3 g6 4. g3 Bg7 5. Bg2 Nf6 6. O-O O-O 7. Nbd2 e5 8. Nc4 b5 9. Ne3 Bh6 10. Nd5 Bxc1 11. Nxf6+ Qxf6 12. Qxc1 Bg4 13. Nh4 g5 14. Nf5 Bxf5 15. exf5 Qxf5 16. Bxa8 Na6 17. Be4 Qe6 18. Qxg5+ Kh8 19. Bf5 Rg8 20. Qh5 1-0",
    B: "1609",
  },
  {
    W: "1584",
    T: "900+15",
    P: "1. e4 d6 2. Nf3 Nf6 3. Nc3 g6 4. d3 Bg7 5. Be2 e5 6. d4 exd4 7. Nxd4 c5 8. Nf3 O-O 9. Bc4 Nc6 10. O-O a6 11. a3 b5 12. Ba2 Bb7 13. Re1 Re8 14. Bg5 h6 15. Bxf6 Qxf6 16. Nd5 Qd8 17. Nc3 Qd7 18. Nd2 Rad8 19. Qf3 c4 20. Nd5 Nd4 21. Qd1 Bxd5 22. exd5 Rxe1+ 23. Qxe1 Nxc2 24. Qc1 Nxa1 25. Qxa1 Re8 26. b3 Bxa1 0-1",
    B: "1598",
  },
  {
    W: "1133",
    T: "900+15",
    P: "1. e4 d5 2. Nc3 f5 3. Bd3 fxe4 4. Bb5+ Bd7 5. a4 d4 6. Nxe4 a6 7. Bd3 Bf5 8. Nc5 Bxd3 9. c3 Bc4 10. Nxb7 Qd5 11. b3 Bxb3 0-1",
    B: "1299",
  },
  {
    W: "1381",
    T: "900+15",
    P: "1. e4 Nc6 2. Nf3 d6 3. Bc4 Be6 4. Bb3 d5 5. exd5 Bxd5 6. O-O e5 7. Re1 Bd6 8. Bxd5 Bb4 9. Bxc6+ bxc6 10. Nxe5 Ne7 11. Nxc6 Bc5 12. Nxd8 1-0",
    B: "1356",
  },
  {
    W: "1746",
    T: "600+0",
    P: "1. e4 d6 2. c4 c5 3. Nc3 Nc6 4. Nf3 g6 5. g3 Bg7 6. Bg2 Nf6 7. O-O Bg4 8. Qb3 b6 9. Re1 O-O 10. Nd5 Na5 11. Nxf6+ Bxf6 12. Qc3 Bxc3 0-1",
    B: "1811",
  },
  {
    W: "2013",
    T: "900+10",
    P: "1. d4 d5 2. Nf3 c6 3. g3 Bg4 4. Bg2 Nd7 5. c4 e6 6. O-O Bb4 7. a3 Ba5 8. b4 Bb6 9. c5 Bc7 10. Nc3 Ne7 11. e4 O-O 12. e5 Re8 13. h3 Bh5 14. g4 Bg6 15. Nh4 Be4 16. f3 Bg6 17. Nxg6 Nxg6 18. f4 Nh4 19. Bh1 f6 20. Be3 f5 21. g5 Nf8 22. Qe1 Nhg6 23. h4 h5 24. Bf3 Nxf4 25. Bxf4 g6 26. Qe2 Nh7 27. Bxh5 gxh5 28. Qxh5 Qe7 29. Rf2 Qg7 30. Rg2 Re7 31. Qf3 Qg6 32. h5 Qxg5 33. Bxg5 Nxg5 34. Rxg5+ Rg7 35. Rxg7+ Kxg7 36. Kf2 Rg8 37. Rg1+ Kh7 38. Rxg8 Kxg8 39. Qf4 1-0",
    B: "1394",
  },
  {
    W: "1817",
    T: "60+0",
    P: "1. g3 e5 2. Bg2 d6 3. Nf3 Nc6 4. O-O Be6 5. d3 Qd7 6. a4 f5 7. c3 Nf6 8. b4 Be7 9. b5 Nd8 10. d4 e4 11. Ng5 d5 12. Nxe6 Qxe6 13. Qb3 O-O 14. Nd2 c6 15. c4 dxc4 16. Qxc4 Qxc4 17. Nxc4 cxb5 18. axb5 Nd5 19. Ne5 Bb4 20. Bb2 Ne6 21. Rac1 Rac8 22. Rb1 Nc3 23. Bxc3 Bxc3 24. d5 Bxe5 25. dxe6 Rfe8 26. Rbc1 Rxc1 27. Rxc1 Rxe6 28. Rc8+ Kf7 29. Bh3 Re7 30. Bxf5 e3 31. f3 Bd4 32. Bd3 g5 33. Rc2 h5 34. Kg2 g4 35. h3 gxh3+ 36. Kxh3 Re5 37. Kg2 Kf6 38. Rc7 Re7 39. Rxe7 Kxe7 40. g4 hxg4 41. fxg4 Kd6 42. Kf3 Kc5 43. Kf4 a6 44. Kf5 axb5 45. g5 b4 46. g6 b3 47. g7 Bxg7 48. Ke4 Bc3 49. Kxe3 b2 50. Kf3 1-0",
    B: "1792",
  },
  {
    W: "1100",
    T: "60+0",
    P: "1. e4 e5 2. Qf3 Nc6 3. Bc4 Nf6 4. g4 d5 5. Bb3 Na5 6. g5 dxe4 7. Qh3 Nxb3 8. Qg2 Nd5 9. g6 Nxc1 10. f3 exf3 11. Qxf3 hxg6 12. Nh3 Bxh3 13. Nc3 Nxc3 14. dxc3 Qd6 15. Rxc1 Rd8 16. Rf1 Qe6 17. Rd1 Bxf1 18. Rxd8+ Kxd8 19. Kxf1 Bc5 20. Qd3+ Bd6 21. b4 Rxh2 22. Kg1 Qh3 23. Qxh3 Rxh3 24. Kf1 e4 25. Ke2 Rg3 26. Kf2 Rg4 27. Ke3 f5 28. c4 Rg3+ 29. Kf2 Rg2+ 30. Kxg2 e3 31. Kf1 Bxb4 32. Ke2 f4 0-1",
    B: "1123",
  },
  {
    W: "1809",
    T: "60+0",
    P: "1. e4 d5 2. Qf3 dxe4 3. Qxe4 Nf6 4. Qf3 Bg4 5. Qg3 Nc6 6. h3 Bh5 7. d3 h6 8. Bd2 e6 9. Nc3 Bd6 10. Qxg7 Rg8 11. Qxh6 Nd4 12. Rb1 Nxc2# 0-1",
    B: "1535",
  },
  {
    W: "1598",
    T: "60+0",
    P: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. d4 h6 5. Nc3 Nf6 6. Be3 e6 7. Bd3 Bd6 8. h3 Nc6 9. a3 Bb8 10. b4 a5 11. b5 Na7 12. a4 b6 13. g4 Ne4 14. Nxe4 dxe4 15. Bxe4 Nxb5 16. Bxa8 Nc3 17. Qd3 Nd5 18. Qb5+ Bd7 19. Qb3 O-O 20. Bxd5 exd5 21. Qxd5 Be6 22. Qxd8 Rxd8 23. Rd1 Bc4 24. Ne5 Ba6 25. d5 Bxe5 26. f4 Bb8 27. d6 Rxd6 28. Rxd6 Bxd6 29. Bxb6 Kh7 30. Bxa5 Bb7 31. Bd2 Bxh1 32. c4 Bc6 33. a5 1-0",
    B: "1688",
  },
  {
    W: "1207",
    T: "60+0",
    P: "1. e4 e5 2. Nc3 Nc6 3. d3 Nf6 4. Nf3 Be7 5. Be2 O-O 6. O-O Re8 7. Bg5 d5 8. Bxf6 Bxf6 9. exd5 Be7 10. d6 Nb8 11. dxe7 Rxe7 12. Qe1 c6 13. d4 exd4 14. Nxd4 c5 15. Bc4 Qxd4 16. Qxe7 g6 17. Qe8+ Kg7 18. Qxc8 Qxc4 19. Rae1 a5 20. Qxb7 Ra6 21. Qxb8 a4 22. Qb5 Qxb5 23. Nxb5 Rb6 24. Nd6 Rxd6 25. Re7 Rd2 26. Rfe1 Rxc2 27. b4 Rxa2 28. b5 a3 29. b6 Rb2 30. b7 a2 31. b8=Q Rxb8 32. Ra1 Rb1+ 33. Rxb1 axb1=Q+ 34. Re1 Qxe1# 0-1",
    B: "1370",
  },
  {
    W: "2327",
    T: "60+0",
    P: "1. d4 Nf6 2. Bg5 c5 3. Bxf6 gxf6 4. d5 e6 5. c4 b5 6. e4 bxc4 7. Bxc4 Be7 8. dxe6 dxe6 9. Qh5 Nc6 10. Nc3 Bb7 11. Rd1 Nd4 12. Nge2 Nc2+ 13. Kf1 Qb6 14. Bb5+ Bc6 15. Bc4 Nd4 16. Nxd4 cxd4 17. Bxe6 Rf8 18. Nd5 Qb5+ 19. Kg1 Bxd5 20. Bxd5 Rc8 21. Qh3 Qc5 22. g3 Qc2 23. Kg2 f5 24. Qxf5 f6 25. Rc1 Qxc1 26. Rxc1 Rxc1 27. Qe6 Kd8 28. Bc6 Kc7 29. Qd7+ Kb6 30. Qb7+ 1-0",
    B: "2308",
  },
  {
    W: "1868",
    T: "60+0",
    P: "1. d4 f6 2. e4 d6 3. Nf3 e5 4. Bc4 Qe7 5. Nc3 Be6 6. Bxe6 Qxe6 7. Qd3 Ne7 8. Qb5+ c6 9. Qxb7 Qc8 10. Qxa8 Nd7 11. Qxa7 c5 12. d5 g5 13. Nb5 Kf7 14. Nxd6+ Kg6 15. Nxc8 Nxc8 16. Qxd7 Ne7 17. Be3 Rg8 18. Bxc5 Rg7 19. Qe6 Ng8 20. Nxe5+ Kh6 21. Qh3# 1-0",
    B: "1870",
  },
  {
    W: "1700",
    T: "60+0",
    P: "1. Nf3 c5 2. Nc3 Nc6 3. d4 e6 4. d5 exd5 5. Qxd5 Nd4 6. Nxd4 cxd4 7. Qxd4 Ne7 8. Ne4 Nc6 9. Qe3 Bb4+ 10. c3 Be7 11. Nd6+ Kf8 12. Nf5 Bf6 13. h4 g6 14. Nh6 Bg7 15. Ng4 f5 16. Nh2 d5 17. h5 Ne5 18. h6 Bf6 19. f4 Nc4 20. Qc5+ Nd6 21. e4 fxe4 22. Qxd5 Qe7 23. Qc5 Be6 24. g3 Rc8 25. Qe3 Kf7 26. g4 Bd5 27. g5 Bxc3+ 28. bxc3 Nc4 29. Qxa7 Rhd8 30. Bxc4 Rxc4 31. Qe3 Rdc8 32. Bd2 R4c6 33. f5 gxf5 34. g6+ Rxg6 35. Nf1 Qa3 0-1",
    B: "1828",
  },
  {
    W: "1384",
    T: "60+0",
    P: "1. g3 e5 2. Bg2 d5 3. d3 c5 4. Nf3 Nc6 5. Bg5 f6 6. Bd2 c4 7. dxc4 dxc4 8. Nc3 Bb4 9. O-O Bxc3 10. Bxc3 Qxd1 11. Raxd1 Nge7 12. Nh4 Bg4 13. Rfe1 g5 14. Nf3 Bxf3 15. Bxf3 O-O 16. Rd7 Rab8 17. Red1 Rfd8 18. Rxd8+ Rxd8 19. Rxd8+ Nxd8 20. e4 b5 21. Kf1 a6 22. Ke2 a5 23. b3 b4 24. Bb2 c3 25. Bc1 Ndc6 26. Be3 Nd4+ 27. Bxd4 exd4 28. Kd3 Nc6 0-1",
    B: "1495",
  },
  {
    W: "1938",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. d3 d6 4. Be2 Be7 5. h3 O-O 6. O-O h6 7. Be3 Nc6 8. c3 a6 9. Nbd2 b5 10. a4 Bb7 11. axb5 axb5 12. Rxa8 Qxa8 13. Qb3 Qa4 14. Qxa4 bxa4 15. Ra1 Ra8 16. Nc4 Nd8 17. Bd1 Bc6 18. Na3 Nb7 19. Kf1 d5 20. exd5 Nxd5 21. Ke2 Nxe3 22. fxe3 Bxf3+ 23. gxf3 Bxa3 24. bxa3 Nc5 25. d4 exd4 26. exd4 Ne6 27. Rb1 Nf4+ 28. Ke3 Nxh3 29. Rb4 g5 30. Rxa4 Rb8 31. Rc4 Rc8 32. a4 Nf4 33. a5 Nd5+ 34. Kd3 Nf4+ 35. Ke3 Nd5+ 36. Kd3 Nf4+ 37. Ke3 1/2-1/2",
    B: "2105",
  },
  {
    W: "1051",
    T: "60+0",
    P: "1. d3 e6 2. e4 d6 3. f4 g6 4. Nf3 b6 5. Be2 Bg7 6. O-O Nc6 7. Nc3 Qd7 8. b3 Bb7 9. a4 Nf6 10. Bb2 O-O-O 11. d4 Kb8 12. d5 exd5 13. exd5 Rde8 14. dxc6 Qxc6 15. Nd4 Qd5 16. Nxd5 Nxd5 17. Bf3 Nxf4 18. Bxb7 Kxb7 19. Rxf4 Bxd4+ 20. Bxd4 Re4 21. Bxh8 Rxf4 22. Qd3 c5 23. Qe3 Rf6 24. Qe7+ Kc6 25. Bxf6 Kd5 26. Rd1+ Kc6 27. Qxd6+ Kb7 28. Qd7+ Ka6 29. Rd6 c4 30. bxc4 Ka5 31. Qxa7+ Kb4 32. Rxb6+ Kxc4 33. Qc7+ Kd5 34. Rd6+ Ke4 35. Qe7+ Kf5 36. Kf2 Kf4 37. g3+ Kf5 38. Kf3 g5 39. g4+ Kg6 40. Rd7 h6 41. Qxf7# 1-0",
    B: "1041",
  },
  {
    W: "1726",
    T: "180+2",
    P: "1. e4 d6 2. f4 e5 3. Nf3 f6 4. fxe5 dxe5 5. Bc4 c6 6. O-O Be7 7. Nxe5 fxe5 8. Bf7+ Kd7 9. Qg4+ 1-0",
    B: "1662",
  },
  {
    W: "1588",
    T: "600+0",
    P: "1. Nf3 d5 2. e3 Nf6 3. d4 a6 4. Bd3 Bg4 5. O-O Nc6 6. c3 e5 7. dxe5 Nxe5 8. Be2 Nxf3+ 9. Bxf3 Bxf3 10. Qxf3 Bd6 11. g3 Qd7 12. Qg2 O-O-O 13. Nd2 Kb8 14. a4 h5 15. f3 g5 16. b4 h4 17. g4 h3 18. Qf2 Nxg4 19. fxg4 Qxg4+ 20. Kh1 Qe6 21. Nf3 g4 22. Nd4 Qe4+ 23. Kg1 g3 24. Qf3 gxh2+ 25. Kh1 Qxf3+ 26. Rxf3 Rdg8 27. Ba3 a5 28. Nf5 Be5 29. Bb2 axb4 30. Nd4 bxc3 31. Bxc3 Rg2 32. Nc6+ bxc6 33. Bxe5 Re2 34. Bxh2 Rg8 35. Rxh3 Kc8 36. a5 Rgg2 37. a6 Ra2 38. Rh8+ Kd7 39. Rxa2 Rxa2 40. Ra8 f5 41. Ra7 Ke6 42. Ra8 Ra3 43. Bf4 c5 44. a7 Kd7 45. Kg2 c4 46. Kf3 c3 47. Rb8 Rxa7 48. Rb1 c2 49. Rc1 Ra2 50. Be5 c5 51. Kf4 Ke6 52. Bc3 Kd6 53. Kxf5 Kc6 54. Ke5 d4 55. exd4 cxd4 56. Bxd4 Ra5+ 57. Ke4 Ra2 58. Kd3 Kb5 59. Kd2 Kc4 60. Bf2 Kb3 61. Bd4 1-0",
    B: "1591",
  },
  {
    W: "2111",
    T: "600+0",
    P: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 c5 5. Nf3 O-O 6. Bd3 d5 7. O-O cxd4 8. exd4 dxc4 9. Bxc4 Bxc3 10. bxc3 Qc7 11. Qe2 Nbd7 12. Ba3 Re8 13. Rac1 b6 14. Rfe1 Bb7 15. Bd3 Nd5 16. c4 Nf4 17. Qd2 Bxf3 18. gxf3 Nf6 19. Be4 Rac8 20. d5 Qe5 21. Bxh7+ Kxh7 22. Rxe5 Nh3+ 23. Kg2 Nd7 24. Rh5+ Kg8 25. Rxh3 exd5 26. Qd3 Nf6 27. cxd5 Rcd8 28. d6 Nd7 29. Qh7+ 1-0",
    B: "2022",
  },
  {
    W: "1168",
    T: "600+0",
    P: "1. d4 d5 2. Nc3 e6 3. f4 f5 4. Nf3 c6 5. e3 Nf6 6. Nd2 Ng4 7. h3 Nxe3 8. Qe2 Nxf1 9. Rxf1 Bd6 10. Nf3 h5 11. h4 Na6 12. Bd2 Qe7 13. a3 e5 14. fxe5 Bc7 15. O-O-O g5 16. hxg5 Rg8 17. Rh1 c5 18. Be3 Bb6 19. Rxh5 cxd4 20. Bxd4 Qf8 21. Bxb6 axb6 22. Nxd5 Qc5 23. Nf4 Be6 24. Nxe6 Rc8 25. Nxc5 Rxc5 26. Rh6 Rc6 27. Rxc6 bxc6 28. Qxa6 Rg7 29. Qxb6 Re7 30. Qb7 Rxb7 31. Rh1 Rb5 32. Rh8+ 1-0",
    B: "1284",
  },
  {
    W: "1218",
    T: "600+0",
    P: "1. d4 c6 2. Bf4 d5 3. c3 Bf5 4. Nf3 e6 5. e3 Nd7 6. Ne5 Nb6 7. Nd2 Nc4 8. Ndf3 Nf6 9. Bxc4 dxc4 10. O-O Bd6 11. Qa4 Ng4 12. Qxc4 Nxe5 13. Nxe5 Bxe5 14. Bxe5 O-O 15. Rfe1 Re8 16. Qe2 Qg5 17. g3 Bh3 18. Qf3 f5 19. Qf4 Qg6 20. Qg4 Qxg4 0-1",
    B: "1279",
  },
  {
    W: "1630",
    T: "600+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. d4 d6 4. Be3 Bg4 5. Be2 Bxf3 6. Bxf3 c6 7. Nc3 Nd7 8. O-O a6 9. Qd2 Ngf6 10. Bh6 O-O 11. Bxg7 Kxg7 12. Rfe1 e5 13. d5 c5 14. Rad1 b5 15. a3 Rb8 16. Na2 a5 17. b3 Nb6 18. h4 c4 19. Qxa5 cxb3 20. cxb3 Ra8 21. Qxb5 Rxa3 22. Ra1 Qc7 23. Nb4 Rxa1 24. Rxa1 Rb8 25. Nc6 Rb7 26. Qd3 Nbd7 27. b4 h6 28. Rb1 h5 29. Qe2 Kh6 30. g4 hxg4 31. Bxg4 Nxg4 32. Qxg4 Nf6 33. Qg5+ Kg7 34. Qe3 Qd7 35. Qh3 0-1",
    B: "1670",
  },
  {
    W: "1413",
    T: "600+0",
    P: "1. e4 e5 2. f4 Nc6 3. Nc3 Qh4+ 4. g3 Qf6 5. Nf3 exf4 6. d4 fxg3 7. hxg3 Bb4 8. Bg5 Qg6 9. Qd3 d6 10. O-O-O Bg4 11. Bd2 O-O-O 12. Nh4 Qf6 13. Nd5 Bxd2+ 14. Rxd2 Qe6 15. c4 Nf6 16. Nf4 Qxe4 17. Qxe4 Nxe4 18. Rd3 Nf2 19. Bg2 Nxd3+ 20. Nxd3 Nxd4 21. Bd5 Ne2+ 22. Kd2 Nxg3 23. Rg1 Rde8 24. Rxg3 Re2+ 25. Kc3 Be6 26. Rxg7 Bxd5 27. cxd5 Rh2 28. Nf5 Rh5 29. Nd4 Rxd5 30. Rxf7 h5 31. Ne6 h4 32. Rf8+ Rxf8 33. Nxf8 h3 34. Nf2 h2 35. Ne6 Rf5 36. Nh1 Rf1 37. Ng3 Rf3+ 0-1",
    B: "1416",
  },
  {
    W: "1112",
    T: "600+0",
    P: "1. d4 d5 2. e3 Nc6 3. Nf3 Bf5 4. Bd3 e6 5. Bxf5 exf5 6. Nc3 Nf6 7. O-O Ne4 8. Re1 Bb4 9. a3 Ba5 10. b4 Bb6 11. Rb1 Nxc3 12. Qd3 Nxb1 13. Qxf5 O-O 14. Bb2 Ne7 15. Qf4 Ng6 16. Qf5 Ne7 17. Qe5 f6 18. Qe6+ Kh8 19. Rxb1 Ng6 20. e4 dxe4 21. Qxe4 Re8 22. Qxb7 Rb8 23. Qc6 Nf4 24. Kf1 Qd6 25. Qc3 Nd5 26. Qc4 c6 27. h4 Qf4 28. Qxc6 Ne7 29. Qe6 Nf5 30. Qc6 Bxd4 31. Nxd4 Nxd4 32. Qd5 Ne2 33. g3 Qg4 34. Qd7 Qxd7 0-1",
    B: "1038",
  },
  {
    W: "1744",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. c3 Nf6 4. d3 Be7 5. Nbd2 h6 6. Be2 O-O 7. Nf1 Nh7 8. Ng3 d6 9. d4 Kh8 10. d5 Nb8 11. h3 f5 12. exf5 Bxf5 13. Nxf5 Rxf5 14. O-O e4 15. Nh2 Bg5 16. Bg4 Rf8 17. f4 Bh4 18. Qd4 Nf6 19. Be6 Nbd7 20. Ng4 Nxg4 21. hxg4 Bg3 22. f5 Be5 23. Qe3 Qh4 24. Qh3 Qxh3 25. gxh3 Nc5 26. b4 Nd3 27. Bd2 Nb2 28. Rae1 Nc4 29. Bc1 e3 30. Bxe3 Bxc3 31. Rc1 Nxe3 32. Rxc3 Nxf1 33. Kxf1 g6 34. Rf3 gxf5 35. gxf5 Rf6 36. Kg2 Re8 37. Kg3 Re7 38. Kh4 Rg7 39. Rc3 Kh7 40. a4 h5 41. b5 Kh6 42. a5 Rg1 43. b6 c6 44. dxc6 bxc6 45. Rxc6 axb6 46. axb6 Rb1 47. Rxd6 Rb4+ 48. Kg3 h4+ 49. Kf3 Kg5 50. Rd8 Rxb6 51. Rg8+ Kh5 52. Rh8+ Rh6 53. Bf7+ Kg5 54. Rxh6 Rxh6 55. Bg6 Rh8 56. Ke4 Rf8 57. Ke5 Rf6 58. Bh7 Rb6 59. Bg6 Rb5+ 60. Ke4 Kf6 61. Kf4 Rb4+ 62. Kf3 Kg5 63. Kg2 Rd4 64. Kf3 Rf4+ 65. Kg2 Kf6 66. Kh2 Rf3 0-1",
    B: "1814",
  },
  {
    W: "1364",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Nf6 4. d4 Nxe4 5. dxe5 Be6 6. Bxe6 fxe6 7. Nc3 Nxc3 8. bxc3 Nc6 9. Bg5 Be7 10. Bf4 dxe5 11. Nxe5 Nxe5 12. Bxe5 O-O 13. O-O Bf6 14. Bxf6 Qxf6 15. Qd4 e5 16. Qd7 Rf7 17. Qb5 b6 18. Rfe1 Qxf2+ 19. Kh1 a6 20. Qc6 Raf8 21. Qxb6 cxb6 0-1",
    B: "1334",
  },
  {
    W: "1643",
    T: "600+0",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. Nf3 cxd4 5. Nxd4 Nc6 6. Nc3 Nxd4 7. Qxd4 Qc7 8. Bf4 Bd7 9. Bd3 Bc5 10. Nb5 Bxb5 11. Bxb5+ Ke7 12. Qc3 Rc8 13. Qg3 g6 14. Bg5+ Kf8 15. O-O Be7 16. c3 Bxg5 17. Qxg5 Qb6 18. Bd7 Rc7 19. Qd8+ Kg7 20. Rab1 Nh6 21. Qf6+ Kf8 22. Qxh8+ Ke7 23. Qe8# 1-0",
    B: "1651",
  },
  {
    W: "1522",
    T: "1800+0",
    P: "1. e4 Nc6 2. d4 b6 3. a3 Bb7 4. d5 Ne5 5. f4 Ng6 6. Nf3 e6 7. Nc3 exd5 8. exd5 Qe7+ 9. Be2 c6 10. dxc6 Bxc6 11. O-O Qd6 12. Qxd6 Bxd6 13. Re1 N8e7 14. g3 O-O 15. Bc4 0-1",
    B: "1528",
  },
  {
    W: "1762",
    T: "180+2",
    P: "1. e4 e5 2. Bc4 c6 3. Nf3 d5 4. exd5 cxd5 5. Bb3 Nf6 6. d3 Nc6 7. Bg5 Be7 8. O-O h6 9. Bh4 Bg4 10. h3 Bxf3 11. Qxf3 Nd4 12. Qd1 Nxb3 13. axb3 O-O 14. Re1 Qd6 15. Nc3 Rfe8 16. Qe2 d4 17. Ne4 Nxe4 18. Qxe4 Bxh4 19. Qxh4 Rac8 20. Rxa7 b5 21. Re2 Re6 22. Qg3 Rg6 23. Qf3 Rc7 24. Rxc7 Qxc7 25. Qe4 f6 26. f4 Kf7 27. fxe5 fxe5 28. Qxe5 Qb7 29. Qe8+ Kf6 30. Rf2+ Kg5 31. h4+ Kxh4 32. Qxg6 h5 33. Rf4# 1-0",
    B: "1718",
  },
  {
    W: "1710",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. Nxe5 Qd4 6. Nxf7 Kxf7 7. O-O Nf6 8. d3 Bd6 9. Kh1 Re8 10. f4 Kg8 11. e5 Bxe5 12. fxe5 Rxe5 13. Nc3 Bg4 14. Bd2 Bxd1 15. Raxd1 b5 16. Bf4 Rf5 17. Bxc7 Rxf1+ 18. Rxf1 Re8 19. Bg3 b4 20. Na4 Re2 21. c3 bxc3 22. bxc3 Qd5 23. Nb6 Qxg2# 0-1",
    B: "1898",
  },
  {
    W: "1622",
    T: "15+0",
    P: "1. e4 c6 2. Nc3 d6 3. d4 g6 4. Nf3 Bg4 5. Be3 Bxf3 6. Qxf3 Qb6 7. Rb1 Bg7 8. e5 d5 9. e6 fxe6 10. Bg5 Nd7 11. Qh3 Nf8 12. Qh4 Nf6 13. Bxf6 Bxf6 14. Bd3 Qxd4 15. Qxd4 Bxd4 16. O-O Bxc3 17. bxc3 O-O-O 18. c4 e5 19. cxd5 cxd5 20. Be2 e4 21. Bg4+ e6 22. Be2 Kb8 23. c4 b6 0-1",
    B: "1685",
  },
  {
    W: "1644",
    T: "120+1",
    P: "1. c4 c5 2. Nc3 g6 3. g3 Bg7 4. d3 Nf6 5. Bd2 O-O 6. Bg2 Nc6 7. Nf3 d6 8. O-O a6 9. Rb1 b5 10. cxb5 axb5 11. Nxb5 Rxa2 12. Nc3 Ra7 13. e4 Nd4 14. Nxd4 cxd4 15. Nd5 Nxd5 16. exd5 Bf5 17. Bf4 Qb6 18. g4 Bd7 19. Bg3 Rb8 20. Qc2 Ra2 21. f4 Rxb2 22. Rxb2 Qxb2 23. Qxb2 Rxb2 24. Bf2 Bb5 25. Be4 Rd2 26. Rb1 Bxd3 27. Bxd3 Rxd3 28. Rb8+ Bf8 29. Kf1 Rf3 30. Ke2 Rxf4 31. Kd3 Rxf2 32. Kxd4 Rxh2 33. Ke4 Re2+ 34. Kf3 Re5 35. Kf4 Rxd5 36. g5 Rd4+ 37. Ke3 Rg4 38. Kf3 Rxg5 39. Kf4 Rf5+ 40. Kg4 e5 41. Re8 d5 42. Rd8 e4 43. Kg3 Kg7 44. Kg4 Be7 45. Re8 Bf6 46. Kg3 Re5 47. Rc8 e3 48. Rc1 d4 49. Kf3 d3 50. Re1 d2 51. Rg1 e2 52. Kf4 e1=Q 53. Rxg6+ hxg6 54. Kg4 d1=Q+ 55. Kh3 Qh5+ 56. Kg2 Qg4+ 57. Kh2 Qeg1# 0-1",
    B: "1773",
  },
  {
    W: "1429",
    T: "120+1",
    P: "1. a4 e5 2. d3 Nc6 3. Bd2 d5 4. e3 Be6 5. Be2 Bd6 6. c3 Nf6 7. b4 O-O 8. h4 a6 9. h5 h6 10. g4 Qc8 11. f3 e4 12. fxe4 dxe4 13. dxe4 Bxg4 14. Bxg4 Nxg4 15. Qf3 Nf6 16. Ne2 Re8 17. Rg1 Nxe4 18. Bc1 Ng5 19. Qg2 b5 20. e4 Rxe4 21. Bxg5 hxg5 22. Qxg5 g6 23. hxg6 fxg6 24. Qxg6+ Kf8 25. Qxe4 Ne5 26. Rf1+ Ke7 27. Nd4 Qh8 28. Nc6+ Kd7 29. Nxe5+ Bxe5 30. Qd5+ Bd6 31. Rf7+ Kc8 32. Qe6+ Kb7 33. Qd5+ Kc8 34. Qxa8# 1-0",
    B: "1316",
  },
  {
    W: "1296",
    T: "120+1",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qd3 Bc5 5. Nf3 Nge7 6. Be3 Bb6 7. Bxb6 axb6 8. Nc3 O-O 9. Nd5 d6 10. Nxe7+ Qxe7 11. Be2 Re8 12. Nd2 d5 13. exd5 Na5 14. O-O-O Qxe2 15. Qxe2 Rxe2 16. Rhe1 Rxe1 17. Rxe1 Bf5 18. b4 Nc6 19. dxc6 bxc6 20. Re7 Ra7 21. Re8# 1-0",
    B: "1203",
  },
  {
    W: "1825",
    T: "120+1",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. c3 dxc3 5. Nxc3 Nc6 6. Bc4 g6 7. e5 dxe5 8. Qxd8+ Nxd8 9. Nb5 e4 10. Ne5 Bg7 11. Nc7+ Kf8 12. Bf4 g5 13. Bg3 Rb8 14. Nxf7 Nxf7 15. Ne6+ Bxe6 16. Bxe6 Rd8 17. O-O h5 18. Bc7 Rd2 19. Rab1 b6 20. b3 Nf6 21. Rfd1 Rxd1+ 22. Rxd1 1-0",
    B: "1817",
  },
  {
    W: "1604",
    T: "120+1",
    P: "1. d4 e5 2. dxe5 f6 3. Nc3 fxe5 4. Be3 d5 5. Qxd5 Bd7 6. Qxb7 Nc6 7. Nd5 Rb8 8. Qxc7 Qxc7 9. Nxc7+ Kd8 10. Na6 Rxb2 11. Rd1 Rxa2 12. Nc5 Bxc5 13. Bxc5 Rxc2 14. Bd6 Nf6 15. f3 e4 16. e3 exf3 17. Nxf3 Re8 18. Bf4 Nh5 19. Bg5+ Kc8 20. Be2 h6 21. Bh4 Rxe3 22. Rd2 Rc1+ 23. Rd1 Rc2 24. Rd2 Rcc3 25. Bf2 Re6 26. O-O Nf4 27. Bd1 Rg6 28. Bg3 Nd3 29. Nh4 Rg4 30. Rf8+ Kb7 31. Bxg4 Rc1+ 0-1",
    B: "1492",
  },
  {
    W: "1143",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Qxd4 Nc6 5. Qd1 Bg4 6. Be2 Qe7 7. h3 Bxf3 8. Bxf3 Nd4 9. Qxd4 Nf6 10. Bg5 h6 11. Bxf6 Qxf6 12. Qxf6 gxf6 13. O-O O-O-O 14. Nc3 Bg7 15. Nd5 Rhe8 16. c3 c6 17. Nb4 d5 18. exd5 cxd5 19. Nxd5 Re5 20. Rad1 Rexd5 21. Rxd5 Rxd5 22. Bxd5 f5 23. Re1 Kc7 24. Bxf7 Bf6 25. Bg6 Kd6 26. Bxf5 1-0",
    B: "1129",
  },
  {
    W: "1094",
    T: "120+1",
    P: "1. e4 e6 2. d4 Nf6 3. f3 g6 4. e5 Nd5 5. c4 Nb6 6. c5 Nd5 7. Bc4 d6 8. Bxd5 exd5 9. Qa4+ c6 10. cxd6 Qh4+ 11. g3 Qh5 12. g4 Qh4+ 13. Kd2 Bh6+ 14. Kd3 Qe1 15. Bxh6 f5 16. g5 f4 17. b4 Bf5# 0-1",
    B: "988",
  },
  {
    W: "1417",
    T: "180+0",
    P: "1. d4 g6 2. Bf4 Bg7 3. e3 d6 4. Bd3 Nd7 5. c3 Ngf6 6. Nd2 O-O 7. Qc2 b6 8. O-O-O Bb7 9. Ngf3 a5 10. h4 Bc6 11. h5 b5 12. hxg6 fxg6 13. Ng5 Bd5 14. Nb3 a4 15. Nd2 a3 16. b3 h6 17. Nge4 Rc8 18. Bxh6 Bxh6 19. Rxh6 Kg7 20. Rdh1 Rh8 21. Rxh8 Qxh8 22. Rxh8 Rxh8 23. Nf3 Rh1+ 24. Kd2 c5 25. Nxf6 Nxf6 26. e4 Bb7 27. e5 dxe5 28. dxe5 Ng4 29. Bxb5 Nxf2 30. Be8 Be4 31. Qc1 Rxc1 32. Kxc1 Nd3+ 33. Kd2 Nf4 34. Nh4 Bxg2 35. Nxg2 Nxg2 36. Kd3 Ne1+ 37. Kc4 g5 38. Kxc5 g4 39. Kc6 g3 40. Kb5 g2 41. Bc6 g1=Q 42. Ka4 Qa7+ 43. Kb4 Qb6+ 44. Kxa3 Qxc6 45. Kb2 Nd3+ 46. Kc2 Nxe5 0-1",
    B: "1405",
  },
  {
    W: "1522",
    T: "180+0",
    P: "1. e4 e6 2. d4 d6 3. Nf3 Nc6 4. Bb5 Bd7 5. c3 Qe7 6. Bg5 f6 7. Bh4 h5 8. e5 g5 9. exd6 cxd6 10. Bxc6 Bxc6 11. Bg3 h4 12. Bxd6 Qxd6 13. Nbd2 O-O-O 14. Qe2 Bg7 15. O-O-O Ne7 16. Rde1 Nd5 17. Qxe6+ Qxe6 18. Rxe6 Rhe8 19. Rhe1 Rxe6 20. Rxe6 Re8 21. Rxe8+ Bxe8 22. h3 Kc7 23. Nh2 Bf8 24. Ng4 Be7 25. Ne3 Nxe3 26. fxe3 Bc6 27. e4 Kd6 28. c4 b6 29. b4 a5 30. b5 Bd7 31. Nf3 Bd8 32. a4 Kc7 33. Ne5 fxe5 0-1",
    B: "1506",
  },
  {
    W: "2109",
    T: "180+0",
    P: "1. d4 e6 2. c4 d5 3. Nc3 Nf6 4. e3 Be7 5. Nf3 b6 6. Bd3 Bb7 7. O-O Nbd7 8. Qc2 O-O 9. b3 Re8 10. e4 Nf8 11. exd5 exd5 12. Ne5 Ng6 13. Nxg6 hxg6 14. cxd5 Nxd5 15. Bb2 Nb4 16. Qb1 Nxd3 17. Qxd3 Bf6 18. Rfd1 a5 19. Rac1 Ba6 20. Qg3 Bh4 21. Qf3 Rb8 22. Re1 Rf8 23. Nd5 Bb7 24. Re5 Bf6 25. Nxf6+ gxf6 26. d5 fxe5 27. Bxe5 Qxd5 28. Qg3 Rbe8 29. Ba1 c5 30. Qh3 f6 31. Qg3 Qg5 0-1",
    B: "2082",
  },
  {
    W: "2047",
    T: "180+0",
    P: "1. e4 c5 2. Bc4 Nc6 3. Qf3 g6 4. Qxf7# 1-0",
    B: "2228",
  },
  {
    W: "2597",
    T: "180+0",
    P: "1. c4 c5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 cxd4 5. Nxd4 d5 6. cxd5 Nxd5 7. Nxc6 bxc6 8. Bd2 e5 9. e4 Nf6 10. Bc4 Bc5 11. O-O O-O 12. Qc2 Bd4 13. Bg5 h6 14. Bh4 g5 15. Bg3 Qe7 16. Ne2 c5 17. Kh1 Bb7 18. f3 Nh5 19. Be1 Nf4 20. Ng3 Bc8 21. Nf5 Bxf5 22. exf5 Nh5 23. Bd2 Nf6 24. Rae1 Qd6 25. b3 Rad8 26. Bc1 Rfe8 27. g3 Kg7 28. Kg2 Qc6 29. h3 e4 30. Qe2 exf3+ 31. Qxf3 Qxf3+ 32. Kxf3 Nh5 33. h4 gxh4 34. g4 Nf6 35. Rxe8 Rxe8 36. Rh1 Ne4 37. Rxh4 Ng5+ 38. Kg2 Re2+ 39. Bxe2 Be5 40. Rh5 f6 41. Rh1 Ne4 42. Rd1 Nc3 43. Rd7+ Kf8 44. Bc4 Ke8 45. Rxa7 Ne2 46. Bxe2 Kd8 47. Bxh6 Kc8 48. Bc4 Bd4 49. a4 Kd8 50. a5 Kc8 51. a6 Kb8 52. Rb7+ Ka8 53. Bf4 Be3 54. Bg3 1-0",
    B: "2382",
  },
  {
    W: "1878",
    T: "180+0",
    P: "1. e4 d5 2. Nc3 dxe4 3. Nxe4 Bf5 4. Ng3 Bg6 5. Nf3 Nf6 6. d3 e6 7. Bf4 Bd6 8. Bxd6 cxd6 9. Be2 d5 10. O-O Nc6 11. Qd2 O-O 12. h4 h6 13. b4 Qb6 14. d4 Ne4 15. Nxe4 Bxe4 16. c3 Rac8 17. Nh2 Ne7 18. f3 Bf5 19. g4 Bg6 20. h5 Bh7 21. Rfc1 a5 22. a3 axb4 23. axb4 Nc6 24. b5 Na5 25. Qa2 Nc4 26. Bxc4 Rxc4 27. Qa5 Qc7 28. Qxc7 Rxc7 29. Ra3 Rc4 30. Nf1 Rfc8 31. Ne3 R4c7 32. b6 Rc6 33. Rb3 Bd3 34. Nd1 Be2 35. Ne3 Bxf3 36. Kf2 Be4 37. Kg3 f5 38. Kf4 fxg4 39. Nxg4 Bf5 40. Ne5 Rd6 41. Rb4 Kf8 42. c4 dxc4 43. Rbxc4 Rxc4 44. Rxc4 Rxb6 45. Rc8+ Ke7 46. Rc7+ Kd6 47. Rc5 Rb1 48. Nf7+ Ke7 49. Rc7+ Kf6 50. Ke3 b5 51. Nd6 b4 52. Rf7+ Kg5 53. Rxg7+ Kxh5 54. Rb7 b3 55. Nc4 Kg5 56. Kd2 h5 57. Ne5 h4 58. Nf3+ Kg4 59. Ne5+ Kg3 60. Nc4 h3 61. Rb8 h2 62. Rh8 Kg2 63. Ne3+ Kg1 64. Rg8+ Kf2 65. Rg2+ Kf3 66. Rxh2 Rb2+ 67. Kc3 Rxh2 68. Nxf5 b2 69. Kb3 b1=Q+ 70. Kc4 exf5 71. Kd5 Kf4 72. Ke6 Qd3 73. d5 Rd2 74. Kf6 Qxd5 75. Kg6 Ke5 76. Kg5 f4 77. Kg4 f3 78. Kg5 f2 79. Kg4 f1=Q 80. Kg5 Rg2+ 81. Kh6 Qh1# 0-1",
    B: "2003",
  },
  {
    W: "1859",
    T: "180+0",
    P: "1. d4 d5 2. Bf4 e6 3. f3 Nf6 4. Nc3 Nbd7 5. e3 a6 6. Bd3 c5 7. dxc5 Nxc5 8. Nge2 Bd6 9. Bxd6 Qxd6 10. O-O O-O 11. Nd4 b5 12. Qe2 Bb7 13. Rae1 Rac8 14. e4 Qb6 15. e5 Nfd7 16. f4 Ne4 17. Nxe4 Qxd4+ 18. Nf2 Qxb2 19. Nh3 g6 20. Qg4 Kg7 21. Qh4 Rh8 22. Rf3 Qxa2 23. Qe7 Bc6 24. Ng5 Rcf8 25. Rh3 Qa5 26. Rf1 Qd8 27. Qd6 Qc8 28. g4 Nb6 29. f5 exf5 30. gxf5 Qd7 31. Qf6+ 1-0",
    B: "1948",
  },
  {
    W: "1696",
    T: "180+0",
    P: "1. e4 c5 2. e5 d6 3. f4 f6 4. Nf3 Bg4 5. Be2 Nc6 6. h3 Bxf3 7. Bxf3 dxe5 8. fxe5 Nxe5 9. Bxb7 Rb8 10. Ba6 Qa5 11. Qe2 Nh6 12. c3 e6 13. O-O Bd6 14. d4 cxd4 15. cxd4 Nc6 16. Qxe6+ Ne7 17. Qxd6 Nhf5 18. Qa3 O-O 19. Qxa5 Ng6 20. Rxf5 Rxb2 21. Bxb2 1-0",
    B: "1523",
  },
  {
    W: "1461",
    T: "180+0",
    P: "1. e4 a5 2. Bc4 b6 3. Qf3 e6 4. e5 Nc6 5. Bb5 Bb7 6. Nc3 Qb8 7. Nge2 Nxe5 8. Qe3 Bxg2 9. Rg1 c6 10. Bd3 Bd5 11. Nxd5 cxd5 12. f4 Ng6 13. Bb5 Bc5 14. Qg3 Bxg1 15. Qxg1 Nf6 16. Qg3 O-O 17. d3 Nh5 18. Qg4 Nf6 19. Qg5 h6 20. Qg3 a4 21. Bd2 a3 22. b3 Qd6 23. O-O-O Rac8 24. Nd4 Qc5 25. Be3 Qxc2+ 26. Nxc2 Rxc2+ 27. Kxc2 Rc8+ 28. Kb1 Rc3 29. f5 exf5 30. Qb8+ Kh7 31. Qxb6 Ng4 32. Bd4 f4 33. Bxc3 f3 34. Qd4 Nxh2 35. Qxg7# 1-0",
    B: "1499",
  },
  {
    W: "1181",
    T: "180+0",
    P: "1. e4 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qd1 Nf6 5. Nc3 e5 6. Bd3 Bb4 7. Bd2 O-O 8. Nf3 d6 9. Qe2 a6 10. h3 b5 11. Bg5 Bc5 12. O-O b4 13. Nd5 h6 14. Nxf6+ gxf6 15. Bxh6 Re8 16. Nh4 Ne7 17. Nf5 Ng6 18. Qg4 d5 19. Rad1 Bf8 20. exd5 Bxh6 21. Nxh6+ Kf8 22. Nf5 Qxd5 23. Qh5 Bxf5 24. Bxf5 Qc5 25. Bxg6 fxg6 26. Qh8+ Ke7 27. Qh4 Rh8 28. Qe4 Qa5 29. Qb7+ Ke6 30. f4 exf4 31. Rfe1+ Kf5 32. Rd5+ Qxd5 33. Qxd5# 1-0",
    B: "1281",
  },
  {
    W: "1444",
    T: "180+0",
    P: "1. Nf3 d5 2. e4 dxe4 3. Ng5 Nf6 4. d3 exd3 5. Bxd3 h6 6. Nxf7 Kxf7 7. Bg6+ Kxg6 8. Qxd8 Nbd7 9. Qxc7 Nd5 10. Qg3+ Kf7 11. Nc3 Nxc3 12. Qxc3 Ke8 13. O-O a5 14. a4 b6 15. Bf4 Nc5 16. Rfe1 Ne6 17. Bg3 Nc5 18. Qc4 g5 19. h3 Bd7 20. Bd6 Kd8 21. Bxc5 bxc5 22. Qxc5 e6 23. Qe5 Rh7 24. c4 Re7 25. c5 Bg7 26. Qe2 Rc8 27. Rab1 Rxc5 28. b4 axb4 29. Rxb4 Ra5 30. Rb8+ Bc8 31. Qd3+ Rd7 32. Qd1 Rxd1 33. Rxd1+ Kc7 34. Rbb1 Rxa4 35. Rdc1+ Kd7 36. Rd1+ Ke7 37. Rb8 Bd7 38. Rb7 Be5 39. Rbxd7+ Kf6 40. Rh7 Kg6 41. Rhd7 Kf6 42. g4 Bf4 43. f3 Ke5 44. Re1+ Kf6 45. Rd3 e5 46. Kg2 Ra2+ 47. Kf1 Rh2 48. Rd6+ Kg7 49. Red1 Rxh3 50. Rd7+ Kg6 51. R1d6# 1-0",
    B: "1420",
  },
  {
    W: "1256",
    T: "180+2",
    P: "1. d4 d5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bf4 e6 5. e3 Bb4 6. Bd3 O-O 7. O-O Re8 8. Ne5 Nxe5 9. Bxe5 Bd7 10. Qf3 c5 11. Qh3 Qa5 12. Bxf6 gxf6 13. Qxh7+ Kf8 14. Qh8+ Ke7 15. Qh4 Bxc3 16. bxc3 Qxc3 17. dxc5 Qxc5 18. g3 Rh8 19. Qf4 Rac8 20. Rab1 b6 21. h4 Ba4 22. Qxa4 1-0",
    B: "1326",
  },
  {
    W: "1997",
    T: "180+2",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Nf3 c5 5. e3 cxd4 6. exd4 Nbd7 7. Bd3 Be7 8. O-O O-O 9. Bg5 b6 10. Bxf6 Bxf6 11. cxd5 exd5 12. Nxd5 Bb7 13. Nxf6+ Nxf6 14. Be2 Qd7 15. a4 Rad8 16. a5 Qg4 17. h3 Qg6 18. axb6 axb6 19. Ra7 Bc6 20. Rc7 Nh5 21. Nh4 Qd6 22. Rxc6 Qxc6 23. Bxh5 Qh6 24. Nf5 Qc6 25. Bf3 1-0",
    B: "1932",
  },
  {
    W: "1039",
    T: "180+2",
    P: "1. h4 d5 2. Nf3 e6 3. a4 Nc6 4. Nc3 a6 5. a5 b5 6. e4 Bb4 7. exd5 exd5 8. Nxd5 Qxd5 9. Qe2+ Nge7 10. g4 O-O 11. Bg2 Qd7 12. O-O Nxa5 13. c3 Nb3 14. Rb1 Nxc1 15. Rbxc1 Bd6 16. c4 bxc4 17. Rxc4 Qb5 18. Ng5 Rb8 19. d3 h6 20. Ne4 Bf4 21. Nf6+ Kh8 22. Rxf4 Rb6 23. Qxe7 Rg8 24. Nxg8 Kxg8 25. Qxf7+ Kh8 26. Qf8+ Kh7 27. g5 Rb8 28. gxh6 Be6 29. Qxg7# 1-0",
    B: "1118",
  },
  {
    W: "1736",
    T: "180+2",
    P: "1. c3 e5 2. d4 Nc6 3. Nf3 d6 4. Bd2 e4 5. Ng1 d5 6. Bf4 f5 7. e3 Nf6 8. Ne2 h6 9. h4 Bd6 10. Bxd6 Qxd6 11. Nf4 Ne7 12. Na3 Bd7 13. Nc2 O-O-O 14. Qd2 g5 15. hxg5 hxg5 16. Rxh8 Rxh8 17. Nh3 g4 18. Nf4 Rh1 19. g3 Bb5 20. O-O-O Rxf1 21. Rxf1 Bxf1 22. Nb4 Bc4 23. b3 Bb5 24. Kb2 a5 25. Nc2 a4 26. b4 Bc4 27. Na3 Nc6 28. Nxc4 dxc4 29. d5 b5 30. Ka3 Ne7 31. Qd4 Nexd5 32. Nxd5 Qxd5 33. Qxf6 Kb7 34. Qd4 Qg8 35. Qe5 Qh7 36. Qxb5+ Kc8 37. Qxc4 Qh2 38. Qe6+ Kb7 39. Qxf5 Qg1 40. Qd5+ Kc8 41. Qf5+ Kb7 42. Qxe4+ c6 43. Qe7+ Kb6 44. Qc5+ Kc7 45. e4 Qc1+ 46. Kxa4 Qc2+ 47. Ka3 Qc1+ 48. Kb3 Qb1+ 49. Kc4 Qxa2+ 50. Kd3 Qb1+ 51. Kc4 Qa2+ 52. Kd4 Qd2+ 53. Ke5 Qg5+ 54. Kd4 Qd2+ 55. Kc4 Qe2+ 56. Kb3 Qd1+ 57. Kb2 Qd2+ 58. Kb1 Qd1+ 59. Ka2 Qc2+ 60. Ka3 Qc1+ 61. Kb3 Qd1+ 62. Kc4 Qe2+ 63. Kd4 Qxf2+ 64. Ke5 Qxg3+ 65. Kf5 Qf3+ 66. Kg6 g3 67. Qe5+ Kb6 68. Qc5+ Kc7 69. Qe7+ Kb6 70. Qg5 g2 71. e5 Qd3+ 72. Kg7 Qd7+ 73. Kf8 Qh3 74. e6 Qf3+ 75. Ke8 Qe2 76. e7 Qf1 77. Qc5+ Kc7 78. Qe5+ Kb6 79. Qe3+ Kc7 80. Qg3+ Kb6 81. Kd8 g1=Q 82. Qc7+ Kb5 83. e8=Q Qd1+ 84. Kc8 Qgg4+ 85. Kb8 Qd5 86. Qb7+ Kc4 87. Qexc6+ Qxc6 88. Qxc6+ Kb3 89. Qd5+ Kc2 90. c4 Qg3+ 91. Kb7 Qg7+ 92. Kb6 Qf6+ 93. Kb5 Qc3 94. c5 1-0",
    B: "1745",
  },
  {
    W: "2104",
    T: "60+0",
    P: "1. d4 Nf6 2. c4 g6 3. f3 Bg7 4. e4 O-O 5. Nc3 c6 6. Be3 d5 7. e5 Ne8 8. Qd2 dxc4 9. Bxc4 b5 10. Bb3 a5 11. a4 b4 12. Nce2 Ba6 13. Ng3 Nc7 14. N1e2 Nd5 15. Bf2 Nd7 16. Ne4 Qb6 17. Bxd5 cxd5 18. N4g3 Rac8 19. O-O e6 20. Rfc1 h5 21. Nf1 Kh7 22. Ne3 Bh6 23. g3 Nxe5 24. dxe5 d4 25. Ng2 Qb7 26. Qd1 Qxf3 27. Nxd4 Qd5 28. Nb5 Rxc1 29. Rxc1 Bxc1 30. Qxc1 Bxb5 31. axb5 a4 32. Qc6 Qxc6 33. bxc6 Rc8 0-1",
    B: "2809",
  },
  {
    W: "1743",
    T: "60+0",
    P: "1. d4 e6 2. Nc3 d5 3. e3 c5 4. dxc5 Bxc5 5. Bb5+ Bd7 6. Bxd7+ Qxd7 7. Nf3 Nc6 8. O-O a6 9. Re1 Nge7 10. b3 O-O 11. Bb2 Bb4 12. Re2 Nf5 13. Nd4 Ncxd4 14. exd4 b5 15. a3 Bd6 16. Qd3 Rac8 17. Rc1 Bc7 18. g4 Qd6 19. gxf5 Qxh2+ 20. Kf1 Qh1# 0-1",
    B: "1761",
  },
  {
    W: "2076",
    T: "120+0",
    P: "1. Nf3 f5 2. g3 Nf6 3. Bg2 d6 4. b3 e5 5. Bb2 Be7 6. d3 O-O 7. a3 h6 8. Nbd2 Be6 9. e3 c5 10. h3 Nc6 11. Nh2 g5 12. Qe2 b5 13. f4 Rc8 14. O-O-O a5 15. Bxc6 Rxc6 16. e4 a4 17. fxg5 hxg5 18. exf5 Bxf5 19. Ne4 Nxe4 20. dxe4 Bg6 21. Qxb5 Rb6 22. Qxa4 Rf2 23. Ng4 Re2 24. Qc4+ Kg7 25. Qxe2 Qa8 26. Rhf1 Bxe4 27. Nxe5 dxe5 28. Bxe5+ Kg6 29. Rd6+ Rxd6 30. Bxd6 Bxd6 31. Qf2 Qxa3+ 32. Kb1 Qxb3+ 33. Kc1 Qa3+ 34. Kd1 Qa1+ 35. Ke2 Qa6+ 36. Kd2 Be5 37. Qf7+ Kh6 38. Qf8+ Bg7 39. Qxc5 Qe6 40. Re1 Qf5 41. Qxf5 Bxf5 42. c4 Bxh3 43. c5 Bc8 44. c6 Kg6 45. Re8 Bf5 0-1",
    B: "2235",
  },
  {
    W: "1612",
    T: "300+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h3 Bg7 5. d4 h6 6. Bc4 d6 7. Nc3 Ne7 8. Qe2 Nec6 9. e5 O-O 10. Bd2 dxe5 11. dxe5 Re8 12. O-O-O Nd4 13. Nxd4 Bxe5 14. Qh5 Qf6 15. Nf3 Nc6 16. Nd5 Bxb2+ 17. Kb1 Qg7 18. h4 Ba1 19. c3 Na5 20. Bb3 Nxb3 21. axb3 g4 22. Nd4 Re5 23. Nxc7 Rxh5 0-1",
    B: "1563",
  },
  {
    W: "1773",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. Nc3 Nxc3 6. dxc3 Nc6 7. Bc4 Be7 8. O-O O-O 9. Bf4 a6 10. Re1 b5 11. Bb3 Bg4 12. h3 Bh5 13. Qd3 Bg6 14. Qd5 Na5 15. Rad1 Nxb3 16. cxb3 Rc8 17. Nd4 Bf6 18. Nc6 Qd7 19. Be5 Bxe5 20. Rxe5 Rfe8 21. Rde1 Kh8 22. g4 Rxe5 23. Rxe5 Re8 24. Rxe8+ Qxe8 25. Ne7 Bb1 26. a4 c6 27. Qxd6 Ba2 28. axb5 cxb5 29. Qxa6 Bxb3 30. Qd6 h6 31. Kg2 Qa8+ 32. Kg3 Ba4 33. Nf5 Qf8 34. Qxf8+ 1-0",
    B: "1716",
  },
  {
    W: "1489",
    T: "300+0",
    P: "1. e4 c5 2. Bc4 Nc6 3. Nf3 Nf6 4. d3 e6 5. Bg5 Be7 6. Nc3 a6 7. a3 b5 8. Ba2 Bb7 9. O-O d6 10. h3 O-O 11. Bd2 Qc7 12. Ne2 Nd7 13. Ng3 Rac8 14. Bg5 Bxg5 15. Nxg5 Nd4 16. c3 Nc6 17. Qh5 Nf6 18. Qh4 Ne5 19. Nh5 Nxd3 20. Nxf6+ gxf6 21. Qxh7# 1-0",
    B: "1467",
  },
  {
    W: "1308",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Nb5 Qa5+ 6. N1c3 a6 7. Qd4 Nf6 8. Na3 Nxd4 9. Nc4 Qd8 10. Be3 Nxc2+ 0-1",
    B: "1277",
  },
  {
    W: "1531",
    T: "300+0",
    P: "1. d4 Nf6 2. Bf4 e6 3. Nc3 Bb4 4. e3 d5 5. Bb5+ c6 6. Ba4 O-O 7. Ne2 b5 8. Bb3 a5 9. a3 Bd6 10. O-O b4 11. axb4 Bxb4 12. Na4 Ba6 13. c3 Be7 14. Nc5 Bxc5 15. dxc5 Nbd7 16. Bd6 Ne4 17. Bxf8 Qxf8 18. Qd4 Ndxc5 19. Rxa5 Nxb3 20. Qb6 Nxa5 21. Qxa5 Bxe2 22. Re1 Rxa5 23. Rxe2 Ra1+ 24. Re1 Rxe1# 0-1",
    B: "1546",
  },
  {
    W: "1703",
    T: "300+0",
    P: "1. c4 e5 2. Nc3 Nc6 3. d3 Nf6 4. Bg5 Be7 5. g3 O-O 6. Nf3 a6 7. Bg2 d6 8. O-O h6 9. Bd2 Bg4 10. Re1 Qd7 11. Kh1 Bh3 12. Ng1 Bxg2+ 13. Kxg2 Nd4 14. e3 Ne6 15. b4 Nh7 16. Nd5 c6 17. Nxe7+ Qxe7 18. Rf1 Nhg5 19. f4 Nh7 20. f5 Nc7 21. e4 d5 22. cxd5 cxd5 23. a3 dxe4 24. dxe4 Nb5 25. Nf3 Rad8 26. Qc2 Nd4 27. Nxd4 exd4 28. Qd3 Qd7 29. e5 Rc8 30. Rae1 Rc3 31. Qe4 Rxa3 32. f6 Qc6 33. fxg7 Qxe4+ 34. Rxe4 Re8 35. Bxh6 Ra2+ 36. Rf2 Rxf2+ 37. Kxf2 f6 38. e6 d3 39. Ke3 Ng5 40. Bxg5 fxg5 41. Kxd3 Kxg7 42. Kd4 Re7 43. Kc5 Kf6 44. Kb6 Kf5 45. Re3 Kf6 46. h3 Kf5 47. h4 gxh4 48. gxh4 Kg4 49. h5 Kxh5 50. b5 axb5 51. Kxb5 Kg6 52. Kb6 Kf6 53. Kb5 Kf5 54. Re1 Kf6 55. Kb6 Kf5 56. Kb5 Kf6 57. Kb6 Re8 58. Kxb7 Rxe6 59. Rxe6+ Kxe6 1/2-1/2",
    B: "1705",
  },
  {
    W: "1660",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. d4 Bd7 5. O-O Nf6 6. Nc3 exd4 7. Nxd4 Nxd4 8. Bxd7+ Qxd7 9. Qxd4 Be7 10. Be3 h6 11. h3 O-O 12. Rad1 b6 13. e5 c5 14. Qd2 Ne8 15. Bf4 Qc6 16. exd6 Bf6 17. d7 Nc7 18. Bxc7 Qxc7 19. Nd5 Qd6 20. Nxf6+ Qxf6 21. Rfe1 Rfd8 22. Re8+ Kh7 23. Rxd8 Rxd8 24. Qd3+ g6 25. Re1 Qc6 26. Re7 c4 27. Rxf7+ Kg8 28. Qf3 Qxf3 29. Rxf3 Rxd7 30. Rc3 b5 31. Kf1 Re7 32. f3 Kf7 33. Kf2 Kf6 34. h4 Kf5 35. g4+ Kf4 36. a3 a6 37. b3 Rc7 38. Re3 cxb3 39. Re4# 1-0",
    B: "1641",
  },
  {
    W: "1121",
    T: "300+0",
    P: "1. e4 e5 2. d3 d6 3. Nf3 Bg4 4. h3 Bxf3 5. Qxf3 Nf6 6. Bg5 Be7 7. h4 h6 8. Be3 Nc6 9. Qg3 Nb4 10. Na3 d5 11. Qxg7 Rg8 12. Qxh6 c5 13. Be2 d4 14. Bd2 a5 15. O-O Qd7 16. Bg5 Rg6 17. Qh8+ Ng8 18. Qxe5 O-O-O 19. Qf4 Bxg5 20. hxg5 Qe7 21. Bg4+ Rd7 22. Bf5 Rxg5 23. Bxd7+ Kxd7 24. Qb8 Rg7 25. Qxb7+ Ke6 26. Nb5 Qg5 27. g3 f5 28. Nc7+ Kf6 29. Ne8+ Kg6 30. Qxg7+ 1-0",
    B: "1076",
  },
  {
    W: "1013",
    T: "300+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qe5+ 4. Nge2 Nf6 5. f4 Qh5 6. d4 e6 7. Ng3 Qxd1+ 8. Nxd1 Bb4+ 9. Bd2 Bxd2+ 10. Kxd2 Nc6 11. c3 Bd7 12. Ne3 O-O-O 13. f5 exf5 14. Nexf5 Bxf5 15. Nxf5 Ne4+ 16. Kc2 Nf2 17. Rg1 g6 18. Ne3 Nxd4+ 19. cxd4 Rxd4 20. Rd1 Nxd1 21. Nxd1 Rhd8 22. Be2 Rd2+ 23. Kc1 Rxe2 24. Rf1 Red2 25. g3 Rxh2 26. Rxf7 h5 27. Rf2 Rxf2 28. Nxf2 g5 29. g4 h4 30. Kc2 Rf8 31. Nd3 h3 0-1",
    B: "937",
  },
  {
    W: "934",
    T: "300+0",
    P: "1. d4 d5 2. Bf4 Nc6 3. Nc3 e5 4. dxe5 Nxe5 5. Bxe5 d4 6. Nb5 Bd6 7. Qxd4 Bxe5 8. Qxe5+ Be6 9. Nxc7+ Ke7 10. Nxa8 f6 11. Qc5+ Kf7 12. Nc7 Bf5 13. Rd1 Qe7 14. Qc4+ Be6 15. Rd5 Kg6 16. Nxe6 Qxe6 17. Qd3+ Kh6 18. Qd2+ g5 19. Rd6 Qe7 20. Nf3 Qe8 21. Nxg5 Ne7 22. Nf7+ Kg7 23. Nxh8 Kxh8 24. Rd8 Qxd8 25. Qxd8+ Kg7 26. Qxe7+ Kg6 27. Qxb7 a5 28. e3 h5 29. Bd3+ Kg5 30. f4+ Kg4 31. Be2+ Kh4 32. g3+ Kh3 33. Bf1+ Kg4 34. c4 a4 35. e4 f5 36. exf5 Kxf5 37. g4+ Kxg4 38. h3+ Kh4 39. f5 Kg3 40. f6 h4 41. Bg2 a3 42. Qa7 axb2 43. O-O b1=Q 44. Rxb1 Kf4 45. Be4 Kg3 46. Kf1 Kxh3 47. Kf2 Kg4 48. Ke3 h3 49. f7 Kg3 50. f8=Q h2 51. Bg6 Kg2 52. Rg1+ Kxg1 53. Kf4+ Kg2 54. Qg8 h1=Q 55. Kg5 Kg3 56. a4 Qh4+ 57. Kf5 Qh3+ 58. Kf6 Kg4 59. Kg7 Kg5 60. Qa6 Qh6+ 61. Kf7 Kf4 62. Bc2 Ke5 63. Qa5+ Kd6 64. Qgd8+ Kc6 65. Qb5# 1-0",
    B: "1013",
  },
  {
    W: "1915",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O d6 6. Re1 b5 7. Bb3 Na5 8. d4 Nxb3 9. axb3 exd4 10. Qxd4 Be7 11. Nc3 Bb7 12. Ng5 O-O 13. e5 dxe5 14. Qxe5 Bd6 15. Qf5 h6 16. Nge4 Nxe4 17. Nxe4 Bxe4 18. Rxe4 Re8 19. Rxe8+ Qxe8 20. Be3 Qc6 21. Qg4 Bc5 22. c3 Bxe3 23. fxe3 Qd5 24. c4 bxc4 25. bxc4 Qd2 26. Qf3 Re8 27. Rxa6 Qe1+ 28. Qf1 Qxe3+ 29. Kh1 Qe1 30. Ra1 Qxf1+ 31. Rxf1 Re2 32. Rb1 Rc2 33. b3 c5 34. Kg1 f5 35. Ra1 Rb2 36. Ra8+ Kh7 37. Rc8 Rxb3 38. Rxc5 Rb2 39. Rxf5 Kg6 40. Rc5 1-0",
    B: "2041",
  },
  {
    W: "1691",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bb5 d6 4. O-O e5 5. c3 a6 6. Bxc6+ bxc6 7. d4 cxd4 8. cxd4 exd4 9. Nxd4 Bb7 10. Qa4 Qc7 11. Nc3 Nf6 12. Bg5 Be7 13. Rfe1 O-O 14. Rac1 c5 15. Nf5 Rfe8 16. Nxe7+ Qxe7 17. Nd5 Bxd5 18. Bxf6 gxf6 19. Re3 Be6 20. e5 fxe5 21. Rg3+ Kh8 0-1",
    B: "1683",
  },
  {
    W: "1387",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Qf6 3. Bc4 Bc5 4. d3 h6 5. h3 d6 6. Nc3 c6 7. O-O Bxh3 8. gxh3 g5 9. Qe2 b5 10. Bb3 a5 11. a3 Bd4 12. Nxd4 exd4 13. Nd1 h5 14. f3 Nd7 15. c3 Qe5 16. cxd4 Qxd4+ 17. Be3 Qe5 18. Rc1 Qg3+ 19. Kh1 Qxh3+ 20. Kg1 g4 21. fxg4 hxg4 22. Nf2 Qh2# 0-1",
    B: "1353",
  },
  {
    W: "1269",
    T: "300+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. d3 c6 4. Nbd2 d5 5. g3 e6 6. Bg2 Ne7 7. O-O O-O 8. Re1 Re8 9. Qe2 b5 10. h4 h5 11. Nf1 Ba6 12. Bf4 Bxb2 13. Rab1 Bg7 14. N1h2 Nd7 15. Qd2 f6 16. Bh6 Bxh6 17. Qxh6 Rb8 18. g4 hxg4 19. Nxg4 Ne5 20. Nfxe5 fxe5 21. Nf6+ Kf7 22. Nxe8 Qxe8 23. Qh7+ Kf8 24. Qh6+ Kf7 25. Qe3 d4 26. Qf3+ Kg7 27. h5 b4 28. Qg3 b3 29. Qxe5+ Kg8 0-1",
    B: "1246",
  },
  {
    W: "2202",
    T: "600+0",
    P: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. Nbd2 Nge7 6. Nb3 Ng6 7. Nbxd4 Ngxe5 8. e3 Bg4 9. Be2 Nxd4 10. Nxd4 Bxe2 11. Qxe2 Be7 12. O-O O-O 13. Rd1 Qc8 14. b3 Bf6 15. Bb2 c5 16. Nb5 a6 17. Nd6 Qc6 18. Rd5 Nd7 19. Rad1 Bxb2 20. Qxb2 Nf6 21. Nf5 Qe6 22. Re5 Qc6 23. Ne7+ 1-0",
    B: "2104",
  },
  {
    W: "2009",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O d6 6. c3 Be7 7. d4 b5 8. Bc2 exd4 9. cxd4 O-O 10. h3 Nb4 11. Bb3 Nxe4 12. a3 d5 13. axb4 Bxb4 14. Be3 Qf6 15. Bxd5 Ng5 16. Nxg5 c6 17. Ne4 Qg6 18. Bxc6 Qxc6 19. Nbc3 Qg6 20. Nc5 Bxh3 21. Qf3 Bf5 22. Nd5 Bxc5 23. dxc5 Rae8 24. b4 Be4 25. Qg3 Qxg3 26. fxg3 Bxd5 27. Bf4 Re6 28. Bd6 Ra8 29. Rae1 a5 30. Rxe6 Bxe6 31. bxa5 Rxa5 32. Rc1 Bc8 33. c6 f6 34. Re1 Ra6 35. Re8+ Kf7 36. Rxc8 Ke6 37. Bf4 b4 38. c7 g5 39. Re8+ 1-0",
    B: "2093",
  },
  {
    W: "1259",
    T: "600+0",
    P: "1. e3 Nc6 2. c3 Nf6 3. Nh3 e5 4. Bc4 h6 5. f4 Bd6 6. Bxf7+ Kxf7 7. g4 a5 8. g5 Ne4 9. Qh5+ Kg8 10. gxh6 Rxh6 11. Qg4 Nf6 12. Qg3 exf4 13. exf4 Qe8+ 14. Kf2 Ne4+ 15. Kf3 Nxg3 16. Kxg3 Qg6+ 17. Ng5 Ne5 18. fxe5 Bxe5+ 19. Kf3 Qxg5 20. d3 Rh3+ 21. Ke4 Re3+ 22. Bxe3 Qg2+ 23. Kxe5 Qxh1 24. Kd4 Qxh2 25. Kc4 b5+ 26. Kxb5 c6+ 27. Ka4 Qh3 28. Bd4 Qxd3 29. Na3 Qe4 30. Nc4 c5 31. Nd6 cxd4 32. cxd4 Qxd4+ 33. b4 Qxb4# 0-1",
    B: "1284",
  },
  {
    W: "1121",
    T: "600+0",
    P: "1. e4 e5 2. Qf3 c6 3. Bc4 Nf6 4. g4 d5 5. exd5 cxd5 6. Bb5+ Bd7 7. Bd3 e4 8. Bxe4 dxe4 9. Qf4 Bxg4 10. h3 Bf3 11. Rh2 Qd5 12. Nc3 Qc4 13. Qe5+ Be7 14. b3 Qc5 15. Qg5 Ng4 16. Qxg7 Rf8 17. Nxf3 Nxh2 18. Nxh2 e3 19. fxe3 Bh4+ 20. Ke2 Qd5 21. Ba3 Qg2+ 22. Kd3 Qxh2 23. Bxf8 Nd7 24. Nd5 Nxf8 25. Nc7+ Kd7 26. Qxf7+ Kc6 27. Nxa8 Qd6+ 28. Ke2 Qh2+ 29. Kd3 Qd6+ 30. Kc4 Qe6+ 31. Qxe6+ Nxe6 32. a4 Bf6 33. Rf1 Be7 34. Rf7 Kd6 35. b4 Nd8 36. Rxh7 Nc6 37. b5 b6 38. bxc6 Kxc6 39. Rxe7 Kd6 40. Rxa7 Kc6 41. h4 b5+ 1-0",
    B: "948",
  },
  {
    W: "1709",
    T: "600+0",
    P: "1. e4 Nf6 2. Nc3 e5 3. d4 Nc6 4. dxe5 Nxe5 5. Nf3 Nxf3+ 6. Qxf3 Bb4 7. Bd3 d5 8. Bd2 dxe4 9. Bxe4 O-O 10. O-O-O Re8 11. Bf5 c6 12. Bg5 h6 13. Rxd8 Rxd8 14. Bxf6 gxf6 15. Bxc8 Bxc3 16. Qxc3 Raxc8 17. Qxf6 Rd7 18. Qxh6 Rcd8 19. Qg5+ Kf8 20. Qc5+ Ke8 21. Re1+ Re7 22. Qxe7# 1-0",
    B: "1578",
  },
  {
    W: "1405",
    T: "180+0",
    P: "1. e3 e5 2. Nf3 Nc6 3. d3 d5 4. g3 Bg4 5. Bg2 e4 6. dxe4 dxe4 7. Qxd8+ Rxd8 8. Nfd2 Nf6 9. O-O Ne5 10. Bxe4 Nxe4 11. Nxe4 Bf5 12. Ned2 Bb4 13. c3 Ba5 14. Nb3 Nf3+ 15. Kg2 Be4 16. Kh3 Bf5+ 17. g4 Bxb1 18. Rxb1 Bb6 19. a4 a5 20. c4 O-O 21. c5 Ba7 22. e4 Ne5 23. Be3 c6 24. Nxa5 Nd3 25. Kg2 Bxc5 26. Nxb7 Rb8 27. Nxc5 Nxc5 28. Bxc5 Rfe8 29. f3 Ra8 30. b3 Reb8 31. Rfd1 h6 32. Rd6 Rc8 33. Rd7 f6 34. Re7 Kh7 0-1",
    B: "1387",
  },
  {
    W: "2103",
    T: "180+0",
    P: "1. d4 d5 2. Bf4 Nf6 3. e3 e6 4. Nf3 Bd6 5. Bg3 c5 6. c3 cxd4 7. exd4 Nc6 8. Bd3 O-O 9. Nbd2 Re8 10. Ne5 Bxe5 11. dxe5 Nd7 12. Qe2 Qb6 13. Nf3 d4 14. cxd4 Nxd4 15. Nxd4 Qxd4 16. O-O b6 17. Be4 Rb8 18. Rfd1 Qa4 19. Qg4 Nc5 20. Rd4 Qa5 21. b4 Qa3 22. bxc5 bxc5 23. Rd3 Ba6 24. Rxa3 1-0",
    B: "2143",
  },
  {
    W: "1306",
    T: "180+0",
    P: "1. d4 d5 2. c4 Nf6 3. Nf3 e6 4. Nc3 Nc6 5. e3 a6 6. a3 b6 7. b4 Na7 8. Ne5 Bd7 9. Nxd7 Qxd7 10. Bd3 c6 11. O-O b5 12. c5 Be7 13. f4 O-O 14. Rf3 Bd8 15. Rh3 Ne8 16. Bxh7+ Kh8 17. Qh5 Nf6 18. Bg6+ Nxh5 19. Rxh5+ Kg8 20. Bh7+ Kh8 21. Bg6+ Kg8 22. Bh7+ Kh8 23. Bg6+ 1/2-1/2",
    B: "1572",
  },
  {
    W: "2458",
    T: "180+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. Nc3 Nxc3 4. dxc3 g6 5. h4 h5 6. Bc4 Bg7 7. Nf3 d6 8. e6 fxe6 9. Qd3 O-O 10. Qxg6 Rf6 11. Qxh5 d5 12. Bd3 Nc6 13. Bg5 Rf7 14. Bg6 Qe8 15. Qh7+ Kf8 16. Bh6 1-0",
    B: "2158",
  },
  {
    W: "1989",
    T: "180+0",
    P: "1. e4 Nc6 2. d4 e5 3. d5 Nce7 4. Nf3 Ng6 5. h4 Nf6 6. h5 Nxh5 7. Rxh5 Bb4+ 8. c3 Bc5 9. Bd3 d6 10. Na3 Bg4 11. Rh1 O-O 12. Nc2 Nf4 13. Bxf4 exf4 14. Ne3 Bxe3 15. fxe3 fxe3 16. Qe2 f5 17. O-O-O fxe4 18. Bxe4 h6 19. Kb1 Re8 20. Qxe3 Bf5 21. Nd2 Qe7 22. Rde1 Bxe4+ 23. Qxe4 Qf7 24. Qd4 Re7 25. Rhf1 Rxe1+ 26. Rxe1 Re8 27. Rxe8+ Qxe8 28. Qe4 Qf8 29. Qe6+ Kh8 30. a4 Qf2 31. Qe8+ Kh7 32. Qe4+ Kh8 33. Nc4 Qf1+ 34. Ka2 Qd1 35. Ne3 Qe1 36. g4 Qe2 37. Qe6 Qa6 38. Qe8+ Kh7 39. Nf5 Qxa4+ 40. Qxa4 1-0",
    B: "2004",
  },
  {
    W: "1502",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. c3 d6 5. b4 Be6 6. Bxe6 fxe6 7. d4 exd4 8. cxd4 d5 9. Nbd2 dxe4 10. Nxe4 Bxb4+ 11. Bd2 Bxd2+ 12. Qxd2 Nf6 13. Nxf6+ Qxf6 14. O-O O-O-O 15. Rfe1 Nxd4 16. Qa5 Nxf3+ 17. gxf3 a6 18. Rac1 c6 19. Qb6 Qxf3 20. Rb1 Rd7 21. Qa7 Qg4+ 22. Kh1 Qf3+ 23. Kg1 Rf8 24. Qa8+ Kc7 25. Qxb7+ Kd6 26. Rbd1+ Kc5 27. Re5+ Rd5 28. Rc1+ Kd6 29. Qxc6+ Kxe5 30. Re1+ Kd4 31. Qb6+ Kc4 32. Rc1+ 1-0",
    B: "1496",
  },
  {
    W: "1851",
    T: "180+0",
    P: "1. c4 g6 2. g3 Bg7 3. Bg2 Nf6 4. e3 d6 5. Ne2 Nc6 6. O-O O-O 7. Nbc3 e5 8. b3 Be6 9. Bb2 Qd7 10. d4 exd4 11. Nxd4 Nxd4 12. Qxd4 Nh5 13. Qd2 Bh3 14. Bxb7 Rab8 15. Bg2 Bxg2 16. Kxg2 Qg4 17. Nd5 Qe4+ 18. Kg1 Rfe8 19. Bxg7 Kxg7 20. Qc3+ Qe5 21. Qxe5+ Rxe5 22. Nxc7 Nf6 23. Rad1 Rd8 24. Nb5 d5 25. cxd5 Rexd5 26. Rxd5 Rxd5 27. Nxa7 Ra5 28. Nc6 Ra3 29. Ra1 Ne4 30. Nd4 1-0",
    B: "1842",
  },
  {
    W: "1057",
    T: "180+0",
    P: "1. e4 c5 2. d3 d6 3. Nf3 Nc6 4. Be3 Nf6 5. Nc3 h6 6. Nd5 e6 7. Nxf6+ Qxf6 8. c3 Be7 9. h4 O-O 10. g4 d5 11. g5 Qg6 12. h5 Qh7 13. Rg1 hxg5 14. Rxg5 Bxg5 15. Nxg5 Qh8 16. Qf3 d4 17. Bf4 e5 18. Bg3 dxc3 19. bxc3 b5 20. O-O-O b4 21. Qe3 Qxh5 22. cxb4 cxb4 23. Be2 Qg6 24. Bh4 Nd4 25. Nf3 Nxf3 26. Bxf3 Be6 27. d4 Bxa2 28. dxe5 Rac8+ 29. Kd2 Rfd8+ 30. Ke1 Rxd1+ 31. Kxd1 Qc6 32. Qxa7 Qc1+ 33. Ke2 Rc2+ 34. Kd3 Qd2# 0-1",
    B: "1238",
  },
  {
    W: "1798",
    T: "180+0",
    P: "1. e4 b6 2. Nf3 Bb7 3. Nc3 Nf6 4. d3 d5 5. exd5 Nxd5 6. Nxd5 Bxd5 7. Be2 e6 8. O-O Bd6 9. c4 Bb7 10. a3 Nd7 11. b4 Nf6 12. d4 O-O 13. Bg5 Be7 14. Ne5 Ne4 15. Bxe7 Qxe7 16. Bf3 Rab8 17. d5 Rfd8 18. Bxe4 exd5 19. Bxh7+ Kxh7 20. Re1 dxc4 21. Qh5+ Kg8 22. Nxc4 Qf6 23. Ne3 g6 24. Qh6 Rd2 25. Rf1 Rbd8 26. Ng4 Qg7 27. Qxg7+ Kxg7 28. Ne5 Re2 29. Rfe1 Rdd2 0-1",
    B: "1781",
  },
  {
    W: "1854",
    T: "180+0",
    P: "1. e4 e6 2. d4 d5 3. exd5 exd5 4. Nc3 c5 5. Nf3 Nc6 6. Be2 cxd4 7. Nxd4 Nf6 8. O-O Be7 9. Be3 O-O 10. Nxc6 bxc6 11. Bd4 Be6 12. Bf3 Rc8 13. Re1 c5 14. Bxf6 Bxf6 15. Rb1 d4 16. Ne4 Be7 17. b3 Bd5 18. Nd2 Bf6 19. Bxd5 Qxd5 20. Nf3 Rfe8 21. Rxe8+ Rxe8 22. c3 d3 23. c4 Qe4 24. Qd2 Qe2 25. Qxe2 Rxe2 26. Rd1 Rxa2 27. Kf1 Ra1 28. Rxa1 Bxa1 29. Ke1 Bc3+ 30. Kd1 f6 31. h3 Kf7 32. Ne1 d2 33. Nf3 Ke6 34. Nxd2 Ke5 35. Ke2 Bxd2 36. Kxd2 Kd4 37. g3 f5 38. f3 g5 39. h4 gxh4 40. gxh4 f4 41. h5 h6 42. Ke2 Kc3 43. Kf2 1-0",
    B: "1872",
  },
  {
    W: "1455",
    T: "180+0",
    P: "1. d4 d5 2. c4 dxc4 3. e3 Nf6 4. Nc3 e6 5. e4 Bb4 6. Qf3 O-O 7. Be3 Nc6 8. Bxc4 a6 9. d5 exd5 10. exd5 Ne5 11. Qd1 Re8 12. Nf3 Nxc4 13. Qd3 Nxb2 0-1",
    B: "1460",
  },
  {
    W: "1604",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. d4 Bd7 5. dxe5 Nxe5 6. Bxd7+ Qxd7 7. Nxe5 dxe5 8. Qxd7+ Kxd7 9. O-O Rd8 10. Bg5 f6 11. Be3 a6 12. Rd1+ Kc8 13. Rxd8+ Kxd8 14. Nc3 Kc8 15. Nd5 Ne7 16. Rd1 Nxd5 17. exd5 Bd6 18. c4 Rd8 19. a3 c6 20. dxc6 bxc6 21. b4 Bc7 22. Rxd8+ Kxd8 23. c5 g6 24. Kf1 f5 25. Bg5+ Kd7 26. f4 exf4 27. Kf2 Ke6 28. g3 fxg3+ 29. hxg3 Kd5 30. Kf3 Kc4 31. Bc1 Kb3 32. Ke2 Bxg3 33. Kd3 h5 34. Ke2 h4 35. Kf3 f4 36. Kg2 g5 37. Kh3 Kc2 38. Bxf4 Bxf4 39. Kg4 Bc1 40. Kf5 h3 41. Kg4 h2 42. Kg3 h1=Q 43. Kg4 Qf1 44. Kh5 g4 45. Kxg4 Bxa3 46. Kg5 Bxb4 47. Kg6 Bxc5 48. Kg7 a5 49. Kg6 a4 50. Kg5 a3 51. Kg4 a2 52. Kg3 a1=Q 53. Kh4 Qg7 54. Kh5 Qh1# 0-1",
    B: "1521",
  },
  {
    W: "1688",
    T: "180+0",
    P: "1. d4 e6 2. Bf4 c5 3. c3 cxd4 4. cxd4 d5 5. e3 a6 6. Nf3 Nc6 7. a3 Nf6 8. Bd3 Nh5 9. Bg3 Nxg3 10. hxg3 Bd7 11. Nbd2 Bd6 12. Rxh7 Rxh7 13. Bxh7 g6 14. Qb3 Qc7 15. Ke2 Ke7 16. Ng5 Rh8 17. Rh1 Kf6 18. f4 Kg7 19. Qd1 f6 20. Ndf3 fxg5 21. Nxg5 Be7 22. Bxg6 Rxh1 23. Qxh1 Kxg6 24. Qh7+ Kf6 25. Qh6+ Kf5 26. g4+ Kxg4 27. Kf2 Be8 28. Qh3# 1-0",
    B: "1765",
  },
  {
    W: "1301",
    T: "60+0",
    P: "1. e4 d5 2. Nc3 e6 3. exd5 Bb4 4. d4 exd5 5. Bd2 Bxc3 6. Bxc3 Nc6 7. Bb5 Ne7 8. Nf3 Bg4 9. Be2 Bxf3 10. Bxf3 Nf5 11. Bg4 Nd6 12. Bf3 Qe7+ 13. Be2 O-O-O 14. O-O Nf5 15. Re1 Nh4 16. Bb5 Qg5 17. g3 Qh5 18. Be2 Qg5 19. Bd2 Qe7 20. Qc1 f6 21. Bg4+ Kb8 22. gxh4 a5 23. Bf4 Qb4 24. Bg3 Nxd4 25. c3 Qb5 26. b3 Nf3+ 27. Kg2 Qc6 28. Bxf3 Ka7 29. Qd2 Qb6 30. Re3 Rd6 31. c4 Rc6 32. Rc3 Rc5 33. b4 Qc6 34. bxc5 b6 35. Rcc1 bxc5 36. Qc2 Kb7 37. a3 Qd7 38. Rab1+ Kc6 39. Qb2 1-0",
    B: "1285",
  },
  {
    W: "2190",
    T: "60+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. d4 c5 4. c3 cxd4 5. cxd4 d6 6. Nc3 Nf6 7. Bc4 Nbd7 8. O-O Nb6 9. Bb3 Bg4 10. Be3 O-O 11. h3 Bxf3 12. Qxf3 Rc8 13. Rac1 e5 14. dxe5 dxe5 15. Rfd1 Qe7 16. a3 Rfd8 17. Bg5 h6 18. Be3 Rxd1+ 19. Rxd1 Rd8 20. Rc1 Rd3 21. Qe2 Rxc3 22. Rxc3 Kh7 23. Bc5 Qd8 24. f3 Nbd7 25. Rd3 Qa5 26. Be3 Qb5 27. Bc2 Qxb2 28. Qd1 Nb6 29. Rb3 Qa2 30. Bxb6 axb6 31. Rxb6 Qxa3 32. Rxb7 Nh5 33. Rb3 Qa7+ 34. Kh2 Nf4 35. g3 Qf2+ 0-1",
    B: "2187",
  },
  {
    W: "1932",
    T: "60+0",
    P: "1. d4 g6 2. Nf3 Bg7 3. e3 c5 4. Bd3 cxd4 5. c3 dxe3 6. Bxe3 Nc6 7. Nbd2 Nf6 8. Qe2 O-O 9. O-O d5 10. Rfe1 e5 11. Bc2 e4 12. Nd4 Bg4 13. f3 exf3 14. N2xf3 Ne4 15. h3 Bd7 16. Bc1 Rc8 17. Bxe4 dxe4 18. Qxe4 Nxd4 19. Nxd4 Bf5 20. Nxf5 gxf5 21. Qxf5 Qb6+ 22. Be3 Qxb2 23. Qg4 Qxc3 24. Bh6 Rc4 25. Qg5 Qd4+ 26. Kh1 Rc5 27. Qg3 Qf6 28. Bxg7 Qxg7 29. Qe3 Re5 30. Qxe5 f6 31. Qe7 1-0",
    B: "1919",
  },
  {
    W: "1515",
    T: "60+0",
    P: "1. e4 e5 2. Nc3 d6 3. Bc4 Nc6 4. Nf3 Bg4 5. O-O Nf6 6. h3 Bh5 7. Be2 d5 8. exd5 Nxd5 9. Nxd5 Qxd5 10. Nh2 Bxe2 11. Qxe2 Nd4 12. Qe3 Nxc2 13. Qc3 Nxa1 14. Re1 f6 15. f4 Bd6 16. d4 O-O-O 17. fxe5 fxe5 18. Bg5 Rde8 19. dxe5 Bxe5 20. Qa3 Qd6 21. Qxa7 Nc2 22. Qa8+ Kd7 23. Re2 Rxa8 24. Rd2 Qxd2 25. Bxd2 Bd4+ 26. Kf1 Rhf8+ 27. Ke2 Rae8+ 28. Kd3 Nb4+ 29. Kxd4 c5+ 30. Kc4 b6 31. Kb5 Ra8 32. g3 Ra5+ 33. Kc4 Re8 34. Ng4 Re4+ 0-1",
    B: "1499",
  },
  {
    W: "1845",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. d4 exd4 5. Nxd4 Bd7 6. Nxc6 Bxc6 7. Bxc6+ bxc6 8. O-O Nf6 9. Nc3 Be7 10. f4 O-O 11. b3 Nd7 12. Bb2 Bf6 13. Qe2 Re8 14. Rae1 Re7 15. Qc4 Nb6 16. Qxc6 Qd7 17. Qxa8+ Nxa8 18. e5 dxe5 19. fxe5 Rxe5 20. Rxe5 Bxe5 21. Re1 Bd4+ 22. Kh1 Nb6 23. Rd1 c5 24. a4 Qf5 25. h3 h5 26. Rf1 Qd7 27. Rd1 Qe6 28. Ba1 Nd5 29. Nb5 c4 30. Nxd4 Qe2 31. Nxe2 Nc3 32. Bxc3 Kh7 33. Rd8 g6 34. Rd7 Kh6 35. Rxf7 Kg5 36. Bf6+ Kf5 37. h4 Ke6 38. Re7+ Kxf6 39. Rxa7 Ke5 40. Rc7 Kf5 41. Rxc4 1-0",
    B: "1889",
  },
  {
    W: "1560",
    T: "60+0",
    P: "1. d4 e6 2. c4 d6 3. Bf4 f6 4. e3 g5 5. Bg3 h5 6. h3 h4 7. Bh2 Be7 8. Nf3 f5 9. Nc3 a6 10. Bd3 Bd7 11. Nd2 a5 12. Qa4 c6 13. Qb3 Na6 14. Qxb7 Nb4 15. Be2 Bc8 16. Qxa8 Nc2+ 17. Kd1 Nxa1 18. Qxc6+ Kf8 19. Bd3 a4 20. Qxa4 Bb7 21. Ke2 Bxg2 22. Rxa1 Bxh3 23. Qa3 Bg4+ 24. Kf1 Bh3+ 25. Ke2 Nf6 26. Rc1 Bg4+ 27. Kf1 Bh3+ 28. Ke1 Kf7 29. Nb5 Qb8 30. Bxd6 Bxd6 31. Nxd6+ Kg6 32. N6e4 Qh2 33. Ke2 Bg4+ 34. Ke1 fxe4 35. Nf1 exd3 36. Qxd3+ Bf5 37. c5 Qb8 38. c6 Qb4+ 0-1",
    B: "1589",
  },
  {
    W: "1807",
    T: "60+0",
    P: "1. e4 c5 2. Qh5 d6 3. Nf3 Nf6 4. Qg5 h6 5. Qe3 Nc6 6. Qb3 Nxe4 7. g4 Qb6 8. g5 c4 9. Qxb6 axb6 10. gxh6 Rxh6 11. Bxc4 e6 12. d3 Nc5 13. Be3 Be7 14. Bxh6 Kd7 15. Bb5 Kc7 16. Bxc6 bxc6 17. Bf4 Ba6 18. d4 Nd7 19. c3 Rh8 20. Nbd2 Rh3 21. O-O-O Bd3 22. Bg5 f6 23. Be3 Be2 24. Rde1 Bxf3 25. Nxf3 Rxf3 26. Rhg1 g5 27. h4 Nb8 28. hxg5 fxg5 29. Bxg5 Kd7 30. Bxe7 Kxe7 31. Rg7+ Rf7 32. Rxf7+ Kxf7 33. Kd2 Kf6 34. Kd3 1-0",
    B: "1489",
  },
  {
    W: "1789",
    T: "60+0",
    P: "1. g3 c6 2. Bg2 d5 3. d3 Nf6 4. Nd2 g6 5. b3 Bg7 6. Bb2 O-O 7. Ngf3 Re8 8. O-O Nbd7 9. Re1 e6 10. e4 dxe4 11. dxe4 Nc5 12. e5 Nd5 13. Nc4 Nb4 14. Qxd8 Rxd8 15. Re2 Nba6 16. Nd6 Nc7 17. c4 Ne8 18. Rd1 Nxd6 19. Rxd6 Rxd6 20. exd6 Bd7 21. Bxg7 Kxg7 22. Ne5 Rd8 23. b4 Na4 24. c5 Nc3 25. Rc2 Nd5 26. Bf1 f6 27. Nc4 Nxb4 28. Rb2 Na6 29. Rxb7 Nxc5 30. Rxa7 Kh6 31. Na5 Bc8 32. Nxc6 Rxd6 33. Ne7 Bd7 34. Rc7 1-0",
    B: "1699",
  },
  {
    W: "1496",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 e5 3. d4 d6 4. dxe5 dxe5 5. Qxd8+ Kxd8 6. Nxe5 Nf6 7. Nxf7+ Ke7 8. Nxh8 Be6 9. e5 Nc6 10. exf6+ Kxf6 11. Nc3 Re8 12. Bd3 Bc4+ 13. Kd2 Bxd3 14. cxd3 Bd6 15. Nd5+ Ke5 16. Nc3 Rxh8 17. Re1+ Kf6 18. Kc2 Rf8 19. Be3 Kg6 20. Kb1 a6 21. Kc2 b5 22. Rad1 Bf4 23. Bxf4 Rxf4 24. Rf1 Ne5 25. Ne4 c4 26. g3 Rg4 27. h3 cxd3+ 28. Kc1 Rxe4 29. Rfe1 Rxe1 30. Rxe1 Nc4 31. Re4 d2+ 32. Kd1 Nxb2+ 33. Kc2 Nc4 0-1",
    B: "1458",
  },
  {
    W: "1128",
    T: "60+0",
    P: "1. e4 c6 2. Nf3 d5 3. Nc3 dxe4 4. Nxe4 Bf5 5. d3 Bxe4 6. dxe4 Qxd1+ 7. Kxd1 Nf6 8. Bg5 Nxe4 9. Bf4 Nxf2+ 10. Ke1 Nxh1 11. g3 e6 12. Bg2 Nxg3 13. Bxg3 Nd7 14. Rd1 O-O-O 15. Ne5 Nxe5 16. Rxd8+ Kxd8 17. Bxe5 Be7 18. a4 f6 19. Bg3 e5 20. c4 Ke8 21. Kd2 Kf7 22. Kc3 Rd8 23. b4 a6 24. b5 cxb5 25. Bd5+ Kf8 26. axb5 axb5 27. Bxb7 Rb8 28. Ba6 bxc4 0-1",
    B: "1172",
  },
  {
    W: "1688",
    T: "60+0",
    P: "1. g3 d5 2. Bg2 e6 3. d3 Nf6 4. e3 Nc6 5. b3 Bd6 6. Bb2 e5 7. Nc3 Be6 8. Nf3 e4 9. dxe4 dxe4 10. Nd2 Bg4 11. f3 exf3 12. Nxf3 O-O 13. h3 Bh5 14. g4 Bg3+ 15. Ke2 Bg6 16. Qxd8 Raxd8 17. h4 h6 18. Rh3 Bd6 19. Nb5 Nxg4 20. Nxa7 Nxa7 21. Rd1 Rfe8 22. Nd4 Nc6 23. Nxc6 bxc6 24. Bf3 Ne5 25. Rg1 Nxf3 26. Rxf3 Bh5 27. Rxg7+ Kf8 28. Rh7 Bxf3+ 29. Kxf3 Ke7 30. Bg7 Rg8 31. Bd4 Rg6 32. Rh8 Rxh8 33. Bxh8 Be5 34. Bxe5 c5 35. Bf4 c6 36. c4 Re6 37. Bg3 Kf6 38. Bf2 Kf5 39. e4+ Ke5 40. Bg3+ Kf6 41. Bc7 Kg6 42. Bd8 Rf6+ 43. Bxf6 Kxf6 44. Kf4 Ke6 45. e5 f6 46. Ke4 fxe5 47. h5 Kf6 48. a3 Ke6 49. a4 Kf6 50. a5 Ke6 51. a6 Kf6 52. a7 Ke6 53. a8=Q Kd6 54. Qxc6+ Kxc6 55. b4 Kd6 56. bxc5+ 1-0",
    B: "1682",
  },
  {
    W: "1847",
    T: "180+0",
    P: "1. e4 e6 2. Nc3 Be7 3. Bc4 d6 4. d3 Bd7 5. f4 a6 6. a4 c6 7. Nf3 b5 8. axb5 cxb5 9. Nxb5 axb5 10. Rxa8 bxc4 11. O-O Nf6 12. e5 Nh5 13. Be3 dxe5 14. Nxe5 O-O 15. Qxh5 g6 16. Qd1 cxd3 17. Qxd3 Bc6 18. Qxd8 Rxd8 19. Rxb8 Rxb8 20. Nxc6 Re8 21. Nxe7+ Rxe7 22. c3 Kf8 23. Bd4 Ke8 24. b4 Kd7 25. Ra1 Kc6 26. Ra7 Re8 27. Rxf7 Kd5 28. Rxh7 Kc4 29. Rh6 Rg8 30. Rh7 Re8 31. Rg7 Kd5 32. Rxg6 Kc4 33. h4 Kd5 34. h5 Ke4 35. h6 Kf5 36. Rg7 Kxf4 37. h7 Kf5 38. Rg8 Ra8 39. h8=Q Ra5 40. Qf6+ 1-0",
    B: "1688",
  },
  {
    W: "1887",
    T: "180+0",
    P: "1. e4 d6 2. Nf3 Nf6 3. Nc3 Nbd7 4. d4 e5 5. dxe5 dxe5 6. Bg5 Be7 7. Bc4 c6 8. a3 Qc7 9. O-O h6 10. Bxf6 gxf6 11. Nh4 Nf8 12. Nf5 Ng6 13. Ng7+ Kf8 14. Nf5 Nf4 15. g3 Nh3+ 16. Kg2 Ng5 17. h4 Nxe4 18. Nxe4 Bxf5 19. Qh5 Bxe4+ 20. f3 Bd5 21. Bb3 Bxb3 22. cxb3 Qb6 23. b4 Qd4 24. Rab1 Qd2+ 25. Rf2 Qd3 26. Rff1 Qg6 27. Qg4 Qxg4 28. fxg4 Rg8 29. Kh3 Rd8 30. Rbd1 Rxd1 31. Rxd1 Ke8 32. b3 Bd8 33. a4 Ke7 34. b5 cxb5 35. axb5 Bb6 36. Rc1 a6 37. bxa6 bxa6 38. Rc6 Bd4 39. Rxa6 Rb8 40. Ra7+ Ke6 41. Ra6+ Kd5 42. Rxf6 Rxb3 43. Rxh6 Be3 44. Rf6 Bf4 45. Rxf7 Rxg3+ 46. Kh2 Rxg4+ 47. Kh1 Rxh4+ 48. Kg2 Rg4+ 49. Kf3 Rg3+ 50. Ke2 Re3+ 51. Kf2 Re4 52. Kf3 Be3 53. Ke2 Rd4 54. Kxe3 Rc4 55. Ke2 Rc3 0-1",
    B: "1874",
  },
  {
    W: "1973",
    T: "60+0",
    P: "1. g3 d5 2. Bg2 e6 3. Nf3 b6 4. d4 Bb7 5. O-O Nc6 6. Re1 Qd7 7. Bf4 Bd6 8. Nc3 Bxf4 9. gxf4 O-O-O 10. e3 h6 11. Ne5 Nxe5 12. dxe5 f6 13. exf6 gxf6 14. Qd2 Ne7 15. Rad1 Rhg8 16. Kh1 Kb8 17. Ne4 dxe4 18. Qxd7 Rxd7 19. Rxd7 Nd5 20. Bxe4 f5 21. Bxd5 exd5 22. f3 d4 23. e4 fxe4 24. fxe4 Bc6 25. Rxd4 Kb7 26. h4 Rg4 27. f5 Rxh4+ 28. Kg2 Rg4+ 29. Kf3 h5 30. Rh1 Rg8 31. Rxh5 a6 32. Rh6 b5 33. Rxc6 Kxc6 34. c3 Kc5 35. b4+ Kc6 36. c4 bxc4 37. Rxc4+ Kb5 38. Rd4 c6 39. a3 a5 40. bxa5 Kxa5 41. Rb4 Re8 42. Rc4 Kb5 43. Rb4+ Kc5 44. Kf4 Kd6 45. Rd4+ Ke7 46. e5 Kf8 47. e6 Ra8 48. Ke5 Rxa3 49. f6 Ra5+ 50. Kd6 Rf5 51. f7 Rxf7 52. exf7 Kxf7 53. Kxc6 Kf6 54. Kd6 Kf5 55. Rd3 Ke4 56. Rd2 Ke3 57. Rd1 Ke2 58. Rc1 Ke3 59. Rb1 Kd3 60. Ra1 1-0",
    B: "1893",
  },
  {
    W: "2106",
    T: "120+1",
    P: "1. e4 c5 2. c3 d5 3. exd5 Nf6 4. Nf3 Qxd5 5. d4 e6 6. Be2 Nc6 7. O-O b6 8. Be3 Bb7 9. dxc5 Bxc5 10. Qxd5 Nxd5 11. Bxc5 bxc5 12. Nbd2 O-O 13. Nb3 Nf4 14. Bb5 Ne7 15. Ne5 Rfc8 16. g3 Nfg6 17. Nc4 Bd5 18. Nd6 Rd8 19. Nc4 a6 0-1",
    B: "2161",
  },
  {
    W: "1556",
    T: "120+1",
    P: "1. d4 d5 2. Nf3 Nc6 3. c4 e6 4. Nc3 Nf6 5. a3 Bd6 6. Bg5 O-O 7. Bxf6 Qxf6 8. e3 e5 9. cxd5 exd4 10. Nxd4 Nxd4 11. Qxd4 Qxd4 12. exd4 Re8+ 13. Be2 Bg4 14. f3 Bf5 15. g4 Bd3 16. Kd2 Bxe2 17. Nxe2 c5 18. dxc5 Bxc5 19. Rad1 Rad8 20. Nc3 Be3+ 21. Kd3 Rd6 22. Rhe1 1-0",
    B: "1553",
  },
  {
    W: "1490",
    T: "120+1",
    P: "1. d4 d5 2. c4 dxc4 3. Qa4+ c6 4. Qxc4 Bg4 5. h3 Bf5 6. Nc3 Nf6 7. Nf3 e6 8. a3 Be7 9. Bf4 Nbd7 10. e3 Nb6 11. Qb3 Nbd5 12. Bh2 Rb8 13. Be2 Bd6 14. Ne5 O-O 15. O-O Ne4 16. Nxe4 Bxe4 17. f3 f6 18. fxe4 fxe5 19. exd5 exd5 20. dxe5 Bc5 21. Rxf8+ Qxf8 22. Rf1 Qe7 23. Kh1 Rf8 24. Rxf8+ Qxf8 25. Qxb7 Qf2 26. Qc8+ Kf7 27. Qb7+ Kg6 28. Qxc6+ Kg5 29. Bf4+ Kh4 30. Qb5 Bxe3 31. Kh2 Bxf4+ 32. Kh1 Qe1+ 33. Bf1 Qg3 0-1",
    B: "1509",
  },
  {
    W: "1172",
    T: "120+1",
    P: "1. d4 d5 2. c4 dxc4 3. e4 b5 4. Nc3 c6 5. a4 a6 6. axb5 cxb5 7. Nxb5 Bb7 8. Nc3 f5 9. exf5 g6 10. fxg6 hxg6 11. Bxc4 e6 12. Nf3 Bb4 13. O-O Bd6 14. Bxe6 Bxh2+ 15. Nxh2 Qh4 16. Bh3 Bxg2 17. Re1+ Ne7 18. Kxg2 Nc6 19. Re3 O-O 20. Nf3 Qg5+ 21. Kf1 Qb5+ 22. Nxb5 axb5 23. Rxa8 Rxa8 24. Ne5 Nxe5 25. Rxe5 Nc6 26. Rxb5 1-0",
    B: "1133",
  },
  {
    W: "1867",
    T: "120+1",
    P: "1. d4 d5 2. Nf3 Nf6 3. Nbd2 c6 4. e3 Bg4 5. c3 e6 6. Be2 Bd6 7. a3 Nbd7 8. O-O Bc7 9. b4 a6 10. h3 Bh5 11. Bb2 Qe7 12. Qb3 Qd6 13. Rae1 Bxf3 14. Nxf3 Ne4 15. c4 Nd2 16. c5 Nxf3+ 0-1",
    B: "1928",
  },
  {
    W: "2058",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. O-O Nf6 5. Re1 Bg4 6. Bxc6+ bxc6 7. d4 exd4 8. Qxd4 Be7 9. Be3 O-O 10. Nbd2 d5 11. e5 Bxf3 12. gxf3 Nd7 13. Kh1 c5 14. Qxd5 Nb6 15. Qe4 Re8 16. Rg1 Nd5 17. Nc4 Nxe3 18. Nxe3 Qd2 19. Rg2 Rad8 20. Rag1 g6 21. f4 Bh4 22. Qf3 c4 23. f5 Rxe5 24. fxg6 fxg6 25. Nxc4 Qxc2 1-0",
    B: "1935",
  },
  {
    W: "1343",
    T: "120+1",
    P: "1. d3 e5 2. f4 exf4 3. Bxf4 Bc5 4. Nf3 d6 5. e4 Bg4 6. Be2 Nc6 7. Nbd2 Qd7 8. h3 Bh5 9. g4 Bg6 10. Nc4 O-O-O 11. Qd2 Kb8 12. O-O-O h5 13. e5 d5 14. Ne3 d4 15. Nc4 Nge7 16. Kb1 hxg4 17. hxg4 f5 18. Rxh8 Rxh8 19. gxf5 Nxf5 20. Ng5 Rh2 21. Bxh2 Qe7 22. Qf4 b5 23. Na3 b4 24. Nc4 Kb7 25. Bf3 Kb8 26. e6 Nd8 27. Qe4 Nd6 28. Qa8# 1-0",
    B: "1472",
  },
  {
    W: "1643",
    T: "120+1",
    P: "1. e4 e5 2. Nc3 Qf6 3. Nf3 c6 4. d4 exd4 5. Nxd4 Be7 6. Bc4 d6 7. Be3 Qg6 8. O-O a6 9. f4 c5 10. f5 Qf6 11. Nf3 h6 12. g4 b5 13. Nd5 Qxb2 14. Nc7+ Kd7 15. Nxa8 bxc4 16. Rb1 Qc3 17. Bd2 Qa3 18. Nb6+ Kd8 19. Nxc8 Kxc8 20. e5 d5 21. Bf4 Nc6 22. Qxd5 Qa4 23. Qxf7 c3 24. Bg3 Qxg4 25. Qe8+ Kc7 26. Rfd1 Nf6 27. exf6+ Qxg3+ 28. hxg3 Rxe8 29. fxe7 Rxe7 30. g4 Re3 31. Kf2 Re7 32. Re1 Rf7 33. Re6 Nb4 34. Ne5 Rf8 35. Rc1 Nxa2 36. Re1 Nb4 37. Re7+ Kd6 38. Rxg7 Nxc2 39. Nc4+ Kc6 40. Ne3 Kb5 41. Nxc2 a5 42. Ne3 Ka4 43. Rg6 Kb3 44. Rxh6 Rg8 45. Ra6 c2 46. Nxc2 Kxc2 47. Rxa5 Kd3 0-1",
    B: "1805",
  },
  {
    W: "1609",
    T: "300+3",
    P: "1. d4 c6 2. e4 d6 3. Nc3 Nf6 4. Nf3 Qa5 5. Bd2 Qb6 6. Rb1 Bg4 7. e5 dxe5 8. dxe5 Nfd7 9. Bc4 e6 10. O-O Nxe5 11. Be2 Nbd7 12. Bf4 Bxf3 13. Bxf3 O-O-O 14. Qe2 Nxf3+ 15. Qxf3 h6 16. Be3 Bc5 17. Bxc5 Nxc5 18. Qxf7 Rhg8 19. b3 Rdf8 20. Qe7 Nd7 21. Qxe6 Qb4 22. Ne4 Re8 23. Nd6+ Kd8 24. Nxe8 Rxe8 25. Qf7 Re7 26. Qg8+ Kc7 27. Rbd1 Qc5 28. c4 Qf5 29. Rfe1 Rxe1+ 30. Rxe1 a5 31. Qxg7 Qd3 32. Qxh6 1-0",
    B: "1762",
  },
  {
    W: "1533",
    T: "300+3",
    P: "1. e4 e6 2. Nc3 d5 3. f4 dxe4 4. Nxe4 Nc6 5. Nf3 Nf6 6. Nxf6+ Qxf6 7. d4 Bd6 8. g3 O-O 9. Be3 Rb8 10. Bd3 b6 11. Ne5 Ne7 12. Rf1 Rd8 13. c4 Bb4+ 14. Ke2 Nf5 15. Bxf5 Qxf5 16. Nc6 Bb7 17. Nxb4 c5 18. Nc2 cxd4 19. Nxd4 Qe4 20. Qd3 Qg2+ 21. Rf2 Qh3 22. Qc3 Qg4+ 23. Rf3 Bxf3+ 24. Nxf3 Qh3 25. Rf1 Qg2+ 26. Rf2 Qg1 27. Nxg1 Rd7 28. Qe5 Rbd8 29. Kf3 Rd3 30. Ne2 R3d7 31. Bd4 f6 32. Qxe6+ Kh8 33. Bxf6 gxf6 34. Qxf6+ Kg8 35. g4 Rd3+ 36. Kg2 Rd2 37. Ng3 Rxf2+ 38. Kxf2 Rd2+ 39. Ne2 Rd7 40. h4 Rf7 41. Qg5+ Kh8 42. Qd8+ Kg7 43. Nd4 Rxf4+ 44. Kg3 Rf7 45. Ne6+ Kg6 46. Qg5# 1-0",
    B: "1569",
  },
  {
    W: "1595",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d5 4. exd5 Qxd5 5. Nc3 Qd6 6. O-O Bd7 7. d4 exd4 8. Re1+ Be7 9. Bxc6 Bxc6 10. Nxd4 Bd7 11. Bg5 O-O-O 12. Bxe7 Nxe7 13. Ncb5 Qf6 14. a4 Bxb5 15. Rxe7 Qxe7 16. axb5 Kb8 17. b6 a6 18. bxc7+ Qxc7 19. Qg4 g6 20. c3 f5 21. Qf3 Qc4 22. b3 Qc7 23. b4 Rd6 24. b5 Re8 25. bxa6 Rxa6 26. Rb1 Re7 27. g3 Rd6 28. Nb5 Qb6 29. c4 Rc6 30. Kg2 Qc5 31. Qf4+ Kc8 32. Ra1 Qxc4 33. Ra8+ Kd7 34. Qd2+ Ke6 35. Nd4+ 1-0",
    B: "1573",
  },
  {
    W: "1464",
    T: "300+3",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nxd4 5. Qxd4 e6 6. Bb5 Ne7 7. O-O a6 8. Ba4 b5 9. Bb3 Nc6 10. Qc3 Bb7 11. Re1 Bb4 12. Qxg7 Rf8 13. c3 Be7 14. Qxh7 Ne5 15. Be3 Nd3 16. Re2 Bc5 17. f3 Bxe3+ 18. Rxe3 Nxb2 19. e5 Qb6 20. Kf2 a5 21. Qc2 Nc4 22. Bxc4 bxc4 23. Nd2 Rh8 24. Nxc4 Qc5 25. Qd3 Bd5 26. Nd6+ Ke7 27. c4 Bc6 28. Rb1 Rxh2 29. Kg3 Rh6 30. Kf2 Bxf3 31. gxf3 Rh2+ 32. Kg3 Rah8 33. Re2 R8h3+ 34. Kf4 Rh4+ 35. Kg5 Rh5+ 36. Kg4 R5h4+ 37. Kg5 f6+ 0-1",
    B: "1455",
  },
  {
    W: "1386",
    T: "300+3",
    P: "1. b4 e5 2. Bb2 d6 3. a3 f5 4. Nc3 d5 5. e3 d4 6. exd4 exd4 7. Nce2 d3 8. cxd3 Qxd3 9. Nf4 Qe4+ 10. Nfe2 Nc6 11. d3 Qe7 12. Nf3 b6 13. g3 Bb7 14. Bg2 O-O-O 15. Nd4 Nxd4 16. Bxb7+ Kxb7 17. Rc1 Nxe2 18. Qxe2 Qxe2+ 19. Kxe2 Nf6 20. Bxf6 gxf6 21. f4 Bd6 22. Kf3 Rhe8 23. Rc4 c5 24. b5 a6 25. a4 axb5 26. axb5 Bb8 27. Rd1 Re7 28. h4 Red7 29. Ke2 h5 30. Kd2 Rxd3+ 0-1",
    B: "1373",
  },
  {
    W: "1244",
    T: "300+3",
    P: "1. e4 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qd1 Nf6 5. Nc3 e5 6. Bb5 Qc7 7. Nf3 Bc5 8. Bxc6 Qxc6 9. Nxe5 Qe6 10. Bf4 a6 11. O-O O-O 12. a3 Bd6 13. Nxd7 Bxd7 14. Qxd6 h6 15. Qxe6 Bxe6 16. e5 Nd7 17. Rad1 f6 18. Rd6 Rae8 19. Re1 Bf5 20. Nd5 fxe5 0-1",
    B: "1259",
  },
  {
    W: "1527",
    T: "300+3",
    P: "1. c4 e5 2. Nc3 Bb4 3. d4 exd4 4. Qxd4 Qf6 5. Qxf6 Nxf6 6. Bd2 Ng4 7. a3 Bc5 8. e3 d6 9. b4 Bb6 10. g3 c5 11. b5 O-O 12. a4 a5 13. Bg2 Bd8 14. Nge2 Ra7 15. h3 Ne5 16. O-O b6 17. Nd5 Bb7 18. Nef4 Nxc4 19. Bc3 Nd7 20. Nh5 f6 21. e4 Nde5 22. Bxe5 fxe5 23. Rfc1 Nd2 24. Rc2 Nf3+ 25. Bxf3 Rxf3 26. Kg2 Rf8 27. g4 Bxd5 28. exd5 Raf7 29. Ng3 Bh4 30. Rf1 e4 31. Nxe4 Rf4 32. Nxd6 Rxa4 33. Nc4 Rf4 34. Nxb6 Rab4 35. Rxc5 a4 36. d6 Rfd4 37. d7 Kf7 38. Re1 a3 39. Nc4 Rbxc4 40. Rxc4 Rxc4 41. Ra1 Rd4 42. Rxa3 Rxd7 43. Rb3 Rb7 44. f4 g5 45. f5 Be1 46. Kf3 Ke7 47. Ke4 Kd6 48. Kd4 Bf2+ 49. Kc4 Rc7+ 50. Kd3 Rb7 51. Ke4 Kc5 52. Ke5 Kc4 53. Rb2 Bd4+ 54. Ke6 Bxb2 55. Kd6 Rxb5 0-1",
    B: "1497",
  },
  {
    W: "1362",
    T: "300+3",
    P: "1. e4 c6 2. Nf3 d5 3. Qe2 Bg4 4. g3 Nf6 5. Bg2 dxe4 6. Nh4 Bxe2 0-1",
    B: "1373",
  },
  {
    W: "1934",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 d5 3. exd5 e4 4. Bb5+ c6 5. dxc6 bxc6 6. Bc4 exf3 7. Qxf3 Nf6 8. O-O Be7 9. Re1 O-O 10. d3 Bg4 11. Qg3 Bd6 12. Qh4 Re8 13. Be3 Nbd7 14. Nd2 Ne5 15. Bb3 Ng6 16. Qg5 Bd7 17. Nf3 Rb8 18. Bxa7 Rb5 19. Rxe8+ Bxe8 20. Qd2 Bf4 21. Be3 Qc7 22. Re1 Rh5 23. g3 Bd6 24. Bd4 Bd7 25. Bxf6 gxf6 26. Qc3 Kg7 27. Re4 Ne5 28. Nh4 Rxh4 29. gxh4 Nf3+ 30. Kg2 Nxh2 31. f4 Ng4 32. Qe1 Bf5 33. Qg3 h5 34. Rc4 c5 0-1",
    B: "2137",
  },
  {
    W: "1727",
    T: "1500+3",
    P: "1. d4 g6 2. e4 Bg7 3. Be3 d6 4. Be2 a6 5. Bf3 Nd7 6. Ne2 Rb8 7. Nbc3 b5 8. a3 Bb7 9. O-O e6 10. b4 Nb6 11. Nf4 e5 12. dxe5 Bxe5 13. Nfd5 Nc4 14. Qc1 Bxd5 15. Nxd5 Bxa1 16. Qxa1 Nf6 17. Nxf6+ Kf8 18. Nd7+ Qxd7 19. Qxh8+ Ke7 20. Qxb8 Nxe3 21. fxe3 1-0",
    B: "1554",
  },
  {
    W: "1733",
    T: "180+2",
    P: "1. b3 d5 2. Bb2 Nf6 3. e3 c5 4. Nf3 Nc6 5. Bb5 Bd7 6. O-O e6 7. d3 Be7 8. Nbd2 O-O 9. Bxc6 Bxc6 10. Ne5 Qc7 11. f4 Nd7 12. Rf3 Nxe5 13. Bxe5 Bd6 14. d4 cxd4 15. exd4 Bxe5 16. fxe5 Qe7 17. Rh3 Qg5 18. Nf3 Qe3+ 19. Kh1 Qe4 20. Qd2 f5 21. Ng5 Qg4 22. Nxe6 Rf7 23. Rf1 Bd7 24. Rf4 Qg6 25. Nc5 Bb5 26. Qf2 b6 27. Nd3 Bxd3 28. cxd3 Rc8 29. Qd2 Qg5 30. Rg3 Qe7 31. Rgf3 g6 32. g4 Qa3 33. gxf5 Rc1+ 34. Rf1 Rxf1+ 35. Rxf1 Rxf5 36. Rxf5 gxf5 37. Qg5+ Kh8 38. Qf6+ Kg8 39. Qe6+ Kg7 40. Qf6+ Kg8 41. Qe6+ Kg7 42. Qxf5 Qc1+ 43. Kg2 Qd2+ 44. Qf2 Qxd3 45. Qg3+ 1-0",
    B: "1685",
  },
  {
    W: "1699",
    T: "60+0",
    P: "1. b3 c5 2. Bb2 Nf6 3. Nf3 e6 4. e3 d5 5. Be2 Be7 6. O-O Nc6 7. d4 O-O 8. c4 cxd4 9. Nxd4 a6 10. Nc3 e5 11. Nxc6 bxc6 12. Qc2 Bb7 13. Rfd1 Rc8 14. a3 Qb6 15. b4 d4 16. c5 Qa7 17. exd4 a5 18. dxe5 Nd5 19. Ne4 Qb8 20. Nd6 Bxd6 21. exd6 axb4 22. Qf5 bxa3 23. Rxa3 Rce8 24. Bd3 g6 25. Qh3 Bc8 26. Qg3 Be6 27. Rb1 Qb4 28. Ra7 Qxc5 29. Qh4 1-0",
    B: "1817",
  },
  {
    W: "1912",
    T: "60+0",
    P: "1. b3 d5 2. Bb2 Nf6 3. e3 Nc6 4. Nf3 a5 5. Ne5 a4 6. Bb5 Bd7 7. Nxd7 Qxd7 8. Bxa4 Rxa4 9. bxa4 Na5 10. O-O Qxa4 11. d3 Qd7 12. Nd2 e5 13. Nf3 Bd6 14. Nxe5 Bxe5 15. Bxe5 O-O 16. Qd2 Nc6 17. Bg3 h5 18. Rab1 b6 19. a4 h4 20. Bxh4 Qg4 21. Bg3 Nh5 22. c4 dxc4 23. dxc4 Ne7 24. c5 bxc5 25. a5 Nf5 26. a6 Nhxg3 27. hxg3 g6 28. a7 Kg7 29. Rb8 Qe4 30. Rxf8 Kxf8 31. Ra1 Qa8 32. Qa5 Kg7 33. Qxc5 Nd6 34. Qe5+ f6 35. Qe7+ Kh6 36. Qxc7 Nf5 37. Rb1 Qd5 38. Rb8 Qd1+ 39. Kh2 Qh5+ 40. Kg1 Qd1+ 41. Kh2 Qh5+ 42. Kg1 Qd1+ 1/2-1/2",
    B: "1886",
  },
  {
    W: "1397",
    T: "60+0",
    P: "1. e4 c5 2. Bc4 d6 3. Qf3 Nf6 4. Qb3 e6 5. Bb5+ Bd7 6. Bxd7+ Qxd7 7. Nf3 Be7 8. e5 dxe5 9. Nxe5 Qd6 10. O-O b6 11. Re1 Nc6 12. Qf3 Nxe5 13. Qxa8+ Kd7 14. Qxh8 Bf8 15. d3 Ng6 16. Qxf8 Qxf8 17. Bg5 h6 18. Bxf6 gxf6 19. Nc3 a6 20. Ne4 Qe7 21. Re2 Ne5 22. Rae1 f5 23. Ng3 f6 24. Nxf5 exf5 25. g4 Qg7 26. Kf1 fxg4 0-1",
    B: "1714",
  },
  {
    W: "1822",
    T: "60+0",
    P: "1. e4 d5 2. e5 e6 3. d4 f6 4. f4 fxe5 5. fxe5 Qh4+ 6. g3 Qe4+ 7. Be3 Qxh1 8. Bb5+ c6 9. Ba4 Qe4 10. Qe2 Be7 11. Qf2 h6 12. c3 Bg5 13. Nd2 Qxe3+ 14. Qxe3 Bxe3 15. Ndf3 Bxg1 16. Nxg1 Na6 17. O-O-O Nc7 18. Rf1 b5 19. Bc2 Ne7 20. Nf3 O-O 21. Nh4 g5 0-1",
    B: "1822",
  },
  {
    W: "2173",
    T: "60+0",
    P: "1. e4 d6 2. d4 g6 3. Nc3 Bg7 4. Be3 Nc6 5. f3 e5 6. d5 Nce7 7. Qd2 f5 8. O-O-O Nf6 9. Kb1 Nxe4 10. fxe4 fxe4 11. Nxe4 Nf5 12. Bg5 Ne7 13. Nf6+ Kf7 14. Ng4 Bxg4 15. Be2 Bxe2 16. Nxe2 h6 17. Rhf1+ Kg8 18. Bf6 Bxf6 19. Rxf6 Kg7 20. Rf2 Rf8 21. Rdf1 Rxf2 22. Rxf2 Nf5 23. g4 Qh4 24. Qe1 Qxg4 25. Ng3 Nxg3 26. h3 Qg5 27. h4 Qxh4 28. a3 Qg5 29. Rg2 Nf5 30. Rxg5 hxg5 31. Qg1 Kf6 32. c4 Rg8 33. b3 Rg7 34. Kb2 Rh7 35. Kc3 Rh3+ 36. Kb4 Rf3 37. Qg2 g4 38. Qxg4 Rf4 39. Qg1 c5+ 40. dxc6 bxc6 41. Ka5 c5 42. Ka6 Ne7 43. Kxa7 Rf3 44. Kb7 Rxb3+ 45. Kc7 Rxa3 46. Kxd6 Rf3 47. Qg2 Rf4 48. Qg3 1-0",
    B: "2186",
  },
  {
    W: "1334",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Qf3 Nc6 4. Qxd5 Nf6 5. Qb5 Nd5 6. Qxd5 Bd7 7. Bc4 O-O-O 8. Qxf7 Bg4 9. f3 Bxf3 10. Nxf3 e5 11. d3 Bc5 12. Qe6+ Rd7 13. Nxe5 Kd8 14. Qxd7# 1-0",
    B: "1282",
  },
  {
    W: "1820",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d3 Nf6 4. Nc3 Be7 5. d4 Kf8 6. dxe5 1-0",
    B: "1768",
  },
  {
    W: "1844",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Nf6 3. Nf3 Nxd5 4. Nc3 Bg4 5. Be2 Nxc3 6. bxc3 Nc6 7. d4 g6 8. Ba3 Bg7 9. O-O O-O 10. h3 Bxf3 11. Bxf3 Re8 12. Re1 e6 13. Bxc6 bxc6 14. Qf3 Qd7 15. Rab1 Rad8 16. Rb7 a5 17. Ra7 Ra8 18. Rxa8 Rxa8 19. Rb1 a4 20. Rb4 Bf8 21. Rb7 Bxa3 22. Qg3 Bd6 23. Qf3 Qe7 24. Qe2 Qd8 25. Rb1 Rb8 26. Re1 Rb2 27. Qd3 a3 28. Qa6 Rxa2 29. Qxc6 Rxc2 30. Qa4 Rb2 31. Ra1 Qb8 32. g3 Rb1+ 33. Rxb1 Qxb1+ 34. Kg2 a2 35. Qe8+ Kg7 36. Qe7 0-1",
    B: "1847",
  },
  {
    W: "1156",
    T: "60+0",
    P: "1. e4 e5 2. d4 Nf6 3. d5 d6 4. Nc3 c6 5. dxc6 Nxc6 6. Nf3 Be7 7. Bb5 O-O 8. O-O a6 9. Ba4 b5 10. Bb3 Be6 11. Bxe6 fxe6 12. Bg5 Nd4 13. Nxd4 exd4 14. Ne2 Nxe4 15. Bxe7 Qxe7 16. Nxd4 e5 17. Nc6 Qc7 18. Nb4 a5 19. Nd3 Nc5 20. Nxc5 dxc5 21. c3 Rae8 22. Re1 c4 23. a3 Qf7 24. Qg4 Qxf2+ 25. Kh1 g6 26. Re2 Qc5 27. Rae1 h5 28. Qxg6+ Kh8 29. Qxh5+ Kg7 30. h3 Rf2 31. Rxe5 1-0",
    B: "1005",
  },
  {
    W: "1208",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 f6 3. Bc4 g6 4. d3 Bg7 5. Nc3 e6 6. Be3 d6 7. O-O a6 8. Na4 Ne7 9. d4 O-O 10. dxc5 dxc5 11. Bxc5 Qxd1 12. Rfxd1 b5 13. Nb6 Ra7 14. Nxc8 Rxc8 15. Bxe6+ Kh8 16. Bxc8 Nxc8 17. Rd8+ Bf8 18. Rxc8 Kg7 19. Bxf8+ Kf7 20. Rxb8 Rd7 21. Re1 Rd2 22. Nxd2 1-0",
    B: "1230",
  },
  {
    W: "2783",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. h3 e5 7. Nde2 h5 8. Bg5 Be7 9. Ng3 Nc6 10. Nf5 Bxf5 11. exf5 Qb6 12. Qd2 Nd4 13. O-O-O O-O 14. g4 Rac8 15. Bg2 Qa5 16. Kb1 Rxc3 17. Qxc3 Qxc3 18. bxc3 Nb5 19. Kb2 Rd8 20. Rd3 e4 21. Rd2 hxg4 22. hxg4 d5 23. Rhd1 Ba3+ 24. Kb3 Bc5 25. c4 Nc7 26. Bxf6 gxf6 27. cxd5 e3 28. fxe3 Bxe3 29. Rd3 Bc5 30. c4 Rd6 31. Re1 Kg7 32. Kc2 Na8 33. Rh3 Nb6 34. Kb3 Bd4 35. Reh1 Nd7 36. Rh8 Nc5+ 37. Kc2 1-0",
    B: "2743",
  },
  {
    W: "1344",
    T: "900+8",
    P: "1. e4 c5 2. f4 d5 3. e5 d4 4. Nf3 e6 5. Bc4 a6 6. O-O b5 7. Be2 Bb7 8. d3 Ne7 9. Nbd2 Nf5 10. Ne4 Nc6 11. c4 bxc4 12. g4 cxd3 13. Bxd3 Nh4 14. a3 Nxf3+ 15. Qxf3 Be7 16. Bd2 Na5 17. Bxa5 Qxa5 18. Nf6+ gxf6 19. Qxb7 Rd8 20. Bxa6 Rg8 21. Bb5+ Kf8 22. Be2 f5 23. g5 h6 24. Rf2 hxg5 25. fxg5 Rxg5+ 26. Rg2 Qd2 27. Kf1 d3 28. Rd1 Qf4+ 29. Bf3 Rxg2 30. Kxg2 Qxe5 31. Qb5 Rb8 32. Qxd3 Rxb2+ 33. Kh3 Qh8+ 34. Kg3 Qxh2# 0-1",
    B: "1731",
  },
  {
    W: "1919",
    T: "5400+0",
    P: "1. d4 Nf6 2. Nf3 g6 3. d5 d6 4. Nc3 Bg7 5. e4 O-O 6. Bd3 c5 7. O-O Na6 8. Bxa6 bxa6 9. Rb1 Rb8 10. Bg5 Re8 11. Qd3 Nd7 12. Bf4 Qa5 13. Nd2 Bxc3 14. Nc4 Qb4 15. a3 Qb5 16. Qxc3 Nb6 17. Bxd6 Rb7 18. Nxb6 axb6 19. Bf4 Qd7 20. b4 Qg4 21. Bh6 f6 22. bxc5 Qxe4 23. f4 Qxd5 24. Bg5 fxg5 25. cxb6 gxf4 26. Rxf4 Be6 27. Rd4 Qe5 28. a4 Rc8 29. Rd8+ Rxd8 30. Qxe5 Bf5 31. Qe2 Rdb8 32. a5 1-0",
    B: "1966",
  },
  {
    W: "1924",
    T: "300+0",
    P: "1. c4 e5 2. Nc3 Nf6 3. g3 c6 4. Bg2 d6 5. d3 Bf5 6. e4 Bg6 7. Nge2 Be7 8. O-O Nbd7 9. h3 a6 10. b4 O-O 11. a4 b5 12. axb5 axb5 13. Rxa8 Qxa8 14. cxb5 cxb5 15. Nxb5 Qb7 16. Nec3 Nb6 17. Be3 h5 18. Qb3 Rb8 19. f4 h4 20. f5 Bh7 21. g4 Nfd7 22. d4 Nc8 23. g5 g6 24. f6 Bf8 25. Rb1 exd4 26. Bxd4 Ne5 27. Qd5 Nb6 28. Bxb6 Qxd5 29. Nxd5 Rb7 30. Nd4 Nd3 31. Rb3 Ne5 32. b5 Kh8 33. Rc3 Bg8 34. Rc7 Rb8 35. Ba7 Ra8 36. b6 Nd3 37. Nb5 Nc5 38. b7 Nxb7 39. Rxb7 Kh7 40. Nbc7 Rc8 41. Bf1 Kh8 42. Bf2 Kh7 43. Bxh4 Kh8 44. Bg3 Rd8 45. h4 Bh7 46. Bh3 Bg8 47. Nb4 Bh7 48. Nc6 Ra8 49. Nxa8 d5 50. exd5 Bc5+ 51. Kg2 Bg8 52. d6 Kh7 53. d7 Bd4 54. d8=Q Bb2 55. Rb8 Bxf6 56. Qxg8# 1-0",
    B: "1879",
  },
  {
    W: "1738",
    T: "300+0",
    P: "1. Nc3 g6 2. f3 Bg7 3. b3 d5 4. g3 e5 5. Bg2 Nf6 6. e4 d4 7. Nb5 a6 8. Na3 b5 9. c4 b4 10. Nc2 d3 11. Ne3 Nc6 12. Nd5 O-O 13. Bf1 Nxd5 14. cxd5 Nd4 15. Bxd3 c6 16. dxc6 Nxc6 17. Bb2 Nd4 18. Bxd4 Qxd4 19. Bc4 Bg4 20. Ne2 Bxf3 21. Nxd4 Bxd1 22. Ne2 Bxe2 23. Bxe2 Rac8 24. Bxa6 Rc5 25. O-O Bh6 26. d3 Be3+ 27. Kg2 Rd8 28. Bc4 Rc6 29. Bxf7+ Kg7 30. Bc4 h5 31. Rf7+ Kh6 32. Raf1 Kg5 33. h4+ Kh6 34. R1f6 Rxf6 35. Rxf6 Ra8 36. Bf7 Rxa2+ 37. Kh3 Bg1 38. g4 hxg4+ 39. Kxg4 Rg2+ 40. Kh3 Rd2 41. Rxg6+ Kh7 42. Rxg1 Rxd3+ 43. Rg3 Rxb3 44. Bxb3 Kh6 45. Rg5 1-0",
    B: "1576",
  },
  {
    W: "1218",
    T: "300+0",
    P: "1. d4 e6 2. Nf3 d5 3. Bg5 Be7 4. Bxe7 Nxe7 5. c3 c5 6. Nbd2 Nbc6 7. e4 cxd4 8. cxd4 Qb6 9. exd5 exd5 10. Ne5 Qxb2 11. a4 Nxe5 12. dxe5 Qxe5+ 13. Be2 Nf5 14. Nf3 Qc3+ 15. Kf1 O-O 16. Kg1 Nd6 17. Rc1 Qb2 18. h3 Ne4 19. Rc2 Qa3 20. Kh2 Nxf2 21. Qxd5 Nxh1 22. Kxh1 Qa1+ 23. Ng1 Qb1 24. Bd3 g6 25. Rf2 Be6 26. Qe4 Qb6 27. Rf6 Bf5 28. Qe5 Qd8 29. Bxf5 gxf5 30. Qxf5 Kg7 31. Qg5+ Kh8 32. Qg3 Qxf6 33. Nf3 Qa1+ 34. Kh2 Rg8 35. Qf4 Qa2 36. Nh4 Rac8 37. Qxf7 Qxf7 38. g3 Qf2+ 39. Ng2 Rc1 40. h4 Qg1+ 41. Kh3 Qh1# 0-1",
    B: "1392",
  },
  {
    W: "1645",
    T: "30+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. g3 g6 4. f4 c6 5. b3 Bg7 6. Bb2 O-O 7. d3 d6 8. c4 Nc7 9. Qd2 dxe5 10. Qc3 f6 11. h4 h5 12. g4 Rf7 13. gxh5 gxh5 14. Nd2 Kf8 15. Rd1 Bg4 16. Be2 Bxe2 17. Nxe2 exf4 18. Nd4 f3 19. Ne6+ Nxe6 20. c5 Nxc5 21. Qc4 Ne6 0-1",
    B: "1890",
  },
  {
    W: "1998",
    T: "300+0",
    P: "1. e4 g6 2. d4 Bg7 3. e5 d6 4. Nf3 dxe5 5. Nxe5 Bxe5 6. dxe5 Qxd1+ 7. Kxd1 Nc6 8. f4 Bg4+ 9. Be2 O-O-O+ 10. Bd2 Nh6 11. Nc3 Rd7 12. Kc1 Rhd8 13. Be3 Nd4 14. Bd3 a6 15. h3 Bf5 16. g4 Bxd3 17. cxd3 Nc6 18. f5 Rxd3 19. Bxh6 Nxe5 20. Rd1 Rxh3 21. Rxd8+ Kxd8 22. Bf4 Nd3+ 23. Kc2 Nxf4 24. Rd1+ Ke8 25. fxg6 hxg6 26. Re1 g5 27. Ne4 Ne6 28. a3 Rh2+ 29. Kb3 Rg2 30. Rc1 Rxg4 31. Nc5 Nxc5+ 32. Rxc5 c6 33. Rc1 f5 34. Rf1 f4 35. Kc3 Kf7 36. Kd3 Kf6 37. Ke4 e5 38. b4 Rg3 39. a4 Re3# 0-1",
    B: "2049",
  },
  {
    W: "1881",
    T: "480+0",
    P: "1. e4 c5 2. Bc4 e6 3. c3 a6 4. a4 d5 5. exd5 exd5 6. Bb3 Nf6 7. h3 Nc6 8. Nf3 d4 9. O-O d3 10. Re1+ Be7 11. Ne5 O-O 12. Qf3 Nxe5 13. Rxe5 Re8 14. Re3 Qc7 15. Rxd3 Bd6 16. Re3 Rxe3 17. dxe3 Bd7 18. e4 Re8 19. Bd5 Nxe4 20. Bxe4 Bc6 21. Nd2 f5 22. Bxc6 Re1+ 23. Nf1 bxc6 24. g3 Qd7 25. Kg2 c4 26. Ne3 c5 27. Qxf5 Qc6+ 28. Qf3 Rg1+ 29. Kxg1 Qxf3 30. Nxc4 Qd1+ 31. Kg2 Qd5+ 32. Kg1 Qxc4 33. Be3 h6 34. Rd1 Be5 35. f4 Bf6 36. Kf2 Qxa4 37. Rd5 c4 38. Rd2 Qb5 39. Bd4 Bxd4+ 40. cxd4 a5 41. d5 Qc5+ 42. Kf3 c3 43. Rc2 Qxd5+ 44. Kf2 cxb2 45. Rxb2 Qd4+ 46. Kf3 Qxb2 0-1",
    B: "1951",
  },
  {
    W: "1037",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Qe7 4. d3 Be6 5. Bxe6 Qxe6 6. c3 Qg4 7. O-O Be7 8. h3 Qd7 9. Bg5 Bxg5 10. Nxg5 h6 11. Nf3 Nc6 12. Nbd2 a6 13. Nc4 b5 14. Ne3 Nf6 15. b4 a5 16. a3 axb4 17. axb4 Rxa1 18. Qxa1 O-O 19. Qd1 Rb8 20. Qe2 Qe6 21. Nd1 Qb3 22. Nh4 Ne7 23. Nf5 Nxf5 24. exf5 Re8 25. Qb2 Qd5 26. Qc2 e4 27. dxe4 Nxe4 28. Ne3 Qe5 29. f3 Ng3 30. Re1 Qf6 31. Ng4 Rxe1+ 32. Kh2 Rh1+ 33. Kxg3 Qg5 34. Qa2 Qxf5 35. Qa8+ Kh7 36. Qc8 Qxc8 0-1",
    B: "1409",
  },
  {
    W: "2191",
    T: "240+0",
    P: "1. e4 e6 2. Qe2 c5 3. Nf3 Nc6 4. g3 d6 5. Bg2 g6 6. O-O Bg7 7. c3 Nge7 8. Na3 a6 9. Nc2 Qb6 10. Rd1 e5 11. d4 exd4 12. cxd4 cxd4 13. Bf4 Be5 14. Nxe5 dxe5 15. Bh6 Rg8 16. Na3 Be6 17. b3 Qb4 18. Nc4 Bxc4 19. bxc4 Na5 20. Rdc1 Nec6 21. Rab1 Qc5 22. Kh1 Rc8 23. f4 f6 24. fxe5 Nxe5 25. Bh3 Rc7 26. Be6 d3 27. Qg2 Rgg7 28. Bxg7 Rxg7 29. Rf1 Ke7 30. Bd5 Ng4 31. Rbe1 Ne3 32. Qf2 1-0",
    B: "2060",
  },
  {
    W: "1634",
    T: "300+0",
    P: "1. d4 d5 2. c4 Nf6 3. Bg5 Nbd7 4. e3 g6 5. Nc3 c6 6. b3 Bg7 7. Rc1 O-O 8. Bd3 a5 9. Nf3 h6 10. Bh4 Re8 11. O-O e5 12. dxe5 Nxe5 13. Nxe5 Rxe5 14. cxd5 cxd5 15. f4 Bg4 16. Bxf6 Bxf6 17. Qxg4 1-0",
    B: "1662",
  },
  {
    W: "1882",
    T: "300+0",
    P: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4 Bxg5 7. hxg5 Qxg5 8. Nf3 Qe7 9. Nb5 Nb6 10. Qd3 h6 11. O-O-O a6 12. Nc3 Nc4 13. Nb1 Qb4 14. Qb3 Qxb3 15. cxb3 Nb6 16. Nc3 Bd7 17. g4 Nc6 18. a3 a5 19. g5 h5 20. Nb5 O-O-O 21. Kb1 Ne7 22. Nc3 Nf5 23. Bd3 g6 24. a4 Kb8 25. Bb5 c6 26. Bd3 Rc8 27. Rc1 c5 28. Bxf5 exf5 29. dxc5 Rxc5 30. Ne2 Rhc8 31. Rc3 Rxc3 32. bxc3 Be6 33. Nfd4 Nd7 34. Kb2 Nxe5 35. Nf4 Re8 36. Re1 Nc6 37. Nxc6+ bxc6 38. Nxe6 fxe6 39. Re5 Kc7 40. c4 Kd6 41. Re1 d4 42. Kc2 c5 43. Kd3 e5 44. f3 Rh8 45. Rh1 h4 46. Rh3 Rh5 47. f4 e4+ 48. Kd2 e3+ 49. Kd3 Rh8 50. Ke2 Ke6 51. Kd3 Kd6 52. Ke2 Re8 53. Kd3 e2 54. Rh1 Re3+ 55. Kd2 Rxb3 56. Kxe2 h3 57. Kd2 Rb4 58. Rxh3 Rxc4 59. Rd3 Rxa4 60. Ke2 Ra2+ 61. Ke1 a4 62. Kd1 Rb2 63. Kc1 Rb3 64. Rxb3 axb3 65. Kb2 c4 0-1",
    B: "1879",
  },
  {
    W: "1208",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O Nf6 5. Re1 O-O 6. c3 d6 7. d4 Bg4 8. dxc5 Bxf3 9. Qxf3 dxc5 10. Rd1 Qe7 11. Bg5 Rfd8 12. Na3 Rxd1+ 13. Rxd1 Rd8 14. Rxd8+ Qxd8 15. Bd5 Ne7 16. Bxb7 h6 17. Bh4 Qd2 18. h3 Qe1+ 19. Kh2 Qxe4 20. Bxe4 Nxe4 21. Qxe4 1-0",
    B: "1151",
  },
  {
    W: "2011",
    T: "300+0",
    P: "1. d4 Nf6 2. Nf3 d5 3. Bf4 Bf5 4. Nbd2 e6 5. e3 Nbd7 6. c3 c5 7. Ne5 Qb6 8. b3 Rc8 9. Be2 Be7 10. O-O O-O 11. Ndf3 Nxe5 12. Nxe5 Ne4 13. Nd7 Qa5 14. Nxf8 Kxf8 15. Rc1 Nxc3 16. Qd2 Nxe2+ 17. Qxe2 b6 18. Qb2 g5 19. Bg3 h5 20. h3 Bf6 21. Be5 Be7 22. dxc5 bxc5 23. Bf6 Qd8 24. Bxe7+ Kxe7 25. Qg7 Qg8 26. Qxg8 Rxg8 27. Rxc5 Kd6 28. Rfc1 Ke5 29. f3 g4 30. hxg4 hxg4 31. Kf2 gxf3 32. gxf3 a6 33. Rg1 Rb8 34. Rc6 a5 35. Ra6 Rb5 36. Rc1 Bd3 37. Ra7 f5 38. Rc3 Bb1 39. Rc6 Bxa2 40. Rca6 Bxb3 41. Rxa5 Rb6 0-1",
    B: "1930",
  },
  {
    W: "1803",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be2 e5 7. Nb3 Be7 8. O-O O-O 9. f4 Nc6 10. Bf3 Be6 11. Be3 b5 12. a3 Rc8 13. Qe1 exf4 14. Bxf4 Ne5 15. Qg3 Ng6 16. Be3 Re8 17. Rad1 Qc7 18. Nd4 Bc4 19. Rf2 Bf8 20. Nf5 Ne5 21. Rfd2 Be6 22. Nxd6 Bxd6 23. Rxd6 Nc4 24. Bf4 Nxd6 25. Bxd6 Qb6+ 26. Kh1 Red8 27. Be5 Rxd1+ 28. Bxd1 Kf8 29. h3 Rd8 30. Bf3 Qe3 31. Bd6+ Kg8 32. Be7 Qc1+ 33. Kh2 Re8 34. Bxf6 g6 35. e5 Qxc2 36. Qg5 1-0",
    B: "1749",
  },
  {
    W: "1742",
    T: "300+0",
    P: "1. d4 d5 2. e3 Bf5 3. Bd3 Bg6 4. f4 e6 5. g4 h6 6. f5 exf5 7. gxf5 Qh4+ 8. Ke2 Bh5+ 9. Nf3 Qh3 10. Qf1 Nf6 11. Qxh3 1-0",
    B: "1730",
  },
  {
    W: "1347",
    T: "300+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 Qxd5 4. Nc3 Qd6 5. d4 Bd7 6. Be2 Nf6 7. O-O Nd5 8. h4 Nxc3 9. bxc3 Nc6 10. Bg5 Be7 11. Qd2 Bxg5 12. Qxg5 g6 13. h5 Qe7 14. Rab1 Qxg5 15. Nxg5 gxh5 16. Bxh5 O-O-O 17. Nxf7 Ne7 18. Nxd8 Kxd8 19. Rxb7 Bc8 20. Rxa7 Nd5 21. c4 Nc3 22. a4 Re8 23. Bxe8 Kxe8 24. Rxc7 Kd8 25. Rxh7 Ne2+ 26. Kh1 Nxd4 27. Rh8+ Kc7 28. Ra1 Nxc2 29. Ra2 Nb4 30. Rh7+ Bd7 31. Rb2 Nc6 32. g4 Kd6 33. g5 Ne5 34. a5 Nxc4 35. Ra2 Be8 36. a6 Ne5 37. a7 Bc6+ 38. Kh2 Nf3+ 39. Kg3 Nxg5 40. Rh8 Ne4+ 41. Kg2 Nc3+ 42. Kf1 Nxa2 43. a8=Q Bxa8 44. Rxa8 Nc3 45. Rd8+ Ke5 46. Rf8 Ne4 47. Ke2 Nc3+ 48. Ke3 Nd1+ 49. Ke2 Nc3+ 50. Kd2 Ne4+ 51. Ke3 Nc3 52. f4+ Kd6 53. Rd8+ Ke7 54. Rh8 Nd5+ 55. Ke4 Nxf4 56. Kxf4 Kd6 57. Rh6 Kd5 58. Rh5+ Kd6 59. Ke4 Kd7 60. Ke5 Ke7 61. Rh6 Kd7 62. Rxe6 Kc7 63. Kd5 Kd7 64. Rf6 Ke7 65. Rc6 Kd7 66. Rd6+ Ke7 67. Rb6 Kf7 68. Kd6 Kf6 69. Kd5+ Kf5 70. Kd4 Kf4 71. Re6 Kf5 72. Re5+ Kf4 73. Re8 Kf5 74. Kd5 Kf6 75. Rf8+ Ke7 76. Rd8 Kf6 0-1",
    B: "1358",
  },
  {
    W: "1462",
    T: "180+0",
    P: "1. Nf3 e6 2. d4 d5 3. Nc3 Nf6 4. e4 c6 5. exd5 cxd5 6. Bg5 Bb4 7. Be2 h6 8. Bd2 Nc6 9. O-O O-O 10. a3 Be7 11. Bd3 a5 12. Ne5 Nxe5 13. dxe5 Nd7 14. f4 Qb6+ 15. Kh1 Qxb2 16. Nb5 Nxe5 17. fxe5 Qxe5 18. Bc3 Qg5 19. Rf3 e5 20. Rg3 Qf4 21. Bd2 Qxg3 22. hxg3 e4 23. Be2 Bd7 24. Nc7 Ra6 25. Nxd5 Re6 26. Bg4 Re5 27. Nxe7+ Rxe7 28. Bxd7 Rxd7 29. Qe2 Re8 30. Be3 f5 31. Qc4+ Kh7 32. Rf1 g6 33. g4 fxg4 34. Rf7+ Rxf7 35. Qxf7+ Kh8 36. Bxh6 Rg8 37. Qf6+ Kh7 38. Bg5 Rg7 39. Qf4 Rc7 40. Kg1 Rxc2 41. Qh2+ Kg7 42. Qh6+ Kf7 43. Qh7+ Ke6 44. Qe7+ Kd5 45. Qd7+ Ke5 46. Qe7+ Kd5 47. Qd8+ Ke5 48. Bf4+ Kxf4 49. g3+ Kf3 50. Qf6+ Kxg3 51. Qd4 Rc1+ 0-1",
    B: "1567",
  },
  {
    W: "2040",
    T: "180+0",
    P: "1. d4 Nf6 2. e3 e6 3. c4 b6 4. Nc3 Bb7 5. Nf3 Bb4 6. Be2 O-O 7. O-O Bxc3 8. bxc3 Ne4 9. Qc2 f5 10. Nd2 d6 11. f3 Nxd2 12. Qxd2 Nd7 13. Bd3 e5 14. Ba3 c5 15. Bb2 Qe7 16. Rae1 e4 17. Be2 Rae8 18. d5 Ne5 19. f4 Nf7 20. Bh5 g6 21. Bd1 Bc8 22. Qf2 Nd8 23. h3 Nb7 24. Be2 Na5 25. g3 Ba6 26. Kh2 Qd7 27. g4 Bxc4 28. gxf5 Bxe2 29. Qxe2 Rxf5 30. Rg1 Qa4 31. c4 Qxc4 32. Qc2 Qxc2+ 0-1",
    B: "2255",
  },
  {
    W: "1884",
    T: "180+0",
    P: "1. c4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qd1 Nf6 5. Nf3 Bb4+ 6. Bd2 d5 7. e3 O-O 8. a3 Bxd2+ 9. Nbxd2 Bg4 10. Be2 dxc4 11. Nxc4 Qxd1+ 12. Rxd1 Rad8 13. O-O h6 14. h3 Bh5 15. b4 a6 16. Nb2 Ne4 17. g4 Bg6 18. Rc1 Nd2 19. Nxd2 Rxd2 20. Bxa6 bxa6 21. Na4 Rd6 22. f4 Be4 23. Rc4 Bd3 24. Rfc1 Bxc4 25. Rxc4 Rfd8 26. Kf2 Rd2+ 27. Kf3 R2d6 28. Nc5 a5 29. Nb7 axb4 30. axb4 Kf8 31. b5 Na5 32. Nxa5 Rb6 33. Rxc7 Rxb5 34. Nc4 g6 35. Ne5 Rxe5 36. fxe5 Ra8 37. h4 Ra4 38. e4 Ke8 39. Rb7 Ra1 40. Kg3 Re1 41. Kf3 Rf1+ 42. Kg3 Ra1 43. Kf3 Ra3+ 44. Kf4 Ra1 45. Kg3 Rc1 46. Kf3 Rc3+ 47. Kf4 Rc5 48. g5 hxg5+ 49. hxg5 Rc1 50. Kg4 Rd1 51. Kf4 Rd2 52. Kg4 Re2 53. Kf4 Rg2 54. Kf3 Rxg5 55. Kf4 Rg1 56. Kf3 Kf8 57. Kf4 Rc1 58. Kf3 Rb1 59. Kf4 Rxb7 60. Kf3 Kg7 61. Kf4 Kh6 62. Kf3 Kg5 63. Ke3 Kg4 64. Kd4 Kf4 65. Kd5 Rb5+ 66. Kd6 Rxe5 0-1",
    B: "2083",
  },
  {
    W: "2059",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. c3 Nf6 4. d3 Nc6 5. Be2 g6 6. Be3 Bg7 7. Qd2 O-O 8. Bh6 Bh8 9. Bxf8 Qxf8 10. O-O Bd7 11. Na3 a6 12. Nc4 b5 13. Nb6 Rd8 14. Nxd7 Rxd7 15. Rae1 Rb7 16. h3 b4 17. Nh2 bxc3 18. bxc3 Nd7 19. Rb1 Qb8 20. Qc2 Rxb1 21. Rxb1 Qc7 22. Ng4 h5 23. Nh6+ Kg7 24. Qd2 Nd8 25. g4 h4 26. g5 Kf8 27. Bf3 Ne5 28. Bg2 Ne6 29. Ng4 Nc6 30. Qb2 Bg7 31. Qb3 Na5 32. Qb8+ Qd8 33. Qxd8+ Nxd8 34. Rb8 Ke8 35. c4 Bd4 36. Kf1 Nc6 37. Ra8 a5 38. f4 Kd7 39. f5 Nb4 40. fxg6 fxg6 41. Rxa5 Nxd3 42. Ra7+ Kc8 43. e5 Nxe5 44. Nxe5 Bxe5 45. Ra8+ Kd7 46. Ke2 Ne6 47. Bd5 Nf4+ 48. Kf3 Nxh3 49. Kg4 Nf2+ 50. Kf3 Nd3 51. Ra7+ Ke8 52. Be6 Kf8 53. Ra8+ Kg7 54. Rg8+ Kh7 55. Rh8+ 1-0",
    B: "1958",
  },
  {
    W: "1899",
    T: "180+0",
    P: "1. d4 Nf6 2. e3 e6 3. Be2 b6 4. c3 Bb7 5. Nd2 Be7 6. Bf3 O-O 7. Bxb7 Nc6 8. Bxa8 Qxa8 9. Qf3 d5 10. h4 Qb7 11. g4 Qa6 12. g5 Nd7 13. c4 Nb4 14. Qd1 Nd3+ 15. Ke2 Nxb2 16. Bxb2 dxc4 17. Bc3 c5 18. Ke1 b5 19. Qc2 Rc8 20. Ngf3 b4 21. Bxb4 cxb4 22. Ne5 Nb6 23. Rc1 c3 24. Nb3 Qb5 25. Qd3 Qd5 26. e4 Qd8 27. Rg1 f6 28. gxf6 Bxf6 29. Nf3 Nc4 30. d5 Nb2 31. Qb5 Rb8 32. Qa6 Rb6 33. Qe2 exd5 34. exd5 Rb7 35. Kf1 Re7 36. Qc2 Qxd5 37. Kg2 Bxh4 38. Rgd1 Qh5 39. Rd8+ Kf7 40. Re1 Qg4+ 41. Kf1 Qh3+ 42. Kg1 Qxf3 43. Rxe7+ Kxe7 0-1",
    B: "1919",
  },
  {
    W: "1869",
    T: "180+0",
    P: "1. e4 c5 2. f4 d6 3. Nf3 Nc6 4. Bc4 e6 5. O-O a6 6. c3 b5 7. Bb3 c4 8. Bc2 Be7 9. b3 d5 10. exd5 exd5 11. Re1 Nf6 12. Ng5 h6 13. Nf3 O-O 14. d3 Be6 15. dxc4 dxc4 16. Be3 cxb3 17. axb3 Qxd1 18. Rxd1 Rfd8 19. Nbd2 Nd5 20. Nd4 Bc5 21. Nf1 Nxd4 22. Bxd4 Bxd4+ 23. Rxd4 Nxc3 24. Rxd8+ Rxd8 25. Rxa6 Ne2+ 26. Kf2 Nxf4 27. g3 Nd3+ 28. Ke3 Nb4 29. Rxe6 fxe6 30. Be4 Kf7 31. Nd2 Nd5+ 32. Bxd5 Rxd5 33. Nf3 g5 34. h3 Rc5 35. Kd4 Rc1 36. Ne5+ Kf6 37. Ng4+ Kf5 38. Ne3+ Kg6 39. Ng4 Rh1 40. Nf2 Rh2 41. Ne4 Rxh3 42. Ke5 Rh1 43. Kxe6 Rb1 44. Nc5 h5 45. Kd5 h4 46. gxh4 gxh4 47. Kc6 h3 48. Ne6 h2 49. Nf4+ Kg5 50. Nh3+ Kg4 51. Nf2+ Kg3 52. Nh1+ Kg2 53. Kxb5 Kxh1 54. Kc4 Kg2 55. b4 h1=Q 56. b5 Qh4+ 57. Kc5 Qb4+ 58. Kc6 Qxb5+ 59. Kd6 Rc1 60. Ke6 Qb2 61. Kd6 Qd2+ 62. Ke7 Re1+ 0-1",
    B: "1805",
  },
  {
    W: "1407",
    T: "180+0",
    P: "1. d4 d5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bf4 Bg4 5. Nb5 e5 6. dxe5 Bxf3 7. exf3 Nd7 8. e6 Bb4+ 9. c3 Bd6 1-0",
    B: "1421",
  },
  {
    W: "1864",
    T: "180+0",
    P: "1. d4 e5 2. dxe5 Nc6 3. f4 Be7 4. Nf3 h6 5. Nc3 g5 6. fxg5 Bxg5 7. e4 Bxc1 8. Qxc1 Qe7 9. Bd3 Nxe5 10. Kf1 Nxf3 11. gxf3 Nf6 12. Kf2 d6 13. Rg1 Be6 14. Bb5+ c6 15. Ba4 d5 16. exd5 Bxd5 17. Nxd5 Qc5+ 18. Ne3 Nd5 19. Re1 O-O-O 20. b4 Qd4 21. c3 Qb6 22. Bb3 Nxe3 0-1",
    B: "1869",
  },
  {
    W: "1553",
    T: "180+0",
    P: "1. e4 d6 2. d4 Nf6 3. Nc3 Nbd7 4. Bd3 c5 5. d5 g6 6. h3 Bg7 7. a3 O-O 8. Be3 a6 9. Bc4 b6 10. Nf3 Ra7 11. Qe2 b5 12. Ba2 b4 13. axb4 a5 14. bxc5 Nxc5 15. O-O Rc7 16. Bb3 Ncxe4 17. Nxe4 Nxe4 18. Bb6 Bxb2 19. Bxc7 Qxc7 20. Qxe4 Bxa1 21. Rxa1 f5 22. Qe2 Qc3 23. Re1 Re8 24. Ng5 f4 25. Ne6 Bxe6 26. Qxe6+ Kg7 27. Qg4 f3 28. Qf4 fxg2 29. Kxg2 Qf6 30. Qg3 Rf8 31. Re6 Qd4 32. Re3 a4 33. Ba2 Qd2 34. Qf3 Rxf3 35. Rxf3 Qxc2 36. Re3 Qxa2 37. Rxe7+ Kh6 38. Re6 Qxd5+ 39. f3 Qxe6 40. Kg3 a3 41. f4 a2 42. h4 a1=Q 43. Kf3 Qa3+ 0-1",
    B: "1522",
  },
  {
    W: "2118",
    T: "180+0",
    P: "1. Nf3 Nf6 2. g3 g6 3. Bg2 Bg7 4. c3 O-O 5. d4 d6 6. Bg5 Nc6 7. Nbd2 Bg4 8. h3 Bxf3 9. exf3 h6 10. Bxf6 Bxf6 11. f4 Bg7 12. Ne4 Rb8 13. Qf3 e5 14. fxe5 dxe5 15. d5 Ne7 16. O-O-O f5 17. Nc5 e4 18. Qe3 Qd6 19. Ne6 Rf6 20. Nxg7 Kxg7 21. c4 Qa6 22. Qb3 Rb6 23. Qc3+ Kh7 24. Kb1 c6 25. d6 Ng8 26. h4 Rd8 27. h5 g5 28. g4 c5 29. gxf5 Rbxd6 30. Rxd6 Qxd6 31. Bxe4 Nf6 32. Bc2 Ng4 33. f6+ Kg8 34. f7+ Kxf7 35. Bg6+ Kg8 36. Qf3 Ne5 37. Qxb7 Nxg6 38. hxg6 Qxg6+ 39. Ka1 Qh5 40. Rb1 Rd1 41. Qb8+ Kg7 42. Qxa7+ Kg6 43. Qb6+ Kf7 44. Qc7+ Kf6 45. Qc6+ Kg7 46. Qc7+ Kf8 47. Qc8+ Ke7 48. Qc7+ Ke8 49. Qc8+ Rd8 50. Qe6+ Kf8 51. Qf6+ Ke8 52. Re1+ Kd7 53. Re7+ Kc8 54. Qc6+ Kb8 55. Qb7# 1-0",
    B: "2078",
  },
  {
    W: "1701",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 d6 5. Nc3 Nf6 6. Nxc6 bxc6 7. Bd3 Be7 8. O-O O-O 9. Re1 Bb7 10. e5 dxe5 11. Rxe5 Bd6 12. Re1 c5 13. h3 h6 14. Ne4 Nxe4 15. Bxe4 Bxe4 16. Rxe4 Bh2+ 17. Kxh2 Qxd1 18. Re3 Qxc2 19. Re1 Qxf2 20. Be3 Qxb2 21. Bxc5 Rfe8 22. Reb1 Qe2 23. a3 a6 24. Bb4 Rab8 25. Re1 Qb5 26. Rec1 a5 27. Bd2 Re6 28. Rxc7 Qb2 29. Bc3 Qe2 30. Bxa5 Rb2 31. Rc8+ Kh7 32. Rg1 Qe5+ 33. Kh1 Qxa5 34. Rcc1 Qxa3 35. Ra1 Qb3 36. Rad1 Ree2 37. Rdf1 Qxh3+ 38. gxh3 Rh2# 0-1",
    B: "1717",
  },
  {
    W: "1646",
    T: "180+0",
    P: "1. d4 d5 2. Nc3 e6 3. Bf4 c5 4. e3 Nf6 5. Nb5 e5 6. Bxe5 Na6 7. c3 Nd7 8. a4 Nxe5 9. dxe5 c4 10. Nf3 Bc5 11. Nfd4 O-O 12. Qf3 Re8 13. Be2 Rxe5 14. O-O Nc7 15. Bd1 a6 16. Nxc7 Qxc7 17. Bc2 Bd7 18. b4 Be7 19. a5 b5 20. axb6 Qxb6 21. Qf4 Bf6 22. Nf3 Re6 23. Ng5 Bxg5 24. Qxg5 Rh6 25. Qxd5 Be6 26. Qxa8+ 1-0",
    B: "1673",
  },
  {
    W: "1599",
    T: "180+0",
    P: "1. Nc3 c5 2. d4 cxd4 3. Qxd4 Nf6 4. Qd1 e6 5. Nf3 Be7 6. e3 O-O 7. Bd3 Nc6 8. b3 d5 9. Bb2 a6 10. O-O Bd6 11. Na4 Bb8 12. Nd4 Nxd4 13. Bxd4 b5 14. Nc5 Ba7 15. Bxf6 Qxf6 16. c4 Bxc5 17. Qc2 g6 18. cxb5 Bd6 19. f4 Bb7 20. g4 Rac8 21. Qe2 d4 22. bxa6 Bc6 23. a7 Qh4 24. a4 dxe3 25. Qxe3 Qxg4+ 26. Qg3 Bc5+ 27. Rf2 Bxf2+ 28. Kxf2 Qxg3+ 29. hxg3 Ra8 30. Bb5 Rfc8 31. Ba6 Rc7 32. b4 Raxa7 33. b5 Be8 34. Rd1 Rc2+ 35. Kf3 Rc3+ 36. Kg4 Ra3 37. Rd8 Kf8 38. b6 Rxa6 39. b7 Rb6 40. b8=Q Rxb8 41. Rxb8 Rxa4 42. Kg5 Ra5+ 43. Kg4 Rb5 44. Rd8 Ke7 45. Rd2 Bd7 46. Rh2 e5+ 47. Kf3 Rb3+ 48. Kf2 Rb2+ 49. Kg1 Rxh2 50. Kxh2 exf4 51. gxf4 f5 52. Kg3 Kf6 53. Kh4 g5+ 54. Kg3 gxf4+ 55. Kxf4 Kg6 56. Kg3 0-1",
    B: "1623",
  },
  {
    W: "1933",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Be6 4. Bb3 Bxb3 5. axb3 a6 6. Nc3 Qd7 7. d3 f5 8. exf5 Qxf5 9. O-O Nf6 10. Qe2 Be7 11. d4 e4 12. Nh4 Qe6 13. d5 Nxd5 14. Qh5+ g6 15. Qxd5 Qd7 16. Qxe4 O-O 17. Nf3 Nc6 18. Bh6 Rfe8 19. Qd5+ Kh8 20. Ng5 Bxg5 21. Bxg5 Re5 22. Qd2 Qf5 23. Be3 Rf8 24. Ne2 Ne7 25. Bd4 Nc6 26. Bxe5+ Nxe5 27. Ng3 Qe6 28. Rae1 Qd7 29. Qd4 Qg7 30. c3 Rf6 31. f4 Nc6 32. Qe4 Qf7 33. Qe8+ Kg7 34. Qxf7+ Rxf7 35. Ne4 Ne7 36. Ng5 Nf5 37. Nxf7 Kxf7 38. g4 Ne7 39. Re4 Nc6 40. Rfe1 d5 41. Re6 Kg7 42. b4 Kf7 43. h3 h5 44. g5 Nd8 45. Rf6+ Kg7 46. Re7+ Kg8 47. Re8+ Kg7 48. Rxd8 c6 49. Rd7+ Kg8 50. Rxb7 c5 51. Rxa6 cxb4 52. cxb4 d4 53. Rxg6+ 1-0",
    B: "1871",
  },
  {
    W: "1670",
    T: "180+0",
    P: "1. d4 e6 2. Nf3 d5 3. e3 Nf6 4. c4 c6 5. c5 b6 6. b4 bxc5 7. bxc5 Nbd7 8. Nc3 g6 9. Bd3 Bg7 10. a4 a5 11. Bb2 O-O 12. O-O Re8 13. Re1 Nh5 14. e4 dxe4 15. Nxe4 e5 16. Nd6 Re7 17. dxe5 Nxe5 18. Bxe5 Bxe5 19. Rxe5 Rxe5 20. Nxe5 Nf4 21. Qf3 Nxd3 22. Qxf7+ Kh8 23. Nxd3 Qg8 24. Qxg8+ Kxg8 25. Nf4 Bf5 26. Re1 Rb8 27. h3 Bc2 28. Ne6 Bxa4 29. Nc7 Rd8 30. Re8+ Rxe8 31. Ndxe8 Kf7 32. Nd6+ Kf6 33. g4 Bb5 34. Ndxb5 cxb5 35. Nxb5 Ke5 36. Kf1 Kd5 37. Na3 Kxc5 38. Ke2 Kb4 39. Nb1 a4 40. Kd2 a3 41. Nxa3 Kxa3 42. Ke3 Kb4 43. Kf4 Kc5 44. Ke5 Kc6 45. f4 Kd7 46. Kf6 Kd6 47. Kg7 Ke6 48. Kxh7 Kf6 49. Kh6 Kf7 50. f5 gxf5 51. gxf5 Kf6 52. h4 Kxf5 53. h5 Kf6 54. Kh7 Kg5 55. h6 Kf6 56. Kg8 Kg6 57. h7 Kh6 58. h8=Q+ Kg6 59. Qh7+ Kf6 60. Qg7+ Kf5 61. Kf8 Ke4 62. Qg5 Kd4 63. Kf7 Ke4 64. Kf6 Kd4 65. Qf5 1-0",
    B: "1655",
  },
  {
    W: "1380",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 d6 4. d3 Bg4 5. Be3 Nd4 6. Bxd4 exd4 7. Nd5 c6 8. Nf4 Qg5 9. Qd2 Bxf3 10. gxf3 Nf6 11. O-O-O Qh6 12. Bh3 Be7 13. Rhe1 O-O 14. e5 dxe5 15. Rxe5 a5 16. Rxe7 Nd5 17. Nxd5 Qxd2+ 18. Kxd2 cxd5 19. Rxb7 a4 20. Rb5 a3 21. b3 Rfd8 22. c4 dxc3+ 23. Kxc3 d4+ 24. Kd2 Rac8 25. Bxc8 1-0",
    B: "1377",
  },
  {
    W: "1855",
    T: "180+0",
    P: "1. e4 e6 2. Nf3 c5 3. Nc3 a6 4. d4 cxd4 5. Nxd4 b5 6. Bd3 Bb7 7. O-O Nc6 8. Nxc6 Bxc6 9. f4 Qb6+ 10. Kh1 Rc8 11. f5 Bc5 12. fxe6 dxe6 13. Qg4 g6 14. Bg5 Rc7 15. Rxf7 Rxf7 16. Qxe6+ Be7 17. Qc8+ Bd8 18. Bxd8 Qxd8 19. Qxc6+ Qd7 20. Qxa6 Nh6 21. Bxb5 O-O 22. Bxd7 Rxd7 23. Nd5 Rdf7 24. Qe6 Kh8 25. Ne3 Rf6 26. Qe5 Ng4 27. Nxg4 Kg8 28. Nxf6+ 1-0",
    B: "1847",
  },
  {
    W: "2380",
    T: "180+0",
    P: "1. Nf3 Nf6 2. c4 g6 3. b3 Bg7 4. Bb2 O-O 5. e3 c5 6. Be2 e6 7. O-O d5 8. cxd5 exd5 9. d4 Ne4 10. Nbd2 Nc6 11. Rc1 cxd4 12. Nxd4 Nxd4 13. Bxd4 Bxd4 14. exd4 Re8 15. Re1 Bf5 16. Nxe4 dxe4 17. Qd2 Qd6 18. Bc4 Rad8 19. Rcd1 Bc8 20. Qb2 Kg7 21. a3 f5 22. b4 b6 23. d5+ Qf6 24. Qxf6+ Kxf6 25. f3 e3 26. f4 g5 27. g3 gxf4 28. gxf4 Re4 29. Bb3 Rde8 30. Rd3 e2 31. Rf3 Ba6 32. Kf2 Rd4 33. Rc1 Ree4 34. Ke1 Rxf4 35. Rxf4 Rxf4 36. Kd2 Rf1 37. Re1 Rxe1 38. Kxe1 Ke5 39. a4 Bd3 40. Kd2 f4 41. Ke1 f3 42. Kf2 Kf4 43. d6 Bf5 44. Bc2 Bd7 45. Bxh7 Bxa4 46. Bd3 Bd7 47. Ba6 Ke5 48. Bb7 Kxd6 49. Bxf3 Bb5 50. Bxe2 Bxe2 51. Kxe2 a5 52. b5 Ke6 53. Kd3 Kf5 54. Kc4 Kg4 55. Kd5 Kh3 56. Kc6 a4 57. Kxb6 a3 58. Kc6 a2 59. b6 a1=Q 60. b7 Qc3+ 61. Kd7 Qd4+ 62. Kc8 Qc5+ 63. Kd8 Qb6+ 64. Kc8 Qc6+ 65. Kb8 Kxh2 66. Ka7 Kg3 67. b8=Q+ Kf3 68. Qf8+ Ke3 69. Qe7+ Kd4 70. Qg7+ 1-0",
    B: "2357",
  },
  {
    W: "2182",
    T: "180+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. d4 e6 4. Be3 d6 5. c3 h6 6. Qd2 a6 7. Bc4 b5 8. Bb3 Nd7 9. O-O Ne7 10. Ne1 Bb7 11. f4 Bxe4 12. c4 bxc4 13. Bxc4 O-O 14. Nc3 Bb7 15. Rc1 Nf6 16. h3 Kh7 17. Bd3 Nfd5 18. Bf2 f5 19. Nxd5 Nxd5 20. a3 Qd7 21. Nf3 Rfe8 22. Rfe1 a5 23. Qe2 Nxf4 24. Qe3 Nxd3 25. Qxd3 Be4 26. Qc3 Rec8 27. Nd2 Bd5 28. Bg3 c5 29. Kh2 Bxd4 30. Qd3 Bxb2 31. Rb1 Bg7 32. Rb5 Rcb8 33. a4 Rxb5 34. axb5 Rb8 35. Rb1 Rb6 36. Qa3 Rxb5 37. Rxb5 Qxb5 38. Bxd6 Qe2 39. Nf3 Bxf3 40. Qxf3 Qxf3 41. gxf3 c4 42. Ba3 c3 43. f4 e5 44. fxe5 Bxe5+ 45. Kg2 g5 46. Kf3 Kg6 47. Ke2 Kh5 48. Kd3 Kh4 49. Kc4 Kxh3 50. Kd5 Bg7 51. Bc1 f4 52. Ke4 a4 53. Kf3 c2 54. Ba3 Bd4 55. Bc1 Be3 56. Bxe3 fxe3 57. Kxe3 c1=Q+ 58. Ke4 a3 59. Kf5 a2 60. Kg6 Qc6+ 61. Kh5 Qf3+ 0-1",
    B: "2171",
  },
  {
    W: "1791",
    T: "180+0",
    P: "1. c3 Nf6 2. d4 e6 3. Bg5 Be7 4. e3 O-O 5. Bxf6 Bxf6 6. Nf3 d6 7. Bd3 Nd7 8. Qc2 g6 9. Nbd2 b6 10. h4 Bb7 11. h5 Bg7 12. hxg6 fxg6 13. O-O-O Nf6 14. Rh2 d5 15. Rdh1 Kf7 16. Ng5+ Ke7 17. Nxh7 Rh8 18. Nxf6 Rxh2 19. Rxh2 Kxf6 20. Bxg6 Qe7 21. Nf3 Rh8 22. Rxh8 Bxh8 23. Ne5 Kg5 24. Nf7+ Kf6 25. Nxh8 Qg7 26. Nf7 Qxg6 27. Qxg6+ Kxg6 28. Ne5+ Kf5 29. g4+ Ke4 30. Kd2 c5 31. f3# 1-0",
    B: "1793",
  },
  {
    W: "1643",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 e6 7. Be2 Be7 8. O-O O-O 9. a3 b5 10. Bf3 Bb7 11. e5 Bxf3 12. Qxf3 Nd5 13. Nxd5 exd5 14. Qxd5 dxe5 15. Qxa8 exd4 16. Bf4 Bd6 17. Bxd6 Qxd6 18. Qf3 Qc5 19. c3 Nc6 20. cxd4 Nxd4 21. Qe3 Qc4 22. Rac1 Ne2+ 23. Kh1 Nxc1 24. Rxc1 Qb3 25. h3 Qxb2 26. Rc7 Qa1+ 27. Kh2 Qe5+ 28. Qg3 Qxg3+ 29. Kxg3 Ra8 30. Kf4 g6 31. Ke5 Kg7 32. g4 Re8+ 33. Kd5 Re6 34. Rc6 Re2 35. Rxa6 Rxf2 36. h4 Rh2 37. Kc5 Rxh4 38. Kxb5 Rxg4 39. Kb6 h5 40. a4 h4 41. Ra7 h3 42. a5 h2 0-1",
    B: "1672",
  },
  {
    W: "1332",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 d6 3. d3 Nf6 4. Bg5 Be7 5. Bxf6 Bxf6 6. h4 Nc6 7. g4 Bxg4 8. Nc3 Nd4 9. Be2 Nxe2 10. Qxe2 Qd7 11. Nd5 O-O-O 12. O-O-O c6 13. Ne3 Bxf3 14. Qxf3 h5 15. c4 c5 16. Nd5 Rh6 17. Rhg1 g5 18. hxg5 Bxg5+ 19. Rxg5 Rf8 20. Nf6 Qe7 21. Rf5 h4 22. Rh1 Rfh8 23. Nd5 Qd7 24. Rxf7 Qa4 25. a3 Qe8 26. Qg4+ Re6 27. Rc7+ Kb8 28. Rxh4 Rxh4 29. Qxh4 Rg6 30. Re7 Rg1+ 31. Kc2 Qa4+ 32. Kd2 Qd1+ 33. Ke3 Re1# 0-1",
    B: "1342",
  },
  {
    W: "1661",
    T: "180+0",
    P: "1. d4 d6 2. f4 g6 3. c3 Bg7 4. g3 Nf6 5. Nd2 O-O 6. Bg2 Nbd7 7. e4 e5 8. dxe5 dxe5 9. fxe5 Nxe5 10. Ngf3 Nfg4 11. Nxe5 Nxe5 12. O-O c6 13. Nf3 Bg4 14. Qxd8 Raxd8 15. Nxe5 Bxe5 16. Bf4 Bxf4 17. Rxf4 f5 18. exf5 Bxf5 19. Rf2 Rd6 20. Raf1 Rfd8 21. b3 Rd3 22. c4 Rd2 23. Rxd2 Rxd2 24. Rf2 Rd1+ 25. Bf1 Bd3 26. Kg2 Be4+ 27. Kh3 h5 28. Bg2 Bf5+ 29. Kh4 Kg7 30. Kg5 Re1 31. h3 Re5 32. Kf4 Rc5 33. g4 hxg4 34. hxg4 Bb1 35. a3 g5+ 36. Kg3 b5 37. cxb5 cxb5 38. b4 Rc3+ 39. Rf3 Rc4 40. Bf1 Rd4 41. Bxb5 Be4 42. Re3 Bg6 43. Bd7 Rxd7 44. a4 Rd3 45. Rxd3 Bxd3 46. b5 Kf6 47. Kf3 Ke5 48. Ke3 Bc4 49. Kd2 Bxb5 50. axb5 Kd5 51. Kc3 Kc5 52. Kd3 Kxb5 53. Kc3 Kc5 54. Kb3 a5 55. Ka4 Kd4 56. Kxa5 Ke4 57. Kb4 Kf4 58. Kc3 Kxg4 59. Kd2 Kf3 60. Ke1 g4 61. Kf1 g3 62. Kg1 g2 63. Kh2 Kf2 64. Kh3 g1=Q 65. Kh4 Kf3 66. Kh5 Kf4 67. Kh6 1/2-1/2",
    B: "1786",
  },
  {
    W: "2021",
    T: "180+0",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. Nf3 Nc6 5. c3 Qb6 6. Be2 cxd4 7. cxd4 Nh6 8. O-O Nf5 9. Nc3 Nfxd4 10. Be3 Nxe2+ 11. Nxe2 Qxb2 12. Bd4 Nxd4 13. Nexd4 Be7 14. Qa4+ Bd7 15. Qa5 Qb6 16. Qd2 O-O 17. Rab1 Qa6 18. Rb2 Rac8 19. Rfb1 b6 20. h3 Rc4 21. Nb5 Bxb5 22. Rxb5 Rfc8 23. R1b2 h6 24. g3 Rc3 25. Kg2 Rc2 26. Rxc2 Rxc2 27. Qxc2 Qxb5 28. Qc7 Bc5 29. Qxa7 Qe2 30. Qa8+ Kh7 31. h4 Qxf2+ 32. Kh3 Qxf3 33. Qe8 h5 34. Qa4 Qf1+ 0-1",
    B: "2078",
  },
  {
    W: "1719",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 a6 3. Be2 Nc6 4. O-O g6 5. c3 Bg7 6. d4 cxd4 7. cxd4 e5 8. d5 Nce7 9. Nc3 h5 10. Bg5 d6 11. Qd2 f6 12. Be3 g5 13. Nxg5 fxg5 14. Bxg5 Bh6 15. Bxh6 Rxh6 16. f4 Bg4 17. fxe5 dxe5 18. Qg5 Qb6+ 19. Kh1 O-O-O 20. Bxg4+ hxg4 21. Rac1 Kb8 22. Qxe5+ Ka7 23. b4 Rdd6 24. b5 Qxb5 25. Nxb5+ axb5 26. Rc7 Rb6 27. Rf8 Nf6 28. Rxe7 Nxe4 29. Qb8+ Ka6 30. Qa8# 1-0",
    B: "1643",
  },
  {
    W: "1985",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 g6 4. Nc3 Bg7 5. d3 Nf6 6. h3 a6 7. a4 Bd7 8. Be3 Nc6 9. Qd2 e5 10. Bg5 h6 11. Bxf6 Qxf6 12. Nd5 Qd8 13. a5 Be6 14. O-O Bxd5 15. Bxd5 O-O 16. Rfe1 Kh7 17. Nh2 f5 18. exf5 Rxf5 19. Nf1 Qf6 20. Ne3 Rf4 21. Be4 Qg5 22. g3 Raf8 23. Re2 Nd4 24. Nd5 Nxe2+ 25. Qxe2 Rxf2 26. Qxf2 Rxf2 27. Kxf2 Qd2+ 28. Kg1 Qxc2 29. Rf1 c4 30. Rf7 cxd3 31. Nf6+ Kh8 32. Bxg6 Bxf6 33. Rf8+ Kg7 34. Be4 Kxf8 0-1",
    B: "2011",
  },
  {
    W: "1766",
    T: "180+2",
    P: "1. Nf3 b6 2. g3 Bb7 3. Bg2 c5 4. O-O e6 5. d3 d6 6. Nbd2 Nf6 7. e4 Be7 8. Qe2 O-O 9. h3 Nbd7 10. Kh2 Qc7 11. Nh4 d5 12. f4 dxe4 13. dxe4 Rfd8 14. f5 exf5 15. Nxf5 Bf8 16. Nc4 Ne5 17. Bf4 Re8 18. Nxe5 Rxe5 19. Bxe5 Qxe5 20. Nh4 Bd6 21. Qf3 g6 22. Rae1 Rf8 23. Qf4 Qxb2 1-0",
    B: "1766",
  },
  {
    W: "1606",
    T: "180+2",
    P: "1. e4 d6 2. d4 Nc6 3. d5 Nb4 4. a3 Na6 5. b4 Nf6 6. f3 e6 7. c4 exd5 8. cxd5 c6 9. Bxa6 bxa6 10. Nc3 cxd5 11. Nxd5 Nxd5 12. Qxd5 Be6 13. Qd4 Qf6 14. Qxf6 gxf6 15. Ne2 Bg7 16. Bb2 O-O 17. O-O d5 18. exd5 Bxd5 19. Nf4 Bc4 20. Rfc1 Rac8 21. Nh5 f5 22. Bxg7 Rfe8 23. Nf6+ Kxg7 24. Nxe8+ Rxe8 25. Rxc4 Kg6 26. Rc6+ Kg5 27. Rxa6 Re2 28. Rd1 Kf4 29. Rd4+ Ke3 30. Rd1 f4 31. Rxa7 h5 32. Re7# 1-0",
    B: "1321",
  },
  {
    W: "1250",
    T: "180+2",
    P: "1. c4 b6 2. g3 Bb7 3. Nf3 e6 4. Bg2 Nf6 5. O-O Bc5 6. e3 O-O 7. d4 Bd6 8. Qc2 c5 9. a3 cxd4 10. exd4 Qe7 11. b4 e5 12. c5 Bc7 13. Nxe5 Bxe5 14. Bxb7 1-0",
    B: "1145",
  },
  {
    W: "1141",
    T: "180+2",
    P: "1. d4 Nf6 2. Bf4 b6 3. e3 d6 4. Ne2 g6 5. c3 Bb7 6. Qb3 Bd5 7. c4 Bb7 8. d5 Bg7 9. Nbc3 e6 10. O-O-O e5 11. Bg3 Nbd7 12. f4 Ng4 13. Nb5 exf4 14. exf4 Nc5 15. Qf3 h5 16. h3 Nf6 17. Bh4 a6 18. Nbd4 b5 19. b3 bxc4 20. bxc4 Qd7 21. g4 hxg4 22. hxg4 Nxg4 23. Ne6 fxe6 24. Qxg4 exd5 25. cxd5 Qxg4 0-1",
    B: "1116",
  },
  {
    W: "1945",
    T: "180+2",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4 O-O 6. Nf3 e5 7. fxe5 dxe5 8. Nxe5 c5 9. Nf3 Nxe4 10. Nxe4 Re8 11. Bg5 Rxe4+ 12. Kf2 Qb6 13. Bd3 Qxb2+ 14. Qd2 Qxd2+ 15. Nxd2 Bxd4+ 16. Kf3 Re5 17. Bf4 Bxa1 0-1",
    B: "1904",
  },
  {
    W: "2171",
    T: "180+2",
    P: "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 h6 5. Nf3 Nd7 6. Bd3 Ngf6 7. O-O Be7 8. Qe2 O-O 9. c4 Nxe4 10. Bxe4 Nf6 11. Bd3 b6 12. Bd2 Bb7 13. Bc3 c5 14. dxc5 Bxc5 15. Rad1 Qe7 16. Ne5 Rfd8 17. Ng4 Nxg4 18. Qxg4 Qg5 19. Qxg5 hxg5 20. Rfe1 Be7 21. b3 Bf6 22. Bxf6 gxf6 23. Bf1 Kg7 24. g3 Bf3 25. Rxd8 Rxd8 26. Bg2 Bxg2 27. Kxg2 Rd2 28. Ra1 g4 29. a3 Rd3 30. Rb1 f5 31. Kf1 e5 32. Ke2 e4 33. b4 Rxa3 34. c5 bxc5 35. bxc5 Rc3 36. Rb5 Kf6 37. Ra5 Ke6 38. Ra6+ Kd5 39. Rxa7 Rxc5 40. Rxf7 Ke6 41. Rf8 Rc2+ 42. Ke3 Rc3+ 43. Ke2 Rc2+ 44. Ke1 Rc1+ 45. Kd2 Rh1 46. Re8+ Kf7 47. Rh8 Kg6 48. Ke2 Kg7 49. Rh5 Kg6 50. Rh8 Kg7 51. Rh5 Kg6 52. Rh4 Kg5 53. Rh8 Kg6 54. Ke3 Kg7 55. Rh5 Kg6 56. Rh4 Kg5 57. Rh8 Ra1 58. Rg8+ Kf6 59. Kf4 Ra5 60. Rf8+ Kg7 61. Rxf5 Rxf5+ 62. Kxf5 e3 63. fxe3 1-0",
    B: "1841",
  },
  {
    W: "1262",
    T: "600+10",
    P: "1. e4 Nf6 2. d3 h6 3. Nd2 d5 4. e5 Nfd7 5. f4 Nb6 6. b4 Bf5 7. Bb2 e6 8. g3 Bxb4 9. c3 Be7 10. Bg2 c6 11. Ngf3 Na6 12. Nb3 c5 13. Na5 Qc7 14. Qb1 Nd7 15. Nb3 b6 16. O-O b5 17. c4 Nb4 18. Qd1 Bxd3 19. Re1 Bxc4 20. a3 Nd3 21. Re3 Nxb2 22. Qc2 Na4 23. Bf1 d4 24. Ree1 Bxf1 25. Kxf1 Qb7 26. Nfd2 Qh1+ 27. Kf2 Qxh2+ 28. Kf1 Qxg3 29. Ne4 Qxf4+ 30. Kg1 O-O 31. Nd6 a6 32. Rf1 Qg3+ 33. Kh1 Bxd6 34. exd6 Qxd6 35. Qg2 Qd5 36. Rg1 g5 37. Nd2 Qxg2+ 38. Kxg2 Nc3 39. Raf1 f5 40. Rh1 Rf6 41. Nf3 Kf7 42. Re1 Ne4 43. Ref1 f4 44. Nxg5+ hxg5 45. Rh7+ Ke8 46. Rh8+ Nf8 47. Rfh1 Ra7 48. R1h7 Rxh7 0-1",
    B: "1616",
  },
  {
    W: "1697",
    T: "60+0",
    P: "1. e4 b6 2. d4 Bb7 3. Bd3 Qc8 4. Qe2 c5 5. dxc5 Qxc5 6. Nf3 Qc6 7. Be3 g6 8. Nc3 Bg7 9. Nd4 Qc8 10. O-O Nc6 11. Nxc6 Qxc6 12. Rad1 f5 13. Bb5 Qc8 14. Bxd7+ Qxd7 15. Rxd7 Kxd7 16. Rd1+ Ke8 17. Qb5+ Kf7 18. Qd7 Nf6 19. Qxb7 Rad8 20. e5 Rxd1+ 21. Nxd1 Rd8 22. Nc3 Ne4 23. Qxa7 Nxc3 24. Kf1 Rd1# 0-1",
    B: "1680",
  },
  {
    W: "2450",
    T: "30+0",
    P: "1. e4 f5 2. Nc3 fxe4 3. Nxe4 Nf6 4. Nc3 d5 5. d4 c5 6. Nf3 Nc6 7. dxc5 e6 8. Bg5 Bxc5 9. Bd3 O-O 10. Qe2 d4 11. Ne4 Be7 12. Nxf6+ Bxf6 13. h4 e5 14. Qe4 g6 15. O-O-O Bf5 16. Qe2 Bxd3 17. Qxd3 Qe7 18. Qb3+ Kg7 19. Rhe1 Rab8 20. Nxd4 Nxd4 21. Rxd4 Qf7 22. Qxf7+ Rxf7 23. Rd3 Bxg5+ 24. hxg5 Re8 25. f3 Rfe7 26. Rde3 Kf7 27. g4 b5 28. c3 a6 29. Kc2 Re6 30. Re4 R8e7 31. f4 exf4 32. Rxf4+ Kg7 33. Rxe6 Rxe6 34. Rf6 Rxf6 35. gxf6+ Kxf6 36. b3 Kg5 37. c4 bxc4 38. bxc4 Kf6 39. Kd3 h5 40. Kd4 hxg4 41. Ke3 Ke5 42. Kf2 Kd4 43. Kg3 Kxc4 44. Kxg4 Kb4 45. Kg5 Ka3 46. Kxg6 Kxa2 47. Kf5 Kb2 0-1",
    B: "2506",
  },
  {
    W: "1285",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. Bb5+ Bd7 7. Bxd7+ Nxd7 8. d3 b6 9. c4 Nb7 10. Nc3 Bb4 11. O-O O-O 12. Qh5 h6 13. Nce4 Be7 14. f4 hxg5 15. fxg5 g6 16. Qh6 Nbc5 17. Be3 Nxe4 18. dxe4 a6 19. Rf3 f6 20. Rh3 Kf7 21. Qh7+ Ke8 22. Qxg6+ Rf7 23. Rh8+ Nf8 24. Rh7 Nxh7 25. Qg8+ Rf8 26. Qxh7 Bc5 27. Qg6+ Kd7 28. Bxc5 bxc5 29. gxf6 Rxf6 30. Qg7+ Qe7 31. Qg4+ Kd6 32. Rb1 Raf8 33. a3 Rf4 34. Qg6+ Qf6 35. Qxf6+ R4xf6 36. b4 cxb4 37. axb4 Kd7 38. c5 Rf4 39. Re1 Rg8 40. h3 Rg5 41. c6+ Kd6 0-1",
    B: "1303",
  },
  {
    W: "1980",
    T: "120+1",
    P: "1. d4 Nf6 2. Nf3 d5 3. c3 Bg4 4. Ne5 Bf5 5. Qb3 Qc8 6. Bf4 c6 7. h3 e6 8. Nd2 Nbd7 9. f3 Nxe5 10. dxe5 Nd7 11. e4 Nc5 12. Qc2 dxe4 13. fxe4 Bg6 14. h4 h5 15. O-O-O Be7 16. Be2 Qc7 17. Bf3 O-O-O 18. Nc4 b5 19. Nd6+ Bxd6 20. exd6 Qd7 21. Rhe1 f6 22. b4 Na4 23. Qd3 e5 24. Bg3 Nb6 25. Qe2 Nc4 26. Rd3 Bf7 27. Red1 Kb7 28. a4 a6 29. a5 g6 30. Bf2 Be6 31. Bb6 Rc8 32. Bc5 Rhf8 33. Rg1 f5 34. exf5 Bxf5 35. Be4 Rce8 36. Bxf5 Rxf5 37. Rdd1 Ref8 38. Qd3 Rd8 39. Rgf1 Re8 40. Rxf5 gxf5 41. Rf1 e4 42. Qd4 e3 43. Re1 e2 44. Qf4 Qe6 45. d7 Rd8 46. Qg5 Rxd7 47. Be3 Nxe3 48. Rxe2 Rd1+ 49. Kb2 Qe4 0-1",
    B: "1946",
  },
  {
    W: "1183",
    T: "120+1",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qd1 d6 5. Nf3 Be7 6. Bb5 Nf6 7. Bxc6+ bxc6 8. O-O Nxe4 9. Qd4 Bf5 10. Nc3 O-O 11. Nxe4 Bxe4 12. Qxe4 d5 13. Qa4 a5 14. Qxc6 Qd6 15. Qc3 Bf6 16. Qd3 Be5 17. Nxe5 Qxe5 18. a4 d4 19. c3 dxc3 20. bxc3 Rad8 21. Qc2 Rd6 22. g3 Rh6 1-0",
    B: "1173",
  },
  {
    W: "2033",
    T: "120+1",
    P: "1. e4 c5 2. Nf3 e6 3. d3 b6 4. g3 Bb7 5. Bg2 Nf6 6. O-O d6 7. Re1 Be7 8. Nbd2 Nbd7 9. h4 Qc7 10. Nf1 Ng4 11. Bf4 Nde5 12. Nxe5 Nxe5 13. Qe2 h6 14. h5 O-O-O 15. Nd2 g5 16. hxg6 Nxg6 17. Be3 h5 18. Nf3 h4 19. gxh4 Nxh4 20. Nxh4 Bxh4 21. a3 Rdg8 22. b4 Be7 23. Bf4 f5 24. bxc5 Qxc5 25. c3 fxe4 26. dxe4 Bg5 27. Bg3 Bf6 28. Rad1 Be5 29. Bxe5 Qxe5 30. Qf3 Ba6 31. c4 Bxc4 0-1",
    B: "2121",
  },
  {
    W: "1540",
    T: "120+1",
    P: "1. e4 d5 2. exd5 Nf6 3. c4 c6 4. d4 cxd5 5. c5 b6 6. b4 bxc5 7. bxc5 Nc6 8. Nf3 Qc7 9. Bb5 Bd7 10. O-O e5 11. Re1 e4 12. Ng5 h6 13. Nh3 Bxh3 14. gxh3 a6 15. Ba4 Kd7 16. Nc3 g5 17. Ne2 g4 18. h4 Be7 19. Bf4 Qa5 20. Ng3 Ke6 21. Bxc6 Nd7 22. Bxa8 Rxa8 23. Ne2 Bxh4 24. Bg3 Bg5 25. Nf4+ Bxf4 26. Bxf4 h5 27. Bd2 Qb5 28. Qe2 Qc6 29. Bf4 f5 30. Reb1 Rg8 31. Kg2 h4 32. Kh1 g3 33. fxg3 hxg3 34. Bxg3 Nf6 35. Qe3 Ng4 36. Rb6 Nxe3 37. Rxc6+ Kd7 38. Rd6+ Ke8 39. Rb1 Rh8 40. Rb8+ Ke7 41. Rxh8 Kf7 42. Rh7+ Kg8 43. Rdd7 Ng4 44. Rhg7+ Kf8 45. Be5 Ke8 46. Rge7+ Kf8 47. Bg7+ Kg8 48. Rd8+ Kh7 49. Bf8+ Kg6 50. Rg7+ Kh6 51. Rxg4+ Kh7 52. Rh4+ Kg6 53. Bd6 Kf7 54. Rh7+ Kg6 55. Rb7 Kh5 56. Rb6 Kg4 57. Rxa6 Kf3 58. Rg8 e3 59. Ra3 Kf2 60. Re8 e2 61. Rxe2+ Kxe2 62. Bf4 Kf2 63. Rg3 Ke2 64. a3 Kf2 65. a4 Ke2 66. a5 Kf2 67. a6 Ke2 68. a7 Kf2 69. a8=Q Ke2 70. Qxd5 Kf2 71. Qf3+ Ke1 72. Rg1# 1-0",
    B: "1609",
  },
  {
    W: "675",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nb4 4. O-O Bc5 5. c3 Nc6 6. d4 exd4 7. cxd4 Bb6 8. e5 Nf6 9. exf6 gxf6 10. Bh6 d5 11. Re1+ Ne7 12. Bb5+ c6 13. Re3 cxb5 14. Qe1 Be6 15. Nc3 Qd6 16. Nxb5 Qc6 17. Qe2 Bxd4 18. Rc1 Qxc1+ 19. Qf1 Qxb2 20. Re2 Qxb5 0-1",
    B: "702",
  },
  {
    W: "1194",
    T: "120+1",
    P: "1. e4 b6 2. Bc4 Bb7 3. Qf3 Nf6 4. Nh3 Bxe4 5. Qf4 Bxc2 6. Ng5 d5 7. Bb3 Bxb3 8. axb3 Nc6 9. Nc3 e5 10. Qf3 Nb4 11. Kd1 Nc2 12. Kxc2 Bb4 13. Nxd5 O-O 14. Nxb4 e4 15. Qf4 a5 16. Nc6 Qxd2+ 17. Kxd2 Nd5 18. Qxe4 h6 19. Nf3 Nf6 20. Qf5 Rfe8 21. Re1 Rxe1 22. Kxe1 Re8+ 23. Be3 h5 24. Ng5 Ng4 25. Qxf7+ 1-0",
    B: "1228",
  },
  {
    W: "1804",
    T: "120+1",
    P: "1. d4 Nf6 2. Bg5 e6 3. Nd2 Be7 4. e4 d5 5. e5 Nfd7 6. Bxe7 Qxe7 7. c4 c5 8. Ngf3 Nc6 9. cxd5 exd5 10. Nb3 c4 11. Nbd2 b5 12. Be2 O-O 13. O-O f6 14. Re1 fxe5 15. dxe5 Ndxe5 16. Nxe5 Nxe5 17. Bf3 Bb7 18. Qe2 Nxf3+ 19. Nxf3 Qxe2 20. Rxe2 Rae8 21. Rae1 Rxe2 22. Rxe2 h6 23. Nd4 a6 24. Re7 Rf7 25. Re8+ Kh7 26. h3 Rf4 27. Ne6 Re4 28. Re7 Bc8 29. Rxg7+ Kh8 30. Ra7 Rxe6 31. Ra8 Re8 32. Ra7 d4 33. f3 d3 0-1",
    B: "1793",
  },
  {
    W: "1119",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Nc6 4. Nxc6 dxc6 5. d4 Bg4 6. Bg5 Bxd1 0-1",
    B: "1129",
  },
  {
    W: "1335",
    T: "900+15",
    P: "1. d4 e6 2. c4 d5 3. cxd5 Bb4+ 4. Bd2 Bxd2+ 5. Nxd2 Nf6 6. e4 exd5 7. e5 Ne4 8. Nxe4 dxe4 9. Be2 Bf5 10. d5 Nd7 11. f4 O-O 12. Bc4 Nb6 13. Bb3 Kh8 14. Ne2 Re8 15. Nc3 Qd7 16. O-O Bg4 17. Qd2 f6 18. h3 Bxh3 19. gxh3 Qxh3 20. Rae1 Rad8 21. Nxe4 Nxd5 22. Bxd5 c6 23. exf6 Qg4+ 24. Ng3 Rxd5 25. Rxe8# 1-0",
    B: "1416",
  },
  {
    W: "1588",
    T: "600+0",
    P: "1. c4 e5 2. Nc3 Nc6 3. Nf3 Bc5 4. g3 Nf6 5. Bg2 d6 6. O-O Be6 7. a3 Bxc4 8. b4 Bd4 9. Nxd4 exd4 10. Na2 O-O 11. b5 0-1",
    B: "1559",
  },
  {
    W: "1404",
    T: "600+0",
    P: "1. Nf3 d5 2. b3 Bf5 3. Bb2 Nf6 4. e3 e6 5. Be2 Be7 6. O-O O-O 7. d3 Bd6 8. Nbd2 Bg6 9. Nh4 Re8 10. Nxg6 fxg6 11. Nf3 e5 12. e4 dxe4 13. dxe4 Nxe4 14. Bc4+ Kh8 15. Re1 Ng5 16. a3 e4 17. Nxg5 Qxg5 18. g3 Nd7 19. Bc1 Qf6 20. Rb1 Qf5 21. Bb2 Ne5 22. Qe2 Nf3+ 23. Kh1 Qh3 24. Qxf3 exf3 25. Rxe8+ Rxe8 26. Rd1 0-1",
    B: "1548",
  },
  {
    W: "1350",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 f5 3. d4 Nf6 4. exf5 d6 5. dxe5 dxe5 6. Qxd8+ Kxd8 7. Nxe5 Bxf5 8. Nf7+ Ke7 9. Nxh8 Bxc2 10. Nc3 Nc6 11. Bg5 h6 12. Bxf6+ Kxf6 13. Nd5+ Kg5 14. Nf7+ Kg6 15. Nxc7 Rc8 16. Bc4 Rxc7 17. O-O Rxf7 18. Bxf7+ Kf6 19. Bd5 Ne5 20. Bxb7 Bc5 21. Rac1 Bd3 22. Rfd1 Bb6 23. Kh1 Ng4 24. Rxd3 Nxf2+ 25. Kg1 Nxd3+ 26. Kf1 Nxc1 27. g4 Nd3 28. Ke2 Ne5 29. h3 g5 30. Kd2 Nc4+ 31. Kc3 Na5 32. Bd5 Ke5 33. Bf7 Bd4+ 34. Kb4 Nc6+ 35. Kb5 Ne7 36. b3 Nd5 37. Kc6 Nb4+ 38. Kb7 Nxa2 39. b4 Nxb4 40. h4 gxh4 41. Bh5 h3 42. g5 hxg5 43. Bg4 h2 44. Bf3 Kf4 45. Bh1 Kg3 46. Kc7 g4 47. Kd6 Kf2 48. Ke6 g3 49. Kf5 g2 50. Bxg2 Kxg2 51. Kg4 h1=Q 52. Kf4 Qe1 53. Kg4 Qg3+ 54. Kf5 Kf3 55. Ke6 Ke3 56. Kf5 Qf4+ 57. Ke6 Ke4 58. Kd7 Qf5+ 59. Kd6 Be5+ 60. Kc5 Nd5 61. Kc4 Qc8+ 62. Kb5 a6+ 63. Ka5 Qb7 64. Ka4 Qb5+ 65. Ka3 a5 66. Ka2 a4 67. Ka3 Qb3# 0-1",
    B: "1339",
  },
  {
    W: "1631",
    T: "600+0",
    P: "1. e4 e6 2. e5 Ne7 3. d4 d6 4. f4 Ng6 5. Bd3 Be7 6. Nf3 O-O 7. Nc3 Nd7 8. Qe2 dxe5 9. fxe5 Nh4 10. Bf4 Nf5 11. g4 Bh4+ 12. Kd2 Ne7 13. Nxh4 Nd5 14. Nxd5 Qxh4 15. Nxc7 Qxg4 16. Qxg4 Rb8 17. Bh6 g6 18. Bxf8 Kxf8 19. Nb5 a6 20. Nd6 Nb6 21. Qf3 f5 22. exf6 Bd7 23. Nxb7 Nd5 24. Nc5 Bc6 25. Nxe6+ Kf7 26. c4 Nxf6 27. Qxc6 Rxb2+ 28. Kc3 Rf2 29. Rhf1 Ng4 30. Rxf2+ Nxf2 31. Rf1 Kf6 32. Rxf2+ Ke7 33. Nc5 1-0",
    B: "1635",
  },
  {
    W: "1854",
    T: "600+0",
    P: "1. d4 d5 2. c4 Nf6 3. Nc3 Bf5 4. Bg5 c6 5. e3 Nbd7 6. c5 e6 7. Bd3 Bxd3 8. Qxd3 Be7 9. Nf3 h6 10. Bh4 a5 11. a4 b6 12. Ne5 Nxe5 13. dxe5 Ng8 14. Bxe7 Nxe7 15. O-O bxc5 16. f4 c4 17. Qd4 O-O 18. g4 f5 19. g5 h5 20. h4 g6 21. Rfb1 Rb8 22. Qc5 Nc8 23. Ra3 Qd7 24. b3 cxb3 25. Raxb3 Rxb3 26. Rxb3 Qc7 27. Nb5 Qd7 28. Nd4 Na7 29. Nxe6 Qxe6 30. Qxa7 d4 31. Rb7 Rf7 32. Rxf7 Qxf7 33. Qxa5 dxe3 34. Qd8+ Kg7 35. Qf6+ Qxf6 36. gxf6+ Kf7 37. Kf1 c5 38. Ke2 c4 39. Kxe3 c3 40. Kd3 c2 41. Kxc2 Ke6 42. Kc3 1-0",
    B: "1846",
  },
  {
    W: "1292",
    T: "600+0",
    P: "1. e4 e5 2. Qh5 Qf6 3. Bc4 g6 4. Qf3 c6 5. d3 b5 6. Bg5 Qxg5 7. Qxf7+ Kd8 8. Qxf8+ Kc7 9. Bxg8 Qxg2 10. Nc3 d6 11. Qe7+ Nd7 12. O-O-O Qxh1 13. Be6 Rf8 14. Bxd7 Bxd7 15. Nh3 Qxh2 16. Qxh7 Rf6 17. Ng5 Qf4+ 18. Kb1 Qxg5 19. Ne2 Raf8 20. b3 Qg2 21. Rg1 Qxf2 22. Nc3 Qxg1+ 23. Kb2 Qd4 24. b4 c5 0-1",
    B: "1133",
  },
  {
    W: "1319",
    T: "600+0",
    P: "1. e4 e5 2. Bb5 h6 3. Nf3 Qe7 4. O-O a6 5. Ba4 b5 6. Bb3 Bb7 7. Nc3 a5 8. Nd5 Bxd5 9. Bxd5 c6 10. Re1 cxd5 11. exd5 Nf6 12. Rxe5 Qxe5 13. Nxe5 Bd6 14. d4 Nxd5 15. Bf4 O-O 16. Bg3 Re8 17. Qf3 Bxe5 18. Bxe5 f6 19. Qxd5+ 1-0",
    B: "1305",
  },
  {
    W: "1930",
    T: "600+0",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. g3 Nxc3 6. bxc3 Bg7 7. Bg2 O-O 8. e4 c5 9. Be3 cxd4 10. Bxd4 e5 11. Bc5 Qa5 12. Bb4 Qb6 13. Bxf8 Bxf8 14. Nf3 Qb5 15. Bf1 Qc6 16. Bd3 Qxc3+ 17. Kf1 Bg4 18. Kg2 Nc6 19. h3 Bxf3+ 20. Qxf3 Nd4 21. Qd1 Rd8 22. Rc1 Qa3 23. Bc4 Bh6 24. Rb1 b6 25. Qg4 Bf8 26. Rhd1 h5 27. Rd3 Qxd3 28. Bxd3 hxg4 29. hxg4 Bc5 30. Bc4 Kf8 31. a4 Ke7 32. a5 Ne6 33. axb6 axb6 34. Ra1 Ng5 35. f3 Rd2+ 36. Kf1 Nxf3 37. Ra7+ Kf6 38. Rxf7+ Kg5 39. Rxf3 Kxg4 40. Rf6 Kxg3 41. Rxg6+ Kf4 42. Bd5 b5 43. Rf6+ Kg5 44. Re6 Bd4 45. Re8 b4 46. Rb8 Rb2 47. Ke1 Kf4 48. Rf8+ Ke3 49. Kd1 Rd2+ 50. Kc1 Bb2+ 51. Kb1 Bc3 52. Rh8 Rb2+ 53. Kc1 Kd3 54. Rh3+ Kd4 55. Rf3 Re2 56. Kd1 Rxe4 57. Bxe4 Kxe4 58. Rh3 Kd4 59. Kc1 e4 60. Kc2 e3 61. Rg3 Bd2 62. Kb3 Bc3 63. Rg2 Kd3 64. Rg3 Kd2 65. Rg2+ e2 66. Rg5 e1=Q 67. Rd5+ Kc1 0-1",
    B: "1938",
  },
  {
    W: "1101",
    T: "120+1",
    P: "1. d4 d5 2. e3 Bf5 3. f3 e6 4. a3 Nc6 5. b4 Nf6 6. g4 Bg6 7. h4 h6 8. f4 Nxg4 9. Qxg4 f5 10. Qxg6+ Ke7 11. Nf3 Na5 12. bxa5 b6 13. a6 Kd6 14. Ng5 Be7 15. Nf7+ Kd7 16. Nxd8 Raxd8 17. Bb5+ c6 18. Bd3 Kc7 19. Qxe6 Bd6 20. Bxf5 b5 21. Nc3 Kb6 22. a4 Kxa6 23. axb5+ Kb6 24. Ra6+ Kb7 25. Bd2 cxb5 26. Rxd6 Kb8 27. Rxd5 Rhe8 28. Rxd8+ Rxd8 29. Qe5+ Kb7 30. Qe7+ Kb6 31. Qxd8+ Ka6 32. Bc8# 1-0",
    B: "1107",
  },
  {
    W: "1757",
    T: "60+0",
    P: "1. e4 Nf6 2. d3 e5 3. Nc3 Nc6 4. Be3 d6 5. Qd2 Bg4 6. f3 Be6 7. Be2 d5 8. exd5 Nxd5 9. Nxd5 Qxd5 10. g4 Bb4 11. c3 Bd6 12. Kd1 O-O-O 13. Kc2 e4 14. d4 exf3 15. Bxf3 Qb5 16. Rc1 Bd5 17. Kb1 Bxf3 18. Nxf3 Qd5 19. Ne5 Bxe5 20. dxe5 Qxd2 21. Bxd2 Rxd2 22. Rhd1 Rhd8 23. Rxd2 Rxd2 24. Rc2 Rxc2 25. Kxc2 Nxe5 26. c4 Nxg4 27. Kd2 Nxh2 28. Ke3 Ng4+ 29. Kf4 Nf6 30. Kf5 Kd7 31. b3 Kc6 32. a4 Kc5 33. b4+ Kxb4 34. a5 Kxc4 35. Ke5 Kb5 36. Kf4 Kxa5 37. Ke5 b5 38. Kf5 b4 39. Ke5 Ka4 40. Kd4 Ka3 41. Kd3 b3 42. Kc3 Ka2 43. Kd2 b2 44. Kc3 b1=Q 45. Kc4 Qb3+ 46. Kc5 Ne4+ 47. Kc6 Qb6+ 48. Kd7 Qd6+ 49. Kc8 Nf6 50. Kb7 Nd7 51. Kc8 Qb6 52. Kxd7 Qb8 53. Kc6 Qb6+ 54. Kd7 h5 55. Kc8 h4 56. Kd7 h3 57. Ke8 h2 58. Kxf7 h1=Q 59. Kg8 Qhh6 60. Kf7 Qbf6+ 61. Ke8 Qh8+ 62. Kd7 Qhd8# 0-1",
    B: "1958",
  },
  {
    W: "2067",
    T: "60+0",
    P: "1. b3 e6 2. Bb2 Ne7 3. Nf3 d6 4. d3 e5 5. e4 g6 6. Nc3 Bg7 7. g3 O-O 8. Bg2 Nd7 9. O-O f5 10. Qd2 fxe4 11. Nxe4 d5 12. Neg5 h6 13. Ne6 Qe8 14. Nxf8 Qxf8 15. Rae1 Nc6 16. Nh4 Nb6 17. Nxg6 Qf6 18. Nxe5 Nxe5 19. Bxe5 Qg6 20. Bxg7 Qxg7 21. Qa5 Bd7 22. Bxd5+ Nxd5 23. Qxd5+ Kh8 24. Qxb7 Rf8 25. Qxc7 Bh3 26. Qxg7+ Kxg7 27. Re7+ Kf6 28. Rfe1 Rc8 29. c4 Rd8 30. R1e3 a5 31. Ra7 a4 32. Rxa4 Bd7 33. Ra6+ Kf5 34. Rd6 Kg4 35. Re7 Kf5 36. Rexd7 Rb8 37. Rxh6 Ra8 38. f3 Rxa2 39. Rd5# 1-0",
    B: "1990",
  },
  {
    W: "1883",
    T: "600+0",
    P: "1. e4 d5 2. e5 e6 3. f4 Be7 4. Nf3 Nh6 5. h3 Nf5 6. d4 Bh4+ 7. Kd2 O-O 8. c3 c6 9. Kc2 b5 10. Bd3 Ng3 11. Rg1 a6 12. Nbd2 a5 13. Nf1 Nxf1 14. Rxf1 a4 15. Be3 Na6 16. Bf2 Bxf2 17. Rxf2 b4 18. g4 bxc3 19. bxc3 Qa5 20. Rb1 Bd7 21. Rb7 Rfd8 22. Qb1 c5 23. Qb6 Qxb6 24. Rxb6 c4 25. Be2 Rdb8 26. Rxb8+ Nxb8 27. Rf1 a3 28. Rb1 Ba4+ 29. Kd2 h6 30. Ke3 Bc2 31. Rb7 Nc6 32. Ne1 Ba4 33. Kd2 Rb8 34. Rxb8+ Nxb8 35. Bd1 Bd7 36. Nc2 Na6 37. Nxa3 Nc7 38. Nc2 Nb5 39. Nb4 Na3 40. Kc1 Kf8 41. Kb2 Nb5 42. a4 Nc7 43. a5 Bb5 44. Ka3 Ke7 45. Ba4 Kd7 46. Bxb5+ Nxb5+ 47. Kb2 Kc7 48. h4 g6 49. h5 gxh5 50. gxh5 Kb7 51. Kc2 Nc7 52. Kd2 Nb5 53. a6+ Kb6 54. Kc2 Na3+ 55. Kd2 Nb5 56. Kc2 Na3+ 57. Kb2 Nb5 58. Kc2 Ka5 59. Nc6+ Kxa6 60. Nd8 Na3+ 61. Kb2 Nb5 62. Nxf7 Nc7 63. Nxh6 Ne8 64. Nf7 Ng7 65. h6 Nh5 66. h7 Nxf4 67. h8=Q Nd3+ 68. Kc2 Ne1+ 69. Kd1 Nd3 70. Qa8+ Kb5 71. Qe8+ Ka5 72. Qxe6 Ka4 73. Qxd5 Kb3 74. Qb5+ Kxc3 75. e6 Kxd4 76. e7 Nf2+ 77. Ke2 Ne4 78. Qe5# 1-0",
    B: "1835",
  },
  {
    W: "1969",
    T: "120+0",
    P: "1. d4 g6 2. c4 Bg7 3. Bf4 d6 4. Nf3 Nf6 5. e3 O-O 6. h3 Nc6 7. Be2 e5 8. O-O exf4 9. exf4 Re8 10. Bd3 Nh5 11. Qd2 Nxd4 12. Nxd4 Bxd4 13. Nc3 Bxc3 14. Qxc3 Nxf4 15. Bc2 Ne2+ 0-1",
    B: "1741",
  },
  {
    W: "1931",
    T: "300+5",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 Nf6 4. Nc3 exd4 5. Nxd4 Nxd4 6. Qxd4 b6 7. Bg5 Bc5 8. Qe5+ Qe7 9. Bxf6 gxf6 10. Qxe7+ Bxe7 11. Nd5 c6 12. Nc7+ 1-0",
    B: "1202",
  },
  {
    W: "1176",
    T: "420+0",
    P: "1. e4 e5 2. c4 Nc6 3. Nc3 Bc5 4. a3 a6 5. b4 Ba7 6. d3 d6 7. Nf3 Bg4 8. h3 Bh5 9. g4 Bg6 10. Be2 h6 11. Bb2 Nf6 12. Bc1 Nh7 13. h4 f6 14. b5 axb5 15. Nxb5 Bb6 16. Be3 Bxe3 17. fxe3 O-O 18. Kd2 Na5 19. Rb1 c6 20. Nc3 b6 21. h5 Bf7 22. Qg1 Rb8 23. g5 Nxg5 24. Nxg5 fxg5 25. Bd1 Be6 26. Kc1 Qf6 27. Rh2 Qf1 28. Qg3 Qxd3 29. Qe1 Nxc4 30. Re2 Nxa3 31. Rbb2 b5 32. Bc2 Nxc2 33. Rexc2 Rf3 34. Nd1 Rf1 35. Qd2 Qxd2+ 36. Rxd2 Rd8 37. Rbc2 Bc4 38. Rc3 Rh1 39. Ra3 Rxh5 40. Ra6 d5 41. Rxc6 Rh1 0-1",
    B: "1772",
  },
  {
    W: "1671",
    T: "60+0",
    P: "1. d4 e6 2. Bf4 d5 3. e3 Nf6 4. Bg3 Bd6 5. Nf3 h6 6. Bd3 Nbd7 7. c3 Nb6 8. Qc2 c5 9. Nbd2 cxd4 10. exd4 a6 11. O-O-O Qc7 12. Bxd6 Qxd6 13. Kb1 Bd7 14. Ne5 Rc8 15. Nxd7 Nfxd7 16. Rde1 Nc4 17. Nxc4 dxc4 18. Be4 b5 19. f4 O-O 20. g4 b4 21. Qg2 Qxf4 22. Rhf1 Qb8 23. h4 bxc3 24. g5 Qxb2+ 25. Qxb2 cxb2 26. Kxb2 c3+ 27. Kc2 hxg5 28. hxg5 e5 29. d5 g6 30. Rf3 Nb6 31. Ref1 Kg7 32. Rxc3 Rxc3+ 33. Kxc3 a5 34. Kb3 Rb8 35. Ka3 Nc4+ 36. Ka4 Nd2 37. Re1 Nxe4 38. Rxe4 Ra8 0-1",
    B: "1718",
  },
  {
    W: "1600",
    T: "1800+20",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Be7 4. d3 Nf6 5. Bg5 d6 6. h3 h6 7. Bxf6 Bxf6 8. Nc3 Ne7 9. Nd5 Nxd5 10. Bxd5 c6 11. Bb3 O-O 12. O-O Be6 13. Bxe6 fxe6 14. Nh2 Bg5 15. Qg4 Qf6 16. Nf3 g6 17. h4 Bf4 18. g3 h5 19. Qh3 Bh6 20. Ng5 Bxg5 21. hxg5 Qe7 22. f4 exf4 23. gxf4 Rxf4 24. Rxf4 Qxg5+ 25. Kh1 Qxf4 26. Qxe6+ Kh8 27. Qxg6 Qf3+ 28. Qg2 Qxg2+ 29. Kxg2 Rf8 30. Rf1 Rxf1 31. Kxf1 Kg7 32. Kg2 Kg6 33. Kg3 Kg5 34. a3 a5 35. b4 axb4 36. axb4 b5 37. c4 h4+ 38. Kf3 h3 39. cxb5 cxb5 40. d4 h2 41. Kg2 Kf4 42. e5 dxe5 43. d5 Kf5 44. Kxh2 e4 45. Kg3 Ke5 46. d6 Kxd6 47. Kf4 Kd5 48. Ke3 Ke5 49. Kd2 Kd4 50. Ke2 e3 51. Kd1 Kd3 52. Ke1 e2 0-1",
    B: "1639",
  },
  {
    W: "1304",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 d5 5. e5 Nxe5 6. Qe2 f6 7. f4 c5 8. Nf3 Qa5+ 9. Bd2 Qb6 10. Nxe5 fxe5 11. Qxe5+ Be6 12. f5 O-O-O 13. fxe6 Rd6 14. e7 Re6 15. Qxe6+ Qxe6+ 16. Be2 Qxe7 17. O-O Qxe2 18. Rf2 Qb5 19. Rxf8+ Kc7 20. Bf4+ Kb6 21. Nc3 Qxb2 22. Nxd5+ Kb5 23. Rc1 Qd4+ 24. Ne3 h6 25. Kf1 g5 26. Rb1+ Kc6 27. c3 Qd3+ 28. Kf2 Qxb1 29. Rc8+ Kb6 30. Nc4+ Kb5 31. Nd6+ Ka4 32. Rxc5 Qxa2+ 33. Kf3 gxf4 34. Rc4+ Kb3 35. Rxf4 Ne7 36. Nxb7 Nd5 37. Nc5+ Kxc3 38. Ne4+ Kd3 39. Nf2+ Kc3 40. Nd1+ Kd3 41. Nf2+ Kd2 42. Ne4+ Ke1 43. Rf7 Rg8 44. Rd7 Qb3+ 45. Nc3 Qxc3+ 46. Ke4 Nf6+ 47. Kf5 Nxd7 48. Ke4 Rxg2 49. Kf5 Rxh2 50. Kg6 a5 51. Kf5 a4 52. Kg6 a3 53. Kf5 a2 54. Kg6 a1=Q 55. Kf5 Rg2 56. Kf4 h5 57. Kf5 h4 58. Ke4 h3 59. Kf5 h2 60. Ke4 h1=Q 61. Kd5 Qd1+ 62. Ke6 Qf1 63. Ke7 Qe3+ 64. Kd8 Rc2 1/2-1/2",
    B: "1312",
  },
  {
    W: "2311",
    T: "60+0",
    P: "1. c4 c5 2. Nc3 e6 3. d3 Nc6 4. Nf3 Nf6 5. g3 d5 6. cxd5 exd5 7. Bg2 d4 8. Ne4 Nxe4 9. dxe4 Be7 10. O-O O-O 11. Bf4 Be6 12. Ne5 Nxe5 13. Bxe5 g5 14. f4 f6 15. f5 Bf7 16. Bxf6 Bxf6 17. e3 b6 18. exd4 Bxd4+ 19. Kh1 Rb8 20. Qg4 Qf6 21. Rae1 h5 22. Qe2 Rfe8 23. b3 Rbc8 24. g4 h4 25. h3 c4 26. Rf3 c3 27. Rxc3 Bxc3 0-1",
    B: "2406",
  },
  {
    W: "1960",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Nf6 3. Nc3 e6 4. Nf3 exd5 5. d4 Bd6 6. Bd3 O-O 7. h3 Re8+ 8. Kd2 Be6 9. Ne2 c6 10. c3 b5 11. Kc2 a5 12. Bg5 b4 13. Ne5 bxc3 14. bxc3 h6 15. Bh4 a4 16. a3 Na6 17. Nf4 g5 18. Nxe6 Rxe6 19. Bg3 c5 20. Bf5 Re8 21. Re1 cxd4 22. Qxd4 Bc5 23. Qd3 Qa5 24. Nc6 Qb6 25. Nb4 Bxb4 26. axb4 Rac8 27. Kb2 Nxb4 28. cxb4 Qxb4+ 29. Ka2 Rc2+ 30. Qxc2 Qb3+ 31. Qxb3 axb3+ 32. Kxb3 Rb8+ 33. Kc3 d4+ 34. Kxd4 Nd7 35. Bxd7 Rb4+ 36. Kc5 Rb6 37. Kxb6 Kg7 38. Ra7 f6 39. Be6+ Kg6 40. Bf5+ Kxf5 41. Re3 1-0",
    B: "2003",
  },
  {
    W: "1540",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 f6 4. d4 Nce7 5. dxe5 d6 6. exf6 Nxf6 7. e5 Nd7 8. e6 Nc5 9. Nd4 d5 10. Qf3 dxc4 11. Qf7# 1-0",
    B: "1562",
  },
  {
    W: "1853",
    T: "60+0",
    P: "1. d4 e6 2. c4 d5 3. Nc3 Bb4 4. Nf3 Bxc3+ 5. bxc3 Nf6 6. Qc2 O-O 7. cxd5 exd5 8. Bg5 Re8 9. e3 Bg4 10. Bd3 h6 11. Bh4 Nbd7 12. Bg3 c6 13. O-O Bxf3 14. gxf3 g5 15. Rfe1 Nh5 16. e4 Nxg3 17. fxg3 Nf6 18. Kg2 dxe4 19. fxe4 Qd7 20. e5 Nd5 21. Bf5 Qe7 22. Rac1 Kg7 23. Qe4 Rad8 24. c4 Nb4 25. a3 Na6 26. Bg4 Nc7 27. Qf5 Ne6 28. Rcd1 Rxd4 29. Qe4 Rxe4 30. Rxe4 Nc5 31. Rde1 Nxe4 32. Rxe4 Qxe5 33. Re3 Qxe3 34. Kh3 Re4 35. Kg2 Rxg4 36. h3 h5 37. hxg4 hxg4 38. Kf1 Qf3+ 39. Kg1 Qe2 40. Kh1 Kf6 41. Kg1 Ke5 0-1",
    B: "1818",
  },
  {
    W: "2071",
    T: "60+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. Bc4 g6 4. a3 Bg7 5. d3 e6 6. Ba2 Nge7 7. Nge2 O-O 8. O-O d5 9. Ng3 b6 10. f4 Bb7 11. f5 exf5 12. exf5 f6 13. fxg6 hxg6 14. Qg4 Ne5 15. Qh3 Bc8 16. Qh4 g5 17. Qh5 Bg4 18. Bd2 Bxh5 19. Nxh5 Qd7 20. h3 Rae8 21. Ng3 f5 22. Nh5 f4 23. Nxg7 Kxg7 24. Rae1 d4 25. Ne4 Nd5 26. Nxg5 Ne3 27. Bxe3 fxe3 28. Ne4 Rxf1+ 29. Rxf1 Rf8 30. Re1 b5 31. Nxc5 Qf5 32. Ne4 Nf3+ 33. gxf3 Qxf3 34. Kh2 Kh6 35. Rg1 Qe2+ 36. Rg2 Rf2 37. Nxf2 exf2 0-1",
    B: "2276",
  },
  {
    W: "2172",
    T: "60+0",
    P: "1. e4 c6 2. f4 d5 3. e5 h5 4. d4 Nh6 5. h3 h4 6. Nf3 Nf5 7. Bd3 g6 8. Nc3 e6 9. Ne2 Be7 10. c3 Nd7 11. O-O Qc7 12. Ng5 Bxg5 13. fxg5 b6 14. b4 c5 15. Bxf5 gxf5 16. a3 Ba6 17. Bf4 Bc4 18. Rf3 Nf8 19. Rc1 Bxe2 20. Qxe2 c4 21. a4 Ng6 22. Bd2 Kd7 23. Rcf1 a6 24. Qf2 Kc8 25. Be1 Kb7 26. Qd2 Rh5 27. Bf2 Rg8 28. Be3 Qe7 29. Kh2 Nf8 30. Bf4 Nh7 31. g3 Nxg5 32. Bxg5 Rhxg5 33. g4 R8g7 34. R1f2 fxg4 35. hxg4 Rxg4 36. Rf6 R4g6 37. Qe2 Rxf6 38. exf6 Qd6+ 39. Qe5 Qxe5+ 40. dxe5 Rg6 41. Rg2 Rg3 42. Rxg3 hxg3+ 43. Kxg3 Kc6 44. Kf4 d4 45. cxd4 Kd5 46. Ke3 c3 47. Kd3 c2 0-1",
    B: "2342",
  },
  {
    W: "1465",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. Bxc6 dxc6 5. O-O Bf5 6. exf5 Bc5 7. Nxe5 O-O 8. d4 Bxd4 9. Nf3 Bb6 10. Qxd8 Raxd8 11. Nc3 c5 12. Be3 a5 13. Rad1 c6 14. Na4 Bc7 15. Nxc5 b5 16. Nb3 Ng4 17. h3 Bh2+ 18. Kh1 Nxe3 19. fxe3 Bd6 20. Nbd4 f6 21. Nxc6 Rc8 22. Rxd6 Rfe8 23. Re1 a4 24. Kg1 Kf7 25. a3 Rc7 26. Nfd4 g6 27. Rd7+ Rxd7 28. e4 Rc7 29. fxg6+ hxg6 30. Kf2 f5 31. exf5 Rxe1 32. Kxe1 Rc8 33. Kf2 Re8 34. Nxb5 Rb8 35. Kf3 Rb6 36. Ncd4 Rb7 37. g4 gxf5 38. gxf5 Re7 39. Kf4 Re2 40. Nd6+ Ke7 41. Nc8+ Kd7 42. Nb6+ 1-0",
    B: "1345",
  },
  {
    W: "1718",
    T: "60+0",
    P: "1. a4 Nf6 2. a5 e6 3. c3 d5 4. b4 c5 5. b5 a6 6. b6 Nc6 7. Nf3 Bd7 8. d4 c4 9. Qc2 Ne4 10. Nbd2 f5 11. Nxe4 fxe4 12. Nd2 e5 13. e3 exd4 14. exd4 Be7 15. Be2 O-O 16. Nf1 Bh4 17. Ng3 Qf6 18. Be3 Bg5 19. O-O Rf7 20. Nh5 Qg6 21. Ng3 Raf8 22. Bxg5 Qxg5 23. Qc1 Ne7 24. Qxg5 Nf5 25. Nxf5 Rxf5 26. Qe3 Be8 27. Bg4 Rf4 28. g3 Rxg4 29. h3 Rg6 30. Kh2 Rgf6 31. Rae1 Rf3 32. Qe2 Bh5 33. g4 Bg6 34. Kg2 Be8 35. Qxf3 Rxf3 0-1",
    B: "1749",
  },
  {
    W: "1675",
    T: "60+0",
    P: "1. e4 c5 2. b3 e6 3. Bb2 a6 4. Nf3 Nc6 5. Be2 Qc7 6. d3 Nf6 7. O-O Be7 8. Nfd2 h5 9. f4 Ng4 10. e5 Ne3 11. Qe1 Nxc2 12. Qf2 Nxa1 13. Bxa1 c4 14. dxc4 b6 15. Ne4 Bb7 16. Nbd2 Nb4 17. a3 Nc2 18. Bb2 Nxa3 19. Ra1 Bxe4 20. Nxe4 Nc2 21. Rc1 Nb4 22. Rd1 O-O 23. Nd6 a5 24. Bxh5 b5 25. Qh4 bxc4 26. Qh3 cxb3 27. Bf3 1-0",
    B: "1600",
  },
  {
    W: "1863",
    T: "60+0",
    P: "1. c4 g6 2. Nf3 Bg7 3. Nc3 d6 4. d4 Nc6 5. e3 e5 6. dxe5 Nxe5 7. Nxe5 Bxe5 8. Be2 Ne7 9. O-O O-O 10. h3 Bf5 11. e4 Be6 12. b3 Qc8 13. Qc2 Bxh3 14. gxh3 Qxh3 15. f4 Bd4+ 16. Rf2 Qg3+ 17. Kf1 Qxf2# 0-1",
    B: "1936",
  },
  {
    W: "2464",
    T: "60+0",
    P: "1. d4 c5 2. e3 cxd4 3. exd4 d5 4. Nf3 Nc6 5. Bd3 Nf6 6. c3 Bg4 7. O-O e6 8. Bf4 Bd6 9. Bxd6 Qxd6 10. Nbd2 O-O 11. h3 Bh5 12. Qc2 Rac8 13. Rfe1 Nb4 14. Qb1 Nxd3 15. Qxd3 Bg6 16. Qe2 Ne4 17. Ne5 Nxd2 18. Qxd2 b5 19. a3 a5 20. Nd3 Bxd3 21. Qxd3 b4 22. axb4 axb4 23. Ra6 Qb8 24. Rc1 bxc3 25. bxc3 Qb2 26. Qc2 Qxc2 27. Rxc2 Rc4 28. Raa2 Rfc8 29. Kf1 h5 30. Ke2 Rxc3 31. Rxc3 Rxc3 32. Rd2 Kh7 33. Rd1 Kg6 34. Kd2 Rc4 35. Kd3 Kf5 36. g4+ hxg4 37. hxg4+ Kf4 38. g5 Kxg5 39. Rg1+ Kf6 40. Rg3 Ra4 41. Rf3+ Ke7 42. Rf4 Ra3+ 43. Kd2 g5 44. Rg4 Kf6 45. Rg2 Rf3 46. Ke2 Rf4 47. Rg3 Rxd4 48. Ke3 Re4+ 49. Kd3 Rf4 50. Re3 Kf5 51. Re1 e5 52. Rb1 f6 53. Rb8 d4 54. Rd8 Rf3+ 55. Kd2 g4 56. Ke2 Ke4 57. Rf8 f5 58. Rxf5 d3+ 59. Ke1 Rxf5 60. Kf1 Rf3 61. Kg1 Kd4 62. Kg2 e4 63. Kf1 d2 64. Kg2 d1=Q 0-1",
    B: "2590",
  },
  {
    W: "1792",
    T: "-",
    P: "1. e4 e5 2. Nf3 1-0",
    B: "1500",
  },
  {
    W: "1778",
    T: "300+0",
    P: "1. e4 e5 2. d4 exd4 3. Nf3 d6 4. Nxd4 Be7 5. Nc3 Nf6 6. Be3 O-O 7. Be2 c6 8. O-O b5 9. Nf5 b4 10. Na4 Nxe4 11. Bf3 Bxf5 0-1",
    B: "1743",
  },
  {
    W: "1563",
    T: "180+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bc5 4. a3 Nf6 5. b4 Bd4 6. Bb5 Bxc3 7. dxc3 Nxe4 8. Bxc6 bxc6 9. O-O f6 10. Qd3 O-O 11. Qxe4 1-0",
    B: "1547",
  },
  {
    W: "1722",
    T: "300+0",
    P: "1. d4 d5 2. c4 e6 3. Nf3 c5 4. Nc3 Nc6 5. Bg5 Be7 6. Bxe7 Qxe7 7. e3 Nf6 8. Bd3 O-O 9. O-O Rd8 10. Qc2 dxc4 11. Bxc4 cxd4 12. Nxd4 Nxd4 13. exd4 Rxd4 14. Bd3 Bd7 15. Rfe1 Rc8 16. Qe2 Qd6 17. Bb5 Rd2 18. Qf3 Bxb5 19. Nxb5 Qb6 20. a4 a6 21. Nc3 Qxb2 22. Ne4 Nxe4 23. Qxe4 Rxf2 24. Kh1 Rcc2 25. Red1 g6 26. Rd8+ Kg7 27. Qd4+ Qxd4 28. Rxd4 Rxg2 29. Rg1 Rxh2# 0-1",
    B: "1633",
  },
  {
    W: "1789",
    T: "180+0",
    P: "1. e4 c5 2. d4 cxd4 3. c3 d6 4. cxd4 Nf6 5. Nc3 e6 6. f4 Be7 7. Nf3 O-O 8. Bd3 Nc6 9. Qc2 Nb4 10. Qb1 Nxd3+ 11. Qxd3 a6 12. a4 Bd7 13. Be3 Ng4 14. Bg1 Nf6 15. h3 Rb8 16. Be3 b5 17. axb5 axb5 18. b4 d5 19. e5 Ne8 20. g4 Bxb4 21. O-O f6 22. Rab1 Bxc3 23. Qxc3 Rc8 24. Qd3 Rc4 25. Nd2 Rc8 26. Nb3 fxe5 27. fxe5 Rxf1+ 28. Qxf1 Qh4 29. Nc5 Rc7 30. Nxd7 Rxd7 31. Rxb5 Rd8 32. Kg2 h6 33. Qf2 Qe7 34. Qb2 Rc8 35. Bd2 Qh4 36. Rb6 Qe7 37. Bb4 Qd7 38. Bc5 Qa4 39. Rxe6 Qd1 40. Qf2 Nc7 41. Re7 Rf8 42. Rxg7+ Kxg7 43. Qxf8+ Kg6 44. Qg8# 1-0",
    B: "1829",
  },
  {
    W: "1494",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nxc6 bxc6 6. Bd3 e5 7. Kf1 d5 8. exd5 cxd5 9. Nc3 Bc5 10. Bg5 O-O 11. Nxd5 Qxd5 12. Bxf6 Bb7 13. f3 gxf6 14. Qd2 e4 15. fxe4 Qd4 16. Rd1 Rae8 17. c3 Qe5 18. Qh6 Bxe4 19. Bxe4 Qxe4 20. Qxf6 Qe2# 0-1",
    B: "1539",
  },
  {
    W: "1301",
    T: "300+5",
    P: "1. d4 Nf6 2. e3 d5 3. g4 Bxg4 4. f3 Bh5 5. Qe2 e6 6. Qb5+ Nbd7 7. Qxb7 Bxf3 8. Nxf3 g5 9. Nxg5 1-0",
    B: "1548",
  },
  {
    W: "1699",
    T: "120+0",
    P: "1. d4 b5 2. e4 Bb7 3. f3 a6 4. b3 e6 5. Bb2 c5 6. Nd2 Qh4+ 7. g3 Qd8 8. Bh3 Nf6 9. dxc5 Bxc5 10. Bxf6 Qxf6 11. Ne2 O-O 12. Rb1 Ba7 13. Nf4 Qh6 14. Bg2 Nc6 15. Qe2 Nd4 16. Qd1 Nc6 17. c3 Ne7 18. Ne2 f5 19. e5 Nd5 20. f4 Ne3 21. Bxb7 Nxd1 22. Bxa8 Nxc3 23. Nxc3 Rxa8 24. Nf3 Qh5 25. Rf1 Qg4 26. Ne2 Rc8 27. Rd1 Rc7 28. a4 bxa4 29. bxa4 h6 30. Nc1 Qg6 31. Ke2 Qe8 32. Rd3 Qb8 33. Rfd1 Qb2+ 34. R3d2 Qb4 35. Nd3 Qxa4 36. Nb2 Qb5+ 37. Ke1 Qb6 38. Nd3 Rc3 0-1",
    B: "1782",
  },
  {
    W: "1584",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bc4 Nxe4 5. Nxe4 d5 6. Bb5 dxe4 7. Ng1 Bd7 8. Qh5 Be7 9. b3 O-O 10. Bb2 Bd6 11. O-O-O a6 12. Be2 Qe7 13. Nh3 Ba3 14. Ng5 h6 15. Nxe4 Bxb2+ 16. Kxb2 Nd4 17. Bc4 b5 18. Bd3 Be6 19. c3 Nf5 20. g4 Nd6 21. g5 Nxe4 22. gxh6 g6 23. Qxe5 Nxf2 24. Qg7# 1-0",
    B: "1689",
  },
  {
    W: "2052",
    T: "30+0",
    P: "1. e3 d6 2. Nf3 c6 3. Be2 Nd7 4. O-O Qc7 5. b3 Ngf6 6. Bb2 e5 7. d4 Be7 8. dxe5 dxe5 9. c4 O-O 10. Nbd2 a5 11. Ng5 Rd8 12. Nge4 b6 13. Qc2 Nc5 14. Nxc5 Bxc5 15. Nf3 Bg4 16. Bxe5 Qb7 17. Bg3 Bxf3 18. Bxf3 Rdc8 19. Rad1 Rd8 20. Qf5 Rxd1 21. Rxd1 Be7 22. Be5 Rd8 23. Bxf6 Rxd1+ 24. Bxd1 Bxf6 25. Bc2 Qb8 26. Qxh7+ Kf8 27. g3 Ke7 28. Qe4+ Kf8 29. Qh7 Qe8 30. Qh8+ Ke7 31. Qxe8+ Kxe8 0-1",
    B: "1976",
  },
  {
    W: "1567",
    T: "180+0",
    P: "1. e4 g6 2. d4 Bg7 3. Nf3 c6 4. c4 d6 5. Nc3 Bg4 6. Be2 Nf6 7. h3 Bd7 8. Be3 Qc8 9. Qd2 O-O 10. O-O-O b6 11. g4 a5 12. h4 c5 13. g5 cxd4 14. Bxd4 Nh5 15. Bxg7 Kxg7 16. Qd4+ e5 17. Qxd6 Rd8 18. Qxe5+ Kg8 19. Nd5 Re8 20. Nf6+ Nxf6 21. Qxf6 Ba4 22. Ne5 Ra7 23. h5 Bxd1 24. hxg6 fxg6 25. Nxg6 Rg7 26. Ne5 Bxe2 27. g6 Bxc4 28. gxh7+ Kh8 29. Ng6# 1-0",
    B: "1568",
  },
  {
    W: "2305",
    T: "120+0",
    P: "1. d4 d6 2. c4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. g3 O-O 6. Bg2 c5 7. O-O cxd4 8. Nxd4 Nc6 9. Nxc6 bxc6 10. Bxc6 Bh3 11. Bg2 Qd7 12. Re1 Rac8 13. b3 Rc5 14. Bb2 Rh5 15. Bf3 Bg4 16. e3 Bxf3 17. Qxf3 Qh3 18. Qg2 Qg4 19. Nd5 Nxd5 20. Bxg7 Kxg7 21. cxd5 Rc8 22. Rec1 Rxc1+ 23. Rxc1 Qb4 24. Qf3 Qd2 25. Rc7 Qxa2 26. Rxe7 Qxb3 27. Qxf7+ Kh6 28. h4 Rxh4 29. Qxh7+ Kg5 30. Qxh4+ Kf5 31. Qe4+ Kg5 32. Qf4+ Kh5 33. Rh7# 1-0",
    B: "2232",
  },
  {
    W: "1870",
    T: "60+0",
    P: "1. g3 d5 2. Bg2 Nf6 3. d3 Bd7 4. Bg5 Bc6 5. Bxf6 gxf6 6. e4 d4 7. f4 e5 8. fxe5 fxe5 9. Nf3 f6 10. O-O Bd6 11. Nbd2 Nd7 12. Nc4 Qe7 13. Nxd6+ cxd6 14. Qd2 O-O-O 15. Qh6 Rdg8 16. Nh4 Nb6 17. Nf5 Qd7 18. h4 Rg6 19. Qd2 Rhg8 20. h5 Rg5 21. Bh3 Kb8 22. g4 Qc7 23. Rf2 Rxh5 24. Kh2 Rhg5 25. Rg2 Bd7 26. Rf1 d5 27. exd5 Nxd5 28. c4 Nf4 29. Ne7 R8g6 30. Nxg6 Rxg6 31. Rgf2 Rh6 32. Rf3 Bc6 33. b4 Bxf3 34. Rxf3 a6 35. c5 b6 36. cxb6 Qxb6 37. a4 Qc7 38. b5 axb5 39. axb5 Kb7 40. Kg1 Nxh3+ 41. Rxh3 Rxh3 42. Qg2+ Rf3 43. Qh3 Rxh3 44. Kg2 Rxd3 45. Kf2 Qc3 46. g5 Qc2+ 0-1",
    B: "1866",
  },
  {
    W: "1682",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 d6 3. c3 Nc6 4. d4 cxd4 5. cxd4 e5 6. Bd3 exd4 7. Bb5 Bd7 8. Bxc6 Bxc6 9. Nxd4 Nf6 10. O-O Be7 11. Nxc6 bxc6 12. Re1 O-O 13. Nc3 Nd7 14. Be3 Ne5 15. f4 Nc4 16. Bf2 Bf6 17. Qc2 a5 18. b3 Nb6 19. Rac1 c5 20. Nd5 Nxd5 21. exd5 Rc8 22. a3 a4 23. b4 cxb4 24. Qb2 Rxc1 25. Rxc1 b3 26. Be1 Bxb2 0-1",
    B: "1623",
  },
  {
    W: "2629",
    T: "60+0",
    P: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 e6 6. Bc4 Nf6 7. O-O Qc7 8. Qe2 Ng4 9. Nb5 Qb8 10. e5 a6 11. Nd6+ Bxd6 12. exd6 Qxd6 13. Rd1 Qc7 14. Be3 b5 15. Bd3 Bb7 16. Rac1 Rc8 17. h3 h5 18. Be4 f5 19. Bxc6 Bxc6 20. Bc5 Qb8 21. hxg4 hxg4 22. Ne5 Be4 23. Bd6 Qb6 24. Rxc8+ Qd8 25. Rxd8+ Kxd8 26. Nf7+ Ke8 27. Nxh8 Bxg2 28. Kxg2 1-0",
    B: "2212",
  },
  {
    W: "1257",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qe5+ 4. Be2 Bg4 5. f3 Bd7 6. Nh3 Bxh3 7. gxh3 Nc6 8. d3 O-O-O 9. f4 Qd4 10. Bd2 e6 11. Nb5 Qb6 12. d4 a6 13. Nc3 Qxd4 14. Bf3 Bc5 15. Qe2 Nf6 16. O-O-O Ne4 17. Be3 Qxe3+ 18. Qxe3 Bxe3+ 19. Kb1 Nxc3+ 20. bxc3 Bxf4 21. Rxd8+ Rxd8 22. Rg1 Ne5 23. Rxg7 1-0",
    B: "1276",
  },
  {
    W: "1620",
    T: "60+0",
    P: "1. e4 Nf6 2. Nf3 Nxe4 3. d3 Nf6 4. Bg5 e6 5. Qe2 Be7 6. Qe5 d6 7. Qb5+ c6 8. Qb3 a5 9. c4 h6 10. Bh4 a4 11. Qb4 Bd7 12. Qxb7 Na6 13. b3 Nc5 14. Qb4 axb3 15. Nc3 bxa2 16. Nxa2 O-O 17. d4 Nce4 18. Bd3 d5 19. cxd5 Bxb4+ 20. Ke2 Nc3+ 21. Nxc3 Rxa1 22. Rxa1 Bxc3 23. Ra3 Bb4 24. Ne5 Qc7 25. Rb3 c5 26. dxc5 Bxc5 27. Nxd7 Qxd7 28. Rb5 Qxd5 29. Bg3 Qa2+ 30. Kf3 Nd5 31. Rxc5 Nf6 32. Re5 Qa8+ 33. Be4 Qa3+ 34. Kf4 Rd8 35. Rxe6 g5+ 36. Kf5 Nh7 37. f3 Rd5+ 38. Kg4 Rf5 39. Bd5 Rf4+ 40. Kh5 1-0",
    B: "1560",
  },
  {
    W: "1465",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Nc3 g6 4. d4 cxd4 5. Nxd4 a6 6. Nxc6 bxc6 7. Qd4 Bg7 8. Qxg7 Qb6 9. Qxh8 Kf8 10. Bh6+ 1-0",
    B: "1535",
  },
  {
    W: "1716",
    T: "60+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. d4 d6 4. c3 Bg4 5. Bd3 Nd7 6. h3 Bxf3 7. Qxf3 e5 8. dxe5 Nxe5 9. Bc2 Nxf3+ 10. gxf3 Nf6 11. O-O O-O 12. e5 dxe5 13. f4 exf4 14. Bxf4 c6 15. Nd2 Nh5 16. Bh2 Qxd2 17. Rac1 Bh6 18. Bb3 Qxc1 19. Rxc1 Bxc1 20. Be5 Bxb2 21. c4 Bxe5 0-1",
    B: "1855",
  },
  {
    W: "1141",
    T: "60+0",
    P: "1. d4 Nf6 2. Nf3 g6 3. Bf4 Bg7 4. e3 d6 5. Nbd2 Nc6 6. Be2 O-O 7. c3 e5 8. dxe5 dxe5 9. Nxe5 Nxe5 10. Bxe5 Be6 11. O-O Qd5 12. Bg3 Rad8 13. Bf3 Qxd2 14. Bxc7 Qxf2+ 15. Rxf2 Rxd1+ 16. Rxd1 Ne4 17. Bxe4 Bxa2 18. Rd8 Rxd8 19. Bxd8 Be6 20. Bf6 Bxf6 21. Rxf6 Kg7 22. Rf2 b5 23. Rd2 a5 24. Bd5 Bxd5 25. Rxd5 a4 26. Rxb5 a3 0-1",
    B: "997",
  },
  {
    W: "1965",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. Bxc6 dxc6 5. O-O Bg4 6. h3 Bh5 7. g4 Bg6 8. Nxe5 Qf6 9. d4 Bd6 10. g5 Qf4 11. Bxf4 1-0",
    B: "1958",
  },
  {
    W: "1381",
    T: "60+0",
    P: "1. f4 f5 2. Nf3 e5 3. fxe5 Bc5 4. d3 d6 5. Bg5 Nf6 6. exf6 gxf6 7. Bf4 Nc6 8. Qd2 Qe7 9. b4 Bd4 10. Nxd4 Nxd4 11. c3 Nc6 12. d4 Bd7 13. d5 Ne5 14. Bxe5 fxe5 15. e3 O-O-O 16. Na3 a6 17. b5 axb5 18. Nxb5 Bxb5 19. Bxb5 c6 20. dxc6 bxc6 21. Bxc6 Qc7 22. Qd5 Rhe8 23. Ba8 Kd7 24. Qb5+ Ke6 25. Bd5+ Kf6 26. c4 Rb8 27. Qc6 Qxc6 28. Bxc6 Rb3 29. Bxe8 Rb2 30. Rd1 Ke6 31. Rd2 Ke7 32. Rxb2 Kxe8 33. Rb8+ Kd7 34. Kd2 Ke6 35. Ra1 Kf6 36. a4 Ke7 37. a5 Ke6 38. a6 Kf6 39. a7 Kg5 40. a8=Q Kg4 41. Qf3+ Kg5 42. h4+ Kf6 43. e4 Ke6 44. Qxf5+ Ke7 0-1",
    B: "1427",
  },
  {
    W: "1744",
    T: "180+0",
    P: "1. e4 e5 2. f4 exf4 3. Bc4 c6 4. Nf3 d5 5. exd5 cxd5 6. Bb3 Nf6 7. O-O Bc5+ 8. d4 O-O 9. dxc5 1-0",
    B: "1704",
  },
  {
    W: "2024",
    T: "0+1",
    P: "1. f4 c5 2. b3 Nc6 3. Bb2 e6 4. e3 d5 5. Bb5 Nf6 6. Bxc6+ bxc6 7. Nf3 Bd6 8. Ne5 Qc7 9. d3 O-O 10. Nd2 Bd7 11. O-O h6 12. Ndf3 Rae8 13. Qe1 Nh7 14. Qg3 f6 15. Ng6 Rf7 16. Nfh4 e5 17. fxe5 fxe5 18. e4 d4 19. Rxf7 Kxf7 20. Rf1+ Kg8 21. Bc1 Be7 22. Bxh6 gxh6 23. Nxe5+ Kh8 24. Nhg6+ Kg7 25. Nxe7+ Kh8 26. Nxd7 Qxd7 27. Ng6+ Kg7 28. Ne5+ Qg4 29. Qxg4+ 1-0",
    B: "1927",
  },
  {
    W: "1572",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nxd4 5. Qxd4 e6 6. Be3 d5 7. exd5 Qxd5 8. Qxd5 exd5 9. Nc3 Be6 10. O-O-O Nf6 11. h3 Bb4 12. Bd4 a5 13. Bb5+ Ke7 14. g4 Rhg8 15. f4 g6 16. Rhe1 Kd6 17. Bxf6 Rgc8 18. Be5+ Kc5 19. Bf1 a4 20. a3 Ba5 21. Bg2 b5 22. Bxd5 b4 23. axb4+ Bxb4 24. Bxe6 fxe6 25. Re2 a3 26. Ne4+ Kb6 27. bxa3 Rxa3 28. Bb2 Ra2 29. Kb1 Rca8 30. Ng5 h6 31. Rxe6+ Kc5 32. Ne4+ Kc4 33. Rd4+ Kb5 34. Re5+ Ka4 35. Nc3# 1-0",
    B: "1790",
  },
  {
    W: "1721",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 e5 7. Nf3 Be6 8. Nd5 Bxd5 9. exd5 Nbd7 10. c4 Be7 11. b3 Rc8 12. g3 e4 13. Nd4 Ne5 14. Bg2 Nd3+ 15. Kd2 Qa5+ 16. Kc2 b5 17. Kb1 bxc4 18. Nc6 Qc3 19. Bd4 Qxd4 20. Nxd4 Nxf2 21. Qe1 Nxh1 22. Qxh1 c3 23. Kc2 O-O 24. Bxe4 Nxe4 25. Qxe4 Bf6 26. Nf5 Rfe8 27. Qd3 Rcd8 28. Qxa6 Re2+ 29. Qxe2 g6 30. Nh6+ Kg7 31. Qf3 Be5 32. Ng4 Bf6 33. Qxf6+ 1-0",
    B: "1604",
  },
  {
    W: "1670",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nd4 4. Nxd4 exd4 5. c3 c6 6. Bc4 d5 7. exd5 cxd5 8. Bb5+ Bd7 9. Bxd7+ Qxd7 10. cxd4 Nf6 11. Nc3 Bb4 12. Qa4 Qxa4 13. Nxa4 O-O 14. O-O Ne4 15. a3 Bxd2 16. f3 Bxc1 17. Raxc1 Nd2 18. Rfe1 Nc4 19. Nc5 b6 20. Nd3 Rac8 21. Ne5 Nxb2 22. Rxc8 Rxc8 23. Ng4 h5 24. Ne5 Rc7 25. Re2 Nc4 26. Rc2 b5 27. Kf2 f6 28. Nd3 Re7 29. Nc5 Nxa3 30. Ra2 Nc4 31. Re2 Rxe2+ 32. Kxe2 a5 33. Kd3 a4 34. Kc3 a3 35. Kb3 b4 36. Kxb4 a2 37. Nb3 Nd2 38. Na1 Kf7 39. Kc5 Ke6 40. Kb4 g5 41. Ka3 Nf1 42. h3 Ng3 43. Kxa2 Nf1 44. Nb3 Ne3 45. Nc5+ Kd6 46. g3 Nf1 47. g4 Nd2 48. gxh5 Nxf3 49. h6 g4 50. hxg4 1-0",
    B: "1685",
  },
  {
    W: "1115",
    T: "180+0",
    P: "1. Nc3 e5 2. Nf3 d6 3. e4 f5 4. Bc4 fxe4 5. Nxe4 Nf6 6. Nxf6+ gxf6 7. d3 Nc6 8. Be3 d5 9. Bb3 b6 10. O-O Ba6 11. c3 e4 12. Nd4 Nxd4 13. Bxd4 Bxd3 14. Qh5+ Kd7 15. Qg4+ Ke7 16. Bxd5 Qxd5 17. Rad1 Bxf1 18. Bxf6+ Kxf6 19. Rxd5 Bc5 20. Kxf1 Rhg8 21. Qf5+ Ke7 22. Qe5+ Kf7 23. Rd7+ Kg6 24. h4 Bxf2 25. Qg5# 1-0",
    B: "1022",
  },
  {
    W: "1817",
    T: "180+0",
    P: "1. d4 d5 2. Nf3 Bf5 3. c4 dxc4 4. e3 e6 5. Bxc4 Nc6 6. Nc3 Nf6 7. e4 Bg6 8. O-O Nxe4 9. Nxe4 Bxe4 10. Ne5 Nxe5 11. dxe5 Qxd1 12. Rxd1 Be7 13. Bf4 O-O 14. a4 c6 15. b3 Rad8 16. Kf1 Rxd1+ 17. Rxd1 Rd8 18. Rxd8+ Bxd8 19. f3 Bg6 20. g4 h6 21. Ke2 Kf8 22. Be3 b6 23. Ba6 c5 24. Kd2 f6 25. f4 Bc7 26. exf6 gxf6 27. h4 e5 28. fxe5 Bxe5 29. g5 hxg5 30. hxg5 fxg5 31. Bxg5 Ke8 32. Bb5+ Kf7 33. Kc1 Ke6 34. Bc4+ Kf5 35. Bd2 Kf6 36. b4 cxb4 37. Bxb4 Bf7 38. Bd3 Bd5 39. Bd2 Bc6 40. a5 bxa5 41. Bxa5 Bd4 42. Bd8+ Ke6 43. Bc4+ Kd7 44. Ba5 Bb6 45. Bc3 Kd6 46. Kb2 Bd5 47. Bb4+ Ke5 48. Bc3+ Kd6 49. Bb4+ Ke5 50. Bc3+ Kd6 1/2-1/2",
    B: "1505",
  },
  {
    W: "2158",
    T: "180+0",
    P: "1. d4 Nf6 2. Bf4 d5 3. e3 e6 4. h3 Bd6 5. Bxd6 Qxd6 6. Nd2 O-O 7. Ngf3 c5 8. c3 Nc6 9. Be2 e5 10. O-O e4 11. Ne1 cxd4 12. cxd4 b6 13. Rc1 Bf5 14. a3 Rac8 15. b4 Ne7 16. Ba6 Rxc1 17. Qxc1 Ng6 18. f4 Qe6 19. Qc7 Nh4 20. Kh2 Nh5 21. g4 Bxg4 22. hxg4 Qxg4 23. Rg1 Qe2+ 24. Bxe2 1-0",
    B: "2266",
  },
  {
    W: "1425",
    T: "300+0",
    P: "1. d4 g6 2. Bf4 Bg7 3. Nc3 Nf6 4. f3 O-O 5. e4 c6 6. e5 Nd5 7. Nxd5 cxd5 8. g4 Qa5+ 9. c3 b5 10. h4 b4 11. Bd2 Nc6 12. a3 bxc3 13. Bxc3 Qb6 14. h5 Ba6 15. Bxa6 Qxa6 16. Nh3 f6 17. hxg6 hxg6 18. Qc2 fxe5 19. Ng5 exd4 20. Qxg6 Rf6 21. Qh7+ Kf8 22. Qd3 Qxd3 0-1",
    B: "1443",
  },
  {
    W: "1830",
    T: "300+0",
    P: "1. d4 e5 2. dxe5 Nc6 3. Nf3 Qe7 4. Bf4 Qb4+ 5. Bd2 Qxb2 6. Bc3 Bb4 7. Qd2 Bxc3 8. Qxc3 Qc1# 0-1",
    B: "1817",
  },
  {
    W: "1922",
    T: "300+0",
    P: "1. d4 d5 2. c4 dxc4 3. e4 b5 4. b3 Nc6 5. d5 Ne5 6. bxc4 bxc4 7. f4 Nd3+ 8. Bxd3 cxd3 9. Qxd3 Nf6 10. Nf3 e6 11. Nc3 Bb4 12. Qb5+ Bd7 13. Qxb4 Rb8 14. Qd4 O-O 15. O-O exd5 16. exd5 Bf5 17. Ba3 Re8 18. Ne5 Qc8 19. Nc6 Rb6 20. Ne7+ Rxe7 21. Bxe7 1-0",
    B: "1922",
  },
  {
    W: "1464",
    T: "300+0",
    P: "1. e3 e5 2. g3 d5 3. b3 c6 4. Bb2 Nf6 5. Bxe5 Nfd7 6. Bb2 a5 7. Nf3 a4 8. Ne5 a3 9. Nxa3 Bxa3 10. Bxa3 c5 11. Bb2 Nxe5 12. Bxe5 O-O 13. Bd3 Nc6 14. Qh5 g6 15. Qh6 Nxe5 16. f4 Nf3+ 17. Ke2 Nd4+ 18. exd4 cxd4 19. c4 Qe7+ 20. Kd1 dxc4 21. bxc4 Bg4+ 22. Kc2 Qa3 23. h3 Bf3 24. Rhf1 Bg2 25. Rfe1 Qa4+ 26. Kb2 Qb4+ 27. Kc2 b5 28. cxb5 Rfc8+ 29. Kd1 Bf3+ 30. Be2 Qa4# 0-1",
    B: "1462",
  },
  {
    W: "2108",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6 7. f4 Be7 8. Qf3 Qc7 9. g4 Nbd7 10. h4 h6 11. Bxf6 Bxf6 12. O-O-O b5 13. g5 hxg5 14. fxg5 Bxd4 15. Rxd4 Ne5 16. Qd1 Ke7 17. Bxb5 axb5 18. Nxb5 Qc5 19. Nxd6 Rxa2 20. Kb1 Qa5 21. Nxc8+ Rxc8 22. b4 Ra1+ 23. Kb2 Qa3# 0-1",
    B: "2077",
  },
  {
    W: "1451",
    T: "300+0",
    P: "1. d4 g6 2. Nf3 Bg7 3. c4 d6 4. e3 Nf6 5. Nc3 O-O 6. Bd3 Nc6 7. O-O Bg4 8. Be2 Re8 9. a3 Na5 10. b4 Nc6 11. d5 Ne5 12. Nxe5 Bxe2 13. Qxe2 dxe5 14. e4 c6 15. Bb2 cxd5 16. exd5 Qd7 17. Rad1 a6 18. Ne4 Nxe4 19. Qxe4 f5 20. Qb1 b5 21. c5 a5 22. Rfe1 e6 23. Bxe5 Bxe5 24. Rxe5 exd5 25. Rdxd5 Qa7 26. Qa2 Kf8 27. Qe2 axb4 28. Rxe8+ Rxe8 29. Re5 Rxe5 30. Qxe5 Kf7 31. g3 bxa3 32. Qd5+ Kf6 33. Qd6+ Kg5 34. c6 a2 35. Qf4+ Kf6 36. Qd6+ Kg5 37. Qf4+ Kf6 38. Qd6+ Kf7 39. Qd5+ Kf6 40. Qd6+ 1/2-1/2",
    B: "1447",
  },
  {
    W: "1753",
    T: "300+0",
    P: "1. e4 c6 2. Bc4 d5 3. exd5 cxd5 4. Bb3 Nc6 5. c3 a6 6. d4 Bf5 7. Nf3 e6 8. h3 Nf6 9. Nbd2 Bd6 10. Nh4 Be4 11. Nxe4 Nxe4 12. Bc2 Nf6 13. Bg5 h6 14. Be3 O-O 15. O-O Nh5 16. Qxh5 b5 17. Bxh6 Qc8 18. Bxg7 1-0",
    B: "1759",
  },
  {
    W: "1297",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O d6 5. c3 Nf6 6. h3 O-O 7. b4 Bb6 8. b5 Na5 9. Na3 Nxc4 10. Nxc4 Bc5 11. Re1 c6 12. d4 exd4 13. cxd4 Bb6 14. Bf4 d5 15. exd5 cxd5 16. Nd6 Nh5 17. Bg5 f6 18. Bd2 Qxd6 19. Rb1 Nf4 20. Bb4 Qd8 21. Bxf8 Qxf8 22. Nh4 Qf7 23. Nf5 Bxf5 24. g4 Qg6 25. Re7 Nxh3+ 26. Kh2 Nxf2 27. Qf3 Nxg4+ 28. Kg3 Ne5+ 0-1",
    B: "1229",
  },
  {
    W: "1563",
    T: "300+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 d6 6. Nxg4 f5 7. exf5 Bxf5 8. Bc4 Qe7+ 9. Kf1 Qd7 10. Nf2 Nf6 11. d3 Nc6 12. Bxf4 O-O-O 13. Bb5 a6 14. Ba4 b5 15. Bb3 Ne5 16. Bxe5 dxe5 17. Qf3 Kb8 18. a4 Bc5 19. axb5 Bxf2 20. Rxa6 Nd5 21. Qxf2 Nb6 22. Nd2 Bxd3+ 23. Kg1 Rdf8 24. Qe3 Bg6 25. Nc4 Qd1+ 26. Kh2 Qg4 27. Nxb6 Qxh4+ 28. Kg1 Qd4 29. Nd7+ Kb7 30. Qxd4 Rf1+ 31. Kxf1 exd4 32. Bd5+ Kc8 33. Ra8+ Kxd7 34. Rxh8 Kd6 35. Bb3 Bxc2 36. Bxc2 Kd5 37. R8xh7 Kc5 38. Rxc7+ Kxb5 39. Rh5+ Kb4 40. Rb7+ 1-0",
    B: "1553",
  },
  {
    W: "1713",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. c3 Nf6 4. d3 h6 5. g3 a6 6. Bg2 Bc5 7. h3 d6 8. O-O Be6 9. d4 exd4 10. cxd4 d5 11. dxc5 Qd7 12. exd5 Nxd5 13. Qe2 O-O 14. Rd1 Bxh3 15. Nh2 Bxg2 16. Kxg2 Nce7 17. Nc3 Qc6 18. Nxd5 Nxd5 19. b4 Nf4+ 20. Kf1 Nxe2 21. Kxe2 Rfe8+ 22. Kf1 Qb5+ 23. Kg1 Qe2 24. Rf1 Rad8 0-1",
    B: "1713",
  },
  {
    W: "1976",
    T: "300+0",
    P: "1. e4 d5 2. e5 e6 3. Nf3 c5 4. c4 d4 5. Bd3 Nc6 6. O-O Qc7 7. Re1 g6 8. Qe2 Bg7 9. Na3 a6 10. Nc2 Nge7 11. Qe4 O-O 12. Re2 Nf5 13. g3 b6 14. Nce1 Bb7 15. Qf4 Bh6 16. Qg4 Nce7 17. Ng2 Rad8 18. Be4 Bxe4 19. Qxe4 Nc6 20. Nf4 Bg7 21. a3 a5 22. d3 h6 23. Nd2 Nxe5 24. Nf3 Nxf3+ 25. Qxf3 Rfe8 26. Bd2 Re7 27. Rae1 Rde8 28. Ng2 Qd7 29. b3 e5 30. Qe4 Nd6 31. Qh4 g5 32. Qh5 Nf5 33. h4 gxh4 34. Nxh4 Nxh4 35. Qxh4 Re6 36. Re4 f5 37. R4e2 Rg6 38. Kh2 Qe6 39. Rh1 e4 40. Bf4 Qc6 41. a4 exd3 42. Rxe8+ Qxe8 43. Qh3 Qe2 44. Qg2 d2 45. Qd5+ Kh7 46. Bxd2 Qxd2 47. Kg2 1-0",
    B: "1952",
  },
  {
    W: "1334",
    T: "300+0",
    P: "1. c3 d5 2. d4 Nc6 3. e3 Nf6 4. f4 Bf5 5. g3 e6 6. h4 Be4 7. Bd3 Bxh1 8. g4 Be4 9. g5 Bxd3 10. Qxd3 Ng4 11. Nf3 h6 12. Ne5 Ncxe5 13. fxe5 hxg5 14. e4 gxh4 15. exd5 exd5 16. Bf4 f6 17. Qg6+ Ke7 18. Qxg4 fxe5 19. Bxe5 h3 20. Qg5+ Ke8 21. Qg6+ Ke7 22. Qg5+ Kf7 23. Qf5+ Kg8 24. Qe6+ Kh7 25. Qf5+ Kg8 26. Qe6+ Kh7 27. Nd2 Be7 28. O-O-O Bg5 29. Kc2 Bf6 30. Rh1 Bxe5 31. Rxh3+ Qh4 32. Rxh4# 1-0",
    B: "1298",
  },
  {
    W: "1563",
    T: "300+0",
    P: "1. e3 e5 2. c3 c5 3. Qa4 Nc6 4. Nf3 d6 5. Ng1 f5 6. Nf3 Nf6 7. h4 e4 8. Ng5 h6 9. Nh3 Bd7 10. Qb3 Na5 11. Qd1 d5 12. d3 exd3 13. Qxd3 Bc6 14. Qxf5 Qe7 15. Nf4 Nc4 16. Ng6 Qe4 17. Qxe4+ Nxe4 18. Nxh8 O-O-O 19. Ng6 d4 20. Bxc4 dxe3 21. Bxe3 Bd6 22. Be6+ Bd7 23. Bxd7+ Rxd7 24. Nd2 Nf6 25. Nf4 Re7 26. Ng6 Re8 27. Nc4 Bc7 28. Nd2 Ng4 29. Ke2 Re6 30. Nf8 Re8 31. Ng6 Re6 32. Nf8 Re8 33. Kf3 Nxe3 34. fxe3 Rxf8+ 35. Ke2 Bg3 36. Kd3 Rd8+ 37. Ke2 Kc7 38. Kf3 Bd6 39. a4 Kc6 40. a5 b5 41. a6 Rf8+ 42. Ke4 Rf2 43. Kd3 Rxg2 44. Kc2 Re2 45. Rhg1 Rxe3 46. Rxg7 Rh3 47. Rxa7 Rxh4 48. Rg7 Re4 49. a7 Re8 50. a8=Q+ Rxa8 51. Rxa8 Kd5 52. Rh7 Bf4 53. Rf8 Bg5 54. Rf5+ Kc6 55. Rxg5 hxg5 56. Rg7 b4 57. Rxg5 bxc3 58. bxc3 Kb5 59. Ne4 Kb6 60. Nxc5 Kb5 61. Nd7+ Kc4 62. Nf8 1/2-1/2",
    B: "1573",
  },
  {
    W: "1515",
    T: "300+0",
    P: "1. Nf3 d6 2. g3 Nf6 3. Bg2 Bd7 4. d4 c6 5. c3 d5 6. Qb3 Qc8 7. Ne5 Bh3 8. O-O Bxg2 9. Kxg2 Ne4 10. f3 Nf6 11. Bg5 g6 12. Bxf6 exf6 13. Ng4 Bg7 14. e4 dxe4 15. fxe4 O-O 16. Nxf6+ Bxf6 17. Rxf6 Qg4 18. Qxb7 Nd7 19. Rxc6 Qe2+ 20. Kh3 Nb6 21. e5 Qxb2 22. Rc7 Qxa1 23. Rxf7 Rxf7 24. Qxa8+ Kg7 25. Qb8 Qxb1 26. e6 Re7 27. Qe5+ Kf8 28. Qf6+ Ke8 29. Qh8# 1-0",
    B: "1472",
  },
  {
    W: "1342",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nc3 d6 4. Bc4 Be7 5. d3 O-O 6. O-O Nc6 7. Bg5 Bg4 8. a3 Nd4 9. Nd5 Nxd5 10. Bxd5 Bxg5 11. Bxb7 Rb8 12. Qb1 Rxb7 13. Nxg5 Qxg5 14. f3 Bh3 15. g4 Qe3+ 16. Kh1 Nxf3 17. Rd1 Qe2 18. c4 Qxh2# 0-1",
    B: "1347",
  },
  {
    W: "1847",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qe5+ 4. Qe2 Qxe2+ 5. Bxe2 c6 6. h3 h6 7. d4 Bf5 8. Bd3 Bxd3 9. cxd3 Nf6 10. Be3 e6 11. Nf3 Bd6 12. O-O Nbd7 13. a3 g5 14. Ne4 Bc7 15. Nxf6+ Nxf6 16. b4 g4 17. hxg4 Nxg4 18. Rae1 O-O-O 19. Nh2 Bxh2+ 20. Kh1 Bd6 21. f3 Nf6 22. Rc1 Nd5 23. b5 Nxe3 24. Rfe1 Nd5 25. bxc6 b6 26. a4 Kc7 27. Rc4 h5 28. Rb1 h4 29. a5 h3 30. g4 f5 31. gxf5 exf5 32. axb6+ axb6 33. Ra4 Ra8 34. Rba1 Rxa4 35. Rxa4 Kxc6 36. Rc4+ Kb5 37. Rc2 Bg3 38. Rb2+ Ka6 39. Ra2+ Kb7 40. Rb2 f4 41. Rb5 Ne3 42. d5 Rd8 43. d4 Rxd5 44. Rxd5 Nxd5 45. Kg1 Ne3 46. Kh1 Kc6 47. d5+ Kxd5 48. Kg1 b5 49. Kh1 b4 50. Kg1 b3 51. Kh1 b2 52. Kg1 b1=Q# 0-1",
    B: "1656",
  },
  {
    W: "1831",
    T: "900+10",
    P: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. Bd3 Nf6 5. c3 Nc6 6. Nf3 Bg4 7. h3 Bxf3 8. Qxf3 e6 9. Bg5 Be7 10. Nd2 O-O 11. O-O a6 12. h4 h6 13. Be3 b5 14. a3 Na5 15. g4 Nc4 16. Nxc4 bxc4 17. Bc2 a5 18. g5 hxg5 19. hxg5 Nh7 20. Bxh7+ Kxh7 21. Qh5+ Kg8 22. Kg2 f5 23. g6 f4 24. Qh7# 1-0",
    B: "1776",
  },
  {
    W: "1773",
    T: "120+1",
    P: "1. Nc3 d5 2. d4 e6 3. Nf3 Bb4 4. Qd3 Ne7 5. a3 Bxc3+ 6. Qxc3 Nbc6 7. Bg5 O-O 8. O-O-O f6 9. Bh4 a6 10. g4 b5 11. Qe3 a5 12. Bh3 Kh8 13. Rdg1 b4 14. a4 Ba6 15. b3 e5 16. dxe5 fxe5 17. Nxe5 Nxe5 18. Qxe5 Re8 19. f4 Qd6 20. Qd4 c5 21. Qd1 Ng6 22. Rf1 Nxh4 23. e4 Bxf1 24. Rxf1 d4 25. e5 Qc6 26. Qe1 Ng6 27. f5 Nxe5 28. Qe2 Rad8 29. Qf2 d3 0-1",
    B: "1723",
  },
  {
    W: "1960",
    T: "180+2",
    P: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 g6 6. Bc4 Bg7 7. O-O Nf6 8. e5 Nh5 9. Re1 O-O 10. Bb3 Kh8 11. Qe2 f5 12. exf6 Nxf6 13. Ne5 Nd4 14. Qc4 Nxb3 15. axb3 d5 16. Qh4 Bf5 17. Bh6 e6 18. Bxg7+ Kxg7 19. g4 Be4 20. g5 Nd7 21. Qh6+ Kg8 22. Nxe4 Nxe5 23. Nf6+ Rxf6 24. gxf6 Qxf6 25. Re3 Ng4 26. Qh3 Qxf2+ 27. Kh1 Nxe3 28. Qxe6+ Kg7 29. Qe5+ Kh6 0-1",
    B: "1922",
  },
  {
    W: "1170",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bb4 4. Nd5 Bc5 5. Bc4 Nd4 6. O-O Nf6 7. Nxe5 Nxe4 8. Qg4 Nf6 9. Nxf6+ Qxf6 10. Nxf7 d5 11. Qh5 g6 12. Qe5+ Qxe5 13. Nxe5 dxc4 14. Nxc4 Be6 15. Rd1 Bxc4 16. Re1+ Ne2+ 17. Kh1 O-O-O 18. d3 Bxf2 19. Rxe2 Rde8 20. Rxe8+ Rxe8 21. Bd2 Bd5 22. h3 Re2 23. Bc3 Rxc2 24. Rf1 Bxg2+ 25. Kxg2 Bd4+ 26. Kh1 Bxc3 27. bxc3 Rxa2 28. Rf8+ Kd7 29. Rf7+ Kc6 30. Rxh7 Ra3 31. Rh6 Rxc3 32. Rxg6+ Kb5 33. Rg5+ Ka6 34. Rg6+ b6 35. Rg3 c5 36. h4 c4 37. dxc4 Rxg3 38. Kh2 Rg4 39. Kh3 Rxc4 40. Kg3 Rc8 41. h5 b5 42. Kg4 b4 43. Kg5 b3 44. h6 b2 45. Kg6 b1=Q+ 46. Kg7 Rc7+ 47. Kh8 Qb8# 0-1",
    B: "1198",
  },
  {
    W: "1778",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. O-O Nf6 5. Bxc6 dxc6 6. Nxe5 g5 7. Nd3 Bb6 8. Nc3 g4 9. Kh1 h5 10. b3 h4 11. e5 Nh5 12. Ne4 g3 13. fxg3 hxg3 14. h3 Bxh3 15. Bb2 Qh4 16. e6 Bf5# 0-1",
    B: "1754",
  },
  {
    W: "1331",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Qxd4 Nc6 5. Qd5 Be6 6. Qh5 Nf6 7. Qg5 h6 8. Qd2 Nxe4 9. Qe3 d5 10. Bb5 Qd7 11. Bxc6 Qxc6 12. Ne5 Qxc2 13. Qf3 Qxc1+ 14. Ke2 Qxb2+ 15. Nd2 Qxe5 16. Nxe4 dxe4 17. Qh5 0-1",
    B: "1299",
  },
  {
    W: "1380",
    T: "180+2",
    P: "1. e4 e5 2. Nc3 Nc6 3. Bc4 Nf6 4. Nf3 Bc5 5. d4 exd4 6. Nxd4 Nxd4 7. Be3 Nxc2+ 8. Qxc2 Bxe3 9. fxe3 O-O 10. O-O d6 11. Nd5 Nd7 12. Rae1 Nc5 13. b3 Re8 14. Nf4 Be6 15. Bxe6 Nxe6 16. Nxe6 Rxe6 17. Qc4 b6 18. a4 c5 19. Rd1 Qc7 20. e5 dxe5 21. e4 g6 22. h3 Rd6 23. Rxd6 Qxd6 24. Qxf7+ Kh8 25. Qb7 Rg8 26. Qxa7 Qd4+ 27. Kh1 Qxe4 28. Qxb6 c4 29. Qf6+ Rg7 30. bxc4 Qxc4 31. a5 e4 32. a6 h5 33. a7 Qxf1+ 34. Qxf1 Rxa7 35. Qf6+ Kh7 36. Qd4 Re7 37. Qb4 Re8 38. Qa4 Re7 39. Qb4 Re8 40. Qb7+ Kh6 41. Qd5 e3 42. Qf7 Rc8 43. Qf4+ Kh7 44. Qxe3 Rf8 45. Qe7+ 1-0",
    B: "1408",
  },
  {
    W: "1970",
    T: "180+2",
    P: "1. b3 d5 2. Bb2 e6 3. Nf3 Nf6 4. e3 Be7 5. c4 O-O 6. cxd5 exd5 7. Bd3 Bg4 8. Be2 c6 9. d3 Nbd7 10. Nbd2 Bd6 11. O-O Qc7 12. h3 Bxf3 13. Nxf3 Ne5 14. Qc2 Nxf3+ 15. Bxf3 Bh2+ 16. Kh1 Be5 17. Rfe1 Bxb2 18. Qxb2 Rfe8 19. e4 dxe4 20. dxe4 Nxe4 21. Bxe4 Qf4 22. Bf3 h6 23. Rad1 Rxe1+ 24. Rxe1 Rd8 25. Qe2 Rd2 26. Qe8+ Kh7 27. Be4+ g6 28. Bf3 Rxf2 29. Re7 Rf1# 0-1",
    B: "1990",
  },
  {
    W: "1482",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Bc4 d5 4. exd5 e4 5. Ne5 Nxd5 6. Nxf7 Kxf7 7. Qh5+ g6 8. Qxd5+ Qxd5 9. Bxd5+ Kg7 10. d3 exd3 11. cxd3 Bc5 12. b3 Re8+ 13. Kd1 Bxf2 14. Bb2+ Kh6 15. Rf1 Bb6 16. Nc3 c6 17. Be4 Bf5 18. Bxf5 gxf5 19. Rxf5 Nd7 20. Kc2 Rg8 21. g3 Kg6 22. Raf1 Raf8 23. Rxf8 Rxf8 24. Rxf8 Nxf8 25. Ne4 Bg1 26. h3 h5 27. g4 hxg4 28. hxg4 Ne6 29. Bc1 b5 30. g5 a5 31. Kc3 b4+ 32. Kc4 a4 33. Kxb4 axb3 34. axb3 Bd4 35. Kc4 c5 36. Ba3 1-0",
    B: "1554",
  },
  {
    W: "1850",
    T: "60+0",
    P: "1. d4 e6 2. Nf3 d5 3. Nc3 Nf6 4. Bg5 Bd6 5. Qd3 h6 6. Bxf6 Qxf6 7. Kd1 c5 8. dxc5 Bxc5 9. e4 Bxf2 10. exd5 Bb6 11. dxe6 Qxe6 12. Kd2 O-O 13. Re1 Qf6 14. Qe3 Nc6 15. Qe4 Bf5 16. Qf4 Rac8 17. Kc1 Bc7 18. h3 Bxf4+ 0-1",
    B: "1782",
  },
  {
    W: "1324",
    T: "60+0",
    P: "1. e4 d6 2. Bc4 e6 3. Nc3 g6 4. Qf3 f6 5. d4 e5 6. Nd5 Be6 7. b3 Bxd5 8. Bxd5 Ne7 9. Bc4 c6 10. Ne2 b5 11. Bd3 Bg7 12. d5 cxd5 13. Bxb5+ Nd7 14. exd5 O-O 15. c4 a6 16. Ba4 Nc5 17. Nc3 Nxa4 18. Nxa4 Qa5+ 19. Ke2 Rfb8 20. Bd2 Qc7 21. Nc3 Nc8 22. Ne4 f5 23. Ng5 Bf6 24. Ne6 Qe7 25. Rhc1 Rb7 26. Kf1 Rba7 27. h3 Nb6 28. Kg1 Re8 29. Be3 Qf7 30. Bxb6 Rb7 31. Be3 Rbb8 32. Bg5 Rbc8 33. Bxf6 Qxf6 0-1",
    B: "1382",
  },
  {
    W: "1450",
    T: "60+0",
    P: "1. e4 e5 2. Bc4 Bc5 3. Qh5 Qf6 4. Nf3 Nc6 5. O-O d5 6. exd5 Bd7 7. dxc6 Bxc6 8. Qxe5+ Ne7 9. Qxf6 gxf6 10. Re1 O-O-O 11. Nc3 a6 12. d3 Rhg8 13. Nh4 f5 14. g3 Nd5 15. Nxd5 Bxd5 16. Bxd5 b6 17. Nxf5 c6 18. Ne7+ Kb8 19. Nxg8 Rd7 20. Bxc6 f6 21. Bxd7 h6 22. Nxf6 Kc7 23. Bg4 Kc6 24. Bxh6 Kb5 25. c4+ Kb4 26. a3+ Ka5 27. b4+ Ka4 28. bxc5 Kb3 29. cxb6 Kc3 30. b7 Kc2 31. b8=Q Kxd3 32. Qb3+ Kd4 33. Rad1+ Kc5 34. Ne4+ Kc6 35. Rd6+ Kc7 36. Qb6# 1-0",
    B: "1437",
  },
  {
    W: "1735",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nf6 5. Ng3 c6 6. Nf3 Bd6 7. c3 Bxg3 8. hxg3 h6 9. Bd3 g5 10. O-O b6 11. Ne5 Nbd7 12. Re1 Qc7 13. Bc2 Nxe5 14. Rxe5 Nd5 15. c4 Nb4 16. Bb3 Na6 17. a3 b5 18. c5 Bd7 19. Be3 O-O-O 20. Qd2 Rde8 21. Re1 Rhf8 22. Bc2 f6 23. Re4 f5 24. Re5 Rf6 25. a4 Ref8 26. axb5 cxb5 27. Ra1 Nb8 28. Qa5 a6 29. Bd2 Qxa5 30. Bxa5 f4 31. gxf4 gxf4 32. Bd2 Rg8 33. f3 Rgf8 34. Re2 R8f7 35. Bd1 Kc7 36. Bc2 Bc6 37. Be4 Bd7 38. Bb7 Kxb7 39. Rae1 Kc7 40. Re5 Rf5 0-1",
    B: "1751",
  },
  {
    W: "1966",
    T: "60+0",
    P: "1. e4 c5 2. Nc3 e6 3. f4 d5 4. a4 d4 5. Nce2 Bd6 6. Nf3 Nc6 7. e5 Bc7 8. Ng3 c4 9. Ne4 d3 10. g3 Nge7 11. Bg2 h5 12. h4 Nf5 13. Kf2 Bb6+ 14. Kf1 Ncd4 0-1",
    B: "2042",
  },
  {
    W: "1470",
    T: "60+0",
    P: "1. e4 e6 2. Bc4 d5 3. exd5 exd5 4. Bb3 Nf6 5. Nc3 c6 6. d4 Bg4 7. f3 Bh5 8. g4 Bg6 9. h4 h6 10. h5 Bh7 11. Nh3 Bd6 12. Nf4 Qe7+ 13. Qe2 Qxe2+ 14. Nfxe2 Nbd7 15. O-O O-O-O 16. Bf4 Bxf4 17. Nxf4 Rhe8 18. Nfe2 Re3 19. a3 Rde8 20. Ba2 Rxe2 21. Nxe2 Rxe2 22. Rfe1 Rxc2 23. b4 1-0",
    B: "1450",
  },
  {
    W: "1955",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. Qg4 cxd4 5. Nf3 Nc6 6. Bd3 Nge7 7. O-O Nf5 8. Bxf5 exf5 9. Qg3 Nb4 10. Na3 Nc6 11. Rd1 Bxa3 12. bxa3 O-O 13. Nxd4 Nxd4 14. Rxd4 Be6 15. Bg5 Qc7 16. Rad1 Qxc2 17. Rh4 f4 18. Qd3 Qxd3 19. Rxd3 g6 20. Bf6 Rfd8 21. Rxh7 Kxh7 22. Rh3+ Kg8 23. Rh8# 1-0",
    B: "1798",
  },
  {
    W: "1426",
    T: "60+0",
    P: "1. e4 e6 2. Nc3 d5 3. Bb5+ c6 4. Bd3 dxe4 5. Bxe4 Nf6 6. Bf3 Nbd7 7. Qe2 Bc5 8. Ne4 Nxe4 9. Bxe4 Ne5 10. Bf3 Nxf3+ 11. Nxf3 O-O 12. d3 Qf6 13. Be3 Bxe3 14. Qxe3 Qxb2 15. Qc1 Qf6 16. h4 b6 17. Nd2 Bb7 18. Nc4 c5 19. Nd6 Bxg2 20. Rg1 Bf3 21. Rf1 Qe5+ 22. Ne4 f5 23. Kd2 fxe4 24. dxe4 Qxe4 25. Kc3 Qb4+ 26. Kd3 Rad8+ 27. Ke3 Qe4# 0-1",
    B: "1428",
  },
  {
    W: "1595",
    T: "60+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. d4 c6 4. Be3 d5 5. Qd2 dxe4 6. Ng5 Nf6 7. f3 h6 8. Nxe4 Nxe4 9. fxe4 O-O 10. Bxh6 Bxh6 11. Qxh6 Qxd4 12. Qc1 Qxe4+ 13. Be2 Nd7 14. Nd2 Qe6 15. O-O Nf6 16. Bc4 Qe3+ 17. Kh1 Ne4 18. Rf3 Qxd2 19. Qxd2 Nxd2 20. Bd3 Nxf3 0-1",
    B: "1564",
  },
  {
    W: "2394",
    T: "60+0",
    P: "1. d4 Nf6 2. Nf3 g6 3. Bg5 Bg7 4. e3 d5 5. Bd3 c5 6. c3 Nc6 7. Nbd2 h6 8. Bf4 Nd7 9. Qe2 e5 10. dxe5 Ndxe5 11. Nxe5 Nxe5 12. Bb5+ Bd7 13. O-O O-O 14. Bxd7 Qxd7 15. Nf3 Rfe8 16. Nxe5 Bxe5 17. Bxe5 Rxe5 18. Rfd1 Qc6 19. Rd2 Kg7 20. Rad1 Rae8 21. Qf3 Qb6 22. b3 a5 23. Rxd5 Rxd5 24. Qxd5 a4 25. h3 axb3 26. axb3 Ra8 27. Qe5+ Kh7 28. Qe7 Kg7 29. Rd7 Qf6 30. Qxf6+ Kxf6 31. Rxb7 Ra1+ 32. Kh2 Ra2 33. Kg3 Rb2 34. b4 cxb4 35. cxb4 Kf5 36. b5 f6 37. b6 g5 38. Rb8 h5 39. b7 h4+ 40. Kf3 Ke5 41. Re8+ 1-0",
    B: "2403",
  },
  {
    W: "2434",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5 Nfd7 5. f4 c5 6. Nf3 Nc6 7. Be3 cxd4 8. Nxd4 Be7 9. Qd2 f6 10. Nxe6 Qa5 11. Nxg7+ Kf7 12. exf6 Bxf6 13. Nh5 d4 14. Bc4+ Kf8 15. Nxf6 Nxf6 16. Bxd4 Nxd4 17. Qxd4 Kg7 18. O-O Bf5 19. Nd5 Rhf8 20. Rae1 b6 21. Re7+ Kg6 22. Nxf6 Rxf6 23. Rxh7 Qc5 24. Rh6+ Kxh6 25. Qxc5 bxc5 26. b3 Raf8 27. Bd3 Bxd3 28. cxd3 Rxf4 29. Rc1 Rf2 30. h3 Rxa2 31. Rxc5 Rff2 32. Rc6+ Kg5 33. Rc5+ Kf4 34. Rc4+ Ke3 35. Rg4 Rfe2 36. Kh2 Ra1 37. h4 Raa2 38. h5 Kxd3 39. h6 Re6 40. Rg3+ Ke2 41. Rh3 Re8 42. h7 Rh8 43. g4 Ra6 44. g5 Rg6 45. Kg3 Rg7 46. Kg4 Rgxh7 47. Rxh7 Rxh7 48. Kf5 Rg7 49. g6 Rxg6 50. Kxg6 Kd3 51. Kf6 Kc3 52. Ke6 Kb4 53. Kd6 a5 54. Kc6 Kxb3 0-1",
    B: "2287",
  },
  {
    W: "1745",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. Nc3 Nxc3 6. dxc3 Bg4 7. Be2 Nc6 8. Be3 Be7 9. h3 Bh5 10. O-O Bg6 11. Nd4 Qd7 12. Bf3 O-O-O 13. Nxc6 Qxc6 14. Bxc6 1-0",
    B: "1712",
  },
  {
    W: "2138",
    T: "180+2",
    P: "1. d4 Nf6 2. Nf3 e6 3. Bg5 d5 4. Nbd2 h6 5. Bh4 c5 6. c3 Nc6 7. e3 Be7 8. Bd3 O-O 9. O-O b6 10. Ne5 Bb7 11. f4 Rc8 12. Rf3 cxd4 13. exd4 Nxe5 14. fxe5 Ne4 15. Bxe7 Qxe7 16. Bxe4 dxe4 17. Rg3 Kh8 18. Qg4 Rg8 19. Nxe4 Bxe4 20. Qxe4 b5 21. a3 a5 22. Qe3 b4 23. axb4 axb4 24. Rh3 Rgd8 25. Rf1 bxc3 26. bxc3 Kg8 27. Qg3 Qg5 28. Qf3 Rc7 29. Rg3 Qd2 30. Qg4 Qg5 31. Qxg5 hxg5 32. Rff3 Rdc8 33. Kf2 f6 34. exf6 gxf6 35. Rxf6 Rxc3 36. Rxg5+ Kh7 37. Rxe6 Rf8+ 38. Ke2 Rc2+ 39. Kd3 Rff2 40. Re3 Kh6 41. Rg4 Kh5 42. Rg8 Rcd2+ 43. Ke4 Rfe2 44. Rxe2 Rxe2+ 45. Kd3 Re1 46. Rg3 Kh6 47. d5 Rh1 48. h3 Rd1+ 49. Ke4 Ra1 50. Ke5 Ra6 51. d6 Kh5 52. Ke6 Kh4 53. Rg4+ Kh5 54. Ke7 Ra4 55. Rd4 Rxd4 56. d7 Re4+ 57. Kd6 Rd4+ 58. Ke6 Kh4 59. Ke7 Re4+ 60. Kf7 Rd4 61. Ke7 Re4+ 62. Kf7 Rd4 63. Ke8 Re4+ 64. Kf7 1/2-1/2",
    B: "2151",
  },
  {
    W: "1898",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nc3 Nc6 4. d4 d6 5. Be3 g6 6. Bb5 Bd7 7. O-O a6 8. Ba4 Ng4 9. h3 Nxe3 10. fxe3 exd4 11. exd4 Bg7 12. Nd5 O-O 13. c3 b5 14. Bb3 a5 15. Qe1 Re8 16. Bc2 b4 17. Qg3 bxc3 18. bxc3 Ne7 19. Ng5 Be6 20. Nxe6 fxe6 21. Nf4 e5 22. Bb3+ d5 23. Nxd5 Nxd5 24. Bxd5+ Kh8 25. Bxa8 exd4 26. Bd5 dxc3 27. Rac1 Bd4+ 28. Kh1 Be5 29. Qf3 c5 30. Rxc3 Bxc3 31. Qxc3+ Qf6 32. Qxf6# 1-0",
    B: "1345",
  },
  {
    W: "1629",
    T: "300+0",
    P: "1. e4 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qc3 Nf6 5. f3 d5 6. e5 d4 7. Qb3 Nd5 8. Bg5 Nxe5 9. Nd2 f6 10. Bh4 Ne3 11. Ne4 e6 12. Bf2 Qa5+ 13. c3 dxc3 14. bxc3 Nxf1 15. Kxf1 Bd7 16. Ne2 Be7 17. Rd1 Kf7 18. f4 Ng4 19. Bg3 Ne3+ 20. Kf2 Nxd1+ 21. Rxd1 Ba4 22. Qa3 Qb6+ 23. Nd4 Bxa3 24. Kf3 Bxd1+ 0-1",
    B: "1602",
  },
  {
    W: "1837",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 d6 4. O-O Bg4 5. h3 Bh5 6. d3 h6 7. Bxf7+ Kxf7 8. Nxe5+ Nxe5 9. Qxh5+ g6 10. Qe2 Nc6 11. Nc3 Nge7 12. Qf3+ Kg7 13. Re1 Nd4 14. Qd1 Nec6 15. Nd5 Ne7 16. Be3 Ndc6 17. Qf3 Nxd5 18. exd5 Ne5 19. Bd4 Qf6 20. Qe4 Re8 21. f4 Nf3+ 22. Qxf3 Qxd4+ 23. Kh1 Rxe1+ 24. Rxe1 Kh7 25. c3 Qf6 26. Qg4 Bg7 27. Qd7 Rf8 28. Qxc7 Rf7 29. Qc8 Qxf4 30. Re8 Qf1+ 31. Kh2 Qf4+ 32. Kg1 Qf2+ 33. Kh2 Qf4+ 34. Kh1 Be5 35. Rxe5 Qxe5 36. Kg1 Qe3+ 37. Kh1 Rf1+ 38. Kh2 Qf4+ 39. g3 Qf2# 0-1",
    B: "1677",
  },
  {
    W: "1591",
    T: "300+0",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 b6 5. Nge2 d6 6. f3 Nbd7 7. Be3 Kf8 8. Ng3 c5 9. d5 Ne5 10. Qc2 Ba6 11. Nge2 Bxc4 12. Nc1 Bxf1 13. Kxf1 a6 14. f4 Neg4 15. Bd2 Nd7 16. N1e2 b5 17. h3 Ngf6 18. g4 c4 19. Kf2 Nc5 20. Rag1 Qb6 21. Be3 Qc7 22. h4 Qd7 23. Kf3 h5 24. e5 dxe5 25. fxe5 hxg4+ 26. Kf2 g3+ 27. Rxg3 Nh5 28. e6 Qd6 29. exf7 Nxg3 30. Qxg6 Qf6+ 31. Qxf6 Bxf6 32. Nxg3 Nd7 33. h5 Kxf7 34. h6 Ne5 35. Ke2 Nd3 36. b3 Bxc3 37. Rf1+ Bf6 38. Ne4 Rag8 39. Bg5 Rxg5 40. Nxg5+ Kg6 41. Rg1 Bxg5 42. Rxg5+ Kxg5 43. Ke3 Rxh6 44. bxc4 bxc4 45. Kd4 Nb2 46. Kc3 Nd1+ 47. Kxc4 Rd6 48. Kc5 Nc3 49. a4 Nxa4+ 50. Kb4 Nb2 51. Kc3 Nd1+ 52. Kd2 Nf2 53. Ke3 Ng4+ 54. Kd4 Nf6 55. Ke3 Rxd5 56. Kf3 a5 57. Ke2 a4 58. Kf2 Rb5 59. Kf3 a3 60. Ke3 a2 61. Kd4 a1=Q+ 62. Ke3 Qa4 63. Kd3 Rb3+ 64. Ke2 1/2-1/2",
    B: "1516",
  },
  {
    W: "1924",
    T: "300+0",
    P: "1. d4 c5 2. d5 Nf6 3. c3 g6 4. Bg5 Bg7 5. h4 h6 6. Bxf6 Bxf6 7. h5 gxh5 8. e3 d6 9. Qxh5 Nd7 10. Bb5 a6 11. Bxd7+ Bxd7 12. Nf3 Qb6 13. b3 O-O-O 14. Nfd2 Rdg8 15. g3 Bg4 16. Qxf7 Qb5 17. Nc4 Rg7 18. Qxg7 Bxg7 19. O-O Qd7 20. Nb6+ Kc7 21. Nxd7 Kxd7 22. a4 Bf3 23. Ra2 h5 24. Nd2 Bxd5 25. c4 Bc6 26. f3 h4 27. g4 Be5 28. Kf2 Bg3+ 29. Ke2 h3 30. Rh1 h2 31. Nf1 Be5 32. Kf2 Rh3 33. e4 Bd4+ 34. Kg2 Rh8 35. Rxh2 Rf8 36. Rh3 Be5 37. Ng3 Kc7 38. Nf5 Bf6 39. Rh7 Kd8 40. Rd2 Bg5 41. Rd1 b5 42. axb5 axb5 43. Rdh1 bxc4 44. bxc4 Ba4 45. Rh8 Rxh8 46. Rxh8+ Kd7 47. Rh7 Bb3 48. Kf2 Bxc4 49. Rh1 Bb5 50. Rd1 c4 51. e5 Bf4 52. exd6 exd6 53. Nd4 Ba6 54. Ne2 Be5 55. Rc1 c3 56. Nxc3 Bxc3 57. Rxc3 Ke6 58. Ke3 Bb7 59. Kd4 Bd5 60. f4 1-0",
    B: "1922",
  },
  {
    W: "1631",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Qe7 4. d4 Nxe4 5. Qe2 d5 6. f3 Nd6 7. Bf4 f6 8. Nd3 Qxe2+ 9. Bxe2 Bf5 10. O-O Be7 11. Nc5 O-O 12. c4 dxc4 13. Nc3 b6 14. Nd5 Bd8 15. Ne4 Nxe4 16. fxe4 Bxe4 17. Bxc4 Kh8 18. Rae1 Bxd5 19. Bxd5 c6 20. Bf3 a5 21. Bxb8 Rxb8 22. Bxc6 Bc7 23. d5 Be5 24. b3 h6 25. a4 g5 26. Bb5 Rfd8 27. Bc4 Re8 28. Kh1 Kg7 29. Re2 Bd4 30. Re6 Rxe6 31. dxe6 Kf8 32. Rf5 Ke7 33. Rd5 Rd8 34. Rf5 Be5 35. Rf1 Rd2 36. g3 h5 37. Kg1 h4 38. Rf3 hxg3 39. hxg3 Rb2 40. Rd3 Rb1+ 41. Kg2 Rb2+ 42. Kh3 Rb1 43. Kg4 Rg1 44. Rd7+ Ke8 45. Rd3 Rg2 46. Kf5 Rxg3 47. Kg6 Rxd3 48. Bxd3 g4 49. Kf5 g3 50. Be4 Ke7 51. Bf3 Bd6 52. Be4 Bb4 53. Bf3 Be1 54. Bd5 Bf2 55. Bg2 Bc5 56. Bd5 1-0",
    B: "1675",
  },
  {
    W: "1486",
    T: "300+0",
    P: "1. d4 f5 2. Bf4 Nf6 3. e3 Nc6 4. Bd3 d6 5. c3 e5 6. Bg5 Be7 7. Qc2 e4 8. Be2 h6 9. Bh4 g5 10. Bg3 Be6 11. h3 Qd7 12. Nd2 O-O-O 13. O-O-O g4 14. hxg4 fxg4 15. Nxe4 Nxe4 16. Qxe4 d5 17. Qc2 Na5 18. Bd3 Nc4 19. Ne2 h5 20. Bxc4 dxc4 21. Be5 Rhf8 22. Bf4 Rf5 23. g3 Bd5 24. Rhg1 Bd6 25. a3 Bxf4 26. Nxf4 Re8 27. Nxd5 Qxd5 28. Rgf1 Rh8 29. Qa4 Kb8 30. Rde1 b6 31. e4 Qa5 32. Qxc4 Rff8 33. e5 h4 34. gxh4 Rxh4 35. d5 Rg8 36. Re4 Rgh8 37. d6 cxd6 38. exd6 Rh1 39. Qc7+ Ka8 40. Qc6+ Kb8 41. Qc7+ Ka8 42. Qc6+ Kb8 43. Qc7+ 1/2-1/2",
    B: "1438",
  },
  {
    W: "1577",
    T: "60+0",
    P: "1. e4 Nf6 2. Nf3 Ng8 3. Bb5 Nf6 4. d3 Ng8 5. c3 Nf6 6. h3 c6 7. Ba4 Ng8 8. O-O Nf6 9. Re1 Nh5 10. Nd4 Nf6 11. Nf5 Nh5 12. Qxh5 g6 13. Qg4 gxf5 14. Qxf5 e6 15. Qf4 Bg7 16. Nd2 h6 17. Nf3 h5 18. Ne5 Bh6 19. Qxf7# 1-0",
    B: "1549",
  },
  {
    W: "2287",
    T: "60+0",
    P: "1. Nc3 d5 2. Nf3 Nf6 3. d4 e6 4. Bg5 Be7 5. Bxf6 Bxf6 6. e4 dxe4 7. Nxe4 h6 8. Bd3 b6 9. c3 Bb7 10. Qc2 Nd7 11. O-O O-O 12. Rfe1 Be7 13. Ne5 Bxe4 14. Bxe4 Nxe5 15. Bxa8 Qxa8 16. dxe5 Qd5 17. b3 Bg5 18. Rad1 Qa5 19. Rd7 c5 20. g3 Qb5 21. Red1 c4 22. f4 cxb3 23. axb3 Qc5+ 24. R1d4 Bd8 25. b4 Qc8 26. f5 b5 27. fxe6 Bb6 28. exf7+ Rxf7 29. e6 Bxd4+ 0-1",
    B: "2222",
  },
  {
    W: "1584",
    T: "60+0",
    P: "1. d3 c5 2. g3 d6 3. Nf3 e5 4. Bg2 Nf6 5. O-O h6 6. Bg5 Be7 7. Bxf6 Bxf6 8. Nbd2 Be6 9. c3 O-O 10. e4 Nd7 11. Ne1 Be7 12. Nef3 Nf6 13. Re1 b6 14. Nf1 a5 15. Qd2 b5 16. Nh4 Nh7 17. Nf5 Bxf5 18. exf5 Qd7 19. d4 Bg5 20. Qd3 Nf6 21. Bxa8 Rxa8 22. dxe5 Ng4 23. e6 Qb7 24. e7 Bxe7 25. Ne3 Nf6 26. Nd5 Nxd5 27. Rad1 Nf6 28. Qe3 Bf8 29. Qd3 Ng4 30. Qe2 Nf6 31. Qe3 c4 32. b3 d5 33. bxc4 dxc4 34. Qe2 Rb8 35. Qf1 Rc8 36. Re2 Qc7 37. Red2 Ne4 38. Rd4 Nxc3 39. R1d2 Na4 40. Rd7 Qc5 41. R2d5 Qb4 42. Qg2 Qb2 43. Qf1 c3 44. Kg2 c2 45. Qe2 1-0",
    B: "1591",
  },
  {
    W: "2176",
    T: "60+0",
    P: "1. e4 d6 2. d4 e6 3. Nf3 f6 4. c4 c6 5. Nc3 d5 6. e5 fxe5 7. dxe5 Nf6 8. exf6 gxf6 9. cxd5 Bd6 10. dxe6 Bxe6 11. Nd4 Bd5 12. Qh5+ Bf7 13. Qf5 Qe7+ 14. Be2 Nd7 15. O-O Be5 16. Nf3 Be6 17. Qh5+ Qf7 18. Qxf7+ Bxf7 19. Nxe5 Nxe5 20. f4 Ng6 21. Bd3 O-O-O 22. Bxg6 hxg6 23. Be3 Bd5 24. Bxa7 Rh7 25. Bb6 Rdh8 26. h3 g5 27. fxg5 fxg5 28. Nxd5 cxd5 29. Rac1+ Kd7 30. Rc7+ Kd6 31. Rxh7 Rxh7 32. b4 Ke5 33. Bc5 d4 34. Re1+ Kd5 35. Rd1 Kc4 36. Rxd4+ Kb5 37. Rg4 b6 38. Bf2 Rh5 39. a3 Ka4 40. Bxb6 Kb5 41. Bc5 Rh6 42. Rxg5 Re6 43. Bf2+ Ka4 44. Ra5+ Kb3 45. b5 Kb2 46. b6 Re7 47. Ra7 Re8 48. b7 Rb8 49. Bg3 Rxb7 50. Rxb7+ Kxa3 51. h4 Ka4 52. Rb1 Ka5 1/2-1/2",
    B: "2041",
  },
  {
    W: "1674",
    T: "60+0",
    P: "1. e4 b6 2. d4 Bb7 3. f3 d5 4. c4 dxe4 5. fxe4 Bxe4 6. Nc3 Bg6 7. Qf3 Nf6 8. Bd3 e6 9. Bxg6 fxg6 10. Bg5 Be7 11. Nge2 Rf8 12. O-O c6 13. Bxf6 Rxf6 14. Qh3 h5 15. Rxf6 gxf6 16. Qxe6 Qd6 17. Qg8+ Kd7 18. Rd1 Qc7 19. d5 c5 20. d6 Bxd6 21. Qf7+ Kc6 22. Qxf6 Nd7 23. Qxg6 Ne5 24. Qxh5 Nxc4 25. b3 Ne5 26. Qh6 Rf8 27. Ne4 Nf7 28. Rxd6+ Nxd6 29. Nxd6 Qxd6 30. Qc1 Rh8 0-1",
    B: "1666",
  },
  {
    W: "897",
    T: "60+0",
    P: "1. g4 d5 2. f4 e6 3. Nf3 h6 4. Bg2 g6 5. Nd4 Bg7 6. b4 a6 7. c3 Nc6 8. f5 Nxd4 9. cxd4 exf5 10. gxf5 gxf5 11. b5 axb5 12. Bxd5 c6 13. e4 cxd5 14. exd5 Qxd5 15. Qf3 Qxf3 16. d3 Qxh1+ 17. Kf2 Qxc1 18. Nc3 Qxa1 19. Nb1 Qxb1 20. Ke2 Qxa2+ 21. Ke3 Qxh2 22. d5 Kd7 23. Kf3 Kd6 24. Ke3 Kxd5 25. d4 Bd7 26. Kd3 Re8 27. Kc3 Qh3+ 28. Kb4 Qh1 29. Ka5 Qb1 30. Kb6 b4 31. Kc7 b3 32. Kxb7 b2 33. Kc7 Qc1+ 34. Kxd7 Nf6# 0-1",
    B: "1244",
  },
  {
    W: "1320",
    T: "60+0",
    P: "1. d4 d5 2. c4 c6 3. Nf3 e6 4. g3 f5 5. Bg2 Nf6 6. O-O Bd6 7. Nc3 O-O 8. b3 Ng4 9. h3 Nf6 10. Qc2 Nbd7 11. Bg5 Ne4 12. Nxe4 Qe8 13. Nxd6 Qh5 14. Nxc8 Raxc8 15. Rad1 Nf6 16. Bd2 Ne4 17. Ne5 Qxe2 18. Bxe4 fxe4 19. Rc1 b6 20. Bf4 Qh5 21. Rfe1 Qxh3 22. Qe2 Qf5 23. Qg4 Qxg4 24. Nxg4 Rf7 25. Ne5 Rf5 26. Rcd1 dxc4 27. bxc4 c5 28. Rxe4 cxd4 29. Rdxd4 Rxf4 30. Rxf4 h6 31. Nc6 1-0",
    B: "1337",
  },
  {
    W: "1791",
    T: "60+0",
    P: "1. e4 e6 2. c4 b6 3. d4 Bb7 4. Nc3 Nc6 5. d5 Nce7 6. Nf3 exd5 7. Nxd5 d6 8. Bg5 Bxd5 9. cxd5 f6 10. Be3 Nc8 11. Bb5+ Ke7 12. O-O g5 13. Nd4 h6 14. Nc6+ Ke8 15. Nxd8+ Kxd8 16. Bc6 Nce7 17. Bxa8 c5 18. Bc6 Nc8 19. Bb7 Kc7 20. Bxc8 Kxc8 21. Qa4 Kc7 22. Qxa7+ Kd8 23. Qxb6+ Ke7 24. a3 Kf7 25. a4 Ne7 26. a5 Bg7 27. Qc7 Ra8 28. a6 Rxa6 29. Rxa6 Bf8 30. Ra7 1-0",
    B: "1838",
  },
  {
    W: "1901",
    T: "60+0",
    P: "1. d4 d5 2. c4 Nc6 3. c5 Nxd4 4. Qxd4 c6 5. Nf3 Nf6 6. Bf4 e6 7. b4 Qe7 8. Bd6 Qd7 9. Ne5 Qd8 10. Bxf8 Rxf8 11. f3 Ne4 12. fxe4 f6 13. Nf3 e5 14. Nxe5 fxe5 15. Qxe5+ Qe7 16. Qxe7+ Kxe7 17. exd5 cxd5 18. Nc3 Be6 19. e3 a6 20. b5 d4 21. exd4 axb5 22. Nxb5 Rac8 23. Be2 Rfe8 24. O-O-O Kf7 25. Rhf1+ Kg8 26. Bf3 Bxa2 27. Nc3 Bc4 28. Rfe1 Rxe1 29. Rxe1 b5 30. Nxb5 Bxb5 31. Re6 Bd7 32. Rd6 Rd8 33. c6 Be8 34. Rxd8 Kf7 35. Rxe8 Kxe8 36. d5 Kd8 37. Kd2 Kc7 38. Kd3 Kd6 39. Kd4 g6 40. c7 h5 41. c8=Q 1-0",
    B: "1954",
  },
  {
    W: "1692",
    T: "60+0",
    P: "1. e4 c5 2. b4 Nc6 3. a3 e6 4. bxc5 Bxc5 5. Bb2 a6 6. Bxg7 Nf6 7. Bxh8 Nxe4 8. d4 Ba7 9. Bd3 Nd6 10. Nf3 Ke7 11. Be5 b5 12. O-O Bb7 13. Qd2 Qg8 14. Qg5+ Qxg5 15. Nxg5 f6 16. Bxf6+ Kxf6 17. Nxh7+ Kg7 18. Ng5 Rg8 19. h4 Nf5 20. g3 Ncxd4 21. Bxf5 Nxf5 22. Nc3 Bd4 23. Kh2 Bxc3 24. Rad1 d5 25. a4 b4 26. Nxe6+ Kf6 27. Nc5 Bc8 28. Nb7 Bxb7 29. Rd3 d4 30. g4 Rxg4 31. f4 Rxh4+ 0-1",
    B: "1700",
  },
  {
    W: "1213",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 c6 4. d3 h6 5. Nc3 Be7 6. O-O Nf6 7. h3 O-O 8. a4 a6 9. b4 Be6 10. Bxe6 fxe6 11. b5 axb5 12. Bd2 b4 13. Na2 d5 14. exd5 exd5 15. Nxe5 c5 16. c3 bxc3 17. Bxc3 d4 18. Bd2 Nd5 19. Ng6 Rf7 20. Nxe7+ Qxe7 21. Qg4 Nf6 22. Qg3 Nc6 23. Bxh6 Kh7 24. Bg5 Rh8 25. Bxf6 Rxf6 26. Nc1 Rg6 27. Qf3 Rf6 28. Qe4+ Qxe4 29. dxe4 Ne5 30. f4 Nc4 31. e5 Re6 32. Nb3 Ne3 33. Nxc5 Nxf1 34. Nxe6 Ng3 35. Nxd4 Rd8 36. Ne6 Rd2 37. a5 Ne2+ 38. Kh2 Nc3 39. a6 bxa6 40. Rxa6 Ne4 41. Ng5+ Nxg5 42. fxg5 Rd5 43. e6 Rxg5 44. e7 Re5 45. Ra7 Kh6 46. g4 g6 47. Kg3 Kg5 48. h4+ Kf6 49. g5+ Kf7 50. Kf4 Re1 51. Ra4 Rxe7 52. Re4 Rc7 53. Ke5 Rc5+ 54. Kf4 Rf5+ 55. Kg4 Rc5 56. Rf4+ Kg7 57. h5 gxh5+ 58. Kxh5 Kh7 59. Rf7+ Kg8 60. Rf6 Kg7 61. Ra6 Kf7 62. Kh6 Ke7 63. Ra7+ Ke6 64. g6 Kf5 65. g7 Rc8 66. Rf7+ Ke6 67. Rf8 Rxf8 68. gxf8=Q Kd7 69. Kg6 Ke6 70. Qf6+ Kd5 71. Qe7 Kc6 72. Kf5 Kd5 73. Qe5+ Kc4 74. Qd6 Kc3 75. Ke4 Kc4 76. Qd4+ Kb5 77. Qc3 Kb6 78. Kd5 Kb5 79. Qc5+ Ka4 80. Qb6 Ka3 81. Kc4 Ka4 82. Qb4# 1-0",
    B: "1610",
  },
  {
    W: "1522",
    T: "60+0",
    P: "1. d4 b6 2. Nf3 Ba6 3. e3 Bxf1 4. Kxf1 d6 5. b3 c5 6. Bb2 cxd4 7. exd4 f6 8. Nbd2 e5 9. dxe5 dxe5 10. Nc4 Qxd1+ 11. Rxd1 Bc5 12. Ke2 Ne7 13. Rhe1 Nf5 14. Kf1 O-O 15. a3 Nd4 16. b4 Be7 17. Bxd4 e4 18. Nfd2 f5 19. f3 a5 20. Bxb6 axb4 21. axb4 Nc6 22. Bc5 Ra2 23. Bxe7 Nxe7 24. fxe4 fxe4+ 25. Kg1 e3 26. Rxe3 Rxc2 27. Rxe7 Rxd2 28. Rxd2 Ra8 29. Rdd7 Ra1+ 30. Kf2 Rf1+ 31. Kg3 h6 32. Rxg7+ Kf8 33. Rdf7+ Rxf7 34. Rxf7+ Kxf7 35. b5 Kf6 36. b6 Ke6 37. b7 Kd7 38. b8=Q 1-0",
    B: "1636",
  },
  {
    W: "1974",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. Bxc6 dxc6 6. Qe2 Bd6 7. O-O O-O 8. c3 Bg4 9. h3 Bh5 10. g4 Bg6 11. d3 Ne8 12. Nbd2 f6 13. Re1 Bc5 14. Nf1 Bf7 15. Be3 Bxe3 16. Nxe3 Qd6 17. Rad1 h5 18. Kg2 hxg4 19. hxg4 g6 20. Rh1 Kg7 21. Rh3 Be6 22. Rdh1 Bg8 23. Nc4 Qe6 24. Qd2 Kf7 25. Ncxe5+ fxe5 26. Ng5+ Ke7 27. Nxe6 Kxe6 28. d4 Nd6 29. d5+ cxd5 30. Qxd5+ Ke7 31. Qxe5+ Kd7 32. Rh7+ Bxh7 33. Rxh7+ Kc6 34. Qd5+ Kb6 35. Qd4+ c5 36. Qxd6+ Ka7 0-1",
    B: "2010",
  },
  {
    W: "1607",
    T: "300+0",
    P: "1. b3 e5 2. Bb2 Nc6 3. d3 d5 4. Nf3 Bd6 5. e3 Nf6 6. Be2 O-O 7. c4 e4 8. dxe4 dxe4 9. Nd4 Nxd4 10. Bxd4 h6 11. Nd2 c5 12. Bxf6 Qxf6 13. Nxe4 Qg6 14. Nxd6 Qxg2 15. Rf1 Rd8 16. Bf3 Qxh2 17. Qd5 Rxd6 18. Qxc5 Rd8 19. Rd1 Be6 20. Rxd8+ Rxd8 21. Qe7 Rb8 22. Bd5 Qe5 23. Bxe6 fxe6 24. Qd7 Qf6 25. Ke2 Rd8 26. Qxb7 Qb2+ 27. Kf3 Rf8+ 28. Ke4 Qc2+ 29. Kd4 Rd8+ 30. Kc5 Qf5+ 31. Kb4 Qe5 32. Ka3 Rb8 33. Qxa7 Qd6+ 34. Kb2 Qd2+ 35. Kb1 Qd3+ 36. Kb2 Qd2+ 37. Kb1 Qd3+ 38. Kc1 Qc3+ 39. Kd1 Rd8+ 40. Ke2 1-0",
    B: "1569",
  },
  {
    W: "2132",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 d6 5. c4 Nf6 6. Nc3 g6 7. Be2 Bg7 8. Be3 O-O 9. Qd2 a6 10. h4 h5 11. f3 Qc7 12. g4 Ne5 13. Nd5 Nxd5 14. cxd5 hxg4 15. f4 Nf3+ 16. Bxf3 gxf3 17. h5 f5 18. hxg6 fxe4 19. Rh8+ Bxh8 20. Qh2 e5 21. dxe6 f2+ 22. Kxf2 Bxd4 23. Rc1 Bxe3+ 24. Kxe3 Qxc1+ 0-1",
    B: "2205",
  },
  {
    W: "1032",
    T: "300+3",
    P: "1. d4 e5 2. c4 d6 3. Nf3 d5 4. dxe5 dxc4 5. Nc3 Qxd1+ 6. Kxd1 Bd7 7. e4 g6 8. Bxc4 Nh6 9. Ng5 Rg8 10. e6 fxe6 11. Bxe6 Bxe6 12. Nxe6 Bb4 13. Nxc7+ Kf7 14. Nxa8 1-0",
    B: "763",
  },
  {
    W: "1519",
    T: "300+3",
    P: "1. d4 d5 2. h3 Bf5 3. Nf3 e6 4. a3 Nc6 5. Bg5 Be7 6. Bxe7 Qxe7 7. e3 a6 8. Nbd2 h5 9. c3 g5 10. Nb3 g4 11. hxg4 Bxg4 12. Be2 O-O-O 13. Nc5 Bxf3 14. Bxf3 e5 15. e4 exd4 16. Qxd4 Nxd4 17. cxd4 dxe4 18. Bxe4 Rxd4 19. b4 Rxe4+ 20. Nxe4 Qxe4+ 21. Kf1 Qd3+ 22. Kg1 h4 23. Kh2 Qd6+ 24. Kg1 Qg6 25. Kh2 Qg5 26. Rhe1 Qf4+ 27. Kg1 Nf6 28. Re7 Nd5 29. Re2 h3 30. g3 h2+ 31. Kh1 Qf3# 0-1",
    B: "1502",
  },
  {
    W: "1499",
    T: "300+3",
    P: "1. d4 e6 2. a3 b6 3. c4 f5 4. Nc3 Nf6 5. g3 Bb7 6. f3 Be7 7. Bg2 O-O 8. h3 d6 9. Be3 Nbd7 10. b4 e5 11. d5 a6 12. Bf2 Qe8 13. Qc2 Qg6 14. O-O-O Rab8 15. e4 fxe4 16. Nxe4 Nxe4 17. fxe4 Bg5+ 18. Kb2 Nf6 19. h4 Bh6 20. Qe2 Ng4 21. Be1 Ne3 22. Rb1 Nxg2 23. Qxg2 Bc8 24. Nf3 Qg4 25. Nd2 Be3 26. Nb3 Bd7 27. Bd2 Bxd2 28. Nxd2 Be8 29. h5 Bxh5 30. Rh4 Qe2 31. Qxe2 Bxe2 32. Rbh1 h6 33. R1h2 Bd3 34. Kc3 Bf1 35. g4 1-0",
    B: "1513",
  },
  {
    W: "2061",
    T: "300+3",
    P: "1. e4 c5 2. Bc4 e6 3. Nc3 Ne7 4. f4 d5 5. exd5 Nxd5 6. Nf3 Nxf4 7. O-O Nd5 8. Bxd5 exd5 9. Re1+ Be7 10. d4 O-O 11. Be3 Nc6 12. dxc5 Be6 13. a3 Bf6 14. Nd4 Qd7 15. Nxc6 bxc6 16. Bd4 Bxd4+ 17. Qxd4 Bf5 18. Re2 Rfe8 19. Rc1 Rxe2 20. Nxe2 Re8 21. Ng3 Bg6 22. c4 Qe6 23. cxd5 cxd5 24. c6 Rc8 25. c7 a6 26. Qf4 h6 27. h3 Qb6+ 28. Kh2 Qxb2 29. Rd1 Qc2 30. Rc1 Qb3 31. Qd6 Qb7 32. Qd8+ Kh7 33. Rc5 d4 34. Nf1 d3 35. Nd2 f6 36. Nc4 1-0",
    B: "2031",
  },
  {
    W: "1530",
    T: "300+3",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bc4 e5 4. d3 d6 5. Ng5 Nh6 6. Nc3 Be7 7. Nf3 Ng4 8. O-O O-O 9. h3 Nf6 10. Bg5 a6 11. Bxf6 Bxf6 12. Nd5 b5 13. Nxf6+ Qxf6 14. Bd5 Bb7 15. Qe2 Rab8 16. Bxc6 Bxc6 17. c3 Rfe8 18. Rad1 d5 19. exd5 Bxd5 20. Nd2 Bxa2 21. b3 c4 22. dxc4 bxc4 23. Qxc4 Rec8 24. Qa4 Bxb3 25. Nxb3 e4 26. Nd2 Qe6 27. Qxe4 Qf6 28. Qd4 Qe6 29. Qc4 Rxc4 30. Nxc4 Qxc4 31. Rd4 Qxc3 32. Rfd1 h6 0-1",
    B: "1527",
  },
  {
    W: "1130",
    T: "300+3",
    P: "1. e4 d6 2. d4 g6 3. d5 Nf6 4. Nc3 Bg7 5. Nf3 Nbd7 6. h3 O-O 7. e5 dxe5 8. Bd3 e4 9. Nxe4 Nxe4 10. Bxe4 Nf6 11. Bd3 Nxd5 12. O-O e5 13. c4 Nb4 14. Be4 Qxd1 15. Rxd1 Re8 0-1",
    B: "1088",
  },
  {
    W: "1731",
    T: "300+3",
    P: "1. d4 d5 2. c4 Nf6 3. Nc3 Bf5 4. e3 e6 5. Bd3 Bxd3 6. Qxd3 c5 7. cxd5 Nxd5 8. Nf3 Nc6 9. a3 Be7 10. O-O O-O 11. Rd1 Rc8 12. Nxd5 Qxd5 13. dxc5 Qxc5 14. b4 Qh5 15. Bb2 Rfd8 16. Qe2 Bd6 17. Rac1 Bxh2+ 18. Kf1 Bd6 19. Rc4 Be7 20. Rdc1 a6 21. Qc2 Rd6 22. Be5 Rd5 23. Qc3 f6 24. Rh4 Qf5 25. Rf4 0-1",
    B: "1635",
  },
  {
    W: "1410",
    T: "300+3",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Nxc6 bxc6 6. c4 Nf6 7. Nc3 Bb4 8. Bd2 Bxc3 9. Bxc3 Nxe4 10. Bxe5 Qa5+ 11. Bc3 Nxc3 12. bxc3 Qxc3+ 13. Ke2 Qxc4+ 14. Ke1 Qc3+ 15. Ke2 Qe5+ 16. Kd2 Qf4+ 17. Kc2 Qxf2+ 18. Qd2 Qxd2+ 19. Kxd2 O-O 20. Bc4 d5 21. Bd3 Be6 22. Rac1 Rfc8 23. a4 Rab8 24. Rc2 c5 25. Rhc1 c4 26. Be2 Kf8 27. Ke3 Rb3+ 28. Kd4 Ke7 29. g3 Rcb8 30. Rc3 Rxc3 31. Rxc3 Rb2 32. Bf3 Rxh2 33. Bxd5 Rd2+ 0-1",
    B: "1396",
  },
  {
    W: "1618",
    T: "300+3",
    P: "1. e4 g6 2. d4 Bg7 3. Nc3 e6 4. Be3 Ne7 5. Qd2 O-O 6. Bh6 c6 7. Nge2 d5 8. h4 dxe4 9. Nxe4 Nf5 10. Bxg7 Kxg7 11. g4 Nxh4 12. Rxh4 Qxh4 0-1",
    B: "1633",
  },
  {
    W: "1345",
    T: "300+0",
    P: "1. e4 d6 2. Nf3 h6 3. b3 c5 4. Bc4 e6 5. Bb2 a6 6. Nc3 Qf6 7. Rb1 Ne7 8. Bd5 exd5 9. Nxd5 Qg6 10. Nc7+ Kd8 11. Nxa8 Qxg2 12. Rg1 Qh3 13. d4 g5 14. dxc5 d5 15. exd5 g4 16. d6 Nec6 17. Nd4 Nxd4 18. Qxd4 Rg8 19. Qf6+ Kd7 20. Nb6+ Ke8 21. Nxc8 Nc6 22. Nb6 Bg7 23. d7+ Kf8 24. d8=Q+ Nxd8 25. Qxd8# 1-0",
    B: "1327",
  },
  {
    W: "1380",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O Bg4 6. h3 Bh5 7. d3 Bc5 8. Nbd2 Qf6 9. Qe1 O-O-O 10. c3 Ne7 11. b4 Bb6 12. Qe2 g5 13. Nc4 g4 14. hxg4 Bxg4 15. Bg5 Bxf3 16. Bxf6 Bxe2 17. Bxh8 Bxf1 18. Rxf1 Rxh8 19. Nxe5 f6 20. Nf7 Rg8 21. Nh6 Rg6 22. Nf5 Nxf5 23. exf5 Rg5 0-1",
    B: "1371",
  },
  {
    W: "2102",
    T: "60+0",
    P: "1. d3 d5 2. c3 c5 3. Nd2 Nc6 4. e4 dxe4 5. dxe4 Nf6 6. Ngf3 e5 7. Qe2 Be7 8. h3 Be6 9. g3 Qb6 10. Bg2 Rd8 11. O-O O-O 12. Re1 Nd7 13. Nc4 Qc7 14. a4 a6 15. Ne3 b5 16. axb5 axb5 17. Qxb5 Rb8 18. Qa4 Nb6 19. Qd1 Rfd8 20. Qe2 c4 21. h4 Rd3 22. Ng5 Bd7 23. Qh5 Bxg5 24. hxg5 Qc8 25. Nd5 Rxd5 26. exd5 Ne7 27. Qe2 Nbxd5 28. Rd1 Be6 29. Qxe5 Ng6 30. Qe2 h6 31. Be3 hxg5 32. Rd4 1-0",
    B: "2022",
  },
  {
    W: "2424",
    T: "60+0",
    P: "1. c4 c6 2. g3 d6 3. Bg2 Nd7 4. Nc3 Ngf6 5. e3 g6 6. Nge2 Bg7 7. b3 O-O 8. Bb2 Re8 9. d3 e5 10. Qd2 Qc7 11. O-O b6 12. Rac1 Bb7 13. h3 Rad8 14. Kh2 Qb8 15. e4 Nf8 16. f4 Nh5 17. f5 d5 18. g4 Nf4 19. Nxf4 exf4 20. Bf3 dxe4 21. Nxe4 Rxe4 22. Bxg7 Kxg7 23. Qc3+ Re5 24. Rce1 f6 25. g5 Rde8 26. gxf6+ Kxf6 27. Re4 c5 28. Rxe5 Qxe5 29. Qxe5+ Kxe5 30. Bxb7 Kf6 31. Bf3 g5 32. Kg2 Re3 33. Bg4 Nd7 34. Rf3 Ne5 35. Rxe3 fxe3 36. Kf1 Nxd3 37. Ke2 Nf4+ 38. Kxe3 Ng2+ 39. Kf3 Nh4+ 40. Kg3 Nxf5+ 41. Kf2 h5 42. Bf3 Nh6 43. Bg2 Ng8 44. Ke3 Ne7 45. Ke4 Ng6 46. Kd5 g4 47. Kc6 gxh3 48. Kb7 Nf4 49. Bf3 h2 50. Kxa7 Nh3 0-1",
    B: "2460",
  },
  {
    W: "1499",
    T: "60+0",
    P: "1. d4 d6 2. c4 e6 3. Nc3 Bd7 4. e4 Be7 5. Nf3 Nf6 6. Bg5 h6 7. Bf4 Ng4 8. h3 Nf6 9. Bd3 Nc6 10. O-O e5 11. Be3 exd4 12. Nxd4 Nxd4 13. Bxd4 O-O 14. Qc2 c5 15. Bxf6 Bxf6 16. Rac1 Qc8 17. Rfe1 Bxh3 18. gxh3 Qxh3 19. Bf1 Bd4 20. Bxh3 f5 21. Ne2 fxe4 22. Nxd4 cxd4 23. Qxe4 Rae8 24. Qd5+ Kh7 25. Rxe8 Rxe8 26. Bf5+ g6 27. Qf7+ Kh8 28. Qxe8+ Kg7 29. Qxg6+ Kf8 30. Re1 b5 31. Qe8+ Kg7 32. Re7+ Kf6 33. Qg6+ Kxe7 34. Qe6+ Kf8 35. Bg6 Kg7 36. Qf7+ Kh8 37. Qh7# 1-0",
    B: "1491",
  },
  {
    W: "1966",
    T: "60+0",
    P: "1. b3 d5 2. Bb2 c5 3. g3 e5 4. Bg2 Nf6 5. e3 Nc6 6. Ne2 Bg4 7. d3 Bd6 8. Nd2 O-O 9. O-O Qb6 10. Qe1 Rfe8 11. a4 a5 12. e4 Ne7 13. exd5 Nexd5 14. Nc4 Qc7 15. Nxd6 Qxd6 16. Nc3 Nxc3 17. Bxc3 Rad8 18. Qe3 e4 19. dxe4 Nd5 20. Qd2 Nb4 21. Qg5 f5 22. Qxg7# 1-0",
    B: "1800",
  },
  {
    W: "1751",
    T: "60+0",
    P: "1. c4 g6 2. g4 Nf6 3. Bg2 Bg7 4. e3 Nxg4 5. Qxg4 d6 6. Qd1 O-O 7. Ne2 c5 8. O-O Nc6 9. Bxc6 f5 10. Nbc3 bxc6 11. d4 e5 12. dxc5 dxc5 13. Qxd8 Rxd8 14. f4 e4 15. Na4 Be6 16. Nxc5 Bxc4 17. Re1 Bf8 18. Nb3 Bb4 19. Bd2 Bxd2 20. Nxd2 Rxd2 21. Nc3 Rxb2 22. Red1 Bd3 23. Na4 Rb4 24. Nc5 Rd8 25. Nxd3 Rxd3 26. Rxd3 exd3 27. Rd1 Ra4 28. Rxd3 Rxa2 29. Rd8+ Kg7 30. Rd7+ Kh6 31. Rc7 a5 32. Rxc6 a4 33. Ra6 a3 34. e4 Ra1+ 35. Kf2 a2 36. e5 Kg7 37. e6 Kf8 38. Ra7 h5 39. e7+ Ke8 40. Ke2 Rb1 41. Rxa2 Kxe7 42. Ra7+ Kf6 43. Ra6+ Kg7 44. Kf3 Kh6 45. Rf6 Rh1 46. Kg2 Rc1 47. Rd6 Rc2+ 48. Kf3 Rc4 49. Ke3 Rc3+ 50. Kd2 Rh3 51. Rd3 Rxh2+ 52. Kc3 Rh4 53. Rd4 Rg4 0-1",
    B: "1717",
  },
  {
    W: "1590",
    T: "60+0",
    P: "1. Nf3 d5 2. d3 Nc6 3. Nbd2 Nf6 4. g3 e5 5. Bg2 Bf5 6. O-O Bc5 7. Re1 Ng4 8. e4 dxe4 9. dxe4 Bg6 10. h3 Nxf2 11. Qe2 Nxe4+ 12. Kh2 O-O 13. Nxe4 Bxe4 14. Qxe4 Re8 15. Bg5 Qd7 16. Rad1 Qe6 17. Be3 Bxe3 18. Qxe3 Rad8 19. Ng5 Qg6 20. Rxd8 Rxd8 21. Bxc6 bxc6 22. h4 h6 23. Nf3 Qxc2+ 24. Qe2 Qf5 25. Qxe5 Qxf3 26. Qe7 Rd2+ 27. Re2 Qg2+ 28. Rxg2 Re2 29. Rxe2 1-0",
    B: "1609",
  },
  {
    W: "1790",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Be6 4. Bb3 Bxb3 5. axb3 Nf6 6. d4 exd4 7. Qxd4 c5 8. Qc4 Be7 9. O-O O-O 10. Re1 Nc6 11. Nbd2 Ne5 12. Qc3 Nxf3+ 13. Nxf3 a6 14. Bg5 h6 15. Bxf6 Bxf6 16. e5 dxe5 17. Nxe5 Re8 18. f4 Qd4+ 19. Qxd4 cxd4 20. Re4 Rad8 21. Rae1 Bxe5 22. fxe5 Rc8 23. Rxd4 Rxc2 24. Rb4 Rxb2 25. Rxb7 f6 26. e6 Kh7 27. Rb6 Rd2 28. Rxa6 Rdd8 29. e7 Rb8 30. Ra3 Rb7 31. b4 Rbxe7 32. Rae3 Rxe3 33. b5 Rxe1+ 34. Kf2 R1e6 35. Kg3 Rb6 36. h4 Rxb5 37. Kh2 Ra8 38. g4 Ra4 39. Kg3 Rb3+ 40. Kf2 Ra2+ 41. Ke1 Rb1# 0-1",
    B: "1757",
  },
  {
    W: "1515",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 Be7 3. d4 Nf6 4. Nc3 Ng4 5. h3 Nxf2 6. Kxf2 Bh4+ 7. Kg1 Be7 8. h4 h6 9. Bd3 Nc6 10. Qe2 Nb4 11. Bf4 Nxd3 12. Qxd3 b6 13. Rf1 a5 14. Ne5 c6 15. g4 Ba6 16. Qd2 Bxf1 17. Kxf1 b5 18. g5 Bb4 19. gxh6 gxh6 20. Qg2 Ke7 21. Bg5+ hxg5 22. Qxg5+ f6 23. Qg7+ Kd6 24. Nd3 Rg8 25. e5+ Kc7 26. Qxf6 Rf8 27. Qxf8 Qxf8+ 28. Ke2 Bxc3 29. h5 c5 30. bxc3 cxd4 31. h6 dxc3 32. h7 Qh8 33. Nc5 Rf8 34. Nxd7 Kxd7 35. Rf1 1-0",
    B: "1503",
  },
  {
    W: "1647",
    T: "60+0",
    P: "1. e4 d6 2. d3 Nd7 3. Nc3 b6 4. Nf3 Bb7 5. Be2 c6 6. h3 Qc7 7. Be3 O-O-O 8. O-O g6 9. Qd2 Ngf6 10. Rad1 Nh5 11. Ng5 Ne5 12. d4 f6 13. Ne6 Qd7 14. Nxd8 Qxd8 15. dxe5 fxe5 16. Bxh5 gxh5 17. Bh6 Rg8 18. Bxf8 Qxf8 19. f4 exf4 20. Rxf4 Qg7 21. Rdf1 e5 22. Rf7 Qg3 23. Rf8+ Kd7 24. Rxg8 Qxg8 25. Qh6 Ba6 26. Rf6 Qg3 27. Rxd6+ Kc7 28. Rxc6+ Kb7 29. Nd5 Qe1+ 30. Kh2 Qxe4 31. Rc7+ Kb8 32. Qd6 1-0",
    B: "1699",
  },
  {
    W: "1828",
    T: "60+0",
    P: "1. g3 d5 2. Bg2 e5 3. e3 Nf6 4. Ne2 Nc6 5. b3 Be6 6. Bb2 Bd6 7. d4 exd4 8. Nxd4 Nxd4 9. exd4 Ne4 10. Bxe4 dxe4 11. c3 Bd5 12. O-O e3 13. fxe3 Qe7 14. c4 Bc6 15. d5 Bd7 16. Qf3 O-O-O 17. Bxg7 Rhg8 18. Bf6 Qe8 19. Bxd8 Kxd8 20. Nc3 h5 21. Qxf7 h4 22. Qxe8+ Bxe8 23. g4 Rxg4+ 24. Kf2 Bxh2 25. Rh1 Bg3+ 26. Kf3 Rg7 27. Rag1 Bh5+ 28. Ke4 Bg6+ 29. Kd4 Bf7 30. e4 Kd7 31. Rxh4 Kd6 32. Rh3 Be5+ 33. Ke3 Rxg1 34. Rh6+ Ke7 35. Ne2 Rg3+ 36. Kf2 Rd3 37. a4 Rd2 38. Ke3 Rb2 39. Rh5 Rxb3+ 40. Kf2 Bf6 41. Rf5 1-0",
    B: "1826",
  },
  {
    W: "1567",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 Nf6 4. Ng5 e5 5. Nxf7 1-0",
    B: "1569",
  },
  {
    W: "1431",
    T: "300+0",
    P: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 Qe7 5. Nxc3 d6 6. Nf3 Nf6 7. O-O Bg4 8. a4 Nc6 9. Bd2 O-O-O 10. Be2 Bxf3 11. Bxf3 Nd4 12. Be3 Nxf3+ 13. Qxf3 Kb8 14. Nd5 Nxd5 15. exd5 f6 16. b4 g5 17. b5 h5 18. a5 g4 19. Qd1 c5 20. a6 b6 21. Qd3 Bh6 22. Rae1 Rhe8 23. Bxh6 Qxe1 24. h3 Qe4 25. Qd1 gxh3 26. gxh3 Qg6+ 27. Kh2 Qxh6 28. Rg1 Qf4+ 29. Kh1 Rg8 30. Rxg8 Rxg8 31. Qf1 Qf3+ 32. Kh2 h4 33. Qh1 Qf4# 0-1",
    B: "1474",
  },
  {
    W: "2435",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 e6 3. d3 Nc6 4. g3 b6 5. Bg2 Bb7 6. O-O d6 7. Re1 Qc7 8. c3 Nf6 9. d4 Be7 10. e5 dxe5 11. dxe5 Rd8 12. Qe2 Nd5 13. Nbd2 O-O 14. a4 Na5 15. Ne4 c4 16. Nd4 Qxe5 17. Qf1 Qb8 18. Bg5 Bxg5 19. Nxg5 Nf6 20. Bxb7 Qxb7 21. Rad1 h6 22. Ngf3 Nb3 23. Qxc4 Nxd4 24. Nxd4 Rc8 25. Qe2 Rfd8 26. h3 Rd7 27. Qe5 Rcd8 28. a5 bxa5 29. Qxa5 Qxb2 30. Rb1 Qd2 31. Nc6 Rc8 32. Ne5 Rdc7 33. c4 Qg5 34. Qa3 Rc5 35. Qe3 a5 36. Qxg5 hxg5 37. Rb7 Rf8 38. Ra7 Nh7 39. Nd7 Rfc8 40. Nxc5 Rxc5 41. Rc1 g6 42. Kg2 Nf6 43. f3 Kg7 44. Kf2 Ne8 45. Ke3 Nd6 46. Kd4 Rf5 47. c5 Nb5+ 48. Kc4 Nxa7 49. Rc3 Nc6 50. g4 Rf4+ 51. Kb5 Nd4+ 52. Kxa5 Nxf3 53. c6 Ne5 54. c7 Nc4+ 55. Ka6 Nd6 56. c8=Q Nxc8 57. Rxc8 Rf3 58. Rc4 Rxh3 59. Kb5 Rh4 60. Kc5 Rxg4 61. Rxg4 f5 62. Kd4 1-0",
    B: "2243",
  },
  {
    W: "1697",
    T: "300+0",
    P: "1. e4 Nc6 2. d4 e6 3. Nf3 Bb4+ 4. Bd2 Be7 5. Be2 Nf6 6. Nc3 O-O 7. O-O d5 8. e5 Nd7 9. Bd3 f6 10. Re1 fxe5 11. Nxe5 Bf6 12. Qh5 Ncxe5 13. dxe5 Nxe5 14. Bxh7+ Kh8 15. Rxe5 Bxe5 16. Bg6+ Kg8 17. Qh7# 1-0",
    B: "1713",
  },
  {
    W: "2035",
    T: "300+0",
    P: "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 d6 6. Nf3 g6 7. e4 Bg7 8. Nd2 Nbd7 9. Be2 a6 10. a4 O-O 11. O-O Re8 12. f3 Rb8 13. Nc4 Ne5 14. Bf4 Nxc4 15. Bxc4 Qc7 16. Qd2 Nd7 17. Bh6 Bh8 18. a5 Ne5 19. Be2 b5 20. axb6 Qxb6 21. Na4 Qb4 22. Rfb1 Nd7 23. Kh1 Bd4 24. h4 Nf6 25. Qf4 Nh5 26. Qg5 Bf6 27. Qe3 Bd4 28. Qg5 f6 29. Qc1 Ng3+ 30. Kh2 Nxe2 31. Qf1 Be5+ 32. f4 Nxf4 33. Bxf4 Qxe4 34. Bxe5 Qxe5+ 35. g3 Qxd5 36. Qxf6 Qd2+ 37. Kh1 Re2 38. Qd8+ Kg7 39. Qc7+ Kh6 0-1",
    B: "2048",
  },
  {
    W: "1722",
    T: "300+0",
    P: "1. e4 e6 2. d4 d6 3. Nf3 Be7 4. b3 g5 5. Bb2 Bd7 6. h3 a5 7. d5 e5 8. Nbd2 c5 9. Be2 a4 10. Nc4 b5 11. Na3 b4 12. Nc4 a3 13. Bc1 h6 14. Ne3 Nf6 15. Nh2 Nxe4 16. Bf3 Nc3 17. Qd3 f5 18. Bd2 e4 19. Qc4 Bb5 0-1",
    B: "1715",
  },
  {
    W: "1373",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Nxc6 bxc6 6. Nc3 Bg7 7. Bd2 d6 8. Bc4 Nf6 9. O-O Bg4 10. Qc1 O-O 11. Bh6 Qb6 12. Rb1 Qd4 13. Be2 Bd7 14. Qf4 Nh5 15. Qg5 f6 16. Qe3 Qxe3 17. Bxe3 f5 18. Bc4+ e6 19. Rbd1 d5 20. exd5 Bxc3 21. dxe6 Kg7 22. exd7 Bf6 23. Bd4 Rad8 24. Bxf6+ Nxf6 25. Rd6 Nxd7 26. Rxc6 Ne5 27. Rc7+ Kf6 28. Bb5 Rf7 29. Rc3 Re7 30. f3 Nf7 31. g4 g5 32. f4 gxf4 33. Rxf4 Nd6 34. gxf5 Nxf5 35. Bd3 Re5 36. Bxf5 Rxf5 37. Rxf5+ Kxf5 38. Kg2 Rg8+ 39. Kf3 Ke5 40. Ke2 Re8 41. Re3+ Kf4 42. Rxe8 h5 43. Rh8 Kg5 44. h3 h4 45. c4 Kf4 46. c5 Ke5 47. c6 Kd6 48. c7 Kxc7 49. b3 Kb6 50. Rxh4 Kc5 51. b4+ Kb5 52. Rg4 a5 53. Kf3 1-0",
    B: "1408",
  },
  {
    W: "1502",
    T: "300+0",
    P: "1. d3 c6 2. Nd2 d5 3. Nb3 e5 4. g3 Nf6 5. Bg2 Be6 6. Na5 b6 7. Nb3 d4 8. Bg5 Bxb3 9. Bxf6 Be7 10. Bxe7 Qxe7 11. cxb3 Qb4+ 12. Qd2 Qxd2+ 13. Kxd2 O-O 14. Rc1 Rc8 15. Kc2 a5 16. Kb1 Ra7 17. Nf3 c5 18. Nxe5 Re8 19. f4 f6 20. Bd5+ Kf8 21. Nc6 Nxc6 22. Bxc6 Rxe2 23. Bb5 Rae7 24. Bc4 Rd2 25. Rc2 Ree2 26. Rxd2 Rxd2 27. Kc1 Rf2 28. Bd5 Ke7 29. Be4 g6 30. h4 f5 31. Bd5 Kd6 32. Bb7 Kc7 33. Ba8 Kb8 34. Bd5 Kc7 35. Rg1 Kd6 36. Ba8 Kc7 37. Rg2 Rf1+ 38. Kd2 Ra1 39. a4 Rb1 40. Kc2 Rf1 41. Bd5 Kd6 42. Bb7 Re1 43. Rf2 Re7 44. Bf3 Re3 45. Kd2 Kc7 46. Kc2 Kd6 47. Bd1 Kc7 48. g4 Rh3 49. h5 Rg3 50. gxf5 gxf5 51. Rh2 h6 52. Be2 Kd6 53. Bf1 Ke6 54. Re2+ Kf6 0-1",
    B: "1547",
  },
  {
    W: "1605",
    T: "300+0",
    P: "1. d4 g6 2. c4 Bg7 3. Nc3 d6 4. e4 e5 5. Nf3 exd4 6. Nxd4 Nf6 7. Bg5 O-O 8. Qd2 Nc6 9. Nf3 Be6 10. Nd5 a5 11. Rb1 a4 12. a3 Na5 13. Qf4 Bxd5 14. cxd5 Nb3 15. Bc4 Nc5 16. O-O Ncxe4 17. Rfe1 Nxg5 18. Qxg5 Re8 19. Qd2 Ne4 20. Qe3 Nc5 21. Qxe8+ Qxe8 22. Rxe8+ Rxe8 23. Kf1 Re4 24. Bb5 Re8 25. Bxe8 Ne4 26. b3 g5 27. bxa4 g4 28. Re1 f5 29. Ng5 Bc3 30. Rxe4 fxe4 31. Nxe4 Bb2 32. Nf6+ Kg7 33. Nxg4 Bxa3 34. Ne3 Bc5 35. Bb5 Kf6 36. g4 Kg5 37. Kg2 Kf4 38. h3 Bxe3 39. fxe3+ Kxe3 40. g5 Kf4 41. h4 Ke5 42. Bc4 Kf4 43. Kh3 Kf5 44. Kg3 Kg6 45. Kg4 Kg7 46. h5 b6 47. Bb5 h6 48. gxh6+ Kxh6 49. Be8 Kg7 50. Kg5 Kh8 51. h6 Kg8 52. Kg6 b5 53. axb5 c6 54. bxc6 Kh8 55. c7 Kg8 0-1",
    B: "1582",
  },
  {
    W: "1102",
    T: "300+0",
    P: "1. e4 c5 2. d3 Nc6 3. Be3 d6 4. e5 Nxe5 5. f4 Nc6 6. Nc3 e6 7. Nf3 Nf6 8. Be2 Qe7 9. h3 g6 10. g4 Bg7 11. g5 Nd5 12. Nd2 Nxc3 13. bxc3 e5 14. Bf3 Bd7 15. O-O exf4 16. Bxf4 Bxh3 17. Rf2 Ne5 18. d4 Nxf3+ 19. Rxf3 Bg4 20. Re3 Bxd1 21. Rxe7+ Kxe7 22. Rxd1 Rhe8 23. Nc4 Kf8 24. Nxd6 Re2 25. Rc1 b6 26. d5 Rae8 27. Nxe8 Rxe8 28. d6 Bxc3 29. d7 Rd8 30. Rd1 f6 31. Bc7 Bd4+ 32. Kg2 Rxd7 33. Bf4 Ke7 34. gxf6+ Kxf6 35. Rf1 Be5 36. Bg3+ Ke6 37. Bxe5 Kxe5 38. Re1+ Kf4 39. Rf1+ Ke4 40. Re1+ Kf5 41. Rf1+ Ke4 42. Re1+ Kf4 43. Rf1+ Ke5 44. Re1+ Kf5 45. Rf1+ Ke4 46. Re1+ Kf4 47. Rf1+ Ke5 48. Re1+ Kf5 49. Rf1+ Ke4 50. Re1+ Kf4 51. Rf1+ Ke4 52. Re1+ Kf5 53. Rf1+ Ke4 54. Re1+ Kf4 55. Rd1 Kg5 56. Rxd7 Kf4 57. Rxh7 Ke4 58. Rh6 Kd4 59. Rxg6 c4 60. Rg4+ Kc3 61. Rg5 Kxc2 62. Rg4 b5 63. Rxc4+ bxc4 64. a3 Kb3 65. a4 Kxa4 66. Kf3 c3 67. Ke3 c2 68. Kd2 Kb3 69. Kc1 a5 70. Kd2 a4 71. Kc1 a3 72. Kd2 a2 73. Kc1 a1=Q+ 74. Kd2 Kb4 75. Ke3 Qc3+ 76. Kf4 Kc5 77. Kg5 Kd5 78. Kg6 Kd6 79. Kf7 Ke5 80. Kg8 Kf6 81. Kf8 Qc7 82. Ke8 Ke6 83. Kf8 Qc8+ 84. Kg7 1/2-1/2",
    B: "1108",
  },
  {
    W: "1655",
    T: "300+0",
    P: "1. e4 e6 2. e5 d6 3. d4 dxe5 4. Nf3 exd4 5. Nxd4 Nc6 6. Bb5 Qxd4 7. Qxd4 1-0",
    B: "1689",
  },
  {
    W: "1118",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d5 3. Nxe5 Qe7 4. f4 f6 5. exd5 fxe5 6. fxe5 Qxe5+ 7. Qe2 Qxe2+ 8. Bxe2 Nf6 9. Bf3 Bc5 10. c3 O-O 11. d4 Bd6 12. g3 Bg4 13. Bg2 Nbd7 14. Bf4 Rae8+ 15. Kf2 Bxf4 16. gxf4 Nb6 17. Nd2 Nbxd5 18. Rae1 Rxe1 19. Rxe1 Nxf4 20. Bxb7 Nd3+ 21. Kf1 Nxe1 22. Kxe1 Re8+ 23. Kf2 Re2+ 0-1",
    B: "1163",
  },
  {
    W: "1848",
    T: "600+1",
    P: "1. e4 e5 2. g3 Nc6 3. Bg2 Bc5 4. Ne2 Nf6 5. O-O d6 6. c3 Bg4 7. a4 a5 8. d3 Bxe2 9. Qxe2 O-O 10. Nd2 h6 11. Nc4 Nh7 12. Be3 Bxe3 13. Qxe3 b6 14. d4 exd4 15. cxd4 Nb4 16. Qd2 Rb8 17. b3 Re8 18. Rfe1 Nf6 19. Rad1 Qd7 20. d5 Qg4 21. Ne3 Qh5 22. Nf5 g5 23. Qc3 Qg6 24. Qxc7 Nbxd5 25. Qxd6 Red8 26. Qxb8 Rxb8 27. Rxd5 Nxd5 28. exd5 Qxf5 29. d6 Rd8 30. Rd1 Qe6 0-1",
    B: "1868",
  },
  {
    W: "1836",
    T: "15+0",
    P: "1. b3 d5 2. Bb2 e6 3. e3 Bb4 4. Ba3 Bxd2+ 5. Kxd2 Qe7 6. Bxe7 Nxe7 7. Ke1 Nf5 8. Bb5+ Bd7 9. Bd3 Nxe3 10. fxe3 Bb5 11. Be2 Bd3 12. Bxd3 O-O 13. Bb5 Nc6 14. Bxc6 bxc6 15. Qf3 d4 16. Ne2 dxe3 17. Qxe3 e5 18. Rf1 f5 19. Qf2 f4 20. Nxf4 exf4 21. Qc5 Rfe8+ 22. Kd1 Re5 23. Kc1 Rxc5 24. Rxf4 Re8 25. Rf8+ Kxf8 26. Kb2 Rce5 27. Nd2 Re2 28. Nf3 Rxc2+ 29. Kxc2 Re2+ 30. Kc3 Rxg2 31. Nh4 Rxa2 32. Nf5 Rxa1 33. Nxg7 Rc1+ 34. Kd2 Rc5 35. Ke3 Rd5 36. Kf4 Rb5 37. Kg3 Rxb3+ 38. Kh4 Rb2 39. Kg5 Rxh2 0-1",
    B: "1400",
  },
  {
    W: "1626",
    T: "180+0",
    P: "1. d4 Nf6 2. Bf4 c5 3. e3 Qb6 4. dxc5 Qxb2 5. Nd2 Nd5 6. Bg3 Nc3 7. Qc1 Qxc1+ 8. Rxc1 Nxa2 9. Ra1 Nb4 10. Ngf3 Nxc2+ 11. Ke2 Nxa1 12. Bxb8 Rxb8 13. g3 b6 14. Bg2 Ba6+ 15. Kd1 bxc5 16. Kc1 c4 17. Re1 e6 18. Ne5 c3 19. Ndc4 c2 20. Kd2 Bb4+ 0-1",
    B: "2200",
  },
  {
    W: "2431",
    T: "180+0",
    P: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 e6 5. Bc4 Nc6 6. Nf3 a6 7. O-O Nge7 8. Bg5 h6 9. Be3 b5 10. Bb3 Bb7 11. Qe2 Na5 12. Rfd1 Ng6 13. Nd5 exd5 14. exd5 Be7 15. d6 O-O 16. dxe7 Nxe7 17. Ne5 Nxb3 18. axb3 d6 19. Ng4 Nf5 20. Bf4 Qh4 21. Bg3 Qg5 22. h4 Nxh4 23. Rxd6 Rfe8 24. Qd1 Nxg2 25. Rd4 Re1+ 26. Qxe1 Nxe1 27. Rxe1 h5 28. Re5 Qg6 29. Ne3 Rc8 30. Kh2 f6 31. Re7 Bc6 32. Rd6 h4 33. Bf4 Qb1 34. Rxc6 Rxc6 35. Nd5 Qxb2 0-1",
    B: "2695",
  },
  {
    W: "1954",
    T: "180+0",
    P: "1. b4 g6 2. Bb2 Nf6 3. e3 Bg7 4. d4 d6 5. Nf3 O-O 6. Be2 a5 7. b5 c6 8. a4 Qb6 9. c4 Bg4 10. O-O Nbd7 11. Nc3 d5 12. c5 Qd8 13. b6 Qe8 14. Rb1 e5 15. dxe5 Nxe5 16. Nxe5 Bxe2 17. Nxe2 1-0",
    B: "1943",
  },
  {
    W: "1887",
    T: "180+0",
    P: "1. e4 a6 2. Nf3 b5 3. d4 Bb7 4. Nc3 b4 5. Nd5 e6 6. Ne3 Bxe4 7. Bd2 a5 8. Bb5 Nf6 9. Ng5 Bb7 10. Qe2 Nd5 11. Nxd5 Bxd5 12. O-O Be7 13. Ne4 O-O 14. Bd3 f5 15. Ng3 Nc6 16. c3 bxc3 17. bxc3 Nb8 18. Qh5 Qe8 19. Qe2 Qg6 20. c4 Bb7 21. Rfb1 Ba6 22. Bxa5 Nc6 23. Bxc7 Nxd4 24. Qe3 Nc6 25. Bb6 Bf6 26. Bc5 Rf7 27. Re1 Bxa1 28. Rxa1 h6 29. Rb1 Qf6 30. Ne2 Ne5 31. Bd4 d6 32. c5 Qg6 33. Bxa6 Rxa6 34. cxd6 Rxd6 35. Bxe5 Rd5 36. Bg3 Rfd7 37. h3 e5 38. Nc3 Rd3 39. Rb8+ Kh7 40. Qe1 f4 41. Bh2 Rxc3 42. Qxc3 Rd1+ 0-1",
    B: "1928",
  },
  {
    W: "951",
    T: "180+0",
    P: "1. e4 e5 2. Nc3 Nc6 3. Nf3 Nf6 4. b3 d5 5. exd5 Nxd5 6. Nxd5 Qxd5 7. Bc4 Qd6 8. d4 exd4 9. Nxd4 Nxd4 10. c3 Ne6 11. Bxe6 Bxe6 12. Qxd6 Bxd6 13. c4 Bc5 14. Bd2 Bd7 15. O-O-O Bc6 16. Rhe1+ Be7 17. Be3 Bxg2 18. f4 O-O 19. Bf2 Rad8 20. Rxe7 Rxd1+ 21. Kxd1 g6 22. Bc5 Bc6 23. Rxc7 Rd8+ 24. Kc2 Be4+ 25. Kc3 h5 26. Re7 f5 27. Rxb7 Rd3+ 28. Kb4 g5 29. fxg5 f4 30. Bxa7 f3 31. Rb8+ Kg7 32. c5 Rd2 0-1",
    B: "1060",
  },
  {
    W: "1280",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Nxc6 bxc6 6. Bc4 Bg7 7. Nc3 Nf6 8. e5 Nh5 9. Ne4 Bxe5 10. Bg5 Bxb2 11. Rb1 Bd4 12. Qxd4 Qa5+ 13. Qc3 Qf5 14. Qxh8# 1-0",
    B: "1336",
  },
  {
    W: "1459",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Bc5 3. Nc3 d6 4. d4 exd4 5. Nxd4 Nf6 6. Bc4 Bb4 7. Bd2 Bxc3 8. Bxc3 Nxe4 9. O-O Nf6 10. Ne2 O-O 11. Ng3 Nc6 12. Bxf6 Qxf6 13. Ne4 Qxb2 14. Qf3 Qxc2 15. Bd3 Qa4 16. Qh5 h6 17. Nf6+ Kh8 18. Nh7 Bg4 19. Qh4 Ne5 20. Bf5 Qf4 21. Bxg4 Nxg4 22. Nxf8 Rxf8 23. h3 Qh2# 0-1",
    B: "1566",
  },
  {
    W: "1449",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 d5 3. Nf3 Bf5 4. Nc3 Nc6 5. a3 dxc4 6. e3 b5 7. b4 cxb3 8. Qxb3 Qd7 9. Qxb5 Rb8 10. Qe2 e5 11. Bd2 exd4 12. Nxd4 Be7 13. Nxc6 Qxc6 14. Qc4 Qxc4 15. Bxc4 Rb2 16. Ke2 O-O 17. Ra2 Rxa2 18. Nxa2 Bxa3 19. Ra1 Rb8 20. Nc3 Bb4 21. Rxa7 1-0",
    B: "1434",
  },
  {
    W: "2057",
    T: "180+0",
    P: "1. e4 c6 2. Bc4 d5 3. exd5 cxd5 4. Bb3 Nc6 5. Nf3 e6 6. O-O Nf6 7. Re1 Be7 8. d4 O-O 9. Bg5 a6 10. a4 Rb8 11. c3 Bd7 12. Bc2 h6 13. Bh4 Rc8 14. Qd3 g6 15. Ne5 Nxe5 16. dxe5 Ne4 17. Bxe7 Qxe7 18. Qe3 Qg5 19. Bxe4 dxe4 20. Qxg5 hxg5 21. Rxe4 Kg7 22. Rg4 Rfd8 23. Rxg5 Bxa4 24. h3 Bc2 25. Na3 Be4 26. f3 Bc6 27. Nc2 Rd2 28. Rc1 Rcd8 29. Nd4 Rxb2 30. Nxc6 bxc6 31. Rg4 Rdd2 32. Kh1 Re2 33. f4 a5 34. Rg3 Rf2 35. Rg4 c5 36. Ra1 Ra2 37. Rxa2 Rxa2 38. h4 a4 39. h5 a3 40. hxg6 Ra1+ 41. Kh2 a2 42. gxf7+ Kxf7 43. f5 Rh1+ 44. Kxh1 a1=Q+ 45. Kh2 exf5 46. Rg3 Ke6 47. Re3 Qc1 48. Rh3 Kxe5 49. Rh5 Qxc3 50. Rh3 Qd2 51. Rf3 c4 52. Kg3 c3 53. Rf2 Qg5+ 54. Kh2 Qh4+ 55. Kg1 Qxf2+ 56. Kxf2 c2 0-1",
    B: "2257",
  },
  {
    W: "2176",
    T: "180+0",
    P: "1. d4 d6 2. Nf3 g6 3. c4 Bg7 4. g3 Nd7 5. Bg2 Ngf6 6. Nc3 e5 7. e4 O-O 8. O-O h6 9. h3 Re8 10. Be3 Nh7 11. Qc2 Ng5 12. Nxg5 hxg5 13. d5 a5 14. f4 exf4 15. gxf4 gxf4 16. Bxf4 Ne5 17. Rae1 b6 18. Ne2 Qh4 19. Nd4 Bxh3 20. b3 Bd7 21. Bxe5 Bxe5 22. Nf3 Qg3 23. Nxe5 Rxe5 24. Rf3 Qg5 25. Ref1 Re7 26. e5 dxe5 27. d6 cxd6 28. Rd3 Bc6 29. Rxd6 Bxg2 30. Qxg2 Qxg2+ 31. Kxg2 Kg7 32. Rff6 e4 33. Rxb6 e3 34. Kf1 Rh8 35. Rf3 e2+ 36. Ke1 Rh1+ 0-1",
    B: "2174",
  },
  {
    W: "1971",
    T: "180+0",
    P: "1. d4 d5 2. Nf3 e6 3. c3 Nf6 4. Bf4 Bd6 5. Bxd6 Qxd6 6. e3 O-O 7. Be2 c5 8. O-O b6 9. a4 Bb7 10. Nbd2 Nc6 11. Rc1 Rfc8 12. Re1 cxd4 13. exd4 Rc7 14. Nf1 Rac8 15. Bd3 Ne7 16. Rb1 Ng6 17. Bxg6 hxg6 18. Ne5 Ne4 19. f3 Nf6 20. Ne3 Nd7 21. N3g4 f5 22. Nf2 Nxe5 23. Rxe5 Kf7 24. Qe2 Re7 25. Re1 Rc4 26. Qc2 Bc6 27. b3 Bxa4 28. bxa4 Rec7 29. Nd1 Re7 30. g4 Qc6 31. gxf5 gxf5 32. Qg2 Qxa4 33. Qg5 Qe8 34. Qh5+ g6 35. Qg5 a5 36. R1e3 a4 37. Nb2 Rc8 38. c4 a3 39. Nd3 Rxc4 40. Qf4 a2 41. Re1 Qa8 42. Ra1 Qa3 43. Qd2 Rc3 44. Nb2 Rxf3 45. Re2 Rh3 46. Rg2 Qe3+ 47. Qf2 Qxf2+ 48. Kxf2 Ra3 49. Nd1 Rea7 50. Ne3 R3a5 51. Ng4 Ra3 52. Nh6+ Kg7 53. Nxf5+ exf5 54. h3 Kf6 55. h4 Ke7 56. Rxg6 Rb3 57. Rg2 Rb2+ 0-1",
    B: "1963",
  },
  {
    W: "1803",
    T: "180+0",
    P: "1. e4 c5 2. Nc3 d6 3. Bc4 Nc6 4. Bb5 Bd7 5. Nf3 Nf6 6. Qe2 a6 7. Bxc6 Bxc6 8. e5 dxe5 9. Nxe5 Qc7 10. Nxc6 Qxc6 11. O-O e6 12. d3 Bd6 13. Bg5 Nd5 14. Ne4 Bc7 15. c4 Ne7 16. Bxe7 Kxe7 17. a3 Rhd8 18. b4 cxb4 19. axb4 Kf8 20. Rfd1 f5 21. Ng5 Kg8 22. Qxe6+ Qxe6 23. Nxe6 Rd7 24. Nxc7 Rxc7 25. b5 a5 26. b6 Rc6 27. Rdb1 Kf7 28. Rb5 g6 29. Rbxa5 Rxa5 30. Rxa5 Rxb6 31. Ra1 Rb3 32. Rd1 Ke6 33. f3 b6 34. Kf2 Ke5 35. Rd2 Kd4 36. Ke2 g5 37. g3 f4 38. g4 h6 39. h3 Rb1 40. Kf2 Rb3 41. Ke2 Rc3 42. Rd1 Rb3 43. Rd2 Rb1 44. Kf2 Rb3 45. Ke2 Rb1 46. Kf2 Rb3 47. Ke2 Rb1 48. Kf2 Rb3 49. Ke2 Rb1 50. Rd1 Rxd1 51. Kxd1 Kxd3 0-1",
    B: "1766",
  },
  {
    W: "2219",
    T: "180+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. exd6 cxd6 6. Nc3 g6 7. Be3 Bg7 8. Nf3 Nc6 9. h3 O-O 10. Rc1 e5 11. d5 Ne7 12. b4 Nf5 13. Bd2 a5 14. a3 axb4 15. axb4 Ra3 16. Nb5 Ra2 17. Qb3 Ra8 18. g4 Nh4 19. Nxh4 Qxh4 20. Nxd6 Bxg4 21. Bg2 Bd7 22. Nxb7 e4 23. Be3 Na4 24. Nc5 Nxc5 25. Bxc5 Rfc8 26. O-O f5 27. Qg3 Qf6 28. Rfd1 Ra2 29. Bd4 Qf7 30. Bxg7 Qxg7 31. Qb3 Rca8 32. c5 Ra1 33. c6 Rxc1 34. Rxc1 Be8 35. d6+ Bf7 36. Qc3 Qf8 37. d7 Be6 38. Qe5 Qe7 39. b5 Kf7 40. Bf1 g5 41. Bc4 Bxc4 42. Qxf5+ Kg7 43. Rxc4 Ra1+ 44. Kg2 e3 45. Qe4 1-0",
    B: "2191",
  },
  {
    W: "1158",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Bc5 3. Bc4 d6 4. h3 Nf6 5. d3 O-O 6. O-O Bd7 7. Re1 Bxh3 8. gxh3 a6 9. Kf1 b5 10. Bb3 Nc6 11. c3 b4 12. cxb4 Bxb4 13. Nc3 Nd4 14. Nxd4 exd4 15. a3 dxc3 16. axb4 Nh5 17. bxc3 Qh4 18. Ke2 Nf4+ 19. Kd2 Qxh3 20. Rh1 Qxd3+ 21. Ke1 Ng2# 0-1",
    B: "1236",
  },
  {
    W: "1742",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 d6 5. Bg2 O-O 6. O-O Nbd7 7. b3 Re8 8. e3 e5 9. Nc3 exd4 10. exd4 c6 11. Be3 d5 12. Qc2 dxc4 13. bxc4 Nb6 14. Qb3 Bf5 15. Rad1 Qc7 16. Bf4 Qe7 17. Rfe1 Qd8 18. Ne5 Nbd7 19. c5 Nxe5 20. dxe5 Be6 21. Rxd8 Bxb3 22. Rxe8+ Rxe8 23. exf6 Rxe1+ 24. Bf1 Bc4 25. fxg7 Rxf1+ 26. Kg2 Kxg7 27. Ne4 Ra1 28. Be5+ Kh6 29. Bxa1 Bd5 30. f3 Bxe4 31. fxe4 g5 32. Kf3 Kg6 33. g4 f6 34. Ke3 Kf7 35. Kd4 Ke6 36. e5 h6 37. exf6 Kxf6 38. Ke3+ Ke6 39. Ke4 b6 40. cxb6 axb6 41. Bg7 h5 42. gxh5 Kf7 43. Bd4 Kg8 44. Bxb6 Kh7 45. Kf5 Kh6 46. Be3 Kxh5 47. Bxg5 c5 48. Bd2 c4 49. a4 Kh4 50. a5 Kh3 51. a6 Kxh2 52. a7 Kg2 53. a8=Q+ Kf2 54. Qe8 Kf3 55. Qe4+ Kf2 56. Be3+ Kf1 57. Qxc4+ Kg2 58. Qe4+ Kg3 59. Qf4+ Kh3 60. Bf2 Kg2 61. Qg3+ Kf1 62. Ke4 Ke2 63. Qe3+ Kf1 64. Kf4 Kg2 65. Qg3+ Kf1 66. Bc5 Ke2 67. Qe3+ Kd1 68. Qa3 Kc2 69. Ke3 Kd1 70. Kd3 Ke1 71. Qa1# 1-0",
    B: "1813",
  },
  {
    W: "1036",
    T: "180+2",
    P: "1. e4 g6 2. Nf3 Bg7 3. d4 d5 4. e5 e6 5. c4 c6 6. c5 Ne7 7. Bd3 O-O 8. O-O b5 9. Na3 Bd7 10. b4 a5 11. Bd2 axb4 12. Bxb4 Ra4 13. Rb1 h5 14. Ng5 f6 15. Nf3 fxe5 16. Nxe5 Bc8 17. Nxg6 Nxg6 18. Bxg6 Rf6 19. Qxh5 Kf8 20. Nxb5 cxb5 21. c6+ Rxb4 22. Rxb4 Nxc6 23. Rxb5 Nxd4 24. Ra5 Nf5 25. Bxf5 Qxa5 26. Bd3 Qxa2 27. Qd1 Bd7 28. h3 Ba4 29. Kh1 Bxd1 30. Rxd1 Qa4 31. Re1 Rxf2 32. Bf1 Rd2 33. Be2 Be5 34. Rf1+ Ke7 35. Bd1 Rxd1 36. Rxd1 Qxd1# 0-1",
    B: "1166",
  },
  {
    W: "1394",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 d5 3. Nxe5 Nf6 4. exd5 Qxd5 5. Qe2 Be7 6. Nc3 Qd8 7. d4 O-O 8. Bg5 Re8 9. f4 Qxd4 10. Bxf6 Bxf6 11. Rd1 Qxf4 12. Qc4 Rxe5+ 13. Be2 Qxc4 14. Rd8+ Bxd8 15. O-O Rxe2 16. Nxe2 Qxe2 17. c4 Bf6 18. h3 Bd4+ 19. Kh2 0-1",
    B: "1404",
  },
  {
    W: "1650",
    T: "180+2",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d3 c6 5. Bd2 Qc7 6. Nf3 Nf6 7. g3 Bg4 8. Bg2 e6 9. O-O Nbd7 10. Ne4 Be7 11. Bc3 O-O 12. h3 Bxf3 13. Qxf3 Ne5 14. Qf4 Nxe4 15. Bxe5 Bd6 16. Bxd6 Nxd6 17. c4 Qe7 18. c5 Nb5 19. Be4 Rad8 20. Qe3 Qf6 21. f4 e5 22. fxe5 Qxe5 23. Bxh7+ Kh8 24. Qxe5 Kxh7 25. Qe4+ Kg8 26. Qh4 Rd4 27. Qe7 Rxd3 28. Kg2 Rd2+ 29. Rf2 Rd3 30. Raf1 Nd4 31. Rxf7 Rd2+ 32. Kh1 Rxf7 33. Qxf7+ Kh7 34. Qf4 Ne2 35. Qxd2 Nxg3+ 36. Kg2 Ne4 37. Qd3 1-0",
    B: "1595",
  },
  {
    W: "1500",
    T: "180+2",
    P: "1. d4 d5 2. c4 e6 3. cxd5 exd5 4. Nc3 Bb4 5. Nf3 Nc6 6. Bd2 Bxc3 7. bxc3 h6 8. e3 a6 9. Bd3 Nf6 10. O-O O-O 11. Rb1 b5 12. a4 Na7 13. axb5 axb5 14. Ne5 c6 15. Bc2 Bb7 16. Nd3 Nc8 17. Nc5 Rb8 0-1",
    B: "1649",
  },
  {
    W: "1926",
    T: "180+2",
    P: "1. c4 Nf6 2. e3 g6 3. d4 Bg7 4. Nc3 O-O 5. Bd3 d6 6. Qc2 e5 7. d5 Na6 8. a3 Nc5 9. Be2 a5 10. Nf3 a4 11. Bd2 Nh5 12. h4 f5 13. Ng5 Nf6 14. f3 Qe7 15. Nd1 h6 16. Nh3 e4 17. f4 Nb3 18. Rb1 Nxd2 19. Qxd2 Nd7 20. b4 axb3 21. Rxb3 Nc5 22. Rb1 Rxa3 23. Nhf2 Na4 24. Qb4 Nc3 25. Rb3 Rxb3 26. Qxb3 Nxe2 27. Kxe2 b6 28. Nc3 Qf6 29. Nb5 Rf7 30. Na7 Bd7 31. h5 g5 32. g3 g4 33. Rc1 Qb2+ 34. Qxb2 Bxb2 35. Rc2 Bf6 36. Ra2 Rf8 37. Nb5 Bxb5 38. cxb5 Bd8 39. Nd1 Kf7 40. Nb2 Bf6 41. Nc4 Rc8 42. Ra7 Bd8 43. Nxb6 Rb8 44. Nc4 Ke8 45. b6 cxb6 46. Nxd6+ Kf8 47. Nxf5 b5 48. Nxh6 b4 49. Kd1 b3 50. Kc1 b2+ 51. Kb1 Bf6 52. d6 Rc8 53. Rc7 Rxc7 54. dxc7 1-0",
    B: "1913",
  },
  {
    W: "1486",
    T: "180+2",
    P: "1. d4 d5 2. Bf4 Nc6 3. e3 b6 4. c4 Bb7 5. cxd5 Qxd5 6. Nc3 Qd7 7. Rc1 e6 8. Nb5 Bb4+ 9. Ke2 Bd6 10. d5 Ne5 11. Nxd6+ cxd6 12. dxe6 fxe6 13. Ke1 a6 14. Qb3 b5 15. Nf3 Bd5 16. Qc3 Ne7 17. Bxe5 dxe5 18. Nxe5 Qd6 19. Qc7 Qb4+ 20. Qc3 Qxc3+ 21. Rxc3 O-O 22. f3 Bxa2 23. Bd3 Nd5 24. Rc1 Nxe3 25. Kf2 Nf5 26. Rhe1 Rad8 27. Nc6 Rxd3 28. Nb4 Rd2+ 29. Kg1 Bd5 30. Nxa6 Rxb2 31. Nc7 Nd4 32. Nxd5 exd5 33. Re7 Ne2+ 34. Kf1 Nxc1 35. Rd7 d4 36. Rxd4 Re8 37. g4 Ree2 38. Rd8+ Kf7 39. Rd7+ Ke6 40. Rxg7 Rf2+ 41. Kg1 Rg2+ 42. Kf1 Rbf2+ 43. Ke1 Re2+ 44. Kf1 Rgf2+ 45. Kg1 Nd3 46. Re7+ Kxe7 47. h3 Re1# 0-1",
    B: "1493",
  },
  {
    W: "1896",
    T: "180+2",
    P: "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Be7 5. Nf3 Nf6 6. Bd3 b6 7. O-O Bb7 8. Re1 Nbd7 9. c3 Nxe4 10. Bxe4 Bxe4 11. Rxe4 Nf6 12. Re1 O-O 13. Bg5 h6 14. Bh4 Nd5 15. Bxe7 Qxe7 16. Ne5 Qd6 17. Qf3 Nf6 18. h3 Rfd8 19. Re2 Rac8 20. Nc6 Rd7 21. Nxa7 Rcd8 22. Nb5 Qd5 23. Qxd5 Nxd5 24. Na3 c6 25. Nc4 b5 26. Ne5 Rd6 27. a3 Kh7 28. Nxf7 1-0",
    B: "1765",
  },
  {
    W: "1344",
    T: "180+2",
    P: "1. e4 d5 2. exd5 Nf6 3. Nc3 Nxd5 4. Nxd5 Qxd5 5. d4 Nc6 6. Nf3 Bg4 7. Be2 Bxf3 8. Bxf3 Nxd4 9. Bxd5 1-0",
    B: "1356",
  },
  {
    W: "1393",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. Bxc6 bxc6 5. O-O d6 6. d3 Bg4 7. h3 h5 8. hxg4 hxg4 9. Nh2 Qh4 10. Re1 Qxh2+ 11. Kf1 Qh1+ 12. Ke2 Qxg2 13. Be3 Qf3+ 14. Kd2 Bxe3+ 15. fxe3 Rh2+ 16. Re2 g3 17. Nc3 O-O-O 18. Qg1 Rxe2+ 19. Nxe2 Qf2 20. Qxg3 Qxg3 21. Nxg3 g6 22. Rf1 Rf8 23. Kc1 Ne7 24. d4 f5 25. dxe5 dxe5 26. exf5 gxf5 27. e4 f4 28. Nh5 Rf7 29. Rf3 c5 30. c3 Kb7 31. Kd2 Nc6 32. Kd3 Na5 33. b4 c4+ 34. Kc2 Nc6 35. Kb2 Kb6 36. Ka3 a5 37. Ka4 axb4 38. cxb4 Na7 39. Rc3 c5 40. Rxc4 cxb4 41. Rxb4+ Ka6 42. Rc4 Rd7 0-1",
    B: "1434",
  },
  {
    W: "1628",
    T: "300+0",
    P: "1. e4 a6 2. d4 h6 3. c4 d6 4. Nf3 e6 5. Nc3 g6 6. Be2 Bg7 7. O-O Nd7 8. Be3 b6 9. Qd2 Bb7 10. Rad1 Qe7 11. d5 e5 12. Rfe1 f6 13. a3 h5 14. b4 h4 15. h3 g5 16. Nh2 Bh6 17. Ng4 Bg7 18. Qc2 Nh6 19. Bf3 Rf8 20. Nxh6 Bxh6 21. Bg4 Bc8 22. a4 Nb8 23. b5 Bxg4 24. hxg4 a5 25. Qe2 Nd7 26. Qf3 O-O-O 27. Qf5 Kb8 28. Kh2 Rh8 29. Ne2 Rh7 30. Ng1 Rdh8 31. Nh3 Bg7 32. f3 Kb7 33. Bf2 Nc5 34. Bxc5 dxc5 35. Rd3 Rd8 36. Red1 Rd6 37. Qxh7 Qf8 38. Qg6 Qe7 39. g3 hxg3+ 40. Kxg3 Rd8 41. Rh1 Rh8 42. Kg2 Rh6 43. Qf5 Bf8 44. Nf2 Qd6 45. Rxh6 Bxh6 46. Rd1 Bg7 47. Rh1 Qf8 48. Rh7 Qg8 49. Qg6 Kb8 50. Rxg7 Qc8 51. Rg8 1-0",
    B: "1681",
  },
  {
    W: "1182",
    T: "300+0",
    P: "1. d4 d5 2. Bf4 Nf6 3. e3 c6 4. Bd3 Qb6 5. Nf3 Qxb2 6. Nc3 Qxc3+ 7. Qd2 Qxa1+ 0-1",
    B: "1223",
  },
  {
    W: "1569",
    T: "300+0",
    P: "1. b4 e6 2. Bb2 Nf6 3. b5 Bc5 4. c4 c6 5. a4 cxb5 6. axb5 Qc7 7. e3 Qd8 8. Bd3 d5 9. Nf3 dxc4 10. Bxc4 Nbd7 11. O-O O-O 12. d4 Bb6 13. Re1 Nd5 14. e4 Nc7 15. d5 exd5 16. exd5 Nf6 17. d6 Nce8 18. Ne5 1-0",
    B: "1576",
  },
  {
    W: "1624",
    T: "300+0",
    P: "1. e4 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qe3 e5 5. Bb5 Nf6 6. Nf3 Be7 7. Bxc6 bxc6 8. O-O O-O 9. Nxe5 Bd6 10. Nc4 Bb8 11. e5 Re8 12. Qc5 Ne4 13. Qe3 d5 14. Nd6 Bxd6 15. exd6 Qxd6 16. Qa3 Qg6 17. Bf4 Bh3 18. Qxh3 Nf6 19. c3 Nh5 20. Bg3 Re2 21. Na3 Rae8 22. c4 f5 23. cxd5 f4 24. Bh4 f3 25. g3 Nf4 26. Qd7 cxd5 27. Rad1 Nh5 28. Qxd5+ Kh8 29. Nc4 Nxg3 30. hxg3 Qg4 31. Ne3 Qh5 32. Qg5 Qxg5 33. Bxg5 h6 34. Bf4 g5 35. Bc7 h5 36. Kh2 h4 37. gxh4 gxh4 38. Kh3 Rxb2 39. Kxh4 Rb4+ 40. Kg3 Rf8 41. Bd6 Rg8+ 42. Kxf3 Ra4 43. Rh1+ Kg7 44. Be5+ Kf7 45. Rd7+ Ke6 46. Rd4 Rf8+ 47. Ke4 Rxa2 48. Rh6+ Ke7 49. Bd6+ Ke8 50. Bxf8 Kxf8 51. Rd8+ Kg7 52. Nf5+ Kf7 53. Rd7+ Ke8 54. Rhd6 Re2+ 55. Kf3 Ra2 56. Ng7+ Kf8 57. Ne6+ Ke8 58. Nc5 Ra3+ 59. Kg2 Rc3 60. Re6+ Kf8 61. Rd8+ Kf7 0-1",
    B: "1621",
  },
  {
    W: "1471",
    T: "300+0",
    P: "1. e4 c5 2. Bc4 d6 3. h3 Nc6 4. Nf3 e5 5. c3 Be6 6. Bxe6 fxe6 7. O-O Nf6 8. Re1 Be7 9. d3 O-O 10. Na3 d5 11. Bg5 dxe4 12. dxe4 Qxd1 13. Raxd1 Nxe4 14. Rxe4 Rxf3 15. gxf3 Bxg5 16. Nb5 a6 17. Nc7 Rf8 18. Nxe6 Rf5 19. Nxc5 Bf6 20. Nxb7 Rxf3 21. Kg2 Rf5 22. Nd6 Rg5+ 23. Kh1 Ne7 24. Rg4 Rh5 25. Ne4 Rxh3+ 26. Kg2 Rh6 27. Rd8+ Kf7 28. Nxf6 gxf6 29. Rd7 Ke6 30. Ra7 Kf5 31. Rg7 Nd5 32. Rxa6 Nf4+ 33. Kg1 Ne2+ 34. Kf1 Nf4 35. f3 Rh2 36. Ra4 Nd3 37. b4 Kf4 38. Rg4+ Kxf3 39. Rg8 Rc2 0-1",
    B: "1487",
  },
  {
    W: "1844",
    T: "300+0",
    P: "1. e4 c5 2. Bc4 d6 3. Qf3 e6 4. d3 Nf6 5. a3 a6 6. h3 b5 7. Bb3 Nc6 8. Ne2 Ne5 9. Qg3 g6 10. Bf4 Nh5 11. Qh2 Nxf4 12. Nxf4 Bg7 13. Nd2 c4 14. dxc4 bxc4 15. Nxc4 Nxc4 16. Bxc4 Bxb2 17. Ra2 Bc3+ 18. Ke2 O-O 19. Rd1 Bb7 20. Bd3 Qf6 21. Qg3 Be5 22. Qf3 d5 23. g3 Bd6 24. Kf1 e5 25. Re1 exf4 26. exd5 Rae8 27. Rxe8 Rxe8 28. gxf4 Qd4 29. Kg1 Bxd5 30. c4 Bxf3 0-1",
    B: "1910",
  },
  {
    W: "1161",
    T: "300+0",
    P: "1. c4 c5 2. Nc3 Nc6 3. g3 Ne5 4. d3 b6 5. Bg2 Rb8 6. Rb1 Qc7 7. a3 Bb7 8. b4 Bxg2 9. bxc5 bxc5 10. Nb5 Qb7 11. Nd6+ exd6 12. Rxb7 Rxb7 13. Nf3 Bxh1 14. Kd2 Bxf3 15. exf3 Be7 16. f4 Nc6 17. Ke2 Nf6 18. Be3 O-O 19. a4 Re8 20. Qd2 Nb4 21. h4 Ng4 22. f5 d5 23. cxd5 Nxd5 24. f3 Ndxe3 25. fxg4 Nxg4 26. Qf4 Bd6+ 27. Qe4 Rxe4+ 28. dxe4 Rb2+ 29. Kf3 Nh2+ 30. Ke3 c4 31. Kd4 Rb3 32. Kxc4 Rxg3 33. Kd5 Bf4 34. e5 Ng4 35. Ke4 Bxe5 36. a5 Re3+ 37. Kd5 Bg3 38. h5 Nf6+ 39. Kc5 Nxh5 40. Kb5 d5 41. Ka6 d4 42. Kxa7 d3 43. a6 d2 44. Kb7 d1=Q 45. a7 Ra3 46. a8=Q+ Rxa8 47. Kxa8 g6 48. f6 g5 49. Kb7 g4 50. Kc6 Be5 51. Kc5 g3 52. Kc6 g2 53. Kc5 g1=Q+ 54. Kc6 Qd6+ 55. Kb7 Qgb6+ 56. Kc8 Qdd8# 0-1",
    B: "1083",
  },
  {
    W: "1966",
    T: "300+0",
    P: "1. d4 d6 2. Nf3 Bg4 3. Bg5 h6 4. Bf4 Nd7 5. Nbd2 c5 6. e3 Ngf6 7. c3 g5 8. Bg3 Bf5 9. Nc4 b5 10. dxc5 bxc4 11. cxd6 exd6 12. Bxd6 Bxd6 13. Qxd6 Qe7 14. Qxe7+ Kxe7 15. Bxc4 Nb6 16. Bb3 Rad8 17. O-O Ne4 18. Rad1 Bg4 19. Rxd8 Rxd8 20. Ne5 Bd7 21. Nxf7 Rf8 22. Nxh6 Nd2 23. Re1 Nxb3 24. axb3 Rh8 25. h4 Rxh6 26. hxg5 Rd6 27. Re2 Rd1+ 28. Kh2 Nd5 29. f4 Bg4 30. Rf2 Nxe3 31. Kg3 Be6 32. Kf3 Ng4 33. Re2 Nh2+ 34. Kg3 Nf1+ 35. Kf2 Kf7 36. f5 Bxf5 37. Re5 Kg6 38. Ra5 Nd2 39. Rxa7 Kxg5 40. Ra5 Nxb3 41. Ra3 Nc5 42. Ra5 Ne4+ 43. Kf3 Rf1+ 44. Ke2 Rf2+ 45. Ke3 Rxb2 0-1",
    B: "2243",
  },
  {
    W: "2119",
    T: "300+0",
    P: "1. d4 e6 2. c4 c5 3. Nc3 cxd4 4. Qxd4 Nc6 5. Qd1 Nf6 6. Nf3 Bc5 7. e3 a6 8. Be2 O-O 9. O-O b6 10. a3 Bb7 11. b4 Be7 12. Bb2 Qc7 13. Rc1 Rad8 14. Qc2 Rfe8 15. Rfd1 d6 16. Nd4 Nxd4 17. Rxd4 e5 18. Rdd1 Qc6 19. f3 Bf8 20. e4 Qd7 21. Nd5 Bxd5 22. cxd5 Rc8 23. Qd2 b5 24. Rxc8 Rxc8 25. Rc1 Rxc1+ 26. Qxc1 h6 27. Qc6 Qa7+ 28. Kf1 Qe3 29. Qxa6 Nh5 30. Qxb5 Nf4 31. Qc4 Qd3 32. Bxd3 1-0",
    B: "1975",
  },
  {
    W: "1974",
    T: "300+0",
    P: "1. e4 e5 2. Nc3 d6 3. f3 c6 4. Nge2 h6 5. Ng3 Nf6 6. d4 Qc7 7. Bc4 Be7 8. Be3 a6 9. Qd2 Nbd7 10. O-O b5 11. Bd3 Bb7 12. a4 Rc8 13. axb5 cxb5 14. d5 Nc5 15. Nf5 Bf8 16. f4 Nxd3 17. cxd3 Ng4 18. fxe5 Nxe5 19. d4 Nc4 20. Qe2 Nxe3 21. Qxe3 g6 22. Ng3 Bg7 23. Rad1 O-O 24. h4 Rfe8 25. h5 Re7 26. hxg6 fxg6 27. Qd3 Kh7 28. Nge2 Rce8 29. Nf4 b4 30. Ne6 Qb6 31. Ne2 Bc8 32. Nxg7 Rxg7 33. Ng3 Bg4 34. Rd2 a5 35. Rdf2 Ree7 36. Kh2 Qc7 37. Ne2 Bxe2 38. Rxe2 Ref7 39. Ref2 Re7 40. Rf3 a4 41. Rh3 Ref7 42. Rxf7 Qxf7 43. e5 dxe5 44. dxe5 Qf4+ 45. Qg3 Qxg3+ 46. Rxg3 Rc7 47. d6 Rc6 48. d7 a3 49. bxa3 bxa3 50. Rxa3 Rc5 51. d8=Q Rxe5 52. Ra7+ 1-0",
    B: "1936",
  },
  {
    W: "1462",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Nc3 Bg7 6. Be3 a6 7. Qd3 e6 8. O-O-O Nge7 9. g3 O-O 10. Bg2 d5 11. exd5 exd5 12. Nxc6 bxc6 13. Bc5 Re8 14. Bd4 Bf5 15. Qd2 Qa5 16. Bxg7 Kxg7 17. a3 Rab8 18. h3 Red8 19. g4 Be6 20. Qd4+ Kg8 21. f4 c5 22. Qd3 d4 23. Ne4 Qb6 24. b3 f5 25. Ng5 Bd5 26. Bxd5+ Nxd5 27. Kb2 Nxf4 28. Qd2 Nd5 29. Nf3 c4 30. Nxd4 cxb3 31. Nxb3 Ne3 32. Qxe3 Qxe3 33. Rxd8+ Rxd8 34. gxf5 gxf5 35. h4 Rb8 36. h5 Qf3 37. Re1 Qxh5 38. Re6 a5 39. Re5 a4 40. Ra5 axb3 41. cxb3 Qe2+ 42. Kc3 Qe3+ 43. Kc4 Qxb3+ 44. Kd4 Rd8+ 45. Ke5 Qc3+ 46. Kxf5 Qxa5+ 47. Kg4 Rd4+ 48. Kh3 Qxa3+ 49. Kg2 Rd2+ 50. Kf1 Qc1# 0-1",
    B: "1447",
  },
  {
    W: "1372",
    T: "300+0",
    P: "1. c4 Nf6 2. g3 d5 3. Bg2 dxc4 4. Nc3 Bd7 5. Bxb7 Nc6 6. Bxa8 Qxa8 7. Nf3 Bg4 8. d4 cxd3 9. Qxd3 Bxf3 10. Qxf3 Qb7 11. b4 e6 12. b5 Nd5 13. Nxd5 exd5 14. Qxd5 Bb4+ 15. Kf1 O-O 16. Qxc6 Qxc6 17. bxc6 Rd8 18. Kg2 Rd6 19. Be3 Rxc6 20. Rhc1 Rxc1 21. Rxc1 Ba5 22. Bxa7 f5 23. Bb8 1-0",
    B: "1305",
  },
  {
    W: "1033",
    T: "300+0",
    P: "1. c4 e5 2. Nc3 f5 3. g3 c5 4. Bg2 Ne7 5. Nf3 e4 6. Nh4 e3 7. fxe3 g6 8. O-O Nec6 9. d4 cxd4 10. exd4 Nxd4 11. Qxd4 g5 12. Qxh8 Qb6+ 13. e3 gxh4 14. Qe5+ Be7 15. Rxf5 d6 16. Qh8+ Bf8 17. Qxf8+ Kd7 1-0",
    B: "1076",
  },
  {
    W: "1877",
    T: "30+0",
    P: "1. e4 c5 2. Bc4 e6 3. Qh5 Nc6 4. Qf3 d6 5. Bd3 Nf6 6. a3 Be7 7. b3 d5 8. e5 Nd7 9. Qe2 O-O 10. Bb2 d4 11. Qf3 Ndxe5 12. Qh3 Nxd3+ 13. cxd3 e5 14. a4 Bxh3 15. Nxh3 Bg5 16. Nxg5 Qxg5 17. h4 Qxg2 18. Rf1 Qh3 19. Na3 Qxh4 20. O-O-O Qf4 21. Rh1 Nb4 22. Nc4 h6 23. Rde1 Na2+ 24. Kb1 Nb4 25. Re4 Nxd3 26. Rxf4 Nxb2 27. Kxb2 exf4 28. Rd1 b5 29. Rc1 bxc4 30. Rxc4 a5 31. Rxc5 Rab8 32. Rxa5 Rb7 33. Ra7 Rfb8 34. Rxb7 Rxb7 35. b4 f6 36. b5 g5 0-1",
    B: "1950",
  },
  {
    W: "1036",
    T: "60+0",
    P: "1. e4 e5 2. d3 Nc6 3. f4 f6 4. f5 Nge7 5. g4 d5 6. exd5 Nxd5 7. c4 Nb6 8. c5 Bxc5 9. b3 Bb4+ 10. Bd2 Bxd2+ 11. Qxd2 O-O 12. Bg2 Re8 13. Bxc6 bxc6 14. Nc3 e4 15. d4 e3 16. Qe2 Nc4 17. Qxc4+ Kh8 18. Qe2 a6 19. O-O-O Qxd4 20. Rxd4 c5 21. Rd5 Bb7 22. Qc4 Bxd5 23. Qxd5 e2 24. Ngxe2 Rad8 25. Qxd8 Rxd8 26. Rd1 Rxd1+ 27. Kxd1 h5 28. gxh5 g6 29. hxg6 Kg7 30. h4 1-0",
    B: "1110",
  },
  {
    W: "2334",
    T: "60+0",
    P: "1. d4 d6 2. c3 Nf6 3. e3 g6 4. Bd3 Bg7 5. Nf3 O-O 6. O-O c5 7. Nbd2 cxd4 8. cxd4 Nc6 9. a3 Bg4 10. b4 e5 11. dxe5 Nxe5 12. Be2 Nxf3+ 13. Bxf3 Bxf3 14. Qxf3 b6 15. Bb2 Rc8 16. Rac1 Qe7 17. Nc4 d5 18. Ne5 Ne4 19. Nd3 Nd2 20. Qe2 Nxf1 21. Qxf1 Rxc1 22. Qxc1 Bxb2 23. Qxb2 Rc8 24. g3 Qe4 25. Nf4 Rc2 26. Qf6 Rc1# 0-1",
    B: "2224",
  },
  {
    W: "1842",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qe6+ 4. Qe2 Qf6 5. Nf3 Nc6 6. d4 Bg4 7. Be3 O-O-O 8. O-O-O Bxf3 9. Qxf3 Qxf3 10. gxf3 Nf6 11. Bh3+ e6 12. d5 Nb4 13. dxe6 Rxd1+ 14. Rxd1 Bd6 15. exf7+ Kb8 16. a3 Nc6 17. Ne4 Nxe4 18. fxe4 Ne5 19. Be6 Rf8 20. Rg1 Nxf7 21. Rxg7 Ne5 22. Rxh7 a6 23. f4 Nf3 24. f5 1-0",
    B: "1788",
  },
  {
    W: "1907",
    T: "60+0",
    P: "1. b3 d5 2. Bb2 c5 3. g3 Nc6 4. Bg2 e5 5. e3 Nf6 6. Ne2 Be6 7. O-O Bd6 8. Re1 O-O 9. a3 Qc7 10. d3 b6 11. Nd2 Rac8 12. Nf1 Ne7 13. f3 Nd7 14. Rc1 Kh8 15. Qd2 f6 16. h4 Nc6 17. Kh2 e4 18. dxe4 dxe4 19. fxe4 Bxg3+ 20. Nexg3 Nde5 21. c4 Rcd8 22. Qc2 Ng4+ 23. Kg1 Nxe3 24. Rcd1 Nxc2 25. Rxd8 Rxd8 26. Re2 N2d4 27. Re1 Bg4 28. Nh1 Qf4 29. Ne3 Nf3+ 30. Bxf3 Bxf3 31. Rf1 Qxe3+ 32. Kh2 Qe2+ 33. Rf2 Qxe4 34. Bc1 1-0",
    B: "1795",
  },
  {
    W: "1368",
    T: "60+0",
    P: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 Be7 5. Nf3 Nf6 6. e5 cxb2 7. Bxb2 Ng4 8. Nc3 O-O 9. O-O d6 10. e6 Bxe6 11. Bxe6 fxe6 12. Nd4 e5 13. Ne6 Qd7 14. Qb3 Rf6 15. Nc5+ Qe6 16. Nxe6 Nc6 17. Nxc7+ Kh8 18. Nxa8 Nd4 19. Qxb7 Rf8 20. f3 Ne3 21. Rfe1 Nec2 22. Nd5 Nxe1 23. Rxe1 Bg5 0-1",
    B: "1548",
  },
  {
    W: "2118",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Nc3 Qc7 6. Be3 Bb4 7. Be2 Nf6 8. O-O O-O 9. Nb3 Bxc3 10. bxc3 d6 11. f3 Nc6 12. Qd3 b5 13. Rad1 Rd8 14. c4 bxc4 15. Qxc4 Bb7 16. Kf2 Rac8 17. Qd3 Nb4 18. Qd2 Nc6 19. c4 Ne5 20. c5 dxc5 21. Qc3 c4 22. Nd4 Nd3+ 23. Bxd3 cxd3 24. Qxc7 Rxc7 25. Rxd3 e5 26. Nf5 Rxd3 27. Rb1 g6 28. Ng3 Kg7 29. h4 h5 30. Bc1 Rdc3 31. Bb2 Rc2+ 32. Kg1 Bc6 33. Bxe5 Rb7 34. Rd1 Rxa2 35. Rd6 Rb1+ 36. Kh2 Be8 37. Bxf6+ Kf8 38. Rd8 Rc1 39. e5 Rcc2 40. Nf1 Rxg2+ 41. Kh1 Rgf2 42. Kg1 Rfe2 43. Be7+ Kxe7 44. Rc8 Bd7 45. Re8+ Kxe8 46. Ne3 Rxe3 47. Kf1 Rb3 48. Ke1 Rb1# 0-1",
    B: "2157",
  },
  {
    W: "2373",
    T: "60+0",
    P: "1. d4 Nf6 2. c4 e6 3. Nc3 b6 4. Nf3 Bb7 5. Bg5 Bb4 6. e3 h6 7. Bh4 g5 8. Bg3 Ne4 9. Qc2 f5 10. Be5 Bxc3+ 11. bxc3 O-O 12. h3 d6 13. Bh2 Nd7 14. Bd3 Qf6 15. O-O c5 16. Rab1 Rac8 17. Nd2 d5 18. cxd5 cxd4 19. Nxe4 fxe4 20. Bxe4 Rc7 21. exd4 Rcc8 22. Rbe1 Bxd5 23. Bxd5 exd5 24. Re2 Qf7 25. Rfe1 Rfe8 26. Be5 Nxe5 27. Rxe5 Rxe5 28. Rxe5 Re8 29. Qe2 Rxe5 30. Qxe5 Kh7 31. f3 Kg6 32. g4 Qxf3 33. Qe6+ Kg7 34. Qe7+ Kg6 35. Qxa7 Qg3+ 36. Kf1 Qxh3+ 37. Kf2 Qxg4 38. Qxb6+ Kh5 39. Qd6 Qf5+ 40. Ke3 Qh3+ 41. Kd2 Qg2+ 42. Kd3 g4 43. Qe5+ Kh4 44. Qf6+ Kh5 45. Qf5+ Kh4 46. Qf6+ Kg3 47. Qe5+ Kh3 48. Qh5+ Kg3 49. Qe5+ Kf2 50. Qf4+ Kg1 51. Qe3+ Kh1 52. Qxh6+ Kg1 53. Qe3+ Kh2 54. a3 Qf3 55. Qxf3 gxf3 56. Ke3 Kg2 57. c4 f2 58. c5 f1=Q 59. c6 Qf3+ 60. Kd2 Qe4 61. Kc3 1-0",
    B: "2588",
  },
  {
    W: "1904",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Bf4 e5 6. Nxc6 bxc6 7. Bxe5 Bb4+ 8. c3 Qe7 9. Bxf6 Bxc3+ 10. Bxc3 Qxe4+ 11. Be2 O-O 12. O-O Bb7 13. Nd2 Qg6 14. Bf3 d5 15. Re1 Rfd8 16. b3 d4 17. Bb2 h5 18. Nc4 h4 19. h3 Ba6 20. Ne5 Qd6 21. Rc1 c5 22. Nc4 Qg6 23. Bxa8 Rxa8 24. Ne5 Qd6 25. Qf3 Rf8 26. Ng4 Qd7 27. Rxc5 Bb7 28. Qf4 d3 29. Ne5 Qd6 30. Nxd3 Qxd3 31. Rc7 Qd5 32. f3 Qd4+ 33. Kh1 Qxf4 0-1",
    B: "1865",
  },
  {
    W: "1588",
    T: "60+0",
    P: "1. e4 e6 2. f4 d5 3. e5 Bc5 4. Nf3 c6 5. d4 Bb6 6. Bd3 Ne7 7. O-O O-O 8. Ng5 g6 9. h4 Nf5 10. h5 Nd7 11. hxg6 fxg6 12. Nxe6 Qe7 13. Nxf8 Qxf8 14. Bxf5 gxf5 15. c3 Qh6 16. Kf2 Qh4+ 17. Ke2 Qg4+ 18. Ke1 Qxd1+ 19. Kxd1 Nf8 20. Nd2 Ne6 21. Nf3 Bd7 22. Ng5 Nxg5 23. fxg5 Rf8 24. Kc2 f4 25. Bxf4 Bf5+ 26. Kb3 a5 27. Bh2 Kg7 28. g4 Bxg4 29. Rxf8 Kxf8 30. Rf1+ Kg7 31. e6 Bxe6 32. Re1 Bg4 33. Re7+ Kg6 34. Rxb7 Bd8 35. Kc2 Bxg5 36. Bc7 Bf5+ 37. Kd1 Bg4+ 38. Ke1 Bh4+ 39. Kf1 Bh3+ 40. Kg1 Bg5 41. Bxa5 Be3+ 0-1",
    B: "1610",
  },
  {
    W: "1938",
    T: "60+0",
    P: "1. e4 d6 2. d3 Nf6 3. Nf3 c6 4. Be2 Nbd7 5. Bg5 e5 6. Bxf6 Nxf6 7. Nc3 Be7 8. O-O Qc7 9. d4 O-O 10. dxe5 dxe5 11. Bc4 b5 12. Bb3 a5 13. a3 Bg4 14. h3 Bh5 15. g4 Bg6 16. Qe2 b4 17. axb4 axb4 18. Na4 Nxe4 19. Rfe1 Ng5 20. Nxg5 Bxg5 21. Qxe5 Qxe5 22. Rxe5 Bf6 23. Ree1 Rfc8 24. Nb6 Rxa1 25. Rxa1 Rd8 26. Ra7 h6 27. Nd7 Be4 28. Nxf6+ gxf6 29. c3 Kf8 30. cxb4 Bd5 31. Bxd5 Rxd5 32. Ra3 Rd1+ 33. Kg2 Rd2 34. Rc3 Rxb2 35. Rxc6 Rxb4 36. Rc8+ Kg7 37. Rc7 Rb2 38. Kg3 Rb3+ 39. f3 Kg6 40. h4 Rb4 41. h5+ Kg7 42. Rc8 Rb3 43. Rc5 Rb4 44. Rc6 Rb3 45. Rc8 Ra3 46. Rc1 Rb3 47. Re1 Rb4 48. Re7 Kf8 49. Rc7 Rb3 50. Ra7 Kg7 51. Ra4 Rc3 52. Rc4 Rb3 53. Rb4 Ra3 54. Rb5 Rc3 55. Rb6 Ra3 56. Rb7 Rc3 57. Ra7 Rd3 58. Rb7 Re3 59. Rc7 Rd3 60. Rc8 Ra3 61. Rc7 Rb3 62. Rxf7+ Kxf7 63. g5 Rxf3+ 64. Kxf3 fxg5 65. Kg4 Kf6 66. Kf3 Ke5 67. Kg3 Kf5 0-1",
    B: "1958",
  },
  {
    W: "1641",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 Nf6 4. Nc3 e6 5. d3 Be7 6. Bg5 O-O 7. O-O a6 8. a4 Nbd7 9. Nd2 Ne5 10. Ba2 Ng6 11. f4 d5 12. f5 exf5 13. exf5 Ne5 14. Nf3 h6 15. Bxf6 Bxf6 16. Nxe5 Bxe5 17. Nxd5 Bxb2 18. Rb1 Bd4+ 19. Kh1 Qg5 20. Qf3 Re8 21. f6 g6 22. Ne7+ Kh8 23. Bxf7 Rf8 24. Ba2 Bxf6 25. Qd5 Bf5 26. Rxf5 gxf5 27. Qd7 Bxe7 28. Bd5 Rad8 29. Qe6 f4 30. Rf1 Rxd5 31. Qxd5 Qxd5 32. h3 f3 33. Kh2 fxg2 0-1",
    B: "1649",
  },
  {
    W: "1706",
    T: "60+0",
    P: "1. d3 a6 2. g3 b5 3. Bg2 Nc6 4. Nf3 Bb7 5. O-O e6 6. e4 Nf6 7. c3 d6 8. d4 Nxe4 9. Nbd2 Nf6 10. Nb3 Rb8 11. Be3 Be7 12. Nbd2 O-O 13. Nh4 Na5 14. b3 Bxg2 15. Kxg2 Nb7 16. c4 bxc4 17. bxc4 c5 18. dxc5 Nxc5 19. Ndf3 Qc7 20. Nd4 Nce4 21. Nhf3 Qxc4 22. Rc1 Qd5 23. Ne2 Qxa2 24. Nf4 Rfc8 25. Rxc8+ Rxc8 26. Qd3 h6 27. Rc1 Ng4 28. Rxc8+ Kh7 29. h3 Nxe3+ 30. Qxe3 Nf6 31. Qc1 g6 32. g4 Qa4 33. Rc7 1-0",
    B: "1716",
  },
  {
    W: "1517",
    T: "120+1",
    P: "1. e4 c5 2. Bc4 e6 3. Nf3 d5 4. exd5 exd5 5. Bb5+ Bd7 6. O-O Bxb5 0-1",
    B: "1502",
  },
  {
    W: "1552",
    T: "120+1",
    P: "1. e4 g6 2. d4 Bg7 3. c3 e6 4. Bc4 Ne7 5. Nf3 b6 6. Bg5 Bb7 7. Nbd2 f6 8. Qc2 fxg5 9. Nxg5 O-O 10. O-O d5 11. exd5 Nxd5 12. Bd3 Qxg5 13. Ne4 Qg4 14. Rae1 Nf4 15. g3 Nh3+ 16. Kg2 Nf4+ 17. Kg1 Nh5 18. Be2 Qh3 19. Ng5 Qg2# 0-1",
    B: "1545",
  },
  {
    W: "1365",
    T: "120+1",
    P: "1. e4 c6 2. b3 d5 3. exd5 cxd5 4. Bb2 Nc6 5. Nf3 Bg4 6. Be2 e6 7. O-O Nf6 8. h3 Bxf3 9. Bxf3 Be7 10. d3 O-O 11. Nd2 Rc8 12. c4 dxc4 13. dxc4 Nd4 14. Re1 Nxf3+ 15. Nxf3 Qxd1 16. Raxd1 Rcd8 17. Nd4 Bc5 18. Nb5 Rxd1 19. Rxd1 a6 20. Nc3 Bb4 21. Rd3 h6 22. Rf3 Bxc3 23. Bxc3 Ne4 24. Bb2 Rd8 25. g4 Rd2 26. Be5 Rxa2 27. Re3 Nd2 28. Re2 Ra1+ 29. Kg2 Nf3 30. Kxf3 f6 31. Bxa1 e5 32. Ra2 1-0",
    B: "1459",
  },
  {
    W: "2051",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O d6 5. c3 Nf6 6. d4 exd4 7. cxd4 Bb6 8. d5 Ne7 9. Bg5 Ng6 10. Nc3 h6 11. Be3 Bg4 12. Bxb6 axb6 13. Qd3 O-O 14. Nd4 Ne5 15. Qg3 Nxc4 16. f4 Bd7 17. e5 dxe5 18. fxe5 Nh7 19. Ne4 Ng5 20. Nf6+ gxf6 21. Rxf6 Kh7 22. h4 Ne4 23. Qd3 Nd2 24. Rd1 Kh8 25. Rxh6+ Kg7 26. Rh5 f5 27. exf6+ Rxf6 28. Rxd2 Nxd2 29. Qxd2 Rg6 30. Qf4 Qf6 31. Qd2 Re8 32. Rg5 Rxg5 33. hxg5 Qe5 34. Nf3 Qe3+ 35. Qxe3 Rxe3 36. Kf2 Re7 37. Kg3 Kg6 38. Kf4 Rf7+ 39. Ke4 Rxf3 40. gxf3 Kxg5 41. f4+ Kf6 42. b4 b5 43. a3 Bf5+ 44. Kd4 Bc2 45. Kc5 Be4 46. d6 cxd6+ 47. Kxd6 Kf5 48. Kc5 Bc6 49. a4 Kxf4 50. axb5 Bd7 51. b6 1-0",
    B: "1934",
  },
  {
    W: "2093",
    T: "60+0",
    P: "1. d4 c5 2. d5 d6 3. c4 Nf6 4. Nc3 g6 5. e4 Bg7 6. f4 e6 7. Nf3 exd5 8. cxd5 O-O 9. Bc4 Bg4 10. O-O Bxf3 11. Qxf3 a6 12. a4 b5 13. axb5 axb5 14. Rxa8 bxc4 15. Be3 Qb6 16. e5 dxe5 17. fxe5 Nh5 18. Qf2 Bxe5 19. Bxc5 Qb7 20. Bxf8 Qxa8 21. Qxf7+ Kh8 22. Bh6 Qa7+ 23. Kh1 Qxf7 24. Rxf7 Ng7 25. Rb7 Nf5 26. Bg5 Kg8 27. Nb5 Na6 28. d6 Nxd6 29. Nxd6 Bxd6 30. Rb6 Be7 31. Bxe7 Kf7 32. Rxa6 Kxe7 33. Ra7+ Kd6 34. Rxh7 Kd5 35. Rh6 c3 36. Rxg6 cxb2 37. Rb6 b1=Q+ 38. Rxb1 Kd4 39. Rf1 Ke4 40. h4 Ke3 41. Kg1 Ke2 42. h5 Ke3 43. h6 Ke2 44. h7 Ke3 45. h8=Q Kd3 46. g4 Ke4 47. g5 Kd5 48. g6 Ke4 49. g7 Ke3 50. g8=Q Ke2 51. Qe8+ Kd3 52. Qd4+ Kxd4 53. Rd1+ Kc3 54. Qc6+ Kb2 55. Rd5 Ka3 56. Rb5 Ka4 57. Qa6# 1-0",
    B: "2014",
  },
  {
    W: "2166",
    T: "180+0",
    P: "1. e4 d6 2. Nc3 Nf6 3. Bc4 Nbd7 4. f4 e5 5. Nf3 Be7 6. d3 h6 7. O-O c6 8. a4 Ng4 9. a5 b6 10. h3 Ngf6 11. Qe1 b5 12. Ba2 a6 13. Qg3 O-O 14. fxe5 dxe5 15. Bxh6 Nh5 16. Qg4 Ndf6 17. Qg5 Kh7 18. Bxg7 Nxg7 19. Nxe5 Ng8 20. Qe3 Be6 21. Nxf7 Bxf7 22. Bxf7 Bg5 23. Qg3 Qd4+ 24. Kh1 Bh6 25. Qg6+ Kh8 26. Bxg8 Rxf1+ 27. Rxf1 Kxg8 28. Qxh6 Rf8 29. Rxf8+ Kxf8 30. Qxc6 Qf2 31. Qc8+ Kf7 32. Qg4 Qxc2 33. Nd5 Qxd3 34. Qf3+ Kg8 35. Qxd3 1-0",
    B: "2102",
  },
  {
    W: "2027",
    T: "120+1",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Nf3 c5 5. cxd5 Nxd5 6. e3 Nc6 7. Be2 cxd4 8. exd4 Bb4 9. Bd2 O-O 10. O-O a5 11. a3 Bxc3 12. bxc3 b6 13. c4 Nf6 14. Bg5 h6 15. Bh4 Ba6 16. Rc1 Rc8 17. Qd2 Ne4 18. Qe3 Ng5 19. Nxg5 hxg5 20. Bxg5 Qxd4 21. Qg3 Qe5 22. Bf4 Qxe2 23. Rfe1 Qd3 24. Qxd3 Rfd8 25. Qg3 Nd4 26. Be5 Nf5 27. Qg4 Rd3 28. Re3 Rcd8 29. Rg3 Bxc4 30. Rxd3 1-0",
    B: "1917",
  },
  {
    W: "1704",
    T: "120+1",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 a6 6. a3 Be7 7. b4 c4 8. a4 f5 9. exf6 Nxf6 10. Bf4 O-O 11. Qd2 Ne4 12. Qe3 e5 13. Bxe5 Nxe5 14. dxe5 Bg4 15. Bxc4 Bxf3 16. gxf3 Ng5 17. Be2 Qc7 18. h4 1-0",
    B: "1745",
  },
  {
    W: "1344",
    T: "120+1",
    P: "1. d4 d5 2. Nf3 e6 3. Bf4 Nf6 4. h3 Bd6 5. Bxd6 Qxd6 6. Nc3 O-O 7. e3 c5 8. Be2 c4 9. O-O Bd7 10. b3 b5 11. Ne5 a5 12. Nxd7 Nbxd7 13. Nxb5 Qb4 14. bxc4 dxc4 15. Nc7 Rac8 16. Rb1 Qd6 17. Nb5 Qc6 18. Nc3 Rb8 19. Bf3 Rxb1 20. Qxb1 Qc7 21. Qa1 Rb8 22. Rb1 Rxb1+ 23. Qxb1 e5 24. dxe5 Nxe5 25. Qd1 g6 26. Be2 Qc6 27. Bf3 Nxf3+ 28. Qxf3 Nd5 29. Nxd5 Qa4 30. Qf6 Qxc2 31. Ne7+ Kf8 32. Nd5 Qd1+ 33. Kh2 Qxd5 34. Qf3 Qd6+ 35. Qg3 Qxg3+ 36. Kxg3 c3 37. Kf3 c2 38. e4 c1=Q 39. g4 Qc3+ 40. Kg2 Qb2 41. Kg3 Qxa2 42. f3 Qb3 43. h4 a4 44. h5 gxh5 45. gxh5 a3 46. Kg4 a2 47. f4 a1=Q 48. f5 Qg1+ 49. Kh4 Qbg3# 0-1",
    B: "1308",
  },
  {
    W: "937",
    T: "120+1",
    P: "1. Nc3 d5 2. d4 Nf6 3. Nf3 e6 4. Ne5 Nc6 5. Bf4 Nxe5 6. Bxe5 Be7 7. e3 Ne4 8. Nxe4 dxe4 9. Bxg7 Rg8 10. Qg4 e5 11. Qxe4 Rxg7 12. Qxe5 f6 13. Qh5+ Kf8 14. Bd3 Bb4+ 15. c3 Qe7 16. cxb4 Qxb4+ 17. Ke2 Kg8 18. Rac1 c6 19. Qe8+ Qf8 20. Bc4+ Rf7 21. Bxf7+ Kg7 22. Qxf8+ Kxf8 23. Bh5 h6 24. h3 b5 25. g4 Bd7 26. f3 Re8 27. e4 b4 28. Rc4 a5 29. Rc5 a4 30. e5 fxe5 31. d5 e4 0-1",
    B: "1102",
  },
  {
    W: "1530",
    T: "120+1",
    P: "1. e4 e5 2. f4 Nc6 3. Nf3 exf4 4. d4 Nf6 5. Nc3 Bb4 6. Bxf4 O-O 7. Bd3 d5 8. e5 Ne4 9. Bxe4 dxe4 10. Nd2 Nxd4 11. Ndxe4 Bxc3+ 12. Nxc3 Re8 13. O-O Bf5 14. Rc1 c5 15. Ne2 Nxe2+ 16. Qxe2 Qd4+ 17. Kh1 Qxb2 18. c4 Qxe2 0-1",
    B: "1459",
  },
  {
    W: "1587",
    T: "120+1",
    P: "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. e4 cxd4 5. Nd5 e5 6. Nf3 Ne7 7. Bg5 Nbc6 8. b4 O-O 9. b5 f6 10. Bxf6 Bxf6 11. Nxf6+ Rxf6 12. bxc6 Nxc6 13. Qb3 Kg7 14. Bd3 b6 15. O-O Bb7 16. Qb5 d6 17. a4 Rc8 18. a5 Nxa5 19. Rxa5 bxa5 20. Qxb7+ Rc7 21. Qb5 Qc8 22. Qxa5 Rff7 23. Rb1 Qg4 24. h3 Qf4 25. Qa3 Rcd7 26. c5 dxc5 27. Qxc5 g5 28. Qxe5+ Qxe5 29. Nxe5 h6 30. Nxf7 Rxf7 31. Ra1 Re7 32. Ra4 Kf6 33. Rxd4 Ke5 34. Rd5+ Kf4 35. Kh2 h5 36. Rf5# 1-0",
    B: "1615",
  },
  {
    W: "1570",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 d6 3. Nc3 a6 4. a4 h6 5. d4 Nc6 6. dxe5 Be7 7. exd6 Bxd6 8. Bd3 Nge7 9. Be3 O-O 10. O-O Ne5 11. Nxe5 Bxe5 12. Qe1 Ng6 13. Rd1 Bd7 14. f4 Bxc3 15. Qxc3 Bg4 16. Rd2 c6 17. e5 Ne7 18. f5 Nd5 19. Qc5 Nxe3 20. Qxe3 f6 21. Bc4+ Kh8 22. exf6 Qxf6 23. Rdf2 Rae8 24. Qb6 Re7 25. Be6 Bh5 26. Qb4 Qe5 27. a5 Be8 28. Qxe7 Qc5 29. Qxc5 Rf6 30. Qe7 Bh5 31. Qxb7 Kh7 32. Qxc6 Bf7 33. Qxa6 Be8 34. Bg8+ Kxg8 35. Qc8 Kf7 36. Qc4+ Kf8 37. b4 Bf7 38. Qc5+ Kg8 39. b5 1-0",
    B: "1564",
  },
  {
    W: "1962",
    T: "30+0",
    P: "1. d4 d5 2. c4 e6 3. Nf3 Nf6 4. Bg5 Be7 5. Nc3 c6 6. e3 h6 7. Bh4 a6 8. cxd5 cxd5 9. a4 Nc6 10. Bd3 O-O 11. a5 Re8 12. Qa4 Bd7 13. Qa2 Bd6 14. Bg3 Qc7 15. O-O Bxg3 16. hxg3 e5 17. Rac1 e4 18. Nxd5 Nxd5 19. Qxd5 exd3 20. Ne5 Qd8 21. Nxd3 Rc8 22. Qxd7 Qxd7 23. Ne5 Qe7 24. Nxc6 bxc6 25. Rc5 Qxc5 26. Rc1 Rcd8 27. Rxc5 Rd7 28. Rxc6 Red8 29. Rxa6 f6 30. Rc6 Kf7 31. a6 Rd6 32. Rc7+ R6d7 33. Rxd7+ Rxd7 34. a7 Rxa7 35. d5 Rb7 36. d6 Ke6 37. e4 Kd7 38. f4 f5 39. e5 Ke6 40. d7 Kxd7 0-1",
    B: "2054",
  },
  {
    W: "1392",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 b5 5. Bb3 Nf6 6. Nc3 Na5 7. d3 Bb7 8. a3 Bc5 9. h3 O-O 10. O-O d5 11. Ba2 d4 12. Ne2 Bd6 13. Ng3 h6 14. Nf5 c5 15. N3h4 Rc8 16. Nxh6+ gxh6 17. Bxh6 Re8 18. Qf3 Bf8 19. Qg3+ Kh7 20. Bxf7 Re7 21. Qg6+ Kh8 22. Qxf6+ Bg7 23. Qxg7# 1-0",
    B: "1379",
  },
  {
    W: "1276",
    T: "300+3",
    P: "1. e4 e5 2. Nc3 Nf6 3. d3 Bc5 4. Nge2 Ng4 5. d4 exd4 6. Nxd4 O-O 7. Qxg4 d5 8. Qe2 Bxd4 9. Nxd5 Re8 10. f3 f5 11. Qc4 c5 12. Nc7+ Kf8 13. Nxa8 fxe4 14. fxe4 Rxe4+ 15. Be2 Bf2+ 16. Kxf2 Rxc4 17. Bxc4 Nc6 18. Bf4 Bf5 19. Rad1 Qxa8 20. Bd6+ Ke8 21. Rhe1+ Kd7 22. Bxc5+ Kc7 23. Bd6+ Kb6 24. Rd5 a6 25. a4 Bxc2 26. b4 Bxa4 27. Bc5+ Kc7 28. Re6 a5 29. b5 Nb4 30. Bb6+ Kc8 31. Re8# 1-0",
    B: "1299",
  },
  {
    W: "1054",
    T: "300+3",
    P: "1. b3 d5 2. Nc3 c6 3. Bb2 Be6 4. e4 Qb6 5. exd5 cxd5 6. g4 Qc6 7. Bb5 Qxb5 8. Nxb5 Nc6 9. Nc7+ Kd7 1-0",
    B: "1054",
  },
  {
    W: "1563",
    T: "300+3",
    P: "1. e4 e5 2. f3 Nc6 3. Bb5 Qh4+ 4. g3 Qf6 5. Nc3 Nge7 6. d3 a6 7. Ba4 Nd4 8. Be3 b5 9. Bb3 Nxb3 10. cxb3 Ng6 11. Qe2 Bb4 12. O-O-O Qc6 13. Bd2 a5 14. Kb1 Bxc3 15. Bxc3 b4 16. Bd2 d6 17. Rc1 Qb5 18. Rxc7 Ba6 19. Bg5 O-O 20. Kc2 Rfc8 21. Rxc8+ Rxc8+ 22. Kd2 Qc5 23. Ke1 Nf4 24. gxf4 Qc1+ 25. Kf2 Rc2 26. Qxc2 Qxc2+ 27. Kg3 exf4+ 28. Bxf4 Qxd3 29. Nh3 Bc8 30. Nf2 Qa6 31. Rd1 d5 32. Rxd5 h6 33. Ng4 Bxg4 34. Kxg4 Qf1 35. Rxa5 Qg1+ 36. Bg3 Qc1 37. Ra8+ Kh7 38. Ra5 Qxb2 39. h4 Qg2 40. Ra4 Qc2 41. Rxb4 Qd2 42. Rb5 Qd7+ 43. Kf4 Qxb5 44. a4 Qd3 45. a5 Qxb3 46. a6 Qb6 47. Kg4 Qxa6 48. f4 Qa3 49. h5 Qa6 50. e5 Qe6+ 51. Kf3 Qf5 52. Bf2 Qxh5+ 53. Ke4 g6 54. Be3 Qf5+ 55. Kf3 f6 56. exf6 Qxf6 57. Kg4 h5+ 58. Kf3 h4 59. Kg4 Qe7 60. Bf2 Qe2+ 61. Kxh4 Qxf2+ 62. Kg5 Qc5+ 63. Kg4 Qf5+ 64. Kf3 Kh6 65. Kg3 Kh5 66. Kf3 Qg4+ 67. Ke4 Kh4 68. Ke5 Qf5+ 69. Kd6 Qxf4+ 70. Ke6 g5 0-1",
    B: "1438",
  },
  {
    W: "1214",
    T: "300+3",
    P: "1. e4 c5 2. Bc4 e6 3. e5 Nc6 4. d4 Nxd4 5. c3 Nc6 6. Nf3 d6 7. exd6 Qxd6 8. O-O Nf6 1-0",
    B: "1130",
  },
  {
    W: "1706",
    T: "300+3",
    P: "1. d4 d5 2. c4 e6 3. Nc3 h6 4. Nf3 Nf6 5. Bf4 c6 6. e3 Bb4 7. a3 Bxc3+ 8. bxc3 Nbd7 9. Be2 Ne4 10. Qc2 Qa5 11. cxd5 Nxc3 12. O-O Nxe2+ 13. Qxe2 Qxd5 14. Rfc1 Nf6 15. Rab1 O-O 16. Be5 Ne4 17. Nh4 Qd8 18. Qg4 f6 19. Qxe4 fxe5 20. dxe5 Qg5 21. Nf3 Qh5 22. Rd1 b6 23. Rd6 Bb7 24. Rd7 Rab8 25. Nh4 Qg5 26. Ng6 Rfd8 27. Ne7+ Kh8 28. Rxd8+ Rxd8 29. Ng6+ Kg8 30. Nf4 Qg4 31. Qc4 Re8 32. a4 c5 33. Qb5 Re7 0-1",
    B: "1659",
  },
  {
    W: "1429",
    T: "300+3",
    P: "1. e4 Nc6 2. f4 d5 3. e5 f6 4. Nf3 fxe5 5. Nxe5 Nxe5 6. Qh5+ g6 7. Qxe5 Nf6 8. Bd3 Bg7 9. O-O O-O 10. Nc3 c6 11. Qg5 Qd6 12. Bxg6 hxg6 13. Qxg6 Qc5+ 14. Kh1 d4 15. Ne2 Bf5 16. Qg3 Qxc2 17. Nxd4 Qd3 18. Qxd3 Bxd3 19. Rf2 Ne4 20. Rf3 Bxd4 21. Rxd3 Nf2+ 22. Kg1 Nxd3+ 0-1",
    B: "1405",
  },
  {
    W: "1313",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O Nf6 5. Nc3 O-O 6. d3 d5 7. Bg5 dxc4 8. Bxf6 Qxf6 9. Nd5 Qd6 10. d4 exd4 11. Nxd4 Nxd4 0-1",
    B: "1303",
  },
  {
    W: "1549",
    T: "900+15",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 e5 5. Nf3 Nf6 6. Bc4 Be7 7. O-O O-O 8. Nc3 Nc6 9. a3 a6 10. Re1 Qe8 11. Nd5 Bd8 12. Nxf6+ Bxf6 13. Bd5 Ne7 14. Bb3 Bd7 15. Qxd6 Bb5 16. Qb6 Rb8 17. Qe3 h6 18. Bd2 Ng6 19. Bb4 Rd8 20. Bxf8 Nxf8 21. Rad1 Rxd1 22. Rxd1 Ne6 23. Bxe6 fxe6 24. c4 Ba4 25. c5 Bxd1 26. Qd2 Ba4 27. Ne1 Bg5 28. Qb4 Bb5 29. Nf3 Be7 30. Nxe5 Qh5 31. Nf3 b6 32. Qb3 Bxc5 33. Qxe6+ Kh7 34. g4 Qh3 35. Qf5+ Kg8 36. Qe6+ Kf8 37. Qf5+ Ke8 38. Qg6+ Kd8 39. Nd2 Qd3 40. Qxg7 Qxd2 41. Qf6+ Kc7 42. Qf7+ Bd7 43. e5 Qc1+ 44. Kg2 Qg5 45. e6 Qxg4+ 46. Kf1 Qxe6 47. Qf4+ Kb7 48. Qf3+ Bc6 49. Qg3 Qc4+ 50. Kg1 Qc1# 0-1",
    B: "1388",
  },
  {
    W: "1551",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 d6 3. d4 f6 4. dxe5 Bg4 5. exd6 Bxf3 6. Qxf3 Bxd6 7. Nc3 c6 8. Bf4 g5 9. Bxd6 Qxd6 10. Rd1 Qb4 11. Qh5+ Ke7 12. e5 Ke6 13. exf6 Kxf6 14. Qf3+ Kg6 15. Bd3+ Kg7 16. O-O Nf6 17. Rfe1 Nbd7 18. Qg3 Rae8 19. Qxg5+ Kf7 20. a3 Rxe1+ 21. Rxe1 Qxb2 22. Bc4+ Kf8 23. Qh6# 1-0",
    B: "1630",
  },
  {
    W: "1988",
    T: "900+15",
    P: "1. d4 b6 2. c4 Bb7 3. Nf3 e6 4. Nc3 Bb4 5. Bd2 Nf6 6. e3 O-O 7. Bd3 d6 8. O-O Nbd7 9. Re1 Bxc3 10. Bxc3 c5 11. b4 cxd4 12. exd4 Rc8 13. Rc1 d5 14. c5 bxc5 15. dxc5 Ba8 16. Bb1 Nb8 17. Qd3 g6 18. b5 Rxc5 19. Bxf6 Rxc1 20. Bxd8 Rxe1+ 21. Nxe1 Rxd8 22. Qc3 Rf8 23. Qc5 a6 24. Qa7 axb5 25. Qxa8 Nd7 26. Qc6 Ne5 27. Qxb5 Nc4 1-0",
    B: "1924",
  },
  {
    W: "1788",
    T: "900+15",
    P: "1. d4 d5 2. Bf4 Nc6 3. e3 Bf5 4. c3 e6 5. Nf3 Bd6 6. Qb3 Rb8 7. Qa4 Ne7 8. Ne5 O-O 9. Nxc6 Nxc6 10. Bxd6 Qxd6 11. Nd2 a6 12. c4 dxc4 13. Nxc4 Qb4+ 14. Qxb4 Nxb4 15. Kd2 b5 16. Ne5 Rbd8 17. a3 Nd5 18. Nd3 Bxd3 19. Bxd3 f5 20. Rhc1 Rd7 21. Rc6 Nb6 22. Rxe6 g6 23. Rc1 Nd5 24. Rxa6 Re8 25. Bxb5 1-0",
    B: "1784",
  },
  {
    W: "1611",
    T: "900+15",
    P: "1. Nf3 c5 2. e4 d6 3. Bb5+ Nc6 4. O-O a6 5. Bxc6+ bxc6 6. c3 Nf6 7. Re1 g6 8. e5 dxe5 9. Nxe5 Qc7 10. d3 Bg7 11. Bf4 Nd5 12. Bg3 Qb6 13. Nc4 Qa7 14. Nd6+ Kf8 15. Nxc8 Rxc8 16. Nd2 h5 17. h4 Kg8 18. Ne4 f6 19. f3 Kh7 20. Bf2 Qb8 21. Nxc5 Rhe8 22. Qc2 a5 23. Re6 Nf4 24. Bg3 Ne2+ 25. Qxe2 Qxg3 26. Ne4 Qxh4 27. Nd6 exd6 28. Rxe8 Rxe8 29. Qxe8 c5 30. Re1 Qg3 31. Re7 Qg5 32. Qf7 Qh6 33. Qxf6 Kg8 34. Qf7+ Kh7 35. f4 Kh8 36. Re8+ Kh7 37. Qg8# 1-0",
    B: "1626",
  },
  {
    W: "1245",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bc5 4. Bc4 h6 5. Na4 a6 6. Nxc5 b5 7. Bd5 d6 8. Bxc6+ 1-0",
    B: "1348",
  },
  {
    W: "1430",
    T: "900+15",
    P: "1. b3 c5 2. Bb2 Nc6 3. g3 d5 4. e3 Nf6 5. Ne2 e5 6. d4 cxd4 7. exd4 exd4 8. Nxd4 Nxd4 9. Bxd4 Bb4+ 10. c3 Ba5 11. b4 Bb6 12. Bb5+ Bd7 13. Qe2+ Ne4 14. Bxd7+ Qxd7 15. Bxb6 axb6 16. Nd2 Qe7 17. Qb5+ Qd7 18. Qxb6 O-O 19. Nxe4 dxe4 20. O-O Ra6 21. Rad1 Qe7 22. Qe3 Rxa2 23. f3 f5 24. fxe4 fxe4 25. Rxf8+ Kxf8 26. Qf4+ Kg8 27. Qc1 e3 28. Re1 e2 29. Qf4 Rd2 30. Qxd2 1-0",
    B: "1450",
  },
  {
    W: "1653",
    T: "900+15",
    P: "1. e4 c5 2. c4 Nc6 3. Nc3 Nf6 4. h3 Ne5 5. b3 e6 6. Bb2 Be7 7. d4 cxd4 8. Qxd4 Nc6 9. Qe3 Nb4 10. O-O-O Nh5 11. Qf3 g6 12. g3 O-O 13. h4 Bf6 14. Nge2 Bg7 15. g4 Nf6 16. g5 Nh5 17. Nd4 b6 18. e5 Rb8 19. Qe3 Bb7 20. Rg1 a6 21. f4 b5 22. cxb5 a5 23. a3 Nd5 24. Nxd5 Bxd5 25. a4 Qb6 26. Kb1 Rbc8 27. Bd3 Qc5 28. Rc1 Qb4 29. Nc2 Qxf4 30. Qxf4 Nxf4 31. Rcd1 Bxb3 32. b6 Nxd3 33. Rxd3 Bxc2+ 0-1",
    B: "1713",
  },
  {
    W: "1946",
    T: "360+2",
    P: "1. d4 c5 2. e3 e6 3. c3 d5 4. Nf3 Nc6 5. Nbd2 Bd6 6. Bb5 Bd7 7. Bxc6 bxc6 8. O-O Nf6 9. Qc2 h6 10. h3 O-O 11. Rd1 cxd4 12. exd4 Re8 13. a3 e5 14. dxe5 Bxe5 15. Nf1 Bc7 16. Be3 Ne4 17. c4 Bf5 18. cxd5 Qd6 19. Qxc6 Qd8 20. Rac1 Ng5 21. Bxg5 1-0",
    B: "1892",
  },
  {
    W: "2083",
    T: "60+0",
    P: "1. e3 c5 2. d4 cxd4 3. exd4 d5 4. c4 Nf6 5. cxd5 e6 6. Nc3 exd5 7. Bg5 Nc6 8. Nf3 Be7 9. Bb5 O-O 10. O-O Bg4 11. h3 Bh5 12. Re1 Bg6 13. Ne5 Rc8 14. Bxc6 bxc6 15. Qa4 c5 16. Bxf6 Bxf6 17. dxc5 d4 18. Nb5 Rxc5 19. Nxd4 Rd5 20. Ndc6 Qb6 21. Nxg6 hxg6 22. Ne7+ Bxe7 23. Rxe7 Qxb2 24. Re8 Rxe8 25. Qxe8+ Kh7 26. Qe3 Qxa1+ 27. Kh2 Qe5+ 28. Qg3 Qxg3+ 29. Kxg3 Rd2 30. Kf3 Rxa2 31. g4 Rb2 32. Ke3 a5 33. Kd3 a4 34. Kc3 Rxf2 35. Kb4 Rh2 36. Kxa4 Rxh3 37. Kb4 Rg3 38. Kc5 Rxg4 39. Kc6 Rf4 40. Kd7 Rf6 41. Ke8 g5 0-1",
    B: "2061",
  },
  {
    W: "2370",
    T: "60+0",
    P: "1. Nf3 g5 2. g3 g4 3. Nh4 h5 4. e4 e6 5. d4 Be7 6. Ng2 h4 7. Qxg4 hxg3 8. fxg3 d5 9. e5 f5 10. Qe2 Nf6 11. exf6 Bxf6 12. c3 Nc6 13. Bf4 Bd7 14. Nd2 Qe7 15. O-O-O a5 16. Ne3 a4 17. a3 Na5 18. h4 Nb3+ 19. Nxb3 axb3 20. g4 fxg4 21. Nxg4 Bg7 22. Be5 Bh6+ 23. Nxh6 Rxh6 24. Bh3 Rxa3 25. bxa3 Qxa3+ 26. Qb2 Qa4 27. Bg4 Bb5 28. Rde1 Bd3 29. Rh3 Kd7 30. Rxd3 Rg6 31. Bd1 Rg2 32. Qxg2 Qa1+ 33. Kd2 Qb2+ 34. Ke3 Qxg2 35. Bxb3 Qh3+ 36. Kd2 Qxh4 37. Ree3 Qf2+ 38. Re2 Qf1 39. Kc2 Qa1 40. Rdd2 Qa3 41. Kd3 b6 42. Bc2 c5 43. Bd1 c4+ 44. Ke3 Qxc3+ 45. Kf2 Qh3 46. Ke1 Qg4 47. Rf2 Qe4+ 48. Be2 Qb1+ 49. Rd1 Qb4+ 50. Kf1 c3 51. Rf3 c2 52. Rc1 Qb1 53. Bf4 Qb2 54. Rd3 b5 55. Ke1 b4 56. Kd2 b3 57. Rxb3 Qxb3 58. Rxc2 Qb4+ 59. Ke3 Qb3+ 60. Kd2 Qb4+ 61. Kd1 Qb3 62. Bc1 Qe3 63. Bxe3 e5 64. Kd2 Kd6 65. Bf2 e4 66. Be3 Ke6 67. Ke1 Kd6 68. Kf2 Ke6 69. Rb2 Kf5 70. Rb5 Ke6 71. Rxd5 Kxd5 72. Bg4 Kd6 73. Bf5 Kd5 74. Bg6 Kd6 75. Bxe4 Kd7 76. Bf3 Ke7 77. Ke2 Kd7 78. Kd3 Ke7 79. d5 Kd7 80. Ke4 Ke7 81. Bf4 Kd8 82. Ke5 Ke8 83. Bg4 Kd8 84. Bg5+ Ke8 85. Ke6 Kf8 86. d6 Kg7 87. d7 Kg6 88. d8=Q Kg7 89. Qf6+ Kg8 90. Qg6+ Kf8 1/2-1/2",
    B: "2411",
  },
  {
    W: "2304",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nc6 5. Nxc6 bxc6 6. Bd3 e5 7. O-O Be6 8. c4 Nf6 9. Nc3 Be7 10. Re1 O-O 11. Be3 Rb8 12. b3 a6 13. h3 d5 14. exd5 cxd5 15. cxd5 Nxd5 16. Nxd5 Qxd5 17. Qe2 a5 18. Rad1 Qc6 19. Rc1 Qb7 20. f3 Ba3 21. Rc2 Bb4 22. Rec1 Rbc8 23. Rxc8 Rxc8 24. Qc2 Rxc2 25. Rxc2 f5 26. Kf2 f4 27. Bc5 Bxc5+ 28. Rxc5 Qb6 29. b4 axb4 0-1",
    B: "2298",
  },
  {
    W: "1603",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. c3 e6 4. d4 d5 5. exd5 exd5 6. Bb5 Nf6 7. Ne5 Bd7 8. Nxd7 Qxd7 9. O-O a6 10. Re1+ Be7 11. Ba4 b5 12. Bc2 O-O 13. dxc5 Bxc5 14. Bg5 Ne8 15. Qd3 g6 16. Bh6 Ng7 17. Nd2 Rfe8 18. Nf3 Bf8 19. Ng5 Ne6 20. Nxe6 Rxe6 21. Bxf8 Rxf8 22. Rxe6 Qxe6 23. h3 Re8 24. Kh2 Qe5+ 25. Kg1 Rd8 26. Qf3 d4 27. cxd4 Qxd4 28. Qxc6 Qd2 29. Bb3 Qd1+ 30. Rxd1 Rxd1+ 31. Kh2 1-0",
    B: "1593",
  },
  {
    W: "1996",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bb5 d6 4. c3 Bd7 5. d4 cxd4 6. cxd4 a6 7. Bxc6 bxc6 8. Nc3 e6 9. O-O d5 10. e5 g6 11. Bg5 Be7 12. Bc1 c5 13. dxc5 Bxc5 14. Bg5 Be7 15. Be3 Bf8 16. Qd2 Ne7 17. Ng5 Nf5 18. Rac1 Be7 19. f4 h6 20. Nf3 Nxe3 21. Qxe3 O-O 22. f5 g5 23. f6 Bxf6 24. exf6 Qxf6 25. Ne5 Qe7 26. h4 Bb5 27. Nxb5 axb5 28. a3 f6 29. Ng6 Qg7 30. Qxe6+ Kh7 31. Nxf8+ Rxf8 32. h5 f5 33. Rxf5 Rxf5 34. Qxf5+ Kh8 35. Rc8+ Qf8 36. Qxf8+ Kh7 37. Qg8# 1-0",
    B: "2032",
  },
  {
    W: "1832",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 Nf6 3. g3 c6 4. Bg2 Bg4 5. O-O Bxf3 6. Bxf3 Nbd7 7. Bg2 h5 8. Re1 h4 9. b3 hxg3 10. fxg3 Qc7 11. Bf4 Qb6 12. e3 Nh5 13. Nc3 Nxf4 14. exf4 e6 15. Na4 Qc7 16. Nc3 Bd6 17. a4 Nf6 18. Qd2 Ke7 19. Rac1 a6 20. Na2 Rh7 21. c4 dxc4 22. bxc4 Qd7 23. d5 cxd5 24. cxd5 e5 25. fxe5 Rah8 26. exd6+ Kxd6 27. Qb4# 1-0",
    B: "1827",
  },
  {
    W: "2505",
    T: "60+0",
    P: "1. Nf3 g6 2. b3 Nf6 3. Bb2 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O Nbd7 7. d4 e5 8. dxe5 Ng4 9. c4 Ngxe5 10. Nxe5 Nxe5 11. Nc3 c6 12. Qd2 Qe7 13. Rad1 Rd8 14. e3 Bg4 15. Rc1 h5 16. h3 Be6 17. Kh2 Nd7 18. Rcd1 Nf6 19. Ne4 Nxe4 20. Bxe4 Bxb2 21. Qxb2 Bxh3 22. Kxh3 Qxe4 23. Qf6 Qe6+ 24. Qxe6 fxe6 25. Rd3 d5 26. cxd5 exd5 27. Rc1 Re8 28. Rd4 Kf7 29. Rf4+ Kg7 30. b4 a6 31. a4 Rac8 32. Rd1 Rc7 33. b5 axb5 34. axb5 Re4 35. Rxe4 dxe4 36. b6 Re7 37. Ra1 Kf6 38. g4 hxg4+ 39. Kxg4 Ke5 40. Kg5 Kd5 41. Kxg6 c5 42. Kf5 Re5+ 43. Kf4 Re6 44. Rb1 c4 45. Rd1+ Kc6 46. Rd4 Kc5 47. Rxe4 Rxb6 48. Re8 c3 49. Rc8+ Rc6 50. Ra8 c2 51. Ra1 Kb4 52. Rc1 Kb3 53. e4 Kb2 54. Rxc2+ Rxc2 55. e5 b5 56. f3 b4 57. e6 b3 58. Kf5 Re2 59. Kf6 Kc3 60. f4 b2 61. f5 b1=Q 62. Kf7 Qxf5+ 63. Ke7 Qxe6+ 64. Kd8 Qe7+ 65. Kc8 Rd2 66. Kb8 Rd8# 0-1",
    B: "2491",
  },
  {
    W: "2342",
    T: "60+0",
    P: "1. Nf3 Nf6 2. g3 g6 3. Bg2 Bg7 4. O-O O-O 5. d3 d5 6. Nc3 c5 7. e4 d4 8. Ne2 Nc6 9. h3 e5 10. Nd2 Ne8 11. a4 Nd6 12. f4 f5 13. exf5 Nxf5 14. Nc4 Be6 15. fxe5 Bxc4 16. dxc4 Bxe5 17. Bf4 Qd6 18. Qd2 Rae8 19. Bxe5 Nxe5 20. Bd5+ Kg7 21. g4 Ne3 22. Rxf8 Rxf8 23. Bxb7 Rb8 24. Bg2 Rxb2 25. Rc1 N5xc4 26. Qd3 Ne5 27. Qe4 Qf6 28. Nf4 N5c4 29. Nd5 Qf7 30. Nxe3 Nxe3 31. Bf3 Rb8 32. Kh1 Re8 33. Qc6 Re6 34. Qb7 Re7 35. Qd5 Qf4 36. Rb1 Qg3 37. Bg2 Rf7 38. Qc6 Rf2 39. Rg1 Nxg2 40. Qd6 Qxd6 41. Rxg2 Qf4 42. Rxf2 Qxf2 43. g5 Qf1+ 44. Kh2 Qf2+ 45. Kh1 Qf3+ 46. Kh2 Qf4+ 47. Kh1 Qf5 48. h4 Qf4 49. Kg2 Qg4+ 0-1",
    B: "2356",
  },
  {
    W: "1227",
    T: "60+0",
    P: "1. e4 c5 2. d3 Nc6 3. Be3 e6 4. Qe2 d5 5. g3 d4 6. Bd2 Nf6 7. Bg2 Be7 8. e5 O-O 9. exf6 Bxf6 10. c3 b6 11. cxd4 cxd4 12. b3 Bb7 13. Bc1 Rb8 14. Nd2 Qd5 15. Rb1 Qxg2 16. Ngf3 Nb4 17. Rg1 Qxf3 18. Qxf3 Bxf3 19. Nxf3 Nxd3+ 20. Kd2 Nxc1 21. Rbxc1 Rbc8 22. Rxc8 Rxc8 23. Nxd4 Bxd4 24. Rd1 Rc3 25. Ke2 f5 26. Rxd4 e5 27. Rd8+ Kf7 28. Rd7+ Kf6 29. Rxa7 Rc2+ 30. Kd1 Rxf2 31. a4 Rxh2 32. Rb7 Rg2 33. Rxb6+ Kg5 34. a5 Rxg3 35. a6 Rxb3 36. a7 Ra3 37. a8=Q Rxa8 38. Rb5 e4 39. Re5 Rd8+ 40. Ke2 h5 41. Ke3 h4 42. Re7 Rg8 43. Re6 h3 44. Rd6 h2 45. Rd1 Rh8 46. Rh1 Rh3+ 47. Kf2 Rf3+ 48. Kg2 Rf1 49. Kxh2 1-0",
    B: "1067",
  },
  {
    W: "1418",
    T: "60+0",
    P: "1. e4 e5 2. d4 Nc6 3. d5 Nce7 4. Bd3 Nf6 5. Ne2 Ng6 6. O-O Bc5 7. Nbc3 O-O 8. Na4 Bd6 9. f4 exf4 10. Bxf4 Bxf4 11. Nxf4 Nxf4 12. Rxf4 d6 13. Nc3 Qd7 14. Qf3 Qe7 15. Rf1 c6 16. b4 cxd5 17. Nxd5 Nxd5 18. exd5 Qg5 19. Rxf7 Rxf7 20. Qxf7+ Kh8 21. Qf8# 1-0",
    B: "1461",
  },
  {
    W: "1581",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 Bc5 5. c3 O-O 6. O-O a6 7. Ba4 b5 8. Bc2 Na5 9. b3 Bb6 10. d4 exd4 11. cxd4 Re8 12. Qd3 Bb7 13. e5 Be4 14. Qd2 Bxc2 15. exf6 Bxb1 16. Rxb1 Qxf6 17. Bb2 d5 18. Ne5 Rad8 19. Bc3 Nb7 20. Nc6 Qxc6 21. Rbc1 Qf6 22. Ba1 Nd6 23. Qd3 Ne4 24. f3 Nd6 25. a3 Nf5 26. g4 Bxd4+ 27. Bxd4 Nxd4 28. Kg2 h5 29. Rxc7 hxg4 30. fxg4 Qh4 31. Rfxf7 1-0",
    B: "1500",
  },
  {
    W: "1717",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d4 Nf6 5. Bc4 e6 6. Nf3 Nbd7 7. Qe2 Nb6 8. Bb3 Be7 9. Bg5 Bd7 10. O-O-O O-O 11. Ne5 Ne8 12. Bd2 f6 13. Nf3 Bc6 14. g4 Nd6 15. Rhg1 Qe8 16. Bxe6+ Kh8 17. h4 Qg6 18. h5 Qe8 19. g5 Bxf3 20. Qxf3 fxg5 21. Qh3 g4 22. Qxg4 Rxf2 23. Qxg7# 1-0",
    B: "1640",
  },
  {
    W: "2092",
    T: "600+0",
    P: "1. e4 e6 2. d4 d5 3. Nc3 c5 4. exd5 cxd4 5. Qxd4 exd5 6. Bb5+ Nd7 7. Qxd5 Nf6 8. Qd1 Bb4 9. Ne2 Bxc3+ 10. Nxc3 Qe7+ 11. Be3 O-O 12. O-O Ne5 13. Nd5 Nxd5 14. Qxd5 Rd8 15. Qe4 a6 16. Be2 f5 17. Qf4 Ng6 18. Qg5 Qxg5 19. Bxg5 Re8 20. Bc4+ Be6 21. Bxe6+ Rxe6 22. Rfe1 Rae8 23. Bd2 Ne5 24. Bc3 Nc4 25. Rxe6 Rxe6 26. Rd1 Rd6 27. Rxd6 Nxd6 28. f3 Nb5 29. Be5 g6 30. a4 Na7 31. Kf2 Kf7 32. Ke3 Ke6 33. Bc3 Nc6 34. b3 Ne7 35. Kd4 Nd5 36. Bd2 b6 37. c4 Ne7 38. Bf4 Nc6+ 39. Kc3 Kd7 40. b4 Na7 41. b5 axb5 42. axb5 Nc8 43. Kd4 Ke6 44. h4 Ne7 45. Bc7 Nc8 46. Bxb6 Nxb6 47. c5 Nc8 48. b6 Nxb6 49. cxb6 Kd6 50. h5 g5 51. h6 g4 52. fxg4 fxg4 53. g3 Kc6 54. Ke5 Kxb6 55. Kf6 Kc5 56. Kg7 Kd6 57. Kxh7 Ke7 58. Kg6 Kf8 59. h7 Ke7 60. h8=Q 1-0",
    B: "2047",
  },
  {
    W: "1332",
    T: "600+0",
    P: "1. e4 e6 2. Nf3 b6 3. Bc4 Bb7 4. d3 g6 5. O-O Bg7 6. Nc3 d5 7. exd5 exd5 8. Bb3 d4 9. Re1+ Ne7 10. Ne4 O-O 11. Bf4 c5 12. c4 Na6 13. a3 Nf5 14. g3 Re8 15. Bc2 Nh6 16. Qd2 Ng4 17. Bd1 f5 18. Neg5 Bf6 19. b4 Bxg5 20. Bxg5 Qd7 21. bxc5 Nxc5 22. a4 a5 23. Be2 Nb3 24. Qb2 Nxa1 25. Qxa1 Bc6 26. c5 Bxa4 27. Qa2+ Kf8 28. h3 Ne5 29. Nxe5 Rxe5 30. cxb6 Qb7 31. Qxa4 Qxb6 32. Bh6+ Kf7 33. Qd7+ Re7 34. Qd5+ Re6 35. Qxa8 1-0",
    B: "1277",
  },
  {
    W: "1124",
    T: "600+0",
    P: "1. e4 e5 2. Nc3 Nc6 3. Nb5 d5 4. exd5 Nd4 5. c3 Nxb5 6. Qe2 Qxd5 7. a4 Nd6 8. Qf3 Nf6 9. Bb5+ c6 10. b3 cxb5 11. axb5 Qxb3 12. Ne2 Qxb5 13. Ba3 Bg4 14. Qe3 Nc4 15. Bxf8 Nxe3 16. dxe3 Rxf8 17. O-O Bxe2 18. Rfb1 Qd3 19. Rxb7 Qxc3 20. Rd1 Bxd1 21. g4 Qc6 22. Rb4 Rd8 23. Re4 Nxe4 24. f3 Rd2 25. fxe4 Qc1 26. g5 Bh5# 0-1",
    B: "1200",
  },
  {
    W: "1497",
    T: "600+0",
    P: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nf3 cxb2 5. Bxb2 Nc6 6. Bc4 e6 7. Qb3 Bb4+ 8. Nc3 Bxc3+ 9. Bxc3 a6 10. Bxg7 b5 11. Bd3 Qa5+ 12. Nd2 Nd4 13. Bxd4 f6 14. Bc3 Qc7 15. O-O Ke7 16. Rfc1 Qb6 17. a4 Bb7 18. axb5 axb5 19. Ba5 Rxa5 20. Rxa5 Qxa5 21. Bxb5 Nh6 22. Nc4 Qa8 23. Nb6 Qd8 24. Rd1 Qxb6 25. Rxd7+ Kf8 26. Qb4+ Kg8 27. Qe7 Qxb5 28. Qe8# 1-0",
    B: "1510",
  },
  {
    W: "1500",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. Nc3 Bc5 5. O-O O-O 6. d3 Re8 7. Bg5 Be7 8. a4 d6 9. Ra3 Nxe4 10. Bxe7 Nxc3 11. Rxc3 Qxe7 12. Qe2 Bd7 13. Qe4 Qf6 14. Bxc6 Bxc6 15. Qh4 Qg6 16. d4 Bxf3 17. Rxf3 exd4 18. Rh3 h6 19. f3 Re5 20. g4 Qxc2 21. Rf2 Qd1+ 22. Kg2 Rae8 23. g5 Rxg5+ 24. Qxg5 hxg5 25. f4 Qg4+ 26. Rg3 Qh4 27. Rff3 Re2+ 28. Kf1 Rxh2 29. Rh3 Rxh3 0-1",
    B: "1490",
  },
  {
    W: "1616",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Qf6 4. O-O a6 5. Ba4 b5 6. Bb3 Bb7 7. Bd5 Nge7 8. c4 bxc4 9. Bxc4 Nd4 10. Nxd4 exd4 11. Re1 d6 12. d3 Nc6 13. Nd2 Ne5 14. b3 Bc8 15. Bd5 Rb8 16. Nc4 Bg4 17. f3 Bh5 18. Nxe5 dxe5 19. Bc6+ Qxc6 20. f4 Bxd1 0-1",
    B: "1647",
  },
  {
    W: "1115",
    T: "600+0",
    P: "1. d4 d5 2. e3 e6 3. Nc3 Bb4 4. Bd2 Nc6 5. Nf3 b5 6. e4 a6 7. exd5 exd5 8. Bxb5 axb5 9. O-O Bg4 10. Re1+ Nce7 11. Nxb5 Bxd2 12. Qxd2 c6 13. Nc3 Bxf3 14. gxf3 Qb6 15. Qe2 Qxb2 16. Qxe7+ Nxe7 17. Ne2 Qxc2 18. Nf4 O-O 19. Re2 Qf5 20. Rxe7 Qxf4 21. Rae1 Qg5+ 22. Kf1 Qxe7 23. Rxe7 Rae8 24. Rxe8 Rxe8 25. a3 f5 26. a4 Ra8 27. f4 Rxa4 28. f3 Rxd4 29. Kf2 Rxf4 30. Kg3 g5 31. h4 h6 32. hxg5 hxg5 33. Kh3 d4 34. Kg3 d3 35. Kg2 d2 36. Kf2 d1=Q 0-1",
    B: "1104",
  },
  {
    W: "1526",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 h6 4. d3 c6 5. Be3 Qf6 6. O-O Be7 7. Nbd2 Qg6 8. d4 Bh3 9. g3 Bxf1 10. Qxf1 Nd7 11. dxe5 Nxe5 12. Nxe5 dxe5 13. Nf3 Qxe4 14. Qe2 Nf6 15. Bd3 Ng4 16. Bxe4 1-0",
    B: "1582",
  },
  {
    W: "1999",
    T: "600+0",
    P: "1. d4 c6 2. Nf3 d5 3. Bf4 Bf5 4. e3 e6 5. Nbd2 Nf6 6. c4 Be7 7. Qb3 Qb6 8. c5 Qxb3 9. axb3 Nbd7 10. b4 O-O 11. h3 a6 12. Nb3 h6 13. Na5 Ra7 14. Ne5 Bd8 15. Naxc6 bxc6 16. Nxc6 Ra8 17. Rxa6 Rxa6 18. Bxa6 g5 19. Bh2 Ne8 20. Nxd8 1-0",
    B: "1897",
  },
  {
    W: "1392",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 d6 5. Ng5 Nh6 6. Nc3 Nd4 7. Nd5 Ne6 8. Nxe6 Bxe6 9. Bxh6 gxh6 10. Qh5 Bxd5 11. Bxd5 Qf6 12. O-O Rg8 13. Bxb7 Rb8 14. Bc6+ Ke7 15. a3 Qg7 16. g3 Rbf8 17. Qh4+ f6 18. Bd5 Rh8 19. b4 Bd4 20. Rab1 Kd7 21. a4 c6 22. Bc4 d5 23. exd5 cxd5 24. Bxd5 Kd6 25. Bb3 h5 26. Qxh5 h6 27. a5 Qg5 28. Qxg5 hxg5 29. Rbc1 g4 30. c3 Rh6 31. cxd4 Rfh8 32. Rfd1 Rxh2 33. Kf1 Rh1+ 34. Kg2 R8h2# 0-1",
    B: "1465",
  },
  {
    W: "1338",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 d6 3. Nc3 a6 4. d4 Nc6 5. d5 Nd4 6. Nxd4 exd4 7. Qxd4 h6 8. Bd3 Nf6 9. e5 dxe5 10. Qxe5+ Qe7 11. Qxe7+ Bxe7 12. O-O O-O 13. Re1 Bb4 14. Bd2 Bxc3 15. Bxc3 Bg4 16. Re3 Nxd5 17. Rg3 h5 18. Bd4 c6 19. h3 h4 20. Rxg4 b5 21. Rxg7+ Kh8 22. Rxf7+ Kg8 23. Rg7+ Kh8 24. Rh7+ Kg8 25. Rxh4 Rac8 26. c4 c5 27. Rh8+ Kf7 28. Rh7+ Ke6 29. Rh6+ Kd7 30. Bf5+ Kc7 31. Be5+ Kb7 32. Bxc8+ Kxc8 33. cxd5 Rf5 34. Re1 c4 35. d6 Kd7 36. Rh7+ Ke6 37. Re7+ Kd5 38. d7 Rf8 39. Re8 Rxe8 40. dxe8=Q Kc5 41. Qc8+ Kb6 42. Qc7# 1-0",
    B: "1343",
  },
  {
    W: "1888",
    T: "600+0",
    P: "1. e4 d6 2. d4 g6 3. f4 Bg7 4. Nf3 Bg4 5. Be3 e5 6. fxe5 dxe5 7. d5 Nf6 8. Bd3 Nbd7 9. Nc3 b6 10. b4 a6 11. O-O O-O 12. h3 Bxf3 13. Qxf3 Nh5 14. Ne2 Nf4 15. Rad1 Nxd3 16. Rxd3 f5 17. Kh2 fxe4 18. Qxe4 Rxf1 19. d6 Nf6 20. dxc7 Nxe4 21. cxd8=Q+ Rxd8 22. Rxd8+ Rf8 23. Rxf8+ Bxf8 24. Bxb6 Bxb4 25. Bc7 Nc3 26. a3 Nd5 27. axb4 Nxc7 28. c4 Kf7 29. Nc3 Ke6 30. Ne4 Kf5 31. Nd6+ Kf4 32. c5 e4 33. Kg1 Ke3 34. Kf1 Kd3 35. Ke1 e3 36. g4 Ne6 37. c6 e2 38. b5 axb5 39. Nxb5 Nf4 40. c7 Ng2+ 41. Kf2 e1=Q+ 42. Kxg2 Qe2+ 43. Kg3 Qe5+ 44. Kh4 Qe8 45. Kg5 Qxb5+ 46. Kh6 Qc6 47. Kxh7 Qxc7+ 48. Kxg6 Qf4 49. Kh5 Ke4 50. h4 Kf3 51. g5 Qg4+ 52. Kh6 Qxh4+ 53. Kg6 Kf4 54. Kf6 Qxg5+ 55. Ke6 Qe5+ 56. Kd7 Qf6 57. Kc7 Ke5 58. Kd7 Qd6+ 59. Kc8 Qe7 60. Kb8 Kd6 0-1",
    B: "1866",
  },
  {
    W: "1342",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bc5 4. Nxe5 Nxe5 5. d4 Bxd4 6. Qxd4 d6 7. f4 Nc6 8. Qxg7 Qf6 9. Qxf6 Nxf6 10. Bb5 Bd7 11. O-O O-O-O 12. a4 a6 13. Be2 Nd4 14. Bd3 Rhg8 15. b4 Rde8 16. a5 Bb5 17. Nxb5 Nxb5 18. c4 Nd4 19. Bb2 c5 20. bxc5 dxc5 21. e5 Ng4 22. Bxd4 cxd4 23. Bf5+ Kc7 24. Rfd1 Ne3 25. Bxh7 Rxg2+ 26. Kh1 Rh8 27. Be4 Rhxh2# 0-1",
    B: "1298",
  },
  {
    W: "1861",
    T: "120+1",
    P: "1. e4 c5 2. Be2 Nc6 3. c3 Nf6 4. d3 d5 5. h3 dxe4 6. dxe4 Qxd1+ 7. Bxd1 Nxe4 8. Bf3 Bf5 9. g4 Bg6 10. Bf4 O-O-O 11. Na3 e5 12. Be3 f6 13. Ne2 a6 14. O-O b5 15. Rad1 Be7 16. Rxd8+ Rxd8 17. Kg2 Kc7 18. Ng3 Nxg3 19. Kxg3 c4 20. Nb1 Bd3 21. Re1 e4 22. Be2 Bd6+ 23. Bf4 Ne5 24. Nd2 g5 25. Bxe5 Bxe5+ 26. Kg2 Bc2 27. Nb1 Bf4 28. Na3 Rd2 29. Nxc2 Rxc2 30. Rf1 Rxb2 31. f3 Rxe2+ 32. Rf2 Rxf2+ 33. Kxf2 exf3 34. Kxf3 Kd6 35. Ke4 Ke6 36. a3 Be5 0-1",
    B: "1883",
  },
  {
    W: "1713",
    T: "480+0",
    P: "1. d4 Nf6 2. Bg5 h6 3. Bxf6 exf6 4. e4 Bb4+ 5. c3 Ba5 6. Bc4 d6 7. Nf3 Bg4 8. O-O Qd7 9. Nbd2 h5 10. b4 Bb6 11. a4 a6 12. d5 Rh6 13. Qc2 Rg6 14. b5 a5 15. Nh4 Rh6 16. Kh1 g5 17. f3 gxh4 18. fxg4 Qxg4 19. Rf3 Rg6 20. g3 Nd7 21. Raf1 Ne5 22. Rf4 Qh3 23. Qd1 Ng4 24. Rxg4 Rxg4 25. Rf4 hxg3 0-1",
    B: "1624",
  },
  {
    W: "1958",
    T: "60+0",
    P: "1. d4 d5 2. c4 dxc4 3. Nc3 b6 4. e4 Ba6 5. Bxc4 Bxc4 6. Qa4+ Qd7 7. Qxc4 Nf6 8. f3 e6 9. Bg5 Be7 10. Nge2 O-O 11. O-O h6 12. Bh4 g5 13. Bg3 c6 14. a3 b5 15. Qd3 Na6 16. b4 Nc7 17. Rac1 Rad8 18. Rc2 a6 19. Rfc1 Rc8 20. Bf2 Nce8 21. Na2 Ng7 22. Rc3 Bd8 23. Qc2 a5 24. Rxc6 Rxc6 25. Qxc6 Qxc6 26. Rxc6 axb4 27. Nxb4 Be7 28. d5 Bxb4 29. axb4 exd5 30. exd5 Nxd5 31. Bc5 Rd8 32. Rxh6 Nf5 33. Rh5 Kg7 34. Rxg5+ Kf6 35. f4 Nc7 36. g4 Ne6 37. Rxf5+ Kg7 38. h4 Nxc5 39. Rxc5 Rd1+ 40. Kf2 Rh1 41. h5 Rh2+ 42. Kf3 Rh3+ 43. Ng3 Rh1 44. g5 Rf1+ 45. Kg4 f6 46. h6+ Kg6 47. Rxb5 fxg5 48. fxg5 Rd1 49. Rb6+ Kh7 50. Kh5 Rd4 51. g6+ Kg8 52. Rb8+ 1-0",
    B: "1974",
  },
  {
    W: "1651",
    T: "60+0",
    P: "1. c3 d5 2. d3 e5 3. Nd2 Nf6 4. h3 Bd6 5. g4 O-O 6. Bg2 h6 7. Nf1 c5 8. g5 Nfd7 9. gxh6 gxh6 10. Bxh6 Qf6 11. Qd2 Qg6 12. Bf3 f5 13. Bxf8 Nxf8 14. Ne3 f4 15. Bxd5+ Kg7 16. Nc4 Be7 17. Nf3 Nc6 18. Rg1 Qxg1+ 19. Nxg1 Be6 20. Bxe6 Nxe6 21. Nf3 Bf6 22. O-O-O Kf7 23. Rg1 b6 24. h4 Rg8 25. Ng5+ Bxg5 26. hxg5 Rxg5 27. Qc2 Rxg1+ 28. Kd2 Rg6 29. Qa4 f3 30. exf3 Nf4 31. Qxc6 Rxc6 32. Nxe5+ Ke6 33. Nxc6 Ng2 34. Nxa7 Nf4 35. Nb5 Kd5 36. Ke3 Ne6 37. c4+ Kc6 38. Na3 Kd7 39. b4 Ke8 40. bxc5 Ng5 41. Kd4 Nf7 42. cxb6 Nd8 43. b7 1-0",
    B: "1484",
  },
  {
    W: "2246",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Nc3 e6 4. a3 Nf6 5. Bc4 d5 6. Ba2 d4 7. Ne2 Be7 8. d3 O-O 9. h3 b5 10. g4 b4 11. Bd2 bxa3 12. Ng3 axb2 13. Rb1 Rb8 14. Qe2 Nb4 15. Bb3 a5 16. Nf5 a4 17. Bxa4 Qa5 18. Nxe7+ Kh8 19. Bb3 Qa1 20. O-O Qa7 21. Ng5 Qxe7 22. f4 Bb7 23. h4 h6 24. Kf2 hxg5 25. hxg5 Nh7 26. Rh1 g6 27. Kg3 Kg7 28. Rxh7+ Kxh7 29. Qh2+ Kg8 30. Rh1 f5 31. Bxe6+ Qxe6 32. Qh7# 1-0",
    B: "1946",
  },
  {
    W: "1231",
    T: "60+0",
    P: "1. e4 e5 2. Nc3 Nc6 3. Nf3 Nf6 4. Bb5 a6 5. Bxc6 dxc6 6. d3 Bd6 7. Bg5 Bg4 8. O-O O-O 9. h3 Bh5 10. g4 Bg6 11. h4 h6 12. h5 hxg5 13. hxg6 fxg6 14. Nxg5 Bb4 15. Ne6 Qe7 16. Nxf8 Qxf8 17. a3 Ba5 18. b4 Bb6 19. Qd2 Qd6 20. Kg2 Rf8 21. Rh1 Qd4 22. Qe3 Qxe3 23. fxe3 Bxe3 24. Rh3 Bg5 25. Rah1 Bh6 26. g5 1-0",
    B: "1243",
  },
  {
    W: "1759",
    T: "60+0",
    P: "1. e3 d5 2. d4 Bg4 3. Nf3 Bxf3 4. Qxf3 e6 5. c4 dxc4 6. Bxc4 Nc6 7. Nc3 Nf6 8. Bb3 Bb4 9. O-O Bxc3 10. bxc3 O-O 11. e4 Nd7 12. Bf4 Re8 13. Rfd1 e5 14. Bg3 exd4 15. cxd4 Nf6 16. e5 Nd7 17. d5 Nb4 18. e6 fxe6 19. dxe6 Nf8 20. e7+ Kh8 1-0",
    B: "1680",
  },
  {
    W: "2620",
    T: "60+0",
    P: "1. e4 d5 2. Nc3 dxe4 3. Nxe4 Qd5 4. Nc3 Qa5 5. Nf3 Nf6 6. Be2 Nc6 7. O-O Bg4 8. h3 Bh5 9. d3 O-O-O 10. Be3 e5 11. Nd2 Bxe2 12. Qxe2 Bb4 13. Nc4 Qa6 14. a3 Bxc3 15. bxc3 Rhe8 16. Bg5 e4 17. Bxf6 gxf6 18. dxe4 Ne5 19. Rab1 Qxc4 20. Qh5 Qxe4 21. Rfe1 Qxc2 22. f4 Nd3 23. Red1 Qf2+ 24. Kh1 Nxf4 25. Qg4+ Kb8 26. Rf1 Qxg2+ 27. Qxg2 Nxg2 28. Kxg2 Re3 29. Rxf6 Rxc3 30. Rxf7 Rxa3 31. Rxh7 a5 32. Rf1 b6 33. h4 Rd2+ 34. Rf2 Rxf2+ 35. Kxf2 Rh3 36. Kg2 Rc3 37. h5 Rc5 38. h6 a4 39. Rg7 Rh5 40. h7 Kb7 41. Kf2 a3 42. Rg3 Rxh7 43. Rxa3 Rd7 44. Kf3 Rd6 45. Ke4 Kc6 46. Ke5 Kb5 47. Rc3 Rc6 48. Rb3+ Kc4 49. Rb1 b5 50. Rc1+ Kb3 51. Rxc6 b4 52. Rxc7 Ka3 53. Kd4 b3 54. Kc4 b2 55. Ra7# 1-0",
    B: "2524",
  },
  {
    W: "2015",
    T: "60+0",
    P: "1. e3 e6 2. Nf3 d5 3. Be2 Nf6 4. b3 Be7 5. Bb2 c5 6. d3 Nc6 7. Nbd2 b6 8. c4 Qc7 9. cxd5 exd5 10. O-O Be6 11. d4 O-O 12. dxc5 Bxc5 13. Nd4 Bxd4 14. Bxd4 Nxd4 15. exd4 Qf4 16. Nf3 Bg4 17. h3 Bxf3 18. Bxf3 Rad8 19. Rc1 Rd7 20. Rc2 Rfd8 21. Qc1 Qh4 22. Rc7 h6 23. Rxd7 Rxd7 24. Qc8+ Kh7 25. Be2 Qxd4 26. Rd1 Qe5 27. Bd3+ g6 28. Bb5 Re7 29. Qc6 Kg7 30. f3 Nh5 31. Qxd5 Qxd5 32. Rxd5 Re1+ 33. Kf2 Ra1 34. Rd2 Nf4 35. Bc4 Nh5 36. a4 g5 37. Rd7 Kf6 38. Rxa7 Ng7 39. Ke3 Nf5+ 40. Kf2 Ra2+ 41. Ke1 Ne3 42. Rb7 Nxc4 43. bxc4 Rxa4 44. Rxb6+ Kg7 45. Rb3 Rxc4 46. Re3 Rc1+ 47. Kf2 Rc2+ 48. Kg3 Rxg2+ 49. Kxg2 f5 50. Rd3 Kf6 51. Rd5 1-0",
    B: "2017",
  },
  {
    W: "1087",
    T: "60+0",
    P: "1. e4 d5 2. e5 e6 3. d4 f6 4. f4 fxe5 5. fxe5 Be7 6. Nf3 Nh6 7. h3 O-O 8. Bd3 Nf5 9. Bxf5 Rxf5 10. O-O Bd7 11. Nh4 Rf8 12. Rxf8+ Qxf8 13. Qh5 Bxh4 14. Qxh4 Nc6 15. Qg4 g6 16. Bh6 Qf5 17. Qh4 Kh8 18. Nd2 Qh5 19. Qxh5 gxh5 20. Nf3 Kg8 21. Ng5 Ne7 22. Kf1 Nf5 23. Ke2 Nxh6 24. Ne4 dxe4 25. Rf1 Rf8 26. g4 Rxf1 27. Kxf1 hxg4 28. hxg4 Nxg4 29. Kg2 e3 30. Kf3 e2 31. Kxe2 1-0",
    B: "1103",
  },
  {
    W: "1966",
    T: "60+0",
    P: "1. e4 c6 2. Nc3 d5 3. Nf3 dxe4 4. Nxe4 Nd7 5. Ng3 Ngf6 6. Be2 e6 7. O-O Be7 8. d3 h6 9. h3 O-O 10. Nh2 Nd5 11. c4 N7f6 12. cxd5 Nxd5 13. Bd2 Bd6 14. f4 Qb6+ 15. Kh1 Qxb2 16. Rb1 Qf6 17. Ng4 Qe7 18. Bf3 Bc5 19. Bxd5 cxd5 20. f5 Bd7 21. fxe6 fxe6 22. Nxh6+ gxh6 23. Bxh6 Rxf1+ 24. Nxf1 Re8 25. Qg4+ Kh7 26. Bg5 Qg7 27. Qh5+ Kg8 28. Ng3 Bd6 29. Rf1 Bxg3 30. Qg4 Bd6 31. Qh5 Rf8 32. Bh6 Rxf1# 0-1",
    B: "1971",
  },
  {
    W: "2087",
    T: "60+0",
    P: "1. c4 b5 2. Nc3 a6 3. cxb5 Nf6 4. bxa6 Bxa6 5. d4 e6 6. a3 Be7 7. Bf4 O-O 8. Rc1 d5 9. e3 Bxf1 10. Kxf1 Nbd7 11. Nge2 Rc8 12. h3 c5 13. Kg1 cxd4 14. exd4 h6 15. Kh2 Nh5 16. Bg3 Nxg3 17. Nxg3 Nf6 18. Re1 Bd6 19. Kg1 Qc7 20. Qa4 Bxg3 21. fxg3 Qxg3 22. Qd1 Rfd8 23. Qf3 Qg6 24. Kh2 Rxc3 25. Rxc3 Ne4 26. Rce3 Rc8 27. Rxe4 dxe4 28. Qxe4 Qf6 29. Qd3 Rd8 30. Re4 Rd5 31. b4 Qe7 32. Qe3 Qd6+ 33. Re5 Rxd4 34. h4 f6 35. Kh3 Qxe5 36. Qb3 Qf5+ 37. g4 Rd3+ 38. Qxd3 Qxd3+ 0-1",
    B: "2192",
  },
  {
    W: "1372",
    T: "60+0",
    P: "1. e4 c5 2. f4 Nc6 3. c4 e6 4. d3 Be7 5. Nf3 Nh6 6. Be2 g5 7. O-O gxf4 8. Bxf4 Qc7 9. Bxh6 d6 10. Be3 Bd7 11. d4 O-O-O 12. dxc5 dxc5 13. Qc2 Rhg8 14. Rf2 Bd6 15. Na3 Rg6 16. Nb5 Qa5 17. Nxd6+ Kb8 18. Nxf7 1-0",
    B: "1411",
  },
  {
    W: "1894",
    T: "60+0",
    P: "1. e4 c6 2. Nf3 d5 3. e5 Bf5 4. d4 e6 5. Bd3 Bg6 6. O-O Nd7 7. Re1 c5 8. c3 c4 9. Bc2 b5 10. a3 a6 11. b3 Be7 12. bxc4 bxc4 13. Nbd2 Nh6 14. Nf1 Nf5 15. Ng3 Nxg3 16. hxg3 Bxc2 17. Qxc2 h5 18. a4 h4 19. gxh4 Bxh4 20. Ba3 Be7 21. Qa2 g5 22. Bxe7 Qxe7 23. Rab1 g4 24. Nd2 Qh4 25. Qa3 0-1",
    B: "1930",
  },
  {
    W: "1917",
    T: "240+0",
    P: "1. d4 d5 2. f4 c5 3. Nf3 Nc6 4. dxc5 g6 5. c3 Bg7 6. e3 Bg4 7. Bb5 e6 8. Bxc6+ bxc6 9. O-O Ne7 10. Qa4 O-O 11. Nd4 Qc7 12. h3 Bf5 13. Nd2 Bd3 14. Rf2 a5 15. Qd1 Rab8 16. b3 Nf5 17. N2f3 Be4 18. g4 Ne7 19. Ba3 h6 20. b4 e5 21. fxe5 Bxe5 22. Nd2 Bg3 23. Nxe4 dxe4 24. Rg2 Nd5 25. Qd2 Bh4 26. Rf1 Bg5 27. Nc2 Rfd8 28. Qe1 Qe5 29. Qg3 Qxg3 30. Rxg3 Nxc3 31. Nd4 axb4 32. Bb2 Bxe3+ 33. Rxe3 Rxd4 34. Bxc3 bxc3 35. Rc1 Rb2 36. Rexc3 Rdd2 37. Rg3 Rxa2 38. Re1 Re2 39. Rxe2 Rxe2 40. Kf1 Rc2 41. Re3 f5 42. gxf5 gxf5 43. Rg3+ Kf7 44. Rg2 Rxg2 45. Kxg2 Ke6 46. Kf2 Ke5 47. Ke3 f4+ 48. Ke2 Kd4 49. Kd2 Kxc5 50. Ke2 Kd4 0-1",
    B: "2227",
  },
  {
    W: "1890",
    T: "240+0",
    P: "1. c4 e5 2. d3 d6 3. Nc3 f5 4. Nf3 Nf6 5. h3 Be7 6. e4 fxe4 7. dxe4 O-O 8. Be2 c6 9. O-O Kh8 10. Nh2 Be6 11. Bf3 Nbd7 12. Ne2 Nc5 13. Ng3 a5 14. a4 Qe8 15. b3 Qg6 16. Be3 Rfd8 17. Rb1 h5 18. Qc2 h4 19. Nf5 Bxf5 20. exf5 Qf7 21. Ng4 Nxg4 22. Bxg4 Bf6 23. Rbd1 Nd7 24. Rxd6 Nf8 25. Rfd1 Rxd6 26. Rxd6 Qe7 27. c5 b6 28. Qc4 bxc5 29. Bxc5 Nd7 30. Ba3 c5 31. Re6 Qf8 32. Qd5 Rd8 33. Qc6 Nb8 34. Qxc5 Qf7 35. Qxa5 Rd1+ 36. Bxd1 1-0",
    B: "2129",
  },
  {
    W: "1116",
    T: "60+0",
    P: "1. e4 Nc6 2. f4 Nf6 3. Nc3 e5 4. Nf3 Bb4 5. Nd5 Nxd5 6. exd5 Nd4 7. c3 Nxf3+ 8. Qxf3 O-O 9. cxb4 d6 10. fxe5 dxe5 11. Bd3 Qf6 12. O-O Qxf3 13. Rxf3 g6 14. Bc4 b6 15. Rf6 a5 16. b5 c6 17. bxc6 Bf5 18. d3 a4 19. Bh6 Rfe8 20. Re1 e4 21. dxe4 Bg4 22. d6 Bh5 23. Ref1 g5 24. d7 Rxe4 25. c7 Rxc4 26. d8=Q+ Rxd8 27. cxd8=Q# 1-0",
    B: "1161",
  },
  {
    W: "1741",
    T: "180+2",
    P: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. g4 Be4 5. f3 Bg6 6. h4 h5 7. g5 e6 8. Bd3 Bxd3 9. Qxd3 Ne7 10. f4 g6 11. Nf3 Nf5 12. Bd2 Be7 13. Nc3 O-O 14. Ne2 Nd7 15. b4 a5 16. c3 b6 17. O-O c5 18. bxc5 bxc5 19. Rab1 c4 20. Qc2 Rb8 21. Rxb8 Qxb8 22. Rb1 Qc7 23. Rb5 Rb8 24. Qb2 Rxb5 25. Qxb5 Bd8 26. a4 Ne7 27. Bc1 Nc6 28. Ba3 Qb6 29. Qxb6 Nxb6 30. Nd2 Nxa4 31. Nb1 Na7 32. Bd6 Nb5 33. Kf2 Nxd6 34. exd6 Kf8 0-1",
    B: "1828",
  },
  {
    W: "1896",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d3 O-O 6. O-O d5 7. exd5 Nxd5 8. Re1 Re8 9. Qb3 Na5 10. Qa4 Nxc4 11. Qxc4 Bb6 12. Nxe5 Be6 13. Qa4 c6 14. Nf3 1-0",
    B: "1888",
  },
  {
    W: "2008",
    T: "180+2",
    P: "1. d4 c6 2. Bf4 e6 3. e3 d5 4. Nf3 Bd6 5. Bg3 Bxg3 6. hxg3 Nf6 7. Bd3 h6 8. Nbd2 Nbd7 9. c3 a6 10. Qe2 b5 11. a3 e5 12. dxe5 Nxe5 13. Nxe5 Qd6 14. Nef3 c5 15. e4 dxe4 16. Nxe4 Qe7 17. Nxf6+ gxf6 18. Qxe7+ Kxe7 19. O-O Be6 20. Rfe1 Kd7 21. Rad1 Kc7 22. Bc2 Rad8 23. Rxd8 Rxd8 24. Rd1 Rxd1+ 25. Bxd1 Kd6 26. Kf1 a5 27. Ke1 b4 28. cxb4 cxb4 29. axb4 axb4 30. Kd2 Kc5 31. Ng1 Bf5 32. Ne2 Be4 33. f3 Bf5 34. g4 Bd7 35. Ng3 Be6 36. Ne4+ Kc4 37. Nxf6 1-0",
    B: "1983",
  },
  {
    W: "2028",
    T: "180+2",
    P: "1. d4 c5 2. c3 Nf6 3. Bf4 g6 4. e3 Bg7 5. Nf3 O-O 6. Be2 d6 7. O-O Nh5 8. Bg5 h6 9. Bh4 g5 10. Bg3 Bf5 11. Nbd2 Nc6 12. e4 Nxg3 13. hxg3 Bg6 14. d5 Nb8 15. Re1 Nd7 16. Rc1 b5 17. Bxb5 Rb8 18. Bxd7 Qxd7 19. b3 a5 20. Nc4 a4 21. bxa4 Qg4 22. Nfd2 Qc8 23. Rb1 Qa6 24. Rxb8 Rxb8 25. Qc2 Ra8 26. a5 f6 27. f3 Be8 28. Rb1 Bb5 29. Qb3 Be8 30. Qb6 Qc8 31. Qb7 Kf8 32. Qxc8 Rxc8 33. a6 Ra8 34. Rb6 f5 35. Na5 Bxc3 36. Ndc4 fxe4 37. fxe4 Bg6 38. Nc6 Bxe4 39. a7 Kf7 40. Rb8 Rxa7 41. Nxa7 Bxd5 42. Ne3 Bxa2 43. Nd1 Bd4+ 44. Kh2 c4 45. Nc6 Bc5 46. Nc3 Bb3 47. Rb7 Ke6 48. Rxe7+ Kf6 49. Kh3 h5 50. Ne4+ Kg6 51. Nxc5 dxc5 52. Re3 Ba4 53. Na5 Bb5 54. Rc3 Bd7+ 55. Kh2 Kf5 56. Nxc4 Ke4 57. Nb6 Kd4 58. Rc4+ Ke5 59. Rxc5+ Kf6 60. Nxd7+ 1-0",
    B: "2236",
  },
  {
    W: "1486",
    T: "180+2",
    P: "1. e4 b6 2. Nf3 Bb7 3. Nc3 e6 4. Bc4 c5 5. O-O Nf6 6. Re1 Be7 7. e5 Nd5 8. d4 Nxc3 9. bxc3 O-O 10. Qd3 a6 11. Rd1 b5 12. dxc5 bxc4 13. Qxc4 Bxf3 14. gxf3 Nc6 15. Ba3 Nxe5 16. Qd4 0-1",
    B: "1580",
  },
  {
    W: "1649",
    T: "180+2",
    P: "1. e4 c5 2. Bc4 d6 3. Nf3 Nd7 4. d4 Nb6 5. Bxf7+ Kxf7 6. dxc5 Nd7 7. Qd5+ Ke8 8. Ng5 Nh6 9. Ne6 Nf6 10. Qg5 Bxe6 11. O-O Qd7 12. Rd1 Kd8 13. cxd6 exd6 14. Nc3 Kc7 15. Rd4 Rd8 16. a4 Nf7 17. Qa5+ Kb8 18. Nb5 a6 19. Be3 axb5 20. axb5 b6 21. Qa8+ Kc7 22. Ra7# 1-0",
    B: "1719",
  },
  {
    W: "1226",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bd6 4. O-O Nf6 5. Re1 O-O 6. c3 Ng4 7. d4 exd4 8. cxd4 Nb4 9. Bc4 c6 10. Bd2 b5 11. Bxb4 Bxb4 12. Nfd2 bxc4 13. Qxg4 d5 14. a3 Bxg4 15. axb4 Qg5 16. exd5 cxd5 17. b3 Bh3 18. g3 cxb3 19. Nxb3 Rae8 20. Rxe8 Rxe8 21. N1d2 Re1+ 22. Rxe1 f5 23. Nf3 Qg4 24. Nbd2 f4 25. Re7 fxg3 26. fxg3 h5 27. Re8+ Kh7 28. Kf2 h4 29. Nxh4 Qxd4+ 30. Ke2 Qxb4 31. Re7 d4 32. Rxa7 d3+ 33. Kxd3 Qb5+ 34. Kc2 Qc6+ 35. Kd1 Bg4+ 36. Ke1 Qe6+ 37. Kf2 Qe2+ 38. Kg1 Qxd2 39. Ng2 Qd4+ 40. Kf1 Qxa7 41. h3 Qa6+ 42. Kf2 Bxh3 43. Nf4 Qb6+ 44. Kf3 Qb3+ 45. Ke4 Qxg3 46. Nd5 Qg4+ 47. Ke5 Qe2+ 48. Kd4 Be6 49. Nc3 1-0",
    B: "1138",
  },
  {
    W: "2123",
    T: "180+2",
    P: "1. d4 Nf6 2. Bf4 d5 3. e3 e6 4. Nd2 Bd6 5. c3 Bxf4 6. exf4 b6 7. Ngf3 Bb7 8. Bd3 Nbd7 9. Qe2 O-O 10. O-O c5 11. Rfe1 cxd4 12. cxd4 Rc8 13. Rac1 Rc7 14. Ne5 Qb8 15. h3 Rfc8 16. Nb3 Rxc1 17. Rxc1 Rxc1+ 18. Nxc1 Qc7 19. Qc2 Qxc2 20. Bxc2 Kf8 21. f3 h6 22. Kf2 Ke7 23. Ke3 Ne8 24. Ba4 Nxe5 25. fxe5 Nc7 26. a3 Ba6 27. g3 Bf1 28. h4 b5 29. Bc2 a5 30. Nd3 Bxd3 31. Bxd3 f6 32. f4 f5 33. Kd2 h5 34. Kc3 Kd7 35. b4 a4 36. Be2 Kc6 37. Bxh5 Kd7 38. Be2 Ke7 39. g4 g6 40. h5 Kf7 41. h6 Kg8 42. gxf5 gxf5 43. Bh5 Kh7 44. Bf7 Kxh6 45. Kd2 Kg7 46. Bh5 Kh6 47. Be2 Kg6 48. Ke3 Kh6 49. Kf2 Kg6 50. Kg3 Kh6 51. Kh4 Kg6 52. Bh5+ Kh6 53. Bf7 Kg7 54. Bh5 Kh6 55. Bd1 Ne8 56. Bh5 Nc7 1/2-1/2",
    B: "2214",
  },
  {
    W: "1322",
    T: "180+0",
    P: "1. d4 d5 2. Nf3 Bf5 3. Bf4 e6 4. a3 f6 5. e3 g5 6. Bg3 g4 7. Nfd2 c5 8. f3 cxd4 9. exd4 Qc8 10. fxg4 Bxc2 11. Qc1 Bh6 12. Nc3 Be4 13. Ncxe4 Qxc1+ 14. Rxc1 dxe4 15. Nxe4 Bxc1 16. Bb5+ Nc6 17. Bxc6+ bxc6 18. O-O Bxb2 19. a4 Bxd4+ 20. Kh1 O-O-O 21. Nd6+ Kd7 22. Nf7 f5 23. gxf5 Rf8 24. Nxh8 Bxh8 25. Rd1+ Ke7 26. fxe6 Kxe6 27. Rd6+ Ke7 28. Rxc6 Rf1# 0-1",
    B: "1291",
  },
  {
    W: "1885",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nxd4 5. Qxd4 e6 6. Nc3 f6 7. Be3 a6 8. O-O-O Qc7 9. Kb1 Ne7 10. f3 Nc6 11. Qd2 Bb4 12. a3 Ba5 13. g4 O-O 14. h4 b5 15. Bc5 Rd8 16. Qd6 Ne5 17. Be2 Rb8 18. b4 Bb6 19. f4 Nf7 20. Qxc7 Bxc7 21. f5 d6 22. Be3 exf5 23. exf5 Ne5 24. Nd5 Rd7 25. Bf4 Bb7 26. Bxe5 dxe5 27. Bf3 Re8 28. Be4 a5 29. c3 Red8 30. Kc2 axb4 31. cxb4 Bb8 32. Rd2 Rc8+ 33. Kb2 Kf7 34. Rhd1 Rc4 35. Bh1 e4 36. Nb6 Be5+ 37. Kb3 Rxd2 38. Rxd2 Rc3+ 39. Kb2 Rd3+ 40. Kc1 Bf4 41. Kc2 Rxd2+ 42. Kb3 e3 43. Bxb7 e2 44. Nd5 e1=Q 45. Nxf4 Qe3+ 0-1",
    B: "1881",
  },
  {
    W: "1100",
    T: "180+0",
    P: "1. e4 d5 2. e5 d4 3. d3 Qd5 4. f4 Bf5 5. Nf3 Be4 6. Nbd2 f6 7. c4 Qa5 8. dxe4 Qxd2+ 9. Qxd2 1-0",
    B: "938",
  },
  {
    W: "2014",
    T: "180+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 Nf6 5. Be2 Bd6 6. Nc3 O-O 7. Bg5 c6 8. O-O Bg4 9. Ne5 Bxe2 10. Qd2 Be7 11. Nxe2 Nbd7 12. Ng3 g6 13. Bh6 Re8 14. f4 Bf8 15. Bg5 Bg7 16. Rae1 Qc7 17. f5 Re7 18. fxg6 fxg6 19. Qf4 Rf8 20. Qh4 Nxe5 21. dxe5 Rxe5 22. Rxe5 Qxe5 23. Bh6 Bxh6 24. Qxh6 Nd7 25. Rxf8+ Nxf8 26. Qd2 Ne6 27. Ne2 Qe4 28. Nc3 Qd4+ 29. Qxd4 Nxd4 30. Na4 Nxc2 31. Nc5 b6 32. Nd7 c5 33. b3 d4 34. Kf2 d3 35. Ne5 Nb4 36. a3 d2 37. Ke2 Nd5 38. Kxd2 Kg7 39. Kd3 Kf6 40. Nc6 a6 41. g3 Ne7 42. Nb8 a5 43. Kc4 Ke5 44. Kb5 Kd4 45. Kxb6 c4 46. bxc4 Kxc4 47. Kxa5 Nd5 48. Nc6 Ne3 49. Kb6 Nd5+ 50. Ka5 h5 51. a4 Kc5 52. Ne5 Ne7 53. Ka6 Kd6 54. Nc4+ Kc7 55. a5 Nc6 56. Ne3 Nb4+ 57. Kb5 Nd3 58. Nd5+ Kb7 59. a6+ Ka7 60. Ne7 Ne5 61. h3 g5 62. h4 gxh4 63. gxh4 Ng6 64. Nc6+ Ka8 65. Kb6 Nxh4 66. a7 Nf5 67. Nb4 Nd6 68. Nd5 Nc8+ 69. Ka6 Nxa7 70. Nc7+ Kb8 71. Nd5 Nc6 72. Nf4 h4 73. Nh3 Ne5 74. Kb5 Ng6 75. Kc4 Kc7 76. Kd4 Kd6 77. Ke4 Ke6 78. Kf3 Kf6 79. Kg4 Kg7 80. Nf2 Kh6 81. Ne4 Ne5+ 82. Kxh4 Kg6 0-1",
    B: "2077",
  },
  {
    W: "1787",
    T: "180+0",
    P: "1. e4 g6 2. Qf3 Bg7 3. Bc4 e6 4. Nh3 Nc6 5. d4 Nxd4 6. Qd1 Nc6 7. Qf3 b6 8. Ng5 Qf6 9. Qb3 Na5 10. Qb4 Nxc4 11. Qxc4 Bb7 12. Nc3 Ne7 13. h4 h6 14. e5 Qxe5+ 15. Nge4 Bxe4 16. Nxe4 d5 17. Qd3 Qxe4+ 18. Qxe4 dxe4 19. O-O O-O 20. h5 g5 21. Be3 f5 22. Rab1 Rfd8 23. c3 Nd5 24. Bd4 Bxd4 25. cxd4 Nb4 26. Rbc1 Rxd4 27. Rxc7 Nxa2 28. Re7 Rd2 29. Rxe6 Rxb2 30. Rxh6 Kg7 31. Rg6+ Kh7 32. Rxg5 Kh6 33. Rxf5 Rg8 34. Re5 Rb4 35. Re6+ Kxh5 36. Re5+ Kh4 37. Re1 Rg4 38. Re3 Nc1 39. g3+ Kh3 40. Rh5+ Rh4 41. Rxh4# 1-0",
    B: "1802",
  },
  {
    W: "1546",
    T: "180+0",
    P: "1. b3 e5 2. Bb2 Nc6 3. d3 d5 4. Nd2 Nf6 5. g3 Be6 6. Bg2 Bd6 7. e4 d4 8. Ngf3 a5 9. a4 Bd7 10. Nc4 Bb4+ 11. Nfd2 Bxd2+ 12. Qxd2 Nb4 13. O-O c6 14. Nxe5 O-O 15. Nf3 Bg4 16. Nxd4 c5 17. Nf3 h5 18. h3 Bxf3 19. Bxf3 h4 20. g4 g6 21. Qh6 Nc6 22. g5 Nd4 23. Bxd4 cxd4 24. gxf6 Qxf6 25. Bg2 Qg7 26. Qxh4 Qh7 27. Qg4 Kg7 28. f4 Rh8 29. f5 Qh5 30. Qxh5 gxh5 31. Rf3 Kh6 32. Rg3 Rag8 33. Rxg8 Rxg8 34. Rf1 h4 35. Rf3 Rg3 36. Rxg3 hxg3 37. Kf1 Kg5 38. Ke2 Kf4 39. h4 1-0",
    B: "1549",
  },
  {
    W: "2100",
    T: "180+0",
    P: "1. e4 d6 2. f4 Nf6 3. Nc3 e5 4. fxe5 dxe5 5. h3 Nxe4 6. Nxe4 Qh4+ 7. Nf2 Bc5 8. Qe2 Nc6 9. Nf3 Qg3 10. d4 Nxd4 11. Nxd4 Bxd4 12. c3 Bb6 13. a4 a5 14. Ra3 O-O 15. c4 Qh4 16. Be3 c5 17. Rb3 Qf6 18. Ne4 Qc6 19. Bf2 f5 20. Nc3 e4 21. Nd5 Bd8 22. g3 b6 23. Bg2 Ba6 24. O-O Qxd5 25. cxd5 Bxe2 26. Re1 Bc4 27. Ra3 Bxd5 28. Be3 Bf6 29. Rd1 Bc4 30. Raa1 Bd3 31. Bf1 Rad8 32. Bxd3 Rxd3 33. Bf4 Rfd8 34. Re1 Bxb2 35. Rac1 Bxc1 36. Rxc1 e3 37. Re1 Re8 38. Kf1 e2+ 39. Kf2 Rd2 40. Bxd2 h6 41. Bf4 Re4 42. Bc7 Rxa4 43. Bxb6 c4 44. Rxe2 c3 45. Rc2 Rc4 46. Bxa5 Kf7 47. Rxc3 Re4 48. Rc7+ Ke6 49. Bc3 g5 50. Rc6+ Kd5 51. Rxh6 g4 52. h4 f4 0-1",
    B: "2114",
  },
  {
    W: "1572",
    T: "180+0",
    P: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Nc3 e6 5. Bd3 Bb4 6. Bxf5 exf5 7. Bd2 Ne7 8. a3 Ba5 9. b4 Bb6 10. Nf3 Nd7 11. Bf4 O-O 12. Ng5 Ng6 13. Qc1 Bxd4 14. e6 Bxc3+ 15. Kf1 Bxa1 16. Qxa1 Nxf4 17. exf7+ Kh8 18. g3 Qxg5 19. gxf4 Qxf4 20. Rg1 Rxf7 21. Rg3 Ne5 22. Qd1 Rg8 23. Qh5 g6 24. Qh3 Ng4 25. Rf3 Qc1+ 26. Kg2 Re8 27. Qh4 Re1 28. Qd8+ Kg7 29. Re3 Rg1+ 30. Kf3 Qd1+ 31. Kf4 Nf6 32. Re6 Rg4+ 33. Ke5 Qe1+ 34. Kd6 Qxe6+ 35. Kxe6 Re4+ 36. Kd6 Ne8+ 37. Kc5 Ree7 0-1",
    B: "1614",
  },
  {
    W: "1594",
    T: "180+0",
    P: "1. e4 c5 2. b4 b6 3. b5 g6 4. c4 Bg7 0-1",
    B: "1635",
  },
  {
    W: "1940",
    T: "180+0",
    P: "1. h4 d5 2. e4 dxe4 3. d3 exd3 4. Bxd3 e5 5. Nc3 Be7 6. Nf3 Nc6 7. Ng5 h6 8. Nxf7 Kxf7 9. Qh5+ Kf8 10. Bg5 Nf6 11. Bxf6 Bxf6 12. O-O-O Be6 13. f4 Qe8 14. Bg6 Bf7 15. Bxf7 Qxf7 16. Qf5 exf4 17. Rd7 Ne7 18. Qc5 Bxc3 19. bxc3 Re8 20. Re1 Rh7 21. Rexe7 Qxe7 22. Rxe7 Rxe7 23. Qf5+ Ke8 24. Qxh7 Re1+ 25. Kd2 Re7 26. Qh8+ Kf7 27. Qc8 c6 28. Qf5+ Ke8 29. Qxf4 Rd7+ 30. Kc1 Re7 31. Qb8+ Kf7 32. Qxa7 b5 33. Qb6 Re1+ 34. Kb2 Re6 35. Qxc6 Rxc6 36. c4 Rxc4 37. Kb3 Rxh4 38. Kb2 Rg4 39. Ka1 Rxg2 40. a4 bxa4 41. Kb1 Rxc2 42. Ka1 Rd2 43. Kb1 h5 44. Ka1 Rg2 45. Kb1 h4 46. Kc1 h3 47. Kd1 h2 48. Ke1 h1=Q# 0-1",
    B: "2252",
  },
  {
    W: "1380",
    T: "180+0",
    P: "1. d4 g6 2. e4 Bg7 3. Nf3 e6 4. Nc3 Ne7 5. Be3 O-O 6. Bc4 a6 7. O-O b5 8. Bd3 c6 9. Re1 Bb7 10. e5 d5 11. Bg5 Re8 12. Qd2 Qb6 13. Bh6 Nf5 14. Bxg7 Nxg7 15. Qh6 Nd7 16. Ng5 Kf8 17. Qxh7 Qxd4 18. Qh8+ Ke7 19. Qxg7 Kd8 20. Nxf7+ Kc8 21. Nd6+ Kd8 22. Nxb7+ Kc7 23. Nd6 Rf8 24. Rf1 Kb6 25. Qxd7 Rh8 26. Qb7+ Kc5 27. a3 Qxe5 28. b4+ Kd4 29. Ne2+ Qxe2 30. Bxe2 Rh5 31. Qxc6 Rh4 32. Bxb5 axb5 33. Nxb5+ Ke5 34. Rae1+ Kf6 35. Qd7 Re4 36. Rxe4 dxe4 37. f4 Rf8 38. Nd6 Rf7 39. Qxf7# 1-0",
    B: "1375",
  },
  {
    W: "1854",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 e6 3. Bc4 Qc7 4. d3 g6 5. a3 Bg7 6. Nc3 Ne7 7. Bd2 d5 8. exd5 exd5 9. Bb3 O-O 10. O-O a6 11. h3 b5 12. Nh2 Bb7 13. Ba2 Nd7 14. Ng4 h5 15. Nh6+ Kh7 16. Qc1 f5 17. f3 Qg3 18. Bf4 Qh4 19. Bg5 Bxh6 20. Bxh6 Qd4+ 21. Be3 Qg7 22. Bg5 f4 23. Bxe7 Qxe7 24. Nxd5 Bxd5 25. Bxd5 Rad8 26. Re1 Qd6 27. Be4 c4 28. dxc4 bxc4 29. c3 Nc5 30. Qd1 Nxe4 31. fxe4 Qc5+ 0-1",
    B: "1846",
  },
  {
    W: "1877",
    T: "180+0",
    P: "1. e4 e5 2. Nc3 d6 3. Bc4 Nf6 4. Nf3 c5 5. d3 Nc6 6. Ng5 Be6 7. Nxe6 fxe6 8. Bxe6 Qe7 9. Bc4 O-O-O 10. Nd5 Nxd5 11. Bxd5 Nb4 12. Bc4 Nc6 13. c3 g6 14. Bd5 Qd7 15. Qb3 Kb8 16. a4 h5 17. a5 a6 18. Bg5 Be7 19. Bxc6 Qc7 20. Bxe7 Qxe7 21. Bd5 Rd7 22. Qb6 Rc7 23. O-O g5 24. b4 cxb4 25. Qxb4 g4 26. Rab1 h4 27. Rb2 h3 28. Rfb1 hxg2 29. Bxb7 Rh7 30. Bxa6+ Ka8 31. Qb8# 1-0",
    B: "1862",
  },
  {
    W: "1808",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e3 O-O 5. Bd3 Nc6 6. Nf3 d6 7. a3 e5 8. O-O Bg4 9. dxe5 dxe5 10. h3 Bh5 11. g4 Nxg4 12. hxg4 Bxg4 13. Be2 Qf6 14. Ne4 Qf5 15. Ng3 Qe6 16. Ng5 Qf6 17. Nxh7 Kxh7 18. Bxg4 Rh8 19. Qf3 Qe7 20. Kg2 f5 21. Rh1+ Kg8 22. Rxh8+ Bxh8 23. Bh3 Bf6 24. e4 Nd4 25. Qd3 f4 26. Ne2 Rd8 27. Nxd4 Rxd4 28. Qe3 Bg5 29. Qe1 Qc5 30. Qb4 Qc6 31. f3 Rxc4 32. Qb3 b5 33. Be6+ Kg7 34. Bxc4 bxc4 35. Qc3 Qc5 36. b4 Qd4 37. Qxd4 exd4 38. Bd2 c3 39. Bxc3 dxc3 40. Rc1 Bf6 41. Kh3 g5 42. Kg4 Kf7 43. Kf5 Kg7 44. e5 a6 45. exf6+ Kf7 46. Rxc3 Ke8 47. Rxc7 Kf8 48. Re7 Kg8 49. Kg6 Kf8 50. Rh7 g4 1-0",
    B: "1820",
  },
  {
    W: "1816",
    T: "300+0",
    P: "1. e4 e5 2. Nc3 Nf6 3. d4 exd4 4. Qxd4 c5 5. Qd1 Nc6 6. Nf3 h5 7. Bc4 Na5 8. Bxf7+ Kxf7 9. Ne5+ Ke8 10. Bg5 c4 11. Nd5 Be7 12. O-O d6 13. Ng6 Rg8 14. Ndxe7 Qc7 15. Nxg8 Ng4 16. Qd5 Bd7 17. N8e7 Bc6 18. Qg8+ Kd7 19. Qxa8 d5 20. e5 d4 21. Bf4 Be4 22. e6+ Kxe6 23. Bxc7 Bxg6 24. Nxg6 Nc6 25. Qxb7 1-0",
    B: "1718",
  },
  {
    W: "1732",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 Nc6 5. Nc3 Nf6 6. Be3 Be7 7. Bb5 a6 8. Bxc6+ bxc6 9. Nxc6 Qd7 10. Nxe7 Qxe7 11. Qf3 Bb7 12. O-O Nxe4 13. Nxe4 Bxe4 14. Qg3 O-O 15. Bd4 Bg6 16. Rfe1 Qd7 17. h4 c5 18. Bc3 f6 19. Rad1 Bxc2 20. Rxd6 Qf5 21. Rd7 Rf7 22. Ree7 Raf8 23. Rxf7 Rxf7 24. Rxf7 Kxf7 25. Qc7+ Kg6 26. Qg3+ Kh5 27. Qf3+ Qxf3 28. gxf3 c4 29. Kf1 g5 30. Ke2 Bd3+ 31. Ke3 gxh4 32. b3 h3 33. Bxf6 h2 0-1",
    B: "1719",
  },
  {
    W: "1673",
    T: "120+1",
    P: "1. f4 f5 2. e4 fxe4 3. d3 exd3 4. Bxd3 Nf6 5. g4 d6 6. g5 Ng4 7. h3 h5 8. Bg6+ Kd7 9. hxg4 Kc6 10. Be4+ d5 11. Bf3 e6 12. gxh5 Bd6 13. Be3 Bd7 14. Qd4 b6 15. Nc3 Kb7 16. Qd3 Nc6 17. O-O-O Qf8 18. Be2 a5 19. Nb5 Bxf4 20. Bxf4 Qxf4+ 21. Kb1 Qxg5 22. Nf3 Qf6 23. Rdf1 Qe7 24. Qc3 e5 25. Re1 e4 26. Nfd4 Qf6 27. Ref1 Qe5 28. Rf7 Nxd4 29. Nxd4 Be6 30. Re7 Rae8 31. Rxe6 Rxe6 32. Nxe6 Qxe6 33. Qxg7 Rg8 34. Qd4 Rg2 35. Bb5 Qd6 36. Rd1 Qh2 37. Qxd5+ Kb8 38. Qd8+ Ka7 39. Qxc7+ 1-0",
    B: "1650",
  },
  {
    W: "1957",
    T: "300+0",
    P: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nd7 5. Ng3 Ngf6 6. Nf3 Nb6 7. Bd3 Bg4 8. O-O e6 9. h3 Bxf3 10. Qxf3 Qxd4 11. c3 Qd8 12. Bf4 Nbd5 13. Be5 Be7 14. Rad1 Qb6 15. b4 O-O 16. a4 a5 17. Bd4 Qc7 18. b5 c5 19. Bxf6 Bxf6 20. c4 Nb4 21. Bb1 Rfd8 22. Nh5 Bd4 23. Qe4 g6 24. Kh1 Bh8 25. Qg4 Rxd1 26. Rxd1 Rd8 27. Ng3 Rxd1+ 28. Qxd1 Bd4 29. Qf3 b6 30. Ne4 Qe5 31. h4 h5 32. Ng5 Qf6 33. Qa8+ Kg7 34. Qb7 Qxf2 35. Nxe6+ Kf6 36. Qf3+ Qxf3 37. gxf3 fxe6 38. Kg2 g5 39. hxg5+ Kxg5 40. Kg3 Be5+ 41. Kh3 Bf4 42. Kg2 Be3 43. Kh3 Bf2 44. Kg2 Be1 45. Kf1 Bg3 46. Kg2 h4 47. Kf1 Kf4 48. Ke2 Ke5 0-1",
    B: "1978",
  },
  {
    W: "1204",
    T: "60+0",
    P: "1. e4 d5 2. Bb5+ c6 3. Bd3 e6 4. Nc3 f5 5. exf5 exf5 6. g4 fxg4 7. Bf5 Qe7+ 8. Nge2 Nh6 9. Bxc8 b5 10. d3 Na6 11. Bxa6 Rb8 12. Bxh6 gxh6 13. d4 b4 14. Nxd5 Qc5 15. Bb5 Qxb5 16. Nc7+ Kd8 17. Nxb5 cxb5 18. Nf4 Rc8 19. d5 Bd6 20. Ne6+ Kd7 21. Nc5+ Kc7 22. Qxg4 Kb8 23. Nd7+ 1-0",
    B: "947",
  },
  {
    W: "1298",
    T: "60+0",
    P: "1. d4 c5 2. Nf3 e6 3. c3 Nc6 4. Bf4 h6 5. e3 Nf6 6. h3 cxd4 7. cxd4 Bb4+ 8. Nbd2 Bxd2+ 9. Nxd2 Qa5 10. a3 Ne4 11. b4 Qf5 12. Bd3 d5 13. f3 Ng3 14. Bxf5 1-0",
    B: "1290",
  },
  {
    W: "2125",
    T: "60+0",
    P: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. Nf3 Nc6 5. Bd3 Bg4 6. O-O Nxd4 7. Be2 Nc6 8. h3 Bh5 9. Nd4 Bxe2 10. Nxe2 e6 11. c3 Bc5 12. Be3 Bxe3 13. fxe3 Nge7 14. Nd4 O-O 15. Nd2 Qb6 16. N2b3 Rac8 17. Qf3 Ne5 18. Qf4 N7g6 19. Qf3 Nxf3+ 20. Rxf3 a5 21. a4 e5 22. Nb5 f5 23. Re1 f4 24. Kh1 fxe3 25. Rfxe3 Rf2 26. R3e2 Rcf8 27. Nd2 e4 28. Nd4 Qc7 29. Nf1 Rxf1+ 30. Rxf1 Rxf1# 0-1",
    B: "2125",
  },
  {
    W: "2454",
    T: "60+0",
    P: "1. g3 Nf6 2. Bg2 g6 3. d4 Bg7 4. c4 O-O 5. Nc3 d5 6. Nf3 dxc4 7. O-O c5 8. d5 Nbd7 9. e4 Nb6 10. Re1 Bg4 11. Be3 Rc8 12. a4 a5 13. h3 Bxf3 14. Bxf3 h5 15. e5 Nh7 16. e6 f5 17. Bxh5 gxh5 18. Qxh5 Qe8 19. Qf3 Qg6 20. h4 Qg4 21. Qxg4 fxg4 22. Rad1 Bxc3 23. bxc3 Nf6 24. d6 exd6 25. Rxd6 Nxa4 26. e7 Rf7 27. Rd8+ Kg7 28. Rxc8 Rxe7 29. Bh6+ Kf7 30. Rf8+ Kg6 31. Rxe7 Kxh6 32. Re6 Nxc3 33. Rexf6+ Kg7 34. h5 Ne4 35. h6+ Kh7 36. R8f7+ Kg8 37. Rg7+ Kh8 38. Rf8# 1-0",
    B: "2293",
  },
  {
    W: "2215",
    T: "60+0",
    P: "1. d4 e6 2. e3 b6 3. f4 Bb7 4. Nf3 f5 5. Be2 Nf6 6. O-O Be7 7. Nbd2 O-O 8. Nb3 Qe8 9. Bd2 d6 10. Be1 Nbd7 11. Bh4 Bd8 12. c4 e5 13. Rc1 e4 14. Ng5 h6 15. Nh3 Nh7 16. Bxd8 Rxd8 17. c5 bxc5 18. dxc5 Ba8 19. Qc2 d5 20. c6 Ndf6 21. Rfd1 g5 22. Qc5 g4 23. Nf2 h5 24. Ba6 h4 25. Bb7 g3 26. hxg3 hxg3 27. Nh3 Qh5 28. Bxa8 Rxa8 29. Rxd5 Nxd5 30. Qxd5+ Kh8 31. Qe5+ Nf6 32. Qxc7 Ng4 33. Ng5 Qh2+ 34. Kf1 Qh1+ 35. Ke2 Qxg2+ 36. Kd1 Nxe3+ 37. Ke1 Qf2# 0-1",
    B: "2229",
  },
  {
    W: "1896",
    T: "60+0",
    P: "1. g3 g6 2. Bg2 Bg7 3. Nc3 f6 4. d3 Nh6 5. Bd2 Nf7 6. Nf3 O-O 7. O-O d6 8. e4 e5 9. Re1 Nc6 10. Nd5 Ne7 11. Nxe7+ Qxe7 12. Bc3 Bd7 13. Nd2 Rae8 14. b3 f5 15. Nc4 b5 16. Na5 a6 17. exf5 gxf5 18. Bc6 f4 19. Bxd7 Qxd7 20. Qf3 fxg3 21. fxg3 Ng5 22. Qc6 Nf3+ 23. Kh1 Qxc6 24. Nxc6 Nxe1 25. Rxe1 e4 26. Bxg7 Kxg7 27. Rxe4 Rxe4 28. dxe4 Rf2 29. Nd4 Rd2 30. Nf5+ Kf8 31. c4 Rxa2 32. cxb5 axb5 33. Nd4 Ra1+ 34. Kg2 Rb1 35. Nxb5 Rd1 36. Nxc7 Rd3 37. b4 Rb3 38. b5 Ke7 39. Kh3 Kd7 40. Nd5 Rxb5 41. Kg4 Ke6 42. Kf4 Rb2 43. h4 Rg2 44. h5 h6 45. g4 Rf2+ 46. Ke3 Rg2 47. Kf3 Rg1 0-1",
    B: "1983",
  },
  {
    W: "1419",
    T: "60+0",
    P: "1. e4 c5 2. g3 Nc6 3. Bg2 Nf6 4. d3 d6 5. Bg5 e5 6. Bxf6 Qxf6 7. f3 Nd4 8. Nc3 Be6 9. Nge2 Be7 10. Nxd4 cxd4 11. Nd5 Bxd5 12. exd5 O-O 13. Qd2 Qg6 14. O-O-O Rae8 15. Kb1 Bg5 16. Qe2 e4 17. dxe4 f5 18. Rxd4 fxe4 19. fxe4 Bf6 20. Rd3 b5 21. Re3 a6 22. b3 Qg5 23. Re1 Be5 24. h4 Qd8 25. h5 Qa5 26. a4 Qb4 27. Kc1 Qa3+ 28. Kd1 Qa1+ 29. Kd2 Bc3+ 30. Kd3 b4 31. Rxa1 Rxe4 32. Rxe4 1-0",
    B: "1463",
  },
  {
    W: "1343",
    T: "60+0",
    P: "1. e4 e6 2. Nc3 d5 3. exd5 exd5 4. Nf3 Qe7+ 5. Be2 Nf6 6. d4 Nc6 7. O-O Bg4 8. Bg5 h6 9. Bxf6 Qxf6 10. Nxd5 Qe6 11. Nxc7+ 1-0",
    B: "1342",
  },
  {
    W: "1500",
    T: "120+1",
    P: "1. Nf3 b6 2. d4 Bb7 3. Nfd2 Nc6 4. b3 e6 5. Ba3 Qf6 6. Ne4 Qxd4 7. Qc1 Qxa1 8. Bb2 Qxa2 9. Ba3 O-O-O 10. Nbc3 Bxa3 11. Nxa2 Bxc1 12. Nxc1 Nd4 13. Nc3 Nxc2+ 14. Kd2 Nd4 15. e3 Nf5 16. h3 d5 17. Nd3 d4 18. exd4 Nxd4 19. Nd1 Nxb3+ 20. Kc2 Nc5 21. Nxc5 bxc5 22. Bc4 Nf6 23. f4 Nd5 24. Bxd5 exd5 0-1",
    B: "1537",
  },
  {
    W: "1438",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Be7 5. O-O Nf6 6. e5 Ng4 7. Bf4 O-O 8. h3 Nh6 9. Bxh6 gxh6 10. Qd2 Bg5 11. Nxg5 hxg5 12. Re1 Re8 13. Na3 Nxe5 14. Rad1 Nxc4 15. Nxc4 Rxe1+ 16. Rxe1 c5 17. a4 d6 18. Qd3 Be6 19. Nd2 h6 20. Qf3 Qd7 21. b3 Re8 22. Qh5 Kg7 23. Nf3 Qe7 0-1",
    B: "1428",
  },
  {
    W: "1352",
    T: "120+1",
    P: "1. g3 d5 2. b3 e5 3. Bg2 Nf6 4. Bb2 Nc6 5. e3 a5 6. Ne2 a4 7. O-O a3 8. Bxa3 Bxa3 9. Nxa3 Rxa3 10. Nc3 O-O 11. Nb5 Ra8 12. d4 e4 13. f3 Ne7 14. fxe4 dxe4 15. Nc3 Ng6 16. Nxe4 Nxe4 17. Bxe4 Bh3 18. Rf2 Re8 19. Bxg6 fxg6 20. c3 Rxe3 21. c4 Qd7 22. d5 c6 23. dxc6 Qxc6 24. Qd5+ Qxd5 25. cxd5 Rae8 26. Rd1 b6 27. d6 Rc3 28. d7 Rd8 29. Re2 Rf3 30. Re8+ Kf7 31. Rxd8 Ke7 32. Re8+ Kf7 33. Re4 Rf1+ 34. Rxf1+ Bxf1 35. d8=Q Bg2 36. Qe7+ Kg8 37. Rf4 1-0",
    B: "1254",
  },
  {
    W: "1481",
    T: "120+1",
    P: "1. e4 c5 2. Nf3 d6 3. c3 Nf6 4. e5 dxe5 5. Nxe5 Nbd7 6. Bb5 a6 7. Nxd7 Nxd7 8. Ba4 b5 9. Bb3 c4 10. Bc2 e6 11. b3 cxb3 12. Bxb3 Be7 13. Qf3 O-O 14. Qxa8 Nc5 15. Qf3 Nd3+ 16. Kd1 Nxc1 17. Kxc1 Bg5 18. Kb2 Bxd2 19. Nxd2 Qxd2+ 20. Bc2 Rd8 21. Rad1 Qg5 22. Rxd8+ Qxd8 23. Rd1 Qf8 24. Qf4 1-0",
    B: "1466",
  },
  {
    W: "1395",
    T: "120+1",
    P: "1. e4 e6 2. c3 c6 3. Bd3 d5 4. Bc2 Nf6 5. e5 Nfd7 6. d4 c5 7. Nf3 Nc6 8. O-O g6 9. Na3 cxd4 10. cxd4 Bg7 11. Nb5 O-O 12. Nd6 Qb6 13. Rb1 a6 14. Bd2 Qd8 15. Qe1 b5 16. Bg5 Qb6 17. Qd2 a5 18. h3 b4 19. b3 Ba6 20. Rfd1 Rab8 21. Rbc1 f6 22. Bb1 fxg5 23. Nxg5 Rf5 24. Nxe6 Kh8 25. Nxf5 gxf5 0-1",
    B: "1395",
  },
  {
    W: "2103",
    T: "120+1",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Nb5 a6 6. Nd6+ Bxd6 7. Qxd6 Qf6 8. Qxf6 Nxf6 9. Bd3 d6 10. Nc3 Be6 11. Bg5 Nb4 12. O-O-O Rc8 13. Kb1 h6 14. Bxf6 gxf6 15. a3 Nc6 16. Be2 Ke7 17. Nd5+ Bxd5 18. exd5 Nd4 19. Bd3 f5 20. c3 e4 21. cxd4 exd3 22. Rxd3 Rc4 23. Rc1 Rhc8 24. Rdc3 b5 25. f4 a5 26. Rxc4 bxc4 27. Rc3 Kf6 28. Kc2 Re8 29. Kd2 Re4 30. Rxc4 Rxf4 31. Ke2 Re4+ 32. Kf2 f4 33. Ra4 h5 34. Rxa5 Rxd4 35. b4 Kf5 36. Rb5 Rd2+ 37. Kf3 Rd3+ 38. Kf2 Rxa3 39. Rb7 Ra2+ 40. Kf3 Ra3+ 41. Kf2 f6 42. Rh7 Ra2+ 43. Kf3 Ra3+ 44. Kf2 Rb3 45. Rxh5+ Ke4 46. Rh6 Rb2+ 47. Ke1 Rxg2 48. Rxf6 Rxh2 49. Rxd6 Rb2 50. Rf6 Rxb4 51. d6 Rd4 52. Re6+ Kf3 53. Rf6 Kg3 54. Rg6+ Kf3 55. Rf6 Re4+ 56. Kf1 Ra4 57. Kg1 Ra1+ 58. Kh2 Rd1 59. Rf8 Rxd6 60. Kg1 Rg6+ 61. Kf1 Rh6 62. Kg1 Ra6 63. Rf7 Ra1+ 64. Kh2 Ra4 65. Rf8 Kf2 66. Rf5 f3 67. Rf7 Rh4# 0-1",
    B: "2225",
  },
  {
    W: "1616",
    T: "120+1",
    P: "1. d4 d5 2. Bf4 e6 3. e3 Nf6 4. Nf3 Be7 5. Nbd2 b6 6. c3 Bb7 7. h3 Nbd7 8. g4 c5 9. g5 Ng8 10. h4 cxd4 11. exd4 a5 12. Ne5 Ba6 13. Bh3 Nxe5 14. dxe5 Bc5 15. h5 Ne7 16. Qf3 b5 17. O-O-O Qb6 18. Be3 Bxe3 19. fxe3 Rc8 20. Kb1 b4 21. Rc1 Bd3+ 22. Ka1 bxc3 23. Rxc3 Rxc3 24. bxc3 Kd7 25. g6 hxg6 26. hxg6 Nxg6 27. Qxf7+ Ne7 28. Qxg7 Rg8 29. Bxe6+ Qxe6 30. Qg2 Rxg2 0-1",
    B: "1609",
  },
  {
    W: "1316",
    T: "60+0",
    P: "1. e3 Nf6 2. b3 g6 3. Bb2 Bg7 4. f4 O-O 5. Nf3 Re8 6. Bd3 d6 7. O-O Nc6 8. c4 e5 9. fxe5 dxe5 10. Qe1 e4 11. Bxf6 exd3 12. Bxg7 Kxg7 13. Nc3 Bf5 14. Qg3 Qf6 15. Nd5 Qd6 16. Ng5 Qxg3 17. hxg3 Re5 18. Nf3 Re4 19. Ng5 Re5 20. Rf4 Rae8 21. Nf3 Rxe3 22. dxe3 Rxe3 23. Ne5 Rxe5 24. Ne3 Rxe3 25. Rff1 Re2 26. Rae1 Rxe1 27. Rxe1 Nd4 28. Rd1 Nc2 29. Kf2 Na3 30. Ke1 Nc2+ 31. Kf2 Nd4 32. Ke3 Ne6 33. Rxd3 Bxd3 34. Kxd3 Ng5 35. Kd4 Ne6+ 36. Ke5 c6 37. g4 b5 38. g5 bxc4 39. bxc4 c5 40. Kd6 a5 41. Kd5 a4 42. g3 a3 43. g4 Nf4+ 44. Kxc5 f6 45. Kb5 fxg5 46. c5 Kf6 47. c6 Ne6 48. c7 Nxc7+ 49. Kb4 Nd5+ 50. Kxa3 Ne3 51. Kb4 Nxg4 52. Kb5 Nf2 53. a4 g4 54. a5 g3 55. a6 g2 56. a7 1-0",
    B: "1366",
  },
  {
    W: "1247",
    T: "60+0",
    P: "1. d4 d5 2. e3 e5 3. Nf3 e4 4. Nfd2 Nf6 5. f3 g6 6. fxe4 dxe4 7. g3 Bg7 8. Bg2 O-O 9. Nxe4 Nxe4 10. Bxe4 Nd7 11. Nc3 Nf6 12. Qf3 Rb8 13. O-O b6 14. b4 Nxe4 15. Nxe4 Bb7 16. Bb2 Re8 17. Nd2 Bxf3 18. Nxf3 Rxe3 19. Bc1 Re8 20. Ng5 h6 21. Nxf7 Qc8 22. Nxh6+ Bxh6 23. Bxh6 Kh7 24. Bf4 Rf8 25. h4 Rb7 26. Rae1 c5 27. Re7+ Rxe7 28. dxc5 bxc5 29. bxc5 Qxc5+ 30. Kg2 Qc4 31. a4 Qxa4 32. c3 Qc2+ 33. Kf3 Qxc3+ 34. Kf2 Qc2+ 35. Kf3 Qa4 36. Kg2 Rb7 37. Kh3 Rb3 38. Kh2 Qa3 39. Kh3 Qa2 40. Kg4 Qe2+ 41. Rf3 Qa2 42. Rxb3 Qa6 43. Rb7+ Qxb7 44. Be5 Qb1 45. Kg5 Rb8 46. h5 Rb6 0-1",
    B: "1400",
  },
  {
    W: "1421",
    T: "300+0",
    P: "1. e4 a6 2. d4 e5 3. d5 Bc5 4. Nc3 Nf6 5. Nge2 Ng4 6. Be3 Bxe3 7. fxe3 Nxe3 8. Qd3 Qg5 9. g3 O-O 10. Nd1 Nxf1 11. Rxf1 d6 12. Ne3 Bh3 13. Rg1 Nd7 14. O-O-O Nc5 15. Qc3 Nxe4 16. Qxc7 Rab8 17. Nc3 Qxe3+ 0-1",
    B: "1437",
  },
  {
    W: "2045",
    T: "300+0",
    P: "1. d4 Nf6 2. Nf3 a6 3. Bf4 b5 4. e3 Bb7 5. Nbd2 d6 6. h3 Nbd7 7. Be2 Ne4 8. O-O f6 9. Nxe4 Bxe4 10. Nd2 Bb7 11. Bf3 Qc8 12. Bxb7 Qxb7 13. c4 e5 14. dxe5 dxe5 15. Bg3 Rd8 16. Qe2 Nc5 17. cxb5 axb5 18. Nb3 Na4 19. Rfc1 c5 20. Na5 Qb6 21. b4 cxb4 22. Nc6 Rc8 23. Qh5+ g6 24. Qf3 Bg7 25. Qd5 Nc3 26. Qe6+ Kf8 27. Qxc8+ Kf7 28. Qd7+ Kf8 29. Qd6+ Kf7 30. Nxe5+ 1-0",
    B: "1958",
  },
  {
    W: "1083",
    T: "300+0",
    P: "1. d4 e6 2. Nf3 Ne7 3. Bf4 b6 4. Nbd2 Bb7 5. e3 g6 6. c3 Bg7 7. Bd3 c5 8. h3 Ba6 9. Bxa6 Nxa6 10. O-O c4 11. Ne5 f6 12. Nef3 d6 13. Qa4+ b5 14. Qxa6 Qb6 15. Qxb6 axb6 16. Bxd6 Nd5 17. Ne4 Bh6 18. b3 f5 19. Ned2 Nxc3 20. bxc4 Rd8 21. Bc7 Rc8 22. Bxb6 bxc4 23. Ne5 Ne2+ 24. Kh1 c3 25. Nb3 O-O 26. Nd7 Rcd8 27. Nf6+ Kg7 28. Rfe1 Rb8 29. Bc5 Nxd4 30. Bxf8+ Kxf8 31. exd4 1-0",
    B: "1014",
  },
  {
    W: "1418",
    T: "300+0",
    P: "1. e4 c5 2. f4 Nc6 3. Nf3 e6 4. Bc4 h6 5. d3 d5 6. exd5 exd5 7. Bb5 Bd7 8. Bxc6 Bxc6 9. d4 Nf6 10. Be3 c4 11. Nc3 Bb4 12. O-O O-O 13. Ne5 Bd7 14. Qf3 Bf5 15. Rf2 Bxc3 16. bxc3 Ne4 17. Re2 Nxc3 18. Qh5 Nxe2+ 19. Qxe2 f6 20. Nf3 Re8 21. c3 Bd3 22. Qd2 Qd7 23. Ne1 Be4 24. Qb2 b6 25. Nc2 Qg4 26. g3 Bxc2 27. Qxc2 Rxe3 28. Qd2 Rae8 29. Rf1 R8e4 30. Kg2 Re2+ 31. Qxe2 Rxe2+ 32. Rf2 Rxf2+ 33. Kxf2 Qd1 34. Kg2 Qa1 35. h4 Qxc3 36. Kh3 Qa3 37. Kg4 Qxa2 38. h5 c3 39. Kh4 c2 40. g4 c1=Q 41. g5 Qg2 42. gxh6 Qch1# 0-1",
    B: "1379",
  },
  {
    W: "1546",
    T: "300+0",
    P: "1. e4 c5 2. Bc4 Nc6 3. c3 e6 4. Bb3 Na5 5. Bc2 b5 6. d4 c4 7. f4 Bb7 8. Nf3 Ne7 9. O-O g6 10. f5 exf5 11. exf5 gxf5 12. Nh4 Rg8 13. Rf2 Qc7 14. Nxf5 Nxf5 15. Bxf5 Nc6 16. Bg5 Be7 17. Bxe7 Nxe7 18. Bxh7 Rg7 19. Qh5 O-O-O 20. h3 Rh8 21. Rxf7 Rxg2+ 22. Kf1 Qh2 23. Qc5+ Bc6 24. Qe5 Qh1# 0-1",
    B: "1619",
  },
  {
    W: "1302",
    T: "300+0",
    P: "1. e4 e5 2. Nc3 Nf6 3. d3 d5 4. f4 d4 5. Nce2 Nc6 6. Nf3 Bd6 7. c3 Bg4 8. Nd2 dxc3 9. bxc3 exf4 10. h3 Bh5 11. Nb3 f3 12. gxf3 Bg3+ 13. Nxg3 Ne5 14. Nxh5 Nxh5 15. f4 Ng6 16. Qxh5 1-0",
    B: "1295",
  },
  {
    W: "1731",
    T: "300+0",
    P: "1. d4 d5 2. Nc3 Bf5 3. Bg5 h6 4. Bh4 g5 5. Bg3 Bg7 6. e3 e6 7. Bd3 Bxd3 8. Qxd3 Nf6 9. O-O-O O-O 10. h4 g4 11. Be5 h5 12. e4 dxe4 13. Nxe4 Nxe4 14. Bxg7 Kxg7 15. Qxe4 c6 16. Qe5+ Qf6 17. Qxh5 Qh6+ 18. Qxh6+ Kxh6 19. Ne2 Nd7 20. Nf4 Nf6 21. Rd3 Ne4 22. Rf1 Rae8 23. f3 gxf3 24. gxf3 Nf6 25. Rd2 Nd5 26. Nd3 b6 27. Rg2 Rg8 28. Rfg1 Rxg2 29. Rxg2 Ne3 30. Rg5 Nf5 31. Ne5 Nxh4 32. Nxf7+ Kh7 33. Nd6 Nxf3 34. Rg3 Rf8 35. Ne4 Nxd4 36. Ng5+ Kh6 37. Rg4 Rf1+ 38. Kd2 Nf3+ 39. Nxf3 Rxf3 40. Ke2 Rf7 41. Re4 Re7 42. c4 Kg5 43. Kf3 Kf5 44. Rf4+ Ke5 45. Ke3 Kd6 46. b4 1-0",
    B: "1730",
  },
  {
    W: "1665",
    T: "300+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 Nf6 5. Bd3 Be7 6. O-O O-O 7. c4 dxc4 8. Bxc4 Nc6 9. Nc3 Bd7 10. Be3 Rb8 11. h3 a6 12. Qd2 b5 13. Bb3 Bf5 14. Rad1 Ne4 15. Nxe4 Bxe4 16. Ne1 Bd6 17. a3 Qf6 18. d5 Ne5 19. Bf4 Qg6 20. g3 Bf3 21. Bxe5 Bxd1 22. Bxd6 cxd6 23. Bxd1 Rfe8 24. Bg4 Re5 25. Nf3 Re7 26. Nh4 Qe4 27. Bf3 Qc4 28. Nf5 Re5 29. Nxd6 Qc5 30. Ne4 Qxd5 31. Nf6+ 1-0",
    B: "1664",
  },
  {
    W: "1750",
    T: "300+0",
    P: "1. e4 e5 2. Nc3 Nc6 3. Bb5 Qf6 4. d3 h6 5. Nf3 Bc5 6. Nd5 Qd8 7. O-O d6 8. Be3 Nf6 9. Bxc5 dxc5 10. Nxe5 O-O 11. Nxf6+ Qxf6 12. Nxc6 bxc6 13. Bc4 Qxb2 14. Rb1 Qd4 15. Kh1 Kh7 16. f4 Be6 17. Bxe6 fxe6 18. Qg4 e5 19. f5 c4 20. Qg6+ Kh8 21. Rf3 Rab8 22. Rbf1 Rb2 23. Rg3 Rg8 24. f6 Qd7 25. fxg7+ Rxg7 26. Qf6 Rxc2 27. Qf8+ Kh7 28. Rxg7+ Qxg7 29. Qxg7+ Kxg7 30. dxc4 Rxa2 31. g3 Rc2 32. Rf5 a5 33. Rxe5 a4 34. Ra5 Rxc4 35. Kg2 Kf6 36. Kf3 c5 37. Ke3 Ke5 38. Kd3 Rd4+ 39. Ke3 Rxe4+ 40. Kf3 Kd5 41. g4 Rb4 42. h3 Kd4 43. h4 c4 44. g5 hxg5 45. hxg5 c3 46. g6 c2 47. g7 Rb8 48. g8=Q Rxg8 49. Rxa4+ Kc3 50. Ra1 Kb2 51. Rf1 c1=Q 52. Rxc1 Kxc1 53. Ke4 Rd8 0-1",
    B: "1752",
  },
  {
    W: "1313",
    T: "300+0",
    P: "1. e4 e6 2. Bc4 d5 3. exd5 exd5 4. Bd3 h5 5. Ne2 Qe7 6. Nc3 d4 7. Nd5 Qe5 8. Bc4 c6 9. f4 Qe4 10. Nc7+ Kd7 11. Nxa8 Qxg2 12. Rf1 b5 13. Bxf7 c5 14. d3 Qxa8 15. f5 Nf6 16. Nf4 Ba6 17. Be6+ Ke7 18. Qe2 Kd8 19. Bb3 c4 20. dxc4 bxc4 21. Bxc4 Bxc4 22. Qxc4 Qe4+ 23. Ne2 Ng4 24. Bg5+ Be7 25. Bxe7+ Qxe7 26. O-O-O Qg5+ 27. Nf4 Ne3 28. Rxd4+ Nd7 29. Ne6+ Ke7 30. Qb4+ Kf6 31. Nxg5 Nxf1 32. Ne4+ Kxf5 33. Qb5+ Ke6 34. Qd5+ Ke7 35. Qxd7+ Kf8 36. Rd6 Kg8 37. Rg6 Kh7 38. Qxg7# 1-0",
    B: "1307",
  },
  {
    W: "1507",
    T: "300+0",
    P: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. Nc3 Nc6 5. Be3 Nf6 6. Bb5 Bf5 7. Bxc6+ bxc6 8. Nf3 e6 9. O-O Be7 10. Ne5 O-O 11. Nxc6 Qc7 12. Nxe7+ Qxe7 13. Bg5 Rab8 14. Bxf6 Qxf6 15. Qd2 Rxb2 16. a4 Rxc2 17. Qe3 Rb2 18. Nb5 Rc8 19. Nxa7 Ra8 20. Nc6 Qh6 21. Ne7+ Kf8 22. Nxf5 exf5 23. Rfe1 Qxe3 24. Rxe3 Re8 25. Rxe8+ Kxe8 26. a5 Kd7 27. a6 Rb8 28. h3 Kc6 29. Rc1+ Kb5 30. a7 Ra8 31. Rc5+ Kb6 32. Rxd5 Rxa7 33. Rxf5 f6 34. g4 Kc6 35. Rc5+ Kd6 36. f4 Ra4 37. Rh5 h6 38. d5 Rxf4 39. h4 Rxg4+ 40. Kf2 Rd4 0-1",
    B: "1482",
  },
  {
    W: "1172",
    T: "300+0",
    P: "1. d4 d5 2. e3 Nc6 3. Bb5 a6 4. Bxc6+ bxc6 5. Nf3 Nf6 6. O-O e6 7. Nc3 c5 8. dxc5 Bxc5 9. b3 e5 10. Nxe5 Bg4 11. Qd3 Bh5 12. e4 d4 13. Ne2 Bg6 14. Nc6 Qd6 15. Na5 O-O 16. f3 Rab8 17. Bb2 Rfe8 18. Bxd4 Bxd4+ 19. Nxd4 Rb4 20. Ndc6 Rd4 21. Nxd4 Qb6 22. Nc4 Qc5 23. g4 Rd8 24. Rad1 h6 25. h4 h5 26. Kg2 hxg4 27. fxg4 Nxg4 28. Ne3 Ne5 29. Qxa6 Bxe4+ 30. Kh3 Qc3 31. Rfe1 Bf3 32. Rd3 Qxe1 33. Nc6 Qh1+ 34. Kg3 Rd6 35. Rxd6 cxd6 36. Qc8+ Kh7 37. Qf5+ Kg8 38. Ne7+ Kh8 39. Qh5+ Bxh5 0-1",
    B: "1129",
  },
  {
    W: "1211",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 d6 4. O-O g6 5. c3 Bg7 6. d4 Nf6 7. d5 Ne7 8. Be3 O-O 9. Qd2 Nxe4 10. Qc1 Nf5 11. Nh4 Qxh4 12. g3 Nfxg3 13. fxg3 Nxg3 14. hxg3 Qxg3+ 15. Kh1 Bh3 16. Rg1 Qf3+ 17. Kh2 Bf5 18. Bh6 Qh3# 0-1",
    B: "1206",
  },
  {
    W: "1874",
    T: "300+0",
    P: "1. e4 d5 2. e5 Bf5 3. d3 e6 4. Be2 Nc6 5. d4 Nb4 6. Na3 Qd7 7. h4 Qc6 8. g4 Nxc2+ 9. Nxc2 Bxc2 10. Qd2 Ba4 11. b3 Bb5 12. Bb2 Qb6 13. Rc1 Bxe2 14. Qxe2 Bb4+ 15. Kf1 Ne7 16. Rh3 Ng6 17. Nf3 Nf4 18. Qe3 Nxh3 19. Ng5 Nxg5 20. Qxg5 O-O 21. h5 h6 22. Qf4 f5 23. g5 Qb5+ 24. Kg2 Qe8 25. g6 Qe7 26. a3 Ba5 27. b4 Bb6 28. Rc2 Qg5+ 29. Qxg5 hxg5 30. a4 c6 31. f3 g4 32. fxg4 fxg4 33. Kg3 Rf5 34. Kxg4 Raf8 35. Bc1 Bxd4 36. Re2 Rxe5 37. Rxe5 Bxe5 38. b5 cxb5 39. axb5 Bd4 40. Bg5 Bf6 41. Be3 d4 42. Bd2 Be7 43. Bc1 d3 44. Bd2 Bc5 45. Kg5 Rf5+ 46. Kg4 Rf2 47. Be1 Rf1 48. Bd2 a6 49. bxa6 bxa6 50. h6 gxh6 51. Bxh6 Rg1+ 52. Kf3 Rxg6 53. Bd2 Kf7 54. Ke4 Rg3 55. Kf4 Rg1 56. Ke4 Kg6 57. Kxd3 Rg3+ 58. Kc4 Bg1 59. Kb4 Kf5 60. Ka5 e5 61. Kxa6 Rd3 62. Bh6 e4 63. Kb5 e3 64. Kc4 Ra3 65. Kb4 Ra8 66. Kc3 Re8 67. Kc2 Kg4 68. Kd1 Kf3 69. Ke1 Ra8 70. Kf1 Bf2 71. Bg7 e2# 0-1",
    B: "1925",
  },
  {
    W: "1836",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 h6 5. c3 Bc5 6. h3 d6 7. O-O Be6 8. Nbd2 Qd7 9. a4 Bxh3 10. gxh3 Qxh3 11. Nh2 h5 12. Qf3 Qd7 13. Bb5 O-O-O 14. b4 a6 15. Bxc6 Qxc6 16. bxc5 dxc5 17. Nc4 Qe6 18. Be3 g5 19. Bxc5 g4 20. Qf5 Rdg8 21. Qxe6+ fxe6 22. Nxe5 g3 23. Nhf3 g2 24. Rfe1 h4 25. Nf7 Rh7 26. N7g5 Rh5 27. Nh3 Nd7 28. Be3 Nf6 29. Rab1 Ng4 30. Rb4 Nxe3 31. Rxe3 a5 32. Rd4 c5 33. Rd6 Rg6 34. Nf4 h3 35. Nxg6 Rh6 36. Nf4 h2+ 37. Nxh2 Kc7 38. e5 b6 39. Nxg2 Rh4 40. Rg3 Rxa4 41. Rg7+ Kc8 42. Rxe6 Kd8 43. Rh6 Ra1+ 44. Nf1 1-0",
    B: "1880",
  },
  {
    W: "1682",
    T: "420+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 d6 4. d4 Bg4 5. Bxf4 Bxf3 6. Qxf3 Nf6 7. Bc4 Be7 8. e5 dxe5 9. dxe5 Nfd7 10. e6 fxe6 11. Bxe6 Bf6 12. Nc3 Qe7 13. Qe2 Bxc3+ 14. bxc3 Na6 15. O-O O-O-O 16. Rab1 Nc5 17. Bxd7+ Nxd7 18. Qxe7 1-0",
    B: "1564",
  },
  {
    W: "1192",
    T: "180+0",
    P: "1. d4 c6 2. Nf3 d5 3. Bf4 h6 4. e3 Bg4 5. h3 Bh5 6. g4 Bg6 7. Ne5 e6 8. Nxg6 fxg6 9. Bd3 g5 10. Be5 c5 11. c3 c4 12. Bc2 Nc6 13. Bg3 Nf6 14. Nd2 Bd6 15. Bxd6 Qxd6 16. Qf3 O-O 17. O-O-O a6 18. b3 cxb3 19. axb3 b5 20. c4 Nb4 21. cxd5 Rac8 22. Ne4 Qc7 23. Nxf6+ Rxf6 24. Qe4 Nxc2 25. dxe6 Nxe3+ 26. Kb2 Rxf2+ 27. Ka3 Qa5# 0-1",
    B: "1144",
  },
  {
    W: "1360",
    T: "180+0",
    P: "1. e4 e5 2. Bc4 Nf6 3. Nc3 Nxe4 4. Nxe4 d5 5. Qe2 dxc4 6. Qxc4 Be6 7. Qb5+ Nd7 8. Qxb7 Be7 9. Nf3 O-O 10. O-O Bf5 11. d3 Bg4 12. Be3 Rb8 13. Qxa7 Bxf3 14. gxf3 Rxb2 15. Nc5 Bxc5 16. Bxc5 Qg5+ 17. Kh1 Rfb8 18. Qxc7 Nf6 19. Be7 Qf4 20. Bxf6 Qxf3+ 21. Kg1 gxf6 22. Rae1 R8b4 23. c4 Rxa2 24. Re3 Qf4 25. Rg3+ 1-0",
    B: "1452",
  },
  {
    W: "1621",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 g6 3. Bg5 Bg7 4. Nf3 d6 5. Nc3 O-O 6. e3 Nc6 7. Bd3 e5 8. d5 Ne7 9. Bxf6 Bxf6 10. e4 Bg7 11. Ne2 f5 12. exf5 Bxf5 13. Bxf5 Nxf5 14. Qc2 Qf6 15. Ng3 Nxg3 16. fxg3 Rae8 17. O-O Qe7 18. Rab1 e4 19. Nd2 Bd4+ 20. Kh1 Rxf1+ 21. Rxf1 Rf8 22. Rxf8+ Qxf8 23. Nxe4 Qf1# 0-1",
    B: "1607",
  },
  {
    W: "1308",
    T: "180+0",
    P: "1. d4 f5 2. e3 e6 3. Ne2 b6 4. Bd2 Bb7 5. Nbc3 Nf6 6. f3 Bb4 7. a3 Qe7 8. axb4 Qxb4 9. Nb1 Qxb2 10. Bc3 Qb5 11. Nf4 Qc4 12. Bxc4 1-0",
    B: "1384",
  },
  {
    W: "1681",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 a6 5. d4 exd4 6. cxd4 Bb4+ 7. Nc3 b5 8. Bb3 d6 9. O-O Be6 10. Bg5 Nge7 11. Bxe6 fxe6 12. d5 exd5 13. Nxd5 Bc5 14. Rc1 Qd7 15. Re1 O-O 16. Qb3 Kh8 17. Rcd1 Nxd5 18. exd5 Ne5 19. Nxe5 Bxf2+ 20. Kh1 dxe5 21. Rxe5 Qg4 22. Be7 Rfe8 23. Rf1 Bc5 24. Qe3 Bxe3 25. Rxe3 h6 26. Rg3 Qd7 27. Rf7 Rxe7 28. Rxe7 Qxe7 29. h3 Rd8 30. Kh2 Rxd5 31. Rf3 Qe5+ 32. Rg3 Rd3 33. Kg1 Qxg3 34. Kf1 Rd2 35. h4 Qxg2+ 36. Ke1 Qe2# 0-1",
    B: "1636",
  },
  {
    W: "1546",
    T: "180+0",
    P: "1. d4 d5 2. c4 c5 3. Nc3 cxd4 4. Qxd4 Nc6 5. Qxd5 Bd7 6. b3 e6 7. Qd1 Bb4 8. Bd2 Nf6 9. Nf3 O-O 10. e3 e5 11. Qc2 e4 12. Ng1 Qe7 13. Nge2 Ng4 14. h3 Nf6 15. Nf4 Ne5 16. Be2 Bc6 17. O-O Rad8 18. Rfd1 Bxc3 19. Bxc3 Rxd1+ 20. Rxd1 Ng6 21. Nxg6 fxg6 22. b4 Rd8 23. b5 Be8 24. a4 b6 25. Rxd8 Qxd8 26. Bxf6 Qxf6 27. Kh2 Qe5+ 28. g3 Bd7 29. Qd2 Qe6 30. Qd4 Qxh3+ 31. Kg1 Be6 32. Qxe4 Qf5 33. Qa8+ Kf7 34. Qxa7+ Kf6 35. Qxb6 Qb1+ 36. Kg2 Qe4+ 37. Bf3 Qd3 38. Qd4+ Qxd4 39. exd4 Bxc4 40. Bd1 Bd5+ 41. f3 Kf5 42. g4+ Ke6 43. Bc2 g5 44. Bxh7 Bc4 45. Bg8+ Kd6 46. Bxc4 1-0",
    B: "1498",
  },
  {
    W: "2077",
    T: "180+0",
    P: "1. c4 f5 2. Nc3 Nf6 3. g3 e6 4. Bg2 c6 5. d4 d5 6. Nf3 Be7 7. O-O O-O 8. b3 b6 9. Qc2 Ba6 10. Ne5 Nbd7 11. Rd1 Qc7 12. Bb2 Nxe5 13. dxe5 Qxe5 14. Nxd5 Nxd5 15. Bxe5 Nb4 16. Qb2 Rac8 17. a3 1-0",
    B: "2056",
  },
  {
    W: "1962",
    T: "180+0",
    P: "1. e4 c5 2. c4 Nc6 3. Nc3 g6 4. d3 Bg7 5. Be3 b6 6. h3 e6 7. Nf3 Nge7 8. Be2 O-O 9. O-O d5 10. cxd5 exd5 11. exd5 Nxd5 12. Nxd5 Qxd5 13. Qc2 Bf5 14. a3 Rad8 15. Rad1 Ne5 16. Nxe5 Bxe5 17. Bf3 Qe6 18. Be4 Bxh3 19. gxh3 Qxh3 20. f4 Qxe3+ 21. Qf2 Qxf2+ 22. Rxf2 Bd4 23. Kg2 Bxf2 24. Kxf2 Rfe8 25. f5 Rxe4 26. dxe4 Rxd1 27. fxg6 hxg6 0-1",
    B: "1899",
  },
  {
    W: "1835",
    T: "180+0",
    P: "1. d4 e6 2. c4 c6 3. Nc3 d5 4. Nf3 Nf6 5. e3 Bd6 6. Bd3 b6 7. e4 dxe4 8. Nxe4 Nxe4 9. Bxe4 O-O 10. Qc2 f5 11. Bd3 Qf6 12. O-O Nd7 13. Re1 h6 14. Ne5 Bxe5 15. dxe5 Nxe5 16. Bf1 Ng4 17. h3 Ne5 18. Bf4 Ng6 19. Bd2 e5 20. Bc3 Bd7 21. Rad1 Rad8 22. g3 Be6 23. b3 f4 24. gxf4 Nxf4 25. Kh2 Qh4 26. Re3 Rxd1 27. Qxd1 Rd8 28. Qf3 Ng6 29. Bd3 Rf8 30. Qg3 Rxf2+ 31. Kg1 Qxg3+ 32. Rxg3 Nf4 33. Be4 Rxa2 34. Bxe5 Ne2+ 35. Kf2 Nxg3+ 36. Kxg3 Re2 37. Kf3 Re1 38. Bg3 Rf1+ 39. Ke2 Bxh3 40. Bxc6 Rb1 41. Bd5+ Kh7 42. Bb8 Rb2+ 43. Kd3 Rxb3+ 44. Kd4 Rb2 45. Bxa7 g5 46. Be4+ Kg7 47. Kd5 g4 48. Bb8 b5 49. Be5+ Kf8 50. Bd6+ Ke8 51. Kc5 Kd7 52. cxb5 Rxb5+ 53. Kxb5 Kxd6 54. Kc4 Ke5 55. Kd3 Kf4 56. Bh1 g3 57. Ke2 g2 58. Bxg2 Bxg2 59. Kf2 Bf3 60. Kg1 Kg4 61. Kh2 Kh4 62. Kg1 Kh3 63. Kf2 Bg4 64. Ke3 Kh2 65. Kf4 Bh3 66. Kf3 Bg2+ 67. Kf4 Kg1 68. Kg3 h5 69. Kh4 Bf3 70. Kg3 Bg4 71. Kh4 Kg2 72. Kg5 Kg3 73. Kf6 h4 74. Ke7 h3 75. Kd6 h2 76. Kc5 h1=Q 77. Kb4 Qf3 78. Kc5 1/2-1/2",
    B: "1890",
  },
  {
    W: "1226",
    T: "180+0",
    P: "1. e4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. d3 d5 5. Bg5 Be7 6. Bxf6 Bxf6 7. exd5 Nd4 8. Nxd4 exd4 9. Ne4 Bf5 10. Nxf6+ Qxf6 11. Be2 O-O 12. O-O Rae8 13. c3 Re5 14. cxd4 Rxd5 15. Rc1 Rxd4 16. Rxc7 Qh6 17. Rxb7 Rh4 18. h3 Bxh3 19. g3 Bxf1 20. gxh4 Qxh4 21. Qxf1 Qg5+ 22. Qg2 Qxg2+ 23. Kxg2 Re8 24. Rxa7 h6 25. Bf3 g5 26. Be4 h5 27. Bc6 Re6 28. Bd5 Re2 29. Bxf7+ Kf8 30. Bxh5 Rxb2 31. d4 Rd2 32. a4 Rxd4 33. a5 Kg8 34. a6 Rf4 35. Ra8+ Kg7 36. a7 g4 37. Rd8 Rf5 38. Bxg4 Rg5 39. a8=Q Rxg4+ 40. Kf1 1-0",
    B: "1237",
  },
  {
    W: "2221",
    T: "180+0",
    P: "1. d4 Nf6 2. Nf3 e6 3. Bg5 d5 4. e3 h6 5. Bh4 Be7 6. Nbd2 O-O 7. Bd3 Nbd7 8. Qe2 Re8 9. O-O Nf8 10. c3 b6 11. e4 Bb7 12. e5 N6d7 13. Bg3 c5 14. Rfc1 Rc8 15. Ne1 f6 16. exf6 Bxf6 17. f4 cxd4 18. Ndf3 dxc3 19. b3 c2 20. Ne5 Nxe5 21. fxe5 Bg5 22. Rxc2 Rxc2 23. Bxc2 d4 24. Nf3 Qd5 25. Nxg5 hxg5 26. Re1 Qc5 27. Qd3 Rc8 28. Bb1 Qc3 29. Bf2 Qxd3 30. Bxd3 Rd8 31. Rc1 Rd7 32. Rc4 Ba6 33. Kf1 Bxc4 34. bxc4 Kf7 35. Ke2 Ng6 36. Bg3 Nf4+ 37. Bxf4 gxf4 38. Kf3 g5 39. h4 gxh4 40. Kxf4 Rd8 41. Kg4 Rh8 42. Kf4 h3 43. gxh3 Rxh3 44. Ke4 Rh4+ 45. Kf3 Ke7 46. a4 Kd7 47. Be4 Kc7 48. Ba8 d3 49. Ke3 Rxc4 50. Kxd3 Rxa4 51. Bg2 Ra5 52. Kd4 Ra2 53. Ba8 Rh2 54. Bf3 a5 55. Be4 Rd2+ 56. Kc3 Rd8 57. Bc2 b5 58. Bd3 Kb6 59. Bc2 b4+ 60. Kb3 Kb5 61. Bd3+ Rxd3+ 62. Kc2 a4 63. Kxd3 b3 64. Kc3 Ka5 65. Kb2 Kb4 66. Kb1 a3 67. Kc1 a2 68. Kd2 a1=Q 69. Kd3 1-0",
    B: "2126",
  },
  {
    W: "2089",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 d6 3. d4 Nc6 4. dxe5 Nxe5 5. Nxe5 dxe5 6. Qxd8+ Kxd8 7. Bc4 f6 8. Nc3 Bc5 9. f4 exf4 10. Bxf4 c6 11. O-O-O+ Ke7 12. a3 a5 13. Na4 Ba7 14. Bd6+ Ke8 15. Bc7 Ne7 16. Rd8# 1-0",
    B: "2083",
  },
  {
    W: "1356",
    T: "180+0",
    P: "1. e4 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qc5 d6 5. Qd5 Be6 6. Qd3 Nb4 7. Qb5+ Nc6 8. Nc3 Rb8 9. Nd5 Bxd5 10. Qxd5 e6 11. Qb3 g6 12. Bb5 Bg7 13. Nf3 Nf6 14. Bg5 Nxe4 15. Bxd8 Rxd8 16. Bxc6+ bxc6 17. Nd4 O-O 18. Nxc6 Rc8 19. Nxa7 Rc7 20. Nb5 Rc5 21. c4 Re5 22. O-O-O Nxf2 23. Rhf1 Nxd1 24. Qxd1 Rc8 25. Qxd6 Rxb5 26. Kb1 Rxc4 27. Qd8+ Bf8 28. b3 Rd5 29. bxc4 Rxd8 30. Kb2 Rb8+ 31. Kc3 Bb4+ 32. Kd4 Rd8+ 33. Ke5 Bd6+ 34. Ke4 Bxh2 35. Rh1 Bb8 36. a4 Rc8 37. Kd4 Rd8+ 38. Kc5 Rd2 39. Kc6 Ra2 40. Kb5 Rxg2 41. a5 Bc7 42. a6 Rb2+ 43. Kc6 Bf4 44. Rf1 Ra2 45. Kb7 Be3 46. Re1 Bd2 47. Rd1 Be3 48. a7 Bxa7 49. Rd8+ Kg7 50. c5 Bxc5 51. Kc6 Rc2 52. Kb5 Ba3 53. Ka4 Rb2 54. Kxa3 1-0",
    B: "1326",
  },
  {
    W: "2522",
    T: "180+0",
    P: "1. e4 e6 2. d4 d5 3. Nd2 Nf6 4. e5 Nfd7 5. Bd3 c5 6. c3 Nc6 7. Ne2 cxd4 8. cxd4 f6 9. exf6 Nxf6 10. Nf3 Bd6 11. O-O O-O 12. Bg5 Bd7 13. Bh4 Be8 14. Bg3 Bh5 15. Nf4 Bxf4 16. Bxf4 Bxf3 17. Qxf3 Nxd4 18. Qh3 Ne4 19. Be5 Nf5 20. Rad1 Qh4 21. Qxh4 Nxh4 22. f3 Nf6 23. Rfe1 Kf7 24. Kf2 Ng6 25. Bd4 b6 26. Ba6 Rfe8 27. g4 e5 28. Bc3 d4 29. Bc4+ Ke7 30. Bb4+ Kd7 31. Bf7 Nf4 32. Bxe8+ Rxe8 33. h4 N6d5 34. Ba3 Ne3 35. Rxe3 1-0",
    B: "2459",
  },
  {
    W: "1713",
    T: "600+0",
    P: "1. e4 d5 2. exd5 c6 3. dxc6 Nf6 4. d4 0-1",
    B: "1853",
  },
  {
    W: "2141",
    T: "30+0",
    P: "1. g3 d5 2. Bg2 Bh3 3. Nxh3 h5 4. O-O Nc6 5. d3 e5 6. e4 dxe4 7. dxe4 Qxd1 8. Rxd1 Bd6 9. Nc3 O-O-O 10. Nb5 f6 11. Nxd6+ cxd6 12. f4 Nge7 13. fxe5 dxe5 14. Rxd8+ Rxd8 15. Be3 Nd4 16. Bxd4 Rxd4 17. Bf3 Rd2 18. Bd1 g6 19. Nf2 Nc6 20. Nd3 Nd4 21. Kf1 Nxc2 22. Bxc2 Rxc2 23. Rc1 Rxc1+ 24. Nxc1 Kd7 25. Nd3 Ke6 26. Ke2 f5 27. Kf3 fxe4+ 28. Kxe4 b5 29. Nxe5 a5 30. Nxg6 Kf6 31. Nf4 Kg5 32. Nxh5 Kxh5 33. Kf4 b4 34. h3 Kg6 35. g4 Kf6 36. h4 Kg7 37. g5 Kh7 38. Kf5 Kg7 39. h5 Kh7 40. b3 Kg8 41. g6 a4 42. Kf6 axb3 43. axb3 Kh8 44. h6 Kg8 0-1",
    B: "2152",
  },
  {
    W: "2079",
    T: "15+0",
    P: "1. d4 Nf6 2. Bg5 g6 3. Bxf6 exf6 4. e3 Bg7 5. c4 O-O 6. Nc3 d5 7. h4 dxc4 8. h5 g5 9. Bxc4 h6 10. Bd3 Bf5 11. Bb1 Bd3 12. Qxd3 f5 13. Qxf5 f6 14. Qh7+ Kf7 15. Bg6+ Ke7 16. Qxg7+ Kd6 17. Nb5+ Kc6 18. O-O-O Kxb5 19. Kb1 Ka6 20. Bd3+ b5 21. Rc1 c6 22. a4 Qb6 23. axb5+ Qxb5 24. Bxb5+ cxb5 25. Qc7 Nc6 26. Qxc6+ Ka5 27. Rc3 Rab8 28. Ra3+ Kb4 29. Qc3# 1-0",
    B: "2002",
  },
  {
    W: "1795",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 Nf6 5. Ng5 O-O 6. a3 h6 7. h4 d6 8. Qf3 Nd4 9. Qg3 Nxc2+ 10. Kf1 Nxa1 11. Nc3 Nc2 12. Nd5 Nxd5 13. Bxd5 Qe7 14. b4 Bxb4 15. axb4 Nxb4 16. Bb3 Be6 17. Nxe6 fxe6 18. Bxh6 Nxd3 19. Bg5 Qf7 20. f3 Nf4 21. h5 Nxh5 22. Qh4 g6 23. Qg4 d5 24. exd5 exd5 25. Qh4 c6 26. g4 Qxf3+ 27. Ke1 Qg3+ 28. Qxg3 Nxg3 29. Bh6 Nxh1 30. Bxf8 Rxf8 0-1",
    B: "1768",
  },
  {
    W: "1437",
    T: "60+0",
    P: "1. e3 Nf6 2. d4 d5 3. Nc3 Bf5 4. Bd3 Bxd3 5. Qxd3 e6 6. Bd2 c5 7. Nf3 Bd6 8. dxc5 Bxc5 9. O-O O-O 10. Rfe1 Nc6 11. Nd4 Bxd4 12. exd4 Ne4 13. Nxe4 dxe4 14. Qxe4 Nxd4 15. Be3 Nc6 16. Rad1 Qa5 17. a3 Rad8 18. Qg4 Rd5 19. Qg3 Rfd8 20. Rxd5 Qxe1# 0-1",
    B: "1456",
  },
  {
    W: "2255",
    T: "60+0",
    P: "1. g3 Nf6 2. Bg2 g6 3. d3 Bg7 4. Nc3 c6 5. Bg5 d5 6. Qd2 d4 7. Ne4 Nxe4 8. dxe4 h6 9. Bf4 e5 10. Bxe5 Bxe5 11. f4 Bg7 12. Nf3 O-O 13. e5 c5 14. O-O-O Nc6 15. e4 Qb6 16. h3 Be6 17. g4 Bxa2 18. f5 d3 19. f6 dxc2 20. Qxc2 Nb4 21. fxg7 Nxc2 22. gxf8=Q+ Rxf8 23. Kxc2 Qb3+ 24. Kc1 Qe3+ 25. Rd2 c4 26. Re1 Qf4 27. Kc2 Bb3+ 28. Kb1 Qg3 29. Rc1 b5 30. Nd4 a5 31. Nxb3 cxb3 32. Rcd1 a4 33. Bf1 Qf4 34. Bxb5 Qxe4+ 35. Bd3 Qb4 36. e6 Kg7 37. exf7 Rd8 38. Bxg6 Rxd2 39. Rf1 Qf8 40. Bh5 a3 41. bxa3 Ra2 42. h4 Rxa3 43. g5 Ra2 44. gxh6+ Kxh6 0-1",
    B: "2354",
  },
  {
    W: "1782",
    T: "60+0",
    P: "1. e4 d5 2. Nf3 1-0",
    B: "1802",
  },
  {
    W: "1601",
    T: "60+0",
    P: "1. e4 d5 2. Nf3 dxe4 3. Ng5 h6 4. Nxe4 Nf6 5. Nxf6+ exf6 6. Qf3 Nc6 7. b3 Bb4 8. Bb2 Nd4 9. Qe4+ Qe7 10. Qxe7+ Bxe7 11. Bxd4 c5 12. Bc3 Bf5 13. d3 b5 14. a3 O-O 15. Nd2 Rad8 16. Nf3 c4 17. bxc4 bxc4 18. Nh4 Bh7 19. Be2 g5 20. Nf3 g4 21. Nd4 Bc5 22. Nb3 Rd6 23. Nxc5 cxd3 24. Nxd3 Rc6 25. Bb2 Rxc2 26. Bxg4 Bxd3 27. Bxf6 Re8+ 28. Kd1 Rc4 29. Re1 Rcc8 30. Rxe8+ Rxe8 31. Kd2 Bg6 32. Re1 Rc8 33. Re7 1-0",
    B: "1612",
  },
  {
    W: "1668",
    T: "60+0",
    P: "1. e4 d5 2. e5 Nc6 3. f3 Nxe5 4. d4 Nc6 5. a3 e5 6. c3 exd4 7. cxd4 Nf6 8. Bb5 Be7 9. Bxc6+ bxc6 10. Qc2 O-O 11. Qxc6 Rb8 12. g4 Bb7 13. Qc3 Re8 14. Ne2 h5 15. gxh5 Nxh5 16. f4 c5 17. dxc5 d4 18. Qxd4 Bxh1 19. Qg1 Bd5 20. f5 Bxc5 21. Qxc5 Bf3 22. Qg1 Rxe2+ 23. Kf1 Qd1# 0-1",
    B: "1670",
  },
  {
    W: "1487",
    T: "60+0",
    P: "1. e4 g6 2. Nc3 Bg7 3. d3 a6 4. Nf3 e6 5. Be2 Ne7 6. O-O c6 7. Bg5 b5 8. Qd2 Bb7 9. Bh6 Bxh6 10. Qxh6 Qc7 11. Ng5 d6 12. Qg7 Nc8 13. Qxh8+ Kd7 14. Qg7 Ne7 15. Qxf7 c5 16. Qxh7 d5 17. exd5 e5 18. Bg4+ Kd8 19. Ne6+ Kc8 20. Nxc7+ Kxc7 21. Qxe7+ Nd7 22. Qxe5+ Kb6 23. Qg7 Ne5 24. Qxe5 1-0",
    B: "1550",
  },
  {
    W: "1912",
    T: "60+0",
    P: "1. e4 g6 2. d4 Bg7 3. f4 d6 4. Nf3 Bg4 5. c4 Nd7 6. Nc3 c5 7. d5 Bxc3+ 8. bxc3 Qa5 9. Qd3 f5 10. e5 dxe5 11. fxe5 Bxf3 12. Qxf3 Nxe5 13. Qe3 Nxc4 14. Bxc4 Nf6 15. Bd2 O-O 16. O-O Kg7 17. Qe5 Rac8 18. Bg5 Qc7 19. Qxc7 Rxc7 20. Bxf6+ Kxf6 21. Rae1 Re8 22. Re6+ Kf7 23. Rfe1 a6 24. d6 Rd7 25. Rxe7+ Kf8 26. Rxe8+ Kg7 27. R1e7+ Rxe7 28. Rxe7+ Kh6 29. Rf7 1-0",
    B: "1838",
  },
  {
    W: "1387",
    T: "60+0",
    P: "1. e4 e6 2. e5 d5 3. d4 c5 4. Nf3 Nc6 5. Bb5 Bd7 6. Bxc6 Bxc6 7. Be3 Ne7 8. dxc5 Nf5 9. Qd3 Nxe3 10. Qxe3 Qa5+ 11. Nc3 Bxc5 12. Nd4 Qb6 13. Nce2 Qxb2 14. O-O Bxd4 15. Nxd4 Rc8 16. Rfb1 Qa3 17. Nxc6 Rxc6 18. Rxb7 O-O 19. Rxa7 Qc3 20. h3 Qxa1+ 21. Kh2 Qc3 22. Qxc3 Rxc3 23. a4 Rxc2 24. a5 Rc5 0-1",
    B: "1433",
  },
  {
    W: "875",
    T: "60+0",
    P: "1. e3 d5 2. d3 d4 3. Nf3 dxe3 4. Bxe3 Bg4 5. Be2 Bxf3 6. O-O Bxg2 7. Bf3 Bxf1 8. Bg2 Bxg2 9. Kxg2 Qd5+ 0-1",
    B: "1011",
  },
  {
    W: "2039",
    T: "60+0",
    P: "1. d4 e6 2. c4 d5 3. Nc3 c6 4. g3 g6 5. Bg2 Bg7 6. Nf3 Ne7 7. O-O O-O 8. Re1 Nf5 9. Bg5 f6 10. Bf4 Nd7 11. cxd5 cxd5 12. Rc1 Nb6 13. e4 dxe4 14. Nxe4 Bd7 15. Bc7 Qe8 16. g4 Ne7 17. Nd6 Ba4 18. b3 Bxb3 19. axb3 Ned5 20. Nxe8 Raxe8 21. Bxb6 Nxb6 22. Rc7 Rf7 23. Rxf7 Kxf7 24. g5 f5 25. Ne5+ Bxe5 26. dxe5 Nd5 27. Bxd5 exd5 28. Qxd5+ Kg7 29. Rc1 1-0",
    B: "1996",
  },
  {
    W: "2300",
    T: "180+0",
    P: "1. Nf3 Nf6 2. d4 c5 3. c4 cxd4 4. Nxd4 g6 5. Nc3 Bg7 6. e4 O-O 7. Be3 d6 8. f3 a6 9. Qd2 Bd7 10. a3 Bc6 11. Rc1 e5 12. Nc2 Qe7 13. Be2 Nbd7 14. O-O Rab8 15. b4 b5 16. cxb5 Bxb5 17. Nxb5 axb5 18. Rfd1 Nh5 19. Qxd6 Qxd6 20. Rxd6 Ndf6 21. Rcd1 Rfc8 22. Ne1 Nf4 23. Bf1 Ne6 24. Nd3 Rc3 25. Bc1 Bf8 26. Rxe6 fxe6 27. Nxe5 Rbc8 28. Bg5 Be7 29. Bxb5 R8c7 30. Bf4 Rxa3 31. Nc4 Raa7 32. Bxc7 Rxc7 33. Nd6 Rc2 34. Bc4 Rb2 35. Bxe6+ Kf8 36. e5 Nh5 37. g3 Rxb4 38. f4 Rb2 39. Bg4 Ng7 40. Nc8 Bc5+ 41. Kh1 h5 42. Bh3 Nf5 43. Bxf5 gxf5 44. Nd6 h4 45. Nxf5 hxg3 46. hxg3 Bf2 47. Kg2 Kf7 48. Kf3 Ke6 49. Ng7+ Kf7 50. Rd7+ Kg6 51. f5+ Kg5 52. f6 Bc5 53. Ne6+ Kf5 54. Nxc5 Rc2 55. f7 Kxe5 56. f8=Q 1-0",
    B: "2148",
  },
  {
    W: "1646",
    T: "180+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 Nf6 5. Nc3 Bd6 6. Bd3 O-O 7. h3 h6 8. O-O Nc6 9. Re1 Be6 10. a3 Qc8 11. Nb5 Bxh3 12. Nxd6 cxd6 13. Bf1 Bg4 14. Bf4 Qf5 15. Bxd6 Rfd8 16. Be5 Ne4 17. Qd3 f6 18. Bc7 Rd7 19. Bg3 Bxf3 20. gxf3 Ng5 21. Qxf5 1-0",
    B: "2162",
  },
  {
    W: "1879",
    T: "180+2",
    P: "1. g3 d5 2. Bg2 c6 3. Nf3 Bg4 4. O-O Nd7 5. d4 Ngf6 6. c3 e6 7. Bg5 Be7 8. Bxf6 gxf6 9. Nbd2 h5 10. h3 Bf5 11. Nh4 Bh7 12. f4 f5 13. Ndf3 Nf6 14. Ng5 Ne4 15. Bxe4 dxe4 16. Qd2 Rg8 17. Kh2 Bxg5 18. fxg5 Qxg5 19. Qxg5 Rxg5 20. e3 Ke7 21. Rf2 Rag8 22. Rg1 f6 23. Rfg2 b6 24. b4 Kd6 25. a4 Kd5 26. a5 b5 27. Rc2 Kc4 28. Rgg2 e5 29. Rgf2 Kd3 30. dxe5 fxe5 31. Rfd2+ Kc4 32. Rd7 R8g7 33. Rxg7 Rxg7 34. Rf2 Kxc3 35. Nxf5 Bxf5 36. Rxf5 Kxb4 37. Rxe5 Kxa5 38. Rxe4 Kb6 39. Re5 a5 40. Rxh5 Re7 41. Rf5 a4 42. Rf3 a3 43. Rf2 b4 44. h4 b3 45. h5 a2 0-1",
    B: "2113",
  },
  {
    W: "1856",
    T: "60+0",
    P: "1. e4 e6 2. d4 g6 3. c3 Bg7 4. Bd3 h6 5. h3 b6 6. Be3 Bb7 7. Qd2 Qe7 8. Nf3 Nc6 9. Qe2 O-O-O 10. Ba6 d6 11. Bxb7+ Kxb7 12. a4 f5 13. exf5 gxf5 14. O-O Nf6 15. a5 Ne4 16. axb6 axb6 17. Qa6+ Kb8 18. d5 Na7 19. Qxa7+ Kc8 20. Qa8+ Kd7 21. Qc6+ Kc8 22. Ra8# 1-0",
    B: "1752",
  },
  {
    W: "1902",
    T: "60+0",
    P: "1. e4 g6 2. d4 Bg7 3. Nf3 d6 4. Nc3 Nc6 5. Bg5 Bg4 6. d5 Bxf3 7. Qxf3 Nd4 8. Qd1 c5 9. Be3 a6 10. Be2 Qb6 11. Na4 Qc7 12. c3 Nxe2 0-1",
    B: "2285",
  },
  {
    W: "1595",
    T: "60+0",
    P: "1. Nc3 d5 2. d4 e5 3. Bf4 exf4 4. Qd2 c5 5. Qxf4 Bd6 6. Qd2 cxd4 7. Qxd4 Nf6 8. O-O-O Qb6 9. Qxb6 axb6 10. e4 Be6 11. Bb5+ Bd7 12. exd5 Bxb5 13. Nxb5 Kd7 14. Nf3 Ne4 15. Rhe1 Nxf2 16. Rd2 Ng4 17. h3 Nf6 18. g4 Bf4 19. Ne5+ Ke7 20. Nd3+ Kd7 21. Nxf4 Ra5 22. Nc3 Kd6 23. g5 Nxd5 24. Nfxd5 Rxd5 25. Nd1 Rxd2 26. Kxd2 Rd8 27. Kc1 Kc5 28. Nc3 Nc6 29. Ne4+ Kb5 30. Nc3+ Ka5 31. Re5+ Ka6 32. Rb5 Nd4 33. Rb4 1-0",
    B: "1588",
  },
  {
    W: "2407",
    T: "60+0",
    P: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c3 Bf5 5. f4 Nf6 6. Nf3 e6 7. Bd3 Be7 8. Bxf5 exf5 9. O-O O-O 10. Ne5 Nbd7 11. Nd2 Ne4 12. Nxe4 fxe4 13. f5 Nxe5 14. dxe5 Qc7 15. f6 gxf6 16. exf6 Bd6 17. Qg4+ Kh8 18. Qg7# 1-0",
    B: "1758",
  },
  {
    W: "1524",
    T: "60+0",
    P: "1. d4 e5 2. c3 exd4 3. cxd4 Nc6 0-1",
    B: "1502",
  },
  {
    W: "1218",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d3 Nf6 6. g3 Be7 7. Nc3 Bg4 8. Bg2 O-O 9. O-O Re8 10. Re1 h5 11. Bg5 Nc6 12. Bxf6 Bxf6 13. Rxe8+ Qxe8 14. h3 Bxc3 15. bxc3 Bd7 16. Nd4 g6 17. Rb1 Qc8 18. Qd2 Bxh3 19. Bxh3 Qxh3 20. Rxb7 Nxd4 21. cxd4 Qd7 22. c4 Qc8 23. Rb1 Rb8 24. Rxb8 Qxb8 25. c5 d5 26. Qg5 c6 27. Kg2 Qb1 28. a4 Qxd3 29. Qd8+ Kg7 30. Qc7 Qe4+ 31. Kg1 Qxd4 32. Qxc6 Qa1+ 33. Kg2 d4 34. Qd6 Qxa4 35. c6 d3 36. c7 d2 37. Qxd2 Qc6+ 38. Kf1 Qxc7 39. Qb2+ Kh7 40. Kg2 Qb6 41. f3 Qxb2+ 0-1",
    B: "1128",
  },
  {
    W: "1908",
    T: "120+5",
    P: "1. d4 e6 2. Bf4 c6 3. e3 d5 4. Bd3 a6 5. Nf3 Nf6 6. Ne5 Nbd7 7. Nd2 Nxe5 8. dxe5 Ne4 9. f3 Nc5 10. Be2 Nd7 11. Bd3 h6 12. Bg3 h5 13. f4 h4 14. Bf2 g6 15. Qg4 Nc5 16. Bxg6 fxg6 17. Qxg6+ Kd7 18. O-O-O Be7 19. e4 Qg8 20. Qxg8 Rxg8 21. g3 hxg3 22. hxg3 Ke8 23. exd5 exd5 24. Rh7 Bf5 25. Rxe7+ Kxe7 26. Bxc5+ Ke6 27. Rg1 b5 28. Nb3 Rh8 29. Nd4+ Kf7 30. Nxf5 Rh5 31. g4 Rh3 32. g5 Rah8 33. Nh6+ Kg6 34. f5+ Kh5 35. f6 Kg6 36. f7 Rf3 37. e6 Rf5 38. e7 Rxf7 39. Nxf7 Kxf7 40. g6+ Ke6 41. g7 Rg8 42. Re1+ Kd7 43. e8=Q+ 1-0",
    B: "1407",
  },
  {
    W: "1459",
    T: "180+2",
    P: "1. e4 c6 2. Nf3 d5 3. Nc3 Bg4 4. exd5 cxd5 5. h3 Bh5 6. g4 Bg6 7. d4 e6 8. Bg2 Bd6 9. O-O Nc6 10. Be3 Nge7 11. h4 h5 12. g5 Nf5 13. Bd2 f6 14. Bh3 fxg5 15. Bxg5 Nce7 16. Re1 Qd7 17. Ne5 Bxe5 18. Rxe5 Nc6 19. Re2 Kf7 20. Qf1 Ncxd4 21. Re5 Nf3+ 22. Kg2 Nxe5 23. Rd1 Qc6 24. Kh2 Nf3+ 25. Kg2 d4 26. Bxf5 Nd2+ 27. Ne4 Nxf1 28. Rxf1 Bxf5 29. f3 Bxe4 30. fxe4+ Kg6 31. Rf4 e5 32. Rf5 Qxe4+ 33. Kg3 Qxf5 34. Bd2 Qg4+ 35. Kf2 Rhf8+ 36. Ke1 Qg2 37. Kd1 Rf1+ 38. Be1 Qf3+ 39. Kd2 Qe3+ 40. Kd1 Rxe1# 0-1",
    B: "1342",
  },
  {
    W: "2247",
    T: "30+0",
    P: "1. d4 e5 2. c4 exd4 3. e3 Nc6 4. exd4 Nf6 5. d5 Ne5 6. Nc3 Ng6 7. Nf3 Bc5 8. Bd3 d6 9. O-O Bg4 10. b3 O-O 11. Bb2 Re8 12. Be2 Bxf3 13. Bxf3 Ne5 14. Na4 Nxf3+ 15. Qxf3 Bb6 16. Rae1 Nd7 17. Qg3 f6 18. h4 Ne5 19. Kh1 Qd7 20. f4 Nf7 21. Bxf6 Nh6 22. h5 Rxe1 23. Rxe1 Re8 24. Rxe8+ Qxe8 25. Qxg7# 1-0",
    B: "2380",
  },
  {
    W: "1968",
    T: "120+0",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Nf3 Be7 5. e3 O-O 6. Bd3 c6 7. Qc2 Nbd7 8. cxd5 h6 9. dxe6 fxe6 10. a3 Nd5 11. b4 Nxc3 12. Qxc3 Nf6 13. Ne5 Nd5 14. Qd2 Bf6 15. Nc4 b5 16. Na5 e5 17. dxe5 Bxe5 18. Rb1 Qd6 19. g3 Bh3 20. Qc2 Rac8 21. Bb2 Rf7 22. Bxe5 Qxe5 23. Nb3 Rcf8 24. e4 Nf6 25. Qb2 Ng4 26. Qxe5 Nxe5 27. Bf1 Nf3+ 28. Ke2 Nd4+ 29. Nxd4 Rxf2+ 30. Ke3 Bg4 31. Nf5 g6 32. Kxf2 gxf5 33. exf5 Bxf5 34. Rc1 Be4+ 35. Kg1 Bxh1 36. Kxh1 Rf2 37. Kg1 Rb2 38. Rxc6 Ra2 39. Rb6 Rxa3 40. Rb8+ 1-0",
    B: "1490",
  },
  {
    W: "2025",
    T: "60+0",
    P: "1. f4 b6 2. Nf3 Ba6 3. e4 Bxf1 4. Rxf1 Qc8 5. d4 Qa6 6. Qe2 e6 7. Qxa6 Nxa6 8. Nc3 Nb4 9. Kd2 Nf6 10. a3 Nc6 11. e5 Nd5 12. Nxd5 exd5 13. b3 Ne7 14. Bb2 c5 15. Rad1 Nc6 16. dxc5 bxc5 17. Kc1 d4 18. Kb1 d6 19. Rfe1 dxe5 20. Nxe5 Nxe5 21. Rxe5+ Kd7 22. Rde1 Bd6 23. R5e2 Rhd8 24. g3 Re8 25. Rxe8 Rxe8 26. Rxe8 Kxe8 27. Bc1 Ke7 28. Bd2 Ke6 29. b4 cxb4 30. Bxb4 Bxb4 31. axb4 Kd5 32. Kc1 Kc4 33. Kd2 Kxb4 34. Kd3 Kc5 35. g4 a5 36. h4 a4 37. c3 a3 38. cxd4+ Kd5 39. Kc3 a2 40. Kb2 Kxd4 41. f5 Ke4 42. h5 Kf4 43. g5 Kxg5 44. f6 Kxf6 45. h6 gxh6 46. Kxa2 h5 47. Kb2 Kg5 48. Kc2 Kg4 49. Kd2 Kg3 50. Ke2 Kg2 51. Ke3 h4 52. Kf4 h3 53. Kg4 h2 54. Kg5 h1=Q 55. Kf6 Qf1+ 0-1",
    B: "2093",
  },
  {
    W: "1613",
    T: "240+6",
    P: "1. d4 d5 2. Nf3 Bg4 3. Ne5 Be6 4. c4 f6 5. Nf3 c6 6. cxd5 cxd5 7. Nc3 Nc6 8. e4 Qd6 9. Bb5 O-O-O 10. O-O Kb8 11. g3 a6 12. Bf4 Ne5 13. dxe5 Qc5 14. exf6+ Ka8 15. fxe7 Bxe7 16. Ba4 d4 17. Ne2 d3 18. Nc3 Qb4 19. Bb3 Bxb3 20. axb3 Nf6 21. Ne5 Rhf8 22. Ra4 Qb6 23. Nc4 Qc6 24. e5 Ne4 25. Nxe4 Qxe4 26. Nb6+ Kb8 27. Re1 Qf5 28. Ree4 Ka7 29. Be3 Kb8 30. Qd2 Qf3 31. Rf4 Qe2 32. Qxe2 dxe2 33. Ra1 Rd1+ 34. Rxd1 exd1=Q+ 35. Kg2 Rxf4 36. gxf4 Bh4 37. Nc4 Qd5+ 38. Kf1 Qh1+ 39. Ke2 Qxh2 40. e6 Qh3 41. e7 Qe6 42. e8=Q+ Qxe8 43. Ne5 Qb5+ 0-1",
    B: "1386",
  },
  {
    W: "1904",
    T: "300+5",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 d6 5. Nc3 Ne5 6. h3 Nf6 7. Be3 Be7 8. f4 Ng6 9. Bc4 O-O 10. Qf3 a6 11. O-O Rb8 12. Bd3 Bd7 13. g4 c5 14. Nf5 Bc6 15. g5 Ne8 16. h4 Qd7 17. h5 Nh8 18. Nh6+ gxh6 19. gxh6 Ng6 20. hxg6 fxg6 21. Qg3 Nf6 22. f5 Nh5 23. Qg2 Kh8 24. fxg6 hxg6 25. Rxf8+ Rxf8 26. Bc4 Kh7 27. Rf1 b5 28. Rxf8 Bxf8 29. Bd5 Bxd5 30. Nxd5 Bxh6 31. Bxh6 Kxh6 32. Qd2+ Kg7 33. Qg5 Qf7 34. b3 Qe6 35. Qe7+ Qxe7 36. Nxe7 Nf6 37. Kf2 Nxe4+ 38. Kf3 Nc3 39. Nc8 Nxa2 40. Kf4 Nb4 41. c3 Na2 42. Nxd6 Nxc3 43. Ke5 Kh6 44. Ne8 g5 45. Kf5 a5 46. Nc7 a4 47. bxa4 bxa4 48. Na6 a3 49. Nxc5 a2 50. Nb3 Ne2 0-1",
    B: "2033",
  },
  {
    W: "1149",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nf6 3. Bc4 d5 4. exd5 b5 5. Bxb5+ c6 6. Bxc6+ Nxc6 7. dxc6 e4 8. Ne5 Bg4 9. f3 Bd6 10. d4 Bb4+ 11. c3 Bd6 12. fxg4 Rc8 13. Bg5 Qa5 14. O-O Nxg4 15. Qxg4 Rb8 16. Nxf7 Bxh2+ 17. Kxh2 Rf8 18. Qe6# 1-0",
    B: "909",
  },
  {
    W: "1277",
    T: "180+2",
    P: "1. e4 a6 2. Qh5 b5 3. a3 Nf6 4. Qf3 d6 5. h3 Bb7 6. Bd3 e5 7. Ne2 h6 8. O-O Be7 9. Nbc3 Nh7 10. Nd5 Bh4 11. Qg4 O-O 12. c4 bxc4 13. Bxc4 Nc6 14. d3 Bg5 15. Bxg5 Nxg5 16. h4 Ne6 17. Ng3 Ncd4 18. Nf5 Nxf5 19. exf5 Nf4 20. Nxf4 exf4 21. Qxf4 Qf6 22. Rab1 Rae8 23. Rbe1 Re5 24. d4 Rxf5 25. Qg3 Rf4 26. Be2 Rxd4 27. Bf3 Bc8 28. Bc6 Rxh4 29. Re3 Rg4 30. Qf3 Qg5 31. Qh3 1-0",
    B: "1209",
  },
  {
    W: "1303",
    T: "180+2",
    P: "1. e4 e6 2. Nf3 d5 3. e5 c5 4. d4 c4 5. b3 b5 6. a4 Ba6 7. Bg5 Be7 8. Bxe7 Nxe7 9. axb5 Bxb5 10. bxc4 Bxc4 11. Bxc4 dxc4 12. O-O Nbc6 13. c3 a5 14. h3 O-O 15. Nh2 a4 16. Rxa4 Rxa4 17. Qxa4 Qb6 18. Nd2 Qb2 19. Ne4 Qb3 20. Qa1 Rb8 21. Nf3 f5 22. Nc5 Qb5 23. Nxe6 Rb6 24. Nc5 Ng6 25. g3 h5 26. Nd2 f4 27. Rb1 Qa5 28. Qxa5 1-0",
    B: "1301",
  },
  {
    W: "1532",
    T: "180+2",
    P: "1. e4 c6 2. Nf3 d5 3. e5 Bf5 4. d4 g6 5. Bd3 e6 6. Bxf5 exf5 7. O-O c5 8. c3 cxd4 9. cxd4 Nc6 10. Bg5 Be7 11. Bxe7 Qxe7 12. Nc3 Qe6 13. Ng5 Qe7 14. f4 h6 15. Nf3 Qe6 16. Nb5 O-O-O 17. Nd6+ Kc7 18. a4 f6 19. Qc2 fxe5 20. fxe5 a5 21. Rac1 Ne7 22. Qd2 g5 23. Qxa5+ Kd7 24. Qb5 Rb8 25. Qd3 g4 26. Nd2 Rhf8 27. g3 Nb4 28. Qb5+ Nbc6 29. a5 h5 30. Nb3 Qh6 31. Nc5+ Kc7 32. Qb6# 1-0",
    B: "1532",
  },
  {
    W: "1689",
    T: "180+2",
    P: "1. e4 c5 2. b3 e5 3. Nc3 Nf6 4. Bc4 Nc6 5. a4 Be7 6. Nf3 h5 7. Ba3 h4 8. h3 d6 9. Bd5 Nxd5 10. Nxd5 Be6 11. Nxe7 Qxe7 12. d3 g5 13. Qd2 g4 14. Ng5 Nd4 15. O-O-O Bxb3 16. c3 Bxd1 17. cxd4 Bxa4 18. dxc5 dxc5 19. Kb1 b5 20. Rc1 b4 21. Bb2 a5 22. Qe3 Rc8 23. hxg4 f6 24. Nf3 h3 25. Rh1 Kf7 26. gxh3 c4 27. g5 c3 28. gxf6 Qc5 29. Ng5+ 1-0",
    B: "1677",
  },
  {
    W: "1947",
    T: "180+2",
    P: "1. e4 b6 2. d4 Bb7 3. Nc3 g6 4. f4 Bg7 5. Nf3 h6 6. Bc4 a6 7. O-O d6 8. Be3 e6 9. Qd2 Ne7 10. Rae1 O-O 11. e5 d5 12. Bd3 Nd7 13. Ne2 c5 14. c3 c4 15. Bc2 b5 16. Ng3 a5 17. Bb1 a4 18. Qc2 Re8 19. a3 Nf8 20. Qd2 Nf5 21. Nxf5 gxf5 22. Kh1 Ng6 23. Rg1 Kh8 24. g4 fxg4 25. Rxg4 Rg8 26. Reg1 Qe7 27. Bxg6 fxg6 28. Rxg6 Qf7 29. Nh4 Raf8 30. f5 exf5 31. Bxh6 Qe7 32. Bxg7+ Rxg7 33. Rxg7 Qxh4 34. Qg5 Qxg5 35. R1xg5 Bc8 36. R7g6 f4 37. Rf6 Rxf6 38. exf6 Be6 39. Kg2 Kh7 40. Kf3 Kh6 41. Kxf4 Bf7 42. Rg7 Be6 43. Ke5 Bh3 44. Rg3 Bf1 45. f7 Bd3 46. f8=Q+ Kh5 47. Qh8+ Bh7 48. Qxh7# 1-0",
    B: "1873",
  },
  {
    W: "1479",
    T: "180+2",
    P: "1. d4 d5 2. Nf3 e6 3. e3 a6 4. b3 Nc6 5. Bb2 Nf6 6. Nbd2 Bd6 7. Ne5 Bd7 8. f4 O-O 9. Bd3 Re8 10. O-O h6 11. Rf3 Bc8 12. Rc1 Bxe5 13. fxe5 Nd7 14. Rh3 Rf8 15. Qh5 f5 16. Rf1 Qe8 17. Qf3 Kh7 18. c4 g5 19. g4 Kg7 20. Bc1 fxg4 21. Qxg4 Rxf1+ 22. Nxf1 Nf8 23. e4 dxc4 24. bxc4 Nxd4 25. Bxg5 hxg5 26. Qxg5+ Qg6 27. Rg3 c5 28. Qe7+ Kh6 29. Rxg6+ Nxg6 30. Qf6 1-0",
    B: "1469",
  },
  {
    W: "1349",
    T: "180+2",
    P: "1. d4 e6 2. e4 d5 3. e5 Nc6 4. Nf3 Nge7 5. g4 Ng6 6. Bg5 Be7 7. h3 h6 8. Bxe7 Qxe7 9. Nc3 Qb4 10. a3 Qxb2 11. Ne2 O-O 12. Qb1 Qxb1+ 13. Rxb1 b6 14. Bg2 Ba6 15. c3 Bxe2 16. Kxe2 Nf4+ 17. Ke3 Nxg2+ 0-1",
    B: "1403",
  },
  {
    W: "1697",
    T: "60+0",
    P: "1. e4 d5 2. exd5 c6 3. Nc3 cxd5 4. d4 e6 5. Nf3 Nf6 6. Bg5 Be7 7. Bd3 O-O 8. O-O h6 9. Be3 Nc6 10. Re1 Ng4 11. Bf4 g5 12. Bg3 f5 13. h3 Nf6 14. Bh2 Ne4 15. Ne5 Nxe5 16. dxe5 Nxc3 17. bxc3 Bd7 18. Qh5 Rf7 19. Qxh6 Rg7 20. Re3 f4 21. Rf3 a6 22. h4 gxh4 23. Bxf4 Bg5 24. g3 Bxf4 25. Rxf4 hxg3 26. fxg3 Qg5 27. Qh2 Qxg3+ 28. Qxg3 Rxg3+ 29. Kf2 Rg7 30. Rh1 Rf8 31. Kf3 Rxf4+ 32. Kxf4 Kf7 33. Ke3 1-0",
    B: "1760",
  },
  {
    W: "1304",
    T: "60+0",
    P: "1. e3 e5 2. b3 d5 3. Bb2 Bd6 4. Nf3 Nc6 5. Be2 Be6 6. c4 d4 7. exd4 exd4 8. Nxd4 Nxd4 9. Bxd4 Nf6 10. O-O O-O 11. Bf3 c6 12. Re1 Re8 13. Bb2 Ng4 14. Bxg4 Bxg4 15. Qxg4 Rxe1# 0-1",
    B: "1302",
  },
  {
    W: "2056",
    T: "60+0",
    P: "1. e4 d5 2. e5 c5 3. d4 Nc6 4. f4 Bf5 5. Nf3 Be4 6. Bd3 Bxf3 7. Qxf3 Nxd4 8. Qh5 e6 9. O-O g6 10. Qh3 h5 11. c3 Nf5 12. Bb5+ Ke7 13. Nd2 Qb6 14. Be2 Kd8 15. Nf3 Ngh6 16. Ng5 Be7 17. Bd2 Kc7 18. b3 Bxg5 19. fxg5 Ng4 20. Bxg4 hxg4 21. Qxg4 d4 22. c4 Rad8 23. Rxf5 gxf5 24. Qf4 Rdg8 25. h4 Qc6 26. g3 Qe4 27. Qxe4 fxe4 28. Bf4 e3 29. Rd1 Rd8 30. a3 Kc6 31. b4 cxb4 32. axb4 a5 33. b5+ Kc5 34. Rc1 Rc8 35. Kf1 Kb4 36. Ke2 Rxc4 37. Rxc4+ Kxc4 38. Kf3 Kxb5 39. Ke4 Kc4 40. g4 e2 41. Bd2 d3 42. Ke3 Rxh4 43. Be1 Rh1 44. Kf2 Rf1+ 0-1",
    B: "2272",
  },
  {
    W: "1176",
    T: "60+0",
    P: "1. e4 e5 2. h3 d6 3. a3 Nf6 4. Nf3 Nxe4 5. Be2 Nf6 6. O-O Bf5 7. g4 g6 8. gxf5 gxf5 9. Ng5 Ne4 10. Nxe4 fxe4 11. Nc3 e3 12. dxe3 Be7 13. Nd5 Nc6 14. Nxe7 Qxe7 15. e4 Nd4 16. Qd2 Nxe2+ 17. Qxe2 O-O 18. Qg4+ Kh8 19. Bg5 Qe6 20. Rfe1 Qxg4+ 21. hxg4 Rg8 22. Bh4 Rxg4+ 23. Bg3 Rxg3+ 24. fxg3 Rg8 25. Re3 d5 26. exd5 c6 27. Kg2 cxd5 28. Rxe5 d4 29. Rd1 d3 30. Rxd3 f6 31. Re7 Rg7 32. Rxg7 Kxg7 33. Rd7+ Kg6 34. Rxb7 h5 35. Rxa7 f5 36. Rc7 h4 37. gxh4 Kh5 38. b4 Kxh4 39. b5 f4 40. b6 Kg5 41. b7 Kg4 42. b8=Q f3+ 43. Kf2 Kh3 44. Rf7 Kg4 45. Qh8 Kg5 46. Rg7+ Kf4 47. Qf8+ Ke4 48. Rd7 Ke5 49. Qe8+ Kf5 50. Rf7+ Kg4 51. Qg8+ Kh5 52. Rh7# 1-0",
    B: "1174",
  },
  {
    W: "1653",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 d6 3. Nc3 Nc6 4. d4 exd4 5. Nxd4 Nxd4 6. Qxd4 Nf6 7. Bg5 Be7 8. O-O-O Be6 9. e5 Nd7 10. Bxe7 Qxe7 11. exd6 cxd6 12. Qxg7 O-O-O 13. Qd4 Nb6 14. Ne4 Bxa2 15. b3 f5 16. Nc3 Qg5+ 17. f4 Qg6 18. Nxa2 d5 19. Nb4 Kb8 20. g3 Rhe8 21. Bg2 Qg4 22. Nxd5 Nxd5 23. Bxd5 Re4 24. Qc5 Rxd5 0-1",
    B: "1450",
  },
  {
    W: "1155",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. O-O axb5 5. d4 exd4 6. Nxd4 Nxd4 7. Qxd4 d6 8. e5 dxe5 9. Qxe5+ Be6 10. Qxb5+ c6 11. Qxb7 Bd6 12. Qxc6+ Qd7 13. Qxa8+ Qd8 14. Qc6+ Bd7 15. Qxd6 Qe7 16. Qb8+ Qd8 17. Qe5+ Qe7 18. Qxg7 Qf6 19. Qxf6 Nxf6 20. Re1+ 1-0",
    B: "1158",
  },
  {
    W: "1680",
    T: "60+0",
    P: "1. b3 e5 2. Bb2 Nf6 3. d3 Nc6 4. Nd2 d6 5. e4 Be6 6. Ne2 d5 7. g3 d4 8. Bg2 Bd6 9. O-O O-O 10. f4 Re8 11. f5 Nd7 12. fxe6 fxe6 13. c4 Rf8 14. Nf3 Ne7 15. a3 Ng6 16. Qd2 c5 17. Rf2 Qe7 18. Raf1 h6 19. Ne1 a6 20. Nc2 Rxf2 21. Rxf2 Rf8 22. Rxf8+ Qxf8 23. Nc1 Qe7 24. Qf2 b5 25. Ne2 b4 26. axb4 cxb4 27. Bc1 a5 28. Bd2 Nf6 29. Ne1 Ng4 30. Qf1 Nf6 31. Nf3 Ng4 32. h3 Nf6 33. Qa1 Qa7 34. Kh2 Kh7 35. Qa4 Nd7 36. Ne1 Nb6 37. Nc2 Nxa4 38. bxa4 b3 39. Na1 b2 40. Nb3 b1=Q 0-1",
    B: "1688",
  },
  {
    W: "1878",
    T: "60+0",
    P: "1. e4 g6 2. d4 c5 3. Be3 cxd4 4. Bxd4 Nf6 5. Bxf6 exf6 6. Bd3 Bg7 7. Nc3 O-O 8. Qd2 Re8 9. O-O-O f5 10. exf5 d5 11. fxg6 fxg6 12. h4 h5 13. f3 d4 14. Nce2 Qb6 15. Bxg6 Rd8 16. Bd3 Nc6 17. Nf4 Nb4 18. a3 Nxd3+ 19. Qxd3 Rf8 20. Nxh5 Bf5 21. Qc4+ Be6 22. Qb4 Qc6 23. Nxg7 Kxg7 24. Qxd4+ Kg8 25. h5 Rad8 26. Qh4 Rxd1+ 27. Kxd1 Qd5+ 28. Kc1 Qa2 29. Qb4 Rd8 30. c4 Bxc4 31. Nh3 Bb3 32. Qg4+ Kf8 33. Qf5+ Bf7 34. Qc5+ Kg8 35. Kc2 Qb3+ 0-1",
    B: "1847",
  },
  {
    W: "1843",
    T: "60+0",
    P: "1. e4 h5 2. d4 h4 3. Nc3 d6 4. Nf3 h3 5. gxh3 Bxh3 6. Bxh3 Rxh3 7. Ng5 Rxc3 8. bxc3 e6 9. Qh5 Nh6 10. Nh7 g6 11. Qf3 Ng8 12. Nxf8 Kxf8 13. h4 Nc6 14. h5 gxh5 15. Rxh5 Na5 16. Rh8 Ke7 17. Bg5+ f6 18. Bxf6+ Nxf6 19. Rxd8 Rxd8 20. e5 dxe5 21. dxe5 Nd5 22. Qh5 Rf8 23. O-O-O Nc4 24. Qh4+ Kd7 25. Qxc4 c6 26. Qc5 1-0",
    B: "1801",
  },
  {
    W: "1983",
    T: "60+0",
    P: "1. d3 g6 2. Nf3 Bg7 3. e3 Nf6 4. Be2 O-O 5. O-O d5 6. c3 c5 7. d4 Nc6 8. dxc5 e5 9. Nbd2 d4 10. Nxd4 exd4 11. cxd4 Bf5 12. Nf3 Ne4 13. Qb3 Be6 14. Qxb7 Qc8 15. Qxc8 Rfxc8 16. Rd1 Bd5 17. Nd2 Nxd2 18. Bxd2 f5 19. Bc3 Rab8 20. Ba6 Re8 21. a4 h5 22. Bb5 g5 23. b4 g4 24. Be2 Bh6 25. b5 Ne7 26. c6 Ng6 27. Rac1 f4 28. Bd3 Kg7 29. Bxg6 Kxg6 30. Bb4 fxe3 31. fxe3 Bxe3+ 32. Kh1 Bxc1 33. Rxc1 Re2 34. Bd6 Rbe8 35. c7 Rxg2 36. c8=Q Rc2+ 37. Kg1 Rcxc8 38. Rxc8 Rxc8 39. Be5 Rc2 40. h3 Ra2 41. hxg4 hxg4 42. b6 axb6 43. a5 bxa5 44. Bd6 Kf5 45. Bc5 a4 46. Kf1 Ke4 47. Ke1 Kf3 48. Kd1 g3 49. Kc1 g2 50. Bd6 Re2 51. Bg3 g1=Q+ 52. Be1 Qxe1# 0-1",
    B: "2004",
  },
  {
    W: "1473",
    T: "60+0",
    P: "1. e4 c6 2. Nf3 d6 3. Bc4 d5 4. exd5 cxd5 5. Bd3 Nc6 6. Ng5 Nf6 7. O-O e6 8. Nf3 Be7 9. Be2 Bd7 10. d4 a6 11. c3 O-O 12. Nbd2 Qb6 13. Ng5 Bd6 14. Ndf3 Qc7 15. g3 h6 16. Nh3 e5 17. dxe5 Nxe5 18. Nxe5 Bxe5 19. Bf4 Bxh3 20. Re1 Bxf4 21. Qd4 Be5 22. Qh4 Bf5 23. Rad1 Bg6 24. Bd3 Bd6 25. Bxg6 fxg6 26. Qd4 Ne4 27. Qxd5+ Kh7 28. Qxe4 Rae8 29. Qd3 Rxe1+ 30. Rxe1 Bc5 31. b4 Bb6 32. c4 Qf7 33. c5 Qxf2+ 34. Kh1 Bc7 0-1",
    B: "1985",
  },
  {
    W: "1963",
    T: "180+2",
    P: "1. g3 d5 2. Bg2 e6 3. b3 Nf6 4. Bb2 Be7 5. d3 O-O 6. Nd2 b6 7. e3 Bb7 8. Ne2 Nbd7 9. c3 c5 10. Qc2 Rc8 11. O-O-O Qc7 12. h4 d4 13. c4 Bxg2 14. Rhg1 Bb7 15. exd4 cxd4 16. Bxd4 Nc5 17. g4 Rfd8 18. g5 Nfd7 19. Qb2 Nxd3+ 20. Kb1 Nxb2 21. Kxb2 e5 22. Bc3 Nc5 23. f3 Nd3+ 24. Kc2 Bb4 25. Ne4 Bxe4 26. fxe4 Bxc3 27. Kxc3 Nc5 28. Rd5 Nxe4+ 29. Kd3 Rxd5+ 30. cxd5 Qc2+ 31. Ke3 Nc5 32. Rd1 Qe4+ 33. Kf2 Nd3+ 34. Kf1 Qf3+ 35. Kg1 Qf2+ 36. Kh1 Qxe2 37. d6 Nf2+ 38. Kg2 Nxd1+ 39. Kg3 Rc3# 0-1",
    B: "1996",
  },
  {
    W: "1964",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nd4 6. c3 b5 7. Bf1 Nxd5 8. cxd4 Qxg5 9. Bxb5+ Kd8 10. O-O Bb7 11. d3 Nf4 0-1",
    B: "2246",
  },
  {
    W: "1929",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Bc5 5. c3 b5 6. Bc2 Nf6 7. O-O O-O 8. d4 exd4 9. cxd4 Bb6 10. e5 Nd5 11. Bg5 Qe8 12. Be4 Nde7 13. d5 Na7 14. h4 Ng6 15. Bxg6 fxg6 16. d6 Nc6 17. Re1 Rf5 18. Nc3 Nxe5 19. Nxe5 Bxf2+ 20. Kh1 Bxe1 21. Qd5+ Kh8 22. Rxe1 c6 23. Nf7+ 1-0",
    B: "1741",
  },
  {
    W: "1607",
    T: "600+0",
    P: "1. e4 h5 2. Nf3 h4 3. d4 h3 4. g3 d5 5. Nc3 dxe4 6. Nxe4 Nf6 7. Nc3 e6 8. Bg5 Nc6 9. Qd3 Qe7 10. O-O-O Bd7 11. Ne5 Nxe5 12. dxe5 Rh5 13. exf6 gxf6 14. Bxf6 Qd6 15. Qf3 Rf5 16. Qxf5 Qxd1+ 17. Kxd1 exf5 18. Bxh3 Be7 19. Re1 Be6 20. Bxe7 Kxe7 21. Bxf5 Rd8+ 22. Kc1 Rd4 23. Bxe6 fxe6 24. Re4 Rd5 25. f4 a5 26. Re5 b5 27. Rxd5 c5 28. Rxc5 b4 29. Rxa5 b3 30. Kd2 e5 31. h4 exf4 32. h5 fxg3 33. Ne2 g2 34. h6 bxa2 35. h7 a1=R 36. Rxa1 g1=N 37. h8=Q Nxe2 38. Re1 Kf7 39. Rxe2 Kg6 40. Qf8 Kh7 41. Rh2+ Kg6 42. Rh6+ Kg5 43. Qf6+ Kg4 44. Rh4+ Kg3 45. Qf4+ Kg2 46. Rh2+ Kg1 47. Qf2# 1-0",
    B: "1608",
  },
  {
    W: "1630",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 bxc6 5. d4 cxd4 6. Nxd4 g6 7. c4 Bg7 8. Nc3 Qb6 9. Be3 Qxb2 10. Rc1 c5 11. Rc2 Qa3 12. Nb3 Bxc3+ 13. Rxc3 Qxa2 14. O-O d6 15. e5 Be6 16. exd6 Rd8 17. Nxc5 Rxd6 18. Qf3 Qa5 19. Nb7 Qxc3 20. Nxd6+ exd6 21. Qa8+ Ke7 22. Qa7+ Bd7 23. Bd4 Qd3 24. Re1+ Kf8 25. Bxh8 Qd2 26. Qb8+ Be8 27. Rxe8# 1-0",
    B: "1624",
  },
  {
    W: "1432",
    T: "600+0",
    P: "1. d4 e5 2. dxe5 Nc6 3. f4 g6 4. e3 Bg7 5. c3 Nh6 6. h3 O-O 7. Bd3 d6 8. g4 dxe5 9. g5 Nf5 10. Bxf5 Bxf5 11. fxe5 Nxe5 12. Nd2 Qd3 13. Ne2 Qxe3 14. Nc4 Nd3+ 15. Kf1 Qf2# 0-1",
    B: "1444",
  },
  {
    W: "1170",
    T: "600+0",
    P: "1. d4 e6 2. c4 d5 3. c5 c6 4. Qd3 Nf6 5. f3 b6 6. e4 bxc5 7. e5 Nfd7 8. Ne2 Ba6 9. Qb3 cxd4 10. Nxd4 c5 11. Nb5 Nxe5 12. f4 Nec6 13. Be3 Be7 14. Qc3 Qb6 15. Qxg7 Rf8 16. Qxh7 Bxb5 17. Bxb5 Qxb5 18. Nc3 Qxb2 19. O-O Qxc3 20. Rfe1 d4 21. Rac1 Qb2 22. a3 0-1",
    B: "1237",
  },
  {
    W: "1746",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qf6 5. Be3 Bc5 6. Nb5 Bd6 7. N1c3 Bf4 8. Bxf4 Qxf4 9. Nd5 Qxe4+ 10. Be2 Kf8 11. O-O Nge7 12. Ndxc7 Rb8 13. Nd6 Qe5 14. Bc4 Qxb2 15. Nxf7 Rg8 16. Nd6 Rh8 17. Nxc8 Rxc8 18. Qxd7 Rxc7 19. Qd8+ Nxd8 0-1",
    B: "1763",
  },
  {
    W: "2140",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Nc3 Qc7 6. g3 Nf6 7. Bg2 Bb4 8. Bd2 Nc6 9. Nxc6 dxc6 10. O-O e5 11. a3 Be7 12. Be3 b5 13. h3 Be6 14. b3 Rd8 15. Qc1 Qc8 16. Kh2 h5 17. Rd1 O-O 18. Bb6 Rxd1 19. Nxd1 Bd8 20. Bc5 Re8 21. Nb2 Nd7 22. Be3 h4 23. g4 Qb7 24. Nd3 Bb6 25. Bg5 Bd4 26. Rb1 f6 27. Bxh4 g5 28. Bg3 Kf7 29. Kg1 Rh8 30. c3 Bb6 31. Qc2 Qa7 32. Nb4 Rc8 33. Rd1 a5 34. Nd3 Nc5 35. Nxc5 Bxc5 36. b4 Be7 37. Bf1 a4 38. c4 Qb7 39. c5 Bb3 40. Qb1 Bxd1 41. Qxd1 Rd8 42. Qc1 Qd7 43. Qe3 Qd4 44. Qf3 Qd1 45. Qf5 Kg7 46. Kg2 Qd7 47. Qf3 Qe6 48. h4 Qb3 49. Qf5 Qxa3 50. hxg5 Rf8 51. Qe6 Rf7 52. gxf6+ Bxf6 53. Qxc6 Qxb4 54. Bxb5 a3 55. Bd3 Qb7 56. Qa4 Qa7 57. Qd1 Qxc5 58. Qa1 Rb7 59. Bb1 Rb2 60. Ba2 Qc2 61. Bd5 a2 62. Bxa2 Rxa2 63. Qe1 Ra3 64. g5 Rxg3+ 65. Kxg3 Bxg5 66. Qh1 Qd3+ 67. f3 Bf4+ 68. Kg4 Qd7+ 69. Kh5 Qe8+ 70. Kg4 Qe6+ 0-1",
    B: "2202",
  },
  {
    W: "1713",
    T: "600+0",
    P: "1. d4 g6 2. Bf4 Bg7 3. Nf3 f6 4. e3 Nh6 5. h3 Nf7 6. Bd3 O-O 7. Nbd2 d6 8. c3 e5 9. dxe5 dxe5 10. Qc2 exf4 11. exf4 Re8+ 12. Ne4 f5 13. O-O-O fxe4 14. Bxe4 Qe7 15. Rhe1 Qc5 16. Ng5 Nxg5 17. fxg5 Qxg5+ 18. Kb1 Nc6 19. Bd5+ Kf8 20. Rxe8+ Kxe8 21. Qe4+ Ne7 22. Bxb7 Bf5 23. Bc6+ Kf8 24. Bxa8 Bxe4+ 25. Ka1 Bxa8 26. Rd8+ Kf7 27. Rxa8 Qxg2 28. Rxa7 Qxf2 29. Rxc7 Qe1# 0-1",
    B: "1710",
  },
  {
    W: "1413",
    T: "600+0",
    P: "1. d4 d5 2. Nf3 Bd7 3. c4 dxc4 4. e4 Nc6 5. Bxc4 e5 6. dxe5 Qe7 7. O-O Nxe5 8. Nxe5 Qxe5 9. Nc3 O-O-O 10. Bxf7 Nf6 11. Qb3 Bc6 12. Re1 Rd7 13. f4 Qe7 14. Bc4 Nxe4 15. Nxe4 Bxe4 16. Be6 Bd5 17. Bxd7+ Qxd7 18. Qd3 Bc5+ 19. Be3 Re8 20. Bxc5 Rxe1+ 21. Rxe1 Bxg2 22. Qxd7+ Kxd7 23. Kxg2 b6 24. Bd4 c5 25. Bxg7 a5 26. f5 Kc6 27. f6 b5 28. f7 c4 29. f8=Q c3 30. Bxc3 b4 31. Qf5 bxc3 32. Re6+ Kd7 33. Qf7+ Kd8 34. Re8# 1-0",
    B: "1246",
  },
  {
    W: "1246",
    T: "600+0",
    P: "1. e4 e5 2. Bc4 Nf6 3. Bxf7+ Kxf7 4. f3 Be7 5. g4 Rf8 6. h4 Kg8 7. d3 d5 8. Nc3 d4 9. Nce2 Nxg4 10. fxg4 Bxg4 11. h5 Bh4+ 12. Kd2 Bxh5 13. c3 Rf2 14. cxd4 exd4 15. Qb3+ Bf7 16. Qxb7 Bd5 17. Qxd5+ Kh8 18. Qxd8+ Bxd8 19. Nh3 Rf7 20. Nxd4 Bf6 21. Ne6 Na6 22. Kc2 c5 23. Nhg5 Rb7 24. Rxh7+ Kg8 25. Rh2 Nb4+ 26. Kd2 Rab8 27. a3 Bxb2 28. Bxb2 Nxd3 29. Kxd3 Rxb2 30. Rxb2 Rxb2 31. Rh1 Rb3+ 32. Kc4 a5 33. Kxb3 a4+ 34. Kxa4 c4 35. Rc1 c3 36. Rxc3 1-0",
    B: "1264",
  },
  {
    W: "1536",
    T: "600+0",
    P: "1. b4 d5 2. Bb2 c5 3. bxc5 Nc6 4. a3 e5 5. e3 Bxc5 6. Bb5 Ne7 7. Bxc6+ Nxc6 8. d4 exd4 9. exd4 Qe7+ 10. Ne2 Bb6 11. Nc3 O-O 12. Nxd5 Qe4 13. c4 Re8 14. O-O Qxe2 15. c5 Qxd1 16. Raxd1 Bc7 17. Nxc7 1-0",
    B: "1471",
  },
  {
    W: "1877",
    T: "600+0",
    P: "1. c4 e5 2. g3 d5 3. cxd5 Qxd5 4. Nf3 Nc6 5. Nc3 Qd7 6. Bg2 Nf6 7. d3 Be7 8. O-O Rb8 9. Be3 O-O 10. Rc1 Ng4 11. Bd2 h6 12. Ne4 Qe6 13. a3 f5 14. Nc5 Bxc5 15. Rxc5 e4 16. dxe4 fxe4 17. Ne1 Rd8 18. Qc1 Nd4 0-1",
    B: "1812",
  },
  {
    W: "1152",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Bb4+ 5. c3 Bc5 6. Ng5 Na5 7. Nxf7 Nxc4 8. Nxd8 1-0",
    B: "1144",
  },
  {
    W: "959",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. d3 Bb4+ 4. Bd2 Bxd2+ 5. Qxd2 Nf6 6. Nc3 O-O 7. d4 exd4 8. Nxd4 Nxd4 9. Qxd4 Re8 10. e5 d6 11. Bb5 Rxe5+ 12. Be2 Bg4 13. Qc4 Bxe2 14. Nxe2 d5 15. Qd4 Qd6 16. f4 Re4 17. Qd3 Rae8 18. O-O-O Rxe2 19. b3 Qxf4+ 20. Kb2 h6 21. Qb5 Qe4 22. Rd3 Re3 23. Rxe3 Qxe3 24. Qxb7 Qf2 25. g3 Qg2 0-1",
    B: "1273",
  },
  {
    W: "1505",
    T: "420+0",
    P: "1. d4 g6 2. Nf3 Bg7 3. e4 a6 4. Be2 d6 5. O-O Nd7 6. a4 b6 7. c3 Bb7 8. d5 Ngf6 9. Bd3 Nc5 10. c4 Nxd3 11. Qxd3 Qd7 12. Nc3 O-O 13. e5 Ne8 14. exd6 exd6 15. h3 Qd8 16. Bd2 c5 17. dxc6 Bxc6 18. b4 Nc7 19. a5 b5 20. c5 dxc5 21. Qxd8 Raxd8 22. Rad1 Bxf3 23. gxf3 Rxd2 24. Rxd2 Bxc3 25. Rd7 Ne6 26. Ra7 Bxb4 27. Rxa6 c4 28. Rb1 Bd2 29. Rxb5 c3 30. Rd5 Rd8 31. Rxd8+ Nxd8 32. Ra8 c2 33. Rc8 c1=Q+ 34. Rxc1 Bxc1 35. Kf1 Nc6 36. a6 Bf4 37. Ke2 Bc7 38. Kd3 Kf8 39. Kc4 Ke7 40. Kc5 Na7 41. h4 Bb8 42. Kb6 Kd7 43. Kb7 Nc6 44. f4 f5 45. f3 1-0",
    B: "1350",
  },
  {
    W: "1261",
    T: "300+0",
    P: "1. e4 d6 2. d4 Be6 3. d5 Bd7 4. Nf3 e6 5. Bc4 exd5 6. Bxd5 c6 7. Bc4 b5 8. Bb3 Na6 9. Ng5 Nf6 10. Nxf7 Qc7 11. Nxh8 Nxe4 12. O-O g6 13. Re1 d5 14. f3 Bf5 15. fxe4 dxe4 16. g3 Qb6+ 17. Be3 Bc5 18. Bxc5 Qxc5+ 19. Kh1 Rd8 20. Bf7+ Ke7 21. Bxg6 Rxd1 22. Rxd1 hxg6 23. Nc3 Qf2 24. Rf1 Qxc2 25. Rad1 Qxb2 26. Nxe4 Bxe4+ 27. Kg1 Qg2# 0-1",
    B: "1279",
  },
  {
    W: "1436",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 d5 5. Bb5 dxe4 6. Bxc6+ bxc6 7. Nxe5 exd3 8. Qxd3 Qe7 9. f4 Qb4+ 10. Nc3 Qxf4 11. Bxf4 1-0",
    B: "1423",
  },
  {
    W: "1470",
    T: "300+0",
    P: "1. d4 d5 2. c4 Nf6 3. Nc3 e6 4. Bg5 Be7 5. Nf3 Ne4 6. Bxe7 Qxe7 7. Nxe4 dxe4 8. Ne5 f6 9. Ng4 Qb4+ 10. Qd2 Qxc4 11. e3 Qd5 12. Be2 Nc6 13. O-O Bd7 14. b3 e5 15. Rad1 exd4 16. exd4 Bxg4 17. Bxg4 f5 18. Bh5+ g6 19. Be2 O-O-O 20. f3 Nxd4 21. Bc4 Qc5 22. Kh1 Nxf3 23. gxf3 Rxd2 24. Rxd2 Rd8 25. Rg2 exf3 26. Rxf3 Rd1+ 27. Bf1 Qc1 28. Rgf2 Qc6 29. h3 h6 30. Kh2 Rd5 31. Bg2 Qd6+ 32. Kh1 Re5 33. Rg3 Qd1+ 34. Kh2 g5 35. h4 f4 36. Rc3 gxh4 37. Rxf4 Qd6 38. Bh3+ Kd8 39. Kh1 Re1+ 40. Rf1 Rxf1+ 41. Bxf1 Qd5+ 42. Bg2 Qd1+ 43. Kh2 Qa1 44. Rd3+ Ke7 45. Bxb7 Qxa2+ 46. Kh3 Qe2 47. Ba6 Qf1+ 48. Kxh4 Qf4+ 49. Kh3 h5 50. Rc3 h4 51. Rc2 Qf5+ 52. Kh2 Qxc2+ 53. Kh3 Qxb3+ 54. Kxh4 c5 55. Kh5 c4 56. Bxc4 Qxc4 57. Kg5 a5 58. Kf5 a4 59. Ke5 a3 60. Kf5 a2 61. Ke5 a1=Q+ 62. Kf5 Qd1 63. Ke5 Qce2+ 64. Kf5 Qdf1+ 65. Kg5 Qeg2+ 66. Kh5 Qfh1# 0-1",
    B: "1464",
  },
  {
    W: "1231",
    T: "300+0",
    P: "1. d4 d5 2. c4 dxc4 3. e4 b5 4. b3 cxb3 5. Bxb5+ Bd7 6. Bxd7+ Qxd7 7. Qxb3 Qxd4 8. Bb2 Qxe4+ 9. Ne2 Qxg2 10. Rg1 Qxh2 11. Bxg7 Bxg7 12. Rxg7 Qh1+ 13. Ng1 Qe4+ 14. Ne2 Qe5 15. Qxf7+ Kd7 16. Nc3 Nh6 17. Rd1+ Kc8 18. Qxe7 Qxe7 19. Rxe7 Nf5 20. Red7 Nc6 21. Nb5 Nd6 22. Rxc7+ Kd8 23. Rxd6+ Ke8 24. Rdxc6 a6 25. Nd6+ Kf8 26. Rc8+ Rxc8 27. Rxc8+ Kg7 28. Rxh8 Kxh8 29. f4 Kg7 30. f5 Kf6 31. Nd4 Ke5 32. Ne2 Kxd6 33. Ng3 Ke5 34. Kf2 Kf4 35. Kg2 Kg5 36. Kf3 h5 37. Ke4 h4 38. Nh1 h3 39. Ke5 h2 40. f6 Kg6 41. Ke6 Kg5 42. f7 Kg4 43. f8=Q Kh3 44. Qg8 Kh4 45. Qg2 Kh5 46. Qxh2+ Kg4 47. Qd2 Kf3 48. Qd6 Ke4 49. Qxa6 Kd4 50. a4 Ke4 51. Qa8+ Kd4 52. a5 Kc4 53. a6 Kd4 54. Qb8 Ke4 55. a7 Kd4 56. a8=R Ke4 57. Qd8 Kf4 58. Kd7 Ke4 59. Kc8 Ke5 60. Ra7 Ke4 61. Re7+ Kf5 62. Qf8+ Kg6 63. Rg7+ Kh6 64. Qh8# 1-0",
    B: "1194",
  },
  {
    W: "1483",
    T: "300+0",
    P: "1. d4 Nf6 2. h3 e6 3. Nf3 c5 4. a3 cxd4 5. Qxd4 Nc6 6. Qd1 a5 7. e3 d5 8. c4 Bc5 9. cxd5 Nxd5 10. Nc3 Nxc3 11. Qxd8+ Nxd8 12. bxc3 O-O 13. a4 Re8 14. Ba3 Bxa3 15. Rxa3 b6 16. Bb5 Re7 17. Ne5 Bb7 18. O-O Rc8 19. c4 Rc5 20. Nd3 Rg5 21. g3 f6 22. Nf4 h5 23. h4 Rc5 24. Ng6 Rf7 25. Rd1 Kh7 26. Nf4 Rc8 27. Rxd8 Rxd8 28. Nxh5 Bf3 29. Nf4 Rd1+ 30. Kh2 Rh1# 0-1",
    B: "1709",
  },
  {
    W: "1409",
    T: "300+0",
    P: "1. e4 c6 2. Bc4 d5 3. exd5 cxd5 4. Bb5+ Bd7 5. Bxd7+ Nxd7 6. Nc3 e6 7. d4 Nb6 8. Bf4 Nf6 9. Nf3 Bd6 10. Bxd6 Qxd6 11. Ne5 O-O 12. Nb5 Qd8 13. O-O a6 14. Nd6 Qxd6 15. b3 Nbd7 16. Nxd7 Qxd7 17. c4 dxc4 18. bxc4 Rfd8 19. Rb1 Rab8 20. f4 Qxd4+ 21. Qxd4 Rxd4 22. Rbc1 Rxf4 23. Rxf4 1-0",
    B: "1389",
  },
  {
    W: "2012",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. dxe5 dxe5 5. Bc4 c6 6. Bg5 Ngf6 7. O-O Bc5 8. Nc3 O-O 9. Re1 b5 10. Bb3 Qb6 11. Qe2 Ng4 12. Nd1 h6 13. Bh4 Re8 14. h3 Ngf6 15. Nc3 a5 16. a4 b4 17. Nd1 Ba6 18. Bc4 Bxc4 19. Qxc4 Re6 20. b3 Rae8 21. Nb2 g5 22. Bg3 Nh5 23. Kh2 Bxf2 24. Bxf2 Qxf2 25. Re2 Qg3+ 26. Kg1 Nf4 27. Rd2 Nf6 28. Kh1 Nxh3 29. gxh3 Qxf3+ 30. Kh2 Nxe4 31. Rg2 Qf4+ 32. Kg1 Qe3+ 33. Kh2 Nd2 34. Qd3 Nf3+ 35. Kh1 Qf4 36. Rf1 e4 37. Qd1 Rf6 38. Rgf2 Qg3 39. Qe2 Qxh3+ 40. Rh2 Qg3 41. Rg2 Qh4+ 42. Rh2 Qg4 43. Rxh6 Rxh6+ 0-1",
    B: "2012",
  },
  {
    W: "1066",
    T: "300+0",
    P: "1. d4 d5 2. c4 c6 3. g3 dxc4 4. Bg2 Nf6 5. Qa4 Bd7 6. Qxc4 Qa5+ 7. Bd2 Qb6 8. e3 Qxb2 9. Bc3 Qc1+ 10. Ke2 Bg4+ 11. Nf3 Bxf3+ 12. Bxf3 Qc2+ 13. Kf1 Ne4 14. Na3 Nd2+ 15. Kg2 Qf5 16. Bxd2 e6 17. Qb3 Nd7 18. Qxb7 Rb8 19. Qxa7 Rb2 20. Ba5 Rb5 21. Bxc6 Rxa5 22. Qxd7# 1-0",
    B: "1055",
  },
  {
    W: "1237",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. d3 Nc6 4. Nc3 Bg4 5. Be2 h6 6. d4 exd4 7. Nxd4 Bxe2 8. Ndxe2 Nf6 9. O-O Be7 10. Be3 O-O 11. Ng3 Qd7 12. f3 Rfe8 13. Nd5 Nxd5 14. exd5 Ne5 15. f4 Nc4 16. Bd4 Bh4 17. Nh5 g6 18. Bf6 gxh5 19. Bxh4 Ne3 20. Qxh5 Nxf1 21. Rxf1 Qb5 22. Qxh6 Re2 23. Qg5+ Kf8 24. Qh6+ Ke8 25. Qh8+ Kd7 26. Qxa8 Qc5+ 27. Bf2 Qxc2 28. Qxb7 Qg6 29. Qc6+ Kd8 30. Bh4+ Kc8 31. Qa8+ Kd7 32. Qd8# 1-0",
    B: "1294",
  },
  {
    W: "1458",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 f6 4. a3 d6 5. d3 Nge7 6. Nc3 Ng6 7. Be3 Bd7 8. Nd5 Be6 9. O-O Bxd5 10. Bxd5 Nf4 11. Bxc6+ bxc6 12. Qc1 d5 13. Bxf4 exf4 14. Qxf4 dxe4 15. Qxe4+ Be7 16. Rfe1 f5 17. Qxf5 Rf8 18. Qh5+ Kd7 19. Qh3+ Ke8 20. Re3 h6 21. Rae1 Rf7 22. Nd4 Qxd4 23. c3 Qd6 24. Re4 Kd8 25. R1e3 Ke8 26. Re6 Qd7 27. R3e4 Rd8 28. Qe3 c5 29. d4 cxd4 30. Rxd4 Qc8 31. h3 Rxd4 32. Qxd4 Qxe6 33. Qxa7 Qe1+ 34. Kh2 Bd6+ 35. g3 Rxf2+ 36. Qxf2 Qxf2+ 37. Kh1 Qxg3 38. b4 Qh2# 0-1",
    B: "1428",
  },
  {
    W: "1778",
    T: "300+0",
    P: "1. e4 e6 2. f4 d5 3. exd5 e5 4. Bb5+ Bd7 5. Bxd7+ Nxd7 6. fxe5 Qh4+ 7. g3 Qe4+ 8. Qe2 Qxh1 9. e6 Qxg1+ 10. Qf1 Qxf1+ 11. Kxf1 fxe6 12. dxe6 Nc5 13. d4 Nxe6 14. Bf4 Nxf4 15. gxf4 Nf6 16. Nc3 Bb4 17. Re1+ Kf7 18. Re3 Bxc3 19. Rxc3 c6 0-1",
    B: "1921",
  },
  {
    W: "2042",
    T: "300+0",
    P: "1. e4 e5 2. Nc3 Nc6 3. f4 d6 4. Nf3 Nf6 5. d4 exd4 6. Nxd4 Nxd4 7. Qxd4 c5 8. Bb5+ Bd7 9. Qa4 Bxb5 10. Qxb5+ Qd7 11. Qxd7+ Kxd7 12. Be3 Kc6 13. O-O-O b5 14. e5 dxe5 15. fxe5 Ng4 16. Rde1 Re8 17. Bf4 b4 18. Na4 Be7 19. h3 Nh6 20. Bxh6 gxh6 21. Rhf1 Bg5+ 22. Kb1 Re7 23. b3 Rd8 24. Nb2 Rd2 25. g4 Rh2 26. Rf3 f6 27. Nd3 fxe5 28. Nxe5+ Kb5 29. Rff1 Rxh3 30. Nd3 Rxe1+ 31. Rxe1 h5 32. gxh5 Rxh5 33. Kb2 Bf6+ 34. Kc1 Bc3 35. Rf1 Rh2 36. Kd1 h5 37. Rf7 a5 38. Rb7+ Kc6 39. Ra7 h4 40. Rxa5 Bd4 41. Ra6+ Kb5 42. Rh6 h3 43. Rh7 Rh1+ 44. Ke2 h2 45. Nf4 Rc1 46. Rxh2 Rxc2+ 47. Kf3 Rxh2 48. Ne6 Rxa2 49. Ke4 Re2+ 50. Kd5 Rxe6 51. Kxe6 c4 52. bxc4+ Kxc4 53. Kd7 b3 54. Ke6 b2 55. Kf7 b1=Q 56. Ke6 Qe1+ 0-1",
    B: "2061",
  },
  {
    W: "1688",
    T: "300+0",
    P: "1. e4 e6 2. Nf3 b6 3. d4 Bb7 4. Ng5 h6 5. Nxf7 Kxf7 6. d5 Qh4 7. Bd3 Bc5 8. g3 Qh3 9. Be3 exd5 10. Bf1 Qe6 11. Bg2 dxe4 12. O-O d5 13. Nd2 c6 14. Qh5+ g6 15. Qh4 Nf6 16. Bh3 Qe7 17. Bxc5 Qxc5 18. Nb3 Qxc2 19. Rac1 Qxb2 20. Rb1 Qxa2 21. Nd4 g5 22. Be6+ Kf8 23. Qh3 c5 24. Nf5 Nc6 25. Bd7 Nxd7 26. Nxh6 Nde5 27. Qf5+ Ke7 28. f4 Nf3+ 29. Kh1 Qxh2# 0-1",
    B: "1658",
  },
  {
    W: "1472",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 d6 4. d3 Nf6 5. a3 Na5 6. Ba2 Bd7 7. Ng5 d5 8. exd5 h6 9. Nf3 Bd6 10. b4 c6 11. bxa5 Qxa5+ 12. Bd2 Qb6 13. O-O O-O-O 14. Be3 c5 15. Nc3 Ng4 16. h3 Nxe3 17. fxe3 g5 18. Ne4 g4 19. Rb1 Qc7 20. Nxd6+ Qxd6 21. Nh4 gxh3 22. gxh3 Bxh3 23. Qf3 Rhg8+ 24. Kf2 Bxf1 25. Kxf1 f5 26. Ke2 e4 27. Qxf5+ Kb8 28. Qxe4 Qh2+ 29. Kf1 Rg1# 0-1",
    B: "1539",
  },
  {
    W: "994",
    T: "300+0",
    P: "1. d4 d5 2. e3 Nc6 3. Nc3 e5 4. Nf3 e4 5. Ne5 Nxe5 6. dxe5 f6 7. exf6 Qxf6 8. Bb5+ c6 9. Be2 Ne7 10. O-O Nf5 11. g4 Bd6 12. gxf5 Qxf5 13. Bg4 Qf6 14. Bh5+ g6 15. Be2 g5 16. Bh5+ Kf8 17. Qd4 Qh6 18. Qxh8+ Ke7 19. Qe8+ Kf6 20. Qf7+ Ke5 21. f4+ gxf4 22. Rxf4 Be6 23. Rxe4+ dxe4 0-1",
    B: "839",
  },
  {
    W: "1483",
    T: "60+0",
    P: "1. e4 e6 2. Nc3 d5 3. d3 d4 4. Nce2 e5 5. Bg5 f6 6. Bd2 Bc5 7. Nf3 Ne7 8. g3 O-O 9. Bg2 Bg4 10. O-O Qd7 11. c3 Bh3 12. cxd4 exd4 13. b4 Bb6 14. a3 Bxg2 15. Kxg2 Ng6 16. Bc1 Qg4 17. Bb2 Nc6 18. Nfxd4 Nxd4 19. Nxd4 Qxd1 20. Raxd1 Rad8 21. Ne6 Rxd3 22. Rxd3 Ne5 23. Nxf8 Kxf8 24. Rdd1 Kf7 25. f4 Nc4 26. Ba1 Ne3+ 27. Kf3 Nxd1 28. Rxd1 g6 29. e5 Ke6 30. exf6 c5 31. Re1+ Kf7 32. bxc5 Bxc5 33. Re7+ Bxe7 34. fxe7 Kxe7 35. g4 Kd6 36. h4 Kd5 37. f5 gxf5 38. gxf5 Kd6 39. Kg4 Ke7 40. Kh5 Kf7 41. Kh6 Kg8 42. h5 b6 43. f6 b5 44. Bb2 Kf7 45. Kxh7 1-0",
    B: "1442",
  },
  {
    W: "2148",
    T: "60+0",
    P: "1. d4 d5 2. Bg5 Nf6 3. Bxf6 exf6 4. e3 c5 5. c3 cxd4 6. Nf3 Nc6 7. exd4 Bd6 8. Bd3 O-O 9. O-O Bg4 10. Nbd2 Re8 11. h3 Bh5 12. Re1 a5 13. Rxe8+ Qxe8 14. Qc2 Qd8 15. Bxh7+ Kf8 16. Bd3 Qb6 17. Re1 a4 18. a3 Rd8 19. Nh2 Ra8 20. Ndf1 Qc7 21. Kh1 Na5 22. g4 Bg6 23. Bxg6 fxg6 24. Qxg6 Nc4 25. Re2 Qb6 26. Qc2 Na5 27. h4 Nb3 28. g5 fxg5 29. hxg5 Qc7 30. Qf5+ Kg8 31. Ng4 Rf8 32. Qg6 Bf4 33. Nh6+ Kh8 34. Nf5 Qf7 35. Qxf7 Rxf7 36. Nd6 Bxd6 37. Re8+ Rf8 38. Kg2 Rxe8 39. Ng3 Nd2 40. Nf5 Ne4 41. g6 Rf8 42. Ne3 Bf4 43. Nxd5 1-0",
    B: "2091",
  },
  {
    W: "1187",
    T: "60+0",
    P: "1. e4 e5 2. Bc4 Nf6 3. Nc3 Bc5 4. Nge2 Ng4 5. O-O Nxf2 6. Rxf2 Bxf2+ 7. Kxf2 Qf6+ 8. Kg1 c6 9. d4 d6 10. dxe5 dxe5 11. Be3 Nd7 12. Qf1 Qxf1+ 13. Rxf1 c5 14. Bxf7+ Ke7 15. Nd5+ Kd6 16. c3 b6 17. b4 cxb4 18. cxb4 Ba6 19. Nec3 Bxf1 20. Kxf1 Raf8 21. Bf4 Rxf7 22. Nc7 Rxf4+ 23. Ke2 1-0",
    B: "917",
  },
  {
    W: "1339",
    T: "60+0",
    P: "1. e3 e5 2. d3 d5 3. c3 Nf6 4. Qa4+ c6 5. Nf3 Bd7 6. Nxe5 c5 7. Nxd7 Nbxd7 8. Qb3 Ne5 9. Qxb7 c4 10. dxc4 dxc4 11. Bxc4 Nxc4 12. Qb5+ Qd7 13. Qxc4 Rc8 14. Qe2 Bd6 15. O-O O-O 16. g3 Ng4 17. Rd1 Qc6 18. Qxg4 Rfe8 19. Qf5 g6 20. Qf6 Qc7 21. Rxd6 Re6 22. Rxe6 Re8 23. Rxe8# 1-0",
    B: "1230",
  },
  {
    W: "2041",
    T: "60+0",
    P: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. b6 d6 6. Nc3 g6 7. e4 Bg7 8. f4 Nbd7 9. Nf3 O-O 10. Bd3 Nxb6 11. O-O c4 12. Bc2 Qc7 13. Be3 Nbd7 14. Qe2 Nc5 15. Bd4 e6 16. Bxf6 Bxf6 17. e5 Bg7 18. Qxc4 Qb6 19. Kh1 dxe5 20. fxe5 exd5 21. Nxd5 Qa7 22. b4 Ne6 23. Nf6+ Kh8 24. Be4 Bb7 25. Bxb7 Qxb7 26. Qh4 h6 27. Rad1 Rad8 28. Qc4 Rc8 29. Qg4 Rfd8 30. h4 Rxd1 31. Rxd1 Qb5 32. h5 1-0",
    B: "2044",
  },
  {
    W: "2253",
    T: "60+0",
    P: "1. f4 d5 2. Nf3 e6 3. g3 Nf6 4. Bg2 g6 5. O-O Bg7 6. d3 O-O 7. Nc3 b6 8. e4 Bb7 9. Qe2 Nbd7 10. Be3 c5 11. Bf2 d4 12. Nd1 Re8 13. h3 e5 14. f5 a6 15. Nh4 b5 16. g4 c4 17. g5 Nh5 18. Be1 Nf4 19. Qf3 Nxg2 20. Qxg2 cxd3 21. cxd3 f6 22. fxg6 fxg5 23. gxh7+ Kxh7 24. Nf5 Rf8 25. Qg4 Nf6 26. Qxg5 Qe8 27. Kh2 Qg6 28. Rg1 Qxg5 29. Rxg5 Bh6 30. Rg2 Bf4+ 31. Kh1 Nh5 32. Bh4 Rxf5 33. exf5 Bxg2+ 34. Kxg2 Rg8+ 35. Kf3 Bg5 36. Bxg5 Rxg5 37. Ke4 Ng3+ 38. Kxe5 Nxf5 39. Kf4 Rh5 40. Nf2 Ne3 41. Ng4 Nxg4 42. hxg4 Rh6 43. g5 Rh4+ 44. Kf5 Rh2 45. Rg1 Rxb2 46. Rg4 Kg7 47. Rf4 Rg2 48. Rh4 Rxg5+ 49. Kxg5 1-0",
    B: "2221",
  },
  {
    W: "1694",
    T: "60+0",
    P: "1. e4 e6 2. d4 e5 3. dxe5 Nc6 4. Nf3 d6 5. exd6 Bxd6 6. Bb5 Bd7 7. O-O Nf6 8. Re1 Ne5 9. Bxd7+ Qxd7 10. Nxe5 Bxe5 11. Qxd7+ Kxd7 12. c3 Rhd8 13. Bg5 Ke7 14. Nd2 Kf8 15. Nf3 Re8 16. Bxf6 Bxf6 17. Rad1 Rad8 18. Rxd8 Rxd8 19. g3 1-0",
    B: "1730",
  },
  {
    W: "1767",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nd4 5. Nxd4 exd4 6. d3 c6 7. Ba4 b5 8. Bb3 a5 9. a3 c5 10. Ba2 d5 11. Bg5 c4 12. exd5 Be7 13. d6 Qxd6 14. Bxf6 Bxf6 15. dxc4 O-O 16. cxb5 Be6 17. Bxe6 fxe6 18. Nd2 Qb6 19. Ne4 Qxb5 20. Rb1 Be5 21. f4 Rxf4 22. Rxf4 Bxf4 23. c4 Qb6 24. c5 Qd8 25. Qh5 e5 26. Nd6 Qf8 27. Rf1 Be3+ 28. Rf2 Qxf2+ 29. Kh1 Qf1# 0-1",
    B: "1731",
  },
  {
    W: "2013",
    T: "60+0",
    P: "1. d4 g6 2. e4 Bg7 3. Nf3 d5 4. exd5 c5 5. dxc5 b6 6. cxb6 Qxb6 7. Bd3 Bxb2 8. Bxb2 Qxb2 9. Nbd2 Nd7 10. O-O Ngf6 11. d6 exd6 12. Re1+ Kf8 13. Nc4 Qc3 14. Nxd6 Kg7 15. Rb1 a5 16. Bc4 Ba6 17. Bxf7 Rhf8 18. Be6 Nc5 19. Bb3 Ra7 20. Qd4 Qxd4 21. Nxd4 Rd7 22. N4b5 Bxb5 23. Nxb5 Rd2 24. Re7+ Kh6 25. f3 a4 26. Be6 Rxc2 0-1",
    B: "1868",
  },
  {
    W: "1273",
    T: "60+0",
    P: "1. b3 e6 2. Bb2 a6 3. Nf3 Bc5 4. c3 Ba7 5. e3 c6 6. d4 f6 7. Bd3 e5 8. dxe5 fxe5 9. Nxe5 Nh6 10. c4 d6 11. Qh5+ g6 12. Nxg6 Rg8 13. Qxh6 Rxg6 14. Bxg6+ hxg6 15. Qxg6+ Kd7 16. Qg7+ Ke8 17. Qh8+ Kd7 18. Qh7+ Ke8 19. Qg7 d5 20. Bf6 Qd7 21. Qh8+ Kf7 22. Qg7+ Ke6 23. cxd5+ cxd5 24. Qg5 Nc6 25. O-O 1-0",
    B: "1205",
  },
  {
    W: "2255",
    T: "60+0",
    P: "1. e4 c5 2. d4 cxd4 3. c3 d3 4. Bxd3 g6 5. c4 Bg7 6. Nc3 Nf6 7. Nf3 O-O 8. h3 Nc6 9. O-O e5 10. Nd5 Nxd5 11. exd5 Nb8 12. d6 Nc6 13. c5 h6 14. Re1 f5 15. Bc4+ Kh8 16. a3 e4 17. Nh2 a6 18. Rb1 b5 19. Ba2 Bb7 20. Bd2 Ne5 21. Bc3 Nd3 22. Bxg7+ Kxg7 23. Re3 Qh4 24. Qd2 f4 25. Rxd3 exd3 26. Qxd3 Rae8 27. Nf3 Qf6 28. Rd1 Be4 29. Qd2 Bxf3 30. gxf3 Re5 31. Kf1 Rxc5 32. b4 Re5 33. Qd4 Rfe8 34. Rd2 Re1+ 35. Kg2 R8e5 36. Kh2 Qh4 37. Qxe5+ Rxe5 38. Re2 Rxe2 0-1",
    B: "2312",
  },
  {
    W: "1499",
    T: "60+0",
    P: "1. d4 d5 2. e3 Nf6 3. Nf3 c5 4. Bb5+ Nc6 5. Bxc6+ bxc6 6. dxc5 e5 7. O-O e4 8. Nd4 Bxc5 9. c3 Bxd4 10. cxd4 O-O 11. Nd2 Qc7 12. f3 Bf5 13. fxe4 Bxe4 14. Nxe4 Nxe4 15. b3 c5 16. Ba3 Rfe8 17. Bxc5 Nxc5 18. dxc5 Qxc5 19. Rc1 Qxe3+ 20. Kh1 Rac8 21. Qxd5 Rxc1 22. Rxc1 Qe1+ 23. Rxe1 Rxe1# 0-1",
    B: "1555",
  },
  {
    W: "1999",
    T: "60+0",
    P: "1. d4 c6 2. c4 d5 3. Nc3 dxc4 4. e4 e6 5. Bxc4 e5 6. f4 exd4 7. Nce2 c5 8. Nf3 Nc6 9. O-O Nf6 10. e5 Nd5 11. Qb3 Na5 12. Qa4+ Nc6 13. Nexd4 cxd4 14. Nxd4 Bd7 15. Qb3 Nxd4 16. Bxd5 Nxb3 0-1",
    B: "2073",
  },
  {
    W: "1796",
    T: "120+1",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 Nc6 4. c3 a6 5. d4 b5 6. Bb3 c4 7. Bc2 e6 8. O-O Nf6 9. Re1 Be7 10. Nbd2 O-O 11. Nf1 Bb7 12. Ng3 h6 13. h3 Qc7 14. Be3 Rad8 15. Rc1 Qb8 16. Bb1 Kh8 17. Qc2 Ng8 18. e5 g6 19. Nh4 Bxh4 20. Ne4 d5 21. Nd6 Be7 22. Nxf7+ Rxf7 23. Qxg6 Rg7 24. Qxe6 Qc8 25. Qxc8 Bxc8 26. g4 Bg5 27. f4 Bh4 28. Rf1 Nge7 29. f5 h5 30. f6 hxg4 31. fxg7+ Kxg7 32. hxg4 Bxg4 33. Rf4 Ng6 34. Rxg4 Ne7 35. Bxg6 1-0",
    B: "1787",
  },
  {
    W: "1458",
    T: "120+1",
    P: "1. d4 d5 2. Nf3 Nc6 3. e3 Bf5 4. g3 Nb4 5. Na3 Qd6 6. c3 Nc6 7. Bg2 e5 8. dxe5 Nxe5 9. Nxe5 Qxe5 10. Bxd5 Rd8 11. Bxf7+ Kxf7 12. Qxd8 Be7 13. Qd2 Be4 14. Rg1 Qc5 15. b4 Qc6 16. Bb2 Bf6 17. Rc1 Qe6 18. Qe2 Qxa2 19. Ra1 Bxc3+ 20. Bxc3 Qxa1+ 21. Bxa1 1-0",
    B: "1446",
  },
  {
    W: "2023",
    T: "120+1",
    P: "1. d4 d6 2. c4 g6 3. Nc3 Bg7 4. e4 Nf6 5. Nf3 c5 6. Be2 cxd4 7. Nxd4 Nc6 8. Be3 O-O 9. O-O Bd7 10. Rc1 Rc8 11. b3 a6 12. Qd2 Re8 13. f3 Qa5 14. Rfd1 Ne5 15. Nb1 Qxd2 16. Rxd2 b5 17. Na3 bxc4 18. Nxc4 Nxc4 19. Bxc4 Bb5 20. Rdc2 Bxc4 21. Rxc4 Rxc4 22. Rxc4 Ra8 23. Kf2 Kf8 24. Ke2 a5 25. Kd3 Nd7 26. Nc6 Rc8 27. Nxa5 Ne5+ 28. Kd2 Nxc4+ 29. Nxc4 Ke8 30. Kd3 Ra8 31. a3 Bb2 32. Nxb2 Rxa3 33. Kc4 Ra2 34. Bd4 Kd7 35. h4 Kc6 36. b4 e6 37. b5+ Kb7 38. g4 Ra3 39. Nd3 Ra2 40. e5 d5+ 41. Kc5 Rc2+ 42. Kd6 Rd2 43. Nc5+ Kc8 44. Bc3 Rc2 45. Ba5 Rf2 46. Bc7 Rxf3 47. b6 Rb3 48. Nxb3 1-0",
    B: "2071",
  },
  {
    W: "1195",
    T: "120+1",
    P: "1. b4 g6 2. Bb2 Nf6 3. g4 Bg7 4. g5 Nh5 5. Bxg7 Nxg7 6. h4 Nh5 7. e3 d6 8. Be2 Nc6 9. Bxh5 gxh5 10. Qxh5 Nxb4 11. g6 fxg6 12. Qg4 Nxc2+ 0-1",
    B: "1226",
  },
  {
    W: "1753",
    T: "120+1",
    P: "1. e4 c5 2. Nc3 e6 3. Nf3 Nf6 4. d4 cxd4 5. Nxd4 Nc6 6. Nxc6 dxc6 7. e5 Qxd1+ 8. Kxd1 Nd5 9. Nxd5 cxd5 10. Bb5+ Bd7 11. Bxd7+ Kxd7 12. Be3 b6 13. Kd2 Rc8 14. c3 g6 15. Bg5 Bg7 16. f4 h6 17. Bh4 g5 18. fxg5 hxg5 19. Bxg5 Bxe5 20. h3 d4 21. cxd4 Bxd4 22. Rab1 f6 23. Be3 Be5 24. Rhd1 Ke7 25. Ke2 Rc2+ 26. Rd2 Rxd2+ 27. Bxd2 Rc8 28. Bb4+ Kf7 29. Kd3 Rg8 30. Rg1 Bh2 31. Rf1 Rxg2 32. Bc3 e5 33. Kc4 Rg3 34. Kb5 Rxh3 35. Ka6 Rh7 36. Kxa7 Ke6+ 37. Kxb6 f5 38. Kc5 e4 39. Kd4 Be5+ 40. Ke3 Bxc3 41. bxc3 Ra7 42. Rf2 Ke5 43. c4 Ra4 44. Rc2 f4+ 45. Kf2 e3+ 46. Kf3 Kd4 47. c5 Ra8 48. c6 Rc8 49. Kxf4 Kd3 50. Rc1 e2 51. Ke5 Kd2 52. Rh1 Rxc6 53. a3 Ra6 54. Ra1 Ra4 55. Kd5 e1=Q 56. Rxe1 Kxe1 0-1",
    B: "1699",
  },
  {
    W: "1439",
    T: "120+1",
    P: "1. d4 d5 2. e4 dxe4 3. Nc3 e6 4. Nxe4 Nc6 5. Nf3 Nf6 6. Bg5 Be7 7. Bd3 h6 8. Bh4 g5 9. Bg3 Nxe4 10. Bxe4 f5 11. Bxc6+ bxc6 12. Be5 Bf6 13. O-O O-O 14. c3 g4 15. Nd2 Bxe5 16. dxe5 Bb7 17. Nc4 h5 18. Qb3 Rb8 19. Na5 Ba8 20. Qxe6+ Kg7 21. Nxc6 Bxc6 22. Qxc6 Re8 23. Rfe1 Rb6 24. Qc4 Rxb2 25. e6 Qd2 26. Qd4+ Kg6 27. Qxd2 Rxd2 28. a4 Kf6 29. Rab1 Rxe6 30. Rb7 Rxe1# 0-1",
    B: "1420",
  },
  {
    W: "1657",
    T: "120+1",
    P: "1. d4 d5 2. e3 Nf6 3. c4 c6 4. Nc3 e6 5. Nf3 Be7 6. b3 O-O 7. Bb2 Nbd7 8. Qc2 Bb4 9. Be2 Ne4 10. O-O Bxc3 11. Bxc3 Nxc3 12. Qxc3 Nf6 13. Nd2 Bd7 14. Qc2 Rc8 15. Bd3 h6 16. Rac1 c5 17. dxc5 Rxc5 18. b4 Rc8 19. c5 b6 20. Nb3 Ba4 21. Qb2 Bxb3 22. Qxb3 bxc5 23. bxc5 Qc7 24. Ba6 Rb8 25. Qd3 Rb2 26. Qa3 Rfb8 27. c6 Ne4 28. Bb7 Rb6 29. Qxa7 Nd2 30. Rfe1 Nc4 31. Rxc4 dxc4 32. Qa4 c3 33. Rc1 Rd8 34. g3 Rd3 35. Qc4 Qd6 36. Rxc3 Rd1+ 37. Kg2 Rb5 38. Qxb5 1-0",
    B: "1602",
  },
  {
    W: "1048",
    T: "120+1",
    P: "1. f4 d5 2. Nf3 e6 3. d4 Ne7 4. e3 g6 5. Bb5+ c6 6. b3 cxb5 7. Bb2 Bg7 8. Ne5 O-O 9. Ng4 Qa5+ 10. c3 b4 11. cxb4 Qxb4+ 12. Nd2 Nbc6 13. O-O a5 14. a3 Qb6 15. Nf3 a4 16. bxa4 Qxb2 17. Rb1 Qxa3 18. Nfe5 Bxe5 19. Nxe5 Nxe5 20. fxe5 Nf5 21. Qg4 Qxe3+ 22. Kh1 Qxd4 23. g3 Qxg4 24. Rb6 Qe4+ 25. Kg1 Qe3+ 0-1",
    B: "1167",
  },
  {
    W: "1833",
    T: "120+1",
    P: "1. e4 e6 2. c4 d5 3. cxd5 exd5 4. exd5 Qxd5 5. Nc3 Qe5+ 6. Qe2 Qxe2+ 7. Bxe2 Nf6 8. d4 Bd6 9. Bg5 Nbd7 10. Nf3 O-O 11. O-O h6 12. Be3 Re8 13. Bc4 Nb6 14. Bb3 Bf5 15. d5 Re7 16. Nd4 Bg6 17. Ndb5 a6 18. Bxb6 cxb6 19. Nxd6 Rb8 20. Rfe1 Rd7 21. Nce4 Bxe4 22. Nxe4 Nxe4 23. Rxe4 Rbd8 24. Rd1 Rd6 25. Re7 R8d7 26. Re8+ Kh7 27. Bc2+ g6 28. Re5 f6 29. Ree1 Rxd5 30. Bb3 Rxd1 31. Rxd1 Rxd1+ 32. Bxd1 f5 33. Bf3 Kg7 34. Bxb7 Kf6 35. Bxa6 Ke5 36. Bc4 g5 37. Kf1 h5 38. Ke2 f4 39. Kd3 Kd6 40. f3 g4 41. Bf7 Ke5 42. Bxh5 gxf3 43. Bxf3 b5 44. Kc3 b4+ 45. Kxb4 Kd4 46. a4 Ke3 47. a5 Kf2 48. h4 Kg1 49. a6 Kh2 50. h5 Kg3 51. h6 Kf2 52. h7 Kg3 53. h8=Q Kf2 54. Qh1 Kg3 55. Qe1+ Kh2 56. g3 fxg3 57. Qe2+ Kh3 58. Bh1 g2 59. Qxg2+ Kh4 60. Kc5 Kh5 61. Kd6 Kh6 62. Ke7 Kh7 63. Kf6 Kh8 64. Qg7# 1-0",
    B: "1797",
  },
  {
    W: "1723",
    T: "300+0",
    P: "1. e4 d5 2. Nf3 dxe4 3. Ng5 h6 4. Nxe4 Nc6 5. Nbc3 e5 6. Bc4 Nf6 7. d3 Bb4 8. O-O Nxe4 9. Nxe4 O-O 10. Bd2 Bxd2 11. Qxd2 Bf5 12. Rfe1 Bxe4 13. Rxe4 Na5 14. Qxa5 Re8 15. Rae1 Qf6 16. Rxe5 Rxe5 17. Qxe5 Qg6 18. d4 Qxc2 19. Bb3 Qxb2 20. Qxc7 Qxd4 21. Qxf7+ Kh8 22. Re8+ Rxe8 23. Qxe8+ Kh7 24. Bc2+ g6 25. Qf7+ Kh8 26. Qe8+ Kh7 27. Qxg6+ Kh8 28. Qxh6+ Kg8 29. Bb3+ Qc4 30. Bxc4# 1-0",
    B: "1615",
  },
  {
    W: "2417",
    T: "60+0",
    P: "1. d4 c5 2. d5 e6 3. c4 d6 4. Nc3 e5 5. e4 Nf6 6. g3 Be7 7. Bg2 O-O 8. Nge2 Ne8 9. O-O g6 10. f4 f6 11. h3 Nd7 12. a4 Ng7 13. f5 g5 14. h4 g4 15. Ra3 h5 16. Kh2 Rf7 17. Qd2 Nf8 18. Qh6 Nh7 19. Qxh5 Nxh5 20. Nd1 a6 21. Nf2 Bd7 22. Nxg4 b5 23. Nh6+ Kg7 24. Nxf7 Kxf7 25. axb5 axb5 26. Rxa8 Qxa8 27. b3 bxc4 28. bxc4 Qa2 29. Rf2 Qxc4 30. g4 Nf4 31. Nxf4 exf4 32. Bxf4 Ba4 33. Rb2 Bb3 34. Rd2 Qc3 35. Rf2 Nf8 36. g5 fxg5 37. hxg5 Qh8+ 38. Kg1 Qh5 39. g6+ Kf6 40. Bg3 c4 41. Bf3 Qg5 42. Kh2 Nxg6 43. Bg2 Nh4 44. Bh3 Qh5 45. Bg4 1-0",
    B: "2334",
  },
  {
    W: "1995",
    T: "180+0",
    P: "1. e4 c6 2. Nc3 d5 3. exd5 cxd5 4. d4 Nf6 5. h3 g6 6. Bd3 Bg7 7. Nf3 O-O 8. O-O Nc6 9. Re1 Bf5 10. Bxf5 gxf5 11. Bg5 e6 12. Ne2 Qb6 13. b3 Ne4 14. Be3 Rac8 15. c4 Nb4 16. c5 Qc7 17. a3 Nc6 18. Ra2 b6 19. b4 bxc5 20. bxc5 Qa5 21. h4 Qa6 22. Ng5 Na5 23. Nxe4 fxe4 24. Nf4 Nc4 25. a4 Kh8 26. Qh5 Nxe3 27. fxe3 Rb8 28. Rf1 Rb3 29. Qe2 Qxe2 30. Nxe2 Rxe3 31. Kf2 Rb3 32. g4 f5 33. g5 f4 34. Rd1 Rf3+ 35. Kg2 Rb3 36. Kf2 f3 37. Ng3 e3+ 38. Ke1 f2+ 39. Kf1 Rfb8 40. Ne2 Rb1 41. Ra1 Rxa1 42. Rxa1 Rb2 43. Rd1 1-0",
    B: "1984",
  },
  {
    W: "1158",
    T: "180+0",
    P: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 e5 5. Nf3 Nc6 6. Bc4 Be7 7. Qd5 Qb6 8. Qxf7+ Kd8 9. Qxg7 Nf6 10. Qxh8+ Kc7 11. O-O d6 12. Bg5 Ng4 13. Nd5+ Kd7 14. Nxb6+ axb6 15. Qxh7 Ra4 16. Bb5 Rxe4 17. Qxe7# 1-0",
    B: "1751",
  },
  {
    W: "2021",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd6 4. Nf3 Nc6 5. Bb5 Bg4 6. d3 O-O-O 7. Bxc6 Qxc6 8. Ne4 f5 9. Neg5 Qg6 10. h3 Bh5 11. Ne5 Bxd1 12. Nxg6 hxg6 13. Nf7 Bxc2 14. Nxh8 Bxd3 15. Nxg6 Nf6 16. Nxf8 Rxf8 17. Be3 Ne4 18. Rd1 Bc4 19. b3 Bb5 20. f3 Nc3 21. Rd2 f4 22. Bd4 Nb1 23. Rd1 Na3 24. Kf2 Re8 25. Rhe1 Bc6 26. Bxg7 Nb5 27. Bb2 a6 28. a4 Nd6 29. Be5 Kd7 30. Bxd6 exd6 31. Rxe8 Kxe8 32. Rd4 Kd7 33. Rxf4 b6 34. h4 Bd5 35. h5 Bxb3 36. h6 Bc2 37. h7 Bxh7 38. Rf7+ Kc6 39. Rxh7 d5 40. g4 Kd6 41. g5 c5 42. g6 Ke6 43. g7 Kf7 44. Ke3 c4 45. Kd4 b5 46. axb5 axb5 47. Kxd5 c3 48. Rh2 Kxg7 49. Kc5 Kf6 50. Kxb5 c2 51. Rxc2 Kf5 52. Rc4 Ke5 53. Kc6 Kf5 54. Re4 Kf6 55. Kd6 Kf5 56. Kd7 Kf6 57. f4 Kf5 58. Rd4 Kf6 59. Kd6 Kf5 60. Re4 Kxe4 61. Ke6 Kxf4 1/2-1/2",
    B: "1768",
  },
  {
    W: "1882",
    T: "180+0",
    P: "1. d4 c5 2. e3 e6 3. c3 d5 4. a3 Nf6 5. f4 cxd4 6. cxd4 Ne4 7. Nf3 Nc6 8. Bd3 f5 9. O-O h5 10. Ng5 Nxg5 11. fxg5 Qxg5 12. e4 Qg4 13. exd5 Qxd4+ 14. Kh1 Qxd5 15. Nc3 Qd8 16. Qe2 Bd7 17. Bxf5 Nd4 18. Bg6+ Ke7 19. Nd5+ Kd6 20. Bf4+ Kc6 21. Qc4+ Bc5 22. b4 b6 23. bxc5 exd5 24. Qxd4 bxc5 25. Rac1 Qb6 26. Qxg7 Rag8 27. Qe5 Rxg6 28. Qxh8 Kb7 29. Rb1 Bb5 30. a4 a6 31. Qh7+ Ka8 32. axb5 axb5 33. Ra1+ Qa6 34. Qxg6 Qxa1 35. Rxa1+ 1-0",
    B: "1885",
  },
  {
    W: "1413",
    T: "300+3",
    P: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Bf5 5. Bd3 e6 6. Nf3 Nd7 7. Ng3 Bxd3 8. Qxd3 Qc7 9. O-O O-O-O 10. Rd1 g6 11. b3 h5 12. Bb2 Rh7 13. Ng5 Rh8 14. Nxf7 h4 15. Ne4 Rh5 16. Nxd8 Qxd8 17. h3 g5 18. Bc1 Be7 19. Nc3 Nh6 20. Qe2 Qg8 21. Qxh5 Qg7 22. Re1 g4 23. Bxh6 gxh3 24. Bxg7 1-0",
    B: "1415",
  },
  {
    W: "1495",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nf6 3. Bc4 d5 4. exd5 Nxd5 5. Nxe5 Be6 6. Qf3 Bd6 7. Bxd5 Bxe5 8. Bxe6 fxe6 9. Qxb7 Nc6 10. Qxc6+ 1-0",
    B: "1500",
  },
  {
    W: "1535",
    T: "300+3",
    P: "1. d4 d5 2. c4 dxc4 3. Nf3 c5 4. e3 cxd4 5. Nxd4 e5 6. Qa4+ Nd7 7. Nf3 a6 8. Qxc4 Ngf6 9. Nc3 Be7 10. Be2 O-O 11. O-O b5 12. Qb3 e4 13. Nd2 Nc5 14. Qc2 Bf5 15. Rd1 Nd3 16. Nf1 Qc7 17. Ng3 Bg6 18. b4 Qe5 19. a3 Nh5 20. Nxh5 Bxh5 21. Bxh5 Qxh5 22. Nxe4 Nxc1 23. Raxc1 f5 24. Ng3 Qg4 25. h3 Qg5 26. Qb2 f4 27. exf4 Qxf4 28. Rc7 Bf6 29. Qa2+ Kh8 30. Rc2 Bh4 31. Rd3 Rfe8 32. Rf3 Qd6 33. Qa1 Rf8 34. Nf5 Rxf5 35. Rxf5 Bf6 36. Qc1 Qe6 37. Rfc5 h6 38. Rc8+ Rxc8 39. Rxc8+ Kh7 40. Qc2+ g6 41. Qc7+ Be7 42. Qc1 Bd6 43. Rc6 Qe5 44. g3 h5 45. Rxa6 h4 46. Ra7+ Kg8 47. Qc8+ Bf8 48. Qg4 Qe1+ 49. Kg2 hxg3 50. Qxg6+ Kh8 51. Qh7# 1-0",
    B: "1611",
  },
  {
    W: "1767",
    T: "300+3",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Bg5 Bg4 6. e3 Ne4 7. cxd5 Nxg5 8. h3 Nxf3+ 9. gxf3 Bd7 10. e4 c6 11. dxc6 Nxc6 12. Bb5 Nxd4 13. Bxd7+ Qxd7 14. Nd5 O-O 15. Nxe7+ Qxe7 16. Qd2 Nxf3+ 17. Ke2 Nxd2 18. Rhd1 Rfd8 19. Rxd2 Rxd2+ 20. Kxd2 Qxe4 21. Rc1 Rd8# 0-1",
    B: "1796",
  },
  {
    W: "1270",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. d4 Bb4+ 5. Bd2 b5 6. Bxb5 Bxd2+ 7. Qxd2 exd4 8. Nxd4 Ne5 9. Nc3 Nf6 10. Nf5 a6 11. Nxg7+ Kf8 12. Bxd7 Bxd7 13. Nf5 Bxf5 14. Qxd8+ Rxd8 15. exf5 Nc4 16. b3 Nd2 17. O-O-O Kg7 18. Rxd2 Rxd2 19. Kxd2 Rd8+ 20. Kc1 Ng4 21. f3 Nf2 22. Rf1 Kf6 23. Rxf2 Kxf5 24. Rd2 Rg8 25. Nd5 Rd8 26. Nxc7 Rxd2 27. Kxd2 a5 28. Nd5 Ke5 29. Nc7 Kd4 30. g4 Kc5 31. Ke3 Kb4 32. h4 Ka3 33. Nd5 Kxa2 34. c4 Kxb3 35. c5 a4 36. c6 a3 37. Kd2 a2 38. c7 a1=Q 39. c8=Q Qa2+ 40. Kd3 Qb1+ 41. Kd4 Qd1+ 42. Ke5 Qa1+ 43. Kf5 Qb1+ 44. Kf6 Qg6+ 45. Ke7 Qd3 46. Qb7+ Kc2 47. Nb4+ Kd2 48. Nxd3 Kxd3 49. Qb3+ 1-0",
    B: "1228",
  },
  {
    W: "1187",
    T: "300+3",
    P: "1. e4 c5 2. d3 Nc6 3. Nf3 e6 4. Be2 d5 5. exd5 exd5 6. O-O Nf6 7. Nc3 Be7 8. b3 O-O 9. Bb2 a6 10. Rb1 d4 11. Ne4 b6 12. Nxf6+ Bxf6 13. c3 Bb7 14. cxd4 Nxd4 15. Nxd4 Bxd4 16. Bxd4 Qxd4 17. Bf3 Bxf3 18. Qxf3 Rad8 19. Rfd1 Rfe8 20. Qc6 Re2 21. Rf1 Rxa2 22. Qxb6 Qxd3 23. Qxc5 Rd2 24. g3 h6 25. b4 Qb5 26. Qxb5 axb5 27. Ra1 Rd1 28. Rfxd1 Rxd1+ 29. Rxd1 1-0",
    B: "1200",
  },
  {
    W: "1076",
    T: "300+3",
    P: "1. d4 d5 2. c4 dxc4 3. e4 e5 4. Nf3 exd4 5. Nxd4 Bb4+ 6. Nc3 h5 7. Qa4+ c6 8. Qxb4 Qxd4 9. Bxc4 b5 10. Bxf7+ 1-0",
    B: "1157",
  },
  {
    W: "2250",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. e5 d5 6. Bb5 Bb4+ 7. c3 dxc3 8. bxc3 Bc5 9. exf6 Qxf6 10. O-O Be6 11. Bg5 Qg6 12. Bd3 Qh5 13. Nbd2 h6 14. Be3 Bd6 15. Be2 Qg6 16. Nd4 Nxd4 17. cxd4 O-O-O 18. f4 Rhe8 19. f5 Bxf5 20. Rxf5 Rxe3 21. Bg4 Kb8 22. Rf2 Rde8 23. Bf3 c6 24. Re2 Qg5 25. Nf1 Rxe2 26. Bxe2 Re4 27. Bf3 Rf4 28. Rb1 b5 29. a4 a6 30. axb5 axb5 31. Qe2 Kc7 32. Ra1 Kb7 33. Ng3 Rxd4 34. Qe8 Kc7 35. Qxf7+ Kb6 36. Qa7# 1-0",
    B: "1850",
  },
  {
    W: "2120",
    T: "180+2",
    P: "1. e4 d5 2. exd5 Nf6 3. Nf3 c6 4. dxc6 Nxc6 5. Be2 e5 6. d3 Bd6 7. O-O Bg4 8. Bg5 h5 9. Nbd2 Qc7 10. a3 O-O-O 11. c4 Nd4 12. Nxd4 exd4 13. Bf3 Bxh2+ 14. Kh1 Bf4 15. Bxf4 Qxf4 16. g3 Qg5 17. Kg2 Kb8 18. Qb3 Bxf3+ 19. Nxf3 Qf5 20. Rae1 g5 21. Re5 Qd7 22. Rxg5 h4 23. Rh1 hxg3 24. fxg3 Rxh1 25. Kxh1 Qh3+ 26. Nh2 Rh8 27. Qc2 Ng4 28. Rxg4 Qf1# 0-1",
    B: "2127",
  },
  {
    W: "1320",
    T: "180+0",
    P: "1. c4 e5 2. Nc3 Nc6 3. Nf3 Bc5 4. e4 Nf6 5. h3 d6 6. d3 O-O 7. Bg5 h6 8. Bh4 g5 9. Bg3 Nd4 10. Nxd4 exd4 11. Nd5 Nxd5 12. cxd5 b6 13. Be2 Ba6 14. O-O Bb7 15. f4 c6 16. fxg5 hxg5 17. Bh5 cxd5 18. Qg4 f6 19. Qf5 dxe4 20. Qg6+ Kh8 21. Rf5 Rg8 22. Qh6# 1-0",
    B: "1319",
  },
  {
    W: "1695",
    T: "180+0",
    P: "1. e4 Nc6 2. Nf3 e5 3. Bc4 h6 4. c3 Be7 5. O-O Nf6 6. d3 d6 7. h3 Bd7 8. Be3 Na5 9. Bb5 Bxb5 10. b4 Nc6 11. c4 Bxc4 12. dxc4 Nxb4 13. Qa4+ Nc6 14. Nc3 O-O 15. Qb5 Rb8 16. a4 a6 17. Qb3 Nh5 18. Nh2 Nf4 19. Bxf4 exf4 20. Nd5 Nd4 21. Qd3 c5 22. f3 Bg5 23. Rab1 b5 24. axb5 axb5 25. cxb5 f5 26. b6 fxe4 27. Qxe4 Re8 28. Qg6 Ne2+ 29. Kh1 Ng3+ 30. Kg1 Nxf1 31. Rxf1 c4 32. Ng4 c3 33. Nxc3 Qxb6+ 34. Kh1 Qd4 35. Ne4 d5 36. Nxg5 hxg5 37. Qxg5 Re7 38. Qxe7 1-0",
    B: "1486",
  },
  {
    W: "1761",
    T: "180+0",
    P: "1. d4 d5 2. Bf4 Nf6 3. e3 Nc6 4. Nf3 e6 5. Bd3 Bd6 6. Bg3 Bxg3 7. hxg3 Qd6 8. Nbd2 e5 9. dxe5 Nxe5 10. Nxe5 Qxe5 11. Qe2 Bf5 12. Bxf5 Qxf5 13. O-O-O O-O-O 14. Kb1 Ne4 15. Nxe4 Qxe4 16. Rh4 Qxg2 17. e4 dxe4 18. Rxd8+ Rxd8 19. a3 Qg1+ 20. Ka2 Rd1 21. Rxe4 Rd8 22. Re8 Qg2 23. Qg4+ Kb8 24. Rxd8# 1-0",
    B: "1813",
  },
  {
    W: "2292",
    T: "180+0",
    P: "1. d4 Nf6 2. Nf3 c5 3. c4 g6 4. dxc5 Na6 5. Nc3 Nxc5 6. b4 Nce4 7. Nxe4 Nxe4 8. Bb2 Nf6 9. e3 Bg7 10. a3 O-O 11. Be2 d6 12. O-O a5 13. Rc1 axb4 14. axb4 Ra2 15. Qb3 Rxb2 16. Qxb2 Qb6 17. Rfd1 Nd5 18. Qd2 Nf6 19. c5 dxc5 20. bxc5 Qc7 21. Nd4 Bd7 22. Bf3 e5 23. Nb3 Ba4 24. Qb4 Bc6 25. Bxc6 bxc6 26. Qb6 Qc8 27. Na5 Qg4 28. h3 Qh4 29. Qxc6 e4 30. Nc4 g5 31. Nd6 g4 32. Nf5 Qg5 33. hxg4 Nxg4 34. Qxe4 Be5 35. f4 Qh5 36. fxe5 Qh2+ 37. Kf1 Re8 38. Qxg4+ 1-0",
    B: "2133",
  },
  {
    W: "2312",
    T: "180+0",
    P: "1. d4 Nc6 2. c4 e5 3. dxe5 Bb4+ 4. Bd2 Qe7 5. f4 f6 6. exf6 Nxf6 7. a3 Bc5 8. Nc3 Ng4 9. e3 Nxe3 10. Bxe3 Qxe3+ 11. Qe2 O-O 12. Qxe3 Bxe3 13. g3 Bd4 14. Nge2 d6 15. Bg2 Bxc3+ 16. Nxc3 Re8+ 17. Kd2 Nd4 18. Rae1 Bf5 19. Nd5 c6 20. Ne7+ Kf8 21. Nxf5 Nxf5 22. Kd3 Rxe1 23. Rxe1 Re8 24. Rxe8+ Kxe8 25. g4 Nh6 26. h3 Nf7 27. Kd4 Ke7 28. b4 g5 29. Ke4 gxf4 30. Kxf4 Ne5 31. Bf1 Kf6 32. Be2 h6 33. h4 Ng6+ 34. Kg3 Ke5 35. b5 cxb5 36. cxb5 d5 37. a4 d4 38. Bf3 b6 39. Kf2 Kf4 40. Bd1 Ne5 41. g5 hxg5 42. hxg5 Kxg5 43. Kg3 Nc4 44. Kf3 Kf5 45. Bc2+ Ke5 46. Bd3 Nb2 0-1",
    B: "2452",
  },
  {
    W: "1232",
    T: "180+0",
    P: "1. d4 Nf6 2. Bf4 g5 3. Bg3 Bg7 4. e3 d5 5. c3 O-O 6. Nd2 Bf5 7. Ngf3 Nc6 8. Qb3 Ne4 9. Qxb7 Nxg3 10. hxg3 Rb8 11. Qxc6 Rxb2 12. Nxg5 e5 13. Nxh7 Bxh7 14. f4 exd4 15. exd4 Re8+ 16. Be2 Qe7 17. O-O-O Qxe2 18. Kxb2 Rb8+ 19. Kc1 Qd3 20. Qxc7 Qc2# 0-1",
    B: "1091",
  },
  {
    W: "2073",
    T: "180+0",
    P: "1. d4 e5 2. dxe5 Nc6 3. Nf3 Qe7 4. Bf4 Qb4+ 5. Bd2 Qxb2 6. Bc3 Bb4 7. Qd2 Bxc3 8. Nxc3 Qxa1+ 9. Nd1 Nge7 10. e4 Qxa2 11. Qg5 O-O 12. Bd3 Qa5+ 13. Kf1 Ng6 14. h4 Ncxe5 15. Nxe5 Qxe5 16. Qg4 d5 17. h5 Bxg4 0-1",
    B: "2077",
  },
  {
    W: "1741",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 Nc6 4. Be3 cxd4 5. Nxd4 Nf6 6. Bb5 Nxe4 7. Bxc6 bxc6 8. Nxc6 dxc6 9. Qxd8+ Kxd8 10. O-O Be7 11. Nc3 Nxc3 12. bxc3 Bf6 0-1",
    B: "1728",
  },
  {
    W: "1347",
    T: "180+0",
    P: "1. e4 e5 2. d3 c6 3. f4 Qh4+ 4. g3 Qf6 5. f5 d5 6. Bg2 d4 7. h4 h6 8. Nf3 Bb4+ 9. c3 dxc3 10. Nxc3 Ba5 11. Bd2 Bxc3 12. Bxc3 Nd7 13. Qb3 Nc5 14. Qc4 b6 15. b4 Nd7 16. g4 Ne7 17. g5 b5 18. Qb3 Qd6 19. f6 Ng6 20. fxg7 Rg8 21. gxh6 Nf4 22. h7 Nxg2+ 23. Kf2 Rxg7 24. h8=Q+ Qf8 25. Qxf8+ Kxf8 26. Rhg1 Nxh4 27. Rxg7 Kxg7 28. Nxh4 c5 29. bxc5 Nxc5 30. Rg1+ Kh6 31. Nf5+ Kh5 32. Qxf7# 1-0",
    B: "1455",
  },
  {
    W: "1096",
    T: "180+0",
    P: "1. e4 g6 2. d4 d6 3. Nc3 Nf6 4. Nf3 Bg7 5. Bf4 O-O 6. Bc4 Bg4 7. O-O Bxf3 8. Qxf3 d5 9. exd5 Nxd5 10. Nxd5 Bxd4 11. Qe4 Bxb2 12. Rad1 Bg7 13. Be5 e6 14. Bxg7 Kxg7 0-1",
    B: "1164",
  },
  {
    W: "1903",
    T: "180+0",
    P: "1. d4 Nf6 2. Nf3 d5 3. Bf4 e6 4. e3 Bd6 5. Bxd6 Qxd6 6. a3 c5 7. c4 Nc6 8. dxc5 Qxc5 9. b4 Qe7 10. c5 O-O 11. Be2 Qc7 12. Nc3 Ne5 13. Nb5 Qb8 14. Nd6 Nxf3+ 15. Bxf3 Ne8 16. Nxe8 Rxe8 17. O-O Bd7 18. a4 Qe5 19. b5 Rac8 20. Rc1 Qg5 21. e4 Red8 22. exd5 exd5 23. Qd4 Be8 24. Kh1 a6 25. bxa6 bxa6 26. g3 Qf5 27. Kg2 h5 28. Rfd1 Bc6 29. g4 hxg4 30. Bxg4 Qg5 31. Kf1 Bxa4 32. Qxa4 d4 33. Bxc8 d3 34. Bxa6 d2 35. Rc2 Qd5 36. Rcxd2 Qh1+ 37. Ke2 Re8+ 38. Kd3 Qe4+ 39. Kc3 Qxa4 40. Bc4 Qa3+ 41. Kd4 Rd8+ 42. Ke4 Rxd2 43. Rxd2 Qxc5 44. Rd8+ Kh7 45. Bd3 Qc6+ 46. Ke3+ Kh6 47. Rh8+ Kg5 48. h4+ Kf6 49. f4 Qc5+ 50. Kf3 Qd5+ 51. Be4 Qd1+ 52. Kg3 Qe1+ 53. Kf3 Qh1+ 54. Ke3 Qg1+ 55. Kd3 Qf1+ 56. Ke3 Qe1+ 57. Kf3 Qd1+ 58. Ke3 Qe1+ 59. Kf3 1/2-1/2",
    B: "1904",
  },
  {
    W: "2097",
    T: "180+0",
    P: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6 6. Nf3 Bf5 7. Bf4 e6 8. Qb3 Qb6 9. Qd1 Rc8 10. b3 Bb4 11. Bd2 O-O 12. c5 Qa5 13. Rc1 Bxc3 14. Rxc3 Qd8 15. a3 a6 16. Be2 Re8 17. O-O h6 18. h3 Ne4 19. Re3 Bg6 20. b4 Qd7 21. Nh2 Nxd2 22. Qxd2 e5 23. Rg3 Nxd4 24. Bg4 f5 25. Bd1 Qf7 26. f4 Nc6 27. fxe5 Rxe5 28. Bb3 Rce8 29. Nf3 Re2 30. Qxd5 Rd8 31. Qxf7+ Bxf7 32. Bxf7+ Kxf7 33. Nh4 g6 34. Nxg6 Rd5 35. Nf4 Rde5 36. Nxe2 Rxe2 37. Rxf5+ Ke6 38. Rgf3 Re1+ 39. Kh2 Nd4 40. Rf6+ Ke5 41. R3f4 Re2 42. Rf8 Ne6 43. R4f5+ Ke4 44. R8f6 Ra2 45. Rh5 Ng5 46. Rxg5 hxg5 47. Rb6 Rxa3 48. Rxb7 Rb3 49. c6 Rc3 50. Rb6 Rc4 51. Rxa6 Rxb4 52. c7 Rc4 0-1",
    B: "2093",
  },
  {
    W: "2102",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nc3 Nc6 4. Bb5 Nd4 5. Nxd4 exd4 6. Ne2 c6 7. Bd3 Bc5 8. O-O O-O 9. a3 Bb6 10. b4 d6 11. Bb2 c5 12. c3 dxc3 13. Bxc3 Re8 14. Ng3 Bg4 15. Qc2 h6 16. Rfe1 Qc7 17. Bb5 Bd7 18. Bc4 cxb4 19. Bxb4 Rac8 20. Qb3 Qxc4 21. Qxc4 Rxc4 22. Bxd6 Nxe4 23. d3 Rd4 24. Nxe4 Rexe4 25. dxe4 Rxd6 26. Rad1 Rxd1 27. Rxd1 Be6 28. g3 Kf8 29. Kg2 Ke7 30. f4 g6 31. Kf3 Bc5 32. Rb1 b6 33. a4 h5 34. Rd1 Bg4+ 0-1",
    B: "2231",
  },
  {
    W: "1854",
    T: "180+0",
    P: "1. d4 Nf6 2. Bf4 d5 3. e3 Nc6 4. c3 Bf5 5. Nd2 e6 6. Ndf3 h6 7. Bd3 Bxd3 8. Qxd3 Be7 9. Ne5 O-O 10. Ngf3 Nxe5 11. Nxe5 c5 12. b3 Rc8 13. h3 Qb6 14. dxc5 Qxc5 15. Rc1 Rfd8 16. O-O a5 17. e4 Nxe4 18. Be3 Qc7 19. c4 Qxe5 20. cxd5 Rxc1 21. Rxc1 Qxd5 22. Qe2 Bc5 23. Bxc5 Nxc5 24. Qe3 Nd3 25. Rd1 b5 26. Kh2 Qd6+ 27. g3 a4 28. bxa4 bxa4 29. Qa7 a3 30. Qxa3 Qxa3 0-1",
    B: "1823",
  },
  {
    W: "1910",
    T: "180+0",
    P: "1. b3 Nf6 2. Bb2 d6 3. Bxf6 gxf6 4. Nc3 Bh6 5. h4 e5 6. e3 f5 7. Qh5 Qf6 8. Bb5+ c6 9. Nd5 Qg6 10. Qxg6 fxg6 11. Nc7+ Kd8 12. Nxa8 cxb5 13. Ne2 Nd7 14. Nc3 a6 15. Nd5 Re8 16. Nab6 Nxb6 17. Nxb6 Kc7 18. Nxc8 Kc6 19. Nxd6 Kxd6 20. O-O-O Rc8 21. Kb1 Bf8 22. d4 Ke6 23. dxe5 Kxe5 24. Rd3 Ke4 25. h5 Ba3 26. hxg6 hxg6 27. Rh6 g5 28. Rg6 f4 29. Rxg5 fxe3 30. Rxe3+ Kf4 0-1",
    B: "1939",
  },
  {
    W: "2068",
    T: "180+0",
    P: "1. g3 d5 2. Bg2 e5 3. b3 Nf6 4. Bb2 Nbd7 5. d3 c6 6. e3 Bd6 7. Nf3 O-O 8. Qe2 Re8 9. Nbd2 a5 10. a4 e4 11. dxe4 dxe4 12. Ng5 Nc5 13. O-O h6 14. Ngxe4 Bg4 15. Nxf6+ Qxf6 16. Qxg4 Qxb2 17. Nc4 Qf6 18. Nxd6 Qxd6 19. Rad1 Qe6 20. Qe2 Rad8 21. Rxd8 Rxd8 22. Rd1 Rxd1+ 23. Qxd1 Ne4 24. Qd8+ Kh7 25. Qxa5 Ng5 26. Qb4 Qe5 27. Qxb7 Qa1+ 28. Bf1 Nf3+ 29. Kg2 Ne1+ 30. Kh3 Nxc2 31. Bd3+ 1-0",
    B: "1951",
  },
  {
    W: "1940",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. e5 d5 7. Bb5 Ne4 8. cxd4 Bb4+ 9. Nbd2 Bxd2+ 10. Nxd2 O-O 11. Nxe4 dxe4 12. Bxc6 bxc6 13. O-O Qe7 14. Qc2 Ba6 15. Re1 Bd3 16. Qxc6 Rab8 17. b3 Rb6 18. Qa4 Ra6 19. Ba3 Qxa3 20. Qd7 Qb2 21. Reb1 Bxb1 0-1",
    B: "1966",
  },
  {
    W: "1142",
    T: "60+0",
    P: "1. e4 g6 2. f4 Nf6 3. e5 b6 4. exf6 exf6 5. Qe2+ Qe7 6. Qxe7+ Kxe7 7. Nf3 Kd8 8. d3 Bb4+ 9. Bd2 Bxd2+ 10. Nbxd2 Re8+ 11. Be2 Rxe2+ 12. Kxe2 Bb7 13. Rhe1 Nc6 14. Kf2 Kc8 15. Re8+ Nd8 16. Nc4 Ba6 17. Nd4 Kb7 18. Rh8 Nc6 19. Nxc6 dxc6 20. Rxa8 Kxa8 21. Re1 Kb7 22. Re7 Ka8 23. Rxf7 Bc8 24. Rxh7 Kb7 25. Rh8 g5 26. Rf8 gxf4 27. Rxf6 f3 28. Rxf3 b5 29. Na5+ Ka6 30. b4 c5 31. a3 cxb4 32. axb4 c5 33. c3 cxb4 34. cxb4 Be6 35. Rf6 Kb6 36. Rxe6+ Kc7 37. Rc6+ Kd7 38. h4 Ke7 39. h5 Kf7 40. h6 Kg8 41. g4 Kh7 42. g5 Kg8 43. g6 Kh8 44. g7+ Kg8 45. Rc7 Kh7 46. Rxa7 Kg6 47. g8=Q+ Kxh6 48. Rh7# 1-0",
    B: "1143",
  },
  {
    W: "1080",
    T: "60+0",
    P: "1. e4 e5 2. Bb5 c6 3. Bd3 Bc5 4. Nf3 d6 5. O-O Bg4 6. Kh1 Nf6 7. h3 Bxf3 8. gxf3 O-O 9. Rg1 a6 10. Qf1 b5 11. Qg2 g6 12. h4 Nh5 13. Be2 Qf6 14. f4 Nxf4 15. Bh5 Nxg2 16. Rxg2 d5 17. d3 dxe4 18. Bh6 exd3 19. Nd2 dxc2 20. Rc1 Nd7 21. Bxf8 Rxf8 22. Ne4 Qxh4+ 23. Rh2 Qxe4+ 24. Kg1 Bxf2+ 25. Kxf2 Qd4+ 26. Kg3 Qxb2 27. Rcxc2 1-0",
    B: "1043",
  },
  {
    W: "1383",
    T: "60+0",
    P: "1. b3 d5 2. Bb2 Nf6 3. d3 Nc6 4. Nd2 Bf5 5. g3 e6 6. Bg2 Bb4 7. e4 dxe4 8. dxe4 Bg4 9. f3 Bh5 10. c3 Bc5 11. Ne2 Bg6 12. Qc2 h6 13. O-O-O O-O 14. Nc4 Ne7 15. Rxd8 Raxd8 16. e5 Nfd5 17. Nf4 Nxf4 18. gxf4 Nf5 19. Rd1 Ng3 20. Rxd8 Rxd8 21. Nd6 Bxc2 22. Kxc2 Bxd6 23. exd6 Ne2 24. h4 Rxd6 25. f5 Nf4 26. Bf1 exf5 27. Bd3 Nxd3 28. c4 Nxb2 29. Kxb2 Rd2+ 0-1",
    B: "1417",
  },
  {
    W: "1333",
    T: "60+0",
    P: "1. c4 d5 2. e3 dxc4 3. Bxc4 b5 4. Bxb5+ c6 5. Bxc6+ Nxc6 6. Qc2 Bd7 7. b4 Nf6 8. Bb2 e6 9. Nc3 Bxb4 10. a3 Bxc3 11. Bxc3 O-O 12. Nf3 Rc8 13. g4 Nd5 14. Ng5 Qxg5 15. h4 Qe7 16. g5 Nxc3 17. Qxc3 Ne5 18. Qxe5 Bc6 19. Rh2 a6 20. h5 Bb5 21. h6 g6 22. Qg7# 1-0",
    B: "1264",
  },
  {
    W: "2088",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Nf6 3. d4 Nxd5 4. c4 Nb6 5. Be3 g6 6. Nf3 Bg7 7. Bd3 O-O 8. Qd2 Nc6 9. Bh6 Nxd4 10. Nxd4 Qxd4 11. Bxg7 Kxg7 12. O-O Be6 13. Nc3 Rad8 14. Rfd1 Nxc4 15. Qc2 Qh4 16. Bxc4 Bxc4 17. Ne4 Bd5 18. Qc3+ Kg8 19. Re1 c6 20. Ng3 Rfe8 21. Qe3 Qc4 22. Qg5 Qd4 23. Nf5 Qf6 24. Nxe7+ Kg7 25. Qxf6+ Kxf6 26. Nxd5+ cxd5 27. Rxe8 Rxe8 28. Rd1 Rd8 29. Kf1 Ke5 30. Ke2 d4 31. Kd3 Kd5 32. Re1 1-0",
    B: "2104",
  },
  {
    W: "1950",
    T: "60+0",
    P: "1. d4 e5 2. c4 exd4 3. Qxd4 Bb4+ 4. Bd2 Qe7 5. a3 Bxd2+ 6. Nxd2 Qf6 7. Ngf3 Qxd4 8. Nxd4 c6 9. e3 d6 10. Ne4 Nf6 11. Nxd6+ Kd7 12. Nxf7 Rf8 13. Ne5+ Kd6 14. Nd3 c5 15. Nb5+ Kc6 16. a4 a6 17. Nc3 b6 18. g3 Be6 19. Bg2+ Kc7 20. b3 Bxc4 21. bxc4 Ra7 22. Nd5+ Kd6 23. Nxf6 Rxf6 24. O-O Nd7 25. Rad1 Ne5 26. Nf4+ Ke7 27. Nd5+ Kf7 28. Nxf6 gxf6 29. Bd5+ Kg7 30. f4 Ng4 31. Rde1 h5 32. Kg2 Rd7 33. h3 Nh6 34. e4 b5 35. axb5 axb5 36. cxb5 Ra7 37. e5 fxe5 38. fxe5 Kg6 39. e6 Nf5 40. e7 Nxe7 41. Bc6 Ra5 42. Rxe7 Kg5 43. Re5+ Kg6 44. Rxc5 Kh7 45. Rxh5+ Kg6 46. Rhf5 1-0",
    B: "1998",
  },
  {
    W: "1888",
    T: "60+0",
    P: "1. d4 e6 2. c4 d6 3. Nf3 f6 4. Nc3 Qe7 5. e4 Nh6 6. Bd3 Nf7 7. O-O c5 8. d5 e5 9. Re1 Bd7 10. a3 Nd8 11. b4 cxb4 12. axb4 Na6 13. Nb5 Bxb5 14. cxb5 Nc7 15. b6 Kf7 16. bxc7 Qxc7 17. Bb2 Be7 18. Rc1 Qd7 19. b5 b6 20. Nd2 Nb7 21. Qh5+ g6 22. Qh4 Nc5 23. Be2 f5 24. exf5 Bxh4 25. fxg6+ hxg6 26. Nf3 Bf6 27. Bd1 Rh6 28. Bc3 Rah8 29. Bd2 R6h7 30. Bc2 e4 31. Bxe4 Nxe4 32. Rxe4 Be5 33. g3 Qxb5 34. Ng5+ Kf6 35. Nxh7+ Rxh7 36. Bc3 Bxc3 37. Rxc3 Qb1+ 38. Kg2 Qxe4+ 39. Rf3+ Qxf3+ 40. Kxf3 a5 41. h4 a4 42. g4 a3 43. Kf4 a2 0-1",
    B: "1804",
  },
  {
    W: "1737",
    T: "60+0",
    P: "1. d4 d6 2. Bg5 c6 3. e4 h6 4. Bf4 g6 5. Bg3 Bg7 6. Nc3 Qb6 7. Qb1 Qxd4 8. Nf3 Qb6 9. Bc4 Bxc3+ 10. bxc3 Qxb1+ 11. Rxb1 Nf6 12. e5 dxe5 13. Bxe5 O-O 14. O-O Nd5 15. Bxd5 cxd5 16. Nd4 f6 17. Bg3 h5 18. Nb5 a6 19. Nc7 Ra7 20. Nxd5 Be6 21. Nxe7+ Kf7 22. Bd6 Re8 23. Ba3 Rxe7 24. Bxe7 Kxe7 25. Rfe1 Kf7 26. f4 f5 27. Rb4 Nc6 28. Rb3 b5 29. Rb2 Na5 30. Rbb1 Nc4 31. Re2 Nd6 32. Rbe1 Ne4 33. c4 bxc4 34. c3 Re7 35. Rb1 Nxc3 0-1",
    B: "1737",
  },
  {
    W: "1786",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nf3 Qd8 4. Be2 Nf6 5. O-O Nc6 6. d4 e5 7. c3 e4 8. Nfd2 Bf5 9. Nc4 Be7 10. Ne3 Bg6 11. Nd2 O-O 12. Ndc4 a6 13. a4 b6 14. f3 exf3 15. Bxf3 Qd7 16. Ne5 Nxe5 17. Bxa8 Nd3 18. Bf3 Nf4 19. Nc4 Ne6 20. Ne5 Qe8 21. Bc6 Qd8 22. Nxg6 fxg6 23. d5 Nc5 24. b4 Qd6 25. bxc5 bxc5 26. Ba3 Qe5 27. Re1 Qxc3 28. Rxe7 Qc4 0-1",
    B: "1798",
  },
  {
    W: "1243",
    T: "60+0",
    P: "1. c4 e5 2. Nc3 d6 3. e3 Be6 4. d4 exd4 5. exd4 Qh4 6. d5 Bg4 7. Be2 Bxe2 8. Qxe2+ Be7 9. Nf3 Qf6 10. Bg5 Qg6 11. O-O c6 12. Rfe1 cxd5 13. Bxe7 dxc4 14. Bxd6+ Kd8 15. Bxb8 f6 16. Rad1+ 1-0",
    B: "1194",
  },
  {
    W: "1600",
    T: "60+0",
    P: "1. c4 c6 2. Nc3 d5 3. cxd5 cxd5 4. d4 g6 5. Nf3 Nf6 6. g3 Bg7 7. Bg2 O-O 8. O-O Re8 9. Bg5 Qb6 10. Qd2 e6 11. Na4 Qa6 12. Nc5 Qb6 13. b3 Nfd7 14. Rac1 Nxc5 15. Rxc5 Nc6 16. Rfc1 h6 17. Bxh6 Bxh6 18. Qxh6 Ne7 19. Ng5 Nf5 20. Qh7+ Kf8 21. Qxf7# 1-0",
    B: "1626",
  },
  {
    W: "1690",
    T: "15+0",
    P: "1. f4 e6 2. Nf3 c5 3. e4 Nf6 4. e5 Nd5 5. d4 Nb6 6. c3 cxd4 7. cxd4 d6 8. Bd3 dxe5 9. fxe5 Be7 10. O-O Bd7 11. Qc2 O-O 12. Bxh7+ Kh8 13. Ng5 g6 14. Bxg6 Kg7 15. Bxf7 Rxf7 16. Nxf7 Qf8 17. Qe4 Qxf7 18. Rxf7+ Kxf7 19. Qxb7 Ke8 20. Qxa8 Nc8 21. Qxb8 Kd8 22. Qxa7 1-0",
    B: "1616",
  },
  {
    W: "1717",
    T: "0+5",
    P: "1. Nf3 d6 2. g3 e5 3. d3 Be7 4. Bg2 Nf6 5. O-O O-O 6. e4 h6 7. Nc3 Be6 8. h3 c6 9. Ne1 Qd7 10. Kh2 d5 11. f4 exf4 12. gxf4 dxe4 13. Nxe4 Nxe4 14. Bxe4 Bxh3 15. Qh5 Bxf1 16. Bf5 Qd5 17. c4 Qd4 0-1",
    B: "1674",
  },
  {
    W: "1365",
    T: "600+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O Nf6 5. d3 d5 6. Bb5 O-O 7. Bxc6 bxc6 8. exd5 Bg4 9. h3 Bh5 10. g4 Nxg4 11. hxg4 Bxg4 12. Bg5 Qxd5 13. c4 Qxf3 14. Qxf3 Bxf3 15. Nd2 Be2 16. Rfe1 Bxd3 17. Rxe5 Bd4 18. Re7 Bxb2 19. Rd1 Bc3 20. Rxc7 Bxd2 21. Rxd2 Bxc4 22. Rxc6 Be6 23. Be7 Rfc8 24. Rcd6 Re8 25. Bg5 h6 26. Be3 Rac8 27. f4 Rc1+ 28. Kh2 a5 29. Rg2 Ra1 30. f5 Bxa2 31. Bxh6 Kf8 32. Bxg7+ Ke7 33. Ra6 Bd5 34. Bxa1 Bxg2 35. Kxg2 Rg8+ 36. Kf3 Rg1 37. Bf6+ Kf8 38. Ra8# 1-0",
    B: "1683",
  },
  {
    W: "1841",
    T: "300+0",
    P: "1. d4 Nf6 2. c4 e5 3. dxe5 Ne4 4. Nf3 Bc5 5. e3 g5 6. Bd3 Nxf2 7. Kxf2 g4 8. Nd4 f6 9. Qxg4 Bxd4 10. Qh5+ Ke7 11. exd4 Rf8 12. exf6+ Rxf6+ 13. Kg1 c5 14. Bg5 Qb6 15. Qxh7+ Ke6 16. Bxf6 Qxb2 17. Qe7# 1-0",
    B: "1798",
  },
  {
    W: "1093",
    T: "180+2",
    P: "1. d4 Nf6 2. Nf3 g6 3. Bf4 d6 4. e3 Bg7 5. Bb5+ c6 6. Ba4 Qc7 7. Nc3 Nbd7 8. Qe2 O-O 9. O-O-O e5 10. dxe5 dxe5 11. Bxe5 Nxe5 12. Rhe1 Nxf3 13. gxf3 Be6 14. Ne4 Nxe4 15. fxe4 Rfd8 16. e5 Qxe5 17. Rxd8+ Rxd8 18. Qd3 Qxb2+ 19. Kd2 Rxd3+ 20. Kxd3 Qc3+ 21. Ke2 Bc4+ 22. Kd1 Qa1+ 23. Kd2 Bc3# 0-1",
    B: "1566",
  },
  {
    W: "1178",
    T: "15+0",
    P: "1. e4 d5 2. Ne2 dxe4 3. Ng3 Nc6 4. Nxe4 Bf5 5. d3 Bxe4 6. dxe4 Qxd1+ 7. Kxd1 Nf6 8. Bf4 Nxe4 9. Bxc7 Nxf2+ 10. Ke2 Nxh1 11. g3 Rc8 12. Bg2 Rxc7 13. Kf1 e6 14. Kg1 Bc5+ 15. Kxh1 Ne5 16. Nc3 O-O 17. Ne4 Rd8 18. Nxc5 b6 19. c4 bxc5 20. b4 Rd4 21. bxc5 Rxc4 22. Bd5 R4xc5 23. Bc4 Rxc4 24. Rc1 Rxc1+ 25. Kg2 R7c2+ 26. Kh3 Rc3 0-1",
    B: "1448",
  },
  {
    W: "1454",
    T: "15+0",
    P: "1. d4 c6 2. Bf4 d5 3. e3 Bf5 4. Bd3 e6 5. Bxf5 exf5 6. Be5 Nf6 7. Bxf6 Qxf6 8. Nf3 Bb4+ 9. c3 Bd6 10. Ne5 Qh4 11. g3 Bxe5 12. dxe5 Qe7 13. e6 Qxe6 14. Qh5 Qe5 15. Qxf7+ Kxf7 16. O-O Kg6 17. f4 Qe4 18. Rf3 Qxe3+ 19. Rxe3 h5 20. Re6+ Kh7 21. Re5 h4 22. Nd2 hxg3 0-1",
    B: "1569",
  },
  {
    W: "2164",
    T: "15+0",
    P: "1. Nf3 e5 2. g3 e4 3. Bg2 exf3 4. exf3 Nf6 5. O-O d5 6. d4 Be7 7. c3 O-O 8. Re1 Bd6 9. Bf4 c5 10. Be5 Bxe5 11. dxe5 Nfd7 12. f4 Nb6 13. Bxd5 Bf5 14. Bf3 Be4 15. Bxe4 Nc6 16. Bxh7+ Kxh7 17. Qh5+ Kg8 18. Nd2 f6 19. Qf3 fxe5 20. Qg2 exf4 21. Ne4 f3 22. Qf1 Qe7 23. Nd6 Qxd6 24. h4 Qh6 25. Re7 Nd5 26. Rxg7+ Kxg7 27. Re1 Qg6 28. Re7+ Rf7 29. h5 Ndxe7 30. hxg6 Rf6 31. Qh3 Rxg6 32. Qh7+ 1-0",
    B: "1844",
  },
  {
    W: "2065",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 g6 3. h4 h5 4. c3 Bg7 5. d4 cxd4 6. cxd4 d6 7. Nc3 Nf6 8. Bc4 O-O 9. Ng5 Qa5 10. O-O Bg4 11. Qb3 d5 12. exd5 Qb6 13. Qxb6 axb6 14. Re1 Nh7 15. Rxe7 Bxd4 16. Nxf7 Bxf2+ 17. Kxf2 Rxf7+ 18. Rxf7 Kxf7 19. d6+ Ke8 20. Bh6 Nf6 21. Bb5+ Nc6 22. Bg5 Nh7 23. Nd5 Nxg5 24. hxg5 Rd8 25. Nf6+ Kf8 26. Re1 Rxd6 27. Re8+ Kf7 28. Bc4+ Be6 29. Bxe6+ Rxe6 30. Rxe6 Kxe6 31. Ne4 b5 0-1",
    B: "2069",
  },
  {
    W: "2652",
    T: "60+0",
    P: "1. e4 e6 2. d4 b6 3. Bd2 Bb7 4. Bd3 d5 5. c3 dxe4 6. Bc2 Nf6 7. Ne2 c5 8. O-O Nc6 9. Be3 cxd4 10. Nxd4 Be7 11. Nxc6 Bxc6 12. Ba4 Bxa4 13. Qxa4+ Qd7 14. Qxd7+ Nxd7 15. Nd2 Nc5 16. Bxc5 Bxc5 17. Nxe4 O-O 18. Nxc5 bxc5 19. b3 Rfd8 20. Rfd1 Kf8 21. c4 Ke7 22. Kf1 a5 23. Ke2 Rxd1 24. Rxd1 Rd8 25. Rxd8 Kxd8 26. Kd3 Kd7 27. a3 Kd6 28. Kc3 Kc6 29. b4 axb4+ 30. axb4 cxb4+ 31. Kxb4 e5 32. c5 f5 33. Kc4 g6 34. h4 h6 35. f3 g5 36. h5 e4 37. fxe4 fxe4 38. Kd4 e3 39. Kxe3 Kxc5 40. Ke4 Kd6 41. Kf5 g4 42. Kg6 g3 43. Kxh6 Ke5 44. Kg5 Ke4 45. Kg4 Ke3 46. Kxg3 Ke2 47. Kh4 1-0",
    B: "2573",
  },
  {
    W: "2203",
    T: "60+0",
    P: "1. b3 e6 2. Nf3 f5 3. Bb2 Nf6 4. c4 b6 5. Nc3 Bb7 6. g3 Be7 7. Bg2 O-O 8. O-O Na6 9. d4 Qe8 10. Qd2 Qh5 11. Rad1 Bb4 12. a3 Ne4 13. Qc2 Nxc3 14. Bxc3 Bxa3 15. Nh4 Bxg2 16. Nxg2 Bb4 17. Nf4 Qh6 18. d5 g5 19. Ng2 f4 20. gxf4 gxf4 21. f3 Bc5+ 22. Bd4 Rf6 23. e3 fxe3 24. Bxc5 Nxc5 25. Qe2 Rg6 26. Rd4 e5 27. Rg4 Rf8 28. Rxg6+ Qxg6 29. Qxe3 d6 30. Kh1 Rf5 31. Rg1 Rg5 32. Ne1 Rxg1+ 33. Qxg1 Qxg1+ 34. Kxg1 Nxb3 35. Nd3 Nd2 36. Nb2 Nxf3+ 37. Kf2 Nd4 38. Kg3 Kf7 39. Kg4 Kf6 40. Nd3 a5 41. Nb4 axb4 0-1",
    B: "2225",
  },
  {
    W: "1863",
    T: "60+0",
    P: "1. g3 e5 2. Bg2 d5 3. e4 d4 4. Nf3 Nc6 5. d3 Bd6 6. c3 Nge7 7. cxd4 exd4 8. Nbd2 Ng6 9. Nb3 Be5 10. Bg5 f6 11. Bd2 O-O 12. O-O Bg4 13. h3 Bxf3 14. Bxf3 Qd7 15. Bg2 f5 16. f4 Bf6 17. e5 Be7 18. a3 b6 19. Qf3 Na5 20. Qd5+ Qxd5 21. Bxd5+ Kh8 22. Be1 Rad8 23. Nxa5 bxa5 24. Bc4 Bc5 25. Bf2 Ne7 26. Rab1 Nc6 27. Kh2 a4 28. Rfe1 Rb8 29. e6 Rfe8 30. Re2 Ne7 31. Re5 Bb6 32. b4 axb3 33. Rxb3 c5 34. a4 a5 35. Bb5 Red8 36. Rb2 1-0",
    B: "1863",
  },
  {
    W: "1945",
    T: "60+0",
    P: "1. d4 d6 2. g3 c6 3. Bg2 Qc7 4. c4 f6 5. Nc3 e5 6. d5 Bg4 7. dxc6 Nxc6 8. Nd5 Qd7 9. h3 Bh5 10. g4 Bf7 11. f4 Nge7 12. fxe5 fxe5 13. e4 Nxd5 14. cxd5 Nd4 15. Be3 Nb5 16. Ne2 Be7 17. O-O O-O 18. Ng3 Bg6 19. h4 Rxf1+ 20. Qxf1 Bxh4 21. Qe1 Rf8 22. Nf5 Bxe1 23. Rxe1 Bxf5 24. gxf5 Nd4 25. Rc1 Rc8 26. Rf1 Rc2 27. f6 gxf6 28. Rxf6 Qg7 29. Rf2 Nf3+ 30. Kf1 Nh2+ 31. Ke1 Rxf2 32. Kxf2 Ng4+ 33. Ke2 Nxe3 34. Kxe3 Qxg2 0-1",
    B: "2101",
  },
  {
    W: "1871",
    T: "60+0",
    P: "1. d4 Nf6 2. Nf3 g6 3. Bf4 Bg7 4. e3 O-O 5. c3 d6 6. Bd3 Nbd7 7. Nbd2 b6 8. Qc2 Bb7 9. h4 c5 10. O-O-O cxd4 11. exd4 Rc8 12. Rdg1 e5 13. dxe5 dxe5 14. Nxe5 Nxe5 15. Bxe5 Re8 16. Bxf6 Qxf6 17. h5 Qxf2 18. hxg6 fxg6 19. Bxg6 hxg6 20. Qxg6 Qf7 21. Qh7+ Kf8 22. Rf1 Rcd8 23. Rxf7+ Kxf7 24. Qf5+ Kg8 25. Qh7+ Kf7 26. Rf1+ Ke7 27. Qxg7+ Kd6 28. Qxb7 Re7 29. Qxe7+ Kxe7 30. Rg1 Rg8 31. g4 b5 32. Nf3 a5 33. Kd2 b4 34. cxb4 axb4 35. Kd3 Ra8 36. Kc4 Rxa2 37. Kxb4 Rxb2+ 38. Kc3 Rb6 39. g5 Kf7 40. Kd4 Kg7 41. Ke4 Rb4+ 42. Kf5 Rb5+ 43. Ne5 Ra5 44. Kf4 Ra4+ 45. Kf5 Ra5 46. Ke6 Ra6+ 47. Ke7 Ra7+ 48. Kd6 Ra6+ 49. Nc6 Kg6 50. Ke5 Rxc6 51. Kf4 Rc5 52. Kg4 1-0",
    B: "1993",
  },
  {
    W: "1030",
    T: "60+0",
    P: "1. e4 c5 2. d4 cxd4 3. c3 Nc6 4. cxd4 Nf6 5. d5 Nxe4 6. dxc6 dxc6 7. Qxd8+ Kxd8 8. Bd3 Bg4 9. Bxe4 1-0",
    B: "964",
  },
  {
    W: "1255",
    T: "60+0",
    P: "1. e4 e5 2. f3 d6 3. c3 Nc6 4. d4 exd4 5. cxd4 Be6 6. Nc3 Bd5 7. exd5 Nb4 8. Qb3 a5 9. Qa4+ c6 10. a3 Nxd5 11. Nxd5 Qg5 12. Qxc6+ Kd8 13. Qxb7 1-0",
    B: "1285",
  },
  {
    W: "1547",
    T: "60+0",
    P: "1. d4 c5 2. Bf4 e6 3. Nc3 cxd4 4. Nb5 Nc6 5. Nc7+ Ke7 6. Nxa8 e5 7. Bg5+ f6 8. Bh4 d6 9. e3 dxe3 10. fxe3 Be6 11. Bd3 Qxa8 12. Nf3 Ke8 13. c4 Nge7 14. Qe2 b6 15. Qf2 Na5 16. Qg3 Nf5 17. Qh3 e4 18. Bxe4 Qxe4 19. Nd2 Qxe3+ 20. Qxe3 Nxe3 21. Bf2 Nc2+ 22. Ke2 Nxa1 23. Rxa1 Nxc4 24. Re1 Nxd2 25. Kxd2 Kf7 26. Be3 g6 27. Bf4 h5 28. Bg3 Bh6+ 29. Kc2 Bf5+ 30. Kd1 Rc8 31. Ke2 Rc2+ 32. Kf3 Bg4+ 33. Ke4 Rxb2 34. Kd5 Rxa2 35. Kc6 Ra5 36. Kb7 b5 37. Rb1 Ra4 38. Rxb5 Rf4 39. Kxa7 1-0",
    B: "1515",
  },
  {
    W: "1811",
    T: "60+0",
    P: "1. e4 e5 2. d3 Nc6 3. Be2 Bc5 4. Nf3 Nf6 5. O-O Ng4 6. h3 Nf6 7. Nc3 d6 8. Nd5 O-O 9. Bg5 Be6 10. Nxe5 Nxe5 11. c3 Ned7 12. Nxf6+ Nxf6 13. d4 h6 14. Bxf6 Qxf6 15. dxc5 dxc5 16. Bd3 Rad8 17. Qc2 c4 18. Be2 Qg5 19. f4 Qg6 20. Kh1 Bd5 21. f5 Qd6 22. exd5 Qxd5 23. Rad1 Qe5 24. Rxd8 Rxd8 25. Bg4 Qc5 26. Qf2 f6 27. Qxc5 b6 28. Qxc7 Rd2 29. Qc8+ Kh7 30. Bh5 Rd8 31. Bg6+ Kg8 32. Qxd8# 1-0",
    B: "1706",
  },
  {
    W: "1662",
    T: "60+0",
    P: "1. c4 e5 2. Nc3 d6 3. g3 Bf5 4. Bg2 Nd7 5. Bxb7 Rb8 6. Bg2 h5 7. e3 h4 8. Nge2 h3 9. Bf3 Ngf6 10. d3 Be7 11. b3 O-O 12. Bb2 c5 13. Ne4 a5 14. Nxf6+ Nxf6 15. O-O Bg4 16. Bxg4 Nxg4 17. e4 Qc7 18. f4 exf4 19. Nxf4 Qb7 20. Qxg4 Bf6 21. Bxf6 g6 22. Qxh3 a4 23. Qh8# 1-0",
    B: "1596",
  },
  {
    W: "1423",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 Nf6 5. Bd3 Nc6 6. c3 Bd6 7. Bg5 O-O 8. O-O Qe7 9. Nbd2 Qd8 10. Bxf6 Qxf6 11. h3 a6 12. a3 Be6 13. b4 Na7 14. Qb3 b5 15. Rae1 Qe7 16. Ne5 c6 17. Re3 f5 18. Rg3 f4 19. Rf3 Bxe5 20. Qc2 Bd6 21. Bxh7+ Kh8 22. Qg6 Qf7 23. Qxf7 Rxf7 24. Bg6 Rf6 25. Bh5 Raf8 26. Bg4 Bxg4 27. hxg4 Rh6 28. Rh3 Rxh3 29. gxh3 f3 30. Nxf3 Rxf3 31. Kg2 Rxc3 32. f4 Rxa3 33. f5 Rg3+ 34. Kf2 Bc5 35. Kxg3 Bxd4 36. Kh4 Bf6+ 0-1",
    B: "1406",
  },
  {
    W: "2002",
    T: "60+0",
    P: "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. dxc5 Qa5 5. Nf3 Qxc5 6. Be3 Qa5 7. Bd2 Nf6 8. Nd5 Qd8 9. Nxf6+ Bxf6 10. Bc3 Nc6 11. Bxf6 exf6 12. Qc2 O-O 13. Rd1 Qc7 14. Nd4 a6 15. e3 Ne5 16. Be2 d6 17. O-O b6 18. f4 Ng4 19. Bxg4 Bxg4 20. Rd2 Rad8 21. h3 Bc8 22. Nb3 Bb7 23. Nd4 d5 24. Nf3 dxc4 0-1",
    B: "2046",
  },
  {
    W: "1453",
    T: "600+0",
    P: "1. e3 e5 2. d3 d5 3. h3 d4 4. exd4 exd4 5. Ne2 Qe7 6. g4 g5 7. Bg2 c6 8. O-O Nf6 9. Re1 Qd6 10. Nxd4+ Be6 11. Nf5 Qc5 12. Be3 Qb5 13. b3 Nbd7 14. Nc3 Qa6 15. a4 O-O-O 16. Nd4 Bd5 17. Bxd5 cxd5 18. Nf5 Bc5 19. Bxg5 Qb6 20. Bxf6 Nxf6 21. Qf3 d4 22. Nb5 a6 23. Nbxd4 Bxd4 24. Nxd4 Qxd4 25. Re3 Qxa1+ 26. Kg2 Rhe8 27. Rxe8 Rxe8 28. Kg3 Qe5+ 29. Kh4 Rg8 30. c4 Qg5+ 31. Kg3 h5 32. Qf5+ Qxf5 0-1",
    B: "1349",
  },
  {
    W: "1611",
    T: "180+0",
    P: "1. d4 d5 2. Bf4 Nc6 3. Nf3 Bg4 4. Nbd2 Bxf3 5. Nxf3 Nf6 6. e3 e6 7. Bb5 Bb4+ 8. c3 Bd6 9. Bg3 Bxg3 10. hxg3 O-O 11. Bd3 Qe7 12. Qc2 g6 13. Nh4 e5 14. Bb5 exd4 15. cxd4 a6 16. Bxc6 bxc6 17. Qxc6 Qd6 18. Rc1 Rfc8 19. Nf3 Qxc6 20. Rxc6 Kg7 21. Ne5 Re8 22. Rxc7 Rac8 23. Rxc8 Rxc8 24. O-O Ne4 25. Nd3 Nd2 26. Rc1 Rxc1+ 27. Nxc1 f5 28. b4 g5 29. a4 Nc4 30. b5 axb5 31. axb5 Nd6 32. Nb3 Nxb5 33. Nc5 Kg6 34. Ne6 h5 35. f4 gxf4 36. Nxf4+ Kg5 37. Nxd5 Kg4 38. Kf2 h4 39. gxh4 Kxh4 40. g3+ Kg4 41. Ne7 Nd6 42. d5 Ne4+ 43. Ke2 Kxg3 44. Kd3 Nc5+ 45. Kd4 Nb3+ 46. Ke5 Kg4 47. Nxf5 Nc5 48. d6 Kg5 49. Ng3 Nb7 50. Ne4+ Kg6 51. d7 Kf7 52. Nf6 Ke7 53. e4 Kd8 54. Ke6 Kc7 55. e5 Kd8 56. Kd5 Kc7 57. e6 Kd8 58. Kc6 Ke7 59. Kxb7 Kxe6 60. d8=Q Ke5 61. Ng4+ Kf4 62. Nf6 Ke5 63. Ne8 Kf4 64. Qd5 Ke3 65. Qc4 Kf3 66. Qd4 Kg2 67. Qd3 Kf2 68. Qc3 Kg2 69. Kc6 Kf2 70. Kd5 Ke2 71. Ke4 Kf2 72. Kf4 Kg2 73. Qd3 Kf2 74. Qe3+ Kg2 75. Qf3+ Kh2 76. Qg3+ Kh1 77. Qf2 1/2-1/2",
    B: "1426",
  },
  {
    W: "1303",
    T: "900+15",
    P: "1. d4 d5 2. f4 Nf6 3. e3 e6 4. c4 c6 5. c5 b6 6. b4 bxc5 7. bxc5 Nbd7 8. Bd2 Be7 9. Nc3 O-O 10. Bd3 h5 11. e4 dxe4 12. Nxe4 Nxe4 13. Bxe4 Bb7 14. Qxh5 Nf6 15. Qe5 Nxe4 16. Qxe4 Bf6 17. Ne2 a5 18. Rb1 Rb8 19. O-O Be7 20. Rb3 f5 21. Qxe6+ Rf7 22. Rfb1 Qd5 23. Qxd5 cxd5 24. Rxb7 Rxb7 25. Rxb7 a4 26. Ba5 Bh4 27. Rxf7 Kxf7 28. c6 Ke7 29. c7 Kd7 30. Nc3 g5 31. g3 Bxg3 32. hxg3 g4 33. Nxa4 Kc6 34. Nc3 Kb7 35. Nxd5 Ka6 36. c8=Q+ Kxa5 1-0",
    B: "1249",
  },
  {
    W: "1399",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 f5 3. exf5 Bc5 4. Nxe5 Bxf2+ 5. Kxf2 Qh4+ 6. g3 Qd4+ 7. Kg2 Qxe5 8. Bd3 Qd6 9. Qh5+ g6 10. fxg6 Nf6 11. g7+ Nxh5 12. gxh8=Q+ Qf8 13. Re1+ Kd8 14. Qxf8# 1-0",
    B: "1602",
  },
  {
    W: "2101",
    T: "900+15",
    P: "1. d4 d5 2. c4 e6 3. Nf3 Nf6 4. Nc3 c5 5. cxd5 exd5 6. Bg5 cxd4 7. Nxd4 Be7 8. g3 Qb6 9. Nb3 Be6 10. Bg2 Nbd7 11. O-O Qd8 12. Nd4 Nc5 13. Nxe6 Nxe6 14. Qa4+ 1-0",
    B: "2240",
  },
  {
    W: "1319",
    T: "900+15",
    P: "1. e4 e5 2. Qh5 d6 3. Bc4 g6 4. Qf3 Nf6 5. h3 Qe7 6. d3 Kd8 7. g4 Be6 8. b3 Nc6 9. c3 a5 10. Bg5 Bg7 11. Bxf6 Bxf6 12. g5 Bxg5 13. Bb5 Rf8 14. Bxc6 bxc6 15. Ne2 a4 16. b4 Qd7 17. a3 c5 18. c4 cxb4 19. axb4 Qc8 20. h4 Be7 21. h5 gxh5 22. Rxh5 Qb7 23. b5 Qa7 24. Rxh7 Qa5+ 25. Kf1 Qb4 26. Nec3 Qb2 27. Qd1 Qxa1 28. Ke1 a3 29. Qb3 a2 30. Ke2 axb1=Q 31. Nxb1 Ra2+ 32. Ke3 Bg5+ 33. Kf3 Rb2 34. Qd1 Rxb1 35. Rh1 Rxd1 0-1",
    B: "1353",
  },
  {
    W: "2146",
    T: "120+1",
    P: "1. d4 Nf6 2. Bg5 c5 3. Nc3 cxd4 4. Qxd4 Nc6 5. Qh4 Qa5 6. O-O-O d6 7. e4 Be6 8. a3 O-O-O 9. f4 h6 10. f5 Bd7 11. Bc4 Ne5 12. Bb3 Kb8 13. Nf3 Rc8 14. Nxe5 Qxe5 15. Bf4 Qa5 16. Bxf7 Rxc3 17. Bd2 Rxc2+ 18. Kxc2 Qc5+ 19. Kb1 Bb5 20. Bc3 a5 21. Rhe1 a4 22. Qf4 h5 23. e5 dxe5 24. Bxe5+ 1-0",
    B: "2121",
  },
  {
    W: "2008",
    T: "300+0",
    P: "1. f4 Nf6 2. Nc3 d5 3. Nf3 Bf5 4. d3 e6 5. e3 h6 6. Be2 c6 7. O-O Bb4 8. Nb1 O-O 9. c3 Ba5 10. Nd4 Bh7 11. b4 Bb6 12. a4 a6 13. Qe1 Nbd7 14. a5 Bxd4 15. cxd4 Qe7 16. Qg3 Rac8 17. Nd2 Qxb4 18. Nf3 Qe7 19. Ba3 c5 20. dxc5 Rfe8 21. d4 Qd8 22. Bc1 Ne4 23. Qe1 Ndf6 24. Ne5 Nd7 25. Qb4 f6 26. Nf3 Qc7 27. Nd2 Nf8 28. Nxe4 Bxe4 29. Bd2 Re7 30. Bf3 Bxf3 31. Rxf3 Rf7 32. Rg3 Qc6 33. Rb1 f5 34. Qa3 Nd7 35. h3 Nf6 36. Rb6 Qe8 37. Qd3 Ne4 38. Rf3 Rc6 39. Be1 Rxb6 40. axb6 Qa4 41. Rf1 Qa1 42. Bh4 Qb2 43. Kh2 g5 44. fxg5 hxg5 45. Bg3 Rh7 46. Be1 g4 47. h4 Kf7 48. Bg3 Kg6 0-1",
    B: "1946",
  },
  {
    W: "893",
    T: "300+0",
    P: "1. e3 e5 2. f4 exf4 3. e4 g5 4. Qg4 d6 5. Qh5 Nf6 6. Qf3 Bg4 7. Qd3 d5 8. h3 dxe4 9. Qb5+ c6 10. Qxg5 Bc8 11. Qxf4 Qd6 12. Ne2 Qxf4 13. Nxf4 Nd5 14. Nxd5 cxd5 15. Bb5+ Bd7 16. Bxd7+ Nxd7 17. Nc3 Nf6 18. Rf1 Bg7 19. d3 exd3 20. cxd3 Nd7 21. Bg5 Bxc3+ 22. bxc3 O-O 23. Be7 Rfe8 24. O-O-O Rxe7 25. Rde1 Rxe1+ 26. Rxe1 Nc5 27. Re7 Nxd3+ 28. Kd2 Nf4 29. Ke3 Nxg2+ 30. Kd4 Rd8 31. Rxb7 a5 32. Ra7 Nf4 33. Rxa5 Ne6+ 34. Ke5 Ng5 35. Rxd5 Rxd5+ 36. Kxd5 Nxh3 37. Ke5 h5 38. Kf5 Kg7 39. a4 Kh6 40. c4 h4 41. Kg4 Ng5 42. Kxh4 Nf3+ 43. Kg4 Nd2 44. Kf5 Nxc4 45. Kf6 Kh7 46. Kxf7 Nb6 47. a5 Nc8 48. Ke8 Nd6+ 49. Kd7 Nc4 50. a6 Nb6+ 51. Kc7 Na8+ 52. Kb7 1-0",
    B: "888",
  },
  {
    W: "1750",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. c3 Nf6 4. Qc2 Be7 5. d4 exd4 6. cxd4 O-O 7. Bc4 c6 8. O-O b5 9. Bb3 a5 10. a3 Bg4 11. Nfd2 Nbd7 12. f3 Be6 13. d5 cxd5 14. exd5 Nxd5 15. Nc3 Ne3 16. Qd3 Nxf1 17. Kxf1 Nc5 18. Qc2 Nxb3 19. Nxb3 Bc4+ 20. Kg1 Qb6+ 21. Kh1 a4 22. Nd2 Be6 23. Nde4 Rac8 24. Qe2 d5 25. Ng3 Bf6 26. Nd1 Rc4 27. Be3 Qc7 28. Nf2 Rc2 29. Qxb5 Bxb2 30. Rb1 d4 31. Bg5 Rxf2 32. Rxb2 Rxb2 33. Qxb2 Bb3 34. h3 h6 35. Bd2 Qxg3 0-1",
    B: "1704",
  },
  {
    W: "1069",
    T: "300+0",
    P: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. d4 Bg4 5. h3 Bh5 6. Nc3 Nf6 7. Bg5 e6 8. Bxf6 Qxf6 9. Bb5+ Nc6 10. Bxc6+ bxc6 11. Qd3 Bxf3 12. gxf3 Bb4 13. O-O-O O-O 14. Rd2 e5 15. h4 Bxc3 16. Qxc3 exd4 17. Rxd4 a5 18. Rg4 Qxc3 19. bxc3 f6 20. Rhg1 g5 21. hxg5 f5 22. Rh4 Kg7 23. Rgh1 Rh8 24. g6 hxg6 25. Rxh8 Rxh8 26. Rxh8 Kxh8 27. Kd2 Kg7 28. f4 Kf6 29. a4 g5 30. fxg5+ Kxg5 31. Ke3 f4+ 32. Kd3 Kf5 33. f3 Ke5 34. c4 dxc4+ 35. Kxc4 Kd6 36. c3 c5 37. Kd3 Kd5 38. c4+ Ke5 39. Kc3 Kd6 40. Kd3 Ke5 0-1",
    B: "1206",
  },
  {
    W: "1598",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 Nc6 5. Nxc6 bxc6 6. Nc3 Nf6 7. Bg5 Be7 8. Bc4 O-O 9. Qf3 Bg4 10. Qg3 Bh5 11. Bh6 Bg6 12. Bg5 d5 13. Bxf6 Bxf6 14. exd5 Bxc3+ 15. Qxc3 cxd5 16. Be2 Re8 17. Qd2 c5 18. O-O-O d4 19. Bf3 Rb8 20. Rhe1 Qb6 21. Rxe8+ Rxe8 22. b3 a5 23. a4 h6 24. g3 Kh7 25. Bg2 Rb8 26. f4 c4 0-1",
    B: "1605",
  },
  {
    W: "1534",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. a3 a6 5. O-O Nf6 6. Re1 O-O 7. Nc3 d6 8. Nd5 Nxd5 9. Bxd5 Bg4 10. h3 Bh5 11. b4 Ba7 12. c3 Qf6 13. Bb2 Bxf3 14. Qxf3 Qxf3 15. gxf3 Ne7 16. Bxb7 Rab8 17. Bxa6 f5 18. Bd3 fxe4 19. fxe4 Bxf2+ 20. Kf1 Bxe1+ 21. Kxe1 Ng6 22. Kd1 Nf4 23. Bc4+ Kh8 24. Bf1 Rbd8 25. Kc2 d5 26. exd5 Nxd5 27. d4 Rf2+ 28. Kb3 exd4 29. cxd4 Ne3 30. Bc4 Nxc4 0-1",
    B: "1556",
  },
  {
    W: "1812",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bc4 e6 4. O-O Nf6 5. Nc3 Be7 6. d3 O-O 7. Bg5 h6 8. Bxf6 Bxf6 9. Re1 Bxc3 10. bxc3 d5 11. exd5 exd5 12. Bb5 Bg4 13. Bxc6 bxc6 14. h3 Bh5 15. g4 Bg6 16. Qd2 Qf6 17. Ne5 a5 18. Nd7 Qf3 19. Nxf8 Rxf8 20. Re3 Qf4 21. Qe2 Qf6 22. Qd2 d4 23. cxd4 cxd4 24. Rg3 c5 25. Qxa5 Re8 26. Qxc5 Re2 27. Rf1 Qf4 28. Qc6 h5 29. gxh5 Bxh5 30. Qg2 Qf6 31. Qd5 Rxc2 32. Qxh5 Rxa2 33. Rg4 Qf3 34. Qg5 g6 35. Rg3 Qc6 36. h4 Rc2 37. h5 Rc5 38. Qh6 g5 39. Rxg5+ Rxg5+ 40. Qxg5+ Kf8 41. Qd8+ Kg7 42. Kh2 Qf6 43. Rg1+ Qg6 44. Rxg6+ fxg6 45. hxg6 Kxg6 46. Qxd4 Kf7 1/2-1/2",
    B: "1820",
  },
  {
    W: "1829",
    T: "300+0",
    P: "1. e4 c6 2. Nf3 d5 3. Nc3 a6 4. d3 Bg4 5. h3 Bxf3 6. Qxf3 e6 7. Be2 Nf6 8. e5 Nfd7 9. Bf4 c5 10. O-O-O Qa5 11. Kb1 Nc6 12. Qg3 b5 13. Bf3 b4 14. Ne2 Rb8 15. Nc1 Nd4 16. Nb3 Nxb3 17. cxb3 Rb5 18. Be2 Nb6 19. d4 c4 20. Rc1 Na4 21. bxa4 Qxa4 22. b3 cxb3 23. Qxb3 Ra5 24. Rc8+ Kd7 25. Rhc1 Qxb3+ 26. axb3 1-0",
    B: "1990",
  },
  {
    W: "983",
    T: "300+0",
    P: "1. e4 c5 2. c4 Nf6 3. Nc3 e5 4. Nf3 d6 5. d4 Be6 6. d5 Bg4 7. Be2 Qa5 8. Bg5 Qb4 9. b3 Qxc3+ 10. Bd2 Qb2 11. Qb1 Qa3 12. h3 Bh5 13. g4 Bg6 14. Bc1 Qb4+ 15. Bd2 Qa3 16. Bc1 Qa5+ 17. Bd2 Qb6 18. Bg5 Be7 19. Bxf6 Bxf6 20. O-O Nd7 21. Nh4 Bxe4 22. Qxe4 Bxh4 23. Rab1 Nf6 24. Qf5 Nd7 25. a3 O-O 26. b4 cxb4 27. Qxd7 Rad8 28. Qb5 Qd4 29. Rbd1 Bxf2+ 30. Rxf2 Qxd1+ 31. Kg2 Qc2 32. Qxb4 Qe4+ 33. Rf3 a5 34. Qb3 a4 0-1",
    B: "977",
  },
  {
    W: "1678",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Be7 4. d4 exd4 5. Nxd4 Nf6 6. Nc3 O-O 7. O-O Bg4 8. f3 Bd7 9. Kh1 c5 10. Ndb5 a6 11. Nxd6 Bxd6 12. Qxd6 Be6 13. Qxc5 Bxc4 14. Qxc4 Nc6 15. Bg5 Qd6 16. Bxf6 Qxf6 17. Nd5 Qe6 18. Rfe1 Rac8 19. Qd3 Ne5 20. Qa3 Nc4 21. Qb3 b5 22. c3 Nb6 23. Nxb6 Qxb6 24. Rac1 Rfd8 25. e5 Rc6 26. Re2 Re6 27. Rce1 a5 28. f4 a4 29. Qb4 Rc6 30. Qe4 Rc4 31. Qf3 Qh6 32. f5 Qf4 33. Qxf4 Rxf4 34. e6 Rxf5 35. e7 Re8 36. Rd2 f6 37. Rd8 Kf7 38. Rxe8 Kxe8 39. h3 Re5 40. Rxe5 fxe5 41. Kg1 Kxe7 42. Kf2 Kf6 43. Ke3 Kf5 44. g4+ Kg5 45. Ke4 h5 46. gxh5 Kxh5 47. Kxe5 Kh4 48. Kd5 Kxh3 49. Kc5 g5 50. Kxb5 g4 51. c4 g3 52. c5 g2 53. c6 g1=Q 54. c7 Qg5+ 55. Kb6 Qf6+ 56. Kb7 Qxb2+ 57. Kc6 Qc3+ 58. Kd7 Qd4+ 59. Ke8 Qe5+ 60. Kd8 Qd6+ 61. Kc8 Kg4 62. Kb8 Qb6+ 63. Kc8 Kf5 64. Kd7 Qb7 65. Kd8 Qd5+ 66. Ke8 Qe6+ 67. Kd8 Qd6+ 68. Kc8 Ke6 69. Kb8 Qb6+ 70. Kc8 Kd6 71. Kd8 Qxc7+ 72. Ke8 Ke6 73. Kf8 Kf6 74. Ke8 Qe7# 0-1",
    B: "1684",
  },
  {
    W: "1495",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Nc3 e6 4. Bb5 a6 5. Bxc6 bxc6 6. O-O d5 7. exd5 cxd5 8. d4 c4 9. Ne5 f6 10. Qh5+ g6 11. Nxg6 hxg6 12. Qxh8 Nh6 13. Bxh6 1-0",
    B: "1369",
  },
  {
    W: "952",
    T: "300+0",
    P: "1. e4 d5 2. f3 e6 3. Nc3 Nf6 4. exd5 exd5 5. b4 Bxb4 6. Nb5 O-O 7. Rb1 Bc5 8. c3 a6 9. d4 Be7 10. Na3 Nbd7 11. Qc2 c5 12. dxc5 Nxc5 13. g4 g5 14. Nh3 b5 15. Nxg5 Nfd7 16. Qxh7# 1-0",
    B: "960",
  },
  {
    W: "1684",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nf6 5. Nxc6 bxc6 6. Bd3 Bb7 7. b3 Bc5 8. h3 O-O 9. Bg5 h6 10. Bh4 Be7 11. Bg3 Bd6 12. Bxd6 cxd6 13. Nc3 Re8 14. O-O c5 15. Qe2 d5 16. Ba6 Bc6 17. Rfe1 dxe4 18. Bb5 Bb7 19. Ba6 Bc6 20. Bb5 Bb7 21. Bc4 d5 22. Ba6 Bxa6 23. Qxa6 Re6 24. Qb7 Re7 25. Qc6 Rc7 26. Qa6 Re7 27. Qa3 d4 28. Ne2 d3 29. cxd3 exd3 30. Nc3 Rxe1+ 31. Rxe1 d2 32. Rd1 Qd6 33. Qb2 Rd8 34. Qc2 Qd3 35. Qxd3 Rxd3 36. Na4 Ne4 37. f3 Nc3 38. Nxc3 Rxc3 39. Rxd2 Rc1+ 40. Kf2 Kf8 41. Ke3 Ke7 42. Kd3 Rg1 43. Kc4 Kf6 44. Kxc5 Kg5 45. Kb5 Kf4 46. Ka6 Kg3 47. Kxa7 Rxg2 48. Rxg2+ Kxg2 49. b4 Kxf3 50. b5 f5 51. b6 Ke4 52. b7 f4 53. b8=Q 1-0",
    B: "1611",
  },
  {
    W: "1067",
    T: "300+0",
    P: "1. e4 d5 2. e5 e6 3. d4 Nc6 4. Bb5 Bd7 5. Bxc6 Bxc6 6. g3 Qd7 7. Ne2 O-O-O 8. O-O f6 9. Nf4 fxe5 10. Nh5 exd4 11. Nf4 Bd6 12. Qxd4 Bxf4 13. Qxf4 Nf6 14. Qg5 d4 15. Nd2 h6 16. Qa5 Kb8 17. Nb3 Nd5 18. Nc5 b6 19. Qa6 bxc5 20. Bd2 Nb4 21. Qc4 Bb5 22. Qxc5 Bxf1 23. Qxb4+ Bb5 24. c3 dxc3 25. bxc3 Kb7 26. Be3 e5 27. Rb1 a6 28. Qe4+ Qd5 29. Qg6 Kc8 30. Ba7 Bd3 31. Qg4+ Rd7 32. Rb8# 1-0",
    B: "1054",
  },
  {
    W: "2360",
    T: "30+0",
    P: "1. g3 Nc6 2. Bg2 Nf6 3. c3 d5 4. d3 e5 5. Nd2 Bd6 6. Qc2 Be6 7. e4 Qd7 8. Ne2 O-O-O 9. O-O h5 10. h4 Rhg8 11. Re1 d4 12. Nf1 Ng4 13. Bg5 f6 14. Bd2 g5 15. hxg5 fxg5 16. f3 Nf6 17. Nh2 h4 18. g4 h3 19. Bh1 Nxg4 20. fxg4 Bxg4 21. Ng3 dxc3 22. Bxc3 Bc5+ 23. d4 Nxd4 24. Bxd4 Qxd4+ 25. Kf1 Rdf8+ 26. Nf5 Bxf5 27. exf5 Rxf5+ 28. Qxf5+ Kb8 29. Ke2 Rf8 30. Qxf8+ Bxf8 31. Rf1 Qc4+ 32. Ke3 Bc5+ 33. Kf3 b5 34. Kg3 a5 35. Nf3 Ka7 36. Kxh3 g4+ 37. Kg3 gxf3 38. Bxf3 Qf4+ 0-1",
    B: "2421",
  },
  {
    W: "2121",
    T: "120+1",
    P: "1. d4 e5 2. dxe5 d6 3. Nf3 Bg4 4. Bg5 Qd7 5. exd6 Bxd6 6. Nc3 Nc6 7. Qd2 f6 8. Be3 O-O-O 9. O-O-O Nge7 10. h3 Bh5 11. g4 Bf7 12. Bg2 Qe6 13. Nd4 Nxd4 14. Qxd4 Be5 15. Qxa7 Bxc3 16. Qxb7# 1-0",
    B: "2122",
  },
  {
    W: "1348",
    T: "120+1",
    P: "1. d3 g6 2. g3 Bg7 3. Bg2 b6 4. a3 Nc6 5. b4 Bb7 6. Ra2 d5 7. Bd2 e5 8. c4 d4 9. b5 Nb4 10. Bxb7 Nxa2 11. Bxa8 Qxa8 12. Qb3 Nc3 13. Bxc3 dxc3 14. Qxc3 Nf6 15. Nf3 O-O 16. e4 Ng4 17. h3 Nf6 18. Nxe5 Nxe4 19. dxe4 Bxe5 20. Qxe5 Re8 21. Qd5 Rxe4+ 22. Kd2 Qe8 23. Nc3 Re5 24. Qc6 Qd8+ 25. Nd5 Qg5+ 26. f4 Qxg3 27. fxe5 Qg2+ 28. Kc3 Qxh1 29. Nf6+ Kg7 30. Qxh1 Kf8 1-0",
    B: "1290",
  },
  {
    W: "1375",
    T: "120+1",
    P: "1. b3 e5 2. Bb2 Nc6 3. e3 Bc5 4. Nf3 d6 5. Bb5 f5 6. d4 Bb6 7. h4 a5 8. Ng5 h6 9. Qh5+ Kd7 10. Qf7+ Ne7 11. Qe6+ Ke8 12. Bxc6+ bxc6 13. Qf7+ Kd7 14. Ne6 Qe8 15. Qxg7 Kxe6 16. d5+ cxd5 17. Bxe5 dxe5 18. Nc3 Rg8 19. Qxh6+ Rg6 20. Qh8 Qxh8 21. g4 fxg4 22. O-O-O Qh6 23. Rhg1 Bxe3+ 24. fxe3 Qxe3+ 25. Kb1 Qxc3 26. Rd3 Qxd3 27. cxd3 Ba6 28. Rd1 Nf5 29. d4 e4 30. Rc1 g3 31. Rc6+ Nd6 32. Rxa6 Rxa6 33. a4 g2 34. Kb2 Rg3 35. Kc2 g1=Q 36. Kd2 Rc6 37. Ke2 Qe3+ 38. Kd1 Rc1# 0-1",
    B: "1436",
  },
  {
    W: "1453",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. cxd4 Bb4+ 7. Bd2 Bxd2+ 8. Qxd2 O-O 9. e5 Ng4 10. h3 Nh6 11. d5 Ne7 12. d6 cxd6 13. exd6 Ng6 14. O-O b6 15. Bd5 Rb8 16. Nc3 Bb7 17. Bxb7 Rxb7 18. Qd5 Rb8 19. Nb5 Re8 20. Ng5 Rc8 21. Nc7 Re5 22. Qd2 Qxg5 23. Qb4 Nf4 24. g3 Nxh3+ 25. Kh2 Qh5 26. Kg2 Ng5 27. Rh1 Qf3+ 28. Kf1 1-0",
    B: "1525",
  },
  {
    W: "1643",
    T: "120+1",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e5 7. Nf3 h6 8. Bxf6 Qxf6 9. Nd5 Qd8 10. Bc4 Be6 11. O-O Nc6 12. b3 b5 13. Bd3 Bxd5 14. exd5 Ne7 15. Re1 Nxd5 16. Be4 Nb6 17. Bxa8 Nxa8 18. Qd5 Be7 19. Rad1 O-O 20. Qe4 Nc7 21. Nxe5 dxe5 22. Rxd8 Rxd8 23. Qxe5 Bd6 24. Qe3 Nd5 25. Qf3 a5 26. g3 Nf6 27. Rd1 Bc7 28. Rxd8+ Bxd8 29. Qa8 1-0",
    B: "1623",
  },
  {
    W: "1210",
    T: "120+1",
    P: "1. e4 d6 2. Nf3 Bg4 3. Bc4 e6 4. h3 Bh5 5. g4 Bg6 6. Nc3 h6 7. d3 d5 8. exd5 exd5 9. Bb5+ c6 10. Ba4 b5 11. Bxb5 cxb5 12. Ne5 Qd6 13. Nxg6 fxg6 14. Be3 Nf6 15. Nxb5 Qb4+ 16. Nc3 d4 17. a3 Qa5 18. Bxd4 Nc6 19. Qe2+ Be7 20. O-O-O Nxd4 21. Qf1 Nb3+ 22. cxb3 Rc8 23. Kb1 Rxc3 24. bxc3 Qxa3 25. Kc2 Qa2+ 26. Kc1 Qa3+ 27. Kb1 Qxb3+ 28. Ka1 Qxc3+ 29. Kb1 Ba3 30. Qe2+ Kf7 31. d4 Rb8+ 32. Ka2 Qb3+ 33. Ka1 Bb2+ 34. Kb1 Bxd4+ 35. Kc1 Qa3+ 36. Kc2 Rb2+ 37. Kc1 Rxe2+ 38. Kb1 Qa2+ 39. Kc1 Rc2# 0-1",
    B: "1199",
  },
  {
    W: "2058",
    T: "120+1",
    P: "1. e4 c5 2. d4 cxd4 3. c3 d3 4. Bxd3 Nc6 5. Ne2 Nf6 6. O-O g6 7. Kh1 Bg7 8. f4 d6 9. Ng3 O-O 10. Be3 Ng4 11. Bg1 e5 12. f5 Qg5 13. Nd2 Ne3 14. Bxe3 Qxe3 15. Rf3 Qg5 16. Qf1 Qxd2 17. f6 Bh6 18. Rb1 Be6 19. a3 d5 20. Nf5 Bxf5 21. exf5 e4 22. Rd1 Qxb2 23. Rh3 Bg5 24. Bb1 Bxf6 25. Qf4 Qe2 26. Rg1 h5 27. fxg6 fxg6 28. Qh6 1-0",
    B: "2075",
  },
  {
    W: "1927",
    T: "120+1",
    P: "1. e4 g6 2. d4 Bg7 3. Nf3 d6 4. h3 e6 5. c4 h6 6. Nc3 Ne7 7. Be2 Nd7 8. Be3 a6 9. Qd2 b6 10. O-O Bb7 11. Rad1 Nf6 12. e5 Ne4 13. Nxe4 Bxe4 14. exd6 cxd6 15. c5 d5 16. Qb4 bxc5 17. Qxc5 O-O 18. Ne5 Rc8 19. Qa3 Bc2 20. Rc1 a5 21. Bf4 a4 22. Bd3 Bxd3 23. Qxd3 Rxc1 24. Rxc1 Qb6 25. Nd7 Qxb2 26. Rb1 Qxa2 27. Nxf8 Bxf8 28. Rb8 Kg7 29. Qe3 Nf5 30. g4 Nxe3 31. Bxe3 a3 32. Bf4 Qa1+ 33. Kg2 a2 34. Be5+ f6 35. Rb7+ Kg8 36. Bf4 Qxd4 37. Be3 Qe4+ 38. Kh2 a1=Q 39. Rb8 Qae5+ 40. Kg1 Qxb8 0-1",
    B: "1792",
  },
  {
    W: "1690",
    T: "120+1",
    P: "1. g3 Nc6 2. Bg2 e5 3. c4 Bc5 4. Qb3 d6 5. e3 Be6 6. Qxb7 Nb4 7. Qxa8 0-1",
    B: "1665",
  },
  {
    W: "1166",
    T: "300+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. Bc4 e6 4. c3 b6 5. d4 Bb7 6. d5 d6 7. dxe6 fxe6 8. Bxe6 Bxe4 9. Qe2 Qe7 10. Qxe4 d5 11. Qxd5 c6 12. Qxc6+ Nxc6 13. Be3 Qxe6 14. O-O Nf6 15. Re1 Qd5 16. Bh6+ Kf8 17. Bxg7+ Kxg7 18. Na3 Ng4 19. h3 Nf6 20. c4 Qd7 21. Nb5 a6 22. Rad1 Qf5 23. Nc7 Rac8 24. Nxa6 Qc2 25. Ne5 Nxe5 26. Rxe5 Qxd1+ 27. Kh2 Qa4 28. Re7+ Kh6 29. Re6 Qxc4 30. Rxf6 Qe4 31. f3 Qa4 32. g4 Qxa6 33. g5+ Kxg5 34. Re6 Qxa2 35. Rxb6 Qxb2+ 36. Kg3 Qxb6 37. f4+ Kf6 38. Kg4 Rc5 39. h4 h6 40. h5 gxh5+ 0-1",
    B: "1129",
  },
  {
    W: "1514",
    T: "180+2",
    P: "1. d4 d5 2. c4 c6 3. e3 e6 4. c5 b6 5. b4 b5 6. a4 Na6 7. axb5 Nxb4 8. bxc6 Nxc6 9. Bb5 Bb7 10. Ra6 Qc8 11. Qa4 Ne7 12. Bxc6+ Bxc6 13. Qa5 Rb8 14. Nc3 Bb7 15. Rxa7 Nc6 16. Qa4 Ra8 17. Rxa8 Bxa8 18. Nf3 f6 19. O-O Be7 20. Ba3 O-O 21. Rb1 Qc7 22. Nb5 Qd7 23. Nd6 Bxd6 24. cxd6 Rd8 25. Rb6 Kf7 26. h3 g5 27. Kh2 h5 28. g3 g4 29. hxg4 hxg4 30. Nd2 Rh8+ 31. Kg2 Rc8 32. Nf1 Rd8 33. Nh2 Nb8 34. Nxg4 Qxa4 0-1",
    B: "1530",
  },
  {
    W: "2214",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. Re1 Nd6 6. Bxc6 dxc6 7. Nxe5 Be7 8. d4 O-O 9. Bf4 Re8 10. Nc3 Bf8 11. Qf3 Be6 12. Rad1 f6 13. Nd3 Bf7 14. Ne2 Qd7 15. b3 Rad8 16. c3 Qf5 17. Ng3 Qc8 18. Nc5 b6 19. Nce4 Nxe4 20. Nxe4 Qd7 21. Ng3 Rxe1+ 22. Rxe1 c5 23. dxc5 Bxc5 24. Ne4 Be7 25. c4 Kf8 26. h4 Qd3 27. Qxd3 Rxd3 28. Bxc7 h6 29. g3 Bb4 30. Re3 Rxe3 31. fxe3 Bg6 32. Nf2 Bb1 33. Bb8 a5 34. Bc7 Bxa2 35. Bxb6 Bxb3 36. Nd3 Bxc4 37. Nxb4 axb4 38. Bc5+ Kf7 39. Bxb4 Ke6 40. Kf2 g6 41. Bc3 h5 42. Bb4 f5 43. Bc3 Kd5 44. Bf6 Ke4 45. Bd4 Bd5 46. Bf6 Bc4 47. Bd4 Bd5 48. Bf6 Bc4 1/2-1/2",
    B: "2079",
  },
  {
    W: "1262",
    T: "180+2",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qd3 Nf6 5. Nc3 Nb4 6. Qd2 Qe7 7. f3 d5 8. Nge2 dxe4 9. Nxe4 Nxe4 10. fxe4 Qxe4 11. a3 Nxc2+ 12. Kf2 Qf5+ 13. Qf4 Bc5+ 14. Kf3 Ne1+ 15. Kg3 Qxf4+ 16. Nxf4 O-O 17. Bc4 Re8 18. Bd2 Nc2 19. Rae1 Rxe1 20. Bxe1 Nxe1 21. Rxe1 Bf5 22. b4 Bd6 23. Rf1 Re8 24. Kh4 Be7+ 25. Kg3 Bg5 26. Nd5 Be4 27. Nxc7 Re5 28. Bxf7+ Kf8 29. Bd5+ Ke7 30. Bxe4 Rxe4 31. Nd5+ Ke6 0-1",
    B: "1296",
  },
  {
    W: "1655",
    T: "180+2",
    P: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 h6 5. Bxf6 Qxf6 6. exd5 exd5 7. Nxd5 Qe6+ 8. Qe2 Qxe2+ 9. Bxe2 Na6 1-0",
    B: "1539",
  },
  {
    W: "981",
    T: "180+2",
    P: "1. Nf3 d5 2. e4 dxe4 3. Ne5 Qd5 4. f4 exf3 5. d4 fxg2 6. Nc3 gxh1=Q 7. Nxd5 Qxd5 8. Bc4 Qd6 9. Qf3 Qxd4 10. Qxf7+ Kd8 11. Qxf8# 1-0",
    B: "946",
  },
  {
    W: "1761",
    T: "180+2",
    P: "1. d4 d5 2. c4 Nf6 3. Nc3 e6 4. a3 c6 5. Bg5 Be7 6. e3 a6 7. Bd3 b5 8. cxd5 cxd5 9. Qc2 g6 10. Nge2 Ng4 11. Bxe7 Qxe7 12. h3 Nf6 13. O-O O-O 14. Rfe1 Nfd7 15. Rad1 f5 16. f3 Nf6 17. e4 dxe4 18. fxe4 Bb7 19. exf5 exf5 20. Nf4 Qd6 21. Qb3+ Kh8 22. Ne6 Nbd7 23. Nxf8 Qxd4+ 24. Kh1 Rxf8 25. Qe6 Qf2 26. Re2 Qg3 27. Rde1 Qxh3+ 28. Kg1 Qxd3 29. Re3 Qd4 30. Qe7 Re8 31. Qd6 Rxe3 0-1",
    B: "1759",
  },
  {
    W: "1789",
    T: "180+2",
    P: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. b3 Bb7 5. e3 Be7 6. Bb2 d6 7. Nbd2 Ne4 8. Bd3 f5 9. d5 e5 10. Nxe4 fxe4 11. Bxe4 O-O 12. O-O Nd7 13. Qd3 Nf6 14. Bf5 Bc8 15. e4 Bxf5 16. exf5 Nh5 17. Rfe1 Qd7 18. g4 Nf4 19. Qe4 Rf6 20. Ng5 Rh6 21. Ne6 Nxe6 22. fxe6 Qe8 23. f4 exf4 24. Qxf4 Qg6 25. Rf1 Rf8 26. Qg3 Bf6 27. Bxf6 Rxf6 28. e7 Qe8 29. g5 Rxf1+ 30. Rxf1 Qxe7 31. gxh6 g6 32. Qc3 a5 33. a4 Qg5+ 34. Kh1 Qxh6 35. Qf6 Qg7 36. Qd8+ 1-0",
    B: "1762",
  },
  {
    W: "1075",
    T: "180+2",
    P: "1. e3 e5 2. Nc3 d5 3. d4 exd4 4. exd4 Qe7+ 5. Be3 Nf6 6. Nf3 Bg4 7. Be2 Bxf3 8. Bxf3 c6 9. Qe2 Qe6 10. O-O-O Bb4 11. Bd2 Bxc3 12. Bxc3 Qxe2 13. Bxe2 Ne4 14. f3 Nf2 15. g4 Nxh1 16. Rxh1 O-O 17. h4 Nd7 18. h5 Rfe8 19. Bd2 Rxe2 20. h6 gxh6 21. Bxh6 Rae8 22. Kd1 R2e6 23. Bg5 f6 24. Bd2 Nb6 25. f4 Nc4 26. Be1 Nxb2+ 27. Kd2 Nc4+ 28. Kc3 Rxe1 29. Rxe1 Rxe1 30. g5 fxg5 31. fxg5 Re2 0-1",
    B: "1168",
  },
  {
    W: "1354",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. Nc3 Nd4 5. d3 Nxb5 6. Nxb5 c6 7. Nc3 b6 8. O-O Ba6 9. h4 Bb4 10. a3 Be7 11. b4 Ng4 12. Ne2 Bxh4 13. Ng3 d6 14. Nf5 Bf6 15. Ne1 Bh4 16. Qxg4 Bc8 17. Qxg7 Bf6 18. Qg3 Bxf5 19. exf5 Bh4 20. Qh3 Bg5 21. Nf3 Bxc1 22. Raxc1 Qf6 23. Ng5 Qxg5 24. d4 O-O-O 25. dxe5 Kb8 26. exd6 Rxd6 27. Rcd1 Rxd1 28. Rxd1 Rd8 29. Rxd8+ Qxd8 30. Qg3+ Kb7 31. c4 Qd1+ 32. Kh2 Qd4 33. c5 b5 34. Kh3 Qd5 35. Qg7 Qxf5+ 36. Qg4 Qxf2 37. Qd7+ Kb8 38. Qxc6 Qe3+ 39. Kh2 Qf4+ 40. Kg1 Qe3+ 41. Kh2 Qf4+ 42. Kg1 Qc1+ 43. Kf2 Qb2+ 44. Kg3 Qxa3+ 45. Kh2 Qxb4 46. Qe8+ Kc7 47. Qxf7+ Kc6 48. Qxh7 Qxc5 49. Qh6+ Qd6+ 50. Qxd6+ Kxd6 51. g4 Ke7 52. Kg2 Kf6 53. Kf2 a5 54. Ke2 Kg5 55. Kd3 Kxg4 56. Kc3 Kf5 57. Kd4 a4 58. Kc5 a3 59. Kxb5 a2 60. Kb4 a1=Q 61. Kb3 Qb1+ 62. Kc4 Qc2+ 63. Kd4 Qe4+ 64. Kc3 Ke5 65. Kd2 Kd5 66. Kc3 Qc4+ 67. Kd2 Kd4 68. Ke1 Ke3 69. Kd1 Kd3 70. Ke1 Qe4+ 71. Kf2 Qe3+ 72. Kg2 Ke2 73. Kh2 Kf2 74. Kh1 Qh3# 0-1",
    B: "1434",
  },
  {
    W: "1920",
    T: "480+0",
    P: "1. e4 c5 2. Bc4 e6 3. a3 a6 4. d3 Nc6 5. Nc3 Qc7 6. h3 Nf6 7. Nf3 Be7 8. O-O b5 9. Ba2 Bb7 10. Bb1 O-O 11. Ne2 d5 12. exd5 Nxd5 13. c3 Rad8 14. d4 g6 15. Re1 Bf6 16. Qc2 c4 17. Bg5 Bxg5 18. Nxg5 Nf6 19. f3 Na5 20. Ng3 Qxg3 0-1",
    B: "2092",
  },
  {
    W: "2325",
    T: "60+0",
    P: "1. d4 c5 2. e3 cxd4 3. exd4 Nf6 4. c3 g6 5. f4 Bg7 6. Nf3 O-O 7. Bd3 d6 8. O-O Nc6 9. Nbd2 Qb6 10. Kh1 e5 11. Qb3 Qc7 12. fxe5 dxe5 13. dxe5 Ng4 14. Ne4 Be6 15. Bc4 Na5 16. Bxe6 Nxb3 17. Bxb3 Nxe5 18. Nxe5 Bxe5 19. Bh6 Bxh2 20. Nf6+ Kh8 21. Rad1 Be5 22. Nd5 Qa5 23. Ne3 Bg7 24. Bf4 Qh5+ 25. Kg1 Rae8 26. g3 Be5 27. Bxe5+ Rxe5 28. Nd5 Kg7 29. Ne7 Qg5 30. Nd5 Qxg3+ 31. Kh1 Rh5# 0-1",
    B: "2356",
  },
  {
    W: "1112",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. Bxc6 dxc6 5. Nc3 Bb4 6. d3 O-O 7. Bd2 Re8 8. O-O Bg4 9. h3 Bh5 10. g4 Nxg4 11. hxg4 Bxg4 12. Nh2 Bxd1 13. Rfxd1 Bxc3 14. Bxc3 Qg5+ 15. Kf1 Rad8 16. Bd2 Qf6 17. Nf3 Qxf3 18. Re1 Re6 19. Re3 Qg4 20. Rae1 Rh6 21. f3 Rh1+ 22. Ke2 Qg2+ 23. Kd1 Rxe1+ 24. Rxe1 Rd6 25. Be3 Qxf3+ 26. Kd2 Rg6 27. Re2 Rg2 28. Rxg2 Qxg2+ 29. Ke1 Qg1+ 30. Kd2 Qg2+ 31. Kc3 h5 32. Bxa7 h4 33. d4 h3 34. dxe5 h2 0-1",
    B: "1156",
  },
  {
    W: "1870",
    T: "60+0",
    P: "1. c4 Nc6 2. g3 d6 3. Bg2 e5 4. Nc3 f5 5. d3 Nf6 6. Nf3 Be6 7. O-O h6 8. Nh4 g5 9. Ng6 Rg8 10. Nxf8 Kxf8 11. Nd5 Kf7 12. Nxf6 Qxf6 13. e3 Rae8 14. Qh5+ Qg6 15. Qxg6+ Kxg6 16. f4 gxf4 17. exf4 Nd4 18. Be3 c5 19. Bxd4 exd4 20. Rfe1 Bc8 21. Kf2 b6 22. Bd5 Bd7 23. a4 Rxe1 24. Kxe1 Re8+ 25. Kf2 Re3 26. Rd1 Bxa4 27. Rd2 Bd7 28. Bf3 Kf6 29. h3 Be8 30. g4 fxg4 31. hxg4 Bd7 32. f5 Kg5 33. Be4 h5 34. gxh5 Kxh5 35. Kg2 1-0",
    B: "1855",
  },
  {
    W: "2148",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Bc4 e6 5. O-O Nf6 6. e5 dxe5 7. Nxe5 Qc7 8. Bf4 Bd6 9. Bb5+ Bd7 10. Bxd7+ Nbxd7 11. Nxf7 Kxf7 12. Bxd6 Qxd6 13. Nd2 h5 14. Re1 Ng4 15. g3 Ndf6 16. Nc4 Qc7 17. b3 h4 18. Qf3 hxg3 19. hxg3 Nh2 20. Qe2 Nhg4 21. Qxe6+ Kf8 22. Qd5 Rd8 23. Qg2 Rd5 24. Re2 Rdh5 25. Rae1 Rh1+ 26. Qxh1 Rxh1+ 27. Kxh1 Nxf2+ 28. Rxf2 Qxg3 29. Ref1 Qh3+ 30. Kg1 Qg3+ 31. Rg2 Qc3 32. Ne5 d3 0-1",
    B: "2143",
  },
  {
    W: "2091",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. d4 Nxe4 4. Nc3 Nxc3 5. bxc3 exd4 6. cxd4 d5 7. c4 Bb4+ 8. Bd2 Bxd2+ 9. Qxd2 O-O 10. Be2 dxc4 11. O-O Be6 12. Rfd1 c6 13. Rab1 b5 14. a4 a6 15. a5 Nd7 16. Qf4 Qb8 17. Qg5 Qd8 18. Qg3 Nf6 19. Ne5 Ne4 20. Qe3 Qd5 21. Bf3 f6 22. Bxe4 Qd7 23. Bxc6 Qe7 24. Bxa8 fxe5 25. Bf3 exd4 26. Rxd4 Rxf3 27. Qxf3 Bf5 28. Qa8+ 1-0",
    B: "2032",
  },
  {
    W: "1252",
    T: "60+0",
    P: "1. c4 e6 2. Nc3 d5 3. Nf3 dxc4 4. e3 Nf6 5. Bxc4 Bd6 6. Bf1 O-O 7. g3 Re8 8. Bg2 e5 9. O-O Be6 10. Ne2 c6 11. d3 Nbd7 12. e4 Qc7 13. Be3 Bc5 14. Bxc5 Nxc5 15. b4 Na6 16. Qa4 Rad8 17. Rad1 b5 18. Qc2 Nxb4 19. Qc3 Qa5 20. Rd2 Qxa2 21. Rxa2 Nxa2 22. Qa3 Rxd3 23. Qxd3 Nb4 24. Qd6 Bc4 25. Qxb4 Rc8 26. Qxc4 bxc4 27. Nxe5 Nxe4 28. Nxc4 Nc3 29. Nb2 1-0",
    B: "1388",
  },
  {
    W: "1643",
    T: "60+0",
    P: "1. b4 e6 2. Bb2 Nf6 3. Nf3 Bxb4 4. e3 Be7 5. Bc4 O-O 6. O-O a6 7. Bb3 c5 8. c4 Nc6 9. a4 d6 10. d4 cxd4 11. exd4 b6 12. d5 exd5 13. cxd5 Na5 14. Ba2 Bb7 15. Nc3 Re8 16. Bb1 Nc4 17. Bc1 Qc7 18. Qd3 Na5 19. Bb2 Qc4 20. Qc2 Qb3 21. Qxb3 Nxb3 22. Ra3 Na5 23. Ne4 Nxd5 24. Rd1 Nf6 25. Bc3 Bxe4 26. Bxa5 bxa5 27. Bxe4 Nxe4 28. Re3 Nc5 29. Nd4 Bf6 30. Nc2 Re7 31. Rxe7 Bxe7 32. Rc1 Re8 33. Ne3 Kf8 34. h3 Ne4 35. Kh2 Nxf2 36. g3 Ne4 37. Rc2 Nc5 38. Rd2 Nxa4 39. Rb2 Nc5 40. Rb7 a4 41. Rb8 Rxb8 0-1",
    B: "1734",
  },
  {
    W: "1889",
    T: "60+0",
    P: "1. d4 d5 2. Bf4 c6 3. Nf3 Bf5 4. e3 e6 5. c3 Nf6 6. Bd3 Bg6 7. Qc2 Bd6 8. Bg3 Qc7 9. Nbd2 Nbd7 10. e4 dxe4 11. Nxe4 Nxe4 12. Bxe4 Bxe4 13. Qxe4 Nf6 14. Qc2 O-O-O 15. O-O-O Bxg3 16. hxg3 h6 17. Ne5 Rhf8 18. f4 Nd5 19. Rhe1 Ne7 20. g4 f6 21. Nf3 Qd7 22. Kb1 Ng6 23. f5 exf5 24. gxf5 Ne7 25. g4 Nd5 26. Re4 Rde8 27. Rde1 Rxe4 28. Qxe4 Re8 29. Qxe8+ Qxe8 30. Rxe8+ Kd7 31. Rg8 g5 32. fxg6 Ke6 33. Rf8 Nf4 34. g7 Ne2 35. g8=Q+ 1-0",
    B: "1898",
  },
  {
    W: "2022",
    T: "60+0",
    P: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. Bd3 O-O 6. Be3 Bg4 7. O-O Bxf3 8. Qxf3 c6 9. Rad1 Nbd7 10. Rfe1 e5 11. dxe5 Nxe5 12. Qe2 Nxd3 13. cxd3 Qe7 14. f4 d5 15. e5 Nd7 16. d4 b6 17. Qf3 Rae8 18. g4 f6 19. Rf1 fxe5 20. dxe5 Nxe5 21. Qg3 Nc4 22. Rfe1 Nxe3 23. Rd3 Qc5 24. Rdxe3 Rxe3 25. Qxe3 Bd4 26. Qxd4 Qxd4+ 0-1",
    B: "1930",
  },
  {
    W: "2101",
    T: "60+0",
    P: "1. c4 Nf6 2. b3 b6 3. Bb2 Bb7 4. Nf3 e6 5. g3 Be7 6. Bg2 c5 7. O-O O-O 8. d4 cxd4 9. Nxd4 Bxg2 10. Kxg2 Nc6 11. Nxc6 dxc6 12. Qc2 Qc7 13. Nc3 c5 14. Nb5 Qc6+ 15. Kg1 a6 16. Nc3 Rad8 17. Rfd1 h6 18. Rxd8 Rxd8 19. Rd1 Rxd1+ 20. Qxd1 Ne4 21. Nxe4 Qxe4 22. e3 b5 23. Qd7 Qb1+ 24. Kg2 Qxb2 25. Qxe7 Qxa2 26. Qxc5 Qxb3 27. Qc8+ Kh7 28. cxb5 Qxb5 29. Qc2+ Kg8 30. Qc8+ Kh7 31. Qc7 f6 32. Qe7 Qd5+ 33. Kg1 a5 34. Qa3 Qd1+ 35. Kg2 Qd5+ 36. f3 Qa8 37. Qa4 Qa6 38. Qe4+ Kg8 39. f4 a4 40. Qa8+ Qxa8+ 0-1",
    B: "2099",
  },
  {
    W: "2114",
    T: "60+0",
    P: "1. e4 e6 2. d4 b6 3. Bd3 Bb7 4. c3 Ne7 5. Qc2 g6 6. Be3 Bg7 7. Na3 O-O 8. Nc4 d6 9. Nd2 Nd7 10. Ne2 Rc8 11. Nf3 c5 12. Qd2 cxd4 13. cxd4 e5 14. d5 Nc5 15. Bb1 f5 16. Bh6 Nxe4 17. Bxe4 fxe4 18. Bxg7 Kxg7 19. Nh4 Nf5 20. Nxf5+ Rxf5 21. O-O Qf6 22. Ng3 Rf4 23. Rae1 Qf7 24. Nxe4 Bxd5 25. Nxd6 Qd7 26. Nxc8 Rg4 27. f3 Rd4 28. Qc3 Rc4 29. Qxe5+ Kh6 30. Ne7 Rc2 31. Nxd5 Rd2 32. Nf6 Qd3 33. Ng8# 1-0",
    B: "2135",
  },
  {
    W: "1805",
    T: "180+0",
    P: "1. e4 c5 2. Bc4 e6 3. f4 a6 4. Nf3 b5 5. Be2 Bb7 6. d3 d5 7. Nc3 Nf6 8. e5 Ng4 9. Ng5 Nh6 10. Bh5 g6 11. Bf3 b4 12. Ne2 Nf5 13. O-O h6 14. Nh3 Nd7 15. g4 Nh4 16. f5 Nxf3+ 17. Rxf3 d4 18. Rf2 Nxe5 19. fxg6 fxg6 20. Nef4 g5 21. Nxe6 Qd5 22. Nc7+ Kd7 23. Nxd5 Bxd5 24. Qe1 Bd6 25. Nxg5 hxg5 26. Bxg5 Rae8 27. h4 Nxg4 28. Rf3 Bxf3 29. Qf1 Bh2# 0-1",
    B: "1456",
  },
  {
    W: "1987",
    T: "180+0",
    P: "1. d4 Nf6 2. Bg5 e6 3. e4 d5 4. e5 h6 5. Bh4 g5 6. exf6 gxh4 7. Nf3 Qxf6 8. Bd3 Nc6 9. Nc3 Bd7 10. Nb5 O-O-O 11. a4 a6 12. c3 axb5 13. axb5 Nb8 14. Qa4 Bd6 15. Qa5 Rhg8 16. b6 Rxg2 17. Ke2 Re8 18. Qa8 e5 19. dxe5 Bxe5 20. Nxe5 Qxe5+ 21. Kf1 Rxf2+ 22. Kxf2 Qe3+ 0-1",
    B: "1908",
  },
  {
    W: "1611",
    T: "180+0",
    P: "1. d4 d5 2. e3 Nc6 3. Nf3 Nf6 4. h3 Bf5 5. Nc3 e6 6. b3 Bb4 7. Bd2 Qe7 8. a3 Bxa3 9. Bb5 Qd6 10. Bxc6+ bxc6 11. Ne5 O-O 12. O-O Qe7 13. Nxc6 Qd6 14. Ne5 Qe7 15. Nb5 Bd6 16. Nxd6 Qxd6 17. c4 dxc4 18. bxc4 Nd7 19. c5 Qd5 20. Nxd7 Qxd7 21. Qf3 c6 22. Rfd1 Qd5 23. Bc3 Qxf3 24. gxf3 Bxh3 25. Kh2 Bf5 26. e4 Bg6 27. f4 Bh5 28. Rg1 Bf3 29. e5 Be4 30. Ra6 g6 31. Rf1 Bd3 32. Rd1 Bxa6 33. f3 Be2 34. Re1 Bxf3 35. f5 exf5 36. Kg3 Be4 37. Kf4 a5 38. Ra1 a4 39. Bb4 Rfb8 40. Ba3 Rb3 41. Kg5 Kg7 42. Kf4 Rf3+ 43. Kg5 Rb8 44. d5 cxd5 45. c6 Rc3 46. c7 Rxc7 47. Ra2 Rc3 48. Bb2 Rcb3 49. e6+ Kf8 50. Be5 Rg3+ 51. Kf6 Rb4 52. Bd6+ Ke8 53. exf7+ Kd7 54. f8=Q 1-0",
    B: "1614",
  },
  {
    W: "1665",
    T: "180+0",
    P: "1. e4 g6 2. d4 Bg7 3. Nf3 d5 4. e5 f6 5. Bf4 fxe5 6. Bxe5 Bxe5 7. dxe5 Qd7 8. Nc3 Qf5 9. Qxd5 c6 10. Qd3 Qe6 11. O-O-O Nd7 12. Re1 Nc5 13. Qd2 b6 14. Rd1 Bb7 15. b4 Na6 16. Nd4 Qxe5 17. Re1 Qd6 18. Ne4 Qxb4 19. Qe2 Qxd4 20. c3 Qg7 21. Nd6+ Kd7 22. Nxb7 Qxc3+ 23. Qc2 Qxe1+ 24. Kb2 Qb4+ 25. Qb3 Qxb3+ 26. axb3 Kc7 27. Bxa6 b5 28. Nc5 Kb6 29. Bb7 Rd8 30. b4 Rd2+ 31. Kc3 Rxf2 32. Ra1 Nf6 33. Ra6+ Kc7 34. Rxa7 Kb6 35. Ra6+ Kc7 36. Ne6+ Kxb7 37. Ra1 Nd5+ 38. Kd4 Rxg2 39. Nc5+ Kb6 40. Ra6+ Kc7 41. Ra7+ Kb8 42. Rd7 Nxb4 43. Rb7+ Ka8 44. Rxe7 Rd2+ 45. Kc3 Nd5+ 46. Kxd2 Nxe7 47. Ne6 b4 48. Nc7+ Kb7 49. Ne6 b3 50. Nc5+ Kc7 51. Nxb3 Rf8 52. Kc3 Rf3+ 53. Kb4 Rxb3+ 54. Kxb3 Kd6 0-1",
    B: "1667",
  },
  {
    W: "1499",
    T: "180+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Bxf7+ Kxf7 6. Ne5+ Ke8 7. Qxg4 Qf6 8. Qh5+ Ke7 9. Ng4 Qg6 10. Qe5+ Kd8 11. Qxf4 d5 12. Qxf8+ Qe8 13. Qxe8+ Kxe8 14. O-O dxe4 15. Nf6+ Ke7 16. Nd5+ Kd6 17. c4 Ne7 18. d3 Nbc6 19. Bf4+ Kd7 20. Nxc7 Rb8 21. Nd5 Ra8 22. Nxe7 Nxe7 23. Nc3 exd3 24. Rad1 b6 25. Rxd3+ Kc6 26. Re1 Nf5 27. Be5 Rg8 28. g3 Nh4 29. Ne4 Bg4 30. Nf6 Nf3+ 31. Rxf3 Bxf3 32. Nxg8 Rxg8 33. Bf4 Rd8 34. Re6+ Kb7 35. Re7+ Ka6 36. b3 Bg4 37. Rxh7 Rd1+ 38. Kf2 Ra1 39. Bb8 Rxa2+ 40. Ke3 Re2+ 41. Kf4 Ka5 42. Kxg4 Kb4 43. Rxa7 Rxh2 44. Rb7 Kxb3 45. Rxb6+ Kxc4 46. Bf4 Rd2 47. Rc6+ Kd5 48. Rd6+ Kc5 49. Rxd2 Kc6 50. Kf3 Kc5 51. g4 Kc6 52. g5 Kb6 53. g6 Kb5 54. g7 Kb4 55. g8=Q 1-0",
    B: "1533",
  },
  {
    W: "1679",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Nf6 3. d4 e6 4. Nf3 c6 5. dxe6 Bxe6 6. Nc3 Bb4 7. Bd2 Bxc3 8. Bxc3 O-O 9. Bd3 Nd5 10. O-O Nxc3 11. bxc3 b6 12. c4 c5 13. d5 Bg4 14. h3 Bxf3 15. Qxf3 Qg5 16. Qe4 f5 17. Qe6+ Kh8 18. Rfe1 Na6 19. Qd7 Nb4 20. a3 Nxd3 21. cxd3 Rfd8 22. Qe6 Re8 23. Qxe8+ 1-0",
    B: "1717",
  },
  {
    W: "1834",
    T: "180+0",
    P: "1. e4 a6 2. d4 b5 3. g3 Bb7 4. Bg2 e6 5. a4 b4 6. a5 c5 7. Be3 cxd4 8. Qxd4 Nc6 9. Qb6 Qxb6 10. Bxb6 Nf6 11. e5 Nd5 12. Bxd5 exd5 13. Nf3 d6 14. Bc7 dxe5 15. Nxe5 Nxe5 16. Bxe5 Bc5 17. Bxg7 Rg8 18. Bh6 d4 19. Nd2 Bxh1 20. f3 Bg2 21. Kf2 Bh3 22. g4 f5 23. Re1+ Kf7 24. Re5 Rac8 25. Rxf5+ Kg6 26. Rh5 d3+ 27. Kg3 dxc2 28. Nb3 Bd6+ 29. Kxh3 Rc3 30. bxc3 bxc3 31. Rg5+ Kf7 32. Rxg8 Kxg8 33. f4 Kf7 34. f5 Be5 35. Kg2 Kf6 36. Kf3 Bd6 37. Bf4 Bxf4 38. Kxf4 h5 39. gxh5 Kg7 40. Ke4 Kf6 41. Kd4 Kxf5 42. Kxc3 Kg5 43. Kxc2 Kxh5 44. Kc3 Kg4 45. Kc4 Kh3 46. Nc5 Kxh2 47. Nxa6 Kg3 48. Nc5 Kf3 49. a6 Ke3 50. a7 Kd2 51. a8=Q Kc2 52. Nd7 Kd2 53. Nf8 Ke3 54. Qd5 Kf4 55. Kc5 Ke3 56. Kd6 Kf4 57. Ke6 Ke3 58. Qc4 Kf3 59. Kf5 Ke3 60. Qe4+ Kd2 61. Qf3 Kc2 62. Ke4 Kd2 63. Qd3+ Ke1 64. Qc2 Kf1 65. Ke3 Kg1 66. Kf3 Kh1 67. Qg2# 1-0",
    B: "1844",
  },
  {
    W: "1790",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 a6 6. Bc4 b5 7. Bb3 Qc7 8. Nxc6 dxc6 9. Be3 Nf6 10. Qe2 c5 11. a4 c4 12. Ba2 Bb7 13. axb5 axb5 14. O-O Bb4 15. Nxb5 Qc8 16. Bxc4 Nxe4 17. Rxa8 Qxa8 18. Bd3 O-O 19. Qg4 Nf6 20. Qg3 Nh5 21. Qh4 Nf6 22. Bd4 Ne4 23. Nc3 Bxc3 24. Bxc3 g6 25. Bxe4 Bxe4 26. Qf6 Bxg2 27. Qg7# 1-0",
    B: "1821",
  },
  {
    W: "2066",
    T: "180+0",
    P: "1. e4 c5 2. f4 Nc6 3. Bc4 e6 4. Nc3 g6 5. Nf3 Bg7 6. d3 Nge7 7. e5 O-O 8. O-O d5 9. exd6 Qxd6 10. Ne4 Qc7 11. Nxc5 Nd4 12. Nb3 Nxf3+ 13. Qxf3 a6 14. d4 Rb8 15. Bd3 b5 16. c3 a5 17. Nc5 Nf5 18. g4 Nxd4 19. cxd4 Bxd4+ 20. Be3 Bxc5 21. Rac1 Bxe3+ 22. Qxe3 Qb6 23. Qxb6 Rxb6 24. f5 exf5 25. gxf5 gxf5 26. Bxf5 Bxf5 27. Rxf5 a4 28. Rcc5 Rfb8 29. Rc7 Rg6+ 30. Kf2 Rg7 31. h4 b4 32. h5 b3 33. a3 h6 34. Rf6 Rd8 35. Ke3 Rg3+ 36. Kf4 Rg2 37. Rfxf7 Rxb2 38. Rg7+ Kh8 39. Rh7+ Kg8 40. Rcg7+ Kf8 41. Rf7+ Ke8 42. Re7+ Kf8 43. Rhf7+ Kg8 44. Rg7+ Kh8 45. Rh7+ Kg8 46. Reg7+ Kf8 47. Rf7+ Kg8 48. Rfg7+ Kf8 49. Rf7+ Kg8 50. Rfg7+ Kf8 1/2-1/2",
    B: "2015",
  },
  {
    W: "2074",
    T: "180+0",
    P: "1. d4 c6 2. c4 d5 3. g3 Nf6 4. Bg2 g6 5. Nf3 Bg7 6. O-O O-O 7. Qc2 a6 8. a4 dxc4 9. Qxc4 b5 10. axb5 cxb5 11. Qc2 Bb7 12. Bg5 Nbd7 13. Nc3 h6 14. Bxf6 Nxf6 15. e4 Nxe4 16. Nxe4 Rc8 17. Qe2 Bd5 18. Rfc1 Bc4 19. Qe3 a5 20. b3 Bd5 21. Rxc8 Qxc8 22. Rxa5 Bxe4 23. Qxe4 Qc1+ 24. Bf1 e6 25. Rxb5 Rc8 26. Rc5 Rxc5 27. dxc5 Qxc5 28. Bc4 Bf8 29. Qe5 Qb4 30. h4 Bd6 31. Qd4 Bc5 32. Qd8+ Kg7 33. Kg2 Be7 34. Qd7 Bf6 35. Bxe6 Qf8 36. h5 gxh5 37. Qd5 fxe6 38. Qxe6 Qb4 39. Qd7+ Kg6 40. Qe8+ Kg7 41. Qxh5 Qxb3 42. Qf5 Qb7 43. Kh3 Qc6 44. Nh4 Qe6 45. Qxe6 Bxh4 46. gxh4 h5 47. Qf5 Kg8 48. Qg5+ Kh7 49. Qxh5+ Kg8 50. Qg5+ Kh8 51. h5 Kh7 52. h6 Kh8 53. Qg7# 1-0",
    B: "2168",
  },
  {
    W: "1465",
    T: "180+0",
    P: "1. e4 e5 2. Bc4 Nf6 3. Nc3 d6 4. Qf3 Bg4 5. Qg3 h5 6. f3 Be6 7. d3 Bxc4 8. dxc4 Be7 9. Bg5 Nc6 10. Nd5 Nxd5 11. cxd5 Nd4 12. O-O-O Bxg5+ 13. f4 Bxf4+ 14. Qxf4 exf4 0-1",
    B: "1246",
  },
  {
    W: "1615",
    T: "180+0",
    P: "1. e4 e6 2. Nf3 b6 3. Bc4 Bb7 4. d3 d5 5. exd5 exd5 6. Bb3 Nf6 7. O-O Be7 8. Re1 O-O 9. Nc3 c5 10. Bg5 d4 11. Ne4 Nbd7 12. Nxf6+ Nxf6 13. c3 h6 14. Bh4 Qd6 15. cxd4 cxd4 16. Bxf6 Bxf6 17. Rc1 Rac8 18. Rxc8 Rxc8 19. Qe2 a5 20. Bc4 Qb4 21. b3 Bxf3 22. Qxf3 Qxe1# 0-1",
    B: "1627",
  },
  {
    W: "1320",
    T: "180+0",
    P: "1. Nf3 e5 2. g3 Bc5 3. Bg2 Bxf2+ 4. Kxf2 d6 5. e3 Nf6 6. b3 c6 7. Bb2 O-O 8. d3 c5 9. Nbd2 Re8 10. Re1 d5 11. Kg1 d4 12. exd4 cxd4 13. c3 Nc6 14. cxd4 Qb6 15. Kh1 Ng4 16. Re2 Bf5 17. Nc4 Qd8 18. dxe5 Bxd3 19. Rd2 Nb4 20. a3 Nc2 21. Rxd3 Nf2+ 22. Kg1 Nxd1 23. Rxd8 Raxd8 24. Rc1 Nce3 25. Nxe3 Nxe3 26. Bh1 Rd1+ 27. Rxd1 Nxd1 28. Bd4 h6 29. Bxa7 Nc3 30. Bd4 Ne4 31. Nh4 Ng5 32. Bxb7 Ne6 33. Bb2 Rb8 34. Bd5 Nc5 0-1",
    B: "1458",
  },
  {
    W: "1823",
    T: "180+0",
    P: "1. f4 c5 2. Nf3 Nc6 3. e3 e6 4. d4 cxd4 5. exd4 Nf6 6. c3 Be7 7. Bd3 a6 8. Nbd2 Nd5 9. O-O Ne3 10. Qe2 Nxf1 11. Qxf1 O-O 12. Ne5 f6 13. Ng4 f5 14. Ne5 d6 15. Nef3 Bd7 16. Qf2 Bf6 17. b3 e5 18. fxe5 dxe5 19. Ba3 Re8 20. Bc4+ Be6 21. dxe5 Bxe5 22. Nxe5 Nxe5 23. Bxe6+ Rxe6 24. Re1 Qa5 25. Qxf5 Rae8 26. Bb4 Qxa2 27. Nc4 Nxc4 28. Rxe6 Qa1+ 29. Kf2 Qb2+ 30. Kg3 Qxg2+ 31. Kxg2 Ne3+ 32. Kf3 Nxf5 33. Rxe8+ Kf7 34. Rg8 g6 35. Rf8+ Ke6 36. Rxf5 gxf5 37. Kf4 b6 38. c4 a5 39. Bf8 h5 40. Bh6 Kf6 41. Bg5+ Ke6 42. Bh4 Kd6 43. Kxf5 Kc5 44. Be7+ Kd4 45. Bd8 Kc3 46. Bxb6 Kxb3 47. Bxa5 Kxc4 48. Bd8 Kd4 49. Kg5 Ke4 50. Kxh5 Kf3 51. h4 Kf4 52. Kg6 Ke5 53. h5 Ke6 54. h6 Kd7 55. h7 Kxd8 56. h8=Q+ Kc7 57. Qe5+ Kd7 58. Kf7 Kc6 59. Ke7 Kb6 60. Kd7 Kb7 61. Qc5 Ka6 62. Kc8 1/2-1/2",
    B: "1832",
  },
  {
    W: "2099",
    T: "180+0",
    P: "1. d4 Nf6 2. Bg5 c5 3. Bxf6 gxf6 4. dxc5 Na6 5. Nf3 Nxc5 6. e3 d6 7. Nc3 h5 8. Qd4 a6 9. Bc4 Bg4 10. O-O-O Bxf3 11. gxf3 Bh6 12. Kb1 Qa5 13. Rhg1 b5 14. Bd5 Rc8 15. b4 Qa3 16. bxc5 Rxc5 17. Bb3 a5 18. Ne4 Rc8 19. Nxf6+ exf6 20. Qxf6 Rc7 21. Rxd6 Re7 22. Rgd1 O-O 23. Qxh6 1-0",
    B: "2190",
  },
  {
    W: "2409",
    T: "30+0",
    P: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. h4 h6 7. Nf3 Nd7 8. h5 Bh7 9. Bd3 Bxd3 10. Qxd3 Qc7 11. Ne2 Ngf6 12. Bf4 Qb6 13. O-O-O e6 14. Nc3 Be7 15. Ne5 Nxe5 16. dxe5 Nd5 17. Ne4 O-O-O 18. Bh2 Kb8 19. Nd6 Ka8 20. Nxf7 Rd7 21. Nxh8 Rc7 22. Qg6 a6 23. Qe8+ Ka7 24. Nf7 Qc5 25. Bg1 b6 26. f4 Qb5 27. Nd6 Qc4 28. Nxc4 Nb4 29. Bxb6+ Kb7 30. Bxc7 1-0",
    B: "2280",
  },
  {
    W: "1602",
    T: "300+0",
    P: "1. e4 e5 2. Bc4 Bc5 3. Nf3 d6 4. c3 h6 5. d4 exd4 6. cxd4 Bb6 7. O-O Nf6 8. Nc3 O-O 9. h3 Ba5 10. a3 Bxc3 11. bxc3 Nxe4 12. Bb2 Nf6 13. Rc1 Nc6 14. Bd3 Re8 15. Re1 Rxe1+ 16. Qxe1 Be6 17. c4 d5 18. cxd5 Bxd5 19. Ne5 Nxe5 20. dxe5 Nd7 21. Rd1 Bc6 22. Bc4 Qg5 23. f3 Nxe5 24. Bxe5 Re8 25. h4 Qxe5 26. Qxe5 Rxe5 27. Rd8+ Re8 28. Rxe8+ Bxe8 29. Kf2 a6 30. Ke3 b5 31. Ba2 c5 32. Kd3 c4+ 33. Kc3 Bd7 34. g3 g5 35. hxg5 hxg5 36. Bb1 f6 37. Be4 Bc8 38. g4 Kf7 39. Bd5+ Ke7 40. Be4 Kd6 41. Kb4 Kc7 42. Kc5 Bb7 43. Kd4 Bxe4 44. fxe4 Kd6 45. Kc3 a5 0-1",
    B: "1599",
  },
  {
    W: "1414",
    T: "120+1",
    P: "1. e4 c6 2. d4 d6 3. Nf3 Bg4 4. Be2 Bxf3 5. Bxf3 e6 6. c4 d5 7. exd5 cxd5 8. cxd5 exd5 9. O-O Ne7 10. Re1 f6 11. Nc3 Kf7 12. Bh5+ g6 13. Bf3 Nbc6 14. a3 a6 15. Be3 b5 16. Qb3 Na5 17. Qa2 Nc4 18. a4 b4 19. b3 Nxe3 20. fxe3 bxc3 21. Rac1 Nf5 22. Rxc3 Bb4 23. Rec1 Bxc3 24. Rxc3 Re8 25. Kf2 Kg7 26. Qc2 Ne7 27. Rc5 Rc8 28. b4 Rxc5 29. bxc5 Qa5 30. Qb3 Rd8 31. h3 Qd2+ 32. Be2 Nf5 33. Qd3 Qxd3 34. Bxd3 Ne7 35. g4 a5 36. Kf3 Rb8 37. Bb5 Rc8 38. Kf4 Rc7 39. Kf3 g5 40. Kg3 Kf7 41. Kf3 Ke6 42. Kg3 Nc6 43. Kf3 Nb4 44. Ke2 h6 45. Kf3 Nc2 46. Ke2 1-0",
    B: "1501",
  },
  {
    W: "1648",
    T: "600+0",
    P: "1. c4 e5 2. Nc3 Nc6 3. e4 a6 4. Nf3 Bb4 5. a3 Ba5 6. b4 Bb6 7. b5 axb5 8. cxb5 Na5 9. a4 d6 10. Nd5 Bc5 11. d3 Bg4 12. h3 Bxf3 13. Qxf3 Bd4 14. Rb1 Ne7 15. Ba3 Nxd5 16. exd5 b6 17. Be2 Nb7 18. O-O Rxa4 19. Bb4 Bc5 20. Bxc5 Nxc5 21. Bd1 Rd4 22. Bc2 O-O 23. Qg3 Qf6 24. Rbe1 Qg6 25. Qe3 Nxd3 26. Rd1 e4 27. Qxd4 Nf4 28. Qxe4 Nxh3+ 29. Kh1 Qxe4 30. Bxe4 Nf4 31. Bc2 Re8 32. Rc1 Nxd5 33. Bb3 Nf4 34. Rxc7 Ne6 35. Rc6 Rd8 36. Rxb6 Nd4 37. Ba2 d5 38. Rd1 Nc2 39. Bxd5 Nb4 40. Bxf7+ Kxf7 41. Rxd8 1-0",
    B: "1672",
  },
  {
    W: "1521",
    T: "600+0",
    P: "1. e4 c6 2. Nf3 d5 3. Nc3 Bg4 4. Be2 Bxf3 5. Bxf3 e6 6. d4 c5 7. O-O cxd4 8. Qxd4 Nc6 9. Qa4 Bd6 10. exd5 exd5 11. Re1+ Ne7 12. Nxd5 O-O 13. Bg5 Re8 14. Rad1 h6 15. Bxe7 Nxe7 16. Nxe7+ Rxe7 17. Rxe7 Qxe7 18. Bxb7 Bxh2+ 19. Kxh2 Qxb7 20. b4 Qc7+ 21. Kg1 a5 22. Rb1 Ra7 23. Qe8+ Kh7 24. a3 Qxc2 25. Rf1 axb4 26. axb4 Qc4 27. b5 Rb7 28. Rb1 Qc5 29. Qe4+ 1-0",
    B: "1401",
  },
  {
    W: "1435",
    T: "600+0",
    P: "1. d4 g6 2. c4 Bg7 3. Nf3 Nc6 4. e3 d5 5. Bd3 e5 6. Nc3 exd4 7. cxd5 dxc3 8. dxc6 cxb2 9. cxb7 bxa1=Q 10. Bb5+ Bd7 11. Bxd7+ Qxd7 12. bxa8=Q+ Ke7 13. Ba3+ Ke6 14. Ng5+ Kf5 15. Qxa1 Bxa1 16. O-O Ne7 17. Qf3+ Kxg5 18. Rxa1 Nf5 19. Qf4+ Kh5 20. g4+ Kh4 21. gxf5+ Kh5 22. Qf3+ Kg5 23. e4 Re8 24. f6 Qc6 25. Qg3+ Kxf6 26. Bb2+ Ke6 27. Rd1 Qxe4 28. Qh3+ f5 29. Qxh7 Qg4+ 30. Kf1 Qxd1+ 31. Kg2 Qg4+ 32. Kf1 Qd1+ 33. Kg2 Qd5+ 34. Kg3 Kd6 35. Qxg6+ Re6 36. Qg5 Qd3+ 37. Kg2 Qd5+ 38. Kg3 Qd3+ 39. Kg2 Qe4+ 40. Kg3 f4+ 41. Qxf4+ Qxf4+ 42. Kxf4 Kd5 43. h4 Rh6 44. Kg5 Rh7 45. h5 c5 46. f4 Ke6 47. Kg6 Rf7 48. h6 Rxf4 49. h7 Rh4 50. h8=Q Rxh8 51. Bxh8 a5 52. Kg5 c4 53. Kg4 Kd5 54. Kf3 Kc5 55. Ke2 Kb4 0-1",
    B: "1607",
  },
  {
    W: "1600",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Ne5 5. Nc3 Nf6 6. Bd2 Bc5 7. Nf3 Neg4 8. Be3 Bxe3 9. fxe3 Nxe3 10. Qe2 Neg4 11. O-O-O O-O 12. e5 Ne8 13. Nd4 d6 14. e6 Qg5+ 15. Kb1 Ne5 16. h4 Qg6 17. e7 Bg4 18. exf8=Q+ Kxf8 19. Nf3 Nxf3 20. gxf3 Bf5 21. Bh3 Nf6 22. Bxf5 Qxf5 23. Rhg1 Re8 24. Qh2 h5 25. f4 g6 26. Rde1 Rc8 27. Rg5 Qd7 28. Nd5 Ng4 29. Qg3 c6 30. Ne3 Nh6 31. f5 Re8 32. fxg6 f6 33. Rxh5 Kg7 34. Rf1 Re5 35. Rxe5 dxe5 36. h5 a5 37. Qf2 Qd6 38. Nf5+ Nxf5 39. Qxf5 b5 40. Rh1 Kh6 41. Qf1 1-0",
    B: "1531",
  },
  {
    W: "1989",
    T: "180+0",
    P: "1. e4 c5 2. Bc4 Nc6 3. Nf3 e6 4. Nc3 g6 5. d3 Bg7 6. Bg5 f6 7. Bf4 g5 8. Be3 b6 9. Nb5 Bf8 10. g4 a6 11. Nc3 b5 12. Bb3 Bb7 13. h4 Nh6 14. hxg5 Nxg4 15. gxf6 Qxf6 16. Bg5 Qg6 17. Rg1 h5 18. Qd2 Bh6 19. Bxh6 Qxh6 20. O-O-O Nxf2 21. Qxh6 Rxh6 22. Rdf1 Ng4 23. Ng5 Nd4 24. Nf7 b4 25. Nxh6 bxc3 26. bxc3 Ne2+ 27. Kb2 Nxg1 28. Nxg4 Nh3 29. Rh1 hxg4 30. d4 Ke7 31. d5 Rh8 32. c4 g3 33. c3 g2 34. Re1 g1=Q 35. Rxg1 Nxg1 36. Bd1 exd5 37. exd5 d6 38. Bg4 Bc8 39. Bd1 a5 40. a4 Bd7 41. Kb3 Rh2 42. Ka3 Ne2 43. Bxe2 Rxe2 44. Kb3 Re3 45. Ka3 Rxc3+ 46. Kb2 Rxc4 47. Kb3 Rxa4 48. Kb2 Rb4+ 49. Ka3 a4 50. Ka2 Bc8 51. Ka1 Bb7 52. Ka2 Bxd5+ 53. Ka3 Rb3+ 54. Ka2 Rh3+ 55. Ka1 Ke6 56. Kb2 Ke5 57. Ka1 Rh2 58. Kb1 Ke4 59. Kc1 Ke3 60. Kb1 Bb3 61. Ka1 Kd3 62. Kb1 a3 63. Kc1 Rh1# 0-1",
    B: "1952",
  },
  {
    W: "1667",
    T: "60+0",
    P: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. Nf3 e6 5. c4 Nf6 6. cxd5 Nxd5 7. Nc3 Nxc3 8. bxc3 Be7 9. Be2 Nd7 10. O-O Nf6 11. Bg5 b6 12. Re1 h6 13. Bh4 Bb7 14. Bg3 O-O 15. Ne5 Ne4 16. Bf3 Nxg3 17. Bxb7 Rb8 18. fxg3 Rxb7 19. Qe2 f6 20. Nf3 e5 21. dxe5 fxe5 22. Qxe5 Bf6 23. Qe6+ Rbf7 24. Nd4 Bxd4+ 25. cxd4 Qxd4+ 26. Kh1 Qf6 27. Qxf6 Rxf6 28. Kg1 Kf7 29. Rf1 Re8 30. Rxf6+ gxf6 31. Rf1 Re2 32. Rf2 Re1+ 33. Rf1 Re2 34. Ra1 Rc2 35. h3 f5 36. Kf1 Kf6 37. a4 a5 38. Ra3 Rc4 39. Ra2 Ke5 40. Ke2 Rb4 41. Kd3 Kf6 42. Kc3 Kg5 43. Rb2 f4 44. Rxb4 axb4+ 45. Kxb4 fxg3 46. a5 1-0",
    B: "1727",
  },
  {
    W: "2040",
    T: "600+0",
    P: "1. d4 Nf6 2. Bf4 c5 3. c3 Qb6 4. b3 Nc6 5. Nf3 d5 6. e3 Nh5 7. Bg5 h6 8. Bh4 g5 9. Ne5 Nxe5 10. dxe5 Ng7 11. Bg3 Nf5 12. Bd3 Nxg3 13. fxg3 Bg7 14. O-O Bxe5 15. Qh5 Be6 16. Na3 c4 17. bxc4 Qxe3+ 18. Kh1 Qxd3 19. cxd5 Qxd5 20. Rad1 Qc6 21. Rde1 Bf6 22. Nb5 Qxb5 23. Rxf6 exf6 0-1",
    B: "1948",
  },
  {
    W: "1139",
    T: "600+0",
    P: "1. e4 d5 2. e5 d4 3. d3 Nc6 4. Nf3 Bg4 5. Na3 Nxe5 6. h3 Nxf3+ 7. gxf3 Bd7 8. Bf4 c6 9. Qd2 Nf6 10. O-O-O e6 11. Re1 Bxa3 12. bxa3 Qb6 13. Rg1 O-O 14. a4 h6 15. a5 Qb5 16. Kd1 Nd5 17. c4 Nc3+ 18. Kc2 Qa4+ 19. Kc1 Nxa2+ 20. Kb1 Qb3+ 21. Ka1 Qa3 22. Qxa2 Qxa2+ 23. Kxa2 Rfe8 24. Be5 c5 25. Rxg7+ Kh8 26. Rxf7+ Kg8 27. Rxd7 Kf8 28. Bg7+ Kg8 29. Bxh6 b6 30. a6 b5 31. cxb5 Rab8 32. Be2 Rxb5 33. Rg1+ Kh8 34. Bf4 Ra5+ 35. Kb1 Rxa6 36. Be5# 1-0",
    B: "1156",
  },
  {
    W: "1387",
    T: "600+0",
    P: "1. d3 e5 2. c3 d5 3. Bg5 f6 4. Bc1 c5 5. e3 Nc6 6. Qa4 Qa5 7. Qxa5 Nxa5 8. Nf3 Nh6 9. h3 e4 10. dxe4 dxe4 11. Nfd2 f5 12. Nc4 Nxc4 13. Bxc4 Be7 14. Bb5+ Bd7 15. Bxd7+ Kxd7 16. O-O Ke6 17. c4 Rad8 18. b3 Rd3 19. Bb2 Rg8 20. Na3 g6 21. Nb5 Bd6 22. Rad1 Rxd1 23. Rxd1 Rd8 24. Kf1 a6 25. Nc3 Be7 26. Rxd8 Bxd8 27. Nd5 b5 28. Nf4+ Kf7 29. cxb5 axb5 30. Ke2 b4 31. Be5 Bf6 32. Bd6 Ke8 33. Bxc5 Kd7 34. Bxb4 Kc6 35. Kd2 Nf7 36. a4 Nd8 37. Bc3 Be7 38. Ne2 Nb7 39. Nd4+ Kb6 40. b4 Bh4 41. g3 Be7 42. a5+ Ka6 43. Ne6 Nd6 44. Nc7+ Kb7 45. Nd5 Ka6 46. Kc2 h5 47. Nf4 g5 48. Nxh5 g4 49. h4 Nc4 50. Ng7 Na3+ 51. Kc1 Bf8 52. Nxf5 Nc4 53. h5 Kb5 54. h6 Bxh6 55. Nxh6 Ka4 56. Nxg4 Kb3 57. Ne5 Na3 58. a6 Kxc3 59. a7 Kxb4 60. a8=Q Kb3 61. Qxe4 Ka2 62. Qb1+ Nxb1 63. g4 Nc3 64. g5 Nd5 65. g6 Nf6 66. f4 Kb3 67. f5 Kc3 68. Nd7 Nxd7 69. g7 Nf6 70. e4 Ng8 71. e5 Kd4 72. e6 Ke5 73. e7 Nxe7 74. f6 Kxf6 75. g8=Q Nxg8 1/2-1/2",
    B: "1387",
  },
  {
    W: "1031",
    T: "600+0",
    P: "1. e4 Nf6 2. Nc3 Nc6 3. b3 Nb4 4. Ba3 Nc6 5. b4 Nxe4 6. Nxe4 g5 7. Nxg5 Bg7 8. Qf3 Bxa1 9. Qxf7# 1-0",
    B: "774",
  },
  {
    W: "1751",
    T: "600+0",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Bb4 4. Nf3 Bxc3+ 5. bxc3 dxc4 6. e4 b5 7. a4 c6 8. axb5 cxb5 9. g3 Bb7 10. e5 Ne7 11. Bg2 h6 12. Ba3 Nbc6 13. O-O O-O 14. Nd2 Ng6 15. Bxf8 Qxf8 16. Ne4 Nce7 17. Nf6+ gxf6 18. Bxb7 Rb8 19. Rxa7 fxe5 20. dxe5 Nxe5 21. Be4 Rd8 22. Qh5 N5g6 23. Bxg6 Nxg6 24. Qxb5 Rd6 25. Qxc4 Ne5 26. Qf4 Ng6 27. Qb4 Ne5 28. Rfa1 Nf3+ 29. Kg2 Ng5 30. Ra8 Rd8 31. Qxf8+ Rxf8 32. Rxf8+ Kxf8 33. Ra5 Ne4 34. c4 Nd6 35. c5 Nc4 36. Ra6 Ke7 37. c6 Kd8 38. Ra8+ Kc7 39. Rh8 Kxc6 40. Rxh6 Kd5 41. Rf6 Ne5 42. f4 Ng4 43. Rxf7 Ne3+ 44. Kf3 Nf5 45. Rxf5+ exf5 46. g4 fxg4+ 47. Kxg4 Ke6 48. Kg5 Kf7 49. f5 Kg7 50. f6+ Kf7 51. h4 Kg8 52. Kg6 Kf8 53. f7 Ke7 54. Kg7 Ke6 55. f8=Q 1-0",
    B: "1750",
  },
  {
    W: "1320",
    T: "600+0",
    P: "1. d4 e6 2. e4 d5 3. e5 c5 4. f4 Nc6 5. Bb5 Bd7 6. Bxc6 Bxc6 7. c3 a5 8. a3 a4 9. Nd2 c4 10. Ngf3 Be7 11. O-O f6 12. h3 Nh6 13. Qe2 O-O 14. Rf2 Nf5 15. Nf1 b5 16. N3h2 Qe8 17. Rf3 Nh4 18. Rg3 Nf5 19. Rg4 Qh5 20. Rxg7+ Nxg7 21. Qxh5 Nxh5 22. Ng4 f5 23. Nh6+ Kg7 24. g4 Kxh6 25. gxh5 Kxh5 26. Ng3+ Kh4 27. Kh2 Be8 28. Be3 Rg8 29. Bf2 Rxg3 30. Bxg3+ Kh5 31. Rg1 Bd7 32. Bf2 Bh4 33. Bxh4 Kxh4 34. Rg7 h5 35. Rxd7 Rg8 36. Rd6 Rg3 37. Rxe6 Rxh3+ 38. Kg2 Re3 39. Kf2 Rd3 40. Ke2 Rg3 41. Kf2 Kg4 42. Rd6 h4 43. Rxd5 Kxf4 44. e6 h3 45. e7 Rg2+ 46. Kf1 h2 47. Kxg2 1-0",
    B: "1266",
  },
  {
    W: "1500",
    T: "600+0",
    P: "1. e4 e6 2. Nf3 c6 3. Nc3 b5 4. a3 Bb7 5. b4 a5 6. Bb2 axb4 7. axb4 Bxb4 8. Rxa8 Bxa8 9. Bd3 Nh6 10. Qa1 Bb7 11. Ne2 Bf8 12. O-O f6 13. e5 Be7 14. exf6 Bxf6 15. Bxf6 Qxf6 16. Qxf6 gxf6 17. Ra1 Kd8 18. c4 Kc7 19. cxb5 cxb5 20. Bxb5 Rg8 21. Rc1+ Kd8 22. Nf4 Bxf3 23. Rb1 Rg4 24. g3 e5 25. Nh5 Ke7 26. h3 Rd4 27. d3 Bxh5 0-1",
    B: "1484",
  },
  {
    W: "1385",
    T: "600+0",
    P: "1. Nf3 f5 2. e3 g5 3. Bc4 g4 4. Ng5 d6 5. Bxg8 Rxg8 6. Nxh7 Rg7 7. Nxf8 Kxf8 8. O-O Nc6 9. Nc3 Ne5 10. d4 Ng6 11. e4 fxe4 12. Nxe4 g3 13. fxg3+ Ke8 14. Qe1 d5 15. Nf6+ Kf8 16. Nxd5+ Kg8 17. Bh6 Rf7 18. Rxf7 Kxf7 19. Qf2+ Ke6 20. Nf4+ Nxf4 21. Bxf4 Kd7 22. d5 c6 23. dxc6+ Kxc6 24. h3 Qh8 25. Rd1 Qxb2 26. Qf3+ Kb6 27. Be3+ Kc7 28. Bf4+ Kb6 29. Be3+ Ka6 30. Qe2+ b5 31. a4 Be6 32. axb5+ Qxb5 33. Ra1+ Kb7 34. Qxb5+ 1-0",
    B: "1357",
  },
  {
    W: "1398",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. Nc3 a6 5. Ba4 b5 6. Bb3 g6 7. O-O Bg7 8. d3 O-O 9. Bg5 d6 10. Nd5 Bb7 11. Qd2 Nd4 12. Nxd4 exd4 13. Nxf6+ Bxf6 14. Bxf6 Qxf6 15. f4 Rae8 16. Rf3 c6 17. Raf1 d5 18. e5 Qh4 19. g3 Qh3 20. a3 c5 21. c4 dxc4 22. dxc4 Bxf3 23. Rxf3 bxc4 24. Bxc4 Qh6 25. Bxa6 Rxe5 26. Rf2 Re3 27. b4 Rfe8 28. bxc5 Re1+ 29. Kg2 R1e6 30. Bb5 Rd8 31. c6 Red6 32. Qa5 Rc8 33. Qa6 Qf8 34. Rc2 Ra8 35. Qb7 d3 36. Rd2 Qe8 37. a4 Rc8 38. a5 Qe4+ 39. Kf2 Qd4+ 40. Kf1 Qa1+ 41. Kg2 Rcd8 42. c7 Rf8 43. a6 Qc3 44. Rxd3 Qc2+ 45. Kh3 Rxd3 46. Bxd3 Qxd3 47. c8=Q Rd8 1-0",
    B: "1422",
  },
  {
    W: "1674",
    T: "600+0",
    P: "1. f4 d5 2. e3 d4 3. Bb5+ c6 4. Ba4 dxe3 5. d3 Qd4 6. Nc3 Qxf4 7. Nf3 Bg4 8. O-O e6 9. Ne4 Nf6 10. c4 Nxe4 11. dxe4 Bc5 12. Qe2 Qxe4 13. Bc2 Bxf3 14. Rxf3 Qxc2 15. Qxc2 e2+ 16. Be3 Bxe3+ 17. Rxe3 e1=Q+ 18. Raxe1 O-O 19. Rh3 h6 20. Qe4 Nd7 21. b4 Nf6 22. Qe5 Ng4 23. Qf4 f5 24. Rxe6 Rad8 25. Re1 Rf7 26. Rg3 g5 27. Rxg4 fxg4 28. Qxg4 Rdf8 29. Qe6 Kg7 30. Qe5+ Kh7 31. b5 cxb5 32. cxb5 Rf5 33. Qc7+ R8f7 34. Qc2 Kg7 35. Qc3+ Kg8 36. Re8+ Kh7 37. Qh8+ Kg6 38. Qg8+ Kh5 39. g4+ Kxg4 40. Re1 h5 41. Qc8 h4 42. Re4+ Kh5 43. Qh8+ Kg6 44. Qg8+ Kh5 45. Qh8+ Kg6 46. Re6+ R5f6 47. Qg8+ Kf5 48. Rxf6+ Kxf6 49. Qd8+ Kg7 50. Qxg5+ Kf8 51. Qc5+ Re7 52. h3 b6 53. Qd6 Kf7 54. Qf4+ Kg8 55. Qxh4 Rg7+ 56. Kh2 Rf7 57. Qd8+ Kg7 58. Qd4+ Kg8 59. h4 Rf2+ 60. Qxf2 1-0",
    B: "1571",
  },
  {
    W: "1790",
    T: "600+0",
    P: "1. d4 d5 2. Nf3 Nc6 3. Bf4 Nf6 4. h3 e6 5. e3 h6 6. Bd3 Bd6 7. Bxd6 Qxd6 8. O-O O-O 9. Nbd2 e5 10. dxe5 Nxe5 11. Nxe5 Qxe5 12. c3 c5 13. Nf3 Qc7 14. Bb1 Re8 15. Qc2 b5 16. Rd1 Bb7 17. b3 Rad8 18. a4 Qc8 19. axb5 d4 20. cxd4 Bxf3 21. gxf3 Qxh3 22. Qe2 cxd4 23. Rxa7 Rd5 24. Bd3 Rh5 0-1",
    B: "1782",
  },
  {
    W: "1982",
    T: "60+0",
    P: "1. d4 b6 2. Bf4 Ba6 3. Na3 g6 4. c4 f6 5. e3 Nh6 6. Nf3 Nf7 7. Bd3 Bg7 8. O-O O-O 9. b4 Re8 10. b5 Bb7 11. Nc2 e5 12. dxe5 fxe5 13. Bg5 Nxg5 14. Nxg5 Qxg5 15. Be2 e4 16. Nd4 Qe7 17. Re1 Kh8 18. Rc1 Qf7 19. Qc2 Rf8 20. Rf1 a6 21. a4 axb5 22. axb5 c5 23. Nb3 d6 24. Nd2 Nd7 25. Nb1 Ne5 26. Nc3 Nd3 27. Bxd3 exd3 28. Qxd3 Rad8 29. e4 Qf4 30. f3 Bd4+ 31. Kh1 Be5 32. Nd5 Qxh2# 0-1",
    B: "1937",
  },
  {
    W: "1252",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 d6 4. d4 exd4 5. Nxd4 Qf6 6. Nxc6 bxc6 7. Nc3 g6 8. Bd3 Bh6 9. Bxh6 Nxh6 10. Qd2 Ng4 11. O-O Ne5 12. f4 Nxd3 13. Qxd3 O-O 14. f5 g5 15. Qg3 h6 16. h4 Ba6 17. Rf4 Rae8 18. hxg5 hxg5 19. Rg4 Qd4+ 20. Kh2 f6 21. Rxg5+ 1-0",
    B: "1339",
  },
  {
    W: "1647",
    T: "60+0",
    P: "1. e4 c5 2. Nc3 e6 3. g3 a6 4. Bg2 Qc7 5. Nge2 Nc6 6. d3 d6 7. O-O Nf6 8. Bd2 Be7 9. f4 b5 10. f5 e5 11. Nd5 Nxd5 12. exd5 Nd4 13. c4 Nxf5 14. cxb5 axb5 15. g4 Nd4 16. Nxd4 exd4 17. b4 O-O 18. bxc5 Bb7 19. c6 Ba6 20. a4 b4 21. a5 Bxd3 22. Bxb4 Bxf1 23. Bxf1 Bf6 24. Ra2 Be5 25. a6 f5 26. Qe2 fxg4 27. Qg2 Qf7 28. a7 Rxa7 29. Bxd6 Bxd6 30. Rxa7 Qxa7 31. Qxg4 d3+ 32. Kh1 Rf2 33. Qe6+ Kf8 34. Qxd6+ Qe7 35. Qb8+ 1-0",
    B: "1806",
  },
  {
    W: "2043",
    T: "60+0",
    P: "1. g3 Nf6 2. d3 e6 3. Bg2 Be7 4. Nf3 O-O 5. O-O d5 6. b3 Nbd7 7. Bb2 c5 8. Nbd2 Qc7 9. Re1 a6 10. e4 b5 11. exd5 exd5 12. c4 Re8 13. cxd5 Nxd5 14. d4 c4 15. bxc4 bxc4 16. Ne5 Nxe5 17. dxe5 Bb7 18. Rc1 Rac8 19. Nxc4 Qd8 20. Bxd5 Qxd5 21. Qxd5 Bxd5 22. Nd6 Bxd6 23. exd6 Rxe1+ 24. Rxe1 Be6 25. Rd1 Rd8 26. Ba3 Rd7 27. Bb4 Kf8 28. Ba5 Ke8 29. Bc7 g6 30. Rd4 Kf8 31. Ra4 Bc4 32. Rxc4 Kg7 33. Ra4 Kf6 34. Rxa6 Ke6 35. Kg2 Kd5 36. Ra8 h5 37. a4 Kc6 38. Rb8 f5 39. a5 Rf7 40. a6 f4 41. a7 f3+ 42. Kh3 Rf5 43. a8=Q+ Kd7 44. Rd8+ Ke6 45. Qc8+ Kd5 46. Qxf5+ gxf5 47. Rf8 Ke4 48. Kh4 Kd3 49. Rxf5 Ke2 50. Kxh5 Kxf2 51. Kg4 Kg2 52. Rxf3 Kxh2 53. Rf4 1-0",
    B: "1957",
  },
  {
    W: "1513",
    T: "60+0",
    P: "1. d4 d5 2. c4 c6 3. cxd5 e6 4. Nc3 cxd5 5. Nf3 f5 6. Bg5 Nf6 7. Bxf6 Qxf6 8. e3 Be7 9. Bd3 O-O 10. O-O Nd7 11. Nd2 Qf7 12. f4 Nf6 13. Nf3 Ne4 14. Bxe4 fxe4 15. Ne5 Qf5 16. g4 Qf6 17. Rf2 Qh4 18. Rg2 b6 19. Qf1 Ba6 20. Rg3 Bxf1 0-1",
    B: "1564",
  },
  {
    W: "1226",
    T: "60+0",
    P: "1. e4 Nf6 2. Nc3 h5 3. e5 Ng4 4. h3 Nxf2 5. Kxf2 d5 6. exd6 Be6 7. d4 Bd5 8. Nf3 Bxf3 9. Qxf3 Nc6 10. Bc4 Nxd4 11. Qxf7+ Kd7 12. Bg5 Nxc2 13. dxe7 Bxe7 14. Bxe7 Qxe7 15. Rad1+ Nd4 16. Rxd4+ Kc6 17. Qxe7 Kb6 18. Qb4+ Kc6 19. Qb5# 1-0",
    B: "1221",
  },
  {
    W: "2160",
    T: "60+0",
    P: "1. e4 c5 2. Nc3 d6 3. Bc4 Nf6 4. f4 e6 5. Nf3 Be7 6. d3 O-O 7. O-O Nc6 8. Be3 d5 9. exd5 exd5 10. Bb5 a6 11. Bxc6 bxc6 12. d4 cxd4 13. Bxd4 c5 14. Bxf6 Bxf6 15. Ne5 Bb7 16. Qg4 d4 17. Ne2 Qd5 18. Nf3 Rae8 19. Ng3 Re3 20. Nh5 Kh8 21. Nxf6 gxf6 22. Rf2 Rg8 23. Qh4 Rg6 24. Raf1 Rxf3 25. Rxf3 Qxa2 26. f5 Rg5 27. Rg3 Qd5 28. Rxg5 fxg5 29. Qxg5 Qe5 30. h3 Qg7 31. Qxg7+ Kxg7 32. f6+ Kg6 33. Re1 Kxf6 34. Ra1 Bd5 35. Rxa6+ Be6 36. Rc6 Ke5 37. Rxc5+ Ke4 38. Rh5 f5 39. Rxh7 Ke3 40. Re7 d3 41. Rxe6+ Kd2 42. Rd6 Kxc2 43. Rxd3 Kxb2 44. Rf3 Kc2 45. Rxf5 Kd2 46. Rf3 1-0",
    B: "2218",
  },
  {
    W: "1459",
    T: "60+0",
    P: "1. d4 e6 2. Bf4 d5 3. e3 c5 4. Nf3 cxd4 5. Nxd4 Nf6 6. Nc3 Ne4 7. Nxe4 dxe4 8. Be2 Bb4+ 9. c3 Ba5 10. f3 O-O 11. fxe4 Nc6 12. e5 Nxd4 13. exd4 f6 14. Qd2 fxe5 15. Bxe5 Qh4+ 16. g3 Qd8 17. O-O-O Bd7 18. Qf4 Rxf4 19. gxf4 Ba4 20. Rhg1 Bxd1 21. Rxg7+ Kf8 22. Bxd1 Rc8 23. Rxh7 Qd5 24. b3 Re8 25. Bh5 Re7 26. Rh8# 1-0",
    B: "1372",
  },
  {
    W: "2309",
    T: "60+0",
    P: "1. e4 e5 2. d4 Nc6 3. Nf3 exd4 4. Nxd4 Bc5 5. Nb3 Bb6 6. Be3 Nf6 7. Bxb6 axb6 8. Bd3 O-O 9. O-O d5 10. exd5 Nxd5 11. Nc3 Be6 12. Ne4 Ncb4 13. Bc4 c6 14. a3 Na6 15. Bd3 Nf4 16. Bxa6 bxa6 17. Qf3 Ng6 18. Rad1 Qe7 19. Rfe1 Bd5 20. Nd4 Qb7 21. Qf5 c5 22. Nf3 Be6 23. Qh5 Rad8 24. Neg5 h6 25. Nxe6 Rxd1 26. Rxd1 fxe6 27. Qxg6 1-0",
    B: "2361",
  },
  {
    W: "1846",
    T: "60+0",
    P: "1. e4 d5 2. exd5 e5 3. Nc3 c5 4. Ne4 f5 5. Ng3 Qxd5 6. Nxf5 Bxf5 7. c4 Qd8 8. Nf3 Nc6 9. d3 Bd6 10. Bg5 Nge7 11. Be2 h6 12. Bh4 g5 13. Bg3 Qc7 14. O-O O-O-O 15. a3 Ng6 16. b4 cxb4 17. axb4 e4 18. dxe4 Bxg3 19. Qc2 Bg4 20. hxg3 Bxf3 21. Bxf3 Nge5 22. Be2 Nd4 23. Qd3 Ndf3+ 24. Qxf3 Nxf3+ 25. Bxf3 g4 26. Bxg4+ Kb8 27. Bh3 h5 28. c5 h4 29. e5 hxg3 30. Rae1 gxf2+ 31. Rxf2 Rxh3 32. gxh3 Rg8+ 33. Rg2 Qc6 34. Rxg8+ Kc7 35. Rg7+ Kd8 36. e6 Ke8 37. Rg8+ Ke7 38. Rg7+ Ke8 39. Rf7 Qf3 40. Rxf3 Ke7 41. Rd3 b6 42. Rd5 bxc5 43. bxc5 1-0",
    B: "1898",
  },
  {
    W: "1739",
    T: "180+2",
    P: "1. d4 d5 2. Bf4 e6 3. e3 Nf6 4. Nf3 Be7 5. Nbd2 O-O 6. c4 b6 7. Bd3 Bb7 8. O-O Nbd7 9. Re1 dxc4 10. Nxc4 c5 11. dxc5 Nxc5 12. Nce5 Bxf3 13. gxf3 Nxd3 14. Nxd3 Nd5 15. Kh1 Bh4 16. Rg1 Nxf4 17. exf4 Bf6 18. Qe2 Rc8 19. Rad1 Qe7 20. Ne5 Rc7 21. Rd3 Rfc8 22. Qe4 Rc2 23. f5 exf5 24. Rd7 Qxe5 25. Qb7 R2c7 26. Rxc7 Qxc7 27. Qd5 Qc5 28. Qd7 Rd8 29. Qxf5 0-1",
    B: "1757",
  },
  {
    W: "1893",
    T: "30+0",
    P: "1. b3 c6 2. Bb2 d6 3. Nf3 Qa5 4. e3 Qd8 5. d4 Bd7 6. Be2 Qc8 7. O-O f6 8. c4 g5 9. Nbd2 h5 10. h4 g4 11. Nh2 f5 12. f3 e6 13. fxg4 Ne7 14. gxh5 Bh6 15. Nhf3 Bxe3+ 16. Kh1 Rxh5 17. d5 e5 18. Ng5 Rh8 19. Ndf3 e4 20. Nd4 Rxh4+ 21. Nh3 f4 22. Bg4 Bxg4 23. Rxf4 Bxh3 24. Rxh4 Bxg2+ 25. Kxg2 Qf5 26. Kh1 Qf3+ 27. Qxf3 exf3 28. Rh3 1-0",
    B: "1643",
  },
  {
    W: "1463",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nge7 4. c3 a6 5. Ba4 b5 6. Bc2 Bb7 7. d4 exd4 8. cxd4 g6 9. Nc3 Bg7 10. Be3 O-O 11. O-O Na5 12. b4 Nc4 13. Bc1 Nc6 14. Ne2 d5 15. e5 Re8 16. Nd2 Nxd2 17. Qxd2 Qe7 18. a3 Bc8 19. Qc3 Bd7 20. Ng3 Qh4 21. f4 Qe7 22. Be3 a5 23. f5 axb4 24. axb4 Nxb4 25. f6 Bxf6 26. exf6 Qd6 27. Rxa8 Rxa8 28. Bf4 Qf8 29. Bxc7 Nxc2 30. Qxc2 Rc8 31. Rc1 Qe8 32. Qc5 Qe3+ 33. Kh1 Qe6 0-1",
    B: "1440",
  },
  {
    W: "1698",
    T: "180+0",
    P: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 Nc6 5. Nf3 Nf6 6. e5 d5 7. Nxc3 dxc4 8. Qxd8+ Nxd8 9. exf6 Be6 10. fxg7 Bxg7 11. Bg5 O-O 12. O-O-O f6 13. Be3 Nf7 14. Nd4 Bg4 15. f3 Bh5 16. g4 Bg6 17. Nf5 Bxf5 18. gxf5 Bh6 19. Rdg1+ Kh8 20. Bxh6 Nxh6 21. Ne4 Nxf5 22. Rg4 Ne3 23. Rg3 f5 24. Ng5 h6 25. Rh3 Kg7 26. Ne6+ Kf6 27. Nxf8 Rxf8 28. Rxh6+ Kg5 29. Rh3 f4 30. Rg1+ Kf6 31. Rh6+ Ke5 32. Re1 Rf7 33. Rh5+ Kd4 34. h4 Ng2 35. Re2 Ne3 36. Rh6 Kd3 37. Rh2 Nf1 38. Rf2 Ng3 39. h5 Ke3 40. Rg2 Ne2+ 41. Rxe2+ Kxe2 42. Rg6 1-0",
    B: "1750",
  },
  {
    W: "1505",
    T: "300+8",
    P: "1. e3 d5 2. a3 e5 3. b3 Nc6 4. Bb2 d4 5. exd4 exd4 6. Qe2+ Be7 7. d3 Nf6 8. Nd2 Be6 9. h3 Bd6 10. g3 O-O 11. Bg2 Bd5 12. Ngf3 Re8 13. Ne4 Bxe4 14. O-O Bg6 15. Qd2 Bc5 16. b4 Bb6 17. a4 a6 18. a5 Ba7 19. c4 b6 20. axb6 cxb6 21. Nxd4 Nxd4 22. Bxa8 Qxa8 23. Bxd4 Rd8 24. Be3 Rxd3 25. Qe2 Rd8 26. Rad1 Re8 27. Rd6 Ne4 28. Rd4 Qc8 29. b5 axb5 30. cxb5 Qxh3 31. Qf3 Bb8 32. Rfd1 Bxg3 33. fxg3 Nxg3 34. Rd8 Kf8 35. Rxe8+ Kxe8 36. Bf2 Nf5 37. Re1+ Kd7 38. Qxh3 1-0",
    B: "1652",
  },
  {
    W: "2020",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nge7 5. Bg5 h6 6. Bh4 Ne5 7. Qh5 d6 8. Nc3 g6 9. Qe2 Bg7 10. f4 N5c6 11. Nf3 Bg4 12. h3 Nd4 13. Qd3 Bxf3 14. gxf3 Ne6 15. Bg3 O-O 16. O-O-O Nc5 17. Qc4 a6 18. h4 b5 19. Qe2 Bxc3 20. bxc3 Na4 21. Qd3 Nc6 22. h5 Qf6 23. Kd2 Nb2 24. Qd5 Nxd1 25. Qxc6 Nb2 26. e5 Qe6 27. Bh3 Qxa2 28. hxg6 Nc4+ 29. Kd3 dxe5 30. gxf7+ Kg7 31. Be6 Rad8+ 32. Ke4 Qxc2# 0-1",
    B: "1948",
  },
  {
    W: "1829",
    T: "60+0",
    P: "1. g3 d5 2. Bg2 c6 3. b3 Nf6 4. Bb2 g6 5. e3 Bg7 6. Nc3 Nh5 7. Qe2 O-O 8. O-O-O Nf6 9. f4 Bg4 10. Bf3 Bxf3 11. Nxf3 Ne4 12. Nxe4 Bxb2+ 13. Kxb2 dxe4 14. Ng5 e5 15. fxe5 Qxg5 16. h4 Qxe5+ 17. Kb1 Nd7 18. h5 c5 19. hxg6 fxg6 20. Qh2 Rf7 21. Rdf1 Raf8 22. Rfg1 b5 23. Qe2 c4 24. d4 exd3 25. cxd3 cxd3 26. Qxd3 Nc5 27. Qc2 Qe4 28. Qxe4 Nxe4 29. Rh4 Nc3+ 30. Kb2 b4 31. Rxb4 Ne2 32. Re1 Rf2 33. Ra4 Rc8 34. Ka3 Nc3 35. Rxa7 Rxa2+ 36. Kb4 Rxa7 37. Rc1 Rb7+ 38. Ka3 Ra8+ 39. Kb2 Ne4 40. Rc4 Nd2 41. Rd4 Nxb3 42. Kc3 Nxd4 43. exd4 Ra3+ 44. Kc4 Rd7 45. Kc5 Ra4 46. d5 Ra5+ 47. Kc6 1-0",
    B: "2077",
  },
  {
    W: "1966",
    T: "60+0",
    P: "1. d4 Nf6 2. Nf3 d5 3. Bg5 c6 4. Nbd2 e6 5. e3 Be7 6. Bd3 O-O 7. c3 h6 8. h4 Nfd7 9. Bxe7 Qxe7 10. g4 Na6 11. g5 h5 12. Ne5 Nxe5 13. dxe5 Qc7 14. Qxh5 g6 15. Qh6 Qxe5 16. h5 Qg7 17. hxg6 Qxh6 18. Rxh6 fxg6 19. f4 Kf7 20. Bxg6+ Ke7 21. O-O-O Bd7 22. Nf3 Rh8 23. Rdh1 Rhg8 24. Ne5 c5 25. Rh7+ Kf8 26. Nxd7# 1-0",
    B: "1961",
  },
  {
    W: "1943",
    T: "60+0",
    P: "1. e4 d6 2. d4 Nf6 3. f3 g6 4. Bd3 Bg7 5. Be3 O-O 6. Qd2 c6 7. h4 b5 8. g4 a5 9. h5 Nbd7 10. hxg6 fxg6 11. g5 Nh5 12. Ne2 b4 13. c3 bxc3 14. Nbxc3 c5 15. dxc5 Nxc5 16. Bxc5 dxc5 17. O-O-O Qc7 18. Kb1 c4 19. Bc2 Be6 20. Rh4 Rfd8 21. Qc1 Rab8 22. Rdh1 Qb7 23. Na4 Bd7 24. Nec3 Bxa4 25. Nxa4 Rd2 26. Qxd2 c3 27. Qd5+ Qxd5 28. exd5 cxb2 29. Bb3 Kf8 30. Re4 Ke8 31. Rhe1 Bf8 32. Re6 Kd7 33. d6 exd6 34. Rf6 Be7 35. Rf7 Re8 36. Rxh7 Nf4 37. Rh5 Nd3 38. Rd1 Nc5 39. Nxc5+ Kc6 40. Ba4+ Kxc5 41. Bxe8 a4 42. Bxg6 a3 0-1",
    B: "1832",
  },
  {
    W: "1572",
    T: "60+0",
    P: "1. e4 c5 2. Bc4 Nc6 3. Qf3 e6 4. d4 Nf6 5. d5 Nd4 6. Qd1 Nxe4 7. c3 Qf6 8. cxd4 Qxf2# 0-1",
    B: "1735",
  },
  {
    W: "1669",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 c5 5. b3 cxd4 6. Nxd4 Nc6 7. Bb2 Nxd4 8. Bxd4 Nf6 9. Bb2 Bc5 10. g3 Qb6 11. Qe2+ Ne4 12. Bg2 Bxf2+ 13. Kf1 f5 14. Bxg7 Rg8 15. Bxe4 fxe4 16. Qxf2 Qxf2+ 17. Kxf2 Rxg7 18. Nd2 Rf7+ 19. Ke3 Bg4 20. Rhf1 Rxf1 21. Rxf1 O-O-O 22. Rf7 h5 23. c4 dxc4 24. Nxc4 Bf3 25. h4 Rd3+ 26. Kf4 Bg4 27. Kxe4 Rxg3 28. Nd6+ Kb8 29. Rxb7+ Ka8 30. Rb4 Bf3+ 31. Ke3 Bh1+ 32. Kf4 Rg4+ 33. Kf5 Rxb4 34. Ne8 Rb6 35. Nc7+ Kb7 36. Kg5 Kxc7 37. Kxh5 Rb5+ 38. Kg6 Rb4 39. h5 Rh4 40. h6 Rxh6+ 41. Kxh6 Bd5 42. Kg5 Bxb3 43. axb3 Kb6 44. Kf4 Kb5 45. Ke3 Kb4 46. Kd2 Kxb3 47. Kc1 a5 48. Kb1 a4 49. Ka1 a3 50. Kb1 Ka4 51. Ka1 Kb4 0-1",
    B: "1775",
  },
  {
    W: "2021",
    T: "60+0",
    P: "1. c4 d5 2. cxd5 Qxd5 3. Nc3 Qd8 4. d4 Nf6 5. Nf3 g6 6. g3 Bg7 7. Bg2 O-O 8. e3 b6 9. O-O Bb7 10. a3 Nbd7 11. b4 Re8 12. Bb2 e5 13. dxe5 Nxe5 14. Nxe5 Bxg2 15. Qxd8 Raxd8 16. Kxg2 Rxe5 17. Ne2 Ree8 18. Nd4 c5 19. bxc5 bxc5 20. Nb3 c4 21. Nd4 Ne4 22. Rac1 c3 23. Bxc3 Nxc3 24. Rxc3 Bxd4 25. exd4 Rxd4 26. Rc7 Ra4 27. Ra1 Re2 28. Rc3 Ra6 29. Kf3 Rb2 30. Rd3 Rf6+ 31. Ke3 Rfxf2 32. Rd8+ Kg7 33. Rd7 Rbe2+ 34. Kd3 a5 35. Ra7 Rd2+ 36. Kc3 Rb2 37. Rxa5 Rbc2+ 38. Kd3 Rxh2 39. Rf1 Rcd2+ 40. Kc3 Rhg2 41. Ra7 Rc2+ 42. Kb3 Rgf2 43. Re1 Rb2+ 44. Kc3 Rfc2+ 45. Kd3 Rd2+ 46. Kc3 Rf2 47. Ree7 Rbe2 48. Red7 Kh6 49. Rd3 Rc2+ 50. Kb3 f5 51. g4 Kg5 52. gxf5 gxf5 53. Rg3+ Kf4 54. Rgg7 Rb2+ 0-1",
    B: "2023",
  },
  {
    W: "2028",
    T: "60+0",
    P: "1. e4 e6 2. Nc3 d5 3. exd5 exd5 4. d4 Nf6 5. Nf3 Bd6 6. Bd3 c6 7. O-O O-O 8. h3 h6 9. Ne5 Be6 10. Re1 Nbd7 11. Nxd7 Qxd7 12. Ne2 Rfe8 13. c3 Bxh3 14. Bf4 Bxf4 15. Nxf4 Bg4 16. Qd2 Ne4 17. Bxe4 dxe4 18. Qe3 Qf5 19. Qg3 Bh5 20. Nxh5 Qxh5 21. Re3 Re6 22. Rae1 Rg6 23. Qf4 f5 24. Rh3 Qg5 25. Qxg5 Rxg5 26. Ree3 Kh7 27. Reg3 Rf8 28. Rxg5 g6 29. Rgg3 Kg7 30. Re3 f4 31. Re1 f3 32. gxf3 exf3 33. Re3 Rf5 34. Rexf3 Rxf3 35. Rxf3 g5 36. Re3 Kg6 37. Kg2 Kh5 38. Kg3 g4 39. Kg2 Kg5 40. Re5+ Kf6 41. Re8 c5 42. Rb8 cxd4 43. Rxb7 dxc3 0-1",
    B: "1954",
  },
  {
    W: "2019",
    T: "60+0",
    P: "1. c4 c6 2. e3 d5 3. Ne2 Nf6 4. cxd5 cxd5 5. Ng3 Nc6 6. Be2 e6 7. O-O Bd6 8. Nc3 Bxg3 9. fxg3 Ne5 10. Kh1 Bd7 11. d4 Nc6 12. Bf3 O-O 13. e4 dxe4 14. Nxe4 Nxe4 15. Bxe4 Ne7 16. Qh5 Ng6 17. Bxg6 hxg6 18. Qg4 Qa5 19. h4 Qh5 20. Qxh5 gxh5 21. Bf4 Rac8 22. Bd6 Rfe8 23. Rac1 Bc6 24. Be5 Bd5 25. b3 b6 26. Rf2 f5 27. Rcf1 Rc6 28. Rf4 Rec8 29. Kh2 Rc2 30. R1f2 b5 31. Kg1 Rc1+ 32. Kh2 R8c2 33. b4 a6 34. a3 Rxf2 35. Rxf2 Rc3 36. Kg1 Rxa3 37. Rc2 Ra1+ 38. Kf2 Ra2 39. Rxa2 Bxa2 40. Ke3 Bd5 41. Kf2 a5 42. bxa5 b4 43. Bf4 b3 44. Bc1 Kf7 45. Bb2 Kf6 46. Kg1 g5 47. Kf2 gxh4 48. gxh4 f4 49. g3 Kf5 50. gxf4 Kxf4 51. Bc1+ Kg4 52. Bb2 Kxh4 53. Bc1 Kg4 54. Bd2 h4 55. Bc3 h3 56. Bb2 Kf5 57. Kg1 Ke4 58. Kh2 Kd3 59. Bc1 Kc2 60. Bb2 Kxb2 61. Kxh3 Kc2 62. Kg3 b2 63. Kf4 b1=Q 64. Kg5 Qd1 65. Kf6 Qxd4+ 0-1",
    B: "2043",
  },
  {
    W: "2249",
    T: "60+0",
    P: "1. b3 Nf6 2. Bb2 g6 3. h4 h5 4. f3 Bg7 5. g4 hxg4 6. fxg4 d6 7. g5 Nh5 8. e3 Bxb2 9. Nc3 Bxc3 10. dxc3 e5 11. Be2 Nc6 12. Bxh5 Rxh5 13. Nf3 Bg4 14. Kf2 e4 15. Qd5 Bxf3 16. Rh2 Ne5 17. Qxb7 Rb8 18. Qxa7 Ng4+ 19. Kg3 Nxh2 20. Kxh2 d5 21. Kg3 Qd6+ 22. Kf2 Rd8 23. Qa4+ c6 24. Rh1 Qe5 25. Qxc6+ Kf8 26. Qc5+ Kg7 27. b4 Qf5 28. Qd4+ Kh7 29. b5 Bxh1+ 30. Ke1 Rxh4 31. Kd2 Bf3 32. Kc1 Rh1+ 33. Kb2 Qd7 34. a4 Rb8 35. Qe5 Rxb5+ 36. axb5 Qxb5+ 37. Ka2 Qc4+ 38. Kb2 Qb5+ 39. Ka2 Qa4+ 40. Kb2 Bd1 41. Qxd5 Qxc2+ 42. Ka3 Qb3+ 43. Qxb3 Bxb3 44. Kxb3 Re1 45. Kc4 Rxe3 46. Kd4 Rd3+ 47. Kxe4 Rxc3 48. Ke5 Rc5+ 49. Kf6 Rf5+ 50. Ke7 Rxg5 51. Kf8 Rf5 52. Ke7 g5 0-1",
    B: "2324",
  },
  {
    W: "1722",
    T: "60+0",
    P: "1. e4 e5 2. f4 d6 3. fxe5 dxe5 4. Nf3 Qe7 5. Nc3 Be6 6. Bb5+ Nd7 7. Ba4 O-O-O 8. Bb3 Kb8 9. d3 Nb6 10. Be3 Qb4 11. Bxe6 fxe6 12. O-O Bc5 13. Bxc5 Qxc5+ 14. Kh1 Ne7 15. Qe2 Nc6 16. a3 Na5 17. Ng5 Rhf8 18. Nxe6 Rxf1+ 19. Rxf1 Qf8 20. Nxd8 Qxd8 21. Qh5 a6 22. Qxe5 Qg5 23. Qxg5 h6 24. Qxg7 Ka7 25. Qe5 Nd7 26. Qd4+ Nb6 27. Na4 c5 28. Nxc5 Nb3 29. Nxb3 Kb8 30. a4 Ka7 31. a5 h5 32. Qxb6+ Kb8 33. Nc5 Ka8 34. Qxb7# 1-0",
    B: "1579",
  },
  {
    W: "1942",
    T: "60+0",
    P: "1. e4 d5 2. d4 dxe4 3. Nc3 f5 4. f3 Nf6 5. fxe4 fxe4 6. Bc4 e6 7. Nge2 Bb4 8. O-O Bxc3 9. bxc3 c6 10. Bg5 Nbd7 11. Nf4 Qe7 12. Nxe6 Nb6 13. Nxg7+ Qxg7 14. Bxf6 Qh6 15. Be2 Rg8 16. Bh5+ Kd7 17. Bg4+ Rxg4 18. Be5 Ke7 19. Qxg4 Bxg4 20. Rf7+ 1-0",
    B: "1951",
  },
  {
    W: "1901",
    T: "60+0",
    P: "1. e4 d6 2. Nf3 e5 3. d4 exd4 4. Nxd4 Nf6 5. Nf3 Be7 6. Bd3 O-O 7. c3 Nc6 8. Bc2 Re8 9. Qd3 g6 10. O-O Bf8 11. Re1 Bg7 12. Qd1 Qe7 13. Be3 Ne5 14. Nxe5 dxe5 15. Nd2 Be6 16. Bb3 Rad8 17. Bxe6 Qxe6 18. Qa4 a6 19. Qb4 Ng4 20. Nf3 Qf6 21. Qxb7 h5 22. Qxc7 h4 23. h3 Nh6 24. Qc4 Kh7 25. Bg5 Qe6 26. Bxd8 Qxc4 27. Bxh4 Qb5 28. Rab1 Qa5 29. a3 Rb8 30. b4 Qb6 31. a4 Ng8 32. a5 Qb7 33. Rbd1 Nf6 34. Bg3 Nh5 35. Bxe5 Bxe5 36. Nxe5 Qe7 37. Nc6 Qg5 38. Nxb8 Nf4 39. g3 Nxh3+ 40. Kg2 Nf4+ 41. Kf3 Nh5 42. Kg2 Qf6 43. f4 Qxc3 44. e5 Qb2+ 45. Kh3 Qxb4 46. Kg4 Qxa5 47. Nc6 Qb6 48. Ne7 Qb8 49. Nxg6 Qc8+ 50. f5 fxg6 0-1",
    B: "2143",
  },
  {
    W: "1166",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. Nxe5 Qd4 6. Qh5 Qxe4+ 7. Kd1 g6 8. Qe2 Qxg2 9. Nxg6+ Be6 10. Nxh8 Qxh1+ 11. Qe1 Qxe1+ 12. Kxe1 O-O-O 13. b3 Nf6 14. Bb2 Bg7 15. Bxf6 Bxf6 16. Nxf7 Bxf7 17. Nc3 Bg6 18. d3 Bxc3+ 19. Ke2 Bxa1 20. a3 Bc3 0-1",
    B: "1256",
  },
  {
    W: "2190",
    T: "180+0",
    P: "1. b3 d6 2. Bb2 e5 3. e3 f5 4. c4 Nf6 5. Nf3 g6 6. d4 e4 7. Nfd2 Bg7 8. Nc3 O-O 9. g3 c6 10. Be2 Be6 11. h4 Nbd7 12. O-O a5 13. a3 Qb6 14. Na4 Qc7 15. b4 d5 16. Nc5 Bf7 17. Nxd7 Nxd7 18. cxd5 Bxd5 19. Rc1 Qd6 20. Bc3 a4 21. Nc4 Qe6 22. Bd2 h6 23. Na5 b6 24. Nc4 g5 25. hxg5 hxg5 26. Kg2 Rab8 27. Rh1 Nf6 28. Qf1 f4 29. gxf4 gxf4 30. exf4 e3+ 31. Kg1 exd2 32. Nxd2 Bxh1 33. Kxh1 Nd5 34. b5 Qh6+ 35. Kg1 Nxf4 36. Bc4+ Kh8 37. Nf3 Bf6 38. Qd1 Rg8+ 39. Kf1 Qh1+ 40. Ng1 Qxg1# 0-1",
    B: "2405",
  },
  {
    W: "1744",
    T: "120+8",
    P: "1. e4 c6 2. Bc4 d5 3. exd5 cxd5 4. Bb3 Nc6 5. a3 Nf6 6. d3 Bg4 7. f3 Bf5 8. g4 Bg6 9. h4 h6 10. h5 Bh7 11. Nc3 e6 12. Bf4 Qd6 13. Bxd6 Bxd6 14. Nb5 Bg3+ 15. Kf1 a6 16. Kg2 Bb8 17. Nc3 Bd6 18. Ba4 b5 19. Nxb5 axb5 20. Bxb5 Rc8 21. c4 O-O 22. cxd5 Nxd5 23. Bxc6 Ne3+ 24. Kh3 Nxd1 25. Rxd1 Rxc6 26. Ne2 Rc2 27. Nd4 Rxb2 28. Rhf1 Bxa3 29. f4 Rd8 30. Nc6 Rxd3+ 31. Rxd3 Bxd3 32. Rd1 Rb3 33. Kh4 Bc5 34. g5 Bf2+ 0-1",
    B: "2135",
  },
  {
    W: "1660",
    T: "300+4",
    P: "1. e4 c5 2. d4 cxd4 3. Nf3 a6 4. Nxd4 Nf6 5. e5 Qa5+ 6. Nc3 Qxe5+ 7. Be2 b5 8. O-O Bb7 9. Re1 Qc7 10. Bf3 Bxf3 11. Nxf3 e6 12. Bg5 Be7 13. Bxf6 Bxf6 14. Nd5 Qd8 15. Nd4 O-O 16. Nxf6+ Qxf6 17. c3 d5 18. Re3 Nd7 19. Rf3 Qe7 20. Qe2 Nf6 21. Re1 Qc7 22. Rg3 Qc4 23. Qf3 Qxa2 24. Qxf6 Qxb2 25. Qxg7# 1-0",
    B: "1681",
  },
  {
    W: "2036",
    T: "30+0",
    P: "1. b3 d5 2. d4 Nf6 3. Bb2 e6 4. Nc3 Be7 5. e3 c5 6. Nf3 cxd4 7. Nxd4 Nc6 8. Nxc6 bxc6 9. Qd2 Qb6 10. O-O-O a5 11. Na4 Qb4 12. Bc3 Qa3+ 13. Bb2 Qb4 14. Qe2 c5 15. Bc3 Qa3+ 16. Kb1 Bd7 17. Nb6 Ra6 18. Nxd7 Ra7 19. Nxf6+ gxf6 20. Qb5+ Kf8 21. g4 a4 22. g5 axb3 23. cxb3 Qxa2+ 24. Kc1 Ra3 25. Bc4 dxc4 26. Rd8+ Kg7 27. Rd7 Kg6 28. gxf6 Bxf6 29. Rg1+ Kf5 30. Rxf7 Ke4 31. Qc6+ Kd3 32. Qd7+ 1-0",
    B: "2077",
  },
  {
    W: "1765",
    T: "60+0",
    P: "1. e4 e5 2. Be2 Nc6 3. Nf3 d5 4. Bd3 dxe4 5. Bxe4 Nf6 6. d3 Nxe4 7. dxe4 Bd6 8. Bg5 Be6 9. Bxd8 1-0",
    B: "1739",
  },
  {
    W: "1939",
    T: "300+0",
    P: "1. e4 c5 2. Bc4 e6 3. c3 Nc6 4. d4 cxd4 5. cxd4 d5 6. exd5 exd5 7. Bb3 Nf6 8. Ne2 Bd6 9. Bg5 O-O 10. O-O h6 11. Bh4 g5 12. Bg3 Na5 13. Nbc3 Nxb3 14. axb3 Bg4 15. Qd2 a6 16. Na4 Re8 17. Nec3 Rc8 18. Qd3 b5 19. Nc5 Bxc5 20. dxc5 Rxc5 21. Rxa6 b4 22. Na4 Rc6 23. Rxc6 Be2 24. Qb1 Bxf1 25. Qxf1 Ne4 26. Rc1 Qa8 27. Qb5 Qa7 28. Rd1 Rb8 29. Qxd5 Nxg3 30. hxg3 Re8 31. Qd6 Re2 32. Rf1 Re4 33. Nc5 Re2 34. Nd7 Rxb2 35. Qf8+ Kh7 36. Qxf7+ Kh8 37. Qf8+ Kh7 38. Nf6+ Kg6 39. Ng8 Qd4 40. Qxh6+ Kf5 41. Ne7+ Ke4 42. Re1+ Kd3 43. Qg6+ Kc3 44. Re3+ Kd2 45. Qxg5 Kc2 46. Qf5+ Kd2 47. Qf3 Rb1+ 48. Kh2 Qh8+ 49. Qh5 Qxh5# 0-1",
    B: "2162",
  },
  {
    W: "1302",
    T: "180+0",
    P: "1. e4 c5 2. f4 e6 3. Nf3 Nf6 4. Nc3 Nc6 5. Bc4 a6 6. O-O b5 7. Bb3 c4 8. Nxb5 axb5 9. Bxc4 bxc4 10. c3 Qb6+ 11. d4 Bb7 12. Kh1 Be7 13. f5 Nxe4 14. fxe6 dxe6 15. Ne5 Nxe5 16. dxe5 O-O 17. Qg4 h6 18. Bxh6 g6 19. Rae1 Nf2+ 20. Rxf2 Qxf2 21. Re2 Qf1# 0-1",
    B: "1301",
  },
  {
    W: "1727",
    T: "180+0",
    P: "1. Nf3 e5 2. Nxe5 d6 3. Nf3 Bf5 4. Nc3 Nf6 5. g3 Nc6 6. d3 Be7 7. e4 Bg4 8. Bg2 Qd7 9. h3 Be6 10. d4 h6 11. d5 g5 12. dxe6 Qxe6 13. O-O g4 14. hxg4 h5 15. g5 Ng4 16. Qd5 Qd7 17. Nd4 Nxd4 18. Qxd4 O-O-O 19. Qb4 h4 20. e5 hxg3 21. Qxb7# 1-0",
    B: "1726",
  },
  {
    W: "909",
    T: "180+0",
    P: "1. e4 d5 2. d4 dxe4 3. f4 exf3 4. gxf3 Nc6 5. Be3 Bf5 6. c4 Nb4 7. Qa4+ b5 8. Qxb5+ c6 9. Qxb4 Bxb1 10. Rxb1 e5 11. Rd1 Qh4+ 12. Bf2 Bxb4+ 13. Ke2 Qf6 14. d5 cxd5 15. cxd5 O-O-O 16. Nh3 Ne7 17. Bg3 Nxd5 18. Bxe5 Qxe5+ 19. Kf2 Qxb2+ 20. Be2 Nf4 21. Nxf4 Bc5+ 22. Kg2 Qf6 23. Rd5 Qxf4 24. Rxc5+ Kb8 25. Ba6 Ka8 26. Rb5 Rb8 27. Rxb8+ Qxb8 28. Rc1 Qb2+ 29. Kg3 Qxc1 30. Bb5 Qc7+ 31. Kh4 Rd8 32. Kg5 Qa5 33. Kf5 Qxb5+ 34. Ke4 Qb6 35. Kf4 Qb4+ 36. Ke5 Qb5+ 37. Ke4 Qa4+ 38. Kf5 Qa5+ 39. Kf4 Qxa2 40. Kg3 Qa4 0-1",
    B: "895",
  },
  {
    W: "1841",
    T: "180+0",
    P: "1. e4 c5 2. Nc3 e6 3. g3 a6 4. Bg2 Nc6 5. Nge2 Be7 6. d3 Nf6 7. O-O O-O 8. Be3 h6 9. Qd2 d5 10. exd5 exd5 11. d4 Bg4 12. h3 cxd4 13. Nxd4 Bd7 14. Rfe1 Bb4 15. a3 Bxc3 16. Qxc3 Ne4 17. Qd3 Re8 18. Re2 Bf5 19. Qd1 Qf6 20. Nxc6 bxc6 21. Qd4 Qg6 22. Bf4 Rad8 23. Rae1 Qh5 24. g4 Bxg4 25. hxg4 Qxg4 26. Bg3 h5 27. Bxe4 dxe4 28. Qc4 h4 29. Rxe4 Rxe4 30. Rxe4 Rd1+ 31. Kg2 h3+ 32. Kh2 Qf3 33. Re8+ Kh7 34. Qh4+ Kg6 35. Bf4 Rh1# 0-1",
    B: "1731",
  },
  {
    W: "1696",
    T: "180+0",
    P: "1. d4 e5 2. dxe5 Bc5 3. e3 f6 4. exf6 Nxf6 5. Bc4 d6 6. Nf3 a6 7. b3 b5 8. Bd3 O-O 9. O-O Nc6 10. Ng5 h6 11. Qf3 hxg5 12. Qxc6 Bd7 13. Qf3 g4 14. Qg3 Nh5 15. Bb2 Nxg3 16. hxg3 Qg5 17. Rd1 Bc6 18. Nd2 Qd5 19. Be4 Qg5 20. Bxc6 Rad8 21. Ne4 Qh6 22. Rd5 Bxe3 23. Rg5 Bxg5 24. Bd5+ Kh8 25. Re1 Bf6 26. Bc1 Rde8 27. Bxh6 gxh6 28. Rd1 Bd4 29. Rxd4 Re5 30. Bc6 Rf7 31. a4 Rfe7 32. Nf6 Re1+ 33. Kh2 bxa4 34. bxa4 Kg7 35. Nxg4 h5 36. Ne3 Kf6 37. Rf4+ Kg5 38. Rf5+ Kg6 39. Rf4 R7xe3 40. fxe3 Rxe3 41. Be4+ Kg5 42. Rf5+ Kg4 43. Rf8 Rxg3 44. Rg8+ Kf4 45. Rxg3 Kxe4 46. c4 Kd4 47. Rg5 Kxc4 48. Rxh5 d5 49. Rh7 d4 50. Rxc7+ Kd3 51. Rd7 Ke4 52. g4 d3 53. Kh3 Ke3 54. g5 Kf4 55. g6 Ke3 56. Rxd3+ Kxd3 57. g7 Kc4 58. g8=Q+ Kb4 59. Qd8 Kxa4 60. Qc8 Ka5 61. Qc5+ Ka4 62. Kg3 a5 63. Kf3 1-0",
    B: "1709",
  },
  {
    W: "2130",
    T: "180+0",
    P: "1. e4 Nc6 2. d4 e6 3. a3 d5 4. e5 Bd7 5. f4 Nce7 6. Nf3 Ng6 7. Be3 N8e7 8. Nbd2 Nf5 9. Bf2 c6 10. g4 Nfh4 11. Nxh4 Nxh4 12. Qe2 Be7 13. O-O-O Qb6 14. Bh3 Ng6 15. f5 exf5 16. gxf5 Nf8 17. e6 fxe6 18. fxe6 Bc8 19. Rdf1 Ng6 20. Bg3 Rf8 21. Rxf8+ Nxf8 22. Qh5+ g6 23. Qe2 Qxd4 24. Rf1 b5 25. c3 Qg7 26. Nb3 Qh6+ 27. Kb1 Qxh3 28. Nd4 Bb7 29. Rf7 Qh5 30. Qe3 Qd1+ 31. Ka2 a5 32. Qe5 Rc8 33. Rxe7+ Kxe7 34. Bh4+ Ke8 35. Qg7 Qf1 36. Qe7# 1-0",
    B: "2158",
  },
  {
    W: "2075",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 Nf6 4. Qe2 Nc6 5. c3 Bg4 6. Qe3 Ne5 7. Nxe5 dxe5 8. Qg3 Bd7 9. Qxe5 e6 10. Qf4 b5 11. Bd3 c4 12. Bc2 Bc6 13. O-O Bd6 14. e5 Nd5 15. Qg3 Bc5 16. Qxg7 Ke7 17. d4 cxd3 18. Bg5+ Kd7 19. Bxd8 dxc2 20. Qxh8 Rxd8 21. Qxh7 cxb1=Q 22. Raxb1 Nf4 23. Qxf7+ 1-0",
    B: "1952",
  },
  {
    W: "1408",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 h6 4. O-O Nf6 5. Nc3 Be7 6. d3 c6 7. Be3 Nbd7 8. d4 Nf8 9. dxe5 dxe5 10. Qxd8+ Bxd8 11. Nxe5 Ne6 12. Bxe6 Bxe6 13. Rad1 O-O 14. Bc5 Re8 15. f4 Bc7 16. b3 b6 17. Bb4 c5 18. Ba3 Nh5 19. f5 Bc8 20. Nf3 Bb7 21. Rde1 Nf4 22. g3 Nh3+ 23. Kg2 Ng5 24. Nxg5 hxg5 25. h3 Rad8 26. b4 Rd2+ 27. Kf3 Rxc2 28. bxc5 Rxc3+ 29. Kg4 Rxa3 30. Kxg5 bxc5 31. Rc1 Bb6 32. Rfd1 Rxa2 33. Rb1 Bxe4 34. Re1 c4 35. Rbc1 Ra4 36. Kf4 Bc7+ 37. Ke3 Bxf5+ 38. Kf3 Rxe1 39. Rxe1 c3 40. g4 Be6 41. Ke3 c2 42. Kd2 Rc4 43. Rc1 Rc6 44. Rxc2 Rxc2+ 45. Kxc2 Bf4 46. Kd3 a5 47. Kc3 Bd6 48. Kb2 a4 49. Ka1 a3 50. Kb1 a2+ 51. Ka1 Be5# 0-1",
    B: "1327",
  },
  {
    W: "1841",
    T: "180+0",
    P: "1. d3 g6 2. f4 Bg7 3. c3 e5 4. fxe5 Bxe5 5. Nf3 Bg7 6. Be3 c6 7. Nbd2 d5 8. g3 Qa5 9. Bg2 Nf6 10. Bf2 O-O 11. O-O Ng4 12. d4 Qb6 13. Qc2 Nxf2 14. Rxf2 Re8 15. Raf1 Bf5 16. e4 dxe4 17. Nh4 e3 18. Nxf5 exf2+ 19. Rxf2 Re1+ 20. Nf1 gxf5 21. Rxf5 Nd7 22. Qf2 Rxf1+ 23. Qxf1 f6 24. Bh3 Qxb2 25. Rf2 Qxc3 26. Bxd7 Qxd4 27. Be6+ Kh8 28. Kh1 Qe4+ 29. Qg2 Qxe6 30. h4 Rd8 31. Kh2 f5 32. Rb2 b6 33. Re2 Qd6 34. Qf3 Rf8 35. h5 f4 36. h6 Qxh6+ 37. Kg2 fxg3 38. Qxg3 Rg8 39. Kf1 Qc1+ 40. Kf2 Rf8+ 41. Kg2 Rg8 42. Kh2 1-0",
    B: "1722",
  },
  {
    W: "2540",
    T: "180+0",
    P: "1. e4 c5 2. Ne2 Nc6 3. g3 g6 4. Bg2 Bg7 5. O-O Nh6 6. c3 O-O 7. d4 cxd4 8. cxd4 f6 9. h3 Nf7 10. Nbc3 e5 11. d5 Ne7 12. d6 Nc6 13. Be3 b6 14. Nb5 Ba6 15. Nc7 Bxe2 16. Qxe2 Rc8 17. Rfd1 Nxd6 18. Rxd6 Qxc7 19. Rad1 Nd4 20. R6xd4 exd4 21. Bxd4 d6 22. Bc3 f5 23. exf5 Bxc3 24. bxc3 Rxf5 25. Qe6+ Kg7 26. Rxd6 Qxc3 27. Rd7+ Kh6 28. Qe7 Qh8 29. g4 Rg5 30. f4 1-0",
    B: "2442",
  },
  {
    W: "1877",
    T: "180+0",
    P: "1. d4 Nf6 2. Nf3 e6 3. e4 Nxe4 4. Bf4 d5 5. Bd3 Nf6 6. c4 dxc4 7. Bxc4 Bd6 8. Qd2 Bxf4 9. Qxf4 Qd6 10. Qxd6 cxd6 11. Nc3 O-O 12. O-O d5 13. Bd3 b6 14. Ne5 Ba6 15. Bxa6 Nxa6 16. a3 Rac8 17. Rac1 Nb8 18. b4 Nbd7 19. b5 Nxe5 20. dxe5 Nd7 21. f4 Rc5 22. Ne2 Rfc8 23. Rxc5 Rxc5 24. Nd4 Rc4 25. Rd1 Nc5 26. Nc6 Ra4 27. Ra1 h5 28. h3 a6 29. bxa6 Rxa6 30. Kh2 Ra4 31. Kg3 Kf8 32. Kf3 Ke8 33. g3 Kd7 34. Nb8+ Kc7 0-1",
    B: "1890",
  },
  {
    W: "1237",
    T: "180+0",
    P: "1. e4 e5 2. c3 d6 3. d4 Nf6 4. f3 Nc6 5. d5 Ne7 6. c4 g6 7. Bg5 Nh5 8. g3 f6 9. Be3 g5 10. g4 Ng7 11. h4 gxh4 12. Rxh4 h5 13. Rh1 Ng6 14. Nc3 Nf4 15. Bxf4 exf4 16. Qa4+ c6 17. dxc6 bxc6 18. Bd3 Bd7 19. Nge2 d5 20. exd5 cxd5 21. Qa5 dxc4 22. Bxc4 Qxa5 23. O-O-O O-O-O 24. Nxf4 Bd6 25. Rxd6 f5 26. Nfd5 fxg4 27. fxg4 hxg4 28. Rxh8 Rxh8 29. a3 Nf5 30. Ba6+ Kd8 31. Rf6 g3 32. Bf1 Qxd5 33. Nxd5 Ne3 34. Nxe3 Rh1 35. Rf8+ Be8 1-0",
    B: "1057",
  },
  {
    W: "1431",
    T: "180+0",
    P: "1. e4 e6 2. d4 h6 3. c4 a6 4. Nc3 d6 5. g3 Nc6 6. Bg2 Nf6 7. d5 exd5 8. exd5 Ne7 9. Nf3 c6 10. dxc6 Nxc6 11. O-O Bg4 12. Re1+ Be7 13. Nd5 Nxd5 14. Qxd5 O-O 15. Nh4 Bxh4 16. gxh4 Qxh4 17. Re4 Kh8 18. Bf3 f5 19. Rf4 Ne7 20. Qd4 Qh3 21. Bxg4 fxg4 22. Rxg4 Nf5 23. Qe4 Rae8 24. Qg2 Re1+ 0-1",
    B: "1434",
  },
  {
    W: "1529",
    T: "180+0",
    P: "1. d4 d5 2. c4 dxc4 3. e4 Nc6 4. d5 Ne5 5. f4 Nd3+ 6. Bxd3 cxd3 7. Qxd3 Bg4 8. h3 Bh5 9. g4 Bg6 10. f5 Bxf5 11. gxf5 Qd6 12. Ne2 O-O-O 13. Bd2 Qb4 14. Bxb4 1-0",
    B: "1472",
  },
  {
    W: "1624",
    T: "120+1",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 e5 7. Nf3 Be7 8. Qd2 O-O 9. h4 b5 10. a3 Bb7 11. h5 Nbd7 12. Bd3 Nb6 13. h6 g6 14. Ng5 d5 15. exd5 Nbxd5 16. Nxd5 Nxd5 17. Ne4 f5 18. Nc5 Bxc5 19. Bxc5 Re8 20. O-O-O e4 21. Be2 Nf6 22. Qg5 1-0",
    B: "1606",
  },
  {
    W: "2186",
    T: "120+1",
    P: "1. e4 d5 2. Ne2 c5 3. exd5 Qxd5 4. Nbc3 Qd8 5. d3 Nf6 6. Be3 b6 7. h3 Bb7 8. Qd2 e5 9. O-O-O Nbd7 10. f4 Bd6 11. fxe5 Bxe5 12. Bf4 O-O 13. Bxe5 Nxe5 14. Nf4 Nc6 15. Rg1 Nd4 16. Be2 Re8 17. Rde1 Qd7 18. g4 h6 19. g5 hxg5 20. Rxg5 Kf8 21. Reg1 g6 22. Nxg6+ fxg6 23. Rxg6 Nxe2+ 24. Nxe2 Qf7 25. Qh6+ Ke7 26. Rg7 Rh8 27. Rxf7+ Kxf7 28. Qg7+ Ke6 29. Qxb7 1-0",
    B: "2155",
  },
  {
    W: "1728",
    T: "120+1",
    P: "1. e4 d5 2. exd5 Nf6 3. Nf3 c6 4. dxc6 Nxc6 5. Bb5 a6 6. Ba4 Bg4 7. O-O e5 8. h3 Bxf3 9. Qxf3 e4 10. Re1 Be7 11. Bxc6+ bxc6 12. Qc3 Qd6 13. d3 Nd5 14. Qa3 exd3 15. Qxd6 1-0",
    B: "1705",
  },
  {
    W: "1283",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nc3 Nc6 4. d3 Bc5 5. a4 Ng4 6. d4 exd4 7. Nxd4 Nxd4 8. Qxd4 Bxd4 0-1",
    B: "1280",
  },
  {
    W: "988",
    T: "120+1",
    P: "1. d4 e5 2. c3 e4 3. d5 d6 4. e3 g6 5. Qd4 Qf6 6. Qxe4+ Be7 7. Bb5+ c6 8. Bc4 cxd5 9. Bxd5 Nh6 10. Bxb7 Bxb7 11. Qxb7 O-O 12. Qxa8 Na6 13. Qxa7 Nc5 14. b4 Nd3+ 15. Kd2 Nxc1 16. Kxc1 Qxf2 17. Nf3 Qxg2 18. Rg1 Qxf3 19. Nd2 Bf6 20. Nxf3 Bxc3 21. Rb1 Nf5 22. Rb3 Bg7 23. b5 Rc8+ 24. Kd2 Bh6 25. b6 Re8 26. Re1 d5 27. b7 d4 28. b8=Q dxe3+ 29. Rexe3 Bxe3+ 30. Kd3 Rxb8 31. Qxb8+ Kg7 32. Rb7 Kh6 33. Nh4 Kg5 34. Nxf5 gxf5 35. Qc7 Bf4 36. Qd8+ Kg4 37. Rxf7 Kf3 38. Qh4 Bc1 0-1",
    B: "1105",
  },
  {
    W: "1206",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Bc5 3. Nxe5 Qe7 4. Nd3 Qxe4+ 5. Qe2 Qxe2+ 6. Bxe2 Bd4 7. Nc3 Nc6 8. b3 Nf6 9. f3 O-O 10. Bb2 d6 11. O-O-O Be6 12. Nb5 Bxb2+ 13. Kxb2 Bd7 14. Nxc7 Rab8 15. Nf4 a6 16. Ncd5 Nxd5 17. Nxd5 Be6 18. Nf4 Rbe8 19. Nxe6 Rxe6 20. Rhe1 Rfe8 21. Bc4 Rxe1 22. Rxe1 Rxe1 23. Bd5 Rg1 24. g4 Rg2 25. d3 Rxh2 26. a4 Nb4 27. Bxb7 Rxc2+ 28. Ka3 Nxd3 29. Bxa6 Nc5 30. Bc4 g6 31. f4 Kg7 32. f5 gxf5 33. gxf5 Kf6 34. Kb4 Nd7 35. a5 h5 36. a6 h4 37. a7 Ra2 38. Bd5 h3 39. Be4 h2 40. a8=Q Rxa8 41. Bxa8 d5 42. Bxd5 Ke5 43. Bc6 Kxf5 44. Bxd7+ Kf4 45. Bc6 Kg3 46. Bd5 Kf2 47. Kc4 Kg1 48. b4 h1=Q 49. Bxh1 Kxh1 50. b5 f5 51. b6 f4 52. b7 f3 53. b8=Q f2 54. Qh8+ Kg1 55. Qa1+ Kg2 56. Qg7+ Kh1 57. Qh6+ Kg1 58. Qg5+ Kh1 59. Qh4+ Kg1 60. Qg3+ Kf1 61. Qh3+ Ke1 62. Qh1+ f1=Q+ 63. Qxf1+ Kxf1 1/2-1/2",
    B: "1264",
  },
  {
    W: "1226",
    T: "120+1",
    P: "1. e4 b6 2. d4 Bb7 3. Nc3 c5 4. dxc5 bxc5 5. Bc4 Nf6 6. Bd5 Nxd5 7. exd5 d6 8. Nf3 Nd7 9. O-O Nf6 10. h3 Qa5 11. a3 c4 12. Bd2 Nxd5 13. Re1 Nxc3 14. Bxc3 f6 15. Bxa5 1-0",
    B: "1226",
  },
  {
    W: "1522",
    T: "120+1",
    P: "1. Nf3 c5 2. b3 Nc6 3. Bb2 d6 4. e3 Nf6 5. Be2 g6 6. O-O Bg7 7. d3 O-O 8. Nbd2 Re8 9. c4 a6 10. a3 Rb8 11. Bc3 b5 12. Qc2 b4 13. axb4 cxb4 14. Bb2 a5 15. Nd4 Nxd4 16. Bxd4 e5 17. Bb2 d5 18. cxd5 Nxd5 19. Qc5 Nc3 20. Bf3 Qxd3 21. Ne4 Bf5 22. Bxc3 Qxc3 23. Nxc3 1-0",
    B: "1405",
  },
  {
    W: "2347",
    T: "120+1",
    P: "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 c6 5. Bg2 d5 6. O-O dxc4 7. Ne5 Ng4 8. Nxc4 Qxd4 9. Qc2 Be6 10. Nba3 O-O 11. e3 Qc5 12. h3 Nf6 13. e4 b5 14. Be3 Qb4 15. Ne5 Nbd7 16. Nxc6 Qa4 17. Nxe7+ Kh8 18. Qxa4 bxa4 19. e5 Ne8 20. Bxa8 1-0",
    B: "2024",
  },
  {
    W: "1961",
    T: "60+0",
    P: "1. d4 c5 2. dxc5 e6 3. Nc3 Nf6 4. Nf3 Bxc5 5. Bg5 h6 6. Bh4 g5 7. Bg3 Nc6 8. e3 Nh5 9. Nxg5 Nxg3 10. hxg3 Qxg5 11. Qf3 b6 12. Be2 Bb7 13. Qf4 Qg6 14. O-O-O O-O-O 15. Nb5 e5 16. Qh4 a6 17. Nc3 Kb8 18. Nd5 Nb4 19. Ne7 Qxc2# 0-1",
    B: "1953",
  },
  {
    W: "1485",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. c3 Bc5 5. d3 O-O 6. h3 d6 7. O-O Be6 8. Bxe6 fxe6 9. Bg5 h6 10. Bxf6 Qxf6 11. Re1 Ne7 12. a3 Ng6 13. b4 Nh4 14. Nbd2 Qg6 15. Nxh4 Bxf2+ 16. Kh1 Bxh4 17. Qe2 Bxe1 18. Rxe1 Rf7 19. Nf3 Raf8 20. d4 Qg3 21. dxe5 dxe5 22. c4 Rf6 23. c5 Rg6 24. Rg1 Qf4 25. Qf2 Rg3 26. Rf1 Rxf3 27. gxf3 1-0",
    B: "1472",
  },
  {
    W: "1950",
    T: "60+0",
    P: "1. Nf3 Nf6 2. d3 g6 3. Nbd2 Bg7 4. e4 d6 5. Be2 O-O 6. O-O Nbd7 7. c3 e5 8. Qc2 Qe7 9. b3 Nh5 10. Bb2 Nf4 11. Nc4 f5 12. Ne3 Nf6 13. Nd5 N6xd5 14. exd5 Nxd5 15. c4 Nf4 16. Ne1 g5 17. Rd1 g4 18. Bc1 Qh4 19. Bxf4 exf4 20. Kh1 g3 21. h3 f3 22. Nxf3 Qh6 23. fxg3 f4 24. g4 Bxg4 25. Qd2 Rae8 26. Nh2 Bxh3 27. gxh3 Qxh3 28. Bf3 Be5 29. Bg2 Qg3 30. Qf2 Qxf2 31. Rxf2 Rf7 32. Bf3 Rg7 33. Rg1 Re7 0-1",
    B: "1892",
  },
  {
    W: "1525",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 Ne7 3. d4 Ng6 4. Nc3 Be7 5. Bg5 d6 6. Bxe7 Qxe7 7. e5 Bd7 8. exd6 cxd6 9. d5 e5 10. Bb5 Bxb5 11. Nxb5 Nd7 12. Nc7+ Kf8 13. Nxa8 Qd8 14. O-O Qc8 15. Re1 Qxa8 16. Ng5 h6 17. Nh3 Nf6 18. Re3 b6 19. Rf3 Nxd5 20. c4 Nf6 21. Qxd6+ Ne7 22. Re1 Ne8 23. Qd7 Qc8 24. Qxc8 Nxc8 25. Rxe5 Ne7 26. Rfe3 Nf6 27. Rxe7 g6 28. Nf4 Kg7 29. Nd5 Nxd5 30. cxd5 Kf6 31. Rxa7 Rd8 32. Ree7 Rxd5 33. Rxf7+ Kg5 34. h3 Rd6 35. Kh2 Kh5 36. Rh7 b5 37. Rhb7 b4 38. Rxb4 1-0",
    B: "1536",
  },
  {
    W: "1853",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 h6 5. c3 Bd6 6. Be3 Nf6 7. Qd2 O-O 8. Bd3 Re8 9. O-O Bf8 10. Na3 c6 11. Nc2 b5 12. Nce1 Na6 13. Ne5 Qb6 14. N1f3 Nc7 15. Nh4 Ne4 16. Bxe4 dxe4 17. Rae1 Nd5 18. Bxh6 gxh6 19. Rxe4 f6 20. Ng4 Rxe4 21. Nxh6+ Bxh6 22. Qxh6 Kf7 23. Qg6+ Kf8 24. Qxe4 Bd7 25. Ng6+ 1-0",
    B: "1851",
  },
  {
    W: "1470",
    T: "60+0",
    P: "1. c4 f6 2. g3 d5 3. Bg2 e6 4. b3 Ne7 5. Nf3 Nbc6 6. O-O Bd7 7. e3 Ng6 8. Re1 Qe7 9. d4 O-O-O 10. Bf1 e5 11. dxe5 fxe5 12. Bb2 e4 13. Nd4 Nxd4 14. Bxd4 Rg8 15. cxd5 Qb4 16. Nc3 Bc5 17. Qc2 Bxd4 18. exd4 Bf5 19. Nxe4 Qxd4 20. Bc4 Rgf8 21. Qe2 Bxe4 22. Qxe4 Qxe4 23. Rxe4 Rde8 24. Rxe8+ Rxe8 25. Kg2 Ne5 26. Bf1 Nd3 27. Bxd3 c6 28. Bxh7 Rh8 29. Bf5+ Kd8 30. dxc6 Rh5 31. Bc2 Kc8 32. cxb7+ Kxb7 33. Rc1 1-0",
    B: "1341",
  },
  {
    W: "1522",
    T: "60+0",
    P: "1. d4 d6 2. c4 Nd7 3. e4 Ngf6 4. Nf3 Nxe4 5. d5 Nec5 6. Be3 b6 7. Bxc5 bxc5 8. Bd3 Nf6 9. O-O e6 10. Nc3 exd5 11. Nxd5 Nxd5 12. cxd5 Bg4 13. Bb5+ Ke7 14. Re1+ Kf6 15. Qd3 Bxf3 16. Qxf3+ Kg6 17. Qg3+ Qg5 18. Qd3+ Kh6 19. Qh3+ Qh5 20. Qe3+ Qg5 21. Qxg5+ Kxg5 22. f3 g6 23. h3 Bg7 24. Rab1 Be5 25. f4+ Kf5 26. fxe5 dxe5 27. Bd3+ Kf6 28. Re4 Rhe8 29. Rbe1 Kg7 30. Rxe5 Rxe5 31. Rxe5 Rb8 32. Re7 Rxb2 33. Rxc7 Rb1+ 34. Kh2 Rd1 35. Rxa7 Rxd3 36. Ra3 Rxd5 0-1",
    B: "1505",
  },
  {
    W: "1606",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. Nc3 Nxc3 6. dxc3 Be7 7. Be3 O-O 8. Qd2 Re8 9. O-O-O Nc6 10. Bd3 Ne5 11. Nxe5 dxe5 12. Qe2 c6 13. Bxh7+ Kxh7 14. Rxd8 Rxd8 15. Qh5+ Kg8 16. Rd1 Rxd1+ 17. Kxd1 Be6 18. f4 Rd8+ 19. Kc1 exf4 20. Bxf4 Rd5 21. Qf3 Rf5 22. g3 g5 23. Qe2 gxf4 24. gxf4 Rxf4 25. b3 Rf6 26. Qg2+ Rg6 27. Qf3 Bg5+ 28. Kb2 Bf6 29. Qf4 Bg5 30. Qb8+ Kg7 31. Qxb7 Bf6 32. Qxc6 Rg5 0-1",
    B: "1590",
  },
  {
    W: "1770",
    T: "180+2",
    P: "1. e4 e6 2. d4 c6 3. e5 b5 4. c4 Qa5+ 5. Bd2 b4 6. Qb3 c5 7. dxc5 Bxc5 8. Nf3 Qb6 9. Be3 a5 10. Bxc5 Qxc5 11. a3 Bb7 12. axb4 Qxb4+ 13. Qxb4 axb4 14. Rxa8 Bxa8 15. Nd4 Nf6 16. exf6 gxf6 17. Nb5 Ke7 18. f3 Nc6 19. Kf2 Ne5 20. Nd2 Rg8 21. Ne4 Bxe4 22. fxe4 Ng4+ 23. Kf3 h5 24. b3 Ne5+ 25. Ke3 Ng4+ 26. Ke2 Ra8 27. h3 Ra2+ 28. Kf3 Rb2 29. hxg4 Rxb3+ 30. Kf4 Rb1 31. gxh5 e5+ 32. Ke3 Kf8 33. Kd2 Kg7 34. Kc2 Re1 35. h6+ Kh7 36. Kb3 Rxe4 37. Bd3 1-0",
    B: "1740",
  },
  {
    W: "1772",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 Nc6 3. c3 e6 4. d4 cxd4 5. cxd4 d5 6. exd5 exd5 7. Nc3 Nf6 8. Bg5 Bf5 9. Bb5 Be7 10. Ne5 Rc8 11. O-O O-O 12. Qa4 Ne4 13. Bxe7 Nxe7 14. Nxe4 Bxe4 15. Rac1 a6 16. Be2 Ng6 17. Nxg6 fxg6 18. Qb3 Qg5 19. g3 Rxc1 20. Rxc1 Qxc1+ 0-1",
    B: "1775",
  },
  {
    W: "1861",
    T: "180+2",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. Nf3 Bg4 5. h3 Bh5 6. d4 c6 7. Be2 Nf6 8. Bg5 Nbd7 9. g4 Bg6 10. Ne5 Nxe5 11. dxe5 Qxd1+ 12. Rxd1 Nd7 13. Bd3 Nxe5 14. Bxg6 Nxg6 15. O-O e6 16. Ne4 Bb4 17. c3 Ba5 18. Nd6+ Kf8 19. Nxb7 Bb6 20. Nd6 h6 21. Be3 Ke7 22. Nc4 Bxe3 23. Nxe3 Rad8 24. b3 Rxd1 25. Rxd1 Rd8 26. Kg2 Nf4+ 27. Kg3 Ne2+ 28. Kf3 Nxc3 29. Rc1 Nxa2 30. Rxc6 Rd7 31. Ra6 Nc1 32. b4 Nd3 33. b5 Nc5 34. Ra5 Rb7 35. Nc4 a6 36. bxa6 Rb3+ 37. Ne3 Nxa6 38. Rxa6 f5 39. gxf5 exf5 40. Kf4 Rb4+ 41. Ke5 Rb5+ 42. Nd5+ Kf7 43. Ra7+ Kg6 44. f4 h5 45. Ke6 Kh6 46. Ne3 g6 47. Ra4 Rb6+ 48. Ke5 Rb5+ 49. Nd5 Rb3 50. Ne7 Re3+ 51. Kf6 Rxh3 52. Nxg6 Rg3 53. Ne5 h4 54. Ra8 Kh7 55. Nf7 Rg6+ 56. Kxf5 Rg8 57. Ng5+ Kh8 58. Rxg8+ Kxg8 59. Kg4 Kg7 60. Kxh4 Kg6 61. Kg4 Kf6 62. f5 Kg7 63. Kf4 Kf6 64. Ne4+ Kf7 65. Ke5 Ke7 66. Ng5 Kf8 67. Ke6 Ke8 68. f6 Kf8 69. Nh7+ Ke8 70. f7+ Kd8 71. f8=Q+ Kc7 72. Qd6+ 1-0",
    B: "1885",
  },
  {
    W: "1433",
    T: "180+2",
    P: "1. e4 e5 0-1",
    B: "1483",
  },
  {
    W: "1516",
    T: "180+2",
    P: "1. c4 d5 2. cxd5 Qxd5 3. Nc3 Qd8 4. e4 Nf6 5. d4 e6 6. Nf3 Nbd7 7. Bd3 Be7 8. O-O O-O 9. Ng5 h6 10. e5 hxg5 11. exf6 Bxf6 12. Ne4 Bxd4 13. Nxg5 f5 14. Qh5 Rf6 15. Qh7+ Kf8 16. Qh8+ Ke7 17. Qxg7+ Kd6 18. Nf7+ Rxf7 19. Qxf7 Ne5 20. Bf4 Kd5 21. Bxe5 Kxe5 22. Rfe1+ Kd5 23. Rad1 c5 24. b4 b6 25. Bxf5 1-0",
    B: "1535",
  },
  {
    W: "1231",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 f5 3. d4 fxe4 4. Bg5 Nf6 5. Nxe5 h6 6. Bxf6 Qxf6 7. Qg4 e3 8. Bc4 0-1",
    B: "1266",
  },
  {
    W: "1513",
    T: "180+2",
    P: "1. d4 d5 2. c4 Nf6 3. e3 c6 4. Bd3 e6 5. Nf3 Bd6 6. Bd2 b6 7. b4 c5 8. bxc5 bxc5 9. Nc3 O-O 10. Qc2 Nc6 11. Rb1 Ba6 12. Na4 Rb8 13. O-O dxc4 14. Bxc4 Bxc4 15. Qxc4 Ne4 16. Qa6 Rxb1 17. Rxb1 Nb8 18. Qd3 Nxd2 19. Qxd2 Nc6 20. dxc5 Bc7 21. Qxd8 Rxd8 22. Kf1 Na5 23. Ke2 Nc4 24. h3 f5 25. Rb7 Na5 26. Rxc7 Rb8 27. Rxa7 Nc6 28. Rc7 Na5 29. c6 h5 30. Nd4 Kh7 31. Nxe6 1-0",
    B: "1495",
  },
  {
    W: "1549",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 e6 4. d4 cxd4 5. Nxd4 Nf6 6. f3 a6 7. a3 b5 8. Ba2 Bb7 9. Bg5 Be7 10. Nc3 Nbd7 11. O-O Rc8 12. Kh1 O-O 13. Nxe6 fxe6 14. Bxe6+ Kh8 15. f4 Nxe4 16. Bxe7 Qxe7 17. Bxd7 Qxd7 18. Nxe4 Bxe4 19. Qe2 d5 20. c3 Qc7 21. Qg4 Rf7 22. b4 Rcf8 23. Rac1 Rxf4 24. Rxf4 Qxf4 25. Qxf4 Rxf4 26. Kg1 g6 27. g3 Rf8 28. a4 Kg7 29. axb5 axb5 30. Ra1 Bd3 31. Kg2 Rc8 32. Ra3 Rf8 33. h4 Kh6 34. g4 Be4+ 35. Kh2 Rf2+ 36. Kg3 Rf3+ 37. Kg2 Rf4+ 38. Kg3 g5 39. Ra6+ Bg6 40. h5 Rc4 41. hxg6 hxg6 42. Ra3 d4 43. cxd4 Rxd4 44. Kf3 Rxb4 45. Rc3 Kg7 46. Kg3 Kf6 47. Re3 Rc4 48. Rb3 b4 49. Kg2 Ke5 50. Kf3 Kd4 51. Rb1 Rc3+ 52. Ke2 b3 53. Rd1+ Ke4 54. Kd2 Rg3 55. Rb1 Kf4 56. Ke2 Kxg4 57. Kf2 Rf3+ 58. Ke2 Rc3 59. Kd2 Rh3 60. Ke2 Kh5 61. Kd2 g4 62. Rg1 Rf3 63. Kc1 Kg5 64. Kb2 Kf4 65. Rg2 g5 66. Rd2 Kg3 67. Re2 Rf2 68. Rxf2 Kxf2 69. Kxb3 g3 70. Kc2 g2 71. Kd3 g1=Q 0-1",
    B: "1594",
  },
  {
    W: "1318",
    T: "180+2",
    P: "1. d4 Nf6 2. Nf3 e6 3. Bf4 d5 4. e3 c5 5. Nbd2 Nc6 6. dxc5 Bxc5 7. Bd3 O-O 8. h3 Qb6 9. Nb3 Re8 10. Nxc5 Qxc5 11. O-O e5 12. Nxe5 Nxe5 13. Be2 Bf5 14. c3 Rad8 15. Qb3 b6 16. Rac1 Ng6 17. Bh2 Ne4 18. Bf3 Nd2 0-1",
    B: "1303",
  },
  {
    W: "1341",
    T: "120+1",
    P: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. f4 Nxe4 5. Nxe4 dxe4 6. f5 exf5 7. g4 Nc6 8. gxf5 Bxf5 9. d5 Qh4+ 10. Ke2 Bg4+ 0-1",
    B: "1332",
  },
  {
    W: "1802",
    T: "300+0",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bg5 Ne4 5. Nxe4 dxe4 6. h3 Bg7 7. e3 c5 8. Rc1 cxd4 9. exd4 Bxd4 10. Qe2 O-O 11. Rd1 Qb6 12. b3 Bc3+ 13. Bd2 Qa5 14. Bxc3 Qxc3+ 15. Qd2 Qxd2+ 16. Rxd2 Nc6 17. Ne2 e5 18. Ng3 f5 19. h4 Be6 20. h5 Kf7 21. hxg6+ Kxg6 22. Be2 Rad8 23. Bh5+ Kg5 24. f4+ exf4 25. Nf1 e3 26. Rxd8 Rxd8 27. Bf3 Bf7 28. Ke2 Bg6 29. Bxc6 bxc6 30. Nh2 Rd2+ 31. Ke1 Rxa2 32. Nf3+ Kf6 33. Rh2 Rb2 34. Nd4 h5 35. Nxc6 Rxb3 36. Nd4 Rb2 37. Ne2 f3 38. gxf3 f4 39. Rg2 Bd3 40. Nxf4 Rxg2 41. Nxg2 Bxc4 42. Nxe3 Bf7 43. Kf2 a5 44. Kg3 a4 45. Nc2 Bb3 46. Na3 Bd1 47. f4 Kf5 48. Nb1 Bc2 49. Nc3 Bb3 50. Nb1 1-0",
    B: "1846",
  },
  {
    W: "1692",
    T: "300+0",
    P: "1. e4 e5 2. Bc4 Nf6 3. Nf3 d6 4. d3 Bg4 5. Nbd2 Be7 6. O-O O-O 7. b4 a6 8. a3 b5 9. Ba2 Nbd7 10. c4 c5 11. cxb5 axb5 12. Bb2 Qc7 13. bxc5 dxc5 14. h3 Bh5 15. Qb3 Rab8 16. Ng5 h6 17. Ngf3 c4 18. dxc4 bxc4 19. Qxc4 Qxc4 20. Nxc4 Nxe4 21. Nfxe5 Nxe5 22. Nxe5 Rxb2 23. g4 Bg6 24. Nxg6 Re8 25. Ne5 Kh8 26. Nxf7+ Kh7 27. Ne5 Bc5 28. Nd3 Rd2 29. Nxc5 Nxc5 30. a4 Nxa4 31. Bb1+ Kh8 32. Rxa4 Red8 33. Kg2 Rd1 34. Rxd1 Rxd1 35. Ra8+ 1-0",
    B: "1707",
  },
  {
    W: "1611",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nf6 3. d4 cxd4 4. Qxd4 Nc6 5. Qe3 Ng4 6. Qf4 d5 7. Be2 e5 8. Qg5 f6 9. Qh5+ g6 10. Qh4 dxe4 11. Nfd2 g5 12. Qh5+ Ke7 13. Bxg4 Bxg4 14. Qxg4 Bh6 15. Nb3 Nb4 16. Qd1 Qxd1+ 17. Kxd1 Rad8+ 18. N1d2 g4 19. a3 e3 20. axb4 exd2 21. Nxd2 e4 22. Ke2 e3 23. fxe3 Rhe8 24. Nb3 Kf7 25. Rxa7 Bg5 26. Rxb7+ Kg8 27. h3 g3 28. h4 Bh6 29. h5 f5 30. Kf3 f4 31. Re1 fxe3 32. Bxe3 Rf8+ 33. Kxg3 Bxe3 34. Rxe3 Rd6 35. Ree7 Rfd8 36. Rg7+ Kf8 37. Rbf7+ Ke8 38. Rxh7 R6d7 39. Rxd7 Rxd7 40. Rxd7 1-0",
    B: "1626",
  },
  {
    W: "1761",
    T: "300+3",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Qb6 6. Be2 Nge7 7. dxc5 Qxc5 8. Be3 Qa5 9. b4 Qc7 10. b5 Nxe5 11. Nxe5 Qxe5 12. Bd4 Qc7 13. a4 b6 14. h3 Nf5 15. Bf3 Bb7 16. Nd2 Nxd4 17. cxd4 Bb4 18. Rc1 Qe7 19. O-O O-O 20. Qe2 Rfc8 21. Bg4 Bxd2 22. Qxd2 Rxc1 23. Rxc1 Rc8 24. Rxc8+ Bxc8 25. Qc1 Qd7 26. f4 f5 27. Bf3 Bb7 28. g4 g6 29. gxf5 gxf5 30. Qc2 a6 31. Qg2+ Qg7 32. Qxg7+ Kxg7 33. Be2 axb5 34. Bxb5 Bc8 35. Kf2 Kh6 36. Kg3 Kh5 37. h4 Kg6 38. Be8+ Kf6 39. h5 h6 40. Kf3 Ba6 41. Ke3 Ke7 42. Bc6 Kd6 43. Be8 Ke7 44. Bb5 Bxb5 45. axb5 Kd6 46. Kd3 Kd7 47. Ke3 Kd6 48. Kd3 Kd7 49. Kc3 Kd6 50. Kb4 Kd7 51. Ka4 Kd6 52. Ka3 Kd7 53. Ka2 Kd6 54. Ka1 Kd7 55. Kb1 Kd6 56. Kc1 Kd7 57. Kd1 Kd6 58. Ke1 Kd7 59. Kf1 Kd6 60. Kg1 Kd7 61. Kh1 Kd6 62. Kh2 Kd7 63. Kg2 Kd6 64. Kf2 Kd7 65. Ke2 Kd6 66. Kd2 Kd7 67. Kc2 Kd6 68. Kb2 Kd7 69. Ka2 Kd6 70. Ka3 Kd7 71. Kb3 Kd6 72. Kc3 Kd7 73. Kd3 Kd6 74. Ke3 Kd7 75. Kf3 Kd6 76. Kg3 Kd7 77. Kh3 Kd6 78. Kh4 Kd7 79. Kg3 Kd6 80. Kf3 Kd7 81. Ke3 Kd6 82. Kd3 1/2-1/2",
    B: "1696",
  },
  {
    W: "1539",
    T: "300+3",
    P: "1. c4 Nf6 2. Nc3 g6 3. Nf3 Bg7 4. d4 O-O 5. g3 d6 6. Bg2 e5 7. O-O exd4 8. Nxd4 c5 9. Ndb5 Be6 10. b3 Nc6 11. Nxd6 Qe7 12. Ndb5 Rad8 13. Qe1 Ng4 14. Rb1 a6 15. Na3 h5 16. Nc2 h4 17. Ne3 hxg3 18. hxg3 Nxe3 19. Bxe3 Bf5 20. Rc1 Nd4 21. Nd5 Qd6 22. Bf4 Qe6 23. Nc7 Qxe2 24. Bxb7 Qxe1 25. Rfxe1 Bc8 26. Bg2 Ne6 27. Nd5 Nxf4 28. Nxf4 Rd2 29. Ra1 Bd4 30. Re2 Rxe2 31. Nxe2 Bxa1 0-1",
    B: "1625",
  },
  {
    W: "1320",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 d5 3. Nxe5 dxe4 4. Bc4 Nh6 5. Nc3 Bf5 6. Qh5 g6 7. Bxf7+ Nxf7 8. Nxf7 Kxf7 9. Qe2 Qe7 10. g4 e3 11. gxf5 exf2+ 12. Kxf2 Qc5+ 13. Ke1 Qc6 14. fxg6+ hxg6 15. Rf1+ Kg7 16. d3 Bd6 17. Bf4 Bxf4 18. Rxf4 Qh1+ 19. Rf1 Qxh2 20. Qxh2 Rxh2 21. Rf2 Rh1+ 22. Rf1 Rh5 23. Kd2 Nc6 24. Nd5 Rh2+ 25. Kc3 Ne5 26. Nxc7 Rc8 27. Kd4 Nc6+ 28. Kc5 Rxc7 29. c3 Ne5+ 30. Kd4 Nc6+ 31. Kd5 Rh5+ 32. Kd6 Re7 33. Rfe1 Rxe1 34. Rxe1 Kh6 35. Kc7 Na5 36. b4 Nc6 37. Kxb7 Nd8+ 38. Kxa7 Nf7 39. Re6 Ne5 40. d4 Nc4 41. a4 Rf5 42. a5 Rf7+ 43. Kb8 Na3 44. a6 Nb5 45. Rb6 Nxc3 46. a7 Nd5 47. Rb7 Rxb7+ 48. Kxb7 Nxb4 49. a8=Q Nc2 50. Qh8+ Kg5 51. d5 Nd4 52. Qe5+ Nf5 53. d6 Kh5 54. d7 1-0",
    B: "1317",
  },
  {
    W: "1652",
    T: "300+3",
    P: "1. d4 Nf6 2. Bf4 e6 3. a3 c5 4. Nc3 cxd4 5. Qxd4 Nc6 6. Qd1 Qa5 7. Qd2 d5 8. e3 Bb4 9. Nge2 Ne4 10. Qd3 e5 11. Bg3 Bf5 12. Rg1 Nxg3 13. Qd1 Nxe2 14. axb4 Nxc3 15. bxc3 Qb6 16. Qxd5 O-O 17. Bc4 Bxc2 18. g4 Rad8 19. Qf3 Nxb4 20. cxb4 Qxb4+ 21. Kf1 Qxc4+ 22. Ke1 Be4 23. Qe2 Qc3+ 0-1",
    B: "1612",
  },
  {
    W: "1381",
    T: "300+3",
    P: "1. e4 b6 2. d4 a6 3. c4 h6 4. f4 Bb7 5. Nc3 e6 6. Nf3 d5 7. e5 dxc4 8. Bxc4 c5 9. Be3 b5 10. Bb3 c4 11. Bc2 g5 12. fxg5 hxg5 13. Bxg5 f6 14. exf6 Nxf6 15. Qe2 Be7 16. Qxe6 Nbd7 17. Bg6+ Kf8 18. Qf7# 1-0",
    B: "1340",
  },
  {
    W: "1415",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nxd4 5. Qxd4 d6 6. Bc4 Nf6 7. Bg5 Be7 8. O-O O-O 9. Nc3 h6 10. Bh4 Nxe4 11. Qxe4 Bxh4 12. Rfe1 Be6 13. Bxe6 fxe6 14. Qxe6+ Kh8 15. Nd5 Bxf2+ 16. Kf1 Bxe1+ 17. Kxe1 Qh4+ 18. Kd2 Qf2+ 19. Kc3 c6 20. Nc7 Rae8 21. Nxe8 Qxg2 22. Nxd6 Rf3+ 23. Kc4 Qxc2+ 24. Kd4 Qd3+ 25. Kc5 b6+ 26. Kxc6 Qc2+ 27. Qc4 Qxb2 28. Nf7+ Rxf7 29. Qxf7 Qxa1 30. Qxa7 Qb1 31. Qxb6 Qxa2 32. Qd8+ Kh7 33. Qd3+ Kg8 34. Qd8+ Kf7 35. Qd7+ Kg6 36. Qe8+ Qf7 37. Qe4+ Qf5 38. Qe8+ Qf7 39. Qe4+ Kh5 40. Qe5+ g5 41. Qe2+ Kg6 42. Qc2+ Qf5 43. Qxf5+ Kxf5 44. Kd5 Kf4 45. Ke6 h5 46. Kf6 g4 47. Kg6 h4 48. Kh5 h3 49. Kh4 Kf3 50. Kg5 g3 51. hxg3 Kxg3 0-1",
    B: "1395",
  },
  {
    W: "1225",
    T: "300+3",
    P: "1. d4 d5 2. c4 e6 3. Nf3 Nf6 4. e3 c5 5. Nc3 cxd4 6. exd4 Nc6 7. Qa4 Be7 8. Ne5 O-O 9. Nxc6 bxc6 10. Qxc6 Bd7 11. Qa6 dxc4 12. Bxc4 Qc8 13. O-O Qxa6 14. Bxa6 Rab8 15. b3 Bb4 16. Bd2 Rb6 17. Bc4 Bxc3 18. Bxc3 Nd5 19. Bxd5 exd5 20. Rfe1 Re8 21. Re5 Rxe5 22. dxe5 Re6 23. Bd4 a6 24. Re1 f6 25. f4 Kf7 26. Rc1 fxe5 27. fxe5 Kg6 28. Rc7 Bb5 29. Kf2 h5 30. Kg3 Kh6 31. Kf4 g6 32. h4 Bf1 33. g3 Re8 0-1",
    B: "1272",
  },
  {
    W: "1488",
    T: "300+3",
    P: "1. e4 c5 2. Nc3 Nc6 3. Bc4 e6 4. d3 a6 5. a3 Nf6 6. Bg5 Be7 7. Nf3 h6 8. Bh4 O-O 9. h3 b5 10. Ba2 Bb7 11. g4 Nd4 12. Nxd4 cxd4 13. Ne2 d5 14. e5 Nd7 15. Bxe7 Qxe7 16. Nxd4 Nxe5 17. Qe2 Qf6 18. O-O-O Rac8 19. f4 Qxf4+ 20. Kb1 Qxd4 21. Rde1 Nc6 22. h4 Qf6 23. g5 hxg5 24. hxg5 Qg6 25. Qh2 f5 26. Qh8+ Kf7 27. Qh3 Rh8 28. Qe3 Rxh1 29. Rxh1 Rb8 30. Re1 e5 31. Qb6 Bc8 32. Bxd5+ Kf8 33. Qxc6 Qxc6 34. Bxc6 Bb7 35. Bxb7 Rxb7 36. Rxe5 g6 37. Re6 Kf7 38. Rxa6 Re7 39. Rb6 f4 40. Rxb5 f3 41. Rb4 Kg7 42. Rf4 Rf7 43. Rxf7+ Kxf7 44. Kc1 f2 45. Kd1 f1=Q+ 46. Kd2 Qf4+ 47. Kc3 Qxg5 48. a4 Qa5+ 49. b4 Qxa4 50. Kc4 Qc6+ 51. Kb3 g5 52. c4 g4 53. d4 Qf3+ 54. Ka4 Qd3 55. Kb5 Qxd4 0-1",
    B: "1489",
  },
  {
    W: "970",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Bc5 5. Ng5 O-O 6. Qf3 Nd4 7. Qg3 h6 8. Bxf7+ Rxf7 9. Nxf7 Kxf7 10. Qxe5 Nxc2+ 11. Kd1 Nxa1 12. Qxc5 d6 13. Qc4+ Be6 14. Qc3 a5 15. b3 b5 16. Qxa1 a4 17. b4 c5 18. Ba3 Ng4 19. f4 Nf2+ 20. Kd2 Nxh1 21. f5 Bd7 22. f6 Qxf6 23. Bb2 Qf2+ 24. Kc3 Qe1+ 25. Nd2 cxb4+ 26. Kxb4 Qxd2+ 27. Bc3 Qxd3 28. Qxh1 Qxe4+ 29. Ka3 Qc2 30. Qf1+ Kg8 31. Qf3 d5 32. Qxd5+ Kh7 33. Qxa8 Qxc3# 0-1",
    B: "1216",
  },
  {
    W: "1401",
    T: "300+0",
    P: "1. d4 d5 2. c4 Nf6 3. e3 Be6 4. Nf3 dxc4 5. Nc3 Bd5 6. Be2 e6 7. O-O Bd6 8. a3 e5 9. dxe5 Bxf3 10. Bxf3 Bxe5 11. Bxb7 Qxd1 12. Rxd1 O-O 13. Bxa8 Nbd7 14. Bf3 h5 15. Bc6 g5 16. Bxd7 Nxd7 17. Rxd7 g4 18. g3 f6 19. Nb5 Ra8 20. Nxc7 Bxc7 21. Rxc7 Rd8 22. Rxc4 Rd1+ 23. Kg2 f5 24. b4 Rd3 25. Rc7 a6 26. Bb2 Rb3 27. Rd1 1-0",
    B: "1409",
  },
  {
    W: "1636",
    T: "300+0",
    P: "1. e4 e6 2. Nc3 c6 3. d4 b5 4. d5 b4 5. dxe6 dxe6 6. Nce2 Qxd1+ 7. Kxd1 Nf6 8. e5 Nd5 9. Nd4 c5 10. Bb5+ Bd7 11. Bxd7+ Nxd7 12. Nb5 a6 13. Nd6+ Bxd6 14. exd6 O-O 15. Nf3 Rfd8 16. Bg5 f6 17. Be3 Ne5 18. Nxe5 fxe5 19. Bxc5 Rac8 20. Be3 Rxd6 21. Kd2 a5 22. Rae1 Nf4+ 23. Kc1 Nxg2 24. Re2 Nxe3 25. Rxe3 g6 26. Rxe5 Kg7 27. b3 Rd5 28. Rxe6 Rdc5 29. Re2 a4 30. Rd1 axb3 31. axb3 Ra8 32. Rd7+ Kh6 33. Kb2 Rh5 34. f4 Rf5 35. Ree7 Rxf4 36. Rxh7+ Kg5 37. Rd5+ Kf6 38. Rd6+ Kf5 39. Rd5+ Kf6 40. Rb5 Rc8 41. Rb6+ Kf5 42. Rf7+ Kg5 43. Rb5+ Kg4 44. h3+ Kg3 45. Rg5+ Kf3 46. Rxf4+ Kxf4 47. Rxg6 Rh8 48. Rg4+ Kf3 49. Rxb4 Rxh3 50. Rd4 Ke2 51. Rd7 Rh6 52. b4 Rb6 53. Kb3 Ke3 54. c4 Ke4 55. c5 Rb5 56. Kc4 Rb8 57. b5 Ke5 58. b6 Ke6 59. Rd2 1-0",
    B: "1682",
  },
  {
    W: "1600",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nc3 d5 4. exd5 Nxd5 5. Nxd5 Qxd5 6. d3 Bc5 7. Be2 Nc6 8. O-O Be6 9. c4 Qd6 10. Re1 O-O-O 11. Be3 Bb4 12. a3 Ba5 13. b4 Bxb4 14. axb4 Qxb4 15. Rb1 Qd6 16. Qb3 b6 17. Ng5 Nd4 18. Bxd4 exd4 19. Nxe6 Qxe6 20. h3 g5 21. Bf3 Qd6 22. Qb5 f5 23. Qa6+ Kd7 24. Rb5 g4 25. hxg4 1-0",
    B: "1580",
  },
  {
    W: "2097",
    T: "300+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 d6 4. d4 g5 5. Bc4 Bg7 6. c3 Nc6 7. O-O h6 8. h3 Nge7 9. Nh2 O-O 10. Qh5 Be6 11. d5 Ne5 12. Be2 Bd7 13. Nd2 Qe8 14. Ndf3 f6 15. Qxe8 Raxe8 16. Nxe5 fxe5 17. Bd2 c5 18. c4 Rb8 19. b4 b6 20. a3 Kf7 21. Bh5+ Kf6 22. Ng4+ Bxg4 23. Bxg4 Kg6 24. Rab1 Ng8 25. Bf5+ Kf7 26. bxc5 bxc5 27. Ba5 Bf6 28. Bc7 Rxb1 29. Rxb1 Be7 30. Rb7 Kf6 31. Rxa7 h5 32. Ra6 Nh6 33. Bd7 g4 34. Bxd6 Bxd6 35. Rxd6+ Ke7 36. Rxh6 Kxd7 37. Rxh5 gxh3 38. gxh3 Ra8 39. Rxe5 Rxa3 40. Kg2 Rg3+ 41. Kh2 Rc3 42. Rf5 Rxc4 43. Rxf4 Rd4 44. h4 c4 45. h5 c3 46. Rf3 Rxe4 47. Kh3 Rd4 48. Rxc3 Rxd5 49. Kh4 Ke7 50. Rg3 Kf7 51. h6 Rd1 52. h7 Rd8 53. Kh5 Rd5+ 54. Kh6 Rd6+ 55. Kh5 Rd5+ 56. Kh4 Rd4+ 57. Kh3 Rd8 58. Rg4 Rd1 59. Rg2 Rh1+ 60. Rh2 Rxh2+ 61. Kxh2 Kg7 62. Kg3 Kh8 63. Kg4 Kxh7 1/2-1/2",
    B: "2055",
  },
  {
    W: "1094",
    T: "300+0",
    P: "1. d4 d5 2. e3 Nc6 3. Bb5 a6 4. Bxc6+ bxc6 5. Nc3 Nf6 6. e4 dxe4 7. Bg5 Bg4 8. f3 Bf5 9. Bxf6 exf6 10. fxe4 Bb4 11. exf5 Bxc3+ 12. bxc3 Qe7+ 13. Ne2 O-O 14. Qd3 c5 15. dxc5 Qxc5 16. Nd4 Rae8+ 17. Kd2 a5 18. Nb3 Qd6 19. Nxa5 Qxd3+ 20. cxd3 Re5 21. Nc6 Rxf5 22. Rhe1 Rf2+ 23. Re2 Rxe2+ 24. Kxe2 Re8+ 25. Kd2 h6 26. Re1 Rxe1 27. Ne7+ Kf8 28. Kxe1 Kxe7 29. Kd2 Kd6 30. c4 Kc5 31. Kc3 c6 32. d4+ Kd6 33. c5+ Kd5 34. a4 f5 35. a5 g5 36. a6 f4 37. a7 f3 38. a8=Q fxg2 39. Qxc6+ Kxc6 40. Kc4 g1=Q 41. d5+ Kd7 42. c6+ Kd6 43. Kb5 Qxh2 44. Kb6 Qc2 45. c7 Qxc7+ 46. Kb5 g4 0-1",
    B: "1113",
  },
  {
    W: "1779",
    T: "300+0",
    P: "1. d4 e6 2. Bf4 c6 3. e3 d5 4. Nd2 Ne7 5. c3 Ng6 6. Bg3 Bd6 7. Bd3 Bxg3 8. hxg3 h6 9. Ngf3 Qc7 10. Qc2 Ne7 11. Ne5 Nd7 12. Ndf3 b5 13. Nxd7 Bxd7 14. O-O-O a6 15. g4 Nc8 16. g5 h5 17. Ne5 Nb6 18. Nxf7 Kxf7 19. Bg6+ Ke7 20. f4 Raf8 21. g4 h4 22. Rh2 Nc4 23. Rh3 Kd8 24. e4 Qxf4+ 25. Kb1 Qxg4 26. Rhh1 Qxg5 27. exd5 cxd5 28. Qe2 Qxg6+ 29. Ka1 Qf6 30. a4 Qe7 31. axb5 Bxb5 32. Qh2 Qc7 33. Qg1 Qb7 34. Qg5+ Kc8 35. Qg4 Bd7 36. Qxg7 Qxb2# 0-1",
    B: "1737",
  },
  {
    W: "1959",
    T: "300+0",
    P: "1. d4 g6 2. Nf3 Bg7 3. Bf4 d6 4. e3 Nf6 5. c3 Nc6 6. Nbd2 O-O 7. Bd3 Re8 8. Qc2 e5 9. dxe5 dxe5 10. Bg5 e4 11. Nxe4 Qe7 12. O-O-O Bg4 13. h3 Bxf3 14. gxf3 Ne5 15. Be2 b6 16. f4 Nc6 17. Bd3 Qe6 18. Nxf6+ Bxf6 19. Bxf6 Qxf6 20. h4 Qe6 21. Kb1 Rad8 22. h5 Na5 23. hxg6 fxg6 24. e4 Nc4 25. f5 gxf5 26. exf5 Qe5 27. Bxc4+ Kh8 28. Rxd8 Rxd8 29. f6 Qxf6 30. Qxh7# 1-0",
    B: "1808",
  },
  {
    W: "1290",
    T: "300+0",
    P: "1. Nf3 e6 2. Nc3 d5 3. d4 c5 4. b3 Ne7 5. dxc5 Ng6 6. e3 Bxc5 7. Bd3 d4 8. exd4 Bxd4 9. Qd2 a6 10. O-O O-O 11. Bxg6 fxg6 12. Qe1 Re8 13. Be3 e5 14. Bxd4 exd4 15. Ne2 Bg4 16. Rd1 Qe7 17. Rd2 Bxf3 18. gxf3 Qg5+ 19. Kh1 Nc6 20. Rg1 Qf4 21. Qf1 Qxf3+ 22. Rg2 d3 23. cxd3 Rad8 24. Kg1 Rd6 25. Rg3 Qf5 26. Qg2 Red8 27. Rf3 Rxd3 28. Rxf5 Rxd2 29. Ng3 Rd1+ 30. Nf1 gxf5 31. Qf3 g6 32. Qc3 R8d5 33. Kg2 R5d4 34. Qc5 Rg4+ 35. Kh1 Rxf1# 0-1",
    B: "1221",
  },
  {
    W: "1133",
    T: "300+0",
    P: "1. d4 e5 2. e4 Nc6 3. d5 Nd4 4. f4 Nf6 5. fxe5 Nxe4 6. Qxd4 Qh4+ 7. g3 Qg4 8. Be2 Qf5 9. g4 Qg6 10. Bf4 b6 11. e6 Bc5 12. exd7+ Bxd7 13. Qe5+ Be7 14. Qxc7 Bh4+ 15. Kd1 Nf2+ 16. Kc1 1-0",
    B: "1135",
  },
  {
    W: "1539",
    T: "300+0",
    P: "1. e4 b6 2. d4 Bb7 3. Nc3 g6 4. Bg5 Bg7 5. Bd3 Nc6 6. d5 Ne5 7. Nf3 f6 8. Bf4 e6 9. O-O Qe7 10. Nb5 Qc5 11. Nxe5 fxe5 12. Be3 Qb4 13. Rb1 Kd8 14. c4 Qe7 15. b4 h6 16. a4 g5 17. a5 Nf6 18. h3 exd5 19. cxd5 d6 20. Qc2 a6 21. Nc3 b5 22. Ne2 Qe8 23. Rbc1 Nd7 24. Qxc7+ Ke7 25. Qxb7 Qf7 26. Ng3 Rhb8 27. Nf5+ Kf8 28. Qc6 Nf6 29. Nxd6 Qe7 30. Bc5 Nd7 31. Nf5 Nxc5 32. Nxe7 Kxe7 33. Qxc5+ Kf6 34. Qe3 1-0",
    B: "1457",
  },
  {
    W: "1632",
    T: "60+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. d3 d6 4. f4 Bd7 5. Nf3 a6 6. Be2 b5 7. Nd5 e6 8. Ne3 Qa5+ 9. Bd2 Qc7 10. O-O Be7 11. Bc3 f6 12. a3 O-O-O 13. Nh4 Kb7 14. f5 e5 15. Nd5 Qc8 16. Ba5 Re8 17. Bd2 Nd4 18. c3 Nxe2+ 19. Qxe2 Bc6 20. Nxe7 Nxe7 21. Qg4 Reg8 22. Nf3 g6 23. Qh4 g5 24. Qf2 h6 25. g4 h5 26. h3 hxg4 27. hxg4 Qd7 28. Qg3 Nc8 29. Rf2 Qh7 30. Kf1 Qh1+ 31. Ke2 Qh7 32. Rg1 Qd7 33. Rh2 Rxh2+ 34. Qxh2 Qg7 35. Qg2 Rh8 36. Rh1 Rxh1 37. Qxh1 Qd7 38. Qg2 Qd8 39. Qh2 Qd7 40. Qg2 Bxe4 41. Qf2 Bxd3+ 42. Kxd3 Qc6 43. Kc2 Qe4+ 44. Kc1 Qa4 45. Qe3 Qb3 46. Qd3 Qa2 47. Qxd6 Qa1+ 0-1",
    B: "1667",
  },
  {
    W: "1374",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bc4 Bc5 5. Nc3 Nf6 6. d3 O-O 7. Bg5 h6 8. Bd2 Nd4 9. Qc1 Nxf3+ 10. gxf3 b5 11. Bb3 c6 12. Bxh6 Nh5 13. Rg1 Qf6 0-1",
    B: "1664",
  },
  {
    W: "1308",
    T: "300+0",
    P: "1. e4 g6 2. d4 d6 3. Nf3 Bg7 4. Nc3 Nf6 5. Bd3 O-O 6. O-O Re8 7. Bg5 e5 8. d5 h6 9. Bh4 Bg4 10. h3 Bxf3 11. Qxf3 g5 12. Bg3 Nbd7 13. Rae1 Nc5 14. Bb5 Rf8 15. b4 Ncd7 16. Ne2 a6 17. Ba4 b5 18. Bb3 Rc8 19. Bh2 c5 20. a3 c4 21. Ba2 Nb6 22. c3 Na4 23. Ng3 Nb2 24. Bb1 a5 25. Re2 Na4 26. Bc2 Nb2 27. Nf5 axb4 28. axb4 Ra8 29. Rb1 Na4 30. Ra1 Nb6 31. Qe3 Rxa1+ 32. Re1 Rxe1+ 33. Qxe1 Qa8 34. Nxd6 Nbxd5 35. exd5 Qxd5 0-1",
    B: "1738",
  },
  {
    W: "1711",
    T: "300+0",
    P: "1. e4 d5 2. exd5 Nf6 3. d6 exd6 4. Nc3 Be7 5. d4 O-O 6. Bg5 Re8 7. Qd2 a5 8. O-O-O a4 9. a3 Na6 10. Bf4 Rb8 11. h3 b5 12. Bxb5 Bd7 13. Bxa6 d5 14. Nf3 c5 15. Bxb8 Qxb8 16. dxc5 Bxc5 17. Rhe1 Rd8 18. Nxd5 Nxd5 19. Qxd5 Qf4+ 20. Kb1 Bb6 21. Qxd7 1-0",
    B: "1532",
  },
  {
    W: "1811",
    T: "300+0",
    P: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. c5 Bg4 5. e3 e6 6. Bd3 Qc7 7. O-O Nbd7 8. h3 Bh5 9. g4 Bg6 10. Bxg6 hxg6 11. Kg2 O-O-O 12. Rh1 Ne4 13. Nbd2 Ndf6 14. b4 a6 15. a4 Nxd2 16. Nxd2 Ne4 17. Nxe4 dxe4 18. b5 f5 19. bxa6 bxa6 20. Qb3 fxg4 21. Qxe6+ Qd7 22. Qxd7+ Kxd7 23. hxg4 Rxh1 24. Kxh1 Be7 25. Ba3 Rf8 26. Kg2 Bh4 27. Rf1 g5 28. Bb4 Rb8 29. Ba5 Rb2 30. Bb6 Ra2 31. a5 Kc8 32. Kg1 Kb7 33. f3 exf3 34. Rxf3 Ra3 35. Rf7+ Kc8 36. Rxg7 Rxe3 37. Rc7+ Kb8 38. Rxc6 Re4 39. d5 Rxg4+ 40. Kf1 Rf4+ 41. Kg2 Rd4 42. d6 Rd5 43. Bc7+ Kb7 44. Rb6+ Kc8 45. Rxa6 Rxc5 46. Ra8+ Kd7 47. Rd8+ Kc6 48. d7 Kxc7 49. Rc8+ Kxd7 50. Rxc5 g4 51. Rh5 Be7 52. Kg3 Bb4 53. Kxg4 Kc6 54. a6 Kb6 55. Rh6+ Ka7 56. Rc6 Bd2 57. Kf5 Be3 58. Ke5 Bb6 59. Rc8 Kxa6 60. Ra8+ Kb7 61. Rh8 Kc6 62. Ke6 Bc5 63. Rc8+ Kb5 64. Kd5 Bf2 65. Rb8+ Ka6 66. Kc6 Ka7 67. Rb2 Ka8 68. Kc7 Ka7 69. Ra2# 1-0",
    B: "1814",
  },
  {
    W: "1566",
    T: "300+0",
    P: "1. d4 e6 2. Bf4 d5 3. e3 c5 4. Nf3 cxd4 5. Nxd4 Nc6 6. Bb5 Bd7 7. Na3 a6 8. Nxc6 bxc6 9. Be2 Bxa3 10. bxa3 Qa5+ 11. Qd2 Qxd2+ 12. Kxd2 Nf6 13. Rab1 Ne4+ 14. Ke1 O-O 15. f3 Nc3 16. Rb2 Nxe2 17. Kxe2 Rfb8 18. Bxb8 1-0",
    B: "1722",
  },
  {
    W: "1724",
    T: "300+0",
    P: "1. e4 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qd1 Nf6 5. Bd3 d6 6. h3 g6 7. Nf3 Bg7 8. Nc3 O-O 9. a3 Qc7 10. Be3 a6 11. Qc1 Ne5 12. Nxe5 dxe5 13. Nd5 Nxd5 14. exd5 f5 15. Bh6 e4 16. Be2 Bxh6 17. Qxh6 Qxc2 18. Qd2 Qxd2+ 19. Kxd2 Rd8 20. Bc4 b5 21. Bb3 Bb7 22. d6+ Kg7 23. Ke3 Rxd6 24. Rad1 Rad8 25. Rxd6 Rxd6 26. Rd1 Kf6 27. Rxd6+ exd6 28. f3 exf3 29. gxf3 g5 30. Bc2 Ke5 31. b4 h6 32. Bb3 d5 33. Bc2 d4+ 34. Kf2 Bd5 35. Bd3 Bc4 36. Ke2 Bxd3+ 37. Kxd3 Kf4 38. Ke2 d3+ 39. Kxd3 Kxf3 40. Kd4 f4 41. Kc5 Kg3 42. Kb6 f3 43. Kxa6 f2 44. Kxb5 f1=Q+ 45. Ka5 Qxh3 46. b5 Qf5 47. a4 h5 48. Ka6 Qd3 49. a5 h4 50. Kb6 h3 51. a6 h2 52. a7 Qa3 53. Kb7 h1=Q+ 54. Kb8 Qh8+ 55. Kb7 Qg7+ 56. Kc6 Qaxa7 57. b6 Qf6+ 58. Kc5 Qa5+ 59. Kc4 Qf4+ 60. Kd3 Qa3+ 61. Kc2 Qc4+ 62. Kd1 Qcd3+ 63. Ke1 Qc1# 0-1",
    B: "1910",
  },
  {
    W: "1851",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5 5. O-O h6 6. c3 d6 7. cxd4 Bb6 8. Nc3 Nge7 9. Re1 O-O 10. e5 Ng6 11. e6 fxe6 12. Bxe6+ Bxe6 13. Rxe6 Kh7 14. Qd3 Rf6 15. d5 Rxe6 16. dxe6 Qe7 17. Qe4 Re8 18. Bd2 Qxe6 19. Qd3 Ne5 20. Nxe5 Qxe5 21. h4 Qh5 22. g3 Kh8 23. Nd5 Ne5 24. Qb5 Nf3+ 25. Kg2 Nxd2 26. Qd7 Qf3+ 27. Kg1 0-1",
    B: "1872",
  },
  {
    W: "1918",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. Bc4 Nf6 5. Qf3 e6 6. Nge2 Be7 7. d4 Nc6 8. Be3 Bd7 9. O-O-O O-O 10. g4 a6 11. g5 Nd5 12. Nxd5 exd5 13. Bxd5 b5 14. Bxc6 Bxc6 15. Qxc6 a5 16. h4 b4 17. h5 a4 18. g6 h6 19. gxf7+ Rxf7 20. Nf4 b3 21. cxb3 axb3 22. a3 Bg5 23. Ng6 Bxe3+ 24. fxe3 Qg5 25. Rhe1 Re8 26. Ne5 Rf5 27. Qc4+ Kh8 28. Qxb3 Qe7 29. Qd3 Rxe5 30. dxe5 Qxe5 31. Qd4 Qe6 32. b4 Qc6+ 33. Kb2 Qa4 34. Qd3 Qb5 35. Qxb5 1-0",
    B: "1772",
  },
  {
    W: "1765",
    T: "300+8",
    P: "1. e4 e5 2. f4 d6 3. Nf3 Qe7 4. Bc4 Nf6 5. Nc3 exf4 6. O-O Nxe4 7. Nxe4 Nd7 8. Re1 b6 9. Neg5 Ne5 10. Nxe5 dxe5 11. Bxf7+ Kd8 12. d4 Qxg5 13. dxe5+ Bd7 14. e6 Qf6 15. Qxd7# 1-0",
    B: "1315",
  },
  {
    W: "1425",
    T: "1200+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bc4 h6 5. O-O Bc5 6. h3 O-O 7. Bb5 Nd4 8. Nxe5 d6 9. Nc4 Nxb5 10. Nxb5 Nxe4 11. d3 Ng5 12. d4 Bb6 13. Nc3 Qf6 14. h4 Nh3+ 15. gxh3 Qg6+ 16. Kh2 Qf5 17. Qg4 Qxc2 18. Qg3 Bxd4 19. Bxh6 Be6 20. Ne3 Qh7 21. Bf4 Qd3 22. Rad1 Qa6 23. Rxd4 Bxa2 24. Ra4 1-0",
    B: "1405",
  },
  {
    W: "2257",
    T: "60+0",
    P: "1. b3 Nf6 2. Bb2 e6 3. Nf3 Be7 4. g3 O-O 5. Bg2 d5 6. O-O c6 7. c4 Nbd7 8. d4 Re8 9. Nbd2 Bf8 10. Qc2 g6 11. Rfd1 Bg7 12. e4 e5 13. cxd5 cxd5 14. dxe5 Nxe4 15. Nxe4 dxe4 16. Qxe4 Nc5 17. Rxd8 Nxe4 18. Rxe8+ Bf8 19. Ba3 Be6 20. Rxa8 Kg7 21. Bxf8+ 1-0",
    B: "2233",
  },
  {
    W: "1337",
    T: "60+0",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. cxd5 Nxd5 5. Nxd5 Qxd5 6. Nf3 Nc6 7. b3 Bb4+ 8. Bd2 O-O 9. Bxb4 Nxb4 10. e3 Qc6 11. Rc1 Qd5 12. Bc4 Qa5 13. O-O Nxa2 14. Ra1 b5 15. Qc2 bxc4 16. Rxa2 Qb4 17. bxc4 Ba6 18. Rxa6 Rab8 19. Rxa7 Rfd8 20. h3 e5 21. Nxe5 f6 22. Nc6 Rdc8 23. Nxb4 1-0",
    B: "1211",
  },
  {
    W: "1579",
    T: "60+0",
    P: "1. d4 c6 2. e3 d5 3. Bd3 Nf6 4. Nf3 Bg4 5. Nc3 Bxf3 6. Qxf3 e6 7. Bd2 c5 8. dxc5 Bxc5 9. Qe2 Nc6 10. h3 a6 11. a3 b5 12. e4 dxe4 13. Nxe4 Nxe4 14. Bxe4 Rc8 15. Be3 Bxe3 16. Qxe3 Ne5 17. O-O Nc4 18. Qc3 Qe7 19. Qf3 O-O 20. b3 Ne5 21. Qe3 Ng6 22. Bxg6 fxg6 23. f4 Rfe8 24. f5 exf5 25. Qxe7 Rxe7 26. Rfd1 Rxc2 27. Rd8+ Kf7 28. Rad1 Ree2 29. R8d7+ Ke6 30. R7d6+ Kf7 31. Rd7+ Kf6 32. R1d6+ Kg5 33. Rd1 Rxg2+ 34. Kh1 Rh2+ 35. Kg1 Rcf2 36. Rf1 Rfg2# 0-1",
    B: "1555",
  },
  {
    W: "1500",
    T: "60+0",
    P: "1. e3 g6 2. g3 Bg7 3. Bg2 Nf6 4. Ne2 O-O 5. O-O d6 6. b3 Nbd7 7. Bb2 e5 8. Bxb7 Bxb7 9. Bxe5 dxe5 10. Nbc3 Nc5 11. d4 exd4 12. exd4 Nce4 13. Nxe4 Nxe4 14. Re1 Qg5 15. Nf4 Qh6 16. Rxe4 Bxe4 17. Qe2 g5 18. Qxe4 gxf4 19. Qxf4 Qxf4 20. gxf4 c5 21. dxc5 Bxa1 0-1",
    B: "1421",
  },
  {
    W: "1982",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Nf6 3. c4 c6 4. dxc6 Nxc6 5. Nf3 e5 6. Be2 e4 7. Ng5 Bf5 8. Nc3 h6 9. Nh3 Bxh3 10. gxh3 Bc5 11. Qc2 Qd4 12. Rf1 Nb4 13. Qa4+ Kf8 14. a3 Nd3+ 15. Bxd3 exd3 16. b4 Re8+ 17. Kd1 Qh4 18. Bb2 Qh5+ 19. Kc1 Bd6 20. Nb5 Be5 21. Bxe5 Qxe5 22. Kb1 Qe2 23. Qd1 Qe5 24. Ka2 Qe6 25. Re1 Qxc4+ 26. Qb3 Qc2+ 27. Qxc2 dxc2 28. Rxe8+ Nxe8 29. Re1 g6 30. Kb2 Nf6 31. Kxc2 Kg7 32. Nd4 Rc8+ 33. Kb3 Nd5 34. Nc2 1-0",
    B: "1961",
  },
  {
    W: "2149",
    T: "60+0",
    P: "1. d4 e5 2. Nf3 exd4 3. c3 Nc6 4. cxd4 d5 5. e4 Bg4 6. exd5 Bxf3 7. Qxf3 Nxd4 8. Qd1 Qxd5 9. Nc3 Bd6 10. Nxd5 Ne6 11. Be2 O-O-O 12. O-O c6 13. Qa4 cxd5 14. Qxa7 Bc5 15. Bf4 Bxa7 16. Bb5 Nxf4 17. Rac1+ Kb8 18. g3 Nh3+ 19. Kg2 Ng5 20. h4 Ne6 21. Rfe1 Nf6 22. Bf1 Ne4 23. b3 Bxf2 24. Rxe4 dxe4 25. Kxf2 Rd2+ 26. Ke3 Rxa2 27. Kxe4 Rc8 28. Bc4 b5 29. Bxe6 Rxc1 30. Bxf7 Rd2 31. Kf4 Rf1+ 32. Kg4 Rxf7 33. Kh3 Rd3 34. h5 Rff3 35. Kh4 Rxg3 36. b4 Rg1 37. h6 Rd2 38. Kh3 Rd6 39. Kh2 Rg5 40. hxg7 Rh6# 0-1",
    B: "2105",
  },
  {
    W: "2038",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 d6 4. Nc3 Be7 5. h3 Nf6 6. d3 O-O 7. Bg5 Na5 8. Bxf6 Bxf6 9. Bb3 Nxb3 10. axb3 c6 11. Nd5 cxd5 12. exd5 Bd7 13. O-O a6 14. c4 b5 15. Re1 bxc4 16. dxc4 Qb6 17. Qd3 Rab8 18. Re3 Rfe8 19. Qe4 g6 20. Nh2 Bf5 21. Qf3 e4 22. Qe2 Bd4 23. Ng4 Bxg4 24. Qxg4 f5 25. Qg3 Bxe3 26. Qxe3 Qxb3 27. Qxb3 Rxb3 28. Rxa6 Rxb2 29. Rxd6 Rb1+ 30. Kh2 Rc1 31. Rd7 Rxc4 32. d6 Rd4 33. Rc7 Rd8 34. Rc4 Rxc4 0-1",
    B: "2019",
  },
  {
    W: "1958",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nf3 Bg4 4. Nc3 Qd6 5. Be2 Nf6 6. O-O a6 7. d4 Nc6 8. h3 Bxf3 9. Bxf3 Nxd4 10. Bxb7 Rb8 11. Ne4 Nxe4 12. Bxe4 e6 13. c3 Nb5 14. Qxd6 Bxd6 15. Be3 O-O 16. Bc6 f5 17. Bxb5 axb5 18. Bd4 e5 19. Be3 f4 20. Bd2 b4 21. cxb4 Bxb4 22. Bxb4 Rxb4 23. b3 c5 24. Rac1 Rc8 25. Rc4 Rxc4 26. bxc4 Rd8 27. Rb1 Rd4 28. Rc1 Rd2 29. Ra1 Rc2 30. a4 Rxc4 31. a5 Rb4 32. a6 Rb8 33. a7 Ra8 34. Ra6 Kf7 35. Kf1 h6 36. Ke2 Kg8 37. Kd3 Kh7 38. Kc4 e4 39. Kxc5 e3 40. fxe3 fxe3 41. Kb6 e2 42. Ra1 Re8 43. Re1 Kg6 44. Kb7 Re7+ 45. Kb8 Re8+ 46. Kc7 Re7+ 47. Kb6 Re8 48. Ka6 Kg5 49. Kb5 Kf4 50. Kc4 Kg3 51. Kd3 Kf2 52. Rxe2+ Rxe2 53. a8=Q Rd2+ 54. Kxd2 1-0",
    B: "1887",
  },
  {
    W: "1885",
    T: "60+0",
    P: "1. e4 e5 2. d4 Nf6 3. dxe5 Nxe4 4. Bd3 d5 5. Bxe4 dxe4 6. Qxd8+ Kxd8 7. Ne2 Bc5 8. Ng3 Nc6 9. Nxe4 Bb6 10. Be3 Bf5 11. Bxb6 axb6 12. Nbc3 Nxe5 13. O-O Ke7 14. Rfe1 Rhe8 15. Ng3 Kf8 16. Nxf5 g6 17. Ne3 c5 18. f4 Nc6 19. Ned5 Nd4 20. Nc7 Rxe1+ 21. Rxe1 Rc8 22. N7d5 Nxc2 23. Nxb6 Rc6 24. Rc1 Nd4 25. Nc4 b5 26. Ne5 Ra6 27. a3 f6 28. Nf3 Nb3 29. Rc2 b4 30. axb4 cxb4 31. Nd5 Ra5 32. Rc8+ Kf7 33. Rc7+ Ke6 34. Ne3 h5 35. Nd4+ Nxd4 36. Rc4 Nf5 37. Kf2 Nxe3 38. Kxe3 Rb5 39. Re4+ Kf7 40. Rd4 g5 41. Re4 f5 42. Re5 gxf4+ 43. Kxf4 Rxe5 44. Kxe5 Kg6 45. g3 Kg5 46. h4+ Kg4 47. Kf6 Kxg3 48. Kxf5 Kxh4 49. Kg6 Kg3 50. Kf5 h4 51. Ke4 h3 0-1",
    B: "1802",
  },
  {
    W: "1792",
    T: "60+0",
    P: "1. c4 Nf6 2. d4 g6 3. d5 Bg7 4. Nc3 O-O 5. e4 d6 6. e5 dxe5 7. Ne4 Nxe4 8. f3 Nf6 9. Ne2 e4 10. Ng3 exf3 11. Qxf3 c6 12. Bd3 cxd5 13. c5 Nc6 14. O-O e5 15. Bc2 e4 16. Qe3 Ng4 17. Qf4 Be5 18. Qg5 Qxg5 19. Bxg5 Bxg3 20. hxg3 Nce5 21. b4 a5 22. Bf4 axb4 23. Rab1 Rxa2 24. Bb3 Re2 25. Bd1 Ra2 26. Bxg4 Nxg4 27. Bd6 Re8 28. Rf4 Nh6 29. Re1 Nf5 30. Ref1 Nxg3 31. Rxf7 1-0",
    B: "1714",
  },
  {
    W: "1398",
    T: "60+0",
    P: "1. e4 d5 2. Nf3 dxe4 3. Ng5 f5 4. Bc4 Nh6 5. Qh5+ Kd7 6. Be6+ Kc6 7. d4 Bxe6 8. Nxe6 Qd6 9. d5+ Qxd5 10. Qe8+ Kb6 11. Nxf8 a6 12. Be3+ c5 13. Nc3 1-0",
    B: "1374",
  },
  {
    W: "1523",
    T: "180+0",
    P: "1. c4 e5 2. Nc3 d6 3. g3 Nf6 4. Bg2 c6 5. Rb1 Be6 6. d3 Be7 7. a3 O-O 8. b4 Na6 9. b5 cxb5 10. cxb5 Nc5 11. Be3 Rb8 12. Na4 Nxa4 13. Qxa4 a6 14. Nf3 axb5 15. Qxb5 Ra8 16. a4 Ra5 17. Qxb7 Rxa4 18. O-O Nd5 19. Bd2 Bf6 20. Ne1 Qc7 21. Bxd5 Qxb7 22. Bxb7 Rb8 23. Nc2 Ra2 24. Rfc1 Bg4 25. Bf3 Bxf3 26. exf3 Rxb1 27. Rxb1 h6 28. Rc1 d5 29. Kf1 d4 30. Ke1 Rb2 31. Na1 Kh7 32. Kd1 h5 33. Nc2 g5 34. Ne1 Kg6 35. f4 exf4 36. gxf4 g4 37. Ng2 Rb3 38. Ke2 Rb2 39. Rc4 Kf5 40. Rc5+ Kg6 41. Nh4+ Kh6 42. Nf5+ Kg6 43. Nd6 Rb6 44. Ne4 Kg7 45. f5 Bh4 46. Rc7 Rb5 47. f6+ Bxf6 48. Nxf6 Kxf6 49. Bf4 Rf5 50. Bd2 Re5+ 51. Kd1 Ra5 52. Rc6+ Kg7 53. Bc1 Ra1 54. Rc2 Ra3 55. Rc4 Rxd3+ 56. Ke2 Rc3 57. Rxd4 1-0",
    B: "1592",
  },
  {
    W: "1977",
    T: "180+0",
    P: "1. e3 e5 2. b3 d5 3. Bb2 Nc6 4. Nc3 Nf6 5. Qe2 Bd6 6. O-O-O Ne7 7. h3 c6 8. g4 Be6 9. Bg2 b5 10. Rf1 a5 11. f4 a4 12. f5 Bd7 13. g5 Nfg8 14. f6 gxf6 15. gxf6 Nf5 16. e4 Ng3 17. Qf3 Nxf1 18. exd5 Qxf6 19. Qxf6 Nxf6 20. Bxf1 cxd5 21. Nxb5 Ke7 22. Nf3 axb3 23. axb3 d4 24. Bc4 Rhg8 25. Re1 Rg3 26. Nxe5 Bxe5 27. Rxe5+ Kf8 28. Bxd4 Re8 29. Rc5 Re1+ 30. Kb2 Kg7 31. Rc7 Bxb5 32. Rxf7+ Kg6 33. Rxf6+ Kg5 34. Bxb5 Re4 35. Rd6 Rxh3 36. Be3+ Kg4 37. Bd7+ Kg3 38. Bxh3 Kxh3 39. Rh6+ 1-0",
    B: "1977",
  },
  {
    W: "2251",
    T: "180+0",
    P: "1. d4 d5 2. Nf3 c6 3. Bf4 e6 4. e3 Bd6 5. Bg3 Nf6 6. Nbd2 Qe7 7. Ne5 Nbd7 8. f4 O-O 9. Bd3 Re8 10. O-O Nf8 11. Bh4 Ng6 12. Nxg6 hxg6 13. Nf3 b6 14. Ne5 Bb7 15. Qf3 c5 16. c3 Rac8 17. Qh3 cxd4 18. exd4 Rc7 19. Ng4 Rec8 20. Rae1 a6 21. Re5 b5 22. f5 Bxe5 23. dxe5 Qc5+ 24. Bf2 Nxg4 25. Bxc5 gxf5 26. Bd4 b4 27. Qh4 bxc3 28. bxc3 Rxc3 29. Bxc3 Rxc3 30. Be2 Ne3 31. Rb1 Bc8 32. Qd4 Ra3 33. Qc5 Bd7 34. Qxa3 f4 35. Rb8+ Kh7 36. Qd3+ g6 37. g3 Nf5 38. gxf4 Kg7 39. Qh3 Nh6 40. Rh8 Kxh8 41. Qxh6+ Kg8 42. h4 Be8 43. h5 gxh5 44. Bd3 a5 45. Kf2 a4 46. Kg3 a3 47. Kh4 Bd7 48. Kg5 Be8 49. Kf6 1-0",
    B: "2343",
  },
  {
    W: "1199",
    T: "180+0",
    P: "1. e4 c5 2. d3 e5 3. c4 Nc6 4. Nc3 Nf6 5. Nf3 Qa5 6. h3 Be7 7. g4 O-O 8. Bd2 h5 9. Nd5 Qa6 10. Nc7 1-0",
    B: "1118",
  },
  {
    W: "1777",
    T: "180+0",
    P: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Bg5 Bg7 5. Be2 O-O 6. Nf3 Nbd7 7. O-O c5 8. Bc4 b5 9. Bxb5 Rb8 10. b3 a6 11. Bc4 Bb7 12. d5 Nb6 13. Bd3 h6 14. Bf4 Nh5 15. Be3 Bxc3 16. Rb1 Bg7 17. Qd2 Kh7 18. e5 dxe5 19. Nh4 Nxd5 20. Bxc5 e6 21. Bxf8 Qxh4 22. Bxg7 Kxg7 23. g3 Qh3 24. Be4 f5 25. Bxd5 Bxd5 26. f3 Nxg3 27. hxg3 1-0",
    B: "1610",
  },
  {
    W: "2177",
    T: "180+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 Ne7 4. Bc4 d5 5. exd5 Nxd5 6. O-O Be6 7. Re1 Be7 8. d4 O-O 9. Nc3 Nxc3 10. bxc3 Bxc4 11. Ne5 Be6 12. Bxf4 Nc6 13. Re3 Nxe5 14. Bxe5 Bg5 15. Rg3 f6 16. Qh5 Bf7 17. Qg4 h5 18. Qf5 h4 19. Rxg5 fxg5 20. Re1 Bxa2 21. Qg6 Qe7 22. Re4 Qf7 23. Qxg5 Qf2+ 0-1",
    B: "2147",
  },
  {
    W: "2496",
    T: "180+0",
    P: "1. Nf3 Nf6 2. b3 g6 3. Bb2 Bg7 4. g3 O-O 5. Bg2 d6 6. d4 e5 7. dxe5 Ng4 8. O-O Nxe5 9. Nxe5 dxe5 10. Qxd8 Rxd8 11. Nc3 c6 12. Rfd1 Nd7 13. Ne4 f5 14. Nd6 e4 15. g4 Bxb2 0-1",
    B: "2454",
  },
  {
    W: "1701",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. Bd3 Bf5 5. Nf3 Bxd3 6. cxd3 Nc6 7. O-O Nf6 8. a3 O-O-O 9. b4 Qh5 10. Qc2 e6 11. Ne4 Nxe4 12. dxe4 Bd6 13. g3 f5 14. exf5 exf5 15. Kg2 g5 16. Qxf5+ Kb8 17. d3 Rhf8 18. Qxg5 Qxf3+ 19. Kg1 Qf5 20. Qe3 Rde8 21. Qh6 Rf7 22. Bb2 Qxd3 23. Rae1 Rxe1 24. Rxe1 a6 25. Qh4 Qc2 26. Re8+ Ka7 27. Bd4+ Nxd4 28. Qxd4+ b6 29. Qd5 Qxf2+ 30. Kh1 Qf3+ 31. Qxf3 Rxf3 32. Kg2 Rxa3 33. Rh8 Bxb4 34. Rxh7 Ra2+ 35. Kh3 Bd6 36. Rd7 a5 37. g4 Rxh2# 0-1",
    B: "1676",
  },
  {
    W: "1800",
    T: "180+0",
    P: "1. c4 e6 2. d4 d5 3. cxd5 Qxd5 4. Nf3 Qa5+ 5. Nc3 Qb6 6. Qc2 Bd7 7. e3 c5 8. Be2 Nf6 9. O-O Bc6 10. Na4 Bxa4 11. Qxa4+ Nc6 12. Bb5 Be7 13. Ne5 Rc8 14. Bxc6+ bxc6 15. Bd2 cxd4 16. Qxd4 Qxd4 17. exd4 O-O 18. Bc3 c5 19. dxc5 Bxc5 20. Rac1 Bb6 21. a3 Nd5 22. Nd7 Nxc3 23. Rxc3 Rxc3 24. bxc3 Rc8 25. h3 Rxc3 26. Ra1 Bc5 27. a4 a5 28. Rb1 Bb4 29. Nb6 g6 30. Rd1 Rc6 31. Nd7 Kg7 32. Kf1 f6 33. f3 Kf7 34. Nb8 Rb6 35. Nd7 Rd6 36. Rxd6 Bxd6 37. Nb6 Bb4 38. Ke2 e5 39. Kd3 Ke6 40. Kc4 Kd6 41. Nc8+ Kc7 42. Na7 Kd7 43. Nb5 f5 44. Na7 e4 45. fxe4 fxe4 46. Nb5 e3 47. Nd4 Bd2 48. Kd3 Kd6 49. Ke2 Kc5 50. Ne6+ Kb4 51. Nf8 Kxa4 52. Nxh7 Kb3 53. Nf6 a4 54. Nd5 a3 55. Nxe3 Bxe3 56. Kxe3 a2 57. Kf3 a1=Q 58. Kg4 Qf6 59. g3 Qf5+ 60. Kh4 Qh5# 0-1",
    B: "1932",
  },
  {
    W: "898",
    T: "180+0",
    P: "1. e4 e5 2. d3 g6 3. Nf3 Bg7 4. Bg5 Nf6 5. d4 Nc6 6. dxe5 Nxe5 7. Nxe5 O-O 8. Bc4 d6 9. Ng4 h6 10. Nxf6+ Bxf6 11. Bf4 g5 12. Bc1 Qe8 13. g4 c6 14. h4 d5 15. hxg5 hxg5 16. Be2 dxe4 17. Bf1 Bd7 18. Be3 Rd8 19. Qd6 Bxg4 20. Qxf6 1-0",
    B: "1017",
  },
  {
    W: "2063",
    T: "180+0",
    P: "1. e4 c6 2. d4 d5 3. f3 dxe4 4. fxe4 e5 5. Nf3 Bg4 6. dxe5 Qxd1+ 7. Kxd1 Bxf3+ 8. gxf3 Nd7 9. Bf4 O-O-O 10. Ke1 Bc5 11. Nc3 Bb4 12. Kf2 Bxc3 13. bxc3 Ne7 14. Bc4 Ng6 15. Be3 Ngxe5 16. Bb3 a6 17. Rhg1 g6 18. f4 Rhe8 19. fxe5 Nxe5 20. h3 b5 21. Bd4 Nd7 22. Bxf7 Rf8 23. Ke3 Rxf7 24. a4 c5 25. axb5 cxd4+ 26. cxd4 axb5 27. Rgb1 Kc7 28. Rxb5 Rb8 29. Ra7+ Kc8 30. Rd5 Rb7 31. Ra8+ Kc7 32. e5 Re7 33. Kd3 Nb6 34. Rc5+ Kd7 35. Ra1 Rc7 36. Rb5 Kc6 37. Rc5+ Kd7 38. Rxc7+ Kxc7 39. c4 Kb7 40. Rb1 Ka6 41. c5 Nd7 42. Kc4 Nf8 43. Kd5 Rb7 44. Rxb7 Kxb7 45. c6+ Kc7 46. Kc5 Ne6+ 47. Kd5 Nf4+ 48. Kc4 Ne6 49. d5 Nf4 50. Kc5 1-0",
    B: "2080",
  },
  {
    W: "2138",
    T: "180+0",
    P: "1. d4 d5 2. c4 Nc6 3. Nc3 dxc4 4. e3 e5 5. d5 Nce7 6. Bxc4 Nf6 7. Nf3 Ng6 8. O-O Bd6 9. h3 O-O 10. e4 Qe7 11. Be3 Bd7 12. Rc1 a6 13. a4 Rae8 14. a5 Nh5 15. Qb3 Bc8 16. Bg5 f6 17. Be3 Kh8 18. Ne2 Nhf4 19. Nxf4 Nxf4 20. Bxf4 exf4 21. Bd3 f5 22. exf5 Bxf5 23. Bxf5 Rxf5 24. Qxb7 Qe2 25. Rfe1 Qxe1+ 26. Rxe1 Rxe1+ 27. Nxe1 1-0",
    B: "2127",
  },
  {
    W: "1650",
    T: "180+0",
    P: "1. c4 Nf6 2. Nc3 e5 3. g3 d6 4. Bg2 Bg4 5. h3 Bh5 6. g4 Bg6 7. e3 c6 8. Nge2 Nbd7 9. b3 d5 10. cxd5 cxd5 11. Nxd5 Nxd5 12. Bxd5 e4 13. Bb2 f6 14. Bxb7 Rb8 15. Bc6 Rc8 16. Bxd7+ Qxd7 17. Nf4 Bf7 18. O-O Bd6 19. Ne2 O-O 20. d4 exd3 21. Qxd3 Bh2+ 0-1",
    B: "1649",
  },
  {
    W: "1454",
    T: "60+1",
    P: "1. e4 d6 2. Bc4 Nc6 3. Bxf7+ Kxf7 4. Nf3 Nf6 5. d3 e5 6. c3 Bg4 7. Qb3+ Be6 8. Ng5+ Ke7 9. Qxe6# 1-0",
    B: "1914",
  },
  {
    W: "1846",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 Nf6 5. Bd3 g6 6. O-O Bg7 7. h3 O-O 8. Re1 Nbd7 9. Bg5 Re8 10. Qd2 c6 11. Rxe8+ Qxe8 12. Bh6 Bh8 13. Qg5 Ne4 14. Bxe4 Qxe4 15. Nbd2 Qe8 16. Nb3 Bf6 17. Qd2 Nf8 18. Bg5 Bg7 19. Bh6 Bh8 20. Re1 Ne6 21. Ng5 Bd7 22. Nxe6 Bxe6 23. Qg5 Qd7 24. f4 b6 25. g4 f6 26. Qh4 Re8 27. f5 Bf7 28. Rxe8+ Qxe8 29. fxg6 Bxg6 30. Bd2 Qe4 31. Qe1 Qxe1+ 32. Bxe1 Bg7 33. Bf2 h5 34. Kg2 hxg4 35. hxg4 Kf7 36. Nd2 Be4+ 37. Nxe4 dxe4 38. c3 Bh6 39. Bg3 e3 40. Kf1 Bg5 41. Ke2 Ke6 42. Be1 Kd5 43. Bg3 Ke6 44. Be1 f5 45. b3 fxg4 0-1",
    B: "1868",
  },
  {
    W: "1492",
    T: "180+2",
    P: "1. e4 e5 2. b3 a6 3. Bb2 Nc6 4. a3 Qf6 5. b4 Bc5 6. bxc5 b6 7. cxb6 cxb6 8. Nc3 Bb7 9. Nd5 Qd8 10. Bc4 b5 11. Bb3 a5 12. Qf3 Nh6 13. Ne2 b4 14. axb4 axb4 15. Rxa8 Qxa8 16. Nc7+ Ke7 17. Nxa8 Rxa8 18. Qxf7+ Nxf7 19. Bxf7 Kxf7 20. Ng3 Ra2 21. O-O Rxb2 22. Rc1 Nd4 23. c3 bxc3 24. dxc3 Ne2+ 25. Nxe2 Rxe2 26. f3 h5 27. h4 d5 28. exd5 Bxd5 29. c4 Bc6 30. c5 e4 31. fxe4 Rxe4 32. Kf2 Rxh4 33. g3 Rh2+ 34. Ke3 g5 35. Rf1+ Kg6 36. Kd4 h4 37. gxh4 gxh4 38. Rf4 h3 39. Rh4 Kg5 40. Rh8 Bd7 41. Rh7 Rd2+ 42. Kc3 Rd5 43. Kc4 Re5 44. Rxd7 Kg4 45. c6 h2 46. Rh7 Rh5 47. Rg7+ Kh3 48. c7 Rh8 49. Rh7+ Rxh7 50. c8=Q+ Kg3 51. Qg8+ Kf2 52. Qxh7 Kg2 53. Qg6+ Kh1 54. Qf5 Kg1 55. Qg4+ Kf2 56. Qh3 1-0",
    B: "1446",
  },
  {
    W: "1810",
    T: "180+0",
    P: "1. e4 Nf6 2. e5 Ng8 3. d4 d5 4. f4 Bf5 5. Nf3 e6 6. c3 c5 7. Bb5+ Nc6 8. O-O cxd4 9. Nxd4 Qb6 10. Bxc6+ bxc6 11. Be3 c5 12. Nxf5 exf5 13. b4 Qc6 14. b5 Qxb5 15. Qxd5 Rd8 16. Qf3 Nh6 17. h3 Be7 18. Na3 Qb8 19. Qc6+ Kf8 20. Nc4 g6 21. Nd6 Bxd6 22. exd6 Kg7 23. Qxc5 Rxd6 24. Bd4+ f6 25. Rad1 Rc8 26. Qb4 Qxb4 27. cxb4 Rc4 28. Bc5 Rxd1 29. Rxd1 Nf7 30. Rd7 Rc1+ 31. Kh2 g5 32. fxg5 fxg5 33. a4 Rc2 34. Rxa7 Kg6 35. Ra6+ Kh5 36. Ra7 Kg6 37. Bf8 h5 38. Ra6+ Kh7 39. Ra7 Kg8 40. Bc5 1-0",
    B: "1834",
  },
  {
    W: "1093",
    T: "60+0",
    P: "1. d4 Nf6 2. Nc3 d6 3. Bf4 g6 4. Nf3 c6 5. e4 Bg7 6. Bd3 O-O 7. O-O Nbd7 8. Re1 b6 9. d5 cxd5 10. exd5 Nc5 11. b4 Nxd3 12. cxd3 Ba6 13. a4 Nh5 14. b5 Bb7 15. Be3 Bxc3 16. Re2 Bxa1 17. Qxa1 Nf6 18. Bd4 e5 19. Nxe5 dxe5 20. Bxe5 Re8 21. Bxf6 Qd7 22. Bh8 f5 23. Rxe8+ Rxe8 24. Qd4 Re1# 0-1",
    B: "1402",
  },
  {
    W: "1268",
    T: "60+0",
    P: "1. e4 d6 2. Nc3 Nf6 3. Nf3 g6 4. d4 Bg7 5. Bc4 O-O 6. O-O c5 7. dxc5 dxc5 8. Qxd8 Rxd8 9. Nb5 a6 10. Nc7 Ra7 11. Be3 b5 12. Bb3 Rxc7 13. e5 Nfd7 14. Rfd1 Bb7 15. Bf4 Rcc8 16. Nd2 Nxe5 17. Bxe5 Bxe5 18. Nf3 Bxb2 19. Rab1 Bg7 20. Rxd8+ Rxd8 21. Re1 e6 22. Ne5 c4 23. Bxc4 bxc4 24. Nxc4 Rd4 25. Na5 Rb4 26. Nxb7 Rxb7 27. Rd1 Rc7 28. Rd8+ Bf8 29. Rxb8 Rxc2 30. Rb2 Rxb2 31. g3 Rxa2 32. Kg2 f5 33. h4 Kg7 34. Kh3 Bd6 35. f3 h6 36. g4 g5 37. hxg5 hxg5 38. gxf5 exf5 39. f4 Kg6 40. fxg5 Kxg5 1/2-1/2",
    B: "1446",
  },
  {
    W: "1472",
    T: "60+0",
    P: "1. c4 d6 2. Nf3 e5 3. e3 Nf6 4. d3 g6 5. Nc3 Bg7 6. a3 O-O 7. Rb1 c5 8. b3 Nc6 9. Nd5 b6 10. Nxf6+ Qxf6 11. e4 Bb7 12. Be2 Nd4 13. Nxd4 cxd4 14. Bf3 a6 15. O-O b5 16. cxb5 d5 17. bxa6 Bxa6 18. Be2 dxe4 19. dxe4 Bxe2 20. Qxe2 Rad8 21. Bd2 Qd6 22. Qc4 d3 23. Qd5 Qe7 24. Qc4 Rc8 25. Qb4 Qd7 26. a4 Rfe8 27. Qa3 Qg4 28. f3 Qg5 29. Bxg5 h6 30. Be3 d2 31. Bxd2 Red8 32. Bb4 Bf8 33. Rbc1 Bxb4 34. Qxb4 Rxc1 35. Rxc1 Rd3 36. Qc4 Kg7 37. Qxd3 h5 38. Qd5 Kh6 39. Qxe5 h4 40. Qh8+ Kg5 41. Qf8 Kf6 42. Qxf7+ Kxf7 43. Re1 Kf6 44. e5+ Kg5 45. e6 1-0",
    B: "1307",
  },
  {
    W: "1441",
    T: "60+0",
    P: "1. d4 d5 0-1",
    B: "1428",
  },
  {
    W: "1382",
    T: "60+0",
    P: "1. d3 c6 2. e3 d6 3. Ne2 Nd7 4. Nd2 h6 5. g3 g5 6. Bg2 Bg7 7. O-O Nf8 8. a3 Ng6 9. d4 Nf6 10. c3 Qc7 11. Nb1 h5 12. Bd2 h4 13. e4 hxg3 14. fxg3 Ng4 15. h4 gxh4 16. gxh4 Nxh4 17. Ng3 Nxg2 18. Kxg2 Nf6 19. Bf4 Bg4 20. Qd2 1-0",
    B: "1165",
  },
  {
    W: "1500",
    T: "900+5",
    P: "1. e4 e5 2. Bc4 Nf6 3. d3 Bc5 4. Nf3 d6 5. Nc3 Bg4 6. Bg5 c6 7. O-O O-O 8. Qe2 Nbd7 9. h3 Bxf3 10. g3 Bxe2 0-1",
    B: "1626",
  },
  {
    W: "2372",
    T: "60+0",
    P: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 d5 5. a3 a6 6. axb4 1-0",
    B: "2307",
  },
  {
    W: "2652",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Ne4 6. h4 Nxg5 7. hxg5 Bxg5 8. Nge2 h6 9. f4 Be7 10. g4 c5 11. Nb5 a6 12. Nbc3 Nc6 13. Qd2 cxd4 14. Nxd4 Qb6 15. Nb3 Bd7 16. O-O-O a5 17. a4 Nb4 18. Kb1 Rc8 19. Bb5 Bc6 20. Nd4 Bc5 21. Bxc6+ bxc6 22. Nce2 Be7 23. c3 Na6 24. f5 Nc5 25. fxe6 fxe6 26. Nf4 Kd7 27. Ng6 Rhe8 28. Nxe7 Rxe7 29. g5 hxg5 30. Rh7 Nxa4 31. Rg1 Rb8 32. b3 c5 33. Rxg5 cxd4 34. Rgxg7 Qxb3+ 0-1",
    B: "2717",
  },
  {
    W: "1581",
    T: "60+0",
    P: "1. d4 d5 2. f3 e6 3. e3 Nf6 4. c3 Nc6 5. b4 Bd6 6. g4 Bd7 7. Bd3 e5 8. Ne2 e4 9. fxe4 dxe4 10. Bc2 O-O 11. h3 Re8 12. Rg1 Nd5 13. Rg2 Be6 14. Kd2 a6 15. Qg1 g6 16. Bxe4 b5 17. a4 bxa4 18. Rxa4 Nf6 19. Bxc6 Re7 20. Bxa8 Qxa8 21. b5 Ne4+ 22. Ke1 a5 23. Na3 Bd7 24. c4 c6 25. c5 Bxc5 26. dxc5 Be6 27. Rxe4 Rd7 28. bxc6 Rc7 29. Rxe6 fxe6 30. Nd4 e5 31. Ne6 Rxc6 32. Qf2 1-0",
    B: "1547",
  },
  {
    W: "1389",
    T: "60+0",
    P: "1. e3 d5 2. c4 e5 3. d4 exd4 4. exd4 dxc4 5. Bxc4 Bb4+ 6. Nc3 Nf6 7. Be3 O-O 8. Nf3 c5 9. O-O cxd4 10. Bxd4 Bxc3 11. bxc3 Nc6 12. Bd3 Nxd4 13. Nxd4 Bg4 14. Qe1 Re8 15. Qd2 Rc8 16. Bc2 Nd5 17. Rfe1 Nf4 18. Qxf4 Rxe1+ 19. Rxe1 Bh5 20. Nf5 Bg6 21. h3 Rxc3 22. Ne7+ Kh8 23. Nxg6+ fxg6 24. Qe4 Rc8 25. Qe8+ Qxe8 26. Rxe8+ Rxe8 27. Bb3 Re1+ 28. Kh2 Re2 29. g3 Rxf2+ 30. Kg1 Rb2 31. Kf1 a5 32. Ke1 b5 33. Kd1 Rh2 34. Kc1 a4 35. Kb1 axb3 36. axb3 b4 37. Ka1 Rxh3 38. Kb1 Rxg3 39. Kc1 Rg2 40. Kd1 h5 0-1",
    B: "1423",
  },
  {
    W: "2204",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 a6 3. d4 cxd4 4. c3 dxc3 5. Nxc3 e6 6. Bc4 b5 7. Bb3 d6 8. O-O Be7 9. Qe2 Nf6 10. Rd1 Qb6 11. Be3 Qb7 12. e5 dxe5 13. Nxe5 O-O 14. Rac1 b4 15. Na4 Nbd7 16. Nc4 Rd8 17. Na5 Qe4 18. Nc6 Re8 19. Rc4 Qf5 20. Bc2 Ne5 21. Bxf5 Nxc4 22. Qxc4 exf5 23. Nxe7+ Rxe7 24. Bg5 Be6 25. Qc5 Rae8 26. Bxf6 gxf6 27. Nb6 Bb3 28. Rf1 Bxa2 29. h3 Be6 30. Qxb4 f4 31. Qa5 f3 32. Qxa6 fxg2 33. Kxg2 Bf5 34. Rg1 Re6 35. Kf3+ Kh8 36. Kf4 Be4 37. f3 Bc6 38. Qc4 1-0",
    B: "2204",
  },
  {
    W: "1753",
    T: "60+0",
    P: "1. e4 d6 2. d4 g6 3. Nf3 Bg7 4. c3 Nc6 5. g3 Bg4 6. Bg2 e5 7. d5 Nce7 8. Nbd2 f5 9. O-O h5 10. exf5 Nxf5 11. h3 Bxf3 12. Nxf3 Bf6 13. h4 Qe7 14. Bg5 O-O-O 15. Re1 e4 16. Bxf6 Nxf6 17. Ng5 Rhe8 18. Ne6 Rd7 19. c4 c6 20. Qa4 Kb8 21. Rab1 cxd5 22. cxd5 Nxd5 23. Ng5 Nb6 24. Qb3 d5 25. a4 e3 26. fxe3 Nxe3 27. a5 Qc5 28. axb6 Nc4+ 29. Kh2 Rxe1 30. Rxe1 1-0",
    B: "1725",
  },
  {
    W: "2391",
    T: "60+0",
    P: "1. e4 e5 2. Nc3 Nc6 3. Nf3 Nf6 4. Bb5 Nd4 5. Ba4 c6 6. O-O b5 7. Bb3 Qc7 8. Nxd4 exd4 9. Ne2 Bc5 10. c3 d3 11. Ng3 a5 12. Qf3 a4 13. Bd1 O-O 14. Qxd3 d5 15. exd5 Nxd5 16. Qc2 Rd8 17. d4 Bb6 18. Re1 Be6 19. Bf3 b4 20. c4 Nf6 21. c5 Ba5 22. b3 a3 23. Be3 Qd7 24. Rad1 Bc7 25. Nf5 Bxf5 26. Qd2 Nd5 27. Bg5 h6 28. Bh4 Bg4 29. Qe2 Bxf3 30. Qxf3 Nc3 31. Bxd8 Nxd1 32. Bxc7 Nxf2 33. Be5 f6 34. Bg3 Qxd4 35. Qxf2 Qxf2+ 36. Bxf2 Rd8 37. Re2 Rd3 38. Re1 Rd1 39. Rxd1 Kf7 40. Rd7+ Kg6 41. Rc7 Kf5 42. Rxc6 g6 43. Rb6 Kg4 44. Rxb4+ Kg5 45. Ra4 f5 46. Rxa3 Kg4 47. Ra4+ f4 48. Rc4 Kg5 49. Rc3 h5 50. Rc4 Kh6 51. Bh4 g5 52. Bxg5+ Kg7 0-1",
    B: "2177",
  },
  {
    W: "2073",
    T: "60+0",
    P: "1. d3 c5 2. Nd2 Nc6 3. Ngf3 b6 4. g3 Bb7 5. Bg2 Nd4 6. O-O Nxf3+ 7. Nxf3 g6 8. e4 Bg7 9. c3 e6 10. Re1 Ne7 11. Bg5 O-O 12. e5 h6 13. Bf6 Bxf6 14. exf6 Nd5 15. Qd2 Nxf6 16. Qxh6 Nh7 17. d4 Qf6 18. Ne5 Bxg2 19. Kxg2 Rad8 20. h4 Qg7 21. Qe3 cxd4 22. cxd4 f6 23. Nc4 g5 24. h5 f5 25. Nd6 f4 26. Qb3 fxg3 27. fxg3 Qxd4 28. Rad1 Qg4 29. Rf1 Rxf1 30. Rxf1 Rf8 31. Rxf8+ Kxf8 32. Qf3+ Qxf3+ 33. Kxf3 Ke7 34. Ne4 d5 35. Nc3 Nf6 36. g4 e5 37. Nxd5+ Nxd5 38. Ke4 Nf6+ 39. Kf5 e4 40. h6 Kf7 41. h7 1-0",
    B: "2133",
  },
  {
    W: "2169",
    T: "60+0",
    P: "1. c4 Nf6 2. g3 d6 3. Bg2 g6 4. Nf3 Bg7 5. d3 O-O 6. O-O Nc6 7. Nc3 e5 8. Bg5 h6 9. Bxf6 Bxf6 10. Qd2 Bg7 11. a3 Nd4 12. Rab1 c6 13. b4 Re8 14. e3 Nxf3+ 15. Bxf3 Bh3 16. Bg2 Bxg2 17. Kxg2 f5 18. b5 c5 19. Nd5 b6 20. a4 Kh7 21. a5 e4 22. dxe4 Rxe4 23. a6 Rxc4 24. Qd3 Re4 25. f3 c4 26. Qd2 Re5 27. f4 Re4 28. Nb4 c3 29. Qd5 Rc8 30. Nc6 Qf6 31. Rfc1 g5 32. Kf3 g4+ 33. Kf2 Rce8 34. Nd4 Rxe3 35. Rxc3 Qxd4 36. Qxd4 Bxd4 37. Rxe3 Rxe3 38. Kg2 Re2+ 39. Kf1 Rxh2 40. Re1 1-0",
    B: "2153",
  },
  {
    W: "1388",
    T: "60+0",
    P: "1. d4 d5 2. Bf4 Nc6 3. e3 f6 4. Nf3 e5 5. Bd3 exf4 6. exf4 Qe7+ 7. Be2 Bg4 8. O-O Bxf3 9. Bxf3 O-O-O 10. Re1 Qd6 11. g3 Nh6 12. c3 Nf5 13. Nd2 Nfxd4 14. cxd4 Nxd4 15. Nb3 Nxf3+ 16. Qxf3 d4 17. Rad1 Qd5 18. Qg4+ Kb8 19. Nxd4 Bc5 20. Ne6 Qxa2 21. Rxd8+ Rxd8 22. Nxd8 Qa5 23. Re8 b6 24. Nc6+ Kb7 25. Nxa5+ Ka6 26. Qc8+ Kxa5 27. Qa8 Bxf2+ 28. Kg2 Kb4 29. Qxa7 Bc5 30. Qxc7 Kb3 31. Re4 Kxb2 32. Qxc5 1-0",
    B: "1291",
  },
  {
    W: "1223",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. Bxc6 dxc6 5. Nxe5 Qf6 6. Ng4 Bxg4 7. f3 Qh4+ 8. g3 Qh3 9. fxg4 Qg2 10. Rf1 Qxe4+ 11. Qe2 Bf2+ 12. Rxf2 Qxe2+ 13. Rxe2+ Ne7 14. d3 O-O 15. Bg5 f6 16. Bh4 Rad8 17. Rxe7 Rde8 18. Re2 Rxe2+ 19. Kxe2 Re8+ 20. Kd1 g5 21. Bxg5 fxg5 22. Nd2 Rf8 23. c3 Rf2 24. h4 gxh4 25. gxh4 Rg2 26. g5 Rg1+ 27. Ke2 Rxa1 28. a3 Kg7 29. Ne4 h6 30. Kd2 Ra2 31. Kc2 Ra1 32. d4 Re1 33. Nc5 Rh1 34. Nxb7 Rxh4 35. gxh6+ Kxh6 36. Nd8 c5 37. dxc5 Rh2+ 38. Kb3 Rd2 39. Nc6 a6 40. Kb4 Rxb2+ 41. Ka5 Rb5+ 42. Kxa6 Rxc5 43. Kb7 Rxc3 44. a4 Rb3+ 45. Kxc7 Ra3 46. a5 Rc3 47. a6 Re3 48. a7 Re8 49. Kb7 Kg7 50. Nb8 Re7+ 51. Kb6 Re6+ 52. Kb7 Re7+ 53. Ka6 Re6+ 54. Kb7 Re7+ 55. Kb6 Re6+ 56. Kc7 Re7+ 57. Nd7 Re8 58. Nb8 Re7+ 59. Nd7 Re8 60. Nb8 Re7+ 61. Kb6 Re6+ 62. Nc6 Re8 63. Nb8 Re6+ 64. Kb7 Re7+ 65. Kc8 Rxa7 66. Nc6 Ra8+ 67. Kb7 Re8 68. Nd4 Rd8 69. Ne6+ Kf6 70. Nxd8 1/2-1/2",
    B: "1202",
  },
  {
    W: "1908",
    T: "600+0",
    P: "1. d4 Nc6 2. Bg5 h6 3. Bh4 g5 4. Bg3 Bg7 5. c3 d6 6. e3 Bf5 7. Bb5 a6 8. Ba4 b5 9. Bb3 Nf6 10. Nd2 O-O 11. Qf3 Bg4 12. Qxc6 e5 13. Ngf3 Bd7 14. Qb7 Rb8 15. Qxa6 Ra8 16. Qb7 Rb8 17. Qa6 Ra8 18. Qb7 Rb8 1/2-1/2",
    B: "1923",
  },
  {
    W: "1295",
    T: "600+0",
    P: "1. d4 d5 2. c4 dxc4 3. e3 Be6 4. Qa4+ c6 5. Bxc4 Bxc4 6. Qxc4 Qd5 7. Qxd5 cxd5 8. Ne2 e6 9. O-O Ne7 10. a3 Nbc6 11. b4 b5 12. Nbc3 a6 13. Bd2 Nf5 14. f4 g6 15. Rfc1 Nfe7 16. Nd1 Bg7 17. a4 Nxd4 18. Nxd4 Bxd4 19. exd4 Rc8 20. axb5 Rxc1 21. Rxc1 axb5 22. Rc5 O-O 23. Rxb5 Nf5 24. Be3 Nxe3 25. Nxe3 Rc8 26. Kf2 Ra8 27. Rc5 Ra2+ 28. Kf3 h5 29. h4 Ra3 30. b5 Rb3 31. Rc8+ Kg7 32. Rb8 Rd3 33. b6 Rxd4 34. b7 Rb4 35. g3 Rb3 36. Kf2 d4 37. Nc4 d3 38. Ke3 d2+ 39. Kxd2 Rxg3 40. Rd8 Rg2+ 41. Kd3 Rg3+ 42. Kd4 Rg4 43. b8=Q Rxh4 44. Qe5+ f6 45. Rd7+ Kh6 46. Qe3 e5+ 47. Kd3 Rxf4 48. Rf7 e4+ 49. Kd2 g5 50. Nd6 f5 51. Nxf5+ Kg6 52. Qa7 Rxf5 53. Rxf5 Kxf5 54. Qf2+ Ke5 55. Qg3+ Kd4 56. Qxg5 h4 57. Qxh4 Ke5 58. Ke2 Kd4 59. Qh3 Ke5 60. Qe3 Kd5 61. Kd2 Ke5 62. Kc3 Kf5 63. Kd4 Ke6 64. Qxe4+ Kd6 65. Qe5+ Kc6 66. Qd5+ Kb6 67. Ke5 Ka6 68. Kd6 Kb6 69. Qc5+ Ka6 70. Kc6 1/2-1/2",
    B: "1306",
  },
  {
    W: "1792",
    T: "600+0",
    P: "1. e4 g6 2. d4 Bg7 3. Nc3 e6 4. Nf3 Ne7 5. Bd3 b6 6. O-O Bb7 7. Re1 d6 8. Bd2 Nd7 9. Qe2 c5 10. d5 e5 11. Nb5 Nf6 12. c4 a6 13. Nc3 O-O 14. a3 Nd7 15. b4 Nf6 16. Rab1 a5 17. bxc5 dxc5 18. Rxb6 Qxb6 19. Rb1 Qa7 20. Nxe5 Rab8 21. Nb5 Qb6 22. g4 Qd8 23. g5 Nd7 24. Bxa5 Qxa5 25. Nxd7 Ba6 26. Nxb8 Rxb8 27. Qa2 Bd4 28. Nxd4 Rxb1+ 29. Qxb1 cxd4 30. Qb8+ Kg7 31. Qe5+ Kg8 32. Qxe7 Qb6 33. d6 Qb8 34. d7 Bb7 35. d8=Q+ Qxd8 36. Qxd8+ Kg7 37. Qxd4+ Kg8 38. Qd8+ Kg7 39. c5 Bc6 40. Qd4+ Kg8 41. a4 Bb7 42. a5 Bc6 43. a6 Be8 44. Qd8 Kf8 45. a7 Kg7 46. a8=Q h6 47. Qxe8 hxg5 48. Qg8+ Kf6 49. Qaf8 Ke5 50. Qgxf7 Kd4 51. c6 Kc3 52. c7 1-0",
    B: "1769",
  },
  {
    W: "1483",
    T: "600+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 Ne7 4. Bc4 d5 5. exd5 Nxd5 6. O-O Bc5+ 7. d4 Bb6 8. Bxf4 Nxf4 9. Bxf7+ Kxf7 10. Ne5+ Kf6 11. Rxf4+ Bf5 12. c3 Re8 13. Qe1 Nc6 14. Qh4+ g5 15. Ng4+ Ke6 16. Qh6+ Kd5 17. Rxf5+ Ne5 18. Nf6+ Kc4 19. Nd2+ Kb5 20. a4+ Ka6 21. Nxe8 Qxe8 22. Rxe5 Qxe5 23. Nc4 Qe2 24. b4 Qxc4 25. Qxg5 c6 26. b5+ cxb5 27. axb5# 1-0",
    B: "1503",
  },
  {
    W: "1673",
    T: "600+0",
    P: "1. e4 e5 2. f4 d5 3. exd5 Qxd5 4. Nc3 Qc5 5. Qe2 Be6 6. Qb5+ Qxb5 7. Nxb5 Na6 8. fxe5 O-O-O 9. Nf3 Nc5 10. d4 a6 11. Nc3 Nd7 12. Bd3 f6 13. h3 fxe5 14. dxe5 h6 15. O-O Bc5+ 16. Kh1 Ne7 17. Ne4 Ba7 18. b3 g5 19. Ba3 Nf5 20. Nf6 Ng3+ 21. Kh2 Nxf1+ 22. Rxf1 g4 23. Nxg4 Bxg4 24. hxg4 Rhe8 25. Bf5 Bd4 26. Nxd4 Rxe5 27. Bxd7+ Rxd7 28. Rf8+ Rd8 29. Rxd8+ Kxd8 30. Nf5 h5 31. Bb2 Rc5 32. Bf6+ Kd7 33. c4 hxg4 34. Kg3 Rxf5 35. Bh4 Ra5 36. a4 Re5 37. Kxg4 c5 38. Kf4 Re2 39. g4 Rb2 40. Kf5 Rxb3 41. g5 Rb4 42. g6 Rxc4 43. Bf6 Ke8 44. Ke6 Rg4 45. g7 b5 46. axb5 axb5 47. Kf5 Rg1 48. Bg5 Kf7 49. Be3 Rxg7 50. Bxc5 Rg8 51. Ke4 Rb8 52. Kd3 b4 53. Kc2 b3+ 54. Kb2 Ke6 55. Bd4 Rb5 56. Bc3 Kd5 57. Bg7 Kc4 58. Bf6 Ra5 59. Bg7 Ra2+ 60. Kb1 Kd3 61. Bf6 Rc2 62. Be5 Kd2 63. Bf4+ Kd1 64. Be5 Rc1+ 65. Kb2 Kd2 66. Kxb3 1/2-1/2",
    B: "1552",
  },
  {
    W: "1523",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e6 5. c4 Bc5 6. Be3 Qf6 7. Nxc6 Bxe3 8. fxe3 bxc6 9. Nc3 Ne7 10. Be2 Qh4+ 11. g3 Qh6 12. Qd4 O-O 13. Na4 e5 14. Qxe5 Ng6 15. Qd4 Re8 16. Nc5 Ne5 17. O-O d6 18. Nd3 Nxd3 19. Bxd3 Bh3 20. Rf2 a5 21. Bf1 c5 22. Qd5 Ra7 23. Bxh3 Qxh3 24. Raf1 Qe6 25. Qc6 Qxe4 26. Qxe4 Rxe4 27. Rd2 Rd7 28. Kf2 Rxc4 29. Rfd1 Rb4 30. Kf3 h6 31. a3 Rb3 32. Rxd6 Rxd6 33. Rxd6 Rxb2 34. h4 Rb1 35. Ra6 c4 36. Ke2 Rb2+ 37. Kd1 c3 38. Rxa5 c2+ 39. Kc1 Ra2 40. a4 f6 41. Ra8+ Kf7 42. a5 Ke6 43. a6 Kd5 44. a7 Ke4 0-1",
    B: "1515",
  },
  {
    W: "760",
    T: "600+0",
    P: "1. d4 b5 2. f4 Nc6 3. Nc3 e5 0-1",
    B: "924",
  },
  {
    W: "1931",
    T: "600+0",
    P: "1. e4 d6 2. d4 c6 3. f4 e5 4. dxe5 dxe5 5. Qxd8+ Kxd8 6. fxe5 Nd7 7. Nf3 Ne7 8. Bf4 Ng6 9. Bg3 Bc5 10. Nc3 Re8 11. O-O-O b5 12. h4 h6 13. h5 Nf8 14. a4 a6 15. Bh4+ Kc7 16. g4 Ne6 17. g5 hxg5 18. Nxg5 Be3+ 19. Kb1 Bxg5 20. Bxg5 Nxg5 21. h6 gxh6 22. Rxh6 Nxe5 23. axb5 axb5 24. Bxb5 cxb5 25. Nxb5+ Kb8 26. Rb6+ Bb7 27. Nd6 Re7 28. Nb5 Ne6 29. Rh1 Ng6 30. Rh7 Ra6 31. Rxa6 Bxa6 32. Nd6 Kc7 33. Nxf7 Ne5 34. Ng5 Rxh7 35. Nxh7 Kd6 0-1",
    B: "2188",
  },
  {
    W: "1718",
    T: "600+0",
    P: "1. d4 d5 2. Bf4 g6 3. Nc3 Nf6 4. h3 Bg7 5. Nf3 c5 6. e3 Nc6 7. Nb5 e5 8. dxe5 Nh5 9. Bh2 a6 10. Nd6+ Kf8 11. Qxd5 Be6 12. Qd1 Qa5+ 13. c3 Rd8 14. Ng5 Bxe5 15. Nxe6+ fxe6 16. Qf3+ Ke7 17. Nc4 Qc7 18. Nxe5 Nxe5 19. Bxe5 Qxe5 20. Bc4 Rhf8 21. Qxb7+ Rd7 22. Qxa6 Nf6 23. Bb5 Rd6 24. Qb7+ Nd7 25. O-O Rb8 26. Qa6 Rxa6 27. Bxa6 Rxb2 28. Rfd1 Qf5 29. f4 Qc2 30. Bf1 Rxa2 31. Rac1 Qf2+ 32. Kh1 Nf6 33. Rb1 Ne4 34. Rb7+ Kf6 35. Kh2 Ng3 0-1",
    B: "1729",
  },
  {
    W: "1858",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 g6 4. Nc3 Bg7 5. d4 cxd4 6. Nxd4 Nc6 7. Nxc6 bxc6 8. O-O Nf6 9. Qf3 d5 10. exd5 cxd5 11. Bb5+ Bd7 12. Bg5 Bxb5 13. Nxb5 O-O 14. Rad1 Qb6 15. Bxf6 Bxf6 16. Rxd5 a6 17. Nc3 Qxb2 18. Ne4 Qxa2 19. Rfd1 Qxc2 20. Nxf6+ exf6 21. Qxf6 a5 22. h4 h5 23. R1d2 Qb1+ 24. Kh2 Qb8+ 25. g3 a4 26. Rxh5 gxh5 27. Rd5 Ra6 28. Rg5+ Kh7 29. Qg7# 1-0",
    B: "1875",
  },
  {
    W: "1408",
    T: "600+0",
    P: "1. d4 e6 2. Nc3 Nc6 3. Bf4 Bb4 4. Qd3 Qh4 5. Nf3 Bxc3+ 6. Qxc3 Qxf4 7. e3 Qd6 8. Bd3 Nf6 9. O-O Ng4 10. a3 O-O 11. Qc4 Qd5 12. h3 Qxc4 13. Bxc4 Nf6 14. c3 d5 15. Bb5 Bd7 16. Ne5 Rad8 17. Nxd7 Rxd7 18. a4 Ne4 19. f3 Nd2 20. Rf2 Nb3 21. Ra3 Nba5 22. e4 dxe4 23. fxe4 a6 24. Be2 b5 25. axb5 axb5 26. Bxb5 Ra8 27. Bxc6 Nxc6 28. Rxa8+ Nd8 29. b4 h5 30. Rfa2 Kh7 31. Rc8 c6 32. Raa8 Nb7 33. Rh8+ Kg6 34. Kf2 Nd6 35. Ra5 Nxe4+ 36. Ke3 Nxc3 37. Raxh5 Nd5+ 38. Ke4 Nxb4 39. Kf4 Nd5+ 40. Ke5 Nf6 41. Rh4 Rd5+ 42. Kf4 Rxd4+ 43. Ke3 Rd5 44. Ra8 Re5+ 45. Kf2 Ne4+ 46. Kg1 Kg5 47. Rg4+ Kf6 48. Ra4 Nc5 49. Raf4+ Ke7 50. Rb4 Re1+ 51. Kh2 g6 52. Rg5 Kf6 53. Rxc5 g5 54. Rxc6 Re3 55. Rb7 Re5 56. Rcc7 Kg6 57. Rxf7 Rd5 58. Rg7+ Kf6 59. Rgf7+ Kg6 60. Rfc7 Rd8 61. Rg7+ Kf6 62. Rgc7 Kg6 63. Rb4 Re8 64. Rg4 Kf6 65. Rc5 e5 66. Re4 Kf5 67. Rec4 Re7 68. Rc6 Re8 69. Ra4 Re7 70. Raa6 Re8 71. Rf6+ Ke4 72. Rg6 Kf5 73. Raf6+ Ke4 74. Rxg5 Rh8 75. Ra6 Rh7 76. Rgg6 Re7 77. Rg4+ Kf5 78. Rga4 e4 79. R6a5+ Kf4 80. Ra6 Re8 81. Rf6+ Ke3 82. Ra3+ Kd4 83. Rd6+ Ke5 84. Raa6 e3 85. Rd7 Ke4 86. Ra4+ Ke5 87. Rda7 Ke6 88. R4a6+ Ke5 89. Ra5+ 1-0",
    B: "1401",
  },
  {
    W: "1318",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 d6 3. Nc3 Nc6 4. d4 Nxd4 5. Nxd4 exd4 6. Qxd4 Be7 7. e5 dxe5 8. Qxe5 Nf6 9. Ne4 O-O 10. Nxf6+ Bxf6 11. Qe4 Re8 12. Qxe8+ Qxe8+ 13. Be2 Bg4 14. f3 Bf5 15. Kf2 Bd4+ 16. Ke1 Bxc2 0-1",
    B: "1516",
  },
  {
    W: "1891",
    T: "180+2",
    P: "1. b3 d6 2. Bb2 e5 3. d3 f5 4. e3 Nf6 5. Nd2 Be6 6. g3 Nbd7 7. Bg2 c6 8. Ne2 Be7 9. h3 O-O 10. a3 d5 11. f4 Bd6 12. Nf3 e4 13. Ne5 Bc7 14. Nxd7 Qxd7 15. g4 fxg4 16. hxg4 Nxg4 17. Ng3 Nxe3 18. Qh5 Nxg2+ 19. Kf2 g6 20. Qh6 Nxf4 21. Ke3 Bb6+ 22. Kd2 d4 23. Nxe4 Qg7 24. Qg5 Nd5 25. Rag1 Bc7 26. Kd1 Bf4 27. Qh4 Ne3+ 28. Ke2 c5 29. Nxc5 Bd5 30. Rh3 Rae8 31. Bc1 Ng2+ 0-1",
    B: "1884",
  },
  {
    W: "1316",
    T: "180+2",
    P: "1. e4 e5 2. Bc4 Nc6 3. Nf3 h6 4. O-O Bc5 5. c3 Nf6 6. d4 exd4 7. e5 Na5 8. exf6 Nxc4 9. fxg7 Rg8 10. Bxh6 dxc3 11. Re1+ Be7 12. Nxc3 Nxb2 13. Qe2 b6 14. Bg5 f6 15. Bxf6 Nc4 16. Bxe7 Qxe7 17. Qxe7# 1-0",
    B: "1222",
  },
  {
    W: "1109",
    T: "180+2",
    P: "1. e4 e5 2. f4 exf4 3. d4 Qe7 4. e5 f6 5. Nf3 fxe5 6. dxe5 d6 7. Bxf4 dxe5 8. Bxe5 Qb4+ 9. c3 Qe4+ 10. Kf2 Nd7 11. Bxc7 Nc5 12. Qd8+ Kf7 13. Ng5+ Kg6 14. Nxe4 Nxe4+ 15. Kg1 Bc5+ 16. Qd4 Bxd4+ 17. cxd4 Ne7 18. Bd3 Kf5 19. Nd2 Ke6 20. Re1 Kd7 21. Rxe4 Kxc7 22. Rxe7+ Bd7 23. Bb5 Rad8 24. Rxg7 Rhe8 25. Bxd7 Re1+ 26. Kf2 Rxh1 27. Bf5+ Kb6 28. Nc4+ Kb5 29. b3 h5 30. Rxb7+ Kc6 31. Na5+ Kd5 32. Rxa7 Rf8 33. h3 Rxf5+ 34. Kg3 Kxd4 35. Nc6+ Kc5 36. Ra5+ Kxc6 37. Rxf5 Ra1 38. a4 Rb1 39. Rf3 Kc5 40. Kh4 Kb4 41. Kxh5 Rg1 42. g4 Rb1 43. g5 Rc1 44. g6 Rc5+ 45. Kh6 Rc7 46. g7 Rc6+ 47. Kh7 Rc5 48. g8=Q Rh5+ 49. Kg6 Rxh3 50. Qb8+ Ka3 51. Qd6+ 1-0",
    B: "1155",
  },
  {
    W: "2106",
    T: "180+2",
    P: "1. d4 Nf6 2. c4 g6 3. e3 Bg7 4. Nc3 O-O 5. Nf3 c5 6. d5 d6 7. h3 Qa5 8. Bd2 Na6 9. a3 Bd7 10. Bd3 Rab8 11. O-O Qd8 12. Rb1 Nc7 13. b4 cxb4 14. axb4 e6 15. e4 b5 16. dxe6 fxe6 17. Ne2 bxc4 18. Bxc4 Nxe4 19. Qc2 d5 20. Ba2 Bb5 21. Rfe1 Rxf3 22. gxf3 Qh4 23. fxe4 Rf8 24. Ng3 Be5 25. Qb3 Bc4 26. Qe3 d4 27. Qh6 Bxg3 28. Qxh4 Bxh4 29. Bxc4 Bxf2+ 30. Kg2 Bxe1 31. Bxe1 Kg7 32. Bg3 Rc8 33. Be5+ Kf7 34. Rf1+ Ke7 35. Bd3 a6 36. Bxd4 Nb5 37. Bc5+ Ke8 38. Rf8+ Kd7 39. Bxb5+ axb5 40. Rf7+ Kc6 41. Rxh7 Ra8 42. Rg7 Ra2+ 43. Kg3 Ra1 44. Rxg6 Rg1+ 45. Bxg1 1-0",
    B: "2082",
  },
  {
    W: "1394",
    T: "180+2",
    P: "1. e4 e5 2. Bc4 Nc6 3. Nc3 Nf6 4. h3 d6 5. d3 g6 6. Bg5 Be7 7. Nd5 Nxd5 8. exd5 Bxg5 9. dxc6 bxc6 10. Qf3 Qd7 11. Bxf7+ Qxf7 12. Qxc6+ Ke7 13. Qxa8 Bd2+ 14. Kxd2 Qxf2+ 15. Ne2 Kd8 16. Rhf1 Qb6 17. b3 e4 18. Qxe4 Re8 19. Qh4+ Kd7 20. Rf7+ Kc6 21. Nd4+ Kb7 22. Qxh7 Qxd4 23. Rxc7+ Kb8 0-1",
    B: "1367",
  },
  {
    W: "1522",
    T: "180+2",
    P: "1. e4 d5 2. exd5 Nf6 3. c4 e6 4. dxe6 Bxe6 5. d4 c6 6. Nf3 Nbd7 7. Be2 Be7 8. O-O O-O 9. h3 h6 10. Qd3 b6 11. Nc3 Bd6 12. d5 cxd5 13. cxd5 Nc5 14. Qc2 Bd7 15. b4 Nb7 16. b5 Rc8 17. Nd4 Be5 18. Nc6 Bxc6 19. bxc6 Nd6 20. Rd1 a6 21. Bxa6 Ra8 22. Bb7 Nxb7 23. cxb7 Rb8 24. Qe2 Bxc3 25. Rb1 Rxb7 26. Qe3 Ba5 27. d6 Re8 28. Qf3 Rd7 29. Qg3 Re6 30. Bxh6 Nh5 31. Qg4 Rxh6 32. Rb5 1-0",
    B: "1554",
  },
  {
    W: "1350",
    T: "180+2",
    P: "1. e4 Nc6 2. Nf3 e5 3. Bc4 h6 4. d4 exd4 5. c3 dxc3 6. Nxc3 Bb4 7. Qb3 Qe7 8. O-O Bxc3 9. Qxc3 d6 10. Re1 Be6 11. Bb5 Qd7 12. Bf4 a6 13. Ba4 b5 14. Bb3 Bxb3 15. axb3 Nf6 16. Rad1 O-O 17. e5 Nh5 18. Qe3 Nxf4 19. Qxf4 Rfe8 20. exd6 Rxe1+ 21. Nxe1 Rd8 22. dxc7 Qxd1 23. Kf1 Rc8 24. g3 Qd7 25. Nc2 Qd1+ 26. Ne1 Qd7 27. Nc2 Qxc7 28. Qxc7 Rxc7 29. Kg2 Ne5 30. Ne3 Nd3 31. Nd1 Rc1 32. Ne3 Nxb2 33. Nd5 Nd3 34. Ne3 Rc3 0-1",
    B: "1337",
  },
  {
    W: "1268",
    T: "180+0",
    P: "1. e4 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qd1 e5 5. Be2 Nf6 6. Nf3 Nxe4 7. Be3 Bb4+ 8. c3 Ba5 9. Bd3 Nf6 10. O-O e4 11. Bxe4 Nxe4 12. Bg5 Nxg5 13. Nxg5 Qxg5 14. Re1+ Ne7 15. Qd6 b6 16. b4 Bb7 17. f3 Bxb4 18. cxb4 Qf6 19. Qxf6 gxf6 20. Nc3 Rg8 21. Rad1 Bxf3 22. Kf2 Bxd1 23. Rxd1 Rc8 24. Ne4 Rc2+ 25. Kf3 Rgxg2 26. Nxf6+ Kf8 27. Nxd7+ Kg8 28. Nf6+ Kg7 29. Nh5+ Kh8 30. Rd8+ Rg8 31. Rxg8+ Kxg8 32. Nf6+ Kg7 0-1",
    B: "1284",
  },
  {
    W: "1609",
    T: "120+1",
    P: "1. d4 d5 2. c4 dxc4 3. e3 Bf5 4. Bxc4 e6 5. Nf3 Nf6 6. Qb3 b6 7. O-O Nbd7 8. Nh4 Be4 9. f3 Bb7 10. Nf5 Be7 11. Nxg7+ Kf8 12. Nxe6+ fxe6 13. Bxe6 Kg7 14. Nc3 Nf8 15. e4 Nxe6 16. Qxe6 Qd6 17. Qh3 Qxd4+ 18. Kh1 Rad8 19. Bh6+ Kf7 20. e5 Qxe5 21. Rfe1 Qd6 22. Rad1 Bd5 23. Nxd5 Nxd5 24. Qf5+ Qf6 25. Qc2 Nb4 26. Qc4+ Nd5 27. Bc1 c6 28. Re4 b5 29. Qc2 Kf8 30. Rxd5 Rxd5 31. Rf4 Qxf4 32. Bxf4 Ke8 33. h3 Kd7 34. Qe2 Rd8 35. Kh2 Ke8 36. Qf2 Rd2 37. Qg3 Rxb2 38. Bc7 Rdd2 39. Qg8+ Kd7 0-1",
    B: "1617",
  },
  {
    W: "2180",
    T: "120+1",
    P: "1. d4 d6 2. e4 e6 3. Nf3 b6 4. Bb5+ c6 5. Bd3 g6 6. O-O f6 7. Be3 Nh6 8. Nc3 Bg7 9. d5 e5 10. dxc6 Nxc6 11. Bc4 Na5 12. Bd5 Bb7 13. b4 Bxd5 14. Qxd5 Rc8 15. Qe6+ Kf8 16. Nd5 Nc4 17. Nxf6 Ng4 18. Nd7+ Qxd7 19. Qxd7 Re8 20. Qxg4 1-0",
    B: "1851",
  },
  {
    W: "1931",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O d6 5. Re1 Bg4 6. Bxc6+ bxc6 7. d4 exd4 8. Qxd4 Bxf3 9. gxf3 Be7 10. Bf4 O-O 11. Nc3 Rb8 12. b3 a6 13. Rad1 Nh5 14. Bg3 Bf6 15. Qd3 Qe7 16. Ne2 g6 17. Nf4 Nxf4 18. Bxf4 Rb5 19. c4 Rh5 20. Bg3 Be5 21. f4 Bf6 22. e5 dxe5 23. fxe5 Bh4 24. Qd7 Qg5 25. e6 Bxg3 26. exf7+ Rxf7 27. Qe8+ Kg7 28. hxg3 Qf6 29. Re2 Qf3 30. Qe5+ Rxe5 0-1",
    B: "1986",
  },
  {
    W: "1206",
    T: "120+1",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qe5+ 4. Be2 Bf5 5. Nf3 Qa5 6. Nd4 c6 7. Nxf5 Qxf5 8. O-O e6 9. Bd3 Qf4 10. Ne2 Qc7 11. Be4 Bd6 12. Ng3 Nd7 13. d3 Ngf6 14. Bg5 O-O-O 15. Bxf6 Nxf6 16. Re1 g5 17. Qd2 Rdg8 18. Qe3 h5 19. Qxa7 h4 20. Qa8+ Kd7 21. Re3 hxg3 22. fxg3 Rxa8 23. Rae1 Rxa2 24. Bf3 Rxb2 25. Bg4 0-1",
    B: "1260",
  },
  {
    W: "1441",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nf6 3. Bc4 d5 4. exd5 Nxd5 5. Nxe5 Qe7 6. d4 f6 7. Qe2 fxe5 8. Qxe5 Qxe5+ 9. dxe5 Nb6 10. Bb3 Bc5 11. O-O Nc6 12. e6 Nd4 13. Re1 Nxb3 14. axb3 Rf8 15. Bg5 Bxf2+ 16. Kf1 Bxe1+ 17. Kxe1 Bxe6 18. Nc3 h6 19. Bf4 O-O-O 20. Rd1 Rxd1+ 21. Kxd1 Rd8+ 22. Kc1 Bf5 23. Ne2 g5 24. Be3 Rd6 25. Bc5 Rc6 26. Bf8 Rxc2+ 27. Kb1 Rxe2+ 28. Kc1 Rxg2 29. Bxh6 g4 30. Bf4 Nd5 31. Bg3 Kd7 32. Kd1 Rxb2 33. Ke1 Rxb3 34. Kf2 a5 35. h4 gxh3 36. Kg1 a4 37. Bf4 a3 38. Bc1 a2 39. Kh2 a1=Q 40. Bg5 Qa2+ 41. Kg1 Rb2 42. Bf6 Rb1# 0-1",
    B: "1443",
  },
  {
    W: "1607",
    T: "120+1",
    P: "1. d4 d5 2. c4 c6 3. c5 b6 4. cxb6 axb6 5. Nf3 Ba6 6. Ne5 Qd6 7. Nd3 e6 8. e3 Nf6 9. Be2 Ne4 10. O-O Be7 11. Nd2 O-O 12. f3 Ng5 13. Nf4 h6 14. a3 c5 15. Bxa6 Nxa6 16. dxc5 bxc5 17. Nb3 d4 18. exd4 cxd4 19. Qxd4 Qb8 20. Nh5 Bf6 21. Nxf6+ Kh8 22. Nh5 f6 23. Bxg5 hxg5 24. Ng3 Qxb3 25. f4 Rad8 26. Qa7 Nb8 27. fxg5 fxg5 28. Rxf8+ Rxf8 29. Qc5 Rg8 30. Qxg5 Rc8 31. Qh5+ Kg8 32. h3 Qxg3 33. Rf1 Qe3+ 34. Kh1 Rc1 35. Qf7+ Kh7 36. Qh5+ Kg8 37. Qf7+ Kh7 38. Qh5+ Kg8 1/2-1/2",
    B: "1574",
  },
  {
    W: "1848",
    T: "120+1",
    P: "1. d4 d5 2. Bf4 Nf6 3. e3 Bf5 4. c4 c6 5. Nc3 e6 6. Nf3 Bb4 7. Rc1 O-O 8. h3 h6 9. cxd5 cxd5 10. Be2 Nc6 11. O-O a6 12. Qb3 Rc8 13. Ne5 Bxc3 14. Nxc6 Rxc6 15. Rxc3 Qc8 16. Rfc1 Ne4 17. Rxc6 bxc6 18. Qa4 Qd8 19. Rxc6 Qh4 20. Bg3 Nxg3 21. fxg3 Qxg3 22. Qd1 Qxe3+ 23. Kh1 Be4 24. Bf3 Bxf3 25. gxf3 Rb8 26. b3 Rb4 27. Rc8+ Kh7 28. Qb1+ g6 29. Kg2 Rxd4 30. Rc7 Rd2+ 31. Kg3 Qg5# 0-1",
    B: "1841",
  },
  {
    W: "1683",
    T: "120+1",
    P: "1. e4 e5 2. c4 Nf6 3. Nc3 Bb4 4. d3 Nc6 5. Be3 d6 6. Nf3 h6 7. a3 Bxc3+ 8. bxc3 Bg4 9. Be2 O-O 10. h3 Be6 11. Qd2 Kh8 12. g4 Nh7 13. Rb1 b6 14. Kd1 g5 15. h4 f6 16. hxg5 hxg5 17. Bxg5 fxg5 18. Nxg5 Qe7 19. Rxh7+ Qxh7 20. Nxh7 Kxh7 21. Kc2 Rf4 22. Rh1+ Kg7 23. g5 Rxf2 24. g6 Rf4 25. Qe1 Rh8 26. Rxh8 Kxh8 27. Qh1+ Kg7 28. Qh7+ Kf6 29. g7 Ne7 30. Qh6+ Kf7 31. Bh5+ 1-0",
    B: "1624",
  },
  {
    W: "1773",
    T: "120+1",
    P: "1. d4 Nf6 2. c4 e5 3. Nc3 exd4 4. Qxd4 Nc6 5. Qd1 Bb4 6. Nf3 Bxc3+ 7. bxc3 O-O 8. Bg5 h6 9. Bh4 g5 10. Bg3 d6 11. e3 Ne4 12. Qc2 f5 13. Bd3 Qe7 14. O-O Nxg3 15. hxg3 Ne5 16. e4 Nxd3 17. Qxd3 fxe4 18. Qe3 Bf5 19. Nd4 Bg6 20. Rfe1 Rae8 21. Rab1 c6 22. Nb3 Qf6 23. Nd4 c5 24. Nb5 Re7 25. Nxa7 Kh7 26. Nb5 h5 27. Rbd1 Rd8 28. a3 h4 29. gxh4 gxh4 30. Rd5 h3 31. gxh3 Re6 32. Rg5 Rf8 33. Qg3 Re5 34. Qh4+ Kg7 35. Re3 Qf4 36. Qxf4 Rxf4 37. Reg3 Rxg5 38. Rxg5 Kf6 39. Rg3 Ke5 40. Nc7 Rf7 41. Nd5 Bh5 42. h4 1-0",
    B: "1758",
  },
  {
    W: "1551",
    T: "120+1",
    P: "1. e4 e5 2. Nc3 Nc6 3. Bc4 Nf6 4. Nge2 Bc5 5. d3 O-O 6. Be3 Bb6 7. Qd2 Ng4 8. h3 Nxe3 9. fxe3 Qh4+ 10. g3 Qg5 11. Nd5 Ba5 12. c3 Ne7 13. O-O-O Nxd5 14. exd5 Bb6 15. d4 exd4 16. Nxd4 Bxd4 17. Qxd4 a6 18. Rdg1 b5 19. Bd3 Bb7 20. c4 bxc4 21. Bxc4 c6 22. h4 Qg6 23. Bd3 Qh6 24. g4 cxd5 25. h5 Rfc8+ 26. Kb1 Re8 27. Rg3 Qd6 28. Rhh3 Rac8 29. g5 Qc5 30. Qxc5 Rxc5 31. g6 h6 32. gxf7+ Kxf7 33. Rf3+ Kg8 34. Rhg3 d4 35. e4 Bxe4 36. Bxe4 Rxe4 37. Rg6 Rxh5 38. Rd6 Rh1+ 39. Kc2 Re7 40. Kd3 Rh2 41. Kxd4 Rxb2 42. Kc5 Rb5+ 43. Kc4 Rb6 44. Rc6 Rxc6+ 45. Kd5 Rf6 46. Rc3 Rf5+ 47. Kd4 Rf6 48. Rc8+ Kh7 49. Ra8 Rc6 50. Kd5 h5 51. a4 h4 52. Rb8 h3 53. Rb1 Re2 54. Rh1 Re3 55. Kd4 Ra3 56. Kd5 Rxa4 57. Ke5 Rc2 58. Kf5 Ra3 59. Kg5 Rf2 60. Kg4 Rd2 61. Rxh3+ Rxh3 62. Kxh3 a5 63. Kg3 a4 64. Kf3 a3 65. Ke3 Rd5 66. Ke4 Rd2 67. Ke3 Rh2 68. Kd3 a2 69. Kc3 a1=Q+ 70. Kb3 Qb2+ 71. Kc4 g5 72. Kd5 g4 73. Kd6 g3 74. Kxd7 g2 75. Ke7 g1=Q 76. Kf7 Qgb1 77. Ke6 Qe2+ 78. Kd5 Qbd3+ 79. Kc6 Qec2+ 80. Kb6 Qdb3+ 0-1",
    B: "1474",
  },
  {
    W: "1561",
    T: "15+0",
    P: "1. e4 g6 2. Bc4 Bg7 3. Nc3 e6 4. Nf3 Qe7 5. d4 d5 6. Bb3 dxe4 7. O-O Bh6 8. Ne5 Bxc1 9. Qxc1 f6 10. Qe1 fxe5 11. dxe5 Nf6 12. Nxe4 Nd5 13. Nf6+ Nxf6 14. exf6 Qxf6 15. f4 Qe7 16. f5 Rf8 17. fxe6 Rxf1+ 18. Qxf1 Bxe6 19. Qf2 Bxb3 20. Qe3 Be6 21. Qxe6 Nc6 22. Re1 Qxe6 23. Rxe6+ Kd7 24. Re4 Re8 25. Re3 Rxe3 26. Kf2 Re5 27. Kg3 Ra5 28. Kf3 Rxa2 29. Kf2 Rxb2 30. Ke3 Rxc2 31. Ke4 Rc5 0-1",
    B: "1824",
  },
  {
    W: "1774",
    T: "300+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. Bc4 e6 4. c3 b6 5. Bb3 Bb7 6. d3 Ne7 7. h3 a5 8. O-O a4 9. Bxa4 O-O 10. Bb3 c5 11. Re1 Nbc6 12. Nbd2 d5 13. Nf1 Na5 14. Bc2 b5 15. d4 c4 16. e5 Nac6 17. Be3 b4 18. Ng3 Qb6 19. Rb1 Rxa2 20. Ra1 b3 21. Rxa2 bxa2 22. Ba4 Qxb2 23. Qa1 Qxa1 24. Rxa1 Ra8 25. Rxa2 Nxe5 26. Ne4 Nxf3+ 27. gxf3 dxe4 28. fxe4 Bc6 29. Bb3 cxb3 30. Rxa8+ Bxa8 31. Bc1 Bxe4 0-1",
    B: "1801",
  },
  {
    W: "1407",
    T: "300+0",
    P: "1. e4 d6 2. Bc4 g6 3. Qf3 Nf6 4. Nh3 Bg7 5. d3 O-O 6. Bg5 Bxh3 7. Qxh3 e5 8. Nd2 Nc6 9. Nf3 a6 10. Qg3 b5 11. Bb3 Qd7 12. h4 h5 13. Qh3 Ng4 14. O-O-O Nxf2 15. Qxd7 Nd4 16. Qxc7 Nxh1 17. Rxh1 Ne2+ 18. Kd1 Ng3 19. Re1 Rac8 20. Qxd6 Kh7 21. Nxe5 f6 22. Bxf6 Bxf6 23. Qd7+ Kh6 24. Nf7+ Kh7 25. Nd6+ Kh8 26. Nxc8 Bxb2 27. Ne7 Rf7 28. Nxg6+ Kg7 1-0",
    B: "1317",
  },
  {
    W: "993",
    T: "300+0",
    P: "1. g3 d5 2. d4 Nc6 3. Bg2 Nxd4 4. Qxd4 e6 5. Nf3 b6 6. Bg5 Ne7 7. O-O h6 8. Bf4 Nf5 9. Qa4+ Bd7 10. Qb3 Bd6 11. Bxd6 Nxd6 12. Qc3 Ne4 13. Qxg7 Ke7 14. Ne5 Rf8 15. Ng6+ Kd6 16. Nxf8 Qxf8 17. Qd4 Qe8 18. Bxe4 e5 19. Qxd5+ Ke7 20. Nc3 Be6 21. Qxe5 Rd8 22. Nd5+ Rxd5 23. Bxd5 Qb5 24. Rad1 f6 25. Qxe6+ Kf8 26. Qf7# 1-0",
    B: "1082",
  },
  {
    W: "740",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. c3 a6 4. d4 Nc6 5. d5 Na5 6. b4 Nc4 7. Bxc4 Bd7 8. b5 axb5 9. Bd3 Be7 10. Na3 Ra5 11. Nxb5 Bxb5 12. c4 Ba4 13. Bc2 Qd7 14. Bd2 Ra6 15. Bxa4 Rxa4 16. Ng5 Rxc4 17. Rc1 Ra4 18. Qb3 h6 19. Nf3 Nf6 20. Qxb7 Rxa2 21. Qb8+ Bd8 22. O-O Nxe4 23. Bg5 Nxg5 24. Nxg5 hxg5 25. f4 gxf4 26. g3 fxg3 27. Rxf7 Kxf7 28. Qxd8 Qxd8 29. Rxc7+ Qxc7 0-1",
    B: "832",
  },
  {
    W: "1192",
    T: "300+0",
    P: "1. e4 e5 2. Bc4 Qh4 3. Nf3 Qxe4+ 4. Be2 Bc5 5. O-O Bd6 6. Re1 Qf4 7. d4 exd4 8. Bxf4 Bxf4 9. Nxd4 Nc6 10. Nxc6 dxc6 11. Bg4+ Be6 12. Bxe6 fxe6 13. Rxe6+ Kf7 14. Qd7+ Kf8 15. Nc3 Nf6 16. Rxf6+ gxf6 17. Qf5 Bd6 18. Qxf6+ Ke8 19. Qxh8+ Bf8 20. Qxh7 b5 21. Re1+ Kd8 22. Qh4+ Kc8 23. Re8+ Kb7 24. Rxa8 Kxa8 25. Qd8+ Kb7 26. Qxf8 1-0",
    B: "1166",
  },
  {
    W: "1282",
    T: "300+0",
    P: "1. d4 d5 2. Bf4 Nc6 3. Nf3 e6 4. e3 Nf6 5. Bd3 Bb4+ 6. c3 Be7 7. Qc2 O-O 8. e4 a6 9. e5 Ne4 10. Bxe4 dxe4 11. Qxe4 b5 12. Nbd2 Bb7 13. Qd3 Na5 14. O-O-O Bd5 15. b3 b4 16. cxb4 Nc6 17. h4 Nxb4 18. Qe2 Nxa2+ 19. Kb2 Nb4 20. g4 a5 21. g5 a4 22. Qe3 Qd7 23. h5 Na6 24. Qd3 a3+ 25. Ka2 Nb4+ 26. Kb1 Nxd3 0-1",
    B: "1281",
  },
  {
    W: "1105",
    T: "300+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d4 e6 5. Nf3 Nc6 6. Bb5 Bd7 7. O-O Nge7 8. Ne5 a6 9. Bxc6 Nxc6 10. Nxd7 Qxd7 11. Bf4 Nxd4 12. Re1 Bd6 13. Bxd6 Qxd6 14. Ne4 Qd5 15. c3 Rd8 16. cxd4 O-O 17. Rc1 f5 18. Nc3 Qxd4 19. Qxd4 Rxd4 20. Rxe6 Rfd8 21. h3 Kf7 22. Re5 Rf4 23. Rc5 Rd2 24. Rxc7+ Kg6 25. Rxb7 Rfxf2 26. Rb6+ Kg5 27. g4 Rg2+ 28. Kf1 fxg4 29. hxg4 Kxg4 30. Ne4 Kf3 31. Nxd2+ Rxd2 32. Rxa6 h5 33. b4 g5 34. Rc3+ Ke4 35. Rc4+ Kd3 36. Rd6+ Kxc4 37. Rxd2 Kxb4 38. Rd5 Ka3 39. Rxg5 Kxa2 40. Rxh5 Kb3 41. Rh3+ Kc4 42. Ke2 Kd5 43. Ke3 Ke6 44. Rh5 Kf6 45. Kf4 Kg6 46. Ra5 Kf7 47. Ra6 Ke7 48. Kf5 Kd7 49. Rb6 Kc7 50. Rh6 Kb7 51. Ke5 Kc7 52. Kd5 Kb7 53. Kc5 Kc8 54. Rh7 Kd8 55. Kc6 Ke8 56. Rg7 Kf8 57. Ra7 Kg8 58. Kd6 Kh8 59. Ke6 Kg8 60. Kf6 Kf8 61. Ra8# 1-0",
    B: "1111",
  },
  {
    W: "1491",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. Nc3 d6 5. Na4 Bb6 6. Nxb6 axb6 7. a3 Bg4 8. h3 Bxf3 9. Qxf3 Nf6 10. c3 O-O 11. O-O Qd7 12. d3 Ra5 13. b4 Raa8 14. Qg3 Rad8 15. Bh6 Nh5 16. Qg5 g6 17. Bxf8 Rxf8 18. g4 Nf4 19. h4 Nh3+ 20. Kg2 Nxg5 21. hxg5 0-1",
    B: "1479",
  },
  {
    W: "1289",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. Bxc6 dxc6 6. Re1 Bf5 7. d3 Ng5 8. Rxe5+ Be7 9. Nxg5 O-O 10. Rxf5 c5 11. Nf3 c4 12. Re5 b6 13. Re1 cxd3 14. Qxd3 Bc5 15. Qxd8 Raxd8 16. Be3 Bd6 17. Nc3 c6 18. Ne4 Be5 19. Nxe5 Rfe8 20. Nxc6 Rd6 21. Nxd6 Re6 22. Bf4 Rxe1+ 23. Rxe1 Kf8 24. Re8# 1-0",
    B: "1289",
  },
  {
    W: "1730",
    T: "300+0",
    P: "1. e4 d6 2. d4 g6 3. Bd3 Bg7 4. Be3 b6 5. c3 Bb7 6. f3 Nd7 7. Nd2 e5 8. d5 Nc5 9. Bc2 f5 10. b4 Nd7 11. Ne2 f4 12. Bf2 Ngf6 13. O-O g5 14. c4 g4 15. c5 bxc5 16. bxc5 Nxc5 17. Bxc5 dxc5 18. Nb3 Qd6 19. Bd3 gxf3 20. gxf3 O-O-O 21. Na5 Ba8 22. Qb3 Rhg8 23. Kh1 Nxd5 24. Rab1 Nb6 25. Nc4 Qxd3 26. Nxb6+ cxb6 27. Qe6+ Qd7 0-1",
    B: "1688",
  },
  {
    W: "1217",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3 Na5 5. d3 Nxc4 6. dxc4 d6 7. Nd5 Nxd5 8. cxd5 Be7 9. h3 h6 10. g4 Bd7 11. Be3 c5 12. c4 Qa5+ 13. Bd2 Qb6 14. b3 O-O 15. Qe2 f6 16. Nh4 Kh7 17. f3 Rac8 18. Qf2 Rf7 19. O-O-O Rff8 20. Kb1 Rf7 21. Rdg1 Rff8 22. Rg2 Rf7 23. Rhg1 Rff8 24. Be3 Rf7 25. Bd2 Rff8 26. Rg3 Rf7 27. R1g2 Rff8 28. Nf5 Bxf5 29. gxf5 Rg8 30. Qg1 g5 31. h4 Rg7 32. hxg5 fxg5 33. Rh3 Rcg8 34. Rgh2 Rf8 35. Rxh6+ Kg8 36. Rh8+ Kf7 37. f4 exf4 38. Qg4 Rxh8 39. Rxh8 Bf6 40. Qh5+ Ke7 41. Qe8# 1-0",
    B: "1233",
  },
  {
    W: "2222",
    T: "300+0",
    P: "1. d4 e6 2. e4 b6 3. c4 Bb7 4. Nc3 Bb4 5. f3 Ne7 6. Be3 Ng6 7. Qd2 c5 8. a3 Bxc3 9. bxc3 cxd4 10. cxd4 O-O 11. Bd3 e5 12. Ne2 exd4 13. Nxd4 Nc6 14. O-O Nge5 15. Be2 Qe7 16. Rfd1 Rad8 17. Nf5 Nxc4 18. Nxe7+ Nxe7 19. Bxc4 d5 20. Bb3 Rc8 21. exd5 Rfd8 22. Bg5 f6 23. d6+ Kh8 24. dxe7 Rxd2 25. Rxd2 Bc6 26. Rd8+ 1-0",
    B: "2012",
  },
  {
    W: "1505",
    T: "300+0",
    P: "1. d4 Nf6 2. c4 e6 3. g3 c6 4. Bg2 d5 5. cxd5 cxd5 6. Nc3 Nc6 7. Nf3 Be7 8. Bg5 Qb6 9. Na4 Qb4+ 10. Nc3 Qxb2 11. Rb1 Qxc3+ 12. Bd2 Qa3 13. Qb3 Qxb3 14. axb3 a6 15. Bg5 b5 16. Ne5 Nxe5 17. dxe5 Nd7 18. Bxe7 Kxe7 19. f4 Rd8 20. Rc1 Bb7 21. O-O Rac8 22. b4 Nb6 23. Rc5 Nc4 24. Rc1 Rxc5 25. bxc5 Rc8 26. Kf2 Rc6 27. e4 Rxc5 28. exd5 Bxd5 29. Bxd5 exd5 30. Ke2 Ke6 31. Kd3 Rc8 32. Kd4 Ra8 33. Kc5 Rc8+ 34. Kb4 d4 35. Kb3 d3 36. Kc3 d2 37. Rh1 Rd8 38. Kc2 0-1",
    B: "1651",
  },
  {
    W: "1821",
    T: "300+0",
    P: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. f3 exf3 5. Nxf3 Bg4 6. Bc4 e6 7. O-O Nf6 8. Be3 Be7 9. Qe1 O-O 10. Ne5 Bh5 11. h3 Bg6 12. Qg3 Nbd7 13. Rad1 Qc7 14. Bd3 Nxe5 15. dxe5 Nd5 16. Nxd5 cxd5 17. h4 Bc5 18. Kh2 Bxe3 19. Qxe3 Bxd3 20. cxd3 b6 21. d4 Rac8 22. h5 Qc2 23. Rd2 Qe4 24. Qxe4 dxe4 25. Re1 g6 26. Rxe4 Kg7 27. Rh4 h6 28. hxg6 fxg6 29. Kg3 Rf5 30. Rf4 Rd8 31. Rxf5 gxf5 32. Kh4 Kg6 33. g4 Rf8 34. gxf5+ Rxf5 35. d5 Rxe5 36. d6 Rd5 37. Rxd5 exd5 38. d7 d4 39. d8=Q d3 40. Qxd3+ Kf6 41. Qa6 1-0",
    B: "1954",
  },
  {
    W: "1541",
    T: "60+0",
    P: "1. d4 a6 2. Bf4 h6 3. h3 g5 4. Bg3 h5 5. e3 d6 6. Be2 Bd7 7. Bxh5 Bc6 8. Bf3 Bxf3 9. Nxf3 f6 10. Nbd2 Bh6 11. Qe2 g4 12. hxg4 Nc6 13. g5 fxg5 14. Nxg5 Bg7 15. Rxh8 Bxh8 16. Ne6 Qd7 17. Qh5# 1-0",
    B: "1495",
  },
  {
    W: "1546",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Qf6 3. Bc4 Be7 4. O-O d6 5. d4 Qg6 6. dxe5 Bh3 7. g3 Bxf1 8. Bxf1 h5 9. exd6 cxd6 10. e5 h4 11. exd6 Bxd6 12. Qe1+ Be7 13. Bb5+ Nd7 14. Ng5 hxg3 15. fxg3 O-O-O 16. Bxd7+ Rxd7 17. Qc3+ Rc7 18. Qa5 a6 0-1",
    B: "1798",
  },
  {
    W: "1310",
    T: "300+0",
    P: "1. d4 d5 2. c4 Nf6 3. Nc3 dxc4 4. e3 e6 5. Bxc4 Nc6 6. Nf3 Bb4 7. Qc2 O-O 8. Bd3 h6 9. O-O Bxc3 10. Qxc3 Qd5 11. Bc4 Qf5 12. Re1 Re8 13. e4 Nxe4 14. Qe3 Bd7 15. Qxe4 Qa5 16. Bd2 Qh5 17. Bd3 f5 18. Qf4 e5 19. Nxe5 Nxe5 20. dxe5 c6 21. Bc4+ Be6 22. Bxe6+ Rxe6 23. Qc4 Qf7 24. f4 g5 25. fxg5 hxg5 26. Bxg5 Rae8 27. Qh4 Rxe5 28. Rxe5 Rxe5 29. Bf4 Re4 30. Qg5+ Qg7 31. Qd8+ Kh7 32. Qh4+ Kg8 33. Qd8+ Kh7 34. Rf1 Qxb2 35. Qh4+ Kg7 36. Qg5+ Kf7 37. Qxf5+ Kg8 38. Qxe4 Qb6+ 39. Be3 Qc7 40. Qe8+ Kh7 41. Rf7+ Qxf7 42. Qxf7+ Kh8 43. Bd4# 1-0",
    B: "1338",
  },
  {
    W: "1556",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. c3 Nf6 5. Qb3 Nxe4 6. Bxf7+ Ke7 7. d3 Nd6 8. Ng5 hxg5 9. Bxg5# 1-0",
    B: "1445",
  },
  {
    W: "1669",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. d3 d6 6. h3 Be7 7. O-O O-O 8. c3 Bd7 9. Re1 b5 10. Bc2 Rb8 11. Nbd2 b4 12. Nf1 bxc3 13. bxc3 d5 14. exd5 Nxd5 15. Bd2 f6 16. Rb1 Ba3 17. Rb3 Rxb3 18. axb3 Bb2 19. c4 Nc3 20. Bxc3 Bxc3 21. Re2 Nd4 22. Nxd4 exd4 23. Qb1 Re8 24. Rxe8+ Qxe8 25. Ng3 f5 26. Kf1 f4 27. Ne4 Bf5 28. Nxc3 dxc3 29. d4 Bxc2 30. Qxc2 Qf7 31. Qxc3 c6 32. Qa5 Qb7 33. Qd8+ Kf7 34. d5 cxd5 35. Qxd5+ Qxd5 36. cxd5 Ke7 37. Ke2 Kd6 38. Kd3 Kxd5 39. f3 a5 40. h4 Kc5 41. Kc3 Kb5 42. h5 g6 43. hxg6 hxg6 44. Kb2 Kb4 45. Kc2 g5 46. Kb2 Kc5 47. Kc3 Kd5 48. Kd3 Ke5 49. Kc4 Kf5 50. Kd5 g4 51. fxg4+ Kxg4 52. Ke4 Kg3 53. Kf5 Kxg2 54. Kxf4 Kf2 55. Ke4 Ke2 56. Kd4 Kd2 57. Kc4 Kc2 58. Kb5 Kxb3 59. Kxa5 1/2-1/2",
    B: "1601",
  },
  {
    W: "1266",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bb5 e5 4. Nc3 Nf6 5. Bxc6 dxc6 6. Nxe5 Bd6 7. Nf3 O-O 8. O-O Bg4 9. d3 Be5 10. Bd2 Bxc3 11. Bxc3 Qe7 12. h3 Bh5 13. a3 Rad8 14. b4 c4 15. d4 b6 16. e5 Ne4 17. Qe1 f5 18. Rd1 g5 19. d5 cxd5 20. Rxd5 Rxd5 21. Qe2 Nxc3 22. Qxc4 Bxf3 23. gxf3 Qxe5 24. Kg2 g4 25. hxg4 fxg4 26. fxg4 Ne2 27. Re1 Re8 28. f4 Qe4+ 29. Qxe4 Rxe4 30. Kf3 Rdd4 31. Rxe2 Rxe2 32. Kxe2 Rxf4 33. Ke3 Rf7 34. c4 Rg7 35. Kf4 h6 36. Kg3 Rd7 37. a4 Rd3+ 38. Kh4 Rb3 39. b5 Ra3 40. a5 bxa5 41. c5 Rc3 42. Kh5 Rxc5+ 43. Kxh6 Rxb5 44. g5 Rb7 45. g6 a4 46. Kg5 a3 47. Kf6 a2 48. g7 a1=Q+ 0-1",
    B: "1307",
  },
  {
    W: "1568",
    T: "180+0",
    P: "1. e4 e5 2. Bc4 Nc6 3. Nf3 Nf6 4. d3 Bc5 5. O-O O-O 6. Be3 Na5 7. Nc3 Bxe3 8. fxe3 Nxc4 9. dxc4 Re8 10. Nd5 b6 11. Ng5 h6 12. Nxf7 Kxf7 13. Qh5+ Kg8 14. Nxf6+ gxf6 15. Qg6+ Kh8 16. Qxh6+ Kg8 17. Qg6+ Kf8 18. Rxf6+ Ke7 19. Qg7# 1-0",
    B: "1457",
  },
  {
    W: "1205",
    T: "180+0",
    P: "1. d4 d5 2. Bf4 Nf6 3. Nf3 e6 4. e3 Bd6 5. Bxd6 Qxd6 6. c3 O-O 7. Nbd2 Nbd7 8. Bd3 Ng4 9. h3 Nxf2 10. Kxf2 Nf6 11. Ng5 g6 12. Ndf3 Ne4+ 13. Bxe4 dxe4 14. Nxe4 Qc6 15. Qd3 f5 16. Ne5 Qb6 17. Ng5 Qxb2+ 18. Kg3 b6 19. Qc4 Bb7 20. Nxe6 f4+ 21. exf4 Rxf4 22. Nxf4+ Kg7 23. Qf7+ Kh8 24. Nfxg6+ hxg6 25. Nxg6# 1-0",
    B: "1193",
  },
  {
    W: "1861",
    T: "180+0",
    P: "1. c4 e5 2. Nc3 d6 3. g3 c6 4. Bg2 Be6 5. d3 Qc8 6. Nf3 Bh3 7. Bxh3 Qxh3 8. Bd2 h6 9. Ne4 Qg2 10. Rg1 Qh3 11. Qb3 b6 12. O-O-O Qd7 13. a3 Be7 14. Qc3 Nf6 15. Nxf6+ Bxf6 16. e4 a5 17. Be3 a4 18. Bxb6 Na6 19. Nd2 c5 20. f4 exf4 21. gxf4 Bxc3 0-1",
    B: "1799",
  },
  {
    W: "1762",
    T: "180+0",
    P: "1. d4 c5 2. e3 Nf6 3. c3 g6 4. Bd3 Bg7 5. f4 O-O 6. Nf3 Nc6 7. O-O Qc7 8. Nbd2 b6 9. Ne5 Bb7 10. Qe1 Nd5 11. e4 Nxe5 12. fxe5 Nxc3 13. bxc3 cxd4 14. cxd4 Rac8 15. Bb2 d6 16. exd6 Qxd6 17. e5 Qd5 18. Be4 Qb5 19. Bxb7 Rc2 20. Ba3 Qd3 21. Be4 Qxd4+ 22. Kh1 Rxd2 23. Rd1 Rxd1 24. Qxd1 Qxe4 25. Bxe7 Re8 26. Bf6 Bxf6 27. exf6 h5 28. h3 a5 29. Qd2 Qe3 30. Qd7 g5 31. Qf5 h4 32. Rf3 Qe5 33. Qg4 Kh7 34. Qh5+ Kg8 35. Qh6 Qe1+ 36. Kh2 Qe5+ 37. Kg1 Qc5+ 38. Kh2 Qc7+ 39. Kg1 Re1+ 40. Kf2 Qg3+ 41. Rxg3 hxg3+ 42. Kxe1 1-0",
    B: "1766",
  },
  {
    W: "1255",
    T: "180+0",
    P: "1. Nf3 Nf6 2. Nc3 e6 3. d3 d5 4. Bg5 h6 5. Bxf6 Qxf6 6. e4 dxe4 7. Nxe4 Qxb2 8. Nd4 Qxd4 9. c3 Qe5 10. Qd2 Nc6 11. f4 Qh5 12. Be2 Qh4+ 13. Ng3 Bd6 14. O-O-O Bxf4 15. Qxf4 Qxf4+ 16. Kc2 b5 17. Rhf1 Qa4+ 18. Kd2 Qxa2+ 19. Ke3 Bb7 20. Bf3 Qd5 21. Bxd5 exd5 22. Kd2 d4 23. Rde1+ Ne7 24. cxd4 1-0",
    B: "1237",
  },
  {
    W: "1054",
    T: "180+0",
    P: "1. e4 e5 2. d3 Nc6 3. Nf3 Bd6 4. Nc3 g5 5. Be2 Nf6 6. Bxg5 Ke7 7. Bh4 Bb4 8. Qd2 d5 9. a3 dxe4 10. Bxf6+ Kxf6 11. O-O Bg4 12. axb4 Nxb4 13. Nb5 Nxd3 14. cxd3 exd3 15. Bxd3 Bf5 16. Qg5+ Ke6 17. Qxf5+ Ke7 18. Qxe5+ Kd7 19. Bf5+ Kc6 20. Nbd4+ Kb6 21. Rfd1 Rg8 22. Bxh7 Rg4 23. Nb3 Qg5 24. Nxg5 Rxg2+ 25. Kxg2 Kc6 26. Qc5# 1-0",
    B: "1043",
  },
  {
    W: "1648",
    T: "180+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 c6 5. Nc3 f5 6. Bf4 Nf6 7. Bd3 Ne4 8. Qe2 Bd6 9. Bxd6 Qxd6 10. Ne5 Nd7 11. Nxd7 Bxd7 12. Bxe4 fxe4 13. O-O-O O-O-O 14. f3 exf3 15. Qxf3 Qh6+ 16. Kb1 Qg6 17. Qd3 Bf5 18. Qd2 Rde8 19. Rhe1 Kd7 20. Rxe8 Rxe8 21. h3 h6 22. g4 Be6 23. Re1 Bf7 24. Rxe8 Bxe8 25. Ne2 h5 26. gxh5 Qxh5 27. Nf4 Qf5 28. Kc1 g5 29. Nd3 Qf1+ 30. Qe1 Qxe1+ 31. Nxe1 Ke6 32. Nf3 Kf5 33. Kd2 Bh5 34. Ke3 Bxf3 35. Kxf3 b6 36. c3 a5 37. a4 b5 38. axb5 cxb5 39. b3 a4 40. bxa4 bxa4 41. Ke2 a3 42. Kd1 a2 43. Kd2 a1=Q 44. Kd3 Qd1+ 45. Ke3 Qe1+ 46. Kf3 0-1",
    B: "1653",
  },
  {
    W: "1356",
    T: "180+0",
    P: "1. e4 Nf6 2. e5 Ng8 3. d4 Nc6 4. d5 Nxe5 5. Kd2 b6 6. Ke3 Bb7 7. Kf4 d6 8. Kf5 Bxd5 9. Qxd5 g6+ 10. Ke4 Nf6+ 11. Kd4 c5+ 12. Ke3 Nxd5+ 13. Ke4 Nf6+ 14. Kf4 Bg7 15. Kg5 O-O 16. Nd2 Nh5 17. Be2 Bf6+ 18. Kh6 d5 19. Bxh5 gxh5 20. Kxh5 Qd7 21. Ne4 Qg4+ 22. Kh6 Qg6# 0-1",
    B: "1395",
  },
  {
    W: "1741",
    T: "180+0",
    P: "1. e4 c6 2. Nf3 d5 3. e5 Bg4 4. d4 e6 5. h3 Bh5 6. Be2 c5 7. c3 Nc6 8. Bb5 Qb6 9. Bxc6+ bxc6 10. O-O cxd4 11. cxd4 c5 12. dxc5 Bxc5 13. Nc3 Ne7 14. Na4 Qb5 15. Nxc5 Qxc5 16. b3 O-O 17. Be3 Qc7 18. Bd4 Nf5 19. Rc1 Qa5 20. Qd3 Bxf3 21. gxf3 Qxa2 22. Bc5 Rfc8 23. b4 Qb2 24. Rc2 Qxe5 25. Re2 Qf6 26. Kh2 a5 27. f4 axb4 28. Bxb4 Ra4 29. Bd2 Rd4 30. Qa6 Rdc4 31. Be3 Nxe3 32. fxe3 h5 33. e4 Rc2 34. Rff2 Qd4 35. Rxc2 Rxc2 36. Rxc2 Qxe4 37. Qc8+ Kh7 38. Rg2 Qxf4+ 39. Kh1 d4 40. Qc2+ Kh6 41. Qd3 e5 42. Kg1 Qe3+ 43. Qxe3+ dxe3 44. Kf1 f5 45. Ke2 f4 46. h4 Kh7 47. Kf3 Kg8 48. Ke4 Kf7 49. Kxe5 f3 50. Rg5 1-0",
    B: "1735",
  },
  {
    W: "2044",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be2 Bg7 7. f4 Nc6 8. Be3 O-O 9. Qd2 Qb6 10. O-O-O Na5 11. Ne6 Bxe6 12. Bxb6 axb6 13. Nd5 Bxd5 14. exd5 Rac8 15. Kb1 Rc5 16. Qe3 Nxd5 17. Rxd5 Rxd5 18. Qxb6 Rd2 19. Qxa5 Rxe2 20. g3 Rc8 21. Rc1 Rxh2 22. Qa7 Rc5 23. Qxb7 h5 24. b4 Rc3 25. b5 Rc4 26. a3 Rc3 27. Ka2 Rxg3 28. Qc8+ Kh7 29. b6 Rhh3 30. Qa8 Bd4 31. b7 Bc5 32. b8=Q Rxa3+ 33. Qxa3 Rxa3+ 34. Kb2 Rf3 35. Qd8 Ba3+ 36. Kb1 Bxc1 37. Kxc1 Rf1+ 38. Kd2 Rxf4 39. Qxe7 Rf5 40. Qxd6 Kg7 41. c4 h4 42. c5 h3 43. c6 Rg5 44. c7 Rg2+ 45. Kd3 h2 46. Qd4+ f6 47. Qh4 Kf7 48. c8=Q Rg3+ 49. Qxg3 h1=Q 50. Qd7+ Kf8 51. Qb8# 1-0",
    B: "2053",
  },
  {
    W: "921",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 d6 3. Bc4 Nc6 4. O-O Nf6 5. d3 Be7 6. a3 O-O 7. Bg5 Na5 8. b4 Nxc4 9. dxc4 a6 10. b5 c6 11. bxc6 bxc6 12. Nc3 d5 13. Qd4 dxe4 14. Nxe4 Qxd4 15. Nxf6+ Bxf6 16. Rad1 Qg4 17. Bxf6 gxf6 18. Rd4 Qg6 19. Rfd1 Bb7 20. Rd7 Rab8 21. Nh4 Qg7 22. R1d3 Kh8 23. Rg3 Qh6 24. Rg8+ Rxg8 25. Rxf7 Ba8 26. Nf5 Qg6 0-1",
    B: "871",
  },
  {
    W: "1224",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 Nxd4 4. Nxd4 exd4 5. Qxd4 c6 6. e5 Qa5+ 7. Bd2 Qd5 8. Qxd5 cxd5 9. Nc3 Bc5 10. Nxd5 Ne7 11. Nxe7 Kxe7 12. O-O-O Re8 13. f4 Kf8 14. Bc4 Kg8 15. b3 Bb6 16. Rhe1 a6 17. g4 d6 18. h4 dxe5 19. Rxe5 Rxe5 20. fxe5 Bxg4 21. e6 Bxd1 22. exf7+ Kf8 23. Bb4+ Bc5 24. Bxc5# 1-0",
    B: "1249",
  },
  {
    W: "1564",
    T: "60+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. d4 Nf6 4. Nc3 O-O 5. Be3 b6 6. Bd3 Bb7 7. Qd2 Nc6 8. O-O-O d6 9. Bh6 e5 10. Bxg7 Kxg7 11. dxe5 Nxe5 12. Nxe5 dxe5 13. f4 exf4 14. Qxf4 Qe7 15. Rhf1 Rad8 16. e5 Nd5 17. Nxd5 Bxd5 18. Qf6+ Qxf6 19. exf6+ Kg8 20. h4 Be6 21. g3 Rd6 22. h5 Bf5 23. Bxf5 gxf5 24. Rxd6 cxd6 25. g4 fxg4 26. Rg1 Kh8 27. Rxg4 Re8 28. Rg7 Re5 29. h6 Rh5 30. Rxf7 Rxh6 31. Rxa7 Rxf6 32. Ra6 d5 33. Ra8+ Kg7 34. Rd8 Kg6 35. Rxd5 h5 36. b3 h4 37. Kb2 h3 38. Rd1 h2 39. Rh1 Rf2 40. a4 Rg2 41. b4 Rg1 42. a5 Rxh1 43. axb6 1-0",
    B: "1462",
  },
  {
    W: "1934",
    T: "60+0",
    P: "1. d4 Nf6 2. Nf3 e6 3. g3 d5 4. Bg2 c5 5. Bg5 cxd4 6. Nxd4 Be7 7. O-O O-O 8. Nb3 b6 9. c3 Ba6 10. Re1 Nbd7 11. N1d2 Ng4 12. Bxe7 Qxe7 13. h3 Ngf6 14. e4 dxe4 15. Nxe4 Nxe4 16. Bxe4 Rac8 17. Nd4 Nc5 18. Bg2 Rfd8 19. Qh5 g6 20. Qh6 Bd3 21. Rad1 Bc4 22. b3 Bd5 23. f3 Ba8 24. Ne2 Rxd1 25. Rxd1 Rd8 26. Re1 e5 27. c4 Nd3 28. Rf1 e4 29. Nf4 exf3 30. Nxd3 fxg2 31. Rf3 Qe2 32. Nf4 Qxf3 33. Kh2 Qf1 34. Nxg6 Qh1# 0-1",
    B: "1946",
  },
  {
    W: "1041",
    T: "60+0",
    P: "1. d4 d5 2. Bf4 Nc6 3. e3 e5 4. Bg3 exd4 5. exd4 Nf6 6. c3 Ne4 7. Bd3 Nxg3 8. hxg3 Qe7+ 9. Ne2 g6 10. Nd2 Bg7 11. Qc2 Bg4 12. f3 Be6 13. O-O-O Qd6 14. Kb1 O-O 15. Nf4 a5 16. Nxe6 Qxe6 17. Rde1 Qd6 18. b4 axb4 19. cxb4 Nxb4 20. Qb3 Rxa2 0-1",
    B: "1112",
  },
  {
    W: "1813",
    T: "60+0",
    P: "1. e3 d5 2. h3 e5 3. a3 f6 4. b4 Bd6 5. Bb2 c6 6. Be2 Be6 7. Bg4 Qe7 8. Bxe6 Qxe6 9. Qe2 e4 10. f3 Nd7 11. f4 f5 12. g4 Ngf6 13. g5 Nh5 14. Qxh5+ Kd8 15. Bxg7 Rg8 16. Qxh7 Kc7 17. Bb2 Rh8 18. Qg7 Rag8 19. Qc3 b5 20. a4 Nb6 21. a5 Nc4 22. Na3 a6 23. Nxc4 bxc4 24. h4 Qe7 25. O-O-O Bxb4 26. Qe5+ Qxe5 27. Bxe5+ Bd6 28. Bxh8 Rxh8 29. h5 Rd8 30. h6 c5 31. h7 Kc6 32. h8=Q Rd7 33. Qb2 Rb7 34. Qxb7+ Kxb7 35. g6 Kc6 36. g7 Kb5 37. g8=Q Kxa5 38. Qa8 Kb4 39. Qxa6 Bc7 40. Qa1 c3 41. d3 exd3 42. Rxd3 c4 43. Rxd5 Bb6 44. Qa6 Bc5 45. Qc6 Ka3 46. Qxc5+ Ka2 47. Qxc4+ Ka3 0-1",
    B: "1847",
  },
  {
    W: "2243",
    T: "60+0",
    P: "1. b3 c6 2. Bb2 d5 3. e3 Nf6 4. f4 g6 5. Nf3 Bg7 6. Be2 O-O 7. O-O Ne4 8. Bxg7 Kxg7 9. d3 Nf6 10. Nbd2 Bg4 11. Qc1 Bxf3 12. Nxf3 e6 13. Qb2 Kg8 14. Ng5 h6 15. Nf3 Nh7 16. Ne5 Qf6 17. Ng4 Qxb2 18. Nxh6+ Kg7 19. Ng4 Qxc2 0-1",
    B: "1979",
  },
  {
    W: "1287",
    T: "60+0",
    P: "1. c4 g6 2. Nc3 Bg7 3. b3 Bxc3 4. dxc3 Nf6 5. e4 O-O 6. f3 e5 7. Ne2 d6 8. Be3 Nc6 9. Qc2 Ne7 10. O-O-O Kg7 11. c5 Qe8 12. cxd6 cxd6 13. Rxd6 Be6 14. Bg5 Rd8 15. Rxd8 Qxd8 16. Kb2 h6 17. Bxf6+ Kxf6 18. Ng3 Kg7 19. Bc4 Bxc4 20. bxc4 f5 21. exf5 Nxf5 22. Re1 Qb6+ 23. Kc1 Nxg3 24. hxg3 Qc6 25. Qd3 g5 26. Rxe5 Re8 27. Rxe8 Qxe8 28. Qd4+ Kg6 29. Qxa7 Qe1+ 30. Kb2 Qd2+ 31. Kb3 Qd1+ 32. Kb4 Qb1+ 33. Kc5 Qf5+ 34. Kb6 Qe6+ 35. Kxb7 Qxc4 36. Qa6+ Qxa6+ 37. Kxa6 Kf5 38. c4 Ke6 39. c5 Kd5 40. c6 Kxc6 41. a4 Kc7 42. a5 Kb8 43. Kb5 Ka8 44. a6 Ka7 45. Kc6 Kxa6 46. f4 gxf4 47. gxf4 1-0",
    B: "1447",
  },
  {
    W: "1770",
    T: "60+0",
    P: "1. e4 g6 2. f4 Bg7 3. Nf3 d5 4. e5 c5 5. d4 c4 6. Be2 e6 7. O-O Ne7 8. c3 Nf5 9. Kh1 Nc6 10. g4 Nh6 11. h3 Bd7 12. Na3 a6 13. Nc2 b5 14. Ne3 f6 15. Qc2 f5 16. g5 Nf7 17. a4 h6 18. Kg2 hxg5 19. Nxg5 Nxg5 20. fxg5 Qxg5+ 21. Kh2 Qh4 22. Rf3 g5 23. Ng2 Qh7 24. Bxg5 f4 25. Qxh7 Rxh7 26. Nxf4 Bh6 27. Bxh6 Rxh6 28. Rg1 O-O-O 29. Rg7 Rdh8 30. Ng6 Kc7 31. Nxh8 Rxh8 32. Rff7 bxa4 33. Rxd7+ Kb6 34. Rb7+ Ka5 35. Bd1 Nb4 36. Ra7 Nc2 0-1",
    B: "1858",
  },
  {
    W: "1659",
    T: "60+0",
    P: "1. b3 d5 2. Bb2 Nf6 3. g3 Bh3 4. Bg2 Bxg2 5. Nh3 Bxh1 6. f3 Bg2 7. Nf4 Bxf3 8. exf3 e5 9. Ne2 Bd6 10. Kf2 e4 11. fxe4 Nxe4+ 12. Kg2 h5 13. Bxg7 h4 14. Bxh8 hxg3 15. Nxg3 Qg5 16. Qf3 Bxg3 17. hxg3 Nc6 18. Nc3 O-O-O 19. Nxe4 dxe4 20. Qxe4 Rxd2+ 21. Kf3 Nd4+ 22. Bxd4 Qh5+ 23. Qg4+ Qxg4+ 24. Kxg4 Rxd4+ 25. Kf5 Rd7 26. g4 Re7 27. c4 Kd8 28. Rd1+ Ke8 29. Rd5 c6 30. Re5 Rxe5+ 31. Kxe5 Kd7 32. a4 Kc7 33. b4 Kb6 34. c5+ Ka6 35. Kf6 b5 36. a5 Kb7 37. Kxf7 Kc7 38. g5 Kd7 39. g6 Kd8 40. g7 Kd7 41. g8=Q Kc7 42. Qe8 Kb7 43. Qe7+ Kc8 44. Qd6 Kb7 45. Qd7+ Kb8 46. Ke7 Ka8 47. Kd6 Kb8 48. Qxc6 a6 49. Qxb5+ axb5 50. c6 Kc8 51. c7 Kb7 52. Kd7 Ka6 53. c8=Q+ Ka7 54. Qc7+ Ka6 55. Qb6# 1-0",
    B: "1845",
  },
  {
    W: "1661",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 c6 3. d4 d5 4. exd5 cxd5 5. Nc3 Bb4 6. Bd3 Bxc3+ 7. bxc3 Nf6 8. Ba3 Bd7 9. O-O Nc6 10. Bd6 Bc8 11. Bc5 b6 12. Ba3 a5 13. Rb1 Ba6 14. Bxa6 Rxa6 15. Qd3 Qc8 16. Qb5 Nd7 17. Ne5 Ncxe5 18. dxe5 f6 19. f4 fxe5 20. fxe5 g6 21. Bd6 Kd8 22. Rf7 Re8 23. Rbf1 Nxe5 24. Bxe5 Qc5+ 25. Qxc5 bxc5 26. Rxh7 Re7 27. Rxe7 Kxe7 28. Bf6+ Kd7 29. Rf4 Kc6 30. Rg4 Kb5 31. Rxg6 c4 32. Bd4 Ka4 33. Rg8 Rc6 34. Rb8 Ra6 35. Rb2 Ka3 36. Rb1 Kxa2 37. Re1 Ka3 38. h3 Ka2 39. h4 Ka3 0-1",
    B: "1421",
  },
  {
    W: "1687",
    T: "60+0",
    P: "1. e4 Nf6 2. Nf3 e5 3. Nc3 d6 4. d4 Bg4 5. Be2 Bxf3 6. Bxf3 Be7 7. d5 Nbd7 8. O-O O-O 9. h3 Qe8 10. Re1 Kh8 11. Nb5 Rg8 12. Nxc7 Qc8 13. Nxa8 Qxa8 14. c3 h5 15. Bxh5 Nxh5 16. Qxh5# 1-0",
    B: "1572",
  },
  {
    W: "1715",
    T: "60+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. c3 d6 4. d4 Nf6 5. Bd3 O-O 6. Bg5 Nc6 7. O-O Bg4 8. e5 dxe5 9. dxe5 Bxf3 10. Qxf3 Nxe5 11. Qxb7 Nxd3 12. Na3 Nc5 13. Qf3 Ne6 14. Rad1 Qc8 15. Bh4 Qa6 16. Bg3 Ng5 17. Qf4 Ne6 18. Qe5 Nh5 19. Rd7 Bxe5 20. Bxe5 Nf6 21. Bxf6 exf6 22. Rfd1 Qc6 23. R7d3 Nf4 24. h3 Qxg2# 0-1",
    B: "1732",
  },
  {
    W: "1997",
    T: "15+0",
    P: "1. c4 d6 2. Nc3 Nf6 3. e4 g6 4. e5 dxe5 5. d4 Bg7 6. dxe5 Qd2+ 7. Bxd2 Ng4 8. Be3 Nxe5 9. Nd5 Nbc6 10. Nxc7+ Kf8 11. Nxa8 Bg4 12. f4 Bxd1 13. Rc1 Nxc4 14. Rxd1 Nxe3 15. Rd2 Nxf1 16. Kxf1 Bxb2 17. Nf3 Bc3 18. Rd3 Kg7 19. Rxc3 e6 20. Re3 e5 21. Re2 exf4 22. Kf2 Rd8 23. Ne5 Rxa8 24. Nxc6 bxc6 25. Rhe1 Rd8 26. Re7 c5 27. Re8 Rd2+ 28. R8e2 Rd1 29. Rxd1 1-0",
    B: "2084",
  },
  {
    W: "1543",
    T: "60+0",
    P: "1. e4 Nf6 2. Nf3 Nxe4 3. Ne5 f6 4. Qe2 Nd6 5. Nc4 Nxc4 6. Qxc4 Nc6 7. Nc3 e5 8. b4 d6 9. b5 Na5 10. Qa4 b6 11. Ba3 Bb7 12. Bb4 d5 13. Bxa5 bxa5 14. Qxa5 d4 15. Na4 c6 16. Qxd8+ Rxd8 17. bxc6 Bxc6 18. Nb2 Bb4 19. c3 dxc3 20. dxc3 Bxc3+ 21. Ke2 Bxb2 22. Rb1 Bd4 23. Kd3 Bb5+ 24. Kc2 Bxf1 25. Rhxf1 O-O 26. Rfd1 a5 27. Kb3 Ra8 28. Ka4 Rfd8 29. Rb7 Bxf2 30. Rc1 Bd4 31. Rcc7 Rf8 32. Rxg7+ Kh8 33. Rxh7+ Kg8 34. Rbg7# 1-0",
    B: "1583",
  },
  {
    W: "1285",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nf6 3. Bc4 h6 4. Nc3 d6 5. d3 Qe7 6. Be3 Be6 7. Bxe6 Qxe6 8. Qd2 Nc6 9. O-O O-O-O 10. Nb5 a6 11. Na7+ Nxa7 12. Bxa7 b6 13. Qc3 Kb7 14. Bxb6 Kxb6 15. a4 Kb7 16. a5 Kc8 17. Qc6 d5 18. Qxa6+ Kd7 19. Qb5+ Qc6 20. Qxc6+ Kxc6 21. Nxe5+ Kd6 22. Nxf7+ 1-0",
    B: "1323",
  },
  {
    W: "1476",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 Bc5 5. Bg5 h6 6. Bh4 a6 7. Bxc6 dxc6 8. O-O Bg4 9. Nbd2 g5 10. Bg3 Qe7 11. h3 Bxf3 12. gxf3 h5 13. Bh2 Qe6 14. Kh1 Qxh3 15. f4 Ng4 16. Qf3 Qxh2# 0-1",
    B: "1605",
  },
  {
    W: "1818",
    T: "900+15",
    P: "1. c4 e5 2. g3 Bc5 3. Bg2 Qf6 4. e3 Qd8 5. Nc3 Nf6 6. Nge2 Nc6 7. O-O d6 8. Re1 Bg4 9. a3 Qd7 10. b4 Bb6 11. b5 Ne7 12. Bxb7 Rb8 13. Bg2 h5 14. f3 Bh3 15. d4 exd4 16. exd4 Bxg2 17. Kxg2 h4 18. c5 hxg3 19. Nxg3 Qh3+ 0-1",
    B: "1952",
  },
  {
    W: "1141",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Qe7 5. Nxf7 Rg8 0-1",
    B: "1456",
  },
  {
    W: "1611",
    T: "900+15",
    P: "1. e4 d5 2. Qe2 dxe4 3. Qxe4 g6 4. Bc4 Bf5 5. Qe5 f6 6. Qc3 a6 7. Nf3 h5 8. Qb3 Nh6 9. d3 b5 10. Bd5 c6 11. Be4 Bxe4 12. dxe4 Ng4 13. O-O Ne5 14. Nh4 Qc8 15. f4 Nc4 16. Nxg6 Rg8 17. f5 Nd7 18. Bf4 e5 19. Bc1 Nc5 20. Qh3 Nxe4 21. Qxh5 Kd7 22. Qh7+ Rg7 23. Rd1+ Kc7 24. Qh5 Bc5+ 25. Kf1 Qg8 26. Nd2 Ne3+ 27. Ke2 Nxd1 28. Nxe4 Be3 29. Kxd1 Rd8+ 30. Ke2 Bxc1 31. Rxc1 Qc4+ 32. Kf3 Rd4 33. b3 Qd5 34. Qh4 Rd1 35. Rxd1 Qxd1+ 36. Kg3 Qe1+ 37. Kh3 Qe3+ 38. g3 Qf3 39. Nxf6 Qxf5+ 40. g4 Qf3+ 41. Qg3 Qxf6 42. Nxe5 Qf1+ 43. Kh4 Rh7+ 44. Kg5 Qc1+ 45. Kg6 Qh6+ 46. Kf5 Qf8+ 47. Ke4 Kb7 48. Qc3 Rh4 49. Qxc6+ Ka7 50. Qd7+ Kb6 51. Qc6+ Ka5 52. Qc7+ Kb4 53. c3+ Ka3 54. Qa5+ Kb2 55. Nd3+ Kc2 56. Ne1+ Kd2 57. c4+ Ke2 58. Ng2 Rxg4+ 59. Kd5 Qa8+ 60. Kc5 Rg5+ 61. Kb4 Rxg2 62. Qa3 Qf8+ 63. c5 Rg4+ 64. Kc3 Qf3+ 65. Kc2 Qe4+ 66. Kc1 Qe3+ 67. Kb1 Qd3+ 68. Kb2 Qd4+ 69. Kc2 Qd2+ 70. Kb1 Rg1+ 71. Qc1 Qxc1# 0-1",
    B: "1485",
  },
  {
    W: "2089",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Nxc6 bxc6 6. Bc4 f5 7. O-O Nf6 8. Nc3 fxe4 9. Nxe4 d5 10. Nxf6+ Qxf6 11. Bb3 Bd6 12. Re1 Be6 13. c4 e4 14. Be3 Qe5 15. g3 Rd8 16. c5 Bb8 17. Bd4 Qf5 18. Rc1 Qh3 19. f3 O-O 20. f4 Bg4 21. Qd2 e3 22. Qxe3 Rde8 23. Qf2 Bc7 24. Be5 Ba5 25. Re3 h6 26. Bc3 Bxc3 27. Rexc3 Kh8 28. Re3 Re4 29. Rxe4 dxe4 30. Re1 Re8 31. Re3 Bf3 32. Be6 Qxe6 33. h4 Qxa2 34. Rxf3 Qb1+ 35. Qf1 Qxf1+ 36. Rxf1 e3 37. Re1 Rb8 38. Kg2 Rb5 39. b4 Rxb4 40. Rxe3 Rc4 41. Re5 Rc2+ 42. Kh3 a5 43. Re8+ Kh7 44. Ra8 a4 45. Rxa4 Rxc5 46. Ra6 Rf5 47. Rxc6 Rf6 48. Rc3 Rf7 49. Rf3 g6 50. Kg4 Kg7 51. h5 Rf5 52. hxg6 Kxg6 53. Kh3 h5 0-1",
    B: "2026",
  },
  {
    W: "1106",
    T: "300+0",
    P: "1. e3 Nc6 2. Nf3 Nf6 3. Bb5 Nb4 0-1",
    B: "1095",
  },
  {
    W: "2111",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Nc3 e6 4. d4 cxd4 5. Nxd4 Nf6 6. f3 e5 7. Ndb5 d6 8. Bg5 Be7 9. Bxf6 Bxf6 10. Nd5 Qa5+ 11. c3 Bd8 12. b4 Qxb5 13. Bxb5 O-O 14. O-O Bd7 15. a3 a6 16. Ba4 b5 17. Bb3 Be6 18. c4 Nd4 19. Ba2 bxc4 20. Bxc4 Bb6 21. Nxb6 1-0",
    B: "1941",
  },
  {
    W: "1826",
    T: "180+0",
    P: "1. e4 a5 2. f4 c6 3. Nf3 e6 4. Be2 h6 5. O-O Nf6 6. e5 Nd5 7. d4 Nc7 8. c4 Be7 9. Nc3 d6 10. Ne4 dxe5 11. fxe5 O-O 12. Be3 f5 13. exf6 Bxf6 14. Qd2 b6 15. Bxh6 gxh6 16. Qxh6 Ne8 17. Qg6+ Ng7 18. Rad1 Qe7 19. Neg5 Bxg5 20. Nxg5 Rxf1+ 21. Rxf1 Nd7 22. Qh7# 1-0",
    B: "1822",
  },
  {
    W: "2070",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 e5 7. Nb3 Be7 8. f3 O-O 9. Qd2 Be6 10. O-O-O Nbd7 11. g4 Rc8 12. h4 b5 13. h5 Nb6 14. g5 Nfd7 15. g6 f5 16. gxh7+ Kh8 17. h6 g5 18. exf5 Bxf5 19. Bd3 Nc4 20. Bxc4 Rxc4 21. Kb1 b4 22. Ne4 d5 23. Qxd5 Qc7 24. Rc1 Rc8 25. Rh2 Nf6 26. Qf7 Bd8 27. Qxc7 R8xc7 28. Na5 Nxe4 29. Nxc4 Rxc4 30. fxe4 Rxe4 31. Bc5 Rc4 32. Bd6 e4 33. Be5+ Kxh7 34. Rd1 Bb6 35. Rd6 Bc5 36. Rxa6 e3 37. Rf6 Rxc2 38. Rxc2 e2 39. Rxf5 e1=Q+ 40. Rc1 Qe4+ 41. Ka1 Qxf5 42. Bc7 Bd4 43. Ba5 Qf6 44. Kb1 b3 45. axb3 Bxb2 46. Rc2 Qf5 47. Kxb2 Qxa5 48. Rc6 Qe5+ 0-1",
    B: "2004",
  },
  {
    W: "2076",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 O-O 5. f4 d6 6. Nf3 c5 7. d5 e6 8. Be2 exd5 9. exd5 Bg4 10. O-O Re8 11. f5 Nbd7 12. fxg6 hxg6 13. Bg5 Qa5 14. Qd3 Bf5 15. Qd2 Ne4 16. Nxe4 Qxd2 17. Nexd2 Bxb2 18. Rae1 Bc3 19. Rc1 Bxd2 20. Bxd2 Rxe2 21. Bc3 Rae8 22. Nh4 Bd3 23. Rf3 R8e4 24. g3 Bxc4 25. Rcf1 Re7 26. Rb1 Bxd5 27. Rf2 R2e3 28. Bd2 Rd3 29. Bg5 Re5 30. Bf4 Re6 31. Ng2 c4 32. Ne1 Rd4 33. Nc2 Rde4 34. Nb4 Bc6 35. Bh6 Kh7 36. Bg5 a5 37. Nc2 a4 38. Ne3 Rxe3 39. Bxe3 Rxe3 40. Rxf7+ Kh6 41. Rbf1 Re2 42. R1f2 Re1+ 43. Rf1 Re2 44. R1f2 Re1+ 45. Rf1 Re2 1/2-1/2",
    B: "2058",
  },
  {
    W: "1875",
    T: "180+0",
    P: "1. d4 1-0",
    B: "1844",
  },
  {
    W: "1766",
    T: "180+0",
    P: "1. d4 e6 2. Nc3 f5 3. Bf4 Nf6 4. Nb5 Be7 5. Bxc7 Qxc7 6. Nxc7+ 1-0",
    B: "1782",
  },
  {
    W: "1056",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nxe5 Nxe5 4. d4 Ng6 5. Qf3 d6 6. Bc4 Nf6 7. e5 dxe5 8. dxe5 Nxe5 9. Bb5+ c6 10. Bxc6+ bxc6 11. Bf4 Nxf3+ 0-1",
    B: "897",
  },
  {
    W: "1674",
    T: "60+0",
    P: "1. d4 e6 2. Nf3 d5 3. Bg5 c5 4. dxc5 Be7 5. c3 Bxg5 6. Nxg5 Qxg5 7. e3 Nc6 8. Nd2 Nf6 9. Nf3 Qf5 10. Nd4 Nxd4 11. cxd4 Qe4 12. Qe2 e5 13. b4 exd4 14. exd4 Qxe2+ 15. Bxe2 O-O 16. O-O-O Re8 17. Bf3 Ne4 18. h3 f5 19. Rdf1 Bd7 20. g4 fxg4 21. Bxg4 Bxg4 22. hxg4 Nc3 23. f3 Nxa2+ 24. Kb1 Nxb4 25. Rf2 Nd3 26. Rd2 Nf4 27. g5 Ng6 28. Rf1 a5 29. Rb2 Nf4 30. Rxb7 Rab8 31. c6 Rxb7+ 32. cxb7 Rb8 33. Ka2 Rxb7 34. Ka3 Rb4 0-1",
    B: "1493",
  },
  {
    W: "1938",
    T: "60+0",
    P: "1. Nf3 e6 2. e4 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Nxf6+ Qxf6 6. d4 Bd6 7. Bd3 b6 8. Bg5 Bb7 9. Bxf6 gxf6 10. c3 e5 11. Qe2 Nd7 12. O-O-O O-O-O 13. Ba6 e4 14. Bxb7+ Kxb7 15. Qxe4+ Kb8 16. Qc6 Rhg8 17. g3 Nf8 18. Nd2 Ne6 19. f4 Rc8 20. Nc4 Nd8 21. Qa4 Bf8 22. Na3 c5 23. Nb5 Nc6 24. d5 a6 25. Qxa6 1-0",
    B: "1948",
  },
  {
    W: "1203",
    T: "60+0",
    P: "1. d4 d5 2. c4 c6 3. e3 e6 4. Nc3 Nd7 5. Nf3 Ngf6 6. Bd3 Be7 7. cxd5 cxd5 8. Qc2 b6 9. O-O Bb7 10. Re1 O-O 11. e4 dxe4 12. Bxe4 Nxe4 13. Nxe4 Nf6 14. Nxf6+ Bxf6 15. Ne5 Qxd4 16. Nf3 Bxf3 17. gxf3 Bh4 18. Be3 Qf6 19. f4 e5 20. fxe5 Qxe5 21. Rad1 Rad8 22. Rxd8 Rxd8 23. Rd1 Rd2 24. Rxd2 Qf6 25. Rd1 h6 26. Qd2 Qg6+ 27. Kf1 Kh7 28. Qd7 Qf6 29. Qxa7 Bg5 30. Rd7 h5 31. Bxg5 Qxg5 0-1",
    B: "1185",
  },
  {
    W: "1116",
    T: "60+0",
    P: "1. e4 Nc6 2. Nf3 e5 3. Bc4 d6 4. d3 Be6 5. Bxe6 fxe6 6. Bg5 Nf6 7. Bxf6 gxf6 8. Nh4 d5 9. Qh5+ Kd7 10. exd5 exd5 11. Nc3 d4 12. Qf5+ Ke7 13. Nb5 Qd5 14. Nxc7 Qd7 15. Nxa8 Qxf5 16. Nxf5+ Kd7 17. O-O Bc5 18. c3 Rxa8 19. cxd4 Bxd4 20. Nxd4 exd4 21. Rfc1 Re8 22. b4 Re2 23. Rc4 b5 24. Rc5 a6 25. Rxb5 axb5 26. Rd1 Nxb4 27. a3 Nc2 0-1",
    B: "1136",
  },
  {
    W: "1331",
    T: "60+0",
    P: "1. e4 c5 2. Nc3 d6 3. Nf3 Nc6 4. Bc4 Nf6 5. d3 b6 6. Be3 Bb7 7. O-O g6 8. h3 Bg7 9. Bb3 O-O 10. Ne2 e6 11. d4 Qd7 12. dxc5 bxc5 13. e5 Ne4 14. exd6 Bxb2 15. Rb1 Bg7 16. Qd3 Nxd6 17. Bf4 Rad8 18. Bxd6 Qxd6 19. Qxd6 Rxd6 20. Nf4 Rfd8 21. Nh4 Ne5 22. Nd3 Nxd3 23. cxd3 Rxd3 24. Nf3 Bxf3 25. Bc4 Rc3 26. Rfc1 Rxc1+ 27. Rxc1 Bd5 28. Bxd5 Rxd5 29. Rxc5 Rxc5 30. a3 Be5 31. a4 Rc1# 0-1",
    B: "1411",
  },
  {
    W: "2051",
    T: "60+0",
    P: "1. d4 d5 2. Bf4 c5 3. e3 Qb6 4. Nc3 e6 5. Nb5 Na6 6. c3 Nf6 7. Nf3 Be7 8. a4 O-O 9. a5 Qd8 10. Bd3 b6 11. axb6 axb6 12. O-O Bb7 13. Ne5 Rc8 14. Qe2 Nc7 15. Nxc7 Rxc7 16. Ra7 Ba8 17. Rxc7 Qxc7 18. Ng6 Bd6 19. Nxf8 Bxf4 20. exf4 Kxf8 21. dxc5 bxc5 22. Ra1 Qb7 23. Qe5 g6 24. Qd6+ Kg7 25. Bf1 d4 26. Qd8 Qe4 27. Rxa8 Kh6 28. Qxf6 Qf5 29. Qxf5 gxf5 30. cxd4 Kg6 31. dxc5 Kf6 32. c6 Ke7 33. c7 Kd6 34. c8=Q Kd5 35. Qh8 Ke4 36. Qxh7 Kxf4 37. Qxf7 Ke5 38. Qxe6+ Kxe6 39. Rf8 Ke5 40. Rxf5+ Ke6 41. g3 Kd6 42. Be2 Ke6 43. Rf4 Kd6 44. h4 Kc5 45. h5 Kb6 46. h6 Kc5 47. h7 Kb6 1/2-1/2",
    B: "2212",
  },
  {
    W: "1709",
    T: "60+0",
    P: "1. d4 Nf6 2. e3 c5 3. f4 d5 4. Nf3 Ne4 5. c3 g6 6. Bd3 Bg7 7. Qc2 Bf5 8. Nbd2 Nxd2 9. Bxd2 Bxd3 10. Qxd3 Nc6 11. Qb5 cxd4 12. exd4 Qd7 13. Ne5 Nxe5 14. fxe5 Qxb5 15. O-O-O Rc8 16. Kc2 a5 17. a4 Qxa4+ 18. Kd3 Qb3 19. Ke3 Bh6+ 20. Ke2 Bxd2 21. Rxd2 Qc4+ 22. Ke3 O-O 23. Rhd1 f6 24. exf6 Rxf6 25. g3 e5 26. dxe5 Qe4# 0-1",
    B: "1726",
  },
  {
    W: "1422",
    T: "60+0",
    P: "1. f4 g6 2. Nf3 c5 3. e3 Bg7 4. Be2 d6 5. O-O Bg4 6. d3 Nd7 7. c3 Rc8 8. Bd2 Ngf6 9. c4 O-O 10. Nc3 Bxf3 11. Bxf3 e5 12. fxe5 Rc7 13. d4 dxe5 14. dxe5 Ne8 15. e6 fxe6 16. Ne4 Ndf6 17. Rb1 Nxe4 18. Bxe4 Rxf1+ 19. Qxf1 Rf7 20. Qe2 Nf6 21. Rf1 Qf8 22. Bc3 Nxe4 23. Bxg7 Kxg7 24. Rxf7+ Qxf7 25. Qf3 Qxf3 26. gxf3 Nd2 27. Kf2 Nxc4 28. b3 Nd6 29. Ke2 Nf5 30. Kd3 Nh4 31. h3 Nxf3 32. Ke4 Ng5+ 33. Ke5 Nxh3 34. Kxe6 Kf8 35. e4 Ng5+ 36. Ke5 Nf3+ 37. Kf4 Nd2 38. Ke3 Nc4+ 39. Kd3 Kf7 40. Kxc4 Ke6 41. b4 Ke5 42. bxc5 Kxe4 43. a4 g5 44. a5 g4 45. c6 bxc6 46. a6 g3 47. Kc5 g2 48. Kxc6 g1=Q 49. Kb7 Qb1+ 50. Kxa7 Qa1 51. Ka8 Qxa6+ 52. Kb8 1/2-1/2",
    B: "1355",
  },
  {
    W: "2027",
    T: "60+0",
    P: "1. e4 d5 2. Nc3 e6 3. exd5 exd5 4. d4 c5 5. Bb5+ Bd7 6. Bxd7+ Qxd7 7. Nf3 cxd4 8. Nxd4 Nc6 9. O-O Bc5 10. Re1+ Nge7 11. Ndb5 O-O-O 12. Bg5 f6 13. Bf4 d4 14. Na4 Bb6 15. Nxb6+ axb6 16. a4 Nd5 17. Bg3 Ne5 18. Bxe5 fxe5 19. Rxe5 Rhe8 20. Rxe8 Rxe8 21. Qxd4 Nf6 22. Qxb6 Qc6 23. Na7+ Kd7 24. Nxc6 bxc6 25. Qb7+ Kd6 26. Rd1+ Kc5 27. b4+ Kc4 28. Qf7+ Nd5 29. Qxe8 Kxb4 30. Qe2 Kc5 31. Rxd5+ Kxd5 32. Qd3+ Kc5 33. Qc3+ 1-0",
    B: "1828",
  },
  {
    W: "1908",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 g6 3. d4 cxd4 4. Qxd4 Nf6 5. e5 Nc6 6. Qd1 Nxe5 7. Nxe5 Qa5+ 8. Bd2 Qxe5+ 9. Be2 Qxb2 10. Bc3 Qb6 11. O-O Bg7 12. Nd2 O-O 13. Nc4 Qc6 14. Bb2 d6 15. Bf3 Qxc4 16. h4 Qxh4 0-1",
    B: "1925",
  },
  {
    W: "2184",
    T: "30+0",
    P: "1. g3 d5 2. Bg2 c6 3. Bxd5 Nf6 4. Bg2 Bg4 5. d3 Bh5 6. Bd2 e5 7. Ba5 Qxa5+ 8. c3 Qb6 9. Bf3 Bc5 10. Bxh5 Bxf2+ 11. Kf1 Nxh5 12. Nf3 Bxg3 13. d4 Bf4 14. Nxe5 O-O 15. Nc4 Qc7 16. Rg1 Re8 17. Qd3 Nd7 18. Qf3 Ndf6 19. Ne5 Bxe5 20. dxe5 Rxe5 21. Nd2 Rae8 22. Re1 Re3 23. Qf2 Ne4 24. Nf3 Nxf2 25. Kxf2 Qf4 26. Rg3 Nxg3 27. hxg3 Qe4 28. Kf1 Rxf3+ 29. exf3 Qxe1+ 30. Kg2 Re3 31. Kh3 Qf2 32. Kg4 Qxf3+ 33. Kh4 1-0",
    B: "2141",
  },
  {
    W: "1749",
    T: "180+0",
    P: "1. e4 e6 2. d4 d5 3. exd5 exd5 4. Nf3 Nc6 5. c3 h6 6. h3 Nf6 7. Bd3 Bd6 8. O-O O-O 9. Qc2 Ne7 10. Bd2 Ne4 11. Bxe4 dxe4 12. Qxe4 Ng6 13. Qc2 b6 14. a4 Bb7 15. Ne1 Nh4 16. f3 Qf6 17. Na3 Bd5 18. b4 c5 19. c4 Qxd4+ 20. Kh1 Be6 21. bxc5 bxc5 22. Nb5 Qxa1 23. Nxd6 Rab8 24. Ne4 Rb1 25. Nxc5 Bxh3 26. Ba5 Rxe1 27. Rxe1 Bxg2+ 28. Kh2 Qd4 29. Ne4 Nxf3+ 30. Kxg2 Nxe1+ 0-1",
    B: "1775",
  },
  {
    W: "1842",
    T: "180+0",
    P: "1. e4 Nf6 2. Nc3 e5 3. Nf3 Nc6 4. Bb5 d6 5. d3 Be7 6. O-O Bg4 7. h3 Bh5 8. Bg5 O-O 9. Bxc6 bxc6 10. g4 Bg6 11. Nh4 Nxe4 12. Bxe7 Nxc3 13. Bxd8 Nxd1 14. Nxg6 hxg6 15. Bxc7 Nxb2 16. Bxd6 Rfe8 17. Rfe1 f6 18. a4 Red8 19. Bc5 Rd5 20. Be3 Ra5 21. Reb1 Nxa4 22. Rb4 Nc3 23. Rxa5 Nd5 24. Rxa7 Rxa7 25. Rb8+ Kf7 26. Bxa7 1-0",
    B: "1834",
  },
  {
    W: "1255",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. Nf3 e5 5. Nxe5 Bc5 6. Nf3 Nf6 7. Bd3 Bxf2+ 8. Kxf2 Ng4+ 9. Kg1 c6 10. Qe1+ Be6 11. Bf5 O-O 12. Bxe6 fxe6 13. Qg3 Qb6+ 14. d4 Nf6 15. Be3 Nbd7 16. Re1 c5 17. dxc5 Nxc5 18. b4 Nd5 19. Bxc5 Qc6 20. Bxf8 Nxc3 21. Rf1 Ne2+ 0-1",
    B: "1228",
  },
  {
    W: "1756",
    T: "60+0",
    P: "1. d4 f5 2. g3 Nf6 3. Bg2 Nc6 4. Nf3 b6 5. O-O Bb7 6. c3 e6 7. Bg5 h6 8. Bxf6 gxf6 9. Nbd2 Bd6 10. e4 Rg8 11. exf5 Qe7 12. fxe6 dxe6 13. Re1 O-O-O 14. d5 Ne5 15. dxe6 Qxe6 16. Nxe5 Bxe5 17. f3 Kb8 18. f4 Bxg2 19. fxe5 fxe5 20. Kxg2 Qc6+ 21. Kg1 Rdf8 22. Qe2 Qc5+ 23. Qe3 Qe7 24. Ne4 Qg7 25. Rad1 h5 26. Kg2 h4 27. Rg1 h3+ 28. Kh1 Qd7 29. Nf2 Qc6+ 30. Rg2 Qxg2# 0-1",
    B: "1721",
  },
  {
    W: "2028",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 e6 3. c4 f5 4. Nc3 c6 5. g3 Nf6 6. Bg2 Bd6 7. O-O Nbd7 8. Ne1 Ne4 9. Na4 O-O 10. f3 Nxg3 11. hxg3 Bxg3 12. Nd3 f4 13. Nf2 Qh4 14. Nh3 e5 15. e3 Nf6 16. dxe5 Bxh3 17. Qe2 Bf5 18. Rf2 Qh2+ 19. Kf1 Bh3 20. exf6 Qh1# 0-1",
    B: "2408",
  },
  {
    W: "1802",
    T: "60+0",
    P: "1. d4 d5 2. c4 Nf6 3. e3 c6 4. Nf3 e6 5. Bd3 Be7 6. O-O O-O 7. Nc3 b6 8. Qe2 Nbd7 9. Rd1 c5 10. e4 dxe4 11. Nxe4 Bb7 12. Neg5 cxd4 13. Nxe6 Bxf3 14. gxf3 fxe6 15. Qxe6+ Kh8 16. Bg5 Nc5 17. Qh3 h6 18. Bxf6 Bxf6 19. Bb1 Qd7 20. Qh5 d3 21. Qg6 Bxb2 22. Rxd3 Nxd3 23. Bxd3 1-0",
    B: "1700",
  },
  {
    W: "1758",
    T: "60+0",
    P: "1. e3 g5 2. d4 g4 3. Qxg4 d5 4. Qd1 c5 5. Nf3 b6 6. c4 dxc4 7. Bxc4 Nf6 8. O-O Bg7 9. Ne5 h6 10. Bxf7+ Kf8 11. Qf3 Qd6 12. Bb3 Be6 13. Ng6+ Kg8 14. Nxh8 Kxh8 15. Bxe6 Qxe6 16. dxc5 Nbd7 17. cxb6 Qd5 18. Qxd5 Nxd5 19. bxa7 Rxa7 20. Nc3 N5f6 21. Bd2 Nh5 22. Nb5 Rb7 23. Nd4 Ne5 24. Bc3 Rb6 25. Nf5 Rg6 26. Nxg7 Nxg7 27. Bxe5 Kh7 28. Bxg7 Rxg7 29. Rac1 Rg6 30. Rc7 Rb6 31. Rxe7+ Kg6 32. b3 Rb8 33. a4 Ra8 34. Re6+ Kf5 35. Rb6 Ra5 36. Rd1 Ra8 37. h3 Ke4 38. Rdd6 Kf5 39. Rxh6 Ke4 40. Rh5 Kd3 41. e4 Ke2 42. e5 Re8 43. e6 Kd3 44. a5 Kc2 45. a6 Kc3 46. a7 Rd8 47. Rb8 Rd1+ 48. Kh2 Ra1 49. a8=Q Re1 50. Qc6+ Kb2 51. b4 Rb1 52. b5 Ka2 53. b6 Ka3 54. Ra5+ Kb4 55. b7 Rh1+ 56. Kxh1 Kxa5 57. Qc5+ Ka6 58. Rc8 Kxb7 59. Qc6+ Ka7 60. Rc7+ Kb8 61. Qb7# 1-0",
    B: "2026",
  },
  {
    W: "2212",
    T: "60+0",
    P: "1. d4 e6 2. c4 d5 3. Nc3 dxc4 4. e4 Be7 5. e5 c5 6. dxc5 Qxd1+ 7. Nxd1 Bxc5 8. Bxc4 Nc6 9. Bf4 Nge7 10. Nf3 a6 11. O-O Ng6 12. Bg3 b5 13. Bd3 Bb7 14. Bxg6 hxg6 15. Nc3 Be7 16. Ne4 O-O 17. Nd6 Na5 18. Nd4 Bd5 19. b3 Rfd8 20. Rac1 f6 21. Rc7 Kf8 22. Ne4 f5 23. Nc3 Rac8 24. Nxd5 exd5 25. Ne6+ Ke8 26. Rxe7+ Kxe7 27. Nxd8 Kxd8 28. f4 Ke7 29. Rd1 Ke6 30. Bh4 Rc2 31. a4 b4 32. Rd3 Rc3 33. Rd4 Rxb3 34. Be1 Nc4 35. g4 a5 36. gxf5+ gxf5 37. h4 g6 38. h5 gxh5 39. Bh4 Rb1+ 40. Kf2 Rb2+ 41. Kf3 b3 42. Rd3 Rb1 43. Bf6 b2 44. Rb3 Rf1+ 45. Ke2 b1=Q 46. Rxb1 Rxb1 47. Kd3 Rb4 48. Kd4 Rxa4 49. Kc5 Nxe5 50. Kb5 Rxf4 51. Kc5 Kxf6 0-1",
    B: "2031",
  },
  {
    W: "1421",
    T: "300+0",
    P: "1. e4 e6 2. d4 d6 3. d5 Be7 4. dxe6 Bxe6 5. Bd2 Nf6 6. h3 Nxe4 7. c3 Nxd2 8. Nxd2 h6 9. Ne4 a6 10. Qa4+ Bd7 11. Qb3 Bc6 12. Bd3 O-O 13. O-O-O Re8 14. f3 Bg5+ 15. Kb1 d5 16. Ng3 a5 17. N1e2 a4 18. Qb4 Be7 19. Qg4 Bd7 20. Nf5 Bxf5 21. Bxf5 Bf6 22. Nf4 d4 23. Nh5 Qd6 24. Nxf6+ Qxf6 25. Rxd4 Nc6 26. Rd7 Ne5 27. Qd4 Nxd7 28. Bxd7 Qxd4 29. cxd4 Red8 30. Bf5 Rxd4 31. Rc1 Rad8 32. Rxc7 Rd1+ 33. Kc2 R8d2+ 34. Kc3 Rxg2 35. Rc8+ Rd8 36. Rxd8# 1-0",
    B: "1458",
  },
  {
    W: "1450",
    T: "180+0",
    P: "1. e4 g6 2. Bc4 Bg7 3. Qf3 e6 4. d3 Nc6 5. Nh3 Nd4 6. Qd1 c6 7. c3 Nb5 8. Bxb5 cxb5 9. d4 a6 10. Bg5 Qc7 11. Qd3 h6 12. Bf4 d6 13. O-O f6 14. e5 g5 15. Be3 f5 16. exd6 Qxd6 17. Nd2 Bd7 18. f4 g4 19. Nf2 h5 20. g3 O-O-O 21. d5 e5 22. Ba7 e4 23. Qe3 Qc7 24. Qc5 Qxc5 25. Bxc5 Nf6 26. Bb6 Rde8 27. Nb3 Nxd5 28. Bd4 Bxd4 29. Nxd4 Bc6 30. Nxc6 bxc6 31. Rfd1 Ne3 32. Rd6 Kb7 33. Nd1 Nc2 34. Rc1 Rd8 35. Rf6 e3 36. Re6 Rhe8 37. Rxe8 Rxe8 38. Rxc2 e2 39. Rxe2 Rxe2 40. Kf1 Rxh2 41. Kg1 Rd2 42. Ne3 Rxb2 43. c4 Rxa2 44. cxb5 axb5 45. Nxf5 b4 46. Nd4 Ra1+ 47. Kf2 c5 48. Nb3 c4 49. Nd4 b3 50. Ke2 b2 51. Nc2 Rc1 52. Na3 c3 53. Kd3 b1=Q+ 54. Nxb1 Rxb1 55. Kxc3 Rg1 56. Kd3 Rxg3+ 57. Ke2 h4 58. Kf2 Rc3 59. Ke2 Rc4 60. Kd2 Rxf4 61. Kd3 g3 62. Ke3 Rf6 63. Ke4 g2 64. Ke5 Rf1 65. Ke4 1/2-1/2",
    B: "1451",
  },
  {
    W: "1339",
    T: "60+0",
    P: "1. e4 d5 2. Nf3 dxe4 3. Ng5 Nf6 4. Qe2 Bf5 5. d3 exd3 6. cxd3 Bxd3 7. Qxd3 Qxd3 8. Bxd3 Nc6 9. Nc3 O-O-O 10. Nxf7 Rxd3 11. Nxh8 Rd8 12. O-O Nd5 13. Nf7 Re8 14. Be3 Nxc3 15. bxc3 e5 16. Rae1 Re7 17. Ng5 h6 18. Nf3 e4 19. Nd4 g5 20. Nxc6 bxc6 21. Bxa7 Kb7 22. Bd4 Bg7 23. Bxg7 Rxg7 24. h3 Re7 25. g4 c5 26. c4 Kc6 27. a4 Kb6 28. Re2 Ka5 29. Rfe1 Kb4 30. Rxe4 Rxe4 31. Rxe4 Kc3 32. a5 Kb4 33. a6 Ka5 34. a7 Kb6 35. a8=Q h5 36. Qb8+ Kc6 37. gxh5 Kd6 38. h6 Kd7 39. h7 c6 40. h8=Q g4 41. Qh6 1-0",
    B: "1324",
  },
  {
    W: "1880",
    T: "60+0",
    P: "1. d4 d5 2. e3 Nf6 3. Nc3 Nc6 4. Bd2 e6 5. Nf3 Bb4 6. Be2 Bxc3 7. Bxc3 Ne4 8. a3 Nxc3 9. bxc3 O-O 10. O-O f5 11. e4 e5 12. exf5 Bxf5 13. dxe5 Re8 14. Nd4 Be4 15. f3 Bg6 16. Nxc6 b6 17. Nd4 c5 18. Qd2 cxd4 19. cxd4 h6 20. Bb5 Re6 21. f4 a6 22. Bc6 Rc8 23. f5 Rexc6 24. fxg6 Rxg6 25. e6 Rc7 26. Qf4 Re7 27. Rab1 b5 28. a4 Rgxe6 29. axb5 Rf6 30. Qg3 Rxf1+ 31. Rxf1 Qc8 32. bxa6 Qxc2 33. Qb8+ Kh7 34. a7 Re2 35. Qg3 Qc8 36. Qb8 Qg4 37. Rf2 Re1+ 38. Rf1 Qxd4+ 39. Kh1 Rxf1# 0-1",
    B: "1763",
  },
  {
    W: "1028",
    T: "60+0",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qd1 Bc5 5. Nc3 Qf6 6. Nf3 Nd4 7. Bg5 Qf4 8. Ne2 Nxc2+ 9. Qxc2 Qe5 10. Bf4 Qf6 11. Qxc5 d6 12. Qxc7 Qxb2 13. Rd1 Be6 14. Rxd6 Qb4+ 15. Nd2 Qxd6 16. Qxd6 Bxa2 17. Qe5+ Ne7 18. Qc7 Rc8 19. Qxb7 Rc1+ 20. Nxc1 Be6 21. Qb8+ Nc8 22. Qb5+ Ke7 23. e5 g6 24. Bg5+ 1-0",
    B: "1082",
  },
  {
    W: "2288",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 Nf6 3. g3 Bf5 4. Bg2 e6 5. Nh4 Bg6 6. Nxg6 hxg6 7. Nc3 c5 8. Bg5 cxd4 9. Qxd4 Nc6 10. Qa4 Qb6 11. O-O-O Bb4 12. Be3 Qa5 13. Qxa5 Bxa5 14. Nb5 O-O-O 15. Bf4 a6 16. Nd6+ Rxd6 17. Bxd6 Ng4 18. Rhf1 Kd7 19. Bc5 b6 20. Be3 Nxe3 21. fxe3 f6 22. h4 Bb4 23. a3 Bc5 24. e4 Be3+ 25. Kb1 d4 26. c3 e5 27. cxd4 Nxd4 28. Rd3 Ke6 29. Rxe3 Nb5 30. Bf3 Rd8 31. Rd3 Nd4 32. Rfd1 Rc8 33. Bg4+ f5 34. exf5+ gxf5 35. Bh3 Nxe2 36. Rd6+ Kf7 37. Bxf5 Nd4 38. Bg6+ Kg8 39. Rd8+ Rxd8 40. Ka2 Rc8 41. Be4 Rc2 42. Bd5+ Kh8 43. Be4 Rxb2+ 44. Kxb2 Nb5 45. Rc1 Nd6 46. Rc8+ Ne8 0-1",
    B: "2427",
  },
  {
    W: "1687",
    T: "60+0",
    P: "1. d4 c5 2. c4 cxd4 3. Qxd4 Nc6 4. Qd1 g6 5. a3 Bg7 6. Nc3 Nf6 7. e4 O-O 8. Be2 Re8 9. e5 Nxe5 10. Bf4 Nc6 11. Nf3 e5 12. Be3 e4 13. Nd4 Nxd4 14. Bxd4 Nh5 15. Bxh5 Bxd4 16. Qxd4 gxh5 17. O-O Qg5 18. Nxe4 Qg4 19. Nf6+ Kg7 20. Nxe8+ Kf8 21. Qxg4 hxg4 22. Nd6 h5 23. Rfe1 b6 24. Re8+ Kg7 25. Rxc8 Rxc8 26. Nxc8 f5 27. Nxa7 Kf6 28. Re1 Kg5 29. Nb5 f4 30. g3 f3 31. Nd6 h4 32. gxh4+ Kxh4 33. Re4 Kh3 34. b4 1-0",
    B: "1658",
  },
  {
    W: "2009",
    T: "60+0",
    P: "1. c4 Nf6 2. Nc3 g6 3. e4 Bg7 4. g3 d6 5. Bg2 O-O 6. d3 c6 7. Nge2 Bg4 8. h3 Bxe2 9. Nxe2 Nbd7 10. O-O e5 11. Kh2 a5 12. Be3 Nc5 13. Qd2 Qc7 14. f4 Rae8 15. fxe5 Na4 16. exf6 Bxf6 17. Rxf6 1-0",
    B: "2047",
  },
  {
    W: "1753",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 d6 3. d4 Bg4 4. dxe5 Bxf3 5. gxf3 dxe5 6. Qxd8+ Kxd8 7. Bc4 f6 8. Nc3 Bb4 9. Bd2 Bxc3 10. Bxc3 Nc6 11. O-O-O+ Ke7 12. Bd5 Rd8 13. Rd3 Nh6 14. Rg1 g6 15. Rgd1 Rd6 16. Bxc6 bxc6 17. Bb4 Rd8 18. c4 Ke6 19. Bxd6 cxd6 20. Kc2 Nf7 21. b3 Rb8 22. Rg1 Ng5 23. h4 Nf7 24. h5 g5 25. Rg3 h6 26. Rg1 Ke7 27. Rgd1 Ke6 28. Kc3 Rb7 29. b4 Kd7 30. a4 Kc7 31. c5 dxc5 32. Rd7+ Kb6 33. Rxb7+ Kxb7 34. Rd7+ Kb6 35. Rxf7 cxb4+ 36. Kxb4 c5+ 37. Kc4 a5 38. Rxf6+ Kc7 39. Rxh6 g4 40. Rg6 gxf3 41. h6 Kd7 42. h7 Ke7 43. h8=Q Kd7 44. Qh7+ Ke8 45. Rg8# 1-0",
    B: "1761",
  },
  {
    W: "1435",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 d6 4. d5 Ne5 5. Bb5+ Bd7 6. Bxd7+ Nxd7 7. c4 Nb6 8. Nbd2 f5 9. exf5 e6 10. fxe6 Qe7 11. O-O a6 12. b3 g6 13. Ne4 Bg7 14. Bg5 Bxa1 15. Bxe7 Nxe7 16. Qxa1 Nf5 17. Qxh8+ 1-0",
    B: "1440",
  },
  {
    W: "1503",
    T: "60+0",
    P: "1. e4 d5 2. d4 dxe4 3. Nc3 Nf6 4. Bf4 e6 5. Nb5 Bd6 6. Bxd6 cxd6 7. Bc4 a6 8. Nxd6+ Qxd6 9. Ne2 b5 10. Bb3 O-O 11. c4 bxc4 12. Bxc4 Bb7 13. O-O Qb4 14. b3 Bd5 15. Bxd5 exd5 16. Nf4 Nbd7 17. a3 Qe7 18. Qd2 Rfb8 19. Qa5 Rb5 20. Qc3 Rab8 21. b4 h6 22. Qh3 R5b7 23. Nh5 Nxh5 24. Qxh5 Nf6 25. Qd1 Nd7 26. Qd2 Nb6 27. Qf4 Nc4 28. Qd2 Qc7 29. Qf4 Qxf4 0-1",
    B: "1501",
  },
  {
    W: "2170",
    T: "60+0",
    P: "1. d4 c6 2. c4 d5 3. Nc3 Nf6 4. Nf3 dxc4 5. e3 Bg4 6. Bxc4 e6 7. h3 Bh5 8. Be2 Be7 9. O-O Bg6 10. Bd2 Nbd7 11. Rc1 O-O 12. b3 Nd5 13. Na4 b5 14. Nc3 Qb6 15. Nxd5 cxd5 16. Bc3 Rac8 17. Bd3 a6 18. Bxg6 hxg6 19. Bb2 Nb8 20. Qd2 Qb7 21. Rxc8 Rxc8 22. Rc1 Rxc1+ 23. Qxc1 Qc6 24. Qxc6 Nxc6 25. Kf1 Kf8 26. a3 Ke8 27. Ke2 b4 28. a4 a5 29. Nd2 Kd7 30. Kd3 Kd6 31. f3 Bf6 32. e4 dxe4+ 33. fxe4 e5 34. d5 Ne7 35. Nc4+ Kc7 36. Nxa5 Kb6 37. Nc4+ Kc5 38. d6 Nc6 39. d7 Nb8 40. Bxe5 Nxd7 41. Bxf6 Nxf6 42. Na5 Nd7 43. Nb7+ Kb6 44. Nd6 Ne5+ 45. Kd4 f6 46. Ne8 Nc6+ 47. Kd5 Na5 48. Nd6 Nxb3 49. Nc4+ Kb7 50. e5 Nd2 51. Nxd2 Kc7 52. Nb3 Kd7 53. a5 fxe5 54. Kxe5 Kc7 55. Ke6 Kb7 56. Kf7 Kc6 57. Kxg7 Kb5 58. Kxg6 Ka4 59. a6 Kxb3 60. a7 Kc2 61. a8=Q b3 62. Qb8 b2 63. Qxb2+ Kxb2 64. h4 Kc3 65. h5 Kc4 66. h6 Kd4 67. h7 Ke4 68. h8=Q Kf4 69. Qf6+ Kg4 70. Qf5+ Kg3 71. Kf6 Kh2 72. Ke5 Kxg2 73. Ke4 Kh2 74. Qg5 Kh1 75. Kf3 Kh2 76. Qg2# 1-0",
    B: "2263",
  },
  {
    W: "1589",
    T: "60+0",
    P: "1. d4 d6 2. Nf3 c5 3. c4 cxd4 4. Nxd4 Nc6 5. Nc3 Nxd4 6. Qxd4 Nf6 7. Qd1 b6 8. Bg5 h6 9. e3 hxg5 10. Bd3 Ne4 11. Rc1 Nxc3 12. bxc3 e6 13. Bb1 f5 14. Qc2 f4 15. Qg6+ Kd7 16. exf4 Qf6 17. Qc2 Qxf4 18. O-O Be7 19. Rcd1 Bb7 20. Qa4+ Bc6 21. Qa6 Qg4 22. f3 Qf5 23. Bxf5 exf5 24. Rf2 Bf6 25. Rfd2 Bxc3 26. Rxd6+ Kc7 27. c5 Bb4 28. cxb6+ axb6 29. R6d3 Rxa6 30. Rb3 Bc5+ 31. Kh1 Rd8 32. Rc3 Rxd1# 0-1",
    B: "1603",
  },
  {
    W: "1883",
    T: "180+0",
    P: "1. d4 e6 2. e3 c5 3. Bd3 cxd4 4. exd4 d5 5. f4 Nf6 6. Nf3 Be7 7. O-O a6 8. Nbd2 b5 9. c3 Bb7 10. Ne5 Nc6 11. Qf3 O-O 12. g4 h6 13. h4 Ne4 14. Nxe4 dxe4 15. Bxe4 Nxe5 16. fxe5 Bxe4 17. Qxe4 Bxh4 18. Bf4 Qd5 19. Qxd5 exd5 20. Rf3 Rfe8 21. Raf1 Ra7 22. Rh3 Bg5 23. Bxg5 hxg5 24. Rf2 f6 25. exf6 Re1+ 26. Kg2 gxf6 27. Rxf6 Rf7 28. Rh8+ Kxh8 29. Rxf7 Re2+ 30. Rf2 Rxf2+ 31. Kxf2 Kg7 32. Ke3 Kf6 33. b3 Ke6 34. Kd3 Kd6 35. c4 dxc4+ 36. bxc4 b4 37. Ke4 a5 38. c5+ Kd7 39. d5 a4 40. Kd4 b3 41. axb3 a3 42. Kc3 Kc7 1-0",
    B: "1887",
  },
  {
    W: "1135",
    T: "120+1",
    P: "1. d4 d5 2. Bf4 Nf6 3. e3 Bf5 4. Nc3 e6 5. Bd3 Bg6 6. Nf3 c6 7. Ne5 Bd6 8. Nxg6 hxg6 9. Qf3 Qc7 10. O-O-O Nbd7 11. e4 dxe4 12. Bxd6 Qxd6 13. Nxe4 Nxe4 14. Bxe4 Nf6 15. Bd3 O-O-O 16. Kb1 Qxd4 17. Be2 Qa4 18. a3 Nd5 19. Rd3 f5 20. Rhd1 g5 21. g3 g4 22. Qg2 g5 23. Rb3 Nb6 24. Rxd8+ Rxd8 25. Bd3 Kb8 26. Qf1 Qd4 27. Qe2 1-0",
    B: "1092",
  },
  {
    W: "1515",
    T: "120+1",
    P: "1. e4 f5 2. exf5 d6 3. Qh5+ Kd7 4. Bb5+ c6 5. Ba4 Na6 6. d4 Nf6 7. Qh3 Nb4 8. Bg5 Qa5 9. Nc3 b5 10. Bb3 c5 11. Be6+ Kc6 12. Bd5+ Nbxd5 13. Qf3 Qb4 14. Bxf6 gxf6 15. Qxd5+ Kd7 16. Qe6+ Kd8 17. O-O-O Bxe6 18. fxe6 cxd4 19. Nd5 Qc5 20. Nf4 Qf5 21. Nge2 Bh6 22. g3 Bxf4+ 23. gxf4 Qxe6 24. Nxd4 Qe4 25. Rhe1 Qxf4+ 26. Kb1 Qxf2 27. Nc6+ Kd7 28. Nb4 Rac8 29. Nd5 Qxc2+ 30. Ka1 Rhg8 31. Rxe7+ Kd8 32. Ree1 Rg2 33. Rb1 Rc5 34. Ne3 Qf2 35. Nxg2 Qxg2 36. Rg1 Qxh2 37. Rg8+ Ke7 38. Rg7+ Kf8 39. Rgg1 Kf7 40. Rh1 Qg2 41. Rxh7+ Kg6 42. Rhh1 f5 43. Rhg1 Qxg1 44. Rxg1+ Kf6 45. a3 Ke5 46. Ka2 f4 47. Re1+ Kf5 48. Kb3 Re5 49. Rd1 d5 50. Kc3 f3 51. Rf1 Kf4 52. Kd3 Re3+ 53. Kd4 f2 54. Rxf2+ Rf3 55. Rh2 Rb3 56. Kxd5 Rxb2 57. Rxb2 Ke3 58. Rxb5 Kd3 59. Ra5 Kc3 60. Rxa7 Kb3 61. Kc5 Kc3 62. a4 Kb3 63. a5 Ka4 64. Rb7 Kxa5 65. Ra7# 1-0",
    B: "1311",
  },
  {
    W: "1071",
    T: "120+1",
    P: "1. e4 h5 2. Bc4 a5 3. Qf3 h4 4. Qxf7# 1-0",
    B: "902",
  },
  {
    W: "1866",
    T: "120+1",
    P: "1. e4 d6 2. d4 Nf6 3. Nc3 Nbd7 4. f3 c6 5. Be3 e5 6. Nge2 Be7 7. Qd2 Qa5 8. O-O-O b5 9. Kb1 b4 10. dxe5 dxe5 11. Nd5 cxd5 12. exd5 Bd6 13. Ng3 Nb6 14. b3 O-O 15. Bg5 Nfxd5 16. Ne4 Be7 17. Bc4 Nc3+ 18. Nxc3 bxc3 19. Qe3 Bxg5 20. Bxf7+ Kh8 21. Qxg5 Qa3 22. Qc1 Qxc1+ 0-1",
    B: "1916",
  },
  {
    W: "2040",
    T: "120+1",
    P: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5 Nfd7 5. f4 c5 6. Nf3 Nc6 7. Be3 Qb6 8. Na4 Qa5+ 9. c3 cxd4 10. b4 Qc7 11. Nxd4 a6 12. Bd3 g5 13. Nxc6 bxc6 14. O-O c5 15. Nxc5 Nxc5 16. bxc5 gxf4 17. Bxf4 Bxc5+ 18. Kh1 Bb7 19. Rb1 O-O-O 20. Qe2 Qa5 21. Bd2 Rhg8 22. Rxf7 Rd7 23. Rxd7 1-0",
    B: "2002",
  },
  {
    W: "1745",
    T: "120+1",
    P: "1. d4 d6 2. e4 Nf6 3. Bd3 Nc6 4. c3 g6 5. f4 Bg7 6. Nf3 Bg4 7. O-O e5 8. d5 Nb8 9. h3 Bxf3 10. Qxf3 Nbd7 11. Be3 c6 12. c4 Qc7 13. Nc3 O-O 14. f5 Nc5 15. Bc2 b5 16. b4 Ncd7 17. Bb3 bxc4 18. Bxc4 cxd5 19. Nxd5 Qxc4 20. Ne7+ Kh8 21. Rfe1 Qxe4 22. Qf2 Qxb4 23. Rab1 Qa4 24. fxg6 fxg6 25. Qe2 Ne4 26. Nd5 Rab8 27. Rxb8 Rxb8 28. Ra1 Nb6 29. Nc7 Nc3 30. Qd2 Nbd5 31. Nxd5 Nxd5 32. Qxd5 e4 33. Qf7 Bxa1 0-1",
    B: "1751",
  },
  {
    W: "1520",
    T: "120+1",
    P: "1. f4 d6 2. e4 e6 3. Nf3 c6 4. d4 Bd7 5. Bd3 d5 6. O-O dxe4 7. Bxe4 Bd6 8. c4 f5 9. Bd3 Nf6 10. Ne5 O-O 11. Nc3 Be8 12. Be3 Nbd7 13. Bf2 Bc7 14. Qf3 Nxe5 15. fxe5 Ng4 16. Bg3 Bh5 17. Qf4 Qe8 18. h3 Nh6 19. d5 cxd5 20. cxd5 exd5 21. Nxd5 Bxe5 22. Qxe5 Qxe5 23. Bxe5 Rad8 24. Ne7+ Kh8 25. Bxf5 Rde8 26. Bd6 Be2 27. Rf2 Nxf5 28. Rxf5 Rxf5 29. Nxf5 h6 30. Re1 Re6 31. Nd4 Rxd6 32. Nxe2 Rd2 33. Nf4 Rxb2 34. Re7 a6 35. Ng6+ Kh7 36. Nf8+ Kg8 37. Ne6 g5 38. Nc5 Rxa2 39. Rxb7 a5 40. Ra7 a4 41. Ne6 a3 42. Nd4 Ra1+ 43. Kf2 a2 44. Kg3 h5 45. h4 gxh4+ 46. Kh3 Rh1# 0-1",
    B: "1576",
  },
  {
    W: "1693",
    T: "120+1",
    P: "1. e4 c5 2. Bc4 Nc6 3. c3 d6 4. Nf3 a6 5. d4 cxd4 6. cxd4 b5 7. Bb3 Qb6 8. Be3 Qa5+ 9. Nc3 b4 10. Nd5 Bb7 11. Ke2 Nf6 12. Ke1 Nxd5 13. Bxd5 b3+ 14. Bd2 Qb6 15. Bxb3 Nxd4 16. Nxd4 Qxd4 17. f3 e6 18. Bc3 Qc5 19. Bd4 Qb4+ 20. Bc3 Qc5 21. Bd4 Qa5+ 22. Bc3 Qb6 23. Qd4 Qxd4 24. Bxd4 Be7 25. Bxg7 Rg8 26. Bh6 Rxg2 27. Rb1 Bh4+ 28. Kf1 Rf2+ 29. Kg1 Rxf3 30. Kg2 Bxe4 31. Ba4+ Ke7 32. Rbe1 Rg3+ 33. Kf2 Ra3+ 34. Ke2 Rxa4 35. Ref1 Bxh1 36. Rxh1 Rxa2 37. Bc1 Bf6 38. Kd1 Bxb2 39. Kc2 Bxc1+ 40. Kxc1 Rg2 0-1",
    B: "1647",
  },
  {
    W: "1706",
    T: "120+1",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 Nc6 4. Nc3 Nf6 5. d3 g6 6. a3 Bg7 7. O-O O-O 8. Ng5 h6 9. Nf3 Bg4 10. h3 Bxf3 11. Qxf3 Nd4 12. Qd1 e6 13. f4 d5 14. Ba2 dxe4 15. dxe4 a6 16. f5 Nh5 17. fxg6 fxg6 18. Rxf8+ Qxf8 19. Be3 Qd6 20. Qe1 Nxc2 21. Qc1 Nxa1 22. Qxa1 Rf8 23. Na4 Ng3 24. Bxc5 Bd4+ 25. Bxd4 Qxd4+ 26. Kh2 Nf1+ 27. Kh1 Qe5 28. Bxe6+ Qxe6 29. b4 Qd6 30. Kg1 Qh2# 0-1",
    B: "1715",
  },
  {
    W: "1862",
    T: "120+1",
    P: "1. d4 d5 2. Nf3 c6 3. Nbd2 Nd7 4. e3 Qc7 5. c3 e5 6. Be2 e4 7. Ng1 Bd6 8. g3 Ngf6 9. Bf1 h5 10. h4 Ng4 11. Qe2 f5 12. Bg2 Bxg3 13. fxg3 Qxg3+ 14. Kd1 Nf2+ 15. Kc2 Qxg2 16. Nh3 Qxh1 17. Nxf2 Qxh4 18. b3 c5 19. Bb2 cxd4 20. cxd4 Nb6 21. Rg1 g5 22. Kb1 g4 23. Qb5+ Bd7 24. Qc5 Qxf2 0-1",
    B: "1800",
  },
  {
    W: "1678",
    T: "180+2",
    P: "1. e4 e5 2. Nc3 Nc6 3. Bc4 Bc5 4. d3 Nge7 5. Bg5 O-O 6. Nf3 h6 7. Bh4 d6 8. Nd5 Bg4 9. h3 Be6 10. c3 a6 11. a4 Ba7 12. b4 Kh8 13. Bxe7 Nxe7 14. g4 Nxd5 15. Bxd5 Bxd5 16. exd5 Qf6 17. Qe2 e4 18. Nd4 exd3 19. Qxd3 Rfe8+ 20. Kd2 Qf4+ 21. Kc2 Qxf2+ 22. Kb3 Re3 23. Qf5 Bxd4 24. Qxf2 Rxc3+ 25. Kb2 Rf3+ 26. Qxd4 1-0",
    B: "1741",
  },
  {
    W: "1454",
    T: "300+0",
    P: "1. e4 b6 2. Nf3 Bb7 3. Bc4 Bxe4 4. Ne5 Bg6 5. Nxg6 hxg6 6. Qf3 e6 7. Qxa8 Ne7 8. O-O g5 9. Qf3 d5 10. Bb5+ c6 11. Bd3 b5 12. Qg4 f5 13. Qxg5 Kd7 14. Qf4 Ng6 15. Qg3 Qe8 16. b3 Be7 17. Ba3 Bh4 18. Qd6+ Kc8 19. g3 Bf6 20. Qb4 Ne5 21. Nc3 Nf3+ 22. Kg2 Qh5 23. h4 Nxd2 24. Rh1 Qf3+ 25. Kg1 g5 26. Be2 Qxc3 27. Qxc3 Bxc3 28. Rc1 gxh4 29. gxh4 Nd7 30. Kg2 Ne5 31. f4 Ng6 32. Kg3 Rg8 33. h5 Ne5+ 34. Kf2 Ng4+ 35. Kg3 Ne4+ 36. Kf3 Bd2 37. Rcd1 Ba5 38. b4 Bb6 39. h6 Rh8 40. h7 d4 41. Bd3 Kd8 42. Bxe4 fxe4+ 43. Kxg4 e3 44. Kf3 Ke8 45. Rdg1 Kf7 1-0",
    B: "1500",
  },
  {
    W: "792",
    T: "300+0",
    P: "1. a4 e5 2. Na3 Nc6 3. d3 d5 4. Be3 d4 5. Bd2 Nf6 6. c4 Nb4 7. Bxb4 Bxb4+ 8. Qd2 Bxd2+ 9. Kxd2 O-O 10. e4 c6 11. Be2 b5 12. axb5 cxb5 13. cxb5 Qa5+ 14. Kd1 Qxb5 15. Nxb5 Ba6 16. Nc7 Bb7 17. Ra5 a6 18. Rxe5 a5 19. Rg5 a4 20. Nf3 h6 21. Rb5 Ng4 22. Rxb7 Nxf2+ 23. Ke1 Nxh1 24. Nxd4 a3 25. Nxa8 Rxa8 26. Nb3 axb2 27. Nc5 Ra1+ 28. Kd2 b1=Q 29. Rxb1 Rxb1 30. Bh5 g6 31. Bg4 h5 32. Bd7 Rb2+ 33. Ke3 Rxg2 34. Nb7 Rxh2 35. Nd8 Rh3+ 36. Kd4 g5 37. e5 g4 38. e6 fxe6 39. Bxe6+ Kf8 40. Ke5 Rxd3 41. Nf7 Re3+ 42. Kf6 g3 43. Nh8 g2 44. Bd7 g1=Q 45. Ng6+ Kg8 46. Ne7+ Kh7 47. Bf5+ Kh6 48. Ng8+ Qxg8 49. Bd3 Qf8# 0-1",
    B: "1100",
  },
  {
    W: "1834",
    T: "300+0",
    P: "1. e4 e5 2. f4 d6 3. Nf3 Nc6 4. Bc4 Bg4 5. O-O f6 6. Qe1 Bxf3 7. Rxf3 Nd4 8. Rc3 Ne7 9. f5 Nec6 10. Qh4 a6 11. Qh5+ Kd7 12. Rd3 Qe8 13. Bf7 Qe7 14. Na3 Nd8 15. Bd5 c6 16. Bb3 Nxb3 17. axb3 Qf7 18. Qf3 Kc7 19. Nc4 g6 20. Rxd6 Bxd6 21. Qe3 c5 22. b4 b6 23. bxc5 Bxc5 0-1",
    B: "1824",
  },
  {
    W: "1506",
    T: "300+0",
    P: "1. e4 e6 2. f4 d5 3. e5 c5 4. d4 Qb6 5. c3 Nc6 6. Nf3 Nh6 7. Bd3 c4 8. O-O cxd3 9. Qxd3 Nf5 10. Be3 Nxe3 11. Qxe3 Be7 0-1",
    B: "1553",
  },
  {
    W: "2204",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. Nc3 f6 5. Na4 Bb6 6. Nxb6 axb6 7. Bxc6 dxc6 8. Nxe5 fxe5 9. Qh5+ Kf8 10. Qxe5 Qe7 11. Qf4+ Qf7 12. Qg3 Nf6 13. e5 Ne4 14. Qe3 Qf5 15. O-O g6 16. d3 Nc5 17. Qh6+ Kf7 18. Bg5 Nd7 19. Rae1 Nxe5 20. d4 Ng4 21. Re7+ 1-0",
    B: "2118",
  },
  {
    W: "1173",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 d5 4. Nc3 dxe4 5. Nd2 exd4 6. Ncxe4 Qd5 7. Nf3 Qxe4+ 8. Qe2 Bb4+ 9. Bd2 Bxd2+ 10. Nxd2 Qxe2+ 11. Bxe2 Bf5 12. O-O-O Nf6 13. Nf3 Rd8 14. Bb5 O-O 15. Bxc6 bxc6 16. Nxd4 Bg6 17. Nxc6 Rxd1+ 18. Rxd1 a6 19. h3 Ne4 20. f3 Nd6 21. Ne7+ Kh8 22. g4 Rd8 23. h4 f5 24. g5 Bh5 25. Rd5 Bxf3 26. Re5 Nc4 27. Rd5 Bxd5 28. Nxd5 Rxd5 29. b3 Ne3 30. c4 Rd1+ 31. Kb2 Rh1 32. a4 f4 33. b4 Nxc4+ 34. Kc3 f3 35. b5 axb5 36. axb5 f2 37. Kxc4 f1=Q+ 38. Kb4 Rxh4+ 39. Ka5 Qa1# 0-1",
    B: "1177",
  },
  {
    W: "1395",
    T: "300+0",
    P: "1. d4 e6 2. c4 g6 3. d5 Qh4 4. Qd3 d6 5. Nf3 Qf6 6. Nc3 Bg7 7. Bg5 Qf5 8. Qxf5 gxf5 9. e3 a6 10. dxe6 Bxe6 11. Be2 Nc6 12. O-O Nb4 13. a3 Nc2 14. Rac1 h6 15. Bf4 Bxc3 16. bxc3 Nxa3 17. c5 Nc4 18. Bxc4 Bxc4 19. Rfd1 a5 20. cxd6 cxd6 21. Rxd6 a4 22. Nd4 a3 23. Nxf5 a2 24. Ra1 h5 25. g3 Rh6 26. Rxh6 Nxh6 27. Nxh6 Ra3 28. Be5 Ke7 29. Kg2 f6 30. Bd4 Kd6 31. Nf5+ Kd5 32. Ne7+ Ke4 33. f3+ Kd3 34. Rd1+ Kc2 35. Ra1 Kb2 36. Rd1 a1=Q 37. Rd2+ Kb3 38. Bxf6 Ra2 39. Rxa2 Qxa2+ 40. Kh3 b5 41. Nf5 Qc2 42. Nd4+ Kxc3 43. Nxc2+ Kxc2 44. Kh4 b4 45. Kxh5 b3 46. g4 b2 47. Bxb2 Kxb2 48. f4 Be2 49. Kg5 Kc3 50. f5 Kd3 51. f6 Kxe3 52. f7 Kf2 53. f8=Q+ Kg2 54. Qh6 Kf3 55. h3 Bf1 56. h4 Bh3 57. Qf6+ Kg2 58. Qc6+ Kg3 59. Qc3+ Kh2 60. Qd2+ Kg3 61. Qe3+ Kh2 62. Qf2+ Kh1 63. Kf4 Bg2 64. h5 Kh2 65. h6 Kh3 66. h7 Bf3 67. h8=Q# 1-0",
    B: "1358",
  },
  {
    W: "1297",
    T: "300+0",
    P: "1. e4 e5 2. Qh5 Nc6 3. Bc4 g6 4. Qf3 Nd4 5. Qxf7# 1-0",
    B: "1358",
  },
  {
    W: "1452",
    T: "300+0",
    P: "1. e4 e5 2. f4 Nc6 3. Bc4 Bd6 4. Bxf7+ Kxf7 5. Nf3 Ke8 6. O-O exf4 7. d4 Nge7 8. e5 Bb4 9. c3 Ba5 10. Bxf4 Nd5 11. Bg5 Nde7 12. Nh4 h6 13. Qf3 Nxe5 14. dxe5 d6 15. Qf7+ Kd7 16. e6+ Kc6 17. Bxe7 Qd7 18. Ng6 Qe8 19. Nxh8 Qxf7 20. Nxf7 Bxe6 21. Na3 Re8 22. Bh4 Kd7 23. Nb5 a6 24. Nd4 Bc4 25. Rf4 b5 26. Nf5 Bxf7 27. Nxg7 Bd5 28. Nxe8 Kxe8 29. Rd1 Kd7 30. Rxd5 Kc6 31. Rh5 1-0",
    B: "1407",
  },
  {
    W: "1702",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Bc5 5. Nxf7 Bxf2+ 6. Kxf2 Nxe4+ 7. Ke3 d5 8. Nxd8 Bf5 9. Bxd5 Rxd8 10. Bxe4 Bxe4 11. Kxe4 O-O 12. d3 Rd4+ 13. Ke3 e4 14. Nc3 Re8 15. Qh5 exd3+ 16. Qxe8# 1-0",
    B: "1554",
  },
  {
    W: "1291",
    T: "300+0",
    P: "1. d4 d5 2. c4 dxc4 3. Nc3 Nf6 4. Qa4+ c6 5. Qxc4 Be6 6. Qb4 b5 7. Nxb5 cxb5 8. Qxb5+ Bd7 9. Qb7 Nc6 10. e3 Qb8 11. Qxb8+ Rxb8 12. Ba6 e6 13. a4 Bb4+ 14. Bd2 Bxd2+ 15. Kxd2 O-O 16. Bb5 Nb4 17. Bxd7 Nxd7 18. Nf3 Rfc8 19. Ke2 Rc2+ 20. Nd2 Rxb2 21. f4 Nd5 22. Rab1 Rxb1 23. Nxb1 h6 24. Kf3 g5 25. Ke4 f5+ 26. Kd3 Rb3+ 27. Kc4 Rxe3 28. Kb5 Rb3+ 29. Kc6 Nb8+ 30. Kd6 Rb6+ 31. Ke5 Nc6+ 32. Kxe6 Ne3 33. Kd7 Nxd4 34. Nc3 Rb3 35. Nb5 Nxb5 36. axb5 Rxb5 37. Ra1 Rb7+ 38. Ke6 gxf4 39. Ke5 Nxg2 40. Kxf5 Rf7+ 41. Kg4 f3 42. Kg3 Ne3 43. Rg1 f2 44. Ra1 f1=Q 45. Rxf1 Nxf1+ 46. Kg2 Ne3+ 47. Kg3 a6 48. h4 a5 49. h5 a4 50. Kh4 a3 51. Kg3 a2 52. Kh4 a1=Q 53. Kg3 Qa4 54. Kh3 Rg7 55. Kh2 Qh4# 0-1",
    B: "1218",
  },
  {
    W: "1133",
    T: "300+0",
    P: "1. d4 d5 2. c4 Nf6 3. Nf3 Nc6 4. Nc3 dxc4 5. e3 Bf5 6. Bxc4 h6 7. Qb3 e6 8. Ne5 Nxe5 9. dxe5 Ng4 10. Qa4+ c6 11. O-O b5 12. Bxb5 cxb5 13. Qxb5+ Ke7 14. h3 Bd3 15. Qc5+ Ke8 16. Qc6+ Ke7 17. Rd1 Rc8 18. Qb7+ Rc7 19. Qb4+ Ke8 20. Qd4 Rd7 21. Qxg4 Bf5 22. Qe2 Bd3 23. Rxd3 Rxd3 24. Qe1 Bb4 25. a3 Bxc3 26. bxc3 Rd1 27. Qxd1 Qxd1+ 28. Kh2 Kd7 29. Bb2 Qc2 30. Ra2 Rb8 31. f4 Rxb2 32. Rxb2 Qxb2 33. c4 Qxa3 0-1",
    B: "1125",
  },
  {
    W: "1899",
    T: "30+1",
    P: "1. d4 e6 2. c4 d5 3. Nc3 Nf6 4. Bf4 Bd6 5. Bg3 Bxg3 6. hxg3 Ne4 7. e3 Nxc3 8. bxc3 c6 9. Qc2 g6 10. Nf3 O-O 11. Bd3 Qf6 12. O-O Nd7 13. Rab1 e5 14. cxd5 cxd5 15. dxe5 Nxe5 16. Nxe5 Qxe5 17. Rb5 f5 18. c4 Be6 19. cxd5 Bd7 20. Rxb7 Qd6 21. Bc4 a6 22. Qb3 Bb5 23. Bxb5 axb5 24. Rxb5 Qa3 25. Qxa3 Rxa3 26. Rb2 Rfa8 27. Rd1 Kf8 28. d6 Ke8 29. d7+ Kd8 30. Rc2 Rxa2 31. Rxa2 Rxa2 32. f3 Ra3 33. Kf2 Ra2+ 34. Kg1 h6 35. e4 fxe4 36. fxe4 Re2 37. Rd4 Re3 38. Kf2 Ra3 39. Ke2 Ra2+ 40. Rd2 Rxd2+ 41. Kxd2 Kxd7 42. Ke3 Ke6 43. Kf4 g5+ 44. Kg4 Kf7 45. Kf5 Kg7 46. g4 Kf7 47. e5 Ke7 48. e6 Kf8 49. Kf6 h5 50. gxh5 g4 51. h6 Kg8 52. e7 Kh7 53. e8=Q Kxh6 54. Qg6# 1-0",
    B: "1561",
  },
  {
    W: "2330",
    T: "60+0",
    P: "1. d4 Nf6 2. Bf4 d6 3. Bg3 d5 4. Nf3 c5 5. e3 Nc6 6. c3 Bg4 7. Be2 e6 8. Nbd2 Bd6 9. O-O Bxg3 10. hxg3 h5 11. Nh4 g5 12. Nhf3 Ne4 13. Nxe4 dxe4 14. Nd2 f5 15. dxc5 Qe7 16. Nc4 Rd8 17. Nd6+ Kf8 18. Qc2 Bxe2 19. Qxe2 h4 20. g4 f4 21. Nxe4 h3 22. g3 h2+ 23. Kh1 Qh7 24. Qf3 Ne5 25. Qg2 f3 26. Qxf3+ Nxf3 27. Nf6 Qh3 28. Nh5 Nh4 0-1",
    B: "2466",
  },
  {
    W: "1892",
    T: "120+1",
    P: "1. b3 g6 2. Bb2 Nf6 3. e3 Bg7 4. Nf3 O-O 5. Be2 d5 6. O-O c6 7. d4 Bg4 8. Ne5 Bxe2 9. Qxe2 Nbd7 10. c4 Ne4 11. f3 Nd6 12. c5 Nb5 13. a4 Nc7 14. Nxd7 Qxd7 15. e4 dxe4 16. fxe4 Bxd4+ 17. Kh1 Bxb2 18. Qxb2 Ne6 19. b4 Qd4 20. Qxd4 Nxd4 21. Nc3 e5 22. b5 cxb5 23. Nxb5 Nxb5 24. axb5 Rfc8 25. Rfc1 f5 26. exf5 gxf5 27. c6 bxc6 28. Rxc6 Rxc6 29. bxc6 Kf7 30. c7 Ke6 31. Rxa7 Rc8 32. Rb7 Kd6 33. Rb8 Rxc7 34. Rd8+ Ke6 35. Rd1 e4 36. h3 Ke5 37. Re1 f4 38. Kh2 e3 39. g3 Ke4 40. gxf4 Kxf4 41. Rf1+ Ke4 42. Re1 Kf3 43. Rf1+ Ke2 44. Rg1 Kd2 45. Kg3 Rf7 46. Rg2+ e2 47. h4 Rg7+ 48. Kf2 Rxg2+ 49. Kxg2 e1=Q 50. h5 Qh4 51. h6 Qxh6 52. Kg3 Qf6 53. Kg4 Ke3 54. Kg3 Qg5+ 55. Kh3 Kf2 0-1",
    B: "1881",
  },
  {
    W: "1798",
    T: "180+0",
    P: "1. e3 d5 2. b3 e6 3. Bb2 Nf6 4. a3 Be7 5. d3 Nbd7 6. h3 b6 7. g4 Bb7 8. Bg2 Rb8 9. Nd2 c5 10. g5 Ng8 11. h4 d4 12. Bxb7 Rxb7 13. exd4 cxd4 14. Qf3 Rb8 15. Bxd4 e5 16. Bc3 Bd6 17. Ne4 Bc7 18. Qg4 Ne7 19. f4 O-O 20. f5 Nd5 21. Bd2 Nc5 22. Nf3 Nxe4 23. Qxe4 Nf4 24. Rh2 Qd6 25. Bb4 Qc6 26. Qxc6 1-0",
    B: "1812",
  },
  {
    W: "2339",
    T: "180+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. d4 d6 5. f4 dxe5 6. fxe5 Nc6 7. Be3 Bf5 8. Nc3 e6 9. Rc1 Bb4 10. Be2 O-O 11. Nf3 Bg4 12. O-O Bxf3 13. Rxf3 Na5 14. c5 Bxc3 15. bxc3 Nd5 16. Bf2 f5 17. exf6 Nxf6 18. Bg3 c6 19. Bd6 Re8 20. Qa4 b5 21. Qc2 Nc4 22. Bg3 Qd5 23. Rcf1 e5 24. Bxc4 bxc4 25. Bxe5 Ne4 26. Rf5 Nxc5 27. Rh5 Qe4 28. Qf2 Qg6 29. dxc5 Qxh5 30. Bd6 h6 31. Qf4 Qd5 32. h3 Re4 33. Qg3 Rae8 34. Kh2 Qg5 35. Qxg5 hxg5 36. Rf5 g4 37. Rg5 gxh3 38. Kxh3 Kh7 39. Rh5+ Kg6 40. Rh4 Rxh4+ 41. Kxh4 Re3 42. Bb8 a6 43. Be5 Rxe5 0-1",
    B: "2231",
  },
  {
    W: "1978",
    T: "180+0",
    P: "1. d4 d6 2. Nf3 g6 3. e3 Bg7 4. Bd3 Nf6 5. O-O O-O 6. b3 c5 7. Bb2 cxd4 8. exd4 Nc6 9. a3 Bg4 10. Nbd2 Rc8 11. h3 Bxf3 12. Nxf3 Re8 13. c4 a6 14. d5 Nb8 15. Qc2 Nbd7 16. b4 b5 17. Rac1 bxc4 18. Bxc4 Nb6 19. Qe2 Nbxd5 20. Bxa6 Rxc1 21. Rxc1 Qb6 22. Bd4 Qb8 23. b5 e5 24. Bb2 Qb6 25. Nd2 h6 26. Nc4 Qb8 27. a4 Nb6 28. a5 Nxc4 29. Qxc4 d5 30. Qc7 e4 31. Be5 Qxc7 32. Bxc7 d4 33. b6 d3 34. b7 Nd5 35. b8=Q Rxb8 36. Bxb8 d2 37. Rd1 Bc3 38. Bc4 Nb4 39. a6 Nc6 40. a7 Nxa7 41. Bxa7 Kg7 42. Be3 f5 43. Bxd2 Be5 44. Be3 1-0",
    B: "2184",
  },
  {
    W: "1948",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nf3 Qd8 4. d4 Nf6 5. h3 c6 6. Bd3 Bf5 7. O-O Bg6 8. Re1 e6 9. Bf4 Bd6 10. Bxd6 Qxd6 11. c3 Nbd7 12. Qc2 Bxd3 13. Qxd3 Nc5 14. Qc2 Ncd7 15. Nbd2 O-O-O 16. b4 Nb6 17. b5 c5 18. dxc5 Qxc5 19. Ne4 Nxe4 20. Qxe4 Rd7 21. Ne5 Rc7 22. c4 f6 23. Nd3 Qxc4 24. Qxe6+ Qxe6 25. Rxe6 Rd8 26. Nf4 Nc4 27. a4 Ne5 28. a5 g5 29. b6 Rf7 30. bxa7 Kc7 31. Rc1+ Nc6 32. Nd5+ Kd7 33. Nb6+ Kxe6 34. a8=Q Rxa8 35. Nxa8 Nxa5 36. Nb6 Kf5 37. Rc5+ Kg6 38. Rxa5 Rc7 39. Nd5 Rc1+ 40. Kh2 Rd1 41. Ne3 Rb1 42. g4 b5 43. Kg2 b4 44. Rb5 b3 45. Nc4 Rc1 46. Nb2 Rc3 47. Nd1 Rd3 48. Nb2 Rc3 49. Na4 Rd3 50. Nc5 Rd5 51. Rxb3 Rxc5 52. Re3 Rc2 53. Re6 Kf7 54. Re3 Kg6 55. Rf3 Rc6 56. Rf5 h6 57. Rf3 Kf7 58. Re3 Rc5 59. Rf3 Kg6 60. Re3 Re5 61. Rf3 f5 62. gxf5+ Rxf5 63. Re3 Rf6 64. Re4 Rb6 65. f3 Ra6 66. Kg3 Rb6 67. Re2 Rc6 68. Re5 Rd6 69. Re2 Rf6 70. Rf2 h5 71. Re2 h4+ 72. Kg2 Rf4 73. Rd2 Rf6 74. Re2 Ra6 75. Rd2 Rb6 76. Rf2 Rc6 77. Re2 Rd6 78. Rf2 g4 79. Rc2 gxf3+ 80. Kxf3 Rf6+ 81. Kg2 Rf2+ 0-1",
    B: "2110",
  },
  {
    W: "1693",
    T: "180+0",
    P: "1. d4 Nf6 2. e3 g6 3. Bd3 Bg7 4. Ne2 Kf8 5. O-O d6 6. f4 Bg4 7. Nd2 Nc6 8. Nf3 Bxf3 9. Rxf3 e6 10. c4 d5 11. c5 Qd7 12. Ng3 h5 13. Rf1 h4 14. Ne2 Ng4 15. h3 Nf6 16. Qc2 Rh6 17. b3 Ke7 18. a4 a5 19. Bd2 Ne8 20. e4 dxe4 21. Bxe4 f5 22. Bxc6 Qxc6 23. Bc3 Nf6 24. Rae1 Ne4 25. Bb2 Rg8 26. Nc3 Ng3 27. Rf3 Bxd4+ 28. Kh2 Ne4 29. Nxe4 Bxb2 30. Qxb2 fxe4 31. Rfe3 Qxc5 32. Rxe4 Qf5 33. Rxe6+ Kd7 34. Qd2+ Kc8 35. Re8+ Rxe8 36. Rxe8# 1-0",
    B: "1641",
  },
  {
    W: "1946",
    T: "180+0",
    P: "1. b3 d5 2. Bb2 Nc6 3. Nf3 Nf6 4. e3 Bg4 5. Be2 e6 6. O-O Be7 7. Ne5 Bxe2 8. Qxe2 Nxe5 9. Bxe5 c5 10. d4 cxd4 11. Bxd4 O-O 12. Qf3 Rc8 13. c3 b6 14. Nd2 Qd6 15. Rfe1 e5 16. Bxe5 Qxe5 17. Nf1 Rxc3 18. Ng3 g6 19. Rad1 Rfc8 20. h4 Nh5 21. Ne2 Rc2 22. g4 Ng7 23. a4 Bd6 24. Nf4 Be7 25. Rxd5 Qb2 26. h5 Bh4 27. Re2 Rxe2 28. Nxe2 Qxb3 29. hxg6 hxg6 30. Rd7 Rf8 31. Nf4 Qxa4 32. Rd4 Qa1+ 33. Kg2 Bf6 34. Rd6 Qe5 35. Rd5 Qc7 36. g5 Bd8 37. Qd1 Nf5 38. Rd7 Qc6+ 39. e4 Bxg5 40. Nxg6 Qxg6 41. exf5 Qxf5 42. Kf1 Bf6 43. Qe2 Qb1+ 44. Kg2 Qg6+ 45. Kf1 Kg7 46. Rd3 Rh8 47. Rg3 Qxg3 48. fxg3 Rh1+ 49. Kg2 Rh6 50. Qg4+ Rg6 51. Qf4 Be7 52. g4 Bf6 53. Qf5 Rg5 54. Qf4 Rxg4+ 55. Qxg4+ Kf8 56. Qf3 Ke7 57. Qa8 Ke6 58. Qxa7 Kf5 59. Qxb6 Kg6 60. Qe3 Kg7 61. Qg3+ Kf8 0-1",
    B: "1981",
  },
  {
    W: "1747",
    T: "180+0",
    P: "1. d4 d5 2. Nf3 Nf6 3. g3 Bg4 4. Bg2 e6 5. Nc3 h6 6. Bf4 Bd6 7. Bxd6 Qxd6 8. O-O O-O 9. Nb5 Qd7 10. Qd3 Bf5 11. Qb3 b6 12. Rfe1 c5 13. Nd2 c4 14. Qb4 Nc6 15. Qc3 a6 16. Na3 b5 17. b4 h5 18. e4 Nxe4 19. Nxe4 Bxe4 20. Bxe4 dxe4 21. Rxe4 Qd6 22. Rae1 Qxb4 23. Qxb4 Nxb4 24. Nb1 Nxc2 25. R1e2 Nb4 26. Nc3 Nd5 27. Nxd5 exd5 28. Re7 g6 29. Rb7 Kg7 30. Ree7 Kf6 31. Kg2 Rfe8 32. Rec7 Re4 33. Rc6+ Kg7 34. Rcc7 Rf8 35. Ra7 Rxd4 36. Rxa6 Rd2 37. Rd6 d4 38. Rcd7 d3 39. Rb6 Rb2 40. Rdb7 d2 41. Rxb5 Rxb5 42. Rxb5 0-1",
    B: "1769",
  },
  {
    W: "1838",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d4 exd4 5. O-O Nf6 6. e5 Ne4 7. Re1 d5 8. exd6 Bf5 9. Ng5 O-O 10. Nxe4 Na5 11. dxc7 Qxc7 12. Nxc5 Qxc5 13. Bd3 Be6 14. c3 dxc3 15. Nxc3 Rad8 16. Qf3 Nc6 17. Be3 Ne5 18. Qe4 Qa5 19. Qxh7# 1-0",
    B: "1550",
  },
  {
    W: "1776",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d4 e6 5. Nf3 Nf6 6. Bd3 Be7 7. O-O a6 8. a3 b5 9. b4 Bb7 10. Be3 Nbd7 11. Ne5 c6 12. Ne2 O-O 13. Ng3 a5 14. Nf3 axb4 15. axb4 Bxb4 16. Rb1 Be7 17. c3 Qc7 18. Ne5 Nxe5 19. dxe5 Qxe5 20. Re1 Qxc3 21. Bd4 Qa5 22. Bxf6 Bxf6 23. Nh5 Bc3 24. Re3 b4 25. Rg3 g6 26. Qf3 Qxh5 27. Qf4 c5 28. Rh3 Qe5 29. Qh6 Qg7 30. Rd1 Qxh6 31. Rxh6 Kg7 32. Rh3 Bd4 33. Bc4 Ba6 34. Bxa6 Rxa6 35. g3 Rc8 36. Rd3 Rb6 37. Kg2 b3 38. g4 b2 39. Rb3 Rxb3 40. Rxb3 c4 41. Rb4 c3 42. Kf3 c2 43. Rc4 c1=Q 44. Rxc1 0-1",
    B: "1789",
  },
  {
    W: "1910",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 e6 3. d3 Nc6 4. Be2 Qc7 5. O-O Nf6 6. Ne1 h5 7. f4 g5 8. f5 Ng4 9. Bxg4 hxg4 10. g3 Ne5 11. Bxg5 Bg7 12. Nc3 a6 13. f6 Bh6 14. Bh4 Be3+ 15. Kh1 b5 16. Ne2 Bb7 17. Nf4 O-O-O 18. Qe2 Bxf4 19. Rxf4 Rxh4 20. gxh4 Rh8 21. Qf2 Ng6 22. Rxg4 Qe5 23. Nf3 Qxb2 24. Rag1 d6 25. Rxg6 fxg6 26. Rxg6 Qc1+ 27. Qg1 Qf4 28. Rg8+ Rxg8 29. Qxg8+ Kc7 30. Qg7+ Kb6 31. Qg2 Qxf6 32. Kg1 c4 33. Kf2 cxd3 34. cxd3 d5 35. e5 Qf4 36. d4 Bc6 37. Qf1 Be8 38. Qd3 Bh5 39. Qe3 1-0",
    B: "1930",
  },
  {
    W: "1261",
    T: "180+0",
    P: "1. g3 e5 2. Bg2 Nf6 3. b3 Bc5 4. Bb2 d6 5. e3 Ng4 6. h3 Nf6 7. g4 Be6 8. Nf3 Qd7 9. Ng5 O-O 10. Nc3 Nc6 11. Na4 Nb4 12. Nxc5 dxc5 13. Bxe5 Nbd5 14. Nxe6 Qxe6 15. Bb2 Ne4 16. d3 Ng5 17. f4 Qxe3+ 18. Qe2 Qxf4 19. Bxd5 Nf3+ 20. Qxf3 Qxf3 21. Bxf3 Rae8+ 22. Kd2 f6 23. Bd5+ Kh8 24. Bxb7 Re6 25. Rhe1 Rxe1 26. Rxe1 h6 27. Be4 g6 28. Bxg6 Kg7 29. Bf5 c6 30. Re7+ Rf7 31. Rxf7+ Kxf7 32. Ke3 a5 33. d4 cxd4+ 34. Kxd4 Ke7 35. Kc5 Kd8 36. Bxf6+ Kc7 37. Be5+ Kb7 38. h4 Ka6 39. g5 hxg5 40. hxg5 a4 41. bxa4 Ka5 42. g6 Kxa4 43. Kxc6 Ka3 44. g7 Kxa2 45. g8=Q+ Kb1 46. Qb3+ Kc1 47. Bf4+ Kd1 48. Qd3+ Ke1 49. Bg4 Kf2 50. Qf3+ Ke1 51. Qe2# 1-0",
    B: "1257",
  },
  {
    W: "1815",
    T: "180+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. f4 e6 4. Nf3 d5 5. d4 cxd4 6. Nxd4 Nf6 7. Nxc6 bxc6 8. e5 Nd7 9. Be3 c5 10. Bb5 d4 11. Ne4 dxe3 12. Nd6+ Bxd6 13. Qxd6 Qe7 14. Qc7 O-O 15. O-O-O Nb6 16. Qxe7 1-0",
    B: "1829",
  },
  {
    W: "1839",
    T: "180+0",
    P: "1. f4 e6 2. Nf3 d5 3. e3 c5 4. Be2 Nc6 5. O-O Nf6 6. c3 Bd6 7. d3 O-O 8. a3 a6 9. Nbd2 b5 10. d4 c4 11. Ne5 Bb7 12. Ndf3 Ne4 13. Nd2 Nxd2 14. Bxd2 Ne7 15. Bg4 g6 16. Qe1 h5 17. Be2 f6 18. Nf3 Nf5 19. Nh4 Kf7 20. Nxf5 exf5 21. Qh4 Qe7 22. Bd1 Rae8 23. Bc2 Bc8 24. Qg3 Be6 25. h4 Rg8 26. Kf2 Qd8 27. Ke2 a5 28. Rh1 Qc7 29. Qf3 Bxf4 30. exf4 Bd7+ 31. Kf2 Bc6 32. Rae1 Rxe1 33. Rxe1 Re8 34. Rxe8 Kxe8 35. Qe3+ Kf7 36. Bd1 Qd6 37. Bf3 a4 38. Bc1 Bd7 39. Kg1 Be6 40. g3 Qc7 41. Qf2 Qd6 42. Qg2 Bd7 43. Bxd5+ Qxd5 44. Qxd5+ Be6 45. Qb7+ Kg8 46. Qxb5 Kg7 47. Qxa4 g5 48. hxg5 fxg5 49. fxg5 Kg6 50. Qe8+ Bf7 51. Qc6+ 1-0",
    B: "1891",
  },
  {
    W: "1469",
    T: "180+0",
    P: "1. e4 e5 2. f4 d6 3. Nf3 Nc6 4. Bc4 Nf6 5. d3 Be7 6. fxe5 dxe5 7. O-O O-O 8. Bd2 Bg4 9. Bc3 Bxf3 10. Rxf3 a6 11. a3 b5 12. Ba2 Nd4 13. Rf2 Bc5 14. Rf1 a5 15. Kh1 a4 16. b4 axb3 17. cxb3 Bb6 18. b4 c5 19. Nd2 cxb4 20. Bxb4 Re8 21. Nf3 Nc6 22. Bc3 Rxa3 23. Bb2 Rxd3 24. Qc2 Nb4 25. Qb1 Nxa2 26. Qxa2 Nxe4 27. Nxe5 Nc3 28. Qxf7+ Kh8 29. Nxd3 1-0",
    B: "1498",
  },
  {
    W: "2072",
    T: "900+2",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd6 4. d4 Nf6 5. Nf3 c6 6. Ne5 Be6 7. Bf4 Nd5 8. Ne4 Qb4+ 9. c3 Qb6 10. Bg3 Nd7 11. Nc4 Qd8 12. Bd3 g6 13. Qe2 Bg7 14. Ng5 Nf8 15. O-O Qd7 16. Ne5 Bxe5 17. Bxe5 f6 18. Nxe6 fxe5 19. Qxe5 Rg8 20. Nc5 Qc7 21. Qxc7 Nxc7 22. Rfe1 b6 23. Bc4 Rg7 24. Ne4 O-O-O 25. Re2 Nfe6 26. Rae1 Nf4 27. Re3 Nfd5 28. Rf3 h6 29. g3 g5 30. Kf1 h5 31. Rf5 Rdg8 32. Be2 h4 33. Bg4 e6 34. Re5 hxg3 35. hxg3 Kb7 36. Nd6+ Ka6 37. Bxe6 Nxe6 38. Rxe6 c5 39. dxc5 Nc7 40. Re7 Rxe7 41. Rxe7 Nd5 42. Re6 bxc5 43. Re5 Nf6 44. Rxc5 Kb6 45. b4 Nd7 46. Rf5 Kc6 47. Ne4 g4 48. c4 Nb6 49. Rf6+ Kc7 50. Rf7+ Kb8 51. c5 Nd5 52. c6 Nxb4 53. c7+ Kc8 54. Nd6# 1-0",
    B: "1968",
  },
  {
    W: "1874",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 Nf6 3. Bf4 e6 4. e3 Bd6 5. c3 Bxf4 6. exf4 Qd6 7. Ne5 Nc6 8. Bd3 Bd7 9. O-O O-O-O 10. Nd2 Qe7 11. Ndf3 Nxe5 12. fxe5 Ne4 13. Qc2 Ng5 14. Nxg5 Qxg5 15. h3 h5 16. f4 Qg3 17. f5 exf5 18. Bxf5 Bxf5 19. Qxf5+ Kb8 20. Rf3 Qg6 21. Qxf7 Qxf7 22. Rxf7 g5 23. Raf1 g4 24. e6 gxh3 25. gxh3 Rde8 26. e7 Kc8 27. Rf8 Kd7 28. Rxh8 Rxh8 29. Re1 Re8 30. Kg2 Rxe7 31. Rxe7+ Kxe7 32. Kg3 Kd6 33. Kh4 c5 34. Kxh5 cxd4 35. cxd4 Ke6 36. Kg5 Kf7 37. h4 b5 38. h5 a5 39. Kf5 Ke7 40. Ke5 Kf7 41. Kxd5 Kg7 42. Kc5 Kh6 43. d5 Kxh5 44. d6 a4 45. d7 b4 46. a3 bxa3 47. bxa3 Kg6 48. d8=Q Kf5 49. Qa5 Ke6 50. Qxa4 1-0",
    B: "1842",
  },
  {
    W: "2085",
    T: "60+0",
    P: "1. d4 e6 2. c4 d5 3. Nc3 Nf6 4. e4 Be7 5. e5 Nfd7 6. Nf3 O-O 7. Bd3 c6 8. c5 f5 9. exf6 Nxf6 10. O-O Nbd7 11. Ne5 Nxe5 12. dxe5 Ne4 13. Bxe4 dxe4 14. Nxe4 Qc7 15. Bf4 Rxf4 16. Nd6 Bd7 17. Qh5 Bxd6 18. exd6 Qd8 19. g3 Rf5 20. Qf3 Rxf3 21. Kg2 Rf5 22. b4 a5 23. a3 axb4 24. axb4 Rxa1 25. Rxa1 e5 26. g4 Rf4 27. h3 Qf8 28. Ra2 Rxb4 29. Rd2 Rc4 30. Re2 Rxc5 31. Rb2 b5 32. Re2 Rc4 33. f3 Qxd6 34. Rd2 Qxd2+ 35. Kg3 Qf4+ 36. Kf2 Rc2+ 37. Ke1 Qc1# 0-1",
    B: "2143",
  },
  {
    W: "1549",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 e6 7. Bc4 Qc7 8. Bb3 Be7 9. Qd2 b5 10. O-O-O Bb7 11. f3 O-O 12. g4 Nbd7 13. h4 Nc5 14. g5 Nxb3+ 15. axb3 Nd7 16. h5 Ne5 17. f4 Nd7 18. g6 h6 19. gxf7+ Rxf7 20. Rdg1 Nc5 21. Rg6 Nxe4 22. Qg2 Nxc3 23. Rxg7+ Rxg7 24. Qd2 Ne4 25. Qe2 Rc8 26. Re1 Ng3 27. Qf2 Ne4 28. Qe2 Nf6 29. Bd2 Rg2 30. Qxe6+ Kh7 31. Qxe7+ Qxe7 32. Rxe7+ Kg8 33. Rxb7 Ne4 34. Ne6 Nf6 35. Rg7+ Kh8 36. Rxg2 Nd5 37. Rg7 1-0",
    B: "1547",
  },
  {
    W: "2071",
    T: "60+0",
    P: "1. d4 d5 2. Bf4 c6 3. Nf3 e6 4. e3 f5 5. Bd3 Nf6 6. Nbd2 Ne4 7. Ne5 Be7 8. f3 O-O 9. fxe4 dxe4 10. Be2 Nd7 11. O-O Nxe5 12. Bxe5 Bd6 13. Bxd6 Qxd6 14. c4 Qe7 15. d5 Qh4 16. dxe6 Bxe6 17. Nb3 Rf6 18. Qe1 Qg5 19. Qg3 Qh6 20. Nd4 Rg6 21. Qf4 Qxf4 22. Rxf4 Bd7 23. Nxf5 Rf6 24. Ne7+ Kf7 25. Rxf6+ gxf6 26. Nxc6 Bxc6 27. Rf1 Ke6 28. b3 Rd8 29. Bg4+ Ke5 30. Rf5+ Kd6 31. Rxf6+ Kc5 32. Rf5+ Kb4 33. Rf7 Ka3 34. Bf5 Kxa2 35. Rxh7 Kxb3 36. Re7 Kxc4 37. Bxe4 Bxe4 38. Rxe4+ Kd3 39. Re7 b5 40. Rxa7 b4 41. Rb7 Kc3 42. e4 b3 43. e5 Kc2 44. e6 Re8 45. Kf2 b2 46. Kf3 b1=Q 47. Rxb1 Kxb1 48. g4 Rxe6 49. h4 Rf6+ 50. Ke4 Kc2 51. h5 Kc3 52. Ke5 Rf1 53. g5 Rg1 54. g6 Rg5+ 55. Kf6 Rxh5 56. g7 Rh2 57. Kf7 Rf2+ 58. Kg8 Rg2 0-1",
    B: "2062",
  },
  {
    W: "1238",
    T: "60+0",
    P: "1. e4 e6 2. Bc4 Nc6 3. d3 Nf6 4. Nc3 d5 5. exd5 exd5 6. Bb5 a6 7. Bxc6+ bxc6 8. d4 Ne4 9. Qe2 Bf5 10. f3 Bb4 11. Bd2 Bxc3 12. Bxc3 h5 13. fxe4 Bg4 14. Nf3 O-O 15. e5 Re8 16. O-O-O Bxf3 17. Qxf3 Qg5+ 18. Bd2 f6 19. Bxg5 fxe5 20. dxe5 Rxe5 21. Rhe1 Rxg5 22. Qf4 Rg6 23. Qf5 Rd6 24. Qxh5 Rdd8 25. Re8+ Rxe8 26. g4 Rf8 27. g5 Rae8 28. g6 Re7 29. Qh7# 1-0",
    B: "1207",
  },
  {
    W: "1631",
    T: "60+0",
    P: "1. e4 e5 2. d3 Bc5 3. Nc3 d6 4. Be3 Bb6 5. Bxb6 axb6 6. Nf3 Be6 7. Ne2 Nf6 8. Ng3 h6 9. Be2 Qd7 10. O-O Nc6 11. Nf5 Bxf5 12. exf5 Qxf5 13. Nh4 Qd7 14. Bf3 Qe6 15. Bxc6+ bxc6 16. Nf3 Rxa2 17. Rxa2 Qxa2 18. b3 Qa5 19. Qe2 O-O 20. Nd2 Qc5 21. Ne4 Qb4 22. Nxf6+ gxf6 23. Qg4+ Kh7 24. Qf5+ Kh8 25. Qxf6+ Kg8 26. Qxh6 Qg4 27. f3 Qg7 28. Qe3 f6 29. Qe4 Kf7 30. Qxc6 Ke7 31. Qxc7+ Ke6 32. Qxg7 Rf7 33. Qg4+ 1-0",
    B: "1559",
  },
  {
    W: "2026",
    T: "60+0",
    P: "1. d4 e6 2. c4 Qf6 3. Nc3 Qg6 4. e4 b6 5. f3 Bb7 6. Bd3 Qxg2 7. Qe2 Qxh1 8. Be3 Bd6 9. e5 Bb4 10. O-O-O Bxc3 11. bxc3 c5 12. d5 exd5 13. Nh3 Qxd1+ 14. Qxd1 Ne7 15. cxd5 Bxd5 16. c4 Be6 17. Nf4 Nbc6 18. Nxe6 fxe6 19. Be4 O-O-O 20. Qa4 Kb7 21. Bxc6+ Nxc6 22. Bf4 Nb4 23. a3 Nc6 24. Bg3 d5 25. exd6 Rxd6 26. Bxd6 Rd8 27. Bg3 Nd4 28. Qd1 Nc6 29. Qe1 Na5 30. Qxe6 Rc8 31. Qe7+ Ka6 32. Qe6 Nxc4 33. Qxc8+ Kb5 34. Qg4 Nxa3 35. Qf4 c4 36. Qe5+ Kb4 37. Qe7+ Kc3 38. Bd6 Nb1 39. Qe3# 1-0",
    B: "1915",
  },
  {
    W: "1770",
    T: "60+0",
    P: "1. d4 Nf6 2. Nc3 e6 3. e4 d5 4. Bg5 dxe4 5. Nxe4 Be7 6. Qf3 O-O 7. Bd3 h6 8. Nxf6+ Bxf6 9. Bxf6 gxf6 10. Qh5 Kg7 11. O-O-O f5 12. Nf3 Qf6 13. Ne5 Nc6 14. g4 Nxe5 15. dxe5 Qg6 16. Qxg6+ fxg6 17. gxf5 exf5 18. Rhg1 Re8 19. Bxf5 Bxf5 20. e6 0-1",
    B: "1739",
  },
  {
    W: "1479",
    T: "60+0",
    P: "1. d4 d6 2. c4 e5 3. Nc3 exd4 4. Qxd4 d5 5. Nf3 dxc4 6. Qxd8+ Kxd8 7. Bg5+ f6 8. Bf4 Bb4 9. O-O-O+ Bd7 10. Nd5 c6 11. Nc7 Kc8 12. Nxa8 b6 13. Nc7 Ne7 14. Bd6 Nd5 15. Nxd5 cxd5 16. Rxd5 Nc6 17. Nd4 Nxd4 18. Rxd4 Rd8 19. Bxb4 a5 20. Bd6 Bb5 21. e4 c3 22. Bxb5 cxb2+ 23. Kxb2 Kb7 24. Bc5 Rxd4 25. Bxd4 a4 26. Rc1 a3+ 27. Kb3 Ka7 28. e5 fxe5 29. Bxe5 g5 30. Rc7+ Kb8 31. Rxh7+ Kc8 32. Rh8+ Kb7 33. Bf6 Kc7 34. Bxg5 Kd6 35. f4 Kc5 36. f5 Kxb5 37. f6 Kc5 38. f7 Kd4 39. f8=Q Kd3 40. Qf3+ Kd4 41. Rd8+ Ke5 42. Rd3 Ke6 43. Qe4+ Kf7 44. Rd7+ Kf8 45. Qe7+ Kg8 46. Qg7# 1-0",
    B: "1423",
  },
  {
    W: "1703",
    T: "60+0",
    P: "1. e4 d5 2. Nf3 dxe4 3. Ng5 Nf6 4. f3 exf3 5. Nxf3 Nc6 6. Bc4 e6 7. O-O Bc5+ 8. Kh1 O-O 9. c3 Re8 10. d4 Bb6 11. a4 a5 12. b4 axb4 13. cxb4 Nxb4 14. Qb3 Nc6 15. Be3 e5 16. dxe5 Nxe5 17. Nxe5 Rxe5 18. Rd1 Qe7 19. Bxb6 cxb6 20. Nc3 Bg4 21. Rf1 Ne4 22. Nxe4 Rxe4 23. h3 Rd8 24. Rxf7 Qh4 25. Rd7+ Kh8 26. Rxd8+ Qxd8 27. Rf1 Bxh3 28. gxh3 Rxc4 29. Rf8+ Qxf8 30. Qxc4 h6 31. Qf1 1-0",
    B: "1715",
  },
  {
    W: "1822",
    T: "60+0",
    P: "1. e4 d5 2. e5 Nc6 3. f4 f6 4. Nf3 fxe5 5. fxe5 e6 6. Bb5 Be7 7. Bxc6+ bxc6 8. d3 Ba6 9. O-O c5 10. Kh1 c4 11. d4 c5 12. c3 cxd4 13. cxd4 Rb8 14. Nc3 Bb4 15. Bg5 Ne7 16. Bxe7 Qxe7 17. Nd2 Bxc3 18. bxc3 Qg5 19. Qf3 Kd7 20. Qf7+ Kc6 21. Qxe6+ Kb5 22. Qxd5+ Kb6 23. Qc5+ Kb7 24. Rf7+ Ka8 25. Qxa7# 1-0",
    B: "1790",
  },
  {
    W: "1627",
    T: "180+2",
    P: "1. e4 e5 2. Bc4 d6 3. Nf3 Nf6 4. O-O g6 5. d4 Bg7 6. dxe5 dxe5 7. Qxd8+ Kxd8 8. Nxe5 Nxe4 9. Nxf7+ Ke7 10. Nxh8 Nd6 11. Re1+ Kf8 12. c3 Nxc4 13. Na3 Nxa3 14. bxa3 Bxh8 15. Bb2 Nc6 16. Rad1 Ne7 17. Rd8+ Kg7 18. Rxe7+ Kh6 19. Rxh8 1-0",
    B: "1334",
  },
  {
    W: "2057",
    T: "180+2",
    P: "1. Nf3 c5 2. c4 g6 3. g3 Bg7 4. Bg2 Nc6 5. O-O Nf6 6. Nc3 O-O 7. d4 cxd4 8. Nxd4 Ne5 9. Qb3 d6 10. Bf4 Nfd7 11. Rad1 a6 12. Rfe1 Qc7 13. Nd5 Qd8 14. Bxe5 Nxe5 15. Nb6 Rb8 16. Nxc8 Qxc8 17. Rc1 Qc5 18. e3 Rfc8 19. Rc2 Nc6 20. Nxc6 bxc6 21. Qa4 a5 22. Rec1 Rxb2 23. Rxb2 Bxb2 24. Rc2 Bf6 25. h4 h5 26. Rd2 Kg7 27. Rd3 Qb4 28. Qc2 a4 29. a3 Qa5 30. Kh2 c5 31. Bd5 Rb8 32. Bc6 Qe1 33. Bxa4 Rb1 34. Rd1 Rxd1 35. Qxd1 Qxf2+ 36. Kh3 Qxe3 37. Bb5 Qxa3 38. Qe2 Qb3 39. Be8 e5 40. Kg2 Qb1 41. Bc6 Be7 42. Bd5 Qf5 43. Be4 Qg4 44. Bf3 Qd4 45. Bd5 f5 46. Qa2 e4 47. Qa7 Qd2+ 48. Kh3 Kf8 49. Qb8+ Kg7 50. Qe8 Bf6 51. Qf7+ Kh6 52. Qf8+ Bg7 53. Qg8 Qe1 54. Qf7 Qh1# 0-1",
    B: "2056",
  },
  {
    W: "1723",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 g6 3. d4 cxd4 4. Nxd4 Bg7 5. f3 Nc6 6. Bc4 Nxd4 7. Nc3 d6 8. Nd5 Nc6 9. c3 Nf6 10. Nf4 O-O 11. h4 a6 12. g4 Ne5 13. Bb3 Nfxg4 14. fxg4 Bxg4 15. Qc2 Nf3+ 16. Kf1 Rc8 17. Qg2 Ne5 18. h5 Bf3 19. Qh3 Bxh1 20. Qxh1 Qb6 21. hxg6 hxg6 22. Nd5 Qd8 23. Bh6 Bxh6 24. Qxh6 e6 25. Ne3 Qf6+ 26. Ke2 Qf3+ 27. Kd2 Qf2+ 28. Kc1 Qe1+ 29. Bd1 Nd3+ 30. Kc2 1-0",
    B: "1731",
  },
  {
    W: "1778",
    T: "180+2",
    P: "1. Nf3 Nf6 2. c4 e6 3. Nc3 d5 4. e3 dxc4 5. Bxc4 Be7 6. O-O a6 7. d4 b5 8. Bd3 Bb7 9. Qc2 Nbd7 10. e4 c5 11. e5 Nd5 12. Nxd5 Bxd5 13. dxc5 Nxc5 14. Bxh7 Rc8 15. Bd3 Bxf3 16. gxf3 Qxd3 17. Qxd3 Nxd3 18. Be3 Nxe5 19. Rac1 O-O 20. Kg2 Nd3 21. Rb1 Rc2 22. Bd4 Nf4+ 23. Kg3 Ne2+ 24. Kg2 Nxd4 0-1",
    B: "1767",
  },
  {
    W: "1341",
    T: "180+2",
    P: "1. e4 c5 2. f4 Nc6 3. Nf3 d6 4. Bc4 Nf6 5. O-O Nxe4 6. Bxf7+ Kxf7 7. Ng5+ Nxg5 8. fxg5+ Ke8 9. Qf3 Be6 10. d3 g6 11. Bf4 Bg7 12. c3 Qb6 13. b3 Rd8 14. c4 Kd7 15. Na3 Bxa1 16. Rxa1 Rhf8 17. Nb5 a6 18. Nc3 Nd4 19. Qe4 Rxf4 20. Qe1 Qb4 21. Qg3 Qxc3 22. Qxf4 Qxa1+ 23. Kf2 Qb2+ 24. Kg3 Ne2+ 25. Kf3 Nxf4 26. Kxf4 Qe5+ 27. Kf3 Qf5+ 0-1",
    B: "1457",
  },
  {
    W: "1538",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 d6 4. d4 Bg4 5. h3 Bh5 6. g4 Bg6 7. Nc3 Nf6 8. dxe5 dxe5 9. Qe2 Bb4 10. Bd2 O-O 11. g5 1-0",
    B: "1486",
  },
  {
    W: "1625",
    T: "180+2",
    P: "1. e4 e5 2. f4 d5 3. exd5 Qxd5 4. Nc3 Qd8 5. fxe5 Nc6 6. Nf3 Bg4 7. Be2 Bc5 8. d4 Bb6 9. O-O Bxf3 10. Bxf3 Nxe5 11. Bxb7 Rb8 12. Bf3 Ne7 13. Kh1 Nxf3 14. Qxf3 O-O 15. d5 Qd6 16. Bf4 Qb4 17. b3 Rbd8 18. Rad1 Qa5 19. Na4 Nxd5 20. Nxb6 cxb6 21. Bc1 Nc3 22. Rd3 Rxd3 23. Qxd3 Nxa2 24. Bd2 Qb5 25. Qxb5 1-0",
    B: "1691",
  },
  {
    W: "2187",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 e6 3. c3 d5 4. e5 Nc6 5. d4 Qb6 6. Bd3 Bd7 7. Bc2 cxd4 8. cxd4 Nb4 9. O-O Nxc2 10. Qxc2 Rc8 11. Nc3 Qc6 12. Be3 b5 13. Qd2 Bb4 14. Rfc1 Ne7 15. Qd3 O-O 16. Ne2 Qa6 17. a3 Ba5 18. Ng3 Bb6 19. Ng5 g6 20. h4 Nf5 21. Nxf5 exf5 22. h5 h6 23. e6 fxe6 24. Nf3 g5 25. Ne5 Qb7 26. g4 Rxc1+ 27. Rxc1 Be8 28. gxf5 exf5 29. f4 Bxh5 30. Rc6 gxf4 31. Bxf4 Qg7+ 32. Kh2 Kh8 33. Rxh6+ Kg8 34. Rxh5 Rf6 35. Rg5 1-0",
    B: "2072",
  },
  {
    W: "1495",
    T: "240+0",
    P: "1. e4 e6 2. f4 c5 3. Nf3 h6 4. Bc4 Be7 5. O-O Nf6 6. d3 a6 7. a4 b5 8. axb5 O-O 9. bxa6 Nxa6 10. Nc3 Bb7 11. Be3 d5 12. exd5 exd5 13. Bxa6 Bxa6 14. Bf2 d4 15. Ne2 Qb6 16. b3 Bd6 17. Ne5 Qc7 18. Bg3 Nh5 19. Qe1 Nf6 20. Rf2 Nd5 21. Rc1 Ne3 22. Qd2 Bb7 0-1",
    B: "1583",
  },
  {
    W: "1838",
    T: "60+0",
    P: "1. e4 d5 2. d4 dxe4 3. Bc4 Nf6 4. Nc3 e6 5. Nge2 b6 6. O-O Bb7 7. Nf4 Bd6 8. Be3 O-O 9. Qe2 Nbd7 10. Rad1 c5 11. d5 e5 12. Nh5 Nxh5 13. Qxh5 Nf6 14. Qh4 Be7 15. Bg5 h6 16. Bxh6 gxh6 17. Qxh6 Nh7 18. Nxe4 f5 19. d6+ Kh8 20. dxe7 Qxe7 21. Ng3 Qg5 22. Qd6 f4 23. Bd5 Bxd5 24. Rxd5 fxg3 25. Rxe5 gxh2+ 26. Kh1 Qg4 27. f3 Rg8 28. fxg4 Rxg4 29. Re2 Rag8 30. Qd3 Rxg2 31. Rxg2 Rxg2 32. Kxg2 Ng5 33. Kxh2 Kg7 34. Rf8 Nf3+ 35. Kg3 Kxf8 36. Qxf3+ Ke7 37. Qf5 Kd6 38. Qc8 Kd5 39. Qa8+ Kd4 40. Qxa7 Ke3 41. Qxb6 Kd2 42. Qxc5 Kc1 43. a4 Kxb2 44. a5 Kc1 45. a6 1-0",
    B: "1962",
  },
  {
    W: "1351",
    T: "600+0",
    P: "1. e4 b5 2. Nf3 Bb7 3. Nc3 b4 4. Nd5 e6 5. Nf4 Bxe4 6. Be2 Nf6 7. d3 Bb7 8. Bd2 Nc6 9. O-O Qe7 10. c3 O-O-O 11. cxb4 Nxb4 12. a3 Nc6 13. b4 g5 14. Nh5 Nxh5 15. Ne5 Nxe5 16. Bxh5 Nxd3 17. Qf3 Bxf3 0-1",
    B: "1327",
  },
  {
    W: "1063",
    T: "600+0",
    P: "1. e4 e5 2. Bc4 d6 3. Nf3 Nf6 4. Nc3 Be6 5. Bb3 Bxb3 6. axb3 Be7 7. d4 exd4 8. Nxd4 Qd7 9. Bf4 d5 10. f3 dxe4 11. fxe4 Bb4 12. O-O Bxc3 13. bxc3 Nxe4 14. Re1 Qe7 15. Qf3 f5 16. Nxf5 Qc5+ 17. Be3 Nf6 18. Bxc5+ 1-0",
    B: "1146",
  },
  {
    W: "1006",
    T: "600+0",
    P: "1. Nf3 f6 2. d4 e6 3. c4 Bb4+ 4. Bd2 Bxd2+ 5. Qxd2 d5 6. e3 dxc4 7. Bxc4 a5 8. O-O Nc6 9. Bb5 Ne7 10. Na3 O-O 11. Rac1 Nb4 12. Bc4 b6 13. d5 Nexd5 14. Bxd5 Nxd5 15. Nb5 Ba6 16. Nfd4 Bxb5 17. Nxe6 Qe7 18. Qxd5 Bxf1 19. Rxc7 Rad8 20. Nxd8+ Rf7 21. Rxe7 g5 22. Qxf7+ Kh8 23. Re8# 1-0",
    B: "1037",
  },
  {
    W: "1413",
    T: "600+0",
    P: "1. b3 d5 2. Bb2 Nc6 3. g3 e5 4. Bg2 Nf6 5. Nf3 e4 6. Nh4 Be7 7. O-O O-O 8. d3 exd3 9. cxd3 Ng4 10. Bh3 Bxh4 11. Bxg4 Bxg4 12. gxh4 Qxh4 13. f3 Bf5 14. Rf2 Rae8 15. Qf1 Bh3 16. Qe1 Re6 17. Nd2 Rg6+ 18. Kh1 Bg2+ 19. Rxg2 Qxe1+ 20. Rxe1 Rxg2 21. Kxg2 Re8 22. e4 dxe4 23. dxe4 1-0",
    B: "1374",
  },
  {
    W: "1660",
    T: "600+0",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 d6 5. Nc3 Nf6 6. Be2 Be6 7. Qg3 Nd4 8. Bd3 Nh5 9. Qe3 c5 10. Nge2 g6 11. Nxd4 cxd4 12. Qxd4 Bg7 13. Qb4 b6 14. Bd2 O-O 15. h3 Nf4 16. Bxf4 a5 17. Qb5 Bd7 18. Qg5 Qc7 19. Nd5 Qd8 20. Qxd8 Raxd8 21. Bxd6 Rfe8 22. O-O-O Bc6 23. Ne7+ Kh8 24. Ba3 Bd7 25. Nd5 Be6 26. Nc7 Rg8 27. Nxe6 fxe6 28. f3 Rb8 29. Bd6 Rb7 30. Bb5 e5 31. g3 h5 32. f4 exf4 33. gxf4 Rc8 34. Rhg1 Kh7 35. e5 Rf7 36. e6 Rxf4 37. Bxf4 Rc5 38. Bd7 Rf5 39. Bc7 Rc5 40. Bxb6 Bxb2+ 41. Kxb2 1-0",
    B: "1613",
  },
  {
    W: "1846",
    T: "600+0",
    P: "1. e4 c5 2. Nc3 e6 3. f4 Nc6 4. Nf3 d5 5. d3 Nd4 6. Be3 Qb6 7. exd5 Qxb2 8. Bxd4 cxd4 9. Ne4 Bb4+ 10. Ned2 exd5 11. Rb1 Bxd2+ 12. Nxd2 Qxa2 13. g3 Nf6 14. Qe2+ Kf8 15. Bg2 Bg4 16. Bf3 Re8 0-1",
    B: "1840",
  },
  {
    W: "1292",
    T: "600+0",
    P: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. e3 c5 5. exd4 Qxd4 6. Qxd4 cxd4 7. Nf3 Bc5 8. Bd3 g6 9. O-O Bf5 10. Bxf5 gxf5 11. Re1 Nc6 12. e6 O-O-O 13. exf7 Nf6 14. Bg5 Rdf8 15. Bxf6 Bb4 16. Bxh8 Bxe1 17. Ng5 Nb4 18. Bxd4 Nc2 19. Bxa7 Nxa1 20. Kf1 Bb4 21. a3 Be7 22. f4 Bxg5 23. fxg5 Rxf7 24. Bd4 Rd7 25. g6 Rxd4 26. gxh7 Rh4 27. Nc3 Rxh7 28. Ke2 Rxh2 0-1",
    B: "1267",
  },
  {
    W: "1514",
    T: "600+0",
    P: "1. f4 d5 2. d3 Nc6 3. Nf3 Bf5 4. e3 Nf6 5. Be2 e6 6. O-O Bd6 7. d4 h6 8. Bd3 g6 9. Qe2 O-O 10. Nbd2 Nb4 11. Nb3 Bxd3 12. cxd3 1-0",
    B: "1431",
  },
  {
    W: "1058",
    T: "300+3",
    P: "1. d4 d5 2. c4 c5 3. dxc5 Nf6 4. Bg5 Qa5+ 5. Nc3 Ne4 6. Qa4+ Qxa4 7. Nxa4 Nxg5 8. O-O-O e5 9. Rxd5 Nc6 10. Nf3 Nb4 11. Rxe5+ Ne6 12. Nd4 Nxa2+ 13. Kb1 Nb4 14. e3 Bd7 15. Nxe6 Bxe6 16. Be2 Be7 17. Rd1 Na2 18. Kxa2 1-0",
    B: "1092",
  },
  {
    W: "1579",
    T: "300+3",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. Qh5 Nf6 5. Qb5+ Nc6 6. Bc4 a6 7. Qb3 e6 8. Nf3 b5 9. Be2 Nd4 10. Nxd4 Qxd4 11. O-O Bd6 12. d3 Bb7 13. Be3 Qh4 14. g3 Qh3 15. f3 Ng4 16. Rf2 Nxf2 17. Bxf2 O-O 18. Ne4 Bxe4 19. dxe4 c5 20. a4 c4 21. Qe3 Qh5 22. f4 Rfc8 23. Bxh5 1-0",
    B: "1647",
  },
  {
    W: "1265",
    T: "300+3",
    P: "1. d4 d5 2. Nf3 Nf6 3. Bg5 e6 4. e3 Be7 5. c4 O-O 6. c5 c6 7. Nbd2 Nbd7 8. Bd3 b6 9. b4 a5 10. bxa5 bxc5 11. Qc2 Rxa5 12. Bxf6 Bxf6 13. Bxh7+ Kh8 14. dxc5 Rxc5 15. Qd3 Ne5 16. Nxe5 Bxe5 17. e4 Kxh7 18. exd5+ Kg8 19. Qe4 Bxa1 0-1",
    B: "1404",
  },
  {
    W: "1749",
    T: "300+3",
    P: "1. f4 d5 2. Nf3 c5 3. e3 Nc6 4. Be2 Nf6 5. d3 g6 6. c3 Bg7 7. O-O O-O 8. Ne5 Qc7 9. Nxc6 bxc6 10. Nd2 d4 11. exd4 cxd4 12. cxd4 Nd5 13. Nb3 Qb6 14. Kh1 Bf5 15. Bf3 Rad8 16. Rb1 Bxd4 17. Bxd5 Rxd5 18. Qf3 Rfd8 19. Nxd4 Qxd4 20. Be3 Qxd3 21. Bxa7 Qa6 22. Qa3 Qxa3 23. bxa3 Bxb1 24. Rxb1 Rd1+ 25. Rxd1 Rxd1+ 26. Bg1 Rd2 27. h3 Rxa2 28. Bc5 e6 29. Kh2 h5 30. Kg3 Rc2 31. Bb4 c5 32. Ba5 Ra2 33. Bb6 Rxa3+ 34. Kh4 c4 35. Bc5 Ra2 36. g3 c3 0-1",
    B: "1754",
  },
  {
    W: "1168",
    T: "300+3",
    P: "1. f4 d5 2. Nf3 d4 3. e3 dxe3 4. dxe3 Bd7 5. Nc3 a6 6. Bc4 e6 7. Ne5 Nh6 8. Nxd7 Nxd7 9. b3 Nf5 10. e4 Qh4+ 11. g3 Nxg3 12. hxg3 Qxh1+ 13. Ke2 Bc5 14. Qxh1 1-0",
    B: "1167",
  },
  {
    W: "1907",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nxd4 5. Qxd4 Qf6 6. Qc3 Qc6 7. Qxc6 dxc6 8. Nc3 Bd6 9. Bc4 Nf6 10. f4 Bc5 11. e5 Ng4 12. h3 Ne3 13. Bxe3 Bxe3 14. g3 Bf5 15. Ke2 Bc5 16. Bd3 Bxd3+ 17. Kxd3 Ke7 18. Rad1 a5 19. Ne4 Ba7 20. Ke2 Rad8 21. Kf3 h6 22. a4 Rxd1 23. Rxd1 Rd8 24. Rxd8 Kxd8 25. Nd2 b5 26. b3 Ke7 27. Ke4 Ke6 28. f5+ Ke7 29. g4 g6 30. Nf3 Bf2 31. Nd4 bxa4 32. bxa4 Bxd4 33. Kxd4 h5 34. Kc5 hxg4 35. hxg4 gxf5 36. gxf5 Kd7 37. f6 Ke6 38. Kd4 c5+ 39. Ke4 c6 40. c4 Kd7 41. Kf5 Ke8 42. e6 fxe6+ 43. Kxe6 Kf8 44. f7 Kg7 45. Ke7 Kg6 46. f8=Q 1-0",
    B: "1910",
  },
  {
    W: "1155",
    T: "300+3",
    P: "1. e3 Nf6 2. Nf3 Nc6 3. Be2 e5 4. O-O d5 5. d4 e4 6. Nfd2 Bd6 7. c4 Be6 8. c5 Be7 9. h3 Qd7 10. Nc3 O-O-O 11. Bb5 a6 12. Ba4 h5 13. b4 Bxh3 14. b5 axb5 15. Bxb5 Bxg2 16. Kxg2 Qe6 17. Bxc6 bxc6 18. Rb1 Ng4 19. f3 Nxe3+ 0-1",
    B: "1162",
  },
  {
    W: "962",
    T: "300+3",
    P: "1. e4 d5 2. e5 f6 3. exf6 exf6 4. Qe2+ Ne7 5. d4 g6 6. Be3 Nf5 7. Bf4+ Be7 8. Nf3 O-O 9. g4 g5 10. Bg3 Nd6 11. Nc3 Nc4 12. O-O-O Bxg4 13. h3 Bxf3 14. Qxf3 Bb4 15. a3 Bxc3 16. bxc3 Nxa3 17. c4 dxc4 18. Qxa3 Nc6 19. Bxc4+ Kg7 20. h4 Nxd4 21. c3 Ne2+ 22. Kb1 Qe8 23. Bxc7 Rc8 24. Bd6 Rf7 25. Rhe1 Qe4+ 26. Kb2 Rxc4 27. Bf8+ Rxf8 28. Rd7+ Rf7 29. Rd4 Rxd4 30. cxd4 Qxd4+ 31. Kb3 Qc3+ 32. Ka2 Qxe1 33. hxg5 fxg5 34. Qb2+ Kg6 35. Qc2+ Kg7 36. Qb2+ Rf6 37. Qxb7+ Kg6 38. Qe4+ Rf5 39. Qe6+ Rf6 40. Qe8+ Rf7 41. Qe6+ Rf6 42. Qe8+ Rf7 43. Qe4+ Rf5 44. Qe6+ Rf6 45. Qe4+ Rf5 46. Qe8+ Rf7 47. Qe4+ Rf5 48. Qe6+ Rf6 49. Qg8+ Kh5 50. Qxh7+ Kg4 51. Qe7 Kf5 52. Qh7+ Rg6 53. Qd7+ Kf6 54. Qd8+ Kf5 55. Qf8+ Kg4 56. Qc5 Nc1+ 57. Kb2 Re6 58. Qc4+ Re4 59. Qc8+ Kf3 60. Qc3+ Qxc3+ 61. Kxc3 Kxf2 62. Kd2 Re2+ 63. Kxc1 g4 64. Kd1 g3 65. Kc1 g2 66. Kb1 g1=Q# 0-1",
    B: "994",
  },
  {
    W: "1425",
    T: "600+0",
    P: "1. d4 c5 2. Nf3 Nc6 3. c4 d5 4. dxc5 dxc4 5. Nc3 e5 6. Qxd8+ Nxd8 7. e4 Bxc5 8. Bxc4 Bb4 9. Nxe5 Ne6 10. O-O Ne7 11. Nd5 Nxd5 12. exd5 Nc5 13. Rd1 O-O 14. h3 Ne4 15. a3 Bd6 16. Bf4 f6 17. Nd3 Bxf4 18. Nxf4 Kh8 19. d6 Bf5 20. Be6 Bxe6 21. Nxe6 Rfe8 22. d7 Rxe6 23. d8=Q+ Re8 24. Qd5 h6 25. Re1 Rad8 26. Qf5 Ng5 27. Rxe8+ Rxe8 28. Qb5 b6 29. Qxe8+ Kh7 30. Rd1 h5 31. Qxh5+ Kg8 32. Rd8# 1-0",
    B: "1423",
  },
  {
    W: "2313",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. a4 e5 7. Nb3 Be7 8. Bc4 O-O 9. O-O Nc6 10. Bg5 Bg4 11. f3 Bh5 12. Bxf6 Bxf6 13. Nd5 Rc8 14. c3 Bg5 15. Be2 Ne7 16. Nxe7+ Qxe7 17. Nd2 Rfd8 18. Nc4 b5 19. axb5 axb5 20. Nb6 Rb8 21. Nd5 Qd7 22. b4 f6 23. Ra5 Bf7 24. Bd3 Kh8 25. Qe2 Rdc8 26. Bxb5 Qd8 27. c4 Bg6 28. Ra7 f5 29. Rd7 Qf8 30. f4 exf4 31. Nxf4 Be8 32. Ne6 Qf6 33. Nxg5 Qxg5 34. Rxf5 Qc1+ 35. Rf1 Qxf1+ 36. Qxf1 Bxd7 37. Bxd7 Rd8 38. Bb5 Re8 39. Qd3 Re6 40. c5 dxc5 41. bxc5 h6 42. c6 Re7 43. Qd6 Rxb5 44. Qxe7 Rb1+ 45. Kf2 Rc1 46. c7 1-0",
    B: "2355",
  },
  {
    W: "2302",
    T: "60+0",
    P: "1. b4 Nf6 2. Bb2 e6 3. a3 a5 4. b5 a4 5. e3 d5 6. c4 Nbd7 7. cxd5 exd5 8. Nc3 Nc5 9. Nf3 Be7 10. Be2 O-O 11. O-O Bg4 12. d4 Nb3 13. Rb1 Bf5 14. Nxa4 Bxb1 15. Qxb3 Be4 16. Nc5 Bf5 17. Ne5 b6 18. Nc6 Qe8 19. Nd3 Bd6 20. Nde5 Ne4 21. Qxd5 Kh8 22. Bf3 f6 23. Bxe4 fxe5 24. dxe5 Bxe4 25. Qxe4 Bxa3 26. Bc3 Bc5 27. e6 1-0",
    B: "2378",
  },
  {
    W: "2284",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 Nf6 3. c4 e6 4. Nc3 c6 5. Bg5 Bb4 6. cxd5 exd5 7. e3 Qa5 8. Qc2 Ne4 9. Bd3 Nxc3 0-1",
    B: "2164",
  },
  {
    W: "959",
    T: "60+0",
    P: "1. d4 d5 2. Bf4 Nc6 3. Nf3 f6 4. e3 e5 5. dxe5 fxe5 6. Bxe5 Nxe5 7. Nxe5 Qf6 8. f4 Bd6 9. Qd4 Ne7 10. c4 Nf5 11. Qxd5 Be6 12. Nf7 Bxd5 13. Nxh8 Be4 14. Nd2 Bc6 15. Be2 Nxe3 16. Ne4 Qe6 17. Bf3 Nc2+ 18. Kf2 Nxa1 19. Rxa1 Bxe4 20. Re1 Qxc4 21. Rxe4+ Qxe4 22. Bxe4 Kd7 23. Bxh7 Rxh8 24. Be4 Rxh2 25. Bxb7 c6 26. Kf3 Kc7 27. Ba6 1-0",
    B: "1019",
  },
  {
    W: "1298",
    T: "60+0",
    P: "1. e3 e5 2. g3 Nc6 3. Bg2 Bc5 4. b3 Nf6 5. a3 a6 6. b4 Bb6 7. d3 d5 8. Bd2 Bg4 9. f3 Bf5 10. e4 dxe4 11. fxe4 Qd4 12. Bc3 Qd7 13. exf5 Qxf5 14. Bxc6+ bxc6 15. Nf3 e4 16. dxe4 Nxe4 17. Bxg7 Rg8 18. Rf1 Ke7 19. Bc3 Rg7 20. Nd4 Qe5 21. Nxc6+ 1-0",
    B: "1356",
  },
  {
    W: "1210",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 Qf6 4. Bd3 exd4 5. c3 dxc3 6. Nxc3 Nd4 7. e5 Nxf3+ 8. Qxf3 Qxf3 9. gxf3 d6 10. Bf4 dxe5 11. Bxe5 f6 12. Bxc7 Ne7 13. Bb5+ Bd7 14. Bxd7+ Kxd7 15. Bf4 g5 16. O-O-O+ Kc6 17. Be3 Nf5 18. Rhe1 Bc5 19. Bxc5 Kxc5 20. Na4+ Kb5 21. Rd5+ Kxa4 22. Re4+ Nd4 23. Rexd4# 1-0",
    B: "1258",
  },
  {
    W: "1099",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. Qf3 c6 5. d3 Nf6 6. Bd2 Bg4 7. Qf4 e6 8. f3 Bf5 9. Ne4 Nbd7 10. Bxa5 1-0",
    B: "1050",
  },
  {
    W: "2011",
    T: "60+0",
    P: "1. e3 g6 2. d4 Bg7 3. c3 b6 4. e4 Bb7 5. e5 e6 6. Nf3 Ne7 7. Bd3 O-O 8. Qe2 d6 9. Be4 dxe5 10. Bxb7 Nbc6 11. Bxa8 Qxa8 12. dxe5 Qe8 13. O-O a6 14. Nbd2 Na5 15. Ne4 Nec6 16. Nf6+ Bxf6 17. exf6 Qd8 18. Bh6 Qxf6 19. Bxf8 Kxf8 20. Rad1 Kg7 21. Rd2 Ne7 22. Rfd1 Nd5 23. a3 Nf4 24. Qe5 Nc6 25. Qxf6+ Kxf6 26. g3 Nd5 27. Nd4 Ne5 28. f4 Nf3+ 29. Nxf3 Ne3 30. Kf2 Nxd1+ 31. Rxd1 h6 32. Rd7 g5 33. Rxc7 gxf4 34. a4 fxg3+ 35. hxg3 h5 36. Rb7 b5 37. axb5 axb5 38. Rxb5 h4 39. gxh4 e5 40. Kg3 e4 41. Ng5 Kg6 42. Nxe4 f5 43. Rxf5 Kxf5 44. Kf3 Ke5 45. Ke3 Kd5 46. b3 Ke5 47. h5 Kf5 48. c4 Ke6 49. Kd4 Kd7 50. b4 Kc7 51. h6 Kb7 52. Ng5 Kb6 53. h7 Ka6 54. h8=Q Kb6 55. Qh6+ Kc7 56. Ke5 Kd8 57. Kd5 Ke8 58. b5 1-0",
    B: "1970",
  },
  {
    W: "1454",
    T: "60+0",
    P: "1. e4 e6 2. e5 h6 3. d4 d6 4. Nf3 a6 5. Nc3 b5 6. a4 b4 7. Na2 a5 8. c3 g5 9. cxb4 axb4 10. Nxb4 dxe5 11. Bb5+ c6 12. Bxc6+ Nxc6 13. Nxc6 Qc7 14. Ncxe5 Nf6 15. O-O Bb7 16. Be3 Nd5 17. Qb3 Bd6 18. Ng6 fxg6 19. Rab1 Ke7 20. Rbc1 Qb8 21. a5 Rxa5 22. Qd1 Rb5 23. Rc2 Rc8 24. Rxc8 Qxc8 25. Re1 Ba6 26. Bxg5+ hxg5 27. Nxg5 Nf4 28. g3 Nh5 29. Rxe6+ Kd7 30. Rxg6 Kc6 31. Qxh5 Qf5 32. Qh6 Qxg5 33. Rxg5 Rxg5 34. Qxg5 Kb6 35. b4 Bxb4 36. Qf6+ Bd6 37. d5 Kb5 38. Qxd6 1-0",
    B: "1447",
  },
  {
    W: "2156",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 e6 3. Bg5 Nf6 4. e3 Be7 5. Bd3 b6 6. Nbd2 O-O 7. c3 h6 8. Bh4 Ba6 9. Qe2 Bxd3 10. Qxd3 c5 11. e4 Nxe4 12. Nxe4 Bxh4 13. Ng3 Bf6 14. O-O Nc6 15. Rae1 cxd4 16. cxd4 Rc8 17. a3 Qc7 18. Nh5 Be7 19. Ne5 Nxe5 20. dxe5 Qc2 21. Qg3 Qg6 22. Qf3 Rc2 23. Nf4 Qf5 24. Qb3 Rfc8 25. g3 g5 26. Nd3 Rd2 27. Rc1 Rxc1 28. Rxc1 Qxd3 29. Rc8+ Kg7 30. Qa4 Rd1+ 31. Kg2 Qf5 32. Qe8 Qe4+ 33. Kh3 g4# 0-1",
    B: "2203",
  },
  {
    W: "2030",
    T: "60+0",
    P: "1. e4 d5 2. e5 d4 3. f4 c5 4. d3 Nc6 5. Nf3 f6 6. Be2 fxe5 7. O-O exf4 8. Bxf4 Nf6 9. Ng5 e5 10. Bg3 Bd6 11. Bh5+ g6 12. Nxh7 Nxh5 13. Nf6+ Nxf6 14. Rxf6 Qxf6 15. Nd2 Be6 16. Ne4 Qe7 17. Qd2 O-O-O 18. Rf1 Rdf8 19. Rxf8+ Rxf8 20. Qe1 Bd5 21. Bh4 Qd7 22. Ng5 Qg4 23. g3 Rf3 24. Nxf3 Qxf3 25. Bg5 Qh1+ 26. Kf2 Qg2# 0-1",
    B: "2026",
  },
  {
    W: "1538",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 f5 3. Bb5 c6 4. Ba4 d6 5. d3 fxe4 6. Ng5 exd3 7. Qxd3 h6 8. Nf3 Nf6 9. Qe2 Bg4 10. O-O e4 11. h3 Bxf3 12. gxf3 b5 13. Bb3 d5 14. fxe4 Nxe4 15. Nc3 Be7 16. Nxe4 dxe4 17. Qxe4 Nd7 18. Qxc6 Kf8 19. Bd2 Rc8 20. Qf3+ Ke8 21. Qf7# 1-0",
    B: "1514",
  },
  {
    W: "1572",
    T: "60+0",
    P: "1. e4 b6 2. f4 Bb7 3. d3 g6 4. Nf3 Bg7 5. Be2 Nc6 6. O-O Nf6 7. Nc3 O-O 8. Bd2 d5 9. e5 Nd7 10. d4 f6 11. Bd3 fxe5 12. fxe5 Nb4 13. Bg5 Nxd3 14. cxd3 c5 15. Qd2 cxd4 16. Nb5 h6 17. Bh4 g5 18. Bxg5 hxg5 19. Nxg5 Rxf1+ 20. Rxf1 Nxe5 21. Ne6 Qd6 22. Nxg7 Qg6 23. Qg5 Qxg5 24. Ne6 Qd2 25. Nbxd4 Qxb2 26. Nf5 Bc8 27. Nxe7+ Kh7 28. Rf7+ Nxf7 29. Nf4 Qxa2 30. Neg6 Bf5 31. Nh4 Qa1+ 32. Kf2 Bxd3 33. Nxd3 Qa2+ 34. Ke3 Ne5 35. Nxe5 Re8 36. Kf4 Rxe5 0-1",
    B: "1539",
  },
  {
    W: "1511",
    T: "60+0",
    P: "1. e4 Nf6 2. f4 d5 3. e5 Nfd7 4. d4 Nc6 5. c3 Nb6 6. f5 Bxf5 7. Bd3 e6 8. Bxf5 exf5 9. Ne2 Be7 10. O-O g6 11. Bh6 Bf8 12. Bf4 Bg7 13. Qd2 O-O 14. Bh6 Qe7 15. Bxg7 Kxg7 16. Ng3 Nd8 17. Na3 c6 18. Nc2 Ne6 19. Ne1 Nc4 20. Qc2 Ne3 21. Qd2 Nxf1 22. Kxf1 b6 23. Nf3 c5 24. h4 cxd4 25. cxd4 Rac8 26. h5 f4 27. hxg6 fxg6 28. Ne2 a5 29. Rc1 Rxc1+ 30. Qxc1 Qd7 31. Qd2 Rc8 32. Kf2 Qc6 33. Nh4 Qc2 34. Qxc2 Rxc2 35. Kf3 Rxe2 36. Kxe2 Nxd4+ 37. Kf2 Nc6 38. Kf3 Nxe5+ 39. Kxf4 Nd3+ 40. Kg5 Nxb2 41. g4 b5 42. Kf4 1-0",
    B: "1497",
  },
  {
    W: "1683",
    T: "900+15",
    P: "1. f4 c6 2. e4 d5 3. Nc3 e6 4. d4 Ne7 5. Nf3 Ng6 6. h4 Be7 7. h5 Nh4 8. Be3 Nxf3+ 9. Qxf3 Bh4+ 10. Bf2 Bxf2+ 11. Qxf2 h6 12. g4 Nd7 13. e5 Qb6 14. b3 Qb4 15. Qd2 c5 16. O-O-O Qa3+ 17. Kb1 a5 18. Nb5 Qb4 19. Qxb4 axb4 20. Nc7+ Kd8 21. Nxa8 b6 22. c4 Bb7 23. Nxb6 Nxb6 24. dxc5 Nd7 25. Bg2 Nxc5 26. cxd5 exd5 27. Bxd5 Ke7 28. Bxb7 Nxb7 29. Rd4 Rc8 30. Rxb4 Nd8 31. Rc1 Rxc1+ 32. Kxc1 Ne6 33. a4 f6 34. exf6+ Kxf6 35. a5 g5 36. fxg5+ Kxg5 37. a6 Nf4 38. Rxf4 Kxf4 39. a7 Kxg4 40. a8=Q Kxh5 41. Qf3+ Kg5 42. b4 h5 43. b5 h4 44. b6 h3 45. Qxh3 Kf4 46. b7 Ke5 47. b8=Q+ Kd5 48. Qd3+ Ke6 49. Qe8+ Kf6 50. Qf3+ Kg7 51. Qff7+ Kh6 52. Qg6# 1-0",
    B: "1785",
  },
  {
    W: "1604",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. Ng5 Qxg5 5. O-O Nf6 6. d4 Nxd4 7. Bxg5 1-0",
    B: "1633",
  },
  {
    W: "1327",
    T: "900+15",
    P: "1. d4 h6 2. e4 d5 3. e5 e6 4. c3 Be7 5. Nf3 Nc6 6. Bf4 b6 7. Nbd2 Bb7 8. Bb5 Bg5 9. Bxg5 hxg5 10. g4 Rh3 11. Qe2 Qe7 12. O-O-O O-O-O 13. Qe3 f6 14. exf6 gxf6 15. Nf1 Na5 16. b4 Nc4 17. Bxc4 dxc4 18. N3d2 Bd5 19. Rg1 Rxe3 20. fxe3 e5 21. e4 Bc6 22. d5 Ba4 23. Re1 c6 24. Nxc4 cxd5 25. exd5 Rxd5 26. Nfe3 Rd3 27. Nf5 Rxc3+ 28. Kb2 Qxb4+ 29. Ka1 Qxc4 30. Nd6+ Kc7 31. Nxc4 Rxc4 32. Ref1 Rc2 33. Rb1 Rxh2 34. Rgf1 Rg2 35. Rg1 Rh2 36. Rgc1+ Kb7 37. Rb4 Bd7 38. Rbc4 Bxg4 39. Rc7+ Ka6 40. Rg7 Nh6 41. Rf7 Nxf7 0-1",
    B: "1302",
  },
  {
    W: "1868",
    T: "900+15",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. e3 dxc4 5. Bxc4 c5 6. Nge2 cxd4 7. exd4 Bg7 8. O-O O-O 9. h3 Nc6 10. Be3 a6 11. f3 h5 12. a3 b5 13. Bd3 Bb7 14. Rc1 Rc8 15. Kh1 Nd5 16. Bf2 Nxc3 17. bxc3 Na5 18. a4 Nc4 19. Bxc4 Rxc4 20. axb5 axb5 21. Qb3 Qd7 22. Rb1 Qe6 23. Rfe1 Ba6 24. Nf4 Qf5 25. Nd3 Qxd3 26. Rxe7 Qxc3 27. Qxc3 Rxc3 28. Ra7 Bc8 29. Rxb5 Rc2 30. Kg1 Rxf2 0-1",
    B: "1911",
  },
  {
    W: "1417",
    T: "900+15",
    P: "1. d4 g6 2. e4 Nf6 3. e5 Nh5 4. g4 Ng7 5. Bd3 e6 6. Nf3 c5 7. Bg5 Be7 8. Bh6 O-O 9. Qd2 cxd4 10. Nxd4 Bc5 11. c3 Qh4 12. Bxg7 Kxg7 13. h3 b6 14. Na3 Bb7 15. Rh2 Bxd4 16. cxd4 Nc6 17. Be4 Rac8 18. Nb5 Ba6 19. Nd6 Rc7 20. O-O-O Be2 21. Re1 Bxg4 22. Reh1 Be2 23. Kb1 Qxe4+ 24. Nxe4 Bb5 25. Rc1 1-0",
    B: "1394",
  },
  {
    W: "1443",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 d6 3. Nc3 Bg4 4. Be2 Bxf3 5. Bxf3 Nf6 6. O-O h6 7. d3 g5 8. Qd2 g4 9. Be2 h5 10. Qd1 h4 11. Bxg4 Nxg4 12. Qxg4 Nd7 13. Bg5 Be7 14. Bxe7 Qxe7 15. Nd5 Qd8 16. Qg7 Rf8 17. c4 c6 18. Ne3 Qe7 19. Nf5 Qe6 20. Nxh4 O-O-O 21. Rad1 Rg8 22. Qh6 Nf6 23. h3 Qxh3 24. g3 Rxg3+ 25. fxg3 Qxg3+ 26. Ng2 Rg8 27. Qd2 Ng4 28. Kh1 Qh2# 0-1",
    B: "1427",
  },
  {
    W: "1901",
    T: "300+0",
    P: "1. d4 Nf6 2. e3 g6 3. Bc4 d5 4. Bd3 Bg7 5. f4 O-O 6. Nd2 Nbd7 7. Ngf3 Re8 8. O-O Nh5 9. Ng5 h6 10. Ngf3 f5 11. Nh4 Kh7 12. Qe1 Ndf6 13. Be2 Bf8 14. Nhf3 Ne4 15. Ne5 Nhf6 16. Nxe4 dxe4 17. Qh4 Bg7 18. Qg3 Ng4 19. Bxg4 fxg4 20. h3 Bxe5 21. fxe5 Rf8 22. Bd2 gxh3 23. gxh3 Bf5 24. h4 Qe8 25. Qf4 e6 26. Qg3 Qe7 27. a3 Rf7 28. Bb4 Qd7 29. c4 a5 30. Bc3 Rg8 31. b4 g5 32. h5 Rfg7 33. b5 Qf7 34. Qh2 g4 35. Bxa5 b6 36. Bb4 Rg5 37. c5 Rxh5 38. Qg3 Rh3 39. Qg2 Qh5 40. Kf2 Qh4+ 41. Ke2 Qg5 42. Qf2 g3 43. Qg2 Rh2 44. Rg1 Rxg2+ 45. Rxg2 Bh3 46. Rgg1 Qg4+ 47. Kd2 Qf3 48. Kc2 Qxe3 49. Bc3 Qd3+ 50. Kb3 Qxb5+ 51. Bb4 g2 52. Ra2 Rg3+ 53. Kc2 Qd3+ 54. Kc1 Qe3+ 55. Rd2 Qxg1+ 0-1",
    B: "1899",
  },
  {
    W: "1178",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bb4 4. Bc4 d6 5. a3 Bxc3 6. dxc3 Nf6 7. O-O Bg4 8. Bg5 Bxf3 9. Qxf3 h6 10. Qg3 hxg5 11. Qxg5 Nxe4 12. Qxg7 Rf8 13. Rfe1 Nd2 14. Ba2 Qh4 15. h3 O-O-O 16. Qg3 Rh8 17. Qd3 Qg5 18. Rad1 Rdg8 19. Bd5 e4 20. Bxe4 Nxe4 21. Qxe4 Re8 22. Qf3 Ne5 23. Qg3 Nc4 24. Qxg5 Nxb2 25. Rb1 Rxe1+ 26. Rxe1 Nc4 27. Qg7 Rd8 28. Qxf7 Ne5 29. Qf5+ Kb8 30. Rd1 b6 31. h4 Nc4 32. g3 Nxa3 33. Ra1 Nc4 34. Qd3 Ne5 35. Qa6 Nc6 36. Qa4 Kb7 37. Qa6+ Kb8 38. Qb5 Ne7 39. c4 Rc8 40. Qa6 Nc6 41. c3 Re8 42. Kg2 Re2 43. c5 dxc5 44. Qxe2 Nd8 45. Qe8 Kc8 46. Rd1 Kb7 47. Qxd8 Ka6 48. Qxc7 Kb5 49. Qxa7 Kc4 50. Rc1 Kd3 51. Qxb6 Ke4 52. Qxc5 Kd3 53. Rd1+ Kc2 54. Rh1 Kb2 55. Qd4 Kb3 56. Qe3 Kc2 1/2-1/2",
    B: "1213",
  },
  {
    W: "2004",
    T: "300+0",
    P: "1. d4 d5 2. Bf4 c6 3. e3 Nf6 4. Nf3 e6 5. h3 Bd6 6. Be2 Bxf4 7. exf4 O-O 8. O-O Qb6 9. b3 a5 10. Nbd2 a4 11. c4 axb3 12. Nxb3 dxc4 13. Bxc4 Nd5 14. g3 Nd7 15. Ne5 Nxe5 16. fxe5 Qc7 17. Qc1 Bd7 18. Re1 Nb6 19. Be2 Nd5 20. Bf3 Rfc8 21. Nc5 b6 22. Ne4 c5 23. Nd6 Rcb8 24. dxc5 bxc5 25. Qg5 c4 26. Qe7 Rf8 27. Bxd5 exd5 28. Rec1 Ra7 29. Qh4 Be6 30. Qd4 Rfa8 31. Rc2 h6 32. Kg2 Qd7 33. g4 h5 34. f3 hxg4 35. hxg4 Ra3 36. Rd1 Rxa2 37. Rxa2 Rxa2+ 38. Kg3 Ra8 39. Nxc4 Ra4 40. Nb6 Rxd4 41. Nxd7 Rxd1 42. Nc5 d4 43. f4 d3 44. f5 Bd5 45. Kf4 d2 46. Ke3 Rc1 47. Kxd2 Rxc5 48. Ke3 Rc4 49. g5 Kf8 50. Kd3 Rh4 0-1",
    B: "1991",
  },
  {
    W: "1243",
    T: "300+0",
    P: "1. e4 c5 2. Bc4 e6 3. f4 Nc6 4. Nf3 Nf6 5. d3 d5 6. exd5 exd5 7. Qe2+ Be7 8. Bb5 Bd7 9. Ne5 Nxe5 10. Qxe5 Bxb5 11. O-O Ng4 12. Qxg7 Rf8 13. Qxg4 c4 14. Nc3 Qb6+ 15. Kh1 cxd3 16. Nxb5 Qxb5 17. cxd3 Qxd3 18. Re1 Qg6 19. Qe2 Qf6 20. Be3 Bb4 21. Bd4+ Qe7 22. Qb5+ Kd8 23. Rxe7 Bxe7 24. Qxd5+ Kc7 25. Rc1+ Kb8 26. Be5+ Bd6 27. Qxd6# 1-0",
    B: "1344",
  },
  {
    W: "1798",
    T: "300+0",
    P: "1. g3 d5 2. Bg2 c5 3. b3 Nc6 4. Bb2 Bg4 5. h3 Bh5 6. a3 e6 7. d3 Nf6 8. Nd2 Be7 9. Ngf3 O-O 10. O-O Qc7 11. Rb1 a6 12. Kh2 Rad8 13. c4 dxc4 14. bxc4 b5 15. cxb5 axb5 16. Ng1 Nd4 17. Bxd4 Rxd4 18. Rxb5 Rh4 19. Ndf3 Ng4+ 20. Kh1 Rd8 21. gxh4 c4 22. hxg4 Bxg4 23. Qc1 Bxf3 24. Nxf3 Qc6 25. Qxc4 Qxc4 26. dxc4 h6 27. a4 f6 28. a5 Ra8 29. Ra1 f5 30. Ra2 Kf7 31. Ne5+ Kf6 32. Bxa8 Bd6 33. f4 g5 34. hxg5+ hxg5 35. fxg5+ Kxg5 36. Nf7+ Kf6 37. Nxd6 e5 38. Kg2 f4 39. Kf3 Kg5 40. a6 Kh4 41. a7 e4+ 42. Nxe4 Kh3 43. Kxf4 Kg2 44. e3+ Kf1 45. Bd5 Ke1 46. a8=Q Kd1 47. c5 Kc1 48. c6 Kd1 49. Rd2+ Ke1 50. Qa1# 1-0",
    B: "1743",
  },
  {
    W: "1346",
    T: "300+0",
    P: "1. e4 c5 2. f4 d6 3. Nf3 Nc6 4. Bc4 g6 5. O-O e6 6. Qe2 Bg7 7. d3 d5 8. exd5 Nce7 9. dxe6 fxe6 10. Bxe6 Bxe6 11. Qxe6 Qb6 12. Qxb6 axb6 13. Re1 Nh6 14. f5 gxf5 15. Bg5 1-0",
    B: "1389",
  },
  {
    W: "1563",
    T: "300+0",
    P: "1. d4 e5 2. dxe5 Nc6 3. Nf3 Qe7 4. Bf4 Qb4+ 5. Nc3 Qxf4 6. e3 Qh6 7. Nd5 Bb4+ 8. c3 Ba5 9. b4 Bb6 10. b5 Nge7 11. bxc6 Nxd5 12. Qxd5 Bxe3 13. cxd7+ Bxd7 14. Qxb7 O-O 15. fxe3 Qxe3+ 16. Be2 Qxc3+ 17. Nd2 Qxa1+ 18. Bd1 Qxe5+ 19. Be2 Rab8 20. Qxa7 Bb5 21. Qf2 Qa1+ 22. Bd1 Rfe8+ 0-1",
    B: "1589",
  },
  {
    W: "1061",
    T: "300+0",
    P: "1. d4 Nf6 2. Nc3 e5 3. e4 d6 4. dxe5 dxe5 5. Nf3 Bb4 6. Bd2 Nxe4 7. Nxe4 Bxd2+ 8. Qxd2 Bf5 9. Qxd8+ Kxd8 10. O-O-O+ Nd7 11. Nc5 b6 12. Nxd7 Bxd7 13. Nxe5 Ke7 14. Nxd7 Rhd8 15. Bb5 c6 16. Bxc6 Rac8 17. Rhe1# 1-0",
    B: "1128",
  },
  {
    W: "1743",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Ne2 Nf6 6. Ng3 Bb4+ 7. c3 Ba5 8. Bd3 d6 9. O-O Be6 10. h3 Qb6 11. b4 Bxb4 12. cxb4 Nxb4 13. Nc3 Nxd3 14. Qxd3 Qb4 15. Rb1 Qc5 16. Re1 Rc8 17. Qb5+ Qc6 18. Qxc6+ bxc6 19. Bg5 Nd7 20. Rb7 f6 21. Be3 c5 22. Rxa7 O-O 23. Nd5 Rb8 24. Nf5 Nb6 25. Rxg7+ Kh8 26. Bh6 Nxd5 27. Nxd6 Nb6 28. Re7 Bxa2 29. Bxf8 Rxf8 30. Nf5 Nc4 31. Rd1 Bb3 32. Rdd7 Ra8 33. Nh6 Nd2 34. Rxh7# 1-0",
    B: "1738",
  },
  {
    W: "1933",
    T: "300+0",
    P: "1. e4 d6 2. Nf3 f5 3. Bc4 fxe4 4. Nd4 d5 5. Bb3 Nf6 6. O-O e5 7. Ne2 Bc5 8. h3 O-O 9. d4 exd4 10. Nxd4 Nc6 11. c3 Nxd4 12. cxd4 Bb6 13. Nc3 c6 14. Bg5 Bc7 15. Kh1 h6 16. Bh4 Qe8 17. Ne2 Qg6 18. Bg3 Bxg3 19. Nxg3 e3 20. Bc2 Qg5 21. Ne2 Nh5 22. fxe3 Be6 23. Qd3 Rf6 24. Qh7+ Kf7 25. Rxf6+ Qxf6 26. Kg1 Ke7 27. Rf1 Qg5 28. Kh2 Qxe3 29. Bd3 Qg5 30. Rf3 Rf8 31. Rxf8 Kxf8 32. Qh8+ Ke7 33. Qb8 Qe3 34. Qxb7+ Kf6 35. Qa6 Nf4 36. Nxf4 Qxf4+ 37. Kg1 Qxd4+ 38. Kf1 Qb6 39. Qa3 c5 40. Be2 c4 41. Qf3+ Bf5 42. g4 Qxb2 43. Qxf5+ Ke7 44. Qxd5 Qc1+ 45. Kf2 Qf4+ 46. Qf3 Qd4+ 47. Kg2 c3 48. Qd3 Qxd3 49. Bxd3 Kd6 50. Kf3 Kc5 51. Ke3 Kb4 52. Bc2 a5 53. Kd3 Ka3 54. Kxc3 Kxa2 55. Kc4 Ka3 56. Kb5 Kb2 57. Bd1 Kc1 58. Bf3 Kd2 59. Kxa5 Ke3 60. Bc6 Kf4 61. Bd7 Kg3 62. Kb5 Kxh3 63. Kc4 Kh4 64. Kd5 Kg5 65. Ke4 h5 66. Kf3 h4 67. Kg2 Kf4 68. Kh3 g5 69. Bf5 Ke3 70. Kg2 Kd2 71. Kf3 Ke1 72. Be4 Kd2 73. Kf2 Kc3 74. Ke3 Kb3 75. Kd4 Kb4 76. Ke5 Kb3 77. Kf5 1-0",
    B: "1933",
  },
  {
    W: "976",
    T: "300+0",
    P: "1. h4 e5 2. Nc3 d6 3. e4 Nc6 4. Bb5 a6 5. Ba4 Be7 6. b4 b5 7. Nxb5 Nxb4 8. Na7+ Bd7 9. Bxd7+ Qxd7 10. c3 Nd3+ 11. Ke2 Nf4+ 12. Kf3 Rxa7 13. g4 Nf6 14. Nh3 g5 15. Nxg5 h6 16. Nh3 Nd3 17. Ba3 Qc6 18. Rb1 Nxe4 19. Rb8+ Kd7 20. Rxh8 Nexf2+ 21. Ke2 Nxd1 22. Kxd1 Qxh1+ 23. Kc2 Qc1+ 24. Bxc1 Nxc1 25. Kxc1 Bxh4 26. Rxh6 Bd8 27. Rh7 Ke7 28. Ng5 Kf6 29. Ne4+ Kg6 30. Rh8 Bh4 31. Rxh4 a5 32. Rh8 d5 33. Rg8+ Kh7 34. Nf6+ Kh6 35. Nxd5 a4 36. Rh8+ Kg5 37. Rg8+ Kh4 38. g5 c6 39. Nb4 e4 40. Nxc6 Rd7 41. Nd4 Kh5 42. Nf5 1-0",
    B: "856",
  },
  {
    W: "1860",
    T: "300+0",
    P: "1. d4 d5 2. e3 Nf6 3. Bd3 g6 4. Nd2 Bg7 5. f4 O-O 6. Ngf3 Bg4 7. O-O Nbd7 8. h3 Bxf3 9. Qxf3 c5 10. c3 c4 11. Bc2 e6 12. g4 b5 13. g5 Nh5 14. Qg2 f6 15. h4 fxg5 16. hxg5 Rf5 17. Bxf5 exf5 18. Qxd5+ Kh8 19. Qxb5 Qe7 20. Nxc4 Ng3 21. Rf2 Re8 22. Rh2 Nf8 23. Ne5 Ne4 24. Qf1 Kg8 25. c4 a5 26. c5 Bxe5 27. fxe5 Qxg5+ 28. Rg2 Qe7 29. Qc4+ Kg7 30. b3 Ne6 31. Ba3 N6g5 32. b4 axb4 33. Bxb4 Qc7 34. a4 Qc6 35. d5 Qc7 36. d6 Qc6 37. e6 Nh3+ 38. Kh2 Nef2 39. Bc3+ Kh6 40. Bd4 Qe4 41. Rf1 Ng4+ 42. Kxh3 Nxe3 43. Bxe3+ Qxe3+ 44. Rg3 Qd2 45. d7 Re7 46. Qh4+ Kg7 47. Qxe7+ Kh6 48. Qg5+ 1-0",
    B: "1924",
  },
  {
    W: "1491",
    T: "300+0",
    P: "1. e4 d6 2. Nf3 h6 3. d4 a6 4. a3 Bg4 5. Be2 g6 6. h3 Bxf3 7. Bxf3 Bg7 8. e5 Nc6 9. exd6 cxd6 10. Bxc6+ bxc6 11. O-O Nf6 12. Re1 O-O 13. c3 Qd7 14. Nd2 Rfe8 15. Ne4 Nd5 16. Bd2 Nb6 17. Qc1 Kh7 18. Kh1 Nc4 19. b3 Nxd2 20. Qxd2 f5 21. Ng3 d5 22. Re2 Qd6 23. b4 e5 24. dxe5 Rxe5 25. Rae1 Rae8 26. f4 Rxe2 27. Rxe2 Rxe2 28. Nxe2 Qf6 29. Nd4 h5 30. Qd3 Qd6 31. Qxa6 Qxf4 32. Qe2 Bxd4 33. cxd4 Qxd4 34. Qe7+ Qg7 35. Qxg7+ 1-0",
    B: "1454",
  },
  {
    W: "1823",
    T: "300+0",
    P: "1. e4 c6 2. d4 e6 3. Nf3 a6 4. Be2 b5 5. O-O Bb7 6. Be3 Be7 7. Nbd2 Nf6 8. c3 O-O 9. h3 h6 10. e5 Ne8 11. Ne4 d6 12. exd6 Nxd6 13. Nxd6 Bxd6 14. Ne5 Bxe5 15. dxe5 Nd7 16. Qd6 Nb6 17. Rad1 Qxd6 18. exd6 Nd7 19. Bf3 f5 20. g3 g5 21. b3 Rad8 22. c4 Kg7 23. c5 Kg6 24. g4 Rf7 25. b4 Ne5 26. gxf5+ exf5 27. Bg2 h5 28. Bd4 Re8 29. Rfe1 Kf6 30. d7 Rxd7 31. Bxe5+ Rxe5 32. Rxd7 Rxe1+ 33. Kh2 Bc8 34. Rc7 Re8 35. Rxc6+ Ke5 36. Rg6 g4 37. Rh6 gxh3 38. Bxh3 Kf4 39. Rxh5 Re2 40. Kg1 Rxa2 41. Rh4+ Kg5 42. Rd4 Bb7 43. Bf1 a5 44. Bxb5 axb4 45. Rxb4 Ra1+ 46. Kh2 Rh1+ 47. Kg3 f4+ 48. Rxf4 Rg1+ 49. Kh2 Rh1+ 50. Kg3 Rg1+ 51. Kh2 Rh1+ 52. Kg3 Rg1+ 1/2-1/2",
    B: "1826",
  },
  {
    W: "1336",
    T: "300+3",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 d6 5. f4 Nf6 6. Nf3 Bg4 7. h3 Bh5 8. g4 Nxg4 9. hxg4 Bxg4 10. Bc4 Be7 11. Ng5 Be6 12. Bxe6 Bxg5 13. Bxf7+ Kxf7 14. fxg5 Rf8 15. Rxh7 Ne5 16. Nc3 Kg8 17. Qh3 Ng6 18. Rh4 Nxh4 19. Qxh4 Qe7 20. Nd5 Qe5 21. g6 Rf1+ 22. Kxf1 Qf6+ 23. Nxf6+ 1-0",
    B: "1441",
  },
  {
    W: "1773",
    T: "120+0",
    P: "1. Nf3 d5 2. d3 Bf5 3. c3 e6 4. Nbd2 Nf6 5. e4 dxe4 6. dxe4 Bg6 7. Ne5 Nbd7 8. Nxg6 hxg6 9. f4 Bc5 10. e5 Nd5 11. Qf3 Qh4+ 12. g3 Qh5 13. Qg2 Ne3 14. Qe2 Qf5 15. Bg2 Nxg2+ 16. Qxg2 g5 17. Nb3 gxf4 18. Nxc5 Nxc5 19. g4 Qg6 20. O-O Nd3 21. Bxf4 Nxf4 22. Rxf4 O-O-O 23. Kf1 f6 24. exf6 gxf6 25. Kg1 e5 26. Rf5 Rd2 27. Qxd2 Qxg4+ 28. Qg2 Qxf5 29. Rf1 1-0",
    B: "1705",
  },
  {
    W: "2105",
    T: "180+0",
    P: "1. d4 d5 2. Bf4 Nf6 3. e3 e6 4. Nf3 Bd6 5. Ne5 Bxe5 6. dxe5 Nfd7 7. Nd2 Nc6 8. c3 Ncxe5 9. Bxe5 Nxe5 10. f4 Ng6 11. Nf3 b6 12. Bb5+ Bd7 13. Bd3 c5 14. O-O O-O 15. Qe2 c4 16. Bc2 Bc6 17. Rad1 Qc7 18. g3 Rad8 19. Ng5 b5 20. Qh5 h6 21. Nxe6 fxe6 22. Qxg6 Rf6 23. Qh7+ Kf7 24. g4 Be8 25. g5 hxg5 26. fxg5 Rxf1+ 27. Rxf1+ Ke7 28. Qxg7+ Kd6 29. Qf6 Qe7 30. Qd4 Bc6 31. h4 Rg8 32. Kh2 Qg7 33. Qxg7 Rxg7 34. Kg3 e5 35. Bf5 e4 36. Kg4 Ke5 37. h5 Be8 38. h6 Rg8 39. Bh7 Bd7+ 40. Kh5 Be8+ 41. g6 Rh8 42. Kg5 a5 43. g7 Rxh7 44. g8=Q Re7 45. Qg7+ Kd6 46. Qxe7+ Kxe7 47. h7 Bf7 48. h8=Q Be6 49. Qf6+ Kd6 50. Qxe6+ Kxe6 51. Rf5 b4 52. Kf4 b3 53. a3 Kd6 54. Rg5 Ke6 55. Rg6+ Kf7 56. Ra6 a4 57. Ra5 d4 58. Rxa4 d3 59. Rxc4 Ke6 60. Rd4 Kf6 61. Kxe4 d2 62. Rd3 Ke6 63. Rxd2 Kf6 64. Rd4 Ke6 65. Rb4 Kd6 66. Rxb3 Kc5 67. Rb4 Kc6 68. a4 Kc5 69. a5 Kc6 70. a6 Kc5 71. a7 Kc6 1/2-1/2",
    B: "2107",
  },
  {
    W: "1665",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. Nc3 Nf6 5. d3 O-O 6. Bg5 Na5 7. Nd5 Nxc4 8. dxc4 Be7 9. Nxe7+ Qxe7 10. O-O d6 11. h3 Qe6 12. b3 Nxe4 13. Re1 Nxg5 14. Nxg5 Qg6 15. h4 f6 16. Qd5+ Kh8 17. Nh3 Bxh3 18. Re3 Bf5 19. Rg3 Qh5 20. Qxb7 Qxh4 21. Qxc7 Bg6 22. Re1 f5 23. f4 Qxg3 24. Rf1 exf4 25. Rf3 Qe1+ 26. Kh2 Rae8 27. Qxd6 Kg8 28. Qxf4 Re2 29. Rf1 Qd2 30. Qf3 Re3 31. Qf4 Rfe8 32. Rf2 Qe1 33. Rf1 Qc3 34. Rf2 R8e4 35. Qg5 Qf6 36. Qxf6 gxf6 37. c5 Rc3 0-1",
    B: "1703",
  },
  {
    W: "1169",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 g6 3. d3 Bg7 4. g3 Nc6 5. Bg2 d5 6. O-O dxe4 7. dxe4 Qxd1 8. Rxd1 e6 9. Bf4 Nge7 10. c4 O-O 11. Nbd2 Nd4 12. Nxd4 cxd4 13. Nb3 d3 14. Bf1 Bxb2 15. Rab1 Bg7 16. Rxd3 Nc6 17. Rbd1 Nb4 18. R3d2 b6 19. e5 Ba6 20. Be3 Nc6 21. Nd4 Nxe5 22. Nc6 Nf3+ 23. Kg2 Nxd2 24. Rxd2 Bb7 25. Rd6 f6 26. Rxe6 f5 27. Kg1 Bxc6 28. Rxc6 Rfe8 29. Rd6 Rab8 30. Rd2 Rbc8 31. Bd3 Rb8 0-1",
    B: "1283",
  },
  {
    W: "1489",
    T: "60+0",
    P: "1. d4 e5 2. d5 d6 3. e4 Nf6 4. Nc3 c6 5. Bg5 Be7 6. Nf3 O-O 7. Bd3 cxd5 8. Nxd5 Nxd5 9. Bxe7 Qxe7 10. exd5 Nd7 11. Be4 Nf6 12. Qd3 Bg4 13. O-O-O Qd7 14. Kb1 Ne8 15. h3 Bxf3 16. Qxf3 Nc7 17. Rhe1 Nb5 18. c4 Nd4 19. Qd3 Qc7 20. Bxh7+ Kh8 21. Be4 g6 22. Qc3 Kg7 23. f4 Rfe8 24. fxe5 dxe5 25. Bf3 Qd6 26. g4 Qf6 27. Bh1 Qf4 28. d6 Qg5 29. Rf1 Qf6 30. c5 Qxf1 31. Rxf1 e4 32. Qc4 e3 33. Qxf7+ Kh6 34. Rf6 e2 0-1",
    B: "1479",
  },
  {
    W: "1307",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 c5 3. c3 Nc6 4. d4 g6 5. d5 exd5 6. exd5 Nce7 7. c4 d6 8. Nc3 Bg7 9. Bd3 Nf6 10. O-O O-O 11. Re1 b6 12. Bg5 Bb7 13. Qd2 Nc8 14. Ne4 Rb8 15. Re2 Re8 16. Rae1 Qd7 17. Nxf6+ Bxf6 18. Rxe8+ Qxe8 19. Rxe8+ Kg7 20. Bh6# 1-0",
    B: "1388",
  },
  {
    W: "1334",
    T: "60+0",
    P: "1. d4 e6 2. c4 d5 3. Nc3 Nc6 4. e4 dxc4 5. Bxc4 Nxd4 6. Nf3 Bc5 7. Nxd4 Bxd4 8. O-O Nf6 9. Bg5 e5 10. Bxf6 Qxf6 11. Nd5 Qc6 12. b3 Be6 13. a4 Qd7 14. Bb5 c6 15. Bc4 cxd5 16. exd5 Bxd5 17. Bxd5 Qxd5 18. Rc1 O-O 19. Rc2 Rac8 20. Rxc8 Rxc8 21. Qh5 g6 22. Qh6 Rc3 23. g3 Rxb3 24. Qd2 Rb6 25. Rc1 Kg7 26. Rc8 Qd7 27. Ra8 a6 28. Qc2 Rb2 29. Qc8 Rxf2 30. Qxd7 Kf6 0-1",
    B: "1349",
  },
  {
    W: "1422",
    T: "60+0",
    P: "1. e4 e5 2. Bc4 Nc6 3. Qh5 Bc5 4. Qxf7# 1-0",
    B: "1324",
  },
  {
    W: "1215",
    T: "60+0",
    P: "1. e4 d5 2. Nf3 dxe4 3. Ng5 Nf6 4. d3 exd3 5. Bxd3 Nc6 6. Nc3 e6 7. O-O Be7 8. Bb5 h6 9. Nge4 O-O 10. Qxd8 Rxd8 11. Nxf6+ Bxf6 12. Bxc6 bxc6 13. Be3 Ba6 14. Rad1 Bxf1 15. Rxf1 Bxc3 16. Rd1 Rxd1# 0-1",
    B: "1232",
  },
  {
    W: "1461",
    T: "60+0",
    P: "1. g3 c5 2. Bg2 d6 3. e3 g6 4. Ne2 Bg7 5. O-O Nf6 6. c3 O-O 7. d4 cxd4 8. cxd4 Nc6 9. Nbc3 Be6 10. Bxc6 Qb6 11. Bg2 Qc6 12. Bd2 Qb6 13. e4 Qxb2 14. Rb1 Qxa2 15. d5 Qc4 16. dxe6 Qxe6 17. Nd4 Qc4 18. Nce2 e5 19. Rc1 Qxc1 20. Bxc1 exd4 21. Nxd4 1-0",
    B: "1426",
  },
  {
    W: "961",
    T: "60+0",
    P: "1. d4 d5 2. c4 dxc4 3. e4 Nf6 4. Nc3 Nc6 5. d5 Nb4 6. Qa4+ c6 7. Qxb4 cxd5 8. exd5 e6 9. Bxc4 exd5 10. Nxd5 Bxb4+ 11. Nxb4 Qe7+ 12. Be3 Qxb4+ 13. Bd2 Qxb2 14. Rc1 Qe5+ 15. Be3 Qc3+ 16. Bd2 Ne4 0-1",
    B: "881",
  },
  {
    W: "1225",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nf3 Qe6+ 4. Be2 b6 5. O-O Ba6 6. Bxa6 Nxa6 7. Re1 Qd7 8. d4 Nf6 9. Bg5 e6 10. Bxf6 gxf6 11. Qd3 Be7 12. Nc3 O-O-O 13. Nb5 Nb8 14. Nxa7+ Kb7 15. Qa3 Nc6 16. Nxc6 Kc8 17. Na7+ Kb7 18. c4 Ra8 19. Nd2 Rxa7 20. Qf3+ c6 21. Nb3 Qc8 22. Rad1 Rxa2 23. d5 cxd5 24. cxd5 exd5 25. Qxd5+ Qc6 26. Na5+ Rxa5 27. Qxc6+ Kxc6 28. Rc1+ Bc5 29. Re7 Rd8 30. Rxf7 Ra2 31. g3 Rxb2 0-1",
    B: "1326",
  },
  {
    W: "1796",
    T: "60+0",
    P: "1. d4 c5 2. e3 cxd4 3. exd4 d5 4. Nf3 Nc6 5. c4 dxc4 6. d5 Nb8 7. Bxc4 e6 8. Bg5 Be7 9. Bxe7 Nxe7 10. O-O Nxd5 11. Re1 O-O 12. Bxd5 Qxd5 13. Qc2 Nc6 14. Nc3 Qf5 15. Qe2 b6 16. h3 Bb7 17. Ne4 Qg6 18. Ng3 h5 19. Rac1 Ne7 20. Rc7 Bxf3 21. Qxf3 Nf5 22. a4 Nxg3 23. fxg3 Rfc8 24. Qb7 Rxc7 25. Qxa8+ Kh7 26. Qf3 Rc2 27. Re2 Rxe2 28. Qxe2 Qb1+ 29. Kh2 Qg6 30. b4 f5 31. a5 f4 32. gxf4 e5 33. Qxe5 bxa5 0-1",
    B: "1790",
  },
  {
    W: "1556",
    T: "60+0",
    P: "1. e4 e5 2. c3 Nf6 3. Nf3 Nc6 4. d4 exd4 5. cxd4 d5 6. e5 Ne4 7. Bd3 Bg4 8. Nbd2 Nxd4 9. Nxe4 Bxf3 10. gxf3 dxe4 11. Bxe4 Bc5 12. Be3 Kf8 13. Qd3 Nxf3+ 14. Bxf3 Qxd3 15. Bxc5+ Ke8 16. Bxb7 Rd8 17. Bc6+ Rd7 18. Bxd7+ Qxd7 19. Rd1 Qc6 20. Be3 Ke7 21. O-O Rd8 22. Bg5+ f6 23. exf6+ gxf6 24. Rfe1+ Kf7 25. Rxd8 Qc2 26. Bxf6 Kxf6 27. Rde8 Kg5 28. R1e5+ Kg6 29. R5e6+ Kf7 30. R8e7+ Kg8 31. Rxc7 Kf8 32. Rxc2 1-0",
    B: "1569",
  },
  {
    W: "1598",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 d5 3. Bc4 dxc4 4. Nxe5 Qd4 5. Nf3 Qd7 6. Nc3 Nf6 7. O-O Be7 8. Re1 O-O 9. e5 Nd5 10. d4 Bb4 11. Bd2 c6 12. a3 Be7 13. Qe2 b5 14. a4 a6 15. axb5 Nxc3 16. bxc3 cxb5 17. Rad1 Nc6 18. Bf4 Bb7 19. d5 Na5 20. d6 Bd8 21. Nd4 Bb6 22. Qg4 Qxg4 23. f3 Qg6 24. Kh1 Bxd4 25. cxd4 Nc6 26. c3 b4 27. Bg3 bxc3 28. Rc1 Nb4 29. Rxc3 Nd3 30. Rxc4 Bxf3 0-1",
    B: "1571",
  },
  {
    W: "2231",
    T: "60+0",
    P: "1. b3 d5 2. Bb2 c6 3. Nf3 Bf5 4. e3 Nd7 5. Be2 h6 6. O-O e6 7. d3 Ngf6 8. Nbd2 Be7 9. c4 O-O 10. Rc1 a5 11. a3 Re8 12. Qc2 Bf8 13. h3 Bh7 14. Ne5 Nxe5 15. Bxe5 Bxa3 16. Ra1 Bd6 17. Qb2 Bxe5 18. Qxe5 Qb8 19. Qd4 e5 20. Qc5 Qc7 21. cxd5 Nxd5 22. Rfc1 Qe7 23. Rxa5 Qxc5 24. Raxc5 Ra2 25. R1c2 Rxc2 26. Rxc2 Ra8 27. Ne4 Nb4 28. Rc1 Ra2 29. Bf1 Bxe4 30. dxe4 Kf8 31. Bc4 Ke7 32. Rd1 f6 33. g3 b5 34. Bf1 Rb2 35. Ra1 Rxb3 36. Ra7+ Kd6 37. Rxg7 c5 38. Rg6 c4 39. Rxf6+ Ke7 40. Rb6 Nd3 41. Bxd3 cxd3 42. Rb7+ Kd6 43. Rb8 Kc5 44. Rd8 Kc4 45. Kf1 Rb2 46. Ke1 Re2+ 47. Kd1 b4 48. Rc8+ Kb3 49. Rb8 Rxf2 50. Rd8 d2 51. Rxd2 Rxd2+ 52. Kxd2 Ka2 53. Ke2 b3 0-1",
    B: "2348",
  },
  {
    W: "1547",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. exd5 exd5 4. c4 c5 5. cxd5 cxd4 6. Qxd4 Nf6 7. Bg5 Be7 8. Bxf6 Bxf6 9. Qe4+ Qe7 10. Qxe7+ Bxe7 11. Bb5+ Bd7 12. Bxd7+ Nxd7 13. Nf3 O-O 14. Nc3 Bb4 15. O-O Bxc3 16. bxc3 Rfc8 17. c4 Rxc4 18. Rad1 Rac8 19. d6 Ne5 20. Rfe1 Rc2 21. Nxe5 Rxa2 22. d7 Rd8 23. Nc4 f6 24. Ne3 Ra4 25. Nf5 b5 26. Ne7+ Kf7 27. Nc6 b4 28. Nxd8+ Kf8 29. Nc6 Kf7 30. d8=Q Kg6 31. Qd3+ Kf7 32. Qd5+ Kg6 33. Qd3+ Kf7 34. Qb3+ Kg6 35. Qxa4 f5 36. Qxb4 Kg5 37. Rd5 Kf6 38. Qf4 Kg6 39. Qxf5+ Kh6 40. Qg5# 1-0",
    B: "1477",
  },
  {
    W: "1213",
    T: "60+0",
    P: "1. d4 e6 2. c4 d5 3. g3 c5 4. Bg2 Nf6 5. cxd5 Nxd5 6. Bxd5 Qxd5 7. Nc3 Qxh1 8. Bg5 Qxg1+ 9. Kd2 Qxd1+ 10. Rxd1 Be7 11. Bxe7 Kxe7 12. dxc5 Nc6 13. Kc2 Rd8 14. Rxd8 Kxd8 15. a3 e5 16. e3 Bf5+ 17. Kb3 Rc8 18. e4 Na5+ 19. Kb4 Nc6+ 20. Kb3 Nd4+ 21. Kc4 Bxe4 22. Nxe4 Nf3 23. h4 Nh2 24. b4 b6 25. Nd6 Rc6 26. Nxf7+ Kd7 27. Nxe5+ Kc7 28. Nxc6 Kxc6 29. b5+ Kb7 30. c6+ Kc7 31. a4 a5 32. Kd5 Nf3 33. Ke6 Nd4+ 34. Ke5 Nb3 35. Ke6 Nd2 36. Kf7 Nf3 37. Kxg7 Nxh4 38. Kxh7 Nf5 39. g4 Ne7 40. g5 1-0",
    B: "1214",
  },
  {
    W: "1447",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f6 4. Nc3 a6 5. Bxc6 bxc6 6. d3 Bb7 7. Be3 Qe7 8. a3 O-O-O 9. b4 Qd6 10. Bc5 Qe6 11. O-O Bxc5 12. bxc5 Nh6 13. Rb1 d6 14. cxd6 cxd6 15. Ne2 Rd7 16. c4 Kd8 17. Qb3 Qe7 18. Rfc1 Ke8 19. Nc3 Kf7 20. a4 Bc8 21. a5 Rb7 22. Qa3 Rxb1 23. Rxb1 Bd7 24. Rb7 Rc8 25. Qb4 Qe6 26. Qb6 Ng4 27. Rxd7+ Qxd7 28. Qxa6 Qc7 29. Qb6 Qxb6 30. axb6 Rb8 31. Na4 c5 32. Nd2 Nh6 33. Nb3 Ng8 34. Na5 Ne7 35. b7 Nc6 36. Nxc6 Rxb7 37. Nd8+ Kg6 38. Nxb7 h6 39. Nxd6 Kg5 40. Nxc5 Kf4 41. Ne8 Kg5 42. Nxg7 Kg6 43. Nce6 Kf7 0-1",
    B: "1444",
  },
  {
    W: "1815",
    T: "60+0",
    P: "1. e4 g6 2. d4 Bg7 3. Nc3 c6 4. Be3 e6 5. Qd2 Ne7 6. f4 d5 7. e5 Nf5 8. g4 Qh4+ 9. Bf2 Qxg4 10. Nge2 Bh6 11. h4 Qf3 12. Rh3 Qg4 13. Rg3 Nxg3 14. Bxg3 Qf3 15. O-O-O b5 16. a3 a6 17. Bh2 a5 18. Ng1 Qg4 19. Be2 Qxh4 20. Nf3 Qe7 21. Na2 b4 22. axb4 axb4 23. Nxb4 Ra1# 0-1",
    B: "1973",
  },
  {
    W: "1721",
    T: "60+0",
    P: "1. e4 g6 2. Nc3 Bg7 3. Nf3 d6 4. g3 e6 5. Bg2 Nf6 6. d4 O-O 7. d5 e5 8. O-O b6 9. Bg5 Bb7 10. Bxf6 Bxf6 11. Nb5 Bg7 12. Qd3 a6 13. Na3 b5 14. b4 Nd7 15. c3 c5 16. bxc5 Nxc5 17. Qd2 Rc8 18. Rab1 Nxe4 19. Qc1 Nxc3 20. Ng5 Ne2+ 21. Kh1 Rxc1 22. Rbxc1 Nxc1 23. Rxc1 h6 24. Nf3 f5 25. Nh4 Rf6 26. Bh3 e4 27. Kg1 Bxd5 28. Nc2 Bxa2 29. Nd4 g5 30. Nc6 Qd7 31. Ng2 g4 32. Nf4 gxh3 33. Nxh3 f4 34. Nxf4 e3 35. Nb8 Qe7 36. Nd5 Qe6 37. Nxf6+ Bxf6 38. Rc8+ Kg7 39. Rc7+ Kg6 40. Nc6 exf2+ 41. Kxf2 Qb3 42. Kg2 Qb2+ 43. Kh3 h5 44. Nd4 Be5 45. Rg7+ Bxg7 46. Nf5 Kxf5 0-1",
    B: "1762",
  },
  {
    W: "1951",
    T: "300+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. f4 dxe5 5. fxe5 c5 6. c4 Nb6 7. d5 e6 8. d6 Qh4+ 9. g3 Qe4+ 10. Qe2 Qxh1 11. Nh3 Nc6 12. Nc3 Nd4 13. Qf2 Nxc4 14. Bf4 Nxb2 15. Qxb2 Nf3+ 16. Ke2 Nd4+ 17. Ke1 Qc6 18. Rd1 Bd7 19. Bg2 Qb6 20. Qxb6 axb6 21. Bxb7 Ra3 22. Rc1 g6 23. Ng5 Bg7 24. Nge4 Nf3+ 25. Ke2 Nxe5 26. Bxe5 Bxe5 27. Kd3 O-O 28. Rb1 b5 0-1",
    B: "2001",
  },
  {
    W: "2115",
    T: "60+0",
    P: "1. b3 c6 2. Bb2 d5 3. Nf3 Nf6 4. d4 Bg4 5. e3 e6 6. Be2 Bd6 7. h3 Bh5 8. c4 Nbd7 9. cxd5 exd5 10. Nc3 O-O 11. O-O Re8 12. Rc1 Qe7 13. a3 a6 14. b4 Ne4 15. Na4 f5 16. Nc5 Ndxc5 17. dxc5 Bc7 18. Nd4 Bxe2 19. Qxe2 Qg5 20. Nf3 Qh6 21. Qc2 f4 22. exf4 Qxf4 23. Rce1 Re6 24. Bc1 Qf5 25. Nd4 Qe5 26. Nf3 Qh5 27. Nd4 Rg6 28. Rxe4 dxe4 29. Qxe4 Rf8 30. Be3 Qxh3 31. Ne6 Qxe6 32. g3 Qxe4 0-1",
    B: "2166",
  },
  {
    W: "1952",
    T: "300+0",
    P: "1. e4 c5 2. Bc4 Nc6 3. Nc3 e6 4. d3 a6 5. a4 Nf6 6. Bg5 Be7 7. Nf3 h6 8. Bh4 d5 9. Bxf6 Bxf6 10. exd5 exd5 11. Nxd5 O-O 12. c3 Re8+ 13. Ne3 Be6 14. Bxe6 Rxe6 15. O-O Qc7 16. d4 cxd4 17. cxd4 Rd8 18. d5 Red6 19. Qc2 Rxd5 20. Nxd5 Rxd5 21. Rad1 Qd6 22. Rxd5 Qxd5 23. Rd1 Qa2 24. Re1 Qxb2 25. Re8# 1-0",
    B: "1972",
  },
  {
    W: "1071",
    T: "120+1",
    P: "1. e4 c6 2. f4 e5 3. fxe5 d6 4. exd6 Qxd6 5. Qh5 g6 6. Qf3 h5 7. Nc3 Bg7 8. Nb5 cxb5 9. Bxb5+ Bd7 10. Bxd7+ Qxd7 11. Qf4 Ne7 12. Nf3 O-O 13. Ng5 Nbc6 14. Nxf7 Rxf7 15. Qxf7+ Kxf7 16. O-O+ Ke6 17. d4 Bxd4+ 18. Kh1 h4 19. Bf4 Bxb2 0-1",
    B: "884",
  },
  {
    W: "1878",
    T: "120+1",
    P: "1. b3 b6 2. Bb2 Bb7 3. e3 d6 4. Nf3 e5 5. Be2 f5 6. O-O Nf6 7. d4 e4 8. Ne1 Be7 9. c4 O-O 10. Nc3 Nbd7 11. f3 a6 12. Nc2 Qe8 13. fxe4 fxe4 14. Qd2 Qg6 15. b4 Rae8 16. Rae1 Ng4 17. Rxf8+ Bxf8 18. Bxg4 Qxg4 19. d5 Ne5 20. Nd4 Nd3 21. Rf1 Nxb2 22. Qxb2 Bc8 23. Qf2 Qg6 24. Qf4 Be7 25. Qg3 Rf8 26. Rxf8+ Bxf8 27. Qxg6 hxg6 28. Nxe4 Be7 29. Nc6 Bf6 30. Nxf6+ gxf6 31. Kf2 Kf7 32. Kf3 Bd7 33. Nb8 Bc6 34. Nxc6 1-0",
    B: "1869",
  },
  {
    W: "1902",
    T: "120+1",
    P: "1. e4 c6 2. Nf3 d5 3. Nc3 d4 4. Ne2 c5 5. c3 Bg4 6. cxd4 Bxf3 7. gxf3 cxd4 8. Qa4+ Nc6 9. Ng3 e5 10. Bb5 Qc7 11. O-O Nf6 12. b3 Bd6 13. Ba3 Bxa3 14. Qxa3 a6 15. Bxc6+ bxc6 16. Rac1 Nd7 17. Qa4 c5 18. Rc2 O-O 19. Rfc1 Rfc8 20. Nf5 Kf8 21. b4 g6 22. Ng3 Qc6 23. Qa3 a5 24. bxa5 Nb6 25. Ne2 Kg8 26. d3 Qb5 27. Qb3 Qxb3 28. axb3 Rxa5 29. f4 Rb5 30. fxe5 Rxb3 31. Nf4 Re8 32. Rxc5 Na4 33. Rc8 Kf8 34. Rxe8+ Kxe8 35. e6 fxe6 36. Nxe6 Ke7 37. Nxd4 Rxd3 38. Nc6+ Ke6 39. Kg2 Nc3 40. f3 Rd2+ 41. Kh3 Ne2 42. Rc4 Nf4+ 43. Kg3 Nh5+ 44. Kh3 Nf4+ 45. Kg4 Nh5 46. f4 Rxh2 47. f5+ gxf5+ 48. exf5+ Kd6 49. Kg5 Kd5 0-1",
    B: "1935",
  },
  {
    W: "1417",
    T: "120+1",
    P: "1. d4 e5 2. dxe5 Nc6 3. f4 f6 4. e3 fxe5 5. Nf3 e4 6. Nd4 Nxd4 7. exd4 Qh4+ 8. g3 Qh6 9. c4 Bb4+ 10. Nc3 Bxc3+ 11. bxc3 Nf6 12. Ba3 d6 13. Qb3 Qh5 14. Be2 Bg4 15. Bxg4 Qxg4 16. Qxb7 O-O 17. Qxc7 Qf3 18. Kd2 Qd3+ 19. Ke1 Qxc3+ 20. Kf2 Qf3+ 21. Kg1 Ng4 22. Rf1 Qe3+ 23. Kg2 Qxa3 24. Kh3 Nf6 25. Rf2 e3 26. Re2 Qd3 27. Rhe1 Qg6 28. Rxe3 Qg4+ 29. Kg2 h5 30. Qxd6 h4 31. Qe6+ Qxe6 32. Rxe6 hxg3 33. hxg3 Rae8 34. Rxe8 Rxe8 35. Rxe8+ Nxe8 36. d5 Kf7 37. Kf3 Ke7 38. Ke4 Kd6 39. Kd4 Nc7 40. c5+ Kd7 41. d6 Ne6+ 42. Kd5 Nd8 43. c6+ Nxc6 44. a4 Nb4+ 45. Kc5 Nd3+ 46. Kd5 Nf2 47. g4 Nxg4 48. f5 Nf6+ 49. Ke5 Ne8 50. a5 Nxd6 51. f6 gxf6+ 52. Kxf6 Nc4 53. Kf5 Nxa5 54. Ke4 Kc6 55. Kd4 Kb5 56. Kc3 Nc4 57. Kb3 a5 58. Ka2 Ka4 59. Kb1 Kb3 60. Ka1 Ka3 61. Kb1 Nb2 62. Ka1 Nd3 63. Kb1 Nb4 64. Ka1 Nc2+ 65. Kb1 Nb4 66. Ka1 Nd3 67. Kb1 a4 68. Ka1 Kb3 69. Kb1 Nb4 70. Ka1 a3 71. Kb1 Nd3 72. Ka1 Nb4 73. Kb1 Kc3 74. Ka1 Kb3 1/2-1/2",
    B: "1506",
  },
  {
    W: "1518",
    T: "120+1",
    P: "1. d4 d5 2. Bg5 Bg4 3. Nc3 h6 4. Bh4 Nf6 5. h3 Bh5 6. Bg3 Ne4 7. Nxe4 dxe4 8. c3 Nc6 9. Qb3 b6 10. e3 a6 11. Be2 Bg6 12. a4 e6 13. Bc4 Bd6 14. Bxd6 Qxd6 15. Ne2 O-O 16. Nf4 Na5 17. Qa2 Nxc4 18. Qxc4 Bh7 19. b4 b5 20. axb5 axb5 21. Rxa8 bxc4 22. O-O Rxa8 23. Rb1 Ra2 24. b5 Qb6 25. d5 g5 26. dxe6 gxf4 27. exf7+ Kxf7 28. exf4 e3 29. fxe3 Bxb1 30. Kh2 Qxe3 31. h4 Qf2 32. Kh3 Qxg2# 0-1",
    B: "1508",
  },
  {
    W: "1531",
    T: "120+1",
    P: "1. d3 d5 2. Nf3 Nc6 3. g3 e5 4. Bg2 e4 5. dxe4 dxe4 6. Qxd8+ Kxd8 7. Ng5 Nh6 8. Nxe4 Bf5 9. Nbc3 Bb4 10. Bd2 Ke7 11. Nd5+ Kd7 12. Nxb4 1-0",
    B: "1640",
  },
  {
    W: "1318",
    T: "120+1",
    P: "1. g3 e5 2. Bg2 d5 3. d3 Be6 4. Nd2 Bd6 5. c4 c6 6. b3 Ne7 7. e3 Nd7 8. Ne2 f6 9. O-O Qa5 10. Bb2 O-O-O 11. e4 h5 12. exd5 cxd5 13. cxd5 Nxd5 14. Nc4 Qa6 15. d4 g5 16. dxe5 Bxe5 17. Nxe5 Nxe5 18. Rc1+ Kb8 19. Nd4 g4 20. Re1 Nf3+ 21. Bxf3 gxf3 22. Rxe6 Qxa2 23. Rc2 Qxb3 24. Nxb3 Nb4 25. Qxf3 Nxc2 26. Qf4+ Ka8 27. Rxf6 Rd1+ 28. Kg2 Ne1+ 29. Kh3 Rdd8 30. Nd4 Nd3 31. Qe4 Nxb2 32. Ne6 Rde8 33. Qf5 Nc4 34. Ng7 Rd8 35. Ne6 Nd6 36. Qe5 Rde8 37. Qd5 Nc8 0-1",
    B: "1339",
  },
  {
    W: "1378",
    T: "180+0",
    P: "1. e4 Nc6 2. Bb5 d5 3. exd5 Qxd5 4. Bxc6+ Qxc6 0-1",
    B: "1393",
  },
  {
    W: "1409",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 d6 3. h3 Qe7 4. d4 exd4 5. Qxd4 Nc6 6. Qd5 Qe5 7. Qxe5+ Nxe5 8. Nxe5 dxe5 9. Nc3 Bd6 10. a4 Nf6 11. Bc4 O-O 12. O-O Be6 13. Bxe6 fxe6 14. b3 a5 15. Bb2 c5 16. Nb5 Bb8 17. Rfe1 Rd8 18. Rad1 Rxd1 19. Rxd1 h6 20. Rd8+ Kf7 21. Nd6+ Bxd6 22. Rxd6 Nxe4 23. Rd7+ Kf6 24. Rxb7 Kf5 25. g4+ Kf4 26. Kg2 g5 27. Re7 Ra6 28. Rh7 Rd6 29. Rxh6 Rd2 30. Rxe6 Rxf2+ 31. Kg1 Kg3 32. Bxe5+ Kxh3 33. Rh6+ Kxg4 34. Rh2 Rxh2 35. Kxh2 Kf5 36. Bc7 g4 37. Bxa5 Kf4 38. Kg2 g3 39. Be1 Kg4 40. a5 Ng5 41. a6 Nf3 42. Bxg3 Nd4 43. a7 Nc6 44. a8=Q Na7 45. Qxa7 1-0",
    B: "1444",
  },
  {
    W: "1794",
    T: "180+0",
    P: "1. d4 d5 2. c4 1-0",
    B: "1789",
  },
  {
    W: "1878",
    T: "180+0",
    P: "1. e4 d6 2. d4 Nd7 3. Bd3 Ngf6 4. Nf3 e5 5. dxe5 dxe5 6. O-O c6 7. Nc3 Be7 8. Bg5 Qc7 9. Ne2 O-O 10. Ng3 Nc5 11. Nf5 Bxf5 12. exf5 e4 13. Bxf6 Bxf6 14. Bxe4 Nxe4 15. Qd3 Rfe8 16. Rae1 Rad8 17. Qc4 Ng5 18. Qg4 Nxf3+ 19. gxf3 Rxe1 20. Rxe1 Qd7 21. Kh1 Re8 22. Rg1 Qd4 23. Qg3 Qxb2 24. h4 Qxc2 25. h5 Qxf5 26. h6 Qh5+ 27. Kg2 Qxh6 0-1",
    B: "1909",
  },
  {
    W: "1622",
    T: "180+0",
    P: "1. b3 e6 2. Bb2 c6 3. e3 Ne7 4. Bc4 d5 5. Be2 g6 6. Bxh8 Bd7 7. Bb2 f5 8. Nf3 Na6 9. Ne5 Qc7 10. d4 O-O-O 11. Bxa6 bxa6 12. O-O Ng8 13. c4 Bd6 14. f4 Bxe5 15. dxe5 c5 16. cxd5 exd5 17. Qxd5 Bb5 18. Qa8+ Qb8 19. Qxb8+ Kxb8 20. Re1 Ne7 21. a4 Bc6 22. Nc3 Nd5 23. Nxd5 Rxd5 24. e6 Kc8 25. e7 Kc7 26. Be5+ Kd7 27. Bf6 Ke8 28. Rad1 Rd7 29. Rxd7 Bxd7 30. Rd1 h5 31. h3 a5 32. Kf2 a6 33. Kf3 Bc6+ 34. Kf2 Be4 35. Rd8+ Kf7 36. Bg5 Bc6 37. g4 hxg4 38. hxg4 fxg4 39. Kg3 Ke6 40. e8=Q+ Bxe8 41. Rxe8+ Kd5 42. Kxg4 c4 43. bxc4+ Kxc4 44. Re6 Kb4 45. Rxa6 Kxa4 46. Rxg6 Kb5 47. Rh6 a4 48. Rh1 a3 49. Bf6 a2 50. f5 Kc6 51. Bd4 Kd6 52. Rh6+ Ke7 53. Kg5 Kf7 54. f6 Kf8 55. Kg6 Kg8 56. f7+ Kf8 57. Rh8+ Ke7 58. f8=Q+ Ke6 59. Qf6+ Kd5 60. Ba1 Ke4 61. Qd4+ Kf3 62. Kf6 Ke2 63. Ke6 Kf3 64. e4 Kf4 65. e5+ Kg5 66. Rf8 Kg6 67. Qg1+ Kh7 68. Rf3 Kh8 69. Rh3# 1-0",
    B: "1648",
  },
  {
    W: "1602",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. d3 Nc6 4. Nc3 Qa5 5. Bd2 Nf6 6. Nf3 Qb6 7. Qc1 e6 8. Be3 Bc5 9. Bxc5 Qxc5 10. Qd2 Nd4 11. Nxd4 Qxd4 12. O-O-O O-O 13. Re1 a5 14. Rg1 a4 15. g3 Ng4 16. Re2 Rd8 17. a3 c6 18. h3 Nf6 19. Bg2 Bd7 20. Rge1 b5 21. Na2 Nd5 22. Bxd5 exd5 23. c3 Qf6 24. g4 c5 25. f4 c4 26. d4 Rdb8 27. f5 Re8 28. Rxe8+ Rxe8 29. Rxe8+ Bxe8 30. Nb4 Bc6 31. Nxc6 Qxc6 32. Kc2 Qf6 33. Qf4 h6 34. Qb8+ Kh7 35. Qxb5 Qh4 36. Qxd5 Qxh3 37. Qxc4 Qxg4 38. Qxa4 Qxf5+ 39. Kb3 h5 40. Qc4 h4 41. a4 h3 42. a5 h2 43. a6 h1=Q 44. a7 Qd1+ 45. Kb4 Qdf3 46. Qa6 Qd7 47. a8=Q Qxa8 48. Qxa8 f5 49. Qh1+ Kg8 50. d5 f4 51. Qf3 Qd6+ 52. Kc4 g5 53. Kd4 Kg7 54. c4 Kg6 55. b3 Qb6+ 56. Kc3 Qa5+ 57. Kd4 Qb6+ 58. Kc3 Kf5 59. Qh3+ Ke4 60. Qg2+ Ke5 61. Qe2+ Kf5 62. Qd3+ Kg4 63. Qe2+ f3 64. Qe4+ Kg3 65. Qe5+ Kf2 66. Qxg5 Ke2 67. Qg4 Qf2 68. Qe6+ Kf1 69. d6 Kg2 0-1",
    B: "1467",
  },
  {
    W: "1670",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 d6 4. dxe5 Bg4 5. exd6 Bxd6 6. Be2 Bxf3 7. Bxf3 Qe7 8. O-O h5 9. c4 h4 10. h3 O-O-O 11. a3 Bh2+ 12. Kxh2 Rxd1 13. Rxd1 Nf6 14. b4 Ne5 15. Be2 Nxe4 16. Re1 Nxf2 17. Bf4 f6 18. Nd2 Rd8 19. Nf3 Nxf3+ 20. Bxf3 Qf8 21. c5 g5 22. Be3 Nd3 23. Re2 Ne5 24. Be4 g4 25. Bf4 gxh3 26. Kxh3 Qg8 27. Rf1 Qg4+ 28. Kh2 Nc4 29. Bd2 Qxe2 0-1",
    B: "1635",
  },
  {
    W: "2196",
    T: "180+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 Nf6 5. Be2 Bd6 6. c3 O-O 7. h3 Bf5 8. O-O Nbd7 9. Bd3 Ne4 10. Nbd2 Ndf6 11. Bxe4 Nxe4 12. Nxe4 Bxe4 13. Ne5 Bxe5 14. dxe5 f5 15. f4 c5 16. Qb3 Qb6 17. Qxb6 axb6 18. Be3 Kf7 19. a3 Rfc8 20. g4 g6 21. Kh2 Ra4 22. Rad1 b5 23. Kg3 b4 24. axb4 cxb4 25. cxb4 Rxb4 26. Bd4 Rb3+ 27. Bc3 b5 28. Rd4 Rc4 29. Rxc4 dxc4 30. Kh4 b4 31. Bd4 h6 32. Ra1 c3 33. bxc3 bxc3 34. Ra7+ Kg8 35. e6 c2 36. Rg7+ Kf8 37. e7+ Ke8 38. Bf6 Bc6 39. Rg8+ Kf7 40. Rf8+ Ke6 41. gxf5+ gxf5 42. Be5 c1=Q 43. e8=Q+ Bxe8 44. Rxe8+ Kf7 45. Rh8 Qe1+ 46. Kh5 Qe2+ 47. Kh4 Qf2+ 48. Kh5 Rxh3# 0-1",
    B: "2122",
  },
  {
    W: "2030",
    T: "180+0",
    P: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nc6 5. Nc3 Nf6 6. Bf4 e6 7. c5 Be7 8. Bb5 O-O 9. Bxc6 bxc6 10. Nf3 a5 11. Ne5 Ba6 12. Qa4 Bb5 13. Nxb5 cxb5 14. Qxb5 Rb8 15. Qe2 Ne4 16. O-O Bf6 17. Ng4 Bxd4 18. Bxb8 Qxb8 19. Rab1 h5 20. Ne3 Bxc5 21. Qxh5 Bxe3 22. fxe3 Qb6 23. Rf3 Rc8 24. Rbf1 Nf6 25. Qg5 Kf8 26. h4 Qxb2 27. R1f2 Qb1+ 28. Kh2 Rc7 29. h5 Qh7 30. Rh3 a4 31. h6 Ne8 32. hxg7+ Qxg7 33. Qf4 Rc4 34. Qb8 Ke7 35. Qa7+ Nc7 36. Rff3 Qe5+ 37. Rfg3 Rc2 38. Qb8 Rxa2 39. Rh4 a3 40. Rf4 Rb2 41. Qa7 a2 42. Qc5+ Qd6 43. Qa5 d4 44. Rxd4 Nd5 45. Qa7+ Kf6 0-1",
    B: "2053",
  },
  {
    W: "2265",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bc4 e6 7. f3 Be7 8. Be3 O-O 9. Qd2 Nc6 10. g4 Nxd4 11. Bxd4 b5 12. Bb3 Bb7 13. O-O-O Nd7 14. h4 b4 15. Na4 Qa5 16. a3 d5 17. axb4 Bxb4 18. Bc3 Bxc3 19. Qxc3 Qxc3 20. Nxc3 Nc5 21. exd5 Nxb3+ 22. cxb3 Rfd8 23. d6 Bxf3 24. Rhg1 Bxd1 25. Rxd1 Rac8 26. Kb1 Rc6 27. Ne4 f5 28. gxf5 exf5 29. Ng5 Rcxd6 30. Re1 h6 31. Nf3 Rd1+ 32. Rxd1 Rxd1+ 33. Kc2 Rf1 34. Nd4 Rf4 35. Ne2 Rxh4 0-1",
    B: "2356",
  },
  {
    W: "2089",
    T: "180+0",
    P: "1. e4 d6 2. Nf3 g6 3. d4 Bg7 4. c4 Nc6 5. Nc3 Bg4 6. Be3 Bxf3 7. gxf3 e5 8. d5 Nce7 9. h4 f5 10. Bh3 Nf6 11. Qa4+ Qd7 12. Qxd7+ Kxd7 13. f4 exf4 14. Bxf4 Ke8 15. exf5 Nxf5 16. Bxf5 gxf5 17. O-O-O Kf7 18. Nb5 Rhc8 19. h5 Ne4 20. Rh2 a6 21. Nd4 Bxd4 22. Rxd4 Re8 23. f3 Nf6 24. Kd2 Rg8 25. Rd3 Rae8 26. Re3 Rxe3 27. Kxe3 Re8+ 28. Kd2 Rg8 29. Re2 Nxh5 30. Be3 f4 31. Bd4 Ng3 32. Rh2 Nf1+ 33. Kc3 Nxh2 34. Kb4 Nxf3 35. c5 Nxd4 36. cxd6 cxd6 37. Kc4 Ne2 38. b4 f3 39. a4 f2 40. b5 axb5+ 41. axb5 f1=Q 42. b6 Nf4+ 43. Kb4 Rc8 44. Ka5 Qb1 45. Ka4 Ra8# 0-1",
    B: "2093",
  },
  {
    W: "2116",
    T: "180+0",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. f3 h6 5. e4 dxe4 6. fxe4 c5 7. d5 a6 8. e5 Ng8 9. Nf3 exd5 10. cxd5 b5 11. a3 Bb7 12. d6 Qd7 13. Bd3 Qe6 14. O-O Nd7 15. Re1 g6 16. Bf4 Bg7 17. Qe2 g5 18. Bg3 O-O-O 19. Bxb5 axb5 20. Nxb5 Nxe5 21. Nxe5 Rxd6 22. Nxd6+ Qxd6 23. Nxf7 Qd4+ 24. Kh1 Nf6 25. Nxh8 Bxh8 26. Be5 Qd5 27. Rad1 Qc6 28. Rd6 Qxg2+ 29. Qxg2 Bxg2+ 30. Kxg2 Ne8 31. Re6 Bxe5 32. Rxe8+ Kd7 33. R8xe5 1-0",
    B: "2105",
  },
  {
    W: "1318",
    T: "180+0",
    P: "1. e4 e5 2. Nc3 Nf6 3. Nf3 d5 4. exd5 Nxd5 5. d3 Nxc3 6. bxc3 Nc6 7. d4 exd4 8. cxd4 g6 9. Bb5 Bd7 10. O-O a6 11. Ba4 Bg7 12. d5 Bxa1 13. dxc6 Bxc6 14. Re1+ Kf8 15. Ba3+ Kg8 16. Qxd8+ Rxd8 17. Bxc6 bxc6 18. Rxa1 Kg7 19. Bb2+ f6 20. Ng5 Rd2 21. Ne6+ Kf7 22. Nxc7 a5 23. Rc1 Rc8 24. Na6 Rcd8 25. g3 Ra8 26. Nc5 Rb8 27. Bxf6 Kxf6 28. Nd7+ Rxd7 29. c4 Rd2 30. f4 Rxa2 31. c5 Rbb2 32. Rd1 Rxh2 33. Rd6+ Kg7 34. Rxc6 Rag2+ 35. Kf1 Rxg3 36. Rc7+ Kh6 37. c6 Rf3+ 38. Ke1 Rxf4 39. Rd7 Rf8 40. c7 a4 41. Rd8 Rxd8 42. cxd8=Q a3 43. Qa8 a2 0-1",
    B: "1288",
  },
  {
    W: "2225",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Bg5 h6 5. Bh4 g5 6. Bg3 Ne4 7. Qc2 Bxc3+ 8. bxc3 Nxg3 9. hxg3 b6 10. Nf3 Bb7 11. e3 d6 12. Bd3 Nd7 13. Be4 c6 14. Nd2 Qe7 15. f4 f5 16. Bf3 g4 17. Be2 h5 18. e4 Qf6 19. e5 dxe5 20. dxe5 Qe7 21. Nb3 c5 22. Kf2 h4 23. gxh4 Rxh4 24. a4 a5 25. Qd2 O-O-O 26. Qe3 Rdh8 27. Rxh4 Qxh4+ 28. Qg3 Qe7 29. Rd1 Kc7 30. Rd6 Nb8 31. Nd2 Rd8 32. Rxd8 Qxd8 33. Qe3 Qd7 34. Nb1 Qxa4 35. Qd2 Qd7 36. Qxd7+ Kxd7 37. Na3 Bc6 38. Nb5 Bxb5 39. cxb5 Ke7 40. Bc4 Nd7 41. Kg3 Kf7 42. Kh4 Nf8 43. Kg5 Kg7 44. g3 a4 45. Ba2 a3 46. Bc4 Nh7+ 47. Kh5 Nf8 48. Kg5 Kf7 49. Kh6 Ke7 50. Kg7 Nd7 51. Kg6 Nb8 52. Kg7 Nd7 53. Kg6 Nxe5+ 54. fxe5 f4 55. gxf4 g3 56. f5 exf5 57. Kxf5 g2 0-1",
    B: "2232",
  },
  {
    W: "1772",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. Nf3 c5 5. Bb5+ Bd7 6. Bxd7+ Nxd7 7. O-O e6 8. d4 Ngf6 9. Bd2 Qb6 10. a4 cxd4 11. Nb5 a6 12. Na3 Qxb2 13. Nc4 Qxa1 14. Qxa1 Bc5 15. Nxd4 O-O 16. Be3 b5 17. axb5 axb5 18. Qc3 bxc4 19. Qxc4 Rac8 20. Qe2 Ne4 21. Nf3 Nc3 22. Qd3 Nd5 23. Bxc5 Nxc5 24. Qb5 Rfd8 25. h3 Ne4 26. Ne5 Rxc2 27. Qb7 Rf8 28. Nd7 Rfc8 29. Ne5 R2c7 30. Qb3 Ndc3 31. Qb6 Nd5 32. Qb1 Nd2 33. Qd1 Nxf1 34. Qxf1 Rc1 35. Qxc1 Rxc1+ 36. Kh2 f6 37. Nd7 Kf7 38. Nb8 Ke7 39. Na6 Rc2 40. f3 Nf4 41. Kg3 e5 42. Nb4 Rxg2+ 43. Kh4 Rh2 44. Nc6+ Ke6 45. Nxe5 fxe5 46. Kg3 Rxh3+ 47. Kf2 Rh5 48. Ke3 Rf5 49. Ke4 h5 50. Ke3 h4 51. Kf2 h3 52. Kg1 Rh5 53. Kh2 Ne2 54. Kh1 1-0",
    B: "1729",
  },
  {
    W: "1735",
    T: "30+2",
    P: "1. e4 e6 2. d4 b6 3. c4 Bb7 4. Bd3 h6 5. f4 Nf6 6. Nc3 d5 7. cxd5 exd5 8. e5 Nfd7 9. Nf3 c5 10. O-O cxd4 11. Nxd4 Bc5 12. Be3 Nc6 13. Nc2 d4 14. Na4 dxe3 15. Nxc5 Nxc5 16. Nxe3 Qxd3 17. Qc1 Nd4 18. Rd1 Ne2+ 0-1",
    B: "1631",
  },
  {
    W: "2078",
    T: "60+0",
    P: "1. c3 d5 2. d4 c5 3. e3 Nc6 4. dxc5 Nf6 5. Nf3 a5 6. Nbd2 e5 7. Nb3 a4 8. Nbd2 Bxc5 9. a3 Bg4 10. Be2 O-O 11. O-O Qc7 12. h3 Bh5 13. Nh2 Bxe2 14. Qxe2 e4 15. f4 exf3 16. Nhxf3 Rfe8 17. Kh1 Rxe3 18. Qb5 Qb6 19. Qxb6 Bxb6 20. c4 Re2 21. cxd5 Nxd5 22. Nc4 Bc5 23. Bg5 f6 24. Bh4 Rae8 25. Bg3 Nd4 26. Nxd4 Bxd4 27. Rab1 Ne3 28. Rfc1 Ba7 29. Nxe3 R2xe3 30. Bf2 Rb3 31. Bxa7 Re2 32. Rg1 Rg3 33. Bd4 Rb3 34. Bc3 Kf7 35. Rbe1 Rb5 36. Rxe2 1-0",
    B: "1994",
  },
  {
    W: "2033",
    T: "60+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. f4 c4 4. Nf3 e6 5. Bxc4 d5 6. Bb3 d4 7. Ne2 f6 8. Ng3 e5 9. d3 Nh6 10. O-O Bg4 11. h3 Bxf3 12. Qxf3 Qc7 13. fxe5 Nxe5 14. Qf2 Nhf7 15. Nf5 g6 16. Bxf7+ Qxf7 17. Nxd4 Bg7 18. Bd2 O-O 19. Bc3 Rac8 20. Kh1 a6 21. Rae1 b5 22. a3 Rc6 23. Nf3 Nxf3 24. Qxf3 Qc7 25. Qg4 a5 26. e5 f5 27. Qf4 b4 28. axb4 axb4 29. Qxb4 Rb6 30. Qf4 Re6 31. d4 Qc6 32. d5 Qxd5 33. Qd2 Qc6 34. Qd6 Rxd6 35. exd6 Qxd6 36. Bxg7 Kxg7 37. Rd1 1-0",
    B: "1971",
  },
  {
    W: "1746",
    T: "60+0",
    P: "1. d4 d6 2. e3 e6 3. Bd3 Nf6 4. f4 Bd7 5. Nf3 Be7 6. O-O O-O 7. Nbd2 Nc6 8. c3 e5 9. fxe5 dxe5 10. dxe5 Ne8 11. Nb3 Bg4 12. Qe1 Bxf3 13. Rxf3 Nxe5 14. Bxh7+ Kxh7 15. Rh3+ Kg8 16. e4 Ng6 17. Qe3 f6 18. c4 Nd6 19. Nd4 Qd7 20. Nf5 Nxf5 21. exf5 Ne5 22. Qg3 Kf7 23. Bh6 Rg8 24. c5 gxh6 25. Qb3+ Kf8 26. Rxh6 Rb8 27. Rd1 Qc6 28. Rh7 Rd8 29. Rxd8+ Bxd8 30. g3 Qxc5+ 31. Kg2 Qc6+ 32. Kh3 Qe8 0-1",
    B: "1841",
  },
  {
    W: "1725",
    T: "60+0",
    P: "1. e4 c5 2. c3 Nc6 3. d4 cxd4 4. cxd4 e5 5. dxe5 Nxe5 6. Nc3 Bc5 7. Qd5 d6 8. f4 Be6 9. Qd1 Ng4 10. Bb5+ Kf8 11. Nh3 a6 12. Bd3 Qh4+ 13. Kf1 N4f6 14. Qf3 Bg4 15. Qg3 Qh5 16. Ng5 h6 17. Nf3 Be6 18. Bd2 Ne7 19. f5 Bd7 20. Nh4 g6 21. fxg6 Rg8 22. Qf4 Nxg6 23. Be2 Nxf4 24. Bxh5 N4xh5 25. Ke2 Ng4 26. Rhf1 f6 0-1",
    B: "1744",
  },
  {
    W: "1896",
    T: "60+0",
    P: "1. e4 d5 2. e5 c6 3. f4 e6 4. Nf3 Nh6 5. d4 Nf5 6. c3 c5 7. g4 Ne7 8. h4 h5 9. gxh5 Nf5 10. Bd3 Ng3 11. Bf5 Nxf5 12. Rg1 Rxh5 13. Ng5 g6 14. Na3 Rxh4 15. Nb5 cxd4 16. Nxd4 Nxd4 17. Qxd4 Nc6 18. Qa4 Bd7 19. Be3 Nxe5 20. Qc2 Nc4 21. Bc1 Qb6 22. b3 Qxg1+ 23. Ke2 Rh2+ 24. Kd3 Rxc2 25. Kxc2 Qf2+ 26. Kb1 Nd2+ 27. Kb2 Ne4+ 28. Kb1 Nxg5 29. fxg5 Bb5 30. a4 Bd3# 0-1",
    B: "1903",
  },
  {
    W: "1423",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O a6 5. Ba4 b5 6. Bb3 a5 7. c3 a4 8. Bc2 b4 9. d4 bxc3 10. bxc3 Rb8 11. d5 Ne7 12. Re1 g6 13. Bg5 Bg7 14. Bxf6 Bxf6 15. Na3 d6 16. Rb1 Bg4 17. h3 Bh5 18. g4 Bxg4 19. hxg4 O-O 20. Qd2 Rxb1 21. Rxb1 c5 22. Nc4 Nc8 23. Bxa4 Kg7 24. Ng5 Bxg5 25. Qxg5 Qxg5 26. Kg2 Qxg4+ 0-1",
    B: "1482",
  },
  {
    W: "1935",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d4 Nf6 5. Nf3 Bg4 6. h3 Bxf3 7. Qxf3 Nc6 8. Bb5 e6 9. Bxc6+ bxc6 10. Qxc6+ Nd7 11. Bg5 f6 12. Bf4 Be7 13. Bxc7 Qc8 14. d5 O-O 15. Qxe6+ Kh8 16. d6 Nc5 17. Qxc8 Raxc8 18. dxe7 Rfe8 19. Bd6 Ne6 20. O-O-O h5 21. Rhe1 Nc5 22. Bxc5 Rxc5 23. Rd8 Rc8 24. Rxc8 Rxc8 25. e8=Q+ Rxe8 26. Rxe8+ Kh7 27. Ra8 a5 28. Rxa5 Kg6 29. Ra7 f5 30. Nd5 h4 31. c4 f4 32. c5 f3 33. c6 fxg2 34. Nf4+ Kf5 35. Nxg2 g5 36. c7 Ke4 37. c8=Q Kf3 38. Qg4+ Kxf2 39. Rf7+ Kg1 0-1",
    B: "1956",
  },
  {
    W: "2180",
    T: "60+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 Nf6 6. f4 O-O 7. Nf3 d5 8. O-O dxe4 9. Nxe4 Nxe4 10. dxe4 Nd4 11. c3 Nxf3+ 12. Qxf3 Qc7 13. Be3 b6 14. Rfd1 Bb7 15. h3 Rfd8 16. Qe2 h6 17. e5 a5 18. f5 Bxg2 19. Qxg2 Qxe5 20. Re1 Qxf5 21. Rf1 Qe6 22. Rae1 Qd7 23. Qf3 Qd5 24. Bf4 Qxf3 25. Rxf3 e5 26. Bxe5 Re8 27. Rfe3 Bxe5 28. Rxe5 Rxe5 29. Rxe5 Rd8 30. Re2 Kg7 31. Kf2 Kf6 32. Ke1 Rd3 33. Rg2 Ke6 34. Ke2 Rd5 35. Rf2 f5 36. g4 Re5+ 37. Kd2 fxg4 38. hxg4 Re4 39. Rg2 Kf6 40. Kd3 Rf4 41. a4 Kg5 42. b3 Rxg4 43. Re2 Rg3+ 44. Kc4 h5 45. Kb5 h4 46. Kxb6 Rxc3 47. Kxa5 Rxb3 48. Rc2 h3 49. Rxc5+ Kh4 0-1",
    B: "2225",
  },
  {
    W: "2288",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6 4. Bxc6 dxc6 5. O-O Bg7 6. Re1 e5 7. d3 Ne7 8. Nbd2 O-O 9. Nc4 b6 10. a4 Qc7 11. Be3 Be6 12. Nfd2 Rad8 13. Qc1 f5 14. exf5 Nxf5 15. Bg5 Rde8 16. b3 Bd5 17. Ne4 Nd4 18. Ncd6 Re6 19. Nc4 h6 20. Bxh6 g5 21. Bxg7 Qxg7 22. Qxg5 Bxe4 23. Qxg7+ Kxg7 24. dxe4 Nxc2 25. Rad1 Nxe1 26. Rxe1 Rd8 27. f3 Re7 28. Ne3 Red7 29. Nf5+ Kf6 30. h4 Rd1 31. Kf2 Rxe1 32. Kxe1 Rd3 33. b4 Rb3 34. bxc5 bxc5 35. Nd6 c4 36. Nxc4 Rb4 37. Ne3 Rxa4 38. Nf5 Ra1+ 39. Kf2 Ra6 40. Kg3 Rb6 41. Kh3 Rb8 42. g4 Rh8 43. g5+ Ke6 44. Kg4 Kd7 45. h5 c5 46. Ng7 c4 47. Nf5 c3 48. Ne3 c2 49. Nxc2 Kc6 50. Ne3 Kc5 51. Nc4 Kd4 52. Nxe5 Kc3 0-1",
    B: "2231",
  },
  {
    W: "2067",
    T: "60+0",
    P: "1. Nf3 d5 2. g3 c5 3. Bg2 Nc6 4. O-O g6 5. d3 Bg7 6. Nc3 Nf6 7. e4 O-O 8. Nxd5 Nxd5 9. exd5 Qxd5 10. c3 Bg4 11. h3 Bf5 12. d4 cxd4 13. Nxd4 Qd7 14. Nxf5 Qxf5 15. Qb3 Rab8 16. Bf4 e5 17. Be3 a6 18. Bc5 Rfd8 19. Rad1 Qc8 20. Rxd8+ Qxd8 21. Rd1 Qe8 22. Bd6 Rd8 23. Qxb7 Rd7 1-0",
    B: "2039",
  },
  {
    W: "2139",
    T: "60+0",
    P: "1. Nf3 d5 2. d4 Nf6 3. e3 Bg4 4. c4 e6 5. cxd5 exd5 6. Qb3 c6 7. Qxb7 Nbd7 8. Qxc6 Be7 9. Nc3 Bxf3 10. gxf3 O-O 11. Bh3 Rc8 12. Qa6 Bb4 13. Bd2 Re8 14. Qxa7 Rc7 15. Qa4 Qb8 16. Bxd7 Nxd7 17. Qb3 Rb7 18. Qc2 Rc8 19. Ke2 Qa7 20. Qd3 Nb6 21. a3 Bd6 22. Rhg1 Nc4 23. Bc1 Nxb2 24. Bxb2 Rxb2+ 25. Kf1 Qd7 26. Nxd5 Qh3+ 27. Rg2 Bxh2 28. Ne7+ Kh8 29. Nxc8 Qxc8 30. Rd1 Rc2 31. Rxh2 g6 32. Qe4 Qc4+ 33. Kg2 Qe6 34. Rf1 Qxe4 35. fxe4 Rc3 36. Kf3 Rxa3 37. Rc1 Ra5 38. Rc7 Kg7 39. Rg2 Ra6 40. Rh2 Rf6+ 0-1",
    B: "2116",
  },
  {
    W: "1694",
    T: "60+0",
    P: "1. d4 Nf6 2. g3 g6 3. Bh3 Bg7 4. Bf4 O-O 5. Nc3 d6 6. Bxc8 Qxc8 7. Qd3 Nc6 8. Qe3 Re8 9. d5 Nb4 10. Qc1 Nbxd5 11. Nxd5 Nxd5 12. Bd2 Be5 13. c4 Nf6 14. f4 Bd4 15. e3 Bb6 16. b4 a5 17. c5 Ba7 18. bxa5 Bxc5 19. Nf3 b6 20. O-O bxa5 21. Kg2 Ne4 22. Be1 e5 23. fxe5 dxe5 24. Ng5 Qf5 25. Nxe4 Qxe4+ 26. Kg1 Qxe3+ 27. Bf2 Qxc1 28. Raxc1 Bxf2+ 29. Rxf2 Rac8 30. Rfc2 Re7 31. Kf2 f5 32. Ke3 e4 33. Kf4 h6 34. h4 Rf7 35. Re2 g5+ 36. Ke5 Re7+ 37. Kf6 Rd7 38. Kxf5 Rf7+ 39. Kxe4 Re8+ 40. Kd3 Rxe2 41. Kxe2 Re7+ 0-1",
    B: "1552",
  },
  {
    W: "1661",
    T: "300+0",
    P: "1. e4 d5 2. exd5 Nf6 3. Nc3 Nxd5 4. Nxd5 Qxd5 5. Nf3 Nc6 6. Be2 Bf5 7. O-O O-O-O 8. d3 e5 9. c4 Qe6 10. Ne1 e4 11. Qb3 Nd4 12. Qa4 Nxe2+ 13. Kh1 Kb8 14. dxe4 Bxe4 15. Be3 Nd4 16. f3 Bc6 17. Qa5 Qxe3 0-1",
    B: "1670",
  },
  {
    W: "1742",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Bc5 5. O-O d6 6. Bg5 h6 7. Bxf6 Qxf6 8. Nc3 Be6 9. Nd5 Bxd5 10. Bxd5 O-O 11. Bxc6 bxc6 12. b3 a5 13. h3 Qg6 14. Nh2 f5 15. exf5 Rxf5 16. Qe2 Raf8 17. Nf3 Rxf3 18. Qe4 Qxe4 0-1",
    B: "1724",
  },
  {
    W: "1753",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Be2 e5 4. O-O d6 5. c3 Be7 6. d4 cxd4 7. cxd4 f5 8. d5 Nb8 9. Bb5+ Bd7 10. Bxd7+ Nxd7 11. exf5 Ngf6 12. Nc3 O-O 13. Ng5 Qb6 14. Ne6 Rf7 15. Be3 Qa5 16. Rc1 b6 17. a3 Ng4 18. Bd2 Nh6 19. Qh5 Nxf5 20. Ng5 Bxg5 21. Qxg5 Raf8 22. Qg4 Qc5 23. Qe4 Qd4 24. Qe2 Qg4 25. Qxg4 1-0",
    B: "1748",
  },
  {
    W: "1527",
    T: "300+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. h4 c6 4. h5 Nf6 5. hxg6 fxg6 6. e5 Nd5 7. d4 O-O 8. Ng5 e6 9. Rxh7 Na6 10. Bd3 Ne7 11. Bxg6 Nxg6 12. Qh5 Qe8 13. Rxg7+ Kxg7 14. Qh7# 1-0",
    B: "1559",
  },
  {
    W: "1245",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. Nc3 c6 4. Bc4 Be6 5. Bxe6 fxe6 6. d3 h6 7. O-O Be7 8. h3 Nf6 9. Be3 O-O 10. Qe2 b5 11. a3 a5 12. d4 exd4 13. Nxd4 e5 14. Nf3 Nbd7 15. Rad1 a4 16. Qe1 Nh7 17. Ne2 Bh4 18. Nxh4 Qxh4 19. Ng3 Ndf6 20. Rxd6 Nxe4 21. Rxc6 Rad8 22. Qb4 Nxg3 23. Qxh4 1-0",
    B: "1248",
  },
  {
    W: "1896",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. c3 g6 4. d4 cxd4 5. cxd4 Bg7 6. Be3 d6 7. Nc3 Qb6 8. d5 Qxb2 9. Na4 Qxa1 10. Qxa1 Bxa1 11. dxc6 bxc6 12. Be2 Bg7 13. O-O Nf6 14. Nd2 O-O 15. f3 c5 16. Nc4 Ba6 17. Re1 Bxc4 18. Bxc4 Rab8 19. Nc3 Rb4 20. Bb3 c4 21. Bc2 Rfb8 22. Nd5 Nxd5 23. exd5 c3 24. Bxa7 R8b7 25. Bd4 Bxd4+ 0-1",
    B: "1797",
  },
  {
    W: "1575",
    T: "300+0",
    P: "1. d4 c6 2. Bf4 d6 3. e3 e6 4. Nf3 f5 5. Be2 h6 6. Nfd2 Qf6 7. Bh5+ g6 8. Be2 Nd7 9. Nc4 e5 10. dxe5 Nxe5 11. Nxe5 dxe5 12. Bg3 Bd7 13. h3 O-O-O 14. O-O g5 15. c3 Bd6 16. f4 exf4 17. exf4 gxf4 18. Bxf4 Bxf4 19. Rxf4 Ne7 20. Qf1 Rhg8 21. Nd2 Qg5 22. Nc4 Nd5 23. Rf2 f4 24. Rf3 Rde8 25. Re1 b5 26. Nd6+ Kc7 27. Nxe8+ Rxe8 28. c4 Ne3 29. Qf2 Rf8 30. cxb5 cxb5 31. Rc1+ Bc6 32. Bxb5 Qxb5 33. Rxe3 f3 34. Rxf3 Rg8 35. Qxa7+ Kd6 36. Rf6+ Ke5 37. Qe7+ Kd4 38. Rd6+ Bd5 39. Rd1+ Kc4 40. Qc7+ Kb4 41. a3+ Kb3 42. Qc3+ Ka2 43. R6xd5 Qe2 44. R5d2 Qe4 45. Qd3 Qxd3 46. Rxd3 h5 47. Re1 Rc8 48. a4 h4 49. a5 Ra8 50. b4 Kb2 0-1",
    B: "1571",
  },
  {
    W: "2061",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6 4. Bxc6 dxc6 5. O-O b5 6. c3 Bg7 7. d3 Nf6 8. Be3 Nd7 9. Nbd2 O-O 10. Nb3 Qb6 11. Rb1 a5 12. d4 c4 13. Nbd2 Bb7 14. e5 c5 15. b3 cxb3 16. Nxb3 c4 17. Nbd2 Rad8 18. Nxc4 Qa7 19. Ncd2 b4 20. cxb4 axb4 21. Rxb4 Ba6 22. Re1 Qc7 23. Qc1 Qa5 24. Qb2 Bd3 25. Nb3 Qd5 26. Rd1 Be4 27. Nbd2 f5 28. Nxe4 fxe4 29. Nd2 Rf5 30. Qb3 e6 31. Rb5 Qc6 32. Qb1 Bf8 33. Nxe4 Nxe5 34. Rb6 Qd5 35. Nc3 Nf3+ 36. gxf3 Qxf3 37. Rxe6 Bd6 38. Rxd6 Rxd6 39. Qb8+ Rf8 40. Qxd6 Qg4+ 41. Kf1 Qh3+ 42. Ke1 Qh5 43. Qd5+ Qxd5 44. Nxd5 Ra8 45. Ne7+ Kf7 46. Nc6 Rxa2 47. d5 Ra6 48. d6 Rxc6 49. d7 Re6 50. d8=Q Re8 51. Qd7+ Re7 52. Qd5+ Re6 53. Rc1 Kf6 54. Rc6 Kg7 55. Qxe6 Kf8 56. Rc7 g5 0-1",
    B: "2070",
  },
  {
    W: "1788",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 e6 3. Bc4 d5 4. exd5 exd5 5. Be2 Nf6 6. c3 Nc6 7. d4 c4 8. O-O Be7 9. b3 b5 10. Bd2 O-O 11. a4 bxa4 12. Rxa4 Ne4 13. Bf4 cxb3 14. Qxb3 Be6 15. Nbd2 Nxd2 16. Bxd2 Rb8 17. Qc2 Qd7 18. Bg5 Bxg5 19. Nxg5 h6 20. Qh7# 1-0",
    B: "1786",
  },
  {
    W: "1258",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. O-O Bc5 5. c3 Nf6 6. d4 exd4 7. cxd4 Bd6 8. d5 Ne5 9. Nbd2 O-O 10. Nxe5 Bxe5 11. d6 Bxd6 12. b3 b6 13. Bb2 Bb7 14. Bd3 Nh5 15. Nf3 Re8 16. e5 Bxe5 17. Nxe5 Qh4 18. Nxd7 Nf4 19. g3 Qh3 20. f3 Qg2# 0-1",
    B: "1275",
  },
  {
    W: "2172",
    T: "300+0",
    P: "1. d4 Nf6 2. Nf3 e6 3. Bf4 c5 4. e3 Nc6 5. c3 Qb6 6. Qb3 c4 7. Bxc4 d5 8. Qxb6 axb6 9. Bb5 Be7 10. Nbd2 O-O 11. O-O Rd8 12. Bc7 Rd7 13. Bxb6 h6 14. Ne5 Nxe5 15. dxe5 Ng4 16. Bxd7 Bxd7 17. Bd4 Bb5 18. h3 Bxf1 19. Nxf1 Nxe5 20. Bxe5 b5 21. Bd4 Kf8 22. f4 f6 23. Kf2 Bd6 24. Nd2 Ke7 25. Nf3 g5 26. fxg5 hxg5 27. g4 e5 28. Bb6 Ra6 29. Bd4 exd4 30. Nxd4 b4 31. Nf5+ Ke6 32. Nxd6 Kxd6 33. cxb4 Rc6 34. Kf3 Rc2 35. a4 Rh2 36. Kg3 Re2 37. a5 Rxe3+ 38. Kf2 Rb3 39. a6 Rxb2+ 40. Ke3 Rb3+ 41. Kd2 Rxb4 42. a7 1-0",
    B: "2180",
  },
  {
    W: "1951",
    T: "300+0",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. e3 c6 5. a3 Nbd7 6. f4 Nb6 7. c5 Nbd7 8. Qc2 b5 9. b4 a5 10. Rb1 a4 11. Nf3 Ng4 12. h3 Nh6 13. Bd3 g6 14. g4 Bg7 15. Kd1 O-O 16. Qg2 f6 17. Kc2 e5 18. fxe5 fxe5 19. Nxe5 Nxe5 20. dxe5 Bxe5 21. Bb2 Qf6 22. Nxd5 cxd5 23. Qxd5+ Nf7 24. Bxe5 Qxe5 25. Qxe5 Nxe5 26. Bxb5 Rf2+ 27. Kc3 Bd7 28. c6 Bxc6 29. Bxc6 Nxc6 30. b5 Na5 31. b6 Rb8 32. Rb5 Nb3 33. Kb4 Rb2 34. Kc3 Re2 35. Kb4 Rxe3 36. Kxa4 Ra8+ 37. Kb4 Nd4 38. b7 Rb8 39. Rc1 Rb3+ 40. Ka4 Rxb7 41. Rxb3 Rxb3 42. Rc4 Rd3 43. Rc8+ Kg7 44. Rd8 Rd2 45. Rd7+ Kh6 46. h4 g5 47. Rd6+ Kg7 48. hxg5 Nf3 49. Rxd2 Nxd2 50. Kb5 Ne4 51. a4 Nd6+ 52. Kc6 Nc8 53. Kb7 Nd6+ 54. Kc6 Nc4 55. Kb5 Na3+ 56. Kb4 Nc2+ 57. Kc5 Ne1 58. a5 Nd3+ 59. Kb6 Nb4 60. a6 Nxa6 61. Kxa6 Kg6 62. Kb5 Kxg5 63. Kc4 Kxg4 64. Kd3 Kf3 65. Kd2 Kf2 66. Kd3 h5 67. Ke4 h4 68. Kf4 h3 69. Kg4 h2 0-1",
    B: "2001",
  },
  {
    W: "1357",
    T: "300+0",
    P: "1. d4 d5 2. e3 Nf6 3. Nf3 Nc6 4. Bb5 Bd7 5. c4 a6 6. Ba4 Na5 7. Bxd7+ Qxd7 8. cxd5 Qxd5 9. O-O e6 10. Nc3 Qd8 11. Bd2 Bb4 12. Ne5 O-O 13. Qa4 Bxc3 14. Bxc3 Nc6 15. Nxc6 bxc6 16. Qxc6 h6 17. Qf3 Nd5 18. Bd2 Re8 19. e4 Nf6 20. Bc3 a5 21. e5 Nh7 22. Rad1 Ng5 23. Qe2 a4 24. f4 Nh7 25. Qe4 a3 26. b3 Qc8 27. d5 exd5 28. Rxd5 c6 29. Rdd1 c5 30. f5 f6 31. e6 Ng5 32. Qd5 Nxe6 33. fxe6 Rxe6 34. Bxf6 gxf6 35. Rxf6 Ra6 36. Rg6+ Kh7 37. Rxe6 Rxe6 38. Qd3+ Kg7 39. Qg3+ Rg6 40. Qc3+ Kh7 41. h3 Qc6 42. b4 cxb4 0-1",
    B: "1414",
  },
  {
    W: "2503",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 Nxd4 4. Nxd4 cxd4 5. Qxd4 e6 6. Nc3 a6 7. Be2 Qc7 8. O-O d6 9. Bf4 e5 10. Nd5 Qc6 11. Qb6 exf4 12. Qxc6+ bxc6 13. Nc7+ Kd8 14. Nxa8 Nf6 15. Nb6 Nxe4 16. Bf3 Nd2 17. Rfd1 Nxf3+ 18. gxf3 Be6 19. c4 Kc7 20. c5 dxc5 21. Na4 Bd5 22. Kg2 g5 23. Nc3 Be6 24. Ne4 Be7 25. Rac1 c4 26. Nd2 Bd5 27. Nxc4 g4 28. Ne5 gxf3+ 29. Kf1 Kd6 30. Nxf7+ Ke6 31. Nxh8 Bf6 32. Re1+ Kf5 33. Nf7 Bxf7 34. Rxc6 Bd4 35. Re7 Bd5 36. Rd6 Bc4+ 37. Kg1 Bc5 38. Rc6 Bxe7 39. Rxc4 Bf6 40. Rc5+ Ke4 41. Ra5 Kd3 42. Rxa6 Ke2 43. a4 Bd4 44. Re6+ Kd2 45. a5 Bxb2 46. a6 Bd4 47. Rd6 Kc3 48. Rxd4 Kxd4 49. a7 Ke4 50. a8=Q+ Kf5 51. Qxf3 Kg5 52. h3 Kf5 53. Qg4+ Ke5 54. Qg8 Ke4 55. Qxh7+ Ke5 56. Qg6 1-0",
    B: "2583",
  },
  {
    W: "1776",
    T: "300+0",
    P: "1. d4 f5 2. g3 Nf6 3. Bg2 d5 4. Nh3 e6 5. O-O Be7 6. Nf4 O-O 7. c3 c5 8. Re1 c4 9. Nd2 Nc6 10. Nf3 Bd7 11. Ne5 Nxe5 12. dxe5 Ne4 13. f3 Ng5 14. e4 fxe4 15. fxe4 Nxe4 16. Bxe4 dxe4 17. Rxe4 Bc5+ 18. Kf1 g5 19. Qg4 Kh8 20. Ke2 gxf4 21. Bxf4 Bc6 22. Rxc4 Qd5 0-1",
    B: "1769",
  },
  {
    W: "2511",
    T: "30+0",
    P: "1. e4 d5 2. d4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 Nc6 6. Bd3 Bg4 7. Be3 e5 8. dxe5 Nxe5 9. Be2 Qxd1+ 10. Rxd1 Nxf3+ 11. Bxf3 Bxf3 12. gxf3 Bb4 13. Kf2 Bxc3 14. bxc3 O-O 15. Rhg1 Rfe8 16. Bd4 Re6 17. f4 Rae8 18. f5 Rd6 19. c4 Ne4+ 20. Kf3 f6 21. Bc3 Nxc3 22. Rxd6 cxd6 23. Ra1 Ne4 24. Kf4 a6 25. Ke3 Nc5+ 26. Kd4 Nd7 27. Kd5 Ne5 28. Kxd6 Kf7 29. Kc7 Re7+ 30. Kb6 Ng4 31. c5 Ne3 32. c3 Nd5+ 33. Ka5 Ne3 34. c4 Nxf5 35. Kb4 Nd4 36. Rg1 Nc6+ 37. Kc3 Ne5 38. Rg2 Nf3 39. Rg3 Ne1 40. h4 Ng2 41. Rf3 Nf4 42. Rxf4 Re2 43. Rf3 Re6 44. Rd3 Re3 45. c6 Rxd3+ 46. Kxd3 bxc6 47. Kd4 1-0",
    B: "2445",
  },
  {
    W: "2055",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. Bg5 Bb4 5. e3 c5 6. Nf3 O-O 7. Be2 Nc6 8. O-O cxd4 9. exd4 Be7 10. c5 b6 11. cxb6 axb6 12. a3 Bb7 13. b4 Ne4 14. Nxe4 dxe4 15. Bxe7 Qxe7 16. Ne5 Nxe5 17. dxe5 e3 18. fxe3 Qg5 19. Bf3 Qxe3+ 20. Kh1 Bxf3 21. Qxf3 Qxe5 22. Rae1 Qg5 23. h3 h6 24. Re4 Qd5 25. Rf4 Qxf3 26. R4xf3 f6 27. Rc1 Rfd8 28. Rc6 Rd1+ 29. Kh2 b5 30. Rxe6 Rc8 31. Rb6 Rd5 32. Rb7 h5 33. Rg3 Rg5 34. Rxg5 fxg5 35. Rxb5 Rc3 36. Rxg5 Rxa3 37. Rxh5 Rb3 38. b5 g6 39. Rg5 Kg7 40. h4 Kh6 41. g3 Rb4 42. Rc5 Rb3 43. Kh3 Rb4 44. Kg2 Rb3 45. Kf2 Rb4 46. Kf3 Rb3+ 47. Kf4 Rb4+ 48. Ke5 Rg4 49. Rc3 Rb4 50. Rc5 Rb3 51. g4 Rb4 52. g5+ Kh5 53. Kf6 Rxh4 54. b6 Rf4+ 55. Kg7 Rb4 56. Rc6 Kxg5 57. Rxg6+ Kf5 58. Rh6 Ke5 59. Kf7 Kd5 60. Ke7 Kc5 61. Kd7 Rxb6 62. Rh5+ Kb4 63. Kc7 Rb8 64. Kc6 Rc8+ 65. Kb6 Rb8+ 66. Kc6 Rc8+ 67. Kb6 Rg8 68. Rh4+ Kc3 69. Kb5 Rg5+ 70. Kc6 Rg6+ 71. Kd5 Rg5+ 72. Ke4 Ra5 73. Ke3 Ra3 74. Rh3 Kb4+ 75. Ke4 Rxh3 0-1",
    B: "2084",
  },
  {
    W: "1959",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 exd4 7. O-O Nf6 8. e5 d5 9. exf6 dxc4 10. Re1+ Be6 11. fxg7 Rg8 12. Bg5 Qd5 13. Bf6 Qf5 14. Bxd4 Nxd4 15. Nxd4 Qg6 16. Qa4+ c6 17. Qxa5 Rxg7 18. Na3 Qxg2# 0-1",
    B: "1800",
  },
  {
    W: "1724",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 h5 5. Bg5 Nd4 6. Nxd4 exd4 7. c3 Bc5 8. O-O c6 9. Bc4 h4 10. h3 d6 11. Qb3 d5 12. exd5 cxd5 13. Re1+ Be6 14. Qb5+ Qd7 15. Qxc5 dxc4 16. Bxf6 gxf6 17. dxc4 Rg8 18. cxd4 Rc8 19. Qd5 Kf8 20. Qxd7 Bxd7 21. Kh2 Rxc4 22. d5 Rd4 23. Nc3 Rd2 24. Re2 Rxe2 25. Nxe2 Bf5 26. d6 Be4 27. f3 Bc6 28. Rd1 Ke8 29. Nd4 Kd7 30. Nf5 Rg5 31. Ne7 Rb5 32. Nxc6 bxc6 33. b3 Ra5 34. a4 Rc5 35. g4 Rc3 36. b4 Rxf3 37. Rd2 Rf4 38. b5 Rxa4 39. bxc6+ Kd8 40. d7 Rc4 41. Re2 Rxc6 42. Re8+ Kxd7 43. Re2 a5 44. Rd2+ Kc7 45. Ra2 Kb6 46. Rb2+ Ka6 47. Ra2 Rc4 48. Kg2 a4 49. Kf3 Kb5 50. Rb2+ Rb4 51. Ra2 Rb3+ 52. Kf4 Kb4 53. Kf5 Rxh3 54. Kxf6 Rf3+ 55. Kg5 h3 56. Kh4 a3 57. g5 Kb3 58. Ra1 a2 59. Kg4 Rc3 60. Kf5 h2 61. Kf6 Kb2 62. Rh1 Rc2 63. Kxf7 a1=Q 64. Rxa1 Kxa1 65. g6 Rg2 66. g7 h1=Q 67. g8=Q Rxg8 68. Kxg8 Qf3 0-1",
    B: "1735",
  },
  {
    W: "1085",
    T: "600+0",
    P: "1. e4 Nc6 2. d3 g6 3. Nf3 Bg7 4. Nc3 f5 5. Bg5 Bh6 6. Bxh6 Nxh6 7. Ng5 e6 8. d4 Qxg5 9. d5 exd5 10. exd5 Nb4 11. Nb5 f4 12. Nxc7+ Kd8 13. Nxa8 Nxd5 14. Qd4 Re8+ 15. Be2 Qxg2 16. O-O-O Qg5 17. Bc4 f3+ 18. Kb1 Nf6 19. Rhg1 Qf5 20. Rge1 Rxe1 21. Rxe1 d5 22. Bd3 Qg5 23. Re6 Bxe6 24. Qxa7 Qg1+ 25. Bf1 Qxf1# 0-1",
    B: "1177",
  },
  {
    W: "1443",
    T: "600+0",
    P: "1. d4 d5 2. Nf3 Bg4 3. h3 Bh5 4. Nc3 e6 5. g4 Bg6 6. Bf4 Bb4 7. Bg2 Bxc3+ 8. bxc3 Ne7 9. Rb1 b6 10. Ne5 Nd7 11. a4 Nxe5 12. Bxe5 Nc6 13. O-O h5 14. Bxg7 Rg8 15. Be5 hxg4 16. hxg4 f5 17. f3 fxg4 18. fxg4 Be4 19. Rf2 Qh4 20. e3 Rxg4 21. Qe2 Nxe5 22. Rbf1 O-O-O 0-1",
    B: "1445",
  },
  {
    W: "1316",
    T: "600+0",
    P: "1. d4 d5 2. c4 c6 3. Nf3 dxc4 4. Nc3 c5 5. e3 Nc6 6. Bxc4 cxd4 7. Nxd4 Nxd4 8. exd4 g6 9. O-O Qc7 10. b3 Nf6 11. Re1 Ng4 12. g3 Bh6 13. Bxh6 Nxh6 14. Nd5 Qd6 15. Rxe7+ Qxe7 16. Nxe7 Kxe7 17. Qe2+ Kd8 18. Re1 Bg4 19. Qe7+ Kc8 20. Qg5 Be6 21. Qxh6 Bxc4 22. bxc4 Kc7 23. Qf4+ Kb6 24. Qxf7 a6 25. Rb1+ Ka7 26. Qxb7# 1-0",
    B: "1254",
  },
  {
    W: "1368",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Qxd4 Nc6 5. Qd1 Nf6 6. Bb5 Qa5+ 7. Nc3 Bd7 8. Bxc6 Bxc6 9. O-O Nxe4 10. Nxe4 Bxe4 11. Be3 Rc8 12. c3 Bxf3 13. Qxf3 e6 14. Rfe1 b6 15. Bd4 Be7 16. Bxg7 Rg8 17. Bd4 e5 18. Be3 Rc4 19. h3 e4 20. Qf4 Kf8 21. Qh6+ Rg7 22. Bd4 Rxd4 23. cxd4 Qf5 24. Rac1 Bg5 25. Rc3 Bxh6 0-1",
    B: "1373",
  },
  {
    W: "1403",
    T: "600+0",
    P: "1. d4 d5 2. Nf3 f6 3. c4 Be6 4. e3 dxc4 5. Qc2 b5 6. Qe4 Qd5 7. Qxd5 Bxd5 8. Nc3 a6 9. Nxd5 Kd7 10. g4 e6 11. Nf4 Nc6 12. Bg2 Rd8 13. Bd2 Bb4 14. g5 Bxd2+ 15. Kxd2 fxg5 16. Nxg5 Nh6 17. Nfxe6 Rde8 18. Nc5+ Kd6 19. Rhe1 a5 20. e4 Nxd4 21. Nb7+ Ke5 22. Nxa5 Rd8 23. Kc3 Rd6 24. Nf3+ Kf4 25. Nxd4 Ng4 26. Nxb5 Ra6 27. Nxc4 c6 28. Nbd6 Ne5 29. Nxe5 Kxe5 30. Nf7+ Kf6 31. Nxh8 g6 32. e5+ Kg7 33. e6 Ra8 34. e7 Re8 35. Rad1 Kxh8 36. Rd8 Kg7 37. Rxe8 Kf7 38. Rd8 Kg7 1-0",
    B: "1427",
  },
  {
    W: "1496",
    T: "600+0",
    P: "1. e4 e5 2. Bc4 Nf6 3. d3 d6 4. Bg5 Bg4 5. Nf3 Nc6 6. c3 Na5 7. Bb3 Nxb3 8. axb3 Be7 9. Bxf6 Bxf6 10. h3 Bh5 11. O-O O-O 12. Nbd2 d5 13. Qc2 dxe4 14. dxe4 c5 15. Ra4 b5 16. Raa1 b4 17. c4 Qd6 18. Ne1 Rad8 19. Rd1 Qe6 20. Nef3 Be7 21. Rde1 Bg6 22. Re3 f5 23. Rd1 f4 24. Ree1 Qc6 25. Nxe5 Qf6 26. Ndf3 Bh5 27. Rxd8 Rxd8 28. Rd1 Bd6 29. Qd2 Bc7 30. Qc2 Rxd1+ 31. Qxd1 Bxe5 32. Qd5+ Kf8 33. Qxe5 Bxf3 34. gxf3 Qxe5 0-1",
    B: "1461",
  },
  {
    W: "1604",
    T: "600+0",
    P: "1. d4 d5 2. Bf4 Nc6 3. Nf3 Nf6 4. e3 e6 5. Nbd2 Bd6 6. Bg5 Bd7 7. c3 Qe7 8. Qb3 O-O 9. Bd3 Na5 10. Qc2 b5 11. b4 Nc4 12. O-O h6 13. Bh4 e5 14. dxe5 Nxe5 15. Nxe5 Bxe5 16. Nf3 Qd6 17. Nxe5 Qxe5 18. Bg3 Qg5 19. h4 Qg4 20. Be2 Qe4 21. Qxe4 Nxe4 22. Bxc7 Nxc3 23. Bf3 Rac8 24. Bd6 Rfe8 25. Bc5 a5 26. a3 a4 27. Rac1 Ne4 28. Rfd1 Nxc5 29. bxc5 Bc6 30. Bxd5 Bxd5 31. Rxd5 Re6 32. Rb1 Rb8 33. Rd6 Rxd6 34. cxd6 Kf8 35. e4 Ke8 36. e5 Kd7 37. Rd1 b4 38. axb4 Rxb4 39. Kh2 Rxh4+ 40. Kg1 Re4 41. Rd5 a3 42. Ra5 a2 43. Rxa2 Rxe5 44. Ra7+ Kxd6 45. Rxf7 g5 46. Rh7 Re6 47. Kh2 Ke5 48. f3 Kf5 49. Kh3 Kg6 50. Rh8 h5 51. g4 hxg4+ 52. fxg4 Re3+ 53. Kg2 Re2+ 54. Kg3 Re3+ 55. Kg2 Re4 56. Kg3 Re3+ 57. Kg2 1/2-1/2",
    B: "1609",
  },
  {
    W: "1669",
    T: "120+0",
    P: "1. e4 g6 2. d4 Bg7 3. Be3 e6 4. Bc4 Ne7 5. Nf3 c6 6. O-O d5 7. exd5 exd5 8. Bb3 Bg4 9. Nbd2 O-O 10. c3 h5 11. h3 Bf5 12. Ne5 Nd7 13. Ndf3 Nxe5 14. Nxe5 Qc7 15. f4 Be4 16. Bc2 f5 17. g4 Bxe5 18. fxe5 fxg4 19. hxg4 Rxf1+ 20. Kxf1 Rf8+ 21. Kg1 hxg4 22. Qxg4 Bxc2 23. Bh6 Rf7 24. Qe6 Bf5 0-1",
    B: "1676",
  },
  {
    W: "1981",
    T: "300+0",
    P: "1. f4 d5 2. Nf3 f6 3. g3 c6 4. Bg2 Bg4 5. O-O Qd7 6. d3 Na6 7. c3 Nc5 8. Na3 Ne6 9. Nc2 g5 10. h3 Bh5 11. g4 Bg6 12. f5 Nf4 13. Bxf4 gxf4 14. fxg6 hxg6 15. e4 e6 16. exd5 exd5 17. Qe2+ Be7 18. Rfe1 O-O-O 19. Ncd4 Re8 20. Qe6 Qxe6 21. Rxe6 Kd7 22. Rae1 Nh6 23. c4 Bc5 24. cxd5 cxd5 25. Rxe8 Rxe8 26. Rxe8 Kxe8 27. Kf1 Kd7 28. Nb3 Be3 29. Ne1 d4 30. Nc2 f5 31. g5 Nf7 32. h4 Ne5 33. Nbxd4 Nxd3 34. Nxe3 fxe3 35. Ke2 Nf4+ 36. Kf3 Nxg2 37. Kxg2 Kd6 38. Kf3 f4 39. Ne2 Ke5 40. Nxf4 Kd4 41. h5 gxh5 42. g6 h4 43. g7 h3 44. g8=Q h2 45. Qd5# 1-0",
    B: "1935",
  },
  {
    W: "1901",
    T: "60+0",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Nf3 dxc4 5. e4 Nbd7 6. Bxc4 Be7 7. O-O O-O 8. Re1 a6 9. h3 b5 10. Bb3 c5 11. dxc5 Nxc5 12. Bc2 Bb7 13. e5 Nd5 14. Nxd5 Bxd5 15. a3 Rc8 16. b4 Na4 17. Bxa4 bxa4 18. Qxa4 h6 19. Nd2 Bg5 20. Qd1 Bxd2 21. Bxd2 Qh4 22. Qg4 Qxg4 23. hxg4 Rc2 24. Be3 Rfc8 25. Bc5 Rc3 26. Rec1 Rxc1+ 27. Rxc1 g6 28. f4 Kg7 29. Rf1 g5 30. f5 Kh7 31. fxe6 Bxe6 32. Rf6 Kg7 33. Kf2 Rd8 34. Ke3 Rd2 35. Kxd2 Kh7 36. Ke3 h5 37. Kd4 hxg4 38. Rf2 g3 39. Rf3 g4 40. Rxg3 Kg6 41. Be7 Kh5 42. Re3 Kg6 43. Re1 f6 44. Rh1 Kf7 45. exf6 1-0",
    B: "1706",
  },
  {
    W: "1844",
    T: "60+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. d4 d6 4. Nc3 Nf6 5. Bd3 O-O 6. Be3 Nc6 7. Qd2 Re8 8. Bh6 e5 9. Bxg7 Kxg7 10. d5 Ne7 11. h4 h5 12. Ng5 Nh7 13. f3 c6 14. Bc4 cxd5 15. Nxd5 Nxd5 16. Bxd5 Nxg5 17. hxg5 Be6 18. O-O-O Qb6 19. g3 Rac8 20. g4 Rc5 21. Bxe6 fxe6 22. gxh5 Rec8 23. c3 Qa5 24. Kb1 Qa4 25. hxg6 Ra5 26. a3 Qb3 27. Qh2 Kxg6 28. Qh6+ Kf7 29. Qf6+ Ke8 30. Rh8+ Kd7 31. Rxc8 Kxc8 32. Qe7 Rxa3 33. Qe8+ Kc7 34. Qe7+ Kb6 35. Qd8+ 1-0",
    B: "1859",
  },
  {
    W: "2025",
    T: "60+0",
    P: "1. d4 e5 2. Nf3 exd4 3. Nxd4 Nf6 4. Nb3 Bb4+ 5. Nc3 Bxc3+ 6. bxc3 h6 7. Bd2 O-O 8. Bc1 Qe7 9. e3 Re8 10. Bc4 c6 11. Bb2 d5 12. Bd3 Nbd7 13. O-O Ne5 14. Re1 a5 15. e4 a4 16. Nd4 Nc4 17. exd5 Qxe1+ 18. Qxe1 Rxe1+ 19. Rxe1 Nxb2 20. Re7 Nxd3 21. cxd3 cxd5 22. h3 Bd7 23. c4 dxc4 24. dxc4 Bc6 25. c5 Re8 26. Rc7 Kf8 27. Nf5 Nd5 28. Rxc6 bxc6 29. Nd6 Rb8 30. Kh2 Rb2 31. a3 Rb3 32. Nc4 Rc3 33. Na5 Rxa3 34. Nxc6 Rc3 35. Nd4 a3 36. Nb5 Rb3 37. Nxa3 Rxa3 38. c6 Rc3 39. f3 Rxc6 0-1",
    B: "2036",
  },
  {
    W: "1829",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nxd4 5. Qxd4 d5 6. e5 c5 7. Qf4 g5 8. Qxg5 Qd7 9. Nc3 d4 10. Bb5 1-0",
    B: "1828",
  },
  {
    W: "896",
    T: "60+0",
    P: "1. Nf3 Nf6 2. g3 d5 3. Bh3 Nc6 4. O-O e5 5. e4 Bc5 6. exd5 Nxd5 7. Qe2 O-O 8. Nxe5 Nxe5 9. Qxe5 f6 10. Qxd5+ Qxd5 11. Rd1 Bxh3 12. Nc3 Qg2# 0-1",
    B: "981",
  },
  {
    W: "1659",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3 Nxe4 5. Nxe4 d5 6. Bxd5 Qxd5 7. d3 Bg4 8. O-O Be7 9. h3 Bh5 10. g4 Bg6 11. Nc3 Qd7 12. Re1 f6 13. g5 Qxh3 14. gxf6 gxf6 15. Nh2 O-O 16. Be3 Kh8 17. Nb1 Rg8 18. Kh1 Bd6 19. Nc3 e4 20. Bg5 Qxh2# 0-1",
    B: "1669",
  },
  {
    W: "1925",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d4 Nc6 5. Bf4 Nf6 6. Nb5 e5 7. dxe5 Qxd1+ 8. Rxd1 Nh5 9. Nxc7+ Ke7 10. Bd2 Rb8 11. Be2 Nxe5 12. Bxh5 g6 13. Bf3 Nc4 14. Bb4+ Kf6 15. Bxf8 Rxf8 16. Nd5+ Kg7 17. Ne7 Bf5 18. Nd5 Rfe8+ 19. Kf1 Rbd8 20. b3 Ne5 21. Ne2 Nxf3 22. gxf3 Bxc2 23. Rd2 Bf5 24. Kg2 Re5 25. Nec3 Be6 26. f4 Rexd5 27. Nxd5 Rxd5 28. Rxd5 Bxd5+ 29. f3 Kf6 30. Rd1 Be6 31. Rd4 Kf5 32. Kg3 a6 33. Kf2 f6 34. Ke3 Bf7 35. Rb4 Bd5 36. a4 Bc6 37. Rd4 a5 38. Rd6 g5 39. fxg5 Kxg5 40. Rd4 Kg6 41. h4 Kf7 42. Rg4 Ke6 43. Rf4 Ke7 44. Kd4 Kf7 45. Kc5 Ke6 46. Rd4 Ke5 47. Rd6 Kf4 48. Rxc6 Kxf3 49. Rxf6+ Kg4 50. Rf7 Kxh4 51. Rxh7+ Kg5 52. Rxb7 Kf6 53. Rb5 1-0",
    B: "1885",
  },
  {
    W: "1934",
    T: "60+0",
    P: "1. d4 g6 2. e3 Bg7 3. Bd3 d6 4. b3 Nc6 5. Bb2 e5 6. Qd2 exd4 7. e4 Nge7 8. Ne2 Bg4 9. f3 Bd7 10. O-O O-O 11. a3 Qc8 12. b4 d5 13. b5 Nd8 14. a4 dxe4 15. fxe4 c6 16. Ba3 Re8 17. Bxe7 Rxe7 18. bxc6 Bxc6 19. Na3 Ne6 20. Nc4 Qb8 21. Ng3 Kh8 22. Rae1 Re8 23. e5 Bd5 24. Rf2 Qc7 25. Nb2 Rec8 26. Nd1 Qxc2 27. Bxc2 Nf4 28. Bd3 Nxd3 29. Qxd3 Re8 30. Qxd4 Rad8 31. Rd2 Bxg2 32. Qxd8 Rxd8 33. Rxd8+ Bf8 34. Kxg2 Kg7 35. Rxf8 Kxf8 36. Ne4 Ke7 37. Nf6 Ke6 38. Nc3 h6 39. h3 h5 40. h4 b6 41. Re3 a6 42. Rd3 a5 43. Nb5 Kf5 44. Rd6 Kxe5 45. Rxb6 Kf5 46. Ne8 Kg4 47. Ra6 Kxh4 48. Rxa5 g5 49. Nbd6 g4 50. Rb5 f5 51. a5 Kg5 52. Rxf5+ Kg6 53. a6 h4 54. a7 h3+ 55. Kg3 h2 56. Kxh2 g3+ 57. Kxg3 1-0",
    B: "1941",
  },
  {
    W: "1407",
    T: "60+0",
    P: "1. Nf3 Nc6 2. e3 e5 3. d4 e4 4. Ne5 Nxe5 5. dxe5 d6 6. Nc3 dxe5 7. Nxe4 Bf5 8. Nc3 Qxd1+ 9. Nxd1 Bxc2 10. Bd3 Bxd3 11. Nc3 e4 12. Bd2 O-O-O 13. Rd1 Bb4 14. a3 Bxc3 15. bxc3 Nf6 16. Bc1 Nd5 17. Rxd3 exd3 18. O-O Nxc3 19. Bd2 Ne2+ 20. Kh1 Rhe8 21. f3 f5 22. e4 fxe4 23. fxe4 Rf8 24. Rxf8 Rxf8 25. h3 Rf1+ 26. Kh2 Rd1 27. Be3 Ra1 28. a4 Rxa4 29. g4 Rxe4 30. h4 Rxe3 31. g5 a6 32. Kg2 d2 33. Kf2 d1=Q 34. Kxe3 Nc1 35. Ke4 a5 36. Kf5 Qd6 37. Kg4 a4 38. Kh5 a3 39. Kg4 a2 40. Kh5 a1=Q 41. Kg4 Qa5 42. Kh5 Qdd5 43. Kg4 Ne2 44. Kh5 Nc3 45. Kg4 1-0",
    B: "1338",
  },
  {
    W: "2119",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. Nf3 Nf6 5. d4 e6 6. Bd3 Be7 7. O-O O-O 8. Qe2 c5 9. dxc5 Bxc5 10. Bg5 h6 11. Bh4 Be7 12. Rad1 Qc7 13. Bg3 Qb6 14. Ne5 a6 15. Nc4 Qb4 16. a3 Qc5 17. b4 Qg5 18. h4 Qg4 19. Ne5 Qxe2 20. Nxe2 Nc6 21. Nxc6 bxc6 22. Nd4 c5 23. Nc6 Bd8 24. bxc5 Bb7 25. Na5 Bxa5 26. Rb1 Bc6 27. Rfd1 Rfd8 28. Bd6 Ne8 29. Bg3 f6 30. Bf4 e5 31. Be3 Rab8 32. Bc4+ Kh8 33. Rbc1 Rxd1+ 34. Rxd1 Rd8 35. Rb1 Nc7 0-1",
    B: "2043",
  },
  {
    W: "1798",
    T: "60+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. Nf3 e6 5. Nc3 d6 6. d4 Nc6 7. d5 Nxe5 8. Nxe5 dxe5 9. Qh5 Qf6 10. Be2 g6 11. Qg4 exd5 12. Qg3 Bg7 13. Nxd5 Nxd5 14. cxd5 O-O 15. O-O Bf5 16. Bg5 Qd6 17. Bc4 c6 18. b3 cxd5 19. Rad1 Be6 20. Rfe1 Qb6 21. Bd3 e4 22. Bc2 Rac8 23. Bb1 Rc3 24. Qf4 Bf5 25. h3 d4 26. Bxe4 Bxe4 27. Qxe4 Rfc8 28. Bd2 Rc1 29. Rxc1 Rxc1 30. Bxc1 d3 31. Bd2 h6 32. Qxd3 Kh7 33. Re7 Qf6 34. Rxb7 Qa1+ 35. Kh2 Qe5+ 36. g3 Qa1 37. Be3 Qf6 38. Rxa7 Qa1 39. Ra6 Qe1 0-1",
    B: "1780",
  },
  {
    W: "1641",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nxd4 5. Qxd4 c5 6. Qa4 Be7 7. Bb5 Nf6 8. O-O O-O 9. Rd1 a6 10. Bg5 axb5 11. Qxa8 Nxe4 12. Bxe7 Qxe7 13. Qa3 Qh4 14. Qg3 Nxg3 0-1",
    B: "1619",
  },
  {
    W: "1444",
    T: "420+0",
    P: "1. Nc3 Nf6 2. d4 d5 3. e4 e6 4. exd5 exd5 5. Bg5 Be7 6. Bxf6 Bxf6 7. Nf3 O-O 8. Bd3 c5 9. dxc5 Bxc3+ 10. bxc3 Qa5 11. O-O Qxc3 12. Re1 Qxc5 13. Re5 b6 14. Rxd5 Qe7 15. Ng5 Bb7 16. Bxh7+ Kh8 17. Rd3 Qxg5 18. Rh3 Qxg2# 0-1",
    B: "1394",
  },
  {
    W: "1611",
    T: "15+0",
    P: "1. d4 c6 2. Bf4 d6 3. e3 Nf6 4. Nf3 g5 5. Bg3 g4 6. Nfd2 h5 7. Nc3 Qb6 8. Bf4 Qxb2 9. Na4 Qa3 10. c3 b5 11. Nb2 Qxb2 12. Rb1 Qxc3 13. Be2 Ne4 14. O-O Qxd2 15. Qxd2 Nxd2 16. Rfd1 Nxb1 17. Rxb1 e5 18. Bg5 f6 19. dxe5 fxg5 20. exd6 Bxd6 21. e4 Be7 22. e5 Be6 23. Rd1 Nd7 24. Bxb5 Nxe5 25. Rd8+ 1-0",
    B: "1695",
  },
  {
    W: "2113",
    T: "900+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Be7 4. h3 Nf6 5. d3 c6 6. O-O h6 7. a4 O-O 8. Nc3 Kh7 9. Ne2 Nh5 10. Kh2 g5 11. g4 Nf4 12. Nxf4 gxf4 13. d4 f6 14. Nh4 a5 15. c3 Na6 16. Bd2 Nc7 17. f3 Bd7 18. Rg1 b5 19. axb5 cxb5 20. Bd3 Ne8 21. Nf5 Ng7 22. Qe2 Ne6 23. Bxb5 Bxb5 24. Qxb5 Rb8 25. Qxa5 Rxb2 26. Rg2 Ng5 27. Nh4 Qb8 28. Qa7 Qd8 29. d5 Rf7 30. Rf2 Bf8 31. Qa8 Qb6 32. Raf1 Qb5 33. Kg2 Qd3 34. Be1 Rb1 35. Qc8 Qe3 36. Qf5+ Kg7 37. Bd2 Qd3 38. Rxb1 Qxb1 39. Qg6+ Kh8 40. Nf5 Nxe4 41. Nxh6 Rg7 42. Qxe4 1-0",
    B: "1985",
  },
  {
    W: "1794",
    T: "60+0",
    P: "1. c4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. e4 Nf6 6. Nge2 O-O 7. O-O d6 8. d4 Ne8 9. d5 Nd4 10. Nxd4 Bxd4 11. Ne2 Bg7 12. Bg5 h6 13. Be3 Nf6 14. Rb1 Ng4 15. Bc1 Qa5 16. Bd2 Qxa2 17. b3 Bd7 18. Nf4 b5 19. Re1 bxc4 20. bxc4 Qxc4 21. Rc1 Qa6 22. e5 Bxe5 23. Nd3 Bd4 24. Be3 Nxe3 25. fxe3 Bxe3+ 26. Rxe3 Bf5 27. Nf4 Qa4 28. Re1 Qxd1 29. Rexd1 a5 30. Ra1 a4 31. Rd2 Rfb8 32. Rda2 Rb4 33. Bf1 Be4 34. Nd3 Rd4 35. Nf4 Bxd5 36. Nxd5 Rxd5 37. Bc4 Rd4 38. Rxa4 Rxa4 39. Rxa4 Kg7 40. Kf2 Rd2+ 41. Ke3 Rxh2 42. Bd3 Kf6 43. Be4 Rh3 44. Bf3 Rxg3 45. Rc4 Rg5 46. Rf4+ Kg7 47. Re4 Re5 48. Rxe5 dxe5 49. Ke4 f6 50. Be2 e6 51. Bc4 Kf7 0-1",
    B: "1959",
  },
  {
    W: "1955",
    T: "30+0",
    P: "1. g3 e5 2. Bg2 Bc5 3. Nf3 Qf6 4. O-O Qb6 5. d4 Bb4 6. dxe5 Qg6 7. c3 Be7 8. Bf4 h5 9. Re1 h4 10. Nxh4 Bxh4 11. gxh4 Rxh4 12. Bg3 Rh8 13. e4 d6 14. Qf3 dxe5 15. Bxe5 Bg4 16. Bg3 Bxf3 17. Bxf3 Qh7 18. Bg2 Nf6 19. e5 Ne4 20. Bxe4 g6 21. Bg2 f5 22. e6 f4 23. Bxf4 g5 24. Bg3 Rg8 25. Bxb7 Nc6 26. Bxa8 Ne7 27. Bg2 Nf5 28. Bxc7 Rh8 29. Bg3 Nxg3 30. fxg3 Qxh2+ 31. Kf2 g4 32. Rg1 Rf8+ 33. Ke3 Qh5 34. Kd4 Qf5 35. Kc4 Qxe6+ 36. Kb4 Qb6+ 37. Ka3 a5 38. b3 Rf4 39. Kb2 Rb4 40. Nd2 Rxb3+ 41. axb3 a4 42. Rae1+ Kd7 43. Re8 Kc7 44. Rge1 1-0",
    B: "1872",
  },
  {
    W: "1878",
    T: "180+0",
    P: "1. d4 d5 2. c4 e6 3. cxd5 exd5 4. Nc3 c6 5. Nf3 Bd6 6. Bg5 Ne7 7. e3 f6 8. Bh4 Bf5 9. Bg3 O-O 10. Bd3 Qd7 11. e4 dxe4 12. Nxe4 Bxg3 13. hxg3 Na6 14. Nc3 Nb4 15. Bxf5 Nxf5 16. a3 Nd5 17. O-O Rfe8 18. Qd2 Re7 19. Rae1 Rae8 20. Rxe7 Rxe7 21. Ne1 Nb6 22. Nc2 Nc4 23. Qc1 Nxd4 24. Rd1 c5 25. Nb1 Ne2+ 26. Kh2 Nxc1 27. Rxd7 Rxd7 28. Nc3 Nxb2 29. Ne3 Rd3 30. Nb5 a6 31. Nc7 Rxa3 0-1",
    B: "1966",
  },
  {
    W: "1687",
    T: "60+0",
    P: "1. g3 e5 2. b3 d5 3. Bb2 Bd6 4. Bg2 f6 5. Qc1 Bf5 6. c4 Nd7 7. Bxd5 c6 8. Bg2 Nb6 9. d4 c5 10. dxc5 Bxc5 11. a3 Qe7 12. b4 Bd4 13. Nc3 Nh6 14. e3 Bxc3+ 15. Qxc3 Nd7 16. f4 exf4 17. gxf4 Ng4 18. Bxb7 Nxe3 19. Bxa8 Nd5+ 20. Qe5 Nxe5 21. fxe5 fxe5 22. Nf3 Nf4 23. Nxe5 Nd3+ 24. Kd2 Nxb2 25. Nc6 Nxc4+ 26. Kc3 Qf6+ 27. Kxc4 O-O 28. Rhe1 Qf7+ 29. Kb5 Qc7 30. Ne7+ Kh8 31. Nxf5 Rxf5+ 32. Ka4 Qd7+ 33. Kb3 h5 34. Re8+ 1-0",
    B: "1690",
  },
  {
    W: "1784",
    T: "60+0",
    P: "1. e4 c6 2. d3 d5 3. exd5 cxd5 4. d4 Nf6 5. Be3 Nc6 6. Bd3 Bg4 7. Be2 Bxe2 8. Qxe2 e6 9. Nf3 Bb4+ 10. c3 Ba5 11. Nbd2 O-O 12. b4 Bc7 13. O-O Rc8 14. a4 Qd6 15. a5 Ne7 16. Nb1 Nf5 17. Ra2 Ne4 18. Rc2 Nxe3 19. Qxe3 Bb8 20. Ne5 f6 21. Nd2 fxe5 22. Nxe4 dxe4 23. Qxe4 exd4 24. cxd4 Qxh2# 0-1",
    B: "1830",
  },
  {
    W: "1032",
    T: "60+0",
    P: "1. e4 d6 2. Nf3 Qd7 3. d4 Qc6 4. d5 Qc5 5. Be3 Qc4 6. Bxc4 b5 7. Bxb5+ c6 8. dxc6 Nxc6 9. Bxc6+ Bd7 10. Bxa8 a5 11. Qd2 Bg4 12. Qxa5 e6 13. Bc6+ Ke7 14. Qc7+ Kf6 15. Bg5+ Kg6 16. Be8 h5 17. Qxf7+ Kh7 18. Qxf8 Nf6 19. Qf7 1-0",
    B: "854",
  },
  {
    W: "1308",
    T: "60+0",
    P: "1. d4 c5 2. c4 cxd4 3. Qxd4 Nc6 4. Qd3 e6 5. Nf3 Bc5 6. b3 Nb4 7. Qc3 Qa5 8. Bd2 Ne7 9. Qb2 Qb6 10. a3 Bxf2+ 11. Kd1 Nbc6 12. Nc3 Nd4 13. Ne5 O-O 14. Na4 Qd6 15. c5 Qd5 16. Nd3 Bh4 17. Nc3 Qf5 18. b4 Qf2 19. Ne4 Qe1+ 20. Nxe1 Bxe1 21. Kxe1 Nef5 22. Bc3 Nf3+ 23. Kd1 Nd2 24. Kxd2 b6 25. Bxg7 Bb7 26. Bc3 e5 27. Bd4 1-0",
    B: "1350",
  },
  {
    W: "1675",
    T: "60+0",
    P: "1. e4 e5 2. f3 Qe7 3. d3 Nf6 4. Bg5 d6 5. Ne2 Be6 6. Ng3 c6 7. Nf5 Bxf5 8. exf5 Nbd7 9. g4 h6 10. Bxf6 Nxf6 11. Bh3 d5 12. Nc3 O-O-O 13. Qd2 e4 14. fxe4 dxe4 15. Nxe4 Nxe4 16. dxe4 Rxd2 17. Kxd2 0-1",
    B: "1649",
  },
  {
    W: "1627",
    T: "60+0",
    P: "1. e4 d6 2. Nf3 Nf6 3. Nc3 g6 4. Bc4 Bg7 5. Bb3 Be6 6. d3 Bxb3 7. O-O O-O 8. axb3 e5 9. h3 Nfd7 10. Nh2 f6 11. f4 Nc6 12. f5 g5 13. Ng4 h5 14. Nh2 h4 15. Qh5 Qe8 16. Qxe8 Rfxe8 17. Be3 Nd4 18. Bxd4 exd4 19. Nd5 c6 20. Nc7 Rec8 21. Nxa8 Rxa8 22. Ra4 c5 23. Rfa1 b6 24. Rxa7 Re8 25. Rxd7 c4 26. bxc4 b5 27. Raa7 bxc4 28. Rxg7+ Kf8 29. Raf7# 1-0",
    B: "1628",
  },
  {
    W: "1866",
    T: "60+0",
    P: "1. d4 d5 2. c4 Nf6 3. cxd5 Nxd5 4. Nc3 Nxc3 5. bxc3 Bg4 6. Qb3 b6 7. Qc4 Nd7 8. d5 e5 9. Qxg4 Be7 10. Nf3 O-O 11. Qe4 f5 12. Qc2 Nc5 13. c4 Bf6 14. Bb2 e4 15. Bxf6 Qxf6 16. Nd2 Qxa1+ 17. Qc1 Qxc1# 0-1",
    B: "1946",
  },
  {
    W: "1544",
    T: "60+0",
    P: "1. Nf3 Nc6 2. g3 e5 3. Bg2 e4 4. Nh4 d5 5. O-O Be7 6. d3 Bxh4 7. gxh4 Qxh4 8. dxe4 Nf6 9. exd5 Ng4 10. h3 Nf6 11. Nc3 Bxh3 12. Bxh3 Qxh3 13. Bf4 Ng4 14. f3 Ne7 15. Qd4 O-O-O 16. e4 Ng6 17. Qd2 Nxf4 18. Qxf4 Nf6 19. Qh2 Qd7 20. Qe5 h5 21. Kf2 h4 22. Ke3 h3 23. Rh1 h2 24. Raf1 Rde8 25. Rf2 Rxe5 26. Rfxh2 Rxe4+ 27. fxe4 Rxh2 28. Rxh2 b6 29. Rh8+ Kb7 30. Rh4 Ng4+ 31. Rxg4 Qxg4 32. Kd4 Qg6 33. a3 f6 34. b3 1-0",
    B: "1558",
  },
  {
    W: "1690",
    T: "60+0",
    P: "1. d4 d5 2. c4 Nf6 3. cxd5 Nxd5 4. e4 Nf6 5. Nc3 e6 6. Nf3 Bb4 7. Bd3 Bxc3+ 8. bxc3 Nc6 9. Ba3 Nd7 10. O-O Nb6 11. Qc2 Bd7 12. Rab1 Ne7 13. Ne5 O-O 14. f4 f6 15. Nf3 e5 16. fxe5 fxe5 17. Nxe5 Rxf1+ 18. Rxf1 Nc6 19. Qf2 Qf6 20. Qxf6 gxf6 21. Nxc6 Bxc6 22. Rxf6 Nd7 23. Rf2 Nf8 24. d5 Bd7 25. e5 Ng6 26. e6 Bxe6 27. dxe6 Re8 28. Bf5 Nf8 29. e7 Ng6 30. Bxg6 hxg6 31. Rf3 Kg7 32. h4 c6 33. g4 b6 34. Kg2 a6 35. Kg3 b5 36. h5 c5 37. hxg6 Kxg6 38. Rf5 b4 39. Kf4 bxa3 0-1",
    B: "1703",
  },
  {
    W: "1711",
    T: "60+0",
    P: "1. e4 e5 2. d4 d5 3. c3 c5 4. dxc5 Bxc5 5. exd5 Qb6 6. Be3 Bxe3 7. fxe3 Qxb2 8. Be2 Qxa1 9. Nf3 Qxa2 10. O-O Nf6 11. Nxe5 Nxd5 12. Nxf7 O-O 13. Nd2 Nf6 14. Bc4 Qa5 15. Nh6+ Kh8 16. Nf7+ Rxf7 17. Bxf7 Qxc3 18. Ne4 Qxe3+ 19. Kh1 h6 20. Bg6 Nbd7 21. Nxf6 gxf6 22. Qa1 Kg7 23. Bh5 Qe5 24. Qd1 Nc5 25. Qd8 Ne4 26. Qc7+ 1-0",
    B: "1732",
  },
  {
    W: "1087",
    T: "60+0",
    P: "1. b3 d5 2. Bb2 Nc6 3. d4 f5 4. f4 Nf6 5. Qd2 Ne4 6. Qe3 Nb4 7. Bc3 Nxc2+ 8. Kd1 Nxe3+ 0-1",
    B: "1035",
  },
  {
    W: "1760",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nf6 5. Nc3 Bc5 6. Be3 Nxd4 7. Bxd4 Bb4 8. Qf3 O-O 9. a3 Bxc3+ 10. Bxc3 Nxe4 11. Qxe4 Re8 12. Be5 d6 13. f4 dxe5 14. fxe5 Rb8 15. Bc4 Qg5 16. O-O Be6 17. Bd3 Qh6 18. Rf3 c6 19. Rg3 Bd5 20. Qf5 g6 21. Rh3 Qe3+ 22. Rxe3 gxf5 23. Bxf5 Kh8 24. Rae1 Rg8 25. g3 Kg7 26. e6 fxe6 27. Bxe6 Bxe6 28. R3e2 Bd5 29. Re7+ Bf7 30. Rc7 Rge8 31. Ree7 Rf8 32. Rxb7 Rxb7 33. Rxb7 Ra8 34. Rc7 Rb8 35. b3 Rb6 36. Rxa7 Kf6 37. a4 Bg6 38. a5 Rb5 39. c4 Rxb3 40. a6 Bd3 41. Ra8 Bxc4 42. a7 Bf7 43. Rf8 Rb1+ 44. Kg2 Rb2+ 45. Kh3 Ra2 46. a8=Q Rxa8 47. Rxa8 Bd5 48. Ra7 Be4 49. Rc7 Ke5 50. Kg4 Kd4 51. Kf4 c5 52. Rd7+ Bd5 53. Rxh7 c4 54. g4 c3 55. Rc7 Bc4 56. Rd7+ Bd5 57. Rc7 Kd3 0-1",
    B: "1772",
  },
  {
    W: "1483",
    T: "180+2",
    P: "1. d4 Nf6 2. Bf4 d6 3. Nf3 Nc6 4. e3 Bg4 5. h3 Bxf3 6. Qxf3 e5 7. Bh2 Be7 8. c3 O-O 9. Bd3 exd4 10. cxd4 Nb4 11. Be2 Nc2+ 12. Kd2 Nxa1 13. Nc3 c5 14. Rxa1 cxd4 15. exd4 d5 16. Be5 Ne4+ 17. Nxe4 dxe4 18. Qxe4 Bg5+ 19. f4 Qa5+ 20. Ke3 Bh6 21. a3 Rac8 22. Bd3 b6 23. Qxh7# 1-0",
    B: "1418",
  },
  {
    W: "1405",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. d3 Bd7 5. c3 Nf6 6. O-O g6 7. Nbd2 Bg7 8. Re1 O-O 9. Nf1 a6 10. Ba4 b5 11. Bc2 Rb8 12. Ng3 Ne7 13. Bg5 c5 14. Bxf6 Bxf6 15. d4 Nc6 16. d5 Ne7 17. Qd2 Kg7 18. Ng5 Ng8 19. Nf3 Be7 20. b3 c4 21. b4 a5 22. a3 axb4 23. axb4 f6 24. Nh4 f5 25. exf5 Bxh4 26. fxg6 hxg6 27. Ne4 Nf6 28. g3 Nxe4 29. Bxe4 Bg5 30. Qe2 Rf7 31. h4 Be7 32. h5 gxh5 33. Qxh5 Qh8 34. Qg6+ Kf8 35. Ra7 Bf5 36. Bxf5 Rxf5 37. Qxf5+ Ke8 38. Qd7+ 1-0",
    B: "1386",
  },
  {
    W: "1358",
    T: "180+2",
    P: "1. Nf3 c5 2. c4 d6 3. d4 Nd7 4. d5 Ngf6 5. Bf4 Nb6 6. e3 e6 7. dxe6 Bxe6 8. b3 Be7 9. Nbd2 O-O 10. Qc2 d5 11. Bg5 h6 12. Bxf6 Bxf6 13. Bd3 Bxa1 14. Bh7+ Kh8 15. O-O Bf6 16. e4 g6 17. Bxg6 fxg6 18. e5 Bg7 19. Qc3 Rf4 20. g3 Rf5 21. Nh4 Rxe5 22. Nxg6+ Kh7 23. Nxe5 Qg5 24. Qd3+ Bf5 25. Qe2 Re8 26. Ndf3 Qf6 27. Re1 d4 28. Nh4 d3 29. Qe3 Be6 30. Nxd3 Nd7 31. Qe4+ Kg8 32. Qxb7 Rf8 33. Qxa7 Qd4 34. Rxe6 Qxd3 35. Ng6 Rf7 36. Ne7+ Kh7 37. Qa3 Qf5 38. Nxf5 Rxf5 39. Re7 Nf6 40. Qa7 Ne8 41. Rxe8 Kg6 42. Qxc5 Rxc5 43. Re6+ Kf7 44. h4 Kxe6 45. Kg2 Re5 46. Kf3 Re1 47. c5 Rc1 48. b4 Rc3+ 49. Kg2 Ra3 50. c6 Be5 51. b5 Rxa2 52. b6 Rb2 53. c7 Rxb6 54. c8=Q+ Kd6 55. Qd8+ 1-0",
    B: "1350",
  },
  {
    W: "1731",
    T: "180+2",
    P: "1. e4 g6 2. f4 Bg7 3. e5 d6 4. d4 c5 5. c3 dxe5 6. fxe5 cxd4 7. cxd4 e6 8. Nf3 Ne7 9. Bc4 Nbc6 10. Be3 Bd7 11. O-O O-O 12. Nc3 Nf5 13. Bf2 a6 14. a3 Qc7 15. Rc1 Rac8 16. Ba2 Qa5 17. Ne4 Rfd8 18. Nd6 Nxd6 19. exd6 Bf8 20. Ne5 Bxd6 21. Nxd7 Rxd7 22. d5 exd5 23. Bxd5 Bxh2+ 24. Kxh2 Rxd5 25. Qg4 Rh5+ 26. Kg1 Rd8 27. Be1 Qg5 28. Qc4 Ne5 29. Qc3 Rd3 30. Qc8+ Rd8 31. Qc3 Rd3 32. Qc8+ Kg7 33. Bc3 Qe3+ 34. Rf2 Qxc1+ 35. Rf1 Qe3+ 36. Rf2 Rd1+ 37. Be1 Rxe1# 0-1",
    B: "1739",
  },
  {
    W: "1729",
    T: "180+2",
    P: "1. e4 c6 2. d4 d5 3. e5 e6 4. Bd3 c5 5. c3 c4 6. Bc2 Be7 7. Nd2 b5 8. f4 Nd7 9. Nf1 f6 10. g4 fxe5 11. fxe5 Bh4+ 12. Ng3 Ne7 13. Nf3 Bxg3+ 14. hxg3 h6 15. g5 Nf5 16. Bxf5 exf5 17. gxh6 gxh6 18. Rxh6 Rxh6 19. Bxh6 Qa5 20. Qc2 Nb6 21. g4 b4 22. gxf5 bxc3 23. Qxc3 Qxc3+ 24. bxc3 Bxf5 25. Nh4 Bh7 26. Kf2 O-O-O 27. Rg1 Na4 28. Rg7 Be4 29. Bd2 Rf8+ 30. Ke3 Nb2 31. Rxa7 Kb8 32. Rd7 Nd3 33. e6 Nf4 34. e7 Re8 35. Rd8+ Rxd8 36. exd8=Q+ Kb7 37. Kxf4 Bd3 38. Qxd5+ Kb6 39. Nf3 1-0",
    B: "1650",
  },
  {
    W: "2193",
    T: "180+2",
    P: "1. d4 Nf6 2. Nf3 e6 3. Bg5 d5 4. e3 c5 5. c3 Qb6 6. Qb3 Bd7 7. Nbd2 Bd6 8. Qxb6 axb6 9. a3 Nc6 10. Rc1 e5 11. dxe5 Nxe5 12. Bxf6 gxf6 13. Be2 Rg8 14. g3 Bh3 15. Nh4 Ng6 16. Ndf3 Ke7 17. Bf1 Be6 18. Bg2 Ra4 19. Nxg6+ fxg6 20. O-O Raa8 21. Nd2 Rad8 22. Rfd1 Bc7 23. Nf3 Rd7 24. Rd2 Rgd8 25. Rcd1 g5 26. h3 h5 27. Nh2 Be5 28. f4 Bc7 29. Nf3 gxf4 30. exf4 Rg8 31. Kf2 Rgd8 32. Nh4 d4 33. cxd4 cxd4 34. f5 Bb3 35. Re1+ Kf7 36. Bf3 b5 37. Bxh5+ Kg7 38. Bf3 Ba5 39. Ree2 Bxd2 40. Rxd2 d3 41. Ng2 Bc2 42. Ne3 Bb3 43. g4 Bc4 44. h4 Re7 45. g5 Rde8 46. g6 Rxe3 47. h5 Kh6 48. Bg4 Re2+ 49. Bxe2 Rxe2+ 50. Rxe2 dxe2 51. b3 Bd3 52. a4 bxa4 53. bxa4 Kxh5 54. g7 Bc4 55. g8=Q Bxg8 56. Kxe2 Kg5 57. Ke3 Bd5 58. Kd4 Bc6 59. a5 Kxf5 60. Kc5 Bg2 61. a6 bxa6 62. Kd4 Kf4 0-1",
    B: "2187",
  },
  {
    W: "1516",
    T: "180+2",
    P: "1. e4 c5 2. c3 d6 3. d4 cxd4 4. cxd4 Nc6 5. Nf3 e5 6. d5 Nce7 7. Nc3 f5 8. Bg5 f4 9. Ne2 h6 10. Bxe7 Bxe7 11. g3 Nf6 12. gxf4 Bg4 13. Bg2 Nxe4 14. fxe5 dxe5 15. Nxe5 Bxe2 16. Qxe2 Bb4+ 17. Kf1 Nd2+ 18. Kg1 O-O 19. Ng6 Rf6 20. Qd3 Qd6 21. a3 Bc5 22. Qxd2 Rxg6 23. h3 Rf8 24. Kf1 Rxf2+ 25. Qxf2 Bxf2 26. Kxf2 Rf6+ 27. Kg1 Qg3 28. Rf1 Rg6 29. Rh2 Qe3+ 30. Rf2 Qe1+ 31. Rf1 Qe3+ 32. Rf2 Qc1+ 33. Rf1 Qxb2 34. d6 Qb6+ 35. Kh1 Qxd6 36. h4 Qxa3 37. Rh3 Qa6 38. Rh2 h5 39. Rg1 b5 40. Bd5+ Kh7 41. Be4 Kh6 42. Rxg6+ Qxg6 43. Bxg6 Kxg6 44. Ra2 Kf6 45. Rxa7 g5 46. hxg5+ Kxg5 47. Ra5 Kg4 48. Rxb5 h4 49. Rb2 Kh3 50. Kg1 Kg3 51. Rh2 h3 52. Kh1 Kg4 53. Kg1 Kg3 54. Rb2 Kg4 55. Kh2 Kh4 56. Rb3 1-0",
    B: "1498",
  },
  {
    W: "2138",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 O-O 5. Bd3 d6 6. Nge2 Nc6 7. O-O Bg4 8. f3 Bd7 9. Be3 Nh5 10. Qd2 f5 11. exf5 gxf5 12. f4 Kh8 13. Rf3 e6 14. Rh3 Nf6 15. d5 Nb4 16. Bb1 exd5 17. cxd5 a5 18. a3 Na6 19. Bc2 Qe7 20. Bb3 Rae8 21. Nd4 Nc5 22. Ba2 Nce4 23. Nxe4 Nxe4 24. Qd3 c5 25. Ne6 Bxe6 26. dxe6 b6 27. Bc4 Qf6 28. Rd1 Rxe6 29. Bxe6 Qxe6 30. Rf3 Bxb2 31. Rb1 Qa2 32. h3 Bg7 33. Rxb6 c4 34. Qd5 Qa1+ 35. Kh2 Qxa3 36. Bd4 Qc1 37. Bxg7+ Kxg7 38. Rb7+ Kg6 39. Rc7 Qc3 0-1",
    B: "2163",
  },
  {
    W: "2267",
    T: "300+0",
    P: "1. a3 c5 2. c4 Nf6 3. Nc3 e6 4. Nf3 Nc6 5. d4 cxd4 6. Nxd4 Nxd4 7. Qxd4 Be7 8. e3 O-O 9. b4 a6 10. Bb2 Qc7 11. Be2 b6 12. Bf3 Bb7 13. Ne4 Rac8 14. Rc1 Rfd8 15. O-O a5 16. Rfd1 axb4 17. axb4 Bxe4 18. Bxe4 Bxb4 19. Bf3 Be7 20. g4 h6 21. h4 Qc5 22. g5 Qxd4 23. Bxd4 hxg5 24. hxg5 Nh7 25. Bxb6 Nxg5 26. Bb7 Rb8 27. Bxd8 Bxd8 28. Bg2 Rb2 29. Rxd7 Bf6 30. c5 1-0",
    B: "1965",
  },
  {
    W: "1054",
    T: "180+0",
    P: "1. e4 e5 2. Nc3 Nf6 3. d3 g6 4. Nf3 d6 5. g3 Bg7 6. Bg2 O-O 7. O-O b6 8. Bg5 Qd7 9. Bxf6 Bxf6 10. Nd5 Bg7 11. b3 c6 12. Ne3 c5 13. a4 Bb7 14. c4 Nc6 15. Nd5 Nd4 16. Nxd4 cxd4 17. b4 Bxd5 18. exd5 Rae8 19. a5 b5 20. Re1 bxc4 21. dxc4 Rc8 22. Rc1 Bh6 23. c5 Bxc1 24. Qxc1 dxc5 25. bxc5 Qe7 26. d6 Qb7 27. Bxb7 Rb8 28. Rxe5 Rxb7 29. Re7 Rxe7 30. dxe7 Re8 31. Qe1 Kg7 32. c6 Kf6 33. c7 Rxe7 34. c8=Q Rxe1+ 35. Kg2 d3 36. Qc6+ Kg7 37. Qc4 d2 38. Qd4+ f6 39. Qxd2 Re7 40. Qd6 Kf7 41. Qc6 Re6 42. Qb7+ Re7 43. Qb8 a6 44. Qb6 Re6 45. Qb7+ Re7 46. Qxa6 f5 47. Qb6 f4 48. a6 Re6 49. Qb7+ Re7 50. Qxe7+ Kxe7 51. a7 fxg3 52. a8=Q Kf6 53. hxg3 Kg5 54. f4+ Kh6 55. Qe4 Kh5 56. Kf3 g5 57. fxg5 Kxg5 58. Qf4+ Kh5 59. g4+ Kh4 60. g5+ Kh3 61. Qf7 Kh4 62. Qf5 Kh5 63. Kf4 h6 64. gxh6+ Kxh6 65. Qg5+ Kh7 66. Kf5 Kh8 67. Qg6 1/2-1/2",
    B: "1111",
  },
  {
    W: "1412",
    T: "15+0",
    P: "1. d4 Nf6 2. e4 e6 3. Bg5 Nxe4 4. Bxd8 Kxd8 5. Qd3 Nxf2 6. Qe2 Nxh1 7. Nc3 Be7 8. O-O-O Ke8 9. Qd3 Bg5+ 10. Rd2 d5 11. Be2 c5 12. Nf3 Bxd2+ 13. Qxd2 Nf2 14. Qd1 Ne4 15. Nxe4 dxe4 16. Ne5 f6 17. Nf7 Kxf7 18. Bh5+ g6 19. d5 exd5 0-1",
    B: "1145",
  },
  {
    W: "2096",
    T: "15+0",
    P: "1. h4 e5 2. a4 Bc5 3. Nf3 Bxf2+ 4. Kxf2 e4 5. e3 exf3 6. Qxf3 Nf6 7. Ke2 O-O 8. Kf2 d5 9. Be2 Ng4+ 10. Qxg4 Bxg4 11. Bxg4 Qf6+ 12. Bf3 d4 13. Ke2 dxe3 14. Rf1 Nc6 15. dxe3 Ne5 16. Nd2 Qg6 17. Ne4 Nxf3 18. gxf3 f5 19. Rf2 fxe4 20. f4 Qf5 21. h5 Rad8 22. h6 Qd5 23. Bd2 Rf7 24. Kf1 Qxd2 25. Re1 Qxf2+ 26. Kxf2 Rd2+ 27. Re2 Rxe2+ 28. Kxe2 1-0",
    B: "1842",
  },
  {
    W: "1427",
    T: "60+0",
    P: "1. e4 e6 2. d4 Ne7 3. e5 g6 4. Nc3 Bg7 5. g3 b6 6. Bg2 c6 7. Nf3 Bb7 8. Be3 Qc7 9. Qd2 c5 10. O-O Bxf3 11. a3 Bxg2 12. Kxg2 Qc6+ 13. Kg1 Nf5 14. f3 Nxe3 15. Qxe3 f6 16. Ne4 fxe5 17. dxe5 Bxe5 18. Ng5 Bxb2 19. Rab1 Bf6 20. Ne4 Ke7 21. Nxf6 Kxf6 22. f4 Ke7 23. Rb3 Na6 24. Rd3 d5 25. Qe5 c4 26. Rd2 Qd6 27. Qe3 Nc5 28. Rd4 Na4 29. Rdd1 Nb2 30. Rb1 Na4 31. Qd4 Nc5 32. Rfe1 Rhf8 33. Rbd1 Rf5 34. Re5 Rxe5 35. fxe5 Qd7 36. Qf4 Rf8 37. Qg5+ Ke8 38. Rd2 Qe7 39. Qxe7+ Kxe7 40. Re2 Ne4 41. Kg2 Nc3 42. Rd2 Na4 43. Re2 Nc5 44. Re3 Ne4 45. h3 Ng5 46. g4 Ne4 47. Kg1 Nc3 48. Re1 d4 49. Rc1 Ne2+ 50. Kg2 Nxc1 51. c3 Rf4 52. a4 Re4 53. Kg3 Re3+ 54. Kh4 Rxc3 55. Kg5 Ra3 56. Kh6 Rxa4 57. Kxh7 Ra5 58. Kxg6 Ra4 59. h4 Ra3 0-1",
    B: "1490",
  },
  {
    W: "1923",
    T: "60+3",
    P: "1. e3 d5 2. b3 c5 3. Bb2 Nf6 4. Qf3 e6 5. Qg3 a6 6. Be2 b5 7. a3 c4 8. d3 cxb3 9. cxb3 a5 10. Nd2 b4 11. a4 Ba6 12. Ngf3 Nbd7 13. O-O Nh5 14. Qg4 Nhf6 15. Qg3 Nh5 16. Qg4 Ndf6 17. Bxf6 Nxf6 18. Qf4 Bd6 19. Qg5 O-O 20. h4 Nd7 21. Qg4 Ne5 22. Qd4 Nxf3+ 23. Nxf3 Rc8 24. h5 Rc3 25. h6 gxh6 26. g3 f6 27. Kg2 Qe7 28. Rh1 Qg7 29. Qb6 Qc7 30. Qxa6 Bxg3 31. fxg3 Rc2 32. d4 Rd2 33. Rxh6 Rxe2+ 34. Qxe2 e5 35. dxe5 fxe5 36. Ng5 e4 37. Ne6 Qe5 38. Nxf8 Qxa1 39. Qg4+ Qg7 40. Qxg7+ Kxg7 41. Rxh7+ Kxf8 42. Rd7 1-0",
    B: "1992",
  },
  {
    W: "1743",
    T: "600+0",
    P: "1. e4 Nc6 2. d4 e5 3. dxe5 Nxe5 4. c3 a6 5. Nd2 Bc5 6. Nc4 Ng4 7. Qxg4 Qf6 8. Qf4 1-0",
    B: "1731",
  },
  {
    W: "1980",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 d6 3. c3 Nf6 4. Bd3 e6 5. Bc2 Be7 6. d4 O-O 7. O-O d5 8. e5 Nfd7 9. Qd3 g6 10. Bh6 Re8 11. Nbd2 Bf8 12. Qe3 Nc6 13. Bxf8 Nxf8 14. Qh6 b6 15. Rfe1 Bb7 16. Ba4 a6 17. Bxc6 Bxc6 18. Ng5 f5 19. exf6 Qxf6 20. Ndf3 Qg7 21. Qxg7+ Kxg7 22. Ne5 Bb7 23. Ngf7 Re7 24. Nd6 Rb8 25. f4 Bc8 26. Re3 Bd7 27. Rae1 cxd4 28. cxd4 b5 29. Rc1 b4 30. a3 bxa3 31. bxa3 Be8 32. Rec3 Bd7 33. Rc7 Rb3 34. a4 Rb4 35. Nc6 Bxc6 36. Rxe7+ Kh6 37. Rxc6 1-0",
    B: "1819",
  },
  {
    W: "1993",
    T: "60+0",
    P: "1. d4 c5 2. c4 cxd4 3. Nf3 e5 4. Nxe5 Qa5+ 5. Bd2 Qxe5 6. g3 Qe4 7. f3 Qe5 8. Bg2 Nc6 9. Bf4 Qa5+ 10. Kf2 g5 11. Bd2 Qd8 12. Rf1 h6 13. Kg1 f5 14. Na3 a6 15. Qc2 d6 16. Qd3 Nf6 17. g4 fxg4 18. Qg6+ Ke7 19. fxg4 Rg8 20. Qxf6+ 1-0",
    B: "1942",
  },
  {
    W: "1588",
    T: "60+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. d4 d6 4. Be3 Bg4 5. Be2 Nc6 6. Nbd2 e6 7. g3 Bxf3 8. Bxf3 Nge7 9. Bg2 O-O 10. O-O d5 11. e5 Nf5 12. Rc1 Nfxd4 13. Bxd4 Nxd4 14. c4 Nc6 15. cxd5 exd5 16. Nb3 Nxe5 17. Rc5 c6 18. Na5 Rb8 19. Nxc6 bxc6 20. Bxd5 cxd5 21. Rxd5 Qb6 22. Re1 Rfe8 23. Qe2 Nf3+ 24. Qxf3 Rxe1+ 25. Kg2 Rbe8 26. Rd7 Qxb2 27. Rxa7 f5 28. g4 R1e2 29. gxf5 gxf5 30. Qxf5 Rf8 31. Qe6+ Kh8 32. Qe7 Rexf2+ 33. Kg3 Be5+ 34. Kg4 Rg2+ 35. Kh4 1-0",
    B: "1629",
  },
  {
    W: "1867",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Nc3 e5 4. Be2 d6 5. d3 Bg4 6. h3 Bxf3 7. Bxf3 Qd7 8. O-O g6 9. Be3 Bg7 10. Nd5 Nge7 11. c4 Nxd5 12. cxd5 Nd4 13. a3 O-O 14. Rb1 Nxf3+ 15. Qxf3 f5 16. b4 f4 17. Bd2 b6 18. Rfc1 Rac8 19. Qe2 g5 20. f3 h5 21. Be1 Rf6 22. Bf2 Rg6 23. bxc5 bxc5 24. Rb3 g4 25. fxg4 hxg4 26. hxg4 Rxg4 27. Rcb1 Rf8 28. Kf1 Qf7 29. Qxg4 f3 30. gxf3 Qb7 1-0",
    B: "1860",
  },
  {
    W: "1385",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. Nc3 Nf6 5. d4 c6 6. Bf4 Bd6 7. Ne5 O-O 8. Qe2 Re8 9. O-O-O Nbd7 10. g3 Bb4 11. Re1 Bxc3 12. bxc3 b5 13. Kd2 a5 14. Bh3 Nxe5 15. dxe5 Bxh3 16. Qd3 Ne4+ 17. Kc1 Qb6 18. f3 Nf2 19. Qd2 Nxh1 20. e6 Bf5 21. exf7+ Kxf7 0-1",
    B: "1427",
  },
  {
    W: "1451",
    T: "60+0",
    P: "1. e4 d5 2. Qf3 dxe4 3. Qxe4 Nf6 4. Qf3 c6 5. Be2 e6 6. Bd1 Bc5 7. d3 Nbd7 8. Qg3 Nb6 9. Qxg7 Rg8 10. Qh6 Bd7 11. Bg5 Be7 12. Bxf6 Bxf6 13. Qxh7 Ke7 14. Bh5 Rh8 15. Qxf7+ Kd6 16. Nf3 Qe7 17. Qxe7+ Bxe7 18. Bf7 Raf8 19. Ng5 Bxg5 20. Bh5 Rxh5 21. f3 Bh4+ 22. g3 Be7 23. g4 Rh3 24. Nd2 Kc7 25. O-O-O Bd6 26. Rde1 Bf4 27. Re4 Bxd2+ 28. Kxd2 Rfxf3 29. Rhe1 Nd5 30. R4e3 Rxe3 31. Rxe3 Nxe3 32. c4 Nxg4 33. Kc3 Nxh2 34. Kb3 a6 35. Kb4 a5+ 36. Kxa5 b6+ 37. Kb4 1-0",
    B: "1515",
  },
  {
    W: "2161",
    T: "60+0",
    P: "1. e4 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qd1 e6 5. Nc3 Nf6 6. Nf3 d6 7. Be2 Be7 8. O-O O-O 9. Be3 e5 10. h3 h6 11. Rc1 Be6 12. Qd2 a6 13. Rfd1 Qc7 14. a3 Rad8 15. Nd5 Bxd5 16. exd5 Nb8 17. c4 b6 18. b4 Nbd7 19. Qb2 Qb7 20. Nd2 Nh7 21. Nb3 f5 22. f3 Nhf6 23. Bf2 e4 24. f4 Nh5 25. Bd4 Nhf6 26. c5 bxc5 27. bxc5 dxc5 28. Bxc5 Nxc5 29. d6 Rxd6 30. Bc4+ Kh7 31. Rxd6 Bxd6 32. Qc3 Nxb3 33. Bxb3 Bxf4 34. Rb1 Qc7 35. Bc2 Qxc3 36. Bd1 Qg3 37. Kf1 Rd8 38. Be2 Rd2 39. Re1 Be3 40. Bf3 Qf2# 0-1",
    B: "2037",
  },
  {
    W: "1784",
    T: "60+0",
    P: "1. Nf3 e5 2. d4 Nc6 3. d5 Nce7 4. Nxe5 Ng6 5. Nxg6 hxg6 6. e4 c6 7. Bc4 Nf6 8. Nc3 d6 9. Bg5 Be7 10. Qd2 Bg4 11. f3 Bh5 12. g4 cxd5 13. Bb5+ Nd7 14. Bxe7 Qxe7 15. Bxd7+ Qxd7 16. gxh5 Rxh5 17. O-O-O O-O-O 18. Nxd5 Kb8 19. Kb1 Rc8 20. Nc3 f5 21. Qxd6+ Qxd6 22. Rxd6 Ka8 23. Rxg6 fxe4 24. fxe4 Re5 25. Rxg7 a6 26. Rd1 Rb8 27. a3 Rf5 28. Rdd7 Rb5 29. Ka2 Rb6 30. Nd5 Ka7 31. Nxb6 Kxb6 32. e5 Kc6 33. e6 Kb5 34. h4 Kc6 35. h5 Rd8 36. h6 1-0",
    B: "1758",
  },
  {
    W: "2272",
    T: "60+0",
    P: "1. g3 e5 2. f3 d6 3. Nh3 f5 4. Nf2 Nf6 5. Bg2 d5 6. O-O c5 7. d3 Nc6 8. e3 Bd6 9. c3 O-O 10. d4 cxd4 11. exd4 e4 12. fxe4 fxe4 13. Bg5 Kh8 14. Nd2 Be6 15. Be3 Qd7 16. Qe2 Ng4 17. Nxg4 Bxg4 18. Qe1 Rxf1+ 19. Nxf1 Rf8 20. Qd2 Bh3 21. Re1 Bxg2 22. Qxg2 Qg4 23. Nd2 Ne7 24. Rf1 Rxf1+ 25. Nxf1 h6 26. h3 Qd1 27. Qf2 Ng6 28. Kg2 Kh7 29. Nd2 b5 30. Nb3 a5 31. Nxa5 Be7 32. Nb3 Bg5 33. Nc5 Bxe3 34. Qxe3 Qc2+ 35. Qf2 Qd1 36. Ne6 Qc1 37. Qe2 e3 38. h4 Qd2 39. Kf3 Qc1 40. Qxe3 Qh1+ 41. Kf2 Qh2+ 42. Kf3 1-0",
    B: "2104",
  },
  {
    W: "1131",
    T: "60+0",
    P: "1. d4 c5 2. Nf3 cxd4 3. Qxd4 Nc6 4. Qd1 e6 5. Bg5 Nf6 6. Nc3 d5 7. e3 Bb4 8. Bb5 O-O 9. O-O a6 10. Ba4 b5 11. Bb3 Be7 12. Ne2 Bb7 13. Ng3 Rc8 14. Qe2 Na7 15. Rad1 a5 16. a3 a4 17. Ba2 b4 18. Bxf6 Bxf6 19. e4 bxa3 20. exd5 Bxd5 21. Bxd5 exd5 22. bxa3 Re8 23. Nf5 Rxe2 24. N3d4 Qb6 25. Nxe2 Rxc2 26. Rb1 Bc3 27. Rxb6 1-0",
    B: "1131",
  },
  {
    W: "1859",
    T: "60+0",
    P: "1. e4 e5 2. Nc3 Nc6 3. Nf3 Nf6 4. Bb5 d6 5. Bxc6+ bxc6 6. d4 exd4 7. Qxd4 Be7 8. e5 dxe5 9. Qxd8+ Bxd8 10. Nxe5 Bb7 11. Bg5 O-O 12. O-O-O Re8 13. Nc4 Nd5 14. Bxd8 Nxc3 15. bxc3 Raxd8 16. f3 Rxd1+ 17. Rxd1 g6 18. Rd2 Re1+ 19. Kb2 Ba6 20. Na5 Re2 21. Rxe2 Bxe2 22. Nxc6 Bf1 23. g3 f6 24. f4 Kf7 25. Nd4 c5 26. Nb3 c4 27. Nd4 Ke7 28. Kc1 a6 29. Kd2 Kd6 30. Ke3 Bh3 31. Ne2 Kc5 32. Kf3 h5 33. Nd4 Bg4+ 34. Ke4 Bd1 35. f5 g5 36. h3 h4 37. gxh4 gxh4 38. Nf3 Bxc2+ 39. Ke3 Bxf5 40. Nxh4 Bxh3 0-1",
    B: "1852",
  },
  {
    W: "1405",
    T: "180+0",
    P: "1. e3 e5 2. Qh5 Nf6 3. Qxe5+ Qe7 4. Qxc7 Nc6 5. Nc3 Qd6 6. Qxd6 Bxd6 7. Nf3 O-O 8. Bb5 a6 9. Bxc6 dxc6 10. Nd4 b5 11. Nxc6 Bb7 12. Nd4 Bxg2 13. Rg1 Be4 14. d3 Bb7 15. Nf5 Be5 16. Ne7+ Kh8 17. e4 Rfe8 18. Nf5 Rac8 19. Nxg7 Rg8 20. Nf5 Rxg1+ 21. Ke2 Rcg8 22. Ne7 R8g2 23. b3 Bxh2 24. e5 Ng4 0-1",
    B: "1411",
  },
  {
    W: "1765",
    T: "180+0",
    P: "1. d4 d5 2. c4 Nf6 3. Nc3 dxc4 4. e4 Nc6 5. Nf3 Bg4 6. Be3 e6 7. Bxc4 Bb4 8. e5 Ne4 9. Qb3 Bxc3+ 10. bxc3 O-O 11. O-O Bxf3 12. gxf3 Ng5 13. Rfd1 Nxf3+ 14. Kh1 Qh4 15. Bf4 Qxf4 0-1",
    B: "1826",
  },
  {
    W: "1361",
    T: "180+0",
    P: "1. e4 Nc6 2. Nc3 e6 3. d4 d6 4. Nf3 Bd7 5. e5 d5 6. Bd3 Bb4 7. Bd2 Qe7 8. a3 Ba5 9. b4 Bb6 10. O-O a6 11. Bg5 f6 12. exf6 gxf6 13. Bh4 O-O-O 14. Re1 Rf8 15. Nxd5 Qf7 16. Nxb6+ cxb6 17. b5 Na5 18. bxa6 bxa6 19. Bxa6+ Kc7 20. Bg3+ Kd8 21. Qd2 Ne7 22. c4 Nac6 23. c5 bxc5 24. dxc5 Nb8 25. Qa5+ Ke8 26. Bxb8 Nc6 27. Qc7 Nxb8 28. Qxb8+ Ke7 29. Qb4 Rb8 30. Qd2 Bc6 31. Rxe6+ Qxe6 32. Re1 Qxe1+ 33. Nxe1 Kf7 34. h3 Rhd8 35. Bc4+ Kg7 0-1",
    B: "1348",
  },
  {
    W: "1537",
    T: "180+0",
    P: "1. e4 a6 2. d4 b5 3. Be3 e6 4. Bd3 d5 5. e5 c5 6. c3 c4 7. Be2 a5 8. Nf3 b4 9. Nbd2 Bb7 10. cxb4 axb4 11. O-O Ba6 12. Bg5 Be7 13. Bf4 f6 14. Qc2 fxe5 15. Bxe5 Bf6 16. h3 Nh6 17. Nh2 O-O 18. Bh5 Nd7 19. Ndf3 Nxe5 20. dxe5 Bh4 21. g3 Be7 22. Nd4 Rf5 23. Nxe6 Qd7 24. Nf4 Bg5 25. e6 Qe7 26. Bf7+ Nxf7 27. exf7+ Qxf7 28. Nf3 Bxf4 29. gxf4 Rxf4 30. Ne5 Qf6 31. Ng4 Qg5 32. f3 c3 33. Rf2 cxb2 34. Qxb2 Raf8 35. Kg2 Qf5 36. Ne5 Re8 37. Ng4 h5 38. Ne3 Rf8 39. Nxf5 1-0",
    B: "1512",
  },
  {
    W: "2310",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 d6 5. O-O Nge7 6. c3 O-O 7. b4 Bb6 8. h3 h6 9. a4 a6 10. a5 Ba7 11. Be3 Kh8 12. Re1 f5 13. exf5 Bxf5 14. Bxa7 Rxa7 15. Nbd2 Qe8 16. Nh4 Bh7 17. Qg4 Nf5 18. Nxf5 Bxf5 19. Qg3 Qg6 20. Qxg6 Bxg6 21. Ne4 Raa8 22. Bd5 Rae8 23. Bxc6 bxc6 24. Re3 Bxe4 25. Rxe4 Rf4 26. Rae1 Ref8 27. f3 R8f7 28. Kf2 g5 29. d4 exd4 30. cxd4 Rxe4 31. Rxe4 Kg7 32. Ke3 Kf6 33. f4 Rf8 34. fxg5+ hxg5 35. Kd3 Rf7 36. h4 Kg6 37. Rg4 Rf5 38. Rxg5+ Rxg5 39. hxg5 Kxg5 40. d5 cxd5 41. b5 axb5 42. a6 b4 43. a7 b3 44. a8=Q b2 45. Qxd5+ Kg4 46. Qf3+ 1-0",
    B: "2324",
  },
  {
    W: "1320",
    T: "180+0",
    P: "1. e4 c5 2. f4 d6 3. Nf3 Nf6 4. Nc3 e6 5. d3 Be7 6. g3 Nc6 7. Bg2 Nd4 8. Nxd4 cxd4 9. Ne2 e5 10. f5 O-O 11. O-O a5 12. c4 dxc3 13. bxc3 b5 14. Bb2 b4 15. d4 bxc3 16. Bxc3 exd4 17. Nxd4 Qb6 18. a4 Rd8 19. Kh1 d5 20. e5 Ne4 21. Bxe4 dxe4 22. Rf4 Bb7 23. Qg1 e3+ 24. Qg2 Bxg2+ 25. Kxg2 Rxd4 26. Bxd4 Qb2+ 27. Kh3 Qb4 28. f6 gxf6 29. Bxe3 fxe5 30. Rxb4 axb4 31. a5 b3 32. Rb1 Rb8 33. Kg2 Bb4 34. Rxb3 Rb5 35. a6 Ra5 36. Rxb4 Rxa6 37. Kf3 Ra3 38. Ke4 f6 39. Bh6 Kf7 40. Kf5 Rf3+ 41. Ke4 Rf1 42. Rb7+ Kg6 43. Be3 h5 44. Rb2 Rh1 45. Kd5 Rd1+ 46. Ke4 Re1 47. Kf3 Rxe3+ 48. Kxe3 Kg5 49. Kf3 f5 50. Rb5 e4+ 51. Ke3 Kg4 52. Kd4 e3 53. Kxe3 f4+ 54. Kd4 f3 55. Kd3 f2 56. Rb4+ Kf3 57. Rb1 Kg2 58. Ke2 f1=Q+ 59. Rxf1 Kxh2 60. Rf2+ Kxg3 61. Ke3 h4 62. Rf1 h3 63. Rh1 Kg2 64. Rxh3 Kxh3 1/2-1/2",
    B: "1324",
  },
  {
    W: "1576",
    T: "180+0",
    P: "1. e4 b6 2. Nf3 Bb7 3. Nc3 g6 4. d4 Bg7 5. Be3 e6 6. Bd3 Ne7 7. O-O O-O 8. h3 d6 9. e5 dxe5 10. Nxe5 Bxe5 11. dxe5 Nd7 12. Bf4 Nc5 13. Bc4 Qxd1 14. Raxd1 Ne4 15. Nxe4 Bxe4 16. Rfe1 Bxc2 17. Rd7 Nf5 18. Rxc7 Rfc8 19. Rxc8+ Rxc8 20. Ba6 Rc7 21. Re2 Nd4 22. Rd2 Rd7 23. Be3 Nf3+ 24. gxf3 Rxd2 25. Bxd2 Bb1 26. a3 Ba2 27. Bb4 Bd5 28. Kg2 f6 29. exf6 Kf7 30. Be7 e5 31. Kg3 e4 32. fxe4 Bxe4 33. f3 Bf5 34. h4 h5 35. f4 Be4 36. Kf2 Bc2 37. Ke3 Bb3 38. Kd4 Ke6 39. Ke4 Bc2+ 40. Kd4 Bb3 41. Ke4 Bc2+ 42. Kf3 Bd1+ 43. Be2 Bb3 44. Kg3 Kf5 45. Bf3 Bc4 46. Bc6 Be6 47. Be8 Bd5 48. f7 Ke6 49. f8=Q Be4 50. Bf7+ Kd7 51. Bxg6 Bxg6 52. Qd8+ Ke6 53. Qd6+ Kf7 54. Qf6+ Ke8 55. Qxg6+ Kxe7 56. Qxh5 Kf6 57. Kg4 a5 58. f5 b5 59. Qg6+ Ke7 60. h5 Kf8 61. h6 b4 62. h7 bxa3 63. bxa3 a4 64. h8=Q+ 1-0",
    B: "1562",
  },
  {
    W: "1296",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 exd4 6. Nxd4 Nxd4 7. Qxd4 Nd6 8. Re1+ Be7 9. Bg5 f6 10. Qe3 Nxb5 11. Bf4 Nd6 12. c4 b6 13. Nc3 Kf7 14. Nd5 Bb7 15. Nxe7 Re8 16. Bxd6 cxd6 17. Qh3 Rxe7 18. Rxe7+ Qxe7 19. Qxh7 Qe5 20. g3 Qxb2 21. Qh5+ Kf8 22. Qh8+ Kf7 23. Qh5+ Ke7 24. Re1+ Kf8 25. Qh8+ Kf7 26. Qh5+ g6 27. Qh7+ Kf8 28. Qe7+ Kg8 29. Qxf6 Qxf6 0-1",
    B: "1275",
  },
  {
    W: "2004",
    T: "180+0",
    P: "1. d4 d6 2. c4 g6 3. Nc3 c6 4. e4 Bg7 5. d5 Nf6 6. f3 O-O 7. Be3 e6 8. Nge2 exd5 9. cxd5 Re8 10. Rb1 cxd5 11. Nxd5 Be6 12. Nec3 Nxd5 13. Nxd5 Qc8 14. Bb5 Nc6 15. O-O Rf8 16. Bxc6 bxc6 17. Ne7+ Kh8 18. Nxc8 1-0",
    B: "2086",
  },
  {
    W: "1485",
    T: "180+0",
    P: "1. d4 d6 2. Nf3 g6 3. Bf4 Bg7 4. e3 Nf6 5. Bb5+ c6 6. Be2 O-O 7. O-O Nbd7 8. c4 a6 9. e4 b5 10. e5 dxe5 11. Bxe5 Ng4 12. Bxg7 Kxg7 13. Ng5 Ngf6 14. f4 h6 15. Nf3 Ne4 16. Qd3 f5 17. Ne5 Nb6 18. Nxc6 Qd6 19. Ne5 Bb7 20. c5 Nxc5 21. dxc5 Qxe5 22. cxb6 Qc5+ 23. Kh1 Qc6 24. Bf3 Qxb6 25. Bxb7 Qxb7 26. Nc3 Rad8 27. Qe2 Rd4 28. Qe5+ Kg8 29. Qxd4 1-0",
    B: "1678",
  },
  {
    W: "1753",
    T: "180+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. Bc4 d6 4. O-O Nf6 5. Re1 O-O 6. c3 b6 7. d4 Bb7 8. Bd3 c5 9. d5 Nbd7 10. c4 Ne8 11. Nc3 Ne5 12. Nxe5 Bxe5 13. Be3 e6 14. Qd2 exd5 15. Nxd5 Bxd5 16. cxd5 Qh4 17. f4 Bg7 18. Qf2 Qxf2+ 19. Kxf2 Bxb2 20. Rab1 Bg7 21. g4 Nf6 22. h3 Rfe8 23. Bd2 h5 24. g5 Nh7 25. Kg3 f6 26. gxf6 Nxf6 27. Bc3 Nd7 28. Bxg7 Kxg7 29. Kh4 Kh6 30. Rg1 Re7 31. f5 gxf5 32. exf5 Rg7 33. Rg6+ Rxg6 34. fxg6 Kg7 35. Kxh5 Ne5 36. Bc2 Rh8+ 37. Kg5 Rxh3 38. a4 Rg3+ 39. Kh4 Rg2 40. Bf5 Nxg6+ 41. Bxg6 Kxg6 42. a5 Rh2+ 43. Kg3 Rh5 44. axb6 axb6 45. Rxb6 Rxd5 46. Kf4 Rd4+ 47. Ke3 Kf5 48. Rc6 Ke5 49. Rc8 Kd5 50. Rd8 Re4+ 51. Kd3 Rh4 52. Rd7 Rh3+ 53. Kd2 c4 54. Kc2 c3 55. Rc7 Kd4 56. Rc6 d5 57. Kb3 Kd3 58. Rd6 d4 59. Rd5 c2 60. Kb4 1-0",
    B: "1730",
  },
  {
    W: "1162",
    T: "180+0",
    P: "1. e4 e5 2. f4 d6 3. Nf3 Nc6 4. Bc4 Be6 5. Bxe6 fxe6 6. O-O Be7 7. d4 Nf6 8. fxe5 dxe5 9. dxe5 Nxe4 10. Qxd8+ Kxd8 11. Be3 b6 12. Nc3 Nxc3 13. bxc3 Bc5 14. Bxc5 bxc5 15. Rad1+ Ke7 16. Rfe1 Rhd8 17. h3 Rxd1 18. Rxd1 Rd8 19. Rxd8 Nxd8 20. g4 g5 21. Kf2 h6 22. Ke3 Nc6 23. Ke4 a5 24. a3 a4 25. h4 gxh4 26. Nxh4 Kf7 27. Kf4 Kg7 28. Nf3 Kg6 29. Ke3 h5 30. gxh5+ Kxh5 31. Kd3 Kg4 32. Nd2 Kf4 33. Kc4 Nxe5+ 34. Kxc5 Ke3 35. Nc4+ Nxc4 36. Kxc4 e5 37. Kd5 e4 38. c4 c5 39. Kxc5 Kd2 40. Kd4 e3 41. c5 e2 42. c6 e1=Q 43. c7 Qe8 44. Kc5 Qc8 45. Kc6 Kxc2 46. Kb5 Kb3 47. Kc6 Kxa3 48. Kb5 Qxc7 49. Ka6 Kb4 1/2-1/2",
    B: "1086",
  },
  {
    W: "2245",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 c6 4. d4 Be7 5. a4 Nd7 6. Nc3 Ngf6 7. h3 O-O 8. O-O Kh8 9. Be3 Nxe4 10. Nxe4 d5 11. Bd3 dxe4 12. Bxe4 f5 13. Bd3 e4 14. Bc4 exf3 15. Qxf3 Nf6 16. Rfe1 Nd5 17. Bd2 f4 18. c3 Bf5 19. Re2 Bg6 20. Rae1 Bg5 21. Re5 Bf6 22. R5e2 Qd6 23. Bxd5 cxd5 24. Bxf4 Qb6 25. Qg3 Rae8 26. b4 Qa6 27. Rxe8 Bxe8 28. a5 Bg6 29. Be5 Be4 30. Bxf6 Qxf6 31. f3 Bd3 32. Re5 Bc4 33. Kh2 h6 34. Qg4 b6 35. Qd7 bxa5 36. bxa5 a6 37. Qg4 Qd6 38. h4 Rf6 39. h5 Kh7 40. f4 Qf8 41. g3 Qf7 42. Qh4 Rf5 43. Rxf5 Qxf5 44. Kg2 Qc2+ 45. Kh3 Qxc3 46. Qg4 Bf1+ 47. Kh4 Qxd4 48. Qg6+ Kg8 49. Qe8+ Kh7 50. Qg6+ Kg8 51. Qe8+ Kh7 52. Qg6+ 1/2-1/2",
    B: "2190",
  },
  {
    W: "1669",
    T: "300+0",
    P: "1. d4 d5 2. e3 Nf6 3. c4 Bg4 4. f3 Bh5 5. c5 e6 6. Nc3 c6 7. a4 Na6 8. Bxa6 bxa6 9. Nh3 h6 10. g4 Bg6 11. Rg1 Be7 12. f4 Rb8 13. Qf3 Ne4 14. Nxe4 Bxe4 15. Qf2 Bh4 16. Rg3 Qa5+ 17. Bd2 Qc7 18. Bc3 g5 19. Rd1 f6 20. b4 a5 21. Qf1 axb4 22. Bd2 b3 23. Nf2 b2 24. Nxe4 b1=Q 25. Bc1 dxe4 0-1",
    B: "1623",
  },
  {
    W: "999",
    T: "300+0",
    P: "1. d4 d5 2. c4 e6 3. Bf4 Nf6 4. Nc3 Bd6 5. Bxd6 Qxd6 6. e3 dxc4 7. Bxc4 O-O 8. Nf3 Bd7 9. Ne5 Nc6 10. Nxd7 Nxd7 11. Bd3 Rfe8 12. a3 e5 13. Qh5 g6 14. Qf3 exd4 15. Nd5 dxe3 16. fxe3 Nde5 17. Qf6 Nxd3+ 18. Ke2 Qxd5 19. Rhf1 Rf8 0-1",
    B: "1006",
  },
  {
    W: "1754",
    T: "300+0",
    P: "1. e4 g6 2. d4 Bg7 3. Nf3 d6 4. Bd3 Nf6 5. h3 O-O 6. Be3 a6 7. Qd2 b5 8. a3 Bb7 9. e5 dxe5 10. dxe5 Nfd7 11. Bh6 Nc6 12. Bxg7 Kxg7 13. O-O Ncxe5 14. Nxe5 Nxe5 15. Nc3 Nxd3 16. cxd3 Qd7 17. d4 Rad8 18. Rad1 Qf5 19. Qe3 Rd7 20. Rd3 Rfd8 21. Rfd1 e5 22. dxe5 Rxd3 23. Rxd3 Rxd3 24. Qg3 Rxg3 0-1",
    B: "1720",
  },
  {
    W: "1356",
    T: "300+0",
    P: "1. e4 e5 2. f4 d6 3. Nf3 exf4 4. d4 g5 5. Nc3 h6 6. Be2 Nf6 7. O-O Nc6 8. Bb5 a6 9. Bxc6+ bxc6 10. e5 dxe5 11. Nxe5 Bg4 12. Qe1 Qxd4+ 13. Kh1 Bd6 14. Nxc6+ Ne4 15. Nxd4 O-O 16. Qxe4 1-0",
    B: "1189",
  },
  {
    W: "1102",
    T: "300+0",
    P: "1. e3 d5 2. d4 Nc6 3. Bd3 e5 4. Bd2 Bb4 5. Nc3 Qe7 6. Nf3 exd4 7. Nxd4 Nxd4 8. Nxd5 Bxd2+ 9. Qxd2 Qe5 10. O-O-O Bg4 11. f3 Nxf3 12. Bb5+ c6 13. Qc3 Qxc3 14. Nxc3 cxb5 15. gxf3 Bxf3 16. Nxb5 Bxh1 17. Nc7+ Ke7 18. Nxa8 Nf6 19. Rxh1 Rxa8 20. Re1 Ne4 21. c3 Rc8 22. a3 f5 23. Rg1 g5 24. h4 gxh4 25. Rg7+ Ke6 26. Rxh7 Nxc3 27. Rxh4 Ne2+ 28. Kb1 Rc1+ 29. Ka2 Kf6 30. Rh6+ Kg5 31. Rh7 f4 32. exf4+ Nxf4 33. Rg7+ Kf6 34. Rxb7 a5 35. Rb6+ Ke5 36. Rb5+ Kd4 37. Rxa5 Nd3 38. b4 Rc2+ 39. Kb3 Rc3+ 40. Ka4 Nb2+ 41. Kb5 Nd3 42. a4 Ne5 43. Ra6 Nd7 44. a5 Rc4 45. Rd6+ Kc3 46. Rxd7 Rxb4+ 47. Ka6 Ra4 48. Rd5 Kc4 49. Rh5 Kb3 50. Kb7 Kb4 51. a6 Ra5 52. a7 Rxh5 53. a8=Q Kc5 54. Qa5+ Kd6 55. Qxh5 Ke6 56. Kc7 Kf6 57. Kd7 1-0",
    B: "1119",
  },
  {
    W: "1681",
    T: "300+0",
    P: "1. d4 Nf6 2. e3 g6 3. c4 Bg7 4. Nf3 d6 5. a3 O-O 6. b4 Bg4 7. Nc3 a6 8. Be2 Nbd7 9. h3 Bxf3 10. Bxf3 c6 11. d5 c5 12. e4 Ne5 13. Bf4 Nxc4 14. O-O Nxe4 15. Bxe4 Bxc3 16. Rc1 cxb4 17. axb4 Be5 18. Bh6 Nb6 19. Bxf8 Qxf8 20. f4 Bf6 21. f5 Nd7 22. Rc7 Ne5 23. Rxb7 Qc8 24. Rb6 Nc4 25. Rc6 Qb7 26. Rxc4 Qb5 27. Qc2 Kg7 28. Rc1 Qb6+ 29. Kh1 Qe3 30. fxg6 fxg6 31. Qd3 Qf4 32. Qf3 Qe5 33. Rc8 Ra7 34. R8c7 Ra8 35. Rf1 Rb8 36. Ra7 Rxb4 37. Rxa6 Rxe4 38. Ra3 Qxd5 39. Re3 Rxe3 40. Qxd5 Re5 41. Qf3 1-0",
    B: "1709",
  },
  {
    W: "1382",
    T: "300+0",
    P: "1. Nf3 d5 2. g3 e6 3. Bg2 Bc5 4. d4 Bb6 5. O-O Nf6 6. Nc3 h6 7. Ne5 O-O 8. e4 dxe4 9. Nxe4 Nxe4 10. Bxe4 Qxd4 11. Qxd4 Bxd4 12. Nf3 Bf6 13. c3 Nc6 14. Bxc6 bxc6 15. Bf4 e5 16. Bxe5 Bxe5 17. Nxe5 Bb7 18. Rad1 Rfe8 19. Nd7 Rad8 20. Nc5 Rxd1 21. Rxd1 Bc8 22. b4 Kh7 23. a4 Bg4 24. Rd2 Bf3 25. Na6 Re1# 0-1",
    B: "1449",
  },
  {
    W: "2198",
    T: "300+0",
    P: "1. g3 d5 2. Bg2 c6 3. Nf3 Nf6 4. d3 Nbd7 5. O-O e5 6. c4 Bd6 7. cxd5 cxd5 8. Nc3 O-O 9. Bg5 Qa5 10. Nd2 d4 11. Nc4 Qa6 12. Nxd6 Qxd6 13. Nb5 Qb6 14. a4 a6 15. Na3 Qxb2 16. Bxf6 Nxf6 17. Nc4 Qb4 18. Nxe5 Re8 19. Rb1 Qe7 20. Nf3 Qxe2 21. Qxe2 Rxe2 22. Nxd4 Ra2 23. Bxb7 Bxb7 24. Rxb7 Rxa4 25. Rfb1 g6 26. Nc6 Ra3 27. d4 Rd3 28. R1b4 a5 29. Ra4 Ne4 30. d5 Rd1+ 31. Kg2 Rd2 32. Rxe4 a4 33. Ree7 a3 34. Rxf7 a2 35. Rg7+ Kh8 36. Rxh7+ Kg8 37. Rbg7+ Kf8 38. Rh8+ Kxg7 39. Rxa8 1-0",
    B: "2193",
  },
  {
    W: "1330",
    T: "300+0",
    P: "1. e4 e5 2. d3 Nc6 3. Nc3 Nf6 4. Nf3 Bb4 5. Bd2 Bxc3 6. Bxc3 O-O 7. Be2 d6 8. Qd2 Bg4 9. O-O-O Nd4 10. Bxd4 exd4 11. h3 Bxf3 12. Bxf3 Qe7 13. g4 Rad8 14. Kb1 d5 15. exd5 Nxd5 16. Bxd5 Rxd5 17. f4 c5 18. f5 f6 19. a3 b6 20. h4 a5 21. c3 dxc3 22. Qxc3 Qe3 23. d4 Qe4+ 24. Ka2 Qxg4 25. dxc5 Rxd1 26. Qc4+ Kh8 27. Qxg4 Rxh1 28. cxb6 Rb8 29. Qd4 Re1 30. Qd6 Ree8 31. b7 Rxb7 32. Qc6 Rbb8 33. Qc7 Rbc8 34. Qxa5 Re4 35. Qa6 Rce8 36. Qc6 h5 37. a4 R8e7 38. a5 Rxh4 39. a6 Rf4 40. Qb6 Re8 41. a7 h4 42. Qb8 Ra4+ 43. Kb3 Rxa7 44. Qxa7 Re3+ 45. Qxe3 1-0",
    B: "1224",
  },
  {
    W: "1522",
    T: "300+0",
    P: "1. d4 e5 2. dxe5 f6 3. exf6 Nxf6 4. Nc3 Nc6 5. e4 d6 6. Bb5 Bd7 7. Nf3 a6 8. Ba4 h6 9. Be3 Ng4 10. Qe2 Nxe3 11. Qxe3 b5 12. Bb3 Nb4 13. O-O a5 14. a3 Na6 15. Bd5 c6 16. Ba2 d5 17. exd5+ Be7 18. dxc6 Bxc6 19. Rfe1 Nc7 20. Qf4 Rf8 21. Qg4 g5 22. Qh5+ Rf7 23. Qxh6 b4 24. Bxf7+ Kxf7 25. Qh7+ Kf6 26. Ne4+ Ke6 27. Nexg5+ Kf6 28. Qf7# 1-0",
    B: "1507",
  },
  {
    W: "1819",
    T: "300+0",
    P: "1. g3 e6 2. Bg2 h6 3. d3 Nf6 4. Nf3 Nc6 5. O-O b6 6. a3 Bb7 7. Nc3 Rb8 8. b4 a6 9. Bd2 Bd6 10. Qc1 Nh7 11. Rd1 Ne7 12. e4 Ng6 13. d4 Be7 14. Re1 Nf6 15. Rb1 d5 16. e5 Ne4 17. Nxe4 dxe4 18. Re3 exf3 19. Bxf3 Bg5 20. Reb3 Qxd4 21. Bxg5 Nxe5 22. Be3 Nxf3+ 23. Kg2 Qf6 24. Qd1 Ng5+ 25. Kf1 Bf3 26. Qd3 Rd8 27. Qxa6 O-O 28. Bxg5 Qxg5 29. Rxf3 Qg4 30. Qe2 Rd5 31. c4 Rd6 32. Rd1 Rfd8 33. Rxd6 Rxd6 34. Kg2 h5 35. Qe5 g6 36. c5 bxc5 37. Qxc5 Rd7 38. b5 Qe4 39. Qc6 Qd4 40. b6 cxb6 41. Rf4 Qd5+ 42. Qxd5 1-0",
    B: "1837",
  },
  {
    W: "1595",
    T: "300+0",
    P: "1. d4 e6 2. Bf4 Be7 3. e3 Nf6 4. Nf3 c6 5. Nbd2 b5 6. c4 a6 7. cxb5 axb5 8. Bd3 Ba6 9. a3 Qa5 10. b4 Qb6 11. O-O O-O 12. e4 Bb7 13. e5 Nh5 14. Be3 Qd8 15. Ne1 g6 16. Be2 Ng7 17. Bh6 Bg5 18. Bxg5 Qxg5 19. Ndf3 Qe7 20. Qc1 Na6 21. Qh6 Nf5 22. Qh3 Nc7 23. Nd3 Nd5 24. Qg4 Nc3 25. Rfe1 c5 26. dxc5 Bxf3 27. Bxf3 Rab8 28. Nf4 Nd4 29. Nh5 Nxf3+ 30. gxf3 Nd5 31. Rad1 Kh8 32. Ng3 h6 33. Qh3 Nf4 34. Qxh6+ Kg8 35. Qxf4 f6 36. exf6 Rxf6 37. Qd6 Qxd6 38. Rxd6 Rxf3 39. Rxd7 Rxa3 40. Rxe6 Ra4 41. Rxg6+ Kh8 42. Nf5 Rxb4 43. Rh6+ Kg8 44. Rg6+ Kh8 45. Rgg7 Rg8 46. Rxg8+ Kxg8 47. Rg7+ Kf8 48. c6 Rc4 49. c7 b4 50. Rh7 Kg8 51. Rd7 b3 52. Rd8+ Kf7 53. c8=Q Rxc8 54. Rxc8 Kf6 55. Rb8 Kxf5 56. Rxb3 Kf4 57. Rg3 Kf5 58. h4 Kf6 59. h5 Kf5 60. h6 Kf4 61. h7 Kf5 62. h8=Q Kf4 63. Qh4+ Kf5 64. Rg5+ Kf6 65. Qh6+ Kf7 66. Rg7+ Kf8 67. Qh8# 1-0",
    B: "1540",
  },
  {
    W: "2041",
    T: "300+0",
    P: "1. e4 e5 2. Ne2 Nc6 3. Ng3 d6 4. c3 Be6 5. Be2 Qd7 6. O-O g6 7. d3 O-O-O 8. Na3 h5 9. Nc2 h4 10. Nh1 Bh6 11. f4 exf4 12. Bxf4 Bxf4 13. Rxf4 Nh6 14. Ne3 f5 15. exf5 Nxf5 16. Nxf5 Bxf5 17. Bg4 Rdf8 18. Nf2 Ne5 19. Bxf5 gxf5 20. d4 Nc6 21. Qh5 Rxh5 22. Rf1 0-1",
    B: "2057",
  },
  {
    W: "1749",
    T: "300+0",
    P: "1. d4 d5 2. e3 e6 3. Bd3 c6 4. f4 Nf6 5. Nd2 Be7 6. Ngf3 h6 7. O-O Bd6 8. Ne5 Bxe5 9. fxe5 Nh7 10. Qg4 O-O 11. e4 dxe4 12. Nxe4 Qxd4+ 13. Kh1 Qxe5 14. Bxh6 Kh8 15. Bf4 Qd5 16. Qh3 f5 17. Ng5 1-0",
    B: "1817",
  },
  {
    W: "1912",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Nxc6 Qf6 6. f3 dxc6 7. Nc3 Ne7 8. Bd2 h5 9. Qe2 h4 10. O-O-O h3 11. g3 b6 12. g4 g5 13. e5 Qg6 14. Qd3 Qxd3 15. Bxd3 Rg8 16. Ne4 Bd4 17. Nf6+ Kf8 18. Nxg8 Kxg8 19. Rhe1 Ng6 20. Bxg6 fxg6 21. Bxg5 Be6 22. Rxd4 1-0",
    B: "1988",
  },
  {
    W: "2060",
    T: "300+3",
    P: "1. c4 Nf6 2. Nc3 e6 3. d4 Bb4 4. Qc2 d5 5. e3 c5 6. Nf3 cxd4 7. exd4 O-O 8. a3 Bxc3+ 9. bxc3 Qc7 10. Ne5 Nc6 11. Nxc6 bxc6 12. c5 e5 13. Bd3 exd4 14. cxd4 Re8+ 15. Kf1 Rb8 16. h3 Ne4 17. Rb1 Rxb1 18. Qxb1 Bf5 19. Be3 Ng3+ 20. fxg3 Bxd3+ 21. Qxd3 Qxg3 0-1",
    B: "2442",
  },
  {
    W: "1286",
    T: "120+1",
    P: "1. e4 c5 2. f4 Nc6 3. Nf3 d6 4. Bb5 a6 5. Bxc6+ bxc6 6. O-O c4 7. d4 Qb6 8. c3 Bg4 9. f5 h5 10. h3 Bxf3 11. Qxf3 Nf6 12. Bg5 e5 13. Rd1 Be7 14. Kh1 O-O-O 15. dxe5 dxe5 16. Rxd8+ Bxd8 17. Bxf6 Bxf6 18. Na3 Qxb2 19. Rd1 Qxa3 20. Qf2 Rf8 21. Qb6 Qa4 22. Rb1 Kd7 23. Qb7+ Kd6 24. Kh2 Bg5 25. Kg3 h4+ 26. Kg4 Bf4 27. Rb6 Qd1+ 28. Kxh4 Rh8# 0-1",
    B: "1276",
  },
  {
    W: "1978",
    T: "180+3",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bc4 e6 7. O-O Be7 8. Re1 O-O 9. Bg5 Nc6 10. Nf3 Re8 11. Bb3 Qc7 12. Qe2 Na5 13. Rac1 Nxb3 14. cxb3 Qb8 15. Na4 b5 16. Nc3 Bb7 17. b4 h6 18. Bh4 Qa7 19. e5 dxe5 20. Nxe5 Qd4 21. Bxf6 Bxf6 22. a3 Rad8 23. Rcd1 Qxe5 24. Rxd8 Rxd8 25. Qxe5 Bxe5 26. Rxe5 Rd2 27. Re2 Rxe2 28. Nxe2 e5 29. f3 f6 30. Kf2 Kf7 31. Ke3 Ke6 32. Nc1 Kd5 33. b3 f5 34. g3 Kd6 35. Nd3 g5 36. f4 e4 37. Ne5 Bd5 38. a4 Bxb3 39. a5 gxf4+ 40. gxf4 Be6 41. Kd4 Bb3 42. h4 Be6 43. Ng6 Bf7 44. Ne5 Bb3 45. Ng6 Ke6 46. Ne5 Kf6 47. Nd7+ Kg6 48. Nc5 Bc4 49. Nxa6 Be6 50. Nc5 Bc8 51. a6 Bxa6 52. Nxa6 Kh5 53. Nc7 Kg4 54. Nxb5 Kxf4 55. Nc3 Kf3 56. b5 e3 57. Kd3 f4 58. b6 Kf2 59. b7 f3 60. b8=Q e2 61. Qh2+ Ke1 62. Ne4 Kd1 63. Nc3+ Kc1 64. Nxe2+ fxe2 65. Qxe2 h5 66. Qxh5 Kb1 67. Qe2 Kc1 68. Qc2# 1-0",
    B: "2220",
  },
  {
    W: "1742",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 a6 3. Nc3 a5 4. d4 a4 5. d5 a3 6. dxe6 dxe6 7. Qxd8+ Kxd8 8. Be3 axb2 9. Rb1 Ba3 10. Kd2 b5 11. Bxb5 c6 12. Bc4 Na6 13. Bxa6 Bxa6 14. Rhd1 Be2 15. Kxe2+ Ke7 16. Rd2 f5 17. exf5 exf5 18. Nd4 Kf6 19. Nxc6 Ne7 20. Nxe7 Kxe7 21. Nd5+ Kf7 22. Nb6 Rab8 23. Nc4 Rhe8 24. Nxa3 Ra8 25. Nc4 Rxa2 26. Rxb2 Rxb2 27. Nxb2 Rxe3+ 28. Kxe3 Kg6 29. Kf4 Kh5 30. g3 g5+ 31. Kxf5 h6 32. g4+ Kh4 33. Rd6 h5 34. Rg6 Kh3 35. gxh5 g4 36. h6 g3 37. fxg3 Kxh2 38. h7 Kg2 39. h8=Q Kf3 40. Qh5+ Kg2 41. Kg4 Kg1 42. Qh3 Kf2 43. Rf6+ Kg1 44. Qf1+ Kh2 45. Rf2# 1-0",
    B: "1568",
  },
  {
    W: "1867",
    T: "60+0",
    P: "1. e3 d6 2. d4 e5 3. Be2 exd4 4. exd4 Nf6 5. c4 d5 6. cxd5 Nxd5 7. Nc3 Nc6 8. Nxd5 Qxd5 9. Bf3 Qxd4 10. Qxd4 Nxd4 11. Be4 f5 12. Bd3 Bb4+ 13. Bd2 Bxd2+ 14. Kxd2 O-O 15. Ne2 Nxe2 16. Bxe2 Bd7 17. Bc4+ Kh8 18. Rad1 Bc6 19. f3 Rae8 20. Kc2 Re3 21. Rhe1 f4 22. Rxe3 fxe3 23. Re1 Re8 24. Kd3 b5 25. Bf7 Re7 26. Bh5 g6 27. Bg4 h5 28. Bh3 e2 29. Rxe2 Rd7+ 30. Bxd7 Bxd7 31. Re7 Bf5+ 32. Kd4 c5+ 33. Kxc5 a6 34. Kb6 Bd3 35. Kxa6 b4+ 36. Ka5 b3 37. axb3 Bc2 38. Re2 Bxb3 39. Re8+ Kg7 40. Re3 Bd1 41. Rd3 Be2 42. Re3 Bf1 43. g3 Bg2 44. f4 Bd5 45. h4 Bc4 46. Re5 Kf6 47. b4 Be2 48. b5 Bc4 49. b6 Bd5 50. Ka6 Bb7+ 51. Kxb7 g5 52. Kc7 gxf4 53. b7 Kxe5 54. gxf4+ Kxf4 55. b8=Q Kg4 56. Qg8+ Kxh4 57. Qe6 Kg3 58. Qe1+ Kg2 59. Qh4 Kf3 60. Qxh5+ Kf4 61. Kd6 1-0",
    B: "1834",
  },
  {
    W: "2340",
    T: "60+0",
    P: "1. Nf3 Nf6 2. g3 e6 3. Bg2 d5 4. O-O c5 5. d3 Be7 6. Nbd2 O-O 7. e4 Nc6 8. e5 Nd7 9. Re1 d4 10. h4 Nb6 11. a4 Nd5 12. Nf1 f6 13. b3 fxe5 14. Nxe5 Nxe5 15. Rxe5 Bd6 16. Re1 Nf6 17. f4 Rb8 18. Nd2 b6 19. Nf3 Bb7 20. Bd2 Nd5 21. Qe2 Re8 22. Ng5 Nc7 23. Qh5 h6 24. Qf7+ Kh8 25. Nxe6 Nxe6 26. Rxe6 Rf8 27. Qxb7 Rxb7 28. Bxb7 g5 29. Be4 gxf4 30. Rxh6+ Kg7 31. Rg6+ Kf7 32. Rf1 Ke7 33. Rg7+ Ke6 34. Rg6+ Kd7 35. Rg7+ Kc8 36. Bxf4 Bxf4 37. Rxf4 Rxf4 38. gxf4 Qxh4 39. Rg8+ Kd7 40. Rg7+ Ke6 41. f5+ Kf6 42. Rg6+ Ke7 43. Kg2 Qf4 44. f6+ Kf7 45. a5 bxa5 46. Rg3 Qd2+ 47. Kh3 Qxc2 48. Rf3 Qc3 49. Bd5+ Kf8 50. Bc4 a4 51. bxa4 a5 52. Kg4 Qb4 53. f7 Qb6 54. Rf5 Qg6+ 55. Rg5 Qxf7 56. Rf5 Qxf5+ 57. Kxf5 Ke7 58. Ke5 Kd7 59. Kd5 Kc7 60. Kxc5 Kb7 61. Kxd4 Ka7 62. Kd5 Kb7 63. Kc5 Ka7 64. Kb5 Kb7 65. d4 Ka7 66. d5 Kb8 67. d6 Ka8 68. Kxa5 Kb8 69. Kb5 Kb7 70. Bd5+ Ka7 71. a5 Kb8 72. a6 Ka7 1/2-1/2",
    B: "2336",
  },
  {
    W: "1615",
    T: "60+0",
    P: "1. e4 c5 2. f4 Nc6 3. Nf3 d6 4. Ng5 h6 5. Nxf7 Kxf7 6. Bc4+ Be6 7. O-O Bxc4 8. d3 Bb5 9. f5 Nf6 10. e5 dxe5 11. d4 Nxd4 12. c4 Bxc4 13. b3 Bxf1 14. Qxf1 Qb6 15. Qc4+ Ke8 16. Nc3 Rd8 17. Nd5 Nxd5 18. Be3 a6 19. Re1 Nxe3 20. Rxe3 g5 21. Rxe5 Bg7 22. Rxe7+ Kxe7 23. Qe6+ Nxe6 24. g4 Rd2 25. f6+ Bxf6 26. Kf1 Qd6 27. Ke1 Qd3 28. h4 Qe2# 0-1",
    B: "1739",
  },
  {
    W: "1509",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d4 e6 5. Nf3 Be7 6. Be2 Nf6 7. O-O Bd7 8. Bg5 Nc6 9. Bxf6 Bxf6 10. Ne5 g6 11. Nxc6 Bxc6 12. d5 exd5 13. Bb5 Bd7 14. Re1+ Be7 15. Bxd7+ Qxd7 16. Qxd5 Qc8 17. Rxe7+ Kxe7 18. Re1+ Kf8 19. Ne4 Qe6 20. Qxe6 fxe6 21. Nf6 Rd8 22. g3 Rd2 23. Kg2 Rxc2 24. Kh3 Rxb2 25. Nd7+ Ke7 26. Nb8 Rxf2 0-1",
    B: "1792",
  },
  {
    W: "1923",
    T: "0+1",
    P: "1. d4 b6 2. d5 Bb7 3. c4 f5 4. Nc3 e6 5. f3 Bb4 6. e4 fxe4 7. fxe4 Bxc3+ 8. bxc3 Nf6 9. Bd3 exd5 10. e5 Ne4 11. cxd5 Bxd5 12. Nf3 Nxc3 13. Qc2 Ne4 14. Bxe4 Bxe4 15. Qxe4 Nc6 16. O-O O-O 17. Bg5 Qe8 18. Rae1 Ne7 19. Qc2 Rc8 20. Bxe7 Qxe7 21. Nd4 Rxf1+ 22. Rxf1 Qxe5 23. Qd3 c5 24. Nf5 d5 25. Re1 Qf6 26. Rf1 d4 27. Qe3 Rf8 28. Qe4 Qg5 29. Qd5+ Kh8 30. Ne7 Rxf1+ 31. Kxf1 Qe3 32. Qd8# 1-0",
    B: "2029",
  },
  {
    W: "1571",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. d4 Qd8 4. Nf3 Bg4 5. c4 Nf6 6. Nbd2 e5 7. Bd3 e4 8. Nxe4 Bxf3 9. Qxf3 Qxd4 10. Nxf6+ gxf6 11. Qxb7 Bc5 12. O-O Qg4 13. Qxa8 Rg8 14. Qxb8+ Ke7 15. g3 Bd6 16. Qxa7 Rg7 17. Qa6 Kd7 18. Qb5+ c6 19. Qb7+ Ke6 20. Re1+ Be5 21. Qc8+ Ke7 22. Qxg4 Rxg4 23. Bxh7 Kd6 24. Bf4 Bxf4 25. Rad1+ Kc5 26. b4+ Kxb4 27. Re4 Kc5 28. Rxf4 Rxf4 29. gxf4 Kxc4 30. Rd7 c5 31. Rxf7 Kd5 32. Rxf6 c4 33. Rb6 c3 34. Rb2 Kc4 35. Rc2 Kd4 36. Rxc3 Kxc3 37. f5 Kb4 38. f6 1-0",
    B: "1568",
  },
  {
    W: "1901",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 e5 7. Nf3 Be7 8. Bc4 O-O 9. O-O Qc7 10. Bb3 b5 11. Re1 Bb7 12. a4 b4 13. Nd5 Nxd5 14. exd5 Kh8 15. c4 bxc3 16. bxc3 f5 17. Bg5 Nd7 18. Bxe7 Rf7 19. Bh4 Nc5 20. Ba2 h6 21. Qc2 g5 22. Bxg5 hxg5 23. Nxg5 Rf6 24. Ne6 Nxe6 25. dxe6 d5 26. Rab1 Rc8 27. Qe2 Rxe6 28. Qh5+ Qh7 29. Qxh7+ Kxh7 30. Rxb7+ Kg6 31. Bxd5 Rd6 32. Rxe5 Rxc3 33. h3 Kg5 34. Bf3 Kf4 35. Rd5 Rxd5 36. Bxd5 Rc1+ 37. Kh2 Rd1 38. Rb4+ Kg5 39. Bb7 Rd7 40. Rb6 Rd5 41. Bxa6 Ra5 42. Be2 Rxa4 43. Rb3 Rf4 44. Rg3+ Rg4 45. Rf3 Rxg2+ 46. Kxg2 Kf6 47. Rxf5+ Kxf5 48. h4 Kg6 49. h5+ Kh7 50. f4 Kh6 51. Kg3 Kg7 52. Kg4 1-0",
    B: "1952",
  },
  {
    W: "2102",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. d4 Nf6 5. dxe5 Nxe4 6. Qd5 Qe7 7. Qxe4 f5 8. Qxf5 d5 9. Qg6+ Qf7 10. Qxf7+ Kxf7 11. Bxd5+ Ke8 12. Nc3 Nb4 13. Bb3 a5 14. a3 a4 15. axb4 axb3 16. Rxa8 Kd7 17. cxb3 Bxb4 18. O-O g5 19. Rd1+ Kc6 20. Nd4+ Kc5 21. e6 Re8 22. Re1 Kxd4 23. e7 Bd7 24. Rxe8 Bxe8 25. Be3+ Kd3 26. Rd1+ Kc2 27. Rd8 Bxe7 28. Rxe8 Bb4 29. Nd5 Kxb2 30. Nxb4 Kxb3 31. Re7 c5 32. Rxb7 cxb4 33. Bc5 Kc4 34. Rxb4+ Kxc5 35. Ra4 Kd5 36. Ra6 Ke5 37. Rxh6 Kf5 38. Kf1 Kg4 39. Ke2 1-0",
    B: "1987",
  },
  {
    W: "1331",
    T: "60+0",
    P: "1. e4 b6 2. Nf3 Bb7 3. Bb5 Bxe4 4. Nc3 Bb7 5. d4 e6 6. Bf4 a6 7. Ba4 c5 8. Bb3 cxd4 9. Nxd4 Bxg2 10. Rg1 Bb7 11. Qg4 h6 12. O-O-O Nf6 13. Qg3 Nc6 14. Nxc6 Bxc6 15. Bc7 Qc8 16. Rd2 Nh5 17. Qh4 Qxc7 18. Qxh5 a5 19. Rgd1 a4 20. Bc4 b5 21. Bd3 Be7 22. Be4 O-O 23. Bxc6 dxc6 24. Rd7 Qe5 25. Qxe5 Rfe8 26. Rg1 Bf6 27. Qxf6 1-0",
    B: "1297",
  },
  {
    W: "1836",
    T: "60+0",
    P: "1. e3 e5 2. e4 d5 3. f3 dxe4 4. fxe4 Nc6 5. Bb5 Bd6 6. Bxc6+ bxc6 7. d3 Nf6 8. Ne2 O-O 9. Bg5 Bb7 10. O-O Be7 11. Nec3 a5 12. Nd2 Ba6 13. Nf3 Nd7 14. Qd2 Bxg5 15. Qxg5 Qxg5 16. Nxg5 h6 17. Nf3 Rad8 18. Ne2 c5 19. Ng3 c6 20. Nf5 Kh7 21. Nd6 Nf6 22. Nf5 c4 23. Rfd1 cxd3 24. c3 c5 25. b3 c4 26. b4 axb4 27. cxb4 Rb8 28. a3 Rfd8 29. Nd2 c3 30. Nb3 c2 31. Rdc1 Rbc8 32. a4 d2 33. Nxd2 Rxd2 34. b5 Bb7 35. a5 Bxe4 36. Nd6 Rd8 37. Nxe4 Nxe4 38. h3 Rd1+ 39. Rxd1 Rxd1+ 40. Rxd1 cxd1=Q+ 41. Kh2 Qd5 42. b6 Qxa5 43. b7 Qb5 44. Kg1 Qxb7 45. Kf1 Nd2+ 46. Ke2 Qb2 47. Kd3 Qd4+ 48. Ke2 Nb3 49. Kf3 Nd2+ 50. Ke2 Ne4 51. Kf3 Qd2 0-1",
    B: "1875",
  },
  {
    W: "1654",
    T: "60+0",
    P: "1. d4 c6 2. c4 d5 3. c5 Nf6 4. b4 Bf5 5. Nc3 a5 6. bxa5 Qxa5 7. Bd2 Qd8 8. e3 e6 9. Nf3 Be7 10. Ne5 Nbd7 11. Nf3 O-O 12. Be2 b6 13. O-O bxc5 14. dxc5 Nxc5 15. Rc1 Nce4 16. Nxe4 Nxe4 17. Ne1 Nxd2 18. Qxd2 c5 19. f3 c4 20. e4 Bg6 21. Qc3 Rxa2 22. exd5 Rxe2 23. dxe6 Bc5+ 24. Kh1 Qh4 25. exf7+ Bxf7 26. g3 Qxh2# 0-1",
    B: "1641",
  },
  {
    W: "2503",
    T: "60+0",
    P: "1. g3 d5 2. Bg2 c6 3. c4 Nf6 4. Nf3 Bg4 5. O-O e6 6. Ne5 Bf5 7. Qb3 Qb6 8. cxd5 exd5 9. Nc3 Qxb3 10. axb3 Nbd7 11. Nxd7 Nxd7 12. b4 Bxb4 13. d3 O-O 14. e4 dxe4 15. dxe4 Bxc3 16. exf5 Bf6 17. Rd1 Nb6 18. Be3 Nc4 19. Bd4 a5 20. Bxf6 gxf6 21. b3 Ne5 22. f4 Ng4 23. Rd7 Rfb8 24. Bf3 Ne3 25. Kf2 Nc2 26. Ra4 b5 27. Ra2 Nb4 28. Re2 a4 29. bxa4 bxa4 30. Ree7 Rf8 31. Ra7 a3 32. Rxa8 Rxa8 33. Re1 a2 34. Ra1 Nc2 35. Bxc6 Nxa1 36. Bxa8 Nb3 37. Bd5 a1=Q 38. Bxb3 Qb2+ 39. Kf3 Qxb3+ 40. Kg4 Qd1+ 41. Kh3 Qf1+ 42. Kh4 Qf2 43. Kh3 Kg7 44. Kh4 Qxh2+ 45. Kg4 Qe2+ 46. Kh3 h5 47. g4 Qxg4+ 48. Kh2 h4 49. Kh1 h3 0-1",
    B: "2324",
  },
  {
    W: "1712",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e6 5. Nxc6 bxc6 6. Bd3 Nf6 7. c3 d5 8. exd5 exd5 9. Bc2 Bc5 10. O-O O-O 11. Be3 Bxe3 12. fxe3 Qb6 13. Re1 Qxb2 14. Nd2 Qxc3 15. Rc1 Qe5 16. e4 Ng4 17. g3 Qh5 18. h4 Qe5 19. exd5 Qxg3+ 20. Kf1 Qf2# 0-1",
    B: "1691",
  },
  {
    W: "1408",
    T: "60+0",
    P: "1. e4 c5 2. f4 d6 3. Nc3 Nc6 4. Nd5 e6 5. Bb5 exd5 6. Bxc6+ bxc6 7. exd5 cxd5 8. Qe2+ Ne7 9. d3 g6 10. Nf3 Bg7 11. d4 O-O 12. dxc5 dxc5 13. Be3 Re8 14. Bxc5 Nf5 15. Ne5 Bxe5 16. O-O-O Bxb2+ 17. Kxb2 Rxe2 18. Rhe1 Rxe1 19. Rxe1 Qa5 20. Kc1 Qxc5 21. Re5 Qg1+ 22. Kb2 Qxg2 23. Re8+ Kg7 24. Rxc8 Rxc8 0-1",
    B: "1428",
  },
  {
    W: "1773",
    T: "60+0",
    P: "1. g3 d5 2. Bg2 Nf6 3. e3 e5 4. Ne2 Be6 5. O-O Bd6 6. d4 Qe7 7. dxe5 Bxe5 8. f4 Bd6 9. b3 Nc6 10. Bb2 Qd7 11. Bxf6 gxf6 12. Nd2 Bh3 13. Bxh3 Qxh3 14. Kf2 O-O-O 15. Rh1 h5 16. Qf1 h4 17. Qxh3+ Kb8 18. Nf3 1-0",
    B: "1764",
  },
  {
    W: "883",
    T: "300+3",
    P: "1. c4 e5 2. d4 exd4 3. Qxd4 d6 4. Nc3 Nc6 5. Qe4+ Be7 6. Qg4 Bxg4 0-1",
    B: "944",
  },
  {
    W: "1482",
    T: "300+3",
    P: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Nf3 e6 5. a3 c5 6. c3 Nc6 7. Bb5 a6 8. Ba4 b5 9. Bc2 Bxc2 10. Qxc2 Qb6 11. Be3 cxd4 12. cxd4 Nge7 13. O-O Ng6 14. h3 Be7 15. Nfd2 O-O 16. Nc3 Rac8 17. Qd3 Na5 18. Rac1 Nc4 19. Nxc4 bxc4 20. Qd2 Rb8 21. Rc2 Rb7 22. f4 Nh4 23. g4 Rfb8 24. Qf2 Bd8 25. g5 Nf5 26. h4 Qc6 27. Bc1 Rb3 28. h5 Bb6 29. Rd2 Ba5 30. Rc2 Qb6 31. Rd1 Bxc3 32. Rxc3 Rxc3 33. bxc3 Qb3 34. Bb2 Qxd1+ 35. Kg2 Qg4+ 36. Kf1 Ng3+ 37. Ke1 Ne4 38. Qc2 1-0",
    B: "1445",
  },
  {
    W: "1237",
    T: "300+3",
    P: "1. d4 f5 2. c4 e6 3. Nc3 Bb4 4. Bg5 Ne7 5. Nf3 h6 6. Bxe7 Qxe7 7. Qd2 b6 8. O-O-O Bb7 9. a3 Bxc3 10. Qc2 Ba5 11. b4 Bxb4 12. axb4 Qxb4 13. Ne5 Be4 14. Qb2 Qxb2+ 15. Kxb2 a5 16. f3 Bc6 17. e4 fxe4 18. fxe4 Bxe4 19. Bd3 Bxd3 20. Rd2 Bf5 21. Rf2 O-O 22. Ng6 Rf6 23. Nh4 Be4 24. Rxf6 gxf6 0-1",
    B: "1281",
  },
  {
    W: "1727",
    T: "300+3",
    P: "1. d4 d5 2. Nc3 Nf6 3. Bf4 Nc6 4. e3 Bf5 5. Nb5 e5 6. dxe5 Nh5 7. Qxh5 g6 8. Qd1 Bb4+ 9. c3 Ba5 10. Nd4 Nxd4 11. Qxd4 O-O 12. Bh6 Re8 13. e6 f6 14. Bd3 Bxe6 15. h4 Bb6 16. Qf4 f5 17. h5 c5 18. hxg6 hxg6 19. Bg5 Qd7 20. Bf6 Qc6 21. Qh6 Qd7 22. Qh8+ Kf7 23. Qg7# 1-0",
    B: "1695",
  },
  {
    W: "1569",
    T: "300+3",
    P: "1. c4 c5 2. Nc3 g6 3. Nf3 Bg7 4. g3 Nf6 5. Bg2 O-O 6. O-O d6 7. d3 Nbd7 8. Bg5 a6 9. a3 Rb8 10. b4 cxb4 11. axb4 Ne8 12. Rb1 Bxc3 13. d4 Qb6 14. c5 dxc5 15. bxc5 Qa5 16. Bxe7 Nef6 17. Bxf8 Kxf8 18. e3 b6 19. c6 Nc5 20. dxc5 Qxc5 21. Qd8+ Kg7 22. c7 Ra8 23. Nd4 Ra7 24. Rfc1 Rxc7 25. Ne2 Rd7 26. Qxb6 Qxb6 27. Rxb6 Ba5 28. Rbb1 Nd5 29. Bxd5 Rxd5 30. Rxc8 1-0",
    B: "1546",
  },
  {
    W: "1807",
    T: "300+3",
    P: "1. d3 d5 2. e3 e5 3. Be2 Nf6 4. Nd2 Bd6 5. g4 Be6 6. e4 dxe4 7. dxe4 Nc6 8. c3 Qd7 9. h3 h6 10. Ngf3 O-O-O 11. a4 Bc5 12. b4 Bd6 13. a5 Ne7 14. b5 Ng6 15. b6 cxb6 16. axb6 a6 17. Bxa6 bxa6 18. Rxa6 Qc6 19. Bb2 Nxe4 20. Qa1 Nxd2 21. Ra8+ Kd7 22. Ra7+ Ke8 23. Nxd2 Qxh1+ 24. Ke2 Qxa1 25. Bxa1 Rb8 26. b7 Nf4+ 27. Kd1 Kd7 28. c4 Bc5 29. Ra8 Rxa8 30. bxa8=Q Rxa8 31. Bxe5 Nxh3 32. Bxg7 Nxf2+ 33. Ke2 Nxg4 34. Ne4 Be7 35. c5 Ra4 36. Kf3 Bd5 37. Kxg4 Rxe4+ 38. Kf5 Bg5 39. Bf8 Rf4+ 40. Ke5 f6+ 41. Kxd5 Rf5+ 42. Ke4 Re5+ 43. Kd4 Kc6 44. Bd6 Rd5+ 45. Kc4 Rxd6 46. cxd6 Kxd6 47. Kd4 Ke6 48. Ke4 f5+ 49. Kf3 Ke5 50. Kg3 h5 51. Kf3 h4 52. Kg2 Ke4 53. Kh3 Kf4 54. Kg2 Kg4 55. Kh2 f4 56. Kg2 f3+ 57. Kh2 Bf4+ 58. Kg1 h3 59. Kh1 Kg3 60. Kg1 f2+ 61. Kf1 h2 62. Ke2 h1=Q 63. Kd3 f1=Q+ 64. Kd4 Qd1+ 0-1",
    B: "1957",
  },
  {
    W: "1079",
    T: "300+3",
    P: "1. d4 d5 2. e3 Nc6 3. Nf3 Nf6 4. a3 e6 5. b4 b5 6. c3 g6 7. Bd3 Bh6 8. Nbd2 O-O 9. O-O Bb7 10. Ne5 Nxe5 11. dxe5 Ng4 12. Qxg4 f5 13. Qh3 Qg5 14. Nf3 Qh5 15. Nh4 g5 16. Nf3 Qxh3 17. gxh3 Rf7 18. Kh1 d4 19. exd4 Bxf3+ 20. Kg1 g4 21. h4 Bxc1 22. Raxc1 Kh8 23. Bxb5 Rg7 24. Bd7 f4 25. Bxe6 g3 26. fxg3 fxg3 27. Rxf3 gxh2+ 28. Kxh2 Re8 29. d5 Reg8 30. Bxg8 Kxg8 31. Rg3 Rxg3 32. Kxg3 1-0",
    B: "1047",
  },
  {
    W: "1375",
    T: "300+3",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. dxc5 Bxc5 5. Nf3 Qb6 6. Be3 Qxb2 7. Bxc5 Qxa1 8. Bb5+ Bd7 9. Bd3 Qxa2 10. O-O Qa5 11. Be3 f6 12. exf6 Nxf6 13. Bd4 O-O 14. Bxf6 Rxf6 15. Ng5 g6 16. Qg4 Nc6 17. Nxh7 Kxh7 18. Qh4+ Kg7 19. Bxg6 Rxg6 20. Qe7+ 0-1",
    B: "1303",
  },
  {
    W: "1654",
    T: "300+3",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bc4 b5 7. Bb3 Bb7 8. f3 b4 9. Nce2 Qb6 10. Be3 Nc6 11. Nf5 Qc7 12. O-O e6 13. Nfd4 Be7 14. Kh1 O-O 15. g4 Rad8 16. Qe1 d5 17. Nxc6 Bxc6 18. exd5 Nxd5 19. Bf2 Nf4 20. Nxf4 Qxf4 21. Bg3 Qxg4 22. Rf2 Bxf3+ 23. Kg1 Bc5 24. Rd1 Rxd1 0-1",
    B: "1586",
  },
  {
    W: "1217",
    T: "300+3",
    P: "1. d4 e6 2. Bf4 Nf6 3. c3 Bd6 4. Nh3 Qe7 5. e3 O-O 6. Nd2 b6 7. Be2 h6 8. O-O Bb7 9. Bf3 Bxf3 10. Qxf3 Bxf4 11. Qxa8 Nc6 12. Qb7 Rb8 13. Qa6 Bd6 14. Nf3 Nh5 15. Ne5 Nxe5 16. dxe5 Bxe5 17. Qxa7 Rc8 18. Rad1 d5 19. Qa4 Qd6 20. g3 Rf8 21. Nf4 Nxf4 22. exf4 Bf6 23. a3 g5 24. Qb4 Qc6 25. Rfe1 gxf4 26. Qxf4 Bg5 27. Qe5 f6 28. Qxe6+ Qxe6 29. Rxe6 Kf7 30. Rde1 Rd8 31. Re7+ Kg6 32. Rxc7 1-0",
    B: "1221",
  },
  {
    W: "1247",
    T: "900+15",
    P: "1. d4 d5 2. f4 Nc6 3. Nf3 Nf6 4. Ne5 Nxe5 5. fxe5 Ne4 6. Be3 e6 7. Nc3 a6 8. Nxe4 dxe4 9. c4 Bb4+ 10. Kf2 O-O 11. d5 exd5 12. cxd5 Re8 13. Bf4 f6 14. e6 Bc5+ 15. e3 Bd6 16. Bxd6 Qxd6 17. g3 Rd8 18. Bc4 b5 19. Bb3 Bb7 20. Rc1 a5 0-1",
    B: "1314",
  },
  {
    W: "1731",
    T: "900+15",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. Bc4 b6 5. d3 Bb7 6. Nf3 e6 7. O-O c6 8. a4 Nf6 9. Bg5 Be7 10. d4 O-O 11. Be2 h6 12. Bf4 Nd5 13. Nxd5 Qxd5 14. c3 Qd8 15. Ne5 Bd6 16. Bh5 Bxe5 17. Bxe5 Nd7 18. Bf4 Nf6 19. Be5 Nxh5 20. Qxh5 Qd5 21. Qg4 f6 22. Bf4 c5 23. Bxh6 Rf7 24. Rfe1 g5 25. Rxe6 Bc8 26. Re8+ Kh7 27. Rxc8 Rxc8 28. Qxc8 Kxh6 29. Re1 Qa2 30. g4 Kg7 31. Re8 f5 32. Rg8+ Kf6 33. Qxf5+ Ke7 34. Qxg5+ Kd7 35. Rd8+ Kc7 36. dxc5 Qb1+ 37. Kg2 Qe4+ 38. Kg3 Qf3+ 39. Kh4 0-1",
    B: "1690",
  },
  {
    W: "1776",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O Nf6 5. d4 Nxd4 6. Nxe5 Nxe4 7. Nxf7 Qf6 8. Nxh8 b5 9. Bd5 1-0",
    B: "1753",
  },
  {
    W: "1552",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bc5 4. Bc4 h6 5. O-O Nf6 6. d3 d6 7. Bb5 Bd7 8. Na4 Bb6 9. c3 Bg4 10. h3 Bh5 11. g4 Nxg4 12. hxg4 Bxg4 13. Kg2 Qd7 14. Rh1 a6 15. Nxb6 cxb6 16. Bxc6 bxc6 17. d4 O-O-O 18. Be3 Rhe8 19. d5 c5 20. Qd3 b5 21. Rh4 h5 22. Bg5 f6 23. Be3 g5 24. Rh2 Bxf3+ 25. Kxf3 Qg4# 0-1",
    B: "1588",
  },
  {
    W: "1878",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 Nxd4 4. Nxd4 exd4 5. Qxd4 d6 6. e5 Bf5 7. exd6 cxd6 8. Bb5+ Bd7 9. Nc3 a6 10. Bxd7+ Qxd7 11. Nd5 Qe6+ 12. Be3 Rc8 13. c3 Nf6 14. Nb6 Rc7 15. Qa4+ Nd7 16. O-O Be7 17. Rfe1 O-O 18. Bg5 Ne5 19. Bxe7 Qxe7 20. Nd5 Qd7 21. Qxd7 Rxd7 22. Rad1 Re8 23. f4 Nf3+ 24. gxf3 Rxe1+ 25. Rxe1 f6 26. Ne7+ Kf7 27. Nd5 Kg6 28. Kf2 Kh5 29. f5 Rd8 30. f4 Kg4 31. Rg1+ Kxf5 32. Rxg7 h5 33. h4 Re8 34. Kf3 b5 35. Ne3+ Ke6 36. f5+ Ke5 37. Rh7 d5 38. Rxh5 d4 39. Ng4+ Kd6 40. cxd4 1-0",
    B: "1796",
  },
  {
    W: "1249",
    T: "900+15",
    P: "1. e4 c5 2. Nf3 d6 3. d3 Nc6 4. Bf4 Nf6 5. e5 dxe5 6. Bxe5 Nd4 7. Nxd4 cxd4 8. c3 dxc3 9. Bxc3 Bf5 10. Qa4+ Qd7 11. Qxd7+ Nxd7 12. Nd2 Nc5 13. d4 Nd3+ 14. Bxd3 Bxd3 15. b4 f6 16. a4 e5 17. dxe5 fxe5 18. Nf3 e4 19. Ne5 Rd8 20. O-O-O a5 21. bxa5 Ba3+ 22. Bb2 Rc8+ 23. Kd2 Bxb2 24. Nxd3 O-O 25. Nxb2 Rfd8+ 26. Ke3 Rc2 27. Rxd8+ Kf7 28. Rb1 Rc3+ 29. Kxe4 Ke7 30. Rb8 Rb3 0-1",
    B: "1004",
  },
  {
    W: "1810",
    T: "60+0",
    P: "1. d4 d5 2. Nc3 Nc6 3. Bd2 e5 4. e3 exd4 5. e4 Qe7 6. Nxd5 Qxe4+ 7. Qe2 Qxe2+ 8. Bxe2 Kd7 9. Bg4+ f5 10. Bxf5+ Kd6 11. Nf4 Bxf5 12. O-O-O Nf6 13. Be3 Ke7 14. Bd2 Kf7 15. Nge2 Bb4 16. c3 dxc3 17. Nxc3 Rhd8 18. a3 Bxc3 19. Bxc3 Rxd1+ 20. Rxd1 Rd8 21. Re1 Nd5 22. Nxd5 Rxd5 23. h3 Bg6 24. g4 Nd4 25. f4 Nb3# 0-1",
    B: "1868",
  },
  {
    W: "1885",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O d6 5. c3 Bg4 6. d4 exd4 7. cxd4 Nxd4 8. Nbd2 Qd7 9. h3 Bh5 10. a3 Nf6 11. Re1 O-O 12. b4 Bb6 13. Bb2 Rfe8 14. Bxd4 Bxd4 15. Rb1 c5 16. Qb3 a6 17. Ng5 h6 18. Ngf3 Nxe4 19. Nxe4 d5 20. Bxd5 Rad8 21. Bc4 Bxf3 22. gxf3 Re5 23. Kh2 Rh5 24. Bxf7+ Qxf7 25. Qxf7+ Kxf7 26. Kg3 cxb4 27. Rxb4 b6 28. Rc4 Ra5 29. Rc7+ Kg6 30. Rc6+ Kh7 31. Nd6 Be5+ 32. Kg2 Bxd6 33. Rd1 Be7 34. Re1 Bxa3 35. Ree6 Rg5+ 36. Kf1 Bc5 37. Ke2 Rgd5 38. f4 Rd2+ 39. Kf3 Rxf2+ 40. Kg4 Rfd2 41. h4 a5 42. f5 a4 43. f6 Rg2+ 44. Kh3 Rg6 45. Rc7 Rd3+ 46. Kh2 Bd6+ 47. Kh1 Bxc7 48. fxg7 Rd1+ 0-1",
    B: "1841",
  },
  {
    W: "1663",
    T: "600+0",
    P: "1. d4 d5 2. c4 e6 3. e3 c5 4. a3 Nc6 5. Nf3 dxc4 6. Bxc4 cxd4 7. exd4 g6 8. O-O Bg7 9. Be3 Nge7 10. Nc3 Nf5 11. Bb5 Bd7 12. d5 Bxc3 13. bxc3 Nce7 14. Bxd7+ Qxd7 15. c4 O-O 16. dxe6 Qxe6 17. c5 Nxe3 18. fxe3 Qxe3+ 19. Kh1 Qxc5 20. Qe1 Nf5 21. Nh4 Rae8 22. Qf2 Qxf2 0-1",
    B: "1563",
  },
  {
    W: "1758",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 d6 3. h3 c5 4. Bc4 Nc6 5. Nc3 h6 6. O-O Nf6 7. d3 Na5 8. Bd5 Be6 9. Nh2 Be7 10. f4 O-O 11. fxe5 dxe5 12. Bxe6 fxe6 13. Qe1 Nc6 14. Qg3 Nh5 15. Qg4 Nd4 16. Bxh6 Qe8 17. Be3 Nf6 18. Qg3 Nxc2 19. Rac1 Nxe3 20. Qxe3 Bd8 21. Nf3 Bb6 22. Nxe5 c4 23. d4 Nd7 24. Nxc4 e5 25. Nxb6 Nxb6 26. d5 Nc4 27. Qe2 Nd6 28. Rf3 Rc8 29. Rcf1 Qg6 30. Qf2 Rxf3 31. Qxf3 Rxc3 32. bxc3 Nxe4 33. c4 Nd2 34. Qf8+ Kh7 35. Rf2 Qb1+ 36. Kh2 Nxc4 37. Qf5+ Qxf5 38. Rxf5 e4 39. Kg1 e3 40. Kf1 Nd2+ 41. Ke2 Ne4 42. Kxe3 Ng3 43. Rf3 1-0",
    B: "1729",
  },
  {
    W: "1410",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. O-O Nxe4 5. d3 Nc5 6. b4 Na4 7. Bd2 Nb6 8. b5 Nd4 9. Re1 d6 10. Nxd4 Nxc4 11. dxc4 Be6 12. Nxe6 fxe6 13. Bc3 Be7 14. c5 O-O 15. cxd6 Qxd6 16. Qg4 Qc5 17. Qg3 Qxb5 18. Bxe5 Bc5 19. Qxg7# 1-0",
    B: "1415",
  },
  {
    W: "1209",
    T: "600+0",
    P: "1. d4 d5 2. c4 Bf5 3. Be3 Bxb1 4. Qxb1 dxc4 5. b3 Nc6 6. bxc4 Nxd4 7. Qxb7 Nc2# 0-1",
    B: "1180",
  },
  {
    W: "1284",
    T: "600+0",
    P: "1. d4 d5 2. c4 Nc6 3. Nc3 e6 4. Bf4 dxc4 5. e4 Qxd4 6. Nb5 Qxb2 7. Nxc7+ Ke7 8. Qd6+ Kf6 9. Nd5+ Kg6 10. Be5 Qxe5 11. Qxe5 Nxe5 12. Nc7 Rb8 13. Be2 Bb4+ 14. Kd1 Nf6 15. Nf3 Nxe4 16. Nxe5+ Kf6 17. f4 Nf2+ 18. Kc2 Nxh1 19. Rxh1 Bd6 20. Ng4+ Kf5 21. Nb5 Bxf4 22. Bxc4 0-1",
    B: "1224",
  },
  {
    W: "1219",
    T: "600+0",
    P: "1. e4 b6 2. Nf3 Bb7 3. Nc3 e6 4. d4 Bb4 5. Bd2 Bxc3 6. Bxc3 Bxe4 7. Bc4 d5 8. Bb3 Nf6 9. O-O Nc6 10. a3 a5 11. Bd2 Ng4 12. h3 Nh6 13. Ng5 Qf6 14. Nxe4 Qxd4 15. Ng5 Nf5 16. c3 Qe5 17. Re1 Qf6 18. Ba4 Ra7 19. Bxc6+ Ke7 20. Bxd5 h6 21. Nxf7 Kxf7 22. Rxe6 Qh4 23. Rxh6+ Ke7 24. Qe2+ Kd7 25. Qe6+ Kd8 26. Rxh4 Nxh4 27. Bg5# 1-0",
    B: "1213",
  },
  {
    W: "1351",
    T: "600+0",
    P: "1. d4 d6 2. e4 c5 3. Nf3 cxd4 4. Nxd4 Nc6 5. Be3 Nxd4 6. Bxd4 Nf6 7. Bd3 e5 8. Bc3 d5 9. exd5 Nxd5 10. O-O Nxc3 11. Nxc3 Bd6 12. Re1 O-O 13. Bxh7+ Kxh7 14. Qh5+ Kg8 15. Re4 g6 16. Qh6 Qc7 17. Rh4 f5 18. Qh8+ Kf7 19. Rh7+ Ke8 20. Qxf8+ Kxf8 21. Rxc7 Bxc7 22. Nd5 Bb6 23. Nxb6 axb6 24. Rd1 Be6 25. a3 b5 26. g3 g5 27. Kg2 f4 28. gxf4 exf4 29. f3 b4 30. axb4 Ra2 31. Rd8+ Ke7 32. Rd3 Rxb2 33. Rd2 Rxb4 34. Kf2 Bf5 35. Ke1 Rb1+ 36. Ke2 Rb2 37. Kd1 Rb1+ 38. Ke2 Rh1 0-1",
    B: "1361",
  },
  {
    W: "1540",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nc3 d5 4. exd5 Nxd5 5. Nxd5 Qxd5 6. Bb5+ Nc6 7. Bxc6+ Qxc6 8. d3 Bg4 9. O-O O-O-O 10. Nxe5 Bxd1 11. Nxc6 bxc6 12. Rxd1 Bc5 13. Be3 Be7 14. Bxa7 h5 15. c3 c5 16. d4 Kb7 17. Bxc5 Bxc5 18. dxc5 Kc6 19. b4 g5 20. a4 h4 21. h3 g4 22. Rxd8 Rxd8 23. hxg4 Rg8 24. f3 f5 25. Kh2 fxg4 26. fxg4 Rxg4 27. Kh3 Rc4 28. Rc1 Kd5 29. Rc2 Ke5 30. a5 Kf5 31. Rf2+ Kg5 32. Rf3 Rd4 33. a6 Rd2 34. Rf8 Rd3+ 35. Kh2 Kg4 36. a7 h3 37. a8=Q Rxc3 38. Rg8+ Kh4 39. Qd8+ Kh5 40. Qg5# 1-0",
    B: "1505",
  },
  {
    W: "1546",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3 Bc5 5. Nxe5 Nxe5 6. d4 Nxc4 7. dxc5 d6 8. b3 Ne5 9. Bg5 Qd7 10. f4 Neg4 11. e5 dxe5 12. Qxd7+ Bxd7 13. fxe5 Nh5 14. O-O-O f6 15. Bh4 Nxe5 16. Rhe1 Bc6 17. g4 Nf4 18. Bg3 g5 19. h4 Kf7 20. hxg5 Nfg6 21. gxf6 Kxf6 22. g5+ Kf5 23. b4 Nc4 24. b5 Be8 25. Rd5+ Kg4 26. Rg1 Ne7 27. Rd4+ Kxg5 28. Be5+ Kf5 29. Bxh8 Ne3 30. Re4 N7d5 31. Re5+ Kf4 32. Nxd5+ Nxd5 33. Rxd5 Bf7 34. Rf1+ Ke4 35. Rd7 Bxa2 36. Re7+ Kd5 37. Rd1+ Kxc5 38. Rxc7+ Kxb5 39. Rxb7+ Ka6 40. Rxh7 Bg8 41. Rh6+ Kb5 42. Bb2 a5 43. Rh8 a4 44. Rg1 Bd5 45. Rxa8 Bxa8 46. Ba3 Bd5 47. Kb2 Bc4 48. Rg5+ Ka6 49. c3 Bd3 50. Rd5 Bc4 51. Rc5 Bb5 52. Rxb5 Kxb5 53. Kc2 Kc4 54. Kd2 Kb3 55. Bb4 a3 56. Bxa3 Kxa3 57. Kd3 Ka4 58. Kd4 Ka5 59. c4 Kb6 60. Kd5 Kc7 61. Kc5 Kd7 62. Kd5 Kc7 63. Kc5 Kd7 64. Kd5 Kc8 65. c5 Kd7 66. c6+ Kc7 67. Kc5 Kd8 68. Kb5 Kc7 69. Kc5 Kb8 70. Kb6 Kc8 71. c7 Kd7 72. Kb7 Kd6 73. c8=Q Kd5 74. Qc6+ Kd4 75. Kb6 Ke5 76. Kb5 Kd4 77. Qc5+ Kd3 78. Kb4 Ke4 79. Qc4+ Ke5 80. Kc3 Kd6 81. Kd3 Ke5 82. Qd4+ Ke6 83. Qe4+ Kd6 84. Kd4 Kc7 85. Qe5+ Kb6 86. Kd5 Kb5 87. Qd6 Ka4 88. Qc5 Kb3 89. Kd4 Kb2 90. Qc4 Ka3 91. Kd3 Kb2 92. Qc3+ Kb1 93. Kd2 Ka2 94. Qc2+ Ka3 95. Kd3 Kb4 96. Qc3+ Kb5 97. Qd4 Kc6 98. Kc3 Kc7 99. Kc4 Kc6 100. Qd5+ Kb6 101. Kd4 Kc7 102. Qc5+ Kd7 103. Qd5+ Ke7 104. Kc5 Kf6 105. Qd6+ Kf5 106. Qe5+ Kxe5 1/2-1/2",
    B: "1554",
  },
  {
    W: "1499",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Na5 4. d4 Nf6 5. Nxe5 Ke7 6. O-O Ke8 7. Nf3 Be7 8. d5 Kf8 9. Qd2 Nxe4 10. Qxa5 b6 11. Qe1 Nd6 12. Bd3 c6 13. Nc3 b5 14. Qe5 Nc4 15. Qh5 g6 16. Qh6+ Ke8 17. Qg7 a5 18. Qxh8+ Bf8 19. Re1+ Ne5 20. Rxe5+ Qe7 21. Rxe7+ Kd8 22. d6 Ra7 23. Qxf8# 1-0",
    B: "1500",
  },
  {
    W: "2120",
    T: "180+0",
    P: "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. f3 exf3 5. Nxf3 c5 6. Be3 cxd4 7. Bxd4 Nc6 8. Bb5 Bd7 9. O-O Nxd4 10. Nxd4 Bxb5 11. Ncxb5 Bc5 12. c3 Nf6 13. Kh1 Bxd4 14. Nxd4 O-O 15. h3 Nd5 16. Qb3 Qb6 17. Qa3 Ne3 18. Rf3 Nc4 19. Qe7 Rae8 20. Qg5 h6 21. Qg4 Ne5 22. Qf4 Nxf3 23. Qxf3 Qxb2 24. Rf1 e5 25. Nf5 e4 26. Qg4 Qb6 27. Qxg7# 1-0",
    B: "2111",
  },
  {
    W: "1833",
    T: "180+0",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 Nf6 5. h3 Bb4+ 6. c3 Ba5 7. Bd3 Bb6 8. Qg3 d5 9. e5 Ne4 10. Qxg7 Rf8 11. Bh6 Qe7 12. Bxe4 dxe4 13. f4 Be6 14. Nd2 O-O-O 15. Nxe4 Bf5 16. Nf6 Qc5 17. Qg3 Rg8 18. Nxg8 Rxg8 19. Qxg8+ Nd8 20. Qg3 Qb5 21. O-O-O Qa4 22. Bg5 Qc2# 0-1",
    B: "1811",
  },
  {
    W: "1599",
    T: "180+0",
    P: "1. e4 c5 2. Bc4 e6 3. Nc3 d5 4. exd5 exd5 5. Bxd5 Nf6 6. Bc4 a6 7. a3 Bd6 8. Nf3 O-O 9. O-O Bg4 10. Be2 Re8 11. Re1 Bc7 12. d3 Qd6 13. Bg5 Bxf3 14. Bxf3 Qxh2+ 15. Kf1 Qh1# 0-1",
    B: "1652",
  },
  {
    W: "2304",
    T: "60+0",
    P: "1. f4 e5 2. fxe5 d6 3. exd6 Bxd6 4. Nf3 g5 5. g3 Ne7 6. d4 g4 7. Nh4 Ng6 8. Nxg6 hxg6 9. Qd3 c6 10. Nc3 Qc7 11. e4 Rxh2 12. Rxh2 Bxg3+ 13. Rf2 Nd7 14. e5 Bxf2+ 15. Kxf2 Nb6 16. Bg2 Bf5 17. Qe2 O-O-O 18. Be3 Nd5 19. Bxd5 cxd5 20. Nb5 g3+ 21. Kxg3 Qb6 22. Nd6+ Rxd6 23. exd6 Qxd6+ 24. Kg2 Qf6 25. Qf3 Be4 26. Rf1 Bxf3+ 27. Rxf3 Qe6 28. Kf2 Qe4 29. Rxf7 Qxc2+ 30. Kf3 Qd1+ 31. Kg3 Qg1+ 32. Bxg1 1-0",
    B: "2056",
  },
  {
    W: "1482",
    T: "60+0",
    P: "1. d4 e6 2. e3 d5 3. f4 Nf6 4. Nf3 Bd6 5. Bd3 O-O 6. O-O c6 7. g3 b5 8. Nbd2 Re8 9. b4 Bd7 10. c3 a5 11. a3 axb4 12. cxb4 Bxb4 13. Nb3 Bc3 14. Rb1 Qe7 15. Bd2 Bxd2 16. Qxd2 Rxa3 17. Nc1 Qd8 18. Qb2 Qa5 19. Nb3 Qa6 20. Ra1 Rxa1 21. Rxa1 Qb6 22. Nc5 Bc8 23. Ne5 Na6 24. Nxa6 Bxa6 25. h4 Bc8 26. Kh2 Nd7 27. Nxd7 Bxd7 28. Kg2 c5 29. dxc5 Qxc5 30. Rc1 Qxe3 31. Kh3 Qxd3 32. Qc2 Qxc2 33. Rxc2 Rc8 34. Rb2 Rb8 35. Ra2 b4 36. Rb2 b3 37. g4 d4 38. Kg2 d3 39. Kf2 Bb5 40. Ke1 Ba4 41. Kd2 e5 42. Kxd3 1-0",
    B: "1466",
  },
  {
    W: "2256",
    T: "30+0",
    P: "1. Nf3 g6 2. e3 Bg7 3. d4 d6 4. c4 Nf6 5. Nc3 O-O 6. Be2 Bg4 7. O-O Bxf3 8. Bxf3 c6 9. d5 cxd5 10. cxd5 Nbd7 11. e4 Rc8 12. Re1 Ne5 13. Be3 Nxf3+ 14. Qxf3 Nd7 15. Bxa7 Ne5 16. Qe2 Ra8 17. Be3 f6 18. f4 Nd7 19. a3 Rc8 20. Rac1 Nc5 21. b4 Nd7 22. Nb5 Re8 23. Rxc8 Qxc8 24. Rc1 Qd8 25. Rc7 b6 26. Qg4 h5 27. Qxd7 Qxd7 28. Rxd7 Kf7 29. Kf2 Bh6 30. g3 g5 31. h3 gxf4 32. Bxf4 Bg7 33. g4 Kf8 34. gxh5 Bh6 35. Bxh6+ Kg8 36. Bf4 Kh7 37. h6 Kg6 38. Kf3 Kh7 39. e5 dxe5 40. Be3 f5 41. d6 e4+ 42. Kf4 Kxh6 43. Ke5+ Kg6 44. dxe7 Kf7 45. Rd6 1-0",
    B: "2191",
  },
  {
    W: "1142",
    T: "120+1",
    P: "1. d4 h5 2. e3 a5 3. Nc3 h4 4. Nf3 a4 5. Bd3 e6 6. a3 d5 7. h3 Bd6 8. O-O Nf6 9. Ng5 e5 10. dxe5 Bxe5 11. e4 d4 12. Nd5 Nxd5 13. exd5 Qxd5 14. Nh7 Nc6 15. c4 Qe6 16. Ng5 Qe7 17. Re1 f6 18. Nh7 Qf7 19. Qf3 1-0",
    B: "899",
  },
  {
    W: "2003",
    T: "120+1",
    P: "1. d4 Nf6 2. c4 c5 3. Nf3 cxd4 4. Nxd4 Nc6 5. Nc3 g6 6. g3 Bg7 7. Bg2 O-O 8. O-O e6 9. c5 Nxd4 10. Qxd4 Nd5 11. Qd3 Nxc3 12. bxc3 Qa5 13. Be3 Qxc3 14. Qxc3 Bxc3 15. Rab1 Rb8 16. Bf4 e5 17. Bh6 Re8 18. Rfc1 Ba5 19. c6 dxc6 20. Bxc6 Re6 21. Bd5 Rd6 22. Bb3 Bb6 23. Rd1 Rxd1+ 24. Rxd1 Bf5 25. e4 Bxe4 26. Rd7 Rd8 27. Bxf7+ Kh8 28. Rxd8+ Bxd8 29. Kf1 Bf6 30. Ke2 Bg7 31. Be3 b6 32. f3 Bc6 33. h4 Bf6 34. Bh6 Bg7 35. Bg5 h6 36. Be7 Kh7 37. Bd6 e4 38. f4 Bd4 39. g4 Kg7 40. Bc4 b5 41. Bb3 a6 42. Be5+ Bxe5 43. fxe5 Kf8 44. Ke3 Ke7 45. Bc2 a5 46. Bxe4 Bxe4 47. Kxe4 Ke6 48. Kd4 a4 49. a3 h5 50. gxh5 gxh5 51. Kc5 Kxe5 52. Kxb5 Kf4 53. Kxa4 Kg4 54. Kb5 Kxh4 55. a4 Kg3 56. a5 h4 57. a6 h3 58. a7 h2 59. a8=Q h1=Q 60. Qxh1 1-0",
    B: "1919",
  },
  {
    W: "1727",
    T: "120+1",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Qb6 5. Qb3 Nc6 6. Qxb6 axb6 7. Be3 Nge7 8. f4 Nf5 9. Bf2 Be7 10. Nf3 O-O 11. g4 Nh6 12. h3 f6 13. Be2 fxe5 14. fxe5 Bd7 15. Be3 Nf7 16. O-O c4 17. Nbd2 Be8 18. b3 b5 19. b4 Nfd8 20. Bg5 Bg6 21. Bxe7 Nxe7 22. Ng5 h6 23. Rxf8+ Kxf8 24. Ngf3 Ke8 25. Nh4 Bh7 26. Kf2 Kd7 27. Ndf3 Nf7 28. a3 Nc6 29. Ng2 Bc2 30. h4 Rf8 31. Ne3 Bb3 32. Kg3 g6 33. Bd1 Bxd1 34. Nxd1 Nfd8 35. a4 bxa4 36. Rxa4 Kc7 37. g5 h5 38. Nb2 b5 39. Ra6 Kb7 40. Ra3 Kc7 41. Nd1 Nb7 42. Nf2 Rf5 43. Nd1 Ne7 44. Ne3 Rf8 45. Ra7 Kb6 46. Ra1 1-0",
    B: "1749",
  },
  {
    W: "1899",
    T: "120+1",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Qxd4 Nf6 5. Nc3 a6 6. Bg5 Nc6 7. Qd3 e6 8. O-O-O Be7 9. Nd4 O-O 10. f4 Nxd4 11. Qxd4 b5 12. Bxf6 Bxf6 13. Qxd6 Qxd6 14. Rxd6 Bxc3 15. bxc3 Bb7 16. Bd3 Rac8 17. Kb2 Rfd8 18. Rxd8+ Rxd8 19. Re1 Kf8 20. g3 Ke7 21. e5 Bd5 22. Kc1 Rc8 23. Kb2 Kd7 24. a4 Bc4 25. axb5 axb5 26. Ra1 Rc7 27. Bxc4 Rxc4 28. Ra7+ Rc7 29. Ra5 Kc6 30. Kb3 Kb6 31. Ra8 h6 32. Rb8+ Kc5 33. Rg8 g6 34. Rh8 h5 35. h4 Rb7 36. Rc8+ Kd5 37. Rd8+ Kc5 38. Rd6 Ra7 39. Rd4 Rb7 40. Rb4 Rb6 0-1",
    B: "1908",
  },
  {
    W: "1318",
    T: "120+1",
    P: "1. e4 e5 2. Bc4 Bc5 3. Qh5 Qf6 4. Nf3 d6 5. O-O Nh6 6. Qg5 Qxg5 7. Nxg5 O-O 8. d3 Nc6 9. Be3 Nd4 10. Bxd4 Bxd4 11. c3 Bb6 12. Nd2 Ng4 13. h3 Bxf2+ 14. Rxf2 Nxf2 15. Kxf2 h6 16. Ngf3 Kh8 17. Kg1 f5 18. Rf1 fxe4 19. Nxe4 Bf5 20. Nh4 Bxe4 21. dxe4 Rxf1+ 22. Kxf1 Rf8+ 23. Ke2 Rf4 24. Ng6+ Kh7 25. Nxf4 exf4 26. Kf3 g5 27. Be6 Kg6 28. Bc8 b6 29. a4 Kf6 30. b4 Ke5 31. c4 Kd4 32. Be6 Kc3 33. Kg4 Kxb4 34. Kh5 Kxa4 35. Kxh6 Kb4 36. Kxg5 a5 37. h4 a4 38. h5 a3 39. c5 bxc5 40. Ba2 c4 41. Kxf4 c3 42. Ke3 c2 43. Kd2 c5 44. Kxc2 c4 45. h6 c3 46. h7 1-0",
    B: "1075",
  },
  {
    W: "1331",
    T: "120+1",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. e4 Nxc3 6. bxc3 Bg7 7. Bd3 O-O 8. Ne2 c6 9. O-O Bg4 10. f3 Bd7 11. Qb3 b5 12. a4 Na6 13. axb5 cxb5 14. Bxb5 Rb8 15. c4 Bxb5 16. cxb5 Bxd4+ 17. Nxd4 Qxd4+ 18. Be3 Qb4 19. Qxb4 Nxb4 20. Rab1 Nc2 21. Bxa7 Ra8 22. b6 Ne3 23. Rfc1 f5 24. Rb3 f4 25. Rc7 e5 26. b7 Rxa7 27. b8=Q Rxc7 28. Qxc7 1-0",
    B: "1338",
  },
  {
    W: "1442",
    T: "120+1",
    P: "1. Nf3 d5 2. b3 Nc6 3. Bb2 e6 4. e3 Be7 5. Bxg7 Bf6 6. Bxh8 Bxa1 7. Bxa1 Bd7 8. d4 Qe7 9. c4 dxc4 10. Bxc4 O-O-O 11. O-O e5 12. Qe2 Bg4 13. dxe5 Nxe5 14. Bxe5 Bxf3 15. Qxf3 Qxe5 16. Bxf7 Rf8 17. Qg4+ Kb8 18. Bxg8 Qe8 19. Bxh7 Rh8 20. Bg6 Qe5 21. Bh7 Qe7 22. Be4 Qe5 23. h3 Re8 24. Bf3 Qd6 25. Nc3 Qf6 26. Ne4 Qf8 0-1",
    B: "1400",
  },
  {
    W: "1479",
    T: "120+1",
    P: "1. d4 d5 2. Bf4 e6 3. Nf3 Nf6 4. e3 c5 5. c3 Nc6 6. Bd3 Bd6 7. Bg3 O-O 8. Nbd2 h6 9. Bb1 Qc7 10. Qc2 cxd4 11. exd4 b5 12. Bh4 1-0",
    B: "1517",
  },
  {
    W: "1313",
    T: "120+1",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. Qe2+ Be7 5. b3 Nf6 6. Bb2 Bg4 7. Qb5+ Nbd7 8. Qxb7 O-O 9. Bxf6 Bxf6 10. Nc3 Re8+ 11. Be2 Qe7 12. Nxd5 Qxe2# 0-1",
    B: "1324",
  },
  {
    W: "1260",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qe6+ 4. Nge2 Qg6 5. g3 h5 6. Bg2 c6 7. O-O Bg4 8. d3 Bxe2 9. Qxe2 e6 10. a4 Bb4 11. Bd2 Bxc3 12. Bxc3 f6 13. Qe4 Nd7 14. Qxg6+ Kd8 15. Qxg7 Rh6 16. Qxg8+ Kc7 17. Qxe6 Kb6 18. Bd4+ Nc5 19. Bxc5+ Kxc5 20. Qe3+ Kb4 21. Qxh6 Re8 22. Qxf6 Re2 23. Rfe1 Rxc2 24. Re4+ Kb3 25. Qe6+ 1-0",
    B: "1213",
  },
  {
    W: "2326",
    T: "60+0",
    P: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Nxf6+ gxf6 6. Nf3 Bg4 7. Be2 Nd7 8. c4 e6 9. Be3 Qc7 10. h3 Bh5 11. Qd2 O-O-O 12. O-O Rg8 13. Bf4 e5 14. Bh2 Bd6 15. d5 e4 16. Bxd6 Qxd6 17. Nd4 Bg6 18. dxc6 Ne5 19. cxb7+ Kb8 20. Rfd1 Qc7 21. c5 Nf3+ 22. Bxf3 exf3 23. g3 Be4 24. Qe3 Rxg3+ 25. fxg3 Qxg3+ 26. Kf1 Qg2+ 27. Ke1 Bxb7 28. c6 f2+ 29. Qxf2 Re8+ 30. Ne2 Ba6 31. Qxg2 Rxe2+ 32. Qxe2 Bxe2 33. Kxe2 Kc7 34. Rdc1 a6 35. Rc3 a5 36. Rd1 a4 37. Rd7+ Kc8 38. Rxf7 a3 39. Rg3 axb2 40. Rg8# 1-0",
    B: "2358",
  },
  {
    W: "1124",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 f6 3. Bc4 h5 4. Bxg8 Rxg8 5. O-O h4 6. h3 Rh8 7. Nc3 d6 8. d4 g5 9. dxe5 g4 10. hxg4 h3 11. g3 h2+ 12. Nxh2 fxe5 13. Nd5 Qe7 14. Nxe7 Bxe7 15. f4 Bxg4 16. fxe5 dxe5 17. Nxg4 Nd7 18. Nxe5 Nxe5 19. Rf5 Nf3+ 20. Rxf3 Bc5+ 21. Kg2 Rd8 22. Qf1 Rh2+ 23. Kxh2 Bd4 24. Qb5+ Ke7 25. Qb4+ c5 26. Bg5+ Ke8 27. Qxc5 b6 0-1",
    B: "934",
  },
  {
    W: "1436",
    T: "60+0",
    P: "1. b3 d5 2. Bb2 e5 3. e3 Nc6 4. Bb5 Bd7 5. Bxc6 Bxc6 6. Nf3 e4 7. Ne5 Ne7 8. Nxc6 Nxc6 9. Nc3 Bd6 10. Ne2 Qh4 11. Nf4 Bxf4 12. exf4 Qxf4 13. g3 Qf3 14. Rf1 O-O 15. Qxf3 exf3 16. O-O-O g5 17. Rfe1 g4 18. Re3 Rfe8 19. Rde1 h5 20. Bf6 Re6 21. Bg5 Rae8 22. Bh4 Re5 23. Rxe5 Rxe5 24. Kd1 Rxe1+ 25. Kxe1 Nd4 26. c3 Nc2+ 27. Kd1 c6 28. Kxc2 Kg7 29. Kd3 Kg6 30. Ke3 Kf5 31. d4 b6 32. Kd2 Ke4 33. c4 dxc4 34. d5 Kxd5 35. Ke3 cxb3 36. axb3 a5 37. Kf4 b5 38. Kg5 a4 39. Kxh5 a3 40. Kxg4 a2 41. Kxf3 a1=Q 42. g4 Qd4 43. Kg2 Qf6 44. f3 Qg7 45. Kg3 Qh7 46. h3 Qxh4+ 47. Kxh4 Ke6 48. g5 Ke5 49. f4+ Kf5 50. Kh5 Kxf4 51. h4 Kf5 52. Kh6 Kg4 53. h5 1-0",
    B: "1404",
  },
  {
    W: "1643",
    T: "60+0",
    P: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. d4 Nf6 5. Nc3 Nc6 6. Bg5 Bg4 7. Be2 e6 8. h3 Bh5 9. g4 Bg6 10. Nh4 Be7 11. Nxg6 hxg6 12. Bf4 O-O 13. Qd3 Bd6 14. Bg3 Bxg3 15. fxg3 Qd6 16. O-O Qb4 17. Rab1 Qxd4+ 18. Qxd4 Nxd4 19. Rfd1 Nxe2+ 20. Nxe2 Ne4 21. c4 dxc4 22. Nc3 Nxc3 23. bxc3 a6 24. Rxb7 Rfc8 25. Rd4 Rc5 26. Rb4 Rac8 27. Ra4 a5 28. Kf2 Kf8 29. Ke3 Ke7 30. Kf4 f6 31. h4 e5+ 32. Ke4 exd4 33. cxd4 Kf7 34. dxc5 Rxc5 35. Kd4 Re5 36. Kxc4 Re4+ 37. Kb5 Rxa4 38. Kxa4 Ke6 39. Kxa5 Kd7 40. Kb5 Kc7 41. a4 f5 42. a5 f4 43. Ka6 f3 44. Ka7 1-0",
    B: "1680",
  },
  {
    W: "2029",
    T: "60+0",
    P: "1. Nf3 d5 2. b3 c6 3. Bb2 e6 4. g3 Nf6 5. Bg2 Be7 6. O-O O-O 7. d3 Nbd7 8. Nbd2 h6 9. Re1 b6 10. e4 dxe4 11. dxe4 Bb7 12. e5 Nd5 13. c4 Nb4 14. Qe2 Nc5 15. Ba3 Nbd3 16. Red1 Qc7 17. Ne4 Nxe4 18. Bxe7 Qxe7 19. Rxd3 Rfd8 20. Rxd8+ Rxd8 21. Qxe4 c5 22. Qe3 Qd7 23. Re1 Qd3 24. Nh4 Bxg2 25. Qxd3 Rxd3 26. Nxg2 Rd2 27. Ra1 Kf8 28. Ne3 Ke7 29. Kf1 f6 30. Ke1 Rd8 31. exf6+ gxf6 32. a4 f5 33. Rb1 Rd3 34. Ke2 Rd8 35. b4 Rc8 36. bxc5 Rxc5 37. a5 Rxa5 38. Rc1 Ra2+ 39. Kf3 Ra5 40. h4 h5 41. Kf4 Kd6 42. Kg5 Kc6 43. f4 Kc5 44. Kxh5 Kd4 45. Kg5 Kxe3 46. Kf6 Kd4 47. Kxe6 Rc5 48. h5 Rxc4 49. Rxc4+ Kxc4 50. h6 b5 51. h7 b4 52. h8=Q b3 53. Qb2 Kb4 54. Kxf5 a5 55. Ke4 a4 56. Kd3 a3 57. Qb1 a2 0-1",
    B: "2102",
  },
  {
    W: "2252",
    T: "60+0",
    P: "1. Nf3 d6 2. g3 Nf6 3. Bg2 g6 4. O-O Bg7 5. d3 O-O 6. Nc3 c6 7. e4 Nbd7 8. Be3 e5 9. h3 Qc7 10. Qd2 Rd8 11. Rfe1 Nf8 12. Rad1 d5 13. Bh6 dxe4 14. Nxe4 Nxe4 15. Rxe4 f5 16. Ree1 Ne6 17. Bxg7 Qxg7 18. Qc3 Nd4 19. Nxd4 exd4 20. Qb3+ Kh8 21. Qb4 Qf7 22. Re7 Qf6 23. Rde1 a5 24. Re8+ Rxe8 25. Rxe8+ Kg7 26. Qb6 Kf7 27. Rxc8 Rxc8 28. Qxb7+ Qe7 29. Qxc8 Qe1+ 30. Kh2 Qxf2 31. Qxc6 Kg7 32. Qd5 Qxc2 33. Qxd4+ Kh6 34. h4 Qe2 35. a4 Qe8 36. Qf4+ Kg7 37. d4 Qb8 38. Qe5+ Qxe5 39. dxe5 Kf7 40. Bd5+ Ke7 41. Kg2 Kd7 42. Kf3 Ke7 43. Ke3 Kd7 44. Kf4 Ke7 45. Kg5 Kd7 46. Kh6 Ke7 47. Kxh7 f4 48. gxf4 g5 49. hxg5 Kf8 50. f5 Ke8 51. g6 Kf8 52. g7+ Ke8 53. g8=Q+ Ke7 54. Qe6+ Kd8 55. Qd6+ Kc8 56. Be6+ Kb7 57. b4 axb4 58. Bd5+ 1-0",
    B: "2232",
  },
  {
    W: "1923",
    T: "60+0",
    P: "1. e4 e6 2. Qe2 d5 3. d3 dxe4 4. dxe4 Nf6 5. c3 Nc6 6. g3 Bc5 7. Bg2 Bd7 8. Nd2 O-O 9. f4 a6 10. Nh3 h6 11. b4 Ba7 12. Nb3 b5 13. Be3 Bxe3 14. Qxe3 Ne7 15. O-O Ne8 16. Rad1 Qc8 17. Nc5 Bc6 18. g4 Nd6 19. Rfe1 Nc4 20. Qg3 Rd8 21. g5 Rxd1 22. Rxd1 hxg5 23. Nxg5 Qf8 24. Qh4 Qc8 25. Qh7+ Kf8 26. Qh8+ Ng8 27. Nh7+ Ke7 28. Qxg7 Nd6 29. Re1 Be8 30. Bh3 Kd8 31. f5 exf5 32. exf5 Nxf5 33. Qg5+ Nge7 34. Rd1+ 1-0",
    B: "1900",
  },
  {
    W: "1392",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 Nf6 5. c4 c6 6. cxd5 cxd5 7. Be2 Bb4+ 8. Nc3 Qa5 9. O-O Bxc3 10. bxc3 Qxc3 11. Bd2 Qc7 12. Re1 O-O 13. Ne5 a6 14. Bb4 Re8 15. Bf3 Nc6 16. Nxc6 bxc6 17. Rxe8+ Nxe8 18. Qb3 Rb8 19. Re1 a5 20. Rxe8# 1-0",
    B: "1245",
  },
  {
    W: "1160",
    T: "60+0",
    P: "1. d4 d5 2. c4 dxc4 3. Nc3 Nf6 4. e3 e6 5. Bxc4 Bd7 6. Nf3 h6 7. O-O Qe7 8. Ne5 Nc6 9. Nxc6 Bxc6 10. d5 Bxd5 11. Bxd5 exd5 12. Nxd5 Nxd5 13. Qxd5 Rd8 14. Qxb7 Qd7 15. Qb3 Bd6 16. e4 O-O 17. Bd2 Be5 18. Bc3 Bxc3 19. Qxc3 Rfe8 20. Rad1 Qe7 21. Rxd8 Rxd8 22. Qc2 Re8 23. Rd1 c5 24. f3 a5 25. Kf2 Kh7 26. a3 f6 27. e5+ Kg8 28. exf6 gxf6 29. Qc4+ Kg7 30. Qc3 Qe2+ 31. Kg3 Qxd1 32. Qxa5 Qd7 33. Qxc5 Kg6 34. f4 Qe7 35. Kg4 Qg7 36. Qf5+ Kf7+ 37. Kf3 1-0",
    B: "1198",
  },
  {
    W: "1732",
    T: "60+0",
    P: "1. d4 d5 2. Bg5 c6 3. e3 Qc7 4. Bd3 e6 5. Nf3 Bd6 6. Nbd2 Nd7 7. Bh4 Ngf6 8. Bg3 O-O 9. Bxd6 Qxd6 10. Qe2 b5 11. O-O e5 12. a3 e4 13. Ng5 exd3 14. cxd3 h6 15. Ngf3 a5 16. Rac1 Bb7 17. Nb3 Rae8 18. Qd2 a4 19. Na5 Qc7 20. Rc2 Qb6 21. Rfc1 Rc8 22. Nxb7 Qxb7 23. Qb4 Rfe8 24. h3 g5 25. Qd6 Kg7 26. Qg3 Qc7 27. h4 Qxg3 28. fxg3 g4 29. Nh2 Re6 30. Nf1 h5 31. Re1 Nb8 32. e4 dxe4 33. dxe4 Rxe4 34. Rd1 Rce8 35. d5 Nxd5 36. Rxd5 cxd5 37. Rc7 Re1 38. Kf2 R8e2+ 39. Kg1 Na6 40. Kh2 Nxc7 41. Ne3 Ne6 42. Nf5+ Kf8 0-1",
    B: "1889",
  },
  {
    W: "1971",
    T: "30+0",
    P: "1. e4 d5 2. d4 dxe4 3. c3 Nf6 4. Ne2 c5 5. Ng3 cxd4 6. cxd4 Nc6 7. Nd2 Nxd4 8. Ndxe4 Nxe4 9. Nxe4 Nc6 10. Qxd8+ Nxd8 11. Bc4 Nc6 12. Bf4 Bf5 13. Ng3 O-O-O 14. O-O Be6 15. Rfe1 Bxc4 16. Rac1 Bd5 17. b4 a6 18. a4 b6 19. b5 axb5 20. axb5 Kb7 21. bxc6+ Bxc6 22. Ne4 e6 23. Nc3 Bd6 24. Bxd6 Rxd6 25. Red1 Rhd8 26. Rxd6 Rxd6 27. Rd1 Bd5 28. Nxd5 Rxd5 29. Rxd5 exd5 30. Kf1 Kc6 31. Ke2 b5 32. Kd3 Kc5 33. Kc3 b4+ 34. Kb3 d4 35. Kc2 Kc4 36. Kd2 b3 37. Kc1 Kc3 38. Kb1 d3 39. Kc1 d2+ 40. Kd1 b2 41. g4 b1=Q+ 42. Ke2 d1=Q+ 43. Ke3 Qe1+ 44. Kf4 Qbe4+ 45. Kg3 Q1e2 46. f3 Q2xf3+ 47. Kh4 Qexg4# 0-1",
    B: "2313",
  },
  {
    W: "1783",
    T: "180+0",
    P: "1. d4 c6 2. e3 d5 3. Bd3 Nf6 4. Nd2 Bg4 5. f3 Bh5 6. Nh3 e6 7. c3 Bd6 8. Qc2 Bc7 9. e4 dxe4 10. Nxe4 Nxe4 11. Bxe4 O-O 12. Nf2 f5 13. Bd3 e5 14. dxe5 Bxe5 15. O-O Bc7 16. Nh3 Qd6 17. Bf4 Qd7 18. Bc4+ Bf7 19. Rad1 Qc8 20. Bxf7+ Rxf7 21. Qb3 Kf8 22. Bxc7 Qxc7 23. Ng5 Re7 24. Nxh7+ Ke8 25. Qg8# 1-0",
    B: "1806",
  },
  {
    W: "1417",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. d3 Nc6 4. Nc3 d6 5. Bg5 Be7 6. h3 O-O 7. Be2 d5 8. Bxf6 dxe4 9. Bxe7 Qxe7 10. dxe4 Be6 11. O-O Rad8 12. Bd3 Nb4 13. Qe2 c5 14. a3 Nc6 15. Bb5 Nd4 16. Nxd4 cxd4 17. Nd5 Bxd5 18. exd5 Rxd5 19. Bd3 f5 20. Rfe1 e4 21. Bxe4 fxe4 22. Qxe4 Qd6 23. Rad1 Kh8 24. Re2 Qd7 25. Red2 Re8 26. Qf4 Rf5 27. Qxd4 Qxd4 28. Rxd4 Rfe5 29. Rd8 h6 30. Rxe8+ Rxe8 31. Rd7 Re1+ 32. Kh2 Rc1 33. Rxb7 Rxc2 34. Rxa7 Rxb2 35. a4 Ra2 36. a5 Kg8 37. Kg3 Kh7 38. a6 Kg6 39. Ra8 Kf6 40. f4 Ra3+ 41. Kh2 g5 42. fxg5+ hxg5 43. a7 Kf5 44. Rf8+ Kg6 1-0",
    B: "1474",
  },
  {
    W: "1870",
    T: "180+0",
    P: "1. e4 e5 2. d3 Nf6 3. Nf3 Be7 4. Nc3 Nc6 5. b3 O-O 6. Bb2 d6 7. g3 Bd7 8. Bg2 h6 9. O-O a6 10. Nd2 b5 11. f4 exf4 12. Rxf4 Ne5 13. Nd5 Nxd5 14. exd5 Bf6 15. Rf1 Ng4 16. Bxf6 Nxf6 17. Ne4 Nxd5 18. Nxd6 Bc6 19. Bxd5 Qxd6 20. Bxc6 Qxc6 21. Qd2 Qd6 22. Qf4 Qc5+ 23. Qf2 Qxf2+ 24. Rxf2 c5 25. Raf1 f6 26. Rf5 Rac8 27. Rd5 Rc6 28. Rff5 Rfc8 29. Kg2 Kf7 30. Kf2 Ke6 31. c4 g6 32. Rf3 bxc4 33. dxc4 a5 34. Re3+ Kf7 35. Rd7+ Kg8 36. Ree7 f5 37. Rg7+ Kf8 38. Rgf7+ Kg8 39. Rh7 h5 40. Rhg7+ Kf8 41. Rge7 Kg8 42. Re5 Kf8 43. Ra7 Re8 44. Rxe8+ Kxe8 45. Rxa5 Ke7 46. Ra8 Kf6 47. Rf8+ Kg5 48. h4+ Kg4 49. Rg8 f4 50. gxf4 Kxf4 51. Rd8 Kg4 52. Re8 Kxh4 53. Re4+ Kg5 54. Re5+ Kf4 55. Rd5 g5 56. a4 g4 57. Rxh5 g3+ 58. Kg2 Rb6 59. Rh4+ Kg5 60. Rh3 Rxb3 61. Rxg3+ Rxg3+ 62. Kxg3 Kf5 63. a5 Ke4 64. a6 Kd4 65. a7 Kxc4 66. a8=Q Kb4 67. Qa1 1-0",
    B: "1876",
  },
  {
    W: "1414",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 d6 3. Nc3 Bg4 4. Be2 c6 5. O-O Nd7 6. d4 Bxf3 7. Bxf3 Ngf6 8. d5 c5 9. Bg5 a6 10. a3 b5 11. b4 c4 12. Qe2 h6 13. Bxf6 Qxf6 14. Rae1 h5 15. h3 g5 16. g3 g4 17. h4 gxf3 18. Qxf3 0-1",
    B: "1373",
  },
  {
    W: "2105",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3 Nge7 5. O-O O-O 6. d4 exd4 7. cxd4 Bb6 8. d5 Nb8 9. Nc3 c6 10. Bc4 cxd5 11. exd5 Ng6 12. Re1 d6 13. Bg5 f6 14. Be3 Bg4 15. h3 Bd7 16. Nd4 Na6 17. Ne6 Bxe6 18. dxe6 Qe7 19. Nd5 Qe8 20. e7 Rf7 21. Bxb6 axb6 22. Nxb6 Kh8 1-0",
    B: "2069",
  },
  {
    W: "1529",
    T: "180+0",
    P: "1. c4 c5 2. d4 Nc6 3. Nc3 cxd4 4. Ne4 e5 5. e3 dxe3 6. Bxe3 Bb4+ 7. Nc3 Nf6 8. Bd2 e4 9. a3 Bxc3 10. Bxc3 O-O 11. Ne2 d5 12. b3 dxc4 13. bxc4 Bg4 14. Qb3 Nd7 15. h3 Bxe2 16. Bxe2 b6 17. O-O Nc5 18. Qb2 Nd4 1-0",
    B: "1584",
  },
  {
    W: "1956",
    T: "180+0",
    P: "1. e4 c5 2. d4 Nc6 3. d5 Ne5 4. f4 Ng6 5. Nf3 d6 6. Bb5+ Bd7 7. Bxd7+ Qxd7 8. O-O e6 9. c4 e5 10. fxe5 dxe5 11. Nc3 Bd6 12. Nb5 a6 13. Qa4 Kf8 14. Qa3 Nf6 15. Bg5 Nxe4 16. Rae1 f5 17. Rxe4 f4 18. Nxd6 Qxd6 19. Nxe5 Kg8 20. Nxg6 hxg6 21. Rexf4 Re8 22. h3 Re5 23. Rg4 Rf5 24. Bf4 Qf6 25. Qxc5 g5 26. Qe3 gxf4 27. Qe8+ Qf8 28. Qe4 Rf7 29. Rgxf4 Rxf4 30. Rxf4 Qc8 31. Qe6+ Qxe6 32. dxe6 g6 33. e7 Kg7 34. Kh2 Re8 35. Re4 Kf7 36. Rg4 Rxe7 37. Rd4 Ke6 38. Rd2 Kf6 39. b3 Rc7 40. Kg3 b6 41. Rd5 Re7 42. Rd2 Re6 43. Kh2 Ke7 44. g4 Re4 45. h4 Rxg4 46. Kh3 Re4 47. Re2 Rxe2 48. Kg4 Rxa2 49. Kg5 Ra3 50. Kxg6 Rxb3 51. c5 bxc5 52. h5 c4 53. h6 Rh3 54. h7 Rxh7 55. Kxh7 1/2-1/2",
    B: "1961",
  },
  {
    W: "2216",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. O-O h6 5. Re1 d6 6. c3 g6 7. h3 Bg7 8. d4 Qe7 9. Nbd2 O-O 10. Nf1 Kh7 11. Ng3 Ng8 12. d5 Nd8 13. Bd3 f6 14. Nh2 g5 15. Qh5 Rf7 16. Rf1 Qf8 17. h4 Qe7 18. hxg5 fxg5 19. Nf5 Qf6 20. Ng4 Qg6 21. Qh3 h5 22. f3 Nh6 23. Ngxh6 Bxh6 24. g4 h4 25. Kg2 Kg8 26. Rh1 Bf8 27. Be3 c6 28. dxc6 bxc6 29. Rad1 Qe6 30. Bxg5 Rh7 31. Nxd6 Bxd6 32. Bxd8 Ba6 33. Bxa6 Rxd8 34. b3 Rf8 35. Bc4 1-0",
    B: "2203",
  },
  {
    W: "1707",
    T: "180+0",
    P: "1. d4 Nf6 2. Nf3 e6 3. e3 d5 4. Bd3 c5 5. c3 Nc6 6. dxc5 Bxc5 7. Nd4 O-O 8. Nxc6 bxc6 9. b4 Bd6 10. O-O Qc7 11. f4 e5 12. g3 Bh3 13. Rf2 Qb6 14. fxe5 Bxe5 15. a4 a5 16. b5 cxb5 17. axb5 Rfc8 18. Bd2 Ne4 19. Bxe4 dxe4 20. Qh5 Rd8 21. Qxe5 Rxd2 22. Nxd2 Qxe3 23. Qxe4 Qa7 24. Qh4 Bf5 25. Qd4 Qxd4 26. cxd4 Bg6 27. h4 Bd3 28. b6 a4 29. Rf3 Be4 30. Nxe4 g6 31. Rb1 Rb8 32. Ra3 Re8 33. Nd6 Rd8 34. Nb5 Rb8 35. Rxa4 Rxb6 36. Rab4 Rf6 37. Nc3 Rf3 38. Kg2 Rd3 39. Nd5 Ra3 40. Nf6+ Kg7 41. Rb6 h5 42. g4 Rd3 43. gxh5 gxh5 44. Nxh5+ Kf8 45. Rb8+ Ke7 46. Re1+ 1-0",
    B: "1659",
  },
  {
    W: "1854",
    T: "180+0",
    P: "1. a3 e5 2. d4 exd4 3. c3 Nc6 4. Nf3 d5 5. Nxd4 Nxd4 6. Qxd4 c6 7. e4 dxe4 8. Qb4 0-1",
    B: "1819",
  },
  {
    W: "1982",
    T: "180+0",
    P: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. d4 Nc6 5. c4 Nf6 6. Nc3 e6 7. Bf4 Bb4 8. a3 Bxc3+ 9. bxc3 O-O 10. cxd5 Nxd5 11. Qd2 Nxf4 12. Qxf4 b6 13. Bd3 Bb7 14. Bxh7+ Kxh7 15. Ng5+ Kg6 16. h4 f6 17. Nxe6 Qe7 18. h5+ Kf7 19. d5 Ne5 20. O-O-O Qxa3+ 21. Kc2 Qa2+ 22. Kc1 Rac8 23. Ng5+ Kg8 24. Qd4 Rc4 25. Qd2 Qa3+ 26. Qb2 Rxc3+ 27. Kb1 Rb3 0-1",
    B: "2243",
  },
  {
    W: "1116",
    T: "180+0",
    P: "1. e4 c5 2. d4 Nc6 3. dxc5 d6 4. cxd6 exd6 5. c4 a6 6. a4 b5 7. b3 bxc4 8. bxc4 Nb4 9. Bd2 d5 10. cxd5 g6 11. f3 f5 12. Bxb4 Bxb4+ 13. Nd2 fxe4 14. fxe4 Nf6 15. Ne2 Nxe4 16. Ra2 Qxd5 17. g3 Bxd2+ 18. Rxd2 Nxd2 19. Qxd2 Qxd2+ 20. Kxd2 Bf5 21. Nf4 O-O-O+ 22. Ke3 Rhe8+ 23. Kf3 Rd3+ 24. Bxd3 1-0",
    B: "995",
  },
  {
    W: "1779",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nge7 4. O-O a6 5. c3 d6 6. Ba4 b5 7. Bc2 Bg4 8. d4 exd4 9. cxd4 Bxf3 10. Qxf3 Nxd4 11. Qd3 Nxc2 12. Qxc2 Ng6 13. Qc6+ Ke7 14. Nc3 Ne5 15. Nd5+ Ke6 16. Nxc7+ Kf6 17. Nd5+ Ke6 18. Qc2 Rc8 19. Qe2 Nc4 20. Qg4+ Ke5 21. Bf4+ Kd4 22. Be3+ Nxe3 23. fxe3+ Kc5 24. Rac1# 1-0",
    B: "1733",
  },
  {
    W: "1502",
    T: "180+0",
    P: "1. f4 d5 2. Nf3 Nc6 3. c3 Bg4 4. d4 e6 5. Be3 Nf6 6. h3 Bh5 7. g4 Bxg4 8. hxg4 Nxg4 9. Qd2 Bd6 10. Na3 Ne7 11. Nb5 Nf5 12. Nxd6+ Qxd6 13. Bg1 c5 14. Bh3 Ng3 15. Bxg4 Nxh1 16. O-O-O cxd4 17. Bxd4 Ng3 18. Be5 Qa6 19. Nd4 Qxa2 20. Qc2 Qa1+ 21. Kd2 Ne4+ 22. Ke3 Qa6 23. Nxe6 fxe6 24. Bxe6 Qxe6 25. Qa4+ Qc6 26. Qd4 Nd6 27. Bxg7 Nf5+ 28. Kf2 Nxd4 29. Rxd4 Rg8 30. Be5 Qg6 31. Ke3 Qg3+ 32. Kd2 Qg1 33. c4 Rg3 34. Rxd5 Rd8 0-1",
    B: "1521",
  },
  {
    W: "1719",
    T: "180+0",
    P: "1. e4 e5 2. d4 Qf6 3. dxe5 Qxe5 4. Nc3 Bb4 5. Bd2 Bxc3 6. Bxc3 Qxe4+ 7. Qe2 Qxe2+ 8. Bxe2 f6 9. Nf3 Ne7 10. O-O-O O-O 11. Bc4+ Kh8 12. Rhe1 Nec6 13. Nd4 Nxd4 14. Bxd4 d6 15. h4 Nc6 16. Bc3 a6 17. h5 h6 18. g4 b5 19. Bd5 Ne5 20. Bxa8 Bxg4 21. f3 Nxf3 22. Bxf3 Bxf3 23. Rd3 Bxh5 24. Re7 Bg6 25. Rg3 Bf7 26. Rxc7 Bxa2 27. b3 h5 28. Rgxg7 Ra8 29. Rh7+ Kg8 30. Rxh5 a5 31. Rxb5 a4 32. Kb2 axb3 33. cxb3 Kf8 34. Bxf6 Ke8 35. Rh5 1-0",
    B: "1725",
  },
  {
    W: "1904",
    T: "180+0",
    P: "1. e4 c5 2. c3 e6 3. d4 d5 4. e5 cxd4 5. cxd4 Nc6 6. Nf3 Nge7 7. Bd3 Bd7 8. O-O Ng6 9. a3 Be7 10. b4 O-O 11. g3 b6 12. Be3 h5 13. Nc3 h4 14. gxh4 Nxh4 15. Nxh4 Bxh4 16. Kh1 Bg5 17. Qh5 Bh6 18. Bxh6 gxh6 19. Rg1+ Kh8 20. Qxh6# 1-0",
    B: "1903",
  },
  {
    W: "2097",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bb5 Nd4 4. Nxd4 cxd4 5. O-O g6 6. d3 Bg7 7. c3 a6 8. Ba4 b5 9. Bb3 Bb7 10. cxd4 Bxd4 11. Nc3 e6 12. Be3 Bg7 13. Qd2 Ne7 14. Rac1 O-O 15. Rc2 Rc8 16. Rfc1 d5 17. exd5 Nxd5 18. Nxd5 Rxc2 19. Qxc2 Bxd5 20. Bc5 Re8 21. f3 Bxb3 22. Qxb3 Bd4+ 23. Kf1 Bxc5 24. Rxc5 Qd4 25. Qc3 Rd8 26. Qxd4 Rxd4 27. Ke2 Rd6 28. Ke3 Kf8 29. Ke4 Ke7 30. Rc7+ Rd7 31. Rxd7+ Kxd7 32. g4 f6 33. f4 Kd6 34. b4 h5 35. h3 hxg4 36. hxg4 f5+ 37. gxf5 exf5+ 38. Kd4 Ke6 39. Kc5 g5 40. fxg5 f4 41. d4 f3 42. d5+ Kd7 43. g6 f2 44. g7 f1=Q 45. g8=Q Qf2# 0-1",
    B: "1942",
  },
  {
    W: "1252",
    T: "300+0",
    P: "1. d4 e6 2. c4 d6 3. Nc3 g6 4. Nf3 Bg7 5. Bg5 f6 6. Bh4 Ne7 7. e3 O-O 8. Bd3 Nf5 9. O-O Nxh4 10. Nxh4 f5 11. Nf3 c6 12. Ne2 b6 13. Nf4 c5 14. d5 e5 15. Nxe5 dxe5 16. Ne6 Bxe6 17. dxe6 Qe7 18. Qf3 Na6 19. Rfd1 Qxe6 20. b3 Nb4 21. a3 Nxd3 22. Rxd3 Rad8 23. Rxd8 Rxd8 24. Rd1 Rd7 25. Rxd7 Qxd7 26. a4 Qd3 27. Qa8+ Bf8 28. Qd5+ Qxd5 29. cxd5 Kf7 30. Kf1 Ke7 31. Ke2 Kd6 32. e4 fxe4 33. Ke3 Kxd5 34. g4 a6 35. h3 b5 36. axb5 axb5 37. h4 c4 38. bxc4+ bxc4 39. Kd2 Bc5 40. f4 exf4 41. g5 e3+ 42. Ke2 Ke4 43. Kd1 Kd3 44. Ke1 c3 45. Kd1 c2+ 46. Kc1 Ba3# 0-1",
    B: "1370",
  },
  {
    W: "1259",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. d4 Nc6 4. d5 Nce7 5. c4 Nf6 6. Bd3 c6 7. Bg5 cxd5 8. Bxf6 gxf6 9. cxd5 Bg4 10. O-O f5 11. Nbd2 fxe4 12. Bxe4 Qa5 13. Nb3 Qb4 14. Re1 f5 15. a3 Qxb3 16. Qxb3 1-0",
    B: "1270",
  },
  {
    W: "1493",
    T: "300+0",
    P: "1. Nf3 c5 2. e4 Nc6 3. c3 g6 4. g3 Bg7 5. Bg2 d6 6. O-O Nf6 7. Re1 e5 8. d3 O-O 9. Be3 a6 10. Nbd2 b5 11. Nb3 Bb7 12. d4 c4 13. Nbd2 Qe7 14. d5 Nb8 15. Bg5 h6 16. Be3 Ng4 17. h3 Nxe3 18. Rxe3 Nd7 19. b4 cxb3 20. axb3 Nc5 21. b4 Na4 22. Nb3 Rac8 23. Na5 Ba8 24. Nc6 Bxc6 25. dxc6 Rxc6 26. Qc2 Rfc8 27. Ra3 f5 28. Nd2 f4 29. gxf4 Qc7 30. f5 Rxc3 31. Rexc3 Nxc3 32. fxg6 Ne2+ 33. Kf1 Qxc2 34. Kxe2 Rc3 35. Rxa6 Qd3+ 36. Kd1 Rc2 37. Ra8+ Bf8 38. g7 Kxg7 39. Ra7+ Kg8 40. Bf1 Qxd2# 0-1",
    B: "1535",
  },
  {
    W: "1565",
    T: "300+0",
    P: "1. d4 e5 2. Nf3 e4 3. Ne5 d5 4. e3 f6 5. Ng4 f5 6. Ne5 Ne7 7. Qh5+ g6 8. Qg5 Bg7 9. f4 Nbc6 10. h4 Nb4 11. Na3 Nbc6 12. c4 Nxe5 13. dxe5 Be6 14. cxd5 Nxd5 15. Bc4 Qxg5 16. hxg5 O-O-O 17. Bd2 c6 18. O-O-O b5 19. Bb3 Rhe8 20. Ba5 Rd7 21. Rhe1 Bf8 22. Nc2 Bc5 23. Bxd5 Bxd5 24. Nb4 Bxb4 25. Bxb4 Kb7 26. Bc5 a5 27. Rd4 Re6 28. Red1 Ka6 29. Bd6 c5 30. R4d2 Bxa2 31. Bxc5 Rxd2 32. Rxd2 Rc6 33. Rd6 Rxd6 34. Bxd6 b4 35. Kc2 Kb5 36. b3 a4 37. bxa4+ Kxa4 38. Kb2 Be6 39. g3 Kb5 40. Bf8 Kc4 41. Bd6 Kd3 42. Bc5 Ke2 43. Bxb4 Kf3 44. Be1 Kxe3 45. Kc1 Ke2 46. Bc3 Kf3 47. Be1 Ke2 48. Bc3 Kf2 49. Bd2 Kxg3 50. Kc2 Kf3 51. Kc3 e3 52. Bxe3 Kxe3 0-1",
    B: "1594",
  },
  {
    W: "1728",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Nc6 4. O-O Bg4 5. h3 Bh5 6. c3 Qf6 7. Be2 Bg6 8. d3 O-O-O 9. Be3 Qe6 10. Qa4 f5 11. Bxa7 fxe4 12. dxe4 Bxe4 13. Be3 Nf6 14. Ng5 Qe7 15. f3 Bd5 16. c4 Be6 17. Qa8+ Kd7 18. Qxb7 h6 19. Nxe6 Qxe6 20. Nc3 g5 21. Nb5 Rc8 22. c5 d5 23. Rfd1 d4 24. Bf2 Nh5 25. f4 Nf6 26. fxe5 Qxe5 27. Bf3 Nd5 28. Bg4+ Ke7 29. Qxc6 Ne3 30. Bxe3 Qxe3+ 31. Kh1 Kf7 32. Rf1+ Kg7 33. Bxc8 h5 34. Qxc7+ Kg8 35. Qf7# 1-0",
    B: "1790",
  },
  {
    W: "1880",
    T: "300+0",
    P: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. c4 Nf6 5. cxd5 Nxd5 6. Nc3 Nc6 7. Bb5 Bf5 8. Bxc6+ bxc6 9. Nxd5 Qxd5 10. d4 e6 11. O-O Be7 12. Re1 O-O 13. Bf4 Bf6 14. Be5 Bxe5 15. Nxe5 Rfd8 16. Rc1 Qxd4 17. Qxd4 Rxd4 18. Nxc6 Rd2 19. Ne7+ Kf8 20. Nxf5 exf5 21. Kf1 Rb8 22. Re2 Rxe2 23. Kxe2 Rxb2+ 24. Kf3 Rxa2 25. Rc8+ Ke7 26. Rc7+ Kf6 27. Kf4 0-1",
    B: "1918",
  },
  {
    W: "1858",
    T: "300+0",
    P: "1. e4 g6 2. d4 Bg7 3. Nf3 a6 4. g3 b5 5. c4 c6 6. Nc3 b4 7. Nb1 e6 8. Bg2 Ne7 9. O-O d5 10. exd5 cxd5 11. cxd5 Nxd5 12. a3 Qb6 13. axb4 Qxb4 14. Ra4 Qb6 15. Nh4 Bb7 16. Bxd5 Bxd5 17. Nc3 Bb3 18. Qe2 Bxa4 19. Nxa4 Qb5 20. Qe4 Ra7 21. Nc5 O-O 22. b4 Qc6 23. Qd3 Qd5 24. Bb2 Nc6 25. Nf3 Nxb4 26. Qd2 Nc6 27. Rd1 a5 28. Nd3 Nb4 29. Nf4 Qb3 30. Ne5 Bxe5 31. dxe5 Nd5 32. Nxd5 exd5 33. e6 fxe6 34. Qd4 e5 35. Qxe5 Qxd1+ 36. Kg2 Qf3+ 37. Kg1 Kf7 38. Qg7+ Ke6 39. Qe5+ Kd7 40. Qg7+ Kc6 41. Qc3+ Kd7 42. Qg7+ Ke6 43. Qe5+ Kd7 44. Qg7+ 1/2-1/2",
    B: "1860",
  },
  {
    W: "1736",
    T: "300+0",
    P: "1. d4 e6 2. Bf4 c5 3. d5 exd5 4. Qxd5 Nf6 5. Qd1 d6 6. e3 Nc6 7. a3 a6 8. Bc4 Be7 9. Nf3 O-O 10. O-O b5 11. Ba2 Bb7 12. c3 Qc7 13. Nbd2 Nh5 14. Bg3 Nxg3 15. fxg3 Kh8 16. Rc1 Ne5 17. Nxe5 dxe5 18. Bxf7 Qc6 19. e4 Rxf7 20. Rxf7 Bf6 21. Qf3 Rd8 22. Qg4 Kg8 23. Rxb7 Qxb7 24. Nf3 Qc6 25. Nxe5 Bxe5 26. Rf1 Rf8 27. Rf5 Rxf5 28. Qxf5 Bf6 29. g4 g6 30. Qf3 Kg7 31. g3 b4 32. h4 a5 33. g5 Be5 34. g4 bxa3 35. bxa3 c4 36. h5 Qc5+ 37. Kg2 Qxa3 38. h6+ Kg8 39. Qd1 Bxc3 40. Qd5+ Kf8 41. Qd8+ Kf7 42. Qd5+ Ke7 43. Qb7+ Kd6 44. Qxh7 Qb2+ 45. Kf3 Be5 46. Qxg6+ Kc5 47. Qh5 Qc3+ 48. Kg2 Qd2+ 49. Kf3 Qd3+ 50. Kg2 Qxe4+ 51. Kf2 Qf4+ 52. Kg2 Qe4+ 53. Kf2 Bd4+ 54. Kg3 Qe3+ 0-1",
    B: "1723",
  },
  {
    W: "1291",
    T: "300+0",
    P: "1. e4 e6 2. Nf3 d5 3. e5 h6 4. d4 g6 5. Bb5+ c6 6. Ba4 Bg7 7. O-O Ne7 8. c3 O-O 9. Be3 b5 10. Bb3 f6 11. Nbd2 Nf5 12. Re1 Nxe3 13. Rxe3 fxe5 14. Nxe5 Nd7 15. Ndf3 g5 16. Bc2 Nf6 17. h3 Qc7 18. Qd3 Ne4 19. Rxe4 Rf5 20. Re3 c5 21. a4 c4 22. Qe2 h5 23. axb5 Qb7 24. Bxf5 exf5 25. Nxg5 Qxb5 26. Qxh5 Qb7 27. Ra5 Be6 28. Rg3 f4 29. Rf3 Rf8 30. Ng6 Bf7 31. Qh7# 1-0",
    B: "1277",
  },
  {
    W: "1431",
    T: "300+0",
    P: "1. d4 c6 2. c4 d5 3. Nc3 dxc4 4. e4 Nf6 5. Bxc4 e6 6. Nf3 Bb4 7. Bd2 O-O 8. e5 Nd5 9. Nxd5 Bxd2+ 10. Qxd2 cxd5 11. Bb3 Nc6 12. O-O Qa5 13. Qxa5 Nxa5 14. Rac1 Bd7 15. Rc7 Bc6 16. Rc1 Rac8 17. Rxc8 Rxc8 18. Bd1 Nc4 19. Be2 Bb5 20. Bxc4 Bxc4 21. b3 b5 22. bxc4 Rxc4 23. Rxc4 bxc4 24. Ne1 Kf8 25. Nc2 Ke7 26. f4 Kd7 27. Kf2 Kc6 28. Ke2 Kb5 29. Kd2 Ka4 30. Kc3 a5 31. a3 Kb5 32. g4 a4 33. f5 exf5 34. gxf5 h5 35. e6 fxe6 36. fxe6 1-0",
    B: "1490",
  },
  {
    W: "1391",
    T: "300+0",
    P: "1. d4 d6 2. Bf4 h6 3. Nf3 Bg4 4. e3 Nf6 5. Nbd2 Nbd7 6. h3 Bh5 7. Bd3 e5 8. dxe5 dxe5 9. Nxe5 Bxd1 10. Rxd1 Nxe5 11. Bxe5 Bd6 12. Bc3 O-O 13. f4 a6 14. O-O Bc5 15. Rfe1 Qe8 16. Nf1 Nd5 17. b4 Bxe3+ 18. Nxe3 Nxe3 19. Kf2 Qc6 20. Bxg7 Kxg7 21. Rxe3 Rfe8 22. Rg3+ Kh8 23. a3 Rg8 24. Re3 Qxg2+ 25. Ke1 Qg1+ 26. Bf1 Rae8 27. Kd2 Qxe3# 0-1",
    B: "1309",
  },
  {
    W: "845",
    T: "300+0",
    P: "1. e4 g5 2. f3 Bg7 3. Nh3 g4 4. fxg4 c5 5. Qe2 Qb6 6. Qe3 Be5 7. Qf3 f6 8. g5 Bd4 9. c3 d6 10. cxd4 Bxh3 11. Qxh3 Qb4 12. Nc3 Nc6 13. Nd5 Qc4 1-0",
    B: "762",
  },
  {
    W: "1401",
    T: "300+0",
    P: "1. d4 Nc6 2. c4 Nf6 3. d5 Nb8 4. e3 c6 5. dxc6 Nxc6 6. Nc3 d5 7. b3 dxc4 8. Bxc4 Be6 9. Qxd8+ Nxd8 10. Bxe6 Nxe6 11. Nf3 O-O-O 12. O-O Nc7 13. Bb2 e6 14. a4 Bb4 15. Ne5 Rhf8 16. Rfc1 Bxc3 17. Bxc3 Ncd5 18. Ba5+ Kb8 19. Bxd8 Rxd8 20. g3 Nb4 21. Nxf7 Rd7 22. Ne5 Re7 23. Rc4 a5 24. Rac1 b6 25. Rc8+ Kb7 26. R8c4 Ka6 27. R1c3 Na2 28. Rc2 Nb4 29. Rd2 Nbd5 30. e4 Nb4 31. Nd3 Nxd3 32. Rxd3 Rb7 33. Re3 Nd7 34. Rc6 e5 35. f4 exf4 36. gxf4 Nc5 37. e5 h6 38. e6 Rf7 39. exf7 1-0",
    B: "1392",
  },
  {
    W: "1203",
    T: "300+0",
    P: "1. d3 g6 2. e3 Bg7 3. Be2 Nf6 4. c3 O-O 5. d4 d6 6. Bd3 e5 7. Ne2 exd4 8. cxd4 Re8 9. O-O Bg4 10. Nbc3 d5 11. Qc2 Nc6 12. Bb5 Nb4 13. Qb3 Nc6 14. Qa4 a6 15. Bxc6 bxc6 16. Qxc6 Bd7 17. Qb7 Ne4 18. Nxe4 dxe4 19. Bd2 a5 20. Rac1 c6 21. d5 Rb8 22. Qa6 Ra8 23. Qc4 Bxb2 24. Rc2 cxd5 25. Qxd5 Bg7 26. Rc5 Re5 27. Qc4 Rxc5 28. Qxc5 Rc8 29. Qd5 Rc2 30. Bxa5 Qc8 31. g3 Rxe2 32. Kg2 Rc2 33. Qxe4 Bh3+ 34. Kf3 Bg4+ 35. Kg2 Bh3+ 36. Kh1 Bxf1 37. Bb4 Rc4 38. Qb1 Qc6+ 39. Kg1 Bh3 0-1",
    B: "1221",
  },
  {
    W: "1747",
    T: "180+2",
    P: "1. d4 d5 2. Nf3 Nf6 3. Bf4 e6 4. e3 c5 5. Nbd2 Nc6 6. Be2 Be7 7. O-O a6 8. c3 h6 9. h3 O-O 10. Re1 b5 11. Ne5 Bb7 12. Ndf3 Rc8 13. Rc1 c4 14. b3 Re8 15. bxc4 bxc4 16. Rb1 Ba8 17. Qa4 Qb6 18. Rxb6 1-0",
    B: "1738",
  },
  {
    W: "1553",
    T: "180+2",
    P: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. dxc5 Bxc5 6. Qxd5 Qxd5 7. Nxd5 Bb6 8. Nf3 Bg4 9. Bf4 Bxf3 10. gxf3 Nc6 11. e3 Nge7 12. Rc1 Nxd5 13. Bb5 Nxf4 14. exf4 Ba5+ 15. Kf1 O-O 16. Bxc6 bxc6 17. Rxc6 Rad8 18. Kg2 Rd2 19. Rb1 Bb6 20. Rc3 Rxf2+ 21. Kg3 Re2 22. a4 Bf2+ 23. Kh3 Rfe8 24. f5 R2e3 25. Rbc1 Rb8 26. Rc8+ Rxc8 27. Rxc8+ Re8 28. Rxe8# 1-0",
    B: "1641",
  },
  {
    W: "1535",
    T: "180+2",
    P: "1. e4 c5 2. Nc3 Nc6 3. f4 e5 4. fxe5 Nxe5 5. Nf3 Nxf3+ 6. gxf3 b6 7. Ne2 Bb7 8. d3 g6 9. h4 Bg7 10. Bg5 Ne7 11. Nc3 d5 12. Nb5 Bxb2 13. Rb1 Bg7 14. Bh3 O-O 15. exd5 f6 16. Bf4 Qxd5 17. Nc7 Qxa2 18. Be6+ Kh8 19. Bxa2 1-0",
    B: "1524",
  },
  {
    W: "2030",
    T: "180+2",
    P: "1. e4 c5 2. d4 cxd4 3. c3 Nf6 4. e5 Nd5 5. cxd4 d6 6. f4 Nc6 7. Nf3 dxe5 8. fxe5 e6 9. Be2 Bb4+ 10. Bd2 Ne3 11. Qa4 Bxd2+ 12. Nbxd2 Nxg2+ 13. Kf2 Nf4 14. d5 Nxd5 15. Qg4 O-O 16. Rhg1 g6 17. Ne4 f5 18. exf6 Nxf6 19. Nxf6+ Qxf6 20. h4 Ne5 21. Qg3 Nxf3 22. Bxf3 e5 23. Rae1 Bf5 24. Rxe5 Rae8 25. Rge1 Rxe5 26. Rxe5 Qb6+ 27. Kf1 Rd8 28. Rxf5 Qa6+ 29. Kg2 Rd2+ 30. Kg1 Qxa2 31. Qb8+ Kg7 32. Qf8# 1-0",
    B: "2093",
  },
  {
    W: "1736",
    T: "180+2",
    P: "1. d4 Nf6 2. c4 e6 3. Nf3 g6 4. g3 Bg7 5. Nc3 O-O 6. h4 d5 7. c5 c6 8. a4 Qa5 9. Bd2 Qc7 10. Qc1 Ne4 11. Bh6 b6 12. h5 bxc5 13. Bxg7 Kxg7 14. hxg6 h5 15. Rxh5 Kf6 16. g7 Rg8 17. Qh6+ Ke7 18. Qh8 Qd8 19. e3 Nxc3 20. bxc3 Nd7 21. a5 Nf6 22. Rh2 Ng4 23. Rh1 cxd4 24. Qh4+ Nf6 25. Ne5 Kd6 26. Nxf7+ Ke7 27. Nxd8 Bb7 28. Nxb7 Rxg7 29. Qf4 Rf8 30. Qc7+ Nd7 31. Nc5 Rd8 32. Rh6 dxe3 33. Rxe6+ Kf7 34. Qxd8 exf2+ 35. Kxf2 Nxc5 36. Qe7+ Kg8 37. Qe8+ 1-0",
    B: "1732",
  },
  {
    W: "1769",
    T: "180+2",
    P: "1. d4 Nf6 2. Nc3 d5 3. e4 e6 4. e5 Nfd7 5. f4 c5 6. Nf3 Nc6 7. Be3 cxd4 8. Nxd4 Bc5 9. Nxc6 bxc6 10. Bxc5 Nxc5 11. Bd3 Nxd3+ 12. Qxd3 O-O 13. O-O c5 14. Rf3 a5 15. Rh3 g6 16. Ne2 Qb6 17. Kh1 Ba6 18. Qf3 Qxb2 19. Rg1 Qxc2 20. Ng3 d4 21. Nh5 gxh5 22. Qxh5 Qf5 23. Rg3+ Kh8 24. Rg5 Qxf4 25. Rg4 Be2 26. Qxh7+ Kxh7 27. Rxf4 d3 28. Re4 Rac8 29. Rc4 d2 30. Rc2 d1=Q 31. Rxd1 Bxd1 32. Rc4 Rfd8 33. g4 Bxg4 34. Rxg4 Rd1+ 35. Kg2 c4 36. Rh4+ Kg7 37. Rg4+ Kf8 38. h4 c3 39. h5 Ke7 40. h6 c2 41. h7 c1=Q 42. Rg8 Rg1+ 43. Kh3 Rgxg8 0-1",
    B: "1815",
  },
  {
    W: "2023",
    T: "180+2",
    P: "1. Nf3 c5 2. Nc3 g6 3. d4 cxd4 4. Qxd4 Nf6 5. Bg5 Bg7 6. O-O-O Nc6 7. Qd2 d6 8. Bh6 O-O 9. Bxg7 Kxg7 10. h3 Qa5 11. e4 Be6 12. a3 Rfc8 13. g4 a6 14. Nd4 b5 15. Nxe6+ fxe6 16. f4 b4 17. Nb1 Qc5 18. Bg2 Rab8 19. a4 Qc4 20. b3 Qc5 21. Kb2 Kf7 22. g5 Nh5 23. f5 exf5 24. exf5 gxf5 25. Bd5+ e6 26. Bc4 Ne5 27. Bxa6 Rb6 28. Bb5 1-0",
    B: "1998",
  },
  {
    W: "1038",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nxd4 5. Qxd4 c5 6. Qd1 d6 7. Nc3 Nf6 8. Bg5 Be7 9. Nd5 Nxd5 10. Bxe7 Nxe7 11. Bb5+ Nc6 12. O-O Qb6 13. a4 a6 14. Bc4 Ne5 15. Bb3 c4 16. Ba2 O-O 17. b3 cxb3 18. Bxb3 Be6 19. a5 Qb5 20. Bxe6 fxe6 21. Qxd6 Nf7 22. Qxe6 Rfe8 23. Qg4 Qc4 0-1",
    B: "1145",
  },
  {
    W: "2076",
    T: "15+0",
    P: "1. d4 g6 2. Nf3 Bg7 3. g3 d5 4. Bg2 e5 5. O-O e4 6. c4 exf3 7. exf3 Ne7 8. Re1 O-O 9. cxd5 Nxd5 10. Nc3 Nf6 11. Bg5 h6 12. Bxf6 Bxf6 13. f4 Qxd4 14. Qxd4 Bxd4 15. Rad1 Bg7 16. f5 Bxf5 17. Ne4 Nc6 18. Nc5 Rad8 19. Nxb7 Rxd1 20. Rxd1 Nd4 21. b3 Ne2+ 22. Kf1 Nc3 23. g4 Nxd1 24. gxf5 gxf5 25. Nc5 f4 26. Nd7 Bf6 27. Bf3 Bg7 28. Nc5 f6 29. Ne4 f5 30. Ng3 Re8 31. Bd5+ Kh8 32. Nxf5 Bd4 33. Bc4 Be3 34. Nxh6 Bxf2 35. Nf7+ 1-0",
    B: "2005",
  },
  {
    W: "1438",
    T: "180+0",
    P: "1. d4 d5 2. c4 c6 3. Nc3 e6 4. Nf3 Bb4 5. Bd2 Nf6 6. e3 O-O 7. Bd3 g6 8. O-O Bxc3 9. Bxc3 Ne4 10. Bb4 Re8 11. Ne5 f6 12. f3 Nd6 13. Ng4 f5 14. Ne5 b6 15. c5 a5 16. Ba3 bxc5 17. Bxc5 Qc7 18. Qa4 Bd7 19. Rac1 Nb7 20. Nxd7 Nxc5 21. Nxc5 e5 22. Qc2 e4 23. fxe4 dxe4 24. Bc4+ Kf8 25. Ne6+ Rxe6 26. Bxe6 Qd6 27. Bb3 Ra7 28. Qc5 Qxc5 29. Rxc5 a4 30. Bc2 a3 31. bxa3 Rxa3 32. Bb3 Ra6 33. Rfc1 Rb6 34. R1c2 Ke7 35. Kf2 Kd6 36. h4 h6 37. g4 fxg4 38. Kg3 h5 39. Kf4 Nd7 40. Kxe4 Nxc5+ 41. Rxc5 Rb4 0-1",
    B: "1449",
  },
  {
    W: "1396",
    T: "300+0",
    P: "1. c4 b6 2. Nc3 Bb7 3. Nf3 Nc6 4. e4 e5 5. Be2 Bc5 6. O-O d6 7. a3 Bd4 8. b4 a5 9. b5 Bxc3 10. dxc3 Nce7 11. Bb2 Bxe4 12. Re1 Nf6 13. Bd3 Bxd3 14. Qxd3 e4 15. Rxe4 Nxe4 16. Qxe4 O-O 17. Ng5 Ng6 18. f4 h6 19. f5 Qxg5 20. fxg6 Qxg6 21. Qc6 Rac8 22. h3 Rfe8 23. a4 Qe4 24. Qxe4 Rxe4 0-1",
    B: "1905",
  },
  {
    W: "1974",
    T: "300+0",
    P: "1. d4 e6 2. Bf4 c5 3. c3 a6 4. e3 Qb6 5. b3 d6 6. Bd3 Qc7 7. Ne2 Nd7 8. O-O b6 9. Nd2 Bb7 10. Nc4 Be7 11. Ng3 Rd8 12. e4 g6 13. Qe2 h6 14. dxc5 dxc5 15. Bxc7 Rc8 16. Bd6 Bh4 17. Bf4 f6 18. Nd6+ Kd8 19. Nxb7+ Ke7 20. Bd6+ Ke8 21. Qg4 Bxg3 22. Qxg6# 1-0",
    B: "1240",
  },
  {
    W: "1839",
    T: "300+0",
    P: "1. d4 Nf6 2. c4 d5 3. Nc3 e6 4. Bg5 Be7 5. Nf3 c5 6. dxc5 Bxc5 7. cxd5 Bb4 8. dxe6 Qxd1+ 9. Rxd1 Bxe6 10. e3 Ne4 11. Rd8# 1-0",
    B: "1713",
  },
  {
    W: "1849",
    T: "300+0",
    P: "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. Bf4 Nc6 5. Nf3 Bb4+ 6. Nc3 Bxc3+ 7. bxc3 Qe7 8. Qd5 Qa3 9. Qd2 Qe7 10. e3 Ngxe5 11. Nxe5 Nxe5 12. Be2 O-O 13. O-O d6 14. Rab1 b6 15. Bxe5 Qxe5 16. Bf3 Rb8 17. Rfd1 g5 18. Qd4 g4 19. Bc6 Be6 20. Rb5 Qxd4 21. cxd4 Bxc4 22. Rg5+ Kh8 23. Rxg4 f5 24. Rg5 h6 25. Rh5 Be2 26. Rxh6+ Kg7 27. Rd2 Kxh6 28. Rxe2 b5 29. Rc2 b4 30. Rb2 a5 31. Ba4 c5 32. dxc5 dxc5 33. Rc2 Rfc8 34. Rc4 Kg5 35. Kf1 Rb7 36. Ke2 Rd7 37. h3 Rd5 38. g3 Rcd8 39. h4+ Kf6 40. Kf3 Rc8 41. Kf4 Rd2 42. Rc2 Rxc2 43. Bxc2 c4 44. Bxf5 c3 45. Bxc8 c2 46. h5 c1=Q 47. Bg4 Qc4+ 48. Kf3 Qxa2 49. h6 Qc2 50. Kf4 b3 51. Bf5 Qxf5# 0-1",
    B: "1848",
  },
  {
    W: "1670",
    T: "300+0",
    P: "1. e3 e5 2. c3 Nf6 3. h4 d5 4. h5 Bg4 5. Qb3 b6 6. a4 Nc6 7. Bb5 Bd7 8. Nf3 Na5 9. Bxd7+ Qxd7 10. Qc2 Bd6 11. h6 g6 12. Ng5 Qg4 13. Nxf7 Kxf7 14. b4 Qxg2 15. Rf1 Nc6 16. d3 e4 17. Na3 exd3 18. Qxd3 Ne5 19. Qe2 a5 20. f4 Neg4 21. Qxg2 Nxe3 22. Qe2 Rhe8 23. Bxe3 axb4 24. Nb5 Bc5 25. Nd4 bxc3 26. O-O-O Bxd4 27. Rxd4 c5 28. Rdd1 d4 29. Qc4+ Kf8 30. Bf2 Ne4 31. Bh4 Nd2 32. Rfe1 Nxc4 33. Rxe8+ Rxe8 34. Kc2 Ne3+ 35. Kc1 Nxd1 36. Kxd1 Re4 37. Bg5 Kf7 38. Kc2 Ke6 39. Kd3 Kd5 40. Bd8 c4+ 41. Kc2 Re2+ 42. Kc1 d3 43. Bg5 d2+ 44. Kd1 Rg2 45. Bh4 Rg1+ 46. Kc2 d1=Q+ 47. Kxc3 Qd3+ 48. Kb2 Rb1+ 49. Ka2 Qb3# 0-1",
    B: "1709",
  },
  {
    W: "1070",
    T: "300+0",
    P: "1. e4 e5 2. Nc3 c5 3. Bc4 d6 4. Nd5 Be6 5. d3 Nc6 6. Be3 Nf6 7. Nxf6+ Qxf6 8. Nf3 Bxc4 9. dxc4 Be7 10. Qd5 O-O 11. O-O-O Rab8 12. h4 Nb4 13. Qd2 Nxa2+ 14. Kb1 Nb4 0-1",
    B: "1286",
  },
  {
    W: "1653",
    T: "300+7",
    P: "1. d4 d5 2. c4 Nf6 3. Nc3 Bf5 4. h3 e6 5. Bg5 Bb4 6. cxd5 Bxc3+ 7. bxc3 exd5 8. e3 h6 9. Bxf6 Qxf6 10. Nf3 Nc6 11. Bd3 O-O 12. Qc2 Bxd3 13. Qxd3 a6 14. O-O g5 15. Nh2 h5 16. Qe2 Qg6 17. f4 g4 18. f5 Qg5 19. Rf4 gxh3 20. Nf3 Qxg2+ 21. Qxg2+ hxg2 22. Kxg2 Kh8 23. Kf2 Rg8 24. f6 Rg6 25. Rh4 Rxf6 26. Rxh5+ Kg8 27. Ke2 Na5 28. Rg1+ Rg6 29. Rxg6+ fxg6 30. Rxd5 Nc4 31. Rc5 Nd6 32. Rxc7 Nf5 33. Rxb7 Re8 34. Ne5 Ng3+ 35. Kf3 Nf1 36. Kf2 Nd2 37. Rb6 Rf8+ 38. Ke2 Nb1 39. Rxb1 1-0",
    B: "1524",
  },
  {
    W: "1821",
    T: "300+5",
    P: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Nf3 d5 6. cxd5 Nxd5 7. Bd2 Nxc3 8. bxc3 Be7 9. e4 c5 10. e5 cxd4 11. cxd4 Nc6 12. Be3 Qa5+ 13. Nd2 Bb4 14. Be2 Bc3 15. Rb1 Nxd4 16. O-O Bd7 17. Nc4 Qd5 18. Bxd4 Bxd4 19. Qb3 Bc6 20. Bf3 Qc5 21. Bxc6 bxc6 22. Rfc1 Bxf2+ 23. Kf1 Bh4 24. Ne3 Qxe5 25. Kg1 Rab8 26. Qd3 Bg5 27. Re1 Rxb1 0-1",
    B: "1683",
  },
  {
    W: "1373",
    T: "60+0",
    P: "1. e4 d5 2. Bc4 dxc4 3. d3 cxd3 4. Qxd3 Qxd3 5. cxd3 e5 6. Bf4 exf4 7. Nc3 Nf6 8. Nf3 Nc6 9. g3 Bb4 10. gxf4 Bxc3+ 11. bxc3 b5 12. e5 Nd5 13. O-O Nxc3 14. Rac1 b4 15. a3 O-O 16. axb4 Nxb4 17. Rxc3 Nd5 18. Rc5 Bh3 19. Rfc1 Nxf4 20. Rxc7 Nxd3 21. Rd1 Nf4 22. Rd4 Rac8 23. Rxc8 Rxc8 24. Rxf4 Rc1+ 25. Ne1 Rxe1# 0-1",
    B: "1383",
  },
  {
    W: "1579",
    T: "180+0",
    P: "1. d4 d5 2. Bf4 Nc6 3. e3 Bf5 4. c4 dxc4 5. Bxc4 e6 6. Nc3 Nb4 7. Rc1 Bd6 8. a3 Bxf4 9. exf4 Nc6 10. Nf3 h6 11. O-O Nf6 12. d5 Na5 13. Bb5+ c6 14. dxc6 bxc6 15. Qxd8+ Rxd8 16. Ba4 O-O 17. b4 Nc4 18. Bxc6 Nxa3 19. Ra1 Nc4 20. Rxa7 Nd2 21. Nxd2 Rxd2 22. b5 Rc2 23. Nd1 Ne4 24. Ne3 Rc5 25. Nxf5 exf5 26. Rc7 Rb8 27. Rd1 Rbxb5 28. Bxb5 Rxc7 29. Rd8+ Kh7 30. h3 Rc1+ 31. Kh2 Nxf2 32. Rd7 Kg6 33. Be2 Ne4 34. Bf3 Rc4 35. Ra7 Rc3 36. h4 Nf2 37. Ra8 Ng4+ 38. Kg3 Nf6 39. Ra7 Rc7 40. Rxc7 1-0",
    B: "1562",
  },
  {
    W: "1909",
    T: "180+0",
    P: "1. e4 c5 2. Bc4 e6 3. Ne2 a6 4. O-O b5 5. d4 bxc4 6. dxc5 Bxc5 7. Nec3 Nf6 8. Qf3 Bb7 9. Be3 Bxe3 10. fxe3 Qc7 11. Nd2 Nc6 12. Rad1 Ne5 13. Qf4 Rc8 14. Nxc4 Nxc4 15. e5 Qxe5 16. Qd4 Qxd4 17. Rxd4 Nxe3 18. Re1 Nxc2 0-1",
    B: "1674",
  },
  {
    W: "1651",
    T: "420+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nc3 Bb4 4. d3 Nc6 5. Bd2 O-O 6. a3 Ba5 7. Nd5 Bxd2+ 8. Qxd2 d6 9. h3 Be6 10. c4 Bxd5 11. cxd5 Nd4 12. Nxd4 exd4 13. Qb4 c5 14. dxc6 bxc6 15. Qxd4 Rb8 16. b4 a5 17. Rb1 axb4 18. axb4 c5 19. Qc3 Qb6 20. b5 Rfe8 21. Be2 d5 22. Bf3 dxe4 23. dxe4 Nxe4 24. Qc2 Ng3+ 25. Kd1 Nxh1 26. Bc6 Rbd8+ 27. Kc1 Re1+ 28. Kb2 Rxb1+ 29. Qxb1 Nxf2 30. Qf5 Nd1+ 31. Kc1 Nc3 32. Qg5 Ra8 33. Qf5 Rd8 34. Qe5 Nxb5 35. Qd5 Rxd5 36. Bxd5 Nd4 0-1",
    B: "1604",
  },
  {
    W: "1424",
    T: "60+0",
    P: "1. e4 g6 2. g3 Bg7 3. Bg2 c6 4. d3 Qb6 5. Nf3 Bxb2 6. Bxb2 Qxb2 7. Nbd2 Qb6 8. Rb1 Qc5 9. O-O Na6 10. Nb3 Qd6 11. e5 Qe6 12. d4 b6 13. Qd3 Bb7 14. Nbd2 O-O-O 15. Ne4 Kb8 16. Neg5 Qg4 17. Nxf7 Nf6 18. Nxd8 Nd5 19. Nxb7 Kxb7 20. h3 Qe6 21. Ng5 Qf5 22. Qxf5 gxf5 23. Nf7 Rf8 24. Bxd5 cxd5 25. Ng5 Nc7 26. Nxh7 Rf7 27. Ng5 Rg7 28. h4 Ne6 29. Nxe6 dxe6 30. Rbc1 Rg4 31. c4 Rxd4 32. cxd5 exd5 33. e6 Re4 34. Rcd1 Rxe6 35. Rxd5 Rc6 36. Rd7+ Rc7 37. Rfd1 Rxd7 38. Rxd7+ Kc6 39. Rxe7 Kc5 40. Re5+ Kc4 41. Rxf5 Kc3 42. Rf4 Kd3 43. Rf3+ Kd4 44. Kg2 Kd5 45. h5 Kc5 46. h6 Kd6 47. h7 Kd5 48. h8=Q Ke4 49. Qc3 Kd5 50. Qd3+ Ke6 51. Rf5 Ke7 52. Qd5 1-0",
    B: "1290",
  },
  {
    W: "1233",
    T: "60+0",
    P: "1. d4 c5 2. Bf4 cxd4 3. Nf3 d6 4. Na3 e5 5. Bg3 d5 6. Nxe5 Qa5+ 7. Qd2 Bb4 8. c3 dxc3 9. bxc3 Bxc3 10. Nac4 Bxd2+ 11. Kd1 dxc4 12. Nxc4 Qa4+ 13. Kxd2 Qxc4 14. e3 Bf5 15. Bxc4 Nh6 16. Bf4 O-O 17. Bxb8 Rd8+ 18. Ke2 Raxb8 19. Rac1 Bg4+ 20. f3 1-0",
    B: "1162",
  },
  {
    W: "1928",
    T: "60+0",
    P: "1. d4 e6 2. Nf3 d5 3. e3 c5 4. c3 Nc6 5. Bd3 Qb6 6. Nbd2 c4 7. Bc2 Bd7 8. O-O Be7 9. Re1 f6 10. e4 dxe4 11. Nxe4 f5 12. Neg5 Nd8 13. Nh3 Nf6 14. Nf4 O-O 15. d5 Ng4 16. Be3 Nxe3 17. Rxe3 Bd6 18. dxe6 Bxf4 19. exd7 Bxe3 20. Qd5+ Kh8 21. Ng5 Bxf2+ 22. Kh1 Qf6 23. Nf3 Nc6 24. Rf1 Bb6 25. Nd2 Rad8 26. Nxc4 Ne7 27. Qd3 Qe6 28. Ne3 Rxd7 29. Rxf5 Bxe3 30. Rxf8+ Ng8 31. Qxh7# 1-0",
    B: "1941",
  },
  {
    W: "2561",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nf3 Nf6 4. Be2 a6 5. O-O Nc6 6. c4 Qd8 7. d4 Bg4 8. d5 Bxf3 9. Bxf3 Ne5 10. Be2 c6 11. Nc3 cxd5 12. cxd5 g6 13. Bf4 Ned7 14. d6 exd6 15. Bf3 Qc7 16. Re1+ Ne5 17. Rc1 Qb8 18. Bg5 Bg7 19. Bxb7 Qxb7 20. f4 O-O 21. fxe5 dxe5 22. Bxf6 Bxf6 23. Qd5 Qxd5 24. Nxd5 Bg7 25. b3 Rfe8 26. Nc7 Bf6 27. Nxa8 Rxa8 28. Rc6 Bg7 29. Rec1 a5 30. Rc8+ Rxc8 31. Rxc8+ Bf8 32. Ra8 Kg7 33. Rxa5 Bb4 34. Rxe5 Bc3 35. Rb5 f5 36. Kf1 1-0",
    B: "2505",
  },
  {
    W: "1859",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qf6 5. Be3 Bb4+ 6. c3 Ba5 7. b4 Bb6 8. a4 Bxd4 9. Bxd4 Nxd4 10. Qxd4 Qxd4 11. cxd4 d6 12. Bc4 Be6 13. Nd2 Bxc4 14. Nxc4 Nf6 15. f3 O-O 16. O-O d5 17. exd5 Nxd5 18. b5 c6 19. Nd6 cxb5 20. Nxb5 b6 21. Rfe1 Rfd8 22. Re5 a6 23. Na3 Nc3 24. Rc1 Nxa4 25. Rc7 Rxd4 26. Ree7 Rf4 27. g3 Rf6 28. Nc4 Rxf3 29. Ne5 Rf6 30. Nxf7 Rf8 31. Ng5 Rg6 32. Ne4 Nc5 33. Nd6 Ne6 34. Rcd7 Nc5 35. Ne4 Nxe4 0-1",
    B: "1789",
  },
  {
    W: "1494",
    T: "60+0",
    P: "1. d4 e5 2. Bg5 Qxg5 3. e3 e4 4. f3 Qb5 5. Bxb5 c6 6. Be2 Bb4+ 7. c3 Be7 8. fxe4 Nf6 9. e5 Ne4 10. Bd3 d5 11. Bxe4 dxe4 12. Qg4 f5 13. Qxg7 Rf8 14. Qxh7 Nd7 15. Qh5+ Kd8 16. Nh3 Nf6 17. Qe2 Ng4 18. Nf4 Bh4+ 19. Kd2 Bd7 20. g3 Bg5 21. e6 Be8 22. e7+ Kxe7 23. Ng6+ Kf7 24. Nxf8 Kxf8 25. h4 Be7 26. h5 Kg7 27. h6+ Nxh6 28. Na3 Kh7 29. Raf1 Bf8 30. Rxh6+ Kxh6 31. Rh1+ Bh5 32. Qh2 Kg6 33. Qxh5+ Kf6 34. Qh6+ 1-0",
    B: "1476",
  },
  {
    W: "1636",
    T: "60+0",
    P: "1. c4 c5 2. d3 Nc6 3. e4 Nf6 4. Nc3 e6 5. Be2 Be7 6. Nf3 O-O 7. h3 b6 8. O-O Bb7 9. Be3 d5 10. cxd5 exd5 11. e5 d4 12. exf6 Bxf6 13. Bg5 dxc3 14. Bxf6 Qxf6 15. bxc3 Qxc3 16. Rc1 Qf6 17. Nh2 Nd4 18. f3 Qg5 19. Kh1 Rfe8 20. Re1 Rad8 21. Bf1 Rxe1 22. Qxe1 h6 23. Ng4 f5 24. Ne5 Re8 25. f4 Qe7 26. Qg3 Qc7 27. Ng6 Re7 28. Nxe7+ Qxe7 29. Re1 Qc7 30. Re8+ Kh7 0-1",
    B: "1630",
  },
  {
    W: "1500",
    T: "60+0",
    P: "1. d4 Nf6 2. Nf3 g6 3. Be3 Bg7 4. Nc3 O-O 5. g3 d5 6. Bh3 Bxh3 7. Bg5 Bg2 8. Rg1 Bxf3 9. exf3 h6 10. Bxf6 Bxf6 11. g4 e5 12. Qe2 Re8 13. f4 exd4 14. Nd1 Rxe2+ 15. Kxe2 Qe7+ 16. Kd3 Qe4+ 17. Kd2 Qxf4+ 18. Kd3 Bg5 19. c3 Qd2# 0-1",
    B: "1501",
  },
  {
    W: "1404",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nf3 Qa5 4. Bc4 Bg4 5. Nc3 Nf6 6. h3 Bxf3 7. Qxf3 Nc6 8. O-O e6 9. Re1 Bd6 10. Bxe6 fxe6 11. Rxe6+ Ne7 12. d3 O-O 13. Qe2 Rae8 14. Be3 Qf5 15. Bxa7 b6 16. Bxb6 cxb6 17. Rxd6 Ng6 18. Qf3 Qf4 19. Qxf4 Nxf4 20. Rd4 Ne2+ 21. Nxe2 Rxe2 22. Kf1 Rfe8 23. c3 Rc2 24. b3 Rxc3 0-1",
    B: "1420",
  },
  {
    W: "1049",
    T: "60+0",
    P: "1. e4 d5 2. e5 f5 3. d4 Be6 4. Nc3 c6 5. Be3 b6 6. Nf3 g6 7. Ng5 h6 8. Nxe6 Qd7 9. Nf4 Qe6 10. Nxe6 Bg7 11. Bd3 Rh7 12. h4 Kd7 13. Nf4 g5 14. hxg5 hxg5 15. Rxh7 gxf4 16. Bxf4 Na6 17. Rxg7 Nf6 18. exf6 Rf8 19. Rxe7+ Kd8 20. Bd6 Rxf6 21. Qh5 Rxd6 22. Qh8+ Kxe7 23. Bxf5 Kf7 24. O-O-O Rf6 25. Qh7+ Kf8 26. Re1 Rxf5 27. Re7 Rxf2 28. Qh8+ Kxe7 29. Nd1 Rxg2 0-1",
    B: "988",
  },
  {
    W: "1936",
    T: "60+0",
    P: "1. d4 e5 2. Bg5 Qxg5 3. Nf3 Qe7 4. Nxe5 d6 5. Nf3 Be6 6. Nbd2 Nc6 7. e3 d5 8. Be2 O-O-O 9. O-O g6 10. c3 Bg7 11. a4 Nf6 12. b4 Ne4 13. Nb3 Bf5 14. b5 Nb8 15. a5 Nd7 16. a6 b6 17. Nfd2 h5 18. c4 Nxd2 19. Nxd2 g5 20. c5 g4 21. c6 Nb8 22. Nb3 h4 23. Bd3 Bxd3 24. Qxd3 g3 25. Qf5+ Nd7 26. cxd7+ Rxd7 27. Rac1 gxh2+ 28. Kxh2 Kb8 29. Qf4 Rd6 30. Rc6 Rxc6 31. bxc6 Bh6 32. Qf5 f6 33. Re1 Bg5 34. Nd2 Qh7 35. Qd7 Qxd7 36. cxd7 Rd8 37. Nf3 Rxd7 38. Nxg5 fxg5 39. Kh3 g4+ 40. Kxg4 Rh7 41. Kh3 Rg7 42. f3 Rf7 43. e4 dxe4 44. fxe4 Re7 45. e5 c5 0-1",
    B: "1936",
  },
  {
    W: "1561",
    T: "60+0",
    P: "1. e4 f5 2. exf5 Nf6 3. h3 d6 4. g4 e5 5. fxe6 Bxe6 6. Bg2 Be7 7. Bxb7 O-O 8. Bxa8 Nc6 9. Bxc6 Qb8 10. d4 Qb6 11. Bg2 Nd5 12. Bxd5 Bxd5 13. f3 Bg5 14. Bxg5 Re8+ 15. Ne2 Bxf3 16. Rh2 Qb5 17. Nc3 Qxg5 18. Qd3 Qh4+ 19. Kd2 Bxg4 20. hxg4 Qxh2 21. Re1 Kh8 22. Qc4 h6 23. Qxc7 Qf4+ 24. Kd1 Qf3 25. Qxd6 Rf8 26. a3 Qf2 27. Ne4 Qf7 28. c3 Re8 0-1",
    B: "1578",
  },
  {
    W: "2738",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nb3 Nf6 6. Nc3 d6 7. g3 a5 8. Bg2 a4 9. Nd4 Bd7 10. O-O Be7 11. Bg5 O-O 12. Ndb5 Be8 13. Qd2 Ne5 14. Bxf6 gxf6 15. b3 Qa5 16. bxa4 Rc8 17. Nb1 Qxd2 18. Nxd2 Rxc2 19. Nb3 Nc4 20. Rfc1 Rxc1+ 21. Rxc1 Nb6 22. a5 Na4 23. Nc7 Bc6 24. Nd4 Rc8 25. Nxc6 Rxc7 26. Nxe7+ Rxe7 27. Rc8+ Kg7 28. Bf1 Nc5 29. f3 f5 30. exf5 exf5 31. Kf2 Kf6 32. Bc4 Rd7 33. Bd5 Re7 34. Rb8 Nd3+ 35. Kf1 Re1+ 36. Kg2 Re2+ 37. Kh3 Nf2+ 38. Kg2 Nd3+ 39. Kg1 Nc5 40. Bxb7 Rb2 41. a6 Nxa6 42. Ra8 Nc5 43. Bd5 Rb1+ 44. Kg2 Rb2+ 45. Kh3 Nd3 46. f4 Nf2+ 47. Kg2 Ng4+ 48. Kf1 Ne3+ 49. Ke1 Nxd5 50. Rd8 Ke6 51. Re8+ Kd7 52. Rf8 Ke7 53. Rb8 Rxb8 0-1",
    B: "2789",
  },
  {
    W: "1899",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nxe5 Nxe5 4. f4 Ng6 1-0",
    B: "1706",
  },
  {
    W: "1452",
    T: "15+0",
    P: "1. e4 c6 2. Nc3 d5 3. exd5 cxd5 4. d4 Nf6 5. Nf3 Bg4 6. Bf4 Bxf3 7. Qxf3 e6 8. Bd3 Bd6 9. Bxd6 Qxd6 10. O-O-O O-O 11. Rde1 Qb4 12. h4 Qa4 13. g3 Qa3 14. bxa3 Nc6 15. g4 Nxd4 16. Qg2 Nb3+ 17. Kd1 a5 18. cxb3 a4 19. Kd2 axb3 20. axb3 Rxa3 21. g5 Ra2+ 22. Ke3 Ne4 23. Bxe4 d4+ 24. Kxd4 Rd2+ 25. Ke3 e5 0-1",
    B: "1574",
  },
  {
    W: "1176",
    T: "15+0",
    P: "1. e4 d5 2. e5 e6 3. Bb5+ Nd7 4. Bxd7+ Bxd7 5. Nf3 Ne7 6. d3 Nc6 7. Ng5 f6 8. Nf3 fxe5 9. Bg5 e4 10. Bxd8 exf3 11. gxf3 Rxd8 12. Qe2 Nd4 13. Qe3 Bd6 14. Na3 c5 0-1",
    B: "1449",
  },
  {
    W: "1709",
    T: "180+0",
    P: "1. e4 e5 2. Nc3 Nf6 3. f4 exf4 4. e5 Ng8 5. Nf3 Qe7 6. Nd5 Qc5 7. Nxf4 Nc6 8. d4 Qe7 9. Bc4 d6 10. O-O Be6 11. d5 Nxe5 12. dxe6 Nxf3+ 13. Rxf3 O-O-O 14. Nd5 fxe6 15. Nxe7+ Bxe7 16. Bxe6+ Kb8 17. Qd4 Nf6 18. Qa4 c6 19. Be3 c5 20. b4 a6 21. bxc5 dxc5 22. Rb1 Ka8 23. Qb3 Rb8 24. Bf4 Ne4 25. Bxb8 Rxb8 26. Rd3 Bf6 27. Bd5 Nd6 28. Bf3 c4 29. Qb6 cxd3 30. Qxa6# 1-0",
    B: "1666",
  },
  {
    W: "1688",
    T: "180+0",
    P: "1. d4 g6 2. g3 Bg7 3. e3 d5 4. Bg2 e6 5. Nf3 Nf6 6. Nc3 a6 7. O-O Nc6 8. a3 O-O 9. b4 Bd7 10. Ne2 Qe8 11. c3 Ne4 12. Bb2 Nf6 13. Qd2 Qe7 14. Rac1 Rfd8 15. c4 Bc8 16. b5 Nb8 17. bxa6 Nxa6 18. cxd5 Nxd5 19. Nc3 Nxc3 20. Qxc3 Rd6 21. Ne5 f6 22. Nc4 Rd8 23. e4 c6 24. Qd2 Qf7 25. Rfe1 b5 26. Na5 Bd7 27. Nxc6 Bxc6 28. Rxc6 Qd7 29. Rec1 Rac8 30. Rxc8 Rxc8 31. Rxc8+ Qxc8 32. d5 e5 33. d6 Nc5 34. Bf1 Qd7 35. Qd5+ Ne6 36. Bxb5 Qd8 37. Qxe6+ Kh8 38. Qe8+ Qxe8 39. Bxe8 Bf8 40. d7 Be7 41. a4 Bd8 42. Ba3 Kg8 43. f4 Bc7 44. Be7 exf4 45. gxf4 f5 46. e5 h6 47. d8=Q Bxd8 48. Bxd8 Kf8 49. Bxg6 Kg7 50. Bxf5 Kf7 51. Bh4 h5 52. e6+ Ke8 53. Bg6+ Kf8 54. e7+ Kg7 55. e8=Q Kh6 56. Qh8+ Kxg6 57. Qf6+ Kh7 58. Qf7+ Kh6 59. Bg5# 1-0",
    B: "1552",
  },
  {
    W: "1964",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Bc5 5. h3 h6 6. O-O a6 7. c3 b5 8. Bb3 O-O 9. Be3 Bxe3 10. fxe3 Bb7 11. Nbd2 d5 12. exd5 Nxd5 13. d4 Nxe3 14. Qe2 Nxf1 15. Rxf1 exd4 16. Nxd4 Nxd4 17. cxd4 Qxd4+ 18. Kh1 Rad8 19. Nf3 Qd6 20. Ne5 Bd5 21. Bxd5 Qxd5 22. Nf3 Rfe8 23. Qf2 Qxa2 24. Qg3 Re2 25. Rf2 Rxf2 26. Qxf2 Qb1+ 27. Kh2 Qf5 28. Nh4 Qxf2 29. Nf3 Qc5 30. b3 Rd3 31. Kg3 Qc3 32. Kf2 Qxb3 33. Ke2 a5 34. Kf2 a4 35. Kg3 a3 36. h4 a2 37. Kf2 a1=Q 38. Kg3 Qc1 39. Kf2 Qbb2+ 40. Kg3 Qh1 41. Kf4 Rxf3+ 42. gxf3 Qf1 43. Ke4 Qb4+ 44. Ke3 Qfc4 45. Kf2 Qf4 46. Kg2 Qbd2+ 47. Kg1 Qe1+ 48. Kg2 Qeg3+ 0-1",
    B: "1902",
  },
  {
    W: "1331",
    T: "180+0",
    P: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. d4 Nc6 5. Nc3 Bg4 6. Be2 e6 7. O-O Nf6 8. h3 Bh5 9. Bg5 h6 10. Bh4 Bd6 11. Nh2 Bg6 12. f4 Qc7 13. Qc1 Ne4 14. Nf3 Bh5 15. Nd2 Nxd4 16. Ndxe4 Nxe2+ 17. Nxe2 Bxe2 18. Nxd6+ Qxd6 19. Re1 Bb5 20. f5 O-O 21. fxe6 fxe6 22. Qd2 Qc5+ 23. Kh1 Bc6 24. Rf1 d4 25. Rae1 Qd5 26. Rxf8+ Rxf8 27. b3 e5 28. Bg3 e4 29. Qe2 e3 30. Bh4 Rf2 0-1",
    B: "1830",
  },
  {
    W: "1776",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd6 4. Nf3 Bg4 5. Be2 Nc6 6. d4 O-O-O 7. O-O Nf6 8. h3 Bh5 9. Be3 e5 10. Nb5 Qb4 11. a3 Qa5 12. b4 Qb6 13. c3 exd4 14. cxd4 a6 15. Nc3 Qa7 16. b5 axb5 17. Nxb5 Qa5 18. a4 Na7 19. d5 Nxb5 20. Bxb5 Bb4 21. g4 Bg6 22. Rc1 Nxd5 23. Qe2 Nxe3 24. Qxe3 Bd2 25. Nxd2 Rxd2 26. Rc5 Rd3 27. Qxd3 Bxd3 28. Bd7+ Kxd7 29. Rxa5 Bxf1 30. Kxf1 Ra8 31. Rxa8 1-0",
    B: "1672",
  },
  {
    W: "2019",
    T: "180+0",
    P: "1. b4 e6 2. Bb2 f5 3. Nf3 Nf6 4. a3 Be7 5. e3 O-O 6. c4 d6 7. d3 Nbd7 8. g3 b6 9. Bg2 Bb7 10. O-O Qe8 11. Ng5 Bxg2 12. Kxg2 Bd8 13. Qf3 Rb8 14. Nc3 Ng4 15. Nh3 Nge5 16. Qe2 a6 17. d4 Ng4 18. Nf4 c6 19. h3 Ngf6 20. Rad1 Ne4 21. Nxe4 fxe4 22. Qh5 d5 23. Qxe8 Rxe8 24. cxd5 exd5 25. Rc1 Rc8 26. Rc2 g5 27. Ne2 Nb8 28. Rfc1 Re6 29. b5 axb5 30. Nc3 c5 31. Nxd5 c4 32. Nc3 h5 33. Nxb5 Rec6 34. Bc3 Nd7 35. Na7 Nf6 36. Nxc8 Rxc8 37. a4 Nd5 38. Rb1 Bc7 39. Rb5 Ne7 40. Rxg5+ Kh7 41. Rxh5+ Kg6 42. Rb5 Nf5 43. a5 bxa5 44. Bxa5 Bxa5 45. Rxa5 Kg5 46. Rc5 Kg6 47. Rxc8 1-0",
    B: "1980",
  },
  {
    W: "1747",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 e6 3. Bc4 d5 4. exd5 exd5 5. Bb5+ Bd7 6. Qe2+ Be7 7. Ne5 Bxb5 8. Qxb5+ Nd7 9. Nxd7 Qxd7 10. Qd3 Nf6 11. Nc3 O-O 12. O-O Rfe8 13. Qf3 a6 14. d3 d4 15. Ne4 Nd5 16. Qg3 Qe6 17. Re1 Nb4 18. Re2 f5 19. Bd2 fxe4 20. dxe4 Qg6 21. Qb3+ Kh8 22. a3 Nc6 23. Qxb7 Bf6 24. Qxc6 Rac8 25. Qxa6 Qf7 26. Qd3 c4 27. Qxd4 Bxd4 28. Rae1 c3 29. bxc3 Bxc3 30. Bxc3 Rxc3 31. a4 Ra3 32. h3 Rxa4 33. c3 Qc4 34. f3 Ra2 35. Rxa2 Qxa2 0-1",
    B: "1824",
  },
  {
    W: "1773",
    T: "180+0",
    P: "1. d4 d5 2. Nc3 Nc6 3. Bf4 Nf6 4. Nb5 e5 5. dxe5 Nh5 6. Qd2 d4 7. e6 Nxf4 8. exf7+ Kxf7 9. Qxf4+ Kg8 10. Qxc7 Bb4+ 11. c3 Qxc7 12. Nxc7 dxc3 13. Kd1 Rb8 14. bxc3 Bxc3 15. Rc1 Ba5 16. Nb5 a6 17. Nd6 Be6 18. Nc4 Rd8+ 19. Kc2 Bxc4 20. a3 Rd2+ 21. Kb1 Be6 22. Nf3 Ra2 23. e3 Rxa3 24. Nd4 Nxd4 25. exd4 g6 26. Bc4 Bxc4 27. Rxc4 Kg7 28. Rd1 Rd8 29. d5 b5 30. Rc6 Bb4 31. d6 Rb3+ 32. Kc2 Ra3 33. d7 Ra2+ 34. Kb1 Rxf2 35. Rc8 Be7 36. Rxd8 Bxd8 37. Kc1 Rxg2 38. h3 Rh2 39. Re1 Rxh3 40. Kc2 Kh6 41. Re8 a5 42. Rxd8 Rh2+ 43. Kb3 Rd2 44. Kc3 Rd1 45. Kb3 Rd6 46. Kc2 a4 47. Kb2 Rd3 48. Ka2 b4 49. Kb2 a3+ 50. Ka2 Rd2+ 51. Kb1 a2+ 52. Ka1 b3 53. Rb8 Rd1+ 54. Kb2 a1=Q+ 55. Kxb3 Qb1+ 56. Kc4 Qxb8 57. d8=Q Qxd8 58. Kc3 Rc1+ 0-1",
    B: "1839",
  },
  {
    W: "1726",
    T: "180+0",
    P: "1. e4 e6 2. d4 Qf6 3. c3 Qg6 4. f4 Qxe4+ 5. Be2 Qxg2 6. Bf3 Qg6 7. Be3 h5 8. Nd2 Nc6 9. Be4 f5 10. Bd3 a6 11. Ngf3 b5 12. Qe2 Bb7 13. O-O-O Nd8 14. Rhg1 Qf6 15. Ne5 b4 16. c4 d6 17. Nef3 c5 18. Kb1 a5 19. Ng5 a4 20. Ndf3 a3 21. b3 g6 22. Bf2 cxd4 23. Bxd4 e5 24. fxe5 Qe7 25. exd6 Qxe2 26. Bxe2 Kd7 27. Bxh8 Bxd6 28. Be5 Bxf3 29. Rxd6+ Ke7 30. Nxf3 Rc8 31. Re1 Nh6 32. Bg3 Kf7 33. Ng5+ Kg7 34. Be5+ Kf8 35. Red1 Ng4 36. Bxg4 hxg4 37. Rxd8+ Rxd8 38. Rxd8+ Ke7 39. Rd6 f4 40. Kc1 f3 41. Kd2 f2 42. Ke2 f1=Q+ 43. Kxf1 1-0",
    B: "1563",
  },
  {
    W: "1659",
    T: "600+0",
    P: "1. d4 d5 2. e3 Nc6 3. c4 e6 4. Nf3 Nf6 5. Nc3 b6 6. b3 Bb7 7. Be2 Bb4 8. Bd2 dxc4 9. Bxc4 Ne7 10. O-O c5 11. Bd3 cxd4 12. Nxd4 Bd6 13. Bb5+ Bc6 14. Nxc6 Nxc6 15. Bxc6+ Kf8 16. Bxa8 Qxa8 17. Nb5 Be5 18. Bb4+ Kg8 19. Bc3 Bb8 20. Qd8+ Ne8 21. Qxe8# 1-0",
    B: "1374",
  },
  {
    W: "1464",
    T: "600+0",
    P: "1. e4 e5 2. Bc4 Nf6 3. d3 Nc6 4. Nc3 Na5 5. Bb5 a6 6. Ba4 b5 7. Bb3 Nxb3 8. axb3 d5 9. Qf3 Bb7 10. Bg5 Be7 11. Nh3 b4 12. Ne2 dxe4 13. dxe4 Bxe4 14. Qe3 Bxc2 15. Qxe5 O-O 16. Bxf6 Bxf6 17. Qe3 Re8 18. Qf3 Bxb2 19. Ra2 Qd1# 0-1",
    B: "1618",
  },
  {
    W: "1301",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 Nge7 5. Ng5 O-O 6. a3 Ng6 7. Qf3 Nf4 8. Bxf4 exf4 9. Qxf4 Qf6 10. Qxf6 gxf6 11. Nf3 Nd4 12. Nxd4 Bxd4 13. c3 Bb6 14. O-O d6 15. d4 Be6 16. Bd3 Bg4 17. h3 Bh5 18. b4 Bg6 19. a4 a5 20. b5 c5 21. Nd2 cxd4 22. cxd4 Bxd4 23. Rac1 d5 24. Rc7 f5 25. exd5 Rad8 26. Rxb7 Rxd5 27. Bc4 Rc5 28. Rd7 Bc3 29. Bb3 Bxd2 30. Rxd2 f4 31. b6 Rb8 32. Rd6 1-0",
    B: "1167",
  },
  {
    W: "1336",
    T: "600+0",
    P: "1. Nc3 d5 2. Nf3 e6 3. d4 a6 4. e4 Nf6 5. exd5 Nxd5 6. Nxd5 exd5 7. Qe2+ Be7 8. Qe5 O-O 9. h4 Bg4 10. Be2 Nc6 11. Qf4 Bxf3 12. Qxf3 Nxd4 13. Qe3 Nxc2+ 14. Kd1 Nxe3+ 15. Bxe3 Bxh4 16. g3 Bg5 17. f4 Bf6 18. Rb1 b6 19. Bd3 d4 20. Rxh7 g6 21. Kd2 dxe3+ 22. Kxe3 Bd4+ 23. Kd2 Kxh7 24. Rh1+ Kg7 25. f5 gxf5 26. Bxf5 Bxb2+ 27. Kc2 Rh8 28. Rd1 Rh2+ 29. Rd2 Qxd2+ 0-1",
    B: "1463",
  },
  {
    W: "1775",
    T: "900+0",
    P: "1. e4 d6 2. Nf3 Nd7 3. Be2 Ne5 4. O-O Nxf3+ 5. Bxf3 e5 6. c3 Nf6 7. Na3 Be7 8. d3 c6 9. h3 Be6 10. d4 O-O 11. Bg5 d5 12. dxe5 Nxe4 13. Bxe7 Qxe7 14. Bxe4 dxe4 15. Qd6 Qg5 16. Nc2 Bxh3 17. Ne3 Be6 18. b3 h5 19. Rad1 h4 20. Qd2 h3 21. Qe2 hxg2 22. Nxg2 Bh3 23. Qxe4 f5 24. Qc4+ Rf7 0-1",
    B: "1532",
  },
  {
    W: "1791",
    T: "60+0",
    P: "1. c4 c6 2. Nc3 d5 3. cxd5 cxd5 4. d4 Nf6 5. Nf3 Nc6 6. e3 Bf5 7. Bd3 Bxd3 8. Qxd3 e6 9. Bd2 Be7 10. Ne2 Ne4 11. Ng3 Nxd2 12. Nxd2 O-O 13. h4 Bf6 14. h5 h6 15. Ne2 Rc8 16. g4 Bg5 17. f4 Bh4+ 18. Kd1 Nb4 19. Qb5 Qb6 20. Qxb6 axb6 21. Rxh4 Nd3 22. Nc3 Nf2+ 23. Ke2 Nxg4 24. Rxg4 Kh7 25. Rag1 Rg8 26. Nf3 f5 27. Rg6 Rce8 28. Ne5 Re7 29. Nb5 Rc8 30. Nd6 Rc2+ 31. Kd3 Rxb2 32. Ndf7 Rxa2 33. Nd8 Ra3+ 34. Kc2 Rxe3 35. Nxb7 Re2+ 36. Kd3 Rh2 37. Nd6 Rxh5 38. Nef7 Kg8 39. Ng5 Rh4 40. Nxe6 Rxe6 41. Rxg7+ Kf8 42. Rg8+ Ke7 43. R1g7+ Kxd6 0-1",
    B: "1841",
  },
  {
    W: "1485",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 d6 5. Nxc6 bxc6 6. Bc4 Bb7 7. Qf3 Nf6 8. Bg5 c5 9. Bxf6 Qxf6 10. Bd5 Qxf3 11. gxf3 Bxd5 12. exd5 Be7 13. Nc3 Rb8 14. O-O-O O-O 15. f4 Bh4 16. f3 Bf2 17. Ne4 Bd4 18. c3 Be3+ 19. Kc2 Bxf4 20. h3 f5 21. Nf2 c4 22. Rd4 Be3 23. Rxc4 Bxf2 24. Rxc7 Rfe8 25. Rxa7 Re2+ 26. Kd3 Re3+ 27. Kd2 Rxf3 28. Rd7 Bg3 29. b4 Re8 30. Rg1 Ree3 31. Rg2 Rxc3 32. b5 Rfd3+ 33. Ke2 Be5 34. b6 Rxd5 35. b7 Rb5 36. Rd8+ Kf7 37. b8=Q Rc2+ 38. Kd3 Rxg2 39. Qxb5 Rxa2 40. Qd5+ 1-0",
    B: "1476",
  },
  {
    W: "1598",
    T: "600+0",
    P: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Nf3 e6 5. Nc3 c5 6. Bb5+ Nc6 7. Be3 Qb6 8. dxc5 Bxc5 9. Bxc5 Qxc5 10. Bxc6+ Qxc6 11. Nd4 Qb6 12. Qd2 Bg6 13. O-O Ne7 14. Ncb5 Rc8 15. Nd6+ Kd7 16. Nxc8 Rxc8 17. b3 Nc6 18. Nxc6 Qxc6 19. Qf4 Qxc2 20. Rfc1 Qf5 21. Qa4+ 1-0",
    B: "1605",
  },
  {
    W: "1065",
    T: "600+0",
    P: "1. d4 e6 2. e4 b6 3. Nf3 Bb7 4. Nc3 Be7 5. h4 d5 6. Bg5 Bxg5 7. Nxg5 Nh6 8. exd5 Bxd5 9. Nxd5 Qxd5 10. b3 f6 11. c4 Qc6 12. d5 exd5 13. cxd5 Qc3+ 14. Qd2 Qxa1+ 15. Ke2 Qe5+ 16. Kd3 c6 17. Ne6 Qxd5+ 18. Nd4 c5 19. Be2 cxd4 20. Bh5+ g6 21. Qxh6 Qf5+ 22. Kxd4 Qxf2+ 23. Ke4 Qxg2+ 24. Bf3 Qc2+ 25. Kf4 Qf5+ 26. Kg3 Qe5+ 27. Kg4 f5+ 28. Kg5 Kf7 29. Rf1 Qg3+ 30. Bg4 Qxg4# 0-1",
    B: "1092",
  },
  {
    W: "1583",
    T: "600+0",
    P: "1. d4 Nf6 2. Nc3 e6 3. e4 Bb4 4. Bd2 Bxc3 5. e5 Bxd2+ 6. Qxd2 Ne4 7. Qf4 Ng5 8. h4 h6 9. hxg5 h5 10. Nf3 Nc6 11. a3 d5 12. Bb5 Bd7 13. O-O-O a6 14. Ba4 Qe7 15. Nh4 O-O-O 16. g6 Rdf8 17. g3 Rh6 18. gxf7 Rxf7 19. Qd2 g5 20. Rh2 Qf8 21. Qxg5 Qg7 22. f4 Qxg5 23. fxg5 Rhh7 24. g6 Rfg7 25. gxh7 Rxh7 26. Nf3 Rg7 27. Rg1 b5 28. Bb3 Kb7 29. Rxh5 Rf7 30. Rf1 Nxd4 31. Nd2 Rg7 32. Rh3 Ne2+ 33. Kb1 Nxg3 34. Rg1 Nf5 35. Rxg7 Nxg7 36. Rh7 Nf5 37. Rxd7 d4 38. Bxe6 Ne3 39. Rxd4 c5 40. Rd7+ Kc6 41. Rd6+ Kc7 42. Nb3 Nc4 43. Bxc4 bxc4 44. Nxc5 a5 45. Rd5 Kc6 46. Rd1 Kxc5 47. e6 Kc6 48. e7 Kc5 49. e8=Q Kb6 50. Rd6+ Kc5 51. Qe5# 1-0",
    B: "1547",
  },
  {
    W: "898",
    T: "600+0",
    P: "1. e4 e5 2. Nc3 Qf6 3. Nf3 b6 4. Bc4 Bb7 5. d3 Qg6 6. Ng5 d6 7. Nxf7 Qxg2 8. Nxh8 Qxh1+ 9. Ke2 Qxh2 10. Bxg8 d5 0-1",
    B: "948",
  },
  {
    W: "1664",
    T: "600+0",
    P: "1. d4 Nf6 2. f4 g6 3. e3 d5 4. Nf3 Bg7 5. c3 c5 6. Bd3 c4 7. Bc2 O-O 8. O-O Nc6 9. Ne5 Qc7 10. Nd2 Nd7 11. Ndf3 Ndxe5 12. fxe5 Nxe5 1-0",
    B: "1641",
  },
  {
    W: "1356",
    T: "600+0",
    P: "1. e4 e6 2. Nf3 Qf6 3. Nc3 Nc6 4. Bb5 Bd6 5. Bxc6 dxc6 6. Qe2 Bd7 7. e5 Bxe5 8. Nxe5 Qg5 9. Qf3 Qxe5+ 10. Ne4 Nf6 11. O-O O-O-O 12. Nxf6 gxf6 13. c3 Rhg8 14. d4 Qb5 15. Qxf6 Be8 16. Bg5 Qd5 17. Qh6 c5 18. f3 cxd4 19. Bxd8 Qxd8 20. Rfd1 Ba4 21. Rxd4 Qe8 22. Rad1 b6 23. b3 Bc6 24. Qxh7 Rh8 25. Qd3 Kb7 26. Rd8 1-0",
    B: "1147",
  },
  {
    W: "1122",
    T: "600+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. Qe2+ Be6 5. Ng5 Qxg5 6. d4 Qg6 7. Nc3 Bb4 8. a3 Bxc3+ 9. bxc3 Nf6 10. Qb5+ Ke7 11. Qxb7 Nbd7 12. Qxc7 Qxc2 13. a4 Rhc8 14. Ba3+ Ke8 15. Qb7 Qxc3+ 16. Kd1 Qxa1+ 17. Ke2 Qxa3 18. f4 Qxa4 19. Ke3 Qc2 20. Bd3 Qxg2 21. Rc1 Ng4# 0-1",
    B: "1144",
  },
  {
    W: "1449",
    T: "600+0",
    P: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 Nc6 5. Qf3 Nf6 6. Nh3 Ne5 7. Qxc3 Nxc4 8. Qxc4 d6 9. Ng5 Be6 10. Nxe6 fxe6 11. Qxe6+ Be7 12. e5 dxe5 13. Qxe5 O-O 14. Bf4 Bb4+ 15. Nc3 Bxc3+ 16. bxc3 Re8 17. Rd1 Rxe5+ 18. Bxe5 Qe8 19. f4 Nd7 20. Kf2 Nxe5 21. Rhe1 Ng4+ 22. Kg3 Qc6 23. Kxg4 Qg6+ 24. Kf3 Re8 25. Rxe8+ Qxe8 26. g4 Qc6+ 27. Kg3 Qxc3+ 28. Kh4 Qf6+ 29. g5 Qxf4+ 30. Kh5 0-1",
    B: "1447",
  },
  {
    W: "2000",
    T: "600+0",
    P: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. Bg5 Be7 5. e3 a6 6. Nf3 O-O 7. Bd3 dxc4 8. Bxc4 b5 9. Bd3 Bb7 10. Rc1 c6 11. O-O c5 12. Bxf6 Bxf6 13. dxc5 Nc6 14. Qe2 Nb4 15. Bb1 Rc8 16. Rfd1 Qc7 17. a3 Nd5 18. Ne4 Bc6 19. b4 a5 20. Qc2 axb4 21. Rxd5 Bxd5 22. Nxf6+ gxf6 23. Qxh7# 1-0",
    B: "1867",
  },
  {
    W: "1744",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bc4 d6 4. Nc3 h6 5. d4 cxd4 6. Nxd4 Nf6 7. Nxc6 bxc6 8. O-O Rb8 9. b3 Bb7 10. Bf4 e5 11. Be3 a6 12. a4 Be7 13. a5 O-O 14. Bb6 Qd7 15. Ne2 c5 16. Bd5 Bd8 17. Bxb7 Qxb7 18. f3 Bxb6 19. axb6 Qxb6 20. Nc3 c4+ 21. Kh1 cxb3 22. cxb3 Qxb3 23. Nd5 Nxd5 24. Qxd5 Qxd5 25. exd5 Rb6 26. Rfd1 f5 27. h4 f4 28. h5 Rf5 29. Kg1 Rxh5 30. Rac1 Rf5 31. Rc6 Rxc6 32. dxc6 Rf8 33. c7 Rc8 34. Rxd6 Rxc7 35. Rxa6 Re7 36. Kf2 Kf7 37. g4 fxg3+ 38. Kxg3 Re6 39. Ra7+ Kf6 40. Ra4 g6 41. f4 e4 42. Ra1 e3 43. Re1 Kf5 44. Kf3 e2 45. Rxe2 Rxe2 46. Kxe2 Kxf4 47. Kf2 h5 48. Kg2 h4 49. Kh3 g5 50. Kg2 g4 51. Kh2 Kf3 52. Kh1 Kf2 53. Kh2 g3+ 54. Kh3 g2 55. Kxh4 g1=Q 56. Kh3 Qg5 57. Kh2 Qg2# 0-1",
    B: "1701",
  },
  {
    W: "1360",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. a3 e5 4. d3 Nf6 5. Bg5 Be7 6. Be2 h6 7. Bh4 b6 8. Bg3 d6 9. O-O Nh5 10. c4 Be6 11. Nbd2 Qd7 12. b3 g5 13. Ne1 Nf6 14. h4 O-O-O 15. f3 Nh5 16. Bf2 Nf4 17. Bg3 Bf6 18. Bxf4 gxf4 19. h5 Rhg8 20. g4 Nd4 21. Ng2 Nxe2+ 22. Qxe2 Be7 23. a4 f6 24. a5 Kc7 25. axb6+ axb6 1-0",
    B: "1373",
  },
  {
    W: "2079",
    T: "300+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. d4 d6 5. exd6 cxd6 6. Nf3 Bg4 7. Bd3 e6 8. O-O Be7 9. h3 Bh5 10. Nc3 h6 11. Re1 O-O 12. g4 Bg6 13. Bxg6 fxg6 14. Rxe6 Rxf3 15. Qxf3 Nxc4 16. Qxb7 Nd7 17. Rxe7 Qxe7 18. Qxa8+ Kh7 0-1",
    B: "2039",
  },
  {
    W: "1908",
    T: "180+0",
    P: "1. d4 d5 2. c4 Nc6 3. Nf3 dxc4 4. d5 Nb8 5. Nc3 Nf6 6. e4 Bg4 7. e5 Nfd7 8. e6 fxe6 9. dxe6 Bxe6 10. Ng5 Bf7 11. Nxf7 Kxf7 12. Bxc4+ e6 13. O-O Be7 14. Qf3+ Nf6 15. Qxb7 Nbd7 16. Re1 Re8 17. Rxe6 Nb6 18. Rxb6+ Kf8 19. Re6 Bd6 20. Bg5 Rxe6 21. Bxe6 Qe7 22. Qxa8+ Ne8 23. Bxe7+ Bxe7 1-0",
    B: "1886",
  },
  {
    W: "1820",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. Nf3 c5 5. Bc4 Nc6 6. d3 Nf6 7. Bd2 Bg4 8. Ne4 Qd8 9. Nxf6+ gxf6 10. h3 Bf5 11. Nh4 Be6 12. Bxe6 fxe6 13. Qf3 Rg8 14. g4 Qd7 15. O-O-O O-O-O 16. Bc3 e5 17. Nf5 Qe6 18. b3 Qd7 19. Kb2 Qc7 20. Rhe1 e6 21. Ne3 Nd4 22. Bxd4 cxd4 23. Nc4 Rd5 24. Qxf6 Rc5 25. Qxe6+ Kb8 26. Qxg8 Qc8 27. Rxe5 Rxe5 28. Nxe5 Ba3+ 29. Kb1 Qxg8 30. Nc4 Bb4 31. a3 Bc3 32. f4 Qf8 33. f5 b5 34. b4 bxc4 35. dxc4 Qc8 36. Ka2 Qxc4+ 37. Kb1 Qe2 38. Rd3 Qe1+ 39. Ka2 Qa1+ 40. Kb3 Qb2+ 41. Kc4 Qxc2 42. f6 a5 43. b5 a4 44. Rf3 Bb2+ 45. Kd5 Bxa3 46. f7 Qa2+ 47. Kc6 d3 48. f8=Q+ Bxf8 49. Rxf8+ Ka7 50. b6+ Ka6 51. Ra8# 1-0",
    B: "1824",
  },
  {
    W: "1416",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd6 4. d4 Nc6 5. d5 Bf5 6. dxc6 Nf6 7. Qxd6 e6 8. Qxc7 Rb8 9. Qxb8+ Ke7 10. cxb7 Ne4 11. Nxe4 f6 12. Qxa7 Ke8 13. b8=Q# 1-0",
    B: "1411",
  },
  {
    W: "1605",
    T: "180+0",
    P: "1. d4 d5 2. Bf4 Nf6 3. e3 Nc6 4. Bd3 Nb4 5. Bb5+ c6 6. Ba4 b5 7. Bb3 a5 8. c3 a4 9. Bc2 Nxc2+ 10. Qxc2 e6 11. Nf3 Be7 12. Nbd2 O-O 13. O-O c5 14. Bg3 cxd4 15. cxd4 Bb7 16. Qd3 Rc8 17. Qxb5 Bc6 18. Qd3 Qa5 19. b3 Bb5 20. Qb1 Bxf1 21. Qxf1 Rc2 22. h3 Rxd2 23. Nxd2 Qxd2 24. bxa4 Ne4 25. Bf4 g5 26. Be5 f6 27. Bh2 Rc8 28. a5 Qxa5 29. a4 Rc2 30. f3 Qd2 31. a5 Nf2 32. a6 Nxh3+ 33. gxh3 Qxh2# 0-1",
    B: "1667",
  },
  {
    W: "1746",
    T: "180+0",
    P: "1. e4 e5 2. f4 d5 3. Nf3 dxe4 4. Nxe5 Nf6 5. Bc4 Be6 6. Bxe6 fxe6 7. O-O Bc5+ 8. Kh1 Nbd7 9. c3 Nxe5 10. fxe5 Nd7 11. d4 exd3 12. Qxd3 Nxe5 13. Qxd8+ Rxd8 14. Bg5 Rd5 15. Na3 Bxa3 16. bxa3 Nd3 17. Be3 Nc5 18. Rab1 b6 19. c4 Re5 20. Bd4 Rg5 21. h4 Rg6 22. Bxc5 1-0",
    B: "1755",
  },
  {
    W: "2006",
    T: "180+0",
    P: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. e3 c5 5. exd4 cxd4 6. Bd3 Nc6 7. Nf3 Bg4 8. O-O Bc5 9. Nbd2 Nge7 10. a3 a5 11. Ne4 b6 12. Nxc5 bxc5 13. b3 O-O 14. Qc2 Bxf3 15. Bxh7+ Kh8 16. gxf3 Nxe5 17. Be4 d3 18. Qb2 N7g6 19. Bxa8 Qxa8 20. Be3 Nxf3+ 21. Kg2 Nfh4+ 0-1",
    B: "1999",
  },
  {
    W: "1355",
    T: "180+0",
    P: "1. e3 d5 2. d4 e5 3. Nc3 e4 4. Nh3 Bxh3 5. gxh3 Nc6 6. Qg4 Nf6 7. Qg5 h6 8. Qh4 Be7 9. Qf4 Bd6 10. Qf5 g6 11. Qxf6 Qxf6 12. Bb5 O-O 13. Bxc6 b6 14. Bxa8 Rxa8 15. Bd2 Bb4 16. Na4 Bxd2+ 17. Kxd2 Re8 18. Raf1 g5 19. f4 gxf4 20. Rxf4 Qg5 21. Kc3 c5 22. b4 cxb4+ 23. Kxb4 Qe7+ 24. Kb3 b5 25. Nc5 Qc7 26. c3 Qa5 27. Rg1+ Kf8 28. Nd7+ Ke7 29. Nc5 Rc8 30. Nb7 Qa4+ 31. Kb2 Rc4 32. Rg7 Qa6 33. Nc5 Rxc5 34. dxc5 d4 35. exd4 Qc6 36. Rgxf7+ Ke6 37. R7f6+ Kd7 38. Rxc6 Kxc6 39. Rf6+ Kd5 40. Rxh6 Kc4 41. h4 e3 42. Rh8 e2 43. Re8 Kd3 44. h5 Kd2 45. h6 e1=Q 46. Rxe1 Kxe1 47. h7 Kd2 48. h8=Q Kd3 49. h3 Kc4 50. h4 b4 51. h5 bxc3+ 52. Kc1 Kd3 53. d5 c2 54. Qb2 Ke2 55. h6 Kd3 56. h7 Kc4 57. h8=Q Kxc5 58. Qh4 Kd6 59. Qb6+ Ke5 60. d6 Ke6 61. d7+ Kxd7 62. Qh7+ Kc8 63. Qbxa7 Kd8 64. Qa8# 1-0",
    B: "1396",
  },
  {
    W: "1893",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 d6 5. Nc3 Nf6 6. Bc4 Be7 7. Be3 Nc6 8. Qd2 Bd7 9. f3 O-O 10. O-O-O Rc8 11. Bb3 a6 12. h4 Ne5 13. g4 Nc4 14. Bxc4 Rxc4 15. h5 e5 16. Nde2 Be6 17. g5 Ne8 18. h6 g6 19. f4 exf4 20. Nxf4 b5 21. Nfd5 Bxd5 22. Nxd5 Rxe4 23. Bd4 Bxg5 24. Be3 Bxe3 25. Nxe3 Qg5 26. Rde1 Nf6 27. Qc3 Nh5 28. Rxh5 gxh5 29. Re2 f5 30. Rg2 Rg4 31. Qb3+ Kh8 32. Qc3+ Qf6 33. Nxg4 Qxc3 34. b3 fxg4 0-1",
    B: "1869",
  },
  {
    W: "1425",
    T: "180+0",
    P: "1. e4 Nc6 2. d4 d6 3. Bd3 g6 4. Nf3 Bg7 5. d5 Ne5 6. Nbd2 e6 7. b3 Ne7 8. Bb2 O-O 9. dxe6 Bxe6 10. Qe2 Nxd3+ 11. Qxd3 Bxb2 12. Rb1 Bg7 13. O-O Nc6 14. Ng5 Ne5 15. Qg3 Ng4 16. Nxe6 fxe6 17. Qxg4 Qf6 18. Nf3 d5 19. e5 Qf5 20. Qb4 b6 21. Qe7 c5 22. Ng5 Qxe5 23. Rbe1 Qf6 24. Qxe6+ Qxe6 25. Nxe6 Rf6 26. Nxg7 Kxg7 27. Re5 Raf8 28. f3 Rf5 29. Rfe1 d4 30. Re7+ R5f7 31. Kf2 a6 32. Rxf7+ Rxf7 33. Re6 Rb7 34. Ke2 a5 35. Kd3 b5 36. c3 Rc7 37. cxd4 c4+ 38. Kc3 Rd7 39. a4 Rc7 40. bxc4 b4+ 41. Kb3 Rxc4 42. Kxc4 b3 43. Kxb3 Kf7 44. Re1 Kf6 45. d5 Kf7 46. d6 Kf6 47. d7 Kf5 48. d8=Q Kf4 49. Qd5 g5 50. Re4# 1-0",
    B: "1511",
  },
  {
    W: "1788",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 bxc6 5. d4 cxd4 6. Qxd4 d6 7. Nc3 e5 8. Qd3 Nf6 9. Be3 Bb7 10. O-O-O Be7 11. Rhg1 Qa5 12. g4 d5 13. exd5 cxd5 14. g5 d4 15. gxf6 Bxf6 16. Ne4 Bxe4 17. Qxe4 Rc8 18. Nxd4 Qxa2 19. Nb3 Qxb3 20. Rd8+ Kxd8 21. Bb6+ Qxb6 0-1",
    B: "1763",
  },
  {
    W: "1558",
    T: "180+0",
    P: "1. d4 d5 2. e3 Nf6 3. Nf3 Bf5 4. c4 e6 5. cxd5 exd5 6. Nc3 c6 7. Bd3 Bg6 8. Bxg6 hxg6 9. Qc2 Bd6 10. Bd2 Nbd7 11. O-O-O b5 12. Kb1 a5 13. Ne5 Nxe5 14. dxe5 Bxe5 15. f4 Bd6 16. h3 b4 17. Ne2 Rc8 18. Qa4 Ne4 19. Nd4 Qb6 20. Rc1 Kd7 21. Be1 Rhe8 22. f5 gxf5 23. Nxf5 g6 24. Nxd6 Kxd6 25. Bh4 Nc5 26. Qc2 Rxe3 27. Rhe1 Re4 28. Rcd1 Rxe1 29. Rxe1 Ne4 30. Rd1 f5 31. g4 Rh8 32. Be1 Rxh3 33. gxf5 gxf5 34. Qg2 Rh8 35. Qg6+ Kc5 36. Rc1+ Kb5 37. Qxf5 Rb8 38. Qf1+ Ka4 39. Qe2 b3 40. axb3+ Qxb3 41. Qd2 Nxd2+ 42. Bxd2 Qxb2# 0-1",
    B: "1563",
  },
  {
    W: "2415",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. g3 Qb6 7. Nb3 Bb4 8. Bg2 d5 9. exd5 Nxd5 10. Bd2 Bxc3 11. bxc3 O-O 12. O-O Nde7 13. c4 e5 14. Bc3 Bf5 15. Qe2 f6 16. a4 Rad8 17. a5 Qc7 18. Rfd1 Rxd1+ 19. Rxd1 Rd8 20. h3 Rxd1+ 21. Qxd1 Qd7 22. Qxd7 Bxd7 23. g4 h5 24. f3 hxg4 25. hxg4 Be6 26. Bf1 Ng6 27. Nc5 Bc8 28. Bd3 Nf4 29. Be4 Ne6 30. Bd5 Nd8 31. a6 bxa6 32. Ba5 Kf8 33. Bb4 Ke8 34. Kf2 Nd4 35. c3 N4c6 36. Ba3 a5 37. Ke3 f5 38. gxf5 Bxf5 39. Kf2 Ne7 40. Kg3 Bc2 41. Kh4 Nf5+ 42. Kg5 Ne3 43. Bc1 Nxd5 44. cxd5 Nf7+ 45. Kg4 Nd6 46. Kg3 Nc4 47. Kf2 a4 48. Na6 a3 49. Nb4 Bb3 50. Ke2 a5 51. Bxa3 Nxa3 52. Nc6 Nc4 53. Kd3 a4 54. Nb4 a3 55. d6 Kd7 0-1",
    B: "2449",
  },
  {
    W: "1154",
    T: "180+0",
    P: "1. d3 d5 2. g3 f5 3. Bg2 e6 4. Nf3 Nf6 5. Bf4 Nc6 6. Ne5 Nxe5 7. Bxe5 Bd6 8. Bxd6 Qxd6 9. Nc3 O-O 10. O-O g6 11. Re1 a5 12. e4 fxe4 13. dxe4 dxe4 14. Qxd6 cxd6 15. Nxe4 d5 16. Nxf6+ Rxf6 17. c4 d4 18. Re5 Rf5 19. Rae1 Bd7 20. Rxf5 exf5 21. Re7 Bc6 22. Bd5+ Bxd5 23. cxd5 Rd8 24. Re5 d3 25. Kf1 Rc8 26. f4 Rc2 27. Re1 d2 28. Rd1 Rxb2 29. Ke2 Rxa2 30. Rxd2 Rxd2+ 31. Kxd2 Kf8 32. Kd3 Ke7 33. Kc3 Kd6 34. Kd4 a4 35. Kc4 b5+ 36. Kxb5 a3 37. Kb4 a2 38. Kb3 a1=Q 39. Kc4 Qa2+ 40. Kd3 Qxd5+ 41. Ke3 Qc4 42. Kf3 Kd5 43. Kg2 Qd3 44. Kh3 h5 45. Kh4 Qe2 46. h3 Qe7# 0-1",
    B: "1186",
  },
  {
    W: "2326",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 e5 7. Nb3 Nc6 8. f3 Be7 9. Qd2 O-O 10. O-O-O Be6 11. g4 b5 12. g5 Nd7 13. Nd5 Bxd5 14. exd5 Na5 15. Nxa5 Nb8 16. Nc6 Nxc6 17. dxc6 Rc8 18. Bg2 Rxc6 19. f4 exf4 20. Bxf4 Rc4 21. Bxd6 Bxg5 22. Qxg5 Qxg5+ 23. Kb1 Qxg2 24. Rhg1 Qxc2+ 25. Ka1 Rd8 26. Rge1 h6 0-1",
    B: "2370",
  },
  {
    W: "2070",
    T: "180+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 Nc6 4. Bc4 Bc5 5. d4 Nxd4 6. Nxd4 Qh4+ 7. Kf1 Nf6 8. Nf3 Qf2# 0-1",
    B: "2057",
  },
  {
    W: "1082",
    T: "180+0",
    P: "1. e4 e6 2. d3 Nf6 3. Nc3 Nc6 4. Bf4 Nd4 5. Nf3 Ng4 6. Nxd4 Nxf2 7. Kxf2 Qh4+ 8. g3 Qg4 9. Qxg4 f5 10. exf5 Ke7 11. Ndb5 exf5 12. Qxf5 b6 13. Nxc7 Ba6 14. Qe6+ Kd8 15. Qe8# 1-0",
    B: "1111",
  },
  {
    W: "1260",
    T: "120+0",
    P: "1. d4 d5 2. c4 e6 3. Nc3 dxc4 4. Bf4 Nc6 5. e3 Be7 6. Bxc4 Nf6 7. Nf3 O-O 8. O-O Na5 9. Bd3 Bb4 10. Qc2 g6 11. a3 Bxc3 12. Qxc3 Nc6 13. Bg5 Bd7 14. Rad1 Nd5 15. Bxd8 Nxc3 16. bxc3 Raxd8 17. Rb1 b6 18. Be4 f5 19. Bd3 Ne7 20. Ba6 Bc6 21. Nd2 Nd5 22. c4 Nc3 23. Rb3 Na4 24. Rfb1 e5 25. d5 e4 26. Nf1 Nc5 27. R3b2 Nxa6 28. dxc6 Nc5 29. Rb5 Na4 30. R5b4 Nc3 31. R1b3 Rd3 32. h3 Ne2+ 33. Kh1 Rfd8 34. Ng3 Rd1+ 35. Kh2 Nxg3 36. Kxg3 a5 37. Rb5 R1d3 38. h4 Rxb3 39. Rxb3 Rd3 40. Rb2 Rxa3 41. c5 bxc5 42. Rb7 Kg7 43. Rxc7+ Kh6 44. Rd7 Rb3 45. c7 Rb7 46. c8=Q Rxd7 47. Qxd7 a4 48. Qd5 a3 0-1",
    B: "1521",
  },
  {
    W: "1781",
    T: "300+5",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4 O-O 6. Nf3 Nbd7 7. Be3 Ng4 8. Qd2 Nxe3 9. Qxe3 Nb6 10. Bd3 e5 11. fxe5 dxe5 12. Nxe5 Bxe5 13. dxe5 Be6 14. O-O-O Qe7 15. Nd5 Qd7 16. Nf6+ 1-0",
    B: "1769",
  },
  {
    W: "1747",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 Nf6 4. Nc3 e6 5. d3 Be7 6. Bg5 O-O 7. Qd2 a6 8. a4 b5 9. axb5 axb5 10. Rxa8 bxc4 11. dxc4 Bb7 12. Ra1 Qb6 13. Na4 Qa6 14. b3 Bc6 15. O-O Qb7 16. Nc3 h6 17. Be3 Nxe4 18. Nxe4 Bxe4 19. Rfd1 Bxf3 20. gxf3 Qxf3 21. Qe1 Qg4+ 22. Kh1 Qf3+ 23. Kg1 Qg4+ 24. Kf1 Qh3+ 25. Ke2 Qg4+ 26. Kd2 Qh5 27. Qe2 Qxh2 28. Rh1 Qe5 29. Bxh6 gxh6 30. Qxe5 dxe5 31. Rxh6 Bg5+ 32. Kd3 Bxh6 33. Rg1+ Kh7 34. Ke4 f5+ 35. Kxe5 Bg7+ 36. Kxe6 Rf6+ 37. Kd5 Bf8 38. Rh1+ Kg7 39. Re1 Rd6+ 40. Kxc5 Rd1+ 41. Kb6 Rxe1 42. Kb7 Rc1 43. c5 Rxc2 44. b4 Rb2 45. b5 Rxb5+ 46. Ka8 Rxc5 47. Ka7 Rc2 48. f3 1-0",
    B: "1705",
  },
  {
    W: "1494",
    T: "300+5",
    P: "1. d4 d5 2. Nf3 Bf5 3. Bf4 Nf6 4. e3 Nc6 5. c4 Nb4 6. Qa4+ Nc6 7. Nc3 dxc4 8. Bxc4 Bd7 9. Qb3 Na5 10. Bxf7# 1-0",
    B: "1370",
  },
  {
    W: "1487",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 e6 3. Bf4 c5 4. e3 cxd4 5. exd4 Nf6 6. c3 Bd6 7. Bg3 Bxg3 8. hxg3 Ne4 9. Nbd2 Qb6 10. Qc2 Nxd2 11. Nxd2 Nc6 12. Nf3 Ne7 13. Bd3 Nf5 14. Bxf5 exf5 15. O-O-O g6 16. Rde1+ Be6 17. Ng5 Qa5 18. Nxe6 fxe6 19. Rxe6+ Kf7 20. Qe2 Qd8 21. Re1 a5 22. f3 Qg5+ 23. f4 Qd8 24. Qe5 Qb8 25. Re7+ Kf8 26. Qg7# 1-0",
    B: "1808",
  },
  {
    W: "1402",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 Bb4 5. e5 Bxc3+ 6. bxc3 Ne4 7. Qd3 f5 8. exf6 Nxf6 9. Bg5 O-O 10. Be2 d5 11. O-O e5 12. dxe5 Qe8 13. exf6 Bg4 14. fxg7 Kxg7 15. Rfe1 Bxf3 16. Bxf3 Qf7 17. Bh4 Rfe8 18. Rxe8 Qxe8 19. Qxd5 Qg6 20. Be4 Qf7 21. Qg5+ Qg6 22. Bxg6 Kf8 0-1",
    B: "1219",
  },
  {
    W: "1221",
    T: "60+0",
    P: "1. d4 d5 2. Bf4 c5 3. e3 cxd4 4. exd4 Qa5+ 5. Nd2 Qb6 6. b3 Nf6 7. Ngf3 Ne4 8. Nxe4 dxe4 9. Nd2 Qxd4 10. Be3 Qc3 11. Be2 e5 12. O-O Bc5 13. Bxc5 Qxc5 14. Nxe4 Qb4 15. Nd2 O-O 16. Re1 Be6 17. Bf3 Nc6 18. Ne4 Bf5 19. Nd2 Qc5 20. c4 Nd4 21. Be2 Nxe2+ 22. Qxe2 Rfe8 23. Rad1 Qd4 24. Nf3 Qc5 25. Nh4 Qxf2+ 0-1",
    B: "1427",
  },
  {
    W: "1417",
    T: "60+0",
    P: "1. d4 d5 2. Bf4 Nc6 3. Nc3 Nf6 4. Nb5 e5 5. Bxe5 Bd6 6. Bxd6 cxd6 7. e3 a6 8. Nc3 O-O 9. Bd3 Bg4 10. Be2 h5 11. Bxg4 Nxg4 12. Qf3 Qg5 13. h3 Nf6 14. Nce2 Rae8 15. Nf4 Re4 16. O-O-O Rfe8 17. Nge2 Nb4 18. Kb1 a5 19. Rhg1 a4 20. h4 Qxh4 21. g3 Qh3 22. Nxh3 1-0",
    B: "1193",
  },
  {
    W: "1379",
    T: "60+0",
    P: "1. e4 e6 2. f4 d5 3. exd5 Qxd5 4. Nf3 Qd8 5. Ke2 Nf6 6. Kf2 Be7 7. Be2 O-O 8. Re1 Nbd7 9. g3 b6 10. d3 Bb7 11. Nc3 Bc5+ 12. Be3 Bxe3+ 13. Kxe3 Bxf3 14. Kf2 Qe7 15. Bxf3 c5 16. Bxa8 Rxa8 17. Qf3 Nd5 18. Nxd5 exd5 19. Qxd5 Qe6 20. Qxa8+ 1-0",
    B: "1340",
  },
  {
    W: "2227",
    T: "60+0",
    P: "1. e4 d6 2. d4 Nd7 3. Nc3 g6 4. Bf4 Bg7 5. Qd2 h6 6. O-O-O a6 7. f3 b5 8. Kb1 Nb6 9. e5 Bd7 10. Qe3 d5 11. Bd3 e6 12. h4 Nc4 13. Qe2 Rb8 14. b3 Qe7 15. Qf2 Qa3 16. Bxc4 bxc4 17. Bc1 Qa5 18. f4 Qxc3 19. Bb2 Qa5 20. g4 cxb3 21. axb3 Ba4 22. Rh3 Bb5 23. f5 exf5 24. gxf5 Ne7 25. f6 Bf8 26. fxe7 Bxe7 27. Rf3 O-O 28. e6 Ba3 29. Rxf7 Bxb2 30. Kxb2 Bc4 31. Qf6 Rxf7 32. Qxf7+ Kh8 33. Qf6+ Kg8 34. Qxg6+ Kh8 35. Qxh6+ Kg8 36. Nf3 Bxb3 37. Rg1# 1-0",
    B: "2175",
  },
  {
    W: "1821",
    T: "60+0",
    P: "1. d4 e5 2. Nf3 e4 3. Ng1 d5 4. Bf4 a6 5. Nd2 c5 6. dxc5 Bxc5 7. e3 Nc6 8. Be2 Be6 9. c3 Nf6 10. h3 O-O 11. Bh5 Nxh5 12. Qxh5 g6 13. Qg5 f6 14. Qg3 Qe7 15. Qh2 Qf7 16. Ne2 Ne5 17. Bxe5 fxe5 18. Qxe5 Qxf2+ 19. Kd1 Bxe3 20. Qxe6+ Rf7 21. Qe5 Raf8 22. Nb3 Qxg2 23. Re1 Bf4 24. Qxd5 Qxh3 25. Nbd4 Kg7 26. Qxe4 Rd7 27. Kc2 Qf5 28. Qxf5 Rxf5 29. Rad1 Rf8 30. Rd3 Bc7 31. Ne6+ Kf7 32. Rxd7+ 1-0",
    B: "1693",
  },
  {
    W: "2351",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nc3 Nc6 4. Bb5 Nd4 5. Nxd4 exd4 6. e5 dxc3 7. exf6 Qxf6 8. dxc3 Qe6+ 9. Be3 c6 10. Bd3 d5 11. O-O Bd6 12. c4 O-O 13. cxd5 Qxd5 14. Qe2 Re8 15. Rad1 Qa5 16. a3 Bf5 17. Bxf5 Qxf5 18. Qd3 Qxd3 19. Rxd3 Be5 20. b3 Rad8 21. Rfd1 Bf6 22. Rxd8 Rxd8 23. Rxd8+ Bxd8 24. Bxa7 b6 25. Bb8 c5 26. Kf1 Bf6 27. Ke2 Bb2 28. a4 Ba3 29. Ba7 Bb4 30. Bxb6 1-0",
    B: "2275",
  },
  {
    W: "1099",
    T: "60+0",
    P: "1. e4 e5 2. Nc3 Nc6 3. f4 Nf6 4. fxe5 Nxe5 5. d4 Nc6 6. e5 Nd5 1-0",
    B: "1151",
  },
  {
    W: "2098",
    T: "60+0",
    P: "1. d4 e5 2. c4 d6 3. Nf3 Be7 4. dxe5 Nc6 5. exd6 Bxd6 6. g3 Qe7 7. Bg2 Nf6 8. O-O Be6 9. Nc3 O-O-O 10. Nd5 Qd7 11. Bg5 Ne7 12. Bxf6 gxf6 13. Qb3 Nxd5 14. cxd5 Bxd5 15. Qxd5 Rhg8 16. Rfd1 Qg4 17. Qxf7 h5 18. Rd4 Qf5 19. Rad1 Rdf8 20. Qb3 Bc5 21. Rd5 Qe4 22. Rxc5 Qxe2 23. Rcd5 Qe6 24. Rd8+ 1-0",
    B: "2092",
  },
  {
    W: "1335",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 f6 3. d4 d6 4. dxe5 fxe5 5. Nc3 c6 6. Bg5 Be7 7. Bxe7 Qxe7 8. Bc4 Bg4 9. O-O Qf6 10. Be2 Nd7 11. h3 Bh5 12. Qd2 O-O-O 13. Rad1 Nb6 14. g4 Bf7 15. a3 h6 16. b3 g5 17. a4 Ne7 18. a5 Na8 19. b4 a6 20. Na4 b5 21. Nb6+ Nxb6 22. axb6 Kb7 23. Qe3 Nc8 24. Ra1 Bc4 0-1",
    B: "1330",
  },
  {
    W: "1906",
    T: "60+0",
    P: "1. d4 g6 2. Bf4 Bg7 3. e3 d6 4. c3 Nf6 5. Be2 O-O 6. h4 Nbd7 7. h5 Re8 8. hxg6 fxg6 9. Bc4+ Kh8 10. Nf3 d5 11. Bb3 c6 12. Nbd2 Ne4 13. Qc2 e5 14. Nxe4 exf4 15. Neg5 fxe3 16. Rxh7+ Kg8 17. Qxg6 exf2+ 18. Kxf2 Qf6 19. Qh5 Rf8 20. Rh1 Rf7 21. Rxg7+ Qxg7 22. Nxf7 Kf8 23. N7e5 Nxe5 24. Nxe5 Bg4 25. Qxg4 Ke7 26. Qxg7+ Kd6 27. Rh6# 1-0",
    B: "1938",
  },
  {
    W: "2107",
    T: "60+0",
    P: "1. e4 e5 2. Nc3 Nc6 3. Nf3 Bc5 4. Bc4 d6 5. d3 Bg4 6. h3 Bxf3 7. Qxf3 Qf6 8. Qe2 Nge7 9. Be3 Bb6 10. O-O-O O-O 11. f3 Nd4 12. Bxd4 Bxd4 13. Nd5 Nxd5 14. Bxd5 c6 15. Bb3 b5 16. Kb1 a5 17. a4 bxa4 18. Bxa4 Rab8 19. b3 c5 20. Ka2 Bc3 21. Rb1 Rb4 22. Ka3 Rfb8 23. g4 Qd8 24. h4 Qb6 25. g5 Rd4 26. h5 Qb4+ 27. Ka2 Qxa4+ 28. bxa4 Rxa4# 0-1",
    B: "2213",
  },
  {
    W: "1974",
    T: "60+0",
    P: "1. e4 c5 2. Nc3 e6 3. Nf3 a6 4. a3 d5 5. exd5 exd5 6. d4 Nf6 7. Be2 Be7 8. Be3 O-O 9. O-O cxd4 10. Nxd4 Nc6 11. Nxc6 bxc6 12. Bd4 Bf5 13. Bd3 Qd7 14. Qf3 Bxd3 15. cxd3 Rab8 16. b4 Rfe8 17. Rae1 a5 18. Na4 axb4 19. axb4 Rxb4 20. Qe3 Bd6 21. Nc3 Rxe3 22. Rxe3 h6 23. Bxf6 gxf6 24. Ne2 Rb2 25. Ng3 c5 26. Nh5 Qd8 27. Rfe1 Be5 28. f4 Bd4 29. Kh1 Bxe3 30. h3 d4 31. Kh2 Kh7 32. Kg3 Kg6 33. Kg4 f5+ 34. Kg3 Kxh5 35. Kh2 Bxf4+ 36. Kg1 Qd6 37. Rf1 Bg3 38. Kh1 Kg6 39. h4 Rf2 40. Kg1 Rxf1+ 41. Kxf1 Qf4+ 42. Ke2 1-0",
    B: "1977",
  },
  {
    W: "1394",
    T: "180+2",
    P: "1. e4 g6 2. Nf3 Bg7 3. d4 d6 4. e5 dxe5 5. Bb5+ c6 6. Bc4 exd4 7. Bxf7+ Kxf7 8. Ng5+ Ke8 9. O-O e5 10. Qf3 Nf6 11. Re1 Nbd7 12. Ne6 Qe7 13. Nc7+ Kf7 14. Nxa8 Nc5 15. Nc7 Qxc7 16. Bg5 Rf8 17. b4 Ne6 18. Nd2 Nxg5 19. Qb3+ Be6 20. c4 b5 21. Qg3 h6 22. h4 Ngh7 23. c5 Kg8 24. Qxg6 Qf7 25. Qc2 Nd5 26. f3 Qf4 27. a4 Qxh4 28. axb5 Nxb4 29. Qb2 Nd3 30. Qb3 Nxe1 31. Rxe1 Qxe1+ 0-1",
    B: "1483",
  },
  {
    W: "1654",
    T: "180+2",
    P: "1. e4 c5 2. f4 Nc6 3. Nf3 d6 4. Bc4 e6 5. O-O a6 6. f5 exf5 7. exf5 Nh6 8. Nh4 Qxh4 9. g3 Qxc4 0-1",
    B: "1659",
  },
  {
    W: "1900",
    T: "180+2",
    P: "1. e4 c6 2. Nc3 d5 3. Nf3 dxe4 4. Nxe4 Nf6 5. Qe2 Nxe4 6. Qxe4 Nd7 7. Bc4 Nf6 8. Ne5 e6 9. Qe2 Bd6 10. d4 O-O 11. Bg5 Be7 12. O-O-O Nd5 13. Bxe7 Qxe7 14. g3 a5 15. h4 f6 16. Nd3 b5 17. Bxd5 cxd5 18. Nf4 Qd6 19. Rhe1 Re8 20. Qxb5 Bd7 21. Qd3 Rab8 22. Rd2 a4 23. a3 Rec8 24. c3 Qb6 25. Qe3 Qb3 26. h5 Qa2 27. Qd3 Bb5 28. Qb1 Qb3 29. Rxe6 Bd7 30. Ree2 Rxc3+ 31. Rc2 Rbc8 32. Rxc3 Rxc3+ 33. bxc3 Qxc3+ 0-1",
    B: "1874",
  },
  {
    W: "1956",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Qb6 5. Nc3 Qxd4 6. Bd3 Nf6 7. Be3 Qb4 8. Rb1 d5 9. a3 Qa5 10. b4 Qd8 11. exd5 Nxd5 12. Nxd5 Qxd5 13. b5 Qxg2 14. Rf1 Ne5 15. b6 a6 16. Bd4 Nf3+ 17. Ke2 Nxd4+ 18. Kd2 Bg4 19. Qc1 e5 20. Re1 Qxf2+ 0-1",
    B: "1970",
  },
  {
    W: "1672",
    T: "180+2",
    P: "1. e4 c6 2. Nf3 d5 3. Ng5 h6 4. Qh5 g6 5. Qh4 e6 6. d3 dxe4 7. dxe4 Bg7 8. Nc3 hxg5 9. Bxg5 Rxh4 10. Bxd8 Kxd8 11. O-O-O+ Nd7 12. g3 Rh8 13. Ne2 Kc7 14. f4 e5 15. Bg2 exf4 16. gxf4 Nb6 17. e5 Bf5 18. Nd4 Ne7 19. Nxf5 Nxf5 20. Be4 Rad8 21. Rdg1 Rh4 22. Bxf5 gxf5 23. Rxg7 Rxf4 24. Rxf7+ Kb8 25. e6 Nd5 26. e7 Nxe7 27. Rxe7 Rf8 28. h4 Re4 29. Rg7 f4 30. h5 f3 31. h6 f2 32. h7 Re1+ 0-1",
    B: "1664",
  },
  {
    W: "1032",
    T: "180+2",
    P: "1. e4 e5 2. Bc4 Nf6 3. d3 d5 4. exd5 Nxd5 5. Nc3 Be6 6. Qf3 c6 7. Be3 Nxc3 8. bxc3 Bxc4 9. dxc4 f6 10. Ne2 Be7 11. Ng3 g6 12. O-O f5 13. Rab1 e4 14. Qf4 Bd6 0-1",
    B: "1063",
  },
  {
    W: "1304",
    T: "180+2",
    P: "1. b3 e5 2. Bb2 d6 3. c4 b6 4. Nc3 Bb7 5. Nd5 Ne7 6. Nxe7 Bxe7 7. Nf3 h6 8. g3 Nd7 9. Bg2 Nf6 10. O-O Qd7 11. h3 g5 12. c5 dxc5 13. Rc1 O-O-O 14. a4 a6 15. d3 g4 16. Nxe5 Qe6 17. Bxb7+ Kxb7 18. h4 Ne4 19. Qe1 Ng5 20. Nc4 Rh7 21. a5 b5 22. Ne5 Qxb3 23. Qc3 Qxc3 24. Bxc3 Nh3+ 25. Kh2 Ng5 26. hxg5 hxg5+ 27. Kg2 f6 28. Nxg4 f5 29. Ne5 Bf6 30. f4 Re8 0-1",
    B: "1085",
  },
  {
    W: "1902",
    T: "60+3",
    P: "1. e4 g6 2. d4 Bg7 3. Nc3 d6 4. Be3 Nc6 5. Bc4 e5 6. Nge2 f5 7. exf5 Bxf5 8. Qd2 Nf6 9. f3 d5 10. Bb5 O-O 11. Bxc6 bxc6 12. dxe5 Nd7 13. f4 Qh4+ 14. Bf2 Qe7 15. O-O g5 16. Be3 gxf4 17. Nxf4 Nxe5 18. Rae1 Nc4 19. Qc1 Rae8 20. Bf2 Qd7 21. Rxe8 Rxe8 22. Re1 Rxe1+ 23. Qxe1 Kf7 24. Qe2 Qe7 25. Qxe7+ Kxe7 26. Bxa7 Kd6 27. Bf2 Nxb2 28. Kf1 Bxc3 29. Ne2 Be5 30. h3 Bxc2 31. Ke1 Bd3 32. Nc1 Be4 33. g4 Bc3+ 34. Ke2 Nc4 35. Kd1 c5 36. Ne2 Be5 37. Nc1 Bf3+ 38. Kc2 d4 39. Kb3 Nd2+ 40. Ka4 Ne4 41. Be1 c4 42. Kb4 Kd5 43. a4 c5+ 44. Kb5 d3 45. a5 c3 46. Nxd3 Bd4 47. a6 c2 48. a7 Nc3+ 49. Ka6 Kd6 50. Bg3+ Ke7 51. Bf4 c4 52. Nb4 Bd1 53. Nc6+ Kf6 54. a8=Q Bf3 55. Qf8+ Kg6 56. Nxd4 Bd1 57. Qf5+ Kg7 58. Be5+ Kg8 59. Ne6 1-0",
    B: "2025",
  },
  {
    W: "1993",
    T: "1800+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. c3 Nf6 4. e5 Nd5 5. d4 cxd4 6. cxd4 d6 7. Bd3 e6 8. exd6 Bxd6 9. Nc3 Ndb4 10. Be4 O-O 11. O-O f5 12. Bb1 Ne7 13. Bg5 Qe8 14. Bxe7 Bxe7 15. a3 Nc6 16. d5 exd5 17. Nxd5 Qh5 18. Ba2 Kh8 19. Re1 Bd6 20. Nb6 Bxh2+ 21. Nxh2 axb6 22. Qxh5 1-0",
    B: "2043",
  },
  {
    W: "1580",
    T: "120+1",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. Nf3 Nc6 5. Bc4 Bg4 6. O-O O-O-O 7. h3 Bxf3 8. Qxf3 Ne5 9. Qf5+ e6 10. Bxe6+ fxe6 11. Qxe6+ Kb8 12. d3 Nf6 13. Bg5 Re8 14. Qf5 Be7 15. Bf4 Nc6 16. Qxa5 Nxa5 17. Rfe1 Nc6 18. Nb5 Rc8 19. a4 a6 20. Nc3 Bb4 21. Re2 Bxc3 22. bxc3 Rhe8 23. Rxe8 Rxe8 24. g4 Nd5 25. Bd2 Ne5 26. Kg2 h6 27. c4 Nf6 28. Rb1 g5 29. Bc3 Nfd7 30. d4 Nc6 31. d5 Nce5 32. Re1 Kc8 33. Kg3 Kd8 34. f4 gxf4+ 35. Kxf4 Ng6+ 36. Kg3 Rxe1 37. Bxe1 Nde5 38. Bc3 Nxc4 39. h4 Ne3 40. h5 Ne7 41. Bf6 Nxd5 42. g5 hxg5 43. Bxg5 Kd7 44. Kg4 Ne3+ 45. Kf4 N3d5+ 46. Ke5 c6 47. h6 Ng6+ 48. Kf5 Nde7+ 49. Kf6 b5 50. axb5 axb5 51. Kg7 c5 52. h7 b4 53. Bxe7 Kxe7 54. Kxg6 c4 55. h8=Q 1-0",
    B: "1610",
  },
  {
    W: "1277",
    T: "120+1",
    P: "1. e4 g6 2. Bc4 e6 3. d4 c6 4. c3 a6 5. Nf3 d6 6. Bf4 Nd7 7. Qc2 Be7 8. Nbd2 Ngf6 9. O-O-O Ng4 10. Bg3 O-O 11. h3 Ngf6 12. e5 dxe5 13. dxe5 Nd5 14. Ne4 f5 15. Nf6+ N5xf6 16. exf6 Bxf6 17. h4 Ne5 18. Nxe5 Qa5 19. h5 b5 20. Bb3 g5 21. Nf3 f4 22. Bh2 h6 23. Nd4 Re8 24. Nxc6 Qb6 25. Nd4 Bb7 26. Bg1 Kg7 27. f3 Qa7 28. Nf5+ 1-0",
    B: "1196",
  },
  {
    W: "2132",
    T: "120+1",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. Nf3 c6 5. d4 Nf6 6. Bd2 Bg4 7. Bc4 e6 8. O-O Nbd7 9. h3 Bh5 10. a3 Qc7 11. Be2 Bd6 12. Ng5 Bxe2 13. Qxe2 O-O 14. Nge4 Bf4 15. Nxf6+ Nxf6 16. Ne4 Bxd2 17. Nxd2 Rad8 18. c3 c5 19. Ne4 Nxe4 20. Qxe4 cxd4 21. cxd4 b6 22. Rac1 Qd7 23. Rfd1 h6 24. Qc6 Qxc6 25. Rxc6 Rd7 26. a4 Rfd8 27. Rc4 g6 28. f4 Kg7 29. Kf2 Rd5 30. b3 h5 31. Ke3 h4 32. Rc7 R8d7 33. Rxd7 Rxd7 34. Rc1 Kf6 35. Ke4 Ke7 36. Rc8 Kd6 37. b4 Ke7 38. Kd3 Kf6 39. Ke4 Kg7 40. b5 Re7 41. Rc6 Rd7 42. Ke5 Rd5+ 43. Ke4 Rd7 44. Rc4 Kf6 45. Rc6 Ke7 46. Ke5 Rd5+ 47. Ke4 Kd7 48. Rc4 Rh5 49. Kf3 Rf5 50. Kg4 Rh5 51. g3 hxg3 52. Kxg3 Rh8 53. Kg4 Rd8 54. Kg5 Ke8 55. Kf6 Rd5 56. Rc8+ Kd7 57. Ra8 Rf5+ 58. Kg7 Rxf4 59. Rxa7+ Kd6 60. Rb7 Kd5 61. Rxb6 Kxd4 62. a5 Rf5 63. a6 Rf1 64. a7 Ra1 65. Rb7 e5 66. b6 e4 67. Rb8 e3 68. a8=Q Rxa8 69. Rxa8 e2 70. Re8 1-0",
    B: "2189",
  },
  {
    W: "1690",
    T: "120+1",
    P: "1. e4 c5 2. c3 e6 3. d4 cxd4 4. cxd4 d5 5. e5 Ne7 6. Nc3 Ng6 7. f4 Be7 8. Nf3 O-O 9. Bd3 Nd7 10. O-O b6 11. f5 a5 12. fxg6 fxg6 13. Be3 Ba6 14. Bxa6 Rxa6 15. Qd3 Ra8 16. Rac1 Rc8 17. Nb5 Nb8 18. Rxc8 Qxc8 19. Rc1 Qa6 20. a4 Bb4 21. Qc2 Qb7 22. Qc7 Qa6 23. Nd6 Qe2 24. Bf2 Bd2 25. Rc2 Qd1+ 26. Be1 Bxe1 27. Rc1 Qe2 28. Rxe1 Qxb2 29. Ng5 Qxd4+ 30. Kh1 Qf2 31. Rg1 Na6 32. Qxb6 Nb4 33. Qxf2 Rxf2 34. Nxe6 h5 35. Ng5 Ra2 36. Nf3 Rxa4 37. Nb7 Nd3 38. Nc5 Nf2# 0-1",
    B: "1690",
  },
  {
    W: "1667",
    T: "120+1",
    P: "1. f4 c5 2. Nf3 Nc6 3. e4 f6 4. Bb5 d6 5. Nc3 Bd7 6. O-O e5 7. fxe5 dxe5 8. d3 Bd6 9. a4 Nge7 10. Qe1 a6 11. Bxc6 Bxc6 12. Ne2 O-O 13. Ng3 f5 14. exf5 Nxf5 15. Nxf5 Rxf5 16. Be3 Qf6 17. Qf2 Rf8 18. Bxc5 Bxc5 19. Qxc5 Rxf3 20. gxf3 Qg5+ 21. Kh1 Bxf3+ 22. Rxf3 Rxf3 23. Qc8+ Rf8 24. Qe6+ Kh8 25. Rg1 Qe3 26. Qg4 Qf3+ 27. Qxf3 Rxf3 28. Kg2 Rf7 29. Re1 Re7 30. d4 e4 31. Re3 g6 32. Kg3 Kg7 33. Kf4 Rc7 34. c3 Rf7+ 35. Kxe4 Rf2 36. b4 Rxh2 37. Kd3 Ra2 38. d5 Rxa4 39. d6 Ra1 40. d7 Rd1+ 41. Kc4 Rxd7 42. Kc5 Kh6 43. Kb6 Rf7 44. Re8 g5 45. Rb8 g4 46. Rxb7 Rxb7+ 47. Kxb7 g3 48. Kxa6 g2 49. b5 g1=Q 50. b6 Qg7 51. b7 Qf6+ 52. Ka7 Qf2+ 53. Ka8 Qf8+ 54. Ka7 Qa3+ 55. Kb6 Kg6 56. c4 h5 57. c5 h4 58. c6 h3 59. b8=Q Qb3+ 60. Kc7 Qxb8+ 61. Kxb8 h2 62. c7 h1=Q 63. c8=Q Qb1+ 64. Qb7 Qg1 65. Qc7 Qb1+ 66. Qb7 Qf5 67. Qc7 Kg5 68. Qb7 Qf8+ 69. Qc8 Qb4+ 70. Qb7 Qd6+ 71. Qc7 Qf8+ 72. Qc8 Qf4+ 73. Qc7 Qb4+ 74. Qb7 Qf8+ 75. Qc8 1/2-1/2",
    B: "1631",
  },
  {
    W: "1452",
    T: "120+1",
    P: "1. g3 d5 2. Bg2 Nc6 3. Nf3 e6 4. d4 Nf6 5. Bg5 Bb4+ 6. c3 Be7 7. Nbd2 O-O 8. Ne5 Nxe5 9. dxe5 Nd7 10. Bxe7 Qxe7 11. e4 Nc5 12. exd5 Nd3+ 13. Ke2 Nxe5 14. dxe6 Qxe6 15. Re1 Qa6+ 16. Ke3 Qd3+ 17. Kf4 Ng6+ 18. Kg5 Qf5# 0-1",
    B: "1424",
  },
  {
    W: "1468",
    T: "120+1",
    P: "1. d4 e6 2. e4 d5 3. exd5 exd5 4. Nf3 Nf6 5. Be2 Be7 6. O-O O-O 7. c4 c6 8. cxd5 Nxd5 9. Nc3 Be6 10. Ne5 Nd7 11. Nxd7 Qxd7 12. Bf3 Bf6 13. Re1 Rad8 14. Be3 Rfe8 15. Nxd5 Bxd5 16. Bxd5 Qxd5 17. Qc2 Bxd4 18. Red1 c5 19. b4 Qe5 20. bxc5 Bxa1 21. Rxd8 Rxd8 22. c6 bxc6 23. h3 Bd4 24. Bxd4 Rxd4 25. Qxc6 Rd1# 0-1",
    B: "1571",
  },
  {
    W: "1885",
    T: "120+1",
    P: "1. d4 b5 2. Bf4 Bb7 3. Nf3 e6 4. e3 a6 5. c4 bxc4 6. Nc3 Nf6 7. Bxc4 c5 8. O-O cxd4 9. exd4 Be7 10. d5 Bb4 11. dxe6 fxe6 12. Re1 O-O 13. Ng5 Bxc3 14. bxc3 Nd5 15. Bd6 Qxg5 16. Bxf8 Kxf8 17. Qf3+ Qf6 18. Qxf6+ Nxf6 19. Rab1 Bd5 20. Bd3 Nc6 21. Rb6 Bxa2 22. Ra1 Bd5 23. Rbxa6 Rxa6 24. Rxa6 Ke7 25. f3 e5 26. c4 Nb4 27. cxd5 Nxa6 28. Bxa6 Nxd5 29. Kf2 d6 30. Bd3 g6 31. Be4 Nf4 32. g3 Ne6 33. Ke3 Nc5 34. Bd5 Kf6 35. h4 h6 36. g4 Ne6 37. Bxe6 Kxe6 38. f4 exf4+ 39. Kxf4 Kf6 40. Ke4 g5 41. h5 Ke6 42. Kd4 d5 43. Kc5 Ke5 44. Kc6 d4 45. Kd7 Kf6 46. Ke8 Kg7 0-1",
    B: "1710",
  },
  {
    W: "1903",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 f5 3. d3 d6 4. Nc3 Be7 5. Be2 Nf6 6. O-O c6 7. Bg5 O-O 8. Bxf6 h6 9. Bxe7 Qxe7 10. h3 Be6 11. exf5 Bxf5 12. Re1 Nd7 13. Bf1 Qf7 14. Re3 Nf6 15. Ne4 Nd5 16. Re1 Rad8 17. Ng3 Bxh3 18. gxh3 Qxf3 19. Qxf3 Rxf3 20. Bg2 Rf6 21. Ne4 Rg6 22. a3 Nf4 23. Ng3 h5 24. h4 Nxg2 25. Kxg2 Rg4 26. Re4 Rf4 27. Rxf4 exf4 28. Nxh5 Rf8 29. Kf3 Rf5 30. Nxf4 1-0",
    B: "1918",
  },
  {
    W: "1036",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 d6 3. Nh4 g5 4. Nf5 Bxf5 5. exf5 Qf6 6. Bb5+ c6 7. Bd3 d5 8. O-O e4 9. Qg4 exd3 10. cxd3 Nh6 11. Qf3 g4 12. Qf4 Bd6 13. g3 Bxf4 14. gxf4 Qxf5 15. Re1+ Kf8 16. Nc3 Qxf4 17. Ne4 Rg8 18. b3 g3 19. Ba3+ Ke8 20. Nf6+ Kd8 21. Be7+ Kc8 22. Nh5 gxf2+ 23. Kh1 Rg1+ 24. Rxg1 Qf3+ 25. Rg2 Qxh5 26. Rxf2 Na6 27. Raf1 Kc7 28. Rxf7 Nxf7 29. Rf6 Qd1+ 30. Kg2 Qxd2+ 31. Kf1 Qxd3+ 32. Ke1 Qe3+ 33. Kd1 Qxe7 34. Rf2 d4 35. Re2 Qd7 36. Kd2 d3 37. Re3 Re8 38. Rxd3 Qe6 39. Re3 Qd6+ 40. Rd3 Qxh2+ 41. Kc3 h5 42. Rf3 Qe5+ 43. Kd3 Nc5+ 44. Kc4 Qd5+ 45. Kb4 Qxf3 46. Kxc5 b6+ 47. Kc4 h4 48. b4 h3 0-1",
    B: "1088",
  },
  {
    W: "1435",
    T: "60+0",
    P: "1. e4 c5 2. c3 Nc6 3. d4 e6 4. d5 exd5 5. exd5 Nb8 6. c4 d6 7. Nc3 Nf6 8. Nf3 Be7 9. Ne5 O-O 10. Nf3 Ng4 11. Bd3 Bf6 12. O-O Bxc3 13. bxc3 Qf6 14. Qc2 Nh6 15. h3 g6 16. Ng5 Nf5 17. Bxf5 Bxf5 18. Qd2 h6 19. Nf3 Qg5 20. Qxg5 hxg5 21. Nxg5 Nd7 22. Bf4 Ne5 23. Rfe1 Nxc4 24. Bxd6 Nxd6 25. g4 Bd3 26. Red1 c4 27. Rab1 Rad8 28. Kg2 Ne4 29. Nxe4 Bxe4+ 30. Kg3 Bxb1 31. Rxb1 Rxd5 32. Rxb7 Rd3+ 33. Kf4 Rxc3 34. Rxa7 Rc1 35. Rc7 c3 36. Ke3 c2 37. Kd3 Rd1+ 38. Kxc2 Rg1 39. f4 Rg2+ 40. Kb3 Rh2 41. f5 Rxh3+ 0-1",
    B: "1512",
  },
  {
    W: "1680",
    T: "120+1",
    P: "1. d4 c5 2. d5 d6 3. e4 e6 4. Bb5+ Bd7 5. a4 exd5 6. exd5 Nf6 7. Ne2 Be7 8. O-O O-O 9. h3 a6 10. Bc4 Qc8 11. Nbc3 Bxh3 12. gxh3 Qxh3 13. Nf4 Qh4 14. Qf3 Nbd7 15. Qg3 Qxg3+ 16. fxg3 Ne5 17. Be2 Rfb8 18. Nd3 Neg4 19. Bf4 b5 20. axb5 axb5 21. Rxa8 Rxa8 22. Nxb5 Rb8 23. c4 1-0",
    B: "1681",
  },
  {
    W: "2326",
    T: "300+0",
    P: "1. Nf3 Nf6 2. b3 g6 3. Bb2 Bg7 4. d4 d5 5. Nbd2 O-O 6. e3 b6 7. Bd3 Bb7 8. O-O c5 9. c4 Nbd7 10. Rc1 Rc8 11. Ne5 cxd4 12. exd4 dxc4 13. Ndxc4 Nd5 14. h3 Nf4 15. f3 Nxe5 16. Nxe5 Bxe5 17. Rxc8 Bxd4+ 18. Bxd4 Qxd4+ 19. Kh1 Rxc8 20. Bc4 Qxd1 21. Rxd1 Rc7 22. Rd8+ Kg7 23. Rb8 e6 24. Kh2 Nd5 25. h4 h6 26. g3 Ne3 27. f4 Nxc4 28. bxc4 Be4 29. g4 Rxc4 30. Kg3 Ra4 31. f5 exf5 32. gxf5 Bxf5 33. Rb7 Be6 34. h5 gxh5 35. a3 h4+ 36. Kf3 h3 37. Kg3 Rxa3+ 38. Kh2 Ra2+ 39. Kg3 h2 0-1",
    B: "2390",
  },
  {
    W: "1628",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d3 Bc5 4. c4 Nf6 5. h3 Nd4 6. Nxe5 d6 7. Nf3 Qe7 8. a3 Be6 9. Nc3 O-O-O 10. Na4 Nd7 11. b4 Bb6 12. Nxb6+ Nxb6 13. Nxd4 Na4 14. Qxa4 1-0",
    B: "1605",
  },
  {
    W: "2078",
    T: "300+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 Nf6 5. Bd3 Bg4 6. Nf3 e6 7. Bd2 c6 8. h3 Bh5 9. Ne4 Qc7 10. Nxf6+ gxf6 11. Qe2 Nd7 12. O-O-O O-O-O 13. g4 Bg6 14. Kb1 Bd6 15. c4 Bf4 16. Bc3 Rhe8 17. Qc2 Nb6 18. c5 Nd5 19. Qa4 Nxc3+ 20. bxc3 Kb8 21. Ka1 e5 22. Bxg6 fxg6 23. Rb1 e4 24. Ne1 e3 25. f3 e2 26. Nd3 Re3 27. Qc4 Rde8 28. Rbe1 Rxf3 29. Nxf4 Qxf4 30. Rxe2 Rf1+ 31. Kb2 Rxe2+ 32. Qxe2 Rxh1 33. Qe8+ Kc7 34. Qe7+ Kc8 35. Qe8+ Kc7 36. Qe7+ Kc8 37. Qe8+ Kc7 1/2-1/2",
    B: "2041",
  },
  {
    W: "1560",
    T: "300+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 Nf6 5. Bb5+ c6 6. Bd3 Bg4 7. O-O Nbd7 8. Re1+ Be7 9. Bd2 O-O 10. Qe2 Bd6 11. Qd1 Nh5 12. Be2 Re8 13. Bg5 f6 14. Bd2 Re4 15. Be3 Rxe3 16. fxe3 f5 17. Ne5 Bxe5 18. Bxg4 Bxh2+ 19. Kxh2 Qh4+ 20. Kg1 Ng3 21. Bh3 g5 22. Nd2 g4 23. Nf3 Qh5 24. Kf2 Ne4+ 25. Kg1 gxf3 26. Qxf3 Qxf3 27. gxf3 Ng5 28. Bg2 h5 29. Kf2 h4 30. Rh1 Kf7 31. Rxh4 Rg8 32. Rf4 Kf6 33. Rh1 Rg6 34. Rh8 c5 35. Rd8 Nb6 36. e4 dxe4 37. fxe4 Nxe4+ 38. Bxe4 Rg5 39. Rd6+ Ke7 40. Rh6 Nd5 41. Rxf5 Rxf5+ 42. Bxf5 Nf6 43. Rg6 cxd4 44. Bd3 b5 45. a3 a5 46. Bxb5 Nh5 0-1",
    B: "1534",
  },
  {
    W: "1749",
    T: "300+0",
    P: "1. d4 d5 2. e3 Nf6 3. Nf3 e6 4. a3 Nc6 5. b4 a6 6. c4 dxc4 7. Bxc4 Be7 8. Nbd2 Bd7 9. Bb2 b5 10. Bd3 Nd5 11. Qc2 Qc8 12. Bxh7 g6 13. Bxg6 fxg6 14. Qxg6+ Kd8 15. Qg7 Bf6 16. Qxf6+ Nxf6 17. d5 Nxd5 18. Bxh8 Ke7 19. Bb2 Qg8 20. O-O Rf8 21. g3 e5 22. Bxe5 Nxe5 23. Nxe5 Bh3 24. Rfc1 Qe6 25. Nef3 Nxe3 26. fxe3 Qxe3+ 27. Kh1 Qf2 28. Re1+ Kd8 29. Rg1 Rxf3 30. Nxf3 Qxf3+ 0-1",
    B: "1767",
  },
  {
    W: "1768",
    T: "300+0",
    P: "1. Nc3 d5 2. b3 c5 3. e3 Nc6 4. Bb2 e5 5. g3 f6 6. Bg2 Be6 7. Nf3 Nge7 8. O-O Ng6 9. d3 Be7 10. Nb5 a6 11. Nc3 Qd7 12. Ne2 O-O-O 13. Nd2 Bh3 14. e4 Bxg2 15. Kxg2 d4 16. Nc4 Kb8 17. a4 h5 18. a5 h4 19. Nb6 Qe6 20. Kg1 hxg3 21. fxg3 Rh5 22. Nc1 Rdh8 23. Rf2 Bd8 24. Qe2 Bxb6 25. axb6 Nge7 26. Na2 Nc8 27. c3 Nxb6 28. cxd4 cxd4 29. b4 Na4 30. Nc1 Nxb2 31. Qxb2 Qd6 32. b5 axb5 33. Qxb5 Qe6 34. Qb2 Qg4 35. Qe2 Qxe2 36. Nxe2 Nb4 37. Nc1 Rc8 38. Rb1 Nc2 39. Rb2 Ne3 40. Ne2 Rch8 41. h4 Ng4 42. Rg2 Rc8 43. Nf4 exf4 44. gxf4 Ne3 45. Rxg7 Rxh4 46. Rbxb7+ 1-0",
    B: "1828",
  },
  {
    W: "1349",
    T: "300+0",
    P: "1. e4 g6 2. d4 Bg7 3. c4 c5 4. Nf3 cxd4 5. Nxd4 Nc6 6. Be3 d6 7. Nxc6 bxc6 8. Nc3 Nf6 9. Qc2 Qc7 10. Bd3 O-O 11. O-O Rb8 12. b3 a5 13. Ne2 Ng4 14. Rab1 Nxe3 15. fxe3 Bb7 16. Nd4 c5 17. Nb5 Qb6 18. Nc3 Rfe8 19. Nd5 Bxd5 20. exd5 e6 21. Qf2 Rb7 22. dxe6 Rxe6 23. Rfe1 Ree7 24. Kh1 Qc7 25. Qg3 a4 26. Qg4 Qd7 27. Qxd7 Rexd7 28. Be4 Rb4 29. Bd5 axb3 30. axb3 Re7 31. e4 Bd4 32. Rf1 Kg7 33. Rf3 Kh6 34. Rxf7 Re8 35. Rd7 Rb6 36. Rb7 Rxb7 37. Bxb7 Rf8 38. Bd5 Rf2 39. Kg1 Rc2+ 40. Kf1 Rf2+ 41. Ke1 Rxg2 42. b4 Bc3+ 43. Kd1 Bxb4 44. Rxb4 cxb4 45. e5 dxe5 46. Bxg2 e4 47. Bxe4 Kh5 48. Kc2 Kg5 49. Kb3 Kf6 50. Kxb4 Ke6 51. Kc5 Kd7 52. Kd5 Kc7 53. Ke6 h5 54. Kf6 g5 55. Kxg5 Kd6 56. Bd5 Ke5 57. Kxh5 Kf4 58. Kg6 1-0",
    B: "1408",
  },
  {
    W: "1477",
    T: "300+0",
    P: "1. d4 b6 2. c4 Bb7 3. d5 e6 4. e4 c5 5. Nc3 g6 6. Bd3 Bg7 7. Qc2 Ne7 8. Nf3 O-O 9. O-O Bxc3 10. Qxc3 exd5 11. cxd5 f5 12. Bh6 Rf7 13. exf5 Nxf5 14. Rfe1 Nxh6 15. Rad1 d6 16. Bc4 Ba6 17. Bxa6 Nxa6 18. Ng5 Re7 19. Ne6 Qd7 20. Rd2 Nc7 21. Rde2 Nxe6 22. dxe6 Qc6 23. Qf6 Qb7 24. f3 Rf8 25. Qg5 Nf5 26. Rd2 c4 27. Re4 b5 28. a3 Ng7 29. Qg3 d5 30. Re5 Rxe6 31. Rxe6 Nxe6 32. h3 Nc5 33. Qg4 Nb3 34. Qe6+ Rf7 35. Rxd5 Nc1 36. Rd8+ Kg7 37. Qe5+ Rf6 38. Qc3 Ne2+ 39. Kh2 Nxc3 40. bxc3 Qc7+ 41. Kg1 Qxd8 42. Kh2 Qd1 43. Kg3 Rxf3+ 44. Kh2 Rf2 45. Kg3 Qe2 46. Kh4 Rxg2 47. a4 Qf3 48. axb5 Rg3 49. b6 Rxh3+ 50. Kg5 Qg3# 0-1",
    B: "1437",
  },
  {
    W: "1910",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O Bc5 6. c3 O-O 7. Bg5 h6 8. Bh4 Be7 9. Bxf6 Bxf6 10. cxd4 d6 11. Nc3 Bg4 12. h3 Bh5 13. g4 Bg6 14. Qd2 Re8 15. Rfe1 a6 16. Rad1 Rb8 17. Qf4 b5 18. Bb3 Na5 19. e5 Nxb3 20. axb3 Bg5 21. Nxg5 Qxg5 22. Qf3 dxe5 23. dxe5 Rxe5 24. Rxe5 Qxe5 25. Rd5 Qe1+ 26. Kh2 Re8 27. Rd7 Qe5+ 28. Kg1 c5 29. Ra7 Qe1+ 30. Kh2 Qe5+ 31. Kg1 Qe1+ 32. Kh2 Qe5+ 33. Kg1 Qd6 34. Kg2 b4 0-1",
    B: "1857",
  },
  {
    W: "1251",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nxd4 5. Qxd4 Nf6 6. Nc3 c5 7. Qe3 Bd6 8. Bd3 O-O 9. h3 Re8 10. O-O b6 11. b3 Bb7 12. Bb2 Qe7 13. Rfe1 Be5 14. f4 Bxc3 15. Bxc3 d5 16. e5 d4 17. Qe2 dxc3 18. exf6 Qxe2 19. Rxe2 gxf6 20. Rxe8+ Rxe8 21. Rd1 b5 22. Bxb5 Re4 23. Rd7 Bc8 24. Rxa7 Rxf4 25. Re7 Rf5 26. Bc4 Be6 27. a4 Bxc4 28. bxc4 Rf4 29. Re1 Rxc4 30. Rc1 Rxa4 31. g4 Rf4 32. Kg2 f5 33. g5 c4 34. Kg3 Re4 35. h4 Kg7 36. Kf3 Rxh4 37. Ke3 Rh3+ 38. Kd4 Rh4+ 39. Kxc3 f4 40. Kxc4 f3+ 41. Kd3 Rh3 42. Rf1 f2+ 43. Ke2 Rh2 44. Rxf2 Rxf2+ 45. Kxf2 Kg6 46. c4 Kxg5 47. c5 Kf6 48. c6 Ke7 49. c7 Kd7 50. c8=Q+ Kxc8 0-1",
    B: "1289",
  },
  {
    W: "1911",
    T: "300+0",
    P: "1. Nf3 Nf6 2. d3 d5 3. g3 d4 4. c3 dxc3 5. bxc3 b6 6. Bg2 Bb7 7. Nbd2 g6 8. O-O Bg7 9. Qc2 O-O 10. e4 Nc6 11. Ba3 Re8 12. Nc4 e6 13. Bc1 e5 14. Rb1 Qd7 15. Rd1 Rad8 16. Bg5 h6 17. Be3 b5 18. Rxb5 Ba6 19. Rbb1 Bxc4 20. dxc4 Qe6 21. Rb7 Ng4 22. Bxa7 Nxa7 23. Rxa7 Rxd1+ 24. Qxd1 Qxc4 25. Qc2 Qc5 26. Rb7 Ra8 27. h3 Nf6 28. Bf1 Ra3 29. Rb3 Ra8 30. Rb4 Bf8 31. Rb5 Qc6 32. Nxe5 Qxe4 33. Qxe4 Nxe4 34. Bg2 Nxc3 35. Rc5 Ra3 36. Rxc7 Bd6 37. Rc8+ Kg7 38. Nd7 Ne2+ 39. Kf1 Rxa2 40. f4 Nxg3+ 41. Kg1 Bxf4 42. Bd5 Be3# 0-1",
    B: "2019",
  },
  {
    W: "871",
    T: "300+0",
    P: "1. d4 Nf6 2. Nc3 d5 3. b4 Bf5 4. f4 Nc6 5. g3 Nxb4 6. a3 Nxc2+ 7. Qxc2 Bxc2 0-1",
    B: "1156",
  },
  {
    W: "1571",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. Bxc6+ bxc6 5. h3 h6 6. Nc3 Nf6 7. d3 c5 8. b3 Be6 9. Bb2 Be7 10. Qd2 O-O 11. O-O-O Nh7 12. Kb1 Bg5 13. Nxg5 Qxg5 14. Qxg5 hxg5 15. h4 gxh4 16. Rxh4 Ng5 17. Bc1 f6 18. Rdh1 Kf7 19. f4 Nxe4 20. Nxe4 f5 21. Ng5+ Kf6 22. Nxe6 Kxe6 23. fxe5 dxe5 24. Bb2 g6 25. Re1 e4 26. dxe4 fxe4 27. Rhxe4+ Kf5 28. Re5+ Kf4 29. R1e4+ Kg3 30. Rg5+ Kf2 31. Rxg6 Rad8 32. Ree6 Rd1+ 33. Bc1 a5 34. Rgf6+ Rxf6 35. Rxf6+ Kxg2 36. Rc6 Rd7 37. Rxc5 c6 38. Rxa5 Rc7 39. Rc5 Kf3 40. a4 Ke4 41. a5 Kd4 42. Rc4+ Kd5 43. a6 c5 44. Be3 Ra7 45. Rxc5+ Ke4 46. Re5+ Kxe5 47. Bxa7 Kd6 48. Bf2 Kc7 49. a7 Kb7 50. Kb2 1-0",
    B: "1598",
  },
  {
    W: "1154",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 f6 3. d4 d6 4. Bb5+ c6 5. Bc4 b5 6. Bb3 Nh6 7. d5 c5 8. c4 b4 9. Bc2 g5 10. Ba4+ Bd7 11. Bxd7+ Nxd7 12. a3 Qa5 13. Nbd2 Nb6 14. Nb3 Qa6 15. Be3 Qxc4 16. Nbd2 Qa6 17. h4 g4 18. Nh2 f5 19. exf5 Nxf5 20. Nxg4 Rg8 21. Nf6+ Kf7 22. Nxg8 Kxg8 23. Qh5 Nxd5 24. Qg4+ Ng7 25. Qe6+ Nxe6 26. h5 bxa3 27. h6 Nb4 0-1",
    B: "1233",
  },
  {
    W: "1905",
    T: "120+0",
    P: "1. e4 e5 2. Bc4 f5 3. d3 Nf6 4. exf5 d5 5. Bb3 Bxf5 6. Nf3 Bd6 7. O-O c6 8. c4 Be6 9. Re1 Nbd7 10. Bg5 O-O 11. Nc3 d4 12. Ne4 Qc7 13. c5 Bxb3 14. Qxb3+ Kh8 15. Nxd6 Nd5 16. Nxe5 Nxc5 17. Ndf7+ Rxf7 18. Nxf7+ Qxf7 19. Qa3 Ne6 20. Bh4 Re8 21. Qxa7 g5 22. Bg3 h6 23. Be5+ Kh7 24. Bxd4 Nxd4 25. Qxd4 Rxe1+ 26. Rxe1 Kg6 27. Qe4+ Qf5 28. Qe6+ Qxe6 29. Rxe6+ Kf5 30. Re8 1-0",
    B: "1386",
  },
  {
    W: "1619",
    T: "60+0",
    P: "1. e4 c5 2. Nc3 e6 3. Nf3 Nc6 4. d3 d5 5. exd5 exd5 6. Bd2 Bd6 7. g3 d4 8. Nb5 Be7 9. Bg2 Nf6 10. O-O a6 11. Na3 b5 12. c3 dxc3 13. Bxc3 b4 14. Bd2 O-O 15. Nc4 Bg4 16. Re1 Nd4 17. Nce5 Bd6 18. Nxg4 Nxf3+ 19. Bxf3 Nxg4 20. Bxg4 Re8 21. Rxe8+ Qxe8 22. Qc2 Qe7 23. Re1 Qg5 24. Bxg5 1-0",
    B: "1566",
  },
  {
    W: "1338",
    T: "300+3",
    P: "1. e4 c5 2. c3 e5 3. h3 Nc6 4. d3 Nf6 5. Qf3 Be7 6. Be2 O-O 7. Qg3 Kh8 8. Nf3 d5 9. Nfd2 d4 10. c4 Be6 11. a3 Rb8 12. O-O a6 13. Kh1 b5 14. f4 Bd6 15. f5 Bd7 16. b3 g6 17. Qf2 Qe8 18. g4 Rb7 19. g5 Ng8 20. f6 h6 21. h4 h5 22. Qg3 Qe6 23. Rf3 bxc4 24. dxc4 Qg4 25. Qh2 d3 26. Bxd3 Nd4 27. Rg3 Qd1+ 28. Rg1 Rxb3 29. Rxd1 1-0",
    B: "1313",
  },
  {
    W: "940",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. O-O Bd7 5. c3 a6 6. Ba4 b5 7. Bb3 Na5 8. d4 exd4 9. Nxd4 Nxb3 10. axb3 Nf6 11. Qf3 Bg4 12. Qf4 d5 13. e5 Bc5 14. exf6 Bxd4 15. cxd4 Bh5 16. fxg7 Rg8 17. Re1+ Kd7 18. Qxf7+ Bxf7 0-1",
    B: "1147",
  },
  {
    W: "1551",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nf6 3. Bc4 Nxe4 4. Nxe5 d5 5. Qf3 Be6 6. d3 Nd6 7. Bb3 Be7 8. O-O c6 9. c4 dxc4 10. dxc4 O-O 11. Nc3 f6 12. Ng4 Bxc4 13. Bxc4+ Nxc4 14. Bf4 Bd6 15. Rad1 Qc7 16. Bxd6 Nxd6 17. Qf4 Nb5 18. Qxc7 Nxc7 19. Rd7 Nba6 20. Re1 Rad8 21. Ree7 Rxd7 22. Rxd7 b6 23. f4 Ne6 24. Rxa7 Nxf4 25. Rxa6 1-0",
    B: "1593",
  },
  {
    W: "1478",
    T: "300+3",
    P: "1. e4 e6 2. e5 d5 3. exd6 Bxd6 4. d4 Nf6 5. Nc3 c6 6. f4 O-O 7. Nf3 Nbd7 8. Bd3 Nd5 9. Nxd5 exd5 10. O-O Nf6 11. Ne5 h6 12. c3 Be6 13. f5 Bc8 14. Bf4 Ne4 15. g4 f6 16. Ng6 Re8 17. Qc2 Bxf4 18. Rxf4 Ng5 19. Kg2 Re3 20. c4 b6 21. cxd5 Qxd5+ 22. Kf2 Rxd3 23. Ne7+ 1-0",
    B: "1507",
  },
  {
    W: "1516",
    T: "300+3",
    P: "1. e4 g6 2. d4 e6 3. e5 Bg7 4. Nf3 Ne7 5. c4 O-O 6. Be2 c6 7. Nc3 Qa5 8. Kf1 Nf5 9. h4 d5 10. c5 b6 11. cxb6 axb6 12. a3 b5 13. b4 Qd8 14. h5 Bb7 15. hxg6 fxg6 16. Ng5 Nxd4 17. Nxe6 Nxe6 18. Bg4 Ng5 19. Qd2 Ne4 20. Nxe4 dxe4 21. e6 Bxa1 22. Qe3 Qd3+ 23. Be2 Qxe3 24. Bxe3 Na6 25. Rh4 Rae8 26. Bh6 Rf6 27. Bg4 Bc8 28. Bg5 Rf4 29. e7 Bxg4 30. Bxf4 h5 31. Bd6 Bf6 32. f3 Bxe7 33. Bxe7 exf3 34. gxf3 Bxf3 35. Bf6 Be2+ 36. Kf2 c5 37. bxc5 Nxc5 38. Rb4 Nd3+ 39. Kg3 Nxb4 40. axb4 Re4 41. Bc3 h4+ 42. Kf2 h3 43. Kg1 Rg4+ 44. Kh2 Rh4 45. Be1 Rxb4 46. Kxh3 Rb3+ 47. Kg2 b4 48. Kf2 Rb2 49. Ke3 b3 50. Bd2 Bb5 51. Bc3 Ra2 52. Kd4 b2 53. Bxb2 Rxb2 54. Ke5 g5 55. Kf5 Rg2 56. Ke4 g4 57. Ke3 g3 58. Kf3 Rg1 59. Kg4 g2 60. Kg3 Bc6 61. Kf2 Ra1 62. Kg3 g1=Q+ 63. Kf4 Rf1+ 0-1",
    B: "1202",
  },
  {
    W: "1944",
    T: "300+3",
    P: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. d4 Bg4 5. Be2 Nc6 6. Nc3 Nf6 7. Bf4 a6 8. O-O e6 9. Ne5 Bxe2 10. Qxe2 Nxd4 11. Qd3 Nc6 12. Nxc6 bxc6 13. Na4 Be7 14. Qc3 Qd7 15. Nb6 Ne4 16. Qxg7 Bf6 17. Qxh8+ Bxh8 18. Nxd7 Kxd7 19. c3 h5 20. f3 Ra7 21. fxe4 1-0",
    B: "1902",
  },
  {
    W: "1683",
    T: "300+3",
    P: "1. Nf3 Nf6 2. g3 d6 3. Bg2 Nc6 4. O-O g6 5. d3 Bg7 6. Nbd2 O-O 7. e4 Bg4 8. h3 Bxf3 9. Bxf3 e5 10. c3 Qd7 11. Bg2 Ne8 12. Nc4 f5 13. exf5 Qxf5 14. f4 exf4 15. Rxf4 Qc5+ 16. Be3 Qb5 17. Rxf8+ Kxf8 18. Qf3+ Nf6 19. Bg5 Ne5 20. Nxe5 Qxe5 21. Bxf6 Bxf6 22. Rf1 Kg7 23. Qxb7 Qe3+ 24. Kh2 Re8 25. Qxc7+ Re7 26. Qxd6 Bg5 27. Be4 Qe2+ 28. Bg2 Qe3 29. h4 Bh6 30. Qf6+ Kg8 31. Bd5+ Re6 32. Bxe6+ 1-0",
    B: "1808",
  },
  {
    W: "1272",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Bc5 3. Bc4 g5 4. Nxe5 Qf6 5. Nf3 g4 6. e5 Qf5 7. d4 gxf3 8. dxc5 Qe4+ 9. Be2 fxe2 10. Qxe2 Qxg2 11. Rf1 Nc6 12. Bf4 Nd4 13. Qd2 Nf3+ 14. Ke2 Nxd2 15. Nxd2 d5 16. c4 Bg4+ 17. Kd3 Qxf2 18. Rxf2 O-O-O 19. cxd5 Rxd5+ 20. Ke4 Ne7 21. Rc1 Rhd8 22. Rg2 Rd4+ 23. Ke3 Nf5+ 24. Kf2 Rxf4+ 25. Kg1 Ne3 26. Re2 Bf3 27. Rxe3 Rxd2 28. e6 Rg4+ 29. Kf1 Bg2+ 30. Ke1 Rxb2 31. exf7 Rf4 32. Re8+ Kd7 33. f8=Q Rf1+ 34. Qxf1 Bxf1 35. Rf8 Bd3 36. Rd1 Re2+ 37. Kf1 Re3+ 38. Kf2 Re2+ 39. Kg3 Re3+ 40. Kh4 Re4+ 41. Kh5 Re5+ 42. Kh6 Re6+ 43. Kg5 Rg6+ 44. Kh5 Ke7 45. Rh8 Be2+ 46. Kh4 Bxd1 47. Rxh7+ Kf6 48. Rxc7 Kf5 49. Rxb7 Kf4 50. Kh3 Kf3 51. Kh4 Kf4 52. Rf7+ Ke5 53. Re7+ Kf4 54. Rf7+ Ke5 55. Rf1 Bg4 56. Re1+ Kf4 57. Rf1+ Bf3 0-1",
    B: "1247",
  },
  {
    W: "1170",
    T: "300+3",
    P: "1. d4 d5 2. Bf4 Bf5 3. Nd2 e6 4. Ngf3 c5 5. e3 a6 6. Bd3 Nc6 7. Bxf5 exf5 8. Ne5 cxd4 9. exd4 Nxd4 10. Nxf7 Kxf7 11. O-O Bd6 12. Be3 Qh4 13. h3 Nf6 14. Nf3 Qh5 15. Bxd4 Ng4 16. Re1 1-0",
    B: "1196",
  },
  {
    W: "1778",
    T: "300+3",
    P: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Nxc3 Nc6 5. Bc4 Bc5 6. Bxf7+ Kxf7 7. Qh5+ g6 8. Qxc5 d6 9. Qc4+ Be6 10. Qe2 Qf6 11. Nf3 Nge7 12. Bg5 Qg7 13. O-O Rhf8 14. Rfe1 Kg8 15. e5 Bg4 16. Qc4+ Kh8 17. Qxg4 Rf5 18. exd6 cxd6 19. Qh4 Nd5 20. Nxd5 1-0",
    B: "1737",
  },
  {
    W: "1143",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Bc5 3. Bc4 f6 4. d4 exd4 5. Nxd4 d5 6. exd5 Qe7+ 7. Be3 Nh6 8. O-O Ng4 9. Nf5 Qe4 10. Bxc5 Qxc4 11. Re1+ Kf7 12. Be3 Bxf5 13. c3 Rd8 14. Na3 Qe4 15. Bxa7 Rxa7 16. Rxe4 Bxe4 17. Qxg4 Bxd5 18. Re1 Ra6 19. Qf5 Be6 20. Qxe6+ Kg6 21. Qe4+ Kf7 22. Qc4+ Kg6 0-1",
    B: "1139",
  },
  {
    W: "1433",
    T: "60+0",
    P: "1. g3 b6 2. Bg2 c6 3. Nf3 Bb7 4. O-O Qc7 5. d3 c5 6. Nbd2 Bxf3 7. Bxf3 Nc6 8. Bg2 Rc8 9. Nf3 Ne5 10. Rb1 Nxf3+ 11. Bxf3 d6 12. b3 Nf6 13. Bb2 e5 14. c4 Nd7 15. Bg2 f6 16. e3 Be7 17. d4 O-O 18. dxc5 bxc5 19. Qe2 Nb6 20. Qd2 Rcd8 21. Bd5+ Kh8 22. Bg2 a5 23. Qxa5 Ra8 24. Qd2 Rxa2 25. Qc1 Raa8 26. Ra1 Rxa1 27. Qxa1 Ra8 28. Qc1 Rb8 29. Qd2 Bd8 30. Ra1 Ra8 31. Rxa8 Nxa8 32. Bxa8 Qb8 33. Bg2 Qc8 34. Qxd6 Qc7 35. Qxc7 Bxc7 36. Kf1 Bb6 37. Ke2 g5 38. Kd3 h5 39. Ke4 Bc7 40. Kf5 Kg7 41. Ba3 Bd8 42. Bxc5 Be7 43. Bxe7 Kf7 44. Bxf6 h4 45. Bxg5 hxg3 46. fxg3 e4 47. Kxe4 Ke8 48. Bf4 Ke7 49. h4 Kd7 50. h5 Kc6 51. Bh3 1-0",
    B: "1420",
  },
  {
    W: "2239",
    T: "60+0",
    P: "1. c4 Nf6 2. g3 c6 3. Bg2 d6 4. Nc3 h6 5. e4 g6 6. Nge2 Bg7 7. O-O Qc7 8. d3 O-O 9. h3 a5 10. Be3 Na6 11. Rc1 Kh7 12. b3 Qd8 13. Qd2 Nb4 14. d4 e5 15. d5 cxd5 16. cxd5 Bd7 17. a3 Na6 18. f4 exf4 19. gxf4 Rc8 20. e5 Ne8 21. Bd4 Nc5 22. Rb1 b6 23. b4 axb4 24. axb4 Nb7 25. Rbc1 dxe5 26. fxe5 Qh4 27. Bf2 Qxb4 28. e6 fxe6 29. dxe6 Bc6 30. Bxc6 Rxc6 31. Ne4 Qxd2 32. Nxd2 Rxe6 33. Nf4 Rd6 34. Ne4 Rd7 35. Bxb6 Nf6 36. Nxf6+ Bxf6 37. Rc7 Rff7 38. Rxd7 Rxd7 39. Nh5 Bg7 40. Nxg7 Kxg7 41. Bf2 Kh7 42. Bg3 Nd8 43. Rf2 Nf7 44. Kg2 Ng5 45. Rd2 Rxd2+ 0-1",
    B: "2211",
  },
  {
    W: "2183",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d4 Nf6 5. Nf3 e6 6. Bc4 Be7 7. O-O O-O 8. h3 c6 9. Re1 b5 10. Bb3 a5 11. a3 a4 12. Ba2 Bb7 13. Bf4 Nbd7 14. Qd3 Rc8 15. Rad1 Re8 16. Ne5 Nf8 17. Ne4 Nxe4 18. Qxe4 Qb6 19. c3 c5 20. Qe3 cxd4 21. Qxd4 Qxd4 22. Rxd4 Bc5 23. Rd2 Ng6 24. Nxg6 hxg6 25. Bd6 Bb6 26. Bb4 Rcd8 27. Red1 Rxd2 28. Rxd2 Rd8 29. Rxd8+ Bxd8 30. c4 bxc4 31. Bxc4 Bd5 32. Bxd5 exd5 33. Kf1 f6 34. Ke2 Kf7 35. Kd3 Ke6 36. f3 f5 37. g3 g5 38. Bc3 g6 39. f4 g4 40. hxg4 fxg4 41. Be5 Bf6 42. Bd4 Be7 43. Be5 g5 44. Kd4 gxf4 45. Bxf4 Bf6+ 46. Kc5 Bxb2 47. Kb4 Be5 48. Kxa4 Bxf4 49. gxf4 g3 50. f5+ Kxf5 51. Kb5 g2 52. a4 g1=Q 53. a5 Qa7 54. a6 Ke4 0-1",
    B: "2020",
  },
  {
    W: "2346",
    T: "60+0",
    P: "1. b3 c5 2. Bb2 Nc6 3. e3 e5 4. Bb5 d6 5. d4 cxd4 6. exd4 Qa5+ 7. Nc3 Nf6 8. Nf3 Bg4 9. d5 Bxf3 10. Qxf3 a6 11. dxc6 axb5 12. cxb7 Rb8 13. Qc6+ Kd8 14. Qc8+ Rxc8 15. bxc8=Q+ Kxc8 16. O-O-O b4 17. Na4 Be7 18. Rd2 Kc7 19. Rhd1 Ra8 20. c3 bxc3 21. Bxc3 Qb5 22. f3 Rxa4 23. bxa4 Qxa4 24. Kb1 d5 25. Bxe5+ Kd7 26. Bxf6 Bxf6 27. Rxd5+ Ke6 28. Rd6+ Ke7 29. Rd7+ Qxd7 30. Rxd7+ Kxd7 31. a4 Kc6 32. Kc2 Kb6 33. Kd3 Ka5 34. Ke4 Kxa4 35. g4 Kb5 36. Kf5 Kc6 37. g5 Bc3 38. h4 g6+ 39. Kg4 Kd6 40. f4 Kd5 41. f5 Ke4 42. fxg6 fxg6 43. h5 Bd2 44. h6 Ke5 45. Kh4 Kf5 46. Kg3 Bxg5 47. Kf2 Bxh6 48. Kf3 g5 49. Kg3 g4 50. Kg2 Bg7 51. Kg1 h5 52. Kg2 h4 53. Kf1 Kf4 54. Kg1 h3 55. Kh1 g3 56. Kg1 1/2-1/2",
    B: "2261",
  },
  {
    W: "2000",
    T: "60+0",
    P: "1. e4 e5 2. Nc3 Nc6 3. Nf3 Nf6 4. h3 Bb4 5. Bc4 Bxc3 6. bxc3 Nxe4 7. Qe2 Nd6 8. Nxe5 Nxe5 9. Qxe5+ Qe7 10. Qxe7+ Kxe7 11. Bb3 Re8 12. O-O Kf8 13. d4 a5 14. a4 b5 15. axb5 Nxb5 16. Bf4 d6 17. Ba4 Bd7 18. Bxb5 Bxb5 19. Rfb1 Bc4 20. Ra4 d5 21. Rba1 Re2 22. Rxa5 Rxa5 23. Rxa5 Rxc2 24. Bxc7 Ke7 25. Ra7 Ke6 26. Ba5 g6 27. Bb4 Rc1+ 28. Kh2 h5 29. Kg3 Rd1 30. h4 f6 31. Kf4 Rd2 32. Re7# 1-0",
    B: "1987",
  },
  {
    W: "1535",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Bc5 5. Be3 d6 6. Bxc5 dxc5 7. Nc3 Bg4 8. Nd5 Nxd5 9. Bxd5 Qd7 10. c4 O-O 11. Nxe5 Nxe5 12. Qxg4 Nxg4 13. h3 Ne5 14. f3 c6 15. Bxc6 Qxc6 16. O-O-O Rfd8 17. Rhe1 Nxd3+ 18. Kc2 Nxe1+ 19. Rxe1 Qa4+ 20. Kb1 Rd1+ 21. Rxd1 Qxd1# 0-1",
    B: "1500",
  },
  {
    W: "2444",
    T: "60+0",
    P: "1. Nf3 c5 2. g3 e6 3. Bg2 Nf6 4. O-O Be7 5. d3 d5 6. c3 O-O 7. Bg5 Nc6 8. Bxf6 Bxf6 9. Qc2 e5 10. Nbd2 d4 11. c4 Nb4 12. Qb1 Bf5 13. a3 Nc6 14. Ne1 h6 15. Bd5 Rb8 16. Nef3 Ne7 17. b4 Nxd5 18. cxd5 Qxd5 19. bxc5 Qxc5 20. Rc1 Qd5 21. Qb3 Be6 22. Qxd5 Bxd5 23. Rc5 Bc6 24. Nxe5 Bxe5 25. Rxe5 Rfe8 26. Rxe8+ Rxe8 27. Re1 b5 28. f4 a5 29. Kf2 Rb8 30. Rb1 b4 31. axb4 axb4 32. Nc4 Bd5 33. Ne5 f6 34. Nd7 Rb7 35. Nc5 Rb5 36. Na6 b3 37. Nc7 Rc5 38. Nxd5 Rxd5 39. Rxb3 Kf7 40. Rb4 Kg6 41. g4 h5 42. h3 hxg4 43. hxg4 Kh6 44. Kf3 g5 45. Ke4 Rd8 46. f5 Re8+ 47. Kf3 Re3+ 48. Kf2 Rh3 49. Rxd4 Rh2+ 50. Kf3 Rh3+ 51. Ke4 Rh4 52. Kd5 Rh2 53. Ke6 Kg7 54. e4 Rg2 55. Rd7+ Kh6 56. Kxf6 Rxg4 57. Rd8 Kh7 58. e5 Rg1 59. e6 Re1 60. Rd4 g4 61. Rxg4 1-0",
    B: "2411",
  },
  {
    W: "2085",
    T: "60+0",
    P: "1. c4 Nf6 2. g3 e6 3. Bg2 d5 4. Nf3 c6 5. O-O Bc5 6. d3 Bb6 7. b3 a6 8. Bb2 Nbd7 9. Nbd2 O-O 10. e4 Re8 11. Qe2 Nf8 12. Rac1 dxe4 13. dxe4 Ba5 14. Rfd1 Qc7 15. a3 N6d7 16. b4 Bb6 17. c5 Ba7 18. Nc4 Rd8 19. Nd6 b6 20. Ne5 bxc5 21. Nxd7 Nxd7 22. Qg4 f6 23. Qxe6+ Kh8 24. Nf7+ Kg8 25. Nh6+ Kh8 26. Qg8+ Rxg8 27. Nf7# 1-0",
    B: "2172",
  },
  {
    W: "2189",
    T: "60+0",
    P: "1. Nf3 c5 2. g3 Nc6 3. Bg2 d5 4. O-O e6 5. d3 Nf6 6. Nc3 Be7 7. e4 d4 8. Ne2 O-O 9. h3 Nd7 10. Kh2 f6 11. Nfg1 e5 12. f4 Bd6 13. f5 Re8 14. g4 Nf8 15. Ng3 Bd7 16. h4 g6 17. Nh3 Qe7 18. g5 Qg7 19. fxg6 Nxg6 20. gxf6 Qf7 21. Ng5 Qf8 22. f7+ Kg7 23. fxe8=N+ Qxe8 24. h5 Nf4 25. h6+ Kxh6 26. Nf7+ Qxf7 27. Bxf4+ exf4 28. Nf5+ Bxf5 29. exf5 Qxf5 30. Qf3 Ne5 31. Qxb7 f3 32. Bxf3 Nxf3+ 33. Kg2 Qg4+ 34. Kf2 Qg3+ 35. Ke2 Re8+ 36. Kd1 Re1+ 0-1",
    B: "2386",
  },
  {
    W: "1304",
    T: "60+0",
    P: "1. d4 b5 2. Nf3 Bb7 3. Bf4 a5 4. Nbd2 Bxf3 5. Nxf3 d6 6. e3 c5 7. Bxb5+ Nd7 8. dxc5 dxc5 9. Qd5 Rb8 10. Bxb8 Qxb8 11. Qxd7# 1-0",
    B: "1227",
  },
  {
    W: "1996",
    T: "60+0",
    P: "1. e4 d6 2. Nc3 c6 3. Bc4 Nf6 4. Qf3 e6 5. Nge2 Be7 6. d3 O-O 7. O-O b6 8. h3 Nbd7 9. Qg3 Kh8 10. f4 d5 11. exd5 exd5 12. Bb3 a5 13. a4 Ba6 14. Bd2 Bd6 15. Qf3 Nc5 16. Ba2 Qd7 17. Ng3 Rae8 18. Nh5 Qf5 19. Nxf6 Qxf6 20. Rae1 d4 21. Ne4 Nxe4 22. Rxe4 Rxe4 23. Qxe4 c5 24. Re1 h6 25. g3 Rb8 26. Bd5 Bc8 27. Qf3 Bxh3 28. Kh2 Bf5 29. Be4 Bxe4 30. Qxe4 c4 31. dxc4 Bc5 32. b3 Rd8 33. Qe5 Qg6 34. Kg2 Qxc2 35. Re2 Qd3 36. Qe4 Qxe4+ 37. Rxe4 d3 38. Kf3 Bd4 39. Be3 Bf6 40. Bxb6 Rg8 0-1",
    B: "2145",
  },
  {
    W: "1984",
    T: "30+0",
    P: "1. Nf3 e6 2. d3 Nf6 3. Nbd2 Be7 4. c3 O-O 5. Qc2 d5 6. e4 c6 7. Be2 dxe4 8. dxe4 Nbd7 9. O-O Re8 10. Rd1 Nf8 11. a4 N6d7 12. b4 Qc7 13. Rb1 a5 14. h3 axb4 15. cxb4 c5 16. b5 b6 17. Nc4 Bb7 18. Ng5 Bxg5 19. Bxg5 Ng6 20. Bh4 Nxh4 21. g3 Ng6 22. Qd3 1-0",
    B: "2044",
  },
  {
    W: "1928",
    T: "180+0",
    P: "1. d4 g6 2. c4 Bg7 3. Nc3 d6 4. e4 e6 5. Nf3 Ne7 6. Bd3 O-O 7. h4 h5 8. Be3 Nd7 9. Qd2 Nf6 10. Bh6 e5 11. Bxg7 Kxg7 12. dxe5 dxe5 13. Nxe5 Ng4 14. Nxg4 Bxg4 15. f3 Be6 16. O-O-O Nc6 17. g4 hxg4 18. Rdg1 Nd4 19. fxg4 Nf3 20. Qe3 Nxg1 21. Rxg1 Qxh4 22. g5 Rad8 23. Nd5 Bxd5 24. cxd5 Rfe8 25. Qxa7 Qf2 26. Qxf2 Rd6 27. e5 Ra6 28. e6 f5 29. gxf6+ Kg8 30. Rxg6+ Kf8 31. e7+ Kf7 32. Bf5 Rc6+ 33. dxc6 Rxe7 34. Qe3 Rxe3 35. Rg7+ Kxf6 36. Bd7 Kxg7 0-1",
    B: "1897",
  },
  {
    W: "1632",
    T: "180+0",
    P: "1. e4 c5 2. d4 Nc6 3. dxc5 d6 4. cxd6 exd6 5. Bc4 Nf6 6. Bxf7+ Kxf7 7. Nf3 Bg4 8. O-O Ne5 9. Nbd2 Be7 10. Nxe5+ dxe5 11. f3 Bh5 12. Nc4 Qxd1 13. Rxd1 Rhd8 14. Rxd8 Rxd8 15. Nxe5+ Ke6 16. f4 Rd1+ 17. Kf2 Nxe4+ 18. Ke3 Bg6 19. Nxg6 Bc5+ 20. Kxe4 hxg6 21. g4 Re1+ 22. Kd3 Rh1 23. a4 Rxh2 24. Be3 Bxe3 25. Kxe3 Rh4 26. g5 Kf5 27. Rf1 Rh3+ 28. Rf3 Rh2 29. Kd3 Kg4 30. Re3 Rh3 31. Rxh3 Kxh3 32. Ke4 Kg3 33. b4 b6 34. c4 1-0",
    B: "1635",
  },
  {
    W: "1215",
    T: "180+0",
    P: "1. c4 d5 2. cxd5 Qxd5 3. Nc3 Qc6 4. d4 Bf5 5. e4 Bxe4 6. Nxe4 Qxe4+ 7. Be2 e6 8. f3 Qf5 9. Nh3 Qa5+ 10. Bd2 Bb4 11. Bxb4 Qxb4+ 12. Kf2 Nf6 13. d5 exd5 14. Qd3 O-O 1-0",
    B: "1236",
  },
  {
    W: "1596",
    T: "180+0",
    P: "1. e4 e6 2. Nf3 c6 3. d4 d5 4. e5 c5 5. c3 Nc6 6. Bd3 Bd7 7. O-O Qb6 8. Re1 cxd4 9. cxd4 Nxd4 10. Nxd4 Qxd4 11. Kh1 Qb6 12. f4 f6 13. Qe2 Bc5 14. Nc3 O-O-O 15. Bd2 fxe5 16. fxe5 Ne7 17. b4 Bd4 18. Rab1 Ng6 19. a4 Nxe5 20. Bc2 Ng6 21. a5 Qd6 22. b5 b6 23. axb6 axb6 24. Ra1 Kb7 25. Ra2 Ra8 26. Rxa8 Rxa8 27. Qd3 Bxc3 28. Bxc3 Nf4 29. Qg3 h6 30. Be5 Qb4 31. Qxf4 Qxe1+ 0-1",
    B: "1622",
  },
  {
    W: "1975",
    T: "180+0",
    P: "1. e4 d6 2. d4 Nd7 3. g3 e5 4. Ne2 Ngf6 5. Nbc3 Be7 6. Bg2 c6 7. O-O O-O 8. h3 Re8 9. Be3 Qc7 10. f4 b5 11. a3 a5 12. f5 Rb8 13. g4 b4 14. axb4 axb4 15. Na4 Ba6 16. Rf2 Bf8 17. Ng3 exd4 18. Bxd4 Ne5 19. g5 Nfd7 20. Qh5 c5 21. Be3 Bb7 22. f6 g6 23. Qe2 c4 24. h4 Bc6 25. h5 Ra8 26. b3 c3 27. hxg6 fxg6 28. Bd4 Ra5 29. Nh5 Rea8 30. Nf4 Bxa4 31. Rxa4 Rxa4 32. bxa4 Qc4 33. f7+ Kxf7 34. Nd5+ Kg8 35. Bxe5 Nxe5 36. Qe1 Rxa4 37. Bh3 Qa6 38. Be6+ Kg7 39. Rf7+ Nxf7 40. Bxf7 Ra1 41. Qxa1 Qxa1+ 42. Kf2 Qd1 43. Be6 Qd2+ 44. Kg3 Qxc2 45. Kg4 Qxe4+ 0-1",
    B: "2049",
  },
  {
    W: "1511",
    T: "180+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. c5 Nd5 5. d4 d6 6. cxd6 exd6 7. Bc4 Be6 8. Qf3 c6 9. Ne2 Be7 10. O-O O-O 11. Qg3 Nb6 12. Bxe6 fxe6 13. Bh6 Rf7 14. Nf4 Kh8 15. Nxe6 gxh6 16. Nxd8 Bxd8 17. exd6 Rg7 18. Qe5 N8d7 19. Qe4 Nd5 20. Nc3 N5f6 21. Qd3 Bb6 22. a4 Rag8 23. g3 h5 24. a5 Bd8 25. Ne4 Nxe4 26. Qxe4 Nf6 27. Qe5 Nd5 28. d7 Bf6 29. Qxh5 Rxd7 30. a6 bxa6 31. Rxa6 Rc7 32. Rc1 Ne7 33. Qa5 Bxd4 34. Raxc6 Rd7 35. Qa4 Bb6 36. Re6 Rc7 37. Qa1 Rb7 38. b4+ Rg7 39. Rxe7 Rxe7 40. Rc8+ 1-0",
    B: "1467",
  },
  {
    W: "1795",
    T: "180+0",
    P: "1. g3 Nf6 2. Bg2 e6 3. e4 d5 4. exd5 exd5 5. Nf3 Be6 6. O-O Be7 7. a3 Nbd7 8. b4 O-O 9. Bb2 c5 10. c3 Qc7 11. d4 c4 12. Bc1 Bd6 13. Nbd2 Bg4 14. Qc2 Rfe8 15. Bb2 Ne4 16. Nxe4 dxe4 17. Ne5 Nxe5 18. dxe5 Rxe5 19. Bxe4 Bh3 20. Rfe1 f5 21. Bf3 Rxe1+ 22. Rxe1 f4 23. g4 Rf8 24. Bd5+ Kh8 25. f3 h5 26. Qf5 Rxf5 27. gxf5 Bxf5 28. h4 Be7 29. Bf7 Bxh4 30. Re8+ Kh7 31. Bxh5 Qb6+ 32. Kh1 Qf2 33. Re5 Qf1+ 34. Kh2 Bg3# 0-1",
    B: "1805",
  },
  {
    W: "1512",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Bc5 3. Bc4 Bxf2+ 4. Kxf2 Qf6 5. Rf1 Nh6 6. Kg1 Qd6 7. Ng5 O-O 8. d3 Qg6 9. Qf3 d6 10. Nh3 Bg4 11. Qg3 Nc6 12. Bxh6 gxh6 13. Nf2 h5 14. Nxg4 hxg4 15. Qf2 Nd4 16. c3 Nf3+ 17. gxf3 gxf3+ 18. Qg3 Qxg3+ 19. hxg3 a6 20. Rxf3 b5 21. Bb3 a5 22. a3 a4 23. Bd5 Rac8 24. Nd2 c6 25. Ba2 Kh8 26. Raf1 Rc7 27. Rxf7 Rfxf7 28. Rxf7 Rxf7 29. Bxf7 Kg7 30. Ba2 c5 31. Nf3 h6 32. Nh4 Kf6 33. Nf5 h5 34. Nxd6 b4 35. axb4 cxb4 36. Nc4 b3 37. Bb1 Kg5 38. Nb6 Kg4 39. Kg2 h4 40. c4 hxg3 41. c5 a3 42. bxa3 b2 43. c6 Kf4 44. c7 Ke3 45. c8=Q Kd4 46. a4 Ke3 47. a5 Kd2 48. a6 Ke3 49. a7 Kd4 50. a8=Q Ke3 51. Qe6 Kd4 52. Qd8+ Ke3 53. Qxe5 Kd2 54. d4 Kc3 55. d5+ Kd2 56. d6 Ke3 57. d7 Kd2 58. Qc8 Ke3 59. d8=Q Ke2 60. Qef5 Ke3 61. e5 Ke2 62. e6 Ke3 63. e7 Ke2 64. e8=Q# 1-0",
    B: "1539",
  },
  {
    W: "2211",
    T: "180+0",
    P: "1. Nf3 d5 2. c4 c6 3. d4 Nf6 4. Nc3 a6 5. c5 Bf5 6. Bf4 e6 7. e3 h6 8. Bd3 Bxd3 9. Qxd3 Nbd7 10. O-O a5 11. Rad1 b6 12. cxb6 Qxb6 13. e4 Bb4 14. e5 Ng8 15. Na4 Qb7 16. Rc1 Rc8 17. Rc2 Bf8 18. Rfc1 Ne7 19. Qd2 Qb5 20. Nc5 a4 21. Bg3 Nf5 22. Nxd7 Kxd7 23. Bf4 Bb4 24. Qd3 Qb6 25. g4 Ne7 26. a3 Ng6 27. Be3 Be7 28. Qd2 Rc7 29. Ne1 Rhc8 30. Nd3 f6 31. f4 fxe5 32. fxe5 Nh4 33. Nc5+ Bxc5 34. dxc5 Qb3 35. Qf2 Re8 36. Rc3 Qb5 37. Qxh4 1-0",
    B: "2208",
  },
  {
    W: "1679",
    T: "180+0",
    P: "1. f4 e5 2. f5 Nc6 3. e4 d5 4. exd5 Qxd5 5. Nc3 Qd7 6. Qf3 Qxf5 7. Qxf5 Bxf5 8. d3 Nf6 9. Bg5 Be7 10. O-O-O O-O 11. h3 Be6 12. g4 Nd5 13. Bxe7 Ncxe7 14. Nge2 Nc6 15. Bg2 Nxc3 16. Nxc3 Rab8 17. Kb1 f6 18. Bf3 Nd4 19. Be4 c5 20. a3 Rfd8 21. Rhf1 a6 22. Na4 Rdc8 23. Nb6 Rc7 24. Nc4 Bxc4 25. dxc4 b5 26. cxb5 Rxb5 27. c3 Ne2 28. Rd8+ Kf7 29. Bd5+ Ke7 30. Rh8 Nxc3+ 31. Kc2 Nxd5 32. Rxh7 Ne3+ 33. Kd2 Nxf1+ 34. Ke2 Ng3+ 35. Kf3 Rb3+ 36. Kf2 Ne4+ 37. Ke2 Rxb2+ 38. Ke3 Ng5 39. Rxg7+ Kd6 40. h4 Rxg7 0-1",
    B: "1688",
  },
  {
    W: "1965",
    T: "180+0",
    P: "1. d4 Nf6 2. Bg5 e6 3. Bxf6 Qxf6 4. e3 d5 5. Nf3 c5 6. c3 Nc6 7. Bb5 a6 8. Qa4 Bd6 9. Bxc6+ bxc6 10. Qxc6+ Ke7 11. Qxa8 cxd4 12. cxd4 e5 13. Qa7+ Bd7 14. dxe5 Bxe5 15. Nxe5 Qxe5 16. Qc5+ Kf6 17. Nc3 Be6 18. O-O h5 19. Rad1 Rc8 20. Qd4 1-0",
    B: "1837",
  },
  {
    W: "1399",
    T: "180+0",
    P: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. Nc3 e6 5. d4 Be7 6. Bf4 b6 7. Bb5+ Bd7 8. O-O Nc6 9. Ne5 Nxe5 10. Bxe5 Nf6 11. Re1 Bxb5 12. Nxb5 a6 13. Nc7+ Kf8 14. Nxa8 Qxa8 15. c3 h5 16. Qb3 b5 17. a4 bxa4 18. Rxa4 Nd7 19. Qa2 Nxe5 20. Rxa6 Qe8 21. Rxe5 f6 22. Rexe6 Qg6 23. Ra8+ Kf7 24. Qxd5 Qb1+ 25. Re1+ 1-0",
    B: "1391",
  },
  {
    W: "2088",
    T: "180+0",
    P: "1. d4 g6 2. c4 Bg7 3. e4 d6 4. Nc3 Nf6 5. Bg5 O-O 6. f3 h6 7. Be3 e5 8. d5 c6 9. Qd2 Qa5 10. Bxh6 cxd5 11. cxd5 Bd7 12. a4 Na6 13. Bxa6 bxa6 14. Nge2 Rab8 15. Bxg7 Kxg7 16. O-O Rb3 17. Nc1 Qb6+ 18. Rf2 Rb4 19. Nd3 Rb3 20. a5 Qb7 21. Nc1 Rb4 22. Nd3 Rd4 23. Qc2 Rb8 24. b4 Bb5 25. Nxb5 Qxb5 26. Rd2 Nh5 27. g3 Rb7 28. Rc1 Nf6 29. Kf2 Nd7 30. Ke2 f5 31. exf5 gxf5 32. f4 e4 33. Qc3 exd3+ 34. Rxd3 Qxd5 35. Qxd4+ Qxd4 36. Rxd4 Nf6 37. Rc6 Ne4 38. Rxa6 Kf7 39. g4 Kf6 40. g5+ Ke6 41. h4 Rc7 42. Rxe4+ fxe4 43. h5 Kf5 0-1",
    B: "2077",
  },
  {
    W: "1259",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Bc5 3. Bc4 d5 4. exd5 Qd6 5. O-O Bg4 6. d3 Nf6 7. Nc3 Nbd7 8. Ne4 O-O-O 9. Nxd6+ Bxd6 10. Re1 Bc5 11. Be3 e4 12. dxe4 Nxe4 13. Bxc5 Ndxc5 14. Bd3 f5 15. Bxe4 fxe4 16. Qd4 b6 17. Qxg7 Bxf3 18. gxf3 exf3 19. Re3 Rhg8 20. Qxg8 Rxg8+ 21. Kf1 Na4 22. Rxf3 Nxb2 23. Rb1 Nc4 24. Rb4 Nd2+ 25. Ke2 Nxf3 26. Kxf3 Rf8+ 27. Ke2 Re8+ 28. Kf1 Rg8 29. h4 Kd7 30. f4 Kd6 31. Rd4 Rf8 32. h5 Kc5 33. c3 b5 34. a3 a5 35. Ke2 b4 36. axb4+ axb4 37. Kd3 Rf5 38. cxb4+ Kd6 39. b5 h6 40. Ke4 Rxh5 41. f5 Kc5 42. f6 Rh1 43. f7 Re1+ 44. Kd3 Rd1+ 45. Ke4 Re1+ 46. Kd3 Rd1+ 47. Kc3 Rc1+ 48. Kd3 Rd1+ 1/2-1/2",
    B: "1259",
  },
  {
    W: "2809",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 Nf6 3. c4 c6 4. Nc3 Bf5 5. cxd5 cxd5 6. Qb3 Qb6 7. Bg5 Qxb3 8. axb3 Nc6 9. Bxf6 gxf6 10. Nxd5 O-O-O 11. Ne3 Be4 12. Nd2 Bg6 13. d5 Nb4 14. Rc1+ Kb8 15. f4 f5 16. Rc4 e6 17. dxe6 fxe6 18. Nf3 b5 19. Rc1 Bh6 20. g3 Rhe8 21. Kf2 Bh5 22. Bg2 Bxf3 23. Bxf3 Nd5 24. Nxd5 exd5 25. Rhd1 d4 26. Rc5 d3 27. Rxd3 Rxd3 28. exd3 Rd8 29. Rxb5+ Kc7 30. Rd5 Rxd5 31. Bxd5 Bg7 32. Be6 Bxb2 33. Bxf5 Bd4+ 34. Kf3 Kd6 35. Bxh7 a5 36. Ke4 Bf6 37. Bg8 Ke7 38. Bc4 Bc3 39. Bb5 Bb4 40. Ba4 1-0",
    B: "2103",
  },
  {
    W: "1155",
    T: "900+15",
    P: "1. e4 d6 2. d4 Nf6 3. Nc3 Bg4 4. f3 Bh5 5. Bg5 h6 6. Bf4 Qd7 7. g4 Bg6 8. Qe2 Qc6 9. O-O-O e6 10. e5 Nd5 11. Nh3 Nxc3 12. bxc3 Qa4 13. exd6 Qa3+ 14. Kb1 cxd6 15. Qb5+ Nd7 16. d5 O-O-O 1-0",
    B: "1106",
  },
  {
    W: "1580",
    T: "900+15",
    P: "1. e4 c6 2. d4 d5 3. Nc3 g6 4. Nf3 Bg7 5. e5 Bf5 6. Bd3 e6 7. Bg5 Ne7 8. Bf6 Bxf6 9. exf6 Ng8 10. Bxf5 exf5 11. O-O Nxf6 12. Re1+ Ne4 13. Nxe4 fxe4 14. Nd2 O-O 15. f3 Qb6 16. Nb3 exf3 17. Qxf3 Nd7 18. c3 Qc7 19. Re7 Qd8 20. Rae1 f6 21. Qf4 g5 22. Qf5 Rf7 23. Rxf7 Kxf7 24. Qxh7+ Kf8 25. Qh6+ Kf7 26. Qh7+ Kf8 27. Nc5 Nxc5 28. dxc5 d4 29. Rd1 d3 30. Rxd3 Qe7 31. Qf5 Qe1+ 32. Qf1 Qe5 33. Qf2 Kg7 34. h3 Rh8 35. Rd7+ Kg6 36. Rxb7 g4 37. h4 g3 38. Qc2+ f5 39. Qd2 Rxh4 40. Qd6+ Qxd6 41. cxd6 Re4 42. Kf1 Rf4+ 43. Ke1 Re4+ 44. Kd1 f4 45. d7 f3 46. d8=Q f2 47. Qd6+ Kf5 48. Rf7+ Kg4 49. Qg6+ Kh4 50. Rh7# 1-0",
    B: "1583",
  },
  {
    W: "1050",
    T: "900+15",
    P: "1. e4 d5 2. d3 e6 3. Nf3 f5 4. Ne5 fxe4 5. Qh5+ g6 6. Qe2 Bb4+ 7. c3 Ba5 8. dxe4 d4 9. Qf3 Nc6 10. Qf7# 1-0",
    B: "1042",
  },
  {
    W: "1471",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. O-O Nf6 5. d3 d6 6. Be3 Be6 7. Bxe6 fxe6 8. h3 Be7 9. Nc3 d5 10. exd5 exd5 11. d4 exd4 12. Nxd4 Nxd4 13. Qxd4 c6 14. Rad1 O-O 15. Rfe1 Re8 16. a4 b6 17. b4 Qd6 18. b5 Qb4 19. bxc6 Qxd4 20. Bxd4 Rac8 21. Bxf6 Bxf6 22. Nxd5 Rxc6 23. Nxf6+ Rxf6 24. Rxe8+ Kf7 25. Red8 Rc6 26. R8d7+ Kg6 27. R1d2 a6 28. R7d6+ Rxd6 29. Rxd6+ 1-0",
    B: "1566",
  },
  {
    W: "1613",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 d6 3. d4 f6 4. dxe5 dxe5 5. Qxd8+ Kxd8 6. Bc4 c6 7. Bxg8 Rxg8 8. Bd2 Bg4 9. Nc3 Bxf3 10. gxf3 b5 11. O-O-O Nd7 12. f4 a5 13. fxe5 Nxe5 14. Bf4+ Kc7 15. b3 b4 16. Na4 g5 17. Bg3 h5 18. f4 h4 19. fxe5 hxg3 20. exf6 gxh2 21. Rxh2 Bd6 22. Rh7+ Kd8 23. Rxd6+ Ke8 24. f7+ Kf8 25. fxg8=Q+ Kxg8 26. Rc7 g4 27. Rg6+ Kf8 28. Rxg4 Re8 29. Rxc6 Kf7 30. Rc5 Kf6 31. Rxa5 Rh8 32. Kb2 Rh3 33. Rf4+ Ke6 34. Nc5+ Ke5 35. Nd3+ Kd4 36. Nxb4 Ke3 37. Raf5 Rh2 38. Nd5+ Kd4 39. a4 Kc5 40. Nc3+ Kd4 41. e5+ Kc5 42. e6+ Kd6 43. Re4 Rh6 44. e7 Rf6 45. Rxf6+ Kc7 46. e8=Q Kb7 47. Qc6+ Ka7 48. Re7+ Kb8 49. Rf8# 1-0",
    B: "1591",
  },
  {
    W: "1783",
    T: "900+15",
    P: "1. e4 e5 2. Bc4 Nf6 3. Nc3 Bc5 4. Nf3 Nc6 5. O-O O-O 6. d3 Bb6 7. h3 d6 8. Re1 Be6 9. Bxe6 fxe6 10. Bg5 Qd7 11. a4 a6 12. Be3 Nd4 13. b4 Qc6 14. Bd2 Nxf3+ 15. gxf3 Nh5 16. Kg2 Nf4+ 17. Bxf4 Rxf4 18. Ne2 Rf6 19. Ng3 Raf8 20. b5 axb5 21. axb5 Qc5 22. Rf1 Rxf3 23. Qxf3 Rxf3 24. Kxf3 Qxc2 25. Ra8+ Kf7 26. Ra3 Bc5 27. Ra7 0-1",
    B: "1832",
  },
  {
    W: "1878",
    T: "900+7",
    P: "1. d4 Nf6 2. Nf3 g6 3. g3 Bg7 4. Bg2 O-O 5. Nbd2 c5 6. c3 cxd4 7. cxd4 Nc6 8. a3 Rb8 9. b4 d6 10. Bb2 e6 11. O-O Bd7 12. Qb3 d5 13. Ne1 Qb6 14. e3 Rbc8 15. Nd3 Rc7 16. Nc5 Rfc8 17. Rac1 Qb5 18. Rc2 Bh6 19. Rfc1 Qe2 20. Nde4 Nxd4 21. Nxf6+ 1-0",
    B: "1728",
  },
  {
    W: "1874",
    T: "120+1",
    P: "1. d4 e6 2. c4 f5 3. Nc3 Nf6 4. Bg5 Be7 5. e4 fxe4 6. Bxf6 Bxf6 7. Nxe4 O-O 8. Nf3 b6 9. Nxf6+ Qxf6 10. Bd3 Bb7 11. Be2 Nc6 12. O-O Ne7 13. Qd3 Nf5 14. Ne5 Rad8 15. Bf3 Bxf3 16. Nxf3 Nh4 17. Nxh4 Qxh4 18. g3 Qh3 19. f4 Rf7 20. Rae1 Rdf8 21. d5 Rxf4 22. Rxf4 Rxf4 23. dxe6 Rf8 24. exd7 Qf5 25. Re8 Qf2+ 26. Kh1 1-0",
    B: "1845",
  },
  {
    W: "2102",
    T: "120+1",
    P: "1. e4 d5 2. exd5 Nf6 3. Nc3 Nxd5 4. Bc4 e6 5. Qf3 c6 6. Nh3 Be7 7. O-O O-O 8. d4 Nd7 9. Nf4 N7b6 10. Bd3 g6 11. Nfxd5 Nxd5 12. Bh6 Re8 13. Ne4 Bf8 14. Bxf8 Rxf8 15. c4 f5 16. cxd5 fxe4 17. Qxe4 exd5 18. Qe5 Qf6 19. Rae1 Bf5 20. Bxf5 Qxf5 21. Qxf5 Rxf5 22. Re7 Rf7 23. Rfe1 Raf8 24. f3 Rxe7 25. Rxe7 Rf7 26. Re8+ Kg7 27. Kf2 Kf6 28. f4 b6 29. Kf3 c5 30. dxc5 bxc5 31. g4 Rc7 32. g5+ Kf7 33. Re5 d4 34. Rd5 Ke6 35. Rd8 Rd7 36. Rxd7 Kxd7 37. Ke4 Kd6 38. b4 cxb4 39. Kxd4 a5 40. h4 Kc6 41. Kc4 Kb6 42. f5 gxf5 43. h5 f4 44. g6 f3 45. Kd3 a4 46. gxh7 1-0",
    B: "1959",
  },
  {
    W: "2276",
    T: "30+0",
    P: "1. g3 d5 2. Bg2 h5 3. Nf3 h4 4. O-O h3 5. Bh1 c6 6. d4 Nf6 7. c4 Nbd7 8. cxd5 cxd5 9. Nc3 e6 10. Bf4 Nb6 11. Nb5 Bd6 12. Nxd6+ Kf8 13. Nxc8 Rxc8 14. Rc1 Nc4 15. b3 Nd6 16. Rxc8 Nxc8 17. Qc2 Ne7 18. e3 Ne4 19. Nd2 Nf6 20. e4 dxe4 21. Nxe4 Qxd4 22. Nxf6 Qxf6 23. Qe2 g5 24. Be5 Qh6 25. f4 Rg8 26. fxg5 Qxg5 27. Bf6 Qc5+ 28. Rf2 Qc1+ 29. Rf1 Qc7 30. Qf2 Ng6 31. Bg5 Qd7 32. Be4 Ne5 33. Bh6+ Ke8 34. Qf6 Ng4 35. Qg5 Qd4+ 36. Qe3 Nf2 0-1",
    B: "2413",
  },
  {
    W: "1998",
    T: "180+0",
    P: "1. d4 d5 2. e3 e6 3. Bd3 f5 4. Nd2 Nf6 5. f4 c5 6. c3 c4 7. Bc2 Nc6 8. Ngf3 Bd7 9. Ne5 Qc7 10. O-O Bd6 11. Rf3 Bxe5 12. fxe5 Ng4 13. Rh3 O-O-O 14. b3 Na5 15. b4 Nc6 16. a4 Ne7 17. Ba3 Ng6 18. b5 Rdf8 19. Bd6 Qd8 20. Rb1 Qg5 21. Bxf8 Rxf8 22. Nf3 Qxe3+ 0-1",
    B: "1876",
  },
  {
    W: "1692",
    T: "60+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. d4 e6 4. Be2 Ne7 5. Nc3 O-O 6. Bf4 a6 7. Bg3 b5 8. O-O d5 9. e5 Re8 10. Re1 f6 11. h3 fxe5 12. dxe5 Nbc6 13. Bf1 Nf5 14. Bh2 Nce7 15. Rb1 c5 16. a3 d4 17. Na2 c4 18. c3 d3 19. Qd2 Bb7 20. Nd4 Nxd4 21. cxd4 Qxd4 22. Qe3 Qxe3 23. Rxe3 Nf5 24. Ree1 Red8 25. Rbd1 Rd7 26. Rd2 Rc8 27. Nc3 Nd4 28. Kh1 Ne2 29. Bxe2 dxe2 30. Rdxe2 Rd3 31. Nb1 Rcd8 32. Bf4 Rb3 33. Nd2 Rxb2 34. Nf3 Rxe2 35. Rxe2 Rd1+ 36. Kh2 1-0",
    B: "1594",
  },
  {
    W: "1257",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Nxe4 4. Qe2 Qe7 5. d3 Qxe5 6. Qxe4 Qxe4+ 7. dxe4 c5 8. Nc3 Nc6 9. Be3 Nd4 10. Bxd4 cxd4 11. Nb5 g6 12. Nc7+ Ke7 13. Nxa8 Bg7 14. Bc4 Rd8 15. Nc7 b6 16. O-O Bb7 17. Nd5+ Bxd5 18. Bxd5 Kd6 19. Rad1 Re8 20. Bxf7 Rxe4 21. Rfe1 Rxe1+ 22. Rxe1 b5 23. Bg8 Kc5 24. Bxh7 a5 25. Bxg6 b4 26. Be4 a4 27. b3 a3 28. Bf5 Kb5 29. Bxd7+ Kc5 30. Re7 Be5 31. Rxe5+ Kd6 32. Rh5 Kxd7 33. g4 Ke6 34. f4 Kf6 35. g5+ Kf5 36. Rh4 1-0",
    B: "1295",
  },
  {
    W: "2371",
    T: "60+0",
    P: "1. e4 c5 2. g3 d6 3. Bg2 Nc6 4. Ne2 g6 5. c3 Bg7 6. d4 cxd4 7. cxd4 e6 8. Nbc3 Nge7 9. Be3 d5 10. e5 O-O 11. Qd2 Nf5 12. O-O Nxe3 13. fxe3 b6 14. h4 Ba6 15. Rf2 Bxe2 16. Nxe2 Na5 17. b3 Rc8 18. Kh2 Qc7 19. Rd1 Qc2 20. Ng1 Qxd2 21. Rdxd2 Rc3 22. Rfe2 Bh6 23. Nh3 Bxe3 24. Rb2 Bxd4 25. b4 Nc4 26. Rb1 Bxe5 27. Rbe1 Bxg3+ 28. Kg1 Bxe1 29. Rxe1 Rc8 30. Ng5 Nd6 31. Rf1 f5 32. Nxe6 Rc1 33. Nf4 Rxf1+ 34. Bxf1 Rc1 35. Nxd5 Ne4 36. Ne3 Nd2 37. Kf2 Nxf1 38. Nxf1 Rc2+ 0-1",
    B: "2305",
  },
  {
    W: "1394",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 e6 3. Nc3 d5 4. d4 dxe4 5. Nxe4 cxd4 6. Qxd4 Nf6 7. Qxd8+ Kxd8 8. Nxf6 gxf6 9. Be2 Bg7 10. Be3 Ke7 11. O-O-O Rd8 12. Rxd8 Kxd8 13. Bd2 Ke7 14. Bb4+ Kd7 15. Rd1+ Ke8 16. Bb5+ Bd7 17. Bxd7+ Nxd7 18. Ba5 b6 19. Bc3 e5 20. Bd2 Nc5 21. Bb4 Ne4 22. Rf1 a5 23. Bd2 Nxd2 24. Nxd2 Rd8 25. Ne4 f5 26. Ng5 Bh6 27. h4 Bxg5+ 28. hxg5 f6 29. g3 fxg5 30. f4 exf4 31. gxf4 gxf4 32. Rxf4 Rd7 33. Rxf5 Rf7 34. Rf1 Rxf1+ 35. Kd2 Rf2+ 36. Kd3 Rf8 37. Kd2 h5 38. c3 h4 39. Ke2 h3 40. Ke3 h2 41. Kd3 h1=Q 42. Kd4 Qd1+ 43. Kc4 Qb1 44. Kb3 Qh1 45. a3 b5 46. Ka2 a4 47. b4 axb3+ 48. Kxb3 Qh8 49. Kb4 Qe5 50. Kb3 Qe4 51. Ka2 Qc4+ 52. Kb2 Rf2+ 0-1",
    B: "1530",
  },
  {
    W: "1349",
    T: "60+0",
    P: "1. e4 c6 2. Nf3 d5 3. d4 dxe4 4. Ng5 f6 5. Nxe4 f5 6. Ned2 Nf6 7. Nb3 Be6 8. Nc3 Bxb3 9. axb3 Nbd7 10. Be3 e5 11. dxe5 Nxe5 12. Qxd8+ Kxd8 13. Be2 Bb4 14. O-O Neg4 15. Bg5 h6 16. Bxf6+ gxf6 17. h3 Ne5 18. g4 fxg4 19. hxg4 Rg8 20. Ra4 Bxc3 21. bxc3 f5 22. Rd4+ Kc7 23. Kh1 fxg4 24. Re4 g3 25. Rxe5 Rae8 26. Rxe8 Rxe8 27. fxg3 Rxe2 28. Rf7+ Kd6 29. Rxb7 Rxc2 30. c4 Rc1+ 31. Kg2 Rc2+ 32. Kh3 Rc3 33. Rxa7 1-0",
    B: "1107",
  },
  {
    W: "2252",
    T: "60+0",
    P: "1. Nc3 d5 2. d4 Nf6 3. e4 e6 4. f3 dxe4 5. Bc4 c5 6. Bd5 exd5 0-1",
    B: "2297",
  },
  {
    W: "2051",
    T: "60+0",
    P: "1. d4 e6 2. c4 c5 3. Nc3 cxd4 4. Qxd4 Nc6 5. Qd1 Nf6 6. Nf3 Bb4 7. e3 d5 8. Bd2 d4 9. exd4 Nxd4 10. Nxd4 Qxd4 11. a3 Qe5+ 12. Be2 Ba5 13. O-O Bd7 14. Bf3 Bc6 15. Bxc6+ bxc6 16. Re1 Qc7 17. Qc1 c5 18. Bf4 Qb7 19. Nb5 O-O 20. Qe3 a6 21. Nd6 Qc6 22. Red1 Rad8 23. b3 Bb6 24. Rd2 Bc7 25. Rad1 Ng4 26. Qf3 e5 27. Qxg4 exf4 28. Nf5 Qf6 29. Rxd8 Rxd8 30. Rxd8+ Bxd8 31. h4 Qa1+ 32. Kh2 f3 33. Qxf3 Bc7+ 34. g3 Qf6 35. Kg2 g6 0-1",
    B: "2087",
  },
  {
    W: "1946",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bc4 e6 4. d4 cxd4 5. Nxd4 d5 6. exd5 exd5 7. Bb5 Bd7 8. Bxc6 bxc6 9. Qe2+ Be7 10. O-O c5 11. Re1 cxd4 12. Bg5 f6 13. Bf4 g5 14. Bg3 h5 15. h3 h4 16. Bh2 Nh6 17. c3 Nf5 18. Nd2 O-O 19. g4 hxg3 20. fxg3 dxc3 21. bxc3 Bc5+ 22. Kg2 Bc6 23. Nf3 d4 24. cxd4 Qxd4 25. Bg1 Qd6 26. Bxc5 Qxg3+ 27. Kf1 Bxf3 28. Qf2 Qxh3+ 29. Kg1 Qg3+ 30. Qxg3 Nxg3 31. Bxf8 Rxf8 32. Re3 Ne2+ 33. Rxe2 Bxe2 34. Re1 Bc4 35. Kf2 Bxa2 36. Ra1 Bc4 37. Rxa7 f5 38. Kg3 Re8 39. Ra5 Re3+ 40. Kf2 Re2+ 41. Kg3 g4 42. Rxf5 Re3+ 43. Kxg4 Be6 44. Kg5 Bxf5 45. Kxf5 1/2-1/2",
    B: "2008",
  },
  {
    W: "2161",
    T: "60+0",
    P: "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. Nf3 cxd4 5. Nxd4 Nc6 6. Nb3 d6 7. Bd3 Nf6 8. Be3 O-O 9. f3 a6 10. Qd2 b5 11. h4 Bb7 12. h5 Nxh5 13. Rxh5 gxh5 14. O-O-O Nb4 15. Bh6 Nxd3+ 16. cxd3 b4 17. Qg5 bxc3 18. Qxg7# 1-0",
    B: "2232",
  },
  {
    W: "1864",
    T: "60+0",
    P: "1. c3 e5 2. d4 exd4 3. cxd4 Nc6 4. e3 Nf6 5. a3 d5 6. b4 a6 7. f3 b5 8. Bd3 Bb7 9. Nd2 Bd6 10. Nb3 O-O 11. Nc5 Qc8 12. Bd2 Re8 13. Bc3 Bxc5 14. bxc5 Rxe3+ 15. Kf2 Qe6 16. Ne2 Re8 17. Re1 Na5 18. Nf4 Qe7 19. Bxa5 g5 20. Ne2 Nh5 21. Bd2 Rxd3 22. Qc2 Qe6 23. Qxd3 Re7 24. Rad1 Qf6 25. Ng3 Qg7 26. Nxh5 Qh6 27. Ng3 Qh5 28. Nxh5 Re6 29. Ng3 h6 30. Nf5 Rf6 31. Nxh6+ 1-0",
    B: "1871",
  },
  {
    W: "2468",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. c3 Nf6 4. Bb5+ Nbd7 5. e5 dxe5 6. Nxe5 e6 7. O-O a6 8. Nxd7 Nxd7 9. Be2 Bd6 10. d4 O-O 11. Nd2 Qc7 12. Nf3 b6 13. Be3 Bb7 14. h3 Rfd8 15. Qb3 Rac8 16. Rfd1 Be4 17. Bxa6 c4 18. Qb5 Ra8 19. Qxc4 Qa7 20. Bb5 Bxf3 21. gxf3 Nf6 22. Bg5 Rdc8 23. Qe2 Nd5 24. Bd3 Be7 25. Be3 Qc7 26. Be4 Ra5 27. Kh1 Nf4 28. Qf1 Rh5 29. Bxf4 Qxf4 30. Qg2 Bd6 31. Rg1 Rg5 32. Qg3 Rxg3 33. fxg3 Qh6 34. Kh2 f5 35. Bc2 g5 36. Bb3 g4 37. fxg4 fxg4 38. Kg2 Qxh3+ 39. Kf2 Rf8+ 40. Ke3 Bxg3 41. Bxe6+ Kg7 42. Ke4 Re8 43. d5 Rxe6+ 44. dxe6 Bf2 45. Rgf1 Qe3+ 46. Kd5 g3 47. Kd6 Kf6 48. Rh1 Qxe6+ 49. Kc7 Qe5+ 50. Kc6 h5 51. a4 g2 52. Rhd1 g1=Q 53. Rxg1 Bxg1 54. Rxg1 Qe4+ 55. Kb5 Qd5+ 56. Ka6 Qa5+ 57. Kb7 Qxa4 58. Re1 b5 59. Kb6 b4 60. Re4 Qc2 61. Rxb4 h4 62. Rxh4 Qxb2+ 63. Kc5 Qxc3+ 64. Rc4 Qe3+ 65. Kb4 Ke5 66. Rc5+ Kd6 67. Rc3 Qd2 68. Kc4 Qd5+ 69. Kb4 Qa2 70. Rd3+ Ke5 71. Ra3 Qb2+ 72. Rb3 Qd2+ 73. Kc4 Ke4 74. Rc3 Qd5+ 75. Kb4 Kd4 76. Rc2 Qb7+ 77. Ka3 Qb5 78. Rc1 Kd3 79. Ka2 Qb4 80. Rb1 Qa4+ 81. Kb2 Kd2 82. Ra1 Qb4+ 83. Ka2 Kc2 84. Rc1+ Kxc1 0-1",
    B: "2489",
  },
  {
    W: "1901",
    T: "60+0",
    P: "1. e4 b6 2. d4 Bb7 3. Nc3 e6 4. Bd3 Nf6 5. Nge2 d5 6. e5 Nfd7 7. O-O c5 8. Ng3 g6 9. f4 Nc6 10. f5 gxf5 11. Qh5 cxd4 12. Bg5 Be7 13. Bh6 Qc7 14. Nb5 Qb8 15. Nxf5 Ncxe5 16. Ng7+ Kd8 0-1",
    B: "2318",
  },
  {
    W: "1280",
    T: "60+0",
    P: "1. e4 d6 2. Nf3 h6 3. d4 g5 4. e5 Bg7 5. h3 g4 6. hxg4 Bxg4 7. Be2 dxe5 8. Nxe5 Bxe2 9. Qxe2 Bxe5 10. Qxe5 Nf6 11. Bf4 Nc6 12. Qxc7 Qxd4 13. Be3 Qxb2 14. Qxb7 Qxb7 15. Bc5 Ne4 16. Kf1 Nxc5 17. Nc3 Nd4 18. Ne2 Nxe2 19. Kxe2 Qe4+ 20. Kd2 Qf4+ 21. Kd1 Rd8+ 22. Ke1 Qe4+ 23. Kf1 Rd2 24. Re1 Qc4+ 25. Kg1 Qxc2 26. Rf1 Ne4 27. Kh2 Qc7+ 0-1",
    B: "1475",
  },
  {
    W: "2285",
    T: "60+0",
    P: "1. d4 d5 2. c3 Nf6 3. Nf3 Nc6 4. Bf4 Bg4 5. e3 e5 6. dxe5 Ne4 7. Be2 Qe7 8. Qa4 O-O-O 9. Nd4 Bxe2 10. Nxe2 Nc5 11. Qb5 a6 12. Qb3 Nxb3 13. axb3 Qh4 14. b4 g5 15. Bg3 Qe4 16. Nd2 Qc2 17. Nf3 Qxb2 18. Rd1 Bxb4 19. cxb4 Qxb4+ 20. Nd2 d4 21. e4 d3 22. Nc1 Nxe5 23. O-O Nc4 24. Nxc4 Qxc4 25. Rd2 Qxe4 26. Rfd1 Rhe8 27. h3 Qe1+ 28. Kh2 Qxd2 0-1",
    B: "1962",
  },
  {
    W: "1748",
    T: "60+0",
    P: "1. e3 d5 2. g3 e6 3. Bg2 Nf6 4. h3 Bd6 5. b3 O-O 6. Bb2 h6 7. Qe2 c5 8. Nc3 Nc6 9. O-O-O d4 10. exd4 Nxd4 11. Qf1 e5 12. d3 a5 13. f4 exf4 14. gxf4 b5 15. Nf3 b4 16. Nxd4 bxc3 17. Bxc3 cxd4 18. Bxd4 a4 19. Bxa8 axb3 20. Bxf6 Qxf6 21. axb3 Ba3+ 22. Kd2 Bb4+ 23. Ke2 Re8+ 24. Kf3 Bc5 25. Be4 Bf5 26. d4 Bxe4+ 27. Kg3 Bxd4 28. Qg2 Qg6+ 29. Kh2 Qxg2# 0-1",
    B: "1754",
  },
  {
    W: "2284",
    T: "60+0",
    P: "1. d3 c6 2. Nf3 d5 3. Nbd2 Bf5 4. e4 dxe4 5. dxe4 Bg6 6. Bd3 h6 7. Qe2 Nd7 8. c3 Nc5 9. Bc2 e6 10. h3 Ne7 11. b4 Na6 12. Nf1 Nc7 13. Be3 Nb5 14. Rd1 Qc7 15. g4 Nxc3 16. Qc4 Nxd1 17. Bxd1 Rd8 18. Be2 Nc8 19. Ng3 Nb6 20. Qb3 Qd6 21. h4 Qxb4+ 22. Qxb4 Bxb4+ 23. Kf1 Na4 24. h5 Bh7 25. Bd1 Rxd1+ 26. Kg2 Rxh1 27. Nxh1 Bxe4 28. Kg3 Bxf3 29. Kxf3 Nc3 30. Ng3 Nxa2 31. Bxa7 O-O 32. Ne4 Nc3 33. Nd2 Nd5 34. Nc4 b5 35. Nb2 Bc3 36. Nd3 Ra8 37. Bc5 Ra3 38. Ke2 Ra2+ 39. Kf3 b4 40. Nxb4 Nxb4 41. Be7 Nd3 42. g5 c5 43. gxh6 gxh6 44. Bf6 Kh7 45. Bxc3 Ra3 46. Be5 1-0",
    B: "1861",
  },
  {
    W: "1506",
    T: "600+0",
    P: "1. d4 Nf6 2. c4 e6 3. e3 d5 4. Nc3 Be7 5. Bd3 h6 6. h3 O-O 7. Nge2 c5 8. Qc2 Bd7 9. O-O Qb6 10. b3 Nc6 11. Na4 Qc7 12. dxc5 Nb4 13. Qb1 Nxd3 14. Qxd3 Bxc5 15. Nxc5 Qxc5 16. Bb2 Ne4 17. Rac1 a5 18. f3 Nd6 19. cxd5 Qxd5 20. Qxd5 exd5 21. Rc5 Be6 22. Nd4 b6 23. Rc6 Nf5 24. Nxe6 fxe6 25. Rxe6 b5 26. e4 dxe4 27. fxe4 Ng3 28. Rxf8+ Rxf8 29. Kh2 Nf1+ 30. Kg1 Ng3 31. Rg6 Nxe4 32. Bxg7 Rf7 33. Bxh6+ Kh7 34. Rb6 Nc3 35. Bd2 b4 36. Bxc3 bxc3 37. Rc6 1-0",
    B: "1581",
  },
  {
    W: "1480",
    T: "600+0",
    P: "1. a3 d5 2. b4 g6 3. Bb2 Nf6 4. d3 e6 5. e3 c5 6. b5 a6 7. c4 axb5 8. cxb5 Bd7 9. d4 c4 10. a4 Bb4+ 11. Bc3 Bf8 12. Nf3 Ne4 13. Be2 Bb4 14. Bxb4 b6 15. O-O f5 16. Nfd2 Nxd2 17. Nxd2 Kf7 18. Qc2 Be8 19. Ra3 Nd7 20. Rfa1 Nf6 21. h3 Bd7 22. Bc3 Qc7 23. f4 h6 24. Qb2 g5 25. g3 g4 26. h4 Nh5 27. Kg2 Nf6 28. a5 bxa5 29. Bxa5 Qb7 30. b6 Ke7 31. Bb4+ Kf7 32. Rxa8 Rxa8 33. Rxa8 Qxa8 34. Bc5 Qb7 35. Nb1 Bc6 36. Nc3 Nd7 37. Nb5 Bxb5 38. Qxb5 Nxc5 39. Qxc5 Kf6 40. Qc7 Qa6 41. b7 Qa7 42. Qd8+ Kg6 43. b8=Q Qa2 44. Qg8+ Kf6 45. Qbf8# 1-0",
    B: "1351",
  },
  {
    W: "1629",
    T: "600+0",
    P: "1. d4 d5 2. c4 c6 3. cxd5 cxd5 4. Nc3 e6 5. Nf3 f6 6. g3 Nc6 7. Bg2 Bd6 8. O-O Nge7 9. Bd2 Bd7 10. Nb5 Bb8 11. a4 a6 12. Nc3 Bd6 13. e4 Qc7 14. exd5 exd5 15. Re1 Nb4 16. Nh4 Nd3 17. Re2 Nxb2 18. Qc2 Nc4 19. Nxd5 Qc8 20. Nf5 Bxf5 21. Nxe7 Bxe7 22. Qb3 Nxd2 23. Rxd2 Qe6 24. d5 Qd6 25. Re1 Kf8 26. Rde2 Re8 27. Qxb7 Bc8 28. Qa7 Kf7 29. Rxe7+ Rxe7 30. Rxe7+ Qxe7 31. Qxe7+ Kxe7 32. Be4 g6 33. f3 Kd6 34. g4 Bb7 35. f4 Bxd5 36. Bxd5 Kxd5 0-1",
    B: "1594",
  },
  {
    W: "1779",
    T: "600+0",
    P: "1. e4 e6 2. Bc4 d5 3. exd5 exd5 4. Bb3 Nf6 5. d3 Bc5 6. Bg5 c6 7. Nf3 O-O 8. O-O Bg4 9. h3 Bh5 10. Nbd2 Nbd7 11. Re1 Qb6 12. Re2 Rae8 13. Qf1 Rxe2 14. Qxe2 Re8 15. Qf1 h6 16. Bxf6 Nxf6 17. Re1 Kh8 18. Rxe8+ Nxe8 19. Qe1 Nf6 20. Ne5 Qc7 21. Ndf3 Bd6 22. Ng4 Nxg4 23. hxg4 Bg6 24. g3 Kh7 25. Nh4 Qd7 26. Nxg6 Kxg6 27. Qe2 Qe6 28. Qxe6+ fxe6 29. Kg2 Kg5 30. Kf3 b5 31. d4 e5 32. dxe5 Bxe5 33. c3 d4 34. cxd4 Bxd4 35. Be6 Bxb2 36. Bd7 b4 37. Bxc6 a5 38. Bd5 Kf6 39. Kg2 Ke5 40. Bb3 Kd4 41. f4 Ke3 42. f5 Ke2 43. Kh3 Kf3 44. Kh4 Bf6+ 45. Kh3 Be5 46. Bd1+ Kf2 47. g5 hxg5 48. Kg4 Bxg3 49. Kxg5 Be5 50. Kg6 Ke3 51. Bb3 Kd4 52. Kf7 Kc3 53. Ke6 Bf6 54. Kd5 Kb2 55. Kc5 Kc3 56. Kb5 Bd8 57. Ka4 Kd4 58. Kb5 Ke5 59. Be6 Kf6 60. Kc6 a4 61. Kb5 b3 62. axb3 a3 63. b4 Be7 64. Ka4 a2 65. Bxa2 Kxf5 66. b5 g5 67. b6 g4 68. b7 Bd6 69. Bd5 Ke5 70. Bh1 g3 71. Kb5 Kf4 72. Kb6 1/2-1/2",
    B: "1758",
  },
  {
    W: "1403",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Nxc6 bxc6 6. Be3 Bxe3 7. fxe3 Rb8 8. b3 Qh4+ 9. g3 Qxe4 10. Qe2 Qxh1 11. Nc3 Nf6 12. O-O-O Ne4 13. Bh3 Nxc3 14. Rxh1 Nxe2+ 15. Kd2 Ba6 16. Bf1 0-1",
    B: "1461",
  },
  {
    W: "1963",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 d6 3. Bb5+ Bd7 4. Bxd7+ Nxd7 5. O-O Ngf6 6. Re1 e6 7. h3 Be7 8. c3 O-O 9. d4 cxd4 10. cxd4 a6 11. Nc3 b5 12. a3 Nb6 13. Bg5 Rc8 14. e5 dxe5 15. dxe5 Qxd1 16. Raxd1 Nfd5 17. Nxd5 Nxd5 18. Rxd5 Bxg5 19. Rd6 Be7 20. Rxa6 Rc2 21. b3 Ra2 22. a4 bxa4 23. bxa4 Bc5 24. Rf1 Rb8 25. Nh2 g6 26. Ng4 h5 27. Nf6+ Kg7 28. Nd7 Rc8 29. Nxc5 Rxc5 30. Ra8 Rxe5 31. g3 g5 32. Kg2 Ree2 33. a5 Re5 34. a6 Rea5 35. a7 Rxa7 36. Rxa7 Rxa7 37. h4 g4 38. f4 Ra2+ 39. Kg1 Kf6 40. Re1 Ke7 41. Re5 f5 42. Re1 Kd6 43. Kf1 Kd5 44. Kg1 Ra4 45. Kg2 Re4 46. Rd1+ Kc4 47. Rd6 Kc5 48. Rd8 Kc4 49. Rh8 Kd3 50. Rxh5 Re2+ 51. Kf1 Ke3 52. Rh8 Kf3 53. h5 Rh2 54. Kg1 Kxg3 55. h6 Rh3 56. h7 Kxf4 57. Kg2 e5 58. Kf2 e4 59. Kg2 Rh6 60. Kf2 g3+ 61. Kg2 Rh2+ 62. Kg1 e3 63. Kf1 e2+ 64. Ke1 Kf3 65. Rf8 Rh1+ 66. Kd2 e1=Q+ 67. Kd3 Qe4+ 68. Kc3 Rxh7 0-1",
    B: "1969",
  },
  {
    W: "1260",
    T: "600+0",
    P: "1. e4 e6 2. c4 Qh4 3. Qf3 Qf6 4. Qc3 Bc5 5. Nf3 b6 6. e5 Qe7 7. d4 Bb4 0-1",
    B: "1327",
  },
  {
    W: "1888",
    T: "600+0",
    P: "1. e4 e6 2. Qe2 b6 3. Nf3 Bb7 4. g3 Nf6 5. e5 Ng4 6. h3 Nh6 7. d4 Bb4+ 8. c3 Be7 9. Bxh6 gxh6 10. Qe3 c5 11. Qxh6 cxd4 12. Bg2 dxc3 13. Nxc3 Bb4 14. Qg7 Rf8 15. O-O Bxc3 16. bxc3 h5 17. Ng5 Bxg2 18. Kxg2 Qc7 19. Nh7 Qc6+ 20. Kg1 Qc5 21. Nxf8 Qxf8 22. Qxf8+ Kxf8 23. Rad1 Ke7 24. f4 Nc6 25. Rd6 Rc8 26. Rfd1 Rc7 27. g4 hxg4 28. hxg4 Na5 29. f5 exf5 30. gxf5 Nc4 31. f6+ Ke8 32. Kf2 Nxd6 33. exd6 Rxc3 34. Rh1 Rc2+ 35. Ke3 1-0",
    B: "1938",
  },
  {
    W: "2115",
    T: "600+0",
    P: "1. c4 c6 2. d4 d5 3. Nc3 Nf6 4. Bg5 e6 5. Qc2 Be7 6. Nf3 h6 7. Bxf6 Bxf6 8. e3 dxc4 9. Bxc4 b5 10. Bd3 Bb7 11. O-O Nd7 12. a4 a6 13. Ne4 Be7 14. a5 O-O 15. Rfd1 Rc8 16. Nc5 Nxc5 17. dxc5 Qc7 18. b4 Rcd8 19. Rd2 Rd7 20. Rad1 Rfd8 21. Ne5 Rd5 22. Bh7+ Kf8 23. Rxd5 exd5 24. Qf5 Bf6 25. Ng4 Bc3 26. Rb1 Bc8 27. Qh5 Qd7 28. h3 f5 29. Nh2 Qf7 30. Bg6 Qf6 31. Nf3 Kg8 32. Nh4 f4 33. exf4 Qxf4 34. Nf3 Rf8 35. Rb3 d4 36. Bd3 Be6 37. Qg6 Bxb3 38. Qh7+ Kf7 39. Bg6+ Ke7 40. Qxg7+ Bf7 41. Ne5 Qc1+ 42. Kh2 d3 43. Nxc6+ Ke8 44. Bxf7+ Rxf7 45. Qg8+ Kd7 46. Qxf7+ Kxc6 47. Qe6+ Kc7 48. Qd6+ Kc8 49. c6 Be5+ 50. Qxe5 Qxc6 51. Qf5+ Qd7 52. Qc5+ Qc7+ 0-1",
    B: "2113",
  },
  {
    W: "1585",
    T: "60+0",
    P: "1. d4 e5 2. Nf3 e4 3. Nfd2 d5 4. Nc3 Nf6 5. f3 Bb4 6. fxe4 Bxc3 7. bxc3 Nxe4 8. Nxe4 dxe4 9. e3 O-O 10. Be2 Qf6 11. Rf1 Qg6 12. g3 Qh6 13. Rh1 Nc6 14. Bb2 Na5 15. Qd2 Nc6 16. d5 Ne7 17. c4 Bf5 18. Qd4 Ng6 19. g4 Bd7 20. g5 Qh4+ 21. Kd2 Qf2 22. Qxg7# 1-0",
    B: "1479",
  },
  {
    W: "1394",
    T: "240+0",
    P: "1. e4 c5 2. Bc4 d6 3. Nf3 Nf6 4. d3 g6 5. Nc3 Bg7 6. Bg5 O-O 7. d4 cxd4 8. Qxd4 Nc6 9. Qd2 a6 10. Bd3 b5 11. h4 Bb7 12. Nd5 Nxd5 13. exd5 Ne5 14. Nxe5 Bxe5 15. c3 e6 16. Rh2 Qc8 17. g3 Bxd5 18. h5 f6 19. Be3 Rf7 20. hxg6 hxg6 21. Bxg6 Rg7 22. Rh6 Qd7 23. O-O-O Bf3 24. Rg1 f5 25. g4 Qe7 26. gxf5 exf5 27. Bg5 Qe6 28. Bh7+ Rxh7 29. Rxe6 Rc8 30. Bf6+ Kf7 31. Rxe5 Kxf6 32. Re3 Rd8 33. Rxf3 Re7 34. Qh6+ Kf7 35. Rg7+ Ke8 36. Rg8+ Kd7 37. Rxd8+ Kxd8 38. Rxf5 1-0",
    B: "1471",
  },
  {
    W: "1485",
    T: "180+2",
    P: "1. d4 Nf6 2. Bf4 g6 3. e3 d6 4. Nf3 c5 5. Bd3 cxd4 6. Nxd4 Bg7 7. Nd2 e5 8. Bxe5 dxe5 9. Nb5 a6 0-1",
    B: "1427",
  },
  {
    W: "1542",
    T: "180+2",
    P: "1. d4 c5 2. c4 cxd4 3. Nf3 Nc6 4. Nxd4 g6 5. Nc3 Bg7 6. Nxc6 bxc6 7. Bf4 d6 8. e3 Rb8 9. Qc2 Nf6 10. g3 O-O 11. Bg2 Bb7 12. O-O c5 13. e4 Nd7 14. Rad1 Ne5 15. Nd5 Bxd5 16. Rxd5 Qb6 17. b3 Qb4 18. Bg5 Rb7 19. f4 Ng4 20. f5 Ne5 21. fxg6 fxg6 22. Rxf8+ Bxf8 23. Bh3 Kg7 24. Be6 h6 25. Bf4 g5 26. Bxe5+ dxe5 27. Qf2 Rb8 28. Qf7+ Kh8 29. Bf5 Qe1+ 30. Kg2 Bg7 31. Qxe7 Qe2+ 32. Kh3 Qf1+ 33. Kg4 Qe2+ 34. Kh3 Qf1+ 35. Kg4 Qe2+ 36. Kh3 Qf1+ 37. Kg4 Qe2+ 1/2-1/2",
    B: "1532",
  },
  {
    W: "2402",
    T: "180+2",
    P: "1. e4 d6 2. d4 Nf6 3. Nc3 c6 4. Nf3 Qc7 5. Bd3 e5 6. O-O Be7 7. a4 O-O 8. a5 Na6 9. Be3 Nb4 10. h3 Nxd3 11. cxd3 Rd8 12. Qe2 Bd7 13. Rfc1 Qb8 14. dxe5 dxe5 15. d4 exd4 16. Bxd4 Be6 17. Be5 Qc8 18. Nb5 Ne8 19. Nfd4 a6 20. Na3 f6 21. Nxe6 Qxe6 22. Bg3 Rd4 23. f3 Bxa3 24. Rxa3 Rad8 25. Rcc3 Nd6 26. Rd3 Nb5 27. Rxd4 Rxd4 28. Ra1 Qd7 29. Bf2 Rd2 30. Qc4+ Kh8 31. b4 h5 32. h4 Kh7 33. Rc1 Rb2 34. e5 Qd2 35. Rf1 Rxb4 36. Qe6 Qf4 37. exf6 Qxf6 38. Qd7 Nd4 39. Bxd4 Qxd4+ 40. Qxd4 Rxd4 41. Rb1 Rd7 42. Kf2 Kg6 43. Ke3 Kf5 44. Rc1 Rd5 45. Rb1 Rb5 46. Rg1 Rxa5 47. g4+ hxg4 48. fxg4+ Kg6 49. Kf4 Ra4+ 50. Ke5 a5 51. h5+ Kg5 0-1",
    B: "2399",
  },
  {
    W: "1627",
    T: "180+2",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 c6 5. Bd2 Qc7 6. Nf3 Nf6 7. Bc4 Bg4 8. O-O e6 9. h3 Bh5 10. g4 Bg6 11. g5 Nh5 12. Ne5 Bd6 13. Nxg6 hxg6 14. Qf3 Bf4 15. Bxf4 Nxf4 16. Rae1 Nxh3+ 17. Qxh3 Rxh3 0-1",
    B: "1655",
  },
  {
    W: "1576",
    T: "180+2",
    P: "1. d4 d5 2. Bf4 Nf6 3. e3 Nc6 4. Nc3 Bg4 5. f3 Bf5 6. a3 e6 7. Nb5 Rc8 8. Bd3 Bg6 9. Ne2 Be7 10. O-O O-O 11. c3 a6 12. Nxc7 Rxc7 13. Bxc7 Qxc7 14. b4 Bd6 15. e4 Bxh2+ 16. Kh1 dxe4 17. fxe4 Ng4 18. e5 Ne3 19. Qd2 Bxd3 20. Qxe3 Bxe2 21. Qxe2 Bg3 22. Qg4 Bxe5 23. dxe5 Qxe5 24. Qf3 Qg5 25. Rae1 Qh6+ 26. Kg1 Qg5 27. Rxe6 Nd8 28. Re5 Qxe5 29. Kf2 Ne6 30. Qe3 Qxe3+ 31. Kxe3 Nc7 32. Rd1 Nb5 33. c4 Nxa3 34. c5 Nc4+ 35. Kd4 Nb2 36. Rb1 Na4 37. Kd5 Rd8+ 38. Kc4 Rd2 39. g4 h6 40. Rg1 Kh7 41. Kb3 Nb2 42. g5 hxg5 43. Rxg5 1-0",
    B: "1544",
  },
  {
    W: "1255",
    T: "180+2",
    P: "1. c4 c5 2. g3 d5 3. cxd5 Qxd5 4. Nf3 g5 5. Bg2 g4 6. Nh4 Qd6 7. O-O f5 8. Nxf5 Qf6 9. Qc2 Bxf5 10. Bxb7 Bxc2 11. Bxa8 Nc6 12. Nc3 Bh6 13. e3 Bd3 14. Re1 Qf3 15. e4 Bxe4 16. Nxe4 Nf6 17. Bxc6+ Kf7 18. Nd6+ exd6 19. Bxf3 Bxd2 20. Bxd2 Nd5 21. Bxd5+ Kf6 22. Re6+ Kf5 23. Bc3 Re8 24. Rxe8 1-0",
    B: "1312",
  },
  {
    W: "1140",
    T: "180+2",
    P: "1. g3 e5 2. Bg2 c6 3. c4 d5 4. Nc3 dxc4 5. b3 Bf5 6. bxc4 Na6 7. e4 Bg6 8. Nf3 Qd3 0-1",
    B: "834",
  },
  {
    W: "1720",
    T: "180+2",
    P: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. Bd3 Nf6 5. Nf3 Bg4 6. Nbd2 Nc6 7. c3 e5 8. dxe5 Nxe5 9. Bb5+ Nfd7 10. Qe2 Qe7 11. Bxd7+ Nxd7 12. Qxe7+ Bxe7 13. O-O O-O 14. h3 Bh5 15. Nd4 Bc5 16. N4b3 Bb6 17. a4 a6 18. a5 Ba7 19. g4 Bg6 20. Nf3 Rfe8 21. Nfd4 Bd3 22. Rd1 Bc4 23. Bf4 Re4 24. Be3 Bxb3 25. Nxb3 Bxe3 26. fxe3 Rxe3 27. Rxd5 Nf6 28. Kf2 Nxd5 29. Rd1 Rxh3 30. Rxd5 Rh2+ 31. Kf3 Rxb2 32. Nc5 Rc8 33. Rd7 Rb5 34. Nxb7 Rxc3+ 35. Kg2 h6 36. Rd8+ Kh7 37. Kf2 Rb2+ 38. Ke1 Rc1+ 39. Rd1 Rxd1+ 40. Kxd1 Rxb7 0-1",
    B: "1702",
  },
  {
    W: "1858",
    T: "180+2",
    P: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 Ne7 5. a3 Bxc3+ 6. bxc3 c5 7. Qg4 Qc7 8. Qxg7 Rg8 9. Qxh7 cxd4 10. Ne2 Nbc6 11. cxd4 Nxd4 12. Nxd4 Qc3+ 13. Ke2 Qxa1 14. Be3 b6 15. g3 Ba6+ 0-1",
    B: "2035",
  },
  {
    W: "1698",
    T: "300+5",
    P: "1. e4 e5 2. Bc4 h6 3. d3 a6 4. Nf3 Nc6 5. c3 Bc5 6. O-O d6 7. a4 Bg4 8. a5 Qf6 9. Nbd2 Nge7 10. Qb3 Rb8 11. Qa2 O-O 12. b4 Ba7 13. b5 axb5 14. Bxb5 Nd8 15. a6 b6 16. Bc4 Ndc6 17. Bb2 Na5 18. Bd5 c6 19. Bb3 Ra8 20. d4 exd4 21. Nxd4 c5 22. Nb5 Bb8 23. f3 Bh5 24. Nc4 Rxa6 25. Nxa5 Rxa5 26. Qb1 Rxa1 27. Qxa1 Qf4 28. c4 Qe3+ 29. Kh1 Ng6 30. Ba2 Nf4 31. Bxg7 Re8 32. Bxh6 Kh7 33. Qg7# 1-0",
    B: "1400",
  },
  {
    W: "1700",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. O-O Nf6 5. Nxe5 Nxe5 6. f4 Nxc4 7. e5 Nh7 8. d4 Nb6 9. Nc3 d6 10. exd6 Qxd6 11. Re1+ Be7 12. Ne4 Qd5 13. Nc5 Be6 14. Nxb7 Qxb7 15. Qh5 O-O 16. Be3 Nf6 17. Qf3 Qxf3 18. gxf3 Bd5 19. Rf1 Nh5 20. Kg2 Bd6 21. Rae1 Bxf4 22. Bxf4 Nxf4+ 23. Kg3 Ng6 24. f4 Rfe8 25. Rd1 Re3+ 26. Kf2 Rh3 27. Kg1 Nh4 28. Rf2 Nf3+ 29. Kf1 Nxh2+ 30. Kg1 Ng4 31. Rh2 Rxh2 32. c4 Bxc4 33. Rc1 Bd5 34. Rxc7 Re8 35. Kf1 Re3 36. Rxa7 Rg3 37. Ke1 Rgg2 38. Ra8+ Kh7 39. Rh8+ Kxh8 40. Kf1 Rh1# 0-1",
    B: "1624",
  },
  {
    W: "1968",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 h6 5. Be3 Nf6 6. Bxc5 d6 7. Ba3 Bg4 8. Nc3 O-O 9. h3 Bxf3 10. Qxf3 Nd4 11. Qd1 c6 12. Ne2 b5 13. Nxd4 exd4 14. Bb3 c5 15. c3 dxc3 16. bxc3 b4 17. cxb4 cxb4 18. Bxb4 Rb8 19. Qd2 Qb6 20. Bc3 d5 21. exd5 Nd7 22. O-O Ne5 23. Bxe5 Rbe8 24. Qc3 f6 25. d6+ Kh8 26. d7 Rd8 27. Bc7 Rxd7 28. Bxb6 axb6 29. Qb4 Rdf7 30. Qxb6 Kh7 31. Bxf7 Rxf7 32. a4 Rb7 33. a5 Rxb6 34. axb6 f5 35. b7 f4 36. b8=Q f3 37. Qg3 fxg2 38. Qxg2 g5 39. Qe4+ Kg7 40. Ra7+ Kf6 41. Ra6+ Kf7 42. Qb7+ Ke8 43. Ra8# 1-0",
    B: "1908",
  },
  {
    W: "1492",
    T: "60+0",
    P: "1. e3 e5 2. g3 Nf6 3. Bg2 Nc6 4. Ne2 d5 5. O-O Be6 6. b3 Bd6 7. d4 Qd7 8. dxe5 Bxe5 9. Nbc3 Bxc3 10. Nxc3 Bh3 11. Bb2 Bxg2 12. Kxg2 h5 13. Kg1 h4 14. e4 hxg3 15. exd5 gxh2+ 16. Kh1 Ne5 17. Re1 Qd6 18. f4 Ng4 19. fxe5 Nf2+ 20. Kg2 Qg6+ 21. Kxf2 Rh3 22. e6 Qg3+ 23. Ke2 Qe3+ 24. Kf1 Rf3+ 25. Kg2 Qf2+ 26. Kh1 Rg3 27. exf7+ Kd7 28. Rf1 Qg2# 0-1",
    B: "1498",
  },
  {
    W: "1882",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 d6 4. d5 Nb8 5. Nc3 f5 6. Be3 fxe4 7. Nxe4 Nf6 8. Nxf6+ gxf6 9. Qd2 Qe7 10. O-O-O Bg7 11. h4 Nd7 12. h5 Nb6 13. h6 Bf8 14. Nh4 f5 15. f4 e4 16. Bd4 Rg8 17. Be2 Bd7 18. Bh5+ Kd8 19. Qc3 Bxh6 20. Bf6 Bxf4+ 21. Kb1 Be5 22. Bxe7+ Kxe7 23. Qxc7 Rg5 24. Be2 Rc8 25. Qxb7 Bg3 26. Qxa7 Bf2 27. Qxb6 Bxb6 28. Ng6+ hxg6 29. Rh7+ Kd8 30. Rh8+ Be8 31. Rxe8+ Kxe8 32. Bb5+ Ke7 33. Rh1 Rxg2 34. Rh7+ Kf6 35. Rf7+ Ke5 36. Re7+ Kxd5 37. Re5+ dxe5 38. c4+ 1-0",
    B: "1888",
  },
  {
    W: "1871",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 a6 5. Nc3 e6 6. Be3 Nf6 7. f3 Be7 8. Qd2 Qc7 9. O-O-O Nc6 10. g4 b5 11. g5 Nd7 12. h4 Bb7 13. h5 Rd8 14. Kb1 Nxd4 15. Bxd4 Ne5 16. Bg2 Nc4 17. Qe2 O-O 18. g6 h6 19. gxf7+ Rxf7 20. Rdg1 Bf6 21. Bxf6 Rxf6 22. Bh3 Rdf8 23. Qg2 Kh8 24. Ne2 Qa5 25. Nd4 R8f7 26. Bxe6 Qb4 27. c3 Nxb2 28. cxb4 Nd3 29. Bxf7 Rxf7 30. Ne6 Nxb4 31. Nxg7 1-0",
    B: "1809",
  },
  {
    W: "1572",
    T: "60+0",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. cxd5 Bb4 5. e4 Bxc3+ 6. bxc3 exd5 7. e5 Ne4 8. Bd3 Nxc3 9. Qc2 f6 10. Bxh7 Rxh7 11. Qxh7 Kf7 12. Qh5+ Ke6 13. Qh3+ f5 14. Qxc3 c6 15. Nf3 Nd7 16. Bg5 g6 17. Bxd8 1-0",
    B: "1534",
  },
  {
    W: "1440",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Qf6 3. d3 Bc5 4. Nc3 Nc6 5. Be3 Bxe3 6. fxe3 d6 7. Nd5 Qd8 8. h4 Nge7 9. Ng5 Nxd5 10. exd5 Qf6 11. d4 Ne7 12. dxe5 dxe5 13. e4 O-O 14. Bc4 Ng6 15. d6 cxd6 16. h5 Nf4 17. Qg4 Bxg4 18. O-O Qxg5 19. Rad1 Bh3 20. Rf2 Nxg2 21. Rdd2 Ne3+ 22. Rg2 Nxg2 23. Bf1 Ne3+ 24. Kh1 Bxf1 25. Rh2 Qf4 0-1",
    B: "1456",
  },
  {
    W: "1773",
    T: "60+0",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. f3 d6 5. e4 O-O 6. Be3 a6 7. Qd2 c5 8. d5 Ne8 9. Bh6 e5 10. Bxg7 Nxg7 11. O-O-O b5 12. g4 bxc4 13. h4 Nd7 14. h5 Qb6 15. hxg6 Rb8 16. Rh2 Kh8 17. Rxh7+ Kg8 18. Rh2 fxg6 19. Qh6 Qxb2+ 20. Rxb2 1-0",
    B: "1734",
  },
  {
    W: "1423",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. Nf3 Nf6 5. Bc4 Bg4 6. h3 Bh5 7. d3 e6 8. O-O Nc6 9. Qe2 Nd4 10. Qe3 Nxf3+ 11. gxf3 b6 12. Ne4 Nxe4 13. Qxe4 Bc5 14. Bb5+ Kf8 15. Bc6 Rb8 16. Qe5 Bd6 17. Qxh5 g6 18. Qh6+ Ke7 19. b3 Qf8 20. Qg5+ f6 21. Qg4 h5 22. Qg3 h4 23. Qg2 Rh5 24. Bb2 Rg5 25. Bxf6+ Kxf6 26. Qxg5+ Kxg5 27. Kh1 Qf4 28. Rg1+ Kf5 29. Rae1 Qxf3+ 30. Bxf3 1-0",
    B: "1396",
  },
  {
    W: "2130",
    T: "60+0",
    P: "1. g3 d5 2. Bg2 e6 3. c4 Nf6 4. cxd5 Nxd5 5. Nc3 c6 6. Qb3 Bd6 7. Nf3 O-O 8. O-O Nd7 9. d4 N7f6 10. e4 Nxc3 11. bxc3 Nxe4 12. Re1 Nf6 13. a4 Bd7 14. a5 b6 15. axb6 Qxb6 16. Qc4 Rfc8 17. Bg5 Be7 18. Ne5 Be8 19. Reb1 Qc7 20. Bf4 Qd8 21. Rb7 c5 22. Raxa7 cxd4 23. Qxd4 Qxd4 24. cxd4 Rxa7 25. Rxa7 1-0",
    B: "1971",
  },
  {
    W: "1037",
    T: "60+0",
    P: "1. e4 c5 2. Bc4 Nc6 3. Qf3 Nf6 4. e5 Nxe5 5. b3 Nxf3+ 6. gxf3 e6 7. Nh3 d5 8. Ng5 dxc4 9. bxc4 e5 10. Ba3 Bd6 11. f4 Bf5 12. fxe5 Bxe5 13. Bxc5 Bxa1 14. Nc3 Bxc3 15. dxc3 Bxc2 16. O-O Ne4 17. Nxe4 Bxe4 18. Re1 Qg5+ 19. Kf1 Qxc5 20. f3 Qxc4+ 21. Kf2 Qxc3 22. Rxe4+ Kd7 23. Rg4 Rhe8 24. f4 Re3 25. Kg2 Rf3 26. Rg3 Rxg3+ 27. Kh1 Rd3 28. Kg2 Rd2+ 0-1",
    B: "1257",
  },
  {
    W: "1588",
    T: "300+0",
    P: "1. e4 e6 2. e5 c6 3. Nf3 f5 4. exf6 Nxf6 5. d4 Bd6 6. Bg5 Qc7 7. Bxf6 gxf6 8. Nh4 h5 9. Ng6 Rh7 10. Be2 Bxh2 11. Bxh5 d5 12. Qd3 Rxh5 13. g3 Qa5+ 14. Nc3 Qb6 15. Nf4 Rh8 16. Qg6+ Kf8 17. Qxf6+ Kg8 18. Ng6 Kh7 19. Rxh2+ Kg8 20. Rxh8# 1-0",
    B: "1503",
  },
  {
    W: "1356",
    T: "300+0",
    P: "1. e4 e5 2. Bc4 Nc6 3. c3 Nf6 4. Nf3 Bc5 5. Ng5 O-O 6. Qb3 Na5 7. Bxf7+ Rxf7 8. Qxf7+ Kh8 9. Qh5 Nxh5 10. Nf7+ Kg8 11. Nxd8 d6 12. d4 exd4 13. b4 Bxb4 14. cxb4 Nc4 15. O-O Bd7 16. Nxb7 Nf4 17. Na5 Re8 18. Bxf4 1-0",
    B: "1362",
  },
  {
    W: "1409",
    T: "300+0",
    P: "1. d4 d5 2. Bf4 c6 3. e3 Nf6 4. Bd3 Bg4 5. Nf3 Nbd7 6. h3 Bxf3 7. Qxf3 e6 8. c3 Be7 9. Nd2 O-O 10. O-O a5 11. a4 Nb8 12. Rfe1 Re8 13. Nf1 Bd6 14. Bxd6 Qxd6 15. Ng3 Na6 16. Bxa6 Rxa6 17. h4 e5 18. dxe5 Qxe5 19. h5 h6 20. Rac1 Rea8 21. b3 b5 22. axb5 cxb5 23. Kf1 a4 24. b4 a3 25. Re2 a2 26. Ra1 Qxc3 27. Ree1 Rc8 28. Red1 Qc1 29. Rdxc1 Rxc1+ 30. Rxc1 a1=Q 31. Rxa1 Rxa1+ 32. Ke2 Ra2+ 33. Kd3 Ra3+ 34. Kd4 Ra4 35. Kc5 Ra8 36. Kxb5 Rb8+ 37. Kc5 Rc8+ 38. Kd4 Rc4+ 39. Kd3 Rxb4 40. Nf5 Rb3+ 41. Ke2 Rb2+ 42. Kd3 Rb3+ 43. Kd4 Rb4+ 44. Kc5 Rb8 45. Nd6 Ne4+ 46. Kxd5 Nxd6 47. Kxd6 Rd8+ 48. Ke5 Re8+ 49. Kf4 Ra8 50. Kg3 Ra5 51. Kh3 Ra4 52. g4 Ra2 53. Kg2 Ra4 54. Qg3 Ra3 55. f4 Ra2+ 56. Kf3 Ra3 57. g5 Ra6 58. gxh6 Rxh6 59. Qh3 Re6 60. e4 f6 61. f5 Re8 62. Kf4 Rc8 63. Qa3 Kf7 64. Qa7+ Ke8 65. Qxg7 Rd8 66. Qg6+ Ke7 67. Qxf6+ Kxf6 68. h6 Rh8 69. e5+ Kf7 70. Kg5 Rh7 71. f6 Rh8 72. e6+ Kxe6 73. Kg6 Rg8+ 0-1",
    B: "1411",
  },
  {
    W: "1279",
    T: "300+0",
    P: "1. e4 e5 2. d4 f5 3. exf5 exd4 4. Qxd4 d5 5. Bd3 Nf6 6. Nf3 Nc6 7. Qe3+ Qe7 8. Qxe7+ Nxe7 9. Nh4 g6 10. fxg6 hxg6 11. Nf3 Bg4 12. Ne5 Bf5 13. Bxf5 gxf5 14. Bg5 Ng4 15. Nxg4 fxg4 16. Bf6 Rh6 17. Bg5 Rh5 18. Bf4 c6 19. Nd2 Bg7 20. O-O-O Rf5 21. Bg3 Ng6 22. Rde1+ Kd7 23. f3 gxf3 24. gxf3 Bh6 25. Re2 Rxf3 26. Kb1 Bxd2 27. Rxd2 Re8 28. Rg1 Ne5 29. Bxe5 Rxe5 30. Rg7+ Kc8 31. b3 Rf1+ 32. Kb2 Rh5 33. Rdg2 Rhf5 34. Re2 Rf8 35. h4 R1f5 36. Rh7 Rf2 37. Re5 R2f5 38. Re6 R8f7 39. Rxf7 Rxf7 40. h5 Rh7 41. h6 Kd7 42. Rg6 Kc7 43. Rg7+ Kb6 44. Rxh7 1-0",
    B: "1254",
  },
  {
    W: "1378",
    T: "300+0",
    P: "1. e4 c6 2. Nf3 d5 3. e5 Bg4 4. d4 e6 5. Bd3 a6 6. O-O h6 7. h3 Bh5 8. Be3 Nd7 9. Nbd2 Ne7 10. c3 Nf5 11. Re1 Be7 12. Qc2 Rc8 13. Bxf5 exf5 14. Qxf5 Bg6 15. Qg4 O-O 16. Bf4 Qb6 17. Qxd7 Rc7 18. Qg4 Qxb2 19. e6 Qxc3 20. Bxc7 fxe6 21. Qxe6+ Bf7 22. Qxe7 Re8 23. Qc5 Rxe1+ 24. Rxe1 Qd3 25. Nb3 Qg6 26. Bg3 Kh7 27. Qe7 b5 28. Nc5 a5 29. Nb7 a4 30. Nc5 a3 31. Ne5 Bg8 32. Nxg6 1-0",
    B: "1382",
  },
  {
    W: "1251",
    T: "300+0",
    P: "1. e3 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qd1 Bc5 5. b3 d6 6. Bb2 Be6 7. Bxg7 Bb4+ 8. c3 Bc5 9. Bxh8 Nge7 10. Bf6 Qd7 11. Bb5 O-O-O 12. Bxc6 bxc6 13. f3 Bd5 14. e4 Qe6 15. Bxe7 Qxe7 16. c4 Be6 17. Ne2 f6 18. Nbc3 d5 19. Qc2 dxc4 20. Rb1 Be3 21. bxc4 Bd2+ 22. Kf2 Bxc4 23. Qb2 Qc5+ 24. Kg3 Qe5+ 25. Kf2 Qf4 26. Nxf4 Bxf4 27. Qb8+ Kd7 28. Rhd1+ Ke7 29. Rxd8 Bxa2 30. Re8+ 1-0",
    B: "1279",
  },
  {
    W: "1667",
    T: "300+0",
    P: "1. e4 e5 2. a3 Qf6 3. Nf3 Bc5 4. Nc3 c6 5. Bc4 h6 6. d3 d6 7. O-O g5 8. Be3 Bg4 9. Bxc5 dxc5 10. h3 Bd7 11. Ne2 g4 12. hxg4 Bxg4 13. Nh2 h5 14. Nxg4 hxg4 15. Ng3 Qh4 16. f4 Qxg3 17. fxe5 Qh2+ 18. Kf2 Qxe5 19. Qxg4 Nd7 20. Ke2 Qxb2 21. Bxf7+ Kd8 22. Bxg8 Qxc2+ 23. Ke3 Ne5 24. Rf8+ Kc7 25. Qg7+ Nd7 26. Rxa8 Rh2 27. Qg3+ Kb6 28. Qxh2 Ne5 29. Qxe5 Qxg2 30. Rb1+ Ka6 31. Bc4+ b5 32. Qxc5 bxc4 33. Rxa7# 1-0",
    B: "1642",
  },
  {
    W: "1863",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Bg5 Bg7 7. Bc4 Ne5 8. Bb3 a5 9. a4 O-O 10. O-O d6 11. Qd2 Re8 12. Bh6 Bh8 13. f4 Neg4 14. Bg5 h6 15. Bh4 e5 16. fxe5 dxe5 17. Nf3 Qxd2 18. Nxd2 g5 19. Bg3 Ne3 20. Rfe1 Rd8 21. Nf3 g4 22. Nxe5 Nh5 23. Nxf7 Rd2 24. Nxh8+ Kxh8 25. Rxe3 Nxg3 26. hxg3 1-0",
    B: "1912",
  },
  {
    W: "1703",
    T: "300+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Qf3 Qd8 4. d3 Nf6 5. h3 Nc6 6. Ne2 Qd5 7. Qxd5 Nxd5 8. Nf4 Nxf4 9. Bxf4 Nd4 10. Na3 e6 11. Be3 Bb4+ 12. c3 Bxa3 13. Bxd4 Bxb2 14. Rb1 Ba3 15. Be2 O-O 16. O-O Be7 17. Bf3 Rb8 18. Bxa7 Ra8 19. Be3 Rxa2 20. Bxb7 Bxb7 21. Rxb7 c5 22. Rxe7 1-0",
    B: "1739",
  },
  {
    W: "1771",
    T: "300+0",
    P: "1. e4 e5 2. d4 exd4 3. Nf3 c5 4. b4 b6 5. a3 a5 6. bxc5 bxc5 7. Bb2 Qb6 8. Qc1 Nc6 9. Bc4 Ba6 10. Ba2 c4 11. c3 d3 12. Nbd2 Nf6 13. Bxc4 Bc5 14. Bxa6 Qxa6 15. O-O O-O 16. e5 Ng4 17. Ne4 Ba7 18. h3 Ngxe5 19. Nxe5 Nxe5 20. Qe3 Bxe3 0-1",
    B: "1742",
  },
  {
    W: "1825",
    T: "300+0",
    P: "1. e4 c5 2. Nc3 e6 3. f4 d5 4. exd5 exd5 5. Nf3 d4 6. Nb1 Bg4 7. Be2 Bxf3 8. Bxf3 Nf6 9. O-O Nc6 10. d3 Be7 11. Re1 O-O 12. Qe2 Re8 13. a3 c4 14. dxc4 d3 15. Qd1 dxc2 16. Qxc2 Bc5+ 17. Kf1 Rxe1+ 18. Kxe1 Nd4 19. Qd3 Qc7 20. Kf1 Re8 21. Nc3 Nb3 22. Rb1 Nxc1 23. Rxc1 Rd8 24. Qe2 Re8 25. Qd3 a6 26. g3 h6 27. Nd5 Nxd5 28. Bxd5 Re3 29. Qd1 b5 30. b3 Bxa3 31. Rc2 Bc5 32. Re2 bxc4 33. Bxc4 Rf3+ 34. Kg2 Re3 35. Rxe3 Bxe3 0-1",
    B: "1802",
  },
  {
    W: "1192",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d3 Nf6 4. Bg5 Be7 5. Bxf6 Bxf6 6. Be2 Nd4 7. Nxe5 Bxe5 8. Nc3 Qf6 9. Nd5 Qc6 10. Qc1 Nxc2+ 11. Kd2 d6 12. Qxc2 Qa4 13. Qxa4+ Bd7 14. Nxc7+ Ke7 15. Qb3 Rac8 16. Nd5+ Ke6 17. Bg4+ f5 18. Bxf5+ Kf7 19. Nb6+ 1-0",
    B: "1166",
  },
  {
    W: "1142",
    T: "300+0",
    P: "1. d4 d5 2. c4 c5 3. cxd5 e6 4. dxe6 cxd4 5. exf7+ Kxf7 6. Qb3+ Ke8 7. e3 Nf6 8. Bb5+ Bd7 9. Bxd7+ Qxd7 10. Nf3 dxe3 11. Qxe3+ Be7 12. Ne5 Qb5 13. a4 Qb4+ 14. Nc3 Nc6 15. Nxc6 bxc6 16. Bd2 Qxb2 17. Rd1 Ng4 18. Qd4 Rd8 19. Qxg4 Rxd2 20. Qc8+ Kf7 21. Qxh8 Rxd1+ 22. Nxd1 Bb4+ 23. Kf1 Qe2+ 24. Kxe2 1-0",
    B: "1123",
  },
  {
    W: "1845",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O b5 6. Bb3 Be7 7. d4 exd4 8. e5 Ne4 9. Bd5 Nc5 10. Nxd4 Bb7 11. Nf5 O-O 12. Bh6 gxh6 13. Qg4+ Bg5 14. h4 Nxe5 15. Nxh6+ Kh8 16. Qxg5 Qxg5 17. hxg5 Bxd5 18. f4 Ng6 19. Nc3 Bb7 20. Rae1 Rae8 21. Nxf7+ Kg7 22. Nh6 Rxe1 23. Rxe1 Rxf4 0-1",
    B: "1851",
  },
  {
    W: "925",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. Nc3 Be6 4. Nd5 Nc6 5. c4 a6 6. h4 b5 7. cxb5 axb5 8. b4 Nd4 9. Nxd4 exd4 10. g3 c6 11. Nf4 Nf6 12. d3 Ng4 13. Nxe6 fxe6 14. a3 Nf6 15. f4 e5 16. f5 c5 17. g4 c4 18. g5 Nd7 19. Bh3 g6 20. f6 h6 21. Rf1 Nb6 22. h5 Na4 23. hxg6 hxg5 24. f7+ Ke7 25. Qg4 Nc3 26. Qxg5# 1-0",
    B: "760",
  },
  {
    W: "1118",
    T: "180+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 Qxd5 4. Nc3 Qc6 5. d4 Qb6 6. Bb5+ c6 7. Ba4 Bd7 8. O-O c5 9. dxc5 Qb4 10. Bxd7+ Nxd7 11. a3 Qxc5 12. b4 Qxc3 13. Be3 Ne5 14. Nxe5 Qxe5 15. c4 Bd6 16. c5 Qxh2# 0-1",
    B: "1140",
  },
  {
    W: "1883",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 e6 3. c4 a6 4. b3 b5 5. Bb2 Nf6 6. e5 Ng8 7. d4 Bb7 8. d5 d6 9. dxe6 fxe6 10. cxb5 axb5 11. Bxb5+ Nc6 12. exd6 Nf6 13. O-O Bxd6 14. Be5 Bxe5 15. Nxe5 Qc7 16. Nc3 Qxe5 17. Re1 Qxc3 18. Rxe6+ Kf7 19. Rxc6 Bxc6 20. Bxc6 Rad8 21. Qf1 Rd6 22. Bb5 Rhd8 23. Bc4+ Kf8 24. Re1 Qxe1 25. Qxe1 Rd1 26. Kf1 Ne4 27. Qxd1 Rxd1+ 28. Ke2 Rd8 29. a4 Nc3+ 30. Ke3 Nd5+ 31. Kd3 Nf4+ 32. Ke4 Nxg2 33. a5 Nh4 34. a6 Ra8 35. Kd5 Ng6 36. Kxc5 Ne5 37. Kb6 Nxc4+ 38. bxc4 Ke7 39. c5 Kd7 40. c6+ Kc8 41. a7 g5 42. f3 h6 43. h3 h5 44. c7 Rxa7 45. Kxa7 Kxc7 46. Ka6 Kd6 47. Kb5 Ke5 48. Kc4 Kf4 49. Kd4 Kxf3 50. Ke5 g4 51. hxg4 hxg4 0-1",
    B: "1877",
  },
  {
    W: "1771",
    T: "180+0",
    P: "1. b4 d5 2. Bb2 c6 3. g3 h6 4. Bg2 Bf5 5. Nf3 e6 6. O-O Nf6 7. a3 Nbd7 8. d3 Be7 9. Nbd2 Bh7 10. Re1 O-O 11. d4 a5 12. bxa5 Rxa5 13. c4 Qc7 14. c5 b5 15. Nb3 Raa8 16. e3 Rfe8 17. Nfd2 Ne4 18. Nxe4 dxe4 19. Nd2 Nf6 20. Qc2 Nd5 21. Bxe4 f5 22. Bxd5 exd5 23. Qd1 Bf6 24. Nf3 Re4 25. Nd2 Re7 26. Nf3 Rae8 27. Ne5 Bxe5 28. dxe5 Re6 29. f4 Qa7 30. a4 Qxc5 31. axb5 Qxb5 32. Ba3 c5 33. Rb1 Qa5 34. Ra1 Qb5 35. Qxd5 Kh8 36. Rec1 Bg8 37. Qxc5 Qe2 38. Re1 Qf3 39. Rf1 Qg4 40. Qd5 Ra6 41. Qf3 Qg6 42. Bb2 Rd8 43. Rxa6 Qxa6 44. Bd4 Bd5 45. Qh5 Qe6 46. Qd1 Be4 47. Qa4 Qd5 48. Qb4 Bh1 49. Rf2 Be4 50. Qc5 Qa2 51. Qc1 1-0",
    B: "1799",
  },
  {
    W: "1800",
    T: "180+0",
    P: "1. d4 c6 2. e3 d5 3. Nf3 Bf5 4. Bd3 Bg4 5. Be2 Nf6 6. h3 Bh5 7. Nbd2 e6 8. O-O Be7 9. Nh2 Bg6 10. Bd3 O-O 11. a3 Nbd7 12. b4 a5 13. c3 Qc7 14. Be2 Ne4 15. Nxe4 dxe4 16. Bb2 f5 17. Bh5 Rf6 18. f4 exf3 19. Bxf3 Bd6 20. c4 Bxh2+ 21. Kh1 Bd6 22. d5 Be5 23. Rb1 Bxb2 24. Rxb2 cxd5 25. cxd5 e5 26. Rc2 Qd6 27. Be2 f4 28. Rc3 axb4 29. axb4 fxe3 30. Rxe3 Rxf1+ 31. Qxf1 Rf8 32. Rf3 Rxf3 33. Qxf3 e4 34. Qb3 Bf7 35. Bc4 Nb6 36. Qa2 Nxc4 37. Qxc4 Bxd5 38. Qc8+ Qf8 39. Qd7 Bc6 40. Qd4 e3 41. Qxe3 Qf1+ 42. Kh2 Qxg2# 0-1",
    B: "1939",
  },
  {
    W: "1967",
    T: "180+0",
    P: "1. e4 Nf6 2. e5 Nd5 3. d4 Nb6 4. c4 e6 5. f4 d6 6. Nc3 c5 7. dxc5 dxc5 8. Qxd8+ Kxd8 9. Be3 a6 10. O-O-O+ Kc7 11. Nf3 Bd7 12. Ne4 Kc6 13. Be2 h6 14. Rd2 Be8 15. Rhd1 N8d7 16. Nd6 Bxd6 17. Rxd6+ Kc7 18. Nd2 Nc8 19. Rd3 b5 20. Ne4 bxc4 21. Rc3 Ncb6 22. Nxc5 Nxc5 23. Bxc5 Ba4 24. Bd6+ Kb7 25. Bf3+ Ka7 26. Rd2 Rac8 27. Kb1 Bc6 28. Bc5 Bxf3 29. Bxb6+ Kxb6 30. Rxf3 Rhd8 31. Rxd8 Rxd8 32. Kc2 Kc5 33. Kc3 a5 34. a3 Rb8 35. Kc2 Kd4 36. Rc3 a4 37. g4 Rb3 38. Rxb3 cxb3+ 39. Kd2 Ke4 40. Kc3 Kxf4 41. Kb4 Kxe5 42. Kxa4 Kd6 43. Kxb3 f5 44. Kc4 f4 45. a4 f3 46. Kd3 e5 47. Ke3 e4 48. b4 g6 49. a5 h5 50. h3 hxg4 51. hxg4 Kc6 52. g5 Kb7 53. b5 Ka7 54. b6+ Kb7 55. Kf2 Ka6 56. Ke3 f2 57. Kxf2 Kb7 58. Ke3 Ka6 59. Kxe4 Kb7 60. Ke5 Ka8 61. Kf6 Kb7 62. Kxg6 Ka6 1/2-1/2",
    B: "2160",
  },
  {
    W: "2316",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e6 6. Ndb5 Bb4 7. a3 Bxc3+ 8. Nxc3 d5 9. exd5 exd5 10. Bd3 d4 11. Ne2 Bf5 12. O-O Bxd3 13. Qxd3 O-O 14. Bg5 h6 15. Bh4 g5 16. Bg3 Re8 17. Rad1 Rc8 18. Rfe1 Qd5 19. c4 dxc3 20. Qxd5 Nxd5 21. Rxd5 c2 22. Rc5 Nd4 23. Rxc8 Nxe2+ 24. Rxe2 Rxc8 0-1",
    B: "2279",
  },
  {
    W: "2174",
    T: "180+0",
    P: "1. Nf3 Nf6 2. c4 e6 3. Nc3 Bb4 4. g3 O-O 5. Bg2 d6 6. O-O Bxc3 7. bxc3 Nc6 8. d4 e5 9. Bg5 h6 10. Bxf6 Qxf6 11. Rb1 Rb8 12. e3 Na5 13. Qa4 b6 14. c5 Bd7 15. Qa3 exd4 16. cxd4 dxc5 17. dxc5 Bc6 18. Nd4 Bxg2 19. Kxg2 bxc5 20. Qxc5 Rxb1 21. Rxb1 Qg6 22. Nf5 Qc6+ 23. Qxc6 Nxc6 24. Rc1 Nb4 25. a3 Nd5 26. Rc5 Rd8 27. Rxd5 Rxd5 28. Ne7+ 1-0",
    B: "2094",
  },
  {
    W: "1274",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. Nf3 Nf6 5. Bb5+ Nc6 6. d4 e6 7. O-O Bb4 8. Bd2 O-O 9. h3 Nxd4 10. Nxd4 Qxd4 11. Be2 Qe5 12. a3 Bxc3 13. Bxc3 Qg5 14. Bd2 Qd5 15. Bb4 Qxd1 16. Raxd1 Ne4 17. Bxf8 Bd7 18. Bb4 Bc6 19. f3 Ng3 20. Rfe1 a5 21. Bc3 b5 22. b4 axb4 23. axb4 h5 24. Rd4 Re8 25. Kf2 Nf5 26. g4 Nxd4 27. Bxd4 hxg4 28. hxg4 Rd8 29. Bc3 Rd5 30. Bd3 Bd7 31. Rh1 e5 32. Bh7+ Kf8 33. Be4 Rd6 34. Rh8+ Ke7 35. Bxe5 Rd2+ 36. Ke3 1-0",
    B: "1302",
  },
  {
    W: "1863",
    T: "180+0",
    P: "1. Nf3 d5 2. d4 e6 3. c3 c5 4. e3 Nf6 5. Nbd2 Nc6 6. Be2 Be7 7. O-O O-O 8. h3 Bd7 9. Ne5 Nxe5 10. dxe5 Ne4 11. Nxe4 dxe4 12. Qc2 Bc6 13. Rd1 Qc7 14. f4 exf3 15. Bxf3 Bxf3 16. gxf3 Qxe5 17. Qg2 Rad8 18. Bd2 Rd3 19. f4 Qc7 20. Qe4 Rfd8 21. Qg2 Qd7 22. Qe2 Rxd2 23. Rxd2 Qxd2 24. Qb5 Qxe3+ 25. Kf1 Qf3+ 26. Ke1 Bh4# 0-1",
    B: "1802",
  },
  {
    W: "1468",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. d4 exd4 5. Nxd4 Qf6 6. Be3 Ne5 7. Be2 Bd6 8. Nc3 Ne7 9. Ncb5 O-O 10. O-O a6 11. Nc3 b6 12. f4 N5g6 13. e5 Nxe5 14. fxe5 Qxe5 15. Qd3 Qxh2+ 16. Kf2 Bg3+ 17. Kf3 Bb7+ 18. Qe4 Bxe4+ 19. Kxe4 Qh4+ 20. Kd3 c5 21. Nf3 Qf6 22. Ne4 Qg6 23. Nh4 Qc6 24. Nxg3 d6 25. Bf3 d5 26. Kd2 c4 27. Rad1 Qb5 28. Kc1 a5 29. Nhf5 Nxf5 30. Nxf5 a4 31. a3 c3 32. b3 axb3 33. cxb3 Qxb3 34. Rf2 Qxa3+ 35. Kc2 Qa1 0-1",
    B: "1480",
  },
  {
    W: "1641",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 d6 3. b3 Bg4 4. Be2 Nf6 5. Bb2 Nxe4 6. O-O Nc6 7. d3 Bxf3 8. Bxf3 Nf6 9. Bxc6+ bxc6 10. Nd2 g6 11. f3 Bg7 12. Ne4 Nxe4 13. fxe4 O-O 14. Qe2 d5 15. exd5 e4 16. Bxg7 exd3 17. Qxd3 Kxg7 18. Rf2 Qxd5 19. Rd1 Rad8 20. Rfd2 Qc5+ 21. Kh1 Rxd3 22. Rxd3 Qxc2 23. R1d2 Qc5 24. h3 Re8 25. Rd7 Re1+ 26. Kh2 Qe5+ 27. g3 Re2+ 28. Rxe2 Qxe2+ 29. Kg1 Qe3+ 30. Kh1 Qe4+ 31. Kh2 Qe2+ 32. Kh1 Qxa2 33. Rxc7 Qxb3 34. h4 Qxg3 35. Rxa7 Qxh4+ 36. Kg1 c5 37. Kf1 c4 38. Ke2 Qe4+ 39. Kd1 Qd3+ 40. Ke1 c3 41. Ra3 Qd2+ 42. Kf1 Qb2 43. Ra7 c2 0-1",
    B: "1613",
  },
  {
    W: "2250",
    T: "180+0",
    P: "1. e4 e6 2. d4 d5 3. Nd2 Nf6 4. e5 Nfd7 5. c3 c5 6. Bd3 Nc6 7. Qg4 Qb6 8. Ndf3 cxd4 9. cxd4 Qa5+ 10. Bd2 Qb6 11. Bc3 Bb4 12. Ne2 Bxc3+ 13. bxc3 O-O 14. O-O Na5 15. Ng5 f5 16. Qh5 h6 17. Rab1 Qd8 18. Nxe6 Qe7 19. Nxf8 Nxf8 20. Bxf5 Bxf5 21. Qxf5 Ne6 22. Nf4 Nxf4 23. Qxf4 Rc8 24. Rb5 b6 25. Rxd5 Rxc3 26. Rc1 Rxc1+ 27. Qxc1 Qe6 28. Rd8+ Kh7 29. Qc2+ g6 30. Qc7+ 1-0",
    B: "2276",
  },
  {
    W: "2076",
    T: "180+0",
    P: "1. Nc3 d5 2. e4 c6 3. exd5 cxd5 4. d4 Nc6 5. Bd2 e6 6. Nf3 Nf6 7. Ne5 Be7 8. Bg5 Ne4 9. Nxe4 Bxg5 10. Nxg5 Qxg5 11. Bb5 O-O 12. O-O Ne7 13. f4 Qf6 14. Ng4 Qh4 15. g3 Qh3 16. Rf2 Kh8 17. Bf1 Qh5 18. Be2 Qf5 19. c3 Nc6 20. Bd3 Qh5 21. Ne5 Qxd1+ 22. Rxd1 Nxe5 23. fxe5 f6 24. Rdf1 f5 25. g4 g6 26. gxf5 gxf5 27. c4 dxc4 28. Bxc4 Bd7 29. b3 b5 30. Bd3 Bc6 31. Rc1 Rac8 32. Rfc2 Rg8+ 33. Kf1 Bb7 34. Rc7 Rxc7 35. Rxc7 Bg2+ 36. Kf2 a6 37. Re7 Bd5 38. Rd7 Rg2+ 39. Kf1 Rxa2 40. Bxf5 1-0",
    B: "2044",
  },
  {
    W: "2095",
    T: "180+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. f4 e6 4. Nf3 d5 5. Bb5 d4 6. Bxc6+ bxc6 7. Ne2 Ba6 8. d3 Nf6 9. O-O Be7 10. e5 Nd5 11. c4 dxc3 12. Nxc3 Nxc3 13. bxc3 Qxd3 14. Qxd3 Bxd3 15. Rd1 c4 16. Be3 O-O 17. Ne1 Rfd8 18. Nxd3 cxd3 19. Rab1 c5 20. Rb7 Bf8 21. Kf2 c4 22. Ke1 a5 23. Rdb1 g6 24. Rc7 Rdc8 25. Rbb7 a4 26. h3 a3 27. g4 Rxc7 28. Rxc7 Rb8 29. Rxc4 Rb2 30. Rd4 Re2+ 31. Kd1 Rxe3 32. Kd2 Rxh3 33. c4 Rh2+ 34. Kc3 Rxa2 35. Rxd3 Rh2 36. Kb3 a2 37. Rd1 h5 38. gxh5 gxh5 39. c5 Bxc5 40. Ra1 Bd4 0-1",
    B: "2225",
  },
  {
    W: "1604",
    T: "180+0",
    P: "1. e4 d6 2. d4 e6 3. Nf3 Be7 4. Bf4 Bd7 5. Nc3 f6 6. e5 fxe5 7. dxe5 dxe5 8. Nxe5 Bf6 9. Nxd7 Qxd7 10. Qxd7+ Nxd7 11. Bxc7 Rc8 12. Ba5 b6 13. Bb4 a5 14. Ba3 Bxc3+ 15. bxc3 Rxc3 16. Bb5 Rxa3 17. O-O Ke7 18. c4 Nc5 19. Rfd1 Rd3 20. Re1 Nf6 21. Rad1 Rhd8 22. h3 Rxd1 23. Rxd1 Rxd1+ 24. Kh2 Ra1 25. a4 Nxa4 0-1",
    B: "1562",
  },
  {
    W: "1827",
    T: "15+0",
    P: "1. b3 g6 2. Bb2 Nf6 3. Bxf6 Bg7 4. Bxg7 Rg8 5. Bb2 e5 6. e3 e4 7. Bc4 d5 8. Bb5+ c6 9. Be2 Be6 10. Bf6 Nd7 11. Bxd8 Kxd8 12. c3 Kc7 13. f3 Nc5 14. fxe4 Nd3+ 15. Bxd3 d4 16. cxd4 Rad8 17. Bc4 Kb8 18. Bxe6 fxe6 19. Nf3 Rge8 20. Ne5 Rxd4 21. exd4 Rd8 22. Nf7 Re8 23. Nd6 Re7 24. Ne8 Rd7 25. Nf6 Rd8 26. Qg4 Rf8 27. Nd7+ 1-0",
    B: "1559",
  },
  {
    W: "2224",
    T: "120+1",
    P: "1. e3 g6 2. d4 Bg7 3. Nf3 Nf6 4. b3 c5 5. Bb2 Ne4 6. Bd3 Qa5+ 7. c3 d5 8. O-O cxd4 9. exd4 Nc6 10. Re1 f5 11. b4 Qc7 12. b5 Na5 13. Nbd2 O-O 14. Rc1 Be6 15. Nb3 Nc4 16. Ba1 b6 17. a4 Kh8 18. h4 h6 19. Nbd2 Ncxd2 20. Nxd2 Qf4 21. f3 Qxd2 22. Qxd2 Nxd2 23. Rxe6 Bf6 24. Rd1 Nc4 25. Bxc4 dxc4 26. Bb2 Kg7 27. Bc1 Bxh4 28. Bf4 Bf6 29. Rde1 g5 30. Be5 Rf7 31. Rc6 Bxe5 32. Rxe5 Rf6 33. Rxe7+ Rf7 34. Ree6 Rd8 35. Rg6+ Kf8 36. Rxh6 Ke7 37. Rc7+ Rd7 38. Rxc4 Kd8 39. Rh8+ Ke7 40. Kf2 Ke6 41. Rc6+ Kd5 42. Ke3 Rfe7+ 43. Kd3 Rd6 0-1",
    B: "2193",
  },
  {
    W: "1412",
    T: "120+1",
    P: "1. d4 d5 2. c4 e6 3. Nc3 dxc4 4. e4 Bb4 5. Qa4+ Nc6 6. Bd2 Qxd4 7. Nce2 Qxd2# 0-1",
    B: "1440",
  },
  {
    W: "1552",
    T: "120+1",
    P: "1. e4 Nf6 2. d3 d6 3. Nc3 g6 4. Bg5 Bg7 5. g3 Na6 6. Bg2 c5 7. Nge2 Nc7 8. O-O Rb8 9. f4 O-O 10. a3 Bd7 11. Qd2 a6 12. Nd1 b5 13. Ne3 Ne6 14. Bxf6 Bxf6 15. c3 a5 16. f5 Nc7 17. g4 b4 18. axb4 axb4 19. c4 Bg7 20. Rf3 e6 21. Raf1 exf5 22. gxf5 Qe7 23. Rg3 g5 24. Bh3 f6 25. Bg4 h6 26. Rg2 Bc6 27. Ng3 Rbe8 28. Nd5 Bxd5 29. exd5 Qe3+ 30. Qxe3 Rxe3 31. Be2 Rfe8 32. Rff2 R8e5 33. b3 Re8 34. Ne4 R3xe4 35. dxe4 Rxe4 36. Bf3 Re3 37. Bd1 Na8 38. Re2 Rc3 39. Re8+ Kh7 40. Rxa8 Rc1 41. Rd2 h5 42. Kf2 g4 43. Kg3 Bh6 44. Rd3 Bg5 45. Ra6 1-0",
    B: "1668",
  },
  {
    W: "2101",
    T: "120+1",
    P: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be2 Bg7 5. h4 O-O 6. h5 e6 7. hxg6 fxg6 8. Be3 c6 9. Qd2 b5 10. Bh6 e5 11. Bxg7 Kxg7 12. Qh6+ Kg8 13. O-O-O Qa5 14. dxe5 dxe5 15. Bxb5 cxb5 16. Nd5 Nbd7 17. a3 b4 18. Nxb4 Rb8 19. Nc6 Qb6 20. Nxb8 Qxb8 21. Nf3 Qb6 22. Ng5 Qxf2 23. Nxh7 Qf4+ 24. Qxf4 exf4 25. Ng5 Ne5 26. Rd6 Bg4 27. Ne6 Bxe6 28. Rxe6 Neg4 29. Rd1 Re8 30. Rxe8+ Nxe8 31. Rd8 Kf8 32. Rd7 Ngf6 33. Rxa7 Nxe4 34. c4 g5 35. c5 g4 36. c6 f3 37. c7 Nxc7 38. gxf3 gxf3 39. Rxc7 f2 40. Rc8+ Kf7 41. Rc7+ Kf6 42. Rc6+ Kf5 43. Rc5+ Nxc5 0-1",
    B: "2034",
  },
  {
    W: "1162",
    T: "120+1",
    P: "1. e3 e5 2. Nf3 e4 3. Nd4 c5 4. Nb3 c4 5. Nd4 Nc6 6. Nxc6 dxc6 7. d3 Qa5+ 8. Bd2 Qd5 9. dxe4 Qxe4 10. Nc3 Qe5 11. Bxc4 Nf6 12. O-O Ng4 13. g3 Bc5 14. a3 O-O 15. b4 Bxe3 16. Re1 Bxf2+ 17. Kf1 Bxe1 18. Bxe1 Nxh2+ 19. Kg2 Ng4 20. Be2 Ne3+ 0-1",
    B: "1116",
  },
  {
    W: "1726",
    T: "120+1",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 Nf6 5. Be2 Bd6 6. Nc3 Be6 7. h3 Nbd7 8. O-O O-O 9. Nb5 Be7 10. Bf4 c6 11. Nc7 Rc8 12. Nxe6 fxe6 13. Ne5 Ne4 14. Nxd7 Qxd7 15. Bh2 Bg5 16. f3 Be3+ 17. Kh1 Nf2+ 18. Rxf2 Bxf2 19. c3 c5 20. Qf1 Be3 21. dxc5 Bxc5 22. Bd3 h6 23. Bc2 Rf6 24. Qd3 g5 25. Be5 Rf7 26. Re1 Bd6 27. Qg6+ Kf8 28. Qxh6+ Ke7 29. Qxg5+ Kf8 30. Bxd6+ Qxd6 31. Qh6+ Ke7 32. Qg5+ Kd7 33. Qg6 Re7 34. Ba4+ Kc7 35. Qc2 Rg8 36. Qe2 Qg3 37. Bc2 a6 38. Qe5+ Qxe5 39. Rxe5 Kc6 40. Ba4+ b5 41. Bb3 Reg7 42. Re2 Kd6 43. Kh2 Rg5 44. Bc2 Re5 45. Rd2 Rgg5 46. h4 Rh5 47. g3 Re3 48. f4 Rh7 49. Kg2 Rg7 50. Rd3 Re2+ 51. Kf3 Rxc2 52. a3 Rxb2 53. g4 Rb1 54. h5 Rg1 55. g5 e5 56. h6 Rg8 57. fxe5+ 1-0",
    B: "1691",
  },
  {
    W: "1943",
    T: "120+1",
    P: "1. e4 c6 2. d4 d5 3. f3 dxe4 4. fxe4 e5 5. Nf3 Be6 6. dxe5 Qxd1+ 7. Kxd1 Bc5 8. Nbd2 Ne7 9. Bc4 Bg4 10. h3 Bh5 11. Nb3 Bb6 12. Bf4 Nd7 13. g4 Bg6 14. Bd3 Rd8 15. Ke2 O-O 16. a4 Bc7 17. h4 h5 18. g5 Rfe8 19. Bh2 Nc8 20. Rae1 Bxe5 21. Nxe5 Nxe5 22. Bxe5 Rxe5 23. Kf3 Rde8 24. Nd4 Nd6 25. Re3 Bxe4+ 26. Bxe4 Rxe4 27. Rxe4 Rxe4 28. Nf5 Nxf5 29. Kxe4 Ng3+ 0-1",
    B: "1960",
  },
  {
    W: "1846",
    T: "120+1",
    P: "1. e4 c5 2. Bc4 e6 3. Nf3 Ne7 4. Nc3 Nbc6 5. O-O g6 6. d3 Bg7 7. Bg5 h6 8. Bh4 O-O 9. a3 d6 10. Rb1 e5 11. h3 Be6 12. Bxe6 fxe6 13. b4 b6 14. Ne2 Rxf3 15. gxf3 g5 16. Bg3 Nd4 17. Nxd4 cxd4 18. c3 dxc3 19. Qb3 Rc8 20. Qxe6+ Kh7 21. Rbc1 Qg8 22. Qxe7 1-0",
    B: "1788",
  },
  {
    W: "1232",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. c3 Bd7 5. d4 exd4 6. cxd4 Nf6 7. e5 Nd5 8. O-O f6 9. exf6 Qxf6 10. Re1+ Be7 11. Bg5 Qf7 12. Bxe7 Ndxe7 13. Ng5 Qd5 14. Nc3 Qxg5 15. Bxc6 Bxc6 16. d5 Bxd5 17. Nxd5 O-O 18. Nxc7 Rae8 19. Ne6 Qf6 20. Nxf8 Rxf8 21. b3 Qxf2+ 22. Kh1 Nf5 23. Rf1 Ng3+ 24. hxg3 Qxg3 25. Rxf8+ Kxf8 26. Qf3+ Qxf3 27. gxf3 1-0",
    B: "1241",
  },
  {
    W: "2108",
    T: "60+0",
    P: "1. c4 Nc6 2. Nc3 e5 3. g3 Nf6 4. Bg2 Bc5 5. e3 d6 6. Nge2 Bg4 7. O-O Bxe2 8. Qxe2 d5 9. d4 Bb4 10. Nxd5 Nxd5 11. cxd5 Ne7 12. dxe5 Nxd5 13. Rd1 O-O 14. Rxd5 Qe7 15. Qc4 c6 16. Rd4 c5 17. Rh4 Rad8 18. Bf1 Rd1 19. Qe2 Rfd8 20. b3 Bc3 21. Rb1 Qxe5 22. Kg2 Qd5+ 23. f3 Qg5 24. Rg4 Qf5 25. h4 Qxb1 26. Ba3 R8d2 27. Bxc5 Rxe2+ 28. Bxe2 Rd2 29. Kf2 Rxe2+ 30. Kxe2 Qe1+ 31. Kd3 Qd2+ 32. Ke4 b6 33. Bd4 Bxd4 34. exd4 Qc2+ 35. Ke3 Qxa2 36. Re4 Qxb3+ 37. Kf4 Kf8 38. h5 h6 39. Kg4 Qd5 40. Rf4 Qe6+ 0-1",
    B: "2176",
  },
  {
    W: "1590",
    T: "180+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. d4 f5 4. Nc3 b6 5. Bd3 c5 6. dxc5 Bxc3+ 7. bxc3 Bb7 8. cxb6 fxe4 9. bxa7 exf3 10. axb8=Q Rxb8 11. gxf3 Nf6 12. Bh6 Ng4 13. fxg4 Bxh1 14. f3 Qb6 15. Qe2 Qg1+ 16. Qf1 Qxf1+ 17. Kxf1 Bxf3 18. h3 Rg8 19. Kf2 Bd5 20. c4 Bc6 21. a4 Ra8 22. a5 Kf7 23. a6 Kf6 24. h4 d5 25. cxd5 Bxd5 26. g5+ Ke6 27. a7 Ke5 28. Re1+ Kd6 29. Ra1 Rge8 30. Ke3 e5 31. Be4 Bxe4 32. Kxe4 Re7 33. c4 Rexa7 34. Rxa7 Rxa7 0-1",
    B: "1525",
  },
  {
    W: "1532",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 c5 5. Nf3 Nf6 6. Nc3 Be7 7. Bg5 Be6 8. Qd2 h6 9. Bh4 b5 10. Bxb5+ Nbd7 11. O-O-O O-O 12. Bxf6 Nxf6 13. Rhe1 a6 14. Bc6 Rb8 15. e5 Qb6 16. b3 Ng4 17. Bd5 Bxd5 18. Nxd5 Qb4 19. Nxe7+ Kh8 20. Qxb4 Rxb4 21. exd6 Rd8 22. Nc6 Rd7 23. Nxb4 cxb4 24. Re7 Nf6 25. Rxd7 Nxd7 26. Nd4 Kg8 27. Re1 Kf8 28. Nc6 f6 29. Re7 g5 30. Rxd7 Ke8 31. Re7+ Kf8 32. Rc7 Ke8 33. d7+ Kf8 34. d8=Q# 1-0",
    B: "1502",
  },
  {
    W: "2058",
    T: "30+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 exd4 5. Nxd4 Bc5 6. Be3 Bb6 7. Qd3 Ne5 8. Qd2 O-O 9. f4 Nc6 10. e5 Ne8 11. O-O-O d5 12. Bb5 Bd7 13. Bxc6 bxc6 14. a3 a5 15. Rhe1 c5 16. Ndb5 d4 17. Bxd4 cxd4 18. Nd5 Bc6 19. Nxd4 Qxd5 20. Nb3 Qe6 21. Qe3 a4 22. Qd2 axb3 23. cxb3 Ba4 24. bxa4 Rd8 25. Qxd8 Nd6 26. Qxf8+ Kxf8 27. exd6 Qxd6 28. Rxd6 Bc5 29. Red1 Bxa3 30. Kc2 Bxb2 31. Kd3 Ke7 32. Kc2 cxd6 33. Re1+ Kd7 34. Kxb2 Kc7 35. Kb3 Kb6 0-1",
    B: "2025",
  },
  {
    W: "1405",
    T: "600+0",
    P: "1. d4 e5 2. dxe5 f6 3. Nf3 Nc6 4. exf6 Nxf6 5. b3 d5 6. Bb2 Bf5 7. a3 a5 8. e3 Qe7 9. Nbd2 O-O-O 10. Bc3 d4 11. Nxd4 Nxd4 12. Bxd4 Rxd4 13. Be2 Rd7 14. O-O c6 15. Qe1 Bxc2 16. Nc4 Nd5 17. Qxa5 Bxb3 18. Bg4 Bxc4 19. Bxd7+ Qxd7 20. Rfd1 Qe6 21. Rac1 Be2 22. Rd2 Bc4 23. Rxc4 Bd6 24. Rxd5 Qh6 25. h3 Rd8 26. a4 Qe6 27. f4 Bc7 28. Qa8+ Bb8 29. Rxd8+ Kxd8 30. Qxb8+ Kd7 31. Qxb7+ Kd6 32. Rxc6+ Kd5 33. Rxe6+ Kxe6 34. Qb6+ Kf5 35. Qc5+ Ke4 36. Qd4+ Kf5 37. a5 Ke6 38. a6 1-0",
    B: "1332",
  },
  {
    W: "1977",
    T: "60+0",
    P: "1. b3 e6 2. Bb2 d6 3. c4 Nf6 4. Nf3 Be7 5. g3 O-O 6. Bg2 e5 7. h4 Nbd7 8. d4 c5 9. dxe5 dxe5 10. Nxe5 Nxe5 11. Qxd8 Rxd8 12. Bxe5 Bf5 13. Bxf6 Bxf6 14. Nd2 Bxa1 15. O-O Bf6 16. Nf3 Bg6 17. Nh2 Re8 18. Bxb7 Rab8 19. Bc6 Rxe2 20. Ra1 Rd8 21. Rf1 h6 22. Ng4 Bb2 23. Bd5 Be4 24. Bxe4 Rxe4 25. Kg2 Rd2 26. Kf3 Red4 27. Ne3 R4d3 28. Ke4 Rd4+ 29. Kf3 R2d3 30. Ke2 Bc3 31. Nd5 Rd2+ 32. Kf3 Rxa2 33. Rc1 Bb4 34. Nxb4 cxb4 35. Ke3 Rd8 36. c5 Rc8 37. Kd4 Ra6 38. Kc4 Rac6 39. Kxb4 f6 40. Kb5 Kf7 41. b4 a6+ 42. Ka5 Ke6 43. Re1+ Kd5 44. f4 Kc4 45. Rc1+ Kd4 46. Rd1+ Ke4 47. Re1+ Kf3 48. Re7 Kxg3 49. Rxg7+ Kxf4 50. Rg6 h5 0-1",
    B: "1960",
  },
  {
    W: "1221",
    T: "300+3",
    P: "1. e4 e5 2. Bc4 Nc6 3. Qf3 Nh6 4. Nc3 a6 5. a4 Bc5 6. d4 Nxd4 7. Qg3 Nxc2+ 8. Kd1 Nxa1 9. Qxg7 Rg8 10. Qxh6 b5 11. Bd5 Nb3 12. Qxh7 c6 13. Qxf7# 1-0",
    B: "1194",
  },
  {
    W: "1300",
    T: "300+3",
    P: "1. e4 d5 2. e5 c5 3. Nf3 f6 4. Qe2 fxe5 5. Nxe5 Nf6 6. Qb5+ Nbd7 7. Nxd7 Qxd7 8. Qxc5 e6 9. Qd4 Bd6 10. Nc3 e5 11. Qd3 d4 12. Ne4 O-O 13. b3 Nxe4 14. Qxe4 Qf5 15. Qxf5 Bxf5 16. Bd3 Bxd3 17. cxd3 Rac8 18. O-O Bb4 19. a3 Be7 20. b4 Bg5 21. g3 Rc2 22. Rd1 Rf3 23. h4 Bh6 24. Re1 Rxd3 25. Rxe5 Bxd2 26. Bxd2 Rcxd2 27. Rae1 h6 28. Kg2 Rxa3 29. R1e4 Raa2 30. Rf5 Rab2 31. b5 b6 32. Re7 Ra2 33. Rff7 1-0",
    B: "1274",
  },
  {
    W: "1517",
    T: "720+5",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Nf3 dxc4 5. e4 Bb4 6. e5 Nd5 7. Bd2 Nxc3 8. Bxc3 Bxc3+ 9. bxc3 b5 10. a4 c6 11. axb5 cxb5 12. Be2 O-O 13. O-O f6 14. exf6 Qxf6 15. Ne5 Na6 16. f4 Bb7 17. Bf3 Bxf3 18. Rxf3 Qe7 19. Rxa6 Qf6 20. Qa1 Qd8 21. Rxa7 Rc8 22. Qa5 Qd5 23. Rc7 Ra8 24. Ra7 Rac8 25. Qa1 Qe4 26. Qc1 Qd5 27. Rg3 Qe4 28. Rgxg7+ Kh8 29. Rxh7+ Qxh7 30. Rxh7+ Kxh7 31. Qc2+ Kg7 32. g3 Rf6 33. Qb2 Ra8 34. Qxb5 Ra1+ 35. Kf2 Ra2+ 36. Ke3 Rxh2 37. Qxc4 Rh3 38. Qc7+ Kh6 39. Ng4+ 1-0",
    B: "1637",
  },
  {
    W: "2212",
    T: "180+2",
    P: "1. e4 c5 2. Nc3 Nc6 3. f4 g6 4. Nf3 Bg7 5. Bc4 e6 6. O-O Nge7 7. d3 O-O 8. Qe1 Nd4 9. f5 Nxc2 10. Qh4 exf5 11. Bg5 Re8 12. Nd5 fxe4 13. dxe4 h6 14. Bxe7 g5 15. Bxg5 hxg5 16. Nxg5 Rxe4 17. Qh7+ 1-0",
    B: "2193",
  },
  {
    W: "1758",
    T: "60+0",
    P: "1. e4 b6 2. Nf3 d6 3. Nc3 Bb7 4. d3 Nd7 5. Be3 e6 6. Be2 Ne7 7. Qd2 g6 8. O-O Bg7 9. h3 O-O 10. Nh2 a5 11. f4 c6 12. Nf3 b5 13. f5 c5 14. fxe6 fxe6 15. Bh6 b4 16. Bxg7 Kxg7 17. Nd1 a4 18. Ng5 Rxf1+ 19. Bxf1 Ng8 20. Ne3 h6 21. Nf3 g5 22. Be2 e5 23. Rf1 Qf6 24. h4 Qg6 25. hxg5 hxg5 26. Ng4 Rf8 27. Nxg5 Rxf1+ 28. Bxf1 Ngf6 29. Nxf6 Kxf6 30. Nf3 Bc8 31. Kf2 Qf7 32. c3 Ke7 33. cxb4 cxb4 34. Qxb4 Nf6 35. Ng5 Nd5+ 36. Nxf7 Nxb4 37. Ng5 Nxd3+ 38. Bxd3 Bg4 39. Be2 Bxe2 40. Kxe2 Kf6 41. Nf3 Ke6 42. b3 d5 43. bxa4 dxe4 44. Nd2 Kd5 45. Ke3 Kc5 46. Nxe4+ Kb4 47. g4 Kxa4 48. g5 Ka3 49. g6 Kxa2 50. g7 Kb3 51. g8=Q+ Kb4 52. Nd6 Kc5 53. Nf7 Kd5 54. Ng5+ Kd6 55. Ke4 Ke7 56. Kxe5 Kd7 57. Ne4 Ke7 58. Qe6+ Kf8 59. Nd6 Kg7 60. Qf6+ Kg8 61. Kf5 Kh7 62. Qg6+ Kh8 63. Ne4 1/2-1/2",
    B: "1779",
  },
  {
    W: "1458",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be2 Nbd7 7. O-O b5 8. a3 Bb7 9. f3 Rc8 10. Bg5 e5 11. Nb3 Be7 12. Rc1 O-O 13. Nd5 Nxd5 14. Bxe7 Nxe7 15. Qd2 Ng6 16. c3 Nf4 17. Bd1 Qf6 18. g3 Nh3+ 19. Kg2 Ng5 20. f4 Ne6 21. f5 Nd8 22. Bf3 Rb8 23. Rcd1 Nc6 24. Nc1 Na5 25. b4 Nc4 26. Qe2 Nxa3 27. Nd3 Nc4 28. Nb2 Qg5 29. Nxc4 bxc4 30. Qxc4 Rbc8 31. Qd3 Qf6 32. Qxd6 Qxd6 33. Rxd6 Nc5 34. Rc1 Nxe4 35. Bxe4 Bxe4+ 0-1",
    B: "1467",
  },
  {
    W: "1352",
    T: "120+1",
    P: "1. e4 c5 2. c4 Nc6 3. Nc3 e6 4. Be2 Nf6 5. Nf3 d5 6. d3 d4 7. Nb5 a6 8. Na3 e5 9. O-O Bg4 10. h3 Bh5 11. Bg5 Be7 12. Nh2 O-O 13. Bxh5 Nxh5 14. Qxh5 Bxg5 15. Ng4 g6 16. Nh6+ Bxh6 17. Qxh6 Qf6 18. f4 Rfe8 19. fxe5 Nxe5 20. Rxf6 Nxd3 21. Raf1 Ne5 22. Nc2 Rac8 23. b4 Rc6 24. b5 Rxf6 25. Rxf6 axb5 26. cxb5 Nd7 27. Rf1 Ne5 28. Qf4 Nc4 29. a4 1-0",
    B: "1258",
  },
  {
    W: "1672",
    T: "120+1",
    P: "1. e4 g6 2. Nf3 Bg7 3. Nc3 d6 4. Bc4 e6 5. d4 Nc6 6. d5 Ne5 7. Nxe5 Bxe5 8. dxe6 fxe6 9. Nb5 Nf6 10. Bg5 O-O 11. c3 a6 12. Nd4 Re8 13. f4 Bxd4 14. cxd4 h6 15. Bxh6 Nxe4 16. Qd3 d5 17. O-O-O Qd6 18. Bb3 c5 19. dxc5 Qxc5+ 20. Kb1 Kf7 21. Rc1 Qd6 22. Rhe1 Qe7 23. Rxe4 dxe4 24. Qxe4 Bd7 25. Rc7 Rac8 26. Rxb7 Red8 27. Ba4 Qc5 28. Rxd7+ Rxd7 29. Bxd7 Qc1# 0-1",
    B: "1784",
  },
  {
    W: "1089",
    T: "120+1",
    P: "1. d4 d5 2. e3 e6 3. f4 Bb4+ 4. Bd2 Be7 5. Nc3 Nf6 6. Nf3 h6 7. h3 O-O 8. Be2 c5 9. Ne5 c4 10. Bh5 Nxh5 11. Qxh5 Qe8 12. O-O-O b5 13. g4 b4 14. Ne2 b3 15. cxb3 cxb3 16. axb3 Ba6 17. Ng3 Qc8+ 18. Bc3 Nd7 19. Rdg1 Nxe5 20. Kc2 Bb4 21. Qxe5 Bxc3 22. bxc3 Qc6 23. Nh5 f6 24. Nxf6+ Rxf6 25. g5 Rf5 26. Qxg7+ Kxg7 27. gxh6+ Kh7 28. Rg4 Rg8 29. Rhg1 Rf7 30. h4 Rxg4 31. Rxg4 Qb5 32. f5 Qe2+ 33. Kc1 Qe1+ 34. Kb2 Qe2+ 35. Ka3 Qxg4 36. Ka4 exf5 37. Ka5 Rf6 38. Ka4 Qe2 39. c4 dxc4 40. bxc4 Qxc4+ 41. Ka5 Qb5# 0-1",
    B: "1251",
  },
  {
    W: "1470",
    T: "120+1",
    P: "1. d4 d6 2. c4 Nf6 3. Nc3 g6 4. e4 Bg7 5. Bg5 O-O 6. Nf3 Nbd7 7. Be2 c5 8. dxc5 Nxc5 9. e5 dxe5 10. Qxd8 Rxd8 11. Nxe5 Bf5 12. O-O Rac8 13. b3 a6 14. Rad1 Ne6 15. Be3 Ne4 16. Rxd8+ Rxd8 17. Nxe4 Bxe4 18. Nf3 h5 19. Bb6 Rd6 20. Be3 Nd4 21. Bd1 Nxf3+ 22. Bxf3 Bxf3 23. gxf3 Bf6 24. c5 Rc6 25. b4 e6 26. Rd1 Bc3 27. a3 Bb2 28. Rd3 Kg7 29. Bd4+ Bxd4 30. Rxd4 b6 31. Rd6 Rxd6 32. cxd6 Kf8 33. Kf1 Ke8 34. Ke2 Kd7 35. Kd3 Kxd6 36. Kd4 f6 37. f4 a5 38. h3 axb4 39. axb4 e5+ 40. fxe5+ fxe5+ 41. Kc4 Kc6 42. f3 b5+ 43. Kc3 Kd5 44. h4 1-0",
    B: "1467",
  },
  {
    W: "1231",
    T: "120+1",
    P: "1. d4 d5 2. Nf3 Nc6 3. Bf4 Bf5 4. c3 e6 5. Nbd2 g6 6. e3 Bg7 7. Bb5 Ne7 8. O-O O-O 9. Bxc6 Nxc6 10. Qb3 b6 11. Ne5 Nxe5 12. Bxe5 Bxe5 13. dxe5 Qg5 14. Nf3 Qh5 15. c4 Be4 16. Qd1 Bxf3 17. Qxf3 Qxe5 18. cxd5 exd5 19. Rab1 c6 20. Rfd1 f5 21. b3 Rac8 22. Rbc1 Kg7 23. Qe2 b5 24. a3 a5 25. b4 a4 26. Rc2 Rf6 27. Rdc1 Kf7 28. Qd2 h5 29. Qe2 Qe7 30. Qf3 Rd6 31. Qf4 Kg7 32. Kh1 Qe4 33. Qg5 Qg4 34. Qe7+ 1-0",
    B: "1141",
  },
  {
    W: "1572",
    T: "120+1",
    P: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. d4 Nc6 5. c4 Bg4 6. cxd5 Qxd5 7. Be2 Bxf3 8. Bxf3 Qxd4 9. Qxd4 Nxd4 10. Bxb7 Rb8 11. Be4 f5 12. Bd3 Nf6 13. O-O e5 14. f3 Bc5 15. Kh1 O-O 16. Nc3 e4 17. fxe4 fxe4 18. Bc4+ Kh8 19. Bf4 Ng4 20. Bxb8 Rxb8 21. Nxe4 Bb6 22. Bd3 Ne3 23. Rf2 h6 24. b3 Nd5 25. Rf7 Nb4 26. Raf1 Nxd3 27. Rf8+ Rxf8 28. Rxf8+ Kh7 29. Rd8 Nb2 30. Rd6 Ne2 31. Rd2 1-0",
    B: "1566",
  },
  {
    W: "1488",
    T: "120+1",
    P: "1. e3 c5 2. d3 e6 3. f3 d5 4. g3 Bd6 5. Bg2 f6 6. Ne2 Ne7 7. O-O Nd7 8. c3 e5 9. Nd2 b6 10. a4 Bb7 11. e4 d4 12. f4 O-O 13. Qe1 Ng6 14. c4 exf4 15. gxf4 Qc7 16. Qg3 f5 17. e5 Bxg2 18. exd6 Qxd6 19. Qxg2 Rae8 20. Ng3 Nxf4 21. Qf3 Ng6 22. Qg2 f4 23. Nge4 f3 24. Nxd6 fxg2 25. Rxf8+ Rxf8 26. N2e4 Nde5 27. Bd2 Nxd3 28. Kxg2 Ndf4+ 29. Bxf4 Nxf4+ 30. Kf3 d3 31. Rd1 Nd5+ 32. Kg4 Nb4 33. Nf5 g6 34. Ne7+ Kg7 35. Ng5 h5+ 36. Kg3 Kf6 37. Nh7+ Kxe7 38. Nxf8 Kxf8 39. Kh4 Kg7 40. Kg5 a6 41. h4 a5 42. b3 Kh7 43. Kf6 Kh6 44. Kf7 Kh7 45. Kf6 Kh6 46. Rg1 d2 47. Rxg6+ Kh7 48. Rg1 Nd3 49. Rd1 Nf2 50. Rxd2 Ne4+ 51. Ke5 Nxd2 52. Kd6 Nxb3 53. Kc6 Nd2 54. Kxb6 Nxc4+ 55. Kxc5 Nb2 56. Kb5 Kg6 57. Kxa5 Nxa4 58. Kxa4 Kf5 59. Kb3 Kg4 60. Kc2 Kxh4 61. Kc1 Kg3 62. Kd1 Kg2 63. Ke1 h4 0-1",
    B: "1560",
  },
  {
    W: "1943",
    T: "120+1",
    P: "1. e4 e6 2. d3 d5 3. Nd2 dxe4 4. dxe4 Nf6 5. Bd3 Bb4 6. Nf3 O-O 7. O-O c5 8. a3 Ba5 9. Nb3 Qe7 10. Nxa5 b6 11. Nc4 Bb7 12. Bg5 h6 13. Bxf6 Qxf6 14. c3 Nc6 15. Nd6 Rab8 16. Nxb7 Rxb7 17. Qc2 Ne5 18. Nxe5 Qf5 19. exf5 1-0",
    B: "1892",
  },
  {
    W: "1388",
    T: "120+1",
    P: "1. d4 f5 2. Nf3 e6 3. Bf4 c6 4. e3 Nf6 5. h4 Be7 6. Ng5 Ne4 7. Nxe4 fxe4 8. Nd2 d5 9. c3 O-O 10. Qh5 Bd6 11. Bg5 Qe8 12. Qg4 e5 13. Qg3 Qg6 14. dxe5 Bc7 15. h5 Qf7 16. Bf6 Nd7 17. f4 Nxf6 18. exf6 Qxf6 19. Nb3 b6 20. Nd4 c5 21. Nb5 Bd8 22. c4 a6 23. Nc3 d4 24. exd4 cxd4 25. Nxe4 Re8 26. Bd3 Bb7 27. Qf3 Bxe4 28. Bxe4 Rb8 29. O-O-O b5 30. b3 bxc4 31. bxc4 Rb4 32. h6 Rxc4+ 33. Kb1 gxh6 34. Qg4+ Kh8 0-1",
    B: "1455",
  },
  {
    W: "1044",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nxd4 5. Qxd4 d6 6. b4 Nf6 7. Bd3 g6 8. e5 dxe5 9. Qxd8+ Kxd8 10. O-O Bg7 11. Re1 Nd5 12. Bg5+ f6 13. Be3 Nxb4 14. Nc3 Nxd3 15. cxd3 Ke7 16. Nd5+ Kd7 17. Rac1 c6 18. Nb4 Re8 19. h3 Ke6 20. a4 Kf7 21. f4 exf4 22. Bxf4 Rxe1+ 23. Rxe1 Be6 24. d4 Rd8 25. Nc2 Rf8 26. Bd6 Re8 27. Rd1 Bf5 28. Ne3 Be4 29. Ng4 f5 30. Nf2 Bc2 31. Rd2 Bxa4 32. Bg3 Re1+ 33. Kh2 Be5 34. Bxe5 Rxe5 35. dxe5 Bb5 36. Nd1 a5 0-1",
    B: "1015",
  },
  {
    W: "2427",
    T: "30+0",
    P: "1. d4 c5 2. Nc3 cxd4 3. Qxd4 Nf6 4. Qa4 Nc6 5. e4 a6 6. Bb5 Qb6 7. Be3 axb5 8. Qxa8 Qc7 9. Nd5 Qb8 10. Qxb8 Nxb8 11. Nc7+ Kd8 12. Bb6 d6 13. Nd5+ Kd7 14. Nf3 Nxd5 15. exd5 e6 16. O-O-O exd5 17. Rxd5 Nc6 18. Rxb5 Be7 19. Rd1 f5 20. Bc5 Ke6 21. Nd4+ Nxd4 22. Bxd4 Bf6 23. Bxf6 gxf6 24. Rbd5 Rd8 25. b3 b6 26. c4 Bb7 27. R5d2 Be4 28. Kb2 Kf7 29. f3 d5 30. fxe4 fxe4 31. Rxd5 Re8 32. Rd7+ Kg6 33. R1d6 e3 34. Rd1 e2 35. Re1 Re3 36. Rd2 f5 37. Rdxe2 Rd3 38. Kc2 Kg5 39. Rd2 Re3 40. Rxe3 f4 41. Red3 Kf5 42. Rf3 Kg4 43. Rd4 Kg5 44. Rdxf4 h5 45. c5 Kh6 46. cxb6 Kg7 47. Rg3+ 1-0",
    B: "2355",
  },
  {
    W: "1621",
    T: "180+0",
    P: "1. e4 e6 2. Nf3 d5 3. e5 c5 4. d4 a6 5. dxc5 Bxc5 6. Nc3 Qb6 7. Qe2 Bd7 8. Be3 Bxe3 9. fxe3 Qxb2 10. Rb1 Qxc3+ 11. Nd2 Bb5 12. Rxb5 axb5 13. Qxb5+ Nd7 14. Qxb7 Rc8 15. Bb5 Qc7 16. Qa6 Nh6 17. O-O O-O 18. Bd3 Ra8 19. Qb5 Rfb8 0-1",
    B: "1600",
  },
  {
    W: "1167",
    T: "300+0",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 b6 4. Bh6 Nxh6 5. Bc4 Qg5 6. g3 Nc6 7. Qd1 Ne5 8. Bb3 Bb7 9. Nf3 Qg4 10. Nxe5 Qg5 11. Qxd7# 1-0",
    B: "1175",
  },
  {
    W: "1704",
    T: "15+4",
    P: "1. e4 d5 2. Nf3 dxe4 3. Ng5 Bf5 4. Nc3 Nf6 5. Qe2 c6 6. Ngxe4 Nxe4 7. Nxe4 Bxe4 8. Qxe4 e6 9. Bd3 Nd7 10. b3 Nf6 11. Qf3 Be7 12. Bb2 O-O 13. Qh3 g6 14. g4 Nd5 15. Qh6 Bf6 16. Bxf6 Qxf6 17. g5 Qxa1+ 18. Ke2 Qxh1 0-1",
    B: "1691",
  },
  {
    W: "1933",
    T: "180+0",
    P: "1. e4 c6 2. d4 d5 3. Nd2 e6 4. Bd3 dxe4 5. Nxe4 Nf6 6. Nxf6+ Qxf6 7. Nf3 h6 8. O-O Bd6 9. c4 b6 10. Be3 Nd7 11. Re1 O-O 12. Qc2 c5 13. Rad1 Rb8 14. a4 Bb7 15. Be4 cxd4 16. Bxd4 e5 17. Bc3 Nc5 18. Bxb7 Rxb7 19. Bxe5 Bxe5 20. Nxe5 Rc8 21. b4 Ne6 22. Rd3 Qf5 23. Qc3 Rbc7 24. Rf3 Nf4 25. Qd4 f6 26. Qxf4 Qxf4 27. Rxf4 fxe5 28. Rf5 Rxc4 29. Rfxe5 Rxb4 30. a5 Rc5 31. Re8+ Kh7 32. axb6 Rxb6 33. h3 a5 34. Ra8 Rbb5 35. f3 Kg6 36. Re6+ Kh5 37. Ra7 Rg5 38. f4 Rg3 39. Kh2 Rg6 40. Rxg6 Kxg6 41. g4 Kf6 42. Ra6+ Kf7 43. Kg3 g6 44. h4 Rb3+ 45. Kf2 Rb5 46. Kf3 Rb3+ 47. Ke4 Rb4+ 48. Ke5 Rb5+ 0-1",
    B: "1953",
  },
  {
    W: "1857",
    T: "180+2",
    P: "1. e4 c5 2. Nc3 e6 3. f4 d5 4. e5 Qb6 5. Nf3 Bd7 6. d3 Nc6 7. g3 Nh6 8. Bg2 Be7 9. O-O Nf5 10. Kh1 O-O 11. g4 Nh4 12. Qe1 Nxf3 13. Rxf3 Nd4 14. Rf2 Bh4 0-1",
    B: "1799",
  },
  {
    W: "1755",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nxd4 5. Qxd4 e5 6. Qxe5+ Ne7 7. Nc3 d6 8. Qb5+ Bd7 9. Qxb7 Rb8 10. Qxa7 g6 11. Qd4 Rg8 12. Bd3 Bg7 13. Qe3 Bxc3+ 14. bxc3 Nc6 15. O-O Ne5 16. f4 Ng4 17. Qd4 Qh4 18. h3 Nf6 19. f5 Nh5 20. Qxd6 Rd8 21. Qe5+ Kf8 22. Ba3+ 1-0",
    B: "1717",
  },
  {
    W: "1563",
    T: "180+2",
    P: "1. e4 e5 2. Nc3 Nf6 3. Nge2 Nc6 4. g3 d6 5. Bg2 Be6 6. d4 exd4 7. Nxd4 Nxd4 8. Qxd4 Qd7 9. e5 dxe5 10. Qxd7+ Nxd7 11. Be3 c6 12. O-O h5 13. Rad1 h4 14. b3 hxg3 15. fxg3 O-O-O 16. Ne4 f5 17. Ng5 Re8 18. Bxa7 f4 19. gxf4 exf4 20. Rxf4 Bd6 21. Rxd6 1-0",
    B: "1600",
  },
  {
    W: "1283",
    T: "180+2",
    P: "1. e4 c6 2. d4 d5 3. e5 b5 4. Nf3 Nd7 5. b3 c5 6. Bxb5 cxd4 7. Nxd4 e6 8. Bb2 Bc5 9. O-O Ne7 10. f4 Nf5 11. Kh1 Ne3 12. Qh5 Nxf1 13. Nxe6 Qa5 14. Nxg7+ Kd8 15. Bxd7 Qe1 16. Nc3 Ng3# 0-1",
    B: "1218",
  },
  {
    W: "1803",
    T: "180+2",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. Bg5 O-O 5. e4 d6 6. Nf3 a6 7. Qd2 c5 8. dxc5 dxc5 9. Nd5 Nxe4 10. Nxe7+ Qxe7 11. Bxe7 Nxd2 12. Nxd2 Re8 13. Kd1 Rxe7 14. Bd3 Nc6 15. Re1 Rxe1+ 16. Kxe1 Nb4 17. Bb1 Bf5 18. Bxf5 gxf5 19. Kd1 Rd8 20. a3 Nd3 21. Kc2 Bxb2 22. Rb1 Bxa3 23. Rxb7 Bb4 24. Nf3 Nxf2 25. Ne5 Rd2+ 26. Kb1 Ne4 27. Nxf7 Rxg2 28. Nh6+ Kf8 29. Nxf5 Nc3+ 30. Kc1 Ne2+ 31. Kd1 Nd4 32. Nxd4 Rd2+ 33. Kc1 Rxd4 34. Rxh7 Rxc4+ 35. Kb2 Rf4 36. h4 a5 37. h5 a4 38. h6 a3+ 39. Kb3 Rf3+ 40. Kc4 a2 41. Ra7 Ra3 42. h7 a1=Q 43. h8=R+ Qxh8 44. Rxa3 Qd4+ 0-1",
    B: "1500",
  },
  {
    W: "993",
    T: "180+2",
    P: "1. d4 d5 2. Bf4 e6 3. Nc3 Nc6 4. Qd2 g6 5. O-O-O Nf6 6. Qd3 Bg7 7. e3 O-O 8. Nb5 Nb4 9. Qb3 Nc6 10. Bxc7 Qd7 11. Bd6 Re8 12. Bd3 a6 13. Nc7 Qxd6 14. Nxa8 b5 15. Nb6 Na7 16. Bxb5 Re7 17. Bxa6 Bxa6 18. Nf3 Rb7 19. Qa4 Rxb6 20. Ng5 Nc6 21. Nxe6 fxe6 22. e4 Nb4 0-1",
    B: "1003",
  },
  {
    W: "1105",
    T: "180+2",
    P: "1. e4 d5 2. d3 dxe4 3. dxe4 Qxd1+ 4. Kxd1 Nc6 5. Nc3 Nf6 6. Nf3 Bg4 7. Be2 e5 8. h3 Bxf3 9. Bxf3 Bb4 10. Bd2 Bxc3 11. Bxc3 Nd4 12. Bxd4 exd4 13. e5 Nd7 14. Re1 O-O-O 15. g4 f6 16. e6 Nc5 17. e7 Rde8 18. b4 Na4 19. b5 Nb2+ 20. Kd2 Nc4+ 21. Kc1 Ne5 22. Bg2 Rxe7 0-1",
    B: "1117",
  },
  {
    W: "1346",
    T: "180+2",
    P: "1. e4 e5 2. Bc4 Nf6 3. d3 Bc5 4. Nc3 O-O 5. Nf3 c6 6. Nxe5 d6 7. Nf3 h6 8. O-O Bb6 9. Re1 Nh7 10. a4 Qf6 11. Be3 Bd8 12. b4 Qg6 13. Kh1 Bg4 14. b5 Bg5 15. Bxg5 Nxg5 16. bxc6 Nxc6 17. Nd5 Nxf3 18. gxf3 Bh3 19. Rg1 Qh5 20. Bb5 Nd4 21. Ne7+ Kh8 22. Nf5 g6 23. Nxd4 a6 24. Bc4 Rfe8 25. Bxf7 Re5 26. Bxg6 Qh4 27. Nf5 Qf4 28. Ne3 Rg8 29. Ra3 Rg5 30. Qa1+ 1-0",
    B: "1373",
  },
  {
    W: "1503",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. O-O d6 5. c3 Bg4 6. d4 exd4 7. cxd4 a6 8. dxc5 axb5 9. cxd6 Qxd6 10. Qxd6 cxd6 11. Nc3 Bxf3 12. gxf3 b4 13. Nd5 Rc8 14. Nb6 Rd8 15. Rd1 Nge7 16. Nc4 d5 17. exd5 Nxd5 18. Bg5 f6 19. Bh4 O-O 20. Bg3 Nde7 21. Bd6 Rfe8 22. Rd2 Nf5 23. Bf4 Rxd2 24. Bxd2 Re2 25. Be3 b5 26. Nd2 Nxe3 27. Rc1 Rxd2 28. fxe3 Ne5 29. f4 Nf3+ 30. Kf1 Rxh2 31. Rc5 Nd2+ 32. Ke1 Ne4 33. Rxb5 Rxb2 34. a4 Nc3 35. Rb8+ Kf7 36. a5 Na4 37. a6 Nc5 38. a7 Ra2 39. Ra8 Nb7 40. Kd1 b3 41. Kc1 b2+ 42. Kb1 Ra5 43. Kxb2 Nd6 44. Kb3 Ra1 45. Kb4 h5 46. Kc5 1-0",
    B: "1463",
  },
  {
    W: "1662",
    T: "60+0",
    P: "1. d4 d5 2. c3 Nf6 3. Qa4+ Nc6 4. b4 a6 5. b5 Nb8 6. bxa6+ Bd7 7. a7 Bxa4 8. axb8=Q Rxb8 9. Nd2 Qd7 10. Bb2 e6 11. a3 Bd6 12. e3 O-O 13. Be2 Bb5 14. Ngf3 Bxe2 15. Kxe2 c6 16. Rhe1 Qc7 17. g3 Ng4 18. Bc1 h6 19. e4 dxe4 20. Nxe4 Ra8 21. a4 Nf6 22. Bxh6 gxh6 23. Nxf6+ Kg7 24. Nh5+ Kh7 25. Nh4 Qe7 26. f4 f5 27. Kf3 Kh8 28. Rxe6 Qxe6 29. Ra2 Qg6 30. Ng7 Kxg7 31. Re2 Qg4+ 32. Kg2 Rfe8 33. Rxe8 Rxe8 34. Nxf5+ Kg6 35. h3 Kxf5 36. hxg4+ Kxg4 37. Kf2 Re3 38. Kg2 Rd3 39. f5 1-0",
    B: "1635",
  },
  {
    W: "1516",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nxd4 5. Qxd4 c5 6. Qe5+ Qe7 7. Qxe7+ Bxe7 8. Bd3 Nf6 9. O-O d6 10. Bg5 O-O 11. Nc3 h6 12. Bxf6 Bxf6 13. Nd5 Be6 14. Nxf6+ gxf6 15. f4 d5 16. e5 fxe5 17. fxe5 c4 18. Be2 b5 19. g4 Kh7 20. h4 Rg8 21. Rf4 h5 22. g5 Kg6 23. Rf6+ Kh7 24. Raf1 Rg6 25. Bxh5 Rxf6 26. Rxf6 Rg8 27. Kh2 a5 28. Kg3 b4 29. Bg4 Bxg4 30. Kxg4 Kg7 31. e6 fxe6 32. Rxe6 b3 33. axb3 cxb3 34. cxb3 Rb8 35. Rd6 Rxb3 36. Rxd5 Rxb2 37. Rxa5 Rb4+ 38. Kh5 Rb8 39. Ra7+ Kf8 40. Rh7 Rb6 41. g6 Rb5+ 42. Kh6 Rb4 43. h5 Rh4 44. Rf7+ Kg8 45. Rg7+ Kf8 46. Rh7 Ke8 47. Rh8+ Ke7 48. Kh7 Kf6 49. Kg8 Rxh5 50. g7 Rh6 51. Kf8 Rg6 0-1",
    B: "1498",
  },
  {
    W: "1343",
    T: "60+0",
    P: "1. d4 c5 2. dxc5 e6 3. b4 Bd6 4. cxd6 Qb6 5. c3 a5 6. Be3 axb4 7. Bxb6 b3 8. axb3 Rxa1 9. Qc2 Nc6 10. e3 Ra6 11. Bc5 Nf6 12. Bxa6 O-O 13. Bxb7 Bxb7 14. Qa2 Ra8 15. Qxa8+ Bxa8 16. Nf3 Na5 17. b4 Nb3 18. Nbd2 Nxc5 19. bxc5 Ne4 20. Nxe4 Bxe4 21. Ke2 Bc6 22. Nd4 Kf8 23. Nxc6 Ke8 24. Nd4 Kd8 25. Rb1 Kc8 26. Rb6 f5 27. Nb5 g6 28. Ra6 h5 29. Ra8+ Kb7 30. Rg8 Kc6 31. Nd4+ Kxc5 32. Rxg6 Kxd6 33. Rg7 e5 34. Nxf5+ Ke6 35. Nd4+ Kd5 36. Rxd7+ Kc5 37. Re7 exd4 38. cxd4+ Kd5 39. Re5+ Kd6 40. Rxh5 Ke6 41. Re5+ Kd6 42. f4 Kd7 43. f5 Kd6 44. f6 Kd7 45. f7 Kd6 46. f8=Q+ Kd7 47. Qf7+ Kd6 48. Re7 Kc6 49. Qf6+ Kd5 50. Rf7 Kc4 1/2-1/2",
    B: "1338",
  },
  {
    W: "1540",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O Bd6 6. h3 Nf6 7. d4 O-O 8. dxe5 Bxe5 9. Qxd8 Rxd8 10. Nxe5 Rd6 11. Bf4 Rd4 12. Nc3 Nxe4 13. Be3 Rc4 14. Nxc4 Nxc3 15. bxc3 Be6 16. Ne5 Rd8 17. Bg5 Rd5 18. Bf4 f6 19. Nf3 Rf5 20. Bxc7 Bd5 21. Nd4 Re5 22. Bxe5 fxe5 23. Nf5 Kf7 24. Rfe1 Ke6 25. Ne3 Bc4 26. Nxc4 b5 27. Nxe5 Kd6 28. Ng4 Kc5 29. Re7 Kb6 30. Rxg7 h6 31. Rh7 h5 32. Rxh5 Ka5 33. a3 Ka4 34. Rh6 Ka5 35. Rxc6 Ka4 36. Rh6 Ka5 37. Re6 Ka4 38. Rxa6# 1-0",
    B: "1554",
  },
  {
    W: "1989",
    T: "60+0",
    P: "1. d4 Nf6 2. Bg5 d6 3. Bxf6 gxf6 4. g3 e5 5. Bg2 Nc6 6. e3 h5 7. h4 f5 8. Ne2 f4 9. exf4 Bg4 10. dxe5 dxe5 11. Bxc6+ bxc6 12. Qxd8+ Rxd8 13. fxe5 Bg7 14. f4 f6 15. exf6 Bxf6 16. Nbc3 O-O 17. Rd1 Rde8 18. Kf2 Be7 19. Rhe1 Bc5+ 20. Kg2 Re3 21. Nc1 Bf3+ 22. Kh2 Rfe8 23. Rxe3 Rxe3 24. Nd3 Bxd1 25. Nxd1 Re2+ 26. Kh3 Rxc2 27. Nxc5 Rxc5 28. Nc3 1-0",
    B: "2016",
  },
  {
    W: "1255",
    T: "60+0",
    P: "1. e4 Nc6 2. f4 e5 3. fxe5 Nxe5 4. Nf3 Nxf3+ 5. Qxf3 d6 6. Bd3 Qe7 7. O-O Be6 8. Nc3 Qf6 9. Qxf6 Nxf6 10. Nd5 Nxd5 11. exd5 Bxd5 12. c4 Bc6 13. b4 a6 14. b5 axb5 15. cxb5 Bd5 16. a4 b6 17. Rf5 Be6 18. Rf4 Be7 19. Be4 Rc8 20. a5 O-O 21. axb6 cxb6 22. Ra6 Rb8 23. Ra8 Rxa8 24. Bxa8 Bg5 25. Rf2 Bd5 26. Bxd5 Bf6 27. Ba3 Bd4 28. Bxd6 Bxf2+ 29. Kxf2 Rd8 30. Bxf7+ Kxf7 31. Bc7 Rxd2+ 32. Ke3 Rb2 33. Bxb6 Rxb5 34. Bd4 Rb3+ 35. Kd2 g5 36. Kc2 Ra3 37. Kb2 Ra6 38. Bc5 Ra8 39. Ba3 Rb8+ 40. Kc3 Rb7 41. Bb4 g4 42. Bc5 g3 43. hxg3 1-0",
    B: "1191",
  },
  {
    W: "2236",
    T: "60+0",
    P: "1. Nf3 d5 2. g3 Nf6 3. Bg2 e6 4. O-O Be7 5. d3 O-O 6. Nbd2 c6 7. Qe1 b6 8. e4 Bb7 9. Nh4 dxe4 10. dxe4 c5 11. f4 Nc6 12. Ndf3 Nd4 13. e5 Nd5 14. Qf2 Bxh4 15. Nxh4 Rc8 16. c3 Nc6 17. f5 exf5 18. Nxf5 Nxe5 19. Bf4 Nxf4 20. Bxb7 Nfd3 21. Qg2 Rc7 22. Be4 g6 23. Nh6+ Kg7 24. Qh3 Qg5 25. Bxd3 Nxd3 26. Ng4 f5 27. Nf2 Qe3 28. Qh4 Re7 29. Rad1 c4 30. Kg2 Nxf2 31. Rxf2 Qe4+ 32. Qxe4 Rxe4 33. Rd7+ Rf7 34. Rfd2 Ree7 35. Rxe7 Rxe7 36. Rd4 Rc7 37. Kf3 Kf6 38. Rd6+ Ke5 39. Rd4 g5 40. h3 g4+ 41. hxg4 fxg4+ 42. Kxg4 Rg7+ 43. Kf3 Rc7 44. Kg4 Rg7+ 45. Kh4 Rc7 46. g4 Kf6 47. g5+ Kg6 48. Rd6+ Kg7 49. Kh5 Rc5 0-1",
    B: "2303",
  },
  {
    W: "2273",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. Nd2 Nf6 4. e5 Nfd7 5. c3 c5 6. f4 Nc6 7. Ndf3 a6 8. Ne2 b5 9. g3 Qb6 10. Bg2 f6 11. Be3 cxd4 12. Nexd4 Nxd4 13. Bxd4 Bc5 14. O-O fxe5 15. fxe5 O-O 16. Qd2 Bb7 17. Rae1 Rf5 18. Nh4 Rxf1+ 19. Bxf1 Rf8 20. Bh3 Bxd4+ 21. cxd4 a5 22. a3 b4 23. axb4 axb4 24. b3 Ba6 25. Ng2 h6 26. Nf4 g5 27. Nxe6 Rf3 28. Nxg5 hxg5 29. Qxg5+ Kf8 30. Qh6+ Ke8 31. Qh8+ Ke7 32. Bxd7 Kxd7 33. e6+ Ke7 34. Qg7+ Kd6 35. Qc7+ Qxc7 36. e7 Qxe7 37. Rxe7 Kxe7 38. Kg2 Rxb3 39. Kh3 Rc3 40. Kg4 b3 41. Kf4 b2 42. Ke5 b1=Q 43. Kxd5 Qb7+ 44. Ke5 Re3+ 45. Kf4 Qf3+ 46. Kg5 Bc8 47. d5 Qg4+ 48. Kh6 Re5 49. h3 Qh5+ 50. Kg7 Rg5# 0-1",
    B: "2246",
  },
  {
    W: "1782",
    T: "60+0",
    P: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. d4 Nf6 5. Bb5+ Nc6 6. Bd3 Bg4 7. c3 e6 8. O-O Be7 9. h3 Bh5 10. g4 Bg6 11. Ne5 Bxd3 12. Qxd3 Nxe5 13. dxe5 Nd7 14. f4 O-O 15. Kg2 Rc8 16. Be3 Qa5 17. Bd4 b5 18. Nd2 Nb6 19. Nb3 Qa6 20. Qe3 Nc4 21. Qd2 Nxd2 22. Nxd2 b4 23. Nb3 bxc3 24. bxc3 Qc4 25. Na5 Qe2+ 26. Rf2 Qe4+ 27. Kg1 h5 28. Nb3 hxg4 29. Nd2 Qd3 30. hxg4 Qg3+ 31. Rg2 Qd3 32. Rd1 Rxc3 33. Bxc3 Qxc3 34. Kf2 Bc5+ 35. Ke2 Qe3+ 36. Kf1 Qxf4+ 37. Nf3 Qxf3+ 38. Ke1 Qxg2 39. Rd2 Bb4 40. Kd1 Qxd2# 0-1",
    B: "1852",
  },
  {
    W: "2333",
    T: "180+0",
    P: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. Bg5 Be7 6. Bf4 g5 7. Bg3 g4 8. Ng1 h5 9. h4 Bf5 10. Nd2 Nb4 11. Qa4+ c6 12. Rc1 d3 13. e4 Nc2+ 14. Kd1 Be6 15. Rxc2 dxc2+ 16. Kxc2 Qd7 17. Ne2 O-O-O 18. Bf4 Qd3+ 19. Kc1 Kb8 20. Nc3 Qd4 21. Be3 Bc5 22. Bxd4 Bxd4 23. f3 Be3 24. Nb1 Ne7 25. Kc2 Ng6 26. Qa5 Bd4 27. fxg4 hxg4 28. Nb3 b6 29. Qe1 Bxe5 30. Nc3 f6 31. Bd3 Nf4 32. Be2 Nxg2 33. Qf2 Nf4 34. Na4 g3 35. Qg1 Nxe2 36. Qe3 Nf4 37. Nxb6 axb6 38. Qxb6+ Kc8 39. Na5 Bc7 40. Qb7+ Kd7 41. Qxc6+ Kc8 42. Qb7+ Kd7 43. Qc6+ Ke7 44. Qxc7+ Rd7 45. Qxf4 Rc8 46. c5 Rxc5+ 47. Kb1 Rxa5 48. h5 g2 49. Rg1 Rg5 50. h6 Rd1+ 51. Rxd1 g1=Q 52. Rxg1 Rxg1+ 53. Kc2 Rg2+ 0-1",
    B: "2299",
  },
  {
    W: "1257",
    T: "180+7",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Be6 4. Bxe6 fxe6 5. O-O Nc6 6. b3 Nf6 7. d3 d5 8. exd5 exd5 9. Re1 Qd7 10. Bb2 Bd6 11. Nxe5 Qe7 12. Nxc6 bxc6 13. Rxe7+ Kxe7 14. Qe1+ Kd8 15. Bxf6+ gxf6 16. Nc3 Re8 17. Qd2 Be5 18. Re1 d4 19. Ne4 Rf8 20. Nc5 f5 21. Ne6+ Ke8 22. Nxf8 Kxf8 23. Rxe5 1-0",
    B: "1132",
  },
  {
    W: "2193",
    T: "180+0",
    P: "1. e4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. Bc4 Nxe4 5. Bxf7+ Kxf7 6. Nxe4 d5 7. Nfg5+ Kg8 8. Qf3 Qe7 9. Qb3 Qd8 10. c3 h6 11. Nh3 Kh7 12. Ng3 Bc5 13. d3 Rf8 14. Be3 d4 15. cxd4 exd4 16. Bd2 Bxh3 17. gxh3 Qe7+ 18. Ne4 Bb6 19. O-O-O Rae8 20. Rde1 Qd7 21. Rhg1 Qxh3 22. Rg3 Qxh2 23. Reg1 Re7 24. Qd1 Qh4 25. Rg4 Qh3 26. R1g3 Qh2 27. Rg2 Qe5 28. Qg1 Rff7 29. f4 1-0",
    B: "1948",
  },
  {
    W: "1829",
    T: "180+0",
    P: "1. e4 g6 2. d4 Bg7 3. c3 d6 4. Nf3 Bg4 5. Ng1 Bxd1 6. Kxd1 e5 7. d5 c6 8. f4 cxd5 9. exd5 exf4 10. Bxf4 Be5 11. Be3 f5 12. Nf3 f4 13. Bf2 Nf6 14. Bb5+ Nbd7 15. Re1 O-O 16. Ng5 Nxd5 17. Ne6 Qe7 18. Bxd7 Qxd7 19. Nxf8 Rxf8 20. Nd2 Qf5 21. Ne4 f3 22. Kc2 fxg2 23. Bg1 Qf1 24. Rad1 Ne3+ 25. Rxe3 Qxd1+ 26. Kxd1 Rf1+ 27. Re1 1-0",
    B: "1786",
  },
  {
    W: "1393",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. Bxc6 dxc6 5. d3 Nf6 6. O-O Be6 7. Be3 b6 8. Bxc5 bxc5 9. c4 O-O 10. Qe2 Ng4 11. h3 Nh6 12. Nc3 f5 13. exf5 Bxf5 14. Rad1 Nf7 15. Nxe5 Nxe5 16. Qxe5 Bxd3 17. Qe3 Bxf1 18. Rxd8 Raxd8 19. Kxf1 Rd4 20. Qe6+ Kh8 21. Kg1 Rfd8 22. Qxc6 Rd2 23. Qxc5 Rxb2 24. Qxc7 Rdd2 1-0",
    B: "1331",
  },
  {
    W: "1837",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. e3 Bg7 5. Nf3 O-O 6. Bd3 b6 7. O-O Bb7 8. b4 c6 9. c5 Nbd7 10. a4 e5 11. dxe5 Ng4 12. cxb6 axb6 13. h3 Ngxe5 14. Nxe5 Nxe5 15. Bb2 Nxd3 16. Qxd3 d4 17. Ne2 dxe3 18. Qxe3 Bxb2 19. Rad1 Qe8 20. Qd2 Bg7 21. Ng3 Rd8 22. Qc2 Rxd1 23. Rxd1 Qe6 24. Nf1 Re8 25. Ne3 Qe4 26. Qa2 Qxb4 27. Rb1 Qa5 28. Qb3 Bd4 29. Nc4 Re1+ 30. Rxe1 Qxe1+ 31. Kh2 Qxf2 32. a5 b5 33. a6 Bxa6 34. Qa2 bxc4 35. Qxa6 Be5+ 0-1",
    B: "1883",
  },
  {
    W: "1937",
    T: "180+0",
    P: "1. e4 c6 2. d4 Qc7 3. Nc3 d6 4. d5 Nd7 5. Nf3 Ngf6 6. Bf4 e5 7. dxe6 fxe6 8. Bc4 b5 9. Bd3 b4 10. Ne2 e5 11. Be3 Be7 12. Ng3 O-O 13. O-O Nb6 14. b3 h6 15. Qd2 Kh7 16. Nh4 d5 17. exd5+ Kg8 18. Ng6 Rd8 19. Bxh6 Nbxd5 20. Nxe7+ Qxe7 21. Bg5 Be6 22. Bf5 Qf7 23. Bxe6 Qxe6 24. Rad1 Rd7 25. Bxf6 Qxf6 26. Ne4 Qg6 27. f3 Rad8 28. Qg5 Qf7 29. Qxe5 Ne3 30. Rxd7 Qxd7 31. Ng5 Nxf1 32. Kxf1 Qd1+ 33. Kf2 Qd4+ 34. Qxd4 Rxd4 35. Ke2 Rd5 36. Ne4 Kf8 37. Nd2 Ke7 38. Nc4 Kf6 39. g3 g5 40. h3 c5 41. f4 gxf4 42. gxf4 Rh5 0-1",
    B: "1907",
  },
  {
    W: "2071",
    T: "180+0",
    P: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 dxe4 5. Nxe4 Be7 6. Bxf6 gxf6 7. c3 f5 8. Ng3 Bd7 9. Nf3 Bc6 10. Bd3 Nd7 11. O-O Nf6 12. Ne5 h5 13. Nxc6 bxc6 14. Qa4 Qd7 15. h4 Ng4 16. Be2 Bxh4 17. Bf3 Ke7 18. Bxc6 Qd6 19. Bxa8 Bxg3 20. fxg3 Qxg3 21. Rf3 Qh2+ 22. Kf1 Qh1+ 23. Ke2 Qxa1 24. Qb4+ Kf6 25. Bc6 Qg1 26. Rg3 Qf2+ 27. Kd1 Ne3+ 28. Rxe3 Qxe3 29. d5 Qg1+ 30. Kc2 Qxg2+ 31. Kb3 e5 32. Qh4+ Qg5 33. Qb4 Qf4 34. Qc5 Rb8+ 35. Bb5 e4 36. Qc6+ Qd6 37. Qc4 a6 38. Qd4+ Ke7 39. a4 axb5 40. axb5 Rxb5+ 41. Kc2 Qh2+ 42. Kb1 Qxb2# 0-1",
    B: "2066",
  },
  {
    W: "1422",
    T: "180+0",
    P: "1. d4 d5 2. c4 dxc4 3. Nc3 Nc6 4. Nf3 Bg4 5. Bf4 Bxf3 6. exf3 e6 7. Bxc4 Bb4 8. O-O Bxc3 9. bxc3 Na5 10. Bd3 a6 11. Re1 b5 12. Be4 Rb8 13. c4 Nxc4 14. d5 Nb2 15. Qb3 Nc4 16. dxe6 fxe6 17. Rad1 Qe7 18. Rc1 Nf6 19. Rxc4 bxc4 20. Qxb8+ Kf7 21. Qxh8 Nxe4 22. Rxe4 h6 23. Qh7 Qa3 24. h3 Qxa2 25. Bxh6 Qa1+ 26. Kh2 a5 27. Rf4+ Ke7 28. Bg5+ Kd6 29. Rd4+ Qxd4 30. Qh4 Qxh4 31. Bxh4 c3 32. Bg5 c2 33. Kg3 a4 34. Bc1 c5 35. f4 c4 36. Kf3 Kd5 37. Ke3 Kc5 38. Kd2 Kb4 39. Kxc2 a3 40. Kb1 Kb3 41. g4 c3 42. f5 exf5 43. gxf5 c2+ 44. Ka1 Kc4 45. Ka2 Kd5 46. Kxa3 Ke5 47. Kb3 Kxf5 48. Kxc2 Ke4 49. Be3 Kf3 50. h4 Kg4 51. Kc3 Kxh4 52. Kd4 Kg4 53. Ke5 g5 54. Ke6 Kf3 55. Kf5 g4 56. Kg5 Ke4 57. Kxg4 Kd5 58. f4 Ke6 59. Kg5 Kf7 60. f5 Ke7 1/2-1/2",
    B: "1451",
  },
  {
    W: "1497",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. d4 Qe6+ 4. Ne2 Qg6 5. c3 Nc6 6. Ng3 Bg4 7. Be2 Bxe2 8. Qxe2 O-O-O 9. O-O e6 10. Nd2 Bd6 11. Nc4 Be7 12. Bf4 Qf5 13. Qe3 h6 14. Ne2 Nf6 15. Bg3 a6 16. Nc1 b5 17. Ne5 Nd5 18. Nxc6 Nxe3 19. Nxe7+ 1-0",
    B: "1534",
  },
  {
    W: "1615",
    T: "180+0",
    P: "1. e4 e5 2. d3 Nf6 3. Nf3 Nc6 4. Be2 Bc5 5. h3 d6 6. a3 O-O 7. Bd2 Be6 8. Nc3 Qd7 9. Ng5 h6 10. Nxe6 fxe6 11. Bf3 Nd4 12. Nb1 Nxf3+ 13. Qxf3 Nd5 14. exd5 Rxf3 15. dxe6 Bxf2+ 16. Kd1 Qxe6 17. gxf3 Qf5 18. Rf1 Qxf3+ 19. Kc1 Qe2 20. Rd1 Rf8 21. Nc3 Qh5 22. b3 Qxh3 23. Kb2 Bd4 24. Ka2 Bxc3 25. Bxc3 Qh2 26. Kb2 c5 27. Ba5 b6 28. Be1 d5 29. b4 cxb4 30. Bxb4 Rb8 31. Bd6 Rc8 32. Rac1 Qf4 33. Bb4 a5 34. Bd2 Qd4+ 35. c3 Qc5 36. Be1 Qb5+ 37. Ka2 d4 38. Rb1 Qd5+ 39. Rb3 dxc3 40. Bxc3 a4 41. Bxe5 Qxb3+ 42. Ka1 Qc2 43. Rb1 Qxd3 44. Bb2 Rc2 45. Bxg7 Qd2 46. Rb2 Rxb2 47. Bxb2 Qd5 48. Bc1 Qe4 49. Bb2 Qe1+ 50. Ka2 Qe6+ 51. Kb1 Qc4 52. Bf6 Qf1+ 53. Kc2 Qxf6 54. Kd3 Qd6+ 55. Kc4 Kf7 56. Kb5 Ke6 57. Kxa4 Kd5 58. Kb3 Qc5 59. a4 b5 60. axb5 Qxb5+ 61. Kc3 Qc5+ 62. Kd3 Ke5 63. Ke2 Qc3 64. Kf2 Kd4 65. Ke2 Qd3+ 66. Kf2 Qe3+ 67. Kg2 Qg5+ 68. Kf3 Qe5 69. Kg4 Qf6 70. Kh5 Ke5 71. Kg4 Qf5+ 72. Kg3 Qf4+ 73. Kg2 Ke4 74. Kh3 Qg5 75. Kh2 Kf4 76. Kh1 Kf3 77. Kh2 Qg2# 0-1",
    B: "1573",
  },
  {
    W: "1126",
    T: "180+0",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bf4 Nc6 5. e3 Bd6 6. Bxd6 Qxd6 7. cxd5 exd5 8. Bb5 Qe6 9. Nf3 O-O 10. O-O Ne4 11. Nxe4 Qxe4 12. Qa4 Qg6 13. Ne5 Qf6 14. Bxc6 bxc6 15. Nxc6 Bd7 16. Qc2 Bxc6 17. Rac1 Bb5 18. Rfd1 Qa6 19. Qxc7 Rfc8 20. Qg3 Qxa2 21. Rxc8+ Rxc8 22. Qd6 Qxb2 23. Qxd5 Bc6 24. Qc5 Qb7 25. g3 Bd7 26. Qe5 a5 27. Qxa5 Qd5 28. Qxd5 1-0",
    B: "1097",
  },
  {
    W: "1957",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. c3 Nf6 4. d4 exd4 5. e5 Nd5 6. cxd4 d6 7. Bb5 Bd7 8. Bg5 Be7 9. Bxc6 Bxc6 10. Bxe7 Qxe7 11. Qd3 dxe5 12. dxe5 O-O-O 13. O-O Nf4 14. b3 Nxd3 15. Nd4 Qg5 0-1",
    B: "1998",
  },
  {
    W: "1685",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. d3 e6 4. g3 Nf6 5. Bg2 Be7 6. O-O O-O 7. Nbd2 a6 8. Re1 b5 9. c3 Bb7 10. Nb3 Nc6 11. d4 c4 12. Nbd2 d5 13. e5 Nd7 14. b3 Re8 15. bxc4 bxc4 16. Nb1 Nf8 17. h4 Ng6 18. Nh2 h6 19. h5 Nf8 20. Qg4 Kh8 21. Nf3 f5 22. Qf4 Nh7 23. Bh3 Ng5 24. Nxg5 Bxg5 25. Qf3 Bxc1 26. Rxc1 Qe7 27. Nd2 Rf8 28. Kg2 Nd8 29. Rh1 Nf7 30. Kg1 Ng5 31. Qe3 Bc6 32. Nf3 Ne4 33. Nd2 Rab8 34. Nxe4 fxe4 35. Bf1 Rb2 36. Be2 Qa3 37. g4 Rxa2 38. Rxa2 Qxa2 39. g5 Kh7 40. gxh6 gxh6 41. Rh4 Qb1+ 42. Kh2 Qb8 43. Rf4 Rg8 44. Rf7+ Rg7 45. Rxg7+ Kxg7 46. Qg3+ Kh8 47. Qh4 Qg8 48. Qe7 Ba4 49. Bg4 Qxg4 50. Qxe6 Qxe6 0-1",
    B: "1706",
  },
  {
    W: "1928",
    T: "180+0",
    P: "1. c4 Nf6 2. Nc3 g6 3. d4 d5 4. Bg5 c6 5. Bxf6 exf6 6. e3 Bg7 7. cxd5 cxd5 8. Nf3 O-O 9. Be2 Nc6 10. O-O f5 11. Qb3 f4 12. Qxd5 fxe3 13. fxe3 Qe7 14. e4 Rd8 15. Qc5 Qxc5 16. dxc5 Nd4 17. Rfe1 Nxf3+ 18. Bxf3 Bd4+ 19. Kh1 Bxc5 20. Nd5 Be6 21. Nc7 Rac8 22. Nxe6 fxe6 23. Bg4 Rc6 24. Rad1 Rcd6 25. Rxd6 Rxd6 26. Rd1 Rxd1+ 27. Bxd1 e5 28. a4 a5 29. b3 Kf7 30. Be2 Ke7 31. Bc4 Bd4 32. Bd5 b6 33. h3 h6 34. Kh2 g5 35. Kg3 Kf6 36. Kg4 Be3 37. Kf3 Bd2 38. g4 Ke7 39. Ke2 Bf4 40. Kd3 Kd6 41. Kc4 Be3 42. Kd3 Bf2 43. Kc4 Bg1 44. Kd3 Kc5 45. Ke2 b5 46. axb5 Kxb5 47. Kf1 Be3 48. Ke2 Bf4 49. Kf3 Kb4 50. Kg2 Kc3 51. Kf1 Kd4 52. Kg2 Ke3 53. Bb7 Kd4 54. Bd5 Bd2 55. Bb7 Ke3 56. Bd5 Kf4 57. Kh2 Kf3 58. Kh1 Kg3 59. Be6 Kxh3 60. Kg1 Kh4 61. Kf1 h5 62. Kg2 hxg4 63. Bf5 Be1 64. Kf1 Bd2 65. Kg2 g3 66. Be6 Be1 67. Bd5 Kg4 68. Be6+ Kf4 69. Bd5 Ke3 70. Bc6 Kd4 71. Bd5 Kc3 72. Kf1 Bf2 73. Kg2 Kb4 74. Be6 a4 75. bxa4 Kxa4 76. Bf5 Kb4 77. Kf1 Kc4 78. Kg2 Kd4 79. Kf1 Ke3 80. Kg2 Kf4 81. Bg6 Kg4 82. Bf5+ Kf4 83. Bh7 Kg4 84. Bf5+ Kf4 1/2-1/2",
    B: "2183",
  },
  {
    W: "1981",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Nf6 3. Nc3 Nxd5 4. Bc4 Nb6 5. Bb3 g6 6. Nf3 Bg7 7. Ng5 e6 8. d3 O-O 9. Qf3 Nc6 10. Qh3 h6 11. Nf3 e5 12. Qg3 Kh7 13. O-O f5 14. Nh4 g5 15. Nf3 f4 16. Nxg5+ hxg5 17. Qf3 g4 18. Qe2 Nd4 19. Qe1 Qg5 20. Ne4 Qg6 21. c3 Nxb3 22. axb3 Bf5 23. f3 Nd5 24. Qh4+ Bh6 25. c4 gxf3 26. Rxf3 Rg8 27. Qf2 Bxe4 28. dxe4 Nf6 29. Rh3 Nxe4 30. Qe2 Raf8 31. Bd2 Nxd2 32. Qxd2 f3 33. Rxh6+ Qxh6 34. Qxh6+ Kxh6 35. g3 e4 36. Kf2 a6 37. Re1 Re8 38. Re3 Rg6 39. h4 Rd6 40. g4 Rd2+ 41. Kg3 Rg2+ 42. Kf4 f2 43. g5+ Kg6 44. h5+ Kf7 45. g6+ Kf6 46. Re1 fxe1=Q 47. g7 Qf2# 0-1",
    B: "1960",
  },
  {
    W: "1838",
    T: "180+0",
    P: "1. d4 f5 2. c4 Nf6 3. Nh3 e6 4. g3 Be7 5. Bg2 O-O 6. O-O c6 7. Nc3 d5 8. cxd5 cxd5 9. Nf4 Nc6 10. a3 Ne4 11. Be3 a6 12. Rc1 Bd7 13. f3 Nf6 14. b4 h6 15. Na4 g5 16. Nd3 f4 17. Bf2 Rc8 18. Nac5 Bxc5 19. bxc5 Re8 20. e3 Nh5 21. g4 Nf6 22. exf4 gxf4 23. Nxf4 Re7 24. Re1 Rg7 25. Nxe6 Bxe6 26. Rxe6 Re7 27. Qe2 Rxe6 28. Qxe6+ Kg7 29. Re1 Qd7 30. Qxd7+ Nxd7 31. f4 Nf6 32. g5 hxg5 33. fxg5 Ng4 34. Bxd5 Nxf2 35. Kxf2 Nxd4 36. Re7+ Kg6 37. Rxb7 Kxg5 38. c6 Nf5 39. Ra7 Rf8 40. Bf3 Nd4 41. c7 Nxf3 42. Kg3 Ne5 43. Rxa6 Rc8 44. Ra5 Kf6 45. Rc5 Ke6 46. a4 Nd7 47. Rc1 Nb6 48. a5 Nd5 49. a6 Nb6 50. a7 Na8 51. h4 Kd7 52. Kg4 Nxc7 53. Kg5 Ra8 54. Ra1 Nb5 55. h5 Nd6 56. h6 Ke7 57. Kg6 Nf7 0-1",
    B: "1454",
  },
  {
    W: "2136",
    T: "180+0",
    P: "1. d4 Nf6 2. Nf3 d5 3. Bf4 Nc6 4. e3 e6 5. h3 Bd6 6. Bxd6 Qxd6 7. Nbd2 O-O 8. Bd3 Bd7 9. c3 Rfe8 10. e4 dxe4 11. Nxe4 Nxe4 12. Bxe4 e5 13. dxe5 Qxd1+ 14. Rxd1 Nxe5 15. Nxe5 Rxe5 16. Rxd7 Rxe4+ 17. Kf1 Rae8 18. Kg1 Re2 19. Kh2 Rxb2 20. Rhd1 h6 21. Rxc7 Ree2 22. Rd8+ Kh7 23. Rxf7 Rxa2 24. Rdd7 Rxf2 25. Rxg7+ Kh8 26. Rg6 Ra6 27. Rxa6 bxa6 28. Rxa7 Rc2 29. Rxa6 Kh7 30. Ra3 h5 31. Kg3 Kg6 32. h4 Kf5 33. Ra5+ Ke4 34. Rxh5 Rxc3+ 35. Kg4 Rc2 36. g3 Rc7 37. Rg5 Rc4 38. h5 Ke3+ 39. Kh3 Kf3 40. h6 Rc1 41. Rf5+ Ke4 42. Rh5 Kf3 43. Rf5+ Ke4 44. Rf4+ Ke5 45. Kg4 Rc7 46. Kh5 Rh7 47. Rf8 Ke6 48. g4 Ke7 49. Rf1 Ke6 50. g5 Rd7 51. Kg6 Rd8 52. h7 Rh8 53. Kh6 1-0",
    B: "1765",
  },
  {
    W: "1497",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 e6 3. a3 d5 4. e5 d4 5. Bb5+ Bd7 6. Qe2 Nc6 7. d3 a6 8. Bc4 b5 9. Ba2 Be7 10. h3 Nh6 11. Bxh6 gxh6 12. Nh2 Bg5 13. Ng4 Qe7 14. Ne3 Bxe3 15. fxe3 O-O-O 16. exd4 Qh4+ 17. Qf2 Qxd4 18. Qxd4 Nxd4 19. Kd2 Rhg8 20. g4 h5 21. Rg1 hxg4 22. hxg4 Nf3+ 23. Ke2 Nxg1+ 24. Kf2 Nh3+ 25. Kg3 Ng5 26. Kf4 h6 27. Nd2 Nh3+ 28. Kg3 Ng5 29. Kh4 Bc6 30. Rf1 Rd4 31. Rf6 Rxg4+ 32. Kxg4 c4 33. dxc4 bxc4 34. Bxc4 Bd5 35. Bxd5 exd5 36. Rxa6 Ne4+ 37. Kf5 Nxd2 38. Ra8+ Kd7 39. Rxg8 Nf3 40. Rg7 Nh4+ 41. Kg4 Ng2 42. Rxf7+ Ke6 0-1",
    B: "1590",
  },
  {
    W: "1674",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Nf6 4. d4 exd4 5. Qxd4 h5 6. e5 Nc6 7. Bb5 dxe5 8. Qxd8+ Kxd8 9. Bxc6 bxc6 10. Nxe5 Be6 11. Nxc6+ Kc8 12. Bf4 Bd6 13. Bxd6 cxd6 14. Nc3 Re8 15. O-O-O Bd5 16. Nxd5 Nxd5 17. Rxd5 Kc7 18. Nb4 Rab8 19. c3 Re2 20. Na6+ Kc8 21. Nxb8 1-0",
    B: "1627",
  },
  {
    W: "1673",
    T: "60+0",
    P: "1. d4 c6 2. c4 d5 3. cxd5 cxd5 4. Nc3 Bf5 5. Be3 Nf6 6. Qd2 e6 7. Bg5 Be7 8. h4 Nc6 9. Nf3 Qd7 10. Ne5 Nxe5 11. dxe5 Ne4 12. Nxe4 Bxe4 13. Bxe7 Qxe7 14. f3 Bg6 15. g4 h5 16. Bh3 a6 17. Rd1 Qc5 18. b3 Qa3 19. e4 Qb4 20. Qxb4 1-0",
    B: "1668",
  },
  {
    W: "1763",
    T: "900+0",
    P: "1. d4 f5 2. Nf3 Nf6 3. h3 e6 4. c3 d5 5. Bg5 Bd6 6. e3 O-O 7. Nbd2 Qe8 8. Bd3 Ne4 9. Bf4 Nc6 10. h4 Qh5 11. Be2 Qg6 12. g3 h6 13. c4 Bd7 14. c5 Bxf4 15. exf4 Qf6 16. Bb5 Rad8 17. Bxc6 Bxc6 18. Ne5 Bb5 19. a4 Be8 20. b4 b6 21. Nb3 Qe7 22. Qd3 Rb8 23. f3 Nxg3 24. Rh3 Nh5 25. Qd2 Kh7 26. a5 Bb5 27. a6 Qe8 28. Rh2 Qc8 29. Kf2 Bxa6 30. Rg1 Bc4 31. Nc1 a5 32. Ne2 Bxe2 33. Qxe2 bxc5 34. bxc5 c6 35. Kg2 Nxf4+ 36. Kh1 Nxe2 37. Rxe2 Qd8 38. Reg2 Qxh4+ 39. Rh2 Qf4 40. Rhg2 Rb7 41. Ng6 Rff7 42. Nxf4 Rb3 43. Nxe6 Rxf3 44. Rxg7+ Rxg7 45. Rxg7+ Kh8 46. Rg6 Kh7 47. Nf8+ Kh8 48. Rxh6+ Kg8 0-1",
    B: "2110",
  },
  {
    W: "1990",
    T: "60+0",
    P: "1. Nf3 d6 2. Ng5 Nf6 3. Nxf7 Kxf7 4. Nc3 h6 5. e4 g5 6. Bc4+ e6 7. d4 Bg7 8. O-O Rf8 9. f4 gxf4 10. Bxf4 Nc6 11. Be3 Ke7 12. Bd3 e5 13. d5 Nd4 14. Bxd4 exd4 15. Ne2 Bg4 16. Qd2 Bxe2 17. Qxe2 Qd7 18. e5 dxe5 19. Qxe5+ Kd8 20. Rae1 Re8 21. Qxd4 c6 22. d6 Nh5 23. Rxe8+ Qxe8 24. Qe4 Nf6 25. Qh4 Kd7 26. Bf5+ Kxd6 27. Rd1+ Kc7 28. Qf4+ Kb6 29. Qb4+ Kc7 30. Qd6+ Kb6 31. a4 a5 32. Qd4+ Ka6 33. Bd3+ b5 34. axb5+ cxb5 35. Qd6+ Qc6 36. Qxc6+ Ka7 37. Bxb5 Rb8 38. Qc7+ Rb7 39. Qxb7+ Kxb7 40. Rd7+ Kb6 41. Rxg7 Kc5 42. Rf7 Kb4 43. Rxf6 Kc5 44. Rxh6 Kb4 45. Rh5 a4 46. Ba6 a3 0-1",
    B: "2119",
  },
  {
    W: "1590",
    T: "60+0",
    P: "1. d4 Nf6 2. Nf3 e6 3. Bf4 d5 4. e3 Bd6 5. Bg3 O-O 6. Nbd2 b6 7. Bd3 Bb7 8. c3 Nbd7 9. Ne5 Ne4 10. Nxe4 dxe4 11. Bc2 f6 12. Nc4 Bxg3 13. hxg3 Ba6 14. Nd2 f5 15. g4 Nf6 16. gxf5 exf5 17. b4 c5 18. Bb3+ Kh8 19. a4 cxb4 20. cxb4 Bd3 21. Bc4 Rc8 22. Bxd3 exd3 23. Nf3 1-0",
    B: "1607",
  },
  {
    W: "1144",
    T: "60+0",
    P: "1. e4 Nc6 2. Nf3 b5 3. d4 Rb8 4. d5 a5 5. dxc6 dxc6 6. Nc3 b4 7. Ne2 Qxd1+ 8. Kxd1 c5 9. Bd2 Rb6 10. c3 Rd6 11. cxb4 Rxd2+ 12. Nxd2 cxb4 13. Ng3 Nf6 14. Nf3 Bb7 15. Bd3 Nxe4 16. Nxe4 Bxe4 17. Bxe4 e5 18. Nxe5 f5 19. Bxf5 g6 20. Be6 Rg8 21. Bxg8 Bg7 22. Ke2 Kf8 23. Bd5 Ke7 24. Rhe1 Bxe5 25. Kf1 g5 26. Rxe5+ Kf6 27. Rae1 Kg6 28. Re7 c6 29. Bxc6 g4 30. g3 Kg5 31. Ra7 Kf5 32. Rxa5+ Kf6 33. Rb5 h5 34. Rxh5 Kg6 35. Rb5 Kg7 36. Rxb4 Kh6 37. a4 Kg6 38. a5 Kg7 39. a6 Kf6 40. a7 Kf7 41. a8=Q Kg6 42. Bf3 Kf6 43. Qa5 Kg6 44. Rb6+ Kg7 45. Qa7+ Kf8 46. Rb8# 1-0",
    B: "890",
  },
  {
    W: "1242",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Bg4 4. d3 Bxf3 5. gxf3 Nf6 6. Nc3 Nbd7 7. d4 exd4 8. Qxd4 Nc5 9. e5 dxe5 10. Qxe5+ Qe7 11. Qxe7+ Bxe7 12. O-O Ncd7 13. Bg5 Bd6 14. Rfe1+ Kd8 15. Nd5 c6 16. Nxf6 gxf6 17. Bh4 Kc7 18. Bxf7 Rhf8 19. Bh5 Ne5 20. Bg3 Nc4 21. Bxd6+ Kxd6 22. Bg4 Kc5 23. Re4 Nd2 24. c4 Nxe4 25. fxe4 Kxc4 26. Be6+ Kb5 27. e5 c5 0-1",
    B: "1279",
  },
  {
    W: "1749",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bc4 e6 4. a3 a6 5. b4 b5 6. Be2 c4 7. d3 c3 8. Nxc3 Be7 9. O-O Bf6 10. Bd2 Bxc3 11. Bxc3 f6 12. Qb1 e5 13. Qb2 Nge7 14. Nd2 O-O 15. f4 Nd4 16. Bd1 Nec6 17. fxe5 fxe5 18. Rxf8+ Qxf8 19. Bf3 Nxf3+ 20. Nxf3 d6 21. Rf1 Qe7 22. h3 h6 23. Qc1 Be6 24. Nh2 Rf8 25. Rxf8+ Qxf8 26. Qb2 Qe7 27. Nf3 g5 28. Nxe5 Nxe5 29. Bxe5 dxe5 30. Qxe5 Qf7 31. Qb8+ Kg7 32. Qe5+ Qf6 33. Qc7+ Kg6 34. Qg3 Qf4 35. Qe1 Ba2 36. Qc3 Be6 37. e5 Qc1+ 38. Kh2 Qf4+ 39. Kg1 g4 0-1",
    B: "1832",
  },
  {
    W: "1534",
    T: "60+0",
    P: "1. d4 b6 2. Nc3 Bb7 3. e4 Nf6 4. f3 e6 5. Bc4 Bb4 6. Bd2 d6 7. Nge2 Nbd7 8. O-O O-O 9. a3 Bxc3 10. bxc3 d5 11. exd5 exd5 12. Bb3 c5 13. dxc5 Nxc5 14. Nd4 Qc7 15. Ne2 g5 16. Bf4 gxf4 0-1",
    B: "1543",
  },
  {
    W: "2288",
    T: "60+0",
    P: "1. Nf3 Nf6 2. g3 e6 3. Bg2 d5 4. O-O Be7 5. d3 O-O 6. c3 c5 7. Nbd2 Nc6 8. e4 b5 9. exd5 exd5 10. Re1 h6 11. a4 bxa4 12. Rxa4 Be6 13. d4 c4 14. Ne5 Nxe5 15. dxe5 Nd7 16. Nf3 Nc5 17. Ra2 Nd3 18. Re2 Qb6 19. Be3 Qb7 20. Bd4 Bc5 21. Bxc5 Nxc5 22. Nd4 Nd3 23. Rd2 a5 24. b3 cxb3 25. Ra1 Nc5 26. Nxe6 fxe6 27. Bh3 Qf7 28. Bg2 a4 29. c4 dxc4 30. Bxa8 Rxa8 31. Rd8+ Rxd8 32. Qxd8+ Kh7 33. Qd4 Nd3 34. Qxc4 Qxf2+ 35. Kh1 Qf3+ 36. Kg1 Qe3+ 37. Kh1 Nf2+ 38. Kg1 Ng4+ 39. Kh1 Qf3+ 40. Kg1 Qf2+ 41. Kh1 Qxh2# 0-1",
    B: "2281",
  },
  {
    W: "1169",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 d6 3. c3 Nf6 4. d3 e6 5. Nbd2 Be7 6. d4 a6 7. d5 e5 8. c4 Nc6 9. Bd3 Nd4 10. Nxd4 cxd4 11. Nb3 O-O 12. Bg5 b5 13. Nd2 bxc4 14. Nxc4 a5 15. b3 Ba6 16. O-O a4 17. b4 Bxc4 18. Bxc4 Nxe4 19. Bxe7 Qxe7 20. a3 Nc3 21. Qc2 f6 22. b5 Rab8 23. Qb2 Nxb5 24. Bxb5 Rxb5 25. Qxb5 Qf7 26. Qxa4 Qxd5 27. Rad1 d3 0-1",
    B: "1163",
  },
  {
    W: "1619",
    T: "60+0",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7 5. e4 Nxe4 6. Bxe7 Qxe7 7. Nxe4 dxe4 8. d5 exd5 9. cxd5 f5 10. Ne2 O-O 11. Nc3 Nd7 12. Bc4 Kh8 13. O-O Nb6 14. Bb3 Bd7 15. a3 Rad8 16. Re1 c6 17. f3 cxd5 18. fxe4 dxe4 19. Nd5 Nxd5 20. Qxd5 Bc6 21. Qxf5 Rxf5 22. h3 Rdf8 23. Rad1 Qg5 24. Rd4 Rf2 25. Ba4 Qxg2# 0-1",
    B: "1680",
  },
  {
    W: "1967",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. exd5 exd5 4. c4 Nf6 5. Nc3 Be7 6. cxd5 O-O 7. Bc4 Nbd7 8. Nf3 Nb6 9. Bb3 Nbxd5 10. Nxd5 Nxd5 11. O-O c6 12. Re1 Bg4 13. Qd3 Re8 14. Bc2 g6 15. Bh6 Bf5 16. Qe2 Bxc2 17. Qxc2 Bf6 18. Ne5 Bg7 19. Bxg7 Kxg7 20. Qe4 Qb6 21. Qf3 Rf8 22. Ng4 Qxd4 23. Qh3 h5 24. Ne5 Qxb2 25. Rab1 Qxa2 26. Rxb7 a5 27. Qg3 Nf6 28. Rxf7+ Rxf7 29. Qxg6+ Kf8 30. Qxf7+ Qxf7 31. Nxf7 Kxf7 32. g4 a4 33. g5 Nd5 34. g6+ Kxg6 35. Rd1 a3 36. Rc1 Kf5 37. Ra1 Ke4 38. Kg2 Nc3 39. Kh3 a2 40. Kh4 Ne2 41. Kxh5 Nf4+ 42. Kg5 Nd3 43. h4 c5 44. h5 c4 45. h6 c3 46. h7 c2 47. h8=Q Rxh8 48. Kg6 Rg8+ 0-1",
    B: "2011",
  },
  {
    W: "1606",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 e6 4. Nc3 Nc6 5. d4 cxd4 6. Nxd4 a6 7. a4 Be7 8. O-O Nf6 9. Nxc6 bxc6 10. Qf3 Qc7 11. Bd3 e5 12. Bc4 Bg4 13. Qg3 Be6 14. Bxe6 fxe6 15. Qxg7 Rg8 16. Qh6 O-O-O 17. Qh3 Qd7 18. Be3 Rg7 19. Bb6 Rdg8 20. g3 d5 21. exd5 exd5 22. Qxd7+ Kxd7 23. a5 c5 24. Na4 c4 25. Rfe1 Bd6 26. Rad1 h5 27. Nc5+ Kc6 28. Nb7 Rxb7 29. Rd2 Rbg7 30. Red1 h4 31. b3 hxg3 32. fxg3 d4 33. bxc4 Ne4 34. Rd3 Nxg3 35. hxg3 Rxg3+ 0-1",
    B: "1585",
  },
  {
    W: "1788",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 a6 5. Nc3 Nc6 6. Be2 Bd7 7. Be3 e6 8. Qd2 Qb6 9. Nxe6 Qxb2 10. Rb1 Qa3 11. Nxg7+ Bxg7 12. Nd5 Qxa2 13. O-O Rc8 14. Bb6 Kf8 15. Nc3 Qa3 16. Rb3 Bxc3 17. Rxc3 Qb4 18. Qe3 Nge7 19. Bd4 Nxd4 20. Rc4 Nxc2 21. Rxb4 Nxe3 22. fxe3 Rg8 23. Rxb7 Bc6 24. Rb6 Bxe4 25. g3 Nf5 26. Rxa6 Nxe3 27. Rf4 Rc1+ 28. Kf2 Nd5 29. Rxe4 Nc3 30. Re3 Nd1+ 31. Kf3 Nxe3 32. Kxe3 Kg7 33. Rxd6 Re8+ 34. Kf2 Rc2 0-1",
    B: "1786",
  },
  {
    W: "2165",
    T: "300+0",
    P: "1. d4 d5 2. e3 Nf6 3. Bd3 b6 4. Nd2 Bb7 5. Qe2 e6 6. c3 c5 7. a3 c4 8. Bc2 Bd6 9. e4 dxe4 10. Nxe4 Nxe4 11. Bxe4 Bxe4 12. Qxe4 Nd7 13. f4 O-O 14. Nf3 Re8 15. O-O Nf6 16. Qe2 b5 17. Ne5 Bc7 18. Bd2 Bb6 19. Rae1 Nd5 20. Kh1 f6 21. Nf3 Qd6 22. Qe4 Rad8 23. Re2 h6 24. Nh4 f5 25. Qf3 Nf6 26. Ng6 Ne4 27. Ne5 Bc7 28. Rfe1 Qd5 29. h3 Bxe5 30. fxe5 Rf8 31. Kh2 Kh7 32. g4 g5 33. Rf1 Nxd2 34. Qxd5 Rxd5 35. Rxd2 f4 36. Kg2 Kg6 37. Kf3 a5 38. Ke4 a4 39. Rh2 Rh8 40. h4 Rh7 41. hxg5 hxg5 42. Rxh7 Kxh7 43. Rh1+ Kg7 44. Rh5 Kg6 45. Rh8 Rd7 46. Re8 Kf7 47. Rb8 Rd5 48. Rb7+ Kg6 49. Re7 Rd8 50. Rxe6+ Kf7 51. Rf6+ Ke7 52. d5 Rh8 53. d6+ Kd7 54. Kd5 Rh1 55. e6+ Kc8 56. Kc6 Kb8 57. d7 Rd1 58. e7 f3 59. d8=Q+ Rxd8 60. exd8=Q+ Ka7 61. Rf7+ 1-0",
    B: "1980",
  },
  {
    W: "1399",
    T: "300+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. f4 f6 4. Nf3 d6 5. Be2 e5 6. f5 g6 7. g4 Nce7 8. d3 h5 9. g5 fxg5 10. Bxg5 gxf5 11. exf5 Bxf5 12. Nd5 Bh6 13. Nf6+ Nxf6 14. Bxf6 Rg8 15. Nxe5 dxe5 16. Bxh5+ Kf8 17. Qf3 Qd7 18. Bxe7+ Kxe7 19. Ke2 Raf8 0-1",
    B: "1442",
  },
  {
    W: "1513",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 Nf6 4. Nc3 Bg4 5. O-O a6 6. h3 Bh5 7. Re1 b5 8. Be2 Nc6 9. d3 e6 10. a3 Be7 11. Bf4 g5 12. Nxg5 Bxe2 13. Qxe2 Rg8 14. Nf3 Nh5 15. Bh2 e5 16. Nd5 Qd7 17. Nxe7 Kxe7 18. Nh4 Qxh3 19. Nf5+ Kd7 20. Bg3 Nxg3 21. Nxg3 Rxg3 22. fxg3 Qxg3 23. Qf3 Qxf3 24. gxf3 Nd4 25. Re2 Nxf3+ 26. Kf2 Nd4 27. Rd2 Rg8 28. c3 Nb3 29. Rdd1 Nxa1 30. Rxa1 Ke6 31. b4 cxb4 32. axb4 Ra8 33. Rh1 Rh8 34. Ra1 h5 35. Rxa6 Rg8 36. Ra1 h4 37. Rh1 Rh8 38. Kf3 f5 39. d4 exd4 40. cxd4 fxe4+ 41. Kxe4 d5+ 0-1",
    B: "1544",
  },
  {
    W: "1624",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 d6 3. h3 Nc6 4. c3 e5 5. Bb5 Nf6 6. O-O Qb6 7. Ba4 Nxe4 8. Re1 Nf6 9. d4 cxd4 10. cxd4 Be7 11. dxe5 dxe5 12. Nxe5 O-O 13. Be3 Qxb2 14. Nxc6 bxc6 15. Bxc6 Qxa1 16. Bd4 Qxa2 17. Bxa8 Bd6 18. Bxf6 gxf6 19. Nc3 Qc4 20. Nd5 Be5 21. Kh1 Rd8 22. f4 Rxd5 23. Bxd5 Bxf4 24. Bxc4 a5 25. Qd8+ Kg7 26. Qxc8 a4 27. Qg4+ Kf8 28. Qxf4 1-0",
    B: "1649",
  },
  {
    W: "963",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 c6 3. Bc4 d5 4. exd5 Nf6 5. dxc6 bxc6 6. Ng5 Qe7 7. d3 Bg4 8. f3 Bf5 9. Nxf7 Rg8 10. Nd6+ Qxd6 11. Bxg8 Nbd7 12. Bc4 Nc5 13. f4 e4 14. O-O Bg4 15. Qe1 Nd5 16. Nc3 e3 17. Bxe3 Nxe3 18. Qxe3+ Ne6 19. h3 Be2 1-0",
    B: "687",
  },
  {
    W: "1770",
    T: "300+0",
    P: "1. e4 e5 2. Bc4 Nf6 3. d3 Nc6 4. Nf3 d6 5. b3 Be6 6. Bxe6 fxe6 7. Bb2 Be7 8. Nbd2 O-O 9. d4 exd4 10. Nxd4 Nxd4 11. Bxd4 e5 12. Bb2 Qd7 13. Qf3 Rad8 14. O-O-O c5 15. h3 b5 16. Qe2 a5 17. Rhf1 a4 18. f4 axb3 19. cxb3 c4 20. bxc4 bxc4 21. Nxc4 Qa4 22. fxe5 dxe5 23. Bxe5 Rc8 24. Rd4 Qa3+ 25. Qb2 Qxb2+ 26. Kxb2 Rb8+ 27. Bxb8 Rxb8+ 28. Kc3 Rc8 29. e5 Nd5+ 30. Rxd5 Ba3 31. Rfd1 1-0",
    B: "1774",
  },
  {
    W: "1797",
    T: "300+0",
    P: "1. e4 c5 2. f4 d6 3. Nf3 Nc6 4. Bc4 Na5 5. Bb5+ Nc6 6. c3 g6 7. Ba4 Bg7 8. d3 Nf6 9. O-O O-O 10. h3 Ne8 11. Be3 f5 12. Nbd2 fxe4 13. Nxe4 Nf6 14. Nfg5 h6 15. Bb3+ d5 16. Nxf6+ exf6 17. Nf3 Kh7 18. Bxc5 Re8 19. Nh4 Ne7 20. Qg4 Bxg4 21. hxg4 f5 22. gxf5 Nxf5 23. Nf3 Ne3 24. Rfe1 d4 25. cxd4 Nc2 26. Bxc2 Rxe1+ 27. Rxe1 Qf6 28. Re4 b6 29. Be7 Qc6 30. Bb3 Qc1+ 31. Kh2 Qxb2 32. Bf7 Qb5 33. Nh4 Qxd3 34. Bxg6+ Kh8 35. Nf3 Qb5 36. Ne5 Bxe5 37. Rxe5 Qc6 38. d5 Qxg6 39. f5 Qg4 40. Bf6+ Kg8 41. Re7 Rf8 42. Rg7+ Kh8 43. Rxg4+ Rxf6 44. Rf4 Kg7 45. g4 Kf7 46. Kg3 Ke7 47. Kf3 Kd6 48. Ke4 a5 49. Kd4 b5 50. Re4 b4 51. Re6+ Rxe6 52. dxe6 a4 53. Kc4 b3 54. axb3 a3 55. Kc3 Ke7 56. b4 Kf6 57. Kb3 1-0",
    B: "1894",
  },
  {
    W: "1027",
    T: "300+0",
    P: "1. e4 d5 2. d3 c5 3. Nf3 Nc6 4. Nc3 d4 5. Nd5 e6 6. Bg5 Nf6 7. Nf4 e5 8. Nd5 Be6 9. c4 h6 10. Bxf6 gxf6 11. Be2 Bxd5 12. exd5 Ne7 13. O-O f5 14. Nxe5 f6 15. d6 Qxd6 16. f4 fxe5 17. fxe5 Qxe5 18. Bh5+ Kd7 19. a4 Nc6 20. b3 Bd6 21. Re1 Qxh2+ 22. Kf2 Ne5 23. Rh1 Qf4+ 24. Bf3 Nxf3 25. gxf3 h5 26. b4 cxb4 27. Rb1 a5 28. Rc1 b6 29. Qc2 Rac8 30. Rcd1 Rh6 31. Qd2 Qxd2+ 32. Rxd2 Bf4 33. Re2 Re8 34. Rxe8 Kxe8 35. Re1+ Kf7 0-1",
    B: "998",
  },
  {
    W: "1683",
    T: "300+0",
    P: "1. e4 e5 2. f4 d6 3. Nf3 Bg4 4. Bc4 Bxf3 5. Qxf3 exf4 6. Qxf4 Qf6 7. Qe3 h6 8. Rf1 Qe7 9. Bxf7+ Qxf7 10. Rxf7 Kxf7 11. Nc3 c6 12. d3 Be7 13. Qf3+ Bf6 14. d4 Nd7 15. Bf4 Ke7 16. O-O-O Rd8 17. e5 dxe5 18. dxe5 Bxe5 19. Bxe5 Nxe5 20. Qe4 Rxd1+ 21. Nxd1 Kf6 22. Ne3 Ne7 23. Qf4+ Ke6 24. Nc4 Nxc4 25. Qxc4+ Kd7 26. Qg4+ Kc7 27. Qxg7 Re8 28. Qxh6 Nd5 29. g4 Re1+ 30. Kd2 Re7 31. g5 Kb6 32. g6 Re6 33. Qg5 Re8 34. g7 Rg8 35. Qg6 Nf4 36. Qf7 Rd8+ 37. Ke3 Nd5+ 38. Ke2 a6 39. g8=Q Rxg8 40. Qxg8 Nf4+ 41. Kd1 Nd5 42. h4 Nb4 43. h5 Nxa2 44. Qxa2 1-0",
    B: "1653",
  },
  {
    W: "1478",
    T: "300+0",
    P: "1. e4 g6 2. d4 Bg7 3. Be3 b6 4. Bd3 Bb7 5. f4 d6 6. Nc3 Nd7 7. Nf3 e5 8. f5 exd4 9. Nxd4 Qh4+ 10. g3 Qh3 11. Qf3 O-O-O 12. O-O-O Ngf6 13. Bf1 Qh5 14. Qxh5 Nxh5 15. fxg6 hxg6 16. Be2 Rde8 17. Bxh5 Rxh5 18. g4 Rh3 19. Nde2 Rxe3 20. h4 Bxe4 21. Rde1 Bxh1 22. Rxh1 Bh6 23. Kb1 Ne5 0-1",
    B: "1477",
  },
  {
    W: "1864",
    T: "300+0",
    P: "1. d4 d5 2. Nf3 e6 3. g3 Nf6 4. Bg2 Bd6 5. O-O O-O 6. c3 Ne4 7. Nbd2 Qf6 8. Nxe4 dxe4 9. Nd2 e5 10. Nxe4 Qe6 11. Qd3 Nc6 12. d5 Qg6 13. dxc6 bxc6 14. Nxd6 cxd6 15. Qxg6 fxg6 16. Bxc6 Rb8 17. Bd5+ Kh8 18. c4 Bh3 19. Re1 Rf5 20. e4 Rf3 21. Re3 Rf6 22. b3 Rbf8 23. Re2 h5 24. Bg5 Rf3 25. Be7 Re8 26. Bxd6 Bg4 27. Rd2 h4 28. Bf7 Rxf7 29. h3 Bf3 30. g4 Bxe4 31. Re1 Rf4 32. c5 Rd8 33. Rde2 Bd3 34. Rxe5 a6 35. Re8+ Rxe8 36. Rxe8+ Kh7 37. Bxf4 g5 38. Bxg5 Kg6 39. f4 Bb5 40. Re6+ Kf7 41. Rb6 Bd3 42. b4 Be2 43. a4 g6 44. Kf2 Bd3 45. Rd6 Bc4 46. Bxh4 Kg7 47. Bg5 Kf7 48. h4 Kg7 49. h5 gxh5 50. gxh5 Kf7 51. Rd7+ Ke8 52. Ra7 Kf8 53. h6 Kg8 54. h7+ Kh8 55. Bf6# 1-0",
    B: "1664",
  },
  {
    W: "1409",
    T: "300+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 Nf6 5. Nf3 Bg4 6. Bd2 h5 7. Be2 c6 8. Ne4 Qc7 9. Nxf6+ exf6 10. Bc3 Nd7 11. Qd3 O-O-O 12. O-O-O Qf4+ 13. Kb1 Qc7 14. h3 Be6 15. g4 g6 16. gxh5 Bf5 17. Qe3 Rxh5 18. Ne5 Rh7 19. Nxd7 Qxd7 20. Qg3 Bd6 21. Qg2 Qe7 22. Bg4 Qe4 23. Qxe4 Re8 24. Bxf5+ gxf5 25. Qxf5+ Re6 26. Qxh7 1-0",
    B: "1400",
  },
  {
    W: "1935",
    T: "600+0",
    P: "1. e4 d5 2. exd5 Nf6 3. Nc3 Bg4 4. f3 Bh5 5. Bc4 a6 6. d4 Nbd7 7. Bg5 Nb6 8. Bxf6 Nxc4 9. Bg5 Nb6 10. Qd2 Nxd5 11. O-O-O h6 12. Be3 e6 13. Nxd5 Qxd5 14. Kb1 Be7 15. Ne2 O-O-O 16. Nf4 Qb5 17. a4 Qxa4 18. Nxh5 Rd5 19. b3 Qa3 20. Qc1 Rxh5 21. Qxa3 Bxa3 22. Bc1 Bxc1 23. Kxc1 Rd8 24. Kb2 Rhd5 25. c3 c5 26. Rc1 cxd4 27. cxd4+ Kb8 28. Rc2 Rxd4 29. Rhc1 R8d7 30. Rc8+ Ka7 31. R1c2 Rd2 32. Ka3 Rxc2 33. Rxc2 b5 34. b4 Kb7 35. Kb3 e5 36. Kb2 Rd4 37. Kb3 e4 38. fxe4 Rxe4 39. Rf2 f6 40. Rf3 Re2 41. Rg3 Re7 42. h4 Kb6 43. Rg4 g5 44. hxg5 hxg5 45. Rd4 Kc6 46. Kc3 Re2 47. g4 Rf2 48. Re4 Kd5 49. Re8 Rf3+ 50. Kd2 Kc4 51. Ra8 Kxb4 52. Rxa6 Rf4 53. Ke3 Kb3 54. Rd6 b4 55. Kd2 Kb2 56. Ra6 b3 57. Kd1 Kc3 58. Rc6+ Rc4 59. Rxc4+ Kxc4 60. Kc1 Kc3 61. Kb1 b2 0-1",
    B: "2056",
  },
  {
    W: "1790",
    T: "180+0",
    P: "1. d4 e6 2. e3 d5 3. Bd3 c5 4. f4 Nf6 5. c3 cxd4 6. exd4 Nc6 7. Nf3 Bd6 8. O-O O-O 9. Nbd2 Re8 10. Ne5 Nd7 11. Qh5 g6 12. Qh6 Bf8 13. Qh3 Ncxe5 14. fxe5 f6 15. Nf3 Bg7 16. Bh6 fxe5 17. Bxg7 Kxg7 18. dxe5 Nc5 19. Bb5 Rf8 20. Nd4 a6 21. Be2 Bd7 22. Rxf8 Qxf8 23. Rf1 Qe7 24. Nf3 Rf8 25. Qg3 Ne4 26. Qe1 Qc5+ 27. Kh1 Bb5 28. Bxb5 Qxb5 29. Rf2 Nxf2+ 30. Qxf2 Qd3 31. h4 Qb1+ 32. Kh2 Qe4 33. Qg3 Qf4 34. Qxf4 Rxf4 35. Ng5 Rxh4+ 36. Kg3 Rh5 37. Nxe6+ Kf7 38. Nf4 Rxe5 39. Nd3 Re3+ 40. Kf2 Rxd3 41. Ke2 Rg3 42. Kf2 Rg5 43. g3 Re5 44. Kf3 Re7 45. g4 h6 46. Kf4 Kg7 47. g5 h5 48. Kg3 Kf7 49. Kf4 b6 50. b4 Rd7 51. a4 Ke6 52. Kf3 Rc7 53. Ke3 Rxc3+ 54. Kd4 Rc4+ 55. Kd3 Rxb4 56. a5 Ra4 57. axb6 Rb4 58. Kc3 Rxb6 59. Kd4 Rb1 60. Kc5 Rg1 61. Kb4 Rxg5 62. Ka5 Rf5 63. Kxa6 g5 64. Kb5 g4 65. Kc6 g3 66. Kc7 d4 67. Kd8 d3 68. Ke8 Rf7 69. Kd8 Ra7 70. Kc8 d2 71. Kb8 Rh7 72. Kc8 d1=Q 73. Kb8 Qg4 74. Ka8 Qg8# 0-1",
    B: "1821",
  },
  {
    W: "1760",
    T: "120+0",
    P: "1. e4 d5 2. exd5 1-0",
    B: "1945",
  },
  {
    W: "1762",
    T: "180+0",
    P: "1. e4 e5 2. c4 Bc5 3. d3 Nc6 4. Nc3 d6 5. Be3 Nd4 6. Qd2 f5 7. exf5 Bxf5 8. h3 Nf6 9. O-O-O c6 10. g4 Bg6 11. g5 Nh5 12. Be2 O-O 13. Rf1 a6 14. Qd1 Nf4 15. Bh5 Bxd3 16. Bxf4 Bxf1 17. Qxf1 Rxf4 18. Nce2 Nxe2+ 19. Nxe2 Rxf2 20. Qd1 Qxg5+ 21. Qd2 Qxh5 22. Re1 0-1",
    B: "1812",
  },
  {
    W: "1846",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 d6 5. c3 Be6 6. Bxe6 fxe6 7. cxd4 Nf6 8. Nc3 Be7 9. O-O O-O 10. Qb3 d5 11. e5 Ne4 12. Qxb7 Nb4 13. Nxe4 dxe4 14. Qxe4 Nd5 15. Bd2 Rf5 16. Rac1 Qf8 17. Rc6 g6 18. Rxe6 Nb6 19. d5 Re8 20. Bc3 Nd7 21. d6 cxd6 22. exd6 Bd8 23. Rxe8 Re5 24. Rxf8+ Kxf8 25. Nxe5 Nxe5 26. Qxe5 1-0",
    B: "1719",
  },
  {
    W: "2283",
    T: "180+0",
    P: "1. c4 a6 2. d4 b6 3. e4 c6 4. Nf3 d6 5. Bd3 e6 6. O-O f6 7. e5 dxe5 8. dxe5 f5 9. Nc3 c5 10. Qe2 Bb7 11. Rd1 Qc7 12. Ng5 Qe7 13. f4 g6 14. Bc2 h6 15. Nf3 Nc6 16. Rd6 Qf7 17. Ba4 Bxd6 18. exd6 O-O-O 19. Bxc6 Bxc6 20. Ne5 Qb7 21. d7+ Bxd7 22. Nf7 Nf6 23. Nxh8 Rxh8 24. b3 Re8 25. Bb2 Bc6 26. Re1 Nd7 27. Nd1 Be4 28. Nf2 Bxg2 29. Nd3 Be4 30. Ne5 Nf8 31. Rd1 g5 32. fxg5 hxg5 33. Rd6 Rd8 34. Qd2 Nd7 35. Nf7 Rf8 36. Nxg5 Rg8 37. h4 Qc7 38. Kf2 Nb8 39. Be5 Qh7 40. Nxh7 Rg2+ 41. Ke1 Rxd2 42. Kxd2 Nc6 43. Rxe6 Kd7 44. Rd6+ Kc7 45. Bf4 Nd4 46. h5 Kb7 47. h6 Nf3+ 48. Ke3 Nh2 49. Bxh2 Bb1 50. Bf4 Bxa2 51. Ng5 Bxb3 52. h7 Bxc4 53. h8=Q a5 54. Qg7+ Ka6 55. Qc7 Kb5 56. Qxb6+ Ka4 57. Qxc5 Bb3 58. Ra6 Bd1 59. Rxa5+ Kb3 60. Ra3+ Kb2 61. Qc3+ Kb1 62. Ra1# 1-0",
    B: "2429",
  },
  {
    W: "1334",
    T: "900+15",
    P: "1. e4 Nc6 2. Nc3 e5 3. Nf3 Nf6 4. d4 d6 5. dxe5 Ng4 6. exd6 Bxd6 7. h3 Nf6 8. e5 Bxe5 9. Qxd8+ Nxd8 10. Nxe5 c5 11. Nc4 Be6 12. Bd3 Nc6 13. O-O Rd8 14. b3 b5 15. Nd2 b4 16. Nce4 Ke7 17. Nxc5 Rd6 18. Nxe6 Rxe6 19. Bc4 Rd6 20. Re1+ Kd8 21. Ne4 Re8 22. Bf4 Kd7 23. Bxd6 Nxe4 24. Bxf7 Nxd6 25. Bxe8+ Nxe8 26. a3 Nf6 27. axb4 Nxb4 28. Rxa7+ Kc6 29. Ra4 Nxc2 30. Re6+ Kd5 31. Rxf6 gxf6 32. b4 Kc6 33. b5+ Kxb5 34. Rf4 h5 35. Rxf6 h4 36. Rf5+ Kc4 37. Rh5 Kd3 38. Rxh4 Ne1 39. Kf1 Nc2 40. g4 Ke4 41. g5+ Kf5 42. Rg4 Nd4 43. g6 Ne6 44. g7 Nxg7 45. Rxg7 Kf4 46. h4 Kf5 47. Rg1 Kf6 48. h5 Ke5 49. h6 Kd4 50. h7 Kc3 51. h8=Q+ Kd2 52. Qf8 Kc3 53. Rg3+ Kd4 54. Qf4+ Kc5 55. Rg5+ Kc6 56. Qf6+ Kd7 57. Rg7+ Ke8 58. Qh6 Kd8 59. Qh8# 1-0",
    B: "1208",
  },
  {
    W: "1181",
    T: "900+15",
    P: "1. d4 d5 2. c4 Nf6 3. Nc3 c5 4. e3 Bg4 5. Nf3 b6 6. h3 Bh5 7. g4 Bg6 8. Ne5 Ne4 9. Nxe4 dxe4 10. Nxg6 hxg6 11. Bg2 f5 12. gxf5 gxf5 13. f3 g6 14. fxe4 fxe4 15. Bxe4 Rh6 16. Bxa8 Bg7 17. O-O cxd4 18. exd4 Bxd4+ 19. Kh2 Be5+ 20. Kg2 Rh5 21. Qxd8+ Kxd8 22. Bf3 Rf5 23. b3 Nc6 24. Bxc6 Kc7 25. Be4 Rxf1 26. Kxf1 Bxa1 27. Bxg6 e5 1/2-1/2",
    B: "1158",
  },
  {
    W: "1022",
    T: "900+15",
    P: "1. d4 d5 2. e3 c6 3. Nf3 Nf6 4. h4 Bg4 5. Ng5 Bh5 6. f3 e6 7. Nc3 Bd6 8. Qd3 a6 9. a4 O-O 10. b3 Bb4 11. Ba3 Qd6 12. Qxh7+ Nxh7 13. Nxh7 Qg3+ 0-1",
    B: "1148",
  },
  {
    W: "1471",
    T: "900+15",
    P: "1. d4 d5 2. Bf4 Nc6 3. Nf3 f6 4. h4 e5 5. Bh2 Bg4 6. Nbd2 Bd6 7. dxe5 fxe5 8. e4 dxe4 9. Nxe4 Qe7 10. Be2 O-O-O 11. c3 Nb4 12. Nxd6+ Qxd6 13. Qxd6 Rxd6 14. cxb4 Bxf3 15. Bxf3 Nf6 16. O-O Rhd8 17. Bxe5 Rd2 18. Bxf6 gxf6 19. b3 R8d4 20. Rad1 Rxd1 21. Rxd1 Rxh4 22. g3 Rxb4 23. a3 Rxb3 24. Bg4+ Kb8 25. Rd8# 1-0",
    B: "1459",
  },
  {
    W: "1677",
    T: "900+15",
    P: "1. e3 e5 2. Qh5 Nc6 3. Bc4 g6 4. Qf3 Nf6 5. Nc3 b6 6. Ne4 Bg7 7. Ne2 Bb7 8. N2c3 h6 9. Bb3 Ne7 10. Nxf6+ Bxf6 11. Qxb7 Rb8 12. Qf3 Nf5 13. e4 Nh4 14. Qg3 c6 15. Qh3 Qc8 16. g3 Nf3+ 17. Ke2 Nd4+ 18. Ke1 Nf3+ 19. Ke2 Nd4+ 20. Kd1 a5 21. a4 b5 22. Ne2 Nxb3 23. cxb3 bxa4 24. bxa4 Rb4 25. b3 Rxb3 26. Nc3 Qa6 27. Qf1 Qb6 28. Qc4 Rb4 29. Qd3 Rd4 30. Qf3 c5 31. Nd5 Qa6 32. Nxf6+ Kd8 33. Nd5 f5 34. Nc3 Rc4 35. exf5 Rf8 36. g4 Rf4 37. Qe2 Qb6 38. Nd5 Qb3+ 39. Ke1 Qxd5 40. d3 Qd4 41. Qb2 Qxd3 42. Bxf4 exf4 43. Qd2 Re8+ 44. Kd1 Qb3+ 45. Kc1 Qc4+ 46. Qc2 Qd5 47. Rd1 Qe4 48. Qxe4 Rxe4 49. f3 Re3 50. Rf1 Rc3+ 51. Kd2 Re3 52. Rae1 Ra3 53. Ra1 Re3 54. Rac1 Ra3 55. Rxc5 Rxa4 56. Ke2 Ra2+ 57. Kd3 a4 58. Rc2 Ra3+ 59. Ke2 Re3+ 60. Kf2 a3 61. Ra2 Ke7 62. Rfa1 d5 63. Rxa3 Re5 64. fxg6 Rg5 65. Ra6 Kf8 66. Rb1 h5 67. Rb7 1-0",
    B: "1652",
  },
  {
    W: "1188",
    T: "900+15",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 Nf6 4. Bc4 Nxe4 5. d3 Nc5 6. O-O c6 7. Bxf4 1-0",
    B: "1190",
  },
  {
    W: "1565",
    T: "900+15",
    P: "1. e4 Nc6 2. Bb5 Nd4 3. Bc4 Nf6 4. c3 Nc6 5. d4 d5 6. e5 Ne4 7. Bb3 e6 8. Qf3 Be7 9. Nh3 O-O 10. Bc2 f5 11. Bxe4 fxe4 12. Qg4 Bg5 13. Nxg5 Na5 14. Nxe6 Bxe6 15. Qxe6+ Kh8 16. Qg4 Nc4 17. O-O e3 18. Bxe3 Nxe3 19. fxe3 Rxf1+ 20. Kxf1 Qf8+ 21. Kg1 Qf7 22. Nd2 Rf8 23. e4 Qf2+ 24. Kh1 Qxd2 25. exd5 Qd3 26. h3 Rf2 27. Qc8+ 1-0",
    B: "1577",
  },
  {
    W: "995",
    T: "60+0",
    P: "1. d4 c6 2. Nc3 d5 3. Be3 Bf5 4. h3 e6 5. g4 Bg6 6. Nf3 h5 7. Ne5 hxg4 8. Nxg6 fxg6 9. Bg2 gxh3 10. Rxh3 Rxh3 11. Bxh3 Qh4 12. Bxe6 Qh1+ 13. Kd2 Qh3 14. Qh1 Qxe6 15. Qh8 Ne7 16. Qh5 Nd7 17. Qg4 Qxg4 18. f3 Qg2 0-1",
    B: "1178",
  },
  {
    W: "2425",
    T: "60+0",
    P: "1. e4 b6 2. d4 Nf6 3. c3 d6 4. Bd3 Nbd7 5. f3 e5 6. Ne2 Be7 7. Bg5 O-O 8. O-O h6 9. Bh4 Bb7 10. Nd2 Rc8 11. Rc1 c5 12. d5 Nh7 13. Bxe7 Qxe7 14. f4 f5 15. fxe5 Nxe5 16. Bb1 fxe4 17. Bxe4 Rxf1+ 18. Qxf1 Ng5 19. Bb1 Bxd5 20. Nf4 Bb7 21. a4 Rf8 22. Ba2+ Kh8 23. Qf2 Ba8 24. Rf1 Qb7 25. Bd5 Qd7 26. Bxa8 Rxa8 27. h4 Ne6 28. Nf3 Nxf3+ 29. Qxf3 Re8 30. Ng6+ Kh7 31. h5 Ng5 32. Qd3 Ne4 33. Re1 d5 34. c4 Qf5 35. cxd5 Qf2+ 36. Kh1 Qxe1+ 37. Kh2 Qh4+ 0-1",
    B: "2331",
  },
  {
    W: "1858",
    T: "60+0",
    P: "1. c4 c6 2. Nc3 d5 3. cxd5 cxd5 4. d4 Bf5 5. g3 Nf6 6. Bg2 e6 7. Bg5 Be7 8. Bxf6 Bxf6 9. Nf3 Nc6 10. O-O O-O 11. a3 Rc8 12. e3 a6 13. Rc1 Na5 14. Na4 Nc4 15. Qe2 b5 16. Nc5 Be7 17. b3 Bxc5 18. bxc4 Bxa3 19. cxd5 Bxc1 20. Rxc1 Rxc1+ 21. Bf1 exd5 22. Ne5 Qc7 23. Qf3 Be4 24. Qg4 f6 25. Nf3 Bd3 26. Nd2 Qc2 27. Kg2 Qxd2 28. Bxd3 Qxd3 29. Qe6+ Kh8 30. Qxd5 f5 31. Qe5 Qe4+ 32. Qxe4 fxe4 33. d5 Rc2 34. Kh3 Rfxf2 35. Kg4 Rxh2 36. Kf4 Rhf2+ 37. Kxe4 Kg8 38. Ke5 Kf8 39. d6 Ke8 40. Ke6 Rce2 41. d7+ Kd8 42. Kd6 Rxe3 43. Kc6 Rd2 44. Kb6 Rc3 45. Kxa6 1-0",
    B: "1878",
  },
  {
    W: "1500",
    T: "60+0",
    P: "1. e4 c5 2. f4 Nc6 3. Nf3 d6 4. Bc4 e6 5. Kf1 Be7 6. d3 a6 7. Be3 b5 8. Bb3 Na5 9. a4 c4 10. dxc4 bxc4 11. Ba2 Bb7 12. b3 cxb3 13. Bxb3 Nxb3 14. cxb3 Bxe4 15. Nc3 Bb7 16. Qd4 Bf6 17. Qd3 Ne7 18. Ne4 Bxa1 19. Nxd6+ Kf8 20. Bc5 Bd5 21. Qd1 Bf6 22. Kf2 Kg8 23. Qe2 Nc6 24. Rd1 g6 25. Ne5 Nxe5 26. fxe5 Be7 27. Qg4 Bxd6 28. Bxd6 Qb6+ 29. Kg3 1-0",
    B: "1570",
  },
  {
    W: "1907",
    T: "60+0",
    P: "1. h4 c5 2. h5 d6 3. h6 Nxh6 4. d4 Nf5 5. dxc5 Qa5+ 6. c3 Qxc5 7. e4 Nh6 8. Be3 Qc7 9. Bd3 e5 10. Bxh6 gxh6 11. Nd2 Be6 12. Ndf3 Nc6 13. Ne2 O-O-O 14. Qc2 f5 15. O-O f4 16. Nc1 Be7 17. a4 Qd7 18. a5 a6 19. Ne2 Kb8 20. Nc1 Rc8 21. Nb3 Nb4 22. Qd2 Nxd3 23. Qxd3 Rhg8 24. Nbd2 Bf6 25. b3 Rxg2+ 26. Kxg2 Rg8+ 27. Kh1 Qf7 28. Rfe1 Qh5+ 29. Nh2 Bh3 30. f3 Bg2+ 31. Kg1 Bxf3+ 32. Kf1 Qxh2 33. Nxf3 Qh1+ 34. Ke2 Rg2+ 35. Kd1 Qh5 36. Qxd6+ 1-0",
    B: "1879",
  },
  {
    W: "1589",
    T: "60+0",
    P: "1. e4 b6 2. Nf3 Bb7 3. Nc3 g6 4. d4 Bg7 5. Be3 d6 6. Bd3 Nd7 7. O-O e6 8. Re1 Ne7 9. Bf4 a6 10. Qd2 O-O 11. Bh6 Nf6 12. e5 Ng4 13. Bxg7 Kxg7 14. Ng5 d5 15. f3 Nh6 16. Nh3 Nc6 17. Be2 Nb4 18. a3 Nc6 19. Nd1 Ne7 20. Ndf2 c5 21. Ng4 Nef5 22. Nxh6 Nxh6 23. g4 Qh4 24. Bf1 c4 25. Qf2 Qxf2+ 26. Kxf2 f6 27. f4 f5 28. g5 Ng4+ 29. Kg3 h5 30. Nf2 Nxf2 31. Kxf2 b5 32. h4 a5 33. Ke3 Rab8 34. Re2 Bc6 35. Bg2 b4 36. Bf3 bxa3 37. Rxa3 Rxb2 38. Rxa5 Rfb8 39. Ra7+ R8b7 40. Rxb7+ Rxb7 41. Rd2 Rb2 42. Ke2 Ba4 43. Kd1 Rb1+ 44. Ke2 Rb2 45. Kd1 Rb1+ 46. Ke2 Rb4 47. Kd1 Bxc2+ 48. Ke2 Bd3+ 0-1",
    B: "1718",
  },
  {
    W: "1760",
    T: "60+0",
    P: "1. Nf3 d5 2. Nc3 c6 3. d4 Nf6 4. Bf4 Bg4 5. e3 Nbd7 6. h3 Bxf3 7. Qxf3 e6 8. Bg5 Be7 9. Ne2 O-O 10. Nf4 a6 11. Nd3 Re8 12. O-O-O Nb6 13. Qe2 Nbd7 14. Bh4 Ne4 15. Bg3 Nxg3 16. fxg3 Bg5 17. Re1 Nb6 18. h4 Bh6 19. g4 g6 20. g5 Bg7 21. g3 Nc4 22. b3 Na3 23. Kb2 Nb5 24. Nf4 Qa5 25. a4 Qc3+ 26. Ka2 Nd6 27. Qd3 Qxd3 28. Nxd3 b5 29. Nc5 Ne4 30. Nxe4 dxe4 31. axb5 axb5+ 32. Kb2 Rec8 33. Be2 f5 34. Ra1 Rxa1 35. Rxa1 c5 36. c4 bxc4 37. bxc4 cxd4 38. Rd1 dxe3+ 39. Kc2 Rb8 40. Rb1 Rb2+ 41. Rxb2 Bxb2 42. Kxb2 Kf7 43. Bf1 e5 44. c5 Ke7 45. c6 Kd6 46. Be2 Kxc6 0-1",
    B: "1734",
  },
  {
    W: "2230",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Bc5 5. c3 d5 6. O-O dxc4 7. dxc4 Nxe4 8. Qxd8+ Kxd8 9. Be3 Bxe3 10. fxe3 f6 11. Rd1+ Ke7 12. Na3 Be6 13. Nb5 Bxc4 14. Nxc7 Rac8 15. Nd5+ Bxd5 16. Rxd5 Rhd8 17. Rad1 Rxd5 18. Rxd5 Rd8 19. Rxd8 Kxd8 20. Kf1 g5 21. Ke2 g4 22. Ne1 h5 23. Nd3 b6 24. Nc1 a5 25. Kd3 Nc5+ 26. Ke2 Ke7 27. b4 axb4 28. cxb4 Nxb4 29. a3 Nc2 30. Kd2 Nxa3 31. Kc3 Nb5+ 32. Kb4 Nd6 33. Ne2 Ke6 34. Ng3 h4 35. Ne2 f5 36. Kc3 Kd5 37. Kd2 Ke4 38. Nc3# 1-0",
    B: "2118",
  },
  {
    W: "2014",
    T: "60+0",
    P: "1. e4 d6 2. Nf3 Be6 3. Nc3 f6 4. d4 Bf7 5. Bd3 Nd7 6. d5 e5 7. dxe6 Bxe6 8. Nd4 Bf7 9. Nd5 Ne5 10. O-O c5 11. Nf3 Bh5 12. Be2 Ne7 13. Nxe5 Bxe2 14. Qxe2 dxe5 15. f4 Nxd5 16. exd5 Qxd5 17. fxe5 fxe5 18. Qh5+ g6 19. Qg4 Qd7 20. Qxd7+ Kxd7 21. Rf7+ Ke6 22. Rxb7 Bd6 23. Be3 h5 24. Rd1 h4 25. c4 h3 26. g3 Rhf8 27. Rd5 Rf3 28. Bf2 e4 29. Bxc5 Bxc5+ 30. Rxc5 e3 31. Rg7 Rf6 32. Rgc7 Raf8 33. R5c6+ Ke5 34. Re7+ Kd4 35. Rxf6 Rxf6 36. Rxe3 Kxe3 37. b3 Rf2 38. c5 Rg2+ 0-1",
    B: "1930",
  },
  {
    W: "1122",
    T: "60+0",
    P: "1. e4 Nc6 2. Nf3 Nf6 3. Nc3 e6 4. Bb5 d5 5. Bxc6+ bxc6 6. e5 Ne4 7. Nxe4 dxe4 8. Ng1 Bc5 9. d3 exd3 10. cxd3 Ba6 11. d4 Bxd4 12. Be3 Bxe3 0-1",
    B: "1093",
  },
  {
    W: "1380",
    T: "60+0",
    P: "1. g3 e5 2. Bg2 Nf6 3. d4 d5 4. dxe5 Ne4 5. Bxe4 dxe4 6. Qxd8+ Kxd8 7. Bg5+ Be7 8. Bxe7+ Kxe7 9. Nc3 Rd8 10. Nxe4 Bf5 11. f3 Bxe4 12. fxe4 f6 13. exf6+ Kxf6 14. Nf3 Re8 15. Nd2 Nc6 16. Rf1+ Ke5 17. Rf5+ Ke6 18. h3 Nd4 19. Rc5 c6 20. c3 Nc2+ 21. Kd1 Nxa1 22. Kc1 Rf8 23. Kb1 1-0",
    B: "1270",
  },
  {
    W: "1230",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qe6+ 4. Nce2 Nf6 5. Nf3 Qd7 6. Ng5 e6 7. Nf3 Be7 8. Ng3 O-O 9. Bc4 Qd8 10. d4 Bd7 11. c3 Nc6 12. Bb3 Na5 13. O-O Nxb3 14. Qxb3 Qc8 15. Ne5 Be8 0-1",
    B: "1179",
  },
  {
    W: "1786",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 Nf6 3. Bg5 c5 4. Nbd2 Nc6 5. c3 e6 6. e3 Be7 7. Bd3 c4 8. Bc2 Ng4 9. Bxe7 Qxe7 10. h3 Nf6 11. O-O Bd7 12. Ne5 Nxe5 13. dxe5 Ne4 14. Nxe4 dxe4 15. Bxe4 Bc6 16. Bxc6+ bxc6 17. Qf3 Qc5 18. Rad1 O-O 19. Rd6 Rac8 20. Rfd1 h6 21. Qe4 Qa5 22. a3 Qc7 23. Rd7 Qb6 24. R1d4 Qxb2 25. Rxc4 Qxa3 26. Rxc6 Rxc6 27. Qxc6 Qc1+ 28. Kh2 Qc2 29. Rd4 Qf5 30. Rf4 Qxe5 31. Qd7 Qf6 32. Rxf6 gxf6 33. Qe7 f5 34. Qf6 Rc8 35. Qxh6 Rxc3 36. Qg5+ Kf8 37. Qf6 Rc8 38. Qh8+ Ke7 39. Qxc8 Kf6 40. Qc7 Kg7 41. f4 Kf6 42. Qd7 a5 43. Qd8+ Kg7 44. h4 a4 45. h5 a3 46. Qg5+ Kf8 47. h6 a2 48. Qd8# 1-0",
    B: "1787",
  },
  {
    W: "1866",
    T: "180+2",
    P: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. d4 Nc6 5. Be3 Bf5 6. Bb5 Nf6 7. Nbd2 e6 8. Ne5 Rc8 9. c3 Bd6 10. f4 O-O 11. O-O b6 12. Nxc6 1-0",
    B: "1862",
  },
  {
    W: "1906",
    T: "180+5",
    P: "1. e4 a6 2. Nf3 c5 3. c3 d6 4. d3 h6 5. Be2 Nc6 6. a4 Bd7 7. Bf4 e5 8. Bg3 Be7 9. Nbd2 Rb8 10. Nc4 b5 11. axb5 axb5 12. Ne3 Nf6 13. Bh4 g5 14. Bg3 g4 15. Nh4 Nxe4 16. dxe4 Bxh4 17. Qxd6 Bxg3 18. hxg3 Qe7 19. Qxe7+ Kxe7 20. Bxg4 Bxg4 21. Nxg4 h5 22. Ne3 Kd6 23. Ra6 Kc7 24. Nd5+ Kb7 25. Rb6+ Ka7 26. Rxc6 1-0",
    B: "1718",
  },
  {
    W: "1852",
    T: "120+0",
    P: "1. e3 e5 2. d3 Nc6 3. c4 Nf6 4. Nc3 d5 5. cxd5 Nxd5 6. Nxd5 Qxd5 7. Be2 Bc5 8. Bf3 Qd6 9. Ne2 Be6 10. Ng3 h6 11. Ne4 Qe7 12. Qc2 Bb4+ 13. Ke2 f5 14. Nc3 Qd6 15. Nb5 Qd7 16. Bd2 Bxd2 17. Qxd2 g5 18. g4 f4 19. Be4 O-O 20. f3 Bd5 21. Bf5 Qf7 22. h4 a6 23. Nc3 Be6 24. hxg5 hxg5 25. Rh7 Qf6 26. Rah1 Bxf5 27. gxf5 Qxf5 28. Nd5 fxe3 29. Qe1 Nd4+ 30. Kxe3 Qxf3+ 31. Kd2 Qg2+ 32. Kc3 Qc2+ 33. Kb4 a5+ 34. Ka3 Qc5+ 35. b4 axb4+ 36. Kb2 Qc2+ 37. Ka1 Nb3# 0-1",
    B: "2032",
  },
  {
    W: "2324",
    T: "300+0",
    P: "1. d4 d5 2. c4 e6 3. Nc3 f5 4. Nf3 c6 5. Bg5 Nf6 6. e3 Bb4 7. Qc2 Qa5 8. Rc1 Ne4 9. Bf4 Qxa2 10. Bd3 Bxc3+ 11. bxc3 Qxc2 12. Rxc2 O-O 13. O-O Nd7 14. Bxe4 fxe4 15. Ne5 Nxe5 16. Bxe5 Bd7 17. c5 b5 18. cxb6 axb6 19. f4 b5 20. Rb1 Ra3 21. Bd6 Rfa8 22. Bxa3 Rxa3 23. Rcc1 Kf7 24. Ra1 Rb3 25. Ra7 Ke7 26. Rc2 Kd6 27. Kf2 g6 28. Ra8 Ke7 29. Rh8 h5 30. Rh7+ Kd6 31. Ra2 Rxc3 32. Ra8 h4 33. Rd8 Rc2+ 34. Ke1 Kc7 35. Rdxd7+ Kb6 36. Ra7 c5 37. Rhb7+ Kc6 38. Rc7+ Kb6 39. Rab7+ Ka6 40. dxc5 Rxg2 41. Rb6+ Ka5 42. Ra7+ Kb4 43. Rab7 Kxc5 44. Rxb5+ Kc4 45. Rb2 Rg1+ 46. Kf2 Rh1 47. Kg2 Re1 48. R7b3 d4 49. exd4 Kxd4 50. Kh3 Re3+ 51. Rxe3 Kxe3 52. Kxh4 Kxf4 53. Rb6 g5+ 54. Kh3 Kf3 55. Rxe6 g4+ 56. Kh4 e3 57. Rxe3+ Kxe3 58. Kxg4 Ke4 59. Kg5 Ke5 60. Kg6 Ke6 61. h4 Ke7 62. Kg7 Ke6 63. h5 1-0",
    B: "2182",
  },
  {
    W: "1481",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3 Nc6 8. Bc4 O-O 9. Bb3 Nxd4 10. Qxd4 e5 11. Qd2 Qd7 12. g4 h5 13. h3 Kh7 14. O-O-O Rh8 15. Kb1 a6 16. Nd5 Nxd5 17. Qxd5 Bf8 18. gxh5 gxh5 19. Rdg1 Qe6 20. Qd3 Qf6 21. Bg5 Qf5 1-0",
    B: "1502",
  },
  {
    W: "1438",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 a6 3. Bc4 Nc6 4. O-O e6 5. h3 d6 6. c3 Nf6 7. d3 b5 8. Bb3 Bb7 9. Re1 Be7 10. d4 c4 11. Bc2 O-O 12. Nbd2 d5 13. e5 Nh5 14. Nh2 g6 15. Qg4 f5 16. Qf3 Rb8 17. Bd1 b4 18. Qe3 bxc3 19. bxc3 f4 20. Qe2 Ng7 21. Qg4 Bg5 22. Ba3 Rf7 23. Nhf3 h5 24. Qxg5 Qxg5 25. Nxg5 Rf5 26. h4 a5 27. Bd6 Rc8 28. Rb1 Ba6 29. Rb6 Nd8 30. Rxa6 Nb7 31. Bc2 Nxd6 1-0",
    B: "1509",
  },
  {
    W: "1560",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Nf6 3. c4 g6 4. Nc3 Bg7 5. f4 e6 6. Nf3 exd5 7. Nxd5 O-O 8. d4 Nxd5 9. cxd5 Qxd5 10. Be3 Re8 11. Qd2 Bg4 12. Be2 Nc6 13. O-O Re7 14. Ne5 Rae8 15. Bxg4 f6 16. Nxg6 hxg6 17. Bf2 f5 18. Bf3 Qd7 19. Rfe1 Nxd4 20. Rxe7 Rxe7 21. Be2 Nxe2+ 22. Qxe2 Rxe2 23. Bc5 Rd2 24. Bb4 Re2 0-1",
    B: "1687",
  },
  {
    W: "1276",
    T: "60+0",
    P: "1. c3 Nf6 2. Nf3 c5 3. g3 g6 4. Bg2 Bg7 5. e3 O-O 6. d4 cxd4 7. cxd4 b5 8. O-O Bb7 9. Bd2 a5 10. Nc3 b4 11. Nb5 Ba6 12. Qe2 Qb6 13. a4 Nc6 14. Ne5 Nxe5 15. dxe5 Nd5 16. Bxd5 Rac8 17. b3 Rc5 18. Bc4 e6 19. e4 Bxe5 20. Qe3 d6 21. Rac1 Rd8 22. f4 Bg7 23. Rfd1 d5 24. exd5 exd5 25. Be2 Rc6 26. Rxc6 Qxe3+ 27. Bxe3 d4 28. Bd2 d3 29. Bf3 Bb7 0-1",
    B: "1508",
  },
  {
    W: "2412",
    T: "60+0",
    P: "1. d4 c5 2. Nf3 cxd4 3. Nxd4 Nc6 4. c4 e5 5. Nb5 d6 6. N1c3 a6 7. Na3 Nf6 8. Bg5 Be7 9. g3 O-O 10. Bg2 Be6 11. O-O h6 12. Bxf6 Bxf6 13. Rc1 Rc8 14. Nd5 Bg5 15. e3 f5 16. f4 Bf6 17. b3 e4 18. Nxf6+ Qxf6 19. Nc2 Rfd8 20. Nd4 Bf7 21. Qd2 d5 22. c5 Nxd4 23. Qxd4 Qxd4 24. exd4 Be8 25. Kf2 Bb5 26. Rfd1 Re8 27. Ke3 g6 28. Bf1 Bxf1 29. Rxf1 Kf7 30. b4 Re6 31. a4 g5 32. b5 Kg6 33. Rb1 Ra8 34. b6 Rc6 35. a5 Rh8 36. Rf2 h5 37. fxg5 Kxg5 38. Rbf1 Rf6 39. Rf4 h4 40. gxh4+ Rxh4 41. Rg1+ Rg4 42. Rgxg4+ fxg4 43. Rxf6 Kxf6 44. c6 bxc6 45. b7 Kg5 46. b8=Q Kh4 47. Qg3+ Kh5 48. h3 1-0",
    B: "2631",
  },
  {
    W: "1716",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6 7. Bd3 Be7 8. f4 O-O 9. Qf3 Nc6 10. Nb3 Qc7 11. O-O-O b5 12. a3 Bb7 13. Qh3 e5 14. f5 b4 15. Nd5 Nxd5 16. exd5 Bxg5+ 17. Kb1 Nd4 18. f6 Bh6 19. Nxd4 exd4 20. g4 bxa3 21. g5 Qb6 22. b3 Qb4 23. gxh6 Qc3 24. Kc1 Qb2+ 25. Kd2 Qc3+ 26. Ke2 Rfe8+ 27. Kf2 Qd2+ 28. Rxd2 Bxd5 29. hxg7 Bxh1 30. Qxh7# 1-0",
    B: "1863",
  },
  {
    W: "1887",
    T: "60+0",
    P: "1. d4 d6 2. Nf3 g6 3. Nc3 Bg7 4. Bg5 Nf6 5. e4 e5 6. d5 O-O 7. Bc4 Nbd7 8. a3 a6 9. O-O Rb8 10. Ba2 b5 11. b4 Bb7 12. Rc1 Nb6 13. Ne2 Qd7 14. h3 Rbc8 15. c3 Nc4 16. Bb1 Nxa3 17. Bd3 c6 18. Qb3 Nc4 19. Bxc4 bxc4 20. Qxc4 cxd5 21. Qb3 dxe4 22. Nd2 Bd5 23. c4 Ba8 24. Qb1 d5 25. cxd5 Rxc1 26. Rxc1 Bxd5 27. Bxf6 Bxf6 28. Nxe4 Bxe4 29. Qxe4 Bg7 30. Kh2 f5 31. Qc4+ Kh8 32. Ng3 e4 33. Qxa6 Be5 34. Qb6 Rf6 35. Qb8+ Kg7 36. Rc7 Bxc7 37. Qxc7 Qxc7 38. f4 Rb6 39. h4 Rxb4 40. Kh3 Rb3 41. Kh2 Qxf4 42. Kh3 Qxg3# 0-1",
    B: "1708",
  },
  {
    W: "2180",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. c4 Nf6 6. Nc3 Bb4 7. Qd3 d5 8. cxd5 exd5 9. exd5 Nxd5 10. Nxc6 bxc6 11. Bd2 O-O 12. Nxd5 Bxd2+ 13. Qxd2 cxd5 14. O-O-O Be6 15. Kb1 a5 16. Bd3 a4 17. Rc1 a3 18. b3 Qf6 19. Rhe1 g6 20. f3 Rfc8 21. Rxc8+ Rxc8 22. Rc1 Rxc1+ 23. Qxc1 Qd4 24. Bc2 Qf2 25. Qxa3 Qxg2 26. Qe7 Qxf3 27. a4 Qf1+ 28. Kb2 Kg7 29. Qc5 Qf6+ 30. Qc3 Qxc3+ 31. Kxc3 d4+ 32. Kxd4 Kf6 33. a5 Ke7 34. a6 Bc8 35. a7 Bb7 36. Be4 Bxe4 37. Kxe4 f5+ 38. Ke5 Kd7 0-1",
    B: "2131",
  },
  {
    W: "2333",
    T: "60+0",
    P: "1. d4 Nf6 2. Bg5 g6 3. Bxf6 exf6 4. c3 Bg7 5. Nd2 O-O 6. e3 d5 7. Ngf3 f5 8. Bd3 Nd7 9. Qc2 Nf6 10. O-O-O Ne4 11. Bxe4 dxe4 12. Ne1 a5 13. f3 a4 14. fxe4 a3 15. b3 Re8 16. exf5 Bxf5 17. e4 Bg4 18. Nef3 c5 19. d5 b5 20. h3 Qf6 21. hxg4 b4 22. e5 Qf4 23. c4 Rxe5 24. Nxe5 Qxe5 25. Rhe1 Qb2+ 26. Qxb2 axb2+ 27. Kb1 Bc3 28. Re7 Rd8 29. Ne4 Bd4 30. d6 Kf8 31. Rc7 f5 32. gxf5 gxf5 33. Nxc5 Rxd6 34. Rxd4 Rxd4 35. Ne6+ Kg8 36. Nxd4 1-0",
    B: "2141",
  },
  {
    W: "1536",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bc5 4. Bc4 d6 5. O-O Bg4 6. d3 Nd4 7. h3 Nxf3+ 8. gxf3 Bxh3 9. Re1 Qh4 10. Ne2 Qg3+ 11. Kh1 Qg2# 0-1",
    B: "1524",
  },
  {
    W: "2007",
    T: "60+0",
    P: "1. d4 c6 2. c4 d5 3. Nc3 Nf6 4. cxd5 cxd5 5. Nf3 e6 6. Bf4 Bd6 7. Bg3 Nc6 8. e3 a6 9. Bd3 Bxg3 10. hxg3 h6 11. Ne5 Nxe5 12. dxe5 Nd7 13. f4 Nc5 14. Bc2 Qb6 15. Rb1 Bd7 16. g4 Bc6 17. g5 h5 18. Rxh5 Rxh5 19. Qxh5 O-O-O 20. Qxf7 Rd7 21. Qf8+ Kc7 22. Bg6 Rd8 23. Qxg7+ Rd7 24. Qf6 Ne4 25. Bxe4 dxe4 26. Qxe6 Qxe3+ 27. Ne2 Bb5 28. Rc1+ Kb8 29. Rc2 Bxe2 30. Rxe2 Qc1+ 31. Kf2 Rd2 32. Qe8+ Ka7 33. Rxd2 Qxd2+ 34. Kg3 Qd3+ 35. Kg4 Qg3+ 36. Kf5 Qxf4+ 37. Kxf4 e3 38. Ke4 e2 0-1",
    B: "2060",
  },
  {
    W: "1992",
    T: "60+0",
    P: "1. d3 c5 2. e4 e6 3. Ne2 Ne7 4. g3 Ng6 5. Bg2 h5 6. O-O h4 7. f4 hxg3 8. Nxg3 a6 9. f5 Ne5 10. fxe6 dxe6 11. Bf4 Bd6 12. Bxe5 Bxe5 13. Qf3 Qc7 14. Nd2 Bxb2 15. Rab1 Bd4+ 16. Kh1 Nc6 17. Nc4 b5 18. Nd6+ Qxd6 19. Qxf7+ Kd8 20. Ne2 Ra7 21. Qf4 Be5 22. Qg5+ Kc7 23. h4 Kb6 24. Rf7 Rxf7 25. c3 Rf4 26. d4 Rf2 27. dxc5+ Qxc5 28. Nd4 Nxd4 29. cxd4 Qxd4 30. Qe7 Bb7 31. Qxe6+ Bc6 32. Rc1 Rc8 33. Rxc6+ Rxc6 34. Qb3 Rc1+ 0-1",
    B: "1970",
  },
  {
    W: "1955",
    T: "60+0",
    P: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. cxd5 exd5 5. Nf3 Bd6 6. g3 Nf6 7. Bg2 O-O 8. O-O h6 9. Re1 Nh7 10. Bd2 Bg4 11. a3 Nd7 12. h3 Bf5 13. Rc1 Ng5 14. Nxg5 hxg5 15. b4 g4 16. h4 f6 17. e4 dxe4 18. Nxe4 Bxe4 19. Rxe4 f5 20. Re6 Nf6 21. Qb3 Kh7 22. Rce1 Qd7 23. Bg5 Ne4 24. Bxe4 fxe4 25. R1xe4 Rae8 26. Rxe8 Rxe8 27. Rxe8 Qxe8 28. Qd3+ g6 29. Bf6 Qe6 30. Be5 Bxe5 31. dxe5 Qxe5 32. Qd8 Qe1+ 33. Kg2 Qe4+ 34. Kg1 Qe6 35. Qc7+ Kh6 36. Qxb7 Qd5 37. Qxa7 Qd1+ 38. Kg2 Qd5+ 39. Kg1 Qd1+ 40. Kg2 Qb1 41. Qc5 Qe4+ 42. Kg1 Kh7 0-1",
    B: "1909",
  },
  {
    W: "2490",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Nf6 3. d4 Nxd5 4. c4 Nb6 5. Nf3 g6 6. Nc3 Bg7 7. h3 O-O 8. Be3 Nc6 9. Be2 e5 10. d5 Ne7 11. O-O Nf5 12. c5 Nxe3 13. fxe3 Nd7 14. b4 e4 15. Nd4 f5 16. Ne6 Qh4 17. Nxg7 Kxg7 18. Qd4+ Nf6 19. d6 cxd6 20. cxd6 Bd7 21. Nd5 Rac8 22. Rac1 Rxc1 23. Rxc1 Rf7 24. Bf1 Bc6 25. Ne7 f4 26. Rxc6 bxc6 27. d7 Rf8 28. d8=Q Rxd8 29. Qxd8 fxe3 30. Qd1 Qf2+ 31. Kh2 Ng4+ 32. Qxg4 Qxf1 33. Qxe4 e2 34. Qe5+ Kh6 35. Ng8# 1-0",
    B: "2312",
  },
  {
    W: "1403",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nf6 3. d3 Nc6 4. Nc3 Bc5 5. Qe2 a6 6. Bg5 b5 7. Bxf6 Qxf6 8. Nd5 Qd8 9. Qd2 d6 10. Qg5 Qxg5 11. Nxg5 b4 12. Nxc7+ Ke7 13. Nxa8 Bd7 14. O-O-O Rxa8 15. Nxh7 f6 16. a3 a5 17. Be2 Rh8 18. Nxf6 gxf6 19. axb4 Nxb4 20. Kb1 a4 21. c3 Nc6 22. f3 Ra8 23. b4 Rb8 24. Kc2 Bb6 25. Ra1 Ra8 26. d4 exd4 27. cxd4 Nxd4+ 28. Kd2 a3 29. Kc3 Nxe2+ 30. Kb3 Be6+ 31. Kc2 a2 32. Rhe1 Nd4+ 33. Kb2 Nb5 34. Rec1 Bd4+ 0-1",
    B: "1427",
  },
  {
    W: "1873",
    T: "900+15",
    P: "1. e4 e6 2. d4 d5 3. e5 c5 4. Nf3 Qb6 5. c4 dxc4 6. Bxc4 cxd4 7. O-O Nc6 8. b3 Bc5 9. Bb2 h6 10. Qd3 Bd7 11. Ba3 Rd8 12. Re1 Nb4 13. Bxb4 Bxb4 14. Rd1 Bc6 15. Bxe6 Bxf3 16. Bxf7+ Kxf7 17. Qxf3+ Ke8 18. Nd2 Ne7 19. Qh3 Rf8 20. Nc4 Qa6 21. e6 Rf5 22. g4 Rfd5 23. Re1 Bxe1 24. Rxe1 Qb5 25. f4 d3 26. f5 d2 27. Rd1 Qc5+ 28. Kf1 Rd3 29. Qg2 R8d4 30. Ke2 Qd5 31. Qxd5 Rxd5 32. Ne3 R5d4 33. Nc4 Rh3 34. Nxd2 Rxh2+ 35. Ke3 Rxg4 36. Ne4 Nxf5+ 37. Kf3 Rgh4 38. Nf2 Ke7 39. Rd7+ Kxe6 40. Rxb7 g5 41. Rxa7 Rf4+ 42. Ke2 Rfxf2+ 43. Kd3 Rxa2 44. Rb7 Nd6 45. Rb4 Rh3+ 46. Kd4 Rd2+ 47. Kc5 Rc3+ 48. Kb6 Rb2 0-1",
    B: "2080",
  },
  {
    W: "1349",
    T: "900+15",
    P: "1. e4 e6 2. d4 c5 3. d5 exd5 4. exd5 Qe7+ 5. Qe2 Qxe2+ 6. Bxe2 Bd6 7. b3 Nf6 8. Nh3 h6 9. Bf4 Bxf4 10. Nxf4 d6 11. Bb5+ Bd7 12. Bxd7+ Nbxd7 13. O-O O-O-O 14. Nc3 a6 15. Rae1 g5 16. Nh5 Nxh5 17. Ne4 Ndf6 18. Nxd6+ Rxd6 19. Re7 Rf8 20. f3 Rxd5 21. g4 Nf4 22. Rxf7 Rxf7 0-1",
    B: "1318",
  },
  {
    W: "1241",
    T: "900+15",
    P: "1. d4 Nc6 2. e4 d6 3. d5 Ne5 4. f4 Ng6 5. g3 Nf6 6. Bd3 Bg4 7. Nf3 c6 8. f5 Ne5 9. Nxe5 dxe5 10. Qd2 cxd5 11. Bb5+ Nd7 12. exd5 Bxf5 13. Qg5 Qa5+ 14. c3 Qxb5 15. Qxf5 e6 16. dxe6 fxe6 17. Qxe6+ Be7 18. Rf1 O-O-O 19. Qxe7 g6 20. Na3 h5 21. Nxb5 1-0",
    B: "1215",
  },
  {
    W: "1509",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. Bxc6 dxc6 5. O-O Bg4 6. d4 exd4 7. Bg5 Be7 8. Bxf6 O-O 9. Bxe7 Qxe7 10. Qxd4 c5 11. Qe5 Rae8 12. Qg3 Qxe4 13. Nbd2 Qxc2 14. Qxg4 Qxb2 15. Ne4 Re6 16. Nfg5 Ra6 17. Qh4 Rxa2 18. Qxh7# 1-0",
    B: "1655",
  },
  {
    W: "1667",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. O-O a6 5. Bxc6 dxc6 6. Nxe5 Nf6 7. Re1 Qd4 8. Nd3 Ba7 9. e5 Nd5 10. Nc3 Nf4 11. Qf3 Nxd3 12. cxd3 Bg4 13. Qg3 h5 14. Re4 Qd7 15. b3 O-O-O 16. Rf4 g6 17. Bb2 Bf5 18. Ne4 Bxe4 19. Rxe4 Qxd3 20. Qxd3 Rxd3 21. e6 f5 22. Bxh8 fxe4 23. e7 Kd7 24. Bf6 Bc5 25. Re1 Bxe7 26. Bxe7 Kxe7 27. Rxe4+ Kf7 28. Re2 Kf6 29. f3 c5 30. Kf2 b5 31. Kg3 c4 32. bxc4 b4 33. Kf4 Rd4+ 34. Re4 Rxd2 35. c5 Rxa2 36. Rxb4 Rc2 37. Ra4 Rxc5 38. Rxa6+ c6 39. g3 Rf5+ 40. Ke4 Re5+ 41. Kf4 Re6 42. h4 Rd6 43. Ke4 Re6+ 44. Kd4 c5+ 45. Kxc5 Rxa6 46. Kd5 Ra3 47. Ke4 Ra4+ 48. Ke3 Kf5 49. Kf2 g5 50. hxg5 Kxg5 51. Ke3 Kf6 52. Kf2 Kf5 53. Kg2 Ra3 54. Kh3 Rxf3 55. Kh4 Kg6 56. g4 hxg4 57. Kxg4 Rf1 58. Kg3 Kf6 59. Kg2 Rf5 60. Kg3 Ke6 61. Kg2 Ke5 62. Kg3 Ke4 63. Kg2 Rf8 64. Kg3 Rf7 65. Kg2 Ke3 66. Kg3 Rg7+ 67. Kh4 Kf3 68. Kh5 Rg1 69. Kh6 Kf4 70. Kh7 Kf5 71. Kh8 Kf6 72. Kh7 Rh1+ 73. Kg8 Ke6 74. Kg7 Rf1 75. Kg6 Rg1+ 76. Kh7 Kf6 77. Kh8 Kf7 78. Kh7 Rh1# 0-1",
    B: "1726",
  },
  {
    W: "1372",
    T: "180+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. Bc4 b5 4. Bb3 a5 5. Ng5 e6 6. Nxf7 Kxf7 7. Qf3+ Nf6 8. e5 a4 9. exf6 Bxf6 10. Qxa8 Nc6 11. Bxa4 bxa4 12. Qxa4 e5 13. Nc3 Nd4 14. O-O d6 15. Nd5 Bd7 16. Qc4 Be6 17. Qxc7+ Qxc7 18. Nxc7 Bd7 19. c3 Nf5 20. Nd5 Be6 21. Nxf6 Kxf6 22. h3 Bd5 23. d3 Nh4 24. f3 Nf5 25. c4 Bb7 26. b4 Ng3 27. Kf2 Nf5 28. a4 Nd4 29. Ra2 Rf8 30. a5 Kg7 31. Kg1 Nb3 32. a6 Ba8 33. b5 Rb8 34. Be3 Nd4 35. Bxd4 exd4 36. Rb1 Kf6 37. b6 Bc6 38. a7 Rd8 39. b7 Bxb7 40. Rxb7 Ra8 41. Rb8 Rxa7 42. Rxa7 Kg5 43. Rxh7 Kf4 44. Rbb7 Ke3 45. Rbg7 Kd2 46. Rxg6 Kc3 47. Rxd6 Kxd3 48. Rxd4+ Kxd4 49. Rd7+ Kxc4 50. Rc7+ Kd5 51. h4 Ke5 52. h5 Ke6 53. Rg7 Kf6 54. h6 Ke5 55. g4 Kd4 56. h7 Ke3 1/2-1/2",
    B: "1393",
  },
  {
    W: "1217",
    T: "180+0",
    P: "1. e4 Nf6 2. Nc3 e6 3. Nf3 d5 4. d3 dxe4 5. dxe4 Qxd1+ 6. Nxd1 Nxe4 7. Bd3 Nc5 8. Nc3 Nxd3+ 9. cxd3 Bb4 10. Bd2 Nc6 11. Nb5 Bxd2+ 12. Nxd2 Kd7 13. O-O b6 14. Rad1 Ba6 15. Nd4 Bxd3 16. Nxc6 Kxc6 17. Rfe1 Bc2 18. Rc1 Kb5 19. Rxc2 Rhd8 20. h3 Kb4 21. Rc4+ Kb5 22. f4 Rxd2 23. Kf1 Kxc4 24. f5 exf5 25. g4 fxg4 26. hxg4 Rxb2 27. Re4+ Kd3 28. Re7 Rxa2 29. Rxf7 Rg8 30. Rxc7 Rc2 31. Rxa7 Rb2 32. g5 b5 33. Ra1 b4 34. Rd1+ Kc2 35. Ke1 Rb1 36. Rxb1 Kxb1 37. Kd2 Rc8 38. Ke3 Ka2 39. Kf4 b3 40. Kf5 b2 41. Ke6 b1=Q 42. Kf7 Qb7+ 43. Ke6 Rc6+ 0-1",
    B: "1286",
  },
  {
    W: "2018",
    T: "180+0",
    P: "1. g3 c6 2. Bg2 d5 3. Nh3 Bg4 4. O-O Qd7 5. Nf4 e5 6. Nd3 e4 7. Ne5 Qf5 8. Nxg4 Qxg4 9. Re1 Bc5 10. Nc3 Nf6 11. e3 Qxd1 12. Nxd1 Nbd7 13. f3 exf3 14. Bxf3 O-O 15. d4 Bb6 16. c3 Rfe8 17. Nf2 c5 18. Bd2 cxd4 19. exd4 Kf8 20. Kg2 Re7 21. Rxe7 Kxe7 22. Re1+ Kf8 23. Bf4 Re8 24. Bd6+ Kg8 25. Rxe8+ Nxe8 26. Bf4 Nef6 27. Be5 Nxe5 28. dxe5 Nd7 29. Bxd5 Nxe5 30. Bxb7 Nc4 31. b3 Ne3+ 32. Kf3 Nc2 33. Be4 Ne1+ 34. Ke2 f5 35. Bd5+ Kf8 36. Kxe1 Ke7 37. Nd3 Kd6 38. Bf3 g5 39. b4 Bg1 40. h3 Bh2 41. g4 f4 42. Ke2 Bg3 43. c4 Kc7 44. a4 Kb6 45. c5+ Kc7 46. b5 Kb8 47. a5 Kc7 48. b6+ axb6 49. axb6+ Kb8 50. c6 h6 51. Nc5 Bh4 52. Na6+ Kc8 53. b7+ Kd8 54. b8=Q+ Ke7 55. Qc7+ Kf6 56. Qd6+ Kg7 57. Qe7+ Kg6 58. Be4# 1-0",
    B: "1938",
  },
  {
    W: "2079",
    T: "180+0",
    P: "1. d4 d5 2. Bf4 c6 3. Nf3 Bg4 4. e3 Nf6 5. Nbd2 h6 6. a3 Nbd7 7. h3 Bxf3 8. Nxf3 Ne4 9. c4 e6 10. Rc1 g5 11. Bh2 Bg7 12. cxd5 exd5 13. Bd3 f5 14. O-O O-O 15. Qb3 Qb6 16. Qxb6 axb6 17. Rc2 b5 18. Rfc1 Rae8 19. Nd2 f4 20. exf4 gxf4 21. Nf3 Re7 22. b3 Ref7 23. Re1 Ng5 24. Ne5 Nxe5 25. dxe5 f3 26. Bg6 Re7 27. e6 Rxe6 28. Rxe6 Nxe6 29. gxf3 Rxf3 30. Re2 Rf6 31. Bd3 Nc5 32. Bc2 Ne4 33. Bxe4 dxe4 34. Rxe4 Rf3 35. Re3 Rf5 36. Re8+ Kf7 37. Rb8 Rf3 38. Rxb7+ Kg6 39. Rb6 Rc3 40. a4 bxa4 41. bxa4 Rc2 42. a5 Bd4 43. Ra6 Rxf2 44. Kh1 Rc2 45. Ra8 Ra2 46. a6 h5 47. Bc7 h4 48. Bd6 Kf5 49. a7 Rxa7 50. Rxa7 Bxa7 51. Kg2 Ke4 0-1",
    B: "2166",
  },
  {
    W: "2252",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Nc3 Qc7 6. g3 b5 7. Bg2 Bb7 8. O-O Nc6 9. Re1 Be7 10. Be3 Ne5 11. f4 Nc4 12. b3 Nxe3 13. Rxe3 Bc5 14. Nd5 exd5 15. exd5+ Kf8 16. Qd2 b4 17. Rae1 Nf6 18. Kh1 Nxd5 19. Re5 Bxd4 20. Qxd4 Nf6 21. Bxb7 Qxb7+ 22. Kg1 h5 23. Qd6+ Kg8 24. Rg5 Rh6 25. Re7 h4 26. g4 h3 27. Re2 Rh4 28. Qxf6 Rh7 29. Qe5 Rf8 30. Qe7 Rh6 31. f5 f6 32. Qxg7# 1-0",
    B: "2231",
  },
  {
    W: "1426",
    T: "180+0",
    P: "1. d4 Nc6 2. c4 d5 3. Nf3 dxc4 4. e4 e6 5. Bxc4 Bb4+ 6. Nc3 Bxc3+ 7. bxc3 a6 8. O-O b5 9. Bb3 Nf6 10. Re1 a5 0-1",
    B: "1466",
  },
  {
    W: "1786",
    T: "180+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. Nf3 a6 4. a3 e5 5. d4 cxd4 6. Nd5 d6 7. c4 Bg4 8. Be2 Nge7 9. h3 Bxf3 10. gxf3 Nxd5 11. exd5 Ne7 12. Rg1 h6 13. f4 exf4 14. Bxf4 Ng6 15. Qa4+ Qd7 16. Qxd7+ Kxd7 17. Bh2 Rb8 18. Bg4+ Kc7 19. b4 Be7 20. a4 Bf6 21. Kd2 b5 22. cxb5 axb5 23. axb5 Rxb5 24. Rgc1+ Kb6 25. Ra4 Bg5+ 26. f4 Bxf4+ 27. Bxf4 Nxf4 28. Rc6+ Kb7 29. Rxd6 Nxd5 30. Bf3 Kc7 31. Rxd5 Rxd5 32. Bxd5 f6 33. Kd3 Rd8 34. Kxd4 Re8 35. Ra7+ Kb6 36. Rxg7 Re5 37. Rb7+ Ka6 38. b5+ Ka5 39. Kc5 Rxd5+ 40. Kxd5 Kb4 41. Kc6 f5 42. Rf7 f4 43. b6 h5 44. Rxf4+ 1-0",
    B: "1789",
  },
  {
    W: "1695",
    T: "180+0",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7 5. e3 O-O 6. Nf3 b6 7. h4 Bb7 8. Bd3 dxc4 9. Bxc4 c5 10. Qc2 Nc6 11. h5 cxd4 12. exd4 Nxd4 13. Nxd4 Qxd4 14. Ne2 Qe5 15. f4 Qe4 16. Bd3 Qxg2 17. Rg1 Qh3 18. Bxh7+ Nxh7 19. Bxe7 Rfc8 20. Qb3 Qxh5 21. Rd1 Ba6 22. Qe3 Bxe2 23. Qxe2 Qxe2+ 24. Kxe2 Rc2+ 25. Rd2 Rac8 26. Rd1 Rxd2+ 27. Rxd2 Nf6 28. Rd8+ Rxd8 29. Bxd8 Kh7 30. Bxf6 gxf6 31. Ke3 Kg6 32. b3 Kf5 33. a3 a5 34. b4 axb4 35. axb4 b5 36. Kf3 e5 37. fxe5 fxe5 38. Ke3 f6 39. Kf3 e4+ 40. Ke3 Ke5 41. Kf2 f5 42. Ke2 f4 0-1",
    B: "1726",
  },
  {
    W: "1854",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O Bd7 7. Nc3 Qc8 8. e4 Bh3 9. Be3 Bxg2 10. Kxg2 Nbd7 11. h3 c5 12. b3 e5 13. d5 Ne8 14. Qc2 f5 15. Ng5 Ndf6 16. Ne6 Rf7 17. exf5 gxf5 18. Qxf5 Nd7 19. Qc2 e4 20. Rae1 Nf8 21. Qxe4 Re7 22. Bg5 Bxc3 23. Bxe7 Bxe1 24. Bxf8 Bc3 25. Bh6 Nf6 26. Qf3 Qd7 27. Qxc3 Ne4 28. Qg7+ Qxg7 29. Bxg7 Nd2 30. Rd1 Ne4 31. Re1 Ng5 32. Nxg5 Kxg7 33. Re6 Rd8 34. Re4 Kf6 35. Nxh7+ Kf5 36. Re6 Rh8 37. g4+ Kf4 38. Nf6 Rf8 39. Nh5+ Kg5 40. Rxd6 b6 41. f4+ Kh4 42. Rf6 Re8 43. Re6 Rf8 44. Nf6 b5 45. g5 bxc4 46. bxc4 Rb8 47. Re7 Rb2+ 48. Kf3 Rxa2 49. Rh7# 1-0",
    B: "1861",
  },
  {
    W: "1933",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. h3 Nf6 5. Nf3 e6 6. Bc4 Be7 7. d4 Bd7 8. O-O c5 9. d5 exd5 10. Nxd5 O-O 11. Nxe7+ Qxe7 12. Bg5 Be6 13. Bxe6 Qxe6 14. Re1 Qf5 15. Bxf6 Qxf6 16. c3 Nc6 17. Qd7 Rab8 18. Rad1 Rfd8 19. Qg4 Rxd1 20. Rxd1 Re8 21. Qg5 Qe6 22. Qxc5 Ne7 23. Re1 Qf6 24. Nd4 Kf8 25. Nf5 g6 26. Nxe7 Rxe7 27. Rxe7 Qxe7 28. Qxe7+ Kxe7 29. Kf1 Ke6 30. Ke2 Kd5 31. Kd3 b5 32. b3 a5 33. c4+ bxc4+ 34. bxc4+ Kc5 35. Kc3 a4 36. a3 f5 37. f3 h6 38. g3 g5 39. g4 f4 40. Kd3 Kd6 41. Kd4 Kc6 42. c5 Kd7 43. Kc4 Kc6 44. Kb4 Kd5 45. Kb5 Kd4 46. c6 Ke3 47. c7 Kxf3 48. c8=Q Kg3 49. Qc3+ Kh4 50. Kxa4 h5 51. gxh5 Kxh5 52. Qf3+ Kh4 53. Qg4# 1-0",
    B: "1887",
  },
  {
    W: "1598",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nf3 Qd8 4. d4 Nf6 5. Nc3 c6 6. Bg5 Bg4 7. Bxf6 exf6 8. Qe2+ Be7 9. O-O-O O-O 10. Re1 Bxf3 11. gxf3 Re8 12. Rg1 g6 13. h4 f5 14. Kb1 Na6 15. h5 Nc7 16. hxg6 hxg6 17. f4 Bd6 18. Qd2 Rxe1+ 19. Qxe1 Bxf4 20. f3 Kg7 21. Bc4 Qxd4 22. Bd3 Re8 23. Qh4 Qxg1+ 24. Bf1 Qxf1+ 25. Qe1 Qxe1+ 26. Nd1 Qxd1# 0-1",
    B: "1649",
  },
  {
    W: "1759",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Nf3 Nf6 6. Nc3 Bb4 7. Bd3 O-O 8. Bg5 h6 9. Bh4 g5 10. Bg3 Nd4 11. Bxe5 Nxf3+ 12. Qxf3 Nh7 13. O-O d6 14. Bd4 Be6 15. Nd5 Bxd5 16. exd5 Bc5 17. Bxh7+ Kxh7 18. Qf5+ Kg8 19. Bf6 Qc8 20. Qxc8 Raxc8 21. c3 a5 22. Rad1 Rce8 23. Bd4 f5 24. Rfe1 Rd8 25. Re6 Kh7 26. Re7+ Kg6 27. Rxb7 Rfe8 28. Rg7+ Kh5 29. Rf7 Bxd4 30. cxd4 f4 31. Rf6 Re2 32. Rb1 Rd2 33. Rf5 Rxd4 34. f3 Rd2 35. Kf1 Re8 36. Rf6 Rxd5 37. b3 Rd2 38. Rf5 Ree2 39. Rxa5 Rxg2 40. Ra6 Rxh2 41. Rc6 Rh1# 0-1",
    B: "1770",
  },
  {
    W: "1508",
    T: "180+0",
    P: "1. e4 e5 2. Qh5 Nf6 3. Qxe5+ Qe7 4. Qxc7 Qxe4+ 5. Ne2 Na6 6. Qc3 Nb4 7. d3 Qg4 8. a3 Nfd5 9. Qd2 Nc6 10. c4 Nf6 11. f3 Qh4+ 12. g3 Qh6 13. Qxh6 gxh6 14. Nbc3 a6 15. Bg2 Nd4 16. Nxd4 a5 17. Ndb5 Ra6 18. Nc7+ Kd8 19. Nxa6 bxa6 20. Nd5 Nxd5 21. cxd5 Bb7 22. f4 Bd6 23. Bd2 Ke7 24. Bxa5 Rc8 25. O-O Rc2 26. Rae1+ Kf8 27. b4 Ra2 28. Be4 Rxa3 29. Bxh7 Rxd3 30. Bxd3 Bxd5 31. Re2 Bc5+ 32. Rff2 Bxf2+ 33. Kxf2 Bc4 34. Bxc4 d5 35. Bxd5 Kg8 36. Bc4 h5 37. Bxa6 h4 38. gxh4 f5 39. b5 Kf7 40. b6 Kg6 41. b7 Kh5 42. b8=Q Kg4 43. Qg8+ Kh3 44. Qg3# 1-0",
    B: "1500",
  },
  {
    W: "1668",
    T: "300+0",
    P: "1. d4 g6 2. c4 Bg7 3. e4 c6 4. f4 e6 5. Nf3 Qa5+ 6. Bd2 Qb6 7. b3 Bxd4 8. Nxd4 Qxd4 9. Nc3 Nf6 10. Qe2 Na6 11. e5 Nb4 12. Rc1 Nh5 13. g4 Nxf4 14. Qe3 Qxe3+ 15. Bxe3 Nfd3+ 16. Bxd3 Nxd3+ 17. Kd2 Nxc1 18. Rxc1 a6 19. Bg5 O-O 20. Bf6 b5 21. Ne4 bxc4 22. Rxc4 a5 23. Nd6 Ba6 24. Rc5 Bf1 25. Be7 Rfb8 26. Ke1 Bh3 27. g5 Bg2 28. h4 a4 29. Rc3 axb3 30. axb3 Ra3 31. Kf2 Bd5 32. Rf3 Bxf3 33. Kxf3 Rbxb3+ 34. Ke4 Ra4+ 0-1",
    B: "1620",
  },
  {
    W: "1699",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d6 3. Bc4 Be6 4. Bxe6 fxe6 5. O-O Be7 6. Nc3 Nf6 7. d3 h6 8. Bd2 O-O 9. Ne1 c6 10. f4 exf4 11. Bxf4 e5 12. Be3 Qd7 13. Nf3 Qe6 14. a4 Nbd7 15. a5 d5 16. exd5 cxd5 17. Bd2 Ng4 18. Qe2 Bc5+ 19. Kh1 Rae8 20. Rae1 Bd6 21. h3 Ngf6 22. Nb5 Bb8 23. Bc3 a6 24. Na3 e4 25. Nd4 Qd6 26. g4 Qg3 27. Qg2 Qh4 28. dxe4 Nxe4 29. Nf5 Rxf5 30. gxf5 Ng3+ 31. Kg1 Ba7+ 32. Kh2 Nxf1+ 33. Rxf1 Nf6 34. Nb1 Re3 35. Nd2 Bb8+ 36. Kg1 Rg3 37. Bxf6 Rxg2+ 38. Kxg2 Qg3+ 39. Kh1 Qh2# 0-1",
    B: "1908",
  },
  {
    W: "1374",
    T: "300+0",
    P: "1. d4 d5 2. c4 Nf6 3. Nf3 e6 4. Nc3 b6 5. e3 Bb7 6. b3 Bb4 7. Bb2 dxc4 8. bxc4 c5 9. Bd3 O-O 10. O-O cxd4 11. exd4 Nc6 12. Re1 Re8 13. Qc2 Nxd4 14. Nxd4 Qxd4 15. Nd5 Nxd5 16. Bxd4 Nf4 17. Qb2 Be7 18. Bf1 Red8 19. Bxg7 Rac8 20. Bh6 Nd3 21. Qg7# 1-0",
    B: "1532",
  },
  {
    W: "1987",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. d3 b5 6. Bb3 Be7 7. O-O d6 8. a4 Bb7 9. axb5 axb5 10. Rxa8 Qxa8 11. Nbd2 O-O 12. c3 Na5 13. Bc2 c5 14. Re1 Nc6 15. Nf1 g6 16. Bg5 Qd8 17. Ne3 Kg7 18. Bxf6+ Bxf6 19. Nd5 h6 20. Nd2 Nb8 21. Nxf6 Qxf6 22. Nf1 Nd7 23. Ne3 Ra8 24. Qd2 Qg5 25. d4 cxd4 26. cxd4 Qe7 27. Ng4 g5 28. dxe5 Nxe5 29. Nxe5 Qxe5 30. Bb3 Rc8 31. Rd1 Rd8 32. g3 Qxe4 33. Qd4+ f6 34. Qb6 Qg2# 0-1",
    B: "2074",
  },
  {
    W: "1816",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Bc5 3. Bc4 Nf6 4. O-O O-O 5. c3 c6 6. d4 exd4 7. cxd4 Bb6 8. e5 d5 9. exd6 Qxd6 10. h3 Nbd7 11. Nc3 Nd5 12. Re1 Nxc3 13. bxc3 Nf6 14. Ne5 Be6 15. Bb3 Rad8 16. Bf4 Nd5 17. Bxd5 Bxd5 18. a4 Bc7 19. Bc1 f6 20. Ba3 Qe6 21. Bxf8 fxe5 22. Bb4 Bb3 23. Qf3 Bd5 24. Qh5 a5 25. Ba3 e4 26. f3 g6 27. Qh6 b5 28. fxe4 Bxe4 29. axb5 cxb5 30. Qh4 Re8 31. Re3 Bd8 32. Qf4 b4 33. cxb4 axb4 34. Bxb4 Bb6 35. Bc5 Bxc5 36. dxc5 Qe5 37. Qxe5 Rxe5 38. Rae1 Rxc5 39. Rxe4 Kg7 40. Re7+ Kh6 41. Kh2 Rc2 42. R1e2 Rc1 43. g4 Rc5 44. h4 Rc6 45. g5+ Kh5 46. Rxh7+ Kg4 47. Re4+ Kf5 48. Rhe7 Rc2+ 49. Re2 Rc5 50. Kh3 Rc3+ 51. R2e3 Rc5 52. Rf7# 1-0",
    B: "1741",
  },
  {
    W: "2092",
    T: "600+0",
    P: "1. e4 d5 2. e5 Bf5 3. d4 e6 4. Bd3 Ne7 5. Nf3 Bg6 6. O-O Nf5 7. c3 Nd7 8. b4 c6 9. Bb2 Be7 10. a3 Nb6 11. a4 Nc4 12. Bc1 Nh4 13. Bxg6 hxg6 14. Nxh4 Bxh4 15. f4 Be7 16. Na3 Qd7 17. Nxc4 dxc4 18. Qf3 Qd5 19. Qxd5 cxd5 20. Ba3 Kd7 21. b5 b6 22. Bxe7 Kxe7 23. g3 a6 24. bxa6 Rxa6 25. Rfb1 Rha8 26. Rb4 R8a7 27. h4 Kd7 28. Kf2 Kc7 29. Ke3 Kc6 30. g4 Ra8 31. f5 gxf5 32. gxf5 Rh8 33. Rh1 exf5 34. Kf4 g6 35. Kg5 Kd7 36. Kf6 Ke8 37. Kg7 Rh5 38. Rhb1 Rxh4 39. Rxb6 Rxb6 40. Rxb6 Rh3 41. Rb8+ Kd7 42. Kxf7 Rxc3 43. Kxg6 f4 44. Rf8 f3 45. a5 Ra3 46. a6 Rxa6+ 47. Kg5 c3 48. Rxf3 c2 49. Rc3 Rc6 50. e6+ Kd6 51. Rxc6+ Kxc6 52. e7 Kd7 0-1",
    B: "2149",
  },
  {
    W: "1616",
    T: "600+0",
    P: "1. e4 e6 2. d4 c5 3. Nf3 cxd4 4. Nxd4 Nc6 5. Nxc6 bxc6 6. Bd3 Bc5 7. Nd2 Qg5 8. O-O Qh4 9. Nf3 Qd8 10. Bd2 Qb6 11. Bc3 f6 12. e5 Bb4 13. Qd2 a5 14. a3 Be7 15. exf6 Bxf6 16. b4 axb4 17. axb4 Rxa1 18. Rxa1 Bb7 19. Ne5 Ne7 20. Nc4 Qc7 21. Ra7 Nd5 22. Bxf6 Nxf6 23. Rxb7 Qd8 24. Qc3 Ke7 25. Ne3 Qc8 26. Qc5+ Kf7 27. Ba6 Qe8 28. b5 Ne4 29. Qd4 Ng5 30. Qf4+ Kg6 31. bxc6 Rf8 32. Bd3+ Rf5 33. Nxf5 Nh3+ 34. gxh3 1-0",
    B: "1585",
  },
  {
    W: "1286",
    T: "600+0",
    P: "1. Nf3 f5 2. Nc3 Nc6 3. e4 g6 4. Bd3 d6 5. exf5 gxf5 6. Nd5 e5 7. Qe2 e4 8. O-O Nge7 9. Nxe7 Qxe7 10. Bb5 exf3 11. Qxf3 Bd7 12. d4 Nxd4 13. Qd3 Nxb5 14. Bf4 Bg7 15. Rad1 O-O-O 16. a4 Nd4 17. Rfe1 Be5 18. Be3 c5 19. c3 f4 20. Bxd4 cxd4 21. cxd4 Bf6 22. Rxe7 Bxe7 23. Rc1+ Kb8 24. b4 Bxa4 25. d5 a6 26. Ra1 Bb5 27. Qf5 Rc8 28. Qf6 Bxf6 29. Re1 Rhe8 30. Rxe8 Rxe8 31. h3 Rg8 32. g3 fxg3 33. Kg2 gxf2+ 34. Kxf2 Rg5 35. h4 Rxd5 36. Ke3 Bxh4 37. Ke4 Bc4 38. Kf4 Bg5+ 39. Kg4 Be2+ 40. Kg3 h5 0-1",
    B: "1338",
  },
  {
    W: "1863",
    T: "600+0",
    P: "1. d4 d5 2. c4 Bf5 3. Nc3 Nc6 4. cxd5 Nb4 5. e4 Bg6 6. a3 Na6 7. Bd3 Nf6 8. Be3 Qd7 9. Nf3 e6 10. dxe6 Qxe6 11. d5 Qc8 12. Bb5+ Nd7 13. Bd3 Bd6 14. Bd4 O-O 15. e5 Bc5 16. Bxc5 Naxc5 17. Bxg6 hxg6 18. O-O c6 19. e6 fxe6 20. dxe6 Nxe6 21. Re1 Nf4 22. Qd6 Nb6 23. Re7 Rf6 24. Qc7 Qxc7 25. Rxc7 Rb8 26. Re1 Nbd5 27. Nxd5 Nxd5 28. Rd7 b5 29. Rxa7 b4 30. axb4 Rxb4 31. Ra8+ Rf8 32. Ra6 Rc8 33. g3 Rxb2 34. Re6 c5 35. Rxg6 c4 36. Ra7 c3 37. Rgxg7+ Kf8 38. Rh7 Kg8 39. Rag7+ Kf8 40. Ne5 Rc6 41. Nd7+ Ke8 42. Rh8# 1-0",
    B: "2037",
  },
  {
    W: "1762",
    T: "600+0",
    P: "1. e3 e5 2. c4 d5 3. d4 Nf6 4. Nf3 e4 5. Nfd2 c5 6. cxd5 Qxd5 7. Bc4 Qg5 8. O-O Bh3 9. g3 Bxf1 10. Qxf1 Bd6 11. Nc3 Qg4 12. Be2 Qf5 13. Qg2 cxd4 14. exd4 O-O 15. Ndxe4 Nxe4 16. Nxe4 Qd7 17. Nxd6 Qxd6 18. Qxb7 Nc6 19. Be3 Nxd4 20. Bf1 Nc2 21. Bf4 Qb4 22. Qxb4 Nxb4 23. a3 Nc2 24. Rc1 Nd4 25. b4 Rfe8 26. Be3 Nf3+ 27. Kg2 Rxe3 28. fxe3 Nd2 29. Ba6 Ne4 30. a4 Rd8 31. b5 Kf8 32. a5 Rd2+ 33. Kh3 Re2 34. b6 Rb2 35. bxa7 Ng5+ 36. Kg4 Rb4+ 37. Kxg5 h6+ 38. Kf5 g6+ 39. Ke5 f6+ 40. Kxf6 1-0",
    B: "1722",
  },
  {
    W: "1715",
    T: "600+0",
    P: "1. e4 d6 2. h3 e6 3. a3 Nf6 4. d3 Be7 5. Bg5 b6 6. Nc3 Bb7 7. Qd2 Nfd7 8. Be3 c5 9. Nce2 b5 10. f4 a5 11. Nf3 b4 12. Ng3 bxa3 13. bxa3 a4 14. Be2 O-O 15. O-O Nc6 16. Rab1 Ba6 17. f5 exf5 18. Nxf5 Nde5 19. Nxe5 dxe5 20. Nxe7+ Nxe7 21. Bg5 f6 22. Bh4 c4 23. Qe3 cxd3 24. cxd3 Ng6 25. Bf2 Nf4 26. Rfd1 Nxe2+ 27. Qxe2 Qe7 28. Rb6 Qxa3 29. Rc1 Qxc1+ 0-1",
    B: "1718",
  },
  {
    W: "1191",
    T: "600+0",
    P: "1. d4 d5 2. Nc3 Nc6 3. e4 e6 4. e5 Be7 5. Bb5 a6 6. Bxc6+ bxc6 7. Nf3 c5 8. dxc5 Bxc5 9. Be3 Qe7 10. Bxc5 Qxc5 11. O-O f6 12. exf6 Nxf6 13. Ne5 O-O 14. Qd3 g6 15. Nxg6 hxg6 16. Qxg6+ Kh8 17. Rae1 d4 18. Ne4 Qxc2 19. Nxf6 Qxg6 20. Rxe6 Bxe6 21. Re1 Qxf6 22. Rxe6 Qxf2+ 0-1",
    B: "1017",
  },
  {
    W: "1944",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Nb5 d6 6. N1c3 a6 7. Na3 Nf6 8. Bg5 Be7 9. Bxf6 Bxf6 10. Nd5 O-O 11. Be2 b5 12. c3 Be6 13. O-O Bg5 14. Nc2 f5 15. exf5 Bxf5 16. Nce3 Bxe3 17. Nxe3 Bg6 18. Qd5+ Kh8 19. Qxc6 Qh4 20. Qxd6 Rae8 21. Qxa6 Be4 22. f3 Rf6 23. Qxb5 Bc6 24. Qc4 Qg5 25. Ng4 Rg6 26. Rf2 h5 27. Bd3 Rd6 28. Qc5 Rde6 29. h4 Qxh4 30. Ne3 e4 31. fxe4 Bxe4 32. Bxe4 Rxe4 33. Nf5 Qg5 34. b4 h4 35. Nd6 Re1+ 36. Rxe1 Rxe1+ 37. Rf1 Rxf1+ 38. Kxf1 Qf4+ 39. Kg1 Qc1+ 40. Kf2 Qf4+ 41. Ke2 Qg4+ 42. Kd3 Qxg2 43. Qh5+ Kg8 44. Qf7+ Kh7 45. Qh5+ Kg8 46. Qe8+ Kh7 47. Qe4+ g6 48. Qxg2 1-0",
    B: "1944",
  },
  {
    W: "1563",
    T: "600+0",
    P: "1. g4 e5 2. c4 Nc6 3. Bg2 d6 4. Nc3 Bxg4 5. Qb3 Nf6 6. Nd5 Rb8 7. Nxf6+ Qxf6 8. Bxc6+ Kd8 9. c5 Kc8 10. cxd6 bxc6 11. d7+ Kxd7 12. Qxb8 Bc5 13. Qxh8 Qxf2+ 14. Kd1 Qf1+ 0-1",
    B: "1501",
  },
  {
    W: "1368",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. O-O Bc5 6. e5 Ng4 7. Bg5 Be7 8. Bxe7 Qxe7 9. Nxd4 Ngxe5 10. Nxc6 bxc6 11. Re1 d5 12. Bd3 O-O 13. f4 Bg4 14. Qxg4 Qc5+ 15. Kh1 Nxg4 16. h3 Nf2+ 17. Kh2 Nxd3 18. cxd3 Qf2 19. Nc3 Qxf4+ 20. g3 Qf2+ 21. Kh1 Qxg3 22. Rg1 Qxh3# 0-1",
    B: "1455",
  },
  {
    W: "1652",
    T: "600+0",
    P: "1. e4 e5 2. Bc4 Nc6 3. a3 Nf6 4. d3 d5 5. exd5 Nxd5 6. c3 Bc5 7. Qf3 Be6 8. Nd2 O-O 9. Ne4 Bb6 10. Ne2 f5 11. N4g3 f4 12. Ne4 Bf5 13. O-O Kh8 14. h3 Bxe4 15. Qxe4 Nf6 16. Qf3 Qd7 17. Bb5 Rae8 18. Bxc6 bxc6 19. Rd1 e4 20. Qxf4 exd3 21. Ng3 Nh5 22. Qh4 Rxf2 23. Kh2 Qd5 24. Rg1 d2 25. Bxd2 Rxd2 26. Nxh5 Bxg1+ 27. Rxg1 Rxb2 28. Qg4 g6 29. Nf6 Qe5+ 0-1",
    B: "1622",
  },
  {
    W: "1269",
    T: "600+0",
    P: "1. Nf3 d5 2. e4 d4 3. e5 Nc6 4. Bb5 Bg4 5. h3 Bxf3 6. gxf3 Qd5 7. Qe2 d3 8. cxd3 a6 9. Bxc6+ Qxc6 10. Nc3 e6 11. d4 Bb4 12. Ne4 f5 13. Ng3 Nh6 14. Nh5 O-O 15. Nf6+ gxf6 16. f4 Qxh1+ 17. Qf1 Qe4+ 18. Kd1 Qxd4 19. Qg1+ Kh8 20. a3 Ba5 21. d3 Qxd3+ 22. Bd2 Qxd2# 0-1",
    B: "1201",
  },
  {
    W: "1288",
    T: "300+0",
    P: "1. c4 e6 2. Nc3 Nf6 3. g3 d5 4. cxd5 Nxd5 5. Bg2 Nxc3 6. bxc3 Bc5 7. Nf3 O-O 8. O-O Nc6 9. d4 Bd6 10. c4 Nb4 11. c5 Be7 12. a3 Nc6 13. a4 Bf6 14. Bb2 Re8 15. e3 Bd7 16. Qb3 Na5 17. Qb4 c6 18. Bc3 b6 19. cxb6 axb6 20. Rfb1 Ra6 21. Bf1 Be7 22. Qb2 Ra8 23. Qxb6 Qxb6 24. Rxb6 c5 25. dxc5 Bxc5 26. Rb2 Nb7 27. Rxb7 Bxa4 28. Ne5 f6 29. Nd7 Bc6 30. Rc7 Rxa1 31. Bxa1 Bxd7 32. Rxd7 Ra8 33. Bd4 Bb4 34. Rb7 Be7 35. Rxe7 e5 36. Bc5 Ra5 37. Rc7 Ra1 38. Kg2 Ra5 39. Bc4+ 1-0",
    B: "1301",
  },
  {
    W: "2441",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. Bd2 cxd4 6. Nb5 Bc5 7. b4 Be7 8. f4 Nc6 9. Nf3 a6 10. Nbxd4 Nxb4 11. Rb1 Nc6 12. Bd3 Bd7 13. O-O Bc5 14. c3 Nge7 15. Rxb7 Rb8 16. Bxa6 O-O 17. Rxb8 Qxb8 18. Bd3 Qa7 19. Be3 Qxa2 20. Kh1 Nxd4 21. cxd4 Bb4 22. Bxh7+ Kxh7 23. Ng5+ Kg6 24. Qg4 f5 25. Qg3 Rg8 26. Qh4 1-0",
    B: "2393",
  },
  {
    W: "1783",
    T: "300+0",
    P: "1. d4 d5 2. Bf4 Nf6 3. e3 h5 4. Bd3 e5 5. dxe5 g5 6. Bxg5 1-0",
    B: "1796",
  },
  {
    W: "1572",
    T: "300+0",
    P: "1. c4 e5 2. Nc3 Nc6 3. g3 f5 4. Bg2 Nge7 5. e3 d6 6. Nge2 Be6 7. b3 g5 8. f4 gxf4 9. exf4 e4 10. O-O Qd7 11. Bb2 Bg7 12. Qc2 Bd4+ 13. Kh1 O-O-O 14. Nb5 Bxb2 15. Qxb2 h5 16. h4 Rhg8 17. Kh2 a6 18. Nbd4 Rg6 19. Nxe6 Qxe6 20. Nd4 Nxd4 21. Qxd4 Nc6 22. Qe3 Rdg8 23. Bh3 Qf6 24. Rg1 Qb2 25. Qc3 Qa3 26. Qc1 Qc5 27. Qc3 Qf2+ 28. Rg2 Qc5 29. a3 Nd4 30. b4 Nf3+ 31. Kh1 Qc6 32. Bxf5+ Kb8 33. Qe3 Rxg3 34. Rxg3 Rxg3 35. Qxe4 Rh3+ 36. Kg2 Qxe4 37. Bxe4 Rh2+ 38. Kxf3 Rxd2 39. f5 Rd4 40. Rc1 Kc8 41. f6 Kd7 42. Bf5+ Ke8 43. Re1+ Kf8 44. Re7 Rxh4 45. Rxc7 Rd4 46. c5 dxc5 47. Rxc5 h4 48. Rc8+ Kf7 49. Rc7+ Kxf6 50. Be4 Rd6 51. Rxb7 Ke5 52. Re7+ Kf6 53. Rh7 Ke5 54. Bb1 Rf6+ 55. Kg4 Rf4+ 56. Kh3 1-0",
    B: "1568",
  },
  {
    W: "1364",
    T: "300+0",
    P: "1. d4 e5 2. d5 d6 3. e4 f5 4. Nc3 Nf6 5. Bd3 Nbd7 6. Bg5 Be7 7. Nf3 O-O 8. O-O c6 9. Re1 cxd5 10. Nxd5 Nxd5 11. exd5 Bxg5 12. Nxg5 Qxg5 13. c4 Nc5 14. Bc2 f4 15. Qd2 Bf5 16. b4 Bxc2 17. bxc5 Bf5 18. cxd6 Rad8 19. c5 e4 20. a4 e3 21. fxe3 fxe3 22. Rxe3 Rfe8 23. Rxe8+ 1-0",
    B: "1364",
  },
  {
    W: "1929",
    T: "300+0",
    P: "1. e4 c5 2. f4 d5 3. e5 Nc6 4. Nf3 Bf5 5. Bb5 Qb6 6. Bxc6+ Qxc6 7. d4 e6 8. c3 Nh6 9. O-O Be7 10. Be3 Ng4 11. Bf2 O-O 12. h3 Nxf2 13. Rxf2 Qb6 14. b3 Rac8 15. g4 Be4 16. Rd2 f6 17. f5 cxd4 18. cxd4 exf5 19. exf6 Bxf6 20. gxf5 Bxf3 21. Qxf3 Bxd4+ 22. Kg2 Bxa1 23. Qxd5+ Kh8 24. Na3 Rcd8 25. Qxd8 Rxd8 26. Rxd8+ Qxd8 0-1",
    B: "1915",
  },
  {
    W: "925",
    T: "300+0",
    P: "1. d4 e5 2. c4 f6 3. Nf3 c6 4. Be3 Qa5+ 5. Nc3 Bb4 6. Bd2 d5 7. e4 exd4 8. Nxd4 Bxc3 9. Bxc3 Qc5 10. Qg4 Bxg4 11. b4 Qe7 12. Rc1 Qxe4+ 13. Be2 Qxg2 14. Rf1 Bxe2 15. Nxe2 dxc4 16. Bd4 Qe4 17. Kd2 Ne7 18. f3 Qe6 19. Nc3 Nf5 20. Rfe1 Ne3 21. Rxe3 1-0",
    B: "790",
  },
  {
    W: "1793",
    T: "300+0",
    P: "1. g3 e5 2. Bg2 c6 3. d4 Qe7 4. Nc3 g6 5. dxe5 Qxe5 6. Nf3 Qa5 7. Qd4 f6 8. O-O Bg7 9. Qe4+ Ne7 10. Bd2 Qf5 11. Qb4 a5 12. Qa3 b5 13. Nd1 Qxc2 14. Rc1 Qf5 15. e4 Qe6 16. Nd4 Qe5 17. Nf3 Qe6 18. e5 fxe5 19. Ng5 Qf6 20. Ne4 Qe6 21. Nd6+ Kd8 22. Bg5 b4 23. Qd3 Kc7 24. Nb5+ Kb6 25. Be3+ Kb7 26. Nd6+ Kc7 27. Nb5+ Kd8 28. Nd6 Ba6 29. Qd2 Kc7 30. Ne4 d5 31. Nc5 Qd6 32. Nxa6+ Nxa6 33. f4 e4 34. f5 Nxf5 35. Bf4 Be5 36. Bxe5 Qxe5 37. Ne3 Nxe3 38. Qxe3 Nb8 39. Rf7+ Nd7 40. Qc5 Qe6 41. Bh3 Qxf7 42. Qxc6+ Kd8 43. Qxa8+ Ke7 44. Qb7 Rd8 45. Rc7 Qf3 46. Rxd7+ Rxd7 47. Qxd7+ Kf6 48. Qd8+ Kg7 49. Qe7+ Kh6 0-1",
    B: "1768",
  },
  {
    W: "2076",
    T: "300+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 Be7 4. Bc4 Bh4+ 5. Kf1 c6 6. d4 d6 7. c3 Bg4 8. Bxf4 Qf6 9. Be3 Bxf3 10. gxf3 Bg5 11. Bxg5 Qxg5 12. Rg1 Qf6 13. Ke2 Ne7 14. Nd2 Ng6 15. Qb3 b5 16. Bd3 Nf4+ 17. Ke3 O-O 18. Rg4 Ne6 19. Rag1 Qh6+ 20. f4 Qxh2 21. f5 Qh6+ 22. Kf3 Qxd2 23. Bb1 h5 24. R4g2 Qf4+ 25. Ke2 Nd8 26. Rxg7+ Kh8 27. f6 Nd7 28. e5 dxe5 29. Rh7# 1-0",
    B: "2027",
  },
  {
    W: "1230",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6 4. O-O Nf6 5. Re1 Ng4 6. h3 h5 7. d3 d6 8. Nc3 Na5 9. b3 Nxc4 10. dxc4 c6 11. Bb2 Nf6 12. Qd2 a6 13. Qg5 g6 14. b4 Bh6 15. Qg3 b6 16. Ba3 Bf4 17. Qh4 Qe7 0-1",
    B: "1429",
  },
  {
    W: "1216",
    T: "300+0",
    P: "1. e4 c5 2. c3 Nc6 3. d3 g6 4. g3 Bg7 5. f3 d6 6. a3 Nf6 7. h4 O-O 8. Bh3 d5 9. Bxc8 Rxc8 10. Ne2 d4 11. Nd2 b5 12. Nb3 Qd6 13. Bf4 e5 14. Bg5 h6 15. Bxf6 Qxf6 16. Nxc5 Qxf3 17. Rf1 Qg4 18. a4 b4 19. cxb4 Nxb4 20. a5 Rxc5 21. Qb3 Rb8 22. Qxf7+ Kh7 23. Qxa7 Nxd3+ 24. Kd1 Nxb2+ 25. Ke1 Nd3+ 26. Kd1 Nb2+ 27. Ke1 Rb3 28. Qxc5 Qxe4 29. Qc4 Nxc4 0-1",
    B: "1184",
  },
  {
    W: "1409",
    T: "300+0",
    P: "1. c4 e5 2. d3 Nc6 3. Nc3 Bb4 4. Bd2 Bxc3 5. Bxc3 d6 6. Nf3 Nf6 7. e4 O-O 8. Be2 a6 9. O-O b5 10. h3 b4 11. Bd2 a5 12. Bg5 h6 13. Bxf6 Qxf6 14. a3 Qg6 15. Nh4 Qg5 16. Nf5 Nd4 17. Bg4 Nxf5 18. Bxf5 Bxf5 19. exf5 Qxf5 20. Qd2 b3 21. f4 a4 22. fxe5 Qxe5 23. d4 Qa5 24. Qe3 c5 25. d5 Qa6 26. Rf3 Qxc4 27. Rg3 Kh8 28. Rf1 Qxd5 29. Qc3 Qd4+ 30. Kh2 Qxc3 31. Rxc3 Rae8 32. Rf2 Re4 33. Kg3 c4 34. Rcf3 Kg8 35. Rf4 d5 36. Rxe4 dxe4 37. Rf4 Re8 38. Kf2 e3+ 39. Ke2 g5 40. Rxc4 Ra8 41. Kxe3 f5 42. g3 Kg7 43. Kf3 Kg6 44. Rc6+ Kh5 45. g4+ fxg4+ 46. hxg4+ Kh4 47. Rxh6# 1-0",
    B: "1249",
  },
  {
    W: "1662",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Bc5 3. Nc3 d6 4. Bc4 Nc6 5. d3 Bg4 6. h3 Bh5 7. Bb5 Ne7 8. Nd5 O-O 9. Nxe7+ Qxe7 10. g4 Bg6 11. h4 h6 12. h5 Bh7 13. Qe2 Nd4 14. Nxd4 Bxd4 15. c3 Bb6 16. Be3 a6 17. Bxb6 axb5 18. Be3 c5 19. O-O-O Ra5 20. a3 Qe6 21. d4 Qb3 22. Qc2 Qa2 23. Qb1 Qc4 24. Qd3 Qxd3 25. Rxd3 c4 26. Rd2 Bxe4 27. Rhd1 Bd3 28. dxe5 dxe5 29. Bc5 Re8 30. Bb4 Ra6 31. Re1 e4 32. f4 f6 33. g5 fxg5 34. fxg5 hxg5 35. Rg1 Re5 36. Rdg2 Rf6 37. Rxg5 Rxg5 38. Rxg5 Rf1+ 39. Kd2 Rf2+ 40. Ke3 Re2+ 41. Kd4 Rxb2 42. h6 e3 43. Rxg7+ Kh8 44. Kxe3 Re2+ 45. Kd4 Rh2 46. Rxb7 Rxh6 47. Rxb5 Rh4+ 48. Kc5 Kg7 49. Rb7+ Kf6 50. Rb6+ Ke7 51. Kb5+ Kd7 52. Rd6+ Kc7 53. Rc6+ Kd7 54. Rd6+ Kc7 55. Kc5 Rh5+ 56. Rd5 Rh6 57. Ba5+ Kb7 58. Bb4 Rc6+ 59. Kd4 Kb6 60. Rd6 Rxd6+ 61. Bxd6 Kb5 62. Be7 Ka4 63. Bd6 Kb3 64. Bb8 Bg6 65. a4 Bf7 66. a5 Be8 67. a6 Bc6 68. Kc5 Ba8 69. a7 Bh1 70. Kd4 Bg2 71. Be5 Bf3 72. Kc5 Be4 73. Kb6 Ba8 74. Kc7 Kc2 75. Kb8 Bg2 76. a8=Q Bxa8 77. Kxa8 Kd3 78. Kb7 Ke4 79. Kc6 Kxe5 80. Kc5 Ke6 81. Kxc4 Kd7 82. Kd4 Kc6 83. c4 Kb6 84. Kc3 Kc6 85. Kb4 Kb6 86. c5+ Kc6 87. Kc4 Kc7 88. Kb5 Kb7 89. c6+ Kc7 90. Kc5 Kc8 91. Kb6 Kb8 92. Kc5 Kc7 93. Kd5 Kd8 94. Kd6 Kc8 95. c7 1-0",
    B: "1666",
  },
  {
    W: "1098",
    T: "300+0",
    P: "1. e4 d5 2. d3 c6 3. exd5 Qxd5 4. Nc3 Qe6+ 5. Nge2 Qd7 6. g3 f6 7. Bg2 e5 8. O-O g5 9. f4 Nh6 10. fxe5 fxe5 11. Bxg5 Na6 12. Rf6 Nf5 13. Qf1 Bg7 14. Rxf5 Qxf5 15. Qxf5 Bxf5 16. Rf1 Be6 17. Ne4 Rf8 18. Nd6+ Kd7 19. Nxb7 Rxf1+ 20. Bxf1 Rf8 21. Bg2 Kc7 22. Na5 Nb4 23. c3 Nxa2 24. Nxc6 e4 25. Bxe4 Nc1 26. Nxc1 Bf5 27. Bf4+ Kb6 28. Be3+ Kb5 29. c4+ Ka4 30. b3+ Ka3 31. Bc5+ Kb2 32. Bxf8 Kxc1 33. Bxg7 Bxe4 34. dxe4 Kc2 35. b4 Kb3 36. c5 Kc4 37. e5 Kd5 38. Na5 Ke6 39. g4 Kf7 40. c6 Kxg7 41. c7 Kf7 42. c8=Q Kg6 43. Qg8+ Kh6 44. Qe6+ Kg5 45. Qg8+ Kf4 46. Qxh7 Kxg4 47. Qxa7 Kf5 48. b5 Kxe5 49. b6 Kd6 50. b7 Kc7 51. b8=Q# 1-0",
    B: "1095",
  },
  {
    W: "1558",
    T: "300+0",
    P: "1. d4 d5 2. e3 Bf5 3. Be2 e6 4. Bd3 Bg6 5. Nf3 h6 6. Bxg6 fxg6 7. Ne5 Nd7 8. Nxg6 Qg5 9. Nxh8 Qxg2 10. Qh5+ Kd8 11. Rf1 Ngf6 12. Qf7 Qxh2 13. Qxe6 Bd6 14. Nf7+ Kc8 15. Nxd6+ cxd6 16. Nc3 b6 17. Nxd5 Nxd5 18. Qxd5 Kc7 19. Qxa8 1-0",
    B: "1542",
  },
  {
    W: "1489",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bc5 4. Bc4 Nf6 5. O-O d6 6. d3 h6 7. a4 O-O 8. Bb5 Bg4 9. Bxc6 bxc6 10. h3 Bd7 11. Ne2 Qe7 12. c3 Rab8 13. d4 exd4 14. Nexd4 Nxe4 15. Re1 d5 16. b4 Bxd4 17. Nxd4 Qd6 18. Qd3 Rfe8 19. Be3 Qg6 20. Qc2 Bxh3 21. g3 Qg4 22. Qe2 Qxe2 23. Nxe2 a6 24. Bf4 Rbc8 25. Nd4 Nxc3 26. Nxc6 Rxe1+ 27. Rxe1 Kh7 28. Re7 Be6 29. a5 Nb5 30. Ne5 f6 31. Nd3 Bf5 32. Nc5 c6 33. Ne6 Bxe6 34. Rxe6 Nd4 35. Re7 Nc2 36. Ra7 Nxb4 37. Bd6 Rb8 38. Bxb4 Rxb4 39. Rxa6 Ra4 40. Rxc6 Rxa5 41. f4 g5 42. Rxf6 Kg7 43. Rd6 gxf4 44. gxf4 Ra1+ 45. Kf2 Ra2+ 46. Kg3 Ra3+ 47. Kg4 Ra5 48. Kf5 h5 0-1",
    B: "1459",
  },
  {
    W: "1218",
    T: "300+0",
    P: "1. d4 d5 2. Nc3 Nf6 3. Bf4 Nc6 4. Nb5 e5 5. Bxe5 Nxe5 6. dxe5 Ne4 7. f3 Bb4+ 8. c3 Nxc3 9. bxc3 Bxc3+ 10. Nxc3 d4 11. Nb5 O-O 12. Nxd4 Re8 13. f4 Qh4+ 14. g3 Qe7 15. Ngf3 Bg4 16. Bg2 Bxf3 17. Bxf3 Qb4+ 18. Qd2 Qb6 19. O-O Rad8 20. e3 c5 21. Rab1 Qc7 22. Nb5 Qb8 23. Qc3 b6 24. Nd6 Re6 25. Nb7 Rh6 26. Nxd8 Qxd8 27. h4 Rg6 28. g4 Qxh4 29. Rb2 Qg3+ 30. Rg2 Rh6 31. Rxg3 f5 32. gxf5 1-0",
    B: "1224",
  },
  {
    W: "1946",
    T: "300+0",
    P: "1. c4 e5 2. Nc3 Nf6 3. g3 d5 4. cxd5 Bf5 5. e4 Bg4 6. f3 Bh5 7. h4 h6 8. g4 Bg6 9. h5 Bh7 10. d4 Bd6 11. g5 hxg5 12. Bxg5 Nbd7 13. Bb5 O-O 14. Bxd7 Qxd7 15. Bxf6 gxf6 16. Rh2 exd4 17. Rg2+ Kh8 18. Qxd4 Be5 19. Qe3 Qd6 20. Nge2 a6 21. O-O-O Qb4 22. Rdg1 c6 23. f4 Bxc3 24. Nxc3 cxd5 25. Nxd5 Rac8+ 26. Nc3 Qxe4 27. Qxe4 Bxe4 28. Rd2 b5 29. a3 a5 30. Rd4 f5 31. Rd6 Kh7 32. Rb6 b4 33. axb4 axb4 34. Rxb4 Ra8 35. Kd2 Rfe8 36. Nxe4 fxe4 37. Rg5 Rad8+ 38. Ke3 Rd3+ 39. Ke2 1-0",
    B: "1831",
  },
  {
    W: "1506",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. Nc3 Bg4 5. h3 Bxf3 6. Qxf3 Nf6 7. d3 Be7 8. Bg5 O-O 9. h4 Nd4 10. Qe3 Nxc2+ 11. Ke2 Nxe3 12. Bxe3 c6 13. Bc4 d5 14. Bb3 d4 15. Bg5 dxc3 16. Bxf6 Bxf6 17. bxc3 Qd7 18. Rad1 Rad8 19. g4 a5 20. g5 Be7 21. f3 Bc5 22. c4 b5 23. cxb5 cxb5 24. Rc1 Qd4 25. Rhf1 Qe3+ 26. Kd1 Rxd3+ 27. Kc2 Rc3+ 28. Kb1 Qd3+ 29. Ka1 Bd4 30. Bc2 Rxc2+ 31. Kb1 Rf2+ 32. Rc2 Qxc2# 0-1",
    B: "1547",
  },
  {
    W: "1433",
    T: "60+0",
    P: "1. f4 e6 2. Nf3 d5 3. e3 Nc6 4. Bb5 a6 5. Bxc6+ bxc6 6. b3 Bb7 7. Bb2 Ne7 8. d3 g6 9. O-O Bg7 10. Qe2 Bxb2 11. Nbd2 Bxa1 12. Rxa1 O-O 13. Rf1 c5 14. e4 dxe4 15. dxe4 f5 16. exf5 exf5 17. Re1 Re8 18. Qf2 Bxf3 19. Nxf3 Nd5 20. Rf1 Nc3 21. Qxc5 Nxa2 22. Ra1 Nc1 23. Rxc1 Rb8 24. Kh1 Rb5 25. Qc4+ Kh8 26. Qf1 Rb6 27. h3 Rbe6 28. Qf2 Qe7 29. Qd4+ Kg8 30. Qd5 c6 31. Qc4 g5 32. Ne5 gxf4 33. Nxc6 Qd7 34. Kh2 Kh8 35. Nd4 Rc6 36. Qd3 Rd6 37. Qc3 Rxd4 38. Qc4 1-0",
    B: "1367",
  },
  {
    W: "1287",
    T: "60+0",
    P: "1. d4 e6 2. c4 b6 3. Nc3 Bb7 4. Nf3 Bb4 5. Bd2 f5 6. e3 Nf6 7. Bd3 O-O 8. O-O Bxc3 9. Bxc3 Na6 10. d5 exd5 11. Bxf6 Qxf6 12. cxd5 Bxd5 13. Bxa6 Bxf3 14. Qxf3 Qxb2 15. Bb7 Rab8 16. Bd5+ Kh8 17. Rab1 Qf6 18. h3 c6 19. Bb3 Rg8 20. Rfd1 Rgf8 21. Rxd7 Rbc8 22. Rxa7 Rb8 23. Qxc6 Qxc6 24. Ra6 Qc5 25. Ra8 Qc6 26. Rxb8 Rxb8 0-1",
    B: "1355",
  },
  {
    W: "1755",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 Nf6 5. Bd3 Be7 6. O-O O-O 7. Re1 c6 8. c3 Re8 9. Bf4 Qb6 10. Qc2 Bg4 11. Nfd2 Nbd7 12. Na3 Rac8 13. h3 Bh5 14. Be5 Nxe5 15. dxe5 Nd7 16. Bxh7+ Kh8 17. Bd3 Bxa3 18. bxa3 Nf6 19. exf6 Rxe1+ 20. Rxe1 gxf6 21. Re7 Kg7 22. Nf3 Bxf3 23. gxf3 Qc5 24. Qe2 Rh8 25. Kh2 Qxc3 26. Qe3 Qb2 27. Bf5 Rh6 28. Rxf7+ Kxf7 29. Qxh6 Qxf2+ 30. Kh1 Qxf3+ 31. Kg1 Qxf5 32. Qd2 Qb1+ 33. Kg2 Qg6+ 34. Kf2 Qf5+ 35. Kg2 Qg6+ 36. Kh2 Qf5 37. Qg2 Qe5+ 38. Qg3 1-0",
    B: "1801",
  },
  {
    W: "1924",
    T: "60+0",
    P: "1. e4 c5 2. Bc4 Nc6 3. Nf3 e6 4. Nc3 a6 5. a4 d6 6. d4 cxd4 7. Nxd4 e5 8. Nxc6 bxc6 9. O-O Qb6 10. Rb1 Nf6 11. Be3 Qc7 12. f4 exf4 13. Bxf4 Be7 14. Re1 Bg4 15. Qd2 O-O 16. h3 Bh5 17. e5 dxe5 18. Bxe5 Rad8 19. Qf4 Bc5+ 20. Kh1 Nd5 21. Bxd5 cxd5 22. Bxc7 1-0",
    B: "1848",
  },
  {
    W: "1953",
    T: "60+0",
    P: "1. d4 Nf6 2. Nf3 g6 3. Bg5 Bg7 4. e3 Ne4 5. Bh4 c5 6. c3 Qb6 7. Qc2 d5 8. Bd3 Bf5 9. Nbd2 Nxd2 10. Kxd2 Bxd3 11. Kxd3 Nc6 12. Rhd1 O-O 13. Ke2 Qb5+ 14. Ke1 cxd4 15. Nxd4 Nxd4 16. cxd4 Rac8 17. Qd2 e5 18. Be7 Rfe8 19. Bc5 b6 20. a4 Qb3 21. Bb4 exd4 22. Kf1 dxe3 23. fxe3 Rxe3 24. Bc3 d4 25. Bxd4 Bxd4 26. Qxd4 Rc2 0-1",
    B: "1995",
  },
  {
    W: "1259",
    T: "60+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 Nc6 4. d4 d6 5. Bxf4 Bg4 6. Bc4 d5 7. Bxd5 Bb4+ 8. c3 Ba5 9. Bxf7+ Kxf7 10. Ng5+ Kf8 11. Qxg4 Nf6 12. Ne6+ Kf7 13. Qxg7+ Kxe6 14. d5+ Nxd5 15. exd5+ Kxd5 16. O-O Rg8 17. Rd1+ Nd4 18. Qxd4+ Ke6 19. Qe5+ Kf7 20. Rxd8 Raxd8 21. Na3 Rge8 22. Qf5+ Kg7 23. Qg5+ Kh8 24. Be5+ Rxe5 25. Qxe5+ Kg8 26. Qxa5 Kf8 27. Qxa7 Ke8 28. Qxb7 Kf8 29. Qxc7 Kg8 30. Qxd8+ 1-0",
    B: "1121",
  },
  {
    W: "2006",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d3 Nf6 5. Bg5 e6 6. Ke2 Be7 7. Ke1 Nd5 8. Bxe7 Nxe7 9. Be2 O-O 10. Nf3 b6 11. Kd2 Bb7 12. h4 Nbc6 13. h5 Nb4 14. h6 Nbd5 15. Nxd5 Nxd5 16. hxg7 Re8 17. Kc1 Qf6 18. Qd2 Qf4 19. Qxf4 Nxf4 20. Bf1 Kxg7 21. g3 Bxf3 22. Rh4 Ng6 23. Rh2 c5 24. Bg2 Bxg2 25. Rxg2 Red8 26. b3 Rd5 27. Kb2 b5 28. Rh1 Ne7 29. Rgh2 Rh8 30. Rh4 Rd4 31. f4 Rd5 32. Rg4+ Ng6 33. c4 bxc4 34. dxc4 Rd2+ 35. Kc3 Rxa2 36. Rg5 a5 37. g4 Kf8 38. f5 exf5 39. gxf5 Nf4 40. Rg4 Ne2+ 41. Kd3 a4 42. f6 axb3 43. Re4 Nd4 44. Rhe1 1-0",
    B: "1874",
  },
  {
    W: "1489",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Qb6 5. Nxc6 bxc6 6. Nc3 Ba6 7. Be3 Qxb2 8. Bxa6 Qxc3+ 9. Bd2 Qe5 10. O-O Qxe4 11. Re1 Qg6 12. Bd3 Qf6 13. Re3 e6 14. Rf3 Qd8 15. Rg3 Nf6 16. Bg5 Be7 17. Bxf6 Bxf6 18. Be4 Bxa1 19. Qxa1 d5 20. Bd3 f6 21. h3 e5 22. Rxg7 Qb6 23. Bxh7 Rf8 24. Rg8 Rxg8 25. Bxg8 Ke7 26. Bh7 Rh8 27. Bd3 e4 28. Be2 Qb4 29. Qd1 Qc3 30. Bg4 Qe5 0-1",
    B: "1505",
  },
  {
    W: "2014",
    T: "60+0",
    P: "1. d4 Nf6 2. c3 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d5 6. O-O c5 7. Re1 cxd4 8. cxd4 Nc6 9. Nc3 Bg4 10. h3 Bxf3 11. Bxf3 e6 12. Bg5 a6 13. e3 h6 14. Bxf6 Bxf6 15. Qb3 Rb8 16. Be2 Na5 17. Qc2 Rc8 18. a3 Nc4 19. Bxc4 Rxc4 20. Qd3 Qc7 21. Ne2 Rc8 22. Rac1 Rxc1 23. Rxc1 Qxc1+ 24. Nxc1 Rxc1+ 25. Kg2 Rc4 26. Qd2 b5 27. b3 Rc6 28. a4 Bd8 29. axb5 axb5 30. Qb4 Kg7 31. Qxb5 Rc2 32. Qd3 Rc6 33. e4 Bg5 34. exd5 exd5 35. Qf3 Rb6 36. h4 Bf6 37. Qxd5 Re6 38. b4 Re2 39. b5 Rd2 40. b6 Rxd4 41. b7 Rxd5 42. b8=Q Rd2 43. g4 Rd4 44. Qg3 h5 45. g5 Be7 46. Qe5+ 1-0",
    B: "2040",
  },
  {
    W: "1744",
    T: "60+0",
    P: "1. e4 c5 2. f4 Nc6 3. Nf3 g6 4. c3 Bg7 5. d4 cxd4 6. cxd4 e6 7. e5 Nge7 8. Nc3 Nf5 9. Bb5 a6 10. Bxc6 bxc6 11. O-O Bb7 12. g4 Ne7 13. g5 O-O 14. h4 Nf5 15. Ne4 d5 16. Nf6+ Bxf6 17. gxf6 h5 18. Ng5 Nxh4 19. Qe1 Nf5 20. Nf3 Rc8 21. Nh4 Nh6 22. Qg3 Kh7 23. f5 exf5 24. Qg5 c5 25. Qxh6+ Kg8 26. Qg7# 1-0",
    B: "1668",
  },
  {
    W: "1714",
    T: "420+5",
    P: "1. d4 d5 2. Bf4 Bf5 3. e3 e6 4. Nd2 a6 5. Be2 h6 6. h3 Nf6 7. Ngf3 Ne4 8. Nxe4 Bxe4 9. Bd3 Nd7 10. Bxe4 dxe4 11. Nd2 Nf6 12. c3 Qd7 13. b4 Bd6 14. Bxd6 Qxd6 15. Nc4 Qc6 16. Qb3 Nd5 17. Ne5 Qxc3+ 18. Qxc3 Nxc3 19. O-O O-O 20. a3 c6 21. Kh1 Kh7 22. Rac1 Nd5 23. Nd7 Rfd8 24. Nc5 b6 25. Nxe4 Rac8 26. Nc3 Kg6 27. Rc2 f5 28. Rfc1 Ne7 29. Na4 b5 30. Nc5 Nd5 31. Nxe6 Rd6 32. Nc5 Ne7 33. Nxa6 Ra8 34. Nc5 Rxa3 35. Rc3 Rb3 36. Nxb3 1-0",
    B: "1752",
  },
  {
    W: "1378",
    T: "300+3",
    P: "1. c4 g6 2. Nc3 Bg7 3. e3 d6 4. Nf3 Nf6 5. Bd3 d5 6. cxd5 Nxd5 7. O-O Nxc3 8. dxc3 O-O 9. Bc4 Qxd1 10. Rxd1 Nc6 11. Bb3 Bg4 12. h3 Bxf3 13. gxf3 Rad8 14. Rxd8 Rxd8 15. e4 Ne5 16. Kg2 c5 17. Bf4 b5 18. Bxe5 Bxe5 19. Rd1 Rxd1 20. Bxd1 b4 21. cxb4 cxb4 22. b3 a5 23. Be2 Bf4 24. Bc4 Kg7 25. Bd5 e6 26. Bc4 e5 27. Bd5 f6 28. Bc4 Kh6 29. Bd5 Kg5 30. Be6 h6 31. Bd5 f5 32. exf5 gxf5 33. Be6 Bd2 34. Bc4 f4 35. Bd5 Kf6 36. Bc4 Ke7 37. Bd5 Kd6 38. Bc4 Kc5 39. Kf1 Kd4 40. Ke2 Kc3 41. Kd1 Be3 42. Bd5 Bxf2 43. Bc4 Kb2 44. Bd5 Kxa2 45. Kc2 Bh4 46. Be6 Bg5 47. Bd5 h5 48. Bc4 h4 49. Bd5 Bh6 50. Bc4 e4 51. fxe4 f3 52. e5 Bf8 53. e6 Be7 54. Bf1 f2 55. Kc1 Kxb3 56. Kb1 Kc3 57. Ka2 b3+ 58. Ka1 a4 59. Bb5 a3 60. Kb1 Kb4 61. Bd3 b2 62. Ka2 Kc3 63. Be2 Kd2 64. Bc4 Ke1 65. Bd3 f1=Q 66. Bxf1 Kxf1 67. Kb3 b1=Q+ 0-1",
    B: "1388",
  },
  {
    W: "1566",
    T: "300+3",
    P: "1. d3 f5 2. g3 e5 3. Bg2 d5 4. Nf3 Bd6 5. Bg5 Nf6 6. O-O h6 7. Bxf6 gxf6 8. Nc3 Be6 9. d4 e4 10. Nh4 Rg8 11. e3 Nc6 12. Qh5+ Kd7 13. Qxh6 Qe7 14. Ng6 Qf7 15. Nb5 a6 16. Nxd6 cxd6 17. b3 Rxg6 18. Qxg6 Qxg6 19. c4 Rh8 20. cxd5 Bxd5 21. Rac1 Qh6 22. h4 Nb4 23. a3 Nd3 24. Rc2 Qg6 25. Rfc1 Nxc1 26. Rxc1 Bxb3 27. Rb1 Bd5 28. Rb6 Rc8 29. Bf1 Rc1 30. Kg2 Qg4 31. a4 Qf3+ 32. Kh3 Qh1# 0-1",
    B: "1620",
  },
  {
    W: "1356",
    T: "300+3",
    P: "1. d4 c5 2. d5 Nf6 3. c4 g6 4. Nc3 Bg7 5. Nf3 O-O 6. e4 d6 7. Bd3 b6 8. O-O a5 9. a4 Ba6 10. b3 Bb7 11. Bf4 Na6 12. Na2 Nb4 13. Nxb4 axb4 14. Qd2 Re8 15. Rfe1 e5 16. Bh6 Bc8 17. h3 Bh8 18. Bg5 Bd7 19. Ra2 Qc8 20. Rea1 Bxh3 21. gxh3 Qxh3 22. Qe3 Qg4+ 23. Kf1 Bg7 24. Ke2 Nh5 25. Rg1 Nf4+ 26. Bxf4 Qxf4 27. Raa1 Bh6 28. Qxf4 exf4 29. Rh1 Bg7 30. Ng5 Bxa1 0-1",
    B: "1325",
  },
  {
    W: "1143",
    T: "300+3",
    P: "1. d4 f5 2. c4 Nf6 3. Nc3 d6 4. Bg5 Ne4 5. Nxe4 fxe4 6. e3 h6 7. Qh5+ Kd7 8. Qg4+ Ke8 9. Qh5+ Kd7 10. Bf4 b6 11. g3 g5 12. Bxg5 Bg7 13. Bh3+ Kc6 14. Bxe7 Qxe7 15. Qd5# 1-0",
    B: "943",
  },
  {
    W: "1962",
    T: "300+3",
    P: "1. c4 e5 2. g3 f5 3. Bg2 Nf6 4. d3 Bc5 5. Nf3 d6 6. O-O O-O 7. a3 a5 8. Nc3 f4 9. Na4 fxg3 10. hxg3 Ba7 11. b4 axb4 12. axb4 Nh5 13. Kh2 Qf6 14. c5 Nc6 15. b5 Nd4 16. b6 Bb8 17. Nxd4 exd4 18. c6 Qg6 19. cxb7 Rxa4 20. Rxa4 Bf5 21. bxc7 Bxc7 22. Rxd4 d5 23. Rxd5 Nf6 24. Rb5 Bb8 25. e4 Ng4+ 26. Kg1 Bd7 27. Qb3+ Kh8 28. Rg5 Qf6 0-1",
    B: "1979",
  },
  {
    W: "1803",
    T: "60+0",
    P: "1. d4 e5 2. e3 d6 3. c3 Nf6 4. Nf3 Nc6 5. Bb5 Bd7 6. O-O a6 7. Bxc6 Bxc6 8. b4 b5 9. Nbd2 Be7 10. c4 O-O 11. c5 Ne4 12. Nxe4 Bxe4 13. g3 Qd7 14. h4 Qh3 15. Nh2 Qg2# 0-1",
    B: "1712",
  },
  {
    W: "2209",
    T: "60+0",
    P: "1. d4 Nf6 2. e3 e6 3. f4 d5 4. Nf3 c5 5. Bd3 Nc6 6. c3 cxd4 7. cxd4 h6 8. O-O Bd6 9. Ne5 Bxe5 10. fxe5 Ne4 11. Nc3 Nxc3 12. bxc3 Ne7 13. Qg4 O-O 14. Bd2 Ng6 15. Bxg6 fxg6 16. Qxg6 Rxf1+ 17. Rxf1 Bd7 18. Rf7 Qg5 19. Rxg7+ Kh8 20. Qh7# 1-0",
    B: "2317",
  },
  {
    W: "1876",
    T: "60+0",
    P: "1. d4 d5 2. c4 e6 3. Nc3 Bb4 4. Bd2 Nf6 5. Nf3 Nc6 6. a3 Be7 7. e3 b6 8. Qc2 Bb7 9. Bd3 Qd7 10. O-O O-O 11. b4 a6 12. c5 bxc5 13. bxc5 h6 14. Rab1 Rab8 15. Rfe1 e5 16. dxe5 Ng4 17. Nxd5 Qxd5 18. Be4 Qd7 19. h3 Ngxe5 20. Nxe5 Nxe5 21. Bxb7 Rfd8 22. Red1 Qe6 23. Be4 Rxb1 24. Rxb1 Qd7 25. Bh7+ Kh8 26. Bc3 Qd2 27. Qxd2 Nf3+ 28. gxf3 1-0",
    B: "1700",
  },
  {
    W: "1622",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 Nc6 4. d5 Nce7 5. dxe6 dxe6 6. Qxd8+ Kxd8 7. Nc3 Nc6 8. Bf4 Nf6 9. Bd3 Be7 10. O-O-O Ke8 11. Bb5 Kf8 12. Bxc6 bxc6 13. Rd2 g6 14. Rhd1 Kg7 15. e5 Nd5 16. Ne2 Ba6 17. g3 Bxe2 18. Rxe2 Nxf4 19. gxf4 Rab8 20. b3 Rhd8 21. Red2 Rxd2 22. Rxd2 Rd8 23. Rxd8 Bxd8 24. Kb2 a5 25. Kc3 Bb6 26. Kc4 f6 27. a3 f5 28. b4 h5 29. bxc5 Ba7 30. Nd2 Kh6 31. a4 g5 32. fxg5+ Kxg5 33. Nb3 Bb8 34. Nxa5 Bxe5 35. Nxc6 Bc7 36. Kb5 e5 37. a5 Bxa5 38. Nxa5 e4 39. c6 f4 40. c7 e3 41. fxe3 fxe3 42. c8=Q e2 43. Qe6 1-0",
    B: "1649",
  },
  {
    W: "1234",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 Nf6 3. e5 Nd5 4. d4 d6 5. Bb5+ Bd7 6. Qd3 Bxb5 7. Qxb5+ Qd7 8. Qxd7+ Nxd7 9. Bg5 dxe5 10. Nxe5 Nxe5 11. dxe5 Be7 12. Bxe7 Nxe7 13. Ke2 O-O 14. f4 Rfd8 15. g3 Nf5 16. Nc3 Nd4+ 17. Kf2 Nxc2 18. Rad1 Rxd1 19. Rxd1 Nb4 20. Rd7 Nd5 21. Nxd5 exd5 22. Rxc7 b6 23. Rd7 d4 24. Rxd4 Rc8 25. Ke3 h6 26. b4 Rc2 27. a4 Rxh2 28. b5 Ra2 29. Rd8+ Kh7 30. Rd7 Rxa4 31. Rxf7 Ra3+ 32. Kd4 Rxg3 33. Kd5 Rg4 34. e6 Rg3 35. e7 Re3 0-1",
    B: "1318",
  },
  {
    W: "1888",
    T: "60+0",
    P: "1. e4 e5 2. g3 c6 3. Bg2 Nf6 4. d3 d5 5. exd5 cxd5 6. Bd2 Bc5 7. Nc3 Be6 8. Qe2 Nc6 9. f4 exf4 10. gxf4 Qd7 11. f5 O-O-O 12. fxe6 fxe6 13. O-O-O e5 14. Bg5 Rhe8 15. Bxf6 gxf6 16. Nxd5 e4 17. Nxf6 exd3 18. Qxe8 d2+ 19. Kb1 Qg4 20. Bh3 Rxe8 21. Nxe8 Qxh3 22. Nxh3 Kd7 23. Rxd2+ Kxe8 24. Re1+ Kf7 25. Rf2+ Kg6 26. Rg1+ Kh5 27. Rfg2 Be3 28. Re1 Bd2 29. Rxd2 1-0",
    B: "1818",
  },
  {
    W: "2180",
    T: "60+0",
    P: "1. e3 Nf6 2. b3 g6 3. Nc3 Bg7 4. Bb2 O-O 5. Nf3 d6 6. d4 e5 7. Bd3 e4 8. Nxe4 Nxe4 9. Bxe4 Re8 10. Bd3 d5 11. O-O c5 12. c4 dxc4 13. Bxc4 cxd4 14. Bxd4 Nc6 15. Bxg7 Kxg7 16. Qxd8 Rxd8 17. Rad1 Bg4 18. Rxd8 Rxd8 19. Rc1 Bxf3 20. gxf3 Ne5 21. Be2 Rd2 22. Kf1 Rxa2 23. Rc7 b6 24. f4 Ra1+ 25. Kg2 Ra2 26. Bd1 Rd2 27. fxe5 Rxd1 28. e6 Rd8 29. Rxf7+ Kg8 30. Rxa7 Re8 31. e7 Kf7 32. Rb7 b5 33. Rxb5 Rxe7 34. Kf3 Kf6 35. Ke2 Rc7 36. f3 Rc2+ 37. Kd3 Rxh2 38. e4 Rf2 39. f4 Rxf4 40. Ke3 Rf1 41. Rb6+ Kg7 42. Rb5 Rb1 43. Kd4 h5 44. Ke5 h4 45. Kf4 Kh6 46. Kg4 g5 47. Rb6+ Kh7 48. Kxg5 h3 49. Rh6+ Kg7 50. Rxh3 Rg1+ 51. Kf4 1-0",
    B: "2180",
  },
  {
    W: "1500",
    T: "60+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. Nf3 e6 4. Bc4 d6 5. d3 a6 6. Bg5 f6 7. Bf4 e5 8. Bg3 b5 9. Bb3 Na5 10. O-O Nxb3 0-1",
    B: "1586",
  },
  {
    W: "1668",
    T: "60+0",
    P: "1. e4 e6 2. Bc4 d5 3. exd5 exd5 4. Bb3 Nf6 5. c4 Nc6 6. cxd5 Nxd5 7. Bxd5 Qxd5 8. Qf3 Be6 9. Qxd5 Bxd5 10. f3 Nd4 11. Kd1 O-O-O 12. Ne2 Bc5 13. Nxd4 Bxd4 14. Nc3 Bc6 15. Re1 h5 16. b3 Bxc3 17. Bb2 0-1",
    B: "1660",
  },
  {
    W: "1687",
    T: "15+0",
    P: "1. f4 e6 2. Nf3 c5 3. e4 Nf6 4. e5 Nd5 5. c4 Nb6 6. Nc3 Nc6 7. d4 d6 8. d5 Nd4 9. dxe6 Nxf3+ 10. Qxf3 Bxe6 11. f5 Bxc4 12. Bxc4 Nxc4 13. e6 Ne5 14. exf7+ Kxf7 15. Qxb7+ Qe7 0-1",
    B: "1619",
  },
  {
    W: "1910",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O Bd6 6. h3 Nf6 7. d4 exd4 8. e5 Bxe5 9. Nxe5 O-O 10. Bg5 h6 11. Bxf6 Qxf6 12. Nd3 Rd8 13. Nd2 Qg6 14. Qf3 Bf5 15. Rfe1 Rd6 16. Re5 Rf6 17. Qg3 Qh7 18. Rae1 Be6 19. Nc5 Bc8 20. Re8# 1-0",
    B: "1830",
  },
  {
    W: "1840",
    T: "600+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 Be7 4. Bc4 Bh4+ 5. g3 fxg3 6. O-O d5 7. Bxd5 Nf6 8. Nxh4 Nxd5 9. Qh5 Nf6 10. hxg3 Bg4 11. Qe5+ Be6 12. c3 Nc6 13. Qf4 Qd3 14. Nf5 O-O-O 15. Rf3 Qxe4 16. Qxe4 Nxe4 17. Ne3 Ne5 0-1",
    B: "1922",
  },
  {
    W: "1073",
    T: "600+0",
    P: "1. e4 e5 2. d3 Nf6 3. c4 d6 4. Nc3 c6 5. Be3 b6 6. Nf3 h6 7. d4 Nbd7 8. Qa4 Bb7 9. d5 c5 10. b4 cxb4 11. Qxb4 Nc5 12. Na4 Nfd7 13. Qb5 Be7 14. Nxc5 dxc5 15. Bxc5 Bxc5 16. Qb2 f6 17. g3 O-O 18. Nh4 Qe8 19. Rd1 a5 20. Bh3 Ra6 21. O-O g5 22. Nf5 Bc8 23. Nxh6+ Kg7 24. Nf5+ Kh8 25. Nh6 Qh5 26. Kg2 Nb8 27. Nf5 Rf7 28. Qd2 Bxf5 29. Bxf5 Nd7 30. d6 Kg7 31. Qd5 Ra8 32. Qxa8 Rf8 33. Qc6 Rh8 34. Bxd7 1-0",
    B: "1053",
  },
  {
    W: "1712",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. d5 h6 5. Nc3 a6 6. Bd3 c5 7. dxc6 bxc6 8. O-O g5 9. Be3 Qf6 10. Qd2 Ne7 11. Rad1 Ng6 12. h3 Nf4 13. Nh2 h5 14. g4 Nxh3+ 15. Kg2 Nf4+ 16. Bxf4 gxf4 17. Qxf4 0-1",
    B: "1788",
  },
  {
    W: "1566",
    T: "600+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. g3 Nf6 4. Bg2 d6 5. Nf3 Bg4 6. O-O Qd7 7. d3 g6 8. a4 a6 9. Be3 Bg7 10. Nd5 Nxd5 11. exd5 Nd4 12. c3 Nxf3+ 13. Bxf3 Bh3 14. Re1 O-O 15. Qd2 b5 16. b3 b4 17. Bh6 Bxc3 18. Qg5 Rfe8 19. Re4 Bxa1 20. Rh4 Bf5 21. Bf8 f6 22. Qh6 e5 23. Bxd6 Qxd6 24. Qxh7+ Kf8 25. Qh8+ Ke7 26. Rh7+ Kd8 27. Qg7 Re7 28. Rh8+ Kd7 29. Qh6 Rxh8 30. Qxh8 Bxd3 31. Qh3+ Bf5 32. g4 e4 33. Bg2 Qf4 34. gxf5 Qxf5 35. Qh8 Bd4 36. Qa8 Qxf2+ 37. Kh1 Qg1# 0-1",
    B: "1473",
  },
  {
    W: "1937",
    T: "600+0",
    P: "1. e4 Nc6 2. Bc4 e5 3. Nf3 Bc5 4. O-O h6 5. Nxe5 Nxe5 6. Bb5 Bb6 7. d4 Ng6 8. Be3 N8e7 9. f4 c6 10. Bc4 O-O 11. f5 Nh8 12. Qg4 d5 13. Bxh6 g6 14. fxg6 Nexg6 15. Bd3 Bxg4 0-1",
    B: "1924",
  },
  {
    W: "1332",
    T: "600+0",
    P: "1. e4 e5 2. d4 Nc6 3. d5 Nd4 4. Qh5 Nxc2+ 5. Ke2 d6 6. Nf3 Nh6 7. Nxe5 Bg4+ 8. Qxg4 Nxg4 9. Nxg4 Nxa1 0-1",
    B: "1342",
  },
  {
    W: "1286",
    T: "600+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 d5 4. exd5 Qxd5 5. d3 Bg4 6. h3 Bxf3 7. gxf3 Bb4+ 8. c3 Qe5+ 9. Be2 Bd6 10. O-O Bc5+ 11. Kh1 Qf5 12. d4 Qxh3+ 13. Kg1 Bd6 14. Re1 Nf6 15. Bf1+ Qe6 16. Rxe6+ fxe6 17. Qe1 Ke7 18. Bc4 Nd5 19. Bxd5 c6 20. Qxe6+ Kf8 1-0",
    B: "1179",
  },
  {
    W: "1321",
    T: "600+0",
    P: "1. c4 c5 2. Nf3 d5 3. d4 dxc4 4. d5 Nf6 5. Nc3 e6 6. e4 Bd6 7. Bxc4 exd5 8. exd5 O-O 9. O-O Ng4 10. Ne4 a6 11. Nxd6 Qxd6 12. a3 b5 13. Ba2 Nd7 14. Re1 Bb7 15. Qe2 Ndf6 16. h3 Nh6 17. g3 Nxd5 18. Bxd5 Bxd5 19. Bf4 Qd7 20. Ne5 Qxh3 21. Nf3 Ng4 0-1",
    B: "1332",
  },
  {
    W: "1361",
    T: "600+0",
    P: "1. e4 e5 2. Bc4 Nc6 3. c3 Bc5 4. Qf3 Nf6 5. d3 O-O 6. Qg3 d6 7. Bh6 Nh5 8. Qf3 gxh6 9. Qxh5 Qg5 10. Qxg5+ hxg5 11. Ne2 Bg4 12. Ng3 a6 13. O-O b5 14. b4 Bb6 15. Nd2 bxc4 16. Nxc4 h5 17. Nxb6 cxb6 18. f3 Be6 19. Nxh5 a5 20. a3 axb4 21. cxb4 Nd4 22. Nf6+ Kg7 23. Nh5+ Kg6 24. Ng3 Nb3 25. Ra2 Nd4 26. a4 Bxa2 27. Ra1 Be6 28. b5 0-1",
    B: "1411",
  },
  {
    W: "1587",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f6 4. Nc3 a6 5. Ba4 b5 6. Bb3 Nb4 7. a3 Nc6 8. O-O Bc5 9. Nd5 Bb7 10. c3 Nh6 11. d4 exd4 12. cxd4 Nxd4 13. Nxd4 Bxd5 14. Bxd5 Bxd4 15. Qxd4 c6 16. Ba2 Ng4 17. e5 Nxe5 18. Re1 Qe7 19. Bf4 d6 20. Bg3 O-O-O 21. f4 c5 22. Qd2 Nf3+ 23. gxf3 Qb7 24. Be6+ Kb8 25. f5 Qxf3 26. Bxd6+ Kb7 27. Bd5+ Qxd5 28. Qxd5+ Kc8 29. Qa8+ Kd7 30. Qa7+ Kxd6 31. Rad1+ Kc6 32. Qxa6+ Kc7 33. Re7+ Kb8 34. Qb7# 1-0",
    B: "1398",
  },
  {
    W: "1085",
    T: "600+0",
    P: "1. d4 d5 2. e3 Nc6 3. Nf3 Bf5 4. Bd3 e6 5. Qd2 Bb4 6. c3 Ba5 7. b4 Bb6 8. a4 a6 9. a5 Ba7 10. Bxf5 exf5 11. O-O Nge7 12. Qd3 O-O 13. Nbd2 b6 14. axb6 Bxb6 15. Nh4 a5 16. bxa5 Bxa5 17. Nxf5 Qd7 18. g4 g6 19. Nh6+ Kg7 20. g5 Nxd4 21. Qxd4+ f6 22. e4 Nc6 23. Qxd5 Qe8 24. Ba3 Rd8 25. gxf6+ Kxh6 26. Qc4 Rxd2 27. Bc1 Qd7 28. Bxd2+ Qxd2 29. Qxc6 Qg5+ 30. Kh1 Rxf6 31. Qxc7 Bxc7 0-1",
    B: "1267",
  },
  {
    W: "1992",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. f3 a6 6. c3 Qc7 7. Bg5 Nbd7 8. Nd2 h6 9. Be3 e6 10. Bd3 Ne5 11. Bc2 b5 12. a3 Be7 13. O-O O-O 14. h3 Bb7 15. Qe2 Rac8 16. Bf4 Ng6 17. Bg3 Nh5 18. Bh2 Nhf4 19. Qf2 Bf6 20. Ne2 Nxe2+ 21. Qxe2 Ne5 22. f4 Ng6 23. Rae1 Bh4 24. g3 Bf6 25. h4 Qc5+ 26. Kg2 a5 27. Kh3 b4 28. axb4 axb4 29. Bg1 Qc7 30. e5 dxe5 31. Bxg6 fxg6 32. fxe5 Be7 33. c4 Rxf1 34. Rxf1 Ba6 35. Rf4 Bc5 36. Bxc5 Qxc5 37. Ne4 Qg1 38. Nd6 Qh1+ 39. Kg4 Rd8 40. Rf1 Qc6 41. b3 Qc5 42. Qe4 h5+ 43. Kg5 Rxd6 44. Kxg6 Rd4 45. Qa8+ Bc8 46. Qf3 Qe7 47. Kxh5 Bb7 48. Qe2 Re4 49. Qf3 Rxe5+ 50. Kg4 Bxf3+ 51. Rxf3 Re4+ 52. Kh3 Qc5 53. Rd3 Qg1 54. Rd2 Qf1+ 55. Kh2 Re2+ 56. Rxe2 Qxe2+ 57. Kh3 Qd3 58. h5 Qxb3 59. c5 Qc4 60. c6 Qxc6 61. g4 Qc4 62. Kh4 b3 63. Kg5 b2 64. Kg6 b1=Q+ 0-1",
    B: "2008",
  },
  {
    W: "2069",
    T: "30+0",
    P: "1. c4 b6 2. Nc3 Bb7 3. e3 e6 4. d4 Nf6 5. Nf3 d5 6. Be2 Nc6 7. cxd5 exd5 8. e4 dxe4 9. Ng5 Qxd4 10. Qxd4 Nxd4 11. Bb5+ c6 12. Be2 Nc2+ 13. Kd2 Nxa1 14. Ncxe4 Nxe4+ 15. Nxe4 O-O-O+ 16. Kc3 c5 17. b3 Bxe4 18. f3 Bb7 19. Bb2 Nc2 20. Kxc2 Be7 21. Bc4 Bf6 22. Bxf6 gxf6 23. Bxf7 Rhf8 24. Be6+ Kc7 25. Re1 Rde8 26. f4 f5 27. Kd2 Kd6 28. Bc4 Rxe1 29. Kxe1 Be4 30. Kf2 Rg8 31. g3 Re8 32. Bf7 Re7 33. Bg8 Bd5 34. Bxh7 Rxh7 35. Ke3 Rxh2 36. g4 Rxa2 37. gxf5 Ra3 38. Kd2 Rxb3 39. Ke1 Rf3 40. Ke2 Rxf4 41. Ke3 Rxf5 0-1",
    B: "2044",
  },
  {
    W: "1945",
    T: "60+0",
    P: "1. d4 d6 2. c4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. Bd2 O-O 6. e3 c6 7. Be2 Nbd7 8. O-O Re8 9. Rb1 e5 10. dxe5 Nxe5 11. Ne1 Be6 12. b3 Qb6 13. Na4 Qc7 14. f3 b5 15. cxb5 cxb5 16. Rc1 Qb7 17. Nc3 b4 18. Na4 a5 19. Nd3 Nxd3 20. Bxd3 Nd5 21. Qe2 Rec8 22. Be4 Rxc1 23. Rxc1 Rc8 24. Rxc8+ Qxc8 25. Bxd5 Bxd5 26. Nb6 Qc6 27. Nxd5 Qxd5 28. e4 Qd4+ 29. Be3 Qa1+ 30. Kf2 Bd4 31. Bxd4 Qxd4+ 32. Kg3 Kg7 33. h4 h5 34. Kh3 Kf6 35. g4 hxg4+ 36. fxg4 Qc3+ 37. Kg2 Ke5 38. Qf3 Qxf3+ 39. Kxf3 f6 40. h5 gxh5 41. gxh5 f5 42. h6 fxe4+ 43. Ke3 Kf6 44. Kxe4 Kg6 45. Kd5 Kxh6 46. Kxd6 Kg5 47. Kc5 a4 48. Kb5 axb3 49. axb3 Kf6 50. Kxb4 Ke6 51. Kc5 Kd7 52. Kb6 Kc8 53. Ka7 Kc7 54. b4 Kc6 55. Ka6 Kc7 56. Ka7 Kc6 57. Ka6 Kc7 58. b5 Kb8 59. Kb6 Ka8 60. Kc7 Ka7 61. b6+ Ka6 62. b7 Kb5 63. b8=Q+ Kc4 64. Qb6 Kd5 65. Kb7 Kc4 66. Qc6+ Kd4 67. Kc7 Ke5 68. Kd7 Kd4 69. Qd6+ Kc4 70. Ke7 Kc3 71. Ke6 Kc4 72. Qd5+ Kc3 73. Ke5 Kc2 74. Ke4 Kc3 75. Qd4+ Kc2 76. Ke3 1-0",
    B: "2056",
  },
  {
    W: "1948",
    T: "60+0",
    P: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Be2 e6 6. Nf3 Qc7 7. O-O Nf6 8. Bc4 Ng4 9. Qe2 Nd4 10. Rd1 Nxf3+ 11. Qxf3 Qxh2+ 12. Kf1 Ne5 13. Qh3 Qxh3 14. gxh3 Nxc4 15. b3 Ne5 16. Bf4 d6 17. Nb5 Kd7 18. Rac1 f6 19. Rc7+ Kd8 20. Bxe5 fxe5 21. Rf7 a6 22. Nxd6 Bxd6 23. Rxd6+ Ke8 24. Rxg7 Rf8 25. Rxh7 b5 26. h4 Rb8 27. h5 Rb7 28. Rxb7 Bxb7 29. h6 Bxe4 30. Rxe6+ Kf7 31. Rxe5 Bg6 32. Rg5 Rh8 33. Rc5 Rxh6 34. Rc7+ Kf6 35. Rc6+ Kg5 36. Rxa6 Bf5 37. Ra5 Rh1+ 38. Kg2 Rb1 39. Rxb5 Rb2 40. a3 Kf4 41. a4 Be4+ 0-1",
    B: "2165",
  },
  {
    W: "2114",
    T: "60+0",
    P: "1. c4 e6 2. Nc3 f5 3. g3 Nf6 4. Bg2 c6 5. Qb3 d5 6. cxd5 exd5 7. d3 Bc5 8. Na4 Bd6 9. Nf3 O-O 10. O-O Be6 11. Nd2 Qe8 12. Qxb7 Nbd7 13. Qxc6 Bb8 14. Qxa8 Nb6 15. Nxb6 axb6 16. Qa3 Qd7 17. b3 Bd6 18. Qb2 Qc7 19. Nf3 Ng4 20. Nd4 Be5 21. e3 Qf7 22. Qc3 Qh5 23. h3 Nf6 24. Bb2 Bxg3 25. fxg3 f4 26. Nxe6 1-0",
    B: "1686",
  },
  {
    W: "1821",
    T: "60+0",
    P: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. Nf3 Nc6 5. Nc3 Bg4 6. Bb5 Bxf3 7. Qxf3 e6 8. Bf4 Nf6 9. O-O Be7 10. Na4 O-O 11. Nc5 Nxd4 12. Qc3 Nxb5 13. Qb4 Nd6 14. Qd4 Nde4 15. Nxe4 Nxe4 16. f3 Bc5 0-1",
    B: "1836",
  },
  {
    W: "1693",
    T: "300+8",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 Nf6 4. d3 Nc6 5. Ng5 e6 6. Nc3 d5 7. exd5 exd5 8. Bb5 Be7 9. O-O O-O 10. Bxc6 bxc6 11. d4 Bg4 12. f3 Bh5 13. Ne2 Bd6 14. Nf4 Bxf4 15. Bxf4 Bg6 16. dxc5 Nd7 17. b4 h6 18. Nh3 f6 19. Qd4 Bxc2 20. Rac1 Ba4 21. Rfe1 g5 22. Bd6 Rf7 23. Qd3 Bb5 24. Qg6+ Rg7 25. Qxh6 Bd3 26. Qh5 Bg6 27. Qg4 a5 28. bxa5 Rxa5 29. a4 Ra8 30. Rc3 Ne5 31. Qe6+ Bf7 32. Qe7 Qxe7 33. Bxe7 Nd7 34. Ra3 Bg6 35. Nf2 Re8 36. Rae3 Ne5 37. Bxf6 Nxf3+ 38. gxf3 Rxe3 39. Rxe3 Rf7 40. Bxg5 Ra7 41. Ra3 d4 42. Nd3 Bxd3 43. Rxd3 Rxa4 44. Bf6 Kf7 45. Bxd4 Ra8 46. Rb3 Rg8+ 47. Kf2 Ke6 48. Rb6 Kd5 49. Be3 Ra8 50. h4 Ra2+ 51. Kg3 Rc2 52. h5 1-0",
    B: "1447",
  },
  {
    W: "1266",
    T: "120+1",
    P: "1. Nf3 e6 2. d4 b6 3. e4 Bb7 4. Nbd2 Bb4 5. c3 Bd6 6. Bd3 Bf4 7. O-O Nf6 8. e5 Nd5 9. c4 Nb4 10. Be4 Bxe4 11. Nxe4 Bxc1 12. Qxc1 Nd3 13. Qd2 Nxf2 14. Qxf2 a5 15. Nfg5 O-O 16. Nxf7 Rxf7 17. Qxf7+ Kh8 18. Rf2 Nc6 19. Raf1 Nxd4 20. Qf8+ Qxf8 21. Rxf8+ Rxf8 22. Rxf8# 1-0",
    B: "1262",
  },
  {
    W: "2159",
    T: "30+0",
    P: "1. d4 Nf6 2. Bg5 g6 3. Bxf6 exf6 4. e3 Bg7 5. Bd3 O-O 6. h4 c5 7. h5 cxd4 8. hxg6 fxg6 9. Rxh7 f5 10. Rh1 d5 11. g4 fxg4 12. Bxg6 Qf6 13. Bh7+ Kh8 14. Bf5+ Bh6 15. Rxh6+ Qxh6 16. Bxc8 Rxc8 17. Qxg4 Rg8 18. Qxd4+ Qg7 19. Nc3 Nc6 0-1",
    B: "1974",
  },
  {
    W: "1597",
    T: "60+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. g4 e6 4. Bg2 Nf6 5. f3 d5 6. Nge2 dxe4 7. Nxe4 Nxe4 8. fxe4 Be7 9. d3 b6 10. Be3 Bb7 11. O-O Qc7 12. h3 h5 13. Bf4 Bd6 14. g5 Bxf4 15. Nxf4 O-O-O 16. c3 f6 17. gxf6 Qf7 18. e5 gxf6 19. exf6 Rhg8 20. Kh1 Rdf8 21. Nxh5 Qg6 22. Ng7 Qxg2+ 23. Kxg2 Rxg7+ 24. fxg7 Rg8 25. Rf2 Rxg7+ 26. Kh2 Re7 27. Rf8+ 1-0",
    B: "1521",
  },
  {
    W: "1454",
    T: "60+0",
    P: "1. e4 e5 2. Nc3 d6 3. Nf3 h6 4. Bc4 a6 5. O-O Nc6 6. d4 Bg4 7. dxe5 Bxf3 8. Qxf3 Nxe5 9. Qf4 Nxc4 10. Nd5 c6 11. Ne3 Nxe3 12. Bxe3 Be7 13. Qg3 Bh4 14. Qxg7 Bf6 15. Qg3 Ne7 16. e5 dxe5 17. Bf4 exf4 18. Qxf4 Bxb2 19. Rae1 Bc3 20. Re2 Qb6 21. Qc4 O-O-O 22. Qxc3 Nd5 23. Qf6 Nxf6 24. Rfe1 Rhg8 25. Re7 Qc7 26. Rxc7+ Kxc7 27. Rf1 Kb6 28. h3 Ne4 29. f3 Nc3 30. Kh2 Ne2 31. Re1 Nf4 32. Rf1 Rxg2+ 33. Kh1 Rxc2 34. Rg1 Rdd2 35. Rf1 Rh2+ 36. Kg1 Rcg2# 0-1",
    B: "1452",
  },
  {
    W: "1426",
    T: "60+0",
    P: "1. e4 e5 2. d4 exd4 3. c3 Nc6 4. cxd4 d5 5. e5 f6 6. exf6 Nxf6 7. Be2 Bd6 8. Bh5+ g6 9. Qe2+ Ne7 10. Bg5 O-O 11. Bf3 Ng4 12. Bxg4 Bxg4 13. Qxg4 Rf5 14. Nf3 c5 15. O-O cxd4 16. Nxd4 Rxg5 17. Qxg5 Rc8 18. Ne6 Qe8 19. Qf6 Qf7 20. Qxf7+ Kxf7 21. Ng5+ Kg7 22. Nc3 Nf5 23. Ne6+ Kf6 24. Nd4 Nxd4 25. Rad1 Ne2+ 26. Nxe2 Bxh2+ 27. Kxh2 1-0",
    B: "1390",
  },
  {
    W: "1259",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 e6 3. Bf4 Nc6 4. e3 f5 5. Nbd2 Nf6 6. c3 Bd6 7. Bg5 O-O 8. Bh4 Be7 9. h3 Ne4 10. Bxe7 Qxe7 11. Nxe4 dxe4 12. Nd2 Bd7 13. Nc4 Rad8 14. Nd2 a6 15. Qa4 Nxd4 16. Qc2 Nxc2+ 17. Ke2 Nxa1 18. f3 Nc2 19. g4 fxg4 20. hxg4 e5 21. Nxe4 Bc6 22. Nd2 e4 23. f4 g6 24. Bg2 b5 25. Rc1 Nxe3 26. Kxe3 Qc5+ 27. Ke2 a5 28. Nxe4 Qe7 29. Rc2 Bxe4 30. Bxe4 Qxe4+ 31. Kf2 Qxc2+ 32. Kg3 Rd3+ 33. Kh4 Qh2+ 34. Kg5 Qh6+ 35. Kxh6 Kh8 36. Kg5 Rh3 37. f5 Rxf5+ 38. gxf5 Rg3+ 39. Kf4 Rh3 40. Kg4 1-0",
    B: "1261",
  },
  {
    W: "1719",
    T: "60+0",
    P: "1. Nf3 a5 2. e3 a4 3. d4 d5 4. c4 dxc4 5. Bxc4 Nf6 6. Nc3 e6 7. Bd2 Bd7 8. O-O c6 9. h3 b5 10. Be2 Be7 11. a3 Nd5 12. Nxd5 exd5 13. b4 O-O 14. Bd3 f5 15. Rc1 Bd6 16. Ne5 Bxe5 17. dxe5 Qe7 18. f4 Kh8 19. Bc3 Be6 20. Bd4 Nd7 21. Qc2 Rac8 22. Bc5 Nxc5 23. Qxc5 Qxc5 24. Rxc5 h6 25. Rfc1 Bd7 26. Kf2 Rfe8 27. g3 g6 28. Kf3 Re6 29. g4 Kg7 30. gxf5 gxf5 31. Bxf5 Re7 32. Bxd7 Rxd7 33. Rxc6 Rxc6 34. Rxc6 d4 35. exd4 Rxd4 36. Rd6 Rxd6 37. exd6 Kf7 38. Ke4 Ke6 39. f5+ Kxd6 40. Kf4 Ke7 41. Kg4 Kf6 42. Kf4 h5 43. h4 Kf7 44. Kg5 Kg7 45. Kxh5 Kf7 46. Kg5 1-0",
    B: "1713",
  },
  {
    W: "2285",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Bc5 5. O-O d6 6. c3 h6 7. b4 Bb6 8. a4 a5 9. b5 Ne7 10. Be3 Ng6 11. Bxb6 cxb6 12. g3 Bh3 13. Re1 h5 14. Nbd2 O-O 15. Qb3 h4 16. Ng5 hxg3 17. hxg3 d5 18. Nxh3 dxc4 19. Qxc4 Qd7 20. Kg2 Rac8 21. Qa2 Qxd3 22. Re3 Qd6 23. Nf3 Ng4 24. Re2 Rxc3 25. Nhg1 Qd3 26. Rae1 Nf4+ 27. gxf4 g6 28. Qd2 Qc4 29. Qd5 Qxa4 30. fxe5 1-0",
    B: "2396",
  },
  {
    W: "1680",
    T: "60+0",
    P: "1. e4 d5 2. c3 Bg4 3. Qxg4 Nf6 4. Qe2 dxe4 5. d4 exd3 6. Qxd3 Nc6 7. Bg5 Ng4 8. Nf3 f6 9. Qxd8+ Rxd8 10. Be3 Nxe3 11. fxe3 Na5 12. Bb5+ c6 13. Be2 b5 14. b4 Nc4 15. Bxc4 bxc4 16. O-O g5 17. Nbd2 g4 18. Nd4 Bh6 19. Nxc4 Bxe3+ 20. Nxe3 f5 21. Nexf5 e6 22. Nxe6 Rd5 23. Rfe1 Rxf5 24. Ng7+ Kf7 25. Nxf5 Kf6 26. Nd6 Rd8 27. Rad1 Rf8 28. Re8 Rxe8 29. Nxe8+ Ke7 30. Ng7 Kf7 31. Nh5 Kg6 32. Nf4+ Kg5 33. g3 h5 34. Rd7 h4 35. Rxa7 hxg3 36. hxg3 Kf5 37. Kg2 Ke4 38. Rc7 Ke3 39. Rxc6 Ke4 40. a4 Kf5 41. a5 Kg5 42. a6 Kf5 43. a7 Kg5 44. a8=N Kf5 45. b5 Ke4 46. b6 Ke3 47. b7 Ke4 48. b8=N Kf5 49. Rf6+ Kg5 50. c4 Kxf6 51. c5 Kf5 52. c6 Ke5 53. c7 Kd6 54. c8=N+ Ke5 55. Ne7 Kf6 56. Nd3 Kg5 57. Nec6 Kf5 58. Ne7+ Kg5 59. Ne5 Kf6 60. Nxg4+ Kg5 1/2-1/2",
    B: "1677",
  },
  {
    W: "1692",
    T: "60+0",
    P: "1. d4 g6 2. Nf3 Bg7 3. g3 Nf6 4. Bg2 d6 5. e3 O-O 6. O-O Nbd7 7. c4 c5 8. Nc3 cxd4 9. exd4 Re8 10. d5 e5 11. dxe6 fxe6 12. Qxd6 Qb6 13. Qxb6 Nxb6 14. Nb5 Re7 15. Bf4 a6 16. Nc7 Ra7 17. Bd6 Rd7 18. c5 Nc4 19. Nxe6 Nxd6 20. cxd6 Rxd6 21. Nxg7 Kxg7 22. Ng5 b5 23. Rac1 Bf5 24. b3 h6 25. Nf3 Rad7 26. Nh4 Be6 27. Rfe1 g5 28. Nf3 Nd5 29. Ne5 Rd8 30. Bxd5 Bxd5 31. Rc7+ Kg8 32. Ng6 Rxg6 33. Ree7 Rf6 34. Rg7+ Kf8 35. Rh7 Re6 36. Rh8# 1-0",
    B: "1708",
  },
  {
    W: "1129",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Bc4 Bc5 4. d3 O-O 5. Ng5 Ng4 6. f3 Nf6 7. Bxf7+ Rxf7 8. Nxf7 Kxf7 9. d4 exd4 10. O-O d3+ 11. Kh1 dxc2 12. Qxc2 b6 13. Nc3 Kg8 14. f4 Qf8 15. e5 Ng4 16. h3 Nh6 17. f5 Nxf5 18. Rxf5 Qe7 19. Bg5 Qe8 20. Raf1 Be7 21. Bxe7 Qxe7 22. Rf8+ Qxf8 23. Rxf8+ Kxf8 24. Qf2+ Kg8 25. Qd4 Nc6 26. Qf4 d6 27. e6 Bxe6 28. Ne4 Bd5 29. Ng5 Rf8 30. Qh4 Bxg2+ 31. Kh2 Bd5 32. Qxh7# 1-0",
    B: "1107",
  },
  {
    W: "2282",
    T: "60+0",
    P: "1. d4 c5 2. Nc3 cxd4 3. Qd3 Nc6 4. Nb5 e5 5. c3 dxc3 6. Nxc3 Nf6 7. f3 d5 8. Bg5 d4 9. Bxf6 gxf6 10. Ne4 Be6 11. Nh3 Qa5+ 12. Nd2 Rc8 13. g3 Nb4 14. Qe4 Nc2+ 15. Kf2 Ne3 16. Bg2 Nxg2 17. Kxg2 Qxd2 18. Rad1 Qxb2 19. Rb1 Qxa2 20. Rhe1 Qd5 21. Qd3 e4 22. fxe4 Qc6 23. Nf4 f5 24. Qxd4 Qxe4+ 25. Qxe4 fxe4 26. Rxb7 Bd6 27. Nxe6 fxe6 28. Rd1 Be7 29. Rdd7 Bf6 30. Rxa7 O-O 31. Rxh7 Bh8 32. h4 Rc2 33. g4 Rxe2+ 34. Kg3 Rf3# 0-1",
    B: "1980",
  },
  {
    W: "1034",
    T: "60+0",
    P: "1. e3 d5 2. b3 e5 3. d3 Nf6 4. g3 Nc6 5. Bg2 Bd6 6. Bb2 O-O 7. Qe2 Re8 8. Nc3 a5 9. Nf3 b5 10. O-O-O a4 11. bxa4 bxa4 12. Nxd5 Nxd5 13. Nxe5 Nxe5 14. Bxd5 a3 15. Bc6 axb2+ 16. Kxb2 Rb8+ 17. Kc1 Re7 18. Be4 Ba3+ 19. Kd2 Qd6 20. Rb1 Rxb1 21. Qg4 Qb4+ 22. Ke2 Nxg4 23. Rxb1 Qxb1 24. Kf3 Nf6 25. Bc6 Qxc2 26. h3 1-0",
    B: "1045",
  },
  {
    W: "1681",
    T: "180+0",
    P: "1. e3 d5 2. Nc3 Nf6 3. Qf3 c6 4. Qg3 Bf5 5. d3 Bg6 6. h3 Nbd7 7. a3 e5 8. b4 Bd6 9. b5 e4 10. f4 c5 11. dxe4 d4 12. Nd5 Nxe4 13. Qf3 dxe3 14. Qxe3 O-O 15. Ne2 Re8 16. Qb3 Nef6 17. Bb2 Nxd5 18. Qxd5 Nf6 19. Qc4 Re4 20. Qb3 c4 21. Qf3 Qb6 22. a4 Bb4+ 23. c3 Bc5 24. O-O-O Be3+ 25. Kb1 Rxf4+ 26. Ka1 Rxf3 27. Nd4 Rf2 28. Bxc4 Bxd4 29. Rxd4 Rxb2 30. Kxb2 Rd8 31. Rxd8+ Qxd8 32. Re1 Qd2+ 33. Kb3 Qxe1 34. Kb4 Qb1+ 35. Kc5 b6+ 36. Kc6 Be4+ 37. Kc7 Qb4 38. Kb8 Qd6+ 39. Kxa7 Qc7+ 40. Ka6 1-0",
    B: "1695",
  },
  {
    W: "1077",
    T: "180+0",
    P: "1. e4 e5 2. Qh5 Nf6 3. Qf3 Nc6 4. Ne2 d6 5. d4 Nxd4 6. Nxd4 exd4 7. Qe2 d5 8. e5 Ne4 9. f3 Be7 10. fxe4 dxe4 11. Qxe4 Bd7 12. Qxd4 Bc6 13. Qxd8+ Bxd8 14. Nc3 f6 15. exf6 gxf6 16. Be2 Rg8 17. Bh5+ Ke7 18. O-O Bxg2 19. Re1+ Kd6 20. Nb5+ Kc6 21. c4 Rg5 22. Nd4+ Kc5 23. Ne6+ Kxc4 24. Nxg5 fxg5 25. b3+ Kb5 26. Kxg2 g4 27. a4+ Kb4 28. Re3 Bf6 29. Ba3+ Ka5 30. Rc1 Rd8 31. Rc5+ Kb6 32. Rf5 Rd2+ 33. Kg3 Bd4 34. Re6+ c6 35. a5+ Kc7 36. Rf7+ Kd8 37. Be7+ Ke8 38. Bc5+ Kd8 39. Bxd4 Rxd4 40. Ref6 Rd7 41. Rf8+ Ke7 42. R8f7+ Ke8 0-1",
    B: "1048",
  },
  {
    W: "2017",
    T: "180+0",
    P: "1. e4 c5 2. d4 cxd4 3. c3 d5 4. Bc4 dxc4 5. cxd4 e6 6. Qa4+ Nc6 7. Qxc4 Qxd4 8. Qe2 Bb4+ 9. Nc3 Bxc3+ 0-1",
    B: "2138",
  },
  {
    W: "1541",
    T: "180+0",
    P: "1. e3 d5 2. b3 c5 3. Bb2 Nc6 4. h3 e5 5. Bb5 Bd6 6. Nf3 Ne7 7. Nxe5 O-O 8. Nxc6 bxc6 9. f4 cxb5 10. Qf3 Bb7 11. Qg3 f6 12. h4 d4 13. h5 dxe3 14. dxe3 Nf5 15. Qf2 Nxe3 16. Na3 Re8 17. Kd2 Bxf4+ 18. Kc3 Qd4# 0-1",
    B: "1571",
  },
  {
    W: "1252",
    T: "180+0",
    P: "1. d4 d5 2. Bf4 Nc6 3. e3 Nf6 4. Bd3 e6 5. Nf3 Bd6 6. Bg3 O-O 7. Ng5 h6 8. Nf3 Re8 9. Nbd2 a6 10. c4 Nb4 11. a3 Nxd3+ 12. Ke2 Nxb2 13. Qb3 Nxc4 14. Nxc4 dxc4 15. Qxc4 b5 16. Qd3 Bb7 17. e4 Bxg3 18. e5 Bxf3+ 19. gxf3 Bxe5 20. dxe5 Qxd3+ 21. Kxd3 Red8+ 22. Ke3 Rd7 23. exf6 g6 24. Rac1 Rad8 25. Kf4 Rd4+ 26. Kg3 R8d7 27. Rc6 R4d6 28. Rxd6 Rxd6 29. Rc1 c6 30. f4 Rd3+ 31. Kg4 Rxa3 32. Rxc6 b4 33. Rc8+ Kh7 34. Rf8 b3 35. Rxf7+ Kg8 36. Rb7 Ra2 37. Rb8+ Kf7 38. Rb7+ Kxf6 39. Rxb3 Rxf2 40. h3 e5 41. fxe5+ Kxe5 42. Rb6 Rf4+ 43. Kg3 Rf6 44. Rb4 a5 45. Ra4 Ra6 46. Kf3 Kf6 47. Rf4+ Kg7 48. h4 a4 49. Kg3 a3 50. Rf1 a2 51. Ra1 Kf6 52. Kg4 Ra4+ 53. Kg3 g5 54. hxg5+ hxg5 55. Kf3 Kf5 56. Kg3 g4 57. Kf2 Kg5 58. Kg3 Kf5 59. Kh4 Kf4 60. Rf1+ Ke4 61. Kxg4 a1=Q 62. Rxa1 Rxa1 63. Kg5 Ke3 64. Kf5 Ra7 65. Ke6 Rf7 66. Ke5 Kd2 67. Ke4 Kc1 68. Kd3 Re7 69. Kd4 Kb2 70. Kd5 Rc7 71. Ke5 Ra7 72. Ke4 Ra5 73. Kd4 Kb3 74. Ke4 Rb5 75. Kd4 Kb4 76. Ke4 Rc5 77. Kd4 Rc3 78. Ke4 Rb3 79. Kd4 Ra3 80. Ke4 1/2-1/2",
    B: "1282",
  },
  {
    W: "2533",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Bd6 7. O-O O-O 8. c4 c6 9. Re1 Bf5 10. Nc3 Nxc3 11. bxc3 Bxd3 12. Qxd3 dxc4 13. Qxc4 Nd7 14. Bg5 Nb6 15. Qe2 Qd7 16. Ne5 Bxe5 17. dxe5 Qf5 18. Qe3 Nd5 19. Qg3 Nxc3 20. Bf6 Qg6 21. Qxc3 gxf6 22. Re3 Kh8 23. Rg3 Qh6 24. exf6 Rad8 25. Re1 Qd2 26. h4 Qxc3 27. Rxc3 Rd6 28. Rf3 b5 29. Re7 a5 30. Rc7 b4 31. Ra7 Rd5 32. Re3 Rf5 33. Rxf7 Kg8 34. Rg7+ Kh8 35. Ree7 Rh5 36. Rgf7 Rxf7 37. Rxf7 Kg8 38. Rc7 Rc5 39. Rg7+ Kh8 40. Ra7 Kg8 41. g4 h6 42. Kg2 Rc3 43. g5 hxg5 44. hxg5 Rc5 45. f4 Rc2+ 46. Kg3 Rc1 47. Kh4 a4 48. g6 Rh1+ 49. Kg5 Rg1+ 50. Kf5 Rxg6 51. Kxg6 Kf8 52. Ra8# 1-0",
    B: "2520",
  },
  {
    W: "1933",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 d6 3. c3 f5 4. exf5 Bxf5 5. Bb5+ c6 6. Ba4 Nf6 7. O-O Be7 8. Bb3 Nbd7 9. d4 e4 10. Ng5 d5 11. Bf4 h6 12. Nh3 O-O 13. f3 exf3 14. Rxf3 Bg4 15. Nd2 Nh5 16. Be3 Bxf3 17. Nxf3 Nhf6 18. Bc2 Bd6 19. Qf1 Ne4 20. Nf4 Bxf4 21. Bxf4 Rxf4 22. Qd3 Qf6 23. Re1 Rf8 24. h3 Qf5 25. Qd1 Ndf6 26. Ne5 Rf2 27. Re3 Qf4 28. Qe1 Rxc2 29. Nf3 Rf2 30. Rxe4 Nxe4 31. b4 Qg3 0-1",
    B: "2256",
  },
  {
    W: "2266",
    T: "180+0",
    P: "1. d4 d5 2. Bg5 f6 3. Bh4 Nh6 4. f3 Nf5 5. Bf2 e5 6. dxe5 fxe5 7. e4 dxe4 8. Qxd8+ Kxd8 9. fxe4 Nd4 10. Bd3 Nbc6 11. c3 Ne6 12. Nd2 Nf4 13. Bf1 Bd6 14. g3 Ne6 15. O-O-O Ke7 16. Bc4 Nc5 17. Be3 Bg4 18. Rf1 h6 19. h3 Be6 20. Bd5 Kd7 21. Ngf3 Rhf8 22. Bxc5 Bxc5 23. Bxc6+ Kxc6 24. Nxe5+ Kb6 25. Nec4+ Ka6 26. b3 b5 27. Ne5 Rad8 28. Kc2 Be3 29. Nd3 Kb6 30. g4 c5 31. Rxf8 Rxf8 32. Rf1 Rd8 33. Rf3 Bg5 34. Nf1 a5 35. Ne5 Re8 36. Ng6 a4 37. h4 Bf6 38. Ne3 axb3+ 39. axb3 Bf7 40. h5 Rxe4 41. Kd3 Re8 42. Nf5 Bxb3 43. Nf4 Bc4+ 44. Kc2 Rd8 45. Rf2 Bg5 46. Nxg7 Bxf4 47. Rxf4 Bd3+ 48. Kb2 b4 49. cxb4 cxb4 50. Ne6 Rd6 51. Rf6 1-0",
    B: "2142",
  },
  {
    W: "1859",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 d5 4. dxc5 Bxc5 5. exd5 exd5 6. Bb5+ Nc6 7. O-O Nf6 8. Re1+ Be6 9. Ng5 O-O 10. Nxe6 fxe6 11. Rxe6 Qd7 12. Re1 Ng4 13. Be3 Bd6 14. Qxd5+ Kh8 15. Bxc6 bxc6 16. Qh5 Bxh2+ 17. Kh1 Bc7 18. Nc3 Nxe3 19. Rxe3 Rxf2 20. Rh3 h6 21. Ne4 Rxc2 22. Ng5 Rf8 23. Qg6 Qf5 24. Qxf5 Rxf5 25. Nf3 Bb6 26. Re1 Kh7 27. Re7 Rxb2 28. Nh4 Rf1+ 29. Kh2 Rbb1 30. Nf3 Rh1+ 31. Kg3 Rxh3+ 32. Kxh3 Rb2 33. Ne5 Rxa2 34. Nxc6 Rf2 35. Ne5 Bc5 36. Rf7 Rxf7 37. Nxf7 a5 38. Ne5 a4 39. Nd3 Bd4 40. Nc5 a3 0-1",
    B: "1841",
  },
  {
    W: "1747",
    T: "180+0",
    P: "1. e4 d5 2. exd5 Nf6 3. c4 c6 4. dxc6 Nxc6 5. Nc3 e5 6. Nf3 e4 7. Ng1 Bc5 8. a3 a5 9. Qe2 O-O 10. Nxe4 Nxe4 11. Qxe4 Re8 12. Qxe8+ Qxe8+ 13. Be2 Bf5 14. Nf3 Nd4 15. Nxd4 Bxd4 0-1",
    B: "1800",
  },
  {
    W: "1204",
    T: "180+0",
    P: "1. c4 d5 2. e3 dxc4 3. Bxc4 a6 4. Bxf7+ Kxf7 5. Na3 e5 6. Nc4 b5 7. Nxe5+ Ke8 8. Qh5+ g6 9. Nxg6 hxg6 10. Qxh8 Nf6 11. Qh4 a5 12. b3 b4 13. Bb2 Nd5 14. Qe4+ Ne7 15. Qxa8 Nbc6 16. Bf6 Qd3 17. Bxe7 Nxe7 18. Qxa5 Qc2 19. Qxb4 Qb2 20. Qb5+ c6 21. Qa4 Qxa1+ 22. Ke2 Bg4+ 23. f3 Bd7 24. Qa8+ Kf7 25. Qa4 Qf6 26. Nh3 Qe5 27. Qc4+ 1-0",
    B: "1188",
  },
  {
    W: "1960",
    T: "180+0",
    P: "1. b4 d5 2. Bb2 Nd7 3. e3 e5 4. Nf3 Bd6 5. Be2 Ngf6 6. O-O Qe7 7. b5 b6 8. a4 Bb7 9. c4 O-O-O 10. cxd5 Bxd5 11. d4 e4 12. Nfd2 h5 13. Bc4 Bxh2+ 14. Kxh2 Ng4+ 15. Kg3 h4+ 16. Kxg4 f5+ 17. Kh3 Bxc4 18. Nxc4 g5 19. Qb3 g4+ 20. Kh2 g3+ 21. Kg1 h3 22. Rc1 gxf2+ 23. Kxf2 hxg2 24. d5 Qh4+ 25. Kg1 Rhg8 26. Nbd2 Nc5 27. Qc3 Nd3 28. Qf6 Qh1# 0-1",
    B: "1937",
  },
  {
    W: "1908",
    T: "180+0",
    P: "1. g3 d5 2. d4 e5 3. Bg2 e4 4. c4 c6 5. Nc3 Nf6 6. Qb3 Be7 7. Nh3 b6 8. Nf4 O-O 9. cxd5 cxd5 10. Ncxd5 Nxd5 11. Nxd5 Be6 12. Nxe7+ Qxe7 13. d5 Bf5 14. e3 Qd6 15. O-O Nd7 16. Rd1 Nc5 17. Qb4 a5 18. Qa3 Rac8 19. Bd2 Qxd5 20. Bxa5 Qc6 21. Bb4 Rfe8 22. Rac1 h6 23. Bxc5 bxc5 24. Bf1 Re5 25. b4 Ra8 26. Qb2 Be6 27. a3 f6 28. b5 Qb6 29. Rc3 Rc8 30. Rdc1 c4 31. Qb4 Rxb5 32. Qa4 Ra5 33. Qc2 Rac5 34. Qxe4 Bd5 35. Qg6 Bf7 36. Qf5 Be6 37. Qf4 Rf5 38. Qd4 Rd5 39. Qxb6 1-0",
    B: "1865",
  },
  {
    W: "1593",
    T: "180+0",
    P: "1. e4 e6 2. Nf3 d5 3. e5 c5 4. d4 f6 5. c3 Qb6 6. Bd3 fxe5 7. dxe5 Nc6 8. Be3 Qxb2 9. Nbd2 c4 10. Bc2 Nh6 11. O-O Nf5 12. Nd4 Ncxd4 13. cxd4 Nxe3 14. fxe3 Be7 15. Qh5+ g6 16. Qf3 Qxc2 17. Qf7+ Kd7 18. Nf3 Rf8 19. Qxh7 b6 20. Ng5 Rxf1+ 21. Rxf1 Ba6 22. Rf7 Re8 23. Qxg6 c3 24. Qxe6+ Kc7 25. Rxe7+ Rxe7 26. Qd6+ Kc8 27. Qxe7 Qd1+ 28. Kf2 Qe2+ 29. Kg3 Qxa2 30. Ne6 Qc4 31. Qd8+ Kb7 32. Qd7+ Kb8 33. Qd8+ Bc8 34. Nf8 Kb7 35. Qd6 Ka6 36. Ne6 Bxe6 37. Qxe6 Qe2 38. Qxd5 Qxe3+ 39. Kh4 c2 40. Qc4+ Kb7 41. Qxc2 Qxd4+ 42. Kh5 Qxe5+ 43. Kg6 Qxh2 44. Qf2 Qc7 45. g4 a5 46. Kh5 Qc6 47. g5 Ka6 48. g6 Qc5+ 49. Kh6 Qf8+ 50. Kh5 Qxf2 51. g7 Qg2 52. Kh6 Qxg7+ 53. Kxg7 a4 54. Kg6 a3 55. Kf6 a2 56. Ke6 a1=Q 57. Kd6 b5 58. Kc7 b4 59. Kc6 b3 60. Kc5 b2 61. Kd5 b1=Q 62. Ke6 Qc1 63. Kf5 Qab1+ 64. Kf6 Qd1 65. Ke6 Qbc1 66. Ke5 Qe1+ 67. Kf5 Qcd1 68. Kf4 Qf1+ 69. Ke4 Qde1+ 70. Kd4 Qd1+ 71. Kc5 Ka7 72. Kb4 Qc1 73. Kb3 Qf3+ 74. Ka2 Qf2+ 75. Kb3 Qcb2+ 76. Kc4 Qf4+ 77. Kd5 Qbd2+ 78. Kc5 Qfd4+ 79. Kc6 Qc4# 0-1",
    B: "1632",
  },
  {
    W: "1680",
    T: "300+0",
    P: "1. e4 e6 2. Nc3 d6 3. Nf3 a6 4. d4 Ne7 5. Bc4 Ng6 6. O-O Be7 7. Be3 b6 8. d5 e5 9. a4 O-O 10. b4 Nd7 11. Ne2 Re8 12. Ng3 Bf6 13. Nf5 Ndf8 14. Qd2 Bxf5 15. exf5 Bh4 16. fxg6 Nxg6 17. Bg5 Bxg5 18. Nxg5 h6 19. Ne4 f6 20. f3 f5 21. Nf2 Qf6 22. Rfe1 Kh8 23. g3 f4 24. Ng4 Qf7 25. Re2 fxg3 26. hxg3 Qxf3 27. Nh2 Qxg3+ 28. Rg2 Qf4 29. Qd3 Nh4 30. Rf1 Qxf1+ 31. Qxf1 Nxg2 32. Qxg2 Rf8 33. Bd3 Rf4 34. Qg6 Kg8 35. Ng4 Kf8 36. Ne3 Rf6 37. Qh7 Rf7 38. Qh8+ Ke7 39. Nf5+ Rxf5 40. Qxg7+ Rf7 41. Qxh6 Rg8+ 42. Kh2 Rfg7 43. Qh4+ Rg5 44. Qxg5+ Rxg5 45. Kh3 Rg8 0-1",
    B: "1647",
  },
  {
    W: "1781",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. O-O Bd7 5. c3 a6 6. Ba4 Na5 7. Bc2 Nf6 8. d3 h5 9. h3 Ng4 10. Nbd2 Nh6 11. Re1 g5 12. Nf1 g4 13. Bxh6 Bxh6 14. Nh4 Qxh4 15. hxg4 hxg4 16. Ng3 Bf4 17. Kf1 Bxg3 18. fxg3 Qxg3 19. Bb3 Rh1+ 20. Ke2 Qxg2+ 21. Ke3 Qg3+ 22. Kd2 Nxb3+ 23. axb3 Rxe1 24. Qxe1 Qh2+ 25. Kc1 g3 26. Kb1 g2 27. Ka2 O-O-O 28. Qf2 a5 29. Rg1 a4 30. Rxg2 axb3+ 31. Kxb3 Qh3 32. Rh2 Qxd3 33. Qxf7 Qb5+ 34. Kc2 Bc6 35. Qe6+ Kb8 36. Rh7 Bxe4+ 37. Kc1 Bxh7 38. Kd2 e4 39. Qe7 Qd3+ 40. Kc1 Rg8 0-1",
    B: "1780",
  },
  {
    W: "1600",
    T: "300+0",
    P: "1. e4 e6 2. f4 d5 3. e5 h6 4. Nf3 Bc5 5. d4 Bb4+ 6. c3 Be7 7. Bd3 Bh4+ 8. g3 Be7 9. O-O Qd7 10. Nbd2 a6 11. Nb3 b6 12. Ne1 a5 13. f5 a4 14. fxe6 fxe6 15. Bg6+ Kd8 16. Nd2 Ba6 17. Rf7 Nc6 18. Ndf3 Kc8 19. Nd3 Kb7 20. Nb4 Nxb4 21. cxb4 Bxb4 22. a3 Ba5 23. Rxd7 Kc6 24. Rf7 h5 25. Qc2+ Kb7 26. Qxc7# 1-0",
    B: "1564",
  },
  {
    W: "1925",
    T: "300+0",
    P: "1. f4 d5 2. Nf3 f5 3. e3 e6 4. b3 Be7 5. Bb2 Nf6 6. Ne5 Nc6 7. Bb5 Bd7 8. Bxc6 bxc6 9. O-O O-O 10. Nxd7 Qxd7 11. d4 Ne4 12. Nd2 Nd6 13. Nf3 Ne4 14. Nd2 Nd6 15. Nf3 Rab8 16. Ne5 Qe8 17. Rf3 Ne4 18. Rh3 Bd6 19. Qh5 Qxh5 20. Rxh5 Bxe5 21. dxe5 c5 22. Rh3 c4 23. Rd1 c3 24. Ba1 c5 25. Rf3 Rfd8 26. Rd3 d4 27. exd4 cxd4 28. Rf1 a5 29. Re1 Ra8 30. g4 g6 31. gxf5 gxf5 32. Kg2 Kf7 33. Kf3 Rg8 34. Rdd1 Rg7 35. Rg1 Rag8 36. Rxg7+ Rxg7 37. a3 Nd2+ 38. Kf2 Rg4 39. b4 Rxf4+ 40. Kg3 Rg4+ 41. Kf2 axb4 42. axb4 d3 43. Bxc3 Ne4+ 44. Kf3 dxc2 45. Rc1 Nxc3 46. Rxc2 Nd5 47. b5 Rb4 48. Ra2 Rxb5 49. Ra7+ Kg6 50. Ra8 Rb3+ 51. Kf2 Rb7 52. Rg8+ Rg7 53. Re8 Kf7 54. Ra8 h5 55. h4 Rg4 56. Ra7+ Ke8 57. Ra8+ Kd7 58. Ra7+ Nc7 59. Ra8 Rxh4 60. Rh8 Rf4+ 61. Kg3 Rg4+ 62. Kf3 h4 63. Rh7+ Kc6 64. Rh8 Kd5 65. Rc8 Nb5 66. Rb8 Nd4+ 67. Ke3 Kxe5 68. Ra8 h3 69. Ra5+ Kf6 70. Kf2 h2 71. Ra1 Rh4 72. Rh1 Kg5 73. Kg3 f4+ 74. Kg2 f3+ 75. Kf2 e5 76. Rd1 h1=Q 77. Rxh1 Rxh1 0-1",
    B: "1969",
  },
  {
    W: "979",
    T: "300+0",
    P: "1. e4 e6 2. Qh5 Nf6 3. Qe5 d5 4. Bb5+ c6 5. Ba4 b5 6. Bb3 Nbd7 7. exd5 Nxe5 8. dxe6 fxe6 9. Bxe6 Bxe6 10. d4 Nc4 11. Bg5 Qxd4 12. Nd2 Qxb2 13. Rb1 Qxc2 14. Ngf3 Nxd2 15. Nxd2 Bxa2 16. Rd1 Rd8 17. O-O Qc5 18. Rfe1+ Be7 19. Rc1 Qxg5 20. Nf3 Qf5 21. Nh4 Qh5 22. Nf3 Bd5 23. Nd4 Qg5 24. Nxc6 Qxg2# 0-1",
    B: "941",
  },
  {
    W: "2129",
    T: "300+0",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. h3 O-O 6. Nf3 Nbd7 7. Be2 e5 8. O-O Nh5 9. dxe5 dxe5 10. Bg5 f6 11. Be3 Nf4 12. Nd5 Nxe2+ 13. Qxe2 c6 14. Nc3 Qe7 15. Rad1 Rd8 16. Qc2 Nf8 17. Rxd8 Qxd8 18. Rd1 Bd7 19. Qd2 Qc7 20. Qd6 Qxd6 21. Rxd6 Kf7 22. Na4 Ke7 23. Rd1 Ne6 24. Nc5 Nxc5 25. Bxc5+ Ke6 26. Rd6+ 1-0",
    B: "2060",
  },
  {
    W: "1391",
    T: "300+0",
    P: "1. d4 Nf6 2. e3 d5 3. Nf3 Bg4 4. Be2 Qd6 5. Nbd2 Nbd7 6. b3 O-O-O 7. Bb2 Kb8 8. c4 e5 9. dxe5 Nxe5 10. Nxe5 Bxe2 11. Qxe2 Qe7 12. cxd5 Nxd5 13. Ndf3 Qb4+ 14. Qd2 Qxd2+ 15. Nxd2 Rg8 16. Nxf7 Rd7 17. Ne5 Re7 18. Ndf3 Nb4 19. O-O Re6 20. Nd7+ Ka8 21. Nxf8 Rxf8 22. Bxg7 Rg8 23. Be5 Nd3 24. Bxc7 Rf6 25. Rad1 Nc5 26. Rd8+ Rxd8 27. Bxd8 Rf8 28. Be7 Rc8 29. Rd1 Ne6 30. h3 a6 31. Ne5 Re8 32. Bh4 Nc5 33. Nd7 Nxd7 34. Rxd7 Rc8 35. Kh2 Rc2 36. Rd8+ Ka7 37. f4 Rxa2 38. e4 Ra3 39. Bf2+ b6 40. Rd7+ 1-0",
    B: "1379",
  },
  {
    W: "1624",
    T: "300+0",
    P: "1. e4 d6 2. Nf3 Nf6 3. Nc3 Bg4 4. h3 Bh5 5. g4 Bg6 6. d3 Nc6 7. Nh4 e5 8. Nxg6 fxg6 9. Be2 Be7 10. g5 Nd7 11. h4 Nd4 12. Bg4 O-O 13. Be3 c6 14. Rh3 Nc5 15. Ne2 Nb5 16. c4 Nc7 17. b4 N5e6 18. Qd2 Qe8 19. h5 Qf7 20. O-O-O b6 21. hxg6 Qxg6 22. Bh5 Qxg5 23. Bxg5 Bxg5 24. f4 exf4 25. Kc2 f3 26. Qe1 fxe2 27. Qxe2 Nd4+ 28. Kc3 Nxe2+ 29. Bxe2 Rf2 30. Bg4 Rxa2 31. Bd7 c5 32. Bc6 Rf8 33. bxc5 dxc5 34. Rb1 Rff2 35. Rh5 Bd2+ 36. Kb3 Bb4 37. Bd5+ Kh8 38. Re5 Ra3# 0-1",
    B: "1642",
  },
  {
    W: "1481",
    T: "300+0",
    P: "1. e4 d5 2. Bc4 dxc4 3. Qg4 Bxg4 4. d3 cxd3 5. Nd2 dxc2 6. Ndf3 Nc6 7. Bg5 Qd7 8. Rd1 cxd1=Q# 0-1",
    B: "1508",
  },
  {
    W: "1640",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. O-O d6 5. d3 Bg4 6. Nc3 Nd4 7. Nd5 Bxf3 8. gxf3 Nxd5 9. Bxd5 Qf6 10. c3 Nxf3+ 11. Kg2 Nh4+ 12. Kh3 c6 13. Bb3 Be7 14. Rg1 Nf3 15. Rg4 Qg5 16. Rxg5 Nxg5+ 17. Bxg5 Bxg5 18. Qg4 Bf6 19. Qh5 O-O 20. Rg1 g6 21. Qg4 Kg7 22. f4 h5 23. Qxh5 Rh8 24. Qxh8+ Rxh8+ 25. Kg3 Bh4+ 26. Kf3 exf4 27. Kxf4 g5+ 28. Ke3 Re8 29. Rf1 d5 30. h3 Kg6 31. Kd2 dxe4 32. Bxf7+ Kg7 33. Bxe8 exd3 34. Kxd3 Bg3 35. b3 Kh6 36. Bd7 Bf4 37. Bc8 b6 38. a4 Kh5 39. b4 Kh4 40. Re1 a6 41. Re6 Kxh3 42. Rxc6+ Kh2 43. Rxb6 g4 44. Bxg4 1-0",
    B: "1652",
  },
  {
    W: "2283",
    T: "300+0",
    P: "1. d4 e5 2. dxe5 d6 3. Nf3 Nc6 4. Bf4 Bg4 5. exd6 Qf6 6. Qc1 h6 7. dxc7 g5 8. Bg3 Bg7 9. Nc3 Nge7 10. e3 Bxf3 11. gxf3 Qxf3 12. Rg1 O-O 13. Bg2 Bxc3+ 14. bxc3 Qf6 15. Be4 Rfe8 16. h4 Ng6 17. hxg5 Qxc3+ 18. Kf1 Rxe4 19. gxh6 Kh7 20. Rb1 b6 21. Rb3 Qc4+ 22. Kg2 Nce5 23. Bxe5 Rxe5 24. f4 Qe2+ 25. Kg3 Rh5 26. Rg2 Qc4 27. Qb2 Rxh6 28. Rc3 Qe6 29. Qb3 Qxb3 30. cxb3 Rc8 31. Rd2 Kg7 32. Rd8 Rh8 33. f5 Ne7 34. Rd7 Nxf5+ 35. Kf4 Nh4 36. Ke5 Ng6+ 37. Kd6 Nf8 38. Rd8 Ne6 39. Rxh8 Kxh8 40. Kd7 Rxc7+ 41. Rxc7 Nxc7 42. Kxc7 Kg7 43. Kb7 Kf6 44. Kxa7 Ke5 45. Kxb6 Ke4 46. a4 Kxe3 47. a5 f5 48. a6 f4 49. a7 f3 50. a8=Q f2 51. Qh1 1-0",
    B: "1963",
  },
  {
    W: "1907",
    T: "300+0",
    P: "1. e4 e6 2. Nc3 Nc6 3. Nf3 Bb4 4. d4 Bxc3+ 5. bxc3 Nf6 6. Bd3 O-O 7. O-O Ne8 8. h3 d6 9. Bf4 f5 10. e5 a6 11. c4 dxe5 12. Bxe5 Nxe5 13. dxe5 b6 14. Qe2 Bb7 15. Nh2 Qg5 16. f4 Qg6 17. Nf3 c5 18. Rab1 b5 19. cxb5 axb5 20. Rxb5 Ba6 21. Rxc5 Bxd3 22. Qxd3 Rxa2 23. Qb3 Ra8 24. Rc6 Qh6 25. Ng5 g6 26. Nxe6 Rf7 27. Ng5 Qg7 28. Rf6 Nxf6 29. Nxf7 Ne4 30. Ng5+ Kh8 31. Nxe4 fxe4 32. Qd5 Qa7+ 33. Kh2 e3 34. Qe4 Rc8 35. Re1 Qf7 36. Rxe3 Qe6 37. c3 Rc4 38. Qf3 Kg8 39. Re4 Rc5 40. Qd3 Rd5 41. Rd4 Rxd4 42. cxd4 Qf5 43. Qxf5 gxf5 44. Kg3 Kf7 45. Kf3 Ke6 46. Ke3 Kd5 47. g4 fxg4 48. hxg4 h6 49. f5 Kc6 50. Ke4 Kd7 51. d5 Ke7 52. d6+ Kd7 53. Kd5 Ke8 54. f6 Kf8 55. e6 Ke8 56. f7+ Kf8 57. d7 Ke7 58. Kc6 Kxe6 59. d8=Q Kxf7 60. Qh8 Kg6 61. Qg8+ Kf6 62. Kd7 h5 63. gxh5 Kf5 64. h6 Kf6 65. h7 Kf5 66. h8=R 1-0",
    B: "1868",
  },
  {
    W: "1316",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. Nc3 Nf6 5. d3 Bg4 6. O-O Be7 7. Bxc6+ bxc6 8. Nd5 O-O 9. Nxf6+ Bxf6 10. h3 Bh5 11. h4 Bxh4 12. c3 Rb8 13. d4 exd4 14. cxd4 f6 15. Qb3+ Kh8 16. d5 Bxf3 17. Qxf3 c5 18. Qg4 Bg5 19. Bxg5 fxg5 20. f4 Rxf4 21. Rxf4 gxf4 22. Qxf4 Qf6 23. Qxf6 gxf6 24. Rf1 Kg7 25. Rf3 Rxb2 26. Rg3+ Kf7 27. Ra3 c4 28. Rxa7 c3 29. Rxc7+ Kg6 30. Rxc3 Rxa2 31. Rg3+ Kf7 32. Rh3 Kg7 33. Rg3+ Kf7 34. Rf3 Ra4 35. e5 dxe5 36. d6 Ra7 37. Rh3 Kg7 38. Rg3+ Kf7 39. Rd3 Rd7 40. Kf2 Ke6 41. g4 Rxd6 42. Ra3 Rd2+ 43. Kg3 h6 44. Ra6+ Kf7 45. Ra7+ Ke6 46. Kf3 Rd3+ 47. Ke4 Rg3 48. Rg7 h5 49. g5 fxg5 50. Rg6+ Kf7 51. Rh6 h4 52. Kxe5 Re3+ 53. Kf5 Rg3 54. Rf6+ Kg7 55. Rg6+ Kf7 56. Ra6 h3 57. Ra7+ Ke8 58. Ke6 Re3+ 59. Kf6 g4 60. Ra8+ Kd7 61. Rg8 g3 62. Rg7+ Kd6 63. Rg6 Kd5 64. Kf5 g2 65. Rg4 0-1",
    B: "1353",
  },
  {
    W: "2102",
    T: "300+0",
    P: "1. g3 g6 2. Bg2 Bg7 3. d3 Nf6 4. Nf3 d6 5. O-O O-O 6. Nc3 c5 7. e4 e5 8. Ne2 Bg4 9. c4 Qd7 10. h3 Bxh3 11. Ng5 Bxg2 12. Kxg2 Nh5 13. Rh1 f6 14. Nf3 f5 15. Rxh5 gxh5 16. Nh4 fxe4 17. dxe4 Nc6 18. Nf5 Nd4 19. Nexd4 cxd4 20. Qxh5 Rf6 21. Bg5 Rxf5 22. exf5 Qxf5 23. g4 Qe4+ 24. f3 Qe2+ 25. Kg3 Rf8 26. Qh1 e4 27. f4 Qe3+ 28. Kh4 Qf2+ 29. Kh5 d3 30. Qxe4 Qh2+ 31. Bh4 d2 32. f5 Be5 33. Rh1 Qf4 34. Qxf4 Bxf4 35. Bg5 Bxg5 36. Kxg5 Re8 37. Rd1 Re2 38. Kf6 a6 39. b4 b6 40. a4 Kf8 41. g5 Kg8 42. g6 hxg6 43. fxg6 Rf2+ 44. Ke6 Kg7 45. Kxd6 Kxg6 46. c5 bxc5 47. bxc5 Kg5 48. c6 Rf6+ 49. Kd7 Rf7+ 50. Kd8 Rf8+ 51. Ke7 Rf2 52. c7 Kg6 53. c8=Q 1-0",
    B: "2079",
  },
  {
    W: "2020",
    T: "15+0",
    P: "1. d3 h5 2. Bd2 Nc6 3. g3 d6 4. Bg2 Bg4 5. Nf3 Bd7 6. O-O Ne5 7. Nd4 Nf3+ 8. Nxf3 Bc6 9. Nd4 Bxg2 10. Kxg2 c5 11. Nf3 h4 12. c3 h3+ 13. Kg1 g5 14. c4 g4 15. Ba5 gxf3 16. Bxd8 Kxd8 17. exf3 Kd7 18. Qe2 e6 19. f4 Ne7 20. Qe4 f5 21. Qxb7+ Ke8 22. Qxa8+ Kf7 23. Qf3 d5 24. Nd2 d4 25. Ne4 fxe4 26. Qxe4 Ke8 27. Qe5 Nc6 28. Qxh8 Kd7 29. Qe5 Bd6 30. Qxd6+ Kxd6 31. f5 Ke7 32. f6+ Kf7 33. f4 Ne5 34. fxe5 a5 35. Rf2 a4 36. b3 a3 37. b4 Kg6 38. b5 Kf7 39. Rb1 Kg6 40. Rf4 Kg5 41. Rg4+ Kxg4 42. Kf2 Kg5 43. Rf1 Kf5 44. Ke2+ Kg5 0-1",
    B: "2098",
  },
  {
    W: "1840",
    T: "15+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. Nf3 e6 4. Bb5 d5 5. Bxc6+ bxc6 6. exd5 cxd5 7. d4 cxd4 8. Nxd4 Nf6 9. Nc6 Bc5 10. Nxd8 Kxd8 11. O-O Ke7 12. Be3 Bxe3 13. fxe3 Rd8 14. Rxf6 gxf6 15. Qh5 f5 16. Qh4+ f6 17. e4 fxe4 18. Nxe4 1-0",
    B: "1165",
  },
  {
    W: "1946",
    T: "15+0",
    P: "1. Nc3 e6 2. e4 d5 3. d4 dxe4 4. Be3 Bb4 5. f3 Bxc3+ 6. bxc3 e5 7. fxe4 exd4 8. cxd4 Nf6 9. Nf3 Nxe4 10. Bd3 f5 11. O-O Ng3 12. hxg3 Be6 13. Bg5 Qd7 14. Qe2 f4 15. gxf4 O-O 16. Bb5 1-0",
    B: "1401",
  },
  {
    W: "2061",
    T: "180+2",
    P: "1. d4 Nf6 2. Nc3 d5 3. Bg5 e6 4. e4 dxe4 5. Nxe4 Be7 6. Bxf6 Bxf6 7. Nf3 O-O 8. Bd3 Bxd4 9. c3 Bf6 10. h4 Nd7 11. Qe2 Ne5 12. Nxf6+ gxf6 13. O-O-O Nxd3+ 14. Rxd3 Qe7 15. Rh3 e5 16. Rg3+ Kh8 17. Re3 Bf5 18. h5 Rg8 19. Nd4 Be6 20. Rxe5 Rxg3 21. Nf5 Qd7 22. fxg3 Bxf5 23. Re7 Qd5 24. h6 Bg6 25. Re8+ 1-0",
    B: "1876",
  },
  {
    W: "2000",
    T: "15+0",
    P: "1. c4 d6 2. Nc3 Nf6 3. e3 g6 4. Nf3 Bg7 5. Be2 O-O 6. O-O e5 7. d4 e4 8. Nd2 c5 9. Ndxe4 cxd4 10. Nxf6+ Bxf6 11. exd4 Bg7 12. Be3 Re8 13. d5 Bf5 14. Bd3 Nd7 15. Qd2 Ne5 16. Bxf5 gxf5 17. Qd3 Nxc4 18. Qd2 Ne5 19. Qe2 Ng4 20. f3 Nxe3 21. Rfe1 f4 22. Ne4 Bd4 23. Nxd6 Qxd6 24. Kh1 Qg6 25. Qd2 Nf5 26. Qxd4 Nxd4 27. Red1 Re2 28. Rg1 Rxg2 29. Rxg2 Qxg2+ 30. Kxg2 Re8 31. Rf1 Nf5 32. Rf2 Ne3+ 33. Kg1 Nd1 34. Rg2+ Kf8 35. Rd2 Re5 36. Rxd1 Rg5+ 37. Kf2 Re5 38. Rd2 Re2+ 39. Rxe2 Kg7 40. Rd2 f5 41. Re2 Kg6 42. Re3 Kg5 43. Re4 Kh4 44. Rxf4+ 1-0",
    B: "2081",
  },
  {
    W: "1556",
    T: "300+8",
    P: "1. e4 Nf6 2. e5 Nd5 3. Bc4 Nb6 4. Bb3 d6 5. Qf3 Be6 6. Qxb7 Bxb3 7. axb3 a6 8. d4 e6 9. Nf3 d5 10. O-O Bb4 11. c3 Be7 12. Ng5 O-O 13. b4 Bxg5 14. Bxg5 Qxg5 15. Qxc7 N8d7 16. f4 Qf5 17. Qd6 Qc2 18. Rf2 Qc1+ 19. Rf1 Qxb2 20. h3 Qxa1 21. Nd2 Qxc3 22. Nf3 Qe3+ 23. Kh1 Rad8 24. Ra1 Qxf4 25. Rxa6 Qc1+ 26. Kh2 Nc4 27. Qc6 Ne3 28. Qxc1 Rc8 29. Qxe3 1-0",
    B: "1412",
  },
  {
    W: "1830",
    T: "180+2",
    P: "1. e4 e6 2. Nc3 d5 3. Nf3 Nf6 4. e5 Nfd7 5. d4 c5 6. dxc5 Nc6 7. Bf4 Bxc5 8. Bd3 O-O 9. Bxh7+ Kxh7 10. Ng5+ Kg8 11. Qh5 Qxg5 12. Qxg5 Nd4 13. O-O-O Nf5 14. h4 Bxf2 15. h5 Nxe5 16. Bxe5 Be3+ 17. Qxe3 Nxe3 18. Rd3 Ng4 19. Bf4 Nf2 20. Rdh3 Nxh1 21. Rxh1 f6 22. h6 gxh6 23. Rxh6 Kg7 24. Rh3 Rh8 25. Rg3+ Kf7 26. Rf3 Rg8 27. Be5 Rg6 28. g4 Bd7 29. g5 f5 30. Bf6 Bc6 31. Rh3 Rxf6 32. gxf6 Kxf6 33. Kd2 Rg8 34. b3 Rg2+ 35. Kd3 Rf2 36. a4 Ke5 37. Re3+ Kd6 38. Ne2 e5 39. Rh3 e4+ 40. Ke3 Rf3+ 41. Rxf3 exf3 42. Kxf3 Ke5 43. Nf4 d4+ 44. Kg3 Be4 45. Ng6+ Kd5 46. Ne7+ Kc5 47. Nc8 Bxc2 48. Nxa7 Bxb3 49. Kf4 Bxa4 50. Kxf5 d3 51. Nc8 d2 0-1",
    B: "1794",
  },
  {
    W: "1461",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be2 Bg7 7. O-O O-O 8. Bg5 Qb6 9. Nb3 Bd7 10. h3 Nc6 11. Bxf6 Bxf6 12. Nd5 Qd8 13. Nxf6+ exf6 14. Bg4 Bxg4 15. Qxg4 f5 16. exf5 Ne5 17. Qe4 b5 18. a3 f6 19. fxg6 hxg6 20. Rad1 f5 21. Qd5+ Nf7 22. Rd3 Rc8 23. Rg3 Kg7 24. Qd4+ Kh6 25. Qh4+ Kg7 26. Nd4 Qxh4 27. Ne6+ Kf6 28. Nxf8 Rxf8 29. Re1 Ne5 30. Rd1 Rd8 31. Rd5 a6 32. b3 Qe4 33. Rd2 Qe1+ 34. Kh2 Qxd2 35. h4 Qxf2 36. Rh3 Ng4+ 37. Kh1 Qf1# 0-1",
    B: "1350",
  },
  {
    W: "1703",
    T: "180+2",
    P: "1. d4 d5 2. c4 dxc4 3. e3 Nf6 4. Bxc4 Bg4 5. Nf3 e6 6. h3 Bh5 7. Be2 Bg6 8. Nc3 Bb4 9. Qb3 Nc6 10. Bb5 Bxc3+ 11. Qxc3 O-O 12. Bxc6 bxc6 13. Qxc6 Be4 14. Qc3 Bxf3 15. gxf3 Nd5 16. Qd3 f5 17. e4 Nf4 18. Bxf4 fxe4 19. Qxe4 c5 20. O-O-O cxd4 21. Rxd4 Qa5 22. Qxe6+ Kh8 23. Rd7 Rxf4 24. Qe7 Rc8+ 25. Kb1 Qf5+ 26. Ka1 Rxf3 27. Qxg7# 1-0",
    B: "1698",
  },
  {
    W: "1484",
    T: "180+2",
    P: "1. d4 d5 2. c4 dxc4 3. e4 g6 4. Bxc4 Bg7 5. Nf3 a6 6. e5 b5 7. Be2 e6 8. a3 Ne7 9. Nc3 Nd5 10. O-O O-O 11. h3 Bb7 12. Re1 Nf4 13. Rb1 Nxh3+ 14. gxh3 Bxf3 15. Bxf3 Qh4 16. Bg2 c6 17. Qf3 Ra7 18. Bd2 Qxd4 19. Be3 Qd3 20. Bxa7 Nd7 21. Qxd3 Bxe5 22. Bxc6 Bxc3 23. bxc3 Ne5 24. Qe4 Nxc6 25. Qxc6 1-0",
    B: "1482",
  },
  {
    W: "1711",
    T: "180+2",
    P: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Bg5 Ne4 6. Nxe4 dxe4 7. Nd2 Qxd4 8. e3 Qxb2 9. Rb1 Qxa2 10. Be2 Nc6 11. O-O O-O 12. Nxe4 Bf5 13. f3 Bxe4 14. fxe4 b6 15. c5 Rfd8 16. Qe1 Rd2 17. Bf3 Ne5 18. Rf2 Rxf2 0-1",
    B: "1760",
  },
  {
    W: "1873",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nc3 Bb4 6. Nxc6 Qxe4+ 7. Qe2 Qxe2+ 8. Bxe2 Bxc3+ 9. bxc3 dxc6 10. Ba3 Be6 11. O-O-O Ne7 12. Bxe7 Kxe7 13. Rhe1 Rad8 14. Rxd8 Rxd8 15. Bc4 Rd6 16. f4 Kf6 17. Bxe6 Rxe6 18. Rd1 Ke7 19. Rd2 Re1+ 20. Kb2 Re4 21. g3 Ra4 22. Rd4 Rxd4 23. cxd4 Kd6 24. Kc3 Kd5 25. Kd3 c5 26. c4+ Kd6 27. d5 c6 28. dxc6 Kxc6 29. Ke4 b5 30. Kd3 bxc4+ 31. Kxc4 Kd6 32. g4 Kc6 33. h4 g6 34. g5 a5 35. a4 Kb6 36. Kd5 c4 37. Kxc4 Kc6 38. Kd4 Kd6 39. Kc4 Kc6 40. Kd4 Kd6 41. Kc4 Kc6 1/2-1/2",
    B: "1878",
  },
  {
    W: "2324",
    T: "180+2",
    P: "1. d4 Nf6 2. Nf3 g6 3. c4 Bg7 4. Nc3 d5 5. Bg5 Ne4 6. cxd5 Nxg5 7. Nxg5 e6 8. Nf3 exd5 9. e3 O-O 10. Bd3 c6 11. O-O Be6 12. b4 a6 13. a4 Nd7 14. b5 axb5 15. axb5 Rxa1 16. Qxa1 c5 17. dxc5 Nxc5 18. Bb1 Nb3 19. Qa3 d4 20. Nxd4 Nxd4 21. exd4 Qxd4 22. Ne4 Bc4 23. Rc1 Be6 24. Nc5 Bd5 25. Ba2 Bxa2 26. Qxa2 Rc8 27. Qa3 b6 28. Na6 Rxc1+ 29. Qxc1 Qd3 30. Qf1 Qd2 31. g3 Bd4 32. Kg2 Qc2 33. Nb8 Qe4+ 34. Kg1 h5 35. h4 Qf3 36. Kh2 Bxf2 37. Qg2 Qxg2+ 38. Kxg2 Bd4 39. Nc6 Bc5 40. Kf3 Kg7 41. g4 hxg4+ 42. Kxg4 f5+ 43. Kf3 Kf6 44. Nd8 Bb4 45. Nc6 Be1 46. h5 gxh5 47. Nd4 h4 48. Kf4 Bb4 49. Nxf5 h3 50. Ne3 Bd2 51. Kg3 Bxe3 52. Kxh3 Ke5 0-1",
    B: "2358",
  },
  {
    W: "1389",
    T: "120+1",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nf3 Bg4 4. Be2 Nf6 5. O-O e5 6. Nc3 Qd8 7. Nxe5 Be6 8. Bf3 Nbd7 9. Nxd7 Nxd7 10. Bxb7 Rb8 11. Bc6 Be7 12. Bxd7+ Qxd7 13. b3 O-O 14. Qf3 c6 15. Ne4 f5 16. Nc5 Bxc5 17. Bb2 Bd5 18. Qg3 Bb6 19. c4 Be4 20. Rad1 Bc7 21. Qg5 Bd3 22. Rfe1 h6 23. Qg6 f4 24. Qxh6 gxh6 0-1",
    B: "1409",
  },
  {
    W: "1545",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Bc5 5. Bg5 O-O 6. h3 Nd4 7. Nxd4 exd4 8. Qf3 Be7 9. Nd2 d6 10. Nb3 Be6 11. Bxe6 fxe6 12. Nxd4 e5 13. Nf5 g6 14. Bh6 Rf7 15. Nxe7+ Qxe7 16. Bg5 Qe6 17. h4 Nd5 18. Qg3 Nf4 19. Bxf4 exf4 20. Qf3 d5 21. h5 dxe4 22. dxe4 Re8 23. O-O-O Qxa2 24. c3 Qa1+ 25. Kc2 Qa4+ 26. Kd2 Rd8+ 27. Ke2 Qc4+ 28. Rd3 Re7 29. Kd2 Rxd3+ 30. Qxd3 Qb3 31. Qc2 Rd7+ 32. Kc1 Qa2 33. Rd1 Qa1+ 34. Qb1 Rxd1+ 0-1",
    B: "1575",
  },
  {
    W: "1671",
    T: "120+1",
    P: "1. d4 d5 2. c4 c5 3. cxd5 Qxd5 4. Nf3 cxd4 5. Nc3 Qd8 6. Qxd4 Qxd4 7. Nxd4 a6 8. Bf4 f6 9. g3 e5 10. Ne6 Bxe6 0-1",
    B: "1724",
  },
  {
    W: "1376",
    T: "120+1",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 Bf5 5. Bd3 Nf6 6. Bxf5 Qe7+ 7. Qe2 Qxe2+ 8. Kxe2 Bd6 9. Ne5 Bxe5 10. dxe5 Nfd7 11. f4 g6 12. Bxd7+ Nxd7 13. Nc3 O-O 14. Nxd5 c6 15. Nc3 Rfe8 16. Kf3 f6 17. Re1 fxe5 18. Rxe5 Nxe5+ 19. fxe5 Rxe5 20. Bf4 Rf5 21. g4 Rf6 22. Ne4 Rf7 23. Kg3 Re8 24. Nd6 Rxf4 25. Kxf4 Rf8+ 26. Kg5 b5 27. Re1 Kg7 28. Re7+ Kg8 29. Kh6 Rf2 30. Re8+ Rf8 31. Rxf8+ Kxf8 32. Kxh7 g5 33. Kg6 Ke7 34. Ne4 Ke6 35. Kxg5 Ke5 36. Nc3 Kd4 37. h4 Ke3 38. h5 Kd2 39. h6 Kxc2 40. h7 Kxb2 41. h8=Q Ka3 42. Qa8 Kb2 43. Qxa7 Kxc3 44. Qc5+ Kb2 45. Qxc6 Kxa2 46. Qxb5 Ka3 1-0",
    B: "1363",
  },
  {
    W: "1207",
    T: "120+1",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bb5 e5 4. Bxc6 bxc6 5. Nxe5 Bb7 6. Nc3 c4 7. Qf3 Nh6 8. d3 cxd3 9. Bxh6 f6 10. Bg5 Be7 11. Qh5+ g6 12. Nxg6 fxg5 13. Nxh8+ Kf8 14. Qf7# 1-0",
    B: "1112",
  },
  {
    W: "1440",
    T: "120+1",
    P: "1. e4 c5 2. f4 Nc6 3. Nf3 d6 4. d4 cxd4 5. Nxd4 Nf6 6. Bb5 Bd7 7. e5 dxe5 8. fxe5 Nxe5 9. Qe2 Bxb5 10. Qxb5+ Qd7 11. Qxe5 Rd8 12. c3 e6 13. O-O Bc5 14. Be3 Bxd4 15. Bxd4 Ng4 16. Qf4 Nf6 17. Bxf6 gxf6 18. Qxf6 Rg8 19. Na3 Qc6 20. Qxf7# 1-0",
    B: "1453",
  },
  {
    W: "1583",
    T: "120+1",
    P: "1. d4 d5 2. Bf4 Nf6 3. e3 Nc6 4. Nf3 Bf5 5. c3 h6 6. Bd3 Bxd3 7. Qxd3 e6 8. Nbd2 g5 9. Bg3 Bg7 10. e4 dxe4 11. Nxe4 Qd5 12. Nxf6+ Bxf6 13. Bxc7 Rc8 14. Bg3 h5 15. Qe2 g4 16. Ne5 Qxg2 17. O-O-O Nxe5 18. Bxe5 Bg5+ 19. Kb1 O-O 20. Rdg1 Qd5 21. h3 f5 22. hxg4 fxg4 23. Rxh5 Rf5 24. Qxg4 Kf7 25. Rxg5 Rxg5 26. Qxg5 Rg8 27. Qf6+ Ke8 28. Rxg8+ Kd7 29. Qd8+ Kc6 30. Qc8+ Kb6 31. Qd8+ Qxd8 32. Rxd8 a6 33. Rd6+ Ka7 34. Rxe6 a5 35. Rd6 b6 36. f4 Kb7 37. f5 Kc7 38. f6 b5 39. f7 b4 40. f8=Q bxc3 41. Qe7+ Kc8 42. Rd8# 1-0",
    B: "1631",
  },
  {
    W: "1949",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O Nf6 5. b4 Bxb4 6. c3 Ba5 7. d4 O-O 8. Bd3 d6 9. h3 exd4 10. cxd4 b6 11. a3 Bb7 12. Qc2 h6 13. Bb2 Ne7 14. Nbd2 c5 15. e5 Nfd5 16. exd6 Qxd6 17. dxc5 Qxc5 18. Qb3 Nf4 19. Rac1 Qd6 20. Bc4 Bxd2 21. Rcd1 Bxf3 22. Qxf3 Qg6 23. Rxd2 Ne6 24. Bd3 Qg5 25. Re2 Nf4 26. Bc1 Nxe2+ 27. Bxe2 Qf6 28. Qxf6 gxf6 29. Bxh6 Kh7 30. Be3 Rfd8 31. Re1 Kg7 32. Bf3 Nf5 33. Bxa8 Nxe3 34. Bf3 Ra8 35. Rxe3 Rd8 36. Kf1 a5 37. Ke2 Rd6 38. a4 b5 39. axb5 Rb6 40. Bc6 a4 41. Ra3 Rb7 42. Rxa4 1-0",
    B: "1846",
  },
  {
    W: "1759",
    T: "120+1",
    P: "1. d4 d5 2. c4 Nc6 3. Nf3 Nf6 4. Nc3 Bg4 5. e3 g6 6. h3 Bxf3 7. Qxf3 dxc4 8. Bxc4 Bg7 9. O-O O-O 10. Rd1 e6 11. a3 Qe7 12. b4 a6 13. Bb2 e5 14. Nd5 Nxd5 15. Bxd5 exd4 16. Bxc6 bxc6 17. Bxd4 Rad8 18. Bc5 Qe5 19. Bxf8 Rxf8 20. Rac1 Qb2 21. Rxc6 Qxa3 22. Rxc7 Qxb4 23. Rdd7 a5 24. Rxf7 Qb1+ 25. Kh2 Be5+ 26. g3 Qb4 27. Rxf8+ Qxf8 28. Qd5+ Kh8 29. Qxe5+ Kg8 30. Qf4 1-0",
    B: "1779",
  },
  {
    W: "1260",
    T: "120+1",
    P: "1. e4 d5 2. e5 d4 3. Nf3 Nc6 4. h3 f6 5. Bb5 Qd5 6. Bxc6+ Qxc6 7. Qe2 Qxc2 8. Na3 Qc6 9. exf6 Nxf6 10. d3 e6 11. Bg5 Be7 12. Bxf6 Bxf6 13. O-O O-O 14. Rac1 Qb6 15. Nc4 Qb5 16. a4 Qxa4 17. Ra1 Qb3 18. Ra3 Qb4 19. Rfa1 a6 20. Ra4 Qe7 21. Re1 b5 22. Nce5 bxa4 23. Nc6 Qd6 24. Rc1 Bd7 25. Ncxd4 e5 26. Nc2 Rfe8 0-1",
    B: "1221",
  },
  {
    W: "1656",
    T: "180+2",
    P: "1. d4 d5 2. Bf4 Nf6 3. e3 e6 4. Bd3 c5 5. c4 cxd4 6. exd4 Nc6 7. Nf3 dxc4 8. Bxc4 Bb4+ 9. Nc3 Ne4 10. Bd2 Nxd4 11. Qa4+ Nc6 12. O-O-O Nxf2 13. Bb5 O-O 14. Bxc6 Bxc3 15. Bxc3 Nxd1 16. Rxd1 Qb6 17. Qg4 f6 18. Bd7 Bxd7 19. Rxd7 Qe3+ 20. Kb1 Rf7 21. Rd1 Rc8 22. Re1 Qd3+ 23. Ka1 f5 24. Qb4 Qc4 25. Rxe6 Qxb4 26. Bxb4 Rc1# 0-1",
    B: "1844",
  },
  {
    W: "2068",
    T: "60+0",
    P: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bd3 dxe4 5. Nxe4 c5 6. Nxf6+ Qxf6 7. Nf3 Nc6 8. Bg5 1-0",
    B: "2076",
  },
  {
    W: "2178",
    T: "180+0",
    P: "1. Nf3 d5 2. b3 e6 3. Bb2 f5 4. e3 Bd6 5. c4 Nf6 6. d4 c6 7. Nc3 O-O 8. Be2 Ne4 9. O-O Bd7 10. Ne5 Be8 11. f3 Nxc3 12. Bxc3 Nd7 13. Qc2 Qf6 14. f4 Qh6 15. Rf3 Bxe5 16. dxe5 Bh5 17. Rh3 g6 18. Rf1 Nc5 19. cxd5 cxd5 20. Bb4 b6 21. Bxc5 bxc5 22. Qxc5 Rfc8 23. Qe7 Re8 24. Qf6 Rac8 25. Bxh5 gxh5 26. Qxh6 1-0",
    B: "2031",
  },
  {
    W: "2043",
    T: "60+0",
    P: "1. d4 d5 2. e3 c6 3. Nf3 Nf6 4. Bd3 Bg4 5. c4 Bxf3 6. gxf3 e6 7. cxd5 cxd5 8. Nc3 Bb4 9. Bd2 a6 10. Qe2 Nbd7 11. e4 dxe4 12. fxe4 O-O 13. O-O Bxc3 14. Bxc3 Qc7 15. f4 h6 16. Rf3 Ng4 17. Rg3 h5 18. h3 Ngf6 19. d5 exd5 20. exd5 Nxd5 21. Rxg7+ Kh8 22. Rxf7+ Kg8 23. Rh7 Qc5+ 24. Kh1 Nxf4 25. Rg1+ 1-0",
    B: "1996",
  },
  {
    W: "1782",
    T: "60+0",
    P: "1. e4 e5 2. Bc4 h6 3. Bxf7+ Kxf7 4. Nf3 Ke7 5. c3 Nc6 6. d4 exd4 7. cxd4 Nf6 8. e5 Nd5 9. Qb3 Ke6 10. Nc3 g5 11. Qxd5+ Ke7 12. Qe4 Bg7 13. d5 Na5 14. Nxg5 hxg5 15. Bxg5+ Bf6 16. Bxf6+ Kf7 17. Bxd8 Rxd8 18. Qf5+ Kg8 19. Qg5+ Kf7 20. Qxd8 Nc4 21. Qxc7 b6 22. O-O Na5 23. f4 Bb7 24. f5 Ke7 25. f6+ Ke8 26. e6 dxe6 27. Qe7# 1-0",
    B: "1757",
  },
  {
    W: "2194",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Nb5 d6 6. c4 a6 7. N5c3 Nf6 8. Bd3 Be6 9. Nd5 Bxd5 10. cxd5 Nb4 11. O-O Nxd3 12. Qxd3 Be7 13. Nc3 O-O 14. Be3 Rc8 15. Rfc1 Ng4 16. Bd2 Qd7 17. a3 f5 18. f3 Nh6 19. Bxh6 gxh6 20. Rc2 fxe4 21. Nxe4 Qf5 22. Rac1 Rxc2 23. Rxc2 Rc8 24. Rxc8+ Qxc8 25. Qc3 Qxc3 26. Nxc3 Kf7 27. Kf2 Bg5 28. Ke2 Bc1 29. Nd1 Kf6 30. a4 Kf5 31. b4 e4 32. Nc3 exf3+ 33. gxf3 Ke5 34. Kd3 Bb2 35. Nd1 Kxd5 36. Nxb2 Ke5 37. Nc4+ Ke6 38. Kd4 d5 39. Nb6 Kd6 40. Nxd5 Kc6 41. Ne7+ Kb6 42. Kd5 Kc7 43. Nf5 b5 44. a5 Kd7 45. Nxh6 Ke7 46. Nf5+ Kf6 47. Nd6 h5 48. Nxb5 Kf5 49. Nc7 Kf4 50. Nxa6 Kxf3 51. Nc5 h4 52. Ne4 Kg2 53. Ng5 Kxh2 54. Ke4 Kg3 55. Kf5 h3 56. Nxh3 Kxh3 57. b5 Kh4 58. b6 Kh5 59. b7 Kh6 60. b8=Q Kh5 61. Qd6 Kh4 62. Qf4+ Kh3 63. Qg5 Kh2 64. a6 Kh1 65. a7 Kh2 66. a8=Q Kh3 67. Qh1# 1-0",
    B: "2125",
  },
  {
    W: "1382",
    T: "60+0",
    P: "1. d4 Nf6 2. Nf3 d5 3. Nc3 g6 4. e3 Bg7 5. Be2 O-O 6. O-O Be6 7. a3 c5 8. dxc5 Nbd7 9. Bd2 Nxc5 10. b4 Ncd7 11. Nb5 a6 12. Nbd4 Ne4 13. Nxe6 fxe6 14. Nd4 Nxd2 15. Qxd2 Nb6 16. Nxe6 Qd7 17. Nxf8 Rxf8 18. Bf3 Bxa1 19. Rxa1 d4 20. Rd1 Rd8 21. exd4 Nd5 22. Bxd5+ Qxd5 23. Qd3 e5 24. c4 Qc6 25. d5 Qd6 26. c5 Qb8 27. d6 b6 28. c6 Qc8 29. Qd5+ Kg7 30. d7 Qc7 31. Qd6 Qxd6 32. Rxd6 Kf7 33. c7 Rxd7 34. Rxd7+ Ke8 35. c8=Q# 1-0",
    B: "1440",
  },
  {
    W: "1963",
    T: "60+0",
    P: "1. f4 g6 2. Nf3 Bg7 3. e3 b6 4. Be2 Bb7 5. O-O Nc6 6. Bb5 d6 7. Bxc6+ Bxc6 8. d4 Bb7 9. Nbd2 e6 10. b3 Ne7 11. Bb2 O-O 12. c4 c5 13. Rb1 cxd4 14. Bxd4 Bxd4 15. Nxd4 Nf5 16. Nxf5 exf5 17. Nf3 Re8 18. Re1 Qf6 19. Nd4 Re4 20. Nf3 Rae8 21. Qd3 R4e7 22. Ng5 h6 23. Nf3 Be4 24. Qd4 Qxd4 25. exd4 Bxb1 26. Rxe7 Rxe7 27. Kf2 Bxa2 0-1",
    B: "1993",
  },
  {
    W: "1120",
    T: "60+0",
    P: "1. e4 d5 2. Nf3 dxe4 3. Ng5 Nf6 4. d3 exd3 5. Bxd3 e5 6. Nc3 Nc6 7. Be3 Bb4 8. Qd2 Nd5 9. O-O-O Nxe3 10. fxe3 Qxg5 11. h4 Qh6 12. Bb5 O-O 13. Rdg1 Bg4 14. g3 a6 15. Ba4 b5 16. Bb3 Rad8 17. Qf2 Qf6 18. Qg2 Bxc3 19. bxc3 Rd1+ 20. Rxd1 Bxd1 21. Kxd1 Qd6+ 0-1",
    B: "1156",
  },
  {
    W: "1097",
    T: "60+0",
    P: "1. e4 e5 2. d4 d6 3. d5 f5 4. exf5 Bxf5 5. Nc3 Nf6 6. Nf3 g6 7. Be3 Bg7 8. Qd2 O-O 9. O-O-O Nc6 10. Kb1 Nb4 11. Bd3 c6 12. Bxf5 gxf5 13. dxc6 bxc6 14. h4 f4 15. a3 fxe3 16. Qxe3 Nbd5 17. Nxd5 cxd5 18. Ng5 d4 19. Qd3 Nd5 20. Qxh7# 1-0",
    B: "1058",
  },
  {
    W: "1729",
    T: "60+0",
    P: "1. e4 d6 2. d4 e5 3. d5 Nd7 4. Nf3 c6 5. c4 Qc7 6. Nc3 c5 7. Be2 a6 8. O-O b6 9. b3 h6 10. Be3 g5 11. Nd2 Ngf6 12. f3 Rg8 13. g3 Be7 14. f4 Nf8 15. f5 N8h7 16. g4 Bd7 17. h3 Qc8 18. Kf2 h5 19. Rc1 hxg4 20. hxg4 Kd8 21. Rh1 Kc7 22. Bf1 Rg7 23. Ke2 Rg8 24. Bg2 Nxg4 25. Bf3 Nhf6 26. Bxg4 Nxg4 27. Kd3 Nxe3 28. Kxe3 g4 29. Qg1 Bg5+ 30. Ke2 Bf4 31. Rf1 g3 32. Qg2 Qd8 33. Rh7 Qg5 34. Kd1 Qg4+ 35. Kc2 Bxd2 36. Qxd2 Qf4 37. Rxf4 exf4 38. Qxf4 g2 39. Rxf7 g1=Q 40. f6 Rg2+ 41. Kd3 Qa1 42. Qf5 Rd8 43. Rxd7+ Rxd7 44. f7 Qe1 45. f8=Q Qf1+ 46. Ke3 Qxf5 0-1",
    B: "1725",
  },
  {
    W: "1632",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 e6 4. Nc3 Nc6 5. d3 Nf6 6. Bg5 Be7 7. O-O Bd7 8. h3 a6 9. Qd2 b5 10. Bb3 Rb8 11. a3 O-O 12. Ne2 a5 13. Ng3 a4 14. Ba2 Nd4 15. Nxd4 cxd4 16. c3 dxc3 17. bxc3 Rc8 18. Rac1 e5 19. d4 exd4 20. cxd4 Nxe4 21. Nxe4 Bxg5 22. Nxg5 h6 23. Nf3 Rxc1 24. Rxc1 Qf6 25. d5 Qg6 26. Bb1 Qf6 27. Qd3 g6 28. Rc7 Bf5 29. Qd1 Bxb1 30. Qxb1 Kg7 31. Qxb5 Qa1+ 32. Kh2 Qxa3 33. Qd7 Qd3 34. Qg4 a3 35. Ne1 Qxd5 36. Nc2 a2 37. Ra7 Qb3 38. Qa4 Qxc2 39. Qxc2 a1=N 40. Rxa1 1-0",
    B: "1585",
  },
  {
    W: "1656",
    T: "60+0",
    P: "1. f4 c5 2. Nf3 d5 3. e3 Nc6 4. b3 a6 5. Bb2 Bf5 6. a4 e6 7. Be2 Nf6 8. d3 Bd6 9. h3 h6 10. O-O d4 11. e4 Bh7 12. e5 Nd5 13. exd6 Nxf4 14. Nbd2 Nxe2+ 15. Qxe2 Qxd6 16. Ne4 Qc7 17. Nxc5 Nb4 18. Ne4 Qxc2 19. Qxc2 Nxc2 20. Rac1 Ne3 21. Nd6+ Kd7 22. Ne4 Nxf1 23. Kxf1 Bxe4 24. dxe4 Rac8 25. Bxd4 Rxc1+ 26. Ke2 Rd8 27. Kd3 Ke8 28. e5 Rd1+ 29. Nd2 Rxd4+ 30. Kxd4 Rxd2+ 0-1",
    B: "1654",
  },
  {
    W: "1989",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O d6 5. c3 Qe7 6. d4 Bb6 7. Bb5 Bd7 8. dxe5 dxe5 9. Bg5 Nf6 10. Nbd2 h6 11. Bh4 g5 12. Bg3 Bg4 13. Bxc6+ bxc6 14. Bxe5 O-O 15. Bxf6 Qxf6 16. e5 Qe6 17. Ne4 Bxf3 18. Qxf3 Qxe5 19. Nf6+ Kg7 20. Nd7 Qb5 21. Qf6+ Kh7 22. Nxf8+ Rxf8 23. b3 Qa5 24. Qxc6 Qe5 25. Kh1 Qf4 26. Rae1 Kg8 27. Qe4 Qf6 28. Qe5 Qc6 29. c4 1-0",
    B: "2151",
  },
  {
    W: "1746",
    T: "600+0",
    P: "1. e4 e6 2. d4 g6 3. e5 Bg7 4. f4 Ne7 5. Nf3 d5 6. Be2 O-O 7. O-O a6 8. c3 c5 9. Nbd2 cxd4 10. cxd4 f6 11. b3 Nbc6 12. Bb2 fxe5 13. dxe5 Rxf4 14. g3 Rf5 15. Rf2 Nxe5 16. Bxe5 Rxe5 17. Nxe5 Bxe5 18. Rc1 Qb6 19. Kg2 Nf5 20. Nf1 Bd7 21. Bg4 Nd4 22. Qe1 Bg7 23. Rd1 Nf5 24. Bxf5 gxf5 25. Qd2 Bc6 26. Kh3 d4 27. Qf4 Be4 28. Nd2 Bd5 29. Nf3 Be4 30. Nd2 e5 31. Qg5 Rf8 32. Nxe4 fxe4 33. Rxf8+ Kxf8 34. Rf1+ Kg8 35. Qf5 Qd8 36. Qxe4 Qe8 37. Rd1 Qe7 38. Kg2 b5 39. Rxd4 Qc7 40. Rd3 a5 41. Qd5+ Kh8 42. Qd8+ Qxd8 43. Rxd8+ Bf8 44. Kf3 Kg7 45. Rd5 Kf6 46. Ke4 a4 47. bxa4 bxa4 48. h4 h6 49. g4 Bb4 50. Rxe5 Be1 51. Ra5 Bxh4 52. Rxa4 Bg5 53. Ra6+ Ke7 54. Kf5 Kd7 55. Ra7+ Kd6 56. Rg7 Kd5 0-1",
    B: "1753",
  },
  {
    W: "1724",
    T: "60+0",
    P: "1. d4 d6 2. Nf3 Bf5 3. h3 Bg6 4. e3 Nc6 5. Bd3 e5 6. a3 e4 7. Be2 exf3 8. Bxf3 Qd7 9. b4 O-O-O 10. Bb2 f6 11. Nd2 Nge7 12. c4 Rg8 13. O-O Qf5 14. b5 Nb8 15. a4 Nd7 16. a5 Kb8 17. a6 b6 18. Ba3 Qe6 19. Qb3 Qf7 20. Rfc1 Qe8 21. Qc3 Rc8 22. c5 dxc5 23. dxc5 Ne5 24. cxb6 cxb6 25. Bd6+ Rc7 26. Qxc7# 1-0",
    B: "1665",
  },
  {
    W: "1732",
    T: "120+1",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O b5 6. Bb3 d6 7. Re1 Na5 8. c3 Nxb3 9. Qxb3 c5 10. d4 c4 11. Qd1 Bg4 12. d5 Be7 13. Nbd2 O-O 14. Nf1 Nd7 15. h3 Bxf3 16. Qxf3 Nc5 17. Rd1 Qd7 18. Ng3 g6 19. Bh6 Rfb8 20. Be3 Nd3 21. Rxd3 cxd3 22. Bh6 Bf8 23. Bxf8 Rxf8 24. Qxd3 f5 25. exf5 gxf5 26. c4 bxc4 27. Qxc4 e4 28. Qd4 Qg7 29. Qb6 Qg6 30. Rc1 f4 31. Nf1 f3 32. g3 Qh6 33. Kh2 Qxc1 34. Qxd6 Qxf1 35. Qe6+ Rf7 0-1",
    B: "1728",
  },
  {
    W: "1613",
    T: "300+3",
    P: "1. d4 d5 2. Bf4 Nc6 3. e3 Bf5 4. c4 e6 5. Nc3 Nf6 6. Nb5 Rc8 7. h3 a6 8. Nxc7+ Rxc7 9. Bxc7 Qxc7 10. g4 Bg6 11. h4 Qa5+ 12. Ke2 h6 13. cxd5 exd5 14. Qd3 Bxd3+ 0-1",
    B: "1724",
  },
  {
    W: "1865",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. c3 g6 4. d4 cxd4 5. cxd4 Bg7 6. Nc3 d6 7. Bb5 Bd7 8. O-O Nf6 9. Re1 O-O 10. Bg5 a6 11. Ba4 Bg4 12. h3 Bxf3 13. gxf3 b5 14. Bb3 b4 15. Nd5 Nxd5 16. exd5 Na5 17. Bxe7 Qd7 18. Bxf8 Rxf8 19. Qd2 Qxh3 20. Qxb4 Nxb3 21. axb3 Qxf3 22. Re3 Qxd5 23. Rxa6 Qg5+ 24. Rg3 Qc1+ 25. Kg2 h5 26. Rxd6 Qxb2 27. Qc4 Qd2 28. Rdxg6 1-0",
    B: "1865",
  },
  {
    W: "1132",
    T: "300+3",
    P: "1. e4 e6 2. d4 c6 3. c4 d5 4. e5 c5 5. dxc5 Bxc5 6. Nc3 d4 7. Ne4 b6 8. Nxc5 bxc5 9. b3 Nc6 10. Qg4 g6 11. Bg5 Qa5+ 12. Bd2 Qc7 13. Nf3 Nxe5 14. Nxe5 Qxe5+ 15. Be2 f5 16. Qf3 d3 17. O-O Rb8 18. Bxd3 Bb7 19. Qf4 Qxf4 20. Bxf4 Rd8 21. Be5 Rxd3 22. Bxh8 Nh6 23. g3 Ng4 24. Rfe1 e5 25. Bxe5 Nxe5 26. Rxe5+ Kf7 27. Rxc5 Be4 28. Rc7+ Kg8 29. Rxa7 Rd2 30. Re1 Rb2 31. Rd7 Rb1 32. Rxb1 Bxb1 33. Rd8+ Kg7 34. c5 Bxa2 35. Rb8 h5 36. c6 f4 37. c7 fxg3 38. c8=Q gxf2+ 39. Kxf2 1-0",
    B: "1139",
  },
  {
    W: "1593",
    T: "300+3",
    P: "1. e4 c5 2. c4 d6 3. d3 Nc6 4. Be2 Nf6 5. Nf3 Bg4 6. O-O Bxf3 7. Bxf3 Nd4 8. Be3 Nxf3+ 9. Qxf3 Qc7 10. Nc3 a6 11. e5 dxe5 12. Ne4 Nxe4 13. Qxe4 e6 14. f4 exf4 15. Bxf4 Qc8 16. Be5 Qc6 17. Qg4 Rg8 18. Qh5 g6 19. Qh4 h5 20. d4 cxd4 21. Qxd4 Rd8 22. Qc3 Bh6 23. Rad1 Rxd1 24. Rxd1 Qc5+ 25. Bd4 Qg5 26. Bf6 Qe3+ 27. Kh1 Qxc3 28. bxc3 Bg7 29. Rd8# 1-0",
    B: "1574",
  },
  {
    W: "1221",
    T: "300+3",
    P: "1. c4 e6 2. e4 Bc5 3. d4 Bb4+ 4. Nc3 Qh4 5. Nf3 Qxe4+ 6. Be2 Bxc3+ 7. bxc3 Qc6 8. d5 exd5 9. cxd5 Qxc3+ 10. Bd2 Qc5 11. Rc1 Qxd5 12. O-O Nc6 13. Re1 Nge7 14. Bg5 f6 15. Bd2 Qxa2 16. Bc4 Qa3 17. Rc3 Qd6 18. Rd3 Qc5 19. Bb3 Nb4 20. Rxe7+ Kxe7 21. Re3+ Kd8 22. Rc3 Qf5 23. Nd4 Qe5 24. f4 Qxd4+ 25. Kh1 Nd3 26. Be1 Nf2+ 27. Bxf2 Qxf2 28. Qc1 Re8 29. Be6 Rxe6 30. Rxc7 Re1+ 31. Qxe1 Qxe1# 0-1",
    B: "1218",
  },
  {
    W: "1472",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Qf6 3. d4 exd4 4. Qxd4 Qxd4 5. Nxd4 Nf6 6. Nc3 Bb4 7. Bd3 Bxc3+ 8. bxc3 O-O 9. O-O d5 10. e5 Ng4 11. Nf3 Nc6 12. Rd1 Ncxe5 13. Nxe5 Nxe5 14. Be2 c6 15. Bf4 Re8 16. Re1 Bf5 17. Bd1 Nf3+ 18. Bxf3 Rf8 19. Rab1 Bxc2 20. Rxb7 h6 21. Ree7 g5 22. Be3 f5 23. Rg7+ Kh8 24. Rh7+ Kg8 25. Rbg7# 1-0",
    B: "1375",
  },
  {
    W: "1983",
    T: "300+3",
    P: "1. Nf3 g6 2. g3 Bg7 3. Bg2 d5 4. O-O Nf6 5. c4 c6 6. cxd5 cxd5 7. d4 Nc6 8. Nc3 O-O 9. h3 Ne4 10. a3 h6 11. Qb3 Be6 12. Rd1 Na5 13. Qb4 Rc8 14. e3 Rc4 0-1",
    B: "2051",
  },
  {
    W: "958",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. Bxc6 bxc6 5. O-O Ba6 6. d3 Nf6 7. Qe2 O-O 8. Bg5 Rb8 9. b3 d6 10. c4 d5 11. exd5 cxd5 12. Nxe5 dxc4 13. dxc4 Qd4 14. Nd2 Bb4 15. Nc6 Rb6 16. Nxd4 1-0",
    B: "1031",
  },
  {
    W: "1040",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Bd6 3. Nc3 Qf6 4. d3 Nh6 5. Na4 Nc6 6. c3 b5 7. Bg5 Qe6 8. Bxh6 gxh6 9. d4 bxa4 10. Qxa4 exd4 11. cxd4 Qxe4+ 12. Be2 Qg6 13. Nh4 O-O 14. O-O Qe6 15. Bc4 Qg4 16. Nf3 Nxd4 17. Nd2 Bb7 18. g3 Nf3+ 19. Nxf3 Qxf3 20. Qxd7 Qg2# 0-1",
    B: "1047",
  },
  {
    W: "1238",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nxd4 5. Qxd4 b6 6. Bc4 f6 7. Bxg8 Rxg8 8. Qd5 Bb4+ 9. c3 Qe7 10. Qxg8+ Qf8 11. Qd5 Rb8 12. cxb4 Bb7 13. Qd4 Qe7 14. O-O Kf8 15. Bf4 c5 16. bxc5 Re8 17. f3 bxc5 18. Qc4 d5 19. exd5 1-0",
    B: "1229",
  },
  {
    W: "1738",
    T: "300+0",
    P: "1. d4 Nf6 2. Bf4 c5 3. c3 Nc6 4. d5 Na5 5. e4 Nxe4 6. Qd3 Nf6 7. b4 Qb6 8. Qd2 cxb4 9. cxb4 Ne4 10. bxa5 Qf6 11. Qe3 Nxf2 12. Kxf2 Qxa1 13. Nc3 Qb2+ 14. Be2 d6 15. Nf3 Bg4 16. Rb1 Qc2 17. Rxb7 Rc8 18. Nb5 Bxf3 19. Nxd6+ Kd8 20. Nxf7+ Ke8 21. Nd6+ Kd8 22. gxf3 exd6 23. Bg5+ 1-0",
    B: "1815",
  },
  {
    W: "1629",
    T: "60+0",
    P: "1. d4 d5 2. e3 e6 3. Be2 Nf6 4. Nd2 g6 5. h4 Bg7 6. h5 b6 7. hxg6 hxg6 8. Rxh8+ Bxh8 9. f4 Bb7 10. g4 c5 11. c3 Nc6 12. g5 Ne4 13. Nxe4 dxe4 14. Bd2 cxd4 15. cxd4 Qe7 16. Qa4 O-O-O 17. O-O-O Kb8 18. Kb1 f5 19. Ba6 Bxa6 20. Qxa6 Qb7 21. Qa4 b5 22. Qb3 Re8 23. Bb4 Nxb4 24. Qxb4 Qb6 25. Ne2 a5 26. Qb3 Bg7 27. Nc3 Bf8 28. Na4 Qb7 29. Qxe6 Rxe6 30. Nc5 Bxc5 31. dxc5 Qc6 32. b4 axb4 33. Rd8+ Kb7 34. Rd6 Rxd6 35. cxd6 Qxd6 36. a4 Qd3+ 37. Kb2 Qxe3 38. axb5 Qxf4 39. b6 Qxg5 40. Kb3 f4 41. Kxb4 f3 42. Kc4 f2 43. Kb4 f1=Q 44. Kc3 Qd5 45. Kc2 Qfd1+ 46. Kc3 Q5d4# 0-1",
    B: "1645",
  },
  {
    W: "2159",
    T: "900+15",
    P: "1. e4 c5 2. b4 b6 3. bxc5 bxc5 4. c3 d5 5. exd5 Qxd5 6. Be2 Bb7 7. Bf3 Qd7 8. Qb3 Nc6 9. Na3 Rb8 10. Rb1 e6 11. Qa4 Bd6 12. Rxb7 1-0",
    B: "2111",
  },
  {
    W: "1757",
    T: "900+15",
    P: "1. e4 d6 2. d4 h6 3. g3 Nc6 4. Bg2 e6 5. Ne2 Nf6 6. O-O Be7 7. d5 Ne5 8. f4 Ned7 9. c4 c5 10. Nbc3 O-O 11. a3 Nb6 12. Qd3 Bd7 13. b4 Rc8 14. bxc5 Rxc5 15. Be3 Rc8 16. a4 Nxc4 17. Bxa7 Qa5 18. Nb5 exd5 19. exd5 Ng4 20. Rfc1 Nd2 21. Rxc8 Rxc8 22. Ra2 Bxb5 23. Rxd2 0-1",
    B: "1726",
  },
  {
    W: "1486",
    T: "900+15",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bc4 a6 4. a3 g6 5. d3 Bg7 6. O-O e6 7. Re1 Nge7 8. Be3 b6 9. Bg5 Bxb2 10. Ra2 Bg7 11. c3 b5 12. Bb3 Na5 13. Bc2 c4 14. d4 e5 15. dxe5 Bxe5 16. Nxe5 d6 17. Nf3 Bb7 18. Bf6 O-O 19. e5 d5 20. Qd4 Nac6 21. Qh4 Qc7 22. Ng5 h5 23. e6 Nd8 24. exf7+ Nxf7 25. Ne6 Qd7 26. Nxf8 Rxf8 27. Rxe7 1-0",
    B: "1612",
  },
  {
    W: "1363",
    T: "900+15",
    P: "1. c4 e5 2. Nc3 d6 3. g3 Be6 4. b3 Nc6 5. Bg2 Nf6 6. Bxc6+ bxc6 7. Nf3 Be7 8. O-O O-O 9. e4 d5 10. exd5 cxd5 11. cxd5 Bxd5 12. Nxd5 Qxd5 13. Ng5 e4 14. Nh3 Qd4 15. Rb1 Qd7 16. Nf4 Bb4 17. d3 exd3 18. Nxd3 Ba5 19. b4 Rab8 20. Rb3 Bb6 21. Ne5 Qh3 22. Rd3 Rbd8 23. Rxd8 c5 24. Rxf8+ Kxf8 25. Nd7+ Nxd7 26. Be3 Ne5 27. Bxc5+ Bxc5 28. Qd8# 1-0",
    B: "1431",
  },
  {
    W: "1987",
    T: "900+15",
    P: "1. Nh3 b6 2. Nc3 Bb7 3. f3 e6 4. g3 Nf6 5. Bg2 d5 6. O-O Be7 7. d4 O-O 8. Bg5 Nbd7 9. e3 Ba6 10. Re1 c5 11. dxc5 Nxc5 12. a3 Rc8 13. e4 d4 14. Ne2 d3 15. cxd3 Nxd3 16. Rf1 Nxb2 17. Nhf4 Nxd1 0-1",
    B: "2016",
  },
  {
    W: "1220",
    T: "900+15",
    P: "1. Nf3 Nf6 2. Nc3 g6 3. e4 d6 4. e5 dxe5 5. Nxe5 Bg7 6. d4 O-O 7. Bc4 e6 8. Bg5 c6 9. Qf3 Qxd4 10. Qg3 b5 11. Rd1 Ne4 12. Qf4 Qxf2+ 13. Qxf2 Nxf2 14. Kxf2 bxc4 15. Be7 Re8 16. Bd6 Nd7 17. Nxd7 Bxd7 18. Be5 Re7 19. Bd6 Ree8 20. Bc7 Re7 21. Bd6 Ree8 22. Bc5 Bc8 23. Bb4 a5 24. Bc5 a4 25. Rd6 Bb7 26. Ne4 Be5 27. Rd7 Bc8 28. Nd6 Bxd6 29. Rxd6 Bb7 30. Rhd1 Ra5 31. Bb6 Rf5+ 32. Kg1 Rd5 33. R1xd5 cxd5 34. Rd7 Bc6 35. Rd8 Rxd8 36. Bxd8 Kg7 37. Ba5 Bb5 38. Bc3+ f6 39. a3 h5 40. g3 g5 41. Bd4 Kg6 42. Kf2 f5 43. Kf3 g4+ 44. Kf4 h4 45. gxh4 Kh5 46. Bf6 d4 47. Bxd4 Bc6 48. Bf6 Bg2 49. c3 Be4 50. Be7 Kg6 51. Bd8 Kf7 52. Bg5 Kg6 53. Ke5 Bd5 54. Kf4 Kf7 55. Ke5 Ke8 56. Kd6 Kf7 57. Kc5 Ke8 58. Kb4 Kd7 59. Kxa4 Kd6 60. Kb5 e5 61. a4 e4 62. a5 Bc6+ 63. Kb6 Kd5 64. a6 Kd6 65. a7 Kd7 66. Bf4 Ba8 67. Kc5 Kc8 68. Kxc4 Kb7 69. Be3 Ka6 70. b4 Bb7 71. b5+ Ka5 1-0",
    B: "1344",
  },
  {
    W: "1908",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Bc5 5. c3 Bb6 6. O-O h6 7. Be3 d5 8. exd5 Nxd5 9. Bxb6 axb6 10. Re1 f6 11. d4 Bg4 12. dxe5 Bxf3 1-0",
    B: "1918",
  },
  {
    W: "1551",
    T: "60+0",
    P: "1. e4 g6 2. d4 Bg7 3. Be3 d6 4. Bd3 Nf6 5. e5 dxe5 6. dxe5 Nd5 7. f4 Nxe3 8. Qe2 Nd5 9. Nf3 Nxf4 0-1",
    B: "1506",
  },
  {
    W: "1706",
    T: "60+0",
    P: "1. d4 d5 2. c4 Nc6 3. Nf3 Nf6 4. Nc3 e6 5. Bf4 Bb4 6. e3 Bxc3+ 7. bxc3 Ne4 8. Rc1 O-O 9. c5 Bd7 10. Bd3 Qf6 11. O-O Rad8 12. Ne5 Nxe5 13. Bxe5 Qe7 14. f3 Qe8 15. fxe4 Ba4 16. Qg4 dxe4 17. Qxg7# 1-0",
    B: "1733",
  },
  {
    W: "1428",
    T: "60+0",
    P: "1. d4 Nf6 2. Bf4 g6 3. Nc3 Bg7 4. Nb5 O-O 5. Nxc7 d5 6. Nxa8 Nc6 7. Nc7 e5 8. dxe5 Ng4 9. e6 fxe6 10. e3 e5 11. Bg3 Qxc7 12. h3 Qa5+ 13. c3 Nh6 14. b4 Qb6 15. Qb3 Be6 16. Bd3 e4 17. Be2 Ne5 18. Bg4 Nhxg4 19. hxg4 d4 20. exd4 Bxb3 21. axb3 Nd3+ 22. Kd2 Qc6 23. Be5 Bxe5 24. dxe5 Nxb4 25. cxb4 Rd8+ 26. Ke3 Qc2 27. Nh3 Qd3+ 28. Kf4 Rf8+ 29. Kg5 Kg7 30. f3 h6+ 31. Kh4 Qe3 32. g5 hxg5+ 33. Nxg5 Qf4+ 0-1",
    B: "1465",
  },
  {
    W: "1311",
    T: "60+0",
    P: "1. e4 e6 2. Nf3 d5 3. exd5 exd5 4. d4 c5 5. dxc5 Bxc5 6. Bd2 Nc6 7. Bb5 Qb6 8. Qe2+ Ne7 9. Bxc6+ bxc6 10. O-O Qxb2 11. Nc3 Qxc2 12. Rfe1 O-O 13. Ng5 Re8 14. Qh5 g6 15. Qh6 Bd4 16. Qxh7+ Kf8 17. Qxf7# 1-0",
    B: "1264",
  },
  {
    W: "2051",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Nf6 3. Nc3 Nxd5 4. Bb5+ c6 5. Nxd5 cxb5 6. Ne3 Nc6 7. Nf3 e5 8. d3 Bc5 9. O-O O-O 10. Re1 h6 11. h3 a6 12. Nf1 Qb6 13. Be3 Be6 14. Bxc5 Qxc5 15. Ng3 Rad8 16. Qe2 f6 17. Rac1 Rd7 18. Nh4 Nd4 19. Qd2 Rfd8 20. Qe3 Qc6 21. c3 Nb3 22. axb3 Bxb3 23. Nhf5 Be6 24. Qf3 Rxd3 25. Qxc6 bxc6 26. Ne7+ Kf7 27. Nxc6 R8d6 28. Nb4 a5 29. Nxd3 Rxd3 30. Rcd1 1-0",
    B: "1802",
  },
  {
    W: "2391",
    T: "60+0",
    P: "1. d4 g6 2. h4 c6 3. h5 d5 4. hxg6 fxg6 5. Qd3 Nf6 6. Bg5 Bg7 7. e3 Bf5 8. Qd2 Ne4 9. Qd1 Nxg5 10. Nc3 Ne4 11. Bd3 Nxc3 12. bxc3 Bxd3 13. Qxd3 O-O 14. Nf3 Nd7 15. Ng5 e5 16. Ne6 e4 17. Qe2 Qa5 18. Nxf8 Qxc3+ 19. Qd2 Qxd2+ 20. Kxd2 Rxf8 21. Ke2 b5 22. a4 b4 23. a5 Rb8 24. a6 Bf8 25. f3 exf3+ 26. gxf3 c5 27. dxc5 Bxc5 28. Rhd1 Nb6 29. Ra5 Rc8 30. Rb5 Kf7 31. Rh1 h5 32. f4 Kf6 33. f5 Be7 34. fxg6 Kxg6 35. Rg1+ Bg5 36. Kd3 Re8 37. e4 Rxe4 38. Rxd5 Nxd5 39. Kxe4 Nf6+ 40. Kd4 Ng4 41. Kc4 Bf6 42. Kxb4 Kg5 43. c4 Be7+ 44. c5 Bxc5+ 45. Kxc5 h4 46. Rxg4+ Kxg4 47. Kc6 h3 48. Kb7 h2 49. Kxa7 h1=Q 0-1",
    B: "2306",
  },
  {
    W: "1012",
    T: "60+0",
    P: "1. f4 d5 2. Nf3 Bf5 3. e3 Nc6 4. Bb5 a6 5. Bxc6+ bxc6 6. Nc3 f6 7. a3 Nh6 8. O-O Rb8 9. Nd4 Bd7 10. f5 c5 11. Ne6 Bxe6 12. fxe6 Qd6 0-1",
    B: "1104",
  },
  {
    W: "1755",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 Nxd4 6. Qxd4 Nf6 7. f4 Bg4 8. Bb5+ Bd7 9. e5 dxe5 10. fxe5 Bxb5 11. Nxb5 Qxd4 12. Nxd4 Rd8 13. exf6 Rxd4 14. fxg7 Bxg7 15. Be3 Re4 16. Kf2 Bxb2 17. Rab1 Bf6 18. Rxb7 O-O 19. Rxa7 Rc8 20. Rc1 Rc3 21. Bd2 Rcc4 22. Ra3 Bd4+ 23. Kf1 Be5 24. Re3 Rxe3 25. Bxe3 Bb2 26. Rb1 Rxc2 27. Ke1 Bc3+ 28. Kd1 Rxg2 29. Rb8+ Kg7 30. Rc8 Bf6 31. a4 Rxh2 32. a5 Ra2 33. Bb6 Bc3 34. Rxc3 Ra1+ 35. Kd2 Ra2+ 36. Kd3 Rh2 37. Kc4 Rh4+ 38. Kb5 Rh5+ 39. Rc5 Kg6 40. a6 Rh3 41. a7 Rh2 42. a8=Q Rb2+ 43. Kc6 1-0",
    B: "1737",
  },
  {
    W: "2207",
    T: "60+0",
    P: "1. d4 Nf6 2. c4 e6 3. Bd2 c5 4. e3 cxd4 5. exd4 d5 6. Nc3 Nc6 7. Nf3 Bd6 8. c5 Bc7 9. Bd3 Qe7 10. a3 e5 11. dxe5 Nxe5 12. Nxe5 Qxe5+ 13. Qe2 O-O 14. Qxe5 Bxe5 15. O-O-O Bg4 16. f3 Be6 17. Bg5 Nd7 18. Bc2 Nxc5 19. Be7 Rfc8 20. Kb1 Bxc3 21. bxc3 b6 22. f4 Na6 23. f5 Bd7 24. Rxd5 Bc6 25. Rd2 Rc7 26. Bd6 Rd7 27. Bg3 Rxd2 0-1",
    B: "2249",
  },
  {
    W: "1606",
    T: "60+0",
    P: "1. e4 b6 2. Nf3 Bb7 3. Nc3 e6 4. Bc4 d6 5. d4 g6 6. Be3 Bg7 7. a3 Nf6 8. Qd2 Nc6 9. h3 a6 10. b4 O-O 11. Bh6 Re8 12. Bxg7 Kxg7 13. g4 Rg8 14. g5 Nd7 15. h4 Kf8 16. h5 Qe7 17. hxg6 fxg6 18. Qf4+ Qf7 19. Qxf7+ Kxf7 20. d5 exd5 21. Bxd5+ Kf8 22. Bxg8 Kxg8 23. O-O-O Kg7 24. Nd4 Nxd4 25. Rxd4 Rd8 26. f3 Ne5 27. Rf1 b5 28. Nd5 Bxd5 29. Rxd5 Nc4 30. Rd3 c6 31. f4 d5 32. e5 d4 33. e6 Re8 34. Rxd4 Ne3 35. Rd7+ Re7 36. Rxe7+ Kf8 37. Rh1 Kxe7 38. Rxh7+ Kxe6 0-1",
    B: "1694",
  },
  {
    W: "1902",
    T: "60+1",
    P: "1. e4 e6 2. d3 d5 3. Nd2 dxe4 4. Nxe4 Nf6 5. Nc3 Bb4 6. Bd2 O-O 7. g3 Nc6 8. Bg2 Bd7 9. Nge2 Rb8 10. O-O Re8 11. Ne4 Nxe4 12. Bxe4 e5 13. Bxc6 Bxc6 14. Bxb4 Qf6 15. f4 exf4 16. Nxf4 Qxb2 17. Bd2 g5 18. Nh5 Qd4+ 19. Rf2 Re6 20. Bxg5 Rbe8 21. Nf6+ Rxf6 22. Bxf6 Re6 23. Bxd4 1-0",
    B: "1456",
  },
  {
    W: "1233",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3 Bb4 5. d3 Bxc3+ 6. bxc3 O-O 7. d4 d6 8. Bg5 Bg4 9. O-O h6 10. Bh4 a6 11. h3 Bh5 12. Qd3 Bg6 13. dxe5 Bxe4 14. Qe3 Nxe5 15. Bxf6 Nxf3+ 16. gxf3 Qxf6 17. Qxe4 Rae8 18. Bd3 g6 19. Qf4 Qxc3 20. Qg4 Qf6 21. Qh4 Qg7 22. Qg4 c5 0-1",
    B: "1286",
  },
  {
    W: "1434",
    T: "60+0",
    P: "1. d4 g6 2. c4 Bg7 3. Nc3 e6 4. Nf3 Ne7 5. e4 O-O 6. Bd3 c6 7. O-O d5 8. cxd5 cxd5 9. e5 Nbc6 10. Re1 b6 11. a3 Bd7 12. b4 Rc8 13. Bg5 Nb8 14. Rc1 f6 15. exf6 Bxf6 16. Bf4 Nbc6 17. Ne2 Nb8 18. Bb1 Rxc1 19. Qxc1 Qc8 20. Qxc8 Bxc8 21. Bd6 Nd7 22. Rc1 Ba6 23. Rc7 Bxe2 24. Rxd7 Nf5 25. Bxf8 Kxf8 26. Nd2 Nxd4 27. Rxa7 b5 28. Rxh7 Kg8 29. Rh6 Kg7 30. Rh3 g5 31. Rg3 Kh6 32. Nf3 Be5 33. Nxd4 Bxd4 34. Rh3+ Kg7 35. g3 Kf6 36. Kg2 g4 37. f3 gxh3+ 38. Kxh3 Bf1+ 39. Kh4 Be5 40. g4 Ke7 41. f4 Bf6+ 42. g5 Bxg5+ 43. Kxg5 Be2 44. f5 Kf7 0-1",
    B: "1467",
  },
  {
    W: "2466",
    T: "60+0",
    P: "1. c3 g6 2. d3 Bg7 3. Nd2 d6 4. g3 c6 5. Ngf3 c5 6. Bg2 Nc6 7. O-O e6 8. e4 Nge7 9. Re1 O-O 10. Nf1 b6 11. Qc2 h6 12. h4 Bb7 13. N3h2 Qd7 14. h5 g5 15. f4 f6 16. Bh3 Rae8 17. Ne3 Kh8 18. Bd2 gxf4 19. gxf4 Rg8 20. Nd5 f5 21. Nxe7 Qxe7 22. exf5 Qh4 23. Bg4 exf5 24. Rxe8 Rxe8 25. Be1 Rxe1+ 26. Rxe1 Qxe1+ 27. Kg2 Nd4+ 28. Kh3 fxg4+ 29. Nxg4 Nxc2 30. f5 Bc8 31. f6 Qh1+ 32. Kg3 Qg1+ 33. Kf4 Qxg4# 0-1",
    B: "2418",
  },
  {
    W: "1787",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e5 7. Bxf6 Qxf6 8. Nd5 Qh4 9. Nf5 Qxe4+ 0-1",
    B: "1847",
  },
  {
    W: "2379",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. c4 Bg7 6. Nxc6 bxc6 7. Nc3 Qc7 8. Be3 d6 9. Qd2 Nf6 10. f3 O-O 11. Bh6 a5 12. h4 d5 13. h5 Qg3+ 14. Ke2 Bxh6 15. Qxh6 dxe4 16. hxg6 fxg6 17. Nxe4 Qe5 18. Kd1 Rf7 19. Kc2 Bf5 20. Bd3 Rd8 21. Rad1 Bxe4 22. Bxe4 Rxd1 23. Rxd1 Rf8 24. Bxc6 Rc8 25. Ba4 Qe2+ 26. Kb1 e6 27. c5 Kf7 28. c6 Qc4 29. Bb3 Qxc6 30. Qe3 Re8 31. Re1 Re7 0-1",
    B: "2416",
  },
  {
    W: "995",
    T: "180+0",
    P: "1. e4 e6 2. e5 Na6 3. f4 f5 4. Nf3 c5 5. c4 d6 6. Nc3 Nc7 7. exd6 Bxd6 8. Nb5 Bxf4 9. d4 Nxb5 10. cxb5 Bxc1 11. Qxc1 e5 12. dxc5 Ne7 13. Nxe5 Qc7 14. Qe3 O-O 15. Bc4+ Kh8 16. Nf7+ Rxf7 17. Bxf7 Bd7 18. Qxe7 Qa5+ 19. Kd1 Rd8 20. Ke2 Bxb5+ 21. Kf2 Rd2+ 22. Ke3 Re2+ 23. Kf3 Rxe7 24. Rae1 Rxe1 25. Rxe1 Qxe1 26. g4 Qf1+ 27. Kg3 Bc6 28. gxf5 h5 29. Kh4 Be4 30. Kxh5 1-0",
    B: "1054",
  },
  {
    W: "1464",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 d6 3. Nc3 g6 4. d4 cxd4 5. Nxd4 Bg7 6. Bc4 Nf6 7. O-O O-O 8. Re1 Nc6 9. Nf3 Ng4 10. h3 Nge5 11. Bb3 Nxf3+ 12. Qxf3 Ne5 13. Qd1 Qc7 14. a3 Qd7 15. Bf4 Qc6 16. f3 Nc4 17. Bxc4 Qxc4 18. Ne2 Bxb2 19. Rb1 Bxa3 20. Kh2 Bb4 21. Rf1 Be6 22. Bh6 Rfc8 23. Rc1 Qc7 24. Qd4 Bc5 25. Qg7# 1-0",
    B: "1477",
  },
  {
    W: "1664",
    T: "180+0",
    P: "1. d4 d5 2. Nf3 Nf6 3. Bf4 Nc6 4. a3 e6 5. e3 Bd6 6. Ne5 Nh5 7. Qxh5 1-0",
    B: "1684",
  },
  {
    W: "1768",
    T: "180+0",
    P: "1. d4 f5 2. Bf4 e6 3. e3 d5 4. Nf3 Bd6 5. Bg3 Nf6 6. Bd3 Ne4 7. Nbd2 O-O 8. Qe2 Nd7 9. Bxd6 Nxd6 10. Ne5 Ne4 11. Nxe4 fxe4 12. Bb5 c6 13. Ba4 Qf6 14. Nxd7 Bxd7 15. O-O-O b5 16. Bb3 a5 17. a3 b4 18. a4 Qe7 19. f3 exf3 20. gxf3 c5 21. dxc5 Qxc5 22. Rhg1 Rac8 23. f4 Be8 24. Qg4 Bg6 25. Qxe6+ Kh8 26. f5 Rce8 27. Qxd5 Qxe3+ 28. Kb1 Re5 29. Qd6 Rexf5 30. Rde1 Qxb3 31. cxb3 R5f6+ 32. Ka2 Rxd6 33. Re5 Ra6 34. Rge1 h6 35. h4 Bf7 36. h5 Rf6 37. Rxa5 Rf3 38. Kb1 Bxb3 39. Ra6 Rd8 40. a5 Rfd3 41. Rc1 Rd1 42. Rc6 Ba4 43. Rc4 Bb3 44. Rc5 Bg8 45. a6 b3 46. a7 Bh7+ 47. Ka1 Rxc1+ 48. Rxc1 Ra8 49. Rc7 Bc2 50. Rb7 Rxa7+ 51. Rxa7 Kg8 52. Ra3 Kf7 53. Ra7+ Kf6 54. Ra6+ Kg5 55. Rc6 1-0",
    B: "1827",
  },
  {
    W: "1955",
    T: "180+0",
    P: "1. e4 c5 2. Nc3 Nc6 3. Bc4 d6 4. d3 e5 5. Nge2 h6 6. O-O Nf6 7. f4 exf4 8. Nxf4 Be7 9. Nfd5 Nxd5 10. Nxd5 O-O 11. Qh5 Be6 12. c3 Na5 13. Nxe7+ Qxe7 14. Bd5 Bxd5 15. Qxd5 Nc6 16. Bf4 Rad8 17. Rf2 Qe6 18. Qh5 Qg6 19. Qf3 Ne5 20. Qe2 Rde8 21. Raf1 f6 22. h3 Rc8 23. d4 cxd4 24. cxd4 Nc4 25. b3 Na3 26. Bxd6 Rfd8 27. Bxa3 Rxd4 28. Bb2 Rxe4 29. Qb5 b6 30. Qd7 Rce8 31. Bxf6 gxf6 32. Rxf6 Qg7 33. Qd5+ Kh8 34. Qh5 R4e6 35. Rxh6+ Qxh6 36. Qxh6+ Rxh6 0-1",
    B: "1955",
  },
  {
    W: "1720",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 exd4 5. Nxd4 Be7 6. e5 Ng8 7. Nf3 d6 8. exd6 Qxd6 9. Qxd6 Bxd6 10. Nd5 Be6 11. Bc4 O-O-O 12. O-O Na5 13. b3 Nxc4 14. bxc4 c6 15. Nf4 Bxc4 16. Rd1 Nf6 17. Nh3 Be2 18. Re1 Bxf3 19. gxf3 h6 20. a4 Rhe8 21. Rd1 Bxh2+ 22. Kxh2 Rxd1 23. Bb2 Rxa1 24. Bxa1 Re2 25. Kg2 Rxc2 26. Nf4 Ra2 27. Bxf6 gxf6 28. Nh5 Rxa4 29. Nxf6 c5 30. Nd5 c4 31. Nc3 Ra3 32. Nb1 Rb3 33. Nd2 Rc3 34. Kf1 b5 35. Ke1 Rc2 36. Kd1 Ra2 37. Kc1 h5 38. Ne4 h4 39. Ng5 Rxf2 40. Kd1 Rf1+ 41. Ke2 Ra1 42. Kf2 c3 43. Kg2 c2 44. Kh3 c1=Q 45. Kxh4 Qf4+ 46. Kh5 Rh1+ 47. Nh3 Rxh3# 0-1",
    B: "1739",
  },
  {
    W: "1365",
    T: "180+0",
    P: "1. d4 d5 2. c4 Nf6 3. Nc3 e6 4. e3 c5 5. cxd5 Nxd5 6. Nxd5 exd5 7. dxc5 Bxc5 8. Nf3 O-O 9. Be2 Bf5 10. O-O Nc6 11. Bd3 Bb6 12. Bxf5 Qd6 13. e4 dxe4 14. Qxd6 exf3 15. gxf3 Rad8 16. Qg3 Nd4 17. Bh3 Nc2 18. Rb1 Rd7 19. Bxd7 Rd8 20. Ba4 g5 21. Bxc2 Bxf2+ 22. Rxf2 Rd1+ 23. Bxd1 1-0",
    B: "1304",
  },
  {
    W: "1889",
    T: "180+0",
    P: "1. e4 c5 2. c3 Nc6 3. d4 cxd4 4. cxd4 d5 5. e5 Bf5 6. f4 e6 7. Bd3 Nge7 8. Bxf5 Nxf5 9. Nf3 Qb6 10. O-O Ncxd4 11. Nxd4 Qxd4+ 12. Kh1 Rc8 13. Nc3 Bb4 14. Qxd4 Nxd4 15. Be3 Nc2 16. Rae1 d4 17. Bxd4 Nxd4 0-1",
    B: "1888",
  },
  {
    W: "1976",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 d5 3. Nc3 c6 4. Bf4 Bf5 5. e3 e6 6. Nf3 Bb4 7. cxd5 exd5 8. Bd3 Ne4 9. Qc2 Qa5 10. Bxe4 Bxe4 11. Qd2 Bxf3 12. gxf3 Nd7 13. a3 Be7 14. b4 Bxb4 15. O-O Bxc3 0-1",
    B: "2035",
  },
  {
    W: "1595",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nge7 4. d4 exd4 5. Nxd4 a6 6. Bxc6 dxc6 7. O-O Ng6 8. Nc3 Bb4 9. Nde2 O-O 10. Be3 Qxd1 11. Raxd1 Be6 12. a3 Bxc3 13. Nxc3 Ne5 14. Bf4 f6 15. Bxe5 fxe5 16. f3 Rad8 17. Na4 b6 18. b3 Rd4 19. Nb2 Rd7 20. Nd3 Rfd8 21. Kf2 Kf7 22. Ke2 Kf6 23. g4 g5 24. Nf2 Rxd1 25. Rxd1 Rxd1 26. Nxd1 Ke7 27. Nf2 Kd6 28. Nd3 c5 29. Nb2 b5 30. Kf2 a5 31. Kg3 b4 32. axb4 cxb4 33. h4 h6 34. hxg5 hxg5 35. Nd1 c5 36. Ne3 Ke7 37. Nf5+ Bxf5 38. gxf5 Kf6 39. Kg4 1-0",
    B: "1530",
  },
  {
    W: "2213",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Nc3 Bg7 6. Be3 Nf6 7. f3 O-O 8. Qd2 d5 9. exd5 Nxd5 10. Nxd5 Qxd5 11. O-O-O Qxa2 12. c3 Rd8 13. Qc2 Nxd4 14. cxd4 Bf5 0-1",
    B: "2093",
  },
  {
    W: "2059",
    T: "180+0",
    P: "1. d4 e5 2. dxe5 Nc6 3. Nf3 Qe7 4. b3 Nxe5 5. Bb2 Nxf3+ 6. gxf3 Nf6 7. Bh3 d5 8. Bxc8 Rxc8 9. Nd2 Qd6 10. e4 dxe4 11. fxe4 Qf4 12. Qf3 Bd6 13. O-O-O Qxf3 14. Nxf3 Nxe4 15. Rhe1 f5 16. Nd2 Bf4 17. f3 O-O 18. fxe4 Rcd8 19. exf5 Rxf5 20. Bc3 Rfd5 21. Re2 b5 22. Kb2 c5 23. a3 a5 24. Rg2 Bh6 25. h4 b4 26. axb4 axb4 27. Bf6 Kf7 28. Bg5 Bxg5 29. hxg5 Kg6 30. c3 Rd3 31. cxb4 cxb4 32. Kc2 Rc3+ 33. Kb2 Rcd3 34. Re2 Kxg5 35. Rg1+ Kf5 36. Rf2+ Ke6 37. Re1+ Kd5 38. Rf5+ Kc6 39. Nc4 Rg3 40. Re6+ Kc7 41. Rf7+ Rd7 42. Ree7 Rxe7 43. Rxe7+ Kd8 44. Rf7 h5 45. Ne5 h4 46. Rf3 Rg2+ 47. Kc1 Re2 48. Nf7+ Ke7 49. Ng5 Rg2 50. Nh3 g5 51. Rf1 g4 52. Nf4 h3 53. Nxg2 hxg2 54. Rg1 Kf6 55. Rxg2 Kf5 56. Kd2 Kf4 57. Kd3 1-0",
    B: "2106",
  },
  {
    W: "1151",
    T: "180+0",
    P: "1. Nf3 e6 2. Nc3 Qf6 3. e4 Bc5 4. d3 Qh4 5. Be2 Qxf2+ 6. Kd2 Be3# 0-1",
    B: "1246",
  },
  {
    W: "1037",
    T: "180+0",
    P: "1. e4 e5 2. Qf3 Qf6 3. Be2 d5 4. exd5 Nh6 5. d3 Bg4 6. Bg5 Qxg5 7. Qxg4 Qc1+ 8. Bd1 Qxb2 9. Qc8+ Ke7 10. Qxc7+ Ke8 11. Qc8+ Ke7 12. Qc7+ Ke8 13. d6 Bxd6 14. Qxd6 Qxa1 15. Nf3 Qxb1 16. Nxe5 Qb5 17. O-O Na6 18. f4 Rd8 19. Qa3 Rd4 20. Be2 Qb6 21. Bh5 Ra4+ 22. Kh1 Rxa3 23. Bxf7+ Nxf7 24. Nxf7 Kxf7 25. d4 Rxa2 0-1",
    B: "1115",
  },
  {
    W: "1305",
    T: "300+0",
    P: "1. e4 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qd1 Nf6 5. Nc3 b6 6. Nf3 Bb7 7. Bc4 h6 8. O-O e6 9. h3 Bc5 10. a3 a5 11. Bd2 Qc7 12. Nb5 Qb8 13. Be3 Bxe3 14. fxe3 Ne5 15. Nxe5 Qxe5 16. Nd6+ Ke7 17. Nxb7 Qxb2 18. e5 Ne4 19. Nd6 g5 20. Nxe4 1-0",
    B: "1238",
  },
  {
    W: "1623",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. d4 exd4 5. Nxd4 Bd7 6. Nxc6 Bxc6 7. Nc3 Nf6 8. Bxc6+ bxc6 9. Bg5 Be7 10. Qf3 O-O 11. O-O h6 12. Bh4 Re8 13. Rfe1 Nh7 14. Bg3 Ng5 15. Qg4 Qc8 16. Qxc8 Raxc8 17. f3 Bf6 18. Rad1 Bxc3 19. bxc3 Rcd8 20. e5 d5 21. c4 d4 22. Bf2 c5 23. f4 Ne6 24. Bh4 Rd7 25. Bg3 g5 26. f5 Nf4 27. h4 d3 28. hxg5 hxg5 29. Bxf4 gxf4 30. cxd3 Kg7 31. Kf2 Kh6 32. Kf3 Kg5 33. f6 Kf5 34. g4+ fxg3 35. Kxg3 Rxe5 36. Rxe5+ Kxe5 37. Kg4 Kxf6 38. Rd2 Kg6 0-1",
    B: "1635",
  },
  {
    W: "1463",
    T: "300+0",
    P: "1. d4 d5 2. Nf3 Bf5 3. Bf4 e6 4. e3 Nf6 5. Bd3 Bxd3 6. Qxd3 Bd6 7. Bxd6 Qxd6 8. Nbd2 O-O 9. O-O Nbd7 10. e4 dxe4 11. Nxe4 Nxe4 12. Qxe4 c6 13. Ng5 f5 14. Qxe6+ Qxe6 15. Nxe6 Rf6 16. Rfe1 Re8 17. Nf4 Re4 18. Rxe4 fxe4 19. Re1 Rxf4 20. g3 Rg4 21. h3 Rg6 22. Rxe4 Rf6 23. Re7 Rd6 24. Kg2 Rxd4 25. Kf3 Kf8 26. Re2 Nf6 27. c3 Rd8 28. Re4 Nxe4 29. Kxe4 Rd2 30. f4 Rxb2 31. f5 Rxa2 32. g4 Ra3 33. Kf4 Rxc3 34. Kg5 Rxh3 35. f6 h6+ 36. Kg6 gxf6 37. Kxf6 Rf3+ 38. Kg6 c5 39. Kxh6 c4 40. g5 c3 41. g6 Rg3 42. g7+ Rxg7 43. Kh5 c2 44. Kh4 c1=Q 45. Kh5 Qh1# 0-1",
    B: "1459",
  },
  {
    W: "1422",
    T: "300+0",
    P: "1. e4 d5 2. Nc3 d4 3. Nb1 e5 4. d3 Be7 5. h3 Nf6 6. a3 O-O 7. Bd2 Qd7 8. Be2 b6 9. Qc1 Bb7 10. f3 Nc6 11. g4 Rab8 12. f4 Bc5 13. f5 Be7 14. Bf3 Nd8 15. h4 Ne8 16. g5 c5 17. b3 Qc7 18. Nh3 b5 19. Rg1 a5 20. h5 Ra8 21. h6 c4 22. bxc4 bxc4 23. hxg7 Nxg7 24. f6 cxd3 25. fxe7 Qxe7 26. cxd3 Nc6 27. Bg4 Qd6 28. Ke2 Ra7 29. Rf1 f6 30. Bf5 Nxf5 31. Rxf5 Bc8 32. gxf6 Bxf5 33. Bg5 Bg4+ 34. Kd2 Bxh3 35. Qg1 Kf7 36. Qh1 Nb4 37. Qxh3 Nxd3 38. Qh5+ Ke6 39. Qg4+ Kf7 40. Kxd3 Qa6+ 41. Kd2 Qc4 42. Qh5+ Ke6 43. Qg4+ Kf7 44. Qh5+ Ke6 45. Qg4+ Kf7 1/2-1/2",
    B: "1401",
  },
  {
    W: "1694",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 d5 3. exd5 Qxd5 4. Nc3 Qd6 5. Bc4 Bg4 6. Bxf7+ Kd8 7. O-O Nc6 8. h3 Qf6 9. Bd5 Nd4 10. hxg4 c6 11. d3 cxd5 12. Bg5 Nxf3+ 13. Qxf3 Qxg5 14. Qxf8+ Kc7 15. Qxa8 Qd8 16. Qxa7 Ne7 17. Nb5+ Kc6 18. Qa4 Kb6 19. Nc3 Kc7 20. Qa5+ b6 21. Nb5+ Kc6 22. Qa4 Kb7 23. Rfe1 Qd7 24. Qa7+ Kc6 25. Qxd7+ Kxd7 26. Rxe5 Nc6 27. Rxd5+ Ke6 28. c4 Nb4 29. Nc7+ Ke7 30. Re1+ Kf7 31. Rd7+ Kg6 32. Nd5 Nxd3 33. Ne7+ Kf7 34. Rxd3 Re8 35. Rdd1 Rxe7 36. Rxe7+ Kxe7 37. Rd5 Ke6 38. Kf1 g6 39. Ke2 h6 40. f4 h5 41. gxh5 gxh5 42. Rb5 h4 43. Rxb6+ Kd7 44. Rh6 Kc7 45. Rxh4 Kd7 46. g4 Ke6 47. g5 Kf5 48. c5 Ke6 49. g6 Kf6 50. c6 Kxg6 51. c7 Kf5 52. c8=Q+ Ke4 53. Qc4+ Kf5 54. Rh5+ Kg6 55. Qg8+ Kf6 56. f5 Ke5 57. f6+ Kxf6 58. Qg5+ Ke6 59. Rh6+ Kf7 60. Qb5 Kg7 61. Ra6 Kf7 62. Qb7+ Ke8 63. Ra8# 1-0",
    B: "1703",
  },
  {
    W: "1511",
    T: "300+0",
    P: "1. e4 b6 2. d4 Bb7 3. Nc3 g6 4. Nf3 Bg7 5. Bc4 e6 6. e5 d6 7. O-O dxe5 8. dxe5 Qxd1 9. Rxd1 Bxf3 10. gxf3 Bxe5 11. Re1 Bg7 12. f4 Nc6 13. Bb5 Ne7 14. Na4 O-O 15. Be3 a6 16. Bxc6 Nxc6 17. c3 f5 18. Bd4 Rad8 19. Bxg7 Kxg7 20. b3 Rfe8 21. c4 Nd4 22. Kg2 Nc2 23. Rad1 Nxe1+ 24. Rxe1 Rd2 25. Nc3 Rd3 26. Rc1 Kf6 27. Ne2 e5 28. Nc3 c6 29. fxe5+ Rxe5 30. Kf1 h5 31. Ne2 Rd2 32. Ng3 Rxa2 33. f4 Re3 34. b4 Rxh2 35. c5 b5 36. Re1 Rxg3 37. Re2 Rxe2 38. Kxe2 Rb3 39. Kf2 Rxb4 40. Ke2 Rxf4 41. Kd2 Rc4 42. Kd3 a5 43. Kd2 a4 44. Kd3 a3 45. Ke3 a2 46. Kd3 a1=Q 47. Ke3 Qa3+ 48. Kd2 Rb4 49. Kc2 Rb2+ 50. Kc1 Qa1# 0-1",
    B: "1522",
  },
  {
    W: "2045",
    T: "300+0",
    P: "1. e4 c5 2. Bc4 e6 3. Nf3 Nc6 4. O-O d6 5. Re1 Qc7 6. d4 cxd4 7. Nxd4 Nf6 8. a3 Be7 9. Ba2 O-O 10. c3 a6 11. Nd2 b5 12. h3 Bb7 13. f3 Ne5 14. Nf1 Rac8 15. Be3 Nc4 16. Bxc4 Qxc4 17. Bf2 d5 18. Ne3 Qc7 19. exd5 Nxd5 20. Nxd5 Bxd5 21. Qd3 Bc4 22. Qe4 Rfd8 23. Qg4 Bf6 24. Rad1 e5 25. Nf5 g6 26. Ng3 Be6 27. Qe4 Bg7 28. Nf1 f5 29. Qe3 a5 30. Qe2 Bc4 31. Qe3 Bb3 32. Rxd8+ Rxd8 33. Nd2 Bc2 34. Nf1 Rd3 35. Qc1 Bb3 36. Nd2 Bf7 37. Qc2 Qd7 38. Nf1 Bc4 39. b3 Bf7 40. Bb6 e4 41. fxe4 fxe4 42. Bxa5 1-0",
    B: "1881",
  },
  {
    W: "1312",
    T: "300+0",
    P: "1. e4 e6 2. Nf3 Nf6 3. e5 Nd5 4. c4 Nb4 5. a3 N4c6 6. d3 Bc5 7. b4 Nxb4 8. axb4 Bxb4+ 9. Bd2 Qe7 10. Bxb4 Qxb4+ 11. Qd2 Qc5 12. Be2 h6 13. O-O g5 14. d4 Qb6 15. c5 Qc6 16. Nc3 a6 17. Bd3 b5 18. Be4 d5 19. exd6 Qd7 20. dxc7 Qxc7 21. Bxa8 Bb7 22. Bxb7 Qxb7 23. Rfe1 Nc6 24. d5 Nb4 25. dxe6 f6 26. Nd5 Nxd5 27. Rxa6 g4 28. Rd6 gxf3 29. Qxd5 Qxd5 30. Rxd5 Rg8 31. c6 Rxg2+ 32. Kf1 Rxh2 33. Kg1 Rg2+ 34. Kh1 Rxf2 35. c7 Rc2 36. Rd8+ Ke7 37. c8=Q Rxc8 38. Rxc8 f5 39. Rc7+ Ke8 40. Rf1 b4 41. Rxf3 b3 42. Rxb3 f4 43. Rf3 Kd8 44. Rh7 h5 45. Rxf4 Ke8 46. e7 Kd7 47. Rd4+ Ke8 48. Rh8+ Kxe7 49. Rxh5 Ke6 50. Kg2 Kf6 51. Kg3 Kg6 52. Kg4 Kf6 53. Rd6+ Ke7 54. Ra6 Kf7 55. Kg5 Ke7 56. Ra7+ Kd6 57. Rh8 Kc5 58. Rh6 Kb5 59. Rb7+ Kc5 60. Rbb6 Kd5 61. Kf4 Kd4 62. Ra6 Kc5 63. Kf5 Kd4 64. Kf6 Kd5 65. Kf7 Kc5 66. Rh5+ Kb4 67. Rg6 Kc4 68. Rg4+ Kd3 69. Rh3+ 1-0",
    B: "1308",
  },
  {
    W: "1324",
    T: "300+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 e5 5. Bd2 exd4 6. Ne4 Qe5 7. Qe2 d3 8. Qxd3 Qxb2 9. Qe2 Qxa1+ 0-1",
    B: "1327",
  },
  {
    W: "2118",
    T: "300+0",
    P: "1. e4 d6 2. Nf3 Nf6 3. Nc3 g6 4. d4 Bg7 5. e5 dxe5 6. Nxe5 O-O 7. Nf3 c5 8. dxc5 Qa5 9. Bd3 Qxc5 10. O-O Bg4 11. Be3 Qh5 12. h3 Bxf3 13. Qxf3 Qxf3 14. gxf3 Nc6 15. Kg2 Nb4 16. Rad1 Nxd3 17. Rxd3 e6 18. Rfd1 Rac8 19. Bd4 a6 20. R3d2 Rc4 21. Be5 Rfc8 22. Rd8+ Ne8 23. Rxc8 Rxc8 24. Bxg7 Kxg7 25. Rd7 b5 26. Ra7 Rc6 27. Ne4 Rxc2 28. Rxa6 Rxb2 29. Rb6 Rxa2 30. Rxb5 Nf6 31. Nxf6 Kxf6 32. Kg3 Ra4 33. Rc5 h5 34. h4 e5 35. Rb5 Kf5 36. Rc5 f6 37. Rb5 Ra1 38. Kg2 Ra4 39. Kg3 g5 40. hxg5 Kxg5 41. Rc5 Rf4 42. Rc8 h4+ 43. Kg2 Kf5 44. Rh8 Kg5 45. Rg8+ Kh6 46. Rh8+ Kg7 47. Ra8 f5 48. Ra7+ Kf6 49. Ra6+ Ke7 50. Rb6 Kd7 51. Ra6 Kc7 52. Rh6 e4 53. fxe4 Rxe4 54. Kf3 Kd7 55. Rf6 Re5 56. Rh6 Re4 57. Rf6 Re5 58. Rh6 Re4 59. Rg6 Ke7 60. Ra6 Re6 61. Ra4 h3 62. Kg3 Rh6 63. Kh2 Kf6 64. Rb4 Kg5 65. Rc4 f4 66. Rc8 Kg4 67. Rc3 Re6 68. f3+ Kh4 69. Rc2 Re3 70. Rc8 Rxf3 71. Rh8+ Kg4 72. Rg8+ Kf5 73. Rf8+ Ke4 74. Re8+ Kd3 75. Re7 Re3 76. Rf7 f3 77. Kxh3 f2+ 78. Kg2 Re2 79. Kf1 Re1+ 80. Kxf2 Re2+ 81. Kf3 1-0",
    B: "2179",
  },
  {
    W: "1148",
    T: "300+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qe5+ 4. Be2 Bg4 5. f3 Bxf3 6. Nxf3 Qa5 7. O-O Nc6 8. d3 O-O-O 9. Bd2 e5 10. Ne4 Bc5+ 11. Kh1 f5 12. Bxa5 1-0",
    B: "1236",
  },
  {
    W: "1671",
    T: "300+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. b3 c6 4. Nf3 Bf5 5. Bc4 Qa5 6. O-O e6 7. d3 Nf6 8. Bd2 Nbd7 9. Bxa5 1-0",
    B: "1679",
  },
  {
    W: "1037",
    T: "300+0",
    P: "1. Nc3 e5 2. e3 d6 3. d4 exd4 4. exd4 Nc6 5. Qe2+ Be7 6. Bg5 f6 7. Bh4 Nh6 8. O-O-O O-O 9. Qc4+ Kh8 10. d5 Ne5 11. Qd4 Nf5 12. Qf4 g5 13. Qd2 gxh4 14. Bd3 Qd7 15. g3 hxg3 16. hxg3 Rf7 17. f4 Ng6 18. Qh2 Bd8 19. g4 Nfh4 20. Bxg6 Qxg4 21. Qxh4 Qxg6 22. Nf3 Bf5 23. Rh2 c6 24. Rdh1 Bc7 25. Nd4 Be4 26. Nxe4 Qxe4 27. Ne6 Re8 28. Nxc7 Qe3+ 29. Kb1 Ree7 30. Re1 1-0",
    B: "1070",
  },
  {
    W: "2091",
    T: "300+0",
    P: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 cxb2 5. Bxb2 d6 6. Qb3 Qd7 7. Nf3 Nc6 8. Bc3 Nf6 9. O-O Be7 10. e5 dxe5 11. Nxe5 1-0",
    B: "2062",
  },
  {
    W: "1985",
    T: "60+0",
    P: "1. Nf3 g6 2. g3 Bg7 3. Bg2 c6 4. O-O e6 5. d3 d5 6. Nbd2 Nf6 7. c3 O-O 8. Qc2 Nbd7 9. Re1 Qc7 10. e4 dxe4 11. dxe4 e5 12. a4 Re8 13. Nc4 b6 14. Bg5 Bb7 15. Rad1 h6 16. Bxf6 Bxf6 17. Nd6 Red8 18. Qb3 1-0",
    B: "2072",
  },
  {
    W: "1798",
    T: "60+0",
    P: "1. g4 b6 2. Bg2 Nc6 3. c4 Bb7 4. e3 e5 5. Nc3 Nf6 6. d4 exd4 7. exd4 d5 8. g5 Ne4 9. Nxe4 dxe4 10. d5 Ne5 11. Bxe4 Bb4+ 12. Bd2 Bxd2+ 13. Qxd2 O-O 14. f4 Nxc4 15. Qc3 b5 16. O-O-O Qe7 17. Bg2 Qe3+ 18. Qxe3 Nxe3 19. Rd2 Nxg2 20. Rxg2 Bxd5 21. Rd2 Bxh1 22. Ne2 Bf3 23. Nc3 Rae8 24. Nxb5 Re1+ 25. Kc2 Bc6 26. Nxc7 Rc8 27. Na6 Bb5+ 28. Kb3 Bxa6 29. Rd6 g6 30. Rxa6 Re3+ 31. Ka4 Rc4+ 32. b4 Ree4 33. Ka5 Rxb4 34. Rxa7 Ra4+ 35. Kb6 Rxa7 36. Kxa7 Rxf4 37. Kb7 Rg4 38. a4 Rxg5 39. a5 Rh5 40. a6 Ra5 41. a7 Rxa7+ 42. Kxa7 f5 43. Kb6 f4 44. Kc5 g5 45. Kd4 Kf7 46. Kd3 Kg6 47. Ke4 Kh5 48. Kf3 g4+ 49. Kg2 Kh4 50. h3 f3+ 51. Kh2 g3+ 52. Kg1 Kxh3 53. Kf1 g2+ 54. Kg1 Kg3 1/2-1/2",
    B: "1745",
  },
  {
    W: "2411",
    T: "60+0",
    P: "1. d4 Nf6 2. Bf4 c5 3. e3 cxd4 4. exd4 d5 5. b3 Nc6 6. c3 Bf5 7. Nf3 e6 8. Nbd2 Bd6 9. Bg3 Bxg3 10. hxg3 O-O 11. Be2 a5 12. O-O a4 13. b4 Rc8 14. a3 Ne7 15. c4 dxc4 16. Nxc4 Ned5 17. Nce5 Nc3 18. Qd2 Nfe4 19. Qe3 f6 20. Nd3 Nd5 21. Qc1 Rxc1 22. Raxc1 Nec3 23. Nf4 Nxf4 0-1",
    B: "2371",
  },
  {
    W: "1439",
    T: "600+0",
    P: "1. d4 e6 2. c4 c5 3. Nf3 Qa5+ 4. Bd2 Qb6 5. e3 Qxb2 6. Bc3 Qb6 7. dxc5 Bxc5 8. Bd3 Bb4 9. O-O Bxc3 10. Nxc3 Qxe3 11. fxe3 Nc6 12. Ng5 f6 13. Qh5+ g6 14. Bxg6+ Ke7 15. Nxh7 Ne5 16. Bd3 Nxd3 17. Nb5 a6 18. Nc7 Ra7 19. Qe8+ Kd6 20. Qxc8 b5 21. Qf8+ Kxc7 22. Rxf6 Nxf6 23. Qxh8 Nxh7 24. Qxh7 bxc4 25. a4 Rb7 26. Qe4 Rb4 27. h4 Kd6 28. h5 Ke7 29. h6 d5 30. Qh7+ Kd6 31. Qf7 Ne5 32. Qf8+ Kc7 33. Qxb4 1-0",
    B: "1440",
  },
  {
    W: "1621",
    T: "600+0",
    P: "1. Nf3 d5 2. d4 c5 3. dxc5 e6 4. e4 Bxc5 5. exd5 Ne7 6. Bb5+ Bd7 7. Bc4 Qb6 8. O-O exd5 9. Bxd5 Nxd5 10. Qxd5 O-O 11. Ng5 Bc6 12. Qf5 g6 13. Qh3 h5 14. Qg3 Na6 15. Nd2 Nb4 16. Qb3 Nd5 17. Nge4 Nf4 18. Re1 Qxb3 19. Nxb3 Bxe4 20. Nxc5 Bxg2 21. Bxf4 Bc6 1-0",
    B: "1661",
  },
  {
    W: "1247",
    T: "600+0",
    P: "1. e4 d5 2. Nf3 dxe4 3. Ne5 Be6 4. Bb5+ c6 5. Be2 Qd5 6. d4 f6 7. Ng4 Na6 8. O-O O-O-O 9. Bf4 Qxd4 10. Qxd4 Rxd4 11. Rd1 c5 12. Rxd4 cxd4 13. Na3 d3 14. cxd3 exd3 15. Bxd3 Bxg4 16. Rc1+ Kd8 17. f3 e5 18. Bg3 Be6 19. Rd1 Ke8 20. f4 Ne7 21. fxe5 fxe5 22. Nb5 Nf5 23. Nxa7 Bc5+ 24. Bf2 Bxa2 25. Bb5+ Kf7 26. Rd7+ Kg6 27. Nc8 Rxc8 0-1",
    B: "1255",
  },
  {
    W: "1422",
    T: "600+0",
    P: "1. b3 e5 2. Bb2 Nc6 3. e3 Bc5 4. Bb5 e4 5. Bxg7 a6 6. Bxc6 dxc6 7. Bxh8 Bf5 8. g4 Bg6 9. Bb2 Qd7 10. Nc3 O-O-O 11. Qe2 f6 12. O-O-O Nh6 13. h3 f5 14. f3 fxg4 15. fxe4 gxh3 16. Nxh3 Nf7 17. d4 Be7 18. Na4 Re8 19. d5 cxd5 20. Rxd5 Qc6 21. Nc3 Bf6 22. Qd2 Nd6 23. Rf1 Nxe4 24. Rxf6 Nxf6 25. Rd4 Qh1+ 26. Nd1 Qxh3 27. Rc4 Ne4 28. Qd3 Nd6 29. Qc3 Nxc4 30. Qxc4 Rd8 31. Bd4 Be8 32. Nf2 Qd7 33. Ne4 Qb5 34. Qc3 Qf1+ 35. Kb2 Qf5 36. a4 Qxe4 37. Ba7 Qc6 38. Qb4 b6 39. Bxb6 cxb6 40. Qg4+ Kb7 41. Qg7+ Rd7 42. Qg5 Qc7 43. Qg1 Qe5+ 44. Ka3 Bf7 45. Ka2 Rc7 46. Qa1 Qd6 47. Qb2 Qc6 48. Qa3 Qxc2+ 0-1",
    B: "1433",
  },
  {
    W: "1498",
    T: "600+0",
    P: "1. Nc3 e5 2. d4 exd4 3. Qxd4 Nf6 4. Bg5 Be7 5. e4 O-O 6. Bd3 Nc6 7. Qe3 Ne5 8. Nd5 Nxd3+ 9. cxd3 Nxd5 10. exd5 Bxg5 11. Qg3 Re8+ 12. Ne2 Qe7 13. O-O Qf6 14. Nc3 d6 15. Rae1 Bd7 16. Rxe8+ Rxe8 17. f4 Qd4+ 18. Kh1 Bf6 19. Re1 Rxe1+ 20. Qxe1 h6 21. Nd1 Qxd5 22. Qf2 Bd4 23. Qh4 Bc6 24. Qd8+ Kh7 0-1",
    B: "1470",
  },
  {
    W: "2097",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 d6 3. Nc3 Nf6 4. d4 cxd4 5. Nxd4 Nc6 6. Bc4 g6 7. f3 Bg7 8. Be3 O-O 9. Qd2 Re8 10. O-O-O a6 11. g4 b5 12. Bb3 Ne5 13. h4 Be6 14. Bxe6 fxe6 15. Nxe6 Qd7 16. Nxg7 Kxg7 17. h5 Nxf3 18. Qe2 Qxg4 19. Rdg1 Nxg1 20. Qe1 Nf3 21. Qd1 Ne5 22. Qd4 Nxh5 23. Nd5 Nf6 24. Nc7 Qxe4 25. Bh6+ Kf7 26. Qd1 Rec8 27. Nxa8 Qxa8 28. Rf1 Ke8 29. Bg5 Qe4 30. Bxf6 exf6 31. Rxf6 Qe3+ 32. Kb1 Qc5 33. Qf1 Qxc2+ 34. Ka1 Qc1+ 35. Qxc1 Rxc1# 0-1",
    B: "1959",
  },
  {
    W: "1402",
    T: "600+0",
    P: "1. e4 e5 2. f4 exf4 3. Nf3 d6 4. d4 a6 5. h3 Nf6 6. Bd3 d5 7. e5 Nfd7 8. Bxf4 c5 9. c3 cxd4 10. cxd4 Qa5+ 11. Nc3 Bb4 12. Qd2 O-O 13. O-O-O h6 14. Qc2 Bxc3 15. bxc3 Qa3+ 16. Kb1 Nc6 17. Qb3 Qxb3+ 18. axb3 b5 19. c4 bxc4 20. bxc4 Rb8+ 21. Kc2 Nb4+ 22. Kc3 Nxd3 23. Rxd3 dxc4 24. Kxc4 Nb6+ 25. Kc3 Na4+ 26. Kd2 Rb2+ 27. Ke3 Rxg2 28. Ra1 Nb6 29. Rb3 Nc4+ 30. Kd3 Nb2+ 31. Kc3 Rf2 32. Rxb2 Rxf3+ 33. Kc4 Rxf4 34. Rab1 Rd8 35. Rb8 Rdxd4+ 36. Kc5 Rd8 37. Kc6 Kh7 38. Kc7 Re8 39. h4 Rc4+ 40. Kd6 Rd8+ 41. Ke7 Rd7+ 42. Ke8 Re4 43. Rxc8 Rd5 44. Kxf7 Rexe5 45. Rb7 Rf5+ 46. Ke7 Rf6 47. Ke8 Re5+ 48. Kd7 Rf7+ 49. Kc6 Re6+ 50. Kc5 Rf5+ 51. Kb4 Re4+ 52. Rc4 Rb5+ 53. Rxb5 Rxc4+ 54. Kxc4 1-0",
    B: "1407",
  },
  {
    W: "1841",
    T: "600+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 d5 4. exd5 exd5 5. dxc5 Bxc5 6. Bb5+ Nc6 7. O-O h6 8. Re1+ Be6 9. Ne5 Qc7 10. Bf4 Qb6 11. Bxc6+ bxc6 12. c3 Bxf2+ 13. Kh1 Bxe1 14. Qxe1 Ne7 15. Qd2 Nf5 16. Na3 O-O 17. Nd7 Bxd7 18. g4 Nh4 19. h3 Nf3 20. Qe2 Ng5 21. Be3 Rfe8 22. Re1 Qc7 23. Qg2 Ne4 24. Nc2 Re7 25. Qf3 Rae8 26. Bf4 Qb6 27. b3 a5 28. Be3 Qc7 29. Bf4 Qb7 30. Kh2 a4 31. b4 c5 32. bxc5 Qb2 33. Re2 Bb5 34. Rg2 Qxc3 35. Qxc3 Nxc3 36. Nd4 Bc4 37. a3 Re4 38. c6 Rxf4 39. Nf5 Nb5 40. Nd6 Nxd6 41. c7 Re2 42. Rxe2 Bxe2 43. Kg3 Rf3+ 0-1",
    B: "1925",
  },
  {
    W: "950",
    T: "600+0",
    P: "1. e3 e5 2. Qh5 Nf6 3. Qxe5+ Be7 4. b3 d5 5. Bb2 Kf8 6. g3 Nc6 7. Qf4 Bd6 8. Qf3 Na5 9. Bg2 c5 10. Nc3 Be6 11. O-O-O Rc8 12. e4 Be5 13. exd5 Bxd5 14. Nxd5 Nxd5 15. Bxe5 Nb4 16. Qg4 Nxa2+ 17. Kb1 Nb4 18. Bxg7+ Ke7 19. Re1+ Kd6 20. Be5+ Ke7 21. Bxh8+ Kd6 22. Be5+ Ke7 23. Bc7+ Kf8 24. Bxd8 Rxd8 25. Nf3 c4 26. Ng5 Kg8 27. Ne6+ Kh8 28. Nxd8 cxb3 29. Re8# 1-0",
    B: "1064",
  },
  {
    W: "1817",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Ne5 5. f4 Ng6 6. Bc4 Bc5 7. Nf5 Qf6 8. Qd5 Bb6 9. Bd2 N8e7 10. Qd3 Nxf5 11. exf5 Qh4+ 12. g3 Qh3 13. fxg6 hxg6 14. Qd5 O-O 15. Nc3 Re8+ 16. Ne2 Re7 17. O-O-O c6 18. Qd6 Re8 19. Qxg6 d5 20. Qg5 dxc4 21. Bc3 Qh7 22. Nd4 Bxd4 23. Bxd4 Bf5 24. Bxg7 Bxc2 25. Bh6+ Kh8 26. Qf6+ Kg8 27. Rd7 Bb3 28. axb3 cxb3 29. Qg5+ Kh8 30. Qf6+ Kg8 31. Rd2 Re6 32. Qd8+ Rxd8 33. Rxd8+ 1-0",
    B: "1770",
  },
  {
    W: "1135",
    T: "600+0",
    P: "1. e3 e5 2. Qh5 Nc6 3. Bb5 d6 4. Nc3 Bd7 5. Bxc6 Bxc6 6. Nf3 Bxf3 7. Qxf3 Qg5 8. Qxb7 Qd8 9. Qc6+ Ke7 10. Nd5+ Ke6 11. Nxc7+ Kf5 12. Nxa8 Qg5 13. Qf3+ Ke6 14. Nc7+ Kd7 15. Qxf7+ Kc6 16. Ne6 Qxg2 17. Qc7+ Kb5 18. Qd7+ Kb6 19. Qxd6+ Kb7 20. Qc7+ Ka6 21. Rf1 Qd5 22. Nxf8 Nf6 23. Qxg7 Qg8 24. Qxf6+ Kb7 25. Nd7 Qd8 26. Qxd8 Rxd8 27. Nxe5 h5 28. Nc4 Rg8 29. Na5+ Kb6 30. Nc4+ Kc5 31. b3 Rg2 32. Ba3+ Kd5 33. f3 Rxh2 34. Rf2 Rh3 35. Ke2 Ke6 36. Rg1 Kf5 37. Rfg2 h4 38. Kf2 a6 39. Rg4 Kf6 40. Rg5 Ke6 41. Kg2 Rg3+ 42. Rxg3 hxg3 43. Kxg3 Kd5 44. Rh1 Kc6 45. Rh6+ Kb5 46. Nd6+ Ka5 47. Nb7+ Kb5 48. c4# 1-0",
    B: "1160",
  },
  {
    W: "1317",
    T: "600+0",
    P: "1. d4 Nc6 2. c4 d5 3. e3 Nf6 4. Nf3 Bg4 5. Qa4 e6 6. Ne5 Bb4+ 7. Bd2 Bxd2+ 8. Nxd2 Bf5 9. Nxc6 bxc6 10. Qxc6+ Qd7 11. Qxa8+ Ke7 12. Qxh8 1-0",
    B: "1334",
  },
  {
    W: "1525",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 Nd7 4. d4 cxd4 5. Nxd4 e6 6. Nc3 a6 7. a4 b6 8. Nc6 Qc7 9. Bd5 exd5 10. exd5 Ngf6 11. Qe2+ Ne5 12. f4 Nd7 13. fxe5 Nxe5 14. Bf4 f6 15. Bxe5 dxe5 16. O-O Bd6 17. Ne4 O-O 18. Nxd6 Qxd6 19. Rad1 Bb7 20. Qc4 Rac8 21. b4 Bxc6 22. dxc6+ Kh8 23. Rxd6 b5 24. axb5 axb5 25. Qxb5 Rb8 26. Qd5 h6 27. Rd1 Rxb4 28. c7 Ra4 29. Rd8 Ra8 30. Rxa8 Rxa8 31. Qd8+ Rxd8 32. cxd8=Q+ Kh7 1-0",
    B: "1691",
  },
  {
    W: "1486",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nf6 3. Bc4 Nc6 4. Ng5 d5 5. exd5 Nxd5 6. Qf3 Qxg5 7. Bxd5 Qf6 8. Bxc6+ bxc6 9. Qxf6 gxf6 10. O-O Bd6 11. Nc3 Be6 12. d3 Kd7 13. f4 exf4 14. Bxf4 Bc5+ 15. Kh1 Bb6 16. Na4 Bd4 17. c3 Be5 18. Bxe5 fxe5 19. Rfe1 f6 20. Rf1 Rhf8 21. Rf3 f5 22. Nc5+ Kd6 23. Nxe6 Kxe6 24. Raf1 f4 25. d4 e4 26. Rxf4 Rxf4 27. Rxf4 Kd5 28. Rf7 Re8 29. Kg1 e3 30. Kf1 Kc4 31. Ke2 Rg8 32. Rxh7 Rxg2+ 33. Kxe3 Rxb2 34. Rxc7 Kxc3 35. Rxc6+ Kb4 36. h4 Rxa2 37. h5 Rh2 38. Rc5 a5 39. Kf4 a4 40. Ke5 a3 41. Rc8 a2 42. Rb8+ Kc3 43. Rc8+ Kb2 44. Rb8+ Kc1 45. Rc8+ Kd2 46. Ra8 Rxh5+ 47. Kd6 Kc3 48. Rxa2 Kxd4 49. Rd2+ Ke3 50. Ra2 Kf4 51. Ra4+ Kg5 52. Ke6 Kh6 53. Kf6 Rg5 54. Ra8 Rg6+ 55. Kf5 Rg5+ 56. Kf6 Rg6+ 57. Kf7 Rg7+ 58. Kf6 Rg6+ 1/2-1/2",
    B: "1487",
  },
  {
    W: "1707",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. h3 e5 7. Nde2 Be7 8. Ng3 O-O 9. Bd3 Be6 10. O-O Nbd7 11. f4 b5 12. f5 Bc4 13. Bxc4 bxc4 14. Nh5 Nxh5 15. Qxh5 Nf6 16. Qe2 Qb6+ 17. Kh1 Rac8 18. Rb1 g6 19. Nd5 Nxd5 20. exd5 Qc5 21. f6 Bd8 22. Qf3 Qd4 23. Be3 Qh4 24. Rbd1 e4 25. Qe2 Bxf6 26. c3 Be5 27. Bd4 Bxd4 28. Rxd4 Rfe8 29. Rxc4 Rxc4 30. Qf2 Qxf2 0-1",
    B: "1992",
  },
  {
    W: "2115",
    T: "120+1",
    P: "1. d4 Nc6 2. Nf3 d6 3. c4 Nf6 4. Nc3 g6 5. Bg5 Bg7 6. e4 O-O 7. Bd3 Bg4 8. Be2 h6 9. Bh4 Re8 10. O-O Bxf3 11. Bxf3 e5 12. d5 Ne7 13. Qd2 g5 14. Bg3 Ng6 15. a3 Nf4 16. b4 Qe7 17. Bd1 Rad8 18. h4 N6h5 19. hxg5 hxg5 20. Bh2 Bh6 21. Bg4 Nf6 22. Bf5 g4 23. Qd1 Kh8 24. Bxg4 Rg8 25. Bf3 Rg6 26. Ne2 Ng4 27. Ng3 Nxh2 28. Kxh2 Qh4+ 29. Kg1 Rdg8 30. Re1 1-0",
    B: "2128",
  },
  {
    W: "2376",
    T: "60+0",
    P: "1. b3 d6 2. Bb2 e5 3. c4 f5 4. Nc3 Nf6 5. d4 e4 6. d5 g6 7. Nh3 Bg7 8. Nf4 O-O 9. Nb5 a6 10. Nd4 Qe7 11. Nfe6 Nbd7 12. Nxf8 Qxf8 13. Ne6 Qe7 14. Nxg7 Kxg7 15. e3 Kf7 16. Be2 Ne5 17. Qc2 b6 18. f4 a5 19. fxe5 1-0",
    B: "2337",
  },
  {
    W: "1939",
    T: "60+0",
    P: "1. d4 e5 2. Nf3 e4 3. Ng1 d5 4. e3 Nf6 5. Be2 Bd6 6. f3 exf3 7. Nxf3 O-O 8. O-O c6 9. Nbd2 Bf5 10. c4 dxc4 11. Nxc4 Bc7 12. Nce5 Nbd7 13. Bd3 Nxe5 14. dxe5 Bxe5 15. Bxf5 Qb6 16. Nxe5 Nd5 17. Qf3 f6 18. Nd7 1-0",
    B: "1897",
  },
  {
    W: "1872",
    T: "60+0",
    P: "1. e4 c5 2. g3 Nc6 3. Bg2 g6 4. Nc3 d6 5. Nf3 Bg7 6. O-O Nf6 7. d4 cxd4 8. Nxd4 a6 9. Nf3 Bg4 10. h3 Bd7 11. Re1 Qc8 12. e5 dxe5 13. Nxe5 Nxe5 14. Rxe5 Bxh3 15. Qe2 O-O 16. Rxe7 Bxg2 17. Kxg2 Qc6+ 18. Kg1 Rfe8 19. Rxe8+ Rxe8 20. Qf1 Ng4 21. Bf4 Qc5 22. Na4 Qh5 23. Qg2 Re2 24. Nc3 Rxf2 25. Qxb7 Qh2# 0-1",
    B: "1941",
  },
  {
    W: "1365",
    T: "60+0",
    P: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 c2 5. Qxc2 Bb4+ 6. Nd2 Nc6 7. a3 Ba5 8. b4 Bb6 9. Bb2 Nf6 10. e5 Qe7 11. Ne2 Nxe5 12. O-O Nxc4 13. Qxc4 d5 14. Bxf6 Qxf6 15. Qxd5 c6 16. Qe4+ Be6 17. Nf4 O-O 18. Nxe6 fxe6 19. Nf3 Rad8 20. Rfd1 Rxd1+ 21. Rxd1 h6 22. h3 Qb2 23. a4 Qxf2+ 24. Kh1 Rf6 25. a5 Bc7 26. Rd2 Qf1+ 27. Ng1 1-0",
    B: "1473",
  },
  {
    W: "1314",
    T: "60+0",
    P: "1. d4 e5 2. dxe5 Nc6 3. Nf3 Bb4+ 4. c3 Bc5 5. Qd5 Nge7 6. Qxc5 d6 7. exd6 cxd6 8. Qb5 b6 9. Nd4 Qc7 10. Nxc6 Nxc6 11. e3 Bd7 12. Qc4 Ne5 13. Qxc7 Be6 14. Qxd6 Bc4 15. Qxe5+ Kf8 16. Bxc4 Re8 17. Qd6+ Re7 18. Bb5 f5 19. Qd8+ Kf7 20. Qxh8 Rxe3+ 21. fxe3 g5 22. Bc4+ Kg6 23. Qg8+ Kf6 0-1",
    B: "1327",
  },
  {
    W: "1291",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Nc3 e5 4. Bc4 d5 5. exd5 Nd4 6. Nxd4 cxd4 7. Ne4 Nf6 8. d3 Nxe4 9. dxe4 Bc5 10. Qf3 O-O 11. O-O a6 12. Qg3 b5 13. Bb3 a5 14. a3 a4 15. Ba2 Be7 16. Bh6 Bg5 17. Bxg5 f6 18. Bh6 g5 19. f4 exf4 20. Rxf4 Rf7 21. Bxg5 fxg5 22. Rxf7 Kxf7 23. Rf1+ Ke7 24. Qxg5+ Kd7 25. Qxd8+ Kxd8 26. Rf8+ Kc7 27. Rf7+ Kb8 28. Rxh7 Bb7 29. Rh8+ Ka7 30. Rxa8+ Bxa8 31. Bb1 b4 32. c3 bxc3 33. bxc3 dxc3 34. Bd3 Bxd5 35. Bc2 1-0",
    B: "1291",
  },
  {
    W: "1636",
    T: "60+0",
    P: "1. e4 c5 2. f4 e6 3. Nf3 d5 4. e5 d4 5. d3 Nc6 6. Be2 Nge7 7. O-O Nf5 8. Qe1 Be7 9. c3 O-O 10. Na3 a6 11. cxd4 Ncxd4 12. Nxd4 Nxd4 13. Nc2 1-0",
    B: "1665",
  },
  {
    W: "1270",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Bc5 5. Bg5 h6 6. Bh4 O-O 7. Nc3 d6 8. Nd5 g5 9. Nxg5 Nxd5 10. Nxf7 Qxh4 11. Bxd5 Qxf2# 0-1",
    B: "1225",
  },
  {
    W: "1585",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 e6 3. Bc4 Nc6 4. O-O Be7 5. Re1 h6 6. a3 Nf6 7. Ba2 O-O 8. c3 b6 9. d4 cxd4 10. cxd4 Bb7 11. e5 Nh7 12. Nbd2 Rc8 13. b4 a6 14. Bb2 d5 15. exd6 Bxd6 16. Ne4 Be7 17. Ng3 Nb8 18. Rc1 Rxc1 19. Qxc1 Bxf3 20. gxf3 Bh4 21. Bb1 Bg5 22. Qc2 f5 23. Rxe6 Qd7 24. Re1 Qf7 25. Bc3 g6 26. Ba2 Qxa2 27. Qxa2+ Kg7 28. d5+ Kg8 29. d6+ 1-0",
    B: "1630",
  },
  {
    W: "2025",
    T: "60+0",
    P: "1. b3 d5 2. Bb2 c5 3. e3 Nc6 4. Nf3 Bg4 5. d4 cxd4 6. exd4 Nf6 7. Nbd2 e6 8. Bd3 Bd6 9. a3 a5 10. c4 O-O 11. O-O h6 12. h3 Bh5 13. Re1 b6 14. Qc2 Rc8 15. Rac1 dxc4 16. bxc4 Ne7 17. Ne5 Nf5 18. Bxf5 exf5 19. Qxf5 g6 20. Qc2 g5 21. g4 Nxg4 22. hxg4 Bxg4 23. Nxg4 1-0",
    B: "2212",
  },
  {
    W: "1701",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 0-1",
    B: "1760",
  },
  {
    W: "1791",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 Nf6 5. Bd2 Bg4 6. Ne4 Qh5 7. Nxf6+ gxf6 8. Be2 Bxe2 9. Nxe2 Nc6 10. Ng3 Qb5 11. Bc3 O-O-O 12. Qf3 e5 13. d5 Nb4 14. O-O-O Nxa2+ 15. Kb1 Nxc3+ 16. Qxc3 Bb4 17. Qb3 c5 18. c3 Qa5 19. cxb4 cxb4 20. Rc1+ Kb8 21. Nf5 Rxd5 22. Ne7 Rd2 23. Qc4 a6 24. Rhe1 e4 25. Re3 f5 26. Qxf7 Ka7 27. Nc6+ Kb6 28. Nxa5 Kxa5 29. Ra3+ 1-0",
    B: "1951",
  },
  {
    W: "1859",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6 7. g3 Nbd7 8. Bg2 Qc7 9. O-O Rb8 10. Nde2 b5 11. a3 Bb7 12. Bxf6 Nxf6 13. f4 Be7 14. b4 O-O 15. f5 Rfd8 16. fxe6 fxe6 17. Bh3 e5 18. Be6+ Kh8 19. Nd5 Nxd5 20. exd5 Qb6+ 21. Kh1 Qe3 22. Rf7 Qe4+ 23. Kg1 Bg5 24. h4 Be3+ 25. Kf1 Rf8 26. Qd3 Rxf7+ 27. Bxf7 Qf3+ 28. Ke1 Rf8 29. Nc3 Rxf7 30. Rd1 Qf2# 0-1",
    B: "1883",
  },
  {
    W: "2099",
    T: "60+0",
    P: "1. e4 c6 2. Bc4 d5 3. exd5 cxd5 4. Bb3 Nf6 5. c4 dxc4 6. Bxc4 e6 7. Qb3 Bd6 8. Nf3 O-O 9. O-O a6 10. Re1 b5 11. Be2 Bb7 12. d4 Nbd7 13. Be3 Rc8 14. Nc3 Nd5 15. Rad1 Nxc3 16. bxc3 Nf6 17. Rc1 Nd5 18. c4 Nxe3 19. fxe3 bxc4 20. Bxc4 Bxf3 21. gxf3 Qg5+ 22. Kf2 Qh4+ 23. Ke2 Qxh2+ 24. Kd3 Qg3 25. Rf1 f5 26. Bxe6+ Kh8 27. Bxc8 Rxc8 28. Rxc8+ 1-0",
    B: "2020",
  },
  {
    W: "1976",
    T: "120+1",
    P: "1. e4 c5 2. Nf3 d6 3. Bc4 Nf6 4. Nc3 e6 5. d3 Be7 6. Bg5 Nbd7 7. Qd2 a6 8. a4 O-O 9. O-O Ne5 10. Nxe5 dxe5 11. f4 exf4 12. Bxf4 Bd7 13. Kh1 Bc6 14. e5 Nd5 15. Nxd5 exd5 16. Bb3 b5 17. axb5 axb5 18. c3 Qd7 19. d4 c4 20. Bc2 g6 21. Bh6 Rfb8 22. Qf2 Bf8 23. Bxf8 Rxf8 24. Qf6 Qe6 25. Qf2 Rxa1 26. Rxa1 Ra8 27. Rxa8+ Bxa8 28. h3 Qe7 29. Qe1 Kg7 30. Qa1 Bc6 31. Kg1 Bd7 0-1",
    B: "1779",
  },
  {
    W: "1721",
    T: "120+1",
    P: "1. d4 c5 2. c3 Nf6 3. Bf4 e6 4. e3 g6 5. Nf3 b6 6. Bd3 Bb7 7. Nbd2 Bg7 8. O-O O-O 9. Qb3 d5 10. dxc5 Nbd7 11. Bd6 Rc8 12. Bxf8 Bxf8 13. cxb6 Qxb6 14. Qxb6 Nxb6 15. Rfc1 Ne4 16. Nb3 f5 17. Nfd4 Re8 18. Bb5 Re7 19. f3 Nd6 20. Be2 Ndc4 21. Bxc4 Nxc4 22. Nd2 Nxd2 23. Rc2 Nc4 24. b3 Nxe3 25. Re2 f4 26. g3 Bh6 27. Rae1 Kf7 28. g4 g5 29. h3 Bg7 30. Rxe3 fxe3 31. Rxe3 Bxd4 32. cxd4 Kf6 33. Kf2 Rc7 34. Re2 Ba6 35. Rd2 h6 36. a3 Rc3 37. Rb2 Bd3 38. a4 Bg6 39. b4 Rc2+ 40. Rxc2 Bxc2 41. a5 Ba4 42. Ke3 Ke7 43. Kd2 Kd6 44. Kc3 Kc6 45. Kb2 Kb5 46. Ka3 Bd1 0-1",
    B: "1685",
  },
  {
    W: "2167",
    T: "120+1",
    P: "1. Nf3 Nf6 2. d4 g6 3. c4 Bg7 4. Nc3 O-O 5. e4 d6 6. Be2 c5 7. d5 Bg4 8. O-O Nbd7 9. Bf4 Nh5 10. Be3 a6 11. h3 Bxf3 12. Bxf3 Ne5 13. Bxh5 gxh5 14. Qxh5 Nxc4 15. Bh6 Bxh6 16. Qxh6 Nxb2 17. f4 Kh8 18. Rab1 Nd3 19. Rxb7 Rb8 20. Rfb1 Rxb7 21. Rxb7 e6 22. dxe6 fxe6 23. Qxh7# 1-0",
    B: "2100",
  },
  {
    W: "1513",
    T: "120+1",
    P: "1. e4 c6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Bg4 6. Be2 e6 7. h3 Bh5 8. g4 Bg6 9. Bg5 Be7 10. Bxe7 Ngxe7 11. Nbd2 O-O 12. Nb3 cxd4 13. cxd4 Qb6 14. a4 a5 15. Ra2 Nb4 16. Ra3 Rfc8 17. O-O Nc2 18. Ra2 Nb4 19. Ra1 Nec6 20. Rc1 Nxd4 21. Nbxd4 Rxc1 22. Qxc1 h6 23. g5 hxg5 24. Qxg5 Rc8 25. Nb5 Rc2 26. Bd1 Rxb2 27. Nd6 Rb1 28. Qh4 Nd3 29. Ng5 Rxd1 30. Rxd1 Nxf2 31. Qxf2 Qxf2+ 32. Kxf2 Bc2 33. Rc1 Bxa4 34. Nxb7 Kf8 35. Nd8 Bd7 36. Nb7 Ke7 37. Nxa5 Be8 38. Rc7+ Bd7 39. Nc6+ Ke8 40. Ke3 Bxc6 41. Rxc6 Kd7 42. Rc1 Ke7 43. Rd1 Kd7 44. Kd4 Ke7 45. Rc1 f6 46. exf6+ gxf6 47. Nh7 Kf7 48. h4 Kg6 49. Nf8+ Kf5 50. Rg1 e5+ 51. Kxd5 e4 52. h5 e3 53. h6 e2 54. Re1 Kg5 55. h7 Kh6 56. Rxe2 Kg7 57. Rh2 Kh8 58. Ng6+ Kg7 59. h8=Q+ Kxg6 60. Qg8+ Kf5 61. Rf2# 1-0",
    B: "1372",
  },
  {
    W: "1649",
    T: "120+1",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bc4 Nf6 4. O-O e5 5. Re1 d6 6. c3 Be7 7. Ng5 O-O 8. d3 Nd7 9. Nxf7 Rxf7 10. Bxf7+ Kxf7 11. Re3 Kg8 12. Rh3 Bg5 13. Qh5 h6 14. Nd2 Nf6 15. Qg6 Bd7 16. Nf3 Be8 17. Qf5 Ne7 18. Qe6+ Bf7 19. Nxg5 Bxe6 20. Nxe6 Qd7 21. Nxg7 Kxg7 22. Bxh6+ Kf7 23. Bg5 Rg8 24. Bh4 Rh8 25. Re1 Ng6 26. g3 Nxh4 27. gxh4 Qxh3 0-1",
    B: "1678",
  },
  {
    W: "1738",
    T: "120+1",
    P: "1. c4 e5 2. Nc3 Nc6 3. d3 d6 4. Nf3 Nf6 5. e3 Bg4 6. Be2 Be7 7. O-O O-O 8. h3 Bd7 9. a3 a6 10. b4 Na7 11. a4 c6 12. Qb3 b5 13. a5 Be6 14. Ng5 Qd7 15. Nxe6 Qxe6 16. Rb1 Rac8 17. Bf3 Rfd8 18. Re1 d5 19. c5 e4 20. dxe4 dxe4 21. Qxe6 fxe6 22. Nxe4 Nxe4 23. Bxe4 Bf6 24. Bb2 Rd2 25. Bxf6 gxf6 26. Rbd1 Rcd8 27. Rxd2 Rxd2 28. Rb1 f5 29. Bf3 Kf7 30. g4 Ke7 31. gxf5 exf5 32. Kg2 Kd7 33. Kg3 Nc8 34. Kf4 Ne7 35. e4 fxe4 36. Bxe4 Nd5+ 37. Bxd5 cxd5 38. Ke3 Ra2 39. Rd1 Kc6 40. Rd4 Ra3+ 41. Kf4 Ra2 42. f3 Rh2 43. Kg3 Rb2 44. f4 h5 45. Kh4 1-0",
    B: "1777",
  },
  {
    W: "1564",
    T: "120+1",
    P: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Bg5 Bg7 5. Nf3 O-O 6. Be2 h6 7. Bh4 g5 8. Bg3 Nh5 9. Nd2 Nxg3 10. hxg3 Re8 11. Nf3 Nc6 12. Qd2 e5 13. d5 Nd4 14. Nxd4 exd4 15. O-O-O dxc3 16. bxc3 c6 17. f4 Qa5 18. fxg5 Qxa2 19. Qd3 Qa3+ 20. Kd2 hxg5 21. Rh2 Be5 22. Rdh1 b5 23. Rh8+ Bxh8 24. dxc6 b4 25. cxb4 Qxb4+ 26. c3 Qb2+ 27. Ke3 Qb6+ 28. Kd2 Qxc6 29. Qf3 Rxe4 30. Qd3 Be6 31. Bf3 Bxc3+ 32. Qxc3 Qxc3+ 33. Kxc3 Rc8+ 34. Kd3 Ra4 35. Rb1 Ra3+ 36. Ke2 Rc2+ 37. Kf1 Bc4+ 38. Kg1 Rac3 39. Rb8+ Kg7 40. Rb7 Rc1+ 41. Kh2 Re3 42. Kh3 Re6 43. Kg4 Rh6 44. Rxa7 Kg6 45. Ra4 f5# 0-1",
    B: "1539",
  },
  {
    W: "1983",
    T: "120+1",
    P: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3 c4 7. Qg4 Ne7 8. Qxg7 Rg8 9. Qxh7 Nbc6 10. h4 Bd7 11. h5 Qa5 12. Bd2 O-O-O 13. Qxf7 Rdf8 14. Qh7 Rf5 15. g4 Rff8 16. g5 Nf5 17. g6 Rg7 18. Qxg7 Nxg7 19. h6 Qd8 20. hxg7 Rg8 21. Nf3 Rxg7 22. Bg5 Qg8 23. Bf6 Kc7 24. Bxg7 Qxg7 25. Rg1 Ne7 26. O-O-O Nxg6 27. Nh4 Qh6+ 28. f4 Nxf4 29. Nf3 Ne2+ 30. Kb2 Nxg1 31. Bxc4 Nxf3 0-1",
    B: "1976",
  },
  {
    W: "1199",
    T: "120+1",
    P: "1. d4 e6 2. Bf4 c5 3. dxc5 Bxc5 4. Nc3 Bd4 5. Qxd4 Nf6 6. Bg5 O-O 7. Bxf6 gxf6 8. O-O-O Nc6 9. Qh4 Ne5 10. Nf3 Ng6 11. Qg3 b6 12. e3 Bb7 13. Bd3 f5 14. Ne5 Qf6 15. Nxd7 Qe7 16. Nxf8 Qxf8 17. h4 Rd8 18. h5 Qg7 19. hxg6 Qxg6 20. Qxg6+ fxg6 21. Nb5 Bxg2 22. Rhg1 Bf3 23. Rde1 a6 24. Nc7 Rc8 25. Nxe6 b5 26. Rg3 Bd5 27. Kb1 Bxe6 28. f3 a5 29. e4 fxe4 30. Rxe4 Bf5 31. Re3 Bxd3 32. Rxd3 Re8 33. a3 Re1+ 34. Ka2 a4 35. f4 Re2 36. c3 Kg7 37. f5 Kf6 38. fxg6 hxg6 39. Rd6+ Kf5 40. Rgxg6 Kf4 41. Rgf6+ Ke5 42. Rfe6+ 1-0",
    B: "1149",
  },
  {
    W: "1877",
    T: "300+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nd4 4. c3 Nxf3+ 5. Qxf3 Nf6 6. d3 Be7 7. Bg5 O-O 8. Nd2 c6 9. h3 d5 10. Bb3 Be6 11. Qg3 Kh8 12. Qxe5 dxe4 13. Nxe4 Nxe4 14. Bxe7 Qxe7 15. Qxe4 Qf6 16. O-O Bxb3 17. axb3 Rfe8 18. Qg4 g6 19. Rfe1 a6 20. Qd7 Rxe1+ 21. Rxe1 b6 22. d4 Rd8 23. Re8+ Rxe8 24. Qxe8+ Kg7 25. Qe5 Qxe5 26. dxe5 f6 27. exf6+ Kxf6 28. g4 Ke5 29. Kf1 Ke4 30. Ke2 g5 31. b4 h6 32. b3 c5 33. bxc5 bxc5 34. b4 c4 35. f3+ Kf4 36. Kf2 Ke5 37. Ke3 Kd5 38. f4 gxf4+ 39. Kxf4 1-0",
    B: "1687",
  },
  {
    W: "1906",
    T: "180+0",
    P: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. cxd5 Nxd5 5. Nxd5 exd5 6. Nf3 Bd6 7. Bg5 f6 8. Bh4 O-O 9. e3 Bg4 10. Bd3 Nd7 11. h3 Be6 12. O-O c5 13. dxc5 Nxc5 14. Bc2 Rc8 15. Nd4 Qd7 16. Nxe6 Qxe6 17. Rc1 Ne4 18. Bxe4 Qxe4 19. Qg4 Qxg4 20. hxg4 g5 21. Bg3 Bxg3 22. fxg3 Rxc1 23. Rxc1 Rf7 24. Rc8+ Kg7 25. Rd8 Rc7 26. Rxd5 Kg6 27. Rd2 h5 28. gxh5+ Kxh5 29. Kf2 f5 30. Kf3 a6 31. b3 b5 32. Rd6 a5 33. Rd5 Rc2 34. Rxb5 Rxa2 35. Rxf5 a4 36. bxa4 Rxa4 37. g4+ Kg6 38. Re5 Ra3 39. g3 Rb3 40. Re6+ Kf7 41. Re5 Kg6 42. Rf5 Rc3 43. Rd5 Rb3 44. Ke4 1-0",
    B: "2024",
  },
  {
    W: "2085",
    T: "180+0",
    P: "1. d4 d5 2. Nf3 Nf6 3. Bf4 Nc6 4. e3 Bg4 5. Be2 Nd7 6. O-O f6 7. h3 Bxf3 8. Bxf3 e5 9. Bg3 e4 10. Bg4 Be7 11. Nd2 O-O 12. a3 f5 13. Be2 f4 14. Bxf4 Rxf4 15. exf4 Nxd4 16. c3 Nxe2+ 17. Qxe2 Bd6 18. g3 c6 19. Rfe1 Nc5 20. c4 Nd3 21. Reb1 Qd7 22. Kh2 Rf8 23. cxd5 cxd5 24. b4 Rf6 25. Rb3 Rh6 26. Qf1 Nxf4 27. Rc1 Nxh3 28. Rbc3 Nxf2+ 29. Kg2 Qh3+ 30. Kxf2 Rf6+ 31. Nf3 Qxg3+ 32. Ke2 exf3+ 33. Rxf3 Re6+ 34. Kd2 Qg5+ 35. Kc2 Re2+ 36. Qxe2 h6 37. Qe8+ Kh7 38. Qf7 Qg2+ 39. Rf2 Qe4+ 40. Kb3 Qd3+ 41. Rc3 Qb1+ 42. Rb2 Qd1+ 43. Ka2 Qg4 44. Rf3 Qc4+ 45. Kb1 Qe4+ 46. Rc2 Qe1+ 47. Kb2 Be5+ 48. Kb3 Qb1+ 49. Ka4 Qxc2+ 50. Kb5 a6+ 51. Kb6 Qc6+ 52. Ka7 Bd4+ 53. Kb8 Qd6+ 0-1",
    B: "2063",
  },
  {
    W: "1389",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d3 g6 4. Nc3 Bg7 5. Bd2 d6 6. Be2 Nf6 7. h3 O-O 8. a3 a6 9. Be3 Qb6 10. d4 Qxb2 11. Na4 Qxa1 12. Qxa1 cxd4 13. Nxd4 Nxe4 14. Qd1 Bd7 15. Nb6 Nc3 16. Qd2 Nxe2 17. Kxe2 Rad8 18. Nxd7 Rxd7 19. Nxc6 bxc6 20. Bd4 e5 21. Bb2 d5 22. Kd1 Re8 23. Re1 c5 24. f4 e4 25. Bxg7 Kxg7 26. Qa5 d4 27. Qxc5 d3 28. Qc3+ Kg8 29. cxd3 Rxd3+ 30. Kc2 Rxc3+ 31. Kxc3 f5 32. Re3 Kf7 33. Kd4 Ke6 34. Kc5 Rc8+ 35. Kb6 Ra8 36. Ka5 Kd5 37. a4 Kd4 38. Re1 Kc4 39. Rb1 e3 40. Rb4+ 1-0",
    B: "1381",
  },
  {
    W: "2274",
    T: "180+0",
    P: "1. e4 g6 2. d4 Bg7 3. Nc3 d6 4. Be3 a6 5. a4 b6 6. f3 Nd7 7. Nge2 Ngf6 8. Qd2 c5 9. Bh6 Bxh6 10. Qxh6 cxd4 11. Nxd4 Bb7 12. Bc4 Qc7 13. Bb3 d5 14. exd5 Qe5+ 15. Nde2 Nxd5 16. Nxd5 Bxd5 17. Bxd5 Qxd5 18. Qe3 O-O 19. c3 e5 20. O-O Qe6 21. Rfd1 Rfd8 22. a5 b5 23. f4 Rac8 24. fxe5 Nxe5 25. Nd4 Qf6 26. Rf1 Qg7 27. Qe2 Nc4 28. Kh1 h5 29. h3 Re8 30. Qf2 b4 31. cxb4 Ne3 32. Rfc1 Qxd4 33. Rxc8 Rxc8 34. Re1 Re8 35. b5 axb5 36. a6 h4 37. b4 Re6 38. Qa2 Nf5 39. Rxe6 Ng3+ 40. Kh2 Nf1+ 41. Kh1 Ng3+ 42. Kh2 fxe6 43. Qb1 Kg7 44. Qe1 Qa7 45. Qc3+ Kh7 46. Qf3 Qxa6 47. Qg4 Kg7 48. Qxh4 Qd6 49. Qe7+ Kh6 50. Qxd6 Nf5 51. Qf4+ Kg7 52. Qxf5 1-0",
    B: "2297",
  },
  {
    W: "1427",
    T: "180+0",
    P: "1. d4 e6 2. d5 b6 3. dxe6 fxe6 4. e3 Bb7 5. f3 h6 6. e4 Qf6 7. Bd3 Nc6 8. Nh3 O-O-O 9. O-O g5 10. Qe2 Nd4 11. Qd2 Bc5 12. c3 Nxf3+ 13. Kh1 Nxd2 14. Rxf6 Nxb1 15. Rxb1 Nxf6 16. e5 Nd5 17. c4 Nb4 18. Bf1 Nxa2 19. b3 Kb8 20. Bb2 Nb4 21. Nf2 Rhf8 22. Ne4 Be7 23. Bc3 Nc2 24. Rc1 Bxe4 25. Bd4 Bb7 26. Bc3 Ne3 27. Kg1 Bc5 28. b4 Be7 29. Bd4 Nxf1 30. Rxf1 Bxb4 31. Rxf8 Rxf8 32. h3 h5 33. Kh2 g4 34. h4 Rf2 35. Bxf2 d6 36. exd6 Bxd6+ 37. g3 e5 38. Kg1 e4 39. Be3 a5 40. Kf2 a4 41. Bd2 a3 42. Bc3 a2 43. Ke3 Bc5+ 44. Kd2 e3+ 45. Ke2 Ba6 46. Ba1 Bxc4+ 47. Kd1 e2+ 48. Ke1 Bb4+ 49. Kf2 e1=Q+ 50. Kg2 Qe2+ 51. Kg1 Qe1+ 52. Kg2 Qxa1 53. Kf2 Qb2+ 54. Ke3 Qc3+ 55. Kf4 a1=Q 56. Kg5 Qac1+ 57. Kxh5 1-0",
    B: "1429",
  },
  {
    W: "1281",
    T: "180+0",
    P: "1. e4 g6 2. Qf3 Bg7 3. Bc4 e6 4. c3 Ne7 5. d4 d6 6. b4 O-O 7. a4 Nd7 8. g4 d5 9. exd5 exd5 10. Bd3 c6 11. h4 b5 12. a5 c5 13. bxc5 b4 14. c6 Nf6 15. cxb4 Qd6 16. b5 a6 17. bxa6 Qxc6 18. g5 Nd7 19. Na3 Bxa6 20. Bxa6 Qxa6 21. Bf4 Qxa5+ 22. Ke2 Qb4 23. Bd2 Qb2 24. Nh3 Rxa3 25. Rxa3 Bxd4 26. Rd3 Ne5 27. Qg2 Qb5 28. Rc1 Qxd3+ 29. Kd1 Qb3+ 30. Rc2 Nd3 31. Qg3 Qb1+ 32. Ke2 Qxc2 33. Qxd3 Qxd3+ 34. Kxd3 Ba7 35. Nf4 Nf5 36. Nxd5 Nxh4 37. Ne7+ Kg7 38. f4 Re8 39. Nc6 Bg1 40. Bc3+ Kg8 41. Kc4 Re4+ 42. Kd5 Rxf4 43. Kd6 Rg4 44. Ne7+ Kf8 45. Kd7 Bc5 46. Nf5 Rxg5 47. Nxh4 Rd5+ 48. Kc6 Rh5 49. Nf3 g5 50. Ne5 g4 51. Be1 g3 52. Bxg3 f5 53. Nd7+ Ke7 54. Nxc5 Rg5 55. Bd6+ Kd8 56. Ne6+ Kc8 57. Nxg5 f4 58. Nxh7 f3 59. Bc5 Kd8 60. Ng5 f2 61. Bxf2 Ke7 62. Bh4 Kf6 63. Kd5 Kf5 64. Nf7 Kf4 65. Ke6 Ke4 66. Bf6 Kd3 67. Ne5+ Kc3 68. Kd5 Kd2 69. Nc4+ Ke2 70. Bd4 Kf3 71. Ke5 Kg4 72. Kf6 Kf4 73. Ne5 Ke4 1/2-1/2",
    B: "1305",
  },
  {
    W: "867",
    T: "180+0",
    P: "1. d4 d5 2. e3 Nc6 3. c3 Nf6 4. f4 Bf5 5. b3 Nb4 6. cxb4 Qd6 7. Bb5+ c6 8. Ba4 Bxb1 9. Rxb1 Ne4 10. Nf3 b5 11. Bxb5 cxb5 12. O-O Nc3 13. Qc2 Nxb1 14. Qxb1 Rc8 15. Nd2 e6 16. h4 Be7 17. g4 Bxh4 18. Nf3 Bg3 19. Kg2 Bxf4 20. exf4 O-O 21. Rh1 h6 22. g5 Qxb4 23. gxh6 gxh6 24. Rxh6 Rfd8 25. Qh7+ Kf8 26. Qh8+ Ke7 27. Qf6+ Ke8 28. Qh8+ Kd7 29. Qf6 Qe7 0-1",
    B: "927",
  },
  {
    W: "2375",
    T: "180+0",
    P: "1. e4 e5 2. Bc4 Nf6 3. d3 Bc5 4. Nf3 d6 5. O-O O-O 6. c3 Bb6 7. a4 a5 8. Na3 c6 9. Nc2 Bg4 10. h3 Be6 11. Ne3 Bxc4 12. Nxc4 Bc7 13. Qc2 Nbd7 14. d4 Qe7 15. Bg5 h6 16. Bh4 exd4 17. Nxd4 Qxe4 18. Qxe4 Nxe4 19. Rae1 d5 20. f3 Ng3 21. Bxg3 Bxg3 22. Re7 Nc5 23. Ne3 Rfe8 24. Rxe8+ Rxe8 25. Nef5 Bf4 26. g3 Be3+ 27. Kg2 Bxd4 28. Nxd4 Nxa4 29. Ra1 Nxb2 30. Rxa5 Nd1 31. g4 Nxc3 32. Ra7 Rb8 33. Kg3 c5 34. Nf5 Kf8 35. Kf4 d4 36. Nd6 Nd5+ 37. Ke4 Nf6+ 38. Kd3 Nd5 39. Nxb7 Nf4+ 40. Kc4 d3 41. Nxc5 Rc8 42. Kd4 d2 43. Nd7+ Ke8 44. Ra1 Rd8 45. Rd1 Rxd7+ 46. Ke3 Ng2+ 47. Ke2 Nf4+ 48. Ke3 Ne6 49. f4 Nc5 50. Rxd2 Rxd2 51. Kxd2 Ke7 52. Ke3 Kf6 53. Kd4 Ne6+ 54. Ke4 g5 55. f5 Ng7 56. Kd5 h5 57. Kd6 Ne8+ 58. Kd7 Ng7 59. Kd6 Nxf5+ 60. Kd5 Ng3 61. Kd4 hxg4 62. hxg4 Nf1 63. Ke4 Nh2 64. Ke3 Nxg4+ 65. Kf3 Ne5+ 66. Ke4 Ng6 67. Kf3 Kg7 68. Kg4 f6 69. Kg3 Kf7 70. Kh3 Ke6 71. Kg2 f5 72. Kf3 Ke5 73. Kf2 Ke4 74. Ke1 f4 75. Ke2 g4 76. Kf1 Ke3 77. Ke1 f3 78. Kd1 g3 79. Kc2 Ne5 80. Kc3 Ng4 81. Kc4 g2 82. Kd5 g1=Q 83. Ke6 f2 84. Kf7 f1=Q+ 85. Kg6 1/2-1/2",
    B: "2378",
  },
  {
    W: "1661",
    T: "180+0",
    P: "1. e4 e5 2. d3 Nc6 3. Nf3 Nf6 4. Bg5 h6 5. Bxf6 Qxf6 6. Nc3 d6 7. Nd5 Qd8 8. d4 Be6 9. dxe5 Bxd5 10. Qxd5 dxe5 11. Qxd8+ Rxd8 12. Bb5 f6 13. Bxc6+ bxc6 14. O-O Bc5 15. Rad1 O-O 16. Rxd8 Rxd8 17. c3 g5 18. b4 Bb6 19. a4 c5 20. a5 cxb4 21. axb6 bxc3 22. bxa7 c2 23. Ra1 Rd1+ 24. Rxd1 cxd1=Q+ 25. Ne1 Qxe1# 0-1",
    B: "1542",
  },
  {
    W: "2096",
    T: "180+0",
    P: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. Nf3 g6 5. Bf4 Bg7 6. e3 a6 7. Be2 Bg4 8. Qb3 b6 9. cxd5 Nxd5 10. Nxd5 cxd5 11. Rc1 b5 12. Bc7 Qc8 13. O-O Qb7 14. h3 Bd7 15. Ne5 O-O 16. Bf3 e6 17. Nxd7 Nxd7 18. Bd6 Rfc8 19. Bc5 Rc6 20. a4 Rac8 21. axb5 axb5 22. Qb4 Bf8 23. e4 Bxc5 24. dxc5 Rxc5 25. Rxc5 Rxc5 26. exd5 exd5 27. Re1 Rc8 28. Qd4 Nb6 29. Qf6 Rf8 30. Re7 Qb8 31. h4 Qd8 32. Qe5 Nd7 33. Qd6 Nb8 34. Qc5 Nd7 35. Qd6 Nb8 36. Qb4 1-0",
    B: "2090",
  },
  {
    W: "1664",
    T: "180+0",
    P: "1. f4 d5 2. Nf3 c5 3. b3 Nf6 4. Bb2 Nc6 5. e3 Qc7 6. Bb5 Bd7 7. h3 a6 8. Be2 O-O-O 9. Ne5 Nxe5 10. Bxe5 Qc6 11. g4 Ne4 12. Rg1 f6 13. Bb2 d4 14. d3 Nd6 15. e4 e5 16. f5 g6 17. h4 Bh6 18. Bc1 g5 19. hxg5 Bxg5 20. Bxg5 fxg5 21. Bf3 Qb6 22. Qe2 Qa5+ 23. Qd2 Qxd2+ 24. Nxd2 h5 25. Rh1 h4 26. Bg2 b5 27. Bh3 Kb7 28. Rf1 Kb6 29. Nf3 Ne8 30. Nxe5 Nf6 31. Nf7 c4 32. Nxg5 cxb3 33. axb3 Rhg8 34. Nf7 Rde8 35. Kd2 Nxg4 36. Bxg4 Rxg4 37. Rg1 Rg3 38. Rxg3 hxg3 39. Rg1 Rg8 40. Nd6 Kc6 41. Nf7 a5 42. Nh6 Rg7 43. Ke2 Be8 44. Kf3 Bh5+ 45. Kf4 b4 46. Rxg3 Rxg3 47. Kxg3 Bd1 48. f6 Bxc2 49. f7 a4 50. f8=Q axb3 51. Qxb4 b2 52. Qxb2 Bxd3 53. Qxd4 Bxe4 54. Qxe4+ Kb5 55. Nf5 1-0",
    B: "1663",
  },
  {
    W: "1745",
    T: "180+0",
    P: "1. c4 Nf6 2. Nf3 d5 3. cxd5 Nxd5 4. g3 Bg4 5. Bg2 c6 6. O-O e6 7. h3 Bxf3 8. Bxf3 Bd6 9. Nc3 Nxc3 10. bxc3 O-O 11. a4 Nd7 12. d4 Nf6 13. e4 e5 14. Bg5 h6 15. Bd2 Re8 16. Re1 Qc7 17. Qc2 exd4 18. cxd4 Bf8 19. e5 Nd5 20. Bxd5 Rad8 21. Ba2 Rxd4 22. Bc3 Rdd8 23. Bb1 g6 24. Ba2 c5 25. Qxg6+ Bg7 26. Rac1 Kh8 27. Bxf7 Rf8 28. e6 Bxc3 29. Rxc3 Qe5 30. Rxe5 1-0",
    B: "1826",
  },
  {
    W: "1831",
    T: "180+0",
    P: "1. Nc3 e5 2. e4 Bc5 3. Nf3 Nc6 4. Bc4 Nf6 5. Bxf7+ Kxf7 6. Ng5+ Kg6 7. h4 h6 8. Nh3 d6 9. h5+ Nxh5 10. d3 Bxh3 11. Rxh3 Nf6 12. g4 Qd7 13. Qf3 Qxg4 14. Rg3 h5 15. Rxg4+ hxg4 16. Qg2 Nd4 17. Kd2 Nf3+ 18. Ke2 Rh2 19. Qf1 Rxf2+ 20. Qxf2 Bxf2 21. Kxf2 Rh8 22. Nd5 Rh2+ 23. Kg3 Kh5 24. Nxf6+ gxf6 25. Be3 Rh3+ 26. Kg2 Rh2+ 27. Kg3 Rh3+ 28. Kg2 Kh4 29. Bxa7 Rh2+ 30. Kf1 b6 31. a4 Rxc2 32. b4 Rh2 33. Ra3 Rb2 34. a5 Rxb4 35. axb6 cxb6 36. Bb8 Kh3 37. Bxd6 Rb2 38. Be7 g3 39. Bxf6 g2# 0-1",
    B: "1850",
  },
  {
    W: "2439",
    T: "30+0",
    P: "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Nxf6+ gxf6 6. Nf3 c5 7. Bd3 cxd4 8. Qe2 e5 9. Bd2 Nc6 10. O-O-O Be6 11. Kb1 Qb6 12. c3 O-O-O 13. c4 Kb8 14. Be4 Ka8 15. Rc1 Bc5 16. Bxc6 bxc6 17. Ne1 Rb8 18. f3 Qxb2# 0-1",
    B: "2516",
  },
  {
    W: "1787",
    T: "60+0",
    P: "1. d4 c5 2. c4 cxd4 3. Qxd4 Nc6 4. Qd1 e5 5. Nc3 Nf6 6. e3 Bb4 7. Bd2 Bxc3 8. Bxc3 Ne4 9. Qc2 Nxc3 10. Qxc3 d6 11. Nf3 O-O 12. O-O-O Qb6 13. Bd3 Nb4 14. Kb1 Bg4 15. a3 Nc6 16. Qc2 f5 17. h3 Bh5 18. g4 e4 19. gxh5 exd3 20. Qxd3 Rad8 21. Ng5 Rd7 22. Qd5+ Kh8 23. Rhg1 h6 24. Ne6 Rff7 25. Rg6 Ne5 26. Rg2 Qc5 27. Qd2 Nxc4 28. Qc3 Qb5 0-1",
    B: "1794",
  },
  {
    W: "2027",
    T: "60+0",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. Bc4 c6 5. d3 Nf6 6. Bd2 Bf5 7. f3 Qc7 8. g4 Bg6 9. Qe2 e6 10. O-O-O Nbd7 11. a4 O-O-O 12. f4 h5 13. g5 Nd5 14. Nxd5 exd5 15. Bb3 Bd6 16. Qf2 Rhe8 17. Nh3 Nc5 18. Kb1 Nxb3 19. cxb3 Qb6 20. Ka2 Qxf2 21. Nxf2 Re2 22. Rhf1 Rde8 23. Bc3 Bxf4 24. Bxg7 Bxg5 25. Nh3 Rxh2 26. Nxg5 h4 27. d4 Ree2 28. Be5 Rxb2+ 29. Ka3 a5 30. b4 axb4# 0-1",
    B: "2144",
  },
  {
    W: "1786",
    T: "60+0",
    P: "1. d4 d5 2. e3 c5 3. Nf3 Nf6 4. Be2 g6 5. O-O Bg7 6. c3 O-O 7. Bd2 Nc6 8. c4 e6 9. cxd5 exd5 10. dxc5 Qa5 11. Bxa5 1-0",
    B: "1784",
  },
  {
    W: "1395",
    T: "60+0",
    P: "1. e4 e5 2. Nc3 Nc6 3. Nge2 Bc5 4. g3 Nf6 5. Bg2 O-O 6. h3 h6 7. O-O d5 8. Nxd5 Nxd5 9. exd5 Ne7 10. c4 a6 11. d4 exd4 12. Nxd4 b6 13. d6 cxd6 14. Bxa8 Bxh3 15. Bg2 Bd7 16. Nf3 Ng6 17. Bf4 Be6 18. Be3 Bxc4 19. Bxc5 bxc5 20. Re1 Bb5 21. a4 Bc6 22. Re2 Qc7 23. Rd2 Rd8 24. Rd3 Ne7 25. Rc1 Nf5 26. Rxc5 dxc5 0-1",
    B: "1326",
  },
  {
    W: "1759",
    T: "60+0",
    P: "1. d4 Nf6 2. c4 c5 3. Nf3 cxd4 4. Nxd4 Nc6 5. Nxc6 bxc6 6. Nc3 d5 7. g3 dxc4 8. Bg2 Qc7 9. O-O e6 10. Nb5 Qb6 11. Nc3 Ba6 12. Be3 Bc5 13. Bxc5 Qxc5 14. Na4 Qb5 15. Nc3 Qb6 16. Rb1 O-O 17. e4 Rfd8 18. Qc2 Rab8 19. e5 Nd5 20. Nxd5 cxd5 21. Rfd1 Bb5 22. Kh1 Qa6 23. f4 Ba4 24. b3 cxb3 25. Qd2 bxa2 26. Rxb8 Rxb8 27. Qxa2 Qc6 28. Ra1 Rb4 29. Qd2 Qb5 30. Rc1 Rc4 31. Re1 Qb3 32. h4 Qc2 33. Qe3 Qxg2+ 34. Kxg2 Rc2+ 35. Kh3 Rc4 36. Re2 d4 37. Qe4 Rc3 38. Qb7 Rf3 39. Qb8+ 1-0",
    B: "1726",
  },
  {
    W: "1429",
    T: "60+0",
    P: "1. g3 e5 2. Bg2 Bc5 3. Nf3 Nf6 4. Nxe5 Ng4 5. Nxg4 d6 6. d3 Bxg4 7. O-O Qe7 8. Nd2 O-O 9. Nf3 Bxf3 10. Bxf3 d5 11. e4 d4 12. a3 Nc6 13. b4 Bb6 14. Bd2 Rae8 15. c3 dxc3 16. Bxc3 Bd4 17. Bxd4 Nxd4 18. Qa4 Qf6 19. Bg2 Nf3+ 20. Bxf3 Qxf3 21. Qxa7 Re7 22. Qxb7 Rfe8 23. Rfe1 Qxd3 24. Red1 Qc2 25. Rdc1 Qb2 26. Qc6 Rxe4 27. Qc2 Qxc2 28. Rxc2 Re1+ 29. Rxe1 Rxe1+ 30. Kg2 h6 31. Rxc7 Kh7 32. Rxf7 Re5 33. f4 Re4 34. Rb7 Rxb4 35. axb4 Kg8 36. b5 Kf8 37. b6 Ke8 38. Ra7 Kd8 39. b7 Kc7 40. b8=Q+ Kc6 41. Ra5 Kd7 42. Qb6 Kc8 43. Ra7 1-0",
    B: "1433",
  },
  {
    W: "1556",
    T: "60+0",
    P: "1. d3 Nf6 2. c4 d6 3. Nf3 g6 4. e3 Bg7 5. Nbd2 O-O 6. d4 Nbd7 7. Bd3 Re8 8. Qc2 e5 9. b3 c6 10. e4 exd4 11. Nxd4 d5 12. cxd5 cxd5 13. O-O dxe4 14. Be2 Qb6 15. Re1 Qxd4 16. Kh1 Qxa1 17. f3 exf3 18. Bxf3 Rb8 19. Rxe8+ Nxe8 20. h3 Nef6 21. Kh2 b6 22. Bb2 Qxa2 23. Qc3 Nh5 24. Qxg7+ Nxg7 25. Bxg7 Kxg7 26. Nc4 Qxb3 27. Nd6 0-1",
    B: "1597",
  },
  {
    W: "1423",
    T: "180+0",
    P: "1. e4 e5 2. d3 Nc6 3. Be2 Nf6 4. Nf3 Bc5 5. b3 Ng4 6. O-O d6 7. Bb2 a6 8. Nc3 b5 9. h3 h5 10. hxg4 hxg4 11. Ng5 Qxg5 12. Bc1 Qh4 13. Bxg4 Qh2# 0-1",
    B: "1562",
  },
  {
    W: "2362",
    T: "60+0",
    P: "1. g3 d5 2. Bg2 e6 3. c4 f6 4. cxd5 exd5 5. d4 f5 6. Nc3 Nf6 7. Nf3 c6 8. O-O Bd6 9. Ne5 O-O 10. e3 Nbd7 11. f4 Ne4 12. Nxe4 fxe4 13. Qb3 Qe7 14. Bxe4 Nf6 15. Bg2 Be6 16. Bd2 Rab8 17. Rac1 Bf7 18. Rfe1 Bh5 19. e4 Kh8 20. exd5 Nxd5 21. Ng6+ Bxg6 22. Rxe7 Bxe7 23. Re1 Bf6 24. Bc3 Rbe8 25. Re5 Bxe5 26. dxe5 Ne3 27. Qxb7 Nxg2 28. Kxg2 Be4+ 29. Kf2 Rd8 30. Qxa7 Rd3 31. e6 Rf3+ 32. Ke2 Rd8 33. Qxg7# 1-0",
    B: "2319",
  },
  {
    W: "1301",
    T: "300+0",
    P: "1. e4 c6 2. Bc4 d5 3. exd5 cxd5 4. Bb5+ Nc6 5. Bxc6+ bxc6 6. Nf3 f6 7. d3 e5 8. O-O Bc5 9. c4 d4 10. a3 Nh6 11. b4 Bd6 12. Bxh6 gxh6 13. Qa4 Bd7 14. c5 Bc7 15. Nh4 f5 16. g3 e4 17. dxe4 fxe4 18. Nd2 O-O 19. Nxe4 Bh3 20. Rfe1 Qd5 21. Rad1 Bd8 22. Ng2 d3 23. Nf4 Qd7 24. Nxh3 Qxh3 25. Rxd3 Qf5 26. Qxc6 Bg5 27. Rdd1 Rae8 28. Nxg5 Rxe1+ 29. Rxe1 hxg5 30. b5 Qxf2+ 31. Kh1 Rf6 32. Qd5+ Kg7 33. Re7+ Kg6 34. Qe4+ Kh6 35. Rxh7# 1-0",
    B: "1305",
  },
  {
    W: "1722",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. c4 Qc7 6. Nc3 Nf6 7. g3 Bc5 8. Be3 O-O 9. Bg2 Nc6 10. Nxc6 Qxc6 11. e5 Qxg2 12. Rf1 Bxe3 13. fxe3 Ne4 14. Nxe4 Qxe4 15. Qd4 Qxd4 16. exd4 b5 17. O-O-O bxc4 0-1",
    B: "1783",
  },
  {
    W: "1632",
    T: "300+0",
    P: "1. c4 g6 2. d4 Bg7 3. e4 d6 4. f4 a6 5. Nf3 e6 6. Be3 Ne7 7. Bd3 Nd7 8. Nc3 c6 9. Qc2 b5 10. b3 b4 11. Ne2 a5 12. Ng3 Bb7 13. e5 d5 14. c5 Qc8 15. a3 Ba6 16. O-O Bxd3 17. Qxd3 Qa6 18. Qxa6 Rxa6 19. axb4 O-O 20. bxa5 Rfa8 21. b4 f6 22. Rab1 Rb8 23. Rb2 fxe5 24. fxe5 Nf5 25. Bd2 h5 26. Ne2 Kf7 27. Ng5+ Ke7 28. Nf4 Nf8 29. Bc3 Ne3 30. Rf3 Nc4 31. Rb1 Bh6 32. Ngh3 g5 33. Nxh5 g4 34. Rf6 Be3+ 35. Nf2 Nh7 36. Rg6 Ng5 37. Re1 Bxf2+ 38. Kxf2 Ne4+ 39. Rxe4 dxe4 40. Rxg4 Rf8+ 41. Ke2 Raa8 42. Rxe4 1-0",
    B: "1613",
  },
  {
    W: "1918",
    T: "300+0",
    P: "1. e4 e6 2. d4 Ne7 3. Bg5 h6 4. Bh4 g5 5. Bg3 Ng6 6. Nc3 b6 7. Bd3 Bb7 8. Nge2 Nc6 9. O-O Be7 10. d5 Nce5 11. Bb5 a6 12. Ba4 Nc4 13. Rb1 h5 14. f4 h4 15. Be1 Ne3 16. Qd4 Nxf1 17. f5 Bf6 18. Qf2 Ng3 19. hxg3 hxg3 20. Nxg3 Nf4 21. fxe6 fxe6 22. dxe6 Nxe6 23. Nd5 Bd4 24. Ne3 Nf4 25. c3 Bxe3 26. Qxe3 Qe7 27. Rd1 O-O-O 28. Nf5 Qxe4 29. Qxe4 Bxe4 30. Ne7+ Kb7 31. g3 Rh1+ 32. Kf2 Nd3+ 33. Ke2 Nxe1 34. Rxe1 g4 35. Kf2 Rh2+ 36. Ke3 Bf3 37. Kd4 Rd2+ 38. Kc4 b5+ 39. Bxb5 axb5+ 40. Kxb5 Rxb2+ 41. Ka4 Rxa2+ 42. Kb3 Rda8 43. Nf5 R2a3+ 44. Kb2 Ra2+ 45. Kb3 R8a3+ 46. Kb4 Ra4+ 47. Kb3 R2a3+ 48. Kb2 Bd5 49. Nd4 Ra2+ 50. Kc1 Rh2 51. Nc2 Ra2 52. Nb4 Ra1# 0-1",
    B: "1859",
  },
  {
    W: "2014",
    T: "300+0",
    P: "1. d4 d5 2. Kd2 e6 3. Ke3 Bd6 4. Kf3 Nf6 5. Qd3 e5 6. Qxh7 Rxh7 7. Bh6 Rxh6 8. a3 e4+ 9. Ke3 Ng4+ 10. Kd2 Nxf2 11. Nh3 Nxh1 12. Nf4 Bxf4+ 13. Kc3 Rxh2 14. g3 Nxg3 15. Bh3 Rxh3 16. Nd2 Nxe2+ 17. Kb4 Bxd2+ 18. c3 Nxc3 19. Kb3 Nb5+ 0-1",
    B: "1929",
  },
  {
    W: "1667",
    T: "300+0",
    P: "1. d4 d5 2. Nf3 e6 3. Bf4 h6 4. e3 Nf6 5. Bd3 a6 6. O-O b5 7. Nbd2 Bb7 8. b3 Nbd7 9. c4 b4 10. cxd5 Bxd5 11. Rc1 c5 12. Qe2 a5 13. Bb5 Qb6 14. Ne5 Be7 15. h3 O-O 16. Nxd7 Qb7 17. Nxf8 Bxg2 18. Rfd1 Bxh3 19. Nxe6 Qg2# 0-1",
    B: "1644",
  },
  {
    W: "1852",
    T: "300+0",
    P: "1. d4 d5 2. c4 Nc6 3. Nc3 dxc4 4. d5 e6 5. dxc6 bxc6 6. Qxd8+ Kxd8 7. e4 Bd7 8. Bxc4 Bb4 9. Bd2 Ne7 10. Nf3 Ke8 11. O-O f6 12. a3 Ba5 13. b4 Bb6 14. Rad1 Kf7 15. Bc1 Rad8 16. Bb2 g5 17. e5 h6 18. exf6 Nf5 19. Ne4 g4 20. Nfd2 Rhg8 21. Kh1 h5 22. Nb3 h4 23. Nbc5 Bxc5 24. Nxc5 g3 25. Rxd7+ Rxd7 26. Nxd7 Kg6 27. Bxe6 gxh2 28. Bxf5+ Kxf5 29. Kxh2 h3 30. gxh3 Rg2+ 31. Kxg2 Ke6 32. Nc5+ Kd6 33. Re1 a5 34. f7 1-0",
    B: "1864",
  },
  {
    W: "1369",
    T: "300+0",
    P: "1. d4 d5 2. Bf4 e6 3. Nf3 h6 4. h3 Bd6 5. Ne5 Bxe5 6. Bxe5 f6 7. Bh2 Nc6 8. e3 Bd7 9. Qh5+ Ke7 10. Qg6 Qf8 11. Bxc7 Be8 12. Qg3 Kd7 13. Ba5 Nxa5 14. Bb5+ Kd8 15. Nd2 g5 16. f4 Bxb5 17. fxg5 hxg5 18. a4 Bc6 19. Qf2 Qd6 20. g4 Kc7 21. b3 Rh6 22. c4 dxc4 23. bxc4 Rd8 24. c5 Qd5 25. Qh2+ Kc8 26. Rf1 Nc4 27. Nxc4 Qxc4 28. Qb2 Rxh3 29. a5 Rxe3+ 30. Kf2 Rf3+ 31. Kg1 Rxf1+ 32. Rxf1 Rxd4 33. Rb1 Rxg4+ 34. Kh2 Qf4+ 35. Kh3 Qg3# 0-1",
    B: "1421",
  },
  {
    W: "1437",
    T: "300+0",
    P: "1. c4 e5 2. Nc3 Nf6 3. e3 d5 4. d4 Bb4 5. Bd2 O-O 6. Nf3 exd4 7. exd4 Re8+ 8. Be2 Bg4 9. O-O Nc6 10. a3 Bxc3 11. Bxc3 Bxf3 12. Bxf3 dxc4 13. Be2 Qd5 14. Bf3 Qg5 15. d5 Nxd5 16. Bxd5 Rad8 17. Qf3 Qxd5 18. Rad1 Qg5 19. Rxd8 Rxd8 20. h3 Ne5 21. Qe4 f6 22. f4 Qh4 23. Bxe5 fxe5 24. Qxe5 Rd2 25. Qe8# 1-0",
    B: "1335",
  },
  {
    W: "1224",
    T: "300+0",
    P: "1. e4 e6 2. Nf3 b6 3. d4 Nf6 4. Nc3 Bb4 5. Bd2 Bxc3 6. Bxc3 Nxe4 7. d5 Nxc3 8. bxc3 Bb7 9. dxe6 dxe6 10. Qxd8+ Kxd8 11. Be2 Na6 12. a3 Nc5 13. O-O Ne4 14. Bd3 Nxc3 15. Rfe1 Ke7 16. a4 Nd5 17. Be4 f5 18. Bxd5 Bxd5 19. Ng5 Kf6 20. h4 h6 21. Nf3 Be4 22. Nd4 c5 23. f3 cxd4 24. fxe4 e5 25. exf5 Kxf5 26. Rf1+ Ke6 27. Rae1 g5 28. Rf2 gxh4 29. Ref1 Kd5 30. Kh2 Rag8 31. Kh3 Rg3+ 32. Kxh4 Rc3 33. Rc1 Ra3 34. Rf6 Rxa4 35. Kh5 b5 36. c3 Rc4 37. Rff1 a5 38. cxd4 Rxd4 39. Ra1 a4 40. Rfb1 Kc5 41. Rb2 e4 42. Rab1 b4 43. Rc1+ Kb5 44. Rf2 a3 45. Rf5+ Ka4 46. Rcc5 Kb3 47. Rf2 a2 48. Rcc2 a1=Q 49. Rb2+ Qxb2 50. Rxb2+ Kxb2 0-1",
    B: "1268",
  },
  {
    W: "2124",
    T: "300+0",
    P: "1. Nf3 d5 2. g3 Nf6 3. Bg2 e6 4. c4 Be7 5. O-O O-O 6. d4 Nbd7 7. Qc2 b6 8. cxd5 exd5 9. Bf4 c5 10. Nc3 a6 11. Ng5 h6 12. Nxd5 Nxd5 13. Qh7# 1-0",
    B: "2355",
  },
  {
    W: "1538",
    T: "300+0",
    P: "1. e4 b6 2. Nf3 Bb7 3. Nc3 Nf6 4. d3 d6 5. Bg5 Nc6 6. Bxf6 gxf6 7. Qd2 Qd7 8. O-O-O O-O-O 9. Kb1 h5 10. Nh4 Bh6 11. f4 Nd4 12. Ne2 Ne6 13. g3 d5 14. Qc3 dxe4 15. Bg2 exd3 16. Rxd3 Qb5 17. Bxb7+ Kxb7 18. Rhd1 Rxd3 19. Rxd3 Nc5 20. Rd5 Qxe2 21. a3 Ne4 22. Qd4 Nd6 23. Rxh5 Nb5 24. Qd5+ Ka6 25. Nf5 Qxh5 26. Nxe7 Qxd5 27. Nxd5 Rd8 28. Nxf6 Rd1+ 29. Ka2 Bg7 30. Ne4 Nd4 31. c3 Nc2 32. h4 Ra1+ 33. Kb3 Ne1 34. g4 Nd3 35. f5 Rh1 36. h5 Rg1 37. g5 Nf4 38. f6 Bh8 39. h6 Ne6 40. g6 fxg6 41. f7 Rf1 0-1",
    B: "1546",
  },
  {
    W: "1214",
    T: "300+0",
    P: "1. h3 e5 2. e3 d5 3. c3 Nc6 4. Qa4 Bd7 5. Bb5 a6 6. Bxc6 Bxc6 7. Qb3 Nf6 8. Nf3 Bd6 9. Na3 e4 10. Nd4 Qd7 11. Nxc6 Qxc6 12. Qc2 b5 13. b3 a5 14. Rb1 b4 15. cxb4 Qxc2 16. Nxc2 axb4 17. Rb2 Be5 18. d4 Bd6 19. Bd2 c5 20. dxc5 Bxc5 21. Bxb4 Bxb4+ 22. Nxb4 O-O 23. O-O Rfb8 24. Nc6 Rb7 25. Rd1 Kf8 26. a4 Rb6 27. Ne5 Rab8 28. Rbd2 Rxb3 29. a5 Ra3 30. Nc6 Ra8 31. Rb2 Ke8 32. Rb6 Kd7 33. Rc1 Ne8 34. Rb7+ 1-0",
    B: "1115",
  },
  {
    W: "1460",
    T: "300+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. c3 Nf6 4. d4 cxd4 5. cxd4 Nxe4 6. d5 Qa5+ 7. Nc3 Nxc3 8. Bd2 Nxd1 9. Bxa5 Nxa5 10. Rxd1 Nc4 11. b3 Nd6 12. Bd3 e6 13. dxe6 dxe6 14. O-O Be7 15. Rfe1 O-O 16. h4 h5 17. Ng5 Bxg5 18. hxg5 Nf5 19. f4 e5 20. Bxf5 Bxf5 21. Rxe5 g6 22. Kf2 Kg7 23. Re7 b6 24. Rc1 a6 25. Rcc7 b5 26. g3 a5 27. Ke3 b4 28. Kd4 Ra6 29. Rc5 Rd8+ 30. Kc4 Be6+ 31. Kb5 Rdd6 32. Ka4 Rdb6 33. Rcc7 Kf8 34. f5 Bxf5 35. Rxf7+ Ke8 36. Rce7+ Kd8 37. Rg7 Rd6 38. Ref7 Rd7 39. Rg8+ Kc7 40. Rff8 Rd3 41. Rc8+ Bxc8 42. Rxg6 Rxg3 43. Rxa6 Rxg5 44. Ra7+ Kb6 45. Rh7 Bf5 46. Rh6+ Rg6 47. Rxh5 Bb1 48. Rh2 Rc6 49. a3 Rc2 50. Rxc2 Bxc2 51. axb4 Bxb3+ 52. Kxb3 1/2-1/2",
    B: "1487",
  },
  {
    W: "980",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 d6 3. Nc3 Nc6 4. d4 exd4 5. Nxd4 Nxd4 6. Qxd4 c5 7. Qd2 Qh4 8. Bb5+ Bd7 9. O-O d5 10. exd5 Bd6 11. Qe2+ Kd8 12. Bxd7 Qxh2# 0-1",
    B: "985",
  },
  {
    W: "1986",
    T: "60+0",
    P: "1. b4 e5 2. Bb2 Bxb4 3. Bxe5 Nc6 4. Bxg7 Nge7 5. Bxh8 d6 6. Bf6 Be6 7. c3 Bc5 8. Bxe7 Qxe7 9. Nf3 O-O-O 10. e3 d5 11. d4 Bb6 12. Nbd2 Na5 13. Be2 Bd7 14. O-O c6 15. Nb3 Nxb3 16. axb3 a6 17. c4 Bc7 18. cxd5 cxd5 19. Rc1 Bc6 20. Qc2 Qd6 21. Bxa6 bxa6 22. Qxc6 Qa3 23. Qxc7# 1-0",
    B: "1472",
  },
  {
    W: "1621",
    T: "300+3",
    P: "1. c4 e5 2. Nc3 f5 3. g3 Nf6 4. Bg2 c6 5. b4 d6 6. Bb2 g6 7. Nf3 Bg7 8. c5 dxc5 9. Nxe5 cxb4 10. Qb3 Qe7 11. Na4 Be6 12. Qe3 Ng4 13. Nxg4 Bxb2 14. Nxb2 fxg4 15. O-O Na6 16. f3 gxf3 17. Rxf3 O-O-O 18. d3 Rhe8 19. Qxa7 Bg4 20. Rf2 Bxe2 21. Re1 1-0",
    B: "1734",
  },
  {
    W: "1565",
    T: "300+3",
    P: "1. e4 e5 2. d4 exd4 3. Nf3 d5 4. Qxd4 dxe4 5. Qxd8+ Kxd8 6. Ng5 Nh6 7. Nxe4 g6 8. Bc4 Bf5 9. Nbc3 Bg7 10. O-O Bxc3 11. Nxc3 Ng4 12. h3 Ne5 13. Bg5+ Kc8 14. Be2 h6 15. Bf6 Re8 16. Rfe1 Ned7 17. Bh4 g5 18. Bg3 Bxc2 19. Rac1 Bg6 20. Nb5 Nc6 21. Nxc7 1-0",
    B: "1612",
  },
  {
    W: "1348",
    T: "300+3",
    P: "1. e4 e6 2. Nf3 d5 3. e5 c5 4. d4 Nc6 5. Bb5 Ne7 6. dxc5 a6 7. Ba4 Qa5+ 8. Nc3 Qxc5 9. Be3 Qa5 10. O-O b5 11. Bb3 Bb7 12. a4 b4 13. Ne2 Rd8 14. Ned4 Qc7 15. Bg5 Nxe5 16. c3 bxc3 17. bxc3 Nc4 18. Bxc4 Qxc4 19. Qc2 h6 20. Bh4 Rc8 21. Rfc1 Nc6 22. a5 Bc5 23. Nxc6 Bxc6 24. Nd2 Qxh4 25. c4 Qxf2+ 26. Kh1 dxc4 27. Rg1 Bb4 28. Nxc4 Qxc2 0-1",
    B: "1385",
  },
  {
    W: "1327",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. b3 Bc5 4. Qe2 Nf6 5. d3 d6 6. Bb2 O-O 7. d4 exd4 8. Nxd4 Bxd4 9. Bxd4 Nxd4 10. Qd3 c5 11. f3 b6 12. c3 Nc6 13. Nd2 Nd7 14. c4 f6 15. Qd5+ Kh8 16. Qxc6 Bb7 17. Qxb7 1-0",
    B: "1148",
  },
  {
    W: "1076",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 d6 4. d5 Na5 5. Bb5+ Bd7 6. Qd3 Nf6 7. Bg5 Be7 8. O-O h6 9. Bxf6 Bxf6 10. b4 Bxb5 11. Qxb5+ Nc6 12. dxc6 bxc6 13. Qxc6+ Qd7 14. Qxa8+ Bd8 15. Qxa7 O-O 16. Nc3 d5 17. exd5 e4 18. Ne5 Qf5 19. Qd4 Re8 20. Nxf7 Qxf7 21. Nxe4 Bf6 22. Nxf6+ gxf6 23. Qg4+ Kf8 1-0",
    B: "1143",
  },
  {
    W: "1468",
    T: "300+3",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bc5 4. Bb5 d6 5. Bxc6+ bxc6 6. O-O Nf6 7. Na4 Bb6 8. Re1 Bg4 9. h3 Bh5 10. c3 O-O 11. d4 exd4 12. cxd4 Re8 13. g4 Bg6 14. e5 dxe5 15. dxe5 Nd5 16. Bg5 Qd7 17. Nxb6 cxb6 18. Qa4 Qc7 19. Rac1 b5 20. Qd4 Red8 21. Qc5 h6 22. Bxd8 Qxd8 23. Qxc6 Nf4 24. Qxb5 Rb8 25. Qc4 Qb6 26. Qxf4 Qxb2 27. e6 fxe6 28. Rb1 Bxb1 29. Rxb1 Qxb1+ 30. Kg2 Rf8 31. Qg3 Qxa2 32. Ne5 Qd5+ 33. Kh2 a5 34. Ng6 Rf6 35. Ne7+ Kf7 36. Nxd5 exd5 37. Qc7+ Kg6 38. Qxa5 Rxf2+ 39. Kg3 Rf6 40. Qxd5 Kh7 41. Qe4+ Kh8 42. h4 Kg8 43. h5 Kh8 44. Qe8+ Kh7 45. Kh4 Rf1 46. Qe4+ Kh8 47. Qa8+ Kh7 48. Qe4+ Kh8 49. Qg2 Rf6 50. g5 Rf4+ 51. Kg3 hxg5 52. Qa8+ Kh7 53. Qd5 Rh4 54. Qxg5 1-0",
    B: "1410",
  },
  {
    W: "1061",
    T: "300+3",
    P: "1. b3 g6 2. Bb2 Nf6 3. e4 e5 4. d4 exd4 5. Qxd4 Nc6 6. Qxf6 Qxf6 7. Bxf6 Rg8 8. Nc3 Ba3 9. Nd5 Bd6 10. Bh4 b6 11. Nf6+ Kf8 12. Nxg8 Kxg8 13. Nf3 Nb4 14. Nd4 Be5 15. O-O-O Nxa2+ 16. Kb1 Nc3+ 17. Kc1 Nxd1 18. Kxd1 Bxd4 19. Bc4 Bb7 20. e5 Bxe5 21. Re1 Bxh2 22. Re7 Rf8 23. Rxd7 Bxg2 24. Bd8 Bf3+ 25. Kd2 Bf4+ 26. Kc3 Be5+ 27. Kb4 Bd6+ 28. Kb5 c6+ 29. Ka6 Bb8 30. Be7 Bg4 31. Bxf7+ Rxf7 32. Rd8+ Kg7 33. Rxb8 Rxe7 34. f4 h5 35. c3 h4 36. b4 h3 37. Rd8 h2 38. Rd1 Bxd1 0-1",
    B: "1067",
  },
  {
    W: "2095",
    T: "300+3",
    P: "1. d4 Nf6 2. Nf3 d5 3. c4 e6 4. Nc3 c6 5. Bg5 Nbd7 6. e3 Be7 7. Rc1 O-O 8. Bd3 dxc4 9. Bxc4 Nd5 10. Bxe7 Qxe7 11. O-O Nxc3 12. Rxc3 e5 13. dxe5 Nxe5 14. Nxe5 Qxe5 15. f4 Qe7 16. f5 b5 17. f6 gxf6 18. Bd3 Qxe3+ 19. Kh1 Qg5 20. Be4 Bg4 21. Qc2 Rad8 22. Rg3 Rd2 23. Qxc6 Qf4 24. Bf3 h5 25. Qc3 Rfd8 26. Be2 Rd1 27. h3 Rxf1+ 28. Bxf1 Qxf1+ 29. Kh2 Rd1 30. Qc8+ Kg7 31. Rxg4+ hxg4 32. Qxg4+ Kf8 33. Qc8+ Kg7 34. Qg4+ Kf8 35. Qc8+ Ke7 36. Qc7+ Ke6 37. Qc8+ Rd7 38. Qc6+ Rd6 39. Qc8+ Ke5 40. Qc3+ Rd4 41. Qc7+ Ke4 42. Qc2+ Qd3 43. Qc6+ Rd5 44. Qe8+ Kf5 45. Qxf7 Qd4 46. g4+ Kf4 47. Qc7+ Qe5 48. Qc1+ Kf3+ 49. Kg1 Qe3+ 0-1",
    B: "1934",
  },
  {
    W: "1774",
    T: "300+3",
    P: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e5 7. Bxf6 Qxf6 8. Nd5 Qd8 9. Nf3 Be6 10. c4 Nc6 11. a3 g6 12. Bd3 Bg7 13. O-O O-O 14. h3 f5 15. exf5 Bxf5 16. Bxf5 Rxf5 17. Re1 b5 18. cxb5 axb5 19. Qb3 Kh8 20. Rac1 Na5 21. Qxb5 Bh6 22. Rcd1 Rb8 23. Qd3 Rb3 24. Qa6 Rfxf3 25. gxf3 Rxf3 26. Re3 Bxe3 27. Nxe3 Qg5+ 28. Kf1 Nb3 29. Qc8+ Kg7 30. Qd7+ Kh6 31. Qxd6 Qf4 32. Ng4+ Kh5 0-1",
    B: "1855",
  },
  {
    W: "953",
    T: "300+3",
    P: "1. e4 Nf6 2. f3 e5 3. Ne2 d5 4. Nbc3 d4 5. Nb5 c6 6. Na3 b5 7. Ng3 Be6 8. b3 b4 9. Nc4 Bxc4 10. bxc4 c5 11. d3 Nc6 12. Nf5 g6 13. Nh4 a5 14. Be2 a4 15. g4 h5 16. gxh5 Rxh5 17. Ng2 Nd7 18. c3 dxc3 19. d4 cxd4 20. c5 Nxc5 21. Bb5 Rc8 22. f4 exf4 23. Bxf4 Rh4 24. e5 g5 25. Bg3 Re4+ 26. Kf1 Rb8 27. Bxc6+ Ke7 28. e6 fxe6 29. Qf3 b3 30. axb3 axb3 31. Re1 b2 32. Kf2 c2 33. Rxe4 b1=Q 34. Rxb1 cxb1=Q 35. Nh4 Rb2+ 36. Re2 Rxe2+ 37. Kxe2 Qc2+ 38. Kf1 Qc4+ 39. Kf2 d3 40. Ng6# 1-0",
    B: "1039",
  },
  {
    W: "1469",
    T: "900+0",
    P: "1. d4 d5 2. c4 e6 3. Nf3 Nf6 4. a3 c5 5. Nc3 Nc6 6. e3 Be7 7. Be2 dxc4 8. Bxc4 Nd5 9. Bxd5 exd5 10. O-O cxd4 11. exd4 Be6 12. Ne5 Nxe5 13. dxe5 O-O 14. f4 Qd7 15. Rf3 Bg4 16. Nxd5 Bxf3 17. Qxf3 Rfd8 18. Be3 Qxd5 19. Rd1 Qxd1+ 0-1",
    B: "1680",
  },
  {
    W: "1937",
    T: "180+2",
    P: "1. d4 c5 2. d5 e6 3. c4 exd5 4. cxd5 d6 5. Nc3 Bf5 6. e4 Bg6 7. f4 Nf6 8. f5 Bh5 9. Be2 Bxe2 10. Qxe2 Be7 11. Nf3 Nbd7 12. O-O O-O 13. h3 Nh5 14. Qf2 g6 15. Bh6 Ng7 16. g4 Rb8 17. a4 Ne5 18. Nxe5 dxe5 19. f6 Bxf6 20. Qxf6 Qxf6 21. Rxf6 Ne8 22. Rf2 Ng7 23. Rd2 Rfd8 24. a5 b5 25. axb6 axb6 26. Nb5 Ne8 27. Bg5 f6 28. Be3 Nd6 29. Nxd6 Rxd6 30. Ra7 Rbd8 31. b3 f5 32. gxf5 gxf5 33. Rg2+ Kh8 34. Rgg7 fxe4 35. Rxh7+ Kg8 36. Rag7+ Kf8 37. Rg4 Rxd5 38. Rh8+ Ke7 39. Rxd8 Rxd8 40. Rxe4 Ke6 41. Rh4 Rd3 42. Kf2 Rxb3 43. Rh6+ Kd5 44. h4 b5 45. Rh5 Ke4 46. Bxc5 b4 47. Bd6 Rf3+ 48. Kg2 Rb3 49. Rxe5+ Kd4 50. Rb5 Kc4 51. Rb8 Rd3 52. Bxb4 Rd5 53. Kg3 Rh5 54. Kg4 Rh7 55. h5 Kd5 56. Bf8 Ke6 57. h6 Kf7 58. Kg5 Rh8 59. Rb7+ Kg8 60. Kg6 Kxf8 61. Rb8+ Ke7 62. Rxh8 Ke6 63. Rf8 Ke5 64. Rf6 Ke4 65. h7 Ke3 66. h8=Q Ke2 67. Qe8+ 1-0",
    B: "1938",
  },
  {
    W: "2155",
    T: "180+2",
    P: "1. d4 d6 2. c4 Nc6 3. d5 Ne5 4. e4 e6 5. Nc3 exd5 6. cxd5 Nf6 7. Nf3 Nxf3+ 8. Qxf3 g6 9. Bb5+ Bd7 10. Bg5 Be7 11. Bxd7+ Nxd7 12. Bh6 Bf6 13. O-O Ne5 14. Qe2 Qe7 15. Qb5+ Nd7 16. Qxb7 Rb8 17. Qxc7 Rxb2 18. Rab1 Rxb1 19. Rxb1 Qd8 20. Qc6 Ke7 21. Nb5 Qb6 22. Be3 Qxc6 23. dxc6 Nc5 24. Nxa7 Ra8 25. Bxc5 dxc5 26. Rb7+ Kd6 27. c7 1-0",
    B: "2160",
  },
  {
    W: "1437",
    T: "180+2",
    P: "1. e4 g6 2. f4 Bg7 3. Nf3 d6 4. d4 Nc6 5. Nc3 e5 6. d5 Nd4 7. f5 Nxf3+ 8. Qxf3 gxf5 9. exf5 Qf6 10. Bd3 Ne7 11. g4 O-O 12. h4 h6 13. g5 hxg5 14. Bxg5 Qxf5 15. Bxf5 Nxf5 16. O-O-O f6 17. Bd2 Nd4 18. Qg2 Bd7 19. h5 Rf7 20. h6 Nxc2 21. Kxc2 Bf5+ 22. Kc1 Kf8 23. hxg7+ Rxg7 24. Rh8+ Kf7 25. Qh2 Rxh8 26. Qxh8 Rh7 27. Qb8 Kg6 28. Rg1+ Kf7 29. Qxc7+ Ke8 30. Rg8# 1-0",
    B: "1191",
  },
  {
    W: "1905",
    T: "180+2",
    P: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. h4 h6 7. Nh3 Nd7 8. Nf4 Bh7 9. Ngh5 Ngf6 10. Bd3 Bxd3 11. cxd3 Nxh5 12. Qxh5 Nf6 13. Qe2 e6 14. O-O Bd6 15. Re1 O-O 16. f3 Qc7 17. Nh3 Nh5 18. g4 Nf4 19. Nxf4 Bxf4 20. Bxf4 Qxf4 21. Qe4 Qg3+ 22. Kf1 Qxh4 23. Ke2 Qh2+ 24. Ke3 Qxb2 25. g5 f5 26. Qxe6+ Kh8 27. gxh6 f4+ 28. Ke4 Rfe8 29. hxg7+ Kxg7 30. Rg1+ Kh7 31. Rh1+ Kg7 32. Rag1+ Kf8 33. Rg8# 1-0",
    B: "1914",
  },
  {
    W: "1496",
    T: "180+2",
    P: "1. e4 c5 2. Nf3 Nc6 3. c3 g6 4. d4 cxd4 5. cxd4 Bg7 6. Bb5 Nf6 7. Bd2 Qa5 8. Bxa5 Nxa5 9. Nc3 a6 10. Ba4 b5 11. Bb3 Nxb3 12. axb3 O-O 13. O-O Bb7 14. e5 Ne8 15. d5 Nc7 16. b4 d6 17. Re1 dxe5 18. Nxe5 Rfd8 19. Qf3 f6 20. Nc6 1-0",
    B: "1511",
  },
  {
    W: "1741",
    T: "180+2",
    P: "1. e4 e6 2. d4 b6 3. Nf3 Bb7 4. Bd3 Nf6 5. Bg5 Be7 6. Nc3 O-O 7. O-O d6 8. Re1 Ng4 9. Bxe7 Qxe7 10. d5 e5 11. h3 Nh6 12. Ne2 f5 13. Ng3 f4 14. Nf5 Qf6 15. c4 g5 16. b4 g4 17. hxg4 Nxg4 18. N3h4 Qg5 19. Qf3 Kh8 20. Be2 Rg8 21. Rac1 Bc8 22. c5 Bxf5 23. Nxf5 Qh5 24. Nh4 Qxh4 25. Qh3 Qxf2+ 0-1",
    B: "1764",
  },
  {
    W: "1666",
    T: "180+2",
    P: "1. d4 e5 2. c4 exd4 3. Nf3 c5 4. b4 d6 5. bxc5 dxc5 6. e3 Nc6 7. exd4 Nxd4 8. Nxd4 Qxd4 9. Qxd4 cxd4 10. Bb2 Bb4+ 11. Nd2 Bc3 12. Bxc3 dxc3 13. Ne4 Nf6 14. Nxc3 Be6 15. Be2 O-O 16. O-O Rac8 17. Nb5 a6 18. Nd6 Rc7 19. Rac1 Rd8 20. c5 Bxa2 21. Bf3 Bd5 22. Bxd5 Nxd5 23. Rfd1 Nf4 24. g3 Ne2+ 0-1",
    B: "1666",
  },
  {
    W: "2104",
    T: "180+2",
    P: "1. c4 c5 2. g3 e6 3. Bg2 d5 4. e3 Nf6 5. Ne2 Nc6 6. b3 d4 7. d3 e5 8. O-O Be7 9. f4 dxe3 10. Bxc6+ bxc6 11. fxe5 Ng4 12. Nf4 Nxe5 13. Bxe3 O-O 14. Nc3 Bf6 15. Ne4 Be7 16. Nxc5 Bg4 17. Qc2 Nf3+ 18. Kh1 Bf6 19. h3 Bxa1 20. hxg4 Nd4 21. Qf2 Bc3 22. Ne4 f5 23. Nxc3 c5 24. Ncd5 fxg4 25. Bxd4 cxd4 26. Qxd4 g5 27. Nh5 Rxf1+ 28. Kg2 Qf8 29. Ndf6+ Rxf6 30. Nxf6+ Kf7 31. Nxg4 Rd8 32. Qe5 Qe7 33. Qf5+ Kg8 34. Nh6+ Kg7 35. Ng4 Rf8 36. Qe5+ Qxe5 37. Nxe5 Rd8 38. c5 Kf6 39. d4 Ke6 40. Nc4 Kd5 41. Kf3 Kxd4 42. Kg4 h6 43. Kh5 Kxc5 44. Kxh6 g4 45. Kg5 Rd4 46. Kf5 a6 47. Ke5 Rd3 48. Kf4 Rd4+ 49. Ke3 Rd1 50. Ne5 Ra1 51. a4 Ra3 52. Nd3+ Kd6 53. Nc1 Ra1 54. Kd2 Kc5 55. Kc2 Kb4 56. Nd3+ Ka3 57. Ne5 Rg1 58. Nxg4 Rxg3 59. Ne5 Rg2+ 60. Kc3 Rg3+ 61. Kc4 Rxb3 62. a5 Ka4 63. Kc5 Rb5+ 64. Kc4 Kxa5 0-1",
    B: "2045",
  },
  {
    W: "1611",
    T: "300+0",
    P: "1. e4 e5 2. Bc4 Nc6 3. Nc3 Nf6 4. f4 Bb4 5. d3 Bxc3+ 6. bxc3 d6 7. Nf3 Bg4 8. O-O O-O 9. f5 a6 10. Be3 Na5 11. Bb3 b5 12. Qd2 c5 13. a3 c4 14. dxc4 bxc4 15. Ba2 Bxf3 16. Rxf3 d5 17. Rg3 Nxe4 18. Qe1 Nxg3 19. Qxg3 e4 20. Bh6 g6 21. Bxf8 Qxf8 22. fxg6 fxg6 23. Rf1 Qxa3 24. Qc7 Qxa2 25. Qf7+ Kh8 26. h3 Nc6 27. Qxd5 Ne7 28. Qxa8+ Kg7 29. Qxe4 Nf5 30. Qe5+ Kh6 31. Rf2 Qa4 32. g4 Nh4 33. Qf4+ Kg7 34. Re2 Qa1+ 35. Kh2 Qxc3 36. Re7+ Kh8 37. Qb8# 1-0",
    B: "1604",
  },
  {
    W: "1795",
    T: "60+0",
    P: "1. h4 d5 2. h5 e5 3. d4 Nf6 4. dxe5 Ne4 5. Nf3 Nc6 6. c3 Be6 7. h6 Bc5 8. e3 gxh6 9. Rxh6 Qe7 10. b4 Bb6 11. a4 a5 12. b5 Nd8 13. Nbd2 Nxc3 14. Qc2 Qb4 15. Ba3 Qg4 16. Qxc3 d4 17. Nxd4 Bxd4 18. exd4 Qg5 19. Rh1 Rg8 20. Nf3 Qf4 21. Qc5 Qe4+ 22. Be2 Rg4 23. Qe7# 1-0",
    B: "1849",
  },
  {
    W: "1170",
    T: "900+0",
    P: "1. Nf3 d5 2. d4 b6 3. e3 c5 4. h3 Nc6 5. b3 e6 6. Bb2 Bd6 7. Be2 Nge7 8. O-O O-O 9. Na3 Bd7 10. Qd3 Nb4 11. Qd2 Nbc6 12. e4 e5 13. exd5 Nxd5 14. Bc4 h6 15. Bxd5 Nxd4 16. Qd3 Qf6 17. Qe4 Bf5 18. Qe3 Nxc2 19. Nxc2 Bxc2 20. Bxa8 Rxa8 21. Rac1 Bg6 22. Rfd1 e4 23. Nd2 Qxb2 24. Nxe4 Be5 25. Qf3 Qxa2 26. Rd7 Bb2 27. Rc2 Qb1+ 28. Rd1 Qxc2 29. Rd2 Qxe4 30. Qxe4 Bxe4 31. Rxb2 b5 32. f3 Bd5 33. Rc2 c4 34. b4 g5 35. Rd2 Be6 36. g4 Kg7 37. Kg2 Kg6 38. Kg3 f5 39. Rd6 Kf6 40. f4 fxg4 41. hxg4 Ke7 42. Rc6 Bf7 43. Rxh6 gxf4+ 44. Kxf4 Rg8 45. g5 c3 46. Rc6 Be8 47. Rxc3 Rf8+ 48. Kg4 Bd7+ 49. Kh5 Rf4 50. g6 Rxb4 51. g7 Be6 52. g8=Q Bxg8 53. Rc7+ Kd6 54. Rxa7 Rc4 55. Rb7 b4 56. Rg7 Be6 57. Rb7 Kc6 58. Re7 Bd5 59. Re8 b3 60. Rb8 Rc3 61. Rc8+ Kb5 62. Rxc3 Kb4 63. Rxb3+ Bxb3 1/2-1/2",
    B: "1050",
  },
  {
    W: "1610",
    T: "900+15",
    P: "1. Nf3 d5 2. d4 c5 3. c3 e6 4. e3 Nf6 5. b3 Be7 6. h3 O-O 7. Bd3 a6 8. O-O Nbd7 9. c4 dxc4 10. Bxc4 cxd4 11. exd4 b5 12. Bd3 Bb7 13. Ne5 Qc7 14. Bf4 Qb6 15. Be3 Qd6 16. Nc3 Nxe5 17. dxe5 Qxe5 18. Qd2 Rad8 19. Qc2 g6 20. Rfe1 Qh5 21. Kh2 Qe5+ 0-1",
    B: "1632",
  },
  {
    W: "1410",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Nxe4 4. Qe2 Nf6 5. Nc6+ Ne4 6. Qxe4+ Be7 7. Nxd8 1-0",
    B: "1260",
  },
  {
    W: "1021",
    T: "900+15",
    P: "1. e4 e5 2. Qf3 Nc6 3. Nh3 d6 4. Bb5 Bd7 5. Nc3 Nd4 6. Rb1 Nxf3+ 0-1",
    B: "1016",
  },
  {
    W: "1119",
    T: "900+15",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qc5 4. Ne4 Qd5 5. Nc3 Qe5+ 6. Nce2 c5 7. Nf3 Qe6 8. b3 b5 9. d4 Nd7 10. a4 bxa4 11. Rxa4 a5 12. Ng5 Qf5 13. h4 h6 14. Ng3 Qf6 15. N3e4 Qf5 16. Qe2 hxg5 17. Nxg5 f6 18. Rc4 e5 19. Ne4 Nb6 20. Rc3 exd4 21. Nxc5+ Be7 22. Rd3 a4 23. Rxd4 axb3 24. cxb3 Qe5 25. Re4 Qxc5 26. Rxe7+ Nxe7 27. Qd2 Ra1 28. b4 Qc4 29. h5 Qc7 30. b5 Rxc1+ 31. Qxc1 Qxc1+ 32. Ke2 O-O 33. f4 Nf5 34. Rg1 Bb7 35. g4 Qc2+ 36. Ke1 Re8+ 37. Be2 Qxe2# 0-1",
    B: "1212",
  },
  {
    W: "1679",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nc6 3. c3 Nf6 4. Bd3 Bc5 5. b4 Bb6 6. b5 Nb8 7. Nxe5 d6 8. Nf3 O-O 9. h3 Re8 10. Qb3 Be6 11. Qc2 d5 12. e5 Bd7 13. Bb2 a6 14. bxa6 Nxa6 15. Na3 Nc5 16. O-O Ba4 17. Qb1 Nfd7 18. c4 Ne6 19. cxd5 Nf4 20. Bxh7+ Kf8 21. Qf5 Ne2+ 22. Kh1 Nxe5 23. Nxe5 Re7 24. Nac4 Bc5 25. Ng6+ Ke8 26. Nxe7 Bxe7 27. Rae1 Bd7 28. Qe5 Rxa2 29. Rxe2 b5 30. Qxg7 bxc4 31. Qg8# 1-0",
    B: "1856",
  },
  {
    W: "1880",
    T: "900+15",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bc4 e6 4. O-O a6 5. d4 cxd4 6. Nxd4 b5 7. Nxc6 Qc7 8. Bd3 Qxc6 9. c3 Bb7 10. Re1 Nf6 11. Nd2 Bd6 12. h3 h5 13. Nf3 Bc7 14. e5 Nd5 15. Be4 O-O-O 16. Ng5 f5 17. Bf3 Rdg8 18. Qe2 Rh6 19. h4 Rg6 20. Bxh5 Nf4 21. Qf3 Nxh5 22. Qxc6 Bxc6 23. Be3 Bxe5 24. f3 Bg3 25. Red1 Bxh4 26. Nf7 Bxf3 27. Rd2 Bd5 28. Rad1 Ng3 29. Rxd5 exd5 30. Rxd5 Ne2+ 31. Kf1 Ng3+ 32. Kg1 Re6 33. Nd6+ Kc7 34. Nxb5+ axb5 35. Bf4+ Kd8 36. Kh2 Nf1+ 37. Kg1 Re1 38. Rxb5 Nd2+ 39. Kh2 Rh8 40. Rb8+ Ke7 41. Rxh8 Nf1+ 42. Kh3 Bf6 43. Bg3 Nxg3 44. Kxg3 Re2 45. Ra8 Rxb2 46. c4 Rc2 47. a4 Rxc4 48. a5 Kf7 49. a6 d5 50. Rb8 Ra4 51. Rb7+ Kg6 52. Ra7 d4 53. Ra8 d3 54. a7 Bd4 55. Rd8 Bxa7 56. Rxd3 f4+ 57. Kf3 Be3 58. Rd6+ Kf5 59. g3 fxg3 60. Rd5+ Ke6 61. Rg5 Bxg5 0-1",
    B: "1937",
  },
  {
    W: "1434",
    T: "900+15",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nf6 5. Nc3 Bb4 6. Bd2 Bxc3 7. Bxc3 Nxe4 8. Qe2 O-O 9. O-O-O Nxc3 10. bxc3 Re8 11. Qd3 Ne5 12. Qd2 d5 13. h3 Qd7 14. Bd3 c5 15. f4 Nxd3+ 16. cxd3 cxd4 17. cxd4 Re6 18. Rhe1 Rc6+ 19. Kb1 Rb6+ 20. Ka1 Qa4 21. Qc2 Qxd4+ 22. Qb2 Qxb2# 0-1",
    B: "1443",
  },
  {
    W: "2002",
    T: "300+3",
    P: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd6 4. g3 Nf6 5. Bg2 c6 6. Nge2 e5 7. d3 Bg4 8. h3 Bh5 9. O-O Be7 10. Be3 Nbd7 11. a3 O-O 12. b4 a5 13. b5 a4 14. bxc6 bxc6 15. Qd2 Rfb8 16. g4 Bg6 17. Ng3 Rb2 18. h4 h6 19. Qc1 Rbb8 20. h5 Bh7 21. g5 hxg5 22. Bxg5 Nc5 23. h6 gxh6 24. Bxh6 Kh8 25. Qg5 Rg8 26. Qh4 Rg4 27. Qh3 Rag8 28. Be3 Ne6 29. Kh2 Nf4 30. Bxf4 exf4 31. Bf3 fxg3+ 32. fxg3 Rxg3 33. Ne4 Rxh3+ 34. Kxh3 Nxe4 35. Bxe4 Qg3# 0-1",
    B: "1994",
  },
  {
    W: "2103",
    T: "180+0",
    P: "1. e4 e5 2. Nc3 Nc6 3. d3 Bc5 4. Be3 d6 5. Bxc5 dxc5 6. Be2 f5 7. Qd2 Nf6 8. exf5 Bxf5 9. h3 c4 10. O-O-O cxd3 11. Bxd3 Bxd3 12. Qxd3 Qxd3 13. Rxd3 O-O 14. f3 Rad8 15. Rxd8 Rxd8 16. Nge2 Nd4 17. Rd1 Nxe2+ 18. Nxe2 Rxd1+ 19. Kxd1 Kf7 20. Kd2 Ke6 21. Kd3 Nd5 22. a3 c5 23. c4 Nf4+ 24. Nxf4+ exf4 25. Ke4 g5 26. b4 b6 27. b5 h6 28. h4 Kf6 29. hxg5+ Kxg5 30. Kd5 Kh4 31. Kc6 Kg3 32. Kb7 Kxg2 33. Kxa7 Kxf3 34. Kxb6 Kg2 35. Kxc5 f3 36. b6 f2 37. b7 f1=Q 38. b8=Q Qf2+ 39. Kc6 Qf3+ 40. Kb6 Qb3+ 41. Kc7 Qxa3 42. Qg8+ Qg3+ 43. Qxg3+ Kxg3 44. Kd7 h5 45. c5 h4 46. c6 h3 47. c7 h2 48. c8=Q h1=Q 49. Qg8+ Kf4 50. Qf7+ Ke3 51. Qb3+ Kf4 52. Qc4+ Kg5 53. Qc5+ Kf4 54. Qd6+ Ke3 55. Qe5+ Qe4 56. Qg5+ Qf4 57. Qg1+ Qf2 58. Qc1+ Qd2+ 59. Qxd2+ Kxd2 1/2-1/2",
    B: "2100",
  },
  {
    W: "2568",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Nc3 g6 4. a3 Bg7 5. g3 Nge7 6. Bg2 O-O 7. O-O d6 8. d3 f5 9. exf5 Nxf5 10. Nd2 Kh8 11. f4 exf4 12. Rxf4 Be5 13. Rf1 Bd7 14. Nde4 Qe7 15. Bg5 Bd4+ 16. Kh1 Qg7 17. Qd2 h6 18. Bf4 g5 19. Bxg5 hxg5 20. Nxg5 Qh6 21. Nce4 Kg7 22. c3 Be3 23. Qe2 Bxg5 24. Qg4 Ne3 25. Qxd7+ Kh8 26. Rf7 Nxg2 27. Nxg5 Qxg5 28. Rh7+ Kg8 29. Kxg2 Ne5 30. Qxc7 Qg4 31. Re1 Qf3+ 32. Kh3 Qf5+ 33. Kg2 Qxh7 34. Qxh7+ Kxh7 35. d4 Ng6 36. Re7+ Nxe7 37. h4 Rac8 38. Kh3 d5 39. g4 Rf3+ 40. Kg2 Rcf8 41. g5 Rf2+ 42. Kg3 R8f3+ 43. Kg4 Kg6 44. h5+ Kg7 45. g6 Kh6 46. g7 Rf4+ 47. Kg3 Kxg7 48. c4 Kh6 49. c5 Nf5+ 50. Kh3 R2f3+ 51. Kg2 Rg3+ 52. Kh2 Rf2+ 53. Kh1 Rd3 54. Kg1 Re2 0-1",
    B: "2657",
  },
  {
    W: "2008",
    T: "60+0",
    P: "1. g3 h5 2. Bg2 h4 3. d3 hxg3 4. fxg3 e6 5. Nc3 c6 6. Nf3 d5 7. Bg5 f6 8. Be3 Nh6 9. Qd2 Nf5 10. Bf2 Nd7 11. O-O-O Nb6 12. e4 Ne7 13. exd5 cxd5 14. Rhe1 e5 15. h4 d4 16. Ne4 Be6 17. Kb1 Qc7 18. Re2 O-O-O 19. Bf1 Kb8 20. Ne1 Ned5 21. Ng2 Bb4 22. Qc1 Qc6 23. a3 Be7 24. c3 Bc5 25. cxd4 Bxd4 26. Bxd4 exd4 27. Nf4 Nxf4 28. gxf4 Qd5 29. b4 Rc8 30. Qb2 Rc7 31. Ng3 Rhc8 32. f5 Bf7 33. Re4 Rc3 34. Bg2 Rb3 35. Re7 Rxb2+ 36. Kxb2 Qb3+ 37. Ka1 Qa2# 0-1",
    B: "2098",
  },
  {
    W: "1523",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 d6 5. a3 Na5 6. b4 Nxc4 7. bxc5 Na5 8. cxd6 Qxd6 9. O-O Bg4 10. h3 Bxf3 11. Qxf3 Nf6 12. Bg5 h6 13. Bxf6 gxf6 14. Nc3 O-O-O 15. Nd5 Qc6 16. c4 Nb3 17. Rad1 Nd4 18. Qxf6 Qxf6 19. Nxf6 Rd6 20. Ng4 h5 21. Nxe5 f5 22. Nf7 fxe4 23. Nxh8 Ne2+ 24. Kh1 Rd8 25. Rfe1 exd3 26. g3 Rxh8 27. Rxd3 Re8 28. Re3 Nxg3+ 29. fxg3 Rg8 30. Kh2 b6 31. Rd1 Kb7 32. Re5 Rh8 33. Rdd5 a5 34. Rxh5 Re8 35. Rhe5 Rf8 36. Kg2 Kc6 37. h4 Rh8 38. Kh3 Rh6 39. g4 a4 40. g5 Rh5 41. Kg4 Rh8 42. h5 Rf8 43. g6 Rf2 44. Kg5 Rg2+ 45. Kh6 Ra2 46. g7 Rxa3 47. g8=Q Rc3 48. Qe8+ Kb7 49. Re7 Rxc4 50. Qd8 a3 51. Rd4 Rc6+ 52. Kg7 a2 53. Ra4 Rc2 54. Rxa2 Rxa2 55. Qxc7+ Ka6 56. Qb7+ Kb5 57. Re5+ Kc4 58. Qe4+ Kb3 59. Qd5+ Kb2 60. Re2+ Ka1 61. Qd1# 1-0",
    B: "1471",
  },
  {
    W: "1439",
    T: "180+0",
    P: "1. e4 d5 2. e5 Nc6 3. d4 f5 4. Nf3 e6 5. Ng5 h6 6. Nf3 g5 7. h3 h5 8. Bxg5 Be7 9. Bxe7 Qxe7 10. h4 Nh6 11. Nc3 Ng4 12. Ng5 Nxd4 13. Qxd4 Bd7 14. f3 Nh6 15. O-O-O c5 16. Qd2 b5 17. Bxb5 Bxb5 18. Nxb5 Rb8 19. Nd6+ Kd7 20. Qa5 Qd8 21. Qxd8+ Rhxd8 22. Ndf7 Nxf7 23. Nxf7 Rg8 24. Ng5 Rb5 25. g3 Ra5 26. a3 Ra4 27. f4 Rc4 28. Rd3 Rb8 29. Rc3 Rxc3 30. bxc3 c4 31. Kd2 Rb6 32. Kc1 Ra6 33. Kb2 Rb6+ 34. Ka2 a5 35. Rb1 Rc6 36. Rb7+ Kc8 37. Rb1 Rc5 38. Nxe6 Rc6 39. Ng7 Rg6 40. Nxf5 Rxg3 41. Nxg3 Kd7 42. Nxh5 Ke7 43. Rb7+ Ke6 44. Nf6 Kf5 45. Nxd5 Ke4 46. Nb6 Kxf4 47. Nxc4 Kf3 48. e6 Kg3 49. e7 Kxh4 50. e8=Q Kg3 51. Qg8+ Kf2 52. Rf7+ Ke2 53. Qe8+ Kd1 54. Rd7+ Kc1 55. Qc8 Kxc2 56. Qb8 Kxc3 57. Qb4+ Kc2 0-1",
    B: "1209",
  },
  {
    W: "1113",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Bc5 3. Nxe5 Nf6 4. Bc4 d6 5. Nxf7 Bxf2+ 6. Ke2 Qe7 7. Nxh8 Nxe4 8. Bf7+ Kd8 9. Rf1 Nc3+ 10. Kxf2 Nxd1+ 11. Rxd1 Qf6+ 12. Kg1 Qd4+ 13. Kh1 Bg4 14. Re1 Kd7 15. Nc3 Nc6 16. Nd5 Rxh8 17. Re7+ Nxe7 18. Nxe7 0-1",
    B: "1135",
  },
  {
    W: "1720",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nxd4 5. Qxd4 h6 6. Bf4 1/2-1/2",
    B: "1762",
  },
  {
    W: "1035",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 e6 3. d3 Qc7 4. Be2 d6 5. Nc3 Nf6 6. Be3 Nbd7 7. O-O Be7 8. Nb5 Qb8 9. d4 cxd4 10. Bxd4 a6 11. Nc3 b5 12. e5 dxe5 13. Be3 O-O 14. a4 bxa4 15. Rxa4 Qxb2 16. Bd2 Bb4 17. Nb1 Bxd2 18. Qxd2 Bb7 19. Nc3 Bxf3 20. gxf3 Rfc8 21. Bxa6 Rxc3 22. Rc1 Rxa6 23. Rxa6 Qxc1+ 24. Qxc1 Rxf3 25. Ra8+ Nf8 26. Qa3 Nd7 27. c4 Rxa3 28. Rxa3 Nc5 29. Ra8 Nb7 30. Ra7 Nc5 31. f4 Ne4 32. fxe5 Nd2 33. c5 Nc4 34. c6 Nxe5 35. c7 Ned7 36. c8=Q 1-0",
    B: "1068",
  },
  {
    W: "1822",
    T: "180+0",
    P: "1. e4 d5 2. Nc3 c6 3. exd5 Nf6 4. d4 Nxd5 5. Nf3 Nxc3 6. bxc3 Qd5 7. Ba3 Bg4 8. c4 Qh5 9. Be2 Nd7 10. Qd3 Bf5 11. Qb3 Qg6 12. Qxb7 Rb8 13. Qxc6 Bxc2 14. O-O Be4 15. Qxg6 1-0",
    B: "1847",
  },
  {
    W: "2032",
    T: "180+0",
    P: "1. d4 d5 2. c4 c6 3. Nf3 Bg4 4. Nc3 Bxf3 5. exf3 e6 6. Qb3 Qb6 7. c5 Qxb3 8. axb3 Nd7 9. Bf4 Ngf6 10. h3 Be7 11. Bd3 O-O 12. O-O Nh5 13. Bh2 a6 14. Na4 g6 15. Rfe1 Bd8 16. Bd6 Re8 17. f4 Be7 18. Bxe7 Rxe7 19. f5 Rae8 20. fxg6 fxg6 21. Re3 Ng7 22. g4 Rf7 23. f3 e5 24. Rae1 Kf8 25. dxe5 Ne6 26. b4 Nf4 27. e6 Rfe7 28. exd7 Rxe3 29. dxe8=Q+ Rxe8 30. Rxe8+ Kxe8 31. Bf1 Ke7 32. Nb6 Kf6 33. Kf2 Ke5 34. Kg3 g5 35. Nd7+ Kd4 36. Nf6 h6 37. Ng8 h5 38. gxh5 Nxh5+ 39. Kg4 Nf4 40. Kxg5 Ne6+ 41. Kf6 Nf4 42. h4 Nh5+ 43. Kg5 Nf4 44. Kxf4 1-0",
    B: "1988",
  },
  {
    W: "2052",
    T: "180+0",
    P: "1. Nc3 e5 2. e4 Nc6 3. f4 exf4 4. Nf3 g6 5. Bc4 Nh6 6. O-O Bg7 7. d4 g5 8. Nxg5 Qxg5 9. Bxf4 Qg4 10. Bxh6 Qxd1 11. Bxf7+ Kf8 12. Bxg7+ Kxg7 13. Raxd1 Rf8 14. Bb3 Rxf1+ 15. Kxf1 d6 16. Nd5 Bg4 17. Rd2 Rf8+ 18. Rf2 Rxf2+ 19. Kxf2 Nxd4 20. Nxc7 Nxb3 21. axb3 Kf6 22. c3 a6 23. g3 Bd1 24. b4 Ke5 25. Ke3 Bc2 26. Ne8 Bb1 27. h4 b6 28. Nc7 a5 29. bxa5 bxa5 30. Nb5 Bc2 31. b4 a4 32. c4 Bb3 33. c5 dxc5 34. bxc5 Bc2 35. c6 Bxe4 36. c7 Bf5 37. Nd4 Bc8 38. Nc6+ Kd6 39. Na7 1-0",
    B: "2050",
  },
  {
    W: "2063",
    T: "180+0",
    P: "1. e4 e5 2. Bc4 Nc6 3. Nc3 Bc5 4. d3 d6 5. f4 Bxg1 6. Rxg1 Qh4+ 7. g3 Qxh2 8. Be3 Nf6 9. Qe2 Qh3 10. O-O-O Bg4 11. Qf1 Qxf1 12. Rdxf1 a6 13. Nd5 Nxd5 14. Bxd5 Be6 15. Bxc6+ bxc6 16. fxe5 dxe5 17. b3 f6 18. Bc5 Kf7 19. g4 h6 20. Kd2 g5 21. Ke3 h5 22. gxh5 Rxh5 23. Rh1 Rah8 24. Rxh5 Rxh5 25. Rf2 Rh3+ 26. Kd2 g4 27. Kc3 g3 28. Rf1 g2 29. Rg1 Rg3 30. Ba7 Bh3 31. Kc4 Rf3 32. Kb4 f5 33. exf5 Rxf5 34. Ka5 e4+ 35. Kxa6 exd3 36. cxd3 Rf3 37. Kb7 Rxd3 38. Kxc7 Rc3 39. b4 Ke6 40. a4 Kf5 41. a5 Kf4 42. Bc5 Kf3 43. Kxc6 Bc8 44. b5 Be6 45. b6 Bf5 46. b7 Be4+ 47. Kb6 Rb3+ 48. Kc7 Rxb7+ 49. Kd6 Rb3 50. a6 Rd3+ 51. Ke5 Rb3 52. a7 Rb7 53. Rxg2 Kxg2 54. Kxe4 Rxa7 55. Bxa7 1/2-1/2",
    B: "2242",
  },
  {
    W: "1663",
    T: "180+0",
    P: "1. d4 d5 2. e3 c5 3. f4 a6 4. c3 c4 5. Nd2 b5 6. e4 e6 7. exd5 exd5 8. Be2 Nf6 9. Ngf3 Bd6 10. g3 O-O 11. Ne5 Nbd7 12. O-O Nb6 13. Nc6 Qd7 14. Ne5 Qh3 15. Bf3 Bxe5 16. fxe5 Ng4 17. Qe2 f5 18. Bxg4 fxg4 19. Rxf8+ Kxf8 20. Qf1+ Kg8 21. Qxh3 gxh3 22. Nf1 Bg4 23. Bg5 Rf8 24. Be7 Rf5 25. Ne3 Rf3 26. Nxg4 Nd7 27. Rf1 Rxf1+ 28. Kxf1 Kf7 29. Bd6 Ke6 30. Nf2 Nf8 31. Nxh3 Ng6 32. Nf4+ Kf5 33. e6 Nxf4 34. gxf4 Kxe6 35. Be5 Kf5 36. Bxg7 Kxf4 37. Ke2 h5 38. Be5+ Ke4 39. Bg3 a5 40. a3 a4 41. Be5 h4 42. Bf6 h3 43. Be5 Kf5 44. Kf3 Ke6 45. Kf4 Kd7 46. Kf5 Kc6 47. Ke6 Kb6 48. Kxd5 Kb7 49. Kc5 Ka6 50. d5 Ka5 51. Bc7+ Ka6 52. d6 Kb7 53. Kxb5 Kc8 54. Kxc4 Kd7 55. Kb4 Kc6 56. Kxa4 Kd7 57. c4 Kc6 58. c5 Kd7 59. c6+ Kxc6 0-1",
    B: "1535",
  },
  {
    W: "1500",
    T: "180+2",
    P: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Nf3 e6 5. Nc3 Nd7 6. Bd3 Bxd3 7. Qxd3 Qb6 8. O-O c5 9. Na4 Qb4 10. Nxc5 Nxc5 11. dxc5 Bxc5 12. a3 Qb6 13. b4 Be7 14. Be3 Qc7 15. Rac1 Nh6 16. Bxh6 gxh6 17. c4 Qd7 18. cxd5 Qxd5 19. Qxd5 exd5 20. Rfd1 Rd8 21. Rc7 O-O 22. Rxe7 d4 1-0",
    B: "1438",
  },
  {
    W: "1400",
    T: "180+2",
    P: "1. d4 d5 2. c4 dxc4 3. Nc3 c6 4. e3 Be6 5. Nf3 Nf6 6. b3 cxb3 7. axb3 Nbd7 8. Bc4 Bxc4 9. bxc4 e6 10. O-O Bd6 11. Re1 O-O 12. Ba3 Bxa3 13. Rxa3 c5 14. d5 exd5 15. cxd5 b6 16. e4 Re8 17. e5 Nh5 18. Qd2 g6 19. Nb5 a5 20. d6 Nb8 21. Nc7 Nc6 22. Nxa8 Qb8 23. Nc7 a4 24. Nd5 Nd4 25. Nxd4 Nf4 26. Nf6+ Kg7 27. Nxe8+ Kf8 28. Qxf4 Qxd6 29. Nxd6 g5 30. Qxf7# 1-0",
    B: "1382",
  },
  {
    W: "1617",
    T: "180+2",
    P: "1. e4 g6 2. d4 Bg7 3. Nf3 b6 4. g3 Bb7 5. Nc3 e6 6. Bg2 d6 7. O-O Nd7 8. Re1 Ndf6 9. d5 Nh6 10. dxe6 fxe6 11. Bg5 Qd7 12. Bxf6 Bxf6 13. e5 Bg5 14. Nxg5 Bxg2 15. Kxg2 O-O 16. f4 Rad8 17. exd6 Qg7 18. Nxe6 Qd7 19. Nxf8 Qf5 20. Qd5+ Kh8 21. Qxf5 Nxf5 22. Ne6 1-0",
    B: "1635",
  },
  {
    W: "2055",
    T: "180+2",
    P: "1. e4 c5 2. c4 d6 3. Nc3 Nf6 4. g3 Nc6 5. Bg2 g6 6. Nge2 Bg7 7. O-O O-O 8. f4 Bg4 9. h3 Bxe2 10. Nxe2 Nd4 11. g4 Nd7 12. f5 e6 13. Ng3 Qh4 14. Kh2 Be5 15. Rf3 Nxf3+ 16. Qxf3 exf5 17. exf5 gxf5 18. gxf5 Kh8 19. d3 Rg8 0-1",
    B: "2094",
  },
  {
    W: "1261",
    T: "60+0",
    P: "1. c4 d5 2. cxd5 Qxd5 3. Nc3 Qd7 4. Nf3 e5 5. g3 Qc6 6. Nxe5 Qxh1 7. e3 Nc6 8. Qc2 Nxe5 9. d4 Nf3+ 10. Ke2 Nf6 11. Nb5 Bd6 12. e4 Bg4 13. Ke3 Qxf1 14. Bd2 Bf4+ 15. Kxf4 Qxa1 16. Qxc7 O-O 17. Nd6 Qxa2 18. Bc3 Qe6 19. d5 Qd7 20. Qxd7 Nxd7 21. Bxg7 Kxg7 22. Nxb7 Rab8 23. Nc5 Rb5 24. Nd3 Nc5 25. Nc1 Nb3 26. Ne2 Nfd4 27. Nxd4 Nxd4 28. f3 Rxb2 29. fxg4 Rb4 30. g5 Nc2 31. g6 1-0",
    B: "1273",
  },
  {
    W: "1312",
    T: "60+0",
    P: "1. g4 d5 2. Bg2 c6 3. g5 Bf5 4. c4 e6 5. Nh3 Bxh3 6. Bxh3 Qxg5 7. cxd5 Qxd5 8. Rg1 Qd6 9. Qb3 b6 10. Bg2 Qxh2 11. Kf1 Qd6 12. d3 Nf6 13. Nc3 Be7 14. Ne4 Nxe4 15. Bxe4 O-O 16. Rg3 f5 17. Bg2 Bf6 18. Rf3 b5 19. Bf4 Be5 20. Bxe5 Qxe5 21. Rc1 a6 22. Qb4 1-0",
    B: "1309",
  },
  {
    W: "1555",
    T: "60+0",
    P: "1. d4 e6 2. h4 d5 3. h5 c5 4. h6 g6 5. Nf3 Nxh6 6. Bxh6 Be7 7. c3 cxd4 8. Qxd4 Nc6 9. Qxh8+ Kd7 10. Ne5+ Nxe5 11. Qxe5 Qa5 12. Nd2 Bd6 13. Qf6 Qa4 14. Nb3 Qe4 15. Qxf7+ Be7 16. Bg5 Kd6 17. Qxe7+ Ke5 18. Bf6+ Kf5 19. g4+ Kxg4 20. Bh3+ Kf4 21. Bg5+ Ke5 22. Bf6+ Kf4 23. e3+ Kf3 24. Nd2# 1-0",
    B: "1439",
  },
  {
    W: "1765",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e6 5. Nc3 Bb4 6. Bb5 Nf6 7. f3 d5 8. exd5 Bxc3+ 9. bxc3 exd5 10. Nxc6 bxc6 11. Bxc6+ Bd7 12. Bxa8 Qxa8 13. O-O O-O 14. Bg5 Qc6 15. Bxf6 gxf6 16. Qd3 Rc8 17. Rad1 Be6 18. Rfe1 f5 19. Rd2 Qa4 20. Qd4 Qxa2 21. Qf6 Qc4 22. Red1 Qxc3 23. Qxc3 Rxc3 24. Ra1 Rc7 25. Ra5 Kg7 26. Kf2 Kf6 27. f4 Kg6 28. g3 Kh5 29. Ke3 Kg4 30. Kd4 Rc4+ 31. Ke5 Rc7 32. c4 dxc4 0-1",
    B: "1978",
  },
  {
    W: "2113",
    T: "60+0",
    P: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Nc3 Qc7 6. Be3 Bb4 7. Be2 Bxc3+ 8. bxc3 Qxc3+ 9. Kf1 Nf6 10. f3 Qd3 11. Qxd3 1-0",
    B: "2163",
  },
  {
    W: "915",
    T: "60+0",
    P: "1. e4 d6 2. Nf3 h5 3. Bc4 Bg4 4. h3 Bxf3 5. gxf3 c5 6. h4 b5 7. Bxb5+ Nd7 8. c4 Qb8 9. Qa4 a6 10. Bxd7+ Kd8 11. Qa5+ Kxd7 12. Qa3 g6 13. Nc3 Bh6 14. Nd5 Nf6 15. Qa5 Nxd5 16. e5 Nc7 17. d4 Nb5 18. Bxh6 Rxh6 0-1",
    B: "956",
  },
  {
    W: "2405",
    T: "60+0",
    P: "1. d4 c5 2. c4 cxd4 3. Qxd4 Nc6 4. Qd1 g6 5. Nc3 Bg7 6. e4 Nf6 7. f3 d6 8. Bg5 O-O 9. Nge2 Re8 10. Qd2 Be6 11. b3 a6 12. Rc1 b5 13. cxb5 axb5 14. Nxb5 h6 15. Be3 Ne5 16. Ned4 Bd7 17. Be2 Rf8 18. O-O Qb8 19. Bxh6 Bxh6 20. Qxh6 Rxa2 21. f4 Neg4 22. Bxg4 Nxg4 23. Qg5 Ne3 24. Rf3 Rxg2+ 25. Qxg2 Nxg2 26. Kxg2 Bxb5 27. f5 e5 28. fxe6 fxe6 29. Nxe6 Rxf3 30. Kxf3 Qe8 31. Nd4 Qf7+ 32. Ke3 Ba6 33. Rc6 Bb7 34. Rxd6 Qe7 35. Rxg6+ Kh7 36. Rg4 Qb4 37. Nf5 Qc3+ 38. Kf4 Qd2+ 39. Ke5 Qxh2+ 40. Kf6 Qb2+ 41. e5 Qxb3 42. Rh4+ Kg8 43. Ne7+ Kf8 44. Rh8+ Qg8 45. Rxg8# 1-0",
    B: "2499",
  },
  {
    W: "1168",
    T: "60+0",
    P: "1. d4 e6 2. e3 c5 3. Nf3 cxd4 4. exd4 Nc6 5. Nc3 d5 6. Be3 Nf6 7. Ne5 Bb4 8. Bd2 Nxd4 9. Nb5 Bd7 10. Bxb4 Bxb5 11. Bxb5+ Nxb5 12. Nd3 a5 13. a3 axb4 14. axb4 Rxa1 15. Qxa1 O-O 16. Qa5 Nd4 17. c3 Qxa5 18. bxa5 Nc2+ 19. Kd2 Ne4+ 20. Kxc2 Rc8 21. Re1 f5 22. f3 d4 23. fxe4 fxe4 24. Nb4 dxc3 25. a6 bxa6 26. Nxa6 g6 27. bxc3 e5 28. Rd1 1-0",
    B: "1238",
  },
  {
    W: "2105",
    T: "60+0",
    P: "1. c4 c6 2. Nc3 d5 3. cxd5 cxd5 4. d4 Bf5 5. g3 Nf6 6. Bg2 e6 7. e3 Bb4 8. Ne2 Nc6 9. O-O Rc8 10. f3 O-O 11. e4 dxe4 12. fxe4 Bxc3 13. bxc3 Bg4 14. Bg5 h6 15. Bf4 Na5 16. h3 Bxe2 17. Qxe2 Rxc3 18. Rab1 b6 19. Bd2 Qxd4+ 20. Kh1 Rxg3 21. Bxa5 bxa5 22. Rbd1 Qe5 23. Qf2 Nxe4 24. Qxa7 Rxh3+ 25. Bxh3 Ng3+ 26. Kg2 Nxf1 27. Rxf1 Qe2+ 28. Rf2 Qe4+ 29. Rf3 Rd8 30. Qxf7+ Kh8 31. Bxe6 Rd2+ 32. Kg3 Qe5+ 33. Rf4 Rd3+ 34. Kg4 Qe2+ 35. Kf5 Qh5+ 36. Ke4 Qxf7 37. Bxf7 Rd7 38. Bg6 Re7+ 39. Kf3 Rb7 40. Rf8# 1-0",
    B: "2090",
  },
  {
    W: "1738",
    T: "60+0",
    P: "1. e4 e5 2. Bc4 Nc6 3. Qh5 g6 4. Qf3 Nf6 5. Qb3 Qe7 6. Ne2 Bg7 7. d3 O-O 8. Bg5 h6 9. Bxf6 Qxf6 10. Nd2 d6 11. O-O-O Re8 12. g3 Be6 13. f4 Bxc4 14. Nxc4 exf4 15. Rhf1 a5 16. Rxf4 Qe7 17. Ne3 a4 18. Qc3 Bxc3 19. Nxc3 Nb4 20. a3 Nc6 21. Ncd5 Qg5 22. Nf6+ Kg7 23. Nxe8+ Rxe8 24. Nd5 Qd8 25. Rdf1 g5 26. Rxf7+ Kg6 27. R1f6+ Kh5 28. g4+ Kxg4 29. Rf1 Kh5 30. h4 Kxh4 31. Rh1+ Kg4 32. Ne3+ Kg3 33. Rg1+ Kh4 34. Rh7 Kh5 35. Rh1+ Kg6 36. R7xh6+ Kf7 37. Rh7+ Ke6 38. R1h6+ Ke5 39. d4+ Kxe4 40. c3 Kxe3 0-1",
    B: "1746",
  },
  {
    W: "1934",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. Bb5+ c6 7. dxc6 bxc6 8. Be2 Be7 9. O-O O-O 10. Nc3 Nd5 11. Nf3 Bg4 12. h3 Bh5 13. Nxe5 Bxe2 14. Nxe2 Bf6 15. Nd3 Re8 16. Nef4 Nb4 17. Nxb4 Nc4 18. c3 Qd6 19. d3 Ne5 20. d4 Nc4 21. b3 Nb6 22. Nbd3 a5 23. c4 Qxd4 24. Bb2 Qe4 25. Bxf6 gxf6 26. Nh5 Qg6 27. Qg4 Rad8 28. Nxf6+ Kh8 29. Qxg6 fxg6 30. Nxe8 Rxd3 31. Rad1 Rc3 32. Rd2 a4 33. Rb2 axb3 34. axb3 c5 35. Nd6 Nd7 36. Rd1 Ne5 37. Ne4 Rd3 38. Rxd3 Nxd3 39. Rd2 Nb4 40. Rd8+ Kg7 41. Nxc5 Nc2 42. Rd7+ Kf6 43. Rb7 Nb4 44. Rxb4 Ke5 45. Nd3+ Kd4 46. Nf4 Kc3 47. Rb5 Kd4 48. Ne6+ 1-0",
    B: "2046",
  },
  {
    W: "1742",
    T: "180+0",
    P: "1. e4 c5 2. Nf3 Nc6 3. Bb5 Qc7 4. O-O a6 5. Be2 b6 6. d4 Bb7 7. d5 Ne5 8. Be3 Nxf3+ 9. Bxf3 d6 10. c4 Nf6 11. Nc3 e5 12. Bg5 Be7 13. Re1 h6 14. Bh4 Nh7 15. Bg3 O-O 16. Rb1 Ng5 17. Kh1 Bc8 18. a3 Bd7 19. b4 Nxf3 20. Qxf3 Rab8 21. b5 axb5 22. Nxb5 Bxb5 23. Rxb5 Ra8 24. Reb1 Ra6 25. Qb3 Rb8 26. h3 Qa7 27. Ra1 Bd8 28. f4 f6 29. fxe5 fxe5 30. Kh2 Qf7 31. Rb1 Qg6 32. Qf3 Bc7 33. h4 Rf8 34. Qe3 Bd8 35. R1b3 Qg4 36. Qd3 Bxh4 37. Bxh4 Qxh4+ 38. Kg1 Qe1+ 39. Kh2 Qh4+ 40. Qh3 Qxe4 41. Rxb6 Rxb6 42. Rxb6 Qf4+ 43. Kh1 Qf1+ 44. Kh2 Qf4+ 0-1",
    B: "1803",
  },
  {
    W: "1933",
    T: "180+0",
    P: "1. e4 d6 2. Nc3 Nd7 3. Bc4 e5 4. d3 h6 5. a3 c6 6. Ba2 Qc7 7. f4 Ngf6 8. Nf3 Be7 9. h3 Nf8 10. f5 g5 11. g4 N8h7 12. Be3 Rg8 13. Qd2 a6 14. Qf2 c5 15. Nd5 Nxd5 16. Bxd5 f6 17. Bxg8 Kf8 18. Bxh7 1-0",
    B: "1909",
  },
  {
    W: "2017",
    T: "600+0",
    P: "1. c4 Nf6 2. Nc3 c6 3. e4 e6 4. Nf3 d5 5. exd5 exd5 6. d4 Bb4 7. Bd3 dxc4 8. Bxc4 Ne4 9. Qb3 Bxc3+ 10. bxc3 O-O 11. Ne5 Nd6 12. Bd3 Be6 13. Qc2 g6 14. O-O Nd7 15. Bh6 Re8 16. Bxg6 Nxe5 17. Bxh7+ Kh8 18. dxe5 Qh4 19. Qd2 Nc4 20. Qc1 Kxh7 21. Bg5 Qh5 22. Qf4 Rg8 23. h4 Rg6 24. f3 Kg8 25. Rfd1 Bd5 26. Rd4 Re6 27. Rxc4 Bxc4 28. Qxc4 Rxe5 29. Rd1 Rae8 30. Kf2 Qg6 31. Rd2 Kh7 32. g4 f6 33. Rd7+ R8e7 34. Rxe7+ Rxe7 35. Be3 Qc2+ 36. Qe2 Qxc3 37. h5 Qe5 38. Qc2+ Kh8 39. Bc5 Rd7 40. Qg6 Qxc5+ 41. Kg3 Qe5+ 42. Kh3 Qe7 43. Qh6+ Qh7 0-1",
    B: "2082",
  },
  {
    W: "1895",
    T: "30+0",
    P: "1. b3 e5 2. Bb2 Bc5 3. Bxe5 Qh4 4. g3 Qg5 5. Bb2 Qd5 6. e3 Qxh1 7. f3 Qxg1 8. Qe2 Nf6 9. Nc3 b6 10. O-O-O Ba6 11. Bh3 Bxe2 12. Rxg1 Bxf3 13. g4 Bxg4 14. Bxg4 Nxg4 15. Rxg4 h5 16. Rxg7 Rf8 17. Rg1 Nc6 18. Nd5 Ne5 19. Nxc7+ Ke7 20. Nxa8 Rxa8 21. d4 Bxd4 22. exd4 Nf3 23. Rf1 Nxh2 24. Re1+ Kd6 25. Re2 Ng4 26. Rg2 Ne3 27. Rh2 Nd5 28. Rxh5 Nf4 29. Rh6+ Ne6 30. Rf6 Rb8 31. Rxf7 Re8 32. Rf6 Re7 33. d5 Kxd5 34. Rxe6 Rxe6 35. c4+ Kc5 36. Bc3 Re1+ 37. Kb2 1-0",
    B: "1867",
  },
  {
    W: "1802",
    T: "600+0",
    P: "1. e4 e5 2. Nf3 Bc5 3. Nc3 Nf6 4. Nxe5 Qe7 5. d4 Bd6 6. Bf4 h6 7. Nd5 Nxd5 8. exd5 f6 9. Qe2 fxe5 10. Bxe5 Nc6 11. Bxd6 Qxe2+ 12. Bxe2 Nxd4 13. Bh5+ Kd8 14. Be5 Nxc2+ 15. Kd2 Nxa1 16. Bxg7 Rg8 17. Bf6# 1-0",
    B: "1763",
  },
  {
    W: "2186",
    T: "30+0",
    P: "1. d4 b6 2. c4 Bb7 3. Nc3 a6 4. f3 e6 5. e4 Be7 6. d5 Bh4+ 7. g3 Bg5 8. dxe6 fxe6 9. Bxg5 Qxg5 10. Nge2 Qf6 11. Qd2 Qxf3 12. O-O-O Qf7 13. Rg1 Ne7 14. Bg2 O-O 15. Nf4 Nbc6 16. a3 Ng6 17. Nd3 Nge5 18. Nxe5 Nxe5 19. Rge1 Nxc4 20. Qc2 Ne5 21. Kb1 Qg6 22. Rf1 Rxf1 23. Rxf1 Rf8 24. Re1 Rf6 25. Bh1 h6 26. Na4 Qg5 27. Qxc7 Bxe4+ 28. Bxe4 Qd2 29. Qc1 Qxc1+ 30. Rxc1 Nc4 31. Nc3 Nd2+ 32. Kc2 Nxe4 33. Kb3 Nc5+ 34. Ka2 Rf3 35. b4 Rxc3 36. Rxc3 Ne4 37. Rc8+ Kh7 38. Kb3 Kg6 39. Rb8 a5 40. Rxb6 a4+ 41. Kxa4 Nd6 42. Ka5 Nc4+ 43. Kb5 Nxb6 44. Kxb6 e5 45. a4 e4 46. a5 e3 47. a6 e2 48. a7 e1=Q 49. a8=Q Qf2+ 50. Kb7 Qf3+ 0-1",
    B: "2189",
  },
  {
    W: "1813",
    T: "180+2",
    P: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 d6 5. Nf5 Bxf5 6. exf5 Nf6 7. g4 Be7 8. Nc3 h6 9. h4 Qd7 10. Bb5 O-O-O 11. Be3 a6 12. a4 axb5 13. axb5 Nb8 14. Ra8 c5 15. bxc6 Qxc6 16. O-O d5 17. Bf4 Bd6 18. Bxd6 Qxd6 19. Nb5 Qb6 20. Na7+ Kc7 21. Qf3 Ne4 22. Ra1 Nc6 23. Qf4+ Kd7 24. Rxd8+ Rxd8 25. c4 Nxa7 26. cxd5 Qxf2+ 27. Qxf2 Nxf2 28. Kxf2 Nb5 29. Ra5 Nc7 30. Ke3 Kd6 31. Kd4 Re8 32. b4 Re1 33. g5 hxg5 34. hxg5 Rd1+ 35. Kc4 Rxd5 36. Rc5 Rxc5+ 37. bxc5+ Kc6 38. Kd4 f6 39. g6 Nd5 40. Ke4 Kxc5 0-1",
    B: "1867",
  },
  {
    W: "1430",
    T: "180+0",
    P: "1. e4 e5 2. Nf3 Bb4 3. Nc3 d6 4. h3 Bg4 5. hxg4 Nd7 6. a3 Ba5 7. d3 Qe7 8. Bd2 O-O-O 9. Qe2 g6 10. O-O-O f5 11. gxf5 gxf5 12. Nd5 Qe6 13. Bxa5 fxe4 14. dxe4 Ngf6 15. Nxc7 b6 16. Nxe6 1-0",
    B: "1499",
  },
  {
    W: "1512",
    T: "60+0",
    P: "1. d4 d5 2. Nf3 Nf6 3. Bg5 Nbd7 4. c4 dxc4 5. Nc3 Nb6 6. e3 Be6 7. Ne5 g6 8. Qd2 Bg7 9. e4 O-O 10. Bh6 c5 11. d5 Bc8 12. Bxg7 Kxg7 13. Qg5 Nh5 14. g4 Nf6 15. h4 h6 16. Qf4 Nbd7 17. g5 Nxe5 18. Qxe5 Bg4 19. gxh6+ Kxh6 20. h5 Nxh5 21. Be2 f6 22. Qh2 Bxe2 23. Nxe2 Qd6 24. Nf4 Qxf4 25. Qxf4+ 1-0",
    B: "1586",
  },
  {
    W: "1190",
    T: "60+0",
    P: "1. e4 e5 2. Bc4 Nc6 3. Nc3 Nf6 4. Nge2 a6 5. a4 Bb4 6. Na2 d6 7. Nxb4 Bg4 8. Nxc6 bxc6 9. f3 Bh5 10. d4 exd4 11. Nxd4 c5 12. Nc6 Qd7 13. Bd5 Nxd5 14. exd5 O-O 15. O-O Bg6 16. b4 cxb4 17. Nxb4 a5 18. Nd3 Rae8 19. Nf4 Ra8 20. Nxg6 fxg6 21. Qd3 c6 22. c4 c5 23. Bf4 Qa7 24. Bxd6 Rfc8 25. Qe4 Re8 26. Be7 Rxe7 27. Qxe7 Re8 28. Qxe8# 1-0",
    B: "1182",
  },
  {
    W: "1665",
    T: "60+0",
    P: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qd1 d6 5. Bb5 Bd7 6. Nf3 Nf6 7. Nc3 Be7 8. O-O O-O 9. Re1 Ne5 10. Bxd7 Nfxd7 11. Bf4 Bf6 12. Nd5 c6 13. Nxf6+ Qxf6 14. Bxe5 Nxe5 15. Nxe5 dxe5 16. Qd3 Rad8 17. Qb3 b5 18. Rad1 a5 19. Qa3 a4 20. Qc5 Rxd1 21. Rxd1 Rd8 22. Rxd8+ Qxd8 23. h3 Qc7 24. Kf1 g6 25. Ke2 Kg7 26. c4 bxc4 27. Qxc4 Qb6 0-1",
    B: "1676",
  },
  {
    W: "1043",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 Qe7 5. Nc3 c5 6. Ndb5 Bd7 7. Nd5 Qe6 8. Ndc7+ Kd8 9. Nxe6+ Bxe6 10. Bf4 a6 11. Nxd6 Bxd6 12. Qxd6+ Ke8 13. O-O-O Ne7 14. Qd8# 1-0",
    B: "1043",
  },
  {
    W: "1873",
    T: "60+0",
    P: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3 Bc5 5. O-O d6 6. h3 h5 7. d3 Be6 8. Nd5 Qd7 9. c3 Bxh3 10. gxh3 Qxh3 11. Nxf6+ gxf6 12. Re1 Rg8+ 0-1",
    B: "2052",
  },
  {
    W: "1481",
    T: "60+0",
    P: "1. e4 e5 2. Nc3 Nf6 3. Nf3 d5 4. d4 dxe4 5. Nxe5 Be7 6. Nxf7 Kxf7 7. Bc4+ Ke8 8. O-O Nbd7 9. Bg5 Nb6 10. Bxf6 Bxf6 11. Qh5+ g6 12. Bb5+ c6 13. Qe2 cxb5 14. Nxb5 Rf8 15. Rad1 Kf7 16. d5 Kg8 17. d6 Bd7 18. Nd4 Bxd4 19. Rxd4 Bc6 20. Rdd1 Bd5 21. b3 Qxd6 22. c4 Nxc4 23. bxc4 Qf6 24. cxd5 Qd6 25. Qxe4 Rae8 26. Qc4 Rf5 27. Qd3 Ref8 28. f3 Rd8 29. g4 Rxd5 30. Qe4 Rxd1 31. Rxd1 Qxd1+ 32. Kg2 Qd2+ 33. Kg3 Qe1+ 34. Kf4 Qxe4+ 35. Kxe4 Re8+ 36. Kf4 Kf7 37. Kg3 Re1 38. h4 Re2 39. f4 Rxa2 40. g5 Ke6 41. Kg4 Kf7 42. h5 Ra5 0-1",
    B: "1484",
  },
  {
    W: "1326",
    T: "60+0",
    P: "1. e4 g6 2. Nf3 Bg7 3. Nc3 Nf6 4. d4 d6 5. Bg5 Nbd7 6. d5 e6 7. dxe6 fxe6 8. Bxf6 Nxf6 9. Be2 d5 10. exd5 Nxd5 11. Nxd5 Qxd5 12. O-O Bxb2 13. Qxd5 exd5 14. Rad1 c6 15. Bd3 O-O 16. a3 Bxa3 17. Rde1 Bd6 18. Ng5 Bd7 19. c3 Rae8 20. Rxe8 Rxe8 21. h3 Ba3 22. Nf3 Bb2 23. Rb1 Bxc3 24. Rxb7 Re7 25. Rxa7 d4 26. Kh2 Rg7 27. Ra8+ Kf7 28. Rd8 Be6 29. Rd6 Bd5 30. Ne5+ Ke7 31. Rd7+ Kf6 32. f4 Rxd7 33. g3 Re7 34. Kg1 Rxe5 35. Kf2 Rf5 36. g4 Rxf4+ 37. Kg3 Rf3+ 38. Kg2 Rxd3+ 39. Kf2 Rd2+ 40. Ke1 Rc2+ 41. Kd1 Rc1+ 42. Kxc1 Kg5 43. Kc2 Kh4 44. Kd3 Kg3 45. Ke2 Kxh3 46. Kf2 Kxg4 47. Kf1 h5 48. Kg1 g5 0-1",
    B: "1278",
  },
  {
    W: "1854",
    T: "60+0",
    P: "1. b3 e6 2. Bb2 d6 3. e4 Qe7 4. d4 f6 5. Nc3 Bd7 6. f4 Nh6 7. Nf3 Nf7 8. Qe2 a5 9. O-O-O b6 10. e5 d5 11. exf6 gxf6 12. g4 Bg7 13. h4 Nc6 14. Bg2 O-O-O 15. Nb5 Kb8 16. a4 Nb4 17. Kb1 Bxb5 18. Qxb5 Rhe8 19. f5 e5 20. dxe5 Nxe5 21. Nxe5 fxe5 22. c3 c6 23. Qxb6+ Qb7 24. Qxb7+ Kxb7 25. cxb4 axb4 26. g5 d4 27. f6 Bh8 28. Rc1 Rc8 29. Rc5 Rc7 30. Bh3 Rf8 31. Rhc1 h6 32. Bg2 hxg5 33. Bxc6+ Kb6 34. Rb5+ Ka6 35. Bb7+ Rxb7 36. Rxb7 Kxb7 37. hxg5 Kb6 38. Rh1 Bxf6 39. gxf6 Rxf6 40. Rh7 Re6 41. Kc2 e4 42. Kd2 e3+ 0-1",
    B: "1812",
  },
  {
    W: "1258",
    T: "60+0",
    P: "1. e4 e5 2. d4 d6 3. c3 exd4 4. Bc4 dxc3 5. Nf3 cxb2 6. Bxb2 Nf6 7. O-O Be7 8. Re1 O-O 9. e5 dxe5 10. Rxe5 Qxd1+ 11. Re1 Qd8 12. Nc3 Nc6 13. Rad1 Bd7 14. Nb5 a6 15. Nbd4 Nxd4 16. Bxd4 c6 17. Ng5 h6 18. Nh3 Ng4 19. f3 Nf6 20. Bxf6 Bxf6 21. Re4 Re8 22. Rxe8+ Qxe8 23. Kh1 Bxh3 24. gxh3 b5 25. Be2 Rd8 26. Rxd8 Qxd8 27. Bf1 Qd2 28. Bg2 Qxa2 29. Bf1 Bd4 30. Bg2 Qa1+ 31. Bf1 Qxf1# 0-1",
    B: "1278",
  },
  {
    W: "2197",
    T: "60+0",
    P: "1. b3 Nf6 2. Bb2 g6 3. c4 Bg7 4. g3 O-O 5. Bg2 c5 6. Nf3 Nc6 7. O-O e6 8. d4 cxd4 9. Nxd4 d5 10. Nxc6 bxc6 11. Nd2 a5 12. Qc2 a4 13. b4 Ba6 14. a3 Rb8 15. Qxa4 Ra8 16. Qc2 Qb6 17. c5 Qb7 18. Rfe1 Rfe8 19. e4 e5 20. exd5 cxd5 21. Rxe5 Rad8 22. Rae1 Rxe5 23. Rxe5 d4 24. Re1 Qc7 25. Nc4 d3 26. Qb3 Bxc4 27. Qxc4 d2 28. Rd1 Qd7 29. Qc2 Qd3 30. Qa4 Qe2 31. Bf1 Ng4 32. Bxe2 Bxb2 33. Qb3 1-0",
    B: "2073",
  },
  {
    W: "1756",
    T: "600+5",
    P: "1. e4 e5 2. Nf3 Bc5 3. Bc4 Nf6 4. d3 d5 5. exd5 Bxf2+ 6. Kxf2 Ng4+ 7. Kg1 c6 8. h3 Qb6+ 9. d4 e4 10. hxg4 exf3 11. gxf3 O-O 12. c3 Nd7 13. dxc6 Qxc6 14. Qd3 Nf6 15. g5 h6 16. gxf6 Qxf6 17. Nd2 Bf5 18. Ne4 Qg6+ 19. Kf2 a6 20. Rg1 Qh7 21. Be3 Bxe4 22. Qxe4 g6 23. Rh1 h5 24. Rag1 Rae8 25. Qf4 b5 26. Bd5 Rd8 27. Bc6 Rc8 28. d5 b4 29. cxb4 Rb8 30. a3 a5 31. Bc5 Rfc8 32. Re1 axb4 33. axb4 Qg7 34. b3 Qb2+ 35. Re2 Qxb3 36. Rhe1 Qc3 37. Re7 Qg7 38. Bd6 Rb6 39. Re8+ Rxe8 40. Rxe8+ Kh7 41. Be5 Qh6 42. Rh8# 1-0",
    B: "1636",
  },
  {
    W: "1846",
    T: "300+0",
    P: "1. d4 e6 2. c4 d5 3. Nc3 Bb4 4. cxd5 Bxc3+ 5. bxc3 Qxd5 6. Nf3 Nf6 7. e3 Ne4 8. c4 Qa5+ 9. Bd2 Nxd2 10. Qxd2 Qxd2+ 11. Nxd2 b6 12. Be2 Bb7 13. O-O c5 14. dxc5 bxc5 15. Rab1 Bc6 16. Bf3 Bxf3 17. Nxf3 Nd7 18. Rb7 Nb6 19. Ne5 O-O 20. Rd1 f6 21. Nc6 Nxc4 22. Rxa7 Rxa7 23. Nxa7 Ra8 24. Nc6 Rxa2 25. h3 h6 26. Rc1 Ra6 27. Ne7+ Kf7 28. Rxc4 Ra1+ 29. Kh2 Kxe7 30. Rxc5 Kd6 31. Rb5 e5 32. Kg3 Ra6 33. e4 g5 34. f3 h5 35. h4 Ra2 36. hxg5 fxg5 37. Rb8 Ke6 38. Rh8 h4+ 39. Kh3 Kf7 40. Rh7+ Kg6 41. Re7 Kf6 42. Re8 Ra5 43. Rf8+ Kg7 44. Rf5 Kg6 45. Kg4 Ra2 46. Rxg5+ Kf6 47. Rf5+ Ke6 48. Kh3 Rf2 49. Rh5 1-0",
    B: "1725",
  },
  {
    W: "1365",
    T: "300+0",
    P: "1. c4 Nf6 2. Nc3 c6 3. e4 e5 4. d3 d5 5. Be2 d4 6. Nb1 Bb4+ 7. Bd2 Bxd2+ 8. Nxd2 Nbd7 9. h3 a5 10. Qc2 Nc5 11. O-O-O Qd6 12. Rf1 b5 13. b3 a4 14. g3 axb3 15. Nxb3 Nxb3+ 16. Qxb3 O-O 17. f4 Ra3 18. fxe5 Qxe5 19. Qb4 Rc3+ 20. Kd2 Qg5+ 21. Kd1 Rc1# 0-1",
    B: "1692",
  },
  {
    W: "1537",
    T: "300+0",
    P: "1. d4 Nf6 2. c4 e6 3. a3 b6 4. Nc3 Bb7 5. Bg5 Be7 6. e3 O-O 7. Nf3 h6 8. Bh4 g5 9. Bg3 Nh5 10. Be5 Ng7 11. h3 f6 12. Bg3 d6 13. Be2 Qe8 14. O-O Qg6 15. Bd3 f5 16. Re1 Qf7 17. Qc2 Bxf3 18. Be2 Bxe2 19. Qxe2 Nd7 20. Qf3 Nf6 21. Rad1 Ngh5 22. Kh2 Nxg3 23. Kxg3 g4 24. Qe2 gxh3 25. Kxh3 Qh5+ 26. Qxh5 Nxh5 27. Rh1 Kh7 28. g3 Nf6 29. Kg2 Rg8 30. Rh3 Rg6 31. Rdh1 Ng4 32. Ne2 e5 33. dxe5 dxe5 34. f4 Nxe3+ 35. Kf3 Nxc4 36. fxe5 Nxe5+ 37. Kg2 Rd8 38. Nf4 Rd2+ 39. Kf1 Rd1+ 40. Kg2 Rxh1 41. Rxh1 Rd6 42. Nh5 Bg5 43. Re1 Ng6 44. Kh3 f4 45. gxf4 Nxf4+ 46. Nxf4 Bxf4 47. Rf1 Rf6 48. Kg4 Bg5 49. Rxf6 Bxf6 50. Kh5 Bxb2 51. a4 Bc3 52. Kg4 c6 53. Kf5 a5 54. Ke4 b5 55. axb5 cxb5 56. Kd3 Bb4 57. Kc2 h5 58. Kb3 h4 59. Kc2 h3 60. Kd3 h2 61. Ke4 h1=Q+ 62. Ke5 Qh5+ 63. Kd4 Kh6 64. Ke4 Qc5 65. Kf4 Kg6 66. Ke4 a4 67. Kf4 a3 68. Ke4 a2 69. Kf3 a1=Q 70. Kf4 Qad4+ 71. Kf3 Qcc3+ 72. Ke2 Qdd2+ 0-1",
    B: "1848",
  },
  {
    W: "1359",
    T: "300+8",
    P: "1. e4 c6 2. d3 d5 3. Nf3 Bg4 4. exd5 cxd5 5. Nc3 Nc6 6. Be2 Nf6 7. h3 Bh5 8. Bf4 e6 9. Qd2 Rc8 10. g4 Bg6 11. Ne5 Nxe5 12. Bxe5 Bd6 13. Bxf6 Qxf6 14. f3 Bb4 15. O-O-O Bxc3 16. bxc3 Qxc3 17. Qxc3 Rxc3 18. Kb2 Rc7 19. h4 h6 20. h5 Bh7 21. c4 dxc4 22. dxc4 Ke7 23. Kb3 Rd8 24. Rxd8 Kxd8 25. Rd1+ Rd7 26. Rxd7+ Kxd7 27. Kc3 e5 28. Bd3 Bxd3 29. Kxd3 Ke6 30. c5 Kd5 31. Ke3 Kxc5 32. Ke4 Kd6 33. f4 exf4 34. Kxf4 b5 35. Kf5 a5 36. g5 hxg5 37. Kxg5 b4 38. h6 gxh6+ 39. Kxh6 a4 40. Kg7 f5 41. Kf7 f4 0-1",
    B: "2115",
  },
  {
    W: "1355",
    T: "600+10",
    P: "1. e4 e5 2. Nc3 Nf6 3. Nf3 d6 4. d4 exd4 5. Nxd4 c5 6. Nf5 Nc6 7. Bg5 Be7 8. Bxf6 Bxf6 9. Bb5 Bd7 10. Qxd6 g6 11. Ne3 Be7 12. Qf4 O-O 13. O-O-O Nd4 14. Bxd7 Qxd7 15. Ned5 Rae8 16. Nf6+ Bxf6 17. Qxf6 Re6 18. Nd5 Qa4 19. Ne7+ Rxe7 20. Qxe7 Qxc2# 0-1",
    B: "1399",
  },
  {
    W: "1945",
    T: "60+0",
    P: "1. g3 d5 2. Bg2 c5 3. Nf3 Nc6 4. O-O Nf6 5. Ne1 e5 6. d3 d4 7. c3 Be6 8. Bg5 Be7 9. Bd2 O-O 10. Bc1 a6 11. Nd2 Bd5 12. Ne4 Nxe4 13. dxe4 Be6 14. c4 Bxc4 15. Bd2 Be6 16. b3 b5 17. Rc1 a5 18. e3 a4 19. exd4 Nxd4 20. Be3 axb3 21. axb3 Bxb3 22. Qd2 Be6 23. Nf3 Nb3 24. Qxd8 Rfxd8 25. Rb1 c4 26. Nxe5 Bf6 27. Nc6 Rdc8 28. e5 Rxc6 29. Bxc6 Ra6 30. Bxb5 Rc6 31. Bxc6 Bxe5 32. Bg2 g6 33. Rb2 1-0",
    B: "1975",
  },
];
