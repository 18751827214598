import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";

import { tokens } from "../../../styles/theme";
import ContentHeader from "../../../components/ContentHeader";
import HelpModal from "../../../components/HelpModal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ImagePuzzleHelp from "../../../help/ImagePuzzleHelp";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import { JigsawPuzzle } from "react-jigsaw-puzzle/lib";
import "react-jigsaw-puzzle/lib/jigsaw-puzzle.css";
import "./ImagePuzzle.css";

import { Helmet } from "react-helmet";
import { chessterms } from "../../../data/chessterms/chessterms";
import { trackEvent } from "../../../config/ga";
import { updateUserData } from "../../../features/Firestore";
import { useUser } from "../../../context/UserContext";
import { useShare } from "../../../context/ShareContext";
import { useTranslation } from "react-i18next";

function ImagePuzzle() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [completed, setCompleted] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState("");
  const [imageName, setImageName] = useState("");
  const { userData, userRef } = useUser();
  const { setShareData } = useShare();
  const { t } = useTranslation("Games");

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/imagepuzzle",
      title: "Chessboard Magic - Image Puzzle",
      description:
        "Engage in jigsaw-like puzzles featuring images of renowned chess players, piecing together each portrait for a challenging experience.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const handleNewGame = useCallback(() => {
    const chessPlayers = chessterms.filter(
      (term) => term.Type === "P" || term.Type === "W"
    );
    // chessPlayers = chessterms.filter((term) => term.Key === "ALEKHINE");
    const randomNumber = Math.floor(Math.random() * chessPlayers.length);
    let newSelectedChessPlayer = chessPlayers[randomNumber];

    setImageName(
      `${process.env.PUBLIC_URL}/img/players/${newSelectedChessPlayer.Key}.png`
    );
    setSelectedPlayer(newSelectedChessPlayer);
    setCompleted(false);

    //GA Tracking
    trackEvent("Games", "ImagePuzzle-Play", "Image Puzzle");
    // Internal Tracking
    if (userData) {
      if (!userData.Puzzles) {
        userData.Puzzles = {};
      }
      if (userData.Puzzles.ImagePuzzle) {
        userData.Puzzles.ImagePuzzle.Played =
          (userData.Puzzles.ImagePuzzle.Played || 0) + 1;
      } else {
        userData.Puzzles.ImagePuzzle = {
          Played: 1,
          Completed: 0,
        };
      }
      updateUserData(userRef, userData);
    }
  }, [userData, userRef]);

  const handleSolved = () => {
    // GA Tracking
    trackEvent("Games", "ImagePuzzle-Success", "Image Puzzle");
    // Internal Tracking
    if (userData) {
      userData.Puzzles.ImagePuzzle.Completed =
        (userData.Puzzles.ImagePuzzle.Completed || 0) + 1;
      updateUserData(userRef, userData);
    }
    setCompleted(true);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Start a new game when the component loads
    handleNewGame();
  }, [handleNewGame]);

  return (
    <Box>
      <ContentHeader
        title={t("ImagePuzzle.header.title")}
        subtitle={t("ImagePuzzle.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      <Helmet>
        <title>Image Puzzle</title>
        <meta
          name="description"
          content="Engage in jigsaw-like puzzles featuring images of renowned chess players, piecing together each portrait for a challenging experience."
        />
        <meta property="og:title" content="Image Puzzle" />
        <meta
          property="og:description"
          content="Engage in jigsaw-like puzzles featuring images of renowned chess players, piecing together each portrait for a challenging experience."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/games/imagepuzzle.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/imagepuzzle`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Image Puzzle" />
        <meta
          name="twitter:description"
          content="Engage in jigsaw-like puzzles featuring images of renowned chess players, piecing together each portrait for a challenging experience."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/games/imagepuzzle.png`}
        />
      </Helmet>
      <Box>
        <Button
          variant="contained"
          onClick={handleNewGame}
          style={{ marginRight: 10 }}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.material[1] }}
            />
          }
        >
          {t("ImagePuzzle.buttons.play")}
        </Button>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          style={{ marginRight: 10 }}
          startIcon={<HelpOutlineIcon style={{ color: colors.material[1] }} />}
        >
          {t("ImagePuzzle.buttons.help")}
        </Button>

        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("ImagePuzzle.helpDetails.title")}
          content={<ImagePuzzleHelp />}
        ></HelpModal>
      </Box>

      <Box
        sx={{
          padding: "10px 0px 10px 0px",
          maxWidth: "550px",
        }}
      >
        <div className="puzzle-container">
          <JigsawPuzzle
            imageSrc={imageName}
            rows={5}
            columns={5}
            onSolved={handleSolved}
          />
        </div>
      </Box>
      {selectedPlayer.ImageAuthor && (
        <small style={{ color: colors.black[500] }}>
          {t("ImagePuzzle.game.author")} {selectedPlayer.ImageAuthor},{" "}
          <a
            href={selectedPlayer.License}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: colors.black[500] }}
          >
            {t("ImagePuzzle.game.license")}
          </a>
        </small>
      )}
      <Box
        sx={{
          p: "0px 0px 0px 0px",
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          },
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        {completed && (
          <Box
            id="results"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "flex-start",
              maxWidth: "600px",
              paddingBottom: "20px", // Add padding to the Description
            }}
          >
            <Typography variant="h5">{selectedPlayer.Term}</Typography>
            <Typography>{selectedPlayer.Description}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ImagePuzzle;
