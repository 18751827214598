import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

const ChessSlideHelp = () => {
  const { t } = useTranslation("Games");

  return (
    <Box>
      <Typography>{t("ChessSlide.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t(
              "ChessSlide.helpDetails.instructions.look_at_puzzle.primary"
            )}
            secondary={t(
              "ChessSlide.helpDetails.instructions.look_at_puzzle.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "ChessSlide.helpDetails.instructions.move_pieces.primary"
            )}
            secondary={t(
              "ChessSlide.helpDetails.instructions.move_pieces.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "ChessSlide.helpDetails.instructions.complete_opening.primary"
            )}
            secondary={t(
              "ChessSlide.helpDetails.instructions.complete_opening.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("ChessSlide.helpDetails.instructions.get_help.primary")}
            secondary={t(
              "ChessSlide.helpDetails.instructions.get_help.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "ChessSlide.helpDetails.instructions.solve_puzzle.primary"
            )}
            secondary={t(
              "ChessSlide.helpDetails.instructions.solve_puzzle.secondary"
            )}
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default ChessSlideHelp;
