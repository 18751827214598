export const peopleCredits = ["Alexander Riddick", "Sayed Hajaj"];

export const techCredits = [
  { name: "Stockfish.js", url: "https://github.com/nmrugg/stockfish.js" },
  { name: "Chess.js", url: "https://github.com/jhlywa/chess.js/" },
  {
    name: "react-chessboard",
    url: "https://github.com/Clariity/react-chessboard",
  },
  { name: "React", url: "https://reactjs.org/" },
  { name: "Material UI", url: "https://mui.com/" },
  { name: "Nivo", url: "https://nivo.rocks/" },
  { name: "Ant Design", url: "https://ant.design/" },
  { name: "react-i18next", url: "https://react.i18next.com/" },
];
