import { ColorModeContext, useMode } from "../styles/theme";
import { CssBaseline, ThemeProvider, Container, Box } from "@mui/material";
import { useState, useEffect } from "react";

import { Outlet } from "react-router-dom";
import AppSideBar from "./AppSideBar";
import AppTopbar from "./AppTopbar";

const Layout = () => {
  const [theme, colorMode] = useMode();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 600);
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const submenuLeftPosition = isSmallScreen ? 60 : 220;
  const boxWidth = pageWidth - submenuLeftPosition - 50;

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container>
          <Box>
            <AppSideBar />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: submenuLeftPosition + 20,
                width: boxWidth,
              }}
            >
              <AppTopbar />
              <Outlet />
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Layout;
