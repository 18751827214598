import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const HiddenChessHelp = () => {
  const { t } = useTranslation("Learn");

  return (
    <>
      <Typography>{t("HiddenChess.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t("HiddenChess.helpDetails.instructions.modes.title")}
            secondary={t(
              "HiddenChess.helpDetails.instructions.modes.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("HiddenChess.helpDetails.instructions.showPieces.title")}
            secondary={t(
              "HiddenChess.helpDetails.instructions.showPieces.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("HiddenChess.helpDetails.instructions.move.title")}
            secondary={t(
              "HiddenChess.helpDetails.instructions.move.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "HiddenChess.helpDetails.instructions.startEngine.title"
            )}
            secondary={t(
              "HiddenChess.helpDetails.instructions.startEngine.description"
            )}
          />
        </ListItem>
      </List>
    </>
  );
};

export default HiddenChessHelp;
