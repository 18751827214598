import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Box, Button, Typography, Alert, useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { useShare } from "../../../context/ShareContext";
import { worldChampionshipGames } from "../../../data/historicgames/worldChampionship";
import { useTranslation } from "react-i18next";

import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import { themeColors } from "../../../styles/boardtheme";
import { pieceSets } from "../../../styles/pieceset";
import { useUser } from "../../../context/UserContext";

import { trackEvent } from "../../../config/ga";
import { updateUserData } from "../../../features/Firestore";

import HelpModal from "../../../components/HelpModal";
import WhereAreMyPiecesHelp from "../../../help/WhereAreMyPiecesHelp";

import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";
import ReplayIcon from "@mui/icons-material/Replay";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

function WhereAreMyPieces() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation("Games");
  const { userData, userRef } = useUser();

  const [boardMap, setBoardMap] = useState();
  const [toPlay, setToPlay] = useState();
  const [orientation, setOrientation] = useState("black");
  const [selectedPiece, setSelectedPiece] = useState(null); // Store dragged piece
  const [fen, setFen] = useState("8/8/8/8/8/8/8/8 w - - 0 1"); // Start with an empty board
  const [selectedGameFen, setSelectedGameFen] = useState();

  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [checkResults, setCheckResults] = useState({});
  const [completed, setCompleted] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);

  const [alertVisible, setAlertVisible] = useState(false);

  const [moves, setMoves] = useState([]); // Store all the moves of the game
  const [game, setGame] = useState(new Chess());
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);

  const { setShareData } = useShare();

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/wherearemypieces",
      title: "Chessboard Magic - Where Are My Pieces?",
      description:
        "Identify hidden chess pieces from famous games! Use your positional knowledge to uncover them and master strategy.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNewGame = useCallback(() => {
    setCompleted(false);
    setCheckResults({});
    setGameStarted(true);
    setOrientation("white");

    // GA Tracking
    trackEvent("Games", "WhereAreMyPieces-Play", "Where Are My Pieces");
    // Internal Tracking

    if (userData) {
      if (!userData.Puzzles) {
        userData.Puzzles = {};
      }
      if (userData.Puzzles.WhereAreMyPieces) {
        userData.Puzzles.WhereAreMyPieces.Played =
          (userData.Puzzles.WhereAreMyPieces.Played || 0) + 1;
      } else {
        userData.Puzzles.WhereAreMyPieces = {
          Played: 1,
          Completed: 0,
        };
      }
      updateUserData(userRef, userData);
    }

    // Randomly select a match
    const randomMatch =
      worldChampionshipGames[
        Math.floor(Math.random() * worldChampionshipGames.length)
      ];

    setSelectedMatch(randomMatch);

    const randomGame =
      randomMatch.Games[Math.floor(Math.random() * randomMatch.Games.length)];

    setSelectedGame(randomGame);

    const newGame = new Chess();
    newGame.loadPgn(randomGame.Moves);

    const maxMoveCount = Math.min(randomGame.PlyCount / 2, 50);
    const randomMoveCount =
      Math.floor(Math.random() * (maxMoveCount - 20)) + 20;

    const history = newGame.history({ verbose: true });

    newGame.reset();
    for (let i = 0; i < randomMoveCount; i++) {
      newGame.move(history[i]);
    }

    setSelectedGameFen(newGame.fen());
    setGame(newGame);
    setBoardMap(newGame.board());
    setToPlay(newGame.turn());
    setMoves(history); // Store the entire move history
    setCurrentMoveIndex(randomMoveCount); // Set the current move index to the last move made
    setFen("8/8/8/8/8/8/8/8 w - - 0 1");
  }, [userData, userRef]);

  const handleNextMove = useCallback(() => {
    if (currentMoveIndex < moves.length - 1) {
      const newGame = new Chess(game.fen());
      newGame.move(moves[currentMoveIndex + 1].san);
      setGame(newGame);
      setFen(newGame.fen());
      setCurrentMoveIndex(currentMoveIndex + 1); // Increment the index by 1
    }
  }, [currentMoveIndex, moves, game]);

  const handlePreviousMove = useCallback(() => {
    if (currentMoveIndex > -1) {
      const newGame = new Chess();
      for (let i = 0; i < currentMoveIndex; i++) {
        newGame.move(moves[i].san);
      }
      setGame(newGame);
      setFen(newGame.fen());
      setCurrentMoveIndex(currentMoveIndex - 1); // Decrement the index by 1
    }
  }, [currentMoveIndex, moves]);

  const goToMove = useCallback(
    (moveIndex) => {
      const newGame = new Chess();
      for (let i = 0; i <= moveIndex; i++) {
        newGame.move(moves[i].san);
      }
      setGame(newGame);
      setFen(newGame.fen());
      setCurrentMoveIndex(moveIndex); // Set the current move index to the clicked move
    },
    [moves]
  );

  const handleResetGame = useCallback(() => {
    if (moves.length > 0) {
      // Create a new Chess instance and load the game from the start
      const newGame = new Chess();

      // Set the FEN to the initial position (starting position of the game)
      setGame(newGame);
      setFen(newGame.fen()); // Set the FEN to the starting position
      setCurrentMoveIndex(-1); // Reset the move index to the start
    }
  }, [moves]);

  const handleLastMove = useCallback(() => {
    goToMove(moves.length - 1);
  }, [goToMove, moves.length]);

  // Add keyboard event listeners for left and right arrow keys
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!completed) return; // Only allow navigation if game is completed

      if (event.key === "ArrowLeft") {
        handlePreviousMove();
      } else if (event.key === "ArrowRight") {
        handleNextMove();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [completed, currentMoveIndex, moves, handleNextMove, handlePreviousMove]); // Dependencies to ensure state is up-to-date

  const handlePieceDrop = (sourceSquare, targetSquare, piece) => {
    // Convert FEN to board array
    const board = fenToBoardArray(fen);

    // Map square notation to array index
    const fileToIndex = (file) => file.charCodeAt(0) - "a".charCodeAt(0);
    const rankToIndex = (rank) => 8 - parseInt(rank);

    const srcRow = rankToIndex(sourceSquare[1]);
    const srcCol = fileToIndex(sourceSquare[0]);

    // If the targetSquare is null or undefined, remove the piece from the source square
    if (!targetSquare) {
      board[srcRow][srcCol] = null;
    } else {
      const tgtRow = rankToIndex(targetSquare[1]);
      const tgtCol = fileToIndex(targetSquare[0]);

      // Move the piece from source to target
      board[tgtRow][tgtCol] = board[srcRow][srcCol];
      board[srcRow][srcCol] = null; // Remove the piece from the source square
    }

    // Convert board array back to FEN
    const newFen = boardArrayToFen(board);

    // Update the FEN and re-render the board
    setFen(newFen);

    // Return true to indicate a valid drop
    return true;
  };

  const handleSquareClick = (square) => {
    if (!selectedPiece) return; // Don't do anything if no piece is selected

    // Convert FEN to board array
    const board = fenToBoardArray(fen);

    // Map square notation to array index
    const fileToIndex = (file) => file.charCodeAt(0) - "a".charCodeAt(0);
    const rankToIndex = (rank) => 8 - parseInt(rank);

    const row = rankToIndex(square[1]);
    const col = fileToIndex(square[0]);

    // If selected piece is "D" (delete), remove the piece from the board
    if (selectedPiece.type === "D") {
      board[row][col] = null; // Remove the piece from the square
    } else {
      // Otherwise, place the selected piece on the clicked square
      board[row][col] =
        selectedPiece.color === "w"
          ? selectedPiece.type.toUpperCase()
          : selectedPiece.type;
    }

    // Convert board array back to FEN
    const newFen = boardArrayToFen(board);

    // Update the FEN and re-render the board
    setFen(newFen);
  };

  const generateSquareStyles = (boardMap) => {
    const squareStyles = {};

    if (completed) {
      return squareStyles;
    }

    boardMap?.forEach((row) => {
      row.forEach((square) => {
        if (square) {
          const isCorrect = checkResults[square.square];

          squareStyles[square.square] = {
            backgroundColor: square.color === "w" ? "white" : "black", // Keep background as black or white
            borderRadius: "50%", // Maintain circular style if needed
            boxShadow:
              isCorrect === true
                ? "0px 0px 10px 2px rgba(0, 255, 0, 0.5)" // Green shadow if correct
                : isCorrect === false
                ? "0px 0px 10px 2px rgba(255, 0, 0, 0.5)" // Red shadow if incorrect
                : "0px 0px 10px 2px rgba(0, 0, 0, 0.5)", // Default shadow (neutral black) if not checked yet
          };
        }
      });
    });

    return squareStyles;
  };

  const fenToBoardArray = (fen) => {
    const [position] = fen.split(" "); // Split FEN into board position and other data
    const rows = position.split("/"); // Split into individual rows
    const board = rows.map((row) => {
      let expandedRow = [];
      for (let char of row) {
        if (isNaN(char)) {
          // It's a piece, push it to the row
          expandedRow.push(char);
        } else {
          // It's a number, push empty squares
          expandedRow = [...expandedRow, ...Array(parseInt(char)).fill(null)];
        }
      }
      return expandedRow;
    });
    return board;
  };

  const boardArrayToFen = (board) => {
    const fenRows = board.map((row) => {
      let emptyCount = 0;
      return (
        row
          .map((cell) => {
            if (cell === null) {
              emptyCount++;
              return "";
            } else {
              if (emptyCount > 0) {
                const result = emptyCount + cell;
                emptyCount = 0;
                return result;
              }
              return cell;
            }
          })
          .join("") + (emptyCount > 0 ? emptyCount : "")
      );
    });
    return fenRows.join("/") + " w - - 0 1"; // You can adjust this based on actual game data
  };

  const handleCheck = () => {
    if (!selectedGame || !fen || !selectedGameFen) return;

    const currentBoard = fenToBoardArray(fen);
    const correctBoard = fenToBoardArray(selectedGameFen);

    const results = {};
    let allCorrect = true;

    // Loop through both boards and compare each square
    for (let row = 0; row < 8; row++) {
      for (let col = 0; col < 8; col++) {
        const currentPiece = currentBoard[row][col];
        const correctPiece = correctBoard[row][col];

        const square = `${String.fromCharCode(97 + col)}${8 - row}`;

        // If both pieces match, set the result to true, else set it to false
        if (currentPiece === correctPiece) {
          results[square] = true;
        } else {
          results[square] = false;
          allCorrect = false; // If any square doesn't match, the board is not 100% correct
        }
      }
    }

    setCheckResults(results);

    // If all squares are correct, set `completed` to true
    if (allCorrect) {
      setCompleted(true);
      setGameStarted(false);
      setCurrentMoveIndex((prev) => prev - 1);
    }
  };

  const handleGiveUp = () => {
    setCompleted(true);
    setGameStarted(false);
    setFen(selectedGameFen);
    setCurrentMoveIndex((prev) => prev - 1);
  };

  const toggleOrientation = () => {
    setOrientation((prevOrientation) =>
      prevOrientation === "white" ? "black" : "white"
    );
  };

  function convertDateToReadableFormat(dateString) {
    // Split the input date string into year, month, and day
    const [year, month, day] = dateString.split(".");

    // Convert the month number to a month name
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthName = monthNames[parseInt(month, 10) - 1];

    // Convert the day number to an integer
    const dayInt = parseInt(day, 10);

    // Determine the appropriate suffix for the day
    const getDayWithSuffix = (day) => {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      }
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    const dayWithSuffix = getDayWithSuffix(dayInt);

    // Return the formatted date string
    return `${dayWithSuffix} ${monthName} ${year}`;
  }

  function convertNameFormat(name) {
    const [lastName, firstName] = name.split(", ").map((str) => str.trim());
    return `${firstName} ${lastName}`;
  }

  const generatePgn = () => {
    if (!selectedGame) return "";

    // PGN Headers
    const pgnHeaders = [
      `[Event "${selectedMatch?.Event || "Unknown Event"}"]`,
      `[Site "${selectedMatch?.Site || "Unknown Site"}"]`,
      `[Date "${selectedGame?.Date || "????.??.??"}"]`,
      `[Round "${selectedGame?.Round || "?"}"]`,
      `[White "${selectedGame?.White || "Unknown White"}"]`,
      `[Black "${selectedGame?.Black || "Unknown Black"}"]`,
      `[Result "${selectedGame?.Result || "*"}"]`, // Last move should contain the result
    ];

    // PGN Moves
    const pgnMoves =
      moves
        .map((move, index) => {
          if (["1-0", "0-1", "1/2-1/2", "*"].includes(move.san)) {
            return move.san; // If it's a result, just return the result
          }
          return index % 2 === 0
            ? `${Math.floor(index / 2) + 1}. ${move.san}` // Number the white moves
            : move.san; // Black moves don't need the numbering
        })
        .join(" ") +
      " " +
      selectedGame.Result;

    // Combine headers and moves into full PGN
    const fullPgn = `${pgnHeaders.join("\n")}\n\n${pgnMoves}`;

    return fullPgn;
  };

  const copyPgnToClipboard = () => {
    const fullPgn = generatePgn();

    if (!fullPgn) return;

    navigator.clipboard
      .writeText(fullPgn)
      .then(() => {
        setAlertVisible(true); // Show alert
        setTimeout(() => {
          setAlertVisible(false); // Hide alert after 3 seconds
        }, 3000);
      })
      .catch((err) => {
        console.error("Failed to copy PGN: ", err);
      });
  };

  const renderDraggablePieces = () => {
    const pieces = ["p", "r", "n", "b", "q", "k"];
    const selectedPieceSet = pieceSets[userData?.pieceset || "Maestro"]; // Access the selected piece set

    const handleClearBoard = () => {
      setFen("8/8/8/8/8/8/8/8 w - - 0 1"); // Reset the FEN to an empty board
    };

    return (
      <Box display="flex" flexDirection="column">
        <Typography>{t("WhereAreMyPieces.helpText")}</Typography>
        {/* White Pieces */}
        <Box>
          <Box display="flex" flexDirection="row">
            {pieces.map((piece) => (
              <div
                key={`w${piece}`}
                onClick={() => setSelectedPiece({ type: piece, color: "w" })}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                  margin: "5px",
                  paddingTop: "3px",
                  textAlign: "center",
                  lineHeight: "40px",
                  backgroundColor:
                    selectedPiece?.type === piece &&
                    selectedPiece?.color === "w"
                      ? "lightgreen"
                      : "transparent", // Remove background color for unselected pieces
                  boxShadow:
                    selectedPiece?.type === piece &&
                    selectedPiece?.color === "w"
                      ? "0px 0px 10px 2px rgba(0, 0, 0, 0.3)"
                      : "none", // Remove shadow for unselected pieces
                }}
              >
                {selectedPieceSet[`w${piece.toUpperCase()}`]({
                  squareWidth: 40, // Set the size of the piece
                })}
              </div>
            ))}
          </Box>
        </Box>

        {/* Black Pieces */}
        <Box>
          <Box display="flex" flexDirection="row">
            {pieces.map((piece) => (
              <div
                key={`b${piece}`}
                onClick={() => setSelectedPiece({ type: piece, color: "b" })}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                  margin: "5px",
                  paddingTop: "3px",
                  textAlign: "center",
                  lineHeight: "40px",
                  backgroundColor:
                    selectedPiece?.type === piece &&
                    selectedPiece?.color === "b"
                      ? "lightgreen"
                      : "transparent", // Remove background color for unselected pieces
                  boxShadow:
                    selectedPiece?.type === piece &&
                    selectedPiece?.color === "b"
                      ? "0px 0px 10px 2px rgba(0, 0, 0, 0.3)"
                      : "none", // Remove shadow for unselected pieces
                }}
              >
                {selectedPieceSet[`b${piece.toUpperCase()}`]({
                  squareWidth: 40, // Set the size of the piece
                })}
              </div>
            ))}
          </Box>
        </Box>

        {/* Delete Option */}
        <Box>
          <Box display="flex" flexDirection="row">
            <Button
              variant="contained"
              onClick={() => setSelectedPiece({ type: "D" })}
              style={{
                margin: "5px",
                textAlign: "center",
                backgroundColor:
                  selectedPiece?.type === "D" ? "lightcoral" : "transparent", // Highlight when selected
              }}
              startIcon={<DeleteIcon style={{ color: colors.material[1] }} />}
            >
              {t("WhereAreMyPieces.buttons.removePieces")}
            </Button>

            <Button
              variant="contained"
              onClick={handleClearBoard}
              style={{
                margin: "5px",
                textAlign: "center",
              }}
              startIcon={
                <RestartAltIcon style={{ color: colors.material[1] }} />
              }
            >
              {t("WhereAreMyPieces.buttons.clearBoard")}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    // Start a new game when the component loads
    handleNewGame();
  }, [handleNewGame]);

  return (
    <Box>
      <ContentHeader
        title={t("WhereAreMyPieces.header.title")}
        subtitle={t("WhereAreMyPieces.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      <Helmet>
        <title>Where Are My Pieces?</title>
        <meta
          name="description"
          content="Identify hidden chess pieces from famous games! Use your positional knowledge to uncover them and master strategy."
        />
        <meta property="og:title" content="Where Are My Pieces?" />
        <meta
          property="og:description"
          content="Identify hidden chess pieces from famous games! Use your positional knowledge to uncover them and master strategy."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/games/wherearemypieces.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/wherearemypieces`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Where Are My Pieces?" />
        <meta
          name="twitter:description"
          content="Identify hidden chess pieces from famous games! Use your positional knowledge to uncover them and master strategy."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/games/wherearemypieces.png`}
        />
      </Helmet>

      <Box>
        <Button
          variant="contained"
          onClick={handleNewGame}
          style={{ marginRight: 10 }}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.material[1] }}
            />
          }
          sx={{ mb: 1 }}
        >
          {t("WhereAreMyPieces.buttons.play")}
        </Button>
        <Button
          variant="contained"
          onClick={handleCheck}
          style={{ marginRight: 10 }}
          disabled={completed || !gameStarted}
          startIcon={<TaskAltIcon style={{ color: colors.material[1] }} />}
          sx={{ mb: 1 }}
        >
          {t("WhereAreMyPieces.buttons.check")}
        </Button>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          style={{ marginRight: 10 }}
          startIcon={<HelpOutlineIcon style={{ color: colors.material[1] }} />}
          sx={{ mb: 1 }}
        >
          {t("WhereAreMyPieces.buttons.help")}
        </Button>
        <Button
          variant="contained"
          onClick={handleGiveUp}
          startIcon={
            <HighlightOffRoundedIcon style={{ color: colors.material[1] }} />
          }
          disabled={!gameStarted}
          sx={{ mb: 1 }}
        >
          {t("WhereAreMyPieces.buttons.quit")}
        </Button>

        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("WhereAreMyPieces.helpDetails.title")}
          content={<WhereAreMyPiecesHelp />}
        ></HelpModal>
      </Box>

      {gameStarted && (
        <Typography>{toPlay === "b" ? "Black" : "White"} to Play</Typography>
      )}

      {(gameStarted || completed) && (
        <Box
          display="flex"
          mt={2}
          sx={{
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
          }}
        >
          <Box flex={1} sx={{ maxWidth: "450px" }}>
            <Chessboard
              position={fen}
              boardOrientation={orientation}
              onPieceDrop={handlePieceDrop}
              onSquareClick={handleSquareClick}
              customSquareStyles={generateSquareStyles(boardMap)}
              customLightSquareStyle={{
                backgroundColor:
                  themeColors[userData?.theme || "Modern Minimal"].lightSquare,
                backgroundImage:
                  themeColors[userData?.theme || "Modern Minimal"]
                    .lightSquarePattern,
              }}
              customDarkSquareStyle={{
                backgroundColor:
                  themeColors[userData?.theme || "Modern Minimal"].darkSquare,
                backgroundImage:
                  themeColors[userData?.theme || "Modern Minimal"]
                    .darkSquarePattern,
              }}
              customPieces={pieceSets[userData?.pieceset || "Maestro"]}
              areArrowsAllowed={false}
              arePiecesDraggable={true}
            />

            {/* Add left and right navigation buttons under the chessboard */}
            {completed && (
              <Box sx={{ pt: 2, display: "flex", justifyContent: "left" }}>
                <Tooltip title="Reset the game">
                  <Button
                    variant="contained"
                    onClick={handleResetGame}
                    style={{ marginRight: 10 }}
                    disabled={currentMoveIndex === -1}
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <ReplayIcon />
                  </Button>
                </Tooltip>
                <Tooltip title="Go to previous move">
                  <Button
                    variant="contained"
                    onClick={handlePreviousMove}
                    disabled={currentMoveIndex === -1}
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                    style={{ marginRight: 10 }}
                  >
                    <KeyboardDoubleArrowLeftIcon
                      style={{ color: colors.black[900] }}
                    />
                  </Button>
                </Tooltip>
                <Tooltip title="Go to next move">
                  <Button
                    variant="contained"
                    onClick={handleNextMove}
                    disabled={currentMoveIndex === moves.length - 1}
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                    style={{ marginRight: 10 }}
                  >
                    <KeyboardDoubleArrowRightIcon
                      style={{ color: colors.black[900] }}
                    />
                  </Button>
                </Tooltip>
                <Tooltip title="Go to last move">
                  <Button
                    variant="contained"
                    onClick={handleLastMove}
                    disabled={currentMoveIndex >= moves.length - 1}
                    style={{ marginRight: 10 }}
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <SkipNextIcon />
                  </Button>
                </Tooltip>
                <Tooltip title="Toggle board orientation">
                  <Button
                    variant="contained"
                    onClick={toggleOrientation}
                    style={{ marginRight: 10 }}
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <SwapVertRoundedIcon />
                  </Button>
                </Tooltip>
                <Tooltip title="Copy PGN to Clipboard">
                  <Button
                    variant="contained"
                    onClick={copyPgnToClipboard} // Call the function
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <ContentCopyRoundedIcon />
                  </Button>
                </Tooltip>{" "}
                {alertVisible && (
                  <Alert
                    severity="success"
                    icon={false}
                    sx={{
                      marginLeft: 1,
                      fontSize: "0.8rem",
                      height: 40,
                      pl: 2,
                      pt: "4px",
                      pb: 0,
                      pr: 2,
                    }}
                  >
                    Copied to Clipboard!
                  </Alert>
                )}
              </Box>
            )}
          </Box>

          {/* Conditional rendering of draggable pieces or moves list */}
          <Box flex={1} ml={{ md: 2, xs: 0 }}>
            {completed ? (
              <Box flexWrap="wrap">
                <Typography>
                  {convertNameFormat(selectedGame.White)} vs{" "}
                  {convertNameFormat(selectedGame.Black)}
                </Typography>
                <Typography sx={{ pb: 2 }}>
                  {selectedMatch.Site},{" "}
                  {convertDateToReadableFormat(selectedGame.Date)}, Round{" "}
                  {selectedGame.Round}
                </Typography>
                {moves.map((move, index) => {
                  const isResult = ["1-0", "0-1", "1/2-1/2", "*"].includes(
                    move.san
                  );

                  // Highlight the move that corresponds to the currentMoveIndex
                  const isCurrent = index === currentMoveIndex;

                  return (
                    <React.Fragment key={"move-comment-" + index}>
                      <span
                        onClick={() => !isResult && goToMove(index)} // Click handler for the move
                        style={{
                          cursor: isResult ? "default" : "pointer",
                          marginRight: 8,
                          fontWeight: isCurrent ? "bold" : "normal",
                          display: "inline", // Ensure continuous line
                        }}
                      >
                        {isResult
                          ? move.san
                          : index % 2 === 0
                          ? `${Math.floor(index / 2) + 1}. ${move.san}`
                          : `${move.san}`}
                      </span>
                    </React.Fragment>
                  );
                })}
                <span>{selectedGame.Result}</span>
              </Box>
            ) : (
              renderDraggablePieces()
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default WhereAreMyPieces;
