import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme, TextField } from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { chessterms } from "../../../data/chessterms/chessterms";
import { Helmet } from "react-helmet";
import { useShare } from "../../../context/ShareContext";
import { useTranslation } from "react-i18next";

const Glossary = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { setShareData } = useShare();
  const { t } = useTranslation("Library");

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/glossary",
      title: "Chessboard Magic - Chess Glossary",
      description: "Explore and demystify Chess terminology.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const [searchTerm, setSearchTerm] = useState("");

  const filteredTerms = chessterms.filter(
    (term) =>
      term.Type === "T" &&
      term.Term.toLowerCase().includes(searchTerm.toLowerCase())
  );
  filteredTerms.sort((a, b) => (a.Key > b.Key ? 1 : -1));

  return (
    <Box>
      <ContentHeader
        title={t("Glossary.header.title")}
        subtitle={t("Glossary.header.subtitle")}
        color={colors.black[900]}
        backgroundImage="./img/header-background.png"
        borderColor={colors.material[6]}
      />
      <Helmet>
        <title>Chess Glossary</title>
        <meta
          name="description"
          content="Explore and demystify Chess terminology."
        />
        <meta property="og:title" content="Chess Glossary" />
        <meta
          property="og:description"
          content="Explore and demystify Chess terminology."
        />
        <meta
          property="og:image"
          content="https://chessboardmagic.com/img/library/glossary.png"
        />
        <meta
          property="og:url"
          content="https://chessboardmagic.com/glossary"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Chess Glossary" />
        <meta
          name="twitter:description"
          content="Explore and demystify Chess terminology."
        />
        <meta
          name="twitter:image"
          content="https://chessboardmagic.com/img/library/glossary.png"
        />
      </Helmet>
      <Box
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        flexDirection="column"
        gap={2}
      >
        <TextField
          label={t("Glossary.buttons.search")}
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
          autoComplete="off"
          focused={false}
        />
        {filteredTerms.map((term) => (
          <Box key={term.id} sx={{ marginBottom: 1 }}>
            <Typography variant="h6">{term.Term}</Typography>
            <Typography variant="body1">{term.Description}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Glossary;
