import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const AnagramsHelp = () => {
  const { t } = useTranslation("Games");

  return (
    <>
      <Typography>{t("Anagrams.helpDetails.welcome")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t("Anagrams.helpDetails.instructions.unscramble.title")}
            secondary={t(
              "Anagrams.helpDetails.instructions.unscramble.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("Anagrams.helpDetails.instructions.hint.title")}
            secondary={t("Anagrams.helpDetails.instructions.hint.description")}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("Anagrams.helpDetails.instructions.quit.title")}
            secondary={t("Anagrams.helpDetails.instructions.quit.description")}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "Anagrams.helpDetails.instructions.chessKnowledge.title"
            )}
            secondary={t(
              "Anagrams.helpDetails.instructions.chessKnowledge.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("Anagrams.helpDetails.instructions.newGame.title")}
            secondary={t(
              "Anagrams.helpDetails.instructions.newGame.description"
            )}
          />
        </ListItem>
      </List>
    </>
  );
};

export default AnagramsHelp;
