// ga.js
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const measurementID = "G-5T1WFE8M68"; // Your Measurement ID
ReactGA.initialize(measurementID, {
  gtagOptions: {
    cookie_flags: "SameSite=None; Secure",
  },
});

// Track page views
const trackPage = (page) => {
  ReactGA.send({ hitType: "pageview", page });
};

// Track events
const trackEvent = (category, action, label, value) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};

// Custom hook for tracking page views
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    trackPage(location.pathname + location.search);
  }, [location]);
};

export { trackEvent, usePageTracking };
