import React from "react";
import {
  Box,
  Typography,
  useTheme,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { Helmet } from "react-helmet";

const MyCard = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <a href={props.link} style={{ textDecoration: "none" }}>
      <Card sx={{ maxWidth: 345, height: "100%" }}>
        <CardActionArea>
          <CardMedia component="img" height="140" image={props.img} />
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ color: colors.black[900] }}
            >
              {props.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {props.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </a>
  );
};

const NotFound = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Helmet>
        <title>Page Not Found</title>
        <meta
          name="description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta property="og:title" content="About Chessboard Magic" />
        <meta
          property="og:description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta
          property="og:image"
          content="https://chessboardmagic.com/img/chessboardmagic-social.png"
        />
        <meta property="og:url" content="https://chessboardmagic.com/about" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Chessboard Magic" />
        <meta
          name="twitter:description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta
          name="twitter:image"
          content="https://chessboardmagic.com/img/chessboardmagic-social.png"
        />
      </Helmet>
      <ContentHeader
        title="Page Not Found"
        subtitle="Why not check out one of the following"
        color={colors.black[900]}
        backgroundImage="./img/header-background.png"
      />
      <Box display="flex" flexDirection="column">
        <Box sx={{ paddingBottom: "10px" }}>
          <Box sx={{ paddingBottom: "10px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} xl={2} key="1">
                <MyCard
                  title="Play the Opening"
                  description="Test your knowledge by correctly playing named chess openings, and deepen your familiarity with a variety of openings"
                  img="./img/games/playtheopening.png"
                  link="playtheopening"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} xl={2} key="2">
                <MyCard
                  title="Guess the Opening"
                  description="A Wordle-like chess game where you challenge yourself to figure out the opening move sequence"
                  img="./img/games/guesstheopening.png"
                  link="guesstheopening"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} xl={2} key="3">
                <MyCard
                  title="Guess the Elo"
                  description="Estimate players' ratings based on their game moves in this challenging and fun game, and see how accurately you can guess their Elo"
                  img="./img/games/guesstheelo.png"
                  link="guesstheelo"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} xl={2} key="4">
                <MyCard
                  title="Guess The Eval"
                  description="Test your accuracy in evaluating chess positions in this fun and challenging game, and improve your ability with each guess"
                  img="./img/games/guesstheeval.png"
                  link="guesstheeval"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} xl={2} key="5">
                <MyCard
                  title="Chess Slide"
                  description="Enjoy a challenging slider puzzle where you slide tiles to reveal a hidden chess opening, testing your logic and problem-solving skills"
                  img="./img/games/chessslide.png"
                  link="chessslide"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} xl={2} key="6">
                <MyCard
                  title="Chess Anagrams"
                  description="Form words from scrambled letters related to chess terms, players, openings, or engines. Fast-paced and fun!"
                  img="./img/games/anagrams.png"
                  link="anagrams"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} xl={2} key="7">
                <MyCard
                  title="Chess Hangman"
                  description="Combine the fun of hangman with chess! Guess famous players or chess terms. Challenge your chess knowledge"
                  img="./img/games/hangman.png"
                  link="hangman"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} xl={2} key="8">
                <MyCard
                  title="Chess Wordsearch"
                  description="Challenge your mind with Chess Wordsearch! Find hidden chess terms in a grid and enhance your chess vocabulary"
                  img="./img/games/wordsearch.png"
                  link="wordsearch"
                />
              </Grid>
              {/*<Grid item xs={12} sm={6} md={3} xl={2} key="9">
                <MyCard
                  title="Chess Image Puzzle"
                  description="Engage in jigsaw-like puzzles featuring images of renowned chess players, piecing together each portrait for a challenging experience"
                  img="./img/games/imagePuzzle.png"
                  link="imagepuzzle"
                />
              </Grid>*/}
              <Grid item xs={12} sm={6} md={3} xl={2} key="10">
                <MyCard
                  title="Fischer Random Generator"
                  description="Generate randomized chess positions and experience the unpredictability and excitement of Fischer Random Chess"
                  img="./img/tools/fischerrandom.png"
                  link="fischerrandom"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} xl={2} key="11">
                <MyCard
                  title="Chess Notation Trainer"
                  description="Master chess notation by typing moves in this interactive trainer. Practice with random games and improve your skills!"
                  img="./img/tools/notationTrainer.png"
                  link="notationtrainer"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} xl={2} key="12">
                <MyCard
                  title="Opening Explorer"
                  description="Explore a wide range of chess openings, and deepen your understanding of variations from the very first moves"
                  img="./img/tools/openingexplorer.png"
                  link="openingexplorer"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} xl={2} key="13">
                <MyCard
                  title="Chess Quotes"
                  description="Discover Famous Chess Quotes: A Collection of Inspiring Words."
                  img="./img/library/quotes.png"
                  link="quotes"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} xl={2} key="14">
                <MyCard
                  title="Chess Glossary"
                  description="Explore and demystify Chess terminology."
                  img="./img/library/glossary.png"
                  link="glossary"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
