import React, { useEffect } from "react";
import {
  Box,
  Typography,
  useTheme,
  Card,
  CardContent,
  CardMedia,
  CardHeader,
  useMediaQuery,
} from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { Helmet } from "react-helmet";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import ExtensionRoundedIcon from "@mui/icons-material/ExtensionRounded";
import MyCard from "../../../components/MyCard";
import HandymanIcon from "@mui/icons-material/Handyman";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import StarIcon from "@mui/icons-material/Star";
import { useShare } from "../../../context/ShareContext";
import { useTranslation } from "react-i18next";

// Shuffle function to randomize the array
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const Homepage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { setShareData } = useShare();
  const { t } = useTranslation("Other");

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    const newShareData = {
      url: `https://chessboardmagic.com`,
      title: "Chessboard Magic",
      description:
        "Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const gameItems = [
    {
      title: t("Homepage.highlights.gamesItems.playTheOpening.title"),
      description: t(
        "Homepage.highlights.gamesItems.playTheOpening.description"
      ),
      longDescription: t(
        "Homepage.highlights.gamesItems.playTheOpening.longDescription"
      ),
      image: "games/playtheopening.png",
      link: "playtheopening",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.gamesItems.guessTheOpening.title"),
      description: t(
        "Homepage.highlights.gamesItems.guessTheOpening.description"
      ),
      longDescription: t(
        "Homepage.highlights.gamesItems.guessTheOpening.longDescription"
      ),
      image: "games/guesstheopening.png",
      link: "guesstheopening",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.gamesItems.guessTheElo.title"),
      description: t("Homepage.highlights.gamesItems.guessTheElo.description"),
      longDescription: t(
        "Homepage.highlights.gamesItems.guessTheElo.longDescription"
      ),
      image: "games/guesstheelo.png",
      link: "guesstheelo",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.gamesItems.guessTheEval.title"),
      description: t("Homepage.highlights.gamesItems.guessTheEval.description"),
      longDescription: t(
        "Homepage.highlights.gamesItems.guessTheEval.longDescription"
      ),
      image: "games/guesstheeval.png",
      link: "guesstheeval",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.gamesItems.guessTheMove.title"),
      description: t("Homepage.highlights.gamesItems.guessTheMove.description"),
      longDescription: t(
        "Homepage.highlights.gamesItems.guessTheMove.longDescription"
      ),
      image: "games/guessthemove.png",
      link: "guessthemove",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.gamesItems.guessWho.title"),
      description: t("Homepage.highlights.gamesItems.guessWho.description"),
      longDescription: t(
        "Homepage.highlights.gamesItems.guessWho.longDescription"
      ),
      image: "games/guesswho.png",
      link: "guesswho",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.gamesItems.chessSlide.title"),
      description: t("Homepage.highlights.gamesItems.chessSlide.description"),
      longDescription: t(
        "Homepage.highlights.gamesItems.chessSlide.longDescription"
      ),
      image: "games/chessslide.png",
      link: "chessslide",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.gamesItems.chessAnagrams.title"),
      description: t(
        "Homepage.highlights.gamesItems.chessAnagrams.description"
      ),
      longDescription: t(
        "Homepage.highlights.gamesItems.chessAnagrams.longDescription"
      ),
      image: "games/anagrams.png",
      link: "anagrams",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.gamesItems.chessHangman.title"),
      description: t("Homepage.highlights.gamesItems.chessHangman.description"),
      longDescription: t(
        "Homepage.highlights.gamesItems.chessHangman.longDescription"
      ),
      image: "games/hangman.png",
      link: "hangman",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.gamesItems.chessWordsearch.title"),
      description: t(
        "Homepage.highlights.gamesItems.chessWordsearch.description"
      ),
      longDescription: t(
        "Homepage.highlights.gamesItems.chessWordsearch.longDescription"
      ),
      image: "games/wordsearch.png",
      link: "wordsearch",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.gamesItems.chessCrossword.title"),
      description: t(
        "Homepage.highlights.gamesItems.chessCrossword.description"
      ),
      longDescription: t(
        "Homepage.highlights.gamesItems.chessCrossword.longDescription"
      ),
      image: "games/crossword.png",
      link: "crossword",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.gamesItems.chessImagePuzzle.title"),
      description: t(
        "Homepage.highlights.gamesItems.chessImagePuzzle.description"
      ),
      longDescription: t(
        "Homepage.highlights.gamesItems.chessImagePuzzle.longDescription"
      ),
      image: "games/imagepuzzle.png",
      link: "imagepuzzle",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.gamesItems.whereAreMyPieces.title"),
      description: t(
        "Homepage.highlights.gamesItems.whereAreMyPieces.description"
      ),
      longDescription: t(
        "Homepage.highlights.gamesItems.whereAreMyPieces.longDescription"
      ),
      image: "games/wherearemypieces.png",
      link: "wherearemypieces",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.gamesItems.rotatingImagePuzzle.title"),
      description: t(
        "Homepage.highlights.gamesItems.rotatingImagePuzzle.description"
      ),
      longDescription: t(
        "Homepage.highlights.gamesItems.rotatingImagePuzzle.longDescription"
      ),
      image: "games/rotatingimagepuzzle.png",
      link: "rotatingimagepuzzle",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.gamesItems.cryptograms.title"),
      description: t("Homepage.highlights.gamesItems.cryptograms.description"),
      longDescription: t(
        "Homepage.highlights.gamesItems.cryptograms.longDescription"
      ),
      image: "games/cryptograms.png",
      link: "cryptograms",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.gamesItems.decryptChess.title"),
      description: t("Homepage.highlights.gamesItems.decryptChess.description"),
      longDescription: t(
        "Homepage.highlights.gamesItems.decryptChess.longDescription"
      ),
      image: "games/decryptchess.png",
      link: "decryptchess",
      highlight: true,
    },
  ];

  const toolItems = [
    {
      title: t("Homepage.highlights.toolsItems.gifGenerator.title"),
      description: t("Homepage.highlights.toolsItems.gifGenerator.description"),
      longDescription: t(
        "Homepage.highlights.toolsItems.gifGenerator.longDescription"
      ),
      image: "tools/gifgenerator.png",
      link: "gifgenerator",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.toolsItems.fischerRandom.title"),
      description: t(
        "Homepage.highlights.toolsItems.fischerRandom.description"
      ),
      image: "tools/fischerrandom.png",
      link: "fischerrandom",
    },
    {
      title: t("Homepage.highlights.toolsItems.openingExplorer.title"),
      description: t(
        "Homepage.highlights.toolsItems.openingExplorer.description"
      ),
      image: "tools/openingexplorer.png",
      link: "openingexplorer",
    },
    {
      title: t("Homepage.highlights.toolsItems.pgnViewer.title"),
      description: t("Homepage.highlights.toolsItems.pgnViewer.description"),
      image: "tools/pgnviewer.png",
      link: "pgnviewer",
    },
    {
      title: t("Homepage.highlights.toolsItems.tablebase.title"),
      description: t("Homepage.highlights.toolsItems.tablebase.description"),
      image: "tools/tablebase.png",
      link: "tablebase",
    },
  ];

  const learnItems = [
    {
      title: t("Homepage.highlights.learnItems.notationTrainer.title"),
      description: t(
        "Homepage.highlights.learnItems.notationTrainer.description"
      ),
      longDescription: t(
        "Homepage.highlights.learnItems.notationTrainer.longDescription"
      ),
      image: "tools/notationtrainer.png",
      link: "notationtrainer",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.learnItems.handAndBrain.title"),
      description: t("Homepage.highlights.learnItems.handAndBrain.description"),
      longDescription: t(
        "Homepage.highlights.learnItems.handAndBrain.longDescription"
      ),
      image: "learn/handandbrain.png",
      link: "handandbrain",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.learnItems.hiddenChess.title"),
      description: t("Homepage.highlights.learnItems.hiddenChess.description"),
      longDescription: t(
        "Homepage.highlights.learnItems.hiddenChess.longDescription"
      ),
      image: "learn/hiddenchess.png",
      link: "hiddenchess",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.learnItems.opponentPrep.title"),
      description: t("Homepage.highlights.learnItems.opponentPrep.description"),
      longDescription: t(
        "Homepage.highlights.learnItems.opponentPrep.longDescription"
      ),
      image: "analytics/opponentprep.png",
      link: "opponentprep",
      highlight: true,
    },
  ];

  const libraryItems = [
    {
      title: t(
        "Homepage.highlights.libraryItems.worldChessChampionships.title"
      ),
      description: t(
        "Homepage.highlights.libraryItems.worldChessChampionships.description"
      ),
      longDescription: t(
        "Homepage.highlights.libraryItems.worldChessChampionships.longDescription"
      ),
      image: "library/worldchampionships.png",
      link: "worldchampionships",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.libraryItems.classicChessGames.title"),
      description: t(
        "Homepage.highlights.libraryItems.classicChessGames.description"
      ),
      longDescription: t(
        "Homepage.highlights.libraryItems.classicChessGames.longDescription"
      ),
      image: "library/classicgames.png",
      link: "classicgames",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.libraryItems.miniatureChessGames.title"),
      description: t(
        "Homepage.highlights.libraryItems.miniatureChessGames.description"
      ),
      longDescription: t(
        "Homepage.highlights.libraryItems.miniatureChessGames.longDescription"
      ),
      image: "library/miniaturegames.png",
      link: "miniaturegames",
      highlight: true,
    },
    {
      title: t("Homepage.highlights.libraryItems.chessQuotes.title"),
      description: t(
        "Homepage.highlights.libraryItems.chessQuotes.description"
      ),
      image: "library/quotes.png",
      link: "quotes",
    },
    {
      title: t("Homepage.highlights.libraryItems.chessGlossary.title"),
      description: t(
        "Homepage.highlights.libraryItems.chessGlossary.description"
      ),
      image: "library/glossary.png",
      link: "glossary",
    },
    {
      title: t("Homepage.highlights.libraryItems.basicRulesOfPlay.title"),
      description: t(
        "Homepage.highlights.libraryItems.basicRulesOfPlay.description"
      ),
      image: "library/basicrulesofplay.png",
      link: "basicrulesofplay",
    },
    {
      title: t("Homepage.highlights.libraryItems.competitiveRulesOfPlay.title"),
      description: t(
        "Homepage.highlights.libraryItems.competitiveRulesOfPlay.description"
      ),
      image: "library/competitiverulesofplay.png",
      link: "competitiverulesofplay",
    },
  ];

  // Shuffle the items for each carousel
  const shuffledGameItems = shuffleArray([...gameItems]);
  const shuffledToolItems = shuffleArray([...toolItems]);
  const shuffledLibraryItems = shuffleArray([...libraryItems]);
  const shuffledLearnItems = shuffleArray([...learnItems]);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getRandomHighlight = () => {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 0);
    const diff = now - startOfYear;
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);

    let highlights = [
      ...gameItems,
      ...toolItems,
      ...libraryItems,
      ...learnItems,
    ];

    // Filter highlights where the `highlight` field is true
    const filteredHighlights = highlights.filter(
      (item) => item.highlight === true
    );

    const index = dayOfYear % filteredHighlights.length;

    return filteredHighlights[index];
  };

  const highlight = getRandomHighlight();

  return (
    <Box>
      <Helmet>
        <title>Chessboard Magic</title>
        <meta
          name="description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta property="og:title" content="Chessboard Magic" />
        <meta
          property="og:description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta
          property="og:image"
          content="https://chessboardmagic.com/img/chessboardmagic-social.png"
        />
        <meta property="og:url" content="https://chessboardmagic.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Chessboard Magic" />
        <meta
          name="twitter:description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta
          name="twitter:image"
          content="https://chessboardmagic.com/img/chessboardmagic-social.png"
        />
      </Helmet>
      <ContentHeader
        title={t("Homepage.header.title")}
        subtitle={t("Homepage.header.subtitle")}
        color={colors.black[900]}
        backgroundImage="./img/header-background.png"
      />

      {/* Highlight Card */}
      <Card
        sx={{
          width: "100%",
          margin: "auto",
          mb: 3,
          background: "#283593",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.7)",
        }}
      >
        <CardHeader
          avatar={<StarIcon sx={{ color: "#EEEEEE", marginRight: 0 }} />}
          title={
            <Link to={highlight.link} style={{ textDecoration: "none" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Typography variant="h6" sx={{ color: "#EEEEEE" }}>
                  <b>{highlight.title}</b>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#EEEEEE", pt: 0.5, pl: 1 }}
                >
                  {t("Homepage.labels.tryNow")}
                </Typography>
              </Box>
            </Link>
          }
          sx={{
            borderBottom: "0.5px solid #000000",
            p: 1,
            "& .MuiCardHeader-avatar": {
              marginRight: 1,
            },
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            gap: "16px",
          }}
        >
          <div style={{ flex: "1" }} sx={{ p: 2, m: 2 }}>
            <Link to={highlight.link}>
              <CardMedia
                component="img"
                image={`${process.env.PUBLIC_URL}/img/${highlight.image}`}
                alt={highlight.title}
                style={{ width: "100%", height: "auto" }}
              />
            </Link>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <CardContent>
              <Typography color="text.secondary" sx={{ color: "#EEEEEE" }}>
                {highlight.longDescription}
              </Typography>
            </CardContent>
          </div>
        </div>
      </Card>

      {/* Games Section */}
      <Box sx={{ paddingBottom: "10px" }}>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            paddingBottom: "10px",
            color: colors.black[900],
          }}
        >
          <ExtensionRoundedIcon style={{ color: colors.black[900] }} />
          {t("Homepage.labels.games")}
        </Typography>
        <Box sx={{ paddingBottom: "10px" }}>
          <Carousel
            responsive={responsive}
            arrows
            infinite
            draggable={true}
            itemClass="carousel-item-padding-40-px"
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
          >
            {shuffledGameItems.map((item, index) => (
              <MyCard
                key={index}
                title={item.title}
                description={item.description}
                img={item.image}
                link={item.link}
              />
            ))}
          </Carousel>
        </Box>
      </Box>

      {/* Learn Section */}
      <Box sx={{ paddingBottom: "10px" }}>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            paddingBottom: "10px",
            color: colors.black[900],
          }}
        >
          <ExtensionRoundedIcon style={{ color: colors.black[900] }} />
          {t("Homepage.labels.learningAndAnalytics")}
        </Typography>
        <Box sx={{ paddingBottom: "10px" }}>
          <Carousel
            responsive={responsive}
            arrows
            infinite
            draggable={true}
            itemClass="carousel-item-padding-40-px"
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
          >
            {shuffledLearnItems.map((item, index) => (
              <MyCard
                key={index}
                title={item.title}
                description={item.description}
                img={item.image}
                link={item.link}
              />
            ))}
          </Carousel>
        </Box>
      </Box>

      {/* Tools Section */}
      <Box sx={{ paddingBottom: "10px" }}>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            paddingBottom: "10px",
            color: colors.black[900],
          }}
        >
          <HandymanIcon style={{ color: colors.black[900] }} />
          {t("Homepage.labels.tools")}
        </Typography>
        <Box>
          <Carousel
            responsive={responsive}
            arrows
            infinite
            draggable={true}
            itemClass="carousel-item-padding-40-px"
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
          >
            {shuffledToolItems.map((item, index) => (
              <MyCard
                key={index}
                title={item.title}
                description={item.description}
                img={item.image}
                link={item.link}
              />
            ))}
          </Carousel>
        </Box>
      </Box>

      {/* Library Section */}
      <Box sx={{ paddingBottom: "20px" }}>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            paddingBottom: "20px",
            color: colors.black[900],
          }}
        >
          <LocalLibraryRoundedIcon style={{ color: colors.black[900] }} />
          {t("Homepage.labels.library")}
        </Typography>
        <Box>
          <Carousel
            responsive={responsive}
            arrows
            infinite
            draggable={true}
            itemClass="carousel-item-padding-40-px"
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
          >
            {shuffledLibraryItems.map((item, index) => (
              <MyCard
                key={index}
                title={item.title}
                description={item.description}
                img={item.image}
                link={item.link}
              />
            ))}
          </Carousel>
        </Box>
      </Box>
    </Box>
  );
};

// Custom arrow components for the Carousel
const CustomLeftArrow = ({ onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",
        left: 0,
        zIndex: 1,
        cursor: "pointer",
        backgroundColor: "rgba(0,0,0,0.5)",
        color: "#EEEEEE",
        pt: 1,
      }}
    >
      <ChevronLeftRoundedIcon />
    </Box>
  );
};

const CustomRightArrow = ({ onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",
        right: 0,
        zIndex: 1,
        cursor: "pointer",
        backgroundColor: "rgba(0,0,0,0.5)",
        color: "#EEEEEE",
        pt: 1,
      }}
    >
      <ChevronRightRoundedIcon />
    </Box>
  );
};

export default Homepage;
