import React, { createContext, useContext, useState } from "react";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [userRef, setUserRef] = useState(null);

  return (
    <UserContext.Provider
      value={{ userData, setUserData, userRef, setUserRef }}
    >
      {children}
    </UserContext.Provider>
  );
};
