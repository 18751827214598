import React from "react";
import { SvgIcon } from "@mui/material";

function CompetitionIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 1080 1080">
      <g transform="scale(0.8) translate(100, 100)">
        <path d="m764.32 914.46h-330.9c-8.6641 0-15.613-6.9727-15.613-15.602l0.003907-611.17c0-8.6289 6.9492-15.602 15.613-15.602h330.9c8.6641 0 15.613 6.9844 15.613 15.602v611.17c0 8.6289-6.9492 15.602-15.613 15.602zm-315.29-31.215h299.69v-579.96l-299.69 0.003906z" />
        <path d="m1095.3 914.46h-330.96c-8.6055 0-15.613-6.9727-15.613-15.602v-279.8c0-8.6289 7.0078-15.613 15.613-15.613h330.96c8.6641 0 15.613 6.9844 15.613 15.613v279.8c-0.003906 8.6289-6.9492 15.602-15.613 15.602zm-315.35-31.215h299.75v-248.59h-299.75z" />
        <path d="m433.41 914.46h-330.96c-8.5938 0-15.613-6.9727-15.613-15.602v-355.52c0-8.6289 7.0195-15.602 15.613-15.602h330.96c8.6055 0 15.613 6.9844 15.613 15.602v355.52c-0.003906 8.6289-7.0117 15.602-15.617 15.602zm-315.35-31.215h299.75l0.003906-324.3h-299.75z" />
        <path d="m1112.4 987.25h-1024.8c-28.715 0-51.996-23.316-51.996-52.008 0-28.68 23.281-51.996 51.996-51.996h1024.8c28.656 0 51.996 23.316 51.996 51.996-0.003906 28.691-23.344 52.008-52 52.008zm-1024.8-72.793c-11.461 0-20.785 9.3242-20.785 20.785 0 11.461 9.3242 20.785 20.785 20.785h1024.8c11.461 0 20.785-9.3242 20.785-20.785 0-11.461-9.3242-20.785-20.785-20.785z" />
        <path d="m433.41 914.46h-330.96c-8.5938 0-15.613-6.9727-15.613-15.602v-355.52c0-2.5312 0.61328-5.0273 1.8359-7.2461l31.211-59.375c2.6875-5.1484 7.9805-8.3516 13.777-8.3516h299.75c8.6055 0 15.613 6.9844 15.613 15.613v414.9c0 8.6133-7.0078 15.586-15.613 15.586zm-315.35-31.215h299.75l0.003906-383.69h-274.69l-25.055 47.617z" />
        <path d="m764.32 914.46h-330.9c-8.6641 0-15.613-6.9727-15.613-15.602l0.003907-611.17c0-2.5195 0.61328-5.0273 1.7656-7.2461l31.223-59.34c2.6875-5.1484 8.0391-8.3516 13.836-8.3516h268.46c5.7969 0 11.16 3.2031 13.836 8.3516l31.223 59.34c1.1641 2.2305 1.7656 4.7266 1.7656 7.2461v611.17c0.011719 8.6289-6.9375 15.602-15.602 15.602zm-315.29-31.215h299.69v-591.72l-24.996-47.578h-249.7l-24.996 47.578z" />
        <path d="m1095.3 914.46h-330.96c-8.6055 0-15.613-6.9727-15.613-15.602l0.058594-339.16c0-8.6289 6.9492-15.613 15.613-15.613h299.69c5.7969 0 11.16 3.2031 13.836 8.3516l31.223 59.34c1.1641 2.2305 1.7656 4.7383 1.7656 7.2617v279.8c0 8.6406-6.9453 15.613-15.609 15.613zm-315.35-31.215h299.75v-260.36l-24.996-47.578h-274.69z" />
        <path d="m877.34 827.05c-8.6641 0-15.613-6.9727-15.613-15.613v-104.94c0-8.6289 6.9492-15.613 15.613-15.613 8.5938 0 15.602 6.9844 15.602 15.613v104.94c0 8.6406-7.0078 15.613-15.602 15.613z" />
        <path d="m929.82 827.05c-8.6523 0-15.602-6.9727-15.602-15.613v-104.94c0-8.6289 6.9492-15.613 15.602-15.613 8.6055 0 15.613 6.9844 15.613 15.613v104.94c0 8.6406-7.0078 15.613-15.613 15.613z" />
        <path d="m982.31 827.05c-8.6523 0-15.602-6.9727-15.602-15.613v-104.94c0-8.6289 6.9492-15.613 15.602-15.613 8.6055 0 15.613 6.9844 15.613 15.613v104.94c0 8.6406-7.0117 15.613-15.613 15.613z" />
        <path d="m599.96 604.51c-8.5938 0-15.613-6.9844-15.613-15.613v-104.94c0-8.6289 7.0195-15.613 15.613-15.613 8.6641 0 15.613 6.9844 15.613 15.613v104.94c-0.003906 8.625-6.9492 15.609-15.613 15.609z" />
        <path d="m241.7 797.36c-8.6641 0-15.613-6.9844-15.613-15.613l0.003906-104.95c0-8.6289 6.9492-15.613 15.613-15.613 8.5938 0 15.602 6.9844 15.602 15.613v104.95c-0.015625 8.625-7.0234 15.609-15.605 15.609z" />
        <path d="m294.19 797.36c-8.6641 0-15.613-6.9844-15.613-15.613v-104.95c0-8.6289 6.9492-15.613 15.613-15.613 8.5938 0 15.602 6.9844 15.602 15.613v104.95c-0.011719 8.625-7.0195 15.609-15.602 15.609z" />{" "}
      </g>
    </SvgIcon>
  );
}

export default CompetitionIcon;
