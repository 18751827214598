import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Alert,
  Button,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { useShare } from "../../../context/ShareContext";
import { useTranslation } from "react-i18next";

import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import { themeColors } from "../../../styles/boardtheme";
import { pieceSets } from "../../../styles/pieceset";
import { useUser } from "../../../context/UserContext";

import HelpModal from "../../../components/HelpModal";
import TablebaseHelp from "../../../help/TablebaseHelp";

import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";
import ReplayIcon from "@mui/icons-material/Replay";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

function Tablebase() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation("Tools");
  const { userData } = useUser();

  const [orientation, setOrientation] = useState("black");
  const [selectedPiece, setSelectedPiece] = useState(null); // Store dragged piece
  const [fen, setFen] = useState("8/8/8/8/8/8/8/8 w - - 0 1"); // Start with an empty board
  const [loadedFen, setLoadedFen] = useState("");

  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
  const [toPlay, setToPlay] = useState("white"); // default to "white"
  const [tablebaseResponse, setTablebaseResponse] = useState(null);
  const [fenError, setFenError] = useState(null);
  const [mainlineMoves, setMainlineMoves] = useState([]);
  const [started, setStarted] = useState(false);

  const { setShareData } = useShare();

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/tablebase",
      title: "Chessboard Magic - Tablebase",
      description:
        "Analyze any position to find the best possible moves and outcomes instantly with our advanced Tablebase checker.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadMove = useCallback(
    (moveIndex) => {
      const newGame = new Chess(loadedFen); // Start from the original FEN (loadedFen)
      let moveIsValid = true;

      // Apply the moves step by step up to the moveIndex
      for (let i = 0; i <= moveIndex; i++) {
        const move = mainlineMoves[i].san; // Use the SAN notation for moves

        const result = newGame.move(move); // Try to apply the move
        if (!result) {
          moveIsValid = false;
          break;
        }
      }

      if (moveIsValid) {
        setFen(newGame.fen()); // Only set the FEN if all moves are valid
        setCurrentMoveIndex(moveIndex); // Update current index
      } else {
      }
    },
    [loadedFen, mainlineMoves]
  );

  const handleNextMove = useCallback(() => {
    if (currentMoveIndex < mainlineMoves.length - 1) {
      loadMove(currentMoveIndex + 1); // Load the next move
    }
  }, [currentMoveIndex, mainlineMoves, loadMove]);

  const handlePreviousMove = useCallback(() => {
    if (currentMoveIndex > 0) {
      loadMove(currentMoveIndex - 1); // Load the previous move
    }
  }, [currentMoveIndex, loadMove]);

  const goToMove = useCallback(
    (moveIndex) => {
      const newGame = new Chess(loadedFen); // Start from the loaded FEN position

      // Apply the moves step by step up to the selected move index
      for (let i = 0; i <= moveIndex; i++) {
        newGame.move(mainlineMoves[i].san);
      }

      setFen(newGame.fen()); // Set the FEN to the current game position
      setCurrentMoveIndex(moveIndex); // Set the current move index to the clicked move
    },
    [loadedFen, mainlineMoves] // Dependencies for the useCallback hook
  );

  const setFenTurn = (fen, toPlay) => {
    const fenParts = fen.split(" "); // FEN string has 6 parts separated by spaces
    fenParts[1] = toPlay === "white" ? "w" : "b"; // Modify the turn to play based on the `toPlay` state
    return fenParts.join(" "); // Join the parts back together
  };

  useEffect(() => {
    // Ensure the turn in the FEN matches the current 'toPlay' state whenever FEN or toPlay changes
    const updatedFen = setFenTurn(fen, toPlay);
    setFen(updatedFen); // Update the FEN with the correct turn
  }, [fen, toPlay]);

  const handleResetGame = () => {
    setCurrentMoveIndex(-1);
    setFen(loadedFen); // Set the FEN to the starting position
  };

  // Add keyboard event listeners for left and right arrow keys
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        handlePreviousMove();
      } else if (event.key === "ArrowRight") {
        handleNextMove();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentMoveIndex, handleNextMove, handlePreviousMove]); // Dependencies to ensure state is up-to-date

  const handlePieceDrop = (sourceSquare, targetSquare, piece) => {
    // Convert FEN to board array
    const board = fenToBoardArray(fen);

    // Map square notation to array index
    const fileToIndex = (file) => file.charCodeAt(0) - "a".charCodeAt(0);
    const rankToIndex = (rank) => 8 - parseInt(rank);

    const srcRow = rankToIndex(sourceSquare[1]);
    const srcCol = fileToIndex(sourceSquare[0]);

    // If the targetSquare is null or undefined, remove the piece from the source square
    if (!targetSquare) {
      board[srcRow][srcCol] = null;
    } else {
      const tgtRow = rankToIndex(targetSquare[1]);
      const tgtCol = fileToIndex(targetSquare[0]);

      // Move the piece from source to target
      board[tgtRow][tgtCol] = board[srcRow][srcCol];
      board[srcRow][srcCol] = null; // Remove the piece from the source square
    }

    // Convert board array back to FEN
    const newFen = boardArrayToFen(board);

    // Update the FEN and re-render the board
    setFen(newFen);

    // Return true to indicate a valid drop
    return true;
  };

  const handleSquareClick = (square) => {
    if (!selectedPiece) return; // Don't do anything if no piece is selected

    // Convert FEN to board array
    const board = fenToBoardArray(fen);

    // Map square notation to array index
    const fileToIndex = (file) => file.charCodeAt(0) - "a".charCodeAt(0);
    const rankToIndex = (rank) => 8 - parseInt(rank);

    const row = rankToIndex(square[1]);
    const col = fileToIndex(square[0]);

    // If selected piece is "D" (delete), remove the piece from the board
    if (selectedPiece.type === "D") {
      board[row][col] = null; // Remove the piece from the square
    } else {
      // Otherwise, place the selected piece on the clicked square
      board[row][col] =
        selectedPiece.color === "w"
          ? selectedPiece.type.toUpperCase()
          : selectedPiece.type;
    }

    // Convert board array back to FEN
    const newFen = boardArrayToFen(board);

    // Update the FEN and re-render the board
    setFen(newFen);
  };

  const fenToBoardArray = (fen) => {
    const [position] = fen.split(" "); // Split FEN into board position and other data
    const rows = position.split("/"); // Split into individual rows
    const board = rows.map((row) => {
      let expandedRow = [];
      for (let char of row) {
        if (isNaN(char)) {
          // It's a piece, push it to the row
          expandedRow.push(char);
        } else {
          // It's a number, push empty squares
          expandedRow = [...expandedRow, ...Array(parseInt(char)).fill(null)];
        }
      }
      return expandedRow;
    });
    return board;
  };

  const countPiecesFromFen = (fen) => {
    // Extract the board position part from the FEN (the first part of the string)
    const fenPosition = fen.split(" ")[0];

    // Initialize an object to store the count of pieces
    const pieceCount = {
      total: 0,
      white: 0,
      black: 0,
    };

    // Iterate over each character in the FEN position part
    for (const char of fenPosition) {
      if (/[prnbqk]/.test(char)) {
        // Lowercase characters represent black pieces
        pieceCount.black += 1;
        pieceCount.total += 1;
      } else if (/[PRNBQK]/.test(char)) {
        // Uppercase characters represent white pieces
        pieceCount.white += 1;
        pieceCount.total += 1;
      }
      // Numbers represent empty squares, so we skip them
    }

    return pieceCount;
  };

  const boardArrayToFen = (board) => {
    const fenRows = board.map((row) => {
      let emptyCount = 0;
      return (
        row
          .map((cell) => {
            if (cell === null) {
              emptyCount++;
              return "";
            } else {
              if (emptyCount > 0) {
                const result = emptyCount + cell;
                emptyCount = 0;
                return result;
              }
              return cell;
            }
          })
          .join("") + (emptyCount > 0 ? emptyCount : "")
      );
    });
    return fenRows.join("/") + " w - - 0 1"; // You can adjust this based on actual game data
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(fen);
  };

  const handleCheck = useCallback(async () => {
    const chess = new Chess(); // Create a Chess instance

    try {
      // Try loading the FEN into the chess instance, which validates it
      chess.load(fen);

      const pieceCount = countPiecesFromFen(fen);

      if (pieceCount.total > 7) {
        // Show an alert if the number of pieces is greater than 7
        setFenError(
          "The number of pieces on the board is greater than 7. Tablebase API only supports 7 or fewer pieces."
        );
        return; // Don't proceed with the API calls if the condition is met
      }

      setFenError(null); // Clear any existing error if the FEN is valid

      // Encode the FEN to ensure it is URL safe
      const encodedFen = encodeURIComponent(fen);

      setLoadedFen(fen);

      // Fetch standard endpoint with status check
      const standardResponse = await fetch(
        `https://tablebase.lichess.ovh/standard?fen=${encodedFen}`
      );

      if (!standardResponse.ok) {
        throw new Error(t("Tablebase.error"));
      }

      const standardData = await standardResponse.json();

      // Fetch mainline endpoint with status check
      const mainlineResponse = await fetch(
        `https://tablebase.lichess.ovh/standard/mainline?fen=${encodedFen}`
      );

      if (!mainlineResponse.ok) {
        throw new Error(t("Tablebase.error"));
      }

      const mainlineData = await mainlineResponse.json();

      // Parse the mainline data to ensure it's an array of move objects
      const mainlineMoves = Array.isArray(mainlineData.mainline)
        ? mainlineData.mainline.map((move) => ({
            san: move.san,
            uci: move.uci,
            dtz: move.dtz,
            precise_dtz: move.precise_dtz,
          }))
        : [];

      // Parse the standard data to extract relevant fields
      let { category, checkmate, stalemate, insufficient_material } =
        standardData;

      // Map the category to human-readable format
      if (category === "win") {
        category = "Win";
      } else if (category === "loss") {
        category = "Loss";
      } else if (category === "draw") {
        category = "Draw";
      }

      // Set the response with parsed standard data and mainline data
      setTablebaseResponse({
        standard: {
          category,
          checkmate: checkmate ? "Yes" : "No",
          stalemate: stalemate ? "Yes" : "No",
          insufficient_material: insufficient_material ? "Yes" : "No",
        },
        mainline: mainlineMoves, // Use the parsed mainline moves
      });

      // Store the parsed mainline moves in state and reset the move index to the start
      setMainlineMoves(mainlineMoves);
      setCurrentMoveIndex(-1); // Reset to the start of the mainline
      setStarted(true);
    } catch (error) {
      // Log the full error for debugging

      // Set the error message to display in the Alert
      setFenError(error.message || "An unexpected error occurred.");
    }
  }, [fen, t]);

  const toggleOrientation = () => {
    setOrientation((prevOrientation) =>
      prevOrientation === "white" ? "black" : "white"
    );
  };

  const renderDraggablePieces = () => {
    const pieces = ["p", "r", "n", "b", "q", "k"];
    const selectedPieceSet = pieceSets[userData?.pieceset || "Maestro"]; // Access the selected piece set

    const handleClearBoard = () => {
      setFen("8/8/8/8/8/8/8/8 w - - 0 1"); // Reset the FEN to an empty board
    };

    return (
      <Box display="flex" flexDirection="column">
        <Typography>{t("Tablebase.helpText")}</Typography>
        {/* White Pieces */}
        <Box>
          <Box display="flex" flexDirection="row">
            {pieces.map((piece) => (
              <div
                key={`w${piece}`}
                onClick={() => setSelectedPiece({ type: piece, color: "w" })}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                  margin: "5px",
                  paddingTop: "3px",
                  textAlign: "center",
                  lineHeight: "40px",
                  backgroundColor:
                    selectedPiece?.type === piece &&
                    selectedPiece?.color === "w"
                      ? "lightgreen"
                      : "transparent", // Remove background color for unselected pieces
                  boxShadow:
                    selectedPiece?.type === piece &&
                    selectedPiece?.color === "w"
                      ? "0px 0px 10px 2px rgba(0, 0, 0, 0.3)"
                      : "none", // Remove shadow for unselected pieces
                }}
              >
                {selectedPieceSet[`w${piece.toUpperCase()}`]({
                  squareWidth: 40, // Set the size of the piece
                })}
              </div>
            ))}
          </Box>
        </Box>

        {/* Black Pieces */}
        <Box>
          <Box display="flex" flexDirection="row">
            {pieces.map((piece) => (
              <div
                key={`b${piece}`}
                onClick={() => setSelectedPiece({ type: piece, color: "b" })}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                  margin: "5px",
                  paddingTop: "3px",
                  textAlign: "center",
                  lineHeight: "40px",
                  backgroundColor:
                    selectedPiece?.type === piece &&
                    selectedPiece?.color === "b"
                      ? "lightgreen"
                      : "transparent", // Remove background color for unselected pieces
                  boxShadow:
                    selectedPiece?.type === piece &&
                    selectedPiece?.color === "b"
                      ? "0px 0px 10px 2px rgba(0, 0, 0, 0.3)"
                      : "none", // Remove shadow for unselected pieces
                }}
              >
                {selectedPieceSet[`b${piece.toUpperCase()}`]({
                  squareWidth: 40, // Set the size of the piece
                })}
              </div>
            ))}
          </Box>
        </Box>

        {/* Delete Option */}
        <Box>
          <Box display="flex" flexDirection="row">
            <Button
              variant="contained"
              onClick={() => setSelectedPiece({ type: "D" })}
              style={{
                margin: "5px",
                textAlign: "center",
                backgroundColor:
                  selectedPiece?.type === "D" ? "lightcoral" : "transparent", // Highlight when selected
              }}
              startIcon={<DeleteIcon style={{ color: colors.black[900] }} />}
            >
              {t("Tablebase.buttons.removePieces")}
            </Button>

            <Button
              variant="contained"
              onClick={handleClearBoard}
              style={{
                margin: "5px",
                textAlign: "center",
              }}
              startIcon={
                <RestartAltIcon style={{ color: colors.black[900] }} />
              }
            >
              {t("Tablebase.buttons.clearBoard")}
            </Button>
          </Box>
        </Box>

        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth sx={{ width: "150px" }}>
            <Typography>{t("Tablebase.labels.turn")}</Typography>
            <Select
              value={toPlay}
              onChange={(e) => setToPlay(e.target.value)}
              displayEmpty
            >
              <MenuItem value="white">{t("Tablebase.labels.white")}</MenuItem>
              <MenuItem value="black">{t("Tablebase.labels.black")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <ContentHeader
        title={t("Tablebase.header.title")}
        subtitle={t("Tablebase.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[14]}
      />
      <Helmet>
        <title>Tablebase</title>
        <meta
          name="description"
          content="Analyze any position to find the best possible moves and outcomes instantly with our advanced Tablebase checker."
        />
        <meta property="og:title" content="Tablebase" />
        <meta
          property="og:description"
          content="Analyze any position to find the best possible moves and outcomes instantly with our advanced Tablebase checker."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/tools/tablebase.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/tablebase`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tablebase" />
        <meta
          name="twitter:description"
          content="Analyze any position to find the best possible moves and outcomes instantly with our advanced Tablebase checker."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/tools/tablebase.png`}
        />
      </Helmet>
      <Box>
        <Button
          variant="contained"
          onClick={handleCheck}
          style={{ marginRight: 10 }}
          startIcon={<TaskAltIcon style={{ color: colors.black[900] }} />}
          sx={{ mb: 1 }}
        >
          {t("Tablebase.buttons.check")}
        </Button>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          style={{ marginRight: 10 }}
          startIcon={<HelpOutlineIcon style={{ color: colors.black[900] }} />}
          sx={{ mb: 1 }}
        >
          {t("Tablebase.buttons.help")}
        </Button>

        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("Tablebase.helpDetails.title")}
          content={<TablebaseHelp />}
        ></HelpModal>
      </Box>
      <Box
        display="flex"
        mt={2}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
        }}
      >
        <Box flex={1} sx={{ maxWidth: "450px" }}>
          <Chessboard
            position={fen}
            boardOrientation={orientation}
            onPieceDrop={handlePieceDrop}
            onSquareClick={handleSquareClick}
            customLightSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "Modern Minimal"].lightSquare,
              backgroundImage:
                themeColors[userData?.theme || "Modern Minimal"]
                  .lightSquarePattern,
            }}
            customDarkSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "Modern Minimal"].darkSquare,
              backgroundImage:
                themeColors[userData?.theme || "Modern Minimal"]
                  .darkSquarePattern,
            }}
            customPieces={pieceSets[userData?.pieceset || "Maestro"]}
            areArrowsAllowed={false}
            arePiecesDraggable={true}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%", // Ensure the container takes up full width
            }}
          >
            <TextField
              label="Fen"
              value={fen}
              InputProps={{
                readOnly: true,
                disableUnderline: true, // Disable the underline
              }}
              sx={{
                flexGrow: 1, // Allows the TextField to take the remaining space
                "& .MuiFilledInput-root": {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 4,
                  borderBottom: "none", // Remove the bottom border
                },
                "& .MuiInputBase-input": {
                  cursor: "text", // Makes the text selectable
                  fontSize: "0.8rem", // Set the font size here
                },
              }}
              fullWidth
              variant="filled"
              InputLabelProps={{
                style: { color: colors.black[900] },
              }}
            />
          </Box>

          {fenError && (
            <Alert severity="error" onClose={() => setFenError(null)}>
              {fenError}
            </Alert>
          )}

          {/* Display the parsed response */}
          {tablebaseResponse && tablebaseResponse.standard && (
            <Box mt={2}>
              <Typography>
                <b>{t("Tablebase.labels.verdict")}:</b>{" "}
                {tablebaseResponse.standard.category}
                {/* Conditionally render toPlay only if category is not "Draw" */}
                {tablebaseResponse.standard.category !== "Draw" &&
                  ` ${toPlay.charAt(0).toUpperCase() + toPlay.slice(1)}`}
              </Typography>
            </Box>
          )}

          <Box mt={2} style={{ overflow: "auto" }}>
            <Typography style={{ overflowWrap: "break-word" }}>
              {mainlineMoves.map((move, index) => (
                <span
                  key={index}
                  onClick={() => goToMove(index)}
                  style={{
                    cursor: "pointer",
                    textDecoration:
                      index === currentMoveIndex ? "underline" : "none",
                    fontWeight: index === currentMoveIndex ? "bold" : "normal",
                    marginRight: "5px",
                    whiteSpace: "nowrap", // Prevent text from wrapping to the next line
                  }}
                >
                  {`${move.san}`}
                </span>
              ))}
            </Typography>
          </Box>
          {started && (
            <Box sx={{ pt: 2, pb: 2, display: "flex", justifyContent: "left" }}>
              <Tooltip title="Reset the game">
                <Button
                  variant="contained"
                  onClick={handleResetGame}
                  style={{ marginRight: 10 }}
                  sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                >
                  <ReplayIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Go to previous move">
                <Button
                  variant="contained"
                  onClick={handlePreviousMove}
                  sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  style={{ marginRight: 10 }}
                >
                  <KeyboardDoubleArrowLeftIcon
                    style={{ color: colors.black[900] }}
                  />
                </Button>
              </Tooltip>
              <Tooltip title="Go to next move">
                <Button
                  variant="contained"
                  onClick={handleNextMove}
                  sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  style={{ marginRight: 10 }}
                >
                  <KeyboardDoubleArrowRightIcon
                    style={{ color: colors.black[900] }}
                  />
                </Button>
              </Tooltip>
              <Tooltip title="Toggle board orientation">
                <Button
                  variant="contained"
                  onClick={toggleOrientation}
                  style={{ marginRight: 10 }}
                  sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                >
                  <SwapVertRoundedIcon />
                </Button>
              </Tooltip>

              <Tooltip title="Copy FEN to clipboard">
                <Button
                  variant="contained"
                  style={{ marginRight: 10 }}
                  sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  onClick={copyToClipboard}
                >
                  <ContentCopyRoundedIcon />
                </Button>
              </Tooltip>
            </Box>
          )}
        </Box>
        {renderDraggablePieces()}
      </Box>
    </Box>
  );
}

export default Tablebase;
