import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  XIcon,
  WhatsappIcon,
} from "react-share";
import { Box } from "@mui/material";
import { useShare } from "../context/ShareContext";

const ShareComponent = () => {
  const { shareData } = useShare();
  const { url, title, description } = shareData;

  return (
    <Box
      sx={{
        textAlign: "left",
        pl: 1,
        pt: 0.7,
        display: "flex",
        gap: 2,
      }}
    >
      <FacebookShareButton url={url} quote={description} hashtag="#Chess">
        <FacebookIcon
          style={{ borderRadius: "5px", width: "30px", height: "30px" }}
        />
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        title={`${title} - ${description}`}
        hashtag="#Chess"
      >
        <XIcon style={{ borderRadius: "5px", width: "30px", height: "30px" }} />
      </TwitterShareButton>
      <WhatsappShareButton url={url} title={`${title} - ${description}`}>
        <WhatsappIcon
          style={{ borderRadius: "5px", width: "30px", height: "30px" }}
        />
      </WhatsappShareButton>
    </Box>
  );
};

export default ShareComponent;
