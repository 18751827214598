import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ImagePuzzleHelp = () => {
  const { t } = useTranslation("Games");

  return (
    <>
      <Typography>{t("ImagePuzzle.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t(
              "ImagePuzzle.helpDetails.instructions.move_squares.primary"
            )}
            secondary={t(
              "ImagePuzzle.helpDetails.instructions.move_squares.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "ImagePuzzle.helpDetails.instructions.learn_player.primary"
            )}
            secondary={t(
              "ImagePuzzle.helpDetails.instructions.learn_player.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("ImagePuzzle.helpDetails.instructions.new_game.primary")}
            secondary={t(
              "ImagePuzzle.helpDetails.instructions.new_game.secondary"
            )}
          />
        </ListItem>
      </List>
    </>
  );
};

export default ImagePuzzleHelp;
