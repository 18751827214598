export const miniatureGames = [
  {
    Name: "Paul Morphy vs. Duke Karl / Count Isouard (1858)",
    Description:
      "The Opera Game is a well-known chess match that took place in 1858 at the Italian Opera House in Paris. American chess master Paul Morphy played against Duke Karl of Brunswick and Count Isouard, who consulted each other on their moves. Morphy's performance in this game is celebrated for its brilliance and decisiveness, marked by a series of aggressive and precise moves. The game is famous for showcasing Morphy's skills in rapid development, coordinated attacks, and tactical exploitation. The final sequence, where Morphy sacrifices his queen to achieve checkmate, is especially admired and frequently studied for its instructional value. ",
    Site: "Paris, France",
    Date: "1858",
    White: "Morphy, Paul",
    Black: "Duke Karl of Brunswick and Count Isouard",
    Result: "1-0",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "33",
    Moves:
      "1. e4 e5 2. Nf3 d6 3. d4 Bg4 4. dxe5 Bxf3 5. Qxf3 dxe5 6. Bc4 Nf6 7. Qb3 Qe7 8. Nc3 c6 9. Bg5 b5 10. Nxb5 cxb5 11. Bxb5+ Nbd7 12. O-O-O Rd8 13. Rxd7 Rxd7 14. Rd1 Qe6 15. Bxd7+ Nxd7 16. Qb8+ Nxb8 17. Rd8# 1-0",
  },
  {
    Name: "Adolf Anderssen vs. Jean Dufresne (1852)",
    Description:
      "The Evergreen Game is a renowned chess match from 1852 between German chess masters Adolf Anderssen and Jean Dufresne. Known for its remarkable tactics and creative play, Anderssen's win is celebrated for its ingenuity and flair. The game stands out for a brilliant mid-game sequence, featuring a striking queen sacrifice that leads to a decisive checkmate. This match is frequently studied for its excellent demonstration of tactical themes and aggressive strategy, making it one of the most revered and instructive games in chess history. ",
    Site: "Berlin, Germany",
    Date: "1852",
    White: "Anderssen, Adolf",
    Black: "Dufresne, Jean",
    Result: "1-0",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "47",
    Moves:
      "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 exd4 7. O-O d3 8. Qb3 Qf6 9. e5 Qg6 10. Re1 Nge7 11. Ba3 b5 12. Qxb5 Rb8 13. Qa4 Bb6 14. Nbd2 Bb7 15. Ne4 Qf5 16. Bxd3 Qh5 17. Nf6+ gxf6 18. exf6 Rg8 19. Rad1 Qxf3 20. Rxe7+ Nxe7 21. Qxd7+ Kxd7 22. Bf5+ Ke8 23. Bd7+ Kf8 24. Bxe7# 1-0",
  },
  {
    Name: "Robert James Fischer vs Reuben Fine (1963)",
    Description: "",
    Site: "New York, USA",
    Date: "1963",
    White: "Robert James Fischer",
    Black: "Reuben Fine",
    Result: "1-0",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 exd4 7. O-O dxc3 8. Qb3 Qe7 9. Nxc3 Nf6 10. Nd5 Nxd5 11. exd5 Ne5 12. Nxe5 Qxe5 13. Bb2 Qg5 14. h4 Qxh4 15. Bxg7 Rg8 16. Rfe1+ Kd8 17. Qg3 1-0",
  },
  {
    Name: "Jose Raul Capablanca vs NN (1918)",
    Description: "",
    Site: "New York, USA",
    Date: "1918",
    White: "Jose Raul Capablanca",
    Black: "NN",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bc4 Bc5 5. O-O O-O 6. d3 d6 7. Bg5 Bg4 8. Nd5 Nd4 9. Qd2 Qd7 10. Bxf6 Bxf3 11. Ne7+ Kh8 12. Bxg7+ Kxg7 13. Qg5+ Kh8 14. Qf6# 1-0",
  },
  {
    Name: "Edward Lasker vs George Alan Thomas (1912)",
    Description:
      "The legendary chess game between Edward Lasker and Sir George Thomas, played in London in 1912, is renowned for its spectacular King walk. In this dramatic encounter, Lasker, an accomplished chess master, executed a remarkable sequence of moves that forced Thomas's king to march across the board from its initial position to the other side, ultimately leading to a checkmate. This extraordinary game is celebrated for Lasker's creative and aggressive play, as well as the rare and memorable sight of a king being driven across the board. The game remains a classic example of strategic ingenuity and tactical brilliance in chess history. ",
    Site: "London, England",
    Date: "1912",
    White: "Lasker, Edward",
    Black: "Thomas, George Alan",
    Result: "1-0",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "35",
    Moves:
      "1. d4 e6 2. Nf3 f5 3. Nc3 Nf6 4. Bg5 Be7 5. Bxf6 Bxf6 6. e4 fxe4 7. Nxe4 b6 8. Ne5 O-O 9. Bd3 Bb7 10. Qh5 Qe7 11. Qxh7+ Kxh7 12. Nxf6+ Kh6 13. Neg4+ Kg5 14. h4+ Kf4 15. g3+ Kf3 16. Be2+ Kg2 17. Rh2+ Kg1 18. Kd2# 1-0",
  },
  {
    Name: "Mikhail Tal vs Sviridov (1969)",
    Description: "",
    Site: "Stuttgart, Germany",
    Date: "1969",
    White: "Mikhail Tal",
    Black: "Sviridov",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3 Nc6 8. Qd2 Bd7 9. O-O-O Qa5 10. Kb1 Rc8 11. g4 h6 12. h4 a6 13. Be2 Ne5 14. g5 hxg5 15. hxg5 Rxh1 16. gxf6 Rxd1+ 17. Nxd1 Qxd2 18. fxg7 1-0",
  },
  {
    Name: "Paul Keres vs Verbac (1933)",
    Description: "",
    Site: "",
    Date: "1933",
    White: "Paul Keres",
    Black: "Verbac",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. d4 d5 2. e4 e6 3. Be3 dxe4 4. Nd2 f5 5. f3 exf3 6. Ngxf3 Nf6 7. Bd3 c5 8. O-O cxd4 9. Nxd4 f4 10. Rxf4 e5 11. Bb5+ Kf7 12. Qh5+ g6 13. Bc4+ Kg7 14. Qh6+ Kxh6 15. Rh4+ Kg7 16. Bh6# 1-0",
  },
  {
    Name: "David Bronstein vs Efim Geller (1961)",
    Description: "",
    Site: "",
    Date: "1961",
    White: "David Bronstein",
    Black: "Efim Geller",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. a3 Bxc3+ 5. bxc3 O-O 6. f3 d5 7. cxd5 exd5 8. e3 Bf5 9. Ne2 Nbd7 10. Nf4 c5 11. Bd3 Bxd3 12. Qxd3 Re8 13. O-O Rc8 14. Rb1 Qa5 15. Rxb7 Nb6 16. g4 h6 17. h4 cxd4 18. g5 dxe3 19. gxf6 Rxc3 20. Qg6 1-0",
  },
  {
    Name: "Bent Larsen vs Boris Spassky (1970)",
    Description: "",
    Site: "",
    Date: "1970",
    White: "Bent Larsen",
    Black: "Boris Spassky",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. b3 e5 2. Bb2 Nc6 3. c4 Nf6 4. Nf3 e4 5. Nd4 Bc5 6. Nxc6 dxc6 7. e3 Bf5 8. Qc2 Qe7 9. Be2 O-O-O 10. f4 Ng4 11. g3 h5 12. h3 h4 13. hxg4 hxg3 14. Rg1 Rh1 15. Rxh1 g2 16. Rf1 Qh4+ 17. Kd1 gxf1=Q+ 0-1",
  },
  {
    Name: "Johannes Zukertort vs NN (1877)",
    Description: "",
    Site: "",
    Date: "1877",
    White: "Johannes Zukertort",
    Black: "NN",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. e4 e5 2. Nc3 Nf6 3. f4 d6 4. Nf3 Nc6 5. Bc4 Bg4 6. O-O Be7 7. d3 Nh5 8. fxe5 Nxe5 9. Nxe5 Bxd1 10. Bxf7+ Kf8 11. Bxh5+ Bf6 12. Rxf6+ gxf6 13. Bh6+ Ke7 14. Nd5+ Ke6 15. Bf7+ Kxe5 16. c3 1-0",
  },
  {
    Name: "Vladimir Kramnik vs Alexander Beliavsky (1996)",
    Description: "",
    Site: "",
    Date: "",
    White: "Vladimir Kramnik",
    Black: "Alexander Beliavsky",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. Nf3 d5 2. g3 c6 3. Bg2 Bg4 4. O-O Nd7 5. d4 e6 6. Nbd2 f5 7. c4 Bd6 8. Qb3 Rb8 9. Re1 Nh6 10. cxd5 cxd5 11. h3 Bh5 12. e4 fxe4 13. Ng5 Bf7 14. Ndxe4 dxe4 15. Nxe6 Bxe6 16. Qxe6+ Qe7 17. Rxe4 Kd8 18. Qd5 1-0",
  },
  {
    Name: "Mikhail Tal vs Georgi Tringov (1964)",
    Description: "",
    Site: "",
    Date: "1964",
    White: "Mikhail Tal",
    Black: "Georgi Tringov",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. e4 g6 2. d4 Bg7 3. Nc3 d6 4. Nf3 c6 5. Bg5 Qb6 6. Qd2 Qxb2 7. Rb1 Qa3 8. Bc4 Qa5 9. O-O e6 10. Rfe1 a6 11. Bf4 e5 12. dxe5 dxe5 13. Qd6 Qxc3 14. Red1 Nd7 15. Bxf7+ Kxf7 16. Ng5+ Ke8 17. Qe6+ 1-0",
  },
  {
    Name: "Henry Edward Bird vs Wilhelm Steinitz (1868)",
    Description: "",
    Site: "",
    Date: "1868",
    White: "Henry Edward Bird",
    Black: "Wilhelm Steinitz",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d4 exd4 5. e5 Ne4 6. Nxd4 Be7 7. O-O Nxd4 8. Qxd4 Nc5 9. f4 b6 10. f5 Nb3 11. Qe4 Nxa1 12. f6 Bc5+ 13. Kh1 Rb8 14. e6 1-0",
  },
  {
    Name: "Captain William Davies Evans vs Alexander McDonnell (1829)",
    Description: "",
    Site: "",
    Date: "1829",
    White: "Captain William Davies Evans",
    Black: "Alexander McDonnell",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bc5 6. O-O Nf6 7. d4 exd4 8. cxd4 Bb6 9. e5 d5 10. exf6 dxc4 11. Re1+ Kf8 12. Ba3+ Kg8 13. d5 Na5 14. Be7 Qd7 15. fxg7 Kxg7 16. Qd2 Qg4 17. Qc3+ Kg8 18. Qxh8+ Kxh8 19. Bf6+ Qg7 20. Re8# 1-0",
  },
  {
    Name: "Adolf Anderssen vs Johannes Zukertort (1865)",
    Description: "",
    Site: "",
    Date: "1865",
    White: "Adolf Anderssen",
    Black: "Johannes Zukertort",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O Qe7 6. Nc3 gxf3 7. d4 d6 8. Nd5 Qd7 9. Qxf3 Nc6 10. Qxf4 Nd8 11. Qg3 c6 12. Qxg8 Rxg8 13. Nf6+ Ke7 14. Nxg8+ Ke8 15. Nf6+ Ke7 16. Nxd7 Bxd7 17. Bg5+ 1-0",
  },
  {
    Name: "Adolf Anderssen vs Max Lange (1859)",
    Description: "",
    Site: "",
    Date: "1859",
    White: "Adolf Anderssen",
    Black: "Max Lange",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nd4 4. Nxd4 exd4 5. Bc4 Nf6 6. e5 d5 7. Bb3 Bg4 8. f3 Ne4 9. O-O d3 10. fxg4 Bc5+ 11. Kh1 Ng3+ 12. hxg3 Qg5 13. Rf5 h5 14. gxh5 Qxf5 15. g4 Rxh5+ 16. gxh5 Qe4 17. Qf3 Qh4+ 18. Qh3 Qe1+ 19. Kh2 Bg1+ 0-1",
  },
  {
    Name: "Paul Keres vs William Winter (1935)",
    Description: "",
    Site: "",
    Date: "1935",
    White: "Paul Keres",
    Black: "William Winter",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. e4 c5 2. Nf3 Nf6 3. e5 Nd5 4. Nc3 e6 5. Nxd5 exd5 6. d4 d6 7. Bg5 Qa5+ 8. c3 cxd4 9. Bd3 dxc3 10. O-O cxb2 11. Rb1 dxe5 12. Nxe5 Bd6 13. Nxf7 Kxf7 14. Qh5+ g6 15. Bxg6+ hxg6 16. Qxh8 Bf5 17. Rfe1 Be4 18. Rxe4 dxe4 19. Qf6+ 1-0",
  },
  {
    Name: "James McConnell vs Paul Morphy (1850)",
    Description: "",
    Site: "",
    Date: "1850",
    White: "James McConnell",
    Black: "Paul Morphy",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. f4 Qb6 6. Nf3 Bd7 7. a3 Nh6 8. b4 cxd4 9. cxd4 Rc8 10. Bb2 Nf5 11. Qd3 Bxb4+ 12. axb4 Nxb4 13. Qd2 Rc2 14. Qd1 Ne3 0-1",
  },
  {
    Name: "Paul Morphy vs Eugene Rousseau (1849)",
    Description: "",
    Site: "",
    Date: "1849",
    White: "Paul Morphy",
    Black: "Eugene Rousseau",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ng5 h6 6. Nxf7 Kxf7 7. Qxg4 Qf6 8. Bc4+ Ke7 9. Nc3 c6 10. e5 Qxe5+ 11. Kd1 Kd8 12. Re1 Qc5 13. Bxg8 d5 14. Re8+ Kxe8 15. Qxc8+ Ke7 16. Nxd5+ Kd6 17. Qc7# 1-0",
  },
  {
    Name: "Per Ofstad vs Wolfgang Uhlmann (1963)",
    Description: "",
    Site: "",
    Date: "1963",
    White: "Per Ofstad",
    Black: "Wolfgang Uhlmann",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 Qxd5 5. Ngf3 cxd4 6. Bc4 Qd6 7. O-O Nc6 8. Re1 a6 9. a4 Qc7 10. Ne4 Bd7 11. Nxd4 Be7 12. Nf5 exf5 13. Nd6+ Kf8 14. Nxf7 Be8 15. Qd5 Qa5 16. Qe6 Nd4 17. Ng5 Bxg5 18. Qd6+ Be7 19. Rxe7 1-0",
  },
  {
    Name: "John van der Wiel vs Sergei Tiviakov (2001)",
    Description: "",
    Site: "",
    Date: "2001",
    White: "John van der Wiel",
    Black: "Sergei Tiviakov",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. g3 Bg4 6. Bg2 Qd7 7. O-O O-O-O 8. Nbd2 h5 9. b4 Nxb4 10. a3 Na6 11. Nb3 d3 12. Be3 Ne7 13. Na5 b6 14. e6 Qd6 15. Ng5 dxe2 16. Qa4 exf1=Q+ 17. Rxf1 fxe6 18. Nb7 Qd3 19. Qxa6 1-0",
  },
  {
    Name: "Alexander Morozevich vs Loek van Wely (2002)",
    Description: "",
    Site: "",
    Date: "2002",
    White: "Alexander Morozevich",
    Black: "Loek van Wely",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nd7 5. Bd3 Ngf6 6. Qe2 c5 7. Nxf6+ Nxf6 8. dxc5 Bxc5 9. Bd2 O-O 10. O-O-O Qd5 11. Kb1 Qxg2 12. Nf3 Qxf2 13. Qe5 Nd7 14. Bxh7+ Kxh7 15. Qh5+ Kg8 16. Rhg1 Be3 17. Bxe3 Qxe3 18. Rg3 Qc5 19. Qh6 1-0",
  },
  {
    Name: "Alexey Shirov vs Jerzy Lapinski (1990)",
    Description: "",
    Site: "",
    Date: "1990",
    White: "Alexey Shirov",
    Black: "Jerzy Lapinski",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O gxf3 6. Qxf3 Qf6 7. e5 Qxe5 8. Bxf7+ Kxf7 9. d4 Qxd4+ 10. Be3 Qf6 11. Bxf4 Ke8 12. Nc3 Nc6 13. Nd5 Qg6 14. Rae1+ Be7 15. Bd6 Kd8 16. Qf8+ Bxf8 17. Bxc7# 1-0",
  },
  {
    Name: "Jonathan David Tisdall vs Graham Lee (1981)",
    Description: "",
    Site: "",
    Date: "1981",
    White: "Jonathan David Tisdall",
    Black: "Graham Lee",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. d4 e6 2. e4 c5 3. Nf3 cxd4 4. Nxd4 Nf6 5. Nc3 Bb4 6. e5 Ne4 7. Qg4 Nxc3 8. Qxg7 Rf8 9. a3 Nb5+ 10. axb4 Nxd4 11. Bg5 Qb6 12. Bd3 Nf5 13. Bxf5 exf5 14. O-O-O Qg6 15. e6 d5 16. Rxd5 Nc6 17. e7 Nxe7 18. Rd8+ Kxd8 19. Qxf8+ 1-0",
  },
  {
    Name: "Charles Wreford Brown vs Gibbs (1918)",
    Description: "",
    Site: "",
    Date: "1918",
    White: "Charles Wreford Brown",
    Black: "Gibbs",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O Nf6 5. d4 exd4 6. e5 d5 7. exf6 dxc4 8. Re1+ Kf8 9. Bg5 gxf6 10. Bh6+ Kg8 11. Nc3 Bg4 12. Ne4 Bb6 13. Qe2 Ne5 14. Nxe5 Bxe2 15. Nd7 1-0",
  },
  {
    Name: "Fabiano Caruana vs Alireza Firouzja (2024)",
    Description: "",
    Site: "",
    Date: "2024",
    White: "Fabiano Caruana",
    Black: "Alireza Firouzja",
    Result: "",
    WhiteElo: "",
    BlackElo: "",
    PlyCount: "",
    Moves:
      "1. c4 c6 2. Nf3 d5 3. g3 Bg4 4. Ne5 Bf5 5. Qb3 Qb6 6. cxd5 Qxb3 7. axb3 Be4 8. dxc6 Bxh1 9. Rxa7 1-0",
  },
];
