import React from "react";
import { SvgIcon } from "@mui/material";

function GuessTheMoveIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 1080 1080">
      <g transform="scale(1) translate(-100)">
        <path d="m732.24 675.37c-16.688-2.2969-33.234 1.9219-46.688 12.141-13.453 10.078-22.078 24.844-24.469 41.531-0.9375 6.6094 3.6094 12.609 10.219 13.547h1.6875c5.8594 0 11.062-4.3125 11.859-10.312 1.4531-10.312 6.8438-19.453 15.141-25.688s18.609-8.8594 28.922-7.4531c6.6094 0.9375 12.609-3.7031 13.547-10.219 0.9375-6.6094-3.6094-12.609-10.219-13.547z" />
        <path d="m784.78 648.24c4.9219-4.4531 5.3906-12 1.0781-16.922-14.297-16.078-12.703-40.781 3.375-54.938 4.9219-4.4531 5.3906-12 1.0781-16.922-4.4531-5.0625-12-5.3906-16.922-1.0781-26.062 23.062-28.453 62.859-5.3906 88.922 2.3906 2.625 5.625 4.0781 9 4.0781 2.8594 0 5.625-0.9375 7.9219-3z" />
        <path d="m666.84 586.08c9.8438 3.375 17.766 10.453 22.312 19.781 4.5469 9.375 5.1562 19.922 1.7812 29.766-2.1562 6.2344 1.2188 13.078 7.4531 15.234 1.3125 0.46875 2.625 0.70312 3.9375 0.70312 4.9219 0 9.6094-3.1406 11.391-8.0625 5.5312-15.844 4.4531-33-2.8594-48.141s-20.156-26.531-36-32.062c-6.2344-2.1562-13.078 1.2188-15.234 7.4531s1.2188 13.078 7.4531 15.234z" />
        <path d="m711.94 536.16c1.2188 0.375 2.3906 0.60938 3.6094 0.60938 5.0625 0 9.8438-3.2344 11.391-8.3906 2.0625-6.375-1.4531-13.078-7.7812-15.141-9.9375-3.1406-18-9.9375-22.781-19.219-4.7812-9.2344-5.625-19.781-2.5312-29.766 2.0625-6.375-1.4531-13.078-7.7812-15.141-6.375-2.0625-13.078 1.4531-15.141 7.7812-5.0625 16.078-3.6094 33.141 4.0781 48s20.766 25.922 36.844 31.078z" />
        <path d="m823.45 514.08c4.0781-10.078 6.2344-21 6.2344-32.062 0-38.297-26.062-71.859-62.062-82.219-0.375-41.391-34.078-75-75.609-75s-75.609 33.938-75.609 75.609v399.37c0 41.625 33.938 75.609 75.609 75.609 37.219 0 68.156-27 74.391-62.391 52.781-2.1562 95.297-45.609 95.297-99 0-17.062-4.5469-33.469-12.703-48.234 12.703-18.375 19.547-40.312 19.547-62.766 0-35.156-17.156-68.156-45.141-88.781zm-56.625 274.78c-1.5469-10.922-5.3906-21.375-11.531-30.609 0.23438-1.2188 0.375-2.3906 0.375-3.7031 0-10.547-8.5312-19.219-19.219-19.219s-19.219 8.5312-19.219 19.219 8.5312 19.078 19.078 19.078c4.5469 7.7812 7.3125 16.688 7.3125 26.062 0 28.453-23.156 51.609-51.609 51.609s-51.609-23.156-51.609-51.609l0.046875-399.37c0-28.453 23.156-51.609 51.609-51.609s51.609 23.156 51.609 51.609c0 9.8438-2.8594 19.219-8.0625 27.375-10.219 0.46875-18.234 8.7656-18.234 19.078s8.5312 19.219 19.219 19.219 19.219-8.5312 19.219-19.219c0-1.6875-0.23438-3.2344-0.60938-4.6875 3.7031-5.7656 6.8438-11.859 8.8594-18.375 24.375 8.3906 41.766 31.781 41.766 58.312 0 6.7031-1.2188 13.312-3.375 19.688-6.6094-2.8594-13.453-5.0625-20.531-6.6094-3.375-5.625-9.4688-9.6094-16.453-9.6094-10.547 0-19.219 8.5312-19.219 19.219s8.5312 19.219 19.219 19.219c4.9219 0 9.375-1.9219 12.703-4.9219 8.3906 2.0625 16.453 5.1562 24 9.6094 26.156 15.469 42.469 44.062 42.469 74.391 0 20.062-6.6094 38.859-19.219 54.375-6.9375 8.5312-15.375 15.609-24.844 20.859-2.625-1.3125-5.5312-2.1562-8.625-2.1562-10.547 0-19.219 8.5312-19.219 19.219s8.5312 19.219 19.219 19.219c8.7656 0 16.078-5.8594 18.375-13.922 7.7812-4.2188 15-9.4688 21.609-15.375 3.8438 9.1406 5.8594 18.844 5.8594 28.781 0 39.938-31.453 72.703-70.922 75z" />
        <path d="m514.31 687.47c-13.453-10.078-30-14.391-46.688-12.141-6.6094 0.9375-11.156 6.9375-10.219 13.547s6.9375 11.156 13.547 10.219c10.312-1.4531 20.531 1.2188 28.781 7.4531 8.2969 6.2344 13.688 15.375 15.141 25.688 0.84375 6 6 10.312 11.859 10.312h1.6875c6.6094-0.9375 11.156-6.9375 10.219-13.547-2.2969-16.688-11.062-31.453-24.469-41.531z" />
        <path d="m409.78 559.31c-4.4531 4.9219-3.9375 12.609 1.0781 16.922 7.7812 6.8438 12.469 16.453 13.078 26.766 0.60938 10.453-2.7656 20.391-9.7031 28.219-4.4531 4.9219-3.9375 12.609 1.0781 16.922 2.2969 2.0625 5.1562 3 7.9219 3 3.375 0 6.6094-1.3125 9-4.0781 11.156-12.609 16.688-28.781 15.703-45.609-0.9375-16.781-8.5312-32.156-21.141-43.312-4.9219-4.4531-12.469-3.9375-16.922 1.0781z" />
        <path d="m525.37 563.39c-32.766 11.391-50.297 47.297-38.859 80.062 1.6875 4.9219 6.375 8.0625 11.391 8.0625 1.3125 0 2.625-0.23438 3.9375-0.70312 6.2344-2.1562 9.6094-9 7.4531-15.234-6.9375-20.297 3.8438-42.469 24.141-49.547 6.2344-2.1562 9.6094-9 7.4531-15.234s-9-9.6094-15.234-7.4531z" />
        <path d="m484.45 536.63c1.2188 0 2.3906-0.23438 3.6094-0.60938 16.078-5.0625 29.156-16.078 36.844-31.078 7.6875-14.859 9.2344-32.062 4.0781-48-2.0625-6.375-8.7656-9.8438-15.141-7.7812s-9.8438 8.7656-7.7812 15.141c3.1406 9.9375 2.2969 20.531-2.5312 29.766-4.7812 9.2344-12.844 16.078-22.781 19.219-6.375 2.0625-9.8438 8.7656-7.7812 15.141 1.6875 5.1562 6.375 8.3906 11.391 8.3906z" />
        <path d="m507.94 324.71c-41.531 0-75.234 33.609-75.609 75-36 10.453-62.062 43.922-62.062 82.219 0 11.062 2.1562 21.844 6.2344 32.062-27.938 20.625-45.141 53.766-45.141 88.781 0 22.453 6.9375 44.391 19.547 62.766-8.2969 14.766-12.703 31.078-12.703 48.234 0 53.391 42.375 96.844 95.297 99 6.2344 35.391 37.219 62.391 74.391 62.391 41.625 0 75.609-33.938 75.609-75.609l0.046875-399.24c0-41.625-33.938-75.609-75.609-75.609zm51.609 474.94c0 28.453-23.156 51.609-51.609 51.609s-51.609-23.156-51.609-51.609c0-9.375 2.625-18.234 7.3125-26.062 10.547 0 19.078-8.625 19.078-19.078s-8.5312-19.219-19.219-19.219-19.219 8.5312-19.219 19.219c0 1.3125 0.14062 2.5312 0.375 3.7031-6 9.2344-9.9375 19.547-11.531 30.609-39.469-2.2969-70.922-34.922-70.922-75 0-10.078 2.1562-19.781 5.8594-28.781 6.6094 6 13.781 11.156 21.609 15.375 2.2969 8.0625 9.6094 13.922 18.375 13.922 10.547 0 19.219-8.5312 19.219-19.219s-8.5312-19.219-19.219-19.219c-3.1406 0-6.1406 0.84375-8.625 2.1562-9.4688-5.3906-17.859-12.375-24.844-20.859-12.609-15.469-19.219-34.312-19.219-54.375 0-30.375 16.219-58.922 42.469-74.391 7.5469-4.4531 15.609-7.5469 24-9.6094 3.375 3 7.7812 4.9219 12.703 4.9219 10.547 0 19.219-8.5312 19.219-19.219s-8.5312-19.219-19.219-19.219c-7.0781 0-13.219 3.8438-16.547 9.6094-6.9375 1.5469-13.922 3.7031-20.531 6.6094-2.1562-6.375-3.375-12.938-3.375-19.688 0-26.531 17.297-49.922 41.766-58.312 2.1562 6.4688 5.1562 12.609 8.8594 18.375-0.375 1.5469-0.60938 3.1406-0.60938 4.6875 0 10.547 8.5312 19.219 19.219 19.219s19.219-8.5312 19.219-19.219-8.1562-18.609-18.234-19.078c-5.1562-8.1562-8.0625-17.391-8.0625-27.375 0-28.453 23.156-51.609 51.609-51.609s51.609 23.156 51.609 51.609v399.37z" />
        <path d="m1053.5 612c6.6094 0 12-5.3906 12-12s-5.3906-12-12-12h-114.84v-125.39h71.391c4.7812 12.609 17.062 21.703 31.312 21.703 18.609 0 33.703-15.141 33.703-33.609s-15.141-33.703-33.703-33.703c-14.391 0-26.531 9-31.312 21.609h-71.391v-21.609c0-85.781-69.844-155.63-155.63-155.63h-21.609v-114.84c0-6.6094-5.3906-12-12-12s-12 5.3906-12 12v114.84h-125.39v-114.84c0-6.6094-5.3906-12-12-12s-12 5.3906-12 12v114.84h-125.39v-71.391c12.609-4.7812 21.609-17.062 21.609-31.312 0-18.609-15.141-33.609-33.609-33.609s-33.703 15.141-33.703 33.609c0 14.391 9 26.531 21.703 31.312v71.391h-21.609c-85.781 0-155.63 69.844-155.63 155.63v21.609h-114.84c-6.6094 0-12 5.3906-12 12s5.3906 12 12 12h114.84v125.39h-114.84c-6.6094 0-12 5.3906-12 12s5.3906 12 12 12h114.84v125.39h-71.391c-4.7812-12.609-17.062-21.703-31.312-21.703-18.609 0-33.703 15.141-33.703 33.703 0 18.609 15.141 33.609 33.703 33.609 14.391 0 26.531-9 31.312-21.609h71.391v21.609c0 85.781 69.844 155.63 155.63 155.63h21.609v114.84c0 6.6094 5.3906 12 12 12s12-5.3906 12-12v-114.84h125.39v114.84c0 6.6094 5.3906 12 12 12s12-5.3906 12-12v-114.84h125.39v71.531c-12.469 4.9219-21.375 17.062-21.375 31.219 0 18.609 15.141 33.703 33.703 33.703 18.609 0 33.609-15.141 33.609-33.703 0-14.531-9.1406-26.625-21.938-31.453v-71.391h21.609c85.781 0 155.63-69.844 155.63-155.63v-21.609h114.84c6.6094 0 12-5.3906 12-12s-5.3906-12-12-12h-114.84v-125.39h114.84zm-138.84 171c0 72.609-59.062 131.63-131.63 131.63h-366c-72.609 0-131.63-59.062-131.63-131.63v-366c0-72.609 59.062-131.63 131.63-131.63h366c72.609 0 131.63 59.062 131.63 131.63z" />{" "}
      </g>
    </SvgIcon>
  );
}

export default GuessTheMoveIcon;
