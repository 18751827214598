import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const TablebaseHelp = () => {
  const { t } = useTranslation("Tools");

  return (
    <>
      <Typography>{t("Tablebase.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t(
              "Tablebase.helpDetails.instructions.piecePlacement.title"
            )}
            secondary={t(
              "Tablebase.helpDetails.instructions.piecePlacement.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("Tablebase.helpDetails.instructions.check.title")}
            secondary={t(
              "Tablebase.helpDetails.instructions.check.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("Tablebase.helpDetails.instructions.moves.title")}
            secondary={t(
              "Tablebase.helpDetails.instructions.moves.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("Tablebase.helpDetails.instructions.fen.title")}
            secondary={t("Tablebase.helpDetails.instructions.fen.description")}
          />
        </ListItem>
      </List>
    </>
  );
};

export default TablebaseHelp;
