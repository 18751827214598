import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Function to dynamically import translation files
const loadTranslations = () => {
  const translations = {};

  // Load English translations
  const contextEn = require.context("./en", false, /\.json$/);
  contextEn.keys().forEach((key) => {
    const ns = key.replace("./", "").replace(".json", ""); // Get the namespace from the filename
    translations["en"] = translations["en"] || {};
    translations["en"][ns] = contextEn(key); // Assign loaded translations
  });

  // Load French translations
  const contextFr = require.context("./fr", false, /\.json$/);
  contextFr.keys().forEach((key) => {
    const ns = key.replace("./", "").replace(".json", "");
    translations["fr"] = translations["fr"] || {};
    translations["fr"][ns] = contextFr(key);
  });

  // Load Spanish translations
  const contextEs = require.context("./es", false, /\.json$/);
  contextEs.keys().forEach((key) => {
    const ns = key.replace("./", "").replace(".json", "");
    translations["es"] = translations["es"] || {};
    translations["es"][ns] = contextEs(key);
  });

  // Load Russian translations
  const contextRu = require.context("./ru", false, /\.json$/);
  contextRu.keys().forEach((key) => {
    const ns = key.replace("./", "").replace(".json", "");
    translations["ru"] = translations["ru"] || {};
    translations["ru"][ns] = contextRu(key);
  });

  // Load Chinese translations
  const contextCn = require.context("./cn", false, /\.json$/);
  contextCn.keys().forEach((key) => {
    const ns = key.replace("./", "").replace(".json", "");
    translations["cn"] = translations["cn"] || {};
    translations["cn"][ns] = contextCn(key);
  });

  // Load Vietnamese translations
  const contextVn = require.context("./vn", false, /\.json$/);
  contextVn.keys().forEach((key) => {
    const ns = key.replace("./", "").replace(".json", "");
    translations["vn"] = translations["vn"] || {};
    translations["vn"][ns] = contextVn(key);
  });

  // Load Modern Arabic translations
  const contextAr = require.context("./ar", false, /\.json$/);
  contextAr.keys().forEach((key) => {
    const ns = key.replace("./", "").replace(".json", "");
    translations["ar"] = translations["ar"] || {};
    translations["ar"][ns] = contextAr(key);
  });

  // Load German translations
  const contextDe = require.context("./de", false, /\.json$/);
  contextDe.keys().forEach((key) => {
    const ns = key.replace("./", "").replace(".json", "");
    translations["de"] = translations["de"] || {};
    translations["de"][ns] = contextDe(key);
  });

  // Load German translations
  const contextHi = require.context("./hi", false, /\.json$/);
  contextHi.keys().forEach((key) => {
    const ns = key.replace("./", "").replace(".json", "");
    translations["hi"] = translations["hi"] || {};
    translations["hi"][ns] = contextHi(key);
  });

  // Load Hebrew translations
  const contextHe = require.context("./he", false, /\.json$/);
  contextHe.keys().forEach((key) => {
    const ns = key.replace("./", "").replace(".json", "");
    translations["he"] = translations["he"] || {};
    translations["he"][ns] = contextHe(key);
  });

  // Load Japanese translations
  const contextJa = require.context("./ja", false, /\.json$/);
  contextJa.keys().forEach((key) => {
    const ns = key.replace("./", "").replace(".json", "");
    translations["ja"] = translations["ja"] || {};
    translations["ja"][ns] = contextJa(key);
  });

  // Load Japanese translations
  const contextKo = require.context("./ko", false, /\.json$/);
  contextKo.keys().forEach((key) => {
    const ns = key.replace("./", "").replace(".json", "");
    translations["ko"] = translations["ko"] || {};
    translations["ko"][ns] = contextKo(key);
  });

  return translations;
};

// Function to detect browser language and check if it's supported
const detectBrowserLanguage = () => {
  const browserLang = navigator.language || navigator.languages[0];
  const supportedLanguages = [
    "en",
    "fr",
    "es",
    "ru",
    "cn",
    "vn",
    "ar",
    "de",
    "hi",
    "ko",
    "ja",
    "he",
  ];
  const languageCode = browserLang.split("-")[0]; // Extract language code (e.g., 'en' from 'en-US')

  return supportedLanguages.includes(languageCode) ? languageCode : "en"; // Fallback to English if not supported
};

i18n.use(initReactI18next).init({
  lng: detectBrowserLanguage(), // Use the browser language
  fallbackLng: "en",
  resources: loadTranslations(), // Load translations dynamically
  ns: Object.keys(loadTranslations().en), // Use all namespaces found in English files
  defaultNS: "", // Adjust this if your default namespace is different
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
