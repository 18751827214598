import React, { createContext, useContext, useState } from "react";

// Create the context
const ShareContext = createContext();

// Create a provider component
export const ShareProvider = ({ children }) => {
  const [shareData, setShareData] = useState({
    url: process.env.PUBLIC_URL,
    title: "Chessboard Magic",
    description:
      "Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills.",
    image: `${process.env.PUBLIC_URL}/img/chessboardmagic-social.png`,
  });

  return (
    <ShareContext.Provider value={{ shareData, setShareData }}>
      {children}
    </ShareContext.Provider>
  );
};

// Custom hook to use the ShareContext
export const useShare = () => useContext(ShareContext);
