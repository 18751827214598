import React from "react";
import { SvgIcon } from "@mui/material";

function PGNIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 1080 1080">
      <g transform="scale(0.8) translate(200, 100)">
        <path d="m39.699 273.66h224.51c5.1758 0 9.375-4.1992 9.375-9.375v-224.58c0-5.1758-4.1992-9.375-9.375-9.375h-224.51c-5.1758 0-9.375 4.1992-9.375 9.375v224.58c0 5.1758 4.1953 9.375 9.375 9.375z" />
        <path d="m488.81 479.5c-5.1758 0-9.375 4.1992-9.375 9.375v224.58c0 5.1758 4.1992 9.375 9.375 9.375h224.51c5.1758 0 9.375-4.1992 9.375-9.375v-224.58c0-5.1758-4.1992-9.375-9.375-9.375z" />
        <path d="m1162.5 928.68h-224.58c-5.1758 0-9.375 4.1992-9.375 9.375v224.45c0 5.1758 4.1992 9.375 9.375 9.375h224.58c5.1758 0 9.375-4.1992 9.375-9.375v-224.45c0-5.1797-4.1953-9.375-9.375-9.375z" />
        <path d="m895.67 1040.9h-631.46c-118.63 0-215.14-96.543-215.14-215.21 0-118.6 96.512-215.08 215.14-215.08h182.5c5.1758 0 9.375-4.1992 9.375-9.375s-4.1992-9.375-9.375-9.375l-182.5-0.003906c-128.97 0-233.89 104.9-233.89 233.83 0 129 104.92 233.96 233.89 233.96h631.46c5.1758 0 9.375-4.1992 9.375-9.375 0-5.1758-4.1992-9.3711-9.375-9.3711z" />
        <path d="m306.46 161.42h631.45c118.67 0 215.21 96.555 215.21 215.23 0 118.67-96.539 215.21-215.21 215.21h-181.09c-5.1758 0-9.375 4.1992-9.375 9.375s4.1992 9.375 9.375 9.375h181.09c129 0 233.96-104.95 233.96-233.96 0-129.02-104.95-233.98-233.96-233.98h-631.45c-5.1758 0-9.375 4.1992-9.375 9.375 0 5.1797 4.1992 9.375 9.375 9.375z" />
      </g>
    </SvgIcon>
  );
}

export default PGNIcon;
