import React from "react";
import { SvgIcon } from "@mui/material";

function CheckersIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 1080 1080">
      <g transform="scale(0.8) translate(0, 100)">
        <path d="m1068 120h-936c-6.6289 0-12 5.3711-12 12v936c0 6.6289 5.3711 12 12 12h936c6.6289 0 12-5.3711 12-12v-936c0-6.6289-5.3711-12-12-12zm-12 468h-209.81v-210.01h209.81zm-233.81 0h-210.05v-210.01h210.05zm-678.16-210.01h210.05v210.01h-210.05zm0 234.01h210.05v210h-210.05zm234.05 0h210.07v210h-210.07zm0-24v-210.01h210.07v210.01zm234.07-234.01v-209.99h210.05v209.99zm-24 0h-210.07v-209.99h210.07zm-234.07 0h-210.05v-209.99h210.05zm-210.05 492.01h210.05v210h-210.05zm234.05 0h210.07v210h-210.07zm234.07 0h210.05v210h-210.05zm0-24v-210h210.05v210zm234.05-210h209.81v210h-209.81zm209.81-258.01h-209.81v-209.99h209.81zm-209.81 702.01v-210h209.81v210z" />
        <path d="m483.1 647.61c-38.262 0-69.387 31.133-69.387 69.391 0 38.262 31.125 69.387 69.387 69.387 38.262 0 69.391-31.125 69.391-69.387s-31.133-69.391-69.391-69.391zm0 114.78c-25.023 0-45.387-20.359-45.387-45.387 0-25.031 20.359-45.391 45.387-45.391 25.031 0 45.391 20.359 45.391 45.391 0 25.023-20.359 45.387-45.391 45.387z" />
        <path d="m951.21 881.61c-38.262 0-69.387 31.133-69.387 69.391 0 38.262 31.125 69.387 69.387 69.387 38.262 0 69.391-31.125 69.391-69.387 0.003906-38.262-31.125-69.391-69.391-69.391zm0 114.78c-25.023 0-45.387-20.359-45.387-45.387 0-25.031 20.359-45.391 45.387-45.391 25.031 0 45.391 20.359 45.391 45.391 0.003906 25.023-20.359 45.387-45.391 45.387z" />
        <path d="m249.05 413.61c-38.262 0-69.387 31.133-69.387 69.391 0 38.262 31.125 69.387 69.387 69.387s69.391-31.125 69.391-69.387-31.129-69.391-69.391-69.391zm0 114.78c-25.023 0-45.387-20.359-45.387-45.387 0-25.031 20.359-45.391 45.387-45.391 25.031 0 45.391 20.359 45.391 45.391 0.003906 25.023-20.359 45.387-45.391 45.387z" />
        <path d="m717.16 176.29c-38.262 0-69.387 31.133-69.387 69.391 0 38.262 31.125 69.387 69.387 69.387 38.262 0 69.391-31.125 69.391-69.387 0-38.262-31.129-69.391-69.391-69.391zm0 114.78c-25.023 0-45.387-20.359-45.387-45.387 0-25.031 20.359-45.391 45.387-45.391 25.031 0 45.391 20.359 45.391 45.391 0 25.023-20.359 45.387-45.391 45.387z" />
        <path d="m951.21 552.39c38.262 0 69.391-31.125 69.391-69.387s-31.133-69.391-69.391-69.391c-38.258 0-69.387 31.129-69.387 69.391s31.125 69.387 69.387 69.387zm0-114.78c25.031 0 45.391 20.359 45.391 45.391 0 25.023-20.359 45.387-45.391 45.387-25.023 0-45.387-20.359-45.387-45.387 0-25.031 20.363-45.391 45.387-45.391z" />{" "}
      </g>
    </SvgIcon>
  );
}

export default CheckersIcon;
