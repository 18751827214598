import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const CryptogramsHelp = () => {
  const { t } = useTranslation("Games");

  return (
    <>
      <Typography>{t("Cryptograms.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t("Cryptograms.helpDetails.instructions.decrypt.title")}
            secondary={t(
              "Cryptograms.helpDetails.instructions.decrypt.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("Cryptograms.helpDetails.instructions.goal.title")}
            secondary={t(
              "Cryptograms.helpDetails.instructions.goal.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("Cryptograms.helpDetails.instructions.quitGame.title")}
            secondary={t(
              "Cryptograms.helpDetails.instructions.quitGame.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("Cryptograms.helpDetails.instructions.newGame.title")}
            secondary={t(
              "Cryptograms.helpDetails.instructions.newGame.description"
            )}
          />
        </ListItem>
      </List>
    </>
  );
};

export default CryptogramsHelp;
