import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Box, Button, useTheme, Typography, Paper } from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { useShare } from "../../../context/ShareContext";
import { useUser } from "../../../context/UserContext";
import { useTranslation } from "react-i18next";
import { chessterms } from "../../../data/chessterms/chessterms";
import { updateUserData } from "../../../features/Firestore";
import { trackEvent } from "../../../config/ga";
import HelpModal from "../../../components/HelpModal";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DecryptChessHelp from "../../../help/DecryptChessHelp";

function DecryptChess() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userData, userRef } = useUser();
  const { t } = useTranslation("Games");
  const { setShareData } = useShare();
  const [selectedTerm, setSelectedTerm] = useState([]);
  const [encryptedTerm, setEncryptedTerm] = useState([]);
  const [gameStarted, setGameStarted] = useState(false);
  const [quitConfirmed, setQuitConfirmed] = useState(false);
  const [gameCompleted, setGameCompleted] = useState(false);
  const [quitGame, setQuitGame] = useState(false);
  const [hint, setHint] = useState("");

  // New state variables
  const [showHintButton, setShowHintButton] = useState(true);
  const [showQuitButton, setShowQuitButton] = useState(false);
  const [showConfirmButtons, setShowConfirmButtons] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState(null);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleNewGame = useCallback(() => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    // Shuffle the alphabet to create a random one-to-one mapping
    const shuffledAlphabet = [...alphabet].sort(() => 0.5 - Math.random());

    // Create a mapping object where each letter is mapped to a shuffled letter
    const letterMapping = {};
    alphabet.forEach((letter, index) => {
      letterMapping[letter] = shuffledAlphabet[index];
    });

    const filteredTerms = chessterms.filter((term) => term.Term !== "Chess960");

    // Pick a random term from the filteredTerms array
    const randomIndex = Math.floor(Math.random() * filteredTerms.length);
    const randomTerm = filteredTerms[randomIndex];

    setSelectedTerm(randomTerm);

    // Helper function to apply the letter mapping while preserving case
    const applyMapping = (text) => {
      return text
        .split("")
        .map((char) => {
          const upperChar = char.toUpperCase();
          if (letterMapping[upperChar]) {
            // Map the letter while preserving the original case
            const mappedChar = letterMapping[upperChar];
            return char === char.toUpperCase()
              ? mappedChar
              : mappedChar.toLowerCase();
          }
          return char; // Keep non-alphabet characters the same
        })
        .join("");
    };

    // Apply the mapping to the selected term and description to create the encrypted version
    const encryptedTerm = applyMapping(randomTerm.Term);
    const encryptedDescription = applyMapping(randomTerm.Description);

    // Update the selected term with encrypted values
    setEncryptedTerm({
      ...randomTerm,
      Term: encryptedTerm,
      Description: encryptedDescription,
    });

    // Rest of the game state updates
    setGameStarted(true);
    setQuitConfirmed(false);
    setGameCompleted(false);
    setQuitGame(false);
    setHint("");
    setShowHintButton(true); // Show Hint button on new game
    setShowQuitButton(false); // Hide Quit button on new game
    setShowConfirmButtons(false); // Hide Confirm/Cancel buttons
    trackEvent("Games", "DecryptChess-Play", "DecryptChess");

    if (userData) {
      if (!userData.Puzzles) {
        userData.Puzzles = {};
      }
      if (userData.Puzzles.DecryptChess) {
        userData.Puzzles.DecryptChess.Played =
          (userData.Puzzles.DecryptChess.Played || 0) + 1;
      } else {
        userData.Puzzles.DecryptChess = {
          Played: 1,
          Completed: 0,
        };
      }
      updateUserData(userRef, userData);
    }
  }, [userData, userRef]);

  useEffect(() => {
    // Start a new game when the component loads
    handleNewGame();
  }, [handleNewGame]);

  const handleHint = () => {
    setHint(selectedTerm.Type);
    setShowHintButton(false); // Hide the hint button after use
    setShowQuitButton(true); // Show Quit button after using Hint
  };

  const handleQuit = () => {
    setShowQuitButton(false); // Hide Quit button
    setShowConfirmButtons(true); // Show Confirm/Cancel buttons
  };

  const confirmQuit = () => {
    setQuitConfirmed(true);
    setShowConfirmButtons(false); // Hide Confirm/Cancel buttons
    setShowHintButton(true); // Show the disabled Hint button
  };

  const cancelQuit = () => {
    setShowConfirmButtons(false); // Hide Confirm/Cancel buttons
    setShowQuitButton(true); // Show Quit button again
  };

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/decryptchess",
      title: "Chessboard Magic - Decrypt Chess",
      description:
        "Challenge your mind by deciphering chess terms, famous players, openings, and engines in this unique cryptographic chess puzzle game.",
    };
    setShareData(newShareData);
  }, [setShareData, t]);

  // Handle letter click to swap letters
  const handleLetterClick = (letter) => {
    if (selectedLetter === null) {
      // Select the first letter
      setSelectedLetter(letter);
    } else if (selectedLetter === letter) {
      // Deselect the letter if clicked again
      setSelectedLetter(null);
    } else {
      // Swap the letters directly in the encrypted term and description
      swapLettersInEncryptedTerm(selectedLetter, letter);

      // Clear the selected letter after swapping
      setSelectedLetter(null);
    }
  };

  // Swap the two selected letters in the encrypted text directly
  const swapLettersInEncryptedTerm = (letter1, letter2) => {
    // Swap in both Term and Description
    setEncryptedTerm((prevEncryptedTerm) => {
      // Swap the letters in the Term
      const updatedTerm = swapLettersInString(
        prevEncryptedTerm.Term,
        letter1,
        letter2
      );

      // Swap the letters in the Description
      const updatedDescription = swapLettersInString(
        prevEncryptedTerm.Description,
        letter1,
        letter2
      );

      // Check if the updated term and description match the selected term and description
      if (
        updatedTerm === selectedTerm.Term &&
        updatedDescription === selectedTerm.Description
      ) {
        setGameCompleted(true); // Mark the game as completed if terms match
      }

      return {
        ...prevEncryptedTerm,
        Term: updatedTerm,
        Description: updatedDescription,
      };
    });
  };

  // Helper function to swap letters in a string
  const swapLettersInString = (text, letter1, letter2) => {
    return text
      .split("")
      .map((char) => {
        // Swap letter1 with letter2, keeping original case
        if (char.toLowerCase() === letter1.toLowerCase()) {
          // If char matches letter1, replace with letter2 while keeping the original case
          return char === char.toUpperCase()
            ? letter2.toUpperCase()
            : letter2.toLowerCase();
        }

        // Swap letter2 with letter1, keeping original case
        if (char.toLowerCase() === letter2.toLowerCase()) {
          // If char matches letter2, replace with letter1 while keeping the original case
          return char === char.toUpperCase()
            ? letter1.toUpperCase()
            : letter1.toLowerCase();
        }

        // If no match, return original character
        return char;
      })
      .join("");
  };

  // Alphabet letters
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  return (
    <Box>
      <ContentHeader
        title={t("DecryptChess.header.title")}
        subtitle={t("DecryptChess.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      <Helmet>
        <title>Decrypt Chess</title>
        <meta
          name="description"
          content="Challenge your mind by deciphering chess terms, famous players, openings, and engines in this unique cryptographic chess puzzle game"
        />
        <meta property="og:title" content="Decrypt Chess" />
        <meta
          property="og:description"
          content="Challenge your mind by deciphering chess terms, famous players, openings, and engines in this unique cryptographic chess puzzle game"
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/games/decryptchess.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/decryptchess`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Decrypt Chess" />
        <meta
          name="twitter:description"
          content="Challenge your mind by deciphering chess terms, famous players, openings, and engines in this unique cryptographic chess puzzle game"
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/games/decryptchess.png`}
        />
      </Helmet>
      <Box>
        {/* New Game Button */}
        <Button
          variant="contained"
          onClick={handleNewGame}
          sx={{ mr: 1, mb: 1 }}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.material[1] }}
            />
          }
        >
          {t("DecryptChess.buttons.play")}
        </Button>

        {/* Hint Button */}
        {showHintButton && (
          <Button
            variant="contained"
            onClick={handleHint}
            sx={{ mr: 1, mb: 1 }}
            disabled={!gameStarted || gameCompleted || hint !== ""}
          >
            {t("DecryptChess.buttons.hints")}
          </Button>
        )}

        {/* Quit Button */}
        {showQuitButton && (
          <Button
            variant="contained"
            color="error"
            onClick={handleQuit}
            sx={{ mr: 1, mb: 1 }}
          >
            {t("DecryptChess.buttons.quit")}
          </Button>
        )}

        {/* Confirm and Cancel Buttons */}
        {showConfirmButtons && (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={confirmQuit}
              sx={{ mr: 1, mb: 1 }}
            >
              {t("DecryptChess.buttons.confirm")}
            </Button>
            <Button
              variant="contained"
              onClick={cancelQuit}
              sx={{ mr: 1, mb: 1 }}
            >
              {t("DecryptChess.buttons.cancel")}
            </Button>
          </>
        )}

        {/* Help Button */}
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ mr: 1, mb: 1 }}
          startIcon={<HelpOutlineIcon style={{ color: colors.material[1] }} />}
        >
          {t("DecryptChess.buttons.help")}
        </Button>
        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("DecryptChess.helpDetails.title")}
          content={<DecryptChessHelp />}
        ></HelpModal>
      </Box>
      <Box sx={{ maxWidth: "600px" }}>
        {!quitConfirmed && !gameCompleted && (
          <>
            <Typography mt={1}>{t("DecryptChess.labels.support")}</Typography>
            <Box display="flex" flexWrap="wrap" mt={1}>
              {alphabet.map((letter) => (
                <Paper
                  key={letter}
                  elevation={selectedLetter === letter ? 4 : 1}
                  onClick={() => handleLetterClick(letter)}
                  style={{
                    width: 40,
                    height: 40,
                    margin: 4,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    backgroundColor:
                      selectedLetter === letter
                        ? colors.material[1]
                        : undefined,
                  }}
                >
                  <Typography variant="h6">{letter}</Typography>
                </Paper>
              ))}
            </Box>
          </>
        )}

        {gameCompleted && gameStarted && !quitGame && (
          <Box mt={3} display="flex" alignItems="center">
            <CheckCircleOutlineOutlinedIcon style={{ color: "green" }} />
            <Typography variant="body" style={{ marginLeft: "10px" }}>
              {t("DecryptChess.labels.completed")}
            </Typography>
          </Box>
        )}

        {!quitConfirmed ? (
          <Box sx={{ pt: 2, mb: 2 }}>
            {hint && (
              <Typography>
                {(() => {
                  switch (hint) {
                    case "T":
                      return t("DecryptChess.labels.term"); // Term
                    case "P":
                      return t("DecryptChess.labels.player"); // Player
                    case "O":
                      return t("DecryptChess.labels.opening"); // Opening
                    case "E":
                      return t("DecryptChess.labels.engine"); // Engine
                    case "W":
                      return t("DecryptChess.labels.worldChampion"); // World Champion
                    default:
                      return "";
                  }
                })()}
              </Typography>
            )}

            <Typography variant="h5" sx={{ pb: 2 }}>
              {encryptedTerm?.Term ? encryptedTerm.Term : "Loading..."}
            </Typography>
            <Typography>
              {encryptedTerm?.Description
                ? encryptedTerm.Description
                : "Loading..."}
            </Typography>
          </Box>
        ) : (
          <Box sx={{ pt: 2 }}>
            <Typography>
              {(() => {
                switch (hint) {
                  case "T":
                    return t("DecryptChess.labels.term"); // Term
                  case "P":
                    return t("DecryptChess.labels.player"); // Player
                  case "O":
                    return t("DecryptChess.labels.opening"); // Opening
                  case "E":
                    return t("DecryptChess.labels.engine"); // Engine
                  case "W":
                    return t("DecryptChess.labels.worldChampion"); // World Champion
                  default:
                    return "";
                }
              })()}
            </Typography>
            <Typography variant="h5" sx={{ pb: 2 }}>
              {selectedTerm?.Term ? selectedTerm.Term : "Loading..."}
            </Typography>
            <Typography>
              {selectedTerm?.Description
                ? selectedTerm.Description
                : "Loading..."}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default DecryptChess;
