import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  useTheme,
} from "@mui/material";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js"; // For handling game logic
import ContentHeader from "../../../components/ContentHeader";
import { Helmet } from "react-helmet";
import { themeColors } from "../../../styles/boardtheme";
import { pieceSets } from "../../../styles/pieceset";
import { useUser } from "../../../context/UserContext";
import { useShare } from "../../../context/ShareContext";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";
import MemoryRoundedIcon from "@mui/icons-material/MemoryRounded";
import { trackEvent } from "../../../config/ga";
import { updateUserData } from "../../../features/Firestore";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../styles/theme";
import HelpModal from "../../../components/HelpModal";
import HiddenChessHelp from "../../../help/HiddenChessHelp";

function HiddenChess() {
  const [game, setGame] = useState(new Chess());
  const [fen, setFen] = useState(game.fen());
  const [mode, setMode] = useState("circles"); // Mode for piece visibility
  const [revealPieces, setRevealPieces] = useState(false); // Show pieces for a hint
  const [movesHistory, setMovesHistory] = useState([]);
  const [isEngineStarted, setIsEngineStarted] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userData, userRef } = useUser();
  const { setShareData } = useShare();
  const { t } = useTranslation("Learn");
  const stockfishWorker = useRef(null);
  const [engineSide, setEngineSide] = useState(null); // 'w' for white, 'b' for black
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [orientation, setOrientation] = useState("white");

  const toggleOrientation = () => {
    setOrientation((prevOrientation) =>
      prevOrientation === "white" ? "black" : "white"
    );
  };

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/hiddenchess",
      title: "Chessboard Magic - Hidden Chess",
      description:
        "Play against an engine while hiding some or all pieces to improve your visualization skills.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  // Load Stockfish as a Web Worker from the public folder
  useEffect(() => {
    stockfishWorker.current = new Worker(
      `${process.env.PUBLIC_URL}/js/stockfish-16.1-lite-single.js`
    );

    return () => {
      if (stockfishWorker.current) {
        stockfishWorker.current.terminate();
      }
    };
  }, []);

  const newGame = () => {
    const newGame = new Chess();
    setIsEngineStarted(false); // Set engine to off
    setGame(newGame);
    setFen(newGame.fen());
    setMovesHistory([]); // Clear moves history

    // GA Tracking and Internal Tracking
    trackEvent("Learn", "HiddenChess-Play", "Hidden Chess");
    if (userData) {
      if (!userData.Puzzles) {
        userData.Puzzles = {};
      }
      if (userData.Puzzles.HiddenChess) {
        userData.Puzzles.HiddenChess.Played =
          (userData.Puzzles.HiddenChess.Played || 0) + 1;
      } else {
        userData.Puzzles.HiddenChess = {
          Played: 1,
        };
      }
      updateUserData(userRef, userData);
    }
  };

  const makeEngineMove = useCallback(() => {
    if (stockfishWorker.current && game.turn() === engineSide) {
      stockfishWorker.current.postMessage(`position fen ${game.fen()}`);
      stockfishWorker.current.postMessage(`go depth 12`);

      stockfishWorker.current.onmessage = (event) => {
        const message = event.data;
        if (message.startsWith("bestmove")) {
          const move = message.split(" ")[1];
          const from = move.substring(0, 2);
          const to = move.substring(2, 4);

          const engineMove = game.move({ from, to, promotion: "q" });
          if (engineMove) {
            setFen(game.fen()); // Update the FEN state
            setGame(game); // Update the game state
            setMovesHistory((prevHistory) => [...prevHistory, engineMove]);

            // If it's still the engine's turn, let the engine make the next move
            if (game.turn() === engineSide) {
              setTimeout(makeEngineMove, 500); // Simulate engine thinking
            }
          }
        }
      };
    }
  }, [game, engineSide]);

  useEffect(() => {
    if (isEngineStarted && game.turn() === engineSide) {
      setTimeout(makeEngineMove, 500); // Start engine thinking after a delay
    }
  }, [isEngineStarted, game, engineSide, makeEngineMove]);

  const startEngine = () => {
    // Set engine to the current side and start it
    setIsEngineStarted(true);
    setEngineSide(game.turn());
  };

  const getCustomPieces = useCallback(() => {
    const customPieces = { ...pieceSets[userData?.pieceset || "Maestro"] };

    if (mode !== "showAllPieces" && !revealPieces) {
      Object.keys(customPieces).forEach((key) => {
        // Show White Pieces Only (Hide Black pieces)
        if (mode === "showWhite" && key.startsWith("b")) {
          customPieces[key] = ({ squareWidth }) => (
            <div
              style={{
                backgroundColor: "transparent", // Make the piece hidden
                width: `${squareWidth}px`,
                height: `${squareWidth}px`,
              }}
            >
              &nbsp;
            </div>
          ); // Hide black pieces visually but keep them selectable
        }
        // Show Black Pieces Only (Hide White pieces)
        else if (mode === "showBlack" && key.startsWith("w")) {
          customPieces[key] = ({ squareWidth }) => (
            <div
              style={{
                backgroundColor: "transparent", // Make the piece hidden
                width: `${squareWidth}px`,
                height: `${squareWidth}px`,
              }}
            >
              &nbsp;
            </div>
          ); // Hide white pieces visually but keep them selectable
        }
        // Show No Pieces (Blind Chess) - Make all pieces transparent but selectable
        else if (mode === "blindChess") {
          customPieces[key] = ({ squareWidth }) => (
            <div
              style={{
                backgroundColor: "transparent", // Make all pieces hidden
                width: `${squareWidth}px`,
                height: `${squareWidth}px`,
              }}
            >
              &nbsp;
            </div>
          ); // Hide all pieces visually but keep them selectable
        }
        // Show White Pieces (Black pieces as circles)
        else if (
          mode === "whitePiecesWithBlackCircles" &&
          key.startsWith("b")
        ) {
          customPieces[key] = ({ squareWidth }) => (
            <div
              style={{
                marginTop: `${squareWidth * 0.1}px`,
                backgroundColor: "black",
                borderRadius: "50%",
                width: `${squareWidth * 0.8}px`,
                height: `${squareWidth * 0.8}px`,
                boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.5)", // Add box shadow
              }}
            >
              &nbsp;
            </div>
          ); // Black circles
        }
        // Show Black Pieces (White pieces as circles)
        else if (
          mode === "blackPiecesWithWhiteCircles" &&
          key.startsWith("w")
        ) {
          customPieces[key] = ({ squareWidth }) => (
            <div
              style={{
                marginTop: `${squareWidth * 0.1}px`,
                backgroundColor: "white",
                borderRadius: "50%",
                width: `${squareWidth * 0.8}px`,
                height: `${squareWidth * 0.8}px`,
                boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.5)", // Add box shadow
              }}
            >
              &nbsp;
            </div>
          ); // White circles
        }
        // Show Circles (Coloured)
        else if (mode === "circles") {
          if (key.startsWith("w")) {
            customPieces[key] = ({ squareWidth }) => (
              <div
                style={{
                  marginTop: `${squareWidth * 0.1}px`,
                  backgroundColor: "white",
                  borderRadius: "50%",
                  width: `${squareWidth * 0.8}px`,
                  height: `${squareWidth * 0.8}px`,
                  boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.5)", // Add box shadow
                }}
              >
                &nbsp;
              </div>
            ); // White circles
          } else if (key.startsWith("b")) {
            customPieces[key] = ({ squareWidth }) => (
              <div
                style={{
                  marginTop: `${squareWidth * 0.1}px`,
                  backgroundColor: "black",
                  borderRadius: "50%",
                  width: `${squareWidth * 0.8}px`,
                  height: `${squareWidth * 0.8}px`,
                  boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.5)", // Add box shadow
                }}
              >
                &nbsp;
              </div>
            ); // Black circles
          }
        }
        // Show Circles (Plain) (both White and Black as white circles)
        else if (mode === "circlesWhite") {
          customPieces[key] = ({ squareWidth }) => (
            <div
              style={{
                marginTop: `${squareWidth * 0.1}px`,
                backgroundColor: "white",
                borderRadius: "50%",
                width: `${squareWidth * 0.8}px`,
                height: `${squareWidth * 0.8}px`,
                boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.5)", // Add box shadow
              }}
            >
              &nbsp;
            </div>
          ); // All pieces as white circles
        }
      });
    }

    return customPieces;
  }, [mode, revealPieces, userData]);

  const handleShowPieces = () => {
    setRevealPieces(true);
    setIsButtonDisabled(true); // Disable the button
    setCountdown(3); // Start countdown from 3 seconds

    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(intervalId); // Clear interval when countdown reaches 0
          setRevealPieces(false);
          setIsButtonDisabled(false); // Enable the button again
          return null; // Reset countdown to null after finishing
        }
        return prevCountdown - 1;
      });
    }, 1000); // Update countdown every second
  };

  const onDrop = (sourceSquare, targetSquare) => {
    try {
      const move = game.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: "q", // Always promote to a queen for simplicity
      });

      // If the move is illegal (move === null), revert to the current valid FEN
      if (move === null) {
        throw new Error("Illegal move");
      }

      setFen(game.fen());
      setGame(game);
      setMovesHistory((prevHistory) => [...prevHistory, move]);

      // If the engine is started and it's now the engine's turn, make the engine move
      if (isEngineStarted && game.turn() === engineSide) {
        setTimeout(makeEngineMove, 500); // Simulate engine thinking
      }

      return true;
    } catch (error) {
      setFen(game.fen()); // Reset to the last valid FEN in case of illegal move
      return false; // Return false to indicate the move was illegal
    }
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <ContentHeader
        title={t("HiddenChess.header.title")}
        subtitle={t("HiddenChess.header.subtitle")}
        color={theme.palette.text.primary}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[10]}
      />
      <Helmet>
        <title>Hidden Chess</title>
        <meta
          name="description"
          content="Play against an engine while hiding some or all pieces to improve your visualization skills."
        />
        <meta property="og:title" content="Hidden Chess" />
        <meta
          property="og:description"
          content="Play against an engine while hiding some or all pieces to improve your visualization skills."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/learn/hiddenchess.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/hiddenchess`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Hidden Chess" />
        <meta
          name="twitter:description"
          content="Play against an engine while hiding some or all pieces to improve your visualization skills."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/learn/hiddenchess.png`}
        />
      </Helmet>
      <Button
        variant="contained"
        onClick={newGame}
        sx={{ mb: 2, mr: 1 }}
        startIcon={
          <PlayCircleOutlineRoundedIcon
            style={{ color: colors.material[10] }}
          />
        }
      >
        {t("HiddenChess.buttons.newGame")}
      </Button>
      <Button
        variant="contained"
        onClick={startEngine}
        sx={{ mb: 2, mr: 1 }}
        startIcon={<MemoryRoundedIcon style={{ color: colors.material[10] }} />}
      >
        {t("HiddenChess.buttons.startEngine")}
      </Button>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<HelpOutlineIcon style={{ color: colors.material[10] }} />}
        sx={{ mb: 2, mr: 1 }}
      >
        {t("HiddenChess.buttons.help")}
      </Button>
      <FormControl sx={{ mb: 2, mr: 1 }}>
        <InputLabel
          id="number-of-moves-label"
          style={{
            backgroundColor: colors.black[100],
            padding: "0 4px",
            color: colors.black[900],
          }}
        >
          {t("HiddenChess.labels.mode")}
        </InputLabel>
        <Select value={mode} onChange={(e) => setMode(e.target.value)}>
          <MenuItem value="showWhite">
            {t("HiddenChess.menu.showWhite")}
          </MenuItem>
          <MenuItem value="showBlack">
            {t("HiddenChess.menu.showBlack")}
          </MenuItem>
          <MenuItem value="blindChess">
            {t("HiddenChess.menu.blindChess")}
          </MenuItem>
          <MenuItem value="whitePiecesWithBlackCircles">
            {t("HiddenChess.menu.whitePiecesWithBlackCircles")}
          </MenuItem>
          <MenuItem value="blackPiecesWithWhiteCircles">
            {t("HiddenChess.menu.blackPiecesWithWhiteCircles")}
          </MenuItem>
          <MenuItem value="circles">{t("HiddenChess.menu.circles")}</MenuItem>
          <MenuItem value="circlesWhite">
            {t("HiddenChess.menu.circlesWhite")}
          </MenuItem>
          <MenuItem value="showAllPieces">
            {t("HiddenChess.menu.showAllPieces")}
          </MenuItem>
        </Select>
      </FormControl>

      <HelpModal
        open={open}
        onClose={handleClose}
        title={t("HiddenChess.helpDetails.title")}
        content={<HiddenChessHelp />}
      ></HelpModal>

      <Box
        display="flex"
        mt={2}
        sx={{ flexDirection: { xs: "column", md: "row" }, gap: 2 }}
      >
        <Box flex={1}>
          <Chessboard
            position={fen}
            onPieceDrop={onDrop}
            boardOrientation={orientation}
            customLightSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "Modern Minimal"].lightSquare,
            }}
            customDarkSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "Modern Minimal"].darkSquare,
            }}
            customPieces={getCustomPieces()}
            areArrowsAllowed={false}
          />
          <Button
            sx={{ mt: 2, mb: 2, mr: 1 }}
            variant="contained"
            onClick={handleShowPieces}
            disabled={isButtonDisabled}
            startIcon={
              <VisibilityIcon style={{ color: colors.material[10] }} />
            }
          >
            {t("HiddenChess.buttons.showPieces")}{" "}
            {countdown && `(${countdown})`}
          </Button>
          <Button
            variant="contained"
            onClick={toggleOrientation}
            sx={{ padding: 0, minWidth: 36, minHeight: 36 }}
          >
            <SwapVertRoundedIcon style={{ color: colors.material[10] }} />
          </Button>
        </Box>

        {/* Right Panel: Moves History */}
        <Box flex={1} ml={{ md: 2, xs: 0 }}>
          <Typography sx={{ pb: 2 }}>
            {movesHistory
              .reduce((acc, move, index) => {
                const moveNumber = Math.floor(index / 2) + 1;
                if (index % 2 === 0) {
                  return `${acc} ${moveNumber}. ${move.san}`;
                } else {
                  return `${acc} ${move.san}`;
                }
              }, "")
              .trim()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default HiddenChess;
