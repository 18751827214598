export const pieceSets = {
  Alpha: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/alpha/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/alpha/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/alpha/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/alpha/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/alpha/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/alpha/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/alpha/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/alpha/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/alpha/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/alpha/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/alpha/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/alpha/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Cardinal: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/cardinal/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/cardinal/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/cardinal/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/cardinal/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/cardinal/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/cardinal/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/cardinal/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/cardinal/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/cardinal/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/cardinal/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/cardinal/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/cardinal/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },

  Celtic: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/celtic/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },

  Chess7: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chess7/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chess7/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chess7/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chess7/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chess7/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chess7/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chess7/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chess7/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chess7/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chess7/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chess7/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chess7/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },

  Chessnut: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/chessnut/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Companion: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/companion/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/companion/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/companion/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/companion/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/companion/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/companion/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/companion/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/companion/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/companion/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/companion/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/companion/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/companion/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Dubrovny: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/dubrovny/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/dubrovny/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/dubrovny/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/dubrovny/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/dubrovny/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/dubrovny/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/dubrovny/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/dubrovny/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/dubrovny/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/dubrovny/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/dubrovny/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/dubrovny/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Fantasy: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fantasy/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Fresca: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fresca/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fresca/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fresca/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fresca/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fresca/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fresca/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fresca/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fresca/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fresca/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fresca/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fresca/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/fresca/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Governor: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/governor/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/governor/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/governor/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/governor/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/governor/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/governor/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/governor/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/governor/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/governor/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/governor/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/governor/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/governor/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Icpieces: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/icpieces/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/icpieces/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/icpieces/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/icpieces/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/icpieces/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/icpieces/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/icpieces/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/icpieces/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/icpieces/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/icpieces/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/icpieces/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/icpieces/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Kosal: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/kosal/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/kosal/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/kosal/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/kosal/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/kosal/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/kosal/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/kosal/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/kosal/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/kosal/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/kosal/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/kosal/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/kosal/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Leipzig: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/leipzig/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/leipzig/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/leipzig/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/leipzig/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/leipzig/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/leipzig/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/leipzig/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/leipzig/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/leipzig/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/leipzig/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/leipzig/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/leipzig/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Maestro: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/maestro/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/maestro/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/maestro/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/maestro/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/maestro/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/maestro/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/maestro/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/maestro/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/maestro/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/maestro/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/maestro/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/maestro/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Merida: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/merida/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/merida/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/merida/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/merida/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/merida/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/merida/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/merida/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/merida/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/merida/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/merida/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/merida/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/merida/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Pirouetti: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/pirouetti/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/pirouetti/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/pirouetti/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/pirouetti/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/pirouetti/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/pirouetti/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/pirouetti/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/pirouetti/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/pirouetti/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/pirouetti/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/pirouetti/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/pirouetti/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Riohacha: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/riohacha/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/riohacha/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/riohacha/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/riohacha/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/riohacha/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/riohacha/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/riohacha/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/riohacha/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/riohacha/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/riohacha/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/riohacha/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/riohacha/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Spatial: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/spatial/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Staunty: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/staunty/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/staunty/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/staunty/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/staunty/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/staunty/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/staunty/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/staunty/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/staunty/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/staunty/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/staunty/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/staunty/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/staunty/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Tatiana: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/tatiana/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/tatiana/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/tatiana/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/tatiana/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/tatiana/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/tatiana/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/tatiana/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/tatiana/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/tatiana/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/tatiana/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/tatiana/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/tatiana/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  USCF: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/uscf/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/uscf/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/uscf/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/uscf/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/uscf/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/uscf/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/uscf/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/uscf/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/uscf/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/uscf/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/uscf/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/uscf/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
  Wikipedia: {
    wP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/wP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Pawn"
      />
    ),
    wN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/wN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Knight"
      />
    ),
    wB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/wB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Bishop"
      />
    ),
    wR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/wR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Rook"
      />
    ),
    wQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/wQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White Queen"
      />
    ),
    wK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/wK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="White King"
      />
    ),
    bP: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/bP.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Pawn"
      />
    ),
    bN: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/bN.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Knight"
      />
    ),
    bB: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/bB.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Bishop"
      />
    ),
    bR: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/bR.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Rook"
      />
    ),
    bQ: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/bQ.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black Queen"
      />
    ),
    bK: ({ squareWidth }) => (
      <img
        src={`${process.env.PUBLIC_URL}/img/chesspieces/wikipedia/bK.png`}
        style={{ width: squareWidth, height: squareWidth }}
        alt="Black King"
      />
    ),
  },
};
