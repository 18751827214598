import React, { useState } from "react";
import {
  Button,
  Box,
  TextField,
  useTheme,
  Alert,
  Divider,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  handleGoogleSignIn,
  handleEmailLogin,
  handleEmailSignUp,
  handlePasswordReset,
} from "../../../features/Authentication";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import GoogleIcon from "../../../icons/GoogleIcon";
import Turnstile from "react-turnstile";

function Login() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { control, handleSubmit } = useForm();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [captchaResponse, setCaptchaResponse] = useState("");

  const handleGoogleLogin = async () => {
    const result = await handleGoogleSignIn();
    if (result) {
      navigate("/"); // Redirect to the Profile Page
    } else {
      setError(result.error);
    }
  };

  const onSubmit = async (data) => {
    if (!captchaResponse) {
      setError("Please complete the CAPTCHA.");
      return;
    }
    try {
      if (isCreatingAccount) {
        if (password !== confirmPassword) {
          setError("Passwords do not match.");
          return;
        }
        const result = await handleEmailSignUp(email, password);
        if (result) {
          setMessage("Verification email sent. Please check your inbox.");
          setIsCreatingAccount(false); // Switch to login view
          setEmail("");
          setPassword("");
          setConfirmPassword("");
          setError("");
        } else {
          setError(result.error);
        }
      } else {
        const result = await handleEmailLogin(email, password);
        if (result.success) {
          navigate("/"); // Redirect to the Profile Page
        } else {
          setError(result.error);
        }
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  const handlePasswordResetRequest = async () => {
    try {
      const result = await handlePasswordReset(email);
      if (result) {
        setMessage("Password reset email sent!");
        setIsResettingPassword(false);
      } else {
        setError(result.error);
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <ContentHeader
        title="Login to Chessboard Magic"
        subtitle="Login to claim your Username, Customize your Chessboard colours and Pieces and track your progress."
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%", // Ensure full width for container
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflow: "auto",
            width: "600px", // Fix the width to 600px
            maxWidth: "100%", // Ensure it doesn't overflow on smaller screens
            padding: 2,
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {error && (
              <Alert
                severity="error"
                sx={{ marginBottom: 2 }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setError("");
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {error}
              </Alert>
            )}
            {message && (
              <Alert
                severity="success"
                sx={{ marginBottom: 2 }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setMessage("");
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {message}
              </Alert>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    variant="filled"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ marginBottom: 2 }}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    fullWidth
                    autoComplete="email"
                  />
                )}
              />
              {!isResettingPassword && (
                <>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Password"
                        variant="filled"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{ marginBottom: 2 }}
                        InputLabelProps={{
                          style: { color: "black" },
                        }}
                        fullWidth
                        autoComplete="current-password"
                      />
                    )}
                  />
                  {isCreatingAccount && (
                    <Controller
                      name="confirmPassword"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Confirm Password"
                          variant="filled"
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          sx={{ marginBottom: 2 }}
                          InputLabelProps={{
                            style: { color: "black" },
                          }}
                          fullWidth
                          autoComplete="new-password"
                        />
                      )}
                    />
                  )}
                </>
              )}
              <Turnstile
                sitekey="0x4AAAAAAAgKQaDETBl-vYMI"
                onVerify={(response) => {
                  setCaptchaResponse(response);
                }}
                onError={() => {
                  setCaptchaResponse("");
                }}
                onExpire={() => {
                  setCaptchaResponse("");
                }}
                style={{ marginTop: 2, width: "100%" }}
              />
              {isResettingPassword ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePasswordResetRequest}
                  sx={{ marginTop: 2 }}
                  fullWidth
                >
                  Reset Password
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: 2 }}
                  fullWidth
                >
                  {isCreatingAccount ? "Sign Up" : "Log In"}
                </Button>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 2,
                  width: "100%",
                }}
              >
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => {
                    setIsCreatingAccount(!isCreatingAccount);
                    setMessage("");
                    setError("");
                  }}
                >
                  {isCreatingAccount
                    ? "Already have an account? Log In"
                    : "Create a new account"}
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => {
                    setIsResettingPassword(!isResettingPassword);
                    setMessage("");
                    setError("");
                  }}
                >
                  {isResettingPassword ? "Back to Log In" : "Forgot Password?"}
                </Button>
              </Box>
              <Divider sx={{ marginY: 3, width: "100%" }}>OR</Divider>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGoogleLogin}
                sx={{ marginTop: 1, width: "100%" }}
              >
                <GoogleIcon /> Log In with Google
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Login;
