import React, { useState, useEffect, useCallback, useRef } from "react";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { themeColors } from "../../../styles/boardtheme";
import { pieceSets } from "../../../styles/pieceset";
import { tokens } from "../../../styles/theme";
import { Helmet } from "react-helmet";
import { useUser } from "../../../context/UserContext";
import { useShare } from "../../../context/ShareContext";
import ContentHeader from "../../../components/ContentHeader";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";
import UndoRoundedIcon from "@mui/icons-material/UndoRounded";
import {
  eco_1,
  eco_2,
  eco_3,
  eco_4,
  eco_5,
  eco_6,
  eco_7,
  eco_8,
  eco_9,
  eco_10,
  eco_11,
  eco_12,
} from "../../../data/eco/eco";
import EngineBar from "../../../components/EngineBar";
import { useTranslation } from "react-i18next";

function OpeningExplorer() {
  const [game, setGame] = useState(new Chess());
  const [pgn, setPgn] = useState("");
  const [evaluation, setEvaluation] = useState(0.2); // To store Stockfish evaluation

  const stockfishWorker = useRef(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [lines, setLines] = useState(eco_1);
  const [orientation, setOrientation] = useState("white");
  const { userData } = useUser();
  const { setShareData } = useShare();

  const chessboardRef = useRef(null);
  const [chessboardWidth, setChessboardWidth] = useState(0);
  const { t } = useTranslation("Tools");

  const updateChessboardWidth = () => {
    const chessboardElement = chessboardRef.current;
    if (chessboardElement) {
      const { width } = chessboardElement.getBoundingClientRect();
      // Use either width or height to set the state
      // Here we are setting the width to match the height
      setChessboardWidth(width); // Keep the width equal to the height
    }
  };

  useEffect(() => {
    // Function to update chessboard width
    const handlePageLoad = () => {
      updateChessboardWidth();
    };

    // Update width when the component mounts or page loads
    window.addEventListener("load", handlePageLoad);
    updateChessboardWidth(); // Ensure it runs during the component's first render

    // Add an event listener to handle window resizing
    window.addEventListener("resize", updateChessboardWidth);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener("resize", updateChessboardWidth);
      window.removeEventListener("load", handlePageLoad);
    };
  }, []);

  useEffect(() => {
    stockfishWorker.current = new Worker(
      `${process.env.PUBLIC_URL}/js/stockfish-16.1-lite-single.js`
    );

    return () => {
      if (stockfishWorker.current) {
        stockfishWorker.current.terminate();
      }
    };
  }, []);

  const getStockfishEvaluation = (fen) => {
    // Extract the turn (White or Black) from the FEN string
    const turn = fen.split(" ")[1]; // The second part of the FEN string indicates whose turn it is ("w" for White, "b" for Black)

    if (stockfishWorker.current) {
      // Post the current FEN and ask Stockfish for the best move
      stockfishWorker.current.postMessage(`position fen ${fen}`);
      stockfishWorker.current.postMessage("go depth 12");

      stockfishWorker.current.onmessage = (event) => {
        const message = event.data;

        // Parse the evaluation message from Stockfish
        if (message.startsWith("info") && message.includes("score")) {
          const scoreMatch = message.match(/score (cp|mate) (-?\d+)/);
          if (scoreMatch) {
            const scoreType = scoreMatch[1];
            let scoreValue = parseInt(scoreMatch[2], 10);

            if (scoreType === "cp") {
              // Centipawn score: Convert centipawn to a value in terms of pawns
              let evaluation = scoreValue / 100;

              // If it's Black's turn, negate the evaluation
              if (turn === "b") {
                evaluation = -evaluation;
              }

              setEvaluation(evaluation); // Set the evaluation in your state if needed
            } else if (scoreType === "mate") {
              // Mate score: Positive values indicate mate in X moves for White, negative values indicate mate for Black
              const mateValue = Math.abs(scoreValue);
              let mateEvaluation =
                scoreValue > 0 ? `M${mateValue}` : `-M${mateValue}`;

              if (turn === "b") {
                mateEvaluation = mateEvaluation.startsWith("-")
                  ? mateEvaluation.replace("-", "") // Convert -M to M
                  : `-M${mateValue}`; // Convert M to -M
              }
              setEvaluation(mateEvaluation); // Set the mate evaluation
            }
          }
        }
      };
    }
  };

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/openingexplorer",
      title: "Chessboard Magic - Opening Explorer",
      description:
        "Explore a wide range of chess openings, and deepen your understanding of variations from the very first moves",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const handleResetBoard = () => {
    const newGame = new Chess();
    setPgn("");
    setLines(eco_1);
    setGame(newGame);
    setEvaluation(0.2); // Clear evaluation on reset
  };

  function onDrop(sourceSquare, targetSquare) {
    try {
      const move = game.move({
        from: sourceSquare,
        to: targetSquare,
      });

      // Check if the move is valid
      if (move === null) {
        return false; // Prevent the move from being accepted
      }

      let history = game.history();
      let formattedHistory = "";
      for (let i = 0; i < history.length; i += 2) {
        const moveNumber = Math.floor(i / 2) + 1;
        const whiteMove = history[i];
        const blackMove = history[i + 1] ? history[i + 1] : "";
        formattedHistory += `${moveNumber}. ${whiteMove} ${blackMove} `;
      }
      let newPgn = formattedHistory.trim();
      setPgn(newPgn);

      if (history.length === 0) {
        setLines(eco_1);
      } else {
        const mergedEcos = [
          ...eco_1,
          ...eco_2,
          ...eco_3,
          ...eco_4,
          ...eco_5,
          ...eco_6,
          ...eco_7,
          ...eco_8,
          ...eco_9,
          ...eco_10,
          ...eco_11,
          ...eco_12,
        ];

        setLines(filterEcosByPgn(newPgn, mergedEcos));
      }

      // Get the evaluation of the new position
      getStockfishEvaluation(game.fen());

      return true; // Accept the move
    } catch (error) {
      return false; // Prevent the move from being accepted
    }
  }

  const flipOrientation = () => {
    setOrientation((prevOrientation) =>
      prevOrientation === "white" ? "black" : "white"
    );
  };

  const handleUndo = useCallback(() => {
    game.undo();

    let history = game.history();
    let formattedHistory = "";
    for (let i = 0; i < history.length; i += 2) {
      const moveNumber = Math.floor(i / 2) + 1;
      const whiteMove = history[i];
      const blackMove = history[i + 1] ? history[i + 1] : "";
      formattedHistory += `${moveNumber}. ${whiteMove} ${blackMove} `;
    }
    let newPgn = formattedHistory.trim();
    setPgn(newPgn);

    if (history.length === 0) {
      setLines(eco_1);
    } else {
      const mergedEcos = [
        ...eco_1,
        ...eco_2,
        ...eco_3,
        ...eco_4,
        ...eco_5,
        ...eco_6,
        ...eco_7,
        ...eco_8,
        ...eco_9,
        ...eco_10,
        ...eco_11,
        ...eco_12,
      ];

      setLines(filterEcosByPgn(newPgn, mergedEcos));
    }

    // Get the evaluation of the new position after undo
    getStockfishEvaluation(game.fen());
  }, [game]);

  useEffect(() => {
    // Add event listeners for left and right arrow keys
    const handleKeyPress = (e) => {
      if (e.key === "ArrowLeft") {
        handleUndo();
      }
    };

    // Attach the event listener to the window
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleUndo]);

  const filterEcosByPgn = (pgnString, ecos) => {
    return ecos.filter((eco) => eco.pgn.startsWith(pgnString)).slice(0, 20);
  };

  return (
    <Box>
      <ContentHeader
        title={t("OpeningExplorer.header.title")}
        subtitle={t("OpeningExplorer.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[14]}
      />
      <Helmet>
        <title>Opening Explorer</title>
        <meta
          name="description"
          content="Explore a wide range of chess openings, and deepen your understanding of variations from the very first moves."
        />
        <meta property="og:title" content="Opening Explorer" />
        <meta
          property="og:description"
          content="Explore a wide range of chess openings, and deepen your understanding of variations from the very first moves."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/tools/openingexplorer.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/openingexplorer`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Opening Explorer" />
        <meta
          name="twitter:description"
          content="Explore a wide range of chess openings, and deepen your understanding of variations from the very first moves."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/tools/openingexplorer.png`}
        />
      </Helmet>
      <Box>
        <Button
          variant="contained"
          onClick={handleResetBoard}
          style={{ marginRight: 10 }}
          startIcon={
            <RestartAltRoundedIcon style={{ color: colors.material[14] }} />
          }
        >
          {t("OpeningExplorer.buttons.reset")}
        </Button>
        <Button
          variant="contained"
          onClick={handleUndo}
          style={{ marginRight: 10 }}
          startIcon={<UndoRoundedIcon style={{ color: colors.material[14] }} />}
        >
          {t("OpeningExplorer.buttons.undo")}
        </Button>
        <Button
          variant="contained"
          onClick={flipOrientation}
          style={{ marginRight: 10 }}
          startIcon={
            <SwapVertRoundedIcon style={{ color: colors.material[14] }} />
          }
        >
          {t("OpeningExplorer.buttons.flip")}
        </Button>
        <Box
          sx={{
            p: "20px 0px 0px 0px",
            display: "flex",
            flexDirection: {
              xs: "column", // Stacks items vertically on small screens
              sm: "column", // Stacks items vertically on small screens
              md: "row", // Align items side by side on medium and larger screens
            },
            alignItems: "flex-start",
            width: "100%", // Full width container
          }}
        >
          {/* EngineBar and Chessboard Grouped Together */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row", // Ensures EngineBar and Chessboard are side by side
              alignItems: "flex-start",
              width: "100%",
              minHeight: "100px",
              maxWidth: "500px", // Control the maximum width for the chessboard section
            }}
          >
            {/* Engine Bar */}
            <Box
              sx={{
                width: "20px", // Fixed width for the engine bar
                mr: 0.5,
                height: chessboardWidth ? `${chessboardWidth}px` : "auto", // Dynamic height based on chessboard width
                backgroundColor: "#AA0000", // Example background color
              }}
            >
              <EngineBar
                value={evaluation}
                height={chessboardWidth}
                color={orientation}
              />
            </Box>

            {/* Chessboard */}
            <Box
              id="chessboard"
              ref={chessboardRef}
              sx={{
                width: "100%",
                maxWidth: "500px", // Maximum width for the chessboard
                padding: "0px", // No padding
              }}
            >
              <Chessboard
                position={game.fen()}
                onPieceDrop={onDrop}
                boardOrientation={orientation}
                customLightSquareStyle={{
                  backgroundColor:
                    themeColors[userData?.theme || "Modern Minimal"]
                      .lightSquare,
                  backgroundImage:
                    themeColors[userData?.theme || "Modern Minimal"]
                      .lightSquarePattern,
                }}
                customDarkSquareStyle={{
                  backgroundColor:
                    themeColors[userData?.theme || "Modern Minimal"].darkSquare,
                  backgroundImage:
                    themeColors[userData?.theme || "Modern Minimal"]
                      .darkSquarePattern,
                }}
                customPieces={pieceSets[userData?.pieceset || "Maestro"]}
                areArrowsAllowed={false}
              />
            </Box>
          </Box>

          {/* Description Section */}
          <Box
            id="description"
            sx={{
              flex: 1,
              overflow: "auto",
              maxWidth: "500px", // Maximum width for the description box
              pl: {
                xs: 0, // Add margin to top on smaller screens
                md: 2, // Remove margin on larger screens
              },
              mt: {
                xs: 2, // Add margin to top on smaller screens
                md: 0, // Remove margin on larger screens
              },
            }}
          >
            <Typography>
              {t("OpeningExplorer.labels.moves")}: {pgn}
            </Typography>

            <ol
              style={{
                marginTop: "20px",
                paddingLeft: 0,
                listStyleType: "none",
              }}
            >
              {lines.map((eco, index) => (
                <li key={index}>
                  {eco.name} <strong>{eco.pgn}</strong>
                </li>
              ))}
            </ol>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default OpeningExplorer;
