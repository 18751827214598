import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  Typography,
  useTheme,
  Select,
  MenuItem,
} from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { chessterms } from "../../../data/chessterms/chessterms";
import { tokens } from "../../../styles/theme";
import { useUser } from "../../../context/UserContext";
import { useShare } from "../../../context/ShareContext";
import { trackEvent } from "../../../config/ga";
import { updateUserData } from "../../../features/Firestore";
import { useTranslation } from "react-i18next";

import HelpModal from "../../../components/HelpModal";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import GuessWhoHelp from "../../../help/GuessWhoHelp";

function GuessWho() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [completed, setCompleted] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState("");
  const [imageName, setImageName] = useState("");
  const [playerOptions, setPlayerOptions] = useState([]);
  const [feedback, setFeedback] = useState(""); // New state for feedback message
  const { userData, userRef } = useUser();
  const { setShareData } = useShare();
  const { t } = useTranslation("Games");
  const [disabledButtons, setDisabledButtons] = useState([]);
  const [difficulty, setDifficulty] = useState("easy"); // New state for difficulty

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/guesswho",
      title: "Chessboard Magic - Guess Who",
      description:
        "Put your chess knowledge to the test by matching the correct name to the famous player. How well do you know the masters?",
    };

    setShareData(newShareData);
  }, [setShareData]);

  const handleNewGame = useCallback(() => {
    setDisabledButtons([]);
    const chessPlayers = chessterms.filter(
      (term) => term.Type === "P" || term.Type === "W"
    );

    const randomNumber = Math.floor(Math.random() * chessPlayers.length);
    let newSelectedChessPlayer = chessPlayers[randomNumber];

    setImageName(
      `${process.env.PUBLIC_URL}/img/players/${newSelectedChessPlayer.Key}.png`
    );
    setSelectedPlayer(newSelectedChessPlayer);
    setCompleted(false);
    setFeedback("");

    const otherPlayers = chessPlayers.filter(
      (player) =>
        player.Key !== newSelectedChessPlayer.Key &&
        player.Gender === newSelectedChessPlayer.Gender
    );

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    let additionalPlayers = [];
    while (additionalPlayers.length < 5) {
      const randomIndex = Math.floor(Math.random() * otherPlayers.length);
      additionalPlayers.push(otherPlayers[randomIndex]);
      otherPlayers.splice(randomIndex, 1);
    }

    const combinedPlayers = [newSelectedChessPlayer, ...additionalPlayers];

    shuffleArray(combinedPlayers);

    setPlayerOptions(combinedPlayers);

    trackEvent("Games", "GuessWho-Play", "Guess Who");

    if (userData) {
      if (!userData.Puzzles) {
        userData.Puzzles = {};
      }
      if (userData.Puzzles.GuessWho) {
        userData.Puzzles.GuessWho.Played =
          (userData.Puzzles.GuessWho.Played || 0) + 1;
      } else {
        userData.Puzzles.GuessWho = {
          Played: 1,
          Completed: 0,
        };
      }
      updateUserData(userRef, userData);
    }
  }, [userData, userRef]);

  const handlePlayerSelection = (player) => {
    if (player.Key === selectedPlayer.Key) {
      setFeedback(t("Correct"));
      setCompleted(true);
      if (userData) {
        userData.Puzzles.GuessWho.Completed =
          (userData.Puzzles.GuessWho.Completed || 0) + 1;
        updateUserData(userRef, userData);
      }
    } else {
      setFeedback(t("Incorrect"));
      setDisabledButtons((prev) => [...prev, player.Key]);
    }
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDifficultyChange = (event) => {
    setDifficulty(event.target.value);
  };

  useEffect(() => {
    // Start a new game when the component loads
    handleNewGame();
  }, [handleNewGame]);

  return (
    <Box>
      <ContentHeader
        title={t("GuessWho.header.title")}
        subtitle={t("GuessWho.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      <Helmet>
        <title>Guess Who</title>
        <meta
          name="description"
          content="Put your chess knowledge to the test by matching the correct name to the famous player. How well do you know the masters?"
        />
      </Helmet>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="contained"
          onClick={handleNewGame}
          style={{ marginRight: 10 }}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.material[1] }}
            />
          }
        >
          {t("GuessWho.buttons.play")}
        </Button>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          style={{ marginRight: 10 }}
          startIcon={<HelpOutlineIcon style={{ color: colors.material[1] }} />}
        >
          {t("GuessWho.buttons.help")}
        </Button>

        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("GuessWho.helpDetails.title")}
          content={<GuessWhoHelp />}
        ></HelpModal>

        {/* Dropdown for Difficulty Selection */}
        <Select value={difficulty} onChange={handleDifficultyChange}>
          <MenuItem value="easy">Easy</MenuItem>
          <MenuItem value="medium">Medium</MenuItem>
          <MenuItem value="hard">Hard</MenuItem>
        </Select>
      </Box>

      {/* Display the image */}
      {imageName && (
        <Box
          sx={{
            marginTop: 2,
            textAlign: "left",
            padding: "10px 0px 10px 0px",
            maxWidth: "400px",
          }}
        >
          <img
            src={imageName}
            alt=""
            style={{
              width: "100%",
              height: "auto",
              filter: completed // If completed, no filter is applied
                ? "none"
                : difficulty === "medium"
                ? "blur(5px)"
                : difficulty === "hard"
                ? "blur(10px) grayscale(100%)"
                : "none", // Easy has no filter
            }}
          />
        </Box>
      )}

      {/* Feedback Section */}
      <Box sx={{ marginTop: 2 }}>
        {feedback && (
          <Typography
            variant="h7"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {feedback.includes("Correct") ? (
              <>
                <CheckCircleOutlineIcon
                  style={{
                    color: "green",
                    marginRight: "5px",
                    fontSize: "32px",
                  }}
                />
                <span>{t("GuessWho.game.correct")}</span>
              </>
            ) : (
              <>
                <ErrorOutlineIcon
                  style={{ color: "red", marginRight: "5px", fontSize: "32px" }}
                />
                <span>{t("GuessWho.game.incorrect")}</span>
              </>
            )}
          </Typography>
        )}
      </Box>

      {/* Display the player options as buttons */}
      <Box sx={{ marginTop: 2, maxWidth: "700px" }}>
        {playerOptions.length > 0 && (
          <Box>
            {playerOptions.map(
              (player, index) =>
                !completed && (
                  <Button
                    key={`${player.Key}-${index}`}
                    variant="contained"
                    style={{ marginRight: 10, marginBottom: 10 }}
                    onClick={() => handlePlayerSelection(player)}
                    disabled={disabledButtons.includes(player.Key)}
                  >
                    {player.Term}
                  </Button>
                )
            )}
          </Box>
        )}
      </Box>

      {/* Display player details if correct */}
      {completed && (
        <Box sx={{ marginTop: 2, mb: 2 }}>
          <Typography variant="body1">{selectedPlayer.Description}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default GuessWho;
