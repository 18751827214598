import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const RotatingImagePuzzleHelp = () => {
  const { t } = useTranslation("Games");

  return (
    <>
      <Typography>
        {t("RotatingImagePuzzle.helpDetails.description")}
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t(
              "RotatingImagePuzzle.helpDetails.instructions.rotate.title"
            )}
            secondary={t(
              "RotatingImagePuzzle.helpDetails.instructions.rotate.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "RotatingImagePuzzle.helpDetails.instructions.goal.title"
            )}
            secondary={t(
              "RotatingImagePuzzle.helpDetails.instructions.goal.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "RotatingImagePuzzle.helpDetails.instructions.newGame.title"
            )}
            secondary={t(
              "RotatingImagePuzzle.helpDetails.instructions.newGame.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "RotatingImagePuzzle.helpDetails.instructions.difficulty.title"
            )}
            secondary={t(
              "RotatingImagePuzzle.helpDetails.instructions.difficulty.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "RotatingImagePuzzle.helpDetails.instructions.lockOnCorrect.title"
            )}
            secondary={t(
              "RotatingImagePuzzle.helpDetails.instructions.lockOnCorrect.description"
            )}
          />
        </ListItem>
      </List>
    </>
  );
};

export default RotatingImagePuzzleHelp;
