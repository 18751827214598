export const rulesofchess = [
  {
    ID: "2.3",
    Name: "Initial Position",
    FEN: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
    ArrowsHighlights: "",
    SquareHighlights: "",
  },
  {
    ID: "3.2",
    Name: "Bishops",
    FEN: "8/8/8/8/3B4/8/8/8 w - - 0 1",
    ArrowsHighlights: "",
    SquareHighlights: "Ba1,Bb2,Bc3,Be5,Bf6,Bg7,Bh8,Ba7,Bb6,Bc5,Be3,Bf2,Bg1",
  },
  {
    ID: "3.3",
    Name: "Rooks",
    FEN: "8/8/8/8/8/3R4/8/8 w - - 0 1",
    ArrowsHighlights: "",
    SquareHighlights: "Ba3,Bb3,Bc3,Be3,Bf3,Bg3,Bh3,Bd1,Bd2,Bd4,Bd5,Bd6,Bd7,Bd8",
  },
  {
    ID: "3.4",
    Name: "Queen",
    FEN: "8/8/8/4Q3/8/8/8/8 w - - 0 1",
    ArrowsHighlights: "",
    SquareHighlights:
      "Ba1,Bb2,Bc3,Bd4,Bf6,Bg7,Bh8,Bb8,Bc7,Bd6,Bf4,Bg3,Bh2,Ba5,Bb5,Bc5,Bd5,Bf5,Bg5,Bh5,Be1,Be2,Be3,Be4,Be6,Be7,Be8",
  },
  {
    ID: "3.6",
    Name: "Knight",
    FEN: "8/8/8/8/3N4/8/8/8 w - - 0 1",
    SquareHighlights: "Bc2,Bb3,Bb5,Bc6,Be6,Bf5,Bf3,Be2",
  },
  {
    ID: "3.7.3",
    Name: "Pawn Moves",
    FEN: "8/8/8/6p1/8/8/2P5/8 w - - 0 1",
    ArrowsHighlights: "Bc2b3,Bc2d3,Rg5f4,Rg5h4",
    SquareHighlights: "Bc3,Bc4,Rg4",
  },
  {
    ID: "3.7.3.2",
    Name: "En Passant",
    FEN: "8/4p3/8/3P4/8/8/8/8 w - - 0 1",
    ArrowsHighlights: "Bd5e6",
    SquareHighlights: "Re5",
  },
  {
    ID: "3.8.1",
    Name: "King",
    FEN: "8/8/5k2/8/8/2K5/8/8 w - - 0 1",
    SquareHighlights:
      "Bb2,Bb3,Bb4,Bc2,Bc4,Bd2,Bd3,Bd4,Re5,Re6,Re7,Rf5,Rf7,Rg5,Rg6,Rg7",
  },
  {
    ID: "3.8.2a",
    Name: "Castling a",
    FEN: "r3k3/8/8/8/8/8/8/4K2R w - - 0 1",
  },
  {
    ID: "3.8.2b",
    Name: "Castling b",
    FEN: "2kr4/8/8/8/8/8/8/5RK1 w - - 0 1",
  },

  {
    ID: "3.8.2c",
    Name: "Castling c",
    FEN: "4k2r/8/8/8/8/8/8/R3K3 w - - 0 1",
  },
  {
    ID: "3.8.2d",
    Name: "Castling d",
    FEN: "5rk1/8/8/8/8/8/8/2KR4 w - - 0 1",
  },
];
